import React from 'react';
import { Tick } from 'components/common/Icon';
import classnames from 'classnames';
import style from './List.scss';

export interface ListProps {
  children: React.ReactNode[];
  extraSpacing?: boolean;
  type?: 'bullets' | 'numbers' | 'ticksTeal' | 'ticksGreen';
}

export const List: React.FC<ListProps> = ({ children, extraSpacing, type }) => {
  const itemIcon = {
    bullets() {
      return <span className={style.bullet}>•</span>;
    },
    ticksTeal() {
      return <Tick size="small" className={style.tickTeal} />;
    },
    ticksGreen() {
      return <Tick size="small" className={style.tickGreen} />;
    },
    numbers(index) {
      return <span className={style.number}>{index + 1}</span>;
    },
  }[type || 'bullets'];
  const liClassnames = classnames(
    style.listItem,
    (type === 'numbers' || extraSpacing) && style.extraSpacing,
  );

  return (
    <ul>
      {children.filter(Boolean).map((item, i) => (
        <li key={`list-item-${i}`} className={liClassnames}>
          <span className={style.itemIcon}>{itemIcon(i)}</span>
          {item}
        </li>
      ))}
    </ul>
  );
};

List.displayName = 'List';
