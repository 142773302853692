/* global Backbone */
/* eslint prefer-rest-params: 0 */
/* eslint import/prefer-default-export: 0 */

import BigNumber from 'bignumber.js';
import channelCodes from 'config/channelCodes';
import { CheckoutPaymentAnalytics } from 'common/analytics';
import _ from 'common/underscore';

if (!App.Views.POS) {
  App.Views.POS = {};
}

App.Views.POS.BasePaymentForm = App.Views.POS.LineItemForm.extend({
  baseView: App.Views.POS.LineItemForm,
  addLineItemButtonClass: '.js-add-line-item',

  initialize: function initialize() {
    const self = this;
    this.baseView.prototype.initialize.apply(this, arguments);

    this.delegateEvents();

    this.formData.on('add', () => {
      self.close();
    });
  },

  updateAmount: function updateAmount(value) {
    const amount = Wahanda.Number.formatPOSInputintoFloat(value) || 0;
    this.updateFormData('amount', amount);
    const paymentType = this.model.get('paymentType');
    if (paymentType !== App.Models.POS.PaymentDiscriminators.CASH) {
      this.defaultAmount =
        this.defaultAmount == null ? this.formData.get('amount') : this.defaultAmount;

      const tips = new BigNumber(amount).minus(this.defaultAmount).toNumber();
      this.updateFormData('tips', tips);
    }
  },

  setSoldProducts: function updateCashInDrawer(products) {
    this.updateFormData('products', products);
  },

  setSoldAppointments: function setSoldAppointments(appointments) {
    this.updateFormData('appointments', appointments);
  },

  updateCashInDrawer: function updateCashInDrawer(value) {
    this.updateFormData('cashInDrawer', value);
  },

  setViewState: function setViewState() {
    const amountInput = this.$('.js-line-item-amount');
    const validation = this.formData.validate(this.formData.attributes);
    const errorTranslationKey = Wahanda.lang.calendar.appointments.pos.notEnoughCashForTips;
    const isAmountInvalid = () => validation && validation.amount;
    const isNotEnoughCashForTips = () =>
      isAmountInvalid() && validation.amount === 'NOT_ENOUGH_CASH_IN_DRAWER_FOR_TIPS';
    const canUseSinglePurposeVoucher = () => {
      if (!validation) {
        return true;
      }
      return !validation.multiVatVoucherNotSupported;
    };

    const toCurrencyFormat = (value) => Wahanda.Currency.format(value, { forceDecimalPoint: true });

    amountInput.qtip('destroy');

    if (isAmountInvalid() && validation.amount === 'CANT_LEAVE_TIP_WITH_VOUCHER') {
      amountInput
        .formErrorTip(Wahanda.lang.calendar.appointments.pos.voucherOverpayNotAllowed)
        .focus();
    }

    if (isNotEnoughCashForTips()) {
      amountInput
        .formErrorTip(
          Wahanda.Template.render(errorTranslationKey, {
            cash: toCurrencyFormat(this.formData.get('cashInDrawer')),
            tips: toCurrencyFormat(this.formData.get('tips')),
          }),
        )
        .focus();
    }

    if (
      this.formData.get('paymentType') === App.Models.POS.PaymentDiscriminators.VOUCHER &&
      !canUseSinglePurposeVoucher()
    ) {
      this.$('.js-salon-voucher-type')
        .formErrorTip(Wahanda.lang.calendar.appointments.pos.singlePurposeVoucherNotAllowed)
        .focus();
    } else {
      this.$('.js-salon-voucher-type').qtip('destroy');
    }

    this.baseView.prototype.setAddLineItemButtonState.apply(this, arguments);
  },

  render: function render() {
    const self = this;
    const paymentTypeCash = App.Models.POS.PaymentDiscriminators.CASH;
    this.baseView.prototype.render.apply(this, arguments);

    self.$el
      .find('.pos-payment--edit')
      .append(Wahanda.Template.renderTemplate('pos-payment-buttons', {}));
    self.setViewState();
    const paymentType = this.model.get('paymentType');
    const payments = this.controller.checkoutCalc.getPayments().models;
    const cashPayment = payments.find((model) => model.attributes.paymentType === paymentTypeCash);
    const cashInTransaction = cashPayment ? cashPayment.attributes.amount : 0;

    this.updateFormData('outstandingSumToPay', self.controller.checkoutCalc.getOutstanding());
    if (paymentType !== paymentTypeCash) {
      self.controller.checkoutCalc
        .getCashInDrawer()
        .then((value) => self.updateCashInDrawer(value + cashInTransaction))
        .catch(() => self.updateCashInDrawer(0));
    }
    if (paymentType === App.Models.POS.PaymentDiscriminators.VOUCHER) {
      this.setSoldProducts(this.controller.products);
      this.setSoldAppointments(this.controller.appointments);
    }

    return this;
  },

  save: function save() {
    if (this.formData.isValid()) {
      this.close();
      this.controller.savePayment(this.formData);
    }
  },

  close: function close() {
    this.destroy();
    this.controller.showAddPaymentButtons();
  },
});

App.Views.POS.CashPaymentForm = App.Views.POS.BasePaymentForm.extend({
  templateId: 'pos-cash-payment-form',

  save: function save() {
    CheckoutPaymentAnalytics.trackCashPaymentAdd();
    App.Views.POS.BasePaymentForm.prototype.save.call(this);
  },
});

App.Views.POS.CardPaymentForm = App.Views.POS.BasePaymentForm.extend({
  events: {
    'change .js-card-type': 'onKeyDown',
  },

  templateId: 'pos-card-payment-form',

  save: function save() {
    CheckoutPaymentAnalytics.trackCardPaymentAdd();
    App.Views.POS.BasePaymentForm.prototype.save.call(this);
  },

  initialize: function initialize() {
    this.baseView.prototype.initialize.apply(this, arguments);
    // Pick up the parent events so we don't have to do our own close, etc
    this.events = _.extend({}, this.baseView.prototype.events, this.events || {});
  },

  updateCardType: function updateCardType(cardType) {
    this.updateFormData('cardType', cardType);
  },

  onKeyDown: function onKeyDown(e) {
    e.preventDefault();

    if ($(e.target).hasClass('js-card-type')) {
      const cardType = $(e.target).val();
      this.updateCardType(cardType);
    }

    // Call parent to save
    this.baseView.prototype.onKeyDown.apply(this, arguments);
  },
});

App.Views.POS.VoucherPaymentForm = App.Views.POS.BasePaymentForm.extend(
  {
    events: {
      'change .js-voucher-issuer': 'onKeyDown',
      'change .js-salon-voucher-type': 'onKeyDown',
      'keyup .js-voucher-code': 'onKeyDown',
      'focus .js-voucher-code': 'onKeyDown',
      'blur .js-voucher-code': 'onKeyDown',
    },

    templateId: 'pos-voucher-payment-form',

    initialize: function initialize() {
      this.baseView.prototype.initialize.apply(this, arguments);
      // Pick up the parent events so we don't have to do our own close, etc
      this.events = _.extend({}, this.baseView.prototype.events, this.events || {});
      this.options = {
        voucherTypes: {
          singleVAT: App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.SINGLE_VAT,
          multiVAT: App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.MULTI_VAT,
        },
      };
    },

    setViewState: function setViewState() {
      App.Views.POS.BasePaymentForm.prototype.setViewState.call(this);
    },

    save: function save() {
      const { attributes } = this.formData;
      switch (attributes.voucherIssuer) {
        case Wahanda.lang.calendar.appointments.pos.voucher.issuer.own:
          CheckoutPaymentAnalytics.trackSalonVoucherTypeClick(attributes.vatVoucherType);
          break;
        case Wahanda.lang.calendar.appointments.pos.voucher.issuer.thirdParty:
          CheckoutPaymentAnalytics.trackThirdPartyVoucherClick(attributes.vatVoucherType);
          break;
        case Wahanda.lang.calendar.appointments.pos.voucher.issuer.other:
          CheckoutPaymentAnalytics.trackOtherPaymentClick(attributes.vatVoucherType);
          break;
        default:
          break;
      }
      App.Views.POS.BasePaymentForm.prototype.save.call(this);
    },

    onKeyDown: function onKeyDown(e) {
      e.preventDefault();
      const value = $(e.target).val();

      if ($(e.target).hasClass('js-voucher-issuer')) {
        this.updateVoucherIssuer(value);
      }
      if ($(e.target).hasClass('js-voucher-code')) {
        this.updateVoucherCode(value);
      }
      if (this.$(e.target).hasClass('js-salon-voucher-type')) {
        this.updateVATVoucherType(value);
      }

      // Call parent to save
      this.baseView.prototype.onKeyDown.apply(this, arguments);
    },

    updateVATVoucherType: function updateVATVoucherType(value) {
      this.updateFormData('vatVoucherType', value);
    },

    updateVoucherIssuer: function updateVoucherIssuer(value) {
      const isIE = App.config.get('venue').contentChannelCode === channelCodes.IE;
      const isDE =
        Wahanda.Features.isEnabled('pos-de-tss') &&
        App.config.get('venue').contentChannelCode === channelCodes.DE;
      const isSalonVoucher = value === Wahanda.lang.calendar.appointments.pos.voucher.issuer.own;
      const showSalonVoucherDateDropdown = isSalonVoucher && !isIE && !isDE;
      this.updateFormData('voucherIssuer', value);

      if (showSalonVoucherDateDropdown) {
        this.renderVoucherTypeDropdown();
      } else {
        this.destroyVoucherTypeDropdown(isSalonVoucher);
      }
    },

    updateVoucherCode: function updateVoucherCode(value) {
      this.updateFormData('voucherCode', value);
    },

    renderVoucherTypeDropdown: function renderVoucherTypeDropdown() {
      const defaultValue =
        App.config.get('venue').contentChannelCode !== channelCodes.CH
          ? App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.SINGLE_VAT
          : App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.MULTI_VAT;

      const $element = this.$('.js-salon-voucher-type');
      $element.wShow();
      $element.val(defaultValue);

      this.updateVATVoucherType(defaultValue);
    },

    destroyVoucherTypeDropdown: function destroyVoucherTypeDropdown(isSalonVoucher) {
      this.$('.js-salon-voucher-type').wHide();

      if (!isSalonVoucher) {
        this.updateVATVoucherType(null);
        return;
      }

      const isDE =
        Wahanda.Features.isEnabled('pos-de-tss') &&
        App.config.get('venue').contentChannelCode === channelCodes.DE;

      const voucherType = isDE ? App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.SINGLE_VAT : null;

      this.updateVATVoucherType(voucherType);
    },
  },
  {
    VOUCHER_TYPES: {
      SINGLE_VAT: 'single-vat',
      MULTI_VAT: 'multi-vat',
    },
  },
);

App.Views.POS.ViewPayment = App.Views.POS.LineItemView.extend({
  events: {
    'click .action.cancel': 'removePayment',
    'blur .js-currency-input input': 'onFocusChange',
    'focus .js-currency-input input': 'onFocusChange',
    'keyup .js-currency-input input': 'onKeyUp',
  },

  baseView: App.Views.POS.LineItemView,

  render: function render() {
    const paymentType = this.model.get('paymentType').toLowerCase();
    const templateName = `pos-view-${paymentType}`;
    const amount = this.model.get('amount');
    const tip = this.model.get('tip');
    const templateModel = _.extend(
      {
        price: Wahanda.Currency.getFormatted(amount),
        tipAmount: tip ? Wahanda.Currency.getFormatted(tip.get('amount')) : null,
      },
      this.model.attributes,
    );

    this.$el.html(Wahanda.Template.renderTemplate(templateName, templateModel));
    if (paymentType === 'cash') {
      const priceView = new App.Views.POS.CurrencyInput({
        el: $(this.el).find('.js-currency-input'),
        name: 'cash-amount',
        noFocus: true,
        cssClass: 'js-line-item-amount',
        cssContainerClass: 'currency-input--small',
        placeholder: Wahanda.Number.formatFloatForPOSInput(this.model.get('amount')) || 0.0,
        value: Wahanda.Number.formatFloatForPOSInput(this.model.get('amount')) || 0.0,
      });

      priceView.render();
    }
    return this;
  },

  onFocusChange: function onFocusChange(evt) {
    const $input = $(evt.currentTarget);
    this.controller.applyFocusStyles($(evt.currentTarget));
    if (evt.type === 'focusout') {
      const formattedPrice = Wahanda.Number.formatPOSInputintoFloat($input.val());
      if (parseFloat(formattedPrice) === 0) {
        this.removePayment();
      }

      if (!new BigNumber(formattedPrice).isNaN()) {
        $input.val(Wahanda.Number.formatFloatForPOSInput(formattedPrice));
        const costAmount = this.controller.checkoutCalc.getCostAmount(formattedPrice, true);
        this.model.set('amount', formattedPrice);
        this.model.set('costAmount', costAmount);
        this.controller.updatePayments();
      } else {
        $input.val(Wahanda.Number.formatFloatForPOSInput(this.model.get('amount')));
      }
    } else {
      $input.select();
    }
  },

  onKeyUp: function onKeyUp(evt) {
    if (evt.keyCode === 13) {
      $(evt.currentTarget).trigger('blur');
    }
  },

  removePayment: function removePayment() {
    // If this specific payment has a tip, remove it.
    if (this.model.get('tip')) {
      this.controller.removeTip(this.model.get('tip'));
    }
    this.controller.removePayment(this.model);
    this.controller.togglePaymentTitle();
  },
});

App.Views.POS.ViewTip = Backbone.View.extend({
  initialize: function initialize() {
    const self = this;
    this.controller = this.options.controller;
    this.model.on('remove', () => {
      self.remove();
    });
    this.controller.tips.on('reset', () => {
      self.remove();
    });
  },

  render: function render() {
    const templateName = 'pos-view-tip';

    const templateModel = _.extend(
      {
        price: Wahanda.Currency.getFormatted(this.model.get('amount')),
        cardType: this.model.get('cardType'),
      },
      this.model.attributes,
    );

    this.$el.html(Wahanda.Template.renderTemplate(templateName, templateModel));
    return this;
  },

  removeTip: function removeTip() {
    this.controller.removeTip(this.model);
  },
});
