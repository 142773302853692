import React from 'react';
import { PopoverBody } from 'components/common/Popover';
import style from './style.scss';

export interface Props {
  header?: React.ReactNode;
  content?: JSX.Element[];
}

export class ReportTooltip extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  public render() {
    return (
      <PopoverBody>
        {this.renderHeader()}
        {this.renderContent()}
      </PopoverBody>
    );
  }

  private renderHeader() {
    const { header } = this.props;
    if (!header) {
      return null;
    }
    return <h1 className={style.header}>{header}</h1>;
  }

  private renderContent() {
    const { content } = this.props;
    if (!content) {
      return null;
    }
    return <div className={style.content}>{content}</div>;
  }
}
