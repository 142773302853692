import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import * as localesConfig from 'config/domains-locales';
import { SideBarAnalytics } from 'common/analytics';

import style from './SidebarInfo.scss';
import { GetHelpButton } from 'components/common/Sidebar/SidebarInfo/GetHelpButton';
import { ChatSourceType } from 'src/tracking-container/events/chat';

const LANG = Wahanda.lang.home.contacts;

export interface Props {
  venueId: number;
  accountId: number;
  employeeName: string;
  emailAddress: string;
  liveChatSource: ChatSourceType;
  isSelfOnboarding: boolean;
  phoneNumber: string;
  shouldShowSupportPhoneNumber: boolean;
}

interface SidebarInfoLink {
  className: string;
  dataTest: string;
  href?: string;
  onClick: () => void;
  label: string;
}

export class SidebarInfo extends React.Component<Props> {
  private onWhatsNewClick = () => {
    if (window.Intercom) {
      window.Intercom('showMessages');
    }
    SideBarAnalytics.trackWhatIsNewClick({
      property: this.props.liveChatSource,
    });
  };

  private renderLink = ({ className, dataTest, href, onClick, label }: SidebarInfoLink) => {
    let optionalProps = {};
    if (href) {
      optionalProps = {
        target: '_blank',
        rel: 'noopener noreferrer', // https://developers.google.com/web/tools/lighthouse/audits/noopener
      };
    }

    return (
      <a
        data-test={`sidebar-info-link-${dataTest}`}
        className={classnames(style.link, className)}
        key={label}
        href={href}
        role="link"
        onClick={onClick}
        {...optionalProps}
      >
        {label}
      </a>
    );
  };

  public openBookingForm() {
    window.open(LANG.callBookingUrl, '_blank', 'width=900,height=600,top=200,left=230');
  }

  public render() {
    // This logic should be moved out with Sidebar refactor to have
    // a clean component that wouldn't depend on translations
    const domain = localesConfig.DOMAIN_TO_LOCALE_MAP_INVERTED[App.getClientLocale()];
    const url = new URL(LANG.referSalonUrl, `https://www.${domain}`);
    url.searchParams.append('venue_id', this.props.venueId.toString());
    url.searchParams.append('venue_name', this.props.employeeName);
    url.searchParams.append('referrer_email', this.props.emailAddress);
    url.searchParams.append('referrer_id', this.props.accountId.toString());
    url.searchParams.append('referrer_type', 'connect');

    const links = [
      {
        className: style.salon,
        dataTest: 'salon',
        href: url.href,
        onClick: SideBarAnalytics.trackReferSalonClick,
        label: LANG.referSalon,
      },
      {
        className: style.help,
        dataTest: 'help',
        href: LANG.helpCenterUrl,
        onClick: SideBarAnalytics.trackHelpCenterClick,
        label: LANG.helpCenter,
      },
      {
        className: style.whatIsNew,
        dataTest: 'chat',
        onClick: this.onWhatsNewClick,
        label: LANG.whatIsNew,
      },
    ];

    return (
      <div className={style.container} data-test="onboarded-sidebar-info">
        <div>{links.map((prop) => this.renderLink(prop))}</div>
        <div className={style.separator} />
        {this.props.shouldShowSupportPhoneNumber && (
          <div
            data-test="sidebar-info-support-phone-number"
            className={classnames(style.link, style.phone)}
          >
            {this.props.phoneNumber}
          </div>
        )}
        <GetHelpButton source={this.props.liveChatSource} />
      </div>
    );
  }
}
