import React from 'react';
import classnames from 'classnames';

import style from './style.scss';

export enum Level {
  ONE = 'levelOne',
}

export interface TableRowProps {
  className?: string;
  hoverable?: boolean;
  onClick?: () => void;
  level?: Level;
  dataTest?: string;
}

export class TableRow extends React.PureComponent<TableRowProps> {
  public render() {
    const { children, className, hoverable, onClick, level, dataTest, ...rest } = this.props;
    const withHoverStyle = hoverable || onClick;
    const classes = classnames(style.row, className, level && style[level], {
      [style.hoverable]: withHoverStyle,
      [style.clickable]: !!onClick,
    });

    return (
      <tr className={classes} onClick={onClick} {...rest} data-test={dataTest}>
        {children}
      </tr>
    );
  }
}
