BackboneEx.View.Calendar.HeaderPaneAppointment = {
	renderTypeBlock: function() {
		var buttons       = this.paneView.$('.views-control');
		var getBackBtn    = this.paneView.$('.get-back');
		var calendarView  = this.paneView.options.calendarView;
		// If there is one group, do not show calendar type changing block
		var forceWeekView = this.forceWeekViewCheck();
		buttons.toggleClass('hidden', forceWeekView);
        getBackBtn.toggleClass('hidden', forceWeekView);
		if (forceWeekView) {
			if (calendarView.currentOptions.type !== "week") {
				// Can not show day view, if only one group exists
				calendarView.trigger("change:type", "week");
			}

			var resourceIdToForce = this.getForcedWeekResourceId();
			if (calendarView.currentOptions.resourceId != resourceIdToForce) {
				calendarView.onResourceChange(resourceIdToForce);
			}
			return;
		}
		// Set initial type
		var type = (calendarView.currentOptions.type === "day" ? "day" : "week");
		buttons.find('li').removeClass('on').filter('.type-' + type).addClass('on');
	},

	/**
	 * Check if week view should be forced.
	 *
	 * @return boolean
	 */
	forceWeekViewCheck: function() {
		return this.paneView.options.employees.length < 2;
	},

	/**
	 * Which resource should be used in forced week view?
	 * Defaults to the current one.
	 *
	 * @return int or null
	 */
	getForcedWeekResourceId: function() {
		return this.paneView.options.calendarView.currentOptions.resourceId;
	},

	toggleClassesByType: function() {
		var back = this.paneView.$('.previous').find('.week-label');
		var next = this.paneView.$('.next').find('.week-label');
		var selector = this.paneView.$('.type-selector');
		var filters = this.paneView.$('.filters');
		var calendarPane = this.paneView.$el;
		var isDay = (this.paneView.options.calendarView.currentOptions.type === "day");
		var hideFilters = this.mustHideFilterBlock();

		back.add(next).toggleClass('hidden', isDay);
		calendarPane.toggleClass('hide-main-controls', isDay);
		selector.toggleClass('week-selector', !isDay).toggleClass('day-selector', isDay);
		filters.toggleClass('hidden', hideFilters);
	},

	getCurrentPaneViewType: function(){
		return this.paneView.options.calendarView.currentOptions.type;
	},

	mustHideFilterBlock: function() {
		var isDay = App.mainView.appointmentCalendar.isDayView();
		return (isDay || this.paneView.options.employees.length === 0);
	},

	getCurrentDayText: function() {
		return this.paneView.options.calendarView.currentOptions.type === "day"
			? Wahanda.lang.calendar.buttons.gotoToday
			: Wahanda.lang.calendar.buttons.gotoCurrentWeek;
	},

	isCurrentDateShown: function() {
		return this.paneView.options.calendarView.isDateVisible(this.getTodayDate());
	},

	getPrevDate: function() {
		return this._addDaysToDate(this.paneView.options.calendarView.currentOptions.type === "week" ? -7 : -1);
	},

	getNextDate: function() {
		return this._addDaysToDate(this.paneView.options.calendarView.currentOptions.type === "week" ? 7 : 1);
	},

	_addDaysToDate: function(daysToAdd) {
		return Wahanda.Date.addDaysToDate(
			this.paneView.options.calendarView.currentOptions.date,
			daysToAdd
		);
	},

	getTodayDate: function() {
		return Wahanda.Date.createVenueDate();
	},

	getCurrentDateText: function() {
		var text;
		var calView = this.paneView.options.calendarView;
		if ("day" === calView.currentOptions.type) {
			var weekDay = Wahanda.lang.date.weekdaysShort[ calView.currentOptions.date.getDay() ];
			if (Wahanda.Date.isToday(calView.currentOptions.date)) {
				text = weekDay + ',<span class="sub">' + Wahanda.lang.date.today + '</span>';
			} else {
				text = weekDay + '<span class="sub">' +
					Wahanda.Date.formatDate(
						App.config.get('jqueryDateFormat').mediumDate,
						calView.currentOptions.date
					) + ', ' + calView.currentOptions.date.getFullYear()
					+ '</span>';
			}
		} else {
			var range = calView.getVisibleDateRange();
			text = Wahanda.Date.formatDate(
				App.config.get('jqueryDateFormat').mediumDate,
				range.from
			);
			text += " - ";
			text += Wahanda.Date.formatDate(
				App.config.get('jqueryDateFormat').mediumDate,
				range.to
			);
		}
		return text;
	},

	getShowAllResourcesText: function() {
		return Wahanda.lang.calendar.pane.buttons.showAllGroups;
	},

	renderSingleResourceTitle: function(title) {
		var $filters = this.paneView.$('.filters').wShow();
		$filters.find(".calendar-resource").hide();
		$filters.find('.b-resource-name').text( String(title) ).show();
	},

	hideSingleResourceTitle: function() {
		this.paneView.$('.b-resource-name').hide();
	}
};
