import React from 'react';
import Wahanda from 'common/wahanda';
import { generatePath } from 'react-router-dom';
import { VENUE_OPENING_CAMPAIGN } from 'components/marketing/EmailCampaign/CampaignList';
import PATHS from 'components/marketing/Router/paths';
import { Button } from 'components/common/Button';
import { Analytics } from '../tracking';
import style from '../CovidBanner.scss';

const LANG = Wahanda.lang.shared.covidBanner;
interface Props {
  canAccessMarketingTools: boolean;
}

export const Reopened: React.SFC<Props> = ({ canAccessMarketingTools }) => {
  const handleViewCampaignClicked = () => {
    Analytics.trackViewCampaignClicked();
    window.location.assign(
      Wahanda.Url.getFullUrl(
        'marketing',
        generatePath(PATHS.CAMPAIGN_PREVIEW_DEFAULT, {
          templateId: VENUE_OPENING_CAMPAIGN,
        }),
      ),
    );
  };
  return (
    <>
      <span>{LANG.backOpen}</span>
      {canAccessMarketingTools ? (
        <span className={style.buttonWrapper}>
          <Button
            onClick={handleViewCampaignClicked}
            dataTest="view-campaign-button"
            label={LANG.viewCampaign}
          />
        </span>
      ) : (
        ` ${LANG.backOpenLimited}`
      )}
    </>
  );
};
