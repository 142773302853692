import { ChecklistTaskItem } from 'components/shared/OnboardingWidget/OnboardingWidget.types';
import { trackingContainer } from './TrackingContainer';
import { chat_opened } from './events/chat';

export type TrackingEvents = typeof trackingEvents;
export type TrackingEventName = keyof TrackingEvents;
export type TrackingEvent<T extends TrackingEventName> = typeof trackingEvents[T];
export type TrackingEventInput<T extends TrackingEventName> = Parameters<TrackingEvent<T>>;
export type TrackingEventOutput<T extends TrackingEventName> = ReturnType<TrackingEvent<T>>;

/**
 * Responsible for defining a list of events by name
 * and providing a simple data mutation function.
 */
export const trackingEvents = {
  invalid_sms_sender_modal: (action: 'open' | 'close' | 'skip' | 'update') => ({ action }),
  self_onboarding_task: (
    action: 'completed' | 'dropdown_open' | 'cta_click',
    task: ChecklistTaskItem,
  ) => ({ action, task }),
  self_onboarding_viewed: () => undefined,
  self_onboarding_get_online: () => undefined,
  chat_opened,
  quick_search: ({ action }: { action: 'focus' }) => ({ action }),
};

export function getEventByName<T extends TrackingEventName>(eventName: T): TrackingEvent<T> {
  return trackingEvents[eventName];
}

/**
 * Example usage:
 * const trackEvent = getTrackingByEventName('event_name');
 * trackEvent(values);
 */
export function getTrackingByEventName<T extends TrackingEventName>(eventName: T) {
  return (...args: TrackingEventInput<T>): void => trackingContainer.fireEvent(eventName, args);
}
