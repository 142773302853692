import { assoc, assocIn, curry, pipeline } from 'mori';
import { toDateString } from 'common/datetime';
import { appState } from 'state';

import { setHash } from 'src/url-states/reports-transactions';
import { fetch } from 'services/pos-daily-report';

export default function changeDate(date) {
  const newDate = toDateString(date) || date;

  appState.swap((currentState) =>
    pipeline(
      assoc(currentState, 'reports/transactions/date', newDate),
      // set transactions to null, to show the loading spinner
      // until the new day has been fetched.
      curry(assocIn, ['pos', 'daily-report', newDate], null),
    ),
  );

  setHash(false);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  fetch();
}
