require('../mixin/common/url-filter.js');
/* * */
/* Collection for retrieving data with  URL-filters. */

BackboneEx.Collection.UrlFilter = BackboneEx.Collection.Base.extend({});

BackboneEx.Mixin.extend(
    BackboneEx.Collection.UrlFilter,
    BackboneEx.Mixin.Common.UrlFilter
);
