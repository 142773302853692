import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'common/moment';
import { GoTo, Direction } from 'components/common/GoTo';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import { appState } from 'state';
import { canDelegateToApp, delegateToApp, ACTION } from 'common/app-delegation';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { ExportDialogRedesigned } from 'components/reports/ExportDialogRedesigned';
import { isPreviousDayOpen, closedOrOpenedDayInFuture } from 'src/services/pos-status';
import Loader from 'components/common/Loader';
import { TransactionSummaryContainer } from 'src/components/reports/transactions/TransactionSummary/container';
import { TransactionsReportTracking } from 'components/reports/transactions/tracking';
import { DailyReport } from 'reduxStore/common/dailyReport/types';
import { PosStatus } from 'reduxStore/common/posStatus/types';
import TransactionsReport from '../TransactionsReport';
import { ActionsAndFiltersBar } from '../ActionsAndFiltersBar';
import { PaymentMethods } from '../PaymentMethods';
import { SalesSummary } from '../SalesSummary';
import { CashRegister } from '../CashRegister';
import { SignatureSearchModal } from '../SignatureSearchModal';
import style from './TransactionsTabContent.scss';

interface StateProps {
  dailyReport: DailyReport;
  posStatus: PosStatus;
  actions: {
    requestPosStatusAction: () => void;
    dailyReportRequest: (date?: string) => void;
  };
}
interface UrlProps {
  venueId: string;
  date?: string;
}
type Props = StateProps & RouteComponentProps<UrlProps>;

export const TransactionsTabContent = (props: Props) => {
  const [cancellationId, setCancellationId] = React.useState(undefined);
  const [checkoutId, setCheckoutId] = React.useState(undefined);
  const [showExportDialog, setShowExportDialog] = React.useState(false);
  const [showFullView, setShowFullView] = React.useState(false);
  const [showSignatureSearch, setShowSignatureSearch] = React.useState(false);
  const date = moment(props.match.params.date).formatApiDateString();
  const venueId = parseInt(props.match.params.venueId, 10);
  const { dailyReport, posStatus, actions } = props;
  const fetchData = () => {
    actions.requestPosStatusAction();
    actions.dailyReportRequest(date);
  };
  React.useEffect(() => {
    App.on(Wahanda.Event.TRANSACTION_CANCELLED, fetchData);
    return () => {
      appState.removeWatch('TransactionsTabContent');
    };
  }, []);
  React.useEffect(() => {
    fetchData();
  }, [venueId, date]);
  const showSimpleView = () => {
    TransactionsReportTracking.trackBasicViewClick();
    if (showFullView) {
      TransactionsReportTracking.trackTableView(false);
    }
    setShowFullView(false);
  };
  const showAdvancedView = () => {
    TransactionsReportTracking.trackAdvancedViewClick();
    if (!showFullView) {
      TransactionsReportTracking.trackTableView(true);
    }
    setShowFullView(true);
  };
  const openDialog = (transaction) => {
    TransactionsReportTracking.trackTransactionDetailsClick();
    if (
      canDelegateToApp(ACTION.OPEN_CHECKOUT_REFUND) ||
      canDelegateToApp(ACTION.OPEN_CHECKOUT_SUMMARY)
    ) {
      if (transaction.cancellationId && canDelegateToApp(ACTION.OPEN_CHECKOUT_REFUND)) {
        delegateToApp(ACTION.OPEN_CHECKOUT_REFUND, {
          cancellationId: transaction.cancellationId,
          onDone: fetchData,
        });
        return;
      }
      if (transaction.checkoutId && canDelegateToApp(ACTION.OPEN_CHECKOUT_SUMMARY)) {
        delegateToApp(ACTION.OPEN_CHECKOUT_SUMMARY, {
          checkoutId: transaction.checkoutId,
          onDone: fetchData,
        });
        return;
      }
    }
    setCheckoutId(transaction.checkoutId);
    setCancellationId(transaction.cancellationId);
  };
  const renderTransactionDialog = () => {
    const onClose = () => {
      setCheckoutId(undefined);
      setCancellationId(undefined);
    };
    if (checkoutId || cancellationId) {
      return (
        <TransactionSummaryContainer
          checkoutId={checkoutId}
          cancellationId={cancellationId}
          date={date}
          onClose={onClose}
        />
      );
    }
    return null;
  };
  const renderNoTransactionsMessage = () => {
    const dayIsClosed = dailyReport.isClosed;
    const canBeClosed =
      (closedOrOpenedDayInFuture(date, posStatus) === false && isPreviousDayOpen(date) === false) ||
      dayIsClosed;
    const transactionsTitle = () => {
      if (canBeClosed) {
        return Wahanda.lang.reports.transactions.table.canCloseNoTransactionsTitle;
      }
      return Wahanda.lang.reports.transactions.table.noTransactionsTitle;
    };
    const transactionsCopy = () => {
      if (canBeClosed) {
        return Wahanda.lang.reports.transactions.table.canCloseNoTransactionsCopy;
      }
      return Wahanda.lang.reports.transactions.table.noTransactionsCopy;
    };
    return (
      <div className={style.messages}>
        <p className={style.header}>{transactionsTitle()}</p>
        <p>{transactionsCopy()}</p>
      </div>
    );
  };
  const onSignatureSearchModalClose = () => setShowSignatureSearch(false);
  const renderTransactionsReport = () => {
    const transactions = dailyReport.transactions;
    if (isEmpty(transactions)) {
      return renderNoTransactionsMessage();
    }
    return (
      <TransactionsReport
        advancedView={showFullView}
        transactions={transactions}
        openDialog={openDialog}
      />
    );
  };
  const toggleExportDialogFn = () => {
    if (!showExportDialog) {
      TransactionsReportTracking.trackExportDataClick();
    }
    setShowExportDialog(!showExportDialog);
  };
  return (
    <div>
      <GoTo className={style.goBackLink} href="/" direction={Direction.backward}>
        {Wahanda.lang.reports.tabs.reports}
      </GoTo>
      {showExportDialog && (
        <ExportDialogRedesigned onClose={toggleExportDialogFn} venueId={venueId} />
      )}
      <div className="top-content">
        <ActionsAndFiltersBar
          lang={Wahanda.lang}
          date={date}
          dailyReport={dailyReport}
          advancedView={showFullView}
          actions={{
            showAdvancedView,
            showSimpleView,
            onSignatureSearchClick: () => setShowSignatureSearch(true),
            toggleExportDialogFn,
          }}
        />
      </div>
      {showSignatureSearch && <SignatureSearchModal onClose={onSignatureSearchModalClose} />}
      <div className={classnames('push-from-top', style.content)}>
        {isEmpty(dailyReport) ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className={style.transactionsReport}>
              {renderTransactionsReport()}
              {renderTransactionDialog()}
            </div>
            {!showFullView && (
              <div className={style.sideBar}>
                <SalesSummary salesSummary={dailyReport.salesSummary} />
                <PaymentMethods salesSummary={dailyReport.salesSummary} />
                <CashRegister cashSummary={dailyReport.cashSummary} />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
