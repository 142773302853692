import React, { Component } from 'react';
import Wahanda from 'common/wahanda';
import { OrderProducts } from 'common/analytics';
import {
  Table,
  TableColumnHeader,
  TableRow,
  TableBody,
  TableHeader,
} from 'components/common/Table';
import ProductOrderRow from './ProductOrderRow/index';
import ProcessOrder from '../ProcessOrder/container';
import style from './style.scss';

const lang = Wahanda.lang.menu.productOrdersList;
interface IProductOrdersListProps extends React.HTMLAttributes<Element> {
  orders?: {}[];
}
type ProductOrdersListState = {
  selectedOrderId?: any;
};
class ProductOrdersList extends Component<IProductOrdersListProps, ProductOrdersListState> {
  static defaultProps = {
    orders: [],
  };

  state = {
    selectedOrderId: null,
  };

  componentDidMount() {
    OrderProducts.trackListView();
  }

  rowClickHandler = (orderId) => {
    this.setState({
      selectedOrderId: orderId,
    });
  };

  render() {
    const { orders } = this.props;
    return (
      <section>
        {this.state.selectedOrderId && (
          <ProcessOrder
            orderId={this.state.selectedOrderId}
            onClose={() =>
              this.setState({
                selectedOrderId: null,
              })
            }
          />
        )}
        <Table>
          <TableHeader>
            <TableRow>
              <TableColumnHeader className={style.tableCell}>
                {lang.columnSupplier}
              </TableColumnHeader>
              <TableColumnHeader className={style.tableCell}>{lang.columnDate}</TableColumnHeader>
              <TableColumnHeader className={style.tableCell}>
                {lang.columnProductsOrdered}
              </TableColumnHeader>
              <TableColumnHeader className={style.tableCell}>{lang.columnStatus}</TableColumnHeader>
            </TableRow>
          </TableHeader>
          <TableBody>
            {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
            {orders.map((order) => (
              // @ts-expect-error ts-migrate(2769) FIXME: Type '{ key: any; onRowClick: (orderId: any) => vo... Remove this comment to see the full error message
              <ProductOrderRow {...order} key={order.id} onRowClick={this.rowClickHandler} />
            ))}
          </TableBody>
        </Table>
      </section>
    );
  }
}

export default ProductOrdersList;
