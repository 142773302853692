import React from 'react';
import classnames from 'classnames';
import { Spinner } from 'components/common/Spinner';
import style from './BadgeRedesigned.scss';

export interface BadgeProps {
  className?: string;
  text?: string | number;
  type?: 'muted' | 'success' | 'alert' | 'info' | 'expose';
  icon?: JSX.Element;
  isLoading?: boolean;
  isLight?: boolean;
}

export const BadgeRedesigned = (props: BadgeProps) => {
  const { text, type = 'success', className, isLight, isLoading, icon } = props;
  const badgeOfType = classnames(className, isLight ? style[`${type}IsLight`] : style[type], {
    [style.noSpace]: !text || `${text}`.length <= 1,
    [style.dot]: !text && !icon,
  });

  const iconClass = classnames(style.icon, {
    [style.iconSpace]: text,
  });

  return (
    <span className={badgeOfType}>
      {icon && <span className={iconClass}>{icon}</span>}

      {isLoading && (
        <span className={iconClass}>
          <Spinner size="tiny" />
        </span>
      )}
      {text}
    </span>
  );
};

BadgeRedesigned.displayName = 'BadgeRedesigned';
