import * as actions from './actions';
import { TransactionExports } from './types';
import { on, reducer } from 'ts-action';

export enum ExportsStateStatus {
  EXPORTS_PENDING = 'EXPORTS_PENDING',
  EXPORTS_COMPLETE = 'EXPORTS_COMPLETE',
  EXPORTS_FAILURE = 'EXPORTS_FAILURE',
  NEW_EXPORT_FAILURE = 'NEW_EXPORT_FAILED',
  INITIAL = 'INITIAL',
}

interface State {
  status: ExportsStateStatus;
  posExports?: TransactionExports[];
}

const initialState: State = {
  status: ExportsStateStatus.INITIAL,
};

export const transactionsExportReducer = reducer(
  initialState,
  on(actions.initPosExport, actions.getPosExports, (state) => ({
    ...state,
    status: ExportsStateStatus.EXPORTS_PENDING,
  })),
  on(actions.initPosExportFailure, (state) => ({
    ...state,
    status: ExportsStateStatus.NEW_EXPORT_FAILURE,
  })),
  on(actions.getPosExportsSuccess, (state, action) => ({
    ...state,
    status: ExportsStateStatus.EXPORTS_COMPLETE,
    posExports: [...action.payload.posExports],
  })),
  on(actions.getPosExportsFailure, (state) => ({
    ...state,
    status: ExportsStateStatus.EXPORTS_FAILURE,
  })),
);
