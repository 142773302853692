import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class CardAlert extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-card-alert', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M14.6960014,14 C14.3384413,14.7332966 13.8710603,15.407728 13.3100014,16 L21.0000014,16 L21.0000014,17 C21.0000014,17.5522847 20.5522861,18 20.0000014,18 L5.17000139,18 C5.59422755,19.1989939 6.72817039,20.0003669 8.00000139,20 L20.0000014,20 C21.6568556,20 23.0000014,18.6568542 23.0000014,17 L23.0000014,11 C23.0000014,9.34314575 21.6568556,8 20.0000014,8 L15.1020014,8 C15.3146602,8.64754765 15.4433772,9.31969922 15.4850014,10 L20.0000014,10 C20.5522861,10 21.0000014,10.4477153 21.0000014,11 L21.0000014,14 L14.6960014,14 Z" />
        <path d="M6.50000139,12 L8.50000139,12 L8.50000139,14 L6.50000139,14 L6.50000139,12 Z M6.50000139,7 L8.50000139,7 L8.50000139,11 L6.50000139,11 L6.50000139,7 Z M7.49400139,4 C5.7708898,4.00079547 4.11866839,4.68606186 2.90080711,5.90504836 C1.68294582,7.12403486 0.999205553,8.77688841 1.00000069,10.5 C1.00000069,14.088 3.90500139,17 7.49400139,17 C11.0852808,17.001105 13.9977929,14.0912789 14.0000007,10.5 C14.0000007,6.912 11.0880014,4 7.49400139,4 Z M3.00000128,10.5 C3.00000128,8.014 5.01400139,6 7.50000128,6 C9.98600139,6 12.0000013,8.014 12.0000013,10.5 C12.0000013,12.986 9.98600139,15 7.50000128,15 C6.30644586,15.0002653 5.16169909,14.5262447 4.31772787,13.6822735 C3.47375664,12.8383023 2.99973606,11.6935555 3.00000128,10.5 L3.00000128,10.5 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(CardAlert);
