import { ActionUnionTypes } from 'reduxStore/actionCreator';
import {
  GET_BANK_DETAILS_REQUEST,
  GET_BANK_DETAILS_RECEIVE,
  GET_LAST_PAYMENT_STATUS_REQUEST,
  GET_LAST_PAYMENT_STATUS_RECEIVED,
} from 'reduxStore/actionsConst';
import * as actions from './actions';
import { LastPaymentResponse } from './types';

export interface BankReducer {
  bankDetailsLoading: boolean;
  lastPaymentStatusLoading: boolean;
  bankName?: string;
  bankCode?: string;
  bankAccountName?: string;
  bankAccountNumber?: string;
  lastPayment?: LastPaymentResponse['lastPayment'];
}

export const initialState: BankReducer = {
  bankDetailsLoading: false,
  lastPaymentStatusLoading: false,
  bankName: undefined,
  bankCode: undefined,
  bankAccountName: undefined,
  bankAccountNumber: undefined,
  lastPayment: undefined,
};

export function bankDetails(
  state = initialState,
  action: ActionUnionTypes<typeof actions>,
): BankReducer {
  switch (action.type) {
    case GET_BANK_DETAILS_REQUEST:
      return {
        ...state,
        bankDetailsLoading: true,
      };
    case GET_BANK_DETAILS_RECEIVE:
      return {
        ...state,
        ...action.payload,
        bankDetailsLoading: false,
      };
    case GET_LAST_PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        lastPaymentStatusLoading: true,
      };
    case GET_LAST_PAYMENT_STATUS_RECEIVED: {
      return {
        ...state,
        ...action.payload,
        lastPaymentStatusLoading: false,
      };
    }
    default:
      return state;
  }
}
