import { connect } from 'react-redux';
import { ClientReach as Component } from './ClientReach';
import { BackToBusinessState } from '../../reducer';

const mapStateToProps = ({ marketing }) => {
  const backToBusiness: BackToBusinessState = marketing?.backToBusiness;
  return {
    currentReach: backToBusiness.currentReach,
    potentialReach: backToBusiness.potentialReach,
    successfullyReached: backToBusiness.successfullyReached,
    alreadySent: backToBusiness.alreadySent,
  };
};

export const ClientReach = connect(mapStateToProps)(Component);
