import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Alert extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-alert', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M15.3366667,2 C15.65,2 16.2222222,2.23888889 16.45,2.46666667 L21.5344444,7.55 C21.7611111,7.77777778 22,8.35222222 22,8.66333333 L22,15.3366667 C22,15.65 21.7611111,16.2233333 21.5344444,16.45 L16.45,21.5344444 C16.2222222,21.7611111 15.6477778,22 15.3366667,22 L8.66333333,22 C8.35,22 7.77666667,21.7611111 7.55,21.5344444 L2.46555556,16.45 C2.23888889,16.2222222 2,15.6477778 2,15.3366667 L2,8.66333333 C2,8.35 2.23888889,7.77777778 2.46666667,7.55 L7.55,2.46555556 C7.77777778,2.23888889 8.35222222,2 8.66333333,2 L15.3366667,2 Z M14.6934538,3.92771084 L9.30654619,3.92771084 C9.05540829,3.92771084 8.59170014,4.12054886 8.40783133,4.30352075 L4.30441766,8.40783133 C4.12054886,8.59170014 3.92771084,9.05361446 3.92771084,9.30654619 L3.92771084,14.6934538 C3.92771084,14.9445917 4.12054886,15.4082999 4.30352075,15.5921687 L8.40783133,19.6964793 C8.5908032,19.8794511 9.05361446,20.0722892 9.30654619,20.0722892 L14.6934538,20.0722892 C14.9445917,20.0722892 15.4082999,19.8794511 15.5921687,19.6964793 L19.6964793,15.5921687 C19.8794511,15.4091968 20.0722892,14.9463855 20.0722892,14.6934538 L20.0722892,9.30654619 C20.0722892,9.05540829 19.8794511,8.59170014 19.6964793,8.40783133 L15.5921687,4.30441766 C15.4082999,4.12054886 14.9463855,3.92771084 14.6934538,3.92771084 Z M10.9959839,6.97991969 L13.0040161,6.97991969 L13.0040161,13.0040161 L10.9959839,13.0040161 L10.9959839,6.97991969 Z M12,17.5220884 C11.1682459,17.5220884 10.4939759,16.8478184 10.4939759,16.0160643 C10.4939759,15.1843101 11.1682459,14.5100402 12,14.5100402 C12.8317541,14.5100402 13.5060241,15.1843101 13.5060241,16.0160643 C13.5060241,16.8478184 12.8317541,17.5220884 12,17.5220884 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Alert);
