import React from 'react';
import Wahanda from 'common/wahanda';
import { SmartDiscount, DiscountTag } from 'components/common/IconPicture';

const lang = Wahanda.lang.marketing.campaignSelection.promotionalOffers.typeSelect;

export const SMART_DISCOUNTS_PROMO_OFFER = 'smart-discounts';

export const SERVICE_ON_SALE_PROMO_OFFER = 'service-on-sale';

const typeList = [
  {
    id: SMART_DISCOUNTS_PROMO_OFFER,
    image: <SmartDiscount />,
    name: lang.smartDiscounts.title,
    description: lang.smartDiscounts.description,
  },
  {
    id: SERVICE_ON_SALE_PROMO_OFFER,
    image: <DiscountTag />,
    name: lang.serviceOnSale.title,
    description: lang.serviceOnSale.description,
  },
];

export default typeList;
