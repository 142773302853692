import React from 'react';
import { getIn, assocIn, hashMap, get, partial, curry, vector } from 'mori';
import { withAnyCursor, cursorValueHasChanged } from 'atom/cursors';
import storeBuilder from 'reduxStore/store';
import { appState } from 'state';
import { sprintf } from 'sprintf-js';
import { trackEvent } from 'common/analytics';
import channelCodes from 'config/channelCodes';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import { toDateString, now } from 'common/datetime';
import { fetch as fetchPosStatus, isPreviousDayOpen, isTodayClosed } from 'src/services/pos-status';
import {
  fetch as fetchPosDailyReport,
  clearFetchCache as clearPOSDailyReportFetchCache,
} from 'src/services/pos-daily-report';
import { ButtonDropdown, ButtonDropdownLink } from 'components/common/ButtonDropdown';
import { Pos } from 'components/common/Icon';
import {
  openSettlementReportPdf,
  openDrawerCorrectionPdf,
} from 'components/reports/transactions/day-status/settlement-report';
import {
  openWithdrawalDialog,
  openDepositDialog,
} from 'components/reports/transactions/RootTransactionDialog/actions';
import POSPreviousDayNotClosedModal from '../POSPreviousDayNotClosedModal';

const lang = Wahanda.lang.calendar.buttons.posDropdown;

function onCloseDialogCallback({ date, showReport, showCorrectionReceipt, correctionReceiptId }) {
  if (showReport) {
    openSettlementReportPdf(date);
  }
  if (showCorrectionReceipt) {
    openDrawerCorrectionPdf(correctionReceiptId);
  }
}

function closePreviousDate(date) {
  appState.swap(
    curry(assocIn, ['pos', 'close-day'], hashMap('date', date, 'callback', onCloseDialogCallback)),
  );
}

function getSettlementReportButton(): ButtonDropdownLink | undefined {
  if (!isTodayClosed()) {
    return undefined;
  }
  return {
    title: lang.settlementReport,
    onClick: () => {
      trackEvent('calendar', 'click', 'pos-settlement-report');
      openSettlementReportPdf(getIn(appState.deref(), ['pos-status', 'lastClosedDay']));
    },
  };
}

function setDialog(dialog) {
  appState.swap((state) => assocIn(state, ['reports', 'transactions', 'dialog'], dialog));
}

function openPrevDayClose(description) {
  const posStatus = get(appState.deref(), 'pos-status');
  const date = get(posStatus, 'openDay');
  const editedDescription = description.replace('{date}', moment(date).formatDateDefault());
  const onClose = partial(setDialog, null);
  const confirmPrevDayClose = (
    <POSPreviousDayNotClosedModal onClose={onClose} text={editedDescription} />
  );
  setDialog(confirmPrevDayClose);
}

function withdrawalClose() {
  openPrevDayClose(Wahanda.lang.posRestrictions.prevDayOpenDropdown.withdrawal);
}

function depositClose() {
  openPrevDayClose(Wahanda.lang.posRestrictions.prevDayOpenDropdown.deposit);
}

function prevClose() {
  openPrevDayClose(Wahanda.lang.posRestrictions.prevDayOpenDropdown.close);
}

function checkoutClose() {
  openPrevDayClose(Wahanda.lang.posRestrictions.prevDayOpenDropdown.checkout);
}

function closeAction(date) {
  trackEvent('calendar', 'click', 'pos-close-day');
  appState.swap((oldState) => assocIn(oldState, ['close-dialog', 'no-fetch'], true));
  if (isPreviousDayOpen(date)) {
    prevClose();
  } else {
    closePreviousDate(date);
  }
}

function getCloseDayButton(date): ButtonDropdownLink | undefined {
  if (!isTodayClosed() && Wahanda.Permissions.managePos()) {
    return { title: lang.closeDay, onClick: partial(closeAction, date) };
  }
  return undefined;
}

function openCheckout() {
  const dialog = new App.Views.Forms.POSDialog();
  dialog.render();
  dialog.open();
}

function checkoutAction(date) {
  trackEvent('calendar', 'click', 'pos-check-out');
  if (isPreviousDayOpen(date)) {
    checkoutClose();
  } else {
    openCheckout();
  }
}

function getHelpCenterLink(): ButtonDropdownLink | undefined {
  const posHelpCentreLink = lang.posHelpCentreLink;
  if (!posHelpCentreLink) {
    return undefined;
  }
  return {
    title: lang.posHelpCentre,
    onClick: () => {
      trackEvent('calendar', 'click', 'pos-help-centre-link');
      window.open(posHelpCentreLink, '_blank');
    },
  };
}

function getCertificateLink(): ButtonDropdownLink | undefined {
  let posCertificateLink;
  const channel = App.domainChannelCode();

  switch (channel) {
    case channelCodes.FR:
      posCertificateLink = lang.posCertificateFrLink;
      break;
  }

  if (!posCertificateLink) {
    return undefined;
  }

  return {
    title: lang.posCertificate,
    onClick: () => {
      trackEvent('calendar', 'click', 'pos-certificate-link');
      window.open(posCertificateLink, '_blank');
    },
  };
}

function getCheckoutButton(date): ButtonDropdownLink | undefined {
  if (isTodayClosed()) {
    return undefined;
  }
  return {
    title: lang.newCheckout,
    onClick: partial(checkoutAction, date),
  };
}

function getTransactionListButton(): ButtonDropdownLink | undefined {
  if (!Wahanda.Permissions.canViewTransactionReport()) {
    return undefined;
  }
  return {
    title: lang.transactionList,
    onClick: () => {
      trackEvent('calendar', 'click', 'pos-list-transactions');
      window.location = sprintf(
        '/reports#venue/%d/transactions/%s',
        // TODO: shouldn't this come from the state atom?
        App.getVenueId(),
        toDateString(now()),
      );
    },
  };
}

function withdrawalAction(date) {
  trackEvent('calendar', 'click', 'pos-widrawal');
  if (isPreviousDayOpen(date)) {
    withdrawalClose();
  } else {
    storeBuilder().dispatch(openWithdrawalDialog());
  }
}

function depositAction(date) {
  trackEvent('calendar', 'click', 'pos-deposit');
  if (isPreviousDayOpen(date)) {
    depositClose();
  } else {
    storeBuilder().dispatch(openDepositDialog());
  }
}

function getWithdrawalButton(date): ButtonDropdownLink | undefined {
  if (isTodayClosed() || !Wahanda.Permissions.managePos()) {
    return undefined;
  }
  return {
    title: lang.withdraw,
    onClick: partial(withdrawalAction, date),
  };
}

function getDepositButton(date): ButtonDropdownLink | undefined {
  if (isTodayClosed() || !Wahanda.Permissions.managePos()) {
    return undefined;
  }
  return { title: lang.deposit, onClick: partial(depositAction, date) };
}

function getDropdownItems(): (ButtonDropdownLink | undefined)[] {
  const date = toDateString(now());
  return [
    getCheckoutButton(date),
    getCloseDayButton(date),
    getSettlementReportButton(),
    getWithdrawalButton(date),
    getDepositButton(date),
    getTransactionListButton(),
    getHelpCenterLink(),
    getCertificateLink(),
  ];
}

function maybeFetchPOSStatus(posStatus, shouldRefetchAll) {
  if (!posStatus || shouldRefetchAll) {
    // refetch, if needed (the && posStatus is not needed, but explicit)
    fetchPosStatus();
  }
}

function maybeFetchPosDailyReport(reportsTransactionsDate, posDailyReport, shouldRefetchAll) {
  if (reportsTransactionsDate && (!posDailyReport || shouldRefetchAll)) {
    fetchPosDailyReport(reportsTransactionsDate);
  }
}

export interface POSDropdownProps extends React.HTMLAttributes<Element> {
  onOverlayClick?: (...args: any[]) => any;
}

type State = {
  openDay?: any;
  dialog?: any;
};

export class POSDropdown extends React.Component<POSDropdownProps, State> {
  state = {
    openDay: toDateString(now()),
    dialog: null,
  };

  UNSAFE_componentWillMount() {
    appState.addWatch(
      'calendar-pos-dropdown',
      withAnyCursor(
        (oldState, newState) => {
          const getOpenDay = curry(getIn, ['pos-status', 'openDay'], this.state.openDay);
          const openDayChanged = getOpenDay(newState) !== getOpenDay(oldState);
          const venueChanged = get(newState, 'venueId') !== get(oldState, 'venueId');
          const reportsTransactionsDate = getIn(newState, 'reports/transactions/date');
          const posStatus = get(newState, 'pos-status');
          const posDailyReport = getIn(newState, ['pos', 'daily-report', reportsTransactionsDate]);
          this.setState({
            openDay: getOpenDay(newState),
            dialog: getIn(newState, ['reports', 'transactions', 'dialog']),
          });
          const shouldRefetchAll = (openDayChanged || venueChanged) && posStatus && posDailyReport;
          maybeFetchPOSStatus(posStatus, shouldRefetchAll);
          maybeFetchPosDailyReport(reportsTransactionsDate, posDailyReport, shouldRefetchAll);
        },
        vector(
          ['venueId'],
          ['pos-status'],
          ['pos', 'daily-report'],
          ['reports/transactions/date'],
          ['reports/transactions/date'],
          ['reports', 'transactions'],
        ),
        cursorValueHasChanged,
      ),
    );
    if (App.config.get('venue').pointOfSaleEnabled) {
      fetchPosStatus();
      if (Wahanda.Permissions.viewFinanceData()) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        fetchPosDailyReport();
      }
      // Re-fetch the POS Status after a checkout
      App.on(Wahanda.Event.APPOINTMENT_CHECKED_OUT, () => {
        fetchPosStatus();
        clearPOSDailyReportFetchCache(now());
      });
    }
  }

  componentWillUnmount() {
    appState.removeWatch('calendar-pos-dropdown');
    App.off(Wahanda.Event.APPOINTMENT_CHECKED_OUT, fetchPosStatus);
  }

  render() {
    if (!App.config.get('venue').pointOfSaleEnabled) {
      // POS Full not enabled.
      return null;
    }
    const { dialog } = this.state;
    const items = getDropdownItems();

    if (!items?.length) {
      return null;
    }

    return (
      <div className="pos-dropdown-box pos-dropdown">
        <ButtonDropdown
          colour="pos"
          variant="secondary"
          size="small"
          fullWidth
          items={items}
          label={lang.title}
          icon={<Pos />}
        />
        {dialog}
      </div>
    );
  }
}
