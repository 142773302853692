import { trackEvent, ACTIONS } from 'common/analytics';
import { L2Page } from 'common/tracking/navigation';

export interface SettingsTrackers {
  trackHowItWorksClick: (property: string) => void;
  trackSettingChange: (property: string, value: boolean) => void;
  trackAreYouSureView: (property: string) => void;
  trackAreYouSureClose: (property: string) => void;
}

export const Analytics = (CATEGORY: L2Page): SettingsTrackers => ({
  trackHowItWorksClick: (property: string) => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'how-it-works', property);
  },
  trackSettingChange: (property: string, value: boolean) => {
    const label = value ? 'enable' : 'disable';
    trackEvent(CATEGORY, ACTIONS.CLICK, label, property);
  },
  trackAreYouSureView: (property: string) => {
    trackEvent(CATEGORY, ACTIONS.VIEW, 'are-you-sure', property);
  },
  trackAreYouSureClose: (property: string) => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'are-you-sure-close', property);
  },
});
