import React from 'react';
import Markdown from 'markdown-to-jsx';
import styles from './index.scss';
import PhotoIcon from './PhotoIcon';

export const PhotosGuideSidebar = () => {
  return (
    <div className={styles['photos-guide-sidebar']}>
      <div className={styles['photos-guide-title']}>
        <PhotoIcon />
        <span>{Wahanda.lang.settings.venue.photosGuide.title}</span>
      </div>
      <div className={styles['photos-guide-content']}>
        <Markdown>{Wahanda.lang.settings.venue.photosGuide.content}</Markdown>
      </div>
      <a
        className={styles['photos-guide-link']}
        href={Wahanda.lang.settings.venue.photosGuide.link.href}
      >
        {Wahanda.lang.settings.venue.photosGuide.link.label}
      </a>
    </div>
  );
};
