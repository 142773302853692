require('../../../../app/assets/javascripts/wahanda/platform.js');

(function() {
    // This is a fix (temporary, hopefully) for iPad on iOS 7 to work around incorrect window
    // sizes in Mobile Safari.
    // It works together with a CSS media query in ipad-ios7-orientation-fix.css
    // so when removing this, remove that one also.
    if (isTargetBrowser()) {
        $('html').addClass('ipad ios7');

        var fixViewportHeight = function() {
            if (document.body.scrollTop !== 0) {
                window.scrollTo(0, 0);
            }
        };

        window.addEventListener("orientationchange", fixViewportHeight, false);
    }
    function isTargetBrowser() {
        if (Wahanda.platform === 'IPAD') {
            return false;
        }
        var isIpadIos7 = navigator.userAgent.match(/iPad;.*CPU.*OS 7_\d/i);
        return isIpadIos7;
    }
}());
