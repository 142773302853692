import React from 'react';
import { POSDropdown } from './POSDropdown';
import { AddDropdown } from './AddDropdown';

interface ISidebarDropdownsProps extends React.HTMLAttributes<Element> {
  addActions?: {};
}

export class SidebarActions extends React.Component<ISidebarDropdownsProps, {}> {
  render() {
    return (
      <div>
        <POSDropdown />
        <AddDropdown addActions={this.props.addActions} />
      </div>
    );
  }
}
