/* global BackboneEx */
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';

const openUrl = (url) => {
  window.location.href = url;
};

App.Views.IdentityVerification = BackboneEx.View.Main.extend({
  initialize: async function () {
    const params = Wahanda.Url.parseSearch(window.location.search);
    if (!params.returnUrl) {
      openUrl('/error');
    } else {
      try {
        const onboardingResponse = await api.get(
          apiUrl('IDENTITY_VERIFICATION', {
            returnUrl: params.returnUrl,
            supplierId: App.getSupplierId(),
            refreshUrl: window.location.href,
          }),
        );
        openUrl(onboardingResponse.onboardingUrl);
      } catch (e) {
        console.error(e);
        // couldn't find a way to directly render 404 page, so we redirect to a not existing route
        openUrl('/error');
      }
    }
  },
});
