import React from 'react';
import copy from 'copy-to-clipboard';
import { AnimatedTick } from 'components/common/AnimatedTick';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';

import style from './style.scss';

const lang = Wahanda.lang.shared.copyToClipboard;

const CLEAR_COPIED_STATE_TIMEOUT = 5000;

interface Props {
  stringToCopy: string;
  onAfterCopy?: () => void;
}

interface State {
  isCopied: boolean;
}

export class CopyToClipboard extends React.PureComponent<Props, State> {
  private timer: number;

  constructor(props: Props) {
    super(props);

    this.state = {
      isCopied: false,
    };

    this.onCopy = this.onCopy.bind(this);
    this.timer = 0;
  }

  public componentWillUnmount() {
    clearTimeout(this.timer);
  }

  public render() {
    const { stringToCopy } = this.props;
    const { isCopied } = this.state;
    return (
      <div className={style.container} onClick={this.onCopy}>
        <div className={style.copyToClipboard}>
          <span className={this.getStringContainerStyle()}>{stringToCopy}</span>
          <button type="button" className={style.buttonContainer}>
            {isCopied ? <AnimatedTick colour="white" /> : lang.button}
          </button>
        </div>
        {isCopied && <span className={style.successLabel}>{lang.successLabel}</span>}
      </div>
    );
  }

  private getStringContainerStyle() {
    const { isCopied } = this.state;
    if (isCopied) {
      return classnames(style.stringContainer, style.success);
    }
    return style.stringContainer;
  }

  private onCopy() {
    const { stringToCopy } = this.props;
    copy(stringToCopy);
    this.setState({ isCopied: true });
    this.timer = window.setTimeout(() => {
      this.setState({ isCopied: false });
    }, CLEAR_COPIED_STATE_TIMEOUT);
    if (this.props.onAfterCopy) {
      this.props.onAfterCopy();
    }
  }
}
