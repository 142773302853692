import React from 'react';
import { Tooltip } from 'components/common/Tooltip';
import { Button } from 'components/common/Button';
import { Eye } from 'components/common/Icon';
import { ComputerBooking } from 'components/common/IconPicture';

import BaseSection from './';
import style from './BaseSection.scss';

// @ts-expect-error ts-migrate(2430) FIXME: Type '{ title: string; introduction: string; label... Remove this comment to see the full error message
interface IIntroSectionProps extends React.HTMLAttributes<Element> {
  widgetEnabled?: boolean;
  lang: {
    title: string;
    introduction: string;
    labels: {
      preview: string;
      disable: string;
      enable: string;
    };
    learnMore?: string;
    learnMoreHref?: string;
    note: {
      title?: string;
      noCommissionText?: string;
    };
    seeMore?: string;
    tooltips: {
      enable?: string;
    };
  };
  allowToDisableWidget: boolean;
  widgetSettings: {
    saving?: boolean;
    widgetLink?: string;
  };
  onWidgetEnabledToggle: (...args: any[]) => any;
  onLearnMoreClick: (...args: any[]) => any;
  onPreviewClick: (...args: any[]) => any;
}
class IntroSection extends React.PureComponent<IIntroSectionProps, {}> {
  static defaultProps = {
    widgetEnabled: undefined,
    widgetSettings: {
      saving: false,
      widgetLink: null,
    },
  };

  constructor(props) {
    super(props);
    this.onPreviewClick = this.onPreviewClick.bind(this);
  }

  onPreviewClick() {
    this.props.onPreviewClick('intro');
  }

  render() {
    const {
      widgetEnabled,
      lang,
      allowToDisableWidget,
      widgetSettings,
      onWidgetEnabledToggle,
      onLearnMoreClick,
    } = this.props;
    return (
      <BaseSection icon={<ComputerBooking />}>
        <h1>{lang.title}</h1>
        <p>
          {lang.introduction}{' '}
          {lang.learnMore && lang.learnMoreHref && (
            <a
              href={lang.learnMoreHref}
              onClick={onLearnMoreClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang.learnMore}
            </a>
          )}
        </p>
        <p>
          <strong>{lang.note.title}</strong>
        </p>
        <p>{lang.note.noCommissionText}</p>
        {lang.seeMore && <p>{lang.seeMore}</p>}
        {widgetEnabled ? (
          <div className={style.actions}>
            {widgetSettings.widgetLink && (
              <div className={style.left}>
                <Button
                  variant="secondary"
                  colour="plain"
                  onClick={this.onPreviewClick}
                  label={lang.labels.preview}
                  icon={<Eye />}
                />
              </div>
            )}
            {allowToDisableWidget && (
              <div className={style.right}>
                <Button
                  colour="plain"
                  disabled={widgetSettings.saving}
                  onClick={onWidgetEnabledToggle}
                  label={lang.labels.disable}
                />
              </div>
            )}
          </div>
        ) : (
          widgetEnabled !== undefined && (
            <div className={style.actions}>
              <Tooltip tooltip={`${lang.labels.enable}-${lang.tooltips.enable}`} placement="right">
                <div className={style.left}>
                  <Button
                    disabled={widgetSettings.saving}
                    onClick={onWidgetEnabledToggle}
                    label={lang.labels.enable}
                  />
                </div>
              </Tooltip>
            </div>
          )
        )}
      </BaseSection>
    );
  }
}

export default IntroSection;
