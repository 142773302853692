export const SALESFORCE_LOCALES = {
  en: 'en',
  en_US: 'en_US',
  zh_CN: 'zh_CN',
  zh_TW: 'zh_TW',
  da: 'da',
  nl_NL: 'nl_NL',
  fi: 'fi',
  fr: 'fr',
  de: 'de',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  no: 'no',
  pt_BR: 'pt_BR',
  ru: 'ru',
  es: 'es',
  es_MX: 'es_MX',
  sv: 'sv',
  th: 'th',
  ar: 'ar',
  bg: 'bg',
  hr: 'hr',
  cs: 'cs',
  en_GB: 'en_GB',
  el: 'el',
  iw: 'iw',
  hu: 'hu',
  in: 'in',
  pl: 'pl',
  pt_PT: 'pt_PT',
  ro: 'ro',
  sk: 'sk',
  sl: 'sl',
  tr: 'tr',
  uk: 'uk',
  vi: 'vi',
  sq: 'sq',
  af: 'af',
  am: 'am',
  ar_DZ: 'ar_DZ',
  ar_BH: 'ar_BH',
  ar_EG: 'ar_EG',
  ar_IQ: 'ar_IQ',
  ar_JO: 'ar_JO',
  ar_KW: 'ar_KW',
  ar_LB: 'ar_LB',
  ar_LY: 'ar_LY',
  ar_MA: 'ar_MA',
  ar_OM: 'ar_OM',
  ar_QA: 'ar_QA',
  ar_SA: 'ar_SA',
  ar_SD: 'ar_SD',
  ar_SY: 'ar_SY',
  ar_TN: 'ar_TN',
  ar_AE: 'ar_AE',
  ar_YE: 'ar_YE',
  hy: 'hy',
  eu: 'eu',
  bs: 'bs',
  bn: 'bn',
  my: 'my',
  ca: 'ca',
  zh_HK: 'zh_HK',
  zh_SG: 'zh_SG',
  zh_MY: 'zh_MY',
  cac: 'cac',
  nl_BE: 'nl_BE',
  en_AU: 'en_AU',
  en_BE: 'en_BE',
  en_CA: 'en_CA',
  en_CY: 'en_CY',
  en_DE: 'en_DE',
  en_HK: 'en_HK',
  en_IN: 'en_IN',
  en_IE: 'en_IE',
  en_IL: 'en_IL',
  en_IT: 'en_IT',
  en_MY: 'en_MY',
  en_MT: 'en_MT',
  en_NL: 'en_NL',
  en_NZ: 'en_NZ',
  en_PH: 'en_PH',
  en_SG: 'en_SG',
  en_ZA: 'en_ZA',
  en_AE: 'en_AE',
  et: 'et',
  fa: 'fa',
  fr_BE: 'fr_BE',
  fr_CA: 'fr_CA',
  fr_LU: 'fr_LU',
  fr_MA: 'fr_MA',
  fr_CH: 'fr_CH',
  ka: 'ka',
  de_AT: 'de_AT',
  de_BE: 'de_BE',
  de_LU: 'de_LU',
  de_CH: 'de_CH',
  el_CY: 'el_CY',
  kl: 'kl',
  gu: 'gu',
  haw: 'haw',
  ht: 'ht',
  hi: 'hi',
  hmn: 'hmn',
  is: 'is',
  ga: 'ga',
  it_CH: 'it_CH',
  cak: 'cak',
  kn: 'kn',
  kk: 'kk',
  km: 'km',
  quc: 'quc',
  lv: 'lv',
  lt: 'lt',
  lb: 'lb',
  mk: 'mk',
  ms: 'ms',
  ml: 'ml',
  mt: 'mt',
  mr: 'mr',
  sh_ME: 'sh_ME',
  pa: 'pa',
  ro_MD: 'ro_MD',
  rm: 'rm',
  ru_AM: 'ru_AM',
  ru_BY: 'ru_BY',
  ru_KZ: 'ru_KZ',
  ru_KG: 'ru_KG',
  ru_LT: 'ru_LT',
  ru_MD: 'ru_MD',
  ru_PL: 'ru_PL',
  ru_UA: 'ru_UA',
  sm: 'sm',
  sr: 'sr',
  sh: 'sh',
  es_AR: 'es_AR',
  es_BO: 'es_BO',
  es_CL: 'es_CL',
  es_CO: 'es_CO',
  es_CR: 'es_CR',
  es_DO: 'es_DO',
  es_EC: 'es_EC',
  es_SV: 'es_SV',
  es_GT: 'es_GT',
  es_HN: 'es_HN',
  es_NI: 'es_NI',
  es_PA: 'es_PA',
  es_PY: 'es_PY',
  es_PE: 'es_PE',
  es_PR: 'es_PR',
  es_US: 'es_US',
  es_UY: 'es_UY',
  es_VE: 'es_VE',
  sw: 'sw',
  tl: 'tl',
  ta: 'ta',
  mi: 'mi',
  te: 'te',
  ur: 'ur',
  cy: 'cy',
  xh: 'xh',
  ji: 'ji',
  zu: 'zu',
};

export const FAKE_MASQUERADING_EMAIL = 'masquerading@treatweall.com';
