var CacheBase = function() {
    // Each imlementation must implement these methods:
    // this.fetch( /* params to .get() */ )
    // this.isCached( /* params to .get() */ )
    // this.mergeToCache(fetchResult)
};

// Implement inheritance the Backbone way
CacheBase.extend = Backbone.Model.extend;

// Helper fn to create promises which the get() method returns
CacheBase.prototype.createPromise = function(handlerFn) {
    var drd = new $.Deferred();
    handlerFn.call(this, drd);
    return drd.promise();
};

// Method for getting the cached values, or fetching new ones
CacheBase.prototype.get = function() {
    var params = arguments;
    return this.createPromise(function(deferred) {
        var self = this;
        function resolveWithCachedValue() {
            deferred.resolve(
                self.getCached.apply(self, params)
            );
        }

        if (this.isCached.apply(this, params)) {
            resolveWithCachedValue();
        } else {
            this.fetch.apply(this, params)
                .done(resolveWithCachedValue)
                .fail(deferred.reject);
        }
    });
};

module.exports = CacheBase;
