import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { LoginPage } from './index';

interface Params {
  node: HTMLElement;
  route: string;
}

export const LoginPageInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(params.node),
  render: () => {
    ReactDOM.render(<LoginPage route={params.route} />, params.node);
  },
});
