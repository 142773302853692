/**
 * After-save question qTip.
 * 
 * @param {Object} options
 * > content          HTML content
 * > title            Title of the tip
 * > saveAction       (optional) Function to execute when the user wants to save anyway
 * > cancelAction     (optional) Function to call when the user cancels
 * > saveButtonText   (optional) Name of the save button
 * > cancelButtonText (optional) Name of cancel button
 * > saveButtonClass  (optional) The class for save button
 * > iconClass        (optional) The class for the icon
 */
$.fn.saveQuestionTip = function(options) {
	var html = Wahanda.Template.renderTemplate(
		"save-question-tip-template",
		{
			title       : options.title,
			htmlContent : options.content,
			save        : options.saveButtonText || Wahanda.lang.shared.saveQuestionTip.buttons.save,
			cancel      : options.cancelButtonText || Wahanda.lang.shared.saveQuestionTip.buttons.cancel,
			saveBtnClass: options.saveButtonClass || 'button-primary',
			iconClass   : options.iconClass || 'tick'
		}
	);
	html = $(html);
	
	var tooltip = this.qtip({
		content: html,
		style: {
			classes: "dialog-tooltip"
		},
		position: {
			my: "bottom left",
			at: "top center"
		},
		show: {
			event: false,
			ready: true
		},
		hide: {
			event: false,
			fixed: true
		}
	});
	
	html
		.find('button[type=submit]').on('click', function() {
			tooltip.qtip('destroy');
			_.isFunction(options.saveAction) && options.saveAction();
		}).end()
		.find('.button-cancel').on('click', function() {
			tooltip.qtip('destroy');
			_.isFunction(options.cancelAction) && options.cancelAction();
		});
	
	return this;
};
