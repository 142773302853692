import React from 'react';

interface Props {
  closingBalance: number;
  supplierId: number;
  actions: {
    setupStripe: (data: { supplierId: number; closingBalance: number }) => void;
    resetStripe: () => void;
  };
}

export default class StripeProvider extends React.PureComponent<Props> {
  public componentDidMount() {
    const { supplierId, closingBalance } = this.props;
    this.props.actions.setupStripe({ supplierId, closingBalance });
  }

  public componentWillUnmount() {
    this.props.actions.resetStripe();
  }

  public render() {
    return this.props.children;
  }
}
