import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import moment from 'moment-timezone';

moment.fn.formatDayMonth = function formatDayMonth() {
  return this.format('DD.MM');
};

moment.fn.formatDateDefault = function formatDateDefault() {
  return this.format(App.config.get('momentDateFormat').defaultDate);
};

moment.fn.formatTimeDefault = function formatTimeDefault() {
  return this.format(App.config.get('momentDateFormat').defaultTime);
};

moment.fn.formatFullDateTime = function formatFullDateTime() {
  return `${this.formatDateDefault()} ${this.formatTimeDefault()}`;
};

moment.fn.formatLongDate = function formatWeekday() {
  return this.format(`${App.config.get('momentDateFormat').longDate}`);
};

moment.fn.formatLongLocaleDate = function formatLongLocaleDate() {
  return this.format(`ddd ${App.config.get('momentDateFormat').longDate}`);
};

moment.fn.formatLongDateWithDay = function formatLongLocaleDate() {
  return this.format(`dddd, ${App.config.get('momentDateFormat').longDate}`);
};

moment.fn.formatMediumLocaleDate = function formatWeekday() {
  return this.format(`dddd, ${App.config.get('momentDateFormat').mediumDate}`);
};

moment.fn.formatApiDateString = function formatApiDateString() {
  return this.format('YYYY-MM-DD');
};

moment.fn.formatWeekday = function formatWeekday() {
  return this.format('dddd');
};

moment.fn.formatMonth = function formatMonth() {
  return this.format('MMMM');
};

moment.fn.formatMonthYear = function formatMonthYear() {
  return this.format('MMMM YYYY');
};

/**
 * Get moment instance with adjusted time to current venue time.
 *
 * @return {moment} The date
 */
moment.venueTimeZoneDate = function venueTimeZoneDate() {
  return moment(Wahanda.Date.createVenueDate());
};

// Set the correct locale
App.once(Wahanda.Event.APP_CONFIG_LOADED, () => {
  const pathPattern = /^\/([a-z]{2}(?:_[A-Z]{2})?)\//;
  const pathLocale = window.location.pathname.match(pathPattern);
  let locale = App.config.get('locale');

  locale = pathLocale ? pathLocale[1] : locale;
  locale = locale.replace('_', '-');
  moment.locale(locale);
});

declare module 'moment' {
  export function venueTimeZoneDate(): Moment;
  interface Moment {
    formatDayMonth(): string;
    formatDateDefault(): string;
    formatTimeDefault(): string;
    formatFullDateTime(): string;
    formatMediumLocaleDate(): string;
    formatLongDateWithDay(): string;
    formatLongDate(): string;
    formatLongLocaleDate(): string;
    formatApiDateString(): string;
    formatWeekday(): string;
    formatMonth(): string;
    formatMonthYear(): string;
    venueTimeZoneDate(): moment.Moment;
  }
}

export default moment;
