import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class VenueSearch extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-venue-search', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M13.6343374,2 C14.6607224,2 15.6013314,2.78263734 15.8157541,3.87130176 L15.9931863,5.38346266 L14.0068137,5.61653734 L13.8419871,4.18395524 C13.8259555,4.10379712 13.7012074,4 13.6343374,4 L2.99066257,4 C2.9262072,4 2.7986693,4.10567301 2.78301286,4.18395524 L2.11905449,7.50374705 L2.06205044,7.69104482 C2.02090294,7.7901619 2,7.89341389 2,8 C2,8.52433327 2.53512508,9 3.25,9 C3.63967203,9 3.99250826,8.85537157 4.22789469,8.6183128 L4.9375,7.90366602 L5.64710531,8.6183128 C5.88249174,8.85537157 6.23532797,9 6.625,9 L6.625,11 C6.02041272,11 5.43917956,10.846481 4.9375,10.5653554 C4.64815923,10.7274929 4.33235624,10.8471842 4.0008726,10.9194581 L4,17.0015323 L8,17 L8,19 L4.0093689,19 C2.8954572,19 2,18.1102792 2,17.0029699 L2.00009149,10.770022 C0.834559236,10.3222018 0,9.26533989 0,8 C0,7.66482831 0.0598141182,7.33632493 0.175087164,7.02554467 L0.821851504,3.79172297 C1.02362727,2.78284411 1.96845033,2 2.99066257,2 L13.6343374,2 Z M19.7071068,17.2928932 L23.7071068,21.2928932 L22.2928932,22.7071068 L18.2928932,18.7071068 L19.7071068,17.2928932 Z M14,7 C17.3137085,7 20,9.6862915 20,13 C20,16.3137085 17.3137085,19 14,19 C10.6862915,19 8,16.3137085 8,13 C8,9.6862915 10.6862915,7 14,7 Z M14,9 C11.790861,9 10,10.790861 10,13 C10,15.209139 11.790861,17 14,17 C16.209139,17 18,15.209139 18,13 C18,10.790861 16.209139,9 14,9 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(VenueSearch);
