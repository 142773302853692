import React from 'react';
import Wahanda from 'common/wahanda';
import { LogoTreatwell } from 'components/common/IconPicture';

import style from './style.scss';

function Error404() {
  document.title = Wahanda.lang.shared.errors.pageMissingTitle;
  return (
    <div className={style.error404}>
      <LogoTreatwell className={style.logo} />
      <div className={style.textblock}>
        <p
          dangerouslySetInnerHTML={{
            __html: Wahanda.lang.shared.errors.pageMissing,
          }}
        />
      </div>
    </div>
  );
}

export default Error404;
