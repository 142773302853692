import React from 'react';
import Wahanda from 'common/wahanda';

import style from './FillInDetails.scss';
import { ImportFileExample } from './ImportFileExample';
import { openChat } from 'utilities/chat';

const lang = Wahanda.lang.clientsImport.addMultipleClients.fillInDetails;
const LIVE_CHAT_SOURCE = 'clients import';

export class FillInDetails extends React.PureComponent<{}> {
  public render() {
    return (
      <div className={style.container}>
        <span className={style.header}>{lang.header}</span>
        <ul className={style.bulletList}>
          <li dangerouslySetInnerHTML={{ __html: lang.mandatoryFields }} />
          <li dangerouslySetInnerHTML={{ __html: lang.gdpr }} />
          <li>
            <span
              dangerouslySetInnerHTML={{
                __html: lang.visitHelpCentre,
              }}
            />
            <a onClick={this.openLiveChat}>{lang.contactUs}</a>
          </li>
        </ul>
        <ImportFileExample className={style.fileExample} />
      </div>
    );
  }

  private openLiveChat = () => {
    openChat(LIVE_CHAT_SOURCE);
  };
}
