/* global BackboneEx */
App.Views.Forms.OpeningTimes = BackboneEx.View.Dialog.extend({
  events: {
    'click button[type=submit]': 'save',
  },
  templateId: 'on-board-wizard-step4-template',

  options: {
    width: 410,
    /**
     * Function to call when user clicks "Save"
     */
    saveCallback: null,
    dataTest: 'wizard-opening-times-modal',
  },

  postInitialize() {
    this.openingHoursView = new App.Views.Partials.OpeningHours({
      model: this.model,
    });
  },

  setupDialog() {
    this.renderTitle();
    this.openingHoursView.setElement(this.$('.week'));
    this.openingHoursView.render();
  },

  save() {
    this.openingHoursView.ifValid(() => {
      this.disableForm();
      this.options.saveCallback(this.openingHoursView.getValues(), this);
    });
  },

  renderTitle() {
    this.$('.dialog-head h1').html(
      Wahanda.Template.render(Wahanda.lang.onboardingWizzard.dialog.openingTimes.title, {
        venue: `<span class="highlight">${App.config.get('venue').name}</span>`,
      }),
    );
  },
});
