import React from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import { Notice } from 'components/common/Notice';
import Dialog from 'components/common/react-dialog';
import Loader from 'components/common/Loader';
import { DatePicker } from 'components/common/DatePicker';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import { Employee, SetEmployeeTimeOffPayload, DateRangeObject } from '../utils/types';
import { dateRange, isBetweenVisibleRange } from '../utils/helpers';
import { MAX_DAYS_RANGE } from '../utils/constants';
import { ShiftsAnalytics } from '../tracking';
import { ShiftModalHeader } from '../ShiftModalHeader';
import style from './ShiftTimeOffModal.scss';

interface Props {
  dateFrom: moment.Moment;
  onClose: () => void;
  trackingCategory?: string | null;
  // redux
  actions: {
    setEmployeesTimeOff: (param: SetEmployeeTimeOffPayload) => void;
  };
  employee: Employee;
  datePickerRange: DateRangeObject;
  isSavingShift: boolean;
}

export const ShiftTimeOffModal = ({
  actions,
  employee,
  datePickerRange,
  dateFrom,
  isSavingShift,
  onClose,
  trackingCategory,
}: Props) => {
  const title = Wahanda.lang.settings.shifts.modalEmployeeActions.addTimeOff;
  const [startDate, setStartDate] = React.useState(dateFrom);
  const [endDate, setEndDate] = React.useState(dateFrom);
  const { employeeId, employeeImage, employeeName } = employee;
  const observer = React.useRef(false);
  const selectedDaysCount = endDate.diff(startDate, 'days') + 1;
  const isMaxTimeOffSelected = selectedDaysCount >= MAX_DAYS_RANGE;
  const isSubmitDisabled = selectedDaysCount > MAX_DAYS_RANGE;
  React.useEffect(() => {
    if (observer.current && !isSavingShift) {
      handleClose(false);
    } else {
      observer.current = true;
    }
  }, [isSavingShift]);
  React.useEffect(() => {
    ShiftsAnalytics.trackShiftTimeOffModalView({
      category: trackingCategory,
    });
  }, []);

  function onDatePickerChange(start, end) {
    if (startDate !== moment(start) || endDate !== moment(end)) {
      setStartDate(start);
      setEndDate(end);
    }
  }

  function handleSubmit() {
    const isBetween = isBetweenVisibleRange({
      datePickerRange,
      startDate,
      endDate,
    });
    const dates = dateRange({
      dateFrom: startDate.formatApiDateString(),
      dateTo: endDate.formatApiDateString(),
    });
    const data = {
      employeeId,
      dates,
      dateFrom: isBetween ? datePickerRange.dateFrom : undefined,
      dateTo: isBetween ? datePickerRange.dateTo : undefined,
    };
    actions.setEmployeesTimeOff(data);
    ShiftsAnalytics.trackShiftTimeOffModalSubmit({
      category: trackingCategory,
      property: dates.length,
    });
  }

  function handleClose(event) {
    if (event) {
      ShiftsAnalytics.trackShiftTimeOffModalClose({
        category: trackingCategory,
      });
    }
    onClose();
  }

  const maxTimeOffDaysMessage = Wahanda.Template.render(
    Wahanda.lang.settings.shifts.modalEmployeeActions.timeOffLimitInfo,
    { count: MAX_DAYS_RANGE },
  );
  return (
    <Dialog
      dataTest="shift-time-off-modal"
      width={400}
      title={title}
      onClose={handleClose}
      noContentPadding
      noContentTopBorders
      noOverflow
    >
      <div className={style.container}>
        <ShiftModalHeader image={employeeImage} title={employeeName} />
        <div className={style.dates}>
          <DatePicker
            labelStartDate={Wahanda.lang.settings.shifts.timeSlotFrom}
            labelEndDate={Wahanda.lang.settings.shifts.timeSlotTo}
            initialStartDate={dateFrom}
            initialEndDate={dateFrom}
            maxRangeDays={MAX_DAYS_RANGE}
            onChange={onDatePickerChange}
            uniqueId="shifts-time-off"
            readOnly
          />
        </div>

        {isMaxTimeOffSelected && (
          <div className={style.attentionContainer}>
            <Notice type="info" message={maxTimeOffDaysMessage} />
          </div>
        )}
        {isSavingShift && <Loader positionAbsolute />}
      </div>
      <DialogFooter>
        <DialogFooterButton
          title={Wahanda.lang.shared.buttons.cancel}
          type="secondary"
          onClick={handleClose}
        />
        <DialogFooterButton
          dataTest="time-off-modal-button-save"
          title={Wahanda.lang.shared.buttons.save}
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        />
      </DialogFooter>
    </Dialog>
  );
};
