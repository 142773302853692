import React from 'react';
import moment from 'common/moment';
import SelectDropdown from 'components/common/SelectDropdown';
import { DatePickerWithPresets } from 'components/common/DatePicker';
import { EmployeeFilter } from '../../EmployeeFilter/container';
import BookingChannelFilter from '../../BookingChannelFilter';
import style from './style.scss';

export interface PossibleFilters {
  timePeriod?: string;
  fromDate?: moment.Moment;
  toDate?: moment.Moment;
  selectedChannel?: string;
  selectedEmployeeId?: number;
}

export interface Props {
  date?: {
    uniqueId: string;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    presetRanges?: any;
    monthlyGranularity?: boolean;
  };
  channel?: {
    selectedChannel: string;
  };
  employee?: {
    selectedEmployeeId: number;
  };
  onFilterChange: (filters: PossibleFilters) => void;
  onDatePickerChange?: (...args: any[]) => void;
  onChannelChange?: (...args: any[]) => void;
  onEmployeeChange?: (...args: any[]) => void;
}

interface IFiltersProps extends React.HTMLAttributes<Element> {
  period?: {
    selected?: string;
    data?: {
      name: string;
      value: string;
      fromDate?: string;
      toDate?: string;
    }[];
  };
  date?: {
    uniqueId: string;
    fromDate: any;
    toDate: any;
    presetRanges?: any;
    monthlyGranularity?: boolean;
  };
  channel?: {
    selectedChannel: string;
  };
  employee?: {
    selectedEmployeeId: number;
    categoryFilterEnabled?: boolean;
  };
  onPeriodChange?: (...args: any[]) => any;
  onFilterChange: (...args: any[]) => any;
  onDatePickerChange?: (...args: any[]) => any;
  onChannelChange?: (...args: any[]) => any;
  onEmployeeChange?: (...args: any[]) => any;
  selectedEmployeeId?: any;
  categoryFilterEnabled?: any;
  selectedChannel?: any;
  monthlyGranularity?: any;
  presetRanges?: any;
  toDate?: any;
  fromDate?: any;
  uniqueId?: any;
  data?: any;
  selected?: any;
}

export class Filters extends React.Component<IFiltersProps, {}> {
  static defaultProps = {
    period: null,
    date: null,
    employee: null,
    channel: null,
    onDatePickerChange: null,
    onChannelChange: null,
    onEmployeeChange: null,
    onPeriodChange: null,
  };

  onEmployeeFilterChange = (selectedEmployeeId) => {
    const { onEmployeeChange } = this.props;
    if (onEmployeeChange) {
      onEmployeeChange(selectedEmployeeId);
    }
    this.updateFilters({ selectedEmployeeId });
  };

  onChannelFilterChange = (selectedChannel) => {
    const { onChannelChange } = this.props;
    if (onChannelChange) {
      onChannelChange(selectedChannel);
    }
    this.updateFilters({ selectedChannel });
  };

  onDatePickerChange = (fromDate, toDate, type) => {
    if (this.props.onDatePickerChange) {
      this.props.onDatePickerChange({ fromDate, toDate, type });
    }
    this.updateFilters({ fromDate, toDate });
  };

  onTimePeriodChange = (timePeriod) => {
    if (this.props.onPeriodChange) {
      this.props.onPeriodChange(timePeriod);
    }
    this.updateFilters({ timePeriod });
  };

  updateFilters = (newValues) => {
    const { date, channel, employee, period } = this.props;
    const filters = {};
    if (date) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fromDate' does not exist on type '{}'.
      filters.fromDate = date.fromDate;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'toDate' does not exist on type '{}'.
      filters.toDate = date.toDate;
    }
    if (channel) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedChannel' does not exist on type ... Remove this comment to see the full error message
      filters.selectedChannel = channel.selectedChannel;
    }
    if (employee) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedEmployeeId' does not exist on ty... Remove this comment to see the full error message
      filters.selectedEmployeeId = employee.selectedEmployeeId;
    }
    if (period) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'timePeriod' does not exist on type '{}'.
      filters.timePeriod = period.selected;
    }
    Object.assign(filters, newValues);
    this.props.onFilterChange(filters);
  };

  render = () => (
    <div className={style.filters}>
      {this.props.period && (
        <div className={style.filter}>
          <SelectDropdown
            dataTest="date-filter"
            extraClassName={style.timePeriod}
            onSelect={this.onTimePeriodChange}
            selected={this.props.period.selected}
            data={this.props.period.data}
          />
        </div>
      )}
      {this.props.date && (
        <div className={style.filter}>
          <DatePickerWithPresets
            uniqueId={this.props.date.uniqueId}
            onChange={this.onDatePickerChange}
            initialStartDate={this.props.date.fromDate}
            initialEndDate={this.props.date.toDate}
            presetRanges={this.props.date.presetRanges}
            monthlyGranularity={this.props.date.monthlyGranularity}
          />
        </div>
      )}
      {this.props.channel && (
        <div className={style.filter}>
          <BookingChannelFilter
            selected={this.props.channel.selectedChannel}
            onChange={this.onChannelFilterChange}
          />
        </div>
      )}
      {this.props.employee && (
        <div className={style.filter}>
          <EmployeeFilter
            categoryFilterEnabled={this.props.employee.categoryFilterEnabled}
            selected={this.props.employee.selectedEmployeeId}
            onChange={this.onEmployeeFilterChange}
          />
        </div>
      )}
    </div>
  );
}

export default Filters;
