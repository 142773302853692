import React, { PureComponent } from 'react';

export const ANGLE = {
  UP: 'UP',
  DOWN: 'DOWN',
};

interface IChevronSmallProps extends React.HTMLAttributes<Element> {
  angle?: any;
  className?: string;
}
class ChevronSmall extends PureComponent<IChevronSmallProps, {}> {
  static defaultProps = {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'LEFT' does not exist on type '{ UP: stri... Remove this comment to see the full error message
    angle: ANGLE.LEFT,
    className: null,
  };

  getRotateAngle() {
    const { angle } = this.props;
    switch (angle) {
      case ANGLE.UP:
        return 180;
      case ANGLE.DOWN:
        return 0;
      default:
        return 0;
    }
  }

  render() {
    const { className } = this.props;
    const rotate = this.getRotateAngle();
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        style={{
          transform: `rotate(${rotate}deg)`,
        }}
      >
        <g fill="none" fillRule="evenodd">
          <path stroke="none" d="M0 0h24v24H0z" />
          <path
            className={className}
            stroke="#B3B3B3"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7 10l5 5 5-5"
          />
        </g>
      </svg>
    );
  }
}

export default ChevronSmall;
