/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* global BackboneEx _ */
import { formatInternationalFromInput } from 'common/phonenumber';
import { trackEvent } from 'common/analytics';
import ROLES from 'common/constants/userRoles';
/**
 * Employee form.
 */
App.Views.Forms.Employee = BackboneEx.View.Dialog.ValidatingForm.extend({
  templateId: 'employee-form',
  events: {
    'click .button-cancel, .a-cancel': 'cancel',
    'click .delete': 'removeImage',
    'click .person-pic.editable': 'openUploadImageWindow',
    'click #employees-employee-canLogin': 'togglePermissions',
    'change #employees-employee-takesAppointments': 'toggleAppointmentTakingDependants',
    'click .delete-action': 'onDelete',
    'click #link-ext-calendar': 'openCalendarSubscribeWindow',
    'click #unlink-ext-calendar': 'openCalendarUnsubscribeWindow',
    'click .send-password': 'passwordResetHandler',
    'change #employees-employee-employeeCategoryId': 'onEmployeeCategoryChange',
    // Menu events
    'click #employees-employee-doesAllOffers': 'toggleMenuDoesAllOffers',
    'click .select-all:not(.disabled)': 'menuSelectAllOffers',
    'click .deselect-all:not(.disabled)': 'menuDeselectAllOffers',
  },

  fieldPrefix: '/employees-employee/',
  lastValueKey: 'menu-last-checked',
  wasArchived: false,

  options: {
    width: 800,
    title: Wahanda.lang.settings.employees.employee.title,
  },

  getDialogTemplateVariables: function () {
    const taAllowed =
      App.config.get('venue').pointOfSaleEnabled || this.model.get('role') === ROLES.TAX_AUDITOR;

    return {
      taxAuditorAllowed: taAllowed,
      active: this.model.isActive(),
    };
  },

  deleteErrorText: function (xhr) {
    if (Wahanda.Util.isUserErrorCode(xhr.status)) {
      return Wahanda.lang.settings.employees.employee.errors.deletingEmployeeInUse;
    }
    return Wahanda.lang.shared.deleteFailed;
  },

  postInitialize: function () {
    if (!this.model.isActive()) {
      this.$('.save-action')
        .find('.msg-action-default')
        .text(Wahanda.lang.settings.employees.employee.buttons.unarchive);
    }

    this.setupPlaceholder();
    this.setupCategories();
    this.setupMenu();

    this.setupPermissionsDropdown();

    this.$('.delete-action').wHide();
    // Set the height to almost all screen
    this.options.height = $(window).height() - 40;
    this.setValidation();
    this.previousSelectedCategory = null;
    const self = this;
    if (this.model.get('permissionsUpdated')) {
      self.enableForm();
      self.initForm();
      if (self.model.get('supplierBound')) {
        self.$('#employees-employee-canLogin').prop('checked', true).prop('disabled', true);
        self.$('tr.permissions select').prop('disabled', true);
        self.$('tr.permissions a').hide();
      } else if (
        App.config.get('account') &&
        $.inArray('edit-user-permissions', App.config.get('account').permissions) < 0
      ) {
        self.$('tr.permissions a').hide();
      }
      const currentUserId = App.config.getAccountEmployeeId();
      if (this.model.id && this.model.id === currentUserId) {
        this.$('#employees-employee-emailAddress').prop('disabled', 'disabled');
        this.model.set('loggedInUser', true);
      }
    } else if (this.model.id) {
      this.disableForm();
      this.model.fetch({
        success: function () {
          self.enableForm();
          self.initForm();
          self.wasArchived = !self.model.isActive();

          if (self.model.get('supplierBound')) {
            self.$('#employees-employee-canLogin').prop('checked', true).prop('disabled', true);
          }

          self.$('tr.permissions select').val(self.model.get('role'));
          if (self.model.get('supplierBound') || !Wahanda.Permissions.editUserPermissions()) {
            self.$('tr.permissions select').prop('disabled', true);
            self.$('tr.permissions a').hide();
          }
          const currentUserId = App.config.getAccountEmployeeId();
          if (self.model.id === currentUserId) {
            self.$('#employees-employee-emailAddress').prop('checked', true).prop('disabled', true);
            self.model.set('loggedInUser', true);
            self.$('tr.permissions select').prop('disabled', true);
            self.$('tr.permissions a').hide();
            self.$('#employees-employee-canLogin').prop('disabled', true);
          }
        },
      });
    }
    this.options.categoriesCollection.on('add', function (model) {
      self.addEmployeeCategoryToSelect(model);
    });

    this.listenTo(
      App,
      Wahanda.Event.EMPLOYEE_EXT_CALENDAR_SUBSCRIBED,
      this.onCalendarSubscribtionChange,
    );
    this.listenTo(App, Wahanda.Event.EMPLOYEE_IMAGE_UPDATED, this.updateImage);
  },

  initForm: function () {
    this.fillFormFromModel();
    this.renderServices();

    if (this.options.forceTakesAppointments) {
      this.$('#employees-employee-takesAppointments').prop('checked', true).disableFormElements();
    }
    this.emailMonitor();
    this.togglePermissions();
    this.toggleMenuDoesAllOffers();
    this.renderTitle();
    this.updateImage();
    if (this.model.id) {
      this.$('.delete-action').wShow();
      this.$('.person-pic').addClass('editable');
    }
    if (this.model.get('canLogin')) {
      this.$('.permissions').wShow();
    }

    this.toggleExtCalendarLink();
    this.toggleAppointmentTakingDependants();

    this.previousSelectedCategory = this.$('#employees-employee-employeeCategoryId').val();
  },

  toggleExtCalendarLink: function () {
    if (this.model.id == null || this.model.get('linkedExternalSalonName') != null) {
      this.$('.cal-linked, .cal-not-linked').wHide();
      return;
    }

    this.$('.cal-linked').wToggle(this.model.get('externalCalendarUri'));
    this.$('.cal-not-linked').wToggle(!this.model.get('externalCalendarUri'));
  },

  removeImage: function (e) {
    trackEvent('team-member', 'click', 'remove-image');
    e.stopPropagation();
    this.model.set({ imageId: null, image: null });
    this.updateImage();
  },

  openUploadImageWindow: function () {
    trackEvent('team-member', 'click', 'image');
    const view = new App.Views.Forms.Employee.EditImage({
      model: this.model,
    });
    view.render();
    view.open();
  },

  onDialogOpen: function () {
    const rolePermissions = new App.Models.RolePermissions();
    const self = this;
    rolePermissions.fetch({
      success: function (model) {
        self.rolesPermissions = model.get('roles');
      },
    });

    BackboneEx.View.Dialog.ValidatingForm.prototype.onDialogOpen.call(this);
  },

  setupPlaceholder: function () {
    this.$('#employees-employee-name').defaultValue();
  },

  emailMonitor: function () {
    const self = this;
    self.$('#employees-employee-emailAddress').on('keyup', function () {
      if (
        self.emailUnchanged() &&
        self.$('#employees-employee-canLogin').is(':checked') &&
        self.model.get('canLogin')
      ) {
        self.$('button.send-password').wShow();
      } else {
        self.$('button.send-password').wHide();
      }
    });
  },

  setupPermissionsDropdown: function () {
    const roleCode = this.model.get('role');
    const $select = this.$('.permissions select');
    if (!Wahanda.Permissions.editUserPermissions()) {
      $select.prop('disabled', true);
      this.$('tr.permissions a').hide();
    }
    if (roleCode) {
      $select.val(roleCode);
    }
  },

  retrieveDropDownPermissions: function (roleCode) {
    let permissions;
    _.each(this.rolesPermissions, function (role) {
      if (role.role === roleCode) {
        permissions = role.permissions;
      }
    });
    return permissions;
  },

  setupCategories: function () {
    const cats = this.options.categoriesCollection;

    const options = [];
    cats.each(function (cat) {
      options.push({
        value: cat.id,
        title: cat.get('name'),
      });
    });

    let content = `<option value="">${
      Wahanda.lang.settings.employees.employee.noGroupText
    }</option>${Wahanda.Template.renderTemplate('form-select-items', {
      options: options,
    })}`;

    if (!App.config.get('venue').partOfChain) {
      content += '<option disabled="disabled">----</option>';
      content += `<option value="create">${Wahanda.lang.settings.employees.employee.buttons.addCategory}</option>`;
    }

    this.$('#employees-employee-employeeCategoryId').html(content);
  },

  setupMenu: function () {
    const menuGroups = this.options.menu.get('groupsCollection');
    const menuOffers = this.options.menu.get('offersCollection');
    const groups = [];

    menuGroups.each(function (group) {
      let offers = menuOffers.getByGroupId(group.id);
      if (offers) {
        // Strip dated offers.
        offers = _.filter(offers, function (offer) {
          return !offer.isEscape();
        });
      }
      if (!offers || offers.length === 0) {
        // Don't render empty groups
        return;
      }

      const item = {
        groupId: group.id,
        groupName: group.get('name'),
        services: [],
      };

      _.each(offers, function (offer) {
        item.services.push({
          serviceId: offer.id,
          serviceName: offer.get('name'),
          on: false /* TODO */,
          isArchived: !offer.get('visible'),
        });
      });

      groups.push(item);
    });

    this.$('.service-wrapper').html(
      Wahanda.Template.renderTemplate('employee-form-menu', { groups: groups }),
    );
  },

  setValidation: function () {
    const self = this;
    const validations = Wahanda.Validate.getValidations('defaults', {
      submitHandler: function () {
        self.save();
      },
    });

    this.$('form').validate(validations);
  },

  renderServices: function () {
    const offers = this.model.get('employeeOffers');
    const $services = this.$('.service-wrapper').find('input[type=checkbox]');
    const dataKey = this.lastValueKey;
    $services.prop('checked', false).removeAttr('checked').removeData(dataKey);

    if (offers && offers.length > 0) {
      _.each(offers, function (offerId) {
        const $service = $services.filter(`[value=${offerId}]`);
        $service.prop('checked', true).attr('checked', 'checked').data(dataKey, true);
      });
    }
  },

  getModelValues: function () {
    const values = BackboneEx.View.Dialog.ValidatingForm.prototype.getModelValues.call(this);
    this.model.set('role', this.$('.permissions select').find(':selected').val());

    values.takesAppointments = !!values.takesAppointments;
    values.doesAllOffers = !!values.doesAllOffers;
    values.takesAppointments = this.options.forceTakesAppointments || values.takesAppointments;
    values.role = this.model.get('role');

    if (values.emailAddress === '') {
      values.emailAddress = null;
    }
    if (
      this.model.get('role') !== 'U' ||
      (this.options.permissions && this.options.permissions.length === 0)
    ) {
      values.permissions = null;
    } else if (this.options.permissions) {
      values.permissions = this.options.permissions;
    } else {
      values.permissions = this.model.get('permissions');
    }

    if (this.$('#employees-employee-canLogin').attr('checked')) {
      values.canLogin = true;
    } else {
      values.canLogin = false;
    }

    if (values.employeeCategoryId === '') {
      values.employeeCategoryId = null;
    }
    if (!values.gender) {
      values.gender = null;
    }

    if (values.phone !== '') {
      values.phone = formatInternationalFromInput(
        values.phone,
        App.config.get('venue').countryCode,
      );
    }

    let offers = null;
    if (values.takesAppointments && !values.doesAllOffers) {
      offers = [];
      const $list = this.$('.service-wrapper').find('input:checked');
      $list.each(function () {
        offers.push(this.value);
      });
    }
    values.employeeOffers = offers;

    return values;
  },
  updateImage: function () {
    if (this.model.get('image')) {
      this.$('.person-pic img').attr(
        'src',
        Wahanda.ScreenDensity.getImageFromStructure(this.model.get('image').uris, 85, 85),
      );
      this.$('.person-pic img').wShow();
      this.$('.person-pic .delete').wShow();
      this.$('.person-pic .delete .icon').wShow();
      this.$('.person-pic .edit').wShow();
      this.$('.person-pic .add-new').wHide();
    } else if (this.model.get('id') && !this.model.get('imageId')) {
      this.$('.person-pic img').wHide();
      this.$('person-pic .delete').wHide();
      this.$('person-pic .delete .icon').wHide();
      this.$('person-pic .edit').wHide();
      this.$('.person-pic .add-new').wShow();
    }
  },
  renderTitle: function () {
    const $head = this.$('.dialog-head h1');
    $head.html(
      Wahanda.Template.render($head.html(), {
        venue: `<span class="highlight">${App.config.get('venue').name}</span>`,
      }),
    );
  },

  getConstraintErrors: function (data) {
    const errors = {};

    _.each(data.errors, function (error) {
      if (error.name === 'employee-name-in-use') {
        errors['employees-employee-name'] =
          Wahanda.lang.settings.employees.employee.errors.duplicateName;
      } else if (error.name === 'employee-in-use') {
        errors['employees-employee-takesAppointments'] =
          Wahanda.lang.settings.employees.employee.errors.deactivatingEmployeeInUse;
      } else if (error.name === 'employee-email-address-in-use') {
        errors['employees-employee-emailAddress'] = Wahanda.Template.render(
          Wahanda.lang.settings.employees.employee.errors.emailInUse,
          { name: data.additionalData.name },
        );
      }
    });

    return errors;
  },

  openCalendarSubscribeWindow: function () {
    trackEvent('team-member', 'click', 'external-calendar-link');
    const view = new App.Views.Forms.Employee.LinkExternalCalendar({
      model: this.model,
    });
    view.render();
    view.open();
  },

  openCalendarUnsubscribeWindow: function () {
    const view = new App.Views.Forms.Employee.UnlinkExternalCalendar({
      model: this.model,
    });
    view.render();
    view.open();
  },

  save: function () {
    trackEvent('team-member', 'submit', 'save-member-details');
    const self = this;
    const saveAction = function () {
      BackboneEx.View.Dialog.ValidatingForm.prototype.save.call(self);
    };

    this.model.set(this.getModelValues());
    // Saving the Employee through `save` means either regular save or Unarchiving
    this.model.set('active', true);

    this.showEmailInstructionsSent =
      (!this.emailUnchanged() && this.model.get('canLogin') === true) ||
      (!this.model.previous('canLogin') && this.model.get('canLogin')) ||
      (this.model.get('canLogin') && !this.model.get('id'));

    if (this.model.previous('canLogin') && !this.model.get('canLogin')) {
      BackboneEx.Tool.Analytics.trackEvent(
        'connect-employee',
        'account-login-disabled',
        this.model.get('id'),
      );
    }

    if (this.model.previous('emailAddress') && this.model.changed.emailAddress) {
      BackboneEx.Tool.Analytics.trackEvent(
        'connect-employee',
        'account-email-changed',
        this.model.get('id'),
      );
    }

    this.model.nextSaveInclude = ['employee-offers'];

    if (
      // Tax Auditors are always not bookable
      this.model.get('role') !== 'X' &&
      !this.model.canBeBooked()
    ) {
      let html = Wahanda.Template.renderTemplate('employee-form-save-tooltip-not-bookable', {
        takesAppointments: this.model.get('takesAppointments'),
      });
      html = $(html);

      const tooltip = this.$('button.save-action').qtip({
        content: html,
        style: {
          classes: 'dialog-tooltip',
        },
        position: {
          my: 'bottom center',
          at: 'top center',
        },
        show: {
          event: false,
          ready: true,
        },
        hide: {
          event: false,
          fixed: true,
        },
      });

      html
        .find('button[type=submit]')
        .on('click', function () {
          tooltip.qtip('destroy');
          saveAction();
        })
        .end()
        .find('.button-cancel')
        .on('click', function () {
          tooltip.qtip('destroy');
          self.enableForm();
          // Don't focus elements on touch devices
          if (!Wahanda.Util.isTouchDevice()) {
            self.focusFormElement();
          }
        });
    } else {
      saveAction();
    }
  },

  sendChangeEvent(event) {
    App.trigger(event, {
      by: App.isMasquerading() ? 'treatwell' : 'owner',
    });
  },

  _afterSaveCallback: function (data) {
    BackboneEx.View.Dialog.ValidatingForm.prototype._afterSaveCallback.call(this);
    let event;

    if (this.showEmailInstructionsSent) {
      // Show "Email will be sent to this team member with instructions how to log in" dialog
      Wahanda.Dialogs.Alert(Wahanda.lang.settings.employees.employee.newAccount, {
        dialogClass: 'alert-dialog success-msg',
      });
      BackboneEx.Tool.Analytics.trackEvent(
        'connect-employee',
        'extranet-account-created',
        this.model.get('id'),
      );
    }

    if (data.isNew) {
      const view = new App.Views.Dialog.NewEmployeeImage({
        model: this.model,
      });
      view.render();
      view.open();

      event = Wahanda.Event.EMPLOYEE_ADDED;
    } else if (this.wasArchived) {
      event = Wahanda.Event.EMPLOYEE_UNARCHIVED;
    } else {
      event = Wahanda.Event.EMPLOYEE_EDITED;
    }
    this.sendChangeEvent(event);
  },

  onDelete: function () {
    const self = this;
    Wahanda.Dialogs.Confirm(
      Wahanda.lang.settings.employees.employee.deleteConfirmation,
      function () {
        self.archive();
      },
    );
  },

  archive: function () {
    const self = this;
    this.disableForm();

    const deleteBtn = this.$('.delete-action');
    deleteBtn.action('doing');

    this.model.set('active', false);
    this.model.set('canLogin', false);
    this.model.nextSaveInclude = [];
    this.model.save(null, {
      success: function () {
        self.resetCloseChangesCheck();
        self.close();
        Wahanda.Ajax.resetErrorHandler();
        self.model.collection.remove(self.model);

        self.sendChangeEvent(Wahanda.Event.EMPLOYEE_ARCHIVED);
      },
      error: function (model, response) {
        deleteBtn.action();
        self.enableForm();
        // Show delete failed tip
        const errorText =
          typeof self.deleteErrorText === 'function'
            ? self.deleteErrorText(response)
            : self.deleteErrorText;
        if (errorText) {
          deleteBtn.noticeTip(errorText, null, 10000);
        }
      },
    });
  },

  addEmployeeCategoryToSelect: function (model) {
    const $select = this.$('#employees-employee-employeeCategoryId');
    const $separator = $select.find('option:disabled');
    const $newOption = $('<option>', { value: model.id }).text(model.get('name'));
    // Add new category before the separator
    $separator.before($newOption);
    $select.val(model.id).change();
  },

  // Events

  onEmployeeCategoryChange: function (event) {
    const select = $(event.target);
    if (select.val() === 'create') {
      select.val(this.previousSelectedCategory);

      const model = new App.Models.EmployeeCategory({
        venueId: App.config.get('venue').id,
      });

      const view = new App.Views.Forms.EmployeeCategory({
        el: $('.employee-category-form'),
        collection: this.options.categoriesCollection,
        model: model,
      });

      view.render();
      view.open();
    } else {
      this.previousSelectedCategory = select.val();
    }
  },
  togglePermissions: function () {
    const showPermissions = this.$('#employees-employee-canLogin').is(':checked');
    const emailAddressField = this.$('#employees-employee-emailAddress');
    this.$('.permissions').wToggle(showPermissions);
    if (
      !this.model.changed.emailAddress &&
      emailAddressField.val() !== '' &&
      this.model.get('canLogin')
    ) {
      this.$('.send-password').wToggle(showPermissions);
    }
    this.$("label[for='employees-employee-emailAddress']").toggleClass(
      'optional',
      !showPermissions,
    );
    emailAddressField.toggleClass('required', showPermissions);
    if (emailAddressField.hasClass('error')) {
      emailAddressField.removeClass('error');
    }

    this.onRoleChange();
  },
  toggleAppointmentTakingDependants: function () {
    const takesAppointments = this.$('#employees-employee-takesAppointments').is(':checked');
    this.$('.when-taking-appointments').wToggle(takesAppointments);
    this.$('.employee-services').toggleClass('empty', !takesAppointments);

    if (takesAppointments) {
      this.$('.service-wrapper').shadows();
    }
  },

  passwordResetHandler: function () {
    trackEvent('team-member', 'click', 'send-password-reset-instruction');

    const passReset = new App.Models.EmployeePasswordReset();

    passReset.save(
      {
        id: this.model.id,
        venueId: App.config.get('venue').id,
      },
      {
        error: function () {
          this.$('.send-password').errorTip(
            Wahanda.lang.settings.employees.employee.errors.resetPasswordEmail,
          );
        },
        success: function () {
          Wahanda.Dialogs.Alert(Wahanda.lang.settings.employees.employee.resetSuccess, {
            dialogClass: 'alert-dialog success-msg',
          });
        },
      },
    );
  },

  toggleMenuDoesAllOffers: function () {
    const doesItAll = this.$('#employees-employee-doesAllOffers').is(':checked');

    const $wrapper = this.$('.service-wrapper');
    $wrapper.toggleClass('does-it-all', doesItAll);

    const dataKey = this.lastValueKey;
    const $services = $wrapper.find('input[type=checkbox]');
    $services.each(function () {
      const $srv = $(this);
      if (doesItAll) {
        // Mark all checkboxes as checked, plus save their state
        $srv.data(dataKey, $srv.is(':checked'));
        $srv.prop('checked', true);
      } else {
        // Restore the previous state (or unset, if no previous state)
        const wasChecked = $srv.data(dataKey);
        $srv.prop('checked', !!wasChecked);
        $srv.removeData(dataKey);
      }
    });
    // Disable/enable the elements
    $services.toggleFormElements(!doesItAll);
  },

  emailUnchanged: function () {
    let originalEmail;
    if (this.model.get('originalEmailAddress')) {
      originalEmail = this.model.get('originalEmailAddress');
    } else {
      originalEmail = this.model.get('emailAddress');
    }

    if ($('#employees-employee-emailAddress').val() === originalEmail) {
      return true;
    }
    return false;
  },
  menuSelectAllOffers: function (event) {
    const $items = this._getItemsInGroup(event);
    if ($items) {
      $items.prop('checked', true).attr('checked', 'checked');
    }
  },

  menuDeselectAllOffers: function (event) {
    const $items = this._getItemsInGroup(event);
    if ($items) {
      $items.prop('checked', false).removeAttr('checked');
    }
  },

  _getItemsInGroup: function (event) {
    const groupId = $(event.currentTarget).closest('.service-group-title').data('groupId');
    if (groupId) {
      const items = this.$(`.multiple-services-list[data-group-id=${groupId}]`).find(
        'input[type=checkbox]',
      );
      if (items.length > 0) {
        return items;
      }
    }
    return null;
  },

  onCalendarSubscribtionChange: function () {
    this.toggleExtCalendarLink();
  },
});
