import {
  PRESENT_DISCOUNT_EMAIL_CAMPAIGN_DIALOG,
  DISCARD_DISCOUNT_EMAIL_CAMPAIGN_DIALOG,
} from 'reduxStore/actionsConst';

import { DialogTypes } from './types';

export const displayEmailCampaignDialog = (discountId: number): DialogTypes => ({
  discountId,
  type: PRESENT_DISCOUNT_EMAIL_CAMPAIGN_DIALOG,
});

export const hideEmailCampaignDialog = (): DialogTypes => ({
  type: DISCARD_DISCOUNT_EMAIL_CAMPAIGN_DIALOG,
});
