import React from 'react';
import classnames from 'classnames';

import style from './SidebarLink.scss';

export interface SidebarLinkProps {
  dataTest?: string;
  href?: string;
  icon?: React.ReactNode;
  isVisible?: boolean;
  onClick?: () => void;
  isSelected: boolean;
  text: string;
}

export class SidebarLink extends React.PureComponent<SidebarLinkProps> {
  public render() {
    const { dataTest, icon, href, onClick, isSelected, isVisible = true, text } = this.props;

    const className = classnames(style.link, {
      [style.isSelected]: isSelected,
    });

    if (!isVisible) {
      return null;
    }

    return (
      <a
        {...(href ? { href } : {})}
        className={className}
        onClick={onClick}
        role="link"
        tabIndex={0}
        data-test={`sidebar-link${dataTest && `-${dataTest}`}`}
      >
        <span className={style.icon}>{icon && icon}</span>
        {text}
      </a>
    );
  }
}
