import React from 'react';
import { get } from 'mori';
import Wahanda from 'common/wahanda';
import Bookings from './Bookings';
import Rating from './Rating';
import Loading from '../common/Loading';

const LANG = Wahanda.lang.dashboard.trafficLights.scoring;

function calculateOrdersSuccessRate(appointmentOrders) {
  const totalCount = get(appointmentOrders, 'rollingAppointmentOrderCount');
  const cancellations = get(
    appointmentOrders,
    'rollingSupplierLedCancellationAppointmentOrderCount',
  );
  const notPerfectOrderPercent = totalCount > 0 ? (cancellations / totalCount) * 100 : 0;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  return parseInt(100 - notPerfectOrderPercent, 10);
}

interface IScoringProps extends React.HTMLAttributes<Element> {
  appointmentOrders?: {};
  reviews?: {};
  thresholds?: {};
}

export default class Scoring extends React.Component<IScoringProps, {}> {
  render() {
    const { reviews, appointmentOrders, thresholds } = this.props;
    const ready = reviews && appointmentOrders && thresholds;
    return (
      <div className="container-block scoring">
        <div className="title">{LANG.title}</div>
        <div className="sub-content underlined">{LANG.info}</div>
        {ready ? null : <Loading />}
        {ready ? (
          <Bookings
            value={calculateOrdersSuccessRate(appointmentOrders)}
            threshold={get(thresholds, 'minimumNonCancelledBookingPercentage')}
          />
        ) : null}
        {ready ? (
          <Rating
            rating={get(reviews, 'weightedRating')}
            ratingCount={get(reviews, 'reviewCount')}
            threshold={get(thresholds, 'minimumOverallReviewRating')}
          />
        ) : null}
      </div>
    );
  }
}
