App.Views.Forms.MenuOffer2.Pricing.Dated.Dates = BackboneEx.View.DynamicForm.extend({
  events: {
    'change select[name=offer-pricing-pricingType]': 'toggleRange',
  },
  fieldPrefix: 'offer-pricing-',

  initialize: function () {
    this.$el.append($('#menu-offer-dated-pricing-dates').html());

    this.initMakeIdsUnique();
    this.$$('dateTo').attr('date-not-less', '#offer-pricing-dateFrom' + this._getUniqueSuffix());

    this.$('.datepicker').venueDatepicker();
  },

  render: function () {
    var $dateFrom = this.$$('dateFrom');
    var $dateTo = this.$$('dateTo');
    this.$$('pricingType').val(this.options.pricingType);
    // Date from
    $dateFrom.val(
      this.options.dateFrom ? Wahanda.Date.formatToDefaultDate(this.options.dateFrom) : '',
    );
    // Date to
    $dateTo.val(
      this.options.dateTo
        ? Wahanda.Date.formatToDefaultDate(this.options.dateTo)
        : // If the second date is not passed, use the first one's value
          this.$$('dateFrom').val(),
    );

    // Set "not less than..." validator
    if (this.options.dateFrom && Wahanda.Date.isPast(this.options.dateFrom)) {
      // If the set date is in the past, allow setting dates from it, not from $now
      $dateFrom.attr('date-later-or-equal', Wahanda.Date.toApiString(this.options.dateFrom));
    } else {
      $dateFrom.attr(
        'date-later-or-equal',
        Wahanda.Date.toApiString(Wahanda.Date.createVenueDate()),
      );
    }

    // Aligning fields to auto-set values for the user
    $.dateFieldAlign.connect({
      first: $dateFrom,
      later: $dateTo,
    });

    this.restrictDatepickers();
    this.toggleRange();
  },

  restrictDatepickers: function () {
    var $dateFrom = this.$$('dateFrom');
    var dateFromDate = Wahanda.Date.createDate($dateFrom.attr('date-later-or-equal'));
    $dateFrom.datepicker('option', 'minDate', dateFromDate);

    this.$$('dateTo').datepicker('option', 'minDate', Wahanda.Date.createVenueDate());
  },

  getValues: function () {
    var pricingType = this.$$('pricingType').val();
    return {
      pricingType: pricingType,
      dateFrom: this.$$('dateFrom').datepicker('getDate'),
      dateTo: 'R' === pricingType ? this.$$('dateTo').datepicker('getDate') : null,
    };
  },

  // Events

  toggleRange: function () {
    var isRange = 'R' === this.$$('pricingType').val();
    this.$('.b-range').wToggle(isRange);
  },
});

BackboneEx.Mixin.extendView(
  App.Views.Forms.MenuOffer2.Pricing.Dated.Dates,
  BackboneEx.Mixin.View.Datepicker,
);
