import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../store/actions';
import { ShiftScheduleModal as Modal } from './ShiftScheduleModal';

const mapStateToProps = ({ shifts }) => ({
  businessHours: shifts.venue.businessHours,
  datePickerRange: shifts.datePickerRange,
  loadedSchedule: shifts.loadedSchedule,
  isBusinessHoursLoaded: shifts.isBusinessHoursLoaded,
  isSavingShift: shifts.isSavingShift,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ShiftScheduleModal = connect(mapStateToProps, mapDispatchToProps)(Modal);
