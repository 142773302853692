import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'reduxStore/common/posStatus/actions';
import { startPOSOperationRequest } from 'reduxStore/common/posOperation/actions';
import IssueRefundDialog from './index';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...actions,
      startPOSOperationRequest,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ posStatus, posOperation }) => ({
  currentDrawerTotal: posStatus.cashBalance,
  operationId: posOperation.operationId,
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueRefundDialog);
