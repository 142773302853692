/* eslint-disable func-names */
import { PROCESSING_TIME_SLOTS } from 'common/constants/timeChoices';

/**
 * Time functions.
 */
Wahanda.Time = {
  apiTimeFormat: 'H:i',

  /**
   * Generates array of values for creating a time select.
   *
   * @param int from            Minutes to start generating from
   * @param int to              Minutes to end generating
   * @param int step            Minutes from the last value to the next one
   * @param string format       OPTIONAL Time format for the shown title, default is H:i
   * @param function selectFunc OPTIONAL Function that defines which element must be selected. It should accept
   *                            minutes as integer and step. It must return true when the element needs to be selected
   * @param boolean withTemplate OPTIONAL Should the format be passed through Mustache templating engine?
   * 							  {@see toFormattedWithTemplate}
   * @return Array of {minutes: 15, value: "00:15", title: "Title"[, selected: true //if selectFunc returned true//}
   */
  getTimeFieldValues: function (from, to, step, format, selectFunc, withTemplate) {
    const list = [];
    let formatMethod;
    const theFormat = format || 'H:i';

    if (withTemplate) {
      formatMethod = 'toFormattedWithTemplate';
    } else {
      formatMethod = 'toFormatted';
    }

    while (from <= to) {
      const name = this[formatMethod](from, theFormat);
      const item = {
        minutes: from,
        name: name,
        value: this[formatMethod](from, 'H:i'),
        title: name,
      };
      if (typeof selectFunc === 'function' && selectFunc(from, step)) {
        item.selected = true;
      }
      list.push(item);

      from += step;
    }
    return list;
  },

  getVenueWorkTimeHours: function (options) {
    options || (options = {});
    const workStart = this.toMinutesIfTime(
      options.workStart != null ? options.workStart : App.config.get('venue').dayStartsAt,
    );
    const workEnd = this.toMinutesIfTime(
      options.workEnd != null ? options.workEnd : App.config.get('venue').dayEndsAt,
    );
    const step = options.step || App.config.get('venue').appointmentSlotDuration;
    return this.getTimeFieldValues(
      workStart,
      workEnd,
      step,
      App.config.get('jqueryDateFormat').defaultTime,
    );
  },

  /**
   * Returns SELECT options for venue startTime..endTime.
   *
   * @param Object options OPTIONAL. Possible values:
   * > (int) step Minutes to step
   * > (int) workStart Custom start of day
   * > (int) workEnd Custom end of day
   *
   * @return string
   */
  getVenueWorkTimeHourSelect: function (options) {
    const templateOptions = this.getVenueWorkTimeHours(options);
    return Wahanda.Template.renderTemplate('form-select-items', {
      options: templateOptions,
    });
  },

  /**
   * Converts minutes to a formatted string.
   *
   * Escaping of characters can be done by prefixing them with "\".
   *
   * @param int time Minutes
   * @param string format Format with values from the `formats`
   * @return string
   */
  toFormatted: function (time, format) {
    let returnStr = '';
    let skipNext = false;
    for (let i = 0, len = format.length; i < len; i++) {
      const curChar = format.charAt(i);
      if (curChar === '\\' && !skipNext) {
        skipNext = true;
      } else if (!skipNext && _.isFunction(this.formats[curChar])) {
        returnStr += this.formats[curChar](Math.floor(time / 60), time % 60);
      } else {
        returnStr += curChar;
        skipNext = false;
      }
    }
    return returnStr;
  },

  toApiString: function (time) {
    return this.toFormatted(time, this.apiTimeFormat);
  },

  /**
   * Converts the given minutes to default time.
   *
   * @param int time
   * @return string
   */
  toDefaultTime: function (time) {
    return this.toFormatted(time, App.config.get('jqueryDateFormat').defaultTime);
  },

  /**
   * Get minutes passed since 00:00 in the given date.
   *
   * @param Date date
   * @return int
   */
  getDateMinutes: function (date) {
    return date.getHours() * 60 + date.getMinutes();
  },

  getDateApiTime: function (date) {
    return Wahanda.Time.toApiString(Wahanda.Time.getDateMinutes(date));
  },

  /**
   * Does the same as {@see toFormatted}, but with format pre-parsing as a Mustache templte.
   *
   * It exposes two boolean mustache variables: hour, minute. If hour or minute equals 0, the variable is false.
   * Otherwise - it's true.
   *
   * @param int time
   * @param string template
   * @return string
   */
  toFormattedWithTemplate: function (time, template) {
    const isHour = time >= 60;
    const isMinute = time % 60 !== 0;

    const format = Mustache.render(template, {
      hour: isHour,
      minute: isMinute,
    });
    return this.toFormatted(time, format);
  },

  /**
   * Time in format H:i is converted into minutes from 00:00.
   *
   * @param string time
   * @return int
   */
  timeToMinutes: function (time) {
    let result = 0;
    let match = null;
    if (time && (match = String(time).match(/^(\d{1,2}):(\d{1,2})$/))) {
      result = parseInt(match[1], 10) * 60 + parseInt(match[2], 10);
    }
    return result;
  },

  toMinutesIfTime: function (time) {
    if (typeof time === 'string') {
      time = this.timeToMinutes(time);
    }
    return time;
  },

  minutesToTimeHash: function (min) {
    const minutes = parseInt(min, 10);
    return {
      hours: Math.floor(minutes / 60),
      minutes: minutes % 60,
    };
  },

  /**
   * Returns string which has "\" added before each characted so that {@see toFormatted} won't escape any
   * special characters.
   *
   * @param String string
   * @return String
   */
  escapeFormatting: function (string) {
    return string ? `\\${String(string).split('').join('\\')}` : '';
  },

  /**
   * Creates a function which compares two times and returns TRUE if the given number is the closest match.
   * Returns a callback function which can be passed to Wahanda.Time.getTimeFieldValues as the `selectFunc` parameter.
   *
   * @param int | string time Time to compare to
   * @param int step
   * @param int startsFrom Time from which will be counted
   * @return function
   */
  getNearestSelectFunc: function (time, step, startsFrom) {
    if (typeof time === 'string') {
      time = this.timeToMinutes(time);
    }
    time += startsFrom % step;

    const matchesStep = time % step === 0;
    const halfStep = step / 2;
    let returned = false;

    return function (currentMinutes, outerStep) {
      if (returned) {
        // This function returns TRUE only once
        return false;
      }
      if (matchesStep) {
        // Time matches exactly one step
        return (returned = time === currentMinutes);
      }
      return (returned = Math.abs(time - currentMinutes) <= halfStep);
    };
  },

  /**
   * Supported time formats.
   */
  formats: {
    // Time
    a: function (h, m) {
      return h < 12 ? 'am' : 'pm';
    },
    A: function (h, m) {
      return h < 12 ? 'AM' : 'PM';
    },
    B: function (h, m) {
      return 'Not Yet Supported';
    },
    g: function (h, m) {
      return h % 12 || 12;
    },
    G: function (h, m) {
      return h;
    },
    h: function (h, m) {
      return ((h % 12 || 12) < 10 ? '0' : '') + (h % 12 || 12);
    },
    H: function (h, m) {
      return (h < 10 ? '0' : '') + h;
    },
    i: function (h, m) {
      return (m < 10 ? '0' : '') + m;
    },
  },

  /**
   * Returns formatted and translated duration.
   *
   * @param int time
   * @param string type MI for minutes, DY for days
   * @return string
   */
  duration: function (time, type) {
    if (type === 'MI') {
      const result = Math.round((isNaN(time) ? 0 : time) / 60, 1);
      return `${result} ${Wahanda.lang.time.duration.abbr[result > 1 ? 'hours' : 'hour']}`;
    }
    return `${time} ${Wahanda.lang.time.duration[time > 1 ? 'days' : 'day']}`;
  },

  /**
   * Converts minutes or days to minutes.
   *
   * @param int time Minutes or Days
   * @param string type MI for minutes, DY for days
   * @return string
   */
  durationToMinutes: function (time, type) {
    if (type === 'DY') {
      time *= 60 * 24;
    }
    return time;
  },

  /**
   * Returns duration in full format (Hours and Minutes separate)
   *
   * @param int time
   * @return string
   */
  getDurationFull: function (time) {
    const ret = this.toFormattedWithTemplate(time, this.getTimeDurationTemplate());

    return typeof ret === 'string' ? ret.trim() : ret;
  },

  /**
   * Generates array of values for duration time selects.
   *
   * @param int selected        OPTIONAL Time to select
   * @param string format       OPTIONAL Time format for the shown title, default is H:i
   * @param boolean withTemplate OPTIONAL Should the format be passed through Mustache templating engine?
   * 							  {@see toFormattedWithTemplate}
   * @param Object options      OPTIONAL Hash of parameters:
   * > minMinutes (int) Minimum minutes returned in results
   * > titleIncrement (int) Minutes to increase the title. For display only, the real value will be different.
   * > valueAsMinutes (bool, optional) Should the value be returned as minutes, not HH:MM?
   * @return Array of {minutes: 15, value: "00:15", title: "Title"[, selected: true //if selectFunc returned true//}
   */
  getDurationValues: function (selected, format, withTemplate, options) {
    const self = this;
    const list = [];
    let formatMethod;
    format = format || 'H:i';
    options = options || {};

    let minMinutes;
    if (options.minMinutes) {
      minMinutes = options.minMinutes;
    } else {
      minMinutes = Math.min(App.config.get('venue').appointmentSlotDuration, 10);
    }

    if (withTemplate) {
      formatMethod = 'toFormattedWithTemplate';
    } else {
      formatMethod = 'toFormatted';
    }

    const valueFn = function (minutes) {
      if (options.valueAsMinutes) {
        return minutes;
      }
      return self[formatMethod](minutes, 'H:i');
    };

    const addTimeToList = function (startHour, endHour, increment, selectFunc) {
      let titleMinutes;
      for (let hour = startHour; hour < endHour; hour++) {
        for (let min = hour > 0 ? 0 : increment; min < 60; min += increment) {
          const minutes = (titleMinutes = hour * 60 + min);
          if (minutes < minMinutes) {
            continue;
          }
          if (options.titleIncrement) {
            titleMinutes += options.titleIncrement;
          }
          list.push({
            minutes: minutes,
            value: valueFn(minutes),
            title: self[formatMethod](titleMinutes, format),
            name: self[formatMethod](titleMinutes, format),
            selected: typeof selectFunc === 'function' && !!selectFunc(minutes, increment),
          });
        }
      }
    };

    let selectFunc;
    if (selected) {
      let firstSelectedCheck = true;
      selectFunc = function (check, step) {
        if (firstSelectedCheck) {
          firstSelectedCheck = false;
          if (selected <= minMinutes) {
            // Select the first value, because the selected one is lower than the minimum
            return true;
          }
        }
        return selected === check || (selected > check && selected < check + step);
      };
    }

    addTimeToList(0, 2, 5, selectFunc);
    addTimeToList(2, 4, 15, selectFunc);
    addTimeToList(4, 8, 30, selectFunc);
    addTimeToList(8, 12, 60, selectFunc);

    const lastHour = 12;
    list.push({
      minutes: 60 * lastHour,
      value: valueFn(60 * lastHour),
      title: this[formatMethod](60 * lastHour, format),
      name: this[formatMethod](60 * lastHour, format),
      selected: typeof selectFunc === 'function' && !!selectFunc(60 * lastHour, 60),
    });
    return list;
  },

  getDurationText: function (appointment) {
    const startTime = appointment.get('startTime');
    const endTime = appointment.get('endTime');
    const processingEndTime = appointment.get('processingEndTime');

    const processingTime = processingEndTime && this.getDifference(endTime, processingEndTime);
    const end = this.getEndTime(appointment);
    const time = this.getDifference(startTime, end);

    const cleanupTime = this.getCleanupDuration(appointment);
    return {
      time,
      cleanupTime,
      processingTime,
    };
  },

  getEndTime: function (appointment) {
    return (
      appointment.get('finishingEndTime') ||
      appointment.get('processingEndTime') ||
      appointment.get('endTime')
    );
  },

  getCleanupDuration: function (appointment) {
    const endTime = this.getEndTime(appointment);
    const cleanupTime = Math.max(
      this.timeToMinutes(appointment.get('cleanupEndTime')) - this.timeToMinutes(endTime),
      0,
    );

    return cleanupTime;
  },

  /**
   * Returns hour difference between two given, as float.
   *
   * @param String less time in format HH:MM
   * @param String more time in format HH:MM
   * @return float
   */
  getHourDiff: function (less, more) {
    const first = this.splitHours(less);
    const second = this.splitHours(more);

    return second.hours + second.minutes / 60 - (first.hours + first.minutes / 60);
  },

  getProcessingTimeSlotsArray: function () {
    return PROCESSING_TIME_SLOTS;
  },

  /**
   * Splits hours intu hours and minutes.
   *
   * @param String hours time in format HH:MM
   * @return {hours, minutes}
   */
  splitHours: function (hours) {
    const match = String(hours).match(/^(\d{1,2}):(\d{1,2})$/);
    if (match) {
      return {
        hours: parseInt(match[1], 10),
        minutes: parseInt(match[2], 10),
      };
    }
    return { hours: 0, minutes: 0 };
  },

  getTimeDurationTemplate: function () {
    return (
      `{{#hour}}G ${this.escapeFormatting(Wahanda.lang.time.duration.mini.hour)}{{/hour}}` +
      `{{#minute}} i ${this.escapeFormatting(Wahanda.lang.time.duration.abbr.minutes)}{{/minute}}`
    );
  },

  /**
   * Returns difference, in human-readable format, between the two times.
   *
   * @param String from
   * @param String to
   * @return String duration in getTimeDurationTemplate() format
   */
  getDifference: function (from, to) {
    const diff = this.timeToMinutes(to) - this.timeToMinutes(from);
    return this.getDurationFull(diff);
  },

  /**
   * Do the given time ranges overlap?
   *
   * TimeRange type is { from: HH:MM, to: HH:MM } OR { from: minutes, to: minutes }
   *
   * @param TimeRange time1
   * @param TimeRange time2
   * @param boolean   strict OPTIONAL Strict checking (e.g. if end matches start, should it count as overlap?)
   * 						Defaults to TRUE.
   * @return boolean
   */
  doesOverlap: function (time1, time2, strict) {
    strict = arguments.length === 2 ? true : strict;
    const start1 = toMins(time1.from);
    const start2 = toMins(time2.from);
    const end1 = toMins(time1.to);
    const end2 = toMins(time2.to);
    // (StartA <= EndB) and (EndA >= StartB)
    if (strict) {
      return start1 <= end2 && end1 >= start2;
    }
    return start1 < end2 && end1 > start2;

    function toMins(time) {
      return isNaN(time) ? Wahanda.Time.timeToMinutes(time) : time;
    }
  },

  /**
   * Makes a prettin time range, in format HH:MM - HH:MM, or in 12 hour one.
   * @param String|int from
   * @param String|int to
   * @return String
   */
  prettyTimeRange: function (from, to) {
    let template;
    from = isNaN(from) ? this.timeToMinutes(from) : from;
    to = isNaN(to) ? this.timeToMinutes(to) : to;

    if (App.config.get('jqueryDateFormat').use24h) {
      template = 'H:i';
    } else {
      template = 'g{{#minute}}:i{{/minute}}A';
    }

    return `${this.toFormattedWithTemplate(from, template)}-${this.toFormattedWithTemplate(
      to,
      template,
    )}`;
  },

  getSkuDurationText: function (duration) {
    const day = 24 * 60;
    const week = day * 7;
    const lang = Wahanda.lang.datetime.duration;

    if (duration >= week) {
      const weeks = Math.floor(duration / week);
      return Wahanda.Text.pluralizeNumber(weeks, [lang.week, lang.weeks]);
    }
    if (duration >= day) {
      const days = Math.floor(duration / day);
      return Wahanda.Text.pluralizeNumber(days, [lang.day, lang.days]);
    }
    return Wahanda.Time.toFormattedWithTemplate(duration, Wahanda.Time.getTimeDurationTemplate());
  },

  /**
   * Add one time to another. This method does not treat day overflows: the latest possible returned time is 23:59
   *
   * @param String|int a Time 1. Can be in minutes from midnight or in HH:MM.
   * @param String|int b Time 2. Can be in minutes from midnight or in HH:MM.
   *
   * @return String HH:MM
   */
  add: function (a, b) {
    a = isNaN(a) ? Wahanda.Time.timeToMinutes(a) : a;
    b = isNaN(b) ? Wahanda.Time.timeToMinutes(b) : b;

    let result = a + b;
    // Not more than 23:59.
    if (result > 1439) {
      result = 1439;
    }

    return Wahanda.Time.toApiString(result);
  },

  getDurationOptions: function (start, end, step, options) {
    options = options || {};
    const selectData = [];

    for (let i = start; i <= end; i += step) {
      selectData.push(Wahanda.Time.getDurationOptionsItem(i, options));
    }

    return selectData;
  },

  getDurationOptionsItem: function (minutes, options) {
    const timeOption = Wahanda.lang.datetime.distance_in_words.x_minutes.other.replace(
      '{{count}}',
      minutes,
    );
    return {
      value: minutes,
      title: timeOption,
      name: timeOption,
      selected: options.selected === minutes,
    };
  },
};
