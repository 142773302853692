(function($) {
	
	$.fn.textSelect = function() {
		this.each(function() {
			if (document.selection) {
				var range = document.body.createTextRange();
				range.moveToElementText(this);
				range.select();
			} else if (window.getSelection) {
				var range = document.createRange();
				range.selectNode(this);
				window.getSelection().addRange(range);
			}
		});
	};
	
	$.fn.textDeselect = function() {
		if (document.selection) {
        	document.selection.empty();
        } else if (window.getSelection) {
			window.getSelection().removeAllRanges();
		}
	};
	
}(jQuery));
