import React from 'react';
import BigNumber from 'bignumber.js';

interface Props {
  value: number | string;
  rounded?: boolean;
}

export class Percentage extends React.PureComponent<Props> {
  public static defaultProps = {
    rounded: false,
  };

  public render() {
    return `${this.value}%`;
  }

  private get value() {
    const { value, rounded } = this.props;
    const result = new BigNumber(value);

    if (rounded) {
      return result.toFixed(0);
    }

    return result.toFixed(2);
  }
}
