import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  requestProductSuppliersAction,
  resetProductSuppliersAction,
} from 'components/menu/ProductSuppliers/actions';
import { selectProductOrderSupplierAction } from '../actions';
import SelectSupplierDialog from './index';

const mapStateToProps = ({ productSuppliers: { items, loading } }) => ({
  suppliers: items,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      requestProductSuppliersAction,
      resetProductSuppliersAction,
      selectProductOrderSupplierAction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectSupplierDialog);
