import React from 'react';
import { Checkbox } from 'components/common/Checkbox';
import MenuItem from './MenuItem/container';
import style from './MenuGroup.scss';

interface IMenuGroupProps extends React.HTMLAttributes<Element> {
  actions: {
    setCheckedMenuItemsAction: (...args: any[]) => any;
  };
  group: {
    id?: string;
    name?: string;
    offers?: {
      id: number;
      checked?: boolean;
    }[];
  };
  discountId?: number;
  discountName?: string;
  discountBadgeVisible?: boolean;
}

export class MenuGroup extends React.PureComponent<IMenuGroupProps, {}> {
  public static defaultProps = {
    discountBadgeVisible: false,
  };

  private handleChange = (checked) => {
    const {
      actions: { setCheckedMenuItemsAction },
      group: { offers },
    } = this.props;
    setCheckedMenuItemsAction(
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      offers.map((offer) => offer.id),
      checked,
    );
  };

  private areAllMenuItemsChecked() {
    const {
      group: { offers },
    } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return offers.filter((offer) => !offer.checked).length === 0;
  }

  private areAllMenuItemsUnchecked() {
    const {
      group: { offers },
    } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return offers.filter((offer) => offer.checked).length === 0;
  }

  private renderGroupNames() {
    const { group } = this.props;
    const semiChecked = !this.areAllMenuItemsChecked() && !this.areAllMenuItemsUnchecked();
    return (
      <div className={style.group}>
        <Checkbox
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
          name={group.id}
          label={group.name}
          checked={this.areAllMenuItemsChecked()}
          onChange={this.handleChange}
          className={style.groupCheckbox}
          semiChecked={semiChecked}
        />
        {this.renderGroupOffers(group.offers)}
      </div>
    );
  }

  private renderGroupOffers(offers) {
    const { discountId, discountName, discountBadgeVisible } = this.props;
    return (
      <ul>
        {offers.map((offer) => (
          <MenuItem
            key={offer.id}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
            offer={offer}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'never'... Remove this comment to see the full error message
            discountId={discountId}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'never'... Remove this comment to see the full error message
            discountName={discountName}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'never'... Remove this comment to see the full error message
            discountBadgeVisible={discountBadgeVisible}
          />
        ))}
      </ul>
    );
  }

  public render() {
    const { group } = this.props;
    return group.offers && group.offers.length > 0 ? <div>{this.renderGroupNames()}</div> : null;
  }
}
