import React from 'react';
import { VATSalesReportAnalytics } from 'common/analytics';
import { BaseCsvDownloadLink } from 'components/reports/newSalesReports/common/BaseCsvDownloadLink';
import { allEmployeesOptionValue } from 'components/reports/newSalesReports/EmployeeFilter/index';

type CsvDownloadLinkProps = {
  fromDate: any;
  toDate: any;
  selectedEmployeeId: number;
};
const CsvDownloadLink: React.SFC<CsvDownloadLinkProps> = (props) => {
  const employeeParam =
    props.selectedEmployeeId === allEmployeesOptionValue
      ? ''
      : `&employee=${props.selectedEmployeeId}`;
  const url = `reports/sales/vat/csv/?from=${props.fromDate.format(
    'YYYY-MM-DD',
  )}&to=${props.toDate.format('YYYY-MM-DD')}${employeeParam}`;
  return (
    <BaseCsvDownloadLink
      relativeReportUrl={url}
      onClick={VATSalesReportAnalytics.trackCsvDownloadClick}
    />
  );
};

export default CsvDownloadLink;
