import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as treatwellEmployeesActions from 'reduxStore/common/employees/actions';
import * as externalSalonIntegrationActions from '../actions';
import EmployeesMapping from './index';

const mapDispatchToProps = (dispatch) => ({
  treatwellEmployeesActions: bindActionCreators(treatwellEmployeesActions, dispatch),
  externalSalonIntegrationActions: bindActionCreators(externalSalonIntegrationActions, dispatch),
});

const mapStateToProps = ({ employees, externalSalonIntegration }) => ({
  treatwellEmployees: employees.items,
  externalSystemEmployees: externalSalonIntegration.externalEmployees,
  employeeLinks: externalSalonIntegration.employeeLinks,
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesMapping);
