import {
  GET_VAT_REPORT_RECEIVE,
  GET_VAT_REPORT_REQUEST,
  GET_VAT_REPORT_FAILURE,
  GET_SALES_REPORT_REQUEST,
  GET_SALES_REPORT_RECEIVE,
  GET_SALES_REPORT_FAILURE,
  GET_PRODUCT_SALES_REPORT_REQUEST,
  GET_PRODUCT_SALES_REPORT_RECEIVE,
  GET_PRODUCT_SALES_REPORT_FAILURE,
  GET_SERVICE_SALES_REPORT_REQUEST,
  GET_SERVICE_SALES_REPORT_RECEIVE,
  GET_SERVICE_SALES_REPORT_FAILURE,
  GET_EMPLOYEE_SALES_REPORT_REQUEST,
  GET_EMPLOYEE_SALES_REPORT_RECEIVE,
  GET_EMPLOYEE_SALES_REPORT_FAILURE,
  GET_CHANNEL_SALES_REPORT_REQUEST,
  GET_CHANNEL_SALES_REPORT_RECEIVE,
  GET_CHANNEL_SALES_REPORT_FAILURE,
  GET_DAILY_SALES_REPORT_REQUEST,
  GET_DAILY_SALES_REPORT_RECEIVE,
  GET_DAILY_SALES_REPORT_FAILURE,
  GET_MONTHLY_SALES_REPORT_REQUEST,
  GET_MONTHLY_SALES_REPORT_RECEIVE,
  GET_MONTHLY_SALES_REPORT_FAILURE,
  GET_RETTENTION_REPORT_REQUEST,
  GET_RETTENTION_REPORT_RECEIVE,
  GET_RETTENTION_REPORT_FAILURE,
  GET_CLIENT_ACQUISITION_REQUEST,
  GET_CLIENT_ACQUISITION_RECEIVE,
  GET_CLIENT_ACQUISITION_FAILURE,
  GET_POS_REPORT_DATA_REQUEST,
  GET_POS_REPORT_DATA_RECEIVE,
  GET_POS_REPORT_DATA_FAILURE,
} from 'reduxStore/actionsConst';
import { combineReducers } from 'redux';
import employeeFilterDataReducer from './EmployeeFilter/reducer';
import reportContainerReducer from './SalesReportContainer/reducer';
import generateReducer from './common/reportReducerGenerator';

const reportReducer = combineReducers({
  filterData: employeeFilterDataReducer,
  reportContainer: reportContainerReducer,
  sales: generateReducer(
    GET_SALES_REPORT_REQUEST,
    GET_SALES_REPORT_RECEIVE,
    GET_SALES_REPORT_FAILURE,
  ),
  productSales: generateReducer(
    GET_PRODUCT_SALES_REPORT_REQUEST,
    GET_PRODUCT_SALES_REPORT_RECEIVE,
    GET_PRODUCT_SALES_REPORT_FAILURE,
  ),
  serviceSales: generateReducer(
    GET_SERVICE_SALES_REPORT_REQUEST,
    GET_SERVICE_SALES_REPORT_RECEIVE,
    GET_SERVICE_SALES_REPORT_FAILURE,
  ),
  employeeSales: generateReducer(
    GET_EMPLOYEE_SALES_REPORT_REQUEST,
    GET_EMPLOYEE_SALES_REPORT_RECEIVE,
    GET_EMPLOYEE_SALES_REPORT_FAILURE,
  ),
  channelSales: generateReducer(
    GET_CHANNEL_SALES_REPORT_REQUEST,
    GET_CHANNEL_SALES_REPORT_RECEIVE,
    GET_CHANNEL_SALES_REPORT_FAILURE,
  ),
  dailySales: generateReducer(
    GET_DAILY_SALES_REPORT_REQUEST,
    GET_DAILY_SALES_REPORT_RECEIVE,
    GET_DAILY_SALES_REPORT_FAILURE,
  ),
  rettentionReport: generateReducer(
    GET_RETTENTION_REPORT_REQUEST,
    GET_RETTENTION_REPORT_RECEIVE,
    GET_RETTENTION_REPORT_FAILURE,
  ),
  acquisitionReport: generateReducer(
    GET_CLIENT_ACQUISITION_REQUEST,
    GET_CLIENT_ACQUISITION_RECEIVE,
    GET_CLIENT_ACQUISITION_FAILURE,
  ),
  monthlySales: generateReducer(
    GET_MONTHLY_SALES_REPORT_REQUEST,
    GET_MONTHLY_SALES_REPORT_RECEIVE,
    GET_MONTHLY_SALES_REPORT_FAILURE,
  ),
  vatReport: generateReducer(
    GET_VAT_REPORT_REQUEST,
    GET_VAT_REPORT_RECEIVE,
    GET_VAT_REPORT_FAILURE,
  ),
  posReport: generateReducer(
    GET_POS_REPORT_DATA_REQUEST,
    GET_POS_REPORT_DATA_RECEIVE,
    GET_POS_REPORT_DATA_FAILURE,
  ),
});

export default reportReducer;
