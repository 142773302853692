import App from 'common/backbone-app';

export const MAX_HIDDEN_PRECHAT_FIELD_CHARS = 100;

export const getExternalUserFullName = () => {
  const fullName = App.config.accountFullName();

  const isFullNameShortEnough = fullName.length <= MAX_HIDDEN_PRECHAT_FIELD_CHARS;
  if (isFullNameShortEnough) {
    return fullName;
  }

  const firstName = App.config.get('account')?.firstName;

  return firstName?.substring(0, MAX_HIDDEN_PRECHAT_FIELD_CHARS);
};
