import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Hamburger extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-hamburger', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 48 48"
      >
        <path d="M39 31c1.657 0 3 1.343 3 3s-1.343 3-3 3H9c-1.657 0-3-1.343-3-3s1.343-3 3-3h30zm0 2H9c-.552 0-1 .448-1 1 0 .513.386.936.883.993L9 35h30c.552 0 1-.448 1-1s-.448-1-1-1zm0-12c1.657 0 3 1.343 3 3s-1.343 3-3 3H9c-1.657 0-3-1.343-3-3s1.343-3 3-3h30zm0 2H9c-.552 0-1 .448-1 1 0 .513.386.936.883.993L9 25h30c.552 0 1-.448 1-1s-.448-1-1-1zm0-12c1.657 0 3 1.343 3 3s-1.343 3-3 3H9c-1.657 0-3-1.343-3-3s1.343-3 3-3h30zm0 2H9c-.552 0-1 .448-1 1 0 .513.386.936.883.993L9 15h30c.552 0 1-.448 1-1s-.448-1-1-1z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Hamburger);
