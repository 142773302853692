import Wahanda from 'common/wahanda';
import { ShiftWorkingHourType, WorkingHoursObject } from '../utils/types';

export const rotaPermissions = (payload: {
  employeeId?: number;
  employeeWorkingHours?: WorkingHoursObject;
  venueWorkingHours?: WorkingHoursObject;
}): {
  canAddTimeOff: boolean;
  canEditEmployeeHours: boolean;
  canEditVenueHours: boolean;
  canRemoveDayOff: boolean;
} => {
  const { employeeId, employeeWorkingHours, venueWorkingHours } = payload;
  const isSalonClosed = venueWorkingHours && venueWorkingHours.timeSlots.length === 0;
  const isDayClosed = !!employeeWorkingHours && employeeWorkingHours.timeSlots.length === 0;
  const canEditEmployeeHours = Wahanda.Permissions.editRotaCalendar(employeeId) || false;
  const canEditDay = canEditEmployeeHours && !isSalonClosed;
  const scheduleId = employeeWorkingHours?.schedule?.scheduleId;

  return {
    canAddTimeOff: canEditDay && !isDayClosed,
    canEditEmployeeHours: canEditDay,
    canEditVenueHours: Wahanda.Permissions.manageVenueDetails() || false,
    canRemoveDayOff:
      canEditDay &&
      isDayClosed &&
      (!scheduleId || employeeWorkingHours?.type === ShiftWorkingHourType.CUSTOM),
  };
};
