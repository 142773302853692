import { reducer, on } from 'ts-action';
import * as AutomatedMessagingActions from './actions';
import { SETTING, AutomatedMessagingSettings, ReminderSettings } from './types';

type Loaders = AutomatedMessagingSettings;

export interface AutomatedMessagingState extends AutomatedMessagingSettings, ReminderSettings {
  isLoading: boolean;
  loaders: Loaders;
}

export const initialState: AutomatedMessagingState = {
  [SETTING.GENERAL]: true,
  [SETTING.SEND_HELPFUL_REMINDERS]: true,
  [SETTING.ASK_FOR_REVIEWS]: true,
  [SETTING.INVITES_TO_REBOOK]: true,
  [SETTING.CONSENT_FOR_APPVOCACY]: true,
  [SETTING.SEND_BIRTHDAY_CONGRATULATIONS]: true,
  [SETTING.SEND_PATCH_TEST_INVITES]: true,
  sendEmailReminders: true,
  sendSmsReminders: true,
  loaders: {
    [SETTING.GENERAL]: false,
    [SETTING.SEND_HELPFUL_REMINDERS]: false,
    [SETTING.ASK_FOR_REVIEWS]: false,
    [SETTING.INVITES_TO_REBOOK]: false,
    [SETTING.CONSENT_FOR_APPVOCACY]: false,
    [SETTING.SEND_BIRTHDAY_CONGRATULATIONS]: false,
    [SETTING.SEND_PATCH_TEST_INVITES]: false,
  },
  isLoading: true,
};

const changedLoaders = (keys: SETTING[], value = true) => {
  const loaders = {};
  keys.forEach((key) => {
    loaders[key] = value;
  });
  return loaders;
};

export const automatedMessaging = reducer(
  initialState,
  on(AutomatedMessagingActions.automatedMessagingSettingsRequest, (state) => ({
    ...state,
  })),
  on(AutomatedMessagingActions.automatedMessagingSettingsSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  })),
  on(AutomatedMessagingActions.setAutomatedMessagingSettingRequest, (state, action) => ({
    ...state,
    loaders: {
      ...state.loaders,
      ...changedLoaders(Object.keys(action.payload) as SETTING[]),
    },
  })),
  on(AutomatedMessagingActions.setAutomatedMessagingSettingSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    loaders: {
      ...state.loaders,
      ...changedLoaders(Object.keys(action.payload) as SETTING[], false),
    },
  })),
  on(AutomatedMessagingActions.setReminderSettingsRequest, (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [SETTING.SEND_HELPFUL_REMINDERS]: true,
    },
  })),
  on(AutomatedMessagingActions.setReminderSettingsSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    loaders: {
      ...state.loaders,
      [SETTING.SEND_HELPFUL_REMINDERS]: false,
    },
  })),
  on(AutomatedMessagingActions.automatedMessagingSettingsFailure, (state) => ({
    ...state,
    loaders: {
      ...initialState.loaders,
    },
  })),
  on(AutomatedMessagingActions.automatedMessagingReset, () => ({ ...initialState })),
);
