/**
 * Employee category.
 */
App.Models.EmployeeCategory = BackboneEx.Model.Silent.extend({
  defaults: {
    venueId: null,
  },
  /**
   * Sets fields to save based on model state.
   *
   * @see BackboneEx.Model.Silent.save
   */
  attributesToSave: function () {
    var fields = ['name'];
    if (this.get('id')) {
      fields.push('id');
    }
    return fields;
  },

  initialize: function () {},

  url: function () {
    var id = this.get('id');
    var base;
    if (id > 0) {
      base = '/venue/{{venueId}}/employee-category/{{id}}.json';
    } else {
      base = '/venue/{{venueId}}/employee-category.json';
    }
    return App.Api.wsUrl(Wahanda.Template.render(base, { id: id, venueId: this.get('venueId') }));
  },
});
