/**
 * Does CSS animation (class toggling, really) on list of elements.
 */
$.fn.colorNotifyChange = function () {
  if (this.length === 0) {
    return;
  }

  var DURATION = 1000;
  var items = this;

  function cleanUp () {
    items.removeClass("animate-bg-once");
  }

  cleanUp();
  items.addClass("animate-bg-once");

  window.setTimeout(cleanUp, DURATION);
};
