import Cookie from 'js-cookie';
import { xhr } from 'common/xhr';

Wahanda.handleLinkPage = function (object, ticket, venueId) {
  xhr.doJQueryAjax({
    url: App.Api.wsUrl('/decode-link/' + object),
    data: { ticket: ticket },
    type: 'get',
    success: function (data) {
      var url = '';
      switch (object) {
        case 'appointment':
        case 'booking':
        case 'order':
          var authPart = '';
          if (data.authenticationKey) {
            App.setLightweightAuthKey(data.authenticationKey);
            authPart = window.encodeURIComponent(data.authenticationKey);
          }

          venueId || (venueId = _extractVenueId(data));

          if (data.appointmentId) {
            url = getAppointmentUrl(data, venueId, authPart);
          } else if (data.orderId) {
            url = getOrderUrl(data, venueId, authPart);
          } else {
            forwardToBooking(data, venueId, authPart);
            return;
          }
          break;

        case 'profile':
          url = getProfileSetupUrl(data);
          break;
      }

      if (url) {
        window.location = url;
      }
    },

    error: function (jqXHR) {
      switch (jqXHR.status) {
        case 401:
          Wahanda.Dialogs.Alert(Wahanda.lang.link.errors.wrongTicket);
          break;

        case 403:
          Wahanda.Dialogs.Alert(Wahanda.lang.link.errors.wrongObject);
          break;
      }
    },
  });

  function getAppointmentUrl(data, venueId, authPart) {
    authPart = authPart ? '/lwauth/' + authPart : '';

    var base =
      App.getFullConnectRootUrl() +
      '/calendar' +
      _getUrlBase(venueId, data) +
      'appointment/appointment/' +
      data.appointmentId +
      authPart;

    switch (data.action) {
      case 'reject':
        return base + '/action/reject';

      case 'confirm':
        return base + '/action/confirm';

      default:
        return base;
    }
  }

  function getOrderUrl(data, venueId, authPart) {
    authPart = authPart ? '/lwauth/' + authPart : '';
    var base =
      App.getFullConnectRootUrl() +
      '/calendar' +
      _getUrlBase(venueId, data) +
      'appointment/appt-order/' +
      data.orderId +
      authPart;

    switch (data.action) {
      case 'reject':
        return base + '/action/reject';

      case 'confirm':
        return base + '/action/confirm';

      default:
        return base;
    }
  }

  // eVouchers redirect always to full Connect
  function getEvoucherUrl(data, venueId, authPart) {
    var base = _getUrlBase(venueId, data);
    authPart = authPart ? '/lwauth/' + authPart : '';

    return (
      App.getFullConnectRootUrl() +
      '/dashboard' +
      base +
      'dialog/booking/' +
      data.bookingId +
      authPart
    );
  }

  function getDatedOrderUrl(bookingModel, data, venueId, authPart) {
    var base = _getUrlBase(venueId, data);
    var type = bookingModel.isSpaBreak() ? 'spa-break' : 'spa-day';
    var date = bookingModel.get('dated').itemDate;
    var action = data.action || 'review';
    authPart = authPart ? '/lwauth/' + authPart : '';

    return (
      App.getFullConnectRootUrl() +
      '/calendar' +
      base +
      type +
      '/' +
      date +
      '/openOrder/' +
      data.orderId +
      authPart +
      '/action/' +
      action
    );
  }

  function forwardToBooking(data, venueId, authPart) {
    var booking = new App.Models.Booking({
      id: data.bookingId,
      venueId: venueId,
    });
    var forward = function () {
      var url;
      if (booking.get('dated')) {
        url = getDatedOrderUrl(
          booking,
          _.extend({ orderId: booking.get('orderId') }, data),
          venueId,
          authPart,
        );
      } else {
        url = getEvoucherUrl(data, venueId, authPart);
      }
      window.location = url;
    };
    booking.fetch({
      success: forward,
      error: BackboneEx.Tool.ModelLightweightFetch({
        onSuccess: forward,
        onError: function () {
          // On login failed, show login redirect dialog
          App.Views.LoginRequest.show();
        },
        customErrorOnUnknownCode: true,
        lightweightOptions: {
          // Do not try to fetch extranet-settings
          relogin: false,
        },
        otherStatuses: [401],
      }),
    });
  }

  function getProfileSetupUrl(data) {
    return (
      App.getFullConnectRootUrl() +
      '/profile/#venue/' +
      data.venueId +
      '/setup-profile/' +
      data.accountId +
      '/auth/' +
      data.authenticationKey
    );
  }

  function _getUrlBase(venueId, data) {
    var base = '#';
    if (venueId) {
      base += 'venue/' + venueId + '/';
    }

    // Setting new venueId
    Cookie.set('extranet.currentVenueId', data.venueId);

    return base;
  }

  function _extractVenueId(data) {
    var venueId = null;
    if (_.isArray(data.venueIds) && data.venueIds[0] > 0) {
      venueId = data.venueIds[0];
    } else if (data.venueId) {
      venueId = data.venueId;
    }
    return venueId;
  }
};
