/* eslint react/no-danger: 0 */
import React from 'react';

import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { Checkbox } from 'components/common/Checkbox';

import { InlineClientCreation } from './InlineClientCreation';
import { ReadOnlyClientInfo } from './ReadOnlyClientInfo';

import style from './InlineClient.scss';
import { UDVAppAdvocacyBanner } from 'components/calendar/UDVAppAdvocacyBanner';
import { Notice } from 'components/common/Notice';

const LANG = Wahanda.lang.inlineClientEditing.messages;

export interface Props {
  actions: {
    setSendMassEmail?: (param) => void;
  };
  dataSource: () => void;
  isReadOnly: boolean;
  isRepeat?: boolean | null;
  id?: number | null;
  showClientInfo: (param) => void;
  toggleIgnoreKeyboardEvents: () => void;
  messageForUser?: string | null;
  renderCallback?: () => void;
  showGDPR: boolean;
  sendMassEmails: boolean;
}

export class InlineClient extends React.PureComponent<Props> {
  constructor(props) {
    super(props);

    this.onEditFormDialogOpen = this.onEditFormDialogOpen.bind(this);
  }

  public static defaultProps = {
    id: null,
    messageForUser: null,
    sendMassEmails: false,
    isRepeat: null,
  };

  public componentDidUpdate() {
    const { renderCallback } = this.props;

    // renderCallback is needed to notify the dialog about a possible height change
    if (renderCallback) {
      renderCallback();
    }
  }

  private onEditFormDialogOpen(options) {
    App.ES6.Initializers.CustomerFormDialog({
      id: this.props.id,
      ...options,
    }).render();
  }

  private inlineClientRef: any;

  public submit = () => {
    if (this.inlineClientRef == null) {
      return;
    }
    this.inlineClientRef.getWrappedInstance().submitForm();
  };

  public isValid = () => {
    if (this.inlineClientRef == null) {
      return true;
    }
    return this.inlineClientRef.getWrappedInstance().state.isValid;
  };

  private sendMassEmails = () => {
    const {
      actions: { setSendMassEmail },
      sendMassEmails,
    } = this.props;
    if (setSendMassEmail) {
      setSendMassEmail(!sendMassEmails);
    }
  };

  private renderForm = () => {
    const { actions, dataSource, toggleIgnoreKeyboardEvents } = this.props;
    return (
      <InlineClientCreation
        actions={actions}
        dataSource={dataSource}
        onEditTrigger={this.onEditFormDialogOpen}
        toggleIgnoreKeyboardEvents={toggleIgnoreKeyboardEvents}
        ref={(ref) => {
          this.inlineClientRef = ref;
        }}
      />
    );
  };

  private renderReadOnlyClientInfo = () => {
    const { actions, isRepeat, showClientInfo } = this.props;
    return (
      <ReadOnlyClientInfo
        actions={actions}
        isRepeat={isRepeat}
        showClientInfo={showClientInfo}
        onEditTrigger={this.onEditFormDialogOpen}
      />
    );
  };

  private renderGDPR = () => {
    const { sendMassEmails, showGDPR } = this.props;

    if (!showGDPR) {
      return null;
    }

    return (
      <div className={style.gdprMessage}>
        <p dangerouslySetInnerHTML={{ __html: LANG.gdprHeader }} />
        <Checkbox
          className={style.checkbox}
          name="gdpr-mass-emails"
          checked={sendMassEmails}
          onChange={this.sendMassEmails}
        >
          <span dangerouslySetInnerHTML={{ __html: LANG.gdprMessage }} />
        </Checkbox>
      </div>
    );
  };

  private renderMessageForUser = () => {
    const { messageForUser, isReadOnly, showGDPR } = this.props;

    if (!isReadOnly && showGDPR) {
      return this.renderGDPR();
    }

    if (messageForUser === null) {
      return null;
    }

    return <Notice className={style.message} message={messageForUser} type="info" />;
  };

  public render() {
    const { isReadOnly } = this.props;
    const content = isReadOnly ? this.renderReadOnlyClientInfo() : this.renderForm();
    return (
      <div className={style.container}>
        {content}
        {this.renderMessageForUser()}
        <UDVAppAdvocacyBanner />
      </div>
    );
  }
}
