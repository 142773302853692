import { connect } from 'react-redux';
import { ClientsImport as Content } from './ClientsImport';
import { clientsFileUploadSuccess } from './actions';
import { bindActionCreators } from 'redux';

const mapStateToProps = ({ clients }) => ({
  stage: clients.clientsImport.stage,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ clientsFileUploadSuccess }, dispatch),
});

export const ClientsImport = connect(mapStateToProps, mapDispatchToProps)(Content);
