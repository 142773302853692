import React from 'react';
import classnames from 'classnames';
import { Info } from 'components/common/Icon';
import style from './Notice.scss';

export interface NoticeProps {
  boldMessage?: string;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  dataTest?: string;
  icon?: React.ReactNode;
  type?: 'expose' | 'success' | 'info' | 'alert';
  message?: React.ReactNode;
}

export const Notice = ({
  type = 'expose',
  boldMessage,
  message,
  className,
  children,
  dataTest,
  icon,
}: NoticeProps) => {
  const boldText = boldMessage ? <strong key={0}>{boldMessage}</strong> : '';
  const spaceBetweenText = message && boldMessage ? ' ' : '';
  const normalText = message ? <span key={1}>{message}</span> : '';

  const wrapperClass = classnames(style.message, className, style[type]);
  return (
    <div className={wrapperClass}>
      {icon ? <span className={style.icon}>{icon}</span> : <Info className={style.icon} />}
      <div data-test={dataTest || 'notice-message'}>{[boldText, spaceBetweenText, normalText]}</div>
      {children && <div className={style.noShrink}>{children}</div>}
    </div>
  );
};

Notice.displayName = 'Notice';
