import React from 'react';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import Dialog from 'components/common/react-dialog';
import { trackEvent, NoShowFlowAnalytics, ACTIONS as TRACKING_ACTION } from 'common/analytics';

import style from './NoShowOverPhone.scss';

const LANG = Wahanda.lang.calendar.appointments.noShow.requestNoShowPhone;

interface Props {
  onClose: () => void;
}

export class NoShowOverPhone extends React.Component<Props> {
  public componentDidMount() {
    trackEvent(NoShowFlowAnalytics.CATEGORY, TRACKING_ACTION.VIEW, 'please-call');
  }

  public render() {
    const { onClose } = this.props;
    const supplierPhone = App.config.get('channel').supplierSupportPhoneNumber;

    return (
      <Dialog
        dataTest="no-show-over-phone-modal"
        width={350}
        title={LANG.title}
        onClose={onClose}
        classes={style.dialog}
      >
        <div className={style.container}>
          {LANG.heading}
          <div className={style.phone}>{supplierPhone}</div>
        </div>
      </Dialog>
    );
  }
}
