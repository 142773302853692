import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Nails extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-nails', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path
              d="M3 8v2H2v4h6v-4H7V8h1c1.105 0 2 .895 2 2v6H0v-6c0-1.105.895-2 2-2h1zm10.5-8C14.88 0 16 1.12 16 2.5v11c0 1.38-1.12 2.5-2.5 2.5S11 14.88 11 13.5v-11C11 1.12 12.12 0 13.5 0zm0 2c-.245 0-.45.177-.492.41L13 2.5v11c0 .276.224.5.5.5.245 0 .45-.177.492-.41L14 13.5v-11c0-.276-.224-.5-.5-.5zM6 0c1.105 0 2 .895 2 2v5H6v6H4V7H2V2c0-1.105.895-2 2-2h2zm0 2H4v3h2V2z"
              fillRule="evenodd"
            />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-nails', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 24 24"
          >
            <path
              d="M9 1c1.105 0 2 .895 2 2v7H9v5.275l.328.103c1.08.334 1.851.51 2.673.582L12 14c0-.513-.386-.936-.883-.993L11 13h-1v-2h1c1.657 0 3 1.343 3 3v9H2v-9c0-1.657 1.343-3 3-3h1v2H5c-.513 0-.936.386-.993.883L4 14l.001.033c.97.069 1.841.25 2.999.598V10H5V3c0-1.105.895-2 2-2h2zm10 0c1.657 0 3 1.343 3 3v16c0 1.657-1.343 3-3 3s-3-1.343-3-3V4c0-1.657 1.343-3 3-3zM4 16.04V21h8v-3.033c-.97-.069-1.841-.25-2.998-.598L9 20H7v-3.276l-.328-.102c-1.08-.334-1.85-.51-2.672-.582zM19 3c-.513 0-.936.386-.993.883L18 4v16c0 .552.448 1 1 1 .513 0 .936-.386.993-.883L20 20V4c0-.552-.448-1-1-1zM9 3H7v5h2V3z"
              fillRule="evenodd"
            />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Nails);
