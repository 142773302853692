import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Venue extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-venue', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M10,20 L12,20 L12,16 L10,16 L10,20 Z M8,14.997 C8,14.447 8.451,14 8.99,14 L13.01,14 C13.556,14 14,14.453 14,14.997 L14,22 L8,22 L8,14.997 Z M21.152,3.826 C20.98,2.803 20.04,2 18.999,2 L5.001,2 C3.956,2 3.021,2.797 2.848,3.826 L2.123,8.18 C2.042,8.444 2,8.72 2,9 C2,10.685 3.48,12.0000151 5.25,12.0000151 C5.855,12.0000151 6.436,11.846 6.938,11.565 C7.45361361,11.8516431 8.03406809,12.0014044 8.624,12.0000151 C9.229,12.0000151 9.81,11.846 10.312,11.565 C10.8282054,11.8519742 11.4093909,12.0017465 12,12.0000151 C12.605,12.0000151 13.186,11.846 13.688,11.565 C14.2039095,11.8518086 14.7847295,12.0015754 15.375,12.0000151 C15.98,12.0000151 16.561,11.846 17.063,11.565 C17.5789095,11.8518086 18.1597295,12.0015754 18.75,12.0000151 C20.52,12.0000151 22,10.685 22,9 C22,8.72 21.958,8.444 21.877,8.18 L21.152,3.826 Z M19.916,8.581 L19.956,8.741 C19.986,8.824 20,8.911 20,9 C20,9.524 19.465,10 18.75,10 C18.36,10 18.007,9.855 17.772,9.618 L17.062,8.904 L16.352,9.618 C16.0895518,9.86934772 15.7383321,10.0066721 15.375,10 C14.985,10 14.632,9.855 14.397,9.618 L13.687,8.904 L12.977,9.618 C12.7145518,9.86934772 12.3633321,10.0066721 12,10 C11.61,10 11.257,9.855 11.022,9.618 L10.312,8.904 L9.602,9.618 C9.33955182,9.86934772 8.98833209,10.0066721 8.625,10 C8.235,10 7.882,9.855 7.647,9.618 L6.937,8.904 L6.227,9.618 C5.96455182,9.86934772 5.61333209,10.0066721 5.25,10 C4.535,10 4,9.524 4,9 C4,8.911 4.015,8.824 4.043,8.74 L4.083,8.581 L4.821,4.155 C4.832,4.088 4.936,4 5.001,4 L18.999,4 C19.059,4 19.169,4.093 19.179,4.155 L19.916,8.581 L19.916,8.581 Z" />
        <path d="M4,11.1 L4,19.997 C4,21.107 4.893,22 5.994,22 L18.006,22 C19.1093975,21.996691 20.0016601,21.1004013 20,19.997 L20,14 L18,14 C18,16.617 18.002,20 18.006,20 C18.006,20 6,19.999 6,19.997 C6,19.997 6,12.084 5.995,11.1 L4,11.1 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Venue);
