/**
 * @mixin BackboneEx.Mixin.View.Datepicker
 */
import { trackEvent } from 'common/analytics';

App.Views.Forms.Calendar.RoomAvailability = BackboneEx.View.Dialog.ValidatingForm.extend({
  events: {
    'click .button-cancel': 'close',
    'change input[name=room-availability-closed]': 'toggleSingleRoomAvailability',
  },
  options: {
    title: Wahanda.lang.calendar.spaDays.spaAvailabilityForm.title,
    width: 460,
    dateFrom: null,
    dateTo: null,
    dialogClass: 'room-availability-form',
    dataTest: 'room-availability-modal',
  },

  addToCollection: null,
  fieldPrefix: /^room\-availability\-/,
  templateId: 'room-availability-form-template',

  onDialogOpen: function () {
    BackboneEx.View.Dialog.ValidatingForm.prototype.onDialogOpen.call(this);
    $.dateFieldAlign.connect({
      first: this.$('#room-availability-dateFrom').blur().venueDatepicker(),
      later: this.$('#room-availability-dateTo').venueDatepicker(),
    });
  },

  postInitialize: function () {
    trackEvent('calendar', 'view', 'room-availability-modal');

    this.model = new App.Models.RoomAvailabilitySaver();

    if (this._isDatesPreset()) {
      this.weekdaysView = new App.Views.Partials.WeekdayCheckboxes({
        el: this.$('.weekdays'),
        lastCanBeDeselectedText:
          Wahanda.lang.calendar.spaDays.spaAvailabilityForm.canNotDeselectLastDay,
      });
    }
  },

  elementToFocus: function () {
    if (this._isSingleRoom()) {
      this.$('#room-availability-inventoryAvailable').focus();
    } else {
      this.$('li.on:first').find('.room-price input').focus();
    }
  },

  initForm: function () {
    this.setDefaultValues();
    if (this._isDatesPreset()) {
      this.weekdaysView.render();
      this.$('.b-preset-dates').hide();
    } else {
      this.renderDayRanges();
      this.$('.b-manual-dates').hide();
    }

    if (this._isSingleRoom()) {
      this.renderSingleRoom();
    } else {
      this.renderRoomTypes();
    }
  },

  renderRoomTypes: function () {
    var list = [];
    this.collection.each(function (roomType) {
      list.push({
        id: roomType.id,
        roomName: roomType.get('name'),
      });
    });
    var $container = this.$('.b-room-types');
    var toggledList = new App.Views.Partials.ToggleableList({
      el: $container,
    });
    toggledList.render('room-availability-form-room', { list: list });
    $container.wShow();
  },

  renderSingleRoom: function () {
    var model = this.collection.get(this.options.roomTypeId);
    var name = model ? model.get('name') : '';
    this.renderAttribute('single-room', name);

    if (this.options.inventoryAvailable) {
      this.$('#room-availability-inventoryAvailable').val(this.options.inventoryAvailable);
    }
  },

  renderDayRanges: function () {
    this.$('.v-dates').text(
      Wahanda.lang.date.months[this.options.date.getMonth()] +
        ' ' +
        Wahanda.Util.combineRanges(this.options.days).join(', '),
    );
  },

  setDefaultValues: function () {
    if (this.options.dateFrom) {
      this.$('#room-availability-dateFrom').val(
        Wahanda.Date.formatToDefaultDate(this.options.dateFrom),
      );
    }
    if (this.options.dateTo) {
      this.$('#room-availability-dateTo').val(
        Wahanda.Date.formatToDefaultDate(this.options.dateTo),
      );
    }
  },

  setValidation: function () {
    this._setDefaultValidation();
  },

  getModelValues: function () {
    var formValues = BackboneEx.View.Dialog.ValidatingForm.prototype.getModelValues.call(this);
    var self = this;
    var values = {
      roomTypes: [],
    };

    // Dates
    if (this._isDatesPreset()) {
      values.dateRange = {
        dateFrom: Wahanda.Date.toApiString(
          this.$('#room-availability-dateFrom').datepicker('getDate'),
        ),
        dateTo: Wahanda.Date.toApiString(this.$('#room-availability-dateTo').datepicker('getDate')),
        weekdays: this.weekdaysView.getValues(),
      };
    } else {
      values.dates = Wahanda.Date.getMonthDateApiStrings(this.options.date, this.options.days);
    }
    // Room type defaults
    if (this._isSingleRoom()) {
      var isClosed = self.$('#room-availability-closed-yes').prop('checked');
      var room = {
        roomTypeId: this.options.roomTypeId,
        inventoryAvailable: null,
        closed: isClosed,
      };

      if (!isClosed) {
        room.inventoryAvailable = parseInt(
          self.$('#room-availability-inventoryAvailable').val(),
          10,
        );
      }

      values.roomTypes.push(room);
    } else {
      this.collection.each(function (roomType) {
        var id = roomType.id;
        var isClosed = !self.$('#room-availability-open-' + id).prop('checked');
        var room = {
          roomTypeId: id,
          inventoryAvailable: null,
          closed: isClosed,
        };

        if (!isClosed) {
          room.inventoryAvailable = parseInt(
            self.$('#room-availability-inventoryAvailable-' + id).val(),
            10,
          );
        }

        values.roomTypes.push(room);
      });
    }

    return values;
  },

  _afterSaveCallback: function () {
    BackboneEx.View.Dialog.ValidatingForm.prototype._afterSaveCallback.call(this);

    var dates = this.model.get('dates') || this.model.get('dateRange');
    App.trigger(Wahanda.Event.CALENDAR_AVAILABILITY_SAVED, {
      forDates: dates,
      type: 'spa-break',
      source: 'availability',
    });
  },

  _isDatesPreset: function () {
    return this.options.dateFrom && null == this.options.days;
  },

  _isSingleRoom: function () {
    return this.options.roomTypeId > 0;
  },

  // Events

  toggleSingleRoomAvailability: function (event) {
    var isOpen = $(event.currentTarget).val() === 'false';
    this.$('#room-availability-inventoryAvailable').toggleFormElements(isOpen);
  },
});

BackboneEx.Mixin.extendView(
  App.Views.Forms.Calendar.RoomAvailability,
  BackboneEx.Mixin.View.Datepicker,
);
