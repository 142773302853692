import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { Select, SelectProps } from 'components/common/Select';
import { OptionValue } from './utils/types';

interface Params extends SelectProps<OptionValue> {
  node: HTMLElement;
  data: any;
  selected?: any;
  typeahead?: boolean;
}

export const SelectInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(params.node),
  render: () => {
    ReactDOM.render(
      <Select
        name={params.dataTest || ''}
        options={params.data}
        dataTest={params.dataTest}
        disabled={params.disabled}
        focusInput={params.focusInput}
        optionsFooter={params.optionsFooter}
        onSelect={params.onSelect}
        onSearchInputChange={params.onSearchInputChange}
        onStateChange={params.onStateChange}
        placeholderNoResult={params.placeholderNoResult}
        selectedOption={params.selected}
        isSearchable={params.typeahead}
        isOptionsFooterSticky={params.isOptionsFooterSticky}
      />,
      params.node,
    );
  },
});
