import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTreatmentGroups, getIsLoaded } from 'components/marketing/EmailCampaign/selectors';
import { getTreatmentGroupsRequest, selectTreatmentGroup } from '../../actions';
import ServiceOnSaleSelect from './index';

const mapStateToProps = (state) => ({
  serviceOnSaleTreatmentGroups: getTreatmentGroups(state),
  isLoaded: getIsLoaded(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getTreatmentGroupsRequest,
      selectTreatmentGroup,
    },
    dispatch,
  ),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ serviceOnSaleTreatmentGroups: null; }' is ... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(ServiceOnSaleSelect);
