/* eslint-disable func-names */
/* global BackboneEx _ */
import { formatName } from 'utilities/menu/sku-helpers';

(() => {
  const Base = BackboneEx.View.Base;

  App.Views.Forms.MenuOffer2.Pricing.PackageServices.Item = Base.extend({
    templateId: 'offer2-package-services-item-template',
    menuTemplateId: 'offer2-package-services-item-menu-template',
    menu: null,
    attentionPlaceholder: '.js-offer-2--package-new-service-warning',

    events: {
      'focus .currency-container input': 'addActive',
      'blur .currency-container input': 'removeActive',
    },

    render: function () {
      const employeeCategories = this.options.employeeCategories;
      const employeeCategoriesIds = employeeCategories.map((o) => o.id);
      const modelSkus = this.model.skus.filter((e) =>
        employeeCategoriesIds.includes(e.employeeCategoryId),
      );

      const skuIds = this.getSkuIds(modelSkus);

      const skus = modelSkus.map((sku) =>
        Object.assign({}, sku, {
          name: formatName(sku, employeeCategories),
        }),
      );
      const currencyInfo = App.config.get('currency');
      const currencySymbol = currencyInfo.symbol || currencyInfo.currencyCode;

      this.setElement(
        Wahanda.Template.renderTemplate(this.templateId, {
          id: this.model.serviceId,
          name: this.model.name,
          price: this.model.price,
          singleSku: skus.length === 1 && skus[0].name ? skus[0] : false,
          multiSkus: this.model.multiSkuSelection ? skus : false,
          skuIds: skuIds,
          sortable: true,
          masterService: this.model.master,
          tempId: this.model.tempId,
          showId: App.isMasquerading() && this.model.serviceId,
          durationList: this.model.durationList,
          currencySymbol: currencySymbol,
        }),
      );

      this.$('.js-button-menu').html(
        Wahanda.Template.renderTemplate(this.menuTemplateId, {
          isMaster: this.model.master,
        }),
      );

      const attentionContainer = this.$(this.attentionPlaceholder)[0];
      if (attentionContainer) {
        App.ES6.Initializers.Notice({
          node: attentionContainer,
          message: Wahanda.lang.menu.offer.packages.hiddenServices,
        }).render();
      }
    },

    remove() {
      Base.prototype.remove.call(this);
      const attentionContainer = this.$(this.attentionPlaceholder)[0];
      if (attentionContainer) {
        App.ES6.Initializers.Notice({ node: attentionContainer }).destroy();
      }
    },

    getSkuIds: function (skus) {
      return _.filter(skus, function (sku) {
        return sku.id;
      });
    },

    addActive: function (event) {
      $(event.target).closest('.currency-container').addClass('active');
    },

    removeActive: function (event) {
      $(event.target).closest('.currency-container').removeClass('active');
    },
  });
})();
