/* eslint-disable */

App.Views.Dialog.Booking = BackboneEx.View.Dialog.extend(
  {
    events: {
      'click .button-cancel': 'close',
      'click .a-confirm-booking': 'confirmBooking',
      'click .a-reject-booking': 'rejectBooking',
      'click .a-show-customer': 'onShowCustomer',
    },
    options: {
      width: 660,
      title: Wahanda.lang.bookingDialog.title,
      updateUrl: true,
      dataTest: 'booking-modal',
    },
    templateId: 'booking-dialog-template',

    postInitialize: function () {
      var self = this;
      this.refetch();

      this.previousUrl = this.options.previousUrl || window.location.hash;
      if (this.options.updateUrl) {
        this.setBookingUrl();
      }

      App.on(
        ['appointment:saved', Wahanda.Event.EVOUCHER_REDEEMED].join(' '),
        function () {
          this.refetch();
        },
        this,
      ).on(
        'booking:rejected booking:confirmed',
        function (modelId) {
          if (this.model.id === modelId) {
            this.close();
          }
        },
        this,
      );
    },

    refetch: function () {
      var self = this;
      var $content = this.$('.dialog-content');
      var onSuccess = function () {
        self.setTypeDialogOptions();
        self.fillValues();
        self.renderTypeView();
        $content.unloadmask();
        self.analytics();
      };

      $content.loadmask();

      this.model.fetch({
        success: onSuccess,
        error: BackboneEx.Tool.ModelLightweightFetch({
          onSuccess: onSuccess,
          onError: function () {},
        }),
        skipErrorHandling: true,
      });
    },

    setupDialog: function () {
      // If the model is set up, we can render already
      if (this.model.get('created')) {
        this.fillValues();
      }
    },

    analytics: function () {
      var eventData = { id: this.model.id, type: this.model.getTypeString() };
      if (this.options.openedFrom) {
        eventData.openedFrom = this.options.openedFrom;
      }
      App.trigger(Wahanda.Event.BOOKING_OPENED, eventData);
    },

    setTypeDialogOptions: function () {
      var isEvoucher = this.model.get('bookingType') === 'SV';

      if (isEvoucher) {
        this.$el.addClass('with-evouchers');
      }
    },

    /**
     * Fill in values from model.
     */
    fillValues: function () {
      var simpleFields = ['id', 'orderReference', 'quantity'];
      for (var i in simpleFields) {
        var name = simpleFields[i];
        var search = '.value-' + name;
        this.$(search).text(this.model.get(name));
      }

      this.fillCustomerValues();

      this.$('.price').text(Wahanda.Currency.format(this.model.get('itemOriginalUnitAmount')));
      this.$('.v-price-title').text(
        Wahanda.lang.bookingDialog.labels[this.model.isUnpaid() ? 'unpaidPrice' : 'price'],
      );
      this.$('.value-totalAmount').text(Wahanda.Currency.format(this.model.get('itemAmount')));

      var serviceTitle = this.$('.service-title');
      serviceTitle.text(this.model.get('itemName'));
      if (this.model.get('variantName')) {
        serviceTitle.append(
          $('<span class="service-sku"></span>').text(' - ' + this.model.get('variantName')),
        );
      }
      if (this.model.get('supplierReference')) {
        serviceTitle.append(
          $('<span class="service-subtitle"></span>').text(this.model.get('supplierReference')),
        );
      }

      this.$('.value-source').text(
        this.model.get('partnerName')
          ? this.model.get('partnerName')
          : Wahanda.lang.shared.defaultPartner,
      );

      var date = Wahanda.Date.formatToDefaultFullDate(
        Wahanda.Date.parse(this.model.get('created')),
      );
      this.$('.value-date').text(date.date + ', ' + date.time);

      this.renderGiftBlock();
    },

    fillCustomerValues: function () {
      var $customerName = this.$('.value-customerName').text(this.model.get('customer').name);
      if (this.model.get('customer').id) {
        $customerName.wrap('<a href="javascript:;" class="a-show-customer"></a>');
      }

      var delivery = this.model.get('delivery');
      var phone = this.model.get('customer').phoneNumber;
      var email = this.model.get('customer').emailAddress;
      // override phone number and name if delivered by post and overriden there
      var customerAddress;
      if (delivery && delivery.deliveryMethodCode == 'PO') {
        if (delivery.contactPhoneNumber) {
          phone = delivery.contactPhoneNumber;
        }
        if (delivery.recipientName) {
          customerName = delivery.recipientName;
        }

        customerAddress = this.model.getFullDeliveryAddress();
      }

      this.renderAttribute(
        'consumerAddress',
        Wahanda.Text.nl2br(Wahanda.Text.trimAndCleanNewLines(customerAddress)),
        true,
      );

      this.$('.consumer-phone-row').toggleClass('hidden', !phone);
      if (phone) {
        this.$('.value-customerPhoneNumber').text(phone);
      }

      this.$('.consumer-email-row').toggleClass('hidden', !email);
      if (email) {
        var $email = this.$('.value-customerEmailAddress').text(email);
        if (Wahanda.Permissions.viewClientContactDetails()) {
          $email.attr('href', 'mailto:' + email);
        }
      }
    },

    renderGiftBlock: function () {
      var delivery = this.model.get('delivery');

      var $block = this.$('.booking-gift-info');

      if (delivery && delivery.includeGiftmsg) {
        this.renderAttribute('giftmsgFrom', delivery.giftmsgFrom);
        this.renderAttribute('giftmsgTo', delivery.giftmsgTo);
        this.renderAttribute('giftmsgMessage', delivery.giftmsgMessage);
        $block.show();
      } else {
        $block.hide();
      }
    },

    /**
     * Renders sub-view, by the type of this booking.
     */
    renderTypeView: function () {
      var view;

      if (this.model.get('evouchers') && this.model.get('evouchers').length > 0) {
        // eVoucher
        view = new App.Views.Dialog.Booking.Evoucher({
          el: this.$('.b-evoucher-view').removeClass('hidden'),
          model: this.model,
          bookingView: this,
        });
      } else if (this.model.get('appointment')) {
        // Appointment
        view = new App.Views.Dialog.Booking.Appointment({
          el: this.$('.b-appointment-view').removeClass('hidden'),
          model: this.model,
          bookingView: this,
        });
      } else if (this.model.isDated()) {
        // Spa day/break
        view = new App.Views.Dialog.Booking.Dated({
          el: this.$('.b-dated-view').removeClass('hidden'),
          model: this.model,
          bookingView: this,
        });
      } else {
        // Unknown type
        return;
      }

      view.render();
    },

    setBookingUrl: function () {
      var hash = App.mainView.getCurrentStateHash();
      hash += '/booking/' + this.model.id;

      App.mainRouter.navigate(hash);
    },

    destruct: function () {
      if (this.options.updateUrl && this.previousUrl) {
        App.mainRouter.navigate(this.previousUrl);
      }
      // Unbind all events of this context
      App.off(null, null, this);
    },

    // Events

    confirmBooking: function () {
      var self = this;
      this.disableForm();

      // After confirming, this dialog will be closed by an event (@see initialize()).
      this.model.confirm({
        error: function () {
          self.$('.a-confirm-booking:visible').errorTip(Wahanda.lang.shared.savingFailed);
          self.enableForm();
        },
      });
    },

    rejectBooking: function () {
      var view = new App.Views.Dialog.Booking.Rejection({
        model: this.model,
      });
      view.render();
      view.open();
    },

    _afterClose: function () {
      App.trigger(Wahanda.Event.APP_CONTEXT_CHANGED);
      App.trigger(Wahanda.Event.BOOKING_CLOSED);
    },

    onShowCustomer: function (event) {
      var customer = this.model.get('customer');
      var dialog = App.Views.Dialog.Consumer.createInstance({
        id: customer.id,
        name: customer.name,
      });
      dialog.render();
      dialog.open();
    },
  },
  // Static methods
  {
    show: function (booking, options) {
      if (!isNaN(booking)) {
        booking = new App.Models.Booking({
          id: booking,
        });
      }
      var params = {
        model: booking,
      };
      params = _.extend(params, options || {});

      var view = new App.Views.Dialog.Booking(params);
      view.render();
      view.open();
      return view;
    },
  },
);
