import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Twitter extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-twitter', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M21.5683877,5.92640883 C20.8377255,6.2581751 20.0603349,6.47602479 19.2633947,6.57234152 C20.1073099,6.05265696 20.7343034,5.2458985 21.0286263,4.30100138 C20.2427033,4.77820467 19.3793423,5.11489934 18.4773978,5.29593297 C17.7275525,4.471345 16.6637678,4.00076304 15.5478471,4 C13.3317272,4 11.5346969,5.83854897 11.5346969,8.10744855 C11.5346969,8.42960138 11.5689416,8.741992 11.6374309,9.04299338 C8.30102084,8.87134124 5.34293291,7.2369851 3.36163364,4.7500629 C3.00437124,5.38031384 2.81725771,6.09232636 2.81861089,6.81639669 C2.81861089,8.24086538 3.52796494,9.49856303 4.60422627,10.2356097 C3.96546705,10.214383 3.34146248,10.0384909 2.78599691,9.72309379 L2.78599691,9.77353186 C2.78599691,11.7642086 4.16964499,13.4245972 6.00662738,13.8004422 C5.662895,13.897675 5.30721227,13.9464167 4.94993445,13.9452483 C4.69146865,13.9452483 4.43871032,13.9208428 4.19410547,13.8720317 C4.70451426,15.5039473 6.18681963,16.6916825 7.94389776,16.7234097 C6.2753473,18.070475 4.1306278,18.6848523 2,18.4261012 C3.82629964,19.6285755 5.96614571,20.2698222 8.15425793,20.2703448 C15.5396936,20.2703448 19.5773042,14.0127702 19.5773042,8.58416966 C19.5773042,8.40519586 19.5732275,8.22703559 19.5667047,8.05212938 C20.3556517,7.46833391 21.0335583,6.74841967 21.5683877,5.92640883" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Twitter);
