import {
  GET_PARTNER_SITE_SETTINGS_RECEIVE,
  GET_PARTNER_SITE_SETTINGS_FAILURE,
  SELECT_PARTNER_SITE_THEME,
  TOGGLE_PARTNER_SITE_ENABLED,
  CHANGE_PARTNER_SITE_SUBDOMAIN,
  CHANGE_PARTNER_SITE_CUSTOM_DOMAIN,
  CHANGE_PARTNER_SITE_FACEBOOK_HANDLE,
  CHANGE_PARTNER_SITE_INSTAGRAM_HANDLE,
  CHANGE_PARTNER_SITE_TWITTER_HANDLE,
  CHANGE_PARTNER_SITE_IMPRESSUM_TEXT,
  SUBMIT_PARTNER_SITE_SETTINGS_REQUEST,
  SUBMIT_PARTNER_SITE_SETTINGS_SUCCESS,
  SUBMIT_PARTNER_SITE_SETTINGS_FAILURE,
  VALIDATE_PARTNER_SITE_SUBDOMAIN_SUCCESS,
  VALIDATE_PARTNER_SITE_SUBDOMAIN_FAILURE,
  VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_SUCCESS,
  VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_FAILURE,
  SET_SUBDOMAIN_VALIDATION_STATUS_TO_EMPTY,
  SET_CUSTOM_DOMAIN_VALIDATION_STATUS_TO_EMPTY,
  RESET_PARTNER_SITE_SETTINGS,
} from 'reduxStore/actionsConst';

import { ValidationStatus, PartnerSiteState, PartnerSiteActionTypes } from './types';

const initialState: PartnerSiteState = {
  settings: {},
  impressum: {
    text: '',
  },
  isLoading: true,
  isSubmitting: false,
  subdomainValidationStatus: { status: ValidationStatus.OK },
  customDomainValidationStatus: { status: ValidationStatus.OK },
};

export default function partnerSite(
  state = initialState,
  action: PartnerSiteActionTypes,
): PartnerSiteState {
  switch (action.type) {
    case GET_PARTNER_SITE_SETTINGS_RECEIVE:
      return {
        ...state,
        settings: {
          ...action.payload.settings,
        },
        impressum: {
          ...action.payload.impressum,
        },
        isLoading: false,
      };
    case GET_PARTNER_SITE_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case TOGGLE_PARTNER_SITE_ENABLED:
      return {
        ...state,
        settings: {
          ...state.settings,
          micrositeEnabled: !state.settings.micrositeEnabled,
        },
      };
    case SELECT_PARTNER_SITE_THEME:
      return {
        ...state,
        settings: {
          ...state.settings,
          theme: action.payload.theme,
        },
      };
    case CHANGE_PARTNER_SITE_SUBDOMAIN:
      return {
        ...state,
        settings: {
          ...state.settings,
          micrositeSubdomain: action.payload.subdomain,
        },
      };
    case CHANGE_PARTNER_SITE_CUSTOM_DOMAIN:
      return {
        ...state,
        settings: {
          ...state.settings,
          micrositeCustomDomain: action.payload.customDomain,
        },
      };
    case CHANGE_PARTNER_SITE_FACEBOOK_HANDLE:
      return {
        ...state,
        settings: {
          ...state.settings,
          facebookHandle: action.payload.facebookHandle,
        },
      };
    case CHANGE_PARTNER_SITE_INSTAGRAM_HANDLE:
      return {
        ...state,
        settings: {
          ...state.settings,
          instagramHandle: action.payload.instagramHandle,
        },
      };
    case CHANGE_PARTNER_SITE_TWITTER_HANDLE:
      return {
        ...state,
        settings: {
          ...state.settings,
          twitterHandle: action.payload.twitterHandle,
        },
      };
    case CHANGE_PARTNER_SITE_IMPRESSUM_TEXT:
      return {
        ...state,
        impressum: { text: action.payload },
      };
    case SUBMIT_PARTNER_SITE_SETTINGS_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case SUBMIT_PARTNER_SITE_SETTINGS_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case SUBMIT_PARTNER_SITE_SETTINGS_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
      };
    case VALIDATE_PARTNER_SITE_SUBDOMAIN_SUCCESS:
      return {
        ...state,
        subdomainValidationStatus: action.payload,
      };
    case VALIDATE_PARTNER_SITE_SUBDOMAIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_SUCCESS:
      return {
        ...state,
        customDomainValidationStatus: action.payload,
      };
    case VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_SUBDOMAIN_VALIDATION_STATUS_TO_EMPTY:
      return {
        ...state,
        subdomainValidationStatus: { status: ValidationStatus.EMPTY },
      };
    case SET_CUSTOM_DOMAIN_VALIDATION_STATUS_TO_EMPTY:
      return {
        ...state,
        customDomainValidationStatus: { status: ValidationStatus.EMPTY },
      };
    case RESET_PARTNER_SITE_SETTINGS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
