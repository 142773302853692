import React from 'react';
import App from 'common/backbone-app';
import * as localesConfig from 'config/domains-locales';
import { Chevron } from 'components/common/Icon';
import { Flag } from 'components/common/Flag';
import { LanguageDropdown } from './LanguageDropdown';
import style from './DomainLanguage.scss';

export const DomainLanguage: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const getDomainCountryName = () => localesConfig.LOCALE_TO_COUNTRY_NAME[App.domainLocale];
  const closeDropDown = () => {
    setDropdownOpen(false);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const renderDropdown = () =>
    dropdownOpen && (
      <>
        <LanguageDropdown />
        <div className={style.dropdownOverlay} onClick={closeDropDown} />
      </>
    );
  return (
    <div>
      <div className={style.container} onClick={toggleDropdown}>
        <Flag locale={App.domainLocale} />
        <span className={style.label}>{getDomainCountryName()}</span>
        <Chevron size="small" className={style.chevron} />
      </div>
      <div className={style.dropdownWrapper}>{renderDropdown()}</div>
    </div>
  );
};
