import { trackEvent, ACTIONS } from 'common/analytics';

export const CATEGORY = 'automated-messaging';

export const Analytics = {
  trackAreYouSureView: (property: string) => {
    trackEvent(CATEGORY, ACTIONS.VIEW, 'are-you-sure', property);
  },
  trackAreYouSureClose: (property: string) => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'are-you-sure-close', property);
  },
};
