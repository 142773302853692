import { call, put, select } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import defaults from 'lodash/defaults';
import { getCustomer, getIsNewCustomer } from './selectors';

import {
  setCustomerAction,
  receiveCustomerAction,
  receiveCustomerActionError,
  putCustomerInfoSuccessAction,
  putCustomerInfoErrorAction,
} from './actions';

export function* getCustomerRequested(action) {
  try {
    const data = yield call(api.get, `${apiUrl('CUSTOMER')}/${action.id}.json`);

    yield put(receiveCustomerAction(data));
  } catch (err) {
    yield put(receiveCustomerActionError(err));
  }
}

export function* getCustomerSubmitted() {
  const customer = yield select(getCustomer);
  const isNewCustomer = yield select(getIsNewCustomer);
  const { id, ...payload } = customer;

  try {
    const data = yield call(
      isNewCustomer ? api.post : api.put,
      isNewCustomer ? `${apiUrl('CUSTOMER')}.json` : `${apiUrl('CUSTOMER')}/${id}.json`,
      payload,
      true,
    );
    // If the put request returns an empty object, but we were returning customer
    // data for submitting we need use defaults, to set default values, incase an
    // empty object is returned.
    yield put(putCustomerInfoSuccessAction(defaults(data, customer)));
    if (isNewCustomer) {
      yield put(setCustomerAction({ id: data.id }));
    }
  } catch (xhr: any) {
    // Currently, there is only one possible BE validation error
    // that is the incorrect email field.
    const error = xhr.responseJson.errors && xhr.responseJson.errors[0].name;
    yield put(putCustomerInfoErrorAction(error || xhr));
  }
}
