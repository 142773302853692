import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AdvancedReminderSettings as Component } from './AdvancedReminderSettings';
import { setReminderSettingsRequest } from '../actions';

const HIDE_CUSTOM_SENDER_FEATURE = 'no-sms-custom-sender';

const mapStateToProps = ({
  marketing: {
    automatedMessaging: {
      sendEmailReminders,
      emailReminderHours,
      sendSmsReminders,
      smsReminderHours,
      smsFromNumber,
    },
  },
}) => ({
  sendEmail: sendEmailReminders,
  sendSms: sendSmsReminders,
  emailHours: emailReminderHours,
  smsHours: smsReminderHours,
  smsFrom: smsFromNumber,
  showSmsSenderName: !App.isFeatureSupported(HIDE_CUSTOM_SENDER_FEATURE),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setReminderSettingsRequest }, dispatch),
});

export const AdvancedReminderSettings = connect(mapStateToProps, mapDispatchToProps)(Component);
