import React from 'react';
import style from './Step.scss';

interface Props {
  count: number;
  children: React.ReactNode;
  withLine: boolean;
}

export class Step extends React.PureComponent<Props> {
  public static defaultProps = {
    withLine: true,
  };

  public render() {
    const { children, count, withLine } = this.props;
    return (
      <div className={style.stepContent}>
        <div className={style.stepIndicator}>
          <div className={style.stepNumber}>{count}</div>
          {withLine && <div className={style.line} />}
        </div>
        <div className={style.childrenWrapper}>{children}</div>
      </div>
    );
  }
}
