;(function($) {
	/**
	 * Wahanda search field change plugin, based on top of $.ui.autocomplete.
	 * 
	 * It is used for custom search logic, where the search is triggered by user input in a text field.
	 */
	$.widget("wahanda.searchTermChange", {
		// These options will be used as defaults
		options: {
			$clear  : null,
			// Should be a function which takes one argument - the chosen object
			select  : null
		},
		
		_create: function() {
			this._bindEvents();
			this._createAutocomplete();
		},
		
		_bindEvents: function() {
			var self = this;
			if (this.options.$clear) {
				this.options.$clear.on('click.whselect', function(event) {
					event.preventDefault();
					self.clearSearch();
				});
				
				// Showing the "Delete content" element only if there is some content
				this.options.$clear.hide();
				this.element.on('keyup.whselect', function() {
					self.options.$clear.toggle( this.value !== "" );
				});
			}
		},
		
		_createAutocomplete: function() {
			var self     = this;
			var lastTerm = '';
			this.element.autocomplete({
				minLength: 2,
				source   : function(request, response) {
					var term = request.term;
					if (lastTerm != term) {
						self._trigger('search', null, request.term);
						lastTerm = term;
					}
				}
			});
			
			// Autocomplete widget hacking.
			var autocomplete = this.element.data('autocomplete');
			
			// Destroy menu instance. We will use our own.
			autocomplete.menu.element.remove();
			
			// "Close" of results
			autocomplete.close = function( event ) {
				if (event.type !== "blur") {
					self._trigger('stop');
				}
			};
		},
		
		clearSearch: function() {
			this.element.val('');
			this._trigger('stop');
			this.options.$clear && this.options.$clear.hide();
		},
		
		destroy: function() {
			if (this.options.$clear) {
				this.options.$clear.off('.whselect');
			}
			this.element.autocomplete('destroy').off('.whselect');
			
			$.Widget.prototype.destroy.call( this );
		}
	});
}(jQuery));
