import {
  CLIENT_SAVED,
  CLIENT_SET_WALKIN,
  INITIAL_STATE_INLINE_CLIENT_CREATION,
  CLIENT_CHOSEN,
  SET_CLIENT_PROPERTIES,
  CLEAR_INLINE_CLIENT,
  SET_SEND_MASS_EMAIL,
} from 'reduxStore/actionsConst';
import { isReadonly, getClientNameSelector, showGDPRMessageSelector } from './selectors';

export const initialState = () => ({});

// Props which need to be kept between client switches
const propsToReuse = [
  'canRemoveClient',
  'recipientName',
  'canViewContactDetails',
  'canEditClient',
  'isRepeat',
];

function getPropsToReuse(state) {
  const props = {};

  if (state) {
    propsToReuse.forEach((prop) => {
      props[prop] = state[prop];
    });
  }

  return props;
}

const setSendMassEmail = (state, { sendMassEmails }) => ({
  ...state,
  sendMassEmails,
});

const setClientProperties = (state, action) => {
  let newState = {
    ...state,
    ...action.data,
    // Changing data on the walk-in client means we don't want any references
    // with the previous id.
    id: null,
  };

  if (!showGDPRMessageSelector(newState)) {
    newState = {
      ...newState,
      ...setSendMassEmail(newState, { sendMassEmails: false }),
    };
  }

  return newState;
};

export function inlineClientForm(state = initialState(), action) {
  switch (action.type) {
    case SET_SEND_MASS_EMAIL:
      return setSendMassEmail(state, action);
    case INITIAL_STATE_INLINE_CLIENT_CREATION:
      return Object.assign({}, action.data, {
        isReadOnlyMode: isReadonly(action.data),
        name: getClientNameSelector(action.data),
      });
    case CLIENT_SAVED:
    case CLIENT_CHOSEN:
      // Saving and choosing the client has the full and correct client information so we
      // don't merge - just use what is passed to us.
      return {
        ...action.data,
        ...getPropsToReuse(state),
        isReadOnlyMode: true,
        isRepeat: null,
      };
    case CLIENT_SET_WALKIN:
      return {
        ...getPropsToReuse(state),
        name: action.note,
        isReadOnlyMode: false,
      };
    case SET_CLIENT_PROPERTIES:
      return setClientProperties(state, action);
    case CLEAR_INLINE_CLIENT:
      return initialState();
    default:
      return state;
  }
}
