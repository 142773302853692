import { assoc, hashMap } from 'mori';
import { atom } from 'atom';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';

// Global Atom
export const appState = atom(hashMap());

export const newLocalState = function () {
  return atom(hashMap());
};

// On `venueId` change propagate the appState with it
App.on(Wahanda.Event.APP_LOADED, () => {
  appState.swap((state) => assoc(state, 'venueId', App.getVenueId()));
});
