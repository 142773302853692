import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import { SidebarInfo } from './';
import { ChatSourceType } from 'src/tracking-container/events/chat';

interface Params {
  liveChatSource: ChatSourceType;
  node: HTMLElement;
}
const store = storeBuilder();

export const SidebarInfoInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(params.node),
  render: () => {
    ReactDOM.render(
      <Provider store={store}>
        <SidebarInfo liveChatSource={params.liveChatSource} />
      </Provider>,
      params.node,
    );
  },
});
