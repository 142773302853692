import React from 'react';

export const Feature: React.FC<{
  children: React.ReactNode;
  isOn?: boolean;
  isOff?: boolean;
  isEnabled: boolean;
}> = ({ children, isOn = false, isOff = false, isEnabled }) => {
  let shouldRenderChildren = false;

  if (isOn) {
    shouldRenderChildren = isEnabled;
  } else if (isOff) {
    shouldRenderChildren = !isEnabled;
  }

  return shouldRenderChildren ? <>{children}</> : null;
};
