import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Calendar extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-calendar', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M9,1 L9,7 L7,7 L7,5 L5,5 L5,19 L19,19 L19,5 L19,3 L19.0049107,3 C20.1067681,3 21,3.8926228 21,4.99508929 L21,19.0049107 C21,20.1067681 20.1073772,21 19.0049107,21 L4.99508929,21 C3.8932319,21 3,20.1073772 3,19.0049107 L3,4.99508929 C3,3.8932319 3.8926228,3 4.99508929,3 L7,3 L7,1 L9,1 Z M13,15 L13,17 L11,17 L11,15 L13,15 Z M9,15 L9,17 L7,17 L7,15 L9,15 Z M9,11 L9,13 L7,13 L7,11 L9,11 Z M13,11 L13,13 L11,13 L11,11 L13,11 Z M17,11 L17,13 L15,13 L15,11 L17,11 Z M17,1 L17,7 L15,7 L15,5 L11,5 L11,3 L15,3 L15,1 L17,1 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Calendar);
