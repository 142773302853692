import React from 'react';
import { Flag } from 'components/common/Flag';
import * as localesConfig from 'config/domains-locales';

import style from './LanguageDropdown.scss';

export class LanguageDropdown extends React.Component {
  private switchDomain = (newLocale) => {
    const DOMAINS = Object.keys(localesConfig.DOMAIN_TO_LOCALE_MAP);
    let newDomain = '';
    let oldDomain = '';

    DOMAINS.some((domain) => {
      if (window.location.hostname.search(domain) > 0) {
        oldDomain = domain;
        newDomain = localesConfig.DOMAIN_TO_LOCALE_MAP_INVERTED[newLocale];
        return true;
      }
      return false;
    });
    window.location = window.location.href.replace(oldDomain, newDomain) as any;
  };

  private renderItems = () =>
    Object.values(localesConfig.CHANNEL_CODE_TO_LOCALE_MAP).map((locale) => (
      <span key={locale} className={style.dropdownItem} onClick={() => this.switchDomain(locale)}>
        <Flag locale={locale} />
        <span className={style.label}>{localesConfig.LOCALE_TO_COUNTRY_NAME[locale]}</span>
      </span>
    ));

  public render() {
    return <div className={style.dropdown}>{this.renderItems()}</div>;
  }
}
