/* global BackboneEx */
App.Views.Calendar.KeyboardShortcuts = BackboneEx.View.Dialog2.extend(
  {
    events: {
      'click .js-close': 'close',
    },
    templateId: 'shortcut-list-template',
    buttons: {
      ok: {
        title: Wahanda.lang.shared.ok,
        classes: 'dialog2--button dialog2--button-right dialog2--button-green js-close',
      },
    },
    dialogTitle: Wahanda.lang.calendar.navigation.title,
    options: {
      width: 444,
      dialogClass: 'dialog2--white',
      dataTest: 'keyboard-shortcuts-modal',
    },

    setupTexts() {
      let navigationText = '';

      if (App.mainView.getCalendarType() === 'appointment') {
        // Prev/next text
        const isDayView = App.mainView.appointmentCalendar.isDayView();
        let base = Wahanda.lang.calendar.navigation.meanings.navigate;

        navigationText = base[isDayView ? 'days' : 'weeks'];
        // Numbers
        if (App.mainView.appointmentCalendar.getResourceCount() > 1) {
          base = Wahanda.lang.calendar.navigation.meanings.numbers;
          this.$('.b-resource-no').show();
          this.$('.v-resource-no-text').text(base.dynamic);
        } else {
          this.$('.b-resource-no').hide();
        }

        this.$('.b-only-appointments').show();
      } else {
        // Toggling content by calendar type
        this.$('.b-only-appointments').hide();
        this.$('.b-resource-no').hide();

        navigationText = Wahanda.lang.calendar.navigation.meanings.navigate.months;
      }

      this.$('.v-navigation-text').text(navigationText);
    },

    render() {
      // eslint-disable-next-line no-underscore-dangle
      this.constructor.__super__.render.call(this);

      this.setupTexts();
      this.$('.pos-only').toggle(App.config.get('venue').pointOfSaleEnabled);
      this.$('.js-redeem-evoucher').toggle(App.config.canRedeemEvouchers());
    },
  },
  {
    show() {
      new App.Views.Calendar.KeyboardShortcuts().open();
    },
  },
);
