import { withErrorHandling } from 'helpers/withErrorHandling';
import { AnalyticsBrowser } from './lib/segment';
import { SegmentUser, SegmentGroup } from './utils/trackingLocalStorage';
import { isTrackingActive } from 'src/tracking/utils/isTrackingActive';
import { normalizePath } from 'src/tracking/utils/normalizePath';
import {
  getAccount,
  getAccountId,
  getPath,
  getPreferredLanguage,
  getSubscriptionPlan,
  getURL,
  getVenue,
  getVenueId,
  getVenueLanguageWithoutRegion,
  getVenues,
} from 'src/tracking/utils/context';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}

export const init = withErrorHandling(() => {
  const writeKey = App.segmentWriteKey;
  if (!writeKey) {
    throw Error('No segment write key found, analytics will not be initialized');
  }

  AnalyticsBrowser.load({ writeKey });
});

export const clearIdentify = withErrorHandling(() => SegmentUser.clearIdentified());

export const identify = withErrorHandling(() => {
  if (!isTrackingActive()) {
    return;
  }

  const userId = getAccountId();
  if (SegmentUser.isAlreadyIdentified({ userId })) {
    return;
  }

  const account = getAccount();
  const venue = getVenue();
  const associatedVenues = getVenues();

  const properties = {
    email: account.emailAddress,
    country: venue.countryCode,
    venue_id_associated: (associatedVenues ?? []).map(({ id }) => id),
    name: account.employeeName,
    venue_id: getVenueId(),
    role: account.employeeRoleCode,
    preferred_language: getPreferredLanguage(),
    venue_language: getVenueLanguageWithoutRegion(),
    subscription_plan: getSubscriptionPlan(),
  };

  window.analytics.identify(userId, properties);

  SegmentUser.markIdentified({ userId });
});

export const page = withErrorHandling(() => {
  if (!isTrackingActive()) {
    return;
  }

  const path = getPath();
  const pageName = normalizePath(path);

  const properties = {
    country: getVenue().countryCode,
    venue_id: getVenueId(),
    url: getURL(),
    path,
    is_masquerading: App.isMasquerading(),
  };

  const context = {
    groupId: getVenueId(),
  };

  window.analytics.page(pageName, properties, context);
});

export const clearGroup = withErrorHandling(() => SegmentGroup.clearTracked());

export const group = withErrorHandling(async () => {
  if (!isTrackingActive()) {
    return;
  }

  const venueId = getVenueId();
  if (SegmentGroup.isAlreadyTracked({ venueId })) {
    return;
  }

  const venue = getVenue();
  const activesEmployees = await Wahanda.Cache.employees();

  const properties = {
    address: {
      postal_code: venue.postalRef,
      street: venue.addressLines,
      country: venue.countryCode,
    },
    created_at: venue.created,
    name: venue.name,
    supplier_id: venue.supplierId,
    type: venue.type,
    language: getVenueLanguageWithoutRegion(),
    employees: activesEmployees.length,
  };

  window.analytics.group(venueId, properties);

  SegmentGroup.markTracked({ venueId });
});

export const track = withErrorHandling((name: string, properties?: Record<string, string>) => {
  if (!isTrackingActive()) {
    return;
  }

  const context = {
    groupId: getVenueId(),
  };

  window.analytics.track(
    name,
    {
      country: getVenue().countryCode,
      platform: 'web',
      venue_id: getVenueId(),
      is_masquerading: App.isMasquerading(),
      ...properties,
    },
    context,
  );
});
