import React, { Component } from 'react';
import DialogContentBox from 'components/common/dialog/DialogContentBox';
import NoProductsState from 'components/menu/NoProductsState/container';
import { ProductSuppliersAnalytics } from 'common/analytics';
import ProductListItem from './ProductListItem/container';
import style from './style.scss';

interface IAssignedProductsTabProps extends React.HTMLAttributes<Element> {
  products?: {}[];
  loading?: boolean;
  selected?: boolean;
  actions: {
    requestProductsListAction: (...args: any[]) => any;
    resetProductsListAction: (...args: any[]) => any;
  };
  resetProductsListAction?: any;
  requestProductsListAction?: any;
}

export default class AssignedProductsTab extends Component<IAssignedProductsTabProps, {}> {
  static defaultProps = {
    products: [],
    loading: false,
    selected: false,
  };

  componentDidMount() {
    this.props.actions.requestProductsListAction();
  }

  componentDidUpdate() {
    const { loading, products, selected } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (!loading && products.length === 0 && selected) {
      ProductSuppliersAnalytics.trackAssignedProductsEmptyListView();
    }
  }

  componentWillUnmount() {
    this.props.actions.resetProductsListAction();
  }

  onGoToProductsPageButtonClick = () => {
    ProductSuppliersAnalytics.trackAssignedProductsEmptyListButtonClick();
  };

  renderProductListItem = (product) => <ProductListItem {...product} key={product.id} />;

  renderNoProductsMessage = () => (
    <div className={style.noProductsMessageContainer}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '() => void' is not assignable to type 'never... Remove this comment to see the full error message */}
      <NoProductsState onClick={this.onGoToProductsPageButtonClick} hasUnsavedChanges />
    </div>
  );

  render() {
    const { products } = this.props;
    return (
      <DialogContentBox separated>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {products.length > 0 ? (
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          <ul>{products.map(this.renderProductListItem)}</ul>
        ) : (
          this.renderNoProductsMessage()
        )}
      </DialogContentBox>
    );
  }
}
