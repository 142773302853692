import Latinize from 'latinize';
import Fuse from 'fuse.js';
import flatten from 'lodash/flatten';

import { formatFuse } from 'components/common/Select/utils/formatFuse';
import { wrapName } from 'components/common/Select/utils/helpers';

import { GroupPayload, SearchPayload, Service } from '../store/types';

const formatResultData = <T>(results, highlightClass): T[] =>
  results.map((item) => ({
    ...item,
    name: wrapName({ name: item.name, highlightClass }),
  }));

export const getSuggestions = <T>(payload: SearchPayload): T[] => {
  const { highlightClass, items, inputValue } = payload;
  const flattenData = flattenGroups<T>({ groups: items });
  const keys = ['latinName', 'name'];

  if (inputValue && inputValue.length > 3) {
    keys.push('aliases');
  }

  if (inputValue && inputValue.length > 0) {
    const fuse = new Fuse(flattenData, {
      keys,
      distance: 600,
      threshold: 0.1,
      includeMatches: true,
      useExtendedSearch: true,
    });
    const results = fuse.search(Latinize(inputValue));
    const formattedResults = formatResultData<T>(formatFuse(results), highlightClass);

    return formattedResults;
  }
  return flattenData;
};

const flattenGroups = <T>(payload: { groups: GroupPayload[] }): T[] => {
  const flat = payload.groups.map((item) => item.services);
  return flatten(flat);
};

export const formatSuggestedServices = (payload: {
  services: Service[];
  results: Service[];
}): Service[] => {
  const { services, results } = payload;
  const servicesInResults: { [x: string]: Service } = {};
  return services
    .filter((service) => {
      const serviceId = service.serviceId;
      const serviceInResults = results.find((o) => o.serviceId === serviceId);
      if (serviceInResults) {
        servicesInResults[serviceId] = serviceInResults;
      }
      return servicesInResults[serviceId];
    })
    .map((service) => ({
      ...service,
      name: servicesInResults[service.serviceId].name,
    }));
};
