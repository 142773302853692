import { trackEvent, ACTIONS } from 'common/analytics';

const CATEGORY = 'facebook-business-extension';

export const FacebookBusinessExtensionAnalytics = {
  trackLinkNowClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'link-now');
  },
  trackFBESuccess: () => {
    trackEvent(CATEGORY, ACTIONS.UPDATE, 'fbe-integration-success');
  },
  trackFBEFailed: () => {
    trackEvent(CATEGORY, ACTIONS.ERROR, 'fbe-integration-failed');
  },
  trackFBEReintegrationSuccess: () => {
    trackEvent(CATEGORY, ACTIONS.UPDATE, 'fbe-reintegration-success');
  },
  trackFBEReintegrationFailure: () => {
    trackEvent(CATEGORY, ACTIONS.ERROR, 'fbe-reintegration-failed');
  },
};
