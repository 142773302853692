import React from 'react';
import Wahanda from 'common/wahanda';
import { trackEvent } from 'common/analytics';
import { DiscountTag } from 'components/common/IconPicture';
import { StateCard } from 'components/common/StateCard';
import style from './style.scss';

const lang = Wahanda.lang.menu.discounts.emptyState;
const handleLearnMoreClick = () => {
  trackEvent('discounts', 'click', 'learn-more');
};

function hasEditPermission() {
  // Checking for edit-menu directly as we want this to work for Benelux locked down venues too.
  return 'edit-menu' in Wahanda.Permissions.list;
}

type EmptyStateProps = {
  onAddRuleClick: (...args: any[]) => any;
};

const EmptyState: React.SFC<EmptyStateProps> = ({ onAddRuleClick }) => (
  <div className={style.emptyDiscountsTab}>
    <StateCard
      image={<DiscountTag />}
      button={
        hasEditPermission()
          ? {
              text: lang.addRule,
              onClick: onAddRuleClick,
            }
          : undefined
      }
      heading={lang.title}
      message={
        <p>
          {lang.description}
          {'\u00A0'}
          <a
            href={lang.linkHref}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleLearnMoreClick}
          >
            {lang.learnMore}
          </a>
        </p>
      }
    />
  </div>
);

export default EmptyState;
