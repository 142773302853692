BackboneEx.View.Dialog.ValidatingForm.DatedDay = BackboneEx.View.Dialog.ValidatingForm.extend({
	fieldPrefix: /^dated\-day\-/,
	templateId: "dated-day-details-form-template",
	saveQuestionTitle: "",
	saveQuestionText : "",

	serviceType: null,

	initForm: function() {
    var wd = Wahanda.Date;
		var self = this;
		var date = this.model.getDate();

    // dateFrom and dateTo define the range of time for when the booking was
    // created; arrivalDate* define the user's checkin and checkout dates.
    // bookings-enquiries was originally designed for a different purpose,
    // that is why dateFrom and dateTo need to be specified even when pointless.
    // Note: dateTo here is defined as the same as arrivalDateTo
    // TODO double check for an eventual better solution
    var dateFrom        = wd.toApiString( wd.addYearsToDate(date, -1) );

    // For spa-breaks take date from as $DATE - 1.
    var arrivalDateFrom = wd.toApiString(
      this.serviceType === 'O' ? wd.addDaysToDate(date, -1) : date
    );
    var arrivalDateTo   = wd.toApiString( date );

		this.$el.tabs();

		this.bookingsList = new App.Collections.BookingsEnquiries();
		this.bookingsList.setFilters({
			arrivalDateFrom: arrivalDateFrom,
      arrivalDateTo  : arrivalDateTo,
      dateFrom       : dateFrom,
      dateTo         : arrivalDateTo,
			entity         : "dated-booking",
      type           : "all"
		});

		this.$el.loadmask();
    this.model.fetch({
      success: function() {
        self.renderForm();
        self.$el.unloadmask();
      }
    });

    var fetchBookings = function(e) {
      self.$el.loadmask();
      self.bookingsList.fetch({
        success: function() {
          self.renderBookings();
          self.$el.unloadmask();
        }
      });
      // remove this handler
      self.$(".history-tab > a").unbind("click", fetchBookings);
    };
    // bookings are loaded on demand
    this.$(".history-tab > a").click(fetchBookings);
	},

	toggleSingleInventoryInput: function() {
		var isAvailable = this.$('#dated-day-closed-no').is(':checked');
		var $input      = this.$('#dated-day-inventoryAvailable');
		if (isAvailable) {
			$input.focus();
		}
		this.toggleInventoryValidator(isAvailable);
	},

	renderForm: function() {
		this.fillFormFromModel();
		this.renderOffers();
		this.renderAttribute("date", this.model.getDateInLocalFormat());
		this.toggleSingleInventoryInput();
		if (!Wahanda.Util.isTouchDevice()) {
			this.focusFormElement();
		}

		this.$(".b-offer-wrapper").shadows({
			width: 348
		});
	},

	renderBookings: function() {
		var count = this.bookingsList.length;
			var serviceType = this.serviceType;
			var isSpaBreak = ('O' === this.serviceType);
			var dayDate;
			if (isSpaBreak) {
				dayDate = this.model.getDate();
			}
			var list = [];
			this.bookingsList.each(function(item) {
				if (item.isCancelled()) {
					// No, don't show the cancelled bookings. We need only active ones here.
					return;
				}

				var date     = item.getCheckinDate();
				var dateTime = Wahanda.Date.formatToDefaultFullDate(date);
				var label;

				if (isSpaBreak) {
					if (Wahanda.Date.isEqualDates(dayDate, date)) {
						label = {
							labelText : Wahanda.lang.date.currentDay,
							labelClass: 'status status-confirmed'
						};
					} else {
						if (item.get('numberOfNights') == 1) {
							// The item isn't for today.
							return;
						}
						label = {
							labelText : Wahanda.lang.date.previousDay,
							labelClass: 'status'
						};
					}
				}

				list.push({
					id         : item.id,
					reference  : item.get('orderReference') || "",
					date       : dateTime.date,
					time       : ('O' === serviceType ? dateTime.time : ''),
					client     : item.getLeadGuestName(),
					serviceName: item.get('itemName'),
					price      : item.getAmountText(),
					statusClass: item.getStatusClass(),
					statusText : item.getStatusText(),
					label      : label
				});
			});
			this.$('.tab-history-body table').html(
				Wahanda.Template.renderTemplate(
					'dated-day-details-form-history-items',
					{ list: list }
				)
			);

			this.$('#day-tab-history').tableAlign().shadows();

			count = list.length;
			this.$('.dialog-tabs .count').text( count );
	},

	openBookingDialog: function(event) {
		var id = $(event.currentTarget).closest('tr').data('id');
		var model = this.bookingsList.get(id);
		if (model) {
			var view = new App.Views.Dialog.Booking({
				model: model
			});
			view.render();
			view.open();
		}
	},

	setDayOpen: function() {
		// If the day is marked as closed when input is focused, open the day
		if (this.$('#dated-day-closed-yes').is(':checked')) {
			this.$('#dated-day-closed-no').prop('checked', true);
		}
		this.toggleInventoryValidator(true);
	},

	toggleInventoryValidator: function(validate) {
		var $input    = this.$('#dated-day-inventoryAvailable');
		var classList = 'required digits';
		if (validate) {
			$input.addClass(classList);
		} else {
			$input.removeClass(classList).removeClass('error').qtip('destroy');
		}
	},

	renderOffers: function() {
		this.$('.content-sidebar, .a-close-out').wShow();

		if ((this.model.get('offers') || []).length === 0) {
			this.$('.content-sidebar').addClass('empty');
			return;
		}

		var data = {
			elementName: "offer",
			list: []
		};

		var allDisabled = this.offerEditingDisabled();
		_.each(this.model.get('offers'), function(offer) {
			data.list.push({
				id      : offer.offerId,
				name    : offer.offerName,
				on      : !offer.closed,
				disabled: allDisabled
			});
		});

		var offers = new App.Views.Partials.FormCheckboxList({
			el: this.$('.b-available-offers')
		});
		offers.render(data);
	},

	offerEditingDisabled: function() {
		return false;
	},

	save: function() {
		var self = this;
		var saveAction = function() {
			BackboneEx.View.Dialog.ValidatingForm.prototype.save.call(self);
		};

		if (this.closeOutInProgress || this.offerEditingDisabled() || this.hasOffersSelected()) {
			saveAction();
		} else {
			this.disableForm();
			this.$('.save-action').saveQuestionTip({
				title       : this.saveQuestionTitle,
				content     : this.saveQuestionText,
				saveAction  : function() {
					self.enableForm();
					saveAction();
				},
				cancelAction: function() {
					self.enableForm();
				}
			});
		}
	},

	hasOffersSelected: function() {
		return this.$('.b-available-offers').find('input:checked').length > 0;
	},

	setValidation: function() {
		this._setDefaultValidation();
	},

	// Closed out offers. Send only those which are not checked.
	getClosedOffers: function() {
		var offers = [];
		this.$('.b-available-offers').find('input[type=checkbox]').not(':checked').each(function() {
			offers.push( parseInt(this.value, 10) );
		});
		return offers;
	},

	_afterSaveCallback: function() {
		BackboneEx.View.Dialog.ValidatingForm.prototype._afterSaveCallback.call(this);
		var type = (this.model instanceof App.Models.RoomAllocation ? 'spa-break' : 'spa-day');
		var closedOffers = (this.model.get('closedOffers') || []).length;

		App.trigger(Wahanda.Event.CALENDAR_AVAILABILITY_SAVED, {
			forDates    : [this.model.get('date')],
			type        : type,
			source      : "single-day",
			closedOffers: closedOffers
		});
	}
});
