/* global BackboneEx */
App.Views.Forms.Customer.MassEmailNoFiltersWarning = BackboneEx.View.Dialog.extend({
  events: {
    'click .a-close': 'close',
  },

  options: {
    title: Wahanda.lang.customer.massEmail.noFilters.title,
    width: 500,
    dataTest: 'mass-email-no-filter-warning-modal',
  },

  templateId: 'customer-mass-email-no-filters-template',
});
