import { useQuery } from 'react-query';
import { CustomerFormLinkResponse, CustomerFormLinkStatus } from './types';
import apiUrl from 'common/api-url';
import * as api from 'src/redux/apiService';

const cacheKey = (...params: string[]) =>
  `CustomerFormLink${params.reduce((total, item) => `${total}#${item}`, '')}`;

interface Params {
  appointmentDate: string;
  customerId?: string;
}

export const useAppointmentCustomerForm = ({ appointmentDate, customerId }: Params) => {
  return useQuery<CustomerFormLinkResponse[]>(
    cacheKey(customerId ?? '', appointmentDate),
    async () => {
      const data = await api.get<CustomerFormLinkResponse[]>(
        apiUrl('CONSULTATION_FORMS_CUSTOMER_FORM', {
          venueCustomerId: customerId,
          date: appointmentDate,
        }),
      );

      data.sort((a) => {
        if (a.status === CustomerFormLinkStatus.COMPLETED) {
          return -1;
        }

        return 1;
      });

      return data;
    },
    {
      // absence of venue customer id means that appointment creation dialog has been opened,
      // so there is no need to fetch for form yet
      enabled: !!customerId,
    },
  );
};
