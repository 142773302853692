import { ACCOUNT_LOCALE_LOCAL_STORAGE_KEY } from 'components/AccountLanguageSelect/constants';

const getAppConfig = () => App.config || new App.Models.Config();

export const getAccount = () => getAppConfig().get('account');

export const getVenue = () => getAppConfig().get('venue');

export const getVenues = () => getAppConfig().get('venues');

export const getAccountId = () => getAppConfig().getAccountId();

export const getVenueId = () => App.getVenueId().toString();

export const getURL = () => window.location.href;

export const getPath = () => `${window.location.pathname}${window.location.hash}`;

export const getVenueLanguage = () => getAppConfig().get('locale');

export const getVenueLanguageWithoutRegion = () => getVenueLanguage().split('_')[0];

export const getPreferredLanguage = () =>
  Wahanda.LocalStorage.get(ACCOUNT_LOCALE_LOCAL_STORAGE_KEY);

export const getSubscriptionPlan = () => getAppConfig().get('pricingPlanType');
