import {
  CLIENTS_BULK_DELETE_REQUEST,
  CLIENTS_BULK_DELETE_SUCCESS,
  CLIENTS_BULK_DELETE_SERVER_ERROR,
  CLIENTS_RESET,
} from 'reduxStore/actionsConst';

export const initialState = {
  serverError: false,
  loading: false,
  deletedCount: null,
};

export const clientBulkDelete = (state = initialState, action) => {
  switch (action.type) {
    case CLIENTS_BULK_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLIENTS_BULK_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        deletedCount: action.deletedCount,
      };
    }

    case CLIENTS_BULK_DELETE_SERVER_ERROR: {
      return {
        ...state,
        loading: false,
        serverError: true,
      };
    }

    case CLIENTS_RESET:
      return initialState;

    default:
      return state;
  }
};
