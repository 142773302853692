import React from 'react';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';
import { Tooltip, TooltipPlacement } from 'components/common/Tooltip';
import { Spinner } from 'components/common/Spinner';
import { GetEmployeesSchedulePayload, LoadingStatus, Schedules, WeekDays } from '../utils/types';
import { countHoursPerWeek, getWeekdaysMapped } from '../utils/helpers';
import style from './ShiftScheduleType.scss';

interface Props {
  employeeId: number;
  scheduleId: number;
  // redux
  actions: {
    getEmployeesSchedule: (payload: GetEmployeesSchedulePayload) => void;
    resetEmployeesSchedule: () => void;
  };
  scheduleLoadingStatus: LoadingStatus;
  loadedSchedule: Schedules;
}

export const ShiftScheduleType = ({
  actions,
  employeeId,
  scheduleLoadingStatus,
  loadedSchedule,
  scheduleId,
}: Props) => {
  React.useEffect(() => {
    actions.getEmployeesSchedule({ employeeId, scheduleId });
    return () => actions.resetEmployeesSchedule();
  }, []);
  if (scheduleLoadingStatus === LoadingStatus.FETCHING) {
    return (
      <div className={style.content}>
        <div className={style.spinnerIcon}>
          <Spinner dark />
        </div>
      </div>
    );
  }
  if (scheduleLoadingStatus === LoadingStatus.FAILED) {
    return null;
  }
  const { validFrom, validTo, type } = loadedSchedule;
  const dateFrom = validFrom && moment(validFrom).formatLongDate();
  const dateTo = validTo && moment(validTo).formatLongDate();
  const mappedDates = (): WeekDays[] =>
    getWeekdaysMapped({
      start: moment(validFrom),
      end: validTo ? moment(validTo).add(1, 'd') : moment(validFrom).add(7, 'd'),
    });
  const dailySchedules = loadedSchedule.dailySchedules.filter((day) =>
    mappedDates().includes(day.dayOfWeek),
  );
  const tooltipContent = (
    <div className={style.weekWrapper}>
      {dailySchedules.map((day) => {
        const { dayOfWeek, timeSlots } = day;
        return (
          <div className={style.week} key={dayOfWeek}>
            <div className={style.day}>{Wahanda.lang.date.weekdaysByName[dayOfWeek]}</div>
            <div>
              {timeSlots.length !== 0
                ? `${timeSlots[0].timeFrom} - ${timeSlots[0].timeTo}`
                : Wahanda.lang.settings.shifts.offText}
            </div>
          </div>
        );
      })}
    </div>
  );
  const hoursPerWeek = countHoursPerWeek(dailySchedules);
  const title = Wahanda.lang.settings.shifts.modalEditSchedule.scheduleType.title;
  const scheduleType =
    Wahanda.lang.settings.shifts.modalEditSchedule.scheduleType[type.toLowerCase()];
  return (
    <div className={style.content}>
      <div className={style.title}>
        {title} {scheduleType}
      </div>
      <div className={style.info}>
        <div className={style.dates}>
          {!dateTo && `${Wahanda.lang.settings.shifts.timeSlotFrom}: `}
          {dateFrom}
          {dateTo && ` - ${dateTo}`}
        </div>
        <Tooltip
          dataTest="schedule-info-tooltip"
          placement={TooltipPlacement.rightStart}
          tooltip={tooltipContent}
        >
          <span className={style.perWeek}>
            <span data-test="schedule-total-hours">{hoursPerWeek}</span>{' '}
            {Wahanda.lang.settings.shifts.perWeek}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
