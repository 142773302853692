import React from 'react';
import { Portal } from 'react-portal';
import { useCombobox } from 'downshift';
import keycode from 'keycode';
import classnames from 'classnames';
import find from 'lodash/find';
import computeScrollIntoView from 'compute-scroll-into-view';

import { useComponentDidMount } from 'utilities/hooks';
import {
  InputContextProvider,
  InputBorder,
  InputDecorators,
  InputError,
  InputLabel,
  InputSizeType,
  InputText,
  SelectOptionType,
} from 'components/common/__BaseCommon';

import { Close } from 'components/common/Icon';

import { getDropdownPositionAndHeight, getSuggestions, DropdownStyle } from './utils/helpers';

import { Option, OptionGroup, OptionValue } from './utils/types';
import { SelectOption, SecondaryOptionText } from './SelectOption';

import style from './Select.scss';

export interface SelectProps<T extends OptionValue> {
  options: OptionGroup<T>[] | Option<T>[];
  disabled?: boolean;
  dataTest?: string;
  errorMessage?: string;
  fullBorder?: boolean;
  focusInput?: boolean;
  hasError?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  isMultipleSelect?: boolean;
  isOptionsFooterSticky?: boolean;
  isSearchable?: boolean;
  newLook?: boolean;
  label?: string;
  // Used in components/menu/OfferPricing/DiscountRuleRow/index.js
  // noChangeEventValue?: any;
  name: string;
  maxSelectedOptions?: number;
  onOpen?: () => void;
  onSelect?: (selectedValue: T | T[], inputValue?: string) => void;
  onStateChange?: (payload) => void;
  onSearchInputChange?: () => void;
  optionsFooter?: React.ReactNode;
  placeholder?: string;
  placeholderNoResult?: string;
  required?: boolean;
  selectedOption?: T | T[];
  size?: InputSizeType;
}

export const Select = <T extends OptionValue>({
  disabled,
  dataTest,
  hasError = false,
  // noChangeEventValue,
  isClearable = false,
  isLoading = false,
  isMultipleSelect = false,
  isOptionsFooterSticky = false,
  isSearchable = false,
  newLook = false,
  errorMessage,
  fullBorder,
  focusInput = false,
  label,
  name,
  maxSelectedOptions,
  onOpen,
  onSelect,
  onStateChange,
  onSearchInputChange,
  options,
  optionsFooter,
  required = false,
  placeholder,
  placeholderNoResult,
  selectedOption,
  size = InputSizeType.DEFAULT,
}: SelectProps<T>) => {
  const componentDidMount = useComponentDidMount();
  const [dropboxPosition, setDropboxStyle] = React.useState<DropdownStyle>();
  const [items, setItems] = React.useState<(OptionGroup<T> | Option<T>)[]>(options);
  const [singleSelected, setSingleSelected] = React.useState<T>(selectedOption as T);
  const multipleSelectOptions = Array.isArray(selectedOption) ? selectedOption : [];

  const [choicesList, setChoicesList] = React.useState(multipleSelectOptions as T[]);
  const [isFocused, setIsFocused] = React.useState(false);
  const [wasSearchInputChanged, setWasSearchInputChanged] = React.useState(false);
  const [scrollToNearest, setScrollToNearest] = React.useState(false);
  const inputTextRef = React.useRef<HTMLInputElement>(null);
  const inputRef = React.useRef<HTMLLabelElement>(null);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const hasAvailableItems =
    choicesList.length !== maxSelectedOptions && choicesList.length !== options.length;

  const selectedValue = find(options, { value: selectedOption });

  const onSelectedItemChange = (change) => {
    let val = change.selectedItem || selectedValue?.value || singleSelected;
    // Sometimes the 'null' value is actually what is needed
    if (change.selectedItem === null) {
      val = null;
    }
    if (change.selectedItem === 0) {
      val = 0;
    }

    if (onStateChange) {
      onStateChange({ isOpen });
    }
    if (onSelect && !isMultipleSelect) {
      onSelect(val, currentInputValue);
    }
    if (isMultipleSelect) {
      handleChoiceAdd(val);
    }
  };

  const onIsOpenChange = () => {
    // Fixes input dropdown highlighted item preselect
    if (!isOpen) {
      setHighlightedIndex(options.findIndex((item) => item.value === singleSelected));
    }

    if (onOpen) {
      onOpen();
    } else {
      setScrollToNearest(false);
    }
  };

  const onInputValueChange = ({ inputValue }) => {
    let results = getSuggestions({
      highlightClass: style.match,
      items: getItemList(),
      inputValue,
      isSearchable,
    });

    if (results.length === 0) {
      results = [
        {
          name: placeholderNoResult || '',
          value: null,
          grouping: 'no-results',
        },
      ] as OptionGroup<T>[];
    }

    // Initialize this only once
    if (onSearchInputChange && !wasSearchInputChanged) {
      onSearchInputChange();
      setWasSearchInputChanged(true);
    }
    setItems(results);
  };

  const onInputClick = () => {
    if (!disabled) {
      setItems(getItemList());
      setInputValue('');
      handleToggleMenu();
    }
  };

  const onOptionSelect = (setName) => {
    const option = find(options, { value: setName.value });
    setInputValue(option.name);
    setSingleSelected(option.value);
  };

  function scrollIntoView(node, menuNode) {
    if (node === null) {
      return;
    }

    const actions = computeScrollIntoView(node, {
      boundary: menuNode,
      block: scrollToNearest ? 'nearest' : 'center',
      scrollMode: 'if-needed',
    });
    actions.forEach(({ el, top, left }) => {
      el.scrollTop = top;
      el.scrollLeft = left;
    });
  }

  const handleToggleMenu = () => {
    if (hasAvailableItems) {
      toggleMenu();
    }
  };

  const handleKeyDown = (event) => {
    const key = keycode(event);

    if (isOpen && ['enter', 'up', 'down'].indexOf(key) !== -1) {
      event.preventDefault();
      setScrollToNearest(true);
    }
    if (
      !isOpen &&
      !event.defaultPrevented &&
      (['up', 'down'].indexOf(key) !== -1 || isSearchable)
    ) {
      setInputValue('');
      event.preventDefault();
      handleToggleMenu();
    }
    if (
      isOpen &&
      isMultipleSelect &&
      !currentInputValue &&
      !!choicesList.length &&
      ['backspace'].indexOf(key) !== -1
    ) {
      const choicePop = choicesList.pop();
      setChoicesList(choicesList.filter((item) => item !== choicePop));
      setItems(getItemList());
    }
  };

  const handleChoiceAdd = (value: T) => {
    setChoicesList((choices) => [...choices, value]);
  };

  const handleChoiceRemove = (value: T) => {
    setChoicesList(choicesList.filter((item) => item !== value));
    handleToggleMenu();
  };

  const handleMouseEnter = () => {
    setIsFocused(true);
  };
  const handleMouseLeave = () => {
    setIsFocused(false);
  };

  const handleInputFocus = () => {
    inputTextRef?.current?.focus();
  };

  const getItemList = () =>
    [options, choicesList].reduce((a: any, b) => a.filter((c) => !b.includes(c.value))) as
      | OptionGroup<T>[]
      | Option<T>[];

  let initialSelectedItem: T | string | undefined = placeholder;

  if (selectedValue && !multipleSelectOptions.length) {
    initialSelectedItem = selectedValue?.value || placeholder;
  }

  function stateReducer(state, actionAndChanges) {
    const valVal = options[state.highlightedIndex]?.value;
    switch (actionAndChanges.type) {
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
        if (!state.inputValue && valVal) {
          setSingleSelected(valVal);
          setHighlightedIndex(options.findIndex((item) => item.value === valVal));
        } else {
          setSingleSelected(actionAndChanges.changes.selectedItem);
          setHighlightedIndex(
            options.findIndex((item) => item.value === actionAndChanges.changes.selectedItem),
          );
        }
        return actionAndChanges.changes;
      case useCombobox.stateChangeTypes.InputBlur:
        // This is needed to persist data when Select is opened
        // and new/old/same options list is passed down and the
        // Select is repainted on demand by higher order component
        return {
          ...actionAndChanges.changes,
          selectedItem: state.selectedItem,
        };
      default:
        return actionAndChanges.changes;
    }
  }

  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    isOpen,
    highlightedIndex,
    setHighlightedIndex,
    setInputValue,
    selectItem,
    inputValue: currentInputValue,
    toggleMenu,
  } = useCombobox({
    defaultHighlightedIndex: 0,
    items: items.map((item) => (item ? item.value : '')),
    initialSelectedItem,
    scrollIntoView,
    onIsOpenChange,
    onSelectedItemChange,
    onInputValueChange: ({ inputValue }) => onInputValueChange({ inputValue }),
    stateReducer,
  });

  const renderSelectedText = (value: T) => {
    const selectedName = find(options, { value });
    const secondaryTextOption = selectedName?.secondary && (
      <SecondaryOptionText
        secondaryText={selectedName?.secondary.secondaryText}
        secondaryType={selectedName?.secondary.secondaryType}
      />
    );
    const selectedText =
      selectedName?.type === SelectOptionType.WARNING ? (
        <span className={style.warning}>{selectedName?.name}</span>
      ) : (
        selectedName?.name
      );

    return (
      <>
        {selectedText || placeholder} {secondaryTextOption}
      </>
    );
  };

  const renderInput = () => {
    const hasChoices = isMultipleSelect && !!choicesList.length;

    return (
      <>
        {isOpen && hasChoices && <div className={style.inputChoices}>{renderChoiceList()}</div>}
        <InputText
          {...getInputProps({
            ref: inputTextRef,
            autoFocus: true,
            name,
            readOnly: disabled || !hasAvailableItems,
          })}
        >
          {!isOpen && (hasChoices ? renderChoiceList() : renderSelectedText(singleSelected))}
        </InputText>
      </>
    );
  };

  const renderChoiceList = () => {
    return choicesList.length > 0
      ? choicesList.map((item, index) => {
          const onChoiceClick = () => handleChoiceRemove(item);
          return (
            // eslint-disable-next-line react/no-array-index-key
            <span onClick={onChoiceClick} className={style.choice} key={`${item}${index}`}>
              {renderSelectedText(item)}
              <span className={style.choiceIcon}>
                <Close size="tiny" />
              </span>
            </span>
          );
        })
      : placeholder;
  };

  const renderOption = (payload) => {
    const { item, index } = payload;
    const isActive = singleSelected === item.value || highlightedIndex === index;
    const isSelected = isMultipleSelect && choicesList?.indexOf(item.value) > -1;

    return (
      !isSelected && (
        <SelectOption
          {...getItemProps({
            disabled: item.grouping,
            index,
            item: item.name,
            onClick: onOptionSelect,
          })}
          key={item.value}
          item={item}
          isActive={isActive}
          isIntended={!!item.groupingValue}
          isSelectable={!item.grouping}
          newLook={newLook}
        />
      )
    );
  };

  React.useLayoutEffect(() => {
    const parentRect = inputRef?.current?.getBoundingClientRect();
    const elementRect = dropdownRef?.current?.getBoundingClientRect();
    if (isOpen && parentRect && elementRect) {
      setDropboxStyle(
        getDropdownPositionAndHeight({
          elementRect,
          parentRect,
          isSearchable,
          newLook,
        }),
      );
    }

    if (!isOpen) {
      handleMouseLeave();
    }
  }, [isOpen, dropdownRef.current]);

  React.useEffect(() => {
    if (isMultipleSelect && onSelect) {
      onSelect(choicesList, currentInputValue);
    }
  }, [choicesList]);

  React.useEffect(() => {
    if (selectedOption && !Array.isArray(selectedOption)) {
      setSingleSelected(selectedOption as T);
      selectItem(selectedOption);
    }
  }, [selectedOption]);

  React.useEffect(() => {
    if (componentDidMount) {
      setItems(options);
      setHighlightedIndex(options.findIndex((item) => item.value === selectedOption));
    }
  }, [options]);

  React.useEffect(() => {
    // Handle the focus if the focusInput is set to true
    if (focusInput && isOpen) {
      handleInputFocus();
      // Trigger the input value change if previously it wasn't empty
      // to accommodate the searchable value
      if (isSearchable && currentInputValue) {
        setInputValue('');
        setInputValue(currentInputValue);
      }
    }
  }, [focusInput]);

  const onKeyDown = disabled ? undefined : handleKeyDown;
  const showError = hasError && required && !singleSelected;
  const contentClass = classnames(style.content, {
    [style.hasStickyFooter]: isOptionsFooterSticky,
  });

  const tabIndex = disabled ? -1 : 0;

  return (
    <InputContextProvider
      size={size}
      disabled={disabled}
      isFocused={isFocused}
      fullBorder={fullBorder}
      newLook={newLook}
    >
      <div>
        {label && <InputLabel {...getLabelProps()} label={label} required={required} />}
        <InputBorder hasError={showError} onKeyDown={onKeyDown} tabIndex={tabIndex}>
          <div
            data-test={dataTest}
            {...getComboboxProps({
              ref: inputRef,
              onClick: onInputClick,
              onMouseEnter: handleMouseEnter,
              onMouseLeave: handleMouseLeave,
            })}
            className={style.container}
          >
            <InputDecorators
              isSelect={hasAvailableItems}
              isLoading={isLoading}
              isClearable={isClearable}
            >
              {renderInput()}
            </InputDecorators>
          </div>
        </InputBorder>
        {showError && errorMessage && <InputError message={errorMessage} />}

        {isOpen ? (
          <Portal>
            <div
              className={contentClass}
              style={dropboxPosition}
              {...getMenuProps({
                ref: dropdownRef,
                onMouseMove: handleMouseEnter,
              })}
              data-test={`${dataTest}-dropdown`}
            >
              <div className={style.dropdown}>
                {items.map((item, index) =>
                  renderOption({
                    item,
                    index,
                  }),
                )}
              </div>
              {optionsFooter}
            </div>
          </Portal>
        ) : (
          <div {...getMenuProps()} />
        )}
      </div>
    </InputContextProvider>
  );
};

Select.displayName = 'Select';
