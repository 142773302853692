import React from 'react';
import Wahanda from 'common/wahanda';
import { LogoTreatwell } from 'components/common/IconPicture';
import { Box } from 'components/common/Box';
import style from './UnsupportedBrowser.scss';

export const UnsupportedBrowser: React.FC = () => {
  return (
    <div className={style.overlay}>
      <div className={style.container}>
        <Box>
          <>
            <LogoTreatwell className={style.logo} />
            <h1 className={style.header}>{Wahanda.lang.unsupportedBrowser.title}</h1>
            <p>{Wahanda.lang.unsupportedBrowser.notSupporting}</p>
            <div className="browser-list">
              <a href="http://www.google.com/chrome/" className="link chrome">
                {Wahanda.lang.unsupportedBrowser.downloadChrome}
              </a>
              <a href="http://www.mozilla.org/firefox/new/" className="link firefox">
                {Wahanda.lang.unsupportedBrowser.downloadFirefox}
              </a>
            </div>
            <p>{Wahanda.lang.unsupportedBrowser.freeBrowsers}</p>
          </>
        </Box>
      </div>
    </div>
  );
};
