module.exports.colors = {
  colorCoralDark: '#b24748',
  colorCoral: '#ff6668',
  colorCoralPastel: '#ffefef',
  colorPinkDark: '#b28085',
  colorPink: '#f89099',
  colorPinkPastel: '#fff7f8',
  colorYellowDark: '#b28e35',
  colorYellow: '#ffcb4d',
  colorYellowPastel: '#fff9ed',
  colorGoldDark: '#726d40',
  colorGold: '#d6cf8d',
  colorGoldPastel: '#f7f5e6',
  colorGreenDark: '#2c25a5',
  colorGreen: '#4036ed',
  colorGreenPastel: '#ebeafd',
  colorNavyDark: '#0d1e44',
  colorNavy: '#071948',
  colorNavyPastel: '#e7efff',
  colorTealDark: '#0b8a90',
  colorTeal: '#11c6cf',
  colorTealPastel: '#e7f9fa',
  colorWhite: '#fff',
  colorGrey98: '#f8f8fa',
  colorGrey95: '#f3f4f6',
  colorGrey90: '#e6e8ed',
  colorGrey70: '#b5bac8',
  colorGrey50: '#838ca3',
  colorGrey40: '#838ca3',
  colorGrey30: '#515e7f',
  colorGrey20: '#36456b',
  colorGrey10: '#071948',
  colorBlack: '#000',
  colorFacebook: '#1877F2',
  colorTwitter: '#00A7F1',
};
