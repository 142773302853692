import { formatName, formatSkus } from 'utilities/menu/sku-helpers';
import CategoryMismatchAlert from './category-mismatch-alert';

(function () {
  var MAX_OPEN_SELECTION_CHOICES = 2;
  const MAX_DIALOG_HEIGHT = 600;

  var BaseView = BackboneEx.View.Dialog2;
  var SelectServices = BaseView.extend({
    events: {
      'click .js-close': 'close',
      'click .row': 'select',
      'click .js-save': 'save',
    },

    buttons: {
      close: {
        title: Wahanda.lang.shared.cancel,
      },
      save: {
        title: Wahanda.lang.shared.addSelected,
      },
    },

    options: {
      width: 460,
      dialogClass: 'offer2 scrollable',
      dataTest: 'select-service-modal',
    },
    templateId: 'offer2-package--select-services-template',
    mediator: null,
    selectedServices: [],
    dialogTitle: Wahanda.lang.menu.offer.packages.addServicesTitle,
    openSelectionServices: 0,

    calculateHeight: function (baseHeight) {
      const padding = 40;
      const minimumHeight = window.innerHeight - padding * 2;

      return Math.min(baseHeight, minimumHeight);
    },

    initialize: function () {
      this.options.height = this.calculateHeight(MAX_DIALOG_HEIGHT);
      BaseView.prototype.initialize.apply(this, arguments);
    },

    render: function () {
      BaseView.prototype.render.call(this);
      this.$el.find('.b-package-select-services').replaceWith(
        Wahanda.Template.renderTemplate(this.templateId, {
          groups: this.getGroups(),
        }),
      );

      if (this.openSelectionServices >= MAX_OPEN_SELECTION_CHOICES) {
        this.disableOpenSelectionsAndShowMessage();
      }
    },

    getGroups: function () {
      var formattedGroups = [];
      var self = this;
      var groups = this.options.menu.offersToGroups();
      this.openSelectionServices = 0;

      _.each(groups, function (groupItem) {
        var offers = self.getOffers(groupItem.offers);
        if (!offers.length) {
          return;
        }

        formattedGroups.push({
          groupName: groupItem.group.get('name'),
          groupId: groupItem.group.get('id'),
          services: offers,
        });
      });
      return formattedGroups;
    },

    getOffers: function (offers) {
      var formattedOffers = [];
      var self = this;

      function isOfferSelected(offerId) {
        if (self.model.get('stateData')) {
          const { skusByOffer, subSkus } = self.model.get('stateData');

          return !!skusByOffer[offerId] || subSkus.some((subSku) => subSku.offerId === offerId);
        }
        return self.model.isOfferSelectedInPackage(offerId);
      }

      _.each(offers, function (offer) {
        if (!offer.isTreatment() || offer.isArchived()) {
          return;
        }
        var skus = self.formatOfferSkus(offer);
        var isMultiSkuSelection = offer.get('multiSkuSelection');
        var alwaysDisabled = isMultiSkuSelection && skus.length > 1;

        var disabled;
        if (alwaysDisabled) {
          disabled = true;
        } else {
          // If it's a single-sku selectable but a sku is chosen, disable the item.
          disabled =
            !isMultiSkuSelection &&
            skus.length > 1 &&
            _.any(skus, function (sku) {
              return sku.on;
            });
        }

        var isSelected = isOfferSelected(offer.id);
        let selectedAndOpenSelection = false;

        if (!disabled && isSelected) {
          // This is an open selection.
          self.openSelectionServices++;
          selectedAndOpenSelection = true;
        }

        formattedOffers.push({
          name: offer.get('name'),
          serviceId: offer.get('id'),
          multiSkuSelection: isMultiSkuSelection,
          skus: skus.length > 1 ? skus : false,
          isAlwaysDisabled: alwaysDisabled,
          isDisabled: disabled,
          isMultiSkuSelection: isMultiSkuSelection,
          skuId: skus.length === 1 ? skus[0].id : false,
          isOpenSelectable: !isMultiSkuSelection && skus.length > 1,
          on: isSelected,
          selectedAndOpenSelection,
        });
      });
      return formattedOffers;
    },

    formatOfferSkus: function (offer) {
      var self = this;
      function isSkuSelected(skuId) {
        if (self.model.get('stateData')) {
          const { subSkus } = self.model.get('stateData');

          return subSkus.some((sku) => sku.id === skuId);
        }

        return self.model.doesPackageContainSku(skuId);
      }

      function isOfferOpenSelection(skuOffer) {
        const isOpenSelectable = skuOffer.isOpenSelectable();

        if (self.model.get('stateData')) {
          const { skusByOffer } = self.model.get('stateData');

          return !!skusByOffer[skuOffer.id] && isOpenSelectable;
        }

        return isOpenSelectable && self.model.getPackageOfferSkuCount(skuOffer.id) > 1;
      }

      // A sku is disabled when it's service is Open Selected.
      var isOpenSelection = isOfferOpenSelection(offer);

      return _.map(offer.get('skus'), (sku) => {
        const name =
          formatName(sku, self.options.employeeCategories) ||
          Wahanda.Time.getSkuDurationText(sku.duration);

        return _.extend(
          {
            on: !isOpenSelection && isSkuSelected(sku.id),
            disabled: isOpenSelection,
          },
          sku,
          {
            name,
          },
        );
      });
    },

    select: function (evt) {
      var $selectedElement = $(evt.currentTarget);
      if ($selectedElement.hasClass('disabled')) {
        return;
      }

      if ($selectedElement.hasClass('sku')) {
        this.toggleSku($selectedElement);
      } else {
        this.toggleService($selectedElement);
      }
    },

    toggleService: function ($selectedService, isSkuAction) {
      if (!$selectedService.hasClass('on') && this.getSelectedServicesCount() < 6) {
        $selectedService.addClass('on');

        if (!isSkuAction) {
          // This is called from a SKU being acted on, don't disable the service's skus and don't
          // do other actions.
          var skuId = $selectedService.data('skuId');
          if (!skuId) {
            this.addOpenSelectionService($selectedService);
            // If a service is selected, we need to uncheck all it's selected skus
            this.disableSkusForService($selectedService);
          }
        }
      } else if ($selectedService.hasClass('on') && !isSkuAction) {
        $selectedService.removeClass('on');
        this.enableSkusForService($selectedService);

        if ($selectedService.data('open-selection-choice') === true) {
          this.removeOpenSelectionService($selectedService);
        }
      }
    },

    toggleSku: function ($selectedSku) {
      var serviceId = $selectedSku.data('serviceId');
      var $serviceRow = this.$('.js-service-row[data-id="' + serviceId + '"]');
      if (!$serviceRow.hasClass('on')) {
        this.toggleService($serviceRow, true /* = from inside the sku action */);

        if (!$serviceRow.hasClass('on')) {
          return;
        }
      }

      var selectedSubSkus = this.returnCheckedSkuCount($serviceRow);

      if ($selectedSku.hasClass('multiSkuSelection')) {
        if (selectedSubSkus >= 6 && !$selectedSku.hasClass('on')) {
          // Limit of skus reached. Don't check anything.
          return;
        }
      } else if (!$selectedSku.hasClass('on') && selectedSubSkus > 0) {
        // It's a single-sku-selectable multi-sku item. Uncheck the other selected sku.
        this.getSkusForService($serviceRow).removeClass('on');
      }

      $selectedSku.toggleClass('on');

      this.toggleServiceBySku($selectedSku);
    },

    getSelectedServicesCount: function () {
      return this.$('.on.js-service-row').length;
    },

    disableOpenSelectionsAndShowMessage: function () {
      this.$('.js-open-selectable')
        // Mark Open Selectable services with skus chosen
        .filter('.on.disabled')
        .addClass('js-open-selected-skus')
        .end()
        // disable = not selected, open selectable, leave skus choosable
        .not('.on')
        .addClass('disabled disabled-open-selection js-disabled-open-selection')
        .end();

      this.maxOpenSelectionMessage = new SelectServices.Message({
        message: Wahanda.lang.menu.offer.packages.addServices.openSelectionNotification,
      });
      this.showMessage(this.maxOpenSelectionMessage);
    },

    addOpenSelectionService: function ($service) {
      $service.addClass('open-selection').data('open-selection-choice', true);
      this.openSelectionServices++;

      if (this.openSelectionServices >= MAX_OPEN_SELECTION_CHOICES) {
        this.disableOpenSelectionsAndShowMessage();
      }
    },

    removeOpenSelectionService: function ($service) {
      $service.removeClass('open-selection').removeData('open-selection-choice');
      this.openSelectionServices--;
      // Re-enable other open selection services
      this.$('.js-service-row.disabled')
        .filter('.js-disabled-open-selection')
        .removeClass('disabled disabled-open-selection js-disabled-open-selection')
        .end()
        .filter('js-open-selected-skus')
        .removeClass('.js-open-selected-skus');

      if (this.maxOpenSelectionMessage) {
        this.maxOpenSelectionMessage.remove();
        this.maxOpenSelectionMessage = null;
      }
    },

    disableSkusForService: function ($service) {
      if ($service.filter('.js-open-selected-skus, .js-disabled-open-selection').length > 0) {
        // The service is disabled as an Open Selection selected item
        return;
      }

      var self = this;
      var $list = this.getSkusForService($service);
      $list.each(function () {
        var $sku = $(this);
        if ($sku.hasClass('on')) {
          self.toggleSku($sku);
        }
        $sku.addClass('disabled');
      });
    },

    toggleServiceBySku: function ($sku) {
      var $service = $sku.prev();
      while (!$service.is('.js-service-row') && $service.length > 0) {
        $service = $service.prev();
      }

      var skuSelected = $sku.hasClass('on');
      if (skuSelected) {
        if (!$service.hasClass('disabled')) {
          $service.addClass('disabled');
        }
        if (!$service.hasClass('on')) {
          $service.addClass('on');
        }
      } else if (
        !$service.data('always-disabled') &&
        this.openSelectionServices < MAX_OPEN_SELECTION_CHOICES
      ) {
        $service.removeClass('disabled');
      }

      if (this.returnCheckedSkuCount($service) === 0) {
        $service.removeClass('on');
      }
    },

    enableSkusForService: function ($service) {
      this.getSkusForService($service).removeClass('disabled');
    },

    getSkusForService: function ($service) {
      return $service.nextUntil('.js-service-row');
    },

    getOfferModel: function (offerId) {
      return this.options.menu.get('offersCollection').get(offerId);
    },

    returnCheckedSkuCount: function ($service) {
      var count = 0;
      this.getSkusForService($service).each(function () {
        if ($(this).hasClass('on')) {
          count++;
        }
      });
      return count;
    },

    getValues: function () {
      var self = this;
      var skusByOffer = {};
      var subSkus = [];
      var subServices = [];

      // This add a subsku for all skus
      function addSubSku($row, fromServiceRow) {
        var skuId, offerId;

        if (fromServiceRow) {
          skuId = $row.data('sku-id');
          offerId = $row.data('id');
        } else {
          skuId = $row.data('id');
          offerId = $row.data('service-id');
        }

        subSkus.push(getSkuStructure(offerId, skuId, fromServiceRow));
      }

      // This adds a high-level sku of a Package, from a Open Selection sku item
      function addSku($row) {
        var offerId = $row.data('service-id');
        var skuId = $row.data('id');

        if (!skusByOffer[offerId]) {
          skusByOffer[offerId] = [];
        }

        skusByOffer[offerId].push(getSkuStructure(offerId, skuId));
      }

      function getSkuStructure(offerId, skuId, skipName) {
        var offer = self.options.menu.get('offersCollection').get(offerId);
        var sku = offer.getSku(skuId);
        var skuName;

        if (!skipName) {
          if (offer.get('skus').length > 0) {
            skuName = sku.get('name');
          }
        }

        return _.extend(
          {
            id: skuId,
            name: skuName,
            offerId: offerId,
            employeeCategoryId: sku.get('employeeCategoryId'),
            nameInherited: sku.get('nameInherited'),
          },
          // This will add sku pricing info
          offer.getSkuPrices(skuId),
        );
      }

      function buildSubSkus(skuList, initialSkus) {
        return skuList.map((sku) => [sku].concat(initialSkus));
      }

      function isMatchEmployeeCategories(skusGroupedByOffer) {
        const skusByOfferList = Object.values(skusGroupedByOffer);
        const getCategories = (skus) => _.uniq(skus.map((sku) => sku.employeeCategoryId));

        if (skusByOfferList.length < 2) {
          return true;
        }

        const firstOfferCategories = getCategories(skusByOfferList[0]);

        return _.every(
          skusByOfferList,
          (skus) => _.difference(getCategories(skus), firstOfferCategories).length === 0,
        );
      }

      const offers = {};

      this.$('.on').each(function () {
        var $item = $(this);

        if ($item.hasClass('js-service-row')) {
          // This is a service, and it's checked
          var serviceId = $item.data('id');
          var offer = self.getOfferModel(serviceId);
          subServices.push({
            serviceId: offer.id,
            name: $item.find('.js-name').text().trim(),
            multiSkuSelection: offer.get('multiSkuSelection'),
          });

          offers[serviceId] = offer;

          if ($item.data('sku-id')) {
            // This service has one sku, add it directly.
            addSubSku($item, true);
          } else if (!$item.hasClass('disabled')) {
            // This is a open selection/2D service. Add all of it's skus.
            $item.nextUntil('.js-service-row').each(function () {
              addSku($(this));
            });
          }
        } else {
          // Add this sub-sku to each skus of the package.
          addSubSku($item);
        }
      });

      var result = {
        skus: formatSkus({ skusByOffer, subSkus, offers }),
        subServices: subServices,
        stateData: {
          skusByOffer: skusByOffer,
          subSkus: subSkus,
          offers: offers,
        },
      };
      const expectedSkuCount = Math.max(
        Object.values(skusByOffer).reduce((sum, skus) => (sum || 1) * skus.length, 0),
        1,
      );
      const expectedSubSkuCount = Object.keys(skusByOffer).length + subSkus.length;

      result.employeeCategoryMismatch =
        result.skus.length === 0 ||
        (expectedSkuCount !== result.skus.length && !isMatchEmployeeCategories(skusByOffer)) ||
        expectedSubSkuCount !== result.skus[0].subSkus.length;

      return result;
    },

    save: function () {
      const values = this.getValues();
      const showError = values.employeeCategoryMismatch;
      delete values.employeeCategoryMismatch;

      this.model.set(
        // This sets skus and subServices
        values,
      );
      this.model.trigger('update');
      this.close();

      this.options.mediator.trigger(this.options.mediator.PACKAGE_SERVICES_CHANGED);

      if (showError) {
        const alertDialog = new CategoryMismatchAlert();
        alertDialog.render();
        alertDialog.open();
      }
    },

    showMessage: function (message) {
      var $container = this.$('.js-footer-msgs');
      if (!$container.length) {
        $container = $('<div class="js-footer-msgs dialog2--footer--messages">');
        this.$('.dialog2--footer').append($container);
      }

      $container.append(message.render().$el);
    },
  });

  App.Views.Forms.MenuOffer2.Pricing.PackageServices.SelectServices = SelectServices;
})();
