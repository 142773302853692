import apiUrl from 'common/api-url';
import { xhr } from 'common/xhr';
import { toDateString } from 'common/datetime';

function maybeJoin(arr) {
  return arr ? arr.join(',') : null;
}

export default function fetch(params) {
  const promise = xhr.getWithPromise(
    apiUrl('CALENDAR', {
      'date-from': toDateString(params.dateFrom),
      'date-to': toDateString(params.dateTo),
      include: maybeJoin(params.include || ['appointments']),
      'appointment-status-codes': maybeJoin(params.appointmentStatusCodes),
      'venue-customer-id': params.venueCustomerId,
      utm_source: params.utmSource,
    }),
  );

  return promise;
}
