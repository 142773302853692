import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import extendMixin from 'common/extend-mixin';
import SlideUpDownTransition from 'components/common/transitions/SlideUpDown';
import DialogRow from 'src/components/reports/transactions/common/dialog-row';
import { DialogConfirmation } from 'components/common/dialog/DialogConfirmation';
import DialogErrorList from 'src/components/common/dialog/error-list';
import PriceInput from 'src/components/common/forms/price-input';
import FormValidationMixin from 'src/components/mixins/form-validation';
import { Currency } from 'components/common/DataFormat';

const lang = Wahanda.lang.reports.transactions.closeDay.dialog;

function getRow(title, ContentComponent) {
  return (
    <div className="row clearfix silent">
      <div className="right">{ContentComponent}</div>
      <div className="left">{title}</div>
    </div>
  );
}

function getOpenAppointmentsNotification(notDoneCount, onConfirm, onCancel) {
  const langBase = Wahanda.lang.posRestrictions.closeDayStillOpenAppts;
  const content = (
    <span>
      {langBase.textBeforeCount}
      <strong> {notDoneCount} </strong>
      {langBase.textAfterCount}
    </span>
  );
  const clickFn = (event, closeNotificationDialog) => {
    onConfirm();
    closeNotificationDialog();
  };
  return (
    <DialogConfirmation
      dataTest="transaction-day-status-close-confirmation-modal"
      title={langBase.title}
      text={content}
      buttonTitle={langBase.buttonText}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(event: any, closeNotificationDialog: any) =... Remove this comment to see the full error message
      onButtonClick={clickFn}
      onClose={onCancel}
    />
  );
}

interface ICloseFormProps extends React.HTMLAttributes<Element> {
  saving?: boolean;
  notDoneAppointmentCount?: number;
  expectedAmount?: number;
  persistToBackend?: (...args: any[]) => any;
  saveError?: boolean;
}
type CloseFormState = {
  saveError?: boolean;
  validationErrors?: any;
  showingNotification?: boolean;
  amount?: any;
  userInput?: boolean;
  hasDrawerCorrection?: boolean;
};

export default class CloseForm extends React.Component<ICloseFormProps, CloseFormState> {
  getErrorList: any;

  input: any;

  onFormMaybeSubmitKeyup: any;

  onFormSubmit: any;

  setupValidation: any;

  constructor() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
    super(); // props
    extendMixin(this, FormValidationMixin);
  }

  state = {
    amount: null,
    userInput: false,
  };

  componentDidMount() {
    this.setupValidation();
    this.focus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'validationErrors' does not exist on type... Remove this comment to see the full error message
      saveError: nextProps.saveError && !this.state.validationErrors,
    });
  }

  componentDidUpdate() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'validationErrors' does not exist on type... Remove this comment to see the full error message
    if (this.state.validationErrors) {
      this.focus();
    }
  }

  onFormValid = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (this.props.notDoneAppointmentCount > 0) {
      this.setState({
        showingNotification: true,
      });
    } else {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.persistToBackend({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasDrawerCorrection' does not exist on t... Remove this comment to see the full error message
        hasDrawerCorrection: this.state.hasDrawerCorrection,
      });
    }
  };

  onFormInvalid = (errors) => {
    this.setState({
      validationErrors: errors,
      saveError: false,
    });
  };

  onAmountChange = (amount) => {
    this.setState({
      amount,
      userInput: true,
      hasDrawerCorrection: !isNaN(amount) && amount !== this.props.expectedAmount,
    });
  };

  getAmount() {
    return this.state.amount;
  }

  focus() {
    if (this.input) {
      this.input.focus();
    }
  }

  render() {
    /*
     *  Both "state" and "props" have a property "saving":
     *  while "props.saving" is consumed in the <PriceInput />
     *  component below, "state.saving" is referenced -- but
     *  it never seems to be set ..?
     */
    const state = this.state;
    const { saving, notDoneAppointmentCount, persistToBackend, expectedAmount } = this.props;
    const inputRow = (
      <DialogRow title={lang.labels.cashInDrawer} customClasses="big">
        <PriceInput
          onChange={this.onAmountChange}
          key="pi-for-cdd"
          id="pi-for-cdd"
          autocomplete="off"
          disabled={saving}
          ref={(input) => {
            this.input = input;
          }}
        />
      </DialogRow>
    );
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'showingNotification' does not exist on t... Remove this comment to see the full error message
    const notificationDialog = state.showingNotification
      ? getOpenAppointmentsNotification(
          notDoneAppointmentCount,
          () =>
            // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            persistToBackend({
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasDrawerCorrection' does not exist on t... Remove this comment to see the full error message
              hasDrawerCorrection: state.hasDrawerCorrection,
            }),
          () => this.setState({ showingNotification: false }),
        )
      : null;
    const errorList = this.getErrorList();
    const errors = errorList.length > 0 ? <DialogErrorList errors={errorList} /> : null;
    const buttonClasses = classnames('action-button', {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'saving' does not exist on type '{ amount... Remove this comment to see the full error message
      disabled: state.saving,
    });
    let expectedRow = null;
    let differenceRow = null;
    if (!App.isFeatureSupported('hide-cash-balance')) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
      expectedRow = getRow(lang.labels.expectedCash, <Currency amount={expectedAmount} />);
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
      differenceRow = state.hasDrawerCorrection
        ? // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          getRow(lang.labels.difference, <Currency amount={state.amount - expectedAmount} />)
        : null;
    }
    return (
      <form onSubmit={this.onFormSubmit} onKeyUp={this.onFormMaybeSubmitKeyup}>
        {errors}
        {inputRow}
        {expectedRow}
        {/* @ts-expect-error ts-migrate(2769) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message */}
        <SlideUpDownTransition className="transition-div last">
          {differenceRow}
        </SlideUpDownTransition>
        {notificationDialog}
        <div className="information" dangerouslySetInnerHTML={{ __html: lang.noReopenWarning }} />
        <div className={buttonClasses} onClick={this.onFormSubmit}>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'saving' does not exist on type '{ amount... Remove this comment to see the full error message */}
          {state.saving ? Wahanda.lang.shared.saving : lang.closeDayButton}
        </div>
      </form>
    );
  }
}
