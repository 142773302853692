import React from 'react';
import App from 'common/backbone-app';
import AFBanner from 'common/AFBanner';
import classnames from 'classnames';
import { useBreakpoint } from 'utilities/hooks';
import { LogoConnect, LogoTreatwell } from 'components/common/IconPicture';
import { LoginLanguageSelect } from './LoginLanguageSelect';
import { LoginForm } from './LoginForm';
import { LoginFooter } from './LoginFooter';
import { LoginAnalytics } from './tracking';
import style from './LoginPage.scss';

interface Props {
  route: string;
}

export const LoginPage: React.FC<Props> = ({ route }) => {
  const isMobile = useBreakpoint('mobile');
  const banner = new AFBanner();
  banner.init('js-mobile-banner');
  const containerClass = classnames(style.container, {
    [style.isMobile]: isMobile,
  });
  React.useEffect(() => {
    App.hideLoadingIndicator();
    LoginAnalytics.trackPageView();
  }, []);
  return (
    <div className={containerClass}>
      <div className={style.header}>
        <LoginLanguageSelect />
      </div>
      <div className={style.loginContainer}>
        <h1 className={style.logo}>
          <LogoTreatwell className={style.icon} />
          <LogoConnect className={style.icon} />
        </h1>
        <LoginForm route={route} />
      </div>
      <LoginFooter />
    </div>
  );
};
