import React, { PureComponent } from 'react';
import style from './style.scss';

export const ANGLE = {
  UP: 'UP',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

interface IChevronProps extends React.HTMLAttributes<Element> {
  angle?: any;
  className?: string;
  color?: string;
  size?: number;
}
class Chevron extends PureComponent<IChevronProps, {}> {
  static defaultProps = {
    angle: ANGLE.LEFT,
    className: null,
    color: style.color,
    size: 24,
  };

  getRotateAngle() {
    const { angle } = this.props;
    switch (angle) {
      case ANGLE.UP:
        return 270;
      case ANGLE.RIGHT:
        return 180;
      case ANGLE.DOWN:
        return 90;
      case ANGLE.LEFT:
        return 0;
      default:
        return 0;
    }
  }

  render() {
    const { className, color, size } = this.props;
    return (
      <svg
        style={{
          transform: `rotate(${this.getRotateAngle()}deg)`,
        }}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <path d="M9 16.59L13.58 12 9 7.41 10.41 6l6 6-6 6z" fill={color} fillRule="nonzero" />
      </svg>
    );
  }
}

export default Chevron;
