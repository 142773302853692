import React from 'react';
import classnames from 'classnames';
import { Checkbox } from 'components/common/Checkbox';
import { Tooltip } from 'components/common/Tooltip';
import style from './style.scss';

type ItemProps = {
  employee: any;
  employeeCategoryName?: string;
  checked: boolean;
  disabled: boolean;
  disabledTooltip?: string;
  onClick?: (...args: any[]) => any;
};
const Item: React.SFC<ItemProps> = (props) => {
  const { employee, employeeCategoryName, checked, disabled, disabledTooltip, onClick } = props;
  const labelClassName = classnames(style.item, {
    [style.checked]: checked,
    [style.withCategory]: employeeCategoryName,
  });
  const label = [
    <div className={style.name} key="name">
      {employee.name}
    </div>,
    employeeCategoryName ? (
      <div className={style.categoryName} key="cat">
        {employeeCategoryName}
      </div>
    ) : null,
  ];
  const content = (
    <Checkbox
      name={employee.id}
      label={label}
      checked={checked}
      disabled={disabled}
      className={labelClassName}
      onChange={disabled ? undefined : onClick}
    />
  );
  // In case it's disabled, we need to show the tooltip around it.
  if (disabled && disabledTooltip) {
    return (
      <Tooltip tooltip={disabledTooltip} placement="top">
        {content}
      </Tooltip>
    );
  }
  return content;
};

export default Item;
