import React from 'react';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { trackEvent } from 'common/analytics';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';
import { StateCard } from 'components/common/StateCard';
import { ServiceList } from 'components/common/IconPicture';

import { OfferTypeChoiceDialogAnalytics } from 'components/menu/OfferTypeChoiceDialog/tracking';

import style from './ServicesTab.scss';

interface Props {
  actions: {
    selectPromotion: (param) => void;
  };
  lang: {
    main: {
      addServiceInfo: string;
      noItems: string;
      noServiceList: string;
      noViewPermission: string;
      viewAll: string;
    };
    labels: {
      addGroup: string;
    };
    tooltips: {
      addGroup: string;
    };
  };
}

export class ServicesTab extends React.Component<Props> {
  public componentDidMount() {
    NavigationAnalytics.trackPageEventView(L2Page.SERVICES);
  }

  private showOfferTypeDialog = () => {
    OfferTypeChoiceDialogAnalytics.trackOfferChoiceModalClick();
    App.ES6.Initializers.OfferTypeChoiceDialog().render({
      onSelect: this.onChoose,
    });
  };

  private onChoose = () => {
    App.ES6.Initializers.OfferTypeChoiceDialog().destroy();
  };

  public render() {
    const { lang } = this.props;

    return (
      <div>
        <div id="filters" />
        <div className="data-content menu-content">
          <div className={`${style.menuContent} menu-content-layout js-menu-content`} />
        </div>
        <div className="data-content menu-empty">
          <StateCard
            image={<ServiceList />}
            heading={lang.main.noServiceList}
            message={lang.main.addServiceInfo}
            button={{
              text: Wahanda.lang.menu.buttons.addNew,
              onClick: this.showOfferTypeDialog,
            }}
            secondaryButton={
              App.isMasquerading() && {
                text: Wahanda.lang.menu.buttons.importServices,
                onClick: () => {
                  trackEvent('services', 'click', 'import-services', 'initiate');
                  App.Views.Dialog.IgluModal.openIgluCopyServices(App.getVenueId());
                },
              }
            }
          />
        </div>
        <div className="data-content no-permission">
          <div className="empty-message">
            <h1>{lang.main.noViewPermission}</h1>
          </div>
        </div>
        <div className="data-content menu-empty-filter">
          <div className="menu-empty-inner">
            {lang.main.noItems}, <a href="#ACT">{lang.main.viewAll}</a>
          </div>
        </div>
        <div className="data-content menu-empty-list">
          <div className="menu-empty-inner">{lang.main.noItems}</div>
        </div>
      </div>
    );
  }
}
