import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'reduxStore/common/employees/actions';
import PricingAndServicesTab from './index';

const mapStateToProps = ({ employees: { categories } }) => ({
  categoriesVisible: categories && categories.length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingAndServicesTab);
