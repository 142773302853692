/* eslint-disable */

import App from 'common/backbone-app';
import { trackEvent } from 'common/analytics';
import APPOINTMENT_CHANNEL_CODE from 'common/constants/appointmentChannelCodes';

const GENDERS = { MALE: 'M', FEMALE: 'F' };

var BaseDialog = BackboneEx.View.Dialog2;

var ConsumerDialog = BaseDialog.extend({
  CHANNEL_CUSTOMER: 'CUSTOMER',
  CHANNEL_SUPCUS: 'SUPPLIERS_CUSTOMER',
  CHANNEL_LOCAL: 'SUPPLIER',
  INTRNL: 'INTERNAL',

  events: {
    'click .js-close': 'close',
    'click .a-ok': 'close',
    'click .a-edit': 'onEdit',
    'click .a-dialog': 'openHistoryDialog',
    'click .a-archive': 'onArchive',
    'click .a-new-checkout': 'onCheckout',
    // Notes
    'mouseenter .b-note-right, .b-note-left': 'showInfo',
    'mouseleave .b-note-right, .b-note-left': 'hideInfo',
  },

  options: {
    width: 800,
    title: Wahanda.lang.consumer.dialog.title,
    preload: true,
    dialogClass: 'consumer-dialog',
    dataTest: 'consumer-modal',
  },
  dialogTitle: Wahanda.lang.consumer.dialog.title,
  templateId: 'consumer-dialog-template',
  elementToFocus: null, // do not focus first field automatically
  scrollHistoryTo: null,
  buttons: {},

  initialize: function () {
    BaseDialog.prototype.initialize.call(this);
    trackEvent('client-details', 'view');

    this.listenTo(App, Wahanda.Event.CALENDAR_RESCHEDULE_MODE_START, this.close.bind(this));
    this.listenTo(App, Wahanda.Event.CALENDAR_REBOOKING_MODE_START, this.close.bind(this));
    this.listenTo(App, Wahanda.Event.CUSTOMER_SAVED, this.close.bind(this));
  },

  render: function () {
    if (!this.rendered) {
      BaseDialog.prototype.render.call(this);
    }
    var self = this;
    this.preload = this.options.preload;
    this.$('.client-other-info').tabs();

    var e = Wahanda.Event;
    App.on(
      [
        e.APPOINTMENT_CONFIRMED,
        e.APPOINTMENT_REJECTED,
        e.APPOINTMENT_CANCELLED,
        e.APPOINTMENT_SAVED,
        e.APPOINTMENT_CHECKED_OUT,
        e.BOOKING_CONFIRMED,
        e.BOOKING_REJECTED,
      ].join(' '),
      function () {
        self.refreshHistory();
      },
      this,
    );

    if (this.preload) {
      this.preload = false;
      this.model.include = 'history';
      this.model.fetch({
        success: function () {
          self.model.trigger('fetched');
        },
      });
    } else {
      this.renderContent();
    }

    const commission = this.$('.client-main-info .commission');

    if (App.config.isVenueSingleConnect()) {
      const link = commission.find('a.about-ts-and-cs');
      const linkText = link.text().trim();
      const linkHref = link.attr('href').trim();

      if (linkText && linkHref) {
        link.click((e) => {
          App.trigger(Wahanda.Event.CONSUMER_COMMISSION_LINK_CLICKED);
        });
      } else {
        link.remove();
      }
    } else {
      commission.remove();
    }

    this.model.on('fetched saved', function () {
      self.renderContent();
      self.unloadmask();
    });

    this.$('.full-loading').remove();
  },

  onDialogOpen: function () {
    // Attach shadows only when the tab is shown
    var self = this;
    var $tabList = this.$('.client-other-info');
    $tabList.on('tabsshow.consumerdialog', function (event, ui) {
      var $tab = $(ui.tab);
      if ($tab.attr('href') === '#client-history') {
        $tabList.off('tabsshow.consumerdialog');
        self.$('.client-history-body').shadows();
      }
    });
    App.trigger(Wahanda.Event.CUSTOMER_DIALOG_OPENED);
  },

  renderContent: function () {
    this.$('.a-edit').wToggle(
      Wahanda.Permissions.editClientContactDetails() && !this.model.isArchived(),
    );

    this.$('.b-archived').wToggle(this.model.isArchived());

    this.renderModelAttributes();

    if (App.config.isVenueSingleConnect()) {
      this.renderClientSource();
    } else {
      this.removeClientSource();
    }

    /*
     *  CSS puts a border onto each of the bottom of elements in this block except for :last-of-type
     *
     *  Which means: we can't rely on hiding elements, we must remove them from the DOM
     *
     *  (They get put back if the block renders again)
     */

    if (!this.model.get('phone')) {
      this.$('.b-phone').remove();
    }

    if (!this.model.get('emailAddress')) {
      this.$('.b-emailAddress').remove();
    } else {
      if (Wahanda.Permissions.viewClientContactDetails()) {
        this.$('.v-emailAddress').attr('href', `mailto:${this.model.get('emailAddress')}`);
      }
    }

    if (
      (this.model.get('emailAddress') || this.model.get('phone')) &&
      Wahanda.Permissions.viewClientContactDetails()
    ) {
      this.renderCommunicationMessage();
    }
    this.renderPrepayMessage();

    this.renderGender();
    this.renderBirthday();
    this.renderHistory();
    this.renderNotes();

    if (App.config.isVenueSingleConnect()) {
      this.renderCommissionNote();
    }

    if (App.config.getChannelSupportedLocales()) {
      this.renderLocale();
    }

    var buttons = {};

    if (Wahanda.Permissions.editClientContactDetails() && !this.model.isArchived()) {
      _.extend(buttons, {
        menu: {
          items: [
            {
              label: Wahanda.lang.consumer.dialog.editDetails,
              classes: 'a-edit',
            },
            {
              label: Wahanda.lang.shared['delete'],
              classes: 'a-archive',
            },
          ],
        },
      });
    }

    if (App.config.get('venue').pointOfSaleEnabled && !this.model.isArchived()) {
      _.extend(buttons, {
        checkout: {
          title: Wahanda.lang.consumer.dialog.buttons.newCheckout,
          onRight: true,
          classes: 'a-new-checkout checkout dialog2--button-blue',
        },
      });
    }

    this.buttons = buttons;
    this.$('.dialog-content').show();

    BaseDialog.prototype.bindMenuButtonEvents.call(this);
    BaseDialog.prototype.renderFooter.call(this);
    BaseDialog.prototype.refreshPosition.call(this);
  },

  /**
   * Removes contact details containers from DOM.
   */
  clearContactDetails: function () {
    this.$('.person-info').remove();
  },

  renderCommissionNote: function () {
    const commission = this.$('.client-main-info .commission');
    const commissionText = commission.find('.commission-rate');
    const commissionLang = Wahanda.lang.consumer.dialog.commission;

    // Set this to new text.
    commissionText.prepend(commissionLang.commissionNote);
    commission.show();

    const link = commission.find('a.about-ts-and-cs');
    const linkText = link?.text()?.trim();
    const linkHref = link?.attr('href')?.trim();

    if (!linkText || !linkHref) {
      link.remove();
    }
  },

  renderNotes: function () {
    var notes = this.model.get('notes');
    var text = notes;
    var hasNotes = !(notes == '' || notes == null);

    if (!hasNotes) {
      text = Wahanda.lang.consumer.dialog.notes.notAvailable;
    }

    const html = $('<span />').text(String(text));

    this.$('.v-notes')
      .toggleClass('note-wrapper', hasNotes)
      .toggleClass('not-available', !hasNotes)
      .empty()
      .append(html)
      .shadows();
  },

  renderLocale: function () {
    // only render if channel has supportedLocales && the client has a locale value
    var locale = this.model.get('locale');
    if (locale) {
      var localeName = Wahanda.lang.language[locale];
      if (!localeName) {
        var supportedLocales = _.invert(App.config.getChannelSupportedLocales());
        localeName = supportedLocales[localeName];
      }
      this.$('.v-locale').text(localeName);
      this.$('.b-locale').wShow();
    }
  },

  renderClientSource: function () {
    // v!
    const clientSource = this.$('.v-clientSource');

    if (this.model.isMarketplaceBooking()) {
      clientSource.addClass('treatwell-badge');
    } else if (this.model.isWidgetBooking()) {
      const text = Wahanda.lang.consumer.dialog.clientSource.widgetBooking;

      clientSource.text(text);
    } else if (this.model.isInternalBooking()) {
      const text = Wahanda.lang.consumer.dialog.clientSource.internalBooking;

      clientSource.text(text);
    } else if (this.model.isConnectBooking()) {
      const text = Wahanda.lang.consumer.dialog.clientSource.connectBooking;

      clientSource.text(text);
    }
  },

  removeClientSource: function () {
    // b!
    this.$('.b-clientSource').remove();
  },

  renderCommunicationMessage: function () {
    var agreed = this.model.get('sendMassEmails');
    var title, icon;
    if (agreed) {
      title = Wahanda.lang.consumer.dialog.massEmail.agreed;
      icon = 'tick';
    } else {
      title = Wahanda.lang.consumer.dialog.massEmail.notAgreed;
      icon = 'icons-x-small-red';
    }

    var $block = this.$('.b-mass-email');
    $block.find('.v-icon').addClass(icon);
    $block.find('.v-text').text(title);
    $block.wShow();
  },

  renderPrepayMessage: function () {
    const isPrepaySet = this.model.get('prepaymentRequired');

    if (!!App.config.canShowPrepayRequirementFeatures() && isPrepaySet) {
      this.$('.b-prepay-message').wShow();
    }
  },

  renderGender: function () {
    const gender = this.model.get('gender');
    const genderText =
      gender === GENDERS.MALE
        ? Wahanda.lang.consumer.dialog.labels.male
        : Wahanda.lang.consumer.dialog.labels.female;

    if (gender) {
      this.$('.v-gender-name').removeClass('not-available').text(genderText);
    } else {
      this.$('.v-gender-name')
        .addClass('not-available')
        .text(Wahanda.lang.consumer.dialog.labels.notAvailable);
    }
  },

  renderBirthday: function () {
    var month = this.model.get('birthMonth');
    if (month) {
      month = month - 1;

      var hasYear = !!this.model.get('birthYear');
      var year = this.model.get('birthYear') || 2000;
      var day = this.model.get('birthDay');
      var format = App.config.get('jqueryDateFormat')[hasYear ? 'longDate' : 'mediumDate'];

      var date = new Date(year, month, day);
      strDate = Wahanda.Date.formatDate(format, date);

      this.$('.v-birthday').removeClass('not-available').text(strDate);
    } else {
      this.$('.v-birthday')
        .addClass('not-available')
        .text(Wahanda.lang.consumer.dialog.labels.notAvailable);
    }
  },

  renderHistory: function () {
    var length = !this.model.get('history') ? 0 : this.model.get('history').length;
    if (length === 0) {
      this.$('#client-history').addClass('empty');
    } else {
      this._renderHistory();
    }

    this.$('.v-history-count').text(length);
  },

  refreshHistory: function () {
    var self = this;
    this.$el.loadmask();

    this.scrollHistoryTo = this.$('.client-history-body').scrollTop();

    this.model.fetch({
      success: function () {
        self.renderHistory();
        self.$el.unloadmask();
      },
    });
  },

  _renderHistory: function () {
    var self = this;
    var data = [];

    _.each(this.model.get('history'), function (entry, position) {
      var icon = self._getHistoryIconData(entry);
      var dateTime = self._getHistoryDateTimeData(entry);
      var isMarketplaceBooking = self._isMarketplaceBooking(entry);
      const isBookedWithGoogle = self.isBookedWithGoogle(entry);
      var isUnpaid = isItemUnpaid(entry);

      data.push({
        icon: icon.icon,
        iconTitle: icon.title,
        date: dateTime.date,
        time: dateTime.time,
        info: entry.appointmentNotes,
        isMarketplaceBooking,
        isBookedWithGoogle,
        acquisition: isMarketplaceBooking
          ? Wahanda.lang.consumer.dialog.clientSource.marketplaceBooking
          : self._getHistoryAcquisitionChannel(entry),
        serviceName: entry.offerName,
        variantName: entry.offerVariantName,
        employeeName: entry.employeeName,
        amount: !isUnpaid && entry.amountPaid > 0 ? Wahanda.Currency.format(entry.amountPaid) : '',
        status: self._getHistoryStatusData(entry, dateTime.dateObject, isUnpaid),
        itemNo: position,
        nights: self._getHistorySpaBreakNightsText(entry),
        serviceTypeBlurred: entry.serviceTypeCode === 'S',
      });
    });

    var $container = this.$('.client-history-body');
    $container.find('table:first').html(
      Wahanda.Template.renderTemplate('consumer-dialog-template-items', {
        list: data,
      }),
    );

    if (this.scrollHistoryTo != null) {
      $container.scrollTop(this.scrollHistoryTo);
      this.scrollHistoryTo = null;
    }

    function isItemUnpaid(item) {
      if (!item.payAtVenue || item.appointmentStatusCode === 'CP') {
        return false;
      }
      // If an unpaid appointment isn't checked out, always treat it as unpaid.
      if (item.bookingType === 'AP') {
        return true;
      }
      // Dated item. Check if the date isn't in the past.
      var date = Wahanda.Date.createVenueDate(item.visitDate, item.visitTime);
      var today = Wahanda.Date.createVenueDate();
      var result = Wahanda.Date.compare(date, today);
      return result <= 0;
    }
  },

  isBookedWithGoogle: function ({ channelCode }) {
    return channelCode != null && channelCode.startsWith(APPOINTMENT_CHANNEL_CODE.BOOKED_BY_GOOGLE);
  },

  _isMarketplaceBooking: function ({ bookingActor }) {
    return bookingActor === this.CHANNEL_CUSTOMER;
  },

  _isWidgetBooking: function ({ bookingActor }) {
    return bookingActor === this.CHANNEL_SUPCUS;
  },

  _isInternalBooking: function ({ bookingActor }) {
    return bookingActor === this.INTRNL;
  },

  _isConnectBooking: function ({ bookingActor }) {
    return !bookingActor || bookingActor === this.CHANNEL_LOCAL;
  },

  _getHistoryAcquisitionChannel: function (entry) {
    if (this._isWidgetBooking(entry)) {
      return Wahanda.lang.consumer.dialog.clientSource.widgetBooking;
    } else if (this._isInternalBooking(entry)) {
      return Wahanda.lang.consumer.dialog.clientSource.internalBooking;
    } else if (this._isConnectBooking(entry)) {
      return Wahanda.lang.consumer.dialog.clientSource.connectBooking;
    }
  },

  _getHistoryIconData: function (entry) {
    var icon, title;
    if (entry.bookingType === 'AP') {
      icon = 'appointment';
      title = Wahanda.lang.consumer.dialog.history.types.appointment;
    } else if (entry.bookingType === 'DT') {
      icon = 'dated';
      title = Wahanda.lang.consumer.dialog.history.types.dated;
    } else {
      var isEvoucher = entry.evouchers && entry.evouchers.length > 0;
      icon = 'voucher3';
      title = Wahanda.lang.consumer.dialog.history.types[(isEvoucher ? 'e' : '') + 'voucher'];
    }

    return {
      icon: icon,
      title: title,
    };
  },

  _getHistoryDateTimeData: function (entry) {
    if (!entry.visitDate) {
      return {};
    }

    var date = Wahanda.Date.createDate(entry.visitDate, entry.visitTime || '00:00');
    var result = Wahanda.Date.formatToDefaultFullDate(date);
    result.dateObject = date;

    if (!entry.visitTime || entry.bookingType === 'DT') {
      result.time = null;
    }

    return result;
  },

  _getHistoryStatusData: function (entry, dateObject, isUnpaid) {
    var data = {
      statuses: [],
    };

    var bookingClassMap = {
      UC: 'not-confirmed',
      CR: 'not-confirmed',
      CN: 'confirmed',
      CC: 'critical',
      RJ: 'critical',
      CP: 'checkedout',
    };

    if (entry.evouchers && entry.evouchers.length > 0) {
      var list = {};
      var result = [];
      _.each(entry.evouchers, function (voucher) {
        if (list[voucher.evoucherStatusCode]) {
          list[voucher.evoucherStatusCode]++;
        } else {
          list[voucher.evoucherStatusCode] = 1;
        }
      });

      var voucherClassMap = {
        A: 'confirmed',
        R: 'done',
        V: 'critical',
        X: 'not-confirmed',
      };

      _.each(['A', 'R', 'V', 'X'], function (code) {
        if (list[code] > 0) {
          result.push({
            evoucherStatusClass: voucherClassMap[code],
            evoucherStatusText: Wahanda.lang.consumer.dialog.history.evoucherCounts[code].replace(
              '{{count}}',
              list[code],
            ),
            evoucherSeparator: true,
          });
        }
      });

      if (result.length > 0) {
        result[0].evoucherSeparator = false;
        result[result.length - 1].evoucherSeparator = false;
      }

      data.eVouchers = result;
    } else if (entry.bookingType === 'AP') {
      if ('CC' === entry.appointmentStatusCode && 'NS' === entry.appointmentCancellationReason) {
        data.noShow = true;
        data.noShowNote = entry.appointmentCancellationNotes;
      } else {
        // Confirmed
        // This is a workaround for DEV-28252: currently the Packages are returned as Appointments in the structure.
        var status = entry.appointmentStatusCode || entry.bookingStatus;
        data.statuses.push({
          statusName: App.Models.Appointment.getStatusText(status),
          statusClass: App.Models.Appointment.getStatusClass(status).replace(/^status-/, ''),
          notes: entry.appointmentCancellationNotes,
        });
      }
    } else if (entry.bookingType === 'DT') {
      data.statuses.push({
        statusName: Wahanda.lang.consumer.dialog.history.datedStatuses[entry.bookingStatus],
        statusClass: bookingClassMap[entry.bookingStatus],
      });
    }

    if (isUnpaid) {
      data.statuses.push({
        statusName: Wahanda.lang.calendar.appointments.multi.labels.unpaid,
        statusClass: 'unpaid',
      });
    }
    // Else, this is probably an physical voucher. Don't show any status for it.

    return data;
  },

  _getHistorySpaBreakNightsText: function (entry) {
    var text = null;

    if (this._isSpaBreak(entry) && entry.nights) {
      text = Wahanda.Text.pluralizeNumber(entry.nights, [
        Wahanda.lang.datetime.duration.night.toLowerCase(),
        Wahanda.lang.datetime.duration.nights.toLowerCase(),
      ]);
      text = '(' + text + ')';
    }

    return text;
  },

  _isSpaBreak: function (entry) {
    var TYPE_SPA_BREAK = 'O';
    return entry.bookingType === 'DT' && entry.serviceTypeCode === TYPE_SPA_BREAK;
  },

  // Events

  onEdit: function () {
    trackEvent('client', 'click', 'edit', 'from-client-details');
    App.ES6.Initializers.CustomerFormDialog({ id: this.model.id }).render();
  },

  showInfo: function (event) {
    var $target = $(event.currentTarget);
    var $ancestor = this.$('.client-history-body');
    // Title: Note, No show note
    var text = $target.data('info');
    var isLeft = $target.is('.b-note-left');
    var isNoShow = $target.is('.b-noshow');
    var critical = $target.is('.b-critical');

    var offset = $target.offset();
    var parentOffset = $ancestor.offset();

    var $info = $(
      Wahanda.Template.renderTemplate('consumer-dialog-template-infotip', {
        tooltipDirection: isLeft ? 'left' : 'right',
        typeTitle: Wahanda.lang.consumer.dialog.history.tip[isNoShow ? 'noShow' : 'default'],
        note: text,
        critical: critical || isNoShow,
      }),
    );
    $ancestor.append($info);

    var infoWidth = $info.outerWidth();
    var infoHeight = $info.outerHeight();
    var containerHeight = $ancestor.height();
    var containerScroll = $ancestor.scrollTop();
    // Tooltip position regarding to the scrollable container
    var y = offset.top - parentOffset.top + containerScroll - 25;
    if (y + infoHeight > containerHeight + containerScroll) {
      y = containerHeight - infoHeight + containerScroll;
    }
    // Uncomment this to force the tooltip always show it's top part. But then handle problems with arrow
    // being positioned incorrectly.
    // if (y < containerScroll) {
    // y = containerScroll;
    // }

    $info.css({
      left: offset.left - parentOffset.left + (isLeft ? -10 - infoWidth : 24),
      top: y,
    });
    // Arrow tip positioning
    $info.find('.arrow').css({
      top: offset.top - parentOffset.top + containerScroll - y - 3,
    });
  },

  hideInfo: function (event) {
    this.$('.b-tooltip').remove();
  },

  openHistoryDialog: function (event) {
    event.preventDefault();

    var itemIndex = $(event.currentTarget).data('index');
    var entry = this.model.get('history')[itemIndex];
    if (!entry) {
      return;
    }

    if (entry.bookingType === 'AP') {
      if (entry.appointmentId) {
        App.ES6.Initializers.State.change({
          'calendar-event-editor': null,
        });
        App.Views.Forms.Appointment2.openById(entry.appointmentId);
      } else {
        App.Views.Forms.Appointment2.openByOrderId(entry.orderId);
      }
    } else if (entry.orderId) {
      App.Views.Dialog.Order2.openById(entry.orderId);
    }
  },

  onArchive: function () {
    var self = this;
    var $button = this.$('.a-archive');
    var i18n = Wahanda.lang.consumer.dialog.questions;

    var confirmView = new App.Views.Dialog.ClientDeletionConfirm({
      model: this.model,
      parentDialog: this,
    });
    confirmView.render();
    confirmView.open();
  },

  onCheckout: function () {
    const openCheckoutDialog = () => {
      var dialog = new App.Views.Forms.POSDialog({
        venueCustomerId: this.model.get('id'),
        mediator: this.options.mediator,
      });
      dialog.render();
      dialog.open();
    };

    Wahanda.Cache.posStatus().done((status) => {
      if (!Wahanda.POSCheckoutCheck(status, openCheckoutDialog)) {
        return;
      }
      openCheckoutDialog();
    });
  },
});

App.Views.Dialog.Consumer = ConsumerDialog;

/**
 * Creates an instance with the given id's model, and returns it.
 *
 * @param mixed data Object of model attributes, or the ID of the customer to show
 * @return App.Views.Dialog.Consumer
 */
App.Views.Dialog.Consumer.createInstance = function (data, mediator) {
  if (!isNaN(data)) {
    data = {
      id: data,
    };
  }
  var model = new App.Models.Customer(data);
  return new App.Views.Dialog.Consumer({
    model: model,
    mediator: mediator,
  });
};
