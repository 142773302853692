import moment from 'common/moment';

export enum PRESET_DATE {
  THIS_MONTH = 'this-month',
  LAST_MONTH = 'last-month',
  THIS_WEEK = 'this-week',
  LAST_WEEK = 'last-week',
  THIS_YEAR = 'this-year',
  LAST_30_DAYS = 'last-30-days',
  CUSTOM = 'custom',
  UNSUPPORTED = 'unsupported',
}

export interface PresetDateRangeProps {
  buttonText: string;
  type: PRESET_DATE;
  getDates: () => {
    startDate: moment.Moment;
    endDate: moment.Moment;
  };
}

export interface PresetRanges {
  thisMonth: PresetDateRangeProps | null;
  lastMonth: PresetDateRangeProps | null;
  thisWeek: PresetDateRangeProps | null;
  lastWeek: PresetDateRangeProps | null;
  thisYear: PresetDateRangeProps | null;
  last30Days: PresetDateRangeProps | null;
}
