import { createSelector } from 'reselect';

export const getPartnerSite = (state) => state.marketing.partnerSite;

export const getPartnerSiteSettings = createSelector(
  getPartnerSite,
  (partnerSite) => partnerSite.settings || {},
);

export const getPartnerSiteImpressum = createSelector(
  getPartnerSite,
  (partnerSite) => partnerSite.impressum || {},
);

export const getPartnerSiteSettingsSubdomain = createSelector(
  getPartnerSiteSettings,
  (partnerSiteSettings) => partnerSiteSettings.micrositeSubdomain || '',
);

export const getPartnerSiteSettingsCustomDomain = createSelector(
  getPartnerSiteSettings,
  (partnerSiteSettings) => partnerSiteSettings.micrositeCustomDomain || '',
);
