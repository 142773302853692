import React from 'react';
import classnames from 'classnames';

import style from './DialogFooterGroupItem.scss';

interface Props {
  title: string;
  type: 'regular' | 'critical';
  onClick: () => void;
}

export class DialogFooterGroupItem extends React.PureComponent<Props> {
  public static defaultProps = {
    type: 'regular',
  };

  public render() {
    const { title, type, onClick } = this.props;
    const className = classnames(style.container, style[type]);

    return (
      <li className={className} onClick={onClick}>
        {title}
      </li>
    );
  }
}
