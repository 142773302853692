import { put, call } from 'redux-saga/effects';
import downloadFile from 'services/downloadFile';
import apiUrl from 'common/api-url';
import {
  downloadCoronaReportRequestAction,
  downloadCoronaReportRequestFailureAction,
  downloadCoronaReportRequestSuccessAction,
} from './actions';

export function* downloadCoronaReport(
  action: ReturnType<typeof downloadCoronaReportRequestAction>,
) {
  const { from, to } = action.payload;
  try {
    yield call(downloadFile, {
      url: apiUrl('COVID_REPORT', { notBefore: from, notAfter: to }),
      fileName: `corona_report_${from}_${to}`,
      type: 'text/csv',
    });
    yield put(downloadCoronaReportRequestSuccessAction());
  } catch (e) {
    yield put(downloadCoronaReportRequestFailureAction());
  }
}
