import { call, put, select } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import BigNumber from 'bignumber.js';
import { CHANNEL } from 'components/reports/newSalesReports/BookingChannelFilter';
import { allEmployeesOptionValue } from 'components/reports/newSalesReports/EmployeeFilter';
import apiUrl from 'common/api-url';
import Wahanda from 'common/wahanda';
import {
  receiveSalesReportDataAction,
  requestSalesReportDataFailureAction,
  receiveProductSalesReportDataAction,
  requestProductSalesReportDataFailureAction,
  receiveEmployeeSalesReportDataAction,
  requestEmployeeSalesReportDataFailureAction,
  receiveServiceSalesReportDataAction,
  requestServiceSalesReportDataFailureAction,
  receiveChannelSalesReportDataAction,
  requestChannelSalesReportDataFailureAction,
  receiveMonthlySalesReportDataAction,
  requestMonthlySalesReportDataFailureAction,
  receiveVatReportDataAction,
  requestVatReportDataFailureAction,
  receiveDailySalesReportDataAction,
  requestDailySalesReportDataFailureAction,
  receiveVenueEmployeeDataAction,
  requestVenueEmployeeDataFailureAction,
  receiveRettentionReportDataAction,
  receiveAcquisitionReportDataAction,
  getPOSReportDataReceiveAction,
  getPOSReportDataFailureAction,
} from './actions';
import { hasEmployees, getEmployeeIdListForReports } from './selectors';
import reportingApiRoot from './common/reportingApi';

export function* getPosReportData(params) {
  try {
    const { from, to } = params;
    const response = yield call(api.get, apiUrl('PERIOD_SALES'), {
      data: { from, to },
    });
    yield put(getPOSReportDataReceiveAction(response));
  } catch (err) {
    yield put(getPOSReportDataFailureAction(err));
  }
}

export function* getAcquisitionReportData(params) {
  try {
    const { fromDate, toDate, selectedEmployeeId } = params;
    const data = {
      fromMonth: fromDate,
      toMonth: toDate,
      employeeId: selectedEmployeeId,
    };
    const response = yield call(api.get, apiUrl('ACQUISITION_REPORTS'), {
      data,
    });
    yield put(receiveAcquisitionReportDataAction(response));
  } catch (err) {
    console.log(err);
  }
}

export function* getRetentionReportData(params) {
  try {
    const { selectedEmployeeId, fromDate, toDate, selectedChannel } = params;
    const data = {
      fromMonth: fromDate,
      toMonth: toDate,
      employeeId: new BigNumber(selectedEmployeeId).isEqualTo(allEmployeesOptionValue)
        ? undefined
        : selectedEmployeeId,
      bookingChannel: selectedChannel !== CHANNEL.ALL ? selectedChannel : undefined,
    };

    const response = yield call(api.get, apiUrl('RETTENTION_REPORTS'), {
      data,
    });
    yield put(receiveRettentionReportDataAction(response));
  } catch (err) {
    console.log(err);
  }
}

export function* getSalesReportData(params) {
  try {
    const employeesIds = yield call(getEmployeeIdsFor, params.employeeFilter);
    const url = apiUrl('SALES_SUMMARY', {
      from: params.from.formatApiDateString(),
      to: params.to.formatApiDateString(),
      employees: employeesIds,
      bookingChannel: params.bookingChannel,
    });
    const data = yield call(api.get, url);
    yield put(receiveSalesReportDataAction(data));
  } catch (err) {
    yield put(requestSalesReportDataFailureAction(err));
  }
}

export function* getProductSalesReportData(params) {
  try {
    const employeesIds = yield call(getEmployeeIdsFor, params.employeeFilter);
    const url = apiUrl('PRODUCT_SALES', {
      from: params.from.formatApiDateString(),
      to: params.to.formatApiDateString(),
      employees: employeesIds,
    });
    const data = yield call(api.get, url);
    yield put(receiveProductSalesReportDataAction(data));
  } catch (err) {
    yield put(requestProductSalesReportDataFailureAction(err));
  }
}

export function* getEmployeeSalesReportData(params) {
  try {
    const requestString = `${reportingApiRoot}/venue/${
      params.venueId
    }/reports/sales/employees?from=${params.from.format('YYYY-MM-DD')}&to=${params.to.format(
      'YYYY-MM-DD',
    )}`;
    const data = yield call(api.get, requestString);
    yield put(receiveEmployeeSalesReportDataAction(data));
  } catch (err) {
    yield put(requestEmployeeSalesReportDataFailureAction(err));
  }
}

export function* getServiceSalesReportData(params) {
  try {
    const employeesIds = yield call(getEmployeeIdsFor, params.employeeFilter);
    const url = apiUrl('SALES_SERVICES', {
      from: params.from.formatApiDateString(),
      to: params.to.formatApiDateString(),
      bookingChannel: params.channel,
      employees: employeesIds,
    });
    const data = yield call(api.get, url);
    yield put(receiveServiceSalesReportDataAction(data));
  } catch (err) {
    yield put(requestServiceSalesReportDataFailureAction(err));
  }
}

export function* getChannelSalesReportData(params) {
  try {
    const requestString = `${reportingApiRoot}/venue/${
      params.venueId
    }/reports/sales/channels?from=${params.from.format('YYYY-MM-DD')}&to=${params.to.format(
      'YYYY-MM-DD',
    )}`;
    const data = yield call(api.get, requestString);
    yield put(receiveChannelSalesReportDataAction(data));
  } catch (err) {
    yield put(requestChannelSalesReportDataFailureAction(err));
  }
}

export function* getMonthlySalesReportData(params) {
  try {
    const employeesIds = yield call(getEmployeeIdsFor, params.employeeFilter);
    const url = apiUrl('MONTHLY_SALES', {
      from: params.from.formatApiDateString(),
      to: params.to.formatApiDateString(),
      bookingChannel: params.channel,
      employees: employeesIds,
    });
    const data = yield call(api.get, url);
    yield put(receiveMonthlySalesReportDataAction(data));
  } catch (err) {
    yield put(requestMonthlySalesReportDataFailureAction(err));
  }
}

export function* getVatReportData(params) {
  try {
    const employeeIdParam = params.employeeId ? `&employee=${params.employeeId}` : '';
    const requestString = `${reportingApiRoot}/venue/${
      params.venueId
    }/reports/sales/vat?from=${params.from.format('YYYY-MM-DD')}&to=${params.to.format(
      'YYYY-MM-DD',
    )}${employeeIdParam}`;
    const data = yield call(api.get, requestString);
    yield put(receiveVatReportDataAction(data));
  } catch (err) {
    yield put(requestVatReportDataFailureAction(err));
  }
}

export function* getDailySalesReportData(params) {
  try {
    const employeesIds = yield call(getEmployeeIdsFor, params.employeeFilter);
    const url = apiUrl('DAILY_SALES', {
      from: params.from.formatApiDateString(),
      to: params.to.formatApiDateString(),
      bookingChannel: params.bookingChannel,
      employees: employeesIds,
    });
    const data = yield call(api.get, url);
    yield put(receiveDailySalesReportDataAction(data));
  } catch (err) {
    yield put(requestDailySalesReportDataFailureAction(err));
  }
}

export function* getEmployeeIdsFor(employmentStatusOrId) {
  const employeeListNotEmpty = yield select(hasEmployees);
  if (!employeeListNotEmpty) {
    yield call(getVenueEmployeeData);
  }
  return yield select(getEmployeeIdListForReports, { employmentStatusOrId });
}

export function* getVenueEmployeeData() {
  try {
    const employees = yield call(Wahanda.Cache.allEmployees);
    yield put(receiveVenueEmployeeDataAction(employees));
  } catch (err) {
    yield put(requestVenueEmployeeDataFailureAction(err));
  }
}
