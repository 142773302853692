/* global BackboneEx _ */
import { trackEvent } from 'common/analytics';
import {
  doesUseExistingCategory,
  getEmployees,
} from 'components/menu/OfferTreatmentPricingAndEmployees/utils';
/**
 * Menu offer.
 */
App.Views.Menu.Offer = BackboneEx.View.Menu.OfferEditing.extend({
  events: {
    click: 'editOffer',
  },

  /**
   * ms while the offer is held as new
   */
  timeWhenNew: 86400000,

  initialize: function initialize() {
    this.model.on('saved', function saved() {
      App.initVenue(null);
    });
  },

  render: function render() {
    const html = Wahanda.Template.renderTemplate('offer-group-offer', this.getTemplateVars(), {
      priceTpl: Wahanda.Template.get('sku-price-template'),
    });
    this.setElement($(html));
    this.bindTooltips();
  },

  rerender: function rerender() {
    const $el = $(Wahanda.Template.renderTemplate('offer-group-offer', this.getTemplateVars()));

    this.$el.replaceWith($el);
    this.setElement($el);
    this.bindTooltips();
  },

  /**
   * Generates template variables based on the model.
   *
   * @return Object
   */
  getTemplateVars: function getTemplateVars() {
    let className = this.model.get('visible') ? '' : 'not-visible';
    if (!this.model.isEditable()) {
      className += ' not-editable';
    }

    let title = this.model.get('name');
    if (title === '') {
      const treatmentId = this.model.get('primaryTreatmentId');
      if (treatmentId != null) {
        title = this.options.treatments.get('treatmentsCollection').get(treatmentId).get('name');
      }
    }

    // Distribution channels
    const channels = {};
    _.each(this.model.get('distributionChannels'), function f(channel) {
      channels[channel] = true;
    });
    const sku = this.model.get('skus')[0];
    const { priceFrom } = this.getSkuPriceAndDurationRange(this.model.get('skus'));
    const purchasable =
      !!this.model.get('amountFrom') ||
      !!(sku && (sku.fullPriceAmount || sku.calculatedPriceAmount)) ||
      !!priceFrom;

    let data = {
      id: this.model.get('id'),
      title: title,
      offerClassName: className,
      info: [],
      customInfo: null,
      fullPrice: null,
      icon: this.model.getIconByType(),
      SKUs: [],
      // TRUE if only one sku, and it is sold out
      soldOut: null,
      unpurchasable: !purchasable,
      fulfillmentName: this.model.getFulfillmentName(),
      showFulfillmentType: this.model.canShowFulfillmentType(),
      visibility: this.model.getVisibilityInfo(),
      channels: channels,
      isEscape: this.model.isEscape(),
      showId: App.isMasquerading(),
      discountRuleName: this.model.get('discountRuleName'),
      discountRuleActive: this.model.get('discountRuleActive'),
    };

    if (this.model.get('featured')) {
      data.info.push({
        text: Wahanda.lang.menu.info.featured,
        type: 'featured',
      });
    }

    const created = this.model.get('created');
    if (
      created &&
      new Date().getTime() - Wahanda.Date.parse(created).getTime() <= this.timeWhenNew
    ) {
      data.info.push({
        text: Wahanda.lang.menu.new,
        type: 'new',
      });
    }

    if (this.model.isEscapeWithRangePricing()) {
      data = this.addDatedInformation(data);
    } else if (this.model.isServicePackage()) {
      data = this.addPackageSkuInformation(data);
    } else {
      data = this.addSkuInformation(data);
    }

    return data;
  },

  addDatedInformation: function addDatedInformation(params) {
    const data = params;
    // Price of the dated offer
    const priceFrom = this.model.get('amountFrom');
    const priceTo = this.model.get('amountTo');

    if (!priceFrom && !priceTo) {
      data.priceNote = {
        type: 'critical',
        text: Wahanda.lang.menu.offer.pricing.labels.notDefined,
      };
    } else if (priceFrom === priceTo) {
      data.price = Wahanda.Currency.getFormattedOrEmpty(priceFrom);
    } else {
      data.price = `${Wahanda.Currency.getFormattedOrEmpty(
        priceFrom,
      )} - ${Wahanda.Currency.getFormattedOrEmpty(priceTo)}`;
    }

    // Number of spa-break days
    if (this.model.isSpaBreak()) {
      data.customInfo = Wahanda.lang.menu.offer.dated.spaBreaks.nights.replace(
        '{{count}}',
        this.model.get('stayNights'),
      );
    }

    return data;
  },

  getPackageSkuDuration: function getPackageSkuDuration(sku) {
    const self = this;
    // The duration of a Package sku is the sum of all sub skus.
    let duration = 0;
    _.each(sku.subSkus, function f(subSku) {
      if (!subSku.duration) {
        return;
      }
      duration += subSku.duration;

      if (subSku.finishingTimeMins) {
        duration += subSku.finishingTimeMins;
      }
    });

    // Add any cleanup or processing time the offers might have
    const offers = this.options.fullMenu.get('offersCollection');
    duration += _.reduce(
      self.model.get('subServices'),
      function f(memo, subService) {
        const offer = offers.get(subService.serviceId);
        if (offer) {
          return memo + offer.getAdditionalTimeMins();
        }
        return memo;
      },
      0,
    );

    return duration;
  },

  getSkuPriceAndDurationRange: function getSkuPriceRange(skus) {
    const self = this;
    let durationFrom = 0;
    let durationTo = 0;
    let priceFrom = 0;
    let priceTo = 0;

    _.each(skus, function f(sku) {
      if (!sku.visible) {
        return;
      }
      const skuPrice = App.Models.MenuOffer.getPackageSkuPrice(sku, true) || 0;
      if (!priceFrom || priceFrom > skuPrice) {
        priceFrom = skuPrice;
      }
      if (!priceTo || priceTo < skuPrice) {
        priceTo = skuPrice;
      }

      const skuDuration = self.getPackageSkuDuration(sku);
      durationFrom = Math.min(durationFrom > 0 ? durationFrom : skuDuration, skuDuration);
      durationTo = Math.max(durationTo > 0 ? durationTo : skuDuration, skuDuration);
    });

    return {
      durationFrom,
      durationTo,
      priceFrom,
      priceTo,
    };
  },

  addPackageSkuInformation: function addPackageSkuInformation(params) {
    const data = params;
    const { durationFrom, durationTo, priceFrom, priceTo } = this.getSkuPriceAndDurationRange(
      this.model.get('skus'),
    );

    if (priceFrom === priceTo) {
      data.price = Wahanda.Currency.getFormattedOrEmpty(priceFrom);
    } else {
      data.price = `${Wahanda.Currency.getFormattedOrEmpty(
        priceFrom,
      )} - ${Wahanda.Currency.getFormattedOrEmpty(priceTo)}`;
    }

    if (durationFrom > 0) {
      if (durationFrom === durationTo) {
        data.customInfo = Wahanda.Time.getSkuDurationText(durationFrom);
      } else {
        data.customInfo = `${Wahanda.Time.getSkuDurationText(
          durationFrom,
        )} - ${Wahanda.Time.getSkuDurationText(durationTo)}`;
      }
    }

    return data;
  },

  addSkuInformation: function addSkuInformation(params) {
    const data = params;
    const skus = [];
    const skuList = this.model.get('skus') || [];
    const offerAdditionalTime = this.model.getAdditionalTimeMins();
    const pricingType = App.Models.MenuOffer.getPricingType(skuList);

    const employeeCategories = this.options.employeeCategories.toJSON();
    const employees = getEmployees(this.model.get('employees'), this.options.employees);

    for (let i = 0, len = skuList.length; i < len; i++) {
      const item = skuList[i];

      let title = '';
      const invalid = !doesUseExistingCategory([item], employees, employeeCategories);

      switch (pricingType) {
        case App.Models.MenuOffer.PRICING_TYPE_2D: {
          const employeeCategory = this.options.employeeCategories.find(function f(cat) {
            return cat.id === item.employeeCategoryId;
          });
          const employeeCategoryName = employeeCategory ? employeeCategory.get('name') : 'null';

          const placeholders = {
            service: item.name,
            employeeCategory: employeeCategoryName,
          };

          title = Wahanda.Template.render(
            Wahanda.lang.menu.offer.sku.twoDimensionalPricing,
            placeholders,
          );
          break;
        }
        case App.Models.MenuOffer.PRICING_TYPE_CUSTOM: {
          title = item.name;
          break;
        }
        default: {
          title =
            (item.nameInherited && item.name ? `${Wahanda.lang.menu.offer.sku.groupPrefix} ` : '') +
            (item.name || '');
        }
      }

      const sku = {
        id: item.id,
        title: title,
        info: [],
        skuUnpurchasable: !item.fullPriceAmount,
        customInfo: getSkuDuration(item),
        fullPrice: Wahanda.Currency.getFormatted(item.fullPriceAmount),
        discountPrice: Wahanda.Currency.getFormattedOrEmpty(item.discountPriceAmount),
        invalid,
      };

      skus.push(sku);
    }

    if (skus.length === 1) {
      // If only one sku, merge it's data into the main row
      _.extend(data, {
        customInfo: skus[0].customInfo,
        fullPrice: skus[0].fullPrice,
        discountPrice: skus[0].discountPrice,
        stock: skus[0].stock,
      });
      data.info = data.info.concat(skus[0].info);
    } else if (skus.length > 1) {
      data.SKUs = skus;
    }

    function getSkuDuration(sku) {
      const timings = [sku.duration, sku.finishingTimeMins, offerAdditionalTime];

      const timingsSum = timings.filter(Number).reduce((sum, timing) => sum + timing, 0);

      return sku.duration > 0 ? Wahanda.Time.getSkuDurationText(timingsSum) : null;
    }

    return data;
  },

  editOffer: function editOffer() {
    trackEvent('services', 'click', 'menu-offer-dialog-open');

    this.showOfferEditForm(this.model);
  },

  bindTooltips: function bindTooltips() {
    const opts = {
      position: {
        viewport: true,
        my: 'right center',
        at: 'left top',
      },
    };
    this.$el.tooltipize(opts);
  },
});
