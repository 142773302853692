/* eslint no-process-env: 0 */
import App from 'common/backbone-app';
import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import immutable from 'redux-immutable-state-invariant';
import analyticsMiddleware from './middlewares/analyticsMiddleware';
import websocketsMiddleware from './middlewares/websocketsMiddleware';
import { legacyEventMiddleware } from './middlewares/legacyEventMiddleware';
import { backboneToReact } from './backboneToReact';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

import { rootSaga } from './rootSaga';
import { appReducer } from '../reducers';

const logger = createLogger({
  level: {
    prevState: false,
    nextState: false,
    action: 'log',
  },
});
const sagaMiddleware = createSagaMiddleware();
let store;
const storeBuilder = () => {
  if (store) {
    return store;
  }
  const middleware = App.isProd
    ? [sagaMiddleware, analyticsMiddleware, websocketsMiddleware, legacyEventMiddleware]
    : [
        sagaMiddleware,
        analyticsMiddleware,
        websocketsMiddleware,
        legacyEventMiddleware,
        immutable(),
        logger,
      ];
  store = createStore(
    appReducer,
    {},
    App.isProd
      ? compose(applyMiddleware(...middleware))
      : composeWithDevTools(applyMiddleware(...middleware)),
  );
  sagaMiddleware.run(rootSaga);
  backboneToReact(store.dispatch);
  return store;
};

export default storeBuilder;
