import React from 'react';
import { Button } from 'components/common/Button';
import styles from './../OnboardingWidget.scss';
import { useGoLive } from 'components/shared/OnboardingWidget/hooks/useGoLive';
import { getTrackingByEventName } from 'src/tracking-container/TrackingEvents';

const trackEvent = getTrackingByEventName('self_onboarding_get_online');

export const GetOnline = () => {
  const [goLive] = useGoLive();

  const handleOnClick = () => {
    trackEvent();
    goLive();
  };

  return (
    <>
      <p className={styles.description}>{Wahanda.lang.onboardingWidget.getOnline.description}</p>
      <div className={styles.button}>
        <Button
          colour="default"
          label={Wahanda.lang.onboardingWidget.getOnline.button.label}
          onClick={handleOnClick}
          type="button"
          variant="primary"
          fullWidth
        />
      </div>
    </>
  );
};
