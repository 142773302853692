import React from 'react';
import classnames from 'classnames';
import Edit from 'components/common/icons/Edit';
import style from './TemplateField.scss';

interface ITemplateFieldProps extends React.HTMLAttributes<Element> {
  isEditable: boolean;
  config: {
    required?: boolean;
    type?: 'text' | 'textarea';
    minLength?: number;
    maxLength?: number;
  };
  name: string;
  value: string;
  defaultValue: string;
  editing?: string;
  onClick: (...args: any[]) => any;
  onBlur: (...args: any[]) => any;
}
type TemplateFieldState = {
  value?: any;
  trim?: any;
};
class TemplateField extends React.Component<ITemplateFieldProps, TemplateFieldState> {
  inputRef: string;

  static defaultProps = {
    editing: null,
  };

  constructor(props) {
    super(props);
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message
    this.inputRef = React.createRef();
    this.autoResize = this.autoResize.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.state = {
      value: props.value,
    };
  }

  componentDidUpdate() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'current' does not exist on type 'string'... Remove this comment to see the full error message
    if (this.props.editing === this.props.name && this.inputRef.current) {
      this.autoResize();
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'current' does not exist on type 'string'... Remove this comment to see the full error message
      this.inputRef.current.focus();
    }
  }

  onKeyDown(event) {
    const { type } = this.props.config;
    if (type !== 'textarea' && event.keyCode === 13) {
      const { value } = event.target;
      this.setState({ value }, this.onBlur);
    }
  }

  onChange(event) {
    const { value } = event.target;
    this.setState({ value });
  }

  onClick() {
    this.props.onClick(this.props.name);
  }

  onBlur() {
    const value = this.state.value && this.state.value.trim();
    this.props.onBlur(this.props.name, value || this.props.defaultValue);
  }

  getField() {
    const { config, name, value, isEditable } = this.props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, ...attrs } = config;
    const fieldProps = {
      ...attrs,
      name,
      defaultValue: value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onKeyDown: this.onKeyDown,
      onKeyUp: this.autoResize,
      className: style.textarea,
      rows: 1,
      ref: this.inputRef,
    };
    const classes = classnames({
      [style.highlightEditable]: isEditable,
      [style.active]: this.isActive(),
    });
    return (
      <div className={classes}>
        <textarea {...fieldProps} />
        {isEditable && (
          <div className={style.editIcon}>
            <Edit />
          </div>
        )}
      </div>
    );
  }

  getContent() {
    const { value, config, isEditable } = this.props;
    const { type } = config;
    const classes = classnames({
      [style.highlightEditable]: isEditable,
      [style.active]: this.isActive(),
    });
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div className={classes} onClick={this.onClick}>
        {value && type === 'textarea' ? (
          <div
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: value.replace(/(?:\r\n|\r|\n)/g, '<br />'),
            }}
          />
        ) : (
          <div>{value}</div>
        )}
        {isEditable && (
          <div className={style.editIcon}>
            <Edit />
          </div>
        )}
      </div>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }

  autoResize() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'current' does not exist on type 'string'... Remove this comment to see the full error message
    this.inputRef.current.style.height = '1px';
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'current' does not exist on type 'string'... Remove this comment to see the full error message
    this.inputRef.current.style.height = `${this.inputRef.current.scrollHeight}px`;
  }

  isActive() {
    const { editing, name } = this.props;
    return editing === name;
  }

  render() {
    const { isEditable } = this.props;
    if (!isEditable) {
      return this.getContent();
    }
    return this.isActive() ? this.getField() : this.getContent();
  }
}

export default TemplateField;
