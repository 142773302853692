import React from 'react';
import { FingerpostAction } from './FingerpostAction';

export type FingerpostActionProps = FingerpostAction['props'] & {
  isHidden?: boolean;
};

interface Props {
  actions: FingerpostActionProps[];
}

export class Fingerpost extends React.PureComponent<Props> {
  public render() {
    const { actions } = this.props;
    return actions.map((action) => {
      const { isHidden, ...rest } = action;
      if (isHidden) {
        return null;
      }
      return <FingerpostAction key={rest.label} {...rest} />;
    });
  }
}
