import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from 'common/backbone-app';
import { requestRettentionReportDataAction } from 'components/reports/newSalesReports/actions';
import {
  firstTimeClientsSelector,
  clientRetentionSelector,
} from 'components/reports/newSalesReports/selectors';
import { topEmployeesSelector, topServicesSelector, channelSalesSelector } from './selectors';
import { requestSalesSummaryReportAction, requestMonthlySalesReportAction } from './actions';
import VenueStats from './index';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      requestSalesSummaryReportAction,
      requestMonthlySalesReportAction,
      requestRettentionReportDataAction,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => {
  const { venueStats, dashboard } = state;
  return {
    canViewMonthOverViewData: App.isAdministrator(),
    grossTotal: venueStats.total.grossTotal,
    salesByMonth: venueStats.rows,
    totalNumberOfBookings: venueStats.totalNumberOfServiceBookings,
    totalSales: venueStats.totalSales,
    acquiredClients: firstTimeClientsSelector(state),
    clientRetention: clientRetentionSelector(state),
    services: topServicesSelector(state, { max: 3 }),
    employees: topEmployeesSelector(state, { max: 3 }),
    channelSales: channelSalesSelector(state),
    venueId: dashboard.venueId,
    widgetChannelSales: venueStats.widgetChannelSales,
    treatwellChannelSales: venueStats.treatwellChannelSales,
    directChannelSales: venueStats.directChannelSales,
  };
};

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ acquiredClients: null; clientRetention: nu... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(VenueStats);
