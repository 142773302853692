import React from 'react';
import Wahanda from 'common/wahanda';
import { trackEvent } from 'common/analytics';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';

import Loader from 'components/common/Loader';
import { Button } from 'components/common/Button';
import Dialog from 'components/menu/DiscountRuleDialog/container';
import EmailCampaignDialog from 'components/menu/DiscountsTab/EmailCampaignDialog/container';
import {
  MAX_LAST_MINUTE_DISCOUNT,
  MAX_MINS_BEFORE_APPOINTMENT,
  NEW_DISCOUNT_URL_FRAG,
} from './constants';
import DiscountList from './DiscountList';
import EmptyState from './EmptyState';
import style from './style.scss';

const lang = Wahanda.lang.menu.discounts;

function hasEditPermission() {
  // Checking for edit-menu directly as we want this to work for Benelux locked down venues too.
  return 'edit-menu' in Wahanda.Permissions.list;
}

interface IDiscountsTabProps extends React.HTMLAttributes<Element> {
  venueId: string;
  actions: {
    initialStatePopulation: (...args: any[]) => any;
    requestDiscountRuleAction: (...args: any[]) => any;
    requestVenueDetailsAction: (...args: any[]) => any;
    hideEmailCampaignDialog: (...args: any[]) => any;
  };
  hasMenuDiscount: boolean;
  hasVenueDetails: boolean;
  hasSaved: boolean;
  hasError: boolean;
  hasDeleteConfirmDialog: boolean;
  venueDetails?: {};
  discountRules?: {
    name: string;
    active: boolean;
    lastMinuteDiscount?: {
      active: boolean;
      maxMinsBeforeAppointment: number;
      maxLastMinuteDiscount: number;
      leadTimeMinutes: number;
    };
    offpeakDiscounts?: {
      active: boolean;
    };
    assignedMenuItems?: {
      id: number;
      name?: string;
      active?: boolean;
      purchasable?: boolean;
      servicePackage?: boolean;
    }[];
  }[];
  hideEmailCampaignDialog?: any;
  requestVenueDetailsAction?: any;
  requestDiscountRuleAction?: any;
  initialStatePopulation?: any;
}
type DiscountsTabState = {
  hasDiscountRuleDialog?: boolean;
  hasDialog?: boolean;
};
class DiscountsTab extends React.Component<IDiscountsTabProps, DiscountsTabState> {
  state = {
    hasDiscountRuleDialog: window.location.hash.indexOf(NEW_DISCOUNT_URL_FRAG) !== -1,
  };

  componentDidMount = () => {
    NavigationAnalytics.trackPageEventView(L2Page.DISCOUNTS);
    this.props.actions.initialStatePopulation({ venueId: this.props.venueId });
    this.props.actions.requestDiscountRuleAction(this.props.venueId);
    this.props.actions.requestVenueDetailsAction(this.props.venueId);
    this.props.actions.hideEmailCampaignDialog();
  };

  handleClick = () => {
    trackEvent('discounts', 'click', 'add-new-discount');
    this.setState({
      hasDiscountRuleDialog: true,
    });
  };

  handleClose = () => {
    const {
      venueId,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'revertDiscountRuleAction' does not exist... Remove this comment to see the full error message
      actions: { revertDiscountRuleAction },
    } = this.props;
    this.setState({
      hasDiscountRuleDialog: false,
    });
    // This is nasty, horrible, bad code.
    // TODO: When the react sidebar is introduced, remove this.
    // Story number : DEV-52894
    window.location.assign(Wahanda.Url.getFullUrl('menu', 'discounts'));
    revertDiscountRuleAction(venueId);
  };

  hasMenuDiscount() {
    return !!this.props.hasMenuDiscount;
  }

  hasVenueDetails() {
    return !!this.props.hasVenueDetails;
  }

  hasDiscountRuleDialog() {
    return !!this.state.hasDiscountRuleDialog;
  }

  hasDiscountRules() {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return !!this.props.discountRules.length;
  }

  renderLoader() {
    // eslint-disable-line class-methods-use-this
    return <Loader positionAbsolute />;
  }

  renderDialog() {
    const {
      venueId,
      actions,
      venueDetails: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'dayPercentages' does not exist on type '... Remove this comment to see the full error message
        dayPercentages: days,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'appointmentMorningEndTime' does not exis... Remove this comment to see the full error message
        appointmentMorningEndTime,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'appointmentAfternoonEndTime' does not ex... Remove this comment to see the full error message
        appointmentAfternoonEndTime,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'leadTimeMinutes' does not exist on type ... Remove this comment to see the full error message
        leadTimeMinutes,
      },
      hasSaved,
      hasError,
      hasDeleteConfirmDialog,
    } = this.props;
    const lastMinuteDiscount = {
      active: false,
      maxHoursBeforeAppointment: MAX_MINS_BEFORE_APPOINTMENT / 60,
      maxLastMinuteDiscount: MAX_LAST_MINUTE_DISCOUNT,
      leadTimeMinutes,
    };
    const offpeakDiscounts = {
      active: false,
      days,
      appointmentMorningEndTime,
      appointmentAfternoonEndTime,
    };
    const hasEdit = hasEditPermission();
    return (
      <Dialog
        active
        venueId={venueId}
        // @ts-expect-error ts-migrate(2740) FIXME: Type '{ initialStatePopulation: (...args: any[]) =... Remove this comment to see the full error message
        actions={actions}
        lastMinuteDiscount={lastMinuteDiscount}
        offpeakDiscounts={offpeakDiscounts}
        assignedMenuItems={[]}
        onClose={this.handleClose}
        hasSaved={hasSaved}
        hasError={hasError}
        hasEditPermission={hasEdit}
        hasDeleteConfirmDialog={hasDeleteConfirmDialog}
        isAssignedTreatmentAvailable
      />
    );
  }

  renderEmailCampaignDialog() {
    const onWrapperClick = (e) => e.stopPropagation();
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div onClick={onWrapperClick}>
        <EmailCampaignDialog />
      </div>
    );
  }

  renderDiscountRules() {
    const {
      discountRules,
      venueId,
      actions,
      hasSaved,
      hasError,
      hasDeleteConfirmDialog,
    } = this.props;
    const hasEdit = hasEditPermission();
    let button;
    const { hasDiscountRuleDialog: hasDialog } = this.state;
    let discountRuleDialog;
    if (hasEdit) {
      button = <Button onClick={this.handleClick} label={lang.newRule} />;
    }
    if (hasDialog) {
      discountRuleDialog = this.renderDialog();
    }
    return (
      <section className={style.discountsTab}>
        <section className={style.heading}>
          <h1 className={style.title}>{lang.title}</h1>

          {button}
        </section>

        {discountRuleDialog}
        {this.renderEmailCampaignDialog()}

        <DiscountList
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type '{ id: ... Remove this comment to see the full error message
          discountList={discountRules}
          venueId={venueId}
          // @ts-expect-error ts-migrate(2739) FIXME: Type '{ initialStatePopulation: (...args: any[]) =... Remove this comment to see the full error message
          actions={actions}
          hasSaved={hasSaved}
          hasError={hasError}
          hasEditPermission={hasEdit}
          hasDeleteConfirmDialog={hasDeleteConfirmDialog}
        />
      </section>
    );
  }

  renderEmptyState() {
    return <EmptyState onAddRuleClick={this.handleClick} />;
  }

  render() {
    /**
     *  Has all the data returned from the server?
     */
    if (this.hasMenuDiscount() && this.hasVenueDetails()) {
      /**
       *  Does the venue have any discount rules in that data?
       */
      if (this.hasDiscountRules()) {
        return this.renderDiscountRules();
      }
      // eslint-disable-line
      /**
       *  Has the user clicked the 'add rules' button in the empty state?
       */
      if (this.hasDiscountRuleDialog()) {
        // eslint-disable-line no-lonely-if
        /**
         *  Present the dialog
         */
        return this.renderDialog();
      }
      // eslint-disable-line
      /**
       *  Present the empty state
       */
      return this.renderEmptyState();
    }
    /*
     *  No data. Show loader
     */
    return this.renderLoader();
  }
}
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
DiscountsTab.defaultProps = {
  hasSaved: false,
  hasError: false,
  hasDeleteConfirmDialog: false,
  discountRules: [],
  venueDetails: {},
};

export default DiscountsTab;
