import { call, put } from 'redux-saga/effects';
import apiUrl from 'common/api-url';
import * as api from 'reduxStore/apiService';

import { MenuGroupsPayload } from './types';

import {
  getMenuGroupTemplateSuccess,
  getMenuGroupTemplateFailure,
  setMenuGroupServices,
  setMenuGroupServicesSuccess,
  setMenuGroupServicesFailure,
} from './actions';

export function* requestMenuGroupTemplateRequested() {
  try {
    const payload: MenuGroupsPayload = yield call(api.get, apiUrl('MENU_GROUP_TEMPLATE'));

    yield put(getMenuGroupTemplateSuccess(payload.menuGroups));
  } catch (err) {
    yield put(getMenuGroupTemplateFailure());
  }
}

export function* setMenuServicesRequested(action: ReturnType<typeof setMenuGroupServices>) {
  try {
    const { menuGroups, targetMenuGroupId } = action.payload;
    yield call(api.post, apiUrl('CREATE_SERVICES_FROM_TEMPLATE'), {
      menuGroups,
      targetMenuGroupId,
    });

    yield put(setMenuGroupServicesSuccess());
  } catch (err) {
    yield put(setMenuGroupServicesFailure());
  }
}
