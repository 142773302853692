import { createAction } from 'reduxStore/actionCreator';
import {
  CLIENTS_IMPORT_SELECT_FILE,
  CLIENTS_IMPORT_RESET,
  CLIENTS_FILE_UPLOAD_REQUEST,
  CLIENTS_FILE_UPLOAD_SUCCESS,
  CLIENTS_FILE_UPLOAD_FAILURE,
  CLIENTS_FILE_IMPORT_FAILURE,
  CLIENTS_FILE_IMPORT_UPDATE,
  CLIENTS_FILE_IMPORT_SUCCESS,
  CLIENTS_IMPORT_TO_CAMPAIGN_PROMPT,
} from 'reduxStore/actionsConst';

import { dataURLtoFile } from './helpers';
import { ProgressResponse, SelectedFile, ClientsFileUploadResponse } from './types';

export const clientsImportSelectFile = (payload: SelectedFile) =>
  createAction(CLIENTS_IMPORT_SELECT_FILE, payload);

export type ClientsImportSelectFileAction = ReturnType<typeof clientsImportSelectFile>;

export const clientsImportReset = () => createAction(CLIENTS_IMPORT_RESET);

export type clientsImportResetAction = ReturnType<typeof clientsImportReset>;

export const clientsFileUploadRequest = (payload: SelectedFile) => {
  const file = payload.file;
  const fileName = payload.fileName;
  const formData = new FormData();
  const fileToSubmit = dataURLtoFile(file, fileName);

  formData.append('filename', fileToSubmit.name);
  formData.append('file', fileToSubmit);

  return createAction(CLIENTS_FILE_UPLOAD_REQUEST, { formData });
};

export type clientsFileUploadRequestAction = ReturnType<typeof clientsFileUploadRequest>;

export const clientsFileUploadSuccess = (payload: ClientsFileUploadResponse) =>
  createAction(CLIENTS_FILE_UPLOAD_SUCCESS, payload);

export type clientsFileUploadSuccessAction = ReturnType<typeof clientsFileUploadSuccess>;

export const clientsFileUploadFailure = () => createAction(CLIENTS_FILE_UPLOAD_FAILURE);

export type clientsFileUploadFailureAction = ReturnType<typeof clientsFileUploadFailure>;

const payloadToImportData = (payload: Partial<ProgressResponse>): Partial<ProgressResponse> => {
  return {
    percentage: payload.percentage,
    messages: payload.messages,
    importCount: payload.importCount,
    status: payload.status,
  };
};

export const clientsFileImportSuccess = (payload: Partial<ProgressResponse>) => {
  return createAction(CLIENTS_FILE_IMPORT_SUCCESS, payloadToImportData(payload));
};

export const clientsFileImportFailure = (payload?: Partial<ProgressResponse>) => {
  if (payload) {
    return createAction(CLIENTS_FILE_IMPORT_FAILURE, payloadToImportData(payload));
  }

  return createAction(CLIENTS_FILE_IMPORT_FAILURE);
};

export const clientsFileImportUpdate = (payload: Partial<ProgressResponse>) => {
  return createAction(CLIENTS_FILE_IMPORT_UPDATE, payloadToImportData(payload));
};

export const clientsImportToCampaignPrompt = () => createAction(CLIENTS_IMPORT_TO_CAMPAIGN_PROMPT);

export type clientsImportToCampaignPromptAction = ReturnType<typeof clientsImportToCampaignPrompt>;

export type clientsFileImportSuccessAction = ReturnType<typeof clientsFileImportSuccess>;

export type clientsFileImportFailureAction = ReturnType<typeof clientsFileImportFailure>;

export type ClientsFileImportUpdateAction = ReturnType<typeof clientsFileImportUpdate>;
