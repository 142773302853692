import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { receiveProductsListAction, requestProductsListFailureAction } from './actions';

function get(url) {
  return api.get(url).then((data: any) =>
    data.map((item) => ({
      id: item.id,
      name: item.name,
      inventory: item.inventory,
      productSupplier: item.productSupplier
        ? { id: item.productSupplier.id, name: item.productSupplier.name }
        : null,
    })),
  );
}

export default function* getProductsListRequested() {
  try {
    const data = yield call(get, apiUrl('VENUE_PRODUCTS'));
    yield put(receiveProductsListAction(data));
  } catch (err) {
    yield put(requestProductsListFailureAction(err));
  }
}
