/* global BackboneEx */

App.Views.Calendar.NoPermission = BackboneEx.View.Dialog.extend(
  {
    events: {
      'click .button-cancel': 'close',
    },

    templateId: 'no-permission-dialog',

    options: {
      width: 416,
      title: Wahanda.lang.calendar.noPermission.title,
      dataTest: 'no-permissions-modal',
    },

    setupDialog() {
      this.$('.login-link').attr('href', App.loginRedirectUrl());
    },
  },
  {
    open() {
      const dialog = new App.Views.Calendar.NoPermission();
      dialog.render();
      dialog.open();
    },
  },
);
