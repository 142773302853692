import urlTemplate from 'url-template';
import App from 'common/backbone-app';

export const REPORTS_ROUTES = {
  MONTHLY: 'reports#venue/{venueId}/salesReports/monthlySales',
  CHANNEL_SALES: 'reports#venue/{venueId}/salesReports/channelSales',
  SALES_REPORTS: 'reports#venue/{venueId}/salesReports/serviceSales',
  EMPLOYEE_SALES: 'reports#venue/{venueId}/salesReports/employeeSales',
};

export const routeFinder = (routeString, params = {}) => {
  const urlParams = {
    ...params,
    venueId: App.getVenueId(),
  };
  return urlTemplate.parse(routeString).expand(urlParams);
};
