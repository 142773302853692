import Wahanda from 'common/wahanda';

export const validations = ({ errors }) => {
  const requiredDefaultValidationObject = {
    required: Wahanda.lang.validate.defaults.required,
  };
  // TODO: yep, time to move these to proper utils file
  const emailPattern = /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
  const emailValidationObject = {
    pattern: {
      value: emailPattern,
      message: Wahanda.lang.validate.defaults.email,
    },
  };

  const emailValidationRules = {
    ...requiredDefaultValidationObject,
    ...emailValidationObject,
  };

  return {
    user: {
      name: 'user',
      type: 'email',
      dataTest: 'login-form-user-input',
      label: Wahanda.lang.login.emailPlaceholder,
      rules: emailValidationRules,
      hasError: !!errors.user,
      errorMessage: errors.user?.message,
      fullBorder: true,
      autoFocus: true,
    },
    password: {
      name: 'password',
      type: 'password',
      dataTest: 'login-form-password-input',
      label: Wahanda.lang.login.passwordPlaceholder,
      rules: requiredDefaultValidationObject,
      hasError: !!errors.password,
      errorMessage: errors.password?.message,
      fullBorder: true,
    },
  };
};
