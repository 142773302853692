/**
 * Employee category.
 */
App.Models.EmployeePasswordReset = BackboneEx.Model.Silent.extend({
  defaults: {
    venueId: null,
  },

  initialize: function () {},

  sync: function (method, model, options) {
    // Overriding the method, so that saving data would POST, not PUT
    if ('update' === method) {
      options.type = 'POST';
    }
    return (BackboneEx.Model.Silent.prototype.sync || Backbone.sync).call(
      this,
      method,
      model,
      options,
    );
  },

  url: function () {
    var id = this.get('id');
    var base;
    base = '/venue/{{venueId}}/employee/{{id}}/password-reset';
    return App.Api.wsUrl(Wahanda.Template.render(base, { id: id, venueId: this.get('venueId') }));
  },
});
