/* global BackboneEx */
import { trackEvent } from 'common/analytics';

(() => {
  const Lang = Wahanda.lang.customer.massEmailConfirmation;

  App.Views.Forms.Customer.MassEmailTargetAudienceConfirmation = BackboneEx.View.Dialog.extend({
    events: {
      'click .a-close': 'cancel',
      'click .a-send': 'send',
    },

    options: {
      title: Lang.title,
      width: 500,
      dataTest: 'mass-email-target-audience-confirmation-modal',
    },

    templateId: 'customer-mass-email-target-audience-template',

    setupElementFromTemplate() {},

    getDialogTemplateVariables() {
      this.venueMassEmailMinIntervalDays = App.config.get('venue').venueMassEmailMinIntervalDays;
      return {
        count: this.model.get('count'),
        hasRecipients: this.model.get('count') > 0,
        hasMinInterval:
          this.venueMassEmailMinIntervalDays && this.venueMassEmailMinIntervalDays > 0,
        minInterval:
          this.venueMassEmailMinIntervalDays === 1
            ? Wahanda.lang.datetime.distance_in_words.x_days.one
            : Wahanda.lang.datetime.distance_in_words.x_days.other.replace(
                '{{count}}',
                this.venueMassEmailMinIntervalDays,
              ),
      };
    },

    prepareDialog(callback) {
      this.model.fetch({
        success: callback,
      });
    },

    render() {
      BackboneEx.View.Dialog.prototype.setupElementFromTemplate.call(this);
      BackboneEx.View.Dialog.prototype.render.call(this);
      if (!this.model.get('count')) {
        this.$('.a-send').disableFormElements();
      }
    },

    cancel() {
      trackEvent('clients-list', 'click', 'cancel', 'compose-email-form');

      this.close();
      this.options.onCancel();
    },

    send() {
      trackEvent('clients-list', 'submit', 'compose-email-form');

      this.disableForm();
      this.$('.a-send').action('doing');
      this.options.onAccept();
    },
  });
})();
