import { cacheForRange, getRangeFromCache } from './cache';
import setupWebSockets from './websockets';
import invalidateCacheByDate from './cache/invalidate-by-dates';
import fetchForRange from './fetch';

export default function createCalendarCache() {
  let cache = null;
  let useCache = false;

  setupWebSockets((onStateChange, onInvalidateDates) => {
    onStateChange((canCache) => {
      useCache = canCache;
    });

    onInvalidateDates((invalidateParam) => {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{}' is not assignable to type 'null'.
      cache = invalidateCacheByDate(cache, invalidateParam);
    });
  });

  return {
    async getForRange({ dateFrom, dateTo, utmSource, venueId }) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{}' is not assignable to type 'null'.
      cache = await cacheForRange(cache, dateFrom, dateTo, (from, to) =>
        fetchForRange({ dateFrom: from, dateTo: to, venueId, utmSource }),
      );
      const result = getRangeFromCache(cache, dateFrom, dateTo);

      if (!useCache) {
        // Don't keep the cache between separate calls
        cache = null;
      }

      return result;
    },

    invalidate() {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{}' is not assignable to type 'null'.
      cache = {};
    },
  };
}
