import React from 'react';
import {
  toJs,
  map,
  get,
  hash,
  reverse,
  subvec,
  some,
  count,
  equals,
  curry,
  filter,
  into,
  vector,
} from 'mori';
import keycode from 'keycode';
import classnames from 'classnames';
import SelectDropdownItem from './SelectDropdownItem';
import { readMousePosition, isEqualMousePosition } from './mouse-position';
// Converts a maybe lazy sequence into a vector
const intoVector = (sequable) => into(vector(), sequable);

function filterShownItems(data) {
  return intoVector(filter((item) => !get(item, 'hide'), data));
}

function renderItems(items, selected, moveHandler, clickHandler) {
  return map(
    (item) => (
      <SelectDropdownItem
        key={hash(item)}
        item={item}
        onMouseMove={moveHandler}
        onSelect={clickHandler}
        selectable={!get(item, 'grouping')}
        indented={!!get(item, 'groupingValue')}
        active={equals(get(selected, 'value'), get(item, 'value'))}
      />
    ),
    items,
  );
}

function getScrollToNode(scrollIn, scrollWhat) {
  if (!scrollWhat) {
    return 0;
  }
  const topRect = scrollIn.getBoundingClientRect();
  const bottomRect = scrollWhat.getBoundingClientRect();
  const targetPosition = topRect.top + topRect.height / 2 - bottomRect.height;
  return scrollIn.scrollTop + bottomRect.top - targetPosition;
}

function setupDOMEventListeners({ handleOutsideClick, handleKeyboardAction }) {
  document.body.addEventListener('click', handleOutsideClick);
  document.body.addEventListener('keyup', handleKeyboardAction);
}

function removeDOMEventListeners({ handleOutsideClick, handleKeyboardAction }) {
  document.body.removeEventListener('click', handleOutsideClick);
  document.body.removeEventListener('keyup', handleKeyboardAction);
}

function getDropdownPositionAndHeight(node, parentRect, isTypeahead) {
  const winHeight = window.innerHeight;
  const height = (() => {
    if (isTypeahead) {
      return 'auto';
    }
    const setHeight = parseInt(node.style.height, 10);
    if (setHeight > 0) {
      return setHeight;
    }
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    return Math.min(parseInt(winHeight * 0.95, 10), node.clientHeight);
  })();
  const top = (() => {
    if (isTypeahead) {
      return parentRect.bottom;
    }
    // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    const topAlignedToParent = parentRect.top - height / 2;
    if (topAlignedToParent < 0) {
      return 10;
    }
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '+' cannot be applied to types 'number' a... Remove this comment to see the full error message
    if (topAlignedToParent + height > winHeight) {
      // @ts-expect-error ts-migrate(2363) FIXME: The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message
      return (winHeight - height) / 2;
    }
    return topAlignedToParent;
  })();
  const maxHeight = (() => {
    if (isTypeahead) {
      return Math.max(
        0,
        // For typeahead, we want 50% of the screen max.
        // But do not exceed the bottom bounds!
        Math.min(winHeight * 0.5, winHeight - parentRect.bottom - 15),
      );
    }
    return '95%';
  })();
  // TODO: maybe a screen-wide invisible DIV preventing scroll of bg elements?
  return {
    maxHeight,
    height,
    width: parentRect.width,
    left: parentRect.left,
    top,
  };
}

const getValue = curry(get, 'value');

function indexOfByValue(vec, search) {
  const length = count(vec);
  let key;
  for (key = 0; key < length; key += 1) {
    const vecValue = get(vec, key);
    if (getValue(search) === getValue(vecValue)) {
      return key;
    }
  }
  return -1;
}

interface IDropdownProps extends React.HTMLAttributes<Element> {
  selected?: any;
  data?: {};
  onSelect?: (...args: any[]) => any;
  open?: boolean;
  parentBoundingRect?: {};
  isTypeahead?: boolean;
  typeaheadInput?: {
    contains: (...args: any[]) => any;
    blur: (...args: any[]) => any;
  };
  optionExtraClassName?: string;
  blur?: any;
}
type DropdownState = {
  positionToSelected?: boolean;
  marked?: any;
};

export default class Dropdown extends React.Component<IDropdownProps, DropdownState> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'dropdown' has no initializer and is not ... Remove this comment to see the full error message
  dropdown: HTMLDivElement;

  eventHandlers: any;

  mousePositionCache: { x: any; y: any };

  constructor(props, p2, p3) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 3.
    super(props, p2, p3);
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '{ x: any; y... Remove this comment to see the full error message
    this.mousePositionCache = null;
    this.state = {
      positionToSelected: true,
    };
    /*
     *  Handlers for DOM events, like external click, etc
     */
    this.eventHandlers = {
      handleOutsideClick: ({ target }) => {
        if (target === this.dropdown || this.dropdown.contains(target)) {
          /*
           *  The user clicked inside of the Dropdown. No action needs to be taken
           */
          return;
        }
        const { isTypeahead } = this.props;
        /*
         *  We do not want to close the dropdown if we are using a typeahead dropdown
         *  and the input has been given focus
         */
        if (isTypeahead) {
          const { typeaheadInput } = this.props;
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          if (target === typeaheadInput || typeaheadInput.contains(target)) {
            return;
          }
        }
        /*
         *  Click outside - close dropdown!
         */
        this.requestClose();
      },
      handleKeyboardAction: (event) => {
        const keyPressed = keycode(event);
        if (keyPressed === 'esc' || keyPressed === 'tab') {
          this.requestClose();
        } else if (keyPressed === 'up') {
          this.moveChoiceUp();
        } else if (keyPressed === 'down') {
          this.moveChoiceDown();
        } else if (keyPressed === 'enter') {
          /*
           *  Prevent default so that the main Dropdown component won't re-open
           *  the dropdown again
           */
          event.preventDefault();
          this.onChooseMarked();
        }
      },
    };
  }

  UNSAFE_componentWillReceiveProps({ open, selected }) {
    if (this.props.open === true && open === false) {
      /*
       *  Reset the kayboard-nagivated "marked" element so that opening
       *  the dropdown next time will highlite the selected element.
       */
      this.setState({
        marked: null,
      });
    }
    if (selected && open && !equals(selected, this.props.selected)) {
      /*
       *  A new "selected" value is passed in. Set it as the marked value.
       *  Probably from FuzzySearchInput.
       */
      this.setState({
        marked: selected,
      });
    }
    /*
     *  DOM event binding
     */
    if (this.props.open !== open) {
      if (open) {
        setupDOMEventListeners(this.eventHandlers);
      } else {
        removeDOMEventListeners(this.eventHandlers);
      }
    }
  }

  componentDidUpdate() {
    if (this.props.open && this.state.positionToSelected) {
      // Scroll the opened dropdown to the active item
      const selectedNode = this.dropdown.querySelector('.active');
      const newScroll = getScrollToNode(this.dropdown, selectedNode);
      this.dropdown.scrollTop = newScroll;
    } else if (!this.props.open && this.props.typeaheadInput) {
      this.props.typeaheadInput.blur();
    }
  }

  componentWillUnmount() {
    removeDOMEventListeners(this.eventHandlers);
  }

  onChooseMarked() {
    const choice = this.state.marked;
    if (choice) {
      this.onChoice(choice);
    } else {
      this.requestClose();
    }
  }

  onChoice = (item) => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.onSelect(item);
    this.setState({
      positionToSelected: true,
    });
  };

  onItemMouseMove = (item, event) => {
    const newPosition = readMousePosition(event);
    if (this.mousePositionCache && isEqualMousePosition(newPosition, this.mousePositionCache)) {
      return;
    }
    this.mousePositionCache = newPosition;
    this.setState({
      marked: item,
      // Don't allow scrolling to the hovered node, as that has bad UX.
      positionToSelected: false,
    });
  };

  updateMarked(rangeFn) {
    const marked = this.state.marked || this.props.selected;
    const shownItems = filterShownItems(this.props.data);
    const selectedIndex = indexOfByValue(shownItems, marked);
    const range = rangeFn(shownItems, selectedIndex);
    const newSelected = some((item) => (!get(item, 'grouping') ? item : false), range);
    if (newSelected) {
      this.setState({
        marked: newSelected,
        positionToSelected: true,
      });
    }
  }

  moveChoiceUp() {
    this.updateMarked((data, index) => reverse(subvec(data, Math.max(0, index - 3), index)));
  }

  moveChoiceDown() {
    this.updateMarked((data, index) => {
      const max = count(data);
      return subvec(data, Math.min(max, index + 1), Math.min(max, index + 3));
    });
  }

  requestClose() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    this.onChoice();
  }

  render() {
    const { open, selected, isTypeahead, optionExtraClassName } = this.props;
    const { marked } = this.state;
    const dropdownClasses = classnames(
      'select-dropdown-ext-dropdown',
      { open },
      optionExtraClassName,
    );
    const styles = open
      ? getDropdownPositionAndHeight(this.dropdown, this.props.parentBoundingRect, isTypeahead)
      : null;
    const data = this.props.data;
    return (
      <div
        className={dropdownClasses}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'CSSProperti... Remove this comment to see the full error message
        style={styles}
        ref={(dropdown) => {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLDivElem... Remove this comment to see the full error message
          this.dropdown = dropdown;
        }}
      >
        {toJs(renderItems(data, marked || selected, this.onItemMouseMove, this.onChoice))}
      </div>
    );
  }
}
