import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import { Initializer } from 'common/types/initializers';
import { CovidBanner } from './container';

const store = storeBuilder();
interface Params {
  node: HTMLElement;
}

export const CovidBannerInitializer = (params: Params): Initializer => ({
  destroy: () => {
    document.getElementsByTagName('body')[0].classList.remove('covid19');
    ReactDOM.unmountComponentAtNode(params.node);
  },
  render: () => {
    document.getElementsByTagName('body')[0].classList.add('covid19');
    ReactDOM.render(
      <Provider store={store}>
        <CovidBanner />
      </Provider>,
      params.node,
    );
  },
});
