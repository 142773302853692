import React from 'react';
import App from 'common/backbone-app';
import classnames from 'classnames';
import style from './style.scss';

interface IUnderlinedInputProps extends React.HTMLAttributes<Element> {
  className?: string;
  inputClassName?: string;
  label?: string;
  hasError?: boolean;
  dataTest?: string;
  rest?: any;
}

export default class UnderlinedInput extends React.Component<IUnderlinedInputProps, {}> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'inputRef' has no initializer and is not ... Remove this comment to see the full error message
  inputRef: HTMLInputElement;

  static defaultProps = {
    className: null,
    inputClassName: null,
    label: null,
    hasError: false,
  };

  getValues() {
    return this.inputRef.value;
  }

  getInputRef() {
    return this.inputRef;
  }

  renderInput = ({ inputClassName, dataTest, hasError, ...rest }) => (
    <input
      {...rest}
      data-test={dataTest}
      ref={(input) => {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLInputEl... Remove this comment to see the full error message
        this.inputRef = input;
      }}
      className={classnames(inputClassName, style.input, {
        [style.error]: hasError,
      })}
    />
  );

  render() {
    const { className, label, ...rest } = this.props;
    if (!App.isProd) {
      console.warn('UnderlinedInput component is deprecated. Use Input component');
    }
    return (
      <div className={classnames(style.wrapper, className)}>
        {label != null ? (
          <label className={style.label}>
            {label}
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'required' does not exist on type '{ inpu... Remove this comment to see the full error message */}
            {rest.required && <span className={style.required}> *</span>}
            {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ inputClassName?: string | unde... Remove this comment to see the full error message */}
            {this.renderInput(rest)}
          </label>
        ) : (
          // @ts-expect-error ts-migrate(2345) FIXME: Property 'inputClassName' is optional in type '{ i... Remove this comment to see the full error message
          this.renderInput(rest)
        )}
      </div>
    );
  }
}
