import React from 'react';
import classnames from 'classnames';
import TickOval from 'components/common/icons/TickOval';

import style from './style.scss';

import { Theme } from '../themes';
import { PartnerSiteTheme } from '../types';

interface Props {
  theme: Theme;
  isSelected?: boolean;
  isDisabled?: boolean;
  actions: {
    selectPartnerSiteTheme: (theme: PartnerSiteTheme) => void;
  };
}

class ThemeCard extends React.PureComponent<Props, {}> {
  public static defaultProps = {
    isSelected: false,
  };

  public render() {
    const { isSelected } = this.props;
    return (
      <div
        className={classnames(style.themeCard, this.selectedCardClass(), this.disabledCardClass())}
        onClick={this.onCardClick}
      >
        <div className={classnames(style.image, this.getImageClass())} />
        <div className={style.label}>
          <span className={style.themeName}>{this.getLabel()}</span>
          {isSelected && <TickOval />}
        </div>
      </div>
    );
  }

  private selectedCardClass = () => this.props.isSelected && style.selected;

  private disabledCardClass = () => this.props.isDisabled && style.disabled;

  private getImageClass = () => {
    const { theme } = this.props;
    switch (theme) {
      case Theme.BLOSSOM:
        return style.blossom;
      case Theme.CONCIERGE:
        return style.concierge;
      case Theme.HIPSTER:
        return style.hipster;
      default:
        return null;
    }
  };

  private getLabel = () => {
    const { theme } = this.props;
    switch (theme) {
      case Theme.BLOSSOM:
        return 'Blossom';
      case Theme.CONCIERGE:
        return 'Concierge';
      case Theme.HIPSTER:
        return 'Hipster';
      default:
        return null;
    }
  };

  private onCardClick = () => {
    if (!this.props.isDisabled) {
      this.props.actions.selectPartnerSiteTheme({ theme: this.props.theme });
    }
  };
}

export default ThemeCard;
