import React, { Component } from 'react';
import { generatePath } from 'react-router-dom';
import Wahanda from 'common/wahanda';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';
import { EmailCampaignSelectionAnalytics } from 'common/analytics';
import { BackToBusinessInit } from 'components/marketing/EmailCampaign/BackToBusiness/CampaignSelection';
import { LayoutContainer, LayoutHeader } from 'components/common/Layout';

import PromotionalOffers from './PromotionalOffers/container';
import { CampaignCard } from './CampaignCard/container';
import style from './CampaignSelection.scss';
import PATHS from '../../Router/paths';
/* THIS SHOULD COME FROM STATE WHEN API IMPLEMENTED */
import { PROMO_OFFERS_CAMPAIGN_ID, campaignList } from '../CampaignList';

const lang = Wahanda.lang.marketing.campaignSelection;
interface ICampaignSelectionProps extends React.HTMLAttributes<Element> {
  actions: {
    startCampaignSelection: (...args: any[]) => any;
    resetCampaignSelectionFlow: (...args: any[]) => any;
    emailTemplateReset: (...args: any[]) => any;
  };
  campaignSelection: {
    flowInProgress?: boolean;
  };
  referrer?: string;
  history: {
    push: (...args: any[]) => any;
  };
  isOpeningCampaignEnabled: boolean;
  flowInProgress?: any;
}
class CampaignSelection extends Component<ICampaignSelectionProps, {}> {
  public componentDidMount() {
    const { resetCampaignSelectionFlow, emailTemplateReset } = this.props.actions;
    resetCampaignSelectionFlow();
    emailTemplateReset();
    NavigationAnalytics.trackPageEventView(L2Page.EMAIL_CAMPAIGN);
  }

  public onCampaignSelect = (campaignId) => {
    EmailCampaignSelectionAnalytics.trackCampaignCardClick();
    switch (campaignId) {
      case PROMO_OFFERS_CAMPAIGN_ID:
        this.props.actions.startCampaignSelection();
        break;
      default:
        this.props.history.push(
          generatePath(PATHS.CAMPAIGN_PREVIEW_DEFAULT, {
            templateId: campaignId,
          }),
        );
        break;
    }
  };

  public renderPromotionalOffersFlow = () =>
    this.props.campaignSelection.flowInProgress && <PromotionalOffers />;

  public render() {
    const { isOpeningCampaignEnabled } = this.props;

    return (
      <LayoutContainer>
        <LayoutHeader header={lang.heading} subheader={lang.introduction} />
        {isOpeningCampaignEnabled && <BackToBusinessInit dataTest="back-to-business" />}
        <div className={style.campaigns}>
          {campaignList.map((campaign) => {
            const uniqueKey = `${campaign.id}`;
            return (
              <CampaignCard
                key={uniqueKey}
                onCampaignSelect={this.onCampaignSelect}
                {...campaign}
              />
            );
          })}
        </div>
        {this.renderPromotionalOffersFlow()}
      </LayoutContainer>
    );
  }
}
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
CampaignSelection.defaultProps = {
  referrer: '',
};

export { CampaignSelection };
