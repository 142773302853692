import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SignatureSearchModal as Component } from './SignatureSearchModal';
import * as actions from './actions';

const mapStateToProps = (state) => ({
  status: state.signature.status,
  signatures: state.signature.signatures,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const SignatureSearchModal = connector(Component);
