import App from 'common/backbone-app';
import { connect } from 'react-redux';
import { VenueClosingDialog as Component } from './VenueClosingDialog';
import { hideCovidBannerDialogs, venueClosingRequest } from '../../actions';

const mapStateToProps = ({ covidBanner }) => ({
  venueId: App.getVenueId(),
  employeeName: App.config.accountEmployeeName() || App.config.accountFullName(),
  emailAddress: App.config.get('account').emailAddress,
  submittingCloseRequest: covidBanner.submittingCloseRequest,
});

const mapDispatchToProps = {
  hideCovidBannerDialogs,
  venueClosingRequest,
};

export const VenueClosingDialog = connect(mapStateToProps, mapDispatchToProps)(Component);
