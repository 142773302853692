const stateListeners = [];
let connected = false;

function executeSafely(func) {
  try {
    func();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('WS listener notification failed', e);
  }
}

export function addWatcher(onConnect, onDisconnect) {
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
  stateListeners.push({ onConnect, onDisconnect });

  if (connected) {
    // Execute in the next tick so that it would be easier to reason about
    // and think that this function is always async.
    window.setTimeout(() => executeSafely(onConnect), 0);
  }
}

function notifyAll(functionName) {
  stateListeners.forEach((fnGroup) => executeSafely(fnGroup[functionName]));
}

export function notifyConnected() {
  notifyAll('onConnect');
  connected = true;
}

export function notifyDisconnected() {
  notifyAll('onDisconnect');
  connected = false;
}
