import {
  INITIAL_STATE_SELECT_SERVICE_DIALOG,
  GET_TREATMENTS_RECEIVE,
  GET_TREATMENTS_FAILURE,
  SELECT_SERVICE_DIALOG_CHANGE_SELECTED_TREATMENT_TYPE_ID,
  SELECT_SERVICE_DIALOG_REVERT_SELECTED_TREATMENT_TYPE_ID,
  SELECT_SERVICE_DIALOG_CHANGE_VALUE,
  SELECT_SERVICE_DIALOG_REVERT_VALUE,
  SELECT_SERVICE_DIALOG_REVERT,
} from 'reduxStore/actionsConst';

interface State {
  treatments: any[];
  treatmentTypes: any[];
  hasError: boolean;
  selectedTreatmentTypeId: number;
  selectServiceValue: string;
  treatmentTypesList: any[];
  treatmentsList: any[];
}

export const initialState: State = {
  treatments: [],
  treatmentTypes: [],
  hasError: false,
  selectedTreatmentTypeId: 0,
  selectServiceValue: '',
  treatmentTypesList: [],
  treatmentsList: [],
};

const initialStateSelectServiceDialog = (state, { data }) => ({
  ...state,
  ...data,
});

// @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
const getTreatmentsReceive = (state, { data: { treatments } = {}, hasError }) => ({
  ...state,
  treatments,
  hasError,
});

const getTreatmentsFailure = (state, { hasError }) => ({
  ...state,
  hasError,
});

const updateSelectedTreatmentId = (state, { selectedTreatmentTypeId }) => ({
  ...state,
  selectedTreatmentTypeId,
});

const updateSelectServiceValue = (state, { selectedTreatmentTypeId, selectServiceValue }) => ({
  ...state,
  selectedTreatmentTypeId,
  selectServiceValue,
});

export default function serviceTemplateSelector(state = initialState, action = {}) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
  switch (action.type) {
    case INITIAL_STATE_SELECT_SERVICE_DIALOG:
      // @ts-expect-error ts-migrate(2345) FIXME: Property 'data' is missing in type '{}' but requir... Remove this comment to see the full error message
      return initialStateSelectServiceDialog(state, action);

    case GET_TREATMENTS_RECEIVE:
      // @ts-expect-error ts-migrate(2345) FIXME: Property 'hasError' is missing in type '{}' but re... Remove this comment to see the full error message
      return getTreatmentsReceive(state, action);

    case GET_TREATMENTS_FAILURE:
      // @ts-expect-error ts-migrate(2345) FIXME: Property 'hasError' is missing in type '{}' but re... Remove this comment to see the full error message
      return getTreatmentsFailure(state, action);

    case SELECT_SERVICE_DIALOG_CHANGE_SELECTED_TREATMENT_TYPE_ID:
    case SELECT_SERVICE_DIALOG_REVERT_SELECTED_TREATMENT_TYPE_ID:
      // @ts-expect-error ts-migrate(2345) FIXME: Property 'selectedTreatmentTypeId' is missing in t... Remove this comment to see the full error message
      return updateSelectedTreatmentId(state, action);

    case SELECT_SERVICE_DIALOG_CHANGE_VALUE:
    case SELECT_SERVICE_DIALOG_REVERT_VALUE:
      // @ts-expect-error ts-migrate(2345) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
      return updateSelectServiceValue(state, action);

    case SELECT_SERVICE_DIALOG_REVERT:
      return initialState;

    default:
      return state;
  }
}
