import { reducer, on } from 'ts-action';
import {
  abortPOSOperationRequestStart,
  startPOSOperationRequestSuccess,
  startPOSOperationRequestFailure,
} from './actions';

interface State {
  operationId?: {
    id: number;
  };
}

const initialState: State = {};

export const posOperationReducer = reducer(
  initialState,
  on(startPOSOperationRequestSuccess, (_, action) => ({
    operationId: action.payload.operationId,
  })),
  on(abortPOSOperationRequestStart, startPOSOperationRequestFailure, () => initialState),
);
