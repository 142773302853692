import { action, payload } from 'ts-action';
import { POS_OPERATIONS } from 'common/constants/pos-operations';
import { POSOperationId } from './types';

export const startPOSOperationRequest = action(
  'START_POS_OPERATION',
  payload<{
    operation: POS_OPERATIONS;
  }>(),
);

export const startPOSOperationRequestSuccess = action(
  'START_POS_OPERATION_SUCCESS',
  payload<{ operationId?: POSOperationId }>(),
);

export const startPOSOperationRequestFailure = action('START_POS_OPERATION_FAILURE');

export const abortPOSOperationRequestStart = action(
  'ABORT_POS_OPERATION_START',
  payload<{ operation: POS_OPERATIONS; operationId?: POSOperationId }>(),
);
