App.Views.Calendar.DatedAlerts = BackboneEx.View.Calendar.Alerts.extend({
  events: {
    'click .a-show-booking': 'onBookingClicked',
  },
  calendarTypeToBooking: {
    'spa-day': 'Y',
    'spa-break': 'O',
  },

  initialize: function () {
    BackboneEx.View.Calendar.Alerts.prototype.initialize.call(this);

    var self = this;

    this.isActive = false;
    this.activeCalendarType = null;
    this.bookings = new App.Collections.Bookings();
    // Setting a comparator to sort by booking itemDate
    this.bookings.comparator = function (booking) {
      return (booking.get('dated') || {}).itemDate;
    };

    App.on('app:loaded', function () {
      if (self.isActive) {
        self.refresh();
      }
    })
      .on('calendar:type-change', function (type) {
        self.isActive = type in self.calendarTypeToBooking;
        self.$el.wHide();
        if (self.isActive) {
          self.activeCalendarType = type;
          self.refresh();
        }
      })
      .on('booking:rejected booking:confirmed', function () {
        if (self.isActive) {
          self.refresh();
        }
      });

    App.Timer.on('notifications', function () {
      // Refresh in sync with notifications toolbar
      if (self.isActive) {
        self.refresh();
      }
    });

    this.$el.hide().removeClass('hidden');
  },

  _render: function () {
    this.$('.list').empty();
    var count = 0;
    if (this.bookings.length > 0) {
      count += this._renderBookings();
    }

    if (count > 0) {
      this.$el.closest('.section-aside').toggleClass('has-alert-block', count > 0);
      this.$('.alert-count').text(count);
      this.show();
    } else {
      this.hide();
    }
  },

  _renderBookings: function () {
    var count = 0;
    var maxName = 30;
    var html = '';

    this.bookings.each(function (booking) {
      if (!booking.get('dated')) {
        // Not a dated booking. Seems like a API bug.
        return;
      }
      var date = Wahanda.Date.createDate(booking.get('dated').itemDate);
      var name = booking.get('itemName') || '';
      var clientName = booking.get('customer').name || '';

      html += Wahanda.Template.renderTemplate('calendar-alerts-item-booking', {
        id: booking.id,
        day: date.getDate(),
        month: Wahanda.lang.date.monthsShort[date.getMonth()],
        serviceName: name.length > maxName ? name.substring(0, maxName - 3) + '...' : name,
        consumerName: clientName,
        className: 'future-appointment dated-booking',
      });
      count++;
    });

    this.$('.list').append(html);

    return count;
  },

  openBooking: function (booking) {
    var dialog = new App.Views.Dialog.Booking({
      model: booking,
      updateUrl: false,
    });
    dialog.render();
    dialog.open();
  },

  /**
   * Refreshes alerts.
   */
  refresh: function () {
    // Do not refresh in lightweight authentication or not active
    if (App.isRestrictedMode() || !this.isActive || !Wahanda.Permissions.viewBookings()) {
      this.hide();
      return;
    }

    var self = this;
    this.bookings.setFilters({
      arrivalDateFrom: Wahanda.Date.toApiString(Wahanda.Date.createVenueDate()),
      serviceTypeCode: this.calendarTypeToBooking[this.activeCalendarType],
      bookingStatus: 'UC',
    });
    this.bookings.fetch({
      success: function () {
        self.render();
      },
    });
  },

  onBookingClicked: function (event) {
    var id = parseInt($(event.currentTarget).data('id'), 10);
    var booking = this.bookings.get(id);
    if (booking && booking.get('dated')) {
      var date = Wahanda.Date.createDate(booking.get('dated').itemDate);
      if ('spa-break' === this.activeCalendarType) {
        App.trigger('calendar:set-room-type', booking.get('dated').roomTypeId, {
          refresh: false,
        });
      }
      App.trigger('calendar:date-change', date);
      this.openBooking(booking);
    }
  },
});
