import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import POSReportSelector from './index';

const mapStateToProps = (state) => {
  const canViewVatReport =
    state.salesReport.reportContainer.isVatPayer && App.config.get('venue').pointOfSaleEnabled;
  return {
    canViewVatReport,
    canViewPosReports: Wahanda.Permissions.viewPosReports(),
  };
};

const connector = connect(mapStateToProps);

export default withRouter(connector(POSReportSelector));
