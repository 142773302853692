import { createSelector } from 'reselect';

import { generateTreatmentsFor, numberOfTreatmentsFor } from './treatments';

import { generateTreatmentTypesFor, numberOfTreatmentTypesFor } from './treatment-types';

/*
 *  Reselect prefers the entire state tree to be passed to the selectors --
 *  but 1) that's unecessary, here, since these selectors only ever operate on
 *  one branch, and 2) passing the entire state tree bloats both these functions
 *  and the transformers which consume them
 */
const selectTreatments = ({ treatments }) => treatments;
const selectTreatmentTypes = ({ treatmentTypes }) => treatmentTypes;
const selectTreatmentsList = ({ treatmentsList }) => treatmentsList;
const selectTreatmentTypesList = ({ treatmentTypesList }) => treatmentTypesList;
const selectSelectServiceValue = ({ selectServiceValue }) => selectServiceValue;

export const treatmentsListSelector = createSelector(selectTreatments, selectSelectServiceValue, (
  treatments,
  selectServiceValue, // for readability:
) => generateTreatmentsFor(treatments, selectServiceValue));

export const treatmentTypesListSelector = createSelector(
  selectTreatmentTypes,
  selectSelectServiceValue,
  selectTreatmentsList,
  (
    treatmentTypes,
    selectServiceValue,
    treatmentsList, // for readability:
  ) => generateTreatmentTypesFor(treatmentTypes, selectServiceValue, treatmentsList),
);

export const numberOfTreatmentsListSelector = createSelector(
  selectTreatmentsList,
  selectSelectServiceValue,
  (
    treatmentsList,
    selectServiceValue, // for readability:
  ) => numberOfTreatmentsFor(treatmentsList, selectServiceValue),
);

export const numberOfTreatmentTypesListSelector = createSelector(
  selectTreatmentTypesList,
  selectSelectServiceValue,
  (
    treatmentTypesList,
    selectServiceValue, // for readability:
  ) => numberOfTreatmentTypesFor(treatmentTypesList, selectServiceValue),
);
