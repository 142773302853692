(function () {
  function dateOrNull(value) {
    return value ? Wahanda.Date.createDate(value) : null;
  }

  App.Models.PosStatus = Backbone.Model.extend(
    {
      url: function () {
        return App.Api.wsVenueUrl('/pos-status');
      },

      parse: function (data) {
        return {
          openDay: dateOrNull(data.openDay),
          lastClosedDay: dateOrNull(data.lastClosedDay),
        };
      },

      isPreviousDayOpen: function () {
        var openDay = dateOrNull(this.get('openDay'));

        if (openDay) {
          return Wahanda.Date.isPast(openDay, true);
        }
        return false;
      },

      isTodayClosed: function () {
        var closedDay = dateOrNull(this.get('lastClosedDay'));

        return closedDay && Wahanda.Date.isToday(closedDay);
      },
    },
    {},
  );
})();
