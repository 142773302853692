BackboneEx.Mixin = {
	View: {},
	Common: {},

    extend: function(obj, mixin) {
        var source = obj.prototype || obj;
        if (mixin.initialize !== undefined) {
            var oldInitialize = source.initialize;
            var newInitialize = mixin.initialize;
            source.initialize = function () {
                newInitialize.apply(this, arguments);
                oldInitialize.apply(this, arguments);
            };
            mixin = _.omit(mixin, 'initialize');
        }
        _.extend(source, mixin);
    },

	/**
	 * The mixin extension functionality.
	 *
	 * @param Backbone.{View|Model|Collection} obj
	 * @param Object mixin
	 */
	extendView: function(obj, mixin) {
		var source = obj.prototype || obj;
		_.defaults(source, mixin);
		if (mixin.events) {
			if (source.events) {
				_.defaults(source.events, mixin.events);
			} else {
				source.events = mixin.events;
			}
		}
		if (mixin.initialize !== undefined) {
			var oldInitialize = source.initialize;
			source.initialize = function () {
				mixin.initialize.apply(this, arguments);
				oldInitialize.apply(this, arguments);
			};
		}
	}
};
