import React from 'react';
import AutogrowTextarea from 'react-textarea-autosize';
import classnames from 'classnames';
import { InputBase, InputTextValue } from 'components/common/__BaseCommon';
import { useComponentDidMount, useDebounce } from 'utilities/hooks';
import style from './Textarea.scss';

export interface TextareaProps {
  autoFocus?: boolean;
  label?: string;
  maxRows?: number;
  minRows?: number;
  name?: string;
  onChange?: (value: InputTextValue) => void;
  onBlur?: (value: InputTextValue) => void;
  onFocus?: () => void;
  onHeightChange?: () => void;
  placeholder?: string;
  value?: string;
  fullBorder?: boolean;
  dataTest?: string;
  disabled?: boolean;
  readOnly?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  required?: boolean;
  hasDebounce?: boolean;
}

export const Textarea = (props: TextareaProps) => {
  const {
    disabled,
    readOnly,
    hasError,
    errorMessage,
    required,
    label,
    fullBorder,
    onChange,
    onBlur,
    onFocus,
    autoFocus,
    maxRows,
    minRows,
    hasDebounce,
    name,
    onHeightChange,
    placeholder,
    value,
    dataTest,
  } = props;
  const componentDidMount = useComponentDidMount();
  const [inputValue, setInputValue] = React.useState(value as InputTextValue);
  const [isFocused, setFocus] = React.useState(autoFocus);
  const modelValue = useDebounce(inputValue, hasDebounce ? 300 : 0);

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
    setFocus(true);
  };
  const handleBlur = () => {
    if (onBlur) {
      onBlur(modelValue);
    }
    setFocus(false);
  };
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  React.useEffect(() => {
    if (componentDidMount && onChange) {
      onChange(modelValue);
    }
  }, [modelValue]);

  return (
    <InputBase
      dataTest={dataTest}
      disabled={disabled}
      label={label}
      readOnly={readOnly}
      hasError={hasError}
      fullBorder={fullBorder}
      required={required}
      errorMessage={errorMessage}
      isFocused={isFocused}
    >
      <AutogrowTextarea
        className={classnames(style.textarea, { [style.fullBorder]: fullBorder })}
        autoFocus={autoFocus}
        maxRows={maxRows}
        minRows={minRows}
        name={name}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onHeightChange={onHeightChange}
        placeholder={placeholder}
        // Using default value fixes issue with caret jumping to the end
        // https://github.com/Andarist/react-textarea-autosize/issues/68#issuecomment-296854883
        defaultValue={value}
        data-test={dataTest}
      />
    </InputBase>
  );
};

Textarea.displayName = 'Textarea';
