export const getEmailCampaign = (state) => (state.marketing || {}).emailCampaign || {};

export const getEmailTemplate = (state) => getEmailCampaign(state).campaignTemplate || {};

export const getCampaignSummary = (state) => getEmailCampaign(state).campaignSummary || {};

export const getCampaignSelection = (state) => getEmailCampaign(state).campaignSelection || {};

export const getPromotionalOffers = (state) => getCampaignSelection(state).promotionalOffers || {};

export const getSmartDiscounts = (state) => getPromotionalOffers(state).smartDiscounts || [];

export const getTreatmentGroups = (state) =>
  getPromotionalOffers(state).serviceOnSaleTreatmentGroups || [];

export const getIsLoaded = (state) => getPromotionalOffers(state).isDataLoaded;
