import React from 'react';
import { Notice } from 'components/common/Notice';
import Wahanda from 'common/wahanda';
import { UnavailableResource } from '../store/types';
import style from './ResourceCheckWarning.scss';

interface Props {
  employeeAvailable: boolean;
  employee: {
    id: number;
    name: string;
  };
  unavailableResources: UnavailableResource[];
}

export const ResourceCheckWarning: React.FC<Props> = ({
  employeeAvailable,
  employee,
  unavailableResources,
}) => {
  const prefix = Wahanda.lang.calendar.appointments.edit.resourceAvailabilityCheck;
  const resourceNames = unavailableResources.map(({ resourceName }) => resourceName);

  const resourceList: JSX.Element[] = [!employeeAvailable && employee.name, ...resourceNames]
    .filter(Boolean)
    .map((name) => <strong key={name as string}>{name}</strong>);

  const intersperse = (arr, separator) => {
    if (arr.length === 0) {
      return [];
    }

    return arr.reduce((acc, curr, index) => {
      if (index !== arr.length - 1) {
        return [...acc, curr, separator];
      }

      return [...acc, curr];
    }, []);
  };

  return (
    <div className={style.resourceCheckWarningContainer}>
      <Notice dataTest="udv-availability-warning">
        {prefix} {intersperse(resourceList, ', ')}.
      </Notice>
    </div>
  );
};
