import React from 'react';
import { Button, ButtonSpacer } from 'components/common/Button';
import { Modal, ModalBody, ModalContainer, ModalHeader } from 'components/common/Modal';
import style from './WizardModal.scss';

export const ConfirmationModal = ({ onExit, onContinue }) => {
  return (
    <Modal>
      <ModalContainer onClose={onContinue}>
        <ModalHeader title="Are you sure you want to exit?" onClose={onContinue} />
        <ModalBody>
          <p className={style.confirmationModal}>
            Exiting now will result in the loss of all progress made. Your current session will not
            be saved.
          </p>
          <Button label="Continue editing" fullWidth size="large" onClick={onContinue} />
          <ButtonSpacer />
          <Button
            label="Exit now"
            colour="plain"
            variant="secondary"
            size="large"
            fullWidth
            onClick={onExit}
          />
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
};
