import React, { PureComponent } from 'react';

export default class Download extends PureComponent<{}, {}> {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <g fill="none" fillRule="evenodd">
          <path stroke="#001E62" strokeWidth="2" d="M3 16v4h18v-4m-5-5l-4 4-4-4m4 4V3" />
        </g>
      </svg>
    );
  }
}
