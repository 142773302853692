import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Phone extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
        return (
          <svg
            className={['icon-svg', 'svg-phone', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 12 12"
          >
            <path d="M8.78590901,8.5769857 C8.81137012,8.58207793 8.48776338,8.85985994 7.81508877,9.41033173 L6.50946807,7.89528896 L7.46341877,7.07320142 C7.90750378,6.69050161 8.60675554,6.5015472 9.17814128,6.61582435 L10.714091,6.9230143 C11.4533493,7.07086596 12,7.75051411 12,8.50097656 L12,10 C12,11.0522847 11.0522847,12 10,12 C4.44771525,12 2.66453526e-14,7.55228475 2.66453526e-14,2 C2.66453526e-14,0.94771525 0.94771525,0 2,0 L3.49902344,0 C4.25743028,0 4.92942784,0.548119707 5.0769857,1.28590901 L5.38417565,2.82185872 C5.48940644,3.34801268 5.35783828,4.01524476 5.06426197,4.46550023 L4.29149066,5.65069295 L2.61615276,4.55833636 L3.38892407,3.37314365 C3.39839148,3.35862355 3.42496645,3.22385178 3.4230143,3.21409099 L3.1801961,2 L2.61042362,2 L2,2 C2,6.44771525 5.55228475,10 10,10 L10,8.8198039 L8.78590901,8.5769857 Z" />
          </svg>
        );
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-phone', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M5.07432941,6.91225888 L3.58774112,5.57432941 L4.48381386,4.57869304 C4.64300877,4.40180981 4.76917697,3.94490434 4.7244303,3.72117101 L4.41440835,2.17106126 C4.39989516,2.0984953 4.27987339,2 4.2042439,2 L3.02081705,2 L2.4,2 C2.25228475,2 2,2.25228475 2,2.4 C2,8.84771525 7.15228475,14 13.6,14 C13.7477153,14 14,13.7477153 14,13.6 L14,11.7957561 C14,11.7254401 13.8960747,11.5990189 13.8289387,11.5855917 L12.278829,11.2755697 C12.0531368,11.2304313 11.598047,11.3571201 11.421307,11.5161861 L10.4256706,12.4122589 L9.08774112,10.9256706 L10.0833775,10.0295979 C10.7274805,9.44990513 11.8232844,9.14485298 12.6710613,9.31440835 L14.221171,9.6244303 C15.2220534,9.82460678 16,10.7709503 16,11.7957561 L16,13.6 C16,14.8522847 14.8522847,16 13.6,16 C6.04771525,16 1.95399252e-14,9.95228475 1.95399252e-14,2.4 C1.95399252e-14,1.14771525 1.14771525,0 2.4,0 L4.2042439,0 C5.23365678,0 6.17416936,0.771827265 6.3755697,1.77882899 L6.68559165,3.32893874 C6.85444866,4.1732238 6.55124654,5.27123985 5.97040215,5.91662251 L5.07432941,6.91225888 Z" />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-phone', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 24 24"
          >
            <path d="M8.3425,10.64 L9.4625,9.39625 C10.18875,8.58875 10.5675,7.21625 10.3575,6.16125 L9.97,4.22375 C9.7175,2.965 8.5425,2 7.255,2 L5,2 C3.435,2 2,3.435 2,5 C2,14.44 9.56,22 19,22 C20.565,22 22,20.565 22,19 L22,16.745 C22,15.46375 21.0275,14.28125 19.77625,14.03 L17.83875,13.6425 C16.77875,13.43 15.40875,13.8125 14.60375,14.5375 L13.36,15.6575 L15.0325,17.515 L16.27625,16.395 C16.4975,16.19625 17.06625,16.0375 17.34875,16.095 L19.28625,16.4825 C19.37,16.49875 19.5,16.6575 19.5,16.745 L19.5,19 C19.5,19.185 19.185,19.5 19,19.5 C10.94,19.5 4.5,13.06 4.5,5 C4.5,4.815 4.815,4.5 5,4.5 L7.255,4.5 C7.35,4.5 7.5,4.6225 7.5175,4.71375 L7.905,6.65125 C7.96125,6.93125 7.80375,7.50125 7.605,7.72375 L6.485,8.9675 L8.3425,10.64 Z" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Phone);
