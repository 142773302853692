import React from 'react';
import { EditorState } from 'draft-js';
import { ControlButton } from '../ControlButton';
import style from '../RichTextEditor.scss';

const INLINE_STYLES = [
  { label: 'Bold', name: 'BOLD', className: 'rich-text-editor-button-bold' },
  { label: 'Italic', name: 'ITALIC', className: 'rich-text-editor-button-italic' },
  { label: 'Underline', name: 'UNDERLINE', className: 'rich-text-editor-button-underlined' },
];
interface Props {
  editorState: EditorState;
  onToggle: (params) => void;
}

export const InlineStyleControls = ({ editorState, onToggle }: Props) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className={style.controls}>
      {INLINE_STYLES.map((type) => (
        <ControlButton
          key={type.label}
          active={currentStyle.has(type.name)}
          onToggle={onToggle}
          style={type.name}
          className={type.className}
        />
      ))}
    </div>
  );
};
