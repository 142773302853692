import sampleSize from 'lodash/sampleSize';

declare global {
  interface Window {
    treatwellAppDelegates: any;
    webkit: {
      messageHandlers: { appDelegates: { postMessage: any } };
    };
  }
}

export enum ACTION {
  CLOSE_DAY = 'pos-close-day',
  DEPOSIT = 'pos-deposit',
  WITHDRAW = 'pos-withdraw',
  REVIEW = 'customer-reviews',
  OPEN_CHECKOUT_REFUND = 'pos-checkout-refund',
  OPEN_CHECKOUT_SUMMARY = 'pos-checkout-summary',
  ISSUE_REFUND = 'pos-issue-refund',
}

interface AppDelegateParams {
  onDone?: (...args: any[]) => void;
  [x: string]: string | number | object | undefined;
}

/**
 * Read in the delegated features from a global variable, if it was set by an App's WebView.
 */
export function getDelegatedFeatures() {
  return typeof window.treatwellAppDelegates === 'undefined' ||
    !Array.isArray(window.treatwellAppDelegates)
    ? []
    : window.treatwellAppDelegates;
}

export function canDelegateToApp(feature: ACTION) {
  return getDelegatedFeatures().includes(feature);
}

const generateRandomString = (): string => {
  return sampleSize('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 7).join('');
};

export function delegateToApp(action: ACTION, params: AppDelegateParams = {}) {
  const { onDone, ...rest } = params;
  const props = {
    ...rest,
  };
  if (onDone) {
    const onDoneCallbackName = generateRandomString();
    props.onDone = onDoneCallbackName;
    window[onDoneCallbackName] = (...args: any[]) => {
      onDone(...args);
      window[onDoneCallbackName] = null;
    };
  }
  window.webkit.messageHandlers.appDelegates.postMessage({ action, ...props });
}
