/* eslint arrow-body-style: 0 */
import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import extendMixin from 'common/extend-mixin';
import ReactDialog from 'src/components/common/react-dialog';
import DialogRow from 'src/components/reports/transactions/common/dialog-row';
import FormValidationMixin from 'src/components/mixins/form-validation';
import PriceInput from 'src/components/common/forms/price-input';
import DialogErrorList from 'src/components/common/dialog/error-list';
import { createCashTransaction } from 'src/actions/pos/create-transaction';
import { Loader } from 'components/common/Loader';
import apiUrl from 'common/api-url';
import { Currency } from 'components/common/DataFormat';
import moment from 'common/moment';
import { POS_OPERATIONS } from 'common/constants/pos-operations';

interface ITransactionDialogProps extends React.HTMLAttributes<Element> {
  onClose?: (...args: any[]) => any;
  title?: string;
  actionButtonText?: string;
  negativeValue?: boolean;
  currentDrawerTotal?: number;
  operationId?: number;
  actions: {
    startPOSOperationRequest: (...args: any[]) => any;
    abortPOSOperationRequestStart: (...args: any[]) => any;
    requestPosStatusAction: (...args: any[]) => any;
    dailyReportRequest: (...args: any[]) => any;
    resetStateAction: (...args: any[]) => any;
  };
  dailyReportRequest?: any;
  resetStateAction?: any;
  abortPOSOperationRequestStart?: any;
  startPOSOperationRequest?: any;
  requestPosStatusAction?: any;
}
type TransactionDialogState = {
  validationErrors?: any;
  saveError?: boolean;
  saving?: boolean;
  operationComplete?: boolean;
};

// TODO: refactor to make more idiomatic
export class TransactionDialog extends React.Component<
  ITransactionDialogProps,
  TransactionDialogState
> {
  amount: any;

  defaultSaveFailHandler: any;

  dialog: any;

  form: any;

  getErrorList: any;

  lang: any;

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'note' has no initializer and is not defi... Remove this comment to see the full error message
  note: HTMLInputElement;

  onAmountChange: any;

  onFormMaybeSubmitKeyup: any;

  onFormSubmit: any;

  static defaultProps = {
    operationId: undefined,
  };

  constructor(props) {
    super(props);
    // Mixin the form validations
    extendMixin(this, FormValidationMixin);
    this.lang = Wahanda.lang.reports.transactions.transaction;
  }

  state = {};

  // As ES6 classes don't supprt mixins, this is how it will be attached.
  componentDidMount() {
    FormValidationMixin.componentDidMount.call(this);
    this.props.actions.requestPosStatusAction();
    this.props.actions.startPOSOperationRequest({
      operation: POS_OPERATIONS.VENUE_TRANSACTION,
    });
  }

  componentWillUnmount = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'operationComplete' does not exist on typ... Remove this comment to see the full error message
    if (!this.state.operationComplete) {
      this.props.actions.abortPOSOperationRequestStart({
        operation: POS_OPERATIONS.VENUE_TRANSACTION,
        operationId: this.props.operationId,
      });
    }
    this.props.actions.resetStateAction();
  };

  componentDidUpdate() {
    this.amount.focus();
  }

  onFormInvalid(errors) {
    // this is used in FormValidationMixin.
    this.setState({
      validationErrors: errors,
      saveError: false,
    });
  }

  onFormValid() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'saving' does not exist on type '{}'.
    if (this.state.saving) {
      return;
    }
    const lang = this.lang.common;
    const amount = this.amount.getValue();
    const note = this.note.value;
    // @ts-expect-error ts-migrate(2345) FIXME: Type 'undefined' is not assignable to type 'POSOpe... Remove this comment to see the full error message
    const promise = createCashTransaction(amount, note, this.props.operationId);
    const receiptTotalLabel = this.props.negativeValue ? lang.withdrawal : lang.deposit;
    promise
      .done((data) => {
        this.setState({ operationComplete: true });
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'receiptId' does not exist on type '{ rec... Remove this comment to see the full error message
        const { receiptId, receiptCopyUri } = data;
        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        this.props.onClose();
        const receiptDialog = new App.Views.Forms.POSTransactionComplete({
          receiptUri: apiUrl('POS_TRANSACTION_RECEIPT', { receiptId }),
          customTotal: amount,
          customTotalLabel: receiptTotalLabel,
          receiptCopyUri,
        });
        receiptDialog.render();
        receiptDialog.open();
        this.props.actions.dailyReportRequest(moment.venueTimeZoneDate().formatApiDateString());
      })
      .fail(this.defaultSaveFailHandler(this.lang.errors));
    this.setState({
      saving: true,
    });
  }

  getErrorListForView = () => {
    let errorList = this.getErrorList();
    if (this.isWithdrawalAmountExceedError()) {
      const text = this.lang.errors.withdrawalAmountExceed;
      errorList = errorList.map(() => ({ text }));
    }
    return errorList;
  };

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'saving' does not exist on type '{}'.
  isProcessing = () => this.state.saving || this.isLoading();

  isLoading = () => this.props.currentDrawerTotal == null;

  isWithdrawalAmountExceedError = () => {
    return (
      this.props.negativeValue &&
      this.amount &&
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.amount.getValue() * -1 > this.props.currentDrawerTotal
    );
  };

  refForm = (form) => {
    this.form = form;
  };

  renderCurrentBalanceDialogRow = () => {
    if (App.isFeatureSupported('hide-cash-balance')) {
      return null;
    }
    return (
      <DialogRow title={this.lang.common.cashInDrawer} isLast={false}>
        <Currency amount={this.props.currentDrawerTotal} />
      </DialogRow>
    );
  };

  renderErrors = () => {
    const state = this.state;
    const errorList = this.getErrorListForView();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'saving' does not exist on type '{}'.
    if (!state.saving && errorList.length > 0) {
      return <DialogErrorList errors={errorList} />;
    }
    return null;
  };

  render() {
    const lang = this.lang.common;
    const state = this.state;
    const buttonClasses = classnames('action-button', {
      disabled: this.isProcessing(),
    });
    return (
      <ReactDialog
        dataTest="report-transaction-modal"
        title={this.props.title}
        classes={{ 'transactions-dialog': true }}
        onClose={this.props.onClose}
        width={340}
        ref={(dialog) => {
          this.dialog = dialog;
        }}
      >
        {this.isLoading() && (
          <div className="transactions-dialog--loading">
            <Loader />
          </div>
        )}
        <form
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
          className={this.isLoading() ? 'hidden' : null}
          ref={this.refForm}
          onSubmit={this.onFormSubmit}
          onKeyUp={this.onFormMaybeSubmitKeyup}
        >
          {this.renderErrors()}
          <DialogRow title={lang.amount} isLast={false} customClasses="big">
            <PriceInput
              onChange={this.onAmountChange}
              key="amount"
              disabled={this.isProcessing()}
              ref={(amount) => {
                this.amount = amount;
              }}
              negative={this.props.negativeValue}
              min={0.01}
              // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
              max={this.props.negativeValue ? this.props.currentDrawerTotal : null}
            />
          </DialogRow>
          {this.renderCurrentBalanceDialogRow()}
          <div className="row last transactions-dialog--note">
            <label htmlFor="transaction-dialog-note">{lang.note}</label>
            <input
              type="text"
              ref={(note) => {
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLInputEl... Remove this comment to see the full error message
                this.note = note;
              }}
              id="transaction-dialog-note"
              disabled={this.isProcessing()}
            />
          </div>
          <div
            className={buttonClasses}
            onClick={this.onFormSubmit}
            data-test="cash-operation-submit-button"
          >
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'saving' does not exist on type '{}'. */}
            {state.saving ? Wahanda.lang.shared.saving : this.props.actionButtonText}
          </div>
        </form>
      </ReactDialog>
    );
  }
}
