import {
  GET_SIGNATURE_REQUEST,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_FAILURE,
  CLEAR_SIGNATURES,
} from 'reduxStore/actionsConst';
import { ActionUnionTypes } from 'reduxStore/actionCreator';
import { Signature } from './models';
import * as actions from './actions';

export enum SignaturesStatus {
  FETCHING = 'FETCHING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIAL = 'INITIAL',
}

export interface State {
  status: SignaturesStatus;
  signatures?: Signature[];
}

const initialState: State = {
  status: SignaturesStatus.INITIAL,
};

export const signatureReducer = (
  state = initialState,
  action: ActionUnionTypes<typeof actions>,
): State => {
  switch (action.type) {
    case GET_SIGNATURE_REQUEST:
      return {
        ...state,
        status: SignaturesStatus.FETCHING,
      };
    case GET_SIGNATURE_SUCCESS:
      return {
        ...state,
        status: SignaturesStatus.COMPLETED,
        signatures: action.payload.signatures,
      };
    case GET_SIGNATURE_FAILURE: {
      return {
        ...state,
        status: SignaturesStatus.FAILED,
      };
    }
    case CLEAR_SIGNATURES:
      return {
        ...state,
        signatures: undefined,
      };
    default:
      return state;
  }
};
