import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Desktop extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-desktop', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M2.00246167,2 L21.9975383,2 C23.0542831,2 23.9181631,2.81894651 23.9945142,3.8571099 L24,4.00684547 L24,15.9931545 C24,17.0519433 23.1909232,17.9179594 22.1480487,17.9945006 L21.9975383,18 L14,18 L14,20 L16,20 L16,22 L8,22 L8,20 L10,20 L10,18 L2.00246167,18 C0.945716909,18 0.081836947,17.1810535 0.00548576189,16.1428901 L0,15.9931545 L0,4.00684547 C0,2.94805675 0.80907684,2.0820406 1.85195127,2.00549944 L2.00246167,2 L21.9975383,2 L2.00246167,2 Z M22,15.9931545 L21.9975383,4 L2,4.00684547 L2.00246167,16 L22,15.9931545 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Desktop);
