import React from 'react';
import Wahanda from 'common/wahanda';
import Formsy from 'formsy-react';
import keycode from 'keycode';

import { Notice } from 'components/common/Notice';
import UnderlinedInput from 'components/common/UnderlinedInput/withFormsy';
import { InputLabel } from 'components/common/__BaseCommon';
import { RichTextEditor } from 'components/common/RichTextEditor';
import { DialogConfirmation } from 'components/common/dialog/DialogConfirmation';
import Loader from 'components/common/Loader';
import Dialog from 'components/common/react-dialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';

import { MenuGroupAnalytics } from './tracking';

import style from './MenuGroupModal.scss';

interface Props {
  active: boolean;
  description: string;
  hasAnyOffers?: boolean;
  showArchiveConfirmation?: boolean;
  id?: number;
  isFullFiltersEnabled?: boolean;
  name: string;
  // Redux
  actions: {
    deleteMenuGroup: (param) => void;
    resetMenuGroup: () => void;
    resetMenuGroupError: () => void;
    setMenuGroup: (param) => void;
  };
  onClose: () => void;
  isSubmitting: boolean;
  serverErrorName: string;
  showDescription: boolean;
}

const descriptionPlaceholder = Wahanda.lang.menu.group.form.descriptionPlaceholder;

const errors = {
  'menu-group-name-in-use': Wahanda.lang.menu.group.errors.duplicateName,
  'menu-group-not-empty': Wahanda.lang.menu.group.errors.deleteNotEmpty,
};

export const MenuGroupModal = ({
  active,
  actions,
  description,
  hasAnyOffers,
  showArchiveConfirmation,
  id,
  isFullFiltersEnabled,
  isSubmitting,
  name,
  onClose,
  serverErrorName,
  showDescription,
}: Props) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    active,
    description,
    id,
    isFormValid: false,
    name,
  });
  const formRef = React.useRef() as React.MutableRefObject<HTMLFormElement>;
  const maybeShouldArchive = id && isFullFiltersEnabled && hasAnyOffers && active;
  const maybeShouldDelete = id && isFullFiltersEnabled && !hasAnyOffers;

  const saveButtonTitle =
    id && !active ? Wahanda.lang.menu.group.form.unarchive : Wahanda.lang.shared.buttons.save;
  const saveButtonDataTest = id && !active ? 'publish' : 'save';
  const isDeleteGroupError = errors[serverErrorName] === errors['menu-group-not-empty'];

  const handleFormValidation = () => {
    formRef.current.submit();
    return formValues.isFormValid;
  };

  const handleOnValid = () => {
    if (!formValues.isFormValid) {
      setFormValues((prevState) => ({ ...prevState, isFormValid: true }));
    }
  };

  const handleOnInvalid = () => {
    if (formValues.isFormValid) {
      setFormValues((prevState) => ({ ...prevState, isFormValid: false }));
    }
  };

  const handleSubmit = (shouldArchive: boolean) => {
    if (!handleFormValidation() && !isDeleteGroupError) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isFormValid, ...restDate } = formValues;
    const data = id
      ? {
          ...restDate,
          active: !shouldArchive,
        }
      : {
          name: formValues.name,
          description: formValues.description,
        };

    actions.resetMenuGroupError();
    actions.setMenuGroup(data);

    if (id && active && !shouldArchive) {
      MenuGroupAnalytics.trackMenuGroupUpdate();
    } else if (id && shouldArchive) {
      MenuGroupAnalytics.trackMenuGroupArchive();
    } else if (id && !active) {
      MenuGroupAnalytics.trackMenuGroupUnarchive();
    } else {
      MenuGroupAnalytics.trackMenuGroupCreate();
    }
  };

  const handleKeyPress = (event) => {
    if (keycode(event) === 'enter') {
      event.preventDefault();
      handleSubmit(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleArchive = () => {
    if (showConfirmation || !showArchiveConfirmation) {
      handleSubmit(true);
    } else {
      setShowConfirmation(true);
    }
  };

  const handleDelete = () => {
    actions.deleteMenuGroup({ id });
    MenuGroupAnalytics.trackMenuGroupDelete();
  };

  const handleFieldChange = (field: string) => {
    return (e) => {
      let updatedValue;

      switch (field) {
        case 'description':
          updatedValue = e;
          break;
        default:
          updatedValue = e.target.value;
          break;
      }

      if (!formValues.isFormValid) {
        actions.resetMenuGroupError();
      }
      setFormValues({ ...formValues, [field]: updatedValue });
    };
  };

  const handleNameSubmitValidation = () => {
    const inputError = errors[serverErrorName];
    const validation = {};

    if (!isSubmitting && serverErrorName && inputError && !isDeleteGroupError) {
      validation[serverErrorName] = () => inputError;
    }

    return validation;
  };

  React.useEffect(() => {
    MenuGroupAnalytics.trackMenuGroupModalView();
    return () => actions.resetMenuGroup();
  }, []);

  React.useEffect(() => {
    if (!isSubmitting && formValues.isFormValid && !serverErrorName) {
      handleClose();
    }
  }, [isSubmitting, serverErrorName]);

  return (
    <Dialog
      dataTest="menu-group-modal"
      width={400}
      title={Wahanda.lang.menu.group.form.modalTitle}
      onClose={handleClose}
      noContentPadding
      noContentTopBorders
    >
      <Formsy
        ref={formRef}
        onValid={handleOnValid}
        onInvalid={handleOnInvalid}
        onKeyPress={handleKeyPress}
      >
        {isDeleteGroupError && <Notice type="alert" message={errors[serverErrorName]} />}

        <div className={style.container}>
          <UnderlinedInput
            dataTest="menu-group-name-input-field"
            autoFocus={id == null}
            maxLength={100}
            name="name"
            onChange={handleFieldChange('name')}
            value={formValues.name}
            label={Wahanda.lang.menu.group.form.name}
            validations={handleNameSubmitValidation()}
            validationErrors={{
              isDefaultRequiredValue: Wahanda.lang.validate.defaults.required,
              maxLength: Wahanda.lang.validate.name.maxlength,
            }}
            required
          />
          {showDescription && (
            <div className={style.description}>
              <div className={style.descLabel}>
                <InputLabel label={descriptionPlaceholder} />
              </div>
              <RichTextEditor
                onTextChanged={handleFieldChange('description')}
                placeholder={descriptionPlaceholder}
                text={formValues.description}
              />
            </div>
          )}
          {isSubmitting && <Loader positionAbsolute />}
        </div>
        <DialogFooter>
          <DialogFooterButton
            dataTest="menu-group-button-close"
            title={Wahanda.lang.shared.buttons.cancel}
            type="secondary"
            onClick={handleClose}
          />
          {maybeShouldDelete && (
            <DialogFooterButton
              dataTest="menu-group-button-delete"
              title={Wahanda.lang.shared.buttons.delete}
              type="destructive"
              onClick={handleDelete}
            />
          )}
          {maybeShouldArchive && (
            <DialogFooterButton
              dataTest="menu-group-button-archive"
              title={Wahanda.lang.menu.group.form.archiveConfirmation.button}
              type="destructive"
              onClick={handleArchive}
            />
          )}
          <DialogFooterButton
            dataTest={`menu-group-button-${saveButtonDataTest}`}
            title={saveButtonTitle}
            onClick={() => handleSubmit(false)}
          />
        </DialogFooter>
      </Formsy>

      {showConfirmation && (
        <DialogConfirmation
          dataTest="menu-group-archive-confirmation-modal"
          onClose={handleClose}
          title={Wahanda.lang.menu.group.form.archiveConfirmation.title}
          text={Wahanda.lang.menu.group.form.archiveConfirmation.text}
          buttonTitle={Wahanda.lang.menu.group.form.archiveConfirmation.button}
          onButtonClick={handleArchive}
        />
      )}
    </Dialog>
  );
};
