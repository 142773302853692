import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Notice } from 'components/common/Notice';
import Loader from 'components/common/Loader';
import { Button } from 'components/common/Button';
import Sync from 'components/common/icons/Sync';
import { DialogConfirmation } from 'components/common/dialog/DialogConfirmation';
import Wahanda from 'common/wahanda';
import { IntegrationSetupAnalytics } from 'common/analytics';
import ExternalSystemSelect from './ExternalSystemSelect';
import ExternalSystemDescription from './ExternalSystemDescription';
import EmployeesMapping from './EmployeesMapping/container';
import {
  SYSTEM_SELECTION_STEP,
  TEST_CONNECTION_STEP,
  EMPLOYEES_MAPPING_STEP,
  FINISH_STEP,
  OTHER_SYSTEM_ID,
} from './reducer';
import style from './style.scss';

const lang = Wahanda.lang.settings.venue.integrations;
const CONNECTION_TEST_RETRY_INTERVAL = 10000;
const MAX_CONNECTION_TEST_RETRIES = 60;
interface IExternalSalonIntegrationProps extends React.HTMLAttributes<Element> {
  actions: {
    requestExternalSalonSystemsAction: (...args: any[]) => any;
    requestExternalSalonIntegrationAction: (...args: any[]) => any;
    requestExternalSalonIntegrationCreateAction: (...args: any[]) => any;
    requestExternalSalonIntegrationDeleteAction: (...args: any[]) => any;
    selectExternalSalonSystemAction: (...args: any[]) => any;
    resetStateAction: (...args: any[]) => any;
  };
  externalSalonSystem?: {
    id?: number;
    name?: string;
    integrationSetupUri?: string;
  };
  externalSystems: {
    id: number;
    key: string;
    name: string;
  }[];
  employeeLinks?: {}[];
  testConnectionFail: boolean;
  loading: boolean;
  step?: number;
  onChange: (...args: any[]) => any;
  name?: any;
  id?: any;
  length?: any;
  requestExternalSalonIntegrationDeleteAction?: any;
  requestExternalSalonIntegrationAction?: any;
  requestExternalSalonIntegrationCreateAction?: any;
  selectExternalSalonSystemAction?: any;
  resetStateAction?: any;
  requestExternalSalonSystemsAction?: any;
}
type ExternalSalonIntegrationState = {
  cancelConfirmationDialogVisible?: boolean;
  removeConfirmationDialogVisible?: boolean;
};
class ExternalSalonIntegration extends Component<
  IExternalSalonIntegrationProps,
  ExternalSalonIntegrationState
> {
  connectionTestTimer: any;

  connectionTestTimerCount: number;

  static defaultProps = {
    step: null,
    externalSalonSystem: {},
    employeeLinks: [],
  };

  constructor(props) {
    super(props);
    this.connectionTestTimer = null;
    this.connectionTestTimerCount = 0;
  }

  state = {};

  componentDidMount() {
    this.props.onChange({ hasChanges: false });
    this.props.actions.requestExternalSalonIntegrationAction();
    this.props.actions.requestExternalSalonSystemsAction();
  }

  componentDidUpdate(prevProps) {
    const { step, testConnectionFail, loading, employeeLinks } = this.props;
    if (loading) {
      return;
    }
    if (
      step === TEST_CONNECTION_STEP &&
      prevProps.step === step &&
      testConnectionFail &&
      this.connectionTestTimer == null
    ) {
      IntegrationSetupAnalytics.trackConnectionErrorView();
    }
    if (prevProps.step === TEST_CONNECTION_STEP && step === EMPLOYEES_MAPPING_STEP) {
      IntegrationSetupAnalytics.trackConnectionSuccessView();
    }
    if (prevProps.step !== step) {
      switch (step) {
        case SYSTEM_SELECTION_STEP:
          IntegrationSetupAnalytics.trackIntegrationSetupView();
          break;
        case EMPLOYEES_MAPPING_STEP:
          IntegrationSetupAnalytics.trackEmployeeMappingView();
          break;
        case FINISH_STEP:
          IntegrationSetupAnalytics.trackLinkedCalendarView();
          break;
        default:
      }
    }
    if (testConnectionFail) {
      this.setConnectionTestRetry();
      if (testConnectionFail !== prevProps.testConnectionFail) {
        this.openExternalSalonSystemIntegrationSetupPage();
      }
    } else {
      this.resetConnectionTestRetry();
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const hasUnsavedChanges = step === EMPLOYEES_MAPPING_STEP && employeeLinks.length;
    this.props.onChange({ hasChanges: hasUnsavedChanges });
  }

  componentWillUnmount() {
    this.props.actions.resetStateAction();
    this.resetConnectionTestRetry();
  }

  onExternalSystemSelect = (id) => {
    this.props.actions.selectExternalSalonSystemAction(id);
  };

  onTestConnectionClick = () => {
    IntegrationSetupAnalytics.trackTestConnectionClick();
    this.props.actions.requestExternalSalonIntegrationAction();
  };

  onSaveClick = () => {
    IntegrationSetupAnalytics.trackFinishSetupClick();
    this.props.actions.requestExternalSalonIntegrationCreateAction(this.props.employeeLinks);
  };

  onSaveClickDebounced = debounce(this.onSaveClick.bind(this), 500, {
    leading: true,
    trailing: false,
  });

  onCancelEmployeeMappingClick = () => {
    this.setState({ cancelConfirmationDialogVisible: true });
  };

  onRemoveClick = () => {
    this.setState({ removeConfirmationDialogVisible: true });
  };

  onCancelConfirm = () => {
    IntegrationSetupAnalytics.trackCancelSetupClick();
    this.removeIntegration();
  };

  onRemoveConfirm = () => {
    IntegrationSetupAnalytics.trackDeleteIntegrationClick();
    this.removeIntegration();
  };

  setConnectionTestRetry = () => {
    if (this.connectionTestTimerCount <= MAX_CONNECTION_TEST_RETRIES) {
      this.resetConnectionTestRetry();
      this.connectionTestTimer = setTimeout(
        this.props.actions.requestExternalSalonIntegrationAction,
        CONNECTION_TEST_RETRY_INTERVAL,
      );
      this.connectionTestTimerCount += 1;
    }
  };

  openExternalSalonSystemIntegrationSetupPage = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'integrationSetupUri' does not exist on t... Remove this comment to see the full error message
    const { integrationSetupUri } = this.props.externalSalonSystem;
    const integrationSetupUriNotEmpty = integrationSetupUri != null && integrationSetupUri.trim();
    if (integrationSetupUriNotEmpty) {
      window.open(integrationSetupUri, '_blank');
    }
  };

  resetConnectionTestRetry = () => {
    if (this.connectionTestTimer) {
      clearTimeout(this.connectionTestTimer);
      this.connectionTestTimer = null;
    }
  };

  removeIntegration = () => {
    this.closeConfirmationDialogs();
    this.props.actions.requestExternalSalonIntegrationDeleteAction();
  };

  closeConfirmationDialogs = () => {
    this.setState({
      cancelConfirmationDialogVisible: false,
      removeConfirmationDialogVisible: false,
    });
  };

  isStep = (step) => this.props.step === step;

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  isMinStep = (step) => this.props.step >= step;

  isMaxStep = (step) => this.props.step != null && this.props.step <= step;

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  isSaveButtonDisabled = () => this.props.employeeLinks.length === 0 || this.props.loading;

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  isSystemsSelectDescriptionVisible = () => this.props.externalSalonSystem.id === OTHER_SYSTEM_ID;

  renderConfirmDialog = (confirmationLang, onConfirm) => (
    <DialogConfirmation
      dataTest="external-salon-integration-confirmation-modal"
      title={confirmationLang.title}
      text={confirmationLang.text}
      buttonTitle={confirmationLang.confirmButtonText}
      buttonColor="red"
      onButtonClick={onConfirm}
      onClose={this.closeConfirmationDialogs}
    />
  );

  renderFinishStepHeader = () => {
    const info = Wahanda.Template.render(lang.connectedSystemInfo, {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      externalSystemName: this.props.externalSalonSystem.name,
    });
    return (
      <div>
        <div className={style.separator} />
        <Notice type="success">
          <div className={style.connectedSystemAttention}>
            {`${info} `}
            <a onClick={this.onRemoveClick}>{lang.remove}</a>
          </div>
        </Notice>
        <div className={style.separator} />
        <Notice type="info">{lang.employeeMappingLocked}</Notice>
      </div>
    );
  };

  renderExternalSystemSelection = () => {
    const { externalSystems } = this.props;
    return (
      <div>
        <p className={style.description} dangerouslySetInnerHTML={{ __html: lang.description }} />
        <div>
          <ExternalSystemSelect
            externalSystems={externalSystems}
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            selected={this.props.externalSalonSystem.id}
            onSelect={this.onExternalSystemSelect}
          />
          <div className={style.stepsSeparator} />
          {this.isSystemsSelectDescriptionVisible() && (
            <Notice
              type="info"
              message={
                <p
                  dangerouslySetInnerHTML={{
                    __html: lang.otherSystemsDescription,
                  }}
                />
              }
            ></Notice>
          )}
        </div>
      </div>
    );
  };

  renderTestConnection = () => {
    const { testConnectionFail } = this.props;
    return (
      <div>
        <ExternalSystemDescription
          // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
          name={this.props.externalSalonSystem.name}
          noConnection={testConnectionFail}
        />
        <div className={style.separator} />
        <Button onClick={this.onTestConnectionClick} label={lang.test} />
      </div>
    );
  };

  renderEmployeeMappingDescription = () => (
    <div>
      <p className={style.description}>
        {Wahanda.Template.render(lang.employeeMappingDescription, {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          externalSystemName: this.props.externalSalonSystem.name,
        })}
      </p>
      <div className={style.stepsSeparator} />
    </div>
  );

  renderEmployeeMapping = () => (
    <EmployeesMapping
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      externalSystemName={this.props.externalSalonSystem.name}
      readOnly={this.isStep(FINISH_STEP)}
      hideHeader={this.isStep(FINISH_STEP)}
      hideDescription={this.isStep(FINISH_STEP)}
    />
  );

  renderEmployeeMappingFooter = () => (
    <div>
      <div className={style.separator} />
      <span className={style.cancelButtonContainer}>
        <Button
          onClick={this.onCancelEmployeeMappingClick}
          label={lang.cancel}
          variant="secondary"
        />
      </span>
      <Button
        onClick={this.onSaveClickDebounced}
        label={lang.confirm}
        colour="plain"
        disabled={this.isSaveButtonDisabled()}
      />
    </div>
  );

  render() {
    const { loading } = this.props;
    return (
      <div>
        <div className={style.integrations}>
          <div className={style.left}>
            <div className={style.integrationsIconContainer}>
              <Sync />
            </div>
          </div>
          <div className={style.right}>
            <h1 className={style.header}>{lang.header}</h1>
            {this.isMaxStep(TEST_CONNECTION_STEP) && this.renderExternalSystemSelection()}
            {this.isStep(TEST_CONNECTION_STEP) && this.renderTestConnection()}
            {this.isStep(FINISH_STEP) && this.renderFinishStepHeader()}
            {this.isStep(EMPLOYEES_MAPPING_STEP) && this.renderEmployeeMappingDescription()}
            {this.isMinStep(EMPLOYEES_MAPPING_STEP) && this.renderEmployeeMapping()}
            {this.isStep(EMPLOYEES_MAPPING_STEP) && this.renderEmployeeMappingFooter()}
          </div>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'cancelConfirmationDialogVisible' does no... Remove this comment to see the full error message */}
          {this.state.cancelConfirmationDialogVisible &&
            this.renderConfirmDialog(lang.cancelConfirm, this.onCancelConfirm)}
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'removeConfirmationDialogVisible' does no... Remove this comment to see the full error message */}
          {this.state.removeConfirmationDialogVisible &&
            this.renderConfirmDialog(lang.removeConfirm, this.onRemoveConfirm)}
        </div>
        {loading && <Loader />}
      </div>
    );
  }
}

export default ExternalSalonIntegration;
