import React from 'react';
import Wahanda from 'common/wahanda';
import Dialog from 'components/common/react-dialog';
import { Currency } from 'components/common/DataFormat';

import style from './BankPayment.scss';

interface Props {
  bankName: string;
  bankCode: string;
  bankAccountName: string;
  bankAccountNumber: string;
  paymentReference: string;
  closingBalance: number;
  onClose: () => void;
}

const LANG = Wahanda.lang;

export class BankPayment extends React.PureComponent<Props> {
  public render() {
    const {
      bankName,
      bankAccountName,
      bankAccountNumber,
      bankCode,
      closingBalance,
      paymentReference,
      onClose,
    } = this.props;

    return (
      <Dialog
        dataTest="bank-payment-modal"
        title={LANG.settings.supplier.payments.payNowDialog.title}
        onClose={onClose}
        width={600}
      >
        <div data-test="bank-payment-modal" className={style.header}>
          <h3 className={style.headline}>
            {LANG.settings.supplier.payments.payNowDialog.header.youOweUs}
          </h3>
          <h1 className={style.price}>
            <Currency amount={closingBalance} />
          </h1>
        </div>
        <div className={style.body}>
          <strong>{LANG.settings.supplier.bank.details}</strong>
          <div className={style.bankDetails}>
            <div className={style.bankDetailItem}>
              <span className={style.bankDetailTitle}>
                {LANG.settings.supplier.bank.labels.accountName}
              </span>
              <span className={style.bankDetailDescription}>{bankAccountName}</span>
            </div>
            <div className={style.bankDetailItem}>
              <span className={style.bankDetailTitle}>
                {LANG.settings.supplier.bank.labels.accountNumber}
              </span>
              <span className={style.bankDetailDescription}>{bankAccountNumber}</span>
            </div>
            <div className={style.bankDetailItem}>
              <span className={style.bankDetailTitle}>
                {LANG.settings.supplier.payments.payNowDialog.bankDetails.amount}
              </span>
              <span className={style.bankDetailDescription}>
                <Currency amount={closingBalance} />
              </span>
            </div>
            <div className={style.bankDetailItem}>
              <span className={style.bankDetailTitle}>
                {LANG.settings.supplier.payments.payNowDialog.bankDetails.paymentReference}
              </span>
              <span className={style.bankDetailDescription}>{paymentReference}</span>
            </div>
            <div className={style.bankDetailItem}>
              <span className={style.bankDetailTitle}>
                {LANG.settings.supplier.bank.labels.bankCode}
              </span>
              <span className={style.bankDetailDescription}>{bankCode}</span>
            </div>
            <div className={style.bankDetailItem}>
              <span className={style.bankDetailTitle}>
                {LANG.settings.supplier.bank.labels.bankName}
              </span>
              <span className={style.bankDetailDescription}>{bankName}</span>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
