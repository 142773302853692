/* eslint-disable func-names */
/* global _ */
import { trackEvent } from 'common/analytics';

/**
 * Employee category form view.
 */
App.Views.Forms.EmployeeCategory = BackboneEx.View.Dialog.ValidatingForm.extend({
  events: {
    'change input[type=checkbox]': 'onToggle',
    'click .button-cancel': 'cancel',
    'click .delete-action': 'del',
  },

  options: {
    width: 540,
    title: Wahanda.lang.settings.employees.categoryPopupTitle,
  },

  templateId: 'employee-category-form',

  postInitialize: function () {
    App.Views.Forms.EmployeeCategory.__super__.postInitialize.call(this);

    this.employeesCollection = new App.Collections.Employees();
    this.employeesCollection.onlyActive = null;
    this.employeesCollection.onlyTakingAppointments = true;
  },

  initForm: function () {
    const self = this;

    if (this.model && this.model.id) {
      this.$('#cat-name').val(this.model.get('name'));
    } else {
      this.$('.button-secondary').hide();
    }

    this.$el.loadmask();
    this.employeesCollection.fetch({
      success: function () {
        self.renderEmployees();
        self.$el.unloadmask();
        self.$('.b-show-after-render').wShow();
      },
    });
  },

  renderEmployees: function () {
    const data = [];
    const ourCategoryId = this.model.id;
    const categories = this.collection;
    this.employeesCollection.each(function (employee) {
      // Render only active employees
      if (!employee.get('active')) {
        return;
      }

      let categoryName = null;
      const employeeCatId = employee.get('employeeCategoryId');
      const on = ourCategoryId === employeeCatId;
      let stateCheck = '';

      if (employeeCatId && ourCategoryId !== employeeCatId) {
        const cat = categories.get(employeeCatId);
        if (cat) {
          categoryName = cat.get('name');
        }
      }
      // With this we check if the warning text should be shown.
      if (on) {
        stateCheck = 'on';
      } else if (employeeCatId) {
        stateCheck = 'off';
      }
      data.push({
        employeeId: employee.id,
        employeeName: employee.get('name'),
        initialCategoryName: categoryName,
        on: on,
        validState: stateCheck,
      });
    });

    this.$('.b-employees').html(
      Wahanda.Template.renderTemplate('employee-category-form-items', {
        editMode: !!this.model.id,
        list: data,
      }),
    );
  },

  setValidation: function () {
    const self = this;
    this.$('form').validate(
      Wahanda.Validate.getValidations('EmployeeCategory', {
        submitHandler: function () {
          self.save();
        },
      }),
    );
  },

  getErrorTooltipBody: function () {
    return Wahanda.lang.settings.employees.cantDeleteTooltip;
  },

  getConstraintErrors: function (data) {
    const errors = {};

    _.each(data.errors, function (error) {
      if (error.name === 'employee-category-name-in-use') {
        errors.name = Wahanda.lang.settings.employees.categories.errors.duplicateName;
      }
    });

    return errors;
  },

  _saveAction: function () {
    this._defaultSaveAction({
      doNotClose: true,
    });
  },

  _afterSaveCallback: function (saveData) {
    trackEvent('pricing-levels', 'submit', 'save-pricing-levels');
    const self = this;
    BackboneEx.View.Dialog.ValidatingForm.prototype._afterSaveCallback.call(this, saveData);

    const onSaveCallback = function () {
      let eventName = Wahanda.Event.EMPLOYEE_CATEGORY_UPDATED;
      if (saveData.isNew) {
        eventName = Wahanda.Event.EMPLOYEE_CATEGORY_CREATED;
      }
      App.trigger(eventName, {
        employeeCategoryId: self.model.id,
      });

      self.close();
    };

    this.saveEmployees(onSaveCallback);
  },

  _afterDeleteCallback: function () {
    App.trigger(Wahanda.Event.EMPLOYEE_CATEGORY_DELETED, {
      employeeCategoryId: this.model.id,
    });
  },

  saveEmployees: function (onSuccess) {
    const toSave = [];
    const employees = this.employeesCollection;
    const ourCatId = this.model.id;
    this.$('.b-employees')
      .find('input')
      .each(function () {
        const $input = $(this);
        const employeeId = parseInt($input.val(), 10);
        const checked = $input.prop('checked');
        const employee = employees.get(employeeId);

        if (!checked && employee.get('employeeCategoryId') === ourCatId) {
          // If this employee was assigned to this category, and is now unassigned
          employee.set('employeeCategoryId', null);
          toSave.push(employee);
        } else if (checked && employee.get('employeeCategoryId') !== ourCatId) {
          // If this is a newly assigned employee
          employee.set('employeeCategoryId', ourCatId);
          toSave.push(employee);
        }
      });

    if (toSave.length > 0) {
      const len = toSave.length;
      onSuccess = _.after(len, onSuccess);
      for (let i = 0; i < len; i++) {
        toSave[i].save(null, {
          success: onSuccess,
        });
      }
    } else {
      // Nothing to save. Close the dialog.
      onSuccess();
    }
  },

  getChangedEmployees: function () {
    const data = [];

    this.$('.b-employees')
      .find('input')
      .each(function () {
        const $input = $(this);
        const check = $input.data('valid');
        if (check) {
          const shouldBeChecked = check === 'on';
          const isAdded = $input.prop('checked');
          if (shouldBeChecked !== isAdded) {
            const id = parseInt($input.val(), 10);
            data.push({
              employeeId: id,
              added: isAdded,
            });
          }
        }
      });

    return data;
  },

  toggleWarningText: function () {
    const show = this.getChangedEmployees().length > 0;
    this.$('.b-categories-changed').toggle(show);
  },

  /**
   * Custom deletion function.
   *
   * It first unassigns any assigned employees to it, and only then archives this employee cat.
   */
  del: function () {
    trackEvent('pricing-levels', 'submit', 'delete');
    const self = this;
    let categoryDelete = function () {
      App.Views.Forms.EmployeeCategory.__super__.del.call(self);
    };
    const assignedEmployees = this.employeesCollection.where({
      employeeCategoryId: this.model.id,
    });

    const len = assignedEmployees.length;
    if (len > 0) {
      // Make this function execute only when all employees are unset
      categoryDelete = _.after(len, categoryDelete);

      for (let i = 0; i < len; i++) {
        assignedEmployees[i].set({
          employeeCategoryId: null,
        });
        assignedEmployees[i].save(null, {
          success: categoryDelete,
        });
      }
    } else {
      categoryDelete();
    }
  },

  // UI events

  onToggle: function (event) {
    const $input = $(event.currentTarget);
    const isOn = $input.prop('checked');
    const $li = $input.closest('li');
    // First, change the UI display
    $li.toggleClass('on', isOn).toggleClass('off', !isOn);
    // Second, toggle the "Category will be changed" text
    $li.find('.b-category-note').toggleClass('hidden', isOn);
    $li.find('.b-warning').toggleClass('hidden', !isOn);
    // And togle the warning text
    this.toggleWarningText();
  },
});
