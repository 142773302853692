import React from 'react';
import { DraftBlockType, Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

import { InlineStyleControls } from './InlineStyleControls';
import { BlockStyleControls } from './BlockStyleControls';

import legacyStyle from './RichTextEditor.scss';
import newLookStyle from './RichTextEditor.newLook.scss';
import classNames from 'classnames';
import { InputError } from '../__BaseCommon';
import { OrderedSet } from 'immutable';
import { FlipTabletBanner } from './FlipTabletBanner';

interface Props {
  onTextChanged: (params) => void;
  placeholder?: string;
  text?: string;
  value?: string;
  fullBorder?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  name?: string;
  dataTest?: string;
  fullWidth?: boolean;
  newLook?: boolean;
}

interface State {
  editorState: any;
}

const STYLES = ['BOLD', 'ITALIC', 'UNDERLINE'];

export class RichTextEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    if (props.text) {
      const contentState = props.text;
      this.state = {
        editorState: EditorState.createWithContent(stateFromHTML(contentState)),
      };
      return;
    }

    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  public static defaultProps = {
    placeholder: '',
    text: null,
    onTextChanged: () => null,
  };

  private onChange = (editorState) => {
    this.setState({ editorState });
    this.handleEmptyContent(editorState);
  };

  /*
   * This is needed for handling empty state
   * i.e. if no meaningful content is detected - don't store HTML tags
   * */
  private handleEmptyContent = (editorState) => {
    const content = stateToHTML(editorState.getCurrentContent());
    const contentWithNoHTML = content.replace(/<\/?[^>]+(>|$)/g, '');
    if (contentWithNoHTML.length === 0) {
      this.props.onTextChanged('');
      return;
    }
    this.props.onTextChanged(content);
  };

  private resetAllStyles = (e) => {
    e.preventDefault();
    const { editorState } = this.state;

    const selection = editorState.getSelection();

    const contentState = editorState.getCurrentContent();

    // Remove all block types (e.g., headers, paragraphs)
    const withoutBlockTypes = Modifier.setBlockType(contentState, selection, 'unstyled');
    // Create a new editor state with the modified content
    const newEditorState = EditorState.push(editorState, withoutBlockTypes, 'change-block-data');

    // Remove all inline styles from selected content
    const contentWithoutStyles = STYLES.reduce(
      (newContentState, style) => Modifier.removeInlineStyle(newContentState, selection, style),
      newEditorState.getCurrentContent(),
    );

    let finalEditorState = EditorState.push(
      editorState,
      contentWithoutStyles,
      'change-inline-style',
    );

    // Finally, if the selection is empty, remove all inline styles from new insert
    if (selection.isCollapsed()) {
      finalEditorState = EditorState.setInlineStyleOverride(finalEditorState, OrderedSet.of());
    }

    this.onChange(finalEditorState);
  };

  private toggleInlineStyle = (inlineStyle: string) => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  };

  private toggleBlockType = (blockType: DraftBlockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  public render() {
    const { editorState } = this.state;
    const style = this.props.newLook ? newLookStyle : legacyStyle;
    return (
      <>
        <div
          className={classNames(style.container, {
            [style.fullBorder]: this.props.fullBorder,
            [style.error]: this.props.hasError,
            [style.fullWidth]: this.props.fullWidth,
          })}
        >
          <FlipTabletBanner />
          <div className={style.controls}>
            <div
              className={classNames(style.normalText, 'rich-text-editor-button-normal')}
              onMouseDown={(e) => this.resetAllStyles(e)}
            />
            <InlineStyleControls editorState={editorState} onToggle={this.toggleInlineStyle} />
            <BlockStyleControls editorState={editorState} onToggle={this.toggleBlockType} />
          </div>
          <div className={style.editor}>
            <Editor
              placeholder={this.props.placeholder}
              editorState={editorState}
              onChange={this.onChange}
            />
          </div>
        </div>
        {this.props.hasError && this.props.errorMessage && (
          <InputError dataTest={this.props.dataTest} message={this.props.errorMessage} />
        )}
      </>
    );
  }
}
