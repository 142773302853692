import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Reload extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-reload', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M12,5 L12,2.99703014 C12,2.48930256 12.3253751,2.27772582 12.7433709,2.50151381 L12.8342743,2.55618286 L17.1657257,5.44381714 C17.5918912,5.72792745 17.6238918,6.1786791 17.2573812,6.4875612 L17.1657257,6.55618286 L12.8342743,9.44381714 C12.4059234,9.72938442 12.0504152,9.57753839 12.0049179,9.10832866 L12,9.00296986 L12,7 C8.6862915,7 6,9.6862915 6,13 C6,16.3137085 8.6862915,19 12,19 C15.3137085,19 18,16.3137085 18,13 L20,13 C20,17.418278 16.418278,21 12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 L12,2.99703014 L12,5 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Reload);
