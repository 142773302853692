import React from 'react';
import Wahanda from 'common/wahanda';
import ReactDialog from 'components/common/react-dialog';
import Loader from 'components/common/Loader';
import { StateCard } from 'components/common/StateCard';
import { SmartDiscount } from 'components/common/IconPicture';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import LinkToPreview from '../LinkToPreview';
import LinkContent from '../LinkToPreview/linkContent';
import style from './style.scss';

const lang = Wahanda.lang.marketing.campaignSelection.promotionalOffers.smartDiscounts;
const PREVIEW_URL = 'email-campaign/smart-discounts';
interface ISmartDiscountSelectProps extends React.HTMLAttributes<Element> {
  onClose: (...args: any[]) => any;
  isLoaded: boolean;
  actions: {
    getDiscountsRulesRequest: (...args: any[]) => any;
    selectDiscount: (...args: any[]) => any;
  };
  smartDiscounts?: {
    id: number;
    name: string;
    active: boolean;
    lastMinuteDiscount?: {
      active: boolean;
    };
    offpeakDiscounts?: {
      active: boolean;
    };
    assignedMenuItems?: {
      id: number;
    }[];
  }[];
  map?: any;
  length?: any;
  selectDiscount?: any;
  getDiscountsRulesRequest?: any;
}

export default class SmartDiscountSelect extends React.Component<ISmartDiscountSelectProps, {}> {
  static defaultProps = {
    smartDiscounts: null,
  };

  componentDidMount = () => {
    this.props.actions.getDiscountsRulesRequest();
  };

  onLinkClickHandler = (selectedDiscountRuleId) => {
    this.props.actions.selectDiscount({ selectedDiscountRuleId });
  };

  getLastMinuteDiscount = (lastMinuteDiscount) => {
    if (!lastMinuteDiscount.active) {
      return null;
    }
    return Wahanda.Template.render(lang.lastMinuteDiscount, {
      lastMinuteDiscount: lastMinuteDiscount.percentage,
    });
  };

  getOffPeakDiscount = (offpeakDiscount) => {
    if (!offpeakDiscount.active) {
      return null;
    }
    return Wahanda.Template.render(lang.offPeakDiscount, {
      maxOffPeakDiscount: offpeakDiscount.max,
    });
  };

  redirectToDiscountsPage = () => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Location'... Remove this comment to see the full error message
    window.location = `menu#venue/${App.getVenueId()}/discounts`;
  };

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  noDiscountsToShow = () => this.props.smartDiscounts.length === 0;

  renderEmptyState = () => (
    <React.Fragment>
      <div className={style.emptyState}>
        <StateCard
          heading={lang.emptyState.title}
          message={lang.emptyState.description}
          image={<SmartDiscount />}
        />
      </div>
      <DialogFooter>
        <DialogFooterButton title={lang.emptyState.button} onClick={this.redirectToDiscountsPage} />
      </DialogFooter>
    </React.Fragment>
  );

  renderSmartDiscountsList = () => (
    <div className={style.dialogContent}>
      {this.renderText()}
      <div className={style.box}>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {this.props.smartDiscounts.map((discount) => (
          <LinkToPreview key={discount.name} url={`${PREVIEW_URL}/${discount.id}`}>
            <LinkContent>{this.renderDiscount(discount)}</LinkContent>
          </LinkToPreview>
        ))}
      </div>
    </div>
  );

  renderDiscount = (discount) => (
    <div className={style.discount}>
      <span className={style.name}>{discount.name}</span>
      <span className={style.description}>
        {this.getOffPeakDiscount(discount.offpeakDiscounts)}
        {this.getLastMinuteDiscount(discount.lastMinuteDiscount)}
        {Wahanda.Template.render(lang.appliedTo, {
          count: discount.assignedMenuItems.length,
        })}
      </span>
    </div>
  );

  renderText = () => (
    <div className={style.textContainer}>
      <span className={style.header}>{lang.header}</span>
      <span className={style.description}>{lang.description}</span>
    </div>
  );

  renderDialogContent = () =>
    this.noDiscountsToShow() ? this.renderEmptyState() : this.renderSmartDiscountsList();

  render() {
    const { onClose, isLoaded } = this.props;
    return (
      <ReactDialog
        dataTest="email-campaign-smart-discount-modal"
        title={lang.title}
        onClose={onClose}
        noContentPadding
      >
        {isLoaded ? (
          this.renderDialogContent()
        ) : (
          <div className={style.loader}>
            <Loader />
          </div>
        )}
      </ReactDialog>
    );
  }
}
