/* eslint-disable import/no-default-export */
import React from 'react';
import App from 'common/backbone-app';
import { get, curry, assocIn, hashMap, merge, updateIn, toClj, count, equals } from 'mori';
import { appState } from 'state';
import uuid from 'uuid';
import keycode from 'keycode';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import { trackEvent } from 'common/analytics';
import { FuzzySearchInput } from './FuzzySearchInput';
import getSelectedForVector from './helpers';

const valueOfSelectedForVector = (data, value) => get(getSelectedForVector(data, value), 'value');
const toMoriData = (data) => (Array.isArray(data) ? toClj(data) : data);
interface ISelectDropdownProps extends React.HTMLAttributes<Element> {
  data?:
    | {}
    | {
        name: string;
        value?: string | number;
      }[];
  dataTest?: string;
  selected?: any;
  onSelect?: (...args: any[]) => any;
  disabled?: boolean;
  typeahead?: boolean;
  onStateChange?: (...args: any[]) => any;
  noChangeEventValue?: any;
  extraClassName?: string;
  optionExtraClassName?: string;
  onOpen?: (...args: any[]) => any;
}
type SelectDropdownState = {
  id?: any;
  selected?: any;
  selectedValue?: any;
  open?: boolean;
  defaultValue?: string;
  stateSelected?: any;
  stateSelectedValue?: any;
  wasSelected?: any;
};

export class SelectDropdown extends React.Component<ISelectDropdownProps, SelectDropdownState> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'mainNode' has no initializer and is not ... Remove this comment to see the full error message
  mainNode: HTMLDivElement;

  textInput: any;

  static defaultProps = {
    extraClassName: '',
    optionExtraClassName: '',
    onOpen: null,
    typeahead: null,
    onStateChange: null,
    noChangeEventValue: null,
    disabled: null,
    onSelect: null,
    selected: null,
    data: null,
    dataTest: null,
  };

  constructor(props, p2, p3) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 3.
    super(props, p2, p3);
    const { selected: value } = props;
    let { data } = props;
    data = toMoriData(data);
    const selected = getSelectedForVector(data, value);
    const selectedValue = get(selected, 'value');
    this.state = {
      id: uuid.v1(),
      selected,
      selectedValue,
      open: false,
      defaultValue: '',
    };
  }

  componentDidMount() {
    const { id, selected, selectedValue } = this.state;
    let { data } = this.props;
    data = toMoriData(data);
    // Initialize the dropdown
    appState.swap(
      curry(
        assocIn,
        ['select-dropdown', id],
        hashMap(
          'selected',
          selected,
          'selectedValue',
          selectedValue,
          'data',
          data,
          'open',
          false,
          'parentBoundingRect',
          null,
          'onSelect',
          this.onSelect,
          'isTypeahead',
          false,
        ),
      ),
    );
  }

  UNSAFE_componentWillReceiveProps({ data: dataFromProp, selected: value }) {
    /*
     *  We can avoid frequent renders by testing these props here,
     *  and not putting new props into state if they are the same as
     *  the current props -- but actually: we don't want to. We want
     *  to force a render each time (for now)
     */
    const data = toMoriData(dataFromProp);
    const selected = getSelectedForVector(data, value);
    const selectedValue = get(selected, 'value');
    this.setState({
      selected,
      selectedValue,
    });
    // Forward maybe new data to the Dropdown
    const { id, stateSelected, stateSelectedValue } = this.state;
    appState.swap(
      curry(updateIn, ['select-dropdown', id], (oldData) =>
        merge(
          oldData,
          hashMap('selected', stateSelected, 'selectedValue', stateSelectedValue, 'data', data),
        ),
      ),
    );
  }

  componentDidUpdate(oldProps, oldState) {
    const { selectedValue } = this.state;
    if (this.textInput) {
      this.textInput.focus();
      // If they're tabbing away and not changing the value, allow the browser
      // to continue to the next field.
    } else if (this.justClosed && oldState.selectedValue !== selectedValue) {
      this.justClosed = false;
      this.mainNode.focus();
    }
  }

  componentWillUnmount() {
    appState.swap(curry(assocIn, ['select-dropdown', this.state.id], null));
  }

  onSelect = (selected) => {
    const { selected: wasSelected } = this.state;
    const nowSelected = selected || wasSelected;
    const hasChanged = !equals(wasSelected, nowSelected);
    /*
     *  Always close the dropdown
     */
    this.closeDropdown();
    /*
     *  But the rest of the work is dependent on the selected value
     *  being different, so ...
     */
    if (hasChanged) {
      const { noChangeEventValue } = this.props;
      /*
       *  Get the currently selected value
       */
      const selectedValue = get(nowSelected, 'value');
      /*
       *  Update the component's state with that value (and the select)
       */
      this.setState({
        selected: nowSelected,
        selectedValue,
      });
      /*
       *  Notify any change listeners of the change
       */
      if (selectedValue !== noChangeEventValue) {
        this.raiseChangeEvent();
      }
      /*
       *  Notify tracking if user has selected the value when the search input was used.
       */
      if (this.textInput && this.textInput.input.value) {
        this.trackChangeEvent(selectedValue, this.textInput.input.value);
      }
      /*
       *  Last but not least: notify controlling component
       */
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onSelect(selectedValue);
    }
  };

  onSearchResults = (results, selected) => {
    const searchResults = (() => {
      if (count(results) === 0) {
        return toClj([
          {
            name: Wahanda.lang.shared.noMatches,
            value: null,
            grouping: 'no-results',
          },
        ]);
      }
      return results;
    })();
    const { id } = this.state;
    const selectedValue = get(selected, 'value');
    appState.swap(
      curry(updateIn, ['select-dropdown', id], (oldData) =>
        merge(
          oldData,
          hashMap('selected', selected, 'selectedValue', selectedValue, 'data', searchResults),
        ),
      ),
    );
  };

  static getInitialValue(data, value) {
    return valueOfSelectedForVector(toMoriData(data), value);
  }

  raiseChangeEvent() {
    const event = document.createEvent('Event');
    event.initEvent('change', true, true);
    this.mainNode.dispatchEvent(event);
  }

  trackChangeEvent(selectedValue, typedValue) {
    // eslint-disable-line class-methods-use-this
    trackEvent('service-search', 'click', 'selected', {
      userTyped: typedValue,
      userSelected: selectedValue,
    });
  }

  justClosed = false;

  updateDropdownState(isOpen, isTypeahead, boudingRect, results) {
    const { id, selected, selectedValue } = this.state;
    appState.swap(
      curry(
        updateIn,
        ['select-dropdown', id],
        curry(
          merge,
          hashMap(
            'open',
            isOpen,
            'parentBoundingRect',
            boudingRect,
            'selected',
            selected,
            'selectedValue',
            selectedValue,
            'isTypeahead',
            isTypeahead,
            'typeaheadResults',
            results,
            'typeaheadInput',
            this.mainNode,
            'optionExtraClassName',
            this.props.optionExtraClassName,
          ),
        ),
      ),
    );
    this.setState({
      open: isOpen,
    });
    if (this.props.onStateChange) {
      this.props.onStateChange({ isOpen });
    }
  }

  openDropdown = () => {
    // Do not try to open an already opened dropdown...
    if (this.state.open) {
      return;
    }
    // This is where we decide if we want to also show the typeahead
    const rect = this.mainNode ? this.mainNode.getBoundingClientRect() : null;
    const isTypeahead = this.props.typeahead;
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
    this.updateDropdownState(true, isTypeahead, rect);
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  closeDropdown() {
    this.justClosed = true;
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
    this.updateDropdownState(false, false, null);
    this.setState({ defaultValue: '' }); // eslint-disable-line
  }

  handleKey = (event) => {
    const key = keycode(event);
    const allowOpenIfAlphaPressed = !!this.props.typeahead;
    if (this.state.open && ['enter', 'up', 'down'].indexOf(key) !== -1) {
      event.preventDefault();
    }
    // Open the dropdown when this component is tabbed on and a key is pressed
    if (
      !this.state.open &&
      !event.defaultPrevented &&
      (['up', 'down'].indexOf(key) !== -1 || allowOpenIfAlphaPressed)
    ) {
      const defaultValue = event.key.length > 1 ? '' : event.key;
      this.setState({ defaultValue }); // eslint-disable-line
      event.preventDefault();
      this.openDropdown();
    }
  };

  render() {
    const { selected, open } = this.state;
    const { dataTest, disabled, typeahead, extraClassName } = this.props;
    const classes = classnames('select-dropdown', 'no-autosubmit', { disabled }, extraClassName);
    const handleClick = disabled ? null : this.openDropdown;
    const handleKeyDown = disabled ? null : this.handleKey;
    const divContent = (() => {
      if (typeahead && open) {
        return (
          <FuzzySearchInput
            ref={(textInput) => {
              this.textInput = textInput;
            }}
            data={toMoriData(this.props.data)}
            originalSelected={selected}
            onResults={this.onSearchResults}
            defaultValue={this.state.defaultValue}
          />
        );
      }
      const primaryText = get(selected, 'name');
      const secondaryText = get(selected, 'secondaryText');
      const hasSecondaryText = !!secondaryText;
      const primaryClassNames = classnames('display', get(selected, 'primaryClass'));
      const secondaryClassNames = classnames('secondaryText', get(selected, 'secondaryClass'));
      return hasSecondaryText ? (
        <div className={primaryClassNames}>
          {primaryText}
          &nbsp;
          <span className={secondaryClassNames}>{`(${secondaryText})`}</span>
        </div>
      ) : (
        <div className={primaryClassNames}>{primaryText}</div>
      );
    })();
    if (!App.isProd) {
      console.warn('SelectDropdown component is deprecated. Use Select component');
    }
    return (
      <div
        className={classes}
        ref={(mainNode) => {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLDivElem... Remove this comment to see the full error message
          this.mainNode = mainNode;
        }}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '((event: Ke... Remove this comment to see the full error message
        onKeyDown={handleKeyDown}
        data-open={open ? 'true' : null}
        data-test={dataTest}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '((event: Mo... Remove this comment to see the full error message
        onClick={handleClick}
        tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
      >
        {divContent}
      </div>
    );
  }
}

export default SelectDropdown;
