import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Birthday extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-birthday', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 16 16"
      >
        <path d="M11,4 L13,4 L13,7 L14.0059397,7 C15.1072288,7 16,7.89585781 16,8.9973917 L16,16 L0,16 L0,8.9973917 C0,7.89426273 0.894513756,7 1.99406028,7 L3,7 L3,4 L5,4 L5,7 L7,7 L7,4 L9,4 L9,7 L11,7 L11,4 Z M14,12.118 L12,13.118034 L10,12.118034 L8,13.118034 L6,12.118034 L4,13.118034 L2,12.118 L2,14 L14,14 L14,12.118 Z M14,9 L2,9 L2,9.882 L4,10.881966 L6,9.88196601 L8,10.881966 L10,9.88196601 L12,10.881966 L14,9.881 L14,9 Z M4,0 C4.25,0 5,1.1372583 5,1.8 C5,2.4627417 4.55228475,3 4,3 C3.44771525,3 3,2.4627417 3,1.8 C3,1.1372583 3.75,0 4,0 Z M8,0 C8.25,0 9,1.1372583 9,1.8 C9,2.4627417 8.55228475,3 8,3 C7.44771525,3 7,2.4627417 7,1.8 C7,1.1372583 7.75,0 8,0 Z M12,0 C12.25,0 13,1.1372583 13,1.8 C13,2.4627417 12.5522847,3 12,3 C11.4477153,3 11,2.4627417 11,1.8 C11,1.1372583 11.75,0 12,0 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Birthday);
