import { ActionUnionTypes } from 'reduxStore/actionCreator';
import { OPEN_DIALOG, CLOSE_DIALOG } from 'reduxStore/actionsConst';

import * as dialogActions from './actions';
import { DialogTypes } from './types';

export interface RootTransactionDialogReducer {
  dialog: DialogTypes | null;
}

export const initialState: RootTransactionDialogReducer = {
  dialog: null,
};

export const rootTransactionDialogReducer = (
  state = initialState,
  action: ActionUnionTypes<typeof dialogActions>,
): RootTransactionDialogReducer => {
  switch (action.type) {
    case OPEN_DIALOG:
      return { dialog: action.payload.dialogType };
    case CLOSE_DIALOG:
      return initialState;
    default:
      return state;
  }
};
