import { ACTIONS, trackEvent } from 'common/analytics';

export class ReportsDashboardAnalytics {
  static CATEGORY = 'reports_dashboard';

  static trackCoronaReportClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'corona_report');
  }

  static trackSalesOverviewLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'sales-overview-link');
  }

  static trackServiceSalesLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'service-sales-link');
  }

  static trackProductSalesLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'product-sales-link');
  }

  static trackSalesByEmployeeLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'sales-by-employee-link');
  }

  static trackSalesByChannelLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'sales-by-channel-link');
  }

  static trackDailySalesLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'daily-sales-link');
  }

  static trackMonthlySalesLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'monthly-sales-link');
  }

  static trackVatReportLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'vat-link');
  }

  static trackYearPosReportLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'pos-year-link');
  }

  static trackMonthPosReportLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'pos-month-link');
  }

  static trackOldSalesReportLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'old-sales-link');
  }

  static trackClientRetentionLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'client-retention-link');
  }

  static trackListOfBookingsLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'list-of-bookings-link');
  }

  static trackTransactionReportLinkClick() {
    trackEvent(ReportsDashboardAnalytics.CATEGORY, ACTIONS.CLICK, 'transaction-report-link');
  }
}
