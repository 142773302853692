import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import SelectDropdown from 'components/common/SelectDropdown';
import {
  MORNING_KEY,
  AFTERNOON_KEY,
  EVENING_KEY,
  PERCENTAGE_VECTORS,
  PERCENTAGE_KEYS,
  DAYSOFWEEK_KEYS,
} from './constants';
import style from './style.scss';

const lang = Wahanda.lang.menu.services.offPeak;
const { morning: MORNING, afternoon: AFTERNOON, evening: EVENING } = lang.parts;
const { venueClosed: VENUE_CLOSED } = lang.texts;
const {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
} = Wahanda.lang.date.weekdaysByName;
const createMorningQualifierText = (morningEnd) =>
  Wahanda.Template.render(lang.times.until, { morningEnd });
const createAfternoonQualifierText = (morningEnd, eveningStart) =>
  Wahanda.Template.render(lang.times.between, {
    morningEnd,
    eveningStart,
  });
const createEveningQualifierText = (eveningStart) =>
  Wahanda.Template.render(lang.times.andAfter, { eveningStart });
// eslint-disable-next-line react/no-multi-comp
const renderMorning = (morning) => (
  <div className={style.label}>
    <span className={style.timeOfDay}>{MORNING}</span>
    {'\u00A0'}
    <span className={style.qualifier}>{`(${createMorningQualifierText(morning)})`}</span>
  </div>
);
// eslint-disable-next-line react/no-multi-comp
const renderAfternoon = (morning, evening) => (
  <div className={style.label}>
    <span className={style.timeOfDay}>{AFTERNOON}</span>
    {'\u00A0'}
    <span className={style.qualifier}>{`(${createAfternoonQualifierText(morning, evening)})`}</span>
  </div>
);
// eslint-disable-next-line react/no-multi-comp
const renderEvening = (evening) => (
  <div className={style.label}>
    <span className={style.timeOfDay}>{EVENING}</span>
    {'\u00A0'}
    <span className={style.qualifier}>{`(${createEveningQualifierText(evening)})`}</span>
  </div>
);
// eslint-disable-next-line react/no-multi-comp
const renderPercentageSelect = (map, per, disabled) => (
  <SelectDropdown
    data={PERCENTAGE_VECTORS}
    selected={map.get(per)}
    onSelect={(value) => {
      map.set(per, value);
    }}
    disabled={disabled}
  />
);
// eslint-disable-next-line react/no-multi-comp
const renderVenueOpen = (map, day, per, disabled) =>
  renderPercentageSelect(map.get(day), per, disabled);
// eslint-disable-next-line react/no-multi-comp
const renderVenueShut = () => <div className={style.venueClosed}>{VENUE_CLOSED}</div>;
const renderTimeOfDay = (timeOfDay, morning, evening) => {
  switch (timeOfDay) {
    case MORNING_KEY:
      return renderMorning(morning);
    case AFTERNOON_KEY:
      return renderAfternoon(morning, evening);
    case EVENING_KEY:
      return renderEvening(evening);
    default:
      return null;
  }
};
const isOpen = (outer, day, per) => {
  if (outer.has(day)) {
    const inner = outer.get(day);
    if (inner.has(per)) {
      return inner.get(per) !== null;
    }
  }
  return false;
};
const key = (day, per) => `${day}-${per}`;
type AppointmentsProps = {
  map: any;
  morning: string;
  evening: string;
  disabled: boolean;
};
const Appointments: React.SFC<AppointmentsProps> = ({ map, morning, evening, disabled }) => (
  <table className={classnames('appointments', { [style.appointments]: true })}>
    <thead>
      <tr>
        <th>{'\u00A0'}</th>
        <th className={style.day}>
          <span>{MONDAY}</span>
        </th>
        <th className={style.day}>
          <span>{TUESDAY}</span>
        </th>
        <th className={style.day}>
          <span>{WEDNESDAY}</span>
        </th>
        <th className={style.day}>
          <span>{THURSDAY}</span>
        </th>
        <th className={style.day}>
          <span>{FRIDAY}</span>
        </th>
        <th className={style.day}>
          <span>{SATURDAY}</span>
        </th>
        <th className={style.day}>
          <span>{SUNDAY}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      {PERCENTAGE_KEYS.map((per) => (
        <tr key={per}>
          <th className={style.day}>{renderTimeOfDay(per, morning, evening)}</th>
          {DAYSOFWEEK_KEYS.map((day) =>
            isOpen(map, day, per) ? (
              <td key={key(day, per)} className={style.venueOpen}>
                {renderVenueOpen(map, day, per, disabled)}
              </td>
            ) : (
              <td key={key(day, per)} className={style.venueShut}>
                {renderVenueShut()}
              </td>
            ),
          )}
        </tr>
      ))}
    </tbody>
  </table>
);

export default Appointments;
