import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../store/actions';
import { getEmployeesCollectionIds, getSelectedGroupService } from '../store/selectors';
import { ServiceRow as Component } from './ServiceRow';

const mapStateToProps = ({ menu: { menuTemplates } }, { menuGroupId, serviceId }) => {
  const employeesCollectionIds = getEmployeesCollectionIds({
    employeesCollection: menuTemplates.employeesCollection,
  });
  const service = getSelectedGroupService(menuTemplates, { menuGroupId, serviceId });

  return {
    employees: service?.employees || employeesCollectionIds,
    hasActiveEmployees: !!employeesCollectionIds.length,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ServiceRow = connect(mapStateToProps, mapDispatchToProps)(Component);
