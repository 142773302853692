import { trackEvent, ACTIONS } from 'common/analytics';

const CATEGORY = 'appointment';

export const AppointmentRepeatAnalytics = {
  trackRepeatClick: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'repeat');
  },
  trackComponentLoad: () => {
    trackEvent(CATEGORY, ACTIONS.VIEW, 'repeat');
  },
  trackRemoveClick: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'remove-repeat');
  },
  trackIntervalChange: (property: string, value: number) => {
    trackEvent(CATEGORY, ACTIONS.EDIT, 'repeat-interval', property, value);
  },
  trackCountChange: (property: number) => {
    trackEvent(CATEGORY, ACTIONS.EDIT, 'repeat-count', property);
  },
};
