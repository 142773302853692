import {
  GET_PRODUCTS_LIST_REQUEST,
  GET_PRODUCTS_LIST_RECEIVE,
  GET_PRODUCTS_LIST_RESET,
  GET_PRODUCTS_LIST_FAILURE,
} from 'reduxStore/actionsConst';

const initialState = {
  serverError: false,
  loading: false,
  items: [],
};

export default function products(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_PRODUCTS_LIST_RECEIVE:
      return {
        ...state,
        items: action.data,
        loading: false,
      };

    case GET_PRODUCTS_LIST_FAILURE:
      return {
        ...state,
        serverError: true,
        loading: false,
      };

    case GET_PRODUCTS_LIST_RESET:
      return initialState;

    default:
      return state;
  }
}
