export enum MessageType {
  REQUEST = 'request',
  RESPONSE = 'response',
  ERROR = 'error',
  EVENT = 'event',
}

export type Message =
  | { type: MessageType.REQUEST; reqId: string; name: string; data: unknown }
  | { type: MessageType.RESPONSE; reqId: string; data: unknown }
  | { type: MessageType.ERROR; reqId: string; error: unknown }
  | { type: MessageType.EVENT; name: string; data: unknown };

export interface TransporterDelegate {
  onRemoteEvent: (name: string, data: unknown) => void;
  onRemoteCall: (name: string, data: unknown) => Promise<unknown>;
}

/**
 * Class that will handle the communication between the host, the embedded app and Store.
 * This must be extended to provide the actual communication methods.
 */
export abstract class Transporter {
  private _responseListeners = new Map<string, (data: unknown, isError?: boolean) => void>();

  constructor(private delegate: TransporterDelegate) {
    // Call the _listen implementation in the next tick
    // to allow the child class to be fully initialized
    setTimeout(() => this._listen(), 0);
  }

  /**
   * Method that will start listening to messages from the host.
   * Implementation should call _onMessage when a message is received.
   */
  protected abstract _listen(): void;

  /**
   * Method that will send messages to the host
   */
  protected abstract _send(data: Message): void;

  /**
   * Method that should be called when a message is received from the host.
   * @param msg The message received from the host, already parsed
   */
  protected async _onMessage(msg: Message): Promise<void> {
    if (msg.type === MessageType.REQUEST) {
      try {
        const res = await this.delegate.onRemoteCall(msg.name, msg.data);
        this._send({
          type: MessageType.RESPONSE,
          reqId: msg.reqId,
          data: res,
        });
      } catch (err) {
        this._send({ type: MessageType.ERROR, reqId: msg.reqId, error: err });
      }
    } else if (msg.type === MessageType.RESPONSE) {
      this._responseListeners.get(msg.reqId)?.(msg.data);
      this._responseListeners.delete(msg.reqId);
    } else if (msg.type === MessageType.EVENT) {
      this.delegate.onRemoteEvent(msg.name, msg.data);
    } else if (msg.type === MessageType.ERROR) {
      this._responseListeners.get(msg.reqId)?.(msg.error);
      this._responseListeners.delete(msg.reqId);
    }
  }

  public emit<T>(name: string, data: T): void {
    this._send({ type: MessageType.EVENT, name, data });
  }

  public call<T, P = unknown>(name: string, data: P): Promise<T> {
    const reqId = Math.random().toString(36);

    return new Promise<T>((resolve, reject) => {
      this._responseListeners.set(reqId, (res, isError) => {
        if (isError) reject(res);
        resolve(res as T);
      });
      try {
        this._send({ type: MessageType.REQUEST, reqId, name, data });
      } catch (e) {
        this._responseListeners.delete(reqId);
        reject(e);
      }
    });
  }
}
