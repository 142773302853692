import React, { MouseEvent, ReactNode, useState } from 'react';
import style from './PhotoThumbnail.scss';
import { PlusIcon } from 'components/common/PhotoThumbnail/icons/PlusIcon';
import classNames from 'classnames';
import { CloseIcon } from 'components/common/PhotoThumbnail/icons/CloseIcon';
import { Spinner } from 'components/common/Spinner';
import { WarningIcon } from 'components/common/PhotoThumbnail/icons/WarningIcon';
import { EditIcon } from 'components/common/PhotoThumbnail/icons/EditIcon';
import { Tooltip } from 'components/common/Tooltip';

interface Props {
  addLabel?: string;
  editLabel?: string;
  removeLabel?: string;
  isEditable?: boolean;
  isRemovable?: boolean;
  error?: string;
  isLoading?: boolean;
  isPlaceholder?: boolean;
  photo?: {
    src: string;
    alt: string;
  };
  children?: ReactNode;
  onAdd?: () => void;
  onEdit?: () => void;
  onRemove?: () => void;
}

const Action = {
  ADD: 'add',
  EDIT: 'edit',
};

const PhotoStatus = {
  IDLE: 'idle',
  LOADING: 'loading',
  ERROR: 'error',
};

export const PhotoThumbnail = ({
  isPlaceholder,
  isLoading,
  error,
  isEditable = true,
  isRemovable = true,
  addLabel = 'Add',
  editLabel = 'Edit',
  removeLabel = 'Remove',
  photo,
  children,
  onAdd = () => {},
  onEdit = () => {},
  onRemove = () => {},
}: Props) => {
  const [status, setStatus] = useState(() => (photo ? PhotoStatus.LOADING : PhotoStatus.IDLE));

  const onErrorPhoto = () => setStatus(PhotoStatus.ERROR);
  const onLoadPhoto = () => setStatus(PhotoStatus.IDLE);

  const action = isPlaceholder ? '' : photo ? Action.EDIT : Action.ADD;
  const isPhotoEnabled = Boolean(photo) && !isPlaceholder && status !== PhotoStatus.ERROR;
  const isRemoveEnabled = Boolean(photo) && isRemovable;
  const isErrorEnabled = Boolean(error) || status === PhotoStatus.ERROR;
  const isEditEnabled = isEditable && !isErrorEnabled;

  return (
    <div
      className={classNames(style.container, {
        [style.isPlaceholder]: isPlaceholder,
        [style.isError]: isErrorEnabled,
        [style.isLoading]: status === PhotoStatus.LOADING,
      })}
    >
      {isPhotoEnabled && (
        <img src={photo!.src} alt={photo!.src} onLoad={onLoadPhoto} onError={onErrorPhoto} />
      )}
      {isLoading && (
        <div className={style.loading}>
          <Spinner />
        </div>
      )}
      {children}
      {isErrorEnabled && (
        <div className={style.error}>
          <Tooltip placement="rigth" tooltip={error ?? 'Error on loading photo'}>
            <WarningIcon />
          </Tooltip>
        </div>
      )}
      {
        {
          [Action.ADD]: (
            <button aria-label={addLabel} className={style.add} onClick={preventDefault(onAdd)}>
              <PlusIcon />
            </button>
          ),
          [Action.EDIT]: isEditEnabled && (
            <button aria-label={editLabel} className={style.edit} onClick={preventDefault(onEdit)}>
              <EditIcon />
            </button>
          ),
        }[action]
      }
      {isRemoveEnabled && (
        <button aria-label={removeLabel} className={style.close} onClick={preventDefault(onRemove)}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

const preventDefault = (fn: Function) => (event: MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
  fn();
};

PhotoThumbnail.displayName = 'PhotoThumbnail';
