import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import uuid from 'uuid';
import storeBuilder from 'reduxStore/store';
import { Initializer } from 'common/types/initializers';

import { SelectServiceDialog } from './';
import { initialStateAction } from './store/actions';

export const KEY = 'select-service-dialog';

const store = storeBuilder();

interface Params {
  node: HTMLElement;
  onOpenCustomServiceDialog: (...args: any[]) => any;
  onOpenServiceDialog: (...args: any[]) => any;
  treatmentTypes: any;
  treatments: any;
}

export const SelectServiceDialogInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(params.node),
  render: () => {
    const {
      node,
      treatments,
      treatmentTypes,
      onOpenCustomServiceDialog,
      onOpenServiceDialog,
    } = params;
    const array = treatmentTypes.toJSON().reduce(
      (a, { menuItems, ...treatment }) =>
        a.concat({
          ...treatment,
          menuItems: menuItems.map((menuItem) => ({
            ...menuItem.toJSON(),
            uuid: uuid.v4(),
          })),
        }),
      [],
    );
    const state = {
      treatments: treatments.toJSON(),
      treatmentTypes: array,
      treatmentTypesList: array,
    };
    const component = (
      <Provider store={store}>
        {/* @ts-expect-error major mismatch with props interface */}
        <SelectServiceDialog
          onOpenCustomServiceDialog={onOpenCustomServiceDialog}
          onOpenServiceDialog={onOpenServiceDialog}
          onClose={() => {
            ReactDOM.unmountComponentAtNode(node);
          }}
        />
      </Provider>
    );
    store.dispatch(initialStateAction(state));
    // Render the component
    ReactDOM.render(component, node);
  },
});
