import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { getTreatmentGroupsReceive, getTreatmentGroupsFail } from '../../actions';

export default function* getTreatmentGroupsRequested() {
  try {
    const data = yield call(api.get, apiUrl('EMAIL_CAMPAIGN_SERVICE_ON_SALE_TREATMENT_GROUPS'));
    yield put(getTreatmentGroupsReceive(data));
  } catch (err) {
    yield put(getTreatmentGroupsFail(err));
  }
}
