import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class ColumnCollapse extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-column-collapse', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 16 16"
      >
        <g fillRule="nonzero">
          <path d="M1 11.59L4.58 8 1 4.41 2.41 3l5 5-5 5zM15 11.59L11.42 8 15 4.41 13.59 3l-5 5 5 5z" />
        </g>
      </svg>
    );
  }
}

export const icon = iconWrapper(ColumnCollapse);
