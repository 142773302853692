import { trackEvent, ACTIONS } from 'common/analytics';

const CATEGORY = 'discounts';

export const DiscountRuleDialogAnalytics = {
  trackTabView(tab: string) {
    trackEvent(CATEGORY, ACTIONS.VIEW, tab);
  },
  trackUpdate(id: number) {
    trackEvent(CATEGORY, ACTIONS.UPDATE, 'discount-dialog', id);
  },
  trackSubmit() {
    trackEvent(CATEGORY, ACTIONS.SUBMIT, 'discount-dialog');
  },
  trackServiceAssigned(id: number) {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'assign-service', id);
  },
  trackServiceUnassigned(id: number) {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'unassign-service', id);
  },
  trackActivityStatusChange(isActive: boolean) {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'activity-status', isActive ? 'enable' : 'disable');
  },
};
