App.Views.Forms.MenuOffer2.RichEditor = BackboneEx.View.Editor.extend({
  initialize: function () {
    this.editorId = this.generateId();
    this.editor = null;
    this.instanceEnabled = true;
  },

  render: function () {
    this.$el
      .empty()
      .append(Wahanda.Template.renderTemplate('menu2-text-editor', this.getTemplateVars()));
    this.options.uiColor = '#ffffff';
    this.showEditor();
  },

  getTemplateVars: function () {
    return {
      id: this.editorId,
      name: this.options.editorName,
      content: this.options.editorContent,
      title: this.options.title,
      maxLength: this.options.maxContentLength,
    };
  },
});
