import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class DocumentFeedback extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-document-feedback', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M15,1 C15.8169954,1 16.8338622,1.41964862 17.4130188,1.99880527 L20.0011947,4.58698117 C20.5794525,5.16523893 21,6.18062644 21,7.00087166 L21,20.9991283 C21,22.1048454 20.106992,23 18.9991283,23 L5.00087166,23 C3.90000338,23 3,22.1011572 3,21.0081969 L3,2.99180311 C3,1.89062768 3.90451312,1 5.00247329,1 L15,1 Z M13,3 L5.00247329,3 L5.00201943,5.4340664 C5.00160741,8.22512922 5.00093426,13.416506 5,21.0081969 C5,21.0048947 9.66637611,21.0021624 18.9991283,21 L18.9999802,20.2117698 C19.0006779,18.5227052 19.0006845,14.1190725 19,7.00087166 C19,6.71103537 18.7901492,6.20436273 18.5869812,6.00119473 L15.9988053,3.41301883 C15.7960327,3.2102463 15.2907218,3.00133214 15.0002978,3.00000634 L15,6 L17,6 L17,8 L13,8 L13,3 Z M12.4610629,9.63576407 L13.2545669,12.0482744 L15.7937287,12.0482744 C16.4120945,12.0482744 16.5999539,12.6581109 16.1078001,13.0203187 L14.0733792,14.5175808 L14.8290162,16.9164771 C15.0130496,17.5007221 14.4987396,17.8748634 13.9996304,17.5190612 L11.950937,16.0586003 L9.90224354,17.5190612 C9.40346303,17.874629 8.88870301,17.5011072 9.07285773,16.9164771 L9.82849478,14.5175808 L7.79407381,13.0203187 C7.30140947,12.6577351 7.49342238,12.0482744 8.1081452,12.0482744 L10.647307,12.0482744 L11.4408111,9.63576407 C11.6326424,9.05253428 12.2706981,9.05699316 12.4610629,9.63576407 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(DocumentFeedback);
