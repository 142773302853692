import React from 'react';
import LowVotes from './LowVotes';
import ActualRating from './ActualRating';

interface IRatingProps extends React.HTMLAttributes<Element> {
  rating?: number;
  ratingCount?: number;
  threshold?: number;
}

export default class Rating extends React.Component<IRatingProps, {}> {
  render() {
    const { rating, ratingCount, threshold } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (ratingCount < 10) {
      return <LowVotes ratingCount={ratingCount} threshold={10} />;
    }
    return <ActualRating rating={rating} threshold={threshold} />;
  }
}
