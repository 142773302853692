import React from 'react';
import Wahanda from 'common/wahanda';
import classnames from 'classnames';
import style from './style.scss';

type TimeStampProps = {
  date: any;
  extraCssClassName?: string;
};
const TimeStamp: React.SFC<TimeStampProps> = (props) => (
  <span data-cy="timestamp" className={classnames(style.timeStamp, props.extraCssClassName)}>
    {`${Wahanda.lang.reports.sales.timestamp.generated} ${props.date.format('LLLL')}`}
  </span>
);
TimeStamp.defaultProps = {
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
  extraCssClassName: null,
};

export default TimeStamp;
