import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class TopRated extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-top-rated', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C8.03327998,3 4.66593619,5.56623469 3.46760515,9.12906754 C7.04783091,9.32811636 9.22605196,9.31009471 9.55100202,9.14761968 C9.74780903,9.04921618 10.2939972,7.88228826 10.8630434,5.86665015 C11.0429501,5.22860006 11.7061214,4.85738322 12.3440365,5.03747662 C12.7276748,5.1457837 13.0324891,5.43752646 13.1574964,5.81605248 L14.2228732,9.04204687 L17.4535059,9.04204687 C18.1162476,9.04204687 18.6535059,9.57930517 18.6535059,10.2420469 C18.6535059,10.5952781 18.4978774,10.9305673 18.2280893,11.1585732 L15.6212078,13.3617256 L16.356471,16.3027785 C16.5172095,16.9457324 16.1262967,17.5972537 15.4833428,17.7579922 C15.1831584,17.8330383 14.8655877,17.7892298 14.5969333,17.635713 L12,16.1517511 L9.4030667,17.635713 C8.82764577,17.964525 8.09462017,17.7646089 7.76580821,17.1891879 C7.6122914,16.9205335 7.56848287,16.6029629 7.64352898,16.3027785 L8.5298575,12.7574644 L10.4701425,13.2425356 L10.0384999,14.9691061 L12,13.8482489 L13.9615001,14.9691061 L13.3787922,12.6382744 L15.2675295,11.0420469 L12.7771268,11.0420469 L12.01856,8.74508287 C11.5429135,9.92458407 11.048275,10.6350512 10.4454292,10.9364741 C9.65151259,11.3334324 7.35397663,11.3637485 3.04552293,11.1089537 C3.01476583,11.4016625 3,11.6991069 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(TopRated);
