import React, { PureComponent } from 'react';
import classnames from 'classnames';
import style from './style.scss';

export const TYPES = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  NOTES: 'NOTES',
};

interface IFieldReadOnlyProps extends React.HTMLAttributes<Element> {
  type: any;
  placeHolderText?: string;
  text?: string;
  additionalClasses?: string;
}
class FieldReadOnly extends PureComponent<IFieldReadOnlyProps, {}> {
  static defaultProps = {
    additionalClasses: '',
    placeHolderText: '',
    text: '',
  };

  get typeStyle() {
    const { type } = this.props;
    switch (type) {
      case TYPES.EMAIL:
        return style.email;
      case TYPES.NOTES:
        return style.notes;
      case TYPES.PHONE:
        return style.phone;
      default:
        return '';
    }
  }

  get isPlaceHolder() {
    const { text } = this.props;
    return !(text && text.length > 0);
  }

  get text() {
    const { text, placeHolderText } = this.props;
    return this.isPlaceHolder ? placeHolderText : text;
  }

  render() {
    const { additionalClasses } = this.props;
    const placeholder = this.isPlaceHolder ? style.placeholder : '';
    const classNames = classnames(
      style.fieldReadOnly,
      this.typeStyle,
      placeholder,
      additionalClasses,
    );
    return <div className={classNames}>{this.text}</div>;
  }
}

export default FieldReadOnly;
