require('../../../../app/assets/javascripts/wahanda/event.js');
import filterMenu from 'services/service-menu';
import { xhr } from 'common/xhr';
import apiUrl from 'common/api-url';

(function () {
  /**
   * Model/Collection cache where the responses are Promises.
   *
   * All caches are lost during App load, e.g. when the venue is changed.
   */
  var Cache = {
    // Needed data: name, Class, params?
    cache: {},

    /**
     * Fetch an object, or have it returned from the cache.
     *
     * @param String cacheKey Unique cache key.
     * @param Class obj The object to be instantiated
     * @param Object options
     * > Object instanceOptions (optional) Options for the instantiated class.
     * > Array[String] (optional) appEventsToCleanOn Array of events to listen on App. If such an event is cought, the item is
     *      removed from the cache and fetched again next time requested.
     * > Int expiry (optional) Seconds the item expires. Null for never.
     *
     * @returns Promise
     */
    fetch: function (cacheKey, obj, options) {
      return Cache.fetchByFunction(
        cacheKey,
        options,
        function (onSuccess, onFailure) {
          options = options || {};
          var inst = new obj(options.instanceOptions || {});
          // Start fetching the Model/Collection
          inst.fetch()
            .done(function () {
              onSuccess(inst);
            })
            .fail(function (jqXHR, textStatus) {
              onFailure(textStatus);
            });
        }
      );
    },

    fetchByFunction: function (cacheKey, options, fetchFunction) {
      if (!Cache.cache[cacheKey] || isExpired(Cache.cache[cacheKey])) {
        options = options || {};
        var drd = new $.Deferred();

        function onSuccess(result) {
          drd.resolve(result);
        }

        function onFailure(errorText) {
          drd.reject();
          delete Cache.cache[cacheKey];
          debug('Failed to persist "' + cacheKey + '" due to a fetch error: ' + errorText);
        }

        fetchFunction(onSuccess, onFailure);

        // Add it to the cache
        Cache.cache[cacheKey] = {
          promise: drd.promise(),
          cached: new Date(),
          expiry: options.expiry
        };

        // Bind to any events the object should be cleaned on
        if (options.appEventsToCleanOn) {
          var cleanupFn = function () {
            delete Cache.cache[cacheKey];
            App.off(null, cleanupFn);
          };
          App.on(
            options.appEventsToCleanOn.join(' '),
            cleanupFn,
            Cache
          );
        }
      }
      return Cache.cache[cacheKey].promise;
    },

    clear: function () {
      Cache.cache = {};
      // Remove all event listeners from App
      App.off(null, null, Cache);
    },

    // Shorthand fetch recipes

    /**
     * Fetch the full menu.
     * @returns Promise
     */
    menu: function () {
      return Cache.fetch('full-menu', App.Models.Menu, {
        appEventsToCleanOn: [
          Wahanda.Event.MENU_GROUP_CREATED,
          Wahanda.Event.MENU_GROUP_UPDATED,
          Wahanda.Event.MENU_GROUP_DELETED,
          Wahanda.Event.MENU_GROUP_CHANGED_ORDER,
          Wahanda.Event.MENU_OFFER_CHANGED_ORDER,
          Wahanda.Event.MENU_OFFER_SAVED,
          Wahanda.Event.MENU_OFFER_UPDATED_DISCOUNT,
          Wahanda.Event.MENU_OFFER_REMOVED_DISCOUNT,
          Wahanda.Event.MENU_GROUP_OFF_PEAK_DISCOUNTS_RESET,
          Wahanda.Event.MENU_DISCOUNT_SAVED
        ],
        instanceOptions: {
          filter: 'ALL'
        },
        // Expire the menu in an hour
        expiry: 60 * 60
      });
    },

    /**
     * Fetch the full menu, and to the filtering on UI.
     *
     * Sadly can't use `async` here as $.when() and other jQuery don't support the native Promise.
     *
     * @param {Object} Filters. Possible values:
     * - {String} filterType One of FILTERS types from 'services/service-menu'
     * - {Array} includedServiceTypes One of SERVICE_TYPES from 'services/service-menu'
     * @returns {Promise}
     */
    menuFiltered(filters) {
      const drd = new $.Deferred();

      Wahanda.Cache.menu()
        .done((menu) => {
          const data = filterMenu(menu.toJSON(), filters);
          drd.resolve(App.Models.Menu.of(data));
        })
        .fail(drd.reject);

      return drd.promise();
    },

    menuItems: function () {
      return Cache.fetch('menu-items', App.Collections.MenuItems, {
        appEventsToCleanOn: [
          Wahanda.Event.MENU_OFFER_SAVED,
          Wahanda.Event.MENU_GROUP_ARCHIVED
        ],
        // Expire in an hour
        expiry: 60 * 60
      });
    },

    /**
     * Fetch the active employee list.
     * @returns Promise
     */
    employees: function () {
      return Cache.fetch('active-employees', App.Collections.Employees, {
        appEventsToCleanOn: [
          Wahanda.Event.EMPLOYEE_ADDED,
          Wahanda.Event.EMPLOYEE_ARCHIVED,
          Wahanda.Event.EMPLOYEE_EDITED
        ],
        instanceOptions: {
          onlyTakingAppointments: true,
          onlyActive: true
        }
      });
    },


    /**
     * Fetch employee list with offers.
     * @returns Promise
     */
    employeesWithOffers: function () {
      return Cache.fetch('employees-offers', App.Collections.Employees, {
        appEventsToCleanOn: [
          Wahanda.Event.EMPLOYEE_ADDED,
          Wahanda.Event.EMPLOYEE_ARCHIVED,
          Wahanda.Event.EMPLOYEE_EDITED
        ],
        instanceOptions: {
          onlyTakingAppointments: true,
          include: ['employee-offers']
        }
      });
    },

    /**
     * Fetch the entire employee list.
     * @returns Promise
     */
    allEmployees: function () {
      return Cache.fetch('employees', App.Collections.Employees, {
        appEventsToCleanOn: [
          Wahanda.Event.EMPLOYEE_ADDED,
          Wahanda.Event.EMPLOYEE_ARCHIVED,
          Wahanda.Event.EMPLOYEE_EDITED
        ],
        instanceOptions: {
          onlyActive: true
        }
      });
    },

    /**
     * Fetch the employee categories
     *
     * @returns Promise
     */
    employeeCategories: function () {
      return Cache.fetch('employee-categories', App.Collections.EmployeeCategories );
    },


    /**
     * Fetch the treatment list.
     *
     * @returns Promise
     */
    menuTreatments: function () {
      return Cache.fetch('menu-treatments', App.Models.MenuTreatments);
    },

    menuTemplate: function () {
      return Cache.fetch('menu-template', App.Collections.MenuTemplate);
    },

    /**
     * Fetch the product list.
     *
     * @returns Promise
     */
    productList: function () {
      return Cache.fetch('product-list', App.Collections.Products, {
        appEventsToCleanOn: [
          Wahanda.Event.PRODUCTS_LIST_CHANGED
        ]
      });
    },

    posStatus() {
      // This method reads the POS Status from React's AppState.
      const fetchPosStatus = (onSuccess, onFailure) => {
        xhr.getWithPromise(apiUrl('POS_STATUS'))
          .done(onSuccess)
          .fail(() => onFailure('timeout'));
      };

      return Cache.fetchByFunction(
        'pos-status',
        {
          appEventsToCleanOn: [
            Wahanda.Event.POS_DAY_CLOSED,
            Wahanda.Event.TRANSACTION_CREATED
          ]
        },
        fetchPosStatus
      );
    },

    venue: function () {
      return Cache.fetch("venue", App.Models.Venue, {
        instanceOptions: {
          venueId: App.getVenueId(),
          relations: ["opening-hours", "fulfillment-communication", "images"]
        },
        appEventsToCleanOn: [
          Wahanda.Event.SETTINGS_FULFILLMENT_COMMUNICATION_SAVED
        ]
      });
    }
  };

  // Clear all cached when the venue changes
  App.on(Wahanda.Event.APP_VENUE_CHANGED, Cache.clear);

  // Export
  Wahanda.Cache = Cache;

  /**
   * Check if the cache item is expired.
   *
   * @param Object obj
   * @returns boolean
   */
  function isExpired (obj) {
    if (!obj.expiry) {
      return false;
    }
    var now = new Date();
    return (now.getTime() - obj.cached.getTime()) > obj.expiry * 1000;
  }

  function debug(str) {
    if (window.console && window.console.error) {
      window.console.error(str);
    }
  }
}());
