import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestProductSalesReportDataAction } from '../actions';
import { ProductSalesReport } from './index';

const mapStateToProps = (state) => ({
  reportData: state.salesReport.productSales.reportData,
  displayState: state.salesReport.productSales.displayState,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ requestProductSalesReportDataAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProductSalesReport);
