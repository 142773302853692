/**
 * This string indicates the platform currently Connect is running on.
 */
Wahanda.platform = (function () {
  // This checks if this is a iPad UI Web View.
  if (/iPad.*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)) {
    return 'IPAD';
  }
  // Maybe an iPhone/iPod with UI web view?
  if (/(iPhone|iPod).*AppleWebKit(?!.*Safari)/.test(navigator.userAgent)) {
    return 'IOS';
  }
  // The default - desktop.
  return 'DESKTOP';
})();
