/* global App Wahanda BackboneEx */
import { trackEvent } from 'common/analytics';

App.Views.Calendar.Appointment.HeaderPane = function (paneView) {
  this.paneView = paneView;
};

_.extend(
  App.Views.Calendar.Appointment.HeaderPane.prototype,
  BackboneEx.View.Calendar.HeaderPaneAppointment,
  {
    renderResources: function () {
      var employees = this.paneView.options.employees;
      var selected = this.paneView.options.calendarView.currentOptions.resourceId;
      var $select = this.paneView.$('#calendar-resource');
      var employee;

      function buildStructure(empl) {
        var image = empl.get('image');

        return {
          value: String(empl.id),
          selected: empl.id == selected,
          title: empl.get('name'),
          initialsHtml: image
            ? null
            : Wahanda.InitialsGenerator.generateHTML(empl.get('name'), {
                small: true,
              }),
          image_src: image ? image.uris['50x50'] : null,
        };
      }

      if (employees.length === 0 || !Wahanda.Permissions.viewAnyCalendar()) {
        if (employees.length > 0 && selected && (employee = employees.get(selected))) {
          this._renderResourceTemplate({
            options: [buildStructure(employee)],
            single: true,
          });
        } else {
          this.paneView.$('.filters').wHide();
        }
      } else if (employees.length === 1) {
        employee = employees.at(0);

        this._renderResourceTemplate({
          options: [buildStructure(employee)],
          single: true,
        });
      } else {
        this.hideSingleResourceTitle();
        this._renderResourceTemplate({
          options: employees.map(buildStructure),
        });

        if (this.paneView.options.calendarView.currentOptions.type === 'week') {
          $select.hide();
          this.paneView.$('.filters').wShow();
          $select.show();
        }
      }

      // Never show the "All groups" button
      this.paneView.toggleAllGroupLinkVisibility(true);
    },

    onResourceIdChange: function (event) {
      trackEvent('calendar', 'edit', 'employee');

      App.trigger(Wahanda.Event.CALENDAR_RESOURCE_EMPLOYEE_CHANGE, {
        employeeId: Wahanda.Text.str2int($(event.currentTarget).attr('id')),
      });
    },

    forceWeekViewCheck: function () {
      if (!Wahanda.Permissions.viewAnyCalendar()) {
        return true;
      }
      return BackboneEx.View.Calendar.HeaderPaneAppointment.forceWeekViewCheck.call(this);
    },

    getForcedWeekResourceId: function () {
      var mineEmployeeId = App.config.getAccountEmployeeId();
      if (mineEmployeeId > 0 && !Wahanda.Permissions.viewAnyCalendar()) {
        return mineEmployeeId;
      }
      return BackboneEx.View.Calendar.HeaderPaneAppointment.getForcedWeekResourceId.call(this);
    },

    mustHideFilterBlock: function () {
      return (
        this.paneView.options.calendarView.isDayView() ||
        this.paneView.options.employees.length === 0
      );
    },

    _renderResourceTemplate: function (tplData) {
      this.paneView
        .$('.calendar-resource')
        .html(Wahanda.Template.renderTemplate('calendar-resource-template', tplData));
    },
  },
);
