import React from 'react';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import { Team } from 'components/common/Icon';
import style from './ClientReach.scss';
import { Analytics } from '../../tracking';

const lang = Wahanda.lang.marketing.campaignSelection.campaigns.backToBusiness.initiation.reach;
interface Props {
  currentReach?: number;
  potentialReach?: number;
  successfullyReached?: number;
  alreadySent?: boolean;
}

export const ClientReach: React.FC<Props> = ({
  currentReach,
  potentialReach,
  successfullyReached,
  alreadySent,
}) => {
  const handleReachMoreClientsClicked = () => {
    Analytics.trackReachMoreClientsClicked();
  };
  const handleViewReportClicked = () => {
    Analytics.trackViewReportClicked();
  };
  const clientReachSuccess = () => (
    <>
      <div className={style.reach}>
        <span className={style.reachName}>{lang.reached}</span>
        <div className={style.reachNumber}>
          <Team />
          <span className={style.clients}>
            {Wahanda.Template.render(lang.numberOfClients, {
              number: successfullyReached,
            })}
          </span>
        </div>
      </div>
      <Button
        variant="hollow"
        colour="help"
        onClick={handleViewReportClicked}
        dataTest="view-report-link"
        label={lang.viewReport}
        href={Wahanda.Url.getFullUrl('reports', 'clientReports/retention')}
      />
    </>
  );
  const clientReach = () => (
    <>
      <div className={style.reach}>
        <span className={style.reachName}>{lang.current}</span>
        <div className={style.reachNumber}>
          <Team />
          <span className={style.clients}>
            {Wahanda.Template.render(lang.numberOfClients, {
              number: currentReach,
            })}
          </span>
        </div>
      </div>
      <div className={style.reach}>
        <span className={style.reachName}>{lang.potential}</span>
        <div className={style.reachNumber}>
          <Team />
          <span className={style.clients}>
            {Wahanda.Template.render(lang.numberOfClients, {
              number: potentialReach,
            })}
          </span>
        </div>
      </div>
      <Button
        variant="hollow"
        colour="help"
        onClick={handleReachMoreClientsClicked}
        dataTest="reach-more-clients-link"
        href={lang.howToReachMoreClientsLink}
        label={lang.howToReachMoreClients}
      />
    </>
  );
  return (
    <div className={style.container}>{alreadySent ? clientReachSuccess() : clientReach()}</div>
  );
};
