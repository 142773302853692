export interface CustomerFormLinkResponse {
  id: string;
  uri: string;
  title: string;
  status: CustomerFormLinkStatus;
}

// TODO could be reused
export enum CustomerFormLinkStatus {
  SCHEDULED = 'SCHEDULED',
  SENT = 'SENT',
  CANT_BE_SENT = 'CANT_BE_SENT',
  COMPLETED = 'COMPLETED',
}
