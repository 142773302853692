import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import {
  clientsFileUploadSuccess,
  clientsFileUploadFailure,
  clientsFileUploadRequestAction,
  clientsFileUploadSuccessAction,
  clientsFileImportSuccess,
  clientsFileImportFailure,
  clientsFileImportUpdate,
} from './actions';

import { ClientsFileUploadResponse, FileImportStatus } from './types';

const IMPORT_POLLING_INTERVAL = 5000;

export function* clientsFileUploadRequested(params: clientsFileUploadRequestAction) {
  try {
    const response: ClientsFileUploadResponse = yield call(
      api.post,
      apiUrl('CLIENTS_FILE_UPLOAD'),
      null,
      null,
      {
        data: params.payload.formData,
        contentType: false,
        processData: false,
      },
    );
    yield put(clientsFileUploadSuccess({ id: response.id }));
  } catch (err) {
    yield put(clientsFileUploadFailure());
  }
}

export function* processClientsFileImport(params: clientsFileUploadSuccessAction) {
  const { id: progressId } = params.payload;
  try {
    while (true) {
      const response = yield call(api.get, apiUrl('CLIENTS_IMPORT_PROGRESS', { progressId }));
      switch (response.status) {
        case FileImportStatus.FINISHED_SUCCESS:
        case FileImportStatus.FINISHED_WITH_WARNINGS:
          yield put(clientsFileImportSuccess(response));
          return;
        case FileImportStatus.IN_PROGRESS:
          yield put(clientsFileImportUpdate(response));
          yield call(delay, IMPORT_POLLING_INTERVAL);
          break;
        case FileImportStatus.UNFINISHED_BECAUSE_OF_ERRORS:
        default:
          yield put(clientsFileImportFailure(response));
          return;
      }
    }
  } catch (err) {
    yield put(clientsFileImportFailure());
  }
}
