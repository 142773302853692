import React from 'react';
import { ClientFormsCreateStepLayout } from './ClientFormsCreateStepLayout';
import { ClientFormsServicePicker } from '../ClientFormsServicePicker/ClientFormsServicePicker';
import Wahanda from 'common/wahanda';

const lang = Wahanda.lang.marketing.clientForms;

const STEP_NUMBER = 2;
const STEP_TITLE = lang.selectTreatments;
const STEP_DESCRIPTION = lang.selectTreatmentsDescription;

type Step2Props = {
  selectedServices: number[];
  onChangeSelectedServices: (serviceIds: number[]) => void;
  displayError?: boolean;
  displayStepNumber?: boolean;
  formTemplateDetailsLoading?: boolean;
  sortServicesBySelected?: boolean;
};

export const ClientFormsCreateStep2 = ({
  selectedServices,
  onChangeSelectedServices,
  displayError,
  displayStepNumber = true,
  formTemplateDetailsLoading = false,
  sortServicesBySelected = false,
}: Step2Props): JSX.Element => {
  const handleChangeSelectedServices = (serviceIds) => {
    onChangeSelectedServices(serviceIds);
  };

  return (
    <ClientFormsCreateStepLayout
      stepNumber={STEP_NUMBER}
      stepTitle={STEP_TITLE}
      stepDescription={STEP_DESCRIPTION}
      displayError={displayError}
      displayStepNumber={displayStepNumber}
    >
      <ClientFormsServicePicker
        selectedServices={selectedServices}
        setSelectedServices={handleChangeSelectedServices}
        formTemplateDetailsLoading={formTemplateDetailsLoading}
        sortServicesBySelected={sortServicesBySelected}
      />
    </ClientFormsCreateStepLayout>
  );
};
