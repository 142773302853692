import React from 'react';
import App from 'common/backbone-app';
import { ACCOUNT_LOCALE_LOCAL_STORAGE_KEY } from 'components/AccountLanguageSelect/constants';
import { Planet } from 'components/common/Icon';
import Wahanda from 'common/wahanda';
import * as localesConfig from 'config/domains-locales';
import style from './AccountLanguage.scss';

export const AccountLanguage = () => {
  const getAccLanguageName = () => {
    const accLocale = Wahanda.LocalStorage.get(ACCOUNT_LOCALE_LOCAL_STORAGE_KEY);
    return localesConfig.LOCALE_TO_TRANSLATION_MAP[accLocale || App.domainLocale];
  };
  const handleClick = () => {
    App.ES6.Initializers.AccountLanguageSelect.render();
  };
  return (
    <div className={style.container} onClick={handleClick}>
      <Planet className={style.icon} />
      <span className={style.label}>{getAccLanguageName()}</span>
    </div>
  );
};
