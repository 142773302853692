import React from 'react';
import classnames from 'classnames';
import { Align } from '../index';
import style from './style.scss';

interface Props {
  className?: string;
  align?: Align;
}

export class TableData extends React.PureComponent<Props> {
  public render() {
    const { children, className, align = Align.LEFT, ...rest } = this.props;
    const styles = classnames(className, style[align], style.tableData);
    return (
      <td className={styles} {...rest}>
        {children}
      </td>
    );
  }
}
