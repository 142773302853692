import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Repeat extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-repeat', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M9,2 L9,8 L7,8 L7,6 L4.99991026,6 L4.99999882,19.5286104 C4.99999922,19.6841092 4.99999961,19.8409485 5,19.9991283 L19,19.9991283 L19,19.9991283 L19.0001925,4 C20.1080627,4 21,4.8953067 21,6.00087166 L21,6.00087166 L21,19.9991283 C21,21.106246 20.1070757,22 19.0024554,22 L19.0024554,22 L4.99754465,22 C3.89292425,22 3,21.106246 3,19.9991283 L3,6.00087166 C3,4.8953067 3.89193733,4 4.99980749,4 L7,4 L7,2 L9,2 Z M12.7866587,8.60709476 L15.8873276,11 L12.7866587,13.3929052 C12.3587426,13.7231445 12,13.5564136 12,13.009222 L12,12 C10.8954305,12 10,12.8954305 10,14 C10,15.1045695 10.8954305,16 12,16 C12.5240428,16 13.0138808,15.7989269 13.384404,15.4434133 L14.7690854,16.8865548 C14.0297744,17.5959171 13.0453801,18 12,18 C9.790861,18 8,16.209139 8,14 C8,11.790861 9.790861,10 12,10 L12,8.99077797 C12,8.45097518 12.3521991,8.27180558 12.7866587,8.60709476 Z M17,2 L17,8 L15,8 L15,6 L11,6 L11,4 L15,4 L15,2 L17,2 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Repeat);
