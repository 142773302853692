import React from 'react';
import { toClj } from 'mori';
import Wahanda from 'common/wahanda';
import SelectDropdown from 'components/common/SelectDropdown';
import { DialogCloseWithChanges } from 'components/common/dialog/DialogCloseWithChanges';
import Dialog from 'components/menu/DiscountRuleDialog/container';
import { trackEvent } from 'common/analytics';
import { ServiceEditLabel } from 'components/menu/ServicesTab/tracking';
import {
  MAX_LAST_MINUTE_DISCOUNT,
  MAX_MINS_BEFORE_APPOINTMENT,
} from 'components/menu/DiscountsTab/constants';
import style from './style.scss';

const transformOptionsToVectors = (options) => toClj(options);
// Checking for edit-menu directly as we want this to work for Benelux locked down venues too
const hasEditPermission = () => 'edit-menu' in Wahanda.Permissions.list;
const lang = Wahanda.lang.menu.offer.discountRule;
const NO_ASSIGNED_DISCOUNT = {
  name: lang.noAssignedDiscount,
  value: null,
};
const CREATE_OPTION = {
  name: lang.createNewDiscount,
  value: 'createDiscount',
  primaryClass: 'createDiscount',
};
const removeInactiveOptions = (options, exceptions) =>
  options.filter((option) => option.active || exceptions.includes(option.value));
interface IDiscountRuleRowProps extends React.HTMLAttributes<Element> {
  venueId: number;
  actions: {
    updateSelectedDiscountRuleAction: (...args: any[]) => any;
    createDiscountRuleAction: (...args: any[]) => any;
    removeDiscountRuleAction: (...args: any[]) => any;
    updateDiscountRuleAction: (...args: any[]) => any;
    revertDiscountRuleAction: (...args: any[]) => any;
    presentDiscountRuleDialogAction: (...args: any[]) => any;
    discardDiscountRuleDialogAction: (...args: any[]) => any;
    trackServiceEditAction: (...args: any[]) => any;
  };
  selectedDiscountRuleId?: number;
  venueDetails: {};
  discountRuleOptions: {
    name?: string;
    value?: number;
  }[];
  hasSaved: boolean;
  hasError: boolean;
  hasDiscountRuleDialog: boolean;
  trackServiceEditAction?: any;
}
interface DiscountRuleRowState {
  selectedDiscountRuleId?: any;
  previousDiscountRuleId?: any;
  previousDiscountRuleIds: any[];
  discountRuleVectors?: any;
  hasCancelDialog?: boolean;
}

export default class DiscountRuleRow extends React.Component<
  IDiscountRuleRowProps,
  DiscountRuleRowState
> {
  redirectToMenu: any;

  static defaultProps = {
    selectedDiscountRuleId: null,
  } as any;

  constructor(props) {
    super(props);
    const { selectedDiscountRuleId, discountRuleOptions } = props;
    const previousDiscountRuleIds: any[] = [];
    if (selectedDiscountRuleId) {
      previousDiscountRuleIds.push(selectedDiscountRuleId);
    }
    const options = [NO_ASSIGNED_DISCOUNT]
      .concat(removeInactiveOptions(discountRuleOptions, [selectedDiscountRuleId]))
      .concat(CREATE_OPTION as any);
    const discountRuleVectors = transformOptionsToVectors(options);
    this.state = {
      selectedDiscountRuleId,
      previousDiscountRuleId: selectedDiscountRuleId,
      previousDiscountRuleIds,
      discountRuleVectors,
      hasCancelDialog: false,
    };
  }

  // @ts-expect-error ts-migrate(2416) FIXME: Property 'selectedDiscountRuleId' is optional in t... Remove this comment to see the full error message
  public UNSAFE_componentWillReceiveProps({ discountRuleOptions, selectedDiscountRuleId }) {
    const { selectedDiscountRuleId: previousDiscountRuleId, previousDiscountRuleIds } = this.state;
    if (selectedDiscountRuleId && !previousDiscountRuleIds.includes(selectedDiscountRuleId)) {
      previousDiscountRuleIds.push(selectedDiscountRuleId);
    }
    if (previousDiscountRuleId && !previousDiscountRuleIds.includes(previousDiscountRuleId)) {
      previousDiscountRuleIds.push(previousDiscountRuleId);
    }
    const options = [NO_ASSIGNED_DISCOUNT]
      .concat(removeInactiveOptions(discountRuleOptions, previousDiscountRuleIds))
      .concat(CREATE_OPTION as any);
    const discountRuleVectors = transformOptionsToVectors(options);
    this.setState({
      selectedDiscountRuleId,
      discountRuleVectors,
    });
  }

  onDiscountRuleChange = (selectedDiscountRuleId) => {
    const { value } = CREATE_OPTION;
    if (selectedDiscountRuleId === null) {
      this.props.actions.trackServiceEditAction(ServiceEditLabel.RemoveDiscount);
    } else {
      this.props.actions.trackServiceEditAction(ServiceEditLabel.AddDiscount);
    }
    if (selectedDiscountRuleId === value) {
      trackEvent('connect-menu', 'new-discount-rule-clicked', 'from menu dropdown');
      const {
        actions: { presentDiscountRuleDialogAction },
      } = this.props;
      presentDiscountRuleDialogAction();
    } else {
      /*
       *  Put the value the changed select dropdown into state
       */
      this.setState({
        previousDiscountRuleId: selectedDiscountRuleId,
      });
      /*
       *  Store the change of the select dropdown
       */
      const {
        actions: { updateSelectedDiscountRuleAction },
      } = this.props;
      updateSelectedDiscountRuleAction(selectedDiscountRuleId);
    }
  };

  handleClose = () => {
    const {
      actions: { discardDiscountRuleDialogAction, revertDiscountRuleAction },
      venueId,
    } = this.props;
    discardDiscountRuleDialogAction();
    revertDiscountRuleAction(venueId);
  };

  hasDiscountRuleDialog() {
    return !!this.props.hasDiscountRuleDialog;
  }

  renderCancel() {
    // eslint-disable-line class-methods-use-this
    return (
      <DialogCloseWithChanges
        onConfirm={this.redirectToMenu}
        onClose={() => {
          this.setState({
            hasCancelDialog: false,
          });
        }}
      />
    );
  }

  renderDialog() {
    const {
      venueId,
      actions,
      venueDetails: {
        dayPercentages: days,
        appointmentMorningEndTime,
        appointmentAfternoonEndTime,
        leadTimeMinutes,
      } = {} as any,
      hasSaved,
      hasError,
    } = this.props;
    const lastMinuteDiscount = {
      active: false,
      maxMinsBeforeAppointment: MAX_MINS_BEFORE_APPOINTMENT,
      maxLastMinuteDiscount: MAX_LAST_MINUTE_DISCOUNT,
      leadTimeMinutes,
    };
    const offpeakDiscounts = {
      active: false,
      days,
      appointmentMorningEndTime,
      appointmentAfternoonEndTime,
    };
    return (
      <Dialog
        active
        venueId={`${venueId}`}
        actions={actions as any}
        lastMinuteDiscount={lastMinuteDiscount}
        offpeakDiscounts={offpeakDiscounts}
        assignedMenuItems={[]}
        onClose={this.handleClose}
        hasSaved={hasSaved}
        hasError={hasError}
        hasEditPermission={hasEditPermission()}
        hasDeleteConfirmDialog={false}
        isAssignedTreatmentAvailable={false}
      />
    );
  }

  render() {
    const { selectedDiscountRuleId } = this.props;
    const { discountRuleVectors, hasCancelDialog } = this.state;
    const { value } = CREATE_OPTION;
    let cancelDialog;
    if (hasCancelDialog) {
      cancelDialog = this.renderCancel();
    }
    let discountRuleDialog;
    if (this.hasDiscountRuleDialog()) {
      discountRuleDialog = this.renderDialog();
    }
    return (
      <div className={style.row}>
        <label className={style.label}>{lang.label}</label>
        <SelectDropdown
          dataTest="discount-rule-dropdown"
          data={discountRuleVectors}
          onSelect={this.onDiscountRuleChange}
          selected={selectedDiscountRuleId}
          noChangeEventValue={value}
        />

        {cancelDialog}

        {discountRuleDialog}
      </div>
    );
  }
}
