import { trackEvent } from 'common/analytics';

const AnalyticsMiddleware = () => (next) => (action) => {
  if (action.meta && action.meta.ga) {
    const { cat, act, lab } = action.meta.ga;

    trackEvent(cat, act, lab);
  }
  return next(action);
};

export default AnalyticsMiddleware;
