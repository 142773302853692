import React from 'react';
import _ from 'common/underscore';
import App from 'common/backbone-app';
import ReactDialog from 'src/components/common/react-dialog';
import Wahanda from 'common/wahanda';
import { SingleGroup } from 'components/menu/AddServicesModal/store/types';
import { OfferTypeChoiceDialogAnalytics, OfferTypeValue } from './tracking';
import { services, TYPES } from './services';
import { ListItem } from './ListItem';

const lang = Wahanda.lang.menu.offer.typeChoice;
interface Props {
  onClose: () => void;
  onSelect: (param) => void;
  singleGroup?: SingleGroup;
}

export const OfferTypeChoiceDialog = ({ singleGroup, onClose, onSelect }: Props) => {
  React.useEffect(() => {
    OfferTypeChoiceDialogAnalytics.trackOfferChoiceModalView();
  }, []);
  const showMultiServiceImportDialog = () => {
    App.ES6.Initializers.AddServicesModal({ singleGroup }).render();
  };
  const showMenuGroupDialog = () => {
    App.ES6.Initializers.MenuGroupModal({
      name: null,
    }).render();
  };
  const handleSelect = (s) => () => {
    OfferTypeChoiceDialogAnalytics.trackOfferTypeChoiceClick({
      property: s.type,
      value: singleGroup ? OfferTypeValue.IN_GROUP : OfferTypeValue.TOP_BUTTON,
    });
    onSelect(s.type);
    switch (s.type) {
      case TYPES.SERVICE:
        showMultiServiceImportDialog();
        break;
      case TYPES.MENU_GROUP:
        showMenuGroupDialog();
        break;
      default:
        onClose();
        App.trigger(Wahanda.Event.OPEN_OFFER_MODAL, {
          type: s.type,
          menuGroupId: singleGroup?.menuGroupId,
        });
        break;
    }
  };
  return (
    <ReactDialog
      dataTest="offer-type-choice-modal"
      title={lang.title}
      restrictHeight
      onClose={onClose}
      noContentTopBorders
    >
      <ul>
        {services
          .filter(
            (s) =>
              !_.has(s, 'visible') ||
              s.visible({
                singleGroup,
              }),
          )
          .map((service) => (
            <ListItem service={service} key={service.title} onSelect={handleSelect(service)} />
          ))}
      </ul>
    </ReactDialog>
  );
};
