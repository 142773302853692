/**
 * View parent for views, which can edit offers.
 *
 * It must get these options:
 * > treatments
 * > employeeCategories
 * > venue
 * > featuredItemsCollection
 * > employees
 */
BackboneEx.View.Menu.OfferEditing = Backbone.View.extend({
	showOfferEditForm: function(offerModel) {
		if (!offerModel.isEditable()) {
			// Do not allow editing.
			return;
		}

		var view;
		var baseOptions = {
			treatments: this.options.treatments,
			employeeCategories: this.options.employeeCategories,
			model: offerModel,
			venue: this.options.venue,
			featuredItemsCollection: this.options.featuredItemsCollection,
			employees: this.options.employees,
			roomTypes: this.options.roomTypes
		};

		/*
		if (offerModel.isTreatment()) {
			view = new App.Views.Forms.MenuOffer(_.extend(baseOptions, {
				title: Wahanda.lang.menu.offer.title
			}));
		} else {
			var title;
			if(offerModel.isSpaDay()) {
				title = Wahanda.lang.menu.spaDay.title;
			} else if (offerModel.isSpaBreak()) {
				title = Wahanda.lang.menu.spaBreak.title;
			} else {
				title = Wahanda.lang.menu["package"].title;
			}
			view = new App.Views.Forms.MenuPackageOrSpa(_.extend(baseOptions, {
				title: title
			}));
		}*/

		var view = new App.Views.Forms.MenuOffer2(baseOptions);

		view.render();
		view.open();
	}
});
