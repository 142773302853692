import React, { Component } from 'react';
import classnames from 'classnames';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { evaluate } from 'mathjs/lib/esm/number';
import style from './price-input.scss';

function extractFloat(value) {
  if (value == null) {
    return '';
  }
  if (`${value}` === '0') {
    return 0;
  }
  return Wahanda.Number.formatFloatForPOSInput(value);
}

interface IPriceInputProps extends React.HTMLAttributes<Element> {
  value?: string | number;
  onChange?: (...args: any[]) => any;
  onFocus?: (...args: any[]) => any;
  onBlur?: (...args: any[]) => any;
  id?: string;
  disabled?: boolean;
  negative?: boolean;
  required?: boolean;
  min?: number;
  max?: number;
  autocomplete?: string;
  name?: string;
  fieldcontain?: boolean;
  defaultValue?: string | number;
  'data-rule-more-than'?: string;
  controlledComponent?: boolean;
}
type PriceInputState = {
  value?: any;
};

export class PriceInput extends Component<IPriceInputProps, PriceInputState> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'input' has no initializer and is not def... Remove this comment to see the full error message
  input: HTMLInputElement;

  state = {
    value: this.props.controlledComponent //eslint-disable-line
      ? this.props.value
        ? this.props.value
        : this.props.defaultValue
      : extractFloat(this.props.value ? this.props.value : this.props.defaultValue),
    controlledComponent: this.props.controlledComponent,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.controlledComponent) {
      return;
    }
    if (nextProps === this.props) {
      return;
    }
    const value = nextProps.value != null ? nextProps.value : nextProps.defaultValue;
    this.setState({ value });
  }

  onFocus = (event) => {
    const { onFocus, onChange, defaultValue, controlledComponent } = this.props;
    if (defaultValue != null) {
      /*
       *  TODO:
       *
       *  Declaring "const value = ''" then testing value in ternary?
       *  Hmm ...
       */
      const value = '';
      if (this.state.value === extractFloat(defaultValue) || this.state.value === defaultValue) {
        this.setState({ value });
        if (onChange && this.state.value !== defaultValue) {
          onChange(controlledComponent ? value : this.parseValue(value));
        }
      }
    }
    if (onFocus) {
      onFocus(event);
    }
  };

  onBlur = (event) => {
    const { onBlur, onChange, defaultValue, controlledComponent } = this.props;
    const hasDefaultValue = defaultValue != null;
    if (this.state.value === '') {
      if (hasDefaultValue) {
        this.setState({ value: extractFloat(defaultValue) });
        if (onChange && this.state.value !== '') {
          onChange(controlledComponent ? defaultValue : this.parseValue(defaultValue));
        }
      }
    } else {
      const parsedValue = this.parseValue(event.target.value);
      if (!Number.isNaN(parsedValue)) {
        this.setState({ value: extractFloat(parsedValue) });
        if (onChange && controlledComponent) {
          onChange(parsedValue);
        }
      }
    }
    if (onBlur) {
      onBlur(event);
    }
  };

  /**
   * Get the value from input.
   *
   * @returns {Number} or {NaN}
   */
  getValue() {
    const { value } = this.state;
    const sign = this.props.negative ? -1 : 1;
    return sign * this.parseValue(value);
  }

  parseValue = (value) => {
    let parsedExpression;
    try {
      parsedExpression = evaluate(Wahanda.Number.formatExpression(value));
      parsedExpression = Wahanda.Number.floatToDecimal(parsedExpression);
    } catch (e) {
      return NaN;
    }
    const strNumber = Wahanda.Number.formatPOSInputintoFloat(`${parsedExpression}`);
    return parseFloat(strNumber);
  };

  sendChangedValue = (event) => {
    const { value } = event.target;
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(this.props.controlledComponent ? value : this.parseValue(value));
    }
  };

  focus() {
    this.input.focus();
  }

  render() {
    const currencyInfo = App.config.get('currency');
    const symbol = currencyInfo.symbol || currencyInfo.currencyCode;
    const inFront = currencyInfo.displayInFront;
    // Default is required. Don't require the value ONLY if it's explicitly false.
    const isRequired = this.props.required !== false;
    const attrs = {
      value: this.state.value,
      onChange: this.sendChangedValue,
      id: this.props.id,
      className: classnames('currency-amount-expression', isRequired && 'required', 'min-zero'),
      disabled: this.props.disabled,
      'data-min-currency': this.props.min,
      autoComplete: this.props.autocomplete,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      name: this.props.name,
      'data-rule-more-than': this.props['data-rule-more-than'],
    };
    if (this.props.max != null) {
      attrs['data-max-currency'] = this.props.max;
    }
    const classes = classnames('price-input', style.priceInput, {
      disabled: this.props.disabled,
      negative: this.props.negative,
    });
    const negativeSymbol = (() => {
      if (this.props.negative) {
        return <span className="negative-sign">-</span>;
      }
      return null;
    })();
    return (
      <span className={classes} data-role={this.props.fieldcontain ? 'fieldcontain' : null}>
        {negativeSymbol}
        {inFront ? symbol : ''}
        <input
          {...attrs}
          ref={(input) => {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLInputEl... Remove this comment to see the full error message
            this.input = input;
          }}
        />
        {!inFront ? <span className={style.currencySuffix}>{symbol}</span> : ''}
      </span>
    );
  }
}

export default PriceInput;
