import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Sort extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-sort', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M17,10 L16.9998932,18.5857864 L20.2928932,15.2928932 L21.7071068,16.7071068 L16,22.4142136 L10.2928932,16.7071068 L11.7071068,15.2928932 L14.9998932,18.5857864 L15,10 L17,10 Z M8,1.58578644 L13.7071068,7.29289322 L12.2928932,8.70710678 L8.99989322,5.41378644 L9,14 L7,14 L6.99989322,5.41378644 L3.70710678,8.70710678 L2.29289322,7.29289322 L8,1.58578644 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Sort);
