import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Discounts extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-discounts', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M13.24,6.391 L8.912,16.844 L10.76,17.609 L15.09,7.156 L13.24,6.391 Z M8,11 C9.1045695,11 10,10.1045695 10,9 C10,7.8954305 9.1045695,7 8,7 C6.8954305,7 6,7.8954305 6,9 C6,10.1045695 6.8954305,11 8,11 Z M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M16,13 C17.1045695,13 18,13.8954305 18,15 C18,16.1045695 17.1045695,17 16,17 C14.8954305,17 14,16.1045695 14,15 C14,13.8954305 14.8954305,13 16,13 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Discounts);
