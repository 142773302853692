import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Wahanda from 'common/wahanda';

import {
  discountRulesBookingHoursTransformer,
  discountRulesAfternoonEndTimeTransformer,
  discountRulesMorningEndTimeTransformer,
} from 'common/transformers/DiscountRules';

import { venueDetailsDayPercentagesTransformer } from 'common/transformers/VenueDetails';

import * as actions from './actions';
import DiscountRuleRow from './index';
import { trackServiceEditAction } from '../../actions';

const lang = Wahanda.lang.menu.offer.discountRule;

export const calculateMaxOffPeakDiscount = (offpeakDiscounts) => {
  let maxOffPeakDiscount = 0;

  if (offpeakDiscounts) {
    const { days } = offpeakDiscounts;

    if (days) {
      const keys = Reflect.ownKeys(days);

      if (keys.length) {
        const maxPercentages = keys.map((key) => {
          const day = Reflect.get(days, key);
          const percentages: number[] = Object.values(day);

          return Math.max(...percentages);
        });

        maxOffPeakDiscount = Math.max(...maxPercentages);
      }
    }
  }

  return maxOffPeakDiscount;
};

export const calculateMaxLastMinuteDiscount = (lastMinuteDiscount) => {
  let maxLastMinuteDiscount = 0;

  if (lastMinuteDiscount) {
    const { percentage } = lastMinuteDiscount;

    maxLastMinuteDiscount = percentage || maxLastMinuteDiscount;
  }

  return maxLastMinuteDiscount;
};

export const createSecondaryText = (maxLastMinuteDiscount, maxOffPeakDiscount) => {
  if (maxLastMinuteDiscount && maxOffPeakDiscount) {
    const maxLastMinuteDiscountLang = lang.lastMinuteText.replace(
      '{{percentage}}',
      maxLastMinuteDiscount,
    );
    const maxOffPeakDiscountLang = lang.offPeakText.replace('{{percentage}}', maxOffPeakDiscount);

    return {
      secondaryText: `${maxLastMinuteDiscountLang}, ${maxOffPeakDiscountLang}`,
      secondaryClass: 'discountPrice',
    };
  }

  if (maxLastMinuteDiscount) {
    const maxLastMinuteDiscountLang = lang.lastMinuteText.replace(
      '{{percentage}}',
      maxLastMinuteDiscount,
    );

    return {
      secondaryText: maxLastMinuteDiscountLang,
      secondaryClass: 'discountPrice',
    };
  }

  if (maxOffPeakDiscount) {
    const maxOffPeakDiscountLang = lang.offPeakText.replace('{{percentage}}', maxOffPeakDiscount);

    return {
      secondaryText: maxOffPeakDiscountLang,
      secondaryClass: 'discountPrice',
    };
  }

  return {};
};

export const transformDiscountRuleToOptions = ({
  name,
  id: value,
  offpeakDiscounts,
  lastMinuteDiscount,
  active,
}) => {
  if (offpeakDiscounts || lastMinuteDiscount) {
    let secondaryText;
    if (active) {
      const offPeakActive = !!(offpeakDiscounts && offpeakDiscounts.active);
      const lastMinuteActive = !!(lastMinuteDiscount && lastMinuteDiscount.active);

      const maxLastMinuteDiscount =
        lastMinuteActive && calculateMaxLastMinuteDiscount(lastMinuteDiscount);

      const maxOffPeakDiscount = offPeakActive && calculateMaxOffPeakDiscount(offpeakDiscounts);

      secondaryText = createSecondaryText(maxLastMinuteDiscount, maxOffPeakDiscount);
    } else {
      secondaryText = {
        secondaryText: lang.discountInactive,
        secondaryClass: 'discountInactive',
      };
    }

    return { name, value, active, ...secondaryText };
  }

  return { name, value, active };
};

export const transformDiscountRulesToOptions = (discountRules) =>
  discountRules.map(transformDiscountRuleToOptions);

const venueDetailsTransformer = (venueDetails) =>
  venueDetailsDayPercentagesTransformer(venueDetails);

// There has to be a way to clean this up that I'm missing at the moment.
const discountRulesTransformer = (discountRules, venueDetails) =>
  discountRulesBookingHoursTransformer(
    discountRulesAfternoonEndTimeTransformer(
      discountRulesMorningEndTimeTransformer(discountRules, venueDetails),
      venueDetails,
    ),
    venueDetails,
  );

const mapDispatchToProps = (dispatch) =>
  Object.assign(
    {},
    {
      actions: bindActionCreators({ ...actions, trackServiceEditAction }, dispatch),
    },
  );

const mapStateToProps = ({ discountRule: { discountRules, venueDetails, ...discountRule } }) =>
  Object.assign(
    {},
    {
      ...discountRule,
      discountRuleOptions: transformDiscountRulesToOptions(discountRules),
      venueDetails: venueDetailsTransformer(venueDetails),
      discountRules: discountRulesTransformer(
        discountRules,
        venueDetails, // This is the untransformed data
      ),
    },
  );

// @ts-expect-error ts-migrate(2345) FIXME: Type 'Readonly<IDiscountRuleRowProps> & Readonly<{... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(DiscountRuleRow);
