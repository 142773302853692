import App from 'common/backbone-app';

interface Params {
  subject: string;
  recordType: string;
}

type submitWebToCaseFormType = (x: Params & { [_: string]: any }) => Promise<any>;

export const submitWebToCaseForm: submitWebToCaseFormType = (params) => {
  const venueId = App.getVenueId();
  const baseData = {
    orgid: '00D20000000CoyM',
    '00N0O000009iIoW': venueId,
    retURL: 'https://www.treatwell.co.uk',
  };

  const formData = { ...baseData, ...params };
  const url = App.isProd
    ? 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8'
    : '/mockWebToCase';

  return fetch(url, {
    method: 'POST',
    // no-cors must be set to prevent CORS error
    // with no-cors, response is opaque and it is not possible to implement proper error handling
    mode: 'no-cors',
    credentials: 'include',
    body: getFormData(formData),
  }).catch(() => true);
};

function getFormData(object) {
  return new URLSearchParams(object);
}
