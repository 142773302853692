import React from 'react';
import classnames from 'classnames';
import Chevron, { ANGLE } from 'components/common/icons/Chevron/small';
import style from './style.scss';

export interface Props {
  text: string;
  onClick: () => void;
  isOpen: boolean;
}

export default class SelectFilter extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { onClick, text, isOpen } = this.props;
    const className = classnames(style.selectFilter, isOpen ? style.isOpen : null);
    return (
      <button onClick={onClick} className={className}>
        <p className={style.text}>{text}</p>
        {/* @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message */}
        <Chevron className={style.icon} color={null} angle={isOpen ? ANGLE.UP : ANGLE.DOWN} />
      </button>
    );
  }
}
