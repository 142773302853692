import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { lastPaymentSelector } from './selectors';
import { requestBankDetailsAction, requestLastPaymentStatus } from './actions';
import { PaymentMessage as Component } from './PaymentMessage';

const mapStateToProps = (state) => {
  return {
    lastPayment: lastPaymentSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ requestBankDetailsAction, requestLastPaymentStatus }, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const PaymentMessage = connector(Component);
