export default {
  NEW_SALES_REPORTS:
    '/venue/:venueId/salesReports/:report?/:fromDate?/:toDate?/:selectedEmployeeId?/:selectedChannel?',
  SALES_REPORT:
    '/venue/:venueId/salesReports/salesOverview/:fromDate?/:toDate?/:employeeFilter?/:selectedChannel?',
  SERVICE_SALES_REPORT:
    '/venue/:venueId/salesReports/serviceSales/:fromDate?/:toDate?/:employeeFilter?/:selectedChannel?',
  PRODUCT_SALES_REPORT:
    '/venue/:venueId/salesReports/productSales/:fromDate?/:toDate?/:employeeFilter?/',
  EMPLOYEE_SALES:
    '/venue/:venueId/salesReports/employeeSales/:fromDate?/:toDate?/:selectedEmployeeId?/',
  CHANNEL_SALES:
    '/venue/:venueId/salesReports/channelSales/:fromDate?/:toDate?/:selectedEmployeeId?/',
  DAILY_SALES:
    '/venue/:venueId/salesReports/dailySales/:fromDate?/:toDate?/:employeeFilter?/:selectedChannel?',
  MONTHLY_SALES_REPORTS:
    '/venue/:venueId/salesReports/monthlySales/:fromDate?/:toDate?/:employeeFilter?/:selectedChannel?',
};

export const POS_REPORTS_ROUTES = {
  VAT_REPORT: '/venue/:venueId/posReports/vat/:fromDate?/:toDate?/:selectedEmployeeId?/',
  YEAR_POS_REPORT: '/venue/:venueId/posReports/yearly/:year?',
  MONTH_POS_REPORT: '/venue/:venueId/posReports/monthly/:from?/:to?',
};

export const CLIENT_ROUTES = {
  ACQUISITION_REPORT: '/venue/:venueId/clientReports/acquisition/:timePeriod?/:selectedEmployeeId?',
  RETENTION_REPORT:
    '/venue/:venueId/clientReports/retention/:timePeriod?/:selectedEmployeeId?/:selectedChannel?',
};
