import {
  ChecklistTask,
  ChecklistTaskItem,
  ChecklistTaskStatus,
  KycStatusValue,
} from 'components/shared/OnboardingWidget/OnboardingWidget.types';

export const isSelfSetupTask = (task: ChecklistTask) =>
  Object.values(ChecklistTaskItem).includes(task.item);

export const normalizeSelfSetupTask = (task: ChecklistTask) => {
  const TaskTitleMap: Record<ChecklistTaskItem, string> = {
    [ChecklistTaskItem.TEAM]: Wahanda.lang.onboardingWidget.selfSetupTask.team.title,
    [ChecklistTaskItem.SERVICES]: Wahanda.lang.onboardingWidget.selfSetupTask.services.title,
    [ChecklistTaskItem.BANK_DETAILS]: Wahanda.lang.onboardingWidget.selfSetupTask.bankDetails.title,
    [ChecklistTaskItem.SALON_PHOTOS]: Wahanda.lang.onboardingWidget.selfSetupTask.salonPhotos.title,
    [ChecklistTaskItem.APPOINTMENTS]:
      Wahanda.lang.onboardingWidget.selfSetupTask.availability.title,
    [ChecklistTaskItem.KYC]: Wahanda.lang.onboardingWidget.selfSetupTask.kyc.title,
    [ChecklistTaskItem.TAX_ID]: Wahanda.lang.onboardingWidget.selfSetupTask.taxId.title,
  };

  const TaskDescriptionMap: Record<ChecklistTaskItem, string | undefined> = {
    [ChecklistTaskItem.TEAM]: Wahanda.lang.onboardingWidget.selfSetupTask.team.description,
    [ChecklistTaskItem.SERVICES]: Wahanda.lang.onboardingWidget.selfSetupTask.services.description,
    [ChecklistTaskItem.BANK_DETAILS]:
      Wahanda.lang.onboardingWidget.selfSetupTask.bankDetails.description,
    [ChecklistTaskItem.SALON_PHOTOS]:
      Wahanda.lang.onboardingWidget.selfSetupTask.salonPhotos.description,
    [ChecklistTaskItem.APPOINTMENTS]:
      Wahanda.lang.onboardingWidget.selfSetupTask.availability.description,
    [ChecklistTaskItem.KYC]: undefined,
    [ChecklistTaskItem.TAX_ID]: Wahanda.lang.onboardingWidget.selfSetupTask.taxId.description,
  };

  const TaskStepsMap: Record<
    ChecklistTaskItem,
    { steps: string[]; stepChecked?: number } | undefined
  > = {
    [ChecklistTaskItem.TEAM]: undefined,
    [ChecklistTaskItem.SERVICES]: undefined,
    [ChecklistTaskItem.BANK_DETAILS]: undefined,
    [ChecklistTaskItem.SALON_PHOTOS]: undefined,
    [ChecklistTaskItem.APPOINTMENTS]: undefined,
    [ChecklistTaskItem.KYC]: {
      steps: [
        Wahanda.lang.onboardingWidget.selfSetupTask.kyc.step.step1,
        Wahanda.lang.onboardingWidget.selfSetupTask.kyc.step.step2,
        Wahanda.lang.onboardingWidget.selfSetupTask.kyc.step.step3,
      ],
      stepChecked: {
        [KycStatusValue.STARTED]: 1,
        [KycStatusValue.PENDING]: 2,
        status:
          {
            [ChecklistTaskStatus.COMPLETED]: 3,
            [ChecklistTaskStatus.TODO]: 0,
          }[task?.status] ?? 0,
      }[task.statusDetail?.kycStatus ?? 'status'],
    },
    [ChecklistTaskItem.TAX_ID]: undefined,
  };

  const TaskExternalLinkMap: Record<
    ChecklistTaskItem,
    { label: string; href: string } | undefined
  > = {
    [ChecklistTaskItem.TEAM]: undefined,
    [ChecklistTaskItem.SERVICES]: {
      label: Wahanda.lang.onboardingWidget.selfSetupTask.services.link_label,
      href: Wahanda.lang.onboardingWidget.selfSetupTask.services.link_url,
    },
    [ChecklistTaskItem.BANK_DETAILS]: undefined,
    [ChecklistTaskItem.SALON_PHOTOS]: {
      label: Wahanda.lang.onboardingWidget.selfSetupTask.salonPhotos.link_label,
      href: Wahanda.lang.onboardingWidget.selfSetupTask.salonPhotos.link_url,
    },
    [ChecklistTaskItem.APPOINTMENTS]: undefined,
    [ChecklistTaskItem.KYC]: undefined,
    [ChecklistTaskItem.TAX_ID]: undefined,
  };

  const TaskCtaLocationMap: Record<ChecklistTaskItem, string> = {
    [ChecklistTaskItem.TEAM]: Wahanda.Url.getFullUrl('team', 'team'),
    [ChecklistTaskItem.SERVICES]: Wahanda.Url.getFullUrl('menu', 'services'),
    [ChecklistTaskItem.BANK_DETAILS]: Wahanda.Url.getFullUrl('settings', 'finance/bank-details'),
    [ChecklistTaskItem.SALON_PHOTOS]: Wahanda.Url.getFullUrl(
      'settings',
      'venue-settings/venue-details',
    ),
    [ChecklistTaskItem.APPOINTMENTS]: Wahanda.Url.getFullUrl('calendar'),
    [ChecklistTaskItem.KYC]: `./identity-verification?returnUrl=${window.encodeURIComponent(
      window.location.href,
    )}`,
    [ChecklistTaskItem.TAX_ID]: Wahanda.Url.getFullUrl('settings', 'finance/billing-details'),
  };

  const TaskCtaMap: Record<
    ChecklistTaskItem,
    {
      label: string;
      location: string;
      external?: boolean;
    }
  > = {
    [ChecklistTaskItem.TEAM]: {
      label: Wahanda.lang.onboardingWidget.selfSetupTask.team.cta_label,
      location: TaskCtaLocationMap[ChecklistTaskItem.TEAM],
    },
    [ChecklistTaskItem.SERVICES]: {
      label: Wahanda.lang.onboardingWidget.selfSetupTask.services.cta_label,
      location: TaskCtaLocationMap[ChecklistTaskItem.SERVICES],
    },
    [ChecklistTaskItem.BANK_DETAILS]: {
      label: Wahanda.lang.onboardingWidget.selfSetupTask.bankDetails.cta_label,
      location: TaskCtaLocationMap[ChecklistTaskItem.BANK_DETAILS],
    },
    [ChecklistTaskItem.SALON_PHOTOS]: {
      label: Wahanda.lang.onboardingWidget.selfSetupTask.salonPhotos.cta_label,
      location: TaskCtaLocationMap[ChecklistTaskItem.SALON_PHOTOS],
    },
    [ChecklistTaskItem.APPOINTMENTS]: {
      label: Wahanda.lang.onboardingWidget.selfSetupTask.availability.cta_label,
      location: TaskCtaLocationMap[ChecklistTaskItem.APPOINTMENTS],
    },
    [ChecklistTaskItem.KYC]: {
      label: Wahanda.lang.onboardingWidget.selfSetupTask.kyc.cta_label,
      location: TaskCtaLocationMap[ChecklistTaskItem.KYC],
      external: true,
    },
    [ChecklistTaskItem.TAX_ID]: {
      label: Wahanda.lang.onboardingWidget.selfSetupTask.taxId.cta_label,
      location: TaskCtaLocationMap[ChecklistTaskItem.TAX_ID],
    },
  };

  const TaskCompletedNotification: Record<ChecklistTaskItem, string> = {
    [ChecklistTaskItem.TEAM]:
      Wahanda.lang.onboardingWidget.selfSetupTask.team.completedNotification,
    [ChecklistTaskItem.SERVICES]:
      Wahanda.lang.onboardingWidget.selfSetupTask.services.completedNotification,
    [ChecklistTaskItem.BANK_DETAILS]:
      Wahanda.lang.onboardingWidget.selfSetupTask.bankDetails.completedNotification,
    [ChecklistTaskItem.SALON_PHOTOS]:
      Wahanda.lang.onboardingWidget.selfSetupTask.salonPhotos.completedNotification,
    [ChecklistTaskItem.APPOINTMENTS]:
      Wahanda.lang.onboardingWidget.selfSetupTask.availability.completedNotification,
    [ChecklistTaskItem.KYC]: Wahanda.lang.onboardingWidget.selfSetupTask.kyc.completedNotification,
    [ChecklistTaskItem.TAX_ID]:
      Wahanda.lang.onboardingWidget.selfSetupTask.taxId.completedNotification,
  };

  return {
    id: task.item,
    title: TaskTitleMap[task.item],
    description: TaskDescriptionMap[task.item],
    steps: TaskStepsMap[task.item],
    externalLink: TaskExternalLinkMap[task.item],
    cta: TaskCtaMap[task.item],
    isCompleted: task.status === ChecklistTaskStatus.COMPLETED,
    completedNotification: TaskCompletedNotification[task.item],
  };
};

export type SelfSetupTask = ReturnType<typeof normalizeSelfSetupTask>;
