import React from 'react';
import classnames from 'classnames';
import style from './style.scss';

interface IDialogContentBoxProps extends React.HTMLAttributes<Element> {
  separated?: boolean;
}

export default class DialogContentBox extends React.Component<IDialogContentBoxProps, {}> {
  static defaultProps = {
    separated: false,
  };

  render() {
    const { separated } = this.props;
    const classes = classnames(style.box, { [style.separated]: separated });
    return <div className={classes}>{this.props.children}</div>;
  }
}
