import { CALENDAR_BLOCK_TYPES, CALENDAR_BLOCK_SOURCES } from 'common/consts';

App.Views.Calendar.BlockTooltip = BackboneEx.View.CalendarTooltip.extend({
  template: 'calendar-block-tooltip',

  renderData: function (calEvent, block) {
    // Set class for this block
    this.$el
      .removeClass(
        'sal-event-info-nonwork sal-event-info-rescheduling-suggestion sal-external-block',
      )
      .addClass('sal-event-info-nonwork');

    // Event type
    this.$('.type').text(Wahanda.lang.calendar.blockTime.types[calEvent.type]);

    if (calEvent.typeCode === CALENDAR_BLOCK_TYPES.EXTERNAL) {
      this.$el.addClass('sal-external-block');
    }

    // Resource name
    var serviceText;
    var resource = this.options.employees.get(calEvent.originalResourceId);
    if (resource) {
      serviceText = resource.get('name');
    }

    if (serviceText) {
      this.$('.service').text(serviceText).show();
    } else {
      this.$('.service').hide();
    }

    // Reason
    if (block.name) {
      this.$('.reason').text(block.name).show();
    } else {
      this.$('.reason').hide();
    }

    if (block.availabilityRuleTypeCode in { D: 1, P: 1 }) {
      // Do not show the repeating type if "none"
      this.$('.repeat-type-row').hide();
    } else {
      this.$('.repeat-type').text(this._getRepeatTypeText(block));
      this.$('.repeat-type-row').show();
    }

    if (block.availabilityRuleTypeCode in { W: 1, B: 1 }) {
      this.$('.repeat-days-row').show();
      this.$('.repeat-days').text(this._getRepeatDays(block));
    } else {
      this.$('.repeat-days-row').hide();
    }

    this.$('.repeat-date-from').text(this._getDate(block.dateFrom));
    if (block.dateTo) {
      this.$('.repeat-to').show();
      this.$('.repeat-date-to').show().text(this._getDate(block.dateTo));
    } else {
      this.$('.repeat-to').hide();
    }

    if (block.updated) {
      const sourceText = this._getSource({ calEvent, block });

      this.$('.source-name-date').text(sourceText);
      this.$('.source-row').show();
    } else {
      this.$('.source-row').hide();
    }

    if (calEvent.recurring) {
      this.$('.repeat-time-row')
        .removeClass('hidden')
        .find('.repeat-time')
        .text(this._getRepeatTimeText(block));
    } else {
      this.$('.repeat-time-row').addClass('hidden');
      var time = this._getRepeatTime(block);
      this.$('.repeat-date-from').append(document.createTextNode(' ' + time.from));
      this.$('.repeat-date-to').append(document.createTextNode(' ' + time.to));
    }
  },

  _getRepeatTypeText: function (block) {
    var key = 'daily';
    switch (block.availabilityRuleTypeCode) {
      case 'W':
        key = 'weekly';
        break;

      case 'B':
        key = 'otherWeekly';
        break;

      case 'P':
      case 'D':
        key = 'none';
        break;
    }
    return Wahanda.lang.calendar.blockTime.labels.repeat[key];
  },

  _getRepeatTime: function (block) {
    var format = App.config.get('jqueryDateFormat').defaultTime;
    return {
      from: Wahanda.Time.toFormatted(Wahanda.Time.timeToMinutes(block.timeFrom), format),
      to: Wahanda.Time.toFormatted(Wahanda.Time.timeToMinutes(block.timeTo), format),
    };
  },

  _getRepeatTimeText: function (block) {
    var time = this._getRepeatTime(block);
    return time.from + ' - ' + time.to;
  },

  _getRepeatDays: function (block) {
    var days = [];

    for (let i in block.weekDays) {
      var dayNum = Wahanda.Date.dayStringToNumberMap[block.weekDays[i]];
      days.push(Wahanda.lang.date.weekdaysShort[dayNum]);
    }

    return days.join(', ');
  },

  _getSource: function ({ calEvent, block }) {
    const lang = Wahanda.lang.calendar.blockTime;
    const isExternal = calEvent.typeCode === CALENDAR_BLOCK_TYPES.EXTERNAL;
    const blockSource = calEvent.blockSource;
    const updatedDateTime = this._getDateTime(block.updated);
    const updatedByName = block.updatedByName ? block.updatedByName : lang.treatwell;
    let sourceTemplate;

    if (isExternal) {
      if (blockSource.code === CALENDAR_BLOCK_SOURCES.ICAL) {
        sourceTemplate = lang.externalSourceText;
      } else {
        sourceTemplate = lang.externalSource2WayApiText;
      }
    } else {
      sourceTemplate = lang.internalSourceText;
    }

    return Wahanda.Template.render(sourceTemplate, {
      externalSystemName: blockSource.name,
      user: updatedByName,
      date: updatedDateTime.date,
      time: updatedDateTime.time,
    });
  },

  _getDate: function (strDate) {
    return Wahanda.Date.formatDate(
      App.config.get('jqueryDateFormat').longDate,
      Wahanda.Date.createDate(strDate, '00:00'),
    );
  },

  _getDateTime: function (strDate) {
    return Wahanda.Date.formatDateTime(
      App.config.get('jqueryDateFormat').longDate,
      App.config.get('jqueryDateFormat').defaultTime,
      Wahanda.Date.createDate(strDate),
    );
  },
});
