import React from 'react';
import BigNumber from 'bignumber.js';
import { Notice } from 'components/common/Notice';
import _ from 'common/underscore';
import pathToRegexp from 'path-to-regexp';
import Loader from 'components/common/Loader';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';
import { ProductSalesReportAnalytics } from 'common/analytics';
import { allEmployeesOptionValue } from '../EmployeeFilter';
import DISPLAY from '../displayStateEnum';
import Filters from '../common/Filters';
import ReportTable from './reportTable';
import ReportLayout from '../common/ReportLayout';
import ROUTES from '../SalesReportContainer/reportRoutes';

interface IProductSalesReportProps extends React.HTMLAttributes<Element> {
  match: {
    params: {
      venueId: string;
      fromDate?: string;
      toDate?: string;
      employeeFilter?: string;
    };
  };
  history: {
    push: (...args: any[]) => any;
  };
  requestProductSalesReportDataAction: (...args: any[]) => any;
  reportData?: any;
  displayState: any;
  params?: any;
  push?: any;
}

export class ProductSalesReport extends React.Component<IProductSalesReportProps, {}> {
  static defaultProps = {
    reportData: null,
  };

  componentDidMount = () => {
    ProductSalesReportAnalytics.trackPageView();
    this.requestDataFromApi();
  };

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(this.props.match.params, prevProps.match.params)) {
      this.requestDataFromApi();
    }
  };

  onFilterChange = (filters) => {
    const { venueId } = this.props.match.params;
    const { fromDate, toDate, selectedEmployeeId } = filters;
    const toPath = pathToRegexp.compile(ROUTES.PRODUCT_SALES_REPORT);
    const newPath = toPath({
      venueId,
      fromDate: fromDate.formatApiDateString(),
      toDate: toDate.formatApiDateString(),
      employeeFilter: selectedEmployeeId,
    });
    this.props.history.push(newPath);
  };

  getLoadingSpinner = () => <Loader />;

  getErrorMessage = () => <div>{Wahanda.lang.reports.sales.error}</div>;

  getQueryParams = () => {
    const { venueId, fromDate, toDate, employeeFilter } = _.defaults(this.props.match.params, {
      fromDate: moment().startOf('month').formatApiDateString(),
      toDate: moment().endOf('month').formatApiDateString(),
      employeeFilter: allEmployeesOptionValue,
    });
    return {
      venueId,
      fromDate: moment(fromDate),
      toDate: moment(toDate),
      employeeFilter: new BigNumber(employeeFilter).toNumber(),
    };
  };

  trackDateFilterChange = (data) => {
    ProductSalesReportAnalytics.trackReportDateFilterChange(data.type);
  };

  requestDataFromApi = () => {
    const { venueId, fromDate, toDate, employeeFilter } = this.getQueryParams();
    const employee = employeeFilter === allEmployeesOptionValue ? null : employeeFilter;
    this.props.requestProductSalesReportDataAction(
      venueId,
      moment(fromDate),
      moment(toDate),
      employee,
    );
  };

  render() {
    let body;
    switch (this.props.displayState) {
      case DISPLAY.WAITING:
        body = this.getLoadingSpinner();
        break;
      case DISPLAY.REPORT:
        body = (
          <ReportTable rows={this.props.reportData.rows} total={this.props.reportData.total} />
        );
        break;
      case DISPLAY.ERROR:
        body = this.getErrorMessage();
        break;
      default:
        throw new Error(`Unrecognised display state: ${this.props.displayState}`);
    }
    const dateFilterProps = {
      uniqueId: 'productSalesReportDateFilter',
      fromDate: this.getQueryParams().fromDate,
      toDate: this.getQueryParams().toDate,
    };
    const filters = (
      <Filters
        date={dateFilterProps}
        employee={{
          categoryFilterEnabled: true,
          selectedEmployeeId: this.getQueryParams().employeeFilter,
        }}
        onFilterChange={this.onFilterChange}
        onDatePickerChange={this.trackDateFilterChange}
        onEmployeeChange={ProductSalesReportAnalytics.trackEmployeeFilterChange}
      />
    );
    const messages = (
      <Notice type="success">
        <p
          dangerouslySetInnerHTML={{
            __html: Wahanda.lang.reports.newReportsMessage,
          }}
        />
      </Notice>
    );
    return (
      <ReportLayout
        messages={messages}
        filter={filters}
        showTimeStamp={this.props.displayState === DISPLAY.REPORT}
        body={body}
      />
    );
  }
}
