import React from 'react';
import {
  DialogFooter,
  DialogFooterButton,
  DialogFooterGroup,
  DialogFooterGroupItem,
} from 'components/common/DialogFooter';
import Loader from 'components/common/Loader';
import ReactDialog from 'components/common/react-dialog';
import Wahanda from 'common/wahanda';
import apiUrl from 'common/api-url';
import { OrderProducts } from 'common/analytics';
import { OrderDetails } from './OrderDetails';
import ProductsOrdered from './ProductsOrdered';
import ConfirmCancellationDialog from '../ConfirmCancellationDialog';
import { ORDER_STATUS_ORDERED, ORDER_STATUS_COMPLETED } from '../constants';
import style from './style.scss';

const LANG = Wahanda.lang.menu.products.orders.orderDetailsDialog;
interface IOrderDetailsDialogProps extends React.HTMLAttributes<Element> {
  orderId: number;
  onClose: (...args: any[]) => any;
  existingOrder?: {
    created?: string;
    id?: number;
    status?: string;
    productOrderItems?: {
      quantity?: number;
      id?: number;
      name?: string;
      venueProductId?: number;
    }[];
  };
  allowToProcess?: boolean;
  productSupplier?: {
    name: string;
  };
  actions: {
    requestProductOrderAction: (...args: any[]) => any;
    requestCancelProductOrderAction: (...args: any[]) => any;
    productOrderCanBeProcessed: (...args: any[]) => any;
  };
  status?: any;
  productOrderItems?: any;
  created?: any;
  productOrderCanBeProcessed?: any;
  requestCancelProductOrderAction?: any;
  requestProductOrderAction?: any;
}
type OrderDetailsDialogState = {
  trackingEventsHaveBeenSent?: boolean;
  showingConfirmCancellationDialog?: boolean;
};

export default class OrderDetailsDialog extends React.Component<
  IOrderDetailsDialogProps,
  OrderDetailsDialogState
> {
  static defaultProps = {
    existingOrder: null,
    allowToProcess: false,
  };

  state = {
    showingConfirmCancellationDialog: false,
    trackingEventsHaveBeenSent: false,
  };

  componentDidMount() {
    this.props.actions.requestProductOrderAction(this.props.orderId);
  }

  onCancelOrder = () => {
    OrderProducts.trackClickCancelOrder();
    this.props.actions.requestCancelProductOrderAction(this.props.orderId);
    this.props.onClose();
  };

  onCloseWithTracking = () => {
    OrderProducts.trackClickCloseDialog();
    this.props.onClose();
  };

  onFooterButtonDownloadPdfClick = () => {
    this.downloadPdf();
    this.props.onClose();
  };

  getPdfUri = () =>
    apiUrl('PRODUCT_ORDER_PDF_DOWNLOAD', {
      productOrderId: this.props.orderId,
    });

  handleTracking = () => {
    const { existingOrder, allowToProcess } = this.props;
    if (allowToProcess) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (existingOrder.status === ORDER_STATUS_ORDERED) {
        OrderProducts.trackViewOrderedItem();
      }
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (existingOrder.status === ORDER_STATUS_COMPLETED) {
        OrderProducts.trackViewCompletedOrder();
      }
    } else {
      OrderProducts.trackViewOrderHasBeenPlaced();
    }
    this.setState({ trackingEventsHaveBeenSent: true });
  };

  downloadPdf = () => {
    OrderProducts.trackClickDownloadPdf();
    window.open(this.getPdfUri());
  };

  handleProcessClicked = () => {
    OrderProducts.trackClickProcessOrder();
    this.props.actions.productOrderCanBeProcessed();
  };

  renderDialogFooter = () => (
    <DialogFooter>
      <DialogFooterButton
        title={LANG.closeButton}
        type="secondary"
        onClick={this.onCloseWithTracking}
      />
      {this.renderMenuFooterButton()}
      {this.renderActionButton()}
    </DialogFooter>
  );

  renderMenuFooterButton = () =>
    this.props.existingOrder &&
    this.props.existingOrder.status === ORDER_STATUS_ORDERED && (
      <DialogFooterGroup>
        <DialogFooterGroupItem
          title={LANG.cancelOrderButton}
          onClick={() => {
            this.setState({ showingConfirmCancellationDialog: true });
          }}
          type="critical"
        />
      </DialogFooterGroup>
    );

  renderDialogContent = () => (
    <React.Fragment>
      <OrderDetails
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
        orderDate={this.props.existingOrder.created}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
        status={this.props.existingOrder.status}
        shouldMessageBeShown={!this.props.allowToProcess}
        onDownload={this.downloadPdf}
      />
      {/* @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type '{ id: ... Remove this comment to see the full error message */}
      <ProductsOrdered products={this.props.existingOrder.productOrderItems} />
    </React.Fragment>
  );

  renderActionButton = () => {
    const ordered =
      this.props.existingOrder && this.props.existingOrder.status === ORDER_STATUS_ORDERED;
    if (ordered && this.props.allowToProcess) {
      return (
        <DialogFooterButton title={LANG.processOrderButton} onClick={this.handleProcessClicked} />
      );
    }
    if (ordered) {
      return (
        <DialogFooterButton
          title={LANG.orderDetails.downloadPdfButton}
          onClick={this.onFooterButtonDownloadPdfClick}
        />
      );
    }
    return null;
  };

  renderConfirmCancellationDialog = () =>
    this.state.showingConfirmCancellationDialog && (
      <ConfirmCancellationDialog
        title={LANG.confirmCancelationDialogTitle}
        onAccept={this.onCancelOrder}
        onReject={() => {
          this.setState({ showingConfirmCancellationDialog: false });
        }}
      />
    );

  render() {
    const { existingOrder, productSupplier } = this.props;
    if (existingOrder) {
      if (!this.state.trackingEventsHaveBeenSent) {
        this.handleTracking();
      }
    }
    const dialogTitle = LANG.title;
    return (
      <ReactDialog
        dataTest="product-order-details-modal"
        title={productSupplier ? productSupplier.name : dialogTitle}
        onClose={this.onCloseWithTracking}
        width={700}
        noContentPadding
      >
        <div data-hj-suppress className={style.dialogContent}>
          {!existingOrder ? <Loader positionAbsolute /> : this.renderDialogContent()}
        </div>
        {this.renderDialogFooter()}
        {this.renderConfirmCancellationDialog()}
      </ReactDialog>
    );
  }
}
