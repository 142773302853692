import React from 'react';
import classnames from 'classnames';
import style from './style.scss';

type TabProps = {
  tab: string;
  label: string;
  onClick?: (...args: any[]) => any;
  selectedTab: string;
  disabled?: boolean;
  error?: boolean;
};
const Tab: React.SFC<TabProps> = ({ error, selectedTab, tab, onClick, disabled, label }) => {
  const isActive = selectedTab === tab;
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={classnames(style.item, {
        [style.active]: isActive,
        [style.disabled]: disabled,
        [style.error]: error && !isActive,
      })}
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onClick={() => !disabled && onClick(tab)}
    >
      {label}
    </li>
  );
};
Tab.defaultProps = {
  onClick: () => null,
  disabled: false,
  error: false,
};

export default Tab;
