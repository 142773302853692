/**
 * POS checkout currency input view
 * Sets up the the input field with the currency symbol correctly, either before or after depending on the app config.
 */
(function () {
  if (!App.Views.POS) {
    App.Views.POS = {};
  }
  var BaseView = Backbone.View;
  App.Views.POS.PaymentButton = BaseView.extend({
    templateId: '',

    initialize: function () {},

    render: function () {
      var html = Wahanda.Template.renderTemplate(this.templateId, {
        currencySymbol: this.currency.currencySymbol,
      });

      this.$el.html(html);
    },
  });
})();
