import React from 'react';
import Wahanda from 'common/wahanda';

import { AllServices, Hamburger, Package } from 'components/common/Icon';

const lang = Wahanda.lang.menu.offer.typeChoice;

export interface ServiceListItem {
  title: string;
  description: string;
  icon: React.ReactNode;
  type?: TYPES;
  visible?: any;
}

export enum TYPES {
  SERVICE = 'treatment',
  PACKAGE = 'package',
  MENU_GROUP = 'menu-group',
}

export const services: ServiceListItem[] = [
  {
    title: lang.treatment.title,
    description: lang.treatment.description,
    icon: <AllServices size="large" />,
    type: TYPES.SERVICE,
  },
  {
    title: lang.package.title,
    description: lang.package.description,
    icon: <Package size="large" />,
    type: TYPES.PACKAGE,
  },
  {
    title: lang.menuGroup.title,
    description: lang.menuGroup.description,
    icon: <Hamburger size="large" />,
    visible: ({ singleGroup }) => !singleGroup,
    type: TYPES.MENU_GROUP,
  },
];
