import { connect } from 'react-redux';
import Wahanda from 'common/wahanda';
import { VenueOpeningConfirmation as Component } from './VenueOpeningConfirmation';
import { hideCovidBannerDialogs, covidUnlimitOperationRequest } from '../../actions';

const mapStateToProps = ({ covidBanner }) => ({
  canAccessMarketingTools: Wahanda.Permissions.canAccessMarketingTools(),
  unlimitLoading: covidBanner.unlimitLoading,
  justSuccessfullyReopened: covidBanner.justSuccessfullyReopened,
});

const mapDispatchToProps = {
  hideCovidBannerDialogs,
  covidUnlimitOperationRequest,
};

export const VenueOpeningConfirmation = connect(mapStateToProps, mapDispatchToProps)(Component);
