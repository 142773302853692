import {
  CLIENT_SAVED,
  CLIENT_SET_WALKIN,
  INITIAL_STATE_INLINE_CLIENT_CREATION,
  CLIENT_CHOSEN,
  SET_CLIENT_PROPERTIES,
  CLEAR_INLINE_CLIENT,
  SET_SEND_MASS_EMAIL,
} from 'reduxStore/actionsConst';

export function clientSaved(data) {
  return {
    type: CLIENT_SAVED,
    data,
  };
}

export function clientSetWalkin(note) {
  return { type: CLIENT_SET_WALKIN, note };
}

export function initialiseInlineClient(data) {
  const { name, phone, emailAddress } = data;
  return {
    type: INITIAL_STATE_INLINE_CLIENT_CREATION,
    data: {
      ...data,
      name: name || '',
      emailAddress: emailAddress || '',
      phone: phone || '',
    },
  };
}

export function clientChosen(data) {
  return {
    type: CLIENT_CHOSEN,
    data,
  };
}

export function setClientProperties({ name, phone, emailAddress }) {
  return {
    type: SET_CLIENT_PROPERTIES,
    data: {
      name,
      phone,
      emailAddress,
    },
  };
}

export function clearInlineClient() {
  return { type: CLEAR_INLINE_CLIENT };
}

export const setSendMassEmail = (sendMassEmails) => ({
  type: SET_SEND_MASS_EMAIL,
  sendMassEmails,
});
