import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Product extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-product', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 100 100"
      >
        <path
          d="M8.47032947e-22,43.74375 C8.47032947e-22,36.85625 5.625,31.25 12.46875,31.25 L50.03125,31.25 C56.91875,31.25 62.5,36.85 62.5,43.74375 L62.5,87.5 C62.5,94.39375 56.875,100 50.03125,100 L12.46875,100 C9.15851618,99.9966865 5.9851774,98.6785225 3.64683282,96.3354895 C1.30848824,93.9924565 -0.00331686673,90.8164838 8.47032947e-22,87.50625 L-6.27467647e-06,43.75 L8.47032947e-22,43.74375 Z M37.5,12.5 L37.5,25 L25,25 L25,12.5 C20.53125,12.5 16.79375,13.4375 13.73125,14.9625 C12.7125,15.475 11.85625,16.0125 11.16875,16.525 C10.83125,16.775 10.6625,16.925 10.66875,16.91875 L1.83125,8.08125 C3.0625,6.85 5.15625,5.275 8.14375,3.7875 C12.89375,1.4125 18.53125,0 25,0 L50,0 L50,12.5 L37.5,12.5 Z M12.5,87.5 L12.5,43.75 L50,43.75 L50,87.5 L12.5,87.5 Z M25,56.25 L25,68.75 L37.5,68.75 L37.5,56.25 L25,56.25 Z"
          transform="translate(19)"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(Product);
