import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Medical extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-medical', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path
              d="M13 4c1.657 0 3 1.343 3 3v8c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V7c0-1.657 1.343-3 3-3h10zm0 2H3c-.513 0-.936.386-.993.883L2 7v7h12V7c0-.513-.386-.936-.883-.993L13 6zM9 7v1.999L11 9v2H9v2H7v-2H5V9h2V7h2zm0-7c1.105 0 2 .895 2 2l-.001 1h-2L9 2H7l-.001 1h-2L5 2c0-1.105.895-2 2-2h2z"
              fillRule="evenodd"
            />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-medical', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 24 24"
          >
            <path
              d="M3 13v8h18v-8h2v9a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-9h2zm10-3v3h3v2h-3v3h-2v-3H8v-2h3v-3h2zm0-9a3 3 0 0 1 3 3v1h2a5 5 0 0 1 5 5v1h-2v-1a3 3 0 0 0-2.824-2.995L18 7H6a3 3 0 0 0-2.995 2.824L3 10v1H1v-1a5 5 0 0 1 5-5h2V4a3 3 0 0 1 2.824-2.995L11 1zm0 2h-2a1 1 0 0 0-1 1v1h4V4a1 1 0 0 0-.883-.993L13 3z"
              fillRule="evenodd"
            />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Medical);
