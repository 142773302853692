import React from 'react';
import { get } from 'mori';

function getButton(def) {
  if (!def) {
    return null;
  }
  return (
    <a
      className="link-button"
      href={get(def, 'link')}
      onClick={get(def, 'onClick')}
      target="_blank"
      rel="noopener noreferrer"
    >
      {get(def, 'title')}
    </a>
  );
}

export function makeBold(text) {
  return <span className="bold">{text}</span>;
}

interface ISubBlockTextProps extends React.HTMLAttributes<Element> {
  button?: {};
}

export default class SubBlockText extends React.Component<ISubBlockTextProps, {}> {
  render() {
    return (
      <div className="sub-content">
        {getButton(this.props.button)}
        {this.props.children}
      </div>
    );
  }
}
