import React from 'react';
import ReactDOM from 'react-dom/server';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';
import { LineChart, ReportTooltip, TooltipLine, SvgDonut } from 'components/common/LineChart';
import style from './style.scss';

export interface TooltipParams {
  name: string;
  color: string;
  seriesName: string;
  value: string;
}

export interface ClientAcquisitionMonthData {
  month: string;
  directFirstTimeClients: number;
  widgetFirstTimeClients: number;
  treatwellFirstTimeClients: number;
}

export interface Props {
  months: ClientAcquisitionMonthData[];
}

// eslint-disable-next-line import/no-default-export
export default class ClientAcquisitionGraph extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.tooltipFormatter = this.tooltipFormatter.bind(this);
  }

  public render() {
    const { months } = this.props;
    const lang = {
      treatwell: Wahanda.lang.reports.sales.filters.bookingChannel.treatwell,
      widget: Wahanda.lang.reports.sales.filters.bookingChannel.widget,
      connect: Wahanda.lang.reports.sales.filters.bookingChannel.connect,
    };
    const graphProps = {
      xAxisData: months.map((month) => month.month),
      xAxisFormatter: this.xAxisFormatter,
      tooltipFormatter: this.tooltipFormatter,
      series: [
        {
          name: lang.treatwell,
          data: months.map((row) => row.treatwellFirstTimeClients),
          areaColor: '#a3df8b',
          color: '#65c93e',
        },
        {
          name: lang.widget,
          data: months.map((row) => row.widgetFirstTimeClients),
          areaColor: '#ff9d88',
          color: '#ff5c39',
        },
        {
          name: lang.connect,
          data: months.map((row) => row.directFirstTimeClients),
          areaColor: '#66d0d5',
          color: '#00b0b9',
        },
      ],
    };
    return (
      <div className={style.graphContainer}>
        <div className={style.graph}>
          <LineChart {...graphProps} />
        </div>
      </div>
    );
  }

  private xAxisFormatter(param: string) {
    return moment(param).format(Wahanda.lang.reports.sales.monthlySalesReport.charts.dateFormat);
  }

  private tooltipFormatter(props: TooltipParams[]) {
    const DATE_FORMAT = Wahanda.lang.reports.clientReports.clientRetention.headers.dateFormat;
    const header = props[0].name;
    return ReactDOM.renderToStaticMarkup(
      <ReportTooltip
        header={moment(header).format(DATE_FORMAT)}
        content={props.map((data) => (
          <TooltipLine
            key={data.seriesName}
            icon={
              <SvgDonut
                outerRadius={6}
                innerRadius={4}
                outerColor={data.color}
                innerColor="white"
              />
            }
            seriesName={data.seriesName}
            value={data.value}
          />
        ))}
      />,
    );
  }
}
