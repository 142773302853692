(function($) {
	/**
	 * Adds shadows on bottom and top of a scrollable div.
	 * (!) It hopes that the parent div is absolute/relative positioned.
	 * 
	 * Options:
	 * > (int) width Force a fixed width of the shadow
	 * 
	 * (c) Wahanda
	 */

	var padding = 17;
	$.fn.shadows = function(options) {
		var data = this.data('shadows');
		if (data) {
			// Destroy the old instance
			data.destroy();
		}
		
		options          = options || {};
		
		var self   		 = this;
		var position     = this.position();
		
		if (position == null){
			// if can not find element to show - do not do anything
			return this;
		}
		
		var top			 = position.top + parseInt(this.css('margin-top'), 10);
		var height		 = this.height();
		var width        = options.width || this.width();
		var paddings     = parseInt(this.css('padding-top'), 10) + parseInt(this.css('padding-bottom'), 10);
		
		var topShadow    = createShadow('top').css({top: top - 1, left: padding});
		var bottomShadow = createShadow('bottom');
		
		this.parent().append(topShadow).append(bottomShadow);
		bottomShadow.css({top: getBottomShadowTopPosition(), left: padding}).addClass('hidden');
		topShadow.add(bottomShadow).css('left', options.left || position.left);
		
		setWidths();
		
		this.on('scroll.shadows', function() {
			var y = self.scrollTop();
			if (y > 0) {
				topShadow.removeClass('hidden');
			} else {
				topShadow.addClass('hidden');
			}

			if (getFullHeight(self) > height + y) {
				bottomShadow.removeClass('hidden');
			} else {
				bottomShadow.addClass('hidden');
			}
		}).scroll();
		
		// On window resize, check for any changed heights
		var resizeFunc = function() {
			var newHeight = self.height();
			if (height != newHeight) {
				height = newHeight;
				bottomShadow.css({top: getBottomShadowTopPosition()});
				self.scroll();
			}
			if (!options.width) {
				var newWidth = self.width();
				if (newWidth != width) {
					width = newWidth;
					setWidths();
				}
			}
		};
		$(window).resize(resizeFunc);
		
		self.data('shadows', {
			destroy: function() {
				$(window).off('resize', resizeFunc);
				self.off('scroll.shadows');
				self.removeData('shadows');
				topShadow.remove();
				bottomShadow.remove();
			}
		});
		
		function getBottomShadowTopPosition() {
			return top + height + paddings - bottomShadow.height();
		}
		function setWidths() {
			topShadow.add(bottomShadow).css("width", (options.width || self.width()));
		}
		
		return this;
	};
	
	function createShadow(type) {
		return $(
			'<div>',
			{
				"class": type + "-shadow"
			}
		);
	}
	function getFullHeight(element) {
		return element[0].scrollHeight - parseInt(element.css('padding-top'), 10) - parseInt(element.css('padding-bottom'), 10);
	}
}(jQuery));
