import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import style from './item-row.scss';

interface IItemRowProps extends React.HTMLAttributes<Element> {
  title: string;
  amount: number;
  expandable?: boolean;
  additionalClass?: string;
  onClick?: (...args: any[]) => any;
}

export default class ItemRow extends React.Component<IItemRowProps, {}> {
  static defaultProps = {
    expandable: false,
    additionalClass: '',
    onClick: () => {},
  };

  getAmount(amount) {
    const negative = amount < 0;
    const stringValue = Wahanda.Currency.format(Math.abs(this.props.amount), {
      forceDecimalPoint: true,
    });
    return (negative ? '-' : '') + stringValue;
  }

  render() {
    const title = this.props.title;
    const classNames = `item ${this.props.additionalClass || ''}`;
    const expandable = this.props.expandable ? <span className="expandable" /> : null;
    const amountClassNames = classnames(style.amount, 'amount', {
      negative: this.props.amount < 0,
    });
    return (
      <div className={classNames} onClick={this.props.onClick}>
        <span className="title">{title}</span>
        {expandable}
        <span className={amountClassNames}>{this.getAmount(this.props.amount)}</span>
      </div>
    );
  }
}
