/**
 * Create an overlay around the given DOM element, spanning whole document.
 * 
 * (c) Wahanda
 * 
 * There are 4 DIVs drawn: T, R, B, and L.
 * 
 *     TTTTTTTTTTTTTTTTTTTT 
 *     L ---------------- R
 *     L |  The inner   | R
 *     L |     DIV      | R
 *     L ---------------- R
 *     BBBBBBBBBBBBBBBBBB R
 * 
 * @param String command (optional) Command. Currently only "remove" is supported.
 */
;(function() {
	var DATA_KEY = 'overlay-around';
	
	$.fn.overlayAround = function(command, options) {
		if ('remove' === command) {
			if (hasOverlay(this)) {
				removeOverlays(this);
			}
			return this;
		}
		
		if (hasOverlay(this)) {
			// Already has overlays. Do nothing.
			return this;
		}
		
		createOverlays.call(this, options);
		
		return this;
	};
	
	function createOverlays(options) {
		options    = options || {};
		var width  = this.width();
		var height = this.height();
		var offset = this.offset();
		
		var winWidth  = $(window).width();
		var winHeight = $(window).height();
		
		var overlays = [];
		
		// if top == 0, no top header
		if (offset.top > 0) {
			overlays.push({
				top   : 0,
				left  : 0,
				width : winWidth,
				height: offset.top + (options.addTop || 0)
			});
		}
		// if left + width == winWidth, no right
		if (parseInt(offset.left + width, 10) < winWidth) {
			overlays.push({
				top   : offset.top  + (options.addTop || 0),
				left  : offset.left + width,
				width : winWidth - (offset.left + width),
				bottom: 0
			});
		}
		// if top + height == winHeight, no bottom
		if (parseInt(offset.top + height, 10) < winHeight) {
			overlays.push({
				top   : offset.top + height,
				left  : 0,
				height: winHeight - (offset.top + height),
				width : offset.left + width
			});
		}
		// if if left == 0, no left
		if (offset.left > 0) {
			overlays.push({
				top   : offset.top,
				left  : 0,
				width : offset.left,
				height: height
			});
		}
		
		var i, overlayCss, $div;
		var $divs = $();
		for (i = 0; overlayCss = overlays[i]; i++) {
			overlayCss['z-index'] = 1000; 
			$div = $('<div />', { "class": "overlay-around ui-widget-overlay" })
				.css(overlayCss)
				.appendTo('body');
			
			$divs = $divs.add($div);
		}
		
		var eventNS = 'oa_' + (new Date()).getTime();
		this.data(
			DATA_KEY,
			{
				$divs  : $divs,
				eventNS: eventNS
			}
		);
		
		var $el = this;
		$(window).on(
			'resize.' + eventNS,
			function() {
				removeOverlays($el);
				createOverlays.call($el, options);
			}
		);
	};
	
	function removeOverlays($el) {
		var data = $el.data(DATA_KEY);
		if (!data) {
			return;
		}
		
		data.$divs.remove();
		$(window).off('.' + data.eventNS);
		
		$el.removeData(DATA_KEY);
	};
	
	function hasOverlay($el) {
		return null != $el.data(DATA_KEY);
	}

}());