import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Ratings extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-ratings', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M19.0049107,2 C20.658264,2 22,3.340324 22,4.99188419 L22,16.0081158 C22,17.662542 20.656688,19 18.9972399,19 L8.99850233,19 C8.70955229,19 8.20515272,19.2090608 7.99635024,19.4178633 L6.70710678,20.7071068 L5.29289322,19.2928932 L6.58213668,18.0036498 C7.16589861,17.4198878 8.1789589,17 8.99850233,17 L18.9972399,17 C19.5540691,17 20,16.5560124 20,16.0081158 L20,4.99188419 C20,4.4453652 19.5541656,4 19.0049107,4 L4.99508929,4 C4.44468325,4 4,4.44381973 4,4.99079514 L4,23 L2,23 L2,4.99079514 C2,3.33837904 3.34098308,2 4.99508929,2 L19.0049107,2 Z M12.4610629,6.63576407 L13.2545669,9.04827443 L15.7937287,9.04827443 C16.4120945,9.04827443 16.5999539,9.65811088 16.1078001,10.0203187 L14.0733792,11.5175808 L14.8290162,13.9164771 C15.0130496,14.5007221 14.4987396,14.8748634 13.9996304,14.5190612 L11.950937,13.0586003 L9.90224354,14.5190612 C9.40346303,14.874629 8.88870301,14.5011072 9.07285773,13.9164771 L9.82849478,11.5175808 L7.79407381,10.0203187 C7.30140947,9.65773511 7.49342238,9.04827443 8.1081452,9.04827443 L10.647307,9.04827443 L11.4408111,6.63576407 C11.6326424,6.05253428 12.2706981,6.05699316 12.4610629,6.63576407 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Ratings);
