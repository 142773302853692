import scrollIntoServiceViewNeeded from 'scroll-into-view-if-needed';
import { produce, Draft } from 'immer';
import { GroupPayload, ServiceSku, Service } from '../store/types';

export function scrollIntoServiceView(node) {
  if (node === null) {
    return;
  }

  scrollIntoServiceViewNeeded(node, {
    behavior: 'smooth',
    block: 'center',
    scrollMode: 'if-needed',
  });
}

export const priceValidator = (value): boolean => {
  if (value == null || value === '' || value === 0) {
    return true;
  }
  return !isNaN(value) && value !== false;
};

/*
 *  Helpers related to SKU editing
 */

const cleanupSku = (sku: ServiceSku) => {
  if (sku.isTempId) {
    return {
      durationTimeMins: sku.durationTimeMins,
      finishingTimeMins: sku.finishingTimeMins,
      fullPriceAmount: sku.fullPriceAmount || 0,
      name: sku.name,
      skuId: undefined,
      isTempId: undefined,
    };
  }
  return sku;
};

export const getCleanedServices = (services: GroupPayload[]) =>
  Object.values(services).map((group) => ({
    ...group,
    services: group.services.map((service) =>
      produce(service, (draftState: Draft<Service>) => {
        draftState.skus = service.skus.map(cleanupSku);
      }),
    ),
  }));

export const hasDuplicateSkuName = (skus: ServiceSku[], index: number, value: string): boolean => {
  return skus.filter((_, i) => i !== index).some((sku) => sku.name === value);
};

interface ElementRect {
  bottom: number;
  top: number;
  left: number;
  right: number;
  width: number;
  height: number;
}

export interface DropdownStyle {
  top?: number;
  left?: number;
}

export const getDropdownPosition = (payload: {
  elementRect: ElementRect;
  parentRect: ElementRect;
}): DropdownStyle => {
  const { elementRect, parentRect } = payload;
  const height = elementRect.height;
  const winHeight = window.innerHeight;

  const top = (() => {
    const topAlignedToParent = parentRect.top;

    if (topAlignedToParent < 0) {
      return 10;
    }
    if (topAlignedToParent + 20 + height > winHeight) {
      return parentRect.bottom - height;
    }
    return topAlignedToParent;
  })();

  return {
    top,
    left: parentRect.left,
  };
};
