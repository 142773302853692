// When errors are caught, send details to the server with an XHR.
Wahanda.startErrorReporting = function () {
  var maxErrorsRequests = 20,
    errorRequestCount = 0,
    originalOnError = window.onerror;

  // Send details of any error to server.
  window.onerror = function errorHandler(errorMessage, url, lineNumber, colNumber, err) {
    var request = new XMLHttpRequest();

    if (errorRequestCount < maxErrorsRequests) {
      sendLogMessage();
      if (errorRequestCount === maxErrorsRequests) {
        log(maxErrorsRequests + ' error messages sent, further error messages will not be sent');
      }
    }

    // Allow the original, or default, handler to also process the error.
    if (originalOnError) {
      originalOnError(errorMessage, url, lineNumber, colNumber, err);
    } else {
      return false;
    }

    // Make XHR to send details of the error.
    function sendLogMessage() {
      var parameters;
      var infoPart =
        '\n\nVenueID: ' + String(App.getVenueId()) + '\nHref: ' + String(window.location.href);

      if (err && err.stack) {
        // Modern browsers, support for the Error as 5th param
        parameters = 'message=' + encodeURIComponent(err.stack + infoPart);
      } else {
        // Old browsers, not much info...
        parameters = 'message=' + encodeURIComponent(errorMessage + infoPart);
        if (lineNumber) {
          parameters += '&line-number=' + encodeURIComponent(lineNumber);
        }
        if (url) {
          parameters += '&source-uri=' + encodeURIComponent(url);
        }
      }

      parameters += '&level=ERROR';

      request.open('POST', App.Api.wsUrl('/client-error'), true);
      request.onreadystatechange = stateChange;
      request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      request.send(parameters);

      errorRequestCount++;
    }

    // When the send has completed log whether it was successful.
    function stateChange() {
      if (request.readyState === 4) {
        if (request.status < 300)
          // 1xx or 2xx
          log('Sent error message for "' + errorMessage + '"');
        else log('Error sending error message : ' + request.statusText + ', ' + request.status);
      }
    }

    // Safe console logger.
    // @param logMessage the message to be logged.
    function log(logMessage) {
      try {
        console.log(logMessage);
      } catch (exception) {} // eslint-disable-line
    }
  };

  // Add a parameter of "errortest=true" to any URL to force an error for testing.
  if (window.location.search.indexOf('errortest=true') !== -1) {
    // Include random value to stop server supressing consecutive identical messages.
    throw 'Test ' + Math.random();
  }
};
