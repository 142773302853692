import { xhr } from 'common/xhr';
import apiUrl from 'common/api-url';

App.Models.MenuTreatments = BackboneEx.Model.Silent.extend({
  defaults: {
    treatmentsCollection: null,
    treatmentTypesCollection: null,
  },

  initialize: function () {
    this.set({
      treatmentTypesCollection: new App.Collections.TreatmentTypes(),
      treatmentsCollection: new App.Collections.Treatments(),
    });
  },

  url: function () {
    return apiUrl('TREATMENTS', { include: 'treatment-aliases' });
  },

  parse: function (data) {
    return {
      treatmentTypesCollection: this.get('treatmentTypesCollection').reset(data.treatmentTypes),
      treatmentsCollection: this.get('treatmentsCollection').reset(data.treatments),
    };
  },

  /**
   * Adds multiple treatments to the venue.
   *
   * @param Array list Treatment list
   * @param Function successCallback
   */
  addTreatments: function (list, successCallback, errorCallback) {
    const url = App.Api.wsUrl(
      `/venue/${this.get('venueId') || App.getVenueId()}/menu/add-treatments.json`,
    );
    xhr.doJQueryAjax({
      url: url,
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify({ treatments: list }),
      success: successCallback,
      error: errorCallback,
    });
  },

  getTreatment: function (id) {
    return this.get('treatmentsCollection').get(id);
  },
});
