import { trackEvent, ACTIONS } from 'common/analytics';
import { SignatureSource } from './models';

const CATEGORY = 'signature-search';

export const SignatureSearchTracking = {
  trackOpen: () => {
    trackEvent(CATEGORY, ACTIONS.VIEW);
  },
  trackSourceSelect: (source: SignatureSource) => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'source-selected', source);
  },
  trackSubmitClick: (source: SignatureSource) => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'search', source);
  },
};
