import {
  START_CAMPAIGN_SELECTION_FLOW,
  RESET_CAMPAIGN_SELECTION_FLOW,
  SELECT_PROMOTION,
  GET_SMART_DISCOUNTS_REQUEST,
  GET_SMART_DISCOUNTS_RECEIVE,
  GET_SMART_DISCOUNTS_FAILURE,
  GET_TREATMENT_TYPES_REQUEST,
  GET_TREATMENT_TYPES_RECEIVE,
  GET_TREATMENT_TYPES_FAILURE,
  SELECT_DISCOUNT,
  SELECT_TREATMENT_GROUP,
} from 'reduxStore/actionsConst';

export const startCampaignSelection = () => ({
  type: START_CAMPAIGN_SELECTION_FLOW,
});

export const resetCampaignSelectionFlow = () => ({
  type: RESET_CAMPAIGN_SELECTION_FLOW,
});

export const selectPromotion = (data) => ({
  type: SELECT_PROMOTION,
  data,
});

export const getDiscountsRulesRequest = () => ({
  type: GET_SMART_DISCOUNTS_REQUEST,
});

export const getSmartDiscountsReceive = (data) => ({
  type: GET_SMART_DISCOUNTS_RECEIVE,
  data,
});

export const getSmartDiscountsFail = (err) => ({
  type: GET_SMART_DISCOUNTS_FAILURE,
  err,
});

export const getTreatmentGroupsRequest = () => ({
  type: GET_TREATMENT_TYPES_REQUEST,
});

export const getTreatmentGroupsReceive = (data) => ({
  type: GET_TREATMENT_TYPES_RECEIVE,
  data,
});

export const getTreatmentGroupsFail = (err) => ({
  type: GET_TREATMENT_TYPES_FAILURE,
  err,
});

export const selectDiscount = (data) => ({
  type: SELECT_DISCOUNT,
  data,
});

export const selectTreatmentGroup = (data) => ({
  type: SELECT_TREATMENT_GROUP,
  data,
});
