import {
  GET_SALES_SUMMARY_REPORT_REQUEST,
  GET_SALES_SUMMARY_REPORT_RECEIVE,
  GET_VENUE_MONTHLY_SALES_REPORT_REQUEST,
  GET_VENUE_MONTHLY_SALES_REPORT_RECEIVE,
} from 'reduxStore/actionsConst';

export const requestSalesSummaryReportAction = ({ venueId, from, to }) => ({
  type: GET_SALES_SUMMARY_REPORT_REQUEST,
  venueId,
  from,
  to,
});

export const requestSalesSummaryReportReceiveAction = (data) => ({
  type: GET_SALES_SUMMARY_REPORT_RECEIVE,
  data,
});

export const requestMonthlySalesReportAction = ({ venueId, from, to }) => ({
  type: GET_VENUE_MONTHLY_SALES_REPORT_REQUEST,
  venueId,
  from,
  to,
});

export const requestMonthlySalesReportReceiveAction = (data) => ({
  type: GET_VENUE_MONTHLY_SALES_REPORT_RECEIVE,
  data,
});
