import { get, assoc, toClj } from 'mori';
import { appState } from 'state';

import apiUrl from 'common/api-url';
import { xhr } from 'common/xhr';

export const VENUE_PRODUCTS_KEY = 'venue-products';

export const EDIT_PRODUCT_KEY = 'edit-product';

export const VENUE_PRODUCTS_REFETCH_KEY = 'venue-products-refetch';

export function fetch() {
  // Reset values before every fetch
  appState.swap((state) =>
    assoc(state, EDIT_PRODUCT_KEY, toClj({}), VENUE_PRODUCTS_REFETCH_KEY, true),
  );

  xhr.getWithPromise(apiUrl('VENUE_PRODUCTS')).done((resp) => {
    appState.swap((state) =>
      assoc(state, VENUE_PRODUCTS_KEY, toClj(resp), VENUE_PRODUCTS_REFETCH_KEY, false),
    );
  });
}

export function save(obj) {
  return xhr.postWithPromise(apiUrl('VENUE_PRODUCT'), obj);
}

export function fetchProduct(venueProductId) {
  appState.swap((state) => assoc(state, EDIT_PRODUCT_KEY, toClj({ loading: true })));
  return xhr.get(appState, EDIT_PRODUCT_KEY, apiUrl('VENUE_PRODUCT_TEMP', { venueProductId }));
}

export function editProduct(obj) {
  const venueProductId = get(obj, 'id');

  return xhr.putWithPromise(apiUrl('VENUE_PRODUCT_TEMP', { venueProductId }), obj);
}

export function deleteProduct(venueProductId) {
  return xhr.deleteWithPromise(apiUrl('VENUE_PRODUCT_TEMP', { venueProductId }));
}
