import App from 'common/backbone-app';
import apiUrl from 'common/api-url';
import * as api from 'reduxStore/apiService';
import { useQuery } from 'react-query';
import {
  ChecklistResponse,
  ChecklistTaskItem,
  ChecklistType,
} from 'components/shared/OnboardingWidget/OnboardingWidget.types';
import {
  isSelfSetupTask,
  normalizeSelfSetupTask,
} from 'components/shared/OnboardingWidget/models/SelfSetupTask';
import { useEffect, useState } from 'react';

const REFETCH_INTERVAL_MS = 1000 * 60; // 1 minute

const fetchChecklist = async ({ venueId }: { venueId: string }) => {
  const url = apiUrl('VENUE_OPPORTUNITY_CHECKLIST', { venueId });
  const response = await api.get<ChecklistResponse>(url);

  const checklist = response.checklists.find(
    (checklist) => checklist.type === ChecklistType.SELF_SETUP,
  );

  return {
    ...checklist,
    tasks: checklist?.tasks.filter(isSelfSetupTask).map(normalizeSelfSetupTask) ?? [],
  };
};

export type ChecklistCacheType = Awaited<ReturnType<typeof fetchChecklist>>;

export const checklistKey = () => ['checklist', App.getVenueId()];

export const useChecklist = () => {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const venueId = App.getVenueId();

  const query = useQuery<ChecklistCacheType>(checklistKey(), () => fetchChecklist({ venueId }), {
    retry: false,
    refetchInterval,
    onSuccess({ tasks }) {
      const kycTask = tasks?.find((task) => task.id === ChecklistTaskItem.KYC);
      const isKycStartedAndNotCompleted =
        kycTask && !kycTask.isCompleted && kycTask.steps?.stepChecked;
      setRefetchInterval(isKycStartedAndNotCompleted ? REFETCH_INTERVAL_MS : false);
    },
  });

  useEffect(() => {
    App.on(
      [
        Wahanda.Event.MENU_GROUP_UPDATED,
        Wahanda.Event.OFFER_DIALOG_SAVED,
        Wahanda.Event.SETTINGS_BANK_DETAILS_SAVED,
        Wahanda.Event.IMAGE_LIST_IMAGE_SELECTED,
        Wahanda.Event.VENUE_SAVED,
        Wahanda.Event.APPOINTMENT_SAVED,
        Wahanda.Event.SETTINGS_BILLING_DETAILS_SAVED,
      ].join(' '),
      query.refetch,
    );
  }, [query.refetch]);

  return query;
};
