import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Suppliers extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-suppliers', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M6.000002,5 C5.285002,5 4.687002,5.15 4.197002,5.394 C4.034002,5.476 3.897002,5.562 3.787002,5.644 C3.733002,5.684 3.706002,5.708 3.707002,5.707 L2.293002,4.293 C2.490002,4.096 2.825002,3.844 3.303002,3.606 C4.063002,3.226 4.965002,3 6.000002,3 L10.000002,3 L10.000002,5 L8.000002,5 L8.000002,8 L10.005002,8 C11.107002,8 12.000001,8.896 12.000001,9.999 L12.000001,19 C12.000001,20.103 11.100002,21 10.005002,21 L3.995002,21 C3.46536459,20.9994698 2.95763039,20.7885636 2.58349525,20.4136783 C2.20936012,20.038793 1.99947131,19.5306374 2.000001,19.001 L2.000001,9.999 C2.000001,8.897 2.900002,8 3.995002,8 L6.000002,8 L6.000002,5 Z M10.000002,10 L4.000002,10 L4.000002,19 L10.000002,19 L10.000002,10 Z M9.000002,12 L9.000002,14 L5.000002,14 L5.000002,12 L9.000002,12 Z M18.200002,6 L16.600002,4.4 L18.000002,3 L22.000002,7 L18.000002,11 L16.600002,9.6 L18.200002,8 L14.000002,8 L14.000002,6 L18.200002,6 Z M17.800002,16 L22.000002,16 L22.000002,18 L17.800002,18 L19.400002,19.6 L18.000002,21 L14.000002,17 L18.000002,13 L19.400002,14.4 L17.800002,16 L17.800002,16 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Suppliers);
