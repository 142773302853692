const INVALID_CHARS = /[ _\-!]/;

function split(name) {
  return name.split(' ');
}

function firstChar(str) {
  if (typeof str !== 'string') {
    return '';
  }

  let start = 0;
  const max = str.length;
  let char;

  function next() {
    const from = start;
    const to = from + 1;
    start += 1;

    return from < max ? str.substring(from, to) : null;
  }

  do {
    char = next();
  } while (char !== null && INVALID_CHARS.test(char));

  return char ? char.toUpperCase() : '';
}

export function initialsGenerator(name) {
  if (!name || typeof name !== 'string') {
    return '';
  }

  const parts = split(name);
  const firstInitial = firstChar(parts[0]);
  const secondInitial = firstChar(parts[1]);

  return firstInitial + secondInitial;
}
