import {
  GET_EXTERNAL_SALON_SYSTEM_SELECT,
  GET_EXTERNAL_SALON_INTEGRATION_REQUEST,
  GET_EXTERNAL_SALON_INTEGRATION_RECEIVE,
  GET_EXTERNAL_SALON_INTEGRATION_CREATE_REQUEST,
  GET_EXTERNAL_SALON_INTEGRATION_CREATE_RECEIVE,
  GET_EXTERNAL_SALON_EMPLOYEE_LINK_ADD,
  GET_EXTERNAL_SALON_EMPLOYEE_LINK_REMOVE,
  GET_EXTERNAL_SALON_INTEGRATION_DELETE_REQUEST,
  GET_EXTERNAL_SALON_INTEGRATION_DELETE_RECEIVE,
  GET_EXTERNAL_SALON_EMPLOYEES_REQUEST,
  GET_EXTERNAL_SALON_EMPLOYEES_RECEIVE,
  GET_EXTERNAL_SALON_SYSTEMS_REQUEST,
  GET_EXTERNAL_SALON_SYSTEMS_RECEIVE,
  GET_EXTERNAL_SALON_INTEGRATION_FAILURE,
  GET_EXTERNAL_SALON_INTEGRATION_RESET,
} from 'reduxStore/actionsConst';

export const selectExternalSalonSystemAction = (id) => ({
  type: GET_EXTERNAL_SALON_SYSTEM_SELECT,
  id,
});

export const requestExternalSalonIntegrationAction = () => ({
  type: GET_EXTERNAL_SALON_INTEGRATION_REQUEST,
});

export const receiveExternalSalonIntegrationAction = (data) => ({
  type: GET_EXTERNAL_SALON_INTEGRATION_RECEIVE,
  data,
});

export const requestExternalSalonIntegrationCreateAction = (employeeLinks) => ({
  type: GET_EXTERNAL_SALON_INTEGRATION_CREATE_REQUEST,
  employeeLinks,
});

export const receiveExternalSalonIntegrationCreateAction = () => ({
  type: GET_EXTERNAL_SALON_INTEGRATION_CREATE_RECEIVE,
});

export const addEmployeeLinkAction = (data) => ({
  type: GET_EXTERNAL_SALON_EMPLOYEE_LINK_ADD,
  data,
});

export const removeEmployeeLinkAction = (externalEmployeeId) => ({
  type: GET_EXTERNAL_SALON_EMPLOYEE_LINK_REMOVE,
  externalEmployeeId,
});

export const requestExternalSalonIntegrationDeleteAction = () => ({
  type: GET_EXTERNAL_SALON_INTEGRATION_DELETE_REQUEST,
});

export const receiveExternalSalonIntegrationDeleteAction = () => ({
  type: GET_EXTERNAL_SALON_INTEGRATION_DELETE_RECEIVE,
});

export const requestExternalSalonEmployeesAction = () => ({
  type: GET_EXTERNAL_SALON_EMPLOYEES_REQUEST,
});

export const receiveExternalSalonEmployeesAction = (data) => ({
  type: GET_EXTERNAL_SALON_EMPLOYEES_RECEIVE,
  data,
});

export const requestExternalSalonSystemsAction = () => ({
  type: GET_EXTERNAL_SALON_SYSTEMS_REQUEST,
});

export const receiveExternalSalonSystemsAction = (data) => ({
  type: GET_EXTERNAL_SALON_SYSTEMS_RECEIVE,
  data,
});

export const requestFailureAction = (ex) => ({
  type: GET_EXTERNAL_SALON_INTEGRATION_FAILURE,
  ex,
});

export const resetStateAction = () => ({
  type: GET_EXTERNAL_SALON_INTEGRATION_RESET,
});
