import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { orderToProcessRowSelectorFactory } from '../../selectors';

import { updateExistingOrder } from '../../actions';

import ProcessOrderRow from './index';

const mapStateToProps = () => {
  const getOrderToProcessRowSelector = orderToProcessRowSelectorFactory;
  return (state, props) => ({
    ...getOrderToProcessRowSelector(state.productOrders, {
      productInOrderId: props.productInOrderId,
    }),
  });
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateExistingOrder,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessOrderRow);
