;(function($) {
	/**
	 * Wahanda Date Table plugin.
	 * 
	 * It generates datepicker-like tables with the given month's date.
	 * It has no persistance. It is mainly for generating tables with HTML content.
	 * 
	 * Options:
	 * > year - year to render. Required.
	 * > month - month to render (0 as january). Required.
	 * > firstDay - OPTIONAL 0 for sunday, 1 for monday, ...
	 * > dayRenderer - OPTIONAL callback function which renders the cell.
	 *    It is given there parameters for each cell:
	 *       - Date
	 *       - isCurrentMonth (is valid)
	 *       - className (String classes, may add it's own)
	 *    It should return an Array: [className, HTML contents]
	 * > today - today's date, optional.
	 * 
	 * @copyright Wahanda
	 */
	$.fn.dateTable = function(options) {
		var opts = $.extend($.fn.dateTable.defaults, options || {});
		this.html(
			new Generator(opts).render()
		);
		return this;
	};
	
	var CLASS_TABLE    = "wahanda-datetable";
	var CLASS_WEEKEND  = "wdt-week-end";
	var CLASS_DISABLED = "wdt-disabled";
	var CLASS_TODAY    = "wdt-today";
	
	var Generator = function(options) {
		this.options       = options;
		this.options.year  = parseInt(this.options.year, 10);
		this.options.month = parseInt(this.options.month, 10);
		this.today         = this.options.today || new Date();
		
		this._setupWeekdayOrder();
		this._setupDate();
	};
	
	$.extend(Generator.prototype, {
		// Ordered weekdays
		weekdays: [],
		// main Date object for which all calculations should be made
		mainDate: null,
		// Difference between firstDay and the month's first day's weekday number
		firstDayDiff: 0,
		
		/**
		 * Render the whole table.
		 * 
		 * @return string HTML
		 */
		render: function() {
			var html = '<table class="' + CLASS_TABLE + '">';
		
			html += '<thead>';
			html += this.renderHeader();
			html += '</thead>';
			
			html += '<tbody>';
			html += this.renderDays();
			html += '</tbody>';
			
			return html + '</table>';
		},
		
		/**
		 * Renders the weekdays header.
		 */
		renderHeader: function() {
			var html = '<tr>';
			
			this._weekDayLoop(function(dayOfWeek) {
				var className = (this._isWeekend(dayOfWeek) ? CLASS_WEEKEND : "");
				html += '<th class="' + className + '">' + this.options.weekdayNames[dayOfWeek] + '</th>';
			});
			
			return html + '</tr>';
		},
		
		/**
		 * Renders the day cells.
		 */
		renderDays: function() {
			var date, className, isCurrentMonth;
			var lastWeek = false;
			var weekNo   = 0;
			var html     = '';
			var currentHtml = '';
			var addHtml  = true;
			var dayStamp = '';
			while(!lastWeek) {
				currentHtml = '<tr>';
				for (var i = 0; i < 7; i++) {
					date           = this._createDate(weekNo * 7 + i + 1);
					isCurrentMonth = this._isCurrent(date);
					dayStamp       = (isCurrentMonth ? ' data-day="' + date.getDate() + '"' : '');
					
					if (!isCurrentMonth && 0 === i && 0 !== weekNo) {
						// First day of week is not of this month. Month ended in the previous week, on last day.
						addHtml = false;
						break;
					}
					
					className = (this._isWeekend(this.weekdays[i]) ? CLASS_WEEKEND + " ": "");
					className += (isCurrentMonth ? "" : CLASS_DISABLED + " ");
					className += (this._isToday(date) ? CLASS_TODAY + " " : "");
					
					var data = this.options.dayRenderer(date, isCurrentMonth, className);
					currentHtml += '<td class="' + data[0] + '"' + dayStamp + '>' + data[1] + '</td>';
				}
				
				currentHtml += '</tr>';
				weekNo++;
				
				if (addHtml) {
					html += currentHtml;
				}
				if (!isCurrentMonth) {
					// If after looping through all week the last day is not in the current month, it was the last week.
					break;
				}
			}
			return html;
		},
		
		/**
		 * Calls the given function for all weekdays.
		 * The function is given the current week day to render, in required order.
		 * 
		 * @param Function callback Function to call
		 */
		_weekDayLoop: function(callback) {
			for (var day = 0; day < 7; day++) {
				var dayOfWeek = this.weekdays[day];
				callback.call(this, dayOfWeek);
			}
		},
		
		_isWeekend: function(dayOfWeek) {
			return dayOfWeek === 0 || dayOfWeek === 6;
		},
		
		/**
		 * Sets up weekdays in order by the "firstDay" parameter
		 */
		_setupWeekdayOrder: function() {
			var day = (this.options.firstDay % 7);
			var days = [];
			for (var n = 0; n < 7; n++) {
				days.push(day++ % 7);
			}
			this.weekdays = days;
		},
		
		/**
		 * Sets up the date (month) for which the table is rendered
		 */
		_setupDate: function() {
			this.mainDate = new Date(this.options.year, this.options.month, 1);
			
			if (!this.mainDate.getTime()) {
				throw new Error("Please set correct year and month parameters");
			}
			
			var firstDay            = this.options.firstDay;
			var weekdayNumberOfDay1 = this.mainDate.getDay();
			
			var diff = firstDay - weekdayNumberOfDay1;
			if (diff > 0) {
				diff -= 7;
			}
			
			this.firstDayDiff = diff;
		},
		
		/**
		 * Creates a date which is `daysToAdd` days away from the month's it's working on.
		 * The parameter can be negative.
		 * 
		 * @param int daysToAdd
		 * @return Date
		 */
		_createDate: function(daysToAdd) {
			// Account for firstDayOfWeek
			daysToAdd += this.firstDayDiff;
			return new Date(
				this.options.year,
				this.options.month,
				daysToAdd
			);
		},
		
		_isToday: function(date) {
			return Wahanda.Date.isEqualDates(this.today, date);
		},
		
		/**
		 * Is the given date in the current month?
		 * 
		 * @param Date date
		 * 
		 * @return boolean
		 */
		_isCurrent: function(date) {
			return this.mainDate.getMonth() === date.getMonth();
		}
	});
	
	$.fn.dateTable.defaults = {
		firstDay: 1,
		dayRenderer: function(date, isCurrentMonth, className) {
			return [
				className,
				date.getDate()
			];
		},
		weekdayNames: []
	};
}(jQuery));
