import React from 'react';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

const sizes = {
  tiny: 12,
  small: 16,
  medium: 24,
  large: 48,
};

type Sizes = 'tiny' | 'small' | 'medium' | 'large';

export interface InjectedProps {
  width: number;
  height: number;
  size?: Sizes;
}

type Props<T extends InjectedProps> = Omit<T, keyof InjectedProps> & {
  size?: Sizes;
};

export const iconWrapper = <T extends InjectedProps>(Component: React.ComponentType<T>) => {
  // this is related to a bug in TS. It is fixed in 3.2. Remove the @ts-ignore comment bellow after upgrading
  return ({ size = 'medium', ...props }: Props<T>) => {
    return (
      <Component
        // @ts-expect-error ts-migrate(2783) FIXME: 'width' is specified more than once, so this usage... Remove this comment to see the full error message
        width={sizes[size]}
        // @ts-expect-error ts-migrate(2783) FIXME: 'height' is specified more than once, so this usag... Remove this comment to see the full error message
        height={sizes[size]}
        size={size}
        {...(props as T)}
      />
    );
  };
};
