import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Tick extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-tick', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <polygon points="14.313 2 16 3.677 5.614 14 0 8.419 1.687 6.743 5.614 10.647" />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-tick', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 24 24"
          >
            <polygon points="17.293 7.293 18.707 8.707 10 17.414 5.293 12.707 6.707 11.293 10 14.586" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Tick);
