BackboneEx.View.Calendar.Alerts = Backbone.View.extend({
	animationSpeed: 300,
	datepickerViewRendered: false,
	
	initialize: function() {
		var self = this;
		App.on(Wahanda.Event.CALENDAR_DATEPICKER_RENDERED, function onDpRendered() {
			self.datepickerViewRendered = true;
			App.off(null, onDpRendered);
		});
	},
	
	render: function() {
		if (this.datepickerViewRendered || !(this instanceof App.Views.Calendar.AppointmentAlerts)) {
			this._render();
		} else {
			var self = this;
			Wahanda.Util.waitUntil(
				function() { return self.datepickerViewRendered; },
				function() { self._render(); }
			);
		}
	},
	
	isShown: function() {
		return this.$el.is(':visible');
	},
	
	show: function() {
		if (!this.isShown()) {
			this.$el.fadeIn(this.animationSpeed);
		}
	},
	
	hide: function() {
		if (this.isShown()) {
			this.$el.fadeOut(this.animationSpeed);
		}
	}
});
