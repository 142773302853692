import React from 'react';

interface Props {
  className?: string;
}

class Success extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-success', this.props.className].join(' ')}
        viewBox="0 0 48 48"
      >
        <g fill="none" fillRule="evenodd">
          <path
            d="M42.592 11.07a2.585 2.585 0 0 0-3.655 0L25.018 24.987a1.477 1.477 0 0 1-2.089 0l-6.005-6.005a2.585 2.585 0 1 0-3.655 3.655l8.094 8.094a3.692 3.692 0 0 0 5.222 0l16.007-16.008a2.585 2.585 0 0 0 0-3.655z"
            stroke="#001E62"
            fill="#3ba150"
            strokeWidth="2"
          />
          <path
            d="M38.6 4.95A23.896 23.896 0 0 0 24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24c0-2.323-.33-4.604-.974-6.79l-1.919.564c.59 2.004.893 4.094.893 6.226 0 12.15-9.85 22-22 22S2 36.15 2 24 11.85 2 24 2c4.909 0 9.57 1.611 13.382 4.537L38.6 4.95z"
            fill="#001E62"
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  }
}

export const icon = Success;
