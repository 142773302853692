export const normalizePath = (path: string): string => {
  const sanitizedPath = sanitzePath(path);

  return applyRules({
    rules: [calendarsRule, reportsRule, productsRule, settingsRule, marketingsRule],
    path: sanitizedPath,
  });
};

type Filter = (path: string) => string;

const sanitzePath: Filter = (path) =>
  [
    replaceHashBySlash,
    stripQueryParams,
    stripVenue,
    stripNumericIds,
    stripDates,
    stripISOLanguageCode,
    stripSlashAtBoundary,
  ].reduce((path, filter) => filter(path), path);

const stripISOLanguageCode: Filter = (path) =>
  path
    .split('/')
    .filter((part) => !part.match(/^[a-zA_Z]{2}(_|-)[a-zA-Z]{2}$/))
    .join('/');

const stripSlashAtBoundary: Filter = (path) => path.replace(/^\/|\/$/g, '');

const replaceHashBySlash: Filter = (path) => path.replace('#', '/');

const stripNumericIds: Filter = (path) =>
  path
    .split('/')
    .filter((part) => !part.match(/^-?\d+$/))
    .join('/');

const stripDates: Filter = (path) =>
  path
    .split('/')
    .filter((part) => !part.match(/^\d{4}-\d{2}-\d{2}$/))
    .join('/');

const stripVenue: Filter = (path) =>
  path
    .split('/')
    .filter((part) => !part.match(/^venue$/))
    .join('/');

const stripQueryParams: Filter = (path) => path.replace(/\?.*$/, '');

type Rule = (path: string) => string | undefined;

const applyRules = ({ rules, path }: { rules: Rule[]; path: string }): string => {
  for (const rule of rules) {
    const result = rule(path);
    if (result) {
      return result;
    }
  }

  return path;
};

const calendarsRule: Rule = (path) => {
  if (!path.startsWith('calendar')) {
    return undefined;
  }

  switch (true) {
    case containSections(path):
      return 'calendar/browse';
    default:
      return path;
  }
};

const reportsRule: Rule = (path) => {
  if (!path.startsWith('reports')) {
    return undefined;
  }

  const [, second, third] = path.split('/');

  switch (true) {
    case second?.includes('clientReports'):
      return `reports/clients-${third ?? 'reports'}`;
    case second?.includes('posReports'):
      return `reports/pos-${third}`;
    case second?.includes('salesReports'): {
      const lastPathKebabCase = camelCaseToKebabCase(third);
      return `reports/${lastPathKebabCase}`;
    }
    default:
      return path;
  }
};

const productsRule: Rule = (path) => {
  if (!path.startsWith('products')) {
    return undefined;
  }

  const [, second] = path.split('/');

  switch (true) {
    case containSections(path): {
      const lastPathWithoutProduct = second.replace('product-', '');
      return `products/${lastPathWithoutProduct}`;
    }
    default:
      return path;
  }
};

const settingsRule: Rule = (path) => {
  if (!path.startsWith('settings')) {
    return undefined;
  }

  const [, second, third] = path.split('/');

  switch (true) {
    case second?.includes('online-booking'): {
      return `settings/${(third ?? second)?.replace('online-', '')}`;
    }
    case containSections(path): {
      const kebabCaseParts = [second.split('-')[0], ...third.split('-')];
      return `settings/${unique(kebabCaseParts).join('-')}`;
    }
    default:
      return path;
  }
};

const marketingsRule: Rule = (path) => {
  if (!path.startsWith('marketing')) {
    return undefined;
  }

  const [, second] = path.split('/');

  switch (true) {
    case second?.includes('email-campaign'): {
      return 'marketing/campaigns';
    }
    default:
      return path;
  }
};

const containSections = (path: string): boolean => path.includes('/');

const unique = (path: string[]): string[] =>
  path.reduce((acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]), [] as string[]);

const camelCaseToKebabCase = (str: string): string =>
  str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
