import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  offerDialogChangePricingTypeAction,
  offerDialogToggleSkuAction,
  offerDialogUpdateFullPriceAmountAction,
} from '../actions';
import {
  mapEmployeeCategoryNamesWithSkusSelector,
  isSkuCombinationNotSupportedSelector,
} from '../selectors';
import PackagePricingComponent from './index';

const mapStateToProps = ({ menu: { menuOffer } }) =>
  Object.assign(
    {},
    {
      skus: menuOffer.offerDialogSkus,
      readonly: menuOffer.offerDialogReadonly,
      readonlyLight: menuOffer.offerDialogReadonlyLight,
      isPackagePricingCustom: menuOffer.isPackagePricingCustom,
      offerDialogSkus: mapEmployeeCategoryNamesWithSkusSelector(menuOffer),
      isSkuCombinationNotSupported: isSkuCombinationNotSupportedSelector(menuOffer),
      offerDialogSubServices: menuOffer.offerDialogSubServices,
    },
  );

const mapDispatchToProps = (dispatch) =>
  Object.assign(
    {},
    {
      actions: {
        offerDialogChangePricingTypeAction: bindActionCreators(
          offerDialogChangePricingTypeAction,
          dispatch,
        ),
        offerDialogToggleSkuAction: bindActionCreators(offerDialogToggleSkuAction, dispatch),
        offerDialogUpdateFullPriceAmountAction: bindActionCreators(
          offerDialogUpdateFullPriceAmountAction,
          dispatch,
        ),
      },
    },
  );

export default connect(mapStateToProps, mapDispatchToProps)(PackagePricingComponent);
