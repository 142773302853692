App.Views.Dialog.Booking.Rejection = BackboneEx.View.Dialog.ValidatingForm.extend({
  events: {
    'click .button-cancel': 'close',
    'click .order-ref': 'showBooking',
  },

  options: {
    width: 540,
    title: Wahanda.lang.bookingRejectDialog.title,
  },

  templateId: 'calendar-appointment-reject',

  _getTemplateId: function () {
    if (this.model && this.model.isWidgetBooking && this.model.isWidgetBooking()) {
      // Allow rejecting widget appts - show the full form
      return 'calendar-appointment-reject';
    }
    return 'calendar-appointment-reject-message';
  },

  setValidation: function () {
    var self = this;
    var validations = Wahanda.Validate.getValidations(
      'defaults',
      _.extend({
        submitHandler: function () {
          self.reject();
        },
      }),
    );

    this.$('form').validate(validations);
  },

  initForm: function () {
    this.$el.addClass('booking');

    this.setTexts();
    this.renderTitle();
    this.renderTable();
    this.toggleFieldsByStatus();
  },

  toggleFieldsByStatus: function () {
    this.$('.unconfirmed').toggleClass('hidden', !this.model.isUnconfirmed());
  },

  setTexts: function () {
    this.$('.t-info-title').hide();
    this.$('.t-info-text1').hide();
    this.$('.t-info-text2').html(Wahanda.lang.bookingRejectDialog.info.text2);

    this.$('.t-reject-reason').text(Wahanda.lang.bookingRejectDialog.labels.rejectionReason);
  },

  renderTable: function () {
    // Date
    this.renderAttribute('date', Wahanda.Date.formatToDefaultDate(this.model.getDatedDate()));
    // Price
    this.$('.price').text(Wahanda.Currency.format(this.model.get('itemAmount')));
    // Client name
    this.$('.consumer-name').text(this.model.get('customer').name);
    // Order ref.
    this.$('.b-order-ref').text(this.model.get('orderReference'));
  },

  renderTitle: function () {
    this.$('.appointment-info .title').text(this.model.get('itemName'));
  },

  showBooking: function () {
    this.previousUrl = null;

    var view = new App.Views.Dialog.Booking({
      model: new App.Models.Booking({
        id: this.model.get('bookingId'),
        venueId: App.config.get('venue').id,
      }),
      previousUrl: App.mainView.getCurrentStateHash(),
    });
    view.render();
    view.open();
  },

  reject: function () {
    this.disableForm();
    var self = this;
    this.model.reject({
      reason: this.$('textarea').val(),
      notifyConsumer: this.$('#reject-notifyConsumer').is(':checked'),
      success: function () {
        self.resetCloseChangesCheck();
        self.close();
      },
      error: function () {
        self.enableForm();
        self
          .$('button[type=submit]')
          .errorTip(Wahanda.lang.calendar.appointments.errors.couldNotReject);
      },
    });
  },
});
