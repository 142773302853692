App.Routers.Calendar = Backbone.Router.extend({
  routes: {
    // Appointment type links
    'venue/:id/appointment-reschedule/:list/:customerId/:date': 'openRescheduleMode',
    'venue/:id/appointment-rebooking/:list/:customerId/:date': 'openRebookingMode',
    'venue/:id/appointment/appointment/:id': 'openAppointment',
    'venue/:id/appointment/appointment/:id/lwauth/:token': 'openAppointment',
    'venue/:id/appointment/appointment/:id/lwauth/:token/action/:action': 'openAppointment',
    'venue/:id/appointment/appt-order/:id': 'openAppointmentOrder',
    'venue/:id/appointment/appt-order/:id/lwauth/:token': 'openAppointmentOrder',
    'venue/:id/appointment/appt-order/:id/lwauth/:token/action/:action': 'openAppointmentOrder',
    'venue/:id/appointment': 'openVenueInAppointmentMode',
    'venue/:id/appointment/:type/:date/:resourceId': 'openVenueInAppointmentMode',
    'venue/:id/appointment/:type/:date/:resourceId/:dialogType/:bookingId':
      'openVenueInAppointmentMode',
    'venue/:id': 'openVenue',
    'venue/:id/': 'openVenue',
    ':type/:date/:resourceId': 'defaultVenue',
    // DEPRECATED, only for transition to new links
    'venue/:id/appointment/:id': 'openAppointment',
    'venue/:id/appointment/:id/lwauth/:token': 'openAppointment',
    'venue/:id/appointment/:id/lwauth/:token/action/:action': 'openAppointment',
    'venue/:id/:type/:date/:resourceId': 'openVenueInAppointmentMode',
    'venue/:id/:type/:date/:resourceId/:dialogType/:bookingId': 'openVenueInAppointmentMode',
    // DEPRECATED, sunsetting escapes
    'venue/:id/spa-:type/:date/openOrder/:bookingId/lwauth/:auth/action/:action':
      'handleDeprecatedRoute',
    'venue/:id/spa-:type': 'handleDeprecatedRoute',
    'venue/:id/spa-:type/:date': 'handleDeprecatedRoute',
    'venue/:id/spa-:type/:date/:resourceId': 'handleDeprecatedRoute',
  },

  openRescheduleMode: function (venueId, strListToReschedule, customerId, date) {
    this.openActionMode(venueId, 'reschedule', strListToReschedule, date, customerId);
  },

  openRebookingMode: function (venueId, strListToReschedule, customerId, date) {
    this.openActionMode(
      venueId,
      'rebooking',
      strListToReschedule,
      'today', // will be converted to a valid date, as this one isn't
      customerId,
      date,
    );
  },

  openActionMode: function (venueId, mode, strListToReschedule, date, customerId, rebookingDate) {
    App.mainViewOptions = {
      calendarAction: mode,
      initialCalendarType: 'appointment',
      stringListForAction: strListToReschedule,
      customerToRescheduleFor: customerId,
      initialDate: date,
      rebookingDate: rebookingDate,
    };
    App.setVenue(venueId);
  },

  openVenue: function (id, calendarType, type, date, resourceId, dialogType, dialogTypeId) {
    App.mainViewOptions = {
      initialCalendarType: calendarType,
      initialType: type,
      initialDate: date,
      initialResourceId: resourceId,
    };

    if (dialogType) {
      App.mainViewOptions.initialDialog = {
        type: dialogType,
        id: dialogTypeId,
      };
    }

    App.setVenue(id);
  },

  openVenueInAppointmentMode: function (id, type, date, resourceId, dialogType, dialogTypeId) {
    this.openVenue(id, 'appointment', type, date, resourceId, dialogType, dialogTypeId);
  },

  handleDeprecatedRoute: function (id, type, date, resourceId) {
    this.openVenue(id, 'appointment', 'day', date, resourceId);
  },

  defaultVenue: function (type, date, resourceId) {
    this.openVenue(null, 'appointment', type, date, resourceId);
  },

  openAppointment: function (venueId, id, token, action) {
    this._openUnifiedAppointmentView('appointment', venueId, id, token, action);
  },

  openAppointmentOrder: function (venueId, id, token, action) {
    this._openUnifiedAppointmentView('appointment-order', venueId, id, token, action);
  },

  _openUnifiedAppointmentView: function (type, venueId, id, token, action) {
    App.mainViewOptions = {
      initialCalendarType: 'appointment',
      initialDialog: {
        type: type,
        id: id,
        action: action,
      },
    };
    if (token) {
      App.setLightweightAuthKey(token);
    }
    App.allowLightweightAuthentication = true;
    App.fromExternalSource = App.fromExternalSource || !!token;
    App.initVenue(venueId);
  },
});
