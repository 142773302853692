import { TogglableSetting } from 'components/common/SettingBox';

export enum SETTING {
  GENERAL = 'sendEmailNotifications', // keep clients up to date
  SEND_HELPFUL_REMINDERS = 'sendSmsReminders;sendEmailReminders',
  ASK_FOR_REVIEWS = 'directReviewRequestEnabled',
  INVITES_TO_REBOOK = 'sendInvitesToRebook',
  CONSENT_FOR_APPVOCACY = 'consentedForAppAdvocacy',
  SEND_COVID_CONSENT_FORMS = 'sendCovidConsentForms',
  SEND_BIRTHDAY_CONGRATULATIONS = 'sendBirthdayCongratulations',
  SEND_PATCH_TEST_INVITES = 'sendPatchTestInvites',
}

export enum AutomatedMessagingCategory {
  MARKETING = 'marketing',
  TRANSACTIONAL = 'transactional',
}

export interface AutomatedMessagingSetting extends TogglableSetting {
  name: SETTING;
  category: AutomatedMessagingCategory;
  trunkFeatureName?: string;
  channelFeature?: string;
}

export interface ReminderSettings {
  sendEmailReminders: boolean;
  emailReminderHours?: number;
  sendSmsReminders: boolean;
  smsReminderHours?: number;
  smsFromNumber?: string;
}

export interface SendEmailNotificationsSetting {
  [SETTING.GENERAL]: boolean;
}

export interface SendHelpfulReminderSetting {
  [SETTING.SEND_HELPFUL_REMINDERS]: boolean;
}

export interface ConsentForAppAdvocacy {
  [SETTING.CONSENT_FOR_APPVOCACY]: boolean;
}

export interface AskForReviewSetting {
  [SETTING.ASK_FOR_REVIEWS]: boolean;
}

export interface InvitesToRebookSetting {
  [SETTING.INVITES_TO_REBOOK]: boolean;
}

export interface BirthdayCongratulationsSetting {
  [SETTING.SEND_BIRTHDAY_CONGRATULATIONS]: boolean;
}

export interface SendPatchTestInvitesSetting {
  [SETTING.SEND_PATCH_TEST_INVITES]: boolean;
}

export interface AutomatedMessagingSettings
  extends SendEmailNotificationsSetting,
    SendHelpfulReminderSetting,
    AskForReviewSetting,
    ConsentForAppAdvocacy,
    InvitesToRebookSetting,
    SendPatchTestInvitesSetting,
    BirthdayCongratulationsSetting {}
