/* eslint-disable func-names */
/* global _ */
App.Views.Forms.MultipleMenuTreatments = App.Views.Forms.MultipleMenuServices.extend({
  events: {
    'click .button-cancel': 'close',
    'change input[type=checkbox]': 'onCheckboxClicked',
    'submit form': 'save',
  },

  options: {
    width: 680,
    saveCallback: null,
    selectedTreatments: [],
    allowEmpty: false,
  },

  postInitialize: function () {
    this.sortingInited = false;
    this.selected = {};
    this.renderedGroups = [];

    const self = this;
    this.model.on('change', function () {
      self.setupDialog();
    });
  },

  setupDialog: function () {
    this.renderTabs();
    this.renderTabContent();

    try {
      this.$('#multiple-services-tabs').tabs('destroy');
    } catch (e) {
      console.error(e);
    }

    this.$('#multiple-services-tabs').tabs();

    this.$('.sorting').addClass('hidden');
  },

  triggerOpenEvent: function () {
    /* do not trigger anything */
  },

  renderTabs: function () {
    const groups = this.model.get('groupsCollection');
    const offers = this.model.get('offersCollection');
    let params = [];
    const self = this;
    this.renderedGroups = [];

    groups.each(function (group) {
      const groupServices = offers.getByGroupId(group.id, self.serviceFilterCallback);
      if (groupServices.length > 0) {
        const itemCount = self.getActiveTreatmentCount(group.get('id'));
        params.push({
          id: group.get('id'),
          title: group.get('name'),
          count: itemCount,
          showCount: itemCount > 0,
        });

        self.renderedGroups.push({
          group: group,
          services: groupServices,
        });
      }
    });

    params = _.sortBy(params, function (item) {
      return item.title;
    });

    this.$('.multiple-services-groups-list').html(
      Wahanda.Template.renderTemplate('menu-group-treatment-type-items', {
        types: params,
      }),
    );
  },

  renderTabContent: function () {
    const params = [];
    const self = this;
    _.each(this.renderedGroups, function (rendered) {
      const group = rendered.group;
      const services = rendered.services;
      const item = {
        treatmentGroupId: group.get('id'),
        treatments: [],
      };

      for (let i = 0, len = services.length; i < len; i++) {
        item.treatments.push({
          id: services[i].get('id'),
          name: services[i].get('name'),
          checked: self.isTreatmentChecked(services[i]),
        });
      }

      params.push(item);
    });

    this.$('.multiple-service-items').html(
      Wahanda.Template.renderTemplate('menu-group-service-service-items', {
        treatmentTypes: params,
      }),
    );
  },

  /**
   * Callback for filtering services out of all offers collection.
   *
   * @param Backbone.Model offer
   * @return boolean
   */
  serviceFilterCallback: function (offer) {
    return offer.get('menuItemTypeCode') === 'T';
  },

  /**
   * Returns active checked treatments for the given treatment type.
   *
   * @param int typeId
   * @return int Count
   */
  getActiveTreatmentCount: function (typeId) {
    const treatments = this.model.get('offersCollection').getByGroupId(typeId);
    const self = this;
    return _.reduce(
      treatments,
      function (memo, t) {
        return memo + (self.isTreatmentChecked(t) ? 1 : 0);
      },
      0,
    );
  },

  /**
   * Saves the changes on the model.
   */
  save: function () {
    const list = [];

    this.$('input[type=checkbox]:checked').each(function () {
      list.push(parseInt($(this).val(), 10));
    });

    this.$('.save-action').action('doing');
    this.disableForm();

    this.options.saveCallback(list, this);
    // Cancel form submit
    return false;
  },
});
