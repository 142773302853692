/* global App Wahanda BackboneEx _ */
App.Views.Dashboard.Interactions = BackboneEx.View.Base.extend({
  graphTemplateId: 'dashboard-stats-graph-template',

  initialize: function () {
    this.model = new App.Models.StatsInteractions();
    // Show tooltips on hover
    this.enableTooltips();
  },

  enableTooltips: function () {
    this.$el.tooltipize({
      position: {
        my: 'bottom center',
        at: 'top left',
        adjust: {
          x: 96,
          y: 0,
        },
      },
      style: {
        tip: {
          height: 8,
        },
      },
    });
  },

  render: function () {
    var self = this;
    var date = this.getStatsDate();
    var interactions = Wahanda.Cache.LocalStorage.interactionsStats(date);
    interactions.done(function (model) {
      self.model = model;
      self.renderData();
    });
  },

  getStatsDate: function () {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth() - 5, 1);
  },

  renderData: function () {
    var self = this;
    var statsList = ['visits', 'bookings'];
    var date = this.getStatsDate();

    _.each(statsList, function (type) {
      var data = self.model.getInteractionsByType(type, date);
      self.renderChart(type, data);
    });

    this.$el.wShow();
  },

  renderChart: function (type, data) {
    var $block = this.$('.b-' + type);
    var last = data[data.length - 1];

    $block
      .find('.v-value')
      .text(Wahanda.Number.formatNumber(last.value, App.config.getNumberFormat()));

    // Calculate graph bars
    var minHeight = 5;
    var bars = [];
    // Get the maximum value.
    var max = Math.max.apply(Math, _.pluck(data, 'value'));
    max = max > 0 ? max : 1;
    _.each(data, function (bar) {
      var heightPercent = parseInt((bar.value / max) * 100, 10) || 0;
      if (heightPercent < minHeight) {
        heightPercent = minHeight;
      }
      var date = Wahanda.Date.createDate(bar.date);
      bars.push({
        height: heightPercent,
        title: Wahanda.Date.formatDate('M yy', date) + ': ' + bar.value,
      });
    });

    $block.find('.graph').html(
      Wahanda.Template.renderTemplate(this.graphTemplateId, {
        bars: bars,
      }),
    );
  },
});
