import React from 'react';
import classnames from 'classnames';
import style from './Loader.scss';

interface Props {
  positionAbsolute?: boolean;
}

export const Loader = (props: Props) => {
  const { positionAbsolute } = props;
  const classes = classnames(style.loaderContainer, {
    [style.positionAbsolute]: positionAbsolute,
  });
  const renderLoaderSvg = () => (
    <svg width="80px" height="80px" viewBox="0 0 80 80" version="1.1">
      <defs>
        <clipPath id="tw-script">
          <path d="M67.4940991,25.9984619 L77.0759351,25.9984619 L70.8982007,38.1648682 L70.4569897,38.1648682 C70.4569897,35.1390869 69.0700757,29.0873291 67.4940991,25.9984619 Z M57.7231616,25.9984619 L67.2419116,47.7467041 L62.6401538,57.8957275 L62.0096851,57.8957275 L47.8891772,25.9984619 L57.7231616,25.9984619 Z M44.9894507,57.8958057 L44.3589819,57.8958057 L32.0665991,30.2219775 L22.202146,30.2219775 L22.202146,25.99854 L40.0724585,25.99854 L49.5912085,47.7465869 L44.9894507,57.8958057 Z M26.677771,52.6006104 C28.0015991,52.6006104 29.3254272,52.3484619 30.7752319,51.844165 L30.7752319,52.285376 C28.3797241,56.0047119 24.9758179,58.0849854 20.6890991,58.0849854 C15.0787476,58.0849854 10.9812866,54.5547119 10.9812866,47.8726807 L10.9812866,30.2220947 L5.55999756,30.2220947 L5.55999756,29.52854 L19.2392944,17.1099854 L19.9957397,17.1099854 L19.9957397,45.2250244 C19.9957397,49.8269775 22.5172241,52.6006104 26.677771,52.6006104 Z" />
        </clipPath>
      </defs>
      <g clipPath="url(#tw-script)">
        <rect width="80" height="80" fill="#00B0B9" />
        <circle
          cx="0"
          cy="0"
          r="113"
          fill="#001E62"
          className={classnames(style.circle, style['circle-1'])}
        />
        <circle
          cx="80"
          cy="40"
          r="113"
          fill="#FF5C39"
          className={classnames(style.circle, style['circle-2'])}
        />
        <circle
          cx="0"
          cy="80"
          r="113"
          fill="#D6CF8D"
          className={classnames(style.circle, style['circle-3'])}
        />
        <circle
          cx="80"
          cy="0"
          r="113"
          fill="#FF808B"
          className={classnames(style.circle, style['circle-4'])}
        />
        <circle
          cx="40"
          cy="80"
          r="113"
          fill="#EFDF00"
          className={classnames(style.circle, style['circle-5'])}
        />
        <circle
          cx="0"
          cy="40"
          r="113"
          fill="#78D64B"
          className={classnames(style.circle, style['circle-6'])}
        />
        <circle
          cx="80"
          cy="80"
          r="113"
          fill="#001E62"
          className={classnames(style.circle, style['circle-7'])}
        />
        <circle
          cx="40"
          cy="0"
          r="113"
          fill="#00B0B9"
          className={classnames(style.circle, style['circle-8'])}
        />
      </g>
    </svg>
  );
  return (
    <div className={classes}>
      <div className={style.loader}>{renderLoaderSvg()}</div>
    </div>
  );
};

export default Loader;
