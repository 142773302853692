import React from 'react';
import moment from 'common/moment';
import urlTemplate from 'url-template';
import { connect } from 'react-redux';
import _ from 'common/underscore';
import { MonthlySalesReportAnalytics } from 'common/analytics';
import { getEmployeeIdListForReports } from '../selectors';
import { BaseCsvDownloadLink } from '../common/BaseCsvDownloadLink';
import { CHANNEL } from '../BookingChannelFilter';

type CsvDownloadLinkProps = {
  fromDate: string;
  toDate: string;
  employees?: number[];
  selectedChannel: string;
};
const CsvDownloadLink: React.SFC<CsvDownloadLinkProps> = ({
  fromDate,
  toDate,
  employees,
  selectedChannel,
}) => {
  const getQueryParams = () => {
    const params = _.defaults(
      { fromDate, toDate, employees, selectedChannel },
      {
        fromDate: moment().startOf('month').subtract(11, 'months').formatApiDateString(),
        toDate: moment().endOf('month').formatApiDateString(),
        selectedChannel: CHANNEL.ALL,
      },
    );
    return params;
  };
  const url = urlTemplate
    .parse('reports/sales/monthly/csv{?employees,from,to,bookingChannel}')
    .expand({
      from: getQueryParams().fromDate,
      to: getQueryParams().toDate,
      employees: getQueryParams().employees,
      bookingChannel:
        getQueryParams().selectedChannel === CHANNEL.ALL
          ? undefined
          : getQueryParams().selectedChannel,
    });
  return (
    <BaseCsvDownloadLink
      relativeReportUrl={url}
      onClick={MonthlySalesReportAnalytics.trackCsvDownloadClick}
    />
  );
};
CsvDownloadLink.defaultProps = {
  employees: undefined,
};

export { CsvDownloadLink };

const connector = connect((state, props) => {
  return {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'match' does not exist on type '{}'.
    fromDate: props.match.params.fromDate,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'match' does not exist on type '{}'.
    toDate: props.match.params.toDate,
    employees: getEmployeeIdListForReports(state, {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'match' does not exist on type '{}'.
      employmentStatusOrId: Number(props.match.params.employeeFilter),
    }),
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'match' does not exist on type '{}'.
    selectedChannel: props.match.params.selectedChannel,
  };
})(CsvDownloadLink);

export default connector;
