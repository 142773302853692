import uuid from 'uuid';
import {
  getPricingType,
  getEmployeeCategoriesFromEmployees,
  updateSkusForCategories,
  createSkusOfType,
  usesEmployeeCategories,
  isSkuCombinationSupported,
  add2dGrouping,
  reduceSkusToCustomType,
  doesUseExistingCategory,
  addMissingCategories,
  getReusableSkuValues,
} from 'components/menu/OfferTreatmentPricingAndEmployees/utils';

import {
  PRICING_TYPE_CUSTOM,
  PRICING_TYPE_BY_EMPLOYEE_CAT,
  PRICING_TYPE_2D,
} from 'components/menu/OfferTreatmentPricingAndEmployees/constants';

function convertCustomTo2dPricing(skus, selectedEmployees, employeeCategoriesCollection) {
  const skusWithEmployeePricing = createSkusOfType(true, {
    selectedEmployees,
    employeeCategoriesCollection,
  });

  let list = [];

  skus.forEach((existingCustomSku) => {
    const groupingId = uuid.v4();

    list = list.concat(
      skusWithEmployeePricing.map((employeeCatSku) => {
        return {
          ...employeeCatSku,
          ...getReusableSkuValues(existingCustomSku),
          groupingId,
        };
      }),
    );
  });

  return list;
}

function convert2dToCustomPricing(skus) {
  const list = [];
  const seen = [];

  skus.forEach((sku) => {
    const seenKey = sku.groupingId || sku.name;

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    if (seen.includes(seenKey)) {
      return;
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    seen.push(seenKey);

    list.push(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      Object.assign({}, sku, {
        id: null,
        employeeCategoryId: null,
        tempId: uuid.v4(),
        groupingId: null,
      }),
    );
  });

  return list;
}

function countWithCategories(employees) {
  if (!Array.isArray(employees)) {
    return 0;
  }

  return employees.reduce(
    (count, employee) => count + (employee.employeeCategoryId > 0 ? 1 : 0),
    0,
  );
}

function mergeSkusWithEmployees(skus, selectedEmployees, employeeCategoriesCollection) {
  return updateSkusForCategories(
    skus,
    getEmployeeCategoriesFromEmployees(selectedEmployees, employeeCategoriesCollection),
    (sku) => {
      // eslint-disable-next-line no-param-reassign
      sku.tempId = uuid.v4();
    },
  );
}

/**
 * Converts SKUs to the required structure, based on staff pricing flag and staff categories chosen.
 *
 * @param {Array} currentSkus Currently rendered SKU list.
 * @param {Object} props Current props
 * @param {Object} nextProps Incoming props
 * @param {Boolean} wasChanged Was there a change on the form since initial render of the data?
 * @returns {Object} New SKUs or original SKUs from props.
 */
export function convertSkusForProps({ currentSkus, props, nextProps, wasChanged }) {
  const currentType = getPricingType(currentSkus);

  if (props.useEmployeeCategoryPricing !== nextProps.useEmployeeCategoryPricing) {
    // The user switched the type of employee category use in pricing.
    // We need to create (or reuse previous ones) the pricing type SKUs.

    if (
      !wasChanged &&
      usesEmployeeCategories(props.skus) === nextProps.useEmployeeCategoryPricing
    ) {
      // Use the same employee categories if no form changes were done.
      return props.skus;
    }

    const newSkus = (() => {
      const originalType = getPricingType(props.skus);

      if (nextProps.useEmployeeCategoryPricing && currentType === PRICING_TYPE_CUSTOM) {
        // Convert from custom to 2d with multiple names
        return convertCustomTo2dPricing(
          currentSkus,
          nextProps.selectedEmployees,
          nextProps.employeeCategoriesCollection,
        );
      }
      if (!nextProps.useEmployeeCategoryPricing && currentType === PRICING_TYPE_2D) {
        return convert2dToCustomPricing(currentSkus);
      }
      if (nextProps.useEmployeeCategoryPricing && originalType === PRICING_TYPE_BY_EMPLOYEE_CAT) {
        return mergeSkusWithEmployees(
          props.skus,
          nextProps.selectedEmployees,
          nextProps.employeeCategoriesCollection,
        );
      }
      return createSkusOfType(nextProps.useEmployeeCategoryPricing, {
        selectedEmployees: nextProps.selectedEmployees,
        employeeCategoriesCollection: nextProps.employeeCategoriesCollection,
      });
    })();

    return newSkus;
  }

  if (
    usesEmployeeCategories(currentSkus) &&
    countWithCategories(props.selectedEmployees) !==
      countWithCategories(nextProps.selectedEmployees)
  ) {
    // Need to add/remove any SKUs for changed employee categories.
    return mergeSkusWithEmployees(
      currentSkus,
      nextProps.selectedEmployees,
      nextProps.employeeCategoriesCollection,
    );
  }

  return currentSkus;
}

function initialisedSkus(skus) {
  return {
    offerDialogSkus: skus,
  };
}

export function initialiseSkus({
  skus,
  employeeCategoriesCollection,
  selectedEmployees,
  newSkuPricingType,
}) {
  if (skus.length === 0) {
    const useEmployeeCategoryPricing = newSkuPricingType === PRICING_TYPE_BY_EMPLOYEE_CAT;

    return initialisedSkus(
      createSkusOfType(useEmployeeCategoryPricing, {
        selectedEmployees,
        employeeCategoriesCollection,
      }),
    );
  }

  if (!isSkuCombinationSupported(skus)) {
    return initialisedSkus(reduceSkusToCustomType(skus));
  }

  const pricingType = getPricingType(skus);
  // @ts-expect-error ts-migrate(2345) FIXME: Type 'null' is not assignable to type 'string'.
  if ([PRICING_TYPE_BY_EMPLOYEE_CAT, PRICING_TYPE_2D].includes(pricingType)) {
    let modifiedSkus = skus;

    if (pricingType === PRICING_TYPE_2D) {
      modifiedSkus = add2dGrouping(modifiedSkus);
    }

    if (pricingType === PRICING_TYPE_BY_EMPLOYEE_CAT) {
      const groupingId = uuid.v4();

      modifiedSkus = modifiedSkus.map((sku) => Object.assign({}, sku, { groupingId }));
    }

    // Remove non-existing employee category SKUs
    if (!doesUseExistingCategory(skus, selectedEmployees, employeeCategoriesCollection)) {
      const validCategories = getEmployeeCategoriesFromEmployees(
        selectedEmployees,
        employeeCategoriesCollection,
      );

      modifiedSkus = modifiedSkus.filter((sku) => validCategories.includes(sku.employeeCategoryId));
    }

    // Add SKUs for new employee categories
    const skusWithAddedCategories = addMissingCategories(
      modifiedSkus,
      selectedEmployees,
      employeeCategoriesCollection,
    );
    if (skusWithAddedCategories.length !== modifiedSkus.length) {
      modifiedSkus = skusWithAddedCategories;
    }

    if (modifiedSkus.length === 0) {
      // If we have removed all the SKUs, add at least an empty one.
      modifiedSkus = createSkusOfType(true, {
        selectedEmployees,
        employeeCategoriesCollection,
      });
    }

    return initialisedSkus(modifiedSkus);
  }
  return initialisedSkus(skus);
}
