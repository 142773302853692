App.Routers.Menu = Backbone.Router.extend({
  initialize: function () {
    // Adding routes this way to prevent iglu routes taking over Menu ones
    // Routes added first are checked last. So add it in the reverse greediness order.
    this.route('venue/:id/:tab/*iglu', 'tabChange');
    // Alternative of "venue/:id/:tab/:filterId/*iglu", just with filterId forced to alpha chars only
    // This is to fix for iglu routes where `_` is treated as the filterId
    this.route(/^venue\/([^\/]+)\/([^\/]+)\/([a-zA-Z]+)\/(.*?)$/, 'tabChange');

    this.route(':tab', 'tabChange');
    this.route('venue/:id/:tab', 'tabChange');
    this.route('venue/:id/:tab/:filterId/:subFilterId', 'tabChange');
    // Introduce hard route to stop the router thinking new-discount is a filter.
    this.route('venue/:id/:tab/new-discount', 'tabChange');

    this.route('venue/:id/services/:filterId/:subFilterId/offer/:id', 'editOffer');
    this.route('venue/:id/services/:filterId/:subFilterId/offer/:id/*iglu', 'editOffer');
  },

  tabChange: function (id, tab, filterId, subFilterId, iglu) {
    this.initTab(id, tab, filterId, subFilterId, iglu);
  },

  editOffer: function (venueId, filterId, subFilterId, offerId, iglu) {
    var additionalMainViewOptions = {
      initialDialog: {
        offerId: offerId,
        venueId: venueId,
      },
    };

    this.initTab(venueId, 'services', filterId, subFilterId, iglu, additionalMainViewOptions);
  },

  initTab: function (id, tab, filterId, subFilterId, iglu, additionalMainViewOptions) {
    iglu = App.Views.Dialog.IgluModal.findIgluUri(iglu, filterId);
    // In the case that there is not filterId set and there is a iglu url,
    // it is possible for the iglu url to fall into the filterId.
    // at this point we should verify that a) the iglu url has been correctly asigned to the iglu variable,
    // nullify the filterId as this should not have been populated.
    // in the case that a filter has been selected and iglu used then the middle condition
    // should keep the integrity of filterId
    var isIgluUrl = typeof filterId === 'string' && filterId.indexOf('iglu') > -1;
    if (isIgluUrl) {
      // Try capturing the string before "/_/iglu", for example: "NPR/_/iglu..."
      var igluMatch = filterId.match(/(.*)\/_\/iglu\//);
      filterId = igluMatch ? igluMatch[1] : null;
    }
    var filter = filterId || (App.mainViewOptions && App.mainViewOptions.initialFilter);
    var subFilter =
      parseInt(subFilterId) || (App.mainViewOptions && App.mainViewOptions.initialSubFilter);
    App.mainViewOptions = $.extend(
      {
        initialTab: tab,
        initialFilter: filter,
        initialSubFilter: subFilter,
        initialIglu: iglu,
      },
      additionalMainViewOptions,
    );

    App.initVenue(null);
  },
});
