(function($) {
	/**
	 * User search autocomplete.
	 * 
	 * Based on top of $.autocomplete
	 * 
	 * @param Object options
	 */
	$.fn.userAutocomplete = function(options) {
		var dataSource = options.source;
		var self = this;
		options.source = function( request, response ) {
			if (this._forcedResults) {
				self.data('resultsCount', this._forcedResults.length);
				response( this._forcedResults );
				this._forcedResults = null;
			} else if (typeof dataSource === 'function') {
        dataSource({
					query: request.term,
          response: (data) => {
            self.data('resultsCount', data ? data.length : 0);
            response(data);
					}
				});
      } else {
				$.getJSON(
					dataSource,
					{ q: request.term },
					function(result) {
						self.data('resultsCount', (result && result.consumers) ? result.consumers.length : 0);
						response(result.consumers);
					}
				);
      }
		};
		
		this.autocomplete(options);
		
		var instance = this.data( "autocomplete" );

		if (options.renderMenu) {
			// eslint-disable-next-line no-underscore-dangle
			instance._renderMenu = options.renderMenu;
		}
		if (options.resizeMenu) {
			instance._resizeMenu = options.resizeMenu;
		}
		
		instance._renderItem = function( ul, item ) {
			ul.addClass("client-autocomplete");
			return $( "<li></li>" )
				.data( "item.autocomplete", item )
				.append(
					'<a>'
					+ '<span class="icon icons-user2"></span>'
					+ '<span class="name">' + Wahanda.Util.escapeHtml(item.name) + '</span>'
					+ (item.phone ? '<span class="phone">' + Wahanda.Util.escapeHtml(item.phone) + '</span>' : '')
					+ '</a>'
				)
				.appendTo( ul );
		};
		
		/**
		 * Show the given results.
		 * 
		 * Results must be in this Customer object format.
		 * 
		 * @param {Object} results
		 */
		instance.showResults = function(results) {
			this._forcedResults = results;
			this._search();
		};
	};
}(jQuery));
