import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Share extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-share', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5286 0.861766C7.78895 0.601417 8.21106 0.601417 8.4714 0.861766L11.1381 3.52843C11.3984 3.78878 11.3984 4.21089 11.1381 4.47124C10.8777 4.73159 10.4556 4.73159 10.1953 4.47124L8.66667 2.94265V9.99984C8.66667 10.368 8.36819 10.6665 8 10.6665C7.63181 10.6665 7.33333 10.368 7.33333 9.99984V2.94265L5.80474 4.47124C5.54439 4.73159 5.12228 4.73159 4.86193 4.47124C4.60158 4.21089 4.60158 3.78878 4.86193 3.52843L7.5286 0.861766ZM2.66667 7.33317C3.03486 7.33317 3.33333 7.63165 3.33333 7.99984V13.3332C3.33333 13.51 3.40357 13.6795 3.5286 13.8046C3.65362 13.9296 3.82319 13.9998 4 13.9998H12C12.1768 13.9998 12.3464 13.9296 12.4714 13.8046C12.5964 13.6795 12.6667 13.51 12.6667 13.3332V7.99984C12.6667 7.63165 12.9651 7.33317 13.3333 7.33317C13.7015 7.33317 14 7.63165 14 7.99984V13.3332C14 13.8636 13.7893 14.3723 13.4142 14.7474C13.0391 15.1225 12.5304 15.3332 12 15.3332H4C3.46957 15.3332 2.96086 15.1225 2.58579 14.7474C2.21071 14.3723 2 13.8636 2 13.3332V7.99984C2 7.63165 2.29848 7.33317 2.66667 7.33317Z"
          fill="#1A1A1A"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(Share);
