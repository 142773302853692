import { connect } from 'react-redux';
import App from 'common/backbone-app';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getCampaignSummary, getEmailTemplate } from 'components/marketing/EmailCampaign/selectors';
import {
  getEmailCampaignRecipientCountRequest,
  clearEmailCampaignError,
  sendEmailCampaignRequest,
  getSmartDiscountsRecipientCountRequest,
  getServiceOnSaleRecipientCountRequest,
  getAppForYourClientsRecipientCountRequest,
  recipientCountReset,
  sendSmartDiscountsCampaignRequest,
  sendServiceOnSaleCampaignRequest,
  sendAppForYourClientsCampaignRequest,
} from './actions';
import { getMassEmailCountRequest } from '../actions';

import { CampaignSummary } from './';

const mapStateToProps = (state) => ({
  campaignTemplate: getEmailTemplate(state),
  campaignSummary: getCampaignSummary(state),
  minInterval: App.config.get('venue').venueMassEmailMinIntervalDays,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getEmailCampaignRecipientCountRequest,
      getSmartDiscountsRecipientCountRequest,
      getServiceOnSaleRecipientCountRequest,
      getAppForYourClientsRecipientCountRequest,
      recipientCountReset,
      clearEmailCampaignError,
      sendEmailCampaignRequest,
      sendSmartDiscountsCampaignRequest,
      sendServiceOnSaleCampaignRequest,
      sendAppForYourClientsCampaignRequest,
      getMassEmailCountRequest,
    },
    dispatch,
  ),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type 'History<any>' is not assignable to type 'nev... Remove this comment to see the full error message

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignSummary));
