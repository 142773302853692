import React, { PureComponent } from 'react';
import Wahanda from 'common/wahanda';
import { Badge } from 'components/common/Badge';
import { ORDER_STATUS_ORDERED } from '../constants';

const lang = Wahanda.lang.menu.productOrdersList;
interface IProductOrderStatusBadgeProps extends React.HTMLAttributes<Element> {
  status: string;
}
class ProductOrderStatusBadge extends PureComponent<IProductOrderStatusBadgeProps, {}> {
  isStatusOrdered = () => {
    const { status } = this.props;
    return status === ORDER_STATUS_ORDERED;
  };

  render() {
    const statusForDisplay = this.isStatusOrdered() ? lang.statusOrdered : lang.statusCompleted;
    const badgeType = this.isStatusOrdered() ? 'success' : 'info';
    return <Badge text={statusForDisplay} type={badgeType} />;
  }
}

export default ProductOrderStatusBadge;
