import React from 'react';
import classnames from 'classnames';
import style from './ProgressBar.scss';

interface Props {
  colour?: 'default' | 'danger' | 'secondary';
  value: number;
  maxValue?: number;
  label?: string | number;
  size?: 'default' | 'small';
}

export const ProgressBar = ({
  colour = 'default',
  value,
  maxValue = 100,
  label,
  size = 'default',
}: Props) => {
  const className = classnames(style.container, style[colour]);
  const labelClassName = classnames(style.label, style[size]);

  const getWidth = () => {
    if (value >= maxValue) {
      return '100%';
    }
    return `${(value / maxValue) * 100}%`;
  };

  return (
    <div className={className}>
      <div className={style.progressBar}>
        <div className={style.filler} style={{ width: getWidth() }} />
      </div>
      {label ? <span className={labelClassName}>{label}</span> : null}
    </div>
  );
};

ProgressBar.displayName = 'ProgressBar';
