import React, { Component, Fragment } from 'react';
import Wahanda from 'common/wahanda';
import { OrderProducts } from 'common/analytics';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';
import { OrdersList } from 'components/common/IconPicture';
import { TabContent } from '../TabContent';
import ProductOrdersList from './ProductOrdersList';
import NewOrder from './NewOrder/container';

const lang = Wahanda.lang.menu.productOrdersList;
interface IProductOrdersTabProps extends React.HTMLAttributes<Element> {
  orders: {}[];
  actions: {
    requestProductOrdersAction: (...args: any[]) => any;
    productOrdersReset: (...args: any[]) => any;
  };
  loading: boolean;
  productOrdersReset?: any;
  requestProductOrdersAction?: any;
}
type ProductOrdersTabState = {
  newOrderDialogShown?: boolean;
};

export default class ProductOrdersTab extends Component<
  IProductOrdersTabProps,
  ProductOrdersTabState
> {
  state = {
    newOrderDialogShown: false,
  };

  componentDidMount() {
    NavigationAnalytics.trackPageEventView(L2Page.PRODUCT_ORDERS);
    this.props.actions.requestProductOrdersAction();
  }

  componentDidUpdate(prevProps) {
    const { loading, orders } = this.props;
    if (prevProps.loading === loading && prevProps.orders.length === orders.length) {
      return;
    }
    if (!loading && orders.length === 0 && !this.state.newOrderDialogShown) {
      OrderProducts.trackEmptyListView();
    }
  }

  componentWillUnmount() {
    this.props.actions.productOrdersReset();
  }

  onNewOrderDialogClose = () => {
    this.setState({
      newOrderDialogShown: false,
    });
  };

  newOrderButtonClick = () => {
    this.setState({
      newOrderDialogShown: true,
    });
  };

  newOrderButtonClickViaEmptyList = () => {
    OrderProducts.trackNewOrderClickViaEmptyList();
    this.newOrderButtonClick();
  };

  newOrderButtonClickViaNotEmptyList = () => {
    OrderProducts.trackNewOrderClickViaNotEmptyList();
    this.newOrderButtonClick();
  };

  // @ts-expect-error ts-migrate(2322) FIXME: Type '() => void' is not assignable to type 'never... Remove this comment to see the full error message
  renderNewOrderDialog = () => <NewOrder onClose={this.onNewOrderDialogClose} />;

  render() {
    const { orders, loading } = this.props;
    return (
      <Fragment>
        <TabContent
          header={lang.header}
          addNewButtonText={lang.addNew}
          onAddNewButtonClick={this.newOrderButtonClickViaNotEmptyList}
          listElement={<ProductOrdersList orders={orders} />}
          itemsCount={orders.length}
          loading={loading}
          emptyStateHeading={lang.empty.heading}
          emptyStateMessage={lang.empty.message}
          emptyStateImage={<OrdersList />}
          emptyStateButtonText={lang.empty.buttonText}
          emptyStateButtonOnClick={this.newOrderButtonClickViaEmptyList}
        />
        {this.state.newOrderDialogShown && this.renderNewOrderDialog()}
      </Fragment>
    );
  }
}
