import React from 'react';
import { trackEvent, ACTIONS } from 'common/analytics';
import style from './FAQs.scss';

interface Question {
  id: string;
  name: string;
  link: string;
}

export interface FAQsProps {
  header: string;
  trackCategory: string;
  questions: Question[];
}

export const FAQs = ({ header, trackCategory, questions }: FAQsProps) => {
  const onClick = (trackProperty: string) => () => {
    trackEvent(trackCategory, ACTIONS.CLICK, 'faq', trackProperty);
  };
  return (
    <div data-test="help-center-link-container" className={style.container}>
      <div className={style.header}>{header}</div>
      {questions.map((question) => (
        <a
          key={question.id}
          className={style.question}
          href={question.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClick(question.id)}
        >
          {question.name}
        </a>
      ))}
    </div>
  );
};
