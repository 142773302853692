import React from 'react';
import Wahanda from 'common/wahanda';
import { Currency, Percentage } from 'components/common/DataFormat';
import {
  Table,
  TableColumnHeader,
  Align,
  TableRow,
  TableBody,
  TableFooter,
  TableHeader,
  TableData,
  TableLayout,
  Level,
} from 'components/common/Table';
import SubHeader from '../SubHeader';
import style from './style.scss';

interface VATSummary extends CategoryGrandTotal {
  vatRate: number;
}

interface Summary extends CategoryGrandTotal {
  vatRateRows: VATSummary[];
}

interface CategoryGrandTotal {
  vatAmount: number;
  netAmount: number;
  grossAmount: number;
}

export interface Props {
  services: Summary;
  products: Summary;
  vouchers: Summary;
  grandTotal: CategoryGrandTotal;
}

const LANG = Wahanda.lang.reports.posReports.salesPeriod.vatByCategory;

export default class VATByCategory extends React.PureComponent<Props> {
  public render() {
    return (
      <div>
        <SubHeader text={LANG.header} />
        <Table border layout={TableLayout.FIXED}>
          <TableHeader>{this.renderHeader()}</TableHeader>
          <TableBody>
            {this.renderCategory(LANG.services, this.props.services)}
            {this.renderCategory(LANG.products, this.props.products)}
            {this.renderCategory(LANG.vouchers, this.props.vouchers)}
          </TableBody>
          <TableFooter>{this.renderFooter()}</TableFooter>
        </Table>
      </div>
    );
  }

  private renderHeader() {
    return (
      <TableRow>
        <TableColumnHeader align={Align.LEFT}>{LANG.category}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.net}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.vat}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.gross}</TableColumnHeader>
      </TableRow>
    );
  }

  private renderCategory(categoryName: string, summary: Summary) {
    const { vatAmount, netAmount, grossAmount, vatRateRows } = summary;
    return (
      <React.Fragment>
        <TableRow className={style.subHeader}>
          <TableData>
            <div className={style.bolded}>{categoryName}</div>
          </TableData>
          <TableData align={Align.RIGHT}>
            <div className={style.bolded}>
              <Currency amount={netAmount} />
            </div>
          </TableData>
          <TableData align={Align.RIGHT}>
            <div className={style.bolded}>
              <Currency amount={vatAmount} />
            </div>
          </TableData>
          <TableData align={Align.RIGHT}>
            <div className={style.bolded}>
              <Currency amount={grossAmount} />
            </div>
          </TableData>
        </TableRow>
        {vatRateRows.map((vatRateSummary) => this.renderCategorySummary(vatRateSummary))}
      </React.Fragment>
    );
  }

  private renderCategorySummary(summary: VATSummary) {
    const { vatAmount, vatRate, netAmount, grossAmount } = summary;
    return (
      <TableRow level={Level.ONE} key={vatRate}>
        <TableData>
          <Percentage value={vatRate} />
        </TableData>
        <TableData align={Align.RIGHT}>
          <Currency amount={netAmount} />
        </TableData>
        <TableData align={Align.RIGHT}>
          <Currency amount={vatAmount} />
        </TableData>
        <TableData align={Align.RIGHT}>
          <Currency amount={grossAmount} />
        </TableData>
      </TableRow>
    );
  }

  private renderFooter() {
    const { netAmount, vatAmount, grossAmount } = this.props.grandTotal;
    return (
      <TableRow>
        <TableData>
          <div className={style.bolded}>{LANG.grandTotal}</div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={netAmount} />
          </div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={vatAmount} />
          </div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={grossAmount} />
          </div>
        </TableData>
      </TableRow>
    );
  }
}
