import React, { Fragment } from 'react';
import { get } from 'mori';
import App from 'common/backbone-app';
import moment from 'common/moment';

type OverviewProps = {
  data?: {};
  lang: {
    walkInClient: string;
    transactionLabel: string;
    dateLabel: string;
    checkoutOutLabel: string;
    transactionLabelPosLite: string;
  };
  isCancellation?: boolean;
};
const Overview: React.SFC<OverviewProps> = ({ isCancellation, data, lang }) => {
  const isRefund = isCancellation === true;
  const info = get(data, isRefund ? 'cancellationInformation' : 'checkoutInformation');
  const getData = (ifCheckout, ifRefund = ifCheckout) =>
    get(info, isRefund ? ifRefund : ifCheckout);
  const dateTime = moment(getData('created')).tz(App.getTimezone()).formatFullDateTime();
  const emailRow = (() => {
    if (get(data, 'emailAddress')) {
      return (
        <span className="overview-row">
          <span className="icon icons-action-email" />
          <span className="customer-email">{get(data, 'emailAddress')}</span>
        </span>
      );
    }
    return null;
  })();
  const rightSection = (() => {
    if (getData('venueCustomerName')) {
      return (
        <div className="right">
          <span data-hj-suppress className="customer-name">
            {getData('venueCustomerName')}
          </span>
          <span className="overview-row">
            <span className="icon icons-action-phone" />
            <span className="customer-phone">{get(data, 'phone')}</span>
          </span>
          {emailRow}
        </div>
      );
    }
    return (
      <div className="right">
        <span data-hj-suppress className="customer-name">
          {lang.walkInClient}
        </span>
      </div>
    );
  })();
  return (
    <div className="overview-summary">
      <div className="left">
        <span className="transaction-id">
          {App.config.get('venue').pointOfSaleEnabled ? (
            <Fragment>
              {lang.transactionLabel}
              {getData('transactionIdForDisplay', 'transactionId')}
            </Fragment>
          ) : (
            lang.transactionLabelPosLite
          )}
        </span>
        <span className="overview-row">
          <span className="date-label">{lang.dateLabel}</span>
          <span className="date">{dateTime}</span>
        </span>
        <span className="overview-row">
          <span className="employee-label">{lang.checkoutOutLabel}</span>
          <span className="employee-name">{get(info, 'employeeName')}</span>
        </span>
      </div>
      {rightSection}
    </div>
  );
};
Overview.defaultProps = {
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '{} | undefi... Remove this comment to see the full error message
  data: null,
  isCancellation: false,
};

export default Overview;
