import {
  PRODUCT_SUPPLIERS_SET_SUPPLIER_DATA,
  PRODUCT_SUPPLIERS_SELECT_PRODUCT,
  PRODUCT_SUPPLIERS_SUBMIT_SUPPLIER,
  PRODUCT_SUPPLIERS_SUPPLIER_SUBMIT_SUCCESS,
  PRODUCT_SUPPLIERS_SUBMIT_PRODUCTS_ASSIGNATION,
  PRODUCT_SUPPLIERS_PRODUCTS_ASSIGNATION_SUBMIT_SUCCESS,
  PRODUCT_SUPPLIERS_REQUEST,
  PRODUCT_SUPPLIERS_RECEIVE,
  PRODUCT_SUPPLIERS_SUPPLIER_REQUEST,
  PRODUCT_SUPPLIERS_SUPPLIER_RECEIVE,
  PRODUCT_SUPPLIERS_FAILURE,
  PRODUCT_SUPPLIERS_SUPPLIER_DATA_RESET,
  PRODUCT_SUPPLIERS_RESET,
  PRODUCT_SUPPLIERS_DELETE_SUPPLIER,
  PRODUCT_SUPPLIERS_DELETE_SUPPLIER_SUCCESS,
} from 'reduxStore/actionsConst';

export const setSupplierDataAction = (data) => ({
  type: PRODUCT_SUPPLIERS_SET_SUPPLIER_DATA,
  data,
});

export const selectProductAction = (data) => ({
  type: PRODUCT_SUPPLIERS_SELECT_PRODUCT,
  data,
});

export const submitSupplierAction = () => ({
  type: PRODUCT_SUPPLIERS_SUBMIT_SUPPLIER,
});

export const receiveSupplierSubmitSuccessAction = () => ({
  type: PRODUCT_SUPPLIERS_SUPPLIER_SUBMIT_SUCCESS,
});

export const submitProductsAssignationAction = () => ({
  type: PRODUCT_SUPPLIERS_SUBMIT_PRODUCTS_ASSIGNATION,
});

export const receiveProductsAssignationSuccessAction = () => ({
  type: PRODUCT_SUPPLIERS_PRODUCTS_ASSIGNATION_SUBMIT_SUCCESS,
});

export const requestProductSuppliersAction = () => ({
  type: PRODUCT_SUPPLIERS_REQUEST,
});

export const receiveProductSuppliersAction = (data) => ({
  type: PRODUCT_SUPPLIERS_RECEIVE,
  data,
});

export const requestProductSupplierAction = (id) => ({
  type: PRODUCT_SUPPLIERS_SUPPLIER_REQUEST,
  id,
});

export const receiveProductSupplierAction = (data) => ({
  type: PRODUCT_SUPPLIERS_SUPPLIER_RECEIVE,
  data,
});

export const requestProductSuppliersFailureAction = (ex) => ({
  type: PRODUCT_SUPPLIERS_FAILURE,
  ex,
});

export const resetSupplierDataAction = () => ({
  type: PRODUCT_SUPPLIERS_SUPPLIER_DATA_RESET,
});

export const resetProductSuppliersAction = () => ({
  type: PRODUCT_SUPPLIERS_RESET,
});

export const deleteSupplierAction = (id) => ({
  type: PRODUCT_SUPPLIERS_DELETE_SUPPLIER,
  id,
});

export const receiveSupplierDeleteSuccessAction = () => ({
  type: PRODUCT_SUPPLIERS_DELETE_SUPPLIER_SUCCESS,
});
