import React, { Component, Fragment } from 'react';
import Wahanda from 'common/wahanda';
import ROLES from 'common/constants/userRoles';
import { trackEvent } from 'common/analytics';
import { Notice } from 'components/common/Notice';
import EmployeeRolesList from './EmployeeRolesList';
import style from './style.scss';

const lang = Wahanda.lang.settings.employees.employee;
// @ts-expect-error ts-migrate(2430) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
interface IEmployeePermissionsProps extends React.HTMLAttributes<Element> {
  id?: number;
  role?: any;
  showTaxAuditorRole?: boolean;
  showBasicRole?: boolean;
  pointOfSalePermissionsVisible?: boolean;
  selectedPermissions?: string[];
  permissionsByRoles?: {
    permissions?: string[];
    role?: any;
  }[];
  showAccessAttention?: boolean;
  disabled?: boolean;
  actions: {
    setEmployeeDataAction: (...args: any[]) => any;
    requestPermissionsByRolesAction: (...args: any[]) => any;
  };
  setEmployeeDataAction?: any;
  requestPermissionsByRolesAction?: any;
}

export default class EmployeePermissions extends Component<IEmployeePermissionsProps, {}> {
  static defaultProps = {
    id: null,
    role: null,
    selectedPermissions: [],
    showTaxAuditorRole: false,
    showBasicRole: false,
    pointOfSalePermissionsVisible: false,
    permissionsByRoles: [],
    disabled: false,
    showAccessAttention: false,
  };

  componentDidMount() {
    this.props.actions.requestPermissionsByRolesAction();
  }

  onPermissionsSelect = (permissions) => {
    const { permissionsByRoles, id, selectedPermissions, showTaxAuditorRole } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const foundRoleByPermissionsSet = permissionsByRoles.find(
      (rolePermissions) =>
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        rolePermissions.permissions.length === permissions.length &&
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        rolePermissions.permissions.every((p) => permissions.includes(p)),
    );
    if (
      foundRoleByPermissionsSet == null ||
      (!showTaxAuditorRole && foundRoleByPermissionsSet.role === ROLES.TAX_AUDITOR)
    ) {
      this.setRole(ROLES.USER);
    } else {
      this.setRole(foundRoleByPermissionsSet.role);
    }
    trackEvent('team-member-permission', 'submit', 'update-permission');
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (selectedPermissions.some((p) => !permissions.includes(p))) {
      trackEvent('connect-employee', 'permissions-updated', id);
    }
    this.props.actions.setEmployeeDataAction({ permissions });
  };

  onRoleSelect = (role) => {
    this.setRole(role);
  };

  setRole = (role) => {
    this.props.actions.setEmployeeDataAction({ role });
    if (role === ROLES.TAX_AUDITOR) {
      this.props.actions.setEmployeeDataAction({ takesAppointments: false });
    }
  };

  render() {
    const {
      showAccessAttention,
      role,
      showTaxAuditorRole,
      showBasicRole,
      pointOfSalePermissionsVisible,
      selectedPermissions,
      permissionsByRoles,
      disabled,
    } = this.props;
    return (
      <Fragment>
        <div className={style.header}>{lang.permissions.header}</div>
        {showAccessAttention && <Notice type="info" message={lang.connectAccessAttention} />}
        <EmployeeRolesList
          selectedRole={role}
          // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type '{ perm... Remove this comment to see the full error message
          permissionsByRoles={permissionsByRoles}
          showTaxAuditorRole={showTaxAuditorRole}
          showBasicRole={showBasicRole}
          pointOfSalePermissionsVisible={pointOfSalePermissionsVisible}
          selectedPermissions={selectedPermissions}
          onPermissionsSelect={this.onPermissionsSelect}
          onRoleSelect={this.onRoleSelect}
          disabled={disabled}
        />
      </Fragment>
    );
  }
}
