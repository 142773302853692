import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { receivePosStatusAction, requestPosStatusFailureAction } from './actions';

export function* getPosStatusRequested() {
  try {
    const data = yield call(api.get, apiUrl('POS_STATUS'));

    yield put(receivePosStatusAction(data));
  } catch (err: any) {
    yield put(requestPosStatusFailureAction(err));
  }
}
