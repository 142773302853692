import React from 'react';

interface Props {
  className?: string;
}

class ComputerBooking extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-computer-booking', this.props.className].join(' ')}
        viewBox="0 0 91 65"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#00B0B8"
            d="M70.23623,0.878395439 L71.5203001,1.29328867 L71.5411798,1.30949457 C71.6803781,1.3997484 72.5155675,1.85234378 74.0467482,2.6672807 L74.8373673,5.2671563 L74.8373673,8.35678653 L75,12.3399892 L74.8373673,20.8054138 L42.5141542,20.8054138 L42.3583845,39 L17,39 C17.2188646,34.1702349 17.2188646,30.182708 17,27.0374195 C21.5289321,22.7547547 25.6638961,18.0556729 29.3514412,12.9985075 L30.0366012,12.0461069 C32.8953562,8.02763924 38.4791282,7.19287708 42.3743844,10.1484005 L42.5930376,10.3197463 C48.3904666,15.0120876 56.9068759,13.9384249 61.3702772,7.95181909 L64.0642943,4.33921216 C65.9715306,1.78119439 66.2943887,1.08355692 67.2756587,0.0405134955 C67.4042777,-0.0962023068 67.6377518,0.1570903 67.7569741,0.155196763 C68.1293386,0.149282715 68.3572616,0.348860236 68.7385195,0.468222914 C69.0601406,0.568914741 69.5593775,0.705638916 70.23623,0.878395439 Z"
            fillRule="nonzero"
          />
          <path
            stroke="#001E62"
            d="M75,12.3820716 L75,5.29177923 C75,2.9207617 73.0355915,1 70.6131552,1 L21.3626285,1 C18.9391899,1 16.9747815,2.9207617 16.9747815,5.29177923 L16.9747815,17.9978999 M16.9747815,25.9877144 L16.9747815,34.9077277 C16.9747815,36.7473444 15.4513626,38.2376418 13.5711431,38.2376418 L3.26200764,38.2376418 C2.14149301,38.2376418 1.57822895,39.5626803 2.37000584,40.3394954 L2.62357489,40.5888678 C3.54593534,41.4933752 4.79337095,42.0006962 6.09336168,42 L34.2645827,42 M35.3770794,38.2435792 L12.9567643,38.2435792"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            fill="#FFF"
            d="M42,42.1898245 L42,59.4593492 C42,61.9669926 44.0284824,64 46.5301119,64 L81.2573331,64 C82.7719994,64 84,62.7687561 84,61.2505231 L84,23.7281908 C84,21.8788163 82.5041802,20.3806525 80.6601956,20.3806525 L43.5325209,20.3806525 C42.733031,20.3806525 42.0843134,19.7304112 42.0843134,18.9286477 C42.0843134,17.8970921 42.8709083,17.0622144 43.8410089,17.0622144 C44.8111095,17.0622144 45.5967125,17.8970921 45.5967125,18.9286477 L45.5967125,21.5737649 C45.5967125,22.604317 46.3833074,23.4401981 47.353408,23.4401981 L47.6599121,23.4401981 C48.6865524,23.4401981 49.5187757,22.5551475 49.5187757,21.4633844 L49.5187757,19.0219693 C49.5187757,17.9051198 50.3708375,17 51.4202919,17 L51.509565,17 C52.5590194,17 53.4110812,17.9051198 53.4110812,19.0219693 L53.4110812,21.4633844 C53.4110812,22.5551475 54.2433045,23.4401981 55.2699447,23.4401981 C56.296585,23.4401981 57.1288083,22.5551475 57.1288083,21.4633844 L57.1288083,19.0219693 C57.1288083,17.9051198 57.9808701,17 59.0313164,17 C60.0817628,17 60.9328326,17.9051198 60.9328326,19.0219693 L60.9328326,21.4633844 C60.9328326,22.5551475 61.7660479,23.4401981 62.7916962,23.4401981 L62.9920646,23.4401981 C64.0196968,23.4401981 64.8519201,22.5551475 64.8519201,21.4633844 L64.8519201,19.0219693 C64.8519201,17.9051198 65.7029899,17 66.7534363,17 C67.8038827,17 68.6549525,17.9051198 68.6549525,19.0219693 L68.6549525,21.4633844 C68.6549525,22.5551475 69.4881678,23.4401981 70.514808,23.4401981 C71.5414482,23.4401981 72.3736715,22.5551475 72.3736715,21.4633844 L72.3736715,19.0219693 C72.3736715,17.9051198 73.2257333,17 74.2761797,17 C75.3256341,17 76.1776959,17.9051198 76.1776959,19.0219693 L76.1776959,21.4633844 C76.1776959,22.5551475 77.0109112,23.4401981 78.0365594,23.4401981 L78.2369279,23.4401981 C79.2635681,23.4401981 80.0967833,22.5551475 80.0967833,21.4633844 L80.0967833,19.0219693 C80.0967833,17.9051198 80.9478532,17 81.9982996,17 L82.2542157,17"
            fillRule="nonzero"
          />
          <path
            stroke="#001E62"
            d="M42,42.1898245 L42,59.4593492 C42,61.9669926 44.0284824,64 46.5301119,64 L81.2573331,64 C82.7719994,64 84,62.7687561 84,61.2505231 L84,23.7281908 C84,21.8788163 82.5041802,20.3806525 80.6601956,20.3806525 L43.5325209,20.3806525 C42.733031,20.3806525 42.0843134,19.7304112 42.0843134,18.9286477 C42.0843134,17.8970921 42.8709083,17.0622144 43.8410089,17.0622144 C44.8111095,17.0622144 45.5967125,17.8970921 45.5967125,18.9286477 L45.5967125,21.5737649 C45.5967125,22.604317 46.3833074,23.4401981 47.353408,23.4401981 L47.6599121,23.4401981 C48.6865524,23.4401981 49.5187757,22.5551475 49.5187757,21.4633844 L49.5187757,19.0219693 C49.5187757,17.9051198 50.3708375,17 51.4202919,17 L51.509565,17 C52.5590194,17 53.4110812,17.9051198 53.4110812,19.0219693 L53.4110812,21.4633844 C53.4110812,22.5551475 54.2433045,23.4401981 55.2699447,23.4401981 C56.296585,23.4401981 57.1288083,22.5551475 57.1288083,21.4633844 L57.1288083,19.0219693 C57.1288083,17.9051198 57.9808701,17 59.0313164,17 C60.0817628,17 60.9328326,17.9051198 60.9328326,19.0219693 L60.9328326,21.4633844 C60.9328326,22.5551475 61.7660479,23.4401981 62.7916962,23.4401981 L62.9920646,23.4401981 C64.0196968,23.4401981 64.8519201,22.5551475 64.8519201,21.4633844 L64.8519201,19.0219693 C64.8519201,17.9051198 65.7029899,17 66.7534363,17 C67.8038827,17 68.6549525,17.9051198 68.6549525,19.0219693 L68.6549525,21.4633844 C68.6549525,22.5551475 69.4881678,23.4401981 70.514808,23.4401981 C71.5414482,23.4401981 72.3736715,22.5551475 72.3736715,21.4633844 L72.3736715,19.0219693 C72.3736715,17.9051198 73.2257333,17 74.2761797,17 C75.3256341,17 76.1776959,17.9051198 76.1776959,19.0219693 L76.1776959,21.4633844 C76.1776959,22.5551475 77.0109112,23.4401981 78.0365594,23.4401981 L78.2369279,23.4401981 C79.2635681,23.4401981 80.0967833,22.5551475 80.0967833,21.4633844 L80.0967833,19.0219693 C80.0967833,17.9051198 80.9478532,17 81.9982996,17 L82.2542157,17"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            stroke="#FFB500"
            d="M32.644,7.857 L6.013,7.857 M32.644,14.857 L18.796,14.857 M32.644,21.857 L21.164,21.857 M19.314,2.043 L19.314,18.415 M26.314,1.782 L26.314,29.532 M12.314,2.043 L12.314,11.611 M6.314,2.043 L6.314,8.15 M0.438,0.975 L33.583,1.105 L33.583,29.765 L23.04,29.765"
            transform="translate(46 29)"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <g
            stroke="#001E62"
            transform="translate(34 21)"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          >
            <path d="M0.24,0.684 C8.763,7.721 5.4,15.031 4.326,19.868 C3.251,24.704 3.34,30.236 8.122,33.064 C11.672,35.164 17.288,35.099 21.592,33.131 C25.896,31.162 28.766,27.498 29.182,23.905" />
            <polyline points="26.102 24.67 29.528 22.738 31.304 26.19" />
          </g>
        </g>
      </svg>
    );
  }
}

export const icon = ComputerBooking;
