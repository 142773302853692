import { put } from 'redux-saga/effects';
import { refreshAfterTransaction } from 'src/actions/pos/create-transaction';
import { ACTION, canDelegateToApp, delegateToApp } from 'common/app-delegation';
import { openDialog } from './actions';
import { DialogTypes } from './types';

export function* openWithdrawalDialog() {
  if (canDelegateToApp(ACTION.WITHDRAW)) {
    delegateToApp(ACTION.WITHDRAW, { onDone: refreshAfterTransaction });
    return;
  }
  yield put(openDialog(DialogTypes.WITHDRAWAL));
}

export function* openDepositDialog() {
  if (canDelegateToApp(ACTION.DEPOSIT)) {
    delegateToApp(ACTION.DEPOSIT, { onDone: refreshAfterTransaction });
    return;
  }
  yield put(openDialog(DialogTypes.DEPOSIT));
}

export function* openDayClosedNotificationDialog() {
  yield put(openDialog(DialogTypes.DAY_CLOSED_NOTIFICATION));
}
