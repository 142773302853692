import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './store/actions';
import { AddServicesModal as Component } from './AddServicesModal';
import { hasFreeSku } from './store/selectors';

const mapStateToProps = ({ menu: { menuTemplates } }) => ({
  selectedGroups: menuTemplates.selectedGroups,
  errorPrices: menuTemplates.errorPrices,
  errorType: menuTemplates.errorType,
  errorStacks: menuTemplates.errorStacks,
  groupTypesList: menuTemplates.groupTypesList,
  isSubmitting: menuTemplates.isSubmitting,
  serverError: menuTemplates.serverError,
  hasFreeSku: hasFreeSku(menuTemplates),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const AddServicesModal = connect(mapStateToProps, mapDispatchToProps)(Component);
