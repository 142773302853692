/* eslint-disable func-names */
/* global BackboneEx */
import { trackEvent } from 'common/analytics';

const DEFAULT_MENU_FILTER = 'ACT';

function openServiceEditForm(options) {
  const view = new App.Views.Forms.MenuOffer2(options);
  view.render();
  view.open();
}

App.Views.Menu.ServicesTab = BackboneEx.View.Base.extend({
  events: {
    'click .js-edit-group': 'showGroupFormDialog',
    'click .js-discounting': 'showDiscountingDialog',
    'click .add-multiple-services': 'showMultipleServicesDialog',
    'click .iglu-import-services': 'showIgluImportServices',
  },

  offerGroupListView: null,
  featuredOfferListView: null,
  menuFilters: null,
  menuTreatments: null,
  employees: null,
  menuTemplate: null,
  featureChanged: false,

  noGroupsClass: 'empty',
  // Full menu object, not dependant on the filters
  fullMenu: null,

  initialize() {
    this.model = new App.Models.Menu();
    const featuredList = new App.Collections.FeaturedOffers();
    this.menuItems = new App.Collections.MenuItems();
    const self = this;
    const employeeCategories = new App.Collections.EmployeeCategories();
    this.venue = new App.Models.Venue();

    this.employees = new App.Collections.Employees();
    this.employees.onlyActive = true;
    this.employees.onlyTakingAppointments = true;

    this.menuTemplate = new App.Collections.MenuTemplate();

    this.roomTypes = new App.Collections.RoomTypes();

    this.menuTreatments = new App.Models.MenuTreatments();
    this.offerGroupListView = new App.Views.Menu.OfferGroupList({
      el: this.$('.js-menu-content'),
      model: this.model,
      treatments: this.menuTreatments,
      employeeCategories,
      venue: this.venue,
      featuredItemsCollection: featuredList,
      employees: this.employees,
      menuTemplate: this.menuTemplate,
      roomTypes: this.roomTypes,
    });

    this.menuFilters = new App.Views.Menu.MenuFilters({
      el: this.$('#filters'),
      collection: this.menuItems,
      menuTemplate: this.menuTemplate,
      treatments: this.menuTreatments,
      menuOffers: this.menuOffers,
    });

    // Reftch the employees if a category is changed
    App.on(
      `${Wahanda.Event.EMPLOYEE_CATEGORY_CREATED} ${Wahanda.Event.EMPLOYEE_CATEGORY_UPDATED}`,
      function () {
        self.employees.trigger('updating');
        self.employees.fetch({
          success() {
            self.employees.trigger('updated');
          },
        });
      },
    );
    App.on(
      [
        Wahanda.Event.MENU_OFFER_CHANGED_ORDER,
        Wahanda.Event.MENU_GROUP_CREATED,
        Wahanda.Event.MENU_GROUP_UPDATED,
        Wahanda.Event.MENU_GROUP_DELETED,
        Wahanda.Event.MENU_GROUP_CHANGED_ORDER,
        Wahanda.Event.MENU_OFFER_SAVED,
      ].join(' '),
      // We need a setTimeout call to make sure the Wahanda.Cache event handlers have cleared
      // the caches and will start fetching anew.
      this.eventLoadModels.bind(this),
    );

    // Offer dialog related event handling
    App.on(Wahanda.Event.MENU_OFFER_DIALOG_OPENED, this.updateRouteWithOfferId.bind(this));
    App.on(Wahanda.Event.MENU_OFFER_DIALOG_CLOSED, this.resetRouteAfterOfferClose.bind(this));

    App.on(Wahanda.Event.OPEN_OFFER_MODAL, (s) => {
      self.openOfferCreation(s.type, s.menuGroupId);
    });

    this.setupEmptyViewRender();
    self.menuTreatments.fetch();

    App.mainRouter.on('route:filterChange', function () {
      self.showLoader();
    });

    this.model.on('fetched', function () {
      self.hideLoader();
    });

    App.on(Wahanda.Event.MENU_OFFER_FEATURE_CHANGE, () => {
      self.featureChanged = true;
    });
  },

  eventLoadModels() {
    // We need a setTimeout call to make sure the Wahanda.Cache event handlers have cleared
    // the caches and will start fetching anew.
    window.setTimeout(() => {
      this.loadModels();
    }, 0);
  },

  getCurrentStateHash() {
    return Wahanda.Template.render('venue/{{venueId}}/services/{{filterId}}', {
      venueId: App.getVenueId(),
      filterId: this.model.get('filter') || DEFAULT_MENU_FILTER,
    });
  },

  removeAppEvents() {
    App.off(
      [
        Wahanda.Event.MENU_OFFER_CHANGED_ORDER,
        Wahanda.Event.MENU_GROUP_CREATED,
        Wahanda.Event.MENU_GROUP_UPDATED,
        Wahanda.Event.MENU_GROUP_DELETED,
        Wahanda.Event.MENU_GROUP_CHANGED_ORDER,
        Wahanda.Event.MENU_OFFER_SAVED,
        Wahanda.Event.MENU_OFFER_UPDATED_DISCOUNT,
        Wahanda.Event.MENU_OFFER_REMOVED_DISCOUNT,
      ].join(' '),
      this.eventLoadModels.bind(this),
    );
  },

  updateRouteWithOfferId(data) {
    if (!data.offerId) {
      return;
    }

    App.mainRouter.navigate(`${this.getCurrentStateHash()}/offer/${data.offerId}`);
  },

  resetRouteAfterOfferClose() {
    App.mainRouter.navigate(this.getCurrentStateHash());
  },

  showMultipleServicesDialog() {
    const view = new App.Views.Forms.MultipleMenuServices({
      el: $('.multiple-services-form'),
      title: Wahanda.lang.menu.selectServices,
      model: this.menuTreatments,
      saveCallback(list, self) {
        view.model.addTreatments(
          list,
          function () {
            App.initVenue();
            self.close();
          },
          function () {
            view.enableForm();
            view.$('.save-action').action();
          },
        );
      },
    });

    view.render();
    view.open();
  },

  showIgluImportServices() {
    trackEvent('services', 'click', 'import-services', 'initiate');
    App.Views.Dialog.IgluModal.openIgluCopyServices(App.config.get('venue').id);
  },

  /**
   * Sets up rendering empty view. This depends on menu filters list and groups collection beingloaded.
   */
  setupEmptyViewRender() {
    let loadedItems = 0;
    let offerCount = null;
    const self = this;
    const setEmptyIfAllLoaded = function () {
      loadedItems += 1;
      if (loadedItems === 2) {
        self.setViewEmpty(offerCount === 0);
        loadedItems = 0;
        offerCount = null;
      }
    };
    self.offerGroupListView.on('group-count-change', function () {
      offerCount = self.model.get('groupsCollection').length;
      setEmptyIfAllLoaded();
    });
    self.menuFilters.collection.on('reset', function () {
      setEmptyIfAllLoaded();
    });
  },

  loadModels(data) {
    if (!Wahanda.Permissions.viewMenu()) {
      return;
    }

    this.showLoader();

    const venueId = App.getVenueId();
    const newVenue = this.model.get('venueId') !== venueId;
    const featuredList = this.offerGroupListView.options.featuredItemsCollection;

    if (newVenue) {
      const employeeCategories = this.offerGroupListView.options.employeeCategories;

      this.model.set('venueId', venueId);

      featuredList.setVenueId(venueId);
      featuredList.fetch();

      employeeCategories.setVenueId(venueId);
      employeeCategories.fetch();

      const defaultVenueOptions = {
        relations: ['opening-hours'],
      };
      this.venue
        .clear()
        .set($.extend({ venueId }, defaultVenueOptions))
        .fetch()
        .done(() => this.venue.trigger('fetched'));

      this.employees.fetch();

      if (App.config.canSellDated()) {
        this.roomTypes.fetch();
      }
    }

    this.menuItems.reset();

    if (this.featureChanged) {
      featuredList.fetch();
      this.featureChanged = false;
    }

    if (data && data.filters) {
      this.model.set('filter', data.filters);
    }
    const filterType = this.model.get('filter') || DEFAULT_MENU_FILTER;

    $.when(
      // Get the menu from the cache
      Wahanda.Cache.menu(),
      // Get the menu filters from the cache
      Wahanda.Cache.menuFiltered({ filterType }),
      Wahanda.Cache.menuTreatments(),
      App.config.isMenuServiceTemplatesEnabled() ? Wahanda.Cache.menuTemplate() : null,
      Wahanda.Cache.menuItems(),
    ).done(
      function (fullMenu, filteredMenu, menuTreatments, menuTemplate, menuItems) {
        const self = this;
        this.offerGroupListView.fullMenu = fullMenu;

        this.model.copyFrom(filteredMenu);
        this.menuTreatments.set(menuTreatments);
        this.menuOffers = this.model.get('offersCollection');
        if (menuTemplate) {
          menuTemplate.models.forEach(function (menuTemplateItem) {
            self.menuTemplate.add(menuTemplateItem);
          });
        }
        menuItems.models.forEach(function (menuItem) {
          self.menuItems.add(menuItem);
        });

        this.menuFilters.options.menuOffers = this.menuOffers;
        this.menuFilters.options.fullMenu = fullMenu.get('offersCollection');

        this.menuFilters.render();
        this.model.trigger('fetched');
        this.hideLoader();
        this.maybeOpenInitialDialog();
      }.bind(this),
    );

    // No need to refetch every app reload. Stays the same.
    this.menuTreatments.set('venueId', venueId);
  },

  render(data) {
    this.bindTooltips();

    const menuVisible = Wahanda.Permissions.viewMenu();
    this.$el.toggleClass('no-permissions', !menuVisible);
    if (menuVisible) {
      this.showLoader();
      this.loadModels({
        filters: data && data.filters,
      });
    }
  },

  getGroupFromEvent(evt) {
    let group = null;
    if (evt) {
      const groupId = $(evt.currentTarget).closest('.offer-group').data('offerGroupId');
      const collection = this.model.get('groupsCollection');
      group = collection.get(groupId);
    }
    return group;
  },

  /**
   * Shows menu group form in a dialog.
   */
  showGroupFormDialog(evt) {
    if (!Wahanda.Permissions.editMenu()) {
      return;
    }
    const group = this.getGroupFromEvent(evt);
    const id = group && group.id;
    const active = group && group.get('active');
    const name = group && group.get('name');
    const description = group && group.get('description');
    const fullOffers = this.menuFilters.options.fullMenu.models;
    const hasAnyOffers = fullOffers.some((o) => o.get('groupId') === id);
    const showArchiveConfirmation = this.menuOffers.models.some((o) => o.get('groupId') === id);
    const filter = this.model.get('filter') || 'ACT';
    const isFullFiltersEnabled = filter === 'ACT' || filter === 'ALL';

    App.ES6.Initializers.MenuGroupModal({
      active,
      description: description ? description.content : '',
      id,
      isFullFiltersEnabled,
      hasAnyOffers,
      showArchiveConfirmation,
      name,
    }).render();
  },

  showDiscountingDialog(evt) {
    const group = this.getGroupFromEvent(evt);

    App.ES6.Initializers.showGroupDiscountEditor({
      group,
      openingHours: this.venue.get('openingHours'),
      appointmentMorningEndTime: this.venue.get('appointmentMorningEndTime'),
      appointmentAfternoonEndTime: this.venue.get('appointmentAfternoonEndTime'),
      leadTimeMinutes: this.venue.get('leadTimeMinutes'),
    });
  },

  /**
   * Sets the view as empty or not empty.
   *
   * @param boolean isEmpty
   */
  setViewEmpty(isEmpty) {
    const hasOffersInOtherFilters = this.menuFilters.collection.hasOffers();
    this.$el
      .toggleClass(this.noGroupsClass, isEmpty)
      .toggleClass('has-offers', hasOffersInOtherFilters)
      .toggleClass(
        'list-is-empty',
        isEmpty &&
          hasOffersInOtherFilters &&
          this.menuFilters.getFilterValue() === DEFAULT_MENU_FILTER,
      );
  },

  bindTooltips() {
    const opts = {
      position: {
        container: this.$el.find('.section-main2'),
        viewport: true,
      },
    };
    this.$el.tooltipize(opts);
  },

  showLoader() {
    this.$el.loadmask();
  },

  hideLoader() {
    this.$el.unloadmask();
  },

  maybeOpenInitialDialog() {
    const initialDialog = App.mainViewOptions && App.mainViewOptions.initialDialog;

    if (initialDialog && initialDialog.offerId > 0) {
      App.mainViewOptions.initialDialog = null;

      if (parseInt(initialDialog.venueId, 10) !== App.getVenueId()) {
        // Venues don't match.
        return;
      }

      const offer = this.model.get('offersCollection').get(initialDialog.offerId);
      if (!offer) {
        return;
      }

      this.openOfferEditor(offer);
    }
  },

  getBaseOptions(model) {
    return {
      treatments: this.menuTreatments,
      employeeCategories: this.offerGroupListView.options.employeeCategories,
      model,
      venue: this.venue,
      featuredItemsCollection: this.offerGroupListView.options.featuredItemsCollection,
      employees: this.employees,
      roomTypes: this.roomTypes,
    };
  },

  openOfferEditor(offer) {
    openServiceEditForm(this.getBaseOptions(offer));
  },

  openOfferCreation(type, groupId = null) {
    openServiceEditForm(
      this.getBaseOptions(App.Models.MenuOffer.typeOf(type, groupId, this.employees)),
    );
  },
});
