import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { OnboardingWidget } from 'components/shared/OnboardingWidget/OnboardingWidget';

interface Params {
  node: HTMLElement;
}

export const OnboardingWidgetInitializer = ({ node }: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(node),
  render: () => {
    ReactDOM.render(<OnboardingWidget />, node);
  },
});
