import { xhr } from 'common/xhr';

;(function($) {
	/**
	 * Wahanda search plugin, based on top of $.ui.autocomplete.
	 */
	$.widget("wahanda.search", {
		// These options will be used as defaults
		options: {
			$results: null,
			$clear  : null,
			$loader : null,
			// Should be a function which takes one argument - the chosen object
			select  : null
		},

		hasResults: false,

		_create: function() {
			this._bindEvents();
			this.options.$loader && this.options.$loader.hide();

			this._createAutocomplete();
		},

		_bindEvents: function() {
			var self = this;
			if (this.options.$clear) {
				this.options.$clear.on('click.whselect', function(event) {
					event.preventDefault();
					self.clearSearch();
				});

				// Showing the "Delete content" element only if there is some content
				this.options.$clear.hide();
				this.element.on('keyup.whselect', function() {
					self.options.$clear.toggle( this.value !== "" );
				});
			}

			App.on(Wahanda.Event.APP_VENUE_CHANGED, this.onVenueChanged, this);
		},

		_createAutocomplete: function() {
			var self           = this;
			var minLength      = 2;
			var autocomplete;
			this.element.autocomplete({
				minLength: minLength,
				open     : this.options.open,
				appendTo : this.options.appendTo,
				autoFocus: true,
				position : this.options.position,
				source   : function(request, response) {
					// Close previous results before searching
					// autocomplete.close();
					// Mark as "no results available yet"
					self.hasResults = false;
					self.options.$loader && self.options.$loader.show();

				    if (!self.options.localSource) {
              xhr.doJQueryAjax({
                dataType: 'json',
                url: $.isFunction(self.options.source) ? self.options.source() : self.options.source,
                data: { q: request.term },
                success: function(result) {
                  var count = 0;
                  _.each(result, function(list) {
                    count += list ? list.length : 0;
                  })
                  // Used to trick autocomplete
                  result.length = count;
                  self.hasResults    = (count > 0);

                  // Hide the loader
                  self.options.$loader && self.options.$loader.hide();
                  response(result);
                }
              })
							.fail(function() {
								self.options.$loader && self.options.$loader.hide();
								// Hide old results
								self.hasResults = false;
								autocomplete.close();
							});
				    } else {
				    	var result = [];
				    	var localSource = self.options.localSource
				    	var sourceList = self.options.source();
				    	_.each(sourceList[self.options.localSource], function(item) {
				    		// check if item is a model
				    		if (item.collection && item.attributes) {
				    			item = item.attributes;
				    		}

				    		if (item.name.toLowerCase().indexOf(request.term.toLowerCase()) > -1) {
				    			result.push(item);
				    		}
				    	});
				    	var count = result.length
							self.hasResults    = (count > 0);
							var resultObject = {};
							resultObject[localSource] = result;
							resultObject['length'] = count;
							self.options.$loader && self.options.$loader.hide();
							response(resultObject);
				    }

				},
				select: function(event, data) {
					self.element.blur();
					self.select(data.item);
				}
			});
			this.element
			.on('focus.whselect', function() {
				if (self.hasResults && this.value.length >= minLength) {
					autocomplete.open();
				}
			})
			.on("keydown.whselect", function( event ) {
				if (autocomplete.options.disabled || autocomplete.element.prop("readOnly")) {
					return;
				}

				if (event.keyCode === $.ui.keyCode.ESCAPE) {
					self.clearSearch();
					$(this).blur();
				}
			})
			.on("autocompleteopen", function () {
				$(this).addClass("dropdown-open");
			})
			.on("autocompleteclose", function () {
				$(this).removeClass("dropdown-open");
			});

			// Autocomplete widget hacking.
			autocomplete = this.element.data('autocomplete');

			autocomplete._renderMenu = function( ul, items ) {
				self._renderResults(ul, items);
			};

			autocomplete.menu.element.addClass('search-results').css('z-index', '');

			// Override the method which deals with results
			autocomplete._resizeMenu = function( items ) {
				var $list = autocomplete.menu.element;
				$list.scrollTop(0);
				self._setHeight($list);
			};

			// Override "normalize" because we don't use autocomplete format
			autocomplete._normalize = function(items)  {
				return items;
			};

			autocomplete.open = function(items)  {
				if (autocomplete.menu.element.is(":hidden")) {
					autocomplete.menu.element.show();
					this._trigger("open");
				}
			};
		},

		_renderResults: function($parent, items) {
			var $container, handler, list, item;
			var $fragment = $(document.createDocumentFragment());
			var separate  = false;
			var types     = [
				{
					index: "visits",
					type: "visits",
					limit: 10,
					prepareItem: function(item) {
						item.isAppointment = (item.bookingType == "AP");
						// Format date
						var date = Wahanda.Date.createDate(item.visitDate, item.visitTime);
						date     = Wahanda.Date.formatToDefaultFullDate(date);
						item.date = date.date + (item.visitTime ? ' ' + date.time : '');
						// Icon
						var icon;
						if (item.anonymous) {
							icon = 'walkin';
							item.consumerName = (item.anonymousNote || Wahanda.lang.topSearch.defaultAnonymousName);
						} else {
							icon = (item.isAppointment ? "appointment" : "dated");
						}
						item.icon = icon;

						if (item.recipientName && item.recipientName !== item.consumerName) {
							/* We switch up values between consumerName and recipientName for display purpose */
							let consumerName = item.consumerName;

							item.consumerName = item.recipientName;
							item.recipientName = consumerName;
							item.templateShowRecipientName = true;
						}

						return item;
					}
				},
				{
					index: "bookings",
					type: "bookings",
					limit: 10
				},
				{
					index: "products",
					type: "products",
					limit: 20
				},
				{
					index: "discounts",
					type: "discounts",
					limit: 20
				},
				{
					index: "consumers",
					type: "clients",
					limit: 20,
					prepareItem: function(item) {
						item.icon = item.prepaymentRequired ? 'prepay' : 'user2';
						return item;
					}
				}
			];

			for (var i = 0, len = types.length; i < len; i++) {
				handler = types[i];
				if (!handler) {
					continue;
				}
				list = items[handler.index];
				if (!list || list.length === 0) {
					continue;
				}

				for (var j = 0, jLen = list.length; j < jLen; j++) {
					if (j === handler.limit) {
						// Limit reached
						break;
					}

					item = list[j];
					$node = $(Wahanda.Template.renderTemplate(
						'search-autocomplete-results-item-' + handler.type,
						{
							icon: handler.icon,
							item: (handler.prepareItem ? handler.prepareItem(item) : item)
						}
					));
					item._type = handler.type;
					$node.data('item', item);

					if (separate) {
						separate = false;
						$node.addClass('with-separator');
					}

					$fragment.append($node);
				}

				separate = true;
			}

			$parent.append($fragment);
		},

		_setHeight: function($list) {
			$list.height('');
			var listTop   = $list.offset().top;
			var winHeight = window.innerHeight || $(window).height();
			var maxHeight = winHeight - listTop - 20;

			if (!isNaN(this.options.maxHeight)) {
				maxHeight = Math.min(maxHeight, this.options.maxHeight);
			}

			if ($list.height() > maxHeight) {
				$list.height( maxHeight );
			}
		},

		clearSearch: function() {
			this.element.val('').change();
			this.options.$clear && this.options.$clear.hide();
		},

		select: function(item) {
			this.options.select(item);
		},

    search: function(value) {
      this.element.autocomplete('search', value);
		},

		destroy: function() {
			if (this.options.$clear) {
				this.options.$clear.off('.whselect');
			}
			if (this.options.$results) {
				this.options.$results.off('.whselect');
			}
			this.element.autocomplete('destroy').off('.whselect');

			$.Widget.prototype.destroy.call( this );
		},

		onVenueChanged: function() {
			var self;
			// Clear the results
			this.hasResults = false;
			// On focus, if any value is set, search again
			if ("" != this.element.val()) {
				self = this;
				this.element.off('click.whsearchone').one('click.whsearchone', function() {
					self.element.autocomplete('search', this.value);
				});
			}
		}
	});
}(jQuery));
