import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Verified extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-verified', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M12,0 C16.9705627,0 21,4.02943725 21,9 C21,13.9705627 16.9705627,18 12,18 C10.9482491,18 9.93863511,17.8195907 9.00041586,17.4880298 L9,20.7192236 L12,19.9692236 L15,20.7192236 L15,19 L17,19 L17,23.2807764 L12,22.0307764 L7,23.2807764 L6.99978429,16.4843066 C4.58793383,14.8697643 3,12.1203231 3,9 C3,4.02943725 7.02943725,0 12,0 Z M12,2 C8.13400675,2 5,5.13400675 5,9 C5,12.8659932 8.13400675,16 12,16 C15.8659932,16 19,12.8659932 19,9 C19,5.13400675 15.8659932,2 12,2 Z M15.2928932,5.79289322 L16.7071068,7.20710678 L11,12.9142136 L7.29289322,9.20710678 L8.70710678,7.79289322 L11,10.0857864 L15.2928932,5.79289322 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Verified);
