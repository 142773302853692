import React from 'react';
import classnames from 'classnames';

import { InputContext } from '../InputContext';
import { InputSizeType } from '../utils/types';
import styleLegacy from './InputText.scss';
import styleNewLook from './InputText.newLook.scss';

export type InputTextValue = string | number;

interface Props {
  autoFocus?: boolean;
  autoComplete?: string;
  dataTest?: string;
  children?: React.ReactNode;
  name?: string;
  min?: number | string;
  maxLength?: number;
  max?: number | string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  type?: string;
  value?: InputTextValue;
  alignRight?: boolean;
  newLook?: boolean;
}

export const InputText = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    autoComplete = 'nope',
    autoFocus,
    dataTest,
    children,
    name,
    maxLength,
    min,
    max,
    onBlur,
    onChange,
    onFocus,
    readOnly,
    onKeyDown,
    placeholder,
    type = 'text',
    value,
    alignRight,
    // Could be passed by consumer
    ...otherProps
  } = props;
  const {
    size = InputSizeType.DEFAULT,
    disabled,
    fullBorder,
    newLook,
    isSearchable,
  } = React.useContext(InputContext);
  const style = newLook ? styleNewLook : styleLegacy;
  const className = classnames(style.input, style[size], alignRight && style.textRight, {
    [style.readOnly]: readOnly,
    [style.fullBorder]: fullBorder,
    [style.searchable]: isSearchable,
  });

  const renderHiddenInput = (element) => {
    return (
      <div ref={ref} className={className}>
        {element}
      </div>
    );
  };

  if (children) {
    return renderHiddenInput(children);
  }

  if (readOnly) {
    return renderHiddenInput(value);
  }

  return (
    <input
      ref={ref}
      {...otherProps}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      className={className}
      data-test={dataTest}
      disabled={disabled}
      name={name}
      max={max}
      maxLength={maxLength}
      min={min}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      readOnly={readOnly}
      type={type}
      value={value}
    />
  );
});
