import React from 'react';
import ReactDOM from 'react-dom';
import Wahanda from 'common/wahanda';
import { Initializer } from 'common/types/initializers';
import { NoShowOverPhone } from './';

const container = Wahanda.Dialogs.getReactContainer();

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

const onClose = () => {
  destroy(container);
};

export const NoShowOverPhoneInitializer = (): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(container),
  render: () => {
    ReactDOM.render(<NoShowOverPhone onClose={onClose} />, container);
  },
});
