import App from 'common/backbone-app';
import { connect } from 'react-redux';
import { FBEInstallStatus } from '../types';
import { completeFBEIntegration } from './actions';
import { NotInstalled as Component } from './NotInstalled';

const mapStateToProps = ({
  marketing: {
    fbeIntegration: { installStatus },
  },
}) => {
  return {
    venueId: App.config.get('venue').id,
    timezone: App.getTimezone(),
    currency: App.config.get('currency').currencyCode,
    isLoading: installStatus.status === FBEInstallStatus.INSTALLING,
    businessConfig: installStatus.businessConfig,
  };
};

const mapDispatchToProps = {
  completeFBEIntegration,
};

export const NotInstalled = connect(mapStateToProps, mapDispatchToProps)(Component);
