export enum AppointmentRepeatBase {
  WEEKLY = 'WEEKLY',
}

export interface AppointmentRepeatModel {
  recurrence: {
    base: AppointmentRepeatBase;
    properties: {
      interval: number;
      count: number;
    };
  };
}
