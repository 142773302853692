/* eslint-disable func-names */
/**
 * Employees collection.
 */
App.Collections.Employees = Backbone.Collection.extend({
  model: App.Models.Employee,
  onlyActive: true,
  onlyTakingAppointments: null,
  /**
   * Associated model data to indluce.
   *
   * Possibilities:
   *  > employee-offers
   */
  include: [],

  initialize: function (opt) {
    let options = opt;
    if (!options) {
      options = {};
    }
    if (typeof options.onlyTakingAppointments !== 'undefined') {
      this.onlyTakingAppointments = options.onlyTakingAppointments;
    }
    if (options.include) {
      this.include = options.include;
    }
    if (typeof options.onlyActive !== 'undefined') {
      this.onlyActive = options.onlyActive;
    }
  },

  url: function () {
    let base = `/venue/${App.config.get('venue').id}/employees.json?`;

    if (typeof this.onlyActive === 'boolean') {
      base += `active=${this.onlyActive ? 'true' : 'false'}`;
    }
    if (_.isArray(this.include) && this.include.length > 0) {
      base += `&include=${this.include.join(',')}`;
    }
    if (typeof this.onlyTakingAppointments === 'boolean') {
      base += `&takes-appointments=${this.onlyTakingAppointments ? 'true' : 'false'}`;
    }

    return App.Api.wsUrl(base);
  },

  /**
   * Returns array of models for this collection to use.
   *
   * @param Object data
   * @return Array
   */
  parse: function (data) {
    return data.employees;
  },

  // Other possible comparators
  comparatorByName: function (a, b) {
    const nameA = String(a.get('name'));
    const nameB = String(b.get('name'));
    return nameA.localeCompare(nameB);
  },
  // End of comparator list

  getInCalendarFormat: function () {
    const result = [];
    this.each(function (employee) {
      const image = employee.get('image');

      result.push({
        id: employee.id,
        title: employee.get('name'),
        imageId: image && image.id,
        // eslint-disable-next-line camelcase
        image_src: image && image.uris['50x50'],
        picture: true,
      });
    });

    return result;
  },

  /**
   * Converts the collection to select <option> element list.
   *
   * @param Object config (optional) Config options. Possible values:
   *   > empty         : Allow empty value (will add a option at the beginning, value="")
   *   > skipEmployees : List of employeeId`s to skip.
   *   > title         : Function to be passed the employee name and return correct label.
   * @param {Object} config
   * @return String HTML
   */
  toSelectOptions: function (config) {
    return Wahanda.Template.renderTemplate('form-select-items', {
      options: this.toSimpleList(config),
    });
  },

  toSimpleList: function (con) {
    const config = con || {};
    const list = [];

    if (config.empty) {
      list.push({
        value: '',
        title: Wahanda.lang.shared.selectInitialOption,
      });
    }

    const toSkip = config.skipEmployees || [];
    this.each(function (employee) {
      if (_.indexOf(toSkip, employee.id) === -1) {
        let name;
        if (_.isFunction(config.title)) {
          name = config.title(employee);
        } else {
          name = employee.get('name');
        }
        list.push({
          value: employee.id,
          title: name,
        });
      }
    });

    return _.sortBy(list, function (o) {
      return o.title;
    });
  },

  /**
   * Return list of employee IDs.
   *
   * @param Object options Filter parameters for .where()
   * @return Array
   */
  getIds: function (options) {
    const list = this.where(options);
    const ids = _.map(list, function (employee) {
      return { employeeId: employee.id };
    });
    return ids;
  },

  /**
   * Return index of employee in the current sorting order.
   *
   * @param Number id
   * @return Number -1 if not found.
   */
  getIndex: function (id) {
    let index = 0;
    const found = this.any(function (emp) {
      if (emp.id === id) {
        return true;
      }
      index += 1;
      return false;
    });
    if (found) {
      return index;
    }
    return -1;
  },

  getByCategory: function (categoryId) {
    return this.where({ employeeCategoryId: categoryId });
  },

  hasAssignedToEmployeeCategories: function () {
    return this.any(function (emp) {
      return emp.get('employeeCategoryId') > 0;
    });
  },
});
