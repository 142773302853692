import React from 'react';
import ReactDOM from 'react-dom';
import { toClj } from 'mori';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import { SidebarActions } from 'components/calendar/SidebarActions';

const store = storeBuilder();

export default function initialize(targetElement, { addActions }) {
  ReactDOM.render(
    <Provider store={store}>
      <SidebarActions addActions={toClj(addActions)} />
    </Provider>,
    targetElement,
  );
}
