import { ActionUnionTypes } from 'reduxStore/actionCreator';
import {
  DAILY_REPORT_REQUEST,
  DAILY_REPORT_SUCCESS,
  DAILY_REPORT_FAILURE,
} from 'reduxStore/actionsConst';
import { DailyReport } from './types';

import * as dailyReportActions from './actions';

export const initialState: DailyReport | {} = {};

export const dailyReportReducer = (
  state = initialState,
  action: ActionUnionTypes<typeof dailyReportActions>,
): DailyReport | {} => {
  switch (action.type) {
    case DAILY_REPORT_REQUEST:
      return initialState;
    case DAILY_REPORT_SUCCESS:
      return { ...action.payload.dailyReport };
    case DAILY_REPORT_FAILURE:
      return initialState;
    default:
      return state;
  }
};
