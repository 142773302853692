/**
 * View for form functionality.
 */
App.Views.Form = Backbone.View.extend({
  /**
   * Prefix for form field names. Can be a string or regexp.
   */
  fieldPrefix: '',

  /**
   * Can the current user navigate away from this form?
   * It returns false when the model has some changed attributes.
   *
   * @return boolean
   */
  canNavigateAway: function () {
    if (this.model) {
      return this._modelValuesHaventChanged() || confirm(Wahanda.lang.settings.switchTabQuestion);
    }
    return true;
  },

  _modelValuesHaventChanged: function () {
    return !this.model.hasChanged();
  },

  /**
   * Disabled any forms on this dialog.
   */
  disableForm: function () {
    this.$el.toggleForm(true);
    if (this.customDisableForm) {
      this.customDisableForm();
    }
  },

  /**
   * Enables previously disabled forms.
   */
  enableForm: function () {
    this.$el.toggleForm(false);
    if (this.customEnableForm) {
      this.customEnableForm();
    }
  },

  /**
   * Fils this view's values with ones from model.
   *
   * @param Object defaults OPTIONAL Default values
   */
  fillFormFromModel: function (defaults, ignore) {
    var $elems = this.$(':input').not('button').not('input[type=button]').not('input[type=submit]');
    var model = this.model;
    defaults = defaults || {};
    ignore = ignore || [];
    var prefix = this.fieldPrefix;

    $elems.each(function () {
      var $elem = $(this);
      var name = $elem.attr('name')?.replace(prefix, '');

      if (-1 !== _.indexOf(ignore, name)) {
        // Field is ignored. Continue;
        return;
      }

      var value = model.get(name);

      var valueToSet = '';

      if (value != null && (!!value || parseInt(value, 10) === 0 || value === false)) {
        valueToSet = value;
      } else if (typeof defaults[name] != 'undefined') {
        valueToSet = defaults[name];
      }

      if ($elem.is(':checkbox')) {
        $elem.prop('checked', !!valueToSet);
      } else {
        $elem.val(valueToSet);
      }
    });
  },

  getFormValues: function (options) {
    var data;

    if (options && options.withDisabled) {
      data = Wahanda.Form.serializeWithDisabled(this.$('form:first'));
    } else {
      data = Wahanda.Form.serialize(this.$('form:first'));
    }

    if (this.fieldPrefix) {
      var newData = {};
      for (var name in data) {
        var newName = name.replace(this.fieldPrefix, '');
        newData[newName] = data[name];
      }
      data = newData;
    }

    return data;
  },

  setValidation: function () {
    var self = this;
    var validations = Wahanda.Validate.getValidations('defaults', {
      submitHandler: function () {
        self.save();
      },
    });

    this.$('form').validate(validations);
  },

  save: function () {
    throw new Error('Not implemented');
  },

  _defaultSaveAction: function () {
    var self = this;
    var $saveButton = this.$('.save-action');
    var isNew = !this.model.id;
    this.disableForm();

    this.model.save(null, {
      success: function () {
        $saveButton.action();
        self.enableForm();
        self._afterSaveCallback({ isNew: isNew });
      },
      error: function () {
        self.enableForm();
        $saveButton.action().errorTip(Wahanda.lang.shared.savingFailed);
        self.model.revert();
      },
    });
  },

  _afterSaveCallback: function (data) {},
});
