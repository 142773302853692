import React from 'react';
import { Tooltip, TooltipPlacement } from 'components/common/Tooltip';

export interface TooltipOverflowProps {
  placement?: TooltipPlacement | string;
  text: string;
  maxLength: number;
}

export class TooltipOverflow extends React.PureComponent<TooltipOverflowProps> {
  public render() {
    const { text, maxLength, placement = TooltipPlacement.top } = this.props;
    if (!text || text.length <= maxLength) {
      return text;
    }
    return (
      <Tooltip placement={placement} tooltip={text}>
        <span>{`${text.substring(0, maxLength)}...`}</span>
      </Tooltip>
    );
  }
}
