import setupWebSocketsListener from 'src/websockets/listener';
import {
  receiveWebSocketsConnectedAction,
  receiveWebSocketsMessageAction,
} from '../../common/websockets/actions';
import { GET_WEBSOCKETS_CONNECT_REQUEST } from '../../actionsConst';

export default function WebsocketsMiddleware(store) {
  return (next) => (action) => {
    if (action.type === GET_WEBSOCKETS_CONNECT_REQUEST) {
      const handleEvent = (data) =>
        store.dispatch(receiveWebSocketsMessageAction({ type: action.data.type, data }));

      const onWebSocketsConnected = () =>
        store.dispatch(receiveWebSocketsConnectedAction({ type: action.data.type }));

      setupWebSocketsListener(action.data.type, handleEvent, onWebSocketsConnected);
    }

    return next(action);
  };
}
