import React from 'react';

interface IErrorProps extends React.HTMLAttributes<Element> {
  errorName?: string;
  errorDescription?: string;
}

export default class Error extends React.Component<IErrorProps, {}> {
  render() {
    return (
      <div className="error">
        <span className="error-name">{this.props.errorName}</span>
        <span className="error-description">{this.props.errorDescription}</span>
      </div>
    );
  }
}
