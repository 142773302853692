import React from 'react';
import Wahanda from 'common/wahanda';
import { TreatmentList } from 'components/menu/DiscountRuleDialog/TreatmentList';
import style from './style.scss';

const lang = Wahanda.lang.settings.employees.employee;
interface IServicesProps extends React.HTMLAttributes<Element> {
  employeeOffers?: number[];
  actions: {
    setEmployeeDataAction: (...args: any[]) => any;
  };
}

export default class Services extends React.PureComponent<IServicesProps, {}> {
  public static defaultProps = {
    employeeOffers: [],
  };

  public render() {
    const { employeeOffers } = this.props;
    return (
      <div>
        <div className={style.header}>{lang.employeeOffers.title}</div>
        <div className={style.listContainer}>
          <TreatmentList
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            assignedTreatments={employeeOffers.map((offerId) => ({
              id: offerId,
            }))}
            includedServiceTypes={['T', 'P', 'Y']}
            selectAllCheckboxVisible
          />
        </div>
      </div>
    );
  }
}
