(function($) {
	/**
	 * Selects the nearest duration (in minutes) of the given SELECT element;
	 * @param Object int
	 */
	$.fn.selectNearestDuration = function(duration) {
		var $options = this.find('option').filter(function() {
			return !this.disabled;
		});
		var selected = null;
		duration     = parseInt(duration, 10);

		$options.each(function(opt, index) {
			var currentDuration = parseInt($(this).val(), 10);
			if (currentDuration >= duration) {
				selected = currentDuration;
				return false;
			}
		});

		if (null === selected) {
			var $last = $options.last();
			if ($last.val() < duration) {
				selected = $last.val();
			}
		}

		this.val(selected);
		return this;
	};
}(jQuery));
