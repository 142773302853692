/* eslint-disable func-names */
App.Models.MenuTemplateItem = BackboneEx.Model.Base.extend({
  idAttribute: 'treatmentGroupId',

  /**
   * Convert the array of plain objects to MenuOffer models when parsing.
   *
   * @param {Object} serverData The API data.
   * @returns {Object} the parsed data, to set on this model.
   */
  parse: function (serverData) {
    const offers = (serverData.menuItems || []).map(function (data) {
      return new App.Models.MenuOffer(
        _.extend(
          {
            // Currently we support only Service offers of Treatment type.
            // The Backend returns the full representation, which we don't support, so this is also
            // hard-wiring of those values to supported ones.
            offerTypeCode: 'SV',
            menuItemTypeCode: 'T',
          },
          data,
        ),
      );
    });

    return _.extend({}, serverData, { menuItems: offers });
  },
});
