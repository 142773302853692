import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import ExternalSalonIntegration from 'components/settings/externalSalonIntegration/container';

const store = storeBuilder();
let settingsChanged = false;

function onChange({ hasChanges }) {
  settingsChanged = hasChanges;
}

function render(target) {
  const venueId = App.config.get('venue').id;
  ReactDOM.render(
    <Provider store={store}>
      {/* @ts-expect-error */}
      <ExternalSalonIntegration onChange={onChange} venueId={venueId} />
    </Provider>,
    target,
  );
}

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

function canNavigateAway() {
  return !settingsChanged;
}

export default { render, destroy, canNavigateAway };
