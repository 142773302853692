import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions';
import ProductSupplierDialog from './index';

const mapStateToProps = ({
  productSuppliers: { loading: suppliersLoading },
  products: { loading: productsLoading, items: products },
}) => ({
  loading: suppliersLoading || productsLoading,
  products,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ id: null; loading: boolean; products: neve... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(ProductSupplierDialog);
