(function () {
  /**
   * Form utils.
   */
  Wahanda.Form = {
    /**
     * Serialize form to Backbone.Model format.
     *
     * @param jQuery form
     * @return Object
     */
    serialize: function (form) {
      var array = form.serializeArray();
      return Wahanda.Form.serializedToModelArray(array);
    },

    /**
     * Serialize the form, with [disabled] values included.
     *
     * @param jQuery $form
     * @return Object
     */
    serializeWithDisabled: function ($form) {
      // Need to enable the fields, as $.serializeArray() skips disabled fields.
      var $disabled = $form.find(':input:disabled').prop('disabled', false);
      var result = Wahanda.Form.serialize($form);

      // Restore to the initial disabled state
      $disabled.prop('disabled', true);

      return result;
    },

    /**
     * Converts serialized with $.serializeArray() form data to Backbone model's format.
     * This does not work on multi-dimensional arrays currently.
     *
     * @param Array array
     * @return Object
     */
    serializedToModelArray: function (array) {
      var ret = {};

      for (var i = 0, len = array.length; i < len; i++) {
        var item = array[i];
        ret[item.name] = item.value;
      }
      return ret;
    },
  };
})(jQuery);
