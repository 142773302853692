import React from 'react';
import Wahanda from 'common/wahanda';
import { Currency, Percentage } from 'components/common/DataFormat';
import {
  Table,
  TableColumnHeader,
  Align,
  TableRow,
  TableBody,
  TableFooter,
  TableHeader,
  TableData,
  TableLayout,
} from 'components/common/Table';
import SubHeader from '../SubHeader';
import style from './style.scss';

interface Summary {
  grossAmount: number;
  vatAmount: number;
  netAmount: number;
}

interface VATSummary extends Summary {
  vatRate: number;
}

export interface Props {
  vatRows: VATSummary[];
  grandTotal: Summary;
}

const LANG = Wahanda.lang.reports.posReports.salesPeriod.vatRate;

export default class VATRate extends React.PureComponent<Props> {
  public render() {
    return (
      <div>
        <SubHeader text={LANG.header} />
        <Table layout={TableLayout.FIXED} border>
          <TableHeader>{this.renderHeader()}</TableHeader>
          <TableBody>{this.renderBody()}</TableBody>
          <TableFooter>{this.renderFooter()}</TableFooter>
        </Table>
      </div>
    );
  }

  private renderHeader() {
    return (
      <TableRow>
        <TableColumnHeader align={Align.LEFT}>{LANG.vatRate}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.net}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.vat}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.gross}</TableColumnHeader>
      </TableRow>
    );
  }

  private renderBody() {
    const { vatRows } = this.props;
    return vatRows.map((vatSummary) => this.renderVatSummary(vatSummary));
  }

  private renderVatSummary(vatSummary: VATSummary) {
    const { grossAmount, netAmount, vatAmount, vatRate } = vatSummary;
    return (
      <TableRow key={vatRate}>
        <TableData align={Align.LEFT}>
          <Percentage value={vatRate} />
        </TableData>
        <TableData align={Align.RIGHT}>
          <Currency amount={netAmount} />
        </TableData>
        <TableData align={Align.RIGHT}>
          <Currency amount={vatAmount} />
        </TableData>
        <TableData align={Align.RIGHT}>
          <Currency amount={grossAmount} />
        </TableData>
      </TableRow>
    );
  }

  private renderFooter() {
    const { grossAmount, netAmount, vatAmount } = this.props.grandTotal;
    return (
      <TableRow>
        <TableData align={Align.LEFT}>
          <div className={style.bolded}>{LANG.grandTotal}</div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={netAmount} />
          </div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={vatAmount} />
          </div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={grossAmount} />
          </div>
        </TableData>
      </TableRow>
    );
  }
}
