import React from 'react';
import Dialog from 'components/common/react-dialog';
import { Notice } from 'components/common/Notice';
import { Button } from 'components/common/Button';
import { CancellationFlowAnalytics } from 'common/analytics';
import style from './RescheduleOrCancelChoice.scss';

interface Props {
  lang: any;
  onCancel: () => void;
  onReschedule: () => void;
  action: string;
  onClose: () => void;
  reschedulingAllowed: boolean;
}

export class RescheduleOrCancelChoice extends React.Component<Props> {
  public componentDidMount() {
    const action = this.props.action;
    if (action === 'on-cancel') {
      CancellationFlowAnalytics.trackViewCancellationInitiationOnCancel();
    } else if (action === 'on-reject') {
      CancellationFlowAnalytics.trackViewCancellationInitiationOnReject();
    }
  }

  private onReschedule = () => {
    CancellationFlowAnalytics.trackClickRescheduleButton();
    this.props.onReschedule();
  };

  private onCancel = () => {
    CancellationFlowAnalytics.trackClickCancelButton();
    this.props.onCancel();
  };

  public render() {
    const { lang, reschedulingAllowed } = this.props;

    return (
      <Dialog
        dataTest="reschedule-or-cancel-choice-modal"
        width={400}
        title={lang.title}
        classes={{ 'dialog2--button-choices appointment-deletion-proxy': true }}
        onClose={this.props.onClose}
      >
        <div className="title">{lang.heading}</div>
        <div className="intro">{lang.text}</div>
        <div className={style.attentionContainer}>
          <Notice type="alert" message={lang.contactCustomerAttention} />
        </div>
        {reschedulingAllowed && (
          <div className={style.button}>
            <Button
              fullWidth
              label={lang.buttons.reschedule}
              onClick={this.onReschedule}
              dataTest="cancellation-reschedule"
            />
          </div>
        )}
        <Button
          fullWidth
          variant="secondary"
          colour="alert"
          label={lang.buttons.cancel}
          onClick={this.onCancel}
          dataTest="cancellation-continue"
        />
      </Dialog>
    );
  }
}
