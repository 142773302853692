/* global Backbone */
/* global BackboneEx */
/* global _ */
/* eslint prefer-arrow-callback: 0 */
/* eslint-disable no-param-reassign */
import { trackEvent, SalesReportOldAnalytics } from 'common/analytics';
/**
 * @mixin BackboneEx.Mixin.View.Datepicker
 */
App.Views.Reports.SalesReport = Backbone.View.extend({
  initialize: function initialize() {
    const self = this;
    this.model.on('fetched', () => {
      self.render();
    });

    this.initialiseDateRangeSelector();
    this.model.setUrlFilters(this.getDateFilters());
    this.updateDateRangeSelectorTitle();
    this.initialiseDateRange();

    this.on('visible', () => {
      SalesReportOldAnalytics.trackPageView();
    });
  },

  initialiseDateRangeSelector: function initialiseDateRangeSelector() {
    const self = this;
    let currentlyOpen = false;
    let weekgranularityPreviouslySelected = false;

    const $dateRangeSelector = this.$('.date-range-selector');
    const $periodGranularity = this.$('.period-granularity input');
    const $closedTitle = $dateRangeSelector.find('.current');
    const $openTitle = $dateRangeSelector.find('.title');
    const $dialog = $dateRangeSelector.find('.selection');
    const $form = $dialog.find('form');
    const $toggleOpenClose = $closedTitle.add($openTitle);
    const $dateLabels = $dialog.find('.date label > *');
    const $startDate = this.$('.datepicker');
    const $cancel = $dialog.find('.action.cancel');
    const $main = $dialog.find('.action.main');

    this.$dateRangeSelectorCurrentValue = $closedTitle.find('.value');
    this.yesterday = Wahanda.Date.addDaysToDate(new Date(), -1);
    this.startDate = this.yesterday;

    function selectDateLabel() {
      $dateLabels.hide();
      $dateLabels.filter(`.v-${self.periodGranularity}`).show();
    }

    function openClose(isOpen) {
      $closedTitle.toggle(!isOpen);
      $dialog.toggle(isOpen);

      currentlyOpen = isOpen;
    }

    function close() {
      openClose(false);
    }

    selectDateLabel();
    $toggleOpenClose.click(() => {
      openClose(!currentlyOpen);
    });

    $cancel.click(() => {
      close();
    });

    $main.click(() => {
      close();
      self.fetchAndRender();
      trackEvent('sales-reports', 'edit', self.model.filters);
    });

    self.periodGranularity = $periodGranularity.filter(':checked').val();
    $periodGranularity.on('change', function periodGranularity() {
      self.periodGranularity = $(this).val();
      trackEvent('sales-report', 'click', `period-${self.periodGranularity}`);

      if (self.periodGranularity === 'week' && !weekgranularityPreviouslySelected) {
        const weekStart = Wahanda.Date.getWeekStartDate($startDate.datepicker('getDate'));
        $startDate.datepicker('setDate', weekStart);
        self.startDate = weekStart;

        weekgranularityPreviouslySelected = true;
      }

      if (self.periodGranularity === 'month') {
        const startOfMonth = $startDate.datepicker('getDate');

        startOfMonth.setDate(1);
        $startDate.datepicker('setDate', startOfMonth);
        self.startDate = startOfMonth;
      }

      selectDateLabel();
    });

    selectDateLabel();

    $('body').on('click keydown', (event) => {
      if (event.type === 'click') {
        if (
          !$closedTitle.is(event.target) &&
          $closedTitle.has(event.target).length === 0 &&
          $form.has(event.target).length === 0 &&
          $('.ui-datepicker').filter(':visible').length === 0
        ) {
          close();
        }
      } else if (
        event.type === 'keydown' &&
        event.keyCode === 27 &&
        $startDate.datepicker('widget').is(':not(:visible)')
      ) {
        close();
      }
    });

    $startDate.venueDatepicker({
      maxDate: self.yesterday,
      beforeShow: () => {
        $startDate.datepicker('option', {
          changeMonth: self.periodGranularity === 'month',
          changeYear: self.periodGranularity === 'month',
        });

        $('.ui-datepicker').toggleClass('ui-month-year-only', self.periodGranularity === 'month');
      },
      onChangeMonthYear: function onChangeMonthYear(year, month) {
        if (self.periodGranularity === 'month') {
          let startOfMonth = new Date(year, month - 1, 1);

          if (Wahanda.Date.isFuture(startOfMonth, true)) {
            // Can occur when changing to current year, when the month is later than
            // the current month. Appears to be an anomally of the datepicker.
            startOfMonth = new Date();
            startOfMonth.setDate(1);
          }

          $startDate.datepicker('setDate', startOfMonth);
          self.startDate = startOfMonth;
        }
      },
      onClose: function onClose() {
        $startDate.blur();
      },

      onSelect: function onSelect() {
        self.startDate = $startDate.datepicker('getDate');
      },
    });
    $startDate.datepicker('setDate', '-1d');
  },

  initialiseDateRange: function initialiseDateRange() {
    if (
      App.mainViewOptions &&
      App.mainViewOptions.reportType === 'sales' &&
      App.mainViewOptions.filters
    ) {
      this.periodGranularity = App.mainViewOptions.filters.periodGranularity;
      this.startDate = new Date(App.mainViewOptions.filters.startDate);

      if (this.periodGranularity) {
        $(`.date-range-selector input[value='${this.periodGranularity}']`).prop('checked', true);
      }

      this.fetchAndRender();
    }
  },

  fetchAndRender: function fetchAndRender() {
    const self = this;

    this.showLoader();
    this.model.setUrlFilters(this.getDateFilters());
    this.model.fetch({
      success: () => {
        self.renderReport();
      },
    });

    this.updateDateRangeSelectorTitle();
    this.updateUrl();
  },

  showLoader: function showLoader() {
    this.$('.report-content').loadmask();
  },

  hideLoader: function hideLoader() {
    this.$('.report-content').unloadmask();
  },

  updateDateRangeSelectorTitle: function updateDateRangeSelectorTitle() {
    const dateRange = this.getDateRange();

    let dateRangeTitle =
      Wahanda.lang.reports.salesReports.dateRange.current[this.periodGranularity];
    dateRangeTitle = dateRangeTitle.replace(
      '{{from}}',
      Wahanda.Date.formatToDefaultDate(dateRange.from),
    );
    dateRangeTitle = dateRangeTitle.replace(
      '{{to}}',
      Wahanda.Date.formatToDefaultDate(dateRange.to),
    );
    this.$dateRangeSelectorCurrentValue.text(dateRangeTitle);
  },

  getDateRange: function getDateRange() {
    const startDate = this.startDate;
    let endDate;

    switch (this.periodGranularity) {
      case 'day':
        endDate = startDate;
        break;
      case 'week':
        endDate = Wahanda.Date.addDaysToDate(startDate, 6);
        break;
      case 'month':
        endDate = new Date(startDate);
        // Months won't wrap as the startDate is 1st of month.
        endDate.setMonth(endDate.getMonth());
        endDate.setDate(Wahanda.Date.getDaysInMonth(endDate));
        break;
      default:
        endDate = this.yesterday;
    }

    // Cap end date to yesterday.
    endDate = Wahanda.Date.min(endDate, this.yesterday);

    return {
      from: startDate,
      to: endDate,
    };
  },

  getDateFilters: function getDateFilters() {
    const dateRange = this.getDateRange();
    const filters = {
      'start-date': Wahanda.Date.toApiString(dateRange.from),
      'end-date': Wahanda.Date.toApiString(dateRange.to),
    };

    return filters;
  },

  getCurrentTime: function getCurrentTime() {
    const currentTime = new Date();
    return `${Wahanda.Date.formatToApiFullDate(currentTime).date} ${
      Wahanda.Date.formatToApiFullDate(currentTime).time
    }`;
  },

  displayRange: function displayRange() {
    const dateRange = this.getDateRange();

    switch (this.periodGranularity) {
      case 'day':
        this.model.set('period', Wahanda.lang.date.weekdays[dateRange.from.getDay()]);
        this.model.set('dates', Wahanda.Date.formatToApiFullDate(dateRange.from).date);
        break;
      case 'week': {
        this.model.set(
          'period',
          `${Wahanda.Date.formatToDefaultDate(dateRange.from)} - ${Wahanda.Date.formatToDefaultDate(
            dateRange.to,
          )}`,
        );
        const days = Wahanda.Text.pluralizeNumber(
          Wahanda.Date.getDayDifference(dateRange.to, dateRange.from) + 1,
          [
            Wahanda.lang.reports.salesReports.day.toLowerCase(),
            Wahanda.lang.reports.salesReports.days.toLowerCase(),
          ],
        );

        this.model.set('dates', days);
        break;
      }
      case 'month':
        this.model.set('period', Wahanda.lang.date.months[dateRange.from.getMonth()]);
        this.model.set(
          'dates',
          `${Wahanda.Date.formatToDefaultDate(dateRange.from)} - ${Wahanda.Date.formatToDefaultDate(
            dateRange.to,
          )}`,
        );
        break;
      default:
        break;
    }
  },

  updateUrl: function updateUrl() {
    App.mainRouter.navigate(this.getStateHash());
  },

  getStateHash: function getStateHash() {
    return `${App.config.currentVenueHash()}/sales/${
      this.periodGranularity
    }/${Wahanda.Date.toApiString(this.startDate)}`;
  },

  enrichData: function enrichData() {
    this.model.set('showTopServices', false);
    this.model.set('showServiceGroups', false);
    this.model.set('showEmployees', false);
    this.model.set('showChannels', false);

    this.displayRange();
    this.model.set('venueName', App.config.get('venue').name);
    this.model.set('timeStamp', this.getCurrentTime());
    if (this.model.get('employees').length > 0) {
      this.model.set('showEmployees', true);
    }

    if (this.model.get('serviceGroups').length > 0) {
      this.model.set('showServiceGroups', true);
    }

    if (this.model.get('topServices').length > 0) {
      this.model.set('showTopServices', true);
      if (this.model.get('topServices').length === 5) {
        this.model.set('fiveServices', true);
      }
    }

    if (this.model.get('channels').length > 0) {
      this.model.set('showChannels', true);
      this.enrichChannels();
    }

    this.formatPrices([this.model.get('total')]);
    this.formatPrices(this.model.get('employees'));
    this.formatPrices(this.model.get('serviceGroups'));
    this.formatPrices(this.model.get('topServices'));
    this.formatPrices(this.model.get('channels'));
  },

  enrichChannels: function enrichChannels() {
    _.each(this.model.get('channels'), (channel) => {
      const langChannel = Wahanda.lang.reports.salesReports.channels[channel.label];

      if (langChannel) {
        channel.label = langChannel.displayName;
      }
    });
  },

  formatPrices: function formatPrices(collection) {
    const self = this;
    _.each(collection, (item) => {
      item.basic = self.formatOptionalPrice(item.basic);
      item.additional = self.formatOptionalPrice(item.additional, true);
      item.ttv = self.formatOptionalPrice(item.ttv);
    });
    return collection;
  },

  formatOptionalPrice: function formatOptionalPrice(price, additionalServiceCheck) {
    if (price) {
      if (price.amount !== 0) {
        if (additionalServiceCheck) {
          this.model.set('showAdditionalServices', true);
        }
        return Wahanda.Currency.format(price.amount);
      }
      return Wahanda.lang.reports.salesReports.zeroValue;
    }
    return price;
  },

  render: function render() {
    if (!Wahanda.Permissions.viewReports()) {
      return;
    }
    // Hide all opgrade overlays, etc.
    $('.upgrade-overlay').removeClass('sales').hide();
    $('#sales').show();

    this.fetchAndRender();
  },

  renderReport: function renderReport() {
    this.enrichData();
    const renderedTemplate = Wahanda.Template.renderTemplate(
      'sales-reports-template',
      this.model.attributes,
    );
    $('#sales .report-content').html(renderedTemplate);
    this.hideLoader();
  },
});

BackboneEx.Mixin.extendView(App.Views.Reports.SalesReport, BackboneEx.Mixin.View.Datepicker);
