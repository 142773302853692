import React from 'react';
import style from './style.scss';

interface Props {
  icon: any;
  seriesName: string;
  value: string | number;
}

export class TooltipLine extends React.PureComponent<Props> {
  public render() {
    const { icon, value } = this.props;
    return (
      <div className={style.tooltipLine}>
        {icon}
        <span className={style.text}>
          {this.seriesNameFormated} <strong className={style.value}>{value}</strong>
        </span>
      </div>
    );
  }

  private get seriesNameFormated() {
    const { seriesName } = this.props;
    return `${seriesName}:`;
  }
}
