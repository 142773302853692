/*
 * There is a small chance that a sum of these variables could produce a duplicate key.
 * Because of that we stringify the key at first.
 *
 * We generate a unique key because the pricing input is not controlled so we need to
 * forcefully re-render it when the sku object changes.
 */
const mapIds = ({ offerId, id }) => `${offerId}-${id}`;

export const generateKey = ({ subSkus }, isPackagePricingCustom) =>
  `${isPackagePricingCustom}${subSkus.map(mapIds).join(';')}`;

export const findRepeatingSubSkuIdsInAllSkus = (skus) => {
  const skusLength = skus.length;
  const subSkuIdMap = {};

  skus.forEach((sku) => {
    sku.subSkus.forEach((subSku) => {
      if (subSku.id) {
        subSkuIdMap[subSku.id] = (subSkuIdMap[subSku.id] || 0) + 1;
      }
    });
  });

  return Object.keys(subSkuIdMap).reduce((skuList, skuId) => {
    const repeatCount = subSkuIdMap[skuId];

    if (repeatCount === skusLength) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      skuList.push(parseInt(skuId, 10));
    }
    return skuList;
  }, []);
};
