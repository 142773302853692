import React from 'react';
import Wahanda from 'common/wahanda';
import ReactDialog from 'components/common/react-dialog';
import TypeCard from './TypeCard/container';
import typeList from './promoTypes';
import style from './style.scss';

const lang = Wahanda.lang.marketing.campaignSelection.promotionalOffers.typeSelect;
interface IPromotionTypeSelectProps extends React.HTMLAttributes<Element> {
  onClose: (...args: any[]) => any;
}

export default class PromotionTypeSelect extends React.Component<IPromotionTypeSelectProps, {}> {
  render() {
    return (
      <ReactDialog
        dataTest="email-campaign-promotion-type-modal"
        title={lang.title}
        onClose={this.props.onClose}
        noContentPadding
      >
        <div className={style.dialogContent}>
          {typeList.map((type) => (
            <TypeCard key={type.id} {...type} />
          ))}
        </div>
      </ReactDialog>
    );
  }
}
