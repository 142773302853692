import React, { SyntheticEvent, useRef, useState } from 'react';
import ReactDialog from 'src/components/common/react-dialog';
import style from './ImageEditDialog.scss';
import { Button } from 'components/common/Button';
import { Delete } from 'components/common/Icon';
import { PHOTO_CONFIG } from './ImageEditDialog.config';
import classNames from 'classnames';

interface Jcrop {
  setSelect: ([x1, y1, x2, y2]: [x1: number, y1: number, x2: number, y2: number]) => void;
}

type Cropping = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

export interface Props {
  src: string;
  cropping?: Cropping;
  onSave: (cropping: Cropping) => void;
  onRemove: () => void;
  onClose: () => void;
}

const STATUS = {
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
};

const IMAGE_EDIT_CROP_ELEMENT = 'image-edit-crop';

export const ImageEditDialog = ({
  src,
  cropping = {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  },
  onSave,
  onRemove,
  onClose,
}: Props) => {
  const ref = useRef<HTMLImageElement>(null);
  const [status, setStatus] = useState(STATUS.LOADING);
  const [selection, setSelection] = useState<Cropping>({
    x1: cropping.x1,
    y1: cropping.y1,
    x2: cropping.x2,
    y2: cropping.y2,
  });

  const isLoading = status === STATUS.LOADING;
  const isError = status === STATUS.ERROR;
  const isLoaded = status === STATUS.LOADED;

  const onClickRemove = () => {
    onRemove();
    onClose();
  };

  const onClickSave = () => {
    onSave(selection);
    onClose();
  };

  const onLoadImage = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    setStatus(STATUS.LOADED);
    initialiseCropping(event);
  };

  const onErrorImage = () => {
    setStatus(STATUS.ERROR);
  };

  const initialiseCropping = ({
    currentTarget: { naturalWidth, naturalHeight },
  }: SyntheticEvent<HTMLImageElement, Event>) => {
    $(`#${IMAGE_EDIT_CROP_ELEMENT}`).Jcrop(
      {
        aspectRatio: PHOTO_CONFIG.ASPECT_RATIO,
        minSize: [
          Math.min(PHOTO_CONFIG.MIN_WIDTH, naturalWidth),
          Math.min(PHOTO_CONFIG.MIN_HEIGHT, naturalHeight),
        ],
        onSelect: ({ x, y, x2, y2 }) => setSelection({ x1: x, y1: y, x2, y2 }),
        onChange: ({ x, y, x2, y2 }) => setSelection({ x1: x, y1: y, x2, y2 }),
        trueSize: [naturalWidth, naturalHeight],
        allowSelect: false, // Prevents deselection by clicking outside of existing selection
      },
      function (this: Jcrop) {
        this.setSelect([selection.x1, selection.y1, selection.x2, selection.y2]);
      },
    );
  };

  const onClickRetryLoad = () => {
    const img = ref.current;
    if (img) {
      setStatus(STATUS.LOADING);
      img.src = src;
    }
  };

  return (
    <ReactDialog
      dataTest="image-edit-dialog"
      title="Edit photo"
      width={700}
      onClose={onClose}
      noContentTopBorders
    >
      <div className={style.content}>
        <div
          className={classNames(style.imageWrapper, {
            [style.isLoading]: isLoading,
          })}
        >
          <div id={IMAGE_EDIT_CROP_ELEMENT}>
            <img
              className={classNames(style.image, {
                [style.isError]: isError,
                [style.isLoaded]: isLoaded,
              })}
              ref={ref}
              src={src}
              onLoad={onLoadImage}
              onError={onErrorImage}
            />
          </div>
          {isError && (
            <div className={style.imageError}>
              <span>Failed to load the image</span>
              <Button onClick={onClickRetryLoad} label="Try again" variant="secondary" />
            </div>
          )}
        </div>
        <div className={style.actions}>
          <Button
            onClick={onClickRemove}
            icon={<Delete size="small" />}
            variant="secondary"
            colour="alert"
          />
          <Button
            dataTest="image-edit-dialog-save-button"
            onClick={onClickSave}
            label="Save"
            variant="primary"
            disabled={!isLoaded}
          />
        </div>
      </div>
    </ReactDialog>
  );
};
