import React from 'react';
import { TransactionReportAnalytics } from 'common/analytics';
import App from 'common/backbone-app';
import { UpgradeWithPromoGraphic } from './UpgradeWithPromoGraphic';
import { TransactionsTabContent } from './transactions/TransactionsTabContent';

export class TransactionsTab extends React.PureComponent<{}, {}> {
  componentDidMount() {
    TransactionReportAnalytics.trackPageView();
  }

  renderContent() {
    /*
     ** This validation is depricated. It's no longer valid
     ** it should be removed with the POS reports section.
     */
    if (!App.config.get('venue').pointOfSaleEnabled) {
      return <UpgradeWithPromoGraphic />;
    }
    // @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'TransactionsTabContent' does not... Remove this comment to see the full error message
    return <TransactionsTabContent />;
  }

  render() {
    return (
      <div id="transactions" className="section-main">
        {this.renderContent()}
      </div>
    );
  }
}
