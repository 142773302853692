import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import Rating from 'react-components/Rating';
import style from './style.scss';

const MAX_CHARS = 275;
const SHOW_MORE = Wahanda.lang.shared.showMore;
const SHOW_LESS = Wahanda.lang.shared.showLess;
interface IReviewRowProps extends React.HTMLAttributes<Element> {
  rating: number;
  reviewer: {
    anonymous: boolean;
    name?: string;
  };
  content?: string;
  createdAt: string;
  employeeNames: string[];
  response?: {
    ownerName: string;
    content: string;
  };
  onClick?: (...args: any[]) => any;
  inline?: boolean;
  onResize?: (...args: any[]) => any;
  length?: any;
  join?: any;
}
type ReviewRowState = {
  expanded?: boolean;
};
class ReviewRow extends React.Component<IReviewRowProps, ReviewRowState> {
  static lang = Wahanda.lang.dashboard.reviews;

  static defaultProps = {
    onResize: () => null,
  };

  state = {};

  onShowMoreClick = (event) => {
    event.stopPropagation();
    this.setState({ expanded: true }, () => {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onResize();
    });
  };

  onShowLessClick = (event) => {
    event.stopPropagation();
    this.setState({ expanded: false }, () => {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onResize();
    });
  };

  listEmployees() {
    const employees = this.props.employeeNames.join(', ');
    return (
      <span className={style.employees}>
        {ReviewRow.lang.employeesLabel} {employees}
      </span>
    );
  }

  showReply() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ownerName' does not exist on type '{ own... Remove this comment to see the full error message
    const { ownerName, content } = this.props.response;
    return (
      <div data-hj-suppress className={style.reply}>
        <h3>{Wahanda.Template.render(ReviewRow.lang.reviewReplied, { ownerName })}</h3>
        <p>{content}</p>
      </div>
    );
  }

  hasContent() {
    return !!this.props.content;
  }

  hasRespondLabel() {
    if (this.hasContent()) {
      const { response, inline } = this.props;
      return !response && inline;
    }
    return false;
  }

  hasEmployees() {
    return this.props.employeeNames.length > 0;
  }

  isExpanded() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'expanded' does not exist on type '{}'.
    return !!this.state.expanded;
  }

  isLong() {
    const { content } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return content.trim().length > MAX_CHARS;
  }

  renderShowLess() {
    return (
      <span className={style.show} onClick={this.onShowLessClick}>
        {SHOW_LESS}
      </span>
    );
  }

  renderShowMore() {
    return (
      <span className={style.show} onClick={this.onShowMoreClick}>
        {SHOW_MORE}
      </span>
    );
  }

  renderContent() {
    const { content } = this.props;
    if (this.isLong()) {
      if (this.isExpanded()) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        const review = content.trim();
        return (
          <p className={style.review}>
            {review}&nbsp;{this.renderShowLess()}
          </p>
        );
      }
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const review = content.trim().slice(0, MAX_CHARS);
      return (
        <p className={style.review}>
          {review}&hellip;&nbsp;{this.renderShowMore()}
        </p>
      );
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const review = content.trim();
    return <p className={style.review}>{review}</p>;
  }

  render() {
    const { rating, reviewer, response, createdAt, onClick, inline } = this.props;
    const reviewerName = reviewer.anonymous ? ReviewRow.lang.anonymousReviewer : reviewer.name;
    const classes = classnames(style.reviewRow, inline && style.reviewRowInline);
    const hasContent = this.hasContent();
    const hasEmployees = this.hasEmployees();
    const hasRespondLabel = this.hasRespondLabel();
    let content;
    if (hasContent) {
      content = this.renderContent();
    }
    let employees;
    if (hasEmployees) {
      employees = this.listEmployees();
    }
    let respondLabel;
    if (hasRespondLabel) {
      respondLabel = <span className={style.respondText}>{ReviewRow.lang.reply.respond}</span>;
    }
    return (
      <div className={classes} onClick={onClick}>
        <div className={style.reviewRowHeader}>
          <Rating rating={rating} size="small" />
          <h2>{reviewerName}</h2>
        </div>
        {content}
        <p className={style.details}>
          <span className={style.date}>{moment(createdAt).formatDateDefault()}</span>
          {employees}
          {respondLabel}
        </p>
        {response && this.showReply()}
      </div>
    );
  }
}

export default ReviewRow;
