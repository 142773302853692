import { ACTIONS, trackEvent } from 'common/analytics';

const CATEGORY = 'corona_report';

export const trackOpen = () => {
  trackEvent(CATEGORY, ACTIONS.VIEW, 'open');
};

export const trackDownloadClick = () => {
  trackEvent(CATEGORY, ACTIONS.CLICK, 'download');
};
