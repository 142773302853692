import React from 'react';
import classnames from 'classnames';

import style from './style.scss';

interface Props {
  className?: string;
}

export class TableHeader extends React.PureComponent<Props> {
  public render() {
    const { children, className, ...rest } = this.props;
    return (
      <thead className={classnames(style.head, className)} {...rest}>
        {children}
      </thead>
    );
  }
}
