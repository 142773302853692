import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ClientsImportInProgress as Content } from './ClientsImportInProgress';

import { clientsImportReset } from '../actions';

const mapStateToProps = ({ clients }) => ({
  value: clients.clientsImport.importStatus.percentage,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ clientsImportReset }, dispatch),
});

export const ClientsImportInProgress = connect(mapStateToProps, mapDispatchToProps)(Content);
