import {
  GET_DISCOUNT_RULE_REQUEST,
  GET_DISCOUNT_RULE_RECEIVE,
  GET_DISCOUNT_RULE_FAILURE,
  PUT_DISCOUNT_RULE_REQUEST,
  PUT_DISCOUNT_RULE_RECEIVE,
  PUT_DISCOUNT_RULE_FAILURE,
  ADD_DISCOUNT_RULE_REQUEST,
  ADD_DISCOUNT_RULE_RECEIVE,
  ADD_DISCOUNT_RULE_FAILURE,
  REMOVE_DISCOUNT_RULE_REQUEST,
  REMOVE_DISCOUNT_RULE_RECEIVE,
  REMOVE_DISCOUNT_RULE_FAILURE,
  REVERT_DISCOUNT_RULE,
  GET_VENUE_DETAILS_REQUEST,
  GET_VENUE_DETAILS_RECEIVE,
  GET_VENUE_DETAILS_FAILURE,
  PRESENT_DISCOUNT_RULE_DELETE_CONFIRM,
  DISCARD_DISCOUNT_RULE_DELETE_CONFIRM,
  INITIAL_STATE_MENU_DISCOUNT,
} from 'reduxStore/actionsConst';

export const requestDiscountRuleAction = (venueId) => ({
  type: GET_DISCOUNT_RULE_REQUEST,
  venueId,
});

export const receiveDiscountRuleAction = (data) => ({
  type: GET_DISCOUNT_RULE_RECEIVE,
  data,
});

export const requestDiscountRuleFailureAction = (ex) => ({
  type: GET_DISCOUNT_RULE_FAILURE,
  ex,
});

export const updateDiscountRuleAction = (venueId, discountId, payload) => ({
  type: PUT_DISCOUNT_RULE_REQUEST,
  venueId,
  discountId,
  payload,
});

export const updateDiscountRuleSuccessAction = (data, values) => ({
  type: PUT_DISCOUNT_RULE_RECEIVE,
  data,
  values,
});

export const updateDiscountRuleFailureAction = (ex, values) => ({
  type: PUT_DISCOUNT_RULE_FAILURE,
  ex,
  values,
});

export const createDiscountRuleAction = (venueId, payload) => ({
  type: ADD_DISCOUNT_RULE_REQUEST,
  venueId,
  payload,
});

export const createDiscountRuleSuccessAction = (data, values) => ({
  type: ADD_DISCOUNT_RULE_RECEIVE,
  data,
  values,
});

export const createDiscountRuleFailureAction = (ex, values) => ({
  type: ADD_DISCOUNT_RULE_FAILURE,
  ex,
  values,
});

export const removeDiscountRuleAction = (venueId, discountId) => ({
  type: REMOVE_DISCOUNT_RULE_REQUEST,
  venueId,
  discountId,
});

export const removeDiscountRuleSuccessAction = (data, values) => ({
  type: REMOVE_DISCOUNT_RULE_RECEIVE,
  data,
  values,
});

export const removeDiscountRuleFailureAction = (ex, values) => ({
  type: REMOVE_DISCOUNT_RULE_FAILURE,
  ex,
  values,
});

export const revertDiscountRuleAction = (id) => ({
  type: REVERT_DISCOUNT_RULE,
  id,
});

export const requestVenueDetailsAction = (venueId) => ({
  type: GET_VENUE_DETAILS_REQUEST,
  venueId,
});

export const receiveVenueDetailsAction = (data) => ({
  type: GET_VENUE_DETAILS_RECEIVE,
  data,
});

export const requestVenueDetailsFailureAction = (ex) => ({
  type: GET_VENUE_DETAILS_FAILURE,
  ex,
});

export const presentDiscountRuleDeleteConfirmAction = () => ({
  type: PRESENT_DISCOUNT_RULE_DELETE_CONFIRM,
});

export const discardDiscountRuleDeleteConfirmAction = () => ({
  type: DISCARD_DISCOUNT_RULE_DELETE_CONFIRM,
});

export const initialStatePopulation = (data) => ({
  type: INITIAL_STATE_MENU_DISCOUNT,
  data,
});
