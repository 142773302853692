import { action, payload } from 'ts-action';

import {
  ErrorPayload,
  GroupPayload,
  ServicePayload,
  ServiceEditPayload,
  ServiceEmployee,
  MenuGroupsPayload,
} from './types';

/*
 * Requesting menu group template
 */
export const getMenuGroupTemplate = action('[Add Services] Request menu template');

export const getMenuGroupTemplateSuccess = action(
  '[Add Services] Request menu template Success',
  payload<GroupPayload[]>(),
);

export const getMenuGroupTemplateFailure = action('[Add Services] Request menu template Failure');

export const resetSelectedService = action('[Add Services] Reset');

export const addService = action('[Add Services] Add service', payload<ServiceEditPayload>());

export const removeService = action('[Add Services] Remove service', payload<ServiceEditPayload>());

export const updateSelectedService = action(
  '[Add Services] Update Service',
  payload<ServicePayload>(),
);

export const setErrorType = action('[Add Services] Error handle', payload<ErrorPayload>());

/*
 * Set menu group services
 */
export const setMenuGroupServices = action(
  '[Add Services] Request Import',
  payload<MenuGroupsPayload>(),
);

export const setMenuGroupServicesSuccess = action('[Add Services] Request Import Success');

export const setMenuGroupServicesFailure = action('[Add Services] Request Import Failure');

/*
 * Set menu group employees
 */
export const setEmployeesCollection = action(
  '[Add Services] Employees Collection loaded',
  payload<ServiceEmployee[]>(),
);

export const selectAllEmployees = action(
  '[Add Services] Select All Employees',
  payload<ServiceEditPayload>(),
);

export const deselectAllEmployees = action(
  '[Add Services] Deselect All Employees',
  payload<ServiceEditPayload>(),
);
