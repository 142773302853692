(function($) {
	/**
	 * Selects the time of the given SELECT element. If the value isn't found, it is added.
	 *
     * @param String time HH:mm
	 */
	$.fn.selectOrAddTime = function(time) {
        var found = false;
		var $options = this.find('option');

        // Try to find the exact value first
        $options.each(function() {
			if (this.value == time) {
                found = true;
                this.selected = true;
                return false;
            }
		});

        if (found) {
            return this;
        }

        // Not found. Add the value and select it.
        var added = false;
        var $option = $('<option>', { value: time, 'data-custom-added': 'true' });
        $option.text(time);

        var intTime = Wahanda.Time.timeToMinutes(time);
		$options.each(function() {
			if (this.value && Wahanda.Time.timeToMinutes(this.value) > intTime) {
                added = true;
                $option.insertBefore(this);
                $option.prop('selected', true);
                return false;
            }
		});
		
		if (!added) {
			this.append($option);
		}

		return this;
	};
}(jQuery));
