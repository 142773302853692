import React, { Component, Fragment } from 'react';
import { DialogConfirmation } from 'components/common/dialog/DialogConfirmation';
import { ClientsAnalytics } from '../tracking';

interface IClientsBulkDeleteProps extends React.HTMLAttributes<Element> {
  loading?: boolean;
  deletedCount?: number;
  onDeleteStart: (...args: any[]) => any;
  onDeleteComplete: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
  actions: {
    clientsBulkDeleteAction: (...args: any[]) => any;
    clientsResetAction: (...args: any[]) => any;
  };
  clientsBulkDeleteAction?: any;
  clientsResetAction?: any;
}
type ClientsBulkDeleteState = {
  confirmDialogVisible?: boolean;
};

export default class ClientsBulkDelete extends Component<
  IClientsBulkDeleteProps,
  ClientsBulkDeleteState
> {
  static defaultProps = {
    loading: false,
    deletedCount: null,
    onDeleteStart: () => {},
    onDeleteComplete: () => {},
  } as any;

  state = { confirmDialogVisible: true };

  componentDidUpdate() {
    if (!this.props.loading) {
      this.props.onDeleteComplete();
    }
  }

  componentWillUnmount() {
    this.props.actions.clientsResetAction();
  }

  deleteClients = () => {
    ClientsAnalytics.trackBulkDeleteClick();
    this.props.onDeleteStart();
    this.props.actions.clientsBulkDeleteAction();
    this.setState({ confirmDialogVisible: false });
  };

  renderDeleteConfirmDialog = () => (
    <DialogConfirmation
      title="Delete clients"
      text="All clients without any appointments will be deleted."
      buttonTitle="Delete"
      onButtonClick={this.deleteClients}
      onClose={this.props.onClose}
      buttonColor="red"
    />
  );

  renderDeleteSuccessInformationDialog = () => (
    <DialogConfirmation
      dataTest="clients-bulk-delete-confirmation-modal"
      title="Clients deleted"
      text={`${this.props.deletedCount} clients have been deleted.`}
      buttonTitle="Close"
      onButtonClick={this.props.onClose}
      onClose={this.props.onClose}
    />
  );

  render() {
    return (
      <Fragment>
        {this.state.confirmDialogVisible && this.renderDeleteConfirmDialog()}
        {this.props.deletedCount != null && this.renderDeleteSuccessInformationDialog()}
      </Fragment>
    );
  }
}
