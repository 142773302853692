/* global BackboneEx */
/* global _ */
/* eslint no-underscore-dangle: 0 */
import { xhr } from 'common/xhr';
import APPOINTMENT_CHANNEL_CODE from 'common/constants/appointmentChannelCodes';

/**
 * Order model.
 */
App.Models.Order = BackboneEx.Model.Base.extend({
  defaults: {
    supplierId: null,
  },
  initialize: function initialize() {},

  url: function url() {
    const supplierId = App.config.get('venue').supplierId;
    return App.Api.wsUrl(`/supplier/${supplierId}/order/${this.id}.json`);
  },

  getPackageGroup: function getPackageGroup() {
    return this._getGroup('package');
  },

  _getGroup: function _getGroup() {
    const self = this;
    const action = 'isPackageType';
    let group;

    _.any(this.get('appointmentGroups'), (groupData) => {
      const tmpGroup = new App.Collections.AppointmentGroup(null, groupData);
      if (tmpGroup[action]()) {
        tmpGroup.add(self.getAppointmentsForGroup(tmpGroup.id));
        group = tmpGroup;
        return true;
      }
      return false;
    });

    return group;
  },

  getAppointmentsForGroup: function getAppointmentsForGroup(groupId) {
    const list = [];
    _.filter(this.get('appointments'), (apptData) => {
      if (_.indexOf(apptData.appointmentGroupIds, groupId) === -1) {
        return;
      }
      list.push(new App.Models.Appointment(apptData));
    });
    return list;
  },

  isAppointment: function isAppointment() {
    return this.get('appointments') !== null && this.get('appointments').length > 0;
  },

  isEvoucher: function isEvoucher() {
    return this.get('evouchers') !== null;
  },

  isDated: function isDated() {
    return this.get('datedBookings') !== null;
  },

  getAppointments: function getAppointments() {
    return _.map(this.get('appointments'), (apptData) => {
      return new App.Models.Appointment(apptData);
    });
  },

  getCustomerId: function getCustomerId() {
    const apptList = this.get('appointments');
    if (apptList && apptList.length > 0) {
      return apptList[0].venueCustomerId;
    }
    return null;
  },

  getBookingActorName: function getBookingActorName() {
    const channelCode = this.get('channelCode');
    if (channelCode && channelCode.startsWith(APPOINTMENT_CHANNEL_CODE.BOOKED_BY_GOOGLE)) {
      return Wahanda.lang.calendar.appointments.channels.google;
    }

    if (App.Models.Appointment.CHANNEL_SUPCUS === this.get('bookingActor')) {
      return Wahanda.lang.calendar.appointments.channels.widget;
    }
    return Wahanda.lang.calendar.appointments.channels.customer;
  },

  /**
   * Confirm the whole Order and it's underlying items.
   *
   * @return Promise
   */
  confirm: function confirm() {
    const url = App.Api.wsSupplierUrl(`/order/${this.id}/confirm.json`);
    return xhr
      .doJQueryAjax({
        url: url,
        contentType: 'application/json',
        type: 'post',
      })
      .done(() => {
        App.trigger(Wahanda.Event.ORDER_CONFIRMED);
      });
  },

  getFirstName: function getFirstName() {
    if (this.isAppointment()) {
      const appt = this.get('appointments')[0];

      return appt.recipientFirstName || appt.consumerFirstName;
    } else if (this.isDated()) {
      return this.get('datedBookings')[0].consumerFirstName;
    }
    return null;
  },

  canShowCustomerContacts: function canShowCustomerContacts() {
    if (this.isAppointment()) {
      return _.all(this.getAppointments(), (appt) => appt.canShowCustomerContacts());
    } else if (this.isDated()) {
      return _.all(this.get('datedBookings'), (datedItem) => {
        const date = Wahanda.Date.createDate(datedItem.itemDate, datedItem.checkinTime || '00:00');

        return Wahanda.canShowCustomerContactsForItem(date, datedItem.bookingStatus === 'CN');
      });
    }
    return true;
  },
});
