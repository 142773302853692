import React from 'react';
import Wahanda from 'common/wahanda';
import { useComponentDidMount } from 'utilities/hooks';
import { mapTimeSlots } from 'components/shared/DurationSelector/helpers';
import { CurrencyInput } from 'components/common/CurrencyInput';
import { Input } from 'components/common/Input';
import { CurrencyContext, CurrencyContextProps } from 'components/common/DataFormat';
import { Select } from 'components/common/Select';
import { DurationSelector } from 'components/shared/DurationSelector';

import { ServiceSku } from '../store/types';
import { priceValidator } from '../utils/helpers';
import { AddServicesModalAnalytics } from '../tracking';
import { ServiceRowRemove } from '../ServiceRowRemove';

import style from './ServiceRowSku.scss';

interface Props {
  // Used for cypress tests
  index?: number;
  onApplicationTimeChange: (param) => void;
  onDurationTimeChange: (param) => void;
  onFinishingTimeChange: (param) => void;
  onPriceChange: (event: Event) => void;
  onSkuNameChange: (param: string, track: boolean) => void;
  onSkuRemove: () => void;
  processingTime?: number;
  showName?: boolean;
  shouldAutoFocusName?: boolean;
  showSkuRemove?: boolean;
  showSkuNameError?: boolean;
  sku: ServiceSku;
  // Redux
  currencyContext: CurrencyContextProps;
}

export const ServiceRowSku = ({
  currencyContext,
  index,
  onApplicationTimeChange,
  onDurationTimeChange,
  onFinishingTimeChange,
  onPriceChange,
  onSkuNameChange,
  onSkuRemove,
  processingTime,
  sku,
  shouldAutoFocusName,
  showName,
  showSkuRemove,
  showSkuNameError,
}: Props) => {
  const componentDidMount = useComponentDidMount();
  // TODO: useState should be removed with implementing Form
  const [priceError, setPriceError] = React.useState(false);
  const [skuError, setSkuError] = React.useState(false);
  const options = { minMinutes: 5 };
  const durations = Wahanda.Time.getDurationValues(
    null,
    Wahanda.Time.getTimeDurationTemplate(),
    true,
    options,
  );
  const values = mapTimeSlots(durations);
  const tracking = {
    trackDurationInputClick: AddServicesModalAnalytics.trackDurationInputClick,
    trackDialogCloseClick: AddServicesModalAnalytics.trackDialogCloseClick,
    trackSelectChange: AddServicesModalAnalytics.trackDurationSelectChange,
  };

  const handlePriceChange = (value) => {
    if (!componentDidMount) {
      return;
    }
    const shouldShowError = !priceValidator(value);
    setPriceError(shouldShowError);
    onPriceChange(value);
  };
  const handleNameOnBlur = (val) => {
    handleSkuNameChange(val, false);
  };

  const handleSkuNameChange = (value, triggerTrack = true) => {
    const shouldShowError = !value;
    setSkuError(shouldShowError);
    onSkuNameChange(value.trim(''), triggerTrack);
  };

  const skuNameErrorMessage =
    !showSkuNameError && !sku.name.length && Wahanda.lang.validate.defaults.required;

  return (
    <div className={style.container}>
      {showName && (
        <div className={style.name}>
          <Input
            onBlur={handleNameOnBlur}
            dataTest={`add-services-sku-name-input-${index}`}
            autoFocus={shouldAutoFocusName}
            name={`sku-name-${sku.skuId}`}
            maxLength={100}
            value={sku.name}
            onChange={handleSkuNameChange}
            placeholder={Wahanda.lang.menu.offer.sku.captionPlaceholder}
            hasDebounce
            hasError={skuError || showSkuNameError}
            errorMessage={skuNameErrorMessage}
          />
        </div>
      )}
      <div className={style.application}>
        <div className={style.duration}>
          {processingTime ? (
            <DurationSelector
              applicationTime={sku.durationTimeMins}
              finishingTime={sku.finishingTimeMins}
              canUserEditAppointment
              processingTime={processingTime}
              processingTimeDisabled
              onApplicationTimeChange={onApplicationTimeChange}
              onFinishingTimeChange={onFinishingTimeChange}
              isFinishingWithNull
              tracking={tracking}
            />
          ) : (
            <Select
              name={`${sku.skuId}`}
              options={values}
              dataTest="service-row-duration-select"
              selectedOption={sku.durationTimeMins}
              onSelect={onDurationTimeChange}
            />
          )}
        </div>
        <div className={style.price}>
          <CurrencyContext.Provider value={currencyContext}>
            <CurrencyInput
              name={`${sku.skuId}`}
              value={sku.fullPriceAmount || 0}
              hasError={priceError}
              onChange={handlePriceChange}
              dataTest="service-row-price-input"
            />
          </CurrencyContext.Provider>
        </div>
        {showSkuRemove && (
          <div className={style.removeButton}>
            <ServiceRowRemove dataTest={`add-services-sku-remove-${index}`} onClick={onSkuRemove} />
          </div>
        )}
      </div>
    </div>
  );
};
