import React from 'react';
import { hashMap, get, hash } from 'mori';
import classnames from 'classnames';

const DIALOG_BUTTON_CLASSES = 'dialog2--button';
const DIALOG_BUTTON_RIGHT_CLASS = 'dialog2--button-right';
const DIALOG_BUTTON_LEFT_CLASS = 'dialog2--button-left';
const DIALOG_BUTTON_CHOICE = 'button-choices--button';
const DIALOG_BUTTON_CHOICE_CANCEL = 'button-choices-cancel';
const DIALOG_BUTTON_DESTRUCTIVE_COLOR_CLASS = 'dialog2--button-destructive';
const DIALOG_BUTTON_SUCCESS_COLOR_CLASS = 'dialog2--button-green';
const DIALOG_BUTTON_DISABLED_CLASS = 'dialog2--button-disabled';

function buildButton(title, onClick, classes, id = null) {
  return hashMap('title', title, 'onClick', onClick, 'classes', classes, 'id', id);
}

export function choiceButton(title, onClick) {
  return buildButton(title, onClick, [DIALOG_BUTTON_CLASSES, DIALOG_BUTTON_CHOICE]);
}

export function choiceCancelButton(title, onClick) {
  return buildButton(title, onClick, [
    DIALOG_BUTTON_CLASSES,
    DIALOG_BUTTON_CHOICE,
    DIALOG_BUTTON_CHOICE_CANCEL,
  ]);
}

export function simpleButton(title, onClick) {
  return buildButton(title, onClick, [DIALOG_BUTTON_CLASSES, DIALOG_BUTTON_LEFT_CLASS]);
}

export function destructiveButton(title, onClick, isEnabled = true) {
  return buildButton(title, isEnabled ? onClick : () => {}, [
    DIALOG_BUTTON_CLASSES,
    DIALOG_BUTTON_RIGHT_CLASS,
    isEnabled ? DIALOG_BUTTON_DESTRUCTIVE_COLOR_CLASS : DIALOG_BUTTON_DISABLED_CLASS,
  ]);
}

export function successButton(title, onClick) {
  return buildButton(title, onClick, [
    DIALOG_BUTTON_CLASSES,
    DIALOG_BUTTON_RIGHT_CLASS,
    DIALOG_BUTTON_SUCCESS_COLOR_CLASS,
  ]);
}

export function toComponent(buttonHash) {
  if (buttonHash === null) {
    return null;
  }
  const classes = classnames('dialog2--button pink', get(buttonHash, 'classes'));
  return (
    <div
      className={classes}
      id={get(buttonHash, 'id')}
      onClick={get(buttonHash, 'onClick')}
      key={hash(buttonHash)}
    >
      <span className="title">{get(buttonHash, 'title')}</span>
    </div>
  );
}
