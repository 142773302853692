import React from 'react';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import classnames from 'classnames';
import { useQuery } from 'react-query';
import apiUrl from 'common/api-url';
import * as api from 'reduxStore/apiService';
import Dialog from 'components/common/dialog/FullHeightDialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import { Spinner } from 'components/common/Spinner';
import { LoginAnalytics } from '../tracking';
import style from './TermsOfServiceModal.scss';

interface Props {
  onClose: () => void;
}

interface TermsResponse {
  terms: string;
}

export const TermsOfServiceModal: React.FC<Props> = ({ onClose }) => {
  const terms = React.useRef<HTMLDivElement>(null);
  const { isLoading, data } = useQuery<TermsResponse>(
    'terms',
    () =>
      api.get<TermsResponse>(
        apiUrl('TERMS_OF_SERVICE_BY_CHANNEL', {
          channelCode: App.domainChannelCode(),
        }),
      ),
    {
      refetchOnWindowFocus: false,
    },
  );
  const containerClass = classnames(style.container, {
    [style.alignCenter]: isLoading,
  });
  React.useEffect(() => {
    LoginAnalytics.trackTermsView();
  }, []);
  React.useLayoutEffect(() => {
    if (data) {
      const links: ArrayLike<HTMLAnchorElement> | undefined = terms?.current?.getElementsByTagName(
        'a',
      );
      if (links) {
        Array.from(links).forEach((link) => {
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener noreferrer');
        });
      }
    }
  }, [data]);
  return (
    <Dialog
      width="90%"
      classes={{ [style.dialog]: true }}
      maxHeight={window.innerHeight}
      minHeightAsMax
      dialogContentMaxWidth={900}
      title={Wahanda.lang.login.dialogTitle}
      onClose={onClose}
      noContentPadding
      keepTopPositionWhenResizing
    >
      <div className={containerClass}>
        {isLoading && <Spinner dark />}
        {data && (
          <div className={style.wrapper}>
            <div ref={terms} dangerouslySetInnerHTML={{ __html: data.terms }} />
          </div>
        )}
      </div>
      <DialogFooter>
        <DialogFooterButton
          dataTest="terms-modal-button-cancel"
          title={Wahanda.lang.shared.buttons.cancel}
          type="secondary"
          onClick={onClose}
        />
      </DialogFooter>
    </Dialog>
  );
};
