import React from 'react';
import classnames from 'classnames';
import { Close } from 'components/common/Icon';

import style from './ServiceRowRemove.scss';

interface Props {
  className?: string;
  dataTest: string;
  onClick: (param) => void;
}

export const ServiceRowRemove = ({ className, dataTest, onClick }: Props) => {
  const containerClass = classnames(style.icon, className);

  return (
    <div
      role="button"
      tabIndex={-1}
      data-test={dataTest}
      className={containerClass}
      onClick={onClick}
    >
      <Close size="tiny" />
    </div>
  );
};
