/* global Backbone */
/* eslint-disable func-names */
import { EMPLOYEE_WORKING_HOURS_TOGGLE_FEATURE } from 'components/calendar/EmployeeWorkingHoursToggle';

App.Views.Calendar.HideNonWorkingToggle = Backbone.View.extend({
  initializer: null,

  initialize: function () {
    if (!App.isFeatureSupported(EMPLOYEE_WORKING_HOURS_TOGGLE_FEATURE)) {
      return;
    }

    this.initializer = App.ES6.Initializers.EmployeeWorkingHoursToggle({
      node: this.options.el[0],
    });

    this.toggleButtonVisibility(this.options.options.initialCalendarType, this.options.isDayView());

    App.on(Wahanda.Event.CALENDAR_TYPE_CHANGE, (newType) => {
      this.toggleButtonVisibility(newType, this.options.isDayView());
    }).on(Wahanda.Event.CALENDAR_DISPLAY_TYPE_CHANGE, ({ type }) => {
      this.toggleButtonVisibility('appointment', type === 'day');
    });
  },

  toggleButtonVisibility: function (calendarType, isDayView) {
    if (calendarType === 'appointment' && isDayView) {
      this.initializer.render();
    } else {
      this.initializer.destroy();
    }
  },
});
