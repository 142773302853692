import React from 'react';

type LinkedTransactionProps = {
  linkText?: string;
  transactionId?: string;
  onLinkClick?: (...args: any[]) => any;
};
const LinkedTransaction: React.SFC<LinkedTransactionProps> = ({
  linkText,
  transactionId,
  onLinkClick,
}) => (
  <div className="linked-transaction">
    {linkText} #&nbsp;
    <span className="transaction-link" onClick={onLinkClick}>
      {transactionId}
    </span>
  </div>
);

export default LinkedTransaction;
