import DISPLAY from 'components/reports/newSalesReports/displayStateEnum';

const defaultState = { displayState: DISPLAY.WAITING };

export default function generateReducer(request, receive, failure) {
  return (state = defaultState, action) => {
    switch (action.type) {
      case receive:
        return {
          ...state,
          displayState: DISPLAY.REPORT,
          reportData: action.data,
        };
      case request:
        return { ...state, displayState: DISPLAY.WAITING };
      case failure:
        return { ...state, displayState: DISPLAY.ERROR, errorMsg: action.ex };
      default:
        return state;
    }
  };
}
