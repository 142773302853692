import React from 'react';
import Wahanda from 'common/wahanda';
import { generatePath } from 'react-router-dom';
import { VENUE_OPENING_CAMPAIGN } from 'components/marketing/EmailCampaign/CampaignList';
import PATHS from 'components/marketing/Router/paths';
import Dialog from 'components/common/react-dialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import { Analytics } from '../../tracking';
import style from './VenueOpeningConfirmation.scss';

const LANG = Wahanda.lang.shared.covidBanner;

export interface Props {
  hideCovidBannerDialogs: () => void;
  covidUnlimitOperationRequest: () => void;
  canAccessMarketingTools: boolean;
  justSuccessfullyReopened: boolean;
  unlimitLoading: boolean;
}

export const VenueOpeningConfirmation: React.SFC<Props> = ({
  hideCovidBannerDialogs,
  covidUnlimitOperationRequest,
  canAccessMarketingTools,
  justSuccessfullyReopened,
  unlimitLoading,
}) => {
  const handleClose = () => {
    hideCovidBannerDialogs();
  };
  const handleSubmit = () => {
    Analytics.trackReopenClicked();
    covidUnlimitOperationRequest();
  };
  const handleViewCampaign = () => {
    Analytics.trackViewCampaignFromDialogClicked();
    hideCovidBannerDialogs();
    window.location.assign(
      Wahanda.Url.getFullUrl(
        'marketing',
        generatePath(PATHS.CAMPAIGN_PREVIEW_DEFAULT, {
          templateId: VENUE_OPENING_CAMPAIGN,
        }),
      ),
    );
  };
  const dialogContentBeforeReopen = () => (
    <div className={style.dialogContent}>
      <div className={style.header}>{LANG.doYouWantToReopen}</div>
      <div
        className={style.description}
        dangerouslySetInnerHTML={{
          __html: LANG.reopenDescription,
        }}
      />
    </div>
  );
  const dialogContentAfterReopen = () => (
    <div className={style.dialogContent}>
      <div className={style.header}>{LANG.greatNews}</div>
      {canAccessMarketingTools && (
        <div className={style.description}>{LANG.letYourClientsKnow}</div>
      )}
    </div>
  );
  const dialogFooter = () => (
    <DialogFooter>
      <DialogFooterButton
        dataTest="cancel-venue-open-confirm-dialog"
        type="secondary"
        title={Wahanda.lang.shared.close}
        onClick={handleClose}
      />
      {justSuccessfullyReopened ? (
        canAccessMarketingTools && (
          <DialogFooterButton
            dataTest="view-campaign-button"
            title={LANG.viewCampaign}
            onClick={handleViewCampaign}
          />
        )
      ) : (
        <DialogFooterButton
          dataTest="submit-venue-open-request"
          title={LANG.reopenConfirm}
          onClick={handleSubmit}
          disabled={unlimitLoading}
          isLoading={unlimitLoading}
        />
      )}
    </DialogFooter>
  );
  return (
    <div className={style.dialogWrapper}>
      <Dialog
        dataTest="venue-opening-confirmation-modal"
        title={justSuccessfullyReopened ? LANG.confirmDialogTitle2 : LANG.pleaseConfirm}
        width={520}
        onClose={handleClose}
        restrictHeight
        noContentPadding
      >
        {justSuccessfullyReopened ? dialogContentAfterReopen() : dialogContentBeforeReopen()}
        {dialogFooter()}
      </Dialog>
    </div>
  );
};
