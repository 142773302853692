import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Shipment extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-shipment', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M6,21 C4.34314575,21 3,19.6568542 3,18 C3,16.3431458 4.34314575,15 6,15 C7.65685425,15 9,16.3431458 9,18 C9,19.6568542 7.65685425,21 6,21 L6,21 Z M6,19 C6.55228475,19 7,18.5522847 7,18 C7,17.4477153 6.55228475,17 6,17 C5.44771525,17 5,17.4477153 5,18 C5,18.5522847 5.44771525,19 6,19 Z M18,15 C19.6568542,15 21,16.3431458 21,18 C21,19.6568542 19.6568542,21 18,21 C16.3431458,21 15,19.6568542 15,18 C15,16.3431458 16.3431458,15 18,15 Z M13,17 L13,19 L8,19 L8,17 L13,17 Z M18,17 C17.4477153,17 17,17.4477153 17,18 C17,18.5522847 17.4477153,19 18,19 C18.5522847,19 19,18.5522847 19,18 C19,17.4477153 18.5522847,17 18,17 Z M16.276,3 C16.9775776,3 17.6569545,3.2459553 18.196,3.695 L22.921,7.632 C23.6049014,8.20214249 24,9.04661608 24,9.937 L24,18 L22,18 L22,9.937 C21.9998887,9.64026736 21.8680019,9.35890888 21.64,9.169 L16.916,5.232 C16.7363643,5.08219831 16.5099007,5.00010525 16.276,5 L13,5 L13,11 C13,12.1045695 12.1045695,13 11,13 L4,13 L4,11 L11,11 L11,5 L3,5 C2.44771525,5 2,5.44771525 2,6 L2,18 L0,18 L0,6 C0,4.34314575 1.34314575,3 3,3 L16.276,3 Z M16.13,7 C16.3683445,7 16.5988582,7.0850944 16.78,7.24 L19.65,9.7 C19.8721378,9.89000268 20,10.1676882 20,10.46 L20,12.5 C20,12.7761424 19.7761424,13 19.5,13 L15.5,13 C15.2238576,13 15,12.7761424 15,12.5 L15,7.5 C15,7.22385763 15.2238576,7 15.5,7 L16.13,7 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Shipment);
