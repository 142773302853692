import React, { Component } from 'react';
import uuid from 'uuid';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import { Close } from 'components/common/Icon';
import UnderlinedInput from 'components/common/UnderlinedInput';
import _ from 'common/underscore';
import SkuList from 'components/menu/OfferTreatmentPricingAndEmployees/Pricing/SkuList/container';
import { DELETE_BUTTON_STATE } from 'components/menu/OfferTreatmentPricingAndEmployees/Pricing/SkuList';
import style from './style.scss';

const LANG = Wahanda.lang.menu.offer.sku;
const notUniqueNameError = Wahanda.lang.menu.offer.errors.skuNameNotUnique;
interface ITwoDimensionalPricingProps extends React.HTMLAttributes<Element> {
  skus: any[];
  onDelete: (...args: any[]) => any;
  canDelete: boolean;
  readonly: boolean;
  className?: string;
  useProcessingTime: boolean;
  onSkuNameChange?: any;
  skuProps?: any;
}
class TwoDimensionalPricing extends Component<ITwoDimensionalPricingProps, {}> {
  getValues = () => {
    const skus = this.skuRefs.map((ref) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
      const name = ref.name.getValues();
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'skusListRef' does not exist on type 'nev... Remove this comment to see the full error message
      return ref.skusListRef
        .getWrappedInstance()
        .getValues()
        .map((sku) => Object.assign({}, { ...sku }, { name }));
    });
    return _.flatten(skus);
  };

  skuRefs = [];

  renderSku = (grouped2dSkus) => {
    const { onDelete, canDelete, className, readonly, useProcessingTime, ...skuProps } = this.props;
    const { name, groupingId } = grouped2dSkus[0];
    const skuRefs = {
      skusListRef: null,
      name: null,
    };
    const classes = classnames(className);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ skusListRef: null; name: null;... Remove this comment to see the full error message
    this.skuRefs.push(skuRefs);
    return (
      <div className={classes} key={groupingId}>
        <div className={style.header}>
          <UnderlinedInput
            ref={(nameRef) => {
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'UnderlinedInput' is not assignable to type '... Remove this comment to see the full error message
              skuRefs.name = nameRef;
            }}
            // @ts-expect-error ts-migrate(2769) FIXME: Property 'required' does not exist on type 'Intrin... Remove this comment to see the full error message
            required
            placeholder={LANG.captionPlaceholder}
            name={uuid.v4()}
            className={style.input}
            onChange={this.props.onSkuNameChange}
            defaultValue={name}
            data-rule-unique-group="offer-sku-name"
            data-msg-unique-group={notUniqueNameError}
            disabled={readonly}
          />
          {canDelete && (
            <Button
              size="small"
              colour="default"
              variant="hollow"
              onClick={() => onDelete(grouped2dSkus)}
              icon={<Close size="small" />}
            />
          )}
        </div>

        <div className={style.twoDimensionalPricingRows}>
          {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ skus: any; ref: (skus: Component<Pick<ISku... Remove this comment to see the full error message */}
          <SkuList
            {...skuProps}
            skus={grouped2dSkus}
            ref={(skus) => {
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'Component<Pick<ISkuListProps, "skus" | "onDe... Remove this comment to see the full error message
              skuRefs.skusListRef = skus;
            }}
            useProcessingTime={useProcessingTime}
            nameEditable={false}
            readonly={readonly}
            onDelete={onDelete}
            deleteButton={DELETE_BUTTON_STATE.HIDDEN}
            doNotShowPricingLevelHeader
          />
        </div>
      </div>
    );
  };

  render() {
    const { skus } = this.props;
    this.skuRefs = [];
    return <div>{skus.map(this.renderSku)}</div>;
  }
}

export default TwoDimensionalPricing;
