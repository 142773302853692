import { xhr } from 'common/xhr';

(function () {
  var EVOUCHER_ACTIVE = 'A';
  var EVOUCHER_REDEEMED = 'R';
  var EVOUCHER_EXPIRED = 'X';
  var EVOUCHER_VOIDED = 'V';
  var EVOUCHER_RESERVED = 'S';

  App.Views.Calendar.VoucherRedemption = BackboneEx.View.Dialog.ValidatingForm.extend(
    {
      events: {
        'click .button-cancel': 'close',
        'click .redeem-action': 'onRedeemClick',
        'click .redeem-another, .clear-search': 'renderSearch',
        'click .evoucher-booking-id': 'showBooking',
        'click .a-create-appointment': 'delegateToAppointment',
      },

      templateId: 'calendar-voucher-redemption',

      options: {
        width: 500,
        title: Wahanda.lang.calendar.voucherRedemption.title,
        convertToAppointment: false,
        calEvent: null,
      },

      confirmClosingIfChangesExist: false,

      postInitialize: function () {
        this.currentEvoucher = null;
      },

      onDialogOpen: function () {
        BackboneEx.View.Dialog.ValidatingForm.prototype.onDialogOpen.call(this);
        App.trigger(Wahanda.Event.EVOUCHER_REDEMPTION_OPEN, this);
      },

      initForm: function () {
        if (this.options.initialVoucher) {
          this.currentEvoucher = this.options.initialVoucher;
          delete this.options.initialVoucher;
          this.renderResults();
          // Set voucher code in the voucher search field
          this.$('input[name=voucher-reference]').val(this.currentEvoucher.referenceCode);
        } else {
          if (this.options.maskedReference) {
            this.$('input[name=voucher-reference]').val(this.options.maskedReference);
            this.options.maskedReference = null;
          }
          this.toggleMaskedInput(true);
        }
        this.$('.login-link').attr('href', App.loginRedirectUrl());
      },

      setValidation: function () {
        var validations = Wahanda.Validate.getValidations('defaults');
        var self = this;
        var $input = this.$('input[name=voucher-reference]');
        validations.submitHandler = function () {
          if ($input.val() !== '_______-______-__') {
            self.searchForEvoucher();
          }
        };
        this.$('form').validate(validations);
      },

      renderSearch: function () {
        this.currentEvoucher = null;
        var $input = this.$('input[name=voucher-reference]');
        // Clear the input value
        $input.val('');

        this.$('.reference-container').removeClass('has-query');
        this.$('.voucher-info').addClass('hidden');
        this.$('.dialog-actions').find('button').addClass('hidden');
        this.showNote('start');
        this.enableSearching();
        this.toggleMaskedInput(true);
        $input.focus();
      },

      renderResults: function () {
        this.hideNotes();
        this.disableSearching();
        this.$('.voucher-info').removeClass('hidden');
        this.$('.dialog-actions').find('button').addClass('hidden');
        this.$('.reference-container').addClass('has-query');
        this.$('.message-wrapper').addClass('hidden');
        this.toggleMaskedInput(false);

        var renderStandard = true;
        var applicableToCurrentVenue =
          _.where(this.currentEvoucher.applicableVenues, {
            id: App.getVenueId(),
          }).length > 0;
        if (
          this.options.convertToAppointment &&
          !applicableToCurrentVenue &&
          EVOUCHER_ACTIVE === this.currentEvoucher.evoucherStatusCode
        ) {
          // Do not render standard actions, b/c voucher not redeemable in the current venue
          renderStandard = false;
          this.$('button.redeem-another').wShow();
          this.setStatusClassName(EVOUCHER_ACTIVE);
          this.$('.evoucher-status').text(
            Wahanda.lang.calendar.voucherRedemption.types.notApplicableForVenue,
          );
        }
        if (renderStandard) {
          this.renderStandardStatus();
        }

        this.$('.title').text(this.currentEvoucher.title);
        this.setStatusClassName(this.currentEvoucher.evoucherStatusCode);
        this.$('.evoucher-recipient').text(this.currentEvoucher.recipientName);
        var expiryDate = Wahanda.Date.createDate(this.currentEvoucher.expiryDate, '00:00');
        this.$('.evoucher-expiry')
          .text(Wahanda.Date.formatToDefaultFullDate(expiryDate).date)
          .toggleClass('expired', this.currentEvoucher.evoucherStatusCode === EVOUCHER_EXPIRED);

        this.$('.evoucher-price').text(Wahanda.Currency.format(this.currentEvoucher.price.amount));
        this.$('.evoucher-order-ref').text(this.currentEvoucher.orderReference);
        // Show the booking link only if the eVoucher is applicable to the current venue
        this.$('.b-booking-link').toggle(applicableToCurrentVenue);
        if (applicableToCurrentVenue) {
          this.$('.evoucher-booking-id')
            .text(String(this.currentEvoucher.bookingId))
            .attr('href', 'javascript:;');
        }

        if (!this.options.convertToAppointment) {
          this._renderResultsVenue();
        }
      },

      renderStandardStatus: function () {
        switch (this.currentEvoucher.evoucherStatusCode) {
          case EVOUCHER_ACTIVE:
            this.$(
              'button.' +
                (this.options.convertToAppointment ? 'a-create-appointment' : 'redeem-action'),
            ).removeClass('hidden');
          case EVOUCHER_EXPIRED: // eslint-disable-line no-fallthrough
          case EVOUCHER_VOIDED:
          case EVOUCHER_RESERVED:
            this.$('.evoucher-status').text(
              this.getEvoucherTextStatus(this.currentEvoucher.evoucherStatusCode),
            );
            if (EVOUCHER_ACTIVE !== this.currentEvoucher.evoucherStatusCode) {
              this.$('button.redeem-another').removeClass('hidden');
            }
            break;

          case EVOUCHER_REDEEMED:
            // Make it bullet proof when redemtion date is not returned by the API
            var text = Wahanda.lang.calendar.voucherRedemption.errors.alreadyRedeemed;

            if (this.currentEvoucher.redemptionDate) {
              var date = Wahanda.Date.formatToDefaultFullDate(
                Wahanda.Date.parse(this.currentEvoucher.redemptionDate),
              );
              text =
                Wahanda.lang.calendar.voucherRedemption.errors.alreadyRedeemed +
                ' ' +
                date.date +
                ', ' +
                date.time;
            }
            this.$('.evoucher-status').text(text);
            this.$('button.redeem-another').removeClass('hidden');
            break;
        }
      },

      hideNotes: function () {
        this.$('.voucher-note').addClass('hidden');
      },

      showNote: function (note) {
        this.$('.voucher-note')
          .addClass('hidden')
          .filter('.voucher-' + note)
          .removeClass('hidden')
          .find('.vertically-centered')
          .center({ horizontal: false });
      },

      getEvoucherTextStatus: function (code) {
        switch (code) {
          case EVOUCHER_ACTIVE:
            return Wahanda.lang.calendar.voucherRedemption.types.active;
          case EVOUCHER_REDEEMED:
            return Wahanda.lang.calendar.voucherRedemption.types.redeemed;
          case EVOUCHER_VOIDED:
            return Wahanda.lang.calendar.voucherRedemption.types.voided;
          case EVOUCHER_EXPIRED:
            return Wahanda.lang.calendar.voucherRedemption.types.expired;
          case EVOUCHER_RESERVED:
            return Wahanda.lang.calendar.voucherRedemption.types.reserved;
        }
      },

      setStatusClassName: function (code) {
        var className = 'status-wrapper ';
        switch (code) {
          case EVOUCHER_ACTIVE:
            className += 'status-active';
            break;
          case EVOUCHER_REDEEMED:
            className += 'status-redeemed-before';
            break;
          case EVOUCHER_RESERVED:
            className += 'status-redeemed-before';
            break;
          case EVOUCHER_VOIDED:
            className += 'status-expired';
            break;
          case EVOUCHER_EXPIRED:
            className += 'status-expired';
            break;
        }
        this.$('.status-wrapper').attr('class', className);
      },

      _renderResultsVenue: function () {
        this.$('.venue-wrapper').removeClass('hidden');
        var container = this.$('.evoucher-venue-container').empty();

        if (
          this.currentEvoucher.evoucherStatusCode !== EVOUCHER_ACTIVE ||
          !this.hasVenuesToRedeemEvoucher()
        ) {
          this.$('.venue-wrapper').addClass('hidden');
          return;
        }

        var self = this;
        var applicableVenues = [];
        if (this.currentEvoucher.applicableVenues) {
          applicableVenues = _.filter(this.currentEvoucher.applicableVenues, function (venue) {
            return null != self._findVenue(venue.id);
          });
        }

        if (null == applicableVenues || applicableVenues.length === 0) {
          // Show nothing
          this.$('.venue-wrapper').addClass('hidden');
        } else if (applicableVenues.length === 1) {
          container.append(
            $('<input />', {
              type: 'hidden',
              name: 'venueId',
              value: applicableVenues[0].id,
            }),
          );

          if (App.config.get('venues').length > 1) {
            container.append(
              $('<span class="evoucher-venue"></span>').text(applicableVenues[0].name),
            );
          } else {
            this.$('.venue-wrapper').addClass('hidden');
          }
        } else {
          // Show venues select
          var self = this;
          var currentVenueId = App.config.get('venue').id;
          var options = [];
          _.each(applicableVenues, function (venueData) {
            options.push({
              title: venueData.name,
              value: venueData.id,
              selected: venueData.id === currentVenueId,
            });
          });

          container.append(
            $('<select>', {
              name: 'venueId',
              id: 'voucher-redemption-venue-id',
            }).html(
              Wahanda.Template.renderTemplate('form-select-items', {
                options: options,
              }),
            ),
          );
        }
      },

      hasVenuesToRedeemEvoucher: function () {
        // If the venue list is empty, it means that the user can redeem the voucher w/o specifying any venue.
        var hasVenue =
          this.currentEvoucher.applicableVenues == null ||
          this.currentEvoucher.applicableVenues.length === 0;
        var self = this;

        if (
          this.currentEvoucher.applicableVenues &&
          this.currentEvoucher.applicableVenues.length > 0
        ) {
          _.each(this.currentEvoucher.applicableVenues, function (venue) {
            if (self._findVenue(venue.id)) {
              hasVenue = true;
              return false;
            }
          });
        }

        return hasVenue;
      },

      _findVenue: function (venueId) {
        return _.find(App.config.get('venues'), function (v) {
          return v.id === venueId;
        });
      },

      searchForEvoucher: function () {
        var $referenceField = this.$('input[name=voucher-reference]');
        var reference = $referenceField.val();
        var self = this;
        var searchButton = this.$('.find-action');
        this.$('.reference-container').addClass('has-query');
        this.showNote('searching');
        this.toggleMaskedInput(false);

        this.disableSearching();
        this.disableForm();
        searchButton.action('doing');
        xhr
          .doJQueryAjax({
            url: App.Api.wsUrl(
              '/supplier/' + App.config.get('venue').supplierId + '/evoucher/find.json',
            ),
            data: { reference: reference },
            dataType: 'json',
            skipErrorHandling: true,
          })
          .success(function (data) {
            App.trigger(Wahanda.Event.EVOUCHER_FOUND, data.reference);

            searchButton.action();
            self.enableForm();

            data.referenceCode = reference;

            self.currentEvoucher = data;
            self.renderResults();
          })
          .error(function (jqXHR) {
            searchButton.action();
            self.enableForm();
            self.showNote('start');
            searchButton.enableFormElements();
            self.enableSearching();
            self.toggleMaskedInput(true);

            switch (jqXHR.status) {
              case 403:
                self.showNote('belongs');
                $referenceField.focus();
                break;
              case 404:
                self.showNote('not-found');
                $referenceField.focus();
                break;
            }
          });
      },

      onRedeemClick: function () {
        if (this.hasVenuesToRedeemEvoucher()) {
          this.redeemEvoucher();
        } else {
          this.$('.redeem-action').noticeTip(
            Wahanda.lang.calendar.voucherRedemption.errors.noVenuesToRedeemAt,
          );
        }
      },

      redeemEvoucher: function () {
        var venueId = this.$(':input[name=venueId]').val();
        var supplierId = App.config.get('venue').supplierId;
        var url = App.Api.wsUrl(
          '/supplier/' +
            supplierId +
            '/evoucher/' +
            this.currentEvoucher.referenceCode +
            '/redeem.json',
        );
        var self = this;
        var $button = this.$('.redeem-action');

        $button.action('doing');

        this.disableForm();

        xhr.doJQueryAjax({
          url: url,
          type: 'post',
          data: JSON.stringify({ venueId: venueId }),
          success: function (data) {
            App.trigger(Wahanda.Event.EVOUCHER_REDEEMED, self.currentEvoucher.reference);

            self.renderRedemptionSuccessState(data);
            self.enableForm();
          },
          error: function () {
            self.enableForm();
            $button.action();
            $button.errorTip(Wahanda.lang.calendar.voucherRedemption.info.errors.notRedeemed);
          },
          contentType: 'application/json',
        });
      },

      renderRedemptionSuccessState: function (data) {
        var $button = this.$('.redeem-action, .a-create-appointment');

        $button.action().addClass('hidden');
        this.$('.evoucher-venue-row').addClass('hidden');
        this.$('.status-wrapper').attr('class', 'status-wrapper status-redeemed');

        var statusTextKey = this.options.convertToAppointment ? 'reserved' : 'redeemed';
        this.$('.evoucher-status').text(
          Wahanda.lang.calendar.voucherRedemption.info.titles[statusTextKey],
        );
        this.$('.v-message-title').text(
          Wahanda.lang.calendar.voucherRedemption.voucherRedemptionSuccess[statusTextKey],
        );

        this.$('.venue-wrapper').addClass('hidden');
        this.$('.message-wrapper').removeClass('hidden');

        var paymentString = null;
        if (data && Wahanda.Date.isValidApiDateString(data.expectedPaymentDate)) {
          var paymentDate = Wahanda.Date.createDate(data.expectedPaymentDate);
          // Wahanda.lang.calendar.voucherRedemption.
          if (Wahanda.Date.isPast(paymentDate, true)) {
            paymentString = Wahanda.lang.calendar.voucherRedemption.labels.alreadyPaid;
          } else {
            paymentString = Wahanda.lang.calendar.voucherRedemption.labels.expectedPaymentDate;
          }
          paymentString += ' ' + Wahanda.Date.formatToDefaultDate(paymentDate);
        }
        this.renderAttribute('payment-date', paymentString);

        if (this.options.convertToAppointment) {
          // Change "Cancel" to "Close"
          this.$('.a-cancel').text(Wahanda.lang.shared.close);
        } else {
          this.$('.redeem-another').removeClass('hidden');
        }
      },

      delegateToAppointment: function () {
        var modelOptions = {
          offerId: this.currentEvoucher.offerId,
          skus: [
            {
              skuId: this.currentEvoucher.skuId,
            },
          ],
        };
        var formOptions = {
          redeemEvoucher: true,
          evoucherReference: this.currentEvoucher.referenceCode,
          initialDuration: Wahanda.Time.durationToMinutes(
            this.currentEvoucher.duration,
            this.currentEvoucher.durationTypeCode,
          ),
          evoucherDialog: this,
          initialCustomerId: this.currentEvoucher.customer && this.currentEvoucher.customer.id,
        };
        this.options.appointmentCalendar.showAppointmentAddDialog(
          this.options.calEvent,
          modelOptions,
          formOptions,
        );
      },

      disableSearching: function () {
        this.$('.find-action').disableFormElements();
        this.$('input[name=voucher-reference]').prop('readonly', true);
      },

      enableSearching: function () {
        this.$('.find-action').enableFormElements();
        this.$('input[name=voucher-reference]').prop('readonly', false);
      },

      /**
       * Toggles masked input (___-____-__ thing) on the search field.
       *
       * @param boolean use Set (true) or remove (anything not-trueish) the masked input
       */
      toggleMaskedInput: function (use) {
        var input = this.$('input[name=voucher-reference]');
        input.unmask();
        if (use) {
          input.mask('9999999-999999-99');
        }
      },

      showBooking: function (bookingId) {
        var view = new App.Views.Dialog.Booking({
          updateUrl: false,
          model: new App.Models.Booking({
            id: this.currentEvoucher.bookingId,
            venueId: App.config.get('venue').id,
          }),
        });
        view.render();
        view.open();
      },
    },
    {
      open: function (maybeOptions) {
        var view = new App.Views.Calendar.VoucherRedemption(maybeOptions || {});

        view.render();
        view.open();
        return view;
      },
    },
  );
})();
