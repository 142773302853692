/* eslint-disable no-empty  */
import { isValidNumber, getNumberType, TYPE_MOBILE } from 'common/phonenumber';
import containsInvalidEmailAddressCharacters from '../../javascripts-es6/utilities/invalidEmailAddressCharacterFilter';
import { xhr } from 'common/xhr';
import { evaluate } from 'mathjs/lib/esm/number';

(function ($) {
  // Wahanda jQuery.Validate validators

  /**
   * Default validator options.
   */
  let defaults;

  Wahanda.Validate = {
    /**
     * Returns validator options.
     *
     * @param string name Validator name
     * @param Object options OPTINOAL Custom options
     * @return Object for $.validate()
     *
     * @throws Error if validator not found
     */
    getValidations: function (name, options) {
      const validator = this.Validators[name];
      if (!validator) {
        throw new Error(`Validator ${name} not found`);
      }
      return $.extend({}, defaults, validator, options || {});
    },
  };

  /**
   * Validator list.
   */
  Wahanda.Validate.Validators = {
    defaults: {},
    EmployeeCategory: {
      rules: {
        name: {
          required: true,
          minlength: 2,
          maxlength: 100,
        },
      },
      messages: {
        name: {
          required: Wahanda.lang.validate.name.required,
          minlength: Wahanda.lang.validate.name.minlength,
          maxlength: Wahanda.lang.validate.name.maxlength,
        },
      },
    },

    MenuGroup: {
      rules: {
        name: {
          required: true,
          minlength: 2,
          maxlength: 150,
        },
      },
      messages: {
        name: {
          required: Wahanda.lang.validate.name.required,
          minlength: Wahanda.lang.validate.name.minlength,
          maxlength: Wahanda.lang.validate.name.maxlength,
        },
      },
    },
  };

  /**
   * Error rendering callbacks. Can be used as `showErrors` option.
   */
  Wahanda.Validate.Renderers = {
    /**
     * Shows errors in tooltip.
     *
     * @param jQuery label
     * @param jQuery input
     */

    topTooltip: {
      errorTooltipOptions: {},
      showErrors: function (errorMap, errorList) {
        const tooltipOptions = {};
        tooltipOptions.position = {
          my: 'bottom center',
          at: 'top center',
          adjust: {
            x: -5,
          },
        };

        for (var i = 0; this.errorList[i]; i++) {
          const error = this.errorList[i];
          this.settings.highlight &&
            error.element &&
            this.settings.highlight.call(
              this,
              error.element,
              this.settings.errorClass,
              this.settings.validClass,
            );
          $(error.element).formErrorTip(error.message, tooltipOptions);
        }
        if (this.errorList.length) {
          this.toShow = this.toShow.add(this.containers);
        }
        if (this.settings.success) {
          for (var i = 0; this.successList[i]; i++) {
            this.showLabel(this.successList[i]);
          }
        }
        if (this.settings.unhighlight) {
          for (var i = 0, elements = this.validElements(); elements[i]; i++) {
            $(elements[i]).qtip('destroy');
            this.settings.unhighlight.call(
              this,
              elements[i],
              this.settings.errorClass,
              this.settings.validClass,
            );
          }
        }
        this.toHide = this.toHide.not(this.toShow);
        this.hideErrors();
      },
    },

    tooltip: {
      showErrors: function (errorMap, errorList) {
        for (var i = 0; this.errorList[i]; i++) {
          const error = this.errorList[i];
          this.settings.highlight &&
            error.element &&
            this.settings.highlight.call(
              this,
              error.element,
              this.settings.errorClass,
              this.settings.validClass,
            );
          $(error.element).formErrorTip(error.message);
        }
        if (this.errorList.length) {
          this.toShow = this.toShow.add(this.containers);
        }
        if (this.settings.success) {
          for (var i = 0; this.successList[i]; i++) {
            this.showLabel(this.successList[i]);
          }
        }
        if (this.settings.unhighlight) {
          for (var i = 0, elements = this.validElements(); elements[i]; i++) {
            $(elements[i]).qtip('destroy');
            this.settings.unhighlight.call(
              this,
              elements[i],
              this.settings.errorClass,
              this.settings.validClass,
            );
          }
        }
        this.toHide = this.toHide.not(this.toShow);
        this.hideErrors();
      },
    },

    offer2: {
      getRenderer: function (view) {
        const errorTemplate =
          '' +
          '<div class="form-errors">' +
          '{{#errors}}' +
          '<label for="{{target}}">{{message}}</label>' +
          '{{/errors}}' +
          '</div>';

        // The currently shown error list
        const currentErrors = {};

        return {
          // We will handle focusing ourselves.
          focusInvalid: false,

          showErrors: function (errorMap, errorList) {
            let redraw = false;
            let i;
            let elements;

            // Add new errors
            for (i = 0; this.errorList[i]; i++) {
              const error = this.errorList[i];
              if (this.settings.highlight && error.element) {
                this.settings.highlight.call(
                  this,
                  error.element,
                  this.settings.errorClass,
                  this.settings.validClass,
                );
                currentErrors[getId(error.element)] = error.message;
                redraw = true;
              }
            }
            // Remove old errors
            for (i = 0, elements = this.validElements(); elements[i]; i++) {
              this.settings.unhighlight.call(
                this,
                elements[i],
                this.settings.errorClass,
                this.settings.validClass,
              );
              if (currentErrors[getId(elements[i])]) {
                delete currentErrors[getId(elements[i])];
                redraw = true;
              }
            }
            // Check if package
            if (view.model.isServicePackage()) {
              const key = 'packageError';
              const obj = {};
              if (!view.model.validPackage()) {
                obj[key] = Wahanda.lang.menu.offer.errors.packageNoSkus;
                _.extend(currentErrors, obj);
                redraw = true;
              } else if (currentErrors[key]) {
                delete currentErrors[key];
                redraw = true;
              }
            }

            if (!redraw) {
              return;
            }

            if (!_.isEmpty(currentErrors)) {
              const strings = {};
              const errors = [];

              _.each(currentErrors, function (msg, id) {
                if (strings[msg]) {
                  // Render only unique errors
                  return;
                }
                strings[msg] = true;
                errors.push({
                  message: msg,
                  target: id,
                });
              });
              const html = Wahanda.Template.render(errorTemplate, {
                errors: errors,
              });
              view.showMessage('error', html);
            } else {
              view.removeMessage('error');
            }
            // The height has probably changed. Set the new max-width of scroll for main content.
            view.setTabContainerMaxHeight();
          },

          highlight: function (element, errorClass, validClass) {
            const $el = getErrorTarget(element);
            $el.addClass(errorClass).removeClass(validClass);
          },

          unhighlight: function (element, errorClass, validClass) {
            const $el = getErrorTarget(element);
            $el.addClass(validClass).removeClass(errorClass);
          },

          invalidHandler: function () {
            // Focus the element after a timeout so that the Chosen container won't close after opening
            // when the "click" event propagates to <body>.
            window.setTimeout(function () {
              view.focusInvalidField();
            }, 0);
          },
        };

        function getId(el) {
          return el.getAttribute('id') || el.getAttribute('name');
        }

        function getErrorTarget(element) {
          const $el = $(element);
          let $target = $el;
          if ($el.is('select.chosen-select')) {
            $target = $el.next('.chosen-container');
          } else if ($el.parent().is('[data-role=fieldcontain]')) {
            $target = $el.parent();
          }
          return $target;
        }
      },
    },
  };

  /**
   * Offer form error renderer.
   *
   * It detects SKU items and renders errors on them accordingly.
   */
  (function () {
    const skuItems = /^(discountPriceAmount\-not\-purchasable|upperAmount|discountPriceAmount)\[\d+\]$/;
    Wahanda.Validate.Renderers['offer-form'] = {
      showErrors: function (errorMap, errorList) {
        // Calling tooltip renderer on all errors.
        Wahanda.Validate.Renderers.tooltip.showErrors.apply(this, arguments);
        const errorUniques = [];
        for (let i = 0; this.errorList[i]; i++) {
          const error = this.errorList[i];
          if (isSkuItem(error.element)) {
            const cell = getInfoRowPriceCell(error.element)
              .addClass('error')
              .qtip({
                content: error.message,
                position: {
                  my: 'center left',
                  at: 'center right',
                },
                style: {
                  classes: 'ui-tooltip-error',
                },
              });
            errorUniques.push(cell.closest('tr').data('uniqueId'));
          }
        }

        // Remove qTips fromn valid rows
        const skuInfoRows = $(this.currentForm).find('.skus-list').find('tr.info');
        _.each(this.validElements(), function (element) {
          if (
            isSkuItem(element) &&
            _.indexOf(errorUniques, $(element).closest('tr').data('uniqueId')) === -1
          ) {
            getInfoRowPriceCell(element).removeClass('error').qtip('destroy');
          }
        });
      },
    };

    /**
     * @return boolean
     */
    function isSkuItem(element) {
      return skuItems.test(element.name);
    }
    /**
     * @return jQuery
     */
    function getInfoRowPriceCell(element) {
      const $el = $(element);
      const uniqueId = $el.closest('tr').data('uniqueId');
      return $el.closest('tbody').find(`tr.info[data-unique-id=${uniqueId}]`).find('.sku-price');
    }
  })();

  // Default values
  defaults = _.extend(
    {
      // Default submit handler. Does not let to submit the form.
      submitHandler: function () {
        return false;
      },
    },
    Wahanda.Validate.Renderers.tooltip,
  );

  // Custom validators
  $.validator.addMethod(
    /**
     * SKU upper amount must be > lower amount
     */
    'sku-range-sanity',
    function (value, element) {
      const $element = $(element);
      if ($element.closest('.range').hasClass('hidden')) {
        return true;
      }
      var value = $element.val();
      const lowerValue = $element.closest('td').find('input[name^=discountPriceAmount]').val();

      if (value === '' && lowerValue === '') {
        return true;
      }
      return parseFloat(value) > parseFloat(lowerValue);
    },
    Wahanda.lang.menu && Wahanda.lang.menu.offer
      ? Wahanda.lang.menu.offer.sku.errors.upperAmountMustBeLess
      : '',
  );

  $.validator.addMethod(
    /**
     * Reuires date to be in the given format
     */
    'date-format',
    function (value, element, dateFormat) {
      try {
        return value && !!$.datepicker.parseDate(dateFormat, value);
      } catch (e) {
        return false;
      }
    },
    Wahanda.lang.shared.errors.invalidDateFormat,
  );

  $.validator.addMethod(
    /**
     * Reuires date to be in the venue`s default format
     */
    'date-format-default',
    function (value, element) {
      if (this.optional(element) && value === '') {
        return true;
      }
      try {
        const dateFormat = App.config.get('jqueryDateFormat').defaultDate;
        return value && !!$.datepicker.parseDate(dateFormat, value);
      } catch (e) {
        return false;
      }
    },
    Wahanda.lang.shared.errors.invalidDateFormat,
  );

  $.validator.addMethod(
    /**
     * Checks that this date field is greater or equal to other field's value
     * It required the 'date-format' attribute to be on the given element too
     */
    'date-not-less',
    function (value, element, otherFieldSelector) {
      try {
        const $otherElement = $(otherFieldSelector);
        const dateFormat =
          element.getAttribute('date-format') || $(element).datepicker('option', 'dateFormat');

        const bigger = $.datepicker.parseDate(dateFormat, value);
        const smaller = $.datepicker.parseDate(dateFormat, $otherElement.val());

        return (
          Wahanda.Date.getMidnight(bigger).getTime() >= Wahanda.Date.getMidnight(smaller).getTime()
        );
      } catch (e) {}
      return false;
    },
    Wahanda.lang.shared.errors.dateMustBeGreater,
  );

  $.validator.addMethod(
    /**
     * Time blocks date & time checking.
     */
    'block-time-date-non-recurring',
    function (value, element) {
      try {
        const $fromDate = $('#timeblock-pdatefrom');
        const $fromTime = $('#timeblock-ptimefrom');
        const $toDate = $('#timeblock-pdateto');
        const $toTime = $('#timeblock-ptimeto');

        const startDate = $.datepicker.parseDate(
          App.config.get('jqueryDateFormat').defaultDate,
          $fromDate.val(),
        );
        const endDate = $.datepicker.parseDate(
          App.config.get('jqueryDateFormat').defaultDate,
          $toDate.val(),
        );

        const startTime = Wahanda.Time.splitHours($fromTime.val());
        const endTime = Wahanda.Time.splitHours($toTime.val());

        startDate.setHours(startTime.hours);
        startDate.setMinutes(startTime.minutes);
        startDate.setSeconds(0);

        endDate.setHours(endTime.hours);
        endDate.setMinutes(endTime.minutes);
        endDate.setSeconds(0);

        return startDate.getTime() < endDate.getTime();
      } catch (e) {}
      return false;
    },
    Wahanda.lang.shared.errors.timeAndDateMustBeGreater,
  );

  $.validator.addMethod(
    'data-max-length',
    (value, _element, maxValue) => (value.length ?? 0) <= maxValue,
    (maxValue, element) => {
      const key = $(element).attr('data-field-name') ?? null;
      return $.validator.format(
        Wahanda.lang.validate.field.maxlength,
        key ? Wahanda.lang.validate.field[key] : '',
        maxValue,
      );
    },
  );

  $.validator.addMethod(
    /**
     * Time blocks date & time checking.
     */
    'block-time-recurring-times',
    function (value, element) {
      try {
        const startTime = Wahanda.Time.timeToMinutes($('#timeblock-between-start').val());
        const endTime = Wahanda.Time.timeToMinutes($('#timeblock-between-end').val());
        return startTime < endTime;
      } catch (e) {}
      return false;
    },
    Wahanda.lang.shared.errors.timeMustBeGreater,
  );

  $.validator.addMethod(
    'time-range',
    function (value, element, otherFieldSelector) {
      const $otherElement = $(otherFieldSelector);
      const isOptional = this.optional(element);
      if (isOptional && value == '' && $otherElement.val() == '') {
        return true;
      }
      if (!isOptional && (value == '' || $otherElement.val() == '')) {
        return false;
      }
      try {
        const startTime = Wahanda.Time.timeToMinutes($otherElement.val());
        const endTime = Wahanda.Time.timeToMinutes(value);
        return startTime < endTime;
      } catch (e) {}
      return false;
    },
    Wahanda.lang.shared.errors.timeMustBeGreater,
  );

  $.validator.addMethod(
    'evoucher-code',
    function (value, element) {
      return (
        value == null ||
        value == '_______-______-__' ||
        value.match(/^\d{7}\-\d{6}\-\d{2}$/) != null
      );
    },
    Wahanda.lang.shared.errors.notEvoucherCodeFormat,
  );

  $.validator.addMethod(
    'evoucher-code-strict',
    function (value) {
      return value == null || value.match(/^\d{7}\-\d{6}\-\d{2}$/) != null;
    },
    Wahanda.lang.shared.errors.notEvoucherCodeFormat,
  );

  $.validator.addMethod(
    'phone-by-country-field',
    function (value, element, countryCodeField) {
      if (value === '' && this.optional(element)) {
        return true;
      }

      let countryCode;
      if (this.settings.$context) {
        countryCode = this.settings.$context.find(countryCodeField).val();
      } else {
        countryCode = $(countryCodeField).val();
      }
      return isValidNumber(value, countryCode);
    },
    Wahanda.lang.shared.errors.notValidPhoneNumber,
  );

  $.validator.addMethod(
    'phone-by-country',
    function (value, element) {
      if (value === '' && this.optional(element)) {
        return true;
      }
      if (Wahanda.Util.containsAlpha(value)) {
        return (
          App.isFeatureSupported('sms-display-name-txt') &&
          $(element).hasClass('alphanumeric-validation')
        );
      }
      return isValidNumber(value, App.config.get('venue').countryCode);
    },
    Wahanda.lang.shared.errors.notValidPhoneNumber,
  );

  $.validator.addMethod(
    'sms-display-name-length',
    function (value, element) {
      if (value === '' && this.optional(element)) {
        return true;
      }
      const stringLengthLimit = 11;
      const isAlphaNumericSupported = App.isFeatureSupported('sms-display-name-txt');
      const isAlphaNumeric = Wahanda.Util.isAlphaNumeric(value);
      const containsAlpha = Wahanda.Util.containsAlpha(value);

      if (
        isAlphaNumericSupported &&
        value.length > stringLengthLimit &&
        containsAlpha &&
        isAlphaNumeric
      ) {
        return false;
      }
      return true;
    },
    Wahanda.lang.shared.errors.notValidSmsDisplayNameLength,
  );

  $.validator.addMethod(
    'alphanumeric-validation',
    function (value, element) {
      if (value === '' && this.optional(element)) {
        return true;
      }
      const isAlphaNumericSupported = App.isFeatureSupported('sms-display-name-txt');
      const isAlphaNumeric = Wahanda.Util.isAlphaNumeric(value);

      if (isAlphaNumericSupported && !isAlphaNumeric) {
        return false;
      }
      return true;
    },
    Wahanda.lang.shared.errors.notValidAlphaNumeric,
  );

  /**
   * Mobile phone validator by country.
   *
   * finding if the number is mobile, and valid, also if US is country code
   * and valid phone number, return true.
   */
  $.validator.addMethod(
    'mobile-phone-by-country',
    function (value, element) {
      if (value === '' && this.optional(element)) {
        return true;
      }
      let result = false;
      try {
        const countryCode = App.config.get('venue').countryCode;
        if (isValidNumber(value, countryCode)) {
          result = countryCode === 'US' || getNumberType(value, countryCode) === TYPE_MOBILE;
        }
      } catch (e) {
        result = false;
      }
      return result;
    },
    Wahanda.lang.shared.errors.notValidMobilePhoneNumber,
  );

  $.validator.addMethod(
    'valid-domain',
    function (value, element) {
      const url = value.replace(/^https?:\/\//i, '').trim();

      if (/\s/.test(url)) {
        $.validator.messages['valid-domain'] = Wahanda.lang.shared.errors.domainNoSpaces;
        return false;
      }
      const domain = url.split('/')[0];

      if (
        !$.validator.methods['unique-domain'].call(
          this,
          domain,
          element,
          '/microsite-custom-domain-validation',
        )
      ) {
        $.validator.messages['valid-domain'] = Wahanda.lang.shared.errors.domainInvalid;
        return false;
      }

      return true;
    },
    $.validator.messages['valid-domain'],
  );

  $.validator.addMethod(
    'unique-domain',
    function (value, element, request) {
      let check = false;
      if (!value) {
        return true;
      }
      xhr.doJQueryAjax({
        url: App.Api.wsVenueUrl(request),
        type: 'get',
        dataType: 'json',
        contentType: 'application/json',
        data: { candidate: value },
        async: false,
        success: function (data) {
          if (data.status === 'INVALID') {
            $.validator.messages['unique-domain'] = Wahanda.lang.shared.errors.domainInvalid;
          } else if (data.status === 'UNAVAILABLE') {
            $.validator.messages['unique-domain'] = Wahanda.lang.shared.errors.domainInUse;
          }

          check = data.status === 'OK';
        },
        error: function () {
          check = false;
        },
      });
      return check;
    },
    $.validator.messages['unique-domain'],
  );
  /**
   * Allows only the given number of max. lines.
   */
  $.validator.addMethod(
    'max-lines',
    function (value, element, max) {
      const str = $.trim(String(value));
      const lineCount = (str.match(/\r\n|\n|\r/g) || []).length;
      return lineCount < max;
    },
    Wahanda.lang.shared.errors.tooManyTextLines,
  );

  /**
   * Allows only the given number of max. lines.
   *
   * Strips all empty multi-liners.
   */
  $.validator.addMethod(
    'max-text-lines',
    function (value, element, max) {
      const str = $.trim(String(value)).replace(/(\r\n|\n|\r){2,}/g, '\n');
      const lineCount = (str.match(/\n/g) || []).length;
      return lineCount < max;
    },
    Wahanda.lang.shared.errors.tooManyTextLines,
  );

  $.validator.addMethod(
    'more-than',
    function (value, element, lesserElement) {
      const less = $(lesserElement).val();
      return value === '' || less === '' || parseFloat(value) > parseFloat(less);
    },
    Wahanda.lang.shared.errors.moreThanValidator,
  );

  $.validator.addMethod(
    'more-or-equal-to',
    function (value, element, lesserElement) {
      const less = $(lesserElement).val();
      return value === '' || less === '' || parseFloat(value) >= parseFloat(less);
    },
    Wahanda.lang.shared.errors.moreThanValidator,
  );

  $.validator.addMethod(
    'account-no',
    function (value, element) {
      const allowedChars = 'a-z0-9' + ' ' + '-';
      const regexp = new RegExp(`[^${allowedChars}]`, 'i');
      return regexp.test(value) === false;
    },
    Wahanda.lang.shared.errors.notAccountNumber,
  );

  $.validator.addMethod(
    'postal-code',
    function (value, element) {
      const allowedChars = 'a-z0-9' + ' ' + '-';
      const regexp = new RegExp(`[^${allowedChars}]`, 'i');
      return regexp.test(value) === false;
    },
    Wahanda.lang.shared.errors.notPostalCode,
  );

  $.validator.addMethod(
    'alphanumeric',
    function (value, element) {
      const allowedChars = 'a-z0-9' + ' ' + '-';
      const regexp = new RegExp(`[^${allowedChars}]`, 'i');
      return regexp.test(value) === false;
    },
    Wahanda.lang.shared.errors.notAlphaNumeric,
  );

  $.validator.addMethod(
    'require-with',
    function (value, $element, otherElementSelector) {
      const otherValue = $(otherElementSelector).val();
      return (value != '' && otherValue != '') || (value == '' && otherValue == '');
    },
    Wahanda.lang.shared.errors.relatedRequired,
  );

  $.validator.addMethod(
    'date-not-in-past',
    function (value, $element) {
      const date = Wahanda.Date.getMidnight($($element).datepicker('getDate'));
      const now = Wahanda.Date.getMidnight();
      return now.getTime() <= date.getTime();
    },
    Wahanda.lang.shared.errors.dateIsPast,
  );

  /**
   * Validates if date is not less than the given API string date.
   */
  $.validator.addMethod(
    'date-later-or-equal',
    function (value, $element, minimumDate) {
      minimumDate = Wahanda.Date.createDate(minimumDate);
      const date = Wahanda.Date.getMidnight($($element).datepicker('getDate'));
      return minimumDate.getTime() <= date.getTime();
    },
    Wahanda.lang.shared.errors.dateIsPast,
  );

  if (Wahanda.lang.profile) {
    // Profile validations.
    // Currently available only in full connect.

    /**
     * Validate profile name. This includes the server-side check too.
     */
    $.validator.addMethod(
      'profile-name',
      function (value) {
        return /[^a-z0-9\-]/i.test(value) === false;
      },
      Wahanda.lang.profile.editForm.errors.name.format,
    );

    /**
     * A dummy check that reacts to profile name validation, which is done asynchronously.
     * The real check is performed in the view itself.
     */
    $.validator.addMethod(
      'profile-async-validation',
      function (value, element) {
        return !$(element).data('profile-async-check');
      },
      Wahanda.lang.profile.editForm.errors.name.format,
    );
  }

  /**
   * One field should match the other.
   */
  $.validator.addMethod(
    'equal-to-field',
    function (value, $element, otherFieldId) {
      const $other = $(`#${otherFieldId}`);
      return value === $other.val();
    },
    Wahanda.lang.shared.errors.fieldsNotEqual,
  );

  /**
   * Validate birth year.
   *
   * 4 digit format and greater or equal than 1900 and less or equal to current year.
   */
  $.validator.addMethod(
    'birthyear',
    function (value, element) {
      if (value === '' && this.optional(element)) {
        return true;
      }
      return /^\d{4}$/.test(value) && value >= 1900 && value <= new Date().getFullYear();
    },
    Wahanda.lang.shared.errors.notBirthYear,
  );

  /**
   * Jquery has a bug in the min validation, whereby if the limit is zero it will allow negative numbers
   * therefore we need a custom jobby for the min zero
   */
  $.validator.addMethod(
    'min-zero',
    function (value, element) {
      if (value === '' && this.optional(element)) {
        return true;
      }

      if (value === '-0') {
        return true;
      }

      const startsWithMinus = value.toString().trim().charAt(0) === '-';

      if (startsWithMinus) {
        return false;
      }

      const parsedValue = Wahanda.Number.formatPOSInputintoFloat(value);

      return parsedValue >= 0;
    },
    Wahanda.lang.shared.errors.greaterThanZero,
  );

  $.validator.addMethod(
    'webcal-url',
    function (value, element) {
      // contributed by Scott Gonzalez: http://projects.scottsplayground.com/iri/
      return (
        this.optional(element) ||
        /^(https?|webcal):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
          $.trim(value),
        )
      );
    },
    Wahanda.lang.shared.errors.notWebcalUri,
  );

  $.validator.addMethod(
    'sku-amount',
    function (value, element) {
      return this.optional(element) || (!isNaN(value) && value >= 1);
    },
    Wahanda.lang.shared.errors.notValidSkuAmount,
  );

  const originalEmailValidationMethod = $.validator.methods.email;
  $.validator.addMethod(
    /**
     * Supplements the validator's core email validation method.
     *
     * If the element has a class of 'v-email-or-profilename' then either
     * an email address or a profile name will pass validation.
     */
    'email',
    function (value, element) {
      const isProfileNameAllowed = $(element).hasClass('v-email-or-profilename');
      const isEmailAddress = value.indexOf('@') !== -1;

      if (isEmailAddress || !isProfileNameAllowed) {
        return originalEmailValidationMethod.apply(this, arguments);
      }
      return true;
    },
  );

  $.validator.addMethod(
    /**
     * Specific validation for the venue email address. This address is used as a 'replyTo' address in any email sent out on behalf of the venue happens to be
     * subject to a restrictions meaning that it must not contain any characters which the backend considers to be invalid (ascii code c <= 40 OR c >= 177)
     */
    'venue-email',
    function (value) {
      return (
        !containsInvalidEmailAddressCharacters(value) &&
        originalEmailValidationMethod.apply(this, arguments)
      );
    },
  );

  $.validator.addMethod(
    /**
     * POS currency format check.
     */
    'currency-amount',
    function (value, element) {
      const $el = $(element);

      if (this.optional(element) && value === '') {
        return true;
      }

      const floatValue = Wahanda.Number.formatPOSInputintoFloat(value);

      return !isNaN(floatValue) && floatValue !== false;
    },
    Wahanda.lang.shared.errors.invalidCurrencyFormat,
  );

  $.validator.addMethod(
    /**
     * POS currency format check.
     */
    'currency-amount-expression',
    function (value, element) {
      const formattedValue = Wahanda.Number.formatExpression(value);
      let parsedExpression;

      if (this.optional(element) && value === '') {
        return true;
      }

      try {
        parsedExpression = evaluate(formattedValue);
      } catch (e) {
        return false;
      }

      return !isNaN(parsedExpression) && parsedExpression !== false;
    },
    Wahanda.lang.shared.errors.invalidCurrencyFormat,
  );

  $.validator.addMethod(
    /**
     * Maximum currency amount check.
     * This differs from "max" in way that this supports correct number formatting.
     */
    'data-max-currency',
    function maxCurrencyValidator(value, element, minValue) {
      const posValue = Wahanda.Number.formatPOSInputintoFloat(value);
      return $.validator.methods.max.call(this, posValue, element, minValue);
    },
    function maxCurrencyErrorText(value) {
      const currencyValue = Wahanda.Currency.format(value);
      return $.validator.messages.max(currencyValue);
    },
  );

  $.validator.addMethod(
    /**
     * Minimum currency amount check.
     * This differs from "min" in way that this supports correct number formatting.
     */
    'data-min-currency',
    function minCurrencyValidator(value, element, minValue) {
      const posValue = Wahanda.Number.formatPOSInputintoFloat(value);
      return $.validator.methods.min.call(this, posValue, element, minValue);
    },
    function minCurrencyErrorText(value) {
      const currencyValue = Wahanda.Currency.format(value);
      return $.validator.messages.min(currencyValue);
    },
  );

  $.validator.addMethod(
    /**
     * Integer check.
     */
    'integer',
    function (value, element) {
      if (value === '' && this.optional(element)) {
        return true;
      }
      return value === parseInt(value, 10).toString();
    },
    Wahanda.lang.shared.errors.notValidInteger,
  );

  // Validation on all form's elements to not have duplicate values in them.
  $.validator.addMethod(
    'unique-group',
    function (value, element, groupName) {
      // Get all elements for this validation group
      const elems = $(element.form).find(`[data-rule-unique-group='${groupName}']`).toArray();
      const values = elems.map(function (elem) {
        return $(elem).val();
      });

      // For all fields to be unique, the field count must equal unique value count
      return _.uniq(values).length === elems.length;
    },
    Wahanda.lang.shared.errors.notUniqueFields,
  );

  // Set the default translations
  if (typeof Wahanda.lang.validate.defaults !== 'undefined') {
    const parametrised = {};
    _.each(['maxlength', 'minlength', 'rangelength', 'range', 'max', 'min'], function (key) {
      parametrised[key] = $.validator.format(Wahanda.lang.validate.defaults[key]);
    });
    $.extend(
      $.validator.messages,
      // Straight translations
      _.pick(
        Wahanda.lang.validate.defaults,
        'required',
        'remote',
        'email',
        'url',
        'date',
        'dateISO',
        'number',
        'digits',
        'creditcard',
        'equalTo',
      ),
      // Translations which have parameters - they must be passed through the formatting fn
      parametrised,
    );
  }
})(jQuery);
