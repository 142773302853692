import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class PatchTest extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-patch-test', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path d="M11.7965 7.56973C11.4072 6.86802 10.3915 6.88759 10.0296 7.60378L6.72343 14.1452C5.17813 17.2026 7.42551 20.8 10.8809 20.8C13.2615 20.8 15.0687 19.0925 15.4573 17.0305C15.5081 16.761 15.6385 16.5089 15.8538 16.3389L16.0491 16.1847L16.4404 15.85L16.9964 15.3L17.1852 15.1132C17.342 14.9581 17.6082 15.0406 17.6499 15.2571L17.7474 15.7641C17.9946 19.4992 15.0256 23 10.8809 23C5.77236 23 2.44975 17.6815 4.73437 13.1613L10.0221 2.69921C10.3855 1.98009 11.4067 1.9641 11.7925 2.67148L15.1826 8.88747C15.3833 9.25546 15.3332 9.70958 15.0571 10.025L14.7725 10.35C14.3214 10.8203 13.5438 10.7194 13.2277 10.1495L11.7965 7.56973Z" />
        <path d="M21.2077 8.25846C20.8213 7.88989 20.2136 7.88989 19.8272 8.25846L13.7949 14.013C13.4085 14.3815 12.8008 14.3815 12.4144 14.013L10.8298 12.5013C10.4434 12.1327 9.83565 12.1327 9.44928 12.5013L9.41547 12.5336C9.00247 12.9276 9.00248 13.5867 9.41548 13.9807L12.4144 16.8415C12.8008 17.2101 13.4085 17.2101 13.7949 16.8415L21.2415 9.73786C21.6545 9.34388 21.6545 8.6847 21.2415 8.29071L21.2077 8.25846Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(PatchTest);
