import React, { useEffect, useState } from 'react';
import Wahanda from 'common/wahanda';
import { SideBarAnalytics } from 'common/analytics';
import style from './GetHelpButton.scss';
import { Spinner } from 'components/common/Spinner';
import { openChat } from 'utilities/chat';
import { ChatSourceType } from 'src/tracking-container/events/chat';

interface GetHelpButtonProps {
  source: ChatSourceType;
}

export const GetHelpButton = ({ source }: GetHelpButtonProps) => {
  const { isLoading, open } = useChat({ source });

  return (
    <button
      disabled={isLoading}
      data-test="get-live-help-button"
      onClick={open}
      className={style.getHelpButton}
    >
      {isLoading && <Spinner size="small" />}
      {Wahanda.lang.home.contacts.getHelpButton}
    </button>
  );
};

const useChat = ({ source }: { source: ChatSourceType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const isOpen = useChatIsOpen();

  const open = async () => {
    setIsLoading(true);
    try {
      SideBarAnalytics.trackGetHelpClick();
      await openChat(source);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    open,
    isOpen,
    isLoading,
  };
};

type UnofficialSalesforceEvent = {
  method: UnofficialSalesforceMethod;
};

// Those events are not official Salesforce events, so may stop working at any time.
// We should consider replacing them with official Salesforce events.
type UnofficialSalesforceMethod = 'ESW_APP_RESET_INITIAL_STATE' | 'ESW_APP_READY_EVENT';

const useChatIsOpen = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSalesforceEvents = (event: MessageEvent<UnofficialSalesforceEvent>) => {
    switch (event.data.method) {
      case 'ESW_APP_RESET_INITIAL_STATE':
        setIsOpen(false);
        break;
      case 'ESW_APP_READY_EVENT':
        setIsOpen(true);
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleSalesforceEvents);
    return () => {
      window.removeEventListener('message', handleSalesforceEvents);
    };
  }, []);

  return isOpen;
};
