import React from 'react';
import App from 'common/backbone-app';
import { AccountLanguageSelectAnalytics } from 'common/analytics';
import { Radio } from 'components/common/Radio';
import Dialog from 'components/common/react-dialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import Wahanda from 'common/wahanda';
import * as localesConfig from 'config/domains-locales';
import { ACCOUNT_LOCALE_LOCAL_STORAGE_KEY, DIALOG_WIDTH } from './constants';

import style from './AccountLanguageSelect.scss';

interface Props {
  onClose: (args?) => void;
  isMasquerading: boolean;
}

interface State {
  locale: string;
}

export class AccountLanguageSelect extends React.PureComponent<Props, State> {
  public constructor(props) {
    super(props);

    const locale = this.accountLocale || this.venueLocale;

    this.state = {
      locale,
    };
  }

  public componentDidMount = () => {
    AccountLanguageSelectAnalytics.trackView();
  };

  private onSubmit = () => {
    const localePathPattern = /^\/([a-z]{2}(?:_[A-Z]{2})?)\//;
    let pathname = window.location.pathname;
    const localeInPathExists = pathname.match(localePathPattern) != null;

    const { onClose } = this.props;
    const { locale } = this.state;
    const pathLocale = locale.length === 2 ? `${locale}_${locale.toUpperCase()}` : locale;

    if (localeInPathExists) {
      pathname = pathname.replace(localePathPattern, '/');
    }

    Wahanda.LocalStorage.set(ACCOUNT_LOCALE_LOCAL_STORAGE_KEY, locale);
    AccountLanguageSelectAnalytics.trackApply(this.venueLocale, this.accountLocale);
    window.location.pathname = `/${pathLocale}${pathname}`;
    onClose();
  };

  private onClose = (...args) => {
    const { onClose } = this.props;
    AccountLanguageSelectAnalytics.trackClose();
    onClose(...args);
  };

  get accountLocale() {
    return Wahanda.LocalStorage.get(ACCOUNT_LOCALE_LOCAL_STORAGE_KEY);
  }

  get venueLocale() {
    if (!App.config) {
      return App.domainLocale;
    }
    return App.config.get('venue').locale || App.domainLocale;
  }

  private renderLocaleListItem = (locale, label) => (
    <div className={style.localeListItem} key={locale}>
      <Radio
        name="user-language"
        label={label}
        value={locale}
        onChange={() => this.setState({ locale })}
        checked={this.state.locale === locale}
      />
    </div>
  );

  private renderLocalesList = () => {
    const { isMasquerading } = this.props;
    const localesToTranslations = localesConfig.LOCALE_TO_TRANSLATION_MAP;
    const translationsToLocales = {};
    Object.keys(localesToTranslations).forEach((key) => {
      if (localesConfig.MASQUERADED_LOCALES.includes(key) && !isMasquerading) {
        return;
      }

      translationsToLocales[localesToTranslations[key]] = key;
    });
    const sortedTranslations = Object.keys(translationsToLocales).sort();

    const items = sortedTranslations.map((key) =>
      this.renderLocaleListItem(translationsToLocales[key], key),
    );

    return <div className={style.localesList}>{items}</div>;
  };

  private renderFooter = () => (
    <DialogFooter fullWidth>
      <DialogFooterButton title={Wahanda.lang.shared.buttons.apply} onClick={this.onSubmit} />
    </DialogFooter>
  );

  public render() {
    const lang = Wahanda.lang.settings.accountLanguageSelectDialog;
    return (
      <Dialog
        dataTest="language-select-modal"
        title={lang.title}
        onClose={this.onClose}
        width={DIALOG_WIDTH}
      >
        <div className={style.description}>{lang.description}</div>
        {this.renderLocalesList()}
        {this.renderFooter()}
      </Dialog>
    );
  }
}
