import React from 'react';
import ProductRow from '../../ProductRow';
// @ts-expect-error ts-migrate(2430) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
interface IProcessOrderRowProps extends React.HTMLAttributes<Element> {
  id: number;
  name: string;
  quantity: number;
  venueProductId: number;
  actions: {
    updateExistingOrder?: (...args: any[]) => any;
  };
  updateExistingOrder?: any;
}

export default class ProcessOrderRow extends React.Component<IProcessOrderRowProps, {}> {
  onChange = (newAmountToExistingOrder) => {
    const updatedProductOrderItem = {
      id: this.props.id,
      name: this.props.name,
      venueProductId: this.props.venueProductId,
      quantity: newAmountToExistingOrder,
    };
    if (newAmountToExistingOrder <= 0) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.actions.updateExistingOrder({
        ...updatedProductOrderItem,
        quantity: 0,
      });
    } else {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.actions.updateExistingOrder(updatedProductOrderItem);
    }
  };

  render() {
    const { name, id, quantity } = this.props;
    return <ProductRow name={name} elementId={id} amount={quantity} onChange={this.onChange} />;
  }
}
