import { createAction } from 'reduxStore/actionCreator';
import {
  COVID_UNLIMIT_OPERATION_REQUEST,
  COVID_UNLIMIT_OPERATION_SUCCESS,
  COVID_UNLIMIT_OPERATION_FAILURE,
  SHOW_VENUE_CLOSING_DIALOG,
  SHOW_VENUE_OPEN_CONFIRMATION_DIALOG,
  COVID_BANNER_HIDE_ALL_DIALOGS,
  VENUE_CLOSING_REQUEST,
  VENUE_CLOSING_SUCCESS,
  VENUE_CLOSING_FAILURE,
  RESET_COVID_BANNER_STATE,
} from 'reduxStore/actionsConst';

export interface VenueClosingRequestParams {
  venueId: number;
  employeeName: string;
  emailAddress: string;
}

export const covidUnlimitOperationRequest = () => createAction(COVID_UNLIMIT_OPERATION_REQUEST);

export type CovidUnlimitOperationRequestAction = ReturnType<typeof covidUnlimitOperationRequest>;

export const covidUnlimitOperationSuccess = () => createAction(COVID_UNLIMIT_OPERATION_SUCCESS);

export type CovidUnlimitOperationSuccessAction = ReturnType<typeof covidUnlimitOperationSuccess>;

export const covidUnlimitOperationFailure = () => createAction(COVID_UNLIMIT_OPERATION_FAILURE);

export type CovidUnlimitOperationFailure = ReturnType<typeof covidUnlimitOperationFailure>;

export const showVenueClosingDialog = () => createAction(SHOW_VENUE_CLOSING_DIALOG);

export type ShowVenueClosingDialog = ReturnType<typeof showVenueClosingDialog>;

export const showVenueOpenConfirmationDialog = () =>
  createAction(SHOW_VENUE_OPEN_CONFIRMATION_DIALOG);

export type ShowVenueOpenConfirmationDialog = ReturnType<typeof showVenueOpenConfirmationDialog>;

export const hideCovidBannerDialogs = () => createAction(COVID_BANNER_HIDE_ALL_DIALOGS);

export type HideCovidBannerDialogs = ReturnType<typeof hideCovidBannerDialogs>;

export const venueClosingRequest = ({
  venueId,
  employeeName,
  emailAddress,
}: VenueClosingRequestParams) =>
  createAction(VENUE_CLOSING_REQUEST, {
    venueId,
    employeeName,
    emailAddress,
  });

export type VenueClosingRequestAction = ReturnType<typeof venueClosingRequest>;

export const venueClosingSuccess = () => createAction(VENUE_CLOSING_SUCCESS);

export type VenueClosingSuccessAction = ReturnType<typeof venueClosingSuccess>;

export const venueClosingFailure = () => createAction(VENUE_CLOSING_FAILURE);

export type VenueClosingFailureAction = ReturnType<typeof venueClosingFailure>;

export const resetCovidBannerState = () => createAction(RESET_COVID_BANNER_STATE);

export type ResetCovidBannerState = ReturnType<typeof resetCovidBannerState>;
