/* global BackboneEx _ */
import { trackEvent } from 'common/analytics';

(function menuOffer() {
  const BaseView = BackboneEx.View.Dialog2;
  const MenuOffer2 = BaseView.extend({
    events: {
      'click .js-close': 'close',
      'click .js-save': 'triggerFormValidation',
      'click .js-archive': 'archive',
      'click .js-view-on-site': 'viewOfferOnSite',
      'click .js-copy-offer': 'onCreateCopy',
      'click .js-open-iglu-dialog': 'openIgluDialog',
      dialogopen: 'onDialogOpen',
    },

    buttons: {
      close: {
        title: Wahanda.lang.shared.cancel,
      },
      archive: {
        title: Wahanda.lang.menu.offer.buttons.archive,
        classes: 'js-archive dialog2--button-red',
        isHidden: function isHidden() {
          return !this.model.id || this.model.isArchived() || this.model.isReadOnly();
        },
      },
      save: {
        title: function title() {
          if (this.model.isArchived()) {
            return Wahanda.lang.menu.offer.buttons.saveUnarchive;
          }
          return Wahanda.lang.shared[this.model.id ? 'save' : 'create'];
        },
      },
      menu: function menu(context) {
        const items = [];

        if (
          (context.model.get('listed') || context.model.get('pending')) &&
          context.model.get('visible') &&
          App.isFeatureSupported('marketplace') &&
          context.model.get('domainToServiceLinks') &&
          context.model.id
        ) {
          const map = context.model.get('domainToServiceLinks');
          Object.keys(map).forEach(function f(key) {
            const value = map[key];
            items.push({
              label: function getLabel() {
                return key;
              },

              classes: 'js-view-on-site',

              dataAttr: [
                {
                  dataKey: 'url',
                  dataValue: `https://${key}${value}`,
                },
              ],
            });
          });
        }
        if (App.isMasquerading()) {
          items.unshift({
            label: function getLabel(labelContext) {
              if (!labelContext.model.id || labelContext.model.isReadOnly()) {
                return false;
              }
              return Wahanda.lang.menu.offer.buttons.copy;
            },
            classes: 'js-copy-offer',
          });

          items.push({
            label: function f(labelContext) {
              if (!labelContext.model.id) {
                return false;
              }
              return 'Internal Service Settings';
            },
            classes: 'js-open-iglu-dialog iglu-menu-item-background',
          });
        }
        return { items };
      },
    },

    options: {
      width: 918,
      height: 300,
      dialogClass: 'offer2',
      dataTest: 'menu-offer-modal',
    },

    templateId: 'offer2-dialog-template',
    messageTemplateId: 'offer2-message-template',
    headerView: null,
    tabViews: null,
    updated: false,
    mediator: null,
    menu: null,
    afterSavecallbacks: null,
    isBeingCopied: false,

    dialogTitle: function getDialogTitle() {
      let key = this.model.getCamelCaseTypeName();
      if (key === 'treatment') {
        key = 'offer';
      }
      let title = Wahanda.lang.menu[key].title;

      if (!this.model.id) {
        title = Wahanda.Template.render(Wahanda.lang.menu.textTemplates.newDialogTitle, {
          title,
        });
      } else if (App.isMasquerading()) {
        title = `${title} - ID #${this.model.id}`;
      }

      return title;
    },

    initialize: function initialize(...args) {
      BaseView.prototype.initialize.apply(this, args);
      this.tabViews = [];
      this.afterSavecallbacks = [];

      this.mediator = Wahanda.Event.createMediator({
        // List of events this mediator supports
        MAIN_TREATMENT_CHANGED: 'mtc',
        PACKAGE_SERVICES_CHANGED: 'psc',
        PACKAGE_SERVICES_CHANGE_ACCEPTED: 'pkg-changes-merged',
        PRICING_TYPE_CHANGE: 'PRICING_TYPE_CHANGE',
        CHANGE_USE_PROCESSING_TIME: Wahanda.Event.CHANGE_USE_PROCESSING_TIME,
        PACKAGE_MASTER_SERVICE_CHANGED: 'PACKAGE_MASTER_SERVICE_CHANGED',
      });

      this.listenTo(
        this.mediator,
        this.mediator.PACKAGE_SERVICES_CHANGE_ACCEPTED,
        this.renderContent,
      );

      App.trigger(Wahanda.Event.RESET_SERVICE_EDIT_TRACKING);
    },

    render: function render() {
      BaseView.prototype.render.call(this);

      const self = this;

      this.loadmask();

      const menuPromise = Wahanda.Cache.menu();
      const promises = [menuPromise];

      menuPromise.done(function done(menu) {
        self.menu = menu;
      });

      if (this.model.id > 0 && !this.updated) {
        this.updated = true;
        promises.push(this.model.fetch());
      }

      $.when // eslint-disable-line prefer-spread
        .apply($, promises)
        .done(function done() {
          self.renderContent();

          if (!self.model.isReadOnly()) {
            self.focusForm();
          }

          self.model.set('hadFreeSkuWhenLoaded', self.model.hasFreeSku());

          self.resetCloseChangesCheck();
          self.unloadmask();
        })
        .fail(function fail() {
          self.showError(Wahanda.lang.menu.offer.errors.readFailed);
          self.close();
        });
    },

    viewOfferOnSite: function viewOfferOnSite(event) {
      const url = $(event.target).data('url');
      window.open(url, '_blank');
    },

    openIgluDialog: function openIgluDialog() {
      App.Views.Dialog.IgluModal.show(
        `/iglu/service/service.xhtml?serviceId=${this.model.id}`,
        'Internal Service Settings',
      );
    },

    archive: function archive() {
      this.forcedStatus = 'archive';
      this.$('form').submit();
    },

    renderContent: function renderContent() {
      if (!this.dialogCommand('isOpen')) {
        // The dialog isn't open yet. Wait for it to open before rendering.
        this.onDialogEvent('open', function open() {
          this.renderContent();
        });
        return;
      }

      this.dialogCommand('option', 'height', this.getDesiredDialogHeight());
      this.refreshPosition();

      this.setupValidation();
      this.renderTabs();
      this.renderInitialMessages();

      this.setTabContainerMaxHeight();

      const self = this;
      setTimeout(function timeout() {
        self.origValues = _.omit(self.getValues(), 'employees');
      }, 500);
    },

    renderInitialMessages: function renderInitialMessages() {
      if (this.model.isArchived()) {
        this.showMessage('archived', Wahanda.lang.menu.offer.warnings.archived.title);
      }
      if (this.model.isFromChain()) {
        this.showMessage('locked', Wahanda.lang.menu.offer.warnings.chain.title);
      }
      if (this.model.isReadOnly() || this.model.get('locked')) {
        const message =
          this.model.get('locked') && App.isMasquerading()
            ? Wahanda.lang.menu.offer.warnings.locked.masq
            : Wahanda.lang.menu.offer.warnings.locked.title;

        this.showMessage('locked', message);
      }
      if (this.model.isPending()) {
        this.showMessage('pending', Wahanda.lang.menu.offer.warnings.pending.title);
      }
    },

    setupValidation: function setupValidation() {
      const self = this;
      const options = _.extend(
        {
          submitHandler: function submitHandler() {
            // Note: save implementation is in `menu-offer2/saving.js` file.
            self.save(!!self.options.isCopy);
            self.once('saved', () => {
              App.trigger(Wahanda.Event.OFFER_DIALOG_SAVED, {
                serviceId: self.model.id,
              });
            });
          },
          // necessary otherwise ckeditor validation won't work, @see: https://stackoverflow.com/questions/22248918/jquery-validation-not-working-with-ckeditor
          ignore: [],
        },
        Wahanda.Validate.Renderers.offer2.getRenderer(this),
      );

      if (this.model.isSpaBreak()) {
        // By default, in this form hidden fields should not be ignored as they might be in a different
        // (hidden) tab.
        // Yet for Dated Spa Break Room Pricing case, hidden fields must be ignored as they are disabled pricing.
        options.ignore = function ignoreFilter(_, field) {
          const $field = $(field);
          // eslint-disable-next-line no-useless-escape
          if (/offer\-pricing\-fullPriceAmount/.test($field.attr('name'))) {
            return $field.closest('li').hasClass('off');
          }
          return false;
        };
      }

      const validations = Wahanda.Validate.getValidations('defaults', options);
      this.$('form').validate(validations);
    },

    hasDialogChanged: function hasDialogChanged() {
      return this.dialogChanged;
    },

    renderTabs: function renderTabs() {
      if (this.tabViews.length === 0) {
        this.initTabs();
      }
      _.invoke(this.tabViews, 'render');
    },

    initTabs: function initTabs() {
      // Add all required tab views to the view, based on model type
      // render the tab chooser
      const tabs = [
        MenuOffer2.PricingTab,
        MenuOffer2.DescriptionTab,
        MenuOffer2.FinePrintTab,
        MenuOffer2.DistributionTab,
      ];

      _.each(
        tabs,
        function f1(Tab) {
          const options = {
            model: this.model,
            mediator: this.mediator,
            hasDialogChanged: this.hasDialogChanged.bind(this),
            isCopy: !!this.options.isCopy,
          };

          if (Tab.optionsToInherit) {
            _.each(
              Tab.optionsToInherit,
              function f2(itemName) {
                options[itemName] = this.options[itemName]
                  ? this.options[itemName]
                  : this[itemName];
              },
              this,
            );
          }

          this.tabViews.push(new Tab(options));
        },
        this,
      );

      const tabContainers = [];
      const tabList = [];
      _.each(this.tabViews, function each(tab) {
        const id = tab.getTabId();
        // Create DIVs to render tab content
        let classList = 'offer2--tab';
        if (tab.tabClasses) {
          classList += ` ${tab.tabClasses}`;
        }
        const $cont = $('<div>', { id, class: classList });
        tab.setElement($cont);
        tabContainers.push($cont);
        // Also create the tab list choice
        tabList.push(`<li><a href="#${id}">${tab.tabName}</a></li>`);
      });

      // Add elements to DOM
      this.$('.offer2--tab-list').append(tabList);
      this.$('.offer2--tab-container').append(tabContainers);

      // Init the UI.tabs plugin
      this.$('.offer2--tabs').tabs();
    },

    getValues: function getValues() {
      const values = {};

      _.each(this.tabViews, function each(view) {
        const viewValues = view.getValues();
        if (viewValues) {
          _.extend(values, viewValues);
        }
      });

      return values;
    },

    setTabContainerMaxHeight: function setTabContainerMaxHeight() {
      const $tc = this.$('.offer2--tab-container');
      const $dialog = this.$dialogContent.parent();
      const $footer = this.$('.dialog2--footer');

      const maxHeight =
        $dialog.height() - $tc.offset().top + $dialog.offset().top - $footer.height();
      $tc.css('max-height', maxHeight);
    },

    destruct: function destruct() {
      // Call `remove` on all views to stop listening for events and do other cleanup
      _.invoke(this.tabViews, 'remove');
    },

    getDesiredDialogHeight: function getDesiredDialogHeight() {
      return $(window).height() - 40;
    },

    onDialogOpen: function onDialogOpen() {
      trackEvent('services', 'view', 'offer-dialog');

      App.trigger(Wahanda.Event.MENU_OFFER_DIALOG_OPENED, {
        offerId: this.model.get('id'),
        offerType: this.model.getCamelCaseTypeName(),
        isNew: !this.model.get('id'),
      });
      if (!this.model.isReadOnly()) {
        window.setTimeout(() => this.focusForm(), 0); // React 16 timing issue
      }

      this.onDialogEvent('close', this.onDialogClose.bind(this));
    },

    onDialogClose: function onDialogOpen() {
      App.trigger(Wahanda.Event.MENU_OFFER_DIALOG_CLOSED, this, {
        fromTemplate: this.model.isFromTemplate,
        isCopy: !!this.options.isCopy,
        isBeingCopied: this.isBeingCopied,
      });
    },

    close: function close() {
      if (!this.isBeingSaved) {
        App.trigger(Wahanda.Event.MENU_OFFER_DIALOG_CANCELLED, {
          offerId: this.model.id,
        });
      }
      BaseView.prototype.close.call(this);
    },

    showMessage: function showMessage(type, message) {
      const html = Wahanda.Template.renderTemplate(this.messageTemplateId, {
        type,
        html: message,
      });
      this.$('.dialog2--messages').html(html);
    },

    removeMessage: function removeMessage() {
      this.$('.dialog2--messages').empty();
    },

    /**
     * Focus an form validation error in a nice UX way.
     */
    focusInvalidField: function focusInvalidField() {
      // Check for errors in current tab
      const $currentTabError = this.getCurrentTabNode().find('.error');
      if ($currentTabError.length) {
        focusEl($currentTabError);
        return;
      }
      // Find first error on another tab. If there is one, open it.
      const $error = this.$('.offer2--tabs').find('.error').first();
      if ($error.length) {
        const $tab = $error.closest('.offer2--tab');
        if ($tab.length) {
          // Tab found. Open it.
          const id = $tab.attr('id');
          this.$(`a[href="#${id}"]`).click();
        }
        focusEl($error);
      }

      function focusEl($el) {
        if ($el.is('.chosen-container')) {
          $el.prev('select').trigger('chosen:open');
          $el.find('.chosen-search input').focus();
        } else {
          $el.focus();
        }
      }
    },

    getCurrentTabNode: function getCurrentTabNode() {
      return this.$('.offer2--tab').not('.ui-tabs-hide');
    },

    onCreateCopy: function onCreateCopy() {
      // Check if model has any changes to decide if we should
      // show warning dialog
      const currentValues = _.omit(this.getValues(), 'employees');
      trackEvent('services', 'click', 'menu-offer', 'create-copy');

      if (!_.isEqual(this.origValues, currentValues)) {
        const confirmDialog = new App.Views.Dialog.ConfirmCopyDialog({
          confirm: this.createCopy.bind(this),
        });
        trackEvent('services', 'view', 'menu-offer', 'create-copy-confirm-dialog');
        confirmDialog.render();
        confirmDialog.open();
      } else {
        this.isBeingCopied = true;
        this.createCopy();
      }
    },

    createCopy: function createCopy() {
      trackEvent('services', 'view', 'menu-offer', 'create-copy');
      const clonedModel = this.model.createCopy({
        featuredItemsCollection: this.options.featuredItemsCollection,
      });

      const baseOptions = {
        isCopy: true,
        treatments: this.options.treatments,
        employeeCategories: this.options.employeeCategories,
        model: clonedModel,
        venue: this.options.venue,
        featuredItemsCollection: this.options.featuredItemsCollection,
        employees: this.options.employees,
        roomTypes: this.options.roomTypes,
      };

      const view = new App.Views.Forms.MenuOffer2(baseOptions);

      view.render();
      this.close();
      // Add a slight delay between closing the current dialog and opening the new
      // copy offer dialog.
      setTimeout(() => {
        view.open();
      }, 200);
    },
  });

  BackboneEx.Mixin.View.Form.mixin(MenuOffer2);

  MenuOffer2.Pricing = {};

  App.Views.Forms.MenuOffer2 = MenuOffer2;
})();
