import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Instagram extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-instagram', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <defs>
          <linearGradient id="instagram-a" x1="50%" x2="50%" y1="99.709%" y2=".777%">
            <stop offset="0%" stopColor="#E09B3D" />
            <stop offset="30%" stopColor="#C74C4D" />
            <stop offset="60%" stopColor="#C21975" />
            <stop offset="100%" stopColor="#7024C4" />
          </linearGradient>
        </defs>
        <path
          fill="url(#instagram-a)"
          d="M16.0173188,2 L7.94768116,2 C4.66811594,2 2,4.66811594 2,7.94768116 L2,16.0173188 C2,19.2968841 4.66811594,21.965 7.94768116,21.965 L16.0173188,21.965 C19.2968841,21.965 21.965,19.2968841 21.965,16.0173188 L21.965,7.94768116 C21.965,4.66811594 19.2968478,2 16.0173188,2 Z M19.9565217,16.0173188 C19.9565217,18.1928623 18.1928623,19.9565217 16.0173188,19.9565217 L7.94768116,19.9565217 C5.77213768,19.9565217 4.00847826,18.1928623 4.00847826,16.0173188 L4.00847826,7.94768116 C4.00847826,5.77210145 5.77213768,4.00847826 7.94768116,4.00847826 L16.0173188,4.00847826 C18.1928623,4.00847826 19.9565217,5.77210145 19.9565217,7.94768116 L19.9565217,16.0173188 Z M11.9825,6.81884058 C14.8297464,6.81884058 17.1461594,9.13521739 17.1461594,11.9824638 C17.1461594,14.8297101 14.8297464,17.1461232 11.9825,17.1461232 C9.13525362,17.1461232 6.81884058,14.8296739 6.81884058,11.9824638 C6.81884058,9.13525362 9.13525362,6.81884058 11.9825,6.81884058 Z M11.9825,8.82731884 C10.2399638,8.82731884 8.82731884,10.2399275 8.82731884,11.9825 C8.82731884,13.7250725 10.2399275,15.1376812 11.9825,15.1376812 C13.7250362,15.1376812 15.1376812,13.7250362 15.1376812,11.9825 C15.1376812,10.2399275 13.7250725,8.82731884 11.9825,8.82731884 Z M17.1561594,5.62028986 C17.8395117,5.62028986 18.3934783,6.17425637 18.3934783,6.8576087 C18.3934783,7.54096102 17.8395117,8.09492754 17.1561594,8.09492754 C16.4728071,8.09492754 15.9188406,7.54096102 15.9188406,6.8576087 C15.9188406,6.17425637 16.4728071,5.62028986 17.1561594,5.62028986 Z"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(Instagram);
