import React from 'react';
import classnames from 'classnames';
import { Treatments } from '../Treatments';
import { AddCustom } from '../AddCustom';

import style from '../SelectServiceDialog.scss';

const filterForTreatmentTypeId = (id) => ({ treatmentGroupId }) => treatmentGroupId === id;
const reduceMenuItems = (array, { menuItems }) => array.concat(menuItems);
const createTreatmentsList = (treatmentGroupId, treatmentTypesList) =>
  treatmentTypesList.filter(filterForTreatmentTypeId(treatmentGroupId)).reduce(reduceMenuItems, []);

interface Props {
  list: any[];
  onTreatmentTypeClick: (...args: any[]) => any;
  onTreatmentClick?: (...args: any[]) => any;
  onAddCustomButtonClick?: (...args: any[]) => any;
  addCustomLabelText?: string;
  addCustomButtonText?: string;
}

export const TreatmentTypes = ({
  list,
  onTreatmentTypeClick,
  onTreatmentClick = () => {
    /* empty */
  },
  onAddCustomButtonClick = () => {
    /* empty */
  },
  addCustomLabelText = '',
  addCustomButtonText = '',
}: Props) => (
  <ul className={style.treatmentTypes}>
    {list.map(({ treatmentGroupId, treatmentTypeName, selected }) => (
      <li
        className={classnames(style.treatmentType, {
          [style.selected]: selected,
        })}
        key={treatmentGroupId}
      >
        <div
          className={style.treatmentTypeName}
          onClick={() => onTreatmentTypeClick(treatmentGroupId)}
        >
          {treatmentTypeName}
        </div>
        {selected && (
          <div className={style.withAddCustom}>
            <Treatments
              list={createTreatmentsList(treatmentGroupId, list)}
              onTreatmentClick={onTreatmentClick}
            />
            <AddCustom
              classes={style.addCustomService}
              labelText={addCustomLabelText}
              buttonText={addCustomButtonText}
              onButtonClick={onAddCustomButtonClick}
            />
          </div>
        )}
      </li>
    ))}
  </ul>
);
