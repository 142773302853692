import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import { Initializer } from 'common/types/initializers';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import { DurationSelector, DurationSelectorProps } from './';

const store = storeBuilder();
interface Params extends DurationSelectorProps {
  node: HTMLElement;
}

export const DurationSelectorInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(params.node),
  render: () => {
    ReactDOM.render(
      <Provider store={store}>
        <DurationSelector
          key={get(params, 'key')}
          applicationTime={get(params, 'applicationTime')}
          finishingTime={get(params, 'finishingTime')}
          processingTime={get(params, 'processingTime')}
          canUserEditAppointment={get(params, 'canUserEditAppointment')}
          onApplicationTimeChange={get(params, 'onApplicationTimeChange')}
          onProcessingTimeChange={get(params, 'onProcessingTimeChange')}
          onFinishingTimeChange={get(params, 'onFinishingTimeChange')}
          tracking={get(params, 'tracking')}
        />
      </Provider>,
      params.node,
    );
  },
});
