/**
 * Time block model.
 */
App.Models.TimeBlock = BackboneEx.Model.Silent.extend({
  REPEAT_NONE: 'D',
  REPEAT_NONE_PERIOD: 'P',
  REPEAT_DAILY: 'A',
  REPEAT_WEEKLY: 'W',
  REPEAT_BIWEEKLY: 'B',

  UPDATE_TYPE_CURRENT: 'D',
  UPDATE_TYPE_WITH_FUTURE: 'F',

  DELETE_TYPE_CURRENT: 'D',
  DELETE_TYPE_WITH_FUTURE: 'F',

  defaults: {
    venueId: null,
    id: null,
    menuGroupId: '',
  },

  attributesToSave: function () {
    var attrs = [
      'dateFrom',
      'timeFrom',
      'dateTo',
      'timeTo',
      'name',
      'availabilityRuleTypeCode',
      'weekDays',
      'employeeId',
    ];
    if (this.id != null) {
      attrs.push('updateTypeCode', 'actionDate');
    }
    return attrs;
  },

  url: function () {
    var id = this.get('id');
    var base = '/venue/' + (this.get('venueId') || App.getVenueId()) + '/time-block';
    if (id) {
      base += '/' + id;
    }
    base += '.json';
    return App.Api.wsUrl(base);
  },

  isExternal: function () {
    return this.get('type') === 'EL';
  },

  isSingleDay: function () {
    return this.get('availabilityRuleTypeCode') === 'D';
  },

  isRecurring: function () {
    return !(this.get('availabilityRuleTypeCode') in { D: 1, P: 1 });
  },

  isMultiDayPeriod: function () {
    return this.get('availabilityRuleTypeCode') === 'P';
  },

  forAllEmployees: function () {
    return this.get('employeeId') === null;
  },

  isRecurringDataChanged: function () {
    if (this.isRecurring()) {
      if (this.hasChanged('timeFrom') || this.hasChanged('timeTo')) {
        return true;
      }
      if (this.get('availabilityRuleTypeCode') !== 'A' && this.hasChanged('weekDays')) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  },

  /**
   * Overridden for custom deletion URL.
   *
   * @param String method
   * @param Backbone.Model model
   * @param Object data
   * @return jqXHR
   */
  sync: function (method, model, data) {
    if (method === 'delete' && this.isRecurring()) {
      data.url =
        this.url() +
        '?delete-type-code=' +
        this.get('deleteTypeCode') +
        '&action-date=' +
        this.get('actionDate');
    }
    return (App.Models.TimeBlock.__super__.sync || Backbone.sync)
      .call(this, method, model, data)
      .done(() => {
        if (['create', 'update'].includes(method)) {
          App.trigger(Wahanda.Event.CALENDAR_BLOCK_TIME_SAVED, {
            id: model.id,
          });
        } else if (method === 'delete') {
          App.trigger(Wahanda.Event.CALENDAR_BLOCK_TIME_DELETED, {
            id: model.id,
          });
        }
      });
  },
});
