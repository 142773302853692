import Wahanda from 'common/wahanda';
import { ErrorType } from '../store/types';

export const errorMessages = {
  [ErrorType.NONE]: null,
  [ErrorType.MINOR]: null,
  [ErrorType.EMPLOYEES]: Wahanda.lang.menu.offer.purchasability.noEmployeesAssigned.text,
  [ErrorType.SERVICE_NAME]: null,
  [ErrorType.SERVICE_SKU_NAME]: null,
  [ErrorType.SERVICE_SKU_NAME_DUPLICATE]: Wahanda.lang.menu.offer.errors.skuNameNotUnique,
  [ErrorType.CURRENCY]: Wahanda.lang.shared.errors.invalidCurrencyFormat,
  [ErrorType.SERVER]: Wahanda.lang.menu.offer.errors.saveFailed,
};
