import React, { useState } from 'react';
import Dialog from 'components/common/react-dialog';
import Wahanda from 'common/wahanda';
import { CancellationFlowAnalytics } from 'common/analytics';
import { DialogFooterButtonProps, DialogFooter } from 'components/common/DialogFooter';
import { BOOKING_ACTOR } from 'common/consts';
import { AppointmentInfoBoxes } from '../../AppointmentInfoBoxes';
import { PaymentProtectionBanner, PaymentProtection } from '../../PaymentProtection';
import { ExplanationBullets } from '../../ExplanationBullets';
import { buildExplanationBullets } from '../../ExplanationBullets/buildExplanationBullets';
import { ExplanationBulletPointTranslations } from '../../ExplanationBullets/explanationBulletTranslations';

interface Props {
  bookingActor: BOOKING_ACTOR;
  onCancel: (params: { preventPaymentProtection: boolean }) => void;
  onClose: () => void;
  isFirstTimeCustomer: boolean;
}

export const ConfirmCancellationWithPaymentProtection = ({
  bookingActor,
  onCancel,
  onClose,
  isFirstTimeCustomer,
}: Props) => {
  const [preventPaymentProtection, setPreventPaymentProtection] = useState(false);
  const [isDirty, setDirty] = useState(false);

  const bulletPoints = buildExplanationBullets({
    flow: 'cancellation',
    bookingActor,
    isPrepaid: false,
    isFirstTimeCustomer,
    canPreventPaymentProtection: true,
  }).map((key) => ExplanationBulletPointTranslations.get(key));

  React.useEffect(() => {
    CancellationFlowAnalytics.trackRefundInitiation();
  }, []);

  const onCancelMethod = () => {
    CancellationFlowAnalytics.trackPaymentProtectionSubmit({ preventPaymentProtection });
    onCancel({ preventPaymentProtection });
  };

  const buttons: DialogFooterButtonProps[] = [
    {
      title: Wahanda.lang.shared.close,
      type: 'secondary',
      onClick: onClose,
    },
    {
      title: Wahanda.lang.calendar.appointments.cancellation.confirmCancellation.buttons.cancel,
      type: 'alert',
      onClick: onCancelMethod,
      disabled: !isDirty,
      dataTest: 'cancellation-submit',
    },
  ];

  const onPaymentProtectionChange = (value: boolean) => {
    setPreventPaymentProtection(value);
    setDirty(true);
    CancellationFlowAnalytics.trackPaymentProtection({ preventPaymentProtection: value });
  };

  return (
    <Dialog
      dataTest="confirm-cancellation-with-payment-protection-modal"
      onClose={onClose}
      title={Wahanda.lang.calendar.appointments.cancellation.confirmCancellation.title}
      width={420}
    >
      <AppointmentInfoBoxes isPrepaid={false} bookingActor={bookingActor} />
      <PaymentProtectionBanner />
      <PaymentProtection flow="cancellation" onChange={onPaymentProtectionChange} />
      <ExplanationBullets bulletPoints={bulletPoints} />
      <DialogFooter fullWidth buttons={buttons} />
    </Dialog>
  );
};
