import { call, put, select } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';

import {
  receiveEmployeesAction,
  receiveEmployeeAction,
  requestEmployeesFailureAction,
  receiveEmployeeSubmitSuccessAction,
  setEmployeeDataAction,
  receiveEmployeeDeleteSuccessAction,
  externalCalendarLinkSuccessAction,
  externalCalendarUnlinkSuccessAction,
  receivePermissionsByRolesAction,
  receiveCategoriesAction,
} from './actions';

import { getEmployee } from './selectors';

function getEmployeeEndpointUrl({ employeeId }) {
  return apiUrl('EMPLOYEE', { id: employeeId, include: 'employee-offers' });
}

function toEntity(response) {
  return {
    id: response.id,
    active: response.active,
    name: response.name || '',
    phone: response.phone || '',
    emailAddress: response.emailAddress || null,
    takesAppointments: response.takesAppointments,
    canLogin: response.canLogin,
    employmentStatus: response.employmentStatus,
    employeeCategoryId: response.employeeCategoryId,
    employeeOffers: response.employeeOffers || [],
    externalCalendarUri: response.externalCalendarUri || '',
    calLinked: response.externalCalendarUri != null,
    image: response.image,
    imageId: response.imageId,
    jobTitle: response.jobTitle || '',
    linkedExternalSalonName: response.linkedExternalSalonName,
    notes: response.notes || '',
    permissions: response.permissions || [],
    role: response.role,
    supplierBound: response.supplierBound,
  };
}

function toRolePermissionsEntity(response) {
  return {
    role: response.role,
    permissions: response.permissions,
  };
}

function toCategoryEntity(response) {
  return {
    id: response.id,
    name: response.name,
  };
}

function apiGetEmployeesList(url) {
  return api.get(url).then((data: any) => (data.employees ? data.employees.map(toEntity) : []));
}

function apiGetEmployee(url) {
  return api.get(url).then(toEntity);
}

function apiGetPermissionsByRoles(url) {
  return api
    .get(url)
    .then((data: any) => (data.roles ? data.roles.map(toRolePermissionsEntity) : []));
}

function apiGetCategories(url) {
  return api
    .get(url)
    .then((data: any) =>
      data.employeeCategories ? data.employeeCategories.map(toCategoryEntity) : [],
    );
}

export function* getEmployeesRequested({
  params: { active = true, takesAppointments = true } = {},
}) {
  try {
    const data = yield call(
      apiGetEmployeesList,
      apiUrl('EMPLOYEES', { active, 'takes-appointments': takesAppointments }),
    );

    yield put(receiveEmployeesAction(data));
  } catch (err) {
    yield put(requestEmployeesFailureAction(err));
  }
}

export function* getEmployeeRequested(action) {
  try {
    const data = yield call(apiGetEmployee, getEmployeeEndpointUrl({ employeeId: action.id }));

    yield put(receiveEmployeeAction(data));
  } catch (err) {
    yield put(requestEmployeesFailureAction(err));
  }
}

export function* getEmployeeSubmitted() {
  try {
    const employee = yield select(getEmployee);
    const isNew = employee.id == null;

    const persistedData = isNew
      ? {}
      : yield call(api.get, getEmployeeEndpointUrl({ employeeId: employee.id }));

    const response = yield call(
      isNew ? api.post : api.put,
      isNew ? apiUrl('EMPLOYEE_CREATE') : getEmployeeEndpointUrl({ employeeId: employee.id }),
      {
        ...employee,
        externalCalendarUri: persistedData.externalCalendarUri,
        employeeOffers: employee.takesAppointments
          ? employee.employeeOffers
          : persistedData.employeeOffers,
        employeeCategoryId: employee.takesAppointments
          ? employee.employeeCategoryId
          : persistedData.employeeCategoryId,
        jobTitle: employee.takesAppointments ? employee.jobTitle : persistedData.jobTitle,
        notes: employee.takesAppointments ? employee.notes : persistedData.notes,
      },
      true,
    );

    yield put(receiveEmployeeSubmitSuccessAction());
    if (isNew) {
      yield put(setEmployeeDataAction({ id: response.id }));
    }
  } catch (xhr: any) {
    const response = xhr.responseJson;
    yield put(
      requestEmployeesFailureAction({
        serverErrorName: response.errors[0].name,
        serverErrorData: response.additionalData,
        serverErrorAction: 'submit',
      }),
    );
  }
}

export function* getEmployeeDeleted(action) {
  try {
    /* @todo need delete/deactive endpoint and remove this call */
    const persistedData = yield call(api.get, getEmployeeEndpointUrl({ employeeId: action.id }));

    yield call(
      api.put,
      getEmployeeEndpointUrl({ employeeId: action.id }),
      {
        ...persistedData,
        active: false,
        canLogin: false,
      },
      true,
    );

    yield put(receiveEmployeeDeleteSuccessAction());
  } catch (xhr: any) {
    yield put(
      requestEmployeesFailureAction({
        serverErrorName: xhr.responseJson.errors[0].name,
        serverErrorAction: 'delete',
      }),
    );
  }
}

export function* getEmployeeExternalCalendarLinkRequested() {
  const employee = yield select(getEmployee);
  let persistedData;

  try {
    persistedData = yield call(api.get, getEmployeeEndpointUrl({ employeeId: employee.id }));

    yield call(api.put, getEmployeeEndpointUrl({ employeeId: employee.id }), {
      ...persistedData,
      externalCalendarUri: employee.externalCalendarUri,
    });

    yield call(
      api.post,
      apiUrl('EMPLOYEE_EXTERNAL_CALENDAR_SYNC', { id: employee.id }),
      null,
      true,
    );
    yield put(externalCalendarLinkSuccessAction());
  } catch (xhr: any) {
    yield call(api.put, getEmployeeEndpointUrl({ employeeId: employee.id }), {
      ...persistedData,
      externalCalendarUri: null,
    });
    yield put(requestEmployeesFailureAction(xhr.responseJson.errors[0].name));
  }
}

export function* getEmployeeExternalCalendarUnlinkRequested() {
  try {
    const employee = yield select(getEmployee);
    const persistedData = yield call(api.get, getEmployeeEndpointUrl({ employeeId: employee.id }));

    yield call(api.put, getEmployeeEndpointUrl({ employeeId: employee.id }), {
      ...persistedData,
      externalCalendarUri: null,
    });
    yield put(externalCalendarUnlinkSuccessAction());
  } catch (err) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    yield put(requestEmployeesFailureAction());
  }
}

export function* getPermissionsByRolesRequested() {
  try {
    const data = yield call(apiGetPermissionsByRoles, apiUrl('EMPLOYEE_ROLE_PERMISSIONS'));

    yield put(receivePermissionsByRolesAction(data));
  } catch (err) {
    yield put(requestEmployeesFailureAction(err));
  }
}

export function* getCategoriesRequested() {
  try {
    const data = yield call(apiGetCategories, apiUrl('EMPLOYEE_CATEGORIES'));

    yield put(receiveCategoriesAction(data));
  } catch (err) {
    yield put(requestEmployeesFailureAction(err));
  }
}
