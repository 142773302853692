/**
 * Collection for menu items.
 */
App.Collections.MenuItems = BackboneEx.Collection.Base.extend({
  urlTemplate: '/venue/{{venueId}}/menu-filters.json',
  model: Backbone.Model,

  parse: function (data) {
    return data.menuFilters;
  },

  /**
   * Comparator for menu filters.
   *
   * @param Object a
   * @param Object b
   *
   * @return int
   */
  comparator: function (a, b) {
    if (a.get('code') === 'ACT') {
      return -1;
    } else if (b.get('code') === 'ACT') {
      return 1;
    }
    return a.get('code').localeCompare(b.get('code'));
  },

  hasOffers: function () {
    var result = false;
    this.each(function (model) {
      result = result || model.get('itemCount') > 0;
    });
    return result;
  },
});
