export const STATUS_CREATED = 'CR';

export const STATUS_CANCELLED = 'CC';

export const STATUS_CONFIRMED = 'CN';

export const STATUS_REJECTED = 'RJ';

export const STATUS_UNASSIGNED = 'UA';

export const STATUS_NOSHOW = 'NS';

export const STATUS_CHECKED_OUT = 'CP';

export const TYPE_TREATMENT = 'T';
