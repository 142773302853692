/**
 * POS checkout error view.
 */
(function () {
  var BaseView = BackboneEx.View.Dialog2;
  App.Views.Forms.CheckoutError = BaseView.extend({
    events: {
      'click .js-close': 'close',
    },

    templateId: 'pos-checkout-error-template',

    options: {
      width: 353,
      dialogClass: 'pos-complete',
      dataTest: 'pos-checkout-error-modal',
    },
    dialogTitle: Wahanda.lang.calendar.appointments.pos.completeErrorTitle,

    initialize: function () {
      BackboneEx.View.Dialog2.prototype.initialize.apply(this, arguments);
    },

    render: function () {
      BackboneEx.View.Dialog2.prototype.render.apply(this, arguments);

      this.$dialogContent.empty().prepend(Wahanda.Template.renderTemplate(this.templateId, {}));
    },
  });
})();
