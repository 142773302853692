import _ from 'common/underscore';
import Wahanda from 'common/wahanda';

function getCategoriesUsed(offer) {
  return offer.get('skus').reduce((ids, sku) => {
    if (!ids.includes(sku.employeeCategoryId)) {
      ids.push(sku.employeeCategoryId);
    }
    return ids;
  }, []);
}

export function getAvailableEmployeeCats(offers, skus) {
  const categoriesPerOffer: any[] = [];

  // Get all categories for the open selection offers
  _.each(offers, (o) => {
    const sku = o.get('skus')[0];

    if (sku.employeeCategoryId) {
      categoriesPerOffer.push(getCategoriesUsed(o));
    }
  });

  // Get all categories for the stand-alone SKUs
  skus.forEach((sku) => {
    if (sku.employeeCategoryId) {
      categoriesPerOffer.push([sku.employeeCategoryId]);
    }
  });

  // Produce a result which contains only those employee cats which are in all of the offers
  return _.intersection.apply(null, categoriesPerOffer as any);
}

export function formatName(sku, employeeCategories) {
  const name = sku.nameInherited ? null : sku.name;
  const catName = sku.employeeCategoryId
    ? employeeCategories.get(sku.employeeCategoryId).get('name')
    : null;

  if (name && catName) {
    return Wahanda.lang.menu.offer.sku.twoDimensionalPricing
      .replace('{{& service}}', name)
      .replace('{{employeeCategory}}', catName);
  }

  return catName || name;
}

function buildSubSkus(skuList, initialSkus) {
  return skuList.map((sku) => [sku].concat(initialSkus));
}

export function formatSkus({ skusByOffer, subSkus, offers }) {
  if (_.isEmpty(skusByOffer)) {
    return subSkus ? [{ subSkus }] : [];
  }
  let offerNumber = 0;
  let packageSubSkus: any[] = [];
  const validEmployeeCats = getAvailableEmployeeCats(offers, subSkus);
  const skuCategoryFilter = (sku) =>
    // Filter out only valid 2D SKUs
    !sku.employeeCategoryId || validEmployeeCats.includes(sku.employeeCategoryId);
  const filteredSubSkus = subSkus.filter(skuCategoryFilter);

  _.each(skusByOffer, (fullSkuList) => {
    offerNumber += 1;

    const skuList = fullSkuList.filter(skuCategoryFilter);

    if (offerNumber === 1) {
      // Build a list of subSkus
      packageSubSkus = buildSubSkus(skuList, filteredSubSkus);
    } else {
      // Second open selection service.
      // Build the list by adding each of open selection service's skus to the
      // first list.
      const newSubSkus: any[] = [];
      _.each(skuList, (sku) => {
        // Filter out only the mergeable SKUs. Valid in 2D Offer case.
        const sku2dFilter = (pSku) =>
          !pSku.employeeCategoryId || sku.employeeCategoryId === pSku.employeeCategoryId;
        const validSkusToCombine = packageSubSkus.filter((pSkuList: any[]) => {
          // If the SKU has no employee category id, we have no reason to exclude it.
          if (!sku.employeeCategoryId) {
            return true;
          }

          return pSkuList.every(sku2dFilter);
        });

        _.each(validSkusToCombine, (pSubSku) => {
          newSubSkus.push([].concat(pSubSku, sku));
        });
      });
      packageSubSkus = newSubSkus;
    }
  });
  return _.map(packageSubSkus, (pSubSkus) => ({ subSkus: pSubSkus }));
}
