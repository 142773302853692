;(function($) {
	/**
	 * ButtonList - a plugin that converts <ul> elements to button-like behaviours, and triggers events.
	 * 
	 * Parameters:
	 *   - multiple Turns "multiMode" ON of OFF. 
	 *   - allowEvent Callback to check if the event is allowed, only in multiMode. E.g. can force to keep at least one
	 * 		selected.
	 * 
	 * (c) Wahanda
	 */
	$.fn.buttonList = function(opts) {
		var options = $.extend({}, $.fn.buttonList.defaults, opts);
		
		this.off('click.buttonList').on('click.buttonList', 'li', function(e) {
			e.preventDefault();
			var $li = $(this);
			var $ul = $li.parent();
			
			if ($li.hasClass('disabled')) {
				// This element is disabled. Can not act.
				return;
			}
			
			if (options.multiple) {
				var willBeChecked = !$li.hasClass(options.activeClass);
				
				if (_.isFunction(opts.allowEvent) && !opts.allowEvent($li, willBeChecked, $ul, options)) {
					// Event is not allowed.
					return;
				}
				
				$li.toggleClass(options.activeClass, willBeChecked);
				triggerEvent($ul, $li, willBeChecked);
			} else {
				if (!$li.hasClass(options.activeClass)) {
					unmark($ul);
					mark($li);
					triggerEvent($ul, $li);
				}
			}
		});

		function unmark(list) {
			list.find('li.' + options.activeClass).removeClass(options.activeClass);
		}
		function mark(item) {
			item.addClass(options.activeClass);
		}
		
		/**
		 * Triggers "change" event on the list.
		 * 
         * @param jQuery list
         * @param mixed * Any number of arguments, which are passed as event parameters
		 */
		function triggerEvent(list) {
			list.trigger("change", [].slice.call(arguments, 1));
		}
	};
	
	$.fn.buttonList.defaults = {
		activeClass: "on",
		multiple: false
	};
	
	/**
	 * Callback to check that the last element would not be unchecked.
	 */
	$.fn.buttonList.lastNotUncheckable = function($li, willBeChecked, $ul, options) {
		if (willBeChecked) {
			return true;
		} else {
			return $ul.find('li.' + options.activeClass).not('.disabled').length > 1;
		}
	};
}(jQuery));
