import clone from 'clone';
import { getRange } from 'common/datetime';
import cachedTime from './cached-time';
import removeExpired from './expiry';
import removeOutOfCacheableRange from './range';

async function cacheForRange(passedCache, from, to, fetchForRange) {
  const cache = removeOutOfCacheableRange(removeExpired(passedCache || {}));
  const dateList = getRange(from, to);

  const missingDates = dateList.filter((date) => cache[date] == null);
  if (missingDates.length === 0) {
    return cache;
  }

  const fetchFrom = missingDates[0];
  const fetchTo = missingDates[missingDates.length - 1];

  const data = await fetchForRange(fetchFrom, fetchTo);
  const cached = cachedTime();

  const dataByDates = {};
  // We fetch by missing dates, not by the dates returned from the Backend.
  // This is due to DEV-58473 - if we have a data bug where one Appointment Group's
  // appointments are on different dates, then that "other" date is also included.
  missingDates.forEach((missingDate) => {
    if (!data[missingDate]) {
      return;
    }
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
    dataByDates[missingDate] = {
      cached,
      data: data[missingDate],
    };
  });

  return {
    ...cache,
    ...dataByDates,
  };
}

const calendarKeys = ['appointments', 'appointmentGroups', 'blocks'];

function getRangeFromCache(passedCache, from, to) {
  const cache = passedCache || {};
  const dateList = getRange(from, to);

  const initialList = calendarKeys.reduce((accum, key) => {
    // eslint-disable-next-line no-param-reassign
    accum[key] = [];
    return accum;
  }, {});

  return dateList.reduce((list, date) => {
    const cacheData = cache[date];

    if (!cacheData) {
      return list;
    }

    const { data } = cacheData;
    calendarKeys.forEach((key) => {
      if (data[key]) {
        list[key].push(...clone(data[key]));
      }
    });

    return list;
  }, initialList);
}

export { cacheForRange, getRangeFromCache };
