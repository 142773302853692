import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { EChart } from 'components/common/LineChart';
import Wahanda from 'common/wahanda';
import { LineChartTooltip } from './utils/LineChartTooltip';
import { LineChartSeries, TooltipSeries, TooltipParams } from './utils/types';
import style from './LineChart.scss';

const tooltipFormatter = (tooltipTotalString, tooltipTextBySeriesName, seriesFormatter) => (
  params,
) => {
  const series: TooltipSeries[] = [];
  for (let i = params.length - 1; i >= 0; i -= 1) {
    const line = params[i];
    series.push({
      text: tooltipTextBySeriesName[line.seriesName],
      value: line.value,
      color: line.color,
    });
  }
  return renderToStaticMarkup(
    <LineChartTooltip
      heading={tooltipTotalString}
      series={series}
      seriesFormatter={seriesFormatter}
    />,
  );
};

export interface LineChartProps {
  xAxisData: string[];
  xAxisFormatter?: (...args: any[]) => any;
  yAxisFormatter?: (...args: any[]) => any;
  tooltipTotalString?: string;
  tooltipTextBySeriesName?: {};
  tooltipFormatter?: (props: TooltipParams[]) => any;
  tooltipSeriesFormatter?: (...args: any[]) => any;
  series: LineChartSeries[];
  actions?: {
    onLegendSelectChanged?: (...args: any[]) => any;
  };
  map?: any;
}

export class LineChart extends React.Component<LineChartProps, {}> {
  public static defaultProps = {
    tooltipFormatter: null,
    tooltipSeriesFormatter: Wahanda.Currency.formatFinancial,
    xAxisFormatter: null,
    yAxisFormatter: null,
    actions: null,
    tooltipTotalString: null,
  };

  private getTooltipFormatter() {
    if (this.props.tooltipFormatter) {
      return this.props.tooltipFormatter;
    }
    return tooltipFormatter(
      this.props.tooltipTotalString,
      this.props.tooltipTextBySeriesName,
      this.props.tooltipSeriesFormatter,
    );
  }

  private getDefaultChartOptions() {
    return {
      tooltip: {
        trigger: 'axis',
        textStyle: {
          color: '#000',
        },
        padding: 0,
        backgroundColor: 'rgba(255,255,255,0.95)',
        extraCssText: 'border-radius: 4px;',
      },
      legend: {
        itemGap: 50,
        bottom: 20,
        textStyle: {
          color: '#808080',
        },
        data: [],
      },
      grid: {
        left: 70,
        right: 70,
        bottom: 100,
      },
      xAxis: {
        axisTick: { show: false },
        axisLabel: {
          color: '#808080',
          fontFamily: '"Source Sans Pro"',
          fontSize: '14',
        },
        axisLine: {
          lineStyle: {
            color: '#b2b2b2',
          },
        },
        axisPointer: {
          type: 'line',
          lineStyle: {
            color: 'rgba(0,0,0,0.13)',
            width: 2,
          },
        },
        boundaryGap: false,
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#808080',
          fontFamily: '"Source Sans Pro"',
          fontSize: '14',
        },
        axisLine: { show: false },
        axisTick: { show: false },
      },
      series: [],
    };
  }

  public render() {
    const stackString = 'stack';
    const options = this.getDefaultChartOptions();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{ axisTick... Remove this comment to see the full error message
    options.xAxis.data = this.props.xAxisData;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'formatter' does not exist on type '{ col... Remove this comment to see the full error message
    options.xAxis.axisLabel.formatter = this.props.xAxisFormatter;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'formatter' does not exist on type '{ col... Remove this comment to see the full error message
    options.yAxis.axisLabel.formatter = this.props.yAxisFormatter;
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: string; type: string; stack: string;... Remove this comment to see the full error message
    options.series = this.props.series.map((series) => ({
      name: series.name,
      type: 'line',
      stack: stackString,
      data: series.data,
      symbolSize: 6,
      lineStyle: {
        color: series.color,
      },
      areaStyle: { normal: { color: series.areaColor, opacity: 0.2 } },
      itemStyle: {
        color: series.color,
        borderColor: series.color,
        borderWidth: 2,
      },
    }));
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'formatter' does not exist on type '{ tri... Remove this comment to see the full error message
    options.tooltip.formatter = this.getTooltipFormatter(this.props);
    for (let i = 0; i < this.props.series.length; i += 1) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: string; data: number[]; areaColor: s... Remove this comment to see the full error message
      options.legend.data[i] = this.props.series[this.props.series.length - i - 1];
    }
    return (
      <EChart
        className={style.graphContainer}
        eChartOptions={options}
        actions={this.props.actions}
      />
    );
  }
}
