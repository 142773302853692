import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProcessOrderDialog from './index';
import { requestCompleteOrderAction } from '../actions';

const mapStateToProps = ({ productOrders }) => ({
  loadingOrderComplete: productOrders.loadingOrderComplete,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      requestCompleteOrderAction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessOrderDialog);
