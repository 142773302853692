import React from 'react';
import Wahanda from 'common/wahanda';
import { Notice } from 'components/common/Notice';
import Dialog from 'components/common/dialog/FullHeightDialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import Loader from 'components/common/Loader';
import { SearchInput } from 'components/common/SearchInput';
import {
  SingleGroup,
  ErrorType,
  GroupPayload,
  ServiceEditPayload,
  ServiceEmployee,
  MenuGroupsPayload,
  ErrorStack,
  Service,
} from './store/types';

import { getCleanedServices, scrollIntoServiceView } from './utils/helpers';
import { formatSuggestedServices, getSuggestions } from './utils/formatSuggestedServices';

import { errorMessages } from './utils/errorMessages';
import { AddServicesModalAnalytics } from './tracking';
import { EmptyState } from './EmptyState';
import { GroupsList } from './GroupsList';
import { ServiceGroups } from './ServiceGroups';
import style from './AddServicesModal.scss';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/common/Modal';
import { Button } from 'components/common/Button';

interface Props {
  onClose: () => void;
  singleGroup?: SingleGroup;
  // redux
  actions: {
    getMenuGroupTemplate: () => void;
    addService: (params: ServiceEditPayload) => void;
    removeService: (params: ServiceEditPayload) => void;
    resetSelectedService: () => void;
    setEmployeesCollection: (params: ServiceEmployee[]) => void;
    setMenuGroupServices: (params: MenuGroupsPayload) => void;
  };
  errorType: ErrorType;
  errorStacks: ErrorStack[];
  groupTypesList: GroupPayload[];
  selectedGroups: GroupPayload[] | [];
  serverError: boolean;
  isSubmitting: boolean;
  hasFreeSku: boolean;
}

export const AddServicesModal = ({
  actions,
  singleGroup,
  errorType,
  errorStacks,
  groupTypesList,
  isSubmitting,
  onClose,
  selectedGroups,
  serverError,
  hasFreeSku,
}: Props) => {
  const [nodeToScroll, setNodeToScroll] = React.useState('');
  const [searchedValue, setSearchedValue] = React.useState('');
  const [showError, setShowError] = React.useState(serverError);
  const hasCheckedGroups = !!Object.values(selectedGroups).length;
  const checkedServicesLength: number = Object.values(selectedGroups).reduce(
    (acc, curr) => acc + curr.services.length,
    0,
  );
  const observer = React.useRef(false);
  const [isDistributionDialogOpen, setIsDistributionDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (!groupTypesList.length) {
      actions.getMenuGroupTemplate();
    }
    AddServicesModalAnalytics.trackAddServicesModalModalView();

    Wahanda.Cache.employees().done((employees) => {
      actions.setEmployeesCollection(employees.toJSON());
    });
  }, []);

  const handleClose = (event) => {
    if (event) {
      AddServicesModalAnalytics.trackCloseClick();
    }
    actions.resetSelectedService();
    onClose();
  };

  React.useEffect(() => {
    if (observer.current) {
      if (serverError) {
        setShowError(serverError);
      }
      if (!isSubmitting && !serverError) {
        handleClose(null);
      }
    } else {
      observer.current = true;
    }
  }, [isSubmitting, serverError]);

  const handleErrorsDisplay = (type: ErrorType) => {
    if (type === ErrorType.SERVER) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  React.useEffect(() => {
    handleErrorsDisplay(errorType);
  }, [errorType]);

  React.useEffect(() => {
    const node = document.getElementById(nodeToScroll);
    scrollIntoServiceView(node);
    if (checkedServicesLength === 0) {
      handleErrorsDisplay(ErrorType.NONE);
    }
  }, [checkedServicesLength]);

  const isSubmitDisabled =
    !hasCheckedGroups || ![ErrorType.EMPLOYEES, ErrorType.NONE].includes(errorType);

  const submit = () => {
    const targetMenuGroupId = singleGroup?.menuGroupId;
    const servicesWithoutEmployees = Number(
      (errorStacks.length / checkedServicesLength).toFixed(2),
    );
    actions.setMenuGroupServices({
      menuGroups: getCleanedServices(selectedGroups),
      targetMenuGroupId,
    });
    AddServicesModalAnalytics.trackSubmitClick({
      property: targetMenuGroupId,
      value: checkedServicesLength,
    });
    AddServicesModalAnalytics.trackEmployeeEmptySubmit(servicesWithoutEmployees);

    if (window.Intercom) {
      window.Intercom('trackEvent', 'new service creation');
      window.Intercom('update');
    }
  };

  const handleSubmit = () => {
    if (hasFreeSku) {
      setIsDistributionDialogOpen(true);
      return;
    }

    submit();
  };

  const onDistributionDialogClose = () => {
    setIsDistributionDialogOpen(false);
  };

  const onDistributionDialogSubmit = () => {
    setIsDistributionDialogOpen(false);
    submit();
  };

  const handleCheckboxChange = (checked, menuGroupId: number, serviceId: number) => {
    const data = { menuGroupId, serviceId };
    if (checked) {
      actions.addService(data);
      AddServicesModalAnalytics.trackAddServicesAddItemClick(serviceId);
      setNodeToScroll(serviceId.toString());
    } else {
      actions.removeService(data);
      AddServicesModalAnalytics.trackAddServicesCheckboxRemoveItemClick(serviceId);
    }
  };

  const handleSearchValueChange = React.useCallback(({ inputValue, items }) => {
    const results = getSuggestions<Service>({
      highlightClass: style.match,
      items,
      inputValue,
    });
    const formattedList = items.map((group) => {
      return {
        ...group,
        services: formatSuggestedServices({
          services: group.services,
          results,
        }),
      };
    });
    return formattedList;
  }, []);

  const suggestedList = React.useMemo(
    () =>
      handleSearchValueChange({
        inputValue: searchedValue,
        items: groupTypesList,
      }),
    [handleSearchValueChange, searchedValue, groupTypesList],
  );

  return (
    <>
      <Dialog
        width="90%"
        classes={{ [style.dialog]: true }}
        maxHeight={window.innerHeight}
        minHeightAsMax
        dialogContentMaxWidth={1200}
        title={Wahanda.lang.menu.multipleServicesImport.header}
        onClose={handleClose}
        noContentPadding
        noContentTopBorders
        keepTopPositionWhenResizing
      >
        {groupTypesList.length ? (
          <>
            <div className={style.container}>
              <div className={style.groups}>
                {!!groupTypesList.length && (
                  <div className={style.searchContainer}>
                    <SearchInput
                      autoFocus
                      name="service-search"
                      dataTest="service-search"
                      onChange={setSearchedValue}
                      fullBorder
                      placeholder={Wahanda.lang.menu.serviceTemplateSelector.placeholder}
                      trackSearchUpdate={AddServicesModalAnalytics.trackSearchUpdate}
                    />
                  </div>
                )}
                <div className={style.groupList}>
                  <GroupsList
                    list={suggestedList}
                    searchedValue={searchedValue}
                    onCheckboxChange={handleCheckboxChange}
                  />
                </div>
              </div>
              <div className={style.content}>
                {showError && <Notice type="alert" message={errorMessages[errorType]} />}
                {hasCheckedGroups ? (
                  <ServiceGroups selectedGroups={selectedGroups} singleGroup={singleGroup} />
                ) : (
                  <EmptyState />
                )}
              </div>
              {isSubmitting && <Loader positionAbsolute />}
            </div>

            <DialogFooter>
              <DialogFooterButton
                dataTest="multiple-services-modal-button-cancel"
                title={Wahanda.lang.shared.buttons.cancel}
                type="secondary"
                onClick={handleClose}
              />
              <DialogFooterButton
                dataTest="multiple-services-modal-button-save"
                disabled={isSubmitDisabled}
                title={Wahanda.lang.shared.buttons.save}
                onClick={handleSubmit}
              />
            </DialogFooter>
          </>
        ) : (
          <Loader positionAbsolute />
        )}
      </Dialog>

      {isDistributionDialogOpen && (
        <ModalContainer onClose={onDistributionDialogClose}>
          <ModalHeader
            title={Wahanda.lang.shared.dialog.doYouWantToContinue}
            onClose={onDistributionDialogClose}
          />
          <ModalBody>
            {Wahanda.lang.menu.offer.purchasability.pricing.errors.freeServicesNotPurchasable}
          </ModalBody>
          <ModalFooter>
            <Button
              label={Wahanda.lang.shared.buttons.cancel}
              colour="plain"
              variant="secondary"
              onClick={onDistributionDialogClose}
            />
            <Button
              colour="default"
              label={Wahanda.lang.shared.saveQuestionTip.buttons.save}
              onClick={onDistributionDialogSubmit}
            />
          </ModalFooter>
        </ModalContainer>
      )}
    </>
  );
};
