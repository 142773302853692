import { addWatcher } from 'common/websockets/state';

// Standard timeout is 60s. So we ping each 45s.
const INTERVAL = 45000;
let timerId;

/**
 * Ping the WS server regularly so that the AWS ELB would not kill this inactive TCP connection.
 *
 * @param {Function} sendCommand The WS Command sender
 */
export default function setup(sendCommand) {
  function queuePing() {
    timerId = window.setTimeout(() => {
      sendCommand('ping');
      queuePing();
    }, INTERVAL);
  }

  function stopPinging() {
    window.clearTimeout(timerId);
  }

  addWatcher(queuePing, stopPinging);
}
