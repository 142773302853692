import React from 'react';
import classnames from 'classnames';
import style from './CovidBanner.scss';
import { Closed, ReopenPending, Reopened } from './State';
import { VenueClosingDialog } from './Dialogs/VenueClosingDialog';
import { VenueOpeningConfirmation } from './Dialogs/VenueOpeningConfirmation';

interface Props {
  hasFullPermissions: boolean;
  canAccessMarketingTools: boolean;
  isClosed: boolean;
  isReopenPending: boolean;
  isReopened: boolean;
  isVenueClosingDialogShown: boolean;
  isVenueOpenConfirmationDialogShown: boolean;
}

export const CovidBanner: React.SFC<Props> = ({
  hasFullPermissions,
  canAccessMarketingTools,
  isClosed,
  isReopenPending,
  isReopened,
  isVenueClosingDialogShown,
  isVenueOpenConfirmationDialogShown,
}) => {
  return (
    <div
      id="covid-banner-wrapper"
      className={classnames(style.wrapper, {
        [style.red]: isClosed,
        [style.yellow]: isReopenPending,
        [style.green]: isReopened,
      })}
    >
      {isClosed && <Closed hasFullPermissions={hasFullPermissions} />}
      {isReopenPending && <ReopenPending hasFullPermissions={hasFullPermissions} />}
      {isReopened && <Reopened canAccessMarketingTools={canAccessMarketingTools} />}
      {isVenueClosingDialogShown && <VenueClosingDialog />}
      {isVenueOpenConfirmationDialogShown && <VenueOpeningConfirmation />}
    </div>
  );
};
