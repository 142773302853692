import React from 'react';
import classnames from 'classnames';

import { Spinner } from 'components/common/Spinner';

import style from './DialogFooterButton.scss';

type DialogFooterButtonType = 'default' | 'pos' | 'alert' | 'secondary' | 'destructive';

export interface DialogFooterButtonProps {
  align?: 'left' | 'right';
  dataTest?: string;
  disabled?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  title: string;
  type?: DialogFooterButtonType;
  size?: 'medium' | 'large';
  onClick: JSX.IntrinsicElements['button']['onClick'];
}

export class DialogFooterButton extends React.Component<DialogFooterButtonProps> {
  public static defaultProps = {
    disabled: false,
    isLoading: false,
    fullWidth: false,
    title: '',
  };

  private loaderStyle = () => {
    const { type } = this.props;
    return ['destructive', 'secondary'].some((buttonType) => buttonType === type);
  };

  public render() {
    const {
      align,
      dataTest,
      disabled,
      isLoading,
      fullWidth,
      size,
      title,
      type,
      onClick,
    } = this.props;

    const mainTypesList = ['default', 'pos', 'alert'];

    const className = classnames(
      style.button,
      style[align || 'left'],
      style[type || 'default'],
      style[size || 'large'],
      {
        [style.isFullWidth]: fullWidth,
        [style.isLoading]: isLoading,
        [style.right]: mainTypesList.includes(type || 'default'),
      },
    );

    return (
      <button
        className={className}
        data-test={dataTest}
        disabled={disabled}
        type="button"
        tabIndex={0}
        onClick={onClick}
      >
        {title}
        {isLoading && (
          <div className={style.spinner}>
            <Spinner dark={this.loaderStyle()} />
          </div>
        )}
      </button>
    );
  }
}
