import {
  INITIAL_STATE_OFFER_PRICING_DISCOUNT_RULE,
  SET_SELECTED_DISCOUNT_RULE,
  GET_DISCOUNT_RULES_RECEIVE,
  GET_DISCOUNT_RULES_FAILURE,
  GET_VENUE_DETAILS_RECEIVE,
  GET_VENUE_DETAILS_FAILURE,
  GET_DISCOUNT_RULE_RECEIVE,
  GET_DISCOUNT_RULE_FAILURE,
  PUT_DISCOUNT_RULE_RECEIVE,
  PUT_DISCOUNT_RULE_FAILURE,
  ADD_DISCOUNT_RULE_RECEIVE,
  ADD_DISCOUNT_RULE_FAILURE,
  PRESENT_DISCOUNT_RULE_DIALOG,
  DISCARD_DISCOUNT_RULE_DIALOG,
  REVERT_DISCOUNT_RULE,
} from 'reduxStore/actionsConst';

export const initialState = {
  hasSaved: false,
  hasError: false,
  discountRules: [],
  venueDetails: {},
  hasDiscountRuleDialog: false,
};

export default function discountRule(state = initialState, action) {
  switch (action.type) {
    case INITIAL_STATE_OFFER_PRICING_DISCOUNT_RULE:
      return Object.assign({}, state, {
        ...action.data,
      });

    case SET_SELECTED_DISCOUNT_RULE:
      return Object.assign({}, state, {
        selectedDiscountRuleId: action.selectedDiscountRuleId,
      });

    case GET_VENUE_DETAILS_RECEIVE:
      return Object.assign({}, state, {
        hasVenueDetails: true,
        venueDetails: {
          ...action.data,
        },
      });

    case GET_VENUE_DETAILS_FAILURE:
      return Object.assign({}, state, {
        hasVenueDetails: false,
        venueDetails: {},
      });

    case GET_DISCOUNT_RULES_RECEIVE:
      return Object.assign({}, state, {
        ...action.data,
      });

    case GET_DISCOUNT_RULES_FAILURE:
      return Object.assign({}, state, {
        ...action.data,
      });

    case GET_DISCOUNT_RULE_RECEIVE:
      return Object.assign({}, state, {
        ...action.data,
        hasMenuDiscount: true,
      });

    case GET_DISCOUNT_RULE_FAILURE:
      return Object.assign({}, state, {
        hasMenuDiscount: false,
      });

    case PUT_DISCOUNT_RULE_RECEIVE:
      return Object.assign({}, state, {
        hasSaved: true,
        hasError: false,
      });

    case PUT_DISCOUNT_RULE_FAILURE:
      return Object.assign({}, state, {
        hasSaved: false,
        hasError: true,
      });

    case ADD_DISCOUNT_RULE_RECEIVE:
      return Object.assign({}, state, {
        hasSaved: true,
        hasError: false,
      });

    case ADD_DISCOUNT_RULE_FAILURE:
      return Object.assign({}, state, {
        hasSaved: false,
        hasError: true,
      });

    case PRESENT_DISCOUNT_RULE_DIALOG:
      return Object.assign({}, state, {
        hasDiscountRuleDialog: true,
      });

    case DISCARD_DISCOUNT_RULE_DIALOG:
      return Object.assign({}, state, {
        hasDiscountRuleDialog: false,
      });

    case REVERT_DISCOUNT_RULE:
      return Object.assign({}, state, {
        hasSaved: false,
        hasError: false,
      });

    default:
      return state;
  }
}
