import {
  GET_MASS_EMAIL_COUNT_REQUEST,
  GET_MASS_EMAIL_COUNT_RECEIVE,
  GET_MASS_EMAIL_COUNT_FAILURE,
} from 'reduxStore/actionsConst';

export const getMassEmailCountRequest = () => ({
  type: GET_MASS_EMAIL_COUNT_REQUEST,
});

export const getMassEmailReceive = ({ count }) => ({
  type: GET_MASS_EMAIL_COUNT_RECEIVE,
  count,
});

export const getMassEmailFailure = (error) => ({
  type: GET_MASS_EMAIL_COUNT_FAILURE,
  error,
});
