import React from 'react';
import Wahanda from 'common/wahanda';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';

import { Button } from 'components/common/Button';
import { StateCard } from 'components/common/StateCard';

import style from './PricingLevelsContainer.scss';

interface Props {
  onNewItemButtonClick: () => {};
}

export class PricingLevelsContainer extends React.Component<Props> {
  public componentDidMount() {
    NavigationAnalytics.trackPageEventView(L2Page.PRICING_LEVELS);
  }

  public render() {
    return (
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.header}>
            <div className={style.title}>{Wahanda.lang.settings.employees.categories.title}</div>
            <Button label={Wahanda.lang.shared.addNew} onClick={this.props.onNewItemButtonClick} />
          </div>
          <ul className="data-list" />
        </div>
        <div className={style.emptyState}>
          <StateCard
            button={{
              text: Wahanda.lang.shared.addNew,
              onClick: this.props.onNewItemButtonClick,
            }}
            heading={Wahanda.lang.shared.list.empty}
            message={Wahanda.lang.settings.employees.categories.description}
          />
        </div>
      </div>
    );
  }
}
