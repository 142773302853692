import Wahanda from 'common/wahanda';

const lang = Wahanda.lang.settings.employees.employee.linkCalendar.uriError;

const employeeExternalCalendarSyncErrors = {
  'employee-external-calendar-processing-failure': () => lang.calendarProcessingFailure,
  'employee-external-calendar-invalid-uri': () => lang.invalidUri,
  'employee-external-calendar-processing-already-in-progress': () => lang.processingInProgress,
  'employee-external-calendar-not-found': () => lang.uriNotEntered,
};

export default employeeExternalCalendarSyncErrors;
