/* global BackboneEx _ */
import { xhr } from 'common/xhr';

/**
 * Venue model.
 */
App.Models.Venue = BackboneEx.Model.Silent.extend({
  /**
   * The ID attribute for this model.
   */
  idAttribute: 'venueId',

  structure: {
    onlineBookingMaxDays: 'integer',
    leadTimeMinutes: 'integer',
    voucherValidityMonths: 'integer',
    maximumAppointmentsPerTimeSlot: 'integer',
    logoImageId: 'integer',
    venueTypeId: 'integer',
    longitude: 'float',
    latitude: 'float',
    gmapScale: 'float',
    gmapLongitude: 'float',
    gmapLatitude: 'float',
    supplierId: 'integer',
    smsReminderHours: 'integer',
    sendSmsReminders: 'boolean',
    emailReminderHours: 'integer',
    sendEmailReminders: 'boolean',
    sendEmailNotifications: 'boolean',
    useOnlineBookingWidget: 'boolean',
    signupTherapistCount: 'integer',
    onlineBookingInterval: 'integer',
    spaDayLeadTimeMinutes: 'integer',
    overnightStayLeadTimeMinutes: 'integer',
    smartDiscountsEnabledOnWidget: 'boolean',
  },

  defaults: {
    // Venue id
    venueId: null,
    // List of relations to include
    relations: [],
  },

  initialize: function initialize() {
    this.nextSaveRelations = null;
  },

  url: function url() {
    const base = '/venue/{{venueId}}.json?include={{relations}}';
    const urlParams = {
      venueId: this.get('venueId'),
      relations: (this.nextSaveRelations || this.get('relations')).join(','),
    };
    this.nextSaveRelations = null;
    // Rendering the final URL
    const wsName = Wahanda.Template.render(base, urlParams);
    return App.Api.wsUrl(wsName);
  },

  /**
   * Returns if the model has been successfully fetched.
   *
   * @return Boolean
   */
  loaded: function loaded() {
    return typeof this.get('name') !== 'undefined';
  },

  /**
   * Sets the opening hours for one day.
   *
   * @param string dayId Day ID, e.g. MONDAY
   * @param string from  If day is disabled, should be `null`
   * @param string to    If day is disabled, should be `null`
   * @param Object options OPTIONAL Options form model's Set method
   */
  setOpeningHours: function setOpeningHours(dayId, from, to, options) {
    const openingHours = this.get('openingHours');
    // clone the values because otherwise comparison does not work well
    const newHours = [];
    for (let i = 0, len = openingHours.length; i < len; i += 1) {
      const day = _.clone(openingHours[i]);
      if (day.dayOfWeek === dayId) {
        day.openingTime = from;
        day.closingTime = to;
      }
      newHours[i] = day;
    }

    this.set('openingHours', newHours, options);
  },

  enableSellingAllOffers: function enableSellingAllOffers(successCallback, errorCallback) {
    const url = App.Api.wsUrl(`/venue/${this.id}/sell-non-featured-offers.json`);
    const data = JSON.stringify({
      voucherValidityMonths: this.get('voucherValidityMonths'),
      openingHours: this.get('openingHours'),
    });

    const self = this;
    const promise = xhr.doJQueryAjax({
      url: url,
      data: data,
      contentType: 'application/json',
      type: 'post',
      success: function success() {
        self.change();
        // eslint-disable-next-line no-unused-expressions
        successCallback && successCallback();
      },
    });

    if (errorCallback) {
      promise.error(errorCallback);
    }
  },

  /**
   * Returns link to external sites the venue is shown.
   *
   * @param string type
   * @return Object or undefined
   */
  getLinkByType: function getLinkByType(type) {
    return _.find(this.get('links') || [], function findLink(link) {
      return link.typeCode === type;
    });
  },

  /**
   * Sets the relations for the next saving of the model.
   *
   * After the model is saved, relations will be reset to the ones set.
   *
   * @param Object relations
   */
  setNextSaveRelations: function setNextSaveRelations(relations) {
    this.nextSaveRelations = relations;
  },

  /**
   * Returns the fulfillment email address.
   * If none exists, returns null.
   *
   * @return String|null
   */
  getFulfillmentAddress: function getFulfillmentAddress() {
    const fc = this.get('fulfillmentCommunication');
    let email = null;

    if (fc && fc.address) {
      email = fc.address;
    }
    return email;
  },

  getLongestWorkDayHours: function getLongestWorkDayHours() {
    const opening = this.get('openingHours');
    let max;
    let diff;
    let day;
    if (opening) {
      for (let i = 0, len = opening.length; i < len; i += 1) {
        day = opening[i];
        if (day.openingTime) {
          diff = Wahanda.Time.getHourDiff(day.openingTime, day.closingTime);
          if (!max || diff > max) {
            max = diff;
          }
        }
      }
    }
    return max > 0 ? max : 8;
  },

  getClosedDays: function getClosedDays() {
    const closedDays = [];
    const opening = this.get('openingHours');
    _.each(opening, function eachDay(day) {
      if (day.openingTime === '' || day.openingTime == null) {
        closedDays.push(day.dayOfWeek);
      }
    });

    return closedDays;
  },

  sortNumber: function sortNumber(a, b) {
    return a - b;
  },

  getCancellationHours: function getCancellationHours(cancellationKey) {
    let presetCancellationHours = [0, 2, 24, 48, 72];
    let currentCancellationHour = this.get(cancellationKey);

    if (currentCancellationHour == null) {
      currentCancellationHour = App.config.getDefaultCancellationPolicyHoursValue(cancellationKey);
    }

    if (currentCancellationHour) {
      presetCancellationHours.push(currentCancellationHour);
      presetCancellationHours.sort(this.sortNumber);
      presetCancellationHours = _.uniq(presetCancellationHours);
    }

    return presetCancellationHours;
  },

  getOpeningHoursRange: function getOpeningHoursRange() {
    const opening = this.get('openingHours');
    const hoursRange = {
      containsMinutes: function containsMinutes(minutes) {
        return minutes >= this.openingMinutes && minutes <= this.closingMinutes;
      },
    };

    if (opening) {
      for (let i = 0, len = opening.length; i < len; i += 1) {
        const day = opening[i];

        if (day.openingTime) {
          const dayOpeningMinutes = Wahanda.Time.timeToMinutes(day.openingTime);

          if (hoursRange.openingMinutes) {
            hoursRange.openingMinutes = Math.min(hoursRange.openingMinutes, dayOpeningMinutes);
          } else {
            hoursRange.openingMinutes = dayOpeningMinutes;
          }
        }

        if (day.closingTime) {
          const dayClosingMinutes = Wahanda.Time.timeToMinutes(day.closingTime);

          if (hoursRange.closingMinutes) {
            hoursRange.closingMinutes = Math.max(hoursRange.closingMinutes, dayClosingMinutes);
          } else {
            hoursRange.closingMinutes = dayClosingMinutes;
          }
        }
      }
    }
    return hoursRange;
  },

  /**
   * Returns a Date object which represents the date at which the latest booking can be done.
   *
   * @return Date
   */
  getLatestBookableDate: function getLatestBookableDate() {
    const now = new Date();
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + (parseInt(this.get('onlineBookingMaxDays'), 10) || 90 - 1),
      23,
      59,
      59,
      0,
    );
  },

  getVisibleImageList: function getVisibleImageList() {
    const images = _.filter(this.get('images'), function filterImg(img) {
      return img.visible;
    });

    if (this.get('logoImage')) {
      images.unshift(this.get('logoImage'));
    }
    return images;
  },
});
