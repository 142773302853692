import Wahanda from 'common/wahanda';

const lang = Wahanda.lang.settings.employees.employee.errors;

const employeeErrors = {
  delete: {
    'employee-in-use': lang.deletingEmployeeInUse,
  },
  name: {
    'employee-name-in-use': lang.duplicateName,
  },
  email: {
    'employee-email-address-in-use': lang.emailInUse,
  },
  takesAppointments: {
    'employee-in-use': lang.deactivatingEmployeeInUse,
  },
};

export default employeeErrors;
