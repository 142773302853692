import { createAction } from 'reduxStore/actionCreator';
import {
  GET_POS_STATUS_REQUEST,
  GET_POS_STATUS_RECEIVE,
  GET_POS_STATUS_FAILURE,
  GET_POS_STATUS_RESET,
} from 'reduxStore/actionsConst';
import { PosStatus } from './types';

export const requestPosStatusAction = () => createAction(GET_POS_STATUS_REQUEST);

export const receivePosStatusAction = (data: PosStatus) =>
  createAction(GET_POS_STATUS_RECEIVE, { data });

export const requestPosStatusFailureAction = (ex: string) =>
  createAction(GET_POS_STATUS_FAILURE, { ex });

export const resetStateAction = () => createAction(GET_POS_STATUS_RESET);
