import { xhr } from 'common/xhr';

export default async function downloadFile({ url, fileName, type }) {
  const response = await xhr.getWithPromise(url);
  const blob = new Blob([response], { type });
  const elem = window.document.createElement('a');
  elem.href = window.URL.createObjectURL(blob);
  elem.download = fileName;
  elem.dispatchEvent(new MouseEvent('click'));
}
