import React from 'react';
import { get } from 'mori';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';

const LANG_SHARED = Wahanda.lang.dashboard.trafficLights.shared;

function getButton(lang, data) {
  return (
    <a
      className="link-button"
      href={get(data, 'link')}
      onClick={get(data, 'onClick')}
      target="_blank"
      rel="noopener noreferrer"
    >
      {get(lang, 'buttonText')}
    </a>
  );
}

interface ICriteriaProps extends React.HTMLAttributes<Element> {
  criteria?: {};
}

export default class Criteria extends React.Component<ICriteriaProps, {}> {
  render() {
    const { criteria } = this.props;
    const lang = get(criteria, 'lang');
    const threshold = get(criteria, 'threshold');
    const currentValue = get(criteria, 'currentValue');
    const checked = get(criteria, 'checked');
    // No buttons for completed criteria
    const buttonData = get(criteria, 'button');
    const text = Wahanda.Template.render(get(lang, 'text'), {
      threshold,
      currentValue,
    });
    const moreInfo = get(lang, 'moreInfoLink');
    const moreInfoComponent = moreInfo ? (
      <a
        href={moreInfo}
        onClick={get(criteria, 'onMoreInfoClick')}
        target="_blank"
        rel="noopener noreferrer"
      >
        {LANG_SHARED.criteriaMoreInfo}
      </a>
    ) : null;
    return (
      <div
        className={classnames('subblock criteria icon-smaller lighter-border', {
          'icon-ok': checked,
          'icon-blank': !checked,
        })}
      >
        <div className="title">{get(lang, 'title')}</div>
        <div className="sub-content">
          {text}
          {moreInfoComponent ? ' ' : null}
          {moreInfoComponent}
        </div>
        {!checked && get(buttonData, 'link') && get(buttonData, 'hasPermission')
          ? getButton(lang, buttonData)
          : null}
      </div>
    );
  }
}
