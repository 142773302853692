import React from 'react';
import Wahanda from 'common/wahanda';
import { RichTextEditor } from 'components/common/RichTextEditor';

const LANG = Wahanda.lang.settings.microsite;
interface IImpressumProps extends React.HTMLAttributes<Element> {
  impressumText?: string;
  actions: {
    changeImpressumText: (...args: any[]) => any;
  };
  changeImpressumText?: any;
}
class Impressum extends React.Component<IImpressumProps, {}> {
  static defaultProps = {
    impressumText: '',
  };

  renderTextEditor = () => (
    <RichTextEditor
      onTextChanged={this.props.actions.changeImpressumText}
      placeholder={LANG.placeholder.editImpressum}
      text={this.props.impressumText}
    />
  );

  render() {
    return this.renderTextEditor();
  }
}

export default Impressum;
