import { trackEvent, ACTIONS } from 'common/analytics';

export class CalendarAnalytics {
  public static CATEGORY = 'calendar';

  public static trackUDVServiceDurationInputClick() {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.CLICK, 'durations-dialog-show');
  }

  public static trackUDVServiceDurationDialogCloseClick() {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.CLICK, 'durations-dialog-hide');
  }

  public static trackUDVServiceDurationSelectChange(property: string) {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.CLICK, 'udv-duration-change', property);
  }
}
