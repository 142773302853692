import React from 'react';
import Wahanda from 'common/wahanda';
import { Facebook, Instagram, Messenger, CautionFilled, External } from 'components/common/Icon';
import { FacebookBusinessExtensionAnalytics as Analytics } from './tracking';
import style from './BookingChannels.scss';

const lang = Wahanda.lang.marketing.facebookBusinessExtension.channels;
const getFbSettingsLink = (pageId) =>
  `https://www.facebook.com/${pageId}/settings/?tab=instagram_management`;

export interface Props {
  facebookPageId?: string;
  venueId: number;
  timezone: string;
  currency: string;
  completeFBEReintegration: () => void;
  businessConfig: {
    business: {
      name: string;
    };
    ig_cta: {
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
    };
    messenger_menu: {
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
    };
    page_cta: {
      below_button_text: string;
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
    };
    page_post: {
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
      subtitle: string;
      title: string;
    };
  };
}

export const BookingChannelsWithWarnings: React.FC<Props> = ({
  facebookPageId,
  venueId,
  timezone,
  currency,
  businessConfig,
  completeFBEReintegration,
}) => {
  const getInstagram = () => {
    const fbPageLink = getFbSettingsLink(facebookPageId);
    const handleReinstallClick = () => {
      Analytics.trackReintegrateStepThree();
      FB.login(
        (response) => {
          if (response.authResponse) {
            completeFBEReintegration();
          }
        },
        {
          scope: 'manage_business_extension',
          // @ts-expect-error ts-migrate(2345) FIXME: Object literal may only specify known properties, ... Remove this comment to see the full error message
          extras: {
            setup: {
              external_business_id: venueId.toString(),
              timezone,
              currency,
              business_vertical: 'SERVICES',
            },
            business_config: businessConfig,
            repeat: false,
          },
        },
      );
    };
    return (
      <div className={style.wrapper} data-test="ig-warning">
        <div className={style.igWarningContainer}>
          <div className={style.icon}>
            <Instagram size="large" />
          </div>
          <div>
            <div className={style.name}>{lang.instagram}</div>
            <div className={style.description}>{lang.instagramDescriptionWarning}</div>
          </div>
          <CautionFilled className={style.warningIcon} />
          <div />

          <div className={style.stepsContainer}>
            <ul>
              <li className={style.step}>
                <span className={style.number}>1.</span>
                <a
                  href={fbPageLink}
                  className={style.stepLink}
                  target="_blank"
                  rel="noreferrer"
                  data-test="fb-settings-url-1"
                  onClick={Analytics.trackReintegrateStepOne}
                >
                  {lang.instagramStepOne} <External size="small" className={style.linkIcon} />
                </a>
              </li>
              <li className={style.step}>
                <span className={style.number}>2.</span>
                <a
                  href={fbPageLink}
                  rel="noreferrer"
                  className={style.stepLink}
                  target="_blank"
                  data-test="fb-settings-url-2"
                  onClick={Analytics.trackReintegrateStepTwo}
                >
                  {lang.instagramStepTwo}
                  <External size="small" className={style.linkIcon} />
                </a>
              </li>
              <li className={style.step}>
                <span className={style.number}>3.</span>
                <a
                  onClick={handleReinstallClick}
                  className={style.stepLink}
                  data-test="fb-settings-url-3"
                >
                  {lang.instagramStepThree} <External size="small" className={style.linkIcon} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className={style.wrapper}>
        <div className={style.icon}>
          <Facebook className={style.fbBlue} size="large" />
        </div>
        <div className={style.textBox}>
          <div className={style.name}>{lang.facebook}</div>
          <div className={style.description}>{lang.facebookDescription}</div>
        </div>
      </div>

      {getInstagram()}

      <div className={style.wrapper}>
        <div className={style.icon}>
          <Messenger size="large" />
        </div>
        <div className={style.textBox}>
          <div className={style.name}>{lang.messenger}</div>
          <div className={style.description}>{lang.messengerDescription}</div>
        </div>
      </div>
    </>
  );
};
