import React from 'react';
import { Portal } from 'react-portal';
import classnames from 'classnames';
import delay from 'lodash/delay';
import debounce from 'lodash/debounce';

import { Close } from 'components/common/Icon';
import { PopoverBody } from 'components/common/Popover';

import style from './Popover.scss';

export const ARROW_SIZE = 8;

export const ARROW_NUDGE = 20;

export enum PopoverPlacement {
  topEnd = 'topEnd',
  topStart = 'topStart',
  bottomEnd = 'bottomEnd',
  bottomStart = 'bottomStart',
  leftTop = 'leftTop',
  leftBottom = 'leftBottom',
  rightTop = 'rightTop',
  rightBottom = 'rightBottom',
}

export interface PopoverAnchorOffset {
  top: number;
  left: number;
  width?: number;
  minWidth?: number;
  height?: number;
}

interface Props {
  children?: React.ReactNode;
  placement?: PopoverPlacement;
  offset: PopoverAnchorOffset;
  open: boolean;
  onClose: () => void;
  hideOnOutsideClick?: boolean;
  hideOnScroll?: boolean;
  showClose?: boolean;
  noPadding?: boolean;
  autoWidth?: boolean;
}

export const Popover = (props: Props) => {
  const {
    children,
    hideOnOutsideClick = true,
    hideOnScroll = true,
    placement,
    offset,
    onClose,
    showClose = true,
    noPadding,
    autoWidth,
    open,
  } = props;
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const [containerPosition, setContainerPosition] = React.useState<PopoverAnchorOffset>({
    top: 0,
    left: 0,
  });
  const arrowClassNames = classnames(style.arrow, style[placement || PopoverPlacement.rightTop]);

  const checkOutsideClick = (e: any & { target: any }) => {
    const { current } = popoverRef;
    if (hideOnOutsideClick && current && !current.contains(e.target)) {
      onClose();
    }
  };

  const checkScroll = () => {
    if (hideOnScroll && popoverRef && popoverRef.current) {
      onClose();
    }
    if (!hideOnScroll && open && offset.top != null) {
      setPositioningStyles();
    }
  };

  const getPositioningStyle = React.useCallback(
    (element) => {
      const { top, left, width = 0, height = 0 } = offset;
      const contentWidth = element.offsetWidth;
      const contentHeight = element.offsetHeight;

      switch (placement) {
        case PopoverPlacement.topStart:
          return {
            top: top - contentHeight - ARROW_NUDGE + ARROW_SIZE,
            left: left + width / 2 - ARROW_NUDGE - ARROW_SIZE / 2,
          };
        case PopoverPlacement.topEnd:
          return {
            top: top - contentHeight - ARROW_NUDGE + ARROW_SIZE,
            left: left - contentWidth + width / 2 + ARROW_NUDGE + ARROW_SIZE / 2,
          };
        case PopoverPlacement.bottomStart:
          return {
            top: top + height + ARROW_SIZE,
            left: left + width / 2 - ARROW_NUDGE - ARROW_SIZE / 2,
          };
        case PopoverPlacement.bottomEnd:
          return {
            top: top + height + ARROW_SIZE,
            left: left - contentWidth + width / 2 + ARROW_NUDGE + ARROW_SIZE / 2,
          };
        case PopoverPlacement.leftTop:
          return {
            top: top + height / 2 - ARROW_NUDGE,
            left: left - contentWidth - ARROW_SIZE - ARROW_SIZE / 2,
          };
        case PopoverPlacement.leftBottom:
          return {
            top: top + height / 2 - contentHeight + ARROW_NUDGE,
            left: left - contentWidth - ARROW_SIZE - ARROW_SIZE / 2,
          };
        case PopoverPlacement.rightBottom:
          return {
            top: top + height / 2 - contentHeight + ARROW_NUDGE,
            left: left + width + ARROW_SIZE,
          };
        case PopoverPlacement.rightTop:
          return {
            top: top + height / 2 - ARROW_NUDGE,
            left: left + width + ARROW_SIZE,
          };
        default:
          return {
            top: 0,
            left: 0,
          };
      }
    },
    [offset],
  );

  const setPositioningStyles = React.useCallback(() => {
    const element = popoverRef.current;

    if (!element) {
      return;
    }
    const positioning = getPositioningStyle(element);

    if (positioning?.top != null && positioning?.left != null) {
      setContainerPosition(positioning);
    }
  }, [getPositioningStyle]);

  React.useEffect(() => {
    // Delay needed for Shift initiliaser that are used in Calendar
    delay(() => {
      document.addEventListener('click', checkOutsideClick);
      document.addEventListener('scroll', checkScroll, true);
    }, 200);
    return () => {
      document.removeEventListener('click', checkOutsideClick);
      document.removeEventListener('scroll', checkScroll, true);
    };
  }, []);

  React.useEffect(() => {
    if (open && offset.top) {
      delay(() => {
        setPositioningStyles();
      }, 100);
    }
  }, [open, offset]);

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }

    const handleResize = debounce(() => {
      setPositioningStyles();
    }, 200);

    window.addEventListener('resize', handleResize);
    return () => {
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, [open, setPositioningStyles]);

  return (
    <Portal>
      <div
        ref={popoverRef}
        className={classnames(style.container, { [style.autoWidth]: autoWidth })}
        style={{
          ...containerPosition,
          ...(containerPosition && containerPosition.top
            ? {}
            : { visibility: 'hidden', opacity: 0 }),
        }}
      >
        <PopoverBody noPadding={noPadding} shadow="large">
          <div className={arrowClassNames} />
          {showClose && (
            <a className={style.closeIcon} role="button" onClick={onClose} tabIndex={-1}>
              <Close />
            </a>
          )}
          <div className={style.content}>{children}</div>
        </PopoverBody>
      </div>
    </Portal>
  );
};

Popover.displayName = 'Popover';
