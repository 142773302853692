/* eslint-disable func-names */
/* global _ */

import { BOOKING_ACTOR } from '../../../../../javascripts-es6/common/consts';

(function () {
  const proto = App.Views.Forms.MenuOffer2.prototype;

  proto.isBeingSaved = false;

  proto.afterSaveCallback = function (callback) {
    this.afterSavecallbacks.push(callback);
  };

  proto.save = function (isCopy) {
    const self = this;
    const isNewOffer = !this.model.id;

    if (this.isBeingSaved) {
      return;
    }

    if (this.forcedStatus === 'archive') {
      this.model.set('visible', false);
      this.model.set('featured', false);
    } else {
      this.model.set('visible', true);
    }

    if (this.model.isPending()) {
      this.model.set('visible', false);
      this.model.set('pending', true);
    }

    if (this.model.isServicePackage()) {
      // We need to save the hidden offers first
      self.disableForm();
      self.isBeingSaved = true;
      saveHiddenServices();
    } else {
      save();
    }

    function saveHiddenServices() {
      const hiddenServices = _.filter(self.model.get('subServices'), function (service) {
        return service.tempId;
      });
      if (hiddenServices.length === 0) {
        save();
        return;
      }

      const employees = self.options.employees.getIds({
        takesAppointments: true,
      });

      function instantiateOffer(hiddenService) {
        const offer = new App.Models.MenuOffer();
        const sku = {
          duration: hiddenService.duration,
          durationTypeCode: 'MI',
          fullPriceAmount: hiddenService.price,
          nameInherited: true,
        };
        offer.set({
          name: hiddenService.name,
          venueId: App.getVenueId(),
          employees: employees,
          skus: [sku],
          fulfillmentTypes: { appointment: true },
          menuItemTypeCode: 'T',
          primaryTreatmentId: hiddenService.primaryTreatmentId,
          groupId: self.model.get('groupId'),
          distributionChannels: ['SUPPLIER'],
        });
        return offer;
      }

      function saveInlineAddedOffersInSeries() {
        let currentIndex = 0;
        const savedList = [];

        function saveNext() {
          const offerData = hiddenServices[currentIndex];
          currentIndex += 1;

          if (offerData) {
            const offer = instantiateOffer(offerData);
            offer.save().done(saveNext);

            savedList.push(offer);
          } else {
            whenAllSaved(savedList);
          }
        }

        saveNext();
      }

      function whenAllSaved(savedOffers) {
        const fetchingHiddenServices = _.map(savedOffers, function (service) {
          const offer = new App.Models.MenuOffer();
          offer.set({
            id: service.id,
            venueId: App.getVenueId(),
          });
          return offer.fetch();
        });

        $.when.apply($, fetchingHiddenServices).done(function () {
          const savedServices = [];

          _.each(arguments, function (response) {
            let service;
            if (Array.isArray(response)) {
              service = response[0];
            } else {
              service = response;
            }
            if (service.id) {
              savedServices.push({
                serviceId: service.id,
                primaryTreatmentId: service.primaryTreatmentId,
                name: service.name,
                skus: [
                  {
                    fullPriceAmount: service.skus[0].fullPriceAmount,
                    offerId: service.id,
                    id: service.skus[0].id,
                    duration: service.skus[0].duration,
                  },
                ],
              });
            }
          });
          const existingServices = _.filter(self.model.get('subServices'), function (service) {
            return service.tempId == null;
          });

          const masterService = _.find(self.model.get('subServices'), function (service) {
            return service.master === true;
          });

          if (masterService.tempId) {
            // A inline created service has been designated master
            _.each(savedServices, function (service) {
              if (
                masterService.name === service.name &&
                masterService.primaryTreatmentId === service.primaryTreatmentId.toString() &&
                masterService.price === service.skus[0].fullPriceAmount.toString() &&
                masterService.duration === service.skus[0].duration.toString()
              ) {
                service.master = true;
              }
            });
          }

          const services = existingServices.concat(savedServices);
          self.model.set('subServices', services);
          self.model.set('hiddenServices', savedServices);

          save();
        });
      }

      // Execute saving the inline-created offers one by one.
      // This is done due to DEV-48928 which prevents us from saving them in parallel.
      saveInlineAddedOffersInSeries();
    }

    function save() {
      self.model.set(self.getValues());
      if (self.forcedStatus === 'archive') {
        self.model.set('featured', false);
      }
      self.disableForm();

      self.isBeingSaved = true;

      const saveAction = function () {
        // Call parent's save
        self
          .saveAction()
          .done(onSaved)
          // .fail(onFailed)
          .always(cleanupTasks);
      };

      function hasNewSku() {
        return _.any(self.model.get('skus'), function (sku) {
          return !sku.id;
        });
      }

      function wasSkuRemoved() {
        const prevSkus = self.model.previous('skus');
        const newSkus = self.model.get('skus');

        return prevSkus.length > newSkus.length;
      }

      const data = self.model.getPurchasabilityData({
        employees: self.options.employees,
      });

      if (self.model.hasEnclosingPackages()) {
        // Errors which prevent saving
        let errorString;
        if (
          !self.model.get('visible') &&
          self.model.hasChanged('visible') &&
          self.model.hasEnclosingActivePackages(self.menu.get('offersCollection'))
        ) {
          errorString = Wahanda.lang.menu.offer.errors.packageArchive;
        } else if (self.model.hasChanged('fulfillmentTypes')) {
          errorString = Wahanda.lang.menu.offer.errors.packageFulfillmentType;
        }
        if (errorString) {
          self.isBeingSaved = false;
          self.enableForm();
          self.showError(errorString);
          self.model.revert();
          return;
        }

        // Notifications?
        if ((hasNewSku() || wasSkuRemoved()) && !data.packageWarning) {
          self.afterSaveCallback(function () {
            App.Views.Dialog.MenuEnclosedOfferChangeNotification.open(
              self.model.get('enclosingPackages'),
            );
          });
        }
      }

      const suggestEnablingOnMarketplace =
        self.model.get('hadFreeSkuWhenLoaded') &&
        !self.model.hasFreeSku() &&
        !self.model.hasDistributionChannel('CUSTOMER');

      if (suggestEnablingOnMarketplace) {
        self.markButtonAsDisabled();
        self.showSuggestEnablingOnMarketplace(data, saveAction);
      } else if (!data.hasErrors) {
        self.removeMessage('error');
        self.markButtonAsProcessing();
        saveAction();
      } else if (data.errorMessage) {
        self.showMessage('error', data.errorMessage);
        self.isBeingSaved = false;
        self.enableForm();
      } else if (data.noEmployeeAssigned) {
        const lang = Wahanda.lang.menu.offer.purchasability.noEmployeesAssigned;

        App.Views.Dialog.ValidationError.open(lang.heading, lang.text);

        self.isBeingSaved = false;
        self.enableForm();
      } else if (data.packageWarning) {
        self.showPackageWarningTooltip(data, saveAction, cleanupTasks);
      } else {
        self.markButtonAsDisabled();
        self.showNotPurchasableTooltip(data, saveAction, cleanupTasks);
      }
    }

    function onSaved() {
      const eventData = {
        customStatus: self.forcedStatus,
        pricingType: self.model.getPricingType(),
      };

      if (isNewOffer && App.config.isMenuServiceTemplatesEnabled() && self.model.isTreatment()) {
        eventData.fromTemplate = !!self.model.isFromTemplate;
      }

      /*
       *  For analytics,
       *  to distinguish between 'closed post save' and 'abandoned ante save'
       */
      self.hasBeenSaved = true;
      self.archived = self.forcedStatus === 'archive';

      App.trigger(Wahanda.Event.MENU_OFFER_SAVED, self, {
        ...eventData,
        isCopy,
      });

      self.close();

      _.each(self.afterSavecallbacks, function (cb) {
        cb();
      });
    }

    function cleanupTasks() {
      self.isBeingSaved = false;
      self.afterSavecallbacks = [];
      self.cleanButtonActions();
      self.enableForm();
    }
  };

  proto.saveErrorText = function () {
    return Wahanda.lang.menu.offer.errors.saveFailed;
  };

  const onlyPricingError = null;

  function getNotPurchaseableTooltipHtml(data) {
    const list = [];

    for (const channel in { treatwell: 1, widget: 1, all: 1, pricing: 1 }) {
      if (data.errors[channel].length > 0) {
        let titleId = '';
        switch (channel) {
          case 'all':
            titleId = 'allNotPurchasable';
            break;

          case 'treatwell':
            titleId = !data.sitePurchasable ? 'notPurchasable' : 'skusNotPurchasable';
            break;

          case 'widget':
            titleId = !data.widgetPurchasable ? 'notPurchasable' : 'skusNotPurchasable';
            break;

          case 'pricing':
            titleId = 'pricingRangeProblem';
            break;
        }
        const languageBase = Wahanda.lang.menu.offer.purchasability[channel];
        const errorTexts = [];
        for (const i in data.errors[channel]) {
          const errorId = data.errors[channel][i];
          if (typeof errorId === 'string') {
            errorTexts.push({ description: languageBase.errors[errorId] });
          } else {
            errorTexts.push({
              description: Wahanda.Template.render(
                languageBase.errors[errorId.errorId],
                errorId.vars,
              ),
            });
          }
        }
        list.push({
          title: Wahanda.Template.render(Wahanda.lang.menu.offer.purchasability.texts[titleId], {
            channel: languageBase.channelName,
          }),
          errors: errorTexts,
        });
        this.onlyPricingError = this.onlyPricingError === null && channel === 'pricing';
      }
    }

    return Wahanda.Template.renderTemplate('offer-not-purchasable-tooltip', {
      list: list,
    });
  }

  proto.showPackageWarningTooltip = function (data, saveAction, cleanupTasks) {
    const packages = this.model.get('packageNames');
    let html = Wahanda.Template.renderTemplate('offer-package-warning', {
      packages: this.model.get('enclosingPackages'),
    });

    if (
      _.any(data.errors, function (errArr) {
        return errArr.length > 0;
      })
    ) {
      html += getNotPurchaseableTooltipHtml(data);
    }

    const $tooltip = this.$('.js-save').dialog2SaveQuestionTip({
      htmlContent: html,
      primaryButtonText: Wahanda.lang.shared.save,
      primaryButtonEnabled: true,
      saveAction: saveAction,
      cancelAction: cleanupTasks,
    });
  };

  proto.showNotPurchasableTooltip = function (data, saveAction, cleanupTasks) {
    const html = getNotPurchaseableTooltipHtml(data);

    const saveButtonId = !data.sitePurchasable && !data.widgetPurchasable ? 'save' : 'savePartial';

    const self = this;
    let wentToSettings = false;
    const cancelCallback = function () {
      if (wentToSettings) {
        // Refetch the collection
        self.options.employees.fetch({
          success: cleanupTasks,
        });
      } else {
        cleanupTasks();
      }
    };

    const $tooltip = this.$('.js-save').dialog2SaveQuestionTip({
      htmlContent: html,
      primaryButtonText: Wahanda.lang.menu.offer.purchasability.buttons[saveButtonId],
      primaryButtonEnabled: !this.model.get('featured') || this.onlyPricingError,
      saveAction: saveAction,
      cancelAction: cancelCallback,
    });

    $tooltip.find('.a-employee-settings').one('click', function () {
      // Binding on 'Go to settings', to refresh the employee collection
      wentToSettings = true;
    });
  };

  proto.showSuggestEnablingOnMarketplace = function (data, saveAction) {
    const html = Wahanda.Template.renderTemplate('offer-suggest-enabling-on-marketplace', {
      title: Wahanda.lang.menu.offer.purchasability.texts.sellServiceOnline.title,
      message: Wahanda.lang.menu.offer.purchasability.texts.sellServiceOnline.message,
    });
    const self = this;

    const setDistributionAndSave = () => {
      self.model.set('distributionChannels', [
        BOOKING_ACTOR.CUSTOMER,
        BOOKING_ACTOR.WIDGET,
        BOOKING_ACTOR.SUPPLIER,
      ]);
      self.markButtonAsProcessing();
      saveAction();
    };

    const skipDistributionAndSave = () => {
      self.markButtonAsProcessing();
      saveAction();
    };

    this.$('.js-save').dialog2SaveQuestionTip({
      htmlContent: html,
      primaryButtonText: Wahanda.lang.shared.yes,
      secondaryButtonText: Wahanda.lang.shared.no,
      primaryButtonEnabled: true,
      saveAction: setDistributionAndSave,
      cancelAction: skipDistributionAndSave,
    });
  };
})();
