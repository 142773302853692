import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import {
  Table,
  TableColumnHeader,
  TableRow,
  TableBody,
  TableHeader,
  TableData,
} from 'components/common/Table';
import style from './style.scss';

export interface ClientAcquisitionMonthData {
  month: string;
  directFirstTimeClients: number;
  widgetFirstTimeClients: number;
  treatwellFirstTimeClients: number;
  totalFirstTimeClients: number;
}

export interface Props {
  months: ClientAcquisitionMonthData[];
}

export default class ClientAcquisitionTable extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.renderReportRow = this.renderReportRow.bind(this);
  }

  public render() {
    const months = this.props.months.sort((m1, m2) =>
      moment(m2.month).diff(moment(m1.month), 'day'),
    );
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableColumnHeader className={style.borderRight}>
              {Wahanda.lang.reports.clientReports.clientRetention.headers.month}
            </TableColumnHeader>
            <TableColumnHeader className={classnames(style.textAlignRight, style.borderRight)}>
              {Wahanda.lang.reports.sales.filters.bookingChannel.treatwell}
            </TableColumnHeader>
            <TableColumnHeader className={classnames(style.textAlignRight, style.borderRight)}>
              {Wahanda.lang.reports.sales.filters.bookingChannel.widget}
            </TableColumnHeader>
            <TableColumnHeader className={classnames(style.textAlignRight, style.borderRight)}>
              {Wahanda.lang.reports.sales.filters.bookingChannel.connect}
            </TableColumnHeader>
            <TableColumnHeader className={classnames(style.textAlignRight, style.borderRight)}>
              {Wahanda.lang.reports.clientReports.clientRetention.headers.total}
            </TableColumnHeader>
          </TableRow>
        </TableHeader>
        <TableBody>{months.map(this.renderReportRow)}</TableBody>
      </Table>
    );
  }

  public renderReportRow(reportRowData: ClientAcquisitionMonthData) {
    const {
      treatwellFirstTimeClients,
      widgetFirstTimeClients,
      directFirstTimeClients,
      totalFirstTimeClients,
      month,
    } = reportRowData;
    const dateFormat = Wahanda.lang.reports.clientReports.clientRetention.headers.dateFormat;
    return (
      <TableRow key={month}>
        <TableData className={style.borderRight}>{moment(month).format(dateFormat)}</TableData>
        <TableData className={classnames(style.textAlignRight, style.borderRight)}>
          {treatwellFirstTimeClients}
        </TableData>
        <TableData className={classnames(style.textAlignRight, style.borderRight)}>
          {widgetFirstTimeClients}
        </TableData>
        <TableData className={classnames(style.textAlignRight, style.borderRight)}>
          {directFirstTimeClients}
        </TableData>
        <TableData className={classnames(style.textAlignRight, style.borderRight)}>
          {totalFirstTimeClients}
        </TableData>
      </TableRow>
    );
  }
}
