import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Luggage extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-luggage', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 48 48"
      >
        <path d="M27 0c2.21 0 4 1.79 4 4v7l-12-.001v2.571l2.42 2.432c.332.333.534.77.575 1.236l.008.175v5.506c0 1.054-.816 1.918-1.851 1.995l-.15.005H16c-1.054 0-1.918-.816-1.995-1.85l-.005-.15V17.41c0-.467.164-.918.46-1.275l.117-.13 1.172-1.187 1.423 1.405L16 17.41v5.509h4.003v-5.506L17 14.396V11L9 11c-1.598 0-2.904 1.249-2.995 2.824L6 14v23c0 1.598 1.249 2.904 2.824 2.995L9 40h30c1.598 0 2.904-1.249 2.995-2.824L42 37V14c0-1.598-1.249-2.904-2.824-2.995L39 11h-4V9h4c2.689 0 4.882 2.122 4.995 4.783L44 14v23c0 2.609-1.998 4.751-4.548 4.98.349.592.548 1.283.548 2.02 0 2.209-1.79 4-4 4s-4-1.791-4-4c0-.729.195-1.412.535-2h-17.07c.34.588.535 1.271.535 2 0 2.209-1.79 4-4 4s-4-1.791-4-4c0-.737.2-1.428.547-2.021-2.478-.221-4.435-2.252-4.542-4.762L4 37V14c0-2.689 2.122-4.882 4.783-4.995L9 9l8-.001v-5c0-2.208 1.79-4 4-4h6zm9 42c-1.105 0-2 .895-2 2 0 1.104.895 2 2 2s2-.896 2-2c0-1.105-.895-2-2-2zm-24 0c-1.105 0-2 .895-2 2 0 1.104.895 2 2 2s2-.896 2-2c0-1.105-.895-2-2-2zm7-38v4.999h10v-5c0-1.104-.895-2-2-2h-6c-1.105 0-2 .896-2 2zM5 34h38v2H5v-2z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Luggage);
