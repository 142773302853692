import React from 'react';

interface ISelectProps extends React.HTMLAttributes<Element> {
  value?: string;
  name?: string;
  options?: any[];
  onChange?: (...args: any[]) => any;
  map?: any;
}
type SelectState = {
  value?: any;
  disabled?: boolean;
};

// <SelectElement options={employees} value={2} name="employee" onChange={this.onSelectChange} />
/**
 *  Wrapped HTML <select> element
 *
 *  @param {String} name Name of the select to use, optional
 *  @param {Array} options List of objects to render. Form is { text, value }
 *  IMPORTANT: the value must be a string
 *  @param {String} value Currently selected value, optional
 *  @param {function} onChange Callback to call when the value is changed
 */
export default class Select extends React.Component<ISelectProps, SelectState> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'select' has no initializer and is not de... Remove this comment to see the full error message
  select: HTMLSelectElement;

  state = {
    value: this.props.value,
    disabled: false,
  };

  UNSAFE_componentWillReceiveProps({ value }) {
    if (this.state.value !== value) {
      this.setState({ value });
    }
  }

  onChange = (event) => {
    const { value } = event.target;
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  findOptionByValue(value) {
    let selectedKey = 0;
    // Need to review this as we may want to iterate over this.props.options.values
    // TODO: Review for eslint
    for (const i in this.props.options) {
      //eslint-disable-line
      if (value === this.props.options[i].value) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
        selectedKey = i;
        break;
      }
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return this.props.options[selectedKey];
  }

  render() {
    const disabled = this.state.disabled;
    const classNames = `select-wrap react${disabled ? ' disabled' : ''}`;
    return (
      <div className={classNames}>
        <select
          name={this.props.name}
          style={{ zIndex: 2 }}
          onChange={this.onChange}
          value={this.state.value}
          ref={(select) => {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLSelectE... Remove this comment to see the full error message
            this.select = select;
          }}
        >
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          {this.props.options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    );
  }
}
