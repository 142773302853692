import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Star extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-star', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M12,15.46191 L16.6147415,18.7516406 L14.9114055,13.3441153 L19.4541616,10.000807 L13.7762094,10.000807 L12,4.60055283 L10.2237906,10.000807 L4.54583836,10.000807 L9.08859453,13.3441153 L7.38525855,18.7516406 L12,15.46191 Z M12,17.9180775 L6.93371665,21.5297008 C5.70026546,22.4089965 4.42729797,21.4853016 4.88270042,20.0395503 L6.75134086,14.1072382 L1.72035238,10.4046081 C0.502026037,9.50796293 0.976861271,8.00080696 2.49703014,8.00080696 L8.77620936,8.00080696 L10.7384924,2.03482843 C11.2128787,0.592539745 12.7907481,0.603566261 13.2615076,2.03482843 L15.2237906,8.00080696 L21.5029699,8.00080696 C23.0321476,8.00080696 23.4967113,9.50889217 22.2796476,10.4046081 L17.2486591,14.1072382 L19.1172996,20.0395503 C19.5724021,21.4843494 18.3005475,22.409576 17.0662833,21.5297008 L12,17.9180775 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Star);
