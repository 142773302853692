import React, { Component } from 'react';
import Wahanda from 'common/wahanda';
import UnderlinedInput from 'components/common/UnderlinedInput/withFormsy';
import DialogContentBox from 'components/common/dialog/DialogContentBox';
import AutogrowTextarea from 'react-textarea-autosize';
import { Notice } from 'components/common/Notice';
import style from './style.scss';

const lang = Wahanda.lang.settings.employees.employee;
interface IPublicProfileTabProps extends React.HTMLAttributes<Element> {
  jobTitle?: string;
  notes?: string;
  actions: {
    setEmployeeDataAction: (...args: any[]) => any;
  };
  setEmployeeDataAction?: any;
}

const maxLengthError = ({ value }: { value: number }) => ({
  maxLength: Wahanda.lang.validate.name.maxlength.replace('{0}', value.toString()),
});

export default class PublicProfileTab extends Component<IPublicProfileTabProps, {}> {
  static defaultProps = {
    jobTitle: '',
    notes: '',
  };

  onChange = (event) => {
    this.props.actions.setEmployeeDataAction({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { jobTitle, notes } = this.props;
    return (
      <DialogContentBox separated>
        <div className={style.attentionContainer}>
          <Notice message={lang.publicProfileAttention} type="info" />
        </div>
        <UnderlinedInput
          label={lang.labels.jobTitle}
          name="jobTitle"
          value={jobTitle}
          onChange={this.onChange}
          validations={{ maxLength: 100 }}
          validationErrors={maxLengthError({ value: 100 })}
          autofocus
        />
        <div className={style.textareaContainer}>
          <AutogrowTextarea
            className={style.textarea}
            value={notes}
            name="notes"
            minRows={4}
            maxRows={23}
            onChange={this.onChange}
            placeholder={lang.labels.notesPlaceholder}
          />
        </div>
      </DialogContentBox>
    );
  }
}
