/* eslint-disable no-undef */
import { VenueImagesAnalytics } from 'common/analytics';
import { checkDimensions, resizeAndCompressImage } from 'common/image-resize';
import { IMAGE_UPLOAD_MAX_DIMENSIONS } from 'common/consts';

App.Views.Dialog.ImageCrop = App.Views.Dialog.ImageUpload.extend({
  events: {
    'click .button-cancel': 'close',
    'click .submit-image': 'submitImage',
  },
  options: {
    width: 800,
    title: Wahanda.lang.settings.employees.employee.image.title,
    dialogClass: 'edit-image-dialog',
    dataTest: 'images-gallery-upload-crop-modal',
  },
  templateId: 'edit-image-dialog',

  attachCropping(width, height) {
    const self = this;
    const aspectRatio = this.options.aspectRatio;
    if (aspectRatio === 1) {
      this.$('.preview-image').addClass('square');
    }

    this.$('.uploaded-image').Jcrop(
      {
        aspectRatio,
        minSize: [this.options.MIN_WIDTH, this.options.MIN_HEIGHT],
        onSelect: showPreview,
        onChange: showPreview,
        trueSize: [width, height],
      },
      function () {
        jcrop_api = this;
      },
    );

    let initialSelectedHeight;
    let initalSelectedWidth;
    if (height < width / aspectRatio) {
      initialSelectedHeight = height;
      initalSelectedWidth = height * aspectRatio;
    } else {
      initialSelectedHeight = width / aspectRatio;
      initalSelectedWidth = width;
    }

    jcrop_api.setSelect([0, 0, initalSelectedWidth, initialSelectedHeight]);

    function showPreview(coordinates) {
      self.showPreview(coordinates, width, height);
    }
  },

  resetImage() {
    jcrop_api.destroy();
  },

  submitImage() {
    if (typeof jcrop_api === 'undefined') {
      this.resetCloseChangesCheck();
      this.close();
      return;
    }
    this.disableForm();
    this.$('.submit-image').action('doing');
    const devicePixelRatio = window.deviceRixelRatio || 1;
    const coordinates = jcrop_api.tellSelect();
    if (coordinates.h != 0 || coordinates.w != 0) {
      const calculatedCoordinates = {
        x1: Math.floor(coordinates.x) * devicePixelRatio,
        y1: Math.floor(coordinates.y) * devicePixelRatio,
        x2: Math.floor(coordinates.x2) * devicePixelRatio,
        y2: Math.floor(coordinates.y2) * devicePixelRatio,
      };

      this.calculatedCoordinates = this.returnAcceptableCoorinates(calculatedCoordinates);
    }

    this.$('input[type=file]').fileupload('add', { files: this.files });
  },

  returnAcceptableCoorinates(coordinates) {
    // Ensure that the coordinates are at least 0;
    if (coordinates.x1 < 0) {
      coordinates.x1 = 0;
    }
    if (coordinates.y1 < 0) {
      coordinates.y1 = 0;
    }
    if (coordinates.x2 < 0) {
      coordinates.x2 = 0;
    }
    if (coordinates.y2 < 0) {
      coordinates.y2 = 0;
    }
    // Ensure that the coordinates provided match at least the minimum size
    if (coordinates.x2 - coordinates.x1 < this.options.MIN_WIDTH) {
      coordinates.x2 = coordinates.x1 + this.options.MIN_WIDTH;
    }

    if (coordinates.y2 - coordinates.y1 < this.options.MIN_HEIGHT) {
      coordinates.y2 = coordinates.y1 + this.options.MIN_HEIGHT;
    }

    return coordinates;
  },

  showPreview(coordinates, width, height) {
    this.$('.preview-image').wShow();
    this.$('.preview-image-text').wShow();
    const rx = (100 * this.options.aspectRatio) / coordinates.w;
    const ry = 100 / coordinates.h;
    this.$('.preview-image img').css({
      width: `${Math.round(rx * width)}px`,
      height: `${Math.round(ry * height)}px`,
      marginLeft: `-${Math.round(rx * coordinates.x)}px`,
      marginTop: `-${Math.round(ry * coordinates.y)}px`,
    });
  },
  onDialogOpen() {
    BackboneEx.View.Dialog.prototype.onDialogOpen.call(this);
    this.$('.uploaded-image-container').wShow();
  },

  setupDialog() {
    App.Views.Dialog.ImageUpload.prototype.setupDialog.call(this);

    const self = this;
    this.$('input[type=file]')
      .fileupload()
      .fileupload('option', {
        dataType: 'json',
        url: App.Api.wsUrl(`/venue/${App.config.get('venue').id}/image.json`),
        multipart: true,
        paramName: 'file',
        imageOrientation: true,
        autoUpload: true,
        submit(e, data) {
          data.formData = {
            filename: data.files[0].name,
            x1: self.calculatedCoordinates.x1,
            y1: self.calculatedCoordinates.y1,
            x2: self.calculatedCoordinates.x2,
            y2: self.calculatedCoordinates.y2,
            'venue-image-type-id': self.options.imageTypeIdToUpload,
          };
          return true;
        },
        add: async (_, data) => {
          try {
            const file = data.files[0];
            data.files[0] = await self.resizeIfIsOutDimensions(file);
          } catch (error) {
            console.error('Error resizing file', error);
          }
          if (self.files) {
            data.submit();
            return;
          }
          if (data.files && data.files[0]) {
            self.onFilesLoad(data.files);
          }
        },
        done(event, data) {
          if (_.isArray(data.result.errors)) {
            self.enableForm();
            self.$('.submit-image').action();
            self.showUploadError(data.result.errors);
          } else if (data.result.id) {
            self.enableForm();
            self.options.selectImage(data.result.id, data.result);
            self.resetCloseChangesCheck();
            self.close();
          }
        },
        fail(event, data) {
          self.handleFail(data);
          self.hideProgress(true);
        },
      });

    this.$el.bind('dialogopen', function () {
      $(this).find('.image-list').shadows();
    });

    this.$el.unloadmask();
  },

  resizeIfIsOutDimensions: async (file) => {
    const { maxWidth, maxHeight } = IMAGE_UPLOAD_MAX_DIMENSIONS;

    const isWithinDimensions = await checkDimensions({ file, maxWidth, maxHeight });

    return isWithinDimensions ? file : await resizeAndCompressImage({ file, maxWidth, maxHeight });
  },

  onFilesLoad(files) {
    const self = this;
    const reader = new FileReader();
    reader.onload = function (e) {
      const image = new Image();
      image.src = e.target.result;
      if (image.src === 'data:') {
        self.openWarningPopup(Wahanda.lang.shared.errors.fileUpload.notImage);
        return;
      }
      image.onload = function () {
        if (this.width < self.options.MIN_WIDTH || this.height < self.options.MIN_HEIGHT) {
          VenueImagesAnalytics.trackError(
            'image-dimensions-too-small',
            `${this.width}x${this.height}`,
          );
          self.openWarningPopup(Wahanda.lang.shared.errors.fileUpload.dimensionsTooSmall);
          return;
        }
        if (this.width > self.options.MAX_WIDTH || this.height > self.options.MAX_WIDTH) {
          VenueImagesAnalytics.trackError(
            'image-dimensions-too-big',
            `${this.width}x${this.height}`,
          );
          self.openWarningPopup(Wahanda.lang.shared.errors.fileUpload.dimensionsTooBig);
          return;
        }
        self.$('.uploaded-image').attr('src', this.src).show();
        self.$('.preview-image img').attr('src', this.src).show();
        self.$('.uploaded-image-container').wHide();
        self.attachCropping(this.width, this.height);
        self.files = files;
      };
    };
    reader.readAsDataURL(files[0]);
  },

  openWarningPopup(popupContent) {
    App.ES6.Initializers.ConfirmationDialog({
      title: Wahanda.lang.shared.errors.fileUpload.title,
      text: popupContent,
    }).render();
  },
});
