import { createAction } from 'reduxStore/actionCreator';
import {
  GET_BANK_DETAILS_REQUEST,
  GET_BANK_DETAILS_RECEIVE,
  GET_LAST_PAYMENT_STATUS_REQUEST,
  GET_LAST_PAYMENT_STATUS_RECEIVED,
} from 'reduxStore/actionsConst';
import { LastPaymentResponse, CompanyResponse } from './types';

export const requestBankDetailsAction = (supplierId: number) =>
  createAction(GET_BANK_DETAILS_REQUEST, { supplierId });

export type RequestBankDetailsAction = ReturnType<typeof requestBankDetailsAction>;

export const receiveBankDetailsAction = (payload: CompanyResponse) =>
  createAction(GET_BANK_DETAILS_RECEIVE, payload);

export type ReceiveBankDetailsAction = ReturnType<typeof receiveBankDetailsAction>;

export const requestLastPaymentStatus = (supplierId: number) =>
  createAction(GET_LAST_PAYMENT_STATUS_REQUEST, { supplierId });

export type RequestLastPaymentStatusAction = ReturnType<typeof requestLastPaymentStatus>;

export const requestLastPaymentStatusReceived = (payload: LastPaymentResponse) =>
  createAction(GET_LAST_PAYMENT_STATUS_RECEIVED, payload);

export type RequestLastPaymentStatusReceivedAction = ReturnType<
  typeof requestLastPaymentStatusReceived
>;
