import { reducer, on, union } from 'ts-action';
import immer from 'immer';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';

import { businessDayTimeSlots } from '../utils/helpers';

import * as actions from './actions';

import {
  Employee,
  EmployeeShiftsArray,
  LoadingStatus,
  SetShiftsObject,
  Schedules,
  ScheduleType,
  ShiftWorkingHourType,
  VenueShiftsObject,
  DateRangeObject,
  WorkingHoursObject,
} from '../utils/types';

export interface ShiftsState {
  datePickerRange: DateRangeObject;
  employees: EmployeeShiftsArray;
  loadedSchedule: Schedules;
  isBusinessHoursLoaded: boolean;
  isEmployeesLoaded: boolean;
  isLoading: boolean;
  isSavingShift: boolean;
  scheduleLoadingStatus: LoadingStatus;
  venue: VenueShiftsObject;
}

const initialState: ShiftsState = {
  datePickerRange: {
    dateFrom: '',
    dateTo: '',
  },
  employees: {
    employeesWorkingHours: [],
  },
  loadedSchedule: {
    dailySchedules: [],
    type: ScheduleType.WEEKLY,
    validFrom: '',
    validTo: '',
  },
  isBusinessHoursLoaded: false,
  isEmployeesLoaded: false,
  isLoading: false,
  isSavingShift: false,
  scheduleLoadingStatus: LoadingStatus.INITIAL,
  venue: {
    businessHours: [],
    workingHours: [],
  },
};

function getEmployeesDay(
  state: ShiftsState,
  payload: { date: string; employeesWorkingHours: Employee[] },
) {
  const { date, employeesWorkingHours } = payload;
  const result = {
    ...state,
  };
  const employeesWorkingHoursState = state.employees.employeesWorkingHours;
  const employeeWorkingHours = immer(employeesWorkingHoursState, (draftState) => {
    draftState.forEach((hours) => {
      const eIndex = findIndex(draftState, ['employeeId', hours.employeeId]);
      const dateIndex = findIndex(draftState[eIndex].workingHours, ['date', date]);

      draftState[eIndex].workingHours[dateIndex] = employeesWorkingHours[eIndex].workingHours[0];
    });
  });

  return {
    ...result,
    employees: {
      employeesWorkingHours: employeeWorkingHours,
    },
  };
}

function setEmployeeShift(state: ShiftsState, payload: SetShiftsObject) {
  const { date, employeeId, workingHours } = payload;
  const result = {
    ...state,
    isSavingShift: false,
  };
  const employeesWorkingHoursState = state.employees.employeesWorkingHours;
  const employeeIndex = findIndex(employeesWorkingHoursState, ['employeeId', employeeId]);

  const workingHoursIndex = findIndex(employeesWorkingHoursState[employeeIndex].workingHours, [
    'date',
    date,
  ]);

  const employeeWorkingHours = immer(employeesWorkingHoursState, (draftState) => {
    draftState[employeeIndex].workingHours[workingHoursIndex] = workingHours;
  });

  return {
    ...result,
    employees: {
      employeesWorkingHours: employeeWorkingHours,
    },
  };
}

/*
 * Set venue working hours for selected date
 */
function setVenueHours(state: ShiftsState, payload: WorkingHoursObject) {
  const result = {
    ...state,
    isSavingShift: false,
  };

  if (!payload.timeSlots) {
    return result;
  }

  const { date } = payload;
  const workingHoursState = state.venue.workingHours;
  const dateIndex = findIndex(workingHoursState, ['date', date]);
  let timeSlots = payload.timeSlots;
  let type = payload.type;

  switch (payload.type) {
    case ShiftWorkingHourType.DAY_OFF:
      timeSlots = [];
      type = ShiftWorkingHourType.CUSTOM;
      break;
    case ShiftWorkingHourType.STANDARD:
      timeSlots = businessDayTimeSlots({
        businessHours: state.venue.businessHours,
        date,
      });
      break;
    default:
      break;
  }
  const venueWorkingHours = immer(workingHoursState, (draftState) => {
    draftState[dateIndex] = {
      date,
      type,
      timeSlots,
    };
  });

  return {
    ...result,
    venue: {
      businessHours: state.venue.businessHours,
      workingHours: venueWorkingHours,
    },
  };
}

/*
 * Populate venue working hours to state by either pushing new dates, either
 * updating existing ones
 */
function addVenueWorkingHours(state: ShiftsState, payload: { workingHours: WorkingHoursObject[] }) {
  return immer(state.venue, (draftState) => {
    payload.workingHours.forEach((hours) => {
      const { date } = hours;
      const dateIndex = findIndex(draftState, ['date', date]);
      if (dateIndex < 0) {
        draftState.workingHours.push(hours);
      } else {
        draftState.workingHours[dateIndex] = hours;
      }
    });
  });
}

function addEmployeeWorkingHours(
  state: ShiftsState,
  payload: { employeeId: number; employeeWorkingHours: WorkingHoursObject[] },
): Employee[] {
  if (!payload.employeeWorkingHours) {
    return state.employees.employeesWorkingHours;
  }

  const { employeeWorkingHours, employeeId } = payload;
  const employeesWorkingHoursState = state.employees.employeesWorkingHours;
  const employeeIndex = findIndex(employeesWorkingHoursState, ['employeeId', employeeId]);

  const wHours = immer(
    state.employees.employeesWorkingHours[employeeIndex].workingHours,
    (draftState) => {
      employeeWorkingHours.forEach((hours) => {
        const { date } = hours;
        const dateIndex = findIndex(draftState, ['date', date]);
        if (dateIndex < 0) {
          draftState.push(hours);
        } else {
          draftState[dateIndex] = hours;
        }
      });
    },
  );

  return immer(state.employees.employeesWorkingHours, (draft) => {
    draft[employeeIndex].workingHours = sortBy(wHours, ['date']);
  });
}

export const shifts = reducer(
  initialState,
  on(actions.getEmployeesWorkingHours, actions.getEmployeeMonthWorkingHours, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.getEmployeesDay, (state) => state),
  on(actions.getEmployeesSchedule, (state) => ({
    ...state,
    scheduleLoadingStatus: LoadingStatus.FETCHING,
  })),

  on(
    ...union(
      actions.setEmployeesSchedule,
      actions.setEmployeesShift,
      actions.setEmployeesTimeOff,
      actions.setEmployeesOpenDay,
    ),
    (state) => ({
      ...state,
      isSavingShift: true,
    }),
  ),
  on(
    actions.setVenueWorkingHours,
    actions.invalidateEmployeesSchedule,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state, { payload }) => ({
      ...state,
      isSavingShift: true,
    }),
  ),

  /*
   * Success
   */
  on(actions.getVenueBusinessHoursSuccess, (state, { payload }) => ({
    ...state,
    venue: {
      ...state.venue,
      ...payload,
    },
    isBusinessHoursLoaded: true,
  })),
  on(actions.getVenueWorkingHoursSuccess, (state, { payload }) => ({
    ...state,
    venue: addVenueWorkingHours(state, payload),
  })),
  on(actions.getEmployeesWorkingHoursSuccess, (state, { payload }) => ({
    ...state,
    datePickerRange: payload.datePickerRange,
    employees: {
      employeesWorkingHours: payload.employeesWorkingHours,
    },
    isEmployeesLoaded: true,
    isLoading: false,
  })),
  on(actions.getEmployeeMonthWorkingHoursSuccess, (state, { payload }) => ({
    ...state,
    datePickerRange: payload.datePickerRange,
    venue: addVenueWorkingHours(state, payload),
    employees: {
      employeesWorkingHours: addEmployeeWorkingHours(state, payload),
    },
    isEmployeesLoaded: true,
    isLoading: false,
  })),
  on(actions.getEmployeesScheduleSuccess, (state, { payload }) => ({
    ...state,
    loadedSchedule: payload,
    scheduleLoadingStatus: LoadingStatus.COMPLETED,
  })),
  on(actions.getEmployeesDaySuccess, (state, { payload }) => getEmployeesDay(state, payload)),
  on(actions.setEmployeesShiftSuccess, (state, { payload }) => setEmployeeShift(state, payload)),
  on(
    ...union(
      actions.setEmployeesOpenDaySuccess,
      actions.setEmployeesTimeOffSuccess,
      actions.setEmployeesScheduleSuccess,
      actions.invalidateEmployeesScheduleSuccess,
    ),
    (state, { payload }) => ({
      ...state,
      employees: {
        employeesWorkingHours: addEmployeeWorkingHours(state, payload),
      },
      isSavingShift: false,
    }),
  ),
  on(actions.setVenueWorkingHoursSuccess, (state, { payload }) => setVenueHours(state, payload)),

  /*
   * Failure
   */
  on(actions.getVenueWorkingHoursFailure, (state) => ({
    ...state,
    isEmployeesLoaded: true,
    isLoading: false,
    isSavingShift: false,
  })),
  on(
    ...union(
      actions.getEmployeesDayFailure,
      actions.getEmployeeMonthWorkingHoursFailure,
      actions.setEmployeesShiftFailure,
      actions.setEmployeesTimeOffFailure,
      actions.setEmployeesOpenDayFailure,
      actions.setEmployeesScheduleFailure,
      actions.invalidateEmployeesScheduleFailure,
    ),
    (state) => ({
      ...state,
      isLoading: false,
      isSavingShift: false,
    }),
  ),
  on(actions.setVenueWorkingHoursFailure, (state) => ({
    ...state,
    isSavingShift: false,
  })),
  on(actions.getEmployeesScheduleFailure, (state) => ({
    ...state,
    scheduleLoadingStatus: LoadingStatus.FAILED,
  })),

  on(actions.resetEmployeesSchedule, (state) => ({
    ...state,
    scheduleLoadingStatus: LoadingStatus.INITIAL,
    loadedSchedule: initialState.loadedSchedule,
  })),
);
