import { createSelector } from 'reselect';

const getProducts = (state) => state.products.items;

export const getSupplier = (state) => state.productOrders.productSupplier;

const getOrders = (productOrders) => productOrders;
const getProductId = (state, props) => props.productId;
const getProductInOrderId = (state, props) => props.productInOrderId;

export const productToOrderSelectorFactory = createSelector(
  getOrders,
  getProductId,
  (productOrders, productId) => {
    const order = productOrders.newOrder.find((item) => item.venueProductId === productId);
    if (order === null) {
      return null;
    }
    return order;
  },
);

export const orderToProcessRowSelectorFactory = createSelector(
  getOrders,
  getProductInOrderId,
  (productOrders, productInOrderId) => {
    const productOrder = productOrders.existingOrder.productOrderItems.find(
      (item) => item.id === productInOrderId,
    );
    if (productOrder === null) {
      return null;
    }
    return productOrder;
  },
);

export const placeOrderHasBeenClickedSelector = createSelector(getOrders, (productOrders) => {
  if (productOrders.placeOrderHasBeenClicked) {
    return productOrders.newOrder;
  }
  return null;
});

export const allowToPlaceOrderSelector = createSelector(
  getOrders,
  (productOrders) => productOrders.newOrder.length > 0,
);

export const newOrderProductsSelector = createSelector(
  getProducts,
  getSupplier,
  (products, supplier) =>
    products.filter(
      (product) =>
        supplier == null || (product.productSupplier && product.productSupplier.id === supplier.id),
    ),
);
