import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Person extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-person', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M12,14 C7.581722,14 4,17.581722 4,22 L2,22 C2,16.4771525 6.4771525,12 12,12 L12,14 Z M12,12 C14.209139,12 16,10.209139 16,8 C16,5.790861 14.209139,4 12,4 C9.790861,4 8,5.790861 8,8 C8,10.209139 9.790861,12 12,12 Z M17.5995658,13.7135719 C20.2548245,15.5113789 22,18.5518856 22,22 L22,22 L20,22 C20,18.9879733 18.3354247,16.3647289 15.8760072,15 C16.5079157,14.6493544 17.0873526,14.2156277 17.5995658,13.7135719 Z M12,2 C15.3137085,2 18,4.6862915 18,8 C18,11.3137085 15.3137085,14 12,14 C8.6862915,14 6,11.3137085 6,8 C6,4.6862915 8.6862915,2 12,2 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Person);
