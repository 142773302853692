/**
 * Extend the class instance with mixin values. Functions are bound to the instance.
 *
 * @param {Object} instance The instance to extend
 * @param {Object} mixin The mixin instance needs to be extended with
 *
 * @returns {void}
 */
const hasOwnProperty = Object.prototype.hasOwnProperty;

export default function extendMixin(instance, mixin) {
  let value;
  let key;

  for (key in mixin) {
    // eslint-disable-line no-restricted-syntax
    if (hasOwnProperty.call(mixin, key) && typeof instance[key] === 'undefined') {
      value = mixin[key];
      if (typeof value === 'function') {
        value = value.bind(instance);
      }
      instance[key] = value; // eslint-disable-line no-param-reassign
    }
  }
}
