import React, { Component, Fragment } from 'react';
import ROLES from 'common/constants/userRoles';
import Wahanda from 'common/wahanda';
import EmployeeRolesListItem from './EmployeeRolesListItem';

const lang = Wahanda.lang.settings.employees.employees;
interface IEmployeeRolesListProps extends React.HTMLAttributes<Element> {
  selectedRole?: any;
  selectedPermissions?: string[];
  permissionsByRoles: {
    permissions?: string[];
    role?: any;
  }[];
  showTaxAuditorRole?: boolean;
  showBasicRole?: boolean;
  disabled?: boolean;
  pointOfSalePermissionsVisible?: boolean;
  onRoleSelect?: (...args: any[]) => any;
  onPermissionsSelect?: (...args: any[]) => any;
}

export default class EmployeeRolesList extends Component<IEmployeeRolesListProps, {}> {
  static defaultProps = {
    selectedRole: null,
    showTaxAuditorRole: false,
    showBasicRole: false,
    disabled: false,
    selectedPermissions: [],
    pointOfSalePermissionsVisible: false,
    onRoleSelect: () => {},
    onPermissionsSelect: () => {},
  };

  getRolePermissions = (role) => {
    const { permissionsByRoles, selectedPermissions } = this.props;
    if (role === ROLES.USER) {
      return selectedPermissions;
    }
    const found = permissionsByRoles.find((item) => item.role === role);
    return found ? found.permissions : [];
  };

  isRoleSelected = (role) => role === this.props.selectedRole;

  renderRole = ({ role, descriptions, autoOpenCustomPermissionsDialog = false }) => {
    const {
      disabled,
      pointOfSalePermissionsVisible,
      onRoleSelect,
      onPermissionsSelect,
    } = this.props;
    return (
      <EmployeeRolesListItem
        role={role}
        label={lang.rolesFull[role.toLowerCase()]}
        selected={this.isRoleSelected(role)}
        permissions={this.getRolePermissions(role)}
        onRoleSelect={onRoleSelect}
        onPermissionsSelect={onPermissionsSelect}
        disabled={disabled}
        pointOfSalePermissionsVisible={pointOfSalePermissionsVisible}
        descriptions={descriptions}
        autoOpenCustomPermissionsDialog={autoOpenCustomPermissionsDialog}
      />
    );
  };

  render() {
    const { showTaxAuditorRole, showBasicRole } = this.props;
    return (
      <Fragment>
        {showBasicRole &&
          this.renderRole({
            role: ROLES.BASIC,
            descriptions: [
              { label: lang.rolesAccess.viewOwnCalendar },
              { label: lang.rolesAccess.clientDetails, disabled: true },
              { label: lang.rolesAccess.financesAndInvoices, disabled: true },
            ],
          })}
        {this.renderRole({
          role: ROLES.THERAPIST,
          descriptions: [
            { label: lang.rolesAccess.manageOwnCalendar },
            { label: lang.rolesAccess.clientDetails, disabled: true },
            { label: lang.rolesAccess.financesAndInvoices, disabled: true },
          ],
        })}
        {this.renderRole({
          role: ROLES.RECEPTIONIST,
          descriptions: [
            { label: lang.rolesAccess.manageAllCalendars },
            { label: lang.rolesAccess.clientDetails },
            { label: lang.rolesAccess.financesAndInvoices, disabled: true },
          ],
        })}
        {this.renderRole({
          role: ROLES.ADMINISTRATOR,
          descriptions: [
            { label: lang.rolesAccess.manageAllCalendars },
            { label: lang.rolesAccess.clientDetails },
            { label: lang.rolesAccess.financesAndInvoices },
          ],
        })}
        {showTaxAuditorRole &&
          this.renderRole({
            role: ROLES.TAX_AUDITOR,
            descriptions: [{ label: lang.rolesAccess.reportsAndFinanceOnly, hideIcon: true }],
          })}
        {this.renderRole({
          role: ROLES.USER,
          descriptions: [{ label: lang.rolesAccess.customAccess, hideIcon: true }],
          autoOpenCustomPermissionsDialog: true,
        })}
      </Fragment>
    );
  }
}
