(function() {
    var Mixin = {
        mixin: function mixin(View) {
            _.defaults(View.prototype, Mixin.implementation);

            BackboneEx.Mixin.extendView(View, Mixin.implementation);
        },

        implementation: {
            events               : {
                keyup: 'submitOnEnterHandler'
            },
            setupValidation      : setupValidation,
            disableForm          : disableForm,
            enableForm           : enableForm,
            save                 : save,
            saveAction           : saveAction,
            handleConstraintError: handleConstraintError,
            saveErrorText        : Wahanda.lang.shared.savingFailed,
            deleteErrorText      : Wahanda.lang.shared.deleteFailed,
            getValues            : getValues,
            getFormValues        : getFormValues,
            getFieldPrefixRegexp : getFieldPrefixRegexp,
            triggerFormValidation: triggerFormValidation,
            $field               : $field,
            submitOnEnterHandler : submitOnEnterHandler,
            fillFormFromModel    : fillFormFromModel,
            showError            : showError,
            focusForm            : focusForm,
            markButtonAsProcessing: markButtonAsProcessing,
            markButtonAsDisabled : markButtonAsDisabled,
            cleanButtonActions   : cleanButtonActions,
            getSaveActionButton  : getSaveActionButton
        }
    };

    BackboneEx.Mixin.View.Form = Mixin;

    function setupValidation() {
        var self = this;
        var validations = Wahanda.Validate.getValidations(
            'defaults',
            {
                submitHandler: function() {
                    self.save();
                }
            }
        );

        this.$('form').validate(validations);
    }

    function disableForm() {
        this.$el.toggleForm(true);
        if (this.customDisableForm) {
            this.customDisableForm();
        }
    }

    function enableForm() {
        this.$el.toggleForm(false);
        if (this.customEnableForm) {
            this.customEnableForm();
        }
    }

    function save() {
        this.model.set(this.getValues());
        this.disableForm();

        return this.saveAction();
    }

    function saveAction () {
        this.trigger('before-save');

        var model = (this.modelToSave || this.model);

        var isNewModel = !model.id;

        var onError = function(model, xhr) {
            this.enableForm();

            var errors = Wahanda.Util.parseErrors(xhr) || {};

            if (!_.isFunction(this.onSaveError) || false !== this.onSaveError(errors)) {
                var errorText = (_.isFunction(this.saveErrorText) ? this.saveErrorText(errors) : this.saveErrorText);
                this.showError(errorText);
            }

            this.trigger('save-failed', errors);
        }.bind(this);

        var onSuccess = function() {
            if (this.resetCloseChangesCheck) {
                this.resetCloseChangesCheck();
            }
            if (this.collection && isNewModel && this.addToCollection) {
                // New model in this collection
                this.addToCollection(model);
            }
            if (_.isFunction(this._afterSaveCallback)) {
                this._afterSaveCallback({ isNew: isNewModel });
            }

            this.trigger('saved');
        }.bind(this);

        return model.save()
          .done(onSuccess)
          .fail(onError);
    }

    function showError(error) {
        // TODO: correct error showing implementation
        alert(error);
    }

    function handleConstraintError(jqXHR) {
        if (Wahanda.Util.isUserErrorCode(jqXHR.status) && /application\/json/.test(jqXHR.getResponseHeader('Content-Type'))) {
            var errorData = {};
            try {
                errorData = JSON.parse(jqXHR.responseText);
            } catch (e) {
            }

            var errors = this.getConstraintErrors && this.getConstraintErrors(errorData);
            if (!_.isEmpty(errors)) {
                this.$('form').validate().showErrors(errors);
                this.$('form').find('.error:first').focus();
                return true;
            }
            else {
                var tooltipBody = this.getErrorTooltipBody && this.getErrorTooltipBody(errorData);
                if (tooltipBody) {
                    this.$('.button-secondary').noticeTip(tooltipBody);
                }
                return true;
            }
        }
    }

    function getValues(onlyWithPrefix) {
        var data  = this.getFormValues();
        var regex = this.getFieldPrefixRegexp();
        if (this.formFieldPrefix) {
            var newData = {};
            for (var name in data) {
                if (onlyWithPrefix && !regex.test(name)) {
                    continue;
                }
                var newName      = name.replace(regex, '');
                newData[newName] = data[name];
            }
            data = newData;
        }
        return data;
    }

    function getFieldPrefixRegexp() {
        var prefix = Wahanda.Util.escapeRegex(this.formFieldPrefix);
        return new RegExp('^' + prefix);
    }

    function getFormValues() {
        var $form = this.$('form:first');
        if ($form.length) {
            // Form is found, use the default value collecting.
            return Wahanda.Form.serialize(this.$('form:first'));
        } else {
            // Form isn't found. This view is probably inside of a form, or not part of form.
            var items = {};
            this.$el.find('input, select, textarea').each(function() {
                if (this.nodeName === 'INPUT' && this.getAttribute('type') === 'button') {
                    return;
                }
                var $field = $(this);
                if (!$field.attr('name') || isInvalidCheckbox($field) || isInvalidRadio($field)) {
                    return;
                }
                items[ $field.attr('name') ] = $field.val();
            });
            return items;
        }

        function isInvalidCheckbox($el) {
            return $el.is('input[type=checkbox]') && !$el.prop('checked');
        }

        function isInvalidRadio($el) {
            return $el.is('input[type=radio]') && !$el.prop('checked');
        }
    }

    function triggerFormValidation() {
        this.$('form:first').submit();
    }

    function $field(name) {
        return this.$('#' + this.formFieldPrefix + name);
    }

    /**
     * Submit the form on ENTER keypress.
     *
     * No submission happens if enter was pressed on SELECT element, TEXTAREA element, or the element/it's parent
     * has a no-autosubmit class.
     *
     * @param Event event
     */
    function submitOnEnterHandler(event) {
        if (event.keyCode !== $.ui.keyCode.ENTER) {
            return;
        }

        var $target = $(event.target);
        if ( $target.is('select') || $target.is('textarea') ) {
            return;
        }
        // Having a no-autosubmit class on an element or it's parent prevents submission of the form by <enter>
        if ($target.closest('.no-autosubmit').length > 0) {
            return;
        }

        if($target.parents('.react-dialog').length) {
          return;
        }

        this.$('form').submit();
    }

    /**
     * Fills the found form with values from the model
     */
    function fillFormFromModel(defaults, ignore) {
        var $elems = this.$(':input[name]').not('button').not('input[type=button]').not('input[type=submit]');
        var model  = this.model;
        defaults = defaults || {};
        ignore   = ignore || [];
        var prefix = this.getFieldPrefixRegexp();

        var toTrigger = [];

        $elems.each(function() {
            var $elem = $(this);
            var name  = $elem.attr('name').replace(prefix, '');

            if (-1 !== _.indexOf(ignore, name)) {
                // Field is ignored. Continue;
                return;
            }

            var value = model.get(name);

            if ($elem.is(':checkbox')) {
                $elem.prop('checked', !!value);
            } else if ($elem.is(':radio')) {
                var radioValue = $elem.val();
                $elem.prop('checked', radioValue === String(value));
            } else if (value != null && (!!value || parseInt(value, 10) === 0)) {
                $elem.val(value);
            } else if (typeof defaults[name] != 'undefined') {
                $elem.val(defaults[name]);
            } else {
                $elem.val('');
            }

            toTrigger.push($elem.get(0));
        });
    }

    function focusForm() {
        this.$(':input:visible:first').focus();
    }

    function markButtonAsProcessing($button) {
        $button = $button || this.getSaveActionButton();
        this.cleanButtonActions($button);
        $button.addClass('dialog2--button-processing');
    }

    function markButtonAsDisabled($button) {
        $button = $button || this.getSaveActionButton();
        this.cleanButtonActions($button);
        $button.addClass('dialog2--button-disabled');
    }

    function cleanButtonActions($button) {
        $button = $button || this.getSaveActionButton();
        $button.removeClass('dialog2--button-disabled dialog2--button-processing');
    }

    function getSaveActionButton() {
        return this.$('.js-save');
    }
})();
