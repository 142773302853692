(function() {
    var Mixin = {
        mixin: function mixin(View) {
            _.defaults(View.prototype, Mixin.implementation);

            BackboneEx.Mixin.extendView(View, Mixin.implementation);
        },

        implementation: {
            loadmask  : loadmask,
            unloadmask: unloadmask
        }
    };

    function loadmask() {
        this.$el.addClass('loading').loadmask();
    }

    function unloadmask() {
        this.$el.unloadmask().removeClass('loading');
    }

    BackboneEx.Mixin.View.Loadmask = Mixin;
}());