import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import ClientsBulkDelete from './index';

const mapStateToProps = ({ clients }) => ({
  loading: clients.clientBulkDelete.loading,
  deletedCount: clients.clientBulkDelete.deletedCount,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsBulkDelete);
