;(function($) {
	var eventSuffix        = '.datefieldalign';
	var lesserEventSuffix  = (eventSuffix + 'lesser') + eventSuffix;
	var greaterEventSuffix = (eventSuffix + 'greater') + eventSuffix;
	/**
	 * Date field aligning.
	 * @requires $.ui.datepicker to be set on the field
	 */
	$.widget("wahanda.dateFieldAlign", {
		// These options will be used as defaults
		options: {
		},
		
		_create: function() {
			if (this.options.lessThan) {
				this._setGreaterField(this.options.lessThan);
			}
			if (this.options.moreThan) {
				this._setLesserField(this.options.moreThan);
			}
		},
		
		_setOption: function(option, value) {
			if ('lessThan' === option) {
				this._setGreaterField(value);
			} else if ('moreThan' === option) {
				this._setLesserField(value);
			}
			$.Widget.prototype._setOption.apply( this, arguments );
		},
		
		/**
		 * Sets the gireater field. E.g. when this.element is changed, check that it is less or equal
		 * to the greater field. If not, update the gerater one with the same value.
		 * 
		 * @param jQuery $input
		 */
		_setGreaterField: function($input) {
			this._setField($input, {
				eventSuffix: greaterEventSuffix,
				isGreater  : true
			});
		},
		
		_setLesserField: function($input) {
			this._setField($input, {
				eventSuffix: lesserEventSuffix,
				isGreater  : false
			});
		},
		
		_setField: function($field, options) {
			var self = this;
			if (!$field.is('input[type=text]')) {
				throw new Error("Field must be an input with datepicker instance attached");
			}
			
			this.element.off(options.eventSuffix);
			
			this.element.on('change' + options.eventSuffix, function() {
				var ourDate     = self.element.datepicker('getDate');
				var other       = $field.datepicker('getDate');
				var needsFixing = false;
				
				if (ourDate && !other) {
					// Our date is filled in, but the other field is empty. Set it to the same date.
					needsFixing = true;
				} else if (ourDate && other) {
					needsFixing = (options.isGreater ? ourDate.getTime() > other.getTime() : ourDate.getTime() < other.getTime());
				}
				if (needsFixing) {
					$field.datepicker('setDate', ourDate);
					// Try revalidating
					try {
						self.element.valid();
						$field.valid();
					} catch (e) {
					}
				}
			});
		},
		
		destroy: function() {
			this.element.off(eventSuffix);
			
			$.Widget.prototype.destroy.call( this );
		}
	});
	
	// Static helper functions
	$.dateFieldAlign = {
		/**
		 * Connect two datepicker fields together.
		 * 
		 * @param Object options
		 * > first jQuery
		 * > later jQuery
		 */
		connect: function(options) {
			// First element binding
			options.first.dateFieldAlign({
				lessThan: options.later
			});
			// Second element binding
			options.later.dateFieldAlign({
				moreThan: options.first
			});
		}
	};
}(jQuery));
