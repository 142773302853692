import React from 'react';
import { Chevron } from 'components/common/Icon';
import style from './ActionBox.scss';

export interface Props {
  dataTest?: string;
  label: string;
  sublabel: string;
  Icon: React.SFC<any>;
  onClick?: () => void;
}

export const ActionBox: React.FC<Props> = ({ dataTest, label, sublabel, Icon, onClick }: Props) => {
  return (
    <div data-test={dataTest} className={style.actionBox} onClick={onClick}>
      <div className={style.iconWrapper}>
        <Icon />
      </div>
      <div className={style.textContainer}>
        <div className={style.label} data-test="action-box-label">
          {label}
        </div>
        <div className={style.sublabel} data-test="action-box-sublabel">
          {sublabel}
        </div>
      </div>
      <Chevron className={style.chevron} />
    </div>
  );
};
