;(function($) {
	// For use when UI "sorting" is needed.
	// All mentioned items must have in a .sorts element as a parent. All DOM searches are done from it.
	// It listens for clicks on elements, with .sort-item class. The node should have data-field="X" attribute,
	//   X wil be counted as the "sort field". Sort order is returned automagically.
	// A sibling of .sort-item must be .sort-icon whichs class will be changed corresponding state class.
	var cItem    = '.sort-item';
	var cParent  = '.sorts';
	var cIcon    = '.sort-icon';

	var ACTIVE   = 'sorting-on';
	var ORD_ASC  = 'sorting-asc-icon';
	var ORD_DESC = 'sorting-desc-icon';
	var ORD_NONE = 'icons-sorting-off';
	$.widget("wahanda.dynamicSorter", {
		// These options will be used as defaults
		options: {
			field: null,
			order: null
		},

		_create: function() {
			var self = this;
			this.element
			.on('click.dsorter', cItem, function(event) {
				self._onSortItemClick(event);
			})
			.on('click.dsorter', cIcon, function(event) {
				self._onSortOrderClick(event);
			});
			this._buildStyles();
		},

		_buildStyles: function() {
			var $node;
			var field = String(this.options.field).replace(/[^a-z0-9_-]+/gi, '');
			var order = this.options.order || 'ASC';
			if (field) {
				$node = this.element.find(cItem).filter('[data-field="' + field + '"]');
			}
			if (!$node || !$node.length) {
				$node = this.element.find(cItem).first();
			}

			$node.addClass(ACTIVE);
			this._setSortOrder($node, order);

			// Mark other icons as inactive
			this.element.find(cIcon).not(this._allIconClasses()).addClass(ORD_NONE);
		},

		/**
		 * Clears classes from DOM.
		 */
		_clearState: function() {
			var $icon;
			var $oldActive = this.element.find('.' + ACTIVE);
			if ($oldActive.length > 0) {
				$oldActive.removeClass(ACTIVE);
				$icon = this._getIcon($oldActive);
				$icon.removeClass(this._allIconClasses()).addClass(ORD_NONE);
			}
		},

		_setSortOrder: function($node, order) {
			var $icon = this._getIcon($node);
			var isAsc = (order !== 'DESC');

			var toAdd = (isAsc ? ORD_ASC : ORD_DESC);
			var toRem = (isAsc ? ORD_DESC : ORD_ASC);

			$icon.addClass(toAdd).removeClass(toRem + ' ' + ORD_NONE);
		},

		_getIcon: function($node) {
			return $node.closest(cParent).find(cIcon);
		},

		_getOrderStringFromIcon: function($icon) {
			return $icon.is('.' + ORD_ASC) ? 'ASC' : 'DESC';
		},

		_allIconClasses: function() {
			return '.' + ORD_ASC + ', .' + ORD_DESC;
		},

		destroy: function() {
			$.Widget.prototype.destroy.call( this );

			this.element.off('click.dsorter');
		},

		handleItemClick: function($item) {
			var isActive = $item.is('.' + ACTIVE);
			var $icon, order;

			if (isActive) {
				$icon = this._getIcon($item);
				order = (this._getOrderStringFromIcon($icon) === 'ASC' ? 'DESC' : 'ASC');
				// Change sort order
				this._setSortOrder($item, order);
			} else {
				this._clearState();

				order = 'ASC';
				$item.addClass(ACTIVE);
				this._setSortOrder($item, order);
			}

			this._trigger('change', null, { field: $item.data('field'), order: order });
		},

		// Event

		_onSortItemClick: function(event) {
			var $item = $(event.currentTarget);
			this.handleItemClick($item);
		},

		/**
		 * When the user clicks on sorting arrow, change the order.
		 *
		 * @param Event event
		 */
		_onSortOrderClick: function(event) {
			var $icon   = $(event.currentTarget);
			var $parent = $icon.closest(cParent);
			var $item   = $parent.find('.' + ACTIVE);
			if ($item.length === 0) {
				$item = $parent.find(cItem).first();
			}
			if ($item.length !== 0) {
				this.handleItemClick($item);
			}
		}
	});
}(jQuery));
