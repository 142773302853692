Wahanda.Text = {
  /**
   * Normlaizes the string for sending over an API call.
   *
   * This function:
   * - trims the string
   * - replaces all possible newline formats to "\n".
   *
   * @param String text
   * @return String
   */
  normalizeString: function (text) {
    if (typeof text !== 'string') {
      return '';
    }
    text = $.trim(text);
    text = text.replace(/\r\n|\r/g, '\n');
    return text;
  },

  /**
   * Normlaizes the string for sending over an API call.
   *
   * This method uses normalizeString, plus replaces multi-new-lines with a single new-line.
   *
   * @param String text
   * @return String
   */
  trimAndCleanNewLines: function (text) {
    text = Wahanda.Text.normalizeString(text);
    text = text.replace(/\n{2,}/, '\n');
    return text;
  },

  nl2br: function (text) {
    var re_nlchar;
    text = escape(text);
    if (text.indexOf('%0D%0A') > -1) {
      re_nlchar = /%0D%0A/g;
    } else if (text.indexOf('%0A') > -1) {
      re_nlchar = /%0A/g;
    } else if (text.indexOf('%0D') > -1) {
      re_nlchar = /%0D/g;
    }
    return unescape(text.replace(re_nlchar, '<br />'));
  },

  /**
   * Split a string into array by newline.
   *
   * @param String str
   * @returns {Array} Of lines
   */
  splitByNewline: function (str) {
    return String(str).split(/[\r\n]+/g);
  },

  str2int: function (value) {
    if (value == null) {
      return null;
    }

    if (value == '') {
      return null;
    }

    if (typeof value == 'string') {
      return parseInt(value, 10);
    }

    return value;
  },

  equals: function (str1, str2) {
    str1 = this.normalizeString(String(str1));
    str2 = this.normalizeString(String(str2));
    return str1 === str2;
  },

  /**
   * Returns camelCased text from dashed one.
   *
   * @param String text
   * @return String
   */
  toCamelCase: function (text) {
    return String(text).replace(/(\-[a-z])/g, function ($1) {
      return $1.toUpperCase().replace('-', '');
    });
  },

  /**
   * Returns camelCased text from uppercase, underscore separated, type code.
   *
   * Types, for example discount type, are often exposed in this form (JIT/OFFPEAK/SALE_PRICE).
   *
   * @param String text
   * @return String
   */
  toCamelCaseFromTypeCode: function (text) {
    return this.toCamelCase(text.toLowerCase().replace(/_/g, '-'));
  },

  /**
   * Translates the number into human language. This means that this function generates
   * strings like "1 dollar" and "15 dollars" based on the number and translation
   * strings input.
   *
   * @param Number number
   * @param Array  forms [one, more]
   */
  pluralizeNumber: function (number, forms) {
    var isOne = number === 1;
    if (isOne) {
      return number + ' ' + forms[0];
    } else {
      return number + ' ' + forms[1];
    }
  },
};
