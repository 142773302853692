import {
  GET_EMAIL_TEMPLATE_RECEIVE,
  GET_EMAIL_TEMPLATE_FAILURE,
  EMAIL_TEMPLATE_RESET,
  EDIT_MARKETING_EMAIL_CONTENT,
  STORE_MARKETING_EMAIL_CONTENT,
} from 'reduxStore/actionsConst';

export const ERROR_TEMPLATE = 'ERROR_TEMPLATE';

const campaignTemplate = (state = {}, action) => {
  switch (action.type) {
    case STORE_MARKETING_EMAIL_CONTENT:
      return {
        ...state,
        modifiedTemplate: action.content,
        editing: null,
      };
    case EDIT_MARKETING_EMAIL_CONTENT:
      return {
        ...state,
        editing: action.field,
      };
    case GET_EMAIL_TEMPLATE_RECEIVE:
      return {
        ...state,
        template: action.template,
        modifiedTemplate: action.template,
      };
    case GET_EMAIL_TEMPLATE_FAILURE:
      return {
        error: {
          type: ERROR_TEMPLATE,
        },
      };
    case EMAIL_TEMPLATE_RESET:
      return {};
    default:
      return state;
  }
};

export default campaignTemplate;
