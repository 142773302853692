import Wahanda from 'common/wahanda';

import rescheduleFloatingEvent from './reschedule';

function getChanges(appointment, newStart, newEmployeeId) {
  const oldDate = appointment.getStartDate();
  const date = Wahanda.Date.toApiString(newStart);
  const timeDiff = Wahanda.Time.getDateMinutes(newStart) - Wahanda.Time.getDateMinutes(oldDate);
  const changes: any = {};

  if (!Wahanda.Date.isEqualDates(newStart, oldDate) || timeDiff !== 0) {
    // No time changes required, only employee change
    changes.date = date;
    changes.timeDiff = timeDiff;
  }
  if (appointment.get('employeeId') !== newEmployeeId || newEmployeeId > 0) {
    changes.employeeId = newEmployeeId;
  }

  return changes;
}

/**
 *  Rescheduling of single or multiple blocks, appointments, or appointment groups
 *
 *  @constructor
 *
 *  @param {App.Models.CalendarObjects} calendarObjects Collection of appointments which
 *  also includes the rescheduled one, and it's related appts/groups
 */
const FloatingEventHandler = {
  /**
   *  Moves event to new start time
   *
   *  @param {Appointment} appointment The appt to move
   *  @param {Date} newStart New start date and time
   *  @param {int} newEmployeeId (optional) New employee id to change to. NOTE! For none,
   *  set `undefined` as `null` means "for all employees" (in case of Blocks)
   *  @param {Collection} dayCollection (optional) Collection of other appointments to
   *  reschedule around this appt
   *  @param {bool} isFullReschedule (optional) Should Package appointments be rescheduled
   *  one after another, not just the one acted on. Defaults to false
   *
   *  @returns {$.Promise} The promise to successfully reschedule every appointment
   */
  moveFloatingEvent(appointment, newStart, newEmployeeId, dayCollection, isFullReschedule) {
    return rescheduleFloatingEvent(
      appointment,
      getChanges(appointment, newStart, newEmployeeId),
      dayCollection,
      isFullReschedule,
      false,
    );
  },

  rebookAppointment(dayCollection, newStart, newEmployeeId) {
    const firstAppointment = dayCollection.at(0);

    return rescheduleFloatingEvent(
      firstAppointment,
      getChanges(firstAppointment, newStart, newEmployeeId),
      dayCollection,
      true,
      true,
    );
  },

  /**
   *  Reschedule a block
   *
   *  @param {App.Models.TimeBlock} block The rescheduled time block
   *  @param {String} updateType "D" - only this day, "F" - this and all future blocks
   *  @param {date} startDate New start date/time
   *  @param {date} endDate New end date/time
   *
   *  @returns {$.Promise} The promise to reschedule a block
   */
  rescheduleBlock(block, updateType, startDate, endDate) {
    const actionDate = Wahanda.Date.toApiString(startDate);

    block.set({
      actionDate,
      updateTypeCode: updateType,
      timeFrom: Wahanda.Time.getDateApiTime(startDate),
      timeTo: Wahanda.Time.getDateApiTime(endDate),
    });

    if (block.isSingleDay()) {
      block.set({
        dateFrom: actionDate,
        dateTo: actionDate,
      });
    }

    return block.save();
  },
};

export default FloatingEventHandler;
