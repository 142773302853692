import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Planet extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-planet', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M14.357,10 L9.643,10 C9.5466028,10.662285 9.49880846,11.3307374 9.49997826,12 C9.49997826,12.696 9.55,13.363 9.643,14 L14.357,14 C14.451,13.363 14.5,12.696 14.5,12 C14.5,11.304 14.45,10.637 14.357,10 L14.357,10 Z M16.376,10 C16.457,10.643 16.5,11.31 16.5,12 C16.5,12.69 16.457,13.357 16.376,14 L19.748,14 C20.0846685,12.687919 20.0846685,11.312081 19.748,10 L16.376,10 Z M14.38,19.64 C16.2993367,19.0399335 17.9232515,17.7408017 18.93,16 L15.978,16 C15.6272244,17.2853119 15.0887616,18.5118479 14.38,19.64 L14.38,19.64 Z M13.89,16 L10.11,16 C10.37,16.806 10.71,17.554 11.114,18.246 C11.399,18.734 11.699,19.164 12,19.535 C12.301,19.164 12.601,18.735 12.886,18.246 C13.29,17.554 13.629,16.806 13.891,16 L13.89,16 Z M14.38,4.36 C15.0887616,5.48815211 15.6272244,6.71468812 15.978,8 L18.93,8 C17.9232515,6.25919828 16.2993367,4.96006646 14.38,4.36 L14.38,4.36 Z M13.89,8 C13.63,7.194 13.29,6.446 12.886,5.754 C12.6241545,5.30225117 12.3279475,4.87131332 12,4.465 C11.699,4.836 11.399,5.265 11.114,5.754 C10.71,6.446 10.371,7.194 10.109,8 L13.891,8 L13.89,8 Z M7.624,10 L4.252,10 C4.08415512,10.6534027 3.99948547,11.325384 3.99999767,12 C3.99999767,12.69 4.088,13.36 4.252,14 L7.624,14 C7.5405028,13.336618 7.49908664,12.6686154 7.49999767,12 C7.49999767,11.31 7.543,10.643 7.624,10 L7.624,10 Z M9.62,19.64 C8.9112384,18.5118479 8.37277562,17.2853119 8.022,16 L5.07,16 C6.07674852,17.7408017 7.70066328,19.0399335 9.62,19.64 L9.62,19.64 Z M9.62,4.36 C7.70066328,4.96006646 6.07674852,6.25919828 5.07,8 L8.022,8 C8.37277562,6.71468812 8.9112384,5.48815211 9.62,4.36 L9.62,4.36 Z M12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Planet);
