/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import style from './PhotoThumbnailList.scss';
import { PhotoThumbnail } from 'components/common/PhotoThumbnail/PhotoThumbnail';
import {
  Active,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import { transformToString } from 'components/common/PhotoThumbnailList/utils/transformToString';
import classNames from 'classnames';
import { useOpenFileSelect } from '../PhotoUpload/useOpenFileSelect';

const MIN_PHOTOS = 4;
const NUMBER_OF_ADD_BUTTONS = 1;

type PhotoThumbnail = {
  id: string;
  src: string;
  alt: string;
  error?: string;
  isLoading?: boolean;
  isEditable?: boolean;
};

interface Props {
  isEditable?: boolean;
  photos?: PhotoThumbnail[];
  onAdd?: () => void;
  onEdit?: (photo: Pick<PhotoThumbnail, 'id'>) => void;
  onRemove?: (photo: Pick<PhotoThumbnail, 'id'>) => void;
  onOrderChange?: (photos: Array<Pick<PhotoThumbnail, 'id'>>) => void;
  onFilesSelected?: (files: FileList) => void;
}

export const PhotoThumbnailList = ({
  isEditable = true,
  photos = [],
  onAdd = () => {},
  onEdit = () => {},
  onRemove = () => {},
  onOrderChange = () => {},
  onFilesSelected = () => {},
}: Props) => {
  const [items, setItems] = useState(photos);

  useEffect(() => {
    setItems(photos);
  }, [photos]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    const isDraggedOverSelf = !over?.id || active.id === over?.id;
    if (isDraggedOverSelf) {
      return;
    }

    changeOrder(active, over);
  };

  const changeOrder = (active: Active, over: Over) =>
    setItems((items) => {
      const oldIndex = items.findIndex(({ id }) => active.id === id);
      const newIndex = items.findIndex(({ id }) => over.id === id);
      const orderedItems = arrayMove(items, oldIndex, newIndex);

      onOrderChange(orderedItems);

      return orderedItems;
    });

  const numberOfPlaceholderPhotos = MIN_PHOTOS - NUMBER_OF_ADD_BUTTONS - photos.length;

  const openMultipleFileSelect = useOpenFileSelect({
    onFilesSelected,
  });

  return (
    <ul data-test="photo-thumbnail-list" className={style.container}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items} strategy={rectSortingStrategy}>
          {items.map((item) => {
            const { id, src, alt, error, isEditable: itemIsEditable, ...props } = item;
            return (
              <SortableItem key={id} id={id}>
                {({ draggable, isDragging }) => (
                  <PhotoThumbnail
                    isEditable={!isDragging && isEditable && itemIsEditable}
                    isRemovable={isEditable && items?.length > 1}
                    photo={{ src, alt }}
                    onAdd={onAdd}
                    onEdit={() => onEdit({ id })}
                    onRemove={() => onRemove({ id })}
                    error={error}
                    {...props}
                  >
                    {isEditable && draggable}
                  </PhotoThumbnail>
                )}
              </SortableItem>
            );
          })}
          {isEditable &&
            NUMBER_OF_ADD_BUTTONS > 0 &&
            Array.from({ length: NUMBER_OF_ADD_BUTTONS }).map((_, index) => (
              <li key={`add-button-${index}`} className="no-drag">
                <PhotoThumbnail onAdd={openMultipleFileSelect} />
              </li>
            ))}
          {numberOfPlaceholderPhotos > 0 &&
            Array.from({ length: numberOfPlaceholderPhotos }).map((_, index) => (
              <li className="no-drag" key={`placeholder-${index}`}>
                <PhotoThumbnail isPlaceholder />
              </li>
            ))}
        </SortableContext>
      </DndContext>
    </ul>
  );
};

const SortableItem = ({ id, children }) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const sortableStyle: React.CSSProperties = {
    transform: transformToString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <li
      style={sortableStyle}
      className={classNames(style.sortable, { [style.isDragging]: isDragging })}
    >
      {children({
        draggable: (
          <div
            ref={setNodeRef}
            className={classNames(style.draggable, { [style.isDragging]: isDragging })}
            {...attributes}
            {...listeners}
          />
        ),
        isDragging,
      })}
    </li>
  );
};

PhotoThumbnailList.displayName = 'PhotoThumbnailList';
