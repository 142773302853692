import React from 'react';
import { useDebounce } from 'utilities/hooks';
import {
  InputBase,
  InputDecorators,
  InputSizeType,
  InputText,
} from 'components/common/__BaseCommon';

interface Props {
  autoFocus?: boolean;
  name: string;
  dataTest?: string;
  disabled?: boolean;
  fullBorder?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  size?: InputSizeType;
  value?: string;
  onClearClick?: () => void;
  onChange: (value) => void;
  trackSearchUpdate?: (param) => void;
  newLook?: boolean;
}

export const SearchInput: React.FC<Props> = ({
  autoFocus = false,
  name,
  dataTest,
  disabled,
  fullBorder,
  isClearable = true,
  isSearchable = true,
  placeholder,
  onClearClick,
  onChange,
  value,
  size = InputSizeType.DEFAULT,
  trackSearchUpdate,
  newLook = false,
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchValue, setValue] = React.useState(value);
  const refInput = React.useRef<HTMLInputElement>(null);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const handleOnChange = (event) => {
    const val = event.target.value;
    event.persist();
    setValue(val);
    setIsLoading(true);
  };
  const handleClearClick = () => {
    refInput?.current?.focus();
    setValue('');
    if (onClearClick) {
      onClearClick();
    }
  };
  const handleOnFocus = () => {
    setIsFocused(true);
  };
  const handleOnBlur = () => {
    setIsFocused(false);
  };
  React.useEffect(() => {
    setIsLoading(false);
    if (debouncedSearchTerm) {
      onChange(debouncedSearchTerm);
      if (trackSearchUpdate) {
        trackSearchUpdate(debouncedSearchTerm);
      }
    } else {
      onChange('');
    }
  }, [debouncedSearchTerm]);
  return (
    <InputBase
      size={size}
      disabled={disabled}
      isFocused={isFocused}
      fullBorder={fullBorder}
      newLook={newLook}
      isSearchable={isSearchable}
    >
      <InputDecorators
        dataTest={dataTest}
        isClearable={isClearable && !disabled && !!searchValue && !isLoading}
        isLoading={isLoading}
        isSearchable={isSearchable}
        onClear={handleClearClick}
      >
        <InputText
          ref={refInput}
          autoComplete="off"
          autoFocus={autoFocus}
          readOnly={disabled}
          dataTest={dataTest}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          placeholder={placeholder}
          name={name}
          value={searchValue || ''}
        />
      </InputDecorators>
    </InputBase>
  );
};

SearchInput.displayName = 'SearchInput';
