import React from 'react';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/common/Modal';
import { Button } from 'components/common/Button';
import Wahanda from 'common/wahanda';
import { Warning } from 'components/common/IconPicture';
import style from './ClientsImportCollision.scss';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
}

export const ClientsImportCollision = (props: Props) => {
  const { onClose, onSubmit } = props;

  return (
    <ModalContainer onClose={onClose}>
      <ModalHeader title={Wahanda.lang.clientsImport.collision.header} onClose={onClose} />
      <ModalBody>
        <div className={style.container}>
          <Warning className={style.icon} />
          <span>{Wahanda.lang.clientsImport.collision.body}</span>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          label={Wahanda.lang.shared.cancel}
          colour="plain"
          variant="secondary"
          onClick={onClose}
        />
        <Button
          label={Wahanda.lang.clientsImport.collision.submit}
          size="medium"
          onClick={onSubmit}
        />
      </ModalFooter>
    </ModalContainer>
  );
};
