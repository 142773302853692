(function() {
    var EVENT_NS = '.select-wrap';
    var CLASS_OPEN   = 'select-wrap-open';
    var CLASS_CLOSED = 'select-wrap-closed';
    /**
     * Converts conventional <SELECT> elements to overlayed-with-DIVs.
     */
    $.fn.selectWrap = function(options) {
        return this.each(function() {
            var $el = $(this);
            var inst = $el.data('selectWrap');
            if (inst) {
                if (typeof options === 'string' && inst[options]) {
                    // This is a method call on an existing SelectWrap instance.
                    var args = Array.prototype.slice.call(arguments, 1);
                    inst[options].apply(inst, args);
                }

                // Don't init it for the second time.
                return;
            }

            inst = new SelectWrap($el, options);
            $el.data('selectWrap', inst);
        });
    };

    function SelectWrap($el, options) {
        this.$select      = $el;
        this.$wrapper     = null;
        this.$replacement = null;
        this.options      = options || {};
        this.handleOpen   = true;

        this.setupHtml();

        if (!this.$select.prop('disabled')) {
            this.bindEventListeners();
        }
    }

    $.extend(SelectWrap.prototype, {
        setupHtml: function setupHtml() {
            var width  = this.$select.outerWidth();
            var height = this.$select.outerHeight();
            var wrapperClasses = 'select-wrap ' + CLASS_CLOSED;
            if (this.isDisabled()) {
                wrapperClasses += ' disabled';
            }
            this.$wrapper     = $('<div>', { 'class': wrapperClasses});
            this.$replacement = $('<span>', { 'class': 'select-wrap-replacement'});
            var wrapperStyles = {
                position: 'relative'
            };

            if (this.options.setStyles) {
                $.extend(wrapperStyles, {
                    width        : width,
                    height       : height,
                    display      : 'inline-block',
                    'line-height': height
                });
            }

            this.$wrapper.css(wrapperStyles);
            this.$select.css({
                opacity  : 0,
                'z-index': 2
            });

            this.updateText();
            this.$select.wrap( this.$wrapper );
            this.$replacement.insertBefore( this.$select );
            this.$wrapper = this.$select.parent();
        },

        bindEventListeners: function bindEventListeners() {
            this.$select.on(
                'change' + EVENT_NS,
                $.proxy(this.updateText, this)
            );
            this.$select.on(
                ['click' + EVENT_NS].join(' '),
                $.proxy(this.markAsOpen, this)
            );
            this.$select.on(
                ['change' + EVENT_NS, 'blur' + EVENT_NS].join(' '),
                $.proxy(this.markAsClosed, this)
            );
        },

        updateText: function updateWrapperText() {
            var text = this.$select.find('option:selected').text();
            this.$replacement.text(text);
        },

        markAsOpen: function markAsOpen() {
            if (!this.handleOpen) {
                return;
            }
            this.$wrapper.removeClass(CLASS_CLOSED).addClass(CLASS_OPEN);
        },

        markAsClosed: function markAsClosed() {
            this.$wrapper.removeClass(CLASS_OPEN).addClass(CLASS_CLOSED);
            var self = this;
            // In chrome (and possibly other borowsers) a click event is fired right after the change event
            // for selects. Ignore the click event as the select option list isn't open.
            this.handleOpen = false;
            window.setTimeout(function() {
                self.handleOpen = true;
            }, 50);
        },

        isDisabled: function() {
            return this.$select.prop('disabled') || this.$select.prop('readonly');;
        },

        refresh: function() {
            var isDisabled = this.isDisabled();
            this.$select.off(EVENT_NS);
            this.$wrapper.toggleClass('disabled', isDisabled);

            if (!isDisabled) {
                this.bindEventListeners();
            }
        }
    });
})();