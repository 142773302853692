require('../../../../app/assets/javascripts/wahanda/event.js');
(function() {
    function buildCheckKey(name, instanceOptions) {
        return name + (instanceOptions ? JSON.stringify(instanceOptions) : "");
    }
    /**
     * Model/Collection cache where the responses are Promises.
     *
     */
    var LSCache = {
        // Needed data: name, Class, params?
        cache: {},

        /**
         * Fetch an object, or have it returned from localStorage.
         *
         * @param String cacheKey Unique cache key.
         * @param Class obj The object to be instantiated
         * @param Object options
         * > Object instanceOptions (optional) Options for the instantiated class.
         * > Array[String] (optional) appEventsToCleanOn Array of events to listen on App. If such an event is caught, the item is
         *      removed from the cache and fetched again next time requested.
         * > Int expiry (optional) Seconds the item expires. Null for never.
         *
         * @returns Promise
         */
        fetch: function(cacheKey, obj, options) {
            options  = options || {};
            var checkKey = buildCheckKey(cacheKey, options.instanceOptions);

            if (!Wahanda.LocalStorage.getForVenue(checkKey) || isExpired(Wahanda.LocalStorage.getForVenue(checkKey))) {

                var inst = new obj(options.instanceOptions || {});
                var drd  = new $.Deferred();

                // Start fetching the Model/Collection
                inst.fetch()
                    .done(function() {
                        Wahanda.LocalStorage.setForVenue(checkKey, {
                            promise: drd.promise(),
                            cached : getCurrentTime(),
                            expiry : options.expiry,
                            inst   : inst
                        });
                        drd.resolve(inst);
                    })
                    .fail(function(jqXHR, textStatus) {
                        drd.reject();
                        delete LSCache.cache[checkKey];
                        debug('Failed to persist "' + checkKey + '" due to a fetch error: ' + textStatus);
                    });

                // Bind to any events the object should be cleaned on
                if (options.appEventsToCleanOn) {
                    var cleanupFn = function() {
                        delete LSCache.cache[checkKey];
                        App.off(null, cleanupFn);
                    };
                    App.on(
                        options.appEventsToCleanOn.join(' '),
                        cleanupFn,
                        LSCache
                    );
                }
            } else {
                var drd  = new $.Deferred();
                var localStorageObject = Wahanda.LocalStorage.getForVenue(checkKey);
                var data = localStorageObject.inst || {};
                var model = new options.model(data);
                drd.resolve(model);
            }
            return drd.promise();
        },

        clear: function() {
            LSCache.cache = {};
            // Remove all event listeners from App
            App.off(null, null, LSCache);
        },

        // Shorthand fetch recipes
        interactionsStats: function(date) {
            var modelToFetch = LSCache.fetch('sales-interactions',
                App.Models.StatsInteractions,
                {
                    expiry: getDashboardExpiry(),
                    instanceOptions: {
                        date: date
                    },
                    model: App.Models.StatsInteractions
                }
            );
            return modelToFetch;
        }
    };

    // Export
    Wahanda.Cache.LocalStorage = LSCache;

    /**
     * Check if the cache item is expired.
     *
     * @param Object obj
     * @returns boolean
     */
    function isExpired(obj) {
        if (!obj.expiry) {
            return false;
        }
        return (getCurrentTime() - obj.cached) > obj.expiry;
    }

    /**
     * Return current time.
     *
     * @param Object obj
     * @returns boolean
     */

    function getCurrentTime() {
        var now = new Date();
        return now.getTime();
    }

    function debug(str) {
        if (window.console && window.console.error) {
            window.console.error(str);
        }
    }

    /**
     * Return time in seconds until cache reset (0500 UTC)
     *
     * @returns int
     */

    function getDashboardExpiry() {
    	//Reset Time is 0500 UTC
    	var minutesAfterMidnight = 60*5;
    	var date = new Date();
    	var resetTime = Wahanda.Date.getUTCMidnight(date);
    	resetTime = Wahanda.Date.addMinutesToDate(resetTime, minutesAfterMidnight);
    	var utcDate = Wahanda.Date.getUTCDate(date);

    	if (Wahanda.Time.getDateMinutes(utcDate) > minutesAfterMidnight) {
    		resetTime = Wahanda.Date.addDaysToDate(resetTime, 1);
    	}

    	return resetTime.getTime() - date.getTime();
    }
}());
