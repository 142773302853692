import React from 'react';
import { useKeyPress } from 'utilities/hooks';
import { Chevron } from 'components/common/Icon';
import { Button } from 'components/common/Button';
import style from './ShiftNavigationButtons.scss';

interface Props {
  buttonText: string;
  onGoToNext: () => void;
  onGoToPrev: () => void;
  onGoToThis: () => void;
  showGoToThis: boolean;
}

export const ShiftNavigationButtons = ({
  buttonText,
  onGoToNext,
  onGoToPrev,
  onGoToThis,
  showGoToThis,
}: Props) => {
  const arrowSize = 'medium';
  useKeyPress('right', onGoToNext, true);
  useKeyPress('left', onGoToPrev, true);
  return (
    <div className={style.container}>
      {showGoToThis && (
        <div className={style.nowButton}>
          <Button variant="hollow" colour="help" onClick={onGoToThis} label={buttonText} />
        </div>
      )}
      <Button
        variant="hollow"
        colour="help"
        onClick={onGoToPrev}
        icon={<Chevron size={arrowSize} className={style.prev} />}
      />
      <Button
        variant="hollow"
        colour="help"
        onClick={onGoToNext}
        icon={<Chevron size={arrowSize} />}
      />
    </div>
  );
};
