/**
 * Templating engine.
 *
 * @uses mustache.js.
 */
Wahanda.Template = {
  /**
   * Returns the template.
   *
   * @param string name
   * @return string
   */
  get: function (name) {
    return $('#' + name).html();
  },

  /**
   * Renders the given template with params.
   *
   * @param string template
   * @param Object params OPTIONAL
   * @return string
   */
  render: function (template, params, partials) {
    return Mustache.render(template, params, partials);
  },

  /**
   * Renders the template, defined by name. It's populated with values from params.
   *
   * @param string name
   * @param Object params
   * @return string
   */
  renderTemplate: function (name, params, partials) {
    return this.render(this.get(name), params, partials);
  },

  /**
   * Check if the given template exists.
   *
   * @param String name
   * @return boolean
   */
  exists: function (name) {
    return null != document.getElementById(name);
  },
};
