import React from 'react';
import AutogrowTextarea from 'react-textarea-autosize';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import style from './style.scss';
import * as analytics from './analytics';
import GuidelinesLink, { CanRenderGuidelines } from '../../Guidelines';

const SHARED_LANG = Wahanda.lang.shared;
const LANG = Wahanda.lang.dashboard.reviews.reply;
const MIN_REPLY_LENGTH = 2;
interface IReplyProps extends React.HTMLAttributes<Element> {
  saveReply: (...args: any[]) => any;
  onHeightChange?: (...args: any[]) => any;
  reviewResponseSaving?: boolean;
  venueId: number;
  reviewId: number;
  readOnlyValue?: string;
}
type ReplyState = {
  valid?: boolean;
};
class Reply extends React.Component<IReplyProps, ReplyState> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'textarea' has no initializer and is not ... Remove this comment to see the full error message
  textarea: HTMLTextAreaElement;

  state = {
    valid: false,
  };

  onKeyUp = (event) => {
    const value = event.target.value;
    this.setState({ valid: String(value).trim().length >= MIN_REPLY_LENGTH });
  };

  saveReply = () => {
    analytics.trackReviewReplied();
    const content = this.textarea.value;
    const { venueId, reviewId, saveReply } = this.props;
    saveReply({ content, venueId, reviewId });
  };

  render() {
    const { valid } = this.state;
    const { onHeightChange, reviewResponseSaving, readOnlyValue } = this.props;
    const buttonText = reviewResponseSaving ? SHARED_LANG.saving : LANG.button;
    const autogrowValueProp = {};
    if (readOnlyValue) {
      // This makes the component controlled, making it read-only.
      // Also this is the only way seem to be possible to force the value in the tests.
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type '{}'.
      autogrowValueProp.value = readOnlyValue;
    }
    return (
      <div className={style.reply}>
        <label className={style.label} htmlFor="review-dialog-reply">
          {LANG.label}
        </label>
        <AutogrowTextarea
          className={style.textarea}
          id="review-dialog-reply"
          inputRef={(ref) => {
            this.textarea = ref;
          }}
          placeholder={LANG.placeholder}
          onKeyUp={this.onKeyUp}
          onHeightChange={onHeightChange}
          {...autogrowValueProp}
        />
        <div className={style.footer}>
          <Button
            onClick={this.saveReply}
            disabled={!valid || reviewResponseSaving}
            label={buttonText}
            variant="primary"
          />
          {CanRenderGuidelines && <GuidelinesLink classes={style.guidelines} />}
        </div>
      </div>
    );
  }
}

export default Reply;
