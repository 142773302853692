import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Link extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-link', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 100 100"
      >
        <path
          d="M36.3636364,45.4545455 L36.3636364,40.9090909 L45.4545455,40.9090909 L45.4545455,45.4545455 C45.4545455,46.6136364 46.2045455,48.8681818 48.6681818,51.3318182 C52.3409091,55 60.7363636,55.5590909 64.9681818,51.3318182 L83.15,33.15 C88.1954545,28.1045455 88.1954545,21.8954545 83.15,16.85 C78.1045455,11.8045455 71.8954545,11.8045455 66.85,16.85 L63.6363636,20.0636364 L57.2090909,13.6363636 L60.4227273,10.4227273 C69.0136364,1.83181818 80.9863636,1.83181818 89.5772727,10.4227273 C98.1681818,19.0136364 98.1681818,30.9863636 89.5772727,39.5772727 L71.3954545,57.7590909 C63.4090909,65.7454545 49.2863636,64.8045455 42.2409091,57.7590909 C37.8863636,53.4090909 36.3636364,48.8409091 36.3636364,45.4545455 Z M63.6363636,36.3636364 L63.6363636,40.9090909 L54.5454545,40.9090909 L54.5454545,36.3636364 C54.5454545,35.2045455 53.7954545,32.95 51.3318182,30.4863636 C47.6590909,26.8181818 39.2636364,26.2590909 35.0318182,30.4863636 L16.85,48.6681818 C11.8045455,53.7136364 11.8045455,59.9227273 16.85,64.9681818 C21.8954545,70.0136364 28.1045455,70.0136364 33.15,64.9681818 L36.3636364,61.7545455 L42.7909091,68.1818182 L39.5772727,71.3954545 C30.9863636,79.9863636 19.0136364,79.9863636 10.4227273,71.3954545 C1.83181818,62.8045455 1.83181818,50.8318182 10.4227273,42.2409091 L28.6045455,24.0590909 C36.5909091,16.0727273 50.7136364,17.0136364 57.7590909,24.0590909 C62.1136364,28.4090909 63.6363636,32.9772727 63.6363636,36.3636364 Z"
          transform="translate(0 9)"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(Link);
