import React from 'react';
import ReactDOM from 'react-dom';
import NotificationListHeader from 'components/notifications/NotificationListHeader';

function render(target, options) {
  ReactDOM.render(<NotificationListHeader {...options} />, target);
}

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

export default { render, destroy };
