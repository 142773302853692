import React from 'react';

interface Props {
  size?: number;
  className?: string;
}

export const EditIcon = ({ size = 12, ...props }: Props) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 1.586a.9.9 0 0 0-.646.268L2.197 8.51l-.484 1.777 1.777-.484 6.656-6.656A.913.913 0 0 0 9.5 1.586M8.767.732a1.914 1.914 0 0 1 2.087 3.122l-6.75 6.75a.5.5 0 0 1-.222.129l-2.75.75a.5.5 0 0 1-.614-.614l.75-2.75a.5.5 0 0 1 .128-.222l6.75-6.75c.178-.178.39-.32.621-.415"
      fill="currentColor"
    />
  </svg>
);
