import React from 'react';
import Wahanda from 'common/wahanda';
import { Spinner } from 'components/common/Spinner';
import { Button } from 'components/common/Button';
import { Facebook, Instagram, Messenger } from 'components/common/Icon';
import style from './Installed.scss';
import { FacebookBusinessExtensionAnalytics as Analytics } from './tracking';

const lang = Wahanda.lang.marketing.facebookBusinessExtension.installed;

export interface Props {
  isLoading: boolean;
  uninstallFBERequest: () => void;
}

export const Installed: React.FC<Props> = ({ isLoading, uninstallFBERequest }) => {
  const handleUnlinkClicked = () => {
    Analytics.trackUninstallClicked();
    uninstallFBERequest();
  };
  return (
    <>
      <div className={style.iconWrapper}>
        <Facebook className={style.icon} />
        <Instagram className={style.icon} />
        <Messenger className={style.icon} />
      </div>
      <div className={style.textContainer}>
        <div data-test="fb-integrated-successfully" className={style.label}>
          {lang.success}
        </div>
      </div>
      <div className={style.button}>
        {isLoading ? (
          <Spinner dark />
        ) : (
          <Button
            dataTest="unlink-button"
            label={lang.button}
            onClick={handleUnlinkClicked}
            size="large"
            variant="secondary"
            colour="plain"
          />
        )}
      </div>
    </>
  );
};
