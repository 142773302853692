import { GET_VENUE_EMPLOYEES_RECEIVE, GET_VENUE_EMPLOYEES_FAILURE } from 'reduxStore/actionsConst';
import _ from 'common/underscore';

const defaultState = { employees: [] };

const parseEmployeeData = (cacheData) =>
  _.map(cacheData.models, (employee) => ({
    id: employee.attributes.id,
    name: employee.attributes.name,
    employmentStatus: employee.attributes.employmentStatus,
  }));

export default function employeeFilterReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_VENUE_EMPLOYEES_RECEIVE:
      return { ...state, employees: parseEmployeeData(action.data) };
    case GET_VENUE_EMPLOYEES_FAILURE:
      return { ...state, employees: [] };
    default:
      return state;
  }
}
