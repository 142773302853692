import Wahanda from 'common/wahanda';
import { ShiftsAnalytics } from '../tracking';

const LANG = Wahanda.lang.settings.shifts;

export const actionGoToRota = (isHidden?: boolean, isTriggeredFromVenueModal = false) => ({
  isHidden,
  label: LANG.goToRota,
  href: Wahanda.Url.getFullUrl('team', 'rota'),
  onClick: () => {
    if (isTriggeredFromVenueModal) {
      ShiftsAnalytics.trackVenueShiftGoToRotaClick();
    } else {
      ShiftsAnalytics.trackShiftGoToRotaClick();
    }
    return true;
  },
});
