import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Note extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
        return (
          <svg
            className={['icon-svg', 'svg-note', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 12 12"
          >
            <path d="M9.75,1 C10.4403559,1 11,1.55964406 11,2.25 L11,7.58578644 C11,7.85100293 10.8946432,8.10535684 10.7071068,8.29289322 L8.29289322,10.7071068 C8.10535684,10.8946432 7.85100293,11 7.58578644,11 L2.25,11 C1.55964406,11 1,10.4403559 1,9.75 L1,2.25 C1,1.55964406 1.55964406,1 2.25,1 L9.75,1 Z M9.75,2 L2.25,2 C2.11192881,2 2,2.11192881 2,2.25 L2,9.75 C2,9.88807119 2.11192881,10 2.25,10 L7,10 L7,8 C7,7.48716416 7.38604019,7.06449284 7.88337887,7.00672773 L8,7 L10,7 L10,2.25 C10,2.11192881 9.88807119,2 9.75,2 Z M9.585,8 L8,8 L8,9.585 L9.585,8 Z M6,7 L6,8 L3,8 L3,7 L6,7 Z M8,5 L8,6 L3,6 L3,5 L8,5 Z M9,3 L9,4 L3,4 L3,3 L9,3 Z" />
          </svg>
        );
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-note', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M14,1.99201702 C14,1.99512596 13.9998765,1.99704029 14,1.99821411 L14,1.99201702 Z M14.0059397,2 L14.00175,2 C14.0026459,2.00008257 14.0039751,2 14.0059397,2 Z M2,2 L2,14 L10.0044225,14 C10.0239471,14 10.2762913,13.8952816 10.2915596,13.8800133 L13.8800133,10.2915596 C13.8967678,10.274805 14,10.0254922 14,10.0044225 L14,2 L2,2 Z M14.0059397,0 C15.1072288,0 16,0.900176167 16,1.99201702 L16,10.0044225 C16,10.5542648 15.6872276,11.3127724 15.2942268,11.7057732 L11.7057732,15.2942268 C11.3159854,15.6840146 10.555163,16 10.0044225,16 L1.99201702,16 C0.891856397,16 0,15.1054862 0,14.0059397 L0,1.99406028 C0,0.892771196 0.894513756,0 1.99406028,0 L14.0059397,0 Z M7,8 L4,8 L4,10 L7,10 L7,8 Z M12,4 L4,4 L4,6 L12,6 L12,4 Z M11,15 L9,15 L9,10.9998075 C9,9.89459441 9.89217632,9 10.9998075,9 L15,9 L15,11 L11,11 L11,15 Z" />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-note', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M14,1.99201702 C14,1.99512596 13.9998765,1.99704029 14,1.99821411 L14,1.99201702 Z M14.0059397,2 L14.00175,2 C14.0026459,2.00008257 14.0039751,2 14.0059397,2 Z M2,2 L2,14 L10.0044225,14 C10.0239471,14 10.2762913,13.8952816 10.2915596,13.8800133 L13.8800133,10.2915596 C13.8967678,10.274805 14,10.0254922 14,10.0044225 L14,2 L2,2 Z M14.0059397,0 C15.1072288,0 16,0.900176167 16,1.99201702 L16,10.0044225 C16,10.5542648 15.6872276,11.3127724 15.2942268,11.7057732 L11.7057732,15.2942268 C11.3159854,15.6840146 10.555163,16 10.0044225,16 L1.99201702,16 C0.891856397,16 0,15.1054862 0,14.0059397 L0,1.99406028 C0,0.892771196 0.894513756,0 1.99406028,0 L14.0059397,0 Z M7,8 L4,8 L4,10 L7,10 L7,8 Z M12,4 L4,4 L4,6 L12,6 L12,4 Z M11,15 L9,15 L9,10.9998075 C9,9.89459441 9.89217632,9 10.9998075,9 L15,9 L15,11 L11,11 L11,15 Z" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Note);
