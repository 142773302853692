import React from 'react';
import keycode from 'keycode';

export function useKeyPress(targetKey, handler, disableInInput?) {
  const keyHandler = (event) => {
    if (
      disableInInput &&
      (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA')
    ) {
      return;
    }

    if (keycode(event) === targetKey) {
      event.preventDefault();
      handler();
    }
  };

  React.useEffect(() => {
    window.addEventListener('keyup', keyHandler);
    return () => {
      window.removeEventListener('keyup', keyHandler);
    };
  }, [handler]);
}
