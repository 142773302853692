import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { clientsBulkDeleteSuccessAction, clientsBulkDeleteServerErrorAction } from './actions';

export default function* getClientsBulkDeleted() {
  try {
    const { disabledCustomersCount } = yield call(api.post, apiUrl('CLIENTS_BULK_DELETE'));

    yield put(clientsBulkDeleteSuccessAction(disabledCustomersCount));
  } catch (err) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    yield put(clientsBulkDeleteServerErrorAction(err));
  }
}
