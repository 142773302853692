import { connect } from 'react-redux';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { isFeatureEnabled } from 'common/trunk-features/store/selectors';

import { Routes } from './';
import {
  PROMOTIONAL_OFFERS_CHANNEL_FEATURE,
  EMAIL_CAMPAIGN_CHANNEL_FEATURE,
  CLIENT_APP_ENABLED_CHANNEL_FEATURE,
  CLIENT_APP_CAMPAIGN_CHANNEL_FEATURE,
  VENUE_OPENING_CAMPAIGN,
  COVID_CONSENT_FORMS,
} from '../chnlFeatures';

const mapStateToProps = (state) => ({
  isClientAppEnabled:
    App.isFeatureSupported(CLIENT_APP_ENABLED_CHANNEL_FEATURE) && App.isVenueListedOnMarketplace(),
  referrer: window.location.search && Wahanda.Url.parseSearch(window.location.search).referrer,
  isEmailCampaignsAccessible: Wahanda.Permissions.anyEmailCampaignEnabled(),
  isEmailCampaignEnabled: App.isFeatureSupported(EMAIL_CAMPAIGN_CHANNEL_FEATURE),
  isPromoOffersCampaignsEnabled: App.isFeatureSupported(PROMOTIONAL_OFFERS_CHANNEL_FEATURE),
  isAppForYourClientsCampaignEnabled:
    App.isFeatureSupported(CLIENT_APP_CAMPAIGN_CHANNEL_FEATURE) && App.isVenueListedOnMarketplace(),
  isPartnerSiteAccessible: Wahanda.Permissions.canAccessPartnerSites(),
  isOpeningCampaignEnabled: App.isFeatureSupported(VENUE_OPENING_CAMPAIGN),
  isClientFormsAccessible:
    isFeatureEnabled(state, 'CAR-2635-client-forms-page') &&
    App.isFeatureSupported(COVID_CONSENT_FORMS),
});

export const MarketingRouter = connect(mapStateToProps)(Routes);
