import React from 'react';
import { toJs, isEmpty } from 'mori';
import uuid from 'uuid';
import classnames from 'classnames';

interface InputRowProps extends React.HTMLAttributes<Element> {
  title?: string;
  onChange?: (...args: any[]) => any;
  value?: string | number;
  id?: string;
  name?: string;
  wide?: boolean;
  isNumber?: boolean;
  required?: boolean;
  min?: number;
  max?: number;
  error?: any[];
  hide?: boolean;
  integer?: boolean;
  minZero?: boolean;
}
type InputRowState = {
  inputId?: any;
};

export class InputRow extends React.Component<InputRowProps, InputRowState> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'input' has no initializer and is not def... Remove this comment to see the full error message
  input: HTMLInputElement;

  state = {
    inputId: this.props.id || uuid.v1(),
    required: typeof this.props.required === 'undefined' ? true : this.props.required,
  };

  getValue() {
    return this.input.value;
  }

  focus() {
    this.input.focus();
  }

  select() {
    this.input.select();
  }

  render() {
    const classes = classnames('dialog2--inputrow clearfix', {
      wide: this.props.wide,
      hidden: this.props.hide,
    });
    const inputClasses = classnames({
      required: this.state.required,
      number: this.props.isNumber,
      'min-zero': this.props.minZero,
      integer: this.props.integer,
    });
    const errorArray = toJs(this.props.error);
    const errorMessage = (() => {
      if (errorArray === null || isEmpty(this.props.error)) {
        return null;
      }
      return errorArray[0].message;
    })();
    return (
      <div className={classes}>
        <div className="right">
          <input
            id={this.state.inputId}
            autoComplete="off"
            type="text"
            value={this.props.value || ''}
            name={this.props.name}
            className={inputClasses}
            onChange={this.props.onChange}
            min={this.props.min}
            max={this.props.max}
            ref={(input) => {
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLInputEl... Remove this comment to see the full error message
              this.input = input;
            }}
            data-error={errorMessage}
          />
        </div>
        <label htmlFor={this.state.inputId} className="left">
          {this.props.title}
        </label>
      </div>
    );
  }
}
