App.Views.Forms.MenuOffer2.Pricing.Dated.InlineEdit = BackboneEx.View.Dialog.ValidatingForm.PricingBase.extend(
  {
    events: {
      'click .a-delete': 'remove',
      'click .button-cancel': 'close',
    },
    options: {
      width: 600,
      title: Wahanda.lang.menu.offer.pricing.editDialog.title,
    },

    templateId: 'menu-offer-pricing-edit-dialog',

    postInitialize: function () {},

    initForm: function () {
      this.editView = new App.Views.Forms.MenuOffer2.Pricing.Dated.Edit(
        _.extend({ el: this.$('.b-container') }, this.options),
      );
      this.editView.render();

      this.$('.a-delete').toggle(this.options.showDelete);
    },

    remove: function () {
      var self = this;
      var removeFunc = function () {
        self.options.deleteCallback();
        self.close();
      };

      Wahanda.Dialogs.Confirm(Wahanda.lang.menu.offer.pricing.removeQuestion, removeFunc);
    },
  },
);
