import React, { useEffect, useState } from 'react';
import { FormTemplateBox } from './FormTemplateBox';
import Loader from 'components/common/Loader';
import { useFormTemplates } from './useFormTemplates';
import { useStorage } from 'utilities/hooks/useStorage';
import { ClientFormsOnboardingModal } from '../ClientFormsOnboardingModal/ClientFormsOnboardingModal';

const CLIENT_FORMS_ONBOARDING_LOCALSTORAGE_KEY = 'clientFormsOnboardingSeen';

export const FormTemplates = (): JSX.Element | null => {
  const { isLoading, isError, formTemplates, refetch } = useFormTemplates();
  const [onboardingModalSeen, setOnboardingModalSeen] = useStorage<string>(
    CLIENT_FORMS_ONBOARDING_LOCALSTORAGE_KEY,
  );
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  const closeOnboardingModal = () => {
    setShowOnboardingModal(false);
    setOnboardingModalSeen('true');
  };

  useEffect(() => {
    if (!isLoading && !formTemplates?.length && !onboardingModalSeen) {
      setShowOnboardingModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formTemplates]);

  if (isLoading) return <Loader />;
  if (isError) return null;

  return (
    <>
      {showOnboardingModal && <ClientFormsOnboardingModal onClose={closeOnboardingModal} />}

      {(formTemplates || []).map((formTemplate) => (
        <FormTemplateBox
          key={formTemplate.id}
          formTemplate={formTemplate}
          onActionPerformed={refetch}
        />
      ))}
    </>
  );
};
