import { ActionUnionTypes } from 'reduxStore/actionCreator';
import {
  GET_RANKING_BOOST_REQUEST,
  GET_RANKING_BOOST_FAILURE,
  GET_RANKING_BOOST_SUCCESS,
} from 'reduxStore/actionsConst';

import * as actions from './actions';

export interface RankingBoostStatusState {
  isLoading: boolean;
  active?: boolean;
  endDate?: string;
}

export const initialState: RankingBoostStatusState = {
  isLoading: false,
};

export const rankingBoost = (
  state = initialState,
  action: ActionUnionTypes<typeof actions>,
): RankingBoostStatusState => {
  switch (action.type) {
    case GET_RANKING_BOOST_REQUEST:
      return {
        isLoading: true,
      };
    case GET_RANKING_BOOST_FAILURE:
      return {
        isLoading: false,
      };
    case GET_RANKING_BOOST_SUCCESS:
      return {
        isLoading: false,
        ...action.payload,
      };
    default:
      return state;
  }
};
