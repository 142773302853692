import React from 'react';
import Wahanda from 'common/wahanda';
import { EnvelopeSmartphone } from 'components/common/IconPicture';
import Dialog from 'components/common/react-dialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import { ClientImportAnalytics } from '../tracking';

import style from './ClientsImportCampaignPrompt.scss';

const lang = Wahanda.lang.clientsImport.addMultipleClients.campaignPrompt;

export interface Props {
  onClose: () => void;
  refreshClientsList: () => void;
  actions: {
    clientsImportReset: () => void;
  };
}

export class ClientsImportCampaignPrompt extends React.PureComponent<Props> {
  public render() {
    return (
      <Dialog
        dataTest="clients-import-campaign-prompt-modal"
        title={lang.dialogTitle}
        width={500}
        onClose={this.onClose}
        restrictHeight
        noContentPadding
      >
        <div className={style.wrapper}>
          <EnvelopeSmartphone className={style.icon} />
          <div className={style.message}>
            <div className={style.header}>{lang.header}</div>
            <div className={style.description}>{lang.description}</div>
          </div>
        </div>
        {this.renderFooter()}
      </Dialog>
    );
  }

  private renderFooter = () => (
    <DialogFooter>
      <DialogFooterButton
        type="secondary"
        title={Wahanda.lang.shared.close}
        onClick={this.onClose}
      />
      <DialogFooterButton
        dataTest="launch-client-app-campaign"
        title={lang.launchCampaign}
        onClick={this.launchCampaign}
      />
    </DialogFooter>
  );

  private onClose = () => {
    this.props.onClose();
    ClientImportAnalytics.trackCloseCampaignPromptClicked();
    this.props.actions.clientsImportReset();
    this.props.refreshClientsList();
  };

  private launchCampaign = () => {
    ClientImportAnalytics.trackLaunchCampaignClicked();
    window.location = Wahanda.Url.getFullUrl('marketing', 'email-campaign/app-for-your-clients');
  };
}
