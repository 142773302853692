import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  widgetToggleEnabled,
  widgetGenerateCode,
  widgetSelectLanguage,
  widgetClearError,
  widgetCleanStore,
} from './redux/actions';
import { getWidgetSettings } from './redux/reducers';
import SettingsOnlineWidget from './';

const mapStateToProps = (state) => ({
  widgetSettings: getWidgetSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      widgetToggleEnabled,
      widgetGenerateCode,
      widgetSelectLanguage,
      widgetClearError,
      widgetCleanStore,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsOnlineWidget);
