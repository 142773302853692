/**
 * Collection for App.Models.FeaturedOffer.
 */
App.Collections.FeaturedOffers = BackboneEx.Collection.Base.extend(
  {
    model: App.Models.FeaturedOffer,
    urlTemplate: '/venue/{{venueId}}/featured-offers.json',

    parse: function (data) {
      return data.featuredOffers;
    },

    /**
     * Can the given model be featured?
     *
     * @param App.Models.MenuOffer model
     *
     * @returns {boolean}
     */
    canMakeFeatured: function (offer) {
      if (offer.id && this.get(offer.id)) {
        // Model is already featured.
        return true;
      }
      // Can only add featured services if limit not reached
      return this.length < App.Collections.FeaturedOffers.MAX_FEATURED_OFFERS;
    },
  },
  {
    MAX_FEATURED_OFFERS: 5,
  },
);
