import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import SelectDropdown from 'components/common/SelectDropdown';
import { Checkbox } from 'components/common/Checkbox';
import style from './style.scss';
import { PERCENTAGE_VECTORS, HOUR_VECTORS } from './constants';

const lang = Wahanda.lang.menu.smartDiscounting.lastMinuteDiscounts;
const MAX_LEAD_TIME = 11 * 60;
const DEFAULT_MAX_MINS_BEFORE_APPOINTMENT = 4 * 60;
const WARNING_MESSAGE = Wahanda.lang.menu.services.jitMaxTimeNote;
interface ILastMinuteProps extends React.HTMLAttributes<Element> {
  venueId: string;
  data?: {
    active: boolean;
    maxMinsBeforeAppointment: number;
    maxHoursBeforeAppointment: number;
    maxLastMinuteDiscount: number;
    leadTimeMinutes: number;
  };
  disabled: boolean;
  leadTimeMinutes?: any;
}
type LastMinuteState = {
  applied?: any;
  isLeadTimeLimited?: boolean;
  hasLeadTime?: boolean;
  percentage?: any;
  maxMinsBeforeAppointment?: number;
  currentState?: any;
};

export default class LastMinute extends React.Component<ILastMinuteProps, LastMinuteState> {
  constructor(props) {
    super(props);
    const {
      data: { active: applied, maxLastMinuteDiscount, maxHoursBeforeAppointment, leadTimeMinutes },
    } = props;
    const isLeadTimeLimited = leadTimeMinutes > MAX_LEAD_TIME;
    const hasLeadTime = this.hasLeadTime(maxHoursBeforeAppointment);
    const initialPercentage = SelectDropdown.getInitialValue(
      PERCENTAGE_VECTORS,
      maxLastMinuteDiscount,
    );
    const initialmaxHoursBeforeAppointment = SelectDropdown.getInitialValue(
      HOUR_VECTORS,
      maxHoursBeforeAppointment,
    );
    this.state = {
      applied,
      isLeadTimeLimited,
      hasLeadTime,
      percentage: initialPercentage,
      maxMinsBeforeAppointment: initialmaxHoursBeforeAppointment * 60,
    };
  }

  onApplyDiscountChange = () => {
    const { applied: currentState } = this.state;
    this.setState({
      applied: !currentState,
    });
  };

  onPercentageChange = (percentage) => {
    this.setState({
      percentage,
    });
  };

  onHoursChange = (maxHoursBeforeAppointment) => {
    const maxMinsBeforeAppointment = maxHoursBeforeAppointment * 60;
    this.setState({
      hasLeadTime: this.hasLeadTime(maxHoursBeforeAppointment),
      maxMinsBeforeAppointment,
    });
  };

  getValues() {
    const { applied } = this.state;
    if (applied) {
      const { percentage, maxMinsBeforeAppointment } = this.state;
      return {
        active: applied,
        percentage,
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        maxHoursBeforeAppointment: maxMinsBeforeAppointment / 60,
      };
    }
    return {
      active: false,
    };
  }

  hasLeadTime(leadTime) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return this.props.data.leadTimeMinutes / 60 >= leadTime;
  }

  renderDiscountHoursSelect() {
    const { maxMinsBeforeAppointment } = this.state;
    const { disabled } = this.props;
    return (
      <SelectDropdown
        data={HOUR_VECTORS}
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        selected={maxMinsBeforeAppointment / 60}
        onSelect={this.onHoursChange}
        disabled={disabled}
      />
    );
  }

  renderDiscountPercentageSelect() {
    const { percentage } = this.state;
    const { disabled } = this.props;
    return (
      <SelectDropdown
        data={PERCENTAGE_VECTORS}
        selected={percentage}
        onSelect={this.onPercentageChange}
        disabled={disabled}
      />
    );
  }

  renderWarning() {
    // eslint-disable-line class-methods-use-this
    const innerHTML = {
      __html: WARNING_MESSAGE,
    };
    return <span dangerouslySetInnerHTML={innerHTML} />;
  }

  renderLastMinuteDiscount() {
    const { applied } = this.state;
    const { disabled } = this.props;
    return (
      <div
        className={classnames('lastMinuteDiscount', {
          [style.lastMinuteDiscount]: style.lastMinuteDiscount,
        })}
      >
        <Checkbox
          name="last-minute-discount"
          checked={applied}
          label={lang.action}
          onChange={this.onApplyDiscountChange}
          className={style.checkbox}
          disabled={disabled}
        />
        <span className={applied ? style.secondarySection : style.hidden}>
          {this.renderDiscountPercentageSelect()}
          {lang.afterPercentage}
          {this.renderDiscountHoursSelect()}
          {lang.afterHours}
        </span>
      </div>
    );
  }

  renderLeadTimeNote() {
    const { hasLeadTime } = this.state;
    if (hasLeadTime) {
      const {
        venueId,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'leadTimeMinutes' does not exist on type ... Remove this comment to see the full error message
        data: { leadTimeMinutes: leadTime },
      } = this.props;
      const leadTimeHours = leadTime / 60;
      let leadTimeInnerHTML = Wahanda.Template.render(Wahanda.lang.menu.services.jitTimeNote, {
        venueId,
        leadTime: leadTimeHours,
      });
      if (leadTimeHours < 1) {
        leadTimeInnerHTML = Wahanda.Template.render(Wahanda.lang.menu.services.jitTimeNoteMinutes, {
          venueId,
          leadTime,
        });
      }
      return (
        <div className={style.leadTimeNotice}>
          <span dangerouslySetInnerHTML={{ __html: leadTimeInnerHTML }} />
        </div>
      );
    }
    return null;
  }

  render() {
    const { isLeadTimeLimited } = this.state;
    return (
      <div className={style.section}>
        <h2 className={style.heading}>{lang.title}</h2>
        {isLeadTimeLimited ? this.renderWarning() : this.renderLastMinuteDiscount()}
        {this.renderLeadTimeNote()}
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
LastMinute.defaultProps = {
  data: {
    maxMinsBeforeAppointment: DEFAULT_MAX_MINS_BEFORE_APPOINTMENT,
  },
};
