import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../store/actions';
import { ShiftScheduleType as Component } from './ShiftScheduleType';

const mapStateToProps = ({ shifts }) => ({
  scheduleLoadingStatus: shifts.scheduleLoadingStatus,
  loadedSchedule: shifts.loadedSchedule,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ShiftScheduleType = connect(mapStateToProps, mapDispatchToProps)(Component);
