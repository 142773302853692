import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class CalendarConfirm extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-calendar-confirm', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="m11 5v-2h6v2zm8-.00296986c0 .00471992-.0018515.0034563 0 .00306444zm.0010434-1.99703014c1.1023003 0 1.9989566.89905068 1.9989566 1.99703014v9.00296986h-2v-8.99990542zm3.2918498 12.2928932 1.4142136 1.4142136-5.7071068 5.7071068-3.7071068-3.7071069 1.4142136-1.4142135 2.2928932 2.2928932zm-13.2928932-14.2928932v6h-2v-2h-2.00008974l.00008856 13.5286104c.0000004.1554988.00000079.3123381.00000118.4705179l7.6322336.000848c.1211466.0000079.2437354.0000158.3677664.0000237v2h-8.00245535c-1.1046204 0-1.99754465-.893754-1.99754465-2.0008717v-13.99825664c0-1.10556496.89193733-2.00087166 1.99980749-2.00087166h2.00019251v-2zm4 14v2h-2v-2zm-4 0v2h-2v-2zm0-4v2h-2v-2zm4 0v2h-2v-2zm4 0v2h-2v-2zm0-10v6h-2v-6z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(CalendarConfirm);
