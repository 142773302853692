(function () {
  var AjaxRetry = (App.Views.Dialog.Alert.AjaxRetry = App.Views.Dialog.Alert.extend({
    events: {
      'click .js-close': 'close',
      'click .a-retry': 'retry',
    },

    buttons: {
      close: true,
      retry: {
        title: Wahanda.lang.shared.retry,
        classes: 'a-retry dialog2--button-right',
      },
    },

    render: function () {
      this.constructor.__super__.render.call(this);
      // Allowing HTML input
      this.$('.b-alert-text').html(Wahanda.lang.shared.errors.connectionProblems);
    },

    retry: function () {
      Wahanda.Ajax.Retry.retry(true);
      this.close();
    },
  }));
})();
