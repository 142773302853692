import { curry, assocIn, get, getIn, hashMap, updateIn, vector, toClj } from 'mori';
import { appState } from 'state';

import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { toDateString } from 'common/datetime';
import apiUrl from 'common/api-url';
import { xhr, toJSON } from 'common/xhr';
import { fetch as fetchPosStatus } from 'src/services/pos-status';

const DATE_KEY = 'reports/transactions/date';

const enforceTransactions = (maybeTransactions) =>
  maybeTransactions === null ? vector() : maybeTransactions;

// Fetch the POS Daily Report and save it to LIST_KEY appState key
export function fetch(date) {
  const posDate = toDateString(date || get(appState.deref(), DATE_KEY));

  xhr
    .getWithPromise(
      apiUrl('POS_DAILY_REPORT', {
        date: posDate,
      }),
    )
    .done((resp) => {
      const newState = updateIn(toClj(toJSON(resp)), ['transactions'], enforceTransactions);

      appState.swap(curry(assocIn, ['pos', 'daily-report', posDate], newState));
    });
}

// Fetch only once - if no data for the given date exists yet
export function fetchOnce(date = get(appState.deref(), DATE_KEY)) {
  const posDate = toDateString(date);

  if (!getIn(appState.deref(), ['pos', 'daily-report', posDate])) {
    fetch(date);
  }
}

export function clearFetchCache(date) {
  appState.swap(curry(assocIn, ['pos', 'daily-report', toDateString(date)], null));
}

/**
 * Close the given day.
 *
 * @param {String} date Date to close
 * @param {Number} amount Counted cash in drawer
 *
 * @returns {Promise} Xhr promise
 */
export function close(date, amount) {
  const toEntity = (data) => ({
    correctionReceiptId: data.cashCorrection ? data.cashCorrection.receiptUri : null,
  });

  const promise = xhr.postWithPromise(
    apiUrl('POS_CLOSE_DAY'),
    hashMap('date', toDateString(date), 'enteredCashDrawerAmount', amount),
  );

  const promiseDone = (() => {
    App.trigger(Wahanda.Event.POS_DAY_CLOSED, toDateString(date));

    if (!getIn(appState.deref(), ['close-dialog', 'no-fetch'])) {
      return () => {
        fetch(date);
        fetchPosStatus();
      };
    }
    return {};
  })();

  // When the day is closed, re-fetch the data
  // @ts-expect-error ts-migrate(2345) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
  return promise.then(toEntity).done(promiseDone);
}

// Fetch the POS Settlement Report and save it to LIST_KEY appState key
export function addTransaction(amount, notes, operationId) {
  const toEntity = (data) => ({
    receiptId: data.receiptUri,
  });

  return xhr
    .postWithPromise(
      apiUrl('POS_TRANSACTION'),
      hashMap('amount', amount, 'notes', notes, 'operationId', operationId),
    )
    .then(toEntity);
}

export function hasTodayOpened() {
  const state = appState.deref();
  const dailyReport = getIn(state, ['pos', 'daily-report', get(state, DATE_KEY)]);
  const neverOpened = get(dailyReport, 'wasNeverOpened');

  return !neverOpened && neverOpened !== null;
}
