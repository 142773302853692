/* global BackboneEx */
import {
  render as renderReact,
  getValues as getReactValues,
  updateAllowProcessing,
  updateOfferName,
  remove as cleanupReactNode,
} from 'components/menu/OfferTreatmentPricingAndEmployees/initializer';

const Base = BackboneEx.View.Base;

App.Views.Forms.MenuOffer2.TreatmentPricingAndEmployees = Base.extend({
  lastActiveEmployees: null,
  useEmployeeCategoryPricing: null,
  primaryTreatmentOverride: null,

  initialize: function () {
    this.listenTo(
      App,
      Wahanda.Event.MENU_OFFER_NAME_CHANGED,
      (offer, { state: { now: name } = {} }) => this.onNameChange({ name }),
    );
    this.listenTo(
      this.options.mediator,
      this.options.mediator.MAIN_TREATMENT_CHANGED,
      this.onTreatmentChange,
    );

    App.on(Wahanda.Event.CHANGE_USE_PROCESSING_TIME, this.onChangeUseProcessingTime.bind(this));
    App.on(Wahanda.Event.PRICING_TYPE_CHANGE, this.onPricingTypeChange.bind(this));
  },

  isProcessingTimeAllowedByModelsTreatment: function (treatmentId) {
    if (!treatmentId) {
      return false;
    }

    const treatment = this.options.treatments.getTreatment(treatmentId);
    return treatment && treatment.get('allowProcessingTime');
  },

  isEmployeePricingType: function () {
    const pricingType = App.Models.MenuOffer.getPricingTypeDefaultingToStored(this.model);

    return (
      [
        App.Models.MenuOffer.PRICING_TYPE_BY_EMPLOYEE_CAT,
        App.Models.MenuOffer.PRICING_TYPE_2D,
      ].indexOf(pricingType) !== -1
    );
  },

  render: function () {
    const employees = this.model
      .getSelectedEmployeeIds()
      .filter((id) => !!this.options.employees.get(id));

    const props = {
      // Shared data
      employeeCategoriesCollection: this.options.employeeCategories.toJSON(),
      allEmployees: this.options.employees.toJSON(),
      offerDialogReadonly: this.model.isReadOnly(),
      offerDialogReadonlyLight: this.model.isReadOnlyLight(),
      offerDialogEmployees: employees,
      // Pricing data
      offerDialogCleanupTime: this.model.get('cleanupTime'),
      offerDialogProcessingTimeMins: this.model.get('processingTimeMins'),
      offerDialogUseDefaultPricingValues: !this.options.isCopy && !this.model.id,
      offerDialogAllowProcessing: this.isProcessingTimeAllowedByModelsTreatment(
        this.model.get('primaryTreatmentId'),
      ),
      offerDialogSkus: this.model.get('skus'),
      offerDialogOfferName: this.model.get('name') || '',
      offerDialogUseEmployeeCategoryPricing: this.isEmployeePricingType(),
      offerDialogEnclosedByPackage: this.model.hasEnclosingPackages(),
      // Employee data
      offerDialogEmployeeCategoryPricingChangeable:
        !this.model.isReadOnly() && !this.model.hasEnclosingPackages(),
      offerDialogEscapeWithSkuPricing: this.model.isEscapeWithSkuPricing(),
    };
    renderReact(this.el, props, {
      onPossiblePricingTypeChange: (c) => c,
    });
  },

  onPricingTypeChange: function ({ isEnabled }) {
    const { mediator } = this.options;
    mediator.trigger(mediator.PRICING_TYPE_CHANGE, isEnabled);
  },

  onChangeUseProcessingTime: function ({ isEnabled }) {
    const { mediator } = this.options;
    mediator.trigger(mediator.CHANGE_USE_PROCESSING_TIME, isEnabled);
  },

  getValues: function () {
    return getReactValues();
  },

  onNameChange: function (data) {
    updateOfferName(data.name);
  },

  onTreatmentChange: function (treatment) {
    updateAllowProcessing(this.isProcessingTimeAllowedByModelsTreatment(treatment.id));
  },

  remove: function () {
    // Clean up the React references
    cleanupReactNode(this.el);
    Base.prototype.remove.call(this);
  },
});
