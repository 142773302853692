import { connect } from 'react-redux';
import App from 'common/backbone-app';

import { ReviewEmptyState as Component } from './ReviewEmptyState';

const mapStateToProps = () => {
  const isZeroToFiveAvailable =
    App.isNewVenue() && App.isVenueListedOnMarketplace() && App.config.isVenueActive();

  return {
    shouldRenderActions: !isZeroToFiveAvailable,
  };
};

export const ReviewEmptyState = connect(mapStateToProps)(Component);
