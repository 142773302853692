require('../../../../../../../vendor/assets/javascripts/jquery.autosize.js');

import { trackEvent } from 'common/analytics';

(() => {
  const Lang = Wahanda.lang.customer.massEmail;
  const KEY_SIGNATURE = 'mass-email:signature';
  const KEY_LINK = 'mass-email:link';

  const BaseView = BackboneEx.View.Dialog.ValidatingForm;

  const MassEmailView = BaseView.extend({
    events: {
      'click .a-close': 'close',
      'click .a-preview': 'showPreview',
      'click .a-back': 'showForm',
      'change input[type=radio]': 'toggleSendButton',
    },
    options: {
      title: Lang.title,
      width: 800,
    },
    templateId: 'customer-mass-email-template',
    fieldPrefix: /^customer\-mass\-email\-/,
    audienceDialog: null,
    removeDialogOnCloseEvent: false,

    initForm() {
      trackEvent('clients-list', 'view', 'compose-email-form');

      this._setDefaultValidation();
      this.model = new App.Models.MassEmail();
      this.model.setFilters(this.options.filters);

      this.$('#customer-mass-email-message').autosize();

      this.toggleSendButton();
    },

    getDialogTemplateVariables() {
      const widgetDisabled = !App.config.get('venue').useOnlineBookingWidget;
      return {
        widgetDisabled,
        settingsLink: Wahanda.Url.getFullUrl('settings'),
        bookingWidgetSettingsLink: Wahanda.Url.getFullUrl(
          'settings',
          'online-booking/online-booking-widget',
        ),
        signature: getSignature(),
        bookingWidgetSelected: !widgetDisabled && getBookingWidgetSelected(),
        venueVisible: App.isFeatureSupported('marketplace'),
      };

      function getSignature() {
        let sign = Wahanda.LocalStorage.getForVenue(KEY_SIGNATURE);
        if (!sign) {
          sign = Wahanda.Template.render(Lang.texts.signatureTemplate, {
            venueName: App.config.get('venue').name,
          });
        }
        return sign;
      }

      function getBookingWidgetSelected() {
        let selected = false;
        if (App.config.get('venue').useOnlineBookingWidget) {
          selected =
            !App.isFeatureSupported('marketplace') ||
            Wahanda.LocalStorage.getForVenue(KEY_LINK) === 'WIDGET';
        }
        return selected;
      }
    },

    save() {
      const self = this;

      this.resetCloseChangesCheck();

      this.audienceDialog = new App.Views.Forms.Customer.MassEmailTargetAudienceConfirmation({
        onAccept: sendEmails,
        onCancel: cancelSend,
        model: this.model,
      });
      this.audienceDialog.prepareDialog(showDialog);

      this.model.set(this.getModelValues());
      this.$('.save-action').action('doing');
      this.disableForm();

      // Persist the reusable fields
      Wahanda.LocalStorage.setForVenue(KEY_SIGNATURE, this.model.get('signature'));
      Wahanda.LocalStorage.setForVenue(KEY_LINK, this.model.get('buttonLinkTarget'));

      function showDialog() {
        self.audienceDialog.render();
        self.audienceDialog.open();
        // Won't be removed from DOM
        self.close();
      }

      function sendEmails() {
        self._defaultSaveAction();
      }

      function cancelSend() {
        self.open();
        self.enableForm();
        self.queueCloseChangesCheckWarning();
        self.$('.save-action').action();
      }
    },

    showForm() {
      this.$el.removeClass('preview-mode');
    },

    showPreview() {
      if (!this.$el.find('form').valid()) {
        const fields = this.$el.find('form .error:input:visible:first');

        if (fields.length > 0) {
          const field = fields[0];
          const name = field.name;
          field.focus();
          trackEvent('clients-list', 'validation', 'compose-email-form', name);
        }

        return;
      }
      trackEvent('clients-list', 'click', 'compose-email-form', 'preview');

      const title = this.$('#customer-mass-email-title').val();
      const mainContent = this.$('#customer-mass-email-message').val();
      const signature = this.$('#customer-mass-email-signature').val();
      const showLogo = this.$('#customer-mass-email-showVenueLogo').prop('checked');

      const emailPreview = Wahanda.Template.renderTemplate('preview-email-dialog-template', {
        title: returnFormattedContent(title),
        mainContent: returnFormattedContent(mainContent),
        signature: returnFormattedContent(signature),
        venueName: App.config.get('venue').name,
        venueLogo: returnVenueLogo,
        locale: Wahanda.lang.locale,
      });

      function returnVenueLogo() {
        if (App.config.get('venue').logoImageId && showLogo) {
          return App.Models.Image.getUrl(App.config.get('venue').logoImageId, {
            width: 328,
            height: 216,
          });
        }
      }

      function returnFormattedContent(content) {
        content = _.escape(content);
        content = content.replace(/(?:\r\n|\r|\n)/g, '<br />');

        return content;
      }

      this.$('.email-preview').html(emailPreview);
      this.$el.addClass('preview-mode');
      this.$('.email-preview .dialog-content').shadows();
    },

    onDialogOpen() {
      BaseView.prototype.onDialogOpen.call(this);

      if (!this.model.hasActiveFilters()) {
        const alert = new App.Views.Forms.Customer.MassEmailNoFiltersWarning();
        alert.render();
        alert.open();
      }
    },

    _afterSaveCallback() {
      if (this.audienceDialog) {
        this.audienceDialog.close();
        this.audienceDialog = null;
      }
      this._destroyDialog();

      const View = BackboneEx.View.Dialog.extend({
        templateId: 'customer-mass-email-send-success',
        options: {
          width: 600,
          title: Lang.success.title,
          dataTest: 'mass-email-modal',
        },
        events: {
          'click button': () => {
            this.close();
          },
        },
      });

      // Open success dialog
      const dialog = new View();
      dialog.render();
      dialog.open();
    },
    toggleSendButton() {
      const $radios = this.$('input[name=customer-mass-email-buttonLinkTarget]');
      const enabled = $radios.filter(':checked').length > 0;

      this.$('.a-send').toggleFormElements(enabled);
    },

    destruct() {
      this.$('textarea').trigger('autosize.destroy');
    },
  });

  App.Views.Forms.Customer.MassEmail = MassEmailView;
})();
