import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from 'common/backbone-app';
import { getRankingBoostRequestAction } from './actions';

import { Reviews as Component } from './Reviews';

const mapStateToProps = ({ dashboard, rankingBoost }) => {
  const reviewCount = dashboard.venueStatistics.reviews.reviewCount || 0;

  return {
    shouldRenderRankingBoost: rankingBoost.active,
    shouldRenderZeroToFive:
      App.isNewVenue() &&
      App.isVenueListedOnMarketplace() &&
      App.config.isVenueActive() &&
      reviewCount < 5,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    getRankingBoostRequestAction: bindActionCreators(getRankingBoostRequestAction, dispatch),
    ...ownProps.actions,
  },
});

export const Reviews = connect(mapStateToProps, mapDispatchToProps)(Component);
