import { createAction } from 'reduxStore/actionCreator';

import {
  BACK_TO_BUSINESS_INIT_STATUS_REQUEST,
  BACK_TO_BUSINESS_INIT_STATUS_SUCCESS,
  BACK_TO_BUSINESS_INIT_STATUS_FAILURE,
  GET_BACK_TO_BUSINESS_TEMPLATE_REQUEST,
} from 'reduxStore/actionsConst';

export interface BackToBusinessInitStatusSuccess {
  currentReach: number;
  emailReach: number;
  smsReach: number;
  potentialReach: number;
  successfullyReached?: number;
  alreadySent: boolean;
}

export const backToBusinessInitStatusRequest = () =>
  createAction(BACK_TO_BUSINESS_INIT_STATUS_REQUEST);

export type BackToBusinessInitStatusRequestAction = ReturnType<
  typeof backToBusinessInitStatusRequest
>;

export const backToBusinessInitStatusSuccess = (payload: BackToBusinessInitStatusSuccess) =>
  createAction(BACK_TO_BUSINESS_INIT_STATUS_SUCCESS, payload);

export type BackToBusinessInitStatusSuccessAction = ReturnType<
  typeof backToBusinessInitStatusSuccess
>;

export const backToBusinessInitStatusFailure = () =>
  createAction(BACK_TO_BUSINESS_INIT_STATUS_FAILURE);

export type BackToBusinessInitStatusFailureAction = ReturnType<
  typeof backToBusinessInitStatusFailure
>;

export const getBackToBusinessEmailTemplateRequest = () =>
  createAction(GET_BACK_TO_BUSINESS_TEMPLATE_REQUEST);
