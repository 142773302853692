import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import {
  setAppointmentNotificationsVisibleAction,
  requestAppointmentNotificationsAction,
} from 'reduxStore/common/appointmentNotifications/actions';
import NotificationList from 'components/notifications/NotificationList/container';

const store = storeBuilder();

function render(target, { onAppointmentClick, onChange }) {
  ReactDOM.render(
    <Provider store={store}>
      <NotificationList onAppointmentClick={onAppointmentClick} onChange={onChange} />
    </Provider>,
    target,
  );
}

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

function setListVisible(visible) {
  store.dispatch(setAppointmentNotificationsVisibleAction(visible));
}

function fetch() {
  store.dispatch(requestAppointmentNotificationsAction());
}

export default {
  render,
  destroy,
  setListVisible,
  fetch,
};
