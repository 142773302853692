import React from 'react';
import Wahanda from 'common/wahanda';
import { Checkbox } from 'components/common/Checkbox';
import Appointments from './Appointments';
import style from './style.scss';

const lang = Wahanda.lang.menu.smartDiscounting.offPeakDiscounts;
const transformMapToObject = (outer) => {
  const outerObject = {};
  outer.forEach((inner, outerKey) => {
    const innerObject = {};
    inner.forEach((value, innerKey) => {
      innerObject[innerKey] = value;
    });
    outerObject[outerKey] = innerObject;
  });
  return outerObject;
};
const transformObjectToMap = (outer) => {
  const outerMap = new Map();
  Reflect.ownKeys(outer).forEach((outerKey) => {
    const inner = Reflect.get(outer, outerKey);
    const innerMap = new Map();
    Reflect.ownKeys(inner).forEach((innerKey) => {
      const value = Reflect.get(inner, innerKey);
      innerMap.set(innerKey, value);
    });
    outerMap.set(outerKey, innerMap);
  });
  return outerMap;
};
interface IOffPeakProps extends React.HTMLAttributes<Element> {
  data?: {
    days: {};
    active: boolean;
    appointmentMorningEndTime: string;
    appointmentAfternoonEndTime: string;
  };
  disabled: boolean;
}
type OffPeakState = {
  applied?: any;
  appointments?: Map<any, any>;
  currentState?: any;
};

export default class OffPeak extends React.Component<IOffPeakProps, OffPeakState> {
  applyDiscount: any;

  constructor(props) {
    super(props);
    const {
      data: { days, active: applied },
    } = props;
    const appointments = transformObjectToMap(days);
    this.state = {
      applied,
      appointments,
    };
  }

  onApplyDiscountChange = () => {
    const { applied: currentState } = this.state;
    this.setState({
      applied: !currentState,
    });
  };

  getValues() {
    const { applied } = this.state;
    if (applied) {
      const { appointments } = this.state;
      const days = transformMapToObject(appointments);
      return {
        active: applied,
        days,
      };
    }
    return {
      active: false,
    };
  }

  refApplyDiscount = (applyDiscount) => {
    this.applyDiscount = applyDiscount;
  };

  renderOffpeakDiscount() {
    const { applied } = this.state;
    const { disabled } = this.props;
    return (
      <div className={style.offpeakDiscounts}>
        <Checkbox
          name="off-peak"
          checked={applied}
          label={lang.action}
          onChange={this.onApplyDiscountChange}
          className={style.checkbox}
          disabled={disabled}
        />
      </div>
    );
  }

  renderOffpeakAppointments() {
    const { applied } = this.state;
    if (applied) {
      const { appointments } = this.state;
      const {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'appointmentMorningEndTime' does not exis... Remove this comment to see the full error message
        data: { appointmentMorningEndTime, appointmentAfternoonEndTime },
        disabled,
      } = this.props;
      return (
        <div className={style.offpeakAppointments}>
          <Appointments
            map={appointments}
            morning={appointmentMorningEndTime}
            evening={appointmentAfternoonEndTime}
            disabled={disabled}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className={style.section}>
        <h2 className={style.heading}>{lang.title}</h2>
        {this.renderOffpeakDiscount()}
        {this.renderOffpeakAppointments()}
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
OffPeak.defaultProps = {
  data: {},
};
