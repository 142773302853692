import {
  GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_REQUEST,
  GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_SUCCESS,
  GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_FAILURE,
  SEND_EMAIL_CAMPAIGN_REQUEST,
  SEND_EMAIL_CAMPAIGN_SUCCESS,
  SEND_EMAIL_CAMPAIGN_FAILURE,
  CLEAR_EMAIL_CAMPAIGN_ERROR,
  GET_SMART_DISCOUNTS_RECIPIENT_COUNT_REQUEST,
  GET_SERVICE_ON_SALE_RECIPIENT_COUNT_REQUEST,
  GET_APP_FOR_YOUR_CLIENTS_RECIPIENT_COUNT_REQUEST,
  RECIPIENT_COUNT_RESET,
  SEND_SMART_DISCOUNTS_CAMPAIGN_REQUEST,
  SEND_SERVICE_ON_SALE_CAMPAIGN_REQUEST,
  SEND_APP_FOR_YOUR_CLIENTS_CAMPAIGN_REQUEST,
  SEND_BACK_TO_BUSINESS_CAMPAIGN_REQUEST,
} from 'reduxStore/actionsConst';

export const clearEmailCampaignError = () => ({
  type: CLEAR_EMAIL_CAMPAIGN_ERROR,
});

export const getEmailCampaignRecipientCountRequest = () => ({
  type: GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_REQUEST,
});

export const getEmailCampaignRecipientCountSuccess = (count) => ({
  type: GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_SUCCESS,
  count,
});

export const getEmailCampaignRecipientCountFailure = (error) => ({
  type: GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_FAILURE,
  error,
});

export const getSmartDiscountsRecipientCountRequest = (data) => ({
  type: GET_SMART_DISCOUNTS_RECIPIENT_COUNT_REQUEST,
  selectedDiscountRuleId: data,
});

export const getServiceOnSaleRecipientCountRequest = (data) => ({
  type: GET_SERVICE_ON_SALE_RECIPIENT_COUNT_REQUEST,
  treatmentGroupId: data,
});

export const getAppForYourClientsRecipientCountRequest = () => ({
  type: GET_APP_FOR_YOUR_CLIENTS_RECIPIENT_COUNT_REQUEST,
});

export const recipientCountReset = () => ({
  type: RECIPIENT_COUNT_RESET,
});

export const sendEmailCampaignRequest = () => ({
  type: SEND_EMAIL_CAMPAIGN_REQUEST,
});

export const sendEmailCampaignSuccess = () => ({
  type: SEND_EMAIL_CAMPAIGN_SUCCESS,
});

export const sendEmailCampaignFailure = (error) => ({
  type: SEND_EMAIL_CAMPAIGN_FAILURE,
  error,
});

export const sendSmartDiscountsCampaignRequest = (data) => ({
  type: SEND_SMART_DISCOUNTS_CAMPAIGN_REQUEST,
  discountRuleId: data,
});

export const sendServiceOnSaleCampaignRequest = (data) => ({
  type: SEND_SERVICE_ON_SALE_CAMPAIGN_REQUEST,
  treatmentGroupId: data,
});

export const sendAppForYourClientsCampaignRequest = () => ({
  type: SEND_APP_FOR_YOUR_CLIENTS_CAMPAIGN_REQUEST,
});

export const sendBackToBusinessCampaignRequest = () => ({
  type: SEND_BACK_TO_BUSINESS_CAMPAIGN_REQUEST,
});
