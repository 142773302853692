import { conj, hashMap, get, some, disj, set, curry, updateIn } from 'mori';
import { newLocalState } from 'state';
import keycode from 'keycode';

// Treatwell DOM event helpers and related sugar
// This library should be used on mostly root-level (window or document) events,
// NOT something controlled by React!

// Make sure to pass in `fn` which you guarantee to be the same when you will unbind.
// If it's being passed in from external context as a prop, the appropriate way would
// be to wrap it in your own caller fn.
export function bindEvent(type, fn, eventTarget = window) {
  eventTarget.addEventListener(type, fn);
}

export function unbindEvent(type, boundFn, eventTarget = window) {
  eventTarget.removeEventListener(type, boundFn);
}

// By-keyboard-key filtering sugar

// The key event stack which holds all currently active key event listeners
const keyEventStack = newLocalState();

function createStackEntry(key, fn, target, filter) {
  return hashMap('key', key, 'fn', fn, 'target', target, 'filter', filter);
}

function addToKeyStack(actionKey, fn, eventTarget, filter) {
  const entry = createStackEntry(actionKey, fn, eventTarget, filter);

  keyEventStack.swap(curry(updateIn, ['stack'], (stack) => conj(stack || set(), entry)));
}

function findStackEntry(key, fn, target) {
  const stack = get(keyEventStack.deref(), 'stack');

  return some(
    (item) =>
      get(item, 'key') === key && get(item, 'fn') === fn && get(item, 'target') === target
        ? item
        : null,
    stack,
  );
}

function removeFromKeyStackAndGetFilterFn(actionKey, fn, eventTarget) {
  const entry = findStackEntry(actionKey, fn, eventTarget);

  if (!entry) {
    throw new Error(`Could not find previous entry for key '${actionKey}'`);
  }

  keyEventStack.swap(curry(updateIn, ['stack'], curry(disj, entry)));

  return get(entry, 'filter');
}

function buildKeyFilter(testKey, fn) {
  return function (event) {
    if (keycode(event) === testKey) {
      fn(event);
    }
  };
}

export function bindKeyEvent(actionKey, fn, eventTarget = window) {
  const filterFn = buildKeyFilter(actionKey, fn);

  bindEvent('keydown', filterFn, eventTarget);
  addToKeyStack(actionKey, fn, eventTarget, filterFn);
}

export function unbindKeyEvent(actionKey, fn, eventTarget = window) {
  const filterFn = removeFromKeyStackAndGetFilterFn(actionKey, fn, eventTarget);

  unbindEvent('keydown', filterFn, eventTarget);
}

// Triggering events
export function triggerEvent(domTarget, eventName) {
  const event = document.createEvent('Event');

  event.initEvent(eventName, true, true);
  domTarget.dispatchEvent(event);
}
