import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Chart extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-chart', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M11,20.0092049 C11,20.0034202 11.000302,20.0012405 11,20.0004349 L11,20.0092049 Z M11,4 L11,20.0004349 C10.9998957,20.0001566 13,20 13,20 L13,3.99956506 C13.0001043,3.99984342 11,4 11,4 Z M9,3.99079514 C9,2.89130934 9.89826062,2 10.9979131,2 L13.0020869,2 C14.1055038,2 15,2.89821238 15,3.99079514 L15,20.0092049 C15,21.1086907 14.1017394,22 13.0020869,22 L10.9979131,22 C9.89449617,22 9,21.1017876 9,20.0092049 L9,3.99079514 Z M3,20 L5.00208688,20 C4.99984923,20.0002353 5,16 5,16 L2.99791312,16 C3.00015077,15.9997647 3,20 3,20 Z M1,15.9981014 C1,14.8945804 1.89826062,14 2.99791312,14 L5.00208688,14 C6.10550383,14 7,14.8867064 7,15.9981014 L7,20.0018986 C7,21.1054196 6.10173938,22 5.00208688,22 L2.99791312,22 C1.89449617,22 1,21.1132936 1,20.0018986 L1,15.9981014 Z M19,19.9941413 C19,19.9996478 21.0020869,20 21.0020869,20 C20.9972736,20 21,10.0058587 21,10.0058587 C21,10.0003522 18.9979131,10 18.9979131,10 C19.0027264,10 19,19.9941413 19,19.9941413 Z M17,10.0058587 C17,8.89805351 17.8982606,8 18.9979131,8 L21.0020869,8 C22.1055038,8 23,8.89706013 23,10.0058587 L23,19.9941413 C23,21.1019465 22.1017394,22 21.0020869,22 L18.9979131,22 C17.8944962,22 17,21.1029399 17,19.9941413 L17,10.0058587 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Chart);
