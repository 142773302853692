import React, { useState, useRef, useLayoutEffect } from 'react';
import { Portal } from 'react-portal';
import { useOnClickOutside } from 'utilities/hooks';

import { Chevron, Team } from 'components/common/Icon';
import { PopoverBody } from 'components/common/Popover';
import { Checkbox } from 'components/common/Checkbox';

import { ServiceEditPayload } from '../store/types';
import { EMPLOYEES_LIMIT } from '../utils/constants';
import { getDropdownPosition, DropdownStyle } from '../utils/helpers';
import { AddServicesModalAnalytics } from '../tracking';

import { EmployeeAvatars, EmployeeAvatarsProps } from './EmployeeAvatars';
import { EmployeeCheckboxLabel } from './EmployeeCheckboxLabel';

import style from './ServiceRowEmployees.scss';

interface Props extends EmployeeAvatarsProps {
  menuGroupId: number;
  serviceId: number;
  // redux
  actions: {
    selectAllEmployees: (params: ServiceEditPayload) => void;
    deselectAllEmployees: (params: ServiceEditPayload) => void;
  };
}

export const ServiceRowEmployees = ({
  actions,
  employees,
  employeesCollection,
  onChange,
  menuGroupId,
  serviceId,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [contentStyle, setContentStyle] = useState<DropdownStyle>();
  const listRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const hasOnlyOneEmployee = employeesCollection.length === 1;
  const hasActiveEmployee = employeesCollection.length > 0;
  const count = employees.length - EMPLOYEES_LIMIT;

  const hasAllEmployeesSelected = employees.length === employeesCollection.length;
  const hasNoneEmployeesSelected = employees.length === 0;
  const hasSomeEmployeesSelected = !hasNoneEmployeesSelected && !hasAllEmployeesSelected;

  useLayoutEffect(() => {
    if (!employeesCollection) {
      return;
    }
    const elementRect = listRef.current?.getBoundingClientRect();
    const parentRect = parentRef.current?.getBoundingClientRect();
    if (isVisible && elementRect && parentRect) {
      setContentStyle(
        getDropdownPosition({
          elementRect,
          parentRect,
        }),
      );
    }
  }, [isVisible, listRef.current]);

  if (!employeesCollection) {
    return <div />;
  }

  const showDialog = () => {
    if (!hasActiveEmployee) {
      return;
    }
    setIsVisible(!isVisible);
    AddServicesModalAnalytics.trackAddServiceEmployeeAddView();
  };

  const hideDialog = () => {
    setIsVisible(false);
  };

  const handleEmployeeChange = (val, id) => {
    onChange(val, id);
  };

  const handleSelectAllChange = () => {
    if (hasAllEmployeesSelected) {
      actions.deselectAllEmployees({ menuGroupId, serviceId });
      AddServicesModalAnalytics.trackAddServiceEmployeeRemoveAllClick();
      return;
    }

    if (hasNoneEmployeesSelected || hasSomeEmployeesSelected) {
      actions.selectAllEmployees({ menuGroupId, serviceId });
      AddServicesModalAnalytics.trackAddServiceEmployeeAddAllClick();
    }
  };

  // not sure if fixing this incorrect hook order would break anything
  // eslint-disable-next-line
  useOnClickOutside(listRef, () => hideDialog(), isVisible);

  return (
    <div className={style.container} ref={parentRef}>
      {!hasOnlyOneEmployee && (
        <div className={style.action} role="button" tabIndex={-1} onClick={showDialog}>
          {!employees.length && (
            <>
              <Team className={style.iconTeam} />
              <div className={style.actionEmpty}>
                {Wahanda.lang.menu.multipleServicesImport.noEmployeesAssigned}
              </div>
            </>
          )}
          {hasActiveEmployee && <Chevron className={style.arrow} />}
        </div>
      )}
      {employees.length > EMPLOYEES_LIMIT && <div className={style.count}>+{count}</div>}

      <EmployeeAvatars
        employees={employees}
        employeesCollection={employeesCollection}
        onChange={handleEmployeeChange}
      />

      {isVisible && (
        <Portal>
          <div className={style.list} ref={listRef} style={contentStyle}>
            <PopoverBody maxHeight={310}>
              <div className={style.listHeader}>
                {Wahanda.lang.menu.multipleServicesImport.employeesAssigned}
              </div>
              <div className={style.listContent}>
                <Checkbox
                  name={`${serviceId}-selectAll`}
                  className={style.selectAllCheckbox}
                  onChange={handleSelectAllChange}
                  label={Wahanda.lang.menu.offer.pricing.employees.selectAll}
                  checked={hasAllEmployeesSelected}
                  semiChecked={hasSomeEmployeesSelected}
                />

                {employeesCollection.map((employee) => {
                  const key = `${serviceId}-${employee.id}`;
                  const isChecked = employees.includes(employee.id) || false;

                  return (
                    <Checkbox
                      className={style.employeeCheck}
                      name={key}
                      key={key}
                      label={<EmployeeCheckboxLabel employee={employee} />}
                      onChange={(val) => handleEmployeeChange(val, employee.id)}
                      checked={isChecked}
                    />
                  );
                })}
              </div>
            </PopoverBody>
          </div>
        </Portal>
      )}
    </div>
  );
};
