import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'reduxStore/common/customers/actions';
import App from 'common/backbone-app';
import { CustomerFormDialog as Component } from './CustomerFormDialog';

const mapStateToProps = ({ customers: { customer, loading, submitting, serverError } }) => ({
  active: customer.active,
  birthDay: customer.birthDay,
  birthMonth: customer.birthMonth,
  birthYear: customer.birthYear,
  emailAddress: customer.emailAddress,
  gender: customer.gender,
  id: customer.id,
  locale: customer.locale,
  name: customer.name,
  notes: customer.notes,
  phone: customer.phone,
  prepaymentRequired: customer.prepaymentRequired,
  sendMassEmails: customer.sendMassEmails,
  loading,
  serverError,
  submitting,
  supportsMultiplePaymentOptions:
    !!App.config.get('venue').supportsMultiplePaymentOptions &&
    App.isFeatureSupported('prepayment-required'),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const CustomerFormDialog = connect(mapStateToProps, mapDispatchToProps)(Component);
