import * as Sentry from '@sentry/browser';

export function withErrorHandling<T extends unknown[], K>(
  fn: (...args: T) => K,
): (...args: T) => K | undefined {
  return (...args: T) => {
    try {
      return fn(...args);
    } catch (error) {
      if (isQuotaExceededError(error)) {
        try {
          Sentry?.captureMessage('QuotaExceededError', {
            level: 'info',
            extra: { space: `${getLocalStorageSizeInMB().toFixed(2)} MB` },
          });
        } catch (error) {
          Sentry?.captureException(error);
          console.error(error);
        }
      }
      Sentry?.captureException(error);
      console.error(error);
    }
  };
}

const isQuotaExceededError = (err: unknown): boolean =>
  err instanceof DOMException &&
  // everything except Firefox
  (err.code === 22 ||
    // Firefox
    err.code === 1014 ||
    // test name field too, because code might not be present
    // everything except Firefox
    err.name === 'QuotaExceededError' ||
    // Firefox
    err.name === 'NS_ERROR_DOM_QUOTA_REACHED');

const getLocalStorageSizeInMB = (): number => {
  const BITS_PER_UTF_16_CHARACTER = 16;
  const BITS_PER_MB = 8 * 1024 * 1024;
  let size = 0;
  for (const key in window.localStorage) {
    // eslint-disable-next-line no-prototype-builtins
    if (!window.localStorage.hasOwnProperty(key)) {
      continue;
    }
    const keyCharacters = key.length;
    const valueCharacters = window.localStorage[key].length;
    const keyValueSize =
      ((keyCharacters + valueCharacters) * BITS_PER_UTF_16_CHARACTER) / BITS_PER_MB;
    if (!isNaN(keyValueSize)) {
      size += keyValueSize;
    }
  }
  return size;
};
