import React from 'react';
import { get, map, hash, intoArray } from 'mori';
import classnames from 'classnames';

const FOOTER_CLASSES = 'dialog2--footer clearfix';
const BUTTON_CLASSES = 'dialog2--button';
const MENU_BUTTON_CLASSES = 'dialog2--button dialog2--button-left dialog2--button--menu';
const MENU_BUTTON_LIST_CLASSES = 'dialog2--button--menu-list';
const MENU_BUTTON_ITEM_CLASSES = 'dialog2--button--menu-list--item';
const createKey = (unique, suffix) => `${unique}-${suffix}`.toLowerCase().replace(/\s/g, '-');
type MenuButtonItemProps = {
  onClick: (...args: any[]) => any;
  classes?: string;
  label: string;
};
const MenuButtonItem: React.SFC<MenuButtonItemProps> = ({ onClick, classes, label }) => (
  <li onClick={onClick} className={classnames(MENU_BUTTON_ITEM_CLASSES, classes)}>
    {label}
  </li>
);
// @ts-expect-error ts-migrate(2430) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
interface IMenuButtonProps extends React.HTMLAttributes<Element> {
  id?: number;
  hashKey: string;
  classes?: string;
  actions: {
    onClick?: (...args: any[]) => any;
    label: string;
    classes?: string;
  }[];
}
type MenuButtonState = {
  isOpen?: boolean;
};
class MenuButton extends React.Component<IMenuButtonProps, MenuButtonState> {
  state = {};

  handleClick = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type '{}'.
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type '{}'.
    const { isOpen } = this.state;
    const { id, hashKey, classes, actions } = this.props;
    return (
      <div
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
        id={id}
        onClick={this.handleClick}
        className={classnames(MENU_BUTTON_CLASSES, { open: isOpen, closed: !isOpen }, classes)}
      >
        <ul className={MENU_BUTTON_LIST_CLASSES}>
          {actions.map(({ onClick, label, classes }) => (
            <MenuButtonItem
              key={createKey(hashKey, label)}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type '(...ar... Remove this comment to see the full error message
              onClick={onClick}
              classes={classes}
              label={label}
            />
          ))}
        </ul>
      </div>
    );
  }
}
type ButtonProps = {
  id?: number;
  onClick?: (...args: any[]) => any;
  classes?: string | any | any[];
  title: string;
};
const Button: React.SFC<ButtonProps> = ({ id, onClick, classes, title }) => (
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
  <div className={classnames(BUTTON_CLASSES, classes)} id={id} onClick={onClick}>
    <span className="title">{title}</span>
  </div>
);
interface IReactDialogFooterProps extends React.HTMLAttributes<Element> {
  buttons?: {};
  fullWidth?: boolean;
}

// eslint-disable-next-line react/no-multi-comp
export default class ReactDialogFooter extends React.Component<IReactDialogFooterProps, {}> {
  static defaultProps = {
    buttons: {},
    fullWidth: false,
  };

  renderButton = (buttonHash) => {
    /*
     *  Bad values do get passed, sometimes ... Sigh
     */
    if (buttonHash) {
      /*
       *  Unique
       */
      const hashId = hash(buttonHash);
      /*
       *  Button values
       */
      const id = get(buttonHash, 'id');
      const actions = get(buttonHash, 'actions');
      const classes = get(buttonHash, 'classes');
      /*
       *  Either ...
       */
      if (Array.isArray(actions)) {
        /*
         *  ... it's a menu button
         */
        const hashKey = createKey(hashId, 'menu-list');
        return (
          <MenuButton id={id} key={hashKey} hashKey={hashKey} actions={actions} classes={classes} />
        );
      }
      /*
       *  Or ...
       */
      const title = get(buttonHash, 'title');
      const hashKey = createKey(hashId, title);
      const onClick = get(buttonHash, 'onClick');
      /*
       *  ... it's a standard button
       */
      return <Button id={id} key={hashKey} title={title} onClick={onClick} classes={classes} />;
    }
    return null;
  };

  render() {
    const { buttons, fullWidth } = this.props;
    return (
      <div className={classnames(FOOTER_CLASSES, { 'full-width': fullWidth })}>
        {intoArray(map(this.renderButton, buttons))}
      </div>
    );
  }
}
