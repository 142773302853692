import React from 'react';
import ReactDOM from 'react-dom';
import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import { assoc } from 'mori';
import { appState } from 'state';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import moment from 'common/moment';
import { toDateString } from 'common/datetime';
import { Reports } from './container';

const DATE_KEY = 'reports/transactions/date';
const getInitialDate = () =>
  App.mainViewOptions && App.mainViewOptions.date
    ? toDateString(moment(App.mainViewOptions.date, 'YYYY-MM-DD'))
    : null;

function updateStateWithExternalData() {
  // this is only needed for transaction reports
  // move this to the transaction reports view.
  appState.swap((oldState) =>
    assoc(
      oldState,
      DATE_KEY,
      getInitialDate() || toDateString(moment.venueTimeZoneDate()),
      'venueId',
      App.getVenueId(),
    ),
  );
}

export const ReportsInitializer = (params) => {
  const store = storeBuilder();
  return {
    destroy() {
      ReactDOM.unmountComponentAtNode(params.tabElement);
    },
    render() {
      updateStateWithExternalData();
      const viewTransactionsTab = Wahanda.Permissions.canViewTransactionReport();
      const canViewBookings = !App.isTaxAuditor() && Wahanda.Permissions.viewBookings();
      const props = {
        canViewClientReports: Wahanda.Permissions.canViewClientReports(),
        canViewSaleReports: Wahanda.Permissions.viewReportsSales(),
        canViewBookingsReport: canViewBookings,
        canViewTransactionsReport: viewTransactionsTab,
        canViewOtherReports: canViewBookings || viewTransactionsTab,
        canViewPosReports: Wahanda.Permissions.viewPosReports(),
        venueId: App.getVenueId(),
      };
      ReactDOM.render(
        <Provider store={store}>
          <Reports {...props} />
        </Provider>,
        params.tabElement,
      );
    },
  };
};
