import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Edit extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-edit', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M17.0857864,9.5 L14.5,6.91421356 L6.41421356,15 L9,17.5857864 L17.0857864,9.5 L17.0857864,9.5 Z M18.5,8.08578644 L19.8769182,6.70886824 C20.2680296,6.31775685 20.2689389,5.68315249 19.8826429,5.29685642 L18.7031436,4.11735715 C18.3125187,3.72673231 17.6872744,3.72693912 17.2911318,4.1230818 L15.9142136,5.5 L18.5,8.08578644 L18.5,8.08578644 Z M5.29470766,13.2947077 L15.8769182,2.70886824 C17.0539001,1.53188633 18.9454742,1.53126065 20.1173571,2.70314358 L21.2968564,3.88264285 C22.4651098,5.05089622 22.4623859,6.95182767 21.2911318,8.1230818 L9,20.4142136 L5.22628077,16.6375552 L4.42521928,19.5747807 L5.17000008,19.3823374 L6.77946231,20.9903304 L1.57478072,22.4252193 L3.58999991,15 L5.29289322,13.2928932 L5.29470766,13.2947077 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Edit);
