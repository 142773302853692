/**
 * Backbone extensions.
 */
window.BackboneEx = {
	Model: {},
	Collection: {},
	View: {
		Menu: {},
		Calendar: {}
	},
	Router: {},
	Tool: {},
	Mixin: {
		View: {}
	}
};
