import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Camera extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-camera', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M20.0000994,5.05203954 C21.098542,5.05203954 22,5.9444961 22,7.04596505 L22,14.0271285 L20,14.0271285 L20,7.05204146 L17.4435949,7.05203954 L17.4435949,7.05203954 C16.3405886,7.05203954 15.4436942,6.15268505 15.4436942,5.05365292 L15.4436942,4.44887686 L8.97762016,4.45049024 L8.97762016,4.45049024 L8.97298959,4.66216055 C8.97256326,4.74682868 8.9724368,4.85689724 8.9726102,4.99236624 C8.9726102,6.10265049 8.08214962,7 6.96632833,7 L4.00628187,7 L4,18.0000589 L4,18.0000589 L21.9013967,18 L21.9013967,18 C21.9013967,19.1003274 21.0043803,20 19.9005814,20 L4.00081535,20 C2.89180357,20 2,19.106823 2,18.0014977 L2,6.99850233 C2,5.89337574 2.90005933,5 4.00628187,5 L6.96632833,5 L6.97243225,4.78832969 C6.97292712,4.70366157 6.97298644,4.593593 6.9726102,4.45812401 C6.9726102,3.34422816 7.86834511,2.45049024 8.97762016,2.45049024 L15.4386843,2.45049024 C16.5401218,2.45049024 17.4436942,3.3447373 17.4436942,4.44887686 L17.4436942,5.05203836 L20.0000994,5.05203954 Z M12,16 C9.790861,16 8,14.209139 8,12 C8,9.790861 9.790861,8 12,8 C14.209139,8 16,9.790861 16,12 C16,14.209139 14.209139,16 12,16 Z M12,14 C13.1045695,14 14,13.1045695 14,12 C14,10.8954305 13.1045695,10 12,10 C10.8954305,10 10,10.8954305 10,12 C10,13.1045695 10.8954305,14 12,14 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Camera);
