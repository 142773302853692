import React from 'react';
import Wahanda from 'common/wahanda';
import Dialog from 'components/common/react-dialog';
import Loader from 'components/common/Loader';
import { Fingerpost } from 'components/common/Fingerpost';
import { FinancePaymentsTracking } from '../../tracking';

export enum Payments {
  stripe = 'stripe',
  bank = 'bank',
  ideal = 'ideal',
}

interface Props {
  onClose: () => void;
  onPaymentSelect: (option: Payments) => void;
  idealSupported: boolean;
  paymentInProgress?: boolean;
}

const { lang } = Wahanda;

export class PaymentOptions extends React.PureComponent<Props> {
  private onClose = () => {
    FinancePaymentsTracking.trackCloseWindow();
    this.props.onClose();
  };

  private paymentSelected = (option: Payments) => {
    this.props.onPaymentSelect(option);
    return false;
  };

  private stripeSelected = () => {
    FinancePaymentsTracking.trackCardPaymentClicked();
    return this.paymentSelected(Payments.stripe);
  };

  private bankPaymentSelected = () => {
    FinancePaymentsTracking.trackBankPaymentClicked();
    return this.paymentSelected(Payments.bank);
  };

  private idealPaymentSelected = () => {
    FinancePaymentsTracking.trackIdealPaymentClicked();
    return this.paymentSelected(Payments.ideal);
  };

  public componentDidMount = () => {
    FinancePaymentsTracking.trackPaymentOptionsOpen();
  };

  public render() {
    const { idealSupported, paymentInProgress } = this.props;
    return (
      <Dialog
        dataTest="payment-option-modal"
        noContentPadding
        title={lang.settings.supplier.payments.options.title}
        onClose={this.onClose}
        width={340}
      >
        {paymentInProgress && <Loader positionAbsolute />}
        <Fingerpost
          actions={[
            {
              onClick: this.stripeSelected,
              dataTest: 'stripe-payment',
              label: lang.settings.supplier.payments.options.cardPayment,
            },
            {
              onClick: this.bankPaymentSelected,
              dataTest: 'bank-payment',
              label: lang.settings.supplier.payments.options.bankDetails,
            },
            {
              onClick: this.idealPaymentSelected,
              dataTest: 'ideal-payment',
              label: lang.settings.supplier.payments.options.idealPayment,
              isHidden: !idealSupported,
            },
          ]}
        />
      </Dialog>
    );
  }
}
