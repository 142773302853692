/* eslint-disable func-names */
/* global Backbone BackboneEx _ */
/**
 * List of offer groups.
 */
import React from 'react';
import { Button } from 'components/common/Button';
import { OfferTypeChoiceDialogAnalytics } from 'components/menu/OfferTypeChoiceDialog/tracking';

App.Views.Menu.OfferGroupList = BackboneEx.View.Sortable.extend({
  events: {
    scroll: 'saveScrollPosition',
  },
  sortableOptions: {
    axis: 'y',
    items: '> .offer-group',
    handle: '> .icon',
    distance: 3,
    tolerance: 'pointer',
    helper: function (event, $group) {
      const $helper = $group.clone();
      $helper.find('.offers').remove();
      $helper.height($group.find('.group-title').outerHeight());
      return $helper;
    },
  },
  offerGroupViews: [],
  rendered: false,
  // The full menu list. Is set from the main MenuView.
  fullMenu: null,

  initialize: function () {
    this.scrollPosition = 0;

    const self = this;
    let modelTriedToRender = false;
    const firstRenderFunction = () => {
      if (!self.options.venue.loaded()) {
        // Can't render w/o the venue
        window.setTimeout(firstRenderFunction, 50);
      } else {
        self.render();
        self.rendered = true;
      }
    };
    this.model.on('fetched', function () {
      modelTriedToRender = true;
      firstRenderFunction();
    });
    this.model
      .get('groupsCollection')
      .on('change remove', function () {
        if (self.rendered) {
          self.render();
        }
      })
      .on('add', function () {
        if (self.rendered) {
          self.render();
          self.scrollToTop();
        }
      });

    this.sortableOptions.start = function () {
      self.$el.addClass('drag-in-progress drag-of-group');
      self.$el.sortable('refreshPositions');
    };
    this.sortableOptions.stop = function () {
      self.$el.removeClass('drag-in-progress drag-of-group');
    };

    this.shadowsInitialized = false;

    // Treatments are required for this view and it's subviews to render
    this.hasTreatments = this.options.treatments.get('treatmentsCollection').length > 0;
    if (!this.hasTreatments) {
      const treatments = this.options.treatments.get('treatmentsCollection');
      const resetFunction = () => {
        self.hasTreatments = true;
        if (modelTriedToRender) {
          firstRenderFunction();
        }
        treatments.off('reset', resetFunction);
      };
      treatments.on('reset', resetFunction);
    }

    App.mainRouter.on('route:filterChange', function () {
      self.scrollPosition = 0;
    });
  },

  /**
   * Is ALL or ACT filter selected?
   */
  isFullFiltersEnabled: function () {
    if (!Wahanda.Permissions.editMenu()) {
      return false;
    }
    const filter = (App.mainViewOptions && App.mainViewOptions.initialFilter) || 'ACT';
    return filter === 'ACT' || filter === 'ALL';
  },

  render: function () {
    this.$el.empty();
    this.offerGroupViews = [];

    const treatmentTypeFilterId =
      (App.mainViewOptions && App.mainViewOptions.initialSubFilter) || -1;
    let treatments = this.options.treatments.get('treatmentsCollection').toArray();
    const groups = this.model.get('groupsCollection');
    const menuOffers = this.model.get('offersCollection');
    let offersToRender;
    const fullMenuOffers = this.fullMenu.get('offersCollection');

    this.renderHeader();

    if (treatmentTypeFilterId > 0) {
      treatments = this.options.treatments.get('treatmentsCollection').filter((treatment) => {
        return treatment.get('treatmentGroupId') === treatmentTypeFilterId;
      });

      offersToRender = new App.Collections.MenuOffers(
        menuOffers.filter((offer) => {
          const offerTest = _.some(treatments, (treatment) => {
            const offerTreatments = offer.getTreatmentIds(fullMenuOffers);
            return offerTreatments.indexOf(treatment.id) !== -1;
          });
          return offerTest;
        }),
      );
    } else {
      offersToRender = menuOffers;
    }

    this.trigger('group-count-change', groups.length);
    if (groups.length === 0 || !Wahanda.Permissions.viewMenu()) {
      // Return - nothing to render
      return;
    }

    const isFullFiltering = this.isFullFiltersEnabled();

    for (let i = 0; i < groups.length; i++) {
      const model = groups.at(i);
      const groupId = model.get('id');
      const groupActivity = model.get('active');
      const groupOfferCollection = offersToRender.filter(function (o) {
        return o.get('groupId') === groupId;
      });

      const maybeGroupActive = groupActivity && isFullFiltering;
      const maybeGroupInactive = !groupActivity && !isFullFiltering;

      if (maybeGroupActive || maybeGroupInactive || groupOfferCollection.length > 0) {
        // Do not render empty groups, if not full filters set
        const group = new App.Views.Menu.OfferGroup({
          model: model,
          el: this.$el,
          collection: new Backbone.Collection(groupOfferCollection),
          allOffers: offersToRender,
          employeeCategories: this.options.employeeCategories,
          treatments: this.options.treatments,
          venue: this.options.venue,
          featuredItemsCollection: this.options.featuredItemsCollection,
          parentView: this,
          enableSorting: isFullFiltering,
          employees: this.options.employees,
          fullMenu: this.fullMenu,
          menuTemplate: this.options.menuTemplate,
          roomTypes: this.options.roomTypes,
        });
        this.offerGroupViews.push(group);
        group.render();
      }
    }

    if (isFullFiltering) {
      this.setupSortable(this.$el);
      this.connectSortables();
    } else {
      this.destroySortable(this.$el);
    }
    this.$el.toggleClass('not-draggable', !isFullFiltering);

    if (!this.shadowsInitialized) {
      this.$el.shadows();
      this.shadowsInitialized = true;
    }

    if (this.scrollPosition > 0) {
      this.$el.scrollTop(this.scrollPosition);
    }
  },

  renderHeader: function () {
    this.$el.prepend('<div class="js-menu-content-header" />');

    if (this.$('.js-menu-content-header')) {
      App.ES6.Initializers.LayoutHeader({
        header: Wahanda.lang.menu.tabs.services,
        actions: [
          App.isMasquerading() && (
            <div className="iglu-import-services">
              <Button
                variant="secondary"
                size="medium"
                label={Wahanda.lang.menu.buttons.importServices}
              />
            </div>
          ),
          Wahanda.Permissions.editMenu() && (
            <Button
              dataTest="menu-offer-create-button"
              label={Wahanda.lang.menu.buttons.addNew}
              size="medium"
              onClick={this.showOfferTypeDialog}
            />
          ),
        ],
        node: this.$('.js-menu-content-header')[0],
      }).render();
    }
  },

  onOfferTypeChoose: function () {
    App.ES6.Initializers.OfferTypeChoiceDialog().destroy();
  },

  showOfferTypeDialog: function () {
    const self = this;
    OfferTypeChoiceDialogAnalytics.trackOfferChoiceModalClick();
    App.ES6.Initializers.OfferTypeChoiceDialog().render({
      onSelect: () => self.onOfferTypeChoose,
    });
  },

  onSortUpdate: function () {
    const allOrderList = this.fullMenu
      .get('groupsCollection')
      .toJSON()
      .map((model) => model.id);
    let orderList = [];
    this.$('.offer-group').each(function () {
      orderList.push($(this).data('offerGroupId'));
    });
    orderList = _.union(orderList, allOrderList);
    this.model.reorderGroups(orderList, () => {
      App.trigger(Wahanda.Event.MENU_GROUP_CHANGED_ORDER, {
        id: this.model.id,
      });
    });
  },

  /**
   * Connect offers so that they can be dropped into different groups.
   */
  connectSortables: function () {
    this.$('.offers').sortable({ connectWith: '.offers' });
  },

  saveScrollPosition: function () {
    this.scrollPosition = this.$el.scrollTop();
  },

  /**
   * Scrolls to the top of groups.
   */
  scrollToTop: function () {
    this.$el.animate({ scrollTop: 0 }, 500);
  },
});
