import { trackEvent, ACTIONS } from 'common/analytics';
import { ExportType } from './types';

const CATEGORY = 'trasactions';

export const TransactionsTracking = {
  trackRunExportClick: (type: ExportType) => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'run-export', type);
  },
};
