import { xhr } from 'common/xhr';

/**
 * Featured offer.
 */
App.Models.FeaturedOffer = BackboneEx.Model.Silent.extend({
  defaults: {
    venueId: null,
  },

  initialize: function () {},

  url: function () {
    // Set when needed.
    return null;
  },

  /**
   * Unfeature an offer.
   *
   * @param callback success OPTIONAL Success callback
   */
  unfeature: function (success) {
    var url = App.Api.wsUrl(
      '/venue/' + App.config.get('venue').id + '/offer/' + this.id + '/unfeature.json',
    );

    var self = this;
    xhr.postWithPromise(url).done(() => {
      if (self.collection) {
        self.collection.remove(self);
      }
      if (_.isFunction(success)) {
        success();
      }
    });
  },

  getVisibilityInfo: function () {
    return App.Models.MenuOffer.getVisibilityInfo(this.get('visibleFrom'), this.get('visibleTo'));
  },
});
