import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Chevron from 'components/common/icons/Chevron';
import style from './style.scss';

interface ILinkWithArrowProps extends React.HTMLAttributes<Element> {
  text: string;
  href: string;
  className?: string;
  onClick?: (...args: any[]) => any;
}

export class LinkWithArrow extends PureComponent<ILinkWithArrowProps, {}> {
  static defaultProps = {
    className: null,
    onClick: null,
  };

  onClick = (e) => {
    const { onClick } = this.props;
    if (!onClick) {
      return;
    }
    onClick(e);
  };

  render() {
    const { text, href, className } = this.props;
    return (
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={href}
        className={classnames(style.link, className)}
        onClick={this.onClick}
      >
        {text}
        <Chevron className={style.icon} />
      </a>
    );
  }
}
