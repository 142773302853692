import { produce } from 'immer';
import {
  CORONA_REPORT_REQUEST,
  CORONA_REPORT_SUCCESS,
  CORONA_REPORT_FAILURE,
  CORONA_REPORT_RESET,
} from 'reduxStore/actionsConst';
import { ActionUnionTypes } from 'reduxStore/actionCreator';
import * as actions from './actions';
import { ReportStatus } from './types';

interface State {
  status: ReportStatus;
}

const initialState: State = {
  status: ReportStatus.INITIAL,
};

export const coronaReportReducer = (
  state = initialState,
  action: ActionUnionTypes<typeof actions>,
) => {
  switch (action.type) {
    case CORONA_REPORT_REQUEST: {
      return produce(state, (draft) => {
        draft.status = ReportStatus.LOADING;
      });
    }
    case CORONA_REPORT_SUCCESS: {
      return produce(state, (draft) => {
        draft.status = ReportStatus.FINISH;
      });
    }
    case CORONA_REPORT_FAILURE: {
      return produce(state, (draft) => {
        draft.status = ReportStatus.ERROR;
      });
    }
    case CORONA_REPORT_RESET: {
      return produce(state, (draft) => {
        draft.status = ReportStatus.INITIAL;
      });
    }
    default:
      return state;
  }
};
