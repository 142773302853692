/* global Backbone */
/* global _ */
import BigNumber from 'bignumber.js';

/**
 * POS checkout line item view
 * Parent line item form view for Discounts, Payments and Products.
 *
 * Handles tasks common to all line items:
 *   Opening, rendering and closing the forms
 *   Updating the model on each change of the formData
 *   Handling the save event
 *   Saving the name and amount which are common to most line items - this
 *   means we don't have to subclass the Discounts, Card or Cash payments
 *   separately.
 *   If other line item fields need to be saved (voucher issuer etc) the Save
 *   call is handed off to the calling subclass, and they set their own events
 *   for those fields.
 *
 *  TODO There is probably a bit more de-duplication that could happen here,
 *  eg the updateName, etc, methods could probably live here rather than in
 *  Product and Payment subclasses
 */

const BaseView = Backbone.View;
App.Views.POS.LineItemForm = BaseView.extend({
  events: {
    'keyup .js-line-item-name': 'onKeyDown',
    'focus .js-line-item-name': 'onKeyDown',
    'blur .js-line-item-name': 'onKeyDown',
    'keyup .js-line-item-amount': 'onKeyDown',
    'focus .js-line-item-amount': 'onKeyDown',
    'blur .js-line-item-amount': 'onKeyDown',
    'keyup .js-product-discount': 'onKeyDown',
    'focus .js-product-discount': 'onKeyDown',
    'blur .js-product-discount': 'onKeyDown',
    'click .js-cancel-payment': 'close',
    'click .js-add-line-item': 'save',
  },

  initialize: function initialize() {
    const self = this;
    this.controller = self.options.controller;
    this.formData = self.model.clone();
    this.formData.on('change', () => {
      self.setViewState();
    });
  },

  render: function render() {
    const templateModel = _.extend(
      {
        currencySymbol: App.config.get('currency').symbol,
      },
      this.options,
      this.model.attributes,
    );

    this.$el.html(Wahanda.Template.renderTemplate(this.templateId, templateModel));

    this.renderCurrencyInput();

    // Validate prepopulated fields
    const $currencyInput = this.$el.find('.js-line-item-amount');
    this.updateAmount($currencyInput.val());
    const $cardTypeSelect = this.$el.find('.js-card-type');
    if ($cardTypeSelect.length > 0) {
      this.updateCardType($cardTypeSelect.val());
    }
  },

  renderCurrencyInput: function renderCurrencyInput() {
    const checkoutCalc = this.controller.checkoutCalc;
    const view = new App.Views.POS.CurrencyInput({
      el: this.$('.js-currency-input'),
      dataTest: 'pos-payment-input',
      cssClass: 'js-line-item-amount',
      cssContainerClass: 'currency-input--large',
      value: Wahanda.Number.formatFloatForPOSInput(checkoutCalc.getOutstanding()) || 0,
    });
    view.render();
  },

  onKeyDown: function onKeyDown(e) {
    this.controller.applyFocusStyles($(e.target));
    e.preventDefault();

    const value = $(e.target).val();
    if ($(e.target).hasClass('js-line-item-name')) {
      this.updateName(value);
    }

    if ($(e.target).hasClass('js-line-item-amount')) {
      // format then repopulate input
      this.updateAmount(value);
      if (e.type === 'focusout') {
        const $input = $(e.target);
        const floatPrice = Wahanda.Number.formatPOSInputintoFloat($input.val());
        $input.val(Wahanda.Number.formatFloatForInput(floatPrice));
      }
    }

    if ($(e.target).hasClass('js-product-discount')) {
      const numericValue = value.replace(/[^0-9]/g, ''); // remove non numbers
      this.updateDiscountAmount(numericValue);
      if (e.type === 'focusout') {
        const $input = $(e.target);
        $input.val(numericValue);
      }
    }

    // Finally save
    if (e.keyCode === 13) {
      this.save();
    }
  },

  destroy: function destroy() {
    this.remove();
  },

  updateDiscountAmount: function updateDiscountAmount(discountPercentage) {
    const discount = new BigNumber(discountPercentage);

    if (discount.isNaN() || discount.isEqualTo(0)) {
      this.updateFormData('discountPercentage', null);
      return;
    }

    if (discount.isGreaterThanOrEqualTo(100)) {
      this.updateFormData('discountPercentage', 100);
      return;
    }

    this.updateFormData('discountPercentage', discount.toNumber());
  },

  updateFormData: function updateFormData(property, value) {
    let newValue = value;
    if ($.isNumeric(value)) {
      newValue = Number(value);
    }
    this.formData.set(property, newValue, {
      silent: true,
    });
    this.formData.change();
  },

  setAddLineItemButtonState: function setAddLineItemButtonState() {
    const enableButton = this.formData.isValid();
    const buttonClass = 'pos-checkout--button-disabled';

    this.$el
      .find(this.addLineItemButtonClass)
      .prop('disabled', !enableButton)
      .toggleClass(buttonClass, !enableButton);
  },
});

App.Views.POS.LineItemView = BaseView.extend({
  initialize: function initialize() {
    const self = this;
    this.controller = this.options.controller;
    this.model.on('remove', () => {
      self.remove();
    });

    this.model.on('change', () => {
      self.render();
      self.controller.updateTotals();
    });
  },
});
