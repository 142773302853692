App.Models.Config = Backbone.Model.extend({
  ALLOCATION_TYPE_STATIC: 'S',
  ALLOCATION_TYPE_DYNAMIC: 'D',

  defaults: {
    venueId: null,
  },

  initialize: function initialize() {},

  urlRoot: function urlRoot() {
    const base = '/extranet-settings.json';
    const venueId = this.get('venueId');
    const wsName = venueId ? `/venue/${venueId}${base}` : base;
    return App.Api.wsUrl(wsName);
  },

  accountFullName: function accountFullName() {
    const account = this.get('account');
    if (account == null) {
      return '';
    }

    if (account.masquerade) {
      return account.entityName || '';
    }
    return `${account.firstName || ''} ${account.lastName || ''}`;
  },

  accountEmployeeName: function accountEmployeeName() {
    const account = this.get('account');

    if (account == null) {
      return '';
    }

    return account.employeeName || '';
  },

  venueName: function venueName() {
    const venue = this.get('venue');
    let name = '';
    if (venue) {
      name = venue.name || '';
    }
    return name;
  },

  isLockdownAndNotMasquerading: function isLockdownAndNotMasquerading() {
    const account = this.get('account');
    const masquerade = account && account.masquerade;
    return App.isFeatureSupported('benelux-lockdown') && !masquerade;
  },

  currentVenueHash: function currentVenueHash() {
    return `#venue/${this.get('venue').id}`;
  },

  isVenueSingleConnect: function isVenueSingleConnect() {
    return this.get('pricingPlanType') === 'SC';
  },

  canShowPrepayRequirementFeatures: function canShowPrepayRequirementFeatures() {
    return (
      this.get('venue').supportsMultiplePaymentOptions &&
      App.isFeatureSupported('prepayment-required')
    );
  },

  canSellDated: function canSellDated() {
    // Returning false because of escapes sunsetting
    return false;
  },

  canRedeemEvouchers: function canRedeemEvouchers() {
    const venue = this.get('venue');
    // Copypasted canSellDated because voucher redemption will be cleaned up later
    const canSellDated =
      this.get('venue').canSellDatedServices === true && App.isFeatureSupported('escape-services');

    return Boolean(
      canSellDated &&
        App.isFeatureSupported('voucher-fulfillment') &&
        venue &&
        venue.supplierId > 0,
    );
  },

  hasVenue: function hasVenue(venueId) {
    const venueIdVal = parseInt(venueId, 10);
    return (
      _.find(this.get('venues'), function findVenue(venue) {
        return venueIdVal === venue.id;
      }) != null
    );
  },

  /**
   * Return current authenticated user's account id.
   *
   * @return int or null
   */
  getAccountId: function getAccountId() {
    const account = this.get('account');
    if (account) {
      return account.id;
    }
    return null;
  },

  /**
   * Get the employee ID of the currently authenticated user in for venue, if any.
   *
   * @return int or null
   */
  getAccountEmployeeId: function getAccountEmployeeId() {
    const account = this.get('account');
    if (account) {
      return account.employeeId;
    }
    return null;
  },

  getAccountEmployeeRoleCode() {
    return this.get('account').employeeRoleCode;
  },

  /**
   * @return int
   */
  getFirstDayOfWeek: function getFirstDayOfWeek() {
    return Wahanda.Date.dayStringToNumberMap[this.get('jqueryDateFormat').firstDayOfWeek];
  },

  /**
   * Returns link to external sites the venue is shown.
   *
   * @param string type
   * @return String or null
   */
  getLinkByType: function getLinkByType(type) {
    const match = _.find(this.get('venue').links, function findLink(link) {
      return link.typeCode === type;
    });

    if (match) {
      return match.uri;
    }
    return null;
  },

  isVenueActive: function isVenueActive() {
    return this.get('venue').venueStatusType === 'A';
  },

  isVenuePending: function isVenuePending() {
    return this.get('venue').venueStatusType === 'P';
  },

  isVenueTemporarilyDisabled: function isVenueTemporarilyDisabled() {
    return this.get('venue').venueStatusType === 'T';
  },

  isVenuePendingOrTemporarilyDisabled: function isVenuePendingOrTemporarilyDisabled() {
    return this.isVenuePending() || this.isVenueTemporarilyDisabled();
  },

  canRescheduleMarketplaceAppointments: function canRescheduleMarketplaceAppointments() {
    return !!(this.get('venue') || {}).permitAppointmentReschedule;
  },

  getContentChannel: function getContentChannel() {
    const channelCode = this.get('venue').contentChannelCode;
    // Make sure we don't create a never ending redirect loop.
    if (!App.isValidContentChannel(channelCode)) {
      return 'WHN_GB';
    }
    return channelCode;
  },

  getGoogleMapApiKey: function getGoogleMapApiKey() {
    return App.GOOGLE_MAP_KEY;
  },

  contentChannelMatches: function contentChannelMatches(channel) {
    return this.getContentChannel() === channel;
  },

  getChannelSupportedLocales: function getChannelSupportedLocales() {
    return this.get('channel').supportedLocales;
  },

  hasFeatureSupport: function hasFeatureSupport(feature) {
    const list = this.get('supportedFeatures') || {};
    return _.contains(list, feature);
  },

  hasChannelFeature: function hasChannelFeature(feature) {
    const list = this.get('contentChannelFeatures') || {};
    return _.contains(list, feature);
  },

  getNumberFormat: function getNumberFormat(forcedParams) {
    return _.defaults(forcedParams || {}, {
      forceDecimalPoint: false,
      thousandsSeparator: this.get('numberFormat').thousandsSeparator,
      decimalPoint: this.get('numberFormat').decimalPoint,
    });
  },

  getDefaultCancellationPolicyHoursValue: function getDefaultCancellationPolicyHoursValue(name) {
    return (this.get('venue') || {})[name] || null;
  },

  getVenueWorkTime: function getVenueWorkTime() {
    const ttm = Wahanda.Time.timeToMinutes;
    return {
      opens: ttm(this.get('venue').dayStartsAt),
      closes: ttm(this.get('venue').dayEndsAt),
    };
  },

  getAppointmentCustomerContactsConfig: function getAppointmentCustomerContactsConfig() {
    const venue = this.get('venue') || {};
    const data = venue.discloseClientDetailsOption || {};

    return {
      showWhen: data.type || 'ALWAYS',
      minutes: data.minutes,
    };
  },

  getPaymentThresholdAmountFormatted: function getPaymentThresholdAmountFormatted() {
    return Wahanda.Currency.format(this.get('paymentThresholdAmount'));
  },

  isVatPayerFeatureEnabled: function isVatPayerFeatureEnabled() {
    return this.hasFeatureSupport('vat-payer-support');
  },

  isMenuServiceTemplatesEnabled: function isMenuServiceTemplatesEnabled() {
    return this.hasFeatureSupport('menu-service-templte');
  },

  isSupplierPaymentsEnabled: function isSupplierPaymentsEnabled() {
    return this.hasFeatureSupport('supplier-payments');
  },

  isFBEInstalled: function isFBEInstalled() {
    return Wahanda.LocalStorage.getForVenue('fbe-installed');
  },

  /**
   * Calendar polling interval
   *
   * @param {String} type one of [default, pushEnabled]
   * @returns {Integer} Polling interval, in minutes.
   */
  getPollingInterval: function getPollingInterval(type) {
    const intervals = this.get('pollingIntervalMinutes');

    return intervals[type] > 0 ? intervals[type] : intervals.default;
  },

  isPosFull: function isPosFull() {
    return this.get('venue').pointOfSaleEnabled;
  },
});
