import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import merge from 'lodash/merge';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import moment from 'common/moment';

import { ShiftWorkingHourType } from '../utils/types';

import {
  getVenueBusinessHoursSuccess,
  getVenueBusinessHoursFailure,
  getVenueWorkingHours,
  getVenueWorkingHoursSuccess,
  getVenueWorkingHoursFailure,
  getEmployeesDay,
  getEmployeesDaySuccess,
  getEmployeesDayFailure,
  getEmployeesWorkingHours,
  getEmployeesWorkingHoursSuccess,
  getEmployeesWorkingHoursFailure,
  getEmployeeMonthWorkingHours,
  getEmployeeMonthWorkingHoursSuccess,
  getEmployeeMonthWorkingHoursFailure,
  getEmployeesSchedule,
  getEmployeesScheduleSuccess,
  getEmployeesScheduleFailure,
  setEmployeesSchedule,
  setEmployeesScheduleSuccess,
  setEmployeesScheduleFailure,
  invalidateEmployeesSchedule,
  invalidateEmployeesScheduleSuccess,
  invalidateEmployeesScheduleFailure,
  setEmployeesShift,
  setEmployeesShiftSuccess,
  setEmployeesShiftFailure,
  setEmployeesOpenDay,
  setEmployeesOpenDaySuccess,
  setEmployeesOpenDayFailure,
  setEmployeesTimeOff,
  setEmployeesTimeOffSuccess,
  setEmployeesTimeOffFailure,
  setVenueWorkingHours,
  setVenueWorkingHoursSuccess,
  setVenueWorkingHoursFailure,
} from './actions';

export function* getVenueBusinessHoursRequested() {
  try {
    const payload = yield call(api.get, apiUrl('VENUE_BUSINESS_HOURS'));

    yield put(getVenueBusinessHoursSuccess({ businessHours: payload.openingHours }));
  } catch (error) {
    yield put(getVenueBusinessHoursFailure());
  }
}

export function* getVenueWorkingHoursRequested(action: ReturnType<typeof getVenueWorkingHours>) {
  const { dateFrom, dateTo } = action.payload;
  try {
    const payload = yield fetchVenueWorkingHours({ dateFrom, dateTo });

    yield put(getVenueWorkingHoursSuccess(payload));
  } catch (error) {
    yield put(getVenueWorkingHoursFailure());
  }
}

export function* setVenueWorkingHoursRequested(action: ReturnType<typeof setVenueWorkingHours>) {
  const { date, timeSlots, type } = action.payload;
  try {
    // Map types for old custom-working-hours api
    const types = {
      DAY_OFF: 'N',
      CUSTOM: 'C',
      STANDARD: 'S',
    };

    let customOpeningTime;
    let customClosingTime;
    switch (type) {
      case ShiftWorkingHourType.DAY_OFF:
      case ShiftWorkingHourType.STANDARD:
        customOpeningTime = null;
        customClosingTime = null;
        break;
      default:
        customOpeningTime = timeSlots[0].timeFrom;
        customClosingTime = timeSlots[0].timeTo;
        break;
    }

    const data = {
      customOpeningTime,
      customClosingTime,
      openingTimeTypeCode: types[type],
    };
    yield call(api.put, apiUrl('VENUE_WORKING_HOURS_SET_CUSTOM', { date }), data);

    const payload = {
      date,
      type,
      timeSlots: [timeSlots[0]],
    };

    yield put(setVenueWorkingHoursSuccess(payload));
  } catch (error) {
    yield put(setVenueWorkingHoursFailure());
  }
}

function getEmployeeIds(payload: { employees: { id: number }[]; employeeId?: number | null }) {
  const { employees, employeeId } = payload;
  // If employeeId was passed to saga then only use that employee
  if (employeeId) {
    return employeeId;
  }
  return employees && employees.map((employee) => employee.id).join(',');
}

function getWeekDateRange(payload: { date: string }) {
  return {
    dateFrom: moment(payload.date).startOf('isoWeek').formatApiDateString(),
    dateTo: moment(payload.date).endOf('isoWeek').formatApiDateString(),
  };
}

function fetchEmployeeWorkingHours(payload: {
  employeeIds: string | number;
  dateFrom: string;
  dateTo?: string;
}) {
  return call(
    api.get,
    apiUrl('EMPLOYEES_WORKING_HOURS', {
      employeeIds: payload.employeeIds,
      dateFrom: payload.dateFrom,
      dateTo: payload.dateTo || payload.dateFrom,
    }),
  );
}

function fetchVenueWorkingHours(payload: { dateFrom: string; dateTo?: string }) {
  return call(
    api.get,
    apiUrl('VENUE_WORKING_HOURS', {
      dateFrom: payload.dateFrom,
      dateTo: payload.dateTo || payload.dateFrom,
    }),
  );
}

function getEmployeeImages(employees) {
  return (
    employees &&
    employees.reduce(
      (o, employee, index) => ({
        ...o,
        [index]: {
          employeeId: employee.id,
          employeeImage: employee.image && employee.image.uris['72x72'],
        },
      }),
      [],
    )
  );
}

export function* getEmployeesWorkingHoursRequested(
  action: ReturnType<typeof getEmployeesWorkingHours>,
) {
  const { dateFrom, dateTo, employeeId } = action.payload;
  try {
    const { employees } = yield call(
      api.get,
      apiUrl('EMPLOYEES', { active: true, takesAppointments: true }),
    );
    // There might not be any employees so return failure
    if (employees && !employees.length) {
      throw new Error('There are no employees');
    }

    const employeeIds = getEmployeeIds({ employees, employeeId });
    const employeeImages = yield getEmployeeImages(employees);
    const payload = yield fetchEmployeeWorkingHours({
      employeeIds,
      dateFrom,
      dateTo,
    });

    payload.employeesWorkingHours.map((employee) => {
      return merge(employee, find(employeeImages, { employeeId: employee.employeeId }));
    });

    const sortedCollection = sortBy(payload.employeesWorkingHours, (item) => {
      return employees.map((employee) => employee.id).indexOf(item.employeeId);
    });

    yield put(
      getEmployeesWorkingHoursSuccess({
        datePickerRange: { dateFrom, dateTo },
        employeesWorkingHours: sortedCollection,
      }),
    );
  } catch (error) {
    yield put(getEmployeesWorkingHoursFailure());
  }
}

export function* getEmployeeMonthWorkingHoursRequested(
  action: ReturnType<typeof getEmployeeMonthWorkingHours>,
) {
  const { dateFrom, dateTo, employeeId } = action.payload;
  try {
    const venuePayload = yield fetchVenueWorkingHours({ dateFrom, dateTo });
    const payload = yield fetchEmployeeWorkingHours({
      employeeIds: `${employeeId}`,
      dateFrom,
      dateTo,
    });

    yield put(
      getEmployeeMonthWorkingHoursSuccess({
        employeeId,
        datePickerRange: { dateFrom, dateTo },
        workingHours: venuePayload.workingHours,
        employeeWorkingHours: payload.employeesWorkingHours[0].workingHours,
      }),
    );
  } catch (error) {
    yield put(getEmployeeMonthWorkingHoursFailure());
  }
}

export function* getEmployeesDayHoursRequested(action: ReturnType<typeof getEmployeesDay>) {
  const { date } = action.payload;
  try {
    const { employees } = yield call(
      api.get,
      apiUrl('EMPLOYEES', { active: true, takesAppointments: true }),
    );

    const employeeIds = getEmployeeIds({ employees });
    const payload = yield fetchEmployeeWorkingHours({
      employeeIds,
      dateFrom: date,
    });

    const sortedCollection = sortBy(payload.employeesWorkingHours, (item) => {
      return employees.map((employee) => employee.id).indexOf(item.employeeId);
    });

    yield put(
      getEmployeesDaySuccess({
        date,
        employeesWorkingHours: sortedCollection,
      }),
    );
  } catch (error) {
    yield put(getEmployeesDayFailure());
  }
}

export function* setEmployeesShiftRequested(action: ReturnType<typeof setEmployeesShift>) {
  const { employeeId, date, fetchShiftData, type, timeSlots, venueTimeSlots } = action.payload;
  try {
    let timeSlotsArray;
    switch (type) {
      case ShiftWorkingHourType.DAY_OFF:
        timeSlotsArray = [];
        break;
      case ShiftWorkingHourType.VENUE:
        timeSlotsArray = [venueTimeSlots];
        break;
      default:
        timeSlotsArray = timeSlots;
        break;
    }
    // DAY_OFF constant is only used for UX
    const isTypeDayOff = type === ShiftWorkingHourType.DAY_OFF;
    const isTypeVenue = type === ShiftWorkingHourType.VENUE;
    const typeValue = isTypeDayOff ? ShiftWorkingHourType.CUSTOM : type;

    const hours = { date, timeSlots: timeSlotsArray };
    const data = isTypeVenue ? { dates: [date] } : { workingHours: [hours] };
    let workingHours;

    const apiUrlVal = isTypeVenue
      ? 'EMPLOYEES_WORKING_HOURS_REMOVE_CUSTOM'
      : 'EMPLOYEES_WORKING_HOURS_SET_CUSTOM';

    yield call(api.post, apiUrl(apiUrlVal, { employeeId }), data);

    if (fetchShiftData) {
      const workingHoursPayload = yield fetchEmployeeWorkingHours({
        employeeIds: employeeId,
        dateFrom: date,
      });
      workingHours =
        workingHoursPayload && workingHoursPayload.employeesWorkingHours[0].workingHours[0];
    }

    const payload = {
      date,
      employeeId,
      workingHours: workingHours || {
        date,
        type: typeValue,
        timeSlots: timeSlotsArray,
      },
    };

    yield put(setEmployeesShiftSuccess(payload));
  } catch (error) {
    yield put(setEmployeesShiftFailure());
  }
}

export function* setEmployeesOpenDayRequested(action: ReturnType<typeof setEmployeesOpenDay>) {
  const { employeeId, dates, dateFrom, dateTo, types } = action.payload;
  try {
    yield call(api.post, apiUrl('EMPLOYEES_WORKING_HOURS_REMOVE_CUSTOM', { employeeId }), {
      dates,
      types,
    });

    const payload = yield fetchEmployeeWorkingHours({
      employeeIds: employeeId,
      dateFrom: dateFrom || dates[0],
      dateTo: dateTo || dateFrom || dates[0],
    });

    yield put(
      setEmployeesOpenDaySuccess({
        employeeId,
        employeeWorkingHours: payload && payload.employeesWorkingHours[0].workingHours,
      }),
    );
  } catch (error) {
    yield put(setEmployeesOpenDayFailure());
  }
}

export function* setEmployeesTimeOffRequested(action: ReturnType<typeof setEmployeesTimeOff>) {
  const { employeeId, dates, dateFrom, dateTo } = action.payload;
  try {
    const data = dates.map((date) => ({
      date,
      timeSlots: [],
    }));
    let payload;

    yield call(api.post, apiUrl('EMPLOYEES_WORKING_HOURS_SET_CUSTOM', { employeeId }), {
      workingHours: data,
    });

    if (!!dateFrom && !!dateTo) {
      payload = yield fetchEmployeeWorkingHours({
        employeeIds: employeeId,
        dateFrom,
        dateTo,
      });
    }

    yield put(
      setEmployeesTimeOffSuccess({
        employeeId,
        employeeWorkingHours: payload && payload.employeesWorkingHours[0].workingHours,
      }),
    );
  } catch (error) {
    yield put(setEmployeesTimeOffFailure());
  }
}

export function* getEmployeesScheduleRequested(action: ReturnType<typeof getEmployeesSchedule>) {
  const { employeeId, scheduleId } = action.payload;
  try {
    const payload = yield call(
      api.get,
      apiUrl('EMPLOYEES_SCHEDULES_GET', { employeeId, scheduleId }),
    );

    yield put(getEmployeesScheduleSuccess(payload));
  } catch (error) {
    yield put(getEmployeesScheduleFailure());
  }
}

export function* setEmployeesScheduleRequested(action: ReturnType<typeof setEmployeesSchedule>) {
  const { employeeId, dailySchedules, dateTo, validFrom, type } = action.payload;
  try {
    const data = { dailySchedules, type, validFrom };

    yield call(api.post, apiUrl('EMPLOYEES_SCHEDULES_SET', { employeeId }), data);
    const { dateFrom } = getWeekDateRange({ date: validFrom });
    const payload = yield fetchEmployeeWorkingHours({
      employeeIds: employeeId,
      dateFrom,
      dateTo,
    });

    yield put(
      setEmployeesScheduleSuccess({
        employeeId,
        employeeWorkingHours: payload && payload.employeesWorkingHours[0].workingHours,
      }),
    );
  } catch (error) {
    yield put(setEmployeesScheduleFailure());
  }
}

export function* invalidateEmployeesScheduleRequested(
  action: ReturnType<typeof invalidateEmployeesSchedule>,
) {
  const { employeeId, scheduleId, invalidFrom, dateTo } = action.payload;
  try {
    yield call(api.post, apiUrl('EMPLOYEES_SCHEDULES_INVALIDATE', { employeeId, scheduleId }), {
      invalidFrom,
    });
    const { dateFrom } = getWeekDateRange({ date: invalidFrom });
    const payload = yield fetchEmployeeWorkingHours({
      employeeIds: employeeId,
      dateFrom,
      dateTo,
    });

    yield put(
      invalidateEmployeesScheduleSuccess({
        employeeId,
        employeeWorkingHours: payload && payload.employeesWorkingHours[0].workingHours,
      }),
    );
  } catch (error) {
    yield put(invalidateEmployeesScheduleFailure());
  }
}
