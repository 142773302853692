import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from 'common/backbone-app';
import { requestVatReportDataAction } from 'components/reports/newSalesReports/actions';
import VatReport from './index';

const mapStateToProps = (state) => ({
  venueName: App.config.venueName(),
  isPosFull: App.config.isPosFull(),
  reportData: state.salesReport.vatReport.reportData,
  displayState: state.salesReport.vatReport.displayState,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ requestVatReportDataAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ reportData: null; }' is not assignable to ... Remove this comment to see the full error message
export default connector(VatReport);
