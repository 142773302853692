import { trackEvent, ACTIONS, CalendarAnalytics } from 'common/analytics';
import { ShiftWorkingHourType, ShiftCustomTypes, ScheduleChangeOptionType } from './utils/types';

export class ShiftsAnalytics {
  public static CATEGORY = 'shifts';

  public static trackRedirectToOpeningHoursClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'venue-opening-hours');
  }

  public static trackDatePickerNextClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'arrow-next-week');
  }

  public static trackDatePickerPrevClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'arrow-prev-week');
  }

  public static trackDatePickerCurrentClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'arrow-current-week');
  }

  public static trackMonthNextClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'arrow-next-month');
  }

  public static trackMonthPrevClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'arrow-prev-month');
  }

  public static trackMonthCurrentClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'arrow-current-month');
  }

  public static trackDatePickerChangeClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'date-picker-change');
  }

  public static trackEmployeeCellClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'employee-workday');
  }

  public static trackVenueCellClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'venue-workday');
  }

  /*
   * Employee tracking
   */

  public static trackEmployeeMonthClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'employee-month');
  }

  public static trackEmployeeMonthBackClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'employee-month-back');
  }

  public static trackEmployeeChangeClick() {
    trackEvent(ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'employee-change');
  }

  public static trackShiftActionsModalView({ category }) {
    trackEvent(category || ShiftsAnalytics.CATEGORY, ACTIONS.VIEW, 'employee-action-modal');
  }

  public static trackShiftDayModalView({ category }) {
    trackEvent(category || ShiftsAnalytics.CATEGORY, ACTIONS.VIEW, 'employee-edit-shift');
  }

  public static trackShiftDayModalClose({ category }) {
    trackEvent(category || ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'employee-edit-shift-close');
  }

  public static trackShiftDayModalSubmit(payload: {
    category?: string | null;
    property: ShiftWorkingHourType;
  }) {
    trackEvent(
      payload.category || ShiftsAnalytics.CATEGORY,
      ACTIONS.SUBMIT,
      'employee-edit-shift-submit',
      payload.property,
    );
  }

  public static trackShiftTimeOffModalView({ category }) {
    trackEvent(category || ShiftsAnalytics.CATEGORY, ACTIONS.VIEW, 'employee-edit-time-off');
  }

  public static trackShiftTimeOffModalClose({ category }) {
    trackEvent(category || ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'employee-edit-time-off-close');
  }

  public static trackShiftTimeOffModalSubmit(payload: {
    category?: string | null;
    property: number;
  }) {
    trackEvent(
      payload.category || ShiftsAnalytics.CATEGORY,
      ACTIONS.SUBMIT,
      'employee-edit-time-off-submit',
      payload.property,
    );
  }

  public static trackShiftRemoveCustomModalView({ category }) {
    trackEvent(category || ShiftsAnalytics.CATEGORY, ACTIONS.VIEW, 'employee-remove-day-off');
  }

  public static trackShiftRemoveCustomModalClose({ category }) {
    trackEvent(
      category || ShiftsAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'employee-remove-day-off-close',
    );
  }

  public static trackShiftRemoveCustomModalSubmit(payload: {
    category?: string | null;
    property?: ShiftCustomTypes[];
    value: number;
  }) {
    trackEvent(
      payload.category || ShiftsAnalytics.CATEGORY,
      ACTIONS.SUBMIT,
      'employee-remove-day-off-submit',
      payload.property?.join(', '),
      payload.value,
    );
  }

  public static trackShiftScheduleModalView({ category }) {
    trackEvent(category || ShiftsAnalytics.CATEGORY, ACTIONS.VIEW, 'employee-add-schedule');
  }

  public static trackShiftScheduleModalClose({ category }) {
    trackEvent(category || ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'employee-add-schedule-close');
  }

  public static trackShiftScheduleModalSubmit({ category }) {
    trackEvent(
      category || ShiftsAnalytics.CATEGORY,
      ACTIONS.SUBMIT,
      'employee-add-schedule-submit',
    );
  }

  public static trackShiftScheduleChangeModalView({ category }) {
    trackEvent(category || ShiftsAnalytics.CATEGORY, ACTIONS.VIEW, 'employee-add-schedule-change');
  }

  public static trackShiftScheduleChangeModalClose({ category }) {
    trackEvent(
      category || ShiftsAnalytics.CATEGORY,
      ACTIONS.VIEW,
      'employee-add-schedule-change-close',
    );
  }

  public static trackShiftScheduleChangeModalSubmit(payload: {
    category?: string | null;
    property: ScheduleChangeOptionType;
  }) {
    trackEvent(
      payload.category || ShiftsAnalytics.CATEGORY,
      ACTIONS.SUBMIT,
      'employee-add-schedule-change-submit',
      payload.property,
    );
  }

  /*
   * Venue tracking
   */

  public static trackVenueShiftActionsModalView() {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.VIEW, 'venue-action-modal');
  }

  public static trackVenueShiftDayModalView({ category }) {
    trackEvent(category || ShiftsAnalytics.CATEGORY, ACTIONS.VIEW, 'venue-edit-shift');
  }

  public static trackVenueShiftDayModalClose({ category }) {
    trackEvent(category || ShiftsAnalytics.CATEGORY, ACTIONS.CLICK, 'venue-edit-shift-close');
  }

  public static trackVenueShiftDayModalSubmit({ category, property }) {
    trackEvent(
      category || ShiftsAnalytics.CATEGORY,
      ACTIONS.SUBMIT,
      'venue-edit-shift-submit',
      property,
    );
  }

  public static trackShiftGoToRotaClick() {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.CLICK, 'employee-modal-to-rota');
  }

  public static trackVenueShiftGoToRotaClick() {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.CLICK, 'venue-modal-to-rota');
  }
}
