/**
 * Role Permissions.
 */
App.Models.RolePermissions = BackboneEx.Model.Silent.extend({
  initialize: function () {},

  url: function () {
    var base;
    base = '/role-permissions.json';
    return App.Api.wsUrl(base);
  },
});
