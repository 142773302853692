import { call, put } from 'redux-saga/effects';
import apiUrl from 'common/api-url';
import * as api from 'reduxStore/apiService';
import { AvailabilityResponse } from './types';
import {
  getAppointmentAvailabilitySuccessAction,
  getAppointmentAvailabilityFailureAction,
} from './actions';

export function* getAppointmentAvailabilityRequested({ payload }) {
  try {
    const response: AvailabilityResponse = yield call(
      api.get,
      apiUrl('APPOINTMENT_AVAILABILITY', payload),
    );

    yield put(
      getAppointmentAvailabilitySuccessAction({
        ...response,
        uuid: payload.uuid,
      }),
    );
  } catch (err) {
    yield put(getAppointmentAvailabilityFailureAction());
  }
}
