import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { requestProductOrdersAction, productOrdersReset } from './actions';

import ProductOrdersList from './index';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ requestProductOrdersAction, productOrdersReset }, dispatch),
});

const mapStateToProps = ({ productOrders, productSuppliers }) => ({
  orders: productOrders.orders,
  ordersHaveBeenFetched: productOrders.ordersHaveBeenFetched,
  loading: productOrders.loadingOrdersList || productSuppliers.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductOrdersList);
