import {
  EMPLOYEES_REQUEST,
  EMPLOYEES_RECEIVE,
  EMPLOYEES_FAILURE,
  EMPLOYEES_RESET,
  EMPLOYEES_SET_EMPLOYEE_DATA,
  EMPLOYEES_SUBMIT_EMPLOYEE,
  EMPLOYEES_EMPLOYEE_SUBMIT_SUCCESS,
  EMPLOYEES_EMPLOYEE_REQUEST,
  EMPLOYEES_EMPLOYEE_RECEIVE,
  EMPLOYEES_EMPLOYEE_DATA_RESET,
  EMPLOYEES_CREATE_EMPLOYEE,
  EMPLOYEES_DELETE_EMPLOYEE,
  EMPLOYEES_DELETE_EMPLOYEE_SUCCESS,
  EMPLOYEES_EXTERNAL_CALENDAR_LINK,
  EMPLOYEES_EXTERNAL_CALENDAR_LINK_SUCCESS,
  EMPLOYEES_EXTERNAL_CALENDAR_UNLINK,
  EMPLOYEES_EXTERNAL_CALENDAR_UNLINK_SUCCESS,
  EMPLOYEES_PERMISSIONS_BY_ROLES_REQUEST,
  EMPLOYEES_PERMISSIONS_BY_ROLES_RECEIVE,
  EMPLOYEES_CATEGORIES_REQUEST,
  EMPLOYEES_CATEGORIES_RECEIVE,
} from 'reduxStore/actionsConst';

export const requestEmployeesAction = (params) => ({
  type: EMPLOYEES_REQUEST,
  params,
});

export const receiveEmployeesAction = (data) => ({
  type: EMPLOYEES_RECEIVE,
  data,
});

export const requestEmployeesFailureAction = (error) => {
  if (typeof error === 'object') {
    const { serverErrorName, serverErrorData, serverErrorAction } = error;

    return {
      type: EMPLOYEES_FAILURE,
      serverErrorName,
      serverErrorData,
      serverErrorAction,
    };
  }

  return {
    type: EMPLOYEES_FAILURE,
    serverErrorName: error,
  };
};

export const resetStateAction = () => ({
  type: EMPLOYEES_RESET,
});

export const setEmployeeDataAction = (data) => ({
  type: EMPLOYEES_SET_EMPLOYEE_DATA,
  data,
});

export const submitEmployeeAction = () => ({
  type: EMPLOYEES_SUBMIT_EMPLOYEE,
});

export const receiveEmployeeSubmitSuccessAction = () => ({
  type: EMPLOYEES_EMPLOYEE_SUBMIT_SUCCESS,
});

export const requestEmployeeAction = (id) => ({
  type: EMPLOYEES_EMPLOYEE_REQUEST,
  id,
});

export const receiveEmployeeAction = (data) => ({
  type: EMPLOYEES_EMPLOYEE_RECEIVE,
  data,
});

export const resetEmployeeDataAction = () => ({
  type: EMPLOYEES_EMPLOYEE_DATA_RESET,
});

export const deleteEmployeeAction = (id) => ({
  type: EMPLOYEES_DELETE_EMPLOYEE,
  id,
});

export const receiveEmployeeDeleteSuccessAction = () => ({
  type: EMPLOYEES_DELETE_EMPLOYEE_SUCCESS,
});

export const linkExternalCalendarAction = () => ({
  type: EMPLOYEES_EXTERNAL_CALENDAR_LINK,
});

export const externalCalendarLinkSuccessAction = () => ({
  type: EMPLOYEES_EXTERNAL_CALENDAR_LINK_SUCCESS,
});

export const unlinkExternalCalendarAction = () => ({
  type: EMPLOYEES_EXTERNAL_CALENDAR_UNLINK,
});

export const externalCalendarUnlinkSuccessAction = () => ({
  type: EMPLOYEES_EXTERNAL_CALENDAR_UNLINK_SUCCESS,
});

export const requestPermissionsByRolesAction = () => ({
  type: EMPLOYEES_PERMISSIONS_BY_ROLES_REQUEST,
});

export const receivePermissionsByRolesAction = (data) => ({
  type: EMPLOYEES_PERMISSIONS_BY_ROLES_RECEIVE,
  data,
});

export const requestCategoriesAction = () => ({
  type: EMPLOYEES_CATEGORIES_REQUEST,
});

export const receiveCategoriesAction = (data) => ({
  type: EMPLOYEES_CATEGORIES_RECEIVE,
  data,
});

export const createEmployeeAction = () => ({
  type: EMPLOYEES_CREATE_EMPLOYEE,
});
