import * as runtime from 'module-federation-runtime';
import { loadScript } from '../../javascripts/wahanda/loadScript';

type Container = {
  get: (path: string) => Promise<void>;
  init: (sharedScope: unknown) => Promise<void>;
};

type Props = {
  remoteUrl: string;
  scope: string;
  module: string;
};

/**
  * Loads a remote module and then initialises the container inside it
  * For example:
      fetchRemoteModule({
        remoteUrl: 'http://localhost:5001/remoteEntry.js',
        scope: 'reconnect',
        module: './remoteEntry',
      })
  *
  */
export async function fetchRemoteModule({ remoteUrl, scope, module }: Props) {
  await loadScript({ src: remoteUrl });
  await runtime.initSharing('default');
  const container: Container = window[scope];
  if (!container) {
    throw new Error(`Container ${scope} not found`);
  }
  await container.init(runtime.shareScopes.default);
  const factory = await window[scope].get(module);
  const Module = factory();
  return Module;
}
