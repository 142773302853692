/* eslint-disable func-names */
/* global _ Backbone BackboneEx */
import { trackEvent } from 'common/analytics';

App.Views.Calendar.DatetableCalendar = Backbone.View.extend({
  events: {
    'click td': 'proxyDayClick',
  },

  selectedDayClass: 'wdt-day-selected',
  calendarViewType: ['spa-day', 'spa-break'],
  closeOutButtonShown: false,
  // Boolean value to check if all preconditions to render are met
  assetsLoaded: false,

  initialize: function () {
    const self = this;
    this.setupTypeChangeListening();

    this.currentDate = this.options.date;
    this.currentResourceId = null;
    this.selectedDays = {};
    this.$closeOutButton = this.options.closeOutButton;
    this.resourceIdToSet = this.options.initialResourceId
      ? parseInt(this.options.initialResourceId, 10)
      : null;

    this.roomTypes = new App.Collections.RoomTypes();
    this.spaCollection = new App.Collections.SpaAllocations();
    this.roomCollection = new App.Collections.RoomAllocations();

    this.spaCollection.include = 'closed-offers-count,offers';
    this.roomCollection.include = this.spaCollection.include;
    this.roomCollection.setRoomTypes(this.roomTypes);

    this.roomTooltip = new App.Views.Calendar.Dated.Tooltip({
      $container: this.$el,
      roomTypesCollection: this.roomTypes,
    });

    App.on('app:loaded', function () {
      if (self.isActive()) {
        self.reset();
      }
    })
      .on(
        'spa-allocation:saved room-allocation:saved booking:confirmed booking:rejected',
        function () {
          self.renderIfActive();
        },
      )
      .on('spa-availability:saved room-availability:saved', function () {
        self.selectedDays = {};
        self.renderIfActive();
      })
      .on('calendar:date-change', function (date) {
        // Reset the selected days hash
        self.selectedDays = {};
        self.currentDate = date;
        if (self.isActive()) {
          self.updateUrlWithCurrentOptions();
          self.render();
        }
      })
      .on(Wahanda.Event.CALENDAR_RESOURCE_CHANGE, function (resourceId) {
        self.onResourceChange(resourceId);
      })
      .on('calendar:set-room-type', function (roomTypeId, options) {
        if (self.isActive()) {
          self.currentResourceId = roomTypeId;
          self.renderResources();
          if (options.refresh) {
            self.updateUrlWithCurrentOptions();
            self.render();
          }
        }
      });

    // Refresh every 5 minutes
    App.Timer.on('calendar-refresh', function () {
      if (self.isActive()) {
        self.render();
      }
    });

    this.setupRequestListener();
    // This is commented out b/c does not work correctly when moving the mouse fast. Needs rewrite.
    // this.delegateCheckboxHover();
  },

  setupRequestListener: function () {
    const self = this;
    App.on(
      Wahanda.Event.CALENDAR_REQUEST_REFRESH,
      this.ifActive(function () {
        self.renderIfActive();
      }),
    );
  },

  renderIfActive: function () {
    if (this.isActive()) {
      this.render();
    }
  },

  render: function () {
    this.options.mainView.renderPaneView();

    this.toggleCloseOutButton();
    this.fetchCollectionAndRender();
  },

  fetchCollectionAndRender: function () {
    const self = this;
    const range = this.getDateRange();
    const collection = this.getActiveCollection();
    collection.dateFrom = Wahanda.Date.toApiString(range.from);
    collection.dateTo = Wahanda.Date.toApiString(range.to);

    this.$el.loadmask();

    if (this.getCalendarType() === 'spa-break') {
      collection.roomTypeId = this.currentResourceId;
    }

    const renderCallback = function () {
      self.renderTable();
      self.$el.unloadmask();
    };
    // Postpone rendering until all assets are loaded
    collection.fetch({
      success: function () {
        if (self.assetsLoaded) {
          renderCallback();
        } else {
          Wahanda.Util.waitUntil(function () {
            return self.assetsLoaded;
          }, renderCallback);
        }
      },
    });
  },

  getActiveCollection: function () {
    if (this.getCalendarType() === 'spa-day') {
      return this.spaCollection;
    }
    return this.roomCollection;
  },

  getCalendarType: function () {
    return this.options.mainView.getCalendarType();
  },

  /**
   * Returns the date range currently visible.
   *
   * @param Object options
   * > fixCurrentMonth (boolean) Should current month's start day be today, not 1?
   * @return {from, to}
   */
  getDateRange: function (options) {
    const year = this.currentDate.getFullYear();
    const month = this.currentDate.getMonth();
    let day = 1;
    let now;
    options = options || {};

    if (options.fixCurrentMonth) {
      now = Wahanda.Date.createVenueDate();
      if (now.getFullYear() === year && now.getMonth() === month) {
        day = now.getDate();
      }
    }

    return {
      from: new Date(year, month, day),
      to: new Date(year, month, Wahanda.Date.getDaysInMonth(this.currentDate)),
    };
  },

  renderTable: function () {
    const self = this;

    // IE fix, removing from DOM before drawing another table
    this.roomTooltip.$el.detach();

    this.$el.dateTable({
      firstDay:
        Wahanda.Date.dayStringToNumberMap[App.config.get('jqueryDateFormat').firstDayOfWeek],
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth(),
      weekdayNames: Wahanda.lang.date.weekdays,
      dayRenderer: function (date, isCurrentMonth, className) {
        return self.renderDay(date, isCurrentMonth, className);
      },
      today: Wahanda.Date.createVenueDate(),
    });

    this.$el.shadows();

    this.bindTooltip();
  },

  renderDay: function (date, isCurrentMonth, className) {
    let html = '';
    let classNames = className;
    if (isCurrentMonth) {
      classNames += 'wdt-day ';
      const collection = this.getActiveCollection();
      const day = date.getDate();
      const model = collection.getByDate(Wahanda.Date.toApiString(date));
      let data;
      if (model) {
        data = {
          dayNumber: day,
          isSet: false,
        };
        if (model.isSet()) {
          _.extend(data, {
            stateClass: model.getStateClass(),
            inventoryLeft: model.getInventoryText(),
            soldCount: model.getBookedInventoryCount(),
            isSet: true,
            hasClosedOffers: model.hasClosedOffers(),
          });
        } else {
          classNames += 'wdt-not-set ';
        }
        html = Wahanda.Template.renderTemplate('datetable-calendar-cell', data);
        if (this.isDaySelected(day)) {
          classNames += `${this.selectedDayClass} `;
        }
      }
    } else {
      // If not current month, render nothing.
    }
    return [classNames, html];
  },

  /**
   * Is the given day selected in the current month?
   *
   * @param integer day
   * @return boolean
   */
  isDaySelected: function (day) {
    return this.selectedDays[day] === true;
  },

  showDayEditForm: function (event) {
    const dayNumber = this.getDayFromEvent(event);
    const date = this.getStringDateByDay(dayNumber);
    const model = this.getActiveCollection().getByDate(date);
    if (model) {
      let view;
      if (this.getCalendarType() === 'spa-day') {
        view = new App.Views.Forms.Calendar.SpaDayDetails({
          date: model.get('date'),
        });
      } else {
        let resourceId = null;
        if (this.currentResourceId > 0) {
          resourceId = this.currentResourceId;
        } else if (this.roomTypes.length === 1) {
          resourceId = this.roomTypes.at(0).id;
        }
        view = new App.Views.Forms.Calendar.RoomDayDetails({
          date: model.get('date'),
          roomTypeId: resourceId,
          collection: this.roomTypes,
        });
      }
      view.render();
      view.open();
    }
  },

  getStringDateByDay: function (day) {
    return `${this.currentDate.getFullYear()}-${Wahanda.Date._makeDoubleDigit(
      this.currentDate.getMonth() + 1,
    )}-${Wahanda.Date._makeDoubleDigit(day)}`;
  },

  toggleDayCheckbox: function (event) {
    const dayNumber = this.getDayFromEvent(event);
    const $td = $(event.currentTarget);

    if (typeof this.selectedDays[dayNumber] === 'undefined') {
      this.selectedDays[dayNumber] = true;
    } else {
      delete this.selectedDays[dayNumber];
    }
    $td.toggleClass(this.selectedDayClass, this.selectedDays[dayNumber]);

    this.toggleCloseOutButton();
  },

  toggleCloseOutButton: function () {
    const shouldBeShown = this.isActive() && this.hasSelectedDays();
    // Would be nice to slide
    if (this.closeOutButtonShown !== shouldBeShown) {
      this.closeOutButtonShown = shouldBeShown;
      if (shouldBeShown) {
        this.$closeOutButton.show();
      } else {
        this.$closeOutButton.hide();
      }
      App.trigger('calendar:closeout-visibility-change', shouldBeShown);
    }
  },

  hasSelectedDays: function () {
    return (
      _.find(this.selectedDays, function (value, key) {
        return value;
      }) != null
    );
  },

  getDayFromEvent: function (event) {
    return $(event.currentTarget).data('day');
  },

  loadAndRenderResources: function () {
    this.assetsLoaded = false;
    if (this.getCalendarType() === 'spa-day') {
      // This view has no resources
      this.options.mainView.renderResources();
      this.assetsLoaded = true;
    } else {
      const self = this;
      const mainView = this.options.mainView;
      const roomTypes = this.roomTypes;

      roomTypes.fetch({
        success: function () {
          self.renderResources();
          self.assetsLoaded = true;

          if (roomTypes.length === 0) {
            // No room types defined. This is an edge case.
            // Remove "spa-break" from calendar type list
            mainView.typeSelector.disableSpaBreak();
            // Change to appointments.
            mainView.changeType('appointment');
          }
        },
        error: function () {
          mainView.renderResources([]);
          self.assetsLoaded = true;
        },
      });
    }
  },

  renderResources: function () {
    this.options.mainView.renderResources({
      list: this.roomTypes,
      selected: this.currentResourceId,
    });
  },

  /**
   * Does a "reset" of this view: resets all changeable parameters and refetches all data.
   */
  reset: function () {
    this.selectedDays = {};
    this.toggleCloseOutButton();
    // Set the resourceId, but only once
    this.currentResourceId = this.resourceIdToSet;
    this.resourceIdToSet = null;
    this.roomTooltip.hide();

    if (this.isActive()) {
      this.loadAndRenderResources();
      this.render();
      this.updateUrlWithCurrentOptions();
    }
  },

  // Event binding/unbinding

  bindTooltip: function () {
    this.unbindTooltip();

    const self = this;
    this.$el
      .on('mouseenter.datetable', 'td.wdt-day:not(.wdt-disabled, .wdt-not-set)', function (event) {
        self.onMouseOver(event);
      })
      .on('mouseleave.datetable', 'td.wdt-day:not(.wdt-disabled)', function (event) {
        self.onMouseOut(event);
      });
  },

  unbindTooltip: function () {
    this.$el.off('.datetable');
  },

  /**
   * Adding classes when the cell or checkbox row is hovered.
   */
  delegateCheckboxHover: function () {
    const dayClass = 'wdt-day-hover';
    const barClass = 'wdt-day-bar-hover';
    this.$el
      .on(
        'mouseenter',
        '.wdt-day:not(.wdt-disabled), .wdt-day:not(.wdt-disabled) .wdt-day-bar',
        function (event) {
          const $node = $(event.target);
          let $td;
          let classToSet;
          if ($node.is('.wdt-day-bar')) {
            $td = $node.closest('.wdt-day');
            classToSet = barClass;
          } else {
            $td = $node;
            classToSet = dayClass;
          }

          $td.addClass(classToSet);
        },
      )
      .on(
        'mouseleave',
        '.wdt-day:not(.wdt-disabled), .wdt-day:not(.wdt-disabled) .wdt-day-bar',
        function (event) {
          const $node = $(event.target);
          if ($node.is('.wdt-day-bar')) {
            $node.closest('.wdt-day').removeClass(barClass);
          } else {
            $node.removeClass(dayClass);
          }
        },
      );
  },

  // Events

  onMouseOver: function (event) {
    if (this.getCalendarType() === 'spa-break') {
      this.onMouseOverRoom(event);
    } else {
      this.onMouseOverSpa(event);
    }
  },

  onMouseOverRoom: function (event) {
    if (this.currentResourceId != null || this.roomTypes.length < 2) {
      // Show tooltip only if "All" room types are selected and there is more than one room type.
      return;
    }

    const dayNumber = this.getDayFromEvent(event);
    const date = this.getStringDateByDay(dayNumber);
    const model = this.roomCollection.getByDate(date);

    if (model) {
      const $cell = $(event.currentTarget);
      this.roomTooltip.render(model);
      this.roomTooltip.positionTo($cell);
      // this.roomTooltip.show();
    }
  },

  onMouseOverSpa: function (event) {
    const dayNumber = this.getDayFromEvent(event);
    const date = this.getStringDateByDay(dayNumber);
    const model = this.spaCollection.getByDate(date);

    if (model && !model.isClosed() && model.hasClosedOffers()) {
      const $cell = $(event.currentTarget);
      this.roomTooltip.render(model);
      this.roomTooltip.positionTo($cell);
    }
  },

  onMouseOut: function (event) {
    this.roomTooltip.hide();
  },

  onCalendarTypeChange: function () {
    this.reset();
  },

  proxyDayClick: function (event) {
    if ($(event.currentTarget).is('.wdt-disabled')) {
      // Do not handle disabled days
      return;
    }

    const $target = $(event.target);
    if ($target.closest('.wdt-day-bar').length === 0) {
      // Clicked anywhere else in the cell
      trackEvent('calendar', 'click', `${this.getCalendarType()}-appointment`);
      this.showDayEditForm(event);
    } else {
      // Clicked on day number label (checkbox)
      this.toggleDayCheckbox(event);
    }
  },

  showSetAvailabilityDialog: function () {
    const options = {};
    let view;
    if (_.isEmpty(this.selectedDays)) {
      const range = this.getDateRange({ fixCurrentMonth: true });
      options.dateFrom = range.from;
      options.dateTo = range.to;
    } else {
      options.days = this.getSelectedDays();
      options.date = this.currentDate;
      options.inventoryAvailable = this.getSelectedDaysInventory();
    }

    if (this.getCalendarType() === 'spa-day') {
      view = new App.Views.Forms.Calendar.SpaAvailability(options);
    } else {
      options.collection = this.roomTypes;
      if (this.currentResourceId != null) {
        // Single resource selected
        options.roomTypeId = this.currentResourceId;
      } else if (this.roomTypes.length === 1) {
        // Only one resource exists
        options.roomTypeId = this.roomTypes.at(0).id;
      }
      view = new App.Views.Forms.Calendar.RoomAvailability(options);
    }
    view.render();
    view.open();
  },

  showCloseOutDaysDialog: function () {
    const view = new App.Views.Dialog.Calendar.CloseOut({
      days: this.getSelectedDays(),
      date: this.currentDate,
      roomTypes: this.roomTypes,
      isSpaBreak: this.getCalendarType() === 'spa-break',
      singleRoomTypeId: this.currentResourceId,
    });
    view.render();
    view.open();
  },

  getSelectedDays: function () {
    return _.keys(this.selectedDays);
  },

  getSelectedDates: function () {
    const now = this.currentDate;
    const prefix = `${now.getFullYear()}-${Wahanda.Date._makeDoubleDigit(now.getMonth() + 1)}-`;

    return _.map(this.selectedDays, function (b, day) {
      return prefix + Wahanda.Date._makeDoubleDigit(day);
    });
  },

  /**
   * Returns the inventory of selected days only, if it's the same in all days.
   *
   * Works only in single item (not multi-rooms) mode.
   *
   * @return int or null if inventory differs
   */
  getSelectedDaysInventory: function () {
    const dates = this.getSelectedDates();
    const isRooms = this.getCalendarType() === 'spa-break';
    let resource = this.currentResourceId;
    if (isRooms && resource == null && this.roomTypes.length === 1) {
      resource = this.roomTypes.at(0).id;
    }
    // Return NULL when no dates are selected, or this is rooms view with one (existing or selected) room type
    if (dates.length === 0 || (isRooms && resource == null)) {
      return null;
    }

    const collection = this.getActiveCollection();
    const firstModel = collection.getByDate(dates[0]);
    const inventory = getInventoryFromModel(firstModel);
    let model;

    if (!inventory) {
      return null;
    }

    for (let i = 1, len = dates.length; i < len; i++) {
      model = collection.getByDate(dates[i]);
      if (getInventoryFromModel(model) !== inventory) {
        return null;
      }
    }

    return inventory;

    // Local scope helper function
    function getInventoryFromModel(model) {
      let inventory;
      let room;
      if (isRooms) {
        room = model.getRoomType(resource);
        inventory = room.inventoryAvailable;
      } else {
        inventory = model.get('inventoryAvailable');
      }
      return inventory;
    }
  },

  onResourceChange: function (resourceId) {
    if (this.isActive()) {
      if (this.roomTypes.get(resourceId) == null) {
        resourceId = null;
      }
      this.currentResourceId = parseInt(resourceId, 10) || null;
      this.updateUrlWithCurrentOptions();
      this.fetchCollectionAndRender();
      this.renderResources();
    }
  },

  // URL state persisting

  updateUrlWithCurrentOptions: function () {
    App.mainRouter.navigate(this.getCurrentStateHash());
  },

  getCurrentStateHash: function () {
    let hash = `venue/${App.getVenueId()}/${this.getCalendarType()}/${Wahanda.Date.toApiString(
      this.currentDate,
    )}`;
    if (this.currentResourceId) {
      hash += `/${this.currentResourceId}`;
    }
    return hash;
  },
});

BackboneEx.Mixin.extendView(
  App.Views.Calendar.DatetableCalendar,
  BackboneEx.Mixin.View.CalendarTypeChange,
);
