import React from 'react';

interface Props {
  fill: string;
}

export default class Dot extends React.PureComponent<Props> {
  public render() {
    return (
      <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
        <rect width="12" height="12" rx="2" fill={this.props.fill} fillRule="evenodd" />
      </svg>
    );
  }
}
