import React from 'react';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import { Spinner } from 'components/common/Spinner';
import { Facebook, Instagram, Messenger } from 'components/common/Icon';
import style from './NotInstalled.scss';
import { FacebookBusinessExtensionAnalytics as Analytics } from './tracking';

const lang = Wahanda.lang.marketing.facebookBusinessExtension.notInstalled;

export interface Props {
  venueId: number;
  timezone: string;
  currency: string;
  isLoading: boolean;
  completeFBEIntegration: () => void;
  businessConfig: {
    business: {
      name: string;
    };
    ig_cta: {
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
    };
    messenger_menu: {
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
    };
    page_cta: {
      below_button_text: string;
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
    };
    page_post: {
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
      subtitle: string;
      title: string;
    };
  };
}

export const NotInstalled: React.FC<Props> = ({
  venueId,
  timezone,
  currency,
  isLoading,
  businessConfig,
  completeFBEIntegration,
}) => {
  const handleLinkNowClicked = () => {
    Analytics.trackLinkNowClicked();
    FB.login(
      (response) => {
        if (response.authResponse) {
          completeFBEIntegration();
        } else {
          Analytics.trackFBEFailed();
          if (window.Intercom) {
            window.Intercom('trackEvent', 'unsuccessful fbe install');
            window.Intercom('update');
          }
        }
      },
      {
        scope: 'manage_business_extension',
        // @ts-expect-error ts-migrate(2345) FIXME: Object literal may only specify known properties, ... Remove this comment to see the full error message
        extras: {
          setup: {
            external_business_id: venueId.toString(),
            timezone,
            currency,
            business_vertical: 'SERVICES',
          },
          business_config: businessConfig,
          repeat: false,
        },
      },
    );
  };
  return (
    <>
      <div className={style.iconWrapper}>
        <Facebook className={style.icon} />
        <Instagram className={style.icon} />
        <Messenger className={style.icon} />
      </div>
      <div className={style.textContainer}>
        <div data-test="fb-page-name" className={style.label}>
          {lang.title}
        </div>
      </div>
      <div className={style.button}>
        {isLoading ? (
          <Spinner dark />
        ) : (
          <Button
            dataTest="link-now-button"
            label={lang.button}
            onClick={handleLinkNowClicked}
            size="large"
          />
        )}
      </div>
    </>
  );
};
