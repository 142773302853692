import { createSelector } from 'reselect';
import { BOOKING_ACTOR } from 'common/consts';

export const soldServicesSelector = (state) => state.venueStats.services;

export const directChannelSalesSelector = (state) => state.venueStats.directChannelSales;

export const marketPlaceChannelSalesSelector = (state) => state.venueStats.treatwellChannelSales;

export const widgetChannelSalesSelector = (state) => state.venueStats.widgetChannelSales;

export const topServicesSelector = createSelector(
  soldServicesSelector,
  (state, props) => props.max,
  (services, max) => {
    if (!services) {
      return null;
    }
    return services.slice(0, max);
  },
);

export const topEmployeesSelector = createSelector(
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  (state) => state.venueStats.employees,
  (state, props) => props.max,
  (employees, max) => {
    if (!employees) {
      return null;
    }

    return employees.slice(0, max);
  },
);

export const channelSalesSelector = createSelector(
  [directChannelSalesSelector, marketPlaceChannelSalesSelector, widgetChannelSalesSelector],
  (directSales, marketPlaceSales, widgetSales) => {
    const channelSales = [
      { type: BOOKING_ACTOR.WIDGET, amount: widgetSales },
      { type: BOOKING_ACTOR.CUSTOMER, amount: marketPlaceSales },
      { type: BOOKING_ACTOR.SUPPLIER, amount: directSales },
    ];

    if (channelSales.every((channelSale) => channelSale.amount == null)) {
      return null;
    }

    return channelSales.sort((a, b) => b.amount - a.amount);
  },
);
