import React from 'react';
import { RouteComponentProps, generatePath } from 'react-router-dom';
import { SingleDayPicker } from 'components/common/DatePicker';
import { TransactionsReportTracking } from 'components/reports/transactions/tracking';
import moment from 'common/moment';

interface OwnProps {
  date: string;
}
interface UrlProps {
  venueId: string;
}
type Props = OwnProps & RouteComponentProps<UrlProps>;

export const DayPicker = (props: Props) => {
  const { push } = props.history;
  const { venueId } = props.match.params;
  const isDayBlocked = (day: moment.Moment) => {
    return day.isAfter(moment());
  };
  const navigate = (date?: string) => {
    push(
      generatePath('/venue/:venueId/transactions/:date?', {
        venueId,
        ...(date && { date }),
      }),
    );
  };
  const onNextRangeSelect = () => {
    TransactionsReportTracking.trackNextDayClick();
    const newDate = moment(props.date).add(1, 'days');
    if (!isDayBlocked(newDate)) {
      navigate(newDate.formatApiDateString());
    }
  };
  const onPrevRangeSelect = () => {
    TransactionsReportTracking.trackPreviousDayClick();
    navigate(moment(props.date).subtract(1, 'days').formatApiDateString());
  };
  return (
    <SingleDayPicker
      date={props.date}
      onDateChange={navigate}
      isDayBlocked={isDayBlocked}
      navigation={{
        onNextRangeSelect,
        onPrevRangeSelect,
      }}
      id="transaction-date-picker"
    />
  );
};
