/* global $ */
(function () {
  $.fn.headerDropdown = function (options) {
    this.each(function () {
      initializeDropdown.call(this, options);
    });
  };

  // Create a context of a dropdown with it's own `options`.
  function handle (pOptions) {
    var options = $.extend(
      {},
      { dropdownSelector: ".ddown" },
      pOptions
    );

    function toggleDropdown (event) {
      var $dropdownContainer = $(event.currentTarget);
      var $dropdown = $dropdownContainer.find(options.dropdownSelector);
      var toShow = !$dropdown.is(":visible");

      $dropdown.wToggle();
      $dropdownContainer.toggleClass("active", toShow);

      if (toShow) {
        addOverlay($dropdown);
      } else {
        var $overlay = $dropdown.data("overlay");
        if ($overlay) {
          $dropdown.removeData("overlay");
          $overlay.remove();
        }
      }

      if (options.onChange) {
        options.onChange({ shown: toShow });
      }
    }

    function addOverlay ($dropdown) {
      var $overlay = $("<div>").css({
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 1010
      });

      $dropdown.data("overlay", $overlay).css("zIndex", 1011).before($overlay);

      // When the overlay is clicked, hide dropdown + remove overlay
      $overlay.click(toggleDropdown);
    }

    return toggleDropdown;
  }

  function initializeDropdown (options) {

    $(this).click(handle(options));
  }
}());
