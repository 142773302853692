import React from 'react';
import Wahanda from 'common/wahanda';
import { List } from 'components/common/List';
import style from './ExplanationBullets.scss';

interface Props {
  bulletPoints: string[];
}

export const ExplanationBullets: React.FunctionComponent<Props> = ({ bulletPoints }) => {
  if (bulletPoints.length === 0) {
    return null;
  }
  return (
    <div className={style.container}>
      <div className={style.header}>
        {Wahanda.lang.calendar.appointments.cancellation.confirmCancellation.bulletPoints.header}
      </div>
      <List>{bulletPoints}</List>
    </div>
  );
};
