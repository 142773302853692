import Wahanda from 'common/wahanda';
import React from 'react';
import { KycBanner as Banner } from './KycBanner';

interface Props {
  kycVerificationNeeded: boolean;
  bankAccountSyncNeeded: boolean;
  isKycNewSupplier: boolean;
}

const getMessage = (
  kycVerificationNeeded: boolean,
  bankAccountSyncNeeded: boolean,
  isKycNewSupplier: boolean,
): string => {
  if (kycVerificationNeeded) {
    if (!isKycNewSupplier) {
      return Wahanda.lang.shared.identityVerification.banner.body;
    } else {
      return Wahanda.lang.shared.identityVerification.banner.newSupplierBody;
    }
  } else if (bankAccountSyncNeeded) {
    return Wahanda.lang.shared.bankAccountVerification.banner.body;
  } else {
    return '';
  }
};

const getButtonLabel = (kycVerificationNeeded: boolean, bankAccountSyncNeeded: boolean): string => {
  if (kycVerificationNeeded) {
    return Wahanda.lang.shared.identityVerification.banner.button;
  } else if (bankAccountSyncNeeded) {
    return Wahanda.lang.shared.bankAccountVerification.banner.button;
  } else {
    return '';
  }
};

const getActionButtonUrl = (
  kycVerificationNeeded: boolean,
  bankAccountSyncNeeded: boolean,
): string => {
  if (kycVerificationNeeded) {
    return App.getIdentityVerificationUrl();
  } else if (bankAccountSyncNeeded) {
    return App.getBankSettingsUrl();
  } else {
    return '';
  }
};

const openInNewTab = (kycVerificationNeeded: boolean, bankAccountSyncNeeded: boolean): boolean => {
  if (kycVerificationNeeded) {
    return true;
  } else if (bankAccountSyncNeeded) {
    return false;
  }
  return false;
};

export const KycBanner: React.FunctionComponent<Props> = (props) => {
  const { kycVerificationNeeded, bankAccountSyncNeeded, isKycNewSupplier } = props;
  return (
    <Banner
      message={getMessage(kycVerificationNeeded, bankAccountSyncNeeded, isKycNewSupplier)}
      buttonLabel={getButtonLabel(kycVerificationNeeded, bankAccountSyncNeeded)}
      actionButtonUrl={getActionButtonUrl(kycVerificationNeeded, bankAccountSyncNeeded)}
      openInNewTab={openInNewTab(kycVerificationNeeded, bankAccountSyncNeeded)}
    />
  );
};
