import React from 'react';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import { Products } from 'components/common/Icon';

import style from './CheckoutButton.scss';

const lang = Wahanda.lang.calendar.buttons;

export interface CheckoutButtonProps {
  onCheckoutButtonClick: (...args: any[]) => any;
}

export const CheckoutButton = ({ onCheckoutButtonClick }: CheckoutButtonProps) => {
  return (
    <div className={style.button}>
      <Button
        size="small"
        colour="pos"
        fullWidth
        justify
        label={lang.newCheckout}
        icon={<Products />}
        onClick={onCheckoutButtonClick}
      />
    </div>
  );
};

CheckoutButton.displayName = 'CheckoutButton';
