/* global jQuery */
import App from 'common/backbone-app';
import { CustomerDuplicateCheckAnalytics } from '../common/tracking/customer-duplicate-check';

async function showDuplicateCustomerPopup(
  tooltipTarget,
  customerList,
  matchedAttributes,
  clientData,
) {
  const { name, phone, emailAddress } = clientData;
  const filters = {
    name,
    phone,
    email: emailAddress,
  };
  CustomerDuplicateCheckAnalytics.duplicatePopUpOpen();

  // Add a calculated itemCount value
  customerList.forEach((c) =>
    Object.assign(c, {
      itemCount: c.appointmentCount + c.bookingCount,
    }),
  );
  customerList.sort((a, b) => b.itemCount - a.itemCount);

  const $content = jQuery(
    Wahanda.Template.renderTemplate('duplicate-consumer-template', {
      customerList,
      filters,
    }),
  );

  const $tooltip = jQuery(tooltipTarget).qtip({
    content: $content,
    style: {
      classes: 'dialog-tooltip',
    },
    position: {
      my: 'bottom center',
      at: 'top center',
    },
    show: {
      event: false,
      ready: true,
    },
    hide: {
      event: false,
      fixed: true,
    },
  });

  (matchedAttributes || []).forEach((attribute) =>
    $content.find(`.matched-attribute.${attribute}`).wShow(),
  );

  return new Promise((resolve) => {
    const reply = (response) => {
      $tooltip.qtip('destroy');
      resolve(response);
    };

    $content.find('.button-cancel').on('click', () => {
      reply({ cancelled: true });
    });

    $content.on('click', '.a-create-new-consumer', () => {
      CustomerDuplicateCheckAnalytics.createNewClient();
      reply({ createNew: true });
    });

    $content.on('click', '.a-duplicate-client', function handleDupeSelect() {
      // eslint-disable-next-line
      CustomerDuplicateCheckAnalytics.useExistingClient();
      const selectedId = $(this).data('id');
      const selectedCustomer = customerList.find(
        // eslint-disable-next-line eqeqeq
        (customer) => customer.id == selectedId,
      );

      reply({ customerData: selectedCustomer });

      return false;
    });
  });
}

/**
 * Checks for duplicates by querying the API. If any are found, the user ir presented with
 * choice which user to choose instead.
 *
 * @param {String} name Entered customer name
 * @param {String} name Entered customer phone
 * @param {String} emailAddress Entered customer email address
 * @param {Node|jQuery} tooltipTarget The element on which to show the tooltip with
 *  duplicate clients, if any are found.
 * @returns {Promise<*>} Promise which will return an object with one truthy value:
 *   { cancelled, customerData, createNew }
 */
export default async function duplicateCheck({ name, phone, emailAddress }, tooltipTarget) {
  const customers = new App.Collections.CustomerSearch();

  customers.setFilters({
    name,
    phone,
    email: emailAddress,
    include: 'appointmentCount,bookingCount',
  });

  // Throws on connection error
  await customers.fetchWithPromise();

  const item = customers.models[0];
  if (item.get('customers').length === 0) {
    // No duplicates found.
    return { createNew: true };
  }

  // The popup is an async function too. It's result is proxyed back.
  const popupResult = await showDuplicateCustomerPopup(
    tooltipTarget,
    item.get('customers'),
    item.get('matchedAttributes'),
    { name, phone, emailAddress },
  );

  return popupResult;
}
