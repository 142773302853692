import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from 'reduxStore/common/appointmentNotifications/actions';
import { requestWebSocketsConnectAction } from 'reduxStore/common/websockets/actions';
import NotificationList from './index';

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(actions, dispatch),
    ...bindActionCreators({ requestWebSocketsConnectAction }, dispatch),
  },
});

const mapStateToProps = ({ appointmentNotifications }) => ({
  appointmentNotifications: appointmentNotifications.items,
  loading: appointmentNotifications.loading,
  visible: appointmentNotifications.visible,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
