/* global BackboneEx */
import { enableRecording } from 'common/analytics';

App.Views.Reports = BackboneEx.View.Main.extend({
  events: {},
  currentFormView: null,
  formViews: {},

  initialize: function initialize() {
    enableRecording();
    this.currentFormView = App.ES6.Initializers.Reports({
      tabElement: this.$('#reports-view')[0],
    });
    const self = this;
    const E = Wahanda.Event;
    App.on(E.APP_VENUE_CHANGED, () => {
      self.currentFormView.render();
    })
      .on(E.APP_VENUE_IS_CHANGING, () => {
        self.currentFormView.destroy();
      })
      .on([E.APPOINTMENT_OPENED, E.BOOKING_OPENED].join(' '), () => {
        self.$el.addClass('printable-hide');
      })
      .on([E.APPOINTMENT_CLOSED, E.BOOKING_CLOSED].join(' '), () => {
        self.$el.removeClass('printable-hide');
      });
  },

  lightweightAuthAction: function lightweightAuthAction() {
    this.openLightweightModeDialog();
  },

  render: function render() {
    App.headerView.setModule('reports');
    this.currentFormView.render();
  },
});
