import React from 'react';
import style from './DatePickerQuicklFilters.scss';
import { DEFAULT_DATE_PICKER_PRESETS } from 'components/common/DatePicker/DatePickerWithPresets';

export const DatePickerQuickFilters = ({ onChange }) => {
  const handleChange = ({ startDate, endDate }) => {
    onChange(startDate, endDate);
  };

  return (
    <div className={style.wrapper}>
      <div
        className={style.filter}
        onClick={() => handleChange(DEFAULT_DATE_PICKER_PRESETS.thisWeek.getDates())}
      >
        {DEFAULT_DATE_PICKER_PRESETS.thisWeek.buttonText}
      </div>
      <div
        className={style.filter}
        onClick={() => handleChange(DEFAULT_DATE_PICKER_PRESETS.last30Days.getDates())}
      >
        {DEFAULT_DATE_PICKER_PRESETS.last30Days.buttonText}
      </div>
      <div
        className={style.filter}
        onClick={() => handleChange(DEFAULT_DATE_PICKER_PRESETS.thisMonth.getDates())}
      >
        {DEFAULT_DATE_PICKER_PRESETS.thisMonth.buttonText}
      </div>
      <div
        className={style.filter}
        onClick={() => handleChange(DEFAULT_DATE_PICKER_PRESETS.thisYear.getDates())}
      >
        {DEFAULT_DATE_PICKER_PRESETS.thisYear.buttonText}
      </div>
    </div>
  );
};
