import {
  GET_MENU_ITEMS_RECEIVE,
  SET_ALL_MENU_ITEMS_CHECKED,
  SET_MENU_ITEM_CHECKED,
  SET_MENU_ITEMS_CHECKED,
} from 'reduxStore/actionsConst';

export const initialState = {
  menuItemGroups: [],
  menuItemOffers: [],
};

const addCheckedToOptions = (assignedTreatments, offers) =>
  offers.map((offer) => {
    const assignedTreatment = assignedTreatments.find((treatment) => treatment.id === offer.id);
    return {
      ...offer,
      checked: !!assignedTreatment,
    };
  });

const removeDatedServices = (offers) =>
  offers.filter((offer) => offer.fulfillmentTypes && offer.fulfillmentTypes.appointment);

export default function discountMenuItems(state = initialState, action) {
  switch (action.type) {
    case GET_MENU_ITEMS_RECEIVE:
      return {
        ...state,
        menuItemGroups: action.data.groups,
        menuItemOffers: removeDatedServices(action.data.offers),
      };
    case SET_ALL_MENU_ITEMS_CHECKED:
      return {
        ...state,
        menuItemOffers: addCheckedToOptions(action.assignedTreatments, state.menuItemOffers),
      };
    case SET_MENU_ITEM_CHECKED:
      return {
        ...state,
        menuItemOffers: state.menuItemOffers.map((offer) => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
          if (offer.id === action.offerId) {
            return {
              // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
              ...offer,
              checked: action.checked,
            };
          }

          return offer;
        }),
      };
    case SET_MENU_ITEMS_CHECKED:
      return {
        ...state,
        menuItemOffers: state.menuItemOffers.map((offer) => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
          if (action.offerIds.indexOf(offer.id) !== -1) {
            return {
              // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
              ...offer,
              checked: action.checked,
            };
          }

          return offer;
        }),
      };
    default:
      return state;
  }
}
