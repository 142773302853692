/**
 * Featured Offer item.
 */
App.Views.Menu.FeaturedOffer = BackboneEx.View.Menu.OfferEditing.extend({
  events: {
    click: 'editOffer',
  },
  render: function () {
    var state = this.model.get('featuredStateCode');

    var html = Wahanda.Template.renderTemplate('featured-service-item', {
      id: this.model.get('id'),
      name: this.model.get('name'),
      state: state.toLowerCase(),
      image_src: Wahanda.Template.render(
        App.Api.wsUrl('/media/venue/{{venueId}}/gallery/image/{{imageId}}?width=100&height=70'),
        {
          venueId: App.config.get('venue').id,
          imageId: this.model.get('imageId'),
        },
      ),
      // Out of scope in R1
      note: this.getNotes(),
      canDelete: Wahanda.Permissions.editMenu() && !this.model.get('locked'),
      visibility: this.model.getVisibilityInfo(),
    });

    var el = $(html);
    this.setElement(el);
  },

  getNotes: function () {
    var notes = [];
    if (this.model.get('locked')) {
      notes.push({
        type: 'lck',
        text: Wahanda.lang.menu.offer.states.LCK,
      });
    }
    if ('PND' === this.model.get('featuredStateCode')) {
      notes.push({
        type: 'pnd',
        text: Wahanda.lang.menu.offer.states.PND,
      });
    }
    if (this.model.get('soldOut')) {
      notes.push({
        type: 'sld',
        text: Wahanda.lang.menu.offer.states.SLD,
      });
    }
    return notes;
  },

  editOffer: function (event) {
    if ($(event.target).is('.unfeature')) {
      // The user clicked on "Delete" icon. Don't handle it here.
      return;
    }

    var self = this;
    var offerModel = new App.Models.MenuOffer({ id: this.model.id });
    offerModel.fetch({
      success: function () {
        self.showOfferEditForm(offerModel);
        offerModel.on('change', function () {
          // Reload the Menu when this model saves
          App.initVenue(null);
        });
      },
    });
  },
});
