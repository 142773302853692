/**
 * Offer copy confirm dialog
 */
(function () {
  var BaseView = BackboneEx.View.Dialog2;
  App.Views.Dialog.ConfirmCopyDialog = BaseView.extend({
    events: {
      'click .js-close': 'close',
      'click  .js-confirm': 'confirm',
    },

    templateId: 'offer2-copy-confirm-dialog-template',

    options: {
      width: 353,
      dialogClass: 'confirm-copy-dialog',
      dataTest: 'confirm-copy-modal',
    },

    buttons: {
      someAction: {
        title: Wahanda.lang.menu.offer.copyDialog.button,
        classes: 'js-confirm dialog2--button-green',
        onRight: true,
      },
    },
    dialogTitle: Wahanda.lang.menu.offer.copyDialog.title,

    confirm: function () {
      this.options.confirm();
      this.close();
    },
  });
})();
