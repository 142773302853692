import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';

export const REFRESH_TIMER = 'calendar-unconfirmed';

export const REFRESH_EVERY = 10 * 60 * 1000;

const resetTimer = () => App.Timer.reset(REFRESH_TIMER);

export default function setup(unconfirmedAppts, updateIfNoWebSocketsConnection) {
  // Set up a timer which will refresh the list if no WS connection
  App.Timer.start(REFRESH_TIMER, REFRESH_EVERY);
  App.Timer.on(REFRESH_TIMER, updateIfNoWebSocketsConnection);

  // Reset the timer on important events
  App.on(Wahanda.Event.APP_VENUE_CHANGED, resetTimer);
  unconfirmedAppts.on('update-started', resetTimer);
}
