import { EmailTemplateField } from './EmailTemplate';

export const fieldConfig: EmailTemplateField[] = [
  {
    name: 'subject',
    required: true,
    type: 'text',
    minLength: 10,
    maxLength: 60,
  },
  {
    name: 'title',
    type: 'text',
    maxLength: 60,
    withinTemplate: true,
  },
  {
    name: 'message',
    required: true,
    type: 'textarea',
    minLength: 20,
    maxLength: 1024,
    withinTemplate: true,
  },
  {
    name: 'signature',
    type: 'textarea',
    maxLength: 1024,
    withinTemplate: true,
  },
];
