import React, { useEffect } from 'react';
import classnames from 'classnames';

import { TabData } from '../types';
import style from './TabItem.scss';
import { ACTIONS, trackEvent } from 'common/analytics';

export interface TabItemProps {
  isSelected?: boolean;
  hasError?: boolean;
  onClick: (tab: TabData) => void;
  tab: TabData;
}

export const TabItem = ({ isSelected, hasError, onClick, tab }: TabItemProps) => {
  useEffect(() => {
    if (isSelected && tab.trackingDetails) {
      const { category, action, label, property } = tab.trackingDetails;

      if (action === ACTIONS.VIEW) {
        trackEvent(category, action, label, property);
      }
    }
  }, [isSelected]);

  const { isDisabled, label } = tab;
  const labelClass = classnames(style.content, {
    [style.active]: isSelected,
    [style.error]: hasError,
    [style.disabled]: tab.isDisabled,
  });

  const handleClick = () => (!isDisabled ? onClick(tab) : {});

  return (
    <div className={labelClass} onClick={handleClick} data-test={tab.dataTest}>
      {label}
    </div>
  );
};
