import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  openWithdrawalDialog,
  openDepositDialog,
  openDayCloseNotificationDialog,
} from 'components/reports/transactions/RootTransactionDialog/actions';
import { ActionsAndFiltersBar as Component } from './ActionsAndFiltersBar';

const mapDispatch = (dispatch, props) => ({
  actions: {
    ...bindActionCreators(
      {
        openWithdrawalDialog,
        openDepositDialog,
        openDayCloseNotificationDialog,
      },
      dispatch,
    ),
    ...props.actions,
  },
});

const mapState = (state) => ({
  posStatus: state.posStatus,
});

export const ActionsAndFiltersBar = connect(mapState, mapDispatch)(Component);
