export interface UDVCovidFormLinkResponse {
  uri: string;
  status: CovidFormLinkStatus;
}

export interface ConsultationFormsVenueSettingsResponse {
  covidConsentFormsEnabled: boolean;
}

export enum CovidFormLinkStatus {
  SCHEDULED = 'SCHEDULED',
  SENT = 'SENT',
  CANT_BE_SENT = 'CANT_BE_SENT',
  COMPLETED = 'COMPLETED',
}
