import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';

import Loader from 'components/common/Loader';
import { Header } from './Header';
import { ReviewEmptyState } from 'components/dashboard/Reviews/ReviewEmptyState';
import { ErrorDialog, Types } from 'components/common/dialog/ErrorDialog';
import { RowRenderer as ReviewsRows } from './RowRenderer';
import { ZeroToFive } from './ZeroToFive';
import { RankingBoost } from './RankingBoost';
import ReviewDialog from './Dialog';

import { ReviewsAnalytics } from './tracking';

import { SIZE, FIRST_PAGE, LAZY_LOAD_SPACING_THRESHOLD } from './constants';

import style from './Reviews.scss';

const LANG = Wahanda.lang.dashboard.reviews;

export interface Review {
  id: number;
  reviewer: {
    name: string;
    anonymous: boolean;
  };
  createdAt: string;
  content: string;
  rating: number;
  employeeNames: string[];
  response: {
    ownerName: string;
    content: string;
  };
}

interface Props {
  actions: {
    requestCustomerReviewsAction: (payload: {
      venueId: number;
      page: number;
      size: number;
    }) => void;
    clearCustomerReviewsAction: () => void;
    submitResponseToCustomerReview: () => void;
    clearResponseToCustomerReviewError: () => void;
    getRankingBoostRequestAction: () => void;
  };
  customerReviews: {
    isSending: boolean;
    reviews: Review[];
    page: {
      page: number;
      total: number;
    };
  };
  scrollableParent: HTMLElement;
  venueId: number;
  reviewCount: number;
  weightedRating: number;
  isFetchingCustomerReviews: boolean;
  reviewResponseSaving: boolean;
  reviewResponseErrors: [];
  isMasquerading: boolean;
  isRankingBoostLoading: boolean;
  shouldRenderZeroToFive: boolean;
  shouldRenderRankingBoost: boolean;
}

interface State {
  shownReviewRowNumber: number | null;
}

export class Reviews extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      shownReviewRowNumber: null,
    };
  }

  public render() {
    const { customerReviews, isRankingBoostLoading } = this.props;

    const isFetchingReviews = !customerReviews;
    const showLoader = isFetchingReviews || isRankingBoostLoading;

    return <div className={style.reviews}>{showLoader ? <Loader /> : this.renderReviews()}</div>;
  }

  public componentDidMount() {
    NavigationAnalytics.trackPageEventView(L2Page.CUSTOMER_REVIEWS);

    const { venueId, actions, scrollableParent, shouldRenderZeroToFive } = this.props;

    scrollableParent.addEventListener('scroll', this.handleScroll);
    if (!shouldRenderZeroToFive) {
      actions.getRankingBoostRequestAction();
    }

    actions.requestCustomerReviewsAction({
      venueId,
      page: FIRST_PAGE,
      size: SIZE,
    });
  }

  public componentWillUnmount() {
    this.props.actions.clearCustomerReviewsAction();
    this.props.scrollableParent.removeEventListener('scroll', this.handleScroll);
  }

  private getReviewDialog = (rowNumber: number) => {
    const {
      venueId,
      reviewResponseSaving,
      customerReviews: { reviews },
      isMasquerading,
      actions: { submitResponseToCustomerReview },
    } = this.props;
    const reviewData = reviews[rowNumber];
    const onClose = () => {
      this.setState({ shownReviewRowNumber: null });
    };

    return (
      <ReviewDialog
        onClose={onClose}
        saveReply={submitResponseToCustomerReview}
        // @ts-expect-error ts-migrate(2769) FIXME: Type 'Review' is not assignable to type '{ treatme... Remove this comment to see the full error message
        reviewData={reviewData}
        venueId={venueId}
        reviewResponseSaving={reviewResponseSaving}
        isMasquerading={isMasquerading}
      />
    );
  };

  private triggerDialog = (rowNumber: number) => {
    ReviewsAnalytics.trackReviewClicked();
    this.setState({ shownReviewRowNumber: rowNumber });
  };

  private handleScroll = (event: Event) => {
    const { reviewCount, isFetchingCustomerReviews } = this.props;
    const target = event.target as HTMLElement;

    if (reviewCount > 0 && !isFetchingCustomerReviews) {
      const { scrollHeight, scrollTop, clientHeight } = target;

      /*
       *  Set threshold above bottom to trigger review fetch
       */
      const isBottom = scrollHeight - scrollTop < clientHeight + LAZY_LOAD_SPACING_THRESHOLD;

      if (isBottom) {
        const {
          actions,
          venueId,
          customerReviews: {
            reviews,
            page: { total },
          },
        } = this.props;

        const count = reviews.length;
        const pageNumber = count / SIZE + 1;
        const hasMorePages = pageNumber <= Math.ceil(total / SIZE);

        if (hasMorePages) {
          actions.requestCustomerReviewsAction({
            venueId,
            page: pageNumber,
            size: SIZE,
          });
        }
      }
    }
  };

  private content = () => {
    const {
      reviewCount,
      customerReviews: { reviews },
    } = this.props;
    if (reviewCount === 0) {
      return <ReviewEmptyState />;
    }

    return (
      <>
        <Header
          reviewCount={this.props.reviewCount}
          weightedRating={this.props.weightedRating}
          customerReviews={this.props.customerReviews}
        />
        <ReviewsRows reviews={reviews} onClick={this.triggerDialog} />
      </>
    );
  };

  private renderZeroToFiveState = () => {
    const { shouldRenderRankingBoost, shouldRenderZeroToFive } = this.props;
    if (shouldRenderZeroToFive) {
      return <ZeroToFive />;
    }
    if (shouldRenderRankingBoost) {
      return <RankingBoost />;
    }
    return null;
  };

  private renderReviews() {
    const {
      customerReviews,
      reviewResponseErrors,
      reviewCount,
      actions: { clearResponseToCustomerReviewError },
    } = this.props;

    const { shownReviewRowNumber } = this.state;

    return (
      <div className={classnames(reviewCount === 0 && style.empty)}>
        {this.renderZeroToFiveState()}
        {this.content()}

        {shownReviewRowNumber !== null ? this.getReviewDialog(shownReviewRowNumber) : null}

        {customerReviews.reviews.length !== customerReviews.page.total ? (
          <div className={style.loader}>
            <Loader />
          </div>
        ) : null}

        {reviewResponseErrors && (
          <ErrorDialog
            dataTest="review-response-error-modal"
            onClose={clearResponseToCustomerReviewError}
            type={Types.COLUMN}
            errors={[
              {
                errorName: LANG.savingFailed.title,
                errorDescription: LANG.savingFailed.message,
              },
            ]}
          />
        )}
      </div>
    );
  }
}
