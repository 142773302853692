import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import { PromotionalOffersCampaignInitiation as Analytics } from 'common/analytics';
import Dialog from 'components/menu/DiscountRuleDialog/container';
import { Badge } from 'components/common/Badge';
import style from './style.scss';

const lang = Wahanda.lang.menu.discounts.discountList.listItem;
const APPLIED_TO_TREATMENTS = [lang.appliedTo.single, lang.appliedTo.plural];
// @ts-expect-error ts-migrate(2430) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
interface IDiscountListItemProps extends React.HTMLAttributes<Element> {
  id: number;
  name?: string;
  lastMinuteDiscount?: {
    active: boolean;
  };
  offpeakDiscounts?: {
    active: boolean;
  };
  hasEditPermission: boolean;
  assignedMenuItems?: {
    id: number;
  }[];
  number?: number;
  active?: boolean;
  venueId?: string;
  actions?: {
    updateDiscountRuleAction: (...args: any[]) => any;
    revertDiscountRuleAction: (...args: any[]) => any;
  };
}
type DiscountListItemState = {
  renderDialog?: boolean;
};

export default class DiscountListItem extends React.Component<
  IDiscountListItemProps,
  DiscountListItemState
> {
  state = {
    renderDialog: false,
  };

  handleRowClick = () => {
    this.setState({
      renderDialog: true,
    });
  };

  handleStartCampaignClick = (event) => {
    event.stopPropagation();
    Analytics.trackSmartDiscountsInitFromList();
    window.location = Wahanda.Url.getFullUrl(
      'marketing?referrer=discounts-link',
      `email-campaign/smart-discounts/${this.props.id}`,
    );
  };

  handleClose = () => {
    const {
      id,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'revertDiscountRuleAction' does not exist... Remove this comment to see the full error message
      actions: { revertDiscountRuleAction },
    } = this.props;
    this.setState({
      renderDialog: false,
    });
    revertDiscountRuleAction(id);
  };

  canRenderStartCampaignButton() {
    const { active, assignedMenuItems, lastMinuteDiscount, offpeakDiscounts } = this.props;
    return (
      Wahanda.Permissions.canAccessMarketingTools() &&
      App.isFeatureSupported('promo-offers-cmpgn') &&
      active &&
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      (lastMinuteDiscount.active || offpeakDiscounts.active) &&
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      assignedMenuItems.some((menuItem) => menuItem.purchasable && menuItem.active)
    );
  }

  hasDiscountRuleDialog() {
    const { renderDialog } = this.state;
    return renderDialog;
  }

  isLastMinuteDiscountActive() {
    const { lastMinuteDiscount } = this.props;
    const { active = false } = lastMinuteDiscount || {};
    return active;
  }

  isOffpeakDiscountActive() {
    const { offpeakDiscounts } = this.props;
    const { active = false } = offpeakDiscounts || {};
    return active;
  }

  renderLastMinuteDiscount() {
    const { lastMinuteDiscount } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'maxLastMinuteDiscount' does not exist on... Remove this comment to see the full error message
    const { maxLastMinuteDiscount = 0 } = lastMinuteDiscount || {};
    if (maxLastMinuteDiscount) {
      return (
        <span className="lastMinuteDiscountPercentage">
          {Wahanda.Template.render(lang.lastMinuteDiscount, {
            maxLastMinuteDiscount,
          })}
        </span>
      );
    }
    return null;
  }

  renderOffpeakDiscount() {
    const { offpeakDiscounts } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'maxOffPeakDiscount' does not exist on ty... Remove this comment to see the full error message
    const { maxOffPeakDiscount = 0 } = offpeakDiscounts || {};
    if (maxOffPeakDiscount) {
      return (
        <span className="offPeakDiscountPercentage">
          {Wahanda.Template.render(lang.offpeakDiscount, {
            maxOffPeakDiscount,
          })}
        </span>
      );
    }
    return null;
  }

  renderAppliedToTreatments() {
    const { number = 0 } = this.props;
    const warning = number === 0;
    const numberOfTreatments = Wahanda.Text.pluralizeNumber(number, APPLIED_TO_TREATMENTS);
    return (
      <span className={classnames({ [style.warning]: warning }, 'assignedMenuItems')}>
        {Wahanda.Template.render(lang.appliedTo.numberOfTreatments, {
          numberOfTreatments,
        })}
      </span>
    );
  }

  renderStatus() {
    const { active } = this.props;
    return (
      <Badge type={active ? 'success' : 'muted'} text={active ? lang.active : lang.inactive} />
    );
  }

  renderDiscountRuleDialog() {
    /*
     *  We genuinely want to just pass through our props here, as our props is exactly what is
     *  needed for the render. And we don't want clicks to propagate up to the <li />
     */
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={style.discountListItemDialog}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ updateDiscountRuleAction: (...args: any[])... Remove this comment to see the full error message */}
        <Dialog {...this.props} onClose={this.handleClose} />
      </div>
    );
  }

  render() {
    const { name } = this.props;
    let discountRuleDialog;
    let lastMinuteDiscount;
    let offpeakDiscounts;
    if (this.hasDiscountRuleDialog()) {
      discountRuleDialog = this.renderDiscountRuleDialog();
    }
    if (this.isLastMinuteDiscountActive()) {
      lastMinuteDiscount = this.renderLastMinuteDiscount();
    }
    if (this.isOffpeakDiscountActive()) {
      offpeakDiscounts = this.renderOffpeakDiscount();
    }
    const appliedToTreatments = this.renderAppliedToTreatments();
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <li className={style.discountListItem} onClick={this.handleRowClick}>
        {discountRuleDialog}

        <div className={style.description}>
          <h3 className="discountName">{name}</h3>
          <p>
            {lastMinuteDiscount}
            {offpeakDiscounts}
            {appliedToTreatments}
          </p>
        </div>
        {this.canRenderStartCampaignButton() && (
          <button className={style.startCampaign} onClick={this.handleStartCampaignClick}>
            {lang.startCampaign}
          </button>
        )}
        <div className={style.status}>{this.renderStatus()}</div>
      </li>
    );
  }
}

/*
 *  API data can have null for these attributes
 */
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
DiscountListItem.defaultProps = {
  lastMinuteDiscount: {
    active: false,
  },
  offpeakDiscounts: {
    active: false,
  },
  number: 0,
  active: false,
  assignedMenuItems: [],
};
