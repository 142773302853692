import {
  GET_PRODUCT_ORDERS_REQUEST,
  GET_PRODUCT_ORDERS_RECEIVE,
  GET_PRODUCT_ORDERS_FAILURE,
  PRODUCT_ORDERS_RESET,
  CREATE_PRODUCT_ORDER_REQUEST,
  CREATE_PRODUCT_ORDER_SUCCESS,
  CREATE_PRODUCT_ORDER_FAILURE,
  COMPLETE_PRODUCT_ORDER_REQUEST,
  COMPLETE_PRODUCT_ORDER_SUCCESS,
  COMPLETE_PRODUCT_ORDER_FAILURE,
  PRODUCT_ORDER_REQUEST,
  PRODUCT_ORDER_RECEIVE,
  PRODUCT_ORDER_FAILURE,
  PRODUCT_ORDER_RESET,
  CANCEL_PRODUCT_ORDER_REQUEST,
  CANCEL_PRODUCT_ORDER_SUCCESS,
  CANCEL_PRODUCT_ORDER_FAILURE,
  UPDATE_EXISTING_ORDER,
  PRODUCT_ORDER_SELECT_SUPPLIER,
  PRODUCT_ADD_TO_ORDER,
  PRODUCT_REMOVE_FROM_ORDER,
  PRODUCT_ORDER_UPDATE,
  NEW_PRODUCT_ORDER_RESET,
  PRODUCT_ORDER_READY_TO_BE_PLACED,
  PRODUCT_ORDER_CAN_BE_PROCESSED,
} from 'reduxStore/actionsConst';

export const requestProductOrdersAction = () => ({
  type: GET_PRODUCT_ORDERS_REQUEST,
});

export const receiveProductOrdersAction = (data) => ({
  type: GET_PRODUCT_ORDERS_RECEIVE,
  data,
});

export const requestProductOrdersFailureAction = (ex) => ({
  type: GET_PRODUCT_ORDERS_FAILURE,
  ex,
});

export const productOrdersReset = () => ({
  type: PRODUCT_ORDERS_RESET,
});

export const requestCreateOrderAction = (data) => ({
  type: CREATE_PRODUCT_ORDER_REQUEST,
  data,
});

export const successCreateOrderAction = (data) => ({
  type: CREATE_PRODUCT_ORDER_SUCCESS,
  data,
});

export const requestCreateOrderFailureAction = () => ({
  type: CREATE_PRODUCT_ORDER_FAILURE,
});

export const requestCompleteOrderAction = (data) => ({
  type: COMPLETE_PRODUCT_ORDER_REQUEST,
  data,
});

export const successCompleteOrderAction = () => ({
  type: COMPLETE_PRODUCT_ORDER_SUCCESS,
});

export const requestCompleteOrderFailureAction = () => ({
  type: COMPLETE_PRODUCT_ORDER_FAILURE,
});

export const requestProductOrderAction = (data) => ({
  type: PRODUCT_ORDER_REQUEST,
  productOrderId: data,
});

export const receiveProductOrderAction = (data) => ({
  type: PRODUCT_ORDER_RECEIVE,
  data,
});

export const requestProductOrderFailureAction = (ex) => ({
  type: PRODUCT_ORDER_FAILURE,
  ex,
});

export const requestCancelProductOrderAction = (data) => ({
  type: CANCEL_PRODUCT_ORDER_REQUEST,
  productOrderId: data,
});

export const successCancelProductOrderAction = () => ({
  type: CANCEL_PRODUCT_ORDER_SUCCESS,
});

export const requestCancelProductOrderFailureAction = (ex) => ({
  type: CANCEL_PRODUCT_ORDER_FAILURE,
  ex,
});

export const productOrderReset = () => ({
  type: PRODUCT_ORDER_RESET,
});

export const updateExistingOrder = (item) => ({
  type: UPDATE_EXISTING_ORDER,
  item,
});

export const selectProductOrderSupplierAction = (supplier) => ({
  type: PRODUCT_ORDER_SELECT_SUPPLIER,
  supplier,
});

export const productAddToOrder = (item) => ({
  type: PRODUCT_ADD_TO_ORDER,
  item,
});

export const productRemoveFromOrder = (item) => ({
  type: PRODUCT_REMOVE_FROM_ORDER,
  item,
});

export const productOrderUpdate = (item) => ({
  type: PRODUCT_ORDER_UPDATE,
  item,
});

export const newProductOrderReset = () => ({
  type: NEW_PRODUCT_ORDER_RESET,
});

export const productOrderReadyToBePlaced = () => ({
  type: PRODUCT_ORDER_READY_TO_BE_PLACED,
});

export const productOrderCanBeProcessed = () => ({
  type: PRODUCT_ORDER_CAN_BE_PROCESSED,
});
