import React from 'react';
import ReactDOM from 'react-dom';
import { assoc } from 'mori';
import { appState } from 'state';
import storeBuilder from 'reduxStore/store';
import { Provider } from 'react-redux';
import { fetch as fetchCheckoutSummary } from 'src/services/pos-checkout-summary';
import { TransactionSummaryContainer } from 'src/components/reports/transactions/TransactionSummary/container';

const store = storeBuilder();

export const CheckoutSummaryDialogInitializer = ({ checkoutId, date, onClose }, node) => {
  appState.swap((s) => assoc(s, 'reports-trans-table:checkoutId', checkoutId));
  fetchCheckoutSummary(checkoutId);
  return {
    destroy: () => ReactDOM.unmountComponentAtNode(node),
    render: () => {
      ReactDOM.render(
        <Provider store={store}>
          <TransactionSummaryContainer checkoutId={checkoutId} date={date} onClose={onClose} />
        </Provider>,
        node,
      );
    },
  };
};
