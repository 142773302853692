/*
 *  Functions to deal with `extranet-settings`
 *  API response data
 */
export const VENUE_STATUS_PENDING = 'P';

export const VENUE_STATUS_ACTIVE = 'A';

export const VENUE_STATUS_TEMPORARILY_DISABLED = 'T';

/*
 *  "extranetSettings" CAN be null so cannot default with ({ account } = {})
 */
export const isMasquerading = (extranetSettings) => {
  const { account } = extranetSettings || {};
  return account ? account.masquerade : false;
};

/*
 *  "extranetSettings" CANNOT be null
 */
export const isFeatureSupported = ({ supportedFeatures }, featureName) =>
  !!supportedFeatures.find((feature) => feature === featureName);

/*
 *  "extranetSettings" CANNOT be null
 */
export const isVenuePending = ({ venue: { venueStatusType } }) =>
  venueStatusType === VENUE_STATUS_PENDING;

/*
 *  "extranetSettings" CANNOT be null
 */
export const isVenueTemporarilyDisabled = ({ venue: { venueStatusType } }) =>
  venueStatusType === VENUE_STATUS_TEMPORARILY_DISABLED;
