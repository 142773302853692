import {
  GET_SALES_SUMMARY_REPORT_RECEIVE,
  GET_VENUE_MONTHLY_SALES_REPORT_RECEIVE,
} from 'reduxStore/actionsConst';

const initialState = { total: {} };

export default function venueStatsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SALES_SUMMARY_REPORT_RECEIVE:
      return {
        ...state,
        ...action.data,
      };
    case GET_VENUE_MONTHLY_SALES_REPORT_RECEIVE:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
