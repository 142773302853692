import App from 'common/backbone-app';
import * as Sentry from '@sentry/browser';
import { loadScript } from '../../../javascripts/wahanda/loadScript';
import { ChatSourceType } from 'src/tracking-container/events/chat';
import { getTrackingByEventName } from 'src/tracking-container/TrackingEvents';
import { getExternalUserFullName } from './getExternalUserFullName';
import { SalesForceBotApi } from './index.d';
import { getSalesForceScriptConfig } from './getSalesForceScriptConfig';
import { getSalesForceLocale } from './getSalesForceLocale';
import { sanitizeValues } from 'common/salesforce-bot/sanitizeValues';
import { FAKE_MASQUERADING_EMAIL } from 'common/salesforce-bot/constants';

let isSalesforceBotInitialised = false;

export const initUnsecureSalesforceBot = async (): Promise<SalesForceBotApi> => {
  // send back the same instance each time otherwise we get a load of
  // duplicate iframe embeds
  if (isSalesforceBotInitialised) {
    return Promise.resolve(window.embeddedservice_bootstrap);
  }

  const {
    bootstrapJsSrc,
    init: { orgId, eswConfigDevName, siteUrl },
    scrt2URL,
  } = getSalesForceScriptConfig();

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error('Chat initialization'));
    }, 10000);

    loadScript({ src: bootstrapJsSrc }).then(() => {
      window.embeddedservice_bootstrap.settings.language = getSalesForceLocale();
      window.embeddedservice_bootstrap.settings.hideChatButtonOnLoad = true;
      window.embeddedservice_bootstrap.init(orgId, eswConfigDevName, siteUrl, { scrt2URL });

      /**
       * We can only use the embedded service API once the onEmbeddedMessagingReady event has been fired
       * so we wait until that happens before resolving the promise with the API
       */
      window.addEventListener('onEmbeddedMessagingReady', () => {
        clearTimeout(timeout);
        isSalesforceBotInitialised = true;
        resolve(window.embeddedservice_bootstrap);
      });
    });
  });
};

const trackChatOpened = getTrackingByEventName('chat_opened');

export const launchUnsecureSalesforceBot = async ({ source }: { source: ChatSourceType }) => {
  const botApi = await initUnsecureSalesforceBot();

  const { valids, invalids } = sanitizeValues({
    externalUserFullName: getExternalUserFullName(),
    externalUserId: App.config.getAccountId(),
    externalVenueId: App.config.get('venue').id,
    externalLanguage: getSalesForceLocale(),
    externalSource: 'Web', // Web, iOS, Android
    externalUserEmail: App.isMasquerading()
      ? FAKE_MASQUERADING_EMAIL
      : App.config.get('account').emailAddress,
    externalCountry: App.config.get('venue').countryCode,
  });

  botApi.prechatAPI.setHiddenPrechatFields(valids);
  botApi.utilAPI.launchChat();

  trackChatOpened({ service: 'Salesforce', source });

  if (Object.keys(invalids).length) {
    Sentry?.captureMessage('Invalid Salesfoce chat fields', {
      level: 'warning',
      extra: { fields: invalids },
    });
  }
};
