import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorDialog } from 'src/components/common/dialog/ErrorDialog';

function onClose(container) {
  ReactDOM.unmountComponentAtNode(container);
}

export default function (options) {
  const container = Wahanda.Dialogs.getReactContainer();
  const close = () => {
    onClose(container);
  };
  const dialog = <ErrorDialog intro={options.intro} errors={options.errorList} onClose={close} />;
  ReactDOM.render(dialog, container);
}
