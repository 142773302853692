(function() {
    var TIMER_NAME = 'sound-notifications';

    App.Timer.on(TIMER_NAME, function() {
        var soundEl = getSoundElement();
        if (!soundEl) {
            return;
        }
        soundEl.play();
    });

    function getSoundElement() {
        var $el = $('#sound-notification');
        if ($el.length === 0) {
            var html = Wahanda.Template.get('sound-notification-template');
            $('body').append(html);
            $el = $('#sound-notification');
        }
        return $el[0];
    }
}());