export const PERMISSIONS = Object.freeze({
  viewBookings: 'view-bookings',
  manageVenueDetails: 'manage-venue-details',
  viewFinanceData: 'view-finance-data',
  viewReportsSales: 'view-reports-sales',
  cancelTransaction: 'cancel-transaction',
  canEditServicePrice: 'can-edit-service-price',
  viewMenu: 'view-menu',
  editMenu: 'edit-menu',
  viewClientList: 'view-client-list',
  viewClientContactDetails: 'view-client-contact-details',
  accessMarketingTools: 'access-marketing-tools',
  editOwnCalendar: 'edit-own-calendar',
  viewAnyCalendar: 'view-any-calendar',
  editAnyCalendar: 'edit-any-calendar',
  deleteAppointments: 'delete-appointments',
  addTherapists: 'add-therapists',
  editUserPermissions: 'edit-user-permissions',
  managePos: 'manage-pos',
});

export const POINT_OF_SALE_PERMISSIONS = Object.freeze({
  managePos: 'manage-pos',
});
