require('./tab-base.js');

import { DISTRIBUTION_CHANNELS } from 'src/common/consts';
// For some reason if these lines below are in the top of the file, Babel complains and crashes.

/* eslint-disable no-underscore-dangle, func-names */
/* eslint no-param-reassign: ["error", { "props": false }] */
/* global _ BackboneEx */

App.Views.Forms.MenuOffer2.DistributionTab = App.Views.Forms.MenuOffer2.TabBase.extend(
  {
    tabName: Wahanda.lang.menu.offer.tabs.distribution.title,
    tabClasses: 'offer2--distribution',
    templateId: 'menu-offer2-distribution-template',

    events: {
      'change select[name=fulfillmentTypes]': 'onFulfillmentTypeChange',
      'change select[name=expiryType]': 'onExpiryTypeChange',
      'change #fulfillment-useApplicableDays': 'toggleApplicableDays',
      'change #offer-menu-visibility': 'onVisibilityRangeChange',
      'change input[name=featured]': 'onFeaturedStateChange',

      'change input[name=distributionChannel-sellOnline]': 'onSellOnlineChange',
      'change input#distributionChannel-suppliers_customer': 'onDistributionChannelsChange',
      'change input#distributionChannel-customer': 'onDistributionChannelsChange',
    },

    initialize: function (...args) {
      this.constructor.__super__.initialize.apply(this, args);

      this.listenTo(this.model, 'update', function () {
        this.render();
      });
      this.listenTo(
        this.options.mediator,
        this.options.mediator.PRICING_TYPE_CHANGE,
        this.onPricingTypeChange,
      );
      this.listenTo(
        this.options.mediator,
        this.options.mediator.CHANGE_USE_PROCESSING_TIME,
        this.onChangeUseProcessingTime.bind(this),
      );

      if (!this.model.id && this.model.isTreatment()) {
        const mediator = this.options.mediator;
        this.listenTo(mediator, mediator.MAIN_TREATMENT_CHANGED, function (treatment) {
          this.setMultiSkuValue(treatment.get('multiSkuSelection'));
        });
      }

      this.weekdayCheckboxesView = new App.Views.Partials.WeekdayCheckboxes({
        tabindexStart: 109,
      });
    },

    render: function () {
      const templateVars = this.getTemplateVars();
      const html = Wahanda.Template.renderTemplate(this.templateId, templateVars);
      this.$el.empty().append(html);

      this.enableTooltips();
      this.renderBookingMinHours();
      this.weekdayCheckboxesView.options.selectedDays =
        this.model.get('applicableDays') != null
          ? this.model.get('applicableDays')
          : this.options.venue.get('applicableDays');

      const isFeatured = this.isMarkedAsFeatured();

      const $weekdays = this.$('.weekdays');
      if ($weekdays.length) {
        this.weekdayCheckboxesView.setElement($weekdays);
        this.weekdayCheckboxesView.render();
      }

      this.$('.fulfillment-edit').removeClass('hidden');
      // if not featured, disable most of the fields
      this.toggleFormElements(isFeatured);

      this.$('.datepicker').venueDatepicker();

      $.dateFieldAlign.connect({
        first: this.$('#offer-visibleFrom'),
        later: this.$('#offer-visibleTo'),
      });

      const $fulfillmentType = this.$('select[name=fulfillmentTypes]');
      if (!templateVars.fulfillmentTypeEditable) {
        $fulfillmentType.prop('disabled', true).val('V');
      } else {
        $fulfillmentType.val(this.model.getFulfillmentString());
      }
      this.onFulfillmentTypeChange();

      if (this.model.isReadOnly()) {
        // If locked, disable everything
        $.toggleForm.findFormElements(this.$el).disableFormElements();
      }

      this.setExpiryType();
      this.onExpiryTypeChange();
      this.toggleApplicableDays();
      this.setVisibilityRangeType(this.model.getVisibleInMenuCode());

      if (this.model.isEscapeWithRangePricing()) {
        this.$('#spa-pricingDisplayTypeCode').val(this.model.get('pricingDisplayTypeCode'));
      }

      // Disallowing to invert disparity
      if (
        this.getHasChannelsDisparity() &&
        this.getIsVenueListedOnMarketplace() &&
        !this.getIsMasquerading()
      ) {
        const isSellOnlineChecked = this.$('#distributionChannel-sellOnline').prop('checked');
        const suppliersCustomerCheckbox = this.$('#distributionChannel-suppliers_customer');
        const customerCheckbox = this.$('#distributionChannel-customer');

        if (suppliersCustomerCheckbox.prop('checked')) {
          suppliersCustomerCheckbox.prop('checked', isSellOnlineChecked);
          suppliersCustomerCheckbox.prop('disabled', true);
        }
        if (customerCheckbox.prop('checked')) {
          customerCheckbox.prop('checked', isSellOnlineChecked);
          customerCheckbox.prop('disabled', true);
        }
      }
    },

    isMarkedAsFeatured: function () {
      return this.$('input[name=featured]').is(':checked');
    },

    toggleApplicableDays: function () {
      this.$('.weekdays').toggle(!!this.$('#fulfillment-useApplicableDays').val());
    },

    renderBookingMinHours: function () {
      const min = App.referenceData.get('appointment-min-availability-period') || 1;
      const hoursInDay = 24;

      // Max selectable elements
      const maxDays = 2;
      const duration = Wahanda.lang.datetime.duration;
      const $fragmentWithHours = $(document.createDocumentFragment());
      let i;
      let title;
      const minutes = [30];
      const hours = [1, 2, 4, 8];
      const previousHour = 1;
      const leadTimeMinutes = this.model.get('leadTimeMinutes');
      const leadTimeHours = leadTimeMinutes ? leadTimeMinutes / 60 : null;

      const venueMinutes = this.options.venue.get('leadTimeMinutes');
      const initialValue = Wahanda.lang.menu.offer.appointmentCloseOutTime.initialValue;
      let formattedInherittedValue;

      // No close out
      $fragmentWithHours.append(
        $('<option value="0">').text(Wahanda.lang.settings.venue.policies.noCloseOut),
      );
      if (venueMinutes === 0) {
        formattedInherittedValue = Wahanda.lang.settings.venue.policies.noCloseOut;
      }

      // Minutes
      for (i = 0; i < minutes.length; i += 1) {
        const minute = minutes[i];

        // check if a non standard value was set for this service

        if (leadTimeMinutes > previousHour && leadTimeMinutes < minute) {
          title = `${leadTimeMinutes} ${(parseInt(leadTimeMinutes, 10) === 1
            ? duration.minute
            : duration.minutes
          ).toLowerCase()}`;
          $fragmentWithHours.append($('<option>', { value: leadTimeMinutes }).text(title));
        }

        title = `${minute} ${(parseInt(minute, 10) === 1
          ? duration.minute
          : duration.minutes
        ).toLowerCase()}`;
        $fragmentWithHours.append($('<option>', { value: minute }).text(title));
        if (minute === venueMinutes) {
          formattedInherittedValue = title;
        }
      }

      // Hours
      for (i = 0; i < hours.length; i += 1) {
        const hour = hours[i];

        // check if a non standard value was set for this service

        if (leadTimeHours > previousHour && leadTimeHours < hour) {
          title = `${leadTimeHours} ${(parseInt(leadTimeHours, 10) === 1
            ? duration.hour
            : duration.hours
          ).toLowerCase()}`;
          $fragmentWithHours.append($('<option>', { value: leadTimeHours * 60 }).text(title));
        }

        title = `${hour} ${(parseInt(hour, 10) === 1
          ? duration.hour
          : duration.hours
        ).toLowerCase()}`;
        $fragmentWithHours.append($('<option>', { value: hour * 60 }).text(title));
        if (hour === venueMinutes / 60) {
          formattedInherittedValue = title;
        }
      }

      if (leadTimeHours > hours[hours.length - 1] && leadTimeHours < hoursInDay) {
        title = `${leadTimeHours} ${duration.hours.toLowerCase()}`;
        $fragmentWithHours.append($('<option>', { value: leadTimeHours / 60 }).text(title));
      }

      // Days
      for (i = Math.max(min, hoursInDay); i <= maxDays * hoursInDay; i += hoursInDay) {
        const day = parseInt(i / hoursInDay, 10);
        title = `${day} ${(day === 1 ? duration.day : duration.days).toLowerCase()}`;
        $fragmentWithHours.append($('<option>', { value: i * 60 }).text(title));
        if (i === venueMinutes / 60) {
          formattedInherittedValue = title;
        }
      }

      if (leadTimeHours > maxDays * hoursInDay) {
        const day = parseInt(leadTimeHours / hoursInDay, 10);
        title = `${day} ${duration.days.toLowerCase()}`;
        $fragmentWithHours.append($('<option>', { value: leadTimeHours }).text(title));
      }

      this.$('#leadTimeMinutes')
        .empty()
        .append($('<option value="">').text(`${initialValue} (${formattedInherittedValue})`))
        .append($fragmentWithHours);

      this.$('#leadTimeMinutes').val(leadTimeMinutes === null ? '' : leadTimeMinutes);
    },

    setExpiryType: function () {
      let type = '';
      if (this.model.get('voucherValidityMonths')) {
        type = 'after';
      }
      this.$('#fulfillment-expiry-type').val(type);
    },

    onFeaturedStateChange: function (event) {
      const isFeatured = $(event.currentTarget).prop('checked');
      this.toggleFormElements(isFeatured);
      App.trigger(Wahanda.Event.MENU_OFFER_FEATURE_CHANGE);
    },

    getTaxRate: function getTaxRate() {
      if (this.model.get('vatCategory') == null) {
        return App.getVenueStandartVat().category;
      }
      return this.model.get('vatCategory');
    },

    getVatRates: function getVatRates() {
      return App.getVenueChannelVATRates()
        .sort((a, b) => b - a)
        .map(({ rate, category }) => ({
          text: rate,
          value: category,
          selected: category === this.getTaxRate(),
        }));
    },

    showTaxRow: function showTaxRow() {
      return App.config.get('venue').pointOfSaleEnabled || this.options.venue.get('template');
    },

    getTemplateVars: function () {
      const isRangePricingEscape = this.model.isEscapeWithRangePricing();
      const isMultiSkuDisabled = this.isMultiSkuDisabled();

      const values = {
        pricingDisplayEditable: isRangePricingEscape,
        fulfillmentTypeEditable: !this.model.isSpaBreak() || this.model.isEscapeWithSkuPricing(),
        applicableDaysEditable: !isRangePricingEscape,
        distributionChannelEditable: !this.model.isEscape(),
        visibleFromDate: this.model.getStringDate('visibleFrom'),
        visibleToDate: this.model.getStringDate('visibleTo'),
        channels: {},
        multiSkuVisible: this.model.isTreatment(),
        multiSkuDisabled: isMultiSkuDisabled,
        multiSkuSelection: this.model.get('multiSkuSelection'),
        showFeaturing: this.model.get('visible'),
        allowFeaturing: this.options.featuredItemsCollection.canMakeFeatured(this.model),
        featured: this.model.get('featured'),
        showTaxRow: this.showTaxRow(),
        vatRates: this.getVatRates(),
        taxRate: this.getTaxRate(),

        hasChannelsDisparity: this.getHasChannelsDisparity(),
        isMasquerading: this.getIsMasquerading(),
        isSoldOnline: this.getIsSoldOnline(),
        isVenueListedOnMarketplace: this.getIsVenueListedOnMarketplace(),
        isMarketplaceChannelEnabled: this.getCurrentDistributionChannels().includes(
          DISTRIBUTION_CHANNELS.CUSTOMER,
        ),
        isWidgetChannelEnabled: this.getCurrentDistributionChannels().includes(
          DISTRIBUTION_CHANNELS.WIDGET,
        ),
      };

      if (values.fulfillmentTypeEditable) {
        let isEvoucherOnly =
          (this.model.isFulfilledAs('evoucher') && !this.model.isFulfilledAs('appointment')) ||
          this.model.isSpaBreak() ||
          this.model.isSpaDay();

        // Check if this is a "copy" of an evoucher only service
        if (
          !this.model.id &&
          isEvoucherOnly &&
          !this.model.isSpaDay() &&
          !this.model.isSpaBreak()
        ) {
          isEvoucherOnly = false;
        }

        _.extend(values, {
          validityMonths: this.getValidityMonths(),
          useApplicableDays: !!this.model.get('useApplicableDays'),
          // Mixture of allowed fulfillments
          fulfillments: {
            evoucher:
              (!this.model.isSpaBreak() || this.model.isEscapeWithSkuPricing()) &&
              App.isFeatureSupported('voucher-fulfillment') &&
              this.model.get('fulfillmentTypes') &&
              this.model.get('fulfillmentTypes').evoucher,
            dated: App.config.get('venue').canSellDatedServices && isRangePricingEscape,
            appointment: !this.model.isEscape(),
            isEvoucherOnly: isEvoucherOnly,
          },
        });

        const fulfillmentChoices = _.reduce(
          values.fulfillments,
          function (memo, val) {
            return val ? memo + 1 : memo;
          },
          0,
        );

        values.fulfillmentTypeVisible =
          fulfillmentChoices > 1 && (values.fulfillments.dated || values.fulfillments.evoucher);
      }

      if (this.hasProcessingOrFinishingTime()) {
        this.onChangeUseProcessingTime(true);
      }

      return values;
    },

    getValidityMonths: function () {
      const months = [1, 2, 3, 6, 12];
      const list = [];
      const selected =
        this.model.get('voucherValidityMonths') != null
          ? this.model.get('voucherValidityMonths')
          : this.options.venue.get('voucherValidityMonths');
      Object.keys(months).forEach((i) => {
        const name =
          months[i] === 1
            ? Wahanda.lang.datetime.distance_in_words.x_months.one
            : Wahanda.lang.datetime.distance_in_words.x_months.other.replace(
                '{{count}}',
                months[i],
              );
        list.push({
          value: months[i],
          name: name,
          on: selected == months[i], // eslint-disable-line eqeqeq
        });
      });
      return list;
    },

    onFulfillmentTypeChange: function () {
      const type = this.$('select[name=fulfillmentTypes]').val();
      const types = App.Models.MenuOffer.parseFulfillmentString(type);
      this.$('.voucher-part').toggleClass('hidden', !types.evoucher);
      this.$('.appointment-part').toggleClass('hidden', !types.appointment);

      this.trigger('fulfillment-type-change', types);
    },

    onSellOnlineChange: function (event) {
      const isSoldOnline = event.currentTarget.checked;
      const channelsDOM = Array.from(
        document.querySelectorAll('input[name^=offer-distributionChannels]'),
      );
      channelsDOM.forEach((channel) => {
        channel.checked = isSoldOnline;
      });

      // If venue is not marketplace then we leave the old CUSTOMER value as it was
      if (!this.getIsVenueListedOnMarketplace()) {
        const channels = this.getCurrentDistributionChannels();
        document.querySelector('input#distributionChannel-customer').checked =
          channels.includes(DISTRIBUTION_CHANNELS.CUSTOMER) || false;
      }
    },

    onDistributionChannelsChange: function () {
      const channelsDOM = Array.from(
        document.querySelectorAll('input[name^=offer-distributionChannels]'),
      );
      const shouldBeSoldOnline = channelsDOM.some((channel) => channel.checked);
      document.querySelector(
        'input[name=distributionChannel-sellOnline]',
      ).checked = shouldBeSoldOnline;

      const suppliersCustomerCheckbox = this.$('#distributionChannel-suppliers_customer');
      const customerCheckbox = this.$('#distributionChannel-customer');
      if (shouldBeSoldOnline) {
        if (suppliersCustomerCheckbox.prop('disabled')) {
          suppliersCustomerCheckbox.prop('checked', true);
        }
        if (customerCheckbox.prop('disabled')) {
          customerCheckbox.prop('checked', true);
        }
      }
    },

    onExpiryTypeChange: function () {
      let showTimeFrameSelect = false;
      let showVenueTimeFrame = false;
      switch (this.$('#fulfillment-expiry-type').val()) {
        case '':
          showVenueTimeFrame = true;
          this.$('.venue-expiry').text(
            Wahanda.Template.render(
              Wahanda.lang.menu.offer.fulfillment.voucher.expiry.venueTemplate,
              { months: this.options.venue.get('voucherValidityMonths') },
            ),
          );
          break;

        case 'after':
          showTimeFrameSelect = true;
          break;
        default:
      }

      this.$('[name=voucherValidityMonths]')
        .toggleClass('hidden', !showTimeFrameSelect)
        .toggleClass('required', showTimeFrameSelect);
      this.$('.venue-expiry')
        .toggleClass('hidden', !showVenueTimeFrame)
        .toggleClass('requried', showVenueTimeFrame);
    },

    onVisibilityRangeChange: function (event) {
      const value = $(event.currentTarget).val();
      this.setVisibilityRangeType(value);
      // Focusing the first visible date field
      this.$('.b-offer-visibility')
        .find('input[type=text]:visible')
        .filter(function () {
          // Filter out the first empty input element
          return this.value === '';
        })
        .first()
        .focus();
    },

    setVisibilityRangeType: function (type) {
      // Set the select's value
      this.$('#offer-menu-visibility').val(type);
      // Set fields according to it
      const isFromVisible = _.indexOf(['FR', 'RG'], type) !== -1;
      const isUntilVisible = _.indexOf(['ED', 'RG'], type) !== -1;

      this.$('.b-menu-visibility-both').toggle(isFromVisible && isUntilVisible);
      // Toggling 'Until' range validator, if only one field is shown
      const $until = this.$('#offer-visibleTo');
      if (isFromVisible && isUntilVisible) {
        $until.attr('date-not-less', '#offer-visibleFrom');
      } else {
        $until.removeAttr('date-not-less').removeClass('error').qtip('destroy');
      }

      function toggleDateElement($el, isVisible) {
        $el.toggle(isVisible);

        const $input = $el.find('input').toggleClass('date-format-default required', isVisible);
        if (isVisible) {
          $input.attr('date-format', App.config.get('jqueryDateFormat').defaultDate);
        } else {
          $input.removeAttr('date-format').removeClass('error');
        }
        if (!isVisible) {
          $input.valid();
        }
      }

      toggleDateElement(this.$('.b-menu-visibility-from'), isFromVisible);
      toggleDateElement(this.$('.b-menu-visibility-until'), isUntilVisible);
    },

    /**
     * Return this View's form item values.
     *
     * They must correspond to all business logic.
     */
    getValues: function () {
      const values = _.extend(
        {
          datedServiceTypeCode: null,
          featured: this.$('[name=featured]').prop('checked'),
        },
        this._getMenuVisibleDates(),
        this._getDistributionChannels(),
      );

      if (!this.model.isSpaBreak() || this.model.isEscapeWithSkuPricing()) {
        _.extend(values, this._getValues());
      }
      if (this.model.isEscapeWithRangePricing()) {
        _.extend(values, this._getValuesDated());
      }
      if (this.model.isFulfilledAs('dated')) {
        values.datedServiceTypeCode = this.model[
          this.model.isSpaDay() ? 'DATED_TYPE_SINGLE' : 'DATED_TYPE_OVERNIGHT_FIXED'
        ];
      } else {
        values.datedServiceTypeCode = null;
      }

      if (this.showTaxRow()) {
        values.vatCategory = this.$('.tax-input').val();
      } else {
        values.vatCategory = this.getTaxRate();
      }

      return values;
    },

    _getMenuVisibleDates: function () {
      let visibleFrom = null;
      let visibleTo = null;

      const type = this.$('#offer-menu-visibility').val();
      // 'AL' type has both values as null
      switch (type) {
        case 'RG':
          visibleFrom = Wahanda.Date.toApiString(
            this.$('#offer-visibleFrom').datepicker('getDate'),
          );
          visibleTo = Wahanda.Date.toApiString(this.$('#offer-visibleTo').datepicker('getDate'));
          break;

        case 'FR':
          visibleFrom = Wahanda.Date.toApiString(
            this.$('#offer-visibleFrom').datepicker('getDate'),
          );
          break;

        case 'ED':
          visibleTo = Wahanda.Date.toApiString(this.$('#offer-visibleTo').datepicker('getDate'));
          break;

        default:
      }

      return {
        visibleFrom: visibleFrom,
        visibleTo: visibleTo,
      };
    },

    _getDistributionChannels: function () {
      // *always* include the SUPPLIER channel
      let result = ['SUPPLIER'];

      const possibilities = Object.values(DISTRIBUTION_CHANNELS);

      if (this.model.isEscape()) {
        // For escapes enable all dist. channels
        result = possibilities;
      } else {
        possibilities.forEach((channel) => {
          const isChecked = this.$(`#distributionChannel-${channel.toLowerCase()}`).prop('checked');

          if (isChecked) {
            result.push(channel);
          }
        });
      }

      return { distributionChannels: result };
    },

    _getValuesDated: function () {
      return {
        pricingDisplayTypeCode: this.$('#spa-pricingDisplayTypeCode').val(),
      };
    },

    _getValues: function () {
      const fulfillmentTypes = App.Models.MenuOffer.parseFulfillmentString(
        this.$('select[name=fulfillmentTypes]').val(),
      );
      let voucherValidityMonths = null;
      let applicableDays = null;
      let useApplicableDays = false;
      const leadTimeMinutes = $('#leadTimeMinutes').val();
      let multiSkuSelection = null;

      let canEdit = this.isMarkedAsFeatured();
      canEdit = canEdit || !this.model.isEscapeWithRangePricing();
      canEdit =
        canEdit ||
        (this.model.isEscapeWithRangePricing() && App.config.get('venue').canSellDatedServices);
      if (canEdit) {
        if (fulfillmentTypes.evoucher) {
          if (this.$('select[name=expiryType]').val() === 'after') {
            voucherValidityMonths = this.$('select[name=voucherValidityMonths]').val();
          }
        }

        applicableDays = [];
        this.$('.weekdays')
          .find('li.on')
          .each(function () {
            applicableDays.push($(this).data('value'));
          });

        useApplicableDays = !!this.$('#fulfillment-useApplicableDays').val();

        const $ms = this.$('#multiSkuSelection');
        multiSkuSelection = $ms.length > 0 ? $ms.prop('checked') : undefined;
      }

      return {
        leadTimeMinutes: leadTimeMinutes === '' ? null : leadTimeMinutes,
        fulfillmentTypes: fulfillmentTypes,
        voucherValidityMonths: voucherValidityMonths,
        applicableDays: applicableDays,
        useApplicableDays: useApplicableDays,
        multiSkuSelection: multiSkuSelection,
      };
    },

    setMultiSkuValue: function (isSet) {
      this.$('#multiSkuSelection').prop('checked', !!isSet);
    },

    destruct: function () {
      this.$('.datepicker').datepicker('destroy');
    },

    /**
     * Toggle form elements.
     *
     * @param boolean disable Should elements be disabled?
     */
    toggleFormElements: function () {
      this.onExpiryTypeChange();
    },

    toggleMultiSkuAvailable: function (wasCheckboxEnabled) {
      let isDisabled;

      // Enable multisku selection
      // if we are disabling the checkboxes and strictly one of the flags is enabled
      if (!wasCheckboxEnabled && this.use2dPricing !== this.useProcessingTime) {
        isDisabled = false;
      }

      // Disable multisku selection
      // if we are enabling the checkboxes and none one of the flags are enabled
      if (wasCheckboxEnabled && !(this.use2dPricing || this.useProcessingTime)) {
        isDisabled = true;
      }

      this.$('#multiSkuSelection').prop('disabled', isDisabled).prop('checked', false);

      // enable/disable toolip explaing why checkbox is disabled
      this.enableMultiSkuTooltip();
    },

    onPricingTypeChange: function (use2dPricing) {
      if (this.use2dPricing !== use2dPricing) {
        this.toggleMultiSkuAvailable(use2dPricing);
        this.use2dPricing = use2dPricing;
      }
    },

    hasProcessingOrFinishingTime: function () {
      return this.model.usesProcessingTime();
    },

    onChangeUseProcessingTime: function (useProcessingTime) {
      if (this.useProcessingTime !== useProcessingTime) {
        this.toggleMultiSkuAvailable(useProcessingTime);
        this.useProcessingTime = useProcessingTime;
      }
    },

    getIsSoldOnline: function () {
      const getIsVenueListedOnMarketplace = this.getIsVenueListedOnMarketplace;
      return this.getCurrentDistributionChannels()
        .filter((channel) => {
          if (!getIsVenueListedOnMarketplace() && channel === DISTRIBUTION_CHANNELS.CUSTOMER) {
            return false;
          }
          return true;
        })
        .some(_.identity);
    },

    getIsMasquerading: () => App.isMasquerading(),

    getIsVenueListedOnMarketplace: () => App.isVenueListedOnMarketplace(),

    getHasChannelsDisparity: function () {
      const allPossibilities = Object.values(DISTRIBUTION_CHANNELS);
      const availableChannels = this.getCurrentDistributionChannels();
      return availableChannels.length > 0 && availableChannels.length < allPossibilities.length;
    },

    getCurrentDistributionChannels: function () {
      if (!this.model.isEscape()) {
        const allPossibilities = Object.values(DISTRIBUTION_CHANNELS);
        let possibleChannels;
        if (this.model.get('distributionChannels')) {
          // Existing Service that we will PUT
          possibleChannels = this.model.get('distributionChannels');
        } else {
          // New service that we will POST

          possibleChannels = this.getIsVenueListedOnMarketplace()
            ? allPossibilities
            : allPossibilities.filter((value) => value !== DISTRIBUTION_CHANNELS.CUSTOMER);
        }
        return _.intersection(allPossibilities, possibleChannels);
      }
      return [];
    },

    enableTooltips: function () {
      this.$el.tooltipize({
        position: {
          my: 'bottom center',
          at: 'top left',
          adjust: {
            x: 96,
            y: 0,
          },
        },
        style: {
          tip: {
            height: 8,
          },
        },
      });

      this.enableMultiSkuTooltip();
    },

    enableMultiSkuTooltip: function () {
      const tooltipContainer = this.$('#multiSkuSelection').parents('.input-part').first();

      const tooltip = this.$('#multiSkuSelection').prop('disabled')
        ? tooltipContainer.attr('data-tooltip-disabled')
        : tooltipContainer.attr('data-tooltip-enabled');

      tooltipContainer.qtip({
        content: tooltip,
        position: {
          my: 'bottom center',
          at: 'top left',
          adjust: {
            x: 96,
            y: 0,
          },
        },
        style: {
          tip: {
            height: 8,
          },
        },
      });
    },

    isMultiSkuDisabled: function () {
      return (
        this.model.hasEnclosingPackages() ||
        this.model.is2Dpricing() ||
        this.hasProcessingOrFinishingTime()
      );
    },
  },
  {
    optionsToInherit: ['venue', 'featuredItemsCollection'],
  },
);

BackboneEx.Mixin.extendView(
  App.Views.Forms.MenuOffer2.DistributionTab,
  BackboneEx.Mixin.View.Datepicker,
);
