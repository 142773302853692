import React from 'react';

interface Props {
  className?: string;
}

class ComputerWidget extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-computer-widget', this.props.className].join(' ')}
        viewBox="0 0 96 49"
      >
        <g fill="none" transform="translate(0 1)" fillRule="evenodd">
          <path
            stroke="#232D56"
            d="M17.153,36.837 L17.088,14.859 L17.088,5.54 C17.088,2.926 19.192,0.795 21.775,0.795 L41.737,0.795"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            fill="#FFB500"
            d="M63.6,1.602 L75.48,1.602 C77.727,1.602 79.56,3.524 79.56,5.882 L79.56,22.678 C79.56,22.678 72.42,21.39 73.232,13.754 C74.045,6.117 65.641,10.198 63.6,1.602"
            fillRule="nonzero"
          />
          <path
            fill="#EB5D42"
            d="M65.482,41.732 C65.482,42.442 64.966,43.019 64.336,43.019 L31.112,43.019 C30.482,43.019 29.966,42.441 29.966,41.732 L29.966,35.892 C29.966,35.184 30.482,34.605 31.112,34.605 L64.336,34.605 C64.966,34.605 65.482,35.184 65.482,35.892 L65.482,41.732 Z"
            fillRule="nonzero"
          />
          <path
            stroke="#232D56"
            d="M83.528,42.672 L12.785,42.672 M42.376,0.766 L71.215,0.82 L75.542,0.82 C77.574,0.82 79.224,2.49 79.224,4.548 L79.224,39.041 C79.224,41.074 80.85,42.721 82.858,42.721 L93.498,42.721 C94.693,42.721 95.29,44.196 94.453,45.044 L94.191,45.31 C93.21,46.303 91.871,46.8830003 90.485,46.8830003 L5.468,46.8830003 C4.07065591,46.8830003 2.73321188,46.315614 1.763,45.31 L1.5,45.044 C0.664,44.173 1.26,42.721 2.456,42.721 L13.502,42.721"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            fill="#00B1B9"
            stroke="#00B1B9"
            d="M0.395,1.558 L15.933,1.558 L15.933,0.442 L0.395,0.442 L0.395,1.558 Z M0.395,4.907 L11.493,4.907 L11.493,3.791 L0.395,3.791 L0.395,4.907 Z"
            transform="translate(44 23)"
            fillRule="nonzero"
          />
          <path
            fill="#00B1B9"
            stroke="#00B1B9"
            d="M0.395,2.047 L15.933,2.047 L15.933,0.93 L0.395,0.93 L0.395,2.047 Z M0.395,5.395 L7.054,5.395 L7.054,4.28 L0.394,4.28 L0.395,5.395 Z"
            transform="translate(44 8)"
            fillRule="nonzero"
          />
          <polygon points="0 48.558 95.953 48.558 95.953 0 0 0" />
          <polygon points="0 48.558 95.953 48.558 95.953 0 0 0" />
          <polygon points="0 48.558 95.953 48.558 95.953 0 0 0" />
          <polygon points="0 48.558 95.953 48.558 95.953 0 0 0" />
          <polygon points="0 48.558 95.953 48.558 95.953 0 0 0" />
          <polyline stroke="#FFF" points="50.215 36.28 46.885 39.628 45.221 37.953" />
          <g transform="translate(27.747 18.977)">
            <polygon points="0 0 12.209 0 12.209 12.279 0 12.279" />
            <ellipse cx="6.104" cy="6.14" stroke="#4D4D4D" rx="4.587" ry="4.616" />
            <polyline stroke="#4D4D4D" points="6.104 3.07 6.104 6.14 7.63 7.674" />
          </g>
          <g transform="translate(28.856 4.465)">
            <polygon points="0 0 12.209 0 12.209 12.279 0 12.279" />
            <ellipse cx="2.543" cy="6.651" stroke="#4D4D4D" rx="1.017" ry="1.023" />
            <ellipse cx="5.596" cy="9.721" stroke="#4D4D4D" rx="1.017" ry="1.023" />
            <path
              stroke="#4D4D4D"
              d="M2.543,7.674 C3.561,7.674 5.596,6.651 5.596,6.651 L10.682,4.093 M5.596,6.651 L8.139,1.535"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export const icon = ComputerWidget;
