/**
 * POS checkout dialog Lite
 *
 */
import { CalendarAnalytics } from 'common/analytics';

(function () {
  if (!App.Views.Forms) {
    App.Views.Forms = {};
  }
  var BaseView = App.Views.Forms.POS.POSDialogBase;
  App.Views.Forms.POSDialogLite = BaseView.extend({
    baseView: App.Views.Forms.POS.POSDialogBase,

    templateId: 'pos-checkout-lite-template',

    options: {
      width: 560,
      dialogClass: 'dialog2--scrollable pos-checkout-dialog',
      dataTest: 'checkout-lite-modal',
    },

    dialogTitle: Wahanda.lang.calendar.appointments.pos.titleLite,

    buttons: {
      complete: {
        title: Wahanda.lang.calendar.appointments.pos.buttonComplete,
        classes:
          'dialog2--button-green pos-checkout--button-complete js-complete-checkout scrollbar-see-through-fix',
        onRight: true,
      },
    },

    getEmployeeId: function () {
      //For POS Lite, return the employee id of the authenticated account
      //Note: this is null when masquerading
      return App.config.get('account').employeeId;
    },

    openUDV: function () {
      if (this.collection && this.collection.models && this.collection.models.length > 0) {
        App.ES6.Initializers.State.change({
          'calendar-event-editor': {
            appointmentViewData: {
              model: new App.Models.Appointment({
                id: this.collection.models[0].id,
              }),
            },
            tab: 'appointment',
            top: top,
          },
        });
      }
    },

    footerText: {
      content: function () {
        return 'test';
      },
      classes: 'right footer-balance-due-lite',
    },

    initialize: function () {
      this.baseView.prototype.initialize.apply(this, arguments);

      //Pick up the parent events so we don't have to do our own close, etc
      this.events = _.extend({}, this.baseView.prototype.events, this.events || {});

      this.enableCompletePaymentButton = true;

      const self = this;
      Wahanda.Cache.allEmployees().done((employees) => {
        self.employees = employees;
      });
      CalendarAnalytics.trackPosLiteCheckoutDialogView();
    },

    render: function () {
      this.baseView.prototype.render.apply(this, arguments);
    },

    onWindowResize: function () {
      this.setHeight();
      this.setMaxScrollHeight();
      this.refreshPosition();
    },

    updateTotals: function () {
      this.renderBalances();
    },

    updatePayments: function () {
      // There are no payments for pos lite and so nothing is needed here
    },

    removePayments: function () {
      // There are no payments for pos lite and so nothing is needed here
    },

    getPaymentTitle: function () {
      return {
        title: Wahanda.lang.calendar.appointments.pos.paymentsTitleLite,
      };
    },

    renderBalances: function () {
      var balances = {
        'pos-balance-due-lite': $('.footer-balance-due-lite'),
        'pos-balance-total-balance-due': this.$('.js-subtotal-due'),
      };

      var self = this;
      _.each(balances, function ($el, templateId) {
        var view = self.createBalanceView($el, templateId);
        view.render();
      });
    },

    getCollections: function () {
      return [this.products];
    },

    async doCompleteCheckout() {
      if (this.enableCompletePaymentButton) {
        this.enableCompletePaymentButton = false;

        try {
          await this.saveCustomerIfNeeded();
        } catch (err) {
          this.onSavingFailed(!err || !err.isValidationError);
          return;
        }

        this.loadmask();
        this.checkoutPOS(this.getCheckoutModel(), {
          success: () => {
            App.trigger(Wahanda.Event.PRODUCTS_LIST_CHANGED);
            this.resetCloseChangesCheck();
            this.close();
            // we want to reopen the UDV of the appts just checked out
            this.openUDV();
            CalendarAnalytics.trackPosLiteCheckoutDialogSubmit();
          },
          error: () => this.onSavingFailed(),
        });
      }
    },
  });
})();
