import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import {
  getRankingBoostSuccessAction,
  getRankingBoostFailureAction,
  RankingBoostResponse,
} from './actions';

export function* getRankingBoostRequested() {
  try {
    const response: RankingBoostResponse = yield call(
      api.get,
      apiUrl('VENUE_RANKING_BOOST_STATUS'),
    );

    yield put(getRankingBoostSuccessAction(response));
  } catch (err) {
    yield put(getRankingBoostFailureAction());
  }
}
