import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../store/actions';
import { getShiftEmployee } from '../store/selectors';
import { ShiftEmployeeSelector as Container } from './ShiftEmployeeSelector';

const mapStateToProps = ({ shifts }, { employeeId }) => ({
  employee: getShiftEmployee(shifts, { employeeId }),
  employees: shifts.employees.employeesWorkingHours,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ShiftEmployeeSelector = connect(mapStateToProps, mapDispatchToProps)(Container);
