import {
  AutomatedMessagingCategory,
  AutomatedMessagingSetting,
  AutomatedMessagingSettings,
  SETTING,
} from 'components/marketing/AutomatedMessaging/types';
import { Analytics, Props as SettingProps, SettingBox } from 'components/common/SettingBox';
import { L2Page } from 'common/tracking/navigation';
import { AdvancedReminderSettings } from 'components/marketing/AutomatedMessaging/AdvancedReminderSettings';
import style from 'components/marketing/AutomatedMessaging/AutomatedMessaging.scss';
import { TabData, Tabs, TrackingDetails } from 'components/common/Tabs';
import { CATEGORY as trackCategory } from 'components/marketing/AutomatedMessaging/tracking';
import { ACTIONS } from 'common/analytics';
import React from 'react';
import Wahanda from 'common/wahanda';
import { AutomatedMessagingState } from 'components/marketing/AutomatedMessaging/reducer';
import { Badge } from 'components/common/Badge';

const lang = Wahanda.lang.marketing.automatedMessaging.tabs;

const getTabFromURL = (): AutomatedMessagingCategory => {
  const tab = new URLSearchParams(window.location.search).get('tab');
  switch (tab) {
    case 'marketing':
      return AutomatedMessagingCategory.MARKETING;
    case 'transactional':
      return AutomatedMessagingCategory.TRANSACTIONAL;
    default:
      return AutomatedMessagingCategory.MARKETING;
  }
};

const getAdvancedSettingsVisibleFromURL = (): boolean => {
  const searchParams = new URLSearchParams(window.location.search);
  return Boolean(searchParams.get('isAdvancedSettingsVisible') === 'true');
};

const removeAdvancedSettingsVisibleFromUrl = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete('isAdvancedSettingsVisible');
  window.history.replaceState({}, '', url.toString());
};

const unpdateLocation = ({ tab }: { tab: AutomatedMessagingCategory }) => {
  const url = new URL(window.location.href);
  url.searchParams.set('tab', tab);
  window.history.replaceState({}, '', url.toString());
};

const tab = (
  label,
  tooltip,
  content,
  trackingDetails,
  disabledSettingsCount,
  isWebview,
): TabData => {
  return {
    label: (
      <>
        {label}
        {disabledSettingsCount > 0 && (
          <span data-test={`${label}-tab-badge`} style={{ marginLeft: 8 }}>
            <Badge text={disabledSettingsCount} />
          </span>
        )}
      </>
    ),
    content,
    trackingDetails,
    dataTest: `${label}-tab`,
    tooltip: !isWebview && tooltip,
  };
};

const disabledSettingsCountByCategory = (
  settings: Array<AutomatedMessagingSetting>,
  automatedMessagingState: AutomatedMessagingState,
  category: AutomatedMessagingCategory,
) =>
  settings
    .filter((setting) => setting.category === category)
    .filter((setting) => !automatedMessagingState[setting.name]).length;

const trackingDetails = (property: string): TrackingDetails => {
  return {
    category: trackCategory,
    action: ACTIONS.VIEW,
    label: 'tabs',
    property,
  };
};

interface Props {
  automatedMessagingState: AutomatedMessagingState;
  isWebview: boolean;
  visibleSettings: Array<AutomatedMessagingSetting>;
  actions: {
    setAutomatedMessagingSettingRequest: (payload: Partial<AutomatedMessagingSettings>) => void;
  };
}

export const AutomatedMessagingTabs = ({
  automatedMessagingState,
  isWebview,
  visibleSettings,
  actions: { setAutomatedMessagingSettingRequest },
}: Props) => {
  const tabContentByCategory = (category: AutomatedMessagingCategory) => {
    return visibleSettings
      .filter((setting) => setting.category === category)
      .map((setting) => {
        const settingProps: SettingProps = {
          ...setting,
          Analytics: Analytics(L2Page.AUTOMATED_MESSAGING),
          isSettingEnabled: automatedMessagingState[setting.name],
          isSettingLoading: automatedMessagingState.loaders[setting.name],
          shouldAskBeforeDisabling: false,
          callbacks: {
            enableSetting: () => {
              setAutomatedMessagingSettingRequest({
                [setting.name]: true,
              });
            },
            disableSetting: () => {
              setAutomatedMessagingSettingRequest({
                [setting.name]: false,
              });
            },
          },
        };

        if (setting.name === SETTING.SEND_HELPFUL_REMINDERS) {
          settingProps.AdvancedSettings = AdvancedReminderSettings;
          settingProps.defaultIsAdvancedSettingsVisible = getAdvancedSettingsVisibleFromURL();
          removeAdvancedSettingsVisibleFromUrl();
        }
        return (
          <div className={style.boxWrapper} key={setting.trackingId}>
            <SettingBox
              {...settingProps}
              dataTest={`${setting.trackingId}-automated-messaging-setting-box`}
            />
          </div>
        );
      });
  };

  const marketingContent = tabContentByCategory(AutomatedMessagingCategory.MARKETING);
  const marketingTab = tab(
    lang.marketing.label,
    lang.marketing.tooltip,
    marketingContent,
    trackingDetails('marketing-tab'),
    disabledSettingsCountByCategory(
      visibleSettings,
      automatedMessagingState,
      AutomatedMessagingCategory.MARKETING,
    ),
    isWebview,
  );

  const transactionalContent = tabContentByCategory(AutomatedMessagingCategory.TRANSACTIONAL);
  const transactionalTab = tab(
    lang.transactional.label,
    lang.transactional.tooltip,
    transactionalContent,
    trackingDetails('transactional-tab'),
    disabledSettingsCountByCategory(
      visibleSettings,
      automatedMessagingState,
      AutomatedMessagingCategory.TRANSACTIONAL,
    ),
    isWebview,
  );

  const tabs = [marketingTab, transactionalTab];

  const defaultTabIndex = {
    [AutomatedMessagingCategory.MARKETING]: 0,
    [AutomatedMessagingCategory.TRANSACTIONAL]: 1,
  }[getTabFromURL()];

  const handleOnChange = (index: number) => {
    const tab = {
      0: AutomatedMessagingCategory.MARKETING,
      1: AutomatedMessagingCategory.TRANSACTIONAL,
    }[index as 0 | 1];
    unpdateLocation({ tab });
  };

  return <Tabs tabs={tabs} defaultTabIndex={defaultTabIndex} onChange={handleOnChange} />;
};
