import React from 'react';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import classnames from 'classnames';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';

import { NavigationAnalytics, L2Page, mapPageToL2Enum, L1Page } from 'common/tracking/navigation';
import { Sidebar, SidebarLink } from 'components/common/Sidebar';
import {
  Discounts,
  MoneyPerson,
  Products,
  Scissors,
  Shipment,
  Suppliers,
} from 'components/common/Icon';

import { ServicesTab } from 'components/menu/ServicesTab';
import { PricingLevelsContainer } from 'components/menu/PricingLevelsContainer';
import DiscountsTab from 'components/menu/DiscountsTab/container';

import { ProductsTab } from 'components/menu/ProductsTab';
import ProductOrdersTab from 'components/menu/ProductOrdersTab/container';
import ProductSuppliersTab from 'components/menu/ProductSuppliers/ProductSuppliersTab/container';

import style from './MenuLayout.scss';

const PRODUCTS_PAGE = L1Page.PRODUCTS;

const SERVICES_TAB = L2Page.SERVICES;
const PRICING_LEVELS_TAB = L2Page.PRICING_LEVELS;
const DISCOUNTS_TAB = L2Page.DISCOUNTS;
const PRODUCTS_TAB = L2Page.PRODUCTS;
const PRODUCT_ORDERS_TAB = L2Page.PRODUCT_ORDERS;
const PRODUCT_SUPPLIERS_TAB = L2Page.PRODUCT_SUPPLIERS;

const DEFAULT_MENU_PAGE_TAB = SERVICES_TAB;
const DEFAULT_PRODUCTS_PAGE_TAB = PRODUCTS_TAB;
const VALID_TABS = [
  SERVICES_TAB,
  PRICING_LEVELS_TAB,
  DISCOUNTS_TAB,
  PRODUCTS_TAB,
  PRODUCT_SUPPLIERS_TAB,
  PRODUCT_ORDERS_TAB,
];

const LIVE_CHAT_SOURCE = 'menu sidebar';

const store = storeBuilder();

function getDefaultTab(page) {
  return page === PRODUCTS_PAGE ? DEFAULT_PRODUCTS_PAGE_TAB : DEFAULT_MENU_PAGE_TAB;
}

function getCurrentTab(path, page) {
  if (!path) {
    return getDefaultTab(page);
  }
  const pathInLower = path.toLowerCase();

  if (VALID_TABS.indexOf(pathInLower) === -1) {
    return getDefaultTab(page);
  }

  return pathInLower;
}

interface Props {
  currentTab: string;
  page: string;
  venueId: number;
  // remove this atrocities
  lang: any;
}

interface State {
  currentTab: string;
}

export class MenuLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const tab = getCurrentTab(this.props.currentTab, this.props.page);

    this.state = {
      currentTab: tab,
    };
  }

  private tabContent: any;

  private servicesTab: any;

  private pricingLevelsContainer: any;

  public componentDidMount() {
    this.changeHash(getCurrentTab(this.props.currentTab, this.props.page));
    NavigationAnalytics.trackPageView(mapPageToL2Enum(this.props.currentTab));
  }

  public componentDidUpdate(prevProps) {
    if (
      prevProps.currentTab !== this.props.currentTab &&
      prevProps.venueId !== this.props.venueId
    ) {
      const currentTab = getCurrentTab(prevProps.currentTab, this.props.page);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentTab });
      this.changeHash(currentTab);
    }
  }

  private isCurrentPage(page: string) {
    return this.state.currentTab === page;
  }

  private changeHash(path: string) {
    this.setState({
      currentTab: path,
    });
    const newHash = `venue/${this.props.venueId}/${path}`;
    window.location.hash = newHash;
  }

  private renderTab() {
    if (this.state.currentTab !== SERVICES_TAB && this.servicesTab) {
      this.servicesTab.removeAppEvents();
      this.servicesTab.undelegateEvents();
      this.servicesTab = null;
    }
    switch (this.state.currentTab) {
      case DISCOUNTS_TAB:
        return this.renderDiscounts();
      case PRICING_LEVELS_TAB:
        return this.renderPricingLevels();
      case PRODUCTS_TAB:
        return <ProductsTab />;
      case PRODUCT_ORDERS_TAB:
        return (
          <Provider store={store}>
            <ProductOrdersTab />
          </Provider>
        );
      case PRODUCT_SUPPLIERS_TAB:
        return this.renderProductSuppliers();
      case SERVICES_TAB:
      default:
        return this.renderServices();
    }
  }

  private renderDiscounts() {
    return (
      <Provider store={store}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'string'. */}
        <DiscountsTab venueId={this.props.venueId} />
      </Provider>
    );
  }

  private renderBackboneView() {
    if (!this.servicesTab) {
      this.servicesTab = new App.Views.Menu.ServicesTab({
        el: this.tabContent,
      });
    }

    this.servicesTab.render({
      el: this.tabContent,
      filters: App.mainViewOptions && App.mainViewOptions.initialFilter,
    });
  }

  private renderServices() {
    window.setTimeout(this.renderBackboneView.bind(this), 0);
    return <ServicesTab lang={this.props.lang} />;
  }

  private renderPricingLevelsView() {
    const el = this.tabContent;

    if (!this.pricingLevelsContainer) {
      const collection = new App.Collections.EmployeeCategories();
      collection.nameComparatorEnabled(false);
      collection.setVenueId(App.getVenueId());

      this.pricingLevelsContainer = new App.Views.Settings.EmployeeCategories({
        el,
        collection,
      });
    }

    this.pricingLevelsContainer.render({
      el,
    });
  }

  private renderPricingLevels() {
    window.setTimeout(this.renderPricingLevelsView.bind(this), 0);
    return (
      <PricingLevelsContainer
        onNewItemButtonClick={() => this.pricingLevelsContainer.showEmployeeFormDialog(null)}
      />
    );
  }

  private renderProductSuppliers() {
    return (
      <Provider store={store}>
        <ProductSuppliersTab />
      </Provider>
    );
  }

  private onSidebarClick = (tab: string) => {
    this.changeHash(tab);
    NavigationAnalytics.trackSidebarClick(mapPageToL2Enum(tab));
  };

  public render() {
    const { lang, page } = this.props;

    return (
      <div className={style.menu}>
        <Sidebar liveChatSource={LIVE_CHAT_SOURCE}>
          <>
            {page !== PRODUCTS_PAGE ? (
              <React.Fragment>
                <SidebarLink
                  dataTest={SERVICES_TAB}
                  icon={<Scissors />}
                  isSelected={this.isCurrentPage(SERVICES_TAB)}
                  onClick={() => this.onSidebarClick(SERVICES_TAB)}
                  text={lang.tabs.services}
                />
                <SidebarLink
                  dataTest={PRICING_LEVELS_TAB}
                  icon={<MoneyPerson />}
                  isSelected={this.isCurrentPage(PRICING_LEVELS_TAB)}
                  onClick={() => this.onSidebarClick(PRICING_LEVELS_TAB)}
                  text={Wahanda.lang.settings.employees.categories.title}
                />
                <SidebarLink
                  dataTest={DISCOUNTS_TAB}
                  icon={<Discounts />}
                  isSelected={this.isCurrentPage(DISCOUNTS_TAB)}
                  onClick={() => this.onSidebarClick(DISCOUNTS_TAB)}
                  text={lang.tabs.discounts}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <SidebarLink
                  dataTest={PRODUCTS_TAB}
                  icon={<Products />}
                  isSelected={this.isCurrentPage(PRODUCTS_TAB)}
                  onClick={() => this.onSidebarClick(PRODUCTS_TAB)}
                  text={lang.tabs.products}
                />
                <SidebarLink
                  dataTest={PRODUCT_SUPPLIERS_TAB}
                  icon={<Suppliers />}
                  isSelected={this.isCurrentPage(PRODUCT_SUPPLIERS_TAB)}
                  onClick={() => this.onSidebarClick(PRODUCT_SUPPLIERS_TAB)}
                  text={lang.tabs.productSuppliers}
                />
                <SidebarLink
                  dataTest={PRODUCT_ORDERS_TAB}
                  icon={<Shipment />}
                  isSelected={this.isCurrentPage(PRODUCT_ORDERS_TAB)}
                  onClick={() => this.onSidebarClick(PRODUCT_ORDERS_TAB)}
                  text={lang.tabs.orders}
                />
              </React.Fragment>
            )}
          </>
        </Sidebar>
        <div id={this.state.currentTab} className={style.contentWrapper}>
          <div
            className={classnames(style.content)}
            ref={(tabContent) => {
              this.tabContent = tabContent;
            }}
          >
            {this.renderTab()}
          </div>
        </div>
      </div>
    );
  }
}
