import { call, put } from 'redux-saga/effects';
import apiUrl from 'common/api-url';
import * as api from 'reduxStore/apiService';
import {
  requestInitStripeSuccess,
  requestConfirmStripePaymentSuccess,
  RequestInitStripeAction,
  DoStripePaymentRequestAction,
  DoIdealPaymentRequestAction,
  stripePaymentFailed,
  doIdealPaymentFailure,
} from './actions';

interface StripeSetupResponse {
  stripePublicApiKey: string;
}

interface StripeCheckoutSubmission {
  paymentIntentClientSecret: string;
  paymentIntentId: { id: string };
  supplierPaymentId: { id: number };
}

interface StripeIdealCheckoutSubmission {
  redirectUri: string;
  supplierPaymentId: {
    id: number;
  };
}

export function* postSetupStripe(params: RequestInitStripeAction) {
  const { supplierId } = params.payload;
  const url = `/api/supplier/${supplierId}/payment/initialise-stripe-checkout`;
  try {
    const data: StripeSetupResponse = yield call(api.post, url);
    const result = {
      stripePublicApiKey: data.stripePublicApiKey,
    };
    yield put(requestInitStripeSuccess(result));
  } catch (err) {
    console.log(err);
  }
}

export function* postDoIdealPayment(params: DoIdealPaymentRequestAction) {
  try {
    const { supplierId, paymentAmount } = params.payload;
    const idealSubmissionResponse: StripeIdealCheckoutSubmission = yield call(
      api.post,
      apiUrl('INITIALISE_STRIPE_IDEAL_CHECKOUT_SUBMISSION', {
        supplierId,
      }),
      {
        paymentAmount,
        callbackUri: window.location.href.replace(window.location.search, ''),
      },
    );
    window.location.assign(idealSubmissionResponse.redirectUri);
  } catch (err) {
    yield put(doIdealPaymentFailure());
  }
}

export function* postDoStripePayment(params: DoStripePaymentRequestAction) {
  const {
    supplierId,
    paymentAmount,
    stripe,
    cardElement,
    cardholderName,
    saveCardInfo,
  } = params.payload;
  let supplierPaymentId: number | null = null;
  let paymentIntentId: string | null = null;
  try {
    const initStripeSubmissionResponse: StripeCheckoutSubmission = yield call(
      api.post,
      `/api/supplier/${supplierId}/payment/initialise-stripe-checkout-submission`,
      { paymentAmount },
    );
    supplierPaymentId = initStripeSubmissionResponse.supplierPaymentId.id;
    paymentIntentId = initStripeSubmissionResponse.paymentIntentId.id;

    const stripeResponse = yield call(
      stripe.handleCardPayment,
      initStripeSubmissionResponse.paymentIntentClientSecret,
      cardElement,
      {
        save_payment_method: saveCardInfo,
        payment_method_data: {
          billing_details: { name: cardholderName },
        },
      },
    );

    if (stripeResponse.error) {
      throw { ...stripeResponse.error };
    }
  } catch {
    yield put(stripePaymentFailed());
  } finally {
    try {
      if (supplierPaymentId != null) {
        yield call(
          api.post,
          `/api/supplier/${supplierId}/payment/${supplierPaymentId}/complete-stripe-checkout`,
          { paymentIntentId },
        );
        yield put(requestConfirmStripePaymentSuccess());
      }
    } catch (err) {
      yield put(stripePaymentFailed());
    }
  }
}
