import React from 'react';
import { Treatments } from '../Treatments';
import { Treatment } from '../store/types';

import style from '../SelectServiceDialog.scss';

const filterForTreatmentTypeId = (id) => ({ treatmentGroupId }) => treatmentGroupId === id;
const reduceMenuItems = (array, { menuItems }) => array.concat(menuItems);
const createTreatmentsList = (treatmentGroupId, treatmentTypesList) =>
  treatmentTypesList.filter(filterForTreatmentTypeId(treatmentGroupId)).reduce(reduceMenuItems, []);

interface Props {
  list: Treatment[];
  onTreatmentClick: (...args: any[]) => void;
  value: string;
}

export const AllTreatmentTypes = ({ list, onTreatmentClick, value }: Props) => (
  <ul className={style.allTreatmentTypes}>
    {list.map(({ treatmentGroupId, treatmentTypeName }) => (
      <li key={treatmentGroupId} className={style.treatmentType}>
        <div className={style.treatmentTypeName}>{treatmentTypeName}</div>
        <Treatments
          list={createTreatmentsList(treatmentGroupId, list)}
          onTreatmentClick={onTreatmentClick}
          value={value}
        />
      </li>
    ))}
  </ul>
);
