export interface ChecklistResponse {
  checklists: Checklist[];
}

export interface Checklist {
  type: ChecklistType;
  tasks: ChecklistTask[];
  mandatory: boolean;
  tasksCompleted: boolean;
  /**
   * true when all tasks are completed and go live request is triggered
   */
  checklistCompleted: boolean;
}

export enum ChecklistType {
  SELF_SETUP = 'SELF_SETUP',
}

export interface ChecklistTask {
  item: ChecklistTaskItem;
  mandatory: boolean;
  status: ChecklistTaskStatus;
  statusDetail?: StatusDetail;
}

export type StatusDetail = {
  kycStatus?: KycStatusValue;
};

export enum KycStatusValue {
  STARTED = 'STARTED',
  PENDING = 'PENDING',
}

export enum ChecklistTaskItem {
  TEAM = 'TEAM',
  SERVICES = 'SERVICES',
  BANK_DETAILS = 'BANK_DETAILS',
  SALON_PHOTOS = 'SALON_PHOTOS',
  APPOINTMENTS = 'APPOINTMENTS',
  KYC = 'KYC',
  TAX_ID = 'TAX_ID',
}

export enum ChecklistTaskStatus {
  COMPLETED = 'COMPLETED',
  TODO = 'TODO',
}

export enum VenueStatusType {
  PENDING = 'P',
  ACTIVE = 'A',
  TEMPORARILY_DISABLED = 'T',
}
