import { connect } from 'react-redux';

import AmountSetter from './index';

const mapStateToProps = ({ productOrders }) => ({
  processingOrder: productOrders.processingOrder,
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ amount: null; processingOrder: boolean; }'... Remove this comment to see the full error message
export default connect(mapStateToProps, null)(AmountSetter);
