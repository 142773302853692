BackboneEx.Model.Base.Stats = BackboneEx.Model.Base.extend({

	url: function() {
		var url = this.urlRoot();
		url    += '?date=' + Wahanda.Date.formatDate("yy-mm-01", new Date());
		url    += '&limit=3';
		return App.Api.wsUrl(url);
	},

	_sortByTtv: function(items) {
		var newItems = (items || []).slice(0);
		newItems.sort(function(a, b) {
			var diff = getTtvDiff(a, b, 0);
			if (0 == diff) {
				diff = getTtvDiff(a, b, 1);
			}
			return diff;
		});
		return newItems;

		/**
		 * Helper fn to calculate sorting value for A and B items having TTV structure in them
		 * @return int
		 */
		function getTtvDiff(a, b, ttvIndex) {
			return getTtv(b, ttvIndex) - getTtv(a, ttvIndex);
		};
		function getTtv(obj, ttvIndex) {
			return obj.ttvs[ttvIndex] && obj.ttvs[ttvIndex].ttv ? obj.ttvs[ttvIndex].ttv.amount : 0;
		};
	}

});
