/* eslint-disable func-names */
/* global _ */
import { trackEvent } from 'common/analytics';
import get from 'lodash/get';

/**
 * Venue details.
 */
App.Views.Settings.SupplierBankDetails = App.Views.Form.extend({
  events: {
    'change #billing-entity-bank-details-bankAddressCountryCode': 'toggleStatesSelect',
  },

  fieldPrefix: 'billing-entity-',

  initialize: function () {
    const self = this;
    this.model.on('fetched', function () {
      self.render();
    });

    this.on('hidden', this.onHide, this);

    this.populated = false;
  },

  /**
   * Renders the whole settings view.
   */
  render: function () {
    // this.disableForm();
    if (!this.populated) {
      this.populated = true;
      this.populate();
      this.setValidation();
    }

    this.showPaymentTerms();
    this.fillFormFromModel();
    this.renderDirectDebit();

    this.enableForm();
    // Saving form state for changes checking
    this.initialFormState = this.getFormValues();

    this.toggleStatesSelect();

    // Rendering shadows
    this.$('.form-content').shadows();
  },

  fillFormFromModel: function () {
    const $elems = this.$(':input')
      .not('button')
      .not('input[type=button]')
      .not('input[type=submit]');
    const billingEntityData = this.model.get('defaultBillingEntity') || {};
    const bankAccountData = billingEntityData.bankAccount || {};

    const bankDetailsPrefix = 'bank-details-';
    const prefix = this.fieldPrefix;

    $elems.each(function () {
      const $elem = $(this);
      let name = $elem.attr('name')?.replace(prefix, '');
      let value;

      if (name?.match(`^${bankDetailsPrefix}`)) {
        name = name?.replace(bankDetailsPrefix, '');
        value = bankAccountData[name];
      } else {
        value = billingEntityData[name];
      }

      if (value != null && (!!value || parseInt(value, 10) === 0)) {
        $elem.val(value);
      } else {
        $elem.val('');
      }
    });
  },

  populate: function () {
    // Render countries
    let options = [];
    _.each(App.referenceData.get('country'), function (country) {
      options.push({
        value: country.id,
        title: country.title,
      });
    });
    this.$('#billing-entity-bank-details-bankAddressCountryCode').html(
      `<option value="">${
        Wahanda.lang.shared.selectInitialOption
      }</option>${Wahanda.Template.renderTemplate('form-select-items', {
        options: options,
      })}`,
    );
    // Render states
    options = [];
    _.each(App.referenceData.get('locationState'), function (state) {
      options.push({
        value: state.id,
        title: state.title,
      });
    });
    this.$('#billing-entity-bank-details-bankAddressStateCode').html(
      `<option value="">${
        Wahanda.lang.shared.selectInitialOption
      }</option>${Wahanda.Template.renderTemplate('form-select-items', {
        options: options,
      })}`,
    );
  },

  showPaymentTerms: function () {
    const lang = Wahanda.lang.settings.supplier.bank.intros;
    const template = this.isDirectDebitEnabled() ? lang.withDirectDebit : lang.noDirectDebit;

    this.$('.js-intro').html(
      Wahanda.Template.render(template, {
        threshold: App.config.getPaymentThresholdAmountFormatted(),
      }),
    );
  },

  isDirectDebitEnabled: function () {
    const billingEntityData = this.model.get('defaultBillingEntity') || {};
    const directDebit = (billingEntityData.bankAccount || {}).directDebit || {};

    return directDebit.enabled;
  },

  renderDirectDebit: function () {
    const $block = this.$('.js-direct-debit');

    if (this.isDirectDebitEnabled()) {
      const billingEntityData = this.model.get('defaultBillingEntity') || {};
      const directDebit = (billingEntityData.bankAccount || {}).directDebit || {};

      $block
        .html(
          Wahanda.Template.renderTemplate('bank-details-direct-debit-note', {
            mandateId: directDebit.mandateId,
          }),
        )
        .show();
    } else {
      $block.hide();
    }
  },

  save: function () {
    trackEvent('finance-bank-details', 'submit', 'save-bank-info');
    if (!this.isDirectDebitEnabled() || this.modelValuesHaventChanged()) {
      this.executeSave();
    } else {
      this.confirmSave();
    }
  },

  confirmSave: function () {
    App.ES6.Initializers.renderDirectDebitBankDetailsChangeConfirmation(
      this.$('.js-save-confirmation').get(0),
      {
        lang: Wahanda.lang.settings.supplier.bank.directDebit.confirmation,
        onConfirm: this.executeSave.bind(this),
      },
    );
  },

  executeSave: function () {
    const self = this;

    this.setModelValues();

    this.disableForm();
    const $saveButton = this.$('button.save-action').action('doing');
    const toSave = ['defaultBillingEntity'];

    this.model.setAttributesToSave(toSave);
    // Save the model and show actions to the user
    this.model.save(null, {
      success: function () {
        $saveButton.action();
        self.enableForm();
        self.initialFormState = self.getFormValues();
        App.trigger(Wahanda.Event.SETTINGS_BANK_DETAILS_SAVED);
        App.initVenue(null);
      },
      error: function (_, xhr) {
        self.enableForm();
        // error processing
        const errors = Wahanda.Util.parseErrors(xhr);
        if (!errors) {
          $saveButton.action().errorTip(Wahanda.lang.shared.savingFailed);
        } else {
          self.processError(errors[0].name);
          $saveButton.action();
        }
      },
    });
  },

  processError: function (error) {
    const lang = Wahanda.lang.settings.supplier.bank.errors;
    // Apply whatever styling to the error message retrieved.
    switch (error) {
      case 'invalid-bank-account-name':
        this.$('form').validate().showErrors({
          'billing-entity-bank-details-accountName': Wahanda.lang.validate.defaults.remote,
        });
        this.$('#billing-entity-bank-details-accountName').focus();
        break;

      case 'invalid-bank-account-number':
        this.$('form').validate().showErrors({
          'billing-entity-bank-details-accountNumber': lang.invalidBankAccountNumber,
        });
        this.$('#billing-entity-bank-details-accountNumber').focus();
        break;

      case 'invalid-bank-code':
        this.$('form').validate().showErrors({
          'billing-entity-bank-details-bankCode': lang.invalidBankCode,
        });
        this.$('#billing-entity-bank-details-bankCode').focus();
        break;

      case 'invalid-email':
        this.$('form').validate().showErrors({
          'billing-entity-emailAddress': Wahanda.lang.validate.defaults.email,
        });
        this.$('#billing-entity-emailAddress').focus();
        break;

      default:
        // Unknown code.
        this.$('button.save-action').action().errorTip(Wahanda.lang.shared.savingFailed);
        break;
    }
  },

  setModelValues: function () {
    const values = this.getFormValues();
    const bankAccountValues = {};
    const bankDetailsPrefix = 'bank-details-';
    const valueKeys = Object.keys(values);
    const billingEntityData = this.model.get('defaultBillingEntity') || {};
    const bankCountryCode = get(billingEntityData, 'bankAccount.bankAddressCountryCode');

    // eslint-disable-next-line guard-for-in, no-unused-vars, no-restricted-syntax
    for (const i in valueKeys) {
      const name = valueKeys[i];

      if (name.match(`^${bankDetailsPrefix}`)) {
        const filteredName = name.replace(bankDetailsPrefix, '');

        bankAccountValues[filteredName] = values[name];
        delete values[name];
      }
    }
    values.bankAccount = bankAccountValues;

    if (bankAccountValues.bankAddressCountryCode !== 'US') {
      bankAccountValues.bankAddressStateCode = null;
    }
    bankAccountValues.bankAddress = Wahanda.Text.trimAndCleanNewLines(
      bankAccountValues.bankAddress,
    );

    bankAccountValues.bankAddressCountryCode =
      (Boolean(bankCountryCode) && bankCountryCode) || App.config.get('venue').countryCode;

    this.model.set(
      'defaultBillingEntity',
      _.extend(this.model.get('defaultBillingEntity') || { id: null }, values),
    );
  },

  modelValuesHaventChanged: function () {
    const currentState = this.getFormValues();
    const change = _.find(this.initialFormState, function (value, name) {
      return value !== currentState[name];
    });
    // if no changes are found - `change` should be "undefined"
    return change == null;
  },

  setValidation: function () {
    const self = this;

    const $form = this.$('form');

    $form.on('invalid-form.validate', this.trackRequiredFieldErrors);

    const validations = Wahanda.Validate.getValidations('defaults', {
      submitHandler: function () {
        self.save();
      },
    });

    $form.validate(validations);
  },

  trackRequiredFieldErrors: function () {
    $(this)
      .find('.required')
      .each(function () {
        if (!$(this).val()) {
          const fieldName = this.id.split('-').pop();
          trackEvent('finance-bank-details', 'error', 'required', fieldName);
        }
      });
  },

  toggleStatesSelect: function () {
    const isUSA = this.$('#billing-entity-bank-details-bankAddressCountryCode').val() === 'US';
    this.$('.state-row').toggleClass('hidden', !isUSA);
  },

  onHide: function () {
    this.$('.b-payment-term').addClass('invisible');
  },
});
