import React from 'react';
import style from './ClientFormsOnboardingModal.scss';
import Wahanda from 'common/wahanda';

const lang = Wahanda.lang.marketing.clientForms;

export const steps: { description: string; imageSrc: string; imageAlt: string }[] = [
  {
    description: lang.onboardingModal.step1Description,
    imageSrc: '/assets/consultation-forms-onboarding/step1',
    imageAlt: lang.onboardingModal.step1Description,
  },
  {
    description: lang.onboardingModal.step2Description,
    imageSrc: '/assets/consultation-forms-onboarding/step2',
    imageAlt: lang.onboardingModal.step2Description,
  },
  {
    description: lang.onboardingModal.step3Description,
    imageSrc: '/assets/consultation-forms-onboarding/step3',
    imageAlt: lang.onboardingModal.step3Description,
  },
];

export const IntroStep = () => {
  return (
    <>
      <figure className={style.onboardingModalImage}>
        <picture>
          <source srcSet="/assets/consultation-forms-onboarding/step0.webp" type="image/webp" />
          <img
            src="/assets/consultation-forms-onboarding/step0.jpg"
            alt={lang.disableConfirmationModalTitle}
            className={style.onboardingImage}
          />
        </picture>
      </figure>
      <section className={style.onboardingModalBody}>
        <article className={style.onboardingModalBodyContent}>
          <span className={style.onboardingNewBadge}>New!</span>
          <h4>{lang.onboardingModal.introStepTitle}</h4>
          <p>{lang.onboardingModal.introStepDescription}</p>
        </article>
      </section>
    </>
  );
};

export const Step = ({ stepNumber, description, image, imageAlt }) => {
  return (
    <>
      <figure className={style.onboardingModalImage}>
        <picture>
          <source srcSet={`${image}.webp`} type="image/webp" />
          <img src={`${image}.jpg`} alt={imageAlt} className={style.onboardingImage} />
        </picture>
      </figure>
      <section className={style.onboardingModalBody}>
        <article className={style.onboardingModalBodyContent}>
          <h5
            data-test={`client-forms-onboarding-modal-step-${stepNumber}`}
          >{`Step ${stepNumber}`}</h5>
          <p>{description}</p>
        </article>
      </section>
    </>
  );
};

export const Steps = [
  <IntroStep key={0} />,
  ...steps.map((step, index) => (
    <Step
      key={index + 1}
      stepNumber={index + 1}
      image={step.imageSrc}
      imageAlt={step.imageAlt}
      description={step.description}
    />
  )),
];
