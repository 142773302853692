import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import * as pathToRegexp from 'path-to-regexp';
import classnames from 'classnames';
import { Badge } from 'components/common/Badge';
import ROUTES, {
  CLIENT_ROUTES,
  POS_REPORTS_ROUTES,
} from 'components/reports/newSalesReports/SalesReportContainer/reportRoutes';
import { NavigationAnalytics, L1Page } from 'common/tracking/navigation';
import Wahanda from 'common/wahanda';
import { CoronaReportModal } from '../CoronaReportModal';
import Card from './Card';
import ReportLink from './ReportLink';
import { ReportsDashboardAnalytics } from './tracking';
import style from './style.scss';

export interface Props {
  canViewVatSaleReport: boolean;
  canViewClientReports: boolean;
  canViewSaleReports: boolean;
  canViewPosReports: boolean;
  canViewBookingsReport: boolean;
  canViewTransactionsReport: boolean;
  canViewOtherReports: boolean;
  venueId: number;
}

interface State {
  coronaModalOpen: boolean;
}

const LANG = Wahanda.lang.reports;

export class ReportsDashboard extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.renderClientReports = this.renderClientReports.bind(this);
    this.renderOtherReports = this.renderOtherReports.bind(this);
    this.renderSalesReports = this.renderSalesReports.bind(this);
    this.renderPOSReports = this.renderPOSReports.bind(this);

    NavigationAnalytics.trackPageView(L1Page.REPORTS_DASHBOARD);
  }

  public state = {
    coronaModalOpen: false,
  };

  public render() {
    const {
      canViewClientReports,
      canViewOtherReports,
      canViewSaleReports,
      canViewPosReports,
    } = this.props;
    return (
      <div className={style.dashboard}>
        {this.renderCoronaModal()}
        <div className={style.content}>
          <h1 className={style.header}>{LANG.tabs.reports}</h1>
          <div className={style.reports}>
            {canViewSaleReports && this.renderSalesReports()}
            {canViewOtherReports && this.renderOtherReports()}
            {canViewClientReports && this.renderClientReports()}
            {canViewPosReports && this.renderPOSReports()}
          </div>
        </div>
      </div>
    );
  }

  private openCoronaModal = () => {
    ReportsDashboardAnalytics.trackCoronaReportClick();
    this.setState({ coronaModalOpen: true });
  };

  private closeCoronaModal = () => {
    this.setState({ coronaModalOpen: false });
  };

  private renderCoronaModal() {
    const { coronaModalOpen } = this.state;
    if (!coronaModalOpen) {
      return null;
    }
    return <CoronaReportModal onClose={this.closeCoronaModal} />;
  }

  private renderSalesReports() {
    const { venueId } = this.props;
    return (
      <Card className={classnames(style.card, style.salesReports)}>
        <div className={style.cardHead}>{LANG.tabs.sales}</div>
        <div className={style.explanation}>{LANG.explanations.salesReports}</div>
        <div className={style.box}>
          <Link
            onClick={ReportsDashboardAnalytics.trackSalesOverviewLinkClick}
            className={style.link}
            to={pathToRegexp.compile(ROUTES.SALES_REPORT)({ venueId })}
          >
            <ReportLink>{LANG.sales.reportType.salesReport}</ReportLink>
          </Link>
          <Link
            onClick={ReportsDashboardAnalytics.trackServiceSalesLinkClick}
            className={style.link}
            to={pathToRegexp.compile(ROUTES.SERVICE_SALES_REPORT)({ venueId })}
          >
            <ReportLink>{LANG.sales.reportType.serviceSalesReport}</ReportLink>
          </Link>
          <Link
            onClick={ReportsDashboardAnalytics.trackProductSalesLinkClick}
            className={style.link}
            to={pathToRegexp.compile(ROUTES.PRODUCT_SALES_REPORT)({ venueId })}
          >
            <ReportLink>{LANG.sales.reportType.productSalesReport}</ReportLink>
          </Link>
          <Link
            onClick={ReportsDashboardAnalytics.trackSalesByEmployeeLinkClick}
            className={style.link}
            to={pathToRegexp.compile(ROUTES.EMPLOYEE_SALES)({ venueId })}
          >
            <ReportLink>{LANG.sales.reportType.employeeSalesReport}</ReportLink>
          </Link>
          <Link
            onClick={ReportsDashboardAnalytics.trackSalesByChannelLinkClick}
            className={style.link}
            to={pathToRegexp.compile(ROUTES.CHANNEL_SALES)({ venueId })}
          >
            <ReportLink>{LANG.sales.reportType.channelSalesReport}</ReportLink>
          </Link>
          <Link
            onClick={ReportsDashboardAnalytics.trackDailySalesLinkClick}
            className={style.link}
            to={pathToRegexp.compile(ROUTES.DAILY_SALES)({ venueId })}
          >
            <ReportLink>{LANG.sales.reportType.dailySalesReport}</ReportLink>
          </Link>
          <Link
            onClick={ReportsDashboardAnalytics.trackMonthlySalesLinkClick}
            className={style.link}
            to={pathToRegexp.compile(ROUTES.MONTHLY_SALES_REPORTS)({ venueId })}
          >
            <ReportLink>{LANG.sales.reportType.monthlySalesReport}</ReportLink>
          </Link>
          <Link
            onClick={ReportsDashboardAnalytics.trackOldSalesReportLinkClick}
            className={style.link}
            to={`/venue/${venueId}/sales`}
          >
            <ReportLink>
              <div className={style.reportName}>
                {LANG.tabs.salesReports}
                <Badge type="muted" isLight className={style.newBadge} text={LANG.tabs.old} />
              </div>
            </ReportLink>
          </Link>
        </div>
      </Card>
    );
  }

  private renderOtherReports() {
    const { venueId, canViewBookingsReport, canViewTransactionsReport } = this.props;

    return (
      <Card className={classnames(style.card, style.otherReports)}>
        <div className={style.cardHead}>{Wahanda.lang.reports.tabs.lists}</div>
        <div className={style.explanation}>{LANG.explanations.otherReports}</div>
        <div className={style.box}>
          {canViewBookingsReport && (
            <Link
              onClick={ReportsDashboardAnalytics.trackListOfBookingsLinkClick}
              className={style.link}
              to={`/venue/${venueId}/bookings`}
            >
              <ReportLink>{LANG.tabs.bookingList}</ReportLink>
            </Link>
          )}
          {canViewTransactionsReport && (
            <Link
              data-test="transactions-list-link"
              onClick={ReportsDashboardAnalytics.trackTransactionReportLinkClick}
              className={style.link}
              to={`/venue/${venueId}/transactions`}
            >
              <ReportLink>{LANG.tabs.transactions}</ReportLink>
            </Link>
          )}
          {canViewBookingsReport && (
            <Link
              data-test="corona-virus-report"
              onClick={this.openCoronaModal}
              to=""
              className={style.link}
            >
              <ReportLink>
                <div className={style.reportName}>{LANG.sales.reportType.coronaVirusModal}</div>
              </ReportLink>
            </Link>
          )}
        </div>
      </Card>
    );
  }

  private renderPOSReports() {
    const { venueId, canViewVatSaleReport, canViewPosReports } = this.props;
    if (!(canViewVatSaleReport || canViewPosReports)) {
      return null;
    }

    return (
      <Card className={classnames(style.card, style.POSReports)}>
        <div className={style.cardHead}>{LANG.tabs.pos}</div>
        <div className={style.explanation}>{LANG.explanations.posReports}</div>
        <div className={style.box}>
          {canViewPosReports && (
            <React.Fragment>
              <Link
                onClick={ReportsDashboardAnalytics.trackMonthPosReportLinkClick}
                className={style.link}
                to={generatePath(POS_REPORTS_ROUTES.MONTH_POS_REPORT, {
                  venueId,
                })}
              >
                <ReportLink>
                  <div className={style.reportName}>{LANG.sales.reportType.posMonthly}</div>
                </ReportLink>
              </Link>
              <Link
                onClick={ReportsDashboardAnalytics.trackYearPosReportLinkClick}
                className={style.link}
                to={generatePath(POS_REPORTS_ROUTES.YEAR_POS_REPORT, {
                  venueId,
                })}
              >
                <ReportLink>
                  <div className={style.reportName}>{LANG.sales.reportType.posYearly}</div>
                </ReportLink>
              </Link>
            </React.Fragment>
          )}
          {canViewVatSaleReport && (
            <Link
              onClick={ReportsDashboardAnalytics.trackVatReportLinkClick}
              className={style.link}
              to={generatePath(POS_REPORTS_ROUTES.VAT_REPORT, { venueId })}
            >
              <ReportLink>{LANG.sales.reportType.vatReport}</ReportLink>
            </Link>
          )}
        </div>
      </Card>
    );
  }

  private renderClientReports() {
    const { venueId } = this.props;
    return (
      <Card className={classnames(style.card, style.clientReports)}>
        <div className={style.cardHead}>{LANG.tabs.clients}</div>
        <div className={style.explanation}>{LANG.explanations.clientReports}</div>
        <div className={style.box}>
          <Link
            className={style.link}
            to={pathToRegexp.compile(CLIENT_ROUTES.ACQUISITION_REPORT)({
              venueId,
            })}
          >
            <ReportLink>
              <div className={style.reportName}>{Wahanda.lang.reports.tabs.clientAcquisition}</div>
            </ReportLink>
          </Link>
          <Link
            onClick={ReportsDashboardAnalytics.trackClientRetentionLinkClick}
            className={style.link}
            to={pathToRegexp.compile(CLIENT_ROUTES.RETENTION_REPORT)({
              venueId,
            })}
          >
            <ReportLink>
              <div className={style.reportName}>
                {Wahanda.lang.reports.clientReports.clientRetention.reportName}
              </div>
            </ReportLink>
          </Link>
        </div>
      </Card>
    );
  }
}
