require('./tab-base.js');
App.Views.Forms.MenuOffer2.FinePrintTab = App.Views.Forms.MenuOffer2.TabBase.extend({
  tabName: Wahanda.lang.menu.offer.tabs.finePrint.title,
  tabClasses: 'offer2--fine-print',
  templateId: 'menu-offer2-fine-print-template',
  listeners: [],

  render: function () {
    /*
     * @see: https://wahanda.atlassian.net/browse/CD-18?focusedCommentId=390354
     * Currently BE limits is set to 2000 for both fields.
     */
    const MAX_RESTRICTION_CONTENT_LENGTH = 2000;
    const MAX_GOOD_TO_KNOW_CONTENT_LENGTH = 2000;
    var html = Wahanda.Template.renderTemplate(this.templateId);
    this.$el.empty().append(html);

    this.initialiseEditor(
      this.$('.restrictions'),
      Wahanda.lang.menu.offer.tabs.finePrint.restrictionEdit,
      Wahanda.lang.menu.offer.tabs.finePrint.restrictionEditTitle,
      this.model.get('restrictions'),
      MAX_RESTRICTION_CONTENT_LENGTH,
    );
    this.initialiseEditor(
      this.$('.good-to-know'),
      Wahanda.lang.menu.offer.tabs.finePrint.goodToKnowEdit,
      Wahanda.lang.menu.offer.tabs.finePrint.goodToKnowEditTitle,
      this.model.get('goodToKnow'),
      MAX_GOOD_TO_KNOW_CONTENT_LENGTH,
    );

    this.setChangeListeners();
  },

  setChangeListeners: function () {
    const createListener = (selector, cb) => {
      return this.$(selector).find('textarea').ckeditor().editor.on('change', cb);
    };

    this.listeners.push(
      createListener('.good-to-know', () =>
        App.trigger(Wahanda.Event.TRACK_SERVICE_EDIT, 'good-to-know'),
      ),
    );
    this.listeners.push(
      createListener('.restrictions', () =>
        App.trigger(Wahanda.Event.TRACK_SERVICE_EDIT, 'restrictions'),
      ),
    );
  },

  remove: function () {
    this.listeners.forEach(({ removeListener }) => removeListener());
  },

  getValues: function () {
    return {
      restrictions: this.$('.restrictions').find('textarea').val().trim(),
      goodToKnow: this.$('.good-to-know').find('textarea').val().trim(),
    };
  },

  initialiseEditor: function (element, editorName, editorTitle, editorContent, maxContentLength) {
    var editor = new App.Views.Forms.MenuOffer2.RichEditor({
      el: element,
      editorName: editorName,
      title: editorTitle,
      editorContent: editorContent,
      maxContentLength,
      readOnly: this.model.isReadOnly(),
    });
    editor.render();
  },
});
