/**
 * View mixin for binding to click on datepicker icon for the datepicker to show.
 */
BackboneEx.Mixin.View.Datepicker = {
	events: {
		"click .date-input": "toggleDatepicker"
	},
	
	toggleDatepicker: function(event) {
		if (event.target.nodeName === "INPUT") {
			return;
		}
		var $div   = $(event.currentTarget);
		var $input = $div.find('input:enabled');
		
		if ($input.length === 1) {
			if (this._isDatepickerVisibleForInput($input)) {
				$input.datepicker('hide');
			} else {
				$input.datepicker('show');
			}
		}
	},
	
	_isDatepickerVisibleForInput: function($input) {
		return $input.datepicker( "widget" ).is(":visible");
	}
};
