import React from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import { Currency, Percentage } from 'components/common/DataFormat';
import { Bubble } from 'components/dashboard/Bubble';
import { GoTo } from 'components/common/GoTo';
import { Notice } from 'components/common/Notice';
import { LinkWithArrow } from 'components/dashboard/LinkWithArrow';
import { MoneyCoins, Team, Repeat } from 'components/common/Icon';
import classnames from 'classnames';
import { VenueStatsAnalytics } from 'common/analytics';
import { REPORTS_ROUTES, routeFinder } from 'common/app-url';
import { CLIENT_ROUTES } from 'components/reports/newSalesReports/SalesReportContainer/reportRoutes';
import pathToRegexp from 'path-to-regexp';
import { BOOKING_ACTOR } from 'common/consts';
import style from './style.scss';
import TopReport from './TopReport';
import ColumnChart from './ColumnChart';

interface IVenueStatsProps extends React.HTMLAttributes<Element> {
  totalSales?: number;
  employees?: {
    name: string;
    grossTotal: number;
  }[];
  services?: {
    name: string;
    grossTotal: number;
    numberOfBookings: number;
  }[];
  salesByMonth?: {
    month: string;
    grossTotal: number;
  }[];
  venueId: number;
  actions: {
    requestSalesSummaryReportAction: (...args: any[]) => any;
    requestMonthlySalesReportAction: (...args: any[]) => any;
    requestRettentionReportDataAction: (...args: any[]) => any;
  };
  totalNumberOfBookings?: number;
  channelSales?: {
    type: string;
    amount: number;
  }[];
  acquiredClients?: number;
  grossTotal?: number;
  canViewMonthOverViewData: boolean;
  clientRetention?: number;
  requestRettentionReportDataAction?: any;
  requestMonthlySalesReportAction?: any;
  requestSalesSummaryReportAction?: any;
}
class VenueStats extends React.Component<IVenueStatsProps, {}> {
  static defaultProps = {
    acquiredClients: null,
    clientRetention: null,
    totalSales: null,
    employees: null,
    services: null,
    salesByMonth: null,
    totalNumberOfBookings: null,
    channelSales: null,
    grossTotal: null,
  };

  UNSAFE_componentWillMount() {
    const { venueId } = this.props;
    const dateFormat = 'YYYY-MM-DD';
    this.props.actions.requestSalesSummaryReportAction({
      venueId,
      from: moment().startOf('month').format(dateFormat),
      to: moment().format(dateFormat),
    });
    this.props.actions.requestMonthlySalesReportAction({
      venueId,
      from: moment().startOf('month').subtract(4, 'months').format(dateFormat),
      to: moment().format(dateFormat),
    });
    if (this.props.canViewMonthOverViewData) {
      this.props.actions.requestRettentionReportDataAction({
        venueId,
        fromDate: moment().startOf('month').format(dateFormat),
        toDate: moment().endOf('month').format(dateFormat),
      });
    }
  }

  renderTopEmployees() {
    const { employees } = this.props;
    if (!employees) {
      return null;
    }
    return (
      <TopReport
        reportHeader={
          <LinkWithArrow
            text={Wahanda.lang.dashboard.topEmployees.title}
            className={style.header}
            href={routeFinder(REPORTS_ROUTES.EMPLOYEE_SALES)}
            onClick={VenueStatsAnalytics.trackClickLinkTopEmployees}
          />
        }
        headers={[
          Wahanda.lang.dashboard.topEmployees.description,
          Wahanda.lang.dashboard.topEmployees.value,
        ]}
        data={employees.map((employee) => [
          employee.name,
          <Currency key={employee.name} className={style.bold} amount={employee.grossTotal} />,
        ])}
      />
    );
  }

  renderTopServices() {
    const { services } = this.props;
    if (!services) {
      return null;
    }
    return (
      <TopReport
        reportHeader={
          <LinkWithArrow
            text={Wahanda.lang.dashboard.topServices.title}
            className={style.header}
            href={routeFinder(REPORTS_ROUTES.SALES_REPORTS)}
            onClick={VenueStatsAnalytics.trackClickLinkTopServices}
          />
        }
        headers={[
          Wahanda.lang.dashboard.topServices.description,
          Wahanda.lang.dashboard.topServices.value,
        ]}
        data={services.map((service) => [
          service.name,
          <Currency key={service.name} className={style.bold} amount={service.grossTotal} />,
        ])}
      />
    );
  }

  renderSalesByChannel() {
    const { channelSales } = this.props;
    if (channelSales == null) {
      return null;
    }
    const data = channelSales.map((channelSale) => {
      let header;
      switch (channelSale.type) {
        case BOOKING_ACTOR.CUSTOMER:
          header = Wahanda.lang.dashboard.salesByChannel.channels.treatwell;
          break;
        case BOOKING_ACTOR.WIDGET:
          header = Wahanda.lang.dashboard.salesByChannel.channels.widget;
          break;
        case BOOKING_ACTOR.SUPPLIER:
          header = Wahanda.lang.dashboard.salesByChannel.channels.direct;
          break;
        default:
          break;
      }
      return [
        header,
        <Currency key="currency" className={style.bold} amount={channelSale.amount} />,
      ];
    });
    return (
      <TopReport
        className={style.salesByChannel}
        reportHeader={
          <LinkWithArrow
            text={Wahanda.lang.dashboard.salesByChannel.title}
            className={style.header}
            href={routeFinder(REPORTS_ROUTES.CHANNEL_SALES)}
            onClick={VenueStatsAnalytics.trackClickLinkSalesByChannel}
          />
        }
        headers={[
          Wahanda.lang.dashboard.salesByChannel.headers.description,
          Wahanda.lang.dashboard.salesByChannel.headers.amount,
        ]}
        data={data}
      />
    );
  }

  renderMessage() {
    const { grossTotal } = this.props;
    if (grossTotal === 0) {
      return (
        <Notice className={style.message} type="info">
          <p
            dangerouslySetInnerHTML={{
              __html: Wahanda.lang.reports.noDataMessage,
            }}
          />
        </Notice>
      );
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (grossTotal > 0) {
      return (
        <Notice className={style.message} type="expose">
          <p
            dangerouslySetInnerHTML={{
              __html: Wahanda.lang.reports.newReportsMessage,
            }}
          />
        </Notice>
      );
    }
    return null;
  }

  renderSales() {
    const { totalSales, salesByMonth, totalNumberOfBookings } = this.props;
    if ([totalSales, totalNumberOfBookings].some((amount) => amount == null)) {
      return null;
    }
    return (
      <div className={classnames(style.box, style.sales)}>
        <LinkWithArrow
          className={classnames(style.header, style.monthlyTotalSalesHeader)}
          text={Wahanda.lang.dashboard.monthlySales.title}
          href={routeFinder(REPORTS_ROUTES.MONTHLY)}
          onClick={VenueStatsAnalytics.trackClickLinkSalesThisMonth}
        />
        {salesByMonth && (
          <ColumnChart
            className={style.chart}
            headers={[
              Wahanda.lang.datetime.duration.year,
              Wahanda.lang.dashboard.monthlySales.earned,
            ]}
            data={salesByMonth.map((sale) => [
              Wahanda.Date.formatDate("M ''y", Wahanda.Date.createDate(sale.month)),
              sale.grossTotal,
            ])}
          />
        )}
      </div>
    );
  }

  renderOverview() {
    const { canViewMonthOverViewData } = this.props;
    if (!canViewMonthOverViewData) {
      return null;
    }
    const { totalSales, acquiredClients, clientRetention, venueId } = this.props;
    return (
      <div className={style.overview}>
        <div className={style.sectionHeader}>
          {Wahanda.lang.dashboard.overviewHeader}
          <div className={style.headerExplanationText}>{Wahanda.lang.dashboard.thisMonth}</div>
        </div>
        <div className={style.monthOverview}>
          <div className={classnames(style.monthOverviewCard, style.box)}>
            <Bubble className={style.moneyBubble}>
              <MoneyCoins />
            </Bubble>
            <p className={style.bigText}>{totalSales && <Currency amount={totalSales} />}</p>
            <p className={style.monthStatsText}> {Wahanda.lang.dashboard.totalSales} </p>
            <GoTo
              onClick={VenueStatsAnalytics.trackClickLinkTotalSales}
              href={routeFinder(REPORTS_ROUTES.MONTHLY)}
              noRouter
            >
              {Wahanda.lang.dashboard.viewReportLabel}
            </GoTo>
          </div>

          <div className={classnames(style.monthOverviewCard, style.box)}>
            <Bubble className={style.clientsBubble}>
              <Team />
            </Bubble>
            {acquiredClients != null && <p className={style.bigText}> {acquiredClients} </p>}
            <p className={style.monthStatsText}>{Wahanda.lang.dashboard.acquiredClients}</p>
            <GoTo
              onClick={VenueStatsAnalytics.trackClickLinkAcquiredClients}
              href={pathToRegexp.compile(`reports#${CLIENT_ROUTES.ACQUISITION_REPORT}`)({
                venueId,
              })}
              noRouter
            >
              {Wahanda.lang.dashboard.viewReportLabel}
            </GoTo>
          </div>

          <div className={classnames(style.monthOverviewCard, style.box)}>
            <Bubble className={style.rettentionBubble}>
              <Repeat />
            </Bubble>
            {clientRetention != null && (
              <p className={style.bigText}>
                <Percentage value={clientRetention} rounded />
              </p>
            )}
            <p className={style.monthStatsText}>{Wahanda.lang.dashboard.clientRetention}</p>
            <GoTo
              onClick={VenueStatsAnalytics.trackClickLinkClientRetention}
              href={pathToRegexp.compile(`reports#${CLIENT_ROUTES.RETENTION_REPORT}`)({ venueId })}
              noRouter
            >
              {Wahanda.lang.dashboard.viewReportLabel}
            </GoTo>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderOverview()}
        <div className={style.sectionHeader}>
          {Wahanda.lang.dashboard.salesOverviewHeader}
          <div className={style.headerExplanationText}>{Wahanda.lang.dashboard.thisMonth}</div>
        </div>
        {this.renderMessage()}
        <div className={style.reports}>
          {this.renderSales()}
          <div className={classnames(style.box, style.topReports)}>
            {this.renderTopServices()}
            {this.renderTopEmployees()}
            {this.renderSalesByChannel()}
          </div>
        </div>
      </div>
    );
  }
}

export default VenueStats;
