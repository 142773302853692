import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Question extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-question', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M12,2 C17.5228475,2 22,6.70101013 22,12.5 C22,18.2989899 17.5228475,23 12,23 C6.4771525,23 2,18.2989899 2,12.5 C2,6.70101013 6.4771525,2 12,2 Z M12,4.1 C7.581722,4.1 4,7.8608081 4,12.5 C4,17.1391919 7.581722,20.9 12,20.9 C16.418278,20.9 20,17.1391919 20,12.5 C20,7.8608081 16.418278,4.1 12,4.1 Z M12,16.175 C12.8284271,16.175 13.5,16.8801515 13.5,17.75 C13.5,18.6198485 12.8284271,19.325 12,19.325 C11.1715729,19.325 10.5,18.6198485 10.5,17.75 C10.5,16.8801515 11.1715729,16.175 12,16.175 Z M12,6.2 C14.3620342,6.2 16,7.34657607 16,9.875 C16,11.1787217 15.4898457,11.8631787 14.1401844,13.0441323 C13.2398457,13.8319287 13,14.1537217 13,14.6 L13,14.6 L11,14.6 C11,13.2962783 11.5101543,12.6118213 12.8598156,11.4308677 C13.7601543,10.6430713 14,10.3212783 14,9.875 C14,8.72842393 13.3879658,8.3 12,8.3 C11.1998159,8.3 10.6752849,8.55918002 10.3150767,8.99142984 C10.1053247,9.24313218 9.99729678,9.49061442 9.9701425,9.60466241 L9.9701425,9.60466241 L8.0298575,9.09533759 C8.12770322,8.68438558 8.36342526,8.14436782 8.80992331,7.60857016 C9.54346513,6.72831998 10.6126841,6.2 12,6.2 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Question);
