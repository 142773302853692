import React from 'react';
import Wahanda from 'common/wahanda';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';
import { LayoutContainer, LayoutGrid } from 'components/common/Layout';
import { FAQs, FAQsProps } from 'components/shared/FAQs';
import { Integration } from './Integration';

const lang = Wahanda.lang.marketing.facebookBusinessExtension.questions;

export const FacebookBusinessExtension: React.FC = () => {
  const list: FAQsProps = {
    header: lang.header,
    trackCategory: 'facebook-business-extension',
    questions: [
      {
        id: 'book-now',
        name: lang.bookNow,
        link: lang.bookNowLink,
      },
      {
        id: 'social-media-impact',
        name: lang.socialMediaImpact,
        link: lang.socialMediaImpactLink,
      },
    ],
  };

  React.useEffect(() => {
    NavigationAnalytics.trackPageEventView(L2Page.FACEBOOK);
  }, []);

  return (
    <LayoutContainer>
      <LayoutGrid>
        <Integration />
        <FAQs {...list} />
      </LayoutGrid>
    </LayoutContainer>
  );
};
