import React from 'react';
import classnames from 'classnames';
import { Dots } from 'components/common/Icon';

import style from './DialogFooterGroup.scss';

interface Props {
  title?: string;
  type: 'MENU_GROUP_BUTTON';
  children: React.ReactNode;
}

interface State {
  showingDropUpItems: boolean;
}

export class DialogFooterGroup extends React.Component<Props, State> {
  private wrapperRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      showingDropUpItems: false,
    };

    this.wrapperRef = React.createRef<HTMLDivElement>();
  }

  public static defaultProps = {
    type: 'MENU_GROUP_BUTTON',
  };

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  private getClasses() {
    const type = this.props.type;

    switch (type) {
      case 'MENU_GROUP_BUTTON':
        return classnames(style.left, style.menu);
      default:
        return null;
    }
  }

  private setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  private handleClickOutside = (event) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      this.state.showingDropUpItems
    ) {
      this.setState({ showingDropUpItems: false });
    }
  };

  private toggleGroup = () => {
    this.setState({ showingDropUpItems: !this.state.showingDropUpItems });
  };

  public render() {
    const { title, children } = this.props;

    if (children == null) {
      return null;
    }

    const decorator = title ? (
      <span className={style.title}>{title}</span>
    ) : (
      <Dots className={style.icon} />
    );

    return (
      <div
        className={classnames(style.container, this.getClasses())}
        ref={this.setWrapperRef}
        role="button"
        tabIndex={0}
        onClick={this.toggleGroup}
      >
        {decorator}
        {this.state.showingDropUpItems && <ul className={classnames(style.list)}>{children}</ul>}
      </div>
    );
  }
}
