import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';
import { allEmployeesOptionValue } from '../EmployeeFilter';
import { CHANNEL } from '../BookingChannelFilter';
import ROUTES from '../SalesReportContainer/reportRoutes';

interface IServiceSalesLinkProps extends React.HTMLAttributes<Element> {
  onClick: (...args: any[]) => any;
  fromDate: any;
  toDate: any;
  employeeFilter?: number;
  selectedChannel?: string;
  className?: string;
  match: {
    params: {
      venueId: string;
    };
  };
}
class ServiceSalesLink extends React.Component<IServiceSalesLinkProps, {}> {
  static defaultProps = {
    employeeFilter: allEmployeesOptionValue,
    selectedChannel: CHANNEL.ALL,
    className: null,
  };

  onClick = () => {
    this.props.onClick(
      this.props.fromDate,
      this.props.toDate,
      this.props.selectedChannel,
      this.props.employeeFilter,
    );
  };

  get toPath() {
    const {
      match: {
        params: { venueId },
      },
      fromDate,
      toDate,
      selectedChannel,
      employeeFilter,
    } = this.props;
    const toPath = pathToRegexp.compile(ROUTES.SERVICE_SALES_REPORT);
    return toPath({
      venueId,
      fromDate: fromDate.formatApiDateString(),
      toDate: toDate.formatApiDateString(),
      employeeFilter:
        selectedChannel != null && employeeFilter == null
          ? allEmployeesOptionValue
          : employeeFilter,
      selectedChannel,
    });
  }

  render() {
    return (
      <Link to={this.toPath} className={this.props.className} onClick={this.onClick}>
        {this.props.children}
      </Link>
    );
  }
}

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ employeeFilter: number; selectedChannel: s... Remove this comment to see the full error message
export default withRouter(ServiceSalesLink);
