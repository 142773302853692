/* eslint-disable func-names, no-restricted-globals, no-param-reassign */
/* global BackboneEx _ */

import uuid from 'uuid';
import { SelectOptionType } from 'components/common/__BaseCommon';
import { CalendarAnalytics } from 'components/calendar/tracking';
import { AvailabilityAnalytics } from 'components/calendar/CalendarEventEditor/ResourceCheckWarning/tracking';
import {
  AppointmentAnalytics,
  ServiceDurationLabel,
} from 'components/calendar/CalendarEventEditor/tracking';

(function () {
  const DEFAULT_DURATION = 60;

  App.Views.Forms.Appointment2.Item.Data = BackboneEx.View.Base.extend({
    events: {
      'change .js-date': 'onDateChange',
    },

    templateId: 'appointment2-form-item-row-template',

    setupElementFromTemplateHidden: false,
    formFieldPrefix: 'appt-',
    firstRender: true,
    currentDate: null,
    originalEndTime: null,

    newStartTime: null,
    newApplicationLength: null,
    newProcessingLength: null,
    newFinishingLength: null,
    newEmployeeId: null,
    maybeShowDurationDialog: false,
    uuid: null,
    closedAppointmentSlots: [],
    ResourceCheckWarning: null,
    shouldTrackAvailability: false,
    shouldTrackEditing: false,

    initialize: function () {
      // Bind to external UI change events
      this.listenTo(this.model, 'ui-change:offerId', this.onOfferChange);
      this.listenTo(this.model, 'ui-change:skus', this.onSkusChange);

      this.listenTo(this.model, 'reschedule', function (data) {
        this.move(data.date, data.diff);
      });

      App.on(
        Wahanda.Event.GET_APPOINTMENT_AVAILABILITY_RECEIVE,
        this.onGetAvailabilitySuccess.bind(this),
        this,
      );
    },

    render: function () {
      this.setupElementFromTemplate();

      this.fillFormFromModel(null, ['appointmentDate', 'startTime']);

      this.setupDurations();
      this.setupDatepickers();
      this.renderDate();
      this.renderStartTime();
      this.renderDuration();
      this.renderEmployees();

      this.onEndTimeFieldsChange();

      this.firstRender = false;
      this.saveApptTimeState();
      this.originalEndTime = this.model.getEndTimeMinutes(true);

      this.shouldTrackEditing = Boolean(this.model.id);

      return this;
    },

    onSaveClick: function () {
      if (!this.shouldTrackAvailability) {
        return;
      }

      AvailabilityAnalytics.trackSave(this.trackingId(), this.maybeGetCurrentClosedSlot());
    },

    trackingId: function () {
      return this.model.id || this.uuid;
    },

    trackingValue: function () {
      if (this.model.get('walkIn')) {
        return 'walkIn';
      }
      return this.model.get('bookingActor');
    },

    trackEditValues: function () {
      return {
        property: this.trackingValue(),
        value: this.trackingId(),
      };
    },

    onDismiss: function () {
      if (!this.shouldTrackAvailability) {
        return;
      }

      AvailabilityAnalytics.trackDismiss(this.trackingId(), this.maybeGetCurrentClosedSlot());
    },

    onGetAvailabilitySuccess: function (payload) {
      if (payload.uuid !== this.uuid) {
        return;
      }

      this.closedAppointmentSlots = payload.closedAppointmentSlots;

      this.renderStartTime(this.newStartTime);

      this.updateAvailabilityMessage();
    },

    updateAvailabilityMessage: function () {
      const closedSlot = this.maybeGetCurrentClosedSlot();

      if (this.ResourceCheckWarning) {
        this.ResourceCheckWarning.destroy();
      }

      if (!closedSlot) {
        return;
      }

      if (!this.shouldTrackAvailability) {
        // Track only once
        AvailabilityAnalytics.trackWarning(this.trackingId(), closedSlot);
      }

      this.shouldTrackAvailability = true;

      const props = {
        ...closedSlot,
        employee: {
          id: this.newEmployeeId,
          name: this.getEmployee(this.newEmployeeId).get('name'),
        },
      };

      this.ResourceCheckWarning = App.ES6.Initializers.ResourceCheckWarning({
        node: this.$('.js-resource-check-warning')[0],
        props,
      });

      this.ResourceCheckWarning.render();
    },

    setupDatepickers: function () {
      this.$('.datepicker').venueDatepicker({ newStyle: true });
    },

    setupDurations: function () {
      this.newApplicationLength = this.getApplicationLength();
      this.newProcessingLength = this.getProcessingLength();
      this.newFinishingLength = this.getFinishingLength();
      this.maybeShowDurationDialog =
        this.newProcessingLength != null || this.newFinishingLength != null;
    },

    renderDate: function (data) {
      let date = data;
      const $field = this.$('.js-date');
      if (!date && this.model.get('appointmentDate')) {
        date = Wahanda.Date.createDate(this.model.get('appointmentDate'));
      }
      if (this.options.textOnlyDate) {
        this.currentDate = date;
        $field.text(date ? Wahanda.Date.formatToDefaultDate(date) : '');
      } else if (date) {
        $field.val(Wahanda.Date.formatToDefaultDate(date));
      } else {
        $field.val('');
      }
    },

    getOffer: function () {
      const offerId = this.model.get('offerId');
      return this.options.offersCollection.get(offerId);
    },

    // Durations

    renderDuration: function () {
      const offer = this.getOffer();
      const isPackage = offer && offer.isServicePackage();
      this.$('.js-duration-block').wToggle(!isPackage);

      let duration;
      const skus = this.model.get('skus');
      // do not override duration from sku in case of single sku offer
      if (this.options.initialDuration) {
        // Take the duration passed in from the parent
        duration = this.options.initialDuration;
        this.options.initialDuration = null;
      } else if (
        this.model.get('id') &&
        !this.model.hasChanged('offerId') &&
        !this.model.hasChanged('skus')
      ) {
        // Only take the duration from the model if the offerId, or sku has not changed.
        duration = this.model.getDuration();
      } else if (skus && skus.length && offer && offer.getSkuCount() > 1) {
        try {
          duration = _.reduce(
            skus,
            function (prevValue, apptSku) {
              const sku = offer.getSku(apptSku.skuId);
              return prevValue + parseInt(sku.get('duration'), 10);
            },
            0,
          );
        } catch (e) {
          // DEV-28308: some Offers might have their SKUs changed. In this case take the duration
          // from appointment directly, don't calculate it from SKUs.
          duration = this.model.getDuration();
        }
      } else if (offer) {
        // Get first sku's duration
        duration =
          offer.getFirstSkuDuration(this.options.offersCollection, false) || DEFAULT_DURATION;
      } else {
        // Default
        duration = DEFAULT_DURATION;
      }

      this.$('.js-udv-durations-selector, .js-duration').hide();

      // Render the times in case cleanup time changes exist
      if (this.model.get('cleanupEndTime') != null) {
        this.cleanupTime = this.model.getAppointmentCleanupTimeLength();
      } else {
        this.cleanupTime = offer && offer.get('cleanupTime');
      }
      if (offer && this.model.hasChanged('offerId')) {
        this.cleanupTime = offer && offer.get('cleanupTime');
      }

      if (this.maybeShowDurationDialog) {
        this.renderDurationDialog(duration);
      } else {
        this.renderDurationSelects(duration);
      }
    },

    /**
     * Renders the durations select.
     *
     * @private
     */
    renderDurationSelects: function (selectedDuration) {
      // Durations must be rendered with added cleanup time *to display value only*
      const options = { minMinutes: 5 };
      const dataTest = 'udv-duration-select';

      if (this.cleanupTime > 0) {
        options.titleIncrement = this.cleanupTime;
      }
      const durations = Wahanda.Time.getDurationValues(
        null,
        Wahanda.Time.getTimeDurationTemplate(),
        true,
        options,
      );

      let selectedOption = _.find(durations, function (item) {
        return item.minutes >= selectedDuration;
      });

      if (!selectedOption) {
        selectedOption = _.last(durations);
      }

      this.newDuration = selectedOption.minutes;
      const $node = this.$('.js-duration');
      $node.show();

      this.renderDropdownAtNode(
        $node[0],
        {
          data: _.map(durations, function (item) {
            return {
              name: item.title,
              value: item.minutes,
            };
          }),
          selected: selectedOption.minutes,
          onSelect: this.onDurationChange.bind(this),
          disabled: !this.model.canUserEditAppointment(),
        },
        null,
        dataTest,
      );
    },

    renderDurationDialog: function (selectedDuration) {
      const $node = this.$('.js-udv-durations-selector');
      $node.show();

      this.newDuration = selectedDuration;
      this.newApplicationLength = selectedDuration;

      this.renderDurations();
    },

    triggerDurationsChangeEvents: function (label) {
      this.onEndTimeFieldsChange();
      this.recalculateAvailability();
      if (this.shouldTrackEditing) {
        AppointmentAnalytics.trackAppointmentDurationEdit({ ...this.trackEditValues(), label });
      }
    },

    renderDurations: function () {
      const self = this;
      const isCheckedOut = this.model.id && this.model.isReadOnly();
      const skuId = this.model.get('durationSkuId') || 'not_selected';

      App.ES6.Initializers.DurationSelector({
        node: this.$('.js-udv-durations-selector')[0],
        key: `skuid_${skuId}`,
        canUserEditAppointment: !(!this.model.canUserEditAppointment() || isCheckedOut),
        applicationTime: this.newApplicationLength,
        finishingTime: this.newFinishingLength,
        processingTime: this.newProcessingLength,
        onApplicationTimeChange: (data) => {
          self.newApplicationLength = data;
          self.newDuration = data;
          self.triggerDurationsChangeEvents(ServiceDurationLabel.Application);
        },
        onProcessingTimeChange: (data) => {
          self.newProcessingLength = data;
          self.triggerDurationsChangeEvents(ServiceDurationLabel.Processing);
        },
        onFinishingTimeChange: (data) => {
          self.newFinishingLength = data;
          self.triggerDurationsChangeEvents(ServiceDurationLabel.Finishing);
        },
        tracking: {
          trackDurationInputClick: CalendarAnalytics.trackUDVServiceDurationInputClick,
          trackDialogCloseClick: CalendarAnalytics.trackUDVServiceDurationDialogCloseClick,
          trackSelectChange: CalendarAnalytics.trackUDVServiceDurationSelectChange,
        },
      }).render();
    },

    getApplicationLength: function () {
      const offer = this.getOffer();
      const skuId = this.model.get('skuId');
      const sku = offer && offer.getSku(skuId);

      if (this.options.initialDuration) {
        return this.options.initialDuration;
      }
      if (
        this.model.get('id') &&
        !this.model.hasChanged('offerId') &&
        !this.model.hasChanged('skus')
      ) {
        return (
          Wahanda.Time.timeToMinutes(this.model.get('endTime')) -
          Wahanda.Time.timeToMinutes(this.model.get('startTime'))
        );
      }
      if (!offer) {
        return DEFAULT_DURATION;
      }

      return sku && sku.get('duration');
    },

    getProcessingLength: function () {
      const offer = this.getOffer();
      let treatment;
      let hasLength;

      if (!offer) {
        return null;
      }

      if (!offer.get('processingTimeMins')) {
        hasLength = false;
      } else {
        treatment = this.options.menuTreatments.getTreatment(offer.get('primaryTreatmentId'));
        hasLength = treatment && treatment.get('allowProcessingTime');
      }

      if (
        hasLength &&
        this.model.id > 0 &&
        !this.model.hasChanged('offerId') &&
        !this.model.hasChanged('skus') &&
        offer.id === this.model.get('offerId')
      ) {
        return this.model.getProcessingTimeLength();
      }
      return hasLength ? offer.get('processingTimeMins') : null;
    },

    /**
     * Check if finishing time needs rendering.
     */
    getFinishingLength: function () {
      const offer = this.getOffer();
      const skuId = this.model.get('skuId');
      const skus = this.model.get('skus');
      let time;

      if (!offer) {
        return null;
      }

      const hasInitialValues =
        this.model.get('id') &&
        !this.model.hasChanged('offerId') &&
        !this.model.hasChanged('skus') &&
        this.model.getFinishingEndTimeDate() != null;
      const hasMultipleSku = skus && skus.length && offer && offer.getSkuCount() > 1;
      const hasSkuId = skuId && offer && offer.getSku(skuId);

      if (hasInitialValues) {
        time = this.model.getFinishingTimeLength() - this.model.getProcessingTimeLength();
      } else if (hasMultipleSku) {
        time = _.reduce(
          skus,
          function (prevValue, apptSku) {
            const sku = offer.getSku(apptSku.skuId);
            if (!sku) {
              return null;
            }
            return prevValue + parseInt(sku.get('finishingTimeMins'), 10);
          },
          0,
        );
      } else if (hasSkuId) {
        time = offer && offer.getSku(skuId).get('finishingTimeMins');
      } else {
        // get first sku finishing time
        time = offer.get('skus')[0].finishingTimeMins;
      }

      return time >= 0 ? time : null;
    },

    getDialogTemplateVariables: function () {
      return {
        uniq: this.cid,
        textOnlyDate: this.options.textOnlyDate,
        title: this.options.title,
      };
    },

    getEmployeesToRender: function () {
      const selectedEmployeeId = this.model.get('employeeSelected')
        ? this.model.previous('employeeId')
        : null;
      const selectOptions = {
        empty: this.options.employees.length === 0,
        title: function (employee) {
          let name = employee.get('name');
          if (selectedEmployeeId && selectedEmployeeId === employee.id) {
            name += ` (${Wahanda.lang.calendar.appointments.employees.requested})`;
          }
          return name;
        },
      };

      if (!Wahanda.Permissions.editAnyCalendar()) {
        let leaveEmployee = null;
        if (Wahanda.Permissions.editOwnCalendar()) {
          leaveEmployee = this.model.get('employeeId');
        }
        // If the user can edit his own calendar data, allow selecting himself as the employee assigned
        // to this appointment.
        selectOptions.skipEmployees = this.options.employees.map(function (employee) {
          return leaveEmployee === employee.id ? null : employee.id;
        });
      }

      const list = this.options.employees.toSimpleList(selectOptions);

      const employeeId = parseInt(selectedEmployeeId || this.model.get('employeeId'), 10);
      // Make sure we have the employee in the list. If not, take the first one.
      const selected =
        employeeId > 0 && list.find((item) => item.value === employeeId)
          ? employeeId
          : ((list && list[0]) || {}).value;

      return { list, selected };
    },

    renderEmployees: function () {
      const data = this.getEmployeesToRender();
      const $node = this.$('.js-employeeId');
      const dataTest = 'udv-employee-select';

      this.newEmployeeId = data.selected;

      this.renderDropdownAtNode(
        $node[0],
        {
          data: _.map(data.list, function (item) {
            return {
              name: item.title,
              value: item.value,
            };
          }),
          selected: data.selected,
          onSelect: this.onEmployeeChange.bind(this),
          disabled: !this.model.canUserEditAppointment(),
        },
        null,
        dataTest,
      );
    },

    /**
     * @return { startTime, endTime, duration }
     */
    getAppointmentTimes: function (withAdditionalTime) {
      const startTime = Wahanda.Time.timeToMinutes(
        this.newStartTime || this.model.get('startTime'),
      );
      let duration = this.newDuration;

      if (!duration) {
        // When adding a new appointment after an non-saved Package, this View does not have a duration
        // dropdown. We need to compensate for it.
        duration = this.getDurationFromOffer(true);
      }

      let additionalTime = 0;
      if (withAdditionalTime) {
        const sum = this.newProcessingLength + this.newFinishingLength + this.cleanupTime;
        additionalTime = sum || 0;
      }
      const endTime = startTime + duration + additionalTime;

      return {
        startTime,
        endTime,
        duration,
        finishingTime: this.newFinishingLength,
        processingTime: this.newProcessingLength,
      };
    },

    getDurationFromOffer: function (includeAdditionalTime) {
      const offer = this.getOffer();
      const skuDuration =
        offer && offer.getFirstSkuDuration(this.options.offersCollection, includeAdditionalTime);
      return skuDuration || 0;
    },

    getAppointmentDate: function (asString) {
      let date;
      if (this.options.textOnlyDate) {
        date = this.currentDate;
      } else {
        date = this.$('.js-date').datepicker('getDate');
      }
      if (asString) {
        date = date ? Wahanda.Date.toApiString(date) : this.model.get('appointmentDate');
      }
      return date || Wahanda.Date.getMidnight(new Date(this.model.get('appointmentDate')));
    },

    getStartTimeSelectValues: function (forcedDate) {
      return App.Models.WorkingHours.getVisibleCalendarTimeChoicesForDate(
        forcedDate || this.getAppointmentDate(),
      );
    },

    renderStartTime: function (newTime) {
      const times = this.getStartTimeSelectValues();
      const self = this;
      const dataTest = 'udv-start-time-select';

      this.newStartTime = newTime !== undefined ? newTime : this.model.get('startTime');
      this.model.set('startTime', this.newStartTime);

      if (!this.model.startTimeMatchesGrid()) {
        const desiredTime = Wahanda.Time.timeToMinutes(this.newStartTime);
        // Insert time, as it does not match the grid
        const pos = _.findIndex(times, function (item) {
          return desiredTime < item.minutes;
        });

        times.splice(pos, 0, {
          minutes: desiredTime,
          value: this.newStartTime,
          title: Wahanda.Time.toDefaultTime(desiredTime),
        });
      }

      this.renderDropdownAtNode(
        this.$('.js-startTime')[0],
        {
          data: _.map(times, function (item) {
            return {
              name: item.title,
              value: item.value,
              type: self.isStartTimeBusy(item.value) ? SelectOptionType.WARNING : undefined,
            };
          }),
          selected: this.newStartTime,
          onSelect: this.onStartTimeChange.bind(this),
          disabled: !this.model.canBeRescheduled(),
        },
        null,
        dataTest,
      );
    },

    maybeGetCurrentClosedSlot: function () {
      return this.closedAppointmentSlots.find(({ startTime }) => startTime === this.newStartTime);
    },

    isStartTimeBusy: function (value) {
      return this.closedAppointmentSlots.some(({ startTime }) => startTime === value);
    },

    saveApptTimeState: function () {
      this.lastAppointmentState = _.extend(this.getAppointmentTimes(true), {
        date: this.getAppointmentDate(),
      });
    },

    colorNotifyChange: function () {
      this.trigger('auto-rescheduled');
    },

    /**
     * Reschedule the View to new date and time.
     *
     * @param Date date
     * @param int timeDiff
     */
    move: function (date, timeDiff) {
      this.renderDate(date);
      this.currentDate = date;

      const newTime = Wahanda.Time.toApiString(
        Wahanda.Time.timeToMinutes(this.newStartTime) + timeDiff,
      );
      this.renderStartTime(newTime);

      this.preventRescheduleNotification = true;
      this.onStartTimeChange(newTime);
      this.preventRescheduleNotification = false;

      this.colorNotifyChange();

      this.saveApptTimeState();
    },

    getEmployee: function () {
      return this.options.employees.get(this.newEmployeeId);
    },

    getValues: function () {
      const self = this;
      const times = this.getAppointmentTimes(false);
      const date = this.getAppointmentDate(true);
      const values = BackboneEx.Mixin.View.Form.implementation.getValues.call(this);

      _.extend(values, {
        appointmentDate: date,
        startTime: Wahanda.Time.toApiString(times.startTime),
        endTime: Wahanda.Time.toApiString(times.endTime),
        cleanupEndTime: this.cleanupTime || this.newProcessingLength ? getCleanupEndTime() : null,
        finishingEndTime: this.newFinishingLength === null ? null : getFinishingEndTime(),
        processingEndTime: this.newProcessingLength === null ? null : getProcessingEndTime(),
        employeeId: this.newEmployeeId,
      });

      delete values.processingTimeMins;

      if (!this.model.id) {
        values.bookingActor = this.model.CHANNEL_LOCAL;
        values.platform = Wahanda.platform;
      }

      return values;

      function getProcessingEndTime() {
        let time = self.newProcessingLength;
        if (isNaN(time) || !time) {
          time = 0;
        }
        const endDate = Wahanda.Date.addMinutesToDate(
          Wahanda.Date.createDate(date, Wahanda.Time.toApiString(times.endTime)),
          time,
        );
        return Wahanda.Time.getDateApiTime(endDate);
      }

      function getFinishingEndTime() {
        let time = self.newProcessingLength + self.newFinishingLength;
        const endTime = times.endTime;
        if (isNaN(time) || !time) {
          time = 0;
        }
        const endDate = Wahanda.Date.addMinutesToDate(
          Wahanda.Date.createDate(date, Wahanda.Time.toApiString(endTime)),
          time,
        );
        return Wahanda.Time.getDateApiTime(endDate);
      }

      function getCleanupEndTime() {
        const time = self.cleanupTime + self.newProcessingLength + self.newFinishingLength;
        const endDate = Wahanda.Date.addMinutesToDate(
          Wahanda.Date.createDate(date, Wahanda.Time.toApiString(times.endTime)),
          time,
        );
        return Wahanda.Time.getDateApiTime(endDate);
      }
    },

    renderDropdownAtNode: function (node, data, typeahead, dataTest) {
      $(node).addClass('is-react');

      data.disabled = data.disabled || (this.model.id && this.model.isReadOnly());
      data.onStateChange = this.options.toggleDialogClosing;

      const oldSelect = data.onSelect;
      data.onSelect = function (...args) {
        oldSelect.apply(this, args);
        this.trigger('selectdropdown-change');
      }.bind(this);

      App.ES6.Initializers.Select({
        ...data,
        node,
        dataTest,
        typeahead,
      }).render();
    },

    remove: function () {
      this.$('.is-react').each(function () {
        App.ES6.ReactDOM.unmountComponentAtNode(this);
      });
      BackboneEx.View.Base.prototype.remove.call(this);
      App.off(Wahanda.Event.GET_APPOINTMENT_AVAILABILITY_RECEIVE, null, this);
    },

    customValidationsPass: function () {
      return this.checkEndTime();
    },

    checkEndTime: function () {
      const MAX_TIME = 24 * 60 - 1;
      const times = this.getAppointmentTimes(true);
      const isOK = times.endTime <= MAX_TIME;
      const $node = this.maybeShowDurationDialog
        ? this.$('.js-udv-durations-selector')
        : this.$('.js-duration');

      if (!isOK) {
        $node.formCustomElementErrorTip(
          Wahanda.lang.calendar.appointments.errors.timeOverrunIntoNextDay,
        );
      }

      return isOK;
    },

    // UI Events

    onDateChange: function () {
      this.renderStartTime(this.newStartTime);
      this.onStartTimeChange(this.newStartTime);
      this.recalculateAvailability();

      if (this.shouldTrackAvailability) {
        AvailabilityAnalytics.trackDateChange(this.trackingId());
      }
      if (this.shouldTrackEditing) {
        AppointmentAnalytics.trackAppointmentDateEdit(this.trackEditValues());
      }
    },

    onStartTimeChange: function (value, trackChange) {
      // Constrain duration to not allow day overlapping
      this.newStartTime = value;

      const maxMinutes = 24 * 60 - 1;
      const startMinutes = Wahanda.Time.timeToMinutes(this.newStartTime);
      const maxDuration = maxMinutes - startMinutes;

      const $select = this.$('.js-duration');
      const duration = parseInt($select.val(), 10);
      const currentDuration = duration;
      const $options = $select.children();

      $options.prop('disabled', false);

      let $lastOption = null;
      /* eslint-disable-next-line consistent-return */
      $options.each(function () {
        const $option = $(this);
        const theDuration = parseInt($option.val(), 10);
        if (theDuration > maxDuration) {
          return false;
        }
        $lastOption = $option;
      });
      if ($lastOption) {
        // Disable all next options
        $lastOption.nextAll().prop('disabled', true);
      }
      if (currentDuration > maxDuration) {
        if ($lastOption) {
          $select.val($lastOption.val());
        } else {
          $select.val($options.first().val());
        }
      }
      // Recalculate the time
      this.onEndTimeFieldsChange();

      if (this.shouldTrackAvailability) {
        AvailabilityAnalytics.trackStartTimeChange(this.trackingId());
      }
      this.updateAvailabilityMessage();

      if (this.shouldTrackEditing && trackChange === '') {
        AppointmentAnalytics.trackAppointmentStartTimeEdit(this.trackEditValues());
      }
    },

    onDurationChange: function (value) {
      this.newDuration = value;
      this.onEndTimeFieldsChange();
      this.recalculateAvailability();
      if (this.shouldTrackAvailability) {
        AvailabilityAnalytics.trackDurationChange(this.trackingId());
      }
      if (this.shouldTrackEditing) {
        AppointmentAnalytics.trackAppointmentDurationEdit(this.trackEditValues());
      }
    },

    onEndTimeFieldsChange: function () {
      const time = this.getAppointmentTimes(true);
      this.$('.js-end-time').text(Wahanda.Time.toDefaultTime(time.endTime));

      this.updateModelDateTime();
      this.notifyReschedule();
    },

    recalculateAvailability() {
      const values = this.getValues();
      const offer = this.getOffer();
      const { startTime, endTime, processingTime, finishingTime } = this.getAppointmentTimes(true);
      this.uuid = uuid.v4();
      const payload = {
        uuid: this.uuid,
        employeeId: values.employeeId,
        serviceId: offer && offer.get('id'),
        date: values.appointmentDate,
        duration: endTime - startTime,
        appointmentId: this.model.id,
        processingTime,
        finishingTime,
      };

      if (!payload.serviceId) {
        return;
      }
      App.trigger(Wahanda.Event.GET_APPOINTMENT_AVAILABILITY_REQUEST, payload);
    },

    updateModelDateTime: function () {
      this.model.set(
        _.pick(
          this.getValues(),
          'appointmentDate',
          'startTime',
          'endTime',
          'employeeId',
          'cleanupEndTime',
          'processingEndTime',
          'finishingEndTime',
        ),
      );
    },

    notifyReschedule: function () {
      // If diff === 0, don't notify of anything.
      if (this.firstRender || this.preventRescheduleNotification) {
        return;
      }

      const newDate = this.getAppointmentDate();
      const times = this.getAppointmentTimes(true);

      if (
        Wahanda.Date.isEqualDates(this.lastAppointmentState.date, newDate) &&
        times.endTime === this.lastAppointmentState.endTime
      ) {
        // The end time didn't change
        return;
      }

      this.trigger('rescheduled', {
        startTime: times.startTime,
        prevStartTime: this.lastAppointmentState.startTime,
        endTime: times.endTime,
        prevEndTime: this.lastAppointmentState.endTime,
        timeDiff: times.endTime - this.lastAppointmentState.endTime,
        newDate: newDate,
        view: this,
      });

      // Lastly, save the new appt state
      this.saveApptTimeState();
    },

    onEmployeeChange: function (value) {
      this.newEmployeeId = value;
      this.recalculateAvailability();
      this.trigger('employee-change', this);

      if (this.shouldTrackAvailability) {
        AvailabilityAnalytics.trackEmployeeChange(this.trackingId());
      }
      if (this.shouldTrackEditing) {
        AppointmentAnalytics.trackAppointmentEmployeeEdit(this.trackEditValues());
      }
    },

    // External UI events

    onOfferChange: function () {
      this.setupDurations();
      this.renderDuration();
      this.onStartTimeChange(this.newStartTime);
      this.recalculateAvailability();

      if (this.shouldTrackEditing) {
        AppointmentAnalytics.trackAppointmentServiceEdit(this.trackEditValues());
      }
    },

    onSkusChange: function () {
      this.setupDurations();
      this.renderDuration();
      this.onStartTimeChange(this.newStartTime);
      this.recalculateAvailability();
    },
  });

  // Mixin some functionality
  BackboneEx.Mixin.View.Form.mixin(App.Views.Forms.Appointment2.Item.Data);
})();
