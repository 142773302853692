import { createSelector } from 'reselect';
import find from 'lodash/find';

export const getEmployees = ({ employees }) => employees;

export const getEmployeesWorkingHours = createSelector(
  [getEmployees],
  (employees) => employees.employeesWorkingHours,
);

export const getShiftEmployee = createSelector(
  [getEmployeesWorkingHours, (state, props) => props.employeeId],
  (employeesWorkingHours, employeeId) => find(employeesWorkingHours, { employeeId }),
);

export const getEmployeeWorkingHours = createSelector(
  [getShiftEmployee, (state, { employeeId }) => ({ employeeId })],
  (employee) => employee.workingHours,
);

export const getEmployeeDayWorkingHours = createSelector(
  [getEmployeeWorkingHours, (state, { employeeId, date }) => ({ employeeId, date })],
  (workingHours, { date }) => find(workingHours, { date }),
);

export const getScheduleId = createSelector(
  [getEmployeeDayWorkingHours, (state, { employeeId, date }) => ({ employeeId, date })],
  (workingHours) => workingHours?.schedule?.scheduleId,
);
