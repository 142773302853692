/**
 * This is the main class for header pane.
 */
import { trackEvent } from 'common/analytics';

App.Views.Calendar.HeaderPaneBase = Backbone.View.extend({
  events: {
    'click .previous': 'triggerBackwards',
    'click .next': 'triggerForward',
    'click .current-date': 'triggerGoToday',
    'click .calendar-resource li': 'onResourceIdChange',
    'click .show-all-groups': 'onShowAllGroupsClick',
  },

  initialize: function () {
    var self = this;
    this.currentDate = this.options.date || Wahanda.Date.createVenueDate();
    $('body').on('click', function (event) {
      if (
        $('.calendar-resource .current.top').is(event.target) ||
        $('.calendar-resource .current.top').has(event.target).length > 0
      ) {
        $('.calendar-resource .selection').toggle(true);
      } else {
        $('.calendar-resource .selection').hide();
      }
    });
    this.options.calendarView
      .on('change:type', function () {
        self.toggleClassesByType();
        self.renderTypeBlock();
        self.renderCurrentDateTitle();
        self.renderGoToCurrentDateLink();
      })
      .on('change:date', function (date) {
        self.currentDate = date;
        self.toggleClassesByType();
        self.renderCurrentDateTitle();
        self.renderGoToCurrentDateLink();
      })
      .on('change:resourceId', function () {
        self.syncGroupSelect();
        self.renderResources();
      });

    App.on('calendar:date-change', function (date) {
      self.currentDate = date;
      self.toggleClassesByType();
      self.renderCurrentDateTitle();
      self.renderGoToCurrentDateLink();
    }).on('calendar:request-date-change', function (date) {
      self._changeDate(date);
    });

    this.setupButtons();
    this.setupRequestListener();

    this.appointmentView = new App.Views.Calendar.Appointment.HeaderPane(this);
    this.datedView = new App.Views.Calendar.Dated.HeaderPane({
      paneView: this,
      mainView: this.options.mainView,
    });
  },

  getActiveView: function () {
    // If dated - return dated.
    if (this.options.mainView.getCalendarType() !== 'appointment') {
      return this.datedView;
    }
    return this.appointmentView;
  },

  /**
   * This methods is called from outside - when resources are loaded.
   */
  renderResources: function () {
    var aView = this.getActiveView();
    aView.renderResources.apply(aView, arguments);
  },

  render: function () {
    this.toggleClassesByType();
    this.renderGoToCurrentDateLink();
    this.renderCurrentDateTitle();
    this.renderTypeBlock();
    this.renderShowAllResourcesButton();
    this.makeFirstButton('day');
  },

  /**
   * Changes the order of week/day mode buttons: if type === day, the day button is forst. Oterwise week is first.
   */
  makeFirstButton: function (type) {
    var $ul = this.$('.views-control');
    var $week = $ul.find('.type-week').detach();
    var $day = $ul.find('.type-day').detach();

    if (type === 'day') {
      $ul.append($day).append($week);
    } else {
      $ul.append($week).append($day);
    }
  },

  setupButtons: function () {
    this.$('.views-control').buttonList();
  },

  setupRequestListener: function () {
    var self = this;
    App.on(Wahanda.Event.CALENDAR_GO_TODAY, function () {
      if (!self.getActiveView().isCurrentDateShown()) {
        self.goToToday();
      }
    })
      .on(Wahanda.Event.CALENDAR_GO_PREV, function () {
        self.goBack();
      })
      .on(Wahanda.Event.CALENDAR_GO_NEXT, function () {
        self.goForward();
      })
      .on('calendar:closeout-visibility-change', function (visible) {
        self.$el.toggleClass('has-closeout', visible);
      });
  },

  toggleClassesByType: function () {
    this.getActiveView().toggleClassesByType();
  },

  renderTypeBlock: function () {
    this.getActiveView().renderTypeBlock();
  },

  renderGoToCurrentDateLink: function () {
    var aView = this.getActiveView();
    var toShow = !aView.isCurrentDateShown();
    this.$el.toggleClass('has-today-link', toShow);
    if (toShow) {
      this.$('.current-date').text(aView.getCurrentDayText());
    }
  },

  renderShowAllResourcesButton: function () {
    this.$('.show-all-groups')
      .find('.link-edit')
      .text(this.getActiveView().getShowAllResourcesText());
  },

  goBack: function () {
    const activeView = this.getActiveView();
    // code used only for analytics
    if (activeView.getCurrentPaneViewType) {
      const currentPane = activeView.getCurrentPaneViewType();
      if (currentPane === 'day') {
        trackEvent('navigation', 'click', 'prev-day');
      } else {
        trackEvent('navigation', 'click', 'prev-week');
      }
    } else {
      trackEvent('navigation', 'click', 'prev-month');
    }

    const date = activeView.getPrevDate();
    this._changeDate(date);
  },

  goForward: function () {
    const activeView = this.getActiveView();

    // code used only for analytics
    if (activeView.getCurrentPaneViewType) {
      const currentPane = activeView.getCurrentPaneViewType();
      if (currentPane === 'day') {
        trackEvent('navigation', 'click', 'next-day');
      } else {
        trackEvent('navigation', 'click', 'next-week');
      }
    } else {
      trackEvent('navigation', 'click', 'next-month');
    }
    const date = activeView.getNextDate();

    this._changeDate(date);
  },

  goToToday: function () {
    this._changeDate(this.getActiveView().getTodayDate());
  },

  /**
   * Triggers a date change event.
   *
   * @param Date date
   */
  _changeDate: function (date) {
    if (this.getActiveView() === this.datedView) {
      App.trigger('calendar:date-change', date);
    } else {
      this.options.calendarView.trigger('change:date', date);
    }
  },

  renderCurrentDateTitle: function () {
    var text = this.getActiveView().getCurrentDateText();
    this.$('.selected-date').html(text);
  },

  syncGroupSelect: function () {
    if (this.options.mainView.getCalendarType() !== 'appointment') {
      return;
    }
    var select = this.$('.calendar-resource');
    var selected = select.find('.selected');

    if (select.find('.selected').attr('id')) {
      this.options.calendarView.currentOptions.resourceId = select.find('.selected').attr('id');
    }
  },

  onResourceIdChange: function (event) {
    this.$('.calendar-resource li').removeClass('selected');
    $(event.currentTarget).addClass('selected');
    this.getActiveView().onResourceIdChange(event);
  },

  toggleAllGroupLinkVisibility: function (hide) {
    this.$('.show-all-groups').toggleClass('hidden', hide);
  },

  onShowAllGroupsClick: function () {
    this.$('.calendar-resource li[id="all"]').click();
  },

  triggerBackwards: function () {
    App.trigger(Wahanda.Event.CALENDAR_GO_PREV);
  },

  triggerForward: function () {
    App.trigger(Wahanda.Event.CALENDAR_GO_NEXT);
  },

  triggerGoToday: function () {
    trackEvent('navigation', 'click', 'today');
    App.trigger(Wahanda.Event.CALENDAR_GO_TODAY);
  },
});
