import React, { PureComponent } from 'react';
import Wahanda from 'common/wahanda';
import { TYPE_CANCELLED, TYPE_NEW, TYPE_RESCHEDULED } from '../constants';
import style from './style.scss';

const lang = Wahanda.lang.header.notifications.appointments;
interface IAppointmentNotificationTitleProps extends React.HTMLAttributes<Element> {
  notificationType: string;
  employeeFullName?: string;
}

export default class AppointmentNotificationTitle extends PureComponent<
  IAppointmentNotificationTitleProps,
  {}
> {
  static defaultProps = {
    employeeFullName: null,
  };

  render() {
    const { notificationType, employeeFullName } = this.props;
    const titleMap = new Map([
      [TYPE_NEW, lang.new],
      [TYPE_RESCHEDULED, lang.rescheduled],
      [TYPE_CANCELLED, lang.cancelled],
    ]);
    let title = titleMap.get(notificationType);
    title = employeeFullName ? `${title} (${employeeFullName})` : title;
    return <div className={style.title}>{title}</div>;
  }
}
