import React from 'react';

interface IClosingXProps extends React.HTMLAttributes<Element> {
  onClick?: (...args: any[]) => any;
  zIndex?: number;
}

// Stand-alone implementation for X in React Dialog
export default class ClosingX extends React.Component<IClosingXProps, {}> {
  render() {
    return (
      <span
        className="react-dialog-close"
        onClick={this.props.onClick}
        style={{
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 'auto',
          left: 'auto',
          margin: 'auto',
          cursor: 'pointer',
          height: '48px',
          width: '48px',
          background: 'transparent url(/assets/icons/dialog2-close.png) no-repeat center',
          zIndex: this.props.zIndex || 5,
        }}
      />
    );
  }
}
