/* global BackboneEx _ */

App.Views.Header.Icons = BackboneEx.View.Base.extend({
  rendered: false,

  calculateAllIconWidth() {
    const elements = this.$('#venues').add(this.$('#nav1 > li')).add(this.$('#user'));

    return _.reduce(
      elements,
      function (sum, item) {
        return sum + $(item).outerWidth();
      },
      0,
    );
  },

  buildStylesheet() {
    this.$('#nav1 > li').map(function () {
      return this.getAttribute('id');
    });
  },

  render() {
    const $styles = $('#header-icons-mq');

    if (this.rendered) {
      // Clean the previously set styles, as we need to recalculate the full-width items.
      $styles.html('');
    }

    const totalIconWidth = this.calculateAllIconWidth();
    const style = this.buildStylesheet(totalIconWidth);

    // Update an stylesheet in the head
    $styles.html(style);
    // Remove the 100% opacity
    this.$('#nav1').removeClass('opaque');

    this.rendered = true;
  },
});
