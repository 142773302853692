import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { DialogCloseWithChanges, Props } from './';

interface Params {
  data: Props;
  node: Element;
}

export const DialogCloseWithChangesInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(params.node),
  render: () => {
    ReactDOM.render(<DialogCloseWithChanges {...params.data} />, params.node);
  },
});
