/* eslint react/no-array-index-key: 0 */
/* eslint arrow-body-style: 0 */
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import style from './style.scss';

interface ITopReportProps extends React.HTMLAttributes<Element> {
  className?: string;
  reportHeader: string | JSX.Element;
  headers: string[];
  data: any[][];
}

export default class TopReport extends PureComponent<ITopReportProps, {}> {
  static defaultProps = {
    className: null,
  };

  hasData = () => {
    const { data } = this.props;
    return data.length !== 0;
  };

  renderHeader = (header, index) => {
    const className = index === 0 ? classnames(style.textLeft, style.fullWidth) : style.textRight;
    return (
      <th key={header} className={classnames(className, style.tableHeader)}>
        {header}
      </th>
    );
  };

  renderEmptyState = () => {
    const { headers } = this.props;
    return (
      <tr>
        <td className={style.noData} colSpan={headers.length}>
          {Wahanda.lang.dashboard.shared.noData}
        </td>
      </tr>
    );
  };

  renderDataRow = (data, index) => {
    return (
      <tr key={index}>
        {data.map((datum, i) => (
          <td
            key={i}
            className={classnames(style.dataPoint, i === 0 ? style.textLeft : style.textRight)}
          >
            {datum}
          </td>
        ))}
      </tr>
    );
  };

  render() {
    const { reportHeader, headers, data } = this.props;
    return (
      <div className={classnames(style.report, this.props.className)}>
        {reportHeader}
        <table className={style.fullWidth}>
          <thead>
            <tr>{headers.map(this.renderHeader)}</tr>
          </thead>
          <tbody>{this.hasData() ? data.map(this.renderDataRow) : this.renderEmptyState()}</tbody>
        </table>
      </div>
    );
  }
}
