/**
 * Employee external calendar.
 */
App.Models.ExmployeeExternalCalendar = BackboneEx.Model.Silent.extend(
  {
    initialize: function () {},

    url: function () {
      const base = '/venue/{{venueId}}/employee/{{id}}/external-calendar-sync.json';
      return App.Api.wsUrl(
        Wahanda.Template.render(base, {
          id: this.get('id'),
          venueId: this.get('venueId'),
        }),
      );
    },

    fetch: function (options) {
      options = options || {};
      options.type = 'POST';

      this.constructor.__super__.fetch.call(this, options);
    },
  },
  {
    /**
     * Return calendar fetching error text by the error name code.
     *
     * @param String name
     * @return String
     */
    getFailureTextByName: function (name) {
      var lang = Wahanda.lang.settings.employees.employee.linkCalendar.uriError;

      switch (name) {
        case 'employee-external-calendar-processing-failure':
          return lang.calendarProcessingFailure;

        case 'employee-external-calendar-invalid-uri':
          return lang.invalidUri;

        case 'employee-external-calendar-processing-already-in-progress':
          return lang.processingInProgress;

        case 'employee-external-calendar-not-found':
          return lang.uriNotEntered;

        default:
          // Unknown code.
          return name;
      }
    },
  },
);
