import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import PartnerSite from './index';

// TODO: think of a way to define state type
const mapStateToProps = (state) => ({
  settings: state.marketing.partnerSite.settings,
  isLoading: state.marketing.partnerSite.isLoading,
  isSubmitting: state.marketing.partnerSite.isSubmitting,
  subdomainValidationStatus: state.marketing.partnerSite.subdomainValidationStatus.status,
  customDomainValidationStatus: state.marketing.partnerSite.customDomainValidationStatus.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PartnerSite);
