/* global Backbone */
import _ from 'common/underscore';
import storeBuilder from 'reduxStore/store';
import storeObserver from 'reduxStore/store/storeObserver';
import { requestPosStatusAction, resetStateAction } from 'reduxStore/common/posStatus/actions';
import BigNumber from 'bignumber.js';

if (!App.Views.POS) {
  App.Views.POS = {};
}

App.Views.POS.POSCheckoutPriceCalculator = Backbone.View.extend({
  initialize: function initialize() {
    this.controller = this.options.controller;
    this.products = new App.Collections.POS.Products();
    this.payments = new App.Collections.POS.Payments();
    this.tips = new App.Collections.POS.Tips();
    this.store = storeBuilder();
  },

  calculatePriceAfterDiscount: function calculatePriceAfterDiscount({ price, discountPercentage }) {
    if (price == null || discountPercentage == null) {
      return price;
    }
    const BN = BigNumber.clone({ ROUNDING_MODE: BigNumber.ROUND_HALF_UP });
    if (new BN(discountPercentage).isLessThanOrEqualTo(0)) {
      return price;
    }
    if (new BN(discountPercentage).isGreaterThanOrEqualTo(100)) {
      return 0;
    }

    const discount = new BN(discountPercentage).dividedBy(100).times(price).toFixed(2);
    const discountedPrice = new BN(price).minus(discount).toNumber();

    return discountedPrice;
  },

  getProducts: function getProducts() {
    return this.products;
  },

  getPayments: function getPayments() {
    return this.payments;
  },

  getTips: function getTips() {
    return this.tips;
  },

  getSubTotal: function getSubTotal() {
    return new BigNumber(this.products.getTotalDiscounted())
      .plus(this.getServicesTotal())
      .toNumber();
  },

  getServicesTotal: function getServicesTotal() {
    const servicesTotal = _.reduce(
      this.controller.appointments,
      (running, appointment) => {
        if (appointment.selected) {
          const { amount, discountedAmount } = appointment;
          if (discountedAmount != null) {
            return running.plus(parseFloat(discountedAmount));
          }

          return running.plus(parseFloat(amount));
        }
        return running;
      },
      new BigNumber(0),
    );

    return servicesTotal.toNumber();
  },

  getPrepaidTotal: function getPrepaidTotal() {
    const prepaidTotal = _.reduce(
      this.controller.appointments,
      (running, appointment) => {
        if (appointment.selected && !appointment.unpaid) {
          return running.plus(parseFloat(appointment.originalAmount));
        }
        return running;
      },
      new BigNumber(0),
    );

    return prepaidTotal.toNumber();
  },

  getCostAmount: function getCostAmount(amount, isCash) {
    const totalCost = this.getTotalCost();
    const totalPaid = isCash
      ? new BigNumber(this.getTotalPaid()).minus(this.payments.getTotalCash()).toNumber()
      : this.getTotalPaid();

    const outstanding = new BigNumber(totalCost).minus(totalPaid).toNumber();
    const overPaid = amount > outstanding;
    return overPaid ? outstanding : amount;
  },

  getTotalCost: function getTotalCost() {
    const totalCost = new BigNumber(this.getSubTotal()).minus(this.getPrepaidTotal()).toNumber();

    return totalCost > 0 ? totalCost : 0;
  },

  getTotalPaid: function getTotalPaid() {
    const totalPaid = this.payments.getTotal();
    return totalPaid;
  },

  getDeductions: function getDeductions() {
    return this.getPrepaidTotal();
  },

  getOutstanding: function getOutstanding() {
    const cost = this.getTotalCost();
    const tips = this.tips.getTotal();
    const paid = this.getTotalPaid();

    return new BigNumber(cost).minus(tips).minus(paid).toNumber().toFixed(2);
  },

  getCashInDrawer: function getCashInDrawer() {
    return new Promise((resolve, reject) => {
      let destroyStoreObserver = () => null;
      const select = (state) => {
        try {
          return state.posStatus.cashBalance;
        } catch (e) {
          reject(e);
        }
      };
      const onChange = (data) => {
        if (data != null) {
          destroyStoreObserver();
          this.store.dispatch(resetStateAction());
          resolve(data);
        }
      };

      destroyStoreObserver = storeObserver(this.store, select, onChange);
      this.store.dispatch(requestPosStatusAction());
    });
  },

  getChange: function getChange() {
    let total = new BigNumber(0);
    this.payments.each((payment) => {
      if (payment.get('paymentType') === App.Models.POS.PaymentDiscriminators.CASH) {
        total = total.plus(payment.get('amount')).minus(payment.get('costAmount'));
      }
    });
    return total.toNumber();
  },

  getTotalPaidIncludingTips: function getTotalPaidIncludingTips() {
    const totalPaid = this.getTotalPaid();

    const totalPaidIncludingTips = new BigNumber(totalPaid).plus(this.tips.getTotal()).toNumber();
    return totalPaidIncludingTips;
  },

  /**
   * Remove tips from payments
   * If a multiple card payment left a tip associated with it,
   * but is removed and leaves a balance due, convert the tip to a payment.
   */
  removeTipsFromPayments: function removeTipsFromPayments() {
    const totalCost = this.getTotalCost();
    const totalPaid = this.getTotalPaidIncludingTips();
    const overpayment = totalCost > totalPaid;

    if (overpayment) {
      this.payments.each((payment) => {
        if (payment.get('tip')) {
          // recalculate payment amount
          const tipOnPayment = payment.get('tip');
          const tipAmountOnPayment = tipOnPayment.get('amount');
          payment.unset('tip');
          payment.set(
            'amount',
            new BigNumber(tipAmountOnPayment).plus(payment.get('amount')).toNumber(),
          );
        }
      });
      this.tips.reset();
    }
  },
});
