import React from 'react';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { GoTo, Direction } from 'components/common/GoTo';

import style from './style.scss';

class BookingsReport extends React.PureComponent {
  private myRef: React.RefObject<HTMLDivElement>;

  private reportView: any;

  constructor(props) {
    super(props);
    this.myRef = React.createRef<HTMLDivElement>();
  }

  public componentDidMount() {
    this.reportView = new App.Views.Reports.Table({
      el: this.myRef.current,
    });
    this.reportView.render();
    this.reportView.trigger('visible');
  }

  public render() {
    return (
      <div ref={this.myRef} id="bookings" className="section-main">
        <div className="reports-bookings">
          <div className="home-filters">
            <div id="filter-type" className="filter-wrapper">
              <div className="filter-current">
                {Wahanda.lang.home.table.filters.type.everything}
                <div className="handler">
                  <div className="icons-arrow-bottom2" />
                </div>
              </div>
              <ul className="filter-ddown" style={{ display: 'none' }}>
                <li className="on" data-value="ALL">
                  {Wahanda.lang.home.table.filters.type.everything}
                </li>
                <li data-value="AAP" className="subitem">
                  {Wahanda.lang.home.table.filters.type.appointments}
                </li>
              </ul>
            </div>

            <div id="filter-subtype" className="filter-wrapper">
              <div className="filter-current">
                {Wahanda.lang.home.table.filters.type.all}
                <span className="count">-</span>
                <div className="handler">
                  <div className="icons-arrow-bottom2" />
                </div>
              </div>
              <ul className="filter-ddown" style={{ display: 'none' }}>
                <li className="on">{Wahanda.lang.home.table.filters.type.all}</li>
              </ul>
            </div>

            <div id="filter-date" className="filter-wrapper short">
              <div className="filter-current act">
                {Wahanda.lang.home.table.filters.date.months1}
                <div className="handler">
                  <div className="icons-arrow-bottom2" />
                </div>
              </div>
              <ul className="filter-ddown" style={{ display: 'none' }}>
                <li className="on" data-value="1M">
                  {Wahanda.lang.home.table.filters.date.months1}
                </li>
                <li data-value="2M">{Wahanda.lang.home.table.filters.date.months2}</li>
                <li data-value="3M">{Wahanda.lang.home.table.filters.date.months3}</li>
                <li data-value="6M">{Wahanda.lang.home.table.filters.date.months6}</li>
                <li data-value="12M">{Wahanda.lang.home.table.filters.date.months12}</li>
                <li data-value="CUST">{Wahanda.lang.home.table.filters.date.custom}</li>
              </ul>
            </div>

            <div className="filter-wrapper date-range hidden">
              <form>
                <div className="txt-input txt-input-mini">
                  <input
                    type="text"
                    defaultValue=""
                    placeholder={Wahanda.lang.shared.from}
                    className="date-format-default"
                    id="filter-date-from"
                    name="filter-date-from"
                  />
                </div>
                <span className="sep">-</span>
                <div className="txt-input txt-input-mini">
                  <input
                    type="text"
                    defaultValue=""
                    placeholder={Wahanda.lang.shared.until}
                    className="date-format-default"
                    id="filter-date-to"
                    name="filter-date-to"
                  />
                </div>
                <button type="button" className="button button-basic refresh a-custom-filter">
                  <div className="button-inner">
                    <div className="button-icon icons-refresh" />
                    &nbsp;
                  </div>
                </button>
              </form>
            </div>
          </div>
          <GoTo className={style.linkBack} href="/" direction={Direction.backward}>
            {Wahanda.lang.reports.tabs.reports}
          </GoTo>
          <div className="home-data" data-hj-suppress>
            <div className="data-head">
              <table cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td className="icon-column" />
                    <td className="column2">{Wahanda.lang.home.table.headings.orderRef}</td>
                    <td className="column3">{Wahanda.lang.home.table.headings.date}</td>
                    <td className="column4">
                      {Wahanda.lang.home.table.headings.appointmentDatetime}
                    </td>
                    <td className="column4">{Wahanda.lang.home.table.headings.employee}</td>
                    <td className="column4">{Wahanda.lang.home.table.headings.customer}</td>
                    <td className="column5">{Wahanda.lang.home.table.headings.service}</td>
                    <td className="column6">{Wahanda.lang.home.table.headings.price}</td>
                    <td className="column7">{Wahanda.lang.home.table.headings.status}</td>
                    <td className="tick" />
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="data-body">
              <table cellPadding="0" cellSpacing="0">
                <tbody />
              </table>
            </div>
            <div className="no-data">{Wahanda.lang.home.table.empty}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default BookingsReport;
