import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { placeOrderHasBeenClickedSelector, allowToPlaceOrderSelector } from '../selectors';
import { requestCreateOrderAction, productOrderReadyToBePlaced } from '../actions';

import NewOrderDialog from './index';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      requestCreateOrderAction,
      productOrderReadyToBePlaced,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ products, productOrders }) => ({
  productSupplier: productOrders.productSupplier,
  loadingProducts: products.loading,
  newOrderItems: placeOrderHasBeenClickedSelector(productOrders),
  allowToPlaceOrder: allowToPlaceOrderSelector(productOrders),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ newOrderItems: never[]; productSupplier: n... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(NewOrderDialog);
