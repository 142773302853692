import { MAX_CACHE_TIME } from './config';

export default function expire(cache) {
  const valid = {};
  const now = Date.now();

  Object.keys(cache).forEach((date) => {
    const data = cache[date];
    if (data.cached + MAX_CACHE_TIME > now) {
      valid[date] = data;
    }
  });

  return valid;
}
