/* eslint new-cap: 0 */
/* eslint no-control-regex: 0 */
/* eslint no-useless-escape: 0 */

import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import { isValidNumber } from 'common/phonenumber';

const isNumber = (val) => /^\d+$/.test(val);

/**
 * Validate birth day.
 * 2 digit format and greater than 0 and less than 31.
 */
const MIN_BIRTH_DAY_VALUE = 1;
const MAX_BIRTH_DAY_VALUE = 31;

// Month must be 1-indexed, year is optional
function daysInMonth(month, year) {
  switch (month) {
    case 2:
      if (!year) {
        // Without year, we cannot tell how many days are in February
        return 29;
      }

      return (year % 4 === 0 && year % 100) || year % 400 === 0 ? 29 : 28;
    case 9:
    case 4:
    case 6:
    case 11:
      return 30;
    default:
      return 31;
  }
}

export const birthDayValidator = {
  KEY: 'birthDayValidator',
  MIN: MIN_BIRTH_DAY_VALUE,
  MAX: MAX_BIRTH_DAY_VALUE,
  VALIDATOR: ({ birthYear, birthMonth }, birthDay) => {
    if (birthDay == null || birthDay === '') {
      return true;
    }

    const isValid =
      birthMonth >= 1 &&
      birthMonth <= 12 &&
      birthDay > 0 &&
      birthDay <= daysInMonth(birthMonth, birthYear);

    if (!isValid) {
      return Wahanda.lang.shared.errors.notBirthDay;
    }
    return true;
  },
};

/**
 * Validate birth year.
 * 4 digit format and greater or equal than 1900 and less or equal to current year.
 */
const MIN_BIRTH_YEAR_VALUE = 1900;

export const birthYearValidator = {
  KEY: 'birthYearValidator',
  MIN: MIN_BIRTH_YEAR_VALUE,
  VALIDATOR: (values, birthYear) => {
    if (birthYear == null || birthYear === '') {
      return true;
    }

    const isValid =
      isNumber(birthYear) &&
      /^\d{4}$/.test(birthYear) &&
      birthYear >= MIN_BIRTH_YEAR_VALUE &&
      birthYear <= new Date().getFullYear();
    if (!isValid) {
      return Wahanda.lang.shared.errors.notBirthYear;
    }
    return true;
  },
};

export const customerNameValidator = {
  KEY: 'customerNameValidator',
  VALIDATOR: (values, name) => {
    const { emailAddress, phone } = values;
    if (
      ((emailAddress != null && emailAddress !== '') || (phone != null && phone !== '')) &&
      (name == null || name === '')
    ) {
      return Wahanda.lang.inlineClientEditing.validationMessages.nameIsRequired;
    }
    return true;
  },
};

export const emailValidator = {
  KEY: 'emailValidator',
  VALIDATOR: (values, emailAddress) => {
    if (emailAddress == null || emailAddress === '') {
      return true;
    }
    /**
     * This has been taken from google email validation
     * in app/assets/javascripts/wahanda/validate.js
     * from google email validation
     */
    const isValid = /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
      emailAddress,
    );
    if (!isValid) {
      return Wahanda.lang.validate.defaults.email;
    }
    return true;
  },
};

export const phoneValidator = {
  KEY: 'phoneValidator',
  VALIDATOR: (values, phone) => {
    const notValidPhoneNumber = Wahanda.lang.shared.errors.notValidPhoneNumber;

    if (phone == null || phone === '') {
      return true;
    }

    if (Wahanda.Util.containsAlpha(phone)) {
      return notValidPhoneNumber;
    }

    const result = isValidNumber(phone, App.config.get('venue').countryCode);
    return result || notValidPhoneNumber;
  },
};

export const webcalUrl = {
  KEY: 'webcalUrl',
  VALIDATOR: (values, url) => {
    if (url == null || url === '') {
      return true;
    }

    const isValid = /^(https?|webcal):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
      url.trim(),
    );
    if (!isValid) {
      return Wahanda.lang.shared.errors.notWebcalUri;
    }
    return true;
  },
};

export const isNotEmpty = {
  KEY: 'isNotEmpty',
  VALIDATOR: (values, value) => {
    if (value == null) {
      return false;
    }
    return String(value).trim().length !== 0;
  },
};
