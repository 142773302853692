import React from 'react';
import Wahanda from 'common/wahanda';
import { Warning, SuccessWarning, Success } from 'components/common/IconPicture';

import { FileImportStatus } from '../../types';
import { ClientImportAnalytics } from '../../tracking';
import style from './StatusMessage.scss';
import { openChat } from 'utilities/chat';

const lang = Wahanda.lang.clientsImport.addMultipleClients.finish;
const LIVECHAT_SOURCE = 'clients import failure';

export interface Props {
  status: FileImportStatus;
  importCount: number;
}

export class StatusMessage extends React.PureComponent<Props> {
  public render() {
    return (
      <React.Fragment>
        {this.renderStatusIcon()}
        {this.renderMessage()}
      </React.Fragment>
    );
  }

  private renderMessage = () => {
    return (
      <div className={style.message}>
        {this.renderHeader()}
        {this.renderDescription()}
      </div>
    );
  };

  private renderHeader = () => {
    const { status, importCount } = this.props;
    let header: string | undefined;
    switch (status) {
      case FileImportStatus.UNFINISHED_BECAUSE_OF_ERRORS:
        header = lang.failure.header;
        break;
      case FileImportStatus.FINISHED_SUCCESS:
      case FileImportStatus.FINISHED_WITH_WARNINGS:
        header = lang.success.header.replace('{{importCount}}', importCount);
        break;
      default:
        break;
    }
    return (
      <div className={style.header} data-test="message-header">
        {header}
      </div>
    );
  };

  private renderDescription = () => {
    const { status } = this.props;
    switch (status) {
      case FileImportStatus.UNFINISHED_BECAUSE_OF_ERRORS:
        return (
          <span className={style.description} data-test="message-description">
            <span dangerouslySetInnerHTML={{ __html: lang.failure.description }} />
            &nbsp;
            <a onClick={this.openLiveChat}>{lang.failure.contactUs}</a>
          </span>
        );
      case FileImportStatus.FINISHED_WITH_WARNINGS:
      case FileImportStatus.FINISHED_SUCCESS:
        return (
          <span className={style.description} data-test="message-description">
            {lang.success.description}
          </span>
        );
      default:
        break;
    }
  };

  private renderStatusIcon = () => {
    const { status } = this.props;
    let icon;
    switch (status) {
      case FileImportStatus.UNFINISHED_BECAUSE_OF_ERRORS:
        icon = <Warning />;
        break;
      case FileImportStatus.FINISHED_WITH_WARNINGS:
        icon = <SuccessWarning />;
        break;
      case FileImportStatus.FINISHED_SUCCESS:
        icon = <Success />;
        break;
      default:
        break;
    }

    if (icon) {
      return <div className={style.icon}>{icon}</div>;
    }
  };

  private openLiveChat = () => {
    ClientImportAnalytics.trackFailureLiveChatClicked();
    openChat(LIVECHAT_SOURCE);
  };
}
