import React from 'react';
import Wahanda from 'common/wahanda';
import { SalesOverviewAnalytics } from 'common/analytics';
import classnames from 'classnames';
import ServiceLink from '../ServiceSalesReport/link';
import ProductLink from '../ProductSalesReport/link';
import tableStyle from '../common/reportTable.scss';

interface IReportTableProps extends React.HTMLAttributes<Element> {
  fromDate: any;
  toDate: any;
  selectedEmployeeId: number;
  selectedChannel: string;
  rows: {
    type?: string;
    paidAtVenue?: number;
    prePaid?: number;
    grossTotalSold?: number;
  }[];
  total: {
    paidAtVenue?: number;
    prePaid?: number;
    grossTotalSold?: number;
  };
}

export default class ReportTable extends React.Component<IReportTableProps, {}> {
  getTableHeader = () => (
    <thead>
      <tr>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th rowSpan="2" className={tableStyle.stackedHeaderDoubleHeight}>
          {' '}
          &nbsp;
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="3">
          {Wahanda.lang.reports.sales.salesReport.headings.salesIncludingVat}
        </th>
      </tr>
      <tr>
        <th className={classnames(tableStyle.numeric, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.salesReport.headings.payAtVenue}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.salesReport.headings.prePaid}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.salesReport.headings.total}
        </th>
      </tr>
    </thead>
  );

  getTableFooter = (total) => (
    <tfoot>
      <tr>
        <td>{Wahanda.lang.reports.sales.salesReport.headings.grandTotal}</td>
        <td className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.paidAtVenue)}
        </td>
        <td className={tableStyle.numeric}>{Wahanda.Currency.formatFinancial(total.prePaid)}</td>
        <td className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.grossTotalSold)}
        </td>
      </tr>
    </tfoot>
  );

  getTableRow = (rowHeading, paidAtVenue, prePaid, total) => (
    <tr>
      <td>{rowHeading}</td>
      <td className={tableStyle.numeric}>{paidAtVenue}</td>
      <td className={tableStyle.numeric}>{prePaid}</td>
      <td className={tableStyle.grandTotal}>{total}</td>
    </tr>
  );

  render() {
    const rows = this.props.rows;
    const serviceRow = rows[0];
    const productRow = rows[1];
    const voucherRow = rows[2];
    const { fromDate, toDate, selectedEmployeeId, selectedChannel } = this.props;
    return (
      <table className={tableStyle.reportTable}>
        {this.getTableHeader()}
        <tbody>
          {this.getTableRow(
            <ServiceLink
              // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
              className={tableStyle.link}
              fromDate={fromDate}
              toDate={toDate}
              employeeFilter={selectedEmployeeId}
              selectedChannel={selectedChannel}
              onClick={SalesOverviewAnalytics.trackNavigateToServiceClick}
            >
              {Wahanda.lang.reports.sales.salesReport.rows.services}
            </ServiceLink>,
            Wahanda.Currency.formatFinancial(serviceRow.paidAtVenue),
            Wahanda.Currency.formatFinancial(serviceRow.prePaid),
            Wahanda.Currency.formatFinancial(serviceRow.grossTotalSold),
          )}
          {this.getTableRow(
            <ProductLink
              // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
              className={tableStyle.link}
              fromDate={fromDate}
              toDate={toDate}
              employeeFilter={selectedEmployeeId}
              onClick={SalesOverviewAnalytics.trackNavigateToProductsClick}
            >
              {Wahanda.lang.reports.sales.salesReport.rows.products}
            </ProductLink>,
            Wahanda.Currency.formatFinancial(productRow.paidAtVenue),
            '-',
            Wahanda.Currency.formatFinancial(productRow.grossTotalSold),
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 6.
            '-',
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'grossTotalMinusFees' does not exist on t... Remove this comment to see the full error message
            Wahanda.Currency.formatFinancial(productRow.grossTotalMinusFees),
          )}
          {this.getTableRow(
            Wahanda.lang.reports.sales.salesReport.rows.vouchers,
            Wahanda.Currency.formatFinancial(voucherRow.paidAtVenue),
            '-',
            Wahanda.Currency.formatFinancial(voucherRow.grossTotalSold),
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 6.
            '-',
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'grossTotalMinusFees' does not exist on t... Remove this comment to see the full error message
            Wahanda.Currency.formatFinancial(voucherRow.grossTotalMinusFees),
          )}
        </tbody>
        {this.getTableFooter(this.props.total)}
      </table>
    );
  }
}
