import React from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';

import { PopoverAnchorOffset, PopoverPlacement } from 'components/common/Popover';

import { actionGoToRota } from '../utils/actionItems';
import { editShiftOptionsList, editScheduleOptionsList } from '../utils/constants';
import { limitedHoursRange } from '../utils/helpers';
import {
  Employee,
  RotaModalType,
  SetEmployeeOpenDayPayload,
  SetEmployeesShiftPayload,
  ShiftWorkingHourType,
  WorkingHoursObject,
} from '../utils/types';
import { ShiftsAnalytics } from '../tracking';
import { ShiftActionsModal } from '../ShiftActionsModal';
import { ShiftDayModal } from '../ShiftDayModal';
import { ShiftTimeOffModal } from '../ShiftTimeOffModal';
import { ShiftRemoveCustomModal } from '../ShiftRemoveCustomModal';
import { ShiftModalHeader } from '../ShiftModalHeader';
import { ShiftScheduleModal } from '../ShiftScheduleModal';
import { ShiftScheduleType } from '../ShiftScheduleType';
import { ShiftScheduleChangeModal } from '../ShiftScheduleChangeModal';

const LANG = Wahanda.lang.settings.shifts;

interface Props {
  employeeId: number;
  date: string;
  modalType?: RotaModalType;
  popoverPlacement?: PopoverPlacement;
  popoverOffset?: PopoverAnchorOffset;
  onClose: (param?) => void;
  trackingCategory?: string | null;
  // redux
  actions: {
    setEmployeesOpenDay: (param: SetEmployeeOpenDayPayload) => void;
    setEmployeesShift: (param: SetEmployeesShiftPayload) => void;
    getVenueBusinessHours: () => void;
    resetShiftModal: () => void;
  };
  canAddTimeOff: boolean;
  canRemoveDayOff: boolean;
  employee: Employee;
  employeeWorkingHours?: WorkingHoursObject;
  isShownOnCalendar: boolean;
  isBeforeCurrentWeek: boolean;
  isBusinessHoursLoaded: boolean;
  scheduleId?: number;
  venueWorkingHours: WorkingHoursObject;
}

interface State {
  modalType: RotaModalType;
}

export class ShiftEmployeeModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      modalType: this.props.modalType || RotaModalType.EMPLOYEE_MODAL_ACTIONS,
    };
  }

  public componentDidMount() {
    if (!this.props.isBusinessHoursLoaded) {
      this.props.actions.getVenueBusinessHours();
    }

    ShiftsAnalytics.trackShiftActionsModalView({
      category: this.props.trackingCategory,
    });
  }

  private onClose = (event?) => {
    this.setState({ modalType: RotaModalType.HIDE });
    this.props.onClose(event);
    if (this.props.isShownOnCalendar) {
      this.props.actions.resetShiftModal();
    }
  };

  private renderModal = () => {
    const { scheduleId } = this.props;
    const { modalType } = this.state;

    switch (modalType) {
      case RotaModalType.HIDE:
        return null;
      case RotaModalType.EMPLOYEE_DAY_EDIT:
        return this.renderShiftDayModal();
      case RotaModalType.EMPLOYEE_TIME_OFF_EDIT:
        return this.renderTimeOffOrRemoveModal();
      case RotaModalType.EMPLOYEE_REMOVE_CUSTOM_EDIT:
        return this.renderTimeOffOrRemoveModal(true);
      case RotaModalType.EMPLOYEE_SCHEDULE_NEW:
        return this.renderShiftScheduleModal();
      case RotaModalType.EMPLOYEE_SCHEDULE_EDIT:
        return this.renderShiftScheduleModal(scheduleId);
      case RotaModalType.EMPLOYEE_SCHEDULE_CHANGE:
        return this.renderShiftScheduleChangeModal();
      case RotaModalType.EMPLOYEE_MODAL_ACTIONS:
      default:
        return this.renderActionModal();
    }
  };

  private setModalType = (modalType: RotaModalType) => {
    this.setState({ modalType });
  };

  private renderActionModal = () => {
    const {
      canAddTimeOff,
      canRemoveDayOff,
      date,
      employee,
      employeeId,
      isBeforeCurrentWeek,
      scheduleId,
      venueWorkingHours,
      popoverPlacement,
      popoverOffset,
    } = this.props;
    const { employeeImage, employeeName, ...otherEmployeeProps } = employee;
    const data = {
      date,
      image: employeeImage,
      title: employeeName,
      ...otherEmployeeProps,
    };

    if (!venueWorkingHours) {
      return null;
    }

    const isSalonClosed = venueWorkingHours?.timeSlots.length === 0;

    const actionList = [
      {
        isHidden: isSalonClosed,
        dataTest: 'shift-action-modal-edit-day',
        label: LANG.modalEmployeeActions.editDay,
        onClick: () => this.setModalType(RotaModalType.EMPLOYEE_DAY_EDIT),
      },
      {
        isHidden: !canAddTimeOff,
        dataTest: 'shift-action-modal-add-time-off',
        label: LANG.modalEmployeeActions.addTimeOff,
        onClick: () => this.setModalType(RotaModalType.EMPLOYEE_TIME_OFF_EDIT),
      },
      {
        isHidden: !canRemoveDayOff,
        dataTest: 'shift-action-modal-remove-day-off',
        label: LANG.modalEmployeeActions.removeDayOff,
        onClick: () => this.setModalType(RotaModalType.EMPLOYEE_REMOVE_CUSTOM_EDIT),
      },
      {
        isHidden: isBeforeCurrentWeek || !!scheduleId,
        dataTest: 'shift-action-modal-add-schedule',
        label: LANG.modalEmployeeActions.newSchedule,
        onClick: () => this.setModalType(RotaModalType.EMPLOYEE_SCHEDULE_NEW),
      },
      {
        isHidden: isBeforeCurrentWeek || !scheduleId,
        dataTest: 'shift-action-modal-change-schedule',
        label: LANG.modalEmployeeActions.changeSchedule,
        onClick: () => this.setModalType(RotaModalType.EMPLOYEE_SCHEDULE_CHANGE),
      },
      actionGoToRota(!this.props.isShownOnCalendar),
    ];

    let scheduleTypeContainer;

    if (scheduleId) {
      scheduleTypeContainer = <ShiftScheduleType employeeId={employeeId} scheduleId={scheduleId} />;
    }

    if (popoverOffset) {
      return (
        <ShiftActionsModal
          actionList={actionList}
          data={data}
          onClose={this.onClose}
          offset={popoverOffset}
          placement={popoverPlacement}
          scheduleTypeContainer={scheduleTypeContainer}
        />
      );
    }
    return null;
  };

  private onShiftEditSubmit = ({ type, timeSlots }) => {
    const { employeeId, date, scheduleId, venueWorkingHours } = this.props;
    const venueTimeSlots = venueWorkingHours.timeSlots[0];
    const hasSchedule = !!scheduleId;
    const openDayType = [ShiftWorkingHourType.VENUE, ShiftWorkingHourType.SCHEDULE];

    if (openDayType.includes(type)) {
      this.props.actions.setEmployeesOpenDay({
        employeeId,
        dates: [date],
      });
    } else {
      this.props.actions.setEmployeesShift({
        employeeId,
        date,
        type,
        timeSlots,
        venueTimeSlots,
        fetchShiftData: hasSchedule,
      });
    }
    ShiftsAnalytics.trackShiftDayModalSubmit({
      category: this.props.trackingCategory,
      property: type,
    });
  };

  private onShiftEditClose = (event) => {
    if (event) {
      ShiftsAnalytics.trackShiftDayModalClose({
        category: this.props.trackingCategory,
      });
    }
    this.onClose();
  };

  private renderShiftDayModal = () => {
    const { employee, employeeWorkingHours, venueWorkingHours, scheduleId } = this.props;
    if (!venueWorkingHours || !employeeWorkingHours) {
      return null;
    }

    const { employeeImage, employeeName } = employee;
    const { date, timeSlots, type } = employeeWorkingHours;

    const venueTimeSlots = venueWorkingHours.timeSlots;

    const header = <ShiftModalHeader image={employeeImage} title={employeeName} />;
    const isDayOff = timeSlots && !timeSlots.length && type !== ShiftWorkingHourType.SCHEDULE;
    const workingHoursType = isDayOff ? ShiftWorkingHourType.DAY_OFF : type;

    const onComponentMount = () =>
      ShiftsAnalytics.trackShiftDayModalView({
        category: this.props.trackingCategory,
      });
    const hasSchedule = !!scheduleId;
    const optionsList = hasSchedule ? editScheduleOptionsList : editShiftOptionsList;

    return (
      <ShiftDayModal
        limitedHoursRange={limitedHoursRange(venueTimeSlots)}
        date={date || ''}
        header={header}
        onComponentMount={onComponentMount}
        onClose={this.onShiftEditClose}
        onSubmit={this.onShiftEditSubmit}
        optionsList={optionsList}
        title={LANG.modalEditShift.title}
        timeSlots={timeSlots}
        workingHoursType={workingHoursType}
        defaultTimeSlots={venueTimeSlots}
      />
    );
  };

  private renderTimeOffOrRemoveModal = (renderRemove = false) => {
    const Modal = renderRemove ? ShiftRemoveCustomModal : ShiftTimeOffModal;
    return (
      <Modal
        employeeId={this.props.employeeId}
        dateFrom={moment(this.props.date)}
        onClose={this.onClose}
        trackingCategory={this.props.trackingCategory}
      />
    );
  };

  private renderShiftScheduleModal = (scheduleId?) => {
    const { employeeId, date } = this.props;

    return (
      <ShiftScheduleModal
        employeeId={employeeId}
        date={date}
        scheduleId={scheduleId}
        onClose={this.onClose}
        trackingCategory={this.props.trackingCategory}
      />
    );
  };

  private onNewScheduleTrigger = () => {
    this.setState({
      modalType: RotaModalType.EMPLOYEE_SCHEDULE_EDIT,
    });
  };

  private renderShiftScheduleChangeModal = () => {
    const { employeeId, date, scheduleId } = this.props;

    if (!scheduleId) {
      return null;
    }

    return (
      <ShiftScheduleChangeModal
        employeeId={employeeId}
        scheduleId={scheduleId}
        invalidFrom={date}
        onClose={this.onClose}
        onNewScheduleTrigger={this.onNewScheduleTrigger}
        trackingCategory={this.props.trackingCategory}
      />
    );
  };

  public render() {
    return this.renderModal();
  }
}
