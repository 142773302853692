import { action, payload } from 'ts-action';
import { TransactionExports, ExportType } from './types';

export const initPosExport = action(
  '[POS Exports] Init',
  payload<{ type: ExportType; from: string; to: string }>(),
);

export const initPosExportFailure = action('[POS Exports] Init Failure');

export const getPosExports = action('[POS Exports] Load');

export const getPosExportsSuccess = action(
  '[POS Exports] Load Success',
  payload<{ posExports: TransactionExports[] }>(),
);

export const getPosExportsFailure = action('[POS Exports] Load Failure');
