/* global BackboneEx */
import { trackEvent } from 'common/analytics';

App.Views.Settings.ClientNotifications = BackboneEx.View.Main.extend({
  initialize() {
    this.render();
  },

  trackPageHasMovedClick() {
    trackEvent('client-notifications', 'click', 'page-have-moved');
  },

  render() {
    const $pageHasMoved = this.$('.page-has-moved');
    $pageHasMoved.html(
      Wahanda.Template.render(Wahanda.lang.settings.notifications.clientNotifications.hasMoved, {
        url: Wahanda.Url.getFullUrl('marketing', 'automated-messaging'),
      }),
    );
    $pageHasMoved.find('a').click(this.trackPageHasMovedClick);
  },
});
