import React from 'react';
import { Tooltip } from 'components/common/Tooltip';
import { Badge } from 'components/common/Badge';
import { Verification } from 'components/common/Icon';
import Wahanda from 'common/wahanda';
import style from './PaymentProtectionBadge.scss';

export const PaymentProtectionBadge = () => {
  const tooltip = (
    <span>
      <span
        dangerouslySetInnerHTML={{ __html: Wahanda.lang.paymentProtection.badge.tooltip.text }}
      />{' '}
      <a
        href={Wahanda.lang.paymentProtection.badge.tooltip.learnMoreUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={style.link}
      >
        {Wahanda.lang.paymentProtection.badge.tooltip.learnMore}
      </a>
    </span>
  );

  return (
    <Tooltip tooltip={tooltip}>
      <Badge
        text={Wahanda.lang.paymentProtection.badge.label}
        type="success"
        icon={<Verification size="tiny" className={style.icon} />}
      />
    </Tooltip>
  );
};
