/**
 * Collection for venue images.
 */
App.Collections.Bookings = BackboneEx.Collection.UrlFilter.extend({
  model: App.Models.Booking,
  urlTemplate: '/venue/{{venueId}}/bookings.json',

  filters: {
    arrivalDateFrom: null,
    arrivalDateTo: null,
    dateFrom: null,
    dateTo: null,
    evoucherRedeemedFrom: null,
    evoucherRedeemedTo: null,
    evoucherStatus: [],
    bookingStatus: [],
    bookingTypes: [],
    appointmentStatus: [],
    serviceTypeCode: null,
  },
  filterMap: {
    arrivalDateFrom: 'arrival-date-from',
    arrivalDateTo: 'arrival-date-to',
    dateFrom: 'date-from',
    dateTo: 'date-to',
    evoucherStatus: 'evoucher-status',
    bookingStatus: 'booking-status',
    bookingTypes: 'booking-types',
    appointmentStatus: 'appointment-status',
    serviceTypeCode: 'service-type-code',
    evoucherRedeemedFrom: 'evoucher-redeemed-from',
    evoucherRedeemedTo: 'evoucher-redeemed-to',
  },

  parse: function (data) {
    return data.bookings;
  },
});
