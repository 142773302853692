import { trackEvent, ACTIONS } from 'common/analytics';

export const CATEGORY = 'automated-messaging';

export const Analytics = {
  trackHowItWorksClick: (property: string) => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'how-it-works', property);
  },
  trackSettingChange: (property: string, value: boolean) => {
    const label = value ? 'enable' : 'disable';
    trackEvent(CATEGORY, ACTIONS.CLICK, label, property);
  },
  trackAreYouSureView: (property: string) => {
    trackEvent(CATEGORY, ACTIONS.VIEW, 'are-you-sure', property);
  },
  trackAreYouSureClose: (property: string) => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'are-you-sure-close', property);
  },
  trackAdvancedRemindersView: () => {
    trackEvent(CATEGORY, ACTIONS.VIEW, 'advanced-reminders-settings');
  },
  trackAdvancedRemindersAction: (property: string) => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'advanced-reminders-settings', property);
  },
};
