import React, { Component } from 'react';
import Wahanda from 'common/wahanda';
import ReactDialog from 'components/common/react-dialog';
import classnames from 'classnames';
import { OrderProducts } from 'common/analytics';
import Chevron from 'components/common/icons/Chevron';
import style from './style.scss';

const DIALOG_WIDTH = 440;
const lang = Wahanda.lang.menu.products.orders.selectSupplierDialog;
interface ISelectSupplierDialogProps extends React.HTMLAttributes<Element> {
  suppliers?: {
    id: number;
    name: string;
    selectedProductsIds: number[];
  }[];
  loading?: boolean;
  onClose: (...args: any[]) => any;
  actions: {
    selectProductOrderSupplierAction: (...args: any[]) => any;
    requestProductSuppliersAction: (...args: any[]) => any;
    resetProductSuppliersAction: (...args: any[]) => any;
  };
  selectProductOrderSupplierAction?: any;
  resetProductSuppliersAction?: any;
  requestProductSuppliersAction?: any;
}

export default class SelectSupplierDialog extends Component<ISelectSupplierDialogProps, {}> {
  static defaultProps = {
    suppliers: [],
    loading: false,
  };

  componentDidMount() {
    this.props.actions.requestProductSuppliersAction();
  }

  componentDidUpdate() {
    const { suppliers, loading } = this.props;
    if (loading) {
      return;
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const suppliersWithAssignedProducts = suppliers.filter(
      (supplier) => supplier.selectedProductsIds.length > 0,
    );
    const suppliersWithAssignedProductsCount = suppliersWithAssignedProducts.length;
    switch (suppliersWithAssignedProductsCount) {
      case 0: {
        OrderProducts.trackSuppliersAutoSelectNoSuppliers();
        this.props.actions.selectProductOrderSupplierAction();
        break;
      }
      case 1: {
        OrderProducts.trackSuppliersAutoSelect();
        this.props.actions.selectProductOrderSupplierAction(suppliersWithAssignedProducts[0]);
        break;
      }
      default:
        OrderProducts.trackSuppliersView();
    }
  }

  componentWillUnmount() {
    this.props.actions.resetProductSuppliersAction();
  }

  onSupplierClick = (supplier) => {
    OrderProducts.trackSupplierClick();
    this.props.actions.selectProductOrderSupplierAction(supplier);
  };

  closeDialog = () => {
    OrderProducts.trackSuppliersClose();
    this.props.onClose();
  };

  renderSupplier = (supplier) => {
    const hasAssignedProducts = supplier.selectedProductsIds.length > 0;
    const assignedProductsLabel = hasAssignedProducts
      ? Wahanda.Template.render(lang.assignedProducts, {
          count: supplier.selectedProductsIds.length,
        })
      : lang.noProductsAssigned;
    const onSupplierClick = () => this.onSupplierClick(supplier);
    const classes = classnames(style.supplier, {
      [style.disabled]: !hasAssignedProducts,
    });
    return (
      <div
        className={classes}
        key={supplier.id}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '((event: Mo... Remove this comment to see the full error message
        onClick={hasAssignedProducts ? onSupplierClick : null}
      >
        <div>
          <div className={style.name}>{supplier.name}</div>
          <div className={style.assignedProducts}>{assignedProductsLabel}</div>
        </div>
        {hasAssignedProducts && <Chevron color={style.chevronColor} />}
      </div>
    );
  };

  renderContent = () => {
    const { suppliers } = this.props;
    return (
      <div data-hj-suppress className={style.dialogContent}>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {suppliers.map(this.renderSupplier)}
      </div>
    );
  };

  render() {
    const { loading } = this.props;
    if (loading) {
      return null;
    }
    return (
      <ReactDialog
        dataTest="product-supplier-select-modal"
        title={lang.title}
        width={DIALOG_WIDTH}
        onClose={this.closeDialog}
        noContentPadding
      >
        {this.renderContent()}
      </ReactDialog>
    );
  }
}
