import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Team extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-team', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M3.815,14.5059345 C4.266,15.1029345 4.842,15.5699345 5.3,15.8839345 C3.655,16.9079345 3,18.7469345 3,20.9989345 L1,20.9989345 C1,18.2909345 1.897,15.9449345 3.815,14.5059345 Z M13.1853336,7.26258756 C14.4941502,9.26420252 14.2022992,11.9123815 12.489,13.5809345 C15.579,14.7549345 17,17.5909345 17,20.9989345 L15,20.9989345 C15,17.4989345 13.418,14.9989345 9,14.9989345 C6.60846006,14.9986709 4.55195721,13.3049104 4.09334576,10.9577549 C3.63473432,8.61059939 4.90243887,6.26732092 7.11798921,5.36687656 C9.33353955,4.46643219 11.876517,5.26097261 13.1853336,7.26258756 Z M9,6.99893449 C7.34314575,6.99893449 6,8.34208024 6,9.99893449 C6,11.6557887 7.34314575,12.9989345 9,12.9989345 C10.6568542,12.9989345 12,11.6557887 12,9.99893449 C12,8.34208027 10.6568542,6.99893449 9,6.99893449 Z M12.038,3.96993449 C14.1548693,2.41559313 17.1134779,2.76388053 18.8115259,4.76731397 C20.509574,6.77074741 20.3682611,9.7464321 18.488,11.5799345 C21.579,12.7559345 23,15.5909345 23,18.9989345 L21,18.9989345 C21,15.5289345 19.446,13.0429345 15.115,12.9989345 C15.44,12.3809345 15.773,11.5979345 15.845,10.8789345 C16.9226155,10.5628472 17.7335734,9.67154749 17.9467607,8.56895169 C18.1599479,7.46635588 17.7396847,6.33699911 16.8575639,5.64199614 C15.975443,4.94699317 14.7791,4.80266264 13.757,5.26793449 C13.283,4.66793449 12.612,4.24793449 12.038,3.96993449 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Team);
