/* eslint-disable prefer-rest-params */
/* eslint-disable no-restricted-globals */
/* eslint-disable func-names */
/* global BackboneEx _ WorkingHoursCache */

/**
 * Calendar.
 *
 * Events triggered on this view:
 * > change:type
 * > change:resourceId
 * > change:date
 */
// Let's keep the Cache creation import at the top so that it can
// bind first to WebSockets events, etc.
import * as Sentry from '@sentry/browser';
import createCalendarCache from 'utilities/CalendarCache';
import moment from 'common/moment';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import setupWebSocketsListener from 'src/websockets/calendar/listener';
import { setup as setupWebSocketsRefreshHandler } from 'src/websockets/calendar/refresh-handler';
import { trackEvent, CancellationFlowAnalytics } from 'common/analytics';
import { RotaModalType } from 'components/shifts/utils/types';
import {
  EMPLOYEE_WORKING_HOURS_TOGGLE_FEATURE,
  EMPLOYEE_WORKING_HOURS_TOGGLE_STORAGE_KEY,
} from 'components/calendar/EmployeeWorkingHoursToggle';

App.Views.Calendar.AppointmentCalendar = BackboneEx.View.Main.extend({
  events: {
    'change .views-control': 'onViewTypeChange',
    'click .get-back': 'onBackButtonPressed',
    'click .go-to-employees': 'redirectToEmployees',
    'click #employee-ext-calendar-sync': 'triggerCalendarRefresh',
  },

  calendarViewType: 'appointment',
  // Lazy fetching mode checks if the requested parameters are same as currently loaded, and does not fetch new
  // data if it matches.
  lazyFetchingMode: false,
  uiEventsEnabled: true,
  focusedAppointmentId: null,
  calendarObjectsPromise: null,
  useLoadedAPIData: false,
  allowRefresh: true,
  allowHeaderNavigation: true,
  // Where the fetch originated from: maybe websockets, or the default.
  fetchRequestSource: null,

  newRotaEmployeeId: null,
  newRotaDate: null,
  showRotaActionModal: true,

  dragState: null,
  hideNonWorking: false,

  initialize: function () {
    const self = this;
    const opts = this.options.options || {};
    this.hideNonWorking =
      App.isFeatureSupported(EMPLOYEE_WORKING_HOURS_TOGGLE_FEATURE) &&
      !!Wahanda.LocalStorage.get(EMPLOYEE_WORKING_HOURS_TOGGLE_STORAGE_KEY);
    this.setupTypeChangeListening();
    this.appointmentToScrollTo = null;
    this.rescheduleKeyHandlerFunc = this.rescheduleKeyHandler.bind(this);
    this.currentOptions = {
      type: opts.initialType || 'day',
      date: opts.initialDate,
      resourceId: opts.initialResourceId || 'all',
    };
    this.calendar = this.$('#bcalendar-inst');
    // These event bindings must be first, before other objects will bind
    this.on('change:date', function (date) {
      // Trigger global date change event. Will set this objects date too.
      App.trigger('calendar:date-change', date);
      self.setCalendarDate(date);
    })
      .on('change:type', function (type) {
        self.currentOptions.type = type;
        self.changeCalendarType(type);
        App.trigger(Wahanda.Event.CALENDAR_DISPLAY_TYPE_CHANGE, { type: type });
        self.toggleAppointmentButtons();
      })
      .on('change:resourceId', function () {
        self.setCalendarResource();
      });

    // The Menu's values here are forwarded to the Wahanda.Cache object,
    // as this model will be set up with data from the Cached Menu.
    this.model = new App.Models.Menu({
      filter: 'ACT',
      serviceTypes: ['T', 'P', 'Y'],
    });

    this.employeesCollection = new App.Collections.Employees();
    this.employeesCollection.onlyTakingAppointments = true;
    this.employeesCollection.include = ['employee-offers'];

    // TODO: move this to calendar-view
    this.headerPaneView = new App.Views.Calendar.HeaderPaneBase({
      calendarView: this,
      el: this.$('.calendar-pane'),
      employees: this.employeesCollection,
      mainView: this.options.mainView,
      date: this.currentOptions.date,
    });

    this.datepickerView = new App.Views.Calendar.DatePicker({
      calendarView: this,
      el: this.$('#calendar-datepicker'),
    });
    this.calendarBlockTooltipView = new App.Views.Calendar.BlockTooltip({
      groups: this.model.get('groupsCollection'),
      employees: this.employeesCollection,
    });
    this.calendarAppointmentTooltipView = new App.Views.Calendar.AppointmentTooltip({
      groups: this.model.get('groupsCollection'),
      onMouseLeave: () => this.onEventMouseLeave(),
    });
    this.alertsView = new App.Views.Calendar.AppointmentAlerts({
      calendarView: this,
      el: this.$('#calendar-alerts'),
    });

    // Set up the calendar fetcher
    this.calendarCache = createCalendarCache();
    // The instance will be re-created with data from `this.calendarCache` do
    // do not depend in the same instance (e.g. don't bind events).
    this.calendarObjects = new App.Models.CalendarObjects();

    this.on('change:type change:resourceId change:date', function () {
      self.updateUrlIfDiffers();
    });

    const wEvent = Wahanda.Event;
    App.on(
      `${wEvent.APPOINTMENT_SAVED} ${wEvent.APPOINTMENT_GROUP_SAVED}`,
      _.debounce(() => {
        if (this.allowRefresh) {
          this.calendarCache.invalidate();
          this.refreshMainCalendar();
        }
      }, 1000),
    )
      .on(
        [
          // Customer events
          wEvent.CUSTOMER_SAVED,
          // Time block events
          wEvent.CALENDAR_BLOCK_TIME_SAVED,
          wEvent.CALENDAR_BLOCK_TIME_DELETED,
          // Appointment events
          wEvent.APPOINTMENT_CONFIRMED,
          wEvent.APPOINTMENT_REJECTED,
          wEvent.APPOINTMENT_CANCELLED,
          wEvent.APPOINTMENT_GROUP_CONFIRMED,
          wEvent.APPOINTMENT_GROUP_REJECTED,
          wEvent.APPOINTMENT_GROUP_SET_NOSHOW,
          wEvent.APPOINTMENT_GROUP_CANCELLED,
          wEvent.CALENDAR_WORK_TIME_SAVED,
          wEvent.TRANSACTION_CANCELLED,
          wEvent.APPOINTMENT_SET_NOSHOW,
        ].join(' '),
        () => {
          if (this.allowRefresh) {
            this.calendarCache.invalidate();
            this.refreshMainCalendar();
          }
        },
      )
      .on(wEvent.APPOINTMENT_GROUP_SAVED, function (apptGroup, savedApptObject) {
        if (apptGroup.id) {
          window.setTimeout(
            () => self.checkPackageRescheduleSuccess(apptGroup, savedApptObject),
            4000,
          );
        }
      })
      .on('calendar:objects-rendered', function () {
        self.scrollToAppointment();
      })
      .on('calendar:date-change', function (date) {
        // Save date changes from other calendars
        self.currentOptions.date = date;
        const shouldShowNonWorking = !self.hideNonWorking;

        if (!self.isDayView() || shouldShowNonWorking) {
          return;
        }

        window.setTimeout(() => {
          // Hack to redraw based on "hide non working" filter
          self.updateCalendar();
          self.render();
          self.calendar.resourceCalendar('refreshNonWorkSlots');
        });
      })
      .on(wEvent.CALENDAR_RESOURCE_CHANGE, function (resourceId) {
        self.onResourceChange(resourceId);
      })
      .on(wEvent.APPOINTMENT_FORM_SUBMIT, function () {
        self.showLoader();
      })
      .on(wEvent.APPOINTMENT_FORM_ERRORS, function () {
        self.hideLoader();
      })
      .on(wEvent.SHIFT_MODAL_CLOSE, function () {
        self.showRotaActionModal = false;
      })
      .on(wEvent.EMPLOYEE_WORKING_HOURS_TOGGLE, function (payload) {
        self.toggleNonWorking(payload);
      });

    this.setupRequestListener();

    this.rendered = false;
  },

  setupRequestListener: function () {
    const self = this;
    App.on(
      Wahanda.Event.CALENDAR_REQUEST_REFRESH,
      this.ifActive(function () {
        self.refreshMainAndEmployeeExtCalendar(true);
      }),
    )
      .on(
        Wahanda.Event.CALENDAR_REQUEST_REFRESH_MAIN,
        this.ifActive(function (options) {
          if (
            options &&
            options.date &&
            !Wahanda.Date.isEqualDates(options.date, self.currentOptions.date)
          ) {
            self.trigger('change:date', options.date);
          } else {
            self.refreshMainCalendar();
          }
        }),
      )
      .on(
        'calendar:request-resource-view',
        this.ifActive(function (position) {
          self.showResourceInWeekView(position);
        }),
      )
      .on(Wahanda.Event.APP_LOADED, function () {
        self.businessHours = null;
        self.calendarCache.invalidate();
      });
  },

  onCalendarTypeChange: function () {
    if (this.isActive()) {
      this.showLoader();
      this.reloadModels();
    }
    this.toggleAppointmentButtons();
  },

  reloadModels: async function () {
    await this.employeesCollection.fetch();

    const [menuFiltered] = await Promise.all([
      Wahanda.Cache.menuFiltered({
        filterType: this.model.get('filter'),
        includedServiceTypes: this.model.get('serviceTypes'),
      }),
      this.loadWorkingHours(),
    ]);

    this.model.copyFrom(menuFiltered);
    this.onResourcesLoaded();
    this.render(true);
  },

  loadWorkingHours: function () {
    const currentDate = this.currentOptions.date;
    const employeeIds = this.getVisibleResourceIds();
    let openingTimesPromise;
    if (!this.rendered || !WorkingHoursCache.isCached(currentDate, currentDate, employeeIds)) {
      openingTimesPromise = WorkingHoursCache.get(currentDate, currentDate, employeeIds).done(
        function (openingHours) {
          this.venueOpeningTimes = openingHours;
        }.bind(this),
      );
    }

    return openingTimesPromise;
  },

  render: function (isInitialRender) {
    // Prefetch for Appointment dialog
    Wahanda.Cache.menu();
    Wahanda.Cache.employees();
    Wahanda.Cache.employeeCategories();

    if (!isInitialRender && !this.rendered) {
      // Do not render if not all data is loaded
      return;
    }

    this.$el.removeClass('loading');

    if (!this.hasPermissionsOrTakesAppointments()) {
      this.setNoPermissionsAndNoServices(true);
      this.hideLoader();
      return;
    }

    this.setNoPermissionsAndNoServices(false);

    // Render happens on initial load, and on venue change in the header.
    // We want to scroll to the current time on these occasions.
    this.scrollToActiveTime = true;

    this.toggleEmpty();
    this.setCurrencyTheme();

    this.checkCalendarResourceValidity();

    if (!this.rendered) {
      this.renderCalendar();
      this.rendered = true;

      if (!App.isRestrictedMode()) {
        setupWebSocketsListener(this);
        setupWebSocketsRefreshHandler();
      }
    } else if (isInitialRender) {
      this.updateCalendar();
      this.refreshMainCalendar();
    }

    this.checkCalendarActionModeStartFromRoute();

    this.headerPaneView.render();
    this.datepickerView.render();
    this.toggleAppointmentButtons();

    // We might have hidden some panes. Need to recalculate the height.
    this.calendar.resourceCalendar('updateHeight');
  },

  checkCalendarActionModeStartFromRoute: function () {
    const action = App.mainViewOptions.calendarAction;
    const strList = App.mainViewOptions.stringListForAction;
    const customerId = App.mainViewOptions.customerToRescheduleFor;
    const rebookingDate = App.mainViewOptions.rebookingDate;

    if (!action || !strList) {
      return;
    }

    App.mainViewOptions.stringListForAction = null;
    App.mainViewOptions.calendarAction = null;
    App.mainViewOptions.customerToRescheduleFor = null;
    App.mainViewOptions.rebookingDate = null;

    function iterateIdList(callback) {
      strList.split(';').forEach(function (part) {
        // a:apptId or ag:apptGroupId
        const data = part.split(':');

        callback({
          appointmentId: data[0] === 'a' ? data[1] : undefined,
          appointmentGroupId: data[0] === 'ag' ? data[1] : undefined,
        });
      });
    }

    function pickItemsFromCollectionByIds(dayCollection) {
      const theList = [];

      iterateIdList(function (idData) {
        let item;

        if (idData.appointmentId) {
          item = dayCollection.get({ id: idData.appointmentId });
        } else {
          item = dayCollection.getAppointmentGroup({
            id: parseInt(idData.appointmentGroupId, 10),
          });
        }

        // Might be possible that the Appointment was moved to another date. So check if it exists.
        if (item) {
          theList.push(item);
        }
      });

      return theList;
    }

    const dayCollection = new App.Collections.CustomerAppointments();
    dayCollection.date = rebookingDate || moment(this.currentOptions.date).formatApiDateString();
    dayCollection.customerId = customerId;
    dayCollection.utmSource = `calendar-${action}`;

    let firstParam = [];

    dayCollection.fetch().done(
      function () {
        let method;

        switch (action) {
          case 'reschedule':
            method = 'startReschedulingMode';
            firstParam = pickItemsFromCollectionByIds(dayCollection);
            break;
          case 'rebooking':
            method = 'startRebookingMode';
            firstParam = dayCollection;
            break;
          default:
            // Unknown action
            return;
        }

        this[method](firstParam, {
          initialPosition: {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
          },
          dayCollection: dayCollection,
        });
      }.bind(this),
    );
  },

  /**
   * If the calendar is already set up, this destroys and renews the Calendar's Employee information.
   */
  checkCalendarResourceValidity: function () {
    const resCalendarInstance = this.calendar.data('resourceCalendar');
    if (resCalendarInstance) {
      resCalendarInstance.destroyResources(this.getResourceIdForCalendar());
      // A hack-ish way to forcefully update the calendar resources
      resCalendarInstance.loadResources(false);
    }
  },

  hasPermissionsOrTakesAppointments: function () {
    if (!Wahanda.Permissions.useCalendar()) {
      const myEmployee = this.employeesCollection.get(App.config.getAccountEmployeeId());
      return myEmployee && myEmployee.canBeBooked();
    }
    return true;
  },

  setCurrencyTheme: function () {
    this.$el.addClass(this.getCurrencyClass());
  },

  getCurrencyClass: function () {
    // Return a styling class if CHF or euro.
    switch (App.config.get('currency').currencyCode) {
      case 'EUR':
        return 'currency-theme-eur';
      case 'CHF':
        return 'currency-theme-CHF';
      case 'DKK':
        return 'currency-theme-DKK';
      default:
        return '';
    }
  },

  setNoPermissionsAndNoServices: function (disabled) {
    this.$el.toggleClass('empty no-permissions', disabled);
  },

  getCanShowAddAppointmentButton: function () {
    let employeeId = null;
    if (!this.isDayView()) {
      employeeId = this.getResourceIdForCalendar();
    }

    return this.canModifyAppointmentsAndBlocks(employeeId);
  },

  /**
   * Checks if the current user can do Add/Edit/Delete actions on Appointments and Blocks.
   *
   * @param int|null forEmployeeId (optional) Employee id for which to check if the action can be performed. Defaults
   *      to the one selected in the resources select box.
   *    If this param == null and is dayView, it means that it's a block for the whole venue.
   *
   * @return boolean
   */
  canModifyAppointmentsAndBlocks: function (forEmployeeId) {
    let employeeId = forEmployeeId;
    if (Wahanda.Permissions.editAnyCalendar()) {
      return true;
    }
    if (arguments.length === 0 || (!this.isDayView() && employeeId == null)) {
      employeeId = this.getResourceIdForCalendar();
    }

    return (
      Wahanda.Permissions.editOwnCalendar() && App.config.getAccountEmployeeId() === employeeId
    );
  },

  showLoader: function () {
    this.$('.section-main').loadmask({
      position: 'bottom-right',
      text: null,
    });
  },

  hideLoader: function () {
    this.$('.section-main').unloadmask();

    if (this.loaderHideCallbacks) {
      this.loaderHideCallbacks.forEach(function (callback) {
        callback();
      });
      this.loaderHideCallbacks = null;
    }
  },

  // Queue callbacks to run when the loader is hidden
  onNextLoaderHide: function (callback) {
    if (!this.loaderHideCallbacks) {
      this.loaderHideCallbacks = [];
    }
    this.loaderHideCallbacks.push(callback);
  },

  /**
   * Toggles "empty" class on whole calendar view.
   */
  toggleEmpty: function () {
    const allClasses = 'empty no-employees';
    this.$el.removeClass(allClasses);
    this.$el.toggleClass('empty no-employees', this.employeesCollection.length === 0);
  },

  withRaceConditionsPrevented: (function () {
    // Race condition prevention. Only the last one is executed.
    const state = {};

    return function withRaceConditionsPrevented(key, fn) {
      const timestamp = new Date().getTime();

      state[key] = timestamp;

      return function () {
        if (timestamp === state[key]) {
          return fn.apply(this, arguments);
        }
        return null;
      };
    };
  })(),

  renderCalendar: function () {
    const self = this;
    let inNewCreation = false;

    /* eslint-disable no-unused-vars */
    /* eslint-disable no-unused-expressions */
    this.calendar.resourceCalendar({
      date: this.currentOptions.date,
      data: function (from, to, resourceId, renderFunction) {
        self.getCalendarData(from, to, resourceId, renderFunction);

        App.on(Wahanda.Event.APPOINTMENT_CHECKED_OUT, ({ checkedoutAppointmentIds }) => {
          self.calendarObjects.checkOutAppointments(checkedoutAppointmentIds);
          renderFunction(
            self.calendarObjects.getInCalendarFormat(
              self.getFormatOptions({ resourceId }),
              self.model.get('offersCollection'),
            ),
          );
        });
      },
      srcResources: function (renderCallback) {
        self.getCalendarResources(self.withRaceConditionsPrevented('resources', renderCallback));
      },
      resourceWorkTime: function (from, to, renderFunction) {
        self.getCalendarWorkingHours(self.withRaceConditionsPrevented('worktime', renderFunction));
      },
      dayView: this.isDayView(),
      dateFormat: App.config.get('jqueryDateFormat').mediumDate,
      timeFormat: App.config.get('jqueryDateFormat').defaultTime,
      useShortDayNames: true,
      buttons: false,
      resourceID: this.getResourceIdForCalendar(),
      dayViewEnabled: true,
      shortDays: Wahanda.lang.date.weekdaysShort,
      shortMonths: Wahanda.lang.date.monthsShort,
      hoverEvents: !(Wahanda.Util.isMobile() || Wahanda.Device.isIOS()),
      beforeDestroyCalendar: () => this.storeScrollPositionBeforeRedraw(),
      onViewChange: function () {
        self.syncWithCalendar(this);
        self.restoreScrollPosition();
      },
      use24Hour: App.config.get('jqueryDateFormat').use24h,
      timeslotsPerHour: this.getTimeSlotCount(),
      timeslotHeight: this.getTimeSlotHeight(this.currentOptions.date),
      timeslotsPerHourWhenDragging: 60 / 5,
      defaultEventLength: 30,
      allowCalEventOverlap: true,
      overlapEventsSeparate: true,
      firstDayOfWeek:
        Wahanda.Date.dayStringToNumberMap[App.config.get('jqueryDateFormat').firstDayOfWeek],
      resizable: function (calEvent, element) {
        return false;
      },
      draggable: function (calEvent, options) {
        if (calEvent.draggable === 'only-rid') {
          return options.dayView;
        }
        return calEvent.draggable;
      },
      businessHours: this.getCalendarTimeRange(),
      daysToShow: 7,
      height: function ($calendar) {
        return self.$('.wc-container').height();
      },
      eventNewStart: function () {
        if (self.uiEventsEnabled) {
          self.uiEventsEnabled = false;
          inNewCreation = true;
        }
      },
      eventNew: function (calEvent, element, options) {
        if (!inNewCreation && !self.uiEventsEnabled) {
          return;
        }

        App.trigger(Wahanda.Event.CALENDAR_NEW_APPOINTMENT);

        self.uiEventsEnabled = true;
        inNewCreation = false;

        App.ES6.Initializers.State.change({
          'calendar-event-editor': {
            appointmentViewData: self.getAppointmentViewData(calEvent),
            blockViewData: self.getBlockEditViewData(calEvent),
          },
        });
      },
      eventClick: function (calEvent, $td, event) {
        if (self.uiEventsEnabled && calEvent) {
          trackEvent('calendar', 'click', 'appointment');
          self.onItemClicked(calEvent, $td);
          const type = self.isDayView() || (self.getCalendarResourcesCount() === 1 && 'venue');
          self.shiftModalDestroy(type);
        }
      },
      dragHelper: this.onGetDragHelper.bind(this),
      eventDrag: this.onDragStart.bind(this),
      eventDrop: this.onDropEvent.bind(this),
      eventDeactivate: this.onDeactivateEvent.bind(this),
      eventDragStop: this.onDragStop.bind(this),
      eventResize: function (calEvent, $event) {},
      eventMouseEnter: function (calEvent, $calEvent, event) {
        self.uiEventsEnabled && calEvent && self.onEventMouseEnter(calEvent, $calEvent, event);
      },
      eventMouseLeave: function (calEvent, $calEvent, event) {
        self.uiEventsEnabled && calEvent && self.onEventMouseLeave(calEvent);
      },
      eventClickNonWork: function (slot, event) {
        // Non-working slots are only editable in day view, or only single employee/menu group
        if (self.isDayView() || self.getCalendarResourcesCount() === 1) {
          self.uiEventsEnabled && self.onNonWorkSlotClick(slot, event, 'venue');
        } else if (
          !self.isDayView() &&
          App.isFeatureSupported('venue-employee-rota') &&
          Wahanda.Permissions.editRotaCalendar(slot.data('calEvent').rid)
        ) {
          self.uiEventsEnabled && self.onNonWorkSlotClick(slot, event, 'employee');
        }
      },
      noEvents: function () {},
      isHeaderNavigationEnabled: function () {
        if (!self.allowHeaderNavigation) {
          return false;
        }
        return self.employeesCollection.length > 1;
      },
      afterEventsCleared: function () {
        self.calendarBlockTooltipView && self.calendarBlockTooltipView.hide();
        self.calendarAppointmentTooltipView && self.calendarAppointmentTooltipView.hide();
      },
      calendarAfterLoad: function () {
        self.$('.js-column-collapse-button').wShow();
        if (self.afterCalendarRendered) {
          const fn = self.afterCalendarRendered;
          self.afterCalendarRendered = null;
          fn.call(self);
        }

        if (self.scrollToActiveTime) {
          self.scrollToActiveTime = false;
          self.scrollToValidHour();
        }

        $('.wc-scrollable-grid').on('scroll', () => {
          const type = self.isDayView() || (self.getCalendarResourcesCount() === 1 && 'venue');
          self.shiftModalDestroy(type);
        });
      },
    });
    /* eslint-enable no-unused-vars */
    /* eslint-enable no-unused-expressions */

    // Refresh every X minutes, based on the API param
    App.Timer.on('calendar-refresh', function () {
      if (self.isActive() && self.allowRefresh) {
        self.refreshMainCalendar();
      }
    });
    // Start autoupdating of calendar time
    this.calendar.resourceCalendar('autoUpdateMinutes');
  },

  storeScrollPositionBeforeRedraw() {
    this.storedCalendarScroll = this.$('.wc-scrollable-grid').prop('scrollTop');
  },

  /**
   * We want to restore the saved scroll position as otherwise the calendar
   * stays at scroll position 0 (e.g. top) after redraw and this results in
   * poor UX.
   */
  restoreScrollPosition() {
    if (!this.storedCalendarScroll) {
      return;
    }
    const pos = this.storedCalendarScroll;
    this.storedCalendarScroll = null;

    this.$('.wc-scrollable-grid').scrollTop(pos);
  },

  onGetDragHelper: function (event) {
    const $calEvent = $(event.currentTarget);
    const $clone = $calEvent.clone();

    // Reposition the event left to parent cell so that the helper is there too
    $calEvent.css('left', 0);

    // Move the node to parent to be on top of other elements
    const $grid = this.$('.wc-scrollable-grid');
    const gridLeft = $grid.offset().left;
    const ownLeft = $calEvent.offset().left;
    const cellWidth = $calEvent.parent().width() - 1;

    // When moving appt count the full duration height
    if ($calEvent.data('calEvent').type !== 'block') {
      const appointment = this.calendarObjects.getAppointment($calEvent.data('calEvent').id);
      const duration = appointment.getRescheduleDuration();
      const height = this.calendar.resourceCalendar('calculateHeightFromDuration', duration);
      $clone.addClass('is-being-dragged').css({ height });
    }

    // Make the helper take up the whole width of the cell.
    $clone.addClass('is-being-dragged').css({
      left: ownLeft - gridLeft + $grid[0].scrollLeft,
      width: cellWidth,
    });

    return $clone;
  },

  onDragStart: function (calEvent, $calEvent) {
    this.onEventMouseLeave(calEvent);
    this.uiEventsEnabled = false;
    // Hide the original item from calendar
    $($calEvent.context).hide();
    // Also hide it's processing and finishing bar, if any
    $(`#wc-processing-${calEvent.id}`).remove();
    $(`#wc-finishing-${calEvent.id}`).remove();
    // Disable all refreshing
    this.allowRefresh = false;
    this.dragState = 'started';
    App.trigger(
      calEvent.type === 'block'
        ? Wahanda.Event.TIME_BLOCK_DRAG_STARTED
        : Wahanda.Event.APPOINTMENT_DRAG_STARTED,
    );

    if (calEvent.mightRepeat) {
      this.$(`.cal-block-${calEvent.id}`).addClass('related-to-dragged');
    }
  },

  getRescheduleComparisonData: function (oldDate, newDate, oldEmployeeId, newEmployeeId) {
    function getDateTimeFormatted(date) {
      return Wahanda.Date.formatDateTime(
        App.config.get('jqueryDateFormat').longDate,
        App.config.get('jqueryDateFormat').defaultTime,
        date,
      );
    }

    return {
      oldDateTime: getDateTimeFormatted(oldDate),
      newDateTime: getDateTimeFormatted(newDate),
      oldEmployee: this.employeesCollection.get(oldEmployeeId).get('name'),
      newEmployee: this.employeesCollection.get(newEmployeeId).get('name'),
    };
  },

  // Get changes for analytics tracking. Send what has been changed with this DnD/OneClickReschedule.
  getRescheduleChangeStringFromComparison: function (comparison) {
    const changes = [
      comparison.oldDateTime.time === comparison.newDateTime.time ? null : 'time',
      comparison.oldDateTime.date === comparison.newDateTime.date ? null : 'date',
      comparison.oldEmployee === comparison.newEmployee ? null : 'employee',
    ];

    return _.filter(changes, function (check) {
      return !!check;
    }).join(',');
  },

  onDeactivateEvent: function () {
    this.uiEventsEnabled = true;
  },

  onDropEvent: function (newCalEvent, oldCalEvent, $newEvent, revertFn) {
    this.uiEventsEnabled = true;
    this.dragState = null;

    $(`#wc-finishing-${newCalEvent.id}`).removeClass('wc-cal-event');

    function sendTrackingEvent(data) {
      App.trigger(
        oldCalEvent.type === 'block'
          ? Wahanda.Event.TIME_BLOCK_DRAG_ENDED
          : Wahanda.Event.APPOINTMENT_DRAG_ENDED,
        data || 'no-changes',
      );
    }

    if (
      newCalEvent.start.getTime() === oldCalEvent.start.getTime() &&
      newCalEvent.rid === oldCalEvent.rid
    ) {
      // No changes here.
      this.allowRefresh = true;

      if (oldCalEvent.mightRepeat) {
        this.$(`.cal-block-${oldCalEvent.id}`).removeClass('related-to-dragged');
      }

      sendTrackingEvent();

      return;
    }

    const data = this.getRescheduleComparisonData(
      oldCalEvent.start,
      newCalEvent.start,
      oldCalEvent.rid,
      newCalEvent.rid,
    );

    sendTrackingEvent(this.getRescheduleChangeStringFromComparison(data));

    if (newCalEvent.type === 'appointment') {
      this.onAppointmentDropEvent(newCalEvent, revertFn, data, oldCalEvent);
    } else {
      this.onBlockDropEvent(newCalEvent, revertFn, data, oldCalEvent);
    }
  },

  onAppointmentDropEvent: function (newCalEvent, revertFn, data) {
    const appointment = this.calendarObjects.getAppointment(newCalEvent.id);

    this.showConfirmAppointmentReschedule({
      appointment: appointment,
      newStartDate: newCalEvent.start,
      newEmployeeId: newCalEvent.rid,
      data: data,
      revertFn: revertFn,
    });
  },

  /**
   * Show the appointment reschedule confirmation dialog.
   *
   * @param Object params { appointment, newStartDate, newEmployeeId, data, revertFn, dayCollection, fullReschedule }
   *
   * @returns $.Promise if the reschedule was confirmed or cancelled
   */
  showConfirmAppointmentReschedule: function (params) {
    const appointment = params.appointment;
    const newStartDate = params.newStartDate;
    const newEmployeeId = params.newEmployeeId;
    const data = params.data;
    const revertFn = params.revertFn;
    const fullReschedule = params.fullReschedule;
    const dayCollection = params.dayCollection;

    const lang = Wahanda.lang.calendar.dragConfirmation;
    const drd = new $.Deferred();

    function doRevert() {
      drd.reject();
      revertFn();
    }

    // Show checkbox asking to reschedule all appointments only if the day contains multiple appts
    const checkbox =
      dayCollection && dayCollection.getSeparateItemCount() > 1 ? { text: lang.checkboxAll } : null;

    App.ES6.Initializers.State.change({
      'calendar-drag-confirmation': {
        oldData: {
          date: data.oldDateTime.date,
          time: data.oldDateTime.time,
          employeeName: data.oldEmployee,
        },
        newData: {
          date: data.newDateTime.date,
          time: data.newDateTime.time,
          employeeName: data.newEmployee,
        },
        notes: [
          appointment.getLinkedPackageGroup() && data.oldDateTime.date !== data.newDateTime.date
            ? lang.otherPackageAppointmentMove
            : null,
          appointment.isUnconfirmed() ? lang.confirmNote : null,
        ],
        buttons: [
          {
            title: appointment.isUnconfirmed()
              ? Wahanda.lang.calendar.appointments.multi.buttons.saveConfirm
              : Wahanda.lang.shared.save,
            primary: true,
            onClick: function onClickMove(closeData) {
              this.onConfirmDragResult(
                appointment,
                newStartDate,
                newEmployeeId,
                closeData && closeData.checkboxChecked ? dayCollection : null,
                doRevert,
                fullReschedule,
              );
              drd.resolve();
              App.trigger(Wahanda.Event.APPOINTMENT_DRAG_AND_DROP_SUBMITED);
            }.bind(this),
          },
          {
            title: Wahanda.lang.shared.cancel,
            onClick: () => {
              doRevert();
              App.trigger(Wahanda.Event.APPOINTMENT_DRAG_AND_DROP_CALCELED);
            },
          },
        ],
        checkbox: checkbox,
        onClose: function onClose(closeData) {
          this.allowRefresh = true;
          if (!closeData || !closeData.afterButtonAction) {
            // No button has closed this form (e.g. ESC) - need to revert.
            doRevert();
          }
        }.bind(this),
      },
    });

    return drd.promise();
  },

  onConfirmDragResult: function (
    appointment,
    newDate,
    newEmployeeId,
    dayCollection,
    revertFn,
    fullReschedule,
  ) {
    this.showLoader();
    Wahanda.FloatingEventHandler.moveFloatingEvent(
      appointment,
      newDate,
      newEmployeeId,
      dayCollection,
      fullReschedule,
    ).fail(
      function (maybeError) {
        revertFn();
        this.hideLoader();
        App.trigger(Wahanda.Event.APPOINTMENT_DRAG_REVERTED);

        if (maybeError instanceof Error && maybeError.type === 'overlap-into-next-day') {
          Wahanda.Dialogs.Alert(Wahanda.lang.calendar.reschedule.errors.overlapsIntoAnotherDay);
        }
      }.bind(this),
    );
  },

  onBlockDropEvent: function (newCalEvent, revertCallback, data, oldCalEvent) {
    const lang = Wahanda.lang.calendar.dragConfirmation;
    const block = new App.Models.TimeBlock(
      this.calendarObjects.getBlock(newCalEvent.id, oldCalEvent.start),
    );
    const isRecurring = block.isRecurring();

    const revertFn = function () {
      App.trigger(Wahanda.Event.TIME_BLOCK_DRAG_REVERTED);
      revertCallback();

      if (oldCalEvent.mightRepeat) {
        this.$(`.cal-block-${oldCalEvent.id}`).removeClass('related-to-dragged');
      }
    }.bind(this);

    let buttons;
    if (isRecurring) {
      buttons = [
        {
          title: lang.block.buttonThis,
          primary: true,
          onClick: function onClickMoveThis() {
            this.onConfirmBlockDragResult(newCalEvent, block, 'D', revertFn);
          }.bind(this),
        },
        {
          title: lang.block.buttonAll,
          onClick: function onClickMoveAll() {
            this.onConfirmBlockDragResult(newCalEvent, block, 'F', revertFn);
          }.bind(this),
        },
      ];
    } else {
      buttons = [
        {
          title: Wahanda.lang.shared.save,
          primary: true,
          onClick: function onClickMove() {
            this.onConfirmBlockDragResult(newCalEvent, block, 'D', revertFn);
          }.bind(this),
        },
      ];
    }
    buttons.push({
      title: Wahanda.lang.shared.cancel,
      onClick: revertFn,
    });

    const isDateChangeable = block.isSingleDay();

    App.ES6.Initializers.State.change({
      'calendar-drag-confirmation': {
        oldData: {
          date: isDateChangeable && data.oldDateTime.date,
          time: data.oldDateTime.time,
        },
        newData: {
          date: isDateChangeable && data.newDateTime.date,
          time: data.newDateTime.time,
        },
        notes: [
          isRecurring ? lang.block.recurringNote : null,
          block.forAllEmployees() ? lang.block.allEmployeeNote : null,
        ],
        buttons: buttons,
        onClose: function onClose(dataOnClose) {
          this.allowRefresh = true;
          if (!dataOnClose || !dataOnClose.afterButtonAction) {
            // No button has closed this form (e.g. ESC) - need to revert.
            revertFn();
          }
        }.bind(this),
      },
    });
  },

  onConfirmBlockDragResult: function (newCalEvent, block, type, revertFn) {
    Wahanda.FloatingEventHandler.rescheduleBlock(block, type, newCalEvent.start, newCalEvent.end)
      .done(this.refreshMainCalendar.bind(this))
      .fail(revertFn);
  },

  onDragStop: function (event, ui) {
    if (this.dragState === 'started') {
      // The dragState should be null. If this is still started, then the drop was done outside the calendar's bounds.
      // We need to show the original calEvent. Helper is removed after this.
      $(ui.helper.context).show();
      this.dragState = null;
    }
  },

  getCalendarWorkingHours: function (renderFunction) {
    const self = this;
    const dates = this.getVisibleDateRange();
    const employeeIds = this.getVisibleResourceIds();

    function renderWithOpeningHours() {
      const workingHours = self.venueOpeningTimes.getInCalendarFormat(self.getVisibleResourceIds());
      self.adjustVisibleCalendarTimeRange(workingHours);
      renderFunction(workingHours);
    }

    function callback() {
      if (self.calendarObjectsPromise) {
        self.calendarObjectsPromise.then(renderWithOpeningHours, () => {});
      } else {
        renderWithOpeningHours();
      }
    }

    WorkingHoursCache.get(dates.from, dates.to, employeeIds).done(function (openingHours) {
      self.venueOpeningTimes = openingHours;
      callback();
    });
  },

  adjustVisibleCalendarTimeRange: function (workingHours) {
    const businessStartTime = Wahanda.Time.timeToMinutes(App.config.get('venue').dayStartsAt);
    const businessEndTime = Wahanda.Time.timeToMinutes(App.config.get('venue').dayEndsAt);

    let startMins = businessStartTime;
    let endMins = businessEndTime;

    _.each(workingHours, function (item) {
      const itemStart = Wahanda.Time.getDateMinutes(item.start);
      const itemEnd = Wahanda.Time.getDateMinutes(item.end);

      if (itemStart === 0 && itemEnd === 0) {
        return;
      }

      startMins = Math.min(startMins, itemStart);
      endMins = Math.max(endMins, itemEnd);
    });

    const startTime = Wahanda.Time.minutesToTimeHash(startMins).hours;
    const end = Wahanda.Time.minutesToTimeHash(endMins);
    const endTime = end.hours + (end.hours < 24 && end.minutes > 0 ? 1 : 0);

    if (
      this.businessHours &&
      this.businessHours.start === startTime &&
      this.businessHours.end === endTime
    ) {
      // No need to update, times are the same.
      return;
    }

    this.businessHours = {
      start: startTime,
      end: endTime,
    };
    this.calendar.resourceCalendar('option', {
      businessHours: this.getCalendarTimeRange(),
    });

    // Request a re-render with the same data after the current render we are in will finish
    this.afterCalendarRendered = this.rerenderWithExistingData;
  },

  /**
   * Force rerendering the calendar with the currently loaded data from API.
   */
  rerenderWithExistingData: function () {
    this.useLoadedAPIData = true;

    // Refresh the calendar by setting it's type to the current one.
    this.changeCalendarType(this.getCalendarType(), true);

    this.useLoadedAPIData = false;
  },

  getCalendarTimeRange: function () {
    if (!this.businessHours) {
      const businessEndTime = Wahanda.Time.splitHours(App.config.get('venue').dayEndsAt);
      this.businessHours = {
        start: Wahanda.Time.splitHours(App.config.get('venue').dayStartsAt).hours,
        end:
          businessEndTime.hours +
          (businessEndTime.hours < 24 && businessEndTime.minutes > 0 ? 1 : 0),
      };
    }
    return _.extend({ limitDisplay: true }, this.businessHours);
  },

  updateCalendar: function () {
    this.calendar.resourceCalendar('option', {
      // eslint-disable-next-line no-underscore-dangle
      date: Wahanda.Date._adjustDate(this.currentOptions.date, true),
      dateFormat: App.config.get('jqueryDateFormat').mediumDate,
      timeFormat: App.config.get('jqueryDateFormat').defaultTime,
      use24Hour: App.config.get('jqueryDateFormat').use24h,
      timeslotsPerHour: this.getTimeSlotCount(),
      timeslotHeight: this.getTimeSlotHeight(this.currentOptions.date),
      firstDayOfWeek:
        Wahanda.Date.dayStringToNumberMap[App.config.get('jqueryDateFormat').firstDayOfWeek],
      businessHours: this.getCalendarTimeRange(),
      daysToShow: 7,
    });

    const range = this.getVisibleDateRange();
    if (Wahanda.Date.isDateBetween(this.currentOptions.date, range.from, range.to)) {
      this.redrawMainCalendarWithScroll();
    } else {
      // This can happen if switching calendar views.
      this.setCalendarDate(this.currentOptions.date);
    }
  },

  /**
   * Fetch the calendar data.
   *
   * This action is throttled to 900ms so that in any event we would not be fetching more often
   * than this, as we have suspicions that Connect might be hammering the API randomly.
   */
  fetchCalendar: (function () {
    let lastVenueId = null;
    const fetchCalendar = async function (options, successCallback) {
      this.showLoader();

      this.calendarObjectsPromise = this.calendarCache.getForRange(options);
      const safeCallback = this.withRaceConditionsPrevented('data', successCallback);

      // Wait for the data to be fetched, or just simply returned from the Cache.
      const data = await this.calendarObjectsPromise;

      this.calendarObjects = new App.Models.CalendarObjects();
      this.calendarObjects.set(this.calendarObjects.parse(data));

      safeCallback();
      this.calendarObjectsPromise = null;
    };
    const fetchThrottled = _.throttle(fetchCalendar, 900);

    return function (...args) {
      if (App.getVenueId() === lastVenueId) {
        fetchThrottled.apply(this, args);
      } else {
        lastVenueId = App.getVenueId();
        fetchCalendar.apply(this, args);
      }
    };
  })(),

  getFormatOptions: function ({ resourceId }) {
    const isDayView = this.calendar.resourceCalendar('getViewType') === 'day';
    const rid = resourceId == null ? [] : [resourceId];
    const formatOptions = {
      isDayView: isDayView,
      resources: isDayView ? this.getResourceIds() : rid,
      focusedAppointmentId: this.focusedAppointmentId,
      employees: this.employeesCollection,
    };
    return formatOptions;
  },

  getCalendarData: function (from, to, resourceId, renderFunction) {
    const successCallback = () => {
      const formatOptions = this.getFormatOptions({ resourceId });
      renderFunction(
        this.calendarObjects.getInCalendarFormat(formatOptions, this.model.get('offersCollection')),
      );
      this.hideLoader();
      App.trigger('calendar:objects-rendered');
    };

    const dates = this.getVisibleDateRange();
    const options = {
      dateFrom: moment(dates.from).formatApiDateString(),
      dateTo: moment(dates.to).formatApiDateString(),
      utmSource: this.fetchRequestSource || 'calendar-regular',
      venueId: App.getVenueId(),
    };
    this.fetchRequestSource = null;
    // Helper function to check if passed parameters equal the ones set on the model.
    const parametersEqual = () =>
      _.all(options, (val, key) => {
        return this.calendarObjects.get(key) === val;
      });
    const requireCachedData = this.useLoadedAPIData || (this.lazyFetchingMode && parametersEqual());

    if (requireCachedData && this.calendarObjects.fetching) {
      // We want to render with cached data, but the request didn't finish.
      // Wait for it, rendering will be done once the data is fetched.
      // eslint-disable-next-line no-useless-return
      return;
    }
    if (requireCachedData) {
      // Run the callback in another tick, reusing already fetched data.
      window.setTimeout(successCallback, 0);
    } else {
      // Reset the fetch timer
      App.Timer.reset('calendar-refresh');
      this.fetchCalendar(options, successCallback);
    }
  },

  filterOutWorkingResources: function (resources) {
    const employeesWorkingHours = JSON.parse(
      JSON.stringify(this.venueOpeningTimes.get('employeesWorkingHours')),
    );
    const date = moment(this.currentOptions.date).formatApiDateString();
    return resources.filter(({ id }) => {
      const currentDay =
        employeesWorkingHours[id] && employeesWorkingHours[id].find((day) => day.date === date);
      return currentDay && currentDay.timeSlots.length > 0;
    });
  },

  getCalendarResources: function (renderCallback) {
    const resourcesInCalendarFormat = this.employeesCollection.getInCalendarFormat();
    const renderedResources =
      this.hideNonWorking && this.isDayView()
        ? this.filterOutWorkingResources(resourcesInCalendarFormat)
        : resourcesInCalendarFormat;

    renderCallback(renderedResources.length ? renderedResources : resourcesInCalendarFormat);
  },

  getCalendarResourcesCount: function () {
    return this.employeesCollection.length;
  },

  /**
   * How many time slots should be drawn in one hour?
   * @return int
   */
  getTimeSlotCount: function () {
    return parseInt(60 / App.config.get('venue').appointmentSlotDuration, 10);
  },

  getResourceIds: function () {
    return this.employeesCollection.map(function (e) {
      return e.id;
    });
  },

  getVisibleResourceIds: function () {
    return Wahanda.Permissions.viewAnyCalendar() ? this.getResourceIds() : App.getEmployeeId();
  },

  /**
   * How high should be one time slot?
   *
   * @param {Date} forDate The date for which to get.
   *
   * @return int or float
   */
  getTimeSlotHeight: function (forDate) {
    const startDate = moment(forDate).formatApiDateString();
    const endDate = moment(Wahanda.Date.addDaysToDate(forDate, 7)).formatApiDateString();
    const employeeIds = this.getVisibleResourceIds();

    const openingHoursData = WorkingHoursCache.getCached(startDate, endDate, employeeIds);

    return this.countTimeSlotHeight(openingHoursData);
  },

  countMinMaxWeekTimes: function (date) {
    // Have to check if can get different employee id's
    // or the permission only allow single user to few the calendar
    const resourceId = Wahanda.Permissions.viewAnyCalendar()
      ? this.getResourceIdForCalendar()
      : this.getVisibleResourceIds();
    const startOfWeekDate = moment(date).startOf('isoWeek').formatApiDateString();
    const endOfWeekDate = moment(date).endOf('isoWeek').formatApiDateString();
    const weekData = WorkingHoursCache.getCached(startOfWeekDate, endOfWeekDate, resourceId);
    const employeeWeekData = weekData.get('employeesWorkingHours')
      ? weekData.get('employeesWorkingHours')[resourceId]
      : weekData.get('workingHours');
    const min = minBy(employeeWeekData, (o) => o.timeSlots[0] && o.timeSlots[0].timeFrom)
      .timeSlots[0].timeFrom;
    const max = maxBy(employeeWeekData, (o) => o.timeSlots[0] && o.timeSlots[0].timeTo).timeSlots[0]
      .timeTo;

    return { min, max };
  },

  countMinMaxDayTimes: function (date) {
    const resourceIds = this.getResourceIds();
    const data = WorkingHoursCache.getCached(date, date, resourceIds);
    const employeeData = data.get('employeesWorkingHours');
    const timeSlots = Object.values(employeeData).map((e) => e[0].timeSlots[0]);
    const min = minBy(timeSlots, (o) => o && o.timeFrom).timeFrom;
    const max = maxBy(timeSlots, (o) => o && o.timeTo).timeTo;

    return { min, max };
  },

  countTimeSlotHeight: function (openingHoursData) {
    let minimum;
    if (App.config.get('venue').appointmentSlotDuration < 15) {
      minimum = 10;
    } else {
      minimum = 23;
    }
    const calendarHeight = this.calendar.height();
    // We want to show -1 .. day range .. +1 hours in the calendar. That's why it's +2.
    const CALENDAR_HOUR_PADDING = 2;
    let totalTimeShown;
    const resourceId = this.getResourceIdForCalendar();
    const employeesWorkingHours = openingHoursData.get('employeesWorkingHours');
    const employeeWorkingHours =
      employeesWorkingHours &&
      (employeesWorkingHours[resourceId] || employeesWorkingHours[App.getEmployeeId()]);
    const venueWorkingHours = openingHoursData.get('workingHours');
    const workingHours = employeeWorkingHours || venueWorkingHours || [];
    const validOTData =
      !!workingHours.length &&
      workingHours.find(function (times) {
        if (!(times && times.timeSlots)) {
          return;
        }
        return times.timeSlots.length > 0;
      });

    // The week view depends:
    // - if it is not a day view,
    // - if only single user exists so will only see the week view
    // - if the user has only access to view it's own calendar
    const isWeekView =
      !this.isDayView() ||
      this.getCalendarResourcesCount() === 1 ||
      !Wahanda.Permissions.viewAnyCalendar();

    if (validOTData) {
      let timeSlots;
      if (isWeekView) {
        timeSlots = this.countMinMaxWeekTimes(validOTData.date);
      } else {
        timeSlots = this.countMinMaxDayTimes(validOTData.date);
      }
      const timeFrom = timeSlots.min;
      const timeTo = timeSlots.max;

      totalTimeShown = CALENDAR_HOUR_PADDING + Wahanda.Time.getHourDiff(timeFrom, timeTo);
    } else {
      totalTimeShown = Wahanda.Time.getHourDiff(
        App.config.get('venue').dayStartsAt,
        App.config.get('venue').dayEndsAt,
      );
    }
    const totalSlots = totalTimeShown * this.getTimeSlotCount();
    let showHeight = Math.round(calendarHeight / totalSlots);

    if (showHeight < minimum) {
      showHeight = minimum;
    }
    return showHeight;
  },

  changeCalendarType: function (type, force) {
    const toDayView = type === 'day';
    const resourceId = this.calendar.resourceCalendar('getResourceID');

    if (this.calendar.resourceCalendar('option', 'dayView') !== toDayView || force) {
      if (toDayView) {
        this.calendar.resourceCalendar('showDayView');
      } else {
        this.calendar.resourceCalendar('showWeekView', resourceId);
      }
    }
  },

  getCalendarType: function () {
    return this.calendar.resourceCalendar('option', 'dayView') ? 'day' : 'week';
  },

  /**
   * Returns resourceId for calendar (e.g. converts 'all' to null)
   *
   * @return int or null
   */
  getResourceIdForCalendar: function () {
    return isNaN(this.currentOptions.resourceId) ? null : this.currentOptions.resourceId;
  },

  /**
   * Get selected resource (Employee).
   *
   * @return Backbone.Model or null
   */
  getResource: function () {
    const resourceId = this.getResourceIdForCalendar();
    let model = null;
    if (resourceId != null) {
      model = this.employeesCollection.get(resourceId);
    }
    return model;
  },

  onBackButtonPressed: function () {
    trackEvent('calendar', 'click', 'show-all-employees');

    this.setCalendarType('day');
  },

  redrawMainCalendarWithScroll: function () {
    this.calendar.resourceCalendar('drawEmptyWithScroll');
  },

  toggleNonWorking: function ({ isChecked }) {
    this.hideNonWorking = isChecked;
    this.updateCalendar();
    this.render();
    this.calendar.resourceCalendar('refreshNonWorkSlots');
  },

  onViewTypeChange: function (event, $li) {
    const type = $li.data('type');
    trackEvent('calendar', 'click', `${type}-view`);
    this.setCalendarType(type);
  },

  setCalendarType: function (type) {
    this.trigger('change:type', type);
  },

  onResourceChange: function (resourceId) {
    if (this.isActive()) {
      this.currentOptions.resourceId = resourceId;
      this.trigger('change:resourceId', resourceId);
      this.toggleAppointmentButtons();
    }
  },

  toggleAppointmentButtons: function () {
    $('#employee-ext-calendar-sync').wToggle(this.isActive());

    this.options.mainView.renderDropdowns();
  },

  // Helper functions
  isDateVisible: function (date) {
    const timestamp = date.getTime();
    const visible = this.getVisibleDateRange();

    return timestamp >= visible.from.getTime() && timestamp <= visible.to.getTime();
  },

  getVisibleDateRange: function () {
    return {
      from: this.calendar.data('startDate'),
      // Calendar returns +1 day it shows. Take a second away from it.
      to: new Date(this.calendar.data('endDate').getTime() - 1),
    };
  },

  // Requests a date change to the calendar
  setCalendarDate: function (date) {
    this.calendar.resourceCalendar('gotoDate', date);
  },

  getDate: function () {
    return this.calendar.resourceCalendar('getDate');
  },

  /**
   * When new groups load, ensure a correct group id.
   */
  onResourcesLoaded: function () {
    const currentResourceId = this.currentOptions.resourceId;
    const collection = this.employeesCollection;
    let model = collection.get(currentResourceId);

    if (!model) {
      model = collection.at(0);
    }

    this.currentOptions.resourceId = model ? model.id : null;

    // If we're in Day view mode (that's the default), but have only one employee
    // we should switch early, otherwise there might be some rendering/scrolling issues.
    if (this.currentOptions.type === 'day' && collection.length < 2) {
      this.currentOptions.type = 'week';
      App.trigger(Wahanda.Event.CALENDAR_DISPLAY_TYPE_CHANGE, { type: 'week' });
    }

    this.updateUrlIfDiffers();
    this.headerPaneView.renderResources();
  },

  isDayView: function () {
    return this.currentOptions.type === 'day';
  },

  isDayOrResourceView: function () {
    return this.currentOptions.resourceId !== 'all' || this.isDayView();
  },

  syncWithCalendar: function (calendarOptions) {
    const viewType = this.calendar.resourceCalendar('getViewType');
    if (this.currentOptions.type !== viewType) {
      // needed because in case of event firing mode is updated after API call is issued
      this.currentOptions.type = viewType;
      this.trigger('change:type', viewType);
      this.calendar.resourceCalendar('updateHeight');
    }
    const currentGroupId = this.getResourceIdForCalendar();
    if (currentGroupId !== calendarOptions.resourceID) {
      this.currentOptions.resourceId = calendarOptions.resourceID;
      this.trigger('change:resourceId', calendarOptions.resourceID);
    }
    if (!Wahanda.Date.isEqualDates(this.currentOptions.date, calendarOptions.date)) {
      this.currentOptions.date = calendarOptions.date;
      this.trigger('change:date', calendarOptions.date);
    }
  },

  getResourceByPosition: function (resourcePosition) {
    let resourceId = null;
    const collection = this.employeesCollection;
    const model = collection.at(resourcePosition);
    if (model) {
      resourceId = model.id;
    }
    return resourceId;
  },

  showResourceInWeekView: function (resourcePosition) {
    const resourceId = this.getResourceByPosition(resourcePosition - 1);

    if (resourceId) {
      this.calendar.resourceCalendar('showWeekView', resourceId);
    }
  },

  /**
   * Returns how many resources this venue currently has.
   *
   * @return int
   */
  getResourceCount: function () {
    return this.employeesCollection.length;
  },

  setCalendarResource: function () {
    if (this.currentOptions.resourceId !== this.calendar.resourceCalendar('getResourceID')) {
      this.calendar.resourceCalendar('showWeekView', this.getResourceIdForCalendar());
    }
  },

  shiftModalDestroy: function (type, show) {
    if (type === 'venue') {
      App.ES6.Initializers.ShiftVenueModal().destroy();
    } else {
      App.ES6.Initializers.ShiftEmployeeModal().destroy();
    }
    this.showRotaActionModal = !!show;
  },

  onNonWorkSlotClick: function ($slot, event, type) {
    const slotData = $slot.data('calEvent');
    const threshold = 150;
    const isVertical = this.calendar.height() < event.clientY + threshold;
    const isHorizontal = this.calendar.width() > event.clientX + threshold;

    const verticalPosition = isVertical ? 'top' : 'bottom';
    const horizontalPosition = isHorizontal ? 'Start' : 'End';
    const popoverPlacement = `${verticalPosition}${horizontalPosition}`;
    const employeeId = slotData.rid;
    const date = moment(slotData.start).format('YYYY-MM-DD');
    const offsetTop = event.clientY + 10;
    const offsetLeft = event.clientX - 8;

    const sharedProps = {
      employeeId,
      date,
      sharedProps,
      popoverOffset: {
        top: offsetTop,
        left: offsetLeft,
      },
      popoverPlacement,
    };

    if (type === 'venue') {
      this.showRotaActionModal = !(
        this.newRotaEmployeeId === employeeId && !!this.showRotaActionModal
      );
      if (this.showRotaActionModal) {
        this.shiftModalDestroy(type, true);
        App.ES6.Initializers.ShiftVenueModal({
          ...sharedProps,
          modalType: RotaModalType.VENUE_MODAL_ACTIONS,
        }).render();
      } else {
        this.shiftModalDestroy(type);
      }
    } else if (type === 'employee') {
      this.showRotaActionModal = !(this.newRotaDate === date && !!this.showRotaActionModal);
      if (this.showRotaActionModal) {
        this.shiftModalDestroy(null, true);
        App.ES6.Initializers.ShiftEmployeeModal({
          ...sharedProps,
        }).render();
      } else {
        this.shiftModalDestroy(null);
      }
    }
    this.newRotaEmployeeId = employeeId;
    this.newRotaDate = date;
  },

  /**
   * Refresh calendar data.
   */
  refreshCalendars: function () {
    this.refreshMainCalendar();
    this.datepickerView.fetchOpeningTimes(null, null, true);
  },

  refreshMainCalendar: function () {
    this.calendar.resourceCalendar('refresh');
  },

  redrawMainCalendar: function () {
    this.calendar.resourceCalendar('drawEmpty');
  },

  scrollToValidHour: function () {
    const dates = this.getVisibleDateRange();
    const today = Wahanda.Date.createVenueDate();
    const employeeIds = this.getVisibleResourceIds();
    // Get employee working hours from cache if only one employee
    // can be shown in calender. Otherwise load venue working hours
    // to get the needed data for this function.
    const id = !employeeIds.length ? employeeIds : null;

    const activeDate = Wahanda.Date.isDateBetween(today, dates.from, dates.to) ? today : dates.from;

    WorkingHoursCache.get(activeDate, activeDate, id).done(
      function (openingHours) {
        const ot = openingHours.getTimesByDate(activeDate);
        let openingHour = 0;
        let targetHour = today.getHours();

        if (ot && ot.timeSlots[0] && ot.timeSlots[0].timeFrom) {
          openingHour = Wahanda.Time.splitHours(ot.timeSlots[0].timeFrom).hours;
          targetHour = Math.max(openingHour, targetHour);
        }

        this.calendar.resourceCalendar('scrollToHour', targetHour);
      }.bind(this),
    );
  },

  onItemClicked: function (calEvent, $event) {
    const self = this;
    if (calEvent.type === 'block') {
      if (calEvent.typeCode === 'EL') {
        // ExternaL blocks are read-only. No popup opens on them.
      } else if (!this.isDayOrResourceView()) {
        // Expand block into day view
        this.setCalendarType('day');
        App.ES6.Initializers.State.change({
          'calendar-event-editor': {
            blockViewData: self.getBlockEditViewData(calEvent),
            tab: 'block',
          },
        });
      } else if (this.canModifyAppointmentsAndBlocks(calEvent.originalResourceId)) {
        // Open block for editing
        $event.trigger('mouseleave');
        App.ES6.Initializers.State.change({
          'calendar-event-editor': {
            blockViewData: self.getBlockEditViewData(calEvent),
            tab: 'block',
          },
        });
      }
    } else if (calEvent.type === 'appointment') {
      const appointment = this.calendarObjects.getAppointment(calEvent.id);
      if (!appointment) {
        Sentry?.captureMessage('Appointment not found on item clicked', {
          level: 'warning',
          extra: { appointmentId: calEvent?.id },
        });
        return;
      }
      $event.trigger('mouseleave');
      App.trigger(Wahanda.Event.CALENDAR_APPOINTMENT_OPEN);
      App.ES6.Initializers.State.change({
        'calendar-event-editor': {
          appointmentViewData: {
            model: appointment,
            initialCollectionStructure: this.calendarObjects.getAppointmentsForCustomerAndDate(
              appointment.get('venueCustomerId'),
              appointment.get('appointmentDate'),
            ),
          },
          tab: 'appointment',
        },
      });
    }
  },

  getBlockEditViewData: function (calEvent) {
    const date = moment(calEvent.start).formatApiDateString();
    const fromMinutes = Wahanda.Time.getDateMinutes(calEvent.start);
    const toMinutes = Wahanda.Time.getDateMinutes(calEvent.end);

    const modelOptions = {
      id: calEvent.id ? calEvent.id : null,
      venueId: App.config.get('venue').id,
      dateFrom: date,
      timeFrom: Wahanda.Time.toFormatted(fromMinutes, 'H:i'),
      dateTo: calEvent.id ? null : date,
      timeTo: calEvent.id ? null : Wahanda.Time.toFormatted(toMinutes, 'H:i'),
      availabilityRuleTypeCode: 'D',
      name: calEvent.title ? calEvent.title : null,
      employeeId: !calEvent.id ? calEvent.rid : null,
    };

    return {
      model: new App.Models.TimeBlock(modelOptions),
      groups: this.model.get('groupsCollection'),
      employees: this.employeesCollection,
      actionDate: date,
      actionDateObject: calEvent.start,
    };
  },

  /**
   * Forces resourceId on the calendar, without triggering any events.
   */
  forceResourceId: function (resourceId) {
    this.currentOptions.resourceId = resourceId;
    this.updateUrlWithCurrentOptions();
    this.changeCalendarType(this.currentOptions.type, true);
  },

  showAppointmentInfo: function (appointmentData, requestFromUrl) {
    const self = this;
    const appointment =
      appointmentData instanceof App.Models.Appointment
        ? appointmentData
        : new App.Models.Appointment(appointmentData);

    Wahanda.Appointment.openUDV(
      {
        model: appointment,
        changeCalendarDate: function (date) {
          self.trigger('change:date', date);
        },
        lightweightFetchOptions: App.mainViewOptions.initialDialog,
        singleAppointmentMode: !!requestFromUrl,
      },
      null,
      'appointment',
    );
  },

  /**
   * Show Appointment Dialog with data set from the Order.
   *
   * @param App.Models.Order order
   */
  showAppointmentFromOrder: function (order) {
    App.Views.Forms.Appointment2.openFromOrder(order);
  },

  onEventMouseEnter: function (calEvent, $eventDiv) {
    this.onEventMouseLeave();
    if (calEvent.type === 'block' && this.isDayOrResourceView()) {
      const block = this.calendarObjects.getBlock(calEvent.id, calEvent.start);
      if (block) {
        if (!this.calendarBlockTooltipView.isAttached()) {
          this.$('.wc-scrollable-grid').append(this.calendarBlockTooltipView.$el);
        }

        this.calendarBlockTooltipView.renderData(calEvent, block);
        this.calendarBlockTooltipView.position($eventDiv);
      }
    } else if (calEvent.type === 'appointment') {
      const appointment = this.calendarObjects.getAppointment(calEvent.id);

      if (appointment) {
        if (!this.calendarAppointmentTooltipView.isAttached()) {
          this.$('.wc-scrollable-grid').append(this.calendarAppointmentTooltipView.$el);
        }
        if (appointment.getProcessingTimeLength() > 0) {
          // Add class to processing time bar to increase the z-index
          this.$(`#wc-processing-${calEvent.id}`).addClass('to-front');
          this.$(`#wc-finishing-${calEvent.id}`).addClass('to-front');
        }
        if (calEvent.venueCustomerId) {
          this.indicateRelatedAppointments(calEvent);
        }
        this.calendarAppointmentTooltipView.renderData(calEvent, appointment);
        this.calendarAppointmentTooltipView.position($eventDiv);
      }
    }
  },

  isAppointmentInCalendar: function (appointmentId) {
    let appointmenFound = false;

    this.calendar.find('.wc-cal-event').each(function () {
      const $event = $(this);
      const data = $event.data('calEvent');
      if (data && data.id === appointmentId) {
        appointmenFound = true;
        return false;
      }
      return true;
    });

    return appointmenFound;
  },

  indicateAppointment: function (appointmentId) {
    const self = this;
    this.calendar.find('.wc-cal-event').each(function (index) {
      const $event = $(this);
      const data = $event.data('calEvent');
      if (data && data.id === appointmentId) {
        const element = self.calendar.find('.wc-cal-event')[index];
        $(element).addClass('animate-bg-once');
        $(`#wc-finishing-${$(element).data('calEvent').id}`).addClass('animate-bg-once');
        setTimeout(() => {
          $('[id^="wc-finishing-"]').removeClass('animate-bg-once');
          $(element).removeClass('animate-bg-once');
        }, 2000);
        return false;
      }
      return null;
    });
  },

  indicateRelatedAppointments: function (calEvent) {
    const appointmentArray = [];
    // First, find if more than consumer has more than 1 appointment that day.
    $('.wc-cal-event').each(function () {
      const $event = $(this);
      const eventVenueCustomerId = $event.data('consumer-day-id');

      if (
        eventVenueCustomerId ===
        App.Models.Appointment.getConsumerDateId(calEvent.venueCustomerId, calEvent.start)
      ) {
        appointmentArray.push($event);
      }
    });

    if (appointmentArray.length > 1) {
      _.each(appointmentArray, function (appointment) {
        appointment.addClass('animate-bg');
        $(`#wc-finishing-${appointment.data('calEvent').id}`).addClass('animate-bg');
      });
    }
  },

  onEventMouseLeave: function () {
    this.$('.wc-cal-event').removeClass('animate-bg');
    this.calendarBlockTooltipView.hide();
    this.calendarAppointmentTooltipView.hide();
    this.$('[id^="wc-processing-"], [id^="wc-finishing-"]').removeClass('to-front');
    this.$('[id^="wc-finishing-"]').removeClass('animate-bg');
  },

  showNoPermissionDialog: function () {
    App.Views.Calendar.NoPermission.open();
  },

  showAppointmentFromUrl: function (itemId) {
    this.showAppointmentInfo(
      {
        id: itemId,
      },
      true,
    );
  },

  showAppointment: function (appointmentId) {
    this.showAppointmentInfo({ id: appointmentId });
  },

  updateUrlIfDiffers: function () {
    const url = this.getCurrentStateHash();
    if (!String(window.location.hash).match(url)) {
      this.updateUrlWithCurrentOptions();
    }
  },

  /**
   * Updates the URL to the current state.
   *
   * @param String type
   * @param Date date
   * @param int resourceId
   */
  updateUrl: function (type, date, resourceId) {
    App.mainRouter.navigate(this.getStateHash(type, date, resourceId));
  },

  updateUrlWithCurrentOptions: function () {
    this.updateUrl(
      this.currentOptions.type,
      this.currentOptions.date,
      this.currentOptions.resourceId,
    );
  },

  getCurrentStateHash: function (forcedData) {
    const data = forcedData || {};
    return this.getStateHash(
      this.currentOptions.type,
      this.currentOptions.date,
      data.resourceId || this.currentOptions.resourceId,
    );
  },

  getStateHash: function (type, date, resourceId) {
    const venueId = App.config.get('venue').id;
    const calendarType = type === 'day' ? 'day' : 'week';
    const calendarDate = moment(date).formatApiDateString();

    // eslint-disable-next-line no-useless-escape
    const resource = /^\-?\d+$/.test(String(resourceId)) ? resourceId : 'all';

    return `venue/${venueId}/appointment/${calendarType}/${calendarDate}/${resource}`;
  },

  redirectToEmployees: function () {
    window.location = Wahanda.Url.getFullUrl('team', 'team');
  },

  getDefaultResourceId: function () {
    let rid = this.calendar.resourceCalendar('getResourceID');

    if (rid == null || this.isDayView()) {
      rid = this.getResourceByPosition(0);
    }
    return rid;
  },

  openAddAppointmentDialog: function () {
    const start = Wahanda.Date.createVenueDate();
    const hour = Wahanda.Date.createVenueDate().getHours();
    start.setHours(hour);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    const end = new Date(start.getTime() + 60 * 60 * 1000);

    const calEvent = {
      start: start,
      end: end,
      rid: this.getDefaultResourceId(),
    };
    this.showAppointmentAddDialog(calEvent);
  },

  getAppointmentViewData: function (calEvent, modelOptions, formOptions) {
    const options = _.extend(modelOptions || {}, {
      venueId: App.config.get('venue').id,
    });

    if (calEvent) {
      const startTime = Wahanda.Time.getDateMinutes(calEvent.start);
      const endTime = Wahanda.Time.getDateMinutes(calEvent.end);

      _.extend(options, {
        employeeId: calEvent.rid,
        appointmentDate: moment(calEvent.start).formatApiDateString(),
        startTime: Wahanda.Time.toApiString(startTime),
        endTime: Wahanda.Time.toApiString(endTime),
      });
    }

    const model = new App.Models.Appointment(options);

    return _.extend(formOptions || {}, {
      model: model,
    });
  },

  showAddDialog: function (tabType, calEvent, apptModelOptions, apptFormOptions) {
    App.ES6.Initializers.State.change({
      'calendar-event-editor': {
        appointmentViewData: this.getAppointmentViewData(
          calEvent,
          apptModelOptions,
          apptFormOptions,
        ),
        blockViewData: this.getBlockEditViewData(calEvent),
        tab: tabType,
      },
    });
  },

  showAppointmentAddDialog: function (calEvent, modelOptions, formOptions) {
    this.showAddDialog('appointment', calEvent, modelOptions, formOptions);
  },

  showBlockAddDialog: function (calEvent, apptModelOptions, apptFormOptions) {
    this.showAddDialog('block', calEvent, apptModelOptions, apptFormOptions);
  },

  showUseEvoucherDialog: function (calEvent) {
    this.options.mainView.showVoucherRedemptionDialog({
      convertToAppointment: true,
      calEvent: calEvent,
      appointmentCalendar: this,
    });
  },

  showAppointmentRejectionForm: function (idToReject) {
    const self = this;
    const model = new App.Models.Appointment({
      id: idToReject,
      venueId: App.config.get('venue').id,
    });

    // TODO: would be nice to show a "Loading..." screen

    const openRejectionForm = function () {
      const form = new App.Views.Calendar.AppointmentReject({
        model: model,
      });
      form.render();
      form.open();
    };

    model.fetch({
      success: openRejectionForm,
      error: BackboneEx.Tool.ModelLightweightFetch({
        onSuccess: openRejectionForm,
        onError: function () {
          self.showNoPermissionDialog();
        },
      }),
      skipErrorHandling: true,
    });
  },

  showAppointmentGroupRejectionForm: function (idToReject) {
    const self = this;
    const apptGroup = new App.Collections.AppointmentGroup(null, {
      id: idToReject,
    });

    const openRejectionForm = function () {
      const form = new App.Views.Calendar.AppointmentGroupReject({
        collection: apptGroup,
      });
      form.render();
      form.open();
    };

    apptGroup
      .fetch({ skipErrorHandling: true })
      .done(openRejectionForm)
      .fail(
        BackboneEx.Tool.ModelLightweightFetch({
          onSuccess: openRejectionForm,
          onError: function () {
            self.showNoPermissionDialog();
          },
        }),
      );
  },

  /**
   * Sets the appointment to scroll the calendar to, when the data is next loaded.
   * @param int appointmentId
   */
  setAppointmentToScrollTo: function (appointmentId) {
    this.appointmentToScrollTo = appointmentId;
  },

  /**
   * Scroll to appointment previously set with `setAppointmentToScrollTo`.
   */
  scrollToAppointment: function (onComplete) {
    if (this.appointmentToScrollTo == null && this.focusedAppointmentId == null) {
      return;
    }
    const id = this.appointmentToScrollTo || this.focusedAppointmentId;
    this.appointmentToScrollTo = null;

    // Find the appointment needed to scroll to
    let $appt;
    this.calendar.find('.wc-cal-event').each(function () {
      const $event = $(this);
      const data = $event.data('calEvent');
      if (data && data.id === id) {
        $appt = $event;
        return false;
      }
      return null;
    });

    if ($appt) {
      // Scroll to the appointment
      const $scroller = this.$('.wc-scrollable-grid');
      const scrollerWidth = $scroller.innerWidth();
      const apptTop = $appt.position().top;
      const apptLeft = $appt.offset().left;
      const scrollTop = apptTop - $scroller.height() / 2;
      const scrollLeft = apptLeft - scrollerWidth + scrollerWidth / 4 + $scroller.scrollLeft();

      $scroller.animate(
        {
          scrollTop,
          scrollLeft,
        },
        500,
        onComplete,
      );
    }
  },

  triggerCalendarRefresh: function () {
    trackEvent('calendar', 'click', 'refresh');

    this.refreshMainAndEmployeeExtCalendar();
  },

  refreshMainAndEmployeeExtCalendar: _.throttle(function (fullRefresh) {
    this.calendarCache.invalidate();

    let refreshEmployeeExtCalendar = false;
    if (!this.isDayView()) {
      const employee = this.getResource();
      refreshEmployeeExtCalendar =
        employee != null &&
        employee.hasExternalCalendar() &&
        employee.get('linkedExternalSalonName') == null;
    }

    if (refreshEmployeeExtCalendar) {
      this.syncEmployeeExtCalendar(fullRefresh);
    } else if (fullRefresh) {
      this.refreshCalendars();
    } else {
      this.refreshMainCalendar();
    }
  }, 1000),

  syncEmployeeExtCalendar: function (fullRefresh) {
    const self = this;
    const resource = this.getResource();
    if (resource) {
      const $button = $('#employee-ext-calendar-sync').find('button').disableFormElements();
      const sync = new App.Models.ExmployeeExternalCalendar({
        id: resource.id,
        venueId: App.getVenueId(),
      });
      sync.fetch({
        success: function () {
          $button.enableFormElements();
          if (fullRefresh) {
            self.refreshCalendars();
          } else {
            self.refreshMainCalendar();
          }
        },
        error: function (model, xhr) {
          $button.enableFormElements();

          const errors = Wahanda.Util.parseErrors(xhr);
          if (errors) {
            $button.noticeTip(
              App.Models.ExmployeeExternalCalendar.getFailureTextByName(errors[0].name),
              // Position the tip a bit to the left, because it can go out of the screen
              {
                position: {
                  my: 'bottom right',
                  at: 'top center',
                  adjust: {
                    y: -2,
                  },
                },
              },
              5000,
            );
          }
        },
      });
    }
  },

  enterBlockoutMode: function () {
    this.$el.addClass('in-blockout-mode');
    this.allowHeaderNavigation = false;
    this.allowRefresh = false;
    this.calendar.resourceCalendar('enterBlockoutMode');
  },

  leaveBlockoutMode: function () {
    this.$el.removeClass('in-blockout-mode');
    this.allowHeaderNavigation = true;
    this.allowRefresh = true;
    this.calendar.resourceCalendar('leaveBlockoutMode');
  },

  getBlockoutEvents: function () {
    return this.calendar.resourceCalendar('getBlockoutEvents');
  },

  /**
   * Enter the "floating event" mode.
   * It's the mode where one has an shadow-like event following the mouse cursor and a click on the
   * calendar ends the mode, also triggering the passed in callbacks.
   */
  enterFloatingEventMode: function (options) {
    this.allowRefresh = false;

    const itemList = options.itemList;
    const onSelected = options.onSelected;
    const firstItem = itemList[0];
    const appointment =
      firstItem instanceof App.Collections.AppointmentGroup ? firstItem.at(0) : firstItem;
    let totalDuration;

    const apptNumber = _.reduce(
      itemList,
      function (running, item) {
        if (item instanceof App.Collections.AppointmentGroup) {
          return running + item.length;
        }
        return running + 1;
      },
      0,
    );

    const title =
      apptNumber > 1
        ? Wahanda.lang.calendar.appointments.rescheduleCount.replace('{count}', apptNumber)
        : appointment.getOfferName();

    this.onExitFloatingEventMode = options.onExit;

    if (options.rebook) {
      totalDuration = options.dayCollection.getRescheduleDuration();
    } else {
      totalDuration = _.reduce(
        itemList,
        function (memo, item) {
          return memo + item.getRescheduleDuration();
        },
        0,
      );
    }

    const rescheduleOptions = {
      duration: totalDuration,
      consumerName: appointment.getConsumerName(),
      isWalkin: appointment.isWalkin(),
      title: title,
      initialPosition: options.initialPosition,
      onEventNew: onSelected,
    };

    this.calendar.resourceCalendar('enterReschedulingMode', rescheduleOptions);
    this.renderFloatingEventModeHeader({
      text: options.headerText,
      rebook: options.rebook,
      reschedule: options.reschedule,
    });
    $('body').keydown(this.rescheduleKeyHandlerFunc);
  },

  checkPackageRescheduleSuccess: function (originalPackage, savedPackageAppts) {
    if (!this.packageReschduleState) {
      this.packageReschduleState = {};
    }
    if (!this.packageReschduleState[originalPackage.id]) {
      this.packageReschduleState[originalPackage.id] = 0;
    }
    const self = this;
    if (this.packageReschduleState[originalPackage.id] > 5) {
      return;
    }

    const appointmentGroup = new App.Collections.AppointmentGroup();
    appointmentGroup.id = originalPackage.id;
    appointmentGroup.fetch().done(function (data) {
      const correctDate = data.appointmentDate;
      const packageSavedCorrectly = data.appointments.every((appointment) => {
        return appointment.appointmentDate === correctDate;
      });

      if (!packageSavedCorrectly) {
        // Save and check again
        originalPackage.correctValues(savedPackageAppts.apptsSaved);
        originalPackage.save().done(() => {
          window.setTimeout(
            () => self.checkPackageRescheduleSuccess(originalPackage, savedPackageAppts),
            4000,
          );
        });
        self.packageReschduleState[originalPackage.id] += 1;
      }
    });
  },

  exitFloatingEventMode: function (cancel) {
    if (cancel) {
      // Need to remove initial hover cal event
      $('.reschedule-preview').remove();
      this.allowRefresh = true;
    }
    this.calendar.resourceCalendar('exitReschedulingMode');
    this.rescheduleHeader.destroy();
    this.options.mainView.exitFloatingEventMode();
    $('body').off('keydown', this.rescheduleKeyHandlerFunc);

    if (this.onExitFloatingEventMode) {
      this.onExitFloatingEventMode(cancel);
      this.onExitFloatingEventMode = null;
    }
  },

  /**
   * Start rescheduling of the given items.
   *
   * An item can be either an Appointment or an Appointment Group.
   *
   * @param {Array} itemList List of items to reschedule.
   * @param {Object} options Additional options.
   *   {Object} initialPosition The initial position for the shadow to render
   *   {Collection} dayCollection The Collection of all appointments for the Customer's day
   * @return {void}
   */
  startReschedulingMode: function (itemList, options) {
    App.trigger(Wahanda.Event.CALENDAR_RESCHEDULE_MODE_START);

    function isNewPositionTheSame(appt, clickData) {
      return (
        appt.getStartDate().getTime() === clickData.start.getTime() &&
        appt.get('employeeId') === clickData.resourceId
      );
    }

    const item = itemList[0];
    const appointment = item instanceof App.Collections.AppointmentGroup ? item.at(0) : item;

    this.enterFloatingEventMode({
      itemList: itemList,
      initialPosition: options.initialPosition,
      headerText: Wahanda.lang.calendar.reschedule.text,
      reschedule: true,
      onSelected: function (data) {
        const revertFn = function () {
          // Need to remove initial hover cal event
          $('.reschedule-preview').remove();
          App.trigger(Wahanda.Event.CALENDAR_RESCHEDULE_MODE_CANCEL);
          if (options.isWithinCancellation) {
            CancellationFlowAnalytics.trackRescheduleCancelled();
          }
        };

        if (!isNewPositionTheSame(appointment, data)) {
          // If clicked in the same place, just exit rescheduling mode.
          const comparisonData = this.getRescheduleComparisonData(
            appointment.getStartDate(),
            data.start,
            appointment.get('employeeId'),
            data.resourceId,
          );
          this.showConfirmAppointmentReschedule({
            appointment: appointment,
            newStartDate: data.start,
            newEmployeeId: data.resourceId,
            data: comparisonData,
            revertFn: revertFn,
            fullReschedule: true,
            dayCollection: options.dayCollection,
          })
            .done(
              function () {
                if (options.isWithinCancellation) {
                  CancellationFlowAnalytics.trackRescheduleConfirmed();
                }

                App.trigger(
                  Wahanda.Event.CALENDAR_RESCHEDULE_MODE_FINISH,
                  this.getRescheduleChangeStringFromComparison(comparisonData),
                );
                this.refreshMainCalendar();
              }.bind(this),
            )
            .always(function () {
              this.allowRefresh = true;
            });
          this.exitFloatingEventMode();
        } else {
          this.exitFloatingEventMode(true);
        }
      }.bind(this),
      onExit: function (isCancelled) {
        if (isCancelled) {
          if (options.isWithinCancellation) {
            CancellationFlowAnalytics.trackRescheduleCancelled();
          }
          App.trigger(Wahanda.Event.CALENDAR_RESCHEDULE_MODE_CANCEL);
        }
      },
    });
  },

  rescheduleKeyHandler: function (evt) {
    if ($.ui.keyCode.ESCAPE === evt.keyCode) {
      this.exitFloatingEventMode(true);
    }
  },

  renderFloatingEventModeHeader: function (options) {
    this.rescheduleHeader = new App.Views.Calendar.FloatingEventModeHeader({
      text: options.text,
      rebook: options.rebook,
      reschedule: options.reschedule,
      onCancel: this.exitFloatingEventMode.bind(this, true),
    });
    this.rescheduleHeader.render();
    $('body').append(this.rescheduleHeader.$el);
  },

  startRebookingMode: function (dayCollection, options) {
    App.trigger(Wahanda.Event.CALENDAR_REBOOKING_MODE_START);
    this.enterFloatingEventMode({
      itemList: dayCollection.models,
      initialPosition: options.initialPosition,
      rebook: true,
      headerText: Wahanda.lang.calendar.rebooking.headerText,
      dayCollection: dayCollection,
      onSelected: function (data) {
        trackEvent('calendar', 'click', 'rebook');
        this.showLoader();
        Wahanda.FloatingEventHandler.rebookAppointment(dayCollection, data.start, data.resourceId)
          .always(
            function () {
              this.allowRefresh = true;
              this.refreshMainCalendar();
            }.bind(this),
          )
          .fail(
            function () {
              this.hideLoader();
            }.bind(this),
          );

        App.trigger(Wahanda.Event.CALENDAR_REBOOKING_MODE_FINISH);
        this.exitFloatingEventMode();
      }.bind(this),
      onExit: function (isCancelled) {
        if (isCancelled) {
          trackEvent('calendar', 'click', 'rebook-cancel');
          App.trigger(Wahanda.Event.CALENDAR_REBOOKING_MODE_CANCEL);
        }
      },
    });
  },

  /**
   * Handle external (possibly WebSockets) calendar change notification.
   *
   * @param {Array} items
   */
  onCalendarChangeNotification: function (items) {
    const dates = this.getVisibleDateRange();
    const { 'appointment-event': apptEvents, 'availability-event': timeBlocks } = items;

    function hasRelevantAppointmentChanges() {
      return (apptEvents || []).some((item) =>
        // If the change happened in our visible date range, fetch it again.
        Wahanda.Date.isDateBetween(Wahanda.Date.createDate(item.date), dates.from, dates.to),
      );
    }
    function hasRelevantTimeBlockChanges() {
      return (timeBlocks || []).some((item) => {
        const itemDate = Wahanda.Date.createVenueDate(item.dateFrom);

        if (item.availabilityRuleType === 'SPECIFIC_DATE') {
          return moment(itemDate).isBetween(dates.from, dates.to);
        }
        return moment(itemDate).isSameOrBefore(dates.from);
      });
    }

    if (hasRelevantAppointmentChanges() || hasRelevantTimeBlockChanges) {
      this.fetchRequestSource = 'websockets';
      this.refreshCalendars();
    }
  },

  onWebSocketsConnected() {
    this.fetchRequestSource = 'websockets';
    this.refreshCalendars();
  },
});

BackboneEx.Mixin.extendView(
  App.Views.Calendar.AppointmentCalendar,
  BackboneEx.Mixin.View.CalendarTypeChange,
);
