import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Shrink extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-shrink', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M11,21 L9,21 L8.99989322,16.4138932 L3.70710678,21.7071068 L2.29289322,20.2928932 L7.58589322,14.9998932 L3,15 L3,13 L11,13 L11,21 Z M20.2928932,2.29289322 L21.7071068,3.70710678 L16.4138932,8.99989322 L21,9 L21,11 L13,11 L13,3 L15,3 L14.9998932,7.58589322 L20.2928932,2.29289322 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Shrink);
