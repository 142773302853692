import React from 'react';
import { ClientFormsCreateStepLayout } from './ClientFormsCreateStepLayout';
import Form from '@rjsf/core';
import style from './ClientFormsCreatePreview.scss';
import { Checkbox } from 'components/common/Checkbox';
import Wahanda from 'common/wahanda';

const lang = Wahanda.lang.marketing.clientForms;

const STEP_NUMBER = 3;
const STEP_TITLE = '';
const STEP_DESCRIPTION = '';

type Step3Props = {
  schema: any;
  confirmCreation: boolean;
  onChangeConfirmCreation: (confirm: boolean) => void;
  displayError: boolean;
};

export const ClientFormsCreateStep3 = ({
  schema,
  confirmCreation,
  onChangeConfirmCreation,
  displayError,
}: Step3Props): JSX.Element => {
  return (
    <ClientFormsCreateStepLayout
      stepNumber={STEP_NUMBER}
      stepTitle={STEP_TITLE}
      stepDescription={STEP_DESCRIPTION}
      displayError={displayError}
      displayStepNumber={false}
    >
      <div className={style.clientFormsCreatePreview}>
        <Form schema={schema}>
          <div />
        </Form>
      </div>
      <div className={style.clientFormsCreatePreviewConfirmationWrapper}>
        <div>
          <h3>{lang.reviewFormConfirmationTitle}</h3>
        </div>
        <Checkbox
          dataTest="client-forms-create-confirmation-checkbox"
          label={lang.reviewFormCheckboxLabel}
          name="confirm"
          onChange={(confirmed) => onChangeConfirmCreation(!!confirmed)}
          checked={confirmCreation}
        />
      </div>
    </ClientFormsCreateStepLayout>
  );
};
