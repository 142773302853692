/* global $ */
// Make the debug bar (one with version) draggable so that while we're testing, we could move it
// if it covers some UI elements.
(function ($debugBar) {
  function box() {
    return $debugBar[0].getBoundingClientRect();
  }
  function toLeftPositioned() {
    var pos = box();
    $debugBar.css({
      top: pos.top,
      left: pos.left,
      right: 'auto',
      bottom: 'auto',
    });
  }
  function toRightPositioned() {
    var pos = box();
    $debugBar.css({
      top: 'auto',
      left: 'auto',
      right: window.innerWidth - pos.right,
      bottom: window.innerHeight - pos.bottom,
    });
  }

  if ($debugBar.length > 0) {
    $debugBar.draggable({
      // Switch between left- and right-position type. As jQuery does not handle right-positioned
      // element dragging well.
      start: toLeftPositioned,
      stop: toRightPositioned,
    });
  }
})($('#dev-version-banner'));
