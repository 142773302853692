import React from 'react';

class TickOval extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
        <defs>
          <path id="a" d="M7.707 8.293L11 5l1.414 1.414-4.707 4.707L4.5 7.9l1.4-1.4z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <circle cx="8" cy="8" r="8" fill="#3ba150" />
          <use fill="#FFF" fillRule="nonzero" xlinkHref="#a" />
        </g>
      </svg>
    );
  }
}

export default TickOval;
