/* eslint-disable prefer-rest-params */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint func-names: 0 */
import { xhr } from 'common/xhr';
import { now, toMoment } from 'common/datetime';
import apiUrl from 'common/api-url';
import APPOINTMENT_CHANNEL_CODE from 'common/constants/appointmentChannelCodes';
import moment from 'common/moment';

/**
 * Appointment model.
 *
 * Custom events triggered:
 * > confirmed
 * > rejected
 *
 */

(function () {
  const apptStatusToClass = {
    RJ: 'status-rejected',
    CR: 'status-not-confirmed',
    CN: 'status-scheduled',
    CC: 'status-cancelled',
    CP: 'status-checkedout',
    NS: 'status-noshow',
  };

  const CHANNEL_CUSTOMER = 'CUSTOMER';
  const CHANNEL_SUPCUS = 'SUPPLIERS_CUSTOMER';
  const CHANNEL_LOCAL = 'SUPPLIER';

  const AppointmentModel = BackboneEx.Model.Silent.extend(
    {
      // Booking channels
      CHANNEL_CUSTOMER,
      CHANNEL_SUPCUS,
      CHANNEL_LOCAL,
      // Appointment delete reasons
      DELETE_REASON_CLIENT_MISS: 'NS',
      DELETE_REASON_CLIENT_CANCEL: 'CC',
      DELETE_REASON_EMPLOYEE: 'UN',
      // Statuses
      STATUS_CREATED: 'CR',
      STATUS_CANCELLED: 'CC',
      STATUS_CONFIRMED: 'CN',
      STATUS_CHECKED_OUT: 'CP',
      STATUS_REJECTED: 'RJ',
      STATUS_NO_SHOW: 'NS',

      defaults: {
        venueId: null,
      },

      attributesToSave: [
        'id',
        'anonymousNote',
        'appointmentDate',
        'bookingActor',
        'platform',
        'venueCustomerId',
        'employeeId',
        'endTime',
        'notes',
        'offerId',
        'skus',
        'startTime',
        'notifyConsumer',
        'evoucherReference',
        'processingEndTime',
        'finishingEndTime',
        'walkIn',
        'recurrence',
      ],

      statusClasses: apptStatusToClass,

      initialize() {
        this.groups = {};
      },

      url() {
        const id = this.id;
        const url = id ? apiUrl('APPOINTMENT', { id }) : apiUrl('APPOINTMENTS');

        return url;
      },

      parse(data) {
        // this.attributes check is for the case where the model isn't yet initialized properly.
        if (this.attributes && !this.id && data.id) {
          // The API does not return customer name when an Appointment is created. Work around this.
          const customerIdMatches =
            parseInt(this.get('venueCustomerId'), 10) === data.venueCustomerId;
          if (this.get('consumerName') && !data.consumerName && customerIdMatches) {
            data.consumerName = this.get('consumerName');
          }
        }

        return data;
      },

      /**
       * Get the first non-falsy attribute.
       *
       * @param String * List of attributes
       *
       * @returns mixed
       */
      getFirst(...args) {
        const self = this;
        const value = args.find((attr) => self.get(attr) != null);
        if (value != null) {
          return this.get(value);
        }
        return undefined;
      },

      /**
       * Returns appointment's start date in local client PCs (not venue!) time.
       *
       * @return Date
       */
      getStartDate() {
        return Wahanda.Date.createDate(this.get('appointmentDate'), this.get('startTime'));
      },

      getAppointmentDate() {
        return this.get('appointmentDate');
      },

      getEndDate() {
        return Wahanda.Date.createDate(
          this.get('appointmentDate'),
          this.getFirst('cleanupEndTime', 'endTime'),
        );
      },

      getEndDateWithAdditionalTime() {
        return Wahanda.Date.createDate(
          this.get('appointmentDate'),
          this.getFirst('cleanupEndTime', 'processingEndTime', 'finishingEndTime', 'endTime'),
        );
      },

      getStartTimeMinutes() {
        return Wahanda.Time.timeToMinutes(this.getStartTime());
      },

      getStartTime() {
        return this.get('startTime');
      },

      getEndTimeMinutes(withAdditionalTime) {
        return Wahanda.Time.timeToMinutes(this.getEndTime(withAdditionalTime));
      },

      getEndTime(withAdditionalTime) {
        let endTime;
        if (withAdditionalTime) {
          endTime = this.get('cleanupEndTime') || this.get('processingEndTime');
        }
        if (!endTime) {
          endTime = this.get('endTime');
        }
        return endTime;
      },

      getFinishingEndTimeMinutes() {
        const pt = this.get('finishingEndTime');
        return pt ? Wahanda.Time.timeToMinutes(pt) : null;
      },

      getProcessingEndTimeMinutes() {
        const pt = this.get('processingEndTime');
        return pt ? Wahanda.Time.timeToMinutes(pt) : null;
      },

      getCleanupEndTimeMinutes() {
        const pt = this.get('cleanupEndTime');
        return pt ? Wahanda.Time.timeToMinutes(pt) : null;
      },

      /**
       * Update the start and end times by the given minutes.
       *
       * @param int timeDiff Minutes to move the appointment. Negative value moves the appointment back.
       * @param string date (optional) New appointment date.
       */
      move(timeDiff, date) {
        function normalizeTime(time) {
          if (time < 0) {
            return 0;
          }
          if (time > 24 * 60 - 1) {
            // Time can't be more than 23:59
            return 24 * 60 - 1;
          }
          return time;
        }

        const appointmentDate = this.get('appointmentDate');
        const newStartTime = normalizeTime(this.getStartTimeMinutes() + timeDiff);
        const newEndTime = normalizeTime(this.getEndTimeMinutes() + timeDiff);
        const newPT = this.get('processingEndTime')
          ? normalizeTime(this.getProcessingEndTimeMinutes() + timeDiff)
          : null;
        // New cleanup time isn't saved, but it might be used in later calculations.
        const newCT = this.get('cleanupEndTime')
          ? normalizeTime(this.getCleanupEndTimeMinutes() + timeDiff)
          : null;
        const newFT = this.get('finishingEndTime')
          ? normalizeTime(this.getFinishingEndTimeMinutes() + timeDiff)
          : null;

        this.set(
          {
            appointmentDate: date || appointmentDate,
            startTime: Wahanda.Time.toApiString(newStartTime),
            endTime: Wahanda.Time.toApiString(newEndTime),
            finishingEndTime: newFT !== null ? Wahanda.Time.toApiString(newFT) : null,
            processingEndTime: newPT !== null ? Wahanda.Time.toApiString(newPT) : null,
            cleanupEndTime: newCT !== null ? Wahanda.Time.toApiString(newCT) : null,
          },
          { silent: false },
        );
      },

      /**
       * Move the Appointment to new start date, keeping the duration the same.
       *
       * @param {Date} newDate The new start date time
       * @returns {void}
       */
      moveTo(newDate) {
        this.move(
          Wahanda.Time.getDateMinutes(newDate) - this.getStartTimeMinutes(),
          Wahanda.Date.toApiString(newDate),
        );
      },

      getAppointmentGroups(model) {
        return App.Models.Appointment.getAppointmentGroups(model);
      },

      formatAppointmentModel(model) {
        return App.Models.Appointment.formatAppointmentModel(model);
      },

      /**
       * Returns date information in venue format:
       *
       * @return Object { date, time, weekday }
       */
      getDateInfo() {
        const date = this.getStartDate();
        const weekday = Wahanda.lang.date.weekdays[date.getDay()];
        const dateObj = Wahanda.Date.formatToDefaultFullDate(date);
        dateObj.weekday = weekday;
        return dateObj;
      },

      getStatusText() {
        return App.Models.Appointment.getStatusText(this.get('appointmentStatusCode'));
      },

      getBookingActorData() {
        return AppointmentModel.getBookingActorData(this);
      },

      getDeviceText() {
        return AppointmentModel.getDeviceText(this);
      },

      getStatusClass() {
        return App.Models.Appointment.getStatusClass(this.get('appointmentStatusCode'));
      },

      getConsumerName() {
        if (this.canShowCustomerContacts()) {
          let name;

          if (this.get('walkIn')) {
            if (this.get('anonymousNote')) {
              name = this.get('anonymousNote');
            } else {
              name = Wahanda.lang.shared.walkin;
            }
          } else {
            name = this.get('recipientName') || this.get('consumerName');
          }
          return name || '';
        }

        return this.get('recipientFirstName') || this.get('consumerFirstName');
      },

      copy(employeeId, date) {
        const startDateTime = Wahanda.Date.formatToApiFullDate(date);
        const diffTime =
          Wahanda.Time.timeToMinutes(startDateTime.time) -
          Wahanda.Time.timeToMinutes(this.get('startTime'));
        const endTimeInMins = Wahanda.Time.timeToMinutes(this.get('endTime')) + diffTime;
        const copy = this.clone();
        copy.set('id', null);
        copy.set('employeeId', employeeId);
        copy.set('appointmentDate', startDateTime.date);
        copy.set('startTime', startDateTime.time);
        copy.set('endTime', Wahanda.Time.toApiString(endTimeInMins));
        return copy;
      },

      createNew() {
        const clone = this.clone();
        clone.set('id', null);
        clone.set('bookingActor', 'SUPPLIER');
        clone.set('platform', 'DESKTOP');
        return clone;
      },

      /**
       * Confirm the appointment
       *
       * @param function success
       * @param function error
       * @param boolean doSilent OPTIONAL Should any errors be not reported by the global error reporting util?
       *
       * @returns Promise
       */
      confirm(success, error, doSilent) {
        const url = App.Api.wsVenueUrl(`/appointment/${this.id}/confirm.json`);
        const self = this;
        return xhr.doJQueryAjax({
          url,
          success() {
            self.trigger('confirmed');
            App.trigger(Wahanda.Event.APPOINTMENT_CONFIRMED, self.id);
            _.isFunction(success) && success();

            self.persistRebookingData();
          },
          error,
          dataType: 'json',
          type: 'POST',
          contentType: 'application/json',
          skipErrorHandling: doSilent,
        });
      },

      reject(params) {
        const url = apiUrl('CANCEL_APPOINTMENT', { id: this.id });
        const self = this;

        return xhr.doJQueryAjax({
          url,
          data: JSON.stringify(params.data),
          success() {
            self.trigger('rejected');
            App.trigger(Wahanda.Event.APPOINTMENT_REJECTED, self.id);
            _.isFunction(params.success) && params.success();
          },
          error: params.error,
          dataType: 'json',
          type: 'POST',
          contentType: 'application/json',
        });
      },

      isFree() {
        return this.get('amount') <= 0;
      },

      isPaidByClient() {
        return !!(this.isPrepaid() || this.get('hasEvoucher'));
      },

      isPrepaid() {
        return (this.isWahandaBooking() || this.isWidgetBooking()) && !this.isPayAtVenue();
      },

      isPayAtVenue() {
        return this.get('payAtVenue') === true;
      },

      /**
       * Is this appointment unpaid, scheduled for today and is due in less than one hour?
       *
       * @return boolean
       */
      isUnpaidAndSoon() {
        if (!this.isPayAtVenue() || this.isCheckedOut() || this.isPaymentProtectionApplied()) {
          return false;
        }
        const date = this.getStartDate();
        if (!Wahanda.Date.isToday(date)) {
          return false;
        }
        const diff = date.getTime() - Wahanda.Date.createVenueDate().getTime();
        return diff < 3600 * 1000;
      },

      isPaymentProtectionApplied() {
        return Boolean(this.get('paymentProtectionApplied'));
      },

      isRecurring() {
        return Boolean(this.get('recurrencePropertiesId'));
      },
      /**
       * Is the appointment unpaid?
       *
       * @param Object options
       *   > onlyPayAtVenue (boolean, optional) Should only payAtVenue appointments be marked as unpaid?
       *
       * @returns boolean
       */
      isUnpaid(options) {
        const onlyPayAtVenue = options && options.onlyPayAtVenue;
        if (this.isCheckedOut() || this.isPaymentProtectionApplied()) {
          // All completed appts are "paid"
          return false;
        }
        if (this.isWahandaBooking() || this.isWidgetBooking() || onlyPayAtVenue) {
          return this.isPayAtVenue();
        }
        // All other booking sources are unpaid, unless the eVoucher ref. was attached
        return !this.get('hasEvoucher');
      },

      getPrepaidAmount() {
        if (!this.isPrepaid()) {
          return 0;
        }
        const amount = this.get('amount');
        return amount > 0 ? amount : 0;
      },

      isWahandaBooking() {
        return this.isMarketplaceBooking();
      },

      isMarketplaceBooking() {
        return this.get('bookingActor') === this.CHANNEL_CUSTOMER;
      },

      isWidgetBooking() {
        return this.get('bookingActor') === this.CHANNEL_SUPCUS;
      },

      isConnectBooking() {
        return this.get('bookingActor') === this.CHANNEL_LOCAL;
      },

      isWalkin() {
        return this.get('walkIn');
      },

      isFirstTimeCustomer() {
        return this.get('firstTimeCustomer');
      },

      hasCancellationPeriodPassed() {
        const endDate = this.getCancellationPeriodEndDate();
        if (endDate == null) {
          return true;
        }

        const venueDate = Wahanda.Date.createVenueDate();
        return endDate.getTime() < venueDate.getTime();
      },

      getCancellationPeriodEndDate() {
        const endDate = this.get('cancellationPeriodEndDate');
        if (endDate == null) {
          return null;
        }
        return Wahanda.Date.parse(endDate);
      },

      isOrderWithNoShows() {
        const orderId = this.get('orderId');

        if (!orderId) {
          // No order, hence no need to check
          return false;
        }

        const models = this.collection && this.collection.models;

        if (!models) {
          // Sanity check like in similar places. It's not clear whether models can be undefined, though.
          return false;
        }

        return models.some(
          (appointment) => appointment.get('orderId') === orderId && appointment.isNoShow(),
        );
      },

      /**
       * Is this appointment in the past?
       *
       * @return boolean
       */
      isInThePast() {
        return this.getStartDate().getTime() < Wahanda.Date.createVenueDate().getTime();
      },

      /**
       * @return boolean
       */
      hasFirstUdvAppointmentStarted() {
        const firstAppointmentInUdv =
          this.collection && this.collection.models && this.collection.models[0];

        if (!firstAppointmentInUdv) {
          return false;
        }

        return (
          firstAppointmentInUdv.getStartDate().getTime() < Wahanda.Date.createVenueDate().getTime()
        );
      },

      /**
       * Is this appointment same day as venue working day or in the future?
       *
       * @return boolean
       */
      isOnTheSameDayOrLater() {
        const dateDifference = Wahanda.Date.getDayDifference(
          Wahanda.Date.createVenueDate(),
          this.getStartDate(),
        );
        return dateDifference <= 0;
      },

      /**
       * Override of the DESTROY call. It POSTs the data, instead of issuing an DELETE call.
       *
       * @param Object options
       * @return jqXHR
       */
      destroy(options) {
        const newOptions = options || {};
        newOptions.type = 'POST';
        newOptions.url = apiUrl('CANCEL_APPOINTMENT', { id: this.id });
        newOptions.contentType = 'application/json';
        newOptions.data = JSON.stringify(options.data);

        const oldSuccess = options.success;
        const id = this.id;
        const self = this;
        newOptions.success = function () {
          App.trigger(Wahanda.Event.APPOINTMENT_CANCELLED, id);
          self.trigger('deleted');
          _.isFunction(oldSuccess) && oldSuccess.apply(this, arguments);
        };

        return App.Models.CalendarObjects.__super__.destroy.call(this, newOptions);
      },

      /**
       * Set this appointment as no show.
       *
       * @param Object options
       * > data
       * > success
       * > error
       *
       * @return jqXHR
       */
      setNoShow(options) {
        const self = this;
        const oldSuccess = options.success;
        const id = this.id;
        options.success = function () {
          self.trigger('no-show');
          App.trigger(Wahanda.Event.APPOINTMENT_SET_NOSHOW, id);
          _.isFunction(oldSuccess) && oldSuccess.apply(this, arguments);
        };

        return xhr.doJQueryAjax({
          url: apiUrl('NO_SHOW_APPOINTMENT', { id: this.id }),
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            notifyConsumer: true,
            preventPaymentProtection: options.preventPaymentProtection,
          }),
          success: options.success,
          error: options.error,
        });
      },

      /**
       * Calculates and returns the appointment's duration.
       *
       * @param {bool} withAdditionalTime (optional) Should additional time be included? E.g. cleanup or processing.
       * @return {int or null} if start and end times aren't set.
       */
      getDuration(withAdditionalTime) {
        if (this.get('startTime') && this.get('endTime')) {
          return (
            this.getEndTimeMinutes(withAdditionalTime) -
            Wahanda.Time.timeToMinutes(this.get('startTime'))
          );
        }
        return null;
      },

      getRescheduleDuration() {
        let duration = 0;
        duration += this.getDuration();
        duration += this.getCleanupTimeLength();
        return duration;
      },

      isRescheduled() {
        if (this.hasChanged('appointmentDate') || this.hasChanged('startTime')) {
          return true;
        }
        return false;
      },

      isConfirmed() {
        return this.get('appointmentStatusCode') === this.STATUS_CONFIRMED;
      },

      isUnconfirmed() {
        return this.get('appointmentStatusCode') === this.STATUS_CREATED;
      },

      isCheckedOut() {
        return this.get('appointmentStatusCode') === this.STATUS_CHECKED_OUT;
      },

      isNoShow() {
        return this.get('appointmentStatusCode') === this.STATUS_NO_SHOW;
      },

      isCancelled() {
        return this.get('appointmentStatusCode') === this.STATUS_CANCELLED;
      },

      isRejected() {
        return this.get('appointmentStatusCode') === this.STATUS_REJECTED;
      },

      noShowTimeLimitExceeded() {
        return toMoment(now()).isAfter(this.get('noShowTimeLimit'));
      },

      getAmountText() {
        let price;
        if (this.isCheckedOut()) {
          price = this.get('checkout') && this.get('checkout').serviceAmount;
        } else {
          price = this.get('amount');
        }
        if (price) {
          return Wahanda.Currency.getFormatted(price);
        }
        return '';
      },

      getAdditionalAmountText() {
        if (this.hasAdditionalCheckoutAmount()) {
          return Wahanda.Currency.getFormatted(this.get('checkout').additionalAmount);
        }
        return '';
      },

      getCheckoutTotal() {
        if (this.isCheckedOut()) {
          return this.get('checkout').total;
        }
        return null;
      },

      getCheckoutTotalText() {
        if (this.isCheckedOut()) {
          return Wahanda.Currency.getFormatted(this.getCheckoutTotal());
        }
        return '';
      },

      hasAdditionalCheckoutAmount() {
        return this.isCheckedOut() && !!this.get('checkout').additionalAmount;
      },

      getVenueName() {
        const venue = this.get('venue');
        if (venue && venue.name) {
          return venue.name;
        }
        return '';
      },

      /**
       * Persists all information that was captured on the POS form.
       *
       * @param Object checkoutModel representation of the checkout.
       * @param Object options additional options, used to supply optional callbacks [done / fail]
       */
      checkoutPOS(checkoutModel, options) {
        if (!this.id) {
          throw new Error('Can not check out a not saved appointment');
        }
        const url = apiUrl('CHECKOUT');
        const id = this.id;
        const self = this;

        xhr
          .doJQueryAjax({
            url,
            type: 'post',
            data: JSON.stringify(checkoutModel),
            contentType: 'application/json',
          })
          .done(() => {
            App.trigger(Wahanda.Event.APPOINTMENT_CHECKED_OUT, { id });
            self.trigger('checked-out');
          })
          // Optional callbacks
          .done(options.success)
          .fail(options.error);
      },

      /**
       * Persist the appointment's data for rebooking.
       */
      persistRebookingData() {
        const customerId = this.get('venueCustomerId');
        const data = {
          offerId: this.get('offerId'),
          skus: this.get('skus'),
          duration: this.getDuration(),
          walkIn: this.isWalkin(),
          customer: {
            id: customerId,
            name: this.get('consumerName'),
            walkinName: this.get('anonymousNote'),
          },
        };

        AppointmentModel.setRebookingData(data);
      },

      /**
       * Return employee or menu group id - which is set.
       *
       * @return int or null
       */
      /* can't find this being used anywhere so probably can be deleted */
      getCalendarResourceId() {
        return this.get('employeeId') || this.get('menuGroupId') || null;
      },

      getDateCreated() {
        return Wahanda.Date.parse(this.get('created'));
      },

      /**
       * Returns an Object with actions and if they are valid (true/false).
       *
       * @return Object
       * > canShowDeleteButton - If the delete button can be shown.
       */
      getActionsValidity() {
        const isUnconfirmed = this.isUnconfirmed();
        const isCancelled = this.isCancelled();
        let canEdit = !isCancelled;
        const isCheckedOut = this.isCheckedOut();
        const inThePast = this.isInThePast();
        const standalone = !this.belongsToGroup();
        const canAccept = isUnconfirmed && !inThePast && standalone;
        // Can checkout only appointments with past start time AND Confirmed appointments AND not yet checked out appointments.
        // Button not shown if user does not have edit permission for this appointment.
        let canCheckout = this.isConfirmed() && inThePast && standalone;

        if (canEdit) {
          canEdit = Wahanda.Permissions.editAnyCalendar();
          if (!canEdit && Wahanda.Permissions.editOwnCalendar()) {
            canEdit = this.get('employeeId') === App.config.getAccountEmployeeId();
          }
          canCheckout = canCheckout && canEdit;
        }

        // Can't delete Widget bookings until they are Confirmed
        const widgetDelete = !this.isWidgetBooking() || !isUnconfirmed;

        const canDeleteMarketplaceBookings =
          this.isWahandaBooking() &&
          this.isOnTheSameDayOrLater() &&
          App.isFeatureSupported('cancel-mp-appts') &&
          App.config.get('venue').marketplaceAppointmentCancellationEnabled;
        const canReschedule = this.canBeRescheduled();
        const notMarketplaceOrCanDeleteMarketplaceBookings =
          !this.isWahandaBooking() || canDeleteMarketplaceBookings;

        const canSetNoShow =
          canEdit &&
          this.hasFirstUdvAppointmentStarted() &&
          this.hasCancellationPeriodPassed() &&
          this.isConfirmed() &&
          !this.noShowTimeLimitExceeded();

        return {
          canAccept,
          canShowReject: canAccept && canEdit && Wahanda.Permissions.canDeleteAppointments(),
          canReject: canAccept && canEdit && notMarketplaceOrCanDeleteMarketplaceBookings,
          rejectPartOfMultiServiceOrder:
            isUnconfirmed && !inThePast && canEdit && notMarketplaceOrCanDeleteMarketplaceBookings,
          canEdit,
          canShowDelete:
            Wahanda.Permissions.canDeleteAppointments() &&
            this.id > 0 &&
            canEdit &&
            !isCheckedOut &&
            widgetDelete &&
            !isUnconfirmed &&
            !this.isOrderWithNoShows(),
          canDelete:
            this.id > 0 &&
            canEdit &&
            !isCheckedOut &&
            widgetDelete &&
            !isUnconfirmed &&
            notMarketplaceOrCanDeleteMarketplaceBookings,
          canCheckout,
          canSetNoShow,
          canShowReschedule:
            this.id > 0 &&
            canEdit &&
            !isCheckedOut &&
            canReschedule &&
            !App.isApp(window.location, navigator.userAgent),
        };
      },

      isReadOnly() {
        return this.isCheckedOut() || this.isNoShow() || this.isCancelled() || this.isRejected();
      },

      /**
       * List of payment states (badges) with boolean flags marking which ones should be shown.
       *
       * @return Object { (prepaid,unpaid,paidAtVenue): Boolean  }
       */
      getPaymentStates() {
        const isPrePay = this.isPaidByClient();
        const isFree = this.isFree();
        return {
          free: isFree,
          pricePrepaid: isPrePay && !isFree,
          totalPrepaid: isPrePay && !this.hasAdditionalCheckoutAmount() && !isFree,
          additionalAmountPaid: this.hasAdditionalCheckoutAmount() && isPrePay,
          unpaid: this.isUnpaid() && !isFree,
          paidAtVenue: !isPrePay && this.isCheckedOut(),
        };
      },

      // can't find this being used anywhere
      // should be deleted
      shouldShowSelectedEmployeeNotification() {
        if (this.id && (this.isWahandaBooking() || this.isWidgetBooking())) {
          return !!this.get('employeeSelected');
        }
        return false;
      },

      /**
       * Is a discount (of any type) applied for this appointment?
       *
       * @return boolean
       */
      isDiscountApplied() {
        return !!this.get('discount');
      },

      /**
       * Is a Just In Time discount applied for this appointment?
       *
       * @return boolean
       */
      isJitDiscountApplied() {
        const discount = this.get('discount');
        if (!discount) {
          return false;
        }
        return discount.type === AppointmentModel.DISCOUNT_TYPE_JIT;
      },

      isValidForEmailNotifications() {
        if (!this.id) {
          return false;
        }
        if (!this.get('consumerEmail') || !App.config.get('venue').consumerEmailNotifications) {
          return false;
        }
        return true;
      },

      /**
       * Should a checkbox be shown to the supplier asking to confirm if he wants to
       * notify the customer of the rescheduling?
       *
       * @param Date apptDate
       * @param Object apptTimeRange { startTime: "HH:MM", endTime: "HH:MM" }
       * @return Boolean
       */
      shouldShowClientReschedulingNotification(apptDate, apptTimeRange) {
        return (
          this.isValidForEmailNotifications() &&
          (this.get('appointmentDate') !== Wahanda.Date.toApiString(apptDate) ||
            apptTimeRange.startTime !== Wahanda.Time.timeToMinutes(this.get('startTime')))
        );
      },

      shouldNotifyCustomerOfDeletion() {
        return this.isValidForEmailNotifications();
      },

      isBlockOutOriginalTimeAllowed(apptDate, apptTimeRange) {
        if (!this.id) {
          return false;
        }
        let showCheckbox = this.get('appointmentDate') !== Wahanda.Date.toApiString(apptDate);
        if (!showCheckbox) {
          showCheckbox = !Wahanda.Time.doesOverlap(
            { from: apptTimeRange.startTime, to: apptTimeRange.endTime },
            { from: this.get('startTime'), to: this.get('endTime') },
            false,
          );
        }
        return showCheckbox;
      },

      /**
       * Returns new instance of TimeBlock model from the appintment's data.
       *
       * @param boolean fromCurrentValues (optionaL) Should the TimeBlock be created from current values?
       *      Defaults to false so that the previous values would be taken.
       *
       * @return App.Models.TimeBlock
       */
      createBlock(forCurrentValues) {
        const method = forCurrentValues ? 'get' : 'previous';
        const block = new App.Models.TimeBlock({
          venueId: App.getVenueId(),
          dateFrom: this[method]('appointmentDate'),
          timeFrom: this[method]('startTime'),
          dateTo: this[method]('appointmentDate'),
          timeTo: this[method]('endTime'),
          availabilityRuleTypeCode: 'D',
        });
        block.set('employeeId', this[method]('employeeId'));
        return block;
      },

      canNotifyCustomerAboutRescheduling() {
        if (
          this.id &&
          this.get('consumerEmail') &&
          App.config.get('venue').consumerEmailNotifications
        ) {
          return this.isRescheduled();
        }
        return false;
      },

      getPaymentStateName(options) {
        if (this.isCheckedOut()) {
          return 'checked-out';
        }
        if (this.isUnpaid(options)) {
          return 'unpaid';
        }
        if (this.isPrepaid()) {
          return 'paid';
        }
        return '';
      },

      getAppointmentCleanupTimeLength() {
        return (
          this.getCleanupTimeLength() -
          (this.getFinishingTimeLength() || this.getProcessingTimeLength())
        );
      },

      getCleanupTimeLength() {
        return this._getMinutesSinceEndTime('cleanupEndTime');
      },

      getCleanupEndTimeDate() {
        if (this.get('cleanupEndTime')) {
          return Wahanda.Date.createDate(this.get('appointmentDate'), this.get('cleanupEndTime'));
        }
        return null;
      },

      getProcessingTimeLength() {
        return this._getMinutesSinceEndTime('processingEndTime');
      },

      getProcessingEndTimeDate() {
        if (this.get('processingEndTime')) {
          return Wahanda.Date.createDate(
            this.get('appointmentDate'),
            this.get('processingEndTime'),
          );
        }
        return null;
      },

      getFinishingTimeLength() {
        return this._getMinutesSinceEndTime('finishingEndTime');
      },

      getFinishingEndTimeDate() {
        if (this.get('finishingEndTime')) {
          return Wahanda.Date.createDate(this.get('appointmentDate'), this.get('finishingEndTime'));
        }
        return null;
      },

      _getMinutesSinceEndTime(param) {
        const ct = this.get(param);
        const et = this.get('endTime');
        if (!ct) {
          return 0;
        }
        const ttm = Wahanda.Time.timeToMinutes;

        return Math.max(ttm(ct) - ttm(et), 0);
      },

      /**
       * Return the percentage which cleanup time is taking from the whole appt + cleanupTime length.
       *
       * @returns Number
       */
      getCleanupTimeLengthInPercentage() {
        const hasFinishingTime =
          this.getFinishingTimeLengthInPercentage() > 0 ||
          (this.getFinishingTimeLengthInPercentage() === 0 &&
            this.getProcessingTimeLengthInPercentage() > 0);
        return this._getPercentageOfLengthSinceEndTime('cleanupEndTime', !hasFinishingTime);
      },

      /**
       * Return the percentage which processing time is taking from the whole appt length.
       *
       * @returns Number
       */
      getProcessingTimeLengthInPercentage() {
        return this._getPercentageOfLengthSinceEndTime('processingEndTime', false);
      },

      /**
       * Return the percentage which finishing time is taking from the whole appt length.
       *
       * @returns Number
       */
      getFinishingTimeLengthInPercentage() {
        return this._getPercentageOfLengthSinceEndTime('finishingEndTime', false);
      },

      _getPercentageOfLengthSinceEndTime(param, includeCustomDuration) {
        const time = this._getMinutesSinceEndTime(param);
        if (!time) {
          return 0;
        }
        const duration = this.getDuration() + (includeCustomDuration ? time : 0);
        return (time / duration) * 100;
      },

      /**
       * Does the appointment start on the grid's start time?
       *
       * @return Boolean
       */
      startTimeMatchesGrid() {
        const startTime = this.get('startTime');
        if (!startTime) {
          return true;
        }
        return (
          Wahanda.Time.timeToMinutes(startTime) %
            App.config.get('venue').appointmentSlotDuration ===
          0
        );
      },

      /**
       * Return the Customer model populated with the Appointment's data.
       * Note: It's not the full model, just a represantation with data the Appt contains.
       *
       * @returns App.Models.Customer
       */
      getCustomer() {
        return new App.Models.Customer({
          id: this.get('venueCustomerId'),
          appointmentDate: this.get('appointmentDate'),
          name: this.get('consumerName'),
          firstName: this.get('consumerName'),
          recipientName: this.get('recipientName'),
          emailAddress: this.get('consumerEmail'),
          prepaymentRequired: this.get('consumerPrepaymentRequired'),
          phone: this.get('consumerPhone'),
          active: this.get('consumerActive'),
          notes: this.get('consumerNotes'),
          walkIn: this.get('consumerName') === null,
          anonymousNote: this.get('anonymousNote'),
        });
      },

      saveSuccess() {
        const isNew = !this.id;

        App.trigger(Wahanda.Event.APPOINTMENT_SAVED, this, {
          isNew,
        });
        this.trigger('saved', { isNew });
        this.persistRebookingData();
      },

      saveCreatedAppointments(models) {
        return xhr.putWithPromise(this.url(), models).done(this.saveSuccess());
      },

      saveNewAppointments() {
        const groups = (this.collection && this.collection.models) || [];
        const data = {
          appointmentGroups: this.getAppointmentGroups(groups),
          appointments: [this.formatAppointmentModel(this)],
          anonymousNote: this.getCustomer().anonymousNote,
          venueCustomerId: this.getCustomer().id,
        };

        return xhr.postWithPromise(this.url(), data).done(this.saveSuccess());
      },

      // @Override
      save() {
        const isNew = !this.id;
        const model = this;

        if (isNew) {
          return this.saveNewAppointments();
        }

        return this.saveCreatedAppointments(model);
      },

      getPlatformName() {
        return AppointmentModel.getPlatformName(this.get('platform'));
      },

      /**
       * Convert the model to a readable string representation.
       *
       * @return String
       */
      toString() {
        const tpl = 'Appt "{{name}}" on {{date}} ({{from}} - {{to}}) for {{customer}}';
        return Wahanda.Template.render(tpl, {
          name: this.getOfferName(),
          date: Wahanda.Date.toApiString(this.getStartDate()),
          from: this.get('startTime'),
          to: this.get('endTime'),
          customer: this.get('consumerName') || 'Walk In',
        });
      },

      belongsToGroup(groupId) {
        const groupIds = this.get('appointmentGroupIds') || [];
        if (groupIds.length === 0) {
          // No groups assigned.
          return false;
        }
        if (groupId > 0) {
          // Belongs to a specific group?
          return _.indexOf(groupIds, groupId) !== -1;
        }
        // Belongs to any group?
        return true;
      },

      getGroupId() {
        return (this.get('appointmentGroupIds') || [])[0];
      },

      eachGroup(callback) {
        _.each(this.get('appointmentGroupIds'), callback, this);
      },

      getOfferName() {
        return this.get('offerName');
      },

      /**
       * Get array of appointment structures for Appointment Group creation.
       *
       * @param App.Collections.MenuOffers offersCollection
       *
       * @return Array
       */
      getStructureForPackageGroup(offersCollection) {
        const template = this._getSaveData();
        delete template.id;

        const groupOffer = offersCollection.get(this.get('offerId'));
        const skus = groupOffer.getActiveSkus() || [];

        const firstStartTime = this.getStartTimeMinutes();
        let totalDuration = 0;

        let sku;
        const skuId = this.get('skus') && this.get('skus')[0] && this.get('skus')[0].skuId;
        if (_.findWhere(skus, { id: skuId })) {
          sku = _.findWhere(skus, { id: skuId });
        } else {
          sku = skus[0];
        }

        const apptItems = [];
        const itemByOfferId = {};

        // First, set the Appointment structure with base info.
        // Also combine all same-offer skus into a multi-sku appointment.
        _.each((sku || {}).subSkus, (skuEl) => {
          const offer = offersCollection.get(skuEl.offerId);

          let item;
          if (!itemByOfferId[offer.id]) {
            item = _.extend({}, template);
            item.offerId = skuEl.offerId;
            item.skus = [];
            item.notes = null;
            item.duration = 0;

            itemByOfferId[offer.id] = item;
            apptItems.push(item);
          } else {
            item = itemByOfferId[offer.id];
          }

          item.duration += skuEl.duration;
          item.skus.push({
            skuId: skuEl.id,
            skuName: skuEl.name,
          });

          // Save for the next loop
          item.offerInstance = offer;
        });
        // The second iteration is for setting the correct durations and start/end times
        _.each(apptItems, (item) => {
          const offer = item.offerInstance;
          const apptDuration = item.duration;
          const skuIdd = item.skus[0].skuId;
          delete item.duration;
          delete item.offerInstance;

          const finishingTimeMins =
            offer.getSku(skuIdd) && offer.getSku(skuIdd).get('finishingTimeMins');

          const apptTotalDuration =
            apptDuration +
            offer.getAdditionalTimeMins() +
            (!!finishingTimeMins && finishingTimeMins);
          const processingTimeMins = offer.get('processingTimeMins');
          const apptStartTime = firstStartTime + totalDuration;
          const apptEndTime = apptStartTime + apptDuration;

          item.startTime = Wahanda.Time.toApiString(apptStartTime);
          item.endTime = Wahanda.Time.toApiString(apptEndTime);
          item.processingEndTime =
            processingTimeMins !== null
              ? Wahanda.Time.toApiString(apptEndTime + processingTimeMins)
              : null;
          item.finishingEndTime =
            finishingTimeMins !== null
              ? Wahanda.Time.toApiString(apptEndTime + finishingTimeMins + processingTimeMins)
              : null;

          totalDuration += apptTotalDuration;
        });

        return apptItems;
      },

      /**
       * Add a link to the Appointment Group.
       *
       * @param App.Collections.AppointmentGroup
       */
      linkWithGroup(group) {
        this.groups[group.id] = group;
      },

      getLinkedPackageGroup() {
        return _.find(this.groups, (group) => group.isPackageType());
      },

      /**
       * Get model setup with previous() attributes of the current model.
       */
      getPreviousModel() {
        return new App.Models.Appointment(this.previousAttributes());
      },

      /**
       * Get notes for the Appointment or any Package it belongs to.
       *
       * @returns {String} The notes
       */
      getAnyNotes() {
        let notes = this.get('notes');

        if (!notes) {
          const pkgGroup = this.getLinkedPackageGroup();

          if (pkgGroup) {
            notes = pkgGroup.getNotes();
          }
        }
        return notes;
      },

      canBeRescheduled() {
        if (!this.id) {
          return true;
        }

        return (
          (!this.isMarketplaceBooking() || App.config.canRescheduleMarketplaceAppointments()) &&
          this.canUserEditAppointment() &&
          !this.isNoShow()
        );
      },

      canUserEditAppointment() {
        return Wahanda.Permissions.editRotaCalendar(this.get('employeeId'));
      },

      canShowCustomerContacts(config) {
        if (!this.isMarketplaceBooking()) {
          return true;
        }

        return Wahanda.canShowCustomerContactsForItem(
          this.getStartDate(),
          !this.isUnconfirmed(),
          config,
          this.isFirstTimeCustomer(),
        );
      },

      // Check if the current state of the appointment is equal from the given one.
      // @returns Bool
      isEqualTo(apptData) {
        return _.isEqual(apptData, this.attributes);
      },

      getIdHash() {
        return `a:${this.id}`;
      },

      hasArchivedOffer(offersCollection) {
        const id = this.get('offerId');
        const offer = offersCollection.get(id);
        const skuIds = _.pluck(this.get('skus'), 'skuId');

        return !offer || offer.isArchived() || !offer.hasAllSkus(skuIds);
      },
    },
    {
      DISCOUNT_TYPE_JIT: 'JIT',
      DISCOUNT_TYPE_OFFPEAK: 'OFFPEAK',
      DISCOUNT_TYPE_SALE: 'SALE_PRICE',
      // Booking channels
      CHANNEL_CUSTOMER,
      CHANNEL_SUPCUS,
      CHANNEL_LOCAL,

      getRebookingStorageKey() {
        return `appt-rebooking-${App.getVenueId()}`;
      },

      /**
       * Return previously set rebooking data for the current venue.
       *
       * If there is no data, or the data is expired, NULL is returned.
       *
       * @return Object
       */
      getRebookingData() {
        const key = AppointmentModel.getRebookingStorageKey();
        let data = Wahanda.LocalStorage.get(key);

        if (!data || data.validUntil < new Date().getTime()) {
          data = null;
          Wahanda.LocalStorage.remove(key);
        }

        return data;
      },

      /**
       * Store the data for rebooking.
       *
       * The data will be valid 1 hours from the time it is persisted.
       *
       * @param Object data
       */
      setRebookingData(data) {
        const storageKey = AppointmentModel.getRebookingStorageKey();
        // Valid 1 hour from now
        data.validUntil = new Date().getTime() + 3600 * 1000;

        Wahanda.LocalStorage.set(storageKey, data);
      },

      /**
       * Redirect to calendar view for rebooking. Or, if in calendar view already, just change the hash.
       *
       * @param Function callbackIfLocal (optional) Callback to execute if this is the calendar view.
       */
      redirectToRebooking(callbackIfLocal) {
        const hash = `#venue/${App.getVenueId()}/appointment`;
        if (App.mainView instanceof App.Views.Calendar) {
          window.location.hash = hash;
          callbackIfLocal && callbackIfLocal();
        } else {
          window.location = `/calendar${hash}`;
        }
      },

      getStatusClass(status) {
        return apptStatusToClass[status] || '';
      },

      getStatusText(status) {
        switch (status) {
          case 'CN':
            return Wahanda.lang.calendar.appointments.types.confirmed;
          case 'CR':
            return Wahanda.lang.calendar.appointments.types.unconfirmed;
          case 'RJ':
            return Wahanda.lang.calendar.appointments.types.rejected;
          case 'CC':
            return Wahanda.lang.calendar.appointments.types.cancelled;
          case 'CP':
            return Wahanda.lang.calendar.appointments.types.checkedOut;
          case 'NS':
            return Wahanda.lang.calendar.appointments.types.noShow;
          default:
            return '';
        }
      },

      getDeviceText(appointment) {
        const data = {
          platform: AppointmentModel.getPlatformName(appointment.get('platform')),
          name:
            appointment.isConnectBooking() && appointment.get('createdByName') === null
              ? App.config.get('channel').marketplaceName
              : appointment.get('createdByName'),
        };
        const deviceTemplate = Wahanda.lang.calendar.appointments.channels.deviceTemplate;
        return Wahanda.Template.render(deviceTemplate, data);
      },

      getBookingActorData: function getBookingActorData(appointment) {
        const data = {
          icon: null,
          name:
            appointment.isConnectBooking() && appointment.get('createdByName') === null
              ? App.config.get('channel').marketplaceName
              : appointment.get('createdByName'),
          date: moment(appointment.getDateCreated()).format('DD MMM'),
        };

        let textTemplate;
        let htmlTemplate;

        const channelsLang = Wahanda.lang.calendar.appointments.channels;

        switch (appointment.get('bookingActor')) {
          case CHANNEL_LOCAL:
            textTemplate = channelsLang.supplierTemplate;
            htmlTemplate = Wahanda.Template.render(channelsLang.bookedViaConnect, {
              date: data.date,
              name: data.name,
            });
            data.platform = AppointmentModel.getPlatformName(appointment.get('platform'));
            break;
          case CHANNEL_SUPCUS:
            textTemplate = channelsLang.customerTemplate;
            htmlTemplate = Wahanda.Template.render(channelsLang.bookedViaWidget, {
              date: data.date,
            });
            data.platform = channelsLang.widget;
            break;
          case CHANNEL_CUSTOMER:
          default: {
            const channelCode = appointment.get('channelCode');
            if (channelCode && channelCode.startsWith(APPOINTMENT_CHANNEL_CODE.BOOKED_BY_GOOGLE)) {
              htmlTemplate = Wahanda.Template.render(channelsLang.bookedViaGoogle, {
                date: data.date,
              });
            } else {
              htmlTemplate = Wahanda.Template.render(channelsLang.bookedViaTreatwell, {
                date: data.date,
              });
            }
            data.platform = channelsLang.customer;
            data.icon = 'treatwell';
            textTemplate = channelsLang.customerTemplate;
          }
        }

        data.html = htmlTemplate;

        if (
          Wahanda.Permissions.viewFinanceData() &&
          appointment.get('treatwellFeePercentage') != null
        ) {
          const feeSuffix = Wahanda.Template.render(channelsLang.feeSuffix, {
            fee: appointment.get('treatwellFeePercentage'),
          });
          const commissionsLinkPart =
            !!channelsLang.feeSuffixCommissionsLink &&
            ` (<a href="${channelsLang.feeSuffixCommissionsLink}" class="js-fee-commissions-link">${feeSuffix}</a>)`;
          data.html += commissionsLinkPart || ` (${feeSuffix})`;
        }

        const dateTime = Wahanda.Date.formatDateTime(
          App.config.get('jqueryDateFormat').longDate,
          App.config.get('jqueryDateFormat').defaultTime,
          appointment.getDateCreated(),
        );
        data.text = Wahanda.Template.render(textTemplate, {
          date: `${dateTime.date} ${dateTime.time}`,
        });

        if (appointment.get('orderId')) {
          const refText = Wahanda.Template.render(channelsLang.orderRef, {
            orderRef: appointment.get('orderReference'),
          });
          const refPart = `<span class="js-order-ref link">${refText}</span>`;
          data.html += `. ${refPart}`;
        }

        if (appointment.get('evoucher')) {
          const evoucherPart = Wahanda.Template.render(channelsLang.withEvoucher, {
            evoucherRef: `<span class="js-evoucher link">${
              appointment.get('evoucher').reference
            }</span>`,
          });
          data.html += ` ${evoucherPart}`;
        }

        data.html += '.';

        // Use the new source icons, if available
        const isNewIcon = /wahanda|zensoon/.test(data.icon);
        data.iconClass = (isNewIcon ? 'icons-source2-' : 'icons-source-') + data.icon;

        return data;
      },

      /**
       * Returns the platform channel name used when booking.
       *
       * @param String platform
       *
       * @returns String
       */
      getPlatformName(platform) {
        const list = Wahanda.lang.calendar.appointments.channels.connectPlatform;
        if (platform === 'IPAD') {
          platform = 'IOS';
        }
        if (!(platform in list)) {
          platform = 'DESKTOP';
        }
        return list[platform];
      },

      /**
       * Returns the consumer date attribute used to identify appointments
       * a consumer has purchased on a single day.
       *
       */

      getConsumerDateId(venueCustomerId, date) {
        return `${venueCustomerId}-${Wahanda.Date.formatDate('yymmd', date)}`;
      },

      /**
       * How do these appointments compere in time? Is there a gap, do they overlap or go one after another?
       * NOTE! The dates *are not* checked.
       *
       * @param App.Models.Appointment model1
       * @param App.Models.Appointment model2
       * @param Object options (optional)
       * > int increment Amount of minutes to increase (or decrease) first model's end time.
       * > boolean keepModelOrder Should the order be kept? If so, then mixed-up models (e.g. model1 > model2) will
       *      be treated as not having a gap.
       *
       * @returns Object { gap: Boolean, oneAfterAnother: Boolean, overlap: Boolean }
       */
      compareInTime(model1, model2, options) {
        if (!model1 || !model2) {
          return undefined;
        }

        let increment = options && options.increment ? options.increment : 0;
        const keepModelOrder = options && options.keepModelOrder;
        if (!keepModelOrder && model2.getStartDate().getTime() < model1.getStartDate().getTime()) {
          // Switch objects so that model1 < model2
          const tmp = model1;
          // eslint-disable-next-line no-param-reassign
          model1 = model2;
          // eslint-disable-next-line no-param-reassign
          model2 = tmp;
          // Reverse the possible increment.
          increment = -increment;
        }

        let model1StartDate = model1.getStartDate();
        let model1EndDate = model1.getEndDateWithAdditionalTime();
        if (increment) {
          model1StartDate = Wahanda.Date.addMinutesToDate(model1StartDate, increment);
          model1EndDate = Wahanda.Date.addMinutesToDate(model1EndDate, increment);
        }
        const model1EndMinutes = Wahanda.Time.getDateMinutes(model1EndDate);
        const model2StartDate = model2.getStartDate();
        const model2StartMinutes = Wahanda.Time.getDateMinutes(model2StartDate);

        const oneAfterAnother = model1EndMinutes === model2StartMinutes;
        // The overlap function returns true when m1end === m2start, which we don't want.
        const overlap =
          !oneAfterAnother &&
          Wahanda.Date.doOverlap(
            [model1StartDate, model1EndDate],
            [model2StartDate, model2.getEndDateWithAdditionalTime()],
          );

        return {
          gap: !oneAfterAnother && !overlap,
          oneAfterAnother,
          overlap,
          timeDiff: model2StartDate.getTime() - model1EndDate.getTime(),
        };
      },

      /**
       * Create TimeBlock in place of an old appointment time.
       * This is needed when rescheduling.
       *
       * @param App.Models.Appointment appointment
       *
       * @return Promise
       */
      createBlockInPlaceOfOldTime(appointment) {
        const block = appointment.createBlock();
        block.set('name', Wahanda.lang.calendar.appointmentForm.blockedTimeText);
        return block.save(null, {
          success() {
            App.trigger(Wahanda.Event.APPOINTMENT_RESCHEDULED_BLOCK_CREATED, {
              model: appointment,
            });
          },
        });
      },

      getAppointmentGroups(models) {
        const self = this;
        return models
          .filter((model) => model instanceof App.Collections.AppointmentGroup)
          .map((model) => {
            return {
              appointments: model.models.map((appointment) =>
                self.formatAppointmentModel(appointment),
              ),
              notes: model.data.notes,
              serviceId: model.data.offerId,
              recurrence: model.data.recurrence,
              optionId: model.data.optionId,
            };
          });
      },

      formatAppointmentModel(appointment) {
        return {
          appointmentDate: appointment.get('appointmentDate'),
          skus: appointment.get('skus'),
          employeeId: appointment.get('employeeId'),
          endTime: appointment.get('endTime'),
          evoucherReference: appointment.get('evoucherReference'),
          finishingEndTime: appointment.get('finishingEndTime'),
          notes: appointment.get('notes'),
          platform: appointment.get('platform'),
          processingEndTime: appointment.get('processingEndTime'),
          serviceId: appointment.get('offerId'),
          startTime: appointment.get('startTime'),
          recurrence: appointment.get('recurrence'),
        };
      },
    },
  );

  App.Models.Appointment = AppointmentModel;
})();
