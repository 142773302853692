import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from 'common/backbone-app';
import { requestSupplierDetailsAction } from 'components/dashboard/actions';
import { Reports } from './index';

const mapStateToProps = (state) => {
  const canViewVatSaleReport =
    state.salesReport.reportContainer.isVatPayer && App.config.get('venue').pointOfSaleEnabled;
  return {
    canViewVatSaleReport,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ requestSupplierDetailsAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const ReportsContainer = connector(Reports);

export { ReportsContainer as Reports };
