import React, { PureComponent } from 'react';
import Wahanda from 'common/wahanda';
import classnames from 'classnames';
import style from './style.scss';
import * as analytics from './analytics';

const lang = Wahanda.lang.dashboard.reviews;

export const CanRenderGuidelines = !!lang.guidelinesLink;

interface IGuidelinesProps extends React.HTMLAttributes<Element> {
  classes?: any | any[];
}
class Guidelines extends PureComponent<IGuidelinesProps, {}> {
  static defaultProps = {
    classes: '',
  };

  onClick = () => {
    analytics.trackGuidelinesOpened();
  };

  render() {
    const { classes } = this.props;
    const className = classnames(style.guidelines, classes);
    return (
      <a
        className={className}
        onClick={this.onClick}
        href={lang.guidelinesLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {lang.guidelines}
      </a>
    );
  }
}

export default Guidelines;
