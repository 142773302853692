import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const ANIMATION_DURATION = 300;

export default class SlideUpDownTransition extends React.Component<{}, {}> {
  render() {
    return (
      <ReactCSSTransitionGroup
        transitionName="slide-updown"
        component="div"
        transitionEnterTimeout={ANIMATION_DURATION}
        transitionLeaveTimeout={ANIMATION_DURATION}
        {...this.props}
      />
    );
  }
}
