import React, { Component } from 'react';
import { Radio } from 'components/common/Radio';
import Wahanda from 'common/wahanda';
import VALUES from './constants';
import style from './style.scss';

const LANG = Wahanda.lang.menu.package.pricing;
interface IServicePricesProps extends React.HTMLAttributes<Element> {
  actions: {
    offerDialogChangePricingTypeAction: (...args: any[]) => any;
  };
  isPackagePricingCustom: boolean;
  readonlyLight: boolean;
}

export default class ServicePrices extends Component<IServicePricesProps, {}> {
  checkboxClick = (selectedPricingType) => {
    const { isPackagePricingCustom, actions, readonlyLight } = this.props;
    const { offerDialogChangePricingTypeAction } = actions;
    if (readonlyLight || selectedPricingType === isPackagePricingCustom) {
      return;
    }
    offerDialogChangePricingTypeAction(selectedPricingType);
  };

  render() {
    const { isPackagePricingCustom } = this.props;
    return (
      <div className={style.container}>
        <div className={style.header}>{LANG.title}</div>
        <div className={style.options}>
          <Radio
            value={VALUES.PRICING_TYPE_SUM}
            name="pricing-type"
            label={LANG.type.current}
            onChange={() => this.checkboxClick(false)}
            checked={!isPackagePricingCustom}
          />
          <Radio
            value={VALUES.PRICING_TYPE_CUSTOM}
            name="pricing-type"
            label={LANG.type.custom}
            onChange={() => this.checkboxClick(true)}
            checked={isPackagePricingCustom}
          />
        </div>
      </div>
    );
  }
}
