/* global BackboneEx */
App.Views.Forms.Employee.UnlinkExternalCalendar = BackboneEx.View.Dialog.extend({
  events: {
    'click .button-cancel': 'close',
    'click .a-unlink': 'unlink',
  },
  options: {
    width: 600,
    title: Wahanda.lang.settings.employees.employee.unlinkCalendar.title,
    dialogClass: 'cal-link-dialog',
    dataTest: 'unlink-external-modal',
  },
  templateId: 'employee-external-cal-unlink-dialog',

  unlink() {
    const self = this;

    this.model.set('externalCalendarUri', null);

    this.disableForm();
    const $button = this.$('.a-unlink').action('doing');

    // Save the Employee ext. calendar URI to the employee.
    this.model.save(null, {
      success() {
        App.trigger(Wahanda.Event.EMPLOYEE_EXT_CALENDAR_UNSUBSCRIBED, self.model);
        self.close();
      },
      error() {
        $button.action().errorTip(Wahanda.lang.shared.savingFailed);
        self.enableForm();
      },
    });
  },
});
