import React from 'react';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import style from '../CovidBanner.scss';

const LANG = Wahanda.lang.shared.covidBanner;
interface Props {
  hasFullPermissions: boolean;
  unlimitLoading: boolean;
  showVenueClosingDialog: () => void;
  showVenueOpenConfirmationDialog: () => void;
}

export const ReopenPending: React.SFC<Props> = ({
  hasFullPermissions,
  unlimitLoading,
  showVenueClosingDialog,
  showVenueOpenConfirmationDialog,
}) => {
  const handleReopenButtonClicked = () => {
    showVenueOpenConfirmationDialog();
  };
  const handleNotPlanningToReopenClick = () => {
    showVenueClosingDialog();
  };
  const renderWithPermissions = () => {
    return (
      <>
        <span
          dangerouslySetInnerHTML={{
            __html: LANG.canBeReopenedWithPermissionsPart1,
          }}
        />
        <span className={style.buttonWrapper}>
          <Button
            onClick={handleReopenButtonClicked}
            dataTest="reopen-button"
            label={LANG.canBeReopenedButton}
            disabled={unlimitLoading}
          />
        </span>
        <span>{LANG.canBeReopenedWithPermissionsPart2}</span> <span>{LANG.notPlanning}</span>{' '}
        <a
          onClick={handleNotPlanningToReopenClick}
          data-test="not-planning-to-reopen-link"
          className={style.link}
        >
          {LANG.clickHere}
        </a>
      </>
    );
  };
  const renderNoPermissions = () => {
    return (
      <>
        <span dangerouslySetInnerHTML={{ __html: LANG.canBeReopenedNoPermissions }} />
      </>
    );
  };
  return hasFullPermissions ? renderWithPermissions() : renderNoPermissions();
};
