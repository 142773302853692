import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Campaigns extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-campaigns', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M4,8.329 L4,16.993 C4,16.998 9.333,17 20,17 C20.001,17 20.002,14.667 20,10 L22,10 L22,17.005 C21.997257,18.1048899 21.1068866,18.9961538 20.007,19 L3.993,19 C3.46256601,18.9981467 2.95459379,18.7856536 2.58083309,18.4092674 C2.20707239,18.0328812 1.99814023,17.523434 1.99998769,16.993 L1.99998769,7.007 C1.99814023,6.47656604 2.20707239,5.96711883 2.58083309,5.59073261 C2.95459379,5.21434639 3.46256601,5.00185327 3.993,5 L20.007,5 C21.485,5 22.007,6.443 20.913,7.416 L13.416,14.079 C12.629,14.779 11.384,14.789 10.589,14.094 L4,8.329 Z M5.519,7 L11.906,12.589 C11.939,12.618 12.051,12.617 12.087,12.584 L18.37,7 L5.519,7 Z M19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L19,20 Z M19,4 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Campaigns);
