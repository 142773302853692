import apiUrl from 'common/api-url';
import App from 'common/backbone-app';
import { menuServiceTransformer } from 'common/transformers/DiscountMenu';
import { getSuggestions } from 'components/menu/AddServicesModal/utils/formatSuggestedServices';
import { useQuery } from 'react-query';
import { get } from 'reduxStore/apiService';
import style from '../components/marketing/ClientForms/ClientFormsServicePicker/ClientFormsServicePicker.scss';
import { useEffect, useState } from 'react';

type ServiceGroup = {
  id: number;
  active: boolean;
  description: {
    content: string;
    contentType: string;
  } | null;
  name: string;
  serviceTypeCode: number | null;
  treatmentGroupId: number | null;
};

type Offer = {
  amountFrom: null | number;
  amountTo: null | number;
  applicableDays: null | string[];
  created: string;
  datedServiceTypeCode: null | string;
  description: null | string;
  discountFrom: null | number;
  discountTo: null | number;
  distributionChannels: string[];
  employees: {
    employeeId: number;
    name: string;
  }[];
  featured: boolean;
  pending: boolean;
  featuredStateCode: null | string;
  fromChain: boolean;
  fulfillmentTypes: {
    appointment: boolean;
    dated: boolean;
    evoucher: boolean;
  };
  goodToKnow: string;
  groupId: number;
  id: number;
  listed: boolean;
  lockDescription: null | string;
  locked: boolean;
  manageInventory: boolean;
  menuItemTypeCode: string;
  name: string;
  nameInherited: boolean;
  occupancyGuests: null | number;
  offerTypeCode: string;
  onlineBookingMinHours: null | number;
  leadTimeMinutes: null | number;
  domainToServiceLinks: { [key: string]: string };
  pricing: null | any;
  pricingDisplayTypeCode: string;
  primaryTreatmentId: number;
  purchasable: boolean;
  restrictions: string;
  skus: any[];
  stayNights: null | number;
  supplierReference: null | string;
  treatmentIds: number[];
  useApplicableDays: boolean;
  visible: boolean;
  visibleFrom: null | string;
  visibleNow: boolean;
  visibleTo: null | string;
  voucherValidityMonths: null | number;
  cleanupTime: null | number;
  multiSkuSelection: boolean;
  processingTimeMins: null | number;
  subServices: null | any[];
  enclosingPackages: any[];
  resources: null | any;
  changedUnderlineServices: null | any[];
  connectDisplayColour: null | string;
  taxRate: number;
  vatCategory: string;
  discountRuleId: number;
  discountRuleName: string;
  discountRuleActive: boolean;
  requiresPatchTest: null | boolean;
  patchTestService: boolean;
};

type ServiceGroupWithOffers = ServiceGroup & { services: Offer[] };

const cacheKey = () => `Services_${App.getVenueId()}`;

export const formatSuggestedServiceOffers = (payload: {
  services: Offer[];
  results: Offer[];
}): Offer[] => {
  const { services, results } = payload;
  const servicesInResults: { [x: string]: Offer } = {};

  return services
    .filter((service) => {
      const serviceId = service.id;
      const serviceInResults = results.find((o) => o.id === serviceId);
      if (serviceInResults) {
        servicesInResults[serviceId] = serviceInResults;
      }
      return servicesInResults[serviceId];
    })
    .map((service) => ({
      ...service,
      name: servicesInResults[service.id].name,
    }));
};

export const useGetGroupedServices = ({
  searchQuery,
  selectedServices = [],
  sortServicesBySelected = false,
}: {
  searchQuery?: string;
  selectedServices?: number[];
  sortServicesBySelected?: boolean;
}): {
  isLoading: boolean;
  groupedServices: ServiceGroupWithOffers[];
  isError: boolean;
} => {
  const [initialSelectedServices, setInitialSelectedServices] = useState(selectedServices);
  const { isLoading, isError, data: services } = useQuery<{
    groups: ServiceGroup[];
    offers: Offer[];
  }>(
    cacheKey(),
    () =>
      get(
        apiUrl('ALL_SERVICES_LIST', {
          venueId: App.getVenueId(),
        }),
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const getGroupedServices = () => {
    const groupedServices = menuServiceTransformer(services?.groups || [], services?.offers || []);

    if (initialSelectedServices.length > 0 && sortServicesBySelected) {
      groupedServices.sort((a) => {
        const isSelected = a.services.some((service: Offer) =>
          initialSelectedServices.includes(service.id),
        );

        if (isSelected) return -1;
      });
    }

    if (!searchQuery) return groupedServices;

    const suggestedServices = getSuggestions<Offer>({
      highlightClass: style.match,
      items: menuServiceTransformer(services?.groups || [], services?.offers || []),
      inputValue: searchQuery,
    });

    const suggestedServicesGroupIds = suggestedServices.map((service) => service.groupId);

    const formattedList = groupedServices.reduce((list, group) => {
      if (!suggestedServicesGroupIds.includes(group.id)) return list;

      return [
        ...list,
        {
          ...group,
          services: formatSuggestedServiceOffers({
            services: group.services,
            results: suggestedServices,
          }),
        },
      ];
    }, []);

    return formattedList;
  };

  useEffect(() => {
    if (initialSelectedServices?.length === 0 && selectedServices.length > 0) {
      setInitialSelectedServices(selectedServices);
    }
  }, [selectedServices, initialSelectedServices]);

  return {
    isLoading,
    groupedServices: getGroupedServices(),
    isError,
  };
};
