import React from 'react';
import ReactDOM from 'react-dom';
import { DraggableCore } from 'react-draggable';
import { trackEvent, ACTIONS } from 'common/analytics';

// Limit how many pixels of dialog must be shown when dragging outside the X axis
const OFFSCREEN_LIMIT = 50;

function correctValue(value, min, max) {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
}

interface DraggabilityProps extends React.HTMLAttributes<Element> {
  dataTest?: string;
  handle?: string;
  onPositionChange?: (...args: any[]) => any;
}

type DraggabilityState = {
  position: { x: any; y: any };
  restrictions: { left: number; right: number; top: number; bottom: number };
  isTrackingOnStartTriggered: boolean;
};

// Component for dragging of the Dialogs
export class Draggability extends React.Component<DraggabilityProps, DraggabilityState> {
  constructor(props: DraggabilityProps) {
    super(props);

    this.state = {
      position: { x: 0, y: 0 },
      restrictions: { left: 0, right: 0, top: 0, bottom: 0 },
      isTrackingOnStartTriggered: false,
    };
  }

  public componentDidMount = () => {
    trackEvent('design-system', ACTIONS.VIEW, 'draggable-dialog', this.props.dataTest);
  };

  private onDragStart = () => {
    const node = ReactDOM.findDOMNode(this); //eslint-disable-line
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const rect = node.getBoundingClientRect();
    this.setState({
      position: { x: rect.left, y: rect.top },
      restrictions: {
        left: -(rect.width - OFFSCREEN_LIMIT),
        right: window.innerWidth - OFFSCREEN_LIMIT,
        top: 0,
        bottom: window.innerHeight - OFFSCREEN_LIMIT,
      },
    });
  };

  private onDrag = (_, data) => {
    const { position, restrictions } = this.state;
    const newPos = {
      x: position.x + data.deltaX,
      y: position.y + data.deltaY,
    };
    this.setState({ position: newPos });

    if (this.props.onPositionChange) {
      this.props.onPositionChange({
        x: correctValue(newPos.x, restrictions.left, restrictions.right),
        y: correctValue(newPos.y, restrictions.top, restrictions.bottom),
      });
    }

    if (!this.state.isTrackingOnStartTriggered) {
      trackEvent('design-system', ACTIONS.CLICK, 'draggable-dialog', this.props.dataTest);
      this.setState({ isTrackingOnStartTriggered: true });
    }
  };

  public render() {
    return (
      <DraggableCore handle={this.props.handle} onStart={this.onDragStart} onDrag={this.onDrag}>
        {this.props.children}
      </DraggableCore>
    );
  }
}
