/* global Backbone */
import Wahanda from 'common/wahanda';
import BigNumber from 'bignumber.js';

const LANG = {
  discount: Wahanda.lang.calendar.appointments.pos.discount,
  update: Wahanda.lang.calendar.appointments.pos.updateButton,
  cancel: Wahanda.lang.calendar.appointments.pos.cancelButton,
  prepaidMarketplaceError: Wahanda.lang.calendar.appointments.pos.prepaidMarketplaceError,
  notAllowedDiscounts: Wahanda.lang.calendar.appointments.pos.notAllowedDiscounts,
  notAllowedEdit: Wahanda.lang.calendar.appointments.pos.notAllowedEdit,
};

App.Views.POS.CheckoutServiceEditView = Backbone.View.extend({
  events: {
    'click .js-cancel': 'onCancel',
    'click .js-save': 'onSave',
    'keyup .js-currency-input input': 'onPriceChange',
    'blur  .js-currency-input input': 'onPriceBlur',
    'keyup .js-discount-input': 'onDiscountChange',
    'blur .js-discount-input': 'onDiscountBlur',
    'focus .js-discount-input': 'onInputFocus',
    'focus  .js-currency-input input': 'onInputFocus',
  },

  template: Wahanda.Template.render.bind(
    null,
    `
    <div class="pos-checkout-section--item-edit-view-container">
      <div class="pos-checkout-section--item-edit-view">
        <div class="pos-checkout-section--item-edit-view-content">
          <div class="pos-checkout-section--item-edit-view-details">
            <div class="pos-checkout-section--item-edit-view-service">
              {{title}}
            </div>
            <div class="js-currency-input"></div>
          </div>
          <label class="pos-checkout-section--item-edit-view-discount">
            <span>
              ${LANG.discount}
            </span>
            <input {{#disableEdit}} disabled {{/disableEdit}} class="js-discount-input discounts-underlined-input {{#disableEdit}} disabled {{/disableEdit}}" value="{{discountPercentage}}"/>
            %
          </label>
          {{#disableEdit}}
            <div class="pos-checkout-section--item-edit-view-validation-message js-validation-message"></div>
            <div class="pos-checkout-section--item-edit-view-disabled-message js-disabled-message"></div>
          {{/disableEdit}}
        </div>
        <div class="pos-checkout-section--item-edit-view-actions">
          <button class="pos-checkout--button pos-checkout--button-cancel js-cancel">
            ${LANG.cancel}
          </button>
          <button class="pos-checkout--button-action pos-checkout--button-bold pos-checkout--button js-save">
            ${LANG.update}
          </button>
        </div>
      </div>
    </div>
  `,
  ),

  options: {
    onSave: null,
    onCancel: null,
  },

  getData: function getData() {
    const discountedAmount = this.calculateDiscountedAmount();
    const amount = new BigNumber(this.formData.amount).toNumber();
    const discountPercentage = this.formData.discountPercentage;

    return {
      amount,
      discountPercentage,
      discountedAmount,
    };
  },

  initialize: function initialize() {
    const { discountPercentage, amount, originalAmount } = this.model;

    const originalAmountFormated = Wahanda.Number.formatPOSInputintoFloat(originalAmount) || 0.0;
    const amountFormated = Wahanda.Number.formatPOSInputintoFloat(amount) || 0.0;
    this.formData = {
      discountPercentage: discountPercentage != null ? discountPercentage : 0,
      amount: amountFormated,
      originalAmount: originalAmountFormated,
    };
    this.disabledMessage = App.ES6.Initializers.Notice;
  },

  remove: function remove() {
    Backbone.View.prototype.remove.call(this, this.model, this.options);
    if (this.disableEditDiscounts()) {
      this.disabledMessage({ node: this.$('.js-disabled-message')[0] }).destroy();
    }
  },

  isPrepaidService: function isPrepaidService() {
    return !this.model.unpaid;
  },

  disableEditDiscounts: function disableEditDiscounts() {
    return this.isPrepaidService() || !Wahanda.Permissions.editPOSServicePrice();
  },

  onPriceChange: function onPriceChange(event) {
    const $input = $(event.currentTarget);
    const amount = $input.val();

    const formattedPrice = Wahanda.Number.formatPOSInputintoFloat(amount);
    const invalid = Number.isNaN(formattedPrice) || formattedPrice === false || formattedPrice < 0;

    if (invalid) {
      return;
    }

    this.formData.amount = formattedPrice;
  },

  onPriceBlur: function onPriceBlur(event) {
    const $input = $(event.currentTarget);
    const amount = new BigNumber(Wahanda.Number.formatPOSInputintoFloat(this.formData.amount));
    // because formatPosInputintoFloat returns '' for 0 we need to double check.
    this.formData.amount = amount.isNaN() ? 0.0 : amount.toNumber();

    $input.val(Wahanda.Number.formatFloatForPOSInput(this.formData.amount));
    $input.parent().removeClass('pos-focused');
  },

  onDiscountBlur: function onDiscountBlur(event) {
    const $input = $(event.currentTarget);
    if (this.formData.discountPercentage == null || this.formData.discountPercentage === '') {
      $input.val('0');
      return;
    }

    const discountPercentageFormated = new BigNumber(
      `${this.formData.discountPercentage}`.replace(/[^0-9]/g, ''),
    ).toNumber();
    this.formData.discountPercentage = discountPercentageFormated;

    $input.val(discountPercentageFormated);
  },

  onDiscountChange: function onDiscountChange(event) {
    const $input = $(event.currentTarget);
    const discountPercentage = new BigNumber($input.val().replace(/[^0-9]/g, ''));

    if (discountPercentage.isNaN() || discountPercentage.isLessThanOrEqualTo(0)) {
      this.formData.discountPercentage = null;
      return;
    }

    if (discountPercentage.isGreaterThanOrEqualTo(100)) {
      this.formData.discountPercentage = 100;
      return;
    }

    this.formData.discountPercentage = discountPercentage.toNumber();
  },

  onInputFocus: function onInputFocus(event) {
    const $input = $(event.target);
    $input.select();

    if ($input.hasClass('js-service-price-input')) {
      $input.parent().addClass('pos-focused');
    }
  },

  calculateDiscountedAmount: function calculateDiscountedAmount() {
    const discountPercentage = new BigNumber(this.formData.discountPercentage);
    if (discountPercentage.isNaN() || discountPercentage.isLessThanOrEqualTo(0)) {
      return null;
    }

    return App.Views.POS.POSCheckoutPriceCalculator.prototype.calculatePriceAfterDiscount({
      price: this.formData.amount,
      discountPercentage: this.formData.discountPercentage,
    });
  },

  onSave: function onSave() {
    if (
      this.isPrepaidService() &&
      new BigNumber(this.formData.amount).isLessThan(this.model.originalAmount)
    ) {
      this.formData.amount = this.formData.originalAmount;
      this.$el
        .find('.js-currency-input input')
        .val(Wahanda.Number.formatFloatForPOSInput(this.formData.amount));
      this.disabledMessage({
        node: this.$('.js-validation-message')[0],
        message: LANG.prepaidMarketplaceError,
        type: 'error',
      }).render();

      return;
    }

    if (this.options.onSave) {
      this.options.onSave(this.getData());
    }
    this.remove();
  },

  onCancel: function onCancel() {
    if (this.options.onCancel) {
      this.options.onCancel();
    }
    this.remove();
  },

  renderDisabledMessage: function renderDisabledMessage() {
    if (!this.disableEditDiscounts()) {
      return;
    }
    let message;
    if (this.isPrepaidService()) {
      message = LANG.notAllowedDiscounts;
    }

    if (!Wahanda.Permissions.editPOSServicePrice()) {
      message = LANG.notAllowedEdit;
    }
    const noticeContainer = this.$('.js-disabled-message')[0];
    this.disabledMessage({ node: noticeContainer }).destroy();
    this.disabledMessage({
      node: noticeContainer,
      message,
    }).render();
  },

  render: function render() {
    this.$el.html(
      this.template({
        ...this.model,
        disableEdit: this.disableEditDiscounts(),
        discountPercentage: this.formData.discountPercentage,
      }),
    );
    const priceView = new App.Views.POS.CurrencyInput({
      el: this.$el.find('.js-currency-input'),
      name: 'service-price',
      cssClass: 'js-service-price-input',
      cssContainerClass: 'currency-input--small',
      placeholder: Wahanda.Number.formatFloatForPOSInput(this.formData.amount),
      value: Wahanda.Number.formatFloatForPOSInput(this.formData.amount),
      disabled: !Wahanda.Permissions.editPOSServicePrice(),
    });

    priceView.render();

    this.renderDisabledMessage();

    return this;
  },
});
