import React from 'react';
import classnames from 'classnames';
import findIndex from 'lodash/findIndex';
import { Badge } from 'components/common/Badge';
import { Checkbox } from 'components/common/Checkbox';
import { Chevron } from 'components/common/Icon';
import { GroupPayload } from '../store/types';
import { NoResults } from '../EmptyState';
import style from './GroupsList.scss';

interface Props {
  list?: GroupPayload[];
  groupTypesList: GroupPayload[];
  searchedValue: string;
  onCheckboxChange: (event, menuGroupId, serviceId) => void;
}

export const GroupsList = ({ list, groupTypesList, onCheckboxChange, searchedValue }: Props) => {
  const hasSearchedValue = searchedValue.length > 0;
  const items = hasSearchedValue ? list : groupTypesList;
  const hasNoResults = items?.every((elem) => !elem.services.length);
  const [expandedGroups, setExpandedGroups] = React.useState<number[]>([]);

  const toggleExpandGroup = (menuGroupId: number) =>
    setExpandedGroups(
      expandedGroups.includes(menuGroupId)
        ? expandedGroups.filter((expandedGroupId) => expandedGroupId !== menuGroupId)
        : [...expandedGroups, menuGroupId],
    );

  const getTotalCheckedLength = (menuGroupId: number) => {
    const index = findIndex(groupTypesList, ['menuGroupId', menuGroupId]);
    const services = groupTypesList[index]?.services;
    return services?.filter((props) => props.checked).length;
  };

  if (hasSearchedValue && hasNoResults) return <NoResults searchedValue={searchedValue} />;

  return (
    <>
      {items?.map(({ menuGroupId, services, name: groupName }) => {
        const isExpanded = expandedGroups.includes(menuGroupId) || hasSearchedValue;
        const totalChecked = getTotalCheckedLength(menuGroupId);

        if (!services.length) return undefined;

        return (
          <div
            className={classnames({
              [style.expanded]: isExpanded,
              [style.forcedExpand]: hasSearchedValue,
            })}
            key={menuGroupId}
          >
            <div
              data-test={`group-list-${menuGroupId}`}
              className={style.groupHeader}
              onClick={() => toggleExpandGroup(menuGroupId)}
            >
              <div className={style.groupName}>
                {groupName}
                {Boolean(totalChecked) && (
                  <Badge className={style.badge} text={totalChecked} type="alert" />
                )}
              </div>
              {!searchedValue && <Chevron className={style.arrow} size="medium" />}
            </div>
            {isExpanded && (
              <div className={style.services}>
                {services.map((service) => {
                  const key = `${menuGroupId}-${service.serviceId}`;
                  return (
                    <Checkbox
                      name={key}
                      dataTest={`group-list-item-${service.serviceId}`}
                      key={key}
                      className={style.service}
                      label={service.name}
                      onChange={(event) => onCheckboxChange(event, menuGroupId, service.serviceId)}
                      checked={service.checked || false}
                    />
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};
