/**
 * Customer search model.
 */
(function () {
  App.Models.CustomerSearch = BackboneEx.Model.Silent.extend({
    url: function () {
      return App.Api.wsUrl('/venue/' + App.config.get('venue').venueId + '/customer-search.json');
    },
  });
})();
