import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'reduxStore/common/employees/actions';
import EmployeeCategories from './index';

const mapStateToProps = ({
  employees: {
    employee: { employeeCategoryId },
    categories,
  },
}) => ({
  employeeCategoryId,
  categories,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ employeeCategoryId: null; categories: neve... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCategories);
