import React from 'react';
import classnames from 'classnames';
import style from './Spinner.scss';

const sizes = {
  tiny: 12,
  small: 16,
  medium: 24,
};

type Sizes = 'tiny' | 'small' | 'medium';
interface Props {
  dark?: boolean;
  size?: Sizes;
}

export class Spinner extends React.PureComponent<Props> {
  public render() {
    const { dark, size = 'medium' } = this.props;
    const svgSize = size && sizes[size];
    return (
      <svg
        width={svgSize}
        height={svgSize}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className={classnames(style.spinner, {
          [style.darkStroke]: dark,
        })}
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          strokeWidth="10"
          r="35"
          strokeDasharray="164.93361431346415 56.97787143782138"
          transform="rotate(281.931 50 50)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    );
  }
}
