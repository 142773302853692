import React from 'react';
import get from 'lodash/get';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';

import Dialog from 'components/common/react-dialog';
import Loader from 'components/common/Loader';
import { Radio } from 'components/common/Radio';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';

import { TimeSlotsObject, ShiftWorkingHourType, VenueShiftsObject } from '../utils/types';

import { workingHoursRange } from '../utils/helpers';

import { ShiftTimeSlotRange } from '../ShiftTimeSlotRange';

import style from './ShiftDayModal.scss';

interface OptionsListArgs {
  label: string;
  value: string;
}

interface Props {
  // Used to set limited startTime, endTime for custom working hours selects
  limitedHoursRange?: {
    startTime: number;
    endTime: number;
  };
  date: string;
  defaultTimeSlots: TimeSlotsObject[];
  header: React.ReactNode;
  onComponentMount?: () => void;
  onClose: (param) => void;
  onSubmit: (param) => void;
  optionsList: OptionsListArgs[];
  timeSlots: TimeSlotsObject[];
  title: string;
  workingHoursType: string;
  // redux
  isSavingShift: boolean;
  venue: VenueShiftsObject;
}

interface State {
  selectedRadio: string;
  timeSlots: TimeSlotsObject[];
}

export class ShiftDayModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const venueTimeSlots = this.props.defaultTimeSlots[0];

    const timeFrom = get(props.timeSlots, '[0].timeFrom') || venueTimeSlots.timeFrom;
    const timeTo = get(props.timeSlots, '[0].timeTo') || venueTimeSlots.timeTo;

    this.state = {
      selectedRadio: props.workingHoursType,
      timeSlots: [{ timeFrom, timeTo }],
    };
  }

  public componentDidMount() {
    if (this.props.onComponentMount) {
      this.props.onComponentMount();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const { isSavingShift, onClose } = this.props;
    if (!isSavingShift && prevProps.isSavingShift && onClose) {
      onClose(false);
    }
  }

  private handleOptionSelection = (event) => {
    this.setState({ selectedRadio: event.value });
  };

  private renderOptionsList = () => {
    const { defaultTimeSlots, optionsList } = this.props;

    return (
      <React.Fragment>
        {optionsList.map((opt) => (
          <div className={style.radio} key={opt.value}>
            <Radio
              dataTest={`shift-edit-modal-radio-${opt.value}`}
              checked={this.state.selectedRadio === opt.value}
              key={opt.value}
              label={opt.label}
              name="shifts"
              onChange={() => this.handleOptionSelection(opt)}
              value={opt.value}
            />
            {(opt.value === ShiftWorkingHourType.VENUE ||
              opt.value === ShiftWorkingHourType.STANDARD) && (
              <span className={style.defaultTime}>({workingHoursRange(defaultTimeSlots)})</span>
            )}
          </div>
        ))}
      </React.Fragment>
    );
  };

  private onTimeSlotChange = (timeSlots: TimeSlotsObject) => {
    this.setState({
      selectedRadio: ShiftWorkingHourType.CUSTOM,
      timeSlots: [timeSlots],
    });
  };

  private onSubmit = () => {
    const { selectedRadio: type, timeSlots } = this.state;
    if (this.props.onSubmit) {
      this.props.onSubmit({ type, timeSlots });
    }
  };

  private onClose = (event) => {
    this.props.onClose(event);
  };

  public render() {
    const { date, header, onClose, title } = this.props;
    return (
      <Dialog
        dataTest="shift-day-modal"
        width={380}
        title={title}
        onClose={onClose}
        noContentPadding
        noContentTopBorders
      >
        <div className={style.container}>
          {header}
          <div className={style.date}>{moment(date).formatLongLocaleDate()}</div>
          {this.renderOptionsList()}
          <div className={style.timeSlotsRange}>
            <ShiftTimeSlotRange
              limitedHoursRange={this.props.limitedHoursRange}
              defaultTimeSlots={this.props.defaultTimeSlots}
              timeSlots={this.state.timeSlots}
              onTimeSlotChange={this.onTimeSlotChange}
            />
          </div>
          {this.props.isSavingShift && <Loader positionAbsolute />}
        </div>
        <DialogFooter>
          <DialogFooterButton
            dataTest="modal-footer-button-cancel"
            title={Wahanda.lang.shared.buttons.cancel}
            type="secondary"
            onClick={this.onClose}
          />
          <DialogFooterButton
            dataTest="modal-footer-button-save"
            title={Wahanda.lang.shared.buttons.save}
            onClick={this.onSubmit}
          />
        </DialogFooter>
      </Dialog>
    );
  }
}
