/* eslint-disable func-names */
import { STATUS_CHECKED_OUT } from 'common/appointment';

/**
 * Calendar objects model.
 */
(function () {
  const FID_CONFIRMED = 2;
  const FID_BOOKED = 3;
  const FID_NONWORK = 5;
  const FID_CHECKED_OUT = 6;
  const FID_NO_SHOW = 13;

  function getAppointmentAmount(appt) {
    const pkgGroup = appt.getLinkedPackageGroup();
    if (pkgGroup) {
      if (pkgGroup.isCheckedOut()) {
        return pkgGroup.reduce(function (total, appointment) {
          return total + appointment.getCheckoutTotal();
        }, 0);
      }
      return pkgGroup.getTotalPrice();
    }
    return appt.isCheckedOut() && appt.get('checkout')
      ? appt.get('checkout').total
      : appt.get('amount');
  }

  function getDraggableStatus(appt) {
    // Here we want to check both appointment status and employee permissions
    if (appt.canBeRescheduled()) {
      if (appt.isUnconfirmed() || appt.isConfirmed()) {
        if (Wahanda.Permissions.editAnyCalendar()) {
          return true;
        }
        return 'only-edit-own-cal';
      }
      if (Wahanda.Permissions.editAnyCalendar()) {
        return 'only-rid';
      }
      return false;
    }
    return false;
  }

  function blockDraggableData(model) {
    if (model.isMultiDayPeriod() || model.isExternal()) {
      return false;
    }

    // Restrict recurring blocks to only time change
    return model.isRecurring() ? 'y' : true;
  }

  App.Models.CalendarObjects = BackboneEx.Model.Base.extend({
    STATUS_CODE_CANCELLED: 'CC',
    STATUS_CODE_CONFIRMED: 'CN',
    STATUS_CODE_BOOKED: 'CR',
    STATUS_CODE_REJECTED: 'RJ',
    STATUS_CODE_UNASSIGNED: 'UA',
    STATUS_CODE_NO_SHOW: 'NS',

    defaults: {
      venueId: null,
      dateFrom: '',
      dateTo: null,
      createdDateFrom: null,
      createdDateTo: null,
      include: ['appointments', 'blocks'],
      groupId: null,
      appointmentStatusCodes: ['CR', 'CN', 'CP', 'NS'],
      bookingActorCodes: null,
      utmSource: null,
    },

    appointments: null,
    appointmentGroups: null,

    initialize: function () {
      this.appointments = new BackboneEx.Collection.Base(null, {
        model: App.Models.Appointment,
      });
    },

    parse: function (data) {
      this.appointments.reset(data.appointments);
      this.buildAppointmentGroups(data.appointmentGroups);

      return data;
    },

    clone: function () {
      const clone = new App.Models.CalendarObjects();

      const appts = this.appointments.toJSON();
      const groupData = this.appointmentGroups.forEach((group) => group.data);

      clone.parse({
        appointments: appts,
        appointmentGroups: groupData,
      });
      return clone;
    },

    // Clear the model's data
    reset() {
      this.set(
        this.parse({
          appointments: [],
          appointmentGroups: [],
        }),
      );
      this.trigger('reset');
    },

    checkOutAppointments(ids = []) {
      this.appointments
        .filter((appointment) => ids.includes(appointment.id))
        .forEach((appointment) => {
          appointment.set('appointmentStatusCode', STATUS_CHECKED_OUT);
          appointment.set('checkout', {});
        });
    },

    urlRoot: function () {
      const base =
        '/venue/{{venueId}}/calendar.json?' +
        'include={{include}}' +
        '{{#dateFrom}}&date-from={{dateFrom}}{{/dateFrom}}' +
        '{{#dateTo}}&date-to={{dateTo}}{{/dateTo}}' +
        '{{#employeeId}}&employee-id={{employeeId}}{{/employeeId}}' +
        '{{#codes}}&appointment-status-codes={{codes}}{{/codes}}' +
        '{{#channels}}&booking-actor-codes={{channels}}{{/channels}}' +
        '{{#createdDateFrom}}&created-date-from={{createdDateFrom}}{{/createdDateFrom}}' +
        '{{#createdDateTo}}&created-date-to={{createdDateTo}}{{/createdDateTo}}' +
        '{{#utmSource}}&utm_source={{utmSource}}{{/utmSource}}';
      const urlParams = {
        venueId: this.get('venueId') || App.getVenueId(),
        dateFrom: this.get('dateFrom'),
        dateTo: this.get('dateTo'),
        createdDateFrom: this.get('createdDateFrom'),
        createdDateTo: this.get('createdDateTo'),
        include: this.get('include').join(','),
        employeeId: this.get('employeeId'),
        channels: this.get('bookingActorCodes') && this.get('bookingActorCodes').join(','),
        utmSource: this.get('utmSource'),
      };

      const codes = this.get('appointmentStatusCodes');

      urlParams.codes = codes.join(',');

      // Rendering the final URL
      return App.Api.wsUrl(Wahanda.Template.render(base, urlParams));
    },

    /**
     * Returns in calendar format.
     *
     * Options with params:
     * > @param boolean isDayView
     * > @param Array resources List of groupIds to expand data into
     * > @param int groupsCount Count of all groups
     * @return Array
     */
    getInCalendarFormat: function (options, offersCollection) {
      const formatted = [];
      this.parseAppointments(formatted, options, offersCollection);
      this.parseBlocks(formatted, options);
      return formatted;
    },

    parseAppointments: function (formatted, options, offersCollection) {
      const appointments = this.appointments;
      let apptInfo;

      const apptGroups = this.getAppointmentGroups(true);
      let hasPermissions = Wahanda.Permissions.editAnyCalendar();

      if (!!appointments && appointments.length) {
        appointments.forEach((appointment) => {
          const item = appointment.toJSON();
          const start = `${item.appointmentDate}T${item.startTime}:00`;
          let end = `${item.appointmentDate}T${item.endTime}:00`;

          let title = '';
          let subTitle = '';

          title = item.offerName;

          if (
            item.skus &&
            item.skus.length === 1 &&
            $.trim(item.skus[0].skuName) &&
            !item.skus[0].nameInherited
          ) {
            subTitle = item.skus[0].skuName;
          }

          hasPermissions = Wahanda.Permissions.editRotaCalendar(item.employeeId);

          apptInfo = this.getAppointmentInfo(item, apptGroups);

          if (
            apptInfo.cleanupTimePercentage > 0 &&
            apptInfo.finishingTimePercentage === 0 &&
            apptInfo.processingTimePercentage === 0
          ) {
            end = `${item.appointmentDate}T${item.cleanupEndTime}:00`;
          }

          formatted.push({
            start: Wahanda.Date.parse(start),
            end: Wahanda.Date.parse(end),
            rid: item.employeeId,
            client: apptInfo.clientName,
            title: title,
            subTitle: subTitle,
            id: item.id,
            type: 'appointment',
            fid: this.getFidByStatus(item.appointmentStatusCode),
            serviceColour: this.getAppointmentBackgroundColour(item.id, offersCollection),
            /* eslint-disable camelcase */
            have_notes: apptInfo.hasNotes,
            is_deposit_paid: apptInfo.isPaid,
            no_deposit_warning: apptInfo.isUnpaidWarn,
            pay_at_venue: apptInfo.isPayAtVenue,
            /* eslint-enable camelcase */
            paymentProtectionApplied: appointment.isPaymentProtectionApplied(),
            isRepeated: appointment.isRecurring(),
            prepaid: apptInfo.isPrepaid,
            checkedout: apptInfo.isCheckedout,
            amount: apptInfo.amount,
            walkIn: item.walkIn,
            extraClasses:
              options.focusedAppointmentId && options.focusedAppointmentId === item.id
                ? 'filter-outlined'
                : '',
            source: item.bookingActor,
            platform: item.platform,
            createdByName: item.createdByName,
            cleanupTime: apptInfo.cleanupEndTime,
            cleanupPercentage: apptInfo.cleanupTimePercentage,
            finishingEndTime: apptInfo.finishingEndTime,
            finishingPercentage: apptInfo.finishingTimePercentage,
            processingTime: apptInfo.processingEndTime,
            processingPercentage: apptInfo.processingTimePercentage,
            processingEndTime: apptInfo.processingEndTime,
            venueCustomerId: item.venueCustomerId,
            packageName: apptInfo.packageName,
            draggable: hasPermissions && apptInfo.draggable,
            restrictTime: false,
          });
        });
      }
    },

    getAppointmentBackgroundColour: function (appointmentId, offersCollection) {
      const appointment = this.getAppointment(appointmentId);
      const serviceColour = offersCollection.getColourFor(appointment.get('offerId'));
      const maybePackageGroup = appointment.getLinkedPackageGroup();

      if (maybePackageGroup) {
        const maybeColour = offersCollection.getColourFor(maybePackageGroup.data.offerId);

        return maybeColour || serviceColour;
      }
      return serviceColour;
    },

    getAppointmentInfo: function (appointment) {
      const appt = this.getAppointment(appointment.id);
      const apptGroup = appt.getLinkedPackageGroup();
      const isCheckedout = appt.isCheckedOut();
      return {
        isPaid: appt.isPaidByClient() || isCheckedout,
        isPrepaid: appt.isPrepaid(),
        isPayAtVenue: appt.isPayAtVenue(),
        isUnpaidWarn: !isCheckedout && appt.isUnpaidAndSoon(),
        isCheckedout: isCheckedout,
        cleanupTimePercentage: appt.getCleanupTimeLengthInPercentage(),
        cleanupEndTime: appt.getCleanupEndTimeDate(),
        finishingTimePercentage: appt.getFinishingTimeLengthInPercentage(),
        finishingEndTime: appt.getFinishingEndTimeDate(),
        processingTimePercentage: appt.getProcessingTimeLengthInPercentage(),
        processingEndTime: appt.getProcessingEndTimeDate(),
        packageName: apptGroup ? apptGroup.getName() : null,
        amount: getAppointmentAmount(appt),
        draggable: getDraggableStatus(appt),
        hasNotes: apptGroup
          ? apptGroup.hasNotes()
          : appointment.notes && appointment.notes.length > 0,
        clientName: appt.getConsumerName(),
      };
    },

    getFidByStatus: function (statusCode) {
      switch (statusCode) {
        case 'CN':
          return FID_CONFIRMED;
        case 'CR':
          return FID_BOOKED;
        case 'CP':
          return FID_CHECKED_OUT;
        case 'NS':
          return FID_NO_SHOW;
        default:
          return null;
      }
    },

    parseBlocks: function (formatted, options) {
      const ridList = options.resources && options.resources.length ? options.resources : [];
      const blocks = this.get('blocks');
      let fid;
      let extraClasses;

      if (!!blocks && blocks.length) {
        blocks.forEach((item) => {
          const time = item;
          // do not render improper data
          if (time.itemDate && time.itemTimeFrom && time.itemTimeTo) {
            const blockModel = new App.Models.TimeBlock(time);
            const start = `${time.itemDate}T${time.itemTimeFrom}:00`;
            const end = `${time.itemDate}T${time.itemTimeTo}:00`;
            extraClasses = '';
            fid = FID_NONWORK;
            if (time.type === 'EL') {
              extraClasses = ' sal-external-block';
            }

            const mightRepeat = time.employeeId === null || blockModel.isRecurring();
            if (mightRepeat) {
              extraClasses += ` cal-block-${time.id}`;
            }

            const block = {
              start: Wahanda.Date.parse(start),
              end: Wahanda.Date.parse(end),
              rid: time.employeeId,
              title: time.name,
              id: time.id,
              recurring: this.isTypeRecurring(time.availabilityRuleTypeCode),
              type: 'block',
              fid: fid,
              originalResourceId: time.employeeId,
              typeCode: time.type,
              blockSource:
                time.blockSource != null
                  ? {
                      code: time.blockSource.code,
                      name: time.blockSource.name,
                    }
                  : {},
              extraClasses: extraClasses,
              draggable: blockDraggableData(blockModel),
              mightRepeat: mightRepeat,
              restrictTime: blockModel.isMultiDayPeriod(),
            };
            formatted.push(block);
            if (time.employeeId == null) {
              // If the block is venue-wide, push it to all groups
              Object.keys(ridList).forEach((k) => {
                formatted.push(_.extend({}, block, { rid: ridList[k] }));
              });
            }
          }
        });
      }
    },

    isTypeRecurring: function (type) {
      return type in { A: 1, W: 1, B: 1 };
    },

    /**
     * Returns block for the given date.
     *
     * @param int blockId
     * @param Date date
     * @return Object or undefined
     */
    getBlock: function (blockId, date) {
      const blockDate = Wahanda.Date.toApiString(date);
      return _.find(this.get('blocks'), function (block) {
        return block.id === blockId && block.itemDate === blockDate;
      });
    },

    /**
     * Returns appointment data.
     *
     * @param int appointmentId
     * @return Object or undefined
     */
    getAppointment: function (appointmentId) {
      return this.appointments.get(appointmentId);
    },

    /**
     * Finds all appointments with have the matching attributes.
     *
     * @return array
     */
    findAppointments: function (attributes) {
      return this.appointments.where(attributes);
    },

    // returns data for the CustomerAppointments collection
    getAppointmentsForCustomerAndDate: function (customerId, date) {
      const appts = this.findAppointments({
        venueCustomerId: customerId,
        appointmentDate: date,
      });
      let groups = [];

      _.each(appts, function (appt) {
        groups = groups.concat(
          _.map(appt.groups, function (group) {
            return group.data;
          }),
        );
      });

      return {
        appointments: _.invoke(appts, 'toJSON'),
        appointmentGroups: _.uniq(groups),
      };
    },

    /**
     * Finds all blocks with have the matching attributes.
     *
     * @return array
     */
    findBlocks: function (attributes) {
      return _.where(this.get('blocks'), attributes);
    },

    /**
     * Get a later start time for an appointment if there is an overlap in the old appts last calendar slot. E.g.:
     *       |----------|++|        (<- an existing appointment, ++ stands for cleanup time.)
     *    |  |  |  |  |  |  |       (<- calendar slots)
     *                    |-------| (<- the checked new appointment with it's start time overlapping w/ old appt)
     *
     * @param Date startDate
     * @param Int employeeId
     *
     * @returns Object { start: time, end: time }
     */
    updateAppointmentTimeIfAppointmentsOverlap: function (startDate, endDate, eId) {
      const startStrDate = Wahanda.Date.toApiString(startDate);
      let startTime = Wahanda.Time.getDateMinutes(startDate);
      let endTime = Wahanda.Time.getDateMinutes(endDate);
      const employeeId = parseInt(eId, 10);
      let newStartTime = startTime;

      // Is there an overlap we want to fix?
      const slotStartTime =
        startTime - (startTime % App.config.get('venue').appointmentSlotDuration);
      const slotEndTime = slotStartTime + App.config.get('venue').appointmentSlotDuration;

      function getAdjustedTimeIfOverlaps(apptDate, start, end) {
        if (apptDate === startStrDate) {
          const apptStartTime = Wahanda.Time.timeToMinutes(start);
          const apptEndTime = Wahanda.Time.timeToMinutes(end);
          // If the new appointment is in the middle of another one, move it to the end
          if (startTime > apptStartTime && startTime < apptEndTime) {
            return apptEndTime;
          }
          // If we're starting a new appointment in a slow where an old starts, move the new appointment a bit
          // forward to start not on slot beginning but right after the appointment.
          if (apptEndTime > slotStartTime && apptEndTime < slotEndTime) {
            return apptEndTime;
          }
        }
        return null;
      }

      _.each(this.get('appointments'), function (appt) {
        if (appt.employeeId !== employeeId) {
          return;
        }

        const newTime = getAdjustedTimeIfOverlaps(
          appt.appointmentDate,
          appt.startTime,
          appt.cleanupEndTime || appt.endTime,
        );
        if (newTime && newTime > newStartTime) {
          newStartTime = newTime;
        }
      });

      if (newStartTime !== startTime) {
        const diff = newStartTime - startTime;
        endTime += diff;
        startTime = newStartTime;
      }
      return {
        start: Wahanda.Time.toApiString(startTime),
        end: Wahanda.Time.toApiString(endTime),
      };
    },

    getAppointments: function () {
      return this.appointments;
    },

    /**
     * Return list or hash of AppointmentGroups.
     *
     * @param Boolean asHashById (optional, default: false) Should the result be returned not as a list, but
     *    as a Map where the key is the groupId?
     *
     * @returns Array or Object[groupId: collection]
     */
    getAppointmentGroups: function (asHashById) {
      return asHashById
        ? this.appointmentGroups
        : _.map(this.appointmentGroups, function (o) {
            return o;
          });
    },

    buildAppointmentGroups: function (apptGroupData) {
      const self = this;
      this.appointmentGroups = {};

      _.each(apptGroupData, function (groupData) {
        self.appointmentGroups[groupData.id] = new App.Collections.AppointmentGroup(
          self.appointments.filter(apptByGroupFinder),
          groupData,
        );

        function apptByGroupFinder(appt) {
          return appt.belongsToGroup(groupData.id);
        }
      });
    },

    /**
     * Counts unique View items.
     * A `View Item` is a top-level thing:
     * - MultiServices group
     * - Package group (if not under MS)
     * - Appointment (if not in Package and MS group)
     *
     * @returns Number
     */
    countUniqueViewItems: function () {
      let standaloneAppointments = 0;
      let groupCount = 0;
      const groups = {};

      _.each(this.get('appointments'), function (apptData) {
        if ((apptData.appointmentGroupIds || []).length === 0) {
          standaloneAppointments += 1;
        } else {
          const key = _.clone(apptData.appointmentGroupIds).sort().join('-');
          if (!groups[key]) {
            groups[key] = true;
            groupCount += 1;
          }
        }
      });

      return standaloneAppointments + groupCount;
    },
  });
})();
