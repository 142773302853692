/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import {
  receiveVenueDetailsAction,
  requestVenueDetailsFailureAction,
  inviteToDownloadAppBySMSSuccessAction,
  inviteToDownloadAppBySMSFailureAction,
} from './actions';

import { GetVenueDetailsRequestAction, InviteToDownloadAppBySMSRequestAction } from './types';

export function* venueDetailsRequested(params: GetVenueDetailsRequestAction) {
  try {
    const venueId = params.data.venueId;
    const data = yield call(api.get, `/api/venue/${venueId}.json`);
    const payload = { isConsentedForAppAdvocacy: data.consentedForAppAdvocacy };
    yield put(receiveVenueDetailsAction(payload));
  } catch (err: any) {
    yield put(requestVenueDetailsFailureAction({ errorMessage: err }));
  }
}

export function* inviteToDownloadAppBySMSRequested(action: InviteToDownloadAppBySMSRequestAction) {
  try {
    const { id: venueCustomerId } = action.data;

    yield call(api.post, apiUrl('INVITE_TO_DOWNLOAD_TREATWELL_APP', { venueCustomerId }));
    yield put(inviteToDownloadAppBySMSSuccessAction());
  } catch (err: any) {
    yield put(inviteToDownloadAppBySMSFailureAction({ errorMessage: err }));
  }
}
