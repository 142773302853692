App.Views.Forms.MenuOffer2.Pricing.Dated.DatesEditDialog = BackboneEx.View.Dialog.ValidatingForm.PricingBase.extend(
  {
    events: {
      'click .a-delete': 'remove',
      'click .button-cancel': 'close',
    },
    options: {
      width: 600,
      title: Wahanda.lang.menu.offer.pricing.dateEdit.title,
    },

    templateId: 'menu-offer-pricing-dates-dialog',

    postInitialize: function () {},

    initForm: function () {
      this.datesView = new App.Views.Forms.MenuOffer2.Pricing.Dated.Dates(
        _.extend({ el: this.$('.b-container') }, this.options),
      );
      this.datesView.render();

      if (this.options.disableDelete) {
        this.$('.a-delete').wHide();
      }
    },

    remove: function () {
      var self = this;
      var removeFunc = function () {
        self.options.deleteCallback();
        self.close();
      };

      Wahanda.Dialogs.Confirm(Wahanda.lang.menu.offer.pricing.removeQuestion, removeFunc);
    },
  },
);
