import { getTrackingByEventName } from 'src/tracking-container/TrackingEvents';
import { loadScript } from './loadScript';
import { ZENDESK_SCRIPT } from './zendesk-script-key';

const trackChatOpened = getTrackingByEventName('chat_opened');

/* eslint-disable func-names */
Wahanda.Livechat = {
  configSettings: function () {
    window.zESettings = {
      webWidget: {
        answerBot: {
          contactOnlyAfterQuery: true,
        },
      },
    };
  },
  load: async function () {
    const zendeskScriptLoaded = !!window.zE;
    if (zendeskScriptLoaded) {
      return;
    }

    this.configSettings();

    await loadScript(ZENDESK_SCRIPT);

    window.zE('webWidget', 'hide');
  },
  show: async function (source) {
    await this.load();
    trackChatOpened({ service: 'Zendesk', source });
    App.trigger(Wahanda.Event.LIVECHAT_TOGGLE, { eventSource: source });
    if (window.zE != null) {
      this.setupDepartment();
      window.zE('webWidget', 'show');
      window.zE('webWidget', 'open');
      window.zE('webWidget:on', 'close', () => {
        window.zE('webWidget', 'hide');
      });
    }
  },
  setupDepartment: function () {
    if (window.$zopim == null || window.$zopim.livechat == null) {
      return;
    }

    const CHANNEL_CODES = App.CHANNEL_CODES;

    const CHANNEL_CODE_TO_ZENDESK_DEPARTMENT = {
      [CHANNEL_CODES.GB]: 'Partners (UK & Ireland)',
      [CHANNEL_CODES.DE]: 'Partners (Germany)',
      [CHANNEL_CODES.ES]: 'Partners (Spain)',
      [CHANNEL_CODES.FR]: 'Partners (France)',
      [CHANNEL_CODES.BE]: 'Partners (Belgium)',
      [CHANNEL_CODES.IE]: 'Partners (UK & Ireland)',
      [CHANNEL_CODES.IT]: 'Partners (Italy)',
      [CHANNEL_CODES.AT]: 'Partners (Austria)',
      [CHANNEL_CODES.CH]: 'Partners (Switzerland)',
      [CHANNEL_CODES.NL]: 'Partners (Netherlands)',
      [CHANNEL_CODES.LT]: 'Partners (Lithuania)',
    };

    if (!window.$zopim.livechat.isChatting()) {
      const departmentName = CHANNEL_CODE_TO_ZENDESK_DEPARTMENT[App.venueChannelCode()];
      const department = window.$zopim.livechat.departments.getDepartment(departmentName);

      if (department?.status === 'online') {
        window.$zopim.livechat.setStatus('online');
        window.$zopim.livechat.departments.setVisitorDepartment(departmentName);
      } else {
        window.$zopim.livechat.setStatus('offline');
      }
    }
  },
};
