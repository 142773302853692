App.Views.Forms.MenuOffer2.Pricing.Dated.List = Backbone.View.extend({
  events: {
    'click .a-edit-dates': 'onEditDates',
    'click .b-pricing-item': 'onItemClicked',
    'click .a-new-pricing': 'onAddPricing',
  },

  initialize: function () {
    // pricing items
    this.childViews = [];
    // Container for pricing items
    this.$container = null;

    this.dateFrom = Wahanda.Date.createDateIfString(this.options.data.dateFrom);
    this.dateTo = this.options.data.dateTo
      ? Wahanda.Date.createDateIfString(this.options.data.dateTo)
      : null;
    this.pricingType = this.options.data.pricingType;
  },

  render: function () {
    this.renderContainer();

    if (!this.options.allowEditRange) {
      this.$el.addClass('.no-range-change');
    }

    if (this.options.hasRoomTypes) {
      this.renderRoomPrices();
    } else {
      this.renderSimplePrices();
    }
    this.toggleAddPricingButton();
    this.tooltipize();
  },

  tooltipize: function () {
    this.$el.tooltipize({
      position: {
        viewport: true,
      },
    });
  },

  renderContainer: function () {
    this.setElement(
      Wahanda.Template.renderTemplate('menu-offer-dated-pricing-list', {
        editable: Wahanda.Permissions.editMenu(),
        dateChangeable: this.options.allowEditRange,
      }),
    );
    this.$container = this.$('.pricing-items');

    this.renderDates();
  },

  renderDates: function () {
    if (this.options.allowEditRange) {
      this.$('.v-price-dates').text(this.getDatesText());
    }
  },

  renderSimplePrices: function () {
    // Prepare the container for pricing items
    this.$container.html($('#menu-offer-dated-pricing-list-simple').html());
    this.childViews = [];
    for (var i = 0, len = this.options.data.list.length; i < len; i++) {
      this.addPricingView(this.options.data.list[i]);
    }
  },

  renderRoomPrices: function () {
    this.childViews = [];
    for (var i = 0, len = this.options.data.list.length; i < len; i++) {
      this.addPricingView(this.options.data.list[i]);
    }
    this.resortRoomViews();
  },

  addPricingView: function (data, resort) {
    var view;
    if (this.options.hasRoomTypes) {
      data.roomTypes = this.getRoomTypes();
      view = new App.Views.Forms.MenuOffer2.Pricing.Dated.List.Rooms(data);
    } else {
      view = new App.Views.Forms.MenuOffer2.Pricing.Dated.List.Simple(data);
    }
    view.render();

    this._addSorted(view, resort);
  },

  getRoomTypes: function () {
    return this.options.parentView.getRoomTypes();
  },

  _addSorted: function (view, resort) {
    // Removing the View from array, if it's there
    this.childViews = _.without(this.childViews, view);
    // Adding the view into it's sorted position
    var index = _.sortedIndex(this.childViews, view, function (item) {
      return item.getFirstWeekDayNumber();
    });

    var tmp = this.childViews.slice(0, index);
    tmp.push(view);
    tmp = tmp.concat(this.childViews.slice(index));
    this.childViews = tmp;

    if (this.options.hasRoomTypes) {
      if (resort) {
        this.resortRoomViews();
      }
    } else {
      // Simple view addition
      if (index - 1 >= 0) {
        view.$el.insertAfter(this.childViews[index - 1].$el);
      } else {
        view.$el.prependTo(this.$container.find('table'));
      }
    }
  },

  /**
   * Resorts all views by removing them from the DOM, adding in correct order
   */
  resortRoomViews: function () {
    // Detaching existing views from DOM, clearing the container
    var list = _.map(this.childViews, function (view) {
      return view.$el.removeClass('last').detach();
    });
    this.$container.empty();

    var $fragment = $(document.createDocumentFragment());
    if (list.length > 1) {
      this.$container.addClass('pricing-container-many clearfix');
    } else {
      this.$container.removeClass('pricing-container-many clearfix');
    }

    var even;
    for (var i = 0, len = list.length; i < len; i++) {
      even = i % 2 === 1;
      if (even) {
        list[i].addClass('last');
      }

      $fragment.append(list[i]);

      if (even && i + 1 !== len) {
        $fragment.append('<div class="clearer"></div>');
      }
    }

    this.$container.append($fragment);
  },

  getDatesText: function () {
    var langKey = 'from';
    var fromText = Wahanda.Date.formatToDefaultDate(this.dateFrom);
    var toText = '';
    if ('R' === this.pricingType) {
      toText = Wahanda.Date.formatToDefaultDate(this.dateTo);
      langKey = 'range';
    }
    return Wahanda.Template.render(Wahanda.lang.menu.offer.pricing.dates[langKey], {
      from: fromText,
      to: toText,
    });
  },

  toggleAddPricingButton: function () {
    var usedDays = 0;
    _.each(this.childViews, function (view) {
      usedDays += view.applicableDays.length;
    });

    this.$('.a-new-pricing').toggle(usedDays < 7);
  },

  /**
   * Returns weekdays already in use by other views
   *
   * @param ListView ignoredView OPTIONAL view to ignore
   * @return Array
   */
  getUsedWeekdays: function (ignoredView) {
    var list = [];
    _.each(this.childViews, function (view) {
      if (view !== ignoredView) {
        list = list.concat(view.applicableDays);
      }
    });
    return list;
  },

  getViewByRow: function ($row) {
    return _.find(this.childViews, function (view) {
      return view.$el.is($row);
    });
  },

  removeView: function (view) {
    this.childViews = _.without(this.childViews, view);
    view.remove();

    if (this.childViews.length === 0) {
      // Remove this view too - no child views left
      this.options.parentView.removeView(this);
    } else if (this.childViews.length === 1) {
      if (!this.options.parentView.onSingleChildLeft() && this.options.hasRoomTypes) {
        this.resortRoomViews();
      }
    } else if (this.options.hasRoomTypes) {
      this.resortRoomViews();
    }
  },

  _openPricingEditDialog: function (options) {
    var dialog = new App.Views.Forms.MenuOffer2.Pricing.Dated.EditDialog(
      _.extend(options, {
        renderDates: false,
        hasRoomTypes: this.options.hasRoomTypes,
        roomTypes: this.getRoomTypes(),
      }),
    );
    dialog.render();
    dialog.open();
  },

  getValues: function () {
    var values = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      pricingType: this.pricingType,
      list: [],
    };

    for (var i = 0, len = this.childViews.length; i < len; i++) {
      values.list.push(this.childViews[i].getValues());
    }

    return values;
  },

  // Events

  onEditDates: function () {
    var self = this;
    var dialog = new App.Views.Forms.MenuOffer2.Pricing.Dated.DatesEditDialog({
      pricingType: this.pricingType,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      saveCallback: function (values) {
        return self._datesUpdate(values);
      },
      deleteCallback: function () {
        self.options.parentView.removeView(self);
      },
      disableDelete: this.options.parentView.getRangesCount() === 1,
    });
    dialog.render();
    dialog.open();
  },

  _datesUpdate: function (values) {
    var self = this;
    var intersection = this.options.parentView.getDateIntersection(
      values.dateFrom,
      values.dateTo,
      this,
    );
    var saveCallback = function () {
      _.extend(self, values);
      self.renderDates();
      self.trigger('date-change');
    };

    if (null == intersection) {
      // No intersection. Proceed with save.
      saveCallback();
      return { valid: true };
    } else {
      if ('partial' === intersection.type) {
        if (null == values.dateTo && null == intersection.view.dateTo) {
          // Both intersections are infinite, but the new one starts after the existing one
          // End the old one before the existing one
          saveCallback();

          this.options.parentView.fixIntersection(this, intersection.view);
          return { valid: true };
        }
        return {
          fixableIntersection: true,
          saveCallback: function (doNotContinue) {
            saveCallback();
            self.options.parentView.fixIntersection(self, intersection.view, {
              doNotContinue: doNotContinue,
            });
          },
        };
      } else {
        return {
          errorText: Wahanda.lang.menu.offer.pricing.errors.intersectionExists,
        };
      }
    }
  },

  onItemClicked: function (event) {
    if (!Wahanda.Permissions.editMenu()) {
      return;
    }

    var self = this;
    var $row = $(event.currentTarget);
    var view = this.getViewByRow($row);

    if (view) {
      var options = view.getValues();
      options.showDelete = true;
      options.saveCallback = function (values) {
        view.setValues(values);
        self.toggleAddPricingButton();
        self._addSorted(view, true);
      };
      options.deleteCallback = function () {
        self.removeView(view);
        self.toggleAddPricingButton();
      };
      options.usedWeekdays = this.getUsedWeekdays(view);
      this._openPricingEditDialog(options);
    }
  },

  onAddPricing: function () {
    var self = this;
    var options = {
      saveCallback: function (values) {
        self.addPricingView(values, true);
        self.toggleAddPricingButton();
      },
      usedWeekdays: this.getUsedWeekdays(),
    };
    if (this.options.hasRoomTypes) {
      options.rooms = _.map(this.childViews[0].rooms, function (room) {
        return {
          roomTypeId: room.roomTypeId,
        };
      });
    }
    this._openPricingEditDialog(options);
  },
});
