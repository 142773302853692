import React from 'react';
import { CHANNEL_CODES } from 'config/domains-locales';
import { assetConfig } from './config';
import style from './style.scss';

interface Props {
  locale?: string;
  contentChannel: string;
}

export const SmsPreview: React.FC<Props> = ({ locale, contentChannel }) => {
  const getSmsAsset = () => {
    if (!locale) {
      return getSmsAssetByContentChannel();
    }
    return getSmsAssetByLocale();
  };
  const getSmsAssetByLocale = () => {
    switch (locale) {
      case 'de':
      case 'de_AT':
      case 'de_CH':
        return assetConfig.sms.dach;
      case 'nl_BE':
      case 'nl':
        return assetConfig.sms.bnl;
      case 'es':
        return assetConfig.sms.es;
      case 'fr':
        return assetConfig.sms.fr;
      case 'it':
        return assetConfig.sms.it;
      case 'lt':
        return assetConfig.sms.lt;
      default:
        return assetConfig.sms.ukie;
    }
  };
  const getSmsAssetByContentChannel = () => {
    switch (contentChannel) {
      case CHANNEL_CODES.DE:
      case CHANNEL_CODES.AT:
      case CHANNEL_CODES.CH:
        return assetConfig.sms.dach;
      case CHANNEL_CODES.BE:
      case CHANNEL_CODES.NL:
        return assetConfig.sms.bnl;
      case CHANNEL_CODES.ES:
        return assetConfig.sms.es;
      case CHANNEL_CODES.FR:
        return assetConfig.sms.fr;
      case CHANNEL_CODES.IT:
        return assetConfig.sms.it;
      case CHANNEL_CODES.LT:
        return assetConfig.sms.lt;
      default:
        return assetConfig.sms.ukie;
    }
  };
  return <img className={style.preview} src={getSmsAsset()} />;
};
