import React from 'react';
import { get, assoc, mapIndexed, isEmpty, partial, some, equals, toJs } from 'mori';
import { withAnyCursor, cursorValueHasChanged } from 'atom/cursors';
import { mapv } from 'common-js';
import { appState } from 'state';
import Wahanda from 'common/wahanda';
import { isAnyWaiting } from 'common/waiting';
import { Loader } from 'components/common/Loader';
import { EmptyList } from 'components/settings/resources/EmptyList';
import { Button } from 'components/common/Button';
import { Plus } from 'components/common/Icon';
import ResourceDetails from 'components/settings/resources/ResourceDetails';
import {
  fetchResources,
  fetchMenu,
  RESOURCE_FETCH_WAIT,
  RESOURCE_UPDATE_WAIT,
  RESOURCE_ADD_WAIT,
  RESOURCE_DELETE_WAIT,
  FETCH_MENU_WAIT,
} from 'services/ResourcesService';
import { trackEvent } from 'common/analytics';
import { LayoutContainer } from 'components/common/Layout';

const isResourceNameUnique = (resources, id, name) =>
  !some((r) => get(r, 'id') !== id && get(r, 'name') === name, resources);
type ResourceListProps = {
  lang: any;
  onResourceListItemClick?: (...args: any[]) => any;
  onDialogCloseFn?: (...args: any[]) => any;
  maybeSpinner?: JSX.Element;
  showResourceDetailsDialog?: boolean;
  clickedResourceId?: string | number;
  onAddResourceButtonClick?: (...args: any[]) => any;
};

const ResourceListItem: React.SFC<ResourceListItemProps> = ({ id, name, quantity, onClickFn }) => (
  <li className="data-list-row" onClick={onClickFn} data-resource-id={id}>
    <span className="data-list-item item-name">{name}</span>
    <span>{quantity}</span>
  </li>
);

const ResourceList: React.SFC<ResourceListProps> = ({
  lang,
  onResourceListItemClick,
  onDialogCloseFn,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'resources' does not exist on type 'Props... Remove this comment to see the full error message
  resources,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'groups' does not exist on type 'PropsWit... Remove this comment to see the full error message
  groups,
  maybeSpinner,
  showResourceDetailsDialog,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'services' does not exist on type 'PropsW... Remove this comment to see the full error message
  services,
  clickedResourceId,
  onAddResourceButtonClick,
}) => {
  // builds the JSX for the resources list's items
  const ResourceListItems = mapIndexed((index, r) => {
    const resourceListItemProps = assoc(
      r,
      'onClickFn',
      partial(onResourceListItemClick, get(r, 'id')),
      'key',
      index,
    );
    return <ResourceListItem {...toJs(resourceListItemProps)} />;
  }, resources);
  // the resource (eventually) clicked by the user
  const clickedResource = some((r) => equals(get(r, 'id'), clickedResourceId) && r, resources);
  // returns true if the service id, as sId, is assigned to the resource r
  const isServiceAssigned = (sId, r) => {
    const assignedServices = get(r, 'serviceIds');
    return some((assignedServiceId) => equals(assignedServiceId, sId), assignedServices);
  };
  // adds the checked flag, as true, for the services that are assigned to the
  // clicked resource
  const servicesListWithCheckedFlag = mapv(
    (s) => assoc(s, 'checked', isServiceAssigned(get(s, 'id'), clickedResource)),
    services,
  );
  const maybeResourceDetails = showResourceDetailsDialog ? (
    <ResourceDetails
      resource={clickedResource}
      services={servicesListWithCheckedFlag}
      groups={groups}
      // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type '(...ar... Remove this comment to see the full error message
      onCloseFn={onDialogCloseFn}
      isResourceNameUniqueFn={partial(isResourceNameUnique, resources)}
    />
  ) : null;
  return (
    <LayoutContainer
      footer={
        <Button
          icon={<Plus />}
          label={Wahanda.lang.settings.venue.resources.addNew}
          onClick={onAddResourceButtonClick}
        />
      }
    >
      <div className="resources-list">
        {maybeSpinner}
        {maybeResourceDetails}
        <span className="list-description">{lang.settings.venue.resources.listDescription}</span>
        <span className="th resource-name">{lang.settings.venue.resources.resourceName}</span>
        <span className="th quantity">{lang.settings.venue.resources.quantity}</span>
        <ul className="data-list">{toJs(ResourceListItems)}</ul>
      </div>
    </LayoutContainer>
  );
};
type EmptyScreenProps = {
  showResourceDetailsDialog?: boolean;
  onDialogCloseFn?: (...args: any[]) => any;
  onAddResourceButtonClick?: (...args: any[]) => any;
  maybeSpinner?: JSX.Element;
};
const EmptyScreen: React.SFC<EmptyScreenProps> = ({
  showResourceDetailsDialog,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'services' does not exist on type 'PropsW... Remove this comment to see the full error message
  services,
  onDialogCloseFn,
  onAddResourceButtonClick,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'resources' does not exist on type 'Props... Remove this comment to see the full error message
  resources,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'groups' does not exist on type 'PropsWit... Remove this comment to see the full error message
  groups,
  maybeSpinner,
}) => {
  const resourceDetails = showResourceDetailsDialog ? (
    <ResourceDetails
      services={services}
      groups={groups}
      // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type '(...ar... Remove this comment to see the full error message
      onCloseFn={onDialogCloseFn}
      isResourceNameUniqueFn={partial(isResourceNameUnique, resources)}
    />
  ) : null;
  const emptyList = (
    <EmptyList
      icon="resources-logo"
      text={Wahanda.lang.settings.venue.resources.addNewLabel}
      addButtonText={Wahanda.lang.settings.venue.resources.addNew}
      addAction={onAddResourceButtonClick}
    />
  );
  return (
    <div>
      {maybeSpinner}
      {resourceDetails}
      {emptyList}
    </div>
  );
};
type ResourceListItemProps = {
  id?: string | number;
  name?: string;
  quantity?: string | number;
  onClickFn?: (...args: any[]) => any;
};

interface IundefinedProps extends React.HTMLAttributes<Element> {
  loading?: boolean;
}
type undefinedState = {
  services?: any;
  groups?: any;
  resources?: any;
  showResourceDetailsDialog?: boolean;
  clickedResourceId?: null;
};

export default class ResourceListPage extends React.Component<IundefinedProps, undefinedState> {
  // TODO get rid of clickedResourceId and use partial instead!!!
  state = {
    services: null,
    resources: null,
    showResourceDetailsDialog: false,
    clickedResourceId: null,
  };

  componentDidMount() {
    appState.addWatch(
      'ResourcesList',
      withAnyCursor(
        (_, newState) => {
          this.setState({
            services: get(newState, 'services'),
            groups: get(newState, 'menuGroups'),
            resources: get(newState, 'resources'),
          });
        },
        [['venueId'], ['resources'], ['services']],
        cursorValueHasChanged,
      ),
    );
    fetchMenu(appState);
    fetchResources(appState);
  }

  componentWillUnmount() {
    appState.removeWatch('ResourcesList');
  }

  getSpinner(resources) {
    const maybeWaitingForXHR = isAnyWaiting([
      RESOURCE_FETCH_WAIT,
      RESOURCE_UPDATE_WAIT,
      RESOURCE_ADD_WAIT,
      RESOURCE_DELETE_WAIT,
      FETCH_MENU_WAIT,
    ]);
    return resources === null || maybeWaitingForXHR || this.props.loading ? (
      <Loader positionAbsolute />
    ) : null;
  }

  render() {
    const {
      resources,
      services,
      clickedResourceId,
      showResourceDetailsDialog,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'groups' does not exist on type '{ servic... Remove this comment to see the full error message
      groups,
    } = this.state;
    const onResourceListItemClick = (id) => {
      this.setState({
        showResourceDetailsDialog: true,
        clickedResourceId: id,
      });
    };
    const onAddResourceButtonClick = () => {
      trackEvent('venue-resources', 'click', 'add-new-resource');
      this.setState({
        showResourceDetailsDialog: true,
        clickedResourceId: null,
      });
    };
    const onDialogClose = () => {
      this.setState({ showResourceDetailsDialog: false });
    };
    const maybeSpinner = this.getSpinner(resources);
    if (resources === null) {
      return maybeSpinner;
    }
    const content = isEmpty(resources) ? (
      <EmptyScreen
        onDialogCloseFn={onDialogClose}
        resources={resources}
        showResourceDetailsDialog={showResourceDetailsDialog}
        services={services}
        onAddResourceButtonClick={onAddResourceButtonClick}
        groups={groups}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Element | u... Remove this comment to see the full error message
        maybeSpinner={maybeSpinner}
      />
    ) : (
      <ResourceList
        lang={Wahanda.lang}
        onResourceListItemClick={onResourceListItemClick}
        onDialogCloseFn={onDialogClose}
        resources={resources}
        showResourceDetailsDialog={showResourceDetailsDialog}
        services={services}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message
        clickedResourceId={clickedResourceId}
        onAddResourceButtonClick={onAddResourceButtonClick}
        groups={groups}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Element | u... Remove this comment to see the full error message
        maybeSpinner={maybeSpinner}
      />
    );
    return content;
  }
}
