import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Money extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-money', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M2,6 L2,18 L22,18 L22,6 L2,6 Z M0,5.00087166 C0,4.4481055 0.44746922,4 1.00069463,4 L22.9993054,4 C23.5519738,4 24,4.44463086 24,5.00087166 L24,18.9991283 C24,19.5518945 23.5525308,20 22.9993054,20 L1.00069463,20 C0.448026248,20 0,19.5553691 0,18.9991283 L0,5.00087166 Z M12,15 C12.9941394,15 14,13.7426743 14,12 C14,10.2573257 12.9941394,9 12,9 C11.0058606,9 10,10.2573257 10,12 C10,13.7426743 11.0058606,15 12,15 Z M2,14 C4.209139,14 6,15.790861 6,18 L6,18 L4,18 C4,16.8954305 3.1045695,16 2,16 L2,16 Z M22,14 L22,16 C20.8954305,16 20,16.8954305 20,18 L18,18 C18,15.790861 19.790861,14 22,14 Z M12,7 C14.209139,7 16,9.23857625 16,12 C16,14.7614237 14.209139,17 12,17 C9.790861,17 8,14.7614237 8,12 C8,9.23857625 9.790861,7 12,7 Z M6,6 C6,8.209139 4.209139,10 2,10 L2,8 C3.1045695,8 4,7.1045695 4,6 L6,6 Z M20,6 C20,7.1045695 20.8954305,8 22,8 L22,8 L22,10 C19.790861,10 18,8.209139 18,6 L18,6 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Money);
