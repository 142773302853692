import { trackEvent, ACTIONS } from 'common/analytics';

export class ClientsAnalytics {
  static CATEGORY = 'clients-list';

  static trackBulkDeleteClick() {
    trackEvent(ClientsAnalytics.CATEGORY, ACTIONS.CLICK, 'bulk-delete');
  }

  static trackClientsImportAddClientsClick() {
    trackEvent(ClientsAnalytics.CATEGORY, ACTIONS.CLICK, 'clients-import', 'add-clients-button');
  }

  static trackClientsImportCloseClick() {
    trackEvent(ClientsAnalytics.CATEGORY, ACTIONS.CLICK, 'clients-import', 'close');
  }

  static trackClientsImportChooseFileClick() {
    trackEvent(ClientsAnalytics.CATEGORY, ACTIONS.CLICK, 'clients-import', 'choose-file');
  }

  static trackClientsImportDownloadTemplateClick() {
    trackEvent(ClientsAnalytics.CATEGORY, ACTIONS.CLICK, 'clients-import', 'download-template');
  }
}
