import React from 'react';
import classname from 'classnames';
import Plus from 'components/common/icons/Plus';
import Minus from 'components/common/icons/Minus';
import actionTypes from './actionTypes';
import style from './style.scss';

interface ISetButtonProps extends React.HTMLAttributes<Element> {
  type: any;
  onAmountChanged: (...args: any[]) => any;
  isActive?: boolean;
}

export default class SetButton extends React.Component<ISetButtonProps, {}> {
  static defaultProps = {
    isActive: false,
  };

  getStyle = () => (this.props.isActive ? style.active : style.passive);

  render() {
    const { type, onAmountChanged } = this.props;
    return (
      <button className={classname(style.button, this.getStyle())} onClick={onAmountChanged}>
        {type === actionTypes.INCREASE ? <Plus size={10} /> : <Minus size={10} />}
      </button>
    );
  }
}
