import React from 'react';
import Wahanda from 'common/wahanda';
import { RouteComponentProps, generatePath } from 'react-router-dom';
import BigSelectDropdown, { Data } from 'components/reports/BigSelectDropdown';
import { POS_REPORTS_ROUTES } from 'components/reports/newSalesReports/SalesReportContainer/reportRoutes';

export interface UrlParams {
  venueId: string;
}

export interface Props extends RouteComponentProps<UrlParams> {
  canViewVatReport: boolean;
  canViewPosReports: boolean;
}

const LANG = Wahanda.lang.reports.sales.reportType;

export default class POSReportSelector extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.getDropDownValues = this.getDropDownValues.bind(this);
  }

  public render() {
    const props = {
      data: this.getDropDownValues(),
      selected: this.props.match.path,
      onSelect: this.onSelect,
    };
    return <BigSelectDropdown {...props} />;
  }

  private getDropDownValues() {
    const { canViewPosReports, canViewVatReport } = this.props;
    const result: Data<string>[] = [];

    if (canViewPosReports) {
      result.push({
        name: LANG.posMonthly,
        value: POS_REPORTS_ROUTES.MONTH_POS_REPORT,
      });
      result.push({
        name: LANG.posYearly,
        value: POS_REPORTS_ROUTES.YEAR_POS_REPORT,
      });
    }

    if (canViewVatReport) {
      result.push({
        name: LANG.vatReport,
        value: POS_REPORTS_ROUTES.VAT_REPORT,
      });
    }
    return result;
  }

  private onSelect(path: string) {
    this.props.history.push(
      generatePath(path, {
        venueId: this.props.match.params.venueId,
      }),
    );
  }
}
