import React from 'react';

const SvgInfoIcon = (props) => (
  <svg width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(-8 -273)">
      <path
        fill="#00B0B9"
        fillOpacity={0.05}
        stroke="#D5EFF1"
        d="M3 265.5A2.5 2.5 0 0 0 .5 268v74a2.5 2.5 0 0 0 2.5 2.5h444a2.5 2.5 0 0 0 2.5-2.5v-74a2.5 2.5 0 0 0-2.5-2.5H3z"
      />
      <path d="M8 273h24v24H8z" />
      <circle cx={20} cy={285} r={9} fill="#FFF" />
      <circle cx={20} cy={285} r={9} stroke="#00B0B9" strokeWidth={2} />
      <path stroke="#00B0B9" strokeWidth={2} d="M20 284v6" />
      <circle cx={20} cy={281} r={1.5} fill="#00B0B9" />
    </g>
  </svg>
);

export default React.memo(SvgInfoIcon);
