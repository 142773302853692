import { trackEvent } from 'common/analytics';
import { xhr } from 'common/xhr';

(function () {
  var timer;

  function clearTimer() {
    window.clearInterval(timer);
    timer = null;
  }

  App.Views.Forms.CustomerImport = App.Views.Dialog.ImageUpload.extend({
    events: {
      'click .button-cancel': 'close',
      'click .a-cancel': 'close',
      'click .js-import-file': 'clickImportFile',
    },
    options: {
      width: 660,
      title: Wahanda.lang.customer['import'].title,
      dialogClass: 'dialog-new-style',
    },
    templateId: 'customer-import-dialog-template',

    postInitialize: function () {
      trackEvent('clients-list', 'view', 'client-import-form');

      this.setupUpload();
      // Disable upload button
      this.$('.a-upload').disableFormElements();
    },

    setProgress: function (percent) {
      App.Views.Dialog.ImageUpload.prototype.setProgress.apply(this, [percent]);
      this.$('.percentage').text(percent + '%');
    },

    setProgressRequestInterval: function (id) {
      var updateProgressBar = function (percentage) {
        if (!isNaN(percentage)) {
          self.setProgress(percentage);
        }
      };
      var self = this;
      var $button = this.$('.a-upload');

      var doRequest = function (id) {
        var venueId = App.config.get('venue').id;
        xhr
          .doJQueryAjax({
            url: App.Api.wsUrl('/venue/' + venueId + '/customer-import/progress/' + id),
            dataType: 'json',
          })

          .done(function (data) {
            // all import statuses
            var isImportNotStarted = data.status === 'NOT_STARTED';
            var isImportInProgress = data.status === 'IN_PROGRESS';
            var isImportFinished = !(isImportInProgress || isImportNotStarted);
            var isImportFinishedSuccessfully =
              data.status === 'FINISHED_SUCCESS' || data.status === 'FINISHED_WITH_WARNINGS';

            updateProgressBar(parseInt(data.percentage, 10));

            if (isImportFinished) {
              clearTimer();
              $button.action();

              var savedCount = data.importCount || 0;

              var sysEvent = isImportFinishedSuccessfully
                ? Wahanda.Event.CUSTOMERS_IMPORT_SUCCESS
                : Wahanda.Event.CUSTOMERS_IMPORT_FAILED;

              App.trigger(sysEvent, { savedCount: savedCount });

              if (isImportFinishedSuccessfully) {
                trackEvent('clients-list', 'submit', 'client-import-form', {
                  clientsImported: savedCount,
                });
              } else {
                trackEvent('clients-list', 'error', 'client-import-form', {
                  status: data.status,
                });
              }

              self.showImportResults(data.status, data.messages, savedCount);
            }
          })

          .fail(function () {
            self.showUploadError();
            $button.action();
            clearTimer();
          });
      };

      timer = setInterval(function () {
        doRequest(id);
      }, 5000);
    },

    clickImportFile: function () {
      trackEvent('clients-list', 'click', 'client-import-form', 'choose-file');
    },

    setupUpload: function () {
      var self = this;
      var $button = this.$('.a-upload');
      var $input = this.$('input[type=file]');

      $input.fileupload().fileupload('option', {
        dataType: 'json',
        url: App.Api.wsUrl('/venue/' + App.config.get('venue').id + '/customers/import.json'),
        multipart: true,
        paramName: 'file',
        autoUpload: true,
        submit: function (e, data) {
          data.formData = {
            filename: data.files[0].name,
          };
          $button.action('doing');
          return true;
        },
        done: function (event, data) {
          var result = data.result;
          if (!result) {
            return;
          }
          if (result.error) {
            self.showUploadError(result.error);
            $button.action();
          } else if (result.id) {
            // hides the instructions for the file upload
            self.$('.b-step-1 .dialog-content .intro').fadeOut(function () {
              // displayes the progress bar
              self.setProgress(0);
              // starts the import progress
              self.setProgressRequestInterval(result.id);
            });
          }
        },
        fail: function (event, data) {
          var $button = self.$('.a-upload');
          self.showUploadError();
          $button.action();
        },
      });
    },

    showUploadError: function (error) {
      App.trigger(Wahanda.Event.CUSTOMERS_IMPORT_FAILED);
      this.setErrorState();
      if (error) {
        this.showError(error);
      }
    },

    setErrorState: function () {
      // Change number to red X
      this.$('.b-step-1 .number').addClass('error');
      // Change subheading
      this.$('.v-subheading').text(Wahanda.lang.customer['import'].errors.uploadErrorSubheading);
    },

    showError: function (error) {
      var code = Wahanda.Text.toCamelCase(error.code);
      this.$('.b-upload-errors')
        .html(Wahanda.lang.customer['import'].uploadErrors[code] || '')
        .wShow();
    },

    showImportResults: function (importStatus, messages, savedCount) {
      const messagesForDisplay = messages != null ? messages.map((m) => m.message) : [];

      if (importStatus === 'UNFINISHED_BECAUSE_OF_ERRORS') {
        this.showRowTable('errors', messagesForDisplay, savedCount, 'icons-failed-small');
      }
      if (importStatus === 'FINISHED_WITH_WARNINGS') {
        this.showRowTable('warnings', messagesForDisplay, savedCount, 'icons-attention-small');
      }
      // Show success content
      this.$('.b-step-1').wHide();
      this.$('.b-step-3').wShow();

      this.resetCloseChangesCheck();
    },

    showRowTable: function (tableType, errorList, savedCount, iconClass) {
      // list is adjusted for the template
      var list = _.map(errorList, function (item) {
        return { cells: [item] };
      });
      var html = Wahanda.Template.renderTemplate('customer-import-table-row-template', {
        rows: list,
        rowPercentWidth: 100,
        iconClass: iconClass,
      });
      var $blocks = this.$('.b-' + tableType);
      $blocks.find('.v-item-count').text(savedCount);
      $blocks.filter('table').append(html);

      $blocks.wShow();
    },

    onDialogOpen: function () {
      App.Views.Dialog.ImageUpload.prototype.onDialogOpen.call(this);
    },

    _afterClose: clearTimer,
  });
})();
