import React from 'react';
import moment from 'common/moment';

export const TIME_TYPES = {
  DATE: 'DATE',
  TIME: 'TIME',
  DATE_TIME: 'DATE_TIME',
  LONG_LOCALE_DATE: 'LONG_LOCALE_DATE',
};

interface Props {
  type?: string;
  time: string;
}

export const Time = ({ type = TIME_TYPES.TIME, time }: Props) => {
  let momentTime = '';

  switch (type) {
    case TIME_TYPES.DATE:
      momentTime = moment(time).formatDateDefault();
      break;
    case TIME_TYPES.DATE_TIME:
      momentTime = moment(time).formatFullDateTime();
      break;
    case TIME_TYPES.LONG_LOCALE_DATE:
      momentTime = moment(time).formatLongLocaleDate();
      break;
    case TIME_TYPES.TIME:
    default:
      momentTime = moment(time).formatTimeDefault();
      break;
  }

  return <>{momentTime}</>;
};
