import apiUrl from 'common/api-url';

App.Collections.Checkouts = BackboneEx.Collection.UrlFilter.extend({
  defaults: {},
  model: App.Models.Checkout,

  parse: function parse(data) {
    return data;
  },

  filters: {
    venueCustomerId: null,
    date: null,
    checkoutIds: null,
  },

  filterMap: {
    venueCustomerId: 'venueCustomerId',
    date: 'date',
    checkoutIds: 'checkoutIds',
  },
  url: function url() {
    return apiUrl('CHECKOUTS', this.filters);
  },
});
