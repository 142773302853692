/* global Backbone */
App.Views.Calendar.FloatingEventModeHeader = Backbone.View.extend({
  templateId: 'floating-event-header',

  events: {
    'click .js-cancel': 'cancel',
  },

  render() {
    this.$el.html(
      Wahanda.Template.renderTemplate(this.templateId, {
        text: this.options.text,
        isReschedule: this.options.reschedule,
        isRebook: this.options.rebook,
      }),
    );
  },

  cancel() {
    // Call cancel function passed in from appointment calendar view
    this.options.onCancel(true);
  },

  destroy() {
    this.remove();
    this.unbind();
  },
});
