import Wahanda from 'common/wahanda';
import { createSelector } from 'reselect';
import { emailValidator, phoneValidator } from 'common/validators';

export const getSendMassEmails = ({ sendMassEmails }) => sendMassEmails;

export const getId = ({ id }) => id;

export const getName = ({ name }) => name;

export const getEmail = ({ emailAddress }) => emailAddress;

export const getPhone = ({ phone }) => phone;

export const getWalkIn = ({ walkIn }) => walkIn;

export const getAnonymousNote = ({ anonymousNote }) => anonymousNote;

export const getRecipientName = ({ recipientName }) => recipientName;

export const canChangeDetails = ({ canViewContactDetails, canEditClient }) =>
  canViewContactDetails && canEditClient;

export const isBookedByAnotherClient = createSelector(
  [getName, getRecipientName],
  (name, recipientName) => !!(recipientName && name !== recipientName),
);

export const showMessageSelector = createSelector(
  [getId, getWalkIn, getAnonymousNote, canChangeDetails],
  (id, walkIn, anonymousNote, changeDetails) =>
    !!(((id || walkIn) && changeDetails) || anonymousNote),
);

export const suggestionMessageSelector = createSelector(
  [showMessageSelector, getName, getEmail, getPhone],
  (showMessage, name, emailAddress, phone) => {
    if (!showMessage) {
      return null;
    }

    const lang = Wahanda.lang.inlineClientEditing.messages;
    const emailEmptyOrNotValid =
      !emailAddress || emailValidator.VALIDATOR({}, emailAddress) !== true;
    const phoneEmptyOrNotValid = !phone || phoneValidator.VALIDATOR({}, phone) !== true;

    if (emailEmptyOrNotValid && phoneEmptyOrNotValid) {
      return lang.noData;
    }

    if (name && emailAddress && phoneEmptyOrNotValid) {
      return lang.noPhone;
    }

    if (name && phone && emailEmptyOrNotValid) {
      return lang.noEmail;
    }

    return null;
  },
);

export const isReadonly = createSelector(
  getId,
  getName,
  getEmail,
  getPhone,
  (id, name, email, phone) => !!(id && name && (email || phone)),
);

export const showGDPRMessageSelector = createSelector(
  [getEmail, getPhone],
  (email, phone) =>
    (!!email && emailValidator.VALIDATOR({}, email) === true) ||
    (!!phone && phoneValidator.VALIDATOR({}, phone) === true),
);

export const getClientNameSelector = createSelector(
  [getName, getAnonymousNote],
  (name, anonymousNote) => name || anonymousNote,
);
