// Initializer to load Reports E6 modules and start the React part of the app
import React from 'react';
import ReactDOM from 'react-dom';
import { assoc, curry, get } from 'mori';
import { appState } from 'state';
// Resources component
import ResourcesList from '../components/settings/resources/ResourcesList';

export function injectResourcesList(targetEl, venueId) {
  // side-effect, ouch!
  appState.swap(curry(assoc, 'venueId', venueId));
  ReactDOM.render(<ResourcesList key={venueId} />, targetEl);
}

export function venueChangeInitiated(targetEl) {
  const venueId = get(appState.deref(), 'venueId');
  ReactDOM.render(<ResourcesList key={venueId} loading />, targetEl);
}
