import React from 'react';
import Wahanda from 'common/wahanda';
import classnames from 'classnames';

import { Tooltip, TooltipPlacement } from 'components/common/Tooltip';
import { PersonDetails, CardAlert, Close, Edit } from 'components/common/Icon';
import { Button } from 'components/common/Button';
import FieldReadOnly, { TYPES } from '../FieldReadOnly';

import style from './ReadOnlyClientInfo.scss';

import { InlineClientAnalytics } from '../tracking';

const APPT_LANG = Wahanda.lang.calendar.appointments.multi;
const CUSTOMER_LANG = APPT_LANG.customer;
const LANG = Wahanda.lang.inlineClientEditing;

export interface Props {
  onEditTrigger?: (param) => void;
  isRepeat?: boolean | null;
  showClientInfo: (param) => void;
  // redux
  actions: {
    resetClient: () => void;
  };
  canEditClient: boolean;
  canRemoveClient: boolean;
  canViewContactDetails: boolean;
  emailAddress?: string;
  id?: number | null;
  isBookedBy: boolean;
  name: string;
  notes?: string | null;
  phone?: string;
  prepaymentRequired?: boolean;
  recipientName?: string | null;
}

export class ReadOnlyClientInfo extends React.Component<Props> {
  public static defaultProps = {
    id: null,
    phone: '',
    emailAddress: '',
    notes: '',
    recipientName: null,
    isRepeat: null,
    prepaymentRequired: false,
  };

  private onEdit = (field?: string) => {
    const fieldToFocus = field || '';
    InlineClientAnalytics.userClickedEditClient();
    if (this.props.onEditTrigger) {
      this.props.onEditTrigger({ fieldToFocus });
    }
  };

  private clientNameClick = () => {
    InlineClientAnalytics.userClickedClientInfo('name');
    this.showClientInfo();
  };

  private clientDetailsClick = () => {
    InlineClientAnalytics.userClickedClientInfo('button');
    this.showClientInfo();
  };

  private showClientInfo = () => {
    const { showClientInfo, id } = this.props;
    showClientInfo(id);
  };

  private editPhone = () => {
    InlineClientAnalytics.userClickedAddPhone();
    this.onEdit('phone');
  };

  private editEmail = () => {
    InlineClientAnalytics.userClickedAddEmail();
    this.onEdit('email');
  };

  private removeClient = () => {
    InlineClientAnalytics.userClickedRemoveClient();
    const { resetClient } = this.props.actions;
    resetClient();
  };

  private renderCustomerName() {
    const { name, isBookedBy, recipientName, prepaymentRequired } = this.props;
    const customerName = isBookedBy ? recipientName : name;
    const classes = classnames(style.nameField, style.noStyleButton, {
      [style.withRecipient]: isBookedBy,
    });
    return (
      <button type="button" className={classes} onClick={this.clientNameClick}>
        <div className={style.name}>
          {prepaymentRequired && (
            <Tooltip
              tooltip={Wahanda.lang.consumer.dialog.prepayEnabled}
              placement={TooltipPlacement.top}
            >
              <CardAlert className={style.cardIcon} />
            </Tooltip>
          )}
          {customerName}
        </div>
        {isBookedBy && <div className={style.bookedBy}>{`${CUSTOMER_LANG.bookedBy} ${name}`}</div>}
      </button>
    );
  }

  private renderCustomerStatus = () => {
    const { isRepeat } = this.props;

    if (isRepeat == null) {
      return null;
    }

    const text = isRepeat ? APPT_LANG.acquisition.repeating : APPT_LANG.acquisition.firstTime;

    return <div className={style.customerStatus}>{text}</div>;
  };

  public render() {
    const {
      phone,
      emailAddress,
      notes,
      canRemoveClient,
      canViewContactDetails,
      canEditClient,
    } = this.props;

    const editPhone = canEditClient ? this.editPhone : this.showClientInfo;
    const editEmail = canEditClient ? this.editEmail : this.showClientInfo;

    return [
      <div data-hj-suppress className={style.clientInfoReadOnly} key="info">
        {this.renderCustomerStatus()}
        <div className={style.clientInfo}>
          {this.renderCustomerName()}
          {canViewContactDetails && (
            <button
              type="button"
              className={classnames(style.noStyleButton, style.phone)}
              onClick={editPhone}
            >
              <FieldReadOnly
                additionalClasses={style.infoField}
                placeHolderText={LANG.placeHolders.phone}
                text={phone}
                type={TYPES.PHONE}
              />
            </button>
          )}
          {canViewContactDetails && (
            <button
              type="button"
              className={classnames(style.noStyleButton, style.email)}
              onClick={editEmail}
            >
              <FieldReadOnly
                additionalClasses={style.infoField}
                placeHolderText={LANG.placeHolders.email}
                text={emailAddress}
                type={TYPES.EMAIL}
              />
            </button>
          )}
          {notes && (
            <FieldReadOnly
              additionalClasses={classnames(style.infoField, style.notesField)}
              text={notes}
              type={TYPES.NOTES}
            />
          )}
        </div>
        <div className={style.buttons}>
          {canViewContactDetails && (
            <div className={style.button}>
              <Tooltip tooltip={LANG.messages.hoverClientDetails} placement={TooltipPlacement.top}>
                <Button
                  colour="plain"
                  size="small"
                  icon={<PersonDetails size="small" />}
                  onClick={this.clientDetailsClick}
                />
              </Tooltip>
            </div>
          )}
          {canViewContactDetails && canEditClient && (
            <div className={style.button}>
              <Tooltip tooltip={LANG.messages.hoverEditClient} placement={TooltipPlacement.top}>
                <Button
                  size="small"
                  colour="plain"
                  icon={<Edit size="small" />}
                  onClick={this.onEdit}
                />
              </Tooltip>
            </div>
          )}
          {canRemoveClient && (
            <div className={style.button}>
              <Button
                size="small"
                colour="plain"
                onClick={this.removeClient}
                icon={<Close size="tiny" />}
              />
            </div>
          )}
        </div>
      </div>,
    ];
  }
}
