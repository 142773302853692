import {
  GET_WEBSOCKETS_CONNECT_REQUEST,
  GET_WEBSOCKETS_CONNECTED,
  GET_WEBSOCKETS_MESSAGE_RECEIVED,
} from 'reduxStore/actionsConst';

export const requestWebSocketsConnectAction = (data) => ({
  type: GET_WEBSOCKETS_CONNECT_REQUEST,
  data,
});

export const receiveWebSocketsConnectedAction = (data) => ({
  type: GET_WEBSOCKETS_CONNECTED,
  data,
});

export const receiveWebSocketsMessageAction = (data) => ({
  type: GET_WEBSOCKETS_MESSAGE_RECEIVED,
  data,
});
