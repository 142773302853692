/**
 * Possible options:
 *
 * > [all View pricing values, e.g. ffullPriceAmount, discountPriceAmount, ...]
 * > saveCallback (viewValues)
 * > deleteCallback
 * > showDelete - Should the delete button be shown?
 */
App.Views.Forms.MenuOffer2.Pricing.Dated.EditDialog = BackboneEx.View.Dialog.ValidatingForm.PricingBase.extend(
  {
    events: {
      'click .a-delete': 'remove',
      'click .button-cancel': 'close',
    },
    options: {
      width: 600,
      title: Wahanda.lang.menu.offer.pricing.editDialog.title,
    },

    templateId: 'menu-offer-pricing-edit-dialog',

    postInitialize: function () {},

    elementToFocus: function () {
      // Focuses the firs date field. In edit mode, the field does not exist. So it focuses nothing.
      this.$('.datepicker:visible:first').focus();
    },

    initForm: function () {
      var self = this;
      this.editView = new App.Views.Forms.MenuOffer2.Pricing.Dated.Edit(
        _.extend(
          {
            el: this.$('.b-container'),
            canSaveToggleCallback: function (canSave) {
              self.toggleSaveButton(canSave);
            },
          },
          this.options,
        ),
      );
      this.editView.render();

      this.$('.a-delete').toggle(this.options.showDelete);
    },

    toggleSaveButton: function (show) {
      this.$('.a-save').toggleFormElements(show);
    },

    remove: function () {
      var self = this;
      var removeFunc = function () {
        self.options.deleteCallback();
        self.resetCloseChangesCheck();
        self.close();
      };

      Wahanda.Dialogs.Confirm(Wahanda.lang.menu.offer.pricing.removeQuestion, removeFunc);
    },
  },
);
