import React from 'react';
import classnames from 'classnames';
import { Chevron } from 'components/common/Icon';

import legacyStyle from './Expandable.scss';
import newStyle from './Expandable.newLook.scss';
import { Checkbox } from '../Checkbox';

export interface ExpandableProps {
  defaultExpanded: boolean;
  title: React.ReactNode;
  maxHeight: number;
  children: React.ReactNode;
  onClick?: () => void;
  newLook?: boolean;
  hasCheckbox?: boolean;
  checkboxOnChange?: (checked?: boolean, event?: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxName?: string;
  checkboxDataTest?: string;
  checkboxIsChecked?: boolean;
  checkboxIsSemiChecked?: boolean;
}

interface State {
  expanded: boolean;
  animationInProgress: boolean;
}

export class Expandable extends React.Component<ExpandableProps, State> {
  constructor(props: ExpandableProps) {
    super(props);

    const { defaultExpanded } = props;

    this.state = {
      expanded: defaultExpanded,
      animationInProgress: false,
    };
  }

  public static defaultProps = {
    defaultExpanded: false,
    maxHeight: 600,
  };

  public componentDidMount() {
    const { defaultExpanded } = this.props;

    if (defaultExpanded) {
      this.recomputeExpandableHeight();
      this.showScrollbar();
    }

    // This here allows us to add animation property after the initial paint has happened,
    // giving us the ability to set initial component height without re-animating it
    window.requestAnimationFrame(() => {
      if (this.growContainer.current) {
        this.growContainer.current.style.transition = 'height .5s';
      }
    });
  }

  private growContainer: React.RefObject<HTMLDivElement> = React.createRef();

  private contentContainer: React.RefObject<HTMLDivElement> = React.createRef();

  private checkboxInput: React.RefObject<HTMLInputElement> = React.createRef();

  private onClick = (e) => {
    const { animationInProgress } = this.state;

    const isClickingCheckbox =
      this.props.hasCheckbox &&
      (e.nativeEvent.srcElement === this.checkboxInput.current ||
        e.nativeEvent.srcElement.parentElement.previousSibling === this.checkboxInput.current ||
        e.nativeEvent.srcElement.parentElement.parentElement.previousSibling ===
          this.checkboxInput.current);

    const shouldAvoidClosing =
      this.state.expanded &&
      (this.props.checkboxIsSemiChecked ||
        (!this.props.checkboxIsSemiChecked && !this.props.checkboxIsChecked)) &&
      isClickingCheckbox;

    if (animationInProgress || shouldAvoidClosing) {
      return;
    }

    this.setState((state) => ({
      expanded: !state.expanded,
      animationInProgress: true,
    }));

    this.hideScrollbar();
    this.recomputeExpandableHeight();

    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  private onCheckboxClick = (checked?: boolean, event?: React.ChangeEvent<HTMLInputElement>) => {
    this.props.checkboxOnChange?.(checked, event);
  };

  private hideScrollbar = () => {
    if (this.growContainer.current) {
      this.growContainer.current!.style.overflowY = 'hidden';
    }
  };

  private showScrollbar = () => {
    if (this.growContainer.current) {
      this.growContainer.current!.style.overflowY = 'auto';
    }
  };

  private onTransitionEnd = () => {
    this.setState({
      animationInProgress: false,
    });

    this.showScrollbar();
  };

  private recomputeExpandableHeight = () => {
    if (!this.growContainer.current || !this.contentContainer.current) {
      return;
    }

    const growContainerDOM = this.growContainer.current;
    const contentContainerDOM = this.contentContainer.current;

    if (growContainerDOM.clientHeight) {
      growContainerDOM.style.height = '0';
    } else {
      growContainerDOM.style.height = `${contentContainerDOM.clientHeight}px`;
    }
  };

  public render() {
    const { title, maxHeight, newLook } = this.props;
    const { expanded } = this.state;
    const style = newLook ? newStyle : legacyStyle;

    return (
      <div
        {...(expanded ? { 'data-test': 'is-expanded' } : {})}
        className={classnames(style.expandable, {
          [style.expanded]: expanded,
        })}
      >
        <div
          className={style.title}
          onClick={this.onClick}
          role="button"
          aria-expanded={expanded}
          tabIndex={0}
        >
          {this.props.hasCheckbox && (
            <Checkbox
              inputRef={this.checkboxInput}
              dataTest={this.props.checkboxDataTest}
              name={this.props.checkboxName || 'expandable-checkbox'}
              onChange={this.onCheckboxClick}
              checked={this.props.checkboxIsChecked}
              semiChecked={this.props.checkboxIsSemiChecked}
            />
          )}
          <div className={style.titleInner}>{title}</div>
          <Chevron className={style.chevron} size="small" />
        </div>
        <div
          style={{ maxHeight }}
          className={style.body}
          ref={this.growContainer}
          onTransitionEnd={this.onTransitionEnd}
          role="region"
        >
          <div className={style.bodyInner} ref={this.contentContainer}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
