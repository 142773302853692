import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../store/actions';
import { isBookedByAnotherClient } from '../store/selectors';
import { ReadOnlyClientInfo as Container } from './ReadOnlyClientInfo';

const mapStateToProps = ({ calendar: { inlineClientForm } }) => ({
  canEditClient: inlineClientForm.canEditClient,
  canRemoveClient: inlineClientForm.canRemoveClient,
  canViewContactDetails: inlineClientForm.canViewContactDetails,
  emailAddress: inlineClientForm.emailAddress,
  id: inlineClientForm.id,
  isBookedBy: isBookedByAnotherClient(inlineClientForm),
  name: inlineClientForm.name,
  notes: inlineClientForm.notes,
  phone: inlineClientForm.phone,
  prepaymentRequired: inlineClientForm.prepaymentRequired,
  recipientName: inlineClientForm.recipientName,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    ...bindActionCreators(actions, dispatch),
    ...ownProps.actions,
  },
});

export const ReadOnlyClientInfo = connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(Container);
