import React from 'react';
import { get, set, assoc, conj, disj, curry, updateIn, last } from 'mori';
import classnames from 'classnames';

// Only the last dialog in the stack has it's own overlay
export function hasOwnOverlay(state, dialogId) {
  return last(get(state, 'react-dialog-overlays')) === dialogId;
}

export function pushDialogToOverlays(appState, dialogId) {
  appState.swap((state) => {
    const dialogs = get(state, 'react-dialog-overlays') || set();
    return assoc(state, 'react-dialog-overlays', conj(dialogs, dialogId));
  });
}

export function removeDialogFromOverlays(appState, dialogId) {
  appState.swap(curry(updateIn, ['react-dialog-overlays'], curry(disj, dialogId)));
}

interface IOverlayProps extends React.HTMLAttributes<Element> {
  zIndex: number;
  onClick: (...args: any[]) => any;
  hidden?: boolean;
}

export default class Overlay extends React.Component<IOverlayProps, {}> {
  render() {
    const { hidden, onClick, zIndex } = this.props;
    return (
      <div
        className={classnames('ui-widget-overlay', { hidden })}
        style={{ zIndex }}
        onClick={onClick}
      />
    );
  }
}
