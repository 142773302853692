import React from 'react';
import { StripeElement, Stripe } from '../types';

export interface InjectedProps {
  paymentInProgress: boolean;
  idealPaymentInProgress: boolean;
  stripe?: Stripe;
  handlePayment: any;
  doIdealPaymentRequest: any;
  error: boolean;
  success: boolean;
}

export const withStripe = (Component: any) => (props: any) => {
  const {
    actions,
    supplierId,
    stripe,
    error,
    success,
    paymentInProgress,
    idealPaymentInProgress,
    ...rest
  } = props;
  const handlePayment = ({
    cardholderName,
    cardElement,
    paymentAmount,
    saveCardInfo,
  }: {
    cardholderName: string;
    paymentAmount: number;
    cardElement: StripeElement;
    saveCardInfo: boolean;
  }) => {
    actions.doStripePaymentRequest({
      paymentAmount,
      cardholderName,
      supplierId,
      stripe,
      cardElement,
      saveCardInfo,
    });
  };
  const doIdealPaymentRequest = ({ paymentAmount }: { paymentAmount: string }) => {
    actions.doIdealPaymentRequest({
      paymentAmount,
      supplierId,
    });
  };
  return (
    <Component
      {...rest}
      idealPaymentInProgress={idealPaymentInProgress}
      paymentInProgress={paymentInProgress}
      stripe={stripe}
      handlePayment={handlePayment}
      doIdealPaymentRequest={doIdealPaymentRequest}
      error={error}
      success={success}
    />
  );
};
