App.Views.Settings.VenueVouchers = App.Views.Form.extend({
  events: {
    'click .save-action': 'save',
    'change input': 'toggleText',
  },

  fieldPrefix: /^venue\-vouchers\-/,

  initialize: function () {
    var self = this;
    this.on('visible', function () {
      self.$el.loadmask();
    });
    this.model.on('fetched', function () {
      self.render();
    });
  },

  render: function () {
    this.fillFormFromModel();
    this.toggleText();

    this.restrictEditingByPermissions();

    this.$el.unloadmask();
  },

  restrictEditingByPermissions: function () {
    var canEdit = Wahanda.Permissions.manageVenueDetails();
    this.$('.save-action').toggle(canEdit);
    this.$('input:checkbox').prop('disabled', !canEdit);
  },

  save: function () {
    var self = this;
    var $saveButton = this.$('button.save-action').action('doing');

    this.model.setAttributesToSave(['acceptsWahandaVoucher']);
    this.model.nextSaveRelations = [];
    this.model.set(this.getFormValues());

    this.disableForm();

    this.model.save(null, {
      success: function () {
        $saveButton.action();
        self.enableForm();
      },
      error: function () {
        self.enableForm();
        $saveButton.action().errorTip(Wahanda.lang.shared.savingFailed);
        self.model.revert();
      },
    });
  },

  toggleText: function () {
    this.$('.b-info-text').wToggle(this.$('input:checkbox').prop('checked'));
  },

  getFormValues: function () {
    return {
      acceptsWahandaVoucher: this.$('#venue-vouchers-acceptsWahandaVoucher').prop('checked'),
    };
  },

  _modelValuesHaventChanged: function () {
    return this.model.get('acceptsWahandaVoucher') === this.getFormValues().acceptsWahandaVoucher;
  },
});
