import {
  getPricingType,
  isSkuCombinationSupported,
  reduceSkusToCustomType,
  isPackageSkus2d,
} from 'components/menu/OfferTreatmentPricingAndEmployees/utils';
import {
  PRICING_TYPE_SIMPLE,
  PRICING_TYPE_BY_EMPLOYEE_CAT,
  PRICING_TYPE_CUSTOM,
  PRICING_TYPE_2D,
} from 'components/menu/OfferTreatmentPricingAndEmployees/constants';
import { SERVICE_TYPES } from 'services/service-menu';
import get from 'lodash/get';

/**
 * Menu offer.
 */
(function menuOffer() {
  const MenuOffer = BackboneEx.Model.Silent.extend(
    {
      TYPE_TREATMENT: SERVICE_TYPES.TREATMENT,
      TYPE_LEGACY_PACKAGE: SERVICE_TYPES.LEGACY_PACKAGE,
      TYPE_SERVICE_PACKAGE: SERVICE_TYPES.SERVICE_PACKAGE,
      TYPE_SPA_DAY: SERVICE_TYPES.SPA_DAY,
      TYPE_SPA_BREAK: SERVICE_TYPES.SPA_BREAK,

      DATED_TYPE_SINGLE: 'DY',
      DATED_TYPE_OVERNIGHT_FIXED: 'FX',
      DATED_TYPE_OVERNIGHT_VARIABLE: 'VR',

      CHANNEL_CUSTOMER: 'CUSTOMER',
      CHANNEL_SUPCUS: 'SUPPLIERS_CUSTOMER',
      CHANNEL_LOCAL: 'SUPPLIER',

      templateOffer:
        '<option value="{{#legacy}}offer:{{/legacy}}{{id}}" class="offer"' +
        '{{#skuId}} data-sku-id="{{skuId}}"{{/skuId}} ' +
        'data-duration="{{duration}}"' +
        '{{#cleanupTime}} data-cleanup-time="{{cleanupTime}}"{{/cleanupTime}}' +
        '>{{name}}</option>',
      templateSku:
        '<option value="{{#legacy}}sku:{{/legacy}}{{id}}" class="sku" data-duration="{{duration}}">{{#ident}}&raquo; {{/ident}}{{name}}</option>',

      defaults: {
        venueId: null,
      },

      // Set externally, by offer-type-view.js
      // If true, the spa day/break is treated as having SKU (not dated) pricing.
      allowSkuPricedEscapes: false,
      isFromTemplate: false,

      initialize: function initialize() {
        this.initializationDate = Wahanda.Date.createVenueDate();
      },

      attributesToSave: function attributesToSave() {
        const list = [
          'name',
          'groupId',
          'offerTypeCode',
          'menuItemTypeCode',
          'visible',
          'featured',
          'listed',
          'manageInventory',
          'fulfillmentTypes',
          'description',
          'restrictions',
          'goodToKnow',
          'employeeCategoryId',
          'images',
          'supplierReference',
          'visibleFrom',
          'visibleTo',
          'treatmentIds',
          'distributionChannels',
          'voucherValidityMonths',
          'useApplicableDays',
          'applicableDays',
          'leadTimeMinutes',
          'connectDisplayColour',
          'pending',
          'vatCategory',
          'discountRuleId',
        ];

        if (this.id) {
          list.push('id');
        }

        if (this.isTreatment()) {
          list.push('primaryTreatmentId', 'multiSkuSelection', 'processingTimeMins', 'cleanupTime');
        }

        if (this.isEscapeWithRangePricing()) {
          list.push('pricingDisplayTypeCode', 'checkinTime', 'checkoutTime', 'pricing');
        } else {
          list.push('employees');
        }

        if (this.hasSkuPricing() || this.isServicePackage() || this.isTreatment()) {
          list.push('skus');
        }

        if (this.isEscape()) {
          list.push('datedServiceTypeCode');
          list.push('occupancyGuests');
        }

        if (this.isServicePackage()) {
          list.push('subServices');
        }

        if (this.isSpaBreak()) {
          list.push('stayNights');
        }

        if (this.get('patchTestExists')) {
          list.push('requiresPatchTest');
        }

        return list;
      },

      _getSaveData: function _getSaveData(...params) {
        // eslint-disable-next-line no-underscore-dangle
        const data = this.constructor.__super__._getSaveData.apply(this, params);
        if (!data.treatmentIds) {
          data.treatmentIds = [];
        }
        return data;
      },

      url: function url() {
        const id = this.get('id');
        let base;
        if (id > 0) {
          base = '/venue/{{venueId}}/offer/{{id}}.json';
          base += `?dated-pricing-from=${Wahanda.Date.toApiString(this.initializationDate)}`;
          base += '&include=pricing';
        } else {
          base = '/venue/{{venueId}}/offer.json';
        }
        return App.Api.wsUrl(Wahanda.Template.render(base, { id, venueId: App.getVenueId() }));
      },

      // Overrides the default clone to lose references to original objects
      clone: function clone() {
        const dereferencedAttrs = JSON.parse(JSON.stringify(this.attributes));

        return new this.constructor(dereferencedAttrs);
      },

      createCopy: function createCopy(options) {
        const copy = this.clone();
        copy.set('id', null);
        copy.set('name', `${this.get('name')} ${Wahanda.lang.menu.offer.header.copySuffix}`);
        copy.set('visible', true);
        copy.set('enclosingPackages', []);
        /* eslint-disable no-param-reassign */
        _.each(copy.get('skus'), (sku) => {
          sku.tempId = sku.id;
          sku.id = null;
        });
        /* eslint-enable no-param-reassign */

        if (copy.get('featured')) {
          copy.set('featured', options.featuredItemsCollection.canMakeFeatured(copy));
        }
        if (copy.isSpaDay() || copy.isSpaBreak()) {
          // Remove pricingRuleId
          /* eslint-disable no-param-reassign */
          _.each(copy.get('pricing'), (pricingRule) => {
            pricingRule.pricingRuleId = null;
          });
          /* eslint-enable no-param-reassign */
        }

        return copy;
      },

      /**
       * Returns sku items as collection.
       */
      getSkuCollection: function getSkuCollection() {
        let collection = this.get('skuCollection');
        if (collection == null) {
          collection = new Backbone.Collection();
          /* eslint-disable no-param-reassign */
          _.each(this.get('skus'), (sku) => {
            collection.add(new BackboneEx.Model.Silent(sku));
          });
          /* eslint-enable no-param-reassign */
          this.set('skuCollection', collection);
        }
        return collection;
      },

      getMasterService: function getMasterService() {
        const subServices = this.get('subServices');
        return _.findWhere(subServices, { master: true });
      },

      isReadOnly: function isReadOnly() {
        return (
          (this.isReadOnlyLight() || this.get('locked') || !Wahanda.Permissions.editMenu()) &&
          !App.isMasquerading()
        );
      },

      isReadOnlyLight: function isReadOnlyLight() {
        return (false || !Wahanda.Permissions.lockedDownEditMenu()) && !App.isMasquerading();
      },

      isEditable: function isEditable() {
        // Type: Physical voucher. Not editable.
        return !this.isFulfilledAs('physicalVoucher');
      },

      isInventoryReadOnly: function isInventoryReadOnly() {
        // NOTE - for chain offers inventory can not be controlled due to data model limitations.
        return this.get('locked') || false;
      },

      isSkusReadOnly: function isSkusReadOnly() {
        // NOTE - for chain offers inventory can notbe controlled due to data model limitations.
        return false;
      },

      isFeaturedReadOnly: function isFeaturedReadOnly() {
        return this.get('locked');
      },

      canShowFulfillmentType: function canShowFulfillmentType() {
        return (
          this.get('visible') && this.get('purchasable') && this.getFulfillmentString() !== 'A'
        );
      },

      isLegacyPackage: function isLegacyPackage() {
        return this.TYPE_LEGACY_PACKAGE === this.get('menuItemTypeCode');
      },

      isServicePackage: function isServicePackage() {
        return this.TYPE_SERVICE_PACKAGE === this.get('menuItemTypeCode');
      },

      isCustom: function isCustom() {
        return this.get('primaryTreatmentId') === undefined;
      },

      isTreatment: function isTreatment() {
        return this.get('menuItemTypeCode') === 'T';
      },

      isSpaDay: function isSpaDay() {
        return this.get('menuItemTypeCode') === 'Y';
      },

      isSpaBreak: function isSpaBreak() {
        return this.get('menuItemTypeCode') === 'O';
      },

      isLocked: function isLocked() {
        return this.get('locked');
      },

      isFromChain: function isFromChain() {
        return false;
      },

      isArchived: function isArchived() {
        return !this.get('visible');
      },

      togglePatchTestRequired: function togglePatchTestRequired() {
        const currentValue = this.get('requiresPatchTest');
        this.set('requiresPatchTest', !currentValue);
      },

      setPatchTestExists: function setPatchTestExists(patchTestExists) {
        this.set('patchTestExists', patchTestExists);
      },

      hasAllSkus: function hasAllSkus(skuIdList) {
        return _.all(skuIdList, (skuId) => !!this.getSku(skuId));
      },

      usesProcessingTime: function usesProcessingTime() {
        const skus = this.getSkuCollection();

        const hasProcessingTime =
          Number.isInteger(this.get('processingTimeMins')) && this.get('processingTimeMins') > 0;

        const hasFinishingTime = skus.some((s) => {
          let finishingTime;
          // In case of copying a service, the sku collection is actually
          // an array of POJOs. In other cases - it is a backbone collection
          if (s instanceof Backbone.Model) {
            finishingTime = s.get('finishingTimeMins');
          } else {
            finishingTime = get(s, 'finishingTimeMins');
          }

          return finishingTime && finishingTime > 0;
        });

        return hasProcessingTime || hasFinishingTime;
      },

      isPending: function isPending() {
        return this.get('pending');
      },

      parse: function parse(params) {
        const data = params;
        // Deleting the SKU collection
        this.unset('skuCollection');

        if (data) {
          if (data.menuItemTypeCode !== 'T' && typeof data.treatmentIds === 'undefined') {
            data.treatmentIds = [];
          }
        }

        return data;
      },

      validPackage: function validPackage() {
        if (
          this.submitted &&
          (!this.get('subServices') ||
            this.get('subServices').length === 0 ||
            (!this.hasVisibleSku() && this.isOpenSelectable()))
        ) {
          return false;
        }
        return true;
      },

      is2dPricedServicePackage: function is2dPricedServicePackage() {
        if (!this.isServicePackage()) {
          return false;
        }
        return MenuOffer.isPackageSkus2d(this.get('skus'));
      },

      hasFreeSku: function () {
        const skus = this.get('skus');
        if (this.isServicePackage()) {
          const hasFreeSku = _.any(skus, (sku) => {
            if (!sku.visible) {
              return false;
            }

            if (this.isPackagePricingCustom()) {
              return sku.fullPriceAmount === 0;
            }

            return sku.calculatedPriceAmount === 0;
          });

          return hasFreeSku;
        }

        const isFreePrice = _.some(
          this.get('skus'),
          (sku) =>
            sku.fullPriceAmount === 0 ||
            (sku.fullPriceAmount !== 0 && sku.discountPriceAmount === 0),
        );

        return isFreePrice;
      },

      /**
       * Returns an object representing purchasability of this offer.
       *
       * @param Object options Possible parameters:
       *  > employees The employee collection.
       *
       * @return Object
       */
      getPurchasabilityData: function getPurchasabilityData(options) {
        const widgetCheck =
          App.config.get('venue').useOnlineBookingWidget && this.hasDistributionChannel('SUPCUS');
        const employeeCheck = this.isFulfilledByAppointment() && !this.isServicePackage();
        const isAssignedToPackage =
          this.get('enclosingPackages') && this.get('enclosingPackages').length > 0;
        this.submitted = true;

        let data = {
          sitePurchasable: true,
          siteSkusPurchasable: true,
          widgetPurchasable: true,
          widgetSkusPurchasable: true,
          hasErrors: false,
          errors: {
            treatwell: [],
            widget: [],
            all: [],
            pricing: [],
          },
        };
        if (this.isServicePackage()) {
          if (!this.validPackage()) {
            data.hasErrors = true;
            data.errorMessage = Wahanda.lang.menu.offer.errors.packageNoSkus;
          }
          const skus = this.get('skus');
          const hasFreeSku = _.any(skus, (sku) => {
            if (!sku.visible) {
              return false;
            }
            return (sku.calculatedPriceAmount || sku.fullPriceAmount || 0) === 0;
          });

          if (hasFreeSku && !App.isMasquerading()) {
            // We don't allow selling Offers which have free SKUs in the Marketplace
            data = this.removeMarketplaceDistribution(data);
            data.hasErrors = data.errors.treatwell.length > 0 || data.errors.widget.length > 0;
          }
        } else if (this.get('visible') && this.get('listed') && !this.isEscapeWithRangePricing()) {
          let hasValidSku = false;
          let allSkusValid = true;
          let widgetAllDurationsSet = true;
          let widgetSomeDurationsSet = false;

          const employeesToCheck = this.get('employees') || [];

          const employeesValid = employeesToCheck.length > 0;

          const widgetEmployeesValid = !widgetCheck || employeesValid;
          let widgetFulfillmentValid = true;

          const fulfilledAsEvoucherOnly = this.isFulfilledOnlyAs('evoucher');
          const isVisible = this.get('visible');
          let isFreePrice = false;
          _.each(this.get('skus'), (sku) => {
            const isSimplePrice = sku.fullPriceAmount >= 0;
            isFreePrice =
              isFreePrice ||
              sku.fullPriceAmount === 0 ||
              (sku.fullPriceAmount !== 0 && sku.discountPriceAmount === 0);

            if (fulfilledAsEvoucherOnly) {
              hasValidSku = hasValidSku || isSimplePrice;
              allSkusValid = allSkusValid && isSimplePrice;
            } else {
              const isValid = isSimplePrice && sku.duration !== null;
              hasValidSku = hasValidSku || isValid;
              allSkusValid = allSkusValid && isValid;
            }
            if (widgetCheck) {
              if (sku.duration == null) {
                widgetAllDurationsSet = false;
              } else {
                widgetSomeDurationsSet = true;
              }
            }
          });

          if (isAssignedToPackage) {
            const self = this;

            const skuFilter = (sku) => sku.id > 0;
            const prevSkus = _.filter(self.previous('skus'), skuFilter);
            const newSkus = _.filter(self.get('skus'), skuFilter);

            const isEqualField = (priceField) => {
              return _.isEqual(_.pluck(prevSkus, priceField), _.pluck(newSkus, priceField));
            };

            const priceChanges = !(
              isEqualField('fullPriceAmount') && isEqualField('discountPriceAmount')
            );

            const durationChanges =
              !isEqualField('duration') ||
              !isEqualField('finishingTimeMins') ||
              self.previous('processingTimeMins') !== self.get('processingTimeMins') ||
              self.previous('cleanupTime') !== self.get('cleanupTime');

            if (priceChanges || durationChanges || this.hasChanged('name')) {
              data.hasErrors = true;
              data.packageWarning = true;
            }
          }

          data.sitePurchasable = hasValidSku && isVisible;
          data.skusPurchasable = allSkusValid;
          data.widgetPurchasable = true;
          data.widgetSkusPurchasable = true;

          if (widgetCheck) {
            const distChannels = this.get('distributionChannels');
            if (distChannels && !this.isEscape() && _.indexOf(distChannels, 'SUPCUS') !== -1) {
              widgetFulfillmentValid = !fulfilledAsEvoucherOnly;
            }

            data.widgetPurchasable =
              widgetSomeDurationsSet && widgetEmployeesValid && widgetFulfillmentValid;
            data.widgetSkusPurchasable = widgetAllDurationsSet;
          }

          if (this.isFulfilledByAppointment() && !employeesValid) {
            data.sitePurchasable = false;
            data.noEmployeeAssigned = true;
          }

          // Treatwell (site) errors
          if (!data.purchasable) {
            if (!hasValidSku) {
              data.errors.treatwell.push(
                `allSkusNotValidIn${fulfilledAsEvoucherOnly ? 'Evoucher' : 'Appointment'}`,
              );
            }
            if (!isVisible) {
              data.errors.treatwell.push('notVisible');
            }
          } else if (!data.skusPurchasable) {
            data.errors.treatwell.push(
              `someSkusNotValidIn${fulfilledAsEvoucherOnly ? 'Evoucher' : 'Appointment'}`,
            );
          }

          // Booking widget errors
          if (!data.widgetPurchasable) {
            if (!widgetAllDurationsSet) {
              data.errors.widget.push('noSkuDurationsSet');
            }
            if (!widgetEmployeesValid) {
              data.noEmployeeAssigned = true;
            }
            if (!widgetFulfillmentValid) {
              data.errors.widget.push('onlyEvoucherFulfillment');
            }
          } else if (!data.widgetSkusPurchasable) {
            data.errors.widget.push('notAllSkuDurationsSet');
          }

          if (isFreePrice && !App.isMasquerading()) {
            data = this.removeMarketplaceDistribution(data);
          }

          data.hasErrors =
            data.hasErrors ||
            data.noEmployeeAssigned ||
            data.errors.treatwell.length > 0 ||
            data.errors.widget.length > 0;

          if (!data.hasErrors && employeeCheck) {
            // Check for empty employee categories only when no other errors exist
            data = this.checkSkuEmployeeCategories(data, options);
          }
        } else if (this.isSpaDay() && this.hasDatedPricing() && this.isFulfilledAs('evoucher')) {
          // If is eVoucher fulfilled and eVoucher validity exceeds set pricing - notify the supplier
          const pricingUntil = this.getPricingDateDefinedUntil();
          if (pricingUntil) {
            const voucherValidityMonths =
              this.get('voucherValidityMonths') || App.config.get('venue').voucherValidityMonths;
            const now = new Date();
            const voucherValidUntil = Wahanda.Date.createVenueDate(
              now.getFullYear(),
              now.getMonth() + voucherValidityMonths,
              now.getDate(),
            );
            if (Wahanda.Date.getDayDifference(pricingUntil, voucherValidUntil) < 0) {
              data.hasErrors = true;
              data.errors.pricing.push({
                errorId: 'noPricingForAllEvoucherValidity',
                vars: {
                  validityMonths: voucherValidityMonths,
                  pricingDate: Wahanda.Date.formatToDefaultDate(pricingUntil),
                },
              });
            }
          }
        }

        return data;
      },

      getTreatmentIds: function getTreatmentIds(menuOffersCollection) {
        if (!this.isServicePackage()) {
          return this.get('treatmentIds');
        }
        let treatmentIds = [];
        // Return array of subServices
        const subServices = this.get('subServices');
        subServices.forEach(function f(subService) {
          const service = menuOffersCollection.get(subService.serviceId);
          if (service) {
            treatmentIds = treatmentIds.concat(service.get('treatmentIds'));
          }
        });

        return treatmentIds;
      },

      // remove the marketplace distribution channel
      removeMarketplaceDistribution: function removeMarketplaceDistribution(data) {
        //if current service is a patch test service, we must allow to sell it on marketplace even if it has skus with zero price
        if (this.get('patchTestService')) {
          return data;
        }

        if (_.contains(this.get('distributionChannels'), this.CHANNEL_CUSTOMER)) {
          // if the price is free we remove the option to have site
          this.set(
            'distributionChannels',
            _.without(this.get('distributionChannels'), this.CHANNEL_CUSTOMER),
          );
          data.errors.treatwell.push('isNotMarketplaceSafe');
        }
        return data;
      },
      /**
       * Return the latest date this offer has range pricing for.
       * If no range pricing exists, or there is at least one "from..." rule, NULL will be returned.
       *
       * @return Date or null
       */
      getPricingDateDefinedUntil: function getPricingDateDefinedUntil() {
        let date = null;
        let d;
        const data = this.get('pricing') || [];
        _.each(data, (item) => {
          if (item.pricingType === 'F') {
            // There is "from" pricing. This means that the end date does not exist.
            return;
          }
          d = Wahanda.Date.createDate(item.dateTo);
          if (!date || date.getTime() < d.getTime()) {
            date = d;
          }
        });
        return date;
      },

      /**
       * Checks if at least one sku contains category with no employees assigned.
       *
       * Updates the data object if error is found.
       */
      checkSkuEmployeeCategories: function checkSkuEmployeeCategories(params, options) {
        const data = params;
        const checkedCategories = {};
        let valid = true;
        _.all(this.get('skus'), (sku) => {
          const catId = sku.employeeCategoryId;
          if (!catId || checkedCategories[catId]) {
            return true;
          }
          checkedCategories[catId] = true;

          valid = options.employees.where({ employeeCategoryId: catId }).length > 0;
          return valid;
        });

        if (!valid) {
          data.purchasable = false;
          data.hasErrors = true;
          data.noEmployeeAssigned = true;
        }

        return data;
      },

      /**
       * Can this offer be fulfilled by appointment?
       *
       * @return boolean
       */
      isFulfilledByAppointment: function isFulfilledByAppointment() {
        return this.isFulfilledAs('appointment');
      },

      isFulfilledAs: function isFulfilledAs(type) {
        const types = this.get('fulfillmentTypes') || {};
        return types[type] === true;
      },

      isFulfilledOnlyAs: function isFulfilledOnlyAs(type) {
        return MenuOffer.isFulfilledOnlyAs(this.get('fulfillmentTypes'), type);
      },

      getDisplayName: function getDisplayName() {
        let name = this.get('name');
        const skus = this.get('skus');

        if (skus && skus.length === 1 && skus[0].duration > 0) {
          const duration =
            skus[0].duration +
            this.get('cleanupTime') +
            this.get('processingTimeMins') +
            skus[0].finishingTimeMins;
          name += ` (${Wahanda.Time.getDurationFull(duration)})`;
        }

        return name;
      },

      /**
       * Converts itself to SELECT element's OPTION(s).
       *
       * @return String HTML
       */
      toSelectOptions: function toSelectOptions() {
        const offerTemplate = this.templateOffer;

        let html = '';
        const skus = this.get('skus');
        let skuId = null;
        let duration = 60;
        let offerName = this.get('name');
        if (skus && skus.length > 0) {
          if (skus.length === 1) {
            skuId = skus[0].id;
            duration = skus[0].duration;
            if (duration) {
              offerName += ` (${Wahanda.Time.getDurationFull(duration)})`;
            } else {
              duration = 60;
            }
          }
        }

        html += Wahanda.Template.render(offerTemplate, {
          id: this.id,
          name: offerName,
          skuId,
          duration,
          cleanupTime: this.get('cleanupTime'),
        });

        return html;
      },

      getSkuList: function getSkuList(baseConfig, offersCollection, nameOnly) {
        // config.includeSingleSku - true if render even if there is only a single sku.
        const skus = this.get('skus');
        const list = [];

        const config = $.extend({ requiredSkus: [] }, baseConfig);

        const requiredSkus = config.requiredSkus;

        // do not render single sku for single sku offers. It is already included in service row.
        if (skus.length === 1 && !config.includeSingleSku) {
          return list;
        }

        const openSelectionServices = this.getOpenSelectionSubServices(offersCollection);

        function isSubSkuFromOpenSelectableService(subSku) {
          if (openSelectionServices.length === 0) {
            return false;
          }
          return _.any(openSelectionServices, (service) => subSku.offerId === service.serviceId);
        }

        function getSkuName(sku) {
          let name;
          if (sku.name) {
            name = sku.name;
          } else if (sku.subSkus && sku.subSkus.length > 0) {
            const skuList = [];
            _.each(sku.subSkus, (subSku) => {
              if (isSubSkuFromOpenSelectableService(subSku)) {
                skuList.push(subSku.name);
              }
            });
            name = skuList.join(' + ');
          } else {
            name = '';
          }
          return name;
        }

        function getSkuDuration(sku) {
          if (sku.duration > 0) {
            return sku.duration;
          }
          return _.reduce(sku.subSkus, (memo, subSku) => memo + (subSku.duration || 0), 0);
        }

        function getEmployeeCatId(sku) {
          if (sku.employeeCategoryId > 0) {
            return sku.employeeCategoryId;
          }
          if (sku.subSkus) {
            const subSku = sku.subSkus.find((ss) => ss.employeeCategoryId > 0);

            if (subSku) {
              return subSku.employeeCategoryId;
            }
          }
          return null;
        }

        function canAddSku(sku) {
          return sku.visible || _.indexOf(requiredSkus, sku.id) !== -1;
        }

        for (let i = 0, len = skus.length; i < len; i++) {
          let name = getSkuName(skus[i]);
          const skuDuration = getSkuDuration(skus[i]);
          if (skuDuration && !nameOnly) {
            if (name === '') {
              name = Wahanda.Time.getDurationFull(skuDuration);
            } else {
              name += ` (${Wahanda.Time.getDurationFull(skuDuration)})`;
            }
          }

          // Only render skus that are visible or required (are in the Appt being rendered, for example)
          if (canAddSku(skus[i])) {
            list.push({
              value: skus[i].id,
              name,
              duration: skuDuration || 60,
              employeeCategoryId: getEmployeeCatId(skus[i]),
            });
          }
        }

        return list;
      },

      getFulfillmentName: function getFulfillmentName() {
        const type = this.getFulfillmentString();
        const name = Wahanda.lang.menu.offer.fulfillment.typeNames[type];
        return name || '';
      },

      /**
       * Is this model restocked?
       *
       * A model is restocked, if it previously didn't have an outof-stock SKU, but now has, and vice-versa.
       *
       * @return boolean
       */
      isRestocked: function isRestocked() {
        const hasEmptyStock = function hasEmptyStock(skuList) {
          return _.any(skuList, (sku) => sku.stockAmount === 0);
        };

        return hasEmptyStock(this.previous('skus') || []) !== hasEmptyStock(this.get('skus') || []);
      },
      /**
       * Converts API types to icon names, e.g. T -> treatment.
       *
       * @return string
       */
      getIconByType: function getIconByType() {
        let icon = 'unknown';

        switch (this.get('menuItemTypeCode')) {
          case this.TYPE_TREATMENT:
            icon = 'treatment';
            break;

          case this.TYPE_LEGACY_PACKAGE:
          case this.TYPE_SERVICE_PACKAGE:
            icon = 'package';
            break;

          case this.TYPE_SPA_DAY:
            icon = 'spa-day';
            break;

          case this.TYPE_SPA_BREAK:
            icon = 'spa-break';
            break;
          default:
        }
        return icon;
      },

      isEscape: function isEscape() {
        // This applies for both plain Spa Day and Spa Break
        // and eVoucher Spa Day/Break
        const type = this.get('menuItemTypeCode');
        return type === 'Y' || type === 'O';
      },

      isEscapeWithRangePricing: function isEscapeWithRangePricing() {
        // Allow for existing services, and new services.
        // For new services, assumption is that pricing rules are used.
        // This applies for Spa Day and Spa Break
        return (
          this.isEscape() &&
          !this.allowSkuPricedEscapes &&
          (this.hasDatedPricing() || !this.hasSkuPricing())
        );
      },

      isEscapeWithSkuPricing: function isEscapeWithSkuPricing() {
        return this.isEscape() && (this.allowSkuPricedEscapes || this.hasSkuPricing());
      },

      getVisibilityInfo: function getVisibilityInfo() {
        return MenuOffer.getVisibilityInfo(this.get('visibleFrom'), this.get('visibleTo'));
      },

      getStringDate: function getStringDate(field) {
        const date = this.get(field);
        if (date == null) {
          return '';
        }
        try {
          return Wahanda.Date.formatToDefaultDate(Wahanda.Date.createDate(date));
        } catch (e) {
          return '';
        }
      },

      getVisibleInMenuCode: function getVisibleInMenuCode() {
        const from = !!this.get('visibleFrom');
        const to = !!this.get('visibleTo');

        switch (true) {
          case !from && !to:
            // Always show
            return 'AL';
          case from && to:
            // Date range
            return 'RG';
          case from && !to:
            // From to infinity
            return 'FR';
          case !from && to:
            // Until a date
            return 'ED';
          default:
        }

        return null;
      },

      getIconClass: function getIconClass() {
        if (this.isLegacyPackage()) {
          return 'icons-package-big';
        }
        if (this.isSpaDay()) {
          return 'icons-spa-day-big';
        }
        if (this.isSpaBreak()) {
          return 'icons-spa-break-big';
        }
        return 'icons-treatment-big';
      },

      getCamelCaseTypeName: function getCamelCaseTypeName() {
        switch (this.get('menuItemTypeCode')) {
          case this.TYPE_LEGACY_PACKAGE:
          case this.TYPE_SERVICE_PACKAGE:
            return 'package';

          case 'Y':
            return 'spaDay';

          case 'O':
            return 'spaBreak';
          default:
        }
        return 'treatment';
      },

      /**
       * Returns split by date pricing information.
       * Can be used on dated models only.
       *
       * @return Array
       */
      getSplitPricings: function getSplitPricings() {
        const items = this.get('pricing') || [];
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const list = new PricingList({ groupRooms: this.isSpaBreak() });
        for (let i = 0, len = items.length; i < len; i++) {
          list.add(items[i]);
        }
        return list.toArray();
      },

      /**
       * Returns TRUE if this DATED offer has pricing defined.
       *
       * @return boolean
       */
      hasDatedPricing: function hasDatedPricing() {
        return (this.get('pricing') || []).length > 0;
      },

      hasPricingForMultipleRanges: function hasPricingForMultipleRanges() {
        let lastDate;
        return _.any(this.get('pricing'), (item) => {
          if (!lastDate) {
            lastDate = item.dateFrom;
            return false;
          }
          if (lastDate !== item.dateFrom) {
            // The dates differ. So multiple ranges.
            return true;
          }
          return false;
        });
      },

      hasPricingForDateRange: function hasPricingForDateRange() {
        return _.any(this.get('pricing'), (item) => item.pricingType === 'R');
      },

      /**
       * Returns a Sku model, if found.
       *
       * @param int skuId
       * @return BackboneEx.Model.Silent or null
       */
      getSku: function getSku(skuId) {
        const collection = this.getSkuCollection();
        return collection.get(skuId);
      },

      getSkuPrices: function getSkuPrices(skuId) {
        const sku = this.getSku(skuId);
        const prices = {
          fullPriceAmount: 0,
          discountPriceAmount: 0,
        };

        if (sku) {
          prices.fullPriceAmount = sku.get('fullPriceAmount');
          prices.discountPriceAmount = sku.get('discountPriceAmount');
        }

        return prices;
      },

      // TODO: We need to get the offpeak discount data here from
      // discounts.json, as we only have the discountRuleId at this point..

      getDiscountCalculator: function getDiscountCalculator(
        morningEndTime,
        afternoonEndTime,
        defaults,
      ) {
        return new Wahanda.DiscountCalculator({
          offpeak: [],
          morningEndTime: Wahanda.Time.timeToMinutes(morningEndTime),
          afternoonEndTime: Wahanda.Time.timeToMinutes(afternoonEndTime),
          defaults,
        });
      },

      getSubService: function getSubService(serviceId) {
        const subServices = this.get('subServices');
        return _.findWhere(subServices, { serviceId });
      },

      hasDistributionChannel: function hasDistributionChannel(channel) {
        return _.indexOf(this.get('distributionChannels'), channel) !== -1;
      },

      hasPricesSet: function hasPricesSet() {
        return this.hasSkuPricing() || this.hasDatedPricing();
      },

      hasSkuPricing: function hasSkuPricing() {
        // Check the offer has sku pricing - as opposed to being a dated offer
        return _.any(this.get('skus') || [], (sku) => sku.id || sku.tempId);
      },

      hasVisibleSku: function hasVisibleSku() {
        return _.any(this.get('skus') || [], (sku) => sku.visible);
      },

      /**
       * Returns fulfillments concatenated into a string.
       *
       * @return string
       */
      getFulfillmentString: function getFulfillmentString() {
        const self = this;
        let type = '';
        // Returned string must be alphabetically sorted.
        const typeMap = {
          appointment: 'A',
          dated: 'D',
          evoucher: 'E',
        };
        _.each(typeMap, (code, typeName) => {
          if (self.isFulfilledAs(typeName)) {
            type += code;
          }
        });
        return type;
      },

      /**
       * Get the pricing type string based on SKU data of the model.
       *
       * @returns String One of PRICING_TYPE_* constants.
       */
      getPricingType: function getPricingTypeFunction(skus) {
        if (!skus) {
          return MenuOffer.getPricingType(this.get('skus'));
        }

        return MenuOffer.getPricingType(skus);
      },

      isFeatured: function isFeatured() {
        return this.get('featured');
      },

      getAdditionalTimeType: function getAdditionalTimeType() {
        return this.get('processingTimeMins') > 0 ? 'processing' : 'cleanup';
      },

      isEmployeeCategoryPricing: function isEmployeeCategoryPricing() {
        return this.getPricingType() === MenuOffer.PRICING_TYPE_BY_EMPLOYEE_CAT;
      },

      is2Dpricing: function is2Dpricing() {
        return this.getPricingType() === MenuOffer.PRICING_TYPE_2D;
      },

      /**
       * Return additional time - cleanup or processing - if any, in minutes.
       *
       * @returns Integer
       */
      getAdditionalTimeMins: function getAdditionalTimeMins() {
        const values = [this.get('cleanupTime'), this.get('processingTimeMins')];

        return values.filter(Number).reduce((sum, i) => sum + i, 0);
      },

      getFirstSkuDuration: function getFirstSkuDuration(offersCollection, includeAdditionalTime) {
        const sku = (this.get('skus') || [])[0];
        if (!sku) {
          return 0;
        }

        function getAdditionalTime(offer) {
          if (!includeAdditionalTime) {
            return 0;
          }
          return offer.getAdditionalTimeMins();
        }

        if (sku.duration > 0) {
          return sku.duration + getAdditionalTime(this);
        }
        if (sku.subSkus) {
          return _.reduce(
            sku.subSkus,
            (memo, subSku) => {
              const offer = offersCollection.get(subSku.offerId);
              return memo + subSku.duration + ((offer && getAdditionalTime(offer)) || 0);
            },
            0,
          );
        }
        return 0;
      },

      hasEnclosingPackages: function hasEnclosingPackages() {
        return !!(this.get('enclosingPackages') && this.get('enclosingPackages').length > 0);
      },

      hasEnclosingActivePackages: function hasEnclosingActivePackages(menuOffersCollection) {
        return _.any(this.get('enclosingPackages'), (item) => {
          const packageOffer = menuOffersCollection.get(item.id);
          return packageOffer && !!packageOffer.get('visible');
        });
      },

      isPackagePricingCustom: function isPackagePricingCustom() {
        if (this.get('skus')) {
          const sku = this.get('skus')[0] || {};

          return sku.fullPriceAmount !== null;
        }
        // For new offers, default to "Sum of all services" pricing
        return false;
      },

      /**
       * Returns the list of subServices (MenuOffer instances) which are chosen
       * as a Open Selection services for this Package.
       *
       * @param App.Collections.MenuOffers menuOffersCollection
       *
       * @returns Array
       */
      getOpenSelectionSubServices: function getOpenSelectionSubServices(menuOffersCollection) {
        const list = [];

        _.each(
          this.get('subServices'),
          function f(subService) {
            const offer = menuOffersCollection.get(subService.serviceId);
            if (!offer) {
              return;
            }

            const subSkuCountForOffer = this.getPackageOfferSkuCount(offer.id);
            const isOpenSelectable =
              offer.isOpenSelectable() ||
              // This check if for updated Offers of the Package which have their SKUs removed
              (!offer.get('multiSkuSelection') && subSkuCountForOffer > 1);

            if (isOpenSelectable && subSkuCountForOffer > 1) {
              list.push(subService);
            }
          },
          this,
        );

        return list;
      },

      isOpenSelectable: function isOpenSelectable() {
        return !this.get('multiSkuSelection') && this.get('skus').length > 1;
      },

      /**
       * Returns number of unique Offer skus in the Package.
       *
       * @param Int offerId
       * @returns Number
       */
      getPackageOfferSkuCount: function getPackageOfferSkuCount(offerId) {
        const skuIds = {};

        _.each(this.get('skus'), (sku) => {
          _.each(sku.subSkus, (subSku) => {
            if (subSku.offerId === offerId) {
              skuIds[subSku.id] = true;
            }
          });
        });

        return _.keys(skuIds).length;
      },

      isOfferSelectedInPackage: function isOfferSelectedInPackage(offerId) {
        return this.getPackageOfferSkuCount(offerId) > 0;
      },

      doesPackageContainSku: function doesPackageContainSku(skuId) {
        return _.any(this.get('skus'), (sku) =>
          _.any(sku.subSkus, (subSku) => subSku.id === skuId),
        );
      },

      /**
       * Get the price for this Package Sku. It is defined by skuIds in the list.
       *
       * @param Array skuIdList
       *
       * @returns Number
       */
      getPackageSkuPrice: function getPackageSkuPrice(skuIdList, allowSavedPrice) {
        return MenuOffer.getPackageSkuPrice(
          this.getPackageSku(skuIdList),
          allowSavedPrice,
          this.getHiddenServices(),
        );
      },

      getHiddenServices: function getHiddenServices() {
        const hiddenServices = _.filter(this.get('subServices'), (service) => service.tempId);
        return hiddenServices;
      },

      getPackageSku: function getPackageSku(skuIdList) {
        let target;
        if (!skuIdList || skuIdList.length === 0) {
          target = (this.get('skus') || {})[0];
        } else {
          const idCountToFind = skuIdList.length;
          _.any(this.get('skus'), (sku) => {
            let found = 0;
            _.each(sku.subSkus, (subSku) => {
              if (_.indexOf(skuIdList, subSku.id) !== -1) {
                found += 1;
              }
            });
            if (found === idCountToFind) {
              target = sku;
              return true;
            }

            return false;
          });
        }
        return target;
      },

      getSkuCount: function getSkuCount() {
        return (this.get('skus') || []).length;
      },

      getSkuByEmployeeCategoryId: function getSkuByEmployeeCategoryId(empCatId) {
        return this.getSkuCollection().find(
          (empCategory) => empCategory.get('employeeCategoryId') === empCatId,
        );
      },

      hasChangedSubservices: function hasChangedSubservices() {
        const changedSvcs = this.get('changedUnderlineServices');

        return changedSvcs && Object.keys(changedSvcs).length > 0;
      },

      updatePackageWithServiceChanges: function updatePackageWithServiceChanges(menuOffers) {
        const self = this;
        const openSelectionServiceIds = _.pluck(
          this.getOpenSelectionSubServices(menuOffers),
          'serviceId',
        );

        function isOfferOpenSelection(offerId) {
          return _.indexOf(openSelectionServiceIds, parseInt(offerId, 10)) !== -1;
        }

        // Return new skus from the updatedOffer, which aren't in the Package yet
        function getNewSkus(updatedOffer) {
          let newSkus = updatedOffer.get('skus').slice(0);

          _.each(self.get('skus'), (sku) => {
            _.each(sku.subSkus, (subSku) => {
              // NewSkus are only those which don't have IDs of skus already in the Package
              newSkus = _.filter(newSkus, (nSku) => nSku.id !== subSku.id);
            });
          });

          return newSkus;
        }

        function getRemovedSkus(offer) {
          const allOfferSkuIds = _.pluck(offer.get('skus'), 'id');

          // Removed sku is one which contains sub sku of the Offer which does not exist in the offer itself
          return _.filter(self.get('skus'), (sku) =>
            _.any(
              sku.subSkus,
              (subSku) =>
                subSku.offerId === offer.id && _.indexOf(allOfferSkuIds, subSku.id) === -1,
            ),
          );
        }

        function getNonOpenSelectSubSkus() {
          return _.filter(
            (self.get('skus')[0] || {}).subSkus,
            (subSku) => !isOfferOpenSelection(subSku.offerId),
          );
        }

        function getOtherOpenSelectionOfferSubSkus(notForOfferId) {
          const subSkus = [];
          const added = [];

          _.each(self.get('skus'), (sku) => {
            _.each(sku.subSkus, (subSku) => {
              if (
                subSku.offerId !== notForOfferId &&
                isOfferOpenSelection(subSku.offerId) &&
                _.indexOf(added, subSku.id) === -1
              ) {
                subSkus.push(subSku);
                added.push(subSku.id);
              }
            });
          });

          return subSkus;
        }

        function getRemovedSubSkuIds(offer) {
          const items = _.filter(
            (self.get('skus')[0] || {}).subSkus,
            (subSku) =>
              offer.id === subSku.offerId &&
              !_.any(offer.get('skus'), (oSku) => oSku.id === subSku.id),
          );

          return _.pluck(items, 'id');
        }

        _.each(this.get('changedUnderlineServices'), (__, id) => {
          const offerId = parseInt(id, 10);
          const offer = menuOffers.get(offerId);

          if (isOfferOpenSelection(offerId)) {
            const newSkus = getNewSkus(offer);

            if (newSkus.length > 0) {
              const nonOpenSelectSkus = getNonOpenSelectSubSkus();
              const otherOpenSelectSkus = getOtherOpenSelectionOfferSubSkus(offerId);
              let skusToAdd = [];

              _.each(newSkus, (newSku) => {
                const skuTemplate = {
                  id: null,
                  offerId: self.id,
                  subSkus: nonOpenSelectSkus.concat(newSku),
                };

                if (otherOpenSelectSkus.length > 0) {
                  skusToAdd = skusToAdd.concat(
                    _.map(otherOpenSelectSkus, (opSelSku) =>
                      _.extend({}, skuTemplate, {
                        subSkus: skuTemplate.subSkus.concat(opSelSku),
                      }),
                    ),
                  );
                } else {
                  skusToAdd.push(skuTemplate);
                }
              });

              self.set('skus', self.get('skus').concat(skusToAdd));
            }

            const removedSkus = getRemovedSkus(offer);

            if (removedSkus.length > 0) {
              self.set('skus', _.difference(self.get('skus'), removedSkus));
            }
          } else {
            // Remove SKUs
            const removedSubSkuIds = getRemovedSubSkuIds(offer);

            if (removedSubSkuIds.length > 0) {
              self.set(
                'skus',
                _.map(self.get('skus'), (baseSku) => {
                  const sku = baseSku;
                  sku.subSkus = _.filter(
                    sku.subSkus,
                    (subSku) => _.indexOf(removedSubSkuIds, subSku.id) === -1,
                  );
                  return sku;
                }),
              );
            }
          }
        });

        // It might be that after removing non-open-select offer's SKUs from this
        // Package, we have removed *all* skus of that offer.
        // In that case we need to also remove the offer from subServices.
        self.set(
          'subServices',
          _.filter(self.get('subServices'), (subService) =>
            self.isOfferSelectedInPackage(subService.serviceId),
          ),
        );
      },

      isSkuEnclosedByPackage: function isSkuEnclosedByPackage(skuId) {
        const sku = this.getSku(skuId);
        const list = sku && sku.get('enclosingPackages');

        return list && list.length > 0;
      },

      getEnclosedSkus: function getEnclosedSkus() {
        return this.getSkuCollection().filter((sku) => {
          const list = sku.get('enclosingPackages');
          return list && list.length > 0;
        });
      },

      getActiveSkus: function getActiveSkus() {
        return _.filter(this.get('skus'), (sku) => sku.visible);
      },

      isSkuForEmployeeCategoryIdEnclosedInPackage: function isSkuForEmployeeCategoryIdEnclosedInPackage(
        employeeCategoryId,
      ) {
        const sku = this.getSkuByEmployeeCategoryId(employeeCategoryId);

        return sku ? this.isSkuEnclosedByPackage(sku.id) : false;
      },

      /**
       * Set model data for creation from an external offer template.
       *
       * @param {Object} templateData The offer's template data
       * @param {App.Collections.EmployeeCategories} employeeCategories Our venue's employee categories
       * @returns {void}
       */
      setFromTemplate: function setFromTemplate(origTemplateData, employeeCategories) {
        const templateData = _.clone(origTemplateData);

        templateData.skus = _.map(templateData.skus, (sku) =>
          _.extend({ fullPriceAmount: 0 }, sku),
        );

        const templateSku = templateData.skus[0];
        const wasSkuWithEmployeeCat = !!templateSku && templateSku.employeeCategoryName;

        this.isFromTemplate = true;

        if (!wasSkuWithEmployeeCat) {
          this.set(templateData);
          return;
        }

        // For templates which had SKU pricing defined as "by-employee-cat", we use our own categories.
        // If we don't have employee categories, an empty array will be returned. That's fine, it means
        // "simple pricing".
        const ourSkus = employeeCategories.map((employeeCategory) =>
          _.extend({}, templateSku, {
            employeeCategoryId: employeeCategory.id,
            name: '',
          }),
        );

        this.set(_.extend({}, templateData, { skus: ourSkus }));
      },

      getSelectedEmployeeIds: function getSelectedEmployeeIds() {
        const employees = this.get('employees') || [];

        return employees.map((employee) => employee.employeeId);
      },
    },
    {
      PRICING_TYPE_STORAGE_KEY: 'offer-pricing-type',

      PRICING_TYPE_SIMPLE,
      PRICING_TYPE_BY_EMPLOYEE_CAT,
      PRICING_TYPE_CUSTOM,
      PRICING_TYPE_2D,

      /**
       * Get the pricing type string based on SKU data of the model.
       *
       * @param {Array} skus The skus of the Offer to calculate type on.
       * @returns String One of PRICING_TYPE_* constants.
       */
      getPricingType,

      /**
       * Does the SKU list pass a "we support this combination" test?
       *
       * @param {Array} skus List of skus
       * @returns {boolean} Is the combination valid
       */
      isSkuCombinationSupported,

      reduceSkusToCustomType,
      isPackageSkus2d,

      typeOf: function typeOf(type, groupId, employees) {
        const isSimpleType = type === 'treatment';

        const model = new App.Models.MenuOffer({
          skus: [],
          applicableDays: null,
          voucherValidityMonths: null,
          groupId,
          menuItemTypeCode: this.getTypeCode(type),
          visible: true,
          listed: true,
          taxRate: null,
          employees:
            isSimpleType && employees ? employees.getIds({ takesAppointments: true }) : null,
          treatmentIds: type === 'package' ? [] : null,
        });

        model.set({ fulfillmentTypes: { appointment: true } });
        return model;
      },

      getPricingTypeDefaultingToStored: function getPricingTypeDefaultingToStored(model) {
        if (model.get('skus').length > 0) {
          return model.getPricingType();
        }
        let type =
          Wahanda.LocalStorage.getForVenue(MenuOffer.PRICING_TYPE_STORAGE_KEY) ||
          model.getPricingType();

        if (type === MenuOffer.PRICING_TYPE_BY_EMPLOYEE_CAT && model.isEscapeWithSkuPricing()) {
          // Sku priced escapes do not support by-employee pricing.
          type = MenuOffer.PRICING_TYPE_SIMPLE;
        }

        return type;
      },

      getVisibilityInfo: function getVisibilityInfo(strFrom, strTo) {
        if (!strFrom && !strTo) {
          return null;
        }

        const from = strFrom ? Wahanda.Date.createDate(strFrom) : null;
        const to = strTo ? Wahanda.Date.createDate(strTo) : null;
        const dateInfo = Wahanda.Date.compareRangeToNow(from, to);

        let dateString = null;
        let dateClass = null;
        if (dateInfo.isPast) {
          dateString = Wahanda.lang.menu.offer.listing.ended;
          dateClass = 'critical';
        } else if (dateInfo.isFuture) {
          dateString = `${Wahanda.lang.shared.from} ${Wahanda.Date.formatToDefaultDate(from)}`;
          dateClass = 'future';
        } else if (dateInfo.isCurrent && to != null) {
          dateString = `${Wahanda.lang.shared.until} ${Wahanda.Date.formatToDefaultDate(to)}`;
          dateClass = 'current';
        }

        if (dateString != null) {
          return {
            text: dateString,
            className: dateClass,
          };
        }
        return null;
      },

      isEvoucherType: function isEvoucherType(fulfillmentTypeString) {
        return this.parseFulfillmentString(fulfillmentTypeString).evoucher;
      },

      parseFulfillmentString: function parseFulfillmentString(dirtyStr) {
        const list = {};
        const typeMap = {
          A: 'appointment',
          D: 'dated',
          E: 'evoucher',
        };
        const str = String(dirtyStr).replace(/[^ADE]/g, '');

        _.each(typeMap, (param, code) => {
          list[param] = str.indexOf(code) !== -1;
        });

        return list;
      },

      /**
       * @param Object|String list List of fulfillment types
       *   > if String, in format parsable by parseFulfillmentString()
       *   > if Object, in format { evoucher: true, ... }
       * @param String type one of (appointment|evoucher|dated)
       *
       * @return boolean
       */
      isFulfilledOnlyAs: function isFulfilledOnlyAs(maybeList, type) {
        let list = maybeList;
        if (typeof list === 'string') {
          list = this.parseFulfillmentString(list);
        }
        let validItem;
        const validItemCnt = _.reduce(
          list,
          (cnt, value, code) => {
            if (value) {
              validItem = code;
              return cnt + 1;
            }
            return cnt;
          },
          0,
        );
        return validItemCnt === 1 && validItem === type;
      },

      getTypeCode: function getTypeCode(type) {
        switch (type) {
          case 'treatment':
            return 'T';

          case 'sku-spa-break':
          case 'spa-break':
            return 'O';

          case 'sku-spa-day':
          case 'spa-day':
            return 'Y';

          default:
            return 'S';
        }
      },

      /**
       * Calculate the sum of the Package SKU.
       *
       * @param sku The Package's sku.
       * @param boolean allowSavedPrice
       *
       *
       * @returns Number
       */
      getPackageSkuPrice: function getPackageSkuPrice(sku, allowSavedPrice, newServices) {
        let sum = 0;

        if (!sku && !newServices) {
          return sum;
        }
        if (sku && allowSavedPrice) {
          // The sku is saved, so render it's calculated values.
          sum = sku.discountPriceAmount || sku.fullPriceAmount || sku.calculatedPriceAmount;
          return sum;
        }

        // Sku isn't saved. We need to render the price as a sum from it's skus.
        if (sku) {
          _.each(sku.subSkus, (tempSku) => {
            const isZeroedDiscountPrice = tempSku.discountPriceAmount === 0;
            sum += isZeroedDiscountPrice
              ? 0
              : tempSku.discountPriceAmount ||
                tempSku.fullPriceAmount ||
                tempSku.calculatedPriceAmount ||
                0;
          });
        }

        // If there are any new services, calculate them
        if (newServices) {
          _.each(newServices, (service) => {
            const price = parseFloat(service.price);
            if (!Number.isNaN(price)) {
              sum += price;
            }
          });
        }
        return sum;
      },

      sortSkuList: function sortSkuList(list, allowSavedPrice) {
        return list.sort((a, b) => {
          const aPrice = MenuOffer.getPackageSkuPrice(a, allowSavedPrice);
          const bPrice = MenuOffer.getPackageSkuPrice(b, allowSavedPrice);

          return aPrice - bPrice || 0;
        });
      },

      getAppointmentModelForSku: function getAppointmentModelForSku(skuId) {
        return new App.Models.Appointment({
          offerId: this.id,
          skus: [{ skuId }],
        });
      },
    },
  );

  // Pricing concatenation object
  let PricingList = function PricingList(options) {
    this.list = {};
    this.groupRooms = options.groupRooms;
  };
  PricingList.prototype = {
    add: function add(pricing) {
      const position = this.list[pricing.dateFrom];
      if (position) {
        position.push(pricing);
      } else {
        // No position match. New pricing.
        this.list[pricing.dateFrom] = [pricing];
      }
    },

    toArray: function toArray() {
      let ret;
      if (this.groupRooms) {
        ret = this.toRoomArray();
      } else {
        ret = this.toSimpleArray();
      }

      return ret.sort((a, b) => (a.dateFrom > b.dateFrom ? 1 : -1));
    },

    toSimpleArray: function toSimpleArray() {
      const ret = [];
      _.each(this.list, (items) => {
        const list = [];

        for (let i = 0, len = items.length; i < len; i++) {
          const item = items[i];
          list.push({
            pricingRuleId: item.pricingRuleId,
            fullPriceAmount: item.fullPriceAmount,
            discountPriceAmount: item.discountPriceAmount,
            applicableDays: item.applicableDays,
          });
        }

        ret.push({
          pricingType: items[0].pricingType,
          dateFrom: items[0].dateFrom,
          dateTo: items[0].dateTo,
          // Concatenated list of items, by date
          list,
        });
      });
      return ret;
    },

    toRoomArray: function toRoomArray() {
      const self = this;
      const ret = [];

      _.each(this.list, (items) => {
        const weekdaysList = [];

        for (let i = 0, len = items.length; i < len; i++) {
          const item = items[i];
          let cont = self.getWeekdayContainer(weekdaysList, item.applicableDays);

          if (cont == null) {
            cont = {
              applicableDays: item.applicableDays,
              rooms: [],
            };
            weekdaysList.push(cont);
          }

          cont.rooms.push({
            pricingRuleId: item.pricingRuleId,
            roomTypeId: item.roomTypeId,
            fullPriceAmount: item.fullPriceAmount,
            discountPriceAmount: item.discountPriceAmount,
          });
        }

        // TODO: sort weekdaysList by weekday 1..7, sunday being 7

        ret.push({
          pricingType: items[0].pricingType,
          dateFrom: items[0].dateFrom,
          dateTo: items[0].dateTo,
          list: weekdaysList,
        });
      });

      return ret;
    },

    /**
     * Returns hash to which to add the given weekday's data.
     *
     * @param Array list List of this date's weekday data
     * @param Array weekdays Weekdays to search for
     *
     * @return Array or null, if not found
     */
    getWeekdayContainer: function getWeekdayContainer(list, weekdays) {
      const test = weekdays.join(',');
      return _.find(list, (item) => item.applicableDays.join(',') === test);
    },
  };

  App.Models.MenuOffer = MenuOffer;
})();
