import React from 'react';
import Wahanda from 'common/wahanda';
import { ClientApp } from 'components/common/Icon';

import { colors } from 'style/exports/colors';

import { SettingBox, Props as SettingProps, Analytics } from 'components/common/SettingBox';

import { SETTING, ConsentForAppAdvocacy } from 'components/marketing/AutomatedMessaging/types';
import { L2Page } from 'common/tracking/navigation';

const lang = Wahanda.lang.marketing.clientApp.appvocacySetting;

interface Props {
  isSettingEnabled: boolean;
  isSettingLoading: boolean;
  automatedMessagingSettingsRequest: () => void;
  automatedMessagingReset: () => void;
  setAutomatedMessagingSettingRequest: (payload: ConsentForAppAdvocacy) => void;
}

export const Appvocacy = ({
  isSettingEnabled,
  isSettingLoading,
  automatedMessagingSettingsRequest,
  automatedMessagingReset,
  setAutomatedMessagingSettingRequest,
}: Props) => {
  React.useEffect(() => {
    automatedMessagingSettingsRequest();
    return automatedMessagingReset;
  }, []);

  const setting: SettingProps = {
    Analytics: Analytics(L2Page.AUTOMATED_MESSAGING),
    text: {
      title: lang.title,
      description: lang.description,
      areYouSureTitle: lang.areYouSureTitle,
      areYouSureDescription: lang.areYouSureDescription,
    },
    shouldAskBeforeDisabling: false,
    icon: ClientApp,
    iconBackground: colors.colorCoral,
    trackingId: 'setting-appvocacy',
    link: lang.link,
    name: SETTING.CONSENT_FOR_APPVOCACY,
    isSettingEnabled,
    isSettingLoading,
    callbacks: {
      enableSetting: () => {
        setAutomatedMessagingSettingRequest({
          [SETTING.CONSENT_FOR_APPVOCACY]: true,
        });
      },
      disableSetting: () => {
        setAutomatedMessagingSettingRequest({
          [SETTING.CONSENT_FOR_APPVOCACY]: false,
        });
      },
    },
  };

  return <SettingBox {...setting} />;
};
