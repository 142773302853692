import { connect } from 'react-redux';
import Wahanda from 'common/wahanda';
import { PaymentContainer as PaymentContainerComponent } from './PaymentContainer';
import { withStripe } from './Stripe';

const mapStateToProps = () => {
  return {
    idealSupported: Wahanda.Permissions.canUseIdealPayment(),
  };
};

const connector = connect(mapStateToProps);

export const PaymentContainer = connector(withStripe(PaymentContainerComponent));
