import { isFeatureEnabled } from 'common/trunk-features/store/selectors';

export const SHOW_SUPPORT_PHONE_NUMBER_FEATURE = 'CD-604-connect-enable-phone-number-references';

/**
 * this util should be used only for legacy backbone components, for react components is better to use the selector `shouldShowSupportPhoneNumberSelector`
 */
export const shouldShowSupportPhoneNumber = () =>
  Wahanda.Features.isEnabled(SHOW_SUPPORT_PHONE_NUMBER_FEATURE);

export const shouldShowSupportPhoneNumberSelector = (state) =>
  isFeatureEnabled(state, SHOW_SUPPORT_PHONE_NUMBER_FEATURE);
