import React from 'react';
import { hashMap, vector } from 'mori';
import Wahanda from 'common/wahanda';
import { bindKeyEvent, unbindKeyEvent } from 'common/events';
import Dialog from 'components/common/react-dialog';
import Footer from 'components/common/dialog/footer';

const {
  confirm: { confirmDiscountRuleDelete: CONFIRM_DISCOUNT_RULE_DELETE },
} = Wahanda.lang.menu.smartDiscounting;
interface IConfirmProps extends React.HTMLAttributes<Element> {
  onAccept?: (...args: any[]) => any;
  onReject?: (...args: any[]) => any;
}

export default class Confirm extends React.Component<IConfirmProps, {}> {
  static defaultProps = {
    onAccept: () => {},
    onReject: () => {},
  };

  componentDidMount() {
    bindKeyEvent('enter', this.onKeyAccept);
  }

  componentWillUnmount() {
    unbindKeyEvent('enter', this.onKeyAccept);
  }

  onKeyAccept = () => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.onAccept();
  };

  render() {
    const { onAccept, onReject } = this.props;
    const { accept, reject, title, message } = CONFIRM_DISCOUNT_RULE_DELETE;
    const buttons = vector(
      hashMap('onClick', onAccept, 'title', accept, 'classes', {
        'dialog2--button-right dialog2--button-destructive': true,
      }),
      hashMap('onClick', onReject, 'title', reject, 'classes', {
        'dialog2--button-right dialog2--button-grey': true,
      }),
    );
    return (
      <Dialog
        dataTest="discount-rule-confirm-modal"
        warnWhenClosingChangedForms={false}
        classes={{ 'with-text-red-exclamation-icon': true }}
        hideTitlebar
        onClose={onReject}
      >
        <div className="text">
          <h2>{title}</h2>
          {message}
        </div>
        <Footer buttons={buttons} fullWidth />
      </Dialog>
    );
  }
}
