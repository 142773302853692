import React from 'react';
import { Chevron } from 'components/common/Icon';
import style from './style.scss';

export default class LinkContent extends React.Component<{}, {}> {
  static defaultProps = {
    children: null,
  };

  render() {
    const { children } = this.props;

    if (!App.isProd) {
      console.warn('linkContent should be replaced with Fingerpost component');
    }

    return (
      <div className={style.linkContent}>
        {children}
        <Chevron className={style.icon} />
      </div>
    );
  }
}
