/* global BackboneEx */

(() => {
  App.Views.Dialog.Alert = BackboneEx.View.Dialog2.extend({
    events: {
      'click .js-close': 'close',
    },

    options: {
      width: 400,
      dialogClass: 'alert-dialog',
      dataTest: 'alert-modal',
    },

    buttons: {
      close: true,
    },

    showHeader: false,
    templateId: 'alert-dialog-template',

    render(title) {
      BackboneEx.View.Dialog2.prototype.render.call(this);
      this.$('.b-alert-text').html(title);
    },

    destruct() {
      _.isFunction(this.options.onClose) && this.options.onClose();
    },
  });
})();
