;(function($) {
	/**
	 * Venue datepicker with it's Treatwell-ish config.
	 *
	 * @param Object options Options object:
	 *  > default jQuery Datepicker params
	 *  > bool newStyle if the datepicker should be shown in the new style (y2016)
	 *
	 * @returns jQuery
	 */
	$.fn.venueDatepicker = function(options) {
		options = options || {};

		var opts = $.extend(
			{
				dateFormat: App.config.get('jqueryDateFormat').defaultDate,
				firstDay: Wahanda.Date.dayStringToNumberMap[App.config.get('jqueryDateFormat').firstDayOfWeek],
				constrainInput: true,
				onSelect: function(dateText, inst) {
					var $el = $(this);
					$el.val(dateText);
					try {
						// this field can be in a not-validated form
						$el.valid();
					} catch (e) {}
					$el.trigger('change');
				},
				beforeShow: function (input, inst) {
					// Add the "refreshed" class to DatePicker
					inst.dpDiv.toggleClass("dp2", !!options.newStyle);
				}
			},
			options || {}
		);
		this.datepicker(opts);
		if (opts.constrainInput) {
			// For jQuery form validation
			this.attr('date-format', opts.dateFormat);
		}
		return this;
	};
}(jQuery));
