import React from 'react';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import { ClientsAnalytics } from '../../../tracking';

import { UploadButton } from './UploadButton';
import { SelectedFile } from '../../types';

import style from './FileUpload.scss';

const lang = Wahanda.lang.clientsImport.addMultipleClients.uploadFile;

/* Accept XLS, XLSX or CSV file formats. */
const FILE_FORMATS =
  '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

interface Props {
  actions: {
    clientsImportSelectFile: (data: SelectedFile) => void;
  };
  selectedFileName?: string;
}

export class FileUpload extends React.PureComponent<Props> {
  public render() {
    const { selectedFileName } = this.props;
    return (
      <div>
        <span className={style.header}>{lang.header}</span>
        <p className={style.description}>{lang.description}</p>
        <div className={style.buttonWrapper}>
          <UploadButton
            onSuccess={this.onSuccess}
            accept={FILE_FORMATS}
            render={this.renderUploadButton}
          />
          <span className={style.fileName}>{selectedFileName || lang.noFile}</span>
        </div>
      </div>
    );
  }

  private onSuccess = (result: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(result);
    reader.onload = () => {
      this.props.actions.clientsImportSelectFile({
        file: reader.result,
        fileName: result.name,
      });
    };
  };

  private renderUploadButton = ({ onUpload }) => {
    const onUploadClick = () => {
      ClientsAnalytics.trackClientsImportChooseFileClick();
      onUpload();
    };
    return <Button variant="secondary" label={lang.chooseFile} onClick={onUploadClick} />;
  };
}
