import React, { Component } from 'react';
import { toClj } from 'mori';
import Wahanda from 'common/wahanda';
import SelectDropdown from 'components/common/SelectDropdown';
import style from './style.scss';

const lang = Wahanda.lang.settings.employees.employee;
interface IEmployeeCategoriesProps extends React.HTMLAttributes<Element> {
  employeeCategoryId?: number;
  categories?: {
    id: number;
    name: string;
  }[];
  actions: {
    setEmployeeDataAction: (...args: any[]) => any;
  };
  setEmployeeDataAction?: any;
}

export default class EmployeeCategories extends Component<IEmployeeCategoriesProps, {}> {
  static defaultProps = {
    employeeCategoryId: null,
    categories: [],
  };

  onEmployeeCategorySelect = (employeeCategoryId) => {
    this.props.actions.setEmployeeDataAction({ employeeCategoryId });
  };

  getEmployeeCategoryOptions = () => {
    const { categories } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const categoryOptions = categories.map((category) => ({
      value: category.id,
      name: category.name,
    }));
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
    categoryOptions.unshift({ value: null, name: lang.noGroupText });
    return categoryOptions;
  };

  render() {
    const { employeeCategoryId } = this.props;
    return (
      <div>
        <div className={style.header}>{lang.labels.category}</div>
        <div className={style.selectContainer}>
          <SelectDropdown
            extraClassName={style.select}
            data={toClj(this.getEmployeeCategoryOptions())}
            selected={employeeCategoryId}
            onSelect={this.onEmployeeCategorySelect}
          />
        </div>
      </div>
    );
  }
}
