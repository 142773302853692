import React, { PureComponent } from 'react';
import { generatePath } from 'react-router-dom';
import localesConfig from 'config/domains-locales';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';

import Loader from 'components/common/Loader';
import { LayoutContainer, LayoutGrid } from 'components/common/Layout';
import { Box } from 'components/common/Box';

import { Description } from './Description';
import { Appvocacy } from './Appvocacy';
import { AppStore } from './AppStore';
import style from './ClientApp.scss';
import PATHS from '../Router/paths';

/*
 * this is a hacky way to determine if we need to show
 * the info element to some countries
 * without using a channel feature
 */
const isInfoBlockVisible = (channelCode: string) =>
  [
    localesConfig.CHANNEL_CODES.DE,
    localesConfig.CHANNEL_CODES.AT,
    localesConfig.CHANNEL_CODES.CH,
    localesConfig.CHANNEL_CODES.FR,
  ].includes(channelCode);

interface Props extends React.HTMLAttributes<Element> {
  shareableLinks?: {
    facebookUri: string;
    twitterUri: string;
    clipboardUri: string;
  };
  isEmailCampaignsAccessible: boolean;
  channelCode: any;
  getClientAppShareLinkRequest: (...args: any[]) => any;
  hasAppvocacySettings: boolean;
  history: {
    push: (...args: any[]) => any;
  };
}

export class ClientApp extends PureComponent<Props> {
  static defaultProps = {
    shareableLinks: null,
  };

  constructor(props: Props) {
    super(props);
    this.handleLaunchCampaignClick = this.handleLaunchCampaignClick.bind(this);
  }

  componentDidMount() {
    if (!this.props.shareableLinks) {
      this.props.getClientAppShareLinkRequest();
    }
    NavigationAnalytics.trackPageEventView(L2Page.APP);
  }

  handleLaunchCampaignClick = () => {
    this.props.history.push(
      generatePath(PATHS.CAMPAIGN_PREVIEW_DEFAULT, {
        templateId: 'app-for-your-clients',
      }),
    );
  };

  render() {
    const { shareableLinks } = this.props;

    if (!shareableLinks) return <Loader positionAbsolute />;

    const { channelCode, isEmailCampaignsAccessible, hasAppvocacySettings } = this.props;

    return (
      <LayoutContainer>
        <LayoutGrid>
          <Box>
            <Description
              {...shareableLinks}
              isInfoBlockVisible={isInfoBlockVisible(channelCode)}
              isEmailCampaignsAccessible={isEmailCampaignsAccessible}
              onLaunchCampaignClick={this.handleLaunchCampaignClick}
            />
          </Box>
          <div className={style.appIllustrationContainer}>
            <AppStore />
          </div>
          {hasAppvocacySettings && (
            <div className={style.appvocacyContainer}>
              <Appvocacy />
            </div>
          )}
        </LayoutGrid>
      </LayoutContainer>
    );
  }
}
