import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { abortPOSOperationRequestStart } from 'reduxStore/common/posOperation/actions';
import { TransactionSummaryContainer as Component } from './index';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      abortPOSOperationRequestStart,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ posOperation }) => ({
  operationId: posOperation.operationId,
});

export const TransactionSummaryContainer = connect(mapStateToProps, mapDispatchToProps)(Component);
