import {
  GET_POS_STATUS_RECEIVE,
  GET_POS_STATUS_FAILURE,
  GET_POS_STATUS_RESET,
} from 'reduxStore/actionsConst';

export const initialState = {
  serverError: false,
  openDay: '',
  cashBalance: undefined,
  cardReaders: [],
};

export function posStatus(state = initialState, action) {
  switch (action.type) {
    case GET_POS_STATUS_RECEIVE:
      return {
        ...state,
        ...action.payload.data,
      };
    case GET_POS_STATUS_RESET: {
      return {
        ...initialState,
      };
    }
    case GET_POS_STATUS_FAILURE:
      return {
        ...state,
        serverError: true,
      };
    default:
      return state;
  }
}
