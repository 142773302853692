import React, { PureComponent } from 'react';
import { Time, TIME_TYPES } from 'components/common/DataFormat';
import { OrderProducts } from 'common/analytics';
import { TableRow, TableData } from 'components/common/Table';
import ProductOrderStatusBadge from '../../ProductOrderStatusBadge';
import style from './style.scss';
// @ts-expect-error ts-migrate(2430) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
interface IProductOrderRowProps extends React.HTMLAttributes<Element> {
  id: number;
  created: string;
  totalQuantityOrdered: number;
  status: string;
  productSupplier?: {
    name: string;
  };
  onRowClick: (...args: any[]) => any;
}
class ProductOrderRow extends PureComponent<IProductOrderRowProps, {}> {
  static defaultProps = {
    productSupplier: null,
  };

  onRowClick = () => {
    OrderProducts.trackListItemClick();
    this.props.onRowClick(this.props.id);
  };

  render() {
    const { created, status, totalQuantityOrdered, productSupplier } = this.props;
    return (
      <TableRow onClick={this.onRowClick}>
        <TableData className={style.tableCell}>{productSupplier && productSupplier.name}</TableData>
        <TableData className={style.tableCell}>
          <Time type={TIME_TYPES.DATE} time={created} />
        </TableData>
        <TableData className={style.tableCell}>{totalQuantityOrdered}</TableData>
        <TableData className={style.tableCell}>
          <ProductOrderStatusBadge status={status} />
        </TableData>
      </TableRow>
    );
  }
}

export default ProductOrderRow;
