;(function($) {
	/**
	 * This plugin 
	 */
	$.widget("wahanda.hideToFit", {
		// These options will be used as defaults
		options: {
		},
		
		timerId: null,
		uniqId: null,
		
		_init: function() {
			this._bindToResize();
			this.refresh();
		},
		
		_bindToResize: function() {
			var self = this;
			this.uniqId = 'htf-' + (new Date().getTime());
			$(window).on('resize.' + this.uniqId, function() {
				self._setResizeTimeout();
			});
		},
		
		_setResizeTimeout: function() {
			var self = this;
			if (this.timerId) {
				return;
			}
			this.timerId = window.setTimeout(function() {
				self.timerId = null;
				self.refresh();
			}, 100);
		},
		
		refresh: function() {
			var self = this;
			var $resizables = this.element.find('.hide-to-fit');
			$resizables.each(function() {
				var $elem        = $(this);
				var width        = $elem.width();
				var contentWidth = 0;
				$elem.children().filter(':visible').each(function() {
					contentWidth += $(this).outerWidth();
				});
				
				if (contentWidth > width) {
					var items = self._getSortedItemsAllowedToHide($elem, ':visible');
					var item;
					while (contentWidth > width && items.length > 0) {
						item = items.shift();
						contentWidth -= $(item).outerWidth();
						$(item).hide();
					}
				} else if (contentWidth < width) {
					var items = self._getSortedItemsAllowedToHide($elem, ':hidden').reverse();
					var $item;
					var oWidth;
					var cleanData = true;
					while (contentWidth < width && items.length > 0) {
						$item = $(items.shift());
						oWidth = self._getCachedWidth($item);
						if (contentWidth + oWidth <= width) {
							$item.show();
							contentWidth += oWidth;
						} else {
							// Showing this element would show too much. Do not show any more elements.
							break;
						}
					}
				}
			});
		},
		
		_getCachedWidth: function($item) {
			var key = 'htf-width';
			var val = $item.data(key);
			if (null == val) {
				val = $item.outerWidth();
				$item.data(key, val);
			}
			return val;
		},
		
		/**
		 * @param jQuery $parent
		 * @param String filter One of :hidden, :visible
		 * @return Array
		 */
		_getSortedItemsAllowedToHide: function($parent, filter) {
			return $parent.find('.hideable').filter(filter).sort(function(a, b) {
				var aVal = parseInt(a.getAttribute('data-hide') || 0);
				var bVal = parseInt(b.getAttribute('data-hide') || 0);
				return aVal - bVal;
			}).toArray();
		},
		
		destroy: function() {
			$(window).off('resize.' + this.uniqId);
			
			$.Widget.prototype.destroy.call( this );
		}
	});
}(jQuery));
