import { createSelector } from 'reselect';

const stripePublicApiKeySelector = (state) => state.stripe.stripePublicApiKey;

export const stripeInstanceSelector = createSelector(
  [stripePublicApiKeySelector, (state, props) => props.locale],
  (stripePublicApiKey, locale) => {
    if (!stripePublicApiKey) {
      return undefined;
    }
    return window.Stripe(stripePublicApiKey, {
      locale,
    });
  },
);
