import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Caution extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-caution', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M11.2806419,4.33727814 L3.20160947,18.6309509 L3.0708259,18.874305 C2.62795793,19.7515295 2.86383762,20 3.99767164,20 L20.0023284,20 L20.2767842,19.9940286 C21.2524212,19.9473175 21.3551417,19.6159723 20.7983905,18.6309509 L12.7193581,4.33727814 L12.5773071,4.09580461 C12.0800757,3.29091195 11.8264526,3.37161303 11.2806419,4.33727814 Z M14.4604838,3.35316362 L22.5395162,17.6468364 C23.8984039,20.0510223 22.7607516,22 20.0023284,22 L3.99767164,22 C1.23753381,22 0.0971688125,20.0588552 1.46048379,17.6468364 L9.53951621,3.35316362 C10.8984039,0.948977741 13.0971688,0.941144822 14.4604838,3.35316362 Z M12,15.5 C11.1715729,15.5 10.5,16.1715729 10.5,17 C10.5,17.8284271 11.1715729,18.5 12,18.5 C12.8284271,18.5 13.5,17.8284271 13.5,17 C13.5,16.1715729 12.8284271,15.5 12,15.5 Z M13,8 L11,8 L11,14 L13,14 L13,8 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Caution);
