import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Print extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-print', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M21,20 L21,9.99539757 L19.3610586,10.0020271 C18.9754253,10.0017311 18.5217391,10.0010554 18,10 L18,8 L20.9906311,8 C22.095329,8 23,8.89834587 23,9.99539757 L23,20 L21,20 Z M7,4 L7,10 L17,10 L17,4 L7,4 Z M19,2 L19,12 L5,12 L5,2 L19,2 Z M17,18 L7,18 L6.99969979,19.9994389 L6.99969979,19.9994389 L16.9975267,20 L17,19.9906311 L17,19.9906311 L17,18 Z M6,8 L6,10 L3.0093689,10 L3,18 L3,18 L5,18 L5,16 L19,16 L19,19.9906311 C19,21.0975072 18.0993875,22 16.9975267,22 L7.00247329,22 C5.89670418,22 5.00538043,21.1111322 5.00002427,20.0006954 L1,20 L1,9.99539757 C1,8.89834587 1.90467103,8 3.0093689,8 L6,8 Z M18,13 C18.5522847,13 19,13.4477153 19,14 C19,14.5522847 18.5522847,15 18,15 C17.4477153,15 17,14.5522847 17,14 C17,13.4477153 17.4477153,13 18,13 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Print);
