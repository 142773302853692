/**
 * Finance-stats model.
 */
(function () {
  App.Models.FinanceStats = BackboneEx.Model.Silent.extend({
    url: function () {
      return App.Api.wsUrl(
        '/supplier/' + App.config.get('venue').supplierId + '/finance-stats.json',
      );
    },
  });
})();
