App.on(
    Wahanda.Event.APP_LOADED,
    function onLoaded() {
        jQuery.datepicker.regional[''] = {
        	closeText: Wahanda.lang.date.datePicker.close,
        	prevText: Wahanda.lang.date.datePicker.prev,
        	nextText: Wahanda.lang.date.datePicker.next,
        	currentText: Wahanda.lang.date.today,
        	monthNames: Wahanda.lang.date.months,
        	monthNamesShort: Wahanda.lang.date.monthsShort,
        	dayNames: Wahanda.lang.date.weekdays,
        	dayNamesShort: Wahanda.lang.date.weekdaysShort,
        	dayNamesMin: Wahanda.lang.date.weekdaysMini,
        	weekHeader: Wahanda.lang.date.weekHeader,
        	dateFormat: App.config.get('jqueryDateFormat').defaultDate,
        	firstDay: Wahanda.Date.dayStringToNumberMap[App.config.get('jqueryDateFormat').firstDayOfWeek],
        	isRTL: false,
        	showMonthAfterYear: false,
        	yearSuffix: ''};
        jQuery.datepicker.setDefaults(jQuery.datepicker.regional['']);
    }
);