import { createSelector } from 'reselect';

const externalSalonSystemSelector = createSelector(
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  (state) => state.externalSalonSystemId,
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  (state) => state.externalSalonSystems,
  (externalSalonSystemId, externalSalonSystems) =>
    externalSalonSystemId && externalSalonSystems.length
      ? externalSalonSystems.find((s) => s.id === externalSalonSystemId)
      : {},
);

export default externalSalonSystemSelector;
