import React from 'react';
import classnames from 'classnames';

import style from './style.scss';

export enum TableLayout {
  FIXED = 'fixed',
  AUTO = 'auto',
}

interface Props {
  className?: string;
  border?: boolean;
  layout?: TableLayout;
}

export class Table extends React.PureComponent<Props> {
  public render() {
    const { children, className, layout, border } = this.props;
    const classes = classnames(
      className,
      layout && style[layout],
      border && style.border,
      style.table,
    );
    return <table className={classes}>{children}</table>;
  }
}
