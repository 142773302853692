import { all, call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { getClientAppShareLinkSuccess, getClientAppShareLinkFailure } from './actions';

export default function* getClientAppShareLinkRequested() {
  try {
    const [{ uri: facebookUri }, { uri: twitterUri }, { uri: clipboardUri }] = yield all([
      call(api.get, apiUrl('CLIENT_APP_SHARE_LINK', { communicationChannel: 'FACEBOOK' })),
      call(api.get, apiUrl('CLIENT_APP_SHARE_LINK', { communicationChannel: 'TWITTER' })),
      call(api.get, apiUrl('CLIENT_APP_SHARE_LINK', { communicationChannel: 'CLIPBOARD' })),
    ]);
    yield put(getClientAppShareLinkSuccess({ facebookUri, twitterUri, clipboardUri }));
  } catch (err) {
    yield put(getClientAppShareLinkFailure(err));
  }
}
