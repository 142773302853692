import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import get from 'lodash/get';
import Wahanda from 'common/wahanda';
import { Initializer } from 'common/types/initializers';
import { ClientsImport as Component } from './container';

const store = storeBuilder();
const container = Wahanda.Dialogs.getReactContainer();
interface Params {
  refreshClientsList: () => void;
}

function destroy(target: Element) {
  ReactDOM.unmountComponentAtNode(target);
}

const onClose = () => {
  destroy(container);
};

export const ClientsImport = (params: Params): Initializer => {
  return {
    destroy: () => ReactDOM.unmountComponentAtNode(container),
    render: () => {
      ReactDOM.render(
        <Provider store={store}>
          <Component onClose={onClose} refreshClientsList={get(params, 'refreshClientsList')} />
        </Provider>,
        container,
      );
    },
  };
};
