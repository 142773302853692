import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Document extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-document', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M14.9975267,1 C15.8169954,1 16.8338622,1.41964862 17.4130188,1.99880527 L20.0011947,4.58698117 C20.5794525,5.16523893 21,6.18062644 21,7.00087166 L21,20.9991283 C21,22.1048454 20.106992,23 18.9991283,23 L5.00087166,23 C3.90000338,23 3,22.1011572 3,21.0081969 L3,2.99180311 C3,1.89062768 3.90451312,1 5.00247329,1 L14.9975267,1 Z M13,3 L5.00247329,3 L5.00201943,5.4340664 C5.00160741,8.22512922 5.00093426,13.416506 5,21.0081969 C5,21.0048947 9.66637611,21.0021624 18.9991283,21 L18.9999802,20.2117698 C19.0006779,18.5227052 19.0006845,14.1190725 19,7.00087166 C19,6.71103537 18.7901492,6.20436273 18.5869812,6.00119473 L15.9988053,3.41301883 C15.7960327,3.2102463 15.2907218,3.00133214 15.0002978,3.00000634 L15,7 L17,7 L17,9 L13,9 L13,3 Z M9,15 L9,17 L7,17 L7,15 L9,15 Z M17,15 L17,17 L11,17 L11,15 L17,15 Z M9,11 L9,13 L7,13 L7,11 L9,11 Z M17,11 L17,13 L11,13 L11,11 L17,11 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Document);
