import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions';
import { ZeroToFive as Component } from './ZeroToFive';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ZeroToFive = connect(null, mapDispatchToProps)(Component);
