/**
 * Collection for App.Models.MenuGroup.
 */
App.Collections.MenuGroups = BackboneEx.Collection.Base.extend({
  model: App.Models.MenuGroup,
  urlTemplate: null,

  // comparator: function(a, b) {
  // return a.get('name').localeCompare(b.get('name'));
  // },

  /**
   * Get groups in calendar format.
   *
   * @return Array
   */
  getInCalendarFormat: function () {
    var result = [];
    var pos = 0;
    this.each(function (group) {
      result.push({
        id: group.id,
        title: group.get('name'),
      });
    });

    return result;
  },
});
