import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Sliders extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-sliders', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M11,15 C11.5522847,15 12,15.4509752 12,15.990778 L12,20.009222 C12,20.5173285 11.6172798,20.9361019 11.1173326,20.9933343 L11,21 C10.4477153,21 10,20.5490248 10,20.009222 L10,19 L2,19 L2,17 L10,17 L10,15.990778 C10,15.4826715 10.3827202,15.0638981 10.8826674,15.0066657 L11,15 Z M22,17 L22,19 L14,19 L14,17 L22,17 Z M16,9 C16.5522847,9 17,9.45097518 17,9.99077797 L17,14.009222 C17,14.5173285 16.6172798,14.9361019 16.1173326,14.9933343 L16,15 C15.4477153,15 15,14.5490248 15,14.009222 L15,13 L2,13 L2,11 L15,11 L15,9.99077797 C15,9.48267152 15.3827202,9.06389808 15.8826674,9.00666569 L16,9 Z M22,11 L22,13 L19,13 L19,11 L22,11 Z M6,3 C6.55228475,3 7,3.45097518 7,3.99077797 L7,8.00922203 C7,8.51732848 6.61727982,8.93610192 6.11733256,8.99333431 L6,9 C5.44771525,9 5,8.54902482 5,8.00922203 L5,7 L2,7 L2,5 L5,5 L5,3.99077797 C5,3.48267152 5.38272018,3.06389808 5.88266744,3.00666569 L6,3 Z M22,5 L22,7 L9,7 L9,5 L22,5 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Sliders);
