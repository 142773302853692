import { combineReducers } from 'redux';
import { inlineClientForm } from './InlineClient/store/reducer';
import UDVAppAdvocacyBanner from './UDVAppAdvocacyBanner/reducer';
import { udvCovidFormLink } from 'components/calendar/UDVCovidFormLink/store/reducer';

export const calendar = combineReducers({
  inlineClientForm,
  UDVAppAdvocacyBanner,
  udvCovidFormLink,
});
