/* global BackboneEx */
App.Views.Forms.Profile = BackboneEx.View.Dialog.ValidatingForm.extend(
  {
    events: {},

    options: {
      width: 540,
      dialogClass: 'dialog-new-style',
      dataTest: 'profile-modal',
    },

    templateId: 'profile-edit-template',
    fieldPrefix: /^profile-edit-/,

    postInitialize() {
      if (this.model.id) {
        this.refreshModelAndForm();
      }
    },

    initForm() {
      this.fillFormFromModel();
      this.renderAttribute('email', this.model.get('emailAddress'));
    },

    setValidation() {
      this._setDefaultValidation();
    },

    getModelValues() {
      const values = this.constructor.__super__.getModelValues.call(this);
      values.professionalNewsletterSubscription = !!values.professionalNewsletterSubscription;

      return values;
    },

    /**
     * After save show info dialog and redirect to connect.
     */

    _afterSaveCallback() {
      const View = BackboneEx.View.Dialog.extend({
        events: {
          'click button': () => {
            window.location = Wahanda.Url.getFullUrl('calendar');
          },
        },
        options: { dialogClass: 'alert-dialog success-msg' },
        templateId: 'profile-edit-success-template',
      });

      const dialog = new View();
      dialog.render();
      dialog.open();
    },
  },
  {
    create(profileId) {
      const model = new App.Models.Profile({
        id: profileId,
      });
      return new App.Views.Forms.Profile({
        model,
      });
    },
  },
);
