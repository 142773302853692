require('./menu-enclosed-offer-change-notification.js');
(() => {
  const Dialog = BackboneEx.View.Dialog2.extend(
    {
      buttons: {
        ok: {
          title: null,
          direction: 'right',
          classes: 'dialog2--button dialog2--button-right dialog2--button-green js-close',
        },
      },
      options: {
        width: 480,
        dialogClass: 'dialog2--list dialog2--scrollable',
        lang: {},
        dataTest: 'menu-offer-enclosed-sku-change-modal',
      },
      events: {
        'click .js-close': 'close',
      },

      initialize() {
        BackboneEx.View.Dialog2.prototype.initialize.call(this);

        this.options.lang = _.extend({}, Wahanda.lang.menu.enclosedSku, this.options.lang);

        this.dialogTitle = this.options.lang.title;
        this.buttons.ok.title = this.options.lang.closeText;
      },

      render() {
        this.setupDialog();
        this.rendered = true;
        const self = this;
        const skus = this.options.skus;
        const packages = _.chain(skus)
          .map(function (sku) {
            return sku.get('enclosingPackages');
          })
          .flatten(true)
          .uniq(false, function (item) {
            return item.id;
          })
          .value();

        this.$dialogContent.addClass('content-scroll');
        this.$dialogContent.html(
          Wahanda.Template.render(this.getTemplateString(), {
            packages,
            sku() {
              const name = self.options.employeeCatName
                ? self.options.employeeCatName
                : skus[0].get('name');
              return `<strong>${name}</strong>`;
            },
            count: skus.length,
          }),
        );

        this.setMaxScrollHeight(120);
      },

      getTemplateString() {
        return (
          `<div>${this.options.lang.topLine}</div>` +
          `<ul class="simple-list">` +
          `{{#packages}}<li>{{name}}</li>{{/packages}}` +
          `</ul>` +
          `<div>${this.options.lang.bottomLine}</div>`
        );
      },
    },
    {
      open(skus, options) {
        const dialog = new Dialog(_.extend({}, options, { skus }));

        dialog.open();
      },
    },
  );

  App.Views.Dialog.MenuOfferEnclosedSkuChangePrevention = Dialog;
})();
