import { useQuery } from 'react-query';
import apiUrl from 'common/api-url';
import * as api from 'reduxStore/apiService';

export interface OrderInfoResponse {
  cancellable: boolean;
  appointments: any[];
  appointmentGroups: any[];
}

export function useOrderInfoQuery(orderId: number | undefined, enabled: boolean) {
  const { isLoading, data } = useQuery<OrderInfoResponse>(
    ['order-info', orderId],
    () => {
      return api.get<OrderInfoResponse>(apiUrl('ORDER_INFO', { id: orderId }));
    },
    {
      enabled,
    },
  );

  return { isLoading, response: data };
}
