import React, { PropsWithChildren } from 'react';
import layoutStyle from './ClientFormsCreate.scss';
import { Notice } from 'components/common/Notice';
import Wahanda from 'common/wahanda';

export const ClientFormsCreateStepLayout = ({
  stepNumber,
  stepTitle,
  stepDescription,
  children,
  displayError = false,
  displayStepNumber = true,
  showHeader = false,
}: PropsWithChildren<{
  stepNumber: number;
  stepTitle: string;
  stepDescription: string;
  displayError?: boolean;
  displayStepNumber?: boolean;
  showHeader?: boolean;
}>): JSX.Element => {
  return (
    <div className={layoutStyle.stepContainer}>
      {showHeader && (
        <header>
          {displayStepNumber && <span>Step {stepNumber}</span>}
          <h2>{stepTitle}</h2>
          <p>{stepDescription}</p>
        </header>
      )}
      {displayError && (
        <Notice type="alert" message={Wahanda.lang.shared.errors.labels.savingFailed} />
      )}
      <section>{children}</section>
    </div>
  );
};
