import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class WalkIn extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
        return (
          <svg
            className={['icon-svg', 'svg-walk-in', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 12 12"
          >
            <path d="M6.46153846,2.86046512 C6.96923077,2.86046512 7.38461538,2.44186047 7.38461538,1.93023256 C7.38461538,1.41860465 6.96923077,1 6.46153846,1 C5.95384615,1 5.53846154,1.41860465 5.53846154,1.93023256 C5.53846154,2.44186047 5.95384615,2.86046512 6.46153846,2.86046512 Z M6.23076923,8.4574904 L6.23076923,11 L6.23076923,11 L7.15384615,11 L7.32699048,8.30799298 C7.34419363,8.04052244 7.25330648,7.77734118 7.07471678,7.57748585 L6.18461538,6.58139535 L6.18461538,6.58139535 L6.46153846,5.18604651 C7.06153846,5.88372093 7.98461538,6.34883721 9,6.34883721 L9,5.41860465 C8.12307692,5.41860465 7.38461538,4.95348837 7.01538462,4.30232558 L6.55384615,3.55813953 C6.29538462,3.14418605 5.77846154,2.97674419 5.33076923,3.16744186 L3.54874276,3.92845155 C3.21071508,4.07280538 2.97912469,4.39096262 2.94563667,4.75699443 L2.79999995,6.34883721 L2.79999995,6.34883721 L3.70000005,6.34883721 L3.88228991,5.0565816 C3.90748992,4.87793835 4.02697359,4.72672439 4.19494668,4.66089514 L4.75384615,4.44186047 L4.75384615,4.44186047 L4.42091819,6.13138694 C4.34706754,6.50616056 4.49316711,6.89026675 4.79741566,7.12122564 L6.03308679,8.0592391 C6.15762579,8.15377822 6.23076923,8.30113309 6.23076923,8.4574904 Z M3.46153846,11 L4.43076923,11 L5.04355169,8.27794947 C5.09006569,8.07132891 5.0013557,7.85781731 4.82211815,7.74499221 L4.16148936,7.32914455 L4.16148936,7.32914455 L3.46153846,11 Z" />
          </svg>
        );
      case 'small':
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-walk-in', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 12 12"
          >
            <path d="M6.46153846,2.86046512 C6.96923077,2.86046512 7.38461538,2.44186047 7.38461538,1.93023256 C7.38461538,1.41860465 6.96923077,1 6.46153846,1 C5.95384615,1 5.53846154,1.41860465 5.53846154,1.93023256 C5.53846154,2.44186047 5.95384615,2.86046512 6.46153846,2.86046512 Z M6.23076923,8.4574904 L6.23076923,11 L6.23076923,11 L7.15384615,11 L7.32699048,8.30799298 C7.34419363,8.04052244 7.25330648,7.77734118 7.07471678,7.57748585 L6.18461538,6.58139535 L6.18461538,6.58139535 L6.46153846,5.18604651 C7.06153846,5.88372093 7.98461538,6.34883721 9,6.34883721 L9,5.41860465 C8.12307692,5.41860465 7.38461538,4.95348837 7.01538462,4.30232558 L6.55384615,3.55813953 C6.29538462,3.14418605 5.77846154,2.97674419 5.33076923,3.16744186 L3.54874276,3.92845155 C3.21071508,4.07280538 2.97912469,4.39096262 2.94563667,4.75699443 L2.79999995,6.34883721 L2.79999995,6.34883721 L3.70000005,6.34883721 L3.88228991,5.0565816 C3.90748992,4.87793835 4.02697359,4.72672439 4.19494668,4.66089514 L4.75384615,4.44186047 L4.75384615,4.44186047 L4.42091819,6.13138694 C4.34706754,6.50616056 4.49316711,6.89026675 4.79741566,7.12122564 L6.03308679,8.0592391 C6.15762579,8.15377822 6.23076923,8.30113309 6.23076923,8.4574904 Z M3.46153846,11 L4.43076923,11 L5.04355169,8.27794947 C5.09006569,8.07132891 5.0013557,7.85781731 4.82211815,7.74499221 L4.16148936,7.32914455 L4.16148936,7.32914455 L3.46153846,11 Z" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(WalkIn);
