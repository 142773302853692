import React from 'react';

interface Props {
  className?: string;
}

class Scissors extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-scissors', this.props.className].join(' ')}
        viewBox="0 0 100 100"
      >
        <g fill="none">
          <path
            fill="#FFB500"
            d="M27.317 72.88a2.13 2.13 0 0 1 0-3.013 7.508 7.508 0 0 0 2.212-5.342c0-2.017 4.262-3.156 4.262 0s-1.23 6.124-3.461 8.355a2.126 2.126 0 0 1-1.507.624c-.545 0-1.09-.208-1.506-.624zm-.907-14.712c-3.677-4.634-3.375-11.41.907-15.693a7.505 7.505 0 0 0 2.212-5.34 7.505 7.505 0 0 0-2.212-5.342c-4.607-4.607-4.607-12.102 0-16.708a2.13 2.13 0 1 1 3.013 3.013c-2.945 2.945-2.945 7.737 0 10.682a11.736 11.736 0 0 1 3.461 8.354c0 3.156-1.23 6.123-3.461 8.354-2.752 2.753-2.932 7.119-.54 10.082.167.208-3.1 2.95-3.38 2.598z"
          />
          <path
            fill="#00B0B9"
            d="M77.732 60.78c1.976-1.328 4.235-2.536 7.316-2.537 6.562-.002 11.902 5.339 11.902 11.902 0 6.562-5.34 11.902-11.901 11.902-5.697.001-10.828-4.792-11.754-10.827l-.096.001c-.042-3.318-2.164-4.883-2.53-5.126 0 0-6.923-3.017-11.842-5.146a14.06 14.06 0 0 0-9.638-.561c-2.865.862-6.374 1.868-8.676 2.359-10.038 2.14-20.67 3.73-30.532 4.936-1.131.138-1.6-.392-1.403-1.588 21.544-4.066 35.453-8.16 41.727-12.283 13.623-8.953 19.308-13.671 25.686-12.77 1.617.23 3.305 1.726 5.227 2.744 10.118 5.36 18.574-7.106 12.573-15.961a11.716 11.716 0 0 1 3.023 7.871c0 6.494-5.265 11.697-11.763 11.763-2.61.024-5.762-1.273-7.378-2.611l.003.008s-3.547-2.32-6.907.002L59.476 52.19l12.49 8.613.068.052c2.398 1.79 5.62-.032 5.653-.052l.045-.025zM85 76.5a7 7 0 1 0 0-14 7 7 0 0 0 0 14z"
          />
          <path fill="#FFF" d="M53.5 54.936l4.107-3.114-3.459-2.555-3.048.374-.553 1.816v2.492z" />
          <path
            stroke="#001E62"
            d="M71.793 60.418c2.398 1.791 5.618-.031 5.652-.052l.045-.025c1.976-1.326 4.235-2.535 7.317-2.536 6.561-.001 11.902 5.338 11.902 11.902-.001 6.562-5.34 11.901-11.902 11.902-5.697 0-10.827-4.791-11.753-10.827-.272-2.787-1.147-4.496-2.626-5.125 0 0-6.924-3.018-11.843-5.147a14.06 14.06 0 0 0-9.638-.56c-2.864.862-6.374 1.869-8.676 2.36-10.037 2.138-20.67 3.73-30.531 4.935-1.715.21-2.296-2.215-.674-2.808l29.2-10.663c1.239-.452 1.223-2.21-.023-2.64L9.988 41.386c-1.653-.57-1.077-3.037.658-2.815 11.037 1.417 25.719 3.417 32.266 4.813 2.008.428 4.867 1.143 7.313 1.776a14.06 14.06 0 0 0 8.65-.52l11.092-4.341s3.135-1.13 3.137-5.246c-.002-.029-.001-.058-.001-.085v-.838c.568-5.966 5.59-10.635 11.707-10.635 6.495 0 11.762 5.267 11.762 11.763 0 6.494-5.265 11.698-11.762 11.763-2.61.025-5.762-1.273-7.379-2.611l.003.009s-3.547-2.321-6.906.001L54.62 54.211a2.684 2.684 0 1 1-3.188-4.316l.218-.155a2.685 2.685 0 0 1 2.863-.16l17.28 10.838z"
          />
          <path
            stroke="#001E62"
            d="M21.013 85.069c-3.782-3.783-3.782-9.914 0-13.696a9.64 9.64 0 0 0 2.757-5.602m-1.577-6.666a9.736 9.736 0 0 0-1.18-1.427 9.685 9.685 0 0 1 0-13.696c3.782-3.782 3.782-9.914 0-13.696m2.49 48.033a9.654 9.654 0 0 1 2.836-6.946 9.653 9.653 0 0 0 2.836-6.717"
          />
          <path
            stroke="#001E62"
            d="M26.339 57.678a9.685 9.685 0 0 1 0-13.696c3.782-3.782 3.782-9.914 0-13.695-3.782-3.782-3.782-9.914 0-13.696m5.325 54.782a9.665 9.665 0 0 0 2.79-7.798"
          />
          <path
            stroke="#001E62"
            d="M30.52 56.301c-2.588-3.771-2.207-8.967 1.144-12.319 3.783-3.782 3.783-9.914 0-13.695-3.781-3.782-3.781-9.914 0-13.696M21.013 30.286c-3.782-3.781-3.782-9.913 0-13.695m62.714 45.916a7.258 7.258 0 1 1-4.574 2.873m4.574-37.439a7.258 7.258 0 1 1-4.75 3.125"
          />
        </g>
      </svg>
    );
  }
}

export const icon = Scissors;
