import LocalStorage from 'common/local-storage';
import {
  PRICING_TYPE_SIMPLE,
  PRICING_TYPE_BY_EMPLOYEE_CAT,
} from 'components/menu/OfferTreatmentPricingAndEmployees/constants';

const PRICING_TYPE_STORAGE_KEY = 'offer-pricing-type';

export function getPricingType() {
  const value = LocalStorage.getForVenue(PRICING_TYPE_STORAGE_KEY);

  return value === PRICING_TYPE_BY_EMPLOYEE_CAT
    ? PRICING_TYPE_BY_EMPLOYEE_CAT
    : PRICING_TYPE_SIMPLE;
}

export function savePricingType(type) {
  LocalStorage.setForVenue(PRICING_TYPE_STORAGE_KEY, type);
}
