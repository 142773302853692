// Appointment related
export const BOOKING_ACTOR_CUSTOMER = 'CUSTOMER';

export const BOOKING_ACTOR_SUPPLIERS_CUSTOMER = 'SUPPLIERS_CUSTOMER'; // Widget

export enum BOOKING_ACTOR {
  /**
   * The booking was made by a customer on one of our marketplaces, or 3rd party integration, e.g Reserve With Google.
   */
  CUSTOMER = 'CUSTOMER',
  /**
   * The booking was made directly by the supplier on the customer's behalf. This might be a walk-in, or over the
   * phone.
   */
  SUPPLIER = 'SUPPLIER',
  /**
   * The booking was made by the customer using the supplier marketing site, i.e.via our widget embedded in their own
   * website or social media page
   */
  WIDGET = 'SUPPLIERS_CUSTOMER',
}

export const DISTRIBUTION_CHANNELS = {
  CUSTOMER: 'CUSTOMER',
  WIDGET: 'SUPPLIERS_CUSTOMER',
};

export const CALENDAR_BLOCK_TYPES = {
  INTERNAL: 'IL',
  EXTERNAL: 'EL',
};

export const CALENDAR_BLOCK_SOURCES = {
  ICAL: 'ICAL',
  TWO_WAY_API: 'TWO_WAY_API',
};

export const IMAGE_UPLOAD_MAX_DIMENSIONS = {
  minWidth: 1920,
  maxWidth: 4500,
  minHeight: 1280,
  maxHeight: 4500,
};
