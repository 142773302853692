(function($) {
	// Extend the jQuery.ui.datepicker to have an "afterShow" event
	// http://bugs.jqueryui.com/ticket/6885
	$.datepicker._inlineDatepicker_original = $.datepicker._inlineDatepicker;
	$.datepicker._inlineDatepicker = function(target, inst) {
		$.datepicker._inlineDatepicker_original(target, inst);
		
		var afterShow = this._get(inst, 'afterShow');
		if (afterShow)
			afterShow.apply((inst.input ? inst.input[0] : null));  // trigger custom callback
	};
	
	$.datepicker._updateDatepicker_original = $.datepicker._updateDatepicker;
	$.datepicker._updateDatepicker = function(inst) {
		$.datepicker._updateDatepicker_original(inst);
		
		var afterUpdate = this._get(inst, 'afterUpdate');
		if (afterUpdate)
			afterUpdate.apply((inst.input ? inst.input[0] : null));  // trigger custom callback
	}
}(jQuery));