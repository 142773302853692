import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';

export default function setup(updateAlways, updateIfNoWebSocketsConnection) {
  // Load on initial venue fetch
  App.once(Wahanda.Event.APP_LOADED, updateAlways);
  // ...and also on Venue Change.
  App.on(Wahanda.Event.APP_VENUE_CHANGED, updateAlways);

  // If no WebSockets connection, we still need to refresh the unconfirmed list.
  const wEvent = Wahanda.Event;
  const appointmentChangeEvents = [
    wEvent.APPOINTMENT_CONFIRMED,
    wEvent.APPOINTMENT_REJECTED,
    wEvent.APPOINTMENT_SET_NOSHOW,
    wEvent.APPOINTMENT_GROUP_CONFIRMED,
    wEvent.APPOINTMENT_GROUP_REJECTED,
    wEvent.APPOINTMENT_GROUP_SET_NOSHOW,
  ].join(' ');
  App.on(appointmentChangeEvents, updateIfNoWebSocketsConnection);
}
