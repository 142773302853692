import { reducer, on } from 'ts-action';
import { MenuGroupResponse } from './types';
import * as actions from './actions';

interface State extends MenuGroupResponse {
  isLoaded: boolean;
  isSubmitting: boolean;
  serverError: boolean;
  serverErrorName?: string;
}

const initialState: State = {
  active: false,
  id: 0,
  name: '',
  description: {
    content: '',
    contentType: '',
  },
  isLoaded: false,
  isSubmitting: false,
  serverError: false,
  serverErrorName: undefined,
};

export const menuGroup = reducer(
  initialState,
  on(actions.getMenuGroup, (state, { payload }) => ({
    ...state,
    ...payload,
  })),
  on(actions.getMenuGroupSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoaded: true,
  })),
  on(actions.getMenuGroupFailure, (state) => ({
    ...state,
    isLoaded: true,
  })),
  on(actions.setMenuGroup, actions.deleteMenuGroup, (state) => ({
    ...state,
    isSubmitting: true,
  })),
  on(actions.setMenuGroupSuccess, actions.deleteMenuGroupSuccess, (state) => ({
    ...state,
    isSubmitting: false,
    serverError: false,
  })),
  on(actions.setMenuGroupFailure, actions.deleteMenuGroupFailure, (state, { payload }) => ({
    ...state,
    isSubmitting: false,
    serverError: true,
    serverErrorName: payload.serverErrorName,
  })),

  on(actions.resetMenuGroupError, (state) => ({
    ...state,
    isSubmitting: false,
    serverError: false,
    serverErrorName: undefined,
  })),

  on(actions.resetMenuGroup, () => initialState),
);
