;(function($) {
	"use strict";
	/**
	 * A "error" tooltip.
	 *
	 * @param string text Text to show
	 * @param Object qtipOptions OPTIONAL
	 * @param int hideAfter OPTIONAL, default 500ms
	 *
	 * @uses qtip2
	 * Copyright Wahanda.com
	 */
	$.fn.errorTip = function(text, qtipOptions, hideAfter) {
		var opts = $.extend(
			{},
			$.fn.errorTip.defaults,
			qtipOptions || {},
			{
				content: {
					text: _makeText(text),
					title: { text: " ", button: true }
				}
			}
		);

		if (hideAfter > 0) {
			opts.hide.inactive = hideAfter;
		}

		this.each(function() {
			$(this).qtip(opts);
		});
	};

	var tooltipClass = "ui-error-tooltip";
	// Global default configuration
	$.fn.errorTip.defaults = {
		position: {
			my: "bottom center",
			at: "top center",
			adjust: {
				y: -2
			}
		},
		content: {
			text: "text",
			title: {
				text: "",
				button: true
			}
		},
		show: {ready: true, event: false},
		hide: false,
		// This hides the qTip onClick
		style: {
			tip: {
				width: 12,
				height: 7
			},
			classes: tooltipClass
		}
	};

	function _makeText(text) {
		var html = text;
		html += '<br /><a href="javascript:;" class="error-info">' + Wahanda.lang.shared.moreInfo + '</a>';
		var $elem = $("<div>").html(html);
		$elem.find('.error-info').on('click', function() {
			// After showing the full error info, close the tooltip
			$elem.closest('.' + tooltipClass).qtip('destroy');
			Wahanda.Dialogs.HTMLAlert(App.lastError, {width: 500});
		});
		return $elem;
	}
}(jQuery));
