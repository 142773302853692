import React from 'react';
import style from './style.scss';

interface ITypeCardProps extends React.HTMLAttributes<Element> {
  actions: {
    selectPromotion: (...args: any[]) => any;
  };
  id: string;
  name: string;
  description: string;
  image: JSX.Element;
  selectPromotion?: any;
}

export default class TypeCard extends React.Component<ITypeCardProps, {}> {
  handleCardClick = () => {
    this.props.actions.selectPromotion(this.props.id);
  };

  render() {
    const { name, description, image } = this.props;
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div onClick={this.handleCardClick} className={style.cardContent}>
        <div className={style.icon}>{image}</div>
        <div className={style.textBox}>
          <span className={style.name}>{name}</span>
          <span className={style.description}> {description} </span>
        </div>
      </div>
    );
  }
}
