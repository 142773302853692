import React, { useEffect, useState } from 'react';

import { CancellationFlowAnalytics } from 'common/analytics';
import { Button } from 'components/common/Button';
import Dialog from 'components/common/react-dialog';
import { OrderInfoResponse } from 'common/api-hooks/useOrderInfoQuery';
import Wahanda from 'common/wahanda';

import style from './OrderCancellationOptions.scss';
import { CancelThroughSupport } from '../CancelThroughSupport';

interface Props {
  onClose: () => void;
  onCancel: () => void;
  orderInfo: Partial<OrderInfoResponse>;
}

export const OrderCancellationOptions = (props: Props) => {
  useEffect(() => {
    CancellationFlowAnalytics.trackOrderCancellationInitiation();
  }, []);

  const { onClose, onCancel, orderInfo } = props;

  const [showCancelThroughSupport, setShowCancelThroughSupport] = useState(false);

  const onCancelSingleAppointment = () => {
    CancellationFlowAnalytics.trackCancelSingleAppointment();

    // Partial cancellations are not supported as of now. Once they are available, this whole component should be redundant.
    // However, support can handle partial cancellations over phone. That's why we fall back to showing phone number.
    setShowCancelThroughSupport(true);
  };

  const onCancelOrder = () => {
    CancellationFlowAnalytics.trackCancelEntireOrder();
    onCancel();
  };

  if (!orderInfo.cancellable || showCancelThroughSupport) {
    return <CancelThroughSupport onClose={onClose} />;
  }

  return (
    <Dialog
      width={350}
      title={Wahanda.lang.calendar.appointments.cancellation.confirmCancellation.title}
      classes={{ 'dialog2--button-choices appointment-deletion-proxy': true }}
      onClose={onClose}
    >
      <div className={style.header}>
        {Wahanda.lang.calendar.appointments.cancellation.order.header}
      </div>
      <Button
        label={Wahanda.lang.calendar.appointments.cancellation.order.entireOrder}
        onClick={onCancelOrder}
        variant="secondary"
        fullWidth
        size="large"
        dataTest="cancel-entire-order"
      />
      <div className={style.button}>
        <Button
          label={Wahanda.lang.calendar.appointments.cancellation.order.singleAppointment}
          onClick={onCancelSingleAppointment}
          variant="secondary"
          fullWidth
          size="large"
          dataTest="cancel-single-appointment"
        />
      </div>
    </Dialog>
  );
};
