import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ClientsBulkDelete from 'components/clients/ClientsBulkDelete/container';
import storeBuilder from 'reduxStore/store';

const store = storeBuilder();

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

function render(params) {
  const container = Wahanda.Dialogs.getReactContainer();
  const close = () => {
    destroy(container);
  };
  ReactDOM.render(
    <Provider store={store}>
      <ClientsBulkDelete
        onClose={close}
        onDeleteStart={params.onDeleteStart}
        onDeleteComplete={params.onDeleteComplete}
      />
    </Provider>,
    container,
  );
}

export default { render, destroy };
