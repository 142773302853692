import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { getEmailTemplate } from 'components/marketing/EmailCampaign/selectors';
import {
  getEmailTemplateRequest,
  getSmartDiscountsTemplateRequest,
  getServiceOnSaleTemplateRequest,
  getAppForYourClientTemplateRequest,
  editMarketingEmailContent,
  storeMarketingEmailContent,
  emailTemplateReset,
} from './actions';

import { selectDiscount, selectTreatmentGroup } from '../CampaignSelection/actions';

import { CampaignPreview as Component } from './';

const mapStateToProps = (state) => ({
  marketingEmailTemplate: getEmailTemplate(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getEmailTemplateRequest,
      getSmartDiscountsTemplateRequest,
      getServiceOnSaleTemplateRequest,
      getAppForYourClientTemplateRequest,
      editMarketingEmailContent,
      storeMarketingEmailContent,
      selectDiscount,
      selectTreatmentGroup,
      emailTemplateReset,
    },
    dispatch,
  ),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type 'History<any>' is not assignable to type 'nev... Remove this comment to see the full error message
export const CampaignPreview = withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
