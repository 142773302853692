import { trackEvent, ACTIONS } from 'common/analytics';

export class ClientAppAnalytics {
  static CATEGORY = 'tw-client-app';

  static trackFacebook() {
    trackEvent(ClientAppAnalytics.CATEGORY, ACTIONS.CLICK, 'facebook-link');
  }

  static trackTwitter() {
    trackEvent(ClientAppAnalytics.CATEGORY, ACTIONS.CLICK, 'twitter-link');
  }

  static trackClipboard() {
    trackEvent(ClientAppAnalytics.CATEGORY, ACTIONS.CLICK, 'copy-link');
  }

  static trackGooglePlay() {
    trackEvent(ClientAppAnalytics.CATEGORY, ACTIONS.CLICK, 'google-play');
  }

  static trackAppStore() {
    trackEvent(ClientAppAnalytics.CATEGORY, ACTIONS.CLICK, 'app-store');
  }

  static trackHelpCenter() {
    trackEvent(ClientAppAnalytics.CATEGORY, ACTIONS.CLICK, 'help-center');
  }
}
