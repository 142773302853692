App.Models.SpaAvailabilitySaver = BackboneEx.Model.Silent.extend({
  structure: {
    inventoryAvailable: 'integer',
    closed: 'boolean',
    dateRange: 'object',
  },

  attributesToSave: function () {
    var list = ['inventoryAvailable', 'closed'];
    if (this.get('dates')) {
      list.push('dates');
    } else {
      list.push('dateRange');
    }
    return list;
  },

  url: function () {
    var base = '/venue/' + App.config.get('venue').id + '/spa-allocations.json';
    return App.Api.wsUrl(base);
  },

  _afterSave: function () {
    App.trigger('spa-availability:saved');
  },
});
