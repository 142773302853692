App.Views.Header.Search = BackboneEx.View.Base.extend({
  rendered: false,

  render: function () {
    if (this.rendered) {
      return;
    }

    var $search = this.$el;

    $search.find('input').search({
      appendTo: $search,
      position: {
        my: 'right top',
        at: 'right bottom',
        offset: '0 11',
      },
      maxHeight: 440,
      $clear: $search.find('.clear-search'),
      $loader: $search.find('.search-loader'),
      source: function () {
        return App.Api.wsUrl(
          '/venue/' +
            App.getVenueId() +
            '/search.json?include=consumers,appointments,dated,services&limit=20',
        );
      },
      select: function (item) {
        this.onSearchSelect(item);
      }.bind(this),
      open: function () {
        App.trigger(Wahanda.Event.HEADER_SEARCH_RESULTS_OPENED);
      },
    });
  },

  onSearchSelect: function (item) {
    var resultType;

    switch (
      item._type // eslint-disable-line no-underscore-dangle
    ) {
      case 'visits':
        if (item.bookingType === 'AP') {
          this.openAppointment(item.appointmentId);
          resultType = 'appointment';
        } else {
          this.openOrderDialogByBookingId(
            item.bookingId || (item.orderItemIds && item.orderItemIds[0]),
          );
          resultType = 'order';
        }
        break;

      case 'clients':
        var view = App.Views.Dialog.Consumer.createInstance(item.id);
        view.render();
        view.open();
        resultType = 'customer';
        break;

      case 'bookings':
        this.openOrderDialogByBookingId(
          item.bookingId || (item.orderItemIds && item.orderItemIds[0]),
        );
        resultType = 'evoucher';
        break;

      default:
      // No default case.
    }

    if (resultType) {
      App.trigger(Wahanda.Event.HEADER_SEARCH_RESULT_CHOSEN, {
        entityTypeCode: resultType,
        sourcePage: App.getCurrentModule(),
      });
    }
  },

  openAppointment: function (apptId) {
    var appointment = new App.Models.Appointment({ id: apptId });

    // Change the calendar date, if it's the calendar module
    function dateChangeHandler(date) {
      if (App.mainView instanceof App.Views.Calendar) {
        App.mainView.appointmentCalendar.trigger('change:date', date);
      }
    }

    Wahanda.Appointment.openUDV({
      model: appointment,
      changeCalendarDate: dateChangeHandler,
    });
  },

  openOrderDialogByBookingId: function (bookingId) {
    App.Views.Dialog.Order2.openByBookingId(bookingId);
  },
});
