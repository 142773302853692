import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Messenger extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-messenger', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100.001%" id="a">
            <stop offset="0%" stopColor="#00B2FF" />
            <stop offset="100%" stopColor="#006AFF" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            d="M9.94.01C4.34.01 0 4.112 0 9.652c0 2.898 1.188 5.402 3.122 7.132a.8.8 0 0 1 .268.566l.054 1.768c.018.564.6.93 1.116.704l1.972-.87a.793.793 0 0 1 .53-.04c.906.25 1.872.382 2.878.382 5.6 0 9.94-4.102 9.94-9.642 0-5.54-4.34-9.642-9.94-9.642z"
            fill="url(#a)"
            transform="translate(2 2)"
          />
          <path
            d="M5.972 14.472l2.92-4.632a1.492 1.492 0 0 1 2.156-.398l2.322 1.742c.214.16.506.158.718-.002l3.136-2.38c.418-.318.966.184.684.628l-2.918 4.63c-.464.736-1.46.92-2.156.398l-2.322-1.742a.597.597 0 0 0-.718.002L6.656 15.1c-.418.318-.966-.184-.684-.628z"
            fill="#FFF"
          />
        </g>
      </svg>
    );
  }
}

export const icon = iconWrapper(Messenger);
