import { shouldShowSupportPhoneNumber } from 'utilities/shouldShowSupportPhoneNumber';

(function () {
  var entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
  };

  Wahanda.Util = {
    /**
     * Combines array of integers into an array with dash-separated ranges.
     *
     * @param Array list
     * @param String separator Separator to use for ranges. Defaults to "-".
     * @return Array m for example [1, 2, 3, 5, 6, 7, 8, 10, 11] would be returned as ["1-3", "5-8", 10, 11];
     */
    combineRanges: function (list, separator) {
      separator = separator || '-';
      list = list.sort(function (a, b) {
        return a - b;
      });
      var ranges = [];
      var last = null;
      var memory = null;
      var current;
      var addToRange = function () {
        if (memory === last) {
          ranges.push(last);
        } else {
          if (last - memory > 1) {
            ranges.push(String(memory) + separator + String(last));
          } else {
            ranges.push(memory);
            ranges.push(last);
          }
        }
      };

      for (var i = 0, len = list.length; i < len; i++) {
        current = list[i];

        if (last === null) {
          memory = last = current;
          continue;
        }

        if (current - last === 1) {
          last = current;
        } else {
          addToRange();
          memory = last = current;
        }
      }

      if (last != null) {
        addToRange();
      }

      return ranges;
    },

    // Return empty string if value is falsy
    stringFalsyCheck: function (value) {
      return value || '';
    },

    // Taken from Mustache.js codebase
    escapeHtml: function (string) {
      return String(string).replace(/[&<>"'\/]/g, function (s) {
        return entityMap[s];
      });
    },

    // Make the string regexp-safe
    // From https://github.com/slevithan/xregexp/
    escapeRegex: function (string) {
      return String(string).replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    },

    /**
     * Waits until the given `untilCallback` returns true and executes `thenCallback`.
     *
     * @param Function untilCallback
     * @param Function thenCallback
     */
    waitUntil: function (untilCallback, thenCallback) {
      var checkFrequency = 100;
      var checker = function () {
        if (untilCallback()) {
          thenCallback();
        } else {
          window.setTimeout(checker, checkFrequency);
        }
      };

      checker();
    },

    /**
     * Generates a UUID-formatted random(?) string.
     *
     * From http://stackoverflow.com/a/2117523
     */
    guid: function () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },

    /**
     * Checks that string passed in is alphanumeric
     */
    isAlphaNumeric: function (string) {
      var alphaNumericRegex = /^[a-z0-9+]+$/i;
      return alphaNumericRegex.test(string);
    },

    /**
     * Checks that the string contains some alpha characters
     */
    containsAlpha: function (string) {
      var containsAlphaRegex = /[a-z]/i;
      return containsAlphaRegex.test(string);
    },

    // Regexp from http://detectmobilebrowsers.com/
    isMobile: function (ua) {
      ua = ua || navigator.userAgent || navigator.vendor || window.opera;
      return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
          ua,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          ua.substr(0, 4),
        )
      );
    },

    /**
     * This function returns if the current user is browsing with a touch device.
     *
     * We can not reliably detect this. But we try.
     *
     * @return Boolean
     */
    isTouchDevice: function () {
      return Modernizr.touch;
    },

    /**
     * Parses the server error resonse and return an Array with the details.
     *
     * @param jqXHR xhr
     * @return Array or null, if parsing failed.
     */
    parseErrors: function (xhr) {
      try {
        var json = JSON.parse(xhr.responseText);
        return json.errors;
      } catch (e) {
        return null;
      }
    },

    /**
     * Takes an array of callbacks, iterates through each waiting for them to call the resolver function.
     * If all callbacks resolve with true, the returned promise is resolved.
     * If a callback resolves with false, the chain is broken, no callbacks called and promise rejected.
     * Exceptions from callbacks also reject the promise.
     *
     * @param Array callbacks
     * @return Promise
     */
    callbackChain: function callbackChain(callbacks) {
      var drd = $.Deferred();
      callbacks = _.clone(callbacks);

      function callbackRunner(resolve) {
        if (resolve === true) {
          if (callbacks.length === 0) {
            drd.resolve();
          } else {
            var callback = callbacks.shift();
            try {
              // Recursively call the runner.
              callback(callbackRunner);
            } catch (e) {
              drd.reject();
            }
          }
        } else {
          drd.reject();
        }
      }

      // Start running the callbacks
      callbackRunner(true);

      return drd.promise();
    },

    getEditingLockedHtml: function () {
      var lang = Wahanda.lang.shared.editingLocked;
      const text = shouldShowSupportPhoneNumber()
        ? Wahanda.Template.render(lang.text, {
            phone: App.config.get('channel').supplierSupportPhoneNumber,
          })
        : lang.textNoPhone;

      return Wahanda.Template.render(
        '<strong>{{title}}</strong> {{text}}',
        _.extend({}, lang, {
          text,
        }),
      );
    },

    /**
     * Check if the given API response code corresponds to a User Error code. That might need additional UI
     * handling.
     *
     * @param {String} code The response code
     * @returns {boolean} weather the response code is a "user input validation error"
     */
    isUserErrorCode: function (code) {
      var numCode = parseInt(code, 10);

      return numCode === 400 || numCode === 409;
    },
  };
})();
