/* global _ Backbone */
import CacheBase from './cache-base';
import moment from 'common/moment';
import * as findIndex from 'lodash/findIndex';
import * as flatten from 'lodash/flatten';

import storeBuilder from 'reduxStore/store';
import {
  getEmployeesWorkingHoursSuccess,
  getVenueWorkingHoursSuccess
} from 'components/shifts/store/actions';

function dateToCompare(data) {
  let date = data;
  if (!(date instanceof Date)) {
    date = Wahanda.Date.createDateIfString(data);
  }
  return Wahanda.Date.getMidnight(date).getTime();
}

(() => {
  const WorkingHoursCacheImpl = CacheBase.extend({
    constructor() {
      this.cache = {};
      this.resourceCache = {};

      this.listenTo(App, Wahanda.Event.APP_VENUE_CHANGED, this.clearCache);
      this.listenTo(App, Wahanda.Event.OPENING_HOURS_SAVED, this.clearCache);
      this.listenTo(
        App,
        Wahanda.Event.CALENDAR_WORK_TIME_SAVED,
        this.clearCache
      );

      this.fetching = null;
    },

    fetch(from, to, employeeIds) {
      if (this.alreadyFetching(from, to)) {
        return this.fetching.promise;
      }

      const dateFrom = moment(from).subtract(7, 'days');
      const dateTo = moment(to).add(20, 'days');
      const employeeIdsArray = this.getEmployeeIds(employeeIds);

      const dateFromString = moment(dateFrom).formatApiDateString();
      const dateToString =  moment(dateTo).formatApiDateString();

      const model = new App.Models.WorkingHours({
        dateFrom: dateFromString,
        dateTo: dateToString,
        employeeIds: !!employeeIds ? employeeIdsArray.join(',') : null
      });

      const self = this;
      const cache = this.cache;
      const resourceCache = this.resourceCache;

      if (employeeIdsArray) {
        this.initializeResourceCache(employeeIdsArray);
      }

      const promise = model
        .fetch()
        .done(function() {
          const modelEmployeesWorkingHours = model.get('employeesWorkingHours');
          const modelVenueWorkingHours = model.get('workingHours');

          if (modelEmployeesWorkingHours) {
            employeeIdsArray.forEach(employeeId => {
              const employeeIndex = findIndex(modelEmployeesWorkingHours, [
                'employeeId',
                employeeId
              ]);

              modelEmployeesWorkingHours[employeeIndex].workingHours.forEach(
                time => {
                  resourceCache[employeeId][time.date] = time;
                }
              );
            });
            storeBuilder().dispatch(
              getEmployeesWorkingHoursSuccess({
                datePickerRange: { dateFrom: dateFromString, dateTo: dateToString },
                employeesWorkingHours: modelEmployeesWorkingHours
              })
            );
          }

          if (modelVenueWorkingHours) {
            modelVenueWorkingHours.forEach(time => {
              cache[time.date] = time;
            });
            storeBuilder().dispatch(
              getVenueWorkingHoursSuccess({
                workingHours: modelVenueWorkingHours
              })
            );
          }
        })
        .always(() => {
          self.fetching = null;
        });

      this.fetching = {
        promise,
        from,
        to
      };

      return promise;
    },

    getEmployeeIds(employeeIds) {
      if (!employeeIds) {
        return null;
      }
      return flatten([employeeIds]);
    },

    initializeResourceCache(employeeIds) {
      const resourceCache = this.resourceCache;
      if (Object.entries(resourceCache).length === 0) {
        employeeIds.forEach(employeeId => {
          resourceCache[employeeId] = {};
        });
      }
    },

    /**
     * Checks if we are already fetching the times for given range.
     *
     * Already fetching should return TRUE only when the requested range is fully covered
     *
     * @param Date from
     * @param Date to
     * @returns Boolean
     */
    alreadyFetching(from, to) {
      if (!this.fetching) {
        return false;
      }

      return (
        dateToCompare(this.fetching.from) <= dateToCompare(from) &&
        dateToCompare(this.fetching.to) >= dateToCompare(to)
      );
    },

    /**
     * Returns true if we don't have the full range cached.
     *
     * @param String|Date from
     * @param String|Date to
     *
     * @returns Boolean
     */
    isCached(from, to, employeeIds) {
      const self = this;
      const dates = Wahanda.Date.getRangeDays(from, to);
      const employeeIdsArray = this.getEmployeeIds(employeeIds);

      if (employeeIdsArray) {
        let isCached;
        this.initializeResourceCache(employeeIdsArray);
        employeeIdsArray.forEach(employeeId => {
          isCached = _.all(dates, date => {
            return !!self.resourceCache[employeeId][
              moment(date).formatApiDateString()
            ];
          });
        });
        return isCached;
      }
      return _.all(dates, date => {
        return !!self.cache[moment(date).formatApiDateString()];
      });
    },

    /**
     * Get the WorkingHours object for the given date range.
     *
     * @param String|Date from
     * @param String|Date to
     *
     * @returns App.Models.WorkingHours
     */
    getCached(from, to, employeeIds) {
      const self = this;
      const dates = Wahanda.Date.getRangeDays(from, to);
      const employeeIdsArray = this.getEmployeeIds(employeeIds);

      return new App.Models.WorkingHours({
        dateFrom: moment(from).formatApiDateString(),
        dateTo: moment(to).formatApiDateString(),
        workingHours: dates.map(
          date => self.cache[moment(date).formatApiDateString()]
        ),
        employeesWorkingHours: self.getEmployeesWorkingHours(
          employeeIdsArray,
          dates
        )
      });
    },

    getEmployeesWorkingHours(employeeIds, dates) {
      const self = this;
      const resources = {};
      const hasEmployeeHours =
        !!employeeIds && Object.entries(self.resourceCache).length !== 0;

      if (!hasEmployeeHours) {
        return null;
      }

      employeeIds.forEach(employeeId => {
        if (!self.resourceCache[employeeId]) {
          return;
        }

        resources[employeeId] = dates.map(
          date =>
            self.resourceCache[employeeId][moment(date).formatApiDateString()]
        );
      });
      return resources;
    },

    /**
     * Get an item from the cache. Do not fetch if it isn't cached yet.
     *
     * @param Date date
     * @returns Object or null
     */
    getCachedItem(date) {
      return this.cache[moment(date).formatApiDateString()] || null;
    },

    clearCache() {
      this.cache = {};
      this.resourceCache = {};
    }
  });

  Object.assign(WorkingHoursCacheImpl.prototype, Backbone.Events);
  const WorkingHoursCache = new WorkingHoursCacheImpl();

  window.WorkingHoursCache = WorkingHoursCache;
})();
