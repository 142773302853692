import { createAction } from 'reduxStore/actionCreator';
import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  OPEN_WITHDRAWAL_DIALOG,
  OPEN_DEPOSIT_DIALOG,
  OPEN_DAY_CLOSED_NOTIFICATION_DIALOG,
} from 'reduxStore/actionsConst';
import { DialogTypes } from './types';

export const openDialog = (dialogType: DialogTypes) => createAction(OPEN_DIALOG, { dialogType });

export const closeDialog = () => createAction(CLOSE_DIALOG);

export const openWithdrawalDialog = () => createAction(OPEN_WITHDRAWAL_DIALOG);

export const openDepositDialog = () => createAction(OPEN_DEPOSIT_DIALOG);

export const openDayCloseNotificationDialog = () =>
  createAction(OPEN_DAY_CLOSED_NOTIFICATION_DIALOG);
