import React, { MouseEvent } from 'react';
import { Button } from 'components/common/Button';
import styles from './../OnboardingWidget.scss';
import { Badge } from 'components/common/Badge';
import { Chevron, Tick } from 'components/common/Icon';
import { SelfSetupTask } from 'components/shared/OnboardingWidget/models/SelfSetupTask';
import { useDisplayToastOnCompletingTask } from '../hooks/useDisplayToastOnCompletingTask';
import { navigateTo } from 'components/shared/OnboardingWidget/utils/navigateTo';
import { getTrackingByEventName } from 'src/tracking-container/TrackingEvents';
import { VerticalSteps } from 'components/common/VerticalSteps';
import { colors } from 'style/exports/colors';

const trackEvent = getTrackingByEventName('self_onboarding_task');

export const Task = ({
  index,
  open,
  onToggle,
  ...task
}: SelfSetupTask & { index: number; open?: boolean; onToggle: () => void }) => {
  const { id, title, description, steps, externalLink, isCompleted, cta } = task;

  useDisplayToastOnCompletingTask(task);

  const handleToggle = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    !open && trackEvent('dropdown_open', id);
    onToggle();
  };

  const handleCtaClick = () => {
    trackEvent('cta_click', id);
    cta.external ? window.open(cta.location, '_blank') : navigateTo(cta.location.toString());
  };

  const keyToFixReactDetailsHandling = open ? 'open' : 'close';

  return (
    <details open={open} key={keyToFixReactDetailsHandling}>
      <summary onClick={handleToggle}>
        <Badge
          type={isCompleted ? 'success' : 'muted'}
          isLight
          text={isCompleted ? '' : index}
          icon={isCompleted ? <Tick size="small" /> : undefined}
        />
        <div style={{ color: colors.colorNavy, flexGrow: 1, fontSize: 14 }}>{title}</div>
        <div
          style={{
            color: colors.colorNavy,
            transform: `rotate(${open ? '90deg' : '-90deg'}) translateY(2px)`,
            margin: 'auto',
          }}
        >
          <Chevron size="small" />
        </div>
      </summary>
      {description && <p className={styles.description}>{description}</p>}
      {steps && <VerticalSteps stepChecked={steps.stepChecked} steps={steps.steps} />}
      {externalLink && (
        <span className={styles.link}>
          🔗{' '}
          <a href={externalLink.href} target="_blank" rel="noopener noreferrer" className="link">
            {externalLink.label}
          </a>
        </span>
      )}
      <div className={styles.button}>
        <Button
          colour="default"
          label={cta.label}
          onClick={handleCtaClick}
          type="button"
          variant="primary"
          fullWidth
        />
      </div>
    </details>
  );
};
