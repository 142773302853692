import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import { receiveDiscountRulesAction, requestDiscountRulesFailureAction } from './actions';

// eslint-disable-next-line import/prefer-default-export
export function* getDiscountRulesDataRequested({ venueId }) {
  try {
    const data = yield call(api.get, `/api/venue/${venueId}/discount-rules.json`);

    yield put(receiveDiscountRulesAction(data));
  } catch (err) {
    yield put(requestDiscountRulesFailureAction(err));
  }
}
