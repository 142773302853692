import React from 'react';
import Wahanda from 'common/wahanda';
import { Currency } from 'components/common/DataFormat';
import {
  Table,
  TableColumnHeader,
  Align,
  TableRow,
  TableBody,
  TableFooter,
  TableHeader,
  TableData,
  TableLayout,
} from 'components/common/Table';
import SubHeader from '../SubHeader';
import style from './style.scss';

interface SummaryType {
  type: string;
  isRefund: boolean;
}

interface Summary {
  quantity: number;
  grossAmount: number;
  vatAmount: number;
  netAmount: number;
}

export interface Props {
  checkouts: Summary;
  refunds: Summary;
  grandTotal: Summary;
}

const LANG = Wahanda.lang.reports.posReports.salesPeriod.salesSummary;

export default class SalesSummary extends React.PureComponent<Props> {
  public render() {
    return (
      <div>
        <SubHeader text={LANG.header} />
        <Table border layout={TableLayout.FIXED}>
          <TableHeader>
            <TableRow>
              <TableColumnHeader align={Align.LEFT}>{LANG.type}</TableColumnHeader>
              <TableColumnHeader align={Align.RIGHT}>{LANG.quantity}</TableColumnHeader>
              <TableColumnHeader align={Align.RIGHT}>{LANG.net}</TableColumnHeader>
              <TableColumnHeader align={Align.RIGHT}>{LANG.vat}</TableColumnHeader>
              <TableColumnHeader align={Align.RIGHT}>{LANG.gross}</TableColumnHeader>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.renderCheckouts()}
            {this.renderRefunds()}
          </TableBody>
          <TableFooter>{this.renderFooter()}</TableFooter>
        </Table>
      </div>
    );
  }

  private renderCheckouts() {
    const { checkouts } = this.props;
    const type: SummaryType = {
      type: LANG.checkouts,
      isRefund: false,
    };
    return this.renderSummary(type, checkouts);
  }

  private renderRefunds() {
    const { refunds } = this.props;
    const type: SummaryType = {
      type: LANG.refunds,
      isRefund: true,
    };
    return this.renderSummary(type, refunds);
  }

  private renderSummary(summaryType: SummaryType, summary: Summary) {
    const { quantity, grossAmount, vatAmount, netAmount } = summary;
    return (
      <TableRow>
        <TableData align={Align.LEFT}>{summaryType.type}</TableData>
        <TableData align={Align.RIGHT}>{quantity}</TableData>
        <TableData align={Align.RIGHT}>
          <Currency alwaysNegative={summaryType.isRefund} amount={netAmount} />
        </TableData>
        <TableData align={Align.RIGHT}>
          <Currency alwaysNegative={summaryType.isRefund} amount={vatAmount} />
        </TableData>
        <TableData align={Align.RIGHT}>
          <Currency alwaysNegative={summaryType.isRefund} amount={grossAmount} />
        </TableData>
      </TableRow>
    );
  }

  private renderFooter() {
    const { quantity, netAmount, vatAmount, grossAmount } = this.props.grandTotal;
    return (
      <TableRow>
        <TableData align={Align.LEFT}>
          <div className={style.bolded}>{LANG.grandTotal}</div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>{quantity}</div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={netAmount} />
          </div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={vatAmount} />
          </div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={grossAmount} />
          </div>
        </TableData>
      </TableRow>
    );
  }
}
