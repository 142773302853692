/**
 * POS checkout quantity selector view.
 *
 * The template must be similar to the following skeleton:
  <div class="quantity">
    <span class="decrement disabled"></span>
    <span class="value">{{quantity}}</span>
    <span class="increment"></span>
  </div>
 *
 * Given a product formData model, it handles updating & displaying 
 * the quantity of a product.
 * 
 */
(function () {
  var BaseView = Backbone.View;
  App.Views.POS.QuantitySelector = BaseView.extend({
    events: {
      'click .decrement, .increment': 'updateQuantity',
    },
    templateId: 'pos-quantity-selector',

    initialize: function () {
      var self = this;
      this.model = this.options.model;
      this.formData = this.model.clone();
      this.formData.on(
        'change',
        function onQuantityChange() {
          this.updateQuantityDisplay();
        },
        this,
      );
    },

    updateQuantityDisplay: function () {
      var quantity = this.formData.get('quantity');
      this.setDecrementButtonState(quantity);
      this.$el.find('.quantity-selector .value').text(quantity);
    },

    render: function () {
      var html = Wahanda.Template.renderTemplate(this.templateId, {
        quantity: this.model.get('quantity'),
      });
      this.$el.html(html);
      this.setDecrementButtonState(this.model.get('quantity'));
    },

    setDecrementButtonState: function (quantity) {
      var $dec = this.$el.find('.quantity-selector .decrement');
      if (quantity === 1) {
        $dec.addClass('disabled');
      } else if (quantity >= 2) {
        $dec.removeClass('disabled');
      }
    },

    updateQuantity: function (event) {
      var $tgt = $(event.target);
      if (!$(event.target).hasClass('disabled')) {
        var quantity = this.formData.get('quantity');
        var delta = $tgt.hasClass('decrement') ? -1 : 1;
        var newQuantity = quantity + delta;
        this.formData.set('quantity', newQuantity, {
          silent: true,
        });
        this.formData.change();
        this.options.onQuantityChange(newQuantity);
      }
    },

    save: function () {
      this.model.set('quantity', this.formData.get('quantity'));
    },

    destroy: function () {
      this.remove();
    },
  });
})();
