import React, { PureComponent } from 'react';
import SelectDropdown from 'components/common/SelectDropdown';
import Wahanda from 'common/wahanda';
import { IntegrationSetupAnalytics } from 'common/analytics';
import style from './style.scss';

const lang = Wahanda.lang.settings.venue.integrations.selectExternalSystem;
interface IExternalSystemSelectProps extends React.HTMLAttributes<Element> {
  externalSystems: {
    id: number;
    name: string;
    key: string;
  }[];
  selected?: number;
  onSelect: (...args: any[]) => any;
}

export default class ExternalSystemSelect extends PureComponent<IExternalSystemSelectProps, {}> {
  static defaultProps = {
    selected: null,
  };

  onSelect = (id) => {
    const { externalSystems, onSelect } = this.props;
    let selectedSystemKey = null;
    if (id) {
      const selectedSystem = externalSystems.find((system) => system.id === id);
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
      selectedSystemKey = selectedSystem.key;
    }
    IntegrationSetupAnalytics.trackExternalSystemDropdownSelect(selectedSystemKey);
    onSelect(id);
  };

  render() {
    const { externalSystems, selected } = this.props;
    const options = [
      { value: null, name: lang.notSet },
      ...externalSystems.map((system) => ({
        value: system.id,
        name: system.name,
      })),
    ];
    return (
      <div className={style.selectSystem}>
        <div className={style.selectRow}>
          <div className={style.label}>{lang.linkTo}</div>
          <SelectDropdown
            data={options}
            extraClassName={style.select}
            onSelect={this.onSelect}
            onOpen={IntegrationSetupAnalytics.trackExternalSystemDropdownClick}
            selected={selected}
          />
        </div>
      </div>
    );
  }
}
