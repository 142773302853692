/* global WorkingHoursCache */
/* eslint-disable func-names */

import flatten from 'lodash/flatten';

/*
 * Working Hours for date range
 */
function getCorrectTime(strTime) {
  let time = Wahanda.Time.timeToMinutes(strTime);
  const MAX_TIME = 23 * 60 + 59;
  if (time > MAX_TIME) {
    // The time exceeds day's limit. Set it to 23:59
    time = MAX_TIME;
  }
  return Wahanda.Time.toApiString(time);
}

const getFormattedTime = (strTime) => (strTime ? `${getCorrectTime(strTime)}:00` : '00:00:00');

(() => {
  /**
   * Working Hours data model.
   */
  const WorkingHours = Backbone.Model.extend(
    {
      defaults: {
        venueId: null,
        employeeIds: null,
        dateFrom: '',
        dateTo: '',
      },

      initialize: function () {},

      urlRoot: function () {
        const employeeIds = this.get('employeeIds');
        const venueId = this.get('venueId') || App.getVenueId();
        let baseUrl;

        if (employeeIds) {
          baseUrl =
            '/venue/{{venueId}}/employees/{{employeeIds}}/working-hours.json?from={{dateFrom}}&to={{dateTo}}';
        } else {
          baseUrl = '/venue/{{venueId}}/working-hours.json?from={{dateFrom}}&to={{dateTo}}';
        }

        const urlParams = {
          employeeIds,
          venueId,
          dateFrom: this.get('dateFrom'),
          dateTo: this.get('dateTo'),
        };

        // Rendering the final URL
        const wsName = Wahanda.Template.render(baseUrl, urlParams);
        return App.Api.wsUrl(wsName);
      },

      /**
       * Returns working hours object for the given date.
       *
       * @param Date date
       * @return Object or null
       */
      getTimesByDate: function (date) {
        const strDate = Wahanda.Date.toApiString(date);
        const times = this.get('workingHours').find((time) => {
          return time && time.date === strDate;
        });
        return times || null;
      },

      /**
       * Returns in calendar format.
       * @param Array ridList List of rid's to expand data into
       * @return Array
       */
      getInCalendarFormat: function (ridList) {
        const employeeIdsArray = flatten([ridList]);
        const list = employeeIdsArray || [];
        const formatted = [];

        list.forEach((rid) => {
          const times = this.get('employeesWorkingHours')[rid] || this.get('workingHours');
          times
            .filter(({ date }) => Boolean(date))
            .map(({ date, timeSlots }) => {
              const firstTimeSlot = timeSlots?.[0];
              const startTime = getFormattedTime(firstTimeSlot?.timeFrom);
              const endTime = getFormattedTime(firstTimeSlot?.timeTo);
              return {
                start: Wahanda.Date.parse(`${date}T${startTime}`),
                end: Wahanda.Date.parse(`${date}T${endTime}`),
              };
            })
            .forEach(({ start, end }) => formatted.push({ start, end, rid }));
        });

        return formatted;
      },
    },
    {
      /**
       * Get the start and end times for calendar of the venue for the given date.
       *
       * If the venue does not work on that day, returns the fallback - default day start/end times.
       *
       * @param {Date} date The date to get information for
       *
       * @returns {opens, closes} The object with opening and closing times
       */
      getVisibleCalendarTimeRangeForDate: function (date) {
        const openingTimesDataForDate = WorkingHoursCache.getCachedItem(date);
        const defaultWorkTime = App.config.getVenueWorkTime();

        if (openingTimesDataForDate && openingTimesDataForDate.timeSlots.length) {
          const timeSlot = openingTimesDataForDate.timeSlots[0];
          return {
            opens: Math.min(defaultWorkTime.opens, Wahanda.Time.timeToMinutes(timeSlot.timeFrom)),
            closes: Math.max(defaultWorkTime.closes, Wahanda.Time.timeToMinutes(timeSlot.timeTo)),
          };
        }

        return defaultWorkTime;
      },

      getVisibleCalendarTimeChoicesForDate: function (date) {
        const workTime = WorkingHours.getVisibleCalendarTimeRangeForDate(date);

        return Wahanda.Time.getVenueWorkTimeHours({
          workStart: workTime.opens,
          workEnd: workTime.closes,
        });
      },
    },
  );

  // Export
  App.Models.WorkingHours = WorkingHours;
})();
