import { trackEvent, ACTIONS } from 'common/analytics';

const CATEGORY = 'facebook-business-extension';

export const FacebookBusinessExtensionAnalytics = {
  trackReintegrateStepOne: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'reintegrate-step-1');
  },
  trackReintegrateStepTwo: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'reintegrate-step-2');
  },
  trackReintegrateStepThree: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'reintegrate-step-3');
  },
};
