/* global Backbone, _ */

import { AppEvent } from './AppEvents';

/**
 * List of global events.
 */
Wahanda.Event = {
  ...AppEvent,
};

/**
 * Creates a new mediator object on which other objects can trigger and listen to events.
 *
 * @param Object baseObject (optional) Object on which to attach the event functionality.
 *
 * @returns Object
 */
Wahanda.Event.createMediator = function createMediator(baseObject) {
  return _.extend(baseObject || {}, Backbone.Events);
};
