import React from 'react';
import Wahanda from 'common/wahanda';
import { Verification } from 'components/common/Icon';
import style from './PaymentProtectionBanner.scss';

export const PaymentProtectionBanner = () => {
  return (
    <div className={style.banner}>
      <Verification className={style.icon} />
      <div>{Wahanda.lang.paymentProtection.banner.message}</div>
    </div>
  );
};
