import React from 'react';
import Wahanda from 'common/wahanda';
import { generatePath } from 'react-router-dom';
import { EmailCampaignPreviewAnalytics as Analytics } from 'common/analytics';
import Loader from 'components/common/Loader';
import { Button } from 'components/common/Button';
import { LayoutContainer, LayoutHeader } from 'components/common/Layout';

import EmailTemplate from './EmailTemplate';
import PATHS from '../../Router/paths';
import { fieldConfig } from './config';

import { GENERIC_MARKETING_CAMPAIGN_ID, APP_FOR_YOUR_CLIENTS_CAMPAIGN_ID } from '../CampaignList';

import {
  SMART_DISCOUNTS_PROMO_OFFER,
  SERVICE_ON_SALE_PROMO_OFFER,
} from '../CampaignSelection/PromotionalOffers/PromotionTypeSelect/promoTypes';

import style from './CampaignPreview.scss';

const lang = Wahanda.lang.marketing.preview;
interface ICampaignPreviewProps extends React.HTMLAttributes<Element> {
  selectedDiscountRuleId?: number;
  selectedTreatmentGroupId?: number;
  referrer?: string;
  marketingEmailTemplate: {
    template?: {
      html?: string;
      subject?: string;
      message?: string;
      title?: string;
      signature?: string;
    };
    modifiedTemplate?: {
      html?: string;
      subject?: string;
      message?: string;
      title?: string;
      signature?: string;
    };
    editing?: string;
  };
  history: {
    push: (...args: any[]) => any;
  };
  actions: {
    getEmailTemplateRequest: (...args: any[]) => any;
    getSmartDiscountsTemplateRequest: (...args: any[]) => any;
    getServiceOnSaleTemplateRequest: (...args: any[]) => any;
    getAppForYourClientTemplateRequest: (...args: any[]) => any;
    editMarketingEmailContent: (...args: any[]) => any;
    storeMarketingEmailContent: (...args: any[]) => any;
    emailTemplateReset: (...args: any[]) => any;
    selectTreatmentGroup: (...args: any[]) => any;
  };
  match: {
    params?: {
      templateId?: string;
    };
  };
  params?: any;
  push?: any;
  emailTemplateReset?: any;
  getAppForYourClientTemplateRequest?: any;
  getEmailTemplateRequest?: any;
  getServiceOnSaleTemplateRequest?: any;
  selectTreatmentGroup?: any;
  getSmartDiscountsTemplateRequest?: any;
  storeMarketingEmailContent?: any;
  editMarketingEmailContent?: any;
}

export class CampaignPreview extends React.Component<ICampaignPreviewProps, {}> {
  constructor(props) {
    super(props);
    this.goToAudiencePage = this.goToAudiencePage.bind(this);
    this.trackOpenWidgetSettings = this.trackOpenWidgetSettings.bind(this);
    this.goToTemplateSelection = this.goToTemplateSelection.bind(this);
    this.getEmailTemplate = this.getEmailTemplate.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.onFieldClick = this.onFieldClick.bind(this);
  }

  public static defaultProps = {
    selectedDiscountRuleId: null,
    selectedTreatmentGroupId: null,
    referrer: undefined,
  };

  public componentDidMount() {
    Analytics.trackPageView(this.props.referrer);
    if (!this.props.marketingEmailTemplate.template) {
      this.getEmailTemplate();
    }
  }

  public onFieldClick(field) {
    this.props.actions.editMarketingEmailContent(field);
  }

  public onFieldBlur(field, value) {
    const { modifiedTemplate } = this.props.marketingEmailTemplate;
    this.props.actions.storeMarketingEmailContent({
      ...modifiedTemplate,
      [field]: value,
    });
  }

  public getEmailTemplate = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'templateId' does not exist on type '{ te... Remove this comment to see the full error message
    const { templateId } = this.props.match.params;
    switch (templateId) {
      case SMART_DISCOUNTS_PROMO_OFFER:
        this.getSmartDiscountTemplate();
        break;
      case SERVICE_ON_SALE_PROMO_OFFER:
        this.getServiceOnSaleTemplate();
        break;
      case GENERIC_MARKETING_CAMPAIGN_ID:
        this.getMassEmailTemplate();
        break;
      case APP_FOR_YOUR_CLIENTS_CAMPAIGN_ID:
        this.getAppForYourClientsTemplate();
        break;
      default:
        this.goToTemplateSelection();
        break;
    }
  };

  public getSmartDiscountTemplate() {
    // Redirect if no selected discount rule id:
    if (!this.props.selectedDiscountRuleId) {
      this.props.history.push(generatePath(PATHS.CAMPAIGN_SELECTION));
      return;
    }
    this.props.actions.getSmartDiscountsTemplateRequest({
      discountRuleId: this.props.selectedDiscountRuleId,
    });
  }

  public getServiceOnSaleTemplate() {
    // Redirect if no treatment group selected:
    if (!this.props.selectedTreatmentGroupId) {
      this.props.history.push(generatePath(PATHS.CAMPAIGN_SELECTION));
      return;
    }
    this.props.actions.selectTreatmentGroup({
      selectedTreatmentGroupId: this.props.selectedTreatmentGroupId,
    });
    this.props.actions.getServiceOnSaleTemplateRequest({
      treatmentGroupId: this.props.selectedTreatmentGroupId,
    });
  }

  public getMassEmailTemplate = () => {
    this.props.actions.getEmailTemplateRequest();
  };

  public getAppForYourClientsTemplate = () => {
    this.props.actions.getAppForYourClientTemplateRequest();
  };

  public getPreviewDescription() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'templateId' does not exist on type '{ te... Remove this comment to see the full error message
    const { templateId } = this.props.match.params;

    if ([APP_FOR_YOUR_CLIENTS_CAMPAIGN_ID, GENERIC_MARKETING_CAMPAIGN_ID].includes(templateId)) {
      return null;
    }
    return (
      <p>
        {lang.descriptionMain}
        <a
          className={style.learnMore}
          href="/settings#online-booking/online-booking-widget"
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.trackOpenWidgetSettings}
        >
          {lang.linkText}
        </a>
      </p>
    );
  }

  public goToTemplateSelection() {
    Analytics.trackBackBtnClick();
    this.props.actions.emailTemplateReset();
    this.props.history.push(generatePath(PATHS.CAMPAIGN_SELECTION));
  }

  public goToAudiencePage() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'templateId' does not exist on type '{ te... Remove this comment to see the full error message
    const { templateId } = this.props.match.params;
    Analytics.trackNextBtnClick();
    switch (templateId) {
      case SMART_DISCOUNTS_PROMO_OFFER:
        this.props.history.push(
          generatePath(PATHS.CAMPAIGN_SUMMARY_SMART_DISCOUNTS, {
            templateId,
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
            selectedDiscountRuleId: this.props.selectedDiscountRuleId,
          }),
        );
        break;
      case SERVICE_ON_SALE_PROMO_OFFER:
        this.props.history.push(
          generatePath(PATHS.CAMPAIGN_SUMMARY_SERVICE_ON_SALE, {
            templateId,
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
            selectedTreatmentGroupId: this.props.selectedTreatmentGroupId,
          }),
        );
        break;
      default:
        this.props.history.push(generatePath(PATHS.CAMPAIGN_SUMMARY_DEFAULT, { templateId }));
        break;
    }
  }

  public trackOpenWidgetSettings() {
    Analytics.trackOpenWidgetSettings();
  }

  public render() {
    const { marketingEmailTemplate } = this.props;
    return !marketingEmailTemplate.template ? (
      <Loader positionAbsolute />
    ) : (
      <LayoutContainer
        footer={
          <div className={style.buttons}>
            <Button
              label={lang.backwardButton}
              variant="secondary"
              colour="plain"
              data-test="button-campaign-previous"
              onClick={this.goToTemplateSelection}
            />
            <Button
              label={lang.forwardButton}
              onClick={this.goToAudiencePage}
              data-test="button-campaign-next"
            />
          </div>
        }
      >
        <LayoutHeader
          header={lang.title}
          subheader={
            <div className={style.description}>
              <p>{lang.descriptionIntro}</p>
              {this.getPreviewDescription()}
            </div>
          }
        />
        <EmailTemplate
          content={marketingEmailTemplate}
          config={fieldConfig}
          onFieldBlur={this.onFieldBlur}
          onFieldClick={this.onFieldClick}
        />
      </LayoutContainer>
    );
  }
}
