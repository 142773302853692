import Cookie from 'js-cookie';

export default function getAuthData() {
  const cookies = Cookie.withConverter((value) =>
    window.decodeURIComponent(value.replace(/\+/g, ' ')),
  ).get();

  const authCookies = {};

  Object.keys(cookies).forEach((name) => {
    if (/ATKT|ITKT/i.test(name)) {
      authCookies[name] = cookies[name];
    }
  });

  return authCookies;
}
