import React, { Component, createRef } from 'react';
import Formsy from 'formsy-react';
import Wahanda from 'common/wahanda';
import ROLES from 'common/constants/userRoles';
import { trackEvent } from 'common/analytics';
import ReactDialog from 'components/common/react-dialog';
import {
  DialogFooter,
  DialogFooterButton,
  DialogFooterGroup,
  DialogFooterGroupItem,
} from 'components/common/DialogFooter';
import Tab from 'components/common/dialog/TabSet/Tab';
import TabSet from 'components/common/dialog/TabSet';
import TabBody from 'components/common/dialog/TabSet/TabBody';
import TabHead from 'components/common/dialog/TabSet/TabHead';
import Loader from 'components/common/Loader';
import { DialogConfirmation } from 'components/common/dialog/DialogConfirmation';
import { EmployeeInformationTab } from './EmployeeInformationTab/container';
import PricingAndServicesTab from './PricingAndServicesTab/container';
import PublicProfileTab from './PublicProfileTab/container';
import ExternalCalendarTab from './ExternalCalendarTab/container';
import NoServicesAssignedConfirm from './NoServicesAssignedConfirm';
import style from './style.scss';
import App from 'common/backbone-app';

const DIALOG_WIDTH = 600;
const lang = Wahanda.lang.settings.employees.employee;
const INFORMATION_TAB = 'INFORMATION_TAB';
const PRICING_AND_SERVICES_TAB = 'PRICING_AND_SERVICES_TAB';
const PUBLIC_PROFILE_TAB = 'PUBLIC_PROFILE_TAB';
const EXTERNAL_CALENDAR_TAB = 'EXTERNAL_CALENDAR_TAB';

type FormTab = typeof INFORMATION_TAB | typeof PUBLIC_PROFILE_TAB;

interface IEmployeeDialogProps extends React.HTMLAttributes<Element> {
  id?: string;
  isLoaded?: boolean;
  active?: boolean;
  canLogin?: boolean;
  takesAppointments?: boolean;
  emailAddress?: string;
  previousEmailAddress?: string;
  loading?: boolean;
  submitting?: boolean;
  serverError?: boolean;
  selectedMenuItemIds: number[];
  onClose: (...args: any[]) => any;
  onSubmit: (...args: any[]) => any;
  onError: (...args: any[]) => any;
  onPasswordReset: (...args: any[]) => any;
  actions: {
    deleteEmployeeAction: (...args: any[]) => any;
    createEmployeeAction: (...args: any[]) => any;
    setEmployeeDataAction: (...args: any[]) => any;
    submitEmployeeAction: (...args: any[]) => any;
    requestEmployeeAction: (...args: any[]) => any;
    resetEmployeeDataAction: (...args: any[]) => any;
  };
  deleteEmployeeAction?: any;
  submitEmployeeAction?: any;
  setEmployeeDataAction?: any;
  createEmployeeAction?: any;
  requestEmployeeAction?: any;
  resetEmployeeDataAction?: any;
}
type EmployeeDialogState = {
  isFormValid?: boolean;
  isInformationTabValid?: boolean;
  isPublicProfileTabValid?: boolean;
  deleteConfirmDialogVisible?: boolean;
  noServicesAssignedConfirmDialogVisible?: boolean;
  selectedTab?: any;
};

export class EmployeeDialog extends Component<IEmployeeDialogProps, EmployeeDialogState> {
  informationFormRef = createRef<typeof Formsy>();
  publicProfileFormRef = createRef<typeof Formsy>();

  static defaultProps = {
    id: null,
    isLoaded: false,
    active: false,
    canLogin: false,
    takesAppointments: false,
    emailAddress: null,
    previousEmailAddress: '',
    selectedMenuItemIds: [],
    loading: false,
    submitting: false,
    serverError: false,
  } as any;

  constructor(props) {
    super(props);
    this.props.actions.resetEmployeeDataAction();
  }

  state = {
    selectedTab: INFORMATION_TAB,
    isFormValid: true,
    isInformationTabValid: true,
    isPublicProfileTabValid: true,
    deleteConfirmDialogVisible: false,
    noServicesAssignedConfirmDialogVisible: false,
  };

  componentDidMount() {
    trackEvent('team-member', 'view');
    if (!this.isNew()) {
      this.props.actions.requestEmployeeAction(this.props.id);
    } else {
      this.props.actions.createEmployeeAction();
      this.props.actions.setEmployeeDataAction({
        takesAppointments: true,
        role: ROLES.THERAPIST,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { canLogin, isLoaded, submitting, serverError } = this.props;
    if (prevProps.submitting && !submitting && !serverError) {
      this.props.onClose();
    }
    if (prevProps.submitting && !submitting && serverError) {
      this.props.onError();
    }
    if (!canLogin && prevProps.isLoaded !== isLoaded && isLoaded) {
      this.props.actions.setEmployeeDataAction({ role: ROLES.THERAPIST });
    }
  }

  onValid = ({ tab }: { tab: FormTab }) =>
    this.setState(
      {
        INFORMATION_TAB: {
          isFormValid: this.state.isPublicProfileTabValid,
          isInformationTabValid: true,
        },
        PUBLIC_PROFILE_TAB: {
          isFormValid: this.state.isInformationTabValid,
          isPublicProfileTabValid: true,
        },
      }[tab],
    );

  onInvalid = ({ tab }: { tab: FormTab }) =>
    this.setState(
      {
        INFORMATION_TAB: {
          isFormValid: false,
          isInformationTabValid: false,
        },
        PUBLIC_PROFILE_TAB: {
          isFormValid: false,
          isPublicProfileTabValid: false,
        },
      }[tab],
    );

  onDeleteButtonClick = () => {
    this.setState({ deleteConfirmDialogVisible: true });
  };

  onDeleteConfirmDialogClose = () => {
    this.setState({ deleteConfirmDialogVisible: false });
  };

  onNoServicesAssignedConfirmDialogClose = () => {
    this.setState({ noServicesAssignedConfirmDialogVisible: false });
  };

  onAssignServicesClick = () => {
    this.onNoServicesAssignedConfirmDialogClose();
    this.onTabClick(PRICING_AND_SERVICES_TAB);
  };

  onSubmit = () => {
    const { id, previousEmailAddress, emailAddress, active, selectedMenuItemIds } = this.props;
    if (!this.validateForm()) {
      return;
    }
    const emailChanged = !this.isNew() && previousEmailAddress !== emailAddress;
    const added = this.isNew();
    this.props.actions.setEmployeeDataAction({
      employeeOffers: selectedMenuItemIds,
    });
    this.props.actions.submitEmployeeAction();
    this.props.onSubmit({
      unarchived: !active,
      added,
      emailChanged,
    });
    if (emailChanged) {
      trackEvent('connect-employee', 'account-email-changed', id);
    }
    if (added || emailChanged) {
      trackEvent('connect-employee', 'extranet-account-created', id);
    }
    trackEvent('team-member', 'submit', 'save-member-details');
    this.onNoServicesAssignedConfirmDialogClose();
  };

  onTabClick = (selectedTab) => {
    if (selectedTab === EXTERNAL_CALENDAR_TAB) {
      trackEvent('team-member', 'click', 'external-calendar-link');
    }
    this.setState({
      selectedTab,
    });
  };

  showNoServicesAssignedConfirmDialog = () => {
    if (this.validateForm()) {
      this.setState({ noServicesAssignedConfirmDialogVisible: true });
    }
  };

  validateForm = () => {
    this.informationFormRef.current.submit();

    // Note: profile tab is shown only when showOnlineProfileTab is true, otherwise component will not mount and ref will be null
    this.publicProfileFormRef.current?.submit();

    this.informationFormRef.current.inputs.forEach((input) => {
      if (!input.isValid() && input.getValue() === '') {
        trackEvent('team-member', 'error', `${input.props.name}-required`);
      }
    });
    return this.state.isFormValid;
  };

  deleteEmployee = () => {
    this.props.actions.deleteEmployeeAction(this.props.id);
    this.props.onSubmit({ archived: true });
    this.onDeleteConfirmDialogClose();
  };

  closeDialog = () => {
    this.props.onClose();
  };

  isNew = () => this.props.id == null;

  renderDeleteConfirmDialog = () => (
    <DialogConfirmation
      dataTest="employee-delete-confirmation-modal"
      title={lang.deleteConfirmationTitle}
      text={lang.deleteConfirmation}
      buttonTitle={Wahanda.lang.shared.buttons.delete}
      buttonColor="red"
      onButtonClick={this.deleteEmployee}
      onClose={this.onDeleteConfirmDialogClose}
    />
  );

  renderNoServicesAssignedConfirmDialogVisible = () => (
    <NoServicesAssignedConfirm
      onAssignServicesClick={this.onAssignServicesClick}
      onSaveClick={this.onSubmit}
      onClose={this.onNoServicesAssignedConfirmDialogClose}
    />
  );

  renderTabHead = (name, label, error?) => {
    const { selectedTab } = this.state;
    return (
      <Tab
        tab={name}
        label={label}
        onClick={this.onTabClick}
        selectedTab={selectedTab}
        error={error}
      />
    );
  };

  renderTabBody = (name, content) => {
    const { selectedTab } = this.state;
    return (
      <TabBody tab={name} selectedTab={selectedTab}>
        <div className={style.tabContent}>{content}</div>
      </TabBody>
    );
  };

  renderContent = () => {
    const venue = App && App.config && App.config.get('venue');

    const isExternalCalendarEnabled =
      venue.externalCalendarEnabled ||
      // null & undefined are allowed in order to guarantee backwards compatibility
      venue.externalCalendarEnabled === null ||
      venue.externalCalendarEnabled === undefined;

    const { takesAppointments } = this.props;
    const showOnlineProfileTab = takesAppointments;
    const showPricingAndServicesTab = takesAppointments;
    const showExternalCalendarTab = takesAppointments && !this.isNew() && isExternalCalendarEnabled;
    const showInformationTabError = !this.state.isInformationTabValid;
    const showPublicProfileTabError = !this.state.isPublicProfileTabValid;
    const showLoader = (this.props.loading || !this.props.isLoaded) && !this.isNew();
    return (
      <div className={style.dialogContent}>
        <TabSet>
          <TabHead>
            {this.renderTabHead(INFORMATION_TAB, lang.tabs.basicInfo, showInformationTabError)}
            {showPricingAndServicesTab &&
              this.renderTabHead(PRICING_AND_SERVICES_TAB, lang.tabs.pricingAndServices)}
            {showOnlineProfileTab &&
              this.renderTabHead(
                PUBLIC_PROFILE_TAB,
                lang.tabs.publicProfile,
                showPublicProfileTabError,
              )}
            {showExternalCalendarTab &&
              this.renderTabHead(EXTERNAL_CALENDAR_TAB, lang.tabs.externalCalendarTab)}
          </TabHead>
          {this.renderTabBody(
            INFORMATION_TAB,
            <Formsy
              ref={this.informationFormRef}
              onValid={() => this.onValid({ tab: INFORMATION_TAB })}
              onInvalid={() => this.onInvalid({ tab: INFORMATION_TAB })}
            >
              <EmployeeInformationTab />
            </Formsy>,
          )}
          {showPricingAndServicesTab &&
            this.renderTabBody(PRICING_AND_SERVICES_TAB, <PricingAndServicesTab />)}
          {showOnlineProfileTab &&
            this.renderTabBody(
              PUBLIC_PROFILE_TAB,
              <Formsy
                ref={this.publicProfileFormRef}
                onValid={() => this.onValid({ tab: PUBLIC_PROFILE_TAB })}
                onInvalid={() => this.onInvalid({ tab: PUBLIC_PROFILE_TAB })}
              >
                <PublicProfileTab />
              </Formsy>,
            )}
          {showExternalCalendarTab &&
            this.renderTabBody(EXTERNAL_CALENDAR_TAB, <ExternalCalendarTab />)}
        </TabSet>
        {showLoader && <Loader positionAbsolute />}
      </div>
    );
  };

  renderFooter = () => {
    const {
      emailAddress,
      previousEmailAddress,
      active,
      canLogin,
      onPasswordReset,
      takesAppointments,
      selectedMenuItemIds,
      loading,
    } = this.props;
    const showPasswordResetButton =
      !this.isNew() && canLogin && active && previousEmailAddress === emailAddress;
    const showDeleteButton = !this.isNew() && active;
    const cancelButton = (
      <DialogFooterButton
        title={Wahanda.lang.shared.buttons.cancel}
        type="secondary"
        onClick={this.closeDialog}
      />
    );
    const passwordResetButton = (
      <DialogFooterGroupItem title={lang.buttons.sendPassword} onClick={onPasswordReset} />
    );
    const deleteButton = (
      <DialogFooterButton
        title={Wahanda.lang.shared.buttons.delete}
        type="destructive"
        onClick={this.onDeleteButtonClick}
      />
    );
    const onSubmitButtonClick =
      takesAppointments && selectedMenuItemIds.length === 0
        ? this.showNoServicesAssignedConfirmDialog
        : this.onSubmit;
    const submitButton = (
      <DialogFooterButton
        title={active ? Wahanda.lang.shared.buttons.save : lang.buttons.unarchive}
        disabled={loading}
        onClick={onSubmitButtonClick}
      />
    );
    return (
      <DialogFooter>
        {cancelButton}
        {showDeleteButton && deleteButton}
        {showPasswordResetButton && (
          <DialogFooterGroup>{showPasswordResetButton && passwordResetButton}</DialogFooterGroup>
        )}
        {submitButton}
      </DialogFooter>
    );
  };

  render() {
    return (
      <ReactDialog
        dataTest="employee-modal"
        title={lang.title}
        width={DIALOG_WIDTH}
        onClose={this.closeDialog}
        noContentPadding
        noContentTopBorders
      >
        {this.renderContent()}
        {this.renderFooter()}
        {this.state.deleteConfirmDialogVisible && this.renderDeleteConfirmDialog()}
        {this.state.noServicesAssignedConfirmDialogVisible &&
          this.renderNoServicesAssignedConfirmDialogVisible()}
      </ReactDialog>
    );
  }
}
