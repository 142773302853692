import { createSelector } from 'reselect';
import {
  allFullTimeEmployees,
  allChairRenters,
} from 'components/reports/newSalesReports/EmployeeFilter';
import { EMPLOYMENT_STATUS } from 'common/constants/employmentStatus';

/**
 * This value is used if the user is selecting an employee category
 * and that category has no employees. In that case we would provide
 * the backend with an empty array of employees and the backend will return
 * all values from the endpoint, not an empty list.
 *
 * a -1 results the backed in returning an empty state value for the endpoint.
 */
export const FILTER_FOR_EMPTY_RESPONSE = -1;

export const clientAcquisitionSelector = (state) => state.salesReport.acquisitionReport.reportData;

export const firstTimeClientsSelector = (state) => {
  if (!state.salesReport.rettentionReport.reportData) {
    return null;
  }
  return state.salesReport.rettentionReport.reportData.firstTimeClients;
};

export const clientRetentionSelector = (state) => {
  if (!state.salesReport.rettentionReport.reportData) {
    return null;
  }
  return state.salesReport.rettentionReport.reportData.retention;
};

export const getEmployees = (state) => {
  return state.salesReport.filterData.employees;
};

export const hasEmployees = (state) => {
  return !!getEmployees(state).length;
};

const getSelectedEmployeeFilter = (_, { employmentStatusOrId }) => employmentStatusOrId;

export const getEmployeesWith = createSelector(
  getEmployees,
  getSelectedEmployeeFilter,
  (employees, employmentStatusOrId) => {
    const result = employees.filter((employee) => {
      switch (employmentStatusOrId) {
        case allFullTimeEmployees:
          return employee.employmentStatus === EMPLOYMENT_STATUS.FULL_TIME;
        case allChairRenters:
          return employee.employmentStatus === EMPLOYMENT_STATUS.SELF_EMPLOYED;
        default:
          return employee.id === employmentStatusOrId;
      }
    });
    return result;
  },
);

export const getEmployeeIdListForReports = createSelector(
  getEmployeesWith,
  getSelectedEmployeeFilter,
  (employees, selectedEmployeeFilter) => {
    const isFilteringEmployeesByCategory = () => {
      return [allFullTimeEmployees, allChairRenters].some(
        (option) => option === selectedEmployeeFilter,
      );
    };
    if (isFilteringEmployeesByCategory() && !employees.length) {
      return [FILTER_FOR_EMPTY_RESPONSE];
    }
    return employees.map(({ id }) => id);
  },
);
