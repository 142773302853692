import React, { MouseEvent, useState, useRef } from 'react';
import classnames from 'classnames';
import {
  Popover,
  PopoverPlacement,
  PopoverAnchorOffset,
  ARROW_SIZE,
  ARROW_NUDGE,
} from 'components/common/Popover';
import { Button, ButtonProps } from 'components/common/Button';
import { Chevron } from 'components/common/Icon';

import style from './ButtonDropdown.scss';

interface Props extends Partial<ButtonProps> {
  items?: (ButtonDropdownLink | undefined)[];
  popoverPlacement?:
    | PopoverPlacement.bottomStart
    | PopoverPlacement.bottomEnd
    | PopoverPlacement.topStart
    | PopoverPlacement.topEnd;
}

export interface ButtonDropdownLink {
  title: string;
  colour?: ButtonProps['colour'];
  onClick: (ev?: MouseEvent) => void;
  dataTest?: string;
  icon?: React.ReactNode;
}

export const ButtonDropdown = ({
  popoverPlacement = PopoverPlacement.bottomStart,
  items = [],
  onClick,
  ...buttonProps
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [popoverOffset, setPopoverOffset] = useState<PopoverAnchorOffset>({
    top: 0,
    left: 0,
    minWidth: 0,
  });

  const showDropdown = (targetElement?: EventTarget & Element) => {
    if (!targetElement) {
      return;
    }

    const { top, left, width } = targetElement.getBoundingClientRect();
    const contentRect = contentRef?.current?.getBoundingClientRect();
    const arrowOffset = ARROW_SIZE / 2 + ARROW_NUDGE;
    const contentWidth = contentRect?.width || 0;

    const vOffset = [PopoverPlacement.bottomStart, PopoverPlacement.bottomEnd].includes(
      popoverPlacement,
    )
      ? top + targetElement.getBoundingClientRect().height
      : top;

    const hOffset = [PopoverPlacement.bottomStart, PopoverPlacement.topStart].includes(
      popoverPlacement,
    )
      ? left + arrowOffset
      : left - arrowOffset - contentWidth + width;

    setIsOpen(true);
    setPopoverOffset({ top: vOffset, left: hOffset, minWidth: width });

    if (onClick) {
      onClick();
    }
  };

  const hideDropdown = () => {
    setIsOpen(false);
  };

  const handleClick = (ev?: MouseEvent) => {
    if (isOpen) {
      hideDropdown();
    } else {
      showDropdown(ev?.currentTarget);
    }
  };

  const handleItemClick = (item: ButtonDropdownLink): ((ev?: MouseEvent) => void) => {
    return (ev?: MouseEvent) => {
      hideDropdown();

      if (item.onClick) {
        item.onClick(ev);
      }
    };
  };

  const renderDropdownItem = (item?: ButtonDropdownLink) => {
    if (!item) {
      return undefined;
    }
    const colour = item.colour || 'plain';

    return (
      <a
        key={item.title}
        role="button"
        tabIndex={0}
        className={classnames(style.buttonDropdownLink, style[colour])}
        onClick={handleItemClick(item)}
        data-test={item.dataTest}
      >
        {item.icon && <span className={style.icon}>{item.icon}</span>}
        {item.title}
      </a>
    );
  };

  return (
    <>
      <Button
        {...buttonProps}
        onClick={handleClick}
        suffixIcon={<Chevron size="small" className={style.chevron} />}
      />
      {isOpen && (
        <Popover
          open
          hideOnOutsideClick
          noPadding
          showClose={false}
          offset={popoverOffset}
          placement={popoverPlacement}
          onClose={handleClick}
          autoWidth
        >
          <div
            className={style.buttonDropdownLinkWrapper}
            ref={contentRef}
            style={{ minWidth: popoverOffset.minWidth }}
          >
            {items.map(renderDropdownItem)}
          </div>
        </Popover>
      )}
    </>
  );
};
