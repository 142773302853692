import React from 'react';
import { ClientFormsCreateStepLayout } from './ClientFormsCreateStepLayout';
import { RichTextEditor } from 'components/common/RichTextEditor';
import { Input } from 'components/common/Input';
import formStyles from './ClientFormsCreate.scss';
import { InputTextValue } from 'components/common/__BaseCommon';
import Wahanda from 'common/wahanda';
import { ClientFormsCreatePersonalInformation } from './ClientFormsCreatePersonalInformation';
import { Notice } from 'components/common/Notice';

const lang = Wahanda.lang.marketing.clientForms;

const SAME_NAME_LABEL = lang.alreadyExistError;

const TITLE_INPUT_LABEL = lang.formTitleInputLabel;
const STEP_NUMBER = 1;
const STEP_TITLE = lang.createFormTitle;
const STEP_DESCRIPTION = lang.createFormDescription;
const DISCLAIMER_TEXT = lang.disclaimerText;

export const ClientFormsCreateStep1 = ({
  formTitle,
  onChangeFormTitle,
  htmlDescription,
  onChangeHtmlDescription,
  formNameAlreadyInUse,
}: {
  formTitle: InputTextValue;
  onChangeFormTitle: (title: InputTextValue) => void;
  htmlDescription: string;
  onChangeHtmlDescription: (description: string) => void;
  formNameAlreadyInUse: boolean;
}): JSX.Element => {
  return (
    <ClientFormsCreateStepLayout
      stepNumber={STEP_NUMBER}
      stepTitle={STEP_TITLE}
      stepDescription={STEP_DESCRIPTION}
    >
      <div className={formStyles.formWrapper}>
        <h3>{STEP_TITLE}</h3>
        <div className={formStyles.inputWrapper}>
          <div className={formStyles.inputs}>
            <Input
              name="title"
              label={TITLE_INPUT_LABEL}
              fullBorder
              maxLength={100}
              errorMessage={SAME_NAME_LABEL}
              required
              dataTest="client-forms-title-input"
              value={formTitle}
              onChange={onChangeFormTitle}
              newLook
              hasError={formNameAlreadyInUse}
            />
          </div>
          <div className={formStyles.richTextWrapper}>
            <RichTextEditor
              name="htmlText"
              text={htmlDescription}
              dataTest="client-forms-html-text-editor"
              fullBorder
              fullWidth
              onTextChanged={onChangeHtmlDescription}
              newLook
            />
          </div>
          <div className={formStyles.richTextNoticeWrapper}>
            <Notice message="Note: form content is not editable once saved" type="expose" />
          </div>
          <ClientFormsCreatePersonalInformation />
        </div>
        <div className={formStyles.disclaimer}>
          <b>Disclaimer: </b> {DISCLAIMER_TEXT}
        </div>
      </div>
    </ClientFormsCreateStepLayout>
  );
};
