import React from 'react';
import { GoTo, Direction } from 'components/common/GoTo';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import style from './style.scss';

class SalesReport extends React.PureComponent {
  private myRef: React.RefObject<HTMLDivElement>;

  private reportView: any;

  constructor(props) {
    super(props);
    this.myRef = React.createRef<HTMLDivElement>();
  }

  public componentDidMount() {
    this.reportView = new App.Views.Reports.SalesReport({
      el: this.myRef.current,
      model: new App.Models.SalesReport(),
    });
    this.reportView.render();
    this.reportView.trigger('visible');
  }

  public render() {
    return (
      <div ref={this.myRef} id="sales" className="section-main ui-tabs-hide">
        <div className="report-actions">
          <div className="date-range-selector">
            <div className="current">
              <div className="icon icons-date-selector" />
              <div className="value" />
              <div className="arrow icons-arrow-bottom2" />
            </div>

            <div className="selection hidden">
              <div className="title">
                <div className="icon icons-date-selector" />
                <div className="value">
                  {Wahanda.lang.reports.salesReports.dateRange.labels.selectRange}
                </div>
              </div>

              <form>
                <div className="period-granularity">
                  <label>
                    <input type="radio" name="period-granularity" value="day" defaultChecked />
                    {Wahanda.lang.reports.salesReports.dateRange.labels.day}
                  </label>
                  <label>
                    <input type="radio" name="period-granularity" value="week" />
                    {Wahanda.lang.reports.salesReports.dateRange.labels.week}
                  </label>
                  <label>
                    <input type="radio" name="period-granularity" value="month" />
                    {Wahanda.lang.reports.salesReports.dateRange.labels.month}
                  </label>
                </div>

                <div className="date">
                  <label>
                    <span className="v-day">
                      {Wahanda.lang.reports.salesReports.dateRange.dateLabel.day}
                    </span>
                    <span className="v-week">
                      {Wahanda.lang.reports.salesReports.dateRange.dateLabel.week}
                    </span>
                    <span className="v-month">
                      {Wahanda.lang.reports.salesReports.dateRange.dateLabel.month}
                    </span>
                  </label>

                  <div className="txt-input date-input">
                    <input
                      type="text"
                      name=""
                      className="datepicker"
                      defaultValue=""
                      id=""
                      readOnly
                    />
                  </div>
                </div>

                <div className="buttons">
                  <span className="action cancel">
                    <span className="icon icons-action-cancel" />
                    <span className="value">{Wahanda.lang.shared.cancel}</span>
                  </span>

                  <span className="action main">
                    <span className="icon icons-action-ok" />
                    <span className="value">
                      {Wahanda.lang.reports.salesReports.dateRange.buttons.showReport}
                    </span>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>

        <GoTo className={style.goBackLink} href="/" direction={Direction.backward}>
          {Wahanda.lang.reports.tabs.reports}
        </GoTo>

        <div className="report-content" />
      </div>
    );
  }
}

export default SalesReport;
