import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import Wahanda from 'common/wahanda';
import { Initializer } from 'common/types/initializers';
import { MenuGroupModal } from './container';

const store = storeBuilder();
const container = Wahanda.Dialogs.getReactContainer();
interface Params {
  active: boolean;
  description: string;
  hasAnyOffers?: boolean;
  showArchiveConfirmation?: boolean;
  id?: number;
  isFullFiltersEnabled?: boolean;
  name: string;
  onClose?: () => void;
}

function destroy(target: Element) {
  ReactDOM.unmountComponentAtNode(target);
}

const onClose = () => {
  destroy(container);
};

export const MenuGroupModalInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(container),
  render: () => {
    ReactDOM.render(
      <Provider store={store}>
        <MenuGroupModal
          active={params.active}
          description={params.description}
          hasAnyOffers={params.hasAnyOffers}
          showArchiveConfirmation={params.showArchiveConfirmation}
          id={params.id}
          isFullFiltersEnabled={params.isFullFiltersEnabled}
          name={params.name}
          onClose={onClose}
        />
      </Provider>,
      container,
    );
  },
});
