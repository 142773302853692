/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';

export const useMediaQuery = (query: string) => {
  if (typeof window !== 'object' || !('matchMedia' in window)) {
    console.warn('useMediaQuery is not supported');
    return null;
  }

  const [isMatch, setIsMatch] = useState(!!window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const documentChangeHandler = () => setIsMatch(!!mediaQueryList.matches);

    mediaQueryList.addListener(documentChangeHandler);

    documentChangeHandler();
    return () => {
      mediaQueryList.removeListener(documentChangeHandler);
    };
  }, [query]);

  return isMatch;
};
