import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Notifications extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-notifications', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M12.6672544,2.02 C12.3720038,2.64675399 12.1721273,3.31413239 12.0742544,4 L12.0032544,4 C9.28125437,4 8.60825437,5.225 7.99525437,10.124 C7.82490074,11.4712724 7.5764829,12.80752 7.25125437,14.126 C7.01393798,15.0956508 6.73467554,16.0545519 6.41425437,17 L17.5922544,17 C17.2743172,16.0603012 16.9967301,15.1074299 16.7602544,14.144 C16.5402544,13.253 16.3502544,12.341 16.1982544,11.415 C16.8612544,11.705 17.5782544,11.896 18.3282544,11.968 C18.5863288,13.2983097 18.9225784,14.6122542 19.3352544,15.903 C19.4622544,16.299 19.5602544,16.574 19.6252544,16.742 C20.0322544,17.913 19.2332544,19 17.9962544,19 L6.01025437,19 C4.76725437,19 3.99025437,17.897 4.40025437,16.689 C4.45325437,16.551 4.55025437,16.277 4.67825437,15.883 C4.89325437,15.218 5.10825437,14.468 5.30825437,13.649 C5.60925437,12.425 5.85025437,11.159 6.01125437,9.876 C6.73725437,4.069 7.87525437,2 12.0032544,2 C12.2332544,2 12.4552544,2.006 12.6672544,2.02 Z M10.0032544,20 L14.0032544,20 C14.0032544,21.1045695 13.1078239,22 12.0032544,22 C10.8986849,22 10.0032544,21.1045695 10.0032544,20 Z M19.0032544,10 C16.2418306,10 14.0032544,7.76142375 14.0032544,5 C14.0032544,2.23857625 16.2418306,0 19.0032544,0 C21.7646781,0 24.0032544,2.23857625 24.0032544,5 C24.0032544,7.76142375 21.7646781,10 19.0032544,10 Z M19.0032544,8 C20.6601086,8 22.0032544,6.65685425 22.0032544,5 C22.0032544,3.34314575 20.6601086,2 19.0032544,2 C17.3464001,2 16.0032544,3.34314575 16.0032544,5 C16.0032544,6.65685425 17.3464001,8 19.0032544,8 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Notifications);
