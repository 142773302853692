import {
  PRODUCT_SUPPLIERS_SET_SUPPLIER_DATA,
  PRODUCT_SUPPLIERS_SELECT_PRODUCT,
  PRODUCT_SUPPLIERS_SUBMIT_SUPPLIER,
  PRODUCT_SUPPLIERS_SUPPLIER_SUBMIT_SUCCESS,
  PRODUCT_SUPPLIERS_SUBMIT_PRODUCTS_ASSIGNATION,
  PRODUCT_SUPPLIERS_PRODUCTS_ASSIGNATION_SUBMIT_SUCCESS,
  PRODUCT_SUPPLIERS_REQUEST,
  PRODUCT_SUPPLIERS_RECEIVE,
  PRODUCT_SUPPLIERS_SUPPLIER_REQUEST,
  PRODUCT_SUPPLIERS_SUPPLIER_RECEIVE,
  PRODUCT_SUPPLIERS_FAILURE,
  PRODUCT_SUPPLIERS_SUPPLIER_DATA_RESET,
  PRODUCT_SUPPLIERS_RESET,
  PRODUCT_SUPPLIERS_DELETE_SUPPLIER,
  PRODUCT_SUPPLIERS_DELETE_SUPPLIER_SUCCESS,
} from 'reduxStore/actionsConst';

export const initialState = {
  supplier: {
    id: null,
    name: '',
    contactPersonName: '',
    phone: '',
    email: '',
    selectedProductsIds: [],
  },
  items: [],
  serverError: false,
  loading: false,
};

export default function productSuppliers(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_SUPPLIERS_SET_SUPPLIER_DATA:
      return {
        ...state,
        supplier: {
          ...state.supplier,
          ...action.data,
        },
      };

    case PRODUCT_SUPPLIERS_SELECT_PRODUCT: {
      const selectedProductsIds = state.supplier.selectedProductsIds.filter(
        (id) => id !== action.data.id,
      );
      if (action.data.selected) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        selectedProductsIds.push(action.data.id);
      }

      return {
        ...state,
        supplier: {
          ...state.supplier,
          selectedProductsIds,
        },
      };
    }

    case PRODUCT_SUPPLIERS_SUBMIT_SUPPLIER:
    case PRODUCT_SUPPLIERS_SUBMIT_PRODUCTS_ASSIGNATION:
    case PRODUCT_SUPPLIERS_REQUEST:
    case PRODUCT_SUPPLIERS_SUPPLIER_REQUEST:
    case PRODUCT_SUPPLIERS_DELETE_SUPPLIER:
      return {
        ...state,
        loading: true,
      };

    case PRODUCT_SUPPLIERS_SUPPLIER_SUBMIT_SUCCESS:
    case PRODUCT_SUPPLIERS_PRODUCTS_ASSIGNATION_SUBMIT_SUCCESS:
    case PRODUCT_SUPPLIERS_DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case PRODUCT_SUPPLIERS_RECEIVE:
      return {
        ...state,
        items: action.data,
        loading: false,
      };

    case PRODUCT_SUPPLIERS_SUPPLIER_RECEIVE:
      return {
        ...state,
        supplier: {
          ...state.supplier,
          ...action.data,
        },
        loading: false,
      };

    case PRODUCT_SUPPLIERS_FAILURE:
      return {
        ...state,
        serverError: true,
        loading: false,
      };

    case PRODUCT_SUPPLIERS_SUPPLIER_DATA_RESET:
      return {
        ...state,
        supplier: {
          ...initialState.supplier,
        },
      };

    case PRODUCT_SUPPLIERS_RESET:
      return initialState;

    default:
      return state;
  }
}
