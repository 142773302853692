import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'common/moment';
import { requestDailySalesReportDataAction } from '../actions';
import { DailySalesReport } from './index';

const mapStateToProps = (state) => {
  const ret = {
    displayState: state.salesReport.dailySales.displayState,
  };
  if (state.salesReport.dailySales.reportData) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'reportData' does not exist on type '{ di... Remove this comment to see the full error message
    ret.reportData = JSON.parse(JSON.stringify(state.salesReport.dailySales.reportData));
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'reportData' does not exist on type '{ di... Remove this comment to see the full error message
    ret.reportData.rows = ret.reportData.rows.map((row) => ({
      ...row,
      date: moment(row.date),
    }));
  }
  return ret;
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ requestDailySalesReportDataAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(DailySalesReport);
