/* eslint-disable */

import { BookingsListAnalytics } from 'common/analytics';

(function () {
  var BOOKING_STATUS_CONFIRMED = 'CN',
    BOOKING_STATUS_CANCELLED = 'CC',
    BOOKING_STATUS_CREATED = 'CR',
    BOOKING_STATUS_REJECTED = 'RJ';

  var APPOINTMENT_STATUS_CANCELLED = 'CC',
    APPOINTMENT_STATUS_UNCONFIRMED = 'CR',
    APPOINTMENT_STATUS_CONFIRMED = 'CN',
    APPOINTMENT_STATUS_CHECKED_OUT = 'CP',
    APPOINTMENT_STATUS_REJECTED = 'RJ',
    APPOINTMENT_STATUS_NOSHOW = 'NS';

  var BOOKING_TYPE_SERVICE = 'SV',
    BOOKING_TYPE_DATED = 'DT',
    BOOKING_TYPE_APPOINTMENT = 'AP';

  var FILTER_TYPE_ALL = 'ALL',
    FILTER_TYPE_CANCELLED = 'BCX',
    FILTER_TYPE_REJECTED = 'BRJ',
    FILTER_TYPE_FUTURE = 'BFU',
    FILTER_TYPE_ALL_APPOINTMENTS = 'AAP',
    FILTER_TYPE_CUSTMR_APPOINTMENTS = 'AWH',
    FILTER_TYPE_SUPCUS_APPOINTMENTS = 'AWG',
    FILTER_TYPE_SUPPLR_APPOINTMENTS = 'ADR',
    FILTER_TYPE_COMPLETED_APPOINTMENTS = 'ACP',
    FILTER_TYPE_SPA_DAYS = 'DTY',
    FILTER_TYPE_SPA_BREAKS = 'DTO',
    FILTER_TYPE_EVOUCHERS_REDEEMED = 'REV',
    FILTER_TYPE_EVOUCHERS_ACTIVE = 'AEV';

  var BOOKING_SUBTYPE_EVOUCHER = 'EV';
  var BOOKING_SUBTYPE_APPOINTMENT = 'AP';
  var BOOKING_SUBTYPE_DATED = 'DT';
  App.Views.Reports.Table = Backbone.View.extend({
    events: {
      'select.menu .home-filters': 'onFilterChange',
      'click .data-body tr': 'openResultDialog',
      'click .a-custom-filter': 'customFilter',
      'keydown #filter-date-from, #filter-date-to': 'customFilterByReturn',
    },

    storageFilterTypeKey: 'home-filter-type',

    filters: {
      type: 'ALL',
      date: '1M',
    },

    customDateRegexp: /^C_([\d-]+)_([\d-]+)$/,

    subTypes: {
      ALL: {
        BCX: Wahanda.lang.home.table.filters.type.cancelled,
        BRJ: Wahanda.lang.home.table.filters.type.rejected,
        BFU: Wahanda.lang.home.table.filters.type.future,
      },
      AAP: {
        AWH: Wahanda.lang.home.table.filters.type.soldTreatwell,
        AWG: Wahanda.lang.home.table.filters.type.soldWidget,
        ADR: Wahanda.lang.home.table.filters.type.createdInConnect,
        ACP: Wahanda.lang.home.table.filters.type.completed,
      },
    },

    initialize: function () {
      this.hadRendered = false;
      this._initializeFilters();
      var self = this;
      this.customFilterSet = false;

      this._setupMenuFilters();

      // Bind to filter change events
      this.on('filter-change', function () {
        self.render();
        self.triggerFilteredEvent();
      });
      this.on('visible', () => {
        BookingsListAnalytics.trackPageView();
        // if the view has rendered atleast once we don't rerender it to avoid refetching data;
        if (self.hadRendered === true) {
          return;
        }
        self.render();
      });
      // Bind to other display-changing events
      var e = Wahanda.Event;
      var refreshEvents = [
        e.APPOINTMENT_CONFIRMED,
        e.APPOINTMENT_REJECTED,
        e.APPOINTMENT_CANCELLED,
        e.APPOINTMENT_CHECKED_OUT,
        e.BOOKING_CONFIRMED,
        e.BOOKING_REJECTED,
        e.EVOUCHER_REDEEMED,
        e.APPOINTMENT_SET_NOSHOW,
        e.APPOINTMENT_GROUP_CONFIRMED,
        e.APPOINTMENT_GROUP_REJECTED,
        e.APPOINTMENT_GROUP_SET_NOSHOW,
      ].join(' ');

      App.on(refreshEvents, function () {
        self.render();
      });

      this.bookingsEnquiries = new App.Models.BookingsEnquiries();

      $(window).resize(function () {
        self.toggleTick();
      });

      this.setValidations();
    },

    _initializeFilters: function () {
      if (App.mainViewOptions && App.mainViewOptions.filters) {
        this.filters = App.mainViewOptions.filters;
      } else {
        var storedType = Wahanda.LocalStorage.get(this.storageFilterTypeKey);
        if (storedType) {
          this.filters.type = storedType;
        }
      }
    },

    _setupMenuFilters: function () {
      var currentElementRenderer = function (node) {
        var handler = this.current.find('.handler').clone();
        this.current.html(node.html()).append(handler);
      };

      var selectedType = this.filters.type;

      // Render type (first) select
      var type;
      _.any(this.subTypes, function (items, typeCode) {
        if (selectedType === typeCode || selectedType in items) {
          type = typeCode;
          return true;
        }
      });
      this.$('#filter-type').wMenu({
        selected: '[data-value=' + type + ']',
        currentElementRenderer: currentElementRenderer,
      });

      // Render subtype (second) select
      var subType;
      if (!(selectedType in this.subTypes)) {
        _.any(this.subTypes, function (items) {
          if (selectedType in items) {
            subType = selectedType;
            return true;
          }
        });
      }
      this.$('#filter-subtype').wMenu({
        selected: subType ? '[data-value=' + subType + ']' : null,
        currentElementRenderer: function (node) {
          var handler = this.current.find('.handler').clone();
          this.current.html(node.html()).append('<span class="count">-</span>').append(handler);
        },
      });

      this.renderSubtypeSelectChoices(type);

      // Date select
      this.$('#filter-date').wMenu({
        selected:
          '[data-value=' + (this._isCustomDateFiltered() ? 'CUST' : this.filters.date) + ']',
        currentElementRenderer: currentElementRenderer,
      });

      // Datepickers
      var $filterDateFrom = this.$('#filter-date-from').venueDatepicker({
        onSelect: function (selectedDate) {
          $filterDateTo.datepicker('option', 'minDate', selectedDate);
          $(this).valid();
        },
      });
      var $filterDateTo = this.$('#filter-date-to').venueDatepicker({
        onSelect: function (selectedDate) {
          $filterDateFrom.datepicker('option', 'maxDate', selectedDate);
          $(this).valid();
        },
      });
    },

    triggerFilteredEvent: function () {
      var dates = this.getFilterDates();
      var filters = this.filters;
      var filterType = filters.type;

      switch (filterType) {
        case 'BKN':
          filterType = 'bookings';
          break;
        case 'REV':
          filterType = 'evouchers-redeemed';
          break;
        case 'AEV':
          filterType = 'evouchers-active';
          break;
      }

      App.trigger(Wahanda.Event.HOME_TABLE_FILTERED, {
        dateFrom: dates.from,
        dateTo: dates.to,
        entityTypes: filterType,
      });
    },

    setValidations: function () {
      var validations = Wahanda.Validate.getValidations('defaults', {
        submitHandler: function () {},
      });

      this.$('form').validate(validations);
    },

    onFilterChange: function (event, selectedNode) {
      if ('menu' !== event.namespace) {
        return;
      }

      var selectType = event.target.getAttribute('id').replace('filter-', '');
      var value = selectedNode.getAttribute('data-value');

      if ('type' === selectType) {
        this.renderSubtypeSelectChoices(value);
      } else if ('subtype' === selectType) {
        selectType = 'type';
        if (null == value) {
          // The subtype does not have a value. This means we should use the type select's value.
          value = this.$('#filter-type').find('li.on').data('value');
        }
      }

      if ('CUST' === value) {
        this.showCustomDateFields();
      } else {
        this.hideCustomDateFields();
        this.filterChange(selectType, value);
      }

      this.showDateFilters(FILTER_TYPE_FUTURE !== value);
    },

    filterChange: function (filter, value) {
      switch (filter) {
        case 'type':
          this.filters.type = value;
          Wahanda.LocalStorage.set(this.storageFilterTypeKey, value);
          break;

        case 'date':
          this.filters.date = value;
          break;
      }

      this.updateUrlWithCurrentOptions();
      this.trigger('filter-change');
    },

    // URL + state functions
    updateUrl: function (type, date) {
      App.mainRouter.navigate(this.getStateHash(type, date));
    },

    updateUrlWithCurrentOptions: function () {
      this.updateUrl(this.filters.type, this.filters.date);
    },

    getCurrentStateHash: function () {
      return this.getStateHash(this.filters.type, this.filters.date);
    },

    getStateHash: function (type, date) {
      return App.config.currentVenueHash() + '/bookings' + '/' + type + '/' + date;
    },

    renderSubtypeSelectChoices: function (typeValue) {
      var html = '<li>' + Wahanda.lang.home.table.filters.type.all + '</li>';
      var type;

      if (this.subTypes[typeValue]) {
        for (type in this.subTypes[typeValue]) {
          html += '<li data-value="' + type + '">' + this.subTypes[typeValue][type] + '</li>';
        }
      }

      var $filter = this.$('#filter-subtype');
      // Set HTML
      $filter.find('.filter-ddown').html(html);
      // Set active value
      var $active = $filter.find('li[data-value="' + this.filters.type + '"]');
      if (!$active.length) {
        $active = $filter.find('li:first');
      }
      $filter.wMenu('select', $active);
    },

    customFilter: function () {
      if (!this.$('form').validate().valid()) {
        return;
      }

      var strFrom = Wahanda.Date.toApiString(this.$('#filter-date-from').datepicker('getDate'));
      var strTo = Wahanda.Date.toApiString(this.$('#filter-date-to').datepicker('getDate'));

      if (strFrom > strTo) {
        this.$('form').validate().showErrors({
          'filter-date-to': Wahanda.lang.shared.errors.dateMustBeGreater,
        });
        return;
      }

      this.customFilterSet = true;
      this.filterChange('date', 'C_' + strFrom + '_' + strTo);
    },

    customFilterByReturn: function (event) {
      if (13 === event.keyCode) {
        this.customFilter();
      }
    },

    _isCustomDateFiltered: function () {
      return this.customDateRegexp.test(this.filters.date);
    },

    showCustomDateFields: function () {
      this.$('.date-range').wShow();
    },

    hideCustomDateFields: function () {
      this.$('.date-range').wHide();
    },

    showDateFilters: function (show) {
      if (show) {
        var dateType = this.$('#filter-date').wShow().find('li.on').data('value');
        if ('CUST' === dateType) {
          this.$('.date-range').wShow();
        }
      } else {
        this.$('#filter-date, .date-range').wHide();
      }
    },

    render: function () {
      if (!Wahanda.Permissions.viewBookings()) {
        return;
      }

      this.fetchAndRender();

      if (this._isCustomDateFiltered()) {
        this.showCustomDateFields();
        this.customFilterSet = true;
      } else {
        this.hideCustomDateFields();
      }

      if (!this.customFilterSet) {
        var range = this.getRange();
        this.renderCustomDates(range.from, range.to);
      }

      this.showDateFilters(FILTER_TYPE_FUTURE !== this.filters.type);
      this.hadRendered = true;
    },

    renderCustomDates: function (from, to) {
      this.$('#filter-date-from').val(Wahanda.Date.formatToDefaultDate(from));
      this.$('#filter-date-to').val(Wahanda.Date.formatToDefaultDate(to));
    },

    fetchAndRender: function () {
      var self = this;
      var timestamp = new Date().getTime();
      // Set loading mask
      this.showLoader();

      var dates = this.getFilterDates();
      if (this.filters.type === FILTER_TYPE_FUTURE) {
        delete dates.to;
      }

      this.bookingsEnquiries.setUrlFilters({
        entity: this.getEntityFilter(),
        type: this.getTypeFilter(),
        'date-from': dates.from,
        'date-to': dates.to,
      });

      this.bookingsEnquiries.checkTS = timestamp;
      this.bookingsEnquiries.fetch({
        success: function () {
          if (self.bookingsEnquiries.checkTS != timestamp) {
            // Race condition detected.
            return;
          }
          var data = self.mergeData();
          self.setResultCount(data);
          self.renderTable(data);
        },
      });
    },

    getEntityFilter: function () {
      var type = this.getFilterType();

      var appointmentType = [
        FILTER_TYPE_ALL_APPOINTMENTS,
        FILTER_TYPE_CUSTMR_APPOINTMENTS,
        FILTER_TYPE_SUPCUS_APPOINTMENTS,
        FILTER_TYPE_SUPPLR_APPOINTMENTS,
        FILTER_TYPE_COMPLETED_APPOINTMENTS,
      ];
      var datedType = [FILTER_TYPE_SPA_DAYS, FILTER_TYPE_SPA_BREAKS];
      var evoucherType = [FILTER_TYPE_EVOUCHERS_REDEEMED, FILTER_TYPE_EVOUCHERS_ACTIVE];

      switch (true) {
        case _.indexOf(appointmentType, type) != -1:
          return 'appointment-booking';

        case _.indexOf(datedType, type) != -1:
          return 'dated-booking';

        case _.indexOf(evoucherType, type) != -1:
          return 'evoucher-booking';

        case _.indexOf(appointmentType, type) != -1:
        default:
          return 'all';
      }
    },

    getTypeFilter: function () {
      switch (this.getFilterType()) {
        case FILTER_TYPE_CANCELLED:
          return 'cancelled';

        case FILTER_TYPE_REJECTED:
          return 'rejected';

        case FILTER_TYPE_CUSTMR_APPOINTMENTS:
          return 'channel-site';

        case FILTER_TYPE_SUPCUS_APPOINTMENTS:
          return 'channel-supcus';

        case FILTER_TYPE_SUPPLR_APPOINTMENTS:
          return 'channel-supplier';

        case FILTER_TYPE_COMPLETED_APPOINTMENTS:
          return 'status-completed';

        case FILTER_TYPE_SPA_DAYS:
          return 'spa-day';

        case FILTER_TYPE_SPA_BREAKS:
          return 'spa-break';

        case FILTER_TYPE_EVOUCHERS_REDEEMED:
          return 'redeemed-evoucher';

        case FILTER_TYPE_EVOUCHERS_ACTIVE:
          return 'active-evoucher';

        case FILTER_TYPE_FUTURE:
          return 'future';

        default:
          return 'all';
      }
    },

    getFilterType: function () {
      return this.filters.type;
    },

    setResultCount: function (data) {
      var count = data.length;
      this.$('#filter-subtype').find('.count').text(count);
    },

    renderTable: function (items) {
      var self = this;
      var $container = this.$('.data-body');
      var $table = $container.find('tbody').empty();
      var html = '';

      this.$('.home-data').toggleClass('empty', items.length === 0);

      _.each(items, function (item) {
        var date = Wahanda.Date.formatToDefaultFullDate(Wahanda.Date.parse(item.date));
        html += Wahanda.Template.renderTemplate('home-table-item', {
          id: item.id,
          type: item.type,
          icon: self.getItemIcon(item),
          orderRef: item.orderRef,
          orderId: item.orderId,
          date: date.date + ' ' + date.time,
          customer: item.customer,
          description: item.service,
          value: item.value ? Wahanda.Currency.format(item.value) : '',
          status: self.getItemStatus(item),
          employeeName: item.employeeName || 'N/A',
          appointmentDatetime: item.appointmentDatetime || 'N/A',
        });
      });

      $table.html(html);

      $container.find('.js-payment-protection-badge').each((i, node) => {
        App.ES6.Initializers.PaymentProtectionBadge({ node }).render();
      });

      $container.shadows();
      this.toggleTick();

      this.hideLoader();
    },

    showLoader: function () {
      this.$('.home-data').loadmask();
    },

    hideLoader: function () {
      this.$('.home-data').unloadmask();
    },

    getItemIcon: function (item) {
      var type = this.getItemIconType(item);
      return {
        className: type + (type === 'voucher' ? '3' : ''),
        title: Wahanda.lang.home.table.icons[type],
      };
    },

    getItemIconType: function (item) {
      if (item.type === 'order') {
        if (item.subtype === BOOKING_SUBTYPE_APPOINTMENT) {
          return 'appointment';
        } else if (item.subtype === BOOKING_SUBTYPE_DATED) {
          return 'dated';
        }
        return 'voucher';
      } else if (item.type === 'appointment') {
        return 'appointment';
      }
      return 'unknown';
    },

    getItemStatus: function (item) {
      if (item.type === 'order' || item.type === 'appointment') {
        switch (item.subtype) {
          case BOOKING_SUBTYPE_EVOUCHER:
            var texts = [];
            var checks = {
              confirmedEvouchers: {
                textId: 'confirmedCount',
                className: 'done',
              },
              activeEvouchers: {
                textId: 'activeCount',
                className: 'confirmed',
              },
              voidedEvouchers: { textId: 'voidedCount', className: 'done' },
              expiredEvouchers: { textId: 'expiredCount', className: 'done' },
              reservedEvouchers: { textId: 'reservedCount', className: 'done' },
            };
            for (var name in checks) {
              if (item[name] > 0) {
                var data = checks[name];
                var text = Wahanda.Template.render(Wahanda.lang.home.table.evouchers[data.textId], {
                  count: item[name],
                });
                texts.push(getStatusSpan(data.className, text));
              }
            }
            return {
              list: texts.join('<br />'),
            };

          case BOOKING_SUBTYPE_APPOINTMENT:
            var texts = [];
            if (APPOINTMENT_STATUS_REJECTED === item.status) {
              texts.push(getStatusSpan('rejected', Wahanda.lang.home.table.statuses['rejected']));
            } else if (APPOINTMENT_STATUS_CHECKED_OUT === item.status) {
              texts.push(getStatusSpan('checkedout', Wahanda.lang.home.table.statuses.checkedOut));
            } else if (BOOKING_STATUS_CONFIRMED === item.status) {
              texts.push(getStatusSpan('confirmed', Wahanda.lang.home.table.statuses['confirmed']));
            } else if (APPOINTMENT_STATUS_CANCELLED === item.status) {
              texts.push(getStatusSpan('cancelled', Wahanda.lang.home.table.statuses['cancelled']));
            } else if (APPOINTMENT_STATUS_NOSHOW === item.status) {
              texts.push(getStatusSpan('noshow', Wahanda.lang.calendar.appointments.types.noShow));
            }

            if (item.paymentProtectionApplied) {
              texts.push('<span class="js-payment-protection-badge"></span>');
            }

            if (item.unpaid) {
              texts.push(
                getStatusSpan('unpaid', Wahanda.lang.calendar.appointments.multi.labels.unpaid),
              );
            }
            return {
              list: texts.join('<br />'),
            };

          case BOOKING_SUBTYPE_DATED:
            var texts = [];
            if (BOOKING_STATUS_CONFIRMED === item.status) {
              texts.push(getStatusSpan('confirmed', Wahanda.lang.home.table.statuses['confirmed']));
            } else if (BOOKING_STATUS_CANCELLED === item.status) {
              texts.push(getStatusSpan('cancelled', Wahanda.lang.home.table.statuses['cancelled']));
            } else if (BOOKING_STATUS_REJECTED === item.status) {
              texts.push(getStatusSpan('rejected', Wahanda.lang.home.table.statuses['rejected']));
            }
            if (item.unpaid) {
              texts.push(
                getStatusSpan('unpaid', Wahanda.lang.calendar.appointments.multi.labels.unpaid),
              );
            }
            return {
              list: texts.join('<br />'),
            };
          default:
            break;
        }
      }

      function getStatusSpan(className, text) {
        return '<span class="status status-' + className + '">' + text + '</span>';
      }
    },

    getFilterDates: function () {
      var range = this.getRange();
      return {
        from: Wahanda.Date.toApiString(range.from),
        to: range.to ? Wahanda.Date.toApiString(range.to) : null,
      };
    },

    /**
     * Returns the filtered date range.
     *
     * @return Object {from, to}
     */
    getRange: function () {
      var from = null;
      var until = new Date();

      if (this.filters.type === FILTER_TYPE_FUTURE) {
        // In the future
        from = until;
        until = null;
      } else if (this.customDateRegexp.test(this.filters.date)) {
        var match = this.getCustomDateRange();
        // Custom date
        from = match.from;
        until = match.to;
      } else {
        switch (this.filters.date) {
          case '1M':
            from = new Date(until.getFullYear(), until.getMonth(), until.getDate() - 30);
            break;

          case '2M':
            from = new Date(until.getFullYear(), until.getMonth(), until.getDate() - 60);
            break;

          case '6M':
            from = new Date(until.getFullYear(), until.getMonth() - 6, until.getDate());
            break;

          case '12M':
            from = new Date(until.getFullYear() - 1, until.getMonth(), until.getDate());
            break;

          default:
            // 3 months
            from = new Date(until.getFullYear(), until.getMonth() - 3, until.getDate());
            break;
        }
      }

      return {
        from: from,
        to: until,
      };
    },

    getCustomDateRange: function () {
      var match = String(this.filters.date).match(this.customDateRegexp);
      return {
        from: Wahanda.Date.createDate(match[1]),
        to: Wahanda.Date.createDate(match[2]),
      };
    },

    /**
     * Merges the retrieved data by the filters.
     *
     * It`s job is to unify the structure of different objects and to skip not needed items.
     * @return array
     */
    mergeData: function () {
      const data = [];
      const seenAppointments = new Set();

      this.bookingsEnquiries.each((item) => {
        if (!this.isValidItemForRendering(item)) {
          // Don't render appointments which belong to a group.
          // Render their Groups instead.
          return;
        }

        const { booking, appointment, appointmentGroup } = item;
        const apptToCheck = appointment || (booking && booking.get('appointment'));

        // Check for duplicate appointments - and don't render the dupes
        if (apptToCheck && seenAppointments.has(apptToCheck.id)) {
          return;
        } else if (apptToCheck) {
          seenAppointments.add(apptToCheck.id);
        }

        if (booking) {
          data.push(this.extractBookingData(booking));
        } else if (appointment) {
          const apptData = this.extractAppointmentData(appointment);
          if (apptData) {
            data.push(apptData);
          }
        } else if (appointmentGroup) {
          const apptGroupData = this.extractAppointmentGroupData(appointmentGroup);
          if (apptGroupData) {
            data.push(apptGroupData);
          }
        }
      });

      return _.sortBy(data, (item) => item.date).reverse();
    },

    /**
     * An "invalid" item for rendering is one (Appt or Group) which is under another group.
     *
     * @param Object item
     *
     * @returns boolean
     */
    isValidItemForRendering: function (item) {
      if (App.Models.BookingsEnquiries.itemBelongsToGroup(item)) {
        return false;
      }
      return true;
    },

    extractBookingData: function (booking) {
      var isEvoucher = !!(booking.get('evouchers') && booking.get('evouchers').length > 0);

      const appointment = booking.isAppointment() && booking.getAppointmentModel();

      var item = {
        id: booking.get('orderId'),
        type: 'order',
        subtype: this._getItemSubtype(booking),
        orderRef: booking.get('orderReference'),
        date: booking.get('created'),
        customer: booking.get('customer').name,
        service: booking.get('itemName'),
        value: booking.get('itemAmount'),
        status: booking.get('bookingStatus'),
        unpaid: booking.isUnpaid({ onlyPayAtVenue: true }),
        paymentProtectionApplied: booking.isPaymentProtectionApplied(),
        appointmentDatetime: appointment && this.getApointmentDatetime(appointment),
        employeeName: appointment && appointment.get('employeeName'),
      };

      if (isEvoucher) {
        item.confirmedEvouchers = 0;
        item.activeEvouchers = 0;
        item.voidedEvouchers = 0;
        item.expiredEvouchers = 0;
        item.reservedEvouchers = 0;

        _.each(booking.get('evouchers'), function (ev) {
          switch (ev.evoucherStatusCode) {
            case 'R':
              item.confirmedEvouchers++;
              break;

            case 'A':
              item.activeEvouchers++;
              break;

            case 'V':
              item.voidedEvouchers++;
              break;

            case 'X':
              item.expiredEvouchers++;
              break;

            case 'S':
              item.reservedEvouchers++;
              break;
          }
        });
      } else if (booking.get('appointment')) {
        var appt = new App.Models.Appointment(booking.get('appointment'));
        // If filter type is "future", do not allow today`s past appointments through
        if (this.filters.type === FILTER_TYPE_FUTURE && appt.isInThePast()) {
          return;
        }

        item.id = appt.id;
        item.type = 'appointment';
        item.status = appt.get('appointmentStatusCode');
        item.customer = appt.getConsumerName();

        var coData = appt.get('checkout');
        if (APPOINTMENT_STATUS_CHECKED_OUT === item.status && coData) {
          item.value = coData.total;
        } else {
          item.value = appt.get('amount');
        }
      }
      return item;
    },

    extractAppointmentData: function (appt) {
      // If filter type is "future", do not allow today`s past appointments through
      if (this.getFilterType() === FILTER_TYPE_FUTURE && appt.isInThePast()) {
        return;
      }

      var item = {
        id: appt.id,
        orderId: appt.get('orderId'),
        type: 'appointment',
        subtype: BOOKING_SUBTYPE_APPOINTMENT,
        orderRef: appt.get('orderReference') || '',
        date: appt.get('created'),
        customer: appt.getConsumerName(),
        service: appt.get('offerName'),
        value: appt.isCheckedOut() && appt.get('checkout') ? appt.get('checkout').total : null,
        status: appt.get('appointmentStatusCode'),
        unpaid: appt.isUnpaid({ onlyPayAtVenue: true }),
        paymentProtectionApplied: appt.isPaymentProtectionApplied(),
        appointmentDatetime: this.getApointmentDatetime(appt),
        employeeName: appt.get('employeeName'),
      };

      return item;
    },

    getApointmentDatetime: function (appointment) {
      const start = appointment.getStartDate();

      const startInfo = Wahanda.Date.formatToDefaultFullDate(start);

      return `${startInfo.date} ${startInfo.time}`;
    },

    extractAppointmentGroupData: function (apptGroup) {
      // Derive main data from the Appointment
      var data = this.extractAppointmentData(apptGroup.at(0));
      if (!data) {
        return;
      }
      // Group overrides
      data.service = apptGroup.getOfferName();
      data.status = apptGroup.data.appointmentStatusCode;
      data.value = apptGroup.isCheckedOut() ? apptGroup.getTotalPrice() : null;
      data.offerId = apptGroup.data.orderId;
      return data;
    },

    _getItemSubtype: function (booking) {
      if (booking.get('appointment')) {
        return BOOKING_SUBTYPE_APPOINTMENT;
      } else if (booking.get('dated')) {
        return BOOKING_SUBTYPE_DATED;
      }
      // show as evoucher even if different type
      return BOOKING_SUBTYPE_EVOUCHER;
    },

    openResultDialog: function (event) {
      BookingsListAnalytics.trackOpenOrderDialog();

      var $tr = $(event.currentTarget);
      var type = $tr.data('type');
      var id = parseInt($tr.data('id'));
      switch (type) {
        case 'order':
          this.openOrderDialog(id);
          break;
        case 'appointment':
          this.openAppointment(id);
          break;
      }
    },

    openOrderDialog: function (id) {
      var view = new App.Views.Dialog.Order2({
        model: new App.Models.Order({ id: id }),
        updateUrl: false,
      });
      view.render();
      view.open();
    },

    openAppointment: function (id) {
      App.Views.Forms.Appointment2.openById(id);
    },

    /**
     * Toggles a tick in the table head, if the list table is narrower than the header table.
     */
    toggleTick: function () {
      var $headTable = this.$('.data-head').find('table');
      var $listTable = this.$('.data-body').find('table');

      $headTable.toggleClass('has-tick', $headTable.width() > $listTable.width());
    },
  });
})();
