/* global BackboneEx */
/* eslint func-names: 0 */
import _ from 'common/underscore';
import { trackEvent } from 'common/analytics';
import App from 'common/backbone-app';

/**
 * POS transaction complete view.
 */
App.Views.Forms.POSTransactionComplete = BackboneEx.View.Dialog2.extend({
  events: {
    'click .js-close': 'close',
    'click .js-open-register': 'doOpenRegister',
    'click .js-add-tip': 'doAddTip',
    'click .js-rebook': 'rebook',
    'click .js-download-original-receipt': 'onGetOriginalReceiptClick',
    'click .js-receipt-copy-reason': 'onReceiptCopyClick',
  },

  templateId: 'pos-transaction-complete-template',

  options: {
    width: 280,
    dialogClass: 'pos-complete',
    dataTest: 'pos-transaction-complete-modal',
  },
  dialogTitle: Wahanda.lang.calendar.appointments.pos.completeTitle,

  originalReceiptDownloaded: false,

  initialize(...props) {
    BackboneEx.View.Dialog2.prototype.initialize.apply(this, props);
    this.collection = this.options.collection;
    this.controller = this.options.controller;
    this.checkoutCalc = this.controller ? this.controller.checkoutCalc : null;
    this.customTotalLabel = this.options.customTotalLabel;
    this.customTotal = this.options.customTotal;
  },

  render(...props) {
    BackboneEx.View.Dialog2.prototype.render.apply(this, props);
    this.hydrate();
  },

  hydrate() {
    this.$dialogContent
      .empty()
      .prepend(Wahanda.Template.renderTemplate(this.templateId, this.getDialogTemplateVariables()));
  },

  doOpenRegister() {
    // TODO
  },

  maybeGetEventPosition(event) {
    return event ? { x: event.clientX, y: event.clientY } : null;
  },

  doAddTip() {
    // TODO
  },

  onGetOriginalReceiptClick(e) {
    e.preventDefault();
    window.open(e.target.href, '_blank');
    if (this.options.receiptCopyUri) {
      this.originalReceiptDownloaded = true;
    }
    this.hydrate();
    return true;
  },

  onReceiptCopyClick() {
    App.ES6.Initializers.ReceiptCopyDialog({
      node: this.$('.js-receipt-copy-dialog')[0],
    }).render(this.options);

    return true;
  },

  rebook(event) {
    trackEvent('calendar', 'click', 'rebook-init', 'pos-transaction-complete');
    this.close();
    Wahanda.Appointment.enterRebookingMode(this.collection, this.maybeGetEventPosition(event));
  },

  getDialogTemplateVariables() {
    const date = this.formatDate(Wahanda.Date.createVenueDate());
    const receiptUri = this.options.receiptUri;
    const receiptButtonLabel = this.options.receiptButtonLabel
      ? this.options.receiptButtonLabel
      : Wahanda.lang.calendar.appointments.pos.completeRegister;
    const receiptCopyUri = this.options.receiptCopyUri;
    const secondReceiptUri = this.options.secondReceiptUri;
    const secondReceiptButtonLabel = this.options.secondReceiptButtonLabel;
    const defaultVariables = {
      date,
      receiptUri,
      receiptButtonLabel,
      receiptCopyUri,
      receiptCopyButtonLabel: this.options.receiptCopyButtonLabel,
      secondReceiptUri,
      secondReceiptButtonLabel,
      originalReceiptDownloaded: this.originalReceiptDownloaded,
    };
    let additionalVariables = {};
    if (this.customTotal != null) {
      additionalVariables = this.getCustomTotalTemplateVariables();
    } else if (this.collection) {
      additionalVariables = this.getCheckoutTemplateVariables();
    }

    return {
      ...defaultVariables,
      ...additionalVariables,
    };
  },

  getCustomTotalTemplateVariables() {
    const { customTotal, customTotalLabel } = this;

    return {
      hasReceipt: true,
      customTotal: this.formatNumber(customTotal.toFixed(2)),
      customTotalLabel,
    };
  },

  getCheckoutTemplateVariables() {
    const product = App.Models.POS.ProductDescriminators.PRODUCT;
    const voucher = App.Models.POS.ProductDescriminators.VOUCHER;

    const changeAmount = this.checkoutCalc.getChange().toFixed(2);
    const servicesTotal = this.checkoutCalc.getServicesTotal().toFixed(2);
    const totalPaid = this.controller.payments.getTotalPaidSum();

    const productsTotal = this.controller.products.getTotalDiscounted(product).toFixed(2);
    const productsQuantity = this.controller.products.getTotalQuantity(product);
    const productsQuantityText = this.getProductText(product, productsQuantity);

    const vouchersTotal = this.controller.products.getTotalDiscounted(voucher).toFixed(2);
    const vouchersQuantity = this.controller.products.getTotalQuantity(voucher);
    const vouchersQuantityText = this.getProductText(voucher, vouchersQuantity);

    const tipsTotal = this.checkoutCalc.tips.getTotal();
    const totalDeductions = this.checkoutCalc.getDeductions().toFixed(2);

    const totalPayments = (totalPaid + tipsTotal).toFixed(2);
    const isPaidWithVATVoucher = this.controller.payments.getTotalVouchersWithVAT() > 0;

    return {
      hasReceipt: true,
      hasServices: this.controller.getServiceLineItems().length > 0,
      hasAppts: !_.isEmpty(this.collection),
      servicesTotal: this.formatNumber(servicesTotal),
      hasProducts: productsQuantity > 0,
      productsTotal: this.formatNumber(productsTotal),
      productsQuantityText,
      hasVouchers: vouchersQuantity > 0,
      vouchersTotal: this.formatNumber(vouchersTotal),
      vouchersQuantityText,
      hasTips: tipsTotal > 0,
      tipsTotal: this.formatNumber(tipsTotal.toFixed(2)),
      showAddTip: false,
      hasDeductions: totalDeductions > 0,
      totalDeductions: this.formatNumber(totalDeductions),
      totalPaid: this.formatNumber(totalPayments),
      hasChange: changeAmount > 0,
      change: this.formatNumber(changeAmount),
      isPaidWithVATVoucher,
      paidWithVATVoucher: this.formatNumber(this.controller.payments.getTotalVouchersWithVAT()),
    };
  },

  getProductText(type, quantity) {
    const single =
      type === App.Models.POS.ProductDescriminators.VOUCHER
        ? Wahanda.lang.calendar.appointments.pos.completeSingleVoucher
        : Wahanda.lang.calendar.appointments.pos.completeSingleProduct;
    const multiple =
      type === App.Models.POS.ProductDescriminators.VOUCHER
        ? Wahanda.lang.calendar.appointments.pos.completeMultipleVouchers
        : Wahanda.lang.calendar.appointments.pos.completeMultipleProducts;
    if (quantity > 1) {
      return `${quantity} ${multiple}`;
    }
    return `${quantity} ${single}`;
  },

  formatNumber(num) {
    return Wahanda.Currency.getFormatted(num);
  },

  formatDate(date) {
    const format = 'M dd yy';
    return Wahanda.Date.formatDate(format, date);
  },
});
