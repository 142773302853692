/* eslint-disable func-names */
/* global BackboneEx _ */
App.Views.Forms.MultipleMenuServices = BackboneEx.View.Dialog.extend({
  events: {
    // The primary button is type=submit, so is submits the form and triggers validation
    'click .button-cancel': 'close',
    'change .sort-type': 'changeSorting',
    'change input[type=checkbox]': 'onCheckboxClicked',
    'submit form': 'save',
  },

  options: {
    width: 680,
    saveCallback: null,
    selectedTreatments: [],
    allowEmpty: false,
    title: Wahanda.lang.menu.selectServices,
    selectionLimit: null,
    dataTest: 'multiple-menu-services-modal',
  },

  groupShadowsOptions: { width: 280 },
  listShadowsOptions: { left: 280, width: 400 },
  detachedElements: null,

  postInitialize() {
    this.sortingInited = false;
    this.selected = {};
  },

  open() {
    // Detaching all elements for $.ui.dialog to render quickly.
    this.detachedElements = this.$el.children().detach();

    // eslint-disable-next-line no-underscore-dangle
    this.constructor.__super__.open.call(this);
  },

  setupDialog() {
    const self = this;
    this.renderTitle();
    this.renderTabs();
    this.renderTabContent();

    try {
      this.$('#multiple-services-tabs').tabs('destroy');
    } catch (e) {
      console.error(e);
    }

    this.$('#multiple-services-tabs').tabs({
      show(args) {
        self.onTabSelect(args);
      },
    });

    if (this.options.hideSorting) {
      this.$('.sorting').addClass('hidden');
    } else if (!this.sortingInited) {
      this.setCurrentSorting();
      this.$('.sorting').buttonList();
      this.sortingInited = true;
    }

    this.toggleActionButtons();
    this.renderSelectedItemsLimit();
    this.toggleCheckboxEditing();
  },

  renderTitle() {
    this.$('.dialog-head h1').html(
      Wahanda.Template.render(Wahanda.lang.menu.services.heading, {
        venue: `<span class="highlight">${App.config.get('venue').name}</span>`,
      }),
    );
  },

  renderSelectedItemsLimit() {
    if (this.options.selectionLimit > 0) {
      const text = Wahanda.lang.menu.services.selectionLimit.replace(
        '{{count}}',
        this.options.selectionLimit,
      );
      this.renderAttribute('items-limit-note', text);
    }
  },

  onDialogOpen() {
    // Re-attaching the detached children.
    this.$el.append(this.detachedElements);
    this.detachedElements = null;
    // And position it to the center with the new content
    this.$el.dialog('option', 'position', this.options.position);

    BackboneEx.View.Dialog.prototype.onDialogOpen.call(this);
    this.$('.multiple-services-groups').shadows(this.groupShadowsOptions);
    this.$('.multiple-services-list:visible').shadows(this.listShadowsOptions);

    this.triggerOpenEvent();
  },

  triggerOpenEvent() {
    if (this.options.fromWizard) {
      // do not trigger if opened from onboarding wizard
      return;
    }
    App.trigger(Wahanda.Event.MENU_QUICK_WIZARD_OPENED);
  },

  setCurrentSorting() {
    const sort =
      this.model.get('treatmentsCollection').getCompareMethod() === 'popularity' ? 'pop' : 'abc';
    this.$(`li[data-sort=${sort}]`).addClass('on');
  },

  renderTabs() {
    const treatmentTypes = this.model.get('treatmentTypesCollection');
    const treatments = this.model.get('treatmentsCollection');
    const params = [];
    const self = this;
    treatmentTypes.each(function (tType) {
      if (!treatments.hasTreatmentsForTypeId(tType.id)) {
        // Don't render empty treatment types
        return;
      }

      const itemCount = self.getActiveTreatmentCount(tType.get('id'));
      params.push({
        id: tType.get('id'),
        title: tType.get('name'),
        count: itemCount,
        showCount: itemCount > 0,
      });
    });

    this.$('.multiple-services-groups-list').html(
      Wahanda.Template.renderTemplate('menu-group-treatment-type-items', {
        types: params,
      }),
    );
  },

  renderTabContent() {
    const treatmentTypes = this.model.get('treatmentTypesCollection');
    const treatments = this.model.get('treatmentsCollection');
    const params = [];
    const self = this;
    treatmentTypes.each(function (tType) {
      if (!treatments.hasTreatmentsForTypeId(tType.id)) {
        // Don't render empty treatment types
        return;
      }

      const typeId = tType.get('id');
      const item = {
        treatmentGroupId: typeId,
        treatments: [],
      };

      const list = treatments.getByTypeId(typeId);
      for (let i = 0, len = list.length; i < len; i++) {
        item.treatments.push({
          id: list[i].get('id'),
          name: list[i].get('name'),
          checked: self.isTreatmentChecked(list[i]),
        });
      }

      params.push(item);
    });

    this.$('.multiple-service-items').html(
      Wahanda.Template.renderTemplate('menu-group-service-service-items', {
        treatmentTypes: params,
      }),
    );
  },

  /**
   * Returns active checked treatments for the given treatment type.
   *
   * @param int typeId
   * @return int Count
   */
  getActiveTreatmentCount(typeId) {
    const treatments = this.model.get('treatmentsCollection').getByTypeId(typeId);
    const self = this;
    return _.reduce(
      treatments,
      function (memo, t) {
        return memo + (self.isTreatmentChecked(t) ? 1 : 0);
      },
      0,
    );
  },

  /**
   * Is the given treatment checked as active?
   *
   * @param Backbone.Model treatment
   * @return boolean
   */
  isTreatmentChecked(treatment) {
    if (typeof this.selected[treatment.get('id')] === 'boolean') {
      return this.selected[treatment.get('id')];
    }
    return _.indexOf(this.options.selectedTreatments, treatment.get('id')) !== -1;
  },

  changeSorting(e, item) {
    this.model
      .get('treatmentsCollection')
      .setCompareMethod(item.data('sort') === 'abc' ? 'name' : 'popularity')
      .sort();

    const selectedTab = this.getVisibleTab();
    this.setupDialog();
    this.selectTab(selectedTab.find('a').attr('href'));
  },

  getVisibleTab() {
    return this.$('.multiple-services-groups-list').find('.ui-tabs-active');
  },

  selectTab(hash) {
    this.$('#multiple-services-tabs').tabs('select', hash);
  },

  onCheckboxClicked(event) {
    const checkbox = $(event.target);
    if (this.options.onCheckboxClicked != null) {
      this.options.onCheckboxClicked(checkbox.prop('checked'));
    }

    this.updateTreatmentTypeCount(checkbox);

    const treatmentId = checkbox.val();
    this.selected[treatmentId] = checkbox.prop('checked');

    this.toggleActionButtons();
    this.toggleCheckboxEditing();
  },

  updateTreatmentTypeCount(checkbox) {
    const groupCheckboxes = checkbox.closest('.multiple-services-list');
    const treatmentGroupId = groupCheckboxes.data('treatmentGroupId');
    const length = groupCheckboxes.find('input:checked').length;
    const countSpan = this.getGroupNodeById(treatmentGroupId).find('.count');

    countSpan.text(length);
    if (length === 0) {
      countSpan.hide();
    } else {
      countSpan.show();
    }
  },

  toggleCheckboxEditing() {
    if (this.options.selectionLimit > 0) {
      const $checkboxes = this.$('input[type=checkbox]');
      const checkedCount = $checkboxes.filter(':checked').length;

      if (checkedCount >= this.options.selectionLimit) {
        $checkboxes.not(':checked').prop('disabled', true);
      } else {
        $checkboxes.filter(':disabled').prop('disabled', false);
      }
    }
  },

  /**
   * Saves the changes on the model.
   */
  save() {
    const self = this;
    const list = [];

    this.$('input[type=checkbox]:checked').each(function () {
      list.push(parseInt($(this).val(), 10));
    });

    if (list.length === 0 && !this.options.allowEmpty) {
      // TODO: notice?
      return;
    }

    const submitBtn = this.$('.save-action').action('doing');
    this.disableForm();

    if (this.options.saveCallback) {
      this.options.saveCallback(list, this);
    } else {
      // Run default action
      this.model.addTreatments(
        list,
        function () {
          self.close();

          App.trigger(Wahanda.Event.MENU_QUICK_WIZARD_SAVED);

          App.initVenue();
        },
        function () {
          self.enableForm();
          submitBtn.action();
        },
      );
    }
    // Cancel form submit
    // eslint-disable-next-line consistent-return
    return false;
  },

  getGroupNodeById(id) {
    return this.$(`#treatment-type-${id}`);
  },

  onTabSelect(ui) {
    // Fix for multiple shadows
    $(ui.panel)
      .siblings('.multiple-services-list')
      .each(function () {
        const shadow = $(this).data('shadows');
        if (shadow) {
          shadow.destroy();
        }
      });
    $(ui.panel).shadows(this.listShadowsOptions);
  },

  toggleActionButtons() {
    const checkedCount = this.$('input[type=checkbox]:checked').length;
    let isEnabled = this.options.allowEmpty || checkedCount > 0;
    if (
      isEnabled &&
      this.options.selectionLimit > 0 &&
      this.options.selectionLimit < checkedCount
    ) {
      isEnabled = false;
    }
    this.$('.dialog-actions button').toggleFormElements(isEnabled);
  },
});
