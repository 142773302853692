import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import Wahanda from 'common/wahanda';
import { Initializer } from 'common/types/initializers';
import { SingleGroup } from './store/types';
import { AddServicesModal } from './container';

const store = storeBuilder();
const container = Wahanda.Dialogs.getReactContainer();
interface Params {
  singleGroup?: SingleGroup;
}

export const AddServicesModalInitializer = (params: Params): Initializer => {
  const onClose = () => ReactDOM.unmountComponentAtNode(container);
  return {
    render: () => {
      ReactDOM.render(
        <Provider store={store}>
          <AddServicesModal onClose={onClose} singleGroup={params.singleGroup} />
        </Provider>,
        container,
      );
    },
    destroy: onClose,
  };
};
