import superagent from 'superagent';

// FIXME remove me and import me from the common utils
function noOp() {
  return null;
}

export function GET(uri, successCallback, errorCallback?) {
  return superagent
    .get(uri)
    .accept('application/json')
    .then(successCallback, errorCallback || noOp);
}

export function PUT(uri, data, successCallback, errorCallback?) {
  return superagent
    .put(uri)
    .send(data)
    .accept('application/json')
    .then(successCallback, errorCallback || noOp);
}

export function POST(uri, data, successCallback, errorCallback?) {
  return superagent
    .post(uri)
    .send(data)
    .accept('application/json')
    .then(successCallback, errorCallback || noOp);
}

export function DELETE(uri, data, successCallback, errorCallback?) {
  return superagent
    .del(uri)
    .accept('application/json')
    .then(successCallback, errorCallback || noOp);
}
