export const TRANSACTION_TYPE = {
  VOUCHER_TIP_WITHDRAWAL: 'VOUCHER_TIP_WITHDRAWAL',
  WITHDRAWAL: 'WITHDRAWAL',
  DEPOSIT: 'DEPOSIT',
  CASH_REGISTER_CORRECTION_WITHDRAWAL: 'CASH_REGISTER_CORRECTION_WITHDRAWAL',
  CASH_REGISTER_CORRECTION_DEPOSIT: 'CASH_REGISTER_CORRECTION_DEPOSIT',
  CARD_TIP_WITHDRAWAL: 'CARD_TIP_WITHDRAWAL',
  REFUNDED_CARD_TIP_DEPOSIT: 'REFUNDED_CARD_TIP_DEPOSIT',
  REFUNDED_VOUCHER_TIP_DEPOSIT: 'REFUNDED_VOUCHER_TIP_DEPOSIT',
};

export const TYPE = {
  CANCELLATION: 'CANCELLATION',
  CHECKOUT: 'CHECKOUT',
  VENUE_TRANSACTION: 'VENUE_TRANSACTION',
};

export const PAYMENT_TYPE = {
  CASH: 'CASH',
  PREPAID: 'PREPAID',
  CARD: 'CARD',
  VOUCHER: 'VOUCHER',
  TAP_TO_PAY: 'TAP_TO_PAY',
};
