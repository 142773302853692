/* eslint-disable func-names */
/* global BackboneEx _ */
/**
 * Settings.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { enableRecording } from 'common/analytics';
import { NavigationAnalytics, L2Page, L3Page, mapPageToL3Enum } from 'common/tracking/navigation';
import URI from 'urijs';

import Wahanda from 'common/wahanda';
import { Reconnect } from 'federation/Reconnect';
import App from 'common/backbone-app';

const VENUE_SETTING_NAV = '#venue-settings';
const VENUE_SETTING_NAV_TABS = `${VENUE_SETTING_NAV}-tabs`;
const OPENING_HOURS_TAB = '#opening-hours';
const BANK_DETAILS_CONTAINER = '#bank-details';

const SHOW_NEW_BANK_DETAILS_PAGE_FEATURE = 'CD-1415-new-Bank-Details-page';
const SHOW_NEW_FINANCE_SETTINGS_PAGE_FEATURE = 'CD-1277-finance-settings-page';
const SHOW_PAYOUT_FLEXIBILITY_FEATURE = 'CD-1279-supplier-payout-settings';
const SHOW_LIVE_BALANCE_FEATURE = 'CD-1299-live-balance';

App.Views.Settings = BackboneEx.View.Main.extend(
  {
    /**
     * @var App.Views.Form
     */
    currentFormView: null,
    venueModel: null,
    suppliermodel: null,
    micrositesModel: null,
    /**
     * Hash of formViews.
     */
    formViews: {},

    initialize: function initialize() {
      enableRecording();
      this.$('#google-maps-scripts').html(
        Wahanda.Template.renderTemplate('google-maps-api', {
          key: App.config.getGoogleMapApiKey(),
        }),
      );

      const venueModel = new App.Models.Venue({
        venueId: null,
        relations: ['opening-hours', 'images', 'fulfillment-communication', 'phones'],
      });
      this.venueModel = venueModel;

      const micrositesModel = new App.Models.Microsite();
      this.micrositesModel = micrositesModel;

      const catCollection = new App.Collections.EmployeeCategories();
      catCollection.nameComparatorEnabled(false);

      const supplierModel = new App.Models.Supplier({
        id: null,
      });
      this.supplierModel = supplierModel;

      this.formViews['venue-details'] = new App.Views.Settings.VenueDetails({
        el: this.$('#venue-details'),
        model: venueModel,
      });
      this.formViews['opening-hours'] = new App.Views.Settings.OpeningHours({
        el: this.$(OPENING_HOURS_TAB),
        model: venueModel,
      });
      this.formViews.resources = new App.Views.Settings.VenueResources({
        el: this.$('#resources'),
        model: venueModel,
      });
      this.formViews.pos = new App.Views.Settings.Pos({
        el: this.$('#pos'),
        model: venueModel,
      });
      this.formViews.payments = new App.Views.Settings.SupplierPayments({
        el: this.$('#payments'),
        model: supplierModel,
      });

      this.formViews['billing-details'] = new App.Views.Settings.SupplierSettings({
        el: this.$('#billing-details'),
        model: supplierModel,
      });
      this.formViews['bank-details'] = new App.Views.Settings.SupplierBankDetails({
        el: this.$('#bank-details'),
        model: supplierModel,
      });
      this.formViews.fulfillment = new App.Views.Settings.NotificationsSettings({
        el: this.$('#fulfillment'),
        model: venueModel,
      });
      this.formViews['client-notifications'] = new App.Views.Settings.ClientNotifications({
        el: this.$('#client-notifications'),
      });
      this.formViews['online-booking-settings'] = new App.Views.Settings.OnlineBookingSettings({
        el: this.$('#online-booking-settings'),
        model: venueModel,
      });
      this.formViews['online-booking-integrations'] = new App.Views.Settings.Integrations({
        el: this.$('#online-booking-integrations .data-content'),
        model: venueModel,
      });
      this.formViews['online-booking-widget'] = new App.Views.Settings.OnlineBookingWidget({
        el: this.$('#online-booking-widget'),
        model: venueModel,
        micrositesModel,
      });

      const self = this;

      App.on('app:loaded', () => {
        const venueId = App.config.get('venue').id;
        const supplierId = App.config.get('venue').supplierId;

        const modelFetchOptions = {
          success: function success(model) {
            model.trigger('fetched');
          },
        };

        micrositesModel.clear({ silent: true });
        micrositesModel.fetch(modelFetchOptions);

        venueModel.set('venueId', venueId).fetch(modelFetchOptions);
        if (supplierId > 0) {
          supplierModel.set('id', supplierId).fetch(modelFetchOptions);
        }

        catCollection.setVenueId(venueId);
      });

      App.on(Wahanda.Event.APP_VENUE_CHANGED, () => {
        if (self.selectedSubviewTabId() === '#settings') {
          self.renderFinanceSettings();
        }
        if (self.selectedSubviewTabId() === '#payments') {
          self.renderLiveBalance();
        }
      });

      // Stop navigation into another module, if the current form has changes
      App.headerView.on('change-module', (cancelCallback) => {
        if (!self.canChangeTab()) {
          cancelCallback();
        }
      });

      // Setting the default module & tab
      let options = this.options.options;
      if (!options || (options && !options.initialModule && !options.initialTab)) {
        options = _.extend(options || {}, {
          initialModule: 'venue-settings',
          initialTab: 'venue-details',
          initialIgu: '',
        });
        this.options.options = options;
      }
      this.currentFormView = this.formViews[options.initialTab] || null;

      // add Iglu bits
      const $igluTabLink = $('#iglu-settings-links');
      if (App.isIgluEnabled()) {
        $igluTabLink.show();
        $('#iglu-settings-venue').show();
        // Don't trigger any tab functionality
        $('#iglu-supplier-link').click((event) => {
          event.stopPropagation();
          App.Views.Dialog.IgluModal.openIgluSupplierSettings(App.config.get('venue').supplierId);
        });
        $('#iglu-venue-link').click((event) => {
          event.stopPropagation();
          App.Views.Dialog.IgluModal.openIgluVenueSettings(App.config.get('venue').id);
        });
      } else {
        // Not masq`d in, won't be anytime in this login session. Remove the tab.
        $igluTabLink.remove();
        $('#iglu-settings-venue').remove();
      }

      this.rendered = false;
    },

    /**
     * Renders the whole settings view.
     */
    render: function render() {
      if (!Wahanda.Permissions.accessSettings()) {
        window.location = Wahanda.Url.getFullUrl('calendar');
        return;
      }

      App.headerView.setModule('settings');
      const self = this;
      const options = this.options.options;

      this.mainTabs = this.$el.wShow().tabs({
        // follow tab hash tags in URL
        select: function select(event, ui) {
          if (self.canChangeTab() && ui.tab.hash !== '#void') {
            if (self.selectedSubviewTabId() === BANK_DETAILS_CONTAINER) {
              self.renderBankDetails();
            }
            if (self.selectedSubviewTabId() === 'settings') {
              self.renderFinanceSettings();
            }

            if (self.selectedSubviewTabId() === 'payments') {
              self.renderLiveBalance();
            }
            const availableTabHash = self.availableSubviewTabId(ui.tab.hash);
            const availableTab = availableTabHash && availableTabHash.replace('#', '');
            const innerTab = availableTab || '';
            const venueUrl = `${App.config.currentVenueHash()}/${ui.tab.hash.replace('#', '')}/`;
            let tabUrl = venueUrl;

            if (self.selectedSubviewTabId() === availableTabHash) {
              // Refetch the inner view model contents, if any
              self.onSubviewChange(innerTab);
              tabUrl = `${venueUrl}${innerTab || ''}`;
            } else if (innerTab) {
              // Allowed and selected tabs do not match. Select the allowed one.
              self.$(`${ui.tab.hash}-tabs`).tabs('select', availableTabHash);
              self.onSubviewChange(availableTab);
              tabUrl = `${venueUrl}${availableTab || ''}`;
            }
            let igluEndpoint = '';
            if (App.mainViewOptions != null && App.mainViewOptions.initialIglu) {
              // the initialIglu value should be missing /_,
              // in order to rebuild the url we add it back in here.
              igluEndpoint = `/_${App.mainViewOptions.initialIglu}`;
            }
            // stop iglu from being added to all tab changes
            if (window.location.hash.indexOf(igluEndpoint) === -1) {
              igluEndpoint = '';
            }
            App.mainRouter.navigate(tabUrl + igluEndpoint);
            return true;
          }
          return false;
        },
      });

      this.$(VENUE_SETTING_NAV_TABS)
        .add('#finance-tabs')
        .add('#notifications-settings-tabs')
        .add('#online-booking-tabs')
        .tabs({
          // follow tab hash tags in URL
          select: function select(event, ui) {
            if (self.canChangeTab()) {
              const subviewHash = ui.tab.hash.replace('#', '');
              const subPage = mapPageToL3Enum(subviewHash);
              self.onSubviewChange(subviewHash);
              App.mainRouter.navigate(
                `${App.config.currentVenueHash()}/${self
                  .selectedMainTabId()
                  .replace('#', '')}/${subviewHash}`,
              );
              NavigationAnalytics.trackPageEventView(subPage);
              return true;
            }
            return false;
          },
        });

      // Check for payNow parameter. Native new URL not supported for IE11
      const windowUrl = new URI(window.location.href);
      if (windowUrl.query(true).payNow) {
        this.mainTabs.tabs('select', '#finance');
      } else if (options && options.initialModule) {
        this.mainTabs.tabs('select', `#${options.initialModule}`);
        options.initialModule = null;
      }
      if (options && options.initialTab) {
        this.$(`${this.selectedMainTabId()}-tabs`).tabs('select', `#${options.initialTab}`);
        const initialFirstTabs = [
          L3Page.VENUE_DETAILS,
          'payments',
          L3Page.CLIENT_NOTIFICATIONS,
          'online-booking-widget',
        ];
        if (initialFirstTabs.includes(options.initialTab)) {
          NavigationAnalytics.trackPageEventView(mapPageToL3Enum(options.initialTab));
        }
        options.initialTab = null;
      }
      App.Views.Dialog.IgluModal.validateAndShow(options.initialIglu);

      this.setTabVisibility();

      this.renderSidebarInfo();

      App.setUpGoLive();

      this.rendered = true;
    },

    renderSidebarInfo() {
      const sidebarInfoNode = this.$('#sidebar-info').get(0);
      App.ES6.Initializers.SidebarInfo({
        node: sidebarInfoNode,
        liveChatSource: 'settings sidebar',
      }).render();
    },

    renderBankDetails() {
      if (Wahanda.Features.isEnabled(SHOW_NEW_BANK_DETAILS_PAGE_FEATURE)) {
        const node = this.$(BANK_DETAILS_CONTAINER).get(0);
        ReactDOM.render(
          <Reconnect
            name="BankDetailsPage"
            props={{
              notifyFn: Wahanda.ToastNotifications.sendMessage,
            }}
          />,
          node,
        );
      }
    },

    renderFinanceSettings() {
      if (Wahanda.Features.isEnabled(SHOW_NEW_FINANCE_SETTINGS_PAGE_FEATURE)) {
        const node = this.$('div[data-finance-settings-tab]').get(0);
        ReactDOM.render(
          <Reconnect
            name="FinanceSettingsPage"
            props={{
              notifyFn: Wahanda.ToastNotifications.sendMessage,
              hasPayoutFlexibility: Wahanda.Features.isEnabled(SHOW_PAYOUT_FLEXIBILITY_FEATURE),
            }}
          />,
          node,
        );
      }
    },

    renderLiveBalance() {
      if (Wahanda.Features.isEnabled(SHOW_LIVE_BALANCE_FEATURE)) {
        const node = this.$('#live-balance').get(0);
        ReactDOM.render(<Reconnect name="LiveBalance" />, node);
      }
    },

    /**
     * Returns the currently selected tab`s ID hash.
     *
     * @return string
     */
    selectedMainTabId: function selectedMainTabId() {
      const curTab = this.mainTabs.find('#nav2 .ui-tabs-active a')[0];
      return curTab.hash;
    },

    /**
     * Returns the main view`s selected inner tab, if any.
     * @param string mainViewId OPTIONAL Main view to use,
     * in format #view-id. If none given, takes current.
     * @return string | null
     */
    selectedSubviewTabId: function selectedSubviewTabId(mainViewId) {
      const mainView = $(mainViewId || this.selectedMainTabId());
      const tab = mainView.find('.ui-tabs-nav:first .ui-tabs-active a')[0];
      return tab ? tab.hash : null;
    },

    availableSubviewTabId: function availableSubviewTabId(mainViewId) {
      if (mainViewId === '#online-booking') {
        return '#online-booking-widget';
      }
      const mainView = $(mainViewId || this.selectedMainTabId());
      const $tabs = mainView.find('.ui-tabs-nav:first li');
      let $tab = $tabs.filter('.ui-tabs-active').not('.hidden');
      if (!$tab.length) {
        $tab = $tabs.not('.hidden').eq(0);
      }
      const tab = $tab.find('a')[0];

      return tab ? tab.hash : null;
    },

    /**
     * Can the user safely change the tab?
     *
     * @return boolean
     */
    canChangeTab: function canChangeTab() {
      if (this.rendered) {
        const view = this.currentFormView;
        return view && view.canNavigateAway ? view.canNavigateAway() : true;
      }
      return true;
    },

    /**
     * Handles changing the subview.
     *
     * @param string subviewHash
     */
    onSubviewChange: function onSubviewChange(subviewHash) {
      if (this.currentFormView) {
        // Trigger the "hidden" event for the view to perform any needed cleanup operations
        this.currentFormView.trigger('hidden');
      }
      this.currentFormView = this.formViews[subviewHash];
      if (
        this.currentFormView &&
        this.currentFormView.model &&
        (this.currentFormView.model.id ||
          (this.venueModel === this.currentFormView.model && this.venueModel.id))
      ) {
        // Refetch the model to stay up-to-date. Fetch only if it has ID defined.
        this.currentFormView.model.fetch({
          success: function success(model) {
            model.trigger('fetched');
          },
        });
      }
      if (this.currentFormView) {
        this.currentFormView.trigger('visible');
      }

      if (subviewHash === 'bank-details') {
        // landing on the Bank Details tab
        this.renderBankDetails();
      }

      if (subviewHash === 'settings') {
        this.renderFinanceSettings();
      }

      if (subviewHash === 'payments') {
        this.renderLiveBalance();
      }
    },

    addSidebarClickTracking() {
      const PAGE_TABS = {
        '#venue-settings-tab': L2Page.VENUE_DETAILS,
        '#finance-tab': L2Page.FINANCE,
        '#notifications-settings-tab': L2Page.NOTIFICATIONS,
        '#online-booking-tab': L2Page.ONLINE_BOOKING,
      };
      const PAGE_SUB_TABS = {
        [L2Page.VENUE_DETAILS]: L3Page.VENUE_DETAILS,
        [L2Page.FINANCE]: L3Page.PAYMENTS,
        [L2Page.NOTIFICATIONS]: L3Page.CLIENT_NOTIFICATIONS,
        [L2Page.ONLINE_BOOKING]: L3Page.ONLINE_BOOKING_WIDGET,
      };

      const namespace = '.settingsViewTracking';

      Object.keys(PAGE_TABS).forEach((tab) => {
        this.$(tab)
          .off(namespace)
          .on(`click${namespace}`, () => {
            NavigationAnalytics.trackPageEventView(PAGE_SUB_TABS[PAGE_TABS[tab]]);
            NavigationAnalytics.trackSidebarClick(PAGE_TABS[tab]);
          });
      });
    },

    addPageTabClickTracking() {
      const PAGE_TABS = {
        // Venue
        '#venue-settings-subtabs-details': L3Page.VENUE_DETAILS,
        '#venue-settings-subtabs-opening-hours': L3Page.OPENING_HOURS,
        '#resources-tab': L3Page.RESOURCES,
        // Finance
        '#finance-subtab-payments': L3Page.PAYMENTS,
        '#finance-subtab-billing-details': L3Page.BILLING_DETAILS,
        '#finance-subtab-bank-details': L3Page.BANK_DETAILS,
        '#finance-subtab-pos': L3Page.POS,
        '#finance-subtab-settings': L3Page.SETTINGS,
        // Notifications
        '#notifications-client-notifications-link': L3Page.CLIENT_NOTIFICATIONS,
        '#notifications-fulfillment-link': L3Page.FULFILLMENT,
        // Online booking
        '#online-booking-subtab-widget': L3Page.ONLINE_BOOKING_WIDGET,
        '#online-booking-subtab-settings': L3Page.ONLINE_BOOKING_SETTINGS,
        '#online-booking-subtab-integrations': L3Page.ONLINE_BOOKING_INTEGRATIONS,
      };

      const namespace = '.settingsTabsTracking';

      Object.keys(PAGE_TABS).forEach((tab) => {
        this.$(tab)
          .off(namespace)
          .on(`click${namespace}`, () => {
            NavigationAnalytics.trackPageTabClick(PAGE_TABS[tab]);
          });
      });
    },

    /**
     * Showing/hiding tabs based on permissions.
     *
     * Please use the "hidden" class for hiding as this is required in other places.
     */
    setTabVisibility: function setTabVisibility() {
      const $allTabs = this.$('#nav2').children();
      const $allSubTabs = this.$('.nav3').find('li');

      const currentPage = mapPageToL3Enum(this.selectedSubviewTabId().replace('#', ''));
      NavigationAnalytics.trackPageView(currentPage);

      // Show all possibly previously hidden elements
      $allTabs.add($allSubTabs).wShow();
      this.$el.wShow();

      if (Wahanda.Permissions.manageVenueDetails()) {
        // eslint-disable-next-line
        this.setTabVisibilityByConfig();
      } else {
        let $toHide = $allTabs;
        // No permissions for viewing/editing.
        // Check for separate permissions and show tabs accordingly.
        if (Wahanda.Permissions.viewFinanceData()) {
          $toHide = $toHide
            .not('#finance-tab')
            // Hide all subtabs of finance, except for remittances
            .add(this.$('#finance-tabs').find('.nav3').find('li').not('#finance-subtab-payments'));
        }
        // Hide tabs to which the user does not have any permissions
        $toHide.wHide();

        if ($allTabs.filter(':visible').length === 0) {
          // No tabs visible. Hide the whole settings view.
          this.$el.wHide();
        } else {
          // Select first active tab. And subtab.
          const $firstTab = $allTabs.filter(':visible').eq(0);
          this.mainTabs.tabs('select', $firstTab.find('a').attr('href'));
        }
      }
      this.addSidebarClickTracking();
      this.addPageTabClickTracking();
    },

    setTabVisibilityByConfig: function setTabVisibilityByConfig() {
      const showFinancesTab = Wahanda.Permissions.viewFinanceData();
      this.$('#finance-tab').toggleClass('hidden', !showFinancesTab);

      if (!showFinancesTab && this.selectedMainTabId() === '#finance') {
        this.mainTabs.tabs('select', VENUE_SETTING_NAV);
      }

      // Integrations settings tab
      const integrationsPermitted = App.isAdministrator() || App.isMasquerading();
      this.$('#online-booking-subtab-integrations').wToggle(integrationsPermitted);

      // Dated tabs: DEPRECATED
      const sellsDated = App.config.canSellDated();
      this.$('#room-types-tab').wToggle(sellsDated);
      if (!sellsDated && this.selectedSubviewTabId() === '#room-types') {
        this.$(`${VENUE_SETTING_NAV}-tabs`).tabs('select', '#venue-details');
      }

      const posEnabled = App.config.get('venue').pointOfSaleEnabled;
      this.$('#finance-subtab-pos').wToggle(posEnabled);
      if ((!posEnabled || !showFinancesTab) && this.selectedSubviewTabId() === '#pos') {
        this.$('#finance-tabs').tabs('select', '#billing-details');
      }

      const giftVouchers = App.isFeatureSupported('wahanda-voucher');
      this.$('#venue-vouchers-tab').wToggle(giftVouchers);
      if (!giftVouchers && this.selectedSubviewTabId() === '#venue-vouchers') {
        this.$(VENUE_SETTING_NAV_TABS).tabs('select', '#venue-details');
      }

      // manage tabs based on new finance settings page feature flag
      if (Wahanda.Features.isEnabled(SHOW_NEW_FINANCE_SETTINGS_PAGE_FEATURE)) {
        const migratedTabs = ['billing-details', 'bank-details', 'pos'];
        migratedTabs.forEach((tab) => this.$(`#finance-subtab-${tab}`).wToggle(false));

        const currentSubTab = this.selectedSubviewTabId().replace('#', '');
        const isTryingToShowOldSubTab = migratedTabs.some((tab) => tab === currentSubTab);

        if (isTryingToShowOldSubTab) {
          this.$('#finance-tabs').tabs('select', '#settings');
        }
      } else {
        // hide settings tab
        this.$('#finance-subtab-settings').wToggle(false);
      }
    },

    isSubviewActive: function isSubviewActive(view) {
      return this.currentFormView === view;
    },
  },
  {
    renderPreviewLink: function renderPreviewLink($link, venueModel) {
      const canShowPreviewLink = App.isFeatureSupported('marketplace');
      const previewLink = canShowPreviewLink && venueModel.getLinkByType('O');

      if (previewLink) {
        $link.attr('href', previewLink.uri);
      }
      $link.wToggle(!!previewLink);
    },
  },
);

App.Views.Settings.Dynamic = {};
