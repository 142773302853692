import React from 'react';
import formStyles from './ClientFormsCreate.scss';
import { Input } from 'components/common/Input';
import Wahanda from 'common/wahanda';

const columnsLang = Wahanda.lang.customer.import.example.columns;
const personalInfoLang = Wahanda.lang.marketing.clientForms.personalInformation;

export const ClientFormsCreatePersonalInformation = () => (
  <div className={formStyles.personalInfoWrapper}>
    <div className={formStyles.personalInfoHeading}>
      <h3>{personalInfoLang.title}</h3>
    </div>
    <div className={formStyles.personalInfoContent}>
      <div className={formStyles.personalInfoContentNameInputs}>
        <Input name="firstName" value={columnsLang.firstName} fullBorder newLook disabled />
        <Input name="lastName" value={columnsLang.lastName} fullBorder newLook disabled />
      </div>
      <Input name="dateOfBirth" value={personalInfoLang.dateOfBirth} fullBorder newLook disabled />
      <Input name="Address" value={personalInfoLang.address} fullBorder newLook disabled />
      <Input name="email" value={columnsLang.emailAddress} fullBorder newLook disabled />
      <Input name="phoneNumber" value={columnsLang.phone} fullBorder newLook disabled />
      <div className={formStyles.personalInfoContentCheckbox}>
        <div className={formStyles.checkbox}></div>
        <p>{personalInfoLang.checkboxDisclaimer}</p>
      </div>
    </div>
  </div>
);
