import React, { useState } from 'react';
import Dialog from 'components/common/react-dialog';
import Wahanda from 'common/wahanda';
import { DialogFooter, DialogFooterButtonProps } from 'components/common/DialogFooter';
import { BOOKING_ACTOR } from 'common/consts';
import { NoShowFlowAnalytics } from 'common/analytics';
import App from 'common/backbone-app';
import { NoShowOverPhone } from './NoShowOverPhone/NoShowOverPhone';
import { ExplanationBullets } from '../ExplanationBullets';
import style from './NoShowDialog.scss';
import { AppointmentInfoBoxes } from '../AppointmentInfoBoxes/AppointmentInfoBoxes';
import { buildExplanationBullets } from '../ExplanationBullets/buildExplanationBullets';
import { ExplanationBulletPointTranslations } from '../ExplanationBullets/explanationBulletTranslations';
import { PaymentProtection } from '../PaymentProtection';
import { PaymentProtectionBanner } from '../PaymentProtection/PaymentProtectionBanner';
import { usePaymentProtectionQuery } from '../PaymentProtection/usePaymentProtectionQuery';

interface Props {
  type?: 'over-phone';
  onClose: () => void;
  onDoNoShow: (_?: { preventPaymentProtection: boolean | null }) => void;
  isPrepaid: boolean;
  bookingActor: BOOKING_ACTOR;
  isFirstTimeCustomer: boolean;
  consumerId: number;
  isWalkin: boolean;
  paymentProtected: boolean;
  granularity: 'appointment' | 'appointment-group';
  id: number;
}

export const NoShowDialog = (props: Props) => {
  const {
    type,
    onClose,
    onDoNoShow,
    isFirstTimeCustomer,
    consumerId,
    isWalkin,
    isPrepaid,
    bookingActor,
    paymentProtected,
    granularity,
    id,
  } = props;

  const [preventPaymentProtection, setPreventPaymentProtection] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const { isLoading, response } = usePaymentProtectionQuery(
    granularity,
    id,
    paymentProtected,
    'no-show',
  );

  const isPaymentProtectionVisible =
    paymentProtected && !isLoading && Boolean(response?.canPreventForNoShow);

  if (type === 'over-phone') {
    return <NoShowOverPhone onClose={onClose} />;
  }

  const onPaymentProtectionChange = (value: boolean) => {
    setPreventPaymentProtection(value);
    setDirty(true);
    NoShowFlowAnalytics.trackPaymentProtection({ preventPaymentProtection: value });
  };

  const isSubmitDisabled = () => {
    if (!paymentProtected) {
      return false;
    }

    return isLoading || (isPaymentProtectionVisible && !isDirty);
  };

  const onNoShowClick = () => {
    onDoNoShow({
      preventPaymentProtection: isPaymentProtectionVisible ? preventPaymentProtection : null,
    });
    onClose();

    if (isPaymentProtectionVisible) {
      NoShowFlowAnalytics.trackPaymentProtectionSubmit({ preventPaymentProtection });
    }

    NoShowFlowAnalytics.trackNoShowConfirmation();
    if (!isWalkin && App.config.canShowPrepayRequirementFeatures()) {
      App.ES6.Initializers.PrepayRequireDialog({
        customerId: consumerId,
      }).render();
    }
  };
  const buttons: DialogFooterButtonProps[] = [
    {
      title: Wahanda.lang.shared.close,
      type: 'secondary',
      onClick: onClose,
    },
    {
      title: Wahanda.lang.calendar.appointments.noShow.buttons.defaultAction,
      type: 'alert',
      onClick: onNoShowClick,
      disabled: isSubmitDisabled(),
      dataTest: 'no-show-submit',
    },
  ];
  const bulletPoints = buildExplanationBullets({
    flow: 'no-show',
    bookingActor,
    isFirstTimeCustomer,
    isPrepaid,
    canPreventPaymentProtection: isPaymentProtectionVisible,
  }).map((key) => ExplanationBulletPointTranslations.get(key));

  return (
    <Dialog
      dataTest="no-show-modal"
      width={420}
      title={Wahanda.lang.calendar.appointments.noShow.requestNoShowPhone.title}
      onClose={onClose}
      classes={style.dialog}
    >
      <AppointmentInfoBoxes isPrepaid={isPrepaid} bookingActor={bookingActor} />
      {isPaymentProtectionVisible && <PaymentProtectionBanner />}
      {isPaymentProtectionVisible && (
        <PaymentProtection flow="no-show" onChange={onPaymentProtectionChange} />
      )}
      <ExplanationBullets bulletPoints={bulletPoints} />
      <DialogFooter fullWidth buttons={buttons} />
    </Dialog>
  );
};
