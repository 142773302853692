import { createSelector } from 'reselect';

export const getDiscountId = (state): number => {
  return state.discountEmailCampaignDialog.discountId;
};

const getDiscountRules = (state) => state.menuDiscount.discountRules;

const getCurrentDiscountRule = createSelector(
  [getDiscountId, getDiscountRules],
  (discountId, discountRules) => {
    return discountRules.find((rule) => rule.id === discountId);
  },
);

export const isCurrentDiscountActive = createSelector(
  getCurrentDiscountRule,
  (currentDiscount) => (currentDiscount && currentDiscount.active) || false,
);

export const isDiscountOffpeakActive = createSelector(
  [getCurrentDiscountRule],
  (currentDiscountRule) => {
    if (!currentDiscountRule || !currentDiscountRule.offpeakDiscounts) {
      return false;
    }

    return currentDiscountRule.offpeakDiscounts.active;
  },
);

export const isLastMinuteActive = createSelector(
  [getCurrentDiscountRule],
  (currentDiscountRule) => {
    if (!currentDiscountRule || !currentDiscountRule.lastMinuteDiscount) {
      return false;
    }

    return currentDiscountRule.lastMinuteDiscount.active;
  },
);

export const getAssignedServices = createSelector(
  getCurrentDiscountRule,
  (currentDiscountRule) => (currentDiscountRule && currentDiscountRule.assignedMenuItems) || [],
);
