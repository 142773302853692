/**
 * Prevent the autofocus feature of jQuery UI Dialog.
 * Monkey-patching. Should be removed if we'll update to a newer UI version.
 */
$.ui.dialog.prototype.open = function() {
    if (this._isOpen) { return; }

        var self = this,
            options = self.options,
            uiDialog = self.uiDialog;

        self.overlay = options.modal ? new $.ui.dialog.overlay(self) : null;
        self._size();
        self._position(options.position);
        uiDialog.show(options.show);
        self.moveToTop(true);

        // prevent tabbing out of modal dialogs
        if ( options.modal ) {
            uiDialog.bind( "keydown.ui-dialog", function( event ) {
                if ( event.keyCode !== $.ui.keyCode.TAB ) {
                    return;
                }

                var tabbables = $(':tabbable', this),
                    first = tabbables.filter(':first'),
                    last  = tabbables.filter(':last');

                if (event.target === last[0] && !event.shiftKey) {
                    first.focus(1);
                    return false;
                } else if (event.target === first[0] && event.shiftKey) {
                    last.focus(1);
                    return false;
                }
            });
        }

        var toFocus;
        if (Wahanda.Util.isTouchDevice()) {
            // Set focus to a button or the dialog, not a tabbable item.
            // This is what the patch does.
            toFocus = $(uiDialog.find('.dialog-actions :tabbable').get().concat(
                    uiDialog.get())).eq(0);
        } else {
            // set focus to the first tabbable element in the content area or the first button
            // if there are no tabbable elements, set focus on the dialog itself
            toFocus = $(self.element.find(':tabbable').not('.no-focus').get().concat(
                uiDialog.find('.ui-dialog-buttonpane :tabbable').get().concat(
                    uiDialog.get()))).eq(0);
        }

        toFocus.focus();

        self._isOpen = true;
        self._trigger('open');

        return self;
};
