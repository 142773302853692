import { combineReducers } from 'redux';

import dashboard from 'components/dashboard/reducer';
import menuDiscount from 'components/menu/DiscountsTab/reducer';
// TODO: move me inside menuDiscount ^^^
import discountEmailCampaignDialog from 'components/menu/DiscountsTab/EmailCampaignDialog/reducer';
import discountRule from 'components/menu/OfferPricing/DiscountRuleRow/reducer';
import selectService from 'components/menu/SelectServiceDialog/store/reducer';
import { menu } from 'components/menu/reducers';
import salesReport from 'components/reports/newSalesReports/reducer';
import discountMenuItems from 'components/menu/DiscountRuleDialog/TreatmentList/reducer';
import { bankDetails } from 'components/settings/PaymentMessage/reducer';
import { posStatus } from 'reduxStore/common/posStatus/reducer';
import venueStats from 'components/dashboard/VenueStats/reducer';
import { rankingBoost } from 'components/dashboard/Reviews/reducer';
import appointmentNotifications from 'reduxStore/common/appointmentNotifications/reducer';
import marketing from 'components/marketing/reducers';
import widgetSettings from 'components/settings/online/Widget/redux/reducers';
import products from 'reduxStore/common/products/reducer';
import productOrders from 'components/menu/ProductOrdersTab/reducer';
import employees from 'reduxStore/common/employees/reducer';
import externalSalonIntegration from 'components/settings/externalSalonIntegration/reducer';
import productSuppliers from 'components/menu/ProductSuppliers/reducer';
import clients from 'components/clients/reducers';
import customers from 'reduxStore/common/customers/reducer';
import { shifts } from 'components/shifts/store/reducer';
import { stripeReducer } from 'components/settings/PaymentMessage/PaymentContainer/Stripe/reducer';
import { calendar } from 'components/calendar/reducers';
import { rootTransactionDialogReducer } from 'components/reports/transactions/RootTransactionDialog/reducer';
import { transactionsExportReducer } from 'components/reports/ExportDialog/reducer';
import { signatureReducer } from 'components/reports/transactions/SignatureSearchModal/reducer';
import { dailyReportReducer } from 'reduxStore/common/dailyReport/reducer';
import { covidBanner } from 'components/CovidBanner/reducer';
import { coronaReportReducer } from 'components/reports/Reports/CoronaReportModal/reducer';
import { servicesTabReducer } from 'components/menu/ServicesTab/store/reducer';
import { trunkFeaturesReducer } from 'common/trunk-features/store/reducer';
import { posOperationReducer } from 'reduxStore/common/posOperation/reducer';
import { appSettingsReducer } from 'reduxStore/common/appSettings/reducers';
import { venueDetails } from 'components/settings/VenueDetails/reducer';

const reducers = {
  customers,
  dashboard,
  rankingBoost,
  menuDiscount,
  discountEmailCampaignDialog,
  discountRule,
  selectService,
  menu,
  salesReport,
  discountMenuItems,
  bankDetails,
  posStatus,
  venueStats,
  appointmentNotifications,
  marketing,
  widgetSettings,
  products,
  productOrders,
  employees,
  externalSalonIntegration,
  productSuppliers,
  clients,
  stripe: stripeReducer,
  shifts,
  calendar,
  dialog: rootTransactionDialogReducer,
  transactionsExport: transactionsExportReducer,
  signature: signatureReducer,
  dailyReport: dailyReportReducer,
  covidBanner,
  coronaReport: coronaReportReducer,
  servicesTab: servicesTabReducer,
  trunkFeatures: trunkFeaturesReducer,
  posOperation: posOperationReducer,
  appSettings: appSettingsReducer,
  venueDetails,
};

// custom createAction has wrong interface.
// eventually, bankDetails reducer expects actions with mandatory payload,
// but AnyAction interface does not require it.
// ts-action or some different union types solution should be used instead
export const appReducer = combineReducers(reducers);
