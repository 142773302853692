import React from 'react';

const SuccessImage = (props) => (
  <svg width={191} height={136} {...props} viewBox="0 0 191 136">
    <defs>
      <path id="path-1" d="M0 162.293 217.715 162.293 217.715 0 0 0z" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-40" transform="translate(-10.000000, -21.000000)">
        <path id="Stroke-39" d="M0.5 161.793 217.214 161.793 217.214 0.5 0.5 0.5z" />
        <path id="Fill-37" d="M0.5 161.793 217.214 161.793 217.214 0.5 0.5 0.5z" />
        <path
          d="M52.3385,51.7631 L54.4235,59.0791 C55.1115,61.4961 57.0005,63.3851 59.4175,64.0741 L66.7345,66.1591 L59.4175,68.2431 C57.0005,68.9321 55.1115,70.8211 54.4235,73.2381 L52.3385,80.5531 L50.2535,73.2381 C49.5655,70.8211 47.6765,68.9321 45.2595,68.2431 L37.9435,66.1591 L45.2595,64.0741 C47.6765,63.3851 49.5655,61.4961 50.2535,59.0791 L52.3385,51.7631 Z"
          id="Fill-1"
          fill="#D5CF8D"
        />
        <path
          d="M109.8278,105.3315 L195.3928,77.6405 C198.2868,76.7045 199.8728,73.6005 198.9358,70.7075 L193.2728,53.2085 C183.4458,58.6595 171.3788,60.4625 160.8088,58.0025 C155.8688,56.8525 151.2938,54.8375 146.3498,53.6965 C136.2558,51.3675 124.7808,52.9265 115.2658,57.9185 C106.1848,62.6825 99.0148,70.5165 95.6168,79.2985 L102.8938,101.7885 C103.8308,104.6815 106.9338,106.2675 109.8278,105.3315"
          id="Fill-3"
          fill="#FF5C39"
        />
        <path
          d="M100.483,57.2797 L107.515,59.7067 L143.484,72.1187 C145.389,72.7757 147.499,72.0907 148.654,70.4397 L181.583,23.3837 C180.418,22.9027 179.081,22.8167 177.787,23.2357 L92.221,50.9257 C89.328,51.8627 87.742,54.9667 88.678,57.8597 L102.894,101.7887 C103.831,104.6807 106.934,106.2667 109.828,105.3317 L195.393,77.6397 C198.287,76.7047 199.873,73.5997 198.936,70.7077 L185.513,29.2287"
          id="Stroke-5"
          stroke="#222C57"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M131.7946,68.768 L106.1986,105.008"
          id="Stroke-7"
          stroke="#222C57"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M198.1383,74.6713 L155.9953,61.3793"
          id="Stroke-9"
          stroke="#222C57"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M52.3385,51.7631 L54.4235,59.0791 C55.1115,61.4961 57.0005,63.3851 59.4175,64.0741 L66.7345,66.1591 L59.4175,68.2431 C57.0005,68.9321 55.1115,70.8211 54.4235,73.2381 L52.3385,80.5531 L50.2535,73.2381 C49.5655,70.8211 47.6765,68.9321 45.2595,68.2431 L37.9435,66.1591 L45.2595,64.0741 C47.6765,63.3851 49.5655,61.4961 50.2535,59.0791 L52.3385,51.7631 Z"
          id="Stroke-11"
          stroke="#222C57"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M21.7394,78.6107 L21.7394,82.5317"
          id="Stroke-13"
          stroke="#D5CF8D"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M21.7394,93.1732 L21.7394,97.0942"
          id="Stroke-15"
          stroke="#D5CF8D"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M30.9811,87.8529 L27.0601,87.8529"
          id="Stroke-17"
          stroke="#D5CF8D"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M16.4181,87.8529 L12.4981,87.8529"
          id="Stroke-19"
          stroke="#D5CF8D"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M110.0436,69.5473 C98.3866,65.4333 85.6086,72.3093 76.8346,81.0163 C68.0596,89.7233 61.2276,100.5493 50.9826,107.4683 C39.5446,115.1933 24.3736,117.0863 11.3876,112.4073"
          id="Stroke-21"
          stroke="#222C57"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M115.0621,81.5248 C103.4051,77.4108 90.6271,84.2868 81.8531,92.9938 C73.0791,101.7008 66.2461,112.5268 56.0011,119.4458 C44.5631,127.1708 29.3921,129.0638 16.4061,124.3848"
          id="Stroke-23"
          stroke="#222C57"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M82.3776,117.4184 L82.3776,120.2654"
          id="Stroke-25"
          stroke="#D5CF8D"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M82.3776,127.9906 L82.3776,130.8376"
          id="Stroke-27"
          stroke="#D5CF8D"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M89.0875,124.1283 L86.2405,124.1283"
          id="Stroke-29"
          stroke="#D5CF8D"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M78.5153,124.1283 L75.6683,124.1283"
          id="Stroke-31"
          stroke="#D5CF8D"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M47.3483,130.8373 L49.1063,137.0053 C49.6863,139.0423 51.2793,140.6353 53.3163,141.2153 L59.4843,142.9733 L53.3163,144.7313 C51.2793,145.3123 49.6863,146.9033 49.1063,148.9413 L47.3483,155.1093 L45.5903,148.9413 C45.0093,146.9033 43.4173,145.3123 41.3793,144.7313 L35.2113,142.9733 L41.3793,141.2153 C43.4173,140.6353 45.0093,139.0423 45.5903,137.0053 L47.3483,130.8373 Z"
          id="Fill-33"
          fill="#D5CF8D"
        />
        <path
          d="M47.3483,130.8373 L49.1063,137.0053 C49.6863,139.0423 51.2793,140.6353 53.3163,141.2153 L59.4843,142.9733 L53.3163,144.7313 C51.2793,145.3123 49.6863,146.9033 49.1063,148.9413 L47.3483,155.1093 L45.5903,148.9413 C45.0093,146.9033 43.4173,145.3123 41.3793,144.7313 L35.2113,142.9733 L41.3793,141.2153 C43.4173,140.6353 45.0093,139.0423 45.5903,137.0053 L47.3483,130.8373 Z"
          id="Stroke-35"
          stroke="#222C57"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1" />
        </mask>
        <g id="Clip-38" />
      </g>
    </g>
  </svg>
);

export default SuccessImage;
