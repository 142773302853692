import { fetch as fetchPOSConfig, POS_SETTINGS_KEY } from 'src/services/posSettings';
import {
  fetch as fetchVenuePOSConfig,
  VENUE_POS_SETTINGS_KEY,
} from 'src/services/venuePosSettings';
import { get } from 'mori';
import { appState } from 'state';

export function fetchConfig() {
  fetchPOSConfig();
  fetchVenuePOSConfig();
}

export function configFetched() {
  return get(appState.deref(), POS_SETTINGS_KEY) && get(appState.deref(), VENUE_POS_SETTINGS_KEY);
}
