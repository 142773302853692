import React from 'react';

type SvgDonutProps = {
  outerRadius: number;
  innerRadius: number;
  outerColor: string;
  innerColor: string;
};

export const SvgDonut: React.SFC<SvgDonutProps> = (props) => {
  const { outerRadius, innerRadius, outerColor, innerColor } = props;
  return (
    <svg height={outerRadius * 2} width={outerRadius * 2}>
      <circle cx={outerRadius} cy={outerRadius} r={outerRadius} fill={outerColor} />
      <svg height={outerRadius * 2} width={outerRadius * 2}>
        <circle cx={outerRadius} cy={outerRadius} r={innerRadius} fill={innerColor} />
      </svg>
    </svg>
  );
};
