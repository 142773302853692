import React from 'react';
import { ModalCloseButton } from 'components/common/Modal';
import classnames from 'classnames';

type TitleBarProps = {
  label: string | JSX.Element;
  onClose: (...args: any[]) => any;
  newLook: boolean;
};
const TitleBar: React.SFC<TitleBarProps> = (props) => {
  const { label, onClose, newLook } = props;

  const className = classnames('ui-dialog-titlebar dialog-draggable', {
    'dialog-draggable-titlebar-flat': newLook,
  });

  return (
    <div data-hj-suppress className={className}>
      <div className="dialog2--title">{label}</div>
      <div style={{ margin: '-8px 0 0 auto' }}>
        <ModalCloseButton onClose={onClose} dataTest="title-bar-close-button" />
      </div>
    </div>
  );
};

export default TitleBar;
