import React from 'react';
import _ from 'common/underscore';
import MonthPickerFilter from 'components/common/Filters/MonthPickerFilter';
import { generatePath } from 'react-router-dom';
import moment from 'common/moment';
import { MonthlySalesPeriodReportAnalytics } from 'common/analytics';
import { POS_REPORTS_ROUTES } from 'components/reports/newSalesReports/SalesReportContainer/reportRoutes';
import SalesPeriodReport from '../BaseSalesPeriodReport/container';

interface Props {
  locale: string;
  match: {
    params: {
      venueId: string;
      from?: string;
      to?: string;
    };
  };
  history: {
    push: (url: string) => void;
  };
}

export default class MonthlySalesPeriodReport extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.onMonthChanged = this.onMonthChanged.bind(this);
  }

  public componentDidMount() {
    MonthlySalesPeriodReportAnalytics.trackPageView();
  }

  public onMonthChanged(month: string) {
    this.props.history.push(
      generatePath(POS_REPORTS_ROUTES.MONTH_POS_REPORT, {
        venueId: this.queryParams.venueId,
        from: moment(month).startOf('month').formatApiDateString(),
        to: moment(month).endOf('month').formatApiDateString(),
      }),
    );
  }

  private get queryParams() {
    const defaults = _.defaults(this.props.match.params, {
      from: moment().startOf('month').formatApiDateString(),
      to: moment().endOf('month').formatApiDateString(),
    });
    return {
      venueId: defaults.venueId,
      from: defaults.from,
      to: defaults.to,
    };
  }

  public render() {
    return (
      <SalesPeriodReport {...this.props} query={this.queryParams} filter={this.renderFilter()} />
    );
  }

  private renderFilter() {
    const { locale } = this.props;
    return (
      <MonthPickerFilter
        locale={locale}
        onMonthSelected={this.onMonthChanged}
        date={this.queryParams.from}
      />
    );
  }
}
