import React from 'react';
import _ from 'common/underscore';
import SelectDropdown from 'components/common/SelectDropdown';
import { generatePath } from 'react-router-dom';
import moment from 'common/moment';
import { POS_REPORTS_ROUTES } from 'components/reports/newSalesReports/SalesReportContainer/reportRoutes';
import { YearlySalesPeriodReportAnalytics } from 'common/analytics';
import SalesPeriodReport from '../BaseSalesPeriodReport/container';
import style from './style.scss';

interface Props {
  match: {
    params: {
      venueId: string;
      year?: string;
    };
  };
  venueCreated: Date;
  history: {
    push: (url: string) => void;
  };
}

export default class YearlySalesPeriodReport extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.onYearChanged = this.onYearChanged.bind(this);
  }

  public componentDidMount() {
    YearlySalesPeriodReportAnalytics.trackPageView();
  }

  public onYearChanged(year: string) {
    this.props.history.push(
      generatePath(POS_REPORTS_ROUTES.YEAR_POS_REPORT, {
        year,
        venueId: this.queryParams.venueId,
      }),
    );
  }

  public render() {
    return (
      <SalesPeriodReport {...this.props} query={this.queryParams} filter={this.renderFilter()} />
    );
  }

  private get queryParams() {
    const defaults = _.defaults(this.props.match.params, {
      year: moment().startOf('year').format('YYYY'),
    });
    const year = new Date(defaults.year); // moment does not take in YYYY format well;
    return {
      venueId: defaults.venueId,
      from: moment(year).startOf('year').formatApiDateString(),
      to: moment(year).endOf('year').formatApiDateString(),
    };
  }

  private renderFilter() {
    const years = this.getFilterYears().map((year) => ({
      name: year,
      value: year,
    }));

    return (
      <SelectDropdown
        extraClassName={style.yearPicker}
        onSelect={this.onYearChanged}
        selected={moment(this.queryParams.from).format('YYYY')}
        data={years}
      />
    );
  }

  private getFilterYears() {
    const result: string[] = [];
    const now = moment(Date.now());

    let iterator = moment(this.props.venueCreated);
    while (iterator.isSameOrBefore(now, 'year')) {
      result.push(iterator.format('YYYY'));
      iterator = iterator.add(1, 'year');
    }

    return result.reverse();
  }
}
