(function () {
  App.Views.Dialog.Booking.Dated = BackboneEx.View.Base.extend({
    render: function () {
      this.renderDate();
      this.renderStatus();
      this.renderActionButtons();
      this.renderGuests();
      this.renderVenueTitle();

      if (this.model.isSpaBreak()) {
        this.renderCheckout();
        this.renderRoomType();

        this.$('.b-spa-break').wShow();
      }
    },

    renderCheckout: function () {
      try {
        var date = this.model.getDatedDate();
        var checkoutDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + this.model.get('dated').numberOfNights,
        );

        var stringDate = Wahanda.Date.formatDate(
          App.config.get('jqueryDateFormat').longDate,
          checkoutDate,
        );
        this.$('.v-checkout-date').text(stringDate);
      } catch (e) {
        window.console && console.log && console.log('renderCheckout problem:', e);
      }
    },

    renderRoomType: function () {
      var roomName = (this.model.get('dated') || {}).roomTypeName;
      this.$('.v-room-name').text(roomName || '');
    },

    renderDate: function () {
      var date = this.model.getDatedDate();
      this.$('.day').text(date.getDate());
      this.$('.month').text(Wahanda.lang.date.months[date.getMonth()]);
      this.$('.year').text(date.getFullYear());
      this.renderAttribute('nights', this.model.get('dated').numberOfNights);
    },

    renderStatus: function () {
      this.$('.status-unpaid').remove();

      var $status = this.$('.status')
        .attr('class', 'status ' + this.model.getStatusClass())
        .text(this.model.getStatusText());
      if (this.model.isCancelled()) {
        this.renderAttribute('cancellation-reason', this.model.getCancellationReason());
      }
      if (this.model.isUnpaid()) {
        $('<span class="status status-unpaid"></span>')
          .text(Wahanda.lang.calendar.appointments.multi.labels.unpaid)
          .insertAfter($status);
      }
    },

    renderActionButtons: function () {
      this.$el.toggleClass('has-actions', this.model.isUnconfirmed());
    },

    renderGuests: function () {
      var guests = (this.model.get('dated') || {}).guests || [];
      guests.sort(function (a, b) {
        if (a.leadGuest) {
          return -1;
        } else if (b.leadGuest) {
          return 1;
        }
        return a.guestName.localeCompare(b.guestName);
      });

      if (guests.length === 1) {
        // Do not show "Lead" badge if only one guest
        guests[0].leadGuest = false;
      }

      this.$('.b-guest-list').html(
        Wahanda.Template.renderTemplate('booking-dated-guests', {
          list: guests,
        }),
      );
    },

    renderVenueTitle: function () {
      if (App.isContextFromExternalSoruce()) {
        var venue = (this.model.get('dated') || {}).venue;
        if (!venue) {
          return;
        }
        this.renderAttribute('venue-name', venue.name);
      }
    },

    // Events

    confirm: function () {
      var self = this;
      var appt = this.getAppointmentModel();
      var bookingView = this.options.bookingView;

      bookingView.disableForm();

      appt.confirm(
        function () {
          bookingView.close();
        },
        function () {
          self.$('.a-confirm-appointment').errorTip(Wahanda.lang.shared.savingFailed);
          bookingView.enableForm();
        },
      );
    },

    reject: function () {
      var appt = this.getAppointmentModel();
      var bookingView = this.options.bookingView;

      var rejectView = new App.Views.Calendar.AppointmentReject({
        model: appt,
      });
      rejectView.render();
      rejectView.open();

      appt.on('rejected', function () {
        bookingView.close();
      });
    },
  });
})();
