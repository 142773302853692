import React from 'react';
import moment from 'common/moment';
import TimeStamp from '../TimeStamp';
import style from './style.scss';

type ReportLayoutProps = {
  filter: JSX.Element;
  showTimeStamp?: boolean;
  graph?: JSX.Element;
  body?: JSX.Element;
  messages?: JSX.Element;
};
const ReportLayout: React.SFC<ReportLayoutProps> = (props) => (
  <div className={style.report}>
    <div className={style.reportHeader}>
      <div className={style.filterContainer}>{props.filter}</div>
      {props.showTimeStamp && <TimeStamp date={moment()} extraCssClassName={style.timeStamp} />}
    </div>
    {props.messages && <div className={style.message}> {props.messages} </div>}
    {props.graph}
    {props.body}
  </div>
);
ReportLayout.defaultProps = {
  showTimeStamp: false,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Element | u... Remove this comment to see the full error message
  graph: null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Element | u... Remove this comment to see the full error message
  messages: null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Element | u... Remove this comment to see the full error message
  body: null,
};

export default ReportLayout;
