import React from 'react';
import ReactDOM from 'react-dom';
import Wahanda from 'common/wahanda';
import style from './EmailTemplate.scss';
import TemplateField from './TemplateField';

const lang = Wahanda.lang.marketing.preview;
interface IEmailTemplateProps extends React.HTMLAttributes<Element> {
  isEditable?: boolean;
  content: {
    editing?: string;
    template?: {
      html?: string;
      subject?: string;
      message?: string;
      title?: string;
      signature?: string;
    };
    modifiedTemplate?: {
      subject?: string;
      message?: string;
      title?: string;
      signature?: string;
    };
  };
  config: {
    required?: boolean;
    name: string;
    type?: 'text' | 'textarea';
    minLength?: number;
    maxLength?: number;
    withinTemplate?: boolean;
  }[];
  onFieldClick?: (...args: any[]) => any;
  onFieldBlur?: (...args: any[]) => any;
}

interface EmailTemplateData {
  html: string;
  subject: string;
  message: string;
  title: string;
  signature: string;
}

export interface EmailTemplateField {
  name: string;
  required?: boolean;
  type?: 'text' | 'textarea';
  minLength?: number;
  maxLength?: number;
  withinTemplate?: boolean;
}

export interface EmailTemplateContent {
  editing: string;
  template: EmailTemplateData;
  modifiedTemplate: EmailTemplateData;
}

class EmailTemplate extends React.Component<IEmailTemplateProps, {}> {
  static defaultProps = {
    isEditable: true,
    onFieldClick: () => {},
    onFieldBlur: () => {},
  };

  componentDidMount() {
    this.updateEmailPlaceholders();
  }

  componentDidUpdate() {
    this.updateEmailPlaceholders();
  }

  updateEmailPlaceholders() {
    const { content, config, onFieldClick, onFieldBlur, isEditable } = this.props;
    const { modifiedTemplate, template, editing } = content;
    config
      .filter((field) => field.withinTemplate)
      .forEach((field) => {
        const node = document.getElementById(`email-template-${field.name}`);
        if (node) {
          ReactDOM.render(
            <TemplateField
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'boolea... Remove this comment to see the full error message
              isEditable={isEditable}
              name={field.name}
              editing={editing}
              config={field}
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              value={modifiedTemplate[field.name]}
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              defaultValue={template[field.name]}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type '(...ar... Remove this comment to see the full error message
              onClick={onFieldClick}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type '(...ar... Remove this comment to see the full error message
              onBlur={onFieldBlur}
            />,
            node,
          );
        }
      });
  }

  render() {
    const { content, config, onFieldClick, onFieldBlur, isEditable } = this.props;
    const { modifiedTemplate, template, editing } = content;
    const subjectConfig = config.find((field) => field.name === 'subject');
    return (
      <div className={style.container}>
        <div className={style.email}>
          <div className={style.subject}>
            <span className={style.subjectLabel}>{lang.subject}</span>
            <span className={style.subjectContent}>
              <TemplateField
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'boolea... Remove this comment to see the full error message
                isEditable={isEditable}
                name="subject"
                editing={editing}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type '{ requ... Remove this comment to see the full error message
                config={subjectConfig}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
                value={modifiedTemplate.subject}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
                defaultValue={template.subject}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type '(...ar... Remove this comment to see the full error message
                onClick={onFieldClick}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type '(...ar... Remove this comment to see the full error message
                onBlur={onFieldBlur}
              />
            </span>
          </div>
          <div className={style.emailContent}>
            {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
            {template.html && <div dangerouslySetInnerHTML={{ __html: template.html }} />}
          </div>
        </div>
      </div>
    );
  }
}

export default EmailTemplate;
