import { toClj } from 'mori';
import Wahanda from 'common/wahanda';

const HOUR_FORMS = [
  Wahanda.lang.datetime.duration['business-hour'].toLowerCase(),
  Wahanda.lang.datetime.duration['business-hours'].toLowerCase(),
];

const PERCENTAGE_OPTIONS = [
  {
    name: '5%',
    value: 5,
  },
  {
    name: '10%',
    value: 10,
  },
  {
    name: '15%',
    value: 15,
  },
  {
    name: '20%',
    value: 20,
  },
  {
    name: '25%',
    value: 25,
  },
  {
    name: '30%',
    value: 30,
  },
  {
    name: '35%',
    value: 35,
  },
  {
    name: '40%',
    value: 40,
  },
  {
    name: '45%',
    value: 45,
  },
  {
    name: '50%',
    value: 50,
  },
  {
    name: '55%',
    value: 55,
  },
  {
    name: '60%',
    value: 60,
  },
  {
    name: '65%',
    value: 65,
  },
  {
    name: '70%',
    value: 70,
  },
  {
    name: '75%',
    value: 75,
  },
  {
    name: '80%',
    value: 80,
  },
  {
    name: '85%',
    value: 85,
  },
  {
    name: '90%',
    value: 90,
  },
  {
    name: '95%',
    value: 95,
  },
  {
    name: '100%',
    value: 100,
  },
];

const HOUR_OPTIONS = [
  {
    name: Wahanda.Text.pluralizeNumber(1, HOUR_FORMS),
    value: 1,
  },
  {
    name: Wahanda.Text.pluralizeNumber(2, HOUR_FORMS),
    value: 2,
  },
  {
    name: Wahanda.Text.pluralizeNumber(3, HOUR_FORMS),
    value: 3,
  },
  {
    name: Wahanda.Text.pluralizeNumber(4, HOUR_FORMS),
    value: 4,
  },
  {
    name: Wahanda.Text.pluralizeNumber(5, HOUR_FORMS),
    value: 5,
  },
  {
    name: Wahanda.Text.pluralizeNumber(6, HOUR_FORMS),
    value: 6,
  },
  {
    name: Wahanda.Text.pluralizeNumber(7, HOUR_FORMS),
    value: 7,
  },
  {
    name: Wahanda.Text.pluralizeNumber(8, HOUR_FORMS),
    value: 8,
  },
  {
    name: Wahanda.Text.pluralizeNumber(9, HOUR_FORMS),
    value: 9,
  },
  {
    name: Wahanda.Text.pluralizeNumber(10, HOUR_FORMS),
    value: 10,
  },
  {
    name: Wahanda.Text.pluralizeNumber(11, HOUR_FORMS),
    value: 11,
  },
  {
    name: Wahanda.Text.pluralizeNumber(12, HOUR_FORMS),
    value: 12,
  },
];

export const PERCENTAGE_VECTORS = toClj(PERCENTAGE_OPTIONS);

export const HOUR_VECTORS = toClj(HOUR_OPTIONS);
