import React from 'react';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import { Print } from 'components/common/Icon';

import style from './PrintButton.scss';

export const PrintButton = () => {
  const onClick = () => {
    window.print();
  };

  return (
    <div className={style.print}>
      <Button
        icon={<Print className={style.icon} />}
        colour="help"
        variant="hollow"
        dataTest="print-button"
        label={Wahanda.lang.reports.sales.print}
        onClick={onClick}
      />
    </div>
  );
};

PrintButton.displayName = 'PrintButton';
