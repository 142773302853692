App.Collections.Remittances = Backbone.Collection.extend({
  model: App.Models.Remittance,

  url: function () {
    return App.Api.wsUrl('/supplier/' + App.config.get('venue').supplierId + '/remittances.json');
  },

  parse: function (data) {
    return data.remittances;
  },

  comparator: function (a, b) {
    var aDate = a.get('periodEndDate');
    var bDate = b.get('periodEndDate');

    if (aDate == bDate) {
      return a.id < b.id ? 1 : -1;
    }
    return aDate < bDate ? 1 : -1;
  },

  getNextPayDate: function () {
    var now = Wahanda.Date.createVenueDate();
    var dateDifference;
    var date;
    var smallestDifference;
    this.each(function (remittance) {
      if (remittance.isPending() && remittance.get('totalAmount') > 0) {
        date = remittance.getDate();
        if (date) {
          dateDifference = Wahanda.Date.getDayDifference(date, now);
          if (dateDifference >= 0) {
            if (!smallestDifference) {
              smallestDifference = dateDifference;
            }
            if (dateDifference <= smallestDifference) {
              smallestDifference = dateDifference;
            }
          }
        }
      }
    });
    return smallestDifference;
  },
});
