import React from 'react';
import Wahanda from 'common/wahanda';

import { L2Page, NavigationAnalytics } from 'common/tracking/navigation';

import Loader from 'components/common/Loader';
import { FAQs, FAQsProps } from 'components/shared/FAQs';
import { LayoutContainer, LayoutGrid, LayoutHeader } from 'components/common/Layout';
import { Box } from 'components/common/Box';

import { CATEGORY as trackCategory } from './tracking';

import style from './AutomatedMessaging.scss';
import { AutomatedMessagingTabs } from 'components/marketing/AutomatedMessaging/Tabs/';

const lang = Wahanda.lang.marketing.automatedMessaging;

export interface Props {
  isLoading: boolean;
  actions: {
    automatedMessagingSettingsRequest: () => void;
    automatedMessagingReset: () => void;
  };
}

export const AutomatedMessaging = ({
  isLoading,
  actions: { automatedMessagingSettingsRequest, automatedMessagingReset },
}: Props) => {
  React.useEffect(() => {
    automatedMessagingSettingsRequest();
    NavigationAnalytics.trackPageEventView(L2Page.AUTOMATED_MESSAGING);

    return automatedMessagingReset;
  }, []);

  if (isLoading) return <Loader positionAbsolute />;

  const list: FAQsProps = {
    header: lang.faq.header,
    trackCategory,
    questions: [
      {
        id: 'how-does-it-work',
        name: lang.faq.howDoesItWork,
        link: lang.faq.howDoesItWorkLink,
      },
      {
        id: 'how-to-get-the-best',
        name: lang.faq.howToGetTheBest,
        link: lang.faq.howToGetTheBestLink,
      },
      {
        id: 'gdpr',
        name: lang.faq.gdpr,
        link: lang.faq.gdprLink,
      },
    ],
  };

  return (
    <LayoutContainer>
      <LayoutGrid>
        <div>
          <div className={style.headerBox}>
            <Box dataTest="automated-messaging-header-box">
              <div>
                <LayoutHeader header={lang.header} subheader={lang.descriptionLine1} />
                <div>{lang.descriptionLine2}</div>
              </div>
            </Box>
          </div>
          <AutomatedMessagingTabs />
        </div>
        <FAQs {...list} />
      </LayoutGrid>
    </LayoutContainer>
  );
};
