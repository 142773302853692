import { ActionUnionTypes } from 'reduxStore/actionCreator';
import {
  CLIENTS_IMPORT_RESET,
  CLIENTS_FILE_UPLOAD_REQUEST,
  CLIENTS_FILE_UPLOAD_SUCCESS,
  CLIENTS_IMPORT_SELECT_FILE,
  CLIENTS_FILE_IMPORT_UPDATE,
  CLIENTS_FILE_IMPORT_FAILURE,
  CLIENTS_FILE_IMPORT_SUCCESS,
  CLIENTS_IMPORT_TO_CAMPAIGN_PROMPT,
} from 'reduxStore/actionsConst';

import { ClientsImportStage, Message, FileImportStatus } from './types';

import * as clientsImportActions from './actions';

export interface ImportStatus {
  messages?: Message[];
  percentage?: number;
  status?: FileImportStatus;
  importCount?: number;
}

export interface ClientsImportState {
  stage: ClientsImportStage;
  importStatus: ImportStatus;
  selectedFile: string | ArrayBuffer | null;
  selectedFileName: string | null;
  isFileUploading: boolean;
}

export const initialState: ClientsImportState = {
  stage: ClientsImportStage.INIT,
  importStatus: {},
  selectedFile: null,
  selectedFileName: null,
  isFileUploading: false,
};

export const clientsImport = (
  state = initialState,
  action: ActionUnionTypes<typeof clientsImportActions>,
): ClientsImportState => {
  switch (action.type) {
    case CLIENTS_IMPORT_RESET:
      return {
        ...initialState,
      };
    case CLIENTS_IMPORT_SELECT_FILE:
      return {
        ...state,
        selectedFile: action.payload.file,
        selectedFileName: action.payload.fileName,
      };
    case CLIENTS_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        isFileUploading: true,
      };
    case CLIENTS_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        isFileUploading: false,
        selectedFile: null,
        selectedFileName: null,
        stage: ClientsImportStage.IN_PROGRESS,
        importStatus: {
          percentage: 0,
        },
      };
    case CLIENTS_FILE_IMPORT_UPDATE:
      return {
        ...state,
        importStatus: {
          ...state.importStatus,
          ...action.payload,
        },
      };
    case CLIENTS_FILE_IMPORT_SUCCESS:
      return {
        ...state,
        stage: ClientsImportStage.FINISHED,
        importStatus: {
          ...state.importStatus,
          ...action.payload,
        },
      };
    case CLIENTS_FILE_IMPORT_FAILURE:
      return {
        ...state,
        stage: ClientsImportStage.FINISHED,
        importStatus: {
          ...state.importStatus,
          status: FileImportStatus.UNFINISHED_BECAUSE_OF_ERRORS,
        },
      };
    case CLIENTS_IMPORT_TO_CAMPAIGN_PROMPT:
      return {
        ...state,
        stage: ClientsImportStage.CAMPAIGN_PROMPT,
      };
    default:
      return state;
  }
};
