(function () {
  var DiscountCalculator = function (options) {
    this.defaults = options.defaults || {};
    this.offpeakDiscounts = options.offpeak;
    this.morningEndTime = options.morningEndTime;
    this.afternoonEndTime = options.afternoonEndTime;
  };

  /**
   * Gets the lowest price based on the set data.
   *
   * @param Date date
   * @param String time HH:MM
   * @param float fullAmount
   * @param float discountAmount
   *
   * @return float
   */
  DiscountCalculator.prototype.getPrice = function getPrice(
    date,
    time,
    fullAmount,
    discountAmount,
  ) {
    fullAmount = fullAmount || this.defaults.fullServicePrice;
    discountAmount = discountAmount || this.defaults.discountServicePrice;

    var salePrice = discountAmount && discountAmount < fullAmount ? discountAmount : fullAmount;
    if (this.offpeakDiscounts.enabled) {
      var offpeakPercentage = 100 - this.getOffpeakDiscountForDate(date, time);
      if (100 != offpeakPercentage && salePrice > (fullAmount * offpeakPercentage) / 100) {
        salePrice = (fullAmount * offpeakPercentage) / 100;
      }
    }
    return salePrice;
  };

  /**
   * Return discount in percent for the given day on the given time.
   *
   * @param Date date
   * @param String time HH:MM
   *
   * @return int 0..100%
   */
  DiscountCalculator.prototype.getOffpeakDiscountForDate = function getOffpeakDiscountForDate(
    date,
    time,
  ) {
    var day = date.getDay();
    time = isNaN(time) ? Wahanda.Time.timeToMinutes(time) : time;
    var discounts = this._getDiscountForWeekday(Wahanda.Date.dayNumberToStringMap[day]);
    var discountName = this._getDiscountNameByTime(time);

    return discounts && discounts[discountName] > 0 ? discounts[discountName] : 0;
  };

  DiscountCalculator.prototype._getDiscountForWeekday = function _getDiscountForWeekday(wday) {
    return _.find(this.offpeakDiscounts.days, function (item) {
      return item.weekday === wday;
    });
  };

  DiscountCalculator.prototype._getDiscountNameByTime = function _getDiscountNameByTime(time) {
    if (time > this.afternoonEndTime) {
      return 'eveningDiscountPercentage';
    } else if (time > this.morningEndTime) {
      return 'afternoonDiscountPercentage';
    }
    return 'morningDiscountPercentage';
  };

  Wahanda.DiscountCalculator = DiscountCalculator;
})();
