import React from 'react';
import ReactDOM from 'react-dom';
import ReactGlobal from 'components/ReactGlobal';

function initialize() {
  const targetNode = document.getElementById('react-root');
  ReactDOM.render(<ReactGlobal />, targetNode);
}

export default { initialize };
