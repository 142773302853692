/* eslint no-throw-literal: 0 */
import _ from 'common/underscore';

export const FILTERS = {
  ALL: 'ALL',
  ACTIVE: 'ACT',
  INACTIVE: 'INC',
  LISTED: 'LST',
  UNLISTED: 'UNL',
  NONPURCHASABLE: 'NPR',
};

export const SERVICE_TYPES = {
  TREATMENT: 'T',
  LEGACY_PACKAGE: 'P',
  SERVICE_PACKAGE: 'S',
  SPA_DAY: 'Y',
  SPA_BREAK: 'O',
};

function filterByType(offers, filterType = FILTERS.ACTIVE) {
  switch (filterType) {
    case FILTERS.ACTIVE:
    case FILTERS.LISTED:
      return offers.filter((o) => o.visible);
    case FILTERS.INACTIVE:
    case FILTERS.UNLISTED:
      return offers.filter((o) => !o.visible);
    case FILTERS.NONPURCHASABLE:
      return offers.filter((o) => !o.purchasable);
    case FILTERS.ALL:
    default:
      return offers;
  }
}

function filterByServiceType(offers, typeCodes) {
  return offers.filter((o) => typeCodes.includes(o.menuItemTypeCode));
}

function isFilterValid(filterType) {
  return !!Object.values(FILTERS).find((value) => value === filterType);
}

export default function filter(data, opts?) {
  const { filterType, includedServiceTypes } = _.defaults(_.isObject(opts) ? opts : {}, {
    filterType: FILTERS.ACTIVE,
  });
  const safeData = Object.assign({ offers: [], groups: [] }, data);

  if (!isFilterValid(filterType)) {
    throw new Error(`provided filter type '${filterType}' is not supported!`);
  }

  let offers = filterByType(safeData.offers, filterType);

  if (Array.isArray(includedServiceTypes) && includedServiceTypes.length > 0) {
    offers = filterByServiceType(offers, includedServiceTypes);
  }

  return { groups: safeData.groups, offers };
}
