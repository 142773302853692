import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'common/moment';
import { CalendarAnalytics } from 'common/analytics';
import * as actions from '../store/actions';

import { getVenueDayWorkingHours } from '../utils/helpers';
import { rotaPermissions } from '../utils/permissions';

import { getScheduleId, getShiftEmployee, getEmployeeDayWorkingHours } from '../store/selectors';

import { ShiftEmployeeModal as Modal } from './ShiftEmployeeModal';

const mapStateToProps = ({ shifts }, { employeeId, date, trackingCategory = '' }) => {
  const employee = getShiftEmployee(shifts, { employeeId });
  const employeeWorkingHours = getEmployeeDayWorkingHours(shifts, {
    employeeId,
    date,
  });
  const venueWorkingHours = getVenueDayWorkingHours(date, shifts.venue.workingHours);
  const scheduleId = getScheduleId(shifts, {
    employeeId,
    date,
  });

  return {
    ...rotaPermissions({
      employeeId,
      employeeWorkingHours,
      venueWorkingHours,
    }),
    employee,
    employeeWorkingHours,
    isShownOnCalendar: trackingCategory === CalendarAnalytics.CATEGORY,
    isBeforeCurrentWeek: moment(date).isBefore(moment(), 'week'),
    isBusinessHoursLoaded: shifts.isBusinessHoursLoaded,
    scheduleId,
    venueWorkingHours,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ShiftEmployeeModal = connect(mapStateToProps, mapDispatchToProps)(Modal);
