import React from 'react';
import style from './VerticalSteps.scss';
import classNames from 'classnames';

interface Props {
  steps: Array<string>;
  stepChecked?: number;
}

export const VerticalSteps = ({ steps, stepChecked = 0 }: Props) => {
  return (
    <ul className={style.container}>
      {steps.map((step, index) => (
        <li
          key={index}
          className={classNames(style.step, {
            [style.checked]: index < stepChecked,
            [style.lastChecked]: index + 1 === stepChecked,
          })}
        >
          <p className={style.content}>{step}</p>
        </li>
      ))}
    </ul>
  );
};

VerticalSteps.displayName = 'VerticalSteps';
