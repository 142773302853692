import {
  CLIENTS_BULK_DELETE_REQUEST,
  CLIENTS_BULK_DELETE_SUCCESS,
  CLIENTS_BULK_DELETE_SERVER_ERROR,
  CLIENTS_RESET,
} from 'reduxStore/actionsConst';

export const clientsBulkDeleteAction = () => ({
  type: CLIENTS_BULK_DELETE_REQUEST,
});

export const clientsBulkDeleteSuccessAction = (deletedCount) => ({
  type: CLIENTS_BULK_DELETE_SUCCESS,
  deletedCount,
});

export const clientsBulkDeleteServerErrorAction = () => ({
  type: CLIENTS_BULK_DELETE_SERVER_ERROR,
});

export const clientsResetAction = () => ({
  type: CLIENTS_RESET,
});
