import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import { InputError } from 'components/common/__BaseCommon';
import UnderlinedInput from './index';
import style from './style.scss';

interface IUnderlinedInputWithFormsyProps extends React.HTMLAttributes<Element> {
  isFormSubmitted: (...args: any[]) => any;
  getErrorMessage: (...args: any[]) => any;
  formsyClass?: string;
  value?: string | number;
}
type UnderlinedInputWithFormsyState = {
  blured?: boolean;
};
class UnderlinedInputWithFormsy extends Component<
  IUnderlinedInputWithFormsyProps,
  UnderlinedInputWithFormsyState
> {
  static defaultProps = {
    value: '',
    formsyClass: '',
  };

  state = {
    blured: false,
  };

  onBlur = () => {
    const { value } = this.props;
    if (value === '' || value == null) {
      return;
    }
    this.setState({
      blured: true,
    });
  };

  getProps = () => {
    const propsToExclude = [
      'getValue',
      'hasValue',
      'isFormDisabled',
      'isValid',
      'isPristine',
      'isFormSubmitted',
      'isRequired',
      'isValidValue',
      'resetValue',
      'setValidations',
      'getErrorMessage',
      'getErrorMessages',
      'setValue',
      'showRequired',
      'showError',
      'innerRef',
      'validationError',
      'validationErrors',
      'formsyClass',
      'validations',
    ];
    return Object.keys(this.props)
      .filter((propKey) => propsToExclude.indexOf(propKey) === -1)
      .reduce((accumulator, key) => Object.assign(accumulator, { [key]: this.props[key] }), {});
  };

  showError() {
    if (
      (this.props.isFormSubmitted() || this.state.blured) &&
      this.props.getErrorMessage() != null
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { formsyClass } = this.props;
    const inputClassName = this.showError() ? style.error : null;
    return (
      <div className={formsyClass}>
        <UnderlinedInput
          {...this.getProps()}
          onBlur={this.onBlur}
          inputClassName={inputClassName}
        />
        {this.showError() && <InputError message={this.props.getErrorMessage()} />}
      </div>
    );
  }
}

export default withFormsy(UnderlinedInputWithFormsy);
