import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  discountRulesOffPeakTransformer,
  discountRulesLastMinuteTransformer,
  discountRulesAppliedTreatmentCountTransformer,
  discountRulesBookingHoursTransformer,
  discountRulesAfternoonEndTimeTransformer,
  discountRulesMorningEndTimeTransformer,
  discountRulesTreatmentPackageFlagTransformer,
} from 'common/transformers/DiscountRules';

import { venueDetailsDayPercentagesTransformer } from 'common/transformers/VenueDetails';

import * as actions from './actions';
import { hideEmailCampaignDialog } from './EmailCampaignDialog/actions';
import DiscountsComponent from './index';

const mapDispatchToProps = (dispatch) =>
  Object.assign(
    {},
    {
      actions: {
        ...bindActionCreators(actions, dispatch),
        hideEmailCampaignDialog: bindActionCreators(hideEmailCampaignDialog, dispatch),
      },
    },
  );

const venueDetailsTransformer = (venueDetails) =>
  venueDetailsDayPercentagesTransformer(venueDetails);

// There has to be a way to clean this up that I'm missing at the moment.
const discountRulesTransformer = (discountRules, venueDetails) =>
  discountRulesOffPeakTransformer(
    discountRulesLastMinuteTransformer(
      discountRulesAppliedTreatmentCountTransformer(
        discountRulesTreatmentPackageFlagTransformer(
          discountRulesBookingHoursTransformer(
            discountRulesAfternoonEndTimeTransformer(
              discountRulesMorningEndTimeTransformer(discountRules, venueDetails),
              venueDetails,
            ),
            venueDetails,
          ),
        ),
      ),
    ),
  );

const mapStateToProps = ({ menuDiscount: { discountRules, venueDetails, ...menuDiscount } }) => ({
  ...menuDiscount,
  venueDetails: venueDetailsTransformer(venueDetails),
  discountRules: discountRulesTransformer(
    discountRules,
    venueDetails, // This is the untransformed data
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscountsComponent);
