import Wahanda from 'common/wahanda';
import { ExplanationBulletPoint } from './buildExplanationBullets';

export const ExplanationBulletPointTranslations = new Map([
  [
    ExplanationBulletPoint.WILL_CHARGE_COMMISSION,
    Wahanda.lang.calendar.appointments.cancellation.confirmCancellation.bulletPoints
      .willChargeCommission,
  ],
  [
    ExplanationBulletPoint.WILL_NOT_CHARGE_COMMISSION,
    Wahanda.lang.calendar.appointments.cancellation.confirmCancellation.bulletPoints
      .willNotChargeCommission,
  ],
  [
    ExplanationBulletPoint.WILL_REFUND_CUSTOMER,
    Wahanda.lang.calendar.appointments.cancellation.confirmCancellation.bulletPoints.refundCustomer,
  ],
  [
    ExplanationBulletPoint.CANCELLATION_EMAIL,

    Wahanda.lang.calendar.appointments.cancellation.confirmCancellation.bulletPoints
      .customerConfirmEmail,
  ],
  [
    ExplanationBulletPoint.WILL_RECEIVE_PAID_AMOUNT,
    Wahanda.lang.calendar.appointments.cancellation.confirmCancellation.bulletPoints
      .willReceivePaidAmount,
  ],
  [
    ExplanationBulletPoint.NO_SHOW_EMAIL,
    Wahanda.lang.calendar.appointments.cancellation.confirmNoShow.bulletPoints.customerConfirmEmail,
  ],
  [
    ExplanationBulletPoint.NO_SHOW_EMAIL_PAYMENT_PROTECTED,
    Wahanda.lang.paymentProtection.bulletPoints.noShowEmail,
  ],
  [
    ExplanationBulletPoint.CANCELLATION_EMAIL_PAYMENT_PROTECTED,
    Wahanda.lang.paymentProtection.bulletPoints.cancellationEmail,
  ],
]);
