import { Transform } from '@dnd-kit/utilities';

export const transformToString = (transform: Transform | null): string => {
  if (!transform) {
    return '';
  }

  const { x, y, scaleX, scaleY } = transform;

  return `translate3d(${x}px, ${y}px, 0) scale(${scaleX}, ${scaleY})`;
};
