import React, { Fragment, PureComponent } from 'react';
import Wahanda from 'common/wahanda';
import Dialog from 'components/common/react-dialog';
import Loader from 'components/common/Loader';
import { Notice } from 'components/common/Notice';
import UnderlinedInput from 'components/common/UnderlinedInput';
import { StripePaymentSucceeded } from './StripePaymentSucceeded';
import { FinancePaymentsTracking } from '../../tracking';
import { CardInput } from '../Stripe';
import style from './stripeCheckout.scss';

const lang = Wahanda.lang.settings.supplier.payments.payNowDialog;
interface IStripeCheckoutProps extends React.HTMLAttributes<Element> {
  paymentInProgress: boolean;
  stripe?: any;
  handlePayment: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
  error?: boolean;
  paymentAmount: number;
  success?: boolean;
  reFetchRemittances: (...args: any[]) => any;
  linkClickHandler: (...args: any[]) => any;
}
type StripeCheckoutState = {
  cardholderName?: string;
  saveCardInfo?: boolean;
};

export class StripeCheckout extends PureComponent<IStripeCheckoutProps, StripeCheckoutState> {
  cardRef: any;

  static defaultProps = {
    stripe: undefined,
    error: undefined,
    success: undefined,
  };

  constructor(props) {
    super(props);
    this.cardRef = React.createRef();
    this.state = {
      cardholderName: '',
      saveCardInfo: false,
    };
  }

  submitCheckout = () => {
    const { handlePayment, paymentInProgress } = this.props;
    if (paymentInProgress) {
      return;
    }
    FinancePaymentsTracking.trackPayButtonClicked();
    handlePayment({
      paymentAmount: this.props.paymentAmount,
      cardholderName: this.state.cardholderName,
      cardElement: this.cardRef.current.element,
      saveCardInfo: this.state.saveCardInfo,
    });
  };

  onErrorLinkClick = () => {
    FinancePaymentsTracking.trackShowBankLinkClick();
    this.props.linkClickHandler();
  };

  renderError() {
    const { error } = this.props;
    const errorLang = lang.errorPayment;
    const errorMessage = errorLang.body;
    if (!error) {
      return null;
    }
    return (
      <div className={style.errorContainer}>
        <Notice type="alert" className={style.errorAttention}>
          <div>
            <strong>{errorLang.header}</strong>
            <span>{errorMessage}</span>
            <span
              className={style.errorLink}
              onClick={this.onErrorLinkClick}
              role="link"
              tabIndex={0}
            >
              {errorLang.link}
            </span>
          </div>
        </Notice>
      </div>
    );
  }

  renderSuccess() {
    const { onClose, reFetchRemittances } = this.props;
    return <StripePaymentSucceeded onClose={onClose} reFetchRemittances={reFetchRemittances} />;
  }

  renderForm() {
    const { stripe, paymentAmount, paymentInProgress } = this.props;
    return (
      <Fragment>
        {this.renderError()}
        <div className={style.checkoutForm}>
          <div className={style.nameInput}>
            <UnderlinedInput
              label={lang.paymentForm.cardholdersName}
              placeholder={lang.paymentForm.cardholdersPlaceholder}
              // @ts-expect-error ts-migrate(2769) FIXME: Property 'value' does not exist on type 'Intrinsic... Remove this comment to see the full error message
              value={this.state.cardholderName}
              name="cardholderName"
              onChange={(event) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                this.setState({ cardholderName: event.target.value });
              }}
            />
          </div>
          <CardInput label={lang.paymentForm.creditCard} ref={this.cardRef} stripe={stripe} />
          <div className={style.actions}>
            <button
              disabled={paymentInProgress}
              className={style.submitButton}
              data-test="submit-payment"
              type="button"
              onClick={this.submitCheckout}
            >
              {Wahanda.Template.render(lang.paymentForm.pay, {
                sum: Wahanda.Currency.format(paymentAmount, {
                  forceDecimalPoint: true,
                }),
              })}
            </button>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const { stripe, success } = this.props;
    if (!stripe) {
      return (
        <Dialog
          dataTest="stripe-checkout-modal"
          onClose={this.props.onClose}
          title={Wahanda.lang.settings.supplier.payments.payNowDialog.title}
          width={332}
          keepTopPositionWhenResizing
        >
          <div className={style.loader}>
            <Loader />
          </div>
        </Dialog>
      );
    }
    if (success) {
      return (
        <Dialog
          onClose={this.props.onClose}
          title={Wahanda.lang.settings.supplier.payments.payNowDialog.success.title}
          width={332}
          keepTopPositionWhenResizing
        >
          <div className={style.checkoutContainer}>{this.renderSuccess()}</div>
        </Dialog>
      );
    }
    return (
      <Dialog
        onClose={() => {
          FinancePaymentsTracking.trackCloseWithoutSubmittingPayment();
          this.props.onClose();
        }}
        title={Wahanda.lang.settings.supplier.payments.payNowDialog.title}
        width={332}
        keepTopPositionWhenResizing
      >
        <div className={style.checkoutContainer}>{this.renderForm()}</div>
      </Dialog>
    );
  }
}
