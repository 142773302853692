/**
 * POS checkout summary views
 * Containers for Services, Products and Payments views
 *
 * Each summary view has a heading and a hook element to add to or remove
 * elements from. This just makes the markup a bit simpler for the two types
 * of checkout, POS and POS Lite.
 *
 */
(function () {
  if (!App.Views.POS) {
    App.Views.POS = {};
  }
  var BaseView = Backbone.View;
  App.Views.POS.ViewSummary = BaseView.extend({
    templateId: '',

    initialize: function () {
      this.controller = this.options.controller;
      this.templateId = this.options.templateId;
      this.additionalTemplateData = this.options.additionalTemplateData;
    },

    render: function () {
      var html = Wahanda.Template.renderTemplate(
        this.templateId,
        this.getDialogTemplateVariables(),
      );
      this.$el.html(html);
    },

    getDialogTemplateVariables: function () {
      var additionalTemplateData = this.additionalTemplateData || {};
      return _.extend({ appointments: this.model }, additionalTemplateData);
    },
  });
})();
