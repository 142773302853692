import React from 'react';
import UnderlinedInput from 'components/common/UnderlinedInput';
import Wahanda from 'common/wahanda';
import _ from 'common/underscore';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';

import Loader from 'components/common/Loader';
import { Switch } from 'components/common/Switch';
import { Button } from 'components/common/Button';
import { InputError } from 'components/common/__BaseCommon';
import { LayoutContainer, LayoutGrid, LayoutHeader } from 'components/common/Layout';
import { Box } from 'components/common/Box';

import SettingsSection from './SettingsSection';
import Impressum from './Impressum/container';
import ThemeCard from './ThemeCard/container';
import { Theme } from './themes';
import {
  PartnerSiteSettings,
  PartnerSiteSubdomain,
  PartnerSiteCustomDomain,
  FacebookHandle,
  InstagramHandle,
  TwitterHandle,
  ValidationStatus,
  PartnerSiteTheme,
} from './types';
import SocialLink, {
  FACEBOOK_SOCIAL_LINK,
  INSTAGRAM_SOCIAL_LINK,
  TWITTER_SOCIAL_LINK,
} from './SocialLink';

import style from './style.scss';

const lang = Wahanda.lang.settings.microsite;
const impressumLang = Wahanda.lang.settings.microsite;

const SUBDOMAIN_INPUT_NAME = 'subdomain';
const CUSTOM_DOMAIN_INPUT_NAME = 'custom-domain';
const FACEBOOK_HANDLE_INPUT_NAME = 'facebook-handle';
const INSTAGRAM_HANDLE_INPUT_NAME = 'instagram-handle';
const TWITTER_HANDLE_INPUT_NAME = 'twitter-handle';

export interface Props {
  settings: PartnerSiteSettings;
  subdomainValidationStatus: ValidationStatus;
  customDomainValidationStatus: ValidationStatus;
  isLoading: boolean;
  isSubmitting: boolean;
  actions: {
    getPartnerSiteSettingsRequest: () => void;
    togglePartnerSiteEnabled: () => void;
    changePartnerSiteSubdomain: (subdomain: PartnerSiteSubdomain) => void;
    changePartnerSiteCustomDomain: (customDomain: PartnerSiteCustomDomain) => void;
    changeFacebookHandle: (facebookHandle: FacebookHandle) => void;
    changeInstagramHandle: (instagramHandle: InstagramHandle) => void;
    changeTwitterHandle: (twitterHandle: TwitterHandle) => void;
    submitPartnerSiteSettingsRequest: () => void;
    validatePartnerSiteSubdomainRequest: () => void;
    validatePartnerSiteCustomDomainRequest: () => void;
    setSubdomainValidationStatusToEmpty: () => void;
    setCustomDomainValidationStatusToEmpty: () => void;
    resetPartnerSiteSettings: () => void;
    selectPartnerSiteTheme: (theme: PartnerSiteTheme) => void;
  };
}

const ValidationMessageCustomDomain = {
  [ValidationStatus.OK]: null,
  [ValidationStatus.UNAVAILABLE]: Wahanda.lang.shared.errors.domainInUse,
  [ValidationStatus.INVALID]: Wahanda.lang.shared.errors.domainInvalid,
  [ValidationStatus.EMPTY]: null,
};

const ValidationMessageSubdomain = {
  [ValidationStatus.OK]: null,
  [ValidationStatus.UNAVAILABLE]: Wahanda.lang.shared.errors.domainInUse,
  [ValidationStatus.INVALID]: Wahanda.lang.shared.errors.domainInvalid,
  [ValidationStatus.EMPTY]: Wahanda.lang.validate.defaults.required,
};

class PartnerSite extends React.PureComponent<Props> {
  private debounceSubdomainValidationRequest = _.debounce(
    this.props.actions.validatePartnerSiteSubdomainRequest,
    1000,
  );

  private debounceCustomDomainValidationRequest = _.debounce(
    this.props.actions.validatePartnerSiteCustomDomainRequest,
    1000,
  );

  public componentDidMount() {
    this.props.actions.getPartnerSiteSettingsRequest();
    NavigationAnalytics.trackPageEventView(L2Page.PARTNER_SITE);
  }

  public componentDidUpdate() {
    if (!this.props.settings.theme) {
      // Setting default theme to HIPSTER
      this.props.actions.selectPartnerSiteTheme({ theme: Theme.HIPSTER });
    }
  }

  public componentWillUnmount() {
    this.props.actions.resetPartnerSiteSettings();
    (this.debounceSubdomainValidationRequest as any).cancel();
    (this.debounceCustomDomainValidationRequest as any).cancel();
  }

  public render() {
    const { isLoading } = this.props;
    if (isLoading) return <Loader positionAbsolute />;
    return this.renderContent();
  }

  private renderContent = () => {
    const { micrositeEnabled } = this.props.settings;
    return (
      <LayoutContainer footer={this.renderButtonsContainer()}>
        <LayoutGrid>
          <div>
            {this.renderNote()}
            {micrositeEnabled && this.renderSettings()}
          </div>
        </LayoutGrid>
      </LayoutContainer>
    );
  };

  private renderNote = () => {
    const { title, p1, p2, helpLink, helpLinkText } = lang.note;
    const { isSubmitting } = this.props;
    return (
      <div className={style.noteContainer}>
        <Box>
          <div>
            <LayoutHeader
              header={title}
              subheader={
                <>
                  <p className={style.paragraph}>{p1}</p>
                  <p className={style.paragraph}>{p2}</p>
                  <a
                    className={style.link}
                    href={helpLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {helpLinkText}
                  </a>
                </>
              }
            />
            <Switch
              className={style.toggle}
              labelClassName={style.toggleLabel}
              onChange={this.props.actions.togglePartnerSiteEnabled}
              label={lang.labels.enable}
              checked={this.props.settings.micrositeEnabled}
              disabled={isSubmitting}
            />
          </div>
        </Box>
      </div>
    );
  };

  private onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const targetName = event.currentTarget.name;
    const targetValue = event.currentTarget.value;
    switch (targetName) {
      case SUBDOMAIN_INPUT_NAME:
        this.props.actions.changePartnerSiteSubdomain({
          subdomain: event.currentTarget.value,
        });
        if (_.isEmpty(targetValue.trim())) {
          this.props.actions.setSubdomainValidationStatusToEmpty();
          (this.debounceSubdomainValidationRequest as any).cancel();
          break;
        }
        this.debounceSubdomainValidationRequest();
        break;
      case CUSTOM_DOMAIN_INPUT_NAME:
        this.props.actions.changePartnerSiteCustomDomain({
          customDomain: event.currentTarget.value,
        });
        if (_.isEmpty(targetValue.trim())) {
          this.props.actions.setCustomDomainValidationStatusToEmpty();
          (this.debounceCustomDomainValidationRequest as any).cancel();
          break;
        }
        this.debounceCustomDomainValidationRequest();
        break;
      case FACEBOOK_HANDLE_INPUT_NAME:
        this.props.actions.changeFacebookHandle({
          facebookHandle: event.currentTarget.value,
        });
        break;
      case INSTAGRAM_HANDLE_INPUT_NAME:
        this.props.actions.changeInstagramHandle({
          instagramHandle: event.currentTarget.value,
        });
        break;
      case TWITTER_HANDLE_INPUT_NAME:
        this.props.actions.changeTwitterHandle({
          twitterHandle: event.currentTarget.value,
        });
        break;
      default:
        break;
    }
  };

  private renderSettings = () => {
    const {
      micrositeSubdomain,
      micrositeCustomDomain,
      micrositeProEnabled,
      partnerSitesDomain,
      facebookHandle,
      twitterHandle,
      instagramHandle,
      theme,
    } = this.props.settings;
    const { isSubmitting, subdomainValidationStatus, customDomainValidationStatus } = this.props;
    return (
      <div className={style.settingsContainer}>
        <SettingsSection title={lang.themeSettings}>
          <div className={style.themeCards}>
            {[Theme.HIPSTER, Theme.BLOSSOM, Theme.CONCIERGE].map((t) => (
              <ThemeCard key={t} theme={t} isSelected={theme === t} isDisabled={isSubmitting} />
            ))}
          </div>
        </SettingsSection>
        <form>
          <SettingsSection title={lang.websiteAddress}>
            <div className={style.domainInput}>
              <UnderlinedInput
                // @ts-expect-error ts-migrate(2769) FIXME: Property 'name' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
                name={SUBDOMAIN_INPUT_NAME}
                label={lang.labels.subdomain}
                value={micrositeSubdomain}
                onChange={this.onInputChange}
                placeholder={lang.placeholder.subdomain}
                disabled={isSubmitting}
                hasError={subdomainValidationStatus !== ValidationStatus.OK}
              />
              <span className={style.genericDomain}>{`.${partnerSitesDomain}`}</span>
            </div>
            {ValidationMessageSubdomain[subdomainValidationStatus] && (
              <InputError message={ValidationMessageSubdomain[subdomainValidationStatus]} />
            )}
            {micrositeProEnabled && (
              <React.Fragment>
                <div className={style.domainInput}>
                  <UnderlinedInput
                    // @ts-expect-error ts-migrate(2769) FIXME: Property 'name' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
                    name={CUSTOM_DOMAIN_INPUT_NAME}
                    label={lang.labels.customDomain}
                    value={micrositeCustomDomain || ''}
                    onChange={this.onInputChange}
                    placeholder={lang.placeholder.customDomain}
                    disabled={isSubmitting}
                    hasError={
                      ![ValidationStatus.OK, ValidationStatus.EMPTY].find(
                        (s) => s === customDomainValidationStatus,
                      )
                    }
                  />
                </div>
                {ValidationMessageCustomDomain[customDomainValidationStatus] && (
                  <InputError
                    message={ValidationMessageCustomDomain[customDomainValidationStatus]}
                  />
                )}
              </React.Fragment>
            )}
          </SettingsSection>
          <SettingsSection
            title={lang.socialLinks.title}
            description={lang.socialLinks.description}
          >
            <div className={style.socialLinks}>
              <SocialLink type={FACEBOOK_SOCIAL_LINK}>
                <UnderlinedInput
                  // @ts-expect-error ts-migrate(2769) FIXME: Property 'name' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
                  name={FACEBOOK_HANDLE_INPUT_NAME}
                  value={facebookHandle || ''}
                  onChange={this.onInputChange}
                  placeholder={lang.placeholder.username}
                  disabled={isSubmitting}
                />
              </SocialLink>
              <SocialLink type={INSTAGRAM_SOCIAL_LINK}>
                <UnderlinedInput
                  // @ts-expect-error ts-migrate(2769) FIXME: Property 'name' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
                  name={INSTAGRAM_HANDLE_INPUT_NAME}
                  value={instagramHandle || ''}
                  onChange={this.onInputChange}
                  placeholder={lang.placeholder.username}
                  disabled={isSubmitting}
                />
              </SocialLink>
              <SocialLink type={TWITTER_SOCIAL_LINK}>
                <UnderlinedInput
                  // @ts-expect-error ts-migrate(2769) FIXME: Property 'name' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
                  name={TWITTER_HANDLE_INPUT_NAME}
                  value={twitterHandle || ''}
                  onChange={this.onInputChange}
                  placeholder={lang.placeholder.username}
                  disabled={isSubmitting}
                />
              </SocialLink>
            </div>
          </SettingsSection>
        </form>
        <SettingsSection title={impressumLang.impressum} description={impressumLang.impressumNote}>
          <Impressum />
        </SettingsSection>
      </div>
    );
  };

  private validationErrorsExist = () => {
    const { subdomainValidationStatus, customDomainValidationStatus } = this.props;

    const subdomainValidationStatusExists = subdomainValidationStatus !== ValidationStatus.OK;
    const customDomainValidationStatusExists =
      customDomainValidationStatus !== ValidationStatus.OK &&
      customDomainValidationStatus !== ValidationStatus.EMPTY;

    return subdomainValidationStatusExists || customDomainValidationStatusExists;
  };

  private validateAndSubmit = () => {
    const { micrositeSubdomain } = this.props.settings;
    if (!micrositeSubdomain) {
      this.props.actions.setSubdomainValidationStatusToEmpty();
      return;
    }
    this.props.actions.submitPartnerSiteSettingsRequest();
  };

  private renderButtonsContainer = () => (
    <div className={style.buttonsContainer}>
      <Button
        label={lang.buttons.save}
        onClick={this.validateAndSubmit}
        disabled={this.props.isSubmitting || this.validationErrorsExist()}
      />
      <Button
        label={lang.buttons.viewSite}
        onClick={this.viewPartnerSite}
        disabled={this.props.isSubmitting || this.validationErrorsExist()}
      />
    </div>
  );

  private viewPartnerSite = () => {
    window.open(
      `https://${this.props.settings.micrositeSubdomain}.${this.props.settings.partnerSitesDomain}`,
      '_blank',
    );
  };
}

export default PartnerSite;
