import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import * as ExportActions from './actions';
import { TransactionExports, ExportStatus } from './types';

export function* initPosExport(action: ReturnType<typeof ExportActions.initPosExport>) {
  const { type, from, to } = action.payload;

  try {
    yield call(api.post, apiUrl('INIT_POS_EXPORT', { type, from, to }));
    yield put(ExportActions.getPosExports());
  } catch {
    yield put(ExportActions.initPosExportFailure());
  }
}

export function* getPosExports() {
  try {
    const result: TransactionExports[] = yield call(api.get, apiUrl('POS_EXPORTS'));
    yield put(ExportActions.getPosExportsSuccess({ posExports: result }));

    if (result.some((posExport) => posExport.status === ExportStatus.IN_PROGRESS)) {
      yield call(delay, 5000);
      yield put(ExportActions.getPosExports());
    }
  } catch {
    yield put(ExportActions.getPosExportsFailure());
  }
}
