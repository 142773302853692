App.Models.RoomAvailabilitySaver = BackboneEx.Model.Silent.extend({
  attributesToSave: function () {
    var list = ['roomTypes'];
    if (this.get('dates')) {
      list.push('dates');
    } else {
      list.push('dateRange');
    }
    return list;
  },

  url: function () {
    var base = '/venue/' + App.config.get('venue').id + '/room-allocations.json';
    return App.Api.wsUrl(base);
  },

  _afterSave: function () {
    App.trigger('room-availability:saved');
  },
});
