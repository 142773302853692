import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Close extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
        return (
          <svg
            className={['icon-svg', 'svg-close', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 10 10"
          >
            <path
              d="M12.7071068,11.2928932 L11.2928932,12.7071068 L3.29289322,4.70710678 L4.70710678,3.29289322 L12.7071068,11.2928932 Z M11.2928932,3.29289322 L12.7071068,4.70710678 L4.70710678,12.7071068 L3.29289322,11.2928932 L11.2928932,3.29289322 Z"
              transform="translate(-3 -3)"
            />
          </svg>
        );
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-close', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
            aria-hidden="true"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M9.485 8.071l5.657 5.657-1.414 1.414-5.657-5.657-5.657 5.657L1 13.728l5.657-5.657L1 2.414 2.414 1l5.657 5.657L13.728 1l1.414 1.414z"
              fillRule="evenodd"
            />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-close', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <polygon points="14 3.2 12.8 2 8 6.8 3.2 2 2 3.2 6.8 8 2 12.8 3.2 14 8 9.2 12.8 14 14 12.8 9.2 8" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Close);
