import React from 'react';
import Wahanda from 'common/wahanda';
import style from './style.scss';

interface ILinkToPreviewProps extends React.HTMLAttributes<Element> {
  url: string;
}

export default class LinkToPreview extends React.Component<ILinkToPreviewProps, {}> {
  static defaultProps = {
    children: null,
  };

  render() {
    const { url, children } = this.props;
    return (
      <a className={style.link} href={Wahanda.Url.getFullUrl('marketing', url)}>
        {children}
      </a>
    );
  }
}
