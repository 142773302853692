import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import {
  receiveExternalSalonSystemsAction,
  receiveExternalSalonIntegrationAction,
  receiveExternalSalonIntegrationCreateAction,
  receiveExternalSalonIntegrationDeleteAction,
  receiveExternalSalonEmployeesAction,
  requestFailureAction,
} from './actions';

function getExternaSalonSystems(url) {
  return api.get(url).then((data) =>
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    data.externalSalonSystems.map((item) => {
      const { id, name, clientId, integrationSetupUri } = item;
      return {
        id,
        name,
        key: clientId,
        integrationSetupUri,
      };
    }),
  );
}

function getExternaSalonIntegration(url) {
  return api.get(url, { skipErrorHandling: true }).then((data) => {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    const employeeLinks = data.employeeLinks.map((item) => ({
      externalEmployeeId: item.externalEmployee.externalId,
      treatwellEmployeeId: item.treatwellEmployeeId,
    }));

    return {
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      externalSalonSystemId: data.externalSalonSystemId,
      employeeLinks,
    };
  });
}

function getExternaSalonEmployees(url) {
  return api.get(url).then((data) =>
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    data.externalEmployees.map((item) => {
      const { externalId, name } = item;
      return {
        id: externalId,
        name,
      };
    }),
  );
}

export function* getExternalSalonSystemsRequested() {
  try {
    const data = yield call(getExternaSalonSystems, apiUrl('EXTERNAL_SALON_SYSTEMS'));

    yield put(receiveExternalSalonSystemsAction(data));
  } catch (err) {
    yield put(requestFailureAction(err));
  }
}

export function* getExternalSalonIntegrationRequested() {
  try {
    const data = yield call(getExternaSalonIntegration, apiUrl('EXTERNAL_SALON_LINK'));

    yield put(receiveExternalSalonIntegrationAction(data));
  } catch (err) {
    yield put(receiveExternalSalonIntegrationAction({}));
  }
}

export function* getExternalSalonIntegrationCreateRequested({ employeeLinks }) {
  try {
    yield call(api.post, apiUrl('EXTERNAL_SALON_LINK_EMPLOYEES'), {
      employeeLinks,
    });

    yield put(receiveExternalSalonIntegrationCreateAction());
  } catch (err) {
    yield put(requestFailureAction(err));
  }
}

export function* getExternalSalonIntegrationDeleteRequested() {
  try {
    yield call(api.del, apiUrl('EXTERNAL_SALON_LINK'));
    yield put(receiveExternalSalonIntegrationDeleteAction());
  } catch (err) {
    yield put(requestFailureAction(err));
  }
}

export function* getExternalSalonEmployeesRequested() {
  try {
    const data = yield call(getExternaSalonEmployees, apiUrl('EXTERNAL_SALON_EMPLOYEES'));

    yield put(receiveExternalSalonEmployeesAction(data));
  } catch (err) {
    yield put(requestFailureAction(err));
  }
}
