import React from 'react';
import { get, getIn, assoc, equals, curry, partial, toJs } from 'mori';
import { withAnyCursor, cursorValueHasChanged } from 'atom/cursors';
import { isNumber, isNull, not } from 'common-js';
import { appState } from 'state';
import Wahanda from 'common/wahanda';
import { toDateString, now } from 'common/datetime';
import { fetch as fetchCheckoutSummary, remove } from 'src/services/pos-checkout-summary';
import { fetch as fetchPosStatus, isPreviousDayOpen, isTodayClosed } from 'src/services/pos-status';
import { fetchCancellationSummary, removeCancellationSummary } from 'src/services/pos-refund';
import CheckoutSummaryDialog from 'src/components/reports/transactions/transaction-summary/checkout-summary';
import CancellationSummaryDialog from 'src/components/reports/transactions/transaction-summary/cancellation-summary';
import IssueRefund from 'src/components/reports/transactions/issue-refund/container';
import { DialogConfirmation } from 'components/common/dialog/DialogConfirmation';
import POSPreviousDayNotClosedModal from 'components/calendar/SidebarActions/POSPreviousDayNotClosedModal';
import { canDelegateToApp, delegateToApp, ACTION } from 'common/app-delegation';
import apiUrl from 'common/api-url';
import { POS_OPERATIONS } from 'common/constants/pos-operations';
import App from 'common/backbone-app';
import { ReceiptCopyDialog } from 'components/pos/ReceiptCopyDialog/ReceiptCopyDialog';

function swapIds(checkoutId, cancellationId) {
  appState.swap((state) =>
    assoc(
      state,
      'reports-trans-table:checkoutId',
      checkoutId,
      'reports-trans-table:cancellationId',
      cancellationId,
    ),
  );
}

export const closeTransactionDialogs = (onClose) => {
  partial(swapIds, null, null);
  if (onClose) {
    onClose();
  }
};

export const openRefundDialogForId = partial(swapIds, null);

export const openCheckoutSummaryDialogForId = curry(swapIds, null);

function hasVenueIdChanged(oldState, newState) {
  return (
    not(isNull(get(oldState, 'venueId'))) && get(oldState, 'venueId') !== get(newState, 'venueId')
  );
}

function hasPosStatusChanged(oldState, newState) {
  return (
    not(isNull(get(oldState, 'posStatus'))) &&
    !equals(get(oldState, 'posStatus'), get(newState, 'posStatus'))
  );
}

function hasSomeNewId(atomKey, oldState, newState) {
  const oldId = getIn(oldState, atomKey);
  const newId = getIn(newState, atomKey);
  return (
    (isNull(oldId) && isNumber(newId)) || (isNumber(oldId) && isNumber(newId) && oldId !== newId)
  );
}

const hasNewCheckoutId = partial(hasSomeNewId, ['reports-trans-table:checkoutId']);
const hasNewCancellationId = partial(hasSomeNewId, ['reports-trans-table:cancellationId']);

function getSomeId(atomKey, hasNewFn, oldState, newState, fallback) {
  const getFn = curry(getIn, atomKey);
  if (not(isNull(getFn(oldState))) && isNull(getFn(newState))) {
    return null;
  }
  if (hasNewFn(oldState, newState)) {
    return getFn(newState);
  }
  return fallback;
}

const getCheckoutId = partial(getSomeId, ['reports-trans-table:checkoutId'], hasNewCheckoutId);
const getCancellationId = partial(
  getSomeId,
  ['reports-trans-table:cancellationId'],
  hasNewCancellationId,
);
interface ITransactionSummaryContainerProps extends React.HTMLAttributes<Element> {
  checkoutId?: number;
  cancellationId?: number;
  date: string;
  onClose?: (...args: any[]) => any;
  operationId?: {
    id: number;
  };
  actions: {
    abortPOSOperationRequestStart?: (...args: any[]) => any;
  };
  abortPOSOperationRequestStart?: any;
}
type TransactionSummaryContainerState = {
  checkoutId?: any;
  cancellationId?: any;
  posStatus?: any;
  checkoutSummary?: any;
  cancellationSummary?: any;
  refundData?: any;
  prevDayOpenDialog?: boolean;
  todayClosedDialog?: boolean;
  shouldOpenReceiptCopyDialog: boolean;
};

export class TransactionSummaryContainer extends React.Component<
  ITransactionSummaryContainerProps,
  TransactionSummaryContainerState
> {
  // @ts-expect-error ts-migrate(2416) FIXME: Type 'null' is not assignable to type 'boolean | u... Remove this comment to see the full error message
  state = {
    checkoutId: this.props.checkoutId,
    cancellationId: this.props.cancellationId,
    posStatus: get(appState.deref(), 'pos-status'),
    checkoutSummary: getIn(appState.deref(), ['checkout-summary', this.props.checkoutId]),
    cancellationSummary: getIn(appState.deref(), [
      'cancellation-summary',
      this.props.cancellationId,
    ]),
    refundData: null,
    prevDayOpenDialog: null,
    todayClosedDialog: null,
    shouldOpenReceiptCopyDialog: false,
  };

  openReceiptCopyDialog = () => {
    this.setState({
      shouldOpenReceiptCopyDialog: true,
    });
  };

  closeReceiptCopyDialog = () => {
    this.setState({
      shouldOpenReceiptCopyDialog: false,
    });
  };

  componentDidMount() {
    appState.addWatch(
      'TransactionsSummaryContainer',
      withAnyCursor(
        (oldState, newState) => {
          const state = appState.deref();
          const checkoutId = getCheckoutId(
            oldState,
            newState,
            // eslint-disable-next-line react/no-access-state-in-setstate
            this.state.checkoutId,
          );
          const cancellationId = getCancellationId(
            oldState,
            newState,
            // eslint-disable-next-line react/no-access-state-in-setstate
            this.state.cancellationId,
          );
          this.setState({
            checkoutId,
            cancellationId,
            posStatus: get(state, 'pos-status'),
            checkoutSummary: getIn(state, ['checkout-summary', checkoutId]),
            cancellationSummary: getIn(state, ['cancellation-summary', cancellationId]),
          });
          const venueIdChanged = hasVenueIdChanged(oldState, newState);
          const posStatusChanged = hasPosStatusChanged(oldState, newState);
          if (venueIdChanged && posStatusChanged) {
            fetchCheckoutSummary(checkoutId);
            fetchCancellationSummary(cancellationId);
          }
          if (hasNewCancellationId(oldState, newState)) {
            fetchCancellationSummary(cancellationId);
          }
        },
        [
          ['venueId'],
          ['pos-status'],
          ['checkout-summary'],
          ['cancellation-summary'],
          ['reports-trans-table:checkoutId'],
          ['reports-trans-table:cancellationId'],
        ],
        cursorValueHasChanged,
      ),
    );
    if (this.props.checkoutId) {
      fetchCheckoutSummary(this.props.checkoutId);
      fetchPosStatus();
    } else if (this.props.cancellationId) {
      fetchCancellationSummary(this.props.cancellationId);
    }
  }

  componentWillUnmount() {
    appState.removeWatch('TransactionsSummaryContainer');
  }

  render() {
    const {
      cancellationId,
      checkoutId,
      refundData,
      todayClosedDialog,
      posStatus,
      checkoutSummary,
      cancellationSummary,
      prevDayOpenDialog,
    } = this.state;
    const { date } = this.props;
    const lang = Wahanda.lang;
    const confirmationDialogCloseFn = () =>
      this.setState({
        todayClosedDialog: false,
      });
    const maybeTodayClosedDialog = (() => {
      if (todayClosedDialog) {
        return (
          <DialogConfirmation
            dataTest="transaction-summary-day-closed-confirmation-modal"
            title={lang.posRestrictions.dayClosedNoRefund.title}
            text={lang.posRestrictions.dayClosedNoRefund.text}
            onClose={confirmationDialogCloseFn}
          />
        );
      }
      return null;
    })();
    const openCheckoutSummary = (newCheckoutId) => {
      openCheckoutSummaryDialogForId(newCheckoutId);
    };
    const cancellationSummaryCloseFn = () => {
      removeCancellationSummary(cancellationId);
      closeTransactionDialogs(this.props.onClose);
    };
    const closeRefundDialogFn = ({ receiptId, tipReceiptId, receiptCopyUri }) => {
      if (!App.config.get('venue').pointOfSaleEnabled) {
        closeTransactionDialogs(this.props.onClose);
      }
      const isTransactionSuccess = receiptId && receiptCopyUri;
      const langRefundSummary = lang.reports.transactions.refundSummary;
      const secondReceiptUri = tipReceiptId
        ? apiUrl('POS_TRANSACTION_RECEIPT', { receiptId: tipReceiptId })
        : null;
      this.setState({
        refundData: null,
      });
      if (!isTransactionSuccess) {
        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        this.props.actions.abortPOSOperationRequestStart({
          operation: POS_OPERATIONS.REFUND,
          operationId: this.props.operationId,
        });
        return;
      }
      const receiptDialog = new App.Views.Forms.POSTransactionComplete({
        receiptUri: apiUrl('POS_TRANSACTION_RECEIPT', { receiptId }),
        receiptButtonLabel: langRefundSummary.printReceiptButton,
        receiptCopyUri: receiptCopyUri,
        receiptCopyButtonLabel: Wahanda.lang.pos.receiptCopyDialog.cta,
        secondReceiptUri,
        secondReceiptButtonLabel: langRefundSummary.printDepositReceiptButton,
      });
      receiptDialog.render();
      receiptDialog.open();
      // The Transaction was refunded. Let's refetch the transaction details.
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (checkoutId > 0) {
        fetchCheckoutSummary(checkoutId);
      }
    };
    const maybeCancellationSummaryDialog = (() => {
      if (!cancellationId) {
        return null;
      }

      return (
        <>
          <CancellationSummaryDialog
            data={cancellationSummary}
            openReceiptCopyDialog={this.openReceiptCopyDialog}
            openCheckoutSummary={openCheckoutSummary}
            onClose={cancellationSummaryCloseFn}
          />
          {this.state.shouldOpenReceiptCopyDialog && (
            <ReceiptCopyDialog
              onClose={this.closeReceiptCopyDialog}
              receiptCopyUri={toJs(get(cancellationSummary, 'cancellationInformation')).receiptUri}
            />
          )}
        </>
      );
    })();

    const maybeRefundDialog = (() => {
      if (refundData) {
        return <IssueRefund transactionData={refundData} onClose={closeRefundDialogFn} />;
      }
      return null;
    })();
    const maybePrevDayOpenDialog = (() => {
      if (prevDayOpenDialog) {
        const onCloseFn = () => this.setState({ prevDayOpenDialog: false });
        return (
          <POSPreviousDayNotClosedModal
            text={lang.posRestrictions.prevDayOpenNoRefund.text}
            onClose={onCloseFn}
          />
        );
      }
      return null;
    })();
    // Get the Checkout Summary only if the POS Status is loaded.
    const checkoutSummaryData = posStatus ? checkoutSummary : null;
    const maybeCheckoutSummaryDialog = (() => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (checkoutId !== null && checkoutId > 0) {
        const openRefundSummary = (id) => {
          openRefundDialogForId(id);
        };
        const setRefundData = (newRefundData) => {
          if (isTodayClosed()) {
            this.setState({
              todayClosedDialog: true,
            });
          } else if (isPreviousDayOpen(toDateString(now()))) {
            this.setState({
              prevDayOpenDialog: true,
            });
          } else if (canDelegateToApp(ACTION.ISSUE_REFUND)) {
            const actionAfterRefundIssued = () => {
              // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
              closeRefundDialogFn();
              App.trigger(Wahanda.Event.TRANSACTION_CANCELLED, {
                checkoutId,
                cancellationId,
              });
            };
            delegateToApp(ACTION.ISSUE_REFUND, {
              date,
              checkoutSummary: toJs(newRefundData),
              onDone: actionAfterRefundIssued,
            });
          } else {
            // All good. Can refund.
            this.setState({
              refundData: newRefundData,
            });
          }
        };
        const onCloseFn = () => {
          closeTransactionDialogs(this.props.onClose);
          // Remove the checkoutId from appState. We don't want to cache.
          remove(checkoutId);
          this.setState({
            checkoutId: null,
            cancellationId: null,
            checkoutSummary: null,
          });
        };

        return (
          <>
            <CheckoutSummaryDialog
              data={checkoutSummaryData}
              openRefundSummary={openRefundSummary}
              openReceiptCopyDialog={this.openReceiptCopyDialog}
              openRefundDialog={setRefundData}
              onClose={onCloseFn}
            />
            {this.state.shouldOpenReceiptCopyDialog && (
              <ReceiptCopyDialog
                onClose={this.closeReceiptCopyDialog}
                receiptCopyUri={toJs(get(checkoutSummaryData, 'checkoutInformation')).receiptUri}
              />
            )}
          </>
        );
      }
      return null;
    })();
    return (
      <div>
        {maybeCheckoutSummaryDialog}
        {maybeCancellationSummaryDialog}
        {maybeRefundDialog}
        {maybeTodayClosedDialog}
        {maybePrevDayOpenDialog}
      </div>
    );
  }
}
