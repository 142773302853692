import React from 'react';
import classnames from 'classnames';
import style from './style.scss';

type TabBodyProps = {
  selectedTab: string;
  tab: string;
  className?: string;
};
const TabBody: React.SFC<TabBodyProps> = ({ tab, selectedTab, children, className }) => (
  <div
    className={classnames(style.tabBody, {
      [style.hidden]: tab !== selectedTab,
      // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
      [className]: className,
    })}
  >
    {children}
  </div>
);
TabBody.defaultProps = {
  className: '',
};

export default TabBody;
