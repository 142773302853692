/* global App Backbone */
App.Routers.Dashboard = Backbone.Router.extend({
  routes: {
    'voucher/:reference': 'openVoucher',
    'venue/:venueId/dialog/:type/:dialogId': 'openInitialDialogWithVenue',
    'venue/:venueId/dialog/:type/:dialogId/lwauth/:token': 'openInitialDialogWithVenue',
    'dialog/:type/:dialogId': 'openInitialDialog',
    'dialog/:type/:dialogId/lwauth/:token': 'openInitialDialog',
    'livechat/:timestamp': 'appLiveChat',
    'venue/:venueId/:tabName': 'openTab',
    ':tabName': 'openWithoutVenueId',
  },

  initVenue: function (venueId, dialogType, dialogTypeId, tabName) {
    App.mainViewOptions = {
      tab: tabName,
    };

    if (dialogType && dialogTypeId) {
      App.mainViewOptions.initialDialog = {
        type: dialogType,
        id: dialogTypeId,
        // Used for venueId check and redirect back from login to correct URL
        venueId: venueId,
        dialogUrl: document.URL,
      };
    }

    App.initVenue(venueId);
  },

  openWithoutVenueId: function (tabName) {
    this.initVenue(null, null, null, tabName);
  },

  openTab: function (venueId, tabName) {
    if (App.mainView && App.getVenueId() === parseInt(venueId, 10)) {
      // We have the view already rendered. Just a tab switch needed, not a full data refetch.
      App.mainViewOptions = {
        tab: tabName,
      };
      App.mainView.render();
    } else {
      // Dashboard view not initialized, or it's a different venueId.
      // Go the long route.
      this.initVenue(venueId, null, null, tabName);
    }
  },

  openVoucher: function (reference) {
    App.mainViewOptions = {
      initVoucherReference: reference,
    };
    App.initVenue(null);
  },

  openInitialDialog: function (type, dialogId, authToken) {
    this.openInitialDialogWithVenue(null, type, dialogId, authToken);
  },

  openInitialDialogWithVenue: function (venueId, type, dialogId, authToken) {
    if (authToken) {
      App.setLightweightAuthKey(authToken);
    }
    App.allowLightweightAuthentication = true;
    App.fromExternalSource = true;
    this.initVenue(venueId, type, dialogId);
  },

  // Mock route for livechat action on Apps
  appLiveChat: function () {
    // Only execute the action if this is an initial load. Otherwise none is needed.
    if (App.config === null) {
      this.initVenue(null);
    }
  },
});
