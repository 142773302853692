import React from 'react';
import style from './style.scss';

interface Props {
  text: string;
}

export default class SubHeader extends React.PureComponent<Props> {
  public render() {
    const { text } = this.props;
    return <h3 className={style.header}>{text}</h3>;
  }
}
