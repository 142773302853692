import moment from 'common/moment';

const excludeDate = (cache, excludedDate) => {
  const newCache = {};

  Object.keys(cache).forEach((cachedDate) => {
    if (cachedDate !== excludedDate) {
      newCache[cachedDate] = cache[cachedDate];
    }
  });

  return newCache;
};

const excludeRangeFrom = (cache, dateFrom) => {
  const from = moment(dateFrom);
  const newCache = {};

  Object.keys(cache).forEach((cachedDate) => {
    if (from.isAfter(cachedDate, 'day')) {
      newCache[cachedDate] = cache[cachedDate];
    }
  });

  return newCache;
};

export default function invalidateCacheByDates(
  cache,
  { date, dateFrom }: { date?: string; dateFrom?: string },
) {
  if (date) {
    return excludeDate(cache, date);
  }
  return excludeRangeFrom(cache, dateFrom);
}
