import {
  GET_SIGNATURE_REQUEST,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_FAILURE,
  CLEAR_SIGNATURES,
} from 'reduxStore/actionsConst';
import { createAction } from 'reduxStore/actionCreator';
import { SignatureSource, Signature } from './models';

export const getSignaturesRequestAction = (payload: {
  signature: string;
  source: SignatureSource;
}) => createAction(GET_SIGNATURE_REQUEST, payload);

export type GetSignatureRequestAction = ReturnType<typeof getSignaturesRequestAction>;

export const getSignatureSuccessAction = (payload: { signatures: Signature[] }) =>
  createAction(GET_SIGNATURE_SUCCESS, payload);

export const getSignatureFailureAction = () => createAction(GET_SIGNATURE_FAILURE);

export const clearSignaturesAction = () => createAction(CLEAR_SIGNATURES);
