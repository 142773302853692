import { TransporterDelegate, Transporter } from './transporter';
import { WebTransporter } from './web-transporter';
import env from '../../../../../server/env';
import getAuthData from 'common/websockets/auth';
import { updateLocation, getStoreBasePath, UpdateLocationParams, isOnStore } from './utils';

export class StoreContext implements TransporterDelegate {
  private static _instance: StoreContext;
  private readonly iframe: HTMLIFrameElement;
  private transporter: Transporter;

  private constructor() {
    // Add iframe
    const storeAnchor = document.getElementById('store-iframe-anchor');
    if (!storeAnchor) {
      throw new Error('HTMLElement with id store-iframe-anchor not found');
    }

    let url = 'https://tw-store-glue.netlify.app';
    if (!env.isProduction) {
      url = localStorage.getItem('STORE_ENDPOINT') || 'https://staging--tw-store-glue.netlify.app';
    }

    const { pathname, hash, search } = window.location;
    this.iframe = document.createElement('iframe');
    this.iframe.setAttribute('id', 'store-iframe');
    this.iframe.src = new URL(`${hash}${search}`, url).toString();
    this.iframe.setAttribute('style', 'width: inherit; height: inherit');
    this.iframe.onload = () => {
      this.iframe?.contentWindow?.postMessage(
        {
          type: 'TWStoreGlueInit',
          data: {
            source: 'connect',
            platform: 'web',
            version: 2,
            initialData: {
              path: pathname + hash + search,
              locale: storeAnchor.getAttribute('data-store-locale'),
              basePath: getStoreBasePath(),
            },
          },
        },
        '*',
      );
    };
    storeAnchor.appendChild(this.iframe);

    // Add menu item
    this.addStoreToHeader();

    // Create transporter for communication with iframe
    this.transporter = new WebTransporter(this, this.iframe);
  }

  private addStoreToHeader() {
    const linkElement = document.createElement('a');
    linkElement.setAttribute('data-page', 'store');
    linkElement.href = 'store';
    // TODO: translate these elements
    linkElement.title = 'Store';
    linkElement.textContent = 'Store';
    if (isOnStore()) {
      // Add store class in order to remove the absolute positioning of the header because it doesn't work with flex box
      document.getElementsByTagName('body')[0].classList.add('store');
      linkElement.onclick = () => {
        this.transporter.emit('navigate', { to: '/' });
        return false;
      };
    }

    const menuElement = document.createElement('li');
    menuElement.setAttribute('id', 'nav_store');
    menuElement.appendChild(linkElement);

    document.getElementById('nav1')?.insertAdjacentElement('beforeend', menuElement);
  }

  public static Instance() {
    return this._instance || (this._instance = new this());
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRemoteCall(name: string, data: unknown): Promise<unknown> {
    switch (name) {
      case 'getDelegateData': {
        const res = {};
        Object.entries(getAuthData()).forEach(([k, v]) => {
          res[k] = encodeURIComponent(v as string);
        });
        return Promise.resolve({ ...res, externalID: `${App.getVenueId()}` });
      }
      case 'debug':
        return Promise.resolve('Connect Web - pong');
      default:
        return Promise.reject(`Connect Web - Unknown event '${name}'`);
    }
  }

  onRemoteEvent(name: string, data: unknown): void {
    switch (name) {
      case 'location':
        if (isOnStore()) {
          updateLocation(data as UpdateLocationParams);
        }
        break;
      case 'updateCartItemCount':
        // On Connect Web, cart button and cart item count are rendered inside the store
        break;
      case 'ready':
        if (isOnStore()) {
          this.transporter.emit('mount', undefined);
        }
        break;
      default:
        break;
    }
  }
}
