/* eslint-disable import/prefer-default-export */
import { call, all, put, select } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import {
  getPartnerSiteSettingsReceive,
  getPartnerSiteSettingsFailure,
  submitPartnerSiteSettingsSuccess,
  submitPartnerSiteSettingsFailure,
  validatePartnerSiteSubdomainSuccess,
  validatePartnerSiteSubdomainFailure,
  validatePartnerSiteCustomDomainSuccess,
  validatePartnerSiteCustomDomainFailure,
} from './actions';
import {
  getPartnerSiteSettings,
  getPartnerSiteImpressum,
  getPartnerSiteSettingsSubdomain,
  getPartnerSiteSettingsCustomDomain,
} from './selectors';
import { PartnerSiteSettings, PartnerSiteValidationStatus } from './types';

export function* getPartnerSiteSettingsRequested() {
  try {
    const [settings, { impressum }]: [PartnerSiteSettings, any] = yield all([
      call(api.get, apiUrl('PARTNER_SITE_SETTINGS')),
      call(api.get, apiUrl('IMPRESSUM')),
    ]);
    yield put(getPartnerSiteSettingsReceive({ settings, impressum }));
  } catch (err: any) {
    yield put(getPartnerSiteSettingsFailure({ errorMessage: err }));
  }
}

export function* submitPartnerSiteSettingsRequested() {
  try {
    const [settings, impressum] = yield all([
      select(getPartnerSiteSettings),
      select(getPartnerSiteImpressum),
    ]);
    yield all([
      call(api.post, apiUrl('PARTNER_SITE_SETTINGS'), settings),
      yield call(api.put, apiUrl('IMPRESSUM'), impressum),
    ]);
    yield put(submitPartnerSiteSettingsSuccess());
  } catch (err: any) {
    yield put(submitPartnerSiteSettingsFailure({ errorMessage: err }));
  }
}

export function* validatePartnerSiteSubdomainRequested() {
  try {
    const subdomain = yield select(getPartnerSiteSettingsSubdomain);
    const status: PartnerSiteValidationStatus = yield call(
      api.get,
      apiUrl('PARTNER_SITE_SUBDOMAIN_VALIDATION', { subdomain }),
    );
    yield put(validatePartnerSiteSubdomainSuccess(status));
  } catch (err: any) {
    yield put(validatePartnerSiteSubdomainFailure({ errorMessage: err }));
  }
}

export function* validatePartnerSiteCustomDomainRequested() {
  try {
    const customDomain = yield select(getPartnerSiteSettingsCustomDomain);
    const status: PartnerSiteValidationStatus = yield call(
      api.get,
      apiUrl('PARTNER_SITE_CUSTOM_DOMAIN_VALIDATION', { customDomain }),
    );
    yield put(validatePartnerSiteCustomDomainSuccess(status));
  } catch (err: any) {
    yield put(validatePartnerSiteCustomDomainFailure({ errorMessage: err }));
  }
}
