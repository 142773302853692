import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeDialog } from './actions';
import { RootTransactionDialog as Component } from './RootTransactionDialog';

const mapState = (state) => ({
  dialog: state.dialog.dialog,
});

const mapDispatch = (dispatch) => ({
  onClose: bindActionCreators(closeDialog, dispatch),
});

export const RootTransactionDialog = connect(mapState, mapDispatch)(Component);
