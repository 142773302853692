import React from 'react';
import classnames from 'classnames';
import style from './ControlButton.scss';

interface Props {
  active?: boolean;
  onToggle: (params) => void;
  style: string;
  className?: string;
}

export const ControlButton = ({ active = false, onToggle, style: styleProp, className }: Props) => {
  let buttonStyle = classnames(style.controlButton, className);
  if (active) {
    buttonStyle = classnames(buttonStyle, style.activeControlButton);
  }
  const handleToggle = (e) => {
    e.preventDefault();
    onToggle(styleProp);
  };
  return <span className={buttonStyle} onMouseDown={handleToggle} />;
};
