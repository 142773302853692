import React from 'react';
import Wahanda from 'common/wahanda';
import { AnimatedTick } from 'components/common/AnimatedTick';
import style from './SuccessOverlay.scss';

const lang = Wahanda.lang.UDVAppAdvocacyBanner;

export interface Props {
  onClose: () => void;
}

class SuccessOverlay extends React.PureComponent<Props> {
  public render() {
    const { onClose } = this.props;
    return (
      <React.Fragment>
        <div className={style.overlay} onClick={onClose} />
        <div className={style.container}>
          <AnimatedTick size={40} colour={style.tickColour} />
          <span className={style.message}>{lang.sentSuccessfully}</span>
        </div>
      </React.Fragment>
    );
  }
}

export default SuccessOverlay;
