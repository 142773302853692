/* global Backbone */
import _ from 'common/underscore';
import {
  CheckoutServiceAnalytics,
  CheckoutPackageAnalytics,
  CheckoutAnalytics,
} from 'common/analytics';

App.Views.POS.Services = Backbone.View.extend({
  events: {
    'click .js-appointments-item': 'openEditView',
    'click .js-icon': 'onRowClick',
  },

  initialize: function initialize() {
    const self = this;
    this.controller = this.options.controller;
    this.controller.on('product-form:reset', () => {
      self.resetView();
    });
  },

  resetView: function resetView() {
    if (this.editView == null) {
      return;
    }
    this.editView.remove();
    this.editView = null;
    this.$el.find('.js-service-details').show();
  },

  onRowClick: function onRowClick(evt) {
    if (
      $(evt.target).parents('.js-currency-container').length > 0 ||
      $(evt.currentTarget).hasClass('disabled')
    ) {
      return;
    }
    const appointment = this.getAppointment(evt);
    appointment.selected = !appointment.selected;
    if (!appointment.selected) {
      this.controller.removePayments();
    }
    const $row = $(evt.currentTarget).parents('.js-service-row');
    $row.find('.js-icon').toggleClass('checked', appointment.selected);
    this.controller.updateTotals();
    this.controller.updatePayments();
    this.controller.renderPrepayments();

    const containsSelectedAppointments = _.any(this.controller.appointments, (app) => app.selected);
    const containsProducts = this.controller.products.length > 0;

    if (containsSelectedAppointments || containsProducts) {
      this.controller.enablePOSSubmit();
    } else {
      this.controller.disablePOSSubmit();
    }
  },

  getAppointment: function getAppointment(evt) {
    const id = $(evt.currentTarget).parents('.js-service-row').data('id');
    return _.find(this.controller.appointments, (appointment) => appointment.id === id);
  },

  openEditView: function openEditView() {
    const self = this;
    const serviceElement = self.$el.find('.js-service-details');
    serviceElement.hide();
    this.controller.trigger('product-form:reset');

    if (self.model.isPackage) {
      CheckoutAnalytics.trackEditPackageClick();
    } else {
      CheckoutAnalytics.trackEditServiceClick();
    }

    this.editView = new App.Views.POS.CheckoutServiceEditView({
      model: this.model,
      onSave: (data) => {
        const { amount, discountPercentage, discountedAmount } = data;
        if (self.model.amount !== amount || self.model.discountPercentage !== discountPercentage) {
          this.controller.removePayments();
        }

        if (!!discountPercentage && self.model.discountPercentage !== discountPercentage) {
          if (self.model.isPackage) {
            CheckoutPackageAnalytics.trackUpdateDiscount(discountPercentage);
          } else {
            CheckoutServiceAnalytics.trackUpdateDiscount(discountPercentage);
          }
        } else if (!discountPercentage && !!self.model.discountPercentage) {
          if (self.model.isPackage) {
            CheckoutPackageAnalytics.trackClearDiscount();
          } else {
            CheckoutServiceAnalytics.trackClearDiscount();
          }
        }

        self.model.amount = amount;
        self.model.discountedAmount = discountedAmount;
        self.model.discountPercentage = discountPercentage;

        if (self.model.discountPercentage != null && self.model.discountPercentage !== 0) {
          self.$el
            .find('.js-appointments-price')
            .html(Wahanda.Currency.getFormatted(self.model.discountedAmount));
          self.$el
            .find('.js-appointments-price-original')
            .html(Wahanda.Currency.getFormatted(self.model.amount));
        } else {
          self.$el
            .find('.js-appointments-price')
            .html(Wahanda.Currency.getFormatted(self.model.amount));
          self.$el.find('.js-appointments-price-original').html('');
        }

        this.controller.updateTotals();
        this.controller.updatePayments();
        self.resetView();
      },
      onCancel: () => {
        self.resetView();
      },
    });

    self.$el.append(this.editView.render().$el);
  },
});
