import { call, put } from 'redux-saga/effects';
import apiUrl from 'common/api-url';
import * as api from 'reduxStore/apiService';

import { MenuGroupResponse } from './types';

import {
  archiveMenuGroup,
  getMenuGroup,
  getMenuGroupSuccess,
  getMenuGroupFailure,
  setMenuGroup,
  setMenuGroupSuccess,
  setMenuGroupFailure,
  deleteMenuGroup,
  deleteMenuGroupSuccess,
  deleteMenuGroupFailure,
} from './actions';

export function* getMenuGroupRequested(action: ReturnType<typeof getMenuGroup>) {
  try {
    const { id } = action.payload;
    const payload: MenuGroupResponse = yield call(api.get, apiUrl('MENU_GROUP', { id }));

    yield put(getMenuGroupSuccess(payload));
  } catch (err) {
    yield put(getMenuGroupFailure());
  }
}

export function* setMenuGroupRequested(action: ReturnType<typeof setMenuGroup>) {
  try {
    const { id } = action.payload;
    const isNew = !id;
    const shouldArchive = !action.payload.active;
    const url = apiUrl(isNew ? 'MENU_GROUP_CREATE' : 'MENU_GROUP', { id });
    const apiMethod = isNew ? api.post : api.put;
    const description = {
      content: action.payload?.description || '',
      contentType: 'HTML',
    };
    yield call(apiMethod, url, { ...action.payload, description }, true);

    yield put(setMenuGroupSuccess());

    if (shouldArchive) {
      yield put(archiveMenuGroup());
    }
  } catch (xhr: any) {
    const response = xhr.responseJson;
    yield put(
      setMenuGroupFailure({
        serverErrorName: response.errors[0].name,
      }),
    );
  }
}

export function* deleteMenuGroupRequested(action: ReturnType<typeof deleteMenuGroup>) {
  try {
    const { id } = action.payload;
    yield call(api.del, apiUrl('MENU_GROUP', { id }), true);

    yield put(deleteMenuGroupSuccess());
  } catch (xhr: any) {
    const response = xhr.responseJson;
    yield put(
      deleteMenuGroupFailure({
        serverErrorName: response.errors[0].name,
      }),
    );
  }
}
