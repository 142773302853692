import React from 'react';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';

import { NavigationAnalytics, L2Page, mapPageToL2Enum } from 'common/tracking/navigation';

import { Sidebar } from 'components/common/Sidebar';
import { Schedules, Team } from 'components/common/Icon';

import { ShiftsContainer } from 'components/shifts';
import { EmployeesContainer } from 'components/employees';

import { LIVE_CHAT_SOURCE, SHIFTS_TAB, TEAM_MEMBER_TAB, VALID_TABS } from './utils/constants';
import { getRedirectUrl } from './utils/utils';

import style from './TeamLayout.scss';

const store = storeBuilder();

interface Props {
  currentTab: string;
  isRotaTabPermitted: boolean;
  isTeamTabPermitted: boolean;
  venueId: number;
}

interface State {
  currentTab?: string;
  redirectUrl?: Location;
}

export class TeamLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentTab: this.getCurrentTab(this.props.currentTab),
      // eslint-disable-next-line react/no-unused-state
      redirectUrl: undefined,
    };
  }

  private tabContent: any;

  private teamMemberTab: any;

  public componentDidMount() {
    NavigationAnalytics.trackPageView(mapPageToL2Enum(this.props.currentTab));
    this.changeHash(this.getCurrentTab(this.props.currentTab));
    this.redirectPage();
  }

  public componentDidUpdate(prevProps) {
    if (
      prevProps.currentTab !== this.props.currentTab &&
      prevProps.venueId !== this.props.venueId
    ) {
      const currentTab = this.getCurrentTab(prevProps.currentTab);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentTab });
      this.changeHash(currentTab);
    }
  }

  private redirectPage() {
    const { currentTab, redirectUrl } = getRedirectUrl(
      this.props.isRotaTabPermitted,
      this.props.isTeamTabPermitted,
      this.state.currentTab,
    );
    if (currentTab) {
      this.setState({ currentTab });
      this.changeHash(currentTab);
    }
    if (redirectUrl) {
      window.location = redirectUrl;
    }
  }

  private isCurrentPage(page: string) {
    return this.state.currentTab === page;
  }

  private getCurrentTab(path: string) {
    const DEFAULT_TAB = this.props.isRotaTabPermitted ? SHIFTS_TAB : TEAM_MEMBER_TAB;
    if (!path) {
      return DEFAULT_TAB;
    }
    const pathInLower = path.toLowerCase() as L2Page;

    if (VALID_TABS.indexOf(pathInLower) === -1) {
      return DEFAULT_TAB;
    }

    return pathInLower;
  }

  private changeHash = (path: string) => {
    this.setState({
      currentTab: path,
    });
    window.location.hash = `venue/${this.props.venueId}/${path}`;
  };

  private onSidebarClick = (tab: string) => {
    this.changeHash(tab);
    NavigationAnalytics.trackSidebarClick(mapPageToL2Enum(tab));
  };

  private renderTab = () => {
    switch (this.state.currentTab) {
      case TEAM_MEMBER_TAB:
        return this.renderTeamMember();
      case SHIFTS_TAB:
      default:
        return this.renderShifts();
    }
  };

  private renderShifts = () => {
    return (
      <Provider store={store}>
        <ShiftsContainer
          venueId={this.props.venueId}
          onEmptyRotaClick={() => this.changeHash(TEAM_MEMBER_TAB)}
        />
      </Provider>
    );
  };

  private renderTeamMemberView = () => {
    const el = this.tabContent;

    const venueModel = new App.Models.Venue({
      venueId: this.props.venueId,
      relations: ['opening-hours', 'images', 'fulfillment-communication', 'phones'],
    });

    const categoriesCollection = new App.Collections.EmployeeCategories();
    categoriesCollection.nameComparatorEnabled(false);
    categoriesCollection.setVenueId(App.getVenueId());

    this.teamMemberTab = new App.Views.Settings.EmployeeEmployeesMaster({
      el,
      model: venueModel,
      categoriesCollection,
    });

    this.teamMemberTab.render();
  };

  private renderTeamMember() {
    window.setTimeout(this.renderTeamMemberView.bind(this), 0);
    return <EmployeesContainer />;
  }

  public render() {
    const sidebarLinks = [
      {
        dataTest: SHIFTS_TAB,
        icon: <Schedules />,
        isSelected: this.isCurrentPage(SHIFTS_TAB),
        isVisible: this.props.isRotaTabPermitted,
        onClick: () => this.onSidebarClick(SHIFTS_TAB),
        text: Wahanda.lang.settings.shifts.title,
      },
      {
        dataTest: TEAM_MEMBER_TAB,
        icon: <Team />,
        isSelected: this.isCurrentPage(TEAM_MEMBER_TAB),
        isVisible: this.props.isTeamTabPermitted,
        onClick: () => this.onSidebarClick(TEAM_MEMBER_TAB),
        text: Wahanda.lang.settings.employees.title,
      },
    ];

    return (
      <div className={style.container}>
        <Sidebar liveChatSource={LIVE_CHAT_SOURCE} links={sidebarLinks} />
        <div id={this.state.currentTab} className={style.contentWrapper}>
          <div
            className={style.content}
            ref={(tabContent) => {
              this.tabContent = tabContent;
            }}
          >
            {this.renderTab()}
          </div>
        </div>
      </div>
    );
  }
}
