import React, { useState } from 'react';

import { DialogCloseWithChanges } from 'components/common/dialog/DialogCloseWithChanges';
import { ModalContainer } from './ModalContainer';
import { ModalHeader } from './ModalHeader';

interface ModalProps {
  children: React.ReactNode;
  dataTest?: string;
  hasSplit?: boolean;
  initialPosition?: 'center' | 'top';
  onClose?: () => void;
  type?: 'small' | 'medium' | 'large' | 'xlarge';
  title?: string;
}

export const Modal = ({
  children,
  dataTest,
  hasSplit,
  initialPosition = 'center',
  onClose,
  type = 'small',

  title,
}: ModalProps) => {
  const [changeCaught, setChangeCaught] = useState(false);
  const [hasConfirmCloseWithChangesDialog, setHasConfirmCloseWithChangesDialog] = useState(false);

  const closeHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleClose = () => {
    if (changeCaught) {
      setHasConfirmCloseWithChangesDialog(true);
    } else {
      closeHandler();
    }
  };

  const renderConfirmCloseDialog = (
    <DialogCloseWithChanges
      onConfirm={closeHandler}
      onClose={() => {
        setHasConfirmCloseWithChangesDialog(false);
      }}
    />
  );

  const handleChange = () => {
    setChangeCaught(true);
  };

  return (
    <>
      <ModalContainer
        type={type}
        initialPosition={initialPosition}
        dataTest={dataTest}
        hasSplit={hasSplit}
        onClose={handleClose}
        onChange={handleChange}
      >
        <ModalHeader title={title} onClose={onClose} />
        {children}
      </ModalContainer>
      {hasConfirmCloseWithChangesDialog && renderConfirmCloseDialog}
    </>
  );
};
