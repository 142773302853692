/* global jQuery */
import React from 'react';
import ReactDOM from 'react-dom';
import { hashMap, vector, get, assoc, isEmpty, each, toJs } from 'mori';
import classnames from 'classnames';
import { trackEvent } from 'common/analytics';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import {
  save as saveVenueProduct,
  editProduct as editVenueProduct,
  deleteProduct as deleteVenueProduct,
} from 'src/services/ProductsService';
import ReactDialog from 'components/common/react-dialog';
import ReactDialogFooter from 'components/common/dialog/footer';
import FormValidationMixin from 'components/mixins/form-validation';
import extendMixin from 'common/extend-mixin';
import { Loader } from 'components/common/Loader';
import { InputRow } from './InputRow';

import style from './ProductDialog.scss';

interface IProductDialogProps extends React.HTMLAttributes<Element> {
  onClose?: (...args: any[]) => any;
  editedProduct?: {};
  vatRates?: {
    rate: number;
    category: string;
  }[];
  map?: any;
}
type ProductDialogState = {
  validationErrors?: any;
  saveError?: boolean;
  formSubmitted?: boolean;
  focusInvalid?: boolean;
  saving?: boolean;
  editedStateProduct?: any;
  setInventory?: any;
  deleting?: boolean;
};
class ProductDialog extends React.Component<IProductDialogProps, ProductDialogState> {
  defaultSaveFailHandler: any;

  dialog: any;

  form: any;

  getErrorTooltipMessage: any;

  inventory: any;

  name: any;

  onFormMaybeSubmitKeyup: any;

  onFormSubmit: any;

  price: any;

  setupValidation: any;

  tax: any;

  static defaultProps = {
    vatRates: [],
  };

  constructor(props) {
    super(props);
    extendMixin(this, FormValidationMixin);
  }

  state = {};

  componentDidMount() {
    trackEvent('products', 'view', 'product');
    if (isEmpty(this.props.editedProduct)) {
      this.dialogValidationSetup();
    }
  }

  componentDidUpdate() {
    this.dialogValidationSetup();
  }

  /*
   *  Unused?
   */
  onFormInvalid(errors) {
    this.setState({
      validationErrors: errors,
      saveError: false,
      formSubmitted: true,
      focusInvalid: true,
    });
  }

  /*
   *  Unused?
   */
  onFormValid() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'saving' does not exist on type '{}'.
    if (this.state.saving) {
      return;
    }
    const action = (() => {
      if (!isEmpty(this.props.editedProduct)) {
        return editVenueProduct;
      }
      return saveVenueProduct;
    })();
    const promise = action(
      hashMap(
        'id',
        this.props.editedProduct ? get(this.props.editedProduct, 'id') : null,
        'name',
        this.name.getValue(),
        'amount',
        this.price.getValue(),
        'vatCategory',
        this.tax.value,
        'currencyCode',
        App.config.get('currency').currencyCode,
        'inventory',
        this.getCurrentInventoryState() ? this.inventory.getValue() : null,
      ),
    );
    promise
      .done(() => {
        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        this.props.onClose(true);
      })
      .fail(this.defaultSaveFailHandler(Wahanda.lang.reports.transactions.transaction.errors));
    this.setState({
      saving: true,
    });
  }

  onNameChange = () => {
    this.onInputChange('name', this.name.getValue());
  };

  onPriceChange = () => {
    this.onInputChange('amount', this.price.getValue());
  };

  onTaxRateChange = (taxRate) => {
    this.onInputChange('vatCategory', taxRate);
  };

  onInventoryChange = () => {
    this.onInputChange('inventory', this.inventory.getValue());
  };

  onInputChange(inputName, value) {
    const changedEditedProduct = assoc(this.getProduct(), inputName, value);
    this.setState({
      editedStateProduct: changedEditedProduct,
    });
    this.setState({
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'revalidate' does not exist on type 'Prod... Remove this comment to see the full error message
      validationErrors: this.revalidate(this.form),
    });
  }

  getProduct() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'editedStateProduct' does not exist on ty... Remove this comment to see the full error message
    if (this.state.editedStateProduct) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'editedStateProduct' does not exist on ty... Remove this comment to see the full error message
      return this.state.editedStateProduct;
    }
    return this.props.editedProduct;
  }

  getCurrentInventoryState() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInventory' does not exist on type '{}... Remove this comment to see the full error message
    if (this.state.setInventory === undefined) {
      return get(this.getProduct(), 'inventory') !== null;
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInventory' does not exist on type '{}... Remove this comment to see the full error message
    return this.state.setInventory;
  }

  productDelete() {
    trackEvent('products', 'update', 'product', 'delete');
    this.setState({
      deleting: true,
    });
    deleteVenueProduct(get(this.props.editedProduct, 'id'))
      .done(() => {
        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        this.props.onClose(true);
      })
      .fail(this.defaultSaveFailHandler(Wahanda.lang.reports.transactions.transaction.errors));
  }

  updateValidationTooltips() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'validationErrors' does not exist on type... Remove this comment to see the full error message
    const validations = this.state.validationErrors;
    jQuery(this.form).find('input').qtip('destroy');
    each(validations, (item) => {
      const element = ReactDOM.findDOMNode(item.element); //eslint-disable-line
      // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type 'string'.
      jQuery(element).formErrorTip(
        // Can't use dataset as we need to support IE10.
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        element.getAttribute('data-error'),
      );
    });
  }

  focusFirstInvalid() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'validationErrors' does not exist on type... Remove this comment to see the full error message
    const validations = this.state.validationErrors;
    const invalidField = (() => {
      if (!isEmpty(validations)) {
        return validations[0].element;
      }
      return null;
    })();
    if (invalidField) {
      invalidField.focus();
    }
  }

  inventoryToggle = () => {
    const currentState = this.getCurrentInventoryState();
    this.setState({
      setInventory: !currentState,
    });
    if (!currentState) {
      window.setTimeout(() => {
        this.inventory.select();
      }, 10);
    }
  };

  dialogValidationSetup() {
    this.setupValidation();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'formSubmitted' does not exist on type '{... Remove this comment to see the full error message
    if (this.state.formSubmitted) {
      this.updateValidationTooltips();
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'focusInvalid' does not exist on type '{}... Remove this comment to see the full error message
      if (this.state.focusInvalid) {
        window.setTimeout(() => {
          this.focusFirstInvalid();
        }, 100);
        this.setState({
          focusInvalid: false,
        });
      }
    }
  }

  disableValidationSetupWhenMounted = true;

  refForm = (form) => {
    this.form = form;
  };

  refName = (name) => {
    this.name = name;
  };

  refPrice = (price) => {
    this.price = price;
  };

  refTax = (tax) => {
    this.tax = tax;
  };

  refInventory = (inventory) => {
    this.inventory = inventory;
  };

  refDialog = (dialog) => {
    this.dialog = dialog;
  };

  render() {
    const { onClose, editedProduct } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'editedStateProduct' does not exist on ty... Remove this comment to see the full error message
    const { editedStateProduct } = this.state;
    const isEditForm = !isEmpty(editedProduct);
    const isLoading = get(editedProduct, 'loading');
    const lang = Wahanda.lang.productDialog;
    const product = (() => {
      if (editedStateProduct) {
        return editedStateProduct;
      }
      return editedProduct;
    })();
    const saveButton = hashMap(
      'title',
      Wahanda.lang.shared.save,
      'onClick',
      () => {
        const isNewProduct = !!get(product, 'id');
        if (isNewProduct) {
          trackEvent('products', 'update', 'product', 'edit');
        } else {
          trackEvent('products', 'submit', 'new-product');
        }
        this.onFormSubmit();
      },
      'classes',
      (() => {
        const baseSaveClasses = 'dialog2--button-right dialog2--button-green';
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'saving' does not exist on type '{}'.
        const savingClass = this.state.saving ? ' dialog2--button-processing' : '';
        return baseSaveClasses + savingClass;
      })(),
    );
    const deleteButton = (() => {
      if (isEditForm) {
        return hashMap(
          'title',
          Wahanda.lang.shared.delete,
          'onClick',
          () => {
            this.productDelete();
          },
          'classes',
          'dialog2--button-left dialog2--button-red',
        );
      }
      return null;
    })();
    const buttons = vector(saveButton, deleteButton);
    const dialogTitle = isEditForm ? lang.titleEdit : lang.titleAdd;
    const name = get(product, 'name') || '';
    const amount = get(product, 'amount') || '';
    const taxRate =
      get(product, 'vatCategory') != null
        ? get(product, 'vatCategory')
        : App.getVenueStandartVat().category;
    const inventory = get(product, 'inventory');
    const showInventory = this.getCurrentInventoryState();
    const dialogContent = (() => {
      if (isLoading) {
        return <Loader positionAbsolute />;
      }
      return (
        <div>
          <div className="dialog2--copy">{lang.mainCopy}</div>
          <form
            data-hj-suppress
            /*
             * These methods don't exist ..?
             */
            onSubmit={this.onFormSubmit}
            onKeyUp={this.onFormMaybeSubmitKeyup}
            className="dialog2--form"
            ref={this.refForm}
          >
            <InputRow
              title={lang.labelName}
              name="name"
              value={name}
              wide
              onChange={this.onNameChange}
              ref={this.refName}
              error={this.getErrorTooltipMessage('name')}
            />
            <InputRow
              title={lang.labelPrice}
              name="price"
              value={amount}
              isNumber
              minZero
              min={0}
              onChange={this.onPriceChange}
              ref={this.refPrice}
              error={this.getErrorTooltipMessage('price')}
            />
            <div
              className={classnames('dialog2--inputrow clearfix tax-row', {
                hidden: !App.config.get('venue').pointOfSaleEnabled,
              })}
            >
              <div className="left">{Wahanda.lang.productDialog.labelTaxRate}</div>
              <div className={classnames('right', style.vatDropdown)}>
                <select ref={this.refTax} onChange={this.onTaxRateChange} defaultValue={taxRate}>
                  {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                  {this.props.vatRates.map(({ rate, category }) => (
                    <option key={rate} value={category}>
                      {rate}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={classnames('dialog2--inputrow clearfix')}>
              <div className="right">
                <input
                  id="record-inventory"
                  type="checkbox"
                  name="record-inventory"
                  onChange={this.inventoryToggle}
                  checked={showInventory}
                />
              </div>
              <label htmlFor="record-inventory" className="left">
                {lang.stockToggle}
              </label>
            </div>
            <InputRow
              title={lang.stockQuantity}
              name="inventory"
              value={inventory}
              isNumber
              integer
              hide={!showInventory}
              onChange={this.onInventoryChange}
              ref={this.refInventory}
              error={toJs(this.getErrorTooltipMessage('inventory'))}
            />
          </form>
          <ReactDialogFooter buttons={buttons} />
        </div>
      );
    })();
    return (
      <ReactDialog
        dataTest="product-modal"
        title={dialogTitle}
        onClose={onClose}
        classes={{
          'product-dialog': true,
          'pos-lite': !App.config.get('venue').pointOfSaleEnabled,
        }}
        width={530}
        ref={this.refDialog}
        noContentTopBorders
      >
        {dialogContent}
      </ReactDialog>
    );
  }
}

export default ProductDialog;
