import React from 'react';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import ReactDialog from 'components/common/react-dialog';
import Loader from 'components/common/Loader';
import Wahanda from 'common/wahanda';
import { OrderProducts } from 'common/analytics';
import ProductsTable from './ProductsTable/container';
import style from './style.scss';

const LANG = Wahanda.lang.menu.products.orders.newOrderDialog;
interface INewOrderDialogProps extends React.HTMLAttributes<Element> {
  actions: {
    requestCreateOrderAction: (...args: any[]) => any;
    productOrderReadyToBePlaced: (...args: any[]) => any;
  };
  newOrderItems?: {
    venueProductId: number;
    quantity: number;
  }[];
  allowToPlaceOrder: boolean;
  productSupplier?: {
    name: string;
  };
  onClose: (...args: any[]) => any;
  requestCreateOrderAction?: any;
  productOrderReadyToBePlaced?: any;
}

export default class NewOrderDialog extends React.Component<INewOrderDialogProps, {}> {
  static defaultProps = {
    newOrderItems: [],
    productSupplier: null,
  };

  componentDidMount() {
    OrderProducts.trackViewOrderProducts();
  }

  handlePlaceOrder = () => {
    OrderProducts.trackClickPlaceOrder();
    this.props.actions.productOrderReadyToBePlaced();
  };

  handleOrderCancelled = () => {
    OrderProducts.trackClickCancelOrderProducts();
    this.props.onClose();
  };

  handleDialogClose = () => {
    OrderProducts.trackClickCloseButton();
    this.props.onClose();
  };

  renderPlaceOrderButton = () => (
    <DialogFooterButton
      title={LANG.placeOrderButton}
      disabled={!(this.props.allowToPlaceOrder && !this.props.newOrderItems)}
      onClick={this.handlePlaceOrder}
    />
  );

  renderCancelButton = () => (
    <DialogFooterButton
      title={LANG.cancelButton}
      type="secondary"
      onClick={this.handleOrderCancelled}
    />
  );

  renderDialogFooter = () => (
    <DialogFooter>
      {this.renderCancelButton()}
      {this.renderPlaceOrderButton()}
    </DialogFooter>
  );

  render() {
    const { productSupplier } = this.props;
    if (this.props.newOrderItems) {
      this.props.actions.requestCreateOrderAction(this.props.newOrderItems);
    }
    return (
      <ReactDialog
        dataTest="product-new-order-modal"
        title={productSupplier ? productSupplier.name : LANG.title}
        onClose={this.handleDialogClose}
        width={700}
        warnWhenClosingChangedForms={false}
        noContentPadding
        noContentTopBorders
      >
        <div data-hj-suppress className={style.dialogContent}>
          {this.props.newOrderItems && <Loader positionAbsolute />}
          <ProductsTable />
        </div>
        {this.renderDialogFooter()}
      </ReactDialog>
    );
  }
}
