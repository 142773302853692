import React from 'react';
import { hashMap, vector } from 'mori';
import Dialog from 'components/common/react-dialog';
import Footer from 'components/common/dialog/footer';

interface IConfirmCancellationDialogProps extends React.HTMLAttributes<Element> {
  onAccept?: (...args: any[]) => any;
  onReject?: (...args: any[]) => any;
  title: string;
  message?: string;
}

/*
 * This component is a temporary solution copied from DiscountRuleDialog
 * It needs to be made as common and reused.
 * */
export default class ConfirmCancellationDialog extends React.Component<
  IConfirmCancellationDialogProps,
  {}
> {
  static defaultProps = {
    onAccept: () => {},
    onReject: () => {},
    message: '',
  };

  render() {
    const { onAccept, onReject, title, message } = this.props;
    const buttons = vector(
      hashMap(
        'onClick',
        onAccept,
        'title',
        'Cancel order', // Change to translations
        'classes',
        { 'dialog2--button-right dialog2--button-destructive': true },
      ),
      hashMap('onClick', onReject, 'title', 'Discard', 'classes', {
        'dialog2--button-right dialog2--button-grey': true,
      }),
    );
    return (
      <Dialog
        dataTest="product-cancellation-confirm-modal"
        warnWhenClosingChangedForms={false}
        classes={{ 'with-text-red-exclamation-icon': true }}
        hideTitlebar
        onClose={onReject}
      >
        <div className="text">
          <h2>{title}</h2>
          {message}
        </div>
        <Footer buttons={buttons} fullWidth />
      </Dialog>
    );
  }
}
