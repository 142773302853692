/* global BackboneEx */
/**
 * Clients main view.
 */
App.Views.Profile = BackboneEx.View.Main.extend({
  events: {},

  initialize() {
    // App.headerView isn't available in lightweight mode
    if (App.headerView) {
      App.headerView.setModule('profile');
    }
  },

  render() {
    this.openInitialDialog();
  },

  lightweightAuthAction() {
    this.openInitialDialog();
  },

  openInitialDialog() {
    if (
      App.mainViewOptions &&
      App.mainViewOptions.initialDialog &&
      App.mainViewOptions.initialDialog.type === 'profile-setup'
    ) {
      this.openProfileSetupDialog(App.mainViewOptions.initialDialog);
      delete App.mainViewOptions.initialDialog;
      return;
    }

    // User likely ended here through a deep link without having a mobile app. Nothing to do here.
    window.location.pathname = '';
  },

  /**
   * Opens the profile setup dialog.
   *
   * If the profile was already set up, an error will be shown and the user redirected to /calendar.
   *
   * @param Object options
   */
  openProfileSetupDialog(options) {
    const self = this;
    const model = new App.Models.Profile({
      id: options.profileId,
    });

    model.fetch({
      success() {
        const name = model.get('name');
        if (name === '' || name == null) {
          const dialog = new App.Views.Forms.Profile({
            refreshModel: true,
            model,
          });
          dialog.render();
          dialog.open();
          $('.ui-widget-overlay').attr('onclick', '').unbind('click');
        } else {
          self.showProfileAlreadyCreatedDialog();
        }
      },
    });
  },

  showProfileAlreadyCreatedDialog() {
    const View = BackboneEx.View.Dialog.extend({
      events: {
        'click button': function () {
          this.close();
          window.location = Wahanda.Url.getFullUrl('calendar');
        },
      },
      options: {
        dialogClass: 'alert-dialog success-msg',
        dataTest: 'profile-already-created-modal',
      },
      templateId: 'profile-already-created-template',
    });

    const dialog = new View();
    dialog.render();
    dialog.open();
  },
});
