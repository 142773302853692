import React from 'react';
import Wahanda from 'common/wahanda';
import classnames from 'classnames';
import style from './Currency.scss';

interface Props {
  alwaysNegative: boolean;
  amount: number;
  className: string;
}

export class Currency extends React.PureComponent<Props> {
  public static defaultProps = {
    amount: null,
    className: null,
    alwaysNegative: false,
  };

  get isNegative() {
    const { alwaysNegative, amount } = this.props;

    if (alwaysNegative && !!amount) {
      return true;
    }
    return amount < 0;
  }

  private getAmount = (amount) => {
    const negativeSymbol = this.isNegative ? '-' : '';
    const stringValue = Wahanda.Currency.format(Math.abs(amount), {
      forceDecimalPoint: true,
    });

    return `${negativeSymbol}${stringValue}`;
  };

  public render() {
    const { amount, className } = this.props;

    return <p className={classnames(style.currency, className)}>{this.getAmount(amount)}</p>;
  }
}
