import React from 'react';

const GooglePlay = (props) => (
  <svg width={100} height={30} {...props}>
    <defs>
      <path
        id="google-play_svg__a"
        d="M12.968 13.19l-3.03-3.036 2.771-2.842 3.482 1.976c1.008.572 1.005 1.501 0 2.072l-3.223 1.83zm-.827.469L1.839 19.507c-.454.257-.87.304-1.189.17l8.648-8.867 2.843 2.849zM.076 18.953a2.016 2.016 0 0 1-.063-.52V2.215c0-.233.033-.438.093-.613l8.544 8.56-8.574 8.79zM.744.943c.305-.093.685-.035 1.095.198l10.046 5.703-2.595 2.66L.744.943z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect
        width={99.083}
        height={29.083}
        x={0.459}
        y={0.459}
        fill="#FFF"
        stroke="#E6E6E6"
        strokeWidth={0.917}
        rx={3.336}
      />
      <g fill="#999">
        <path d="M52.64 9.733a2.402 2.402 0 0 1-2.406-2.4c0-1.325 1.077-2.4 2.405-2.4a2.402 2.402 0 0 1 2.405 2.4c0 1.326-1.077 2.4-2.405 2.4zm0-.733c.922 0 1.67-.746 1.67-1.667 0-.92-.748-1.666-1.67-1.666a1.668 1.668 0 1 0 0 3.333zm-16.3-1.4v1.467h1.937V9.6h-2.672V5.133h2.672v.534H36.34v1.4h1.737V7.6H36.34zm4.542-1.933V9.6h-.734V5.667h-1.136v-.534h3.006v.534h-1.136zm6.547 0V9.6h-.735V5.667h-1.136v-.534h3.006v.534H47.43zm-3.274-.534h1V9.6h-1zm12.225 0h.134l2.004 3.269V5.133h.735V9.6h-.735L56.38 6.114V9.6h-.735V5.133h.735zM32.852 7.145h2.012l-.02.635h-1.992z" />
        <path d="M34.468 5.65l-.46.458c-.288-.292-.696-.453-1.166-.453a1.7 1.7 0 0 0-1.703 1.698 1.7 1.7 0 0 0 1.703 1.698c.94 0 1.608-.542 1.607-1.698.001-.071 0-.14-.005-.208h.588v.023c.22 1.618-.885 2.55-2.21 2.538a2.358 2.358 0 0 1-2.36-2.353c0-1.3 1.056-2.353 2.36-2.353.681 0 1.254.237 1.646.65z" />
      </g>
      <g fill="#999">
        <path d="M72.145 13.133h3.787s2.18.37 2.18 2.869c0 2.498-2.18 2.869-2.18 2.869h-3.787v-5.738zm.735 1.388V17.6h2.571s1.17 0 1.17-1.54c0-1.539-1.17-1.539-1.17-1.539h-2.57z" />
        <path d="M71.811 13.133h1.336v9.333h-1.336zm7.081 0h1.336v9.333h-1.336zM85.18 21.8c-.368.302-.956.588-1.885.645-2.13.133-2.13-1.805-2.13-1.936 0-.13-.105-1.712 1.588-1.963 1.2-.178 2.039.118 2.427.307v-.275c-.095-.363-.39-1.03-1.276-1.03-1.216 0-1.485.656-1.485.656l-1.113-.656s.623-1.281 2.491-1.281c2.324 0 2.72 1.599 2.72 2.138a.22.22 0 0 1 0 .007v4.052H85.18v-.665zm-.095-2s-2.574-.692-2.664.75c-.09 1.444 2.47.751 2.698-.272 0-.325-.034-.478-.034-.478zm3.938 2.313l-2.382-5.646h1.336l1.714 4.062 1.7-4.03h1.336l-3.614 8.568h-1.336l1.246-2.954zM68.82 18.648l-4.225 1.743s.273.964 1.543.97c.995.006 1.556-.88 1.556-.88l1.073.725s-.866 1.396-2.637 1.396c-2.178 0-3.137-1.9-3.137-3.133 0-1.19.762-3.202 3.002-3.202 2.24 0 2.825 2.381 2.825 2.381zm-1.607-.493s-.532-.808-1.347-.659c-1.725.315-1.508 1.821-1.508 1.821l2.855-1.162zm-6.223-5.022h1.412v9.314H60.99zm-2.262 3.337l1.306.01v5.877c0 1.501-.896 3.086-3.004 3.086-2.11 0-2.79-1.864-2.79-1.864l1.2-.504s.366 1.15 1.652 1.15c1.465 0 1.636-1.33 1.636-1.786v-.638s-.507.792-1.766.792c-1.365 0-2.987-1.167-2.987-3.156 0-1.99 1.528-3.128 3.003-3.17 1.182-.033 1.75.783 1.75.783v-.58zm-1.654 4.929c.952 0 1.725-.871 1.725-1.946 0-1.074-.773-1.945-1.725-1.945-.953 0-1.726.87-1.726 1.945 0 1.075.773 1.946 1.726 1.946zM50.208 22.6a3.173 3.173 0 0 1-3.18-3.167 3.173 3.173 0 0 1 3.18-3.166 3.173 3.173 0 0 1 3.18 3.166 3.173 3.173 0 0 1-3.18 3.167zm-.02-1.242c.987 0 1.787-.862 1.787-1.925 0-1.063-.8-1.925-1.788-1.925-.987 0-1.787.862-1.787 1.925 0 1.063.8 1.925 1.787 1.925zm-6.86 1.242a3.173 3.173 0 0 1-3.18-3.167 3.173 3.173 0 0 1 3.18-3.166 3.173 3.173 0 0 1 3.18 3.166 3.173 3.173 0 0 1-3.18 3.167zm-.021-1.242c.987 0 1.788-.862 1.788-1.925 0-1.063-.8-1.925-1.788-1.925-.987 0-1.788.862-1.788 1.925 0 1.063.8 1.925 1.788 1.925zm-8.191-4.088h4.199l-.042 1.325h-4.157z" />
        <path d="M38.488 14.154l-.96.957c-.6-.61-1.45-.945-2.433-.945a3.547 3.547 0 0 0-3.554 3.54 3.547 3.547 0 0 0 3.554 3.538c1.963 0 3.357-1.13 3.354-3.539.003-.149 0-.294-.01-.434h1.226v.049c.462 3.373-1.845 5.315-4.611 5.29-2.721-.003-4.927-2.196-4.927-4.905s2.206-4.905 4.927-4.905c1.422 0 2.617.495 3.434 1.354z" />
      </g>
      <use fill="#999" transform="translate(7.281 4.283)" xlinkHref="#google-play_svg__a" />
    </g>
  </svg>
);

export default GooglePlay;
