import React from 'react';
import Wahanda from 'common/wahanda';
import classnames from 'classnames';
import { ChannelSalesReportAnalytics } from 'common/analytics';
import ServiceLink from '../ServiceSalesReport/link';
import ProductLink from '../ProductSalesReport/link';
import tableStyle from '../common/reportTable.scss';
import { CHANNEL } from '../BookingChannelFilter';

interface IReportTableProps extends React.HTMLAttributes<Element> {
  fromDate: any;
  toDate: any;
  reportData: any;
}

export default class ReportTable extends React.Component<IReportTableProps, {}> {
  getTableHeader = () => (
    <thead>
      <tr>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={tableStyle.stackedHeaderDoubleHeight} rowSpan="2">
          {Wahanda.lang.reports.sales.channelSalesReport.headings.bookingChannel}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.channelSalesReport.headings.services}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.channelSalesReport.headings.products}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.channelSalesReport.headings.vouchers}
        </th>
        <th
          className={classnames(tableStyle.centerHeader, tableStyle.stackedHeaderDoubleHeight)}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
          rowSpan="2"
        >
          {Wahanda.lang.reports.sales.channelSalesReport.headings.totalSalesIncludingVat}
        </th>
      </tr>
      <tr>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.channelSalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.channelSalesReport.headings.salesIncludingVat}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.channelSalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.channelSalesReport.headings.salesIncludingVat}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.channelSalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.channelSalesReport.headings.salesIncludingVat}
        </th>
      </tr>
    </thead>
  );

  getTableFooter = (total) => (
    <tfoot>
      <tr data-testid="channel-sales-grand-total">
        <td>{Wahanda.lang.reports.sales.channelSalesReport.headings.grandTotal}</td>
        <td data-testid="channel-sales-services-qty" className={tableStyle.numeric}>
          {total.numberOfServices}
        </td>
        <td data-testid="channel-sales-services-gross" className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.serviceGrossTotal)}
        </td>
        <td data-testid="channel-sales-product-qty" className={tableStyle.numeric}>
          {total.numberOfProducts}
        </td>
        <td data-testid="channel-sales-product-gross" className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.productGrossTotal)}
        </td>
        <td data-testid="channel-sales-voucher-qty" className={tableStyle.numeric}>
          {total.numberOfVouchers}
        </td>
        <td data-testid="channel-sales-voucher-gross" className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.voucherGrossTotal)}
        </td>
        <td data-testid="channel-sales-gross-total" className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.grossTotal)}
        </td>
      </tr>
    </tfoot>
  );

  getOneRowWithProductLink = (row, channel, channelDisplayString, testId) => {
    const { fromDate, toDate } = this.props;
    return (
      <tr data-testid={testId} key={channel}>
        <td data-testid="channel-sales-channel">{channelDisplayString}</td>
        <td data-testid="channel-sales-services-qty" className={tableStyle.numeric}>
          <ServiceLink
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={tableStyle.link}
            fromDate={fromDate}
            toDate={toDate}
            selectedChannel={channel}
            onClick={ChannelSalesReportAnalytics.trackLinkToServicesSalesClick}
          >
            {row.numberOfServices}
          </ServiceLink>
        </td>
        <td data-testid="channel-sales-services-gross" className={tableStyle.numeric}>
          <ServiceLink
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={tableStyle.link}
            fromDate={fromDate}
            toDate={toDate}
            selectedChannel={channel}
            onClick={ChannelSalesReportAnalytics.trackLinkToServicesSalesClick}
          >
            {Wahanda.Currency.formatFinancial(row.serviceGrossTotal)}
          </ServiceLink>
        </td>
        <td data-testid="channel-sales-product-qty" className={tableStyle.numeric}>
          <ProductLink
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={tableStyle.link}
            fromDate={fromDate}
            toDate={toDate}
            onClick={ChannelSalesReportAnalytics.trackLinkToProductsSalesClick}
          >
            {row.numberOfProducts}
          </ProductLink>
        </td>
        <td data-testid="channel-sales-product-gross" className={tableStyle.numeric}>
          <ProductLink
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={tableStyle.link}
            fromDate={fromDate}
            toDate={toDate}
            onClick={ChannelSalesReportAnalytics.trackLinkToProductsSalesClick}
          >
            {Wahanda.Currency.formatFinancial(row.productGrossTotal)}
          </ProductLink>
        </td>
        <td data-testid="channel-sales-voucher-qty" className={tableStyle.numeric}>
          {row.numberOfVouchers}
        </td>
        <td data-testid="channel-sales-voucher-gross" className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(row.voucherGrossTotal)}
        </td>
        <td data-testid="channel-sales-gross-total" className={tableStyle.grandTotal}>
          {Wahanda.Currency.formatFinancial(row.grossTotal)}
        </td>
      </tr>
    );
  };

  getOneRow = (row, channel, channelDisplayString, testId) => {
    const { fromDate, toDate } = this.props;
    return (
      <tr data-testid={testId} key={channel}>
        <td data-testid="channel-sales-channel">{channelDisplayString}</td>
        <td data-testid="channel-sales-services-qty" className={tableStyle.numeric}>
          <ServiceLink
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={tableStyle.link}
            fromDate={fromDate}
            toDate={toDate}
            selectedChannel={channel}
            onClick={ChannelSalesReportAnalytics.trackLinkToServicesSalesClick}
          >
            {row.numberOfServices}
          </ServiceLink>
        </td>
        <td data-testid="channel-sales-services-gross" className={tableStyle.numeric}>
          <ServiceLink
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={tableStyle.link}
            fromDate={fromDate}
            toDate={toDate}
            selectedChannel={channel}
            onClick={ChannelSalesReportAnalytics.trackLinkToServicesSalesClick}
          >
            {Wahanda.Currency.formatFinancial(row.serviceGrossTotal)}
          </ServiceLink>
        </td>
        <td data-testid="channel-sales-product-qty" className={tableStyle.numeric}>
          {row.numberOfProducts}
        </td>
        <td data-testid="channel-sales-product-gross" className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(row.productGrossTotal)}
        </td>
        <td data-testid="channel-sales-voucher-qty" className={tableStyle.numeric}>
          {row.numberOfVouchers}
        </td>
        <td data-testid="channel-sales-voucher-gross" className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(row.voucherGrossTotal)}
        </td>
        <td data-testid="channel-sales-gross-total" className={tableStyle.grandTotal}>
          {Wahanda.Currency.formatFinancial(row.grossTotal)}
        </td>
      </tr>
    );
  };

  render() {
    const {
      treatwellChannelSalesRow,
      widgetChannelSalesRow,
      connectChannelSalesRow,
      total,
    } = this.props.reportData;
    return (
      <div>
        <table className={tableStyle.reportTable}>
          {this.getTableHeader()}
          <tbody>
            {this.getOneRow(
              treatwellChannelSalesRow,
              CHANNEL.TREATWELL,
              Wahanda.lang.reports.sales.channelSalesReport.headings.channelTreatwell,
              'channel-sales-treatwell',
            )}
            {this.getOneRow(
              widgetChannelSalesRow,
              CHANNEL.WIDGET,
              Wahanda.lang.reports.sales.channelSalesReport.headings.channelWidget,
              'channel-sales-widget',
            )}
            {this.getOneRowWithProductLink(
              connectChannelSalesRow,
              CHANNEL.CONNECT,
              Wahanda.lang.reports.sales.channelSalesReport.headings.channelConnect,
              'channel-sales-connect',
            )}
          </tbody>
          {this.getTableFooter(total)}
        </table>
      </div>
    );
  }
}
