import React from 'react';
import { Close } from 'components/common/Icon';

import style from './ModalCloseButton.scss';

interface ModalCloseButtonProps {
  dataTest?: string;
  onClose: () => void;
}

export const ModalCloseButton = (props: ModalCloseButtonProps) => {
  const { dataTest, onClose } = props;
  return (
    <span
      className={style.container}
      data-test={dataTest || 'modal-close-button'}
      role="button"
      onClick={onClose}
      tabIndex={0}
    >
      <Close size="small" />
    </span>
  );
};
