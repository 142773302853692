/* global BackboneEx */
App.Views.Dialog.NewEmployeeImage = BackboneEx.View.Dialog.extend({
  events: {
    'click .button-primary': 'openUploadImageWindow',
    'click .button-cancel': 'close',
  },
  templateId: 'new-employee-image-dialog',

  options: {
    width: 600,
    title: Wahanda.lang.settings.employees.employee.image.title,
    dialogClass: 'alert-dialog',
    dataTest: 'new-employee-image-modal',
  },

  openUploadImageWindow() {
    const view = new App.Views.Forms.Employee.EditImage({
      model: this.model,
    });
    view.render();
    view.open();
    this.close();
  },
});
