import React from 'react';
import { ModalCloseButton } from '../ModalCloseButton';

import style from './ModalHeader.scss';

interface ModalHeaderProps {
  onClose?: (...args: any[]) => void;
  leftAction?: React.ReactNode;
  showClose?: boolean;
  title?: string;
}

export const ModalHeader = ({ leftAction, title, onClose, showClose = true }: ModalHeaderProps) => {
  return (
    <div className={style.container}>
      {leftAction && <div className={style.leftAction}>{leftAction}</div>}
      {title && <div className={style.title}>{title}</div>}
      {showClose && onClose && <ModalCloseButton onClose={onClose} />}
    </div>
  );
};
