import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ClientsImportCampaignPrompt as Content } from './ClientsImportCampaignPrompt';

import { clientsImportReset } from '../actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ clientsImportReset }, dispatch),
});

export const ClientsImportCampaignPrompt = connect(null, mapDispatchToProps)(Content);
