import React from 'react';
import { map, hash, intoArray } from 'mori';
import Criteria from './Criteria';

function buildList(criteriaList) {
  return intoArray(
    map((criteria) => <Criteria key={hash(criteria)} criteria={criteria} />, criteriaList),
  );
}

interface ICriteriaListProps extends React.HTMLAttributes<Element> {
  criteriaList: {};
  maxHeight?: number;
}

export default class CriteriaList extends React.Component<ICriteriaListProps, {}> {
  render() {
    const { criteriaList, maxHeight } = this.props;
    const styles = { maxHeight };
    return (
      <div className="scrollable-y" style={styles}>
        {buildList(criteriaList)}
      </div>
    );
  }
}
