import { toClj, merge, isMap, curry } from 'mori';
import { appState } from 'state';

// TODO: also need a method of passing changes back to Backbone

/**
 * This function exposes a way to trigger appState changes from the Backbone world.
 * Use wisely, most of the time it is better to write separate initializers for specific use cases
 * than to directly update the appState.
 *
 * @param {Object} changes Map of changes which will be applied to appState directly.
 *
 * @returns {void}
 */
export default function changeStateExternally(changes) {
  const changeMap = toClj(changes);

  if (!isMap(changeMap)) {
    throw new Error('`changes` must be a map or a map-like object');
  }

  appState.swap(curry(merge, changeMap));
}
