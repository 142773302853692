import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class BellOn extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-bell-on', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M14,20 C14,21.1045695 13.1045695,22 12,22 C10.8954305,22 10,21.1045695 10,20 L10,20 Z M12,2 C16.1282788,2 17.2664597,4.06941981 17.9922779,9.87596527 C18.1530675,11.1622819 18.3960204,12.4348112 18.6990757,13.6664175 C18.9006671,14.4856776 19.116468,15.2371504 19.3317406,15.9032983 C19.4594674,16.2985413 19.5572953,16.5741647 19.6210885,16.7419948 C20.0285649,17.9130933 19.2299233,19 17.9931545,19 L6.00684547,19 C4.76389734,19 3.98663489,17.8973292 4.39752699,16.6889003 C4.45040237,16.5510558 4.54785562,16.276569 4.67512694,15.8825238 C4.88967883,15.2182488 5.10476975,14.4681382 5.3057095,13.649435 C5.60614233,12.4253599 5.84737015,11.1587811 6.00772212,9.87596527 C6.7335403,4.06941981 7.8717212,2 12,2 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(BellOn);
