import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Notebook extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-notebook', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M6,11 L6,17 L2,17 L2,15 L4,15 L4,11 L6,11 Z M17,15 L17,17 L12,17 L12,15 L17,15 Z M10,15 L10,17 L8,17 L8,15 L10,15 Z M17,11 L17,13 L12,13 L12,11 L17,11 Z M10,11 L10,13 L8,13 L8,11 L10,11 Z M4,7 L4,4.99895656 C4,3.89264812 4.89894363,3 6.00748397,3 L18.992516,3 C20.1004282,3 21,3.8915247 21,5.00087166 L21,18.9991283 C21,20.1060643 20.1014822,21 18.992516,21 L6.00748397,21 C4.9015007,21 4,20.1018146 4,19 L6,19 C6,18.9970615 10.3308387,18.9970615 18.992516,19 L18.9929621,18.9839137 C18.9971357,18.6782734 18.9994817,14.0172594 19,5.00087166 L6.00748397,5 L6.00748397,5 C6.00414042,5 6.00191401,5.33217993 6.00080474,5.99653979 L6,9 L2,9 L2,7 L4,7 Z M17,7 L17,9 L12,9 L12,7 L17,7 Z M10,7 L10,9 L8,9 L8,7 L10,7 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Notebook);
