/**
 * Dynamic form View implementation.
 * 
 * The "dynamic" is in that sense, that the IDs of the form must have suffixes added, so they stay unique.
 */
BackboneEx.View.DynamicForm = Backbone.View.extend({
	// @var string. Prefix of form fields
	fieldPrefix: null,
	
	/**
	 * Make the form IDs unique.
	 */
	initMakeIdsUnique: function() {
		var unq = this._getUniqueSuffix();
		this.$('input, select, textarea').each(function() {
			this.id += unq;
		});
	},
	
	_getUniqueSuffix: function() {
		return "-" + this.cid;
	},
	
	/**
	 * Select the field (working around the automatic generation of IDs).
	 * 
	 * @param String id
	 * @return jQuery
	 */
	$$: function(id) {
		return this.$('#' + this.fieldPrefix + id + this._getUniqueSuffix());
	}
});
