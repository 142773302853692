import React from 'react';
import Dialog from 'components/common/react-dialog';
import { DialogFooter, DialogFooterButtonProps } from 'components/common/DialogFooter';
import Wahanda from 'common/wahanda';
import { EmailCampaignPreviewAnalytics as Analytics } from 'common/analytics';
import style from './CampaignError.scss';

interface ICampaignErrorProps extends React.HTMLAttributes<Element> {
  onRetry: (...args: any[]) => any;
  onClose?: (...args: any[]) => any;
}
class CampaignError extends React.Component<ICampaignErrorProps, {}> {
  static defaultProps = {
    onClose: null,
  };

  componentDidMount() {
    Analytics.trackError();
  }

  onClientListClick = () => {
    Analytics.trackClientsLinkClick();
    window.location.assign('/clients');
  };

  render() {
    const buttons: DialogFooterButtonProps[] = [
      {
        title: Wahanda.lang.header.clients,
        type: 'secondary',
        onClick: this.onClientListClick,
      },
      {
        title: Wahanda.lang.shared.buttons.retry,
        onClick: this.props.onRetry,
      },
    ];
    return (
      <Dialog
        dataTest="email-campaign-error-modal"
        onClose={this.props.onClose}
        title={Wahanda.lang.shared.error}
        hideTitlebar={this.props.onClose === null}
      >
        <div className={style.error}>{Wahanda.lang.marketing.error.description}</div>
        <DialogFooter buttons={buttons} fullWidth />
      </Dialog>
    );
  }
}

export default CampaignError;
