import React from 'react';
import classnames from 'classnames';

import { Align } from 'components/common/Table';
import style from './style.scss';

interface Props {
  align?: Align;
  className?: string;
  colSpan?: any;
  rowSpan?: any;
}

export class TableColumnHeader extends React.PureComponent<Props> {
  public render() {
    const { children, className, align = Align.LEFT, ...rest } = this.props;
    return (
      <th className={classnames(className, style[align], style.header)} {...rest}>
        {children}
      </th>
    );
  }
}
