/* eslint-disable func-names */
/* global BackboneEx _ */
import { CalendarAnalytics, NoShowFlowAnalytics } from 'common/analytics';
import React from 'react';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import { SelectOptionType } from 'components/common/__BaseCommon';

(() => {
  const BaseView = BackboneEx.View.Base;
  const MAX_SKU_CHOICES = 6;

  function maybeGetEventPosition(event) {
    return event ? { x: event.clientX, y: event.clientY } : null;
  }

  /**
   * The single Appointment view.
   *
   * Note on rendering: the views are rerendered after the model is changed (or reverted)
   * so please take extra care that you reset all state when render is called.
   *
   * Note on saving: the save is called externally, from appointment2.js.
   */
  const AppointmentItem = BaseView.extend({
    // The correct events are set in the initializer and after `update` call.
    // events: null,
    commonEvents: {
      'change .js-skus-chosen': 'onChosenSkusChange',
      'click .js-add-note': 'onAddNote',
      // Appointment actions
      'click .js-remove-unsaved': 'onRemoveClick',
      'click .js-order-ref': 'openOrderDialog',
      'click .js-evoucher': 'openOrderDialogFromEvoucher',
      'click .js-reject': 'onRejectClick',
      'click .js-reschedule': 'onRescheduleClick',
    },
    // Events which will be added if this is an edit, not a appointment creation
    modelUpdateEvents: {
      change: 'onAnyChange',
      'keyup textarea': 'onTextareaKeyup',
      // Appointment actions
      'click .js-no-show': 'onNoShowClick',
      'click .js-delete': 'onDeleteClick',
    },

    templateId: 'appointment2-form-item-template',
    notesTemplateId: 'appointment2-form-item-editable-notes-template',
    actionsTemplateId: 'appointment2-form-item-actions-template',
    priceTemplateId: 'appointment2-form-price-template',
    apptRowTemplateId: 'appointment2-form-item-row-template',
    packageNameTemplateId: 'appointment2-form-package-name',
    formFieldPrefix: 'appt-',
    chosenDropShown: false,
    createdAppointmentGroup: null,

    rendered: false,
    updateAfterSave: true,
    formState: null,
    // Is this Appointment rendered in a ApptGroup view?
    inGroupView: false,
    appointmentViews: null,
    setOfferId: undefined,
    // If set to false, validation errors are not shown during `isValid()`
    showValidationErrors: true,
    isChanged: false,

    modelOptionsList: null,
    isMissingSkuSelected: false,
    missingSkuAttentionElement: '.js-missing-sku-attention',

    initialize(options) {
      BaseView.prototype.initialize.call(this, options);
      // Listen for relevant model events
      _.each(
        ['checked-out'],
        function (event) {
          this.listenTo(this.model, event, this.onAfterSave);
        },
        this,
      );

      _.each(
        ['rejected', 'no-show', 'deleted'],
        function (event) {
          this.listenTo(this.model, event, this.refreshOrClose);
        },
        this,
      );

      this.listenTo(this, 'saved', this.onAfterSave);

      if (this.collection) {
        this.bindCollectionEvents();
      }

      const events = _.extend({}, this.commonEvents);
      const md = this.options.mediator;

      if (!this.model.id) {
        // Listen for customer selection/walkin events
        this.listenTo(md, md.UNSAVED_APPOINTMENTS_CHANGED, this.onUnsavedApptViewsChange);
        // Maybe don't update after save? The parent view will close after all appointments are saved.
        this.updateAfterSave = !this.options.externalSave;
      } else {
        // Add additional event handlers for model update
        _.extend(events, this.modelUpdateEvents);
      }

      if (!this.model.id || this.model.isWalkin()) {
        this.listenTo(md, md.CUSTOMER_SET_WALKIN, this.onWalkinSet);
        this.listenTo(md, md.CUSTOMER_FOUND, this.onCustomerFound);
      }

      this.inGroupView = options.inGroupView;

      this.events = events;

      this.isFirstForToday = options.isFirstForToday;
      this.newSkus = [];

      this.employeeOfferList = [];
      this.offerList = [];

      this.employees = Wahanda.Cache.employeesWithOffers();
    },

    bindCollectionEvents() {
      const self = this;
      // Proxy local collection events to mediator
      const eventMap = {
        confirmed: this.options.mediator.APPOINTMENT_GROUP_CONFIRMED,
        rejected: this.options.mediator.APPOINTMENT_GROUP_REJECTED,
        'checked-out': this.options.mediator.APPOINTMENT_CHECKED_OUT,
      };
      _.each(eventMap, function (mediatorEvent, collectionEvent) {
        self.listenTo(self.collection, collectionEvent, function () {
          self.onAfterSave();
          self.options.mediator.trigger(mediatorEvent, self.collection);
        });
      });

      const refreshEventMap = {
        'no-show': this.options.mediator.APPOINTMENT_GROUP_SET_NOSHOW,
        deleted: this.options.mediator.APPOINTMENT_GROUP_CANCELLED,
      };
      _.each(refreshEventMap, function (mediatorEvent, collectionEvent) {
        self.listenTo(self.collection, collectionEvent, function () {
          self.options.mediator.trigger(mediatorEvent, self.collection);
          self.refreshOrClose();
        });
      });

      this.listenTo(this.collection, 'saved', this.onAfterSave);
    },

    render() {
      // This renders the main container, and the footer inc. notes, pricing
      const el = Wahanda.Template.renderTemplate(this.templateId, this.getTemplateVars(), {
        notesTpl: Wahanda.Template.get(this.notesTemplateId),
        appointmentActionsTpl: Wahanda.Template.get(this.actionsTemplateId),
        priceTemplate: Wahanda.Template.get(this.priceTemplateId),
        apptRowTemplate: Wahanda.Template.get(this.apptRowTemplateId),
      });

      const $el = $(el);
      this.renderAppointmentRows($el, { useModelData: true });

      if (this.rendered) {
        // Re-rendering means replacing the appointment div with a new one.
        this.undelegateEvents();
        this.$el.replaceWith($el);
      }
      this.rendered = true;

      $el.find('.js-payment-protection-badge').each((i, node) => {
        App.ES6.Initializers.PaymentProtectionBadge({ node }).render();
      });

      this.setElement($el);

      this.renderServices();

      this.renderSkus(true);
      this.toggleCategoryMissmatchWarning();

      if (this.model.id) {
        if (this.model.isReadOnly()) {
          this.preventEditing();
        }
      } else if (!this.options.externalSave) {
        this.$('.js-remove-unsaved').wShow();
      }

      this.setupValidation();

      this.unloadmask();

      // Reset the form state to "nothing is changed".
      this.formState = {};

      this.options.initialDuration = null;
    },

    getServicesList() {
      const $target = this.$('.js-offerId');
      const isReadonly =
        this.collection || this.model.isReadOnly() || (this.model.id > 0 && this.inGroupView);

      if (this.model.id && isReadonly) {
        const pricePrepaid = this.model.isPaidByClient() && !this.model.isFree();
        const unpaid = this.model.isUnpaid() && !this.model.isFree();
        // It's a Package Edit, or Checked-out rendering - don't render the Services dropdown.
        const offer = this.getSelectedOffer();
        $target.html(
          Wahanda.Template.renderTemplate(
            this.packageNameTemplateId,
            {
              standalone: !this.inGroupView,
              name: offer ? offer.get('name') : this.model.get('offerName'),
              price: this.model.getAmountText(),
              pricePrepaid,
              unpaid,
              showPaymentStatus: true,
            },
            {
              priceTemplate: Wahanda.Template.get(this.priceTemplateId),
            },
          ),
        );
        return;
      }

      $target.addClass('is-react');

      const offerList = this.options.menu.toSimpleList({
        empty: !this.model.id,
        skipPackages: this.model.id > 0,
        skipDated: true,
        alwaysInclude: this.model.get('offerId'),
        initialOptionName: Wahanda.lang.calendar.appointments.multi.selectService,
      });

      this.offerList = offerList;
    },

    renderServices(offersList, optionsFooter, offerId) {
      if (!this.offerList.length) {
        this.getServicesList();
      }
      const offers = offersList || this.offerList;
      const employeeId = this.model.get('employeeId');
      const currentOfferId = offerId || this.model.get('offerId');

      this.setOfferId = offerId || this.model.get('offerId') || null;
      this.renderServicesDropdown(
        {
          data: offers,
          disabled: !this.model.canUserEditAppointment(),
          selected: this.setOfferId,
          optionsFooter,
          isOptionsFooterSticky: true,
          focusInput: this.focusInput,
          typeahead: true,
          onSelect: (...args) => {
            this.onOfferChange(...args);
            this.onAnyChange();
            this.triggerSelectChangeEvent(...args);
          },
          onStateChange: this.options.toggleDialogClosing,
          onSearchInputChange: () => this.loadFullServices(false),
          placeholderNoResult: Wahanda.lang.shared.noMatches,
        },
        false,
      );

      this.focusInput = false;

      if (this.employeeOfferList && !this.employeeOfferList.length) {
        this.renderEmployeeServices(employeeId, currentOfferId);
      }
    },

    triggerSelectChangeEvent(selectedValue, typedValue) {
      CalendarAnalytics.trackUDVSelectChange(selectedValue, typedValue);
    },

    renderEmployeeServices(employeeId, offerId) {
      this.employees.done((employees) => {
        const employee = employees.models.find((model) => model.id === employeeId);
        const employeeName = employee && employee.get('name');

        this.employeeOfferList = this.employeeServicesList(employee, this.offerList);

        const performedBy = Wahanda.Template.render(
          Wahanda.lang.calendar.appointments.textTemplates.servicesPerformedBy,
          {
            name: employeeName,
          },
        );

        const optionsFooter = (
          <div className="udv-load-more-services">
            <div>{performedBy}</div>
            <span
              className="udv-load-more-services-link"
              role="button"
              tabIndex={0}
              data-test="udv-load-more-services"
              onClick={() => this.loadFullServices()}
            >
              {Wahanda.lang.calendar.buttons.showAllServices}
            </span>
          </div>
        );

        const employeePerformsAllServices =
          this.employeeOfferList && this.employeeOfferList.length === this.offerList.length;
        const offerNotInEmployeeOfferList =
          offerId &&
          this.employeeOfferList &&
          !this.employeeOfferList.find((offer) => offer.value === offerId);

        if (offerNotInEmployeeOfferList || employeePerformsAllServices) {
          this.renderServices(this.offerList);
        } else {
          this.renderServices(this.employeeOfferList, optionsFooter, offerId);
        }
      });
    },

    loadFullServices(shouldTrackAction = true) {
      this.renderServices(this.offerList);
      if (shouldTrackAction) {
        this.focusInput = true;
        CalendarAnalytics.trackServiceShowMoreClick();
      }
    },

    employeeServicesList(employee, offerList) {
      const employeeOffers = employee.get('employeeOffers') || [];
      const offersCollection = this.options.menu.get('offersCollection').toJSON();
      const employeePackages = offersCollection
        .filter(
          (item) =>
            !!item.subServices &&
            item.subServices.some((sub) => employeeOffers.includes(sub.serviceId)),
        )
        .map((item) => item.id);

      const fullOfferList = [...employeeOffers, ...employeePackages];

      const mappedOfferList = offerList.filter((offer) => {
        if (fullOfferList.includes(offer.value) || offer.value == null || offer.grouping) {
          return offer;
        }
        return undefined;
      });

      const groupedByValue = groupBy(mappedOfferList, 'groupingValue');
      const offerGroupValues = Object.keys(groupedByValue);
      const groupedOffer = groupBy(
        mappedOfferList,
        (offer) =>
          offer.value == null ||
          offerGroupValues.includes(offer.value.toString()) ||
          !!offer.groupingValue,
      );

      return Object.values(groupedOffer)[0];
    },

    renderServicesDropdown(data, useCache) {
      const dataTest = 'udv-service-select';
      this.renderReactDropdown(
        this.$('.js-offerId'),
        'cachedServicesDropdownData',
        data,
        useCache,
        dataTest,
      );
    },

    getCleanupTimeLengthTemplate(time) {
      return Wahanda.Template.render(
        Wahanda.lang.calendar.appointments.textTemplates.cleanup,
        Wahanda.Time.minutesToTimeHash(time),
      );
    },

    // Renders cleanup time below UDV footer which can be rendered
    // for single appointment and package
    renderCleanupTime($el, time) {
      const $element =
        ($el && $el.find('.js-appointment-cleanup')) || this.$('.js-appointment-cleanup');
      const modelCleanupTime = this.model.getAppointmentCleanupTimeLength();
      const cleanupTime = time || modelCleanupTime;
      $element.hide();

      if (time !== 0 && cleanupTime > 0) {
        $element.show();
        const text = this.getCleanupTimeLengthTemplate(cleanupTime);
        if (this.$el.length === 1) {
          $element.text(text);
        }
      }
    },

    // Renders cleanup time line inside package between appointments
    renderCleanupTimeInPackage($element, time) {
      const modelCleanupTime = this.model.getAppointmentCleanupTimeLength();
      const cleanupTime = time || modelCleanupTime;
      if (cleanupTime > 0) {
        const text = this.getCleanupTimeLengthTemplate(cleanupTime);
        $element.after(`<div class="appointment--item--cleanup">${text}</div>`);
      }
    },

    renderReactDropdown($target, cacheName, data, useCache, dataTest) {
      if (!$target.hasClass('is-react')) {
        // No react class found. Must be read-only text field.
        return;
      }

      if (!useCache || !this[cacheName]) {
        this[cacheName] = data;
      } else {
        _.extend(this[cacheName], data);
      }

      App.ES6.Initializers.Select({
        node: $target[0],
        ...this[cacheName],
        dataTest,
      }).render();
    },

    refreshOrClose() {
      if (this.isLastView) {
        // Don't refresh if last view - close it.
        this.options.mediator.trigger(this.options.mediator.CLOSE_ACTION_REQUIRED);
      } else {
        this.refresh();
      }
    },

    refresh() {
      const dataModel = this.getObjectToSave();
      const orderId = dataModel.get('orderId');
      const orderItems = orderId
        ? this.options.apptModels.filter((model) => model.get('orderId') === orderId)
        : [];

      this.loadmask();
      dataModel.fetch().done(
        function () {
          if (dataModel instanceof App.Collections.AppointmentGroup) {
            // It's a Package. Need to renew the main model's data.
            const tmpModel = this.collection.formatAsModelWithData(this.model.get('offerId'), {
              id: this.model.get('skus')[0].skuId,
              name: this.model.get('skus')[0].skuName,
            });

            this.model.attributes = tmpModel.attributes;
          }

          this.render();

          this.options.mediator.trigger(this.options.mediator.VIEW_REFRESHED);
          orderItems.forEach((appointmentModel) => {
            if (!appointmentModel.isReadOnly()) {
              appointmentModel.trigger('deleted');
            }
          });
        }.bind(this),
      );
    },

    isMissingSkuSelectedType(list, value) {
      if (!list.length || !value) {
        return false;
      }
      const item = find(list, { value });
      return item && item.type === SelectOptionType.WARNING;
    },

    toggleMissingSkuWarning() {
      const $missingSkuMessage = this.$(this.missingSkuAttentionElement);
      if (!$missingSkuMessage.html().length) {
        return;
      }
      if (this.isMissingSkuSelected) {
        $missingSkuMessage.show();
      } else {
        $missingSkuMessage.hide();
      }
    },

    toggleCategoryMissmatchWarning() {
      const offer = this.getSelectedOffer();
      const $message = this.$('.js-attention');

      if (!offer) {
        $message.hide();
        return;
      }

      const { skus } = this.getOfferAndSkuIds();

      if (!skus.length) {
        $message.hide();
        return;
      }

      /*
       *  Get first sku (destructure first item to variable "first")
       */
      const [first] = skus;
      const sku = offer.getSku(first.skuId);
      // Missing a SKU means that the service prices have changed, and we don't have
      // a lot of info on what this is. So we're not able to perform this check.
      if (!sku) {
        return;
      }
      const openSelectionOfferIds = offer
        .getOpenSelectionSubServices(this.options.menu.get('offersCollection'))
        // eslint-disable-next-line radix
        .map((data) => parseInt(data.serviceId));

      const findSkuForOffer = (offerId) =>
        sku.get('subSkus').find(({ offerId: OFFERID }) => offerId === OFFERID);
      const isServicePackage = offer.isServicePackage();

      const missmatchedSubskusCount = this.appointmentViews.reduce((count, view) => {
        const apptOffer = view.getOffer();

        if (isServicePackage && !openSelectionOfferIds.includes(apptOffer.id)) {
          return count;
        }
        // For Package Services, only check the Master Serivice
        if (isServicePackage && offer.getMasterService().serviceId !== apptOffer.id) {
          return count;
        }

        const employee = view.getEmployee();

        const offerSku = sku.get('subSkus') ? findSkuForOffer(apptOffer.id) : sku.toJSON();

        if (
          offerSku &&
          offerSku.employeeCategoryId &&
          employee &&
          employee.get('employeeCategoryId') !== offerSku.employeeCategoryId
        ) {
          return count + 1;
        }
        return count;
      }, 0);

      if (missmatchedSubskusCount > 0) {
        const skuEmployeeCat = sku.get('subSkus')
          ? sku.get('subSkus').find((sSku) => sSku.employeeCategoryId)
          : sku.toJSON();

        if (!skuEmployeeCat) {
          $message.hide();
          return;
        }

        const catName = this.options.employeeCategories
          .get(skuEmployeeCat.employeeCategoryId)
          .get('name');
        const attentionMessageText = Wahanda.lang.calendar.appointments.employeeCatAlert;

        App.ES6.Initializers.Notice({
          node: $message[0],
          message: attentionMessageText.replace('{pricingLevel}', catName.trim()),
        });
        $message.show();
      } else {
        $message.hide();
      }
    },

    renderAppointmentRows($el, options = {}) {
      const self = this;
      const dataToKeep = options.dataToKeep;

      const $appointmentContainer = ($el || this.$el).find('.js-appointment-data-rows').empty();

      // If we had some appt views already rendered, destroy them.
      _.each(
        this.appointmentViews,
        function (apptView) {
          this.stopListening(apptView);
          apptView.remove();
        },
        this,
      );

      // Here we should render all item parts
      // If we're rendering a Package Appointment, this will return more than one item.
      const appointments = this.getAppointmentsForRendering(options.useModelData);

      // Is single appointment with cleanup
      if (appointments.length === 1 && this.model.getCleanupTimeLength() > 0) {
        this.renderCleanupTime();
      }

      const appointmentViews = [];
      const offersCollection = this.options.menu.get('offersCollection');
      const menuTreatments = this.options.menuTreatments;
      const employees = this.options.employees;
      const initialDuration = this.options.initialDuration;
      const toggleDialogClosing = this.options.toggleDialogClosing;
      _.each(appointments, function (appointmentData, index) {
        // Enrich with additional View requirements
        _.extend(appointmentData, {
          offersCollection,
          menuTreatments,
          employees,
          initialDuration,
          toggleDialogClosing,
        });
        const modelData = getDataToKeep(appointmentData);
        if (modelData) {
          appointmentData.model.set(modelData);
        }

        const apptView = new AppointmentItem.Data(appointmentData);
        const isLastItem = index + 1 === appointments.length;

        apptView.render();
        $appointmentContainer.append(apptView.$el);
        appointmentViews.push(apptView);

        const hasCleanupTime = !!apptView.cleanupTime;
        if (hasCleanupTime && !isLastItem) {
          self.renderCleanupTimeInPackage(apptView.$el, apptView.cleanupTime);
        } else if (hasCleanupTime && isLastItem) {
          self.renderCleanupTime($el, apptView.cleanupTime);
        }
        self.listenTo(apptView, 'rescheduled', self.onReschedule);
        self.listenTo(apptView, 'auto-rescheduled', self.onAutoReschedule);
        self.listenTo(apptView, 'employee-change', self.onEmployeeChange);
        self.listenTo(apptView, 'selectdropdown-change', self.onAnyChange);
      });
      this.appointmentViews = appointmentViews;

      function getDataToKeep(apptData) {
        const match = _.find(dataToKeep, function (item) {
          return _.all(item.match, function (val, key) {
            return apptData.model.get(key) === val;
          });
        });
        return match ? match.data : null;
      }
    },

    /**
     * Update the Appointment rows as the skus have changed.
     */
    updateAppointmentRows() {
      // Recreate the Views keeping the chosen EmployeeId
      const dataToKeep = _.map(this.appointmentViews, (view) => {
        const employee = view.getEmployee();
        const employeeId = employee ? employee.id : null;

        return {
          match: {
            offerId: view.model.get('offerId'),
          },
          data: {
            employeeId,
          },
        };
      });
      this.renderAppointmentRows(null, { dataToKeep });
      this.toggleCategoryMissmatchWarning();
    },

    getAppointmentsForRendering(useModelData) {
      // var self = this;
      const chosenOffer = this.getSelectedOffer();
      const offersCollection = this.options.menu.get('offersCollection');
      const reschedulingEnabled = this.model.canBeRescheduled();
      let appts;

      let data = [];
      const keysToChange = ['skus', 'offerId'];
      if (!useModelData) {
        keysToChange.push(
          'startTime',
          'endTime',
          'cleanupEndTime',
          'processingEndTime',
          'finishingEndTime',
        );
      }
      let isFirst = true;
      if (this.collection) {
        // Render the Package Group
        appts = this.model.getStructureForPackageGroup(offersCollection);
        data = this.collection.map(function (appointment, index) {
          if (!useModelData) {
            // Not using Model data - means that the rendering is done because of e.g. Offer or SKU change.
            let apptData = appts[index];
            // Keep the itemId
            apptData.skus[0].itemId = appointment.get('skus')[0].itemId;
            // Also keep other important data
            apptData = _.pick(apptData, function (value, keyName) {
              return _.indexOf(keysToChange, keyName) !== -1;
            });
            appointment.set(apptData);
          }
          return getRowStructure(appointment);
        });
      } else if (chosenOffer && chosenOffer.isServicePackage()) {
        // For Package mode, create a new Appt model for
        appts = this.model.getStructureForPackageGroup(offersCollection);
        data = _.map(appts, function (apptData) {
          return getRowStructure(new App.Models.Appointment(apptData));
        });
      } else {
        // For single appointment model, use the main model for rendering.
        data.push({
          model: this.model,
          textOnlyDate: !reschedulingEnabled,
        });
      }

      function getRowTitle(offerName, skus) {
        return Wahanda.Template.renderTemplate('appointment2-form-package-item-title-template', {
          title: offerName,
          singleSku: skus.length === 1 ? skus[0].skuName : null,
          skus:
            skus.length > 1
              ? skus.map(function (sku) {
                  return sku.skuName;
                })
              : null,
        });
      }

      function getRowStructure(model) {
        const offer = offersCollection.get(model.get('offerId'));
        const ret = {
          model,
          textOnlyDate: !reschedulingEnabled || !isFirst,
          title: getRowTitle(offer.get('name'), model.get('skus')),
        };
        isFirst = false;
        return ret;
      }

      return data;
    },

    /**
     * Refatch the model and render everything.
     */
    update() {
      const self = this;
      this.loadmask();
      this.model.fetch().done(function () {
        self.requestGapCheck();
        self.enableAllEvents();
        self.render();
        self.unloadmask();
      });
    },

    enableAllEvents() {
      this.events = _.extend({}, this.commonEvents, this.modelUpdateEvents);
      // NOTE: the events aren't re-delegated. render()->setElement() should handle it.
    },

    preventEditing() {
      this.$('input, select').disableFormElements();
    },

    customDisableForm() {
      const data = { disabled: true };

      this.renderServicesDropdown(data, true);
      this.renderSKUDropdown(data, true);
    },

    customEnableForm() {
      const data = { disabled: false };

      this.renderServicesDropdown(data, true);
      this.renderSKUDropdown(data, true);
    },

    setupValidation() {
      const self = this;
      const validations = Wahanda.Validate.getValidations('defaults', {
        submitHandler() {
          if (self.customValidationsPass()) {
            self.save();
          }
        },
      });

      this.$('form').validate(validations);
    },

    customValidationsPass() {
      return this.checkOfferChosen() && this.checkMultiSku() && this.allViewsValidationPasses();
    },

    checkOfferChosen() {
      const hasService = !!this.setOfferId;

      if (!hasService && !this.model.id) {
        if (this.showValidationErrors) {
          this.$('.js-offerId').formCustomElementErrorTip(Wahanda.lang.validate.defaults.required);
        }
        return false;
      }
      return true;
    },

    checkMultiSku() {
      if (!this.hasServiceMultipleSkus()) {
        return true;
      }
      // Check if at least one sku is selected
      const hasSkus = this.newSkus.length > 0;
      if (!hasSkus && this.showValidationErrors) {
        this.$('.chosen-container').formErrorTip(
          Wahanda.lang.calendar.appointmentForm.errors.oneSkuMinimum,
          {
            show: { ready: true, event: false },
            hide: { event: 'click' },
          },
        );
      }
      return hasSkus;
    },

    allViewsValidationPasses() {
      return _.all(_.invoke(this.appointmentViews, 'customValidationsPass'));
    },

    renderSkus(useModelValue = false) {
      const offer = this.getSelectedOffer();
      const appointmentSkus = this.model.get('skus');
      const $node = this.$('.js-skuId');

      this.chosenDropShown = false;

      // Try to destroy previous chosen instance
      const $prevSelect = $node.find('select');
      if ($prevSelect.length > 0 && $prevSelect.data('chosen')) {
        $prevSelect.data('chosen').destroy();
        $prevSelect.off('.appt-item');
      }

      // Get the sku list
      const isMultiSku = offer && offer.get('multiSkuSelection');
      const asMultiSku = isMultiSku && offer.get('skus').length > 1;
      let optionsList = [];
      let initialOptionsList = [];
      let hasMissingSku = false;
      let restoredItem = {};

      if (offer) {
        optionsList = offer.getSkuList(
          {
            requiredSkus: _.pluck(appointmentSkus, 'skuId'),
          },
          this.options.menu.get('offersCollection'),
        );
        initialOptionsList = optionsList;
      }

      // Check if there is a missing sku in the model
      const $missingSkuMessage = this.$(this.missingSkuAttentionElement);
      $missingSkuMessage.hide();

      if (offer && this.model.id && this.model.previous('offerId') === offer.id) {
        if (appointmentSkus && appointmentSkus.length && !offer.getSku(appointmentSkus[0].skuId)) {
          // The option isn't found, probably an archived sku. Add it.
          const missingSku = appointmentSkus[0];
          restoredItem = {
            name: missingSku.skuName,
            value: missingSku.skuId,
            type: SelectOptionType.WARNING,
          };
          hasMissingSku = true;

          App.ES6.Initializers.Notice({
            node: $missingSkuMessage[0],
            message: Wahanda.lang.calendar.appointments.edit.warning.pricingOptionUnavailable,
            dataTest: 'udv-missing-sku-warning',
          }).render();

          if (offer.isServicePackage()) {
            // If we are missing a SKU in an Package Group, don't allow to change SKUs as that might break
            // the UI and cause other problems.
            optionsList = [restoredItem];
          } else {
            optionsList.push(restoredItem);
          }

          this.modelOptionsList = optionsList;
        }
      }

      const availableSkuIds = _.pluck(optionsList, 'value');
      const skuByEmployee = useModelValue ? false : this.isSelectedOfferEmployeeCategory();
      let selectedSkus = this.getSelectedSkus(availableSkuIds, skuByEmployee);
      this.isMissingSkuSelected = this.isMissingSkuSelectedType(
        initialOptionsList,
        selectedSkus[0],
      );
      this.$('.js-attention').hide();
      let selectedEmployeeCategory = {};

      if (this.isSelectedOfferEmployeeCategory()) {
        const employeeCategories = offer.getSkuList(
          {
            requiredSkus: _.pluck(appointmentSkus, 'skuId'),
          },
          this.options.menu.get('offersCollection'),
          true,
        );

        $node.removeClass('is-react').hide();

        if (!selectedSkus.length) {
          selectedSkus.push(availableSkuIds[0]);
          selectedEmployeeCategory = employeeCategories[0];
        }
        this.newSkus = selectedSkus;

        // If we are dealing with a non-2D offer we do not want to render the sku dropdown
        if (!(offer.is2Dpricing() || offer.is2dPricedServicePackage())) {
          return;
        }
      }

      // If no match, try keeping previously selected SKUs.
      if (!selectedSkus.length) {
        selectedSkus = this.getAvailableSkus(availableSkuIds);
      }

      if (selectedSkus.length === 0 && !isMultiSku && availableSkuIds.length > 0) {
        selectedSkus.push(availableSkuIds[0]);
      }

      this.newSkus = selectedSkus;

      if (optionsList.length < 2) {
        // Nothing to choose. Don't render stuff.
        $node.removeClass('is-react').hide();
        return;
      }

      const isDisabled = this.model.id > 0 && (this.inGroupView || this.model.isReadOnly());

      $node.toggleClass('is-react', !asMultiSku);

      if (asMultiSku) {
        App.ES6.ReactDOM.unmountComponentAtNode($node.get(0));

        let html = Wahanda.Template.render(
          '<select ' +
            'name="appt-skus" ' +
            'class="js-skus-chosen required" ' +
            'multiple ' +
            'data-placeholder="{{placeholderText}}"' +
            '{{#disabled}} disabled="disabled"{{/disabled}}' +
            '>' +
            '{{#options}}<option value="{{value}}"{{#on}} selected{{/on}}>{{name}}</option>{{/options}}' +
            '</select>',
          {
            disabled: isDisabled,
            options: _.map(optionsList, (item) => ({
              ...item,
              on: _.indexOf(selectedSkus, item.value) !== -1,
            })),
            placeholderText: Wahanda.lang.shared.selectInitialOption,
          },
        );

        html += Wahanda.Template.renderTemplate(
          'appointment-form-template-multisku-limit-notification',
        );

        $node.html(html);

        if (!Wahanda.Util.isTouchDevice()) {
          // Set up chosen, but only for non-touch devices as it does not handle them well.
          const $select = $node.find('select');

          $select.chosen({
            // eslint-disable-next-line camelcase
            max_selected_options: MAX_SKU_CHOICES,
            width: '100%',
          });

          const getChosenSizeParams = () => {
            const scrollRect = $node.closest('.content-scroll').get(0).getBoundingClientRect();
            const ownRect = $node.get(0).getBoundingClientRect();

            return {
              maxDropdownHeight: scrollRect.bottom - ownRect.bottom - 10,
            };
          };

          $select.on('chosen:showing_dropdown.appt-item', function () {
            $node.setChosenSize(getChosenSizeParams());
          });
        }
      } else {
        if ($node.find('select').length) {
          $node.empty();
        }

        const is2dSku = offer.is2Dpricing() || offer.is2dPricedServicePackage();
        let skuOptions = [];

        if (is2dSku) {
          skuOptions = this.get2DSkus(
            // Convert options structure back to SKU-like structure
            (hasMissingSku ? initialOptionsList : optionsList).map((opt) => {
              return { id: opt.value };
            }),
            selectedEmployeeCategory.employeeCategoryId,
          );

          if (hasMissingSku) {
            // Push the missing 2d sku object so that it could be preselected in UDV
            skuOptions.push(restoredItem);
          }
        } else {
          skuOptions = optionsList;
        }

        this.renderSKUDropdown(
          {
            data: skuOptions,
            selected: selectedSkus[0],
            onSelect: (...args) => {
              // Show missing sku warning if it was selected
              if (hasMissingSku && args[0]) {
                this.isMissingSkuSelected = this.isMissingSkuSelectedType(skuOptions, args[0]);
              }
              this.onSkuSelected(...args);
              this.onAnyChange();
            },
            disabled: isDisabled,
            onStateChange: this.options.toggleDialogClosing,
          },
          false,
        );
      }

      $node.show();
    },

    getAvailableSkus(availableSkuIds) {
      const availableSkus = [];
      return availableSkus.concat(
        _.filter(this.newSkus, function (skuId) {
          return _.indexOf(availableSkuIds, skuId) !== -1;
        }),
      );
    },

    renderSKUDropdown(data, useCache) {
      const dataTest = 'udv-sku-select';
      this.renderReactDropdown(
        this.$('.js-skuId'),
        'cachedSKUDropdownData',
        data,
        useCache,
        dataTest,
      );
    },

    getSelectedSkus(possibleSkuIds, forceChoiceByEmployee) {
      const skus = this.model.previous('skus');

      if (!skus || skus.length === 0 || forceChoiceByEmployee) {
        return this.getSelectedSkusByEmployee();
      }
      return this.getSelectedSkusFromModel(possibleSkuIds, skus);
    },

    isSelectedOfferEmployeeCategory() {
      const offer = this.getSelectedOffer();
      if (offer) {
        return (
          offer.is2dPricedServicePackage() ||
          offer.isEmployeeCategoryPricing() ||
          offer.is2Dpricing()
        );
      }
      return false;
    },

    doesSkuMatchEmployeeCategory(skuData, catId) {
      const categoryFilter = (skuItem) => skuItem.employeeCategoryId === catId;

      return skuData.subSkus ? skuData.subSkus.some(categoryFilter) : categoryFilter(skuData);
    },

    getSelectedSkusByEmployee() {
      const currentData = this.getOfferAndSkuIds();
      const catId = this.getChosenEmployeeCategory();

      // Try reusing the current SKU, if it matches the category id.
      if (currentData.skus.length > 0) {
        const offer = this.getSelectedOffer();
        const [{ skuId: firstSkuId }] = currentData.skus;
        const firstSku = offer.getSku(firstSkuId);

        if (firstSku && this.doesSkuMatchEmployeeCategory(firstSku.toJSON(), catId)) {
          return [firstSkuId];
        }
      }

      const categorySku = this.getEmployeeSkus(_.find, catId);
      if (categorySku.length) {
        return [categorySku[0].id];
      }

      return [];
    },

    getEmployeeSkus(filterFunc, categoryId = null, skusToUse = null) {
      const catId = categoryId || this.getChosenEmployeeCategory();
      const offer = this.getSelectedOffer();

      if (!catId || !offer) {
        return [];
      }

      const offerSkus = offer.get('skus');
      const skus =
        skusToUse && skusToUse.length > 0
          ? skusToUse.map((sku) => offerSkus.find((os) => os.id === sku.id)).filter((sku) => !!sku)
          : offerSkus;

      const resultSkus = filterFunc(skus, (sku) => this.doesSkuMatchEmployeeCategory(sku, catId));

      if (!resultSkus) {
        return [];
      }

      return Array.isArray(resultSkus) ? resultSkus : [resultSkus];
    },

    get2DSkus(validSkus, employeeCatId) {
      // Get skus matching employee category
      let categorySkus = this.getEmployeeSkus(_.filter, employeeCatId, validSkus);
      const offer = this.getSelectedOffer();

      if (!categorySkus.length) {
        const currentChosenEmployeeCat = this.getSkuEmployeeCategory();
        categorySkus = this.getEmployeeSkus(_.filter, currentChosenEmployeeCat, validSkus);
      }
      if (categorySkus) {
        const formattedSkus = categorySkus.map(function (sku) {
          let name;
          if (offer.is2dPricedServicePackage()) {
            name = sku.subSkus
              .filter((subSku) => !subSku.nameInherited)
              .map((subSku) => subSku.name)
              .join(' + ');
          } else {
            name = sku.name;
          }
          return {
            name,
            value: sku.id,
            employeeCategoryId: sku.employeeCategoryId,
            duration: sku.duration,
          };
        });
        return formattedSkus;
      }
      return [];
    },

    getSelectedSkusFromModel(possibleSkuIds, skus) {
      const list = [];

      _.each(
        skus,
        function (sku) {
          if (_.indexOf(possibleSkuIds, sku.skuId) !== -1) {
            list.push(sku.skuId);
          }
        },
        this,
      );

      if (list.length === 0) {
        // The chosen service isn't one the model had set on it.
        // Render the SKU based on employee category.
        return this.getSelectedSkusByEmployee();
      }

      return list;
    },

    /**
     * Find an employee, based on the currently selected Offer type.
     *
     * If the offer is Service Package, return the Master Service's Employee.
     * If offer is of another kind, return the first Appointment's employee.
     *
     * @returns {App.Models.Employee} The employee.
     */
    getChosenEmployee() {
      const offer = this.getSelectedOffer();

      if (offer && offer.isServicePackage()) {
        const { serviceId: masterOfferId } = offer.getMasterService();
        const view = this.appointmentViews.find((v) => v.getOffer().id === masterOfferId);

        if (view) {
          return view.getEmployee();
        }
      }
      return this.appointmentViews[0].getEmployee();
    },

    getChosenEmployeeCategory() {
      const employee = this.getChosenEmployee();
      return employee ? employee.get('employeeCategoryId') : null;
    },

    getSelectedService() {
      const dropdownNode = this.$('.js-offerId').find('.select-dropdown').get(0);

      if (dropdownNode) {
        return dropdownNode.getAttribute('data-value');
      }
      return null;
    },

    getSelectedOffer() {
      const wasOfferIdSet = this.setOfferId !== undefined;
      const strOfferId = wasOfferIdSet ? this.setOfferId : this.getSelectedService();
      let offerId;

      if (strOfferId || wasOfferIdSet) {
        offerId = parseInt(strOfferId, 10);
      } else {
        offerId = this.model.get('offerId');
      }

      return this.options.menu.get('offersCollection').get(offerId);
    },

    getSkuEmployeeCategory() {
      const offer = this.getSelectedOffer();
      const currentSkuId = (() => {
        const modelSkuId = this.model.get('skuId');
        if (modelSkuId) {
          return modelSkuId;
        }

        const allSkus = offer.get('skus');
        if (allSkus && allSkus.length > 0) {
          return allSkus[0].id;
        }
        return null;
      })();
      let employeeCatId = null;
      const isSearchedSku = (sku) => sku.id === currentSkuId;
      const getEmployeeCategory = (sku) => {
        // Return the employee category of the SKU
        if (sku.employeeCategoryId) {
          return sku.employeeCategoryId;
        }
        // If not found, return the first Empl. Category of the subSkus
        if (sku.subSkus) {
          return sku.subSkus.reduce((foundId, subSku) => {
            return foundId || subSku.employeeCategoryId;
          }, null);
        }
        return null;
      };

      offer.get('skus').every((sku) => {
        if (isSearchedSku(sku) || (sku.subSkus || []).some(isSearchedSku)) {
          employeeCatId = getEmployeeCategory(sku);
        }

        // Break the loop if we found what we needed
        return !employeeCatId;
      });

      return employeeCatId;
    },

    getOfferAndSkuIds() {
      let skus;
      const offer = this.getSelectedOffer();
      const values = {
        offerId: offer && offer.id,
        skus: [],
      };

      if (!offer) {
        return values;
      }

      if (offer.get('skus').length === 1) {
        skus = [offer.get('skus')[0].id];
      }

      // override skuId from separate sku selector if available
      if (this.hasServiceMultipleSkus()) {
        skus = [];
        this.newSkus.forEach(function (skuId) {
          if (skuId) {
            skus.push(skuId);
          }
        });
      }

      if (skus) {
        _.each(skus, function (skuId) {
          if (skuId) {
            values.skus.push({
              skuId: parseInt(skuId, 10),
            });
          }
        });
      }

      return values;
    },

    hasServiceMultipleSkus() {
      const offer = this.getSelectedOffer();
      if (!offer) {
        return false;
      }

      return offer.get('skus').length > 1;
    },

    getTemplateVars() {
      const isStandalone = !this.inGroupView;
      const offer = this.getSelectedOffer(); // might be null
      const data = {
        id: this.model.id,
        isNewModel: !this.model.id,
        uniq: this.cid,
        showPaymentStatus: isStandalone && this.model.id,
        showPaymentProtectionBadge: this.model.isPaymentProtectionApplied(),
        checkedOut: this.model.isCheckedOut(),
        standalone: isStandalone,
        apptGroupLock: this.model.id > 0 && !isStandalone,
        isApptPackage: this.model.id > 0 && offer && offer.isServicePackage(),
        isRecurring: this.model.isRecurring(),
      };

      data.showStatusContainer = data.standalone || data.isApptPackage;

      const paymentStates = this.model.getPaymentStates();
      const notes = this.model.get('notes') || (this.collection && this.collection.data.notes);

      $.extend(
        data,
        {
          showNotes: notes && this.model.canUserEditAppointment(),
          notes,
          showAddNotes: !notes && this.model.canUserEditAppointment(),
          status: {
            className: this.model.getStatusClass(),
            name: this.model.getStatusText(),
          },
          bookingActor: this.model.id > 0 && isStandalone ? this.model.getBookingActorData() : null,
          actions: this.getActionsValidity(),
          renderUnsavedDelete: !this.model.id,
          price: Wahanda.Currency.getFormatted(this.model.get('amount')),
        },
        paymentStates,
      );

      // Add checkout data
      if (this.model.isCheckedOut()) {
        data.checkout = {
          servicePrice: this.model.getAmountText(),
          additionalAmount: this.model.getAdditionalAmountText() || null,
          totalAmount: this.model.getCheckoutTotalText(),
        };
      }

      return data;
    },

    /** @Override */
    $field(name) {
      return this.$(`[name="${this.formFieldPrefix}${name}"]`);
    },

    getAppointmentDate(asString) {
      let date = this.$('.js-date').datepicker('getDate');
      if (asString) {
        date = Wahanda.Date.toApiString(date);
      }
      return date;
    },

    isDateChanged() {
      let startDate = this.model.previous('appointmentDate');
      if (!startDate) {
        startDate = this.model.get('appointmentDate');
      }
      return startDate !== this.getAppointmentDate(true);
    },

    toggleMultiSkuMaxItemsNotice() {
      let show = false;
      if (this.canShowMaxSkuNotification()) {
        const count = this.newSkus.length;
        show = count >= MAX_SKU_CHOICES;
      }
      this.$('.appointment-skus-limit-notice').wToggle(show);
      this.$('.chosen-container').toggleClass('item-limit-reached', show);
    },

    canShowMaxSkuNotification() {
      return true;
    },

    checkMaxSkuLimit() {
      const $select = this.$('.js-skuId').find('select');
      const count = $select.find('option:checked').length;
      if (count >= MAX_SKU_CHOICES) {
        $select.find('option').not(':selected').addClass('temp-disabled').prop('disabled', true);
        $select.blur();
        return;
      }

      const $options = $select.find('option.temp-disabled');

      if ($options.length > 0) {
        $options.removeClass('temp-disabled').prop('disabled', false).blur();
        $select.blur();
      }
    },

    shouldNotifyCustomerOfReschedule() {
      if (this.model.isMarketplaceBooking()) {
        return true;
      }
      if (!App.config.get('venue').consumerEmailNotifications) {
        return false;
      }

      return _.any(this.appointmentViews, function (view) {
        const currentDate = view.getAppointmentDate();
        const currentTimes = view.getAppointmentTimes(false);
        return view.model
          .getPreviousModel()
          .shouldShowClientReschedulingNotification(currentDate, currentTimes);
      });
    },

    /**
     * Get the Appointment model values for saving.
     *
     * @return Object
     */
    getValues() {
      if (this.appointmentViews.length > 1) {
        throw new Error('Package saving not yet implemented');
      } else {
        return this.getSingleAppointmentValues();
      }
    },

    getSingleAppointmentValues(appointmentValues) {
      const dataView = this.appointmentViews[0];
      let values = appointmentValues;

      if (dataView.model.isReadOnly()) {
        // Prevent saving anything else except from notes
        const notes = appointmentValues.notes;
        values = {
          notes: notes && $.trim(notes) !== '' ? notes : null,
        };
      } else {
        _.extend(values, this.getOfferAndSkuIds(), dataView.getValues(), {
          notifyConsumer: this.shouldNotifyCustomerOfReschedule(),
        });

        // When redeeming an eVoucher, add it's reference to model values
        if (this.options.redeemEvoucher) {
          values.evoucherReference = this.options.evoucherReference;
        }
      }

      if (!this.model.id) {
        values.bookingActor = this.model.CHANNEL_LOCAL;
        values.platform = Wahanda.platform;
      }

      return values;
    },

    getPackageValues(values) {
      const offer = this.getSelectedOffer();
      return {
        type: App.Collections.AppointmentGroup.TYPE_PACKAGE,
        name: offer.get('name'),
        offerId: offer.id,
        notes: values.notes,
        recurrence: values.recurrence,
        optionId: this.newSkus && this.newSkus[0],
        appointments: _.map(this.appointmentViews, function (view) {
          const appt = view.model;
          appt.set(view.getValues());
          if (values.recurrence) {
            appt.set('recurrence', values.recurrence);
          }
          return appt;
        }),
      };
    },

    setVenueCustomerId(id) {
      _.each(this.appointmentViews, function (view) {
        view.model.set('venueCustomerId', id);
      });
    },

    getObjectToSave(doNotClose, repeatModel) {
      const values = {
        notes: this.$('textarea[name=appt-notes]').val(),
        recurrence: repeatModel && repeatModel.recurrence,
      };

      if (this.getSelectedOffer().isServicePackage()) {
        // This is a Package create/edit
        // This also sets the values on the appointments
        const data = this.getPackageValues(values);
        let group = this.collection;
        if (!group) {
          group = new App.Collections.AppointmentGroup(
            data.appointments,
            _.omit(data, 'appointments'),
          );

          const self = this;
          const md = this.options.mediator;
          if (!doNotClose) {
            this.listenTo(group, 'saved', function () {
              md.trigger(md.APPOINTMENT_GROUP_SAVED, self);
            });
          }
        } else {
          _.extend(group.data, _.omit(data, 'appointments'));
        }

        // Definitely a clumsy part. Probably backend should handle all notification logic.
        group.data.notifyConsumer = this.shouldNotifyCustomerOfReschedule();

        return group;
      }
      // This is a single appointment edit
      this.model.set(this.getSingleAppointmentValues(values));
      return this.model;
    },

    getSaveErrorText(xhr, defaultError) {
      let text;
      const errors = xhr ? Wahanda.Util.parseErrors(xhr) : null;

      if (errors && errors.length > 0) {
        const error = errors[0];
        const errorKey = Wahanda.Text.toCamelCase(error.name);
        text = Wahanda.lang.calendar.appointments.errors[errorKey];
      }

      return text || defaultError;
    },

    onSaveError() {
      // We will handling errors ourselves
      return false;
    },

    save(callback) {
      const self = this;
      let saveStep = null;
      let errorXhr;
      let doNotClose;
      // If there is a callback, we do not want to close the dialog after save
      if (callback) {
        doNotClose = true;
      }

      _.invoke(this.appointmentViews, 'onSaveClick');

      const toSave = this.getObjectToSave(doNotClose);

      this.disableForm();

      // This calls each callback, one-by-one, waiting for it to resolve
      Wahanda.Util.callbackChain([confirmUnconfirmed, saveAppointment]).fail(function () {
        self.showError(
          self.getSaveErrorText(
            errorXhr,
            Wahanda.lang.calendar.appointments.errors[
              saveStep === 'confirm' ? 'couldNotConfirm' : 'couldNotSave'
            ],
          ),
        );
        self.enableForm();
      });

      // This is the logic that is performed, one by one

      function confirmUnconfirmed(resolver) {
        saveStep = 'confirm';
        if (toSave.isUnconfirmed()) {
          // Stand-alone unconfirmed appointment.
          self.disableForm();
          // Confirm before saving
          toSave
            .confirm()
            .done(function () {
              resolver(true);
            })
            .fail(function (xhr) {
              errorXhr = xhr;
              resolver(false);
            });
        } else {
          resolver(true);
        }
      }

      function saveAppointment(resolver) {
        saveStep = 'save-appt';

        self.modelToSave = toSave;

        const alwaysFn = () => {
          self.modelToSave = null;
        };
        const doneFn = (data) => {
          if (callback) {
            callback(data);
          }
          resolver(true);
        };

        const skipSaving = toSave.isUnconfirmed() && !self.isChanged;

        if (skipSaving) {
          // If the form isn't changed, don't save it.
          window.setTimeout(() => {
            alwaysFn();
            self.trigger('saved');
            doneFn(self.model.toJSON());
          }, 0);
          return;
        }

        self
          .saveAction()
          .always(alwaysFn)
          .done(doneFn)
          .fail((xhr) => {
            errorXhr = xhr;
            resolver(false);
          });
      }
    },

    /**
     * Request a reschedule of all models w/o gaps after this model.
     *
     * This might be called from the AppointmentGroup view if the model is a part of a Package.
     */
    requestSubsequentReschedule() {
      const lastView = this.appointmentViews[this.appointmentViews.length - 1];
      const lastModel = lastView.model;
      const endTime = lastView.getAppointmentTimes(true).endTime;
      const diff = endTime - lastView.originalEndTime;

      this.options.mediator.trigger(this.options.mediator.SUBSEQUENT_RESCHEDULE_REQUIRED, {
        timeDiff: diff,
        changedModel: lastModel,
        date: lastModel.get('appointmentDate'),
      });
    },

    requestGapCheck() {
      this.options.mediator.trigger(this.options.mediator.POSSIBLE_RESCHEDULE, this.model);
    },

    isValid() {
      return this.$('form').length && this.$('form').valid() && this.customValidationsPass();
    },

    isValidReturnOnly() {
      this.showValidationErrors = false;

      const result = this.isValid();

      this.showValidationErrors = true;

      return result;
    },

    getEmployeeId() {
      const id = this.$('.js-employeeId').val();
      return id > 0 ? parseInt(id, 10) : null;
    },

    getLastModel() {
      return this.model;
    },

    /**
     * Return one or another parameter, based if a Package or an Appointment is rendered.
     *
     * @param mixed forAppt
     * @param mixed forPackage
     * @returns mixed
     */
    chooseBasedOnType(forAppt, forPackage) {
      return this.collection ? forPackage : forAppt;
    },

    getActionsValidity() {
      return (this.collection || this.model).getActionsValidity();
    },

    recalculateAvailability() {
      _.invoke(this.appointmentViews, 'recalculateAvailability');
    },

    onSaveClick() {
      _.invoke(this.appointmentViews, 'onSaveClick');
    },

    onDismiss() {
      _.invoke(this.appointmentViews, 'onDismiss');
    },

    remove() {
      _.invoke(this.appointmentViews, 'remove');

      // Remove React nodes
      this.$('.is-react, .js-payment-protection-badge').each(function () {
        App.ES6.ReactDOM.unmountComponentAtNode(this);
      });

      BaseView.prototype.remove.call(this);
    },

    focusForm() {
      this.$(':tabbable').not(':disabled').not('textarea').first().focus();
    },

    // Events
    onOfferChange(value) {
      this.setOfferId = value ? parseInt(value, 10) : null;

      const newOffer = this.getSelectedOffer();
      const isServicePackage = newOffer && newOffer.isServicePackage();

      this.model.set('offerId', newOffer ? newOffer.id : null);

      this.renderSkus();

      // Re-set the offerId and SKUs here.
      // It's important to update the SKU as the Appointment.Data view depends on correct
      // Offer's SKU to be set for setting various related data (like duration).
      this.model.set(this.getOfferAndSkuIds());

      if (isServicePackage || this.wasServicePackage) {
        // This is a switch from or to a Package multi-rows view. Render all.
        this.renderAppointmentRows();
        this.recalculateAvailability();
      } else {
        if (newOffer) {
          this.renderCleanupTime(null, newOffer.get('cleanupTime') || 0);
        }
        // Simple-to-simple service transition, events are enough.
        this.model.trigger('ui-change:offerId');
      }
      this.wasServicePackage = isServicePackage;
      this.toggleCategoryMissmatchWarning();

      this.options.mediator.trigger(this.options.mediator.DIALOG_POSITION_REFRESH_NEEDED);
    },

    // React SelectDropdown change
    onSkuSelected(value) {
      this.newSkus = [value ? parseInt(value, 10) : value];
      this.model.set('durationSkuId', this.newSkus);
      this.onAfterSkusChange();
    },

    // jQuery chosen change
    onChosenSkusChange() {
      this.newSkus = _.map(this.$('.js-skuId').find('option:selected'), function (option) {
        return parseInt(option.value, 10);
      });
      this.onAfterSkusChange();
    },

    onAfterSkusChange() {
      const self = this;

      this.checkMaxSkuLimit();
      this.toggleMultiSkuMaxItemsNotice();

      // Collect skus, set on model and trigger
      this.model.set(this.getOfferAndSkuIds());

      const offer = this.getSelectedOffer();
      if (offer && offer.isServicePackage()) {
        changePackageModelSkuIds();
        this.updateAppointmentRows();
      } else {
        this.model.trigger('ui-change:skus');
        this.toggleCategoryMissmatchWarning();
      }

      this.onAnyChange();

      function changePackageModelSkuIds() {
        if (!self.collection) {
          // Nothing to change as the model isn't saved
          return;
        }
        const pkgSku = self.getSelectedOffer().getSku(self.model.get('skus')[0].skuId);

        _.each(self.appointmentViews, function (apptView) {
          const subSku = _.find(pkgSku.get('subSkus'), function (sku) {
            return sku.offerId === apptView.model.get('offerId');
          });
          const existingSku = apptView.model.get('skus')[0];
          apptView.model.set({
            skuId: subSku.id,
            skus: [
              {
                skuId: subSku.id,
                name: subSku.name,
                itemId: existingSku.itemId,
              },
            ],
          });
        });
      }
    },

    onAddNote() {
      // Remove the clicked button
      this.$('.js-add-note').remove();
      // Render note HTML
      this.$('.js-notes').html(Wahanda.Template.renderTemplate(this.notesTemplateId));
      this.$('.js-notes').find(':input').focus();
    },

    onMultiSkuChoiceFocus() {
      this.chosenDropShown = true;
      this.toggleMultiSkuMaxItemsNotice();
    },

    onMultiSkuChoiceBlur() {
      this.chosenDropShown = false;
      this.toggleMultiSkuMaxItemsNotice();
    },

    onRescheduleClick(event) {
      this.options.mediator.trigger(
        this.options.mediator.START_RESCHEDULE,
        [this.collection || this.model],
        maybeGetEventPosition(event),
      );
    },

    onRescheduleClickWithinCancellation() {
      const isWithinCancellation = true;
      this.options.mediator.trigger(
        this.options.mediator.START_RESCHEDULE,
        [this.collection || this.model],
        null,
        isWithinCancellation,
      );
    },

    onNoShowClick() {
      if (
        this.model.isMarketplaceBooking() &&
        !App.config.get('venue').marketplaceAppointmentNoShowEnabled
      ) {
        this.openNoShowDialog(null, 'over-phone');
        return;
      }

      const noShowHandler = this.collection ? this.collection : this.model;

      if (!this.shouldCheckPosStatus()) {
        this.openNoShowDialog(noShowHandler);
        return;
      }

      if (!App.isRestrictedMode()) {
        Wahanda.Cache.posStatus().done((status) => {
          if (!Wahanda.POSCheckoutCheck(status, this.openNoShowDialog.bind(this, noShowHandler))) {
            return;
          }
          this.openNoShowDialog(noShowHandler);
        });
      }
    },

    openNoShowDialog(noShowHandler, type) {
      const doNoShow = ({ preventPaymentProtection }) => {
        this.disableForm();
        noShowHandler.setNoShow({
          preventPaymentProtection,
          error: () => {
            this.enableForm();
            this.$('button.delete-action').errorTip(
              Wahanda.lang.calendar.appointments.errors.couldNotSetNoShow,
            );
          },
        });
      };

      NoShowFlowAnalytics.trackNoShowModalView();

      this.startNoShowFlow(doNoShow, type);
    },

    /**
     * Function that starts  cancellation flow of the appointment
     * @param {func} cancellationHandler function that handles the cancellation
     * @param {bool} cancellationAllowed
     * @param {string} action defines what triggered the cancellation (reject/cancel)
     */
    startCancellationFlow(cancellationHandler, cancellationAllowed, action) {
      this.options.showConfirmDeletion({
        id: this.collection ? this.collection.id : this.model.id,
        granularity: this.collection ? 'appointment-group' : 'appointment',
        isFirstTimeCustomer: this.model.isFirstTimeCustomer(),
        bookingActor: this.model.get('bookingActor'),
        isPrepaid: this.model.isPrepaid(),
        isRecurring: this.model.isRecurring(),
        paymentProtected: this.model.get('paymentProtected'),
        isWithinCancellationPeriod: !this.model.hasCancellationPeriodPassed(),
        cancellationAllowed,
        reschedulingAllowed: this.model.canBeRescheduled(),
        onReschedule: function () {
          this.onRescheduleClickWithinCancellation();
        }.bind(this),
        action,
        onDoCancellation: cancellationHandler,
        onClose: this.enableForm.bind(this),
        orderId: this.model.get('orderId'),
      });

      this.disableForm();
    },

    /**
     * Function that starts no-show flow
     * @param {func} onDoNoShow function that handles the no show
     * @param {string} type over-phone etc.
     */
    startNoShowFlow(onDoNoShow, type) {
      this.options.showConfirmNoShow({
        id: this.collection ? this.collection.id : this.model.id,
        granularity: this.collection ? 'appointment-group' : 'appointment',
        paymentProtected: this.model.get('paymentProtected'),
        type,
        onDoNoShow,
        bookingActor: this.model.get('bookingActor'),
        isPrepaid: this.model.isPrepaid(),
        isWithinCancellationPeriod: !this.model.hasCancellationPeriodPassed(),
        reschedulingAllowed: this.model.canBeRescheduled(),
        isFirstTimeCustomer: this.model.isFirstTimeCustomer(),
        isWalkin: this.model.isWalkin(),
        onClose: this.enableForm.bind(this),
      });

      this.disableForm();
    },

    getCancellationPayload(data = {}) {
      const {
        isVenueCancellation,
        isCovidCancellation,
        requestRefund,
        preventPaymentProtection,
        includeFutureRecurrences = null,
      } = data;

      let cancellationReason;
      if (isCovidCancellation) {
        cancellationReason = 'CV'; // Coronavirus precaution
      } else if (typeof isVenueCancellation === 'boolean') {
        cancellationReason = isVenueCancellation
          ? 'UN' // Venue can not do the Appointment
          : 'CC'; // Customer Cancellation
      }

      return {
        notifyConsumer: this.model.shouldNotifyCustomerOfDeletion(),
        cancellationReason,
        requestRefund,
        preventPaymentProtection,
        platform: 'DESKTOP',
        includeFutureRecurrences,
      };
    },

    onDeleteClick() {
      const doDelete = function (opts) {
        let deleteHandler;
        if (this.collection) {
          deleteHandler = this.collection;
        } else {
          deleteHandler = this.model;
        }

        deleteHandler
          .destroy({
            data: this.getCancellationPayload(opts),
          })
          .fail(
            function () {
              this.enableForm();
              this.$('.js-delete').errorTip(
                Wahanda.lang.calendar.appointments.errors.couldNotDelete,
              );
            }.bind(this),
          );
      }.bind(this);

      const cancellationFlowCb = this.startCancellationFlow.bind(
        this,
        doDelete,
        this.getActionsValidity().canDelete,
        'on-cancel',
      );

      if (!this.shouldCheckPosStatus()) {
        cancellationFlowCb();
        return;
      }

      if (!App.isRestrictedMode()) {
        Wahanda.Cache.posStatus().done((status) => {
          if (!Wahanda.POSCheckoutCheck(status, cancellationFlowCb)) {
            return;
          }
          cancellationFlowCb();
        });
      }
    },

    shouldCheckPosStatus() {
      const pricePrepaid = this.model.isPaidByClient();
      const paymentProtected = this.model.get('paymentProtected');
      const shouldCheckPosStatus =
        App.config.get('venue').pointOfSaleEnabled && (pricePrepaid || paymentProtected);

      return shouldCheckPosStatus;
    },

    onRejectClick() {
      const doReject = function (data) {
        const target = this.collection ? this.collection : this.model;

        target
          .reject({
            data: this.getCancellationPayload(data),
          })
          .fail(
            function () {
              this.$('.js-reject').errorTip(
                Wahanda.lang.calendar.appointments.errors.couldNotDelete,
              );
            }.bind(this),
          );
      }.bind(this);

      this.startCancellationFlow(doReject, this.getActionsValidity().canReject, 'on-reject');
    },

    onAfterSave() {
      const mediator = this.options.mediator;

      if (this.updateAfterSave) {
        mediator.trigger(
          this.chooseBasedOnType(mediator.APPOINTMENT_SAVED, mediator.APPOINTMENT_GROUP_SAVED),
          this,
        );
      }
      this.updateAfterSave = true;
    },

    /**
     * Handle form input change.
     * This shows Save/Cancel buttons instead of other actions.
     */
    onAnyChange() {
      // If this is a Package, the footer is hidden most of the time
      if (this.inGroupView) {
        this.$('.appointment--item--footer').wShow();
      }

      this.isChanged = true;
      this.toggleMissingSkuWarning();
      this.options.mediator.trigger(this.options.mediator.FORM_VALUES_CHANGE, this);
    },

    openOrderDialog() {
      const orderId = this.model.get('orderId');
      if (!orderId) {
        return;
      }

      const view = new App.Views.Dialog.Order2({
        model: new App.Models.Order({
          id: orderId,
        }),
        updateUrl: false,
      });
      view.render();
      view.open();
    },

    openOrderDialogFromEvoucher() {
      const eVoucher = this.model.get('evoucher');
      if (!eVoucher) {
        return;
      }

      App.Views.Dialog.Order2.openByBookingId(eVoucher.bookingId);
    },

    /**
     * Return the time this view spans.
     *
     * @param withAdditionalTime
     */
    getAppointmentTimes(withAdditionalTime) {
      let start;
      let end;
      _.each(this.appointmentViews, function (view) {
        const times = view.getAppointmentTimes(withAdditionalTime);
        if (start == null || start > times.startTime) {
          start = times.startTime;
        }
        if (end == null || end < times.endTime) {
          end = times.endTime;
        }
      });
      return {
        startTime: start,
        endTime: end,
      };
    },

    getLastAppointmentView() {
      if (this.appointmentViews && this.appointmentViews.length > 0) {
        return this.appointmentViews[this.appointmentViews.length - 1];
      }
      return this;
    },

    onUnsavedApptViewsChange(data) {
      const apptCount = data.total;
      const showUnsavedDelete = apptCount > 1;
      this.$('.js-remove-unsaved').wToggle(showUnsavedDelete);
    },

    onRemoveClick() {
      this.model.destroy({
        data: this.model,
      });
    },

    setCustomerData(customerData) {
      this.model.set(customerData);

      _.each(this.appointmentViews, function (view) {
        view.model.set(customerData);
      });

      this.onAnyChange();
    },

    // External customer events
    onCustomerFound(customer) {
      this.setCustomerData(customer.toAppointmentStructure());
    },

    onWalkinSet(data) {
      let anonymousNote = null;
      if (data && data.anonymousNote) {
        anonymousNote = data.anonymousNote;
      }
      this.setCustomerData({
        venueCustomerId: null,
        walkIn: true,
        consumerName: null,
        anonymousNote,
        consumerEmail: null,
      });
    },

    /**
     * On textarea change, proxy the requests to onAnyChange if the save button isn't visible.
     */
    onTextareaKeyup(event) {
      if (this.$('.js-save').length > 0) {
        // The save button is already shown. Don't do anything.
        return;
      }
      this.onAnyChange(event);
    },

    rescheduleUnsavedPackageSubviews(data) {
      const changedView = data.view;
      let found = false;
      let lastEndTime = data.prevEndTime;
      let lastChangedView = changedView;

      function changeDateIfNeeded(view) {
        if (!Wahanda.Date.isEqualDates(data.newDate, view.getAppointmentDate())) {
          // The date was changed. Don't touch the time, but change the date.
          view.move(data.newDate, 0);
          lastChangedView = view;
        }
      }

      _.each(this.appointmentViews, function (view) {
        if (view === changedView) {
          found = true;
          return;
        }
        if (!found) {
          changeDateIfNeeded(view);
          // The calling view is still not found. Search until it's found.
          return;
        }

        const viewTimes = view.getAppointmentTimes(true);
        if (lastEndTime !== viewTimes.startTime) {
          // These views aren't one-after-another.
          changeDateIfNeeded(view);
          // Don't reschedule this view but check the next one as we might have an incorrect order now.
          return;
        }

        view.move(data.newDate, data.timeDiff);

        lastEndTime = viewTimes.endTime;
        lastChangedView = view;
      });

      return lastChangedView;
    },

    onReschedule(data) {
      const offer = this.getSelectedOffer();
      let changedView = data.view;

      if (!this.model.id && offer && offer.isServicePackage()) {
        changedView = this.rescheduleUnsavedPackageSubviews(data);
      }

      // Trigger this separately, as might not get triggered after views are
      // reshuffled after subsequent reschedule.
      this.onAnyChange();

      // This triggers rescheduling of other ItemViews in Appointment2
      this.options.mediator.trigger(this.options.mediator.SUBSEQUENT_RESCHEDULE_REQUIRED, {
        timeDiff: data.timeDiff,
        changedModel: changedView.model,
        date: data.newDate,
      });
    },

    enqueueColorNotify() {
      if (this.colorNotifyTimeout) {
        return;
      }

      const callback = function () {
        this.$el.colorNotifyChange();
        this.colorNotifyTimeout = null;
      }.bind(this);

      this.colorNotifyTimeout = window.setTimeout(callback, 0);
    },

    onAutoReschedule() {
      if (this.inGroupView) {
        this.trigger('auto-rescheduled');
      } else {
        this.enqueueColorNotify();
      }
    },

    shouldHandleEmployeeChange(apptView) {
      const offer = this.getSelectedOffer();

      if (!offer || (offer && !offer.isServicePackage())) {
        return true;
      }

      const { serviceId: masterOfferId } = offer.getMasterService();
      const subOffer = apptView.getOffer();

      return subOffer.id === masterOfferId;
    },

    onEmployeeChange(apptView) {
      if (!this.shouldHandleEmployeeChange(apptView)) {
        return;
      }

      const oldSkus = this.getOfferAndSkuIds().skus;

      this.renderSkus();

      const newSkus = this.getOfferAndSkuIds().skus;

      if (!_.isEqual(oldSkus, newSkus)) {
        this.onAfterSkusChange();
      } else {
        this.toggleCategoryMissmatchWarning();
      }

      this.employeeOfferList = [];
      this.renderEmployeeServices(apptView.newEmployeeId, this.setOfferId);

      // Hide missing sku warning as we change sku to first existing one if
      // employee was changed and the initially selected sku doesn't exist
      this.toggleMissingSkuWarning();
    },
  });

  BackboneEx.Mixin.View.Form.mixin(AppointmentItem);
  BackboneEx.Mixin.View.Loadmask.mixin(AppointmentItem);

  App.Views.Forms.Appointment2.Item = AppointmentItem;
})();
