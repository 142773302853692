import { action, payload } from 'ts-action';

import { MenuGroupParams, MenuGroupResponse } from './types';

interface ServerError {
  serverErrorName: string;
}

export const resetMenuGroup = action('[Menu Group] Reset');

export const resetMenuGroupError = action('[Menu Group] Reset Error');

export const archiveMenuGroup = action('[Menu Group] Archive Success');

/*
 * Requesting menu group
 */
export const getMenuGroup = action('[Menu Group] Get', payload<MenuGroupParams>());

export const getMenuGroupSuccess = action('[Menu Group] Get Success', payload<MenuGroupResponse>());

export const getMenuGroupFailure = action('[Menu Group] Get Failure');

/*
 * Update/create menu group
 */
export const setMenuGroup = action('[Menu Group] Update/Create', payload<MenuGroupResponse>());

export const setMenuGroupSuccess = action('[Menu Group] Update/Create Success');

export const setMenuGroupFailure = action(
  '[Menu Group] Update/Create Failure',
  payload<ServerError>(),
);

/*
 * Delete menu group
 */
export const deleteMenuGroup = action('[Menu Group] Delete', payload<MenuGroupParams>());

export const deleteMenuGroupSuccess = action('[Menu Group] Delete Success');

export const deleteMenuGroupFailure = action('[Menu Group] Delete Failure', payload<ServerError>());
