import React from 'react';

export const hasValue = (value) => !!(value || '').length;

const char = String.fromCharCode(32);

interface Props {
  uuid: string;
  name: string;
  onClick: (...args: any[]) => any;
  value?: string;
}

export const TreatmentNameWithValue = ({ onClick, uuid, name, value }: Props) => {
  const expression = new RegExp(`(.*?)(${value})`, 'ig');
  let match;
  let count = 0;
  const array: React.ReactNode[] = [];

  // eslint-disable-next-line no-cond-assign
  while ((match = expression.exec(name))) {
    const [current, partOne, partTwo] = match;
    const { index } = match;

    if (hasValue(current)) {
      count += current.length;
      const PARTONE = partOne.trim();
      if (hasValue(PARTONE)) {
        if (/^\s/.test(partOne)) {
          array.push(char);
        }
        array.push(<span key={`partOne-${uuid}-${index}`}>{PARTONE}</span>);
        if (/\s$/.test(partOne)) {
          array.push(char);
        }
      }
      array.push(<strong key={`partTwo-${uuid}-${index}`}>{partTwo}</strong>);
    }
  }

  const partThree = name.substr(count);
  const PARTTHREE = partThree.trim();

  if (hasValue(PARTTHREE)) {
    if (/^\s/.test(partThree)) {
      array.push(char);
    }
    array.push(<span key={`partThree-${uuid}`}>{PARTTHREE}</span>);
  }

  const handleClick = () => onClick(uuid);

  return <li onClick={handleClick}>{array}</li>;
};
