import { trackEvent, ACTIONS } from 'common/analytics';

const CATEGORY = 'back-to-business';

export const Analytics = {
  trackReachMoreClientsClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'reach-more-clients-link');
  },
  trackViewReportClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'retention-report-link');
  },
  trackCampaignPreviewButtonClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'preview-campaign-button');
  },
  trackViewReportFromSuccessClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'retention-report-link', 'from-success');
  },
  trackCampaignSendButtonClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'send-campaign-button');
  },
};
