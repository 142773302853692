/* global Wahanda shortcut App */
(function () {
  function addShortcut(key, handlerFn) {
    shortcut.add(key, _.throttle(handlerFn, 1000), {
      disable_in_input: true,
      type: 'keyup',
    });
  }

  Wahanda.Shortcuts = {
    setupDone: false,

    // Expose the throttled shortcut adding function
    add: addShortcut,

    definitions: {
      jumpToSearch: function () {
        const focusSearch = () => {
          const quickSearch = $('[aria-label="quick search"]');
          quickSearch?.click();
        };

        addShortcut('s', focusSearch);
        addShortcut('/', focusSearch);
        // This is the keyCode the browser returns when pressing "/"... Quirks.
        addShortcut(String.fromCharCode(191), focusSearch);
      },

      redeemEvoucher: function () {
        var checkCalendarRescheduleMode = function (fn) {
          return function () {
            if (!App.mainView.rescheduleMode) {
              fn.call(this);
            }
          }.bind(this);
        }.bind(this);
        addShortcut('r', checkCalendarRescheduleMode(App.Views.Calendar.VoucherRedemption.open));
      },
    },

    setupGlobal: function () {
      if (Wahanda.Shortcuts.setupDone) {
        return;
      }

      ['jumpToSearch', App.config.canRedeemEvouchers() ? 'redeemEvoucher' : null].forEach(function (
        globalDefinition,
      ) {
        if (globalDefinition) {
          Wahanda.Shortcuts.definitions[globalDefinition]();
        }
      });

      Wahanda.Shortcuts.setupDone = true;
    },
  };
})();
