import React from 'react';
import classnames from 'classnames';
import style from './style.scss';

interface IBubbleProps extends React.HTMLAttributes<Element> {
  className?: string;
}

export class Bubble extends React.PureComponent<IBubbleProps, {}> {
  static defaultProps = {
    className: null,
  };

  render() {
    const { className } = this.props;
    return <div className={classnames(className, style.bubble)}>{this.props.children}</div>;
  }
}
