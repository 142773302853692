import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';

import { Notice, NoticeProps } from './';

interface Params extends NoticeProps {
  node: HTMLElement;
}

export const NoticeInitializer = (props: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(props.node),
  render: () => {
    ReactDOM.render(
      <Notice
        dataTest={props.dataTest}
        message={props.message}
        boldMessage={props.boldMessage}
        type={props.type}
      />,
      props.node,
    );
  },
});
