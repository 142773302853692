import React from 'react';

export interface CurrencyContextProps {
  displayInFront: boolean;
  symbol?: string;
  currencyCode: string;
  thousandsSeparator: string;
  decimalPoint: string;
}

export const CurrencyContext = React.createContext<CurrencyContextProps>({
  displayInFront: true,
  symbol: '💸',
  currencyCode: '',
  thousandsSeparator: ',',
  decimalPoint: '.',
});
