import { trackEvent } from 'common/analytics';

/**
 * Settings.
 */
App.Views.Settings.OpeningHours = App.Views.Form.extend({
  events: {
    'click .save-action': 'onClickSave',
  },

  openingHoursPartialView: null,

  initialize: function () {
    this.openingHoursPartialView = new App.Views.Partials.OpeningHours({
      model: this.model,
    });

    this.bindToModel();
  },

  /**
   * Waits for the model to refetch it's data, then the view is redrawn.
   */
  bindToModel: function () {
    var self = this;
    this.model.on('fetched', function () {
      self.render();
    });
  },

  /**
   * Renders the whole settings view.
   */
  render: function () {
    this.renderDays();
    this.bindTooltips();

    // Preview link
    App.Views.Settings.renderPreviewLink(this.$('.preview-link'), this.model);
    this.$('.preview-link').click(() => {
      trackEvent('venue-opening-hours', 'click', 'preview-on-treatwell');
    });
  },

  /**
   * Renders the day and time from..to form elements.
   */
  renderDays: function () {
    this.openingHoursPartialView.setElement(this.$('.week'));
    this.openingHoursPartialView.render();
  },

  /**
   * Handler clicks on the enabled/disabled day's checkboxes
   *
   * @param Event event
   */
  onChangeDayAvailability: function (event) {
    var checkbox = event.target;
    var elem = $(checkbox).closest('li');
    elem[checkbox.checked ? 'addClass' : 'removeClass']('on');
    elem[checkbox.checked ? 'removeClass' : 'addClass']('off');
    // Disabling the selects
    var selects = elem.find('select');
    selects.attr('disabled', !checkbox.checked);

    this.updateOpeningHours(elem);
  },

  /**
   * Handler clicking on "Save".
   *
   * @param Event event
   */
  onClickSave: function (event) {
    trackEvent('venue-opening-hours', 'submit', 'save-opening-hours');
    event.preventDefault();
    this.openingHoursPartialView.ifValid(this.doSave.bind(this, $(event.currentTarget)));
  },

  doSave: function ($saveButton) {
    var self = this;
    var appointmentSlotTypeChanged = this.model.hasChanged('appointmentSlotType');
    $saveButton.prop('disabled', true).action('doing');

    function reenableForm() {
      return $saveButton.action().prop('disabled', false);
    }

    this.model.setAttributesToSave(['openingHours']);
    this.model.setNextSaveRelations(['opening-hours']);
    // Save the model and show actions to the user
    this.model.save(null, {
      success: () => {
        reenableForm();
        App.trigger(Wahanda.Event.OPENING_HOURS_SAVED);

        if (appointmentSlotTypeChanged) {
          // We need to refetch the config too
          App.config.fetch();
        }
      },
      error: function () {
        reenableForm().errorTip(Wahanda.lang.shared.savingFailed);
      },
    });
  },

  bindTooltips: function () {
    var opts = {
      position: {
        container: this.$el.find('.form-content'),
        viewport: true,
      },
    };
    this.$el.tooltipize(opts);
  },
});
