export const DEFAULT_OPENING_HOURS = [
  { dayOfWeek: 'MONDAY', openingTime: '10:00', closingTime: '20:00' },
  { dayOfWeek: 'TUESDAY', openingTime: '10:00', closingTime: '20:00' },
  { dayOfWeek: 'WEDNESDAY', openingTime: '10:00', closingTime: '20:00' },
  { dayOfWeek: 'THURSDAY', openingTime: '10:00', closingTime: '20:00' },
  { dayOfWeek: 'FRIDAY', openingTime: '10:00', closingTime: '20:00' },
  { dayOfWeek: 'SATURDAY', openingTime: '10:00', closingTime: '18:00' },
  { dayOfWeek: 'SUNDAY', openingTime: '10:00', closingTime: '18:00' },
];

export const DEFAULT_OPENING_HOURS_FOR_WIZARD = [
  ...DEFAULT_OPENING_HOURS.slice(0, -1),
  { dayOfWeek: 'SUNDAY', openingTime: null, closingTime: null },
];
