import {
  GET_CLIENT_APP_SHARE_LINK_REQUEST,
  GET_CLIENT_APP_SHARE_LINK_SUCCESS,
  GET_CLIENT_APP_SHARE_LINK_FAILURE,
} from 'reduxStore/actionsConst';

export const ERROR = 'ERROR_CLIENT_APP_LINK';

const defaultState = {
  uris: null,
};

const clientApp = (state = defaultState, action) => {
  switch (action.type) {
    case GET_CLIENT_APP_SHARE_LINK_REQUEST:
      return {
        ...state,
      };
    case GET_CLIENT_APP_SHARE_LINK_SUCCESS:
      return {
        ...state,
        uris: action.uris,
      };
    case GET_CLIENT_APP_SHARE_LINK_FAILURE:
      return {
        error: {
          type: ERROR,
        },
      };
    default:
      return state;
  }
};

export default clientApp;
