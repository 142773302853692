import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { call, put, all } from 'redux-saga/effects';

import {
  getEmailTemplateReceive,
  getEmailTemplateFailure,
} from 'components/marketing/EmailCampaign/CampaignPreview/actions';
import { backToBusinessInitStatusSuccess, backToBusinessInitStatusFailure } from './actions';

export function* getBackInBusinessInitStatusRequested() {
  try {
    const [reach, status] = yield all([
      call(api.get, apiUrl('BACK_TO_BUSINESS_REACH')),
      call(api.get, apiUrl('BACK_TO_BUSINESS_STATUS')),
    ]);
    const payload = {
      ...reach,
      alreadySent: status.sent,
      successfullyReached: status.reach,
    };
    yield put(backToBusinessInitStatusSuccess(payload));
  } catch (error) {
    yield put(backToBusinessInitStatusFailure());
  }
}

export function* getBackToBusinessTemplateRequested() {
  try {
    const template = yield call(api.get, apiUrl('EMAIL_CAMPAIGN_BACK_TO_BUSINESS_PREVIEW'));
    yield put(getEmailTemplateReceive({ template }));
  } catch (err) {
    yield put(getEmailTemplateFailure(err));
  }
}
