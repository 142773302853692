App.Routers.Clients = Backbone.Router.extend({
  routes: {
    'venue/:id': 'venue',
    '': 'defaultVenue',
  },

  defaultVenue: function () {
    this.venue(null);
  },

  venue: function (venueId) {
    App.initVenue(venueId);
  },
});
