import React from 'react';
import pathToRegexp from 'path-to-regexp';
import { Link, withRouter } from 'react-router-dom';
import { allEmployeesOptionValue } from '../EmployeeFilter';
import ROUTES from '../SalesReportContainer/reportRoutes';

interface IProductSalesLinkProps extends React.HTMLAttributes<Element> {
  onClick: (...args: any[]) => any;
  fromDate: any;
  toDate: any;
  employeeFilter?: number;
  className?: string;
  match: {
    params: {
      venueId: string;
    };
  };
}
class ProductSalesLink extends React.Component<IProductSalesLinkProps, {}> {
  static defaultProps = {
    employeeFilter: allEmployeesOptionValue,
    className: null,
  };

  onClick = () => {
    this.props.onClick(this.props.fromDate, this.props.toDate, this.props.employeeFilter);
  };

  get toLink() {
    const {
      match: {
        params: { venueId },
      },
      fromDate,
      toDate,
      employeeFilter,
    } = this.props;
    const toPath = pathToRegexp.compile(ROUTES.PRODUCT_SALES_REPORT);
    return toPath({
      venueId,
      fromDate: fromDate.formatApiDateString(),
      toDate: toDate.formatApiDateString(),
      employeeFilter,
    });
  }

  render() {
    return (
      <Link to={this.toLink} className={this.props.className} onClick={this.onClick}>
        {this.props.children}
      </Link>
    );
  }
}

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ employeeFilter: number; className: null; }... Remove this comment to see the full error message
export default withRouter(ProductSalesLink);
