export const TYPE_NO_EMPLOYEE_CATEGORY = 'no-cat';

export const TYPE_LAST_OF_CATEGORY = 'last-of-cat';

function isAssigned(employee, offerEmployees) {
  return !!offerEmployees.find((item) => item.id === employee.id);
}

function stateResp(type, isChecked) {
  return {
    // Is the employee disabled
    disabled: !!type,
    // Why disabled? See one of TYPE_* constants
    type,
    // Is checked?
    isChecked,
  };
}

function countOfSameCategorySelected(employee, offerEmployees) {
  return offerEmployees.reduce(
    (sum, { id, employeeCategoryId }) =>
      id !== employee.id && employeeCategoryId === employee.employeeCategoryId ? sum + 1 : sum,
    0,
  );
}

export function getEmployeeState(
  employee,
  {
    offerEmployees,
    initialOfferEmployees,
    useEmployeeCategoryPricing,
    employeeCategoryPricingChangeable,
  },
) {
  if (useEmployeeCategoryPricing) {
    if (!employee.employeeCategoryId) {
      return stateResp(TYPE_NO_EMPLOYEE_CATEGORY, false);
    }

    if (!employeeCategoryPricingChangeable && isAssigned(employee, initialOfferEmployees)) {
      // We are interested in getting the same employee category count only if
      // these conditions match. If staff pricing isn't changeable, we should not let
      // to uncheck the last employee of that category.
      const sameCatEmployeesSelectedCount = countOfSameCategorySelected(employee, offerEmployees);

      if (sameCatEmployeesSelectedCount === 0) {
        return stateResp(TYPE_LAST_OF_CATEGORY, true);
      }
    }

    return stateResp(null, isAssigned(employee, offerEmployees));
  }
  return stateResp(null, isAssigned(employee, offerEmployees));
}
