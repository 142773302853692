import moment from 'common/moment';
import { CACHE_PAST_DAYS, CACHE_FUTURE_DAYS } from './config';

const removeOutOfCacheableRange = (cache) => {
  const newCache = {};
  const minDate = moment().subtract(CACHE_PAST_DAYS, 'days');
  const maxDate = moment().add(CACHE_FUTURE_DAYS, 'days');

  Object.keys(cache).forEach((date) => {
    if (moment(date).isBetween(minDate, maxDate, null, '[]')) {
      newCache[date] = cache[date];
    }
  });

  return newCache;
};

export default removeOutOfCacheableRange;
