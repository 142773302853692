import { useEffect, useState } from 'react';
import { SelfSetupTask } from '../models/SelfSetupTask';
import { getTrackingByEventName } from 'src/tracking-container/TrackingEvents';

export const trackEvent = getTrackingByEventName('self_onboarding_task');

export const useDisplayToastOnCompletingTask = ({
  id,
  isCompleted,
  completedNotification,
}: SelfSetupTask) => {
  const [prevIsCompleted, setPrevIsCompleted] = useState(isCompleted);

  useEffect(() => {
    if (prevIsCompleted === false && isCompleted === true) {
      trackEvent('completed', id);
      Wahanda.ToastNotifications.sendMessage(completedNotification);
    }

    setPrevIsCompleted(isCompleted);
  }, [id, prevIsCompleted, setPrevIsCompleted, isCompleted, completedNotification]);
};
