import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { requestPosStatusAction } from 'reduxStore/common/posStatus/actions';
import { dailyReportRequest } from 'reduxStore/common/dailyReport/actions';
import { TransactionsTabContent as Component } from './TransactionsTabContent';

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ requestPosStatusAction, dailyReportRequest }, dispatch),
});

const mapState = (state) => ({
  dailyReport: state.dailyReport,
  posStatus: state.posStatus,
});

export const TransactionsTabContent = compose(
  withRouter,
  connect(mapState, mapDispatch),
)(Component);
