import App from 'common/backbone-app';

export const NumberUtils = {
  to2digit(num) {
    return `00${num}`.slice(-2);
  },

  formatToSingleDecimalAndRoundUpByHalf(number: number) {
    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(number);
  },

  /**
   * Formats numbers for output.
   *
   * @param float num
   * @param Object options Options for formatting. Possibilities:
   * > String thousandsSeparator : String which should separate thousands. Defaults to none (do not separate).
   * > Boolean forceDecimalPoint: Do not add a "num.00"
   * > Boolean noRounding       : Can disable the rounding to only 2 decimal points
   * > Boolean asNumber         : return a number (defaults to false)
   * @return String
   */
  formatNumber(
    value: number,
    options: {
      forceDecimalPoint?: boolean;
      decimalPoint?: boolean;
      noRounding?: boolean;
      asNumber?: boolean;
      thousandsSeparator?: string;
    } = {},
  ) {
    let decPoint;
    let num: any = value;

    if (options.asNumber || !options.decimalPoint) {
      // We need to return a Number. Can't have non-dot dec. point here.
      decPoint = '.';
    } else {
      decPoint = options.decimalPoint;
    }

    if (options.noRounding !== true) {
      num = Math.round(num * 100) / 100;
    }
    if (options.forceDecimalPoint) {
      num = String((parseFloat(num) || 0.0).toFixed(2));
    } else {
      num = String((parseFloat(num) || 0) + 0);
    }
    if (decPoint !== '.') {
      num = num.split('.', 2).join(decPoint);
    }

    if (!options.asNumber && options.thousandsSeparator) {
      const parts = num.split(decPoint, 2);
      let first = parts[0];

      const rxp = /(-?[0-9]+)([0-9]{3})/;
      while (rxp.test(first)) {
        first = first.replace(rxp, `$1${options.thousandsSeparator}$2`);
      }
      num = first + (parts[1] != null ? decPoint + parts[1] : '');
    }

    if (options.asNumber) {
      num = parseFloat(num);
    }

    return num;
  },

  formatNumberDefault(num, options) {
    const numberFormat = App.config.getNumberFormat(options);
    return NumberUtils.formatNumber(num, numberFormat);
  },

  formatPercentage(num) {
    return NumberUtils.formatFinancial(num);
  },

  formatFinancial(num) {
    return NumberUtils.formatNumberDefault(num, { forceDecimalPoint: true });
  },

  formatForDisplay(value: number) {
    if (value === null || Number.isNaN(value)) {
      return null;
    }
    if (value % 1 === 0) {
      return String(value);
    }

    return NumberUtils.formatNumber(
      value,
      App.config.getNumberFormat({
        forceDecimalPoint: true,
      }),
    );
  },

  formatFloatForInput(value) {
    const num = Number(value);
    if (isNaN(num) || num === 0) {
      return '';
    }
    return NumberUtils.formatNumber(num, { forceDecimalPoint: true });
  },

  formatFloatForPOSInput(value) {
    let num = value;

    if (!num && num !== 0) {
      return '';
    }

    const decimalPoint = App.config.get('numberFormat').decimalPoint;

    num = String(num).split(decimalPoint, 2).join('.');

    num = String((parseFloat(num) || 0.0).toFixed(2));
    return String(num).replace('.', decimalPoint);
  },

  formatPOSInputintoFloat(value: string, converted?: boolean) {
    let num = value;
    if (!num) {
      return '';
    }

    if (num === '0') {
      return '0';
    }

    let isInvalid = false;
    const thousandsSeparator = App.config.get('numberFormat').thousandsSeparator;
    const decimalPoint = App.config.get('numberFormat').decimalPoint;
    const regexThousands = new RegExp(
      `^([1-9](?:[0-9]{1,2})?(\\${thousandsSeparator}[0-9]{3})*)(\\${decimalPoint}[0-9]{2})?$`,
    );
    const regex = new RegExp(`^[0-9]+(\\${decimalPoint}[0-9]{1,2})?$`);
    if (String(num).includes(thousandsSeparator)) {
      if (!regexThousands.test(num)) {
        isInvalid = true;
      }
    } else if (!regex.test(num)) {
      isInvalid = true;
    }

    if (isInvalid) {
      if (converted) {
        return false;
      }
      // Replace decimal place and try to validate that.
      let alternativeDecimal;
      if (decimalPoint === '.') {
        alternativeDecimal = ',';
      } else {
        alternativeDecimal = '.';
      }

      const updatedNum = String(num).replace(alternativeDecimal, decimalPoint);
      return this.formatPOSInputintoFloat(updatedNum, true);
    }

    num = String(num).split(thousandsSeparator).join('');
    const decimalSplit = String(num).split(decimalPoint);
    num = decimalSplit.join('.');
    if (
      isNaN(num as any) ||
      decimalSplit.length > 2 ||
      (decimalSplit[1] && decimalSplit[1].length !== 2)
    ) {
      return num;
    }
    num = String((parseFloat(num) || 0.0).toFixed(2));
    return parseFloat(num);
  },

  formatExpression(expression) {
    const matches = expression.match(/([^-+*/]*)/g);

    if (!matches) {
      return NumberUtils.formatPOSInputintoFloat(expression);
    }

    const expressionWithoutTrailingSymbol = expression.replace(/[-+*/\s]+$/, '');

    return matches
      .map((x) => x.trim())
      .filter(Boolean)
      .map((match) => ({
        match,
        replacement: NumberUtils.formatPOSInputintoFloat(match),
      }))
      .reduce((acc, { match, replacement }) => {
        return acc && replacement ? acc.replace(match, replacement) : false;
      }, expressionWithoutTrailingSymbol);
  },

  floatToDecimal(x) {
    const inner = Math.round(Number(`${x}e2`));
    return Number(`${inner}e-2`);
  },
};
