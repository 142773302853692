import { trackEvent, VenueImagesAnalytics } from 'common/analytics';
import { checkDimensions, resizeAndCompressImage } from 'common/image-resize';
import { IMAGE_UPLOAD_MAX_DIMENSIONS } from 'common/consts';

BackboneEx.View.Sortable.Images = BackboneEx.View.Sortable.extend({
    sortableOptions: {
        axis: 'y',
        items: '.single-picture:not(.empty)',
        distance: 5
    },

    events: {
        'click .single-picture.empty:not(.not-editable):not(.multi-upload)': 'uploadSingleImage',
        'click .icons-delete2': 'removeImage',
        'click .image-upload': 'trackMultiUpload',
    },

    imageItemTemplateId: 'images-list-image-item',
    imagePlaceholderTemplateId: 'images-list-image-placeholder',
    imageDownloadAllTemplateId: 'images-list-download-all',
    imageDownloadAllSelector: '.download-all-pictures',

    initialize: function () {
        // Disable sorting if canEditImages is not enabled.
        this.sortableOptions.disabled = !this.options.canEditImages;
    },

    render: function (imageList) {
        this.$el.find('ul').empty();

        imageList = imageList || [];

        if (imageList.length > 0 && this.options.canDownloadAllImages) {
            this.renderDownloadAll();
        }

        for (var i = 0, len = imageList.length; i <= len; i++) {
            if (i === len) {
                this.renderPlaceholder();
            } else {
                this.renderImage(imageList[i]);
            }
        }

        this.renderDownloadAll();

        if (!this.options.canEditImages) {
            this.$el.data('valid-tooltip', '');
        }

        this.$('.vertically-centered').center({ horizontal: false });

        this.setupSortable(this.$el);
        if (App.isMasquerading()) {
            this.setupMultiUpload();
        }

    },

    setupMultiUpload: function () {
        var self = this;
        this.uploadFails = [];
        this.$('.image-upload')
            .fileupload()
            .fileupload("option", {
                dataType: "json",
                url: App.Api.wsUrl("/venue/" + App.config.get("venue").id + "/image.json"),
                multipart: true,
                paramName: "file",
                autoUpload: true,
                limitMultiFileUploads: 6,
                sequentialUploads: true,
                add: async (_, data) => {
                    try {
                        const file = data.files[0];
                        data.files[0] = await self.resizeIfIsOutDimensions(file)
                    } finally {
                        // Always try submit the file, even if it's not resized
                        data.submit();
                    }
                },
                submit: function (e, data) {
                    data.formData = {
                        "filename": data.files[0].name,
                        "venue-image-type-id": 9
                    };
                    self.showUploadSpinner();
                    return true;
                },
                done: function (event, data) {
                    if (_.isArray(data.result.errors)) {
                        self.addToFails(data);
                    } else if (data.result.id) {
                        self.addImage(data.result);
                    }
                },
                always: function () {
                    var activeUploads = $(this).fileupload('active');
                    if (activeUploads != 1) {
                        return;
                    }
                    self.hideUploadSpinner();

                    if (self.uploadFails.length > 0) {
                        self.showUploadFails();
                    }
                },
                fail: function (event, data) {
                    self.addToFails(data);
                }
            });
    },

    resizeIfIsOutDimensions: async (file) => {
        const { maxWidth, maxHeight } = IMAGE_UPLOAD_MAX_DIMENSIONS;

        const isWithinDimensions = await checkDimensions({ file, maxWidth, maxHeight })

        return isWithinDimensions 
            ? file
            : await resizeAndCompressImage({ file, maxWidth, maxHeight });
    },

    addToFails: function (data) {
        try {
            var response;
            if (data.jqXHR.status === 413) {
                response = {
                    errors: [{
                        name: "image-file-too-big"
                    }]
                };
            } else {
                response = JSON.parse(data.jqXHR.responseText);
            }

        } catch (e) { }
        var errorMessage;
        if (response) {
            const errorPayload = this.getErrorPayload(response.errors, data.files[0]);
            errorMessage = errorPayload.message;
            VenueImagesAnalytics.trackError(errorPayload.name, errorPayload.property);
        } else {
            errorMessage = Wahanda.lang.shared.errors.fileUpload.unexpected;
            VenueImagesAnalytics.trackError('unexpected-error')
        }

        this.uploadFails.push(
            {
                errorName: data.files[0].name,
                errorDescription: errorMessage
            });
    },

    getErrorPayload: function (errors, file) {
        var error = errors[0];
        var errorText = null;
        var property = null;
        switch (error.name) {
            case 'image-type-unknown':
                errorText = Wahanda.lang.shared.errors.fileUpload.notImage;
                property = file.type;
                break;

            case "image-file-too-big":
                errorText = Wahanda.lang.shared.errors.fileUpload.tooBig;
                property = file.size;
                break;

            case "image-dimensions-too-big":
                errorText = Wahanda.lang.shared.errors.fileUpload.dimensionsTooBig;
                property = this.extractDimensionsFromError(error.message)
                break;

            case "image-dimensions-too-small":
                errorText = Wahanda.lang.shared.errors.fileUpload.dimensionsTooSmall;
                property = this.extractDimensionsFromError(error.message)
                break;
            default:
                errorText = Wahanda.lang.shared.errors.fileUpload.unexpected;
        }
        return { message: errorText, name: error.name || 'unexpected-error', property };
    },

    extractDimensionsFromError(errorMessage) {
        var dimensions = errorMessage.match(/[0-9]*\*[0-9]*/);
        return dimensions && dimensions[0].replace('*', 'x');
    },

    showUploadFails: function () {
        App.ES6.Initializers.ErrorListDialog({
            errorList: this.uploadFails,
            intro: Wahanda.lang.shared.errors.fileUpload.intro
        });
        // Now clear the array after showing.
        this.uploadFails = [];
    },

    showUploadSpinner: function () {
        this.$('.multi-upload').addClass('processing');
    },

    hideUploadSpinner: function () {
        this.$('.multi-upload').removeClass('processing');
    },

    renderImage: function (image) {
        this.$('ul').append(
            this.getImageHtml(image)
        );
    },

    getImageHtml: function (image) {
        return Wahanda.Template.renderTemplate(
            this.imageItemTemplateId,
            {
                src: Wahanda.ScreenDensity.getImageFromStructure(image.uris, 99, 66),
                id: image.id,
                canEditImages: this.options.canEditImages,
                uris: JSON.stringify(image.uris)
            }
        );
    },

    getPlaceholderHtml: function (options) {
        return Wahanda.Template.renderTemplate(
            this.imagePlaceholderTemplateId,
            {
                canAddMultiImages: this.canAddMultiImages(),
                canEditImages: this.options.canEditImages
            }
        );
    },

    canAddMultiImages: function () {
        return Wahanda.Features.isEnabled('CD-695-enable-add-multiple-images') || App.isMasquerading();
    },

    renderPlaceholder: function () {
        if (this.options.canEditImages) {
            this.$('ul').append(
                this.getPlaceholderHtml(this.options)
            );
        }
    },

    renderDownloadAll: function () {
        const venueId = App.config.get("venue").id;
        this.$(this.imageDownloadAllSelector).replaceWith(
            Wahanda.Template.renderTemplate(
                this.imageDownloadAllTemplateId,
                {
                    url: App.Api.wsUrl("/venue/" + venueId + "/download/source-images"),
                }
            )
        );
        this.$(this.imageDownloadAllSelector).click(function () {
            trackEvent('venue-details', 'download', 'all-venue-photos', venueId);
        })
    },

    onSortUpdate: function (_, ui) {
        const id = ui.item.find('img').data('id');
        VenueImagesAnalytics.trackReorder(id)
    },

    onSelectImage: function (event) {
        var $target = $(event.target);
        var id = $target.data("id");
        var image = this.collection.get(id);

        this.selectImage(id, image.toJSON());
        this.close();
    },

    selectImage: function (imageId, data) {
        App.trigger(Wahanda.Event.IMAGE_LIST_IMAGE_SELECTED);
        // this.options.selectImageCallback(imageId, data);
        this.addImage(data);
    },

    uploadSingleImage: function () {
        var view = new App.Views.Dialog.ImageCrop({
            imageTypeIdToUpload: 9,
            selectImage: this.selectImage.bind(this),
            MIN_WIDTH: 1920,
            MIN_HEIGHT: 1280,
            MAX_WIDTH: 4500,
            aspectRatio: 1.5,
            submit: this.imageSubmit,
            done: this.imageDone
        });
        view.render();
        view.open();
        trackEvent('venue-details', 'click', 'add-venue-image');
    },

    getImagesToSkip: function () {
        return _.pluck(this.getValues(), "id");
    },

    removeImage: function (event, ...args) {
        const $singlePicture = $(event.target).closest('.single-picture');
        const id = $singlePicture.find('img').data('id');
        VenueImagesAnalytics.trackDelete(id);
        $singlePicture.slideUp(350, function () {
            $(this).remove();
        });
    },

    addImage: function (image) {
        this.$('.single-picture.empty:not(.multi-upload)').before(
            this.getImageHtml(image)
        );
    },

    getValues: function () {
        return _.map(this.$('img'), function (img) {
            return {
                id: parseInt(img.getAttribute('data-id'), 10),
                visible: true,
                uris: JSON.parse(img.getAttribute('data-uris'))
            };
        });
    },

    trackMultiUpload: function () {
        VenueImagesAnalytics.trackMultiUpload()
    }
});
