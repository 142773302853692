import React from 'react';
import { hash, map, get, assoc, toJs, toClj } from 'mori';
import ButtonChoicesDialog from 'components/common/dialog/button-choices';
import Wahanda from 'common/wahanda';

function maybeChangeCell(oldValue, newValue) {
  return oldValue !== newValue ? (
    <tr key={hash(`${oldValue}>${newValue}`)}>
      <td>{oldValue}</td>
      <td className="separator">&gt;</td>
      <td>{newValue}</td>
    </tr>
  ) : null;
}

function changesTable(oldData, newData) {
  return (
    <table>
      <tbody>
        {maybeChangeCell(oldData.time, newData.time)}
        {maybeChangeCell(oldData.date, newData.date)}
        {maybeChangeCell(oldData.employeeName, newData.employeeName)}
      </tbody>
    </table>
  );
}

function getIsChecboxChecked(dialog) {
  const cRef = dialog.checkbox;
  return { checkboxChecked: cRef ? cRef.checked : false };
}

function injectCheckboxValueIntoButtonOnClick(dialog, buttons) {
  return map((button) => {
    const origOnClick = get(button, 'onClick');
    return assoc(button, 'onClick', () => {
      origOnClick(getIsChecboxChecked(dialog));
    });
  }, buttons);
}

interface IDragConfirmationDialogProps extends React.HTMLAttributes<Element> {
  oldData: {
    date?: boolean;
    time?: string;
    employeeName?: string;
  };
  newData: {
    date?: boolean;
    time?: string;
    employeeName?: string;
  };
  notes?: string[];
  onClose?: (...args: any[]) => any;
  buttons?: any[];
  checkbox?: {
    text?: string;
  };
  text?: any;
}

export default class DragConfirmationDialog extends React.Component<
  IDragConfirmationDialogProps,
  {}
> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'checkbox' has no initializer and is not ... Remove this comment to see the full error message
  checkbox: HTMLInputElement;

  render() {
    const lang = Wahanda.lang.calendar.dragConfirmation;
    const notes = map(
      (note) =>
        note ? (
          <div className="note" key={hash(note)}>
            {note}
          </div>
        ) : null,
      this.props.notes,
    );
    const checkboxComponent = (() => {
      if (!this.props.checkbox) {
        return null;
      }
      return (
        <label className="checkbox-wrapper">
          <input
            type="checkbox"
            ref={(checkbox) => {
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLInputEl... Remove this comment to see the full error message
              this.checkbox = checkbox;
            }}
          />
          {this.props.checkbox.text}
        </label>
      );
    })();
    const { oldData, newData } = this.props;
    return (
      <ButtonChoicesDialog
        onClose={this.props.onClose}
        classes={{ 'dialog2--drag-confirm': true }}
        title={lang.title}
        buttons={injectCheckboxValueIntoButtonOnClick(this, toClj(this.props.buttons))}
      >
        {changesTable(oldData, newData)}
        {checkboxComponent}
        {toJs(notes)}
      </ButtonChoicesDialog>
    );
  }
}
