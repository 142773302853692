import React from 'react';
import { EditorState } from 'draft-js';
import { ControlButton } from '../ControlButton';
import style from '../RichTextEditor.scss';

const BLOCK_TYPES = [
  { label: 'H1', name: 'header-one', className: 'rich-text-editor-button-header-1' },
  { label: 'H2', name: 'header-two', className: 'rich-text-editor-button-header-2' },
  { label: 'UL', name: 'unordered-list-item', className: 'rich-text-editor-button-unordered-list' },
  { label: 'OL', name: 'ordered-list-item', className: 'rich-text-editor-button-ordered-list' },
];
interface Props {
  editorState: EditorState;
  onToggle: (params) => void;
}

export const BlockStyleControls = ({ editorState, onToggle }: Props) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className={style.controls}>
      {BLOCK_TYPES.map((type) => (
        <ControlButton
          key={type.label}
          active={type.name === blockType}
          onToggle={onToggle}
          style={type.name}
          className={type.className}
        />
      ))}
    </div>
  );
};
