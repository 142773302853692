import { trackEvent, ACTIONS } from 'common/analytics';

export class MenuGroupAnalytics {
  public static CATEGORY = 'services';

  public static trackMenuGroupModalView() {
    trackEvent(MenuGroupAnalytics.CATEGORY, ACTIONS.VIEW, 'menu-group-name');
  }

  public static trackMenuGroupCreate() {
    trackEvent(MenuGroupAnalytics.CATEGORY, ACTIONS.SUBMIT, 'menu-group', 'created');
  }

  public static trackMenuGroupUpdate() {
    trackEvent(MenuGroupAnalytics.CATEGORY, ACTIONS.UPDATE, 'menu-group-name', 'edit');
  }

  public static trackMenuGroupArchive() {
    trackEvent(MenuGroupAnalytics.CATEGORY, ACTIONS.UPDATE, 'menu-group-name', 'archive');
  }

  public static trackMenuGroupUnarchive() {
    trackEvent(MenuGroupAnalytics.CATEGORY, ACTIONS.UPDATE, 'menu-group-name', 'unarchive');
  }

  public static trackMenuGroupDelete() {
    trackEvent(MenuGroupAnalytics.CATEGORY, ACTIONS.UPDATE, 'menu-group-name', 'delete');
  }
}
