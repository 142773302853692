/* global CFInstall */
/* Include core js polyfill for browser rendering */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import _ from 'common/underscore';
import Cookie from 'js-cookie';
import ROLES from 'common/constants/userRoles';
import Wahanda from 'common/wahanda';
import { ACCOUNT_LOCALE_LOCAL_STORAGE_KEY } from 'components/AccountLanguageSelect/constants';
import { kebab } from 'case';
import debounce from 'lodash/debounce';
import * as Sentry from '@sentry/browser';
import { StoreContext } from 'common/store/store-context';
import { isOnboardingWidgetEnabled } from 'components/shared/OnboardingWidget/utils/isOnboardingWidgetEnabled';
import { isSelfOnboarding } from 'components/shared/OnboardingWidget/utils/isSelfOnboarding';

const appConfig = require('config/domains-locales');

const App = _.extend(
  {
    Api: {
      // Comes in from /connect.js
      version: null,
      wsRootPath: '/api',
      loginUrl: '/login',
    },
    CHANNEL_CODES: appConfig.CHANNEL_CODES,
    GOOGLE_MAP_KEY: appConfig.GOOGLE_MAP_KEY,
    DOMAIN_TO_LOCALE_MAP: appConfig.DOMAIN_TO_LOCALE_MAP,
    LOCALE_TO_CHANNEL_CODE_MAP: appConfig.LOCALE_TO_CHANNEL_CODE_MAP,
    CHANNEL_CODE_TO_LOCALE_MAP: appConfig.CHANNEL_CODE_TO_LOCALE_MAP,
    LOCALES_IDENTITY_VERIFICATION_LEARN_MORE_LINK_MAP:
      appConfig.LOCALES_TO_IDENTITY_VERIFICATION_LEARN_MORE_LINK,
    Views: {
      Forms: {},
      Partials: {},
    },
    ES6: {
      Components: {},
      Initializers: {},
      Externs: {},
    },
    Routers: {},
    Models: {},
    Collections: {},
    config: null,
    isLoaded: false,
    // set to TRUE in a router if handling a request that allows lightweight authentication
    allowLightweightAuthentication: false,
    referenceDataTypes: [
      'appointment-slot-type',
      'time-zone',
      'country',
      'location-state',
      'venue-type',
      'currency',
    ],
    // Last got ajax error
    lastError: null,
    lastVenueId: null,
    // This request's "unique" identifier
    requestGUID: null,
    // Should be set to TRUE, if the current request is from an external source (e.g. email)
    fromExternalSource: false,
    isProd: window.IS_PROD,
  },
  Backbone.Events,
);

if (!App.isProd) {
  App.on('all', function (eventName, ...rest) {
    console.log(`Backbone Event: ${eventName}`, ...rest);
  });
}

App.getClientLocale = function () {
  const { config, domainLocale } = this;
  if (!config || !domainLocale) {
    return undefined;
  }

  const accountLocale = Wahanda.LocalStorage.get(ACCOUNT_LOCALE_LOCAL_STORAGE_KEY);
  const venueLocale = config.get('venue').locale;

  return accountLocale || venueLocale || domainLocale;
};

App.setup = function (config) {
  Sentry.init({
    enabled: config.isSentryEnabled,
    dsn: 'https://f0ce3675e837b1efce3e1855034e1a77@o483267.ingest.sentry.io/4505753772883968',
    environment: config.env,
    release: config.apiVersion,
    ignoreErrors: [
      // This type of Sentry error comes from a backend request with an error message.
      // Since these errors are being tracked on the backend, we can safely ignore them.
      'Object captured as promise rejection with keys: abort, always, complete, done, error',
    ],
  });

  Sentry.setTag('ruid', config.ruid);

  App.Api.version = config.apiVersion;
  App.enableErrorReporting = config.enableErrorReporting;
  App.env = config.env;
  App.ruid = config.ruid;
  App.pathLocale = config.pathLocale;
  App.segmentWriteKey = config.segmentWriteKey;

  // Disable Segment tracking when masquerading
  if (!App.isMasquerading()) Wahanda.Tracking.init();
};

App.Api.wsRoot = function () {
  // On DESKTOP/MOBILE channels use relative URLs.
  let prefix = App.Api.wsRootPath;
  if (!window.location.host) {
    // On in-App code, use the set domain.
    prefix = App.getContentChannelUrl() + prefix;
  }
  return prefix;
};

App.Api.wsUrl = function (wsName) {
  return this.wsRoot() + wsName;
};

App.Api.wsVenueUrl = function (wsName) {
  return App.Api.wsUrl(`/venue/${App.getVenueId()}${wsName}`);
};

App.Api.wsSocialMediaUrl = function (wsName) {
  return App.Api.wsUrl(`/supplier-social-media-integrations/venue/${App.getVenueId()}/${wsName}`);
};

App.Api.wsPointOfSaleUrl = function (wsName) {
  return App.Api.wsUrl(`/point-of-sale/venue/${App.getVenueId()}/${wsName}`);
};

App.Api.wsSupplierUrl = function (wsName) {
  return App.Api.wsUrl(`/supplier/${App.getSupplierId()}${wsName}`);
};

App.Api.wsConsultationFormsUrl = function (wsName) {
  return App.Api.wsUrl(`/consultation-forms${wsName}`);
};

App.Api.wsSupplierMarketingCampaignsUrl = function (wsName) {
  return App.Api.wsUrl(`/supplier-marketing-campaigns/venue/${App.getVenueId()}${wsName}`);
};

App.Api.setupVersionHeader = function () {
  $(document).ajaxSend(function (event, jqxhr) {
    jqxhr.setRequestHeader('X-Api-Version', App.Api.version);
  });
};

App.onLoad = function (onLoaded) {
  const readyStateCheckInterval = setInterval(function () {
    if (document.readyState === 'complete') {
      clearInterval(readyStateCheckInterval);
      App.validateBrowser(onLoaded);
    }
  }, 500);
};

App.showUnsupportedBrowser = function () {
  App.ES6.Initializers.UnsupportedBrowser().render();
  App.hideLoadingIndicator();
};

App.validateBrowser = function (onLoaded) {
  if (typeof CFInstall !== 'undefined' && navigator.userAgent.indexOf('chromeframe') < 0) {
    CFInstall.check({
      mode: 'overlay',
    });

    App.showUnsupportedBrowser();
    return;
  }
  if (onLoaded) {
    onLoaded();
  }
};

App.start = function (mainViewClass, mainViewSelector) {
  this.mainViewClass = mainViewClass;
  this.mainViewSelector = mainViewSelector;
  this.headerRouter = new App.Routers.Header();
  Backbone.history.start();

  App.watchRouteLabels();

  if (App.enableErrorReporting) {
    Wahanda.startErrorReporting();
  }

  App.Api.setupVersionHeader();

  // Load the ajax error handler
  Wahanda.Ajax.initErrorHandler();
  // Initialize the OnBoarding Wizzard
  Wahanda.Wizzard.initialize();
  // Set the unique request identifier
  App.requestGUID = Wahanda.Util.guid();
  // Setup Wahanda tracking
  App.setupAnalytics();

  // Show the site
  if (Cookie.get('extranet.currentVenueId')) {
    App.hideLoadingIndicator();
  }

  App.ES6.Initializers.ReactGlobal.initialize();
};

App.initVenue = function (venueId, settingsUpdated) {
  this.login(venueId, settingsUpdated);
};

App.login = function (venueId, settingsUpdated) {
  // TODO: implement Loading screen here
  const id = venueId != null ? venueId : Cookie.get('extranet.currentVenueId');
  this.loginEx(id, settingsUpdated);
};

App.hideLoadingIndicator = function () {
  $('body').removeClass('loading');
};

App.showLoadingFailed = function () {
  const $body = $('body');
  if ($body.hasClass('loading')) {
    $body.addClass('load-failed');
  }
};

App.watchRouteLabels = function () {
  const debouncedTriggerLabelsChanged = debounce(() => {
    App.trigger(Wahanda.Event.APP_ROUTE_LABELS_CHANGED);
  }, 1000);

  const formatLabels = () =>
    [App.getCurrentModule(), ...window.location.hash.slice(1).split('/')]
      .filter((label) => !['default', 'venue'].includes(label))
      .filter((label) => {
        /* eslint-disable-next-line no-restricted-globals */
        return isNaN(label.replace(/-/gi, ''));
      })
      .map(kebab);

  App.routeLabels = formatLabels();

  window.addEventListener('hashchange', () => {
    App.routeLabels = formatLabels();
    debouncedTriggerLabelsChanged();
  });
};

function maybeRedirectBecauseOfLanguage(data, settingsUpdated, orCallback) {
  const pathPattern = /^\/([a-z]{2}(?:_[A-Z]{2})?)\//;
  const pathLocale = window.location.pathname.match(pathPattern);
  const pathnameWithoutLocale = window.location.pathname.replace(pathPattern, '/');
  const accountLocale = Wahanda.LocalStorage.get(ACCOUNT_LOCALE_LOCAL_STORAGE_KEY);
  const locale = accountLocale || data.venue.locale;

  const longLocale = locale && locale.length === 2 ? `${locale}_${locale.toUpperCase()}` : locale;

  if (settingsUpdated) {
    if (locale) {
      window.location.pathname = `/${longLocale}${pathnameWithoutLocale}`;
    } else {
      window.location.pathname = pathnameWithoutLocale;
    }

    return;
  }

  if (locale && pathLocale == null) {
    window.location.pathname = `/${longLocale}${pathnameWithoutLocale}`;
  } else {
    orCallback();
  }
}

App.loginEx = function (venueId, settingsUpdated) {
  const fetchOptions = {
    skipErrorHandling: true,
    success(config, response) {
      App.trigger(Wahanda.Event.APP_FEATURES_REQUEST, {
        venueId: response.venue.id,
      });

      maybeRedirectBecauseOfLanguage(
        response,
        settingsUpdated,
        _.partial(App.onLogin, config, response),
      );
    },
    error(config, response) {
      let url;
      if ((response.status === 401 || response.status === 403) && App.hasLightweightAuthKey()) {
        // Lightweight login
        let afterLightweightLoginFailure = null;
        if (venueId) {
          afterLightweightLoginFailure = function () {
            // Try to login without any venue given
            App.loginEx(null);
          };
        }
        App.loginLightweight({ error: afterLightweightLoginFailure });
        return;
      }

      if (response.status === 401) {
        url = App.loginRedirectUrl();
      }

      if (response.status === 403) {
        if (App.config.get('venueId')) {
          // clean the page hash parameters if they were passed in URL if user is not authorised for this venue
          App.headerRouter.navigate('');
          App.loginEx(null);
          return;
        }
        url = App.Api.loginUrl;
      }

      // Other errors are handled globally, by Wahanda.Ajax.

      if (url) {
        window.location = url;
      } else {
        App.showLoadingFailed();
      }
    },
  };

  if (!this.config) {
    this.config = new App.Models.Config({ venueId });
    this.config.fetch(fetchOptions);
  } else {
    this.config.set('venueId', venueId, { silent: true });
    this.config.fetch(fetchOptions);
  }

  App.trigger(Wahanda.Event.APP_LOAD_STARTED);
};

App.isApp = function (location, userAgent) {
  return (
    userAgent.indexOf('Wahanda') !== -1 ||
    userAgent.indexOf('Treatwell') !== -1 ||
    location.search.indexOf('hidemenu') !== -1
  );
};

App.isFromApp = function (location) {
  return location.search.indexOf('sourceUserAgent') !== -1;
};

/**
 * Determines whether mobile apps have customized user agent to effectively say
 * that web pages should not display some of the UI elements as the web page is
 * going to be shown on a webview.
 * @returns {boolean} true if user agent string contains 'webview', false otherwise
 */
App.isWebview = function () {
  return (
    window.navigator.userAgent.toLowerCase().includes('webview') ||
    window.location.href.includes('webview-testing') ||
    // for development purposes
    // todo: when App.isWebview() is being called on non-local dev environments it always
    // returns false for the code that calls it before enabled features are returned by the api
    (!App.isProd && Wahanda.Features.isEnabled('webview-testing'))
  );
};

App.loginRedirectUrl = function (route) {
  const origin = `${window.location.protocol}//${window.location.host}`;
  const originLength = origin.length;
  const relativeRoute = (route || document.URL).slice(originLength);
  return `${App.Api.loginUrl}?route=${encodeURIComponent(relativeRoute)}`;
};

App.setVenue = function (venueId) {
  this.initVenue(venueId);
};

App.logout = function (redirect) {
  const shouldRedirect = redirect == null ? true : redirect;
  return $.ajax({
    url: App.Api.wsUrl('/logout.json'),
    type: 'post',
    contentType: 'application/json',
    data: '{}',
  }).done(function () {
    if (shouldRedirect) {
      window.location = App.Api.loginUrl;
    }
  });
};

App.onLogin = function (config) {
  App.once(Wahanda.Event.APP_FEATURES_LOADED, ({ features }) => {
    Wahanda.Features.setEnabledFeatures(features);
    App.onLoginAfterFeatures(config);
  });
};

App.initSalesforceChat = function () {
  if (!Wahanda.Features.isEnabled('CD-823-salesforce-bot')) {
    return;
  }

  if (!Wahanda.Features.isEnabled('CD-1312-salesforce-bot-secure-authentication')) {
    return Wahanda.initUnsecureSalesforceBot();
  }

  if (App.isMasquerading()) {
    return;
  }

  App.ES6.Initializers.SalesforceBotInitializer().render();
};

App.onLoginAfterFeatures = function (config) {
  const venue = config.get('venue');

  App.initSalesforceChat();

  const payload = {
    isWebview: App.isWebview(),
  };
  App.trigger(Wahanda.Event.APP_CONFIG_LOADED, payload);

  // Check if the venue can access this locale's Connect
  if (
    !App.isApp(window.location, navigator.userAgent) &&
    !config.contentChannelMatches(App.domainChannelCode()) &&
    // Prevent redirects during cypress tests
    App.env !== 'TEST'
  ) {
    // First log the user out.
    const redirectUrl = App.getContentChannelUrl(config.getContentChannel());
    App.logout(false).done(function () {
      window.location = redirectUrl;
    });
    return;
  }

  if (!App.haveAccessToUrlDialogVenue()) {
    window.location = App.loginRedirectUrl(App.mainViewOptions.initialDialog.dialogUrl);
    return;
  }

  // Show the site
  App.hideLoadingIndicator();

  // fix time from/time to values to be start of the hour
  if (venue) {
    let strTime = venue.dayStartsAt;
    let time = Wahanda.Time.timeToMinutes(strTime);
    time = Math.floor(time / 60) * 60;
    strTime = Wahanda.Time.toApiString(time);
    venue.dayStartsAt = strTime;

    if (App.lastVenueId != null && App.lastVenueId !== venue.id) {
      App.trigger(Wahanda.Event.APP_VENUE_CHANGED);
    }
    App.lastVenueId = venue.id;
  }

  // Redirect to login if lightweight authentication is not expected in this situation
  if (!App.allowLightweightAuthentication && App.isRestrictedMode()) {
    window.location = App.loginRedirectUrl();
    return;
  }

  // Set permission list
  Wahanda.Permissions.setPermissionList(
    config.get('account') ? config.get('account').permissions : [],
  );

  if (App.isRestrictedMode()) {
    // render restricted functionality if logged in in lightweight authentication
    App.goLightweight();
    return;
  }

  if (App.isTaxAuditor() && App.mainViewClass !== App.Views.Reports) {
    window.location.href = '/reports';
    return;
  }

  // store id of current venue
  Cookie.set('extranet.currentVenueId', null, { expires: -1, path: '/menu' });
  Cookie.set('extranet.currentVenueId', venue.id, { expires: 150, path: '/' });

  // render page header
  if ($('#header').length > 0) {
    if (!App.headerView) {
      App.headerView = new App.Views.Header({
        el: $('#header'),
        model: App.config,
      });
    } else {
      // set new config for a Header
      App.headerView.model = App.config;
    }
    App.headerView.render();
  }

  // Load reference data
  if (!App.referenceData) {
    App.referenceData = new App.Models.Reference();
  }

  App.referenceData.set(
    {
      venueId: venue.id,
      types: App.referenceDataTypes,
      params: {
        'time-zone.country-code': venue.countryCode,
      },
    },
    { silent: true },
  );
  App.referenceData.fetch({
    success: App.onReferenceDataLoaded,
    error() {
      App.showLoadingFailed();
    },
  });

  App.setTitle();
  if (Wahanda.Features.isEnabled('Store-on-Connect')) {
    // Init Treatwell Store
    StoreContext.Instance();
  }
};

App.setModuleName = function (moduleName) {
  App.moduleName = moduleName;
};

App.getVenueChannelVATRates = function () {
  return App.config.get('channel').vatRates.sort((a, b) => b.rate - a.rate);
};

App.getVenueStandartVat = function () {
  return App.getVenueChannelVATRates().find((vatRate) => vatRate.category === 'STANDARD');
};

App.getVATRateForCategory = function (category) {
  const vat = App.getVenueChannelVATRates().find((vatRate) => vatRate.category === category);
  if (vat === undefined) {
    return undefined;
  }
  return vat.rate;
};

App.setTitle = function () {
  const venue = App.config.get('venue');
  let venueName = '';
  let moduleName = '';

  if (venue) {
    venueName = venue.name;
  }

  if (App.moduleName) {
    moduleName = App.moduleName;
  }

  window.document.title = `${moduleName} - ${venueName}`;
};

App.renderGlobalReactListeners = () => {
  App.ES6.Initializers.RootTransactionDialogInitializer({
    node: window.document.getElementById('root-transaction-dialog'),
  }).render();
};

function hideLeftPanelOptions() {
  ['.calendar-buttons', '#pos-ad', '#calendar-alerts', '#sidebar-info', '#nav2'].forEach(
    (selector) => {
      const element = document.querySelector(selector);
      if (element) {
        element.style.display = 'none';
      }
    },
  );
}

function matchLeftPanelOptionsStyleWithOnboardingWidget() {
  const calendarWrapperElement = document.querySelector('.calendar-wrapper');
  if (calendarWrapperElement) {
    calendarWrapperElement.style.flex = '1';
  }
}

App.setUpGoLive = () => {
  const goLiveNode = $('#js-go-live').get(0);
  if (!goLiveNode || !App.isOnboardingWidgetEnabled()) {
    return;
  }

  hideLeftPanelOptions();
  matchLeftPanelOptionsStyleWithOnboardingWidget();

  App.ES6.Initializers.OnboardingWidget({ node: goLiveNode }).render();
};

App.onReferenceDataLoaded = function () {
  if (!App.mainViewClass || !App.mainViewSelector) {
    return;
  }

  if (!App.mainView) {
    // eslint-disable-next-line
    App.mainView = new App.mainViewClass({
      el: $(App.mainViewSelector),
      options: App.mainViewOptions,
    });
  }
  App.trigger('app:loaded');

  App.mainView.render();
  App.renderGlobalReactListeners();

  Wahanda.Shortcuts.setupGlobal();
};

App.getTimezone = function () {
  return App.config.get('venue').zoneId;
};

App.goLightweight = function () {
  if (!App.mainView) {
    // eslint-disable-next-line
    App.mainView = new App.mainViewClass({
      el: $(App.mainViewSelector),
      options: App.mainViewOptions,
    });
  }
  if (App.mainView.lightweightAuthAction) {
    App.mainView.lightweightAuthAction();
  }
};

// Lightweight authentication
App.setLightweightAuthKey = function (key) {
  App.lightweightAuthKey = key;
};

App.hasLightweightAuthKey = function () {
  return App.lightweightAuthKey != null;
};

App.isRestrictedMode = function () {
  if (App.config == null) {
    return false;
  }

  const account = App.config.get('account');
  if (account == null) {
    return false;
  }
  return account.lightweight;
};

App.isMasquerading = function () {
  if (Wahanda.Features.isEnabled('c-masqueraded')) {
    // Feature toggle is used only for UI layout testing purposes and will not ensure actually masqueraded session
    return true;
  }

  if (App.config == null) {
    return false;
  }

  const account = App.config.get('account');
  if (account == null) {
    return false;
  }
  return account.masquerade;
};

// owner access
App.isAdministrator = function () {
  return App.config.get('account').employeeRoleCode === ROLES.ADMINISTRATOR;
};

App.isOnboardingWidgetEnabled = isOnboardingWidgetEnabled;

App.isSelfOnboarding = isSelfOnboarding;

App.isTaxAuditor = function () {
  const account = App.config && App.config.get('account');
  return account && account.employeeRoleCode === ROLES.TAX_AUDITOR;
};

App.isIgluEnabled = function () {
  return App.isMasquerading(); // We no longer require the Iglu cookie
};

App.getVenueId = function () {
  if (App.config == null) {
    return null;
  }

  const venue = App.config.get('venue');
  if (venue == null) {
    return null;
  }
  return venue.id;
};

App.getEmployeeId = function () {
  if (App.config == null) {
    return null;
  }

  const account = App.config.get('account');
  if (account == null) {
    return null;
  }
  return account.employeeId;
};

App.getSupplierId = function () {
  if (App.config == null) {
    return null;
  }

  const venue = App.config.get('venue');
  if (venue == null) {
    return null;
  }
  return venue.supplierId;
};

/**
 * Tries lightweight login.
 *
 * @param Object options Possible params:
 * > Function success Callback to be called on login success
 * > Function error   Callback to be called when login fails
 * > boolean relogin  Should try to login again if auth is successful (default: true)
 */
App.loginLightweight = function (opt) {
  const options = _.defaults(opt || {}, {
    relogin: true,
  });
  const key = App.lightweightAuthKey;
  const loginUrl = App.Api.wsUrl(`/authenticate-lightweight/${key}`);
  delete App.lightweightAuthKey;

  const successCallback = function () {
    if (options.relogin) {
      App.loginEx(null);
    }
    if (_.isFunction(options.success)) {
      options.success();
    }
  };
  const errorCallback = function () {
    // Lightweight auth failed
    if (_.isFunction(options.error)) {
      options.error();
    } else if (App.config.get('venueId')) {
      // User is authenticated
      App.trigger('app:lightweight-login-failed');
    } else {
      // User is not logged in. Redirect to login screen.
      window.location = App.loginRedirectUrl();
    }
  };

  $.ajax({
    url: loginUrl,
    type: 'get',
    skipErrorHandling: true,
    success: successCallback,
    error: errorCallback,
  });
};

/**
 * Returns current module (home, calendar, ...).
 *
 * @return string
 */
App.getCurrentModule = function () {
  const path = window.location.pathname || '';
  const moduleResult = path.match(/\/([a-z]+)$/i);

  if (moduleResult) {
    return moduleResult[1].toLowerCase();
  }
  return 'calendar';
};

App.haveAccessToUrlDialogVenue = function () {
  if (
    App.mainViewOptions &&
    App.mainViewOptions.initialDialog &&
    App.mainViewOptions.initialDialog.venueId > 0
  ) {
    return App.config.hasVenue(App.mainViewOptions.initialDialog.venueId);
  }
  // No dialog defined or no venue id passed
  return true;
};

App.setupAnalytics = function () {
  if (App.isMasquerading()) {
    // Do not track masqeraded users
    return;
  }

  // Set up in page events
  Wahanda.Analytics.start();
};

/**
 * Is the feature supported for this venue and/or channel?
 *
 * @param String feature
 *
 * @returns boolean
 */
App.isFeatureSupported = function isFeatureSupported(feature) {
  return App.config.hasFeatureSupport(feature);
};

App.isVenueListedOnMarketplace = function isVenueListedOnMarketplace() {
  return App.config.get('venue').listedOnMarketplace;
};

App.isNewVenue = function isNewVenue() {
  return App.config.get('venue').newVenue;
};

App.isIdentityVerificationNeeded = function isIdentityVerificationNeeded() {
  return App.config.get('account')?.kycActionOutstanding;
};

App.isKycNewSupplier = function isKycNewSupplier() {
  return App.config.get('account')?.newSupplier;
};

App.isBankAccountSyncNeeded = function isBankAccountSyncNeeded() {
  return !App.config.get('account')?.bankAccountSyncStatus?.sync;
};

App.getIdentityVerificationLearnMoreLink = () => {
  return (
    App.LOCALES_IDENTITY_VERIFICATION_LEARN_MORE_LINK_MAP[App.getClientLocale()] ||
    App.LOCALES_IDENTITY_VERIFICATION_LEARN_MORE_LINK_MAP['en']
  );
};

App.getIdentityVerificationUrl = () =>
  `./identity-verification?returnUrl=${encodeURIComponent(window.location.href)}`;

App.getBankSettingsUrl = () => `./settings#venue/${App.getVenueId()}/finance/bank-details`;

(function () {
  const validOnce = true;
  let validContext = true;
  /**
   * Returns if the request is from an external source.
   * It returns "true" only once. This is usefull for analytics tracking.
   *
   * @return boolean
   * @deprecated in favor of isContextFromExternalSoruce
   */
  App.isFromExternalSourceOnce = function () {
    const result = validOnce && App.fromExternalSource;
    valid = false; // eslint-disable-line
    return result;
  };

  // Context-sensitive external soruce checking
  // A "context" is a displayable object, visible in the UI. Examples of different contexts:
  //   Appointment dialog, Calendar, Block-time form dialog
  App.isContextFromExternalSoruce = function () {
    return validContext && App.fromExternalSource;
  };

  // On context change invalidate "fromExternalSource" checking.
  // This event must be triggered on end of each context (example: when an relevant dialog closes).
  // Value should be taken from Wahanda.Event.APP_CONTEXT_CHANGED
  App.on('app:context-changed', function () {
    validContext = false;
  });
})();

/**
 * Global timer.
 *
 * A timer must be started with `start(timerId, timeInMillis)`
 * Attaching to it is via Backbone.Event interface:
 * > App.Timer.on(timerId, function[, scope]);
 */
App.Timer = _.extend(
  {
    // Timer list
    timers: {},

    /**
     * Starts timer `timer` to run each `time` milliseconds.
     *
     * @param string timer
     * @param int time
     *
     * @return App.Timer for chaining
     */
    start(timer, time) {
      if (this.started(timer)) {
        return null;
      }

      this.timers[timer] = {
        time,
      };

      this.startCount(timer);

      return this;
    },

    started(timer) {
      return typeof this.timers[timer] !== 'undefined';
    },

    startCount(timer) {
      const self = this;
      this.timers[timer].timeoutId = window.setTimeout(function () {
        self.startCount(timer);
        self.trigger(timer);
      }, this.timers[timer].time);
    },

    reset(timer) {
      if (this.started(timer)) {
        const time = this.timers[timer].time;
        this.cancel(timer);
        this.start(timer, time);
      }
    },

    /**
     * Cancels the given timer.
     *
     * Note, no event callbacks are removed. They must be removed with `App.Timer.off(timer);`
     * This means that if a timer is set some time later, all callbacks attached previously will run.
     *
     * @param string timer
     */
    cancel(timer) {
      if (this.started(timer)) {
        window.clearTimeout(this.timers[timer].timeoutId);
        delete this.timers[timer];
      }
    },
  },
  Backbone.Events,
);

window.App = App;
