import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class QuestionMark extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-question-mark', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 16 16"
      >
        <path
          d="M8,2 C11.3137085,2 14,4.6862915 14,8 C14,11.3137085 11.3137085,14 8,14 C4.6862915,14 2,11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 Z M8,10 C7.44771525,10 7,10.4477153 7,11 C7,11.5522847 7.44771525,12 8,12 C8.55228475,12 9,11.5522847 9,11 C9,10.4477153 8.55228475,10 8,10 Z M8.058,4 C6.536,4 5.535,4.642 5.32,5.88 L5.295,6.053 L6.786,6.218 C6.841,5.721 7.163,5.5 8.058,5.5 C9.1,5.5 9.372,5.794 9.372,6.135 C9.372,6.51358333 9.23419444,6.735875 8.77526273,6.90585938 L8.642,6.951 C7.675,7.253 7.206,8.033 7.294,9.073 L7.316,9.259 L8.8,9.046 C8.7433,8.6491 8.79676,8.50006 9.012139,8.410798 L9.09,8.383 C10.287,8.009 10.872,7.199 10.872,6.135 C10.872,4.992 9.957,4 8.058,4 Z"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(QuestionMark);
