import React from 'react';
import { History } from 'history';
import moment from 'common/moment';
import _ from 'common/underscore';
import Wahanda from 'common/wahanda';
import * as pathToRegexp from 'path-to-regexp';
import { ClientRetentionReportAnalytics } from 'common/analytics';
import { CLIENT_ROUTES } from 'components/reports/newSalesReports/SalesReportContainer/reportRoutes';
import { PrintButton } from 'components/shared/PrintButton';
import { CHANNEL } from 'components/reports/newSalesReports/BookingChannelFilter';
import { allEmployeesOptionValue } from 'components/reports/newSalesReports/EmployeeFilter';
import BigSelectDropdown from 'components/reports/BigSelectDropdown';
import Loader from 'components/common/Loader';
import { Notice } from 'components/common/Notice';
import { GoTo, Direction } from 'components/common/GoTo';
import VenueInfoForPrint from 'components/reports/POSReports/VenueInfoForPrint/container';
import Filters, { PossibleFilters } from 'components/reports/newSalesReports/common/Filters/index';
import TimeStamp from '../../newSalesReports/common/TimeStamp';
import DISPLAY from '../../newSalesReports/displayStateEnum';
import { CLIENT_REPORT_DROPDOWN_VALUES, TIME_PERIOD_FILTERS } from '../timePeriodFilters';
import ClientRetentionGraph from './ClientRetentionGraph';
import ClientRetentionTable from './ClientRetentionTable';
import style from './style.scss';

interface MonthlyClientRetention {
  month: string;
  firstTimeClients: number;
  repeatClients: number;
  totalClients: number;
  walkInClients: number;
  retention: number;
}

export interface ReportData {
  months: MonthlyClientRetention[];
  firstTimeClients: number;
  repeatClients: number;
  totalClients: number;
  walkInClients: number;
  retention: number;
}

export interface UrlParams {
  venueId: string;
  timePeriod: string;
  selectedChannel: string;
  selectedEmployeeId: string;
}

interface RequestData {
  fromDate?: string;
  toDate?: string;
  selectedChannel: string;
  selectedEmployeeId: number;
}

export interface Props {
  displayState: symbol;
  reportData?: ReportData;
  actions: {
    requestRettentionReportDataAction: (props: RequestData) => void;
  };
  match: {
    params: UrlParams;
  };
  history: History;
}

const LANG = Wahanda.lang.reports.clientReports.clientRetention;

export default class ClientRetentionReport extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onDatePickerChange = this.onDatePickerChange.bind(this);
    this.onReportDropdownChange = this.onReportDropdownChange.bind(this);
    this.requestData = this.requestData.bind(this);
    this.getQueryParams = this.getQueryParams.bind(this);
    this.getTimePeriodValues = this.getTimePeriodValues.bind(this);
  }

  public componentDidMount() {
    ClientRetentionReportAnalytics.trackPageView();
    this.requestData();
  }

  public componentDidUpdate(prevProps: Props) {
    const { params } = this.props.match;
    if (!_.isEqual(params, prevProps.match.params)) {
      this.requestData();
    }
  }

  public render() {
    const dropDownProps = {
      data: [
        CLIENT_REPORT_DROPDOWN_VALUES.CLIENT_ACQUISITION,
        CLIENT_REPORT_DROPDOWN_VALUES.CLIENT_RETENTION,
      ],
      selected: CLIENT_REPORT_DROPDOWN_VALUES.CLIENT_RETENTION.value,
      onSelect: this.onReportDropdownChange,
    };

    const filterProps = {
      onDatePickerChange: this.onDatePickerChange,
      onFilterChange: this.onFilterChange,
      channel: { selectedChannel: this.getQueryParams().selectedChannel },
      employee: {
        selectedEmployeeId: this.getQueryParams().selectedEmployeeId,
      },
      onChannelChange: ClientRetentionReportAnalytics.trackChannelFilterChange,
      onEmployeeChange: ClientRetentionReportAnalytics.trackEmployeeFilterChange,
      onPeriodChange: ClientRetentionReportAnalytics.trackDatePeriodFilterChange,
      period: {
        selected: this.getQueryParams().timePeriod,
        data: [
          {
            ...TIME_PERIOD_FILTERS.LAST_TWELVE_MONTHS,
          },
          {
            ...TIME_PERIOD_FILTERS.LAST_YEAR,
          },
          {
            ...TIME_PERIOD_FILTERS.THIS_YEAR,
          },
          {
            ...TIME_PERIOD_FILTERS.ALL_TIME,
          },
        ],
      },
    };

    return (
      <div className={style.report}>
        <GoTo href="/" direction={Direction.backward} className={style.backLink}>
          {Wahanda.lang.reports.tabs.reports}
        </GoTo>
        <VenueInfoForPrint />
        <div className={style.actions}>
          <BigSelectDropdown {...dropDownProps} />
          <PrintButton />
        </div>
        <div className={style.reportBody}>
          <div className={style.filters}>
            {/* @ts-expect-error ts-migrate(2769) FIXME: Type '{ selected: number; data: ({ value: number; ... Remove this comment to see the full error message */}
            <Filters {...filterProps} />
            <TimeStamp date={moment()} />
          </div>
          <Notice className={style.message} type="info">
            {Wahanda.lang.reports.clientReports.dataExclamation}
          </Notice>
          <Notice className={style.explanationMessage} type="info">
            {LANG.infoBox.howRetentionWorks}
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={ClientRetentionReportAnalytics.trackClientRetentionHowWorksClick}
              href={LANG.infoBox.howReportWorksLink}
            >
              {LANG.infoBox.learnMore}
            </a>
          </Notice>
          {this.renderContent()}
        </div>
      </div>
    );
  }

  public onFilterChange(props: PossibleFilters) {
    const { venueId } = this.props.match.params;
    const { selectedChannel, selectedEmployeeId, timePeriod } = props;
    const toPath = pathToRegexp.compile(CLIENT_ROUTES.RETENTION_REPORT);
    this.props.history.push(
      toPath({
        venueId,
        selectedEmployeeId,
        selectedChannel,
        timePeriod,
      }),
    );
  }

  private onReportDropdownChange(selected: number) {
    const { push } = this.props.history;
    const { venueId } = this.getQueryParams();
    switch (selected) {
      case 1:
        push(
          pathToRegexp.compile(CLIENT_ROUTES.ACQUISITION_REPORT)({
            venueId,
            ...CLIENT_REPORT_DROPDOWN_VALUES.CLIENT_ACQUISITION.defaultFilters,
          }),
        );
        break;
      case 2:
        push(
          pathToRegexp.compile(CLIENT_ROUTES.RETENTION_REPORT)({
            venueId,
            ...CLIENT_REPORT_DROPDOWN_VALUES.CLIENT_RETENTION.defaultFilters,
          }),
        );
        break;
      default:
        break;
    }
  }

  private getQueryParams() {
    const defaults: UrlParams = _.defaults(this.props.match.params, {
      selectedChannel: CHANNEL.ALL,
      selectedEmployeeId: allEmployeesOptionValue,
      timePeriod: TIME_PERIOD_FILTERS.LAST_TWELVE_MONTHS.value,
    });
    return {
      venueId: defaults.venueId,
      timePeriod: Number(defaults.timePeriod),
      selectedEmployeeId: Number(defaults.selectedEmployeeId),
      selectedChannel: defaults.selectedChannel,
    };
  }

  private getTimePeriodValues(timePeriod: number) {
    switch (timePeriod) {
      case TIME_PERIOD_FILTERS.LAST_TWELVE_MONTHS.value:
        return TIME_PERIOD_FILTERS.LAST_TWELVE_MONTHS.range;
      case TIME_PERIOD_FILTERS.ALL_TIME.value:
        return TIME_PERIOD_FILTERS.ALL_TIME.range;
      case TIME_PERIOD_FILTERS.LAST_YEAR.value:
        return TIME_PERIOD_FILTERS.LAST_YEAR.range;
      case TIME_PERIOD_FILTERS.THIS_YEAR.value:
      default:
        return TIME_PERIOD_FILTERS.THIS_YEAR.range;
    }
  }

  private requestData() {
    const { selectedChannel, selectedEmployeeId, timePeriod } = this.getQueryParams();
    const { requestRettentionReportDataAction } = this.props.actions;
    requestRettentionReportDataAction({
      selectedChannel,
      selectedEmployeeId,
      fromDate: this.getTimePeriodValues(timePeriod).fromDate,
      toDate: this.getTimePeriodValues(timePeriod).toDate,
    });
  }

  private onDatePickerChange(data) {
    ClientRetentionReportAnalytics.trackDateFilterChange(data.type);
  }

  private renderContent() {
    const { reportData, displayState } = this.props;
    if (reportData == null) {
      return null;
    }

    if (displayState === DISPLAY.WAITING) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <ClientRetentionGraph {...reportData} />
        <ClientRetentionTable {...reportData} />
      </React.Fragment>
    );
  }
}
