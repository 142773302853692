App.Views.Forms.MenuOffer2.Pricing.Dated.List.Rooms = BackboneEx.View.Menu.PricingListBase.extend({
  events: function () {},

  initialize: function () {
    this.applicableDays = this.options.applicableDays;
    this.rooms = this.options.rooms;
    this.pricingRuleId = this.options.pricingRuleId;
  },

  render: function () {
    var $html = $(
      Wahanda.Template.renderTemplate(
        'menu-offer-dated-pricing-list-rooms-item',
        this.getTemplateVars(),
        {
          priceTpl: Wahanda.Template.get('sku-price-template'),
        },
      ),
    );
    this.$el.replaceWith($html);
    this.setElement($html);
  },

  getTemplateVars: function () {
    var self = this;
    var values = {
      weekdays: this.getDaysHtml(),
      editable: Wahanda.Permissions.editMenu(),
      rooms: [],
    };
    var roomTypes = this.options.roomTypes;
    var rooms = this.rooms;

    roomTypes.each(function (rType) {
      var setData = _.find(rooms, function (r) {
        return r.roomTypeId === rType.id;
      });
      if (setData) {
        var room = {
          roomName: rType.get('name'),
          fullPrice: Wahanda.Currency.getFormattedOrEmpty(setData.fullPriceAmount),
          discountPrice: Wahanda.Currency.getFormattedOrEmpty(setData.discountPriceAmount),
        };
        values.rooms.push(room);
      }
    });

    return values;
  },

  getValues: function () {
    return {
      rooms: this.rooms,
      applicableDays: this.applicableDays,
    };
  },
});
