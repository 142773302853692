/* eslint-disable padding-line-between-statements */
export { icon as ComputerBookNow } from './dist/ComputerBookNow';
export { icon as ComputerBooking } from './dist/ComputerBooking';
export { icon as ComputerMedia } from './dist/ComputerMedia';
export { icon as ComputerWidget } from './dist/ComputerWidget';
export { icon as DiscountTag } from './dist/DiscountTag';
export { icon as EnvelopeSmartphone } from './dist/EnvelopeSmartphone';
export { icon as LogoConnect } from './dist/LogoConnect';
export { icon as LogoTreatwell } from './dist/LogoTreatwell';
export { icon as OrdersList } from './dist/OrdersList';
export { icon as Products } from './dist/Products';
export { icon as Resources } from './dist/Resources';
export { icon as Rocket } from './dist/Rocket';
export { icon as Scissors } from './dist/Scissors';
export { icon as ServiceList } from './dist/ServiceList';
export { icon as SmartDiscount } from './dist/SmartDiscount';
export { icon as SuccessWarning } from './dist/SuccessWarning';
export { icon as Success } from './dist/Success';
export { icon as Supplier } from './dist/Supplier';
export { icon as TeamSetup } from './dist/TeamSetup';
export { icon as Warning } from './dist/Warning';
