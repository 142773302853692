/* global Backbone */
import BigNumber from 'bignumber.js';
import Wahanda from 'common/wahanda';

App.Routers.Header = Backbone.Router.extend({
  routes: {
    'venue/:id': 'openVenue',
    '': 'defaultVenue',
  },

  openVenue: function openVenue(id) {
    if (!new BigNumber(id).isEqualTo(App.getVenueId())) {
      App.trigger(Wahanda.Event.APP_VENUE_IS_CHANGING);
    }

    App.setVenue(id);
  },

  defaultVenue: function defaultVenue() {
    App.initVenue(null);
  },
});
