import React from 'react';
import uuid from 'uuid';
import { UnderlinedPriceInput } from 'components/common/UnderlinedPriceInput';
import UnderlinedText from 'components/common/UnderlinedText';
import { Checkbox } from 'components/common/Checkbox';
import style from './style.scss';

type PricingRowTwoDimensionalProps = {
  skus: {
    calculatedPriceAmount?: number;
    fullPriceAmount?: number;
    index: number;
    employeeCategoryName: string;
  }[];
  indexArray?: number[];
  isPackagePricingCustom: boolean;
  visible: boolean;
  actions: {
    offerDialogToggleSkuAction: (...args: any[]) => any;
    offerDialogUpdateFullPriceAmountAction: (...args: any[]) => any;
  };
  nameReadOnly: string;
};
const PricingRowTwoDimensional: React.SFC<PricingRowTwoDimensionalProps> = (props) => {
  const { nameReadOnly, skus, visible, actions, indexArray, isPackagePricingCustom } = props;
  const { offerDialogToggleSkuAction, offerDialogUpdateFullPriceAmountAction } = actions;
  const uniqueCheckboxId = uuid.v4();
  return (
    <li className={style.container}>
      <div className={style.header}>
        <Checkbox
          name={uniqueCheckboxId}
          checked={visible}
          onChange={() => offerDialogToggleSkuAction({ indexArray, visible: !visible })}
        />
        <UnderlinedText text={nameReadOnly} />
      </div>
      <ul>
        {skus.map((sku, key) => {
          const { index, fullPriceAmount, calculatedPriceAmount, employeeCategoryName } = sku;
          const unique = uuid.v4();
          /*
           *  We may as well use the UUID for the 'key' attribute as
           *  well, but for now let's instead consider it as an
           *  alternative for the future
           */
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={key} className={style.row}>
              <UnderlinedText text={employeeCategoryName} />
              {visible && (
                <UnderlinedPriceInput
                  // @ts-expect-error ts-migrate(2322) FIXME: Property 'required' does not exist on type 'Intrin... Remove this comment to see the full error message
                  required
                  name={unique}
                  id={unique}
                  controlledComponent
                  disabled={!isPackagePricingCustom}
                  value={isPackagePricingCustom ? fullPriceAmount : calculatedPriceAmount}
                  onChange={(newAmount) => {
                    offerDialogUpdateFullPriceAmountAction({
                      index,
                      fullPriceAmount: newAmount,
                    });
                  }}
                />
              )}
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default PricingRowTwoDimensional;
