import { get } from 'mori';
import { appState } from 'state';

const DATE_KEY = 'reports/transactions/date';

export function getHash() {
  const date = get(appState.deref(), DATE_KEY);
  const hash = App.config.currentVenueHash();

  return `${hash}/transactions/${date}`;
}

export function setHash(trigger) {
  App.mainRouter.navigate(getHash(), {
    trigger,
  });
}
