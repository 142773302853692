/**
 * Clients main view.
 */
/* global BackboneEx */
/* eslint-disable func-names, no-underscore-dangle */
import { trackEvent, enableRecording } from 'common/analytics';
import apiUrl from 'common/api-url';
import Cookie from 'js-cookie';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import downloadFile from 'services/downloadFile';
import { NavigationAnalytics, L1Page } from 'common/tracking/navigation';

App.Views.Clients = BackboneEx.View.Main.extend({
  events: {
    'click tr.consumer': 'onClickConsumer',
    'click .a-new': 'onClickNewConsumer',
    'click .a-import': 'onImportStart',
    'click .a-mass-email': 'onMassEmail',
    'click .a-bulk-delete': 'onBulkDelete',
    'click .a-open-filter': '_showFilter',
    'click .filter .title .icon': '_closeFilter',
    'click .a-reset-filter': '_resetFilter',
    'click .a-reset-all': 'clickResetFilter',
    'click .no-data .link': 'clearFilter',
    'click .a-submit-form': 'submitForm',
    'click .filter-option label': 'onClickFilterOption',
    'click .client-list-actions .js-action': 'onActionClick',
    'click .js-export-client-list': 'clientExportClick',
  },

  limit: 200,
  includes: ['last-visit'],
  fetchSensitivity: 700,
  // How many times this search was "resumed" by fetching next result set. 0 means "first page".
  currentPage: 0,
  // Cearch results container
  $results: null,
  // Loading indicator
  $fetchIndicator: null,

  resultsHeight: null,
  searchHasMoreResults: false,
  searching: false,
  // XHR request for the last search
  searchRequest: null,
  // Was the last search executed with no search text, returning full client list?
  lastFetchedFullClientList: false,
  lastSearchOptions: null,
  activeFilters: {},
  filterOptions: {},
  scrollPositionToApply: null,
  // sorting options
  sortField: 'full-name',
  sortOrder: 'ASC',
  sortCookieId: 'clients:list-sort',
  defaultFilters: {},

  initialize() {
    enableRecording();
    NavigationAnalytics.trackPageView(L1Page.CLIENTS_LIST);

    const self = this;
    App.headerView.setModule('clients');
    this.$results = this.$('.client-list-body');

    this.collection = new App.Collections.Customers();
    this.collection.setFilters({
      limit: this.limit,
    });

    this.$results.scrollNotify({
      scroll(event, data) {
        self.onScrollChanged(data);
      },
    });

    $(window).resize(function () {
      self.updateDimensions();
      self.toggleTick();
    });

    this.$fetchIndicator = $(Wahanda.Template.renderTemplate('consumer-list-loading-more'));

    // Set sorting options
    this._setSortingFromCookie();
    this._setupListeners();
    this._setupFilters();
    this.setValidation();

    this.$('#client-search').searchTermChange({
      $clear: this.$('.clear-search'),
      search(event, text) {
        trackEvent('clients-list', 'update', 'search', text);

        self.onSearchFieldChange(text);
      },
      stop() {
        self.onSearchFieldCleared();
      },
    });
    this.$('.client-list-head').dynamicSorter({
      field: this.sortField,
      order: this.sortOrder,
      change(event, data) {
        trackEvent('clients-list', 'edit', 'order', data);

        self.onSortingChange(data);
      },
    });
  },

  renderClientsExportButton: function renderClientsExportButton() {
    this.$('.js-export-client-list').wToggle(Wahanda.Permissions.canExportClientList());
  },

  setValidation() {
    const self = this;

    const validations = {
      submitHandler() {
        self._submitFilter();
      },
      invalidHandler() {
        const filterName = self.$('.filter:visible').data('name');
        trackEvent('clients-list', 'validation', filterName);
      },
      focusInvalid: true,
      ...Wahanda.Validate.Renderers.topTooltip,
    };

    this.$('form').each(function () {
      $(this).validate(validations);
    });
  },

  disableEmailButton() {
    const html = $('.email-button-tooltip').html();
    this.$('.a-mass-email').qtip({
      content: html,
      style: {
        classes: 'dialog-tooltip',
        width: '310px',
      },
      position: {
        my: 'bottom center',
        at: 'top center',
      },
      show: {
        event: false,
        ready: true,
      },
      hide: {
        event: 'click',
        fixed: true,
      },
    });
  },

  renderUpgradeOverlay() {
    this.$('.upgrade-overlay').show();
  },

  _setSortingFromCookie() {
    let cookie = Cookie.get(this.sortCookieId);
    if (cookie) {
      try {
        cookie = cookie.split(';');
        if (Array.isArray(cookie) && cookie.length === 2) {
          this.sortField = cookie[0];
          this.sortOrder = cookie[1];
        }
      } catch (e) {} // eslint-disable-line no-empty
    }
  },

  submitForm(event) {
    const $form = $(event.currentTarget).parents('form');
    $form.submit();
  },

  _setupListeners() {
    const self = this;
    const E = Wahanda.Event;
    App.on(
      [E.CUSTOMER_SAVED, E.CUSTOMERS_IMPORT_SUCCESS, E.CUSTOMERS_BULK_DELETE].join(' '),
      function () {
        self.refreshAfterAdd();
      },
    )
      .on(E.CUSTOMER_DIALOG_OPENED, function () {
        self.$el.addClass('printable-hide');
      })
      .on(E.CUSTOMER_DIALOG_CLOSED, function () {
        self.$el.removeClass('printable-hide');
      })
      .on(E.CUSTOMER_ARCHIVED, function (model) {
        self.removeCustomerFromTable(model);
      });
  },
  _showFilter(event) {
    const $filter = $(event.currentTarget).siblings('.filter');
    this._closeFilter();
    $filter.show();
  },

  _getLastVisitFilterOptions(daysNumbers, filterOption) {
    const currentDate = Wahanda.Date.createVenueDate();
    const pastDate = Wahanda.Date.addDaysToDate(currentDate, daysNumbers[0] * -1);
    let lastVisitOptions = {};
    if (filterOption === 'more') {
      lastVisitOptions = {
        lastVisitDateTo: Wahanda.Date.toApiString(pastDate),
      };
    } else if (filterOption === 'exactly') {
      lastVisitOptions = {
        lastVisitDateFrom: Wahanda.Date.toApiString(pastDate),
        lastVisitDateTo: Wahanda.Date.toApiString(pastDate),
      };
    } else {
      lastVisitOptions = {
        lastVisitDateFrom: Wahanda.Date.toApiString(pastDate),
        lastVisitDateTo: Wahanda.Date.toApiString(currentDate),
      };
    }

    return lastVisitOptions;
  },

  _getOptinFilterOptions(filterOption) {
    return {
      sendMassEmails: filterOption,
    };
  },

  _getBookingCountFilterOptions(bookingCounts, filterOption) {
    let bookingCountOptions = {};
    if (filterOption === 'more') {
      bookingCountOptions = {
        bookingCountFrom: bookingCounts[0],
      };
    } else if (filterOption === 'exactly') {
      bookingCountOptions = {
        bookingCountFrom: bookingCounts[0],
        bookingCountTo: bookingCounts[1],
      };
    } else {
      bookingCountOptions = {
        bookingCountTo: bookingCounts[0],
      };
    }

    return bookingCountOptions;
  },

  removeOptInoptions() {
    const self = this;
    const optInOptions = ['sendMassEmails'];
    optInOptions.forEach((optInOption) => delete self.filterOptions[optInOption]);
  },

  removeBookingCountOptions() {
    const self = this;
    const bookingCountOptions = ['bookingCountFrom', 'bookingCountTo'];
    bookingCountOptions.forEach(
      (bookingCountOption) => delete self.filterOptions[bookingCountOption],
    );
  },

  removeLastVisitOptions() {
    const self = this;
    const lastVisitOptions = ['lastVisitDateFrom', 'lastVisitDateTo'];
    lastVisitOptions.forEach((lastVisitOption) => delete self.filterOptions[lastVisitOption]);
  },

  _submitFilter() {
    const currentFilter = this.$('.filter:visible');
    const filterName = currentFilter.data('name');
    this._resetFilter(filterName, { reset: true });
    this.collection.reset();

    const filterOption = currentFilter.find('.filter-option input:checked').val();

    const filterValues = [];
    currentFilter.find('.filter-value input:visible').each(function (i) {
      filterValues[i] = $(this).val();
    });

    let filterOptions = {};

    switch (filterName) {
      case 'lastVisit':
        filterOptions = this._getLastVisitFilterOptions(filterValues, filterOption);
        trackEvent(
          'clients-list',
          'edit',
          `lastVisit-filter`,
          Object.keys(filterOptions).join(', '),
        );
        break;
      case 'bookingCount':
        filterOptions = this._getBookingCountFilterOptions(filterValues, filterOption);
        trackEvent(
          'clients-list',
          'edit',
          `bookingCount-filter`,
          Object.keys(filterOptions).join(', '),
        );
        break;
      case 'optIn':
        trackEvent('clients-list', 'edit', `optIn-filter`, filterOption);
        filterOptions = this._getOptinFilterOptions(filterOption);
        break;
      default:
        filterOptions = {};
    }
    Object.assign(this.filterOptions, filterOptions);

    this.collection.setFilters({
      searchText: $('#client-search').val(),
      ...this.filterOptions,
      ...this.getSorting(),
      limit: this.limit,
      include: this.includes,
    });

    this._initiateSearch();
    this._closeFilter();

    this.activeFilters[filterName] = true;
    App.trigger(Wahanda.Event.CONSUMER_LIST_FILTER_APPLIED, {
      filter: filterName,
    });
  },

  _resetFilter(filter, nosearch) {
    this.currentPage = 0;
    const filterName =
      filter !== 'lastVisit' || filter !== 'bookingCount'
        ? this.$('.filter:visible').data('name')
        : filter;
    this.collection.reset();

    switch (filterName) {
      case 'lastVisit':
        this.removeLastVisitOptions();
        break;
      case 'bookingCount':
        this.removeBookingCountOptions();
        break;
      case 'optIn':
        this.removeOptInoptions();
        break;
      default:
        this.filterOptions = {};
    }

    this.collection.setFilters({
      searchText: $('#client-search').val(),
      ...this.getSorting(),
      ...this.filterOptions,
      limit: this.limit,
      include: this.includes,
    });

    if (nosearch && nosearch.reset) {
      return;
    }

    this._initiateSearch();
    this._closeFilter();

    this.$('.filter .filter-value input').val(this.defaultFilters[filterName].defaultValue);
    this.$(
      `.filter .filter-option input[value="${this.defaultFilters[filterName].defaultOption}"]`,
    ).click();
    this.activeFilters[filterName] = false;
    App.trigger(Wahanda.Event.CONSUMER_LIST_FILTER_DROPPED, {
      filter: filterName,
    });
  },

  _initiateSearch() {
    const self = this;
    this.$results.loadmask();
    this.searching = true;
    this.searchRequest = this.collection.search({
      success(list, data) {
        self.renderResults(list, data);
      },
    });
  },

  _setupFilters() {
    const optInFilter = {
      filter: 'optIn',
      column: '.opt-in-column',
      className: 'a-filter-opt-in',
      text: {
        title: Wahanda.lang.clients.filters.optIn.title,
        filter: Wahanda.lang.clients.filters.optIn.filter,
        reset: Wahanda.lang.clients.filters.optIn.reset,
      },
      defaultValues: {
        defaultOption: 'true',
        defaultValue: '',
      },
      fieldName: 'opt-in',
      options: [
        {
          value: true,
          text: Wahanda.lang.clients.filters.optIn.true,
          checked: true,
        },
        {
          value: false,
          text: Wahanda.lang.clients.filters.optIn.false,
        },
      ],
    };

    const lastVisitFilter = {
      filter: 'lastVisit',
      className: 'a-filter-visit-date',
      text: Wahanda.lang.clients.filters.lastVisit,
      column: '.visit-column',
      fieldName: 'last-visit-date',
      input1: 'days',
      defaultValues: {
        defaultOption: 'less',
        defaultValue: '30',
      },
      options: [
        {
          value: 'less',
          text: Wahanda.lang.clients.filters.lastVisit.less,
          checked: true,
        },
        {
          value: 'exactly',
          text: Wahanda.lang.clients.filters.lastVisit.exactly,
        },
        {
          value: 'more',
          text: Wahanda.lang.clients.filters.lastVisit.more,
        },
      ],
    };
    const bookingCountFilter = {
      filter: 'bookingCount',
      className: 'a-filter-booking-count',
      text: Wahanda.lang.clients.filters.bookingCount,
      column: '.bookings-column',
      fieldName: 'booking-item-count',
      input1: 'bookings',
      input2: 'bookings2',
      range: true,
      defaultValues: {
        defaultOption: 'less',
        defaultValue: '',
      },
      options: [
        {
          value: 'less',
          text: Wahanda.lang.clients.filters.bookingCount.less,
          checked: true,
        },
        {
          value: 'exactly',
          text: Wahanda.lang.clients.filters.bookingCount.exactly,
        },
        {
          value: 'more',
          text: Wahanda.lang.clients.filters.bookingCount.more,
        },
      ],
    };
    this._setupFilter(lastVisitFilter);
    this._setupFilter(bookingCountFilter);
    this._setupFilter(optInFilter);

    const self = this;
    const $addButton = $('.js-action');
    $('body').on('click keydown', function (event) {
      const $openFilter = $('.filter:visible');
      const $filterIcon = $openFilter.siblings('.a-open-filter').find('.icon');
      if (event.type === 'click') {
        if (
          !$openFilter.is(event.target) &&
          !$filterIcon.is(event.target) &&
          $openFilter.has(event.target).length === 0
        ) {
          self._closeFilter();
        }
        if (
          !$addButton.is(event.target) &&
          !$addButton.is(event.target) &&
          $addButton.has(event.target).length === 0
        ) {
          self._closeTooltip();
        }
      } else if (event.type === 'keydown' && event.keyCode === 27) {
        self._closeFilter();
        self._closeTooltip();
      }
    });
  },

  _setupFilter(filter) {
    const filterName = filter.filter;
    const column = $('.client-list-head').find(filter.column);
    column.append(Wahanda.Template.renderTemplate('consumer-filter', filter));
    this.defaultFilters[filterName] = filter.defaultValues;
  },

  _closeFilter() {
    const $filters = $('.filter');
    $filters.hide();
  },

  render() {
    this.$('.client-hidden').toggle(true);

    const hasAccess = Wahanda.Permissions.viewClientList();
    this.$el.toggleClass('no-permission', !hasAccess);
    if (!hasAccess) {
      return;
    }

    this.search({
      searchText: '',
    });
    this.updateDimensions();
    this.renderClientsExportButton();
    if (this.shouldStartImport()) {
      const clientsUrl = Wahanda.Url.getFullUrl('clients').replace('?startImport=true', '');
      window.history.replaceState({}, document.title, clientsUrl);
      this.onImportStart();
    }
  },

  clientExportClick: function clientExportClick() {
    const self = this;
    const lang = Wahanda.lang.clients;
    const venueName = App.config.venueName();
    const todayDate = moment().formatDateDefault();

    const fileName = `${venueName} ${lang.exportsFileName} ${todayDate}.csv`;

    self.$el.loadmask();

    downloadFile({
      url: apiUrl('CLIENTS_EXPORT'),
      fileName,
      type: 'text/csv',
    }).finally(() => self.$el.unloadmask());

    trackEvent('clients-list', 'click', 'export');
  },

  onActionClick() {
    const $tooltip = $('.tooltip');
    $tooltip.show();
  },

  _closeTooltip() {
    const $tooltip = $('.tooltip');
    $tooltip.hide();
  },

  onClickFilterOption(event) {
    let $option;
    const $options = $('.filter-option label');
    if ($(event.target).parents('label').length > 0) {
      $option = $(event.target).parents('label');
    } else {
      $option = $(event.target);
    }
    const filter = $option.parents('.filter');
    if ($option.find('input').val() === 'exactly' && filter.find('.filters .values').length > 0) {
      filter.find('.filters .filter-value').hide();
      filter.find('.filters .filter-value.values').show();
    } else {
      filter.find('.filters .filter-value').show();
      filter.find('.filters .filter-value.values').hide();
    }

    $options.removeClass('selected');
    $option.addClass('selected');
  },

  search(options) {
    this.searching = true;
    this.currentPage = 0;
    this.$results.loadmask();
    // Clearing last content
    this._startScroller();

    this.lastFetchedFullClientList = !options.searchText;
    this.lastSearchOptions = options;

    this.collection.reset();
    this.collection.setFilters({
      ...options,
      ...this.lastSearchOptions,
      ...this.filterOptions,
      ...this.getSorting(),
      limit: this.limit,
      include: this.includes,
    });

    this._initiateSearch();
  },

  fetchMoreClients() {
    const self = this;
    this.searching = true;
    this.currentPage += 1;

    this.addFetchingMoreIndicator();

    this.searchRequest = this.collection.getSearchNextPage({
      success(list, data) {
        self.renderResults(list, data);
      },
    });
  },

  refreshAfterAdd() {
    if (this.lastFetchedFullClientList) {
      // No filtering applied
      if (this.currentPage === 0) {
        this.scrollPositionToApply = this.$results.scrollTop();
      }
      this.search(this.lastSearchOptions);
    } else {
      this.clearFilter();
    }
  },

  clickResetFilter() {
    trackEvent('clients-list', 'click', 'reset-all-filters');

    this.clearFilter();
  },

  /**
   * Clears user free-text search and refreshes ticons-filterhe list.
   */
  clearFilter() {
    this.$('#client-search').val('');
    this.filterOptions = {};
    this.activeFilters = {};
    this.search({});
  },

  addFetchingMoreIndicator() {
    this.$results.find('tbody').append(this.$fetchIndicator);
  },

  updateFilterIcons() {
    const filterOn = 'icons-filter-on';
    const filterOff = 'icons-filter';
    const $icons = $('.icon[data-filter]');
    $icons.removeClass(filterOn);
    $icons.addClass(filterOff);
    if (!this.activeFilters || Object.keys(this.activeFilters).length === 0) {
      return;
    }
    // eslint-disable-next-line no-undef
    _.each(this.activeFilters, function (active, filter) {
      if (active) {
        const $icon = $(`.icon[data-filter="${filter}"]`);
        $icon.addClass(filterOn);
        $icon.removeClass(filterOff);
      }
    });
  },

  isMultiLocale() {
    const channelSupportedLocales = App.config.getChannelSupportedLocales();

    if (!channelSupportedLocales) {
      return false;
    }

    const numberOfSupportedLocales = Object.keys(channelSupportedLocales).length;

    return numberOfSupportedLocales > 1;
  },

  renderResults(results, data) {
    const hasResults = results.length > 0;
    const $body = this.$results.find('tbody');
    const $resultCount = this.$('.client-list-count');
    const isFirstPage = this.currentPage === 0;
    const $massEmailButton = this.$('.mass-email-button');
    const $bulkDeleteButton = this.$('.bulk-delete-button');
    const $downloadLastImportButton = this.$('.download-last-import-button');
    this.searchHasMoreResults = data.hasMoreResults;
    this.searching = false;
    if (isFirstPage) {
      this.totalCustomers = data.totalCustomers;
      this.filterResultCount = data.filterResultCount;
    }

    this.updateFilterIcons();
    if (isFirstPage) {
      // Clear previous results
      $body.empty();
    }

    if (!this.searchHasMoreResults) {
      this._stopScroller();
    }
    this.$('.client-list').toggleClass('empty', !hasResults && isFirstPage);

    // Remove loading indicator
    this.$fetchIndicator.remove();

    const viewContacts = Wahanda.Permissions.viewClientContactDetails();

    this.$('.client-list-head').find('.b-contact').wToggle(viewContacts);
    this.$('.client-list-head').find('.b-multi-locale').wToggle(this.isMultiLocale());

    $body.append(
      Wahanda.Template.renderTemplate('consumer-list-items', {
        list: this._prepareConsumersForTemplate(results),
        withContacts: viewContacts,
        withLocale: this.isMultiLocale(),
      }),
    );

    this.bindTooltips();

    $resultCount.html(
      Wahanda.Template.renderTemplate('consumer-list-result-count', {
        total: this.totalCustomers,
        filterCount: this.filterResultCount,
      }),
    );

    let showMassEmailButton;
    if (
      this.totalCustomers &&
      this.filterResultCount !== 0 &&
      App.isFeatureSupported('mass-mailing')
    ) {
      showMassEmailButton = true;
    }

    $massEmailButton.html(
      Wahanda.Template.renderTemplate('customer-mass-email-button-template', {
        resultsReturned: showMassEmailButton,
      }),
    );

    const showBulkDeleteButton = App.isMasquerading() && this.totalCustomers > 0;
    $bulkDeleteButton.html(
      Wahanda.Template.renderTemplate('customer-bulk-delete-button-template', {
        resultsReturned: showBulkDeleteButton,
      }),
    );

    $downloadLastImportButton.html(
      Wahanda.Template.renderTemplate('customer-download-last-import-template', {
        shouldAllowDownloadLastImport: App.isMasquerading(),
        venueId: App.getVenueId(),
      }),
    );

    this.$results.unloadmask().shadows();
    this.toggleTick();

    if (this.scrollPositionToApply) {
      this.$results.scrollTop(this.scrollPositionToApply);
      this.scrollPositionToApply = null;
    }

    this._triggerSearchResultEvent();
  },

  _prepareConsumersForTemplate(list) {
    const now = new Date();
    const result = [];
    for (let i = 0, len = list.length; i < len; i++) {
      const item = list[i].toJSON();
      if (item.locale) {
        let locale = Wahanda.lang.language[item.locale];
        if (!locale) {
          // eslint-disable-next-line no-undef
          const supportedLocales = _.invert(App.config.getChannelSupportedLocales());
          locale = supportedLocales[item.locale];
        }
        item.locale = locale;
      }

      if (item.lastVisit) {
        const visitDate = Wahanda.Date.createDate(item.lastVisit.visitDate);
        const dayDiff = Wahanda.Date.getDayDifference(now, visitDate);
        const visit = {
          date: Wahanda.Date.formatToDefaultDate(visitDate),
          daysAgo: this.getDistanceInWords(dayDiff),
        };
        item.visit = visit;

        delete item.lastVisit;
      }
      result.push(item);
    }
    return result;
  },

  getDistanceInWords(dayDiff) {
    if (dayDiff === 0) {
      return Wahanda.lang.date.today;
    }
    const template =
      Wahanda.lang.datetime.distance_in_words.x_days_ago[dayDiff === 1 ? 'one' : 'other'];
    return template.replace('{{count}}', dayDiff);
  },

  onScrollChanged(data) {
    if (!this.searchHasMoreResults || this.searching) {
      return;
    }
    const leftToScroll = data.maxScroll - (this.resultsHeight + data.scrollTop);
    if (this.fetchSensitivity > leftToScroll) {
      this.fetchMoreClients();
    }
  },

  updateDimensions() {
    this.resultsHeight = this.$results.height();
  },

  _stopScroller() {
    this.$results.scrollNotify('disable');
  },

  _startScroller() {
    this.$results.scrollNotify('enable');
  },

  openConsumerDetails(id) {
    const view = App.Views.Dialog.Consumer.createInstance(id);

    view.render();

    view.open();
  },

  openConsumerEditForm() {
    App.ES6.Initializers.CustomerFormDialog().render();
  },

  /**
   * Toggles a tick in the table head, if the list table is narrower than the header table.
   */
  toggleTick() {
    const $headTable = this.$('.client-list-head').find('table');
    const $listTable = this.$('.client-list-body').find('table');

    $headTable.toggleClass('has-tick', $headTable.width() > $listTable.width());
  },

  getSorting() {
    const sorting = {
      sortBy: this.sortField || 'full-name',
      sortOrder: this.sortOrder || 'ASC',
    };

    // Reverse order for last-visit-date
    if (sorting.sortBy === 'last-visit-date') {
      sorting.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
    }
    return sorting;
  },

  _triggerSearchResultEvent() {
    if (this.currentPage === 0) {
      // This is a genuine search event.
      App.trigger(Wahanda.Event.CONSUMER_LIST_SEARCHED, {
        filtered: !this.lastFetchedFullClientList,
      });
    } else {
      // This is a "next page loaded" event
      App.trigger(Wahanda.Event.CONSUMER_LIST_PAGINATED, {
        pageNumber: this.currentPage + 1,
      });
    }
  },

  getCurrentStateHash() {
    return this.getStateHash();
  },

  getStateHash() {
    return `venue/${App.getVenueId()}`;
  },

  removeCustomerFromTable(customerModel) {
    this.$('.client-list-body').find(`tr[data-id="${customerModel.id}"]`).remove();
  },

  // UI events

  onSearchFieldChange(text) {
    this._abortExistingSearch();
    this.search({ searchText: text });
  },

  onSearchFieldCleared() {
    this._abortExistingSearch();
    if (!this.lastFetchedFullClientList) {
      this.search({ searchText: null });
    }
  },

  _abortExistingSearch() {
    if (this.searchRequest) {
      this.searchRequest.abort();
      this.searchRequest = null;
    }
  },

  onSortingChange(data) {
    Cookie.set(this.sortCookieId, [data.field, data.order].join(';'), {
      expires: 365,
    });

    this.sortField = data.field;
    this.sortOrder = data.order;

    this.search(this.lastSearchOptions);
  },

  onClickConsumer(event) {
    trackEvent('clients-list', 'click', 'client-details');

    const venueCustomerId = $(event.currentTarget).data('id');
    this.openConsumerDetails(venueCustomerId);
  },

  onClickNewConsumer() {
    trackEvent('clients-list', 'click', 'add-new-client');

    this.openConsumerEditForm();
  },

  useNewCustomerImportFlow() {
    return !window.location.search.includes('oldimport=true');
  },

  shouldStartImport() {
    return window.location.search.includes('startImport=true');
  },

  onImportStart() {
    trackEvent('clients-list', 'click', 'add-new-client-from-file');
    if (this.useNewCustomerImportFlow()) {
      const refreshClientsList = () => {
        this.refreshAfterAdd();
      };
      App.ES6.Initializers.ClientsImport({
        refreshClientsList,
      }).render();
    } else {
      const formDialog = new App.Views.Forms.CustomerImport();
      formDialog.render();
      formDialog.open();
    }
  },

  onMassEmail() {
    trackEvent('clients-list', 'click', 'send-email');

    const editPermissions =
      Wahanda.Permissions.editClientContactDetails() &&
      Wahanda.Permissions.viewClientContactDetails();
    if (!editPermissions) {
      this.disableEmailButton();
    } else {
      const formDialog = new App.Views.Forms.Customer.MassEmail({
        filters: this.collection.filters,
      });
      formDialog.render();
      formDialog.open();
    }
  },

  onBulkDelete() {
    const self = this;

    App.ES6.Initializers.ClientsBulkDelete.render({
      onDeleteStart: () => self.$results.loadmask(),
      onDeleteComplete: () => App.trigger(Wahanda.Event.CUSTOMERS_BULK_DELETE),
    });
  },
  bindTooltips() {
    const opts = {
      position: {
        viewport: true,
        my: 'right center',
        at: 'left top',
      },
    };
    this.$el.tooltipize(opts);
  },
});
