import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import { Dashboard } from 'components/dashboard/container';
import { initialStatePopulation, resetStateToDefault } from 'components/dashboard/actions';
import { isFeatureSupported, isMasquerading } from 'common/extranet-helpers';
// This is our main store. It is reused for the same venue, until a venue change happens.
const store = storeBuilder();

function render(target, state, { tab }) {
  // Taking initial state extranet-settings living in the backbone model
  const initialState = {
    isMasquerading: isMasquerading(state),
    name: state.venue.name,
    logoImage: state.venue.logoImage,
    firstVenueImage: state.venue.primaryImage,
    venueId: state.venue.id,
    supplierId: state.venue.supplierId,
    bookingWidgetSupported: isFeatureSupported(state, 'useOnlineBookingWidget'),
    marketplaceSupported: isFeatureSupported(state, 'marketplace'),
    venue: {
      venueStatusType: state.venue.venueStatusType,
    },
    rating: state.venue.weightedRating,
    marketplaceLink: state.venue.marketplaceUri ? state.venue.marketplaceUri : null,
    widgetLink: state.venue.widgetUri ? state.venue.widgetUri : null,
    canRedeemEvouchers: !!App.config.canRedeemEvouchers(),
    canAccessSettings: !!Wahanda.Permissions.accessSettings(),
    canViewFinanceData: !!Wahanda.Permissions.viewFinanceData(),
    imageEditingEnabled: state.venue.imageEditingEnabled,
    isFetchingCustomerReviews: false,
  };
  // Populate the Redux store with the initial state
  store.dispatch(initialStatePopulation(initialState));
  ReactDOM.render(
    <Provider store={store}>
      <Dashboard currentTab={tab} />
    </Provider>,
    target,
  );
}

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
  store.dispatch(resetStateToDefault());
}

export const DashboardInitializer = { render, destroy };
