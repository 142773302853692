/* global jQuery */
import React from 'react';
import { withCursor, cursorValueHasChanged } from 'atom/cursors';
import { appState } from 'state';
import uuid from 'uuid';
import keycode from 'keycode';
import classnames from 'classnames';
import { isTextarea } from 'common/dom-tests';
import { isLastOpenDialog, addToStack, removeFromStack } from 'components/common/dialog-stack';
import { Draggability } from './dialog/Draggability';
import { DialogCloseWithChanges } from './dialog/DialogCloseWithChanges';
import Overlay, {
  hasOwnOverlay,
  pushDialogToOverlays,
  removeDialogFromOverlays,
} from './dialog/Overlay';
import TitleBar from './dialog/title-bar';

const DEFAULT_WIDTH = 500;
const ZINDEX_INCREMENT = 2;
const GENERAL_PADDING = 30;

function wasEventHandled(event) {
  const result = !!event.dialogCollectionHandled;
  if (!result) {
    // eslint-disable-next-line no-param-reassign
    event.dialogCollectionHandled = true;
  }
  return result;
}

function shouldCloseDialog(event, dialogKey) {
  return isLastOpenDialog(dialogKey) && !wasEventHandled(event);
}

// Get the max zIndex, based on all dialogs.
function getZIndex() {
  const dialogs = document.querySelectorAll('.ui-dialog');
  const childrenCount = dialogs.length;
  // 1000 is the header - we need a higher number than that.
  let maxIndex = 999;
  let current;
  let i;
  for (i = 0; i < childrenCount; i += 1) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type 'Element'.
    current = dialogs[i].style && dialogs[i].style.zIndex;
    if (current && current > maxIndex) {
      maxIndex = current;
    }
  }
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  return parseInt(maxIndex, 10) + ZINDEX_INCREMENT;
}

function adjustTop(top, newHeight, winHeight) {
  return top + newHeight > winHeight
    ? Math.max(winHeight - newHeight, 0) // Dialog would not fit if we kept the same top coord
    : top; // The dialog still fits into the screen
}

function calculateTop(top, newHeight, keepTop) {
  const winHeight = window.innerHeight;
  if (newHeight > winHeight) {
    return 0;
  }
  // By default, center the dialog in the screen
  return keepTop
    ? adjustTop(top, newHeight, winHeight)
    : Math.max(Math.round((winHeight - newHeight) / 2), 0);
}

function readPosition(node, newHeight, keepTopPositionWhenResizing) {
  const { left: x, top: y } = node.getBoundingClientRect();
  return {
    x,
    y: calculateTop(y, newHeight, keepTopPositionWhenResizing),
  };
}

/**
 * Get the dialog positioning styles which are based on the content height.
 *
 * @param {number} dialogHeight The dialog's element
 * @param {Object|null} position { x, y }
 * @param {number} zIndex The zIndex to return
 * @param {Object} props Props passed into the Dialog component
 *
 * @returns {Object} The CSS styles to set on the dialog directly
 */
function getStyles(
  dialogHeight = 0,
  position,
  zIndex,
  { calculateHeight, width, dialogContentMaxWidth },
) {
  /*
   *  TODO
   *  Setting 'min-content' has obseleted this?
   */
  const winHeight = window.innerHeight;
  const height =
    calculateHeight && dialogHeight ? Math.min(dialogHeight, winHeight - GENERAL_PADDING) : null;
  let margin;
  if (position) {
    if (position.x === null) {
      margin = '0 auto';
    } else {
      margin = '0';
    }
  } else {
    margin = 'auto';
  }
  return {
    width: width || DEFAULT_WIDTH,
    maxWidth: dialogContentMaxWidth,
    height,
    left: position ? position.x : 0,
    top: position ? position.y : 0,
    // This resets the margin so we are able to correctly drag the item
    margin,
    zIndex,
    cursor: 'default',
  };
}

const getHeight = ({ scrollHeight = 0 } = {}) => scrollHeight;

function getInitialTopPosition() {
  return {
    x: null,
    y: 20,
  };
}

/*
 *  This updates the jQuery max zIndex variable
 *  allowing both dialogs to correctly overlay one another
 */
function updateJqueryMaxZindex(zIndex) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ui' does not exist on type 'JQueryStatic... Remove this comment to see the full error message
  jQuery.ui.dialog.maxZ = Math.max(0, zIndex, jQuery.ui.dialog.maxZ) || zIndex;
}

// @ts-expect-error ts-migrate(2430) FIXME: Type '{}' is not assignable to type 'string'.
interface IReactDialogProps extends React.HTMLAttributes<Element> {
  title?: string | {};
  onClose?: (...args: any[]) => any;
  onShow?: (...args: any[]) => any;
  onHide?: (...args: any[]) => any;
  width?: number | string;
  dialogContentMinHeight?: number;
  dialogContentMaxHeight?: number;
  dialogContentMaxWidth?: number | string;
  dialogContentHeight?: number;
  classes?: {} | string;
  hideTitlebar?: boolean;
  calculateHeight?: boolean;
  keepTopPositionWhenResizing?: boolean;
  warnWhenClosingChangedForms?: boolean;
  textWhenClosingChangesForm?: string;
  footer?: {};
  initialPosition?: 'top';
  onPositionChange?: (...args: any[]) => any;
  restrictHeight?: boolean;
  newLook?: boolean;
  noContentPadding?: boolean;
  noContentTopBorders?: boolean;
  noOverflow?: boolean;
  height?: number;
  onConfirm?: (...args: any[]) => any;
  dataTest: string;
}

type ReactDialogState = {
  id?: any;
  dialogHeight?: number;
  zIndex?: number;
  position?: any;
  changeCaught?: any;
  ignoreKeyboardEvents?: boolean;
  contentMaxHeight?: number;
  dialogHeightWas?: any;
  contentMaxHeightWas?: any;
  hasConfirmCloseWithChangesDialog?: boolean;
  onConfirmCloseWithChanges?: () => void;
  hidden?: boolean;
};

/*
 *  To make it scrollable, provide ".dialog2--scrollable" as an additional class,
 *  and a "dialogContentMaxHeight" value (see <ExportDialog />)
 */
export default class ReactDialog extends React.Component<IReactDialogProps, ReactDialogState> {
  contentNode: any;

  contentWrapper: any;

  dialogNode: any;

  dialogRoot: any;

  forceUpdate: any;

  static defaultProps = {
    calculateHeight: true,
    warnWhenClosingChangedForms: true,
    restrictHeight: false,
    newLook: false,
    noContentPadding: false,
    noOverflow: false,
    onClose: () => {},
    onShow: () => {},
    onHide: () => {},
    onPositionChange: () => {},
    height: 0,
    onConfirm: () => {},
    dataTest: undefined,
  };

  state = {
    id: uuid.v1(),
    zIndex: getZIndex(),
    position: this.props.initialPosition === 'top' ? getInitialTopPosition() : null,
  };

  UNSAFE_componentWillMount() {
    const { id } = this.state;
    pushDialogToOverlays(appState, id);
  }

  componentDidMount() {
    const { id, zIndex } = this.state;
    const { warnWhenClosingChangedForms } = this.props;
    // It's too dangerous to disable this right now as
    // I haven't been able to look at exactly how it works
    // TODO: Come back and work out why we're setting state inside of didMount
    this.setState({
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0-1 arguments, but got 2.
      //eslint-disable-line
      dialogHeight: getHeight(this.contentWrapper, this.props),
    });
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
    window.addEventListener('resize', this.updateHeight);
    if (warnWhenClosingChangedForms) {
      this.dialogRoot.addEventListener('change', this.handleChange);
    }
    addToStack(id, this.close);
    appState.addWatch(
      `${id}-overlays`,
      withCursor(() => this.forceUpdate(), ['react-dialog-overlays'], cursorValueHasChanged),
    );
    updateJqueryMaxZindex(zIndex);
  }

  componentDidUpdate() {
    this.updateHeight();
  }

  componentWillUnmount() {
    const { id, zIndex } = this.state;
    const { warnWhenClosingChangedForms } = this.props;
    removeDialogFromOverlays(appState, id);
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
    window.removeEventListener('resize', this.updateHeight);
    if (warnWhenClosingChangedForms) {
      this.dialogRoot.removeEventListener('change', this.handleChange);
    }
    removeFromStack(id);
    appState.removeWatch(`${id}-overlays`);
    updateJqueryMaxZindex(zIndex - ZINDEX_INCREMENT);
  }

  onPositionChange = (position) => {
    const { onPositionChange } = this.props;
    this.setState({ position });
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onPositionChange();
  };

  getTitlebar() {
    return this.dialogRoot.querySelector('.ui-dialog-titlebar');
  }

  getFooter() {
    return this.dialogRoot.querySelector('.dialog2--footer');
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'changeCaught' does not exist on type '{ ... Remove this comment to see the full error message
  hasChanges = () => this.props.warnWhenClosingChangedForms && this.state.changeCaught;

  /**
   * Toggle keyboard events on/off.
   * Mostly called externally.
   *
   * @param {Boolean} ignore Should events be ignored
   *
   * @returns {void}
   */
  ignoreKeyboardEvents(ignore) {
    this.setState({
      ignoreKeyboardEvents: !!ignore,
    });
  }

  /**
   * Function which calculates the height of the contents of the dialog. It can be used to restrict
   * the dialog height going out of window bounds.
   *
   * @param {ReactComponent} dialogRef The dialog which's height to restrict.
   * @returns {Number} Max height in pixels
   */
  calculateContentMaxHeight() {
    const nodeHeight = (node) => node.getBoundingClientRect().height;
    const headHeight = nodeHeight(this.getTitlebar());
    const footer = this.getFooter();
    const footerHeight = footer ? nodeHeight(footer) : null;
    return window.innerHeight - headHeight - footerHeight - GENERAL_PADDING;
  }

  // Can be externally called to update after known changes in DOM
  updateHeight = () => {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'dialogHeight' does not exist on type '{ ... Remove this comment to see the full error message
      dialogHeight: dialogHeightWas,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'contentMaxHeight' does not exist on type... Remove this comment to see the full error message
      contentMaxHeight: contentMaxHeightWas,
      position,
    } = this.state;
    const { keepTopPositionWhenResizing, restrictHeight } = this.props;
    const dialogHeightNow = getHeight(this.contentWrapper); // , this.props);
    if (dialogHeightWas !== dialogHeightNow || !position) {
      this.setState({
        dialogHeight: dialogHeightNow,
        position: readPosition(this.dialogNode, dialogHeightNow, keepTopPositionWhenResizing),
      });
    }
    if (restrictHeight) {
      const contentMaxHeightNow = this.calculateContentMaxHeight();
      if (contentMaxHeightWas !== contentMaxHeightNow) {
        this.setState({
          contentMaxHeight: contentMaxHeightNow,
        });
      }
    }
  };

  close = (...args) => {
    const handleClose = () => {
      const { onClose } = this.props;
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onClose(...args);
    };
    if (this.hasChanges()) {
      this.setState({
        hasConfirmCloseWithChangesDialog: true,
        onConfirmCloseWithChanges: handleClose,
      });
    } else {
      handleClose();
    }
  };

  hide() {
    const { onHide } = this.props;
    this.setState({ hidden: true });
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onHide();
  }

  show() {
    const { onShow } = this.props;
    this.setState({ hidden: false });
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onShow();
  }

  handleChange = () => {
    this.setState({
      changeCaught: true,
    });
    // Don't need to listen for any other changes
    this.dialogRoot.removeEventListener('change', this.handleChange);
  };

  handleKeyDown = (event) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ignoreKeyboardEvents' does not exist on ... Remove this comment to see the full error message
    if (this.state.ignoreKeyboardEvents || isTextarea(event.target)) {
      // Allow all keyboard events in textareas
      // Or the events handling is disabled.
      return;
    }
    if (keycode(event) === 'esc' && shouldCloseDialog(event, this.state.id)) {
      this.close(event);
    }
  };

  handleKeyUp = (event) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ignoreKeyboardEvents' does not exist on ... Remove this comment to see the full error message
    if (this.state.ignoreKeyboardEvents || isTextarea(event.target)) {
      // Allow all keyboard events in textareas
      // Or the events handling is disabled.
      return;
    }
    if (keycode(event) === 'enter' && this.anyParentHasClass(event.target, 'react-dialog')) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onConfirm(event);
    }
  };

  // returns true if the element or one of its parents has the class classname
  anyParentHasClass(element, classname) {
    if (element.className && element.className.split(' ').indexOf(classname) >= 0) {
      return true;
    }
    return element.parentElement && this.anyParentHasClass(element.parentNode, classname);
  }

  refDialogRoot = (dialogRoot) => {
    this.dialogRoot = dialogRoot;
  };

  refDialogNode = (dialogNode) => {
    this.dialogNode = dialogNode;
  };

  refContentWrapper = (contentWrapper) => {
    this.contentWrapper = contentWrapper;
  };

  refContentNode = (contentNode) => {
    this.contentNode = contentNode;
  };

  renderTitleBar() {
    const { hideTitlebar, newLook } = this.props;
    if (!hideTitlebar) {
      const { title } = this.props;
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      return <TitleBar label={title} onClose={this.close} newLook={newLook} />;
    }
    return null;
  }

  renderOverlay() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hidden' does not exist on type '{ id: an... Remove this comment to see the full error message
    const { hidden, zIndex, id } = this.state;
    if (hasOwnOverlay(appState.deref(), id)) {
      return <Overlay zIndex={zIndex - 1} hidden={hidden} onClick={this.close} />;
    }
    return null;
  }

  renderConfirmCloseDialog() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasConfirmCloseWithChangesDialog' does n... Remove this comment to see the full error message
    const { hasConfirmCloseWithChangesDialog } = this.state;
    if (hasConfirmCloseWithChangesDialog) {
      const { textWhenClosingChangesForm } = this.props;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onConfirmCloseWithChanges' does not exis... Remove this comment to see the full error message
      const { onConfirmCloseWithChanges } = this.state;
      return (
        <DialogCloseWithChanges
          text={textWhenClosingChangesForm}
          onConfirm={onConfirmCloseWithChanges}
          onClose={() => {
            this.setState({
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'boolean | u... Remove this comment to see the full error message
              hasConfirmCloseWithChangesDialog: null,
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '(() => void... Remove this comment to see the full error message
              onConfirmCloseWithChanges: null,
            });
          }}
        />
      );
    }
    return null;
  }

  render() {
    const {
      warnWhenClosingChangedForms,
      children,
      classes,
      dialogContentMinHeight,
      dialogContentMaxHeight,
      dialogContentHeight,
      footer,
      restrictHeight,
      noContentPadding,
      noContentTopBorders,
      noOverflow,
      height,
    } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dialogHeight' does not exist on type '{ ... Remove this comment to see the full error message
    const { dialogHeight, hidden, zIndex, position, contentMaxHeight, changeCaught } = this.state;
    const overlay = this.renderOverlay();
    const titleBar = this.renderTitleBar();
    const confirmCloseDialog = this.renderConfirmCloseDialog();
    const dialogClassName = classnames('ui-dialog dialog2 react-dialog', classes, { hidden });
    const dialogContentClassName = classnames('ui-dialog-content', {
      'ui-dialog-content-no-overflow': noOverflow,
    });
    // @ts-expect-error ts-migrate(2345) FIXME: Property 'calculateHeight' is optional in type 'Re... Remove this comment to see the full error message
    const dialogStyle = getStyles(dialogHeight, position, zIndex, this.props);
    const contentWrapperStyle = {
      minHeight: dialogContentMinHeight,
      maxHeight: dialogContentMaxHeight,
      height: height !== 0 ? height : dialogContentHeight,
    };
    const contentStyle = {
      overflowY: restrictHeight ? 'auto' : null,
      boxSizing: restrictHeight ? 'border-box' : null,
      maxHeight: contentMaxHeight,
      padding: noContentPadding ? '0px' : null,
      borderTopLeftRadius: noContentTopBorders ? '0px' : null,
      borderTopRightRadius: noContentTopBorders ? '0px' : null,
    };
    let onChange;
    if (warnWhenClosingChangedForms && !changeCaught) {
      onChange = this.handleChange;
    }
    return (
      <div
        data-test={this.props.dataTest}
        onChange={onChange}
        ref={this.refDialogRoot}
        className="dialog-root"
      >
        {overlay}
        {confirmCloseDialog}
        <Draggability
          handle=".dialog-draggable"
          dataTest={this.props.dataTest}
          onPositionChange={this.onPositionChange}
        >
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message */}
          <div ref={this.refDialogNode} className={dialogClassName} style={dialogStyle}>
            <div
              ref={this.refContentWrapper}
              className="dialog-content-wrapper"
              data-test="dialog-content-wrapper"
              style={contentWrapperStyle}
            >
              {titleBar}
              <div
                ref={this.refContentNode}
                className={dialogContentClassName}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '"border-box... Remove this comment to see the full error message
                style={contentStyle}
              >
                {children}
              </div>
              {footer}
            </div>
          </div>
        </Draggability>
      </div>
    );
  }
}
