import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../store/actions';
import { getShiftEmployee } from '../store/selectors';
import { ShiftTimeOffModal as Component } from './ShiftTimeOffModal';

const mapStateToProps = ({ shifts }, { employeeId }) => ({
  employee: getShiftEmployee(shifts, { employeeId }),
  datePickerRange: shifts.datePickerRange,
  isSavingShift: shifts.isSavingShift,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ShiftTimeOffModal = connect(mapStateToProps, mapDispatchToProps)(Component);
