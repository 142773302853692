import Wahanda from 'common/wahanda';

const lang = Wahanda.lang.marketing.campaignSelection;

export const STATUS = {
  EXPIRED: 'EXPIRED',
  COMING_SOON: 'COMING_SOON',
  ACTIVE: 'ACTIVE',
};

export const PROMO_OFFERS_CAMPAIGN_ID = 'promotional-offers';

export const VENUE_OPENING_CAMPAIGN = 'venue-opening-campaign';

export const APP_FOR_YOUR_CLIENTS_CAMPAIGN_ID = 'app-for-your-clients';

export const GENERIC_MARKETING_CAMPAIGN_ID = 'marketing-campaign';

export const campaignList = [
  {
    id: GENERIC_MARKETING_CAMPAIGN_ID,
    name: lang.campaigns.topRated.name,
    description: lang.campaigns.topRated.description,
    status: STATUS.ACTIVE,
  },
  {
    id: PROMO_OFFERS_CAMPAIGN_ID,
    name: lang.campaigns.promotingOffers.name,
    description: lang.campaigns.promotingOffers.description,
    status: STATUS.ACTIVE,
  },
  {
    id: APP_FOR_YOUR_CLIENTS_CAMPAIGN_ID,
    name: lang.campaigns.clientApp.name,
    description: lang.campaigns.clientApp.description,
    status: STATUS.ACTIVE,
  },
];
