/* global _ */
import { trackEvent } from 'common/analytics';

/**
 * Settings.
 */

const pushNotifications = [
  'appointmentPushNotificationsEnabled',
  'employeeAppointmentReminderEnabled',
  'sendAppointmentPushNotificationCopyToOwner',
  'reviewPushNotificationsEnabled',
];

App.Views.Settings.NotificationsSettings = App.Views.Form.extend({
  events: {
    'click #notifications-settings-appointmentPushNotificationsEnabled':
      'toggleSendAppointmentPushNotificationCopyToOwner',
  },

  // eslint-disable-next-line no-useless-escape
  fieldPrefix: /^notifications\-settings\-/,

  initialize: function initialize() {
    const self = this;
    this.model.on('fetched', () => {
      self.render();
    });

    this.setValidation();

    if (/prevalidate=fulfillment/.test(document.location.href)) {
      this.prevalidate = true;
    }
  },

  /**
   * Renders the whole settings view.
   */
  render: function render() {
    const data = this.getModelData();
    this.$('#notifications-settings-address').val(data.address);
    this.$('#notifications-settings-secondEmailAddress').val(data.secondEmailAddress);

    pushNotifications.forEach((notification) => {
      this.$(`#notifications-settings-${notification}`).attr('checked', data[notification]);
    });

    this.toggleSendAppointmentPushNotificationCopyToOwner();

    this.$('.b-for-dated').wToggle(
      App.isFeatureSupported('dated-booking') && App.config.canSellDated(),
    );

    if (this.prevalidate) {
      this.prevalidate = false;
      this.$('form').validate().form();
      this.$('#notifications-settings-address').focus();
    }
  },

  /**
   * Handler clicking on "Save".
   *
   * @param Event event
   */
  save: function save() {
    trackEvent('venue-notifications', 'submit', 'save-venue-notifications');
    const self = this;
    const $saveButton = this.$('.save-action');
    const values = this.getFormValues();

    const fulfillmentCommunication = this.model.get('fulfillmentCommunication') || {};

    // eslint-disable-next-line no-restricted-syntax
    for (const name in values) {
      if (Object.prototype.hasOwnProperty.call(values, name)) {
        fulfillmentCommunication[name] = values[name];
      }
    }

    this.model.set('fulfillmentCommunication', fulfillmentCommunication, {
      silent: false,
    });

    this.disableForm();
    $saveButton.action('doing');
    this.model.setAttributesToSave(['fulfillmentCommunication']);
    this.model.setNextSaveRelations(['fulfillment-communication']);
    // Save the model and show actions to the user
    this.model.save(null, {
      success: function success() {
        $saveButton.action();
        self.enableForm();
        App.trigger(Wahanda.Event.SETTINGS_FULFILLMENT_COMMUNICATION_SAVED);
      },
      error: function error() {
        self.enableForm();
        $saveButton.action().errorTip(Wahanda.lang.shared.savingFailed);
        self.model.revert();
      },
    });
  },

  _modelValuesHaventChanged: function _modelValuesHaventChanged() {
    const values = this.getFormValues();
    const data = this.getModelData();

    return _.all(values, function allValues(value, name) {
      return data[name] == null
        ? // eslint-disable-next-line eqeqeq
          value == ''
        : Wahanda.Text.equals(data[name], value);
    });
  },

  setValidation: function setValidation() {
    const self = this;
    const validations = Wahanda.Validate.getValidations('defaults', {
      submitHandler: function submitHandler() {
        self.save();
      },
    });

    this.$('form').validate(validations);
  },

  getFormValues: function getFormValues() {
    const values = App.Views.Form.prototype.getFormValues.call(this);
    pushNotifications.forEach((notification) => {
      values[notification] = $(`#notifications-settings-${notification}`).is(':checked');
    });
    if (typeof values.allowCancellation !== 'undefined') {
      values.allowCancellation = values.allowCancellation === '1';
    }
    return values;
  },

  getModelData: function getModelData() {
    const fulfillmentCommunication = this.model.get('fulfillmentCommunication') || {};
    const {
      appointmentPushNotificationsEnabled,
      sendAppointmentPushNotificationCopyToOwner,
      reviewPushNotificationsEnabled,
      employeeAppointmentReminderEnabled,
    } = fulfillmentCommunication;

    return {
      address: fulfillmentCommunication.address || '',
      secondEmailAddress: fulfillmentCommunication.secondEmailAddress || '',
      appointmentPushNotificationsEnabled,
      sendAppointmentPushNotificationCopyToOwner,
      reviewPushNotificationsEnabled,
      employeeAppointmentReminderEnabled,
    };
  },

  toggleSendAppointmentPushNotificationCopyToOwner: function toggleSendAppointmentPushNotificationCopyToOwner() {
    const disabled = !this.getFormValues().appointmentPushNotificationsEnabled;
    $('#notifications-settings-sendAppointmentPushNotificationCopyToOwner').attr(
      'disabled',
      disabled ? 'disabled' : null,
    );
    $('#notifications-settings-sendAppointmentPushNotificationCopyToOwner')
      .closest('.input-row')
      .toggleClass('disabled', disabled);
  },

  toggleByCheckbox: function toggleByCheckbox(checkboxId, fields) {
    const $checkbox = this.$(checkboxId);

    if ($checkbox.is(':checked')) {
      _.each(fields, function eachFields(field) {
        $(field).enableFormElements();
      });
    } else {
      _.each(fields, function eachFields(field) {
        $(field).disableFormElements().removeClass('error');
      });
    }
  },
});
