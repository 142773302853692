import { connect } from 'react-redux';
import App from 'common/backbone-app';
import { SmsPreview as SmsPreviewComponent } from './SmsPreview';
import { PushNotificationPreview as PushNotificationPreviewComponent } from './PushNotificationPreview';

const mapStateToProps = () => ({
  contentChannel: App.domainChannelCode(),
  locale: App.config.attributes.venue.locale,
});

export const SmsPreview = connect(mapStateToProps)(SmsPreviewComponent);

export const PushNotificationPreview = connect(mapStateToProps)(PushNotificationPreviewComponent);
