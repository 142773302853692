import React from 'react';
import Wahanda from 'common/wahanda';
import { Rocket } from 'components/common/IconPicture';
import { ReviewsAnalytics } from '../tracking';
import style from './RankingBoost.scss';

const LANG = Wahanda.lang.dashboard.reviews.rankingBoost;

export interface Props {
  endDate?: string;
}

export const RankingBoost: React.FC<Props> = ({ endDate }) => {
  const handleLearnMoreLinkClicked = () => {
    ReviewsAnalytics.trackRankingBoostHelpClick();
  };
  return (
    <div className={style.container}>
      <Rocket className={style.icon} />
      <div className={style.message}>
        <h1 className={style.title}>{LANG.title}</h1>
        <p className={style.description}>
          {Wahanda.Template.render(LANG.description1, {
            endDate,
          })}
        </p>
        <p className={style.description}>
          {LANG.description2}{' '}
          <a
            data-test="learn-more-link"
            onClick={handleLearnMoreLinkClicked}
            href={LANG.learnMoreLink}
          >
            {LANG.learnMore}
          </a>
        </p>
      </div>
    </div>
  );
};
