import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AccountLanguageSelect as Component } from 'components/AccountLanguageSelect';
import storeBuilder from 'reduxStore/store';

const store = storeBuilder();

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

function render() {
  const container = Wahanda.Dialogs.getReactContainer();
  const close = () => {
    destroy(container);
  };
  ReactDOM.render(
    <Provider store={store}>
      <Component onClose={close} />
    </Provider>,
    container,
  );
}

export const AccountLanguageSelect = {
  render,
  destroy,
};
