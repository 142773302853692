import React from 'react';
import App from 'common/backbone-app';
import style from './style.scss';

type UnderlinedTextProps = {
  text?: string;
};
const UnderlinedText: React.SFC<UnderlinedTextProps> = (props) => {
  const { text } = props;
  if (!App.isProd) {
    console.warn('UnderlinedText component is deprecated. Use Input component with readOnly prop');
  }
  return <span className={style.underlinedText}>{text}</span>;
};

export default UnderlinedText;
