(function() {
    /**
     * Injects into the given View prototype logic to find a substitute offer/sku in case
     * the original one is missing.
     */
    BackboneEx.Mixin.View.asAppointmentServiceSelector = function(proto) {
        /**
         *
         * @param jQuery $select
         * @param MenuCollection menuCollection
         * @param int offerId (optional, will be taken from model)
         */
        proto._addMissingService = function($select, menuCollection, offerId) {
            var $option;
            var offerId = (offerId || this.model.get('offerId'));
            var $offer  = $select.find('option.offer[value="' + offerId + '"]');
            if (!$offer.length) {
                var offerModel = menuCollection.get('offersCollection').get(offerId);
                if (offerModel) {
                    // Add to optgroup
                    var groupId   = offerModel.get('groupId');
                    var $optGroup = $select.find('optgroup[data-group-id="' + groupId + '"]');
                    if (!$optGroup.length) {
                        // No optgroup exists. Create one.
                        var html = menuCollection.get('groupsCollection').get(groupId).toSelectOptions();
                        $optGroup = $(html);
                        $select.append($optGroup);
                    }

                    var $options = $( offerModel.toSelectOptions() );
                    $optGroup.append($options);
                    $offer = $options.first();
                } else {
                    // ERROR: offer in menu not found. Should be impossible.
                    return;
                }
            }

            var skuId = this.model.get('skuId');
            if (!skuId) {
                // No sku set. So select the offer.
                $option = $offer;
                $option.attr("data-duration", this.model.getDuration());
            }

            return $option;
        };

        proto._addMissingSku = function($select, $service, menuCollection, skuId){
            var skuId = (skuId || this.model.get('skuId'));
            if (skuId) {
                // Sku set. Find and select it.
                // Find sku in case of multi-sku offer
                var $sku = $select.find('option.sku[value="' + skuId + '"]');

                if (!$sku.length){
                    // Find sku in case of single-sku offer
                    var $sku = $service.find('option.offer[data-sku-id="' + skuId + '"]');
                }

                if (!$sku.length) {
                    // Su does not exist in the offer. It was deleted. Add it to the select.
                    var someOffer = menuCollection.get('offersCollection').at(0);
                    var template  = someOffer.templateSku;
                    var skuHtml   = Wahanda.Template.render(
                        template,
                        {
                            id: skuId,
                            name: this.model.get('skuName') || '',
                            duration: 0
                        }
                    );
                    $sku = $(skuHtml);
                    $select.append($sku);
                }
                $option = $sku;
                $option.attr("data-duration", this.model.getDuration());
            }

            return $option;
        };
    };
}());
