/**
 * The mass email model.
 *
 * Executing a GET request (fetch()) will fetch the count of recipients.
 * Executing a POST will send the emails.
 */
App.Models.MassEmail = BackboneEx.Model.Base.extend({
  // Save all set attributes
  attributesToSave: BackboneEx.Model.Base.getAllAttributesToSave,

  structure: {
    showVenueLogo: 'boolean',
  },

  urlTemplate: '/venue/{{venueId}}/customers-mass-email.json',
  filters: App.Models.Customer.getUrlFilters(),
  filterMap: App.Models.Customer.getUrlFilterMap(),

  hasActiveFilters: App.Models.Customer.getUrlFilterHasActiveFiltersFunction(),
});

BackboneEx.Mixin.extend(App.Models.MassEmail, BackboneEx.Mixin.Common.UrlFilter);
