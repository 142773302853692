import React from 'react';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';
import apiUrl from 'common/api-url';
import { Notice } from 'components/common/Notice';
import { Button } from 'components/common/Button';
import { DatePicker } from 'components/common/DatePicker';
import Dialog from 'components/common/react-dialog';
import { Select } from 'components/common/Select';
import Loader from 'components/common/Loader';
import { TableBody, TableRow, TableData, Table } from 'components/common/Table';
import { ExportsStateStatus } from './reducer';
import { TransactionExports, ExportType, ExportStatus, DATEV_EXPORT_FEATURE_FLAG } from './types';
import style from './style.scss';
import { DatePickerQuickFilters } from 'components/common/DatePickerQuickFilters';
import { BadgeRedesigned } from 'components/common/BadgeRedesigned';
import { TransactionsTracking } from './tracking';
import { ExportDialogDownloadIcon } from 'components/reports/ExportDialogRedesigned/ExportDialogDownloadIcon';
import ExportDialogReturnIcon from 'components/reports/ExportDialogRedesigned/ExportDialogReturnIcon';

interface FinishedExportRowProps {
  venueId: number;
  id: number;
  datestamp: string;
  filename: string;
  type: ExportType;
  status: ExportStatus;
}

const FinishedExportRow = (props: FinishedExportRowProps) => {
  const { venueId, id, datestamp, filename, type, status } = props;

  const isCompleted = status === ExportStatus.COMPLETED;
  return (
    <TableRow>
      <TableData className={style.row}>
        {status === ExportStatus.COMPLETED ? (
          <span>{filename}</span>
        ) : (
          <span data-test={`pos-export-report-inactive-link-${id}`}>{filename}</span>
        )}
      </TableData>
      <TableData className={style.row}>
        {isCompleted ? (
          <span>[{moment(datestamp).format('YYYY-MM-DD HH:mm:ss')}]</span>
        ) : (
          <BadgeRedesigned
            type="expose"
            text={Wahanda.lang.reports.transactions.exports.processing}
            isLoading
          />
        )}
      </TableData>
      <TableData className={style.row}>
        <span>{type}</span>
      </TableData>
      <TableData className={style.row}>
        <a
          data-test="pos-export-report-download"
          href={apiUrl('DOWNLOAD_POS_EXPORT', {
            venueId,
            type,
            id,
          })}
          download
        >
          <ExportDialogDownloadIcon />
        </a>
      </TableData>
    </TableRow>
  );
};

interface Props {
  venueId: number;
  onClose: () => void;
  status: ExportsStateStatus;
  exports?: TransactionExports[];
  canExportNF525: boolean;
  canExportDSFINVK: boolean;
  canExportGDPDU: boolean;
  actions: {
    getPosExports: (payload: { venueId: number }) => void;
    initPosExport: (payload: { type: ExportType; from: string; to: string }) => void;
  };
}

enum PageValues {
  setExportParams = 'SET_EXPORT_PARAMS',
  listOfReports = 'LIST_OF_REPORTS',
}
interface State {
  exportType: ExportType;
  from: moment.Moment;
  to: moment.Moment;
  page: PageValues;
}
const DIALOG_WIDTH_1 = 500;
const DIALOG_WIDTH_2 = 740;

export class ExportDialogRedesigned extends React.PureComponent<Props, State> {
  public state = {
    exportType: ExportType.TRANSACTIONS,
    from: moment().startOf('month'),
    to: moment(),
    page: PageValues.setExportParams,
  };

  public componentDidMount() {
    const { venueId } = this.props;
    this.props.actions.getPosExports({ venueId });
  }

  private onExportTypeChange = (exportType: ExportType) => {
    this.setState({ exportType });
  };

  private onFromDateChange = (start, end) => {
    if (!start && !end) {
      return;
    }
    this.setState({ from: start, to: end });
  };

  private isDayBlocked = (day: moment.Moment) => {
    return day.isAfter(moment());
  };

  private renderLoader = () => {
    const { exports, status } = this.props;
    if (status !== ExportsStateStatus.EXPORTS_PENDING) {
      return null;
    }
    if (exports == null) {
      return (
        <div className={style.loader}>
          <Loader />
        </div>
      );
    }
    return <Loader positionAbsolute />;
  };

  private renderExports = () => {
    const { exports, venueId } = this.props;
    return exports?.map((exp) => (
      <FinishedExportRow
        key={exp.lastModificationDate}
        venueId={venueId}
        datestamp={exp.lastModificationDate}
        filename={exp.filename}
        id={exp.id}
        type={exp.type}
        status={exp.status}
      />
    ));
  };

  private getDropdownValues = () => {
    const { canExportNF525, canExportDSFINVK, canExportGDPDU } = this.props;
    const exports = [
      {
        name: Wahanda.lang.reports.transactions.exports.typeCsvExport,
        value: ExportType.TRANSACTIONS,
      },
    ];

    if (Wahanda.Features.isEnabled(DATEV_EXPORT_FEATURE_FLAG)) {
      exports.push(
        {
          name: 'DATEV_SKR03',
          value: ExportType.DATEV_SKR03,
        },
        {
          name: 'DATEV_SKR04',
          value: ExportType.DATEV_SKR04,
        },
      );
    }
    if (canExportGDPDU) {
      exports.push({
        name: Wahanda.lang.reports.transactions.exports.typeGdpduExport,
        value: ExportType.GDPDU,
      });
    }

    if (canExportNF525) {
      exports.push({
        name: Wahanda.lang.reports.transactions.exports.typeNf525Archive,
        value: ExportType.NF525,
      });
    }

    if (canExportDSFINVK) {
      exports.push({
        name: Wahanda.lang.reports.transactions.exports.typeDsfinvkArchive,
        value: ExportType.DSFINVK,
      });
    }
    return exports;
  };

  public render() {
    const { onClose, status } = this.props;
    const { exportType, from, to } = this.state;

    const handleExportButtonClick = () => {
      TransactionsTracking.trackRunExportClick(exportType);
      this.props.actions.initPosExport({
        type: exportType,
        from: from.formatApiDateString(),
        to: to.formatApiDateString(),
      });
    };

    const handleScreenChange = (page) => {
      this.setState({ page });
    };

    return (
      <Dialog
        dataTest="report-export-modal"
        title={Wahanda.lang.reports.transactions.exports.dialogTitle}
        width={this.state.page === PageValues.setExportParams ? DIALOG_WIDTH_1 : DIALOG_WIDTH_2}
        dialogContentMaxHeight={window.innerHeight - 110}
        onClose={onClose}
        warnWhenClosingChangedForms={false}
        noOverflow
        newLook
      >
        {this.state.page === PageValues.setExportParams && (
          <div className={style.section}>
            <div>
              <Select
                name="export-type"
                dataTest="export-select-dropdown"
                selectedOption={this.state.exportType}
                onSelect={this.onExportTypeChange as any}
                options={this.getDropdownValues()}
                label={Wahanda.lang.reports.transactions.exports.format}
                newLook
                fullBorder
                disabled={this.getDropdownValues().length === 1}
              />
              <DatePicker
                initialEndDate={to}
                uniqueId={'export-date-row'}
                initialStartDate={from}
                numberOfMonths={1}
                isDayBlocked={this.isDayBlocked}
                labelStartDate={Wahanda.lang.reports.transactions.exports.from}
                labelEndDate={Wahanda.lang.reports.transactions.exports.to}
                onChange={this.onFromDateChange}
                newLook
              />
              <DatePickerQuickFilters onChange={this.onFromDateChange} />
            </div>
            {status === ExportsStateStatus.NEW_EXPORT_FAILURE && (
              <div className={style.validationMessage}>
                <Notice type="alert">
                  {Wahanda.lang.reports.transactions.exports.exportFailure}
                </Notice>
              </div>
            )}
            <div className={style.buttonContainer}>
              <Button
                dataTest="exports-submit-show-exports"
                size="large"
                variant="hollow"
                disabled={status === ExportsStateStatus.EXPORTS_PENDING}
                onClick={() => handleScreenChange(PageValues.listOfReports)}
                label={Wahanda.lang.reports.transactions.exports.exportShow}
              />
              <Button
                dataTest="exports-submit-export-request"
                size="large"
                disabled={status === ExportsStateStatus.EXPORTS_PENDING}
                onClick={handleExportButtonClick}
                label={Wahanda.lang.reports.transactions.exports.dialogTitle}
              />
            </div>
          </div>
        )}
        {this.state.page === PageValues.listOfReports && (
          <div className={style.exports}>
            {this.renderLoader()}
            <Table>
              <div
                className={style.arrow}
                onClick={() => handleScreenChange(PageValues.setExportParams)}
              >
                <ExportDialogReturnIcon />
              </div>
              <TableBody>{this.renderExports()}</TableBody>
            </Table>
          </div>
        )}
      </Dialog>
    );
  }
}
