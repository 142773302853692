require("ckeditor-jquery");

(function () {
	BackboneEx.View.Editor = Backbone.View.extend({
		options: {
			editorName: "",
			editorContent: "",
			height: 156,
			title: "",
			visible: false,
			tabindex: null
		},

		initialize: function() {
			this.editor   = null;
			this.editorId = null;
			this.instanceEnabled = true;
			this.changingReadOnly = false;
			this.contentToSetWhenReady = null;
			this.cachedValue = null;
			this.initialized = false
		},

		render: function() {

			var $textarea = this.$('textarea');
			if (!$textarea.attr('id')) {
				$textarea.attr('id', this.generateId());
			}
			this.editorId = $textarea.attr('id');

			this.showEditor();
		},

		generateId: function() {
			return _.uniqueId('editor_');
		},

		showEditor: function() {
			var self = this;
			if (self.options.readOnly) {
			    self.instanceEnabled = false;	
			}
			this.$('.show-editor').addClass('hidden disabled');
			this.$('#' + this.editorId).removeClass('hidden');

			try {
			// CKEDITOR config settings
			CKEDITOR.config.toolbar_Minimal = [
				[ 'Bold', 'Italic', 'Underline', 'NumberedList','BulletedList']
			];

        // default removed plugins
			if (self.options.readOnly && self.options.implementDisabledStyle ) {
				CKEDITOR.addCss(
					".cke_editable{background-color: #f2f2f2; color: #808080}"
				);
			} else if(self.options.implementDisabledStyle) {
				CKEDITOR.addCss(
					".cke_editable{background-color: #fff; color: #000}"
				)
			}
			var removeDefault = "elementspath, save, font";

			// it removes the context menu and plugins dependent from it
			var removeContextMenu = "liststyle, tabletools, scayt, contextmenu";
					// TODO: localisation
					this.editor = this.$('#' + this.editorId).ckeditor(function(){}, {
						toolbar: "Minimal",
						height: this.options.height,
						toolbarStartupExpanded: false,
						language: Wahanda.lang.locale,
						uiColor: self.options.uiColor,
			  disableNativeSpellChecker: false,
			  removePlugins: removeDefault + ", " + removeContextMenu
					});

					var self = this;
					var instance = this.editor.ckeditorGet();
					instance.on('instanceReady', function(e) {
						e.editor.setReadOnly(!self.instanceEnabled);
					});

					instance.on('dataReady', function(e) {
						self.initialized      = true;
						self.changingReadOnly = false;
						self.cachedValue      = null;

						if (self.contentToSetWhenReady != null) {
							this.setData(self.contentToSetWhenReady);
							self.contentToSetWhenReady = null;
						}
					});
					// Trigger change event so that the Dialog Confirm Changed Close handler would kick in
					instance.on('change', () => { self.$el.trigger('change'); });
			} catch (e) {
				this._debug(e);
			}
		},

		destruct: function() {
			if (this.editor) {
				try {
					this.editor.ckeditorGet().destroy();
					this.editor = null;
				} catch (e) {
					this._debug(e);
				}
			}
		},

		/**
		 * Returns the current editor's value. Can be cached, if readOnly mode is changing.
		 *
		 * @return string
		 */
		getValue: function() {
			if (this.cachedValue != null) {
				return this.cachedValue;
			} else {
				return this._value();
			}
		},

		/**
		 * Returns the value of the text editor, without any caching.
		 *
		 * @return string
		 */
		_value: function() {
			return this.$('textarea').val();
		},

		disable: function() {
			this.instanceEnabled = false;
			try {
				if (this.editor) {
					this._setReadOnly(true);
				}
			} catch (e) {
				this._debug(e);
			}
		},

		enable: function() {
			this.instanceEnabled = true;
			try {
				if (this.editor) {
					this._setReadOnly(false);
				}
			} catch (e) {
				this._debug(e);
			}
		},

		_setReadOnly: function(readOnly) {
			var editor = this.editor.ckeditorGet();
			if (editor.readOnly != readOnly) {
				// Cache the current value for retrieval, until the editor is accessible again.
				this.cachedValue = this._value();

				editor.setReadOnly(readOnly);
				this.changingReadOnly = true;
			}
		},

		update: function(html) {
			if (!this.editor) {
				return;
			}

			if (!this.editor) {
				this.$('textarea').text(html);
				this.showEditor();
			} else {
				try {
					if (!this.initialized || this.changingReadOnly) {
						// Save the content to be updated when the instance is ready
						this.contentToSetWhenReady = this.cachedValue = html;
					} else {
						this.editor.val(html);
						this.contentToSetWhenReady = null;
					}
				} catch (e) {
					this._debug(e);
				}
			}
		},

		_debug: function(error) {
			if (window.console && console.debug) {
				console.debug(error);
				if (error.getStack) {
					console.debug(error.stack());
				}
			}
		}
	});
}());
