import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TypeCard from './';
import { selectPromotion } from '../../../actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      selectPromotion,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(TypeCard);
