import React from 'react';

interface Props {
  size?: number;
  className?: string;
}

export const CloseIcon = ({ size = 16, ...props }: Props) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 16 16" {...props}>
    <circle fill="#FFF" cx="8" cy="8" r="6" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 1.333a6.667 6.667 0 110 13.334A6.667 6.667 0 018 1.334zm0 1.213a5.455 5.455 0 100 10.909 5.455 5.455 0 000-10.91zm2.332 2.12l1.001 1.002L9.001 8l2.332 2.332-1.001 1.002L8 9.002l-2.332 2.332-1.001-1.002L6.997 8l-2.33-2.332 1.001-1.001L8 6.998l2.332-2.331z"
      clipRule="evenodd"
    ></path>
  </svg>
);
