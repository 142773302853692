import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestRettentionReportDataAction } from '../../newSalesReports/actions';
import ClientAcquisitionReport from './index';

const mapSateToProps = (state) => ({
  displayState: state.salesReport.rettentionReport.displayState,
  reportData: state.salesReport.rettentionReport.reportData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ requestRettentionReportDataAction }, dispatch),
});
const connector = connect(mapSateToProps, mapDispatchToProps);

export default connector(ClientAcquisitionReport);
