/**
 * The bridge between the old Backbone code and new ES6 world.
 */
Wahanda.ES6Bridge = {
  View: {
    /**
     * Render the View into the container.
     *
     * @param reactClass
     * @param target
     */
    render: function (reactClass, target) {
      var element = App.ES6.React.createElement(reactClass);

      return App.ES6.React.render(element, target);
    },
  },
};
