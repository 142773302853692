export const INITIAL_STATE_DASHBOARD = 'INITIAL_STATE_DASHBOARD';

export const INITIAL_STATE_MENU_DISCOUNT = 'INITIAL_STATE_MENU_DISCOUNT';

export const INITIAL_STATE_OFFER_PRICING_DISCOUNT_RULE =
  'INITIAL_STATE_OFFER_PRICING_DISCOUNT_RULE';

export const GET_VENUE_REQUEST = 'GET_VENUE_REQUEST';

export const GET_VENUE_RECEIVE = 'GET_VENUE_RECEIVE';

export const GET_VENUE_FAILURE = 'GET_VENUE_FAILURE';

export const GET_VENUE_STATISTICS_REQUEST = 'GET_VENUE_STATISTICS_REQUEST';

export const GET_VENUE_STATISTICS_RECEIVE = 'GET_VENUE_STATISTICS_RECEIVE';

export const GET_VENUE_STATISTICS_FAILURE = 'GET_VENUE_STATISTICS_FAILURE';

export const GET_SUPPLIER_DETAILS_REQUEST = 'GET_SUPPLIER_DETAILS_REQUEST';

export const GET_SUPPLIER_DETAILS_RECEIVE = 'GET_SUPPLIER_DETAILS_RECEIVE';

export const GET_SUPPLIER_DETAILS_FAILURE = 'GET_SUPPLIER_DETAILS_FAILURE';

export const TRACK_ACTION_ANALYTICS = 'TRACK_ACTION_ANALYTICS';

export const VENUE_STATE_RESET = 'VENUE_STATE_RESET';

export const GET_CUSTOMER_REVIEWS_FAILURE = 'GET_CUSTOMER_REVIEWS_FAILURE';

export const GET_CUSTOMER_REVIEWS_RECEIVE = 'GET_CUSTOMER_REVIEWS_RECEIVE';

export const GET_CUSTOMER_REVIEWS_REQUEST = 'GET_CUSTOMER_REVIEWS_REQUEST';

export const CLEAR_CUSTOMER_REVIEWS = 'CLEAR_CUSTOMER_REVIEWS';

export const GET_RANKING_BOOST_REQUEST = 'GET_RANKING_BOOST_REQUEST';

export const GET_RANKING_BOOST_SUCCESS = 'GET_RANKING_BOOST_SUCCESS';

export const GET_RANKING_BOOST_FAILURE = 'GET_RANKING_BOOST_FAILURE';

export const CUSTOMER_DUPLICATE_SET_DATA = 'CUSTOMER_DUPLICATE_SET_DATA';

export const CUSTOMER_INFO_SET_DATA = 'CUSTOMER_INFO_SET_DATA';

export const CUSTOMER_INFO_SUBMIT = 'CUSTOMER_INFO_SUBMIT';

export const CUSTOMER_INFO_REQUEST = 'CUSTOMER_INFO_REQUEST';

export const CUSTOMER_INFO_REQUEST_SUCCESS = 'CUSTOMER_INFO_REQUEST_SUCCESS';

export const CUSTOMER_INFO_REQUEST_FAILURE = 'CUSTOMER_INFO_REQUEST_FAILURE';

export const CUSTOMER_INFO_SUBMIT_SUCCESS = 'CUSTOMER_INFO_SUBMIT_SUCCESS';

export const CUSTOMER_INFO_SUBMIT_FAILURE = 'CUSTOMER_INFO_SUBMIT_FAILURE';

export const CUSTOMER_INFO_RESET = 'CUSTOMER_INFO_RESET';

export const PUT_REVIEW_RESPONSE = 'SUBMIT_RESPONSE_TO_CUSTOMER_REVIEW';

export const PUT_REVIEW_RESPONSE_SUCCESS = 'PUT_RESPONSE_TO_CUSTOMER_REVIEW_SUCCESS';

export const PUT_REVIEW_RESPONSE_FAILURE = 'PUT_RESPONSE_TO_CUSTOMER_REVIEW_FAILURE';

export const CLEAR_PUT_REVIEW_RESPONSE_FAILURE = 'CLEAR_CUSTOMER_REVIEW_PUT_FAILURE';

export const OFFER_DIALOG_OPEN = 'OFFER_DIALOG_OPEN';

export const OFFER_DIALOG_PACKAGE_PRICING_SKUS_CHANGE = 'OFFER_DIALOG_PACKAGE_PRICING_SKUS_CHANGE';

export const OFFER_DIALOG_CLOSE = 'OFFER_DIALOG_CLOSE';

export const OFFER_DIALOG_CHANGE_PRICING_TYPE = 'OFFER_DIALOG_CHANGE_PRICING_TYPE';

export const OFFER_DIALOG_TOGGLE_SKU = 'OFFER_DIALOG_TOGGLE_SKU';

export const OFFER_DIALOG_UPDATE_FULL_PRICE_AMOUNT = 'OFFER_DIALOG_UPDATE_FULL_PRICE_AMOUNT';

export const OFFER_DIALOG_PACKAGE_SERVICES_CHANGE = 'OFFER_DIALOG_PACKAGE_SERVICES_CHANGE';

export const OFFER_DIALOG_ADD_INLINE_SUB_SERVICE = 'OFFER_DIALOG_ADD_SUB_SERVICE';

export const OFFER_DIALOG_UPDATE_INLINE_SUB_SERVICE = 'OFFER_DIALOG_UPDATE_SUB_SERVICE';

export const OFFER_DIALOG_REMOVE_INLINE_SUB_SERVICE = 'OFFER_DIALOG_REMOVE_SUB_SERVICE';

export const OFFER_DIALOG_PACKAGE_MASTER_SERVICE_CHANGE =
  'OFFER_DIALOG_PACKAGE_MASTER_SERVICE_CHANGE';

export const OFFER_DIALOG_PRICING_AND_EMPLOYEES_READY = 'OFFER_DIALOG_PRICING_AND_EMPLOYEES_READY';

export const OFFER_DIALOG_CHANGE_USE_EMPLOYEE_CATEGORIES =
  'OFFER_DIALOG_CHANGE_USE_EMPLOYEE_CATEGORIES';

export const OFFER_DIALOG_CHANGE_EMPLOYEE = 'OFFER_DIALOG_CHANGE_EMPLOYEE';

export const OFFER_DIALOG_SELECT_ALL_EMPLOYEES = 'OFFER_DIALOG_SELECT_ALL_EMPLOYEES';

export const OFFER_DIALOG_DESELECT_ALL_EMPLOYEES = 'OFFER_DIALOG_DESELECT_ALL_EMPLOYEES';

export const OFFER_DIALOG_OFFER_NAME_CHANGE = 'OFFER_DIALOG_OFFER_NAME_CHANGE';

export const OFFER_DIALOG_CHANGE_ALLOW_PROCESSING = 'OFFER_DIALOG_CHANGE_ALLOW_PROCESSING';

export const OFFER_DIALOG_USE_PROCESSING_TIME = 'OFFER_DIALOG_USE_PROCESSING_TIME';

export const OFFER_DIALOG_SET_PROCESSING_TIME = 'OFFER_DIALOG_SET_PROCESSING_TIME';

export const OFFER_DIALOG_SAVED = 'OFFER_DIALOG_SAVED';

export const RESET_SERVICE_EDIT_TRACKING = 'RESET_SERVICE_EDIT_TRACKING';

export const TRACK_SERVICE_EDIT = 'TRACK_SERVICE_EDIT';

export const GET_DISCOUNT_RULE_REQUEST = 'GET_DISCOUNT_RULE_REQUEST';

export const GET_DISCOUNT_RULE_RECEIVE = 'GET_DISCOUNT_RULE_RECEIVE';

export const GET_DISCOUNT_RULE_FAILURE = 'GET_DISCOUNT_RULE_FAILURE';

export const PUT_DISCOUNT_RULE_REQUEST = 'PUT_DISCOUNT_RULE_REQUEST';

export const PUT_DISCOUNT_RULE_RECEIVE = 'PUT_DISCOUNT_RULE_RECEIVE';

export const PUT_DISCOUNT_RULE_FAILURE = 'PUT_DISCOUNT_RULE_FAILURE';

export const ADD_DISCOUNT_RULE_REQUEST = 'ADD_DISCOUNT_RULE_REQUEST';

export const ADD_DISCOUNT_RULE_RECEIVE = 'ADD_DISCOUNT_RULE_RECEIVE';

export const ADD_DISCOUNT_RULE_FAILURE = 'ADD_DISCOUNT_RULE_FAILURE';

export const REMOVE_DISCOUNT_RULE_REQUEST = 'REMOVE_DISCOUNT_RULE_REQUEST';

export const REMOVE_DISCOUNT_RULE_RECEIVE = 'REMOVE_DISCOUNT_RULE_RECEIVE';

export const REMOVE_DISCOUNT_RULE_FAILURE = 'REMOVE_DISCOUNT_RULE_FAILURE';

export const PRESENT_DISCOUNT_RULE_DIALOG = 'PRESENT_DISCOUNT_RULE_DIALOG';

export const DISCARD_DISCOUNT_RULE_DIALOG = 'DISCARD_DISCOUNT_RULE_DIALOG';

export const PRESENT_DISCOUNT_EMAIL_CAMPAIGN_DIALOG = 'PRESENT_DISCOUNT_EMAIL_CAMPAIGN_DIALOG';

export const DISCARD_DISCOUNT_EMAIL_CAMPAIGN_DIALOG = 'DISCARD_DISCOUNT_EMAIL_CAMPAIGN_DIALOG';

export const SET_SELECTED_DISCOUNT_RULE = 'SET_SELECTED_DISCOUNT_RULE';

export const GET_VENUE_DETAILS_REQUEST = 'GET_VENUE_DETAILS_REQUEST';

export const GET_VENUE_DETAILS_RECEIVE = 'GET_VENUE_DETAILS_RECEIVE';

export const GET_VENUE_DETAILS_FAILURE = 'GET_VENUE_DETAILS_FAILURE';

export const GET_DISCOUNT_RULES_REQUEST = 'GET_DISCOUNT_RULES_REQUEST';

export const GET_DISCOUNT_RULES_RECEIVE = 'GET_DISCOUNT_RULES_RECEIVE';

export const GET_DISCOUNT_RULES_FAILURE = 'GET_DISCOUNT_RULES_FAILURE';

export const REVERT_DISCOUNT_RULE = 'REVERT_DISCOUNT_RULE';

export const PRESENT_DISCOUNT_RULE_DELETE_CONFIRM = 'PRESENT_DISCOUNT_RULE_DELETE_CONFIRM';

export const DISCARD_DISCOUNT_RULE_DELETE_CONFIRM = 'DISCARD_DISCOUNT_RULE_DELETE_CONFIRM';

export const GET_MENU_ITEMS_REQUEST = 'GET_MENU_ITEMS_REQUEST';

export const GET_MENU_ITEMS_RECEIVE = 'GET_MENU_ITEMS_RECEIVE';

export const SET_ALL_MENU_ITEMS_CHECKED = 'SET_ALL_MENU_ITEMS_CHECKED';

export const SET_MENU_ITEM_CHECKED = 'SET_MENU_ITEM_CHECKED';

export const SET_MENU_ITEMS_CHECKED = 'SET_MENU_ITEMS_CHECKED';

export const GET_TREATMENTS_REQUEST = 'GET_TREATMENTS_REQUEST';

export const GET_TREATMENTS_RECEIVE = 'GET_TREATMENTS_RECEIVE';

export const GET_TREATMENTS_FAILURE = 'GET_TREATMENTS_FAILURE';

export const SELECT_SERVICE_DIALOG_CHANGE_SELECTED_TREATMENT_TYPE_ID =
  'SELECT_SERVICE_DIALOG_CHANGE_SELECTED_TREATMENT_TYPE_ID';

export const SELECT_SERVICE_DIALOG_REVERT_SELECTED_TREATMENT_TYPE_ID =
  'SELECT_SERVICE_DIALOG_REVERT_SELECTED_TREATMENT_TYPE_ID';

export const SELECT_SERVICE_DIALOG_CHANGE_VALUE = 'SELECT_SERVICE_DIALOG_CHANGE_VALUE';

export const SELECT_SERVICE_DIALOG_REVERT_VALUE = 'SELECT_SERVICE_DIALOG_REVERT_VALUE';

export const SELECT_SERVICE_DIALOG_REVERT = 'SELECT_SERVICE_DIALOG_REVERT';

export const INITIAL_STATE_SELECT_SERVICE_DIALOG = 'INITIAL_STATE_SELECT_SERVICE_DIALOG';

export const GET_RETTENTION_REPORT_REQUEST = 'GET_RETTENTION_REPORT_REQUEST';

export const GET_RETTENTION_REPORT_RECEIVE = 'GET_RETTENTION_REPORT_RECEIVE';

export const GET_RETTENTION_REPORT_FAILURE = 'GET_RETTENTION_REPORT_FAILURE';

export const GET_SALES_REPORT_REQUEST = 'GET_SALES_REPORT_REQUEST';

export const GET_SALES_REPORT_RECEIVE = 'GET_SALES_REPORT_RECEIVE';

export const GET_SALES_REPORT_FAILURE = 'GET_SALES_REPORT_FAILURE';

export const GET_PRODUCT_SALES_REPORT_REQUEST = 'GET_PRODUCT_SALES_REPORT_REQUEST';

export const GET_PRODUCT_SALES_REPORT_RECEIVE = 'GET_PRODUCT_SALES_REPORT_RECEIVE';

export const GET_PRODUCT_SALES_REPORT_FAILURE = 'GET_PRODUCT_SALES_REPORT_FAILURE';

export const GET_SERVICE_SALES_REPORT_REQUEST = 'GET_SERVICE_SALES_REPORT_REQUEST';

export const GET_SERVICE_SALES_REPORT_RECEIVE = 'GET_SERVICE_SALES_REPORT_RECEIVE';

export const GET_SERVICE_SALES_REPORT_FAILURE = 'GET_SERVICE_SALES_REPORT_FAILURE';

export const GET_EMPLOYEE_SALES_REPORT_REQUEST = 'GET_EMPLOYEE_SALES_REPORT_REQUEST';

export const GET_EMPLOYEE_SALES_REPORT_RECEIVE = 'GET_EMPLOYEE_SALES_REPORT_RECEIVE';

export const GET_EMPLOYEE_SALES_REPORT_FAILURE = 'GET_EMPLOYEE_SALES_REPORT_FAILURE';

export const GET_CHANNEL_SALES_REPORT_REQUEST = 'GET_CHANNEL_SALES_REPORT_REQUEST';

export const GET_CHANNEL_SALES_REPORT_RECEIVE = 'GET_CHANNEL_SALES_REPORT_RECEIVE';

export const GET_CHANNEL_SALES_REPORT_FAILURE = 'GET_CHANNEL_SALES_REPORT_FAILURE';

export const GET_DAILY_SALES_REPORT_REQUEST = 'GET_DAILY_SALES_REPORT_REQUEST';

export const GET_DAILY_SALES_REPORT_RECEIVE = 'GET_DAILY_SALES_REPORT_RECEIVE';

export const GET_DAILY_SALES_REPORT_FAILURE = 'GET_DAILY_SALES_REPORT_FAILURE';

export const GET_VAT_REPORT_REQUEST = 'GET_VAT_REPORT_REQUEST';

export const GET_VAT_REPORT_RECEIVE = 'GET_VAT_REPORT_RECEIVE';

export const GET_VAT_REPORT_FAILURE = 'GET_VAT_REPORT_FAILURE';

export const GET_VENUE_EMPLOYEES_REQUEST = 'GET_VENUE_EMPLOYEES_REQUEST';

export const GET_VENUE_EMPLOYEES_RECEIVE = 'GET_VENUE_EMPLOYEES_RECEIVE';

export const GET_VENUE_EMPLOYEES_FAILURE = 'GET_VENUE_EMPLOYEES_FAILURE';

export const GET_MONTHLY_SALES_REPORT_RECEIVE = 'GET_MONTHLY_SALES_REPORT_RECEIVE';

export const GET_MONTHLY_SALES_REPORT_REQUEST = 'GET_MONTHLY_SALES_REPORT_REQUEST';

export const GET_MONTHLY_SALES_REPORT_FAILURE = 'GET_MONTHLY_SALES_REPORT_FAILURE';

export const CLIENT_CHOSEN = 'CLIENT_CHOSEN';

export const CLIENT_SAVED = 'CLIENT_SAVED';

export const CLIENT_SET_WALKIN = 'CLIENT_SET_WALKIN';

export const INITIAL_STATE_INLINE_CLIENT_CREATION = 'INITIAL_STATE_INLINE_CLIENT_CREATION';

export const SET_CLIENT_PROPERTIES = 'SET_CLIENT_PROPERTIES';

export const CLEAR_INLINE_CLIENT = 'CLEAR_INLINE_CLIENT';

export const SET_SEND_MASS_EMAIL = 'SET_SEND_MASS_EMAIL';

export const CLIENTS_BULK_DELETE_REQUEST = 'CLIENTS_BULK_DELETE_REQUEST';

export const CLIENTS_BULK_DELETE_SUCCESS = 'CLIENTS_BULK_DELETE_SUCCESS';

export const CLIENTS_BULK_DELETE_SERVER_ERROR = 'CLIENTS_BULK_DELETE_SERVER_ERROR';

export const CLIENTS_RESET = 'CLIENTS_RESET';

export const CLIENTS_IMPORT_SELECT_FILE = 'CLIENTS_IMPORT_SELECT_FILE';

export const CLIENTS_IMPORT_RESET = 'CLIENTS_IMPORT_RESET';

export const CLIENTS_IMPORT_TO_CAMPAIGN_PROMPT = 'CLIENTS_IMPORT_TO_CAMPAIGN_PROMPT';

export const CLIENTS_FILE_UPLOAD_REQUEST = 'CLIENTS_FILE_UPLOAD_REQUEST';

export const CLIENTS_FILE_UPLOAD_SUCCESS = 'CLIENTS_FILE_UPLOAD_SUCCESS';

export const CLIENTS_FILE_UPLOAD_FAILURE = 'CLIENTS_FILE_UPLOAD_FAILURE';

export const CLIENTS_FILE_IMPORT_SUCCESS = 'CLIENTS_FILE_IMPORT_SUCCESS';

export const CLIENTS_FILE_IMPORT_FAILURE = 'CLIENTS_FILE_IMPORT_FAILURE';

export const CLIENTS_FILE_IMPORT_UPDATE = 'CLIENTS_FILE_IMPORT_UPDATE';

export const GET_BANK_DETAILS_REQUEST = 'GET_BANK_DETAILS_REQUEST';

export const GET_BANK_DETAILS_RECEIVE = 'GET_BANK_DETAILS_RECEIVE';

export const GET_LAST_PAYMENT_STATUS_REQUEST = 'GET_LAST_PAYMENT_STATUS_REQUEST';

export const GET_LAST_PAYMENT_STATUS_RECEIVED = 'GET_LAST_PAYMENT_STATUS_RECEIVED';

export const GET_POS_STATUS_REQUEST = 'GET_POS_STATUS_REQUEST';

export const GET_POS_STATUS_RECEIVE = 'GET_POS_STATUS_RECEIVE';

export const GET_POS_STATUS_FAILURE = 'GET_POS_STATUS_FAILURE';

export const GET_POS_STATUS_RESET = 'GET_POS_STATUS_RESET';

export const GET_SALES_SUMMARY_REPORT_REQUEST = 'GET_SALES_SUMMARY_REPORT_REQUEST';

export const GET_SALES_SUMMARY_REPORT_RECEIVE = 'GET_SALES_SUMMARY_REPORT_RECEIVE';

export const GET_SALES_SUMMARY_REPORT_FAILURE = 'GET_SALES_SUMMARY_REPORT_FAILURE';

export const GET_VENUE_MONTHLY_SALES_REPORT_REQUEST = 'GET_VENUE__MONTHLY_SALES_REPORT_REQUEST';

export const GET_VENUE_MONTHLY_SALES_REPORT_RECEIVE = 'GET_VENUE__MONTHLY_SALES_REPORT_RECEIVE';

export const GET_VENUE_MONTHLY_SALES_REPORT_FAILURE = 'GET_VENUE__MONTHLY_SALES_REPORT_FAILURE';

export const GET_APPOINTMENT_NOTIFICATIONS_REQUEST = 'GET_APPOINTMENT_NOTIFICATIONS_REQUEST';

export const GET_APPOINTMENT_NOTIFICATIONS_RECEIVE = 'GET_APPOINTMENT_NOTIFICATIONS_RECEIVE';

export const GET_APPOINTMENT_NOTIFICATIONS_FAILURE = 'GET_APPOINTMENT_NOTIFICATIONS_FAILURE';

export const SET_APPOINTMENT_NOTIFICATIONS_VISIBLE = 'SET_APPOINTMENT_NOTIFICATIONS_VISIBLE';

export const GET_APPOINTMENT_NOTIFICATIONS_RESET = 'GET_APPOINTMENT_NOTIFICATIONS_RESET';

export const GET_APPOINTMENT_AVAILABILITY_REQUEST = 'GET_APPOINTMENT_AVAILABILITY_REQUEST';

export const GET_APPOINTMENT_AVAILABILITY_RECEIVE = 'GET_APPOINTMENT_AVAILABILITY_RECEIVE';

export const GET_APPOINTMENT_AVAILABILITY_FAILURE = 'GET_APPOINTMENT_AVAILABILITY_FAILURE';

export const GET_WEBSOCKETS_CONNECT_REQUEST = 'GET_WEBSOCKETS_CONNECT_REQUEST';

export const GET_WEBSOCKETS_CONNECTED = 'GET_WEBSOCKETS_CONNECTED';

export const GET_WEBSOCKETS_MESSAGE_RECEIVED = 'GET_WEBSOCKETS_MESSAGE_RECEIVED';

export const GET_EMAIL_TEMPLATE_REQUEST = 'GET_EMAIL_TEMPLATE_REQUEST';

export const GET_SMART_DISCOUNTS_TEMPLATE_REQUEST = 'GET_SMART_DISCOUNTS_TEMPLATE_REQUEST';

export const GET_SERVICE_ON_SALE_TEMPLATE_REQUEST = 'GET_SERVICE_ON_SALE_TEMPLATE_REQUEST';

export const GET_APP_FOR_YOUR_CLIENTS_TEMPLATE_REQUEST =
  'GET_APP_FOR_YOUR_CLIENTS_TEMPLATE_REQUEST';

export const GET_BACK_TO_BUSINESS_TEMPLATE_REQUEST = 'GET_BACK_TO_BUSINESS_TEMPLATE_REQUEST';

export const GET_EMAIL_TEMPLATE_RECEIVE = 'GET_EMAIL_TEMPLATE_RECEIVE';

export const GET_EMAIL_TEMPLATE_FAILURE = 'GET_EMAIL_TEMPLATE_FAILURE';

export const GET_MASS_EMAIL_COUNT_REQUEST = 'GET_MASS_EMAIL_COUNT_REQUEST';

export const GET_MASS_EMAIL_COUNT_RECEIVE = 'GET_MASS_EMAIL_COUNT_RECEIVE';

export const GET_MASS_EMAIL_COUNT_FAILURE = 'GET_MASS_EMAIL_COUNT_FAILURE';

export const EDIT_MARKETING_EMAIL_CONTENT = 'EDIT_MARKETING_EMAIL_CONTENT';

export const STORE_MARKETING_EMAIL_CONTENT = 'STORE_MARKETING_EMAIL_CONTENT';

export const CLEAR_EMAIL_CAMPAIGN_ERROR = 'CLEAR_EMAIL_CAMPAIGN_ERROR';

export const GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_REQUEST =
  'GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_REQUEST';

export const GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_SUCCESS =
  'GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_SUCCESS';

export const GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_FAILURE =
  'GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_FAILURE';

export const SEND_EMAIL_CAMPAIGN_REQUEST = 'SEND_EMAIL_CAMPAIGN_REQUEST';

export const SEND_EMAIL_CAMPAIGN_SUCCESS = 'SEND_EMAIL_CAMPAIGN_SUCCESS';

export const SEND_EMAIL_CAMPAIGN_FAILURE = 'SEND_EMAIL_CAMPAIGN_FAILURE';

export const WIDGET_TOGGLE_ENABLED_REQUEST = 'WIDGET_TOGGLE_ENABLED_REQUEST';

export const WIDGET_TOGGLE_ENABLED_SUCCESS = 'WIDGET_TOGGLE_ENABLED_SUCCESS';

export const WIDGET_TOGGLE_ENABLED_FAILURE = 'WIDGET_TOGGLE_ENABLED_FAILURE';

export const WIDGET_GENERATE_CODE_REQUEST = 'WIDGET_GENERATE_CODE_REQUEST';

export const WIDGET_GENERATE_CODE_SUCCESS = 'WIDGET_GENERATE_CODE_SUCCESS';

export const WIDGET_GENERATE_CODE_FAILURE = 'WIDGET_GENERATE_CODE_FAILURE';

export const WIDGET_SELECT_LANGUAGE = 'WIDGET_SELECT_LANGUAGE';

export const WIDGET_CLEAN_STORE = 'WIDGET_CLEAN_STORE';

export const WIDGET_CLEAR_ERROR = 'WIDGET_CLEAR_ERROR';

export const GET_PRODUCTS_LIST_REQUEST = 'GET_PRODUCTS_LIST_REQUEST';

export const GET_PRODUCTS_LIST_RECEIVE = 'GET_PRODUCTS_LIST_RECEIVE';

export const GET_PRODUCTS_LIST_RESET = 'GET_PRODUCTS_LIST_RESET';

export const GET_PRODUCTS_LIST_FAILURE = 'GET_PRODUCTS_LIST_FAILURE';

export const PRODUCT_ORDER_SELECT_SUPPLIER = 'PRODUCT_ORDER_SELECT_SUPPLIER';

export const PRODUCT_ADD_TO_ORDER = 'PRODUCT_ADD_TO_ORDER';

export const PRODUCT_REMOVE_FROM_ORDER = 'PRODUCT_REMOVE_FROM_ORDER';

export const PRODUCT_ORDER_UPDATE = 'PRODUCT_ORDER_UPDATE';

export const NEW_PRODUCT_ORDER_RESET = 'NEW_PRODUCT_ORDER_RESET';

export const PRODUCT_ORDER_READY_TO_BE_PLACED = 'PRODUCT_ORDER_READY_TO_BE_PLACED';

export const PRODUCT_ORDER_CAN_BE_PROCESSED = 'PRODUCT_ORDER_CAN_BE_PROCESSED';

export const GET_PRODUCT_ORDERS_REQUEST = 'GET_PRODUCT_ORDERS_REQUEST';

export const GET_PRODUCT_ORDERS_RECEIVE = 'GET_PRODUCT_ORDERS_RECEIVE';

export const GET_PRODUCT_ORDERS_FAILURE = 'GET_PRODUCT_ORDERS_FAILURE';

export const PRODUCT_ORDERS_RESET = 'PRODUCT_ORDERS_RESET';

export const PRODUCT_ORDER_REQUEST = 'PRODUCT_ORDER_REQUEST';

export const PRODUCT_ORDER_RECEIVE = 'PRODUCT_ORDER_RECEIVE';

export const PRODUCT_ORDER_FAILURE = 'PRODUCT_ORDER_FAILURE';

export const PRODUCT_ORDER_RESET = 'PRODUCT_ORDER_RESET';

export const CANCEL_PRODUCT_ORDER_REQUEST = 'CANCEL_PRODUCT_ORDER_REQUEST';

export const CANCEL_PRODUCT_ORDER_SUCCESS = 'CANCEL_PRODUCT_ORDER_SUCCESS';

export const CANCEL_PRODUCT_ORDER_FAILURE = 'CANCEL_PRODUCT_ORDER_FAILURE';

export const UPDATE_EXISTING_ORDER = 'UPDATE_EXISTING_ORDER';

export const CREATE_PRODUCT_ORDER_REQUEST = 'CREATE_PRODUCT_ORDER_REQUEST';

export const CREATE_PRODUCT_ORDER_SUCCESS = 'CREATE_PRODUCT_ORDER_SUCCESS';

export const CREATE_PRODUCT_ORDER_FAILURE = 'CREATE_PRODUCT_ORDER_FAILURE';

export const COMPLETE_PRODUCT_ORDER_REQUEST = 'COMPLETE_PRODUCT_ORDER_REQUEST';

export const COMPLETE_PRODUCT_ORDER_SUCCESS = 'COMPLETE_PRODUCT_ORDER_SUCCESS';

export const COMPLETE_PRODUCT_ORDER_FAILURE = 'COMPLETE_PRODUCT_ORDER_FAILURE';

export const EMPLOYEES_REQUEST = 'EMPLOYEES_REQUEST';

export const EMPLOYEES_RECEIVE = 'EMPLOYEES_RECEIVE';

export const EMPLOYEES_FAILURE = 'EMPLOYEES_FAILURE';

export const EMPLOYEES_RESET = 'EMPLOYEES_RESET';

export const EMPLOYEES_SET_EMPLOYEE_DATA = 'EMPLOYEES_SET_EMPLOYEE_DATA';

export const EMPLOYEES_SUBMIT_EMPLOYEE = 'EMPLOYEES_SUBMIT_EMPLOYEE';

export const EMPLOYEES_EMPLOYEE_SUBMIT_SUCCESS = 'EMPLOYEES_EMPLOYEE_SUBMIT_SUCCESS';

export const EMPLOYEES_EMPLOYEE_REQUEST = 'EMPLOYEES_EMPLOYEE_REQUEST';

export const EMPLOYEES_EMPLOYEE_RECEIVE = 'EMPLOYEES_EMPLOYEE_RECEIVE';

export const EMPLOYEES_EMPLOYEE_DATA_RESET = 'EMPLOYEES_EMPLOYEE_DATA_RESET';

export const EMPLOYEES_CREATE_EMPLOYEE = 'EMPLOYEES_CREATE_EMPLOYEE';

export const EMPLOYEES_DELETE_EMPLOYEE = 'EMPLOYEES_DELETE_EMPLOYEE';

export const EMPLOYEES_DELETE_EMPLOYEE_SUCCESS = 'EMPLOYEES_DELETE_EMPLOYEE_SUCCESS';

export const EMPLOYEES_EXTERNAL_CALENDAR_LINK = 'EMPLOYEES_EXTERNAL_CALENDAR_LINK';

export const EMPLOYEES_EXTERNAL_CALENDAR_LINK_SUCCESS = 'EMPLOYEES_EXTERNAL_CALENDAR_LINK_SUCCESS';

export const EMPLOYEES_EXTERNAL_CALENDAR_UNLINK = 'EMPLOYEES_EXTERNAL_CALENDAR_UNLINK';

export const EMPLOYEES_EXTERNAL_CALENDAR_UNLINK_SUCCESS =
  'EMPLOYEES_EXTERNAL_CALENDAR_UNLINK_SUCCESS';

export const EMPLOYEES_PERMISSIONS_BY_ROLES_REQUEST = 'EMPLOYEES_PERMISSIONS_BY_ROLES_REQUEST';

export const EMPLOYEES_PERMISSIONS_BY_ROLES_RECEIVE = 'EMPLOYEES_PERMISSIONS_BY_ROLES_RECEIVE';

export const EMPLOYEES_CATEGORIES_REQUEST = 'EMPLOYEES_CATEGORIES_REQUEST';

export const EMPLOYEES_CATEGORIES_RECEIVE = 'EMPLOYEES_CATEGORIES_RECEIVE';

export const GET_EXTERNAL_SALON_SYSTEM_SELECT = 'GET_EXTERNAL_SALON_SYSTEM_SELECT';

export const GET_EXTERNAL_SALON_INTEGRATION_REQUEST = 'GET_EXTERNAL_SALON_INTEGRATION_REQUEST';

export const GET_EXTERNAL_SALON_INTEGRATION_RECEIVE = 'GET_EXTERNAL_SALON_INTEGRATION_RECEIVE';

export const GET_EXTERNAL_SALON_INTEGRATION_CREATE_REQUEST =
  'GET_EXTERNAL_SALON_INTEGRATION_CREATE_REQUEST';

export const GET_EXTERNAL_SALON_INTEGRATION_CREATE_RECEIVE =
  'GET_EXTERNAL_SALON_INTEGRATION_CREATE_RECEIVE';

export const GET_EXTERNAL_SALON_EMPLOYEE_LINK_ADD = 'GET_EXTERNAL_SALON_EMPLOYEE_LINK_ADD';

export const GET_EXTERNAL_SALON_EMPLOYEE_LINK_REMOVE = 'GET_EXTERNAL_SALON_EMPLOYEE_LINK_REMOVE';

export const GET_EXTERNAL_SALON_INTEGRATION_DELETE_REQUEST =
  'GET_EXTERNAL_SALON_INTEGRATION_DELETE_REQUEST';

export const GET_EXTERNAL_SALON_INTEGRATION_DELETE_RECEIVE =
  'GET_EXTERNAL_SALON_INTEGRATION_DELETE_RECEIVE';

export const GET_EXTERNAL_SALON_EMPLOYEES_REQUEST = 'GET_EXTERNAL_SALON_EMPLOYEES_REQUEST';

export const GET_EXTERNAL_SALON_EMPLOYEES_RECEIVE = 'GET_EXTERNAL_SALON_EMPLOYEES_RECEIVE';

export const GET_EXTERNAL_SALON_SYSTEMS_REQUEST = 'GET_EXTERNAL_SALON_SYSTEMS_REQUEST';

export const GET_EXTERNAL_SALON_SYSTEMS_RECEIVE = 'GET_EXTERNAL_SALON_SYSTEMS_RECEIVE';

export const GET_EXTERNAL_SALON_INTEGRATION_FAILURE = 'GET_EXTERNAL_SALON_INTEGRATION_FAILURE';

export const GET_EXTERNAL_SALON_INTEGRATION_RESET = 'GET_EXTERNAL_SALON_INTEGRATION_RESET';

export const PRODUCT_SUPPLIERS_SET_SUPPLIER_DATA = 'PRODUCT_SUPPLIERS_SET_SUPPLIER_DATA';

export const PRODUCT_SUPPLIERS_SELECT_PRODUCT = 'PRODUCT_SUPPLIERS_SELECT_PRODUCT';

export const PRODUCT_SUPPLIERS_SUBMIT_SUPPLIER = 'PRODUCT_SUPPLIERS_SUBMIT_SUPPLIER';

export const PRODUCT_SUPPLIERS_SUPPLIER_SUBMIT_SUCCESS =
  'PRODUCT_SUPPLIERS_SUPPLIER_SUBMIT_SUCCESS';

export const PRODUCT_SUPPLIERS_SUBMIT_PRODUCTS_ASSIGNATION =
  'PRODUCT_SUPPLIERS_SUBMIT_PRODUCTS_ASSIGNATION';

export const PRODUCT_SUPPLIERS_PRODUCTS_ASSIGNATION_SUBMIT_SUCCESS =
  'PRODUCT_SUPPLIERS_PRODUCTS_ASSIGNATION_SUBMIT_SUCCESS';

export const PRODUCT_SUPPLIERS_REQUEST = 'PRODUCT_SUPPLIERS_REQUEST';

export const PRODUCT_SUPPLIERS_RECEIVE = 'PRODUCT_SUPPLIERS_RECEIVE';

export const PRODUCT_SUPPLIERS_SUPPLIER_REQUEST = 'PRODUCT_SUPPLIERS_SUPPLIER_REQUEST';

export const PRODUCT_SUPPLIERS_SUPPLIER_RECEIVE = 'PRODUCT_SUPPLIERS_SUPPLIER_RECEIVE';

export const PRODUCT_SUPPLIERS_FAILURE = 'PRODUCT_SUPPLIERS_FAILURE';

export const PRODUCT_SUPPLIERS_SUPPLIER_DATA_RESET = 'PRODUCT_SUPPLIERS_SUPPLIER_DATA_RESET';

export const PRODUCT_SUPPLIERS_RESET = 'PRODUCT_SUPPLIERS_RESET';

export const PRODUCT_SUPPLIERS_DELETE_SUPPLIER = 'PRODUCT_SUPPLIERS_DELETE_SUPPLIER';

export const PRODUCT_SUPPLIERS_DELETE_SUPPLIER_SUCCESS =
  'PRODUCT_SUPPLIERS_DELETE_SUPPLIER_SUCCESS';

export const SUBMIT_PARTNER_SITE_IMPRESSUM_REQUEST = 'SUBMIT_PARTNER_SITE_IMPRESSUM_REQUEST';

export const SUBMIT_PARTNER_SITE_IMPRESSUM_SUCCESS = 'SUBMIT_PARTNER_SITE_IMPRESSUM_SUCCESS';

export const IMPRESSUM_SERVER_ERROR = 'IMPRESSUM_SERVER_ERROR';

export const CHANGE_PARTNER_SITE_IMPRESSUM_TEXT = 'CHANGE_PARTNER_SITE_IMPRESSUM_TEXT';

export const FBE_UNINSTALL_REQUEST = 'FBE_UNINSTALL_REQUEST';

export const FBE_UNINSTALL_SUCCESS = 'FBE_UNINSTALL_SUCCESS';

export const FBE_UNINSTALL_FAILURE = 'FBE_UNINSTALL_FAILURE';

export const FBE_STATUS_CHECK_REQUEST = 'FBE_STATUS_CHECK_REQUEST';

export const FBE_STATUS_CHECK_SUCCESS = 'FBE_STATUS_CHECK_SUCCESS';

export const FBE_STATUS_CHECK_FAILURE = 'FBE_STATUS_CHECK_FAILURE';

export const FBE_BUSINESS_CONFIG_REQUEST = 'FBE_BUSINESS_CONFIG_REQUEST';

export const FBE_BUSINESS_CONFIG_SUCCESS = 'FBE_BUSINESS_CONFIG_SUCCESS';

export const FBE_BUSINESS_CONFIG_FAILURE = 'FBE_BUSINESS_CONFIG_FAILURE';

export const FBE_INTEGRATION_COMPLETE = 'FBE_INTEGRATION_COMPLETE';

export const FBE_INSTALLATION_SUCCESS = 'FBE_INSTALLATION_SUCCESS';

export const FBE_INTEGRATION_ASSETS_REQUEST = 'FBE_INTEGRATION_ASSETS_REQUEST';

export const FBE_INTEGRATION_ASSETS_SUCCESS = 'FBE_INTEGRATION_ASSETS_SUCCESS';

export const FBE_INTEGRATION_ASSETS_FAILURE = 'FBE_INTEGRATION_ASSETS_FAILURE';

export const FBE_REINTEGRATION_COMPLETE = 'FBE_REINTEGRATION_COMPLETE';

export const GET_CLIENT_ACQUISITION_REQUEST = 'GET_CLIENT_ACQUISITION_REQUEST';

export const GET_CLIENT_ACQUISITION_RECEIVE = 'GET_CLIENT_ACQUISITION_RECEIVE';

export const GET_CLIENT_ACQUISITION_FAILURE = 'GET_CLIENT_ACQUISITION_FAILURE';

export const START_CAMPAIGN_SELECTION_FLOW = 'START_CAMPAIGN_SELECTION_FLOW';

export const RESET_CAMPAIGN_SELECTION_FLOW = 'RESET_CAMPAIGN_SELECTION_FLOW';

export const SELECT_PROMOTION = 'SELECT_PROMOTION';

export const GET_SMART_DISCOUNTS_REQUEST = 'GET_SMART_DISCOUNTS_REQUEST';

export const GET_SMART_DISCOUNTS_RECEIVE = 'GET_SMART_DISCOUNTS_RECEIVE';

export const GET_SMART_DISCOUNTS_FAILURE = 'GET_SMART_DISCOUNTS_FAILURE';

export const GET_TREATMENT_TYPES_REQUEST = 'GET_TREATMENT_TYPES_REQUEST';

export const GET_TREATMENT_TYPES_RECEIVE = 'GET_TREATMENT_TYPES_RECEIVE';

export const GET_TREATMENT_TYPES_FAILURE = 'GET_TREATMENT_TYPES_FAILURE';

export const EMAIL_TEMPLATE_RESET = 'EMAIL_TEMPLATE_RESET';

export const SELECT_DISCOUNT = 'SELECT_DISCOUNT';

export const SELECT_TREATMENT_GROUP = 'SELECT_TREATMENT_GROUP';

export const BACK_TO_BUSINESS_INIT_STATUS_REQUEST = 'BACK_TO_BUSINESS_INIT_STATUS_REQUEST';

export const BACK_TO_BUSINESS_INIT_STATUS_SUCCESS = 'BACK_TO_BUSINESS_INIT_STATUS_SUCCESS';

export const BACK_TO_BUSINESS_INIT_STATUS_FAILURE = 'BACK_TO_BUSINESS_INIT_STATUS_FAILURE';

export const GET_SMART_DISCOUNTS_RECIPIENT_COUNT_REQUEST =
  'GET_SMART_DISCOUNTS_RECIPIENT_COUNT_REQUEST';

export const GET_SERVICE_ON_SALE_RECIPIENT_COUNT_REQUEST =
  'GET_SERVICE_ON_SALE_RECIPIENT_COUNT_REQUEST';

export const GET_APP_FOR_YOUR_CLIENTS_RECIPIENT_COUNT_REQUEST =
  'GET_APP_FOR_YOUR_CLIENTS_RECIPIENT_COUNT_REQUEST';

export const RECIPIENT_COUNT_RESET = 'RECIPIENT_COUNT_RESET';

export const SEND_SMART_DISCOUNTS_CAMPAIGN_REQUEST = 'SEND_SMART_DISCOUNTS_CAMPAIGN_REQUEST';

export const SEND_SERVICE_ON_SALE_CAMPAIGN_REQUEST = 'SEND_SERVICE_ON_SALE_CAMPAIGN_REQUEST';

export const SEND_APP_FOR_YOUR_CLIENTS_CAMPAIGN_REQUEST =
  'SEND_APP_FOR_YOUR_CLIENTS_CAMPAIGN_REQUEST';

export const SEND_BACK_TO_BUSINESS_CAMPAIGN_REQUEST = 'SEND_BACK_TO_BUSINESS_CAMPAIGN_REQUEST';

export const GET_CLIENT_APP_SHARE_LINK_REQUEST = 'GET_CLIENT_APP_SHARE_LINK_REQUEST';

export const GET_CLIENT_APP_SHARE_LINK_SUCCESS = 'GET_CLIENT_APP_SHARE_LINK_SUCCESS';

export const GET_CLIENT_APP_SHARE_LINK_FAILURE = 'GET_CLIENT_APP_SHARE_LINK_FAILURE';

export const GET_POS_REPORT_DATA_REQUEST = 'GET_POS_REPORT_DATA_REQUEST';

export const GET_POS_REPORT_DATA_RECEIVE = 'GET_POS_REPORT_DATA_RECEIVE';

export const GET_POS_REPORT_DATA_FAILURE = 'GET_POS_REPORT_DATA_FAILURE';

export const GET_PARTNER_SITE_SETTINGS_REQUEST = 'GET_PARTNER_SITE_SETTINGS_REQUEST';

export const GET_PARTNER_SITE_SETTINGS_RECEIVE = 'GET_PARTNER_SITE_SETTINGS_RECEIVE';

export const GET_PARTNER_SITE_SETTINGS_FAILURE = 'GET_PARTNER_SITE_SETTINGS_FAILURE';

export const SELECT_PARTNER_SITE_THEME = 'SELECT_PARTNER_SITE_THEME';

export const TOGGLE_PARTNER_SITE_ENABLED = 'TOGGLE_PARTNER_SITE_ENABLED';

export const CHANGE_PARTNER_SITE_SUBDOMAIN = 'CHANGE_PARTNER_SITE_SUBDOMAIN';

export const CHANGE_PARTNER_SITE_CUSTOM_DOMAIN = 'CHANGE_PARTNER_SITE_CUSTOM_DOMAIN';

export const CHANGE_PARTNER_SITE_FACEBOOK_HANDLE = 'CHANGE_PARTNER_SITE_FACEBOOK_HANDLE';

export const CHANGE_PARTNER_SITE_INSTAGRAM_HANDLE = 'CHANGE_PARTNER_SITE_INSTAGRAM_HANDLE';

export const CHANGE_PARTNER_SITE_TWITTER_HANDLE = 'CHANGE_PARTNER_SITE_TWITTER_HANDLE';

export const SUBMIT_PARTNER_SITE_SETTINGS_REQUEST = 'SUBMIT_PARTNER_SITE_SETTINGS_REQUEST';

export const SUBMIT_PARTNER_SITE_SETTINGS_SUCCESS = 'SUBMIT_PARTNER_SITE_SETTINGS_SUCCESS';

export const SUBMIT_PARTNER_SITE_SETTINGS_FAILURE = 'SUBMIT_PARTNER_SITE_SETTINGS_FAILURE';

export const VALIDATE_PARTNER_SITE_SUBDOMAIN_REQUEST = 'VALIDATE_PARTNER_SITE_SUBDOMAIN_REQUEST';

export const VALIDATE_PARTNER_SITE_SUBDOMAIN_SUCCESS = 'VALIDATE_PARTNER_SITE_SUBDOMAIN_SUCCESS';

export const VALIDATE_PARTNER_SITE_SUBDOMAIN_FAILURE = 'VALIDATE_PARTNER_SITE_SUBDOMAIN_FAILURE';

export const VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_REQUEST =
  'VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_REQUEST';

export const VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_SUCCESS =
  'VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_SUCCESS';

export const VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_FAILURE =
  'VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_FAILURE';

export const SET_SUBDOMAIN_VALIDATION_STATUS_TO_EMPTY = 'SET_SUBDOMAIN_VALIDATION_STATUS_TO_EMPTY';

export const SET_CUSTOM_DOMAIN_VALIDATION_STATUS_TO_EMPTY =
  'SET_CUSTOM_DOMAIN_VALIDATION_STATUS_TO_EMPTY';

export const RESET_PARTNER_SITE_SETTINGS = 'RESET_PARTNER_SITE_SETTINGS';

export const CLEAR_STRIPE = 'CLEAR_STRIPE';

export const INIT_STRIPE_REQUEST = 'INIT_STRIPE_REQUEST';

export const INIT_STRIPE_REQUEST_SUCCESS = 'INIT_STRIPE_REQUEST_SUCCESS';

export const CONFIRM_STRIPE_PAYMENT_REQUEST_SUCCESS = 'CONFIRM_STRIPE_PAYMENT_REQUEST_SUCCESS';

export const STRIPE_PAYMENT_FAILURE = 'STRIPE_PAYMENT_FAILURE';

export const MAKE_CARD_PAYMENT = 'MAKE_CARD_PAYMENT';

export const MAKE_IDEAL_PAYMENT = 'MAKE_IDEAL_PAYMENT';

export const MAKE_IDEAL_PAYMENT_FAILURE = 'MAKE_IDEAL_PAYMENT_FAILURE';

export const VENUE_DETAILS_REQUEST = 'VENUE_DETAILS_REQUEST';

export const VENUE_DETAILS_RECEIVE = 'VENUE_DETAILS_RECEIVE';

export const VENUE_DETAILS_FAILURE = 'VENUE_DETAILS_FAILURE';

export const INVITE_TO_DOWNLOAD_APP_BY_SMS_REQUEST = 'INVITE_TO_DOWNLOAD_APP_BY_SMS_REQUEST';

export const INVITE_TO_DOWNLOAD_APP_BY_SMS_SUCCESS = 'INVITE_TO_DOWNLOAD_APP_BY_SMS_SUCCESS';

export const INVITE_TO_DOWNLOAD_APP_BY_SMS_FAILURE = 'INVITE_TO_DOWNLOAD_APP_BY_SMS_FAILURE';

export const CLEAR_APP_ADVOCACY_BANNER = 'CLEAR_APP_ADVOCACY_BANNER';

export const APP_ADVOCACY_BANNER_HIDE_SUCCESS_OVERLAY = 'APP_ADVOCACY_BANNER_HIDE_SUCCESS_OVERLAY';

export const EMPLOYEE_WORKING_HOURS_TOGGLE = 'EMPLOYEE_WORKING_HOURS_TOGGLE';

export const OPEN_DIALOG = 'OPEN_DIALOG';

export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const OPEN_WITHDRAWAL_DIALOG = 'OPEN_WITHDRAWAL_DIALOG';

export const OPEN_DEPOSIT_DIALOG = 'OPEN_DEPOSIT_DIALOG';

export const OPEN_DAY_CLOSED_NOTIFICATION_DIALOG = 'OPEN_DAY_CLOSED_NOTIFICATION_DIALOG';

export const GET_CSV_EXPORT_REQUEST = 'GET_CSV_EXPORT_REQUEST';

export const GET_CSV_EXPORT_SUCCESS = 'GET_CSV_EXPORT_SUCCESS';

export const GET_POS_EXPORTS_REQUEST = 'GET_POS_EXPORTS_REQUEST';

export const GET_POS_EXPORTS_SUCCESS = 'GET_POS_EXPORTS_SUCCESS';

export const GET_POS_EXPORTS_FAIL = 'GET_POS_EXPORTS_FAIL';

export const POST_GDPDU_EXPORTS_REQUEST = 'POST_GDPDU_EXPORTS_REQUEST';

export const EXPORT_FAILURE = 'EXPORT_FAILURE';

export const GET_NF525_EXPORTS_REQUEST = 'GET_NF525_EXPORTS_REQUEST';

export const GET_SIGNATURE_REQUEST = 'GET_SIGNATURE_REQUEST';

export const GET_SIGNATURE_SUCCESS = 'GET_SIGNATURE_SUCCESS';

export const GET_SIGNATURE_FAILURE = 'GET_SIGNATURE_FAILURE';

export const CLEAR_SIGNATURES = 'CLEAR_SIGNATURES';

export const DAILY_REPORT_REQUEST = 'DAILY_REPORT_REQUEST';

export const DAILY_REPORT_SUCCESS = 'DAILY_REPORT_SUCCESS';

export const DAILY_REPORT_FAILURE = 'DAILY_REPORT_FAILURE';

export const SHOW_VENUE_CLOSING_DIALOG = 'SHOW_VENUE_CLOSING_DIALOG';

export const SHOW_VENUE_OPEN_CONFIRMATION_DIALOG = 'SHOW_VENUE_OPEN_CONFIRMATION_DIALOG';

export const COVID_BANNER_HIDE_ALL_DIALOGS = 'COVID_BANNER_HIDE_ALL_DIALOGS';

export const COVID_UNLIMIT_OPERATION_REQUEST = 'COVID_UNLIMIT_OPERATION_REQUEST';

export const COVID_UNLIMIT_OPERATION_SUCCESS = 'COVID_UNLIMIT_OPERATION_SUCCESS';

export const COVID_UNLIMIT_OPERATION_FAILURE = 'COVID_UNLIMIT_OPERATION_FAILURE';

export const CORONA_REPORT_REQUEST = 'CORONA_REPORT_REQUEST';

export const CORONA_REPORT_SUCCESS = 'CORONA_REPORT_SUCCESS';

export const CORONA_REPORT_FAILURE = 'CORONA_REPORT_FAILURE';

export const CORONA_REPORT_RESET = 'CORONA_REPORT_RESET';

export const VENUE_CLOSING_REQUEST = 'VENUE_CLOSING_REQUEST';

export const VENUE_CLOSING_SUCCESS = 'VENUE_CLOSING_SUCCESS';

export const VENUE_CLOSING_FAILURE = 'VENUE_CLOSING_FAILURE';

export const RESET_COVID_BANNER_STATE = 'RESET_COVID_BANNER_STATE';

export const VENUE_PHOTOS_SUCCESS = 'VENUE_PHOTOS_SUCCESS';

export const UPLOAD_VENUE_PHOTOS_REQUEST = 'UPLOAD_VENUE_PHOTOS_REQUEST';

export const VENUE_PHOTO_UPLOAD_REQUEST = 'VENUE_PHOTO_UPLOAD_REQUEST';

export const VENUE_PHOTO_UPLOAD_SUCCESS = 'VENUE_PHOTO_UPLOAD_SUCCESS';

export const VENUE_PHOTO_UPLOAD_FAILURE = 'VENUE_PHOTO_UPLOAD_FAILURE';

export const VENUE_PHOTO_REMOVE_REQUEST = 'VENUE_PHOTO_REMOVE_REQUEST';

export const VENUE_PHOTOS_ORDER_REQUEST = 'VENUE_PHOTOS_ORDER_REQUEST';

export const VENUE_PHOTO_CROPPING_UPDATE_REQUEST = 'VENUE_PHOTO_CROPPING_UPDATE_REQUEST';

export const VENUE_PHOTO_CROPPING_UPDATE_SUCCESS = 'VENUE_PHOTO_CROPPING_UPDATE_SUCCESS';

export const VENUE_PHOTO_CROPPING_UPDATE_FAILURE = 'VENUE_PHOTO_CROPPING_UPDATE_FAILURE';
