const LANGUAGE_LOCALES = {
  EN: 'en',
  EN_BE: 'en_BE',
  EN_NL: 'en_NL',
  IE: 'en_IE',
  DE: 'de',
  AT: 'de_AT',
  CH: 'de_CH',
  ES: 'es',
  EL: 'el',
  FR: 'fr',
  FR_BE: 'fr_BE',
  IT: 'it',
  NL: 'nl',
  NL_BE: 'nl_BE',
  LT: 'lt',
  DA: 'da',
  LV: 'lv',
  ET_EE: 'et_EE',
  RU: 'ru',
  PT: 'pt',
  VI: 'vi',
};

module.exports = LANGUAGE_LOCALES;
