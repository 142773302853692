export interface GroupPayload {
  menuGroupId: number;
  name: string;
  services: Service[];
}

export interface SearchPayload {
  highlightClass?: string;
  items: GroupPayload[];
  inputValue?: string;
}

export interface ServiceSku {
  durationTimeMins: number;
  finishingTimeMins?: number;
  fullPriceAmount?: number;
  name: string;
  skuId?: number;
  isTempId?: boolean;
}

export interface Service {
  checked?: boolean;
  employees?: number[];
  name: string;
  latinName?: string;
  serviceId: number;
  processingTimeMins?: number;
  skus: ServiceSku[];
  groupId?: number;
}

export interface ServicePayload {
  menuGroupId: number;
  serviceId: number;
  service: Service;
}

export type ServiceEditPayload = Omit<ServicePayload, 'service'>;

export interface SingleGroup {
  name: string;
  menuGroupId: number;
}

export interface MenuGroupsPayload {
  menuGroups: GroupPayload[];
  targetMenuGroupId?: number;
}

export enum ErrorType {
  SERVER = 'server',
  CURRENCY = 'currency',
  MINOR = 'minor',
  EMPLOYEES = 'employees',
  REQUIRED = 'required',
  SERVICE_NAME = 'service-name',
  SERVICE_SKU_NAME = 'service-sku-name',
  SERVICE_SKU_NAME_DUPLICATE = 'service-sku-name-duplicate',
  NONE = 'none',
}

export interface ErrorStack {
  serviceId: number;
  errorType: ErrorType;
}

export interface ErrorPayload {
  serviceId?: number;
  errorType: ErrorType;
}

export interface ServiceEmployee {
  id: number;
  image: any;
  name: string;
}
