import React from 'react';
import Wahanda from 'common/wahanda';
import { Checkbox } from 'components/common/Checkbox';
import { Tooltip, TooltipPlacement } from 'components/common/Tooltip';
import { QuestionMark } from 'components/common/Icon';

import style from './PatchTestRequired.scss';

const lang = Wahanda.lang.menu.offer.patchTestRequired;

export interface Props {
  requiresPatchTest: boolean;
  togglePatchTestRequired: () => void;
}

interface State {
  isChecked: boolean;
}

export class PatchTestRequired extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isChecked: props.requiresPatchTest,
    };
  }

  public render() {
    const { isChecked } = this.state;

    const tooltip = (
      <Tooltip tooltip={lang.tooltip} placement={TooltipPlacement.top}>
        <QuestionMark size="small" className={style.questionIcon} />
      </Tooltip>
    );

    return (
      <div className={style.container}>
        <Checkbox
          name="patch-required-toggle"
          label={lang.title}
          checked={isChecked}
          onChange={this.toggleCheckbox}
        >
          {tooltip}
        </Checkbox>
      </div>
    );
  }

  private toggleCheckbox = () => {
    const { togglePatchTestRequired } = this.props;
    togglePatchTestRequired();
    this.setState((prevState) => ({ isChecked: !prevState.isChecked }));
  };
}
