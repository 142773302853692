/* eslint import/no-extraneous-dependencies:0 import/no-unresolved:0 */

// We need to re-include the vendor javascripts in the main Connect javascript file
// so that some libs are exposed into the global scope (jquery, modernizr, ...)
// That's because we use a lot modules though the global scope, e.g. jQuery.
import 'vendor/../../prebuilt-includes';
import _ from 'common/underscore';
import Mustache from 'mustache';

// Populate the global scope with Vendor libs
window.Mustache = Mustache;
window._ = _;
