/*
 * Every time the form field is changed, sanitize its contents with the given
 * function to only allow input of a certain form.
 * 
 * http://github.com/treyhunner/jquery-formrestrict
 */
(function ($) {
    'use strict';

    var inputEvents = "input";
    if (!("oninput" in document || "oninput" in $("<input>")[0])) {
        inputEvents += " keypress keyup";
    }

    jQuery.fn.restrict = function(sanitizationFunc) {

        return this.each(function(){
            // the element(s) to be restricted
            var $this = $(this);

            $this.bind(inputEvents, function() {
                var val = $this.val();
                var sanitizedVal = sanitizationFunc(val);

                if (val !== sanitizedVal) {
                    $this.val(sanitizedVal);
                }
            });
        });
    };

    /*
     * Every time the form field is changed, modify its contents by eliminating
     * matches for the given regular expression within the field.
     */
    jQuery.fn.regexRestrict = function(regex){
        var sanitize = function(text) {
            return text.replace(regex, '');
        };
        $(this).restrict(sanitize);
    };
    
    // Aditional restrictions
    /**
     * Restrict the field to accept price only.
     * 
	 * @param Object options
	 * > allowNegative (boolean, optional) Allow negative values. Defaults to false.
     */
    jQuery.fn.restrictPrice = function(options) {
    	var regexp = jQuery.fn.restrictPrice.getMatchingRegexp( options && options.allowNegative );
		this.restrict(function(value) {
			var m = value.match(regexp);
			return m ? m[0] : "";
		});
    };
    
    jQuery.fn.restrictPrice.getMatchingRegexp = function(withNegative) {
    	var pattern;
    	if (withNegative) {
    		pattern = '\\-?\\d*(\\.\\d{0,2})?';
    	} else {
    		pattern = '\\d+(\\.\\d{0,2})?';
    	}
    	return new RegExp(pattern);
    };
})(jQuery);
