import { track as sendToSegment } from 'src/tracking/trackingService';

/**
 * Responsible for sending tracking events and data
 * to a third party data processor such as Segment or GTM.
 */
interface TrackingProcessor {
  send: (eventName: string, data: Record<string, any>) => void;
}

export class TestTrackingProcessor implements TrackingProcessor {
  send(eventName: string, data: Record<string, any>) {
    console.log('### TestTrackingProcessor send()', eventName, data);
  }
}

export class SegmentTrackingProcessor implements TrackingProcessor {
  send(eventName: string, data: Record<string, any>) {
    sendToSegment(eventName, data);
  }
}
