/**
 * Settings.
 */
App.Views.Settings.Pos = App.Views.Form.extend({
  isVisible: false,

  initialize: function () {
    this.on('visible', this.onVisible, this).on('hidden', this.onHidden, this);

    this.listenTo(this.model, 'fetched', this.renderIfVisible);
    this.listenTo(App, Wahanda.Event.APP_LOAD_STARTED, this.onConfigLoadStarted);
  },

  onVisible: function () {
    if (!App.config.get('venue').pointOfSaleEnabled) {
      return;
    }
    this.isVisible = true;
    this.injectReactView();
  },

  canNavigateAway: function () {
    return (
      App.ES6.Initializers.POSSettings.canNavigateAway() ||
      confirm(Wahanda.lang.settings.switchTabQuestion)
    );
  },

  onHidden: function () {
    App.ES6.Initializers.POSSettings.unmountPOSSettings(this.el);
    this.isVisible = false;
  },

  injectReactView: function () {
    App.ES6.Initializers.POSSettings.inject(this.el, App.getVenueId());
  },

  renderIfVisible: function () {
    if (this.isVisible) {
      this.injectReactView();
    }
  },

  onConfigLoadStarted: function () {
    if (this.isVisible) {
      App.ES6.Initializers.POSSettings.venueChangeInitiated(this.el);
    }
  },
});
