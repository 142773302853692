export enum PaymentStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export interface LastPaymentResponse {
  lastPayment?: {
    currencyCode: string;
    created: string;
    supplierPaymentId: { id: number };
    payerEmail: string;
    payerName: string;
    amount: number;
    status: PaymentStatus;
  };
}

export interface CompanyResponse {
  bankCode: string;
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  paymentThreshold: number;
}
