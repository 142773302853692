import React from 'react';
import ReviewRow from '../Row';

interface IRowRendererProps extends React.HTMLAttributes<Element> {
  reviews: any[];
  onClick: (...args: any[]) => any;
}

export class RowRenderer extends React.Component<IRowRendererProps, {}> {
  shouldComponentUpdate(nextProps) {
    return this.props.reviews !== nextProps.reviews;
  }

  render() {
    const { reviews, onClick } = this.props;
    return (
      <div>
        {reviews.map((rowData, rowNum) => (
          <ReviewRow {...rowData} key={rowData.id} inline onClick={() => onClick(rowNum)} />
        ))}
      </div>
    );
  }
}
