import React from 'react';
import Wahanda from 'common/wahanda';
import { TooltipSeries } from './types';
import { SvgDonut } from './SvgDonut';
import { ReportTooltip } from '../ReportTooltip';
import style from '../LineChart.scss';

type TooltipProps = {
  heading?: string;
  series: TooltipSeries[];
  seriesFormatter?: (...args: any[]) => any;
};

export const LineChartTooltip = ({ heading, series, seriesFormatter }: TooltipProps) => {
  let total = 0;
  for (let i = 0; i < series.length; i += 1) {
    total += series[i].value;
  }

  return (
    <ReportTooltip
      header={
        !!heading && (
          <div className={style.header}>
            {heading} {Wahanda.Currency.formatFinancial(total)}
          </div>
        )
      }
      content={series.map((item) => (
        <div className={style.tooltipItem} key={item.text}>
          <SvgDonut outerRadius={6} innerRadius={4} outerColor={item.color} innerColor="white" />
          {item.text}&nbsp;
          <span className={style.tooltipAmount}>
            {seriesFormatter ? seriesFormatter(item.value) : item.value}
          </span>
        </div>
      ))}
    />
  );
};
