;(function($) {
	$.widget("wahanda.scrollNotify", {
		// These options will be used as defaults
		options: {
			
		},
		
		timerId: null,
		checkInterval: 500,
		lastScroll: null,
		
		_create: function() {
			this.lastScroll = this.getScroll();
			this.enable();
		},
		
		enable: function() {
			this._startTimer();
		},
		
		disable: function() {
			this._stopTimer();
		},
		
		_startTimer: function() {
			var self  = this;
			this._stopTimer();
			
			var setTimer = function() {
				self.timerId = window.setTimeout(
					function() {
						setTimer();
						self._check();
					},
					self.checkInterval
				);
			}
			setTimer();
		},
		
		_stopTimer: function() {
			if (this.timerId) {
				window.clearTimeout(this.timerId);
				this.timerId = null;
			}
		},
		
		_check: function() {
			var scroll = this.getScroll();
			if (scroll !== this.lastScroll) {
				this.lastScroll = scroll;
				this._trigger(
					'scroll',
					null,
					{
						scrollTop: scroll,
						maxScroll: this.element.get(0).scrollHeight
					}
				);
			}
		},
		
		getScroll: function() {
			return this.element.scrollTop();
		},
		
		destroy: function() {
			this._stopTimer();
			
			$.Widget.prototype.destroy.call( this );
		}
	});
}(jQuery));
