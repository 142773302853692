import { connect } from 'react-redux';
import App from 'common/backbone-app';
import { bindActionCreators } from 'redux';
import * as actions from './store/actions';
import { MenuGroupModal as Component } from './MenuGroupModal';

const mapStateToProps = ({ menu: { menuGroup } }) => ({
  isSubmitting: menuGroup.isSubmitting,
  serverErrorName: menuGroup.serverErrorName,
  showDescription: App.isFeatureSupported('menu-group-descr'),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const MenuGroupModal = connect(mapStateToProps, mapDispatchToProps)(Component);
