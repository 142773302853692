import React from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import { Notice } from 'components/common/Notice';
import { Button } from 'components/common/Button';
import { FinancePaymentsTracking } from './tracking';
import { StripeProvider } from './PaymentContainer/Stripe';
import { PaymentContainer } from './PaymentContainer';
import { PaymentStatus } from './types';
import style from './PaymentMessage.scss';

const lang = Wahanda.lang.settings.supplier.payments;

export interface Props {
  actions: {
    requestBankDetailsAction: (supplierId: number) => void;
    requestLastPaymentStatus: (supplierId: number) => void;
  };
  lastPayment?: {
    status: PaymentStatus;
    date: string;
    amount: number;
    id: number;
  };
  supplierId: number;
  closingBalance: number;
  reFetchRemittances: () => void;
  paymentDueDays: number;
  paymentReference: string;
  payNow: boolean;
}

interface State {
  isDialogOpen: boolean;
}

export class PaymentMessage extends React.PureComponent<Props, State> {
  public static defaultProps = {
    payNow: false,
    lastPayment: undefined,
  };

  public state = {
    isDialogOpen: this.props.payNow,
  };

  public componentDidUpdate(prevProps: Props) {
    const { closingBalance, lastPayment } = this.props;
    if (
      prevProps.lastPayment == null &&
      lastPayment != null &&
      lastPayment.status === PaymentStatus.SUCCESSFUL &&
      moment(lastPayment.date).isAfter(moment().subtract(1, 'minutes')) &&
      closingBalance > 0
    ) {
      FinancePaymentsTracking.trackInconsistentData();
    }
  }

  public componentDidMount() {
    const { supplierId } = this.props;
    this.props.actions.requestBankDetailsAction(supplierId);
    this.props.actions.requestLastPaymentStatus(supplierId);
  }

  public handleClick = () => {
    FinancePaymentsTracking.trackMakePaymentClick();
    this.setState({
      isDialogOpen: true,
    });
  };

  public handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
    });
  };

  public renderDialog() {
    const { isDialogOpen } = this.state;
    const { closingBalance, reFetchRemittances, paymentReference } = this.props;

    if (!isDialogOpen) {
      return null;
    }

    return (
      <StripeProvider closingBalance={closingBalance}>
        <PaymentContainer
          paymentReference={paymentReference}
          closingBalance={closingBalance}
          reFetchRemittances={reFetchRemittances}
          onClose={this.handleDialogClose}
        />
      </StripeProvider>
    );
  }

  public renderPayUsMessage() {
    const { closingBalance, paymentDueDays } = this.props;
    if (closingBalance <= 0) {
      return null;
    }

    const boldMessage = Wahanda.Template.render(lang.remittanceBalanceNote, {
      closingBalance: Wahanda.Currency.format(closingBalance),
    });

    const dayString = lang.remittanceDay ? lang.remittanceDays : null;
    const daysString = lang.remittanceDays ? lang.remittanceDays : null;

    const dueDays = `${paymentDueDays} ${paymentDueDays === 1 ? dayString : daysString}`;
    const remittanceNumberUnpaidNote = Wahanda.Template.render(lang.remittanceNumberUnpaidNote, {
      dueDateDays: dueDays,
    });

    return (
      <div className={style.message}>
        <Notice dataTest="pay-us-message" type="alert">
          <div className={style.attention}>
            <strong>{boldMessage}</strong>
            <div>{remittanceNumberUnpaidNote}</div>
            <div className={style.button}>
              <Button label={lang.payNowButton} onClick={this.handleClick} />
            </div>
          </div>
        </Notice>
      </div>
    );
  }

  public renderPaymentStatusMessage() {
    const { lastPayment } = this.props;
    if (!lastPayment) {
      return null;
    }
    const { amount, date, id } = lastPayment;

    switch (lastPayment.status) {
      case PaymentStatus.SUCCESSFUL:
        return (
          <Notice dataTest="payment-succeeded-message" type="success">
            <div
              dangerouslySetInnerHTML={{
                __html: Wahanda.Template.render(lang.paymentStatus.complete, {
                  date: moment(date).formatDateDefault(),
                  id,
                  amount: Wahanda.Currency.format(amount),
                }),
              }}
            />
          </Notice>
        );
      case PaymentStatus.PENDING:
        return (
          <Notice dataTest="payment-ongoing-message">
            <div
              dangerouslySetInnerHTML={{
                __html: Wahanda.Template.render(lang.paymentStatus.pending, {
                  date: moment(date).formatDateDefault(),
                  id,
                  amount: Wahanda.Currency.format(amount),
                }),
              }}
            />
          </Notice>
        );
      case PaymentStatus.FAILED:
        return (
          <Notice dataTest="payment-failed-message" type="alert">
            <div
              dangerouslySetInnerHTML={{
                __html: Wahanda.Template.render(lang.paymentStatus.failed, {
                  date: moment(date).formatDateDefault(),
                  id,
                }),
              }}
            />
          </Notice>
        );
      default:
        return null;
    }
  }

  public render() {
    return (
      <div>
        {this.renderDialog()}
        {this.renderPayUsMessage()}
        {this.renderPaymentStatusMessage()}
      </div>
    );
  }
}
