import React from 'react';
import { Portal } from 'react-portal';
import { Close } from 'components/common/Icon';
import { Button } from 'components/common/Button';
import { ProgressBar } from 'components/common/ProgressBar';
import { LogoConnect, LogoTreatwell } from 'components/common/IconPicture';
import { useWizardModal } from 'components/common/WizardModal/WizardModal.hooks';
import type { WizardModalProps } from 'components/common/WizardModal/WizardModal.types';
import { ConfirmationModal } from 'components/common/WizardModal/ConfirmationModal';
import { Spinner } from 'components/common/Spinner';
import style from './WizardModal.scss';
import Wahanda from 'common/wahanda';
import { Badge } from '../Badge';
import { useBreakpoint } from 'utilities/hooks';

const lang = Wahanda.lang.shared.wizardModal;

export const WizardModal: React.FC<WizardModalProps> = ({
  steps,
  onClose,
  onSubmit,
  lastStepLocale,
  showSidebar = false,
}) => {
  const {
    currentStep,
    handleNextStep,
    handlePreviousStep,
    isOpen,
    isSubmitting,
    showConfirmationModal,
    hideConfirmationModal,
    handleClose,
    goToStep,
  } = useWizardModal({
    steps,
    onClose,
    onSubmit,
  });
  const isLastStep = currentStep === steps.length - 1;
  const progress = (100 / steps.length) * (currentStep + 1);
  const isDesktop = useBreakpoint('desktop');

  return (
    <Portal>
      <div className={style.wrapper}>
        <h1 className={style.header}>
          <span>
            <LogoTreatwell className={style.logo} />
            <LogoConnect className={style.logo} />
          </span>
          <span
            className={style.container}
            role="button"
            onClick={showConfirmationModal}
            tabIndex={0}
          >
            <Close size="small" />
          </span>
        </h1>
        <ProgressBar value={progress} />
        {isDesktop && showSidebar ? (
          <div className={style.contentSidebar}>
            <aside className={style.sidebar}>
              <ul>
                {steps.map((step, index) => (
                  <li
                    key={index}
                    className={`${style.step} ${index === currentStep ? style.active : ''}`}
                    onClick={index < currentStep ? () => goToStep(index) : undefined}
                  >
                    <Badge
                      className={style.sidebarBadge}
                      text={index + 1}
                      type={index === currentStep ? 'success' : 'muted'}
                      isLight
                    />
                    <div className={style.sidebarTextBlock}>
                      <h3>{step.title}</h3>
                      <span>{step.description}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </aside>

            <div className={style.stepContent}>{steps[currentStep].element}</div>
          </div>
        ) : (
          <div className={style.content}>{steps[currentStep].element}</div>
        )}

        <footer className={style.footer}>
          {currentStep >= 1 ? (
            <Button
              label={lang.previousStep}
              colour="plain"
              variant="secondary"
              size="large"
              onClick={handlePreviousStep}
            />
          ) : (
            <div />
          )}
          {isSubmitting ? (
            <Spinner dark />
          ) : (
            <Button
              label={
                isLastStep ? lastStepLocale : steps[currentStep].nextStepLabel || lang.nextStep
              }
              disabled={steps[currentStep].nextStepDisabled}
              size="large"
              onClick={handleNextStep}
            />
          )}
        </footer>
      </div>
      {isOpen && <ConfirmationModal onExit={handleClose} onContinue={hideConfirmationModal} />}
    </Portal>
  );
};
