import { getIn, assoc, toClj } from 'mori';
import { appState } from 'state';
import moment from 'common/moment';
import { now, toDateString } from 'common/datetime';
import Wahanda from 'common/wahanda';
import result from 'lodash/result';
import isEmpty from 'lodash/isEmpty';

/**
 * Fetch the POS status from Wahanda.Cache() and put it into the store.
 */
export function fetch() {
  if (App.isRestrictedMode()) {
    return;
  }

  Wahanda.Cache.posStatus().done((data) =>
    appState.swap((state) => assoc(state, 'pos-status', toClj(data))),
  );
}

export function isPreviousDayOpen(activeDate) {
  const openDay = getIn(appState.deref(), ['pos-status', 'openDay']);

  if (openDay) {
    return openDay < toDateString(activeDate);
  }
  return false;
}

export function isTodayClosed(state = appState.deref()) {
  const closedDay = getIn(state, ['pos-status', 'lastClosedDay']);

  return closedDay === toDateString(moment.venueTimeZoneDate());
}

export function closedOrOpenedDayInFuture(activeDate, posStatus) {
  const closedDay = result(posStatus, 'lastClosedDay');
  const openedDay = result(posStatus, 'openDay');

  if (isEmpty(posStatus)) {
    return null;
  }
  if (closedDay || openedDay) {
    return closedDay >= activeDate || openedDay > activeDate;
  }
  return false;
}

export const hasTodayOpened = (state = appState.deref()) =>
  getIn(state, ['pos-status', 'openDay']) === toDateString(now());

export function hasOpenDay(state = appState.deref()) {
  return !!getIn(state, ['pos-status', 'openDay']);
}
