/* global BackboneEx */
import { trackEvent } from 'common/analytics';

App.Views.Settings.VenueDetails.Map = BackboneEx.View.Dialog.extend({
  events: {
    'click .button-cancel': 'close',
    'click .save-action': 'savePosition',
    'click .a-geocode': 'geocode',
  },

  templateId: 'venue-details-map-template',
  markerUrl: '/assets/map-marker.png',

  options: {
    width: 800,
    modal: false,
    title: Wahanda.lang.settings.venue.details.map.title,
    dataTest: 'map-modal',
  },

  postInitialize() {
    const self = this;
    this.$el.on('dialogopen', function () {
      if (self.map) {
        google.maps.event.trigger(self.map, 'resize');
        self.map.setCenter(self._getMapCenter());
      }
    });
  },

  setupDialog() {
    const options = {
      zoom: parseInt(this.options.scale),
      center: this._getMapCenter(),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    let zIndex = 3000;
    const $mapContainer = this.$('#venue-details-map-container');
    $mapContainer.css('z-index', zIndex++);

    this.map = new google.maps.Map($mapContainer.get(0), options);

    this.marker = new google.maps.Marker({
      map: this.map,
      title: Wahanda.lang.settings.venue.details.markerTitle,
      icon: new google.maps.MarkerImage(
        this.markerUrl,
        new google.maps.Size(26, 34),
        null,
        new google.maps.Point(13, 34),
      ),
      position: this._getPinPosition(),
      draggable: true,
      zIndex: zIndex++,
    });

    this.renderAddress();
  },

  renderAddress() {
    let address = null;
    if (this.options.address.address) {
      const parts = [this.options.address.address];
      if (this.options.address.postCode) {
        parts.push(this.options.address.postCode);
      }
      if (this.options.address.country) {
        parts.push(this.options.address.country);
      }
      address = _.map(parts, function (part) {
        return $.trim(part);
      }).join(', ');
    }
    this.renderAttribute('full-address', address, true);
  },

  _getMapCenter() {
    return new google.maps.LatLng(this.options.mapLat, this.options.mapLng);
  },

  _getPinPosition() {
    return new google.maps.LatLng(this.options.lat, this.options.lng);
  },

  savePosition() {
    trackEvent('venue-details', 'submit', 'save-venue-map');
    const position = this.marker.getPosition();
    const mapPosition = this.map.getCenter();
    this.options.onSave(
      position.lat(),
      position.lng(),
      mapPosition.lat(),
      mapPosition.lng(),
      this.map.getZoom(),
    );

    this.close();
  },

  /**
   * Tries to geocode the address part.
   */
  geocode() {
    const self = this;
    const opts = {
      address: this.$('.v-full-address').text(),
      success(results) {
        self._geocodeSetPosition(results);
      },
      error(results, errorCode) {
        self._geocodeShowError(errorCode);
      },
    };
    Wahanda.Geocoder.getResult(opts);
    this._geocodeToggleSearching(true);
  },

  _geocodeToggleSearching(isSearching) {
    const $map = this.$('#venue-details-map-container');
    const $button = this.$('.a-geocode');
    if (isSearching) {
      $map.loadmask();
      $button.disableFormElements();
    } else {
      $map.unloadmask();
      $button.enableFormElements();
    }
  },

  _geocodeSetPosition(results) {
    this._geocodeToggleSearching(false);

    const result = results[0];
    const point = result.geometry.location;

    this.marker.setPosition(point);
    this.map.setCenter(point);
  },

  _geocodeShowError(errorCode) {
    this._geocodeToggleSearching(false);
    this.$('.a-geocode').noticeTip(Wahanda.lang.settings.venue.details.map.errors.geocodeError);
  },
});
