/* global BackboneEx */

/**
 * Venue microsite settings.
 */
App.Models.Microsite = BackboneEx.Model.Silent.extend({
  structure: {
    micrositeEnabled: 'boolean',
  },

  url: () => App.Api.wsVenueUrl('/microsite-settings.json'),
});
