import React from 'react';
import App from 'common/backbone-app';
import {
  ClientFormsEditableValues,
  ClientFormsCreatePayloadValues,
  JsonSchemaCustomConsultationForm,
} from './types';
import Wahanda from 'common/wahanda';
import DOMPurify from 'dompurify';

const lang = Wahanda.lang.marketing.clientForms;
const DISCLAIMER_TEXT = lang.disclaimerText;

export const getClientFormCreateJsonSchemaTemplate = (
  { title, htmlText }: Pick<ClientFormsEditableValues, 'title' | 'htmlText'>,
  options?: { preview?: boolean },
): JsonSchemaCustomConsultationForm => ({
  title,
  description: '',
  type: 'object',
  required: ['personalInformation', 'signature'],
  properties: {
    formContent: {
      title: '',
      type: 'object',
      properties: {
        contentBlock: {
          title: '',
          type: 'object',
          properties: {
            content: {
              title: '',
              type: 'object',
              description: options?.preview ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(htmlText),
                  }}
                />
              ) : (
                htmlText
              ),
            },
          },
        },
      },
    },
    personalInformation: {
      title: 'Personal Information',
      description: '',
      type: 'object',
      required: ['firstName', 'lastName', 'phoneNumber', 'emailAddress', 'dateOfBirth', 'address'],
      properties: {
        firstName: {
          type: 'string',
          title: 'First Name',
          maxLength: 100,
        },
        lastName: {
          type: 'string',
          title: 'Last Name',
          maxLength: 100,
        },
        dateOfBirth: {
          type: 'string',
          title: 'Date of birth',
          format: 'date',
        },
        address: {
          type: 'string',
          title: 'Address',
          maxLength: 100,
        },
        phoneNumber: {
          type: 'string',
          title: 'Phone Number',
          maxLength: 100,
        },
        emailAddress: {
          type: 'string',
          title: 'Email Address',
          maxLength: 100,
        },
      },
    },
    signature: {
      title: '',
      type: 'object',
      properties: {
        confirmed: {
          type: 'boolean',
          title: 'I have read and understood the above',
        },
      },
    },
    footnote: {
      title: '',
      type: 'object',
      description: `Disclaimer: ${DISCLAIMER_TEXT}`,
    },
  },
});

export const getClientFormCreateRequestPayload = ({
  title,
  description,
  htmlText,
  serviceIds,
}: ClientFormsEditableValues): ClientFormsCreatePayloadValues => {
  return {
    title,
    description,
    jsonSchema: getClientFormCreateJsonSchemaTemplate({ title, htmlText }),
    uiSchema: {},
    serviceIds,
    venueId: App.getVenueId(),
    language: App.getLocaleForChannelCode(App.venueChannelCode()),
  };
};
