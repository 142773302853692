import React from 'react';

interface Props {
  className?: string;
}

class SuccessWarning extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-success-warning', this.props.className].join(' ')}
        viewBox="0 0 48 48"
      >
        <g fill="none" fillRule="evenodd">
          <path
            d="M42.592 11.07a2.585 2.585 0 0 0-3.655 0L25.018 24.987a1.477 1.477 0 0 1-2.089 0l-6.005-6.005a2.585 2.585 0 1 0-3.655 3.655l8.094 8.094a3.692 3.692 0 0 0 5.222 0l16.007-16.008a2.585 2.585 0 0 0 0-3.655z"
            stroke="#001E62"
            fill="#3ba150"
            strokeWidth="2"
          />
          <path
            d="M24 0c5.186 0 10.123 1.65 14.207 4.655l.393.295-1.218 1.587A21.896 21.896 0 0 0 24 2C11.85 2 2 11.85 2 24s9.85 22 22 22c.943 0 1.872-.06 2.784-.175.115.698.376 1.348.75 1.917C26.38 47.912 25.2 48 24 48 10.745 48 0 37.255 0 24S10.745 0 24 0zm15.295 30.412l.162.084.18.108a3 3 0 0 1 .985 1.057l6.601 11.882A3 3 0 0 1 44.601 48H31.399a3 3 0 0 1-2.622-4.457l6.6-11.882a3 3 0 0 1 3.918-1.25zm-2.17 2.22l-6.6 11.882A1 1 0 0 0 31.399 46h13.202a1 1 0 0 0 .874-1.486l-6.6-11.881a1 1 0 0 0-.295-.33l-.094-.059-.106-.05a1 1 0 0 0-1.254.439zM38 42a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-7a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm7.107-17.226l1.919-.565C47.67 19.396 48 21.677 48 24c0 4.258-1.11 8.258-3.054 11.725l-1.148-2.12A21.912 21.912 0 0 0 46 24c0-1.938-.25-3.841-.74-5.677l-.153-.549 1.919-.565z"
            fill="#001E62"
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  }
}

export const icon = SuccessWarning;
