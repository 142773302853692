import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './store/actions';
import {
  treatmentsListSelector,
  treatmentTypesListSelector,
  numberOfTreatmentsListSelector,
  numberOfTreatmentTypesListSelector,
} from './store/selectors';

import { SelectServiceDialog as Component } from './SelectServiceDialog';

const transformTreatmentsList = (selectService) => ({
  ...selectService,
  treatmentsList: treatmentsListSelector(selectService),
});

const transformTreatmentTypesList = (selectService) => ({
  ...selectService,
  treatmentTypesList: treatmentTypesListSelector(selectService),
});

const transformNumberOfTreatmentsList = (selectService) => ({
  ...selectService,
  numberOfTreatments: numberOfTreatmentsListSelector(selectService),
});

const transformNumberOfTreatmentTypesList = (selectService) => ({
  ...selectService,
  numberOfTreatmentTypes: numberOfTreatmentTypesListSelector(selectService),
});

export const transform = (state) =>
  transformNumberOfTreatmentTypesList(
    transformNumberOfTreatmentsList(transformTreatmentTypesList(transformTreatmentsList(state))),
  );

const mapStateToProps = ({ selectService }) => transform(selectService);

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const SelectServiceDialog = connect(mapStateToProps, mapDispatchToProps)(Component);
