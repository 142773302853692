App.Collections.SpaAllocations = Backbone.Collection.extend({
  model: App.Models.SpaAllocation,

  // URL parameters
  dateFrom: null,
  dateTo: null,
  include: null,

  url: function () {
    var base =
      '/venue/' +
      App.config.get('venue').id +
      '/spa-allocations.json?' +
      'date-from=' +
      this._toApiDate(this.dateFrom) +
      '&date-to=' +
      this._toApiDate(this.dateTo);
    if (this.include) {
      base += '&include=' + this.include;
    }
    return App.Api.wsUrl(base);
  },

  /**
   * Returns SpaAllocation model by date.
   *
   * @param String dateString
   * @return App.Models.SpaAllocation
   */
  getByDate: function (dateString) {
    var model = this.get(dateString);
    if (!model) {
      model = new App.Models.SpaAllocation({
        date: dateString,
        inventoryAvailable: 0,
        inventoryReserved: 0,
        inventoryBooked: 0,
        closed: false,
      });
      model.notSet = true;
      this.add(model);
    }
    return model;
  },

  _toApiDate: function (date) {
    if (!date) {
      return '';
    }
    return typeof date === 'string' ? date : Wahanda.Date.toApiString(date);
  },

  parse: function (data) {
    return data.allocations;
  },

  getSoldOut: function () {
    return this.where({ inventoryAvailable: 0 });
  },
});
