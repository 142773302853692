import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Products extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-products', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M9,3 L9,7 L12.0046024,7 C13.1072445,7 14,7.89581564 14,8.99850233 L14,20.0014977 C14,21.1026503 13.0992268,22 12.0046024,22 L3.99539757,22 C2.89275553,22 2,21.1041844 2,20.0014977 L2,8.99850233 C2,7.8973497 2.90077319,7 3.99539757,7 L7,7 L7,3 L9,3 Z M20.009222,14 C21.107313,14 22,14.8972855 22,15.990778 L22,20.009222 C22,21.107313 21.1027145,22 20.009222,22 L16,22 L16,20 L20,20 L20,16 L16,16 L16,14 L20.009222,14 Z M12,9 L4.00013238,9 C3.99860493,9 3.99856081,12.6666667 4,20 L11.9998676,20 C12.0013951,20 12.0014392,16.3333333 12,9 Z M10,12 L10,14 L6,14 L6,12 L10,12 Z M11,2 L11,4 L7,4 C6.28498711,4 5.68714885,4.14945957 5.1972136,4.39442719 C5.03370535,4.47618131 4.89702274,4.56160794 4.7875,4.64375 C4.73284413,4.6847419 4.70586437,4.70834919 4.70710678,4.70710678 L3.29289322,3.29289322 C3.48996291,3.09582352 3.82531879,2.84430662 4.3027864,2.60557281 C5.06285115,2.22554043 5.96501289,2 7,2 L11,2 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Products);
