import React, { useCallback, useEffect, useState, useRef } from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import classnames from 'classnames';
import find from 'lodash/find';
import { Button } from 'components/common/Button';
import { Chevron } from 'components/common/Icon';
import Loader from 'components/common/Loader';
import { Spinner } from 'components/common/Spinner';
import { Employee, VenueShiftsObject, WeekDays, WorkingHoursObject } from '../utils/types';
import { countHoursPerWeek, getWeekdaysMapped, getWorkingHoursWithRange } from '../utils/helpers';
import { SCHEDULE_ROW_TYPE, DAYS_PER_WEEK } from '../utils/constants';
import { ShiftsAnalytics } from '../tracking';
import { ShiftEmployeeSelector } from '../ShiftEmployeeSelector';
import { ShiftRowCell } from '../ShiftRowCell';
import { ShiftNavigationButtons } from '../ShiftNavigationButtons';
import style from './ShiftEmployeeMonth.scss';

interface Props {
  employeeId: number;
  onClose: () => void;
  onClick: (param) => void;
  // redux
  actions: {
    getEmployeeMonthWorkingHours: (param) => void;
  };
  employees: Employee[];
  isLoading: boolean;
  venue: VenueShiftsObject;
}

export const ShiftEmployeeMonth = ({
  actions,
  employeeId,
  employees,
  isLoading,
  onClose,
  onClick,
  venue,
}: Props) => {
  const observer = useRef(false);
  const [userId, setUserId] = useState(employeeId);
  const [dates, setDates] = useState({
    month: moment().formatMonth(),
    date: moment().formatApiDateString(),
    dateFrom: moment().startOf('month').startOf('isoWeek').formatApiDateString(),
    dateTo: moment().endOf('month').endOf('isoWeek').formatApiDateString(),
  });
  const employee: Employee = find(employees, { employeeId: userId });
  const wHours = getWorkingHoursWithRange({
    datePickerRange: {
      dateFrom: moment(dates.date).startOf('month').formatApiDateString(),
      dateTo: moment(dates.date).endOf('month').formatApiDateString(),
    },
    workingHours: employee.workingHours,
  });
  const hoursPerWeek = countHoursPerWeek(wHours);
  const handleDataLoad = useCallback(() => {
    actions.getEmployeeMonthWorkingHours({
      ...dates,
      employeeId: userId,
    });
  }, [actions, dates, userId]);

  const handleDataPopulate = useCallback(() => {
    return getWorkingHoursWithRange({
      datePickerRange: dates,
      workingHours: employee.workingHours,
    });
  }, [employee.workingHours, dates]);

  const [employeeWorkingHours, setEmployeeWorkingHours] = useState(handleDataPopulate());
  const mappedDates = (): WeekDays[] =>
    getWeekdaysMapped({
      start: moment().startOf('isoWeek'),
      end: moment().endOf('isoWeek').add(1, 'd'),
    });
  const formatCalendarFirstDate = (date: moment.Moment): string =>
    date.startOf('month').startOf('isoWeek').formatApiDateString();
  const formatCalendarLastDate = (date: moment.Moment): string =>
    date.endOf('month').endOf('isoWeek').formatApiDateString();
  const increaseMonth = (date: string): moment.Moment => moment(date).add(1, 'months');
  const decreaseMonth = (date: string): moment.Moment => moment(date).subtract(1, 'months');
  const handleMonthChange = (monthWithDate) => {
    setDates({
      date: moment(monthWithDate).formatApiDateString(),
      month: moment(monthWithDate).formatMonth(),
      dateFrom: formatCalendarFirstDate(moment(monthWithDate)),
      dateTo: formatCalendarLastDate(moment(monthWithDate)),
    });
  };
  const goToPrevMonth = () => {
    setDates({
      date: decreaseMonth(dates.date).formatApiDateString(),
      month: decreaseMonth(dates.date).formatMonth(),
      dateFrom: formatCalendarFirstDate(decreaseMonth(dates.date)),
      dateTo: formatCalendarLastDate(decreaseMonth(dates.date)),
    });
    ShiftsAnalytics.trackMonthPrevClick();
  };
  const goToNextMonth = () => {
    setDates({
      date: increaseMonth(dates.date).formatApiDateString(),
      month: increaseMonth(dates.date).formatMonth(),
      dateFrom: formatCalendarFirstDate(increaseMonth(dates.date)),
      dateTo: formatCalendarLastDate(increaseMonth(dates.date)),
    });
    ShiftsAnalytics.trackMonthNextClick();
  };
  const goToThisMonth = () => {
    handleMonthChange(moment().formatApiDateString());
    ShiftsAnalytics.trackMonthCurrentClick();
  };
  const handleOnCellClick = (workingHours: WorkingHoursObject, ele: any) => {
    const element = ele?.current;
    if (!(element instanceof Element)) {
      return;
    }
    if (onClick) {
      onClick({
        date: workingHours.date,
        popoverOffset: element?.getBoundingClientRect(),
        timeSlots: workingHours.timeSlots,
        type: workingHours.type,
        title: employee.employeeName,
        employeeId: employee.employeeId,
        image: employee.employeeImage,
      });
    }
  };
  const handleEmployeeChange = (id) => {
    setUserId(id);
    ShiftsAnalytics.trackEmployeeChangeClick();
  };
  const showCalendar = employeeWorkingHours.length > DAYS_PER_WEEK;
  const showGoToThis = moment(dates.date).formatMonthYear() !== moment().formatMonthYear();

  useEffect(() => {
    handleDataLoad();
  }, [handleDataLoad]);

  useEffect(() => {
    if (!isLoading) {
      setEmployeeWorkingHours(handleDataPopulate());
    }
  }, [employees, handleDataPopulate, isLoading]);

  useEffect(() => {
    if (observer.current) {
      handleDataLoad();
    } else {
      observer.current = true;
    }
  }, [dates, handleDataLoad, userId]);

  return (
    <>
      <div className={style.backButton}>
        <Button
          icon={<Chevron className={style.backIcon} />}
          onClick={onClose}
          variant="hollow"
          colour="help"
          label={Wahanda.lang.settings.shifts.goToOverview}
        />
      </div>
      <ShiftEmployeeSelector onClick={handleEmployeeChange} employeeId={userId} />

      <div className={style.actions}>
        <div>
          <div className={style.month}>
            {dates.month} {moment(dates.date).format('YYYY')}
          </div>
          <div className={style.perWeek}>
            {isLoading ? (
              <Spinner dark size="tiny" />
            ) : (
              <>
                {hoursPerWeek} {Wahanda.lang.settings.shifts.perMonth}
              </>
            )}
          </div>
        </div>

        <ShiftNavigationButtons
          buttonText={Wahanda.lang.settings.shifts.goToThisMonth}
          onGoToNext={goToNextMonth}
          onGoToPrev={goToPrevMonth}
          onGoToThis={goToThisMonth}
          showGoToThis={showGoToThis}
        />
      </div>

      <div className={classnames(style.weekRow, style.rowHead)}>
        {mappedDates().map((day, index) => {
          return (
            <div className={style.gridCol} key={`${day}-${index}`}>
              {Wahanda.lang.date.weekdaysByName[day]}
            </div>
          );
        })}
      </div>
      {showCalendar && (
        <div className={style.grid}>
          {employeeWorkingHours.map((workingDay, index) => {
            const isClear =
              moment(workingDay.date).isBefore(moment(dates.date).startOf('month')) ||
              moment(workingDay.date).isAfter(moment(dates.date).endOf('month'));
            return (
              <ShiftRowCell
                gridClassName={style.gridCol}
                key={index}
                index={index}
                workingHours={workingDay}
                onClick={handleOnCellClick}
                type={SCHEDULE_ROW_TYPE.STYLIST}
                venue={venue}
                isLoading={false}
                isClear={isClear}
                showMonthDate
                canEditEmployeeHours
                canEditVenueHours={false}
              />
            );
          })}
        </div>
      )}
      {isLoading && <Loader positionAbsolute />}
    </>
  );
};
