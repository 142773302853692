import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class CameraAdd extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-camera-add', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M19 7.046v.006-.006zM11 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm8 1h2v3h3v2h-3v3h-2v-3h-3v-2h3v-3zm-6 3v2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3V4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1h3a2 2 0 0 1 2 2v5h-2V7h-3a2 2 0 0 1-2-2V4H8v1a2 2 0 0 1-2 2H3v11h10z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(CameraAdd);
