require('./tab-base.js');
require('../treatment-pricing-and-employees.js');
App.Views.Forms.MenuOffer2.PricingTab = App.Views.Forms.MenuOffer2.TabBase.extend(
  {
    tabName: Wahanda.lang.menu.offer.tabs.pricing.title,
    pricingView: null,
    tabClasses: 'offer2--pricing',
    templateId: 'menu-offer2-pricing-template',

    containerRendered: false,
    $pricingEl: null,
    $employeesEl: null,
    subviews: null,

    initialize: function () {
      App.Views.Forms.MenuOffer2.TabBase.prototype.initialize.call(this);
      this.listenTo(this.model, 'update', this.render);

      // Build the views based on the type
      this.subviews = [];
      this.subviews.push({
        view: new App.Views.Forms.MenuOffer2.Pricing.ServiceDetails(this.options),
        target: '.b-service-details',
      });
      if (this.model.isEscapeWithRangePricing()) {
        this.subviews.push({
          view: new App.Views.Forms.MenuOffer2.Pricing.Dated(this.options),
          target: '.b-pricing',
        });
      } else if (this.model.isServicePackage()) {
        this.subviews.push(
          {
            view: new App.Views.Forms.MenuOffer2.Pricing.PackageServices(this.options),
            target: '.b-package-services',
          },
          {
            view: new App.Views.Forms.MenuOffer2.Pricing.PackagePricing(this.options),
            target: '.b-pricing',
          },
        );
      } else {
        this.subviews.push({
          view: new App.Views.Forms.MenuOffer2.TreatmentPricingAndEmployees(this.options),
          target: '.b-pricing',
        });
        this.$('.b-employees').hide();
      }
    },

    render: function () {
      if (!this.containerRendered) {
        this.containerRendered = true;
        this.initContainer();
      }

      _.each(
        this.subviews,
        function (viewData) {
          const $el = this.$(viewData.target);
          if ($el !== viewData.view.$el) {
            viewData.view.setElement($el);
          }
          viewData.view.render();
        },
        this,
      );
    },

    initContainer: function () {
      this.$el.html(
        Wahanda.Template.renderTemplate(this.templateId, {
          showEmployeeBlock: !this.model.isServicePackage(),
        }),
      );
    },

    remove: function () {
      // Call remove() to stop listening for events
      _.each(this.subviews, (viewData) => {
        viewData.view.remove();
      });

      App.Views.Forms.MenuOffer2.TabBase.prototype.remove.call(this);
    },

    getValues: function () {
      const values = {};
      _.each(this.subviews, (viewData) => {
        _.extend(values, viewData.view.getValues(values));
      });
      return values;
    },
  },
  {
    optionsToInherit: ['employeeCategories', 'treatments', 'menu', 'employees', 'roomTypes'],
  },
);
