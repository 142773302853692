import { combineReducers } from 'redux';
import {
  GET_MASS_EMAIL_COUNT_REQUEST,
  GET_MASS_EMAIL_COUNT_RECEIVE,
  GET_MASS_EMAIL_COUNT_FAILURE,
} from 'reduxStore/actionsConst';

import campaignTemplate from './CampaignPreview/reducers';
import campaignSummary from './CampaignSummary/reducers';
import campaignSelection from './CampaignSelection/reducer';

const campaignGeneral = (state = { count: null }, action) => {
  switch (action.type) {
    case GET_MASS_EMAIL_COUNT_REQUEST:
      return {
        ...state,
      };
    case GET_MASS_EMAIL_COUNT_RECEIVE:
      return {
        ...state,
        count: action.count,
      };
    case GET_MASS_EMAIL_COUNT_FAILURE:
      return {
        ...state,
        countError: action.data,
      };
    default:
      return state;
  }
};

const emailCampaign = combineReducers({
  campaignGeneral,
  campaignTemplate,
  campaignSummary,
  campaignSelection,
});

export default emailCampaign;
