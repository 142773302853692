import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import SalesforceBot from 'common/salesforce-bot/SalesforceBot';

export const SalesforceBotInitializer = (): Initializer => {
  const node = getSalesforceBotNode();
  return {
    destroy: () => ReactDOM.unmountComponentAtNode(node),
    render: () => {
      ReactDOM.render(<SalesforceBot />, node);
    },
  };
};

const SALESFORCE_BOT_ID = 'salesforce-bot';
const getSalesforceBotNode = () => {
  if (document.getElementById(SALESFORCE_BOT_ID)) {
    return document.getElementById(SALESFORCE_BOT_ID)!;
  }

  const node = document.createElement('div');
  node.id = SALESFORCE_BOT_ID;

  document.body.appendChild(node);

  return node;
};
