import React from 'react';
import classnames from 'classnames';
import style from './style.scss';

const CRITICAL_BOUND = 0;
const RUNNING_LOW_BOUND = 5;
interface IInStockIndicatorProps extends React.HTMLAttributes<Element> {
  amount?: number;
}

export default class InStockIndicator extends React.Component<IInStockIndicatorProps, {}> {
  static defaultProps = {
    amount: null,
  };

  getStyle = () => {
    const { amount } = this.props;
    let color = style.sufficient;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (amount > CRITICAL_BOUND && amount <= RUNNING_LOW_BOUND) {
      color = style.runningLow;
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    } else if (amount <= CRITICAL_BOUND) {
      color = style.critical;
    }
    return classnames(style.inStockIndicator, color);
  };

  render() {
    const amount = this.props.amount;
    if (amount == null) {
      return null;
    }
    return <div className={this.getStyle()}>{amount}</div>;
  }
}
