/* eslint-disable func-names */
/* global BackboneEx */
import { trackEvent } from 'common/analytics';
/**
 * Employee categories.
 */
App.Views.Settings.EmployeeCategories = BackboneEx.View.Sortable.extend({
  events: {
    'click .data-list-row.on': 'showEmployeeFormDialog',
    'click #create-category': 'showCreateEmployeeFormDialog',
    'click #no-categories .button-primary': 'showCreateEmployeeFormDialog',
  },

  sortableOptions: {
    axis: 'y',
    items: '.data-list-row',
    distance: 3,
  },

  initialize: function () {
    const self = this;

    this.collection.on('all', function (type) {
      if (type !== 'error') {
        self.renderTable();
      }
    });

    this.on('visible', function () {
      self.render();
    });

    this.$categoriesContainer = this.$('.data-list');
  },

  render: function () {
    // Fetches the collection. Then, renderTable finishes.
    this.collection.fetch();
  },

  renderTable: function () {
    if (this.collection.length > 0) {
      this.$el.removeClass('empty');
      this.renderCategoryItems();
    } else {
      this.$el.addClass('empty');
    }
  },

  renderCategoryItems: function () {
    this.$('.data-list')
      .empty()
      .html(
        Wahanda.Template.renderTemplate('employee-categories-list-items', {
          categories: this.collection.toJSON(),
        }),
      );
    this.setupSortable(this.$categoriesContainer);
  },

  /**
   * Shows a create dialog form.
   */
  showCreateEmployeeFormDialog: function () {
    trackEvent('pricing-levels', 'click', 'add-new-pricing-level');
    this.showEmployeeFormDialog(null);
  },

  /**
   * Shows employee categories form in a dialog.
   */
  showEmployeeFormDialog: function (event) {
    const view = new App.Views.Forms.EmployeeCategory({
      el: $('.employee-category-form'),
      title: Wahanda.lang.settings.employees.categoryPopupTitle,
      collection: this.collection,
    });

    let model = null;
    if (event) {
      const item = $(event.currentTarget);
      const categoryId = item.data('id');
      if (categoryId && (model = this.collection.get(categoryId))) {
        view.model = model;
      }
    } else {
      model = new App.Models.EmployeeCategory();
      model.set('venueId', App.config.get('venue').id, { silent: false });
    }

    view.model = model;
    view.render();
    view.open();
  },

  visible: function () {
    return this.$el.is(':visible');
  },

  onSortUpdate: function () {
    trackEvent('pricing-levels', 'edit', 'pricing-level-order');
    BackboneEx.View.Sortable.Images.prototype.onSortUpdate.apply(this, arguments);
    const employeeCategoryIds = this.$categoriesContainer
      .children()
      .map(function (index, element) {
        return $(element).data('id');
      })
      .toArray();

    this.collection.updateOrder(employeeCategoryIds).fail(function () {
      const error = new App.Views.Dialog.Alert();
      error.render(Wahanda.lang.settings.employees.categories.errors.changingOrderFail);
      error.open();
    });
  },
});
