;(function($) {
	"use strict";
	/**
	 * Adds tooltips to *children* elements having data-tooltip attributes.
	 *
	 * NOTE: This is turned off for "touch devices" by default as it causes problems with receiving "click" events.
	 *
	 * @uses qTip2 http://craigsworks.com/projects/qtip2/
	 *
	 * @param Object qtipOptions OPTIONAL Options for qTip2
	 *
	 * Copyright Wahanda.com
	 */
	$.fn.tooltipize = function(qtipOptions) {
		if (Wahanda.Util.isTouchDevice() && !qtipOptions.allowTouchDevice) {
			return this;
		}

		var opts = $.extend(true, {}, $.fn.tooltipize.defaults, qtipOptions || {});
		var $targets = this.find("[data-tooltip]");

		if (this.is("[data-tooltip]")) {
			$targets = $targets.add(this);
		}

		$targets.each(function() {
			var $this = $(this);
			opts.content = {
				text: $this.data("tooltip")
			};
			$this.qtip("destroy").qtip(opts);
		});
		return this;
	};

	// Global default configuration
	$.fn.tooltipize.defaults = {
		position: {
			my: "left center",
			at: "right top",
			adjust: {
				y: 10
			}
		},
		style: {
			tip: {
				width: 8,
				height: 15
			}
		}
	};
}(jQuery));
