/* eslint func-names: 0 */
/* eslint no-empty: 0  */

import _ from 'common/underscore';
import { formatInternationalFromInput } from 'common/phonenumber';
import channelCodes from 'config/channelCodes';
import { trackEvent } from 'common/analytics';
/**
 * Venue details.
 */
App.Views.Settings.SupplierSettings = App.Views.Form.extend({
  events: {
    'change #supplier-settings-addressCountryCode': 'toggleStatesSelect',
    'click #supplier-settings-vatPayer': 'onVatPayerClick',
  },

  fieldPrefix: /^supplier-settings-/,
  initVenueAfterSave: true,

  initialize: function initialize() {
    const self = this;
    const isFrance = App.config.get('venue').contentChannelCode === channelCodes.FR;
    ['.js-supplier-naf', '.js-supplier-siret'].forEach((selector) => {
      self.$(selector).wToggle(isFrance);
      self
        .$(`${selector} label`)
        .toggleClass('optional', !App.config.get('venue').pointOfSaleEnabled);
    });
    this.model.on('fetched', () => {
      self.render();
    });
    this.populated = false;
  },

  isVatPayerFeatureEnabled: function () {
    return App.config.isVatPayerFeatureEnabled();
  },

  isVatNumberRequired: function (isVatPayer) {
    if (!this.isVatPayerFeatureEnabled()) {
      return false;
    }
    const isItaly = App.config.get('venue').countryCode === 'IT';
    return isVatPayer || isItaly;
  },

  shouldShowVatNumberInput: function (isVatPayer) {
    const isItaly = App.config.get('venue').countryCode === 'IT';

    return isVatPayer || isItaly || !this.isVatPayerFeatureEnabled();
  },

  onVatPayerClick: function ({ target: { checked } }) {
    this.toggleVatNumberInput(checked);
  },

  toggleVatNumberInput: function (checked) {
    this.$('.js-vatNumber-toggle').toggle(this.shouldShowVatNumberInput(checked));
    this.$('.js-vatNumber-label').toggleClass('optional', !this.isVatNumberRequired(checked));
    this.$('.js-vatNumber-input').toggleClass('required', this.isVatNumberRequired(checked));
  },

  toggleVatPayerCheckbox: function () {
    this.$('.js-vatPayer-toggle').toggle(this.isVatPayerFeatureEnabled());
  },
  /**
   * Renders the whole settings view.
   */
  render: function () {
    // this.disableForm();
    if (!this.populated) {
      this.populated = true;
      this.populate();
      this.setValidation();
    }
    this.fillFormFromModel();
    this.renderContacts();
    this.toggleVatPayerCheckbox();
    this.toggleVatNumberInput(this.model.get('vatPayer'));
    this.updateVenueAddressDisplay();

    this.enableForm();
    // Saving form state for changes checking
    this.initialFormState = this.getFormValues();

    this.toggleStatesSelect();

    // Rendering shadows
    this.$('.form-content').shadows();
  },

  updateVenueAddressDisplay: function () {
    const isGerman = App.config.get('venue').contentChannelCode === channelCodes.DE;

    if (!isGerman) {
      return;
    }

    this.$('.js-supplier-city-form-row').show();

    const address = this.model.get('address').trim().split('\n');
    const city = address.pop();
    this.$('#supplier-settings-address').attr('max-text-lines', 3).val(address.join('\n'));
    this.$('#supplier-settings-city').attr('required', true).val(city);
  },

  renderContacts: function () {
    const contact = this.model.getContact() || {};
    const map = {
      '#supplier-settings-contact-name-cn': contact.contactName,
      '#supplier-settings-email-cn': contact.emailAddress,
      '#supplier-settings-phone-cn': contact.phone,
    };
    const self = this;
    Object.keys(map).forEach((key) => {
      self.$(key).val(map[key]);
    });
  },

  populate: function () {
    // Render countries
    let options = [];
    _.each(App.referenceData.get('country'), function (country) {
      options.push({
        value: country.id,
        title: country.title,
      });
    });
    this.$('#supplier-settings-addressCountryCode').html(
      `<option value="">${
        Wahanda.lang.shared.selectInitialOption
      }</option>${Wahanda.Template.renderTemplate('form-select-items', {
        options: options,
      })}`,
    );
    // Render states
    options = [];
    _.each(App.referenceData.get('locationState'), function (state) {
      options.push({
        value: state.id,
        title: state.title,
      });
    });
    this.$('#supplier-settings-addressStateCode').html(
      `<option value="">${
        Wahanda.lang.shared.selectInitialOption
      }</option>${Wahanda.Template.renderTemplate('form-select-items', {
        options: options,
      })}`,
    );
  },

  getAttributesToSave: function getAttributesToSave() {
    const result = [
      'address',
      'addressPostalRef',
      'addressStateCode',
      'addressCountryCode',
      'name',
      'contacts',
      'companyNumber',
      'vatNumber',
      'taxIdNumber',
      'siretNumber',
      'nafCode',
      'vatPayer',
    ];

    return result;
  },

  save: function () {
    const self = this;

    this.setModelValues();

    const isVatNumberChanged = this.initialFormState.vatNumber !== this.model.get('vatNumber');

    trackEvent(
      'finance-billing-info',
      'submit',
      'save-billing-info',
      isVatNumberChanged ? 'vat-number' : null,
    );

    this.disableForm();
    const $saveButton = this.$('button.save-action').action('doing');

    this.model.setAttributesToSave(this.getAttributesToSave());
    // Save the model and show actions to the user
    this.model.save(null, {
      success: function () {
        $saveButton.action();
        self.enableForm();
        self.initialFormState = self.getFormValues();
        App.trigger(Wahanda.Event.SETTINGS_BILLING_DETAILS_SAVED);
        if (self.initVenueAfterSave) {
          App.initVenue(null);
        }
      },
      error: function (model, xhr) {
        self.enableForm();
        self.model.revert();
        $saveButton.action();

        self.handleSaveError(xhr);
      },
    });
  },

  setModelValues: function () {
    const values = this.getFormValues();

    const phone = formatInternationalFromInput(
      this.$('#supplier-settings-phone-cn').val(),
      this.$('#supplier-settings-addressCountryCode').val(),
    );
    let contact = this.model.getContact();
    if (contact) {
      contact.phone = phone;
      contact.contactName = this.$('#supplier-settings-contact-name-cn').val();
      contact.emailAddress = this.$('#supplier-settings-email-cn').val();
    } else {
      contact = {
        id: null,
        phone: phone,
        contactName: this.$('#supplier-settings-contact-name-cn').val(),
        emailAddress: this.$('#supplier-settings-email-cn').val(),
      };
      let contacts = this.model.get('contacts');
      if (!contacts) {
        contacts = [contact];
        this.model.set('contacts', contacts);
      } else {
        contacts.push(contact);
      }
    }

    delete values['phone-cn'];
    delete values['name-cn'];
    delete values['email-cn'];
    delete values['contact-name-cn'];

    if (values.addressCountryCode !== 'US') {
      values.addressStateCode = null;
    }
    values.vatPayer = !!values.vatPayer;
    values.vatNumber = this.shouldShowVatNumberInput(values.vatPayer)
      ? values.vatNumber
      : undefined;
    values.address = Wahanda.Text.trimAndCleanNewLines(values.address);

    if (App.config.get('venue').contentChannelCode === channelCodes.DE) {
      values.address = [...values.address.split('\n'), values.city].join('\n');
      values.city = undefined;
    }

    this.model.set(values);
  },

  _modelValuesHaventChanged: function () {
    const currentState = this.getFormValues();
    const change = _.find(this.initialFormState, function (value, name) {
      return value !== currentState[name];
    });
    // if no changes are found - `change` should be "undefined"
    return change == null;
  },

  setValidation: function setValidation() {
    const self = this;
    const pointOfSaleEnabled = App.config.get('venue').pointOfSaleEnabled;
    const validationSchema = {
      rules: {
        'supplier-settings-siretNumber': {
          required: pointOfSaleEnabled,
          minlength: 14,
          maxlength: 14,
        },
        'supplier-settings-nafCode': {
          required: pointOfSaleEnabled,
        },
      },
      messages: {
        'supplier-settings-siretNumber': {
          required: Wahanda.lang.validate.defaults.required,
          minlength: Wahanda.lang.settings.supplier.billing.validation.siretLength,
          maxlength: Wahanda.lang.settings.supplier.billing.validation.siretLength,
        },
        'supplier-settings-nafCode': {
          required: Wahanda.lang.validate.defaults.required,
        },
      },
    };

    const validations = Wahanda.Validate.getValidations('defaults', {
      ...validationSchema,
      submitHandler: () => {
        self.save();
      },
      $context: this.$el,
    });

    this.$('form').validate(validations);
  },

  toggleStatesSelect: function () {
    const isUSA = this.$('#supplier-settings-addressCountryCode').val() === 'US';
    this.$('.state-row').toggleClass('hidden', !isUSA);
  },

  handleSaveError: function (xhr) {
    try {
      if (Wahanda.Util.isUserErrorCode(xhr.status)) {
        const json = JSON.parse(xhr.responseText);
        const errors = this.getConstraintErrors(json.errors);
        if (!_.isEmpty(errors)) {
          this.$('form').validate().showErrors(errors);
          this.$('form').find('.error:first').focus();
          return;
        }
      }

      this.$('button.save-action').errorTip(Wahanda.lang.shared.savingFailed);
    } catch (e) {}
  },

  getConstraintErrors: function (errors) {
    const list = {};
    const error = errors[0];
    if (error.name === 'duplicate-supplier-name') {
      list['supplier-settings-name'] =
        Wahanda.lang.settings.supplier.billing.errors.supplierNameNotUnique;
    }

    return list;
  },
});
