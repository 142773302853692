import App from 'common/backbone-app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AutomatedMessagingTabs as Component } from './AutomatedMessagingTabs';
import { setAutomatedMessagingSettingRequest, automatedMessagingSettingsRequest } from '../actions';
import { settings } from 'components/marketing/AutomatedMessaging/config';
import { isFeatureEnabled } from 'common/trunk-features/store/selectors';

const mapStateToProps = (state) => {
  const {
    marketing: { automatedMessaging },
    appSettings: { isWebview },
  } = state;

  const visibleSettings = settings
    .filter(
      ({ trunkFeatureName }) =>
        !trunkFeatureName || isFeatureEnabled(state, trunkFeatureName as any),
    )
    .filter(({ channelFeature }) => !channelFeature || App.isFeatureSupported(channelFeature));

  return {
    automatedMessagingState: automatedMessaging,
    isWebview,
    visibleSettings,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setAutomatedMessagingSettingRequest,
      automatedMessagingSettingsRequest,
    },
    dispatch,
  ),
});

export const AutomatedMessagingTabs = connect(mapStateToProps, mapDispatchToProps)(Component);
