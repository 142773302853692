import React from 'react';

interface Props {
  className?: string;
}

class Products extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-products', this.props.className].join(' ')}
        viewBox="0 0 85 98"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFB500"
            d="M43.9384766 24C41.9794922 29.4160156 41 39.4573568 41 54.1240234 41 68.7906901 41 75.0644531 41 72.9453125L43.9384766 72.0253906 43.9384766 68.7011719 42.3769531 67.0932617 43.9384766 65.0253906 43.9384766 40.0253906 43.9384766 24zM60 84.3955078C72.8108937 86.7985026 80.7296762 84.3505859 83.7563477 77.0517578 83.7563477 77.0517578 83.7563477 80.7011719 83.7563477 88L80.7563477 91 60 91 60 84.3955078z"
            fillRule="nonzero"
          />
          <path
            fill="#FFB500"
            d="M1.0000003,82.3955078 C12.9412447,83.7740885 22.1933606,81.3261719 28.756348,75.0517578 C28.756348,75.0517578 28.756348,79.3678385 28.756348,88 L25.2512198,91 L1.0000003,91 L1.0000003,82.3955078 Z"
            transform="matrix(-1 0 0 1 29.756 0)"
            fillRule="nonzero"
          />
          <path
            stroke="#001E62"
            d="M18,59 L18,45 C18,44.4477153 18.4477153,44 19,44 L25,44 C25.5522847,44 26,43.5522847 26,43 L26,40 C26,39.4477153 25.5522847,39 25,39 C21.6673151,39 18.3346303,39 15.0019454,39 C11.0032423,39 7.66926051,40.3333333 5,43 C6.2374684,45.3404875 6.9537189,46.4645703 7.1487515,46.3722485 C10.489714,44.7907495 10.9225231,44 14.0019454,44 C14.0019454,44 14.0019454,46.3645833 14.0019454,51.09375 L14.0019454,59"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            stroke="#001E62"
            d="M22,51 L29,51 L29,91 L7,91 C3.6862915,91 1,88.3137085 1,85 L1,56 C1,53.2385763 3.23857625,51 6,51 L11,51"
            strokeWidth="2"
          />
          <g
            stroke="#001E62"
            transform="translate(59.5 35)"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          >
            <path d="M15.5,20 L18.5,20 L18.5,6.5 C18.5,2.91014913 15.5898509,-4.54747351e-13 12,-4.54747351e-13 L12,-4.54747351e-13 C8.41014913,-4.54747351e-13 5.5,2.91014913 5.5,6.5 L5.5,20 L11.5,20 L11.5,36" />
            <path d="M0.5,26 L19.9980546,26 C22.2081358,26 24,27.7821205 24,29.9807753 L24,52.0192247 C24,54.2178795 22.2081358,56 19.9980546,56 L0.5,56 L0.5,37" />
          </g>
          <g transform="translate(29 1)">
            <path
              fill="#00B0B9"
              d="M25,39 L24.9995052,34.0022262 C24.9995052,32.8978499 24.1043762,32.0024995 22.9999999,32.0022263 L18.0000001,32 C16.8956238,31.9997268 16.0004948,31.1043763 16.0004948,30 L16,20.6471877 L16,14 C16,11.790861 14.209139,10 12,10 L12,28.7160051 L7.203036,28.7160051 C4.847772,28.7160051 2.93818,29.6201183 2.93818,31.9691414 L2.93818,38.6242828"
              fillRule="nonzero"
            />
            <path
              stroke="#001E62"
              d="M38.51453,21.184126 L37.160578,14.675437 C36.901202,13.428162 35.924018,12.4536632 34.673306,12.195 L28.146682,10.8447686 C27.951106,10.8042802 27.951106,10.5259512 28.146682,10.4854627 L34.673306,9.13523136 C35.924018,8.87656812 36.901202,7.90206941 37.160578,6.65502571 L38.51453,0.146105398 C38.55513,-0.0487017995 38.834226,-0.0487017995 38.874826,0.146105398 L40.228778,6.65502571 C40.488154,7.90206941 41.465338,8.87656812 42.715818,9.13523136 L49.242674,10.4854627 C49.438018,10.5259512 49.438018,10.8042802 49.242674,10.8447686 L42.715818,12.195 C41.465338,12.4536632 40.488154,13.428162 40.228778,14.675437 C40.1363354,15.1198445 40.0751039,15.4142078 40.0450836,15.558527"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
            <path
              fill="#FFF"
              stroke="#001E62"
              d="M12,42 L12,78.0096245 C12,79.2877678 12.4894791,80.5173827 13.3678786,81.4458571 L14,82.1140137 L13.4998622,82.5856402 C12.5735533,83.4591432 12.191202,84.764808 12.5,86 L12.5,86 C12.7938534,87.1754137 13.8499641,88 15.0615528,88 L15.9384472,88 C17.1500359,88 18.2061466,87.1754137 18.5,86 L18.5,86 C18.808798,84.764808 18.4264467,83.4591432 17.5001378,82.5856402 L17,82.1140137 L17.6321214,81.4458571 C18.5105209,80.5173827 19,79.2877678 19,78.0096245 L19,39 L27,39 C29.209139,39 31,40.790861 31,43 L31,90 C31,93.3137085 28.3137085,96 25,96 L6,96 C2.6862915,96 -8.8817842e-16,93.3137085 -8.8817842e-16,90 L-8.8817842e-16,43 C-8.8817842e-16,40.790861 1.790861,39 4,39 L22,39"
              fillRule="nonzero"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path
              stroke="#001E62"
              d="M28 36L28 33C28 30.790861 26.209139 29 24 29L19 29 19 9.5C19 7.56700338 17.4329966 6 15.5 6L15.5 6C13.5670034 6 12 7.56700338 12 9.5L12 25.6284401M13 29L7 29C4.790861 29 3 30.790861 3 33L3 39"
              strokeWidth="2"
            />
          </g>
          <rect width="13" height="2" x="9" y="65" fill="#001E62" fillRule="nonzero" />
          <rect width="13" height="2" x="64" y="75" fill="#001E62" fillRule="nonzero" />
          <rect width="13" height="2" x="9" y="69" fill="#001E62" fillRule="nonzero" />
          <rect width="13" height="2" x="64" y="79" fill="#001E62" fillRule="nonzero" />
        </g>
      </svg>
    );
  }
}

export const icon = Products;
