/**
 * Base validating form.
 *
 * You can use some predefined functions:
 *   initForm: for setting default values, and possibly rendering additional content
 *   postInitialize: for initializing options, that should NOT persist across all instances of this class
 */
BackboneEx.View.Dialog.ValidatingForm = BackboneEx.View.Dialog.extend({
	events: {
		// The primary button is type=submit, so is submits the form and triggers validation
		"click .button-secondary": "del",
		"click .button-cancel": "cancel"
	},
	options: {
		dataTest: 'validation-form-modal'
	},
	/**
	 * @param string or function If function, it can accept jqXHR response as it's only parameter
	 */
	saveErrorText: Wahanda.lang.shared.savingFailed,
	deleteErrorText: Wahanda.lang.shared.deleteFailed,
	/**
	 * Should be a regexp, e.x. /^someForm-/
	 */
	fieldPrefix: null,

	/**
	 * Forms element to focus. Can be a jQuery selector, or a callback.
	 */
	elementToFocus: ':input:visible:first',

	setupDialog: function() {
		this.initForm();
		this.setValidation();
	},

	/**
	 * For setting default values, and possibly rendering additional content.
	 * Is at the end of rendering of the view, just before validation is set.
	 */
	initForm: function() {
	},

	handleConstraintError: function(jqXHR) {
		if (Wahanda.Util.isUserErrorCode(jqXHR.status) && /application\/json/.test(jqXHR.getResponseHeader('Content-Type'))) {
			var errorData = {};
			try {
				errorData = JSON.parse(jqXHR.responseText);
			} catch (e) {
			}

			var errors = this.getConstraintErrors(errorData);
			if (!_.isEmpty(errors)) {
				this.$('form').validate().showErrors(errors);
				this.$('form').find('.error:first').focus();
				return true;
			}
			else {
				var tooltipBody = this.getErrorTooltipBody(errorData);
				if (tooltipBody) {
					this.$('.button-secondary').noticeTip(tooltipBody);
				}
				return true;
			}
		}
	},

	getErrorTooltipBody: function(errorData) {
		// Implement own.
	},

	/**
	 * When overridden, should return error strings for the given error in format
	 * 	{ formFieldName: "error1", ... }
	 *
	 * @return Object | undefined if no errors exist
	 */
	getConstraintErrors: function(data) {
	},

	/**
	 * Sends "remove model" request to the server
	 */
	del: function() {
		var self = this;
		this.disableForm();

		var deleteBtn = this.$('.delete-action');
		deleteBtn.action("doing");

		Wahanda.Ajax.setSafeErrorHandler(function(xhr) {
			deleteBtn.action();
			self.enableForm();
			// Show delete failed tip
			var errorText = (typeof self.deleteErrorText === "function" ? self.deleteErrorText(xhr) : "") || self.deleteErrorText;
			if (errorText) {
				deleteBtn.noticeTip(errorText, null, 10000);
			}
			self.apiErrorHandler(xhr);
		});

		this.model.destroy({
			success: function() {
				Wahanda.Ajax.resetErrorHandler();
				self._afterDeleteCallback();
				self.close();
			}
		});
	},

	_afterDeleteCallback: function() {},

	/**
	 * Saves the changes on the model.
	 */
	save: function() {
		this.model.set(this.getModelValues());
		this.disableForm();

		var submitBtn = this.saveActionButton || this.$('.save-action');
		submitBtn.clearQueue().action("doing");

		this._saveAction();
		if (this.resetCloseChangesCheck) {
			this.resetCloseChangesCheck();
		}
	},

	_saveAction: function() {
		this._defaultSaveAction();
	},

	/**
	 * Options for saving. Possible values:
	 * > doNotClose - don't close the dialog after saving
	 */
	_defaultSaveAction: function(options) {
		var self = this;
		var submitBtn = this.saveActionButton || this.$('.save-action');
		var isNewModel = !this.model.id;
		options = (options || {});
		var params = {
			error: function(model, response) {
				self.enableForm();
				submitBtn.action();
				self._saveFailedCallback(response);

				if (true !== self.handleConstraintError(response)) {
					// Show saving failed tip in case of unexpected error
					var errorText = (typeof self.saveErrorText === "function" ? self.saveErrorText(response) : self.saveErrorText);
					if (errorText) {
						submitBtn.errorTip(errorText);
					}
				}
			},
			success: function() {
				if (self.collection && isNewModel && self.addToCollection) {
					// New model in this collection
					self.addToCollection(self.model);
				}
				self._afterSaveCallback({ isNew: isNewModel });
				if (!options.doNotClose) {
					try {
            self.close();
					} catch (e) {
						// Probably the form was already closed
					}
				}
			}
		};

		this.model.save(null, params);
	},

	/**
	 * Callback performed after successful save of the model.
	 */
	_afterSaveCallback: function() {
		this.model.trigger('saved');
		this.trigger('saved');
	},

	/**
	 * Callback which gets called when the saving fails.
	 *
	 * @param Object response The server's response
	 */
	_saveFailedCallback: function(response) {
	},

	addToCollection: function(model) {
		this.collection.add(model);
	},

	/**
	 * Returns values for setting to the Model before save.
	 *
	 * @param boolean onlyWithPrefix OPTIONAL Return only those fields which have the form's prefix
	 *
	 * @return Object
	 */
	getModelValues: function(onlyWithPrefix) {
		var data = Wahanda.Form.serialize(this.$('form:first'));
		if (this.fieldPrefix) {
			var newData = {};
			for (var name in data) {
				if (onlyWithPrefix && !this.fieldPrefix.test(name)) {
					continue;
				}
				var newName      = name.replace(this.fieldPrefix, '');
				newData[newName] = data[name];
			}
			data = newData;
		}
		return data;
	},

	/**
	 * The temporary error handler.
	 *
	 * @param jqXHR
	 * @access static
	 */
	apiErrorHandler: function(jqXHR) {
		Wahanda.Ajax.resetErrorHandler();
		this.enableForm();
		if (Wahanda.Util.isUserErrorCode(jqXHR.status)) {
			// Constraints fail
			this.handleConstraintError(jqXHR);
		} else {
			// Unhandled error. Pass to the default handler.
			Wahanda.Ajax.errorHandler(jqXHR);
		}
	},

	// For validations with $.validate().
	setValidation: function() {
		/* Please implement your own. */
	},

	_setDefaultValidation: function() {
		var self = this;
		var validations = Wahanda.Validate.getValidations(
			"defaults",
			{
				submitHandler: function() {
					self.save();
				}
			}
		);

		this.$('form').validate(validations);
	},

	open: function() {
		BackboneEx.View.Dialog.prototype.open.call(this);
		// Don't focus elements on touch devices
		if (!Wahanda.Util.isTouchDevice()) {
			this.focusFormElement();
		}
	},

	focusFormElement: function() {
		if (this.elementToFocus) {
			if (typeof this.elementToFocus === "function") {
				this.elementToFocus();
			} else {
				this.$(this.elementToFocus).focus();
			}
		}
	},

	cancel: function() {
		this.model.revert();
		this.trigger('cancelled');
		this.close(false);
	},

	/**
	 * Fils this view's values with ones from model.
	 *
	 * @param Object defaults OPTIONAL Default values
	 */
	fillFormFromModel: function(defaults, ignore) {
		var $elems = this.$(':input[name]').not('button').not('input[type=button]').not('input[type=submit]');
		var model  = this.model;
		defaults = defaults || {};
		ignore   = ignore || [];
		var prefix = this.fieldPrefix;

		$elems.each(function() {
			var $elem = $(this);
			var name  = $elem.attr('name').replace(prefix, '');

			if (-1 !== _.indexOf(ignore, name)) {
				// Field is ignored. Continue;
				return;
			}

			var value = model.get(name);

			if ($elem.is(":checkbox")) {
				$elem.prop('checked', !!value);
			} else if ($elem.is(":radio")) {
				var radioValue = $elem.val();
				$elem.prop('checked', radioValue === String(value));
			} else if (value != null && (!!value || parseInt(value, 10) === 0)) {
				$elem.val(value);
			} else if (typeof defaults[name] != "undefined") {
				$elem.val(defaults[name]);
			} else {
				$elem.val("");
			}
		});
	},

	refreshModelAndForm: function() {
		var self = this;
		this.disableForm();
		this.loadmask();
		return this.model.fetch({
			success: function() {
				self.initForm();
				self.enableForm();
				if (!Wahanda.Util.isTouchDevice()) {
					self.focusFormElement();
				}
				self.unloadmask();
			}
		});
	},

	showQuestionTooltip: function($target, $html, acceptCallback) {
		var self    = this;
		var tooltip = $target.qtip({
			content: $html,
			style: {
				classes: "dialog-tooltip"
			},
			position: {
				my: "bottom center",
				at: "top center"
			},
			show: {
				event: false,
				ready: true
			},
			hide: {
				event: false,
				fixed: true
			}
		});

		$html
			.find('.a-confirm').on('click', function() {
				tooltip.qtip('destroy');
				acceptCallback();
			}).end()
			.find('.a-cancel').on('click', function() {
				tooltip.qtip('destroy');
				self.enableForm();
				if (!Wahanda.Util.isTouchDevice()) {
					self.focusFormElement();
				}
			});
	}
});
