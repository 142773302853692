import React from 'react';
import { Fingerpost, FingerpostActionProps } from 'components/common/Fingerpost';
import { Popover, PopoverPlacement, PopoverAnchorOffset } from 'components/common/Popover';

import { ShiftModalArgs } from '../utils/types';
import { ShiftModalHeader } from '../ShiftModalHeader';

import style from './ShiftActionsModal.scss';

interface Props {
  actionList: FingerpostActionProps[];
  offset: PopoverAnchorOffset;
  placement?: PopoverPlacement;
  data: ShiftModalArgs;
  onClose: () => void;
  scheduleTypeContainer?: JSX.Element;
}

export class ShiftActionsModal extends React.Component<Props> {
  public render() {
    const { actionList, data, onClose, placement, offset, scheduleTypeContainer } = this.props;

    return (
      <Popover open offset={offset} placement={placement} onClose={onClose}>
        <ShiftModalHeader {...data} />
        {scheduleTypeContainer}
        <div className={style.wrapper}>
          <Fingerpost actions={actionList} />
        </div>
      </Popover>
    );
  }
}
