/* eslint-disable no-undef,no-empty,no-fallthrough */
/**
 * @mixin BackboneEx.Mixin.View.Datepicker
 *
 * NOTE: the rendering is moved out to React.
 */
App.Views.Forms.BlockTime = BackboneEx.View.Dialog.ValidatingForm.extend({
  events: {
    'click .delete-action': 'del',
    'change select[name=availabilityRuleTypeCode]': 'onAvailabilityChange',
    'change select.end-date-type': 'onEndDateTypeChange',
    'change #timeblock-between-start, #timeblock-between-end': 'onTimeChange',
  },

  templateId: 'add-block-dialog-template',

  getDateFormat: function () {
    return App.config.get('jqueryDateFormat').defaultDate;
  },

  render: function () {
    this.$el.html(Wahanda.Template.renderTemplate(this.templateId));

    this.daysPartial = new App.Views.Partials.WeekdayCheckboxes({
      el: this.$('.weekdays'),
      lastCanBeDeselectedText: Wahanda.lang.calendar.blockTime.weekdays.cantDeselect,
      icon: 'block2',
      activeClass: 'block',
    });

    this.$('input.datepicker')
      .datepicker({
        dateFormat: this.getDateFormat(),
        firstDay:
          Wahanda.Date.dayStringToNumberMap[App.config.get('jqueryDateFormat').firstDayOfWeek],
        constrainInput: true,
        onSelect: function (dateText, inst) {
          var $el = $(this);
          $el.val(dateText);
          $el.valid();
        },
      })
      // Adding date validator
      .attr('date-format', this.getDateFormat());

    this.currentActionTypeCode = null;
    var buttons = {};
    var saveButton = {
      title: Wahanda.lang.shared.save,
      classes: 'dialog2--button-right dialog2--button-green',
    };
    var deleteButton = {
      title: Wahanda.lang.shared['delete'],
      classes: 'dialog2--button dialog2--button-left dialog2--button-red',
    };

    buttons.save = saveButton;
    if (this.model.id) {
      buttons.deleteButton = deleteButton;
    }
    this.options.updateButtons(buttons);

    this.setFormValues();

    this.setValidation();

    if (this.model.id) {
      this.refetch();
    }
  },

  setFormValues: function () {
    this.renderResources();
    this.setReason();
    this.renderRepetion();
    this.renderWeekdays();
  },

  refetch: function () {
    this.disableForm();

    var self = this;
    this.model.fetch({
      success: function () {
        self.update();
      },
    });
  },

  update: function () {
    this.setFormValues();
    this.enableForm();
    this.options.heightChange(this.$el.height());
  },

  renderResources: function () {
    if (
      this.model.id ||
      this.options.employees.length < 2 ||
      !Wahanda.Permissions.editAnyCalendar()
    ) {
      this._renderResourceText();
    } else {
      this._renderEmployeeSelect();
    }
  },

  _renderEmployeeSelect: function () {
    var resourceId = this.model.get('employeeId');
    var options = [
      {
        value: 'all',
        title: Wahanda.lang.calendar.blockTime.employeeSelect.allEmployees,
        selected: !resourceId,
      },
      {
        title: '---',
        disabled: true,
      },
    ];

    // Which employee to include in select. NULL means "include all".
    var toInclude;
    var editAny = Wahanda.Permissions.editAnyCalendar();
    var editOwn = Wahanda.Permissions.editOwnCalendar();

    if (!editAny && editOwn) {
      toInclude = App.config.getAccountEmployeeId();
    } else if (!editAny && !editOwn) {
      throw new Exception('No permissions to render employees');
    }

    this.options.employees.each(function (employee) {
      if (!toInclude || employee.id == toInclude) {
        options.push({
          value: String(employee.id),
          title: employee.get('name'),
          selected: employee.id === resourceId,
        });
      }
    });

    var $select = $('<select name="employeeId" class="required">').html(
      '<option value="">' +
        Wahanda.lang.calendar.blockTime.employeeSelect.placeholder +
        '</option>' +
        Wahanda.Template.renderTemplate('form-select-items', {
          options: options,
        }),
    );
    this.$('.employee').empty().append($select);
  },

  _renderResourceText: function () {
    var resourceId, resourceText, employee;
    if (this.model.id) {
      if (null == this.model.get('employeeId')) {
        resourceId = 'all';
        resourceText = Wahanda.lang.calendar.blockTime.employeeSelect.allEmployees;
      } else {
        employee = this.options.employees.get(this.model.get('employeeId'));
        resourceId = employee.id;
        resourceText = employee.get('name');
      }
    } else {
      var resourceObject;
      if (!Wahanda.Permissions.editAnyCalendar()) {
        employee = this.options.employees.get(App.config.getAccountEmployeeId());
        if (Wahanda.Permissions.editOwnCalendar() && employee) {
          resourceObject = employee;
        } else {
          throw new Error(
            'No permissions to create time block or employee does not perform services',
          );
        }
      }
      if (!resourceObject) {
        resourceObject = this.options.employees.at(0);
      }
      resourceId = resourceObject.id;
      resourceText = resourceObject.get('name');
    }

    this.$('.employee')
      .empty()
      .append(
        $('<input />', {
          type: 'hidden',
          name: 'employeeId',
          value: resourceId,
        }),
      )
      .append(document.createTextNode(resourceText));
  },

  setReason: function () {
    this.$('input[name=name]').val(this.model.get('name'));
  },

  renderRepetion: function () {
    var type = this.model.get('availabilityRuleTypeCode');
    var $type = this.$('select[name=availabilityRuleTypeCode]').val(
      // Map "Day" type to "Period"
      type === 'D' ? 'P' : type,
    );

    this._toggleRepetionBlocks();
    this._renderTimeField('timeFrom');
    this._renderTimeField('timeTo');

    if ('P' !== type && 'D' !== type) {
      this.$('select.end-date-type').val(this.model.get('dateTo') ? 'date' : 'inf');
    }

    if (this.model.get('dateFrom')) {
      var date = Wahanda.Date.createDate(this.model.get('dateFrom'), '00:00');
      this.$('input[name=dateFrom]').val(Wahanda.Date.formatDate(this.getDateFormat(), date));
    }
    if (this.model.get('dateTo')) {
      var date = Wahanda.Date.createDate(this.model.get('dateTo'), '00:00');
      this.$('input[name=dateTo]').val(Wahanda.Date.formatDate(this.getDateFormat(), date));
    }
    this._toggleEndDateField();
    if (this.model.id) {
      $type.disableFormElements();
    }
  },

  _toggleRepetionBlocks: function () {
    var type = this.$('select[name=availabilityRuleTypeCode]').val();
    this.$('.availability-depend')
      .addClass('hidden')
      .find(':input')
      .disableFormElements()
      .end()
      .filter('.availability-' + type)
      .removeClass('hidden')
      .find(':input')
      .enableFormElements();
  },

  _toggleEndDateField: function () {
    var dateType = this.$('select.end-date-type').val();
    var disabled = dateType !== 'date';
    this.$('.date-to-input').toggleClass('hidden', disabled);
    var input = this.$('.date-to-input input');
    if (disabled) {
      input.disableFormElements();
    } else {
      input.enableFormElements();
    }
  },

  _renderTimeField: function (name) {
    var venueStartHours = Wahanda.Time.timeToMinutes(App.config.get('venue').dayStartsAt);
    var venueCloseHours = Wahanda.Time.timeToMinutes(App.config.get('venue').dayEndsAt);
    var modelValue = Wahanda.Time.timeToMinutes(this.model.get(name));

    var step = App.config.get('venue').appointmentSlotDuration;
    var options = Wahanda.Time.getTimeFieldValues(
      venueStartHours,
      venueCloseHours,
      step,
      App.config.get('jqueryDateFormat').defaultTime,
      Wahanda.Time.getNearestSelectFunc(modelValue, step, venueStartHours),
    );

    this.$('select[name=' + name + ']').html(
      Wahanda.Template.renderTemplate('form-select-items', { options: options }),
    );
  },

  renderWeekdays: function () {
    if (this.model.id) {
      this.daysPartial.options.selectedDays = this.model.get('weekDays');
    } else if (this.options.actionDateObject) {
      var weekDay = this.options.actionDateObject.getDay();
      this.daysPartial.options.selectedDays = [Wahanda.Date.dayNumberToStringMap[weekDay]];
    }

    this.daysPartial.render();
  },

  onAvailabilityChange: function (event) {
    this._toggleRepetionBlocks();
    this.options.heightChange(this.$el.height());
  },

  onEndDateTypeChange: function (event) {
    this._toggleEndDateField();
    this.options.heightChange(this.$el.height());
  },

  getUpdateTypeCode: function () {
    return this.currentActionTypeCode || (this.model.id ? 'F' : undefined);
  },

  getModelValues: function () {
    var values = Wahanda.Form.serialize(this.$('form'));
    if (values.employeeId === 'all') {
      values.employeeId = null;
    }
    values.weekDays = null;
    values.actionDate = this.options.actionDate;

    var dates = ['dateFrom', 'dateTo'];
    for (var i in dates) {
      var name = dates[i];
      if (values[name]) {
        try {
          var date = $.datepicker.parseDate(this.getDateFormat(), values[name]);
          values[name] = Wahanda.Date.toApiString(date);
        } catch (e) {}
      }
    }

    switch (values.availabilityRuleTypeCode || this.model.get('availabilityRuleTypeCode')) {
      case 'D':
      case 'P':
        values.availabilityRuleTypeCode = values.dateFrom === values.dateTo ? 'D' : 'P';
        break;

      case 'W':
      case 'B':
        values.weekDays = this.daysPartial.getValues();
      case 'A':
        if ('date' !== this.$('select.end-date-type').val()) {
          values.dateTo = null;
        }
        break;
    }

    return values;
  },

  setValidation: function () {
    var self = this;
    var validations = Wahanda.Validate.getValidations(
      'defaults',
      _.extend({
        submitHandler: function () {
          self.save();
        },
      }),
    );

    this.$('form').validate(validations);
  },

  save: function (reactCallback) {
    var self = this;
    if (!this.$('form').valid()) {
      this.$('.error')[0].focus();
      return;
    }
    if (this.saving) {
      console.log('Prevented duplicate block saving');
      return;
    }

    this.saving = true;
    const callback = (prop) => {
      this.saving = false;
      reactCallback(prop);
    };

    var saveAction = function () {
      self.model.set('updateTypeCode', self.getUpdateTypeCode());

      self.model.save(null, {
        success: function () {
          callback('close');
        },
        error: function (model, response) {
          callback('show');
          self.enableForm();

          if (true !== self.handleConstraintError(response)) {
            // Show saving failed tip in case of unexpected error
            var errorText =
              typeof self.saveErrorText === 'function'
                ? self.saveErrorText(response)
                : self.saveErrorText;
            if (errorText) {
              this.$('.dialog2--button-right.dialog2--button-green').errorTip(errorText);
            }
          }
        },
      });
    };

    this.model.set(this.getModelValues());

    if (!this.model.id || !this.model.isRecurringDataChanged()) {
      saveAction();
    } else {
      this._showClarificationTooltip({
        title: Wahanda.lang.calendar.blockTime.clarification.buttons.update,
        tooltipButton: $('#block-save-btn'),
        actionCallback: function () {
          callback('hide');
          saveAction();
        },
      });
    }

    this.disableForm();
  },

  _showClarificationTooltip: function (options) {
    var self = this;
    var tipContent = $($('#block-action-clarification-dialog').html());
    tipContent.find('.title').text(options.title);

    options.tooltipButton.action('doing');

    const cancelActionAndCloseDialog = () => {
      tooltip.qtip('destroy');
      self.enableForm();
      options.tooltipButton.action();
    };

    var tooltip = options.tooltipButton.qtip({
      content: tipContent,
      style: {
        classes: 'dialog-tooltip clarification-dialog',
      },
      position: {
        my: 'bottom center',
        at: 'top center',
      },
      show: {
        event: false,
        ready: true,
      },
      hide: {
        event: 'unfocus',
        fixed: true,
        effect: cancelActionAndCloseDialog,
      },
    });

    tipContent
      .find('.change-only-this, .change-this-and-future')
      .on('click', function () {
        tooltip.qtip('destroy');
        self.currentActionTypeCode = $(this).hasClass('change-only-this') ? 'D' : 'F';
        options.actionCallback();
      })
      .end()
      .find('.button-cancel')
      .on('click', cancelActionAndCloseDialog);
  },

  del: function (callback) {
    var self = this;

    // prevent multi delete requests for same time block
    if (self.deleting) {
      return;
    }

    self.deleting = true;

    var deleteAction = function () {
      self.model.set('deleteTypeCode', self.getUpdateTypeCode());
      self.model.set('actionDate', self.options.actionDate);
      self.model.destroy({
        success: function () {
          self.deleting = false;
          callback('close');
        },
        error: function () {
          self.deleting = false;
        },
      });
    };

    self.disableForm();

    if (this.model.isRecurring()) {
      self._showClarificationTooltip({
        title: Wahanda.lang.calendar.blockTime.clarification.buttons.remove,
        tooltipButton: $('#block-del-btn'),
        actionCallback: deleteAction,
      });
    } else {
      var deleteButton = this.$('button.delete-action');
      deleteButton.action('doing');
      deleteAction();
    }
  },

  /**
   * Align time selects.
   *
   * @param Event event
   */
  onTimeChange: function (event) {
    var $select = $(event.target);
    $select.alignTimeSelects({
      lesserSelectName: 'timeFrom',
    });
  },
});

BackboneEx.Mixin.extendView(App.Views.Forms.BlockTime, BackboneEx.Mixin.View.Datepicker);
