import React from 'react';

const ExportDialogReturnIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2928 4.29297L12.707 5.70718L7.41369 11L18.9999 11.0001V13.0001L7.41369 13L12.707 18.293L11.2928 19.7072L3.58569 12.0001L11.2928 4.29297Z"
      fill="#333333"
    />
  </svg>
);

export default ExportDialogReturnIcon;
