import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Delete extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-delete', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M7,8 L7,20.0005775 C7,21.1041148 7.89490544,22 8.99715203,22 L15.002848,22 C16.1058245,22 17,21.105096 17,20.0005775 L17,8 L19,8 L19,20.0005775 C19,22.209301 17.2107587,24 15.002848,24 L8.99715203,24 C6.78984574,24 5,22.2081947 5,20.0005775 L5,8 L7,8 Z M15,11 L15,20 L13,20 L13,11 L15,11 Z M11,8 L11,17 L9,17 L9,8 L11,8 Z M14.0018986,0 C15.1054196,0 16.2815238,0.844571292 16.6300128,1.89003825 L16.6666667,2 L9.99810135,2 C9.75152031,2 9.34307073,2.29534314 9.2645541,2.53089303 L8.77485177,4 L21,4 L21,6 L3,6 L3,4 L6.66666667,4 L7.3671875,1.8984375 C7.71668019,0.84995942 8.88670635,0 9.99810135,0 L14.0018986,0 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Delete);
