import {
  INITIAL_STATE_OFFER_PRICING_DISCOUNT_RULE,
  SET_SELECTED_DISCOUNT_RULE,
  GET_VENUE_DETAILS_REQUEST,
  GET_VENUE_DETAILS_RECEIVE,
  GET_VENUE_DETAILS_FAILURE,
  GET_DISCOUNT_RULES_REQUEST,
  GET_DISCOUNT_RULES_RECEIVE,
  GET_DISCOUNT_RULES_FAILURE,
  REVERT_DISCOUNT_RULE,
  GET_DISCOUNT_RULE_REQUEST,
  GET_DISCOUNT_RULE_RECEIVE,
  GET_DISCOUNT_RULE_FAILURE,
  ADD_DISCOUNT_RULE_REQUEST,
  ADD_DISCOUNT_RULE_RECEIVE,
  ADD_DISCOUNT_RULE_FAILURE,
  REMOVE_DISCOUNT_RULE_REQUEST,
  REMOVE_DISCOUNT_RULE_RECEIVE,
  REMOVE_DISCOUNT_RULE_FAILURE,
  PUT_DISCOUNT_RULE_REQUEST,
  PUT_DISCOUNT_RULE_RECEIVE,
  PUT_DISCOUNT_RULE_FAILURE,
  PRESENT_DISCOUNT_RULE_DIALOG,
  DISCARD_DISCOUNT_RULE_DIALOG,
} from 'reduxStore/actionsConst';

export const initialState = (data) => ({
  type: INITIAL_STATE_OFFER_PRICING_DISCOUNT_RULE,
  data,
});

export const updateSelectedDiscountRuleAction = (selectedDiscountRuleId) => ({
  type: SET_SELECTED_DISCOUNT_RULE,
  selectedDiscountRuleId,
});

export const requestVenueDetailsAction = (venueId) => ({
  type: GET_VENUE_DETAILS_REQUEST,
  venueId,
});

export const receiveVenueDetailsAction = (data) => ({
  type: GET_VENUE_DETAILS_RECEIVE,
  data,
});

export const requestVenueDetailsFailureAction = (ex) => ({
  type: GET_VENUE_DETAILS_FAILURE,
  ex,
});

export const requestDiscountRulesAction = (venueId) => ({
  type: GET_DISCOUNT_RULES_REQUEST,
  venueId,
});

export const receiveDiscountRulesAction = (data) => ({
  type: GET_DISCOUNT_RULES_RECEIVE,
  data,
});

export const requestDiscountRulesFailureAction = (ex) => ({
  type: GET_DISCOUNT_RULES_FAILURE,
  ex,
});

export const requestDiscountRuleAction = (venueId) => ({
  type: GET_DISCOUNT_RULE_REQUEST,
  venueId,
});

export const receiveDiscountRuleAction = (data) => ({
  type: GET_DISCOUNT_RULE_RECEIVE,
  data,
});

export const requestDiscountRuleFailureAction = (ex) => ({
  type: GET_DISCOUNT_RULE_FAILURE,
  ex,
});

export const createDiscountRuleAction = (venueId, payload) => ({
  type: ADD_DISCOUNT_RULE_REQUEST,
  venueId,
  payload,
});

export const createDiscountRuleSuccessAction = (data, values) => ({
  type: ADD_DISCOUNT_RULE_RECEIVE,
  data,
  values,
});

export const createDiscountRuleFailureAction = (ex, values) => ({
  type: ADD_DISCOUNT_RULE_FAILURE,
  ex,
  values,
});

export const removeDiscountRuleAction = (venueId, discountId) => ({
  type: REMOVE_DISCOUNT_RULE_REQUEST,
  venueId,
  discountId,
});

export const removeDiscountRuleSuccessAction = (data, values) => ({
  type: REMOVE_DISCOUNT_RULE_RECEIVE,
  data,
  values,
});

export const removeDiscountRuleFailureAction = (ex, values) => ({
  type: REMOVE_DISCOUNT_RULE_FAILURE,
  ex,
  values,
});

export const updateDiscountRuleAction = (venueId, discountId, payload) => ({
  type: PUT_DISCOUNT_RULE_REQUEST,
  venueId,
  discountId,
  payload,
});

export const updateDiscountRuleSuccessAction = (data, values) => ({
  type: PUT_DISCOUNT_RULE_RECEIVE,
  data,
  values,
});

export const updateDiscountRuleFailureAction = (ex, values) => ({
  type: PUT_DISCOUNT_RULE_FAILURE,
  ex,
  values,
});

export const presentDiscountRuleDialogAction = () => ({
  type: PRESENT_DISCOUNT_RULE_DIALOG,
});

export const discardDiscountRuleDialogAction = () => ({
  type: DISCARD_DISCOUNT_RULE_DIALOG,
});

export const revertDiscountRuleAction = (id) => ({
  type: REVERT_DISCOUNT_RULE,
  id,
});
