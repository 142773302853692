import React, { PureComponent } from 'react';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { Download } from 'components/common/Icon';
import { Button } from 'components/common/Button';

import reportingApiRoot from '../reportingApi';
import style from './BaseCsvDownloadLink.scss';

interface Props {
  onClick?: (...args: any[]) => any;
  relativeReportUrl: string;
}

export class BaseCsvDownloadLink extends PureComponent<Props> {
  static defaultProps = {
    onClick: null,
  };

  onClick = () => {
    const { onClick } = this.props;
    if (onClick == null) {
      return;
    }
    onClick();
  };

  render() {
    const { relativeReportUrl } = this.props;

    return (
      <div className={style.link}>
        <Button
          colour="help"
          variant="hollow"
          icon={<Download />}
          label={Wahanda.lang.reports.sales.download}
          href={`${reportingApiRoot}/venue/${App.getVenueId()}/${relativeReportUrl}`}
          onClick={this.onClick}
        />
      </div>
    );
  }
}
