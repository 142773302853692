import React from 'react';
import moment from 'common/moment';
import { Avatar } from 'components/common/Avatar';
import { ShiftHeaderArgs } from '../utils/types';

import style from './ShiftModalHeader.scss';

export class ShiftModalHeader extends React.PureComponent<ShiftHeaderArgs> {
  public static defaultProps = {
    showImage: true,
    date: null,
  };

  public render() {
    const { date, image, title, showImage } = this.props;

    return (
      <div className={style.header}>
        {showImage && (
          <div className={style.image}>
            <Avatar text={title} src={image} type="large" />
          </div>
        )}
        <div>
          <div className={style.title}>{title}</div>
          {date && <div className={style.date}>{moment(date).formatMediumLocaleDate()}</div>}
        </div>
      </div>
    );
  }
}
