/* global BackboneEx */

import { trackEvent } from 'common/analytics';

(function () {
  const dialogHeight = function () {
    return Math.floor(window.innerHeight * 0.7);
  };
  App.Views.Dialog.IgluModal = BackboneEx.View.Dialog2.extend({
    events: {
      'click .js-close': 'closeIgluHandler',
    },

    templateId: 'iglu-modal',

    options: {
      width: '90%',
      height: dialogHeight(),
      dataTest: 'iglu-modal',
    },

    dialogTitle: 'Iglu settings',

    loadContent(uri) {
      const $i = $('<iframe/>')
        .css({ minHeight: `${dialogHeight() - 62}px` })
        .width('100%')
        .height('100%')
        .attr('src', uri)
        .appendTo(this.$('.dialog-content'));
    },

    closeEventHandler() {
      this.unregisterCloseHandler();
      this.close();
    },

    closeIgluHandler() {
      this.removeIgluUrl();
      this.unregisterCloseHandler();
      this.close();
    },

    registerCloseHandler() {
      App.on(Wahanda.Event.IGLU_DIALOG_CLOSE, this.closeIgluHandler.bind(this));
    },

    unregisterCloseHandler() {
      App.off(Wahanda.Event.IGLU_DIALOG_CLOSE, null);
    },

    // Stops jquery drag events when cursor leaves the window since
    // "mouseup" event is not being fired. Otherwise, mouse cursor
    // sometimes get stuck to the dialog header, as detailed in
    // https://wahanda.atlassian.net/browse/DEV-40986
    registerOffScreenDragHandler() {
      let dragging = false;
      this.$el
        .on('dragstart', function (e) {
          dragging = true;
        })
        .on('dragstop', function (e) {
          dragging = false;
        })
        .on('drag', function (e) {
          if (!dragging) return;

          const mouseOutsideWindow =
            e.clientX < 0 ||
            e.clientY < 0 ||
            e.clientX > window.innerWidth ||
            e.clientY > window.innerHeight;
          if (mouseOutsideWindow) {
            return false;
          }
        });
    },

    _afterClose() {
      this.removeIgluUrl();
      this.unregisterCloseHandler();
    },

    removeIgluUrl() {
      if (window.location.hash.indexOf('/_/iglu') > -1) {
        const removeIgluPattern = '(.*)/_/iglu/';
        const windowHash = window.location.hash.match(removeIgluPattern)[1];
        window.history.pushState('', document.title, windowHash);
      }
    },
  });

  App.Views.Dialog.IgluModal.validateAndShow = function (uri, title, optionsOverride) {
    if (uri === undefined) {
      return;
    }
    if (App.isIgluEnabled() && /iglu/.test(uri)) {
      if (title === undefined) {
        title = App.Views.Dialog.IgluModal.extractFrameTitle(uri);
      }
      App.Views.Dialog.IgluModal.show(uri, title, optionsOverride);

      const $igluIframe = $('#iglu-dialog iframe')[0];
      const $iframeWindow = $($igluIframe.contentWindow);

      $iframeWindow.on('click', (e) => {
        const $target = $(e.target);

        const clickedOnSubmit =
          $target.is('.Copyservice_cta-importBtn.ui-confirmdialog-yes') ||
          $target.parent().is('.Copyservice_cta-importBtn.ui-confirmdialog-yes');

        if (clickedOnSubmit) {
          trackEvent('services', 'click', 'import-services', 'finalise');
        }
      });
    } else {
      window.history.pushState('', document.title, window.location.hash.replace(uri, ''));
    }
  };

  App.Views.Dialog.IgluModal.extractFrameTitle = function (uri) {
    // remove some characters that could cause problems
    const escapedUri = uri.replace(/\?.*/, '');
    return `${escapedUri.match('iglu/(.*)/')[1]} settings`;
  };

  /**
   *
   * Some routes in connect have optional parameters, as is their right,
   * but having 2 optional parameters can cause parameter misplacements,
   * especially since backbone routing parameters to function parameters
   * doesn't resepect variable names.
   *
   * in the case of services where we have an optional FilterId we can sometimes
   * find that the iglu deeplink falls into this field.
   */
  App.Views.Dialog.IgluModal.findIgluUri = function (normalIgluUri, possibleAlternativeUri) {
    const igluUriPattern = '(/iglu/.*)';
    let igluUri = '';
    const uriTest = /iglu/;

    if (normalIgluUri && uriTest.test(normalIgluUri)) {
      igluUri = normalIgluUri.match(igluUriPattern)[1];
    } else if (possibleAlternativeUri && uriTest.test(possibleAlternativeUri)) {
      igluUri = possibleAlternativeUri.match(igluUriPattern)[1];
    }
    return igluUri;
  };

  App.Views.Dialog.IgluModal.openIgluSupplierSettings = function (supplierId) {
    const supplierUri = `/iglu/supplier/supplier.xhtml?supplierId=${supplierId}`;
    this.validateAndShow(supplierUri);
  };

  App.Views.Dialog.IgluModal.openIgluVenueSettings = function (venueId) {
    const venueUri = `/iglu/venue/venue.xhtml?venueId=${venueId}`;
    this.validateAndShow(venueUri);
  };

  (App.Views.Dialog.IgluModal.openIgluCopyServices = function (venueId) {
    const copyServiceUri = `/iglu/service/copyservice.xhtml?destinationVenueId=${venueId}`;

    // Width is 700px or 90%, whichever is smaller
    const width = Math.min(Math.floor(window.innerWidth * 0.9), 700);
    this.validateAndShow(copyServiceUri, Wahanda.lang.menu.importMenu.dialogTitle, {
      width,
    });
  }),
    /**
     * Static method for dialog invocation.
     *
     * @return App.Views.Dialog.IgluModal
     */
    (App.Views.Dialog.IgluModal.show = function (uri, title, optionsOverride = {}) {
      const dialog = new App.Views.Dialog.IgluModal();
      dialog.dialogTitle = title;
      dialog.options.height = dialogHeight();

      Object.assign(dialog.options, optionsOverride);

      dialog.render();
      dialog.registerCloseHandler();
      dialog.registerOffScreenDragHandler();
      dialog.open();
      dialog.loadContent(uri);
      return dialog;
    });
})();
