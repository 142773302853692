import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Wahanda from 'common/wahanda';
import { POS_REPORTS_ROUTES } from 'components/reports/newSalesReports/SalesReportContainer/reportRoutes';
import { GoTo, Direction } from 'components/common/GoTo';
import VatReports from './VatReport/container';
import MonthlySalesPeriodReport from './SalesPeriodReport/MonthlySalesPeriodReport/container';
import YearlySalesPeriodReport from './SalesPeriodReport/YearlySalesPeriodReport/container';
import VenueInfoForPrint from './VenueInfoForPrint/container';
import style from './style.scss';

interface Props {
  canViewVatSaleReport: boolean;
  canViewPosReports: boolean;
}

export default class POSReports extends React.PureComponent<Props> {
  public render() {
    const { canViewVatSaleReport, canViewPosReports } = this.props;
    return (
      <div className={style.posReports}>
        <GoTo className={style.goBackLink} href="/" direction={Direction.backward}>
          {Wahanda.lang.reports.tabs.reports}
        </GoTo>
        <VenueInfoForPrint />
        <div className={style.report}>
          <Switch>
            {canViewVatSaleReport && (
              <Route path={POS_REPORTS_ROUTES.VAT_REPORT} component={VatReports} />
            )}
            {canViewPosReports && (
              <React.Fragment>
                <Route
                  path={POS_REPORTS_ROUTES.YEAR_POS_REPORT}
                  component={YearlySalesPeriodReport}
                />
                <Route
                  path={POS_REPORTS_ROUTES.MONTH_POS_REPORT}
                  component={MonthlySalesPeriodReport}
                />
              </React.Fragment>
            )}
          </Switch>
        </div>
      </div>
    );
  }
}
