import { connect } from 'react-redux';

import { RankingBoost as Component } from './RankingBoost';

const mapStateToProps = ({ rankingBoost }) => {
  return {
    endDate: rankingBoost.endDate || undefined,
  };
};

export const RankingBoost = connect(mapStateToProps)(Component);
