/* eslint-disable */

import moment from 'common/moment';

App.Models.Customer = BackboneEx.Model.Silent.extend(
  {
    GENDER_MALE: 'M',
    GENDER_FEMALE: 'F',

    CHANNEL_CUSTOMER: 'CUSTOMER',
    CHANNEL_SUPCUS: 'SUPPLIERS_CUSTOMER',
    CHANNEL_LOCAL: 'SUPPLIER',
    INTRNL: 'INTERNAL',

    include: null,

    attributesToSave: [
      'name',
      'phone',
      'emailAddress',
      'gender',
      'birthYear',
      'birthMonth',
      'birthDay',
      'prepaymentRequired',
      'sendMassEmails',
      'notes',
      'active',
      'locale',
    ],

    defaults: {
      active: true,
      name: null,
      phone: null,
      emailAddress: null,
      gender: null,
      birthYear: null,
      birthMonth: null,
      birthDay: null,
      prepaymentRequired: false,
      sendMassEmails: false,
      locale: null,
      appointmentDate: null,
    },

    initialize: function () {},

    url: function () {
      var base = '/venue/' + App.config.get('venue').id + '/customer';
      if (this.id) {
        base += '/' + this.id;
      }
      base += '.json';
      if (this.include) {
        base += '?include=' + this.include;
      }
      return App.Api.wsUrl(base);
    },

    _getSaveData: function () {
      var data = BackboneEx.Model.Silent.prototype._getSaveData.apply(this, arguments);
      if (!this.id) {
        // When creating a client, define the supplier channel.
        data.channel = App.Models.Customer.CHANNEL_SUPPLIER;
      }
      return data;
    },

    getBookingActor: function (history) {
      const i = history.length;
      if (i) {
        const n = i - 1;
        const { bookingActor } = history[n];
        return (
          bookingActor || null // for consistency of return
        );
      }

      return null;
    },

    getAppointmentDate: function () {
      return this.get('appointmentDate');
    },

    /*
     *  Are the appointment day and today the same day?
     */
    isAppointmentToday: function () {
      const currentDate = new Date();
      const appointment = this.getAppointmentDate();

      return moment(appointment).isSame(currentDate, 'day');
    },

    /*
     *  Is the appointment day in the future?
     */
    isAppointmentAfterToday: function () {
      const currentDate = new Date();
      const appointment = this.getAppointmentDate();

      return moment(appointment).isAfter(currentDate, 'day');
    },

    isMarketplaceBooking: function () {
      const history = this.get('history');

      if (Array.isArray(history)) {
        return this.getBookingActor(history) === this.CHANNEL_CUSTOMER;
      }

      return false;
    },

    isWidgetBooking: function () {
      const history = this.get('history');

      if (Array.isArray(history)) {
        return this.getBookingActor(history) === this.CHANNEL_SUPCUS;
      }

      return false;
    },

    isInternalBooking: function () {
      const history = this.get('history');

      if (Array.isArray(history)) {
        return this.getBookingActor(history) === this.INTRNL;
      }

      return false;
    },

    isConnectBooking: function () {
      const history = this.get('history');

      if (Array.isArray(history)) {
        const bookingActor = this.getBookingActor(history);

        /*
         *  1) It is a Connect booking if the `bookingActor`
         *  attribute is not defined, or is 'SUPPLIER'
         */
        return !bookingActor || bookingActor === this.CHANNEL_LOCAL;
      }

      /*
       *  2) It is a Connect booking if the `history`
       *  attribute is not defined (or not an array)
       */
      return true;
    },

    isArchived: function () {
      return !this.get('active');
    },

    setArchived: function () {
      this.set('active', false);
    },

    toAppointmentStructure: function () {
      return {
        venueCustomerId: this.id,
        walkIn: false,
        consumerName: this.get('name'),
        anonymousNote: null,
        consumerEmail: this.get('emailAddress'),
      };
    },
  },
  {
    CHANNEL_SUPPLIER: 'SUP',

    getUrlFilters: function () {
      return {
        include: [],
        searchText: null,
        searchScope: null,
        limit: null,
        sortBy: [],
        sortOrder: [],
        lastVisitDateFrom: null,
        lastVisitDateTo: null,
        bookingCountFrom: null,
        bookingCountTo: null,
        sendMassEmails: null,
        prepaymentRequired: null,
      };
    },
    getUrlFilterMap: function () {
      return {
        include: 'include',
        searchText: 'search-text',
        searchScope: 'search-scope',
        limit: 'limit',
        sortBy: 'sort-by',
        sortOrder: 'sort-order',
        lastVisitDateFrom: 'last-visit-date-from',
        lastVisitDateTo: 'last-visit-date-to',
        bookingCountFrom: 'booking-count-from',
        bookingCountTo: 'booking-count-to',
        sendMassEmails: 'sendMassEmails',
        prepaymentRequired: 'prepaymentRequired',
      };
    },
    getUrlFilterHasActiveFiltersFunction: function () {
      return function () {
        return !!(
          this.filters.searchText ||
          this.filters.lastVisitDateFrom ||
          this.filters.lastVisitDateTo ||
          this.filters.bookingCountFrom ||
          this.filters.bookingCountTo
        );
      };
    },
  },
);
