/* global BackboneEx */
/* eslint func-names: 0 */
/* eslint prefer-template: 0 */
import { formatInternationalFromInput } from 'common/phonenumber';
import App from 'common/backbone-app';
import channelCodes from 'config/channelCodes';
import _ from 'common/underscore';
import { trackEvent } from 'common/analytics';
import { xhr } from 'common/xhr';
import apiUrl from 'common/api-url';
import haversine from 'haversine-distance';
import { venuePhotosSuccess } from 'components/settings/VenueDetails/actions';
import storeBuilder from 'reduxStore/store';
import storeObserver from 'reduxStore/store/storeObserver';

/**
 * Venue details.
 */
App.Views.Settings.VenueDetails = App.Views.Form.extend({
  events: {
    'change #venue-details-addressCountryCode': 'toggleStatesSelect',
    'click .location-map-wrapper': 'showMapDialog',
  },

  fieldPrefix: /^venue-details-/,
  markerUrl: '/assets/map-marker.png',

  initialize: function () {
    const self = this;
    this.initStore();
    this.model.on('fetched', function () {
      self.render();
    });

    this.populated = false;

    App.on(Wahanda.Event.APP_LOADED, function () {
      self.populated = false;
      if (self.editorView) {
        self.editorView.destruct();
      }
    });
  },

  updateVenueAddressDisplay: function () {
    const isFrench = App.config.get('venue').contentChannelCode === channelCodes.FR;
    const isGerman = App.config.get('venue').contentChannelCode === channelCodes.DE;

    if (!(isFrench || isGerman)) {
      return;
    }

    this.$('.js-city-form-row').show();
    const address = this.model.get('address').trim().split('\n');
    const city = address.pop();
    this.$('#venue-details-address').attr('max-text-lines', 3).val(address.join('\n'));
    this.$('#venue-details-city-input').attr('required', true).val(city);
  },

  /**
   * Renders the whole settings view.
   */
  render: function () {
    this.editorView = new BackboneEx.View.Editor({
      el: this.$('.description-part'),
      readOnly: !Wahanda.Permissions.canEditVenueDetails(),
      implementDisabledStyle: true,
    });

    this.disableForm();

    if (!this.populated) {
      this.populated = true;
      this.populate();
      this.setValidation();
      this.editorView.render();
    } else {
      this.editorView.update(this.model.get('longDescription'));
    }

    this.fillFormFromModel();
    this.updateVenueAddressDisplay();

    this.renderPhone();

    const inChain = this.model.get('chain') && this.model.get('chain').name;
    this.$('.part-of').toggleClass('hidden', !inChain);
    if (inChain) {
      this.$('.chain-name').text(this.model.get('chain').name);
    }

    this.toggleStatesSelect();
    this.toggleLanguageSelect();

    // Preview link
    App.Views.Settings.renderPreviewLink(this.$('.preview-link'), this.model);
    this.$('.preview-link').click(() => {
      trackEvent('venue-details', 'click', 'preview-on-treatwell');
    });

    this.$('.location-txt').text(this.model.get('locationName'));

    this.enableFormWithRestrictions();
    // Saving form state for changes checking
    this.initialFormState = this.getFormValues();

    // Rendering shadows
    this.$('.form-venue-details').shadows({ width: 606 });
    this.$('.form-venue-pictures').shadows({ width: 138 });

    // Render primary image
    this.renderLogo();

    this.renderMapImageFromModel();
    this.bindTooltips();

    this.renderPhotosGuideSidebar();
    this.renderPhotoUploadRow();
    this.renderPhotoThumbnailsRow();
    this.model.canEditImages = Wahanda.Permissions.canEditImages();
  },

  renderPhotosGuideSidebar: function () {
    if (!Wahanda.Permissions.canEditImages()) {
      return;
    }

    const node = document.getElementById('venue-details-photos-guide-sidebar');
    App.ES6.Initializers.PhotosGuideSidebar({ node }).render({});
  },

  renderPhotoUploadRow: function () {
    if (!Wahanda.Permissions.canEditImages()) {
      return;
    }

    this.renderPhotoUpload();

    const node = document.getElementById('venue-details-photos-upload-row');

    node.style.display = 'table-row';
  },

  renderPhotoUpload: function () {
    const node = document.getElementById('venue-details-photo-upload');

    App.ES6.Initializers.PhotoUpload({ node }).render({});
  },

  uploadPhoto: async function (file) {
    const venueId = App.config.get('venue').id;
    const url = App.Api.wsUrl(`/venue/${venueId}/image.json`);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', file.name);

    const response = await fetch(url, { method: 'POST', body: formData });

    if (!response.ok) {
      throw new Error('Failed to upload photo');
    }

    return response.json();
  },

  initStore: function () {
    this.store = storeBuilder();
    this.bingLogoImage(this.store);
  },

  bingLogoImage: function (store) {
    storeObserver(
      store,
      (state) => state.venueDetails.photos,
      (photos) => {
        const logoImage = this.model.get('logoImage');
        const firstImage = photos?.find(this.uploadedPhoto);
        const isLogoImageOutOfSync = firstImage && firstImage.id !== logoImage?.id;
        if (isLogoImageOutOfSync) {
          this.setLogoImage(firstImage);
        }
      },
    );
  },

  setLogoImage: function (photo) {
    this.$('.logo-image')
      .removeClass('hidden')
      .attr('src', Wahanda.ScreenDensity.getImageFromStructure(photo.uris, 156, 104));
    this.model.set('logoImage', _.extend({}, photo, { visible: true }));
  },

  getPhotos: function () {
    const photos = this.store.getState().venueDetails.photos;
    return photos.filter(this.uploadedPhoto);
  },

  uploadedPhoto: function (photo) {
    return !photo.error && !photo.isLoading;
  },

  renderPhotoThumbnailsRow: function () {
    this.renderPhotoThumbnails();
    this.renderPhotoThumbnailsLabel();
  },

  renderPhotoThumbnailsLabel: function () {
    const label = document.querySelector('label[for="venue-details-photos-preview"]');

    label.textContent = Wahanda.Permissions.canEditImages()
      ? Wahanda.lang.settings.venue.photos.label.editable
      : Wahanda.lang.settings.venue.photos.label.readonly;
  },

  renderPhotoThumbnails: function () {
    const node = document.getElementById('venue-details-photo-thumbnails');

    const photos = this.model.getVisibleImageList();
    this.store.dispatch(venuePhotosSuccess({ photos }));

    App.ES6.Initializers.PhotoThumbnailList({ node }).render({});
  },

  toggleRestrictions: function () {
    const restrictEditing = !Wahanda.Permissions.canEditVenueDetails();
    this.$('.description-part').toggleClass('disabled', restrictEditing);
    this.$('form:first').toggleClass('restricted', restrictEditing);

    const prefix = 'venue-details-';
    const fields = ['name', 'venueTypeId', 'address', 'addressPostalRef'];

    const $formFields = $(
      fields.map(
        function (name) {
          return this.$(`[name='${prefix}${name}']`).get(0);
        }.bind(this),
      ),
    );

    function toggleField($field, hide, getText) {
      const $parent = $field.parent();
      $parent.toggleClass('as-text', hide).find('.js-text').remove();
      if (hide) {
        $parent.append(
          $('<span>', { class: 'js-text input-replacement' }).html(String(getText($field))),
        );
        $field.hide();
      } else {
        $parent.find('.js-text').remove();
        $field.show();
      }
    }

    function toggleSelect($select, hide) {
      toggleField($select, hide, function getText() {
        return $select.find('option:selected').text();
      });
    }

    function toggleTextField($field, hide) {
      toggleField($field, hide, function getText() {
        return Wahanda.Text.nl2br(Wahanda.Util.escapeHtml($field.val()));
      });
    }

    $formFields.toggleFormElements(!restrictEditing);

    // Toggle map editing
    this.$('.location-map-wrapper').toggleClass('no-edit', restrictEditing);
    this.$('.js-edit-map').toggle(!restrictEditing);

    // Convert the fields to/from text
    $formFields.each(function () {
      const $field = $(this);

      if ($field.is('select')) {
        toggleSelect($field, restrictEditing);
      } else {
        toggleTextField($field, restrictEditing);
      }
    });
  },

  renderLogo: function () {
    let image = this.model.get('logoImage');
    if (!image) {
      image = this.model.getVisibleImageList()[0];
    }
    if (image) {
      this.$('.logo-image')
        .removeClass('hidden')
        .attr('src', Wahanda.ScreenDensity.getImageFromStructure(image.uris, 156, 104));
    } else {
      this.$('.logo-image').addClass('hidden');
    }

    if (!Wahanda.Permissions.canEditImages()) {
      this.$('.logo-image').data('valid-tooltip', '');
    }
  },

  renderMapImageFromModel: function () {
    if (this.model.get('latitude') == null) {
      // Full model's data not loaded yet. Waiting.
      return;
    }
    this.renderMapImage(
      this.model.get('latitude'),
      this.model.get('longitude'),
      this.model.get('gmapScale'),
    );
  },

  renderMapImage: function (latitude, longitude, scale) {
    const $container = this.$('.location-map-wrapper');
    const mapParams = {
      sensor: 'false',
      zoom: scale,
      size: `${$container.width()}x${$container.height()}`,
      maptype: 'roadmap',
      markers: `icon:${this.getMarkerImageUrl()}|${latitude},${longitude}`,
      key: App.config.getGoogleMapApiKey(),
    };
    $container.find('img').remove();
    $container.prepend(
      $('<img>', {
        alt: '',
        src: `https://maps.googleapis.com/maps/api/staticmap?${$.param(mapParams)}`,
      }),
    );
  },

  populate: function () {
    // Render countries
    let options = [];
    _.each(App.referenceData.get('country'), function (country) {
      options.push({
        value: country.id,
        title: country.title,
      });
    });
    this.$('select[name=venue-details-addressCountryCode]').html(
      '<option value="">' +
        Wahanda.lang.shared.selectInitialOption +
        '</option>' +
        Wahanda.Template.renderTemplate('form-select-items', {
          options: options,
        }),
    );
    // Render states
    options = [];
    _.each(App.referenceData.get('locationState'), function (state) {
      options.push({
        value: state.id,
        title: state.title,
      });
    });
    this.$('select[name=venue-details-addressStateCode]').html(
      '<option value="">' +
        Wahanda.lang.shared.selectInitialOption +
        '</option>' +
        Wahanda.Template.renderTemplate('form-select-items', {
          options: options,
        }),
    );
    // Render locales ...
    options = [];
    _.each(App.config.getChannelSupportedLocales(), function (localeCode, name) {
      const option = {
        value: localeCode,
        title: Wahanda.lang.language[localeCode] ? Wahanda.lang.language[localeCode] : name,
      };
      options.push(option);
    });
    this.$('select[name=venue-details-locale]').html(
      '<option value="">' +
        Wahanda.lang.shared.selectInitialOption +
        '</option>' +
        Wahanda.Template.renderTemplate('form-select-items', {
          options: options,
        }),
    );

    // Primary venue type
    options = [];
    _.each(App.referenceData.get('venueType'), function (state) {
      options.push({
        value: state.id,
        title: state.title,
      });
    });
    this.$('select[name=venue-details-venueTypeId]').html(
      Wahanda.Template.renderTemplate('form-select-items', { options: options }),
    );
  },

  renderPhone: function () {
    let phone = (this.model.get('phone') || {}).reservations || '';
    if (!phone) {
      const fulfillmentCommunication = this.model.get('fulfillmentCommunication') || {};

      phone = fulfillmentCommunication.supportPhone;
    }

    this.$('#venue-details-phone').val(phone);
  },

  enableFormWithRestrictions: function () {
    this.enableForm();
    this.toggleRestrictions();
  },

  trackSubmit: function () {
    const trackedFields = [
      'name',
      'address',
      'addressPostalRef',
      'phone',
      'emailAddress',
      'primaryVenueUri',
    ];

    const newValues = this.getFormValues();
    const oldValues = this.initialFormState;

    trackedFields
      .filter((key) => {
        return newValues[key] !== oldValues[key];
      })
      .forEach((field) => {
        trackEvent('venue-details', 'update', 'save-venue-details', field);
      });
  },

  /**
   * Handler clicking on "Save".
   *
   * @param Event event
   */
  save: async function () {
    const self = this;

    // Destroy custom validation error in case it is a consecutive save
    $('#venue-details-address').qtip('destroy');

    this.setModelValues();
    this.trackSubmit();

    this.disableForm();
    const $saveButton = this.$('button.save-action').action('doing');
    const toSave = [
      'name',
      'address',
      'addressCountryCode',
      'addressPostalRef',
      'addressStateCode',
      'emailAddress',
      'primaryVenueUri',
      'venueTypeId',
      'phone',
      'fulfillmentCommunication',
      'images',
      'locale',
      'logoImage',
      'longDescription',
      { name: 'latitude', type: 'float' },
      { name: 'longitude', type: 'float' },
      { name: 'gmapScale', type: 'float' },
      { name: 'gmapLongitude', type: 'float' },
      { name: 'gmapLatitude', type: 'float' },
    ];

    this.model.setAttributesToSave(toSave);
    this.model.setNextSaveRelations(['images', 'phones', 'fulfillment-communication']);

    const formValues = this.getFormValues();

    const addressFields = {
      addressLine1: formValues.address,
      city:
        formValues.city || formValues.address.trim().replace(/,/, '\n').split('\n').pop().trim(),
      postcode: formValues.addressPostalRef,
    };

    const geocode = new Promise((resolve, reject) => {
      xhr.getWithPromise(apiUrl('GEOCODE', addressFields)).success(resolve).fail(reject);
    });

    geocode
      .then(
        ({ longitude, latitude }) => {
          const distance = haversine(
            { longitude, latitude },
            { latitude: formValues.latitude, longitude: formValues.longitude },
          );

          trackEvent(
            'venue-details',
            'validation',
            'map-location-diff-meters',
            Math.round(distance),
          );

          if (distance > 500) {
            // Imply that map was not updated or location marker was placed too far from the actual address. Use geocoded coordinates.
            this.model.set({
              latitude,
              longitude,
              gmapLongitude: longitude,
              gmapLatitude: latitude,
            });
          }
        },
        () => {
          trackEvent('venue-details', 'error', 'address', JSON.stringify(addressFields));
          const htmlContent = Wahanda.Template.renderTemplate('address-geocode-warning');

          return new Promise((resolve, reject) => {
            $saveButton.dialog2SaveQuestionTip({
              htmlContent,
              saveAction: () => {
                const { gmapLatitude, gmapLongitude } = this.initialFormState;
                // Disregard changes to gmap lat/long, if any.
                this.model.set({ gmapLongitude, gmapLatitude });
                resolve();
              },
              cancelAction: reject,
              position: {
                my: 'bottom left',
                at: 'top center',
              },
            });
          });
        },
      )
      .then(
        () => {
          // Save the model and show actions to the user
          self.model
            .save()
            .done(function () {
              $saveButton.action();
              self.enableFormWithRestrictions();
              self.initialFormState = self.getFormValues();
              App.trigger(Wahanda.Event.VENUE_SAVED);
              App.initVenue(null, true);
            })
            .fail(function () {
              self.enableFormWithRestrictions();
              $saveButton.action().errorTip(Wahanda.lang.shared.savingFailed);
              self.model.revert();
            });
        },
        () => {
          self.enableFormWithRestrictions();
          $saveButton.action();
          self.model.revert();
        },
      );
  },

  setModelValues: function () {
    const values = this.getFormValues();

    const standardNumber = formatInternationalFromInput(
      values.phone,
      $('#venue-details-addressCountryCode').val(),
    );

    // 1. Try to find a phone. If none exists - create new one.
    const phoneObj = this.model.get('phone') || {};
    phoneObj.reservations = standardNumber;
    values.phone = phoneObj;

    // 2. Try to find a fulfillment rule for phone. If none exists, create one.
    values.fulfillmentCommunication = this.model.get('fulfillmentCommunication') || {};
    values.fulfillmentCommunication.supportPhone = standardNumber;

    if (values.addressCountryCode !== 'US') {
      values.addressStateCode = null;
    }
    if (typeof values.address !== 'undefined') {
      values.address = Wahanda.Text.trimAndCleanNewLines(values.address);
    }

    const isCityFieldVisible = [channelCodes.FR, channelCodes.DE].includes(
      App.config.get('venue').contentChannelCode,
    );

    if (isCityFieldVisible) {
      values.address = [...values.address.split('\n'), values.city].join('\n');
      values.city = undefined;
    }

    values.images = this.getPhotos();
    values.images.shift();

    this.model.set(values);
  },

  getFormValues: function () {
    const values = App.Views.Form.prototype.getFormValues.call(this, { withDisabled: true });
    if (this.editorView) {
      values.longDescription = this.editorView.getValue();
    }
    return values;
  },

  _modelValuesHaventChanged: function () {
    // TODO FIXME: ckeditor changes some symbols on load. When first gathering data, we do not have ckeditor ready
    // yet.
    // So this is a temporary fix, but change checking should work on the longDescription field too.
    delete this.initialFormState.longDescription;
    // End of fix

    const currentState = this.getFormValues();
    const change = _.find(this.initialFormState, function (value, name) {
      return value !== currentState[name];
    });
    // if no changes are found - `change` should be "undefined"
    if (change == null) {
      const getVisibleImageString = function (list) {
        return _.chain(list)
          .filter(function (img) {
            return img.visible;
          })
          .pluck('id')
          .value()
          .join(',');
      };
      // Last check - if images order and IDs match
      const modelImages = getVisibleImageString(this.model.get('images'));
      const formImages = getVisibleImageString(this.getPhotos().slice(1));

      return modelImages === formImages;
    }
    return false;
  },

  setValidation: function () {
    const self = this;
    const validations = Wahanda.Validate.getValidations('defaults', {
      submitHandler: function () {
        self.save();
      },
    });

    this.$('form').validate(validations);
  },

  toggleStatesSelect: function () {
    const isUSA = this.$('#venue-details-addressCountryCode').val() === 'US';
    this.$('.state-row').toggleClass('hidden', !isUSA);
  },

  isMultiLocale: function () {
    const channelSupportedLocales = App.config.getChannelSupportedLocales();

    if (!channelSupportedLocales) {
      return false;
    }

    const numberOfSupportedLocales = Object.keys(channelSupportedLocales).length;

    return numberOfSupportedLocales > 1;
  },

  toggleLanguageSelect: function () {
    this.$('.language-row').toggleClass('hidden', !this.isMultiLocale());
  },

  showMapDialog: function () {
    if (!Wahanda.Permissions.canEditVenueDetails()) {
      return;
    }

    trackEvent('venue-details', 'click', 'venue-map');

    const self = this;
    const dialog = new App.Views.Settings.VenueDetails.Map({
      scale: this.$('#venue-details-gmapScale').val(),
      lat: this.$('#venue-details-latitude').val(),
      lng: this.$('#venue-details-longitude').val(),
      mapLat: this.$('#venue-details-gmapLatitude').val(),
      mapLng: this.$('#venue-details-gmapLongitude').val(),
      address: this.getFullAddress(),
      onSave: function (latitude, longitude, mapCenterLatitude, mapCenterLongitude, zoom) {
        self.$('#venue-details-latitude').val(latitude);
        self.$('#venue-details-longitude').val(longitude);
        self.$('#venue-details-gmapLatitude').val(mapCenterLatitude);
        self.$('#venue-details-gmapLongitude').val(mapCenterLongitude);
        self.$('#venue-details-gmapScale').val(zoom);

        self.renderMapImage(latitude, longitude, zoom);
      },
    });
    dialog.render();
    dialog.open();
  },

  getFullAddress: function () {
    let address = this.$('#venue-details-address').val();
    const postCode = this.$('#venue-details-addressPostalRef').val();
    const country = this.$('#venue-details-addressCountryCode').val();
    const countryText = this.$('#venue-details-addressCountryCode').find('option:selected').text();

    if (country === 'US') {
      const state = this.$('#venue-details-addressStateCode').val();
      if (state) {
        address += ', ' + state;
      }
    }

    return {
      address: address,
      postCode: postCode,
      countryCode: country,
      country: countryText,
    };
  },

  getMarkerImageUrl: function () {
    let host = window.location.host;
    // if dev inviroment use marker url from production. As google can't donwload it localy.
    if (/twbox|twtest/i.test(host)) {
      host = 'connect.treatwell.co.uk';
    }
    return 'https://' + host + this.markerUrl;
  },

  bindTooltips: function () {
    const TOOLTIP_FULL_MIN_SCREEN_WIDTH = 1050;
    const canEdit = Wahanda.Permissions.canEditVenueDetails();
    const readonlyTooltipHtml = Wahanda.Util.getEditingLockedHtml();

    this.$('[data-valid-tooltip]').each(function () {
      const $el = $(this);
      // Set the tooltip text to show.
      $el.data('tooltip', canEdit ? $el.data('valid-tooltip') : readonlyTooltipHtml);
    });

    const opts = {
      position: {
        container: this.$el.find('.form-content'),
        viewport: true,
        my: 'left center',
        at: 'right center',
        adjust: {
          y: -8,
        },
      },
      // Show tooltips for touch devices when editing is disabled
      allowTouchDevice: !canEdit,
    };
    if (window.innerWidth < TOOLTIP_FULL_MIN_SCREEN_WIDTH) {
      opts.style = {
        width: 150,
      };
    }
    this.$el.tooltipize(opts);
  },
});
