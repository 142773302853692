import {
  CUSTOMER_DUPLICATE_SET_DATA,
  CUSTOMER_INFO_RESET,
  CUSTOMER_INFO_REQUEST,
  CUSTOMER_INFO_REQUEST_SUCCESS,
  CUSTOMER_INFO_REQUEST_FAILURE,
  CUSTOMER_INFO_SET_DATA,
  CUSTOMER_INFO_SUBMIT,
  CUSTOMER_INFO_SUBMIT_SUCCESS,
  CUSTOMER_INFO_SUBMIT_FAILURE,
} from 'reduxStore/actionsConst';

// Reset customer state
export const resetCustomerAction = () => ({
  type: CUSTOMER_INFO_RESET,
});

// Get customer data
export const requestCustomerAction = (id) => ({
  type: CUSTOMER_INFO_REQUEST,
  id,
});

// Submit the customer data that was setup in state
export const submitCustomerAction = () => ({
  type: CUSTOMER_INFO_SUBMIT,
});

export const setCustomerAction = (data) => ({
  type: CUSTOMER_INFO_SET_DATA,
  data,
});

export const setDuplicateCustomerDataAction = (data) => ({
  type: CUSTOMER_DUPLICATE_SET_DATA,
  data,
});

export const receiveCustomerAction = (data) => ({
  type: CUSTOMER_INFO_REQUEST_SUCCESS,
  data,
});

export const receiveCustomerActionError = (data) => ({
  type: CUSTOMER_INFO_REQUEST_FAILURE,
  data,
});

export const putCustomerInfoSuccessAction = (data) => ({
  type: CUSTOMER_INFO_SUBMIT_SUCCESS,
  data,
});

export const putCustomerInfoErrorAction = (data) => ({
  type: CUSTOMER_INFO_SUBMIT_FAILURE,
  data,
});
