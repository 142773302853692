import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Wahanda from 'common/wahanda';
import * as actions from './store/actions';
import { EmployeeWorkingHoursToggle } from './EmployeeWorkingHoursToggle';

import { EMPLOYEE_WORKING_HOURS_TOGGLE_STORAGE_KEY } from './constants';

const mapStateToProps = () => ({
  initialIsCollapsed: Wahanda.LocalStorage.get(EMPLOYEE_WORKING_HOURS_TOGGLE_STORAGE_KEY),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setEmployeeWorkingHoursToggle: bindActionCreators(
      actions.setEmployeeWorkingHoursToggle,
      dispatch,
    ),
  },
});

export const EmployeeWorkingHoursToggleContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeWorkingHoursToggle);
