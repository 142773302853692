import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import SettingsOnlineWidget from 'components/settings/online/Widget/container';

const store = storeBuilder();

function render(node, options) {
  ReactDOM.render(
    <Provider store={store}>
      <SettingsOnlineWidget {...options} />
    </Provider>,
    node,
  );
}

function destroy(node) {
  ReactDOM.unmountComponentAtNode(node);
}

export default {
  render,
  destroy,
};
