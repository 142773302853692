import { init, identify, clearIdentify, group, clearGroup, page } from './trackingService';

export const Tracking = {
  init,
  identify,
  clearIdentify,
  group,
  clearGroup,
  page,
};
