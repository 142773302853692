import React, { Component } from 'react';
import Wahanda from 'common/wahanda';
import ROLES from 'common/constants/userRoles';
import UnderlinedInput from 'components/common/UnderlinedInput/withFormsy';
import DialogContentBox from 'components/common/dialog/DialogContentBox';
import { Checkbox } from 'components/common/Checkbox';
import { Radio } from 'components/common/Radio';
import { phoneValidator } from 'common/validators';
import { InputError } from 'components/common/__BaseCommon';
import EmployeePermissions from './EmployeePermissions/container';
import EmployeeProfilePhoto from './EmployeeProfilePhoto/container';
import employeeErrors from './validators';
import style from './style.scss';
import { Notice } from 'components/common/Notice';

const lang = Wahanda.lang.settings.employees.employee.labels;

export const EMPLOYMENT_STATUS = {
  EMPLOYED: 'EMPLOYED',
  SELF_EMPLOYED: 'SELF_EMPLOYED',
};

// @ts-expect-error ts-migrate(2430) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
interface IEmployeeInformationTabProps extends React.HTMLAttributes<Element> {
  employmentStatus?: any;
  id?: number;
  name?: string;
  phone?: string;
  emailAddress?: string;
  emailDisabled?: boolean;
  takesAppointments?: boolean;
  role?: any;
  submitting?: boolean;
  serverErrorName?: string;
  serverErrorData?: {
    name?: string;
  };
  serverErrorAction?: string;
  actions: {
    setEmployeeDataAction: (...args: any[]) => any;
  };
  setEmployeeDataAction?: any;
}

interface State {
  isNameChangeNoticeVisible: boolean;
}

export class EmployeeInformationTab extends Component<IEmployeeInformationTabProps, State> {
  static defaultProps = {
    employmentStatus: undefined,
    id: null,
    name: '',
    phone: '',
    emailAddress: null,
    emailDisabled: false,
    takesAppointments: false,
    role: null,
    submitting: false,
    serverErrorName: null,
    serverErrorData: null,
    serverErrorAction: null,
  };

  constructor(props) {
    super(props);
    this.state = { isNameChangeNoticeVisible: false };
  }

  onContactDetailsChange = (event) => {
    const { name, value } = event.target;

    if (this.isNew()) {
      this.props.actions.setEmployeeDataAction({
        [name]: value,
      });
      return;
    }

    this.setState({ isNameChangeNoticeVisible: true }, () => {
      this.props.actions.setEmployeeDataAction({
        [name]: value,
      });
    });
  };

  onChange = (event) => {
    this.props.actions.setEmployeeDataAction({
      [event.target.name]: event.target.value,
    });
  };

  onTakesAppointmentsChange = (checked) => {
    this.props.actions.setEmployeeDataAction({
      takesAppointments: checked,
    });
  };

  getValidations = (inputName) => {
    const { submitting, serverErrorName, serverErrorData } = this.props;
    const validations = {};
    const inputError = employeeErrors[inputName][serverErrorName];
    if (!submitting && serverErrorName && inputError) {
      validations[serverErrorName] = () =>
        Wahanda.Template.render(inputError, {
          name: serverErrorData ? serverErrorData.name : null,
        });
    }
    return validations;
  };

  isNew = () => this.props.id == null;

  render() {
    const {
      name,
      takesAppointments,
      phone,
      emailAddress,
      role,
      emailDisabled,
      serverErrorName,
      serverErrorAction,
      employmentStatus,
    } = this.props;
    const deleteValidations = serverErrorAction === 'delete' ? this.getValidations('delete') : {};
    const nameValidations = this.getValidations('name');
    const emailValidations = this.getValidations('email');
    const takesAppointmentsValidations =
      serverErrorAction === 'submit' ? this.getValidations('takesAppointments') : {};
    return (
      <div>
        <DialogContentBox separated>
          <div data-hj-suppress className={style.basicInfoContainer}>
            {!this.isNew() && (
              <div className={style.photoContainer}>
                <EmployeeProfilePhoto />
              </div>
            )}
            <div className={style.inputsContainer}>
              <div className={style.inputGroupRow}>
                <div className={style.inputGroupRowItem}>
                  <UnderlinedInput
                    label={lang.name}
                    name="name"
                    value={name}
                    onChange={this.onContactDetailsChange}
                    validations={{ ...nameValidations, ...deleteValidations }}
                    validationErrors={{
                      isDefaultRequiredValue: Wahanda.lang.validate.defaults.required,
                    }}
                    maxLength={100}
                    autoFocus
                    required
                  />
                </div>
              </div>
              <div className={style.inputGroupRow}>
                <div className={style.inputGroupRowItem}>
                  <UnderlinedInput
                    label={lang.email}
                    name="emailAddress"
                    value={emailAddress}
                    onChange={this.onContactDetailsChange}
                    validations={{
                      isEmail: true,
                      ...emailValidations,
                    }}
                    validationErrors={{
                      isEmail: Wahanda.lang.validate.defaults.email,
                      isDefaultRequiredValue: Wahanda.lang.validate.defaults.required,
                    }}
                    disabled={emailDisabled}
                  />
                </div>
                <div className={style.inputGroupRowItem}>
                  <UnderlinedInput
                    label={lang.phone}
                    name="phone"
                    value={phone}
                    onChange={this.onContactDetailsChange}
                    validations={{
                      [phoneValidator.KEY]: phoneValidator.VALIDATOR,
                    }}
                  />
                </div>
              </div>
              <div className={style.inputGroupRow}>
                <div className={style.inputGroupRowItem}>
                  <Checkbox
                    name="takes-appointments"
                    label={lang.takesAppointments}
                    checked={takesAppointments}
                    disabled={role === ROLES.TAX_AUDITOR}
                    onChange={this.onTakesAppointmentsChange}
                  />
                  {serverErrorName && takesAppointmentsValidations[serverErrorName] && (
                    <InputError message={takesAppointmentsValidations[serverErrorName]()} />
                  )}
                </div>
              </div>
              {this.state.isNameChangeNoticeVisible && (
                <div className={style.inputGroupRow}>
                  <Notice
                    message={Wahanda.lang.settings.employees.employee.warnings.updatingPersonInfo}
                  />
                </div>
              )}
            </div>
          </div>
        </DialogContentBox>
        {takesAppointments && (
          <DialogContentBox separated>
            <div className={style.employmentStatusContainer}>
              <div className={style.header}>{lang.employmentStatus.header}</div>
              <div className={style.subHeader}>{lang.employmentStatus.subHeader}</div>
              <div className={style.employeeTypes}>
                <div className={style.employeeTypeSelect}>
                  <Radio
                    name="employmentStatus"
                    label={lang.employmentStatus.fullTime}
                    value={EMPLOYMENT_STATUS.EMPLOYED}
                    checked={employmentStatus === EMPLOYMENT_STATUS.EMPLOYED}
                    onChange={this.onChange}
                  />
                </div>
                <Radio
                  name="employmentStatus"
                  label={lang.employmentStatus.selfEmployed}
                  value={EMPLOYMENT_STATUS.SELF_EMPLOYED}
                  checked={employmentStatus === EMPLOYMENT_STATUS.SELF_EMPLOYED}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </DialogContentBox>
        )}

        <DialogContentBox separated>
          <EmployeePermissions />
        </DialogContentBox>
      </div>
    );
  }
}
