import {
  VENUE_DETAILS_RECEIVE,
  VENUE_DETAILS_FAILURE,
  INVITE_TO_DOWNLOAD_APP_BY_SMS_REQUEST,
  INVITE_TO_DOWNLOAD_APP_BY_SMS_SUCCESS,
  INVITE_TO_DOWNLOAD_APP_BY_SMS_FAILURE,
  APP_ADVOCACY_BANNER_HIDE_SUCCESS_OVERLAY,
  CLEAR_APP_ADVOCACY_BANNER,
  INITIAL_STATE_INLINE_CLIENT_CREATION,
} from 'reduxStore/actionsConst';

import { UDVAppAdvocacyBannerState, UDVAppAdvocacyBannerActionTypes } from './types';

export const initialState: UDVAppAdvocacyBannerState = {
  isLoading: true,
  isSendingInvite: false,
  isShowingSuccessOverlay: false,
  venueDetails: {},
};

export default function UDVAppAdvocacyBanner(
  state = initialState,
  action: UDVAppAdvocacyBannerActionTypes,
): UDVAppAdvocacyBannerState {
  switch (action.type) {
    case VENUE_DETAILS_RECEIVE:
      return {
        ...state,
        isLoading: false,
        venueDetails: {
          ...action.data,
        },
      };
    case VENUE_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case INVITE_TO_DOWNLOAD_APP_BY_SMS_REQUEST:
      return {
        ...state,
        isSendingInvite: true,
      };
    case INVITE_TO_DOWNLOAD_APP_BY_SMS_SUCCESS:
      return {
        ...state,
        isSendingInvite: false,
        isShowingSuccessOverlay: true,
      };
    case INVITE_TO_DOWNLOAD_APP_BY_SMS_FAILURE:
      return {
        ...state,
        isSendingInvite: false,
      };
    case APP_ADVOCACY_BANNER_HIDE_SUCCESS_OVERLAY:
      return {
        ...state,
        isShowingSuccessOverlay: false,
      };
    case INITIAL_STATE_INLINE_CLIENT_CREATION:
      return {
        ...state,
        bookingActor: action.data.bookingActor,
        appointmentDateTime: action.data.appointmentDateTime,
      };
    case CLEAR_APP_ADVOCACY_BANNER:
      return initialState;
    default:
      return state;
  }
}
