import React from 'react';
import { curry } from 'mori';
import ReactDialog from 'src/components/common/react-dialog';
import { DialogFooter, DialogFooterButtonProps } from 'components/common/DialogFooter';
import { Caution } from 'components/common/Icon';
import { colors } from 'style/exports/colors';
import style from './DialogConfirmation.scss';

interface Props {
  dataTest?: string;
  title: string;
  buttonTitle?: string;
  buttonColor?: string;
  onButtonClick?: () => void;
  onClose?: () => void;
  icon?: string;
  text: string | object;
}

export const DialogConfirmation = ({
  dataTest,
  title,
  buttonTitle,
  buttonColor = 'default',
  onButtonClick,
  onClose,
  icon = 'red',
  text,
}: Props) => {
  const buttonClick = onButtonClick ? curry(onButtonClick, onClose) : onClose;
  const type = buttonColor === 'red' ? 'alert' : 'default';
  const Icon = () => {
    let color;
    switch (icon) {
      case 'orange':
        color = colors.colorYellow;
        break;
      default:
        color = colors.colorCoralDark;
        break;
    }
    return (
      <span className={style.icon} style={{ color }}>
        <Caution />
      </span>
    );
  };
  const buttons: DialogFooterButtonProps[] = [
    {
      dataTest: 'dialog-confirmation-action',
      title: buttonTitle || '',
      type,
      onClick: buttonClick,
    },
  ];
  return (
    <ReactDialog
      dataTest={dataTest}
      title={title}
      width={480}
      onClose={onClose}
      noContentTopBorders
    >
      <div className={style.content}>
        <Icon /> {text}
      </div>
      {buttonTitle ? <DialogFooter buttons={buttons} fullWidth /> : null}
    </ReactDialog>
  );
};
