import React from 'react';
import pathToRegexp from 'path-to-regexp';
import BigNumber from 'bignumber.js';
import Loader from 'components/common/Loader';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';
import _ from 'common/underscore';
import { VATSalesReportAnalytics } from 'common/analytics';
import DISPLAY from 'components/reports/newSalesReports/displayStateEnum';
import { PrintButton } from 'components/shared/PrintButton';
import Filters from '../../newSalesReports/common/Filters';
import { allEmployeesOptionValue } from '../../newSalesReports/EmployeeFilter';
import CsvDownloadLink from './csvDownloadLink';
import ReportTable from './reportTable';
import ReportLayout from '../../newSalesReports/common/ReportLayout';
import { POS_REPORTS_ROUTES } from '../../newSalesReports/SalesReportContainer/reportRoutes';
import POSReportSelector from '../filters/POSReportSelector/withRouter';
import style from './style.scss';

interface IVatReportProps extends React.HTMLAttributes<Element> {
  requestVatReportDataAction: (...args: any[]) => any;
  isPosFull: boolean;
  match: {
    params: {
      venueId: string;
      fromDate?: string;
      toDate?: string;
      selectedEmployeeId?: string;
    };
  };
  history: {
    push: (...args: any[]) => any;
  };
  reportData?: {
    serviceRows: {
      vatRate?: number;
      netTotal?: number;
      vat?: number;
    }[];
    productRows: {
      vatRate?: number;
      netTotal?: number;
      vat?: number;
    }[];
    voucherRows: {
      vatRate?: number;
      netTotal?: number;
      vat?: number;
    }[];
    total: {
      netTotal?: number;
      vat?: number;
    };
  };
  displayState: any;
  params?: any;
  push?: any;
}

export default class VatReport extends React.Component<IVatReportProps, {}> {
  static defaultProps = {
    reportData: null,
  };

  componentDidMount = () => {
    VATSalesReportAnalytics.trackPageView();
    this.requestDataFromApi();
  };

  componentDidUpdate = (prevProps) => {
    const { params } = this.props.match;
    if (!_.isEqual(params, prevProps.match.params)) {
      this.requestDataFromApi();
    }
  };

  onFilterChange = (filters) => {
    const { venueId } = this.getQueryParams();
    const { fromDate, toDate, selectedEmployeeId } = filters;
    const toPath = pathToRegexp.compile(POS_REPORTS_ROUTES.VAT_REPORT);
    const newPath = toPath({
      venueId,
      fromDate: fromDate.formatApiDateString(),
      toDate: toDate.formatApiDateString(),
      selectedEmployeeId,
    });
    this.props.history.push(newPath);
  };

  getLoadingSpinner = () => <Loader />;

  getErrorMessage = () => <div>{Wahanda.lang.reports.sales.error}</div>;

  getQueryParams = () => {
    const { venueId, fromDate, toDate, selectedEmployeeId } = _.defaults(this.props.match.params, {
      fromDate: moment().startOf('month').formatApiDateString(),
      toDate: moment().endOf('month').formatApiDateString(),
      selectedEmployeeId: allEmployeesOptionValue,
    });
    return {
      venueId,
      fromDate: moment(fromDate),
      toDate: moment(toDate),
      selectedEmployeeId: new BigNumber(selectedEmployeeId).toNumber(),
    };
  };

  trackDateFilterChange = (data) => {
    VATSalesReportAnalytics.trackReportDateFilterChange(data.type);
  };

  requestDataFromApi = () => {
    const { venueId, fromDate, toDate, selectedEmployeeId } = this.getQueryParams();
    this.props.requestVatReportDataAction(
      venueId,
      fromDate,
      toDate,
      selectedEmployeeId === allEmployeesOptionValue ? null : selectedEmployeeId,
    );
  };

  render() {
    let body;
    switch (this.props.displayState) {
      case DISPLAY.WAITING:
        body = this.getLoadingSpinner();
        break;
      case DISPLAY.REPORT:
        body = (
          <ReportTable
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            serviceRows={this.props.reportData.serviceRows}
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            productRows={this.props.reportData.productRows}
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            voucherRows={this.props.reportData.voucherRows}
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            total={this.props.reportData.total}
          />
        );
        break;
      case DISPLAY.ERROR:
        body = this.getErrorMessage();
        break;
      default:
        throw new Error(`Unrecognised display state: ${this.props.displayState}`);
    }
    const dateFilterProps = {
      uniqueId: 'vatReportDateFilter',
      fromDate: this.getQueryParams().fromDate,
      toDate: this.getQueryParams().toDate,
    };
    const employeeFilterProps = this.props.isPosFull
      ? { selectedEmployeeId: this.getQueryParams().selectedEmployeeId }
      : null;
    const filters = (
      <Filters
        onDatePickerChange={this.trackDateFilterChange}
        date={dateFilterProps}
        // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type '{ selectedE... Remove this comment to see the full error message
        employee={employeeFilterProps}
        onFilterChange={this.onFilterChange}
        onEmployeeChange={VATSalesReportAnalytics.trackEmployeeFilterChange}
      />
    );
    return (
      <div>
        <div className={style.row}>
          <div className={style.reportName}>
            <POSReportSelector />
          </div>
          <div className={style.actionButtons}>
            <PrintButton />
            <CsvDownloadLink
              fromDate={this.getQueryParams().fromDate}
              toDate={this.getQueryParams().toDate}
              selectedEmployeeId={this.getQueryParams().selectedEmployeeId}
            />
          </div>
        </div>
        <ReportLayout
          filter={filters}
          showTimeStamp={this.props.displayState === DISPLAY.REPORT}
          body={body}
        />
      </div>
    );
  }
}
