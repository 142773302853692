import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { LISTEN_CALENDAR } from 'common/websockets/commands';
import { send as wsSend, listen, onStateChange } from 'common/websockets';
import _ from 'common/underscore';

import { debounceInterval } from './config';

const EVENTS = [
  'AppointmentCancelled',
  'AppointmentConfirmed',
  'AppointmentCreated',
  'AppointmentRejected',
];

const requestListenForCalendarEvents = () => wsSend(LISTEN_CALENDAR, { venueId: App.getVenueId() });

export default function setup(update) {
  let isConnected = false;
  // We want to prevent calling the update function too often
  const updateDebounced = _.debounce(update, debounceInterval);
  const handleMessage = (eventData) => {
    const { event } = eventData;

    if (EVENTS.includes(event)) {
      updateDebounced();
    }
  };

  const onConnect = () => {
    requestListenForCalendarEvents();
    // Also listen for appointment notifications
    listen('appointment-event', handleMessage);
    isConnected = true;

    // Refetch the data on connect, as we can't be sure what changed when WS was offline.
    update();
  };

  // Bind to WebSockets connection events
  onStateChange(onConnect, () => {
    isConnected = false;
  });

  // On venue change, request to listen for other venue's data
  App.on(Wahanda.Event.APP_VENUE_CHANGED, () => {
    if (isConnected) {
      requestListenForCalendarEvents();
    }
  });
}
