export const EMAIL_CAMPAIGN_CHANNEL_FEATURE = 'marketing-campaign';

export const PROMOTIONAL_OFFERS_CHANNEL_FEATURE = 'promo-offers-cmpgn';

export const CLIENT_APP_ENABLED_CHANNEL_FEATURE = 'tw-app-sharing-link';

export const CLIENT_APP_CAMPAIGN_CHANNEL_FEATURE = 'app-for-clnts-cmpgn';

export const VENUE_OPENING_CAMPAIGN = 'venue-opening-cmpgn';

export const COVID_CONSENT_FORMS = 'covid-consent-forms';
