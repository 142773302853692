App.Views.Forms.MenuOffer2.Pricing.Dated.List.Simple = BackboneEx.View.Menu.PricingListBase.extend({
  initialize: function () {
    var html = Wahanda.Template.renderTemplate('menu-offer-dated-pricing-list-simple-item', {
      editable: Wahanda.Permissions.editMenu(),
    });
    this.setElement(html);

    this.discountPriceAmount = this.options.discountPriceAmount;
    this.fullPriceAmount = this.options.fullPriceAmount;
    this.applicableDays = this.options.applicableDays;
    this.pricingRuleId = this.options.pricingRuleId ? this.options.pricingRuleId : null;
  },

  render: function () {
    this.$('.b-weekdays').html(this.getDaysHtml());
    this.$('.v-price').html(this.getPriceHtml());
  },

  getPriceHtml: function () {
    return Wahanda.Template.renderTemplate('sku-price-template', {
      fullPrice: Wahanda.Currency.getFormattedOrEmpty(this.fullPriceAmount),
      discountPrice: Wahanda.Currency.getFormattedOrEmpty(this.discountPriceAmount),
    });
  },

  getValues: function () {
    return {
      discountPriceAmount: this.discountPriceAmount,
      fullPriceAmount: this.fullPriceAmount,
      applicableDays: this.applicableDays,
      pricingRuleId: this.pricingRuleId,
    };
  },
});
