import React from 'react';
import classnames from 'classnames';
import style from './DialogFooter.scss';
import { DialogFooterButton } from './DialogFooterButton';
import { DialogFooterButtonProps } from './DialogFooterButton/DialogFooterButton';

interface Props {
  buttons?: DialogFooterButtonProps[];
  children?: React.ReactNode;
  fullWidth?: boolean;
  showShadow?: boolean;
}

export const DialogFooter = ({
  buttons = [],
  children = [],
  fullWidth = false,
  showShadow = false,
}: Props) => {
  const renderButtons = () => {
    return buttons.map((button) => {
      const { dataTest, title, type, onClick, disabled, isLoading } = button;
      return (
        <DialogFooterButton
          dataTest={dataTest}
          key={`${title}-${type}`}
          title={title}
          type={type}
          onClick={onClick}
          disabled={disabled}
          isLoading={isLoading}
        />
      );
    });
  };
  return (
    <div
      className={classnames(style.container, {
        [style.fullWidth]: fullWidth,
        [style.shadow]: showShadow,
      })}
    >
      {buttons.length > 0 ? renderButtons() : children}
    </div>
  );
};
