/* global BackboneEx */
App.Views.Dialog.ValidationError = BackboneEx.View.Dialog2.extend(
  {
    events: {
      'click .js-close': 'close',
    },
    options: {
      dialogClass: 'dialog2--white with-text-red-exclamation-icon with-correct-padding',
      width: 560,
      dataTest: 'validation-error-modal',
    },
    buttons: {
      OK: {
        title: Wahanda.lang.shared.ok,
        classes: 'dialog2--button-right dialog2--button-destructive js-close',
      },
    },
    showHeader: false,

    getDialogRenderedTemplate() {
      return $('<div>', { class: 'text' })
        .append($('<h2>').html(this.options.heading))
        .append($('<p>').html(this.options.text));
    },

    render() {
      BackboneEx.View.Dialog2.prototype.render.call(this);

      // Remove the annoying min-height from the content
      this.onDialogEvent(
        'open',
        function () {
          this.$dialogContent.css('min-height', '');
        }.bind(this),
      );
    },
  },
  {
    open(heading, text) {
      new App.Views.Dialog.ValidationError({
        heading,
        text,
      }).open();
    },
  },
);
