import React, { Component, Fragment } from 'react';
import { StateCard } from 'components/common/StateCard';
import Wahanda from 'common/wahanda';
import Loader from 'components/common/Loader';
import { DialogCloseWithChanges } from 'components/common/dialog/DialogCloseWithChanges';

const lang = Wahanda.lang.menu.products.noProducts;
interface INoProductsStateProps extends React.HTMLAttributes<Element> {
  venueId: number;
  hasUnsavedChanges?: boolean;
  onClick?: (...args: any[]) => any;
}
type NoProductsStateState = {
  confirmCloseDialogVisible?: boolean;
  loading?: boolean;
};

export default class NoProductsState extends Component<
  INoProductsStateProps,
  NoProductsStateState
> {
  static defaultProps = {
    hasUnsavedChanges: false,
    onClick: null,
  };

  state = {
    loading: false,
    confirmCloseDialogVisible: false,
  };

  onRedirectButtonClick = () => {
    if (this.props.hasUnsavedChanges) {
      this.setState({ confirmCloseDialogVisible: true });
    } else {
      this.redirectToProductsPage();
    }
  };

  redirectToProductsPage = () => {
    const { onClick } = this.props;
    this.setState({
      loading: true,
      confirmCloseDialogVisible: false,
    });
    if (onClick) {
      onClick();
    }
    window.location.hash = `venue/${this.props.venueId}/products`;
  };

  renderConfirmCloseDialog() {
    return (
      <DialogCloseWithChanges
        onConfirm={this.redirectToProductsPage}
        onClose={() => {
          this.setState({
            confirmCloseDialogVisible: false,
          });
        }}
      />
    );
  }

  render() {
    const { confirmCloseDialogVisible } = this.state;
    return (
      <Fragment>
        {!this.state.loading && (
          <StateCard
            heading={lang.heading}
            message={lang.message}
            button={{
              text: lang.button,
              onClick: this.onRedirectButtonClick,
            }}
          />
        )}
        {this.state.loading && <Loader />}
        {confirmCloseDialogVisible && this.renderConfirmCloseDialog()}
      </Fragment>
    );
  }
}
