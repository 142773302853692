import React from 'react';
import classnames from 'classnames';
import style from './style.scss';

type VenueInfoActionButtonProps = {
  text: string;
  classes: string;
  onClick: (...args: any[]) => any;
};
const VenueInfoActionButton: React.SFC<VenueInfoActionButtonProps> = ({
  text,
  classes,
  onClick,
}) => {
  const myClasses = classnames(style.action, classes);
  return (
    <div className={myClasses} onClick={onClick}>
      <div className={style.actionWrapper}>{text}</div>
    </div>
  );
};

export default VenueInfoActionButton;
