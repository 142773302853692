import { connect } from 'react-redux';

import { StatusMessage as Content } from './StatusMessage';

const mapStateToProps = ({ clients }) => ({
  importCount: clients.clientsImport.importStatus.importCount,
  status: clients.clientsImport.importStatus.status,
});

export const StatusMessage = connect(mapStateToProps)(Content);
