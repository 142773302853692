import React from 'react';
import get from 'lodash/get';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import classnames from 'classnames';

import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';
import { Avatar } from 'components/common/Avatar';
import { PopoverAnchorOffset, PopoverPlacement } from 'components/common/Popover';
import Loader from 'components/common/Loader';

import { LayoutHeader } from 'components/common/Layout';
import { StateCard } from 'components/common/StateCard';
import { TeamSetup } from 'components/common/IconPicture';

import { ShiftsAnalytics } from './tracking';
import {
  EmployeeShiftsArray,
  RequestEmployeesWorkingHoursPayload,
  VenueShiftsObject,
  DateRangeObject,
  DateDurationArgs,
  ShiftModalArgs,
  RotaModalType,
} from './utils/types';
import { rotaPermissions } from './utils/permissions';
import { DAYS_PER_WEEK, SCHEDULE_ROW_TYPE } from './utils/constants';
import { countHoursPerWeek, getWorkingHoursWithRange } from './utils/helpers';

import { ShiftsDatePicker } from './ShiftsDatePicker';
import { ShiftRow } from './ShiftRow';
import { ShiftEmployeeModal } from './ShiftEmployeeModal';
import { ShiftVenueModal } from './ShiftVenueModal';
import { ShiftEmployeeMonth } from './ShiftEmployeeMonth';

import style from './ShiftsContainer.scss';

const LANG = Wahanda.lang.settings.shifts;

interface Props {
  employeeId: number;
  venueId: number;
  onEmptyRotaClick?: () => void;
  // redux
  actions: {
    getVenueBusinessHours: () => void;
    getVenueWorkingHours: (payload: DateRangeObject) => void;
    getEmployeesWorkingHours: (payload: RequestEmployeesWorkingHoursPayload) => void;
  };
  datePickerRange: DateRangeObject;
  canManageVenueDetails: boolean;
  isEmployeesLoaded: boolean;
  isLoading: boolean;
  venue: VenueShiftsObject;
  employees: EmployeeShiftsArray;
}

interface State extends DateDurationArgs {
  popoverPlacement: PopoverPlacement;
  editModalData: ShiftModalArgs;
  employeeId: number;
  isEmployeeEditModalVisible: boolean;
  isEmployeeMonthVisible: boolean;
  isVenueEditModalVisible: boolean;
  popoverOffset: PopoverAnchorOffset;
}

export class ShiftsContainer extends React.Component<Props, State> {
  private grid: any;

  private monthGrid: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      popoverPlacement: PopoverPlacement.rightTop,
      dateFrom: moment().startOf('isoWeek'),
      dateTo: moment().endOf('isoWeek'),
      isEmployeeEditModalVisible: false,
      isEmployeeMonthVisible: false,
      isVenueEditModalVisible: false,
      employeeId: 0,
      editModalData: {
        date: '',
        image: null,
        title: '',
        type: undefined,
        timeSlots: [
          {
            timeFrom: '',
            timeTo: '',
          },
        ],
      },
      popoverOffset: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
    };
  }

  public componentDidMount() {
    const { venueId } = this.props;
    const { dateFrom, dateTo } = this.state;

    if (venueId) {
      this.requestWorkingHoursActions({ dateFrom, dateTo });
      this.props.actions.getVenueBusinessHours();
    }

    NavigationAnalytics.trackPageEventView(L2Page.ROTA);
  }

  private requestWorkingHoursActions = ({ dateFrom, dateTo }: DateDurationArgs) => {
    const dates = {
      dateFrom: dateFrom.formatApiDateString(),
      dateTo: dateTo.formatApiDateString(),
    };
    // Load single employee id to saga if employee doesn't have permissions
    // to view any calendar
    const employeeId = Wahanda.Permissions.viewAnyCalendar() ? null : this.props.employeeId;

    this.props.actions.getVenueWorkingHours(dates);
    this.props.actions.getEmployeesWorkingHours({
      ...dates,
      employeeId,
    });
  };

  private redirectToOpeningHours = () => {
    ShiftsAnalytics.trackRedirectToOpeningHoursClick();
    return true;
  };

  private goToWeek = ({ dateFrom, dateTo }) => {
    this.setState({ dateFrom, dateTo });
    this.requestWorkingHoursActions({ dateFrom, dateTo });
  };

  private slicedWorkingHours = () => {
    const {
      datePickerRange,
      venue: { workingHours },
    } = this.props;
    return getWorkingHoursWithRange({
      datePickerRange,
      workingHours,
      rangeLimit: DAYS_PER_WEEK,
    });
  };

  private generateEmptyCells = () => {
    return Object.keys(Array(DAYS_PER_WEEK).fill('')).map((item) => (
      <div key={item} className={style.gridCol} />
    ));
  };

  private renderWeekdaysCells = (date, index) => {
    const dayOfWeek = moment(date).formatWeekday();
    const day = moment(date).isSame(new Date(), 'day')
      ? Wahanda.lang.date.today
      : moment(date).formatDayMonth();

    return (
      <div className={style.gridCol} key={`${dayOfWeek}-${index}`}>
        <div className={style.weekDay}>{dayOfWeek}</div>
        {day}
      </div>
    );
  };

  private renderWeekDays = () => {
    const hours = this.slicedWorkingHours();
    return (
      <div className={classnames(style.weekRow, style.rowHead)}>
        <div className={style.gridCol} />
        {hours.length
          ? hours.map(({ date }, index) => this.renderWeekdaysCells(date, index))
          : this.generateEmptyCells()}
      </div>
    );
  };

  private hideEditModal = () => {
    this.setState({
      isEmployeeEditModalVisible: false,
      isVenueEditModalVisible: false,
    });
  };

  private onVenueCellClick = (editModalData) => {
    this.setState((state) => ({
      isEmployeeEditModalVisible: false,
      isVenueEditModalVisible: !state.isVenueEditModalVisible,
      editModalData,
    }));

    ShiftsAnalytics.trackVenueCellClick();
  };

  private renderSalonWorkingHours = () => {
    const workingHours = this.slicedWorkingHours();
    const text = this.props.canManageVenueDetails ? (
      <a
        href={Wahanda.Url.getFullUrl('settings', 'venue-settings/opening-hours')}
        className={style.link}
        onClick={this.redirectToOpeningHours}
      >
        {LANG.salonOpeningHours}
      </a>
    ) : (
      LANG.salonOpeningHours
    );

    return (
      <div className={classnames(style.weekRow, style.venueWeekRow)} data-test="shift-venue-row">
        <div className={style.gridCol}>{text}</div>
        {workingHours.length ? (
          <ShiftRow
            workingHours={workingHours}
            type={SCHEDULE_ROW_TYPE.VENUE}
            gridClassName={style.gridCol}
            onClick={this.onVenueCellClick}
          />
        ) : (
          this.generateEmptyCells()
        )}
      </div>
    );
  };

  private onEmployeeCellClick = ({
    // Coming from ShiftRow component
    date,
    type,
    popoverOffset,
    timeSlots,
    // Coming from renderEmployeeWorkingHours function
    employeeId,
    image,
    title,
  }) => {
    const gridRect = this.grid?.getBoundingClientRect() || this.monthGrid?.getBoundingClientRect();
    const threshold = 250;
    const isVertical = gridRect.width > popoverOffset.left + threshold;
    const isHorizontal = gridRect.height < popoverOffset.top + threshold;
    const verticalPosition = isVertical ? 'right' : 'left';
    const horizontalPosition = isHorizontal ? 'Bottom' : 'Top';
    const placement = `${verticalPosition}${horizontalPosition}` as PopoverPlacement;
    const isSameCell =
      this.state.popoverOffset.top === popoverOffset.top &&
      this.state.popoverOffset.left === popoverOffset.left;
    const isEmployeeEditModalVisible = !(isSameCell && this.state.isEmployeeEditModalVisible);

    this.setState({ isEmployeeEditModalVisible: false, isVenueEditModalVisible: false }, () => {
      this.setState({
        popoverPlacement: placement,
        isEmployeeEditModalVisible,
        employeeId,
        popoverOffset,
        editModalData: {
          date,
          title,
          image,
          type,
          timeSlots,
        },
      });
    });

    ShiftsAnalytics.trackEmployeeCellClick();
  };

  private handleEmployeeMonthDisplay = (employeeId?) => {
    const { dateFrom, dateTo } = this.state;

    this.setState(
      (state) => ({
        isEmployeeMonthVisible: !state.isEmployeeMonthVisible,
        employeeId,
      }),
      () => {
        if (!this.state.isEmployeeMonthVisible) {
          this.requestWorkingHoursActions({ dateFrom, dateTo });
          ShiftsAnalytics.trackEmployeeMonthBackClick();
        } else {
          ShiftsAnalytics.trackEmployeeMonthClick();
        }
      },
    );
  };

  private renderEmployeeWorkingHours = () => {
    const { employees, datePickerRange } = this.props;
    return (
      <React.Fragment>
        {employees.employeesWorkingHours.map(
          ({ employeeId, employeeImage, employeeName, workingHours }, index) => {
            const wHours = getWorkingHoursWithRange({
              datePickerRange,
              workingHours,
              rangeLimit: DAYS_PER_WEEK,
            });
            const { canEditEmployeeHours } = rotaPermissions({ employeeId });
            const hoursPerWeek = countHoursPerWeek(wHours);
            const employeeData = {
              title: employeeName,
              employeeId,
              image: employeeImage,
            };

            const onEmployeeClick = () => this.handleEmployeeMonthDisplay(employeeId);

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className={style.weekRow} key={index} data-test={`shift-employee-row-${index}`}>
                <div className={classnames(style.gridCol, style.employeeWrapper)}>
                  <a
                    role="button"
                    tabIndex={0}
                    className={style.employeeImage}
                    onClick={onEmployeeClick}
                  >
                    <Avatar text={employeeName} src={employeeImage} type="medium" />
                  </a>
                  <div className={style.employeeInfo}>
                    <a
                      role="button"
                      tabIndex={0}
                      className={style.employeeName}
                      onClick={onEmployeeClick}
                    >
                      {employeeName}
                    </a>
                    <div className={style.perWeek}>
                      {hoursPerWeek} {LANG.perWeek}
                    </div>
                  </div>
                </div>
                <ShiftRow
                  canEditEmployeeHours={canEditEmployeeHours}
                  workingHours={wHours}
                  type={SCHEDULE_ROW_TYPE.STYLIST}
                  gridClassName={style.gridCol}
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={(data) => this.onEmployeeCellClick({ ...data, ...employeeData })}
                />
              </div>
            );
          },
        )}
      </React.Fragment>
    );
  };

  private renderEmpty = () => {
    return (
      <StateCard
        button={{
          text: LANG.emptyState.button,
          onClick: () => this.props.onEmptyRotaClick && this.props.onEmptyRotaClick(),
        }}
        heading={LANG.emptyState.title}
        image={<TeamSetup />}
        message={LANG.emptyState.message}
      />
    );
  };

  private renderContent = () => {
    const {
      isEmployeeEditModalVisible,
      isVenueEditModalVisible,
      isEmployeeMonthVisible,
    } = this.state;
    const showVenueEditModal = !isEmployeeEditModalVisible && isVenueEditModalVisible;

    if (isEmployeeMonthVisible) {
      return (
        <div
          className={style.wrapper}
          ref={(monthGrid) => {
            this.monthGrid = monthGrid;
          }}
          onScroll={this.hideEditModal}
        >
          <div className={style.layoutWidth}>
            <ShiftEmployeeMonth
              employeeId={this.state.employeeId}
              onClose={this.handleEmployeeMonthDisplay}
              onClick={this.onEmployeeCellClick}
            />
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className={style.layoutWidth}>
          <LayoutHeader header={LANG.title} />
          <ShiftsDatePicker
            goToWeek={this.goToWeek}
            dateFrom={this.state.dateFrom}
            dateTo={this.state.dateTo}
          />
        </div>
        <div
          className={style.wrapper}
          ref={(grid) => {
            this.grid = grid;
          }}
          onScroll={this.hideEditModal}
        >
          <div className={style.weekGrid}>
            {this.renderWeekDays()}
            {this.renderSalonWorkingHours()}
          </div>
          <div className={style.overflowContainer}>
            <div
              className={classnames(style.weekGrid)}
              ref={(grid) => {
                this.grid = grid;
              }}
            >
              {this.renderEmployeeWorkingHours()}
            </div>
          </div>
        </div>
        {showVenueEditModal && (
          <ShiftVenueModal
            data={this.state.editModalData}
            onClose={this.hideEditModal}
            modalType={RotaModalType.VENUE_DAY_EDIT}
          />
        )}
      </React.Fragment>
    );
  };

  public render() {
    const {
      isEmployeeEditModalVisible,
      isVenueEditModalVisible,
      isEmployeeMonthVisible,
    } = this.state;
    const showEmployeeEditModal = isEmployeeEditModalVisible && !isVenueEditModalVisible;

    const employeesWorkingHours = get(this.props, 'employees.employeesWorkingHours');
    const hasEmployeesWorkingHoursLength = employeesWorkingHours && employeesWorkingHours.length;
    if (
      !this.props.venue.workingHours.length ||
      (!this.props.isEmployeesLoaded && !hasEmployeesWorkingHoursLength)
    ) {
      return null;
    }

    const isEmployeeShiftLoaded = this.props.employees && hasEmployeesWorkingHoursLength;
    const content = isEmployeeShiftLoaded ? this.renderContent() : this.renderEmpty();

    const contentClass = classnames(style.content, {
      [style.fullScroll]: isEmployeeMonthVisible,
    });

    return (
      <div data-hj-suppress className={contentClass}>
        {!isEmployeeShiftLoaded || (this.props.isLoading && <Loader positionAbsolute />)}
        {content}

        {showEmployeeEditModal && (
          <ShiftEmployeeModal
            employeeId={this.state.employeeId}
            date={this.state.editModalData.date}
            onClose={this.hideEditModal}
            popoverOffset={this.state.popoverOffset}
            popoverPlacement={this.state.popoverPlacement}
          />
        )}
      </div>
    );
  }
}
