import {
  GET_PRODUCTS_LIST_REQUEST,
  GET_PRODUCTS_LIST_RECEIVE,
  GET_PRODUCTS_LIST_RESET,
  GET_PRODUCTS_LIST_FAILURE,
} from 'reduxStore/actionsConst';

export const requestProductsListAction = () => ({
  type: GET_PRODUCTS_LIST_REQUEST,
});

export const receiveProductsListAction = (data) => ({
  type: GET_PRODUCTS_LIST_RECEIVE,
  data,
});

export const resetProductsListAction = () => ({
  type: GET_PRODUCTS_LIST_RESET,
});

export const requestProductsListFailureAction = (ex) => ({
  type: GET_PRODUCTS_LIST_FAILURE,
  ex,
});
