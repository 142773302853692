import React from 'react';

interface Props {
  className?: string;
}

class OrdersList extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-orders-list', this.props.className].join(' ')}
        viewBox="0 0 331 339"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#00B0B9"
            d="M100.153846,127.076923 L100.152134,107.854716 C100.152134,103.607041 96.7092135,100.163354 92.4615385,100.162409 L69.3846154,100.153846 C65.1369404,100.1529 61.6940204,96.7092138 61.6940204,92.4615385 L61.6923077,60.3353373 L61.6923077,23.2307692 C61.6923077,14.7340808 54.8043808,7.84615385 46.3076923,7.84615385 L46.3076923,91.3692504 L27.8578308,91.3692504 C18.7991231,91.3692504 11.4545385,94.8466088 11.4545385,103.881313 L11.4545385,129.478011"
            fillRule="nonzero"
          />
          <path
            fill="#FFB500"
            d="M3.9994308,263.014423 C39.6708837,268.316657 70.1277396,258.901593 95.3699988,234.769231 C95.3699988,234.769231 95.3699988,251.369541 95.3699988,284.570162 L81.8887365,296.108624 L3.9994308,296.108624 L3.9994308,263.014423 Z"
            transform="matrix(-1 0 0 1 99.37 0)"
            fillRule="nonzero"
          />
          <path
            fill="#FFF"
            d="M69.3846154,150.153846 C61.6923077,156.667393 57.8461538,173.493064 57.8461538,200.630859 C57.8461538,222.659219 48.9894831,246.60943 48.9894831,264.673969 C48.9894831,267.467326 51.9417069,270.24948 57.8461538,273.020433 L69.1479869,269.482272 L69.1479869,256.696815 L63.1421273,250.512545 L69.3846154,242.559195 L69.3846154,211.789964 L69.3846154,150.153846 Z"
            fillRule="nonzero"
          />
          <path
            stroke="#001E62"
            d="M46.3076923,142.461538 L46.3076923,234.806248 C46.3076923,239.722184 48.1903042,244.451472 51.5687638,248.022527 L54,250.59236 L52.0763931,252.406308 C48.5136665,255.765935 47.0430846,260.787723 48.2307692,265.538462 L48.2307692,265.538462 C49.3609746,270.059283 53.4229388,273.230769 58.0828954,273.230769 L61.4555662,273.230769 C66.1155227,273.230769 70.1774869,270.059283 71.3076923,265.538462 L71.3076923,265.538462 C72.4953769,260.787723 71.024795,255.765935 67.4620685,252.406308 L65.5384615,250.59236 L67.9696977,248.022527 C71.3481573,244.451472 73.2307692,239.722184 73.2307692,234.806248 L73.2307692,130.923077 L96.3076923,130.923077 L96.3076923,296.307692 L19.3846154,296.307692 C10.8879269,296.307692 4,289.419765 4,280.923077 L4,146.307692 C4,137.811004 10.8879269,130.923077 19.3846154,130.923077 L84.7692308,130.923077"
            strokeWidth="7.692"
            strokeLinejoin="round"
          />
          <path
            stroke="#001E62"
            d="M73.2307692,96.3076923 L73.2307692,17.4615385 C73.2307692,10.0269361 67.2038331,4 59.7692308,4 L59.7692308,4 C52.3346285,4 46.3076923,10.0269361 46.3076923,17.4615385 L46.3076923,79.4940004 M42.4615385,92.4615385 L19.3846154,92.4615385 C13.012099,92.4615385 7.84615385,97.6274838 7.84615385,104 L7.84615385,138.615385"
            strokeWidth="7.692"
          />
          <g transform="translate(73.23 92.462)">
            <path
              stroke="#001E62"
              d="M84.6000484,203.846154 L135.76163,203.846154 C141.07206,203.846154 145.377015,208.151108 145.377015,213.461538 L145.377015,213.461538 C145.377015,218.771969 141.07206,223.076923 135.76163,223.076923 L114.059288,223.076923 C108.748858,223.076923 104.443904,227.381877 104.443904,232.692308 L104.443904,232.692308 C104.443904,238.002738 108.748858,242.307692 114.059288,242.307692 L157.692616,242.307692 C164.065132,242.307692 169.231077,237.141747 169.231077,230.769231 L169.231077,15.3846154 L0.000308,15.3846154 L0.000308,9.61538462 C0.000308,4.30495435 4.30526235,0 9.61569262,0 L9.61569262,0 C14.9261229,0 19.2310772,4.30495435 19.2310772,9.61538462 L19.2310772,21.1538462 C19.2310772,26.4642764 23.5360316,30.7692308 28.8464618,30.7692308 L28.8464618,30.7692308 C34.1568921,30.7692308 38.4618465,26.4642764 38.4618465,21.1538462 L38.4618465,9.61538462 C38.4618465,4.30495435 42.7668007,0 48.0772311,0 L48.0772311,0 C53.3876615,0 57.6926157,4.30495435 57.6926157,9.61538462 L57.6926157,21.1538462 C57.6926157,26.4642764 61.9975699,30.7692308 67.3080003,30.7692308 L67.3080003,30.7692308 C72.6184307,30.7692308 76.9233849,26.4642764 76.9233849,21.1538462 L76.9233849,9.61538462 C76.9233849,4.30495435 81.2283392,0 86.5387695,0 L86.5387695,0 C91.8491999,0 96.1541542,4.30495435 96.1541542,9.61538462 L96.1541542,21.1538462 C96.1541542,26.4642764 100.459108,30.7692308 105.769539,30.7692308 L105.769539,30.7692308 C111.079969,30.7692308 115.384923,26.4642764 115.384923,21.1538462 L115.384923,9.61538462 C115.384923,4.30495435 119.689878,0 125.000308,0 L125.000308,0 C130.310738,0 134.615693,4.30495435 134.615693,9.61538462 L134.615693,21.1538462 C134.615693,26.4642764 138.920647,30.7692308 144.231077,30.7692308 L144.231077,30.7692308 C149.541508,30.7692308 153.846462,26.4642764 153.846462,21.1538462 L153.846462,11.5384615 C153.846462,5.16594519 159.012407,0 165.384923,0 L173.077231,0 L192.308,0"
              transform="matrix(-1 0 0 1 192.308 0)"
              strokeWidth="7.692"
            />
            <g transform="scale(-1 1) rotate(-40 -74.449 385.38)">
              <path
                fill="#FFB500"
                d="M0.365822875,138.573446 L0.365822875,192.306379 L8.05813056,184.614071 L18.9280224,184.614071 L26.6203301,192.306379 L34.3126378,184.614071 L45.4360603,184.614071 L53.1283679,192.306379 L53.1283679,69.229456 L19.5965921,69.229456 C8.97573152,69.229456 0.365822875,77.8393649 0.365822875,88.4602253 L0.365822875,138.573446 Z"
                fillRule="nonzero"
              />
              <path
                stroke="#001E62"
                d="M53.8495218,26.9217637 C53.8495218,12.0525589 41.7956495,-0.00131320341 26.9264449,-0.00131320341 C12.0572401,-0.00131320341 0.00336794776,12.0525589 0.00336794776,26.9217637 L0.00336794776,34.6140714 C0.00336794776,36.7382435 1.72534968,38.4602253 3.84952179,38.4602253 L48.080291,38.4602253 C51.2665491,38.4602253 53.8495218,41.043198 53.8495218,44.229456 L53.8495218,44.229456 C53.8495218,47.4157141 51.2665491,49.9986868 48.080291,49.9986868 L5.77259872,49.9986868 C2.58634054,49.9986868 0.00336794776,52.5816595 0.00336794776,55.7679176 L0.00336794776,55.7679176 C0.00336794776,58.9541756 2.58634054,61.5371483 5.77259872,61.5371483 L50.0033679,61.5371483 C52.1275399,61.5371483 53.8495218,63.2591303 53.8495218,65.3833022 C53.8495218,110.255097 53.8495218,155.126892 53.8495218,199.998687 C53.8495218,210.255097 44.8751629,221.793559 26.9264449,234.614071 C8.97772691,221.793559 0.00336794776,210.255097 0.00336794776,199.998687 C0.00336794776,189.742276 0.00336794776,146.987619 0.00336794776,71.7347145"
                strokeWidth="7.692"
              />
              <path
                stroke="#001E62"
                d="M53.8495218,198.07561 C53.8495218,190.641008 47.8225856,184.614071 40.3879833,184.614071 C32.9533809,184.614071 26.9264449,190.641008 26.9264449,198.07561 C26.9264449,190.641008 20.8995088,184.614071 13.4649064,184.614071 C6.03030402,184.614071 0.00336794776,190.641008 0.00336794776,198.07561"
                strokeWidth="7.692"
                strokeLinejoin="round"
              />
              <polygon
                fill="#00B0B9"
                points="11.542 219.229 42.311 219.229 34.619 230.768 19.234 230.768"
                fillRule="nonzero"
              />
              <polygon
                fill="#001E62"
                points="3.85 211.537 50.003 211.537 46.157 219.229 7.696 219.229"
                fillRule="nonzero"
              />
              <rect
                width="3.846"
                height="96.154"
                x="32.696"
                y="74.999"
                fill="#D8D8D8"
                stroke="#001E62"
                fillRule="nonzero"
                strokeWidth="3.846"
              />
              <rect
                width="3.846"
                height="96.154"
                x="17.311"
                y="74.999"
                fill="#D8D8D8"
                stroke="#001E62"
                fillRule="nonzero"
                strokeWidth="3.846"
              />
            </g>
            <polygon
              fill="#001E62"
              points="88.462 57.692 180.769 57.692 176.923 65.385 88.462 65.385"
              fillRule="nonzero"
            />
            <polygon
              fill="#001E62"
              points="88.462 73.077 169.231 73.077 165.385 80.769 88.462 80.769"
              fillRule="nonzero"
            />
            <polygon
              fill="#001E62"
              points="88.462 100 146.154 100 138.462 107.692 88.462 107.692"
              fillRule="nonzero"
            />
            <polygon
              fill="#001E62"
              points="88.462 115.385 134.615 115.385 126.923 123.077 88.462 123.077"
              fillRule="nonzero"
            />
            <polyline
              stroke="#43B02A"
              points="42.236 65.385 53.775 76.923 72.084 58.613"
              strokeWidth="7.692"
            />
            <polyline
              stroke="#43B02A"
              points="42.236 107.692 53.775 119.231 72.084 100.921"
              strokeWidth="7.692"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export const icon = OrdersList;
