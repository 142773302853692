import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class MoneyPerson extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-money-person', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M6,9 L4,9 L4,4.00292933 C4,2.89890472 4.89047747,2 5.99339768,2 L22.0066023,2 C23.1082892,2 24,2.90415942 24,4.00247329 L24,13.9975267 C24,15.102097 23.1088293,16 22.0044225,16 L11,16 L11,14 L22.0008149,14 L22,4.00247329 L22,4.00247329 L5.99844173,4 L5.99844173,4 L6,9 Z M14,13 C12.2374077,13 11,11.1438884 11,9 C11,6.85611157 12.2374077,5 14,5 C15.7625923,5 17,6.85611157 17,9 C17,11.1438884 15.7625923,13 14,13 Z M14,11 C14.4465467,11 15,10.1698201 15,9 C15,7.83017993 14.4465467,7 14,7 C13.5534533,7 13,7.83017993 13,9 C13,10.1698201 13.5534533,11 14,11 Z M10.2928932,2.29289322 L11.7071068,3.70710678 L6.414,9 L5,9 L4.29289322,8.29289322 L10.2928932,2.29289322 Z M5,11 C7.209139,11 9,12.790861 9,15 C9,16.1192768 8.54028275,17.1311809 7.79936624,17.8571943 C9.12698863,18.7549515 10,20.2755244 10,22 L8,22 C8,20.3431458 6.65685425,19 5,19 C3.34314575,19 2,20.3431458 2,22 L0,22 C0,20.2755244 0.873011372,18.7549515 2.20118389,17.8561316 C1.45971725,17.1311809 1,16.1192768 1,15 C1,12.790861 2.790861,11 5,11 Z M5,13 C3.8954305,13 3,13.8954305 3,15 C3,16.1045695 3.8954305,17 5,17 C6.1045695,17 7,16.1045695 7,15 C7,13.8954305 6.1045695,13 5,13 Z M22.2928932,8.29289322 L23.7071068,9.70710678 L17.7071068,15.7071068 L16.2928932,14.2928932 L22.2928932,8.29289322 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(MoneyPerson);
