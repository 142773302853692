import { trackEvent, ACTIONS } from 'common/analytics';

export class SelectServiceDialogAnalytics {
  public static CATEGORY = 'services';

  public static trackTreatmentClicked(templateId) {
    trackEvent(
      SelectServiceDialogAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'templates-treatment',
      templateId,
    );
  }

  public static trackAddCustomTreatmentClicked() {
    trackEvent(
      SelectServiceDialogAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'templates-add-custom-service',
    );
  }

  public static trackExpandTemplateCategoryClicked() {
    trackEvent(SelectServiceDialogAnalytics.CATEGORY, ACTIONS.CLICK, 'templates-category');
  }

  public static trackServiceSearchUpdate(selectServiceValue) {
    trackEvent(
      SelectServiceDialogAnalytics.CATEGORY,
      ACTIONS.UPDATE,
      'templated-search-used',
      selectServiceValue,
    );
  }
}
