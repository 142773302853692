import React, { useState } from 'react';

import Wahanda from 'common/wahanda';
import { CancellationFlowAnalytics } from 'common/analytics';
import { BOOKING_ACTOR } from 'common/consts';

import App from 'common/backbone-app';
import { useOrderInfoQuery } from 'common/api-hooks/useOrderInfoQuery';
import { RescheduleOrCancelChoice } from './RescheduleOrCancelChoice';
import { CancelThroughSupport } from './CancelThroughSupport';
import { CancellationReason } from './CancellationReason';
import { ConfirmCancellationWithBulletPoints } from './ConfirmCancellationWithBulletPoints';
import { ConfirmCancellationWithRefundOptions } from './ConfirmCancellationWithRefundOptions';
import { ConfirmCancellationWithPaymentProtection } from './ConfirmCancellationWithPaymentProtection';
import { usePaymentProtectionQuery } from '../PaymentProtection/usePaymentProtectionQuery';
import { OrderCancellationOptions } from './OrderCancellationOptions';

const LANG = Wahanda.lang.calendar.appointments.cancellation;
const CANCELLATION_REASON_TYPE_VENUE = 'venue';
const CANCELLATION_REASON_TYPE_CUSTOMER = 'customer';
const CANCELLATION_REASON_TYPE_COVID = 'covid-19';

export interface CancellationProps {
  consumerId?: number | null;
  bookingActor: BOOKING_ACTOR;
  isPrepaid: boolean;
  isWithinCancellationPeriod: boolean;
  cancellationAllowed: boolean;
  reschedulingAllowed: boolean;
  paymentProtected: boolean;
  isFirstTimeCustomer: boolean;
  onReschedule: () => void;
  onDoCancellation: (_: any) => void;
  onClose: () => void;
  doClose: () => void;
  action: string;
  id: number;
  orderId?: number;
  granularity: 'appointment' | 'appointment-group';
  isRecurring: boolean;
}

enum Step {
  Init,
  CheckOrder,
  CancelOrCall,
  Confirm,
}

export const Cancellation = (props: CancellationProps) => {
  const { id, granularity, orderId } = props;

  const [step, setStep] = useState(Step.Init);
  const [isOrderQueryEnabled, setIsOrderQueryEnabled] = useState(false);
  const [cancellationReasonType, setCancellationReasonType] = useState();

  const { response: orderInfo } = useOrderInfoQuery(orderId, isOrderQueryEnabled);

  const { isLoading, response } = usePaymentProtectionQuery(
    granularity,
    id,
    cancellationReasonType === CANCELLATION_REASON_TYPE_CUSTOMER,
    'cancellation',
  );

  const isOrderWithSingleAppointment = () => {
    const appointments = orderInfo?.appointments || [];
    const appointmentGroups = orderInfo?.appointmentGroups || [];
    return appointments.length + appointmentGroups.length === 1;
  };

  const isVenueCancellation = () => cancellationReasonType === CANCELLATION_REASON_TYPE_VENUE;

  const isCustomerCancellation = () => cancellationReasonType === CANCELLATION_REASON_TYPE_CUSTOMER;

  const isCovidCancellation = () => cancellationReasonType === CANCELLATION_REASON_TYPE_COVID;

  const renderPrepayRequirementDialog = () => {
    const { consumerId } = props;
    // After cancelling an appointment and specifying that it was initiated by Customer
    // render prepay requirement dialog
    if (isCustomerCancellation() && App.config.canShowPrepayRequirementFeatures()) {
      App.ES6.Initializers.PrepayRequireDialog({
        customerId: consumerId,
      }).render();
    }
  };

  const renderConfirmCancellationWithRefundOptions = () => {
    const { bookingActor, isPrepaid, onDoCancellation, onClose } = props;

    const onCancel = (opts) => {
      onDoCancellation({
        ...opts,
        isVenueCancellation: isVenueCancellation(),
      });
      onClose();
      CancellationFlowAnalytics.trackCancellationSuccessful();
      renderPrepayRequirementDialog();
    };

    return (
      <ConfirmCancellationWithRefundOptions
        language={LANG.prepaidWidget.confirmCancellationWithRefundOptions}
        bookingActor={bookingActor}
        isPrepaid={isPrepaid}
        onClose={onClose}
        onCancel={onCancel}
      />
    );
  };

  const renderConfirmCancellationWithPaymentProtection = () => {
    const { bookingActor, onDoCancellation, onClose, isFirstTimeCustomer } = props;

    const onCancel = (opts) => {
      onDoCancellation({
        ...opts,
        isVenueCancellation: isVenueCancellation(),
      });
      onClose();
      CancellationFlowAnalytics.trackCancellationSuccessful();
      renderPrepayRequirementDialog();
    };

    return (
      <ConfirmCancellationWithPaymentProtection
        bookingActor={bookingActor}
        isFirstTimeCustomer={isFirstTimeCustomer}
        onClose={onClose}
        onCancel={onCancel}
      />
    );
  };

  const renderSuggestReschedulingOrCancelling = (options) => {
    const { language, onCancel } = options;

    const { onReschedule, onClose, reschedulingAllowed, action } = props;

    return (
      <RescheduleOrCancelChoice
        lang={language}
        onCancel={onCancel}
        reschedulingAllowed={reschedulingAllowed}
        action={action}
        onReschedule={onReschedule}
        onClose={onClose}
      />
    );
  };

  const renderCancelOrCall = () => {
    const { onClose, cancellationAllowed, bookingActor } = props;

    if (bookingActor === BOOKING_ACTOR.CUSTOMER && !cancellationAllowed) {
      return <CancelThroughSupport onClose={onClose} />;
    }

    const nextStepWithReason = (type) => () => {
      setCancellationReasonType(type);
      setStep(Step.Confirm);
    };

    return (
      <CancellationReason
        lang={LANG.marketplace.cancellationReason}
        onVenueCancelled={nextStepWithReason(CANCELLATION_REASON_TYPE_VENUE)}
        onCustomerCancelled={nextStepWithReason(CANCELLATION_REASON_TYPE_CUSTOMER)}
        onCovidCancelled={nextStepWithReason(CANCELLATION_REASON_TYPE_COVID)}
        onClose={onClose}
      />
    );
  };

  const renderConfirmCancellationWithBulletPoints = () => {
    const {
      bookingActor,
      isPrepaid,
      onClose,
      onDoCancellation,
      doClose,
      isFirstTimeCustomer,
      isRecurring,
    } = props;

    const onCancel = ({ includeFutureRecurrences }) => {
      if (isRecurring) {
        CancellationFlowAnalytics.trackSubmitCancelFutureAppointments(includeFutureRecurrences);
      }

      onDoCancellation({
        isVenueCancellation: isVenueCancellation(),
        isCovidCancellation: isCovidCancellation(),
        requestRefund: isCovidCancellation(),
        includeFutureRecurrences,
      });
      doClose();
      CancellationFlowAnalytics.trackCancellationSuccessful();
      renderPrepayRequirementDialog();
    };

    return (
      <ConfirmCancellationWithBulletPoints
        bookingActor={bookingActor}
        isPrepaid={isPrepaid}
        onClose={onClose}
        onCancel={onCancel}
        isReasonCovid={isCovidCancellation()}
        isReasonVenue={isVenueCancellation()}
        isFirstTimeCustomer={isFirstTimeCustomer}
        isRecurring={isRecurring}
      />
    );
  };

  const getStepComponent = () => {
    const {
      bookingActor,
      isWithinCancellationPeriod,
      isPrepaid,
      paymentProtected,
      onClose,
    } = props;
    const isLateCustomerLedCancellation = isCustomerCancellation() && !isWithinCancellationPeriod;

    switch (step) {
      case Step.Init:
        if (bookingActor === BOOKING_ACTOR.SUPPLIER) {
          return renderConfirmCancellationWithBulletPoints();
        }

        return renderSuggestReschedulingOrCancelling({
          language: LANG.marketplace.rescheduleDialog,
          onCancel: () => setStep(Step.CheckOrder),
        });
      case Step.CheckOrder:
        // All logic in this case will be redundant with backend support for partial order cancellations
        if (!orderId) {
          setStep(Step.CancelOrCall);
          return null;
        }

        if (orderId && !isOrderQueryEnabled) {
          setIsOrderQueryEnabled(true);
          return null;
        }

        if (!orderInfo) {
          return null;
        }

        if (isOrderWithSingleAppointment()) {
          setStep(Step.CancelOrCall);
          return null;
        }

        return (
          <OrderCancellationOptions
            onClose={onClose}
            orderInfo={orderInfo}
            onCancel={() => setStep(Step.CancelOrCall)}
          />
        );

      case Step.CancelOrCall:
        /*
         *  Step 2 is based on whether the cancellation is allowed by the channel feature:
         *
         *  If allowed, then go to next step
         *  If NOT allowed, stop here and show the "call us" dialog
         */
        return renderCancelOrCall();
      case Step.Confirm:
        /*
         *  Step 3 is the final confirmation before cancelling, with cancellation
         *  type based texts.
         */

        if (isLoading) {
          return null;
        }

        if (
          paymentProtected &&
          response?.canPreventForCancellation &&
          isLateCustomerLedCancellation
        ) {
          return renderConfirmCancellationWithPaymentProtection();
        }

        if (isPrepaid && isLateCustomerLedCancellation) {
          return renderConfirmCancellationWithRefundOptions();
        }

        return renderConfirmCancellationWithBulletPoints();

      default:
        throw new Error(`Unknown step ${step}`);
    }
  };

  return getStepComponent();
};
