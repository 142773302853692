import React from 'react';
import Wahanda from 'common/wahanda';
import { CalendarAnalytics } from 'common/analytics';
import { Tooltip, TooltipPlacement } from 'components/common/Tooltip';
import { ColumnCollapse, ColumnExpand } from 'components/common/Icon';
import { EMPLOYEE_WORKING_HOURS_TOGGLE_STORAGE_KEY } from './constants';
import styles from './EmployeeWorkingHoursToggle.scss';

export interface Props {
  actions: {
    setEmployeeWorkingHoursToggle: (isCollapsed: boolean) => void;
  };
  initialIsCollapsed: boolean;
}

export const EmployeeWorkingHoursToggle: React.FunctionComponent<Props> = ({
  actions,
  initialIsCollapsed,
}) => {
  const [isCollapsed, setCollapsed] = React.useState(initialIsCollapsed);
  const onClick = () => {
    const newisCollapsed = !isCollapsed;
    CalendarAnalytics.trackClickHideNonWorking(newisCollapsed);
    Wahanda.LocalStorage.set(EMPLOYEE_WORKING_HOURS_TOGGLE_STORAGE_KEY, newisCollapsed);
    setCollapsed(newisCollapsed);
    actions.setEmployeeWorkingHoursToggle(newisCollapsed);
  };
  const Icon = isCollapsed ? ColumnExpand : ColumnCollapse;
  const tooltip = isCollapsed
    ? Wahanda.lang.calendar.buttons.showAllTeamMembers
    : Wahanda.lang.calendar.buttons.showWorkingToday;
  return (
    <div className={styles.container}>
      <Tooltip tooltip={tooltip} placement={TooltipPlacement.right}>
        <a role="button" className={styles.toggleButton} onClick={onClick}>
          <Icon size="small" className={styles.icon} />
        </a>
      </Tooltip>
    </div>
  );
};
