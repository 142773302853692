const match = (datum, value) => datum.toLowerCase().includes(value);

const matchTreatmentPID = (value) => ({ id }) => id === value;
const matchMenuItemName = (value) => ({ name }) => match(name, value);

const matchMenuItems = (value, array) => ({ name, primaryTreatmentId }) =>
  match(name, value) || array.some(matchTreatmentPID(primaryTreatmentId));

const reduceTreatmentTypes = (value, treatments, treatmentTypes, { menuItems, ...treatmentType }) =>
  menuItems.some(matchMenuItems(value, treatments))
    ? treatmentTypes.concat({
        ...treatmentType,
        menuItems: menuItems.filter(matchMenuItems(value, treatments)),
      })
    : treatmentTypes;

const numberOfTreatmentTypes = (treatmentTypes, value) =>
  treatmentTypes.reduce(
    (n, { menuItems }) => n + menuItems.filter(matchMenuItemName(value)).length,
    0,
  );

const generateTreatmentTypes = (treatmentTypes, value, treatments) =>
  treatmentTypes.reduce(
    (treatmentTypesList, treatmentType) =>
      reduceTreatmentTypes(value, treatments, treatmentTypesList, treatmentType),
    [],
  );

/*
 *  Prepare the value once by converting to
 *  lower case and trimming white space
 */
export const generateTreatmentTypesFor = (treatmentTypes, value = '', treatmentsList) =>
  generateTreatmentTypes(treatmentTypes, value.toLowerCase().trim(), treatmentsList);

/*
 *  Prepare the value once by converting to
 *  lower case and trimming white space
 */
export const numberOfTreatmentTypesFor = (treatmentTypes, value = '') =>
  numberOfTreatmentTypes(treatmentTypes, value.toLowerCase().trim());
