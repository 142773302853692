import React from 'react';
import Wahanda from 'common/wahanda';
import { Currency } from 'components/common/DataFormat';

import {
  Table,
  TableColumnHeader,
  Align,
  TableRow,
  TableBody,
  TableFooter,
  TableHeader,
  TableData,
  TableLayout,
  Level,
} from 'components/common/Table';
import SubHeader from '../SubHeader';

import style from './style.scss';

interface PaymentSubType extends PaymentSummary {
  subType: string;
}

interface PaymentSummary {
  refunds: number;
  sales: number;
  total: number;
}

interface Payment extends PaymentSummary {
  subTypes: PaymentSubType[];
}

export interface Props {
  card: Payment;
  cash: Payment;
  grandTotal: PaymentSummary;
  vouchers: Payment;
  prepaid: Payment;
}

const LANG = Wahanda.lang.reports.posReports.salesPeriod.salesByPaymentType;

export default class PaymentsByType extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.renderPaymentSubType = this.renderPaymentSubType.bind(this);
  }

  public render() {
    return (
      <div>
        <SubHeader text={LANG.header} />
        <Table border layout={TableLayout.FIXED}>
          <TableHeader>{this.renderHeader()}</TableHeader>
          <TableBody>{this.renderBody()}</TableBody>
          <TableFooter>{this.renderFooter()}</TableFooter>
        </Table>
      </div>
    );
  }

  private renderHeader() {
    return (
      <TableRow>
        <TableColumnHeader align={Align.LEFT}>{LANG.type}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.grossSales}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.grossRefunds}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.grossTotal}</TableColumnHeader>
      </TableRow>
    );
  }

  private renderBody() {
    const { cash, card, vouchers, prepaid } = this.props;
    return (
      <React.Fragment>
        {this.renderPayment(LANG.cashPayments, cash)}
        {this.renderPayment(LANG.cardPayments, card)}
        {this.renderPayment(LANG.voucherPayments, vouchers)}
        {this.renderPayment(LANG.treatwellPayments, prepaid)}
      </React.Fragment>
    );
  }

  private renderPayment(header: string, payment: Payment) {
    return (
      <React.Fragment>
        <TableRow className={style.subHeader}>
          <TableData>
            <div className={style.bolded}>{header}</div>
          </TableData>
          <TableData align={Align.RIGHT}>
            <div className={style.bolded}>
              <Currency amount={payment.sales} />
            </div>
          </TableData>
          <TableData align={Align.RIGHT}>
            <div className={style.bolded}>
              <Currency amount={payment.refunds} alwaysNegative />
            </div>
          </TableData>
          <TableData align={Align.RIGHT}>
            <div className={style.bolded}>
              <Currency amount={payment.total} />
            </div>
          </TableData>
        </TableRow>
        {payment.subTypes.map(this.renderPaymentSubType)}
      </React.Fragment>
    );
  }

  private renderPaymentSubType(payment: PaymentSubType) {
    const { refunds, sales, total, subType } = payment;
    return (
      <TableRow key={subType} level={Level.ONE}>
        <TableData>{subType}</TableData>
        <TableData align={Align.RIGHT}>
          <Currency amount={sales} />
        </TableData>
        <TableData align={Align.RIGHT}>
          <Currency alwaysNegative amount={refunds} />
        </TableData>
        <TableData align={Align.RIGHT}>
          <Currency amount={total} />
        </TableData>
      </TableRow>
    );
  }

  private renderFooter() {
    const { grandTotal } = this.props;
    return (
      <TableRow>
        <TableData align={Align.LEFT}>
          <div className={style.bolded}>{LANG.grandTotal}</div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={grandTotal.sales} />
          </div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency alwaysNegative amount={grandTotal.refunds} />
          </div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={grandTotal.total} />
          </div>
        </TableData>
      </TableRow>
    );
  }
}
