import React from 'react';
import Wahanda from 'common/wahanda';

const { columns: langColumns, values: langExamples } = Wahanda.lang.customer.import.example;

export const ImportFileExample: React.SFC<React.HTMLAttributes<HTMLElement>> = (
  props: React.HTMLAttributes<HTMLOrSVGElement>,
) => (
  <svg width={510} height={117} {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="en_svg__a">
        <stop stopColor="#FFF" stopOpacity={0} offset="0%" />
        <stop stopColor="#FFF" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M0 0h516v117H0z" />
      <path
        strokeOpacity={0.102}
        stroke="#000"
        fill="#3ba150"
        d="M23.5 29.5h85v29h-85zm85 0h85v29h-85zm85 0h144v29h-144zm144 0h101v29h-101z"
      />
      <path stroke="#E6E6E6" d="M23.5 59.5h85v28h-85z" />
      <path fill="#FFF" d="M24 59h84v2H24z" />
      <path stroke="#E6E6E6" d="M108.5 59.5h85v28h-85z" />
      <path fill="#FFF" d="M109 59h84v2h-84z" />
      <path
        stroke="#E6E6E6"
        d="M193.5 59.5h144v28h-144zm144 0h101v28h-101zm-314 28h85v29h-85zm85 0h85v29h-85z"
      />
      <path fill="#FFF" d="M194 59h143v2H194zm144 0h100v2H338z" />
      <path stroke="#E6E6E6" d="M193.5 87.5h144v29h-144zm144 0h101v29h-101z" />
      <path stroke="#C4CBD3" fill="#E6EAED" d="M.5 29.5h23v29H.5z" />
      <path
        d="M.5 29.5h23V.5H3A2.5 2.5 0 0 0 .5 3v26.5z"
        stroke="#C4CBD3"
        fillOpacity={0.7}
        fill="#E6EAED"
      />
      <path
        stroke="#C4CBD3"
        fill="#E6EAED"
        d="M23.5.5h85v29h-85zm85 0h85v29h-85zm85 0h144v29h-144zm144 0h101v29h-101z"
      />
      <text
        fontFamily="SourceSansPro-Semibold, Source Sans Pro"
        fontSize={12}
        fontWeight={500}
        fill="#FFF"
      >
        <tspan x={31} y={48}>
          {langColumns.fullName}
        </tspan>
      </text>
      <text
        fontFamily="SourceSansPro-Semibold, Source Sans Pro"
        fontSize={12}
        fontWeight={500}
        fill="#FFF"
      >
        <tspan x={116} y={48}>
          {langColumns.firstName}
        </tspan>
      </text>
      <text
        fontFamily="SourceSansPro-Semibold, Source Sans Pro"
        fontSize={12}
        fontWeight={500}
        fill="#FFF"
      >
        <tspan x={201} y={48}>
          {langColumns.lastName}
        </tspan>
      </text>
      <text
        fontFamily="SourceSansPro-Semibold, Source Sans Pro"
        fontSize={12}
        fontWeight={500}
        fill="#FFF"
      >
        <tspan x={345} y={48}>
          {langColumns.phone}
        </tspan>
      </text>
      <text fontFamily="SourceSansPro-Regular, Source Sans Pro" fontSize={12.121} fill="#4D4D4D">
        <tspan x={31} y={76}>
          {langExamples.line1.fullName}
        </tspan>
      </text>
      <text fontFamily="SourceSansPro-Regular, Source Sans Pro" fontSize={12.121} fill="#4D4D4D">
        <tspan x={345} y={76}>
          {langExamples.line1.phone}
        </tspan>
      </text>
      <text fontFamily="SourceSansPro-Regular, Source Sans Pro" fontSize={12.121} fill="#4D4D4D">
        <tspan x={446} y={76}>
          {langExamples.line1.emailAddress}
        </tspan>
      </text>
      <text fontFamily="SourceSansPro-Regular, Source Sans Pro" fontSize={12.121} fill="#4D4D4D">
        <tspan x={116} y={105}>
          {langExamples.line2.firstName}
        </tspan>
      </text>
      <text fontFamily="SourceSansPro-Regular, Source Sans Pro" fontSize={12.121} fill="#4D4D4D">
        <tspan x={202} y={105}>
          {langExamples.line2.lastName}
        </tspan>
      </text>
      <text fontFamily="SourceSansPro-Regular, Source Sans Pro" fontSize={12.121} fill="#4D4D4D">
        <tspan x={345} y={105}>
          {langExamples.line2.phone}
        </tspan>
      </text>
      <text fontFamily="SourceSansPro-Regular, Source Sans Pro" fontSize={12.121} fill="#4D4D4D">
        <tspan x={446} y={105}>
          {langExamples.line2.emailAddress}
        </tspan>
      </text>
      <path strokeOpacity={0.102} stroke="#000" fill="#3ba150" d="M438.5 29.5h77v29h-77z" />
      <path stroke="#E6E6E6" d="M438.5 59.5h77v28h-77z" />
      <path fill="#FFF" d="M439 59h77v2h-77z" />
      <path d="M438.5 87.5v29H513a2.5 2.5 0 0 0 2.5-2.5V87.5h-77z" stroke="#E6E6E6" />
      <path d="M438.5.5v29h77V3A2.5 2.5 0 0 0 513 .5h-74.5z" stroke="#C4CBD3" fill="#E6EAED" />
      <text
        fontFamily="SourceSansPro-Semibold, Source Sans Pro"
        fontSize={12}
        fontWeight={500}
        fill="#FFF"
        transform="translate(438)"
      >
        <tspan x={8} y={48}>
          {langColumns.emailAddress}
        </tspan>
      </text>
      <text
        fontFamily="SourceSansPro-Bold, Source Sans Pro"
        fontSize={12.121}
        fontWeight="bold"
        fill="#4D4D4D"
        transform="translate(438)"
      >
        <tspan x={8} y={19}>
          E
        </tspan>
      </text>
      <text
        fontFamily="SourceSansPro-Bold, Source Sans Pro"
        fontSize={12.121}
        fontWeight="bold"
        fill="#4D4D4D"
      >
        <tspan x={9} y={48}>
          1
        </tspan>
      </text>
      <path stroke="#C4CBD3" fill="#E6EAED" d="M.5 58.5h23v29H.5z" />
      <text
        fontFamily="SourceSansPro-Bold, Source Sans Pro"
        fontSize={12.121}
        fontWeight="bold"
        fill="#4D4D4D"
      >
        <tspan x={9} y={78}>
          2
        </tspan>
      </text>
      <path d="M.5 87.5V114a2.5 2.5 0 0 0 2.5 2.5h20.5v-29H.5z" stroke="#C4CBD3" fill="#E6EAED" />
      <text
        fontFamily="SourceSansPro-Bold, Source Sans Pro"
        fontSize={12.121}
        fontWeight="bold"
        fill="#4D4D4D"
      >
        <tspan x={9} y={106}>
          3
        </tspan>
      </text>
      <text
        fontFamily="SourceSansPro-Bold, Source Sans Pro"
        fontSize={12.121}
        fontWeight="bold"
        fill="#4D4D4D"
      >
        <tspan x={32} y={19}>
          A
        </tspan>
      </text>
      <text
        fontFamily="SourceSansPro-Bold, Source Sans Pro"
        fontSize={12.121}
        fontWeight="bold"
        fill="#4D4D4D"
      >
        <tspan x={117} y={19}>
          B
        </tspan>
      </text>
      <text
        fontFamily="SourceSansPro-Bold, Source Sans Pro"
        fontSize={12.121}
        fontWeight="bold"
        fill="#4D4D4D"
      >
        <tspan x={202} y={19}>
          C
        </tspan>
      </text>
      <text
        fontFamily="SourceSansPro-Bold, Source Sans Pro"
        fontSize={12.121}
        fontWeight="bold"
        fill="#4D4D4D"
      >
        <tspan x={346} y={19}>
          D
        </tspan>
      </text>
      <path fill="url(#en_svg__a)" transform="rotate(-90 486 58.5)" d="M427.5 28.5h117v60h-117z" />
    </g>
  </svg>
);
