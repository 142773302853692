import {
  GET_VENUE_RECEIVE,
  INITIAL_STATE_DASHBOARD,
  GET_VENUE_STATISTICS_RECEIVE,
  GET_SUPPLIER_DETAILS_RECEIVE,
  TRACK_ACTION_ANALYTICS,
  VENUE_STATE_RESET,
  GET_CUSTOMER_REVIEWS_RECEIVE,
  PUT_REVIEW_RESPONSE,
  PUT_REVIEW_RESPONSE_SUCCESS,
  PUT_REVIEW_RESPONSE_FAILURE,
  CLEAR_PUT_REVIEW_RESPONSE_FAILURE,
  CLEAR_CUSTOMER_REVIEWS,
  GET_CUSTOMER_REVIEWS_REQUEST,
} from 'reduxStore/actionsConst';
import _ from 'common/underscore';
import updateReviewResponse from './selectors';

export const initialState = {
  isFetchingCustomerReviews: false,
  customerReviews: null,
};

function getCustomerReviewsReceive(state = {}, { data = {} } = {}) {
  /*
   *  Since "customerReviews" can be null ...
   */
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'customerReviews' does not exist on type ... Remove this comment to see the full error message
  const { customerReviews } = state;
  const { reviews = [] } = customerReviews || {};

  return Object.assign({}, state, {
    customerReviews: {
      ...data,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'reviews' does not exist on type '{}'.
      reviews: _.uniq([...reviews, ...data.reviews], false, ({ id }) => id),
    },
    isFetchingCustomerReviews: false,
  });
}

/*
 *  Complex -- refactor with functions
 */
export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case GET_VENUE_RECEIVE:
      return Object.assign({}, state, {
        venueDetails: {
          ...action.data,
        },
      });

    case INITIAL_STATE_DASHBOARD:
      return Object.assign({}, state, {
        ...action.data,
      });

    case GET_VENUE_STATISTICS_RECEIVE:
      return Object.assign({}, state, {
        venueStatistics: {
          ...action.data,
        },
      });

    case GET_SUPPLIER_DETAILS_RECEIVE:
      return Object.assign({}, state, {
        supplierDetails: {
          ...action.data.defaultBillingEntity,
        },
      });

    case GET_CUSTOMER_REVIEWS_REQUEST:
      return Object.assign({}, state, {
        isFetchingCustomerReviews: true,
      });

    case GET_CUSTOMER_REVIEWS_RECEIVE:
      return getCustomerReviewsReceive(state, action);

    case CLEAR_CUSTOMER_REVIEWS:
      return Object.assign({}, state, {
        customerReviews: null,
      });

    case PUT_REVIEW_RESPONSE:
      return Object.assign({}, state, {
        reviewResponseSaving: true,
      });

    case PUT_REVIEW_RESPONSE_SUCCESS:
      return Object.assign({}, state, {
        customerReviews: {
          ...(state.customerReviews as any),
          reviews: updateReviewResponse(
            (state.customerReviews as any).reviews,
            action.reviewId,
            action.data,
          ),
        },
        reviewResponseSaving: false,
      });

    case PUT_REVIEW_RESPONSE_FAILURE:
      return Object.assign({}, state, {
        reviewResponseErrors: action.data,
        reviewResponseSaving: false,
      });

    case CLEAR_PUT_REVIEW_RESPONSE_FAILURE:
      return Object.assign({}, state, {
        reviewResponseErrors: undefined,
      });
    case VENUE_STATE_RESET:
      return initialState;

    case TRACK_ACTION_ANALYTICS:
    default:
      return state;
  }
}
