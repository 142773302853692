import { DAY_PERCENTAGES } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const venueDetailsDayPercentagesTransformer = ({ openingHours = [], ...venueDetails }) => {
  const dayPercentages = openingHours
    .filter(({ openingTime, closingTime }) => !!(openingTime && closingTime))
    .reduce((days, { dayOfWeek }) => ({ ...days, [dayOfWeek]: DAY_PERCENTAGES }), {});

  return {
    ...venueDetails,
    openingHours,
    dayPercentages,
  };
};
