import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProcessOrder from './index';
import { productOrderReset, requestProductOrdersAction } from '../actions';

const mapStateToProps = ({ productOrders }) => ({
  existingOrder: productOrders.existingOrder,
  processingOrder: productOrders.processingOrder,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      productOrderReset,
      requestProductOrdersAction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessOrder);
