import { trackEvent, ACTIONS } from 'common/analytics';

const CATEGORY = 'finance-payments';

export const FinancePaymentsTracking = {
  trackMakePaymentClick: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'make-payments');
  },

  trackPaymentOptionsOpen: () => {
    trackEvent(CATEGORY, ACTIONS.VIEW, 'payment-options');
  },

  trackCardPaymentClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'card-payment');
  },

  trackBankPaymentClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'bank-payment');
  },

  trackIdealPaymentClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'ideal-payment');
  },

  trackCloseWindow: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'closed');
  },

  trackSuccessView: () => {
    trackEvent(CATEGORY, ACTIONS.VIEW, 'payment-success');
  },

  trackPayButtonClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'payment-submit');
  },

  trackCloseWithoutSubmittingPayment: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'payment-abandon');
  },

  trackShowBankLinkClick: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'payment-abandon-bank-details-link');
  },

  trackInconsistentData: () => {
    trackEvent(CATEGORY, ACTIONS.VIEW, 'payment-invalid-state');
  },
};
