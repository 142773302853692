export type ChatSourceType =
  | 'dashboard sidebar'
  | 'marketing sidebar'
  | 'calendar sidebar'
  | 'settings sidebar'
  | 'menu sidebar'
  | 'team sidebar'
  | 'header'
  | 'clients import failure'
  | 'clients import';

export const chat_opened = (props: { service: 'Salesforce' | 'Zendesk'; source: ChatSourceType }) =>
  props;
