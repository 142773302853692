import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import DiscountRuleRow from 'components/menu/OfferPricing/DiscountRuleRow/container';
import {
  initialState,
  requestVenueDetailsAction,
  requestDiscountRulesAction,
} from 'components/menu/OfferPricing/DiscountRuleRow/actions';

export const store = storeBuilder();

export default function render(
  targetElement,
  venueId,
  selectedDiscountRuleId,
  discountRules,
  hasDialogChanged,
) {
  const state = {
    venueId,
    selectedDiscountRuleId,
    discountRules,
  };
  store.dispatch(initialState(state));
  store.dispatch(requestVenueDetailsAction(venueId));
  store.dispatch(requestDiscountRulesAction(venueId));
  ReactDOM.render(
    //eslint-disable-line
    <Provider store={store}>
      {/* @ts-expect-error */}
      <DiscountRuleRow hasDialogChanged={hasDialogChanged} />
    </Provider>,
    targetElement,
  );
}
