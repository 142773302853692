import React, { useState, useEffect } from 'react';
import { Radio } from 'components/common/Radio';
import Wahanda from 'common/wahanda';
import style from './PaymentProtection.scss';

interface Props {
  flow: 'no-show' | 'cancellation';
  onChange: (_: boolean) => void;
}

export const PaymentProtection = (props: Props) => {
  const [preventPaymentProtection, setPreventPaymentProtection] = useState('');

  const informationTemplate = (props.flow === 'no-show'
    ? Wahanda.lang.paymentProtection.information
    : Wahanda.lang.paymentProtection.cancellationInformation
  )
    .replace(/{{/gi, '{{{')
    .replace(/}}/gi, '}}}');
  // triple mustache will not escape html. Replacing here so that translators are not confused by triple mustaches.

  const informationHtml = Wahanda.Template.render(informationTemplate, {
    bookingTermsLink: createLink(
      Wahanda.lang.paymentProtection.links.bookingTerms.label,
      Wahanda.lang.paymentProtection.links.bookingTerms.url,
    ),
    paymentProtectionLink: createLink(
      Wahanda.lang.paymentProtection.links.paymentProtection.label,
      Wahanda.lang.paymentProtection.links.paymentProtection.url,
    ),
  });

  function createLink(label, url) {
    return `<a href=${url} target="_blank" rel="noopener noreferrer">${label}</a>`;
  }

  useEffect(() => {
    if (preventPaymentProtection) {
      props.onChange(preventPaymentProtection === 'true');
    }
  }, [preventPaymentProtection]);

  function onChange(event) {
    setPreventPaymentProtection(event.target.value);
  }

  return (
    <div className={style.options}>
      <div className={style.information} dangerouslySetInnerHTML={{ __html: informationHtml }} />
      <div className={style.option}>
        <Radio
          name="prevent-payment-protection"
          value="false"
          label={Wahanda.lang.paymentProtection.chargeClient.label}
          onChange={onChange}
          checked={preventPaymentProtection === 'false'}
          dataTest="charge-client"
        />
        <p className={style.optionBody}>{Wahanda.lang.paymentProtection.chargeClient.body}</p>
      </div>
      <div className={style.option}>
        <Radio
          name="prevent-payment-protection"
          value="true"
          label={Wahanda.lang.paymentProtection.notChargeClient.label}
          onChange={onChange}
          checked={preventPaymentProtection === 'true'}
          dataTest="dont-charge-client"
        />
        <p className={style.optionBody}>{Wahanda.lang.paymentProtection.notChargeClient.body}</p>
      </div>
    </div>
  );
};
