App.Views.Forms.MenuOffer2.Pricing.Dated = Backbone.View.extend({
  events: {
    'click .a-new-range': 'onAddNewRange',
    'change .weekdays': 'toggleSingleEditButtons',
    'click .a-new-pricing-inline': 'addNewPricingToInline',
  },

  rendered: false,

  initialize: function () {
    // Pricing list views
    this.pricingViews = [];
    this.singleEditView = null;
    this.savedPricings = null;
    this.forceListMode = false;
  },

  /**
   * Render the pricing info from the model.
   */
  render: function () {
    if (!this.rendered) {
      this.rendered = true;
      this.$el.html(Wahanda.Template.renderTemplate('menu-offer-dated-pricing'));
      // Pricing items container
      this.$container = this.$('.pricing-container');
      this.$el.addClass('offer2--pricing--dated');
    }
    this.setBlockTitle();
    var items = this.getSplitPricings();
    this.pricingViews = [];
    this.$container.empty();

    if (this.singleEditView) {
      this.singleEditView = null;
    }

    if (
      !this.forceListMode &&
      (items.length === 0 || (items.length === 1 && items[0].list.length === 1))
    ) {
      this.addSingleItemView(items[0]);
    } else {
      this.forceListMode = false;
      for (var i = 0, len = items.length; i < len; i++) {
        var dateRange = items[i];
        this.addRange(dateRange);
      }
    }

    this.$('.a-new-range').wToggle(Wahanda.Permissions.editMenu() && this.canEditDateRange());
  },

  setBlockTitle: function () {
    var $title = this.$('.part-title');
    var isSpaBreak = this.model.isSpaBreak();
    $title.html(Wahanda.lang.menu.offer.pricing.titles[isSpaBreak ? 'spaBreak' : 'spaDay']);
  },

  getSplitPricings: function () {
    if (this.savedPricings) {
      var tmp = this.savedPricings;
      this.savedPricings = null;
      return tmp;
    }
    return this.model.getSplitPricings();
  },

  getRoomTypes: function () {
    return this.options.roomTypes;
  },

  addSingleItemView: function (data) {
    var self = this;
    var item;
    if (data) {
      item = _.extend(
        {
          dateFrom: data.dateFrom,
          dateTo: data.dateTo,
          pricingType: data.pricingType,
        },
        data.list[0],
      );
    } else {
      // Set defaults
      item = {
        dateFrom: Wahanda.Date.createVenueDate(),
      };
    }
    this.singleEditView = new App.Views.Forms.MenuOffer2.Pricing.Dated.Edit(
      _.extend(
        {
          el: this.$container,
          renderDates: true,
          isInline: true,
          hasRoomTypes: this.model.isSpaBreak(),
          roomTypes: this.getRoomTypes(),
          forceDateToFuture: !this.canEditDateRange(),
        },
        item,
      ),
    );
    this.singleEditView.render();
    this.toggleSingleEditButtons();
  },

  /**
   * Adds a new pricing range, into it's sorted position.
   *
   * @param Object data Hash of data
   * @return View The created range
   */
  addRange: function (data) {
    var view = new App.Views.Forms.MenuOffer2.Pricing.Dated.List({
      hasRoomTypes: this.model.isSpaBreak(),
      data: data,
      parentView: this,
      allowEditRange: this.canEditDateRange(),
    });

    view.render();
    this.addViewSorted(view);

    // Set up event lisener for date changes
    var self = this;
    view.on(
      'date-change',
      function () {
        self.addViewSorted(view);
      },
      this,
    );

    return view;
  },

  canEditDateRange: function () {
    return (
      App.config.get('venue').canSellDatedServices ||
      this.model.hasPricingForMultipleRanges() ||
      this.model.hasPricingForDateRange()
    );
  },

  addViewSorted: function (view) {
    // Removing the View from array, if it's there
    this.pricingViews = _.without(this.pricingViews, view);
    // Adding the view into it's sorted position
    var index = _.sortedIndex(this.pricingViews, view, function (item) {
      return item.dateFrom.getTime();
    });

    var tmp = this.pricingViews.slice(0, index);
    tmp.push(view);
    tmp = tmp.concat(this.pricingViews.slice(index));
    this.pricingViews = tmp;

    if (index - 1 >= 0) {
      view.$el.insertAfter(this.pricingViews[index - 1].$el);
    } else {
      view.$el.prependTo(this.$container);
    }
  },

  /**
   * Removes the given Pricing.List from DOM and pricingViews array.
   *
   * @param App.Views.Forms.MenuOffer2.Pricing.Dated.List view
   */
  removeView: function (view) {
    view.remove();
    this.pricingViews = _.without(this.pricingViews, view);
    view.off(null, null, this);

    if (this.pricingViews.length === 1 && this.pricingViews[0].childViews.length === 1) {
      this.onSingleChildLeft();
    }
  },

  /**
   * Returns the intersection check results betwen existing ranges and the given one.
   *
   * @param Date dateFrom
   * @param Date dateTo
   * @param View ignoreView OPTIONAL View to ignore when checking intersections
   *
   * @return Object. Possible values:
   * > null Means no intersection exists
   * > { type: "full", view: view } Means an intersection which can not be changed
   * > { type: "partial", view: view } Means an intersection exits, but the old range is infinite and it can be split
   */
  getDateIntersection: function (dateFrom, dateTo, ignoreView) {
    var newFrom = dateFrom.getTime();
    var newTo = dateTo && dateTo.getTime();
    var answer = null;
    var intersectingView = null;

    _.any(this.pricingViews, function (view) {
      if (view === ignoreView) {
        return;
      }

      if (newTo) {
        // New date is finite.
        if (view.dateTo) {
          // Both ranges are finite.
          // Error if dates intersect.
          if (Wahanda.Date.doOverlap([dateFrom, dateTo], [view.dateFrom, view.dateTo])) {
            answer = { type: 'full', view: view };
            return true;
          }
        } else {
          // The existing range is infinite.
          // Error if the new one begins before and ends in intersection.
          var oldFrom = view.dateFrom.getTime();
          if (newFrom <= oldFrom) {
            if (newTo >= oldFrom) {
              answer = { type: 'full', view: view };
              return true;
            }
          } else {
            answer = { type: 'partial', view: view };
            return true;
          }
        }
      } else {
        // New date is infinite.
        if (view.dateTo) {
          // Only the old range is finite.
          // Old range can not end later than the current begins.
          if (view.dateTo.getTime() >= newFrom) {
            answer = { type: 'full', view: view };
            return true;
          }
        } else {
          // Both ranges are infinite
          // Error if the new one starts before the old one.
          if (newFrom <= view.dateFrom.getTime()) {
            answer = { type: 'full', view: view };
          } else {
            answer = { type: 'partial', view: view };
          }
          return true;
        }
      }
    });

    return answer;
  },

  /**
   * Fixes partial intersection between a new intersectingView and the old (previously infinite) viewToChange.
   *
   * @param PricingView intersectingView
   * @param PricingView viewToShrink
   * @param Object options OPTIONAL Possible values:
   * > doNotContinue : do not continue the intersectingView, even if it was infinite
   */
  fixIntersection: function (intersectingView, viewToChange, options) {
    options = options || {};

    viewToChange.dateTo = new Date(
      intersectingView.dateFrom.getFullYear(),
      intersectingView.dateFrom.getMonth(),
      intersectingView.dateFrom.getDate() - 1,
    );
    viewToChange.pricingType = 'R';
    viewToChange.renderDates();

    if (intersectingView.dateTo && !options.doNotContinue) {
      var values = viewToChange.getValues();
      values.dateFrom = new Date(
        intersectingView.dateTo.getFullYear(),
        intersectingView.dateTo.getMonth(),
        intersectingView.dateTo.getDate() + 1,
      );
      values.dateTo = null;
      values.pricingType = 'F';
      // The copied rule does not have an ID yet
      for (var i = 0, len = values.list.length; i < len; i++) {
        values.list[i].pricingRuleId = null;
      }

      this.addRange(values);
    }
  },

  /**
   * Reutns pricing values in API format.
   */
  getValues: function () {
    var values = [];
    var hasRooms = this.model.isSpaBreak();
    if (this.singleEditView) {
      var viewValues = this.singleEditView.getValues();

      viewValues.dateFrom = Wahanda.Date.toApiString(viewValues.dateFrom);
      if (viewValues.dateTo) {
        viewValues.dateTo = Wahanda.Date.toApiString(viewValues.dateTo);
      }

      if (hasRooms) {
        var rooms = viewValues.rooms;
        delete viewValues.rooms;
        _.each(rooms, function (room) {
          values.push(_.extend({}, room, viewValues));
        });
      } else {
        values.push(viewValues);
      }
    } else {
      for (var i = 0, len = this.pricingViews.length; i < len; i++) {
        var viewValues = this.pricingViews[i].getValues();
        var pricingType = viewValues.pricingType;
        var dateFrom = Wahanda.Date.toApiString(viewValues.dateFrom);
        var dateTo = viewValues.dateTo ? Wahanda.Date.toApiString(viewValues.dateTo) : null;
        for (var j = 0, jLen = viewValues.list.length; j < jLen; j++) {
          var base = {
            dateFrom: dateFrom,
            dateTo: dateTo,
            pricingType: pricingType,
            applicableDays: viewValues.list[j].applicableDays,
          };
          if (hasRooms) {
            _.each(viewValues.list[j].rooms, function (room) {
              values.push(_.extend({}, room, base));
            });
          } else {
            values.push(_.extend({}, viewValues.list[j], base));
          }
        }
      }
    }

    return { pricing: values };
  },

  /**
   * Returns the count of ranges exist in this offer.
   *
   * @return int
   */
  getRangesCount: function () {
    if (this.singleEditView) {
      return 1;
    }
    return this.pricingViews.length;
  },

  isInlineEditViewValid: function () {
    if (this.singleEditView && !this.singleEditView.isValid()) {
      // Form is not valid.
      this.markInvalidElement();
      return false;
    }
    return true;
  },

  // Events

  onAddNewRange: function () {
    // Is inline edit mode, validate the form first
    if (!this.isInlineEditViewValid()) {
      return;
    }

    var self = this;
    var dateFrom = null;
    var lastRange;
    var initRooms;

    if (this.singleEditView) {
      lastRange = this.singleEditView.getValues();
      initRooms = lastRange.rooms;
    } else {
      lastRange = this.pricingViews[this.pricingViews.length - 1];
      initRooms = lastRange.childViews[0].rooms;
    }
    // If available, set dateFrom to the last view's dateTo + 1 day
    if (lastRange.dateTo !== null) {
      dateFrom = new Date(
        lastRange.dateTo.getFullYear(),
        lastRange.dateTo.getMonth(),
        lastRange.dateTo.getDate() + 1,
      );
    }

    var options = {
      renderDates: true,
      hasRoomTypes: this.model.isSpaBreak(),
      roomTypes: this.getRoomTypes(),
      saveCallback: function (values) {
        return self._handleRangeAdd(values);
      },
      dateFrom: dateFrom,
    };
    if (this.model.isSpaBreak()) {
      options.rooms = _.map(initRooms, function (room) {
        return {
          roomTypeId: room.roomTypeId,
        };
      });
    }

    var dialog = new App.Views.Forms.MenuOffer2.Pricing.Dated.EditDialog(options);
    dialog.render();
    dialog.open();
  },

  _handleRangeAdd: function (values) {
    var self = this;

    if (this.singleEditView) {
      this.convertSingleEditViewToList();
    }

    var intersection = this.getDateIntersection(values.dateFrom, values.dateTo, this);
    var saveCallback = function () {
      App.trigger(Wahanda.Event.MENU_OFFER_PRICING_RANGE_ADDED, {
        offerId: self.model.id,
        dateFrom: Wahanda.Date.toApiString(values.dateFrom),
        dateTo: values.dateTo && Wahanda.Date.toApiString(values.dateTo),
      });
      return self.addRange({
        dateFrom: values.dateFrom,
        dateTo: values.dateTo,
        pricingType: values.dateTo == null ? 'F' : 'R',
        list: [values],
      });
    };

    if (null == intersection) {
      // No intersection. Proceed with save.
      saveCallback();
      return { valid: true };
    } else {
      if ('partial' === intersection.type) {
        if (null == values.dateTo && null == intersection.view.dateTo) {
          this.fixIntersection(saveCallback(), intersection.view);
          return { valid: true };
        }
        return {
          fixableIntersection: true,
          saveCallback: function (doNotContinue) {
            var newView = saveCallback();
            self.fixIntersection(newView, intersection.view, {
              doNotContinue: doNotContinue,
            });
          },
        };
      } else {
        return {
          errorText: Wahanda.lang.menu.offer.pricing.errors.intersectionExists,
        };
      }
    }
  },

  convertSingleEditViewToList: function (otherViewData) {
    var data = this.singleEditView.getValues();
    var list = [data];
    if (otherViewData) {
      list.push(otherViewData);
    }

    var saved = {
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      pricingType: data.pricingType,
    };
    saved.list = list;

    this.savedPricings = [saved];
    this.forceListMode = true;
    this.render();
  },

  toggleSingleEditButtons: function () {
    var availableDays = this.$('.weekdays').find('li.on').length;
    // Add new weekday item to inline view
    this.$('.b-add-new').wToggle(availableDays < 7);
  },

  /**
   * Adds new pricing for inline edit view.
   */
  addNewPricingToInline: function () {
    if (!this.isInlineEditViewValid()) {
      return;
    }

    var self = this;
    var data = this.singleEditView.getValues();
    var rooms;
    if (this.model.isSpaBreak()) {
      rooms = _.map(data.rooms, function (room) {
        return {
          roomTypeId: room.roomTypeId,
        };
      });
    }

    var view = new App.Views.Forms.MenuOffer2.Pricing.Dated.EditDialog({
      saveCallback: function (values) {
        self.convertSingleEditViewToList(values);
      },
      usedWeekdays: data.applicableDays,
      hasRoomTypes: this.model.isSpaBreak(),
      roomTypes: this.getRoomTypes(),
      rooms: rooms,
    });
    view.render();
    view.open();
  },

  /**
   * Is triggered when only one childView is left in a ListView
   *
   * @return boolean If converted to inline-view
   */
  onSingleChildLeft: function () {
    if (this.pricingViews.length === 1) {
      this.savedPricings = [this.pricingViews[0].getValues()];
      this.render();
      return true;
    }
    return false;
  },

  /**
   * Supplementory validation to $.validate(), handling invisible business logic.
   */
  isValid: function () {
    if (this.singleEditView) {
      return this.singleEditView.isValid();
    }
    return true;
  },

  markInvalidElement: function () {
    var $errorField = this.$(':input:visible').filter('.error:first');
    if ($errorField.length > 0) {
      // $.validate has already marked form as invalid, do nothing.
      $errorField.focus();
      return;
    }

    // Room edit error. Mark one of possible problematic areas.
    var $tooltipTarget = this.$('.pricing-container').find('input:checkbox:first');
    var tooltipOptions = {
      position: {
        my: 'bottom center',
        at: 'top center',
      },
    };
    if ($tooltipTarget.length === 0) {
      $tooltipTarget = this.$('.room-type-row .input-part');
      tooltipOptions.hide = {
        event: 'click',
        inactive: 1000,
      };
    }
    $tooltipTarget
      .formErrorTip(Wahanda.lang.menu.offer.pricing.errors.noRoomSelected, tooltipOptions)
      .focus();
    $tooltipTarget.one('blur', function () {
      $tooltipTarget.qtip('destroy');
    });
  },
});
