import React from 'react';
import Dialog from 'components/common/react-dialog';
import classnames from 'classnames';
import style from './style.scss';

const WINDOW_PADDING = 40;
/*
 *  Default to window.innerHeight so that the prop can be undefined
 */
const calculateMaxHeight = (maxHeight = window.innerHeight) =>
  Math.min(window.innerHeight - WINDOW_PADDING, maxHeight);

interface IFullHeightDialogProps extends React.HTMLAttributes<Element> {
  minHeight?: number;
  minHeightAsMax?: boolean;
  maxHeight?: number;
  classes?: {} | string;
  dialogContentMaxWidth?: number;
  hideTitlebar?: boolean;
  title: string;
  width?: number | string;
  onClose: (...args: any[]) => void;
  noContentPadding?: boolean;
  noContentTopBorders?: boolean;
  keepTopPositionWhenResizing: boolean;
}
type FullHeightDialogState = {
  maxHeight?: number;
  minHeight?: any;
};

export default class FullHeightDialog extends React.Component<
  IFullHeightDialogProps,
  FullHeightDialogState
> {
  state = {
    minHeight: this.props.minHeightAsMax
      ? calculateMaxHeight(this.props.maxHeight)
      : this.props.minHeight,
    maxHeight: calculateMaxHeight(this.props.maxHeight),
  };

  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({ maxHeight: calculateMaxHeight(this.props.maxHeight) });
  };

  render() {
    const { minHeight, maxHeight } = this.state;
    const { classes } = this.props;
    // 'children' are also passed as props
    return (
      <Dialog
        {...this.props}
        classes={classnames(classes, style.dialog)}
        dialogContentMinHeight={minHeight}
        dialogContentMaxHeight={maxHeight}
      />
    );
  }
}
