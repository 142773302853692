import React from 'react';
import Loader from 'components/common/Loader';
import _ from 'common/underscore';
import moment from 'common/moment';
import { PrintButton } from 'components/shared/PrintButton';
import TimeStamp from 'components/reports/newSalesReports/common/TimeStamp/';
import DISPLAY from 'components/reports/newSalesReports/displayStateEnum';
import POSReportSelector from 'components/reports/POSReports/filters/POSReportSelector/withRouter';
import PaymentsByType, { Props as PaymentsByTypeProps } from './PaymentsByType';
import SalesSummary, { Props as SalesSummaryProps } from './SalesSummary';
import SalesByCategory, { Props as SalesByCategoryProps } from './SalesByCategory';
import VATRate, { Props as VatRateProps } from './VATRate';
import VATByCategory, { Props as VATByCategoryProps } from './VATByCategory';
import NotRevenueRelevant, { Props as NotRevenueRelevantProps } from './NotRevenueRelevant';
import style from './style.scss';

interface RequestProps {
  from: string;
  to: string;
  venueId: string | number;
}

export interface Props {
  query: {
    from: string;
    to: string;
    venueId: string;
  };
  displayState: symbol;
  reportData?: {
    paymentsByType: PaymentsByTypeProps;
    notRevenueRelevantSales: NotRevenueRelevantProps;
    salesByCategory: SalesByCategoryProps;
    salesByVatRate: VatRateProps;
    salesSummary: SalesSummaryProps;
    vatByCategory: VATByCategoryProps;
  };
  filter?: JSX.Element;
  actions: {
    getPOSReportDataRequestAction: (params: RequestProps) => void;
  };
}

export default class SalesPeriodReport extends React.PureComponent<Props> {
  public componentDidUpdate(prevProps: Props) {
    if (!_.isEqual(this.props.query, prevProps.query)) {
      this.requestData();
    }
  }

  public componentDidMount() {
    this.requestData();
  }

  public render() {
    return (
      <div>
        <div className={style.actions}>
          <POSReportSelector />
          <PrintButton />
        </div>
        <div className={style.filters}>
          {this.props.filter}
          <TimeStamp date={moment()} />
        </div>
        {this.renderContent()}
      </div>
    );
  }

  private renderContent() {
    const { reportData, displayState } = this.props;
    if (displayState === DISPLAY.WAITING) {
      return <Loader positionAbsolute />;
    }
    if (reportData == null) {
      return null;
    }

    return (
      <div>
        <div className={style.table}>
          <SalesSummary {...reportData.salesSummary} />
        </div>
        <div className={style.table}>
          <SalesByCategory {...reportData.salesByCategory} />
        </div>
        <div className={style.table}>
          <NotRevenueRelevant {...reportData.notRevenueRelevantSales} />
        </div>
        <div className={style.table}>
          <VATRate {...reportData.salesByVatRate} />
        </div>
        <div className={style.table}>
          <VATByCategory {...reportData.vatByCategory} />
        </div>
        <div className={style.table}>
          <PaymentsByType {...reportData.paymentsByType} />
        </div>
      </div>
    );
  }

  private requestData() {
    const { getPOSReportDataRequestAction } = this.props.actions;
    getPOSReportDataRequestAction(this.props.query);
  }
}
