import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class MoneyDeposit extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-money-deposit', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3329 2.6665C15.7013 2.6665 16 2.96292 16 3.33375V12.6659C16 13.0344 15.7017 13.3332 15.3329 13.3332H0.66713C0.298684 13.3332 0 13.0367 0 12.6659V3.33375C0 2.96524 0.298313 2.6665 0.66713 2.6665H15.3329ZM1.33333 10.6665V11.9998H2.66667C2.66667 11.2635 2.06971 10.6665 1.33333 10.6665ZM14.6667 10.6665C13.9638 10.6665 13.3879 11.2104 13.337 11.9003L13.3333 11.9998H14.6667V10.6665ZM8 4.6665C9.47276 4.6665 10.6667 6.15889 10.6667 7.99984C10.6667 9.84079 9.47276 11.3332 8 11.3332C6.52724 11.3332 5.33333 9.84079 5.33333 7.99984C5.33333 6.15889 6.52724 4.6665 8 4.6665ZM8 5.99984C7.33724 5.99984 6.66667 6.83805 6.66667 7.99984C6.66667 9.16162 7.33724 9.99984 8 9.99984C8.66276 9.99984 9.33333 9.16162 9.33333 7.99984C9.33333 6.83805 8.66276 5.99984 8 5.99984ZM2.66667 3.99984H1.33333V5.33317C2.03624 5.33317 2.61211 4.78925 2.66301 4.09935L2.66667 3.99984ZM14.6667 3.99984H13.3333C13.3333 4.37949 13.492 4.73209 13.7667 4.98362C14.0111 5.20745 14.3282 5.33317 14.6667 5.33317V3.99984ZM14.6667 6.6665C13.9916 6.6665 13.3543 6.41379 12.8663 5.96702C12.3185 5.4655 12 4.75746 12 3.99984H4C4 5.42797 2.87735 6.59389 1.46643 6.66324L1.33333 6.6665V9.33317C2.80609 9.33317 4 10.5271 4 11.9998H12C12 10.5717 13.1226 9.40578 14.5336 9.33643L14.6667 9.33317V6.6665Z"
          fill="#1A1A1A"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(MoneyDeposit);
