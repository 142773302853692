import React from 'react';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';

import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';
import { Button } from 'components/common/Button';
import { StateCard } from 'components/common/StateCard';

import { TeamAnalytics } from './tracking';

const LANG = Wahanda.lang.settings.employees;

export class EmployeesContainer extends React.Component {
  public componentDidMount() {
    NavigationAnalytics.trackPageEventView(L2Page.TEAM);
  }

  private openAddEmployeeForm = () => {
    TeamAnalytics.trackAddTeamMemberClick();
    App.ES6.Initializers.EmployeeDialog.render();
  };

  public render() {
    return (
      <div data-test="team-container" className="dynamic-allocation">
        <div className="employee-filter" />
        <div className="data-content" />
        <div className="none-employees">
          <StateCard
            button={{
              text: LANG.employees.buttons.addEmployee,
              onClick: this.openAddEmployeeForm,
            }}
            heading={LANG.employees.buttons.addENew}
            message={Wahanda.lang.shared.list.empty}
          />
        </div>
        <div className="data-actions">
          <Button label={LANG.employees.buttons.addEmployee} onClick={this.openAddEmployeeForm} />
        </div>
      </div>
    );
  }
}
