import React from 'react';
import classnames from 'classnames';

interface IButtonProps extends React.HTMLAttributes<Element> {
  icon?: string;
  iconElement?: JSX.Element;
  classes?: any;
  title?: string;
  onClick?: (...args: any[]) => any;
}

export default class Button extends React.Component<IButtonProps, {}> {
  renderIcon = () => {
    const { iconElement, icon } = this.props;
    if (iconElement) {
      return <div className="button-icon">{iconElement}</div>;
    }
    if (icon) {
      return <div className={`button-icon icons-${this.props.icon}`} />;
    }
    return null;
  };

  render() {
    return (
      <button
        type="button"
        className={classnames('button', this.props.classes)}
        onClick={this.props.onClick}
      >
        <div className="button-inner">
          {this.renderIcon()}
          {this.props.title}
        </div>
      </button>
    );
  }
}
