import React from 'react';
import moment from 'common/moment';

interface Props {
  datetime: string;
}

export const NotificationDatetimeFormatted = ({ datetime }: Props) => {
  return (
    <>
      {moment(datetime).calendar(undefined, {
        nextWeek: 'dddd, MMM D, LT',
        lastWeek: 'dddd, MMM D, LT',
        sameElse: 'MMMM D, LT',
      })}
    </>
  );
};
