App.Views.Calendar.Dated.HeaderPane = function (options) {
  this.paneView = options.paneView;
  this.mainView = options.mainView;
};

$.extend(App.Views.Calendar.Dated.HeaderPane.prototype, {
  renderResources: function (options) {
    if (this.mainView.getCalendarType() === 'spa-day') {
      this.paneView.$('.filters').wHide();
      // Never show the "All groups" button
      this.paneView.toggleAllGroupLinkVisibility(true);
    } else {
      this._renderResourceSelect(options);
    }
  },

  onResourceIdChange: function (event) {
    var allGroupsSelected = $(event.currentTarget).attr('id') === 'all';
    this.paneView.toggleAllGroupLinkVisibility(allGroupsSelected);
  },

  toggleClassesByType: function () {
    var back = this.paneView.$('.previous').find('.icon');
    var next = this.paneView.$('.next').find('.icon');

    back.addClass('icons-previous2').removeClass('icons-previous');
    next.addClass('icons-next2').removeClass('icons-next');
  },

  renderTypeBlock: function () {
    // Do nothing.
  },

  getCurrentDayText: function () {
    return Wahanda.lang.calendar.buttons.gotoThisMonth;
  },

  isCurrentDateShown: function () {
    var now = this.getTodayDate();
    var shown = this.paneView.currentDate;

    return now.getFullYear() === shown.getFullYear() && now.getMonth() === shown.getMonth();
  },

  getPrevDate: function () {
    var oldDate = this.paneView.currentDate;
    return new Date(oldDate.getFullYear(), oldDate.getMonth() - 1, 1);
  },

  getNextDate: function () {
    var oldDate = this.paneView.currentDate;
    return new Date(oldDate.getFullYear(), oldDate.getMonth() + 1, 1);
  },

  getTodayDate: function () {
    return Wahanda.Date.createVenueDate();
  },

  getCurrentDateText: function () {
    var date = this.paneView.currentDate;
    return date.getFullYear() + ' ' + Wahanda.lang.date.months[date.getMonth()];
  },

  getShowAllResourcesText: function () {
    return Wahanda.lang.calendar.pane.buttons.showAllRooms;
  },

  /**
   *
   * @param Object options
   */
  _renderResourceSelect: function (options) {
    var list = [];
    if (options.list.length === 0) {
      this.paneView.$('.filters').wHide();
      this.paneView.toggleAllGroupLinkVisibility(true);
    } else {
      var itemsHtml = '';
      var moreThanOne = options.list.length > 1;
      this.hideSingleResourceTitle();
      var tplData = {
        options: options.list.toSelectOptions({
          selected: options.selected,
        }),
        single: options.list === 1,
      };

      this._renderResourceTemplate(tplData);

      this.paneView.$('.filters').wShow();

      this.paneView.toggleAllGroupLinkVisibility(!(moreThanOne && options.selected != null));
    }
  },

  hideSingleResourceTitle: function () {
    this.paneView.$('.b-resource-name').hide();
  },

  _renderResourceTemplate: function (tplData) {
    this.paneView
      .$('.calendar-resource')
      .html(Wahanda.Template.renderTemplate('calendar-resource-template', tplData));
  },
});
