require('../../../../../lib/assets/javascript/backbone-ex/model/silent/allocation.js');
App.Models.RoomAllocation = BackboneEx.Model.Silent.Allocation.extend({
  structure: {
    date: 'date',
    roomTypes: 'array',
  },
  // String
  dateFrom: null,
  // String
  dateTo: null,

  _afterSave: function () {
    App.trigger(Wahanda.Event.ROOM_ALLOCATION_SAVED, {
      closedOffers: (this.get('closedOffers') || []).length,
    });
    this.notSet = false;
  },

  _getSaveData: function () {
    var data = {
      roomTypes: this.get('roomTypes'),
      closedOffers: this.get('closedOffers') || [],
    };
    if (this.get('dateRange')) {
      data.dateRange = this.get('dateRange');
    } else {
      data.dates = this.get('dates');
    }

    return data;
  },

  isAllClosed: function (list) {
    list = list || this.get('roomTypes') || [];
    var allClosed = list.length > 0;
    _.each(this.get('roomTypes') || [], function (roomType) {
      return (allClosed = allClosed && roomType.closed);
    });
    return allClosed;
  },

  isOutOfStock: function (rooms) {
    rooms = rooms || this.get('roomTypes') || [];
    var total = rooms.length;
    var oos = 0;
    var closed = 0;

    _.each(rooms, function (roomType) {
      if (roomType.closed) {
        closed++;
      } else if (roomType.inventoryAvailable === 0) {
        oos++;
      }
    });
    // Day is out of stock, if at least one room is OOS and all others too or all others are closed out
    return total > 0 && oos > 0 && total === oos + closed;
  },

  isNoInventory: function (rooms) {
    rooms = rooms || this.get('roomTypes') || [];
    var total = rooms.length;
    var noInventory = 0;
    var closed = 0;

    _.each(rooms, function (roomType) {
      if (roomType.closed) {
        closed++;
      } else if (roomType.inventoryAvailable === 0 && roomType.inventoryBooked === 0) {
        noInventory++;
      }
    });
    // Day is out of stock, if at least one room is OOS and all others too or all others are closed out
    return total > 0 && noInventory > 0 && total === noInventory + closed;
  },

  hasLowStock: function (rooms) {
    rooms = rooms || this.get('roomTypes') || [];
    var lowCount = this.lowInventoryCount;
    return (
      null !=
      _.find(rooms, function (roomType) {
        return roomType.inventoryAvailable <= lowCount && roomType.closed === false;
      })
    );
  },

  getInventoryText: function () {
    var inv = Wahanda.lang.calendar.spaDays.inventory;

    if (this.isAllClosed()) {
      return inv.closed;
    }
    if (this.isNoInventory()) {
      return inv.noinventory;
    }
    if (this.isOutOfStock()) {
      return inv.nothing;
    }
    var count = this.getTotalInventoryAvailable();
    if (count > 0) {
      return inv.count.replace('{{count}}', count);
    }
    return inv.nothing;
  },

  getStateClass: function () {
    if (this.isAllClosed()) {
      return 'state-closed';
    }
    if (this.isNoInventory()) {
      return 'state-noinventory';
    }
    if (this.isOutOfStock()) {
      return 'state-out';
    }
    if (this.hasLowStock()) {
      return 'state-low';
    }
    return '';
  },

  getTotalInventoryAvailable: function () {
    return _.reduce(
      this.get('roomTypes') || [],
      function (memo, roomType) {
        return memo + roomType.inventoryAvailable;
      },
      0,
    );
  },

  getBookedInventoryCount: function () {
    return _.reduce(
      this.get('roomTypes') || [],
      function (memo, roomType) {
        return memo + roomType.inventoryBooked;
      },
      0,
    );
  },

  getRoomType: function (typeId) {
    var type = _.find(this.get('roomTypes') || [], function (type) {
      return typeId === type.roomTypeId;
    });
    if (!type) {
      type = {
        roomTypeId: typeId,
        closed: true,
      };
    }
    return type;
  },

  getRoomTypesForTemplate: function (roomTypesCollection) {
    var self = this;
    var list = [];

    function getInventoryText(roomType) {
      var inv = Wahanda.lang.calendar.spaBreaks.inventory;
      if (roomType.closed) {
        return inv.closed;
      }
      if (roomType.inventoryAvailable > 0) {
        return inv.count.replace('{{count}}', roomType.inventoryAvailable);
      }
      if (roomType.inventoryAvailable === 0 && roomType.inventoryBooked === 0) {
        return inv.noinventory;
      }
      // default === 0
      return inv.nothing;
    }

    function getStateClass(roomType) {
      if (roomType.closed) {
        return 'state-closed';
      }
      if (roomType.inventoryAvailable === 0 && roomType.inventoryBooked === 0) {
        return 'state-noinventory';
      }
      if (roomType.inventoryAvailable === 0) {
        return 'state-out';
      }
      if (roomType.inventoryAvailable <= self.lowInventoryCount) {
        return 'state-low';
      }
      // default > 0
      return '';
    }

    roomTypesCollection.each(function (roomType) {
      var type = self.getRoomType(roomType.id);
      list.push({
        roomType: roomType.get('name'),
        stockText: getInventoryText(type),
        stateClass: getStateClass(type),
        soldCount: type.inventoryBooked,
      });
    });
    return list;
  },

  /**
   * Runs the given `callback` on each type in the types array.
   * All logic is as _.each
   *
   * @param Function callback
   */
  eachType: function (callback) {
    _.each(this.get('roomTypes') || [], function (type) {
      callback(type);
    });
  },

  /**
   * Fills missing room types with `closed` values.
   * It is called form the extern.
   *
   * @param App.Collections.RoomTypes collection
   */
  fillMissingValues: function (collection) {
    var allTypes = this.get('roomTypes') || [];
    var existingIds = _.pluck(allTypes, 'roomTypeId');

    collection.each(function (model) {
      if (-1 === _.indexOf(existingIds, model.id)) {
        allTypes.push({
          roomTypeId: model.id,
          inventoryAvailable: 0,
          inventoryReserved: 0,
          inventoryBooked: 0,
          closed: true,
        });
      }
    });

    this.set('roomTypes', allTypes);
  },

  // Fetching / saving functions

  url: function (forSave) {
    var base = '/venue/' + App.config.get('venue').id + '/room-allocations.json';
    if (!forSave) {
      base +=
        '?' + 'include=offers' + '&' + 'date-from=' + this.dateFrom + '&date-to=' + this.dateTo;
    } else {
      base += '?include=closed-offers';
    }
    return App.Api.wsUrl(base);
  },
});
