const LocalStorageKey = {
  USER_ID: 'segment_identify_user_id',
  VENUE_ID: 'segment_group_venue_id',
};

export const SegmentUser = {
  id: LocalStorageKey.USER_ID,
  isAlreadyIdentified: ({ userId }) => isInLocalStorage(SegmentUser.id, userId),
  markIdentified: ({ userId }) => saveInLocalStorage(SegmentUser.id, userId),
  clearIdentified: () => removeFromLocalStorage(SegmentUser.id),
};

export const SegmentGroup = {
  id: LocalStorageKey.VENUE_ID,
  isAlreadyTracked: ({ venueId }) => isInLocalStorage(SegmentGroup.id, venueId),
  markTracked: ({ venueId }) => saveInLocalStorage(SegmentGroup.id, venueId),
  clearTracked: () => removeFromLocalStorage(SegmentGroup.id),
};

type LocalStorageKey = typeof LocalStorageKey[keyof typeof LocalStorageKey];

const isInLocalStorage = (key: LocalStorageKey, value: number | string): boolean =>
  localStorage.getItem(key) === value.toString();

const saveInLocalStorage = (key: LocalStorageKey, value: number | string): void => {
  // catch errors if localStorage capacity is exceeded
  // https://treatwell-tech.sentry.io/issues/4875372455/
  try {
    localStorage.setItem(key, value.toString());
  } catch (e) {
    console.error(e);
  }
};

const removeFromLocalStorage = (key: LocalStorageKey) => localStorage.removeItem(key);
