import React from 'react';

interface Props {
  size?: number;
  className?: string;
}

export const WarningIcon = ({ size = 16, ...props }: Props) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.64 2.235l5.386 9.53c.906 1.602.149 2.901-1.695 2.901H2.665c-1.84 0-2.6-1.294-1.691-2.902L6.36 2.235c.906-1.602 2.371-1.608 3.28 0zM8 11a1 1 0 100 2 1 1 0 000-2zm.667-6.333H7.333v4.666h1.334V4.667z"
      clipRule="evenodd"
    ></path>
  </svg>
);
