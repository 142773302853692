import React from 'react';

const Edit = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M-1-1h18v18H-1z" />
      <path
        d="M2.75 10.25l3 3 8.332-8.332a2.005 2.005 0 0 0 .006-2.83l-.176-.176a2.006 2.006 0 0 0-2.83.006L2.75 10.25zM10.25 3.5l2.625 2.625"
        stroke="#FFF"
      />
      <path stroke="#FFF" d="M3.5 9.5l-1.125 1.125L1.25 14.75l4.125-1.125L6.5 12.5" />
      <path fill="#001E62" d="M3.875 12.5L6.5 15.125l-2.063.75-2.062-2.25z" />
    </g>
  </svg>
);

export default Edit;
