export const PHONE_NUMBER_LENGTH = { MIN: 3, MAX: 11 };

export const validatePhoneNumber = (phoneNumber?: string): boolean =>
  isLengthValid(phoneNumber) && isAlphaNumeric(phoneNumber) && containsLetter(phoneNumber);

const isEmpty = (value?: string): boolean => !value;

const isLengthValid = (value?: string): boolean =>
  !!value && value.length >= PHONE_NUMBER_LENGTH.MIN && value.length <= PHONE_NUMBER_LENGTH.MAX;

const containsLetter = (value?: string): boolean => !!value && /[a-z]/i.test(value);

const isAlphaNumeric = (value?: string): boolean => !!value && /^[a-zA-Z0-9 +\-_&]+$/i.test(value);

export const getPhoneNumberValidationError = (value: string): string | undefined => {
  if (isEmpty(value)) {
    return undefined;
  }

  if (!isAlphaNumeric(value)) {
    return Wahanda.Template.render(Wahanda.lang.shared.errors.notValidSmsOriginatorPermittedChars);
  }

  if (!isLengthValid(value) || !containsLetter(value)) {
    return Wahanda.Template.render(Wahanda.lang.shared.errors.notValidSmsOriginatorLength, {
      min: PHONE_NUMBER_LENGTH.MIN,
      max: PHONE_NUMBER_LENGTH.MAX,
    });
  }

  return undefined;
};
