import React from 'react';

const flagDK = () => (
  <svg width="16" height="12" viewBox="0 0 21 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#FFFFFF" offset="0%"></stop>
        <stop stopColor="#F0F0F0" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
        <stop stopColor="#EF264D" offset="0%"></stop>
        <stop stopColor="#E1143C" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="DK">
        <rect
          id="FlagBackground"
          fill="url(#linearGradient-1)"
          x="0"
          y="0"
          width="21"
          height="15"
        ></rect>
        <rect
          id="Mask-Copy"
          fill="url(#linearGradient-2)"
          x="0"
          y="0"
          width="21"
          height="15"
        ></rect>
        <polygon
          id="Rectangle-2"
          fill="url(#linearGradient-1)"
          points="0 9 6 9 6 15 9 15 9 9 21 9 21 6 9 6 9 0 6 0 6 6 0 6"
        ></polygon>
      </g>
    </g>
  </svg>
);

export default flagDK;
