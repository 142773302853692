import { connect } from 'react-redux';
import App from 'common/backbone-app';
import MonthlySalesPeriodReport from './index';

const mapStateToProps = () => ({
  locale: App.getClientLocale(),
});
const connector = connect(mapStateToProps);

export default connector(MonthlySalesPeriodReport);
