/**
 * Collection for Wahandas purposes.
 */
BackboneEx.Collection.Base = Backbone.Collection.extend({
	/**
	 * Current visible venue.
	 */
	venueId: null,
	/**
	 * Url template in MustacheJS format, which utilizes the {{venueId}} param.
	 */
	urlTemplate: '',
	
	url: function() {
		var base = Wahanda.Template.render(this.urlTemplate, { venueId: this.venueId || App.getVenueId() });
		return App.Api.wsUrl(base);
	},
	
	/**
	 * Sets a new venue id.
	 * This is optional, as the venueId is retrieved automatically if not set.
	 * 
	 * @param int id
	 * @return BackboneEx.Collection.Base
	 */
	setVenueId: function(id) {
		this.venueId = id;
		return this;
	},

  async fetchWithPromise(...args) {
    return new Promise((resolve, reject) => {
      this.fetch(...args)
				.done(resolve)
				.fail(reject);
    });
  },
});
