import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import Wahanda from 'common/wahanda';
import { Initializer } from 'common/types/initializers';
import { CustomerFormDialog } from './container';

const container = Wahanda.Dialogs.getReactContainer();
const store = storeBuilder();
interface Params {
  id?: number;
  customerData?: object;
  fieldToFocus?: string;
}

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

const onClose = () => {
  destroy(container);
};
const onSubmit = () => {
  onClose();
};

export const CustomerFormDialogInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(container),
  render: () => {
    ReactDOM.render(
      <Provider store={store}>
        <CustomerFormDialog
          customerId={get(params, 'id')}
          customerData={get(params, 'customerData')}
          fieldToFocus={get(params, 'fieldToFocus')}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </Provider>,
      container,
    );
  },
});
