import React from 'react';

type TickProps = {
  colour: any;
  isLight?: boolean;
  small?: boolean;
};
const Tick: React.SFC<TickProps> = (props) => {
  if (props.small) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8">
        <g fill="none" fillRule="evenodd">
          <path d="M-1-2h12v12H-1z" />
          <path
            fill={props.colour}
            fillRule="nonzero"
            d="M3.707 4.293L8 0l1.414 1.414-5.707 5.707L0 3.414 1.414 2z"
          />
        </g>
      </svg>
    );
  }
  if (props.isLight) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.21 8.06">
        <path fill="none" stroke={props.colour} d="M10.85.35l-7 7-3.5-3.5" />
      </svg>
    );
  }
  return (
    <svg width="14" height="11" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M-1-3h16v16H-1z" />
        <path stroke={props.colour} strokeWidth="2" d="M1 5l4 4 8-8" />
      </g>
    </svg>
  );
};
Tick.defaultProps = {
  isLight: false,
  small: false,
};

export default Tick;
