import { call, put } from 'redux-saga/effects';
import { get } from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { SignatureResponse } from './models';
import {
  GetSignatureRequestAction,
  getSignatureSuccessAction,
  getSignatureFailureAction,
} from './actions';

export function* getSignatures(action: GetSignatureRequestAction) {
  const { signature, source } = action.payload;
  try {
    const result: SignatureResponse = yield call(
      get,
      apiUrl('SIGNATURE_SEARCH', { signature, source }),
    );
    yield put(getSignatureSuccessAction({ ...result }));
  } catch {
    yield put(getSignatureFailureAction());
  }
}
