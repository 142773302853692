import React, { useMemo } from 'react';
import { ClientFormsCreateStep1 } from './ClientFormsCreateStep1';
import { ClientFormsCreateStep2 } from './ClientFormsCreateStep2';
import { useClientFormsMutations } from './useClientFormsMutations';
import { WizardModal } from 'components/common/WizardModal';
import { useFormTemplates } from '../FormTemplates/useFormTemplates';
import Wahanda from 'common/wahanda';
import { ClientFormsCreateStep3 } from './ClientFormsCreateStep3';
import { getClientFormCreateJsonSchemaTemplate } from './util';

const lang = Wahanda.lang.marketing.clientForms;

type ClientFormsCreateProps = {
  onClose: <T>(createdFormTitle?: T) => void;
};

export const ClientFormsCreate = ({ onClose }: ClientFormsCreateProps) => {
  const {
    formTitle,
    setFormTitle,
    htmlText,
    selectedServices,
    handleChangeHtmlText,
    handleChangeSelectedServices,
    handleFormSubmit,
    goBackToFormsList,
    isError,
    confirmCreation,
    setConfirmCreation,
  } = useClientFormsMutations({
    onCloseCallback: onClose,
  });

  const { formTemplates } = useFormTemplates();

  const formNameAlreadyInUse = (formTemplates || [])?.some((t) => t.title === formTitle);
  const validateStep1 = () => {
    if (!formTitle || !htmlText || formNameAlreadyInUse) return true;

    return false;
  };

  const validateStep2 = () => !selectedServices.length;

  const validateStep3 = () => !confirmCreation;

  const jsonSchema = useMemo(
    () =>
      getClientFormCreateJsonSchemaTemplate(
        { title: formTitle as string, htmlText },
        { preview: true },
      ),
    [formTitle, htmlText],
  );

  const steps = [
    {
      title: lang.createFormTitle,
      description: lang.createFormDescription,
      element: (
        <ClientFormsCreateStep1
          formTitle={formTitle}
          onChangeFormTitle={setFormTitle}
          htmlDescription={htmlText}
          onChangeHtmlDescription={handleChangeHtmlText}
          formNameAlreadyInUse={formNameAlreadyInUse}
        />
      ),
      nextStepDisabled: validateStep1(),
      nextStepLabel: lang.linkTreatments,
    },
    {
      title: lang.selectTreatments,
      description: lang.selectTreatmentsDescription,
      element: (
        <ClientFormsCreateStep2
          selectedServices={selectedServices}
          onChangeSelectedServices={handleChangeSelectedServices}
          displayError={isError}
        />
      ),
      nextStepDisabled: validateStep2(),
      nextStepLabel: lang.reviewFormStep,
    },
    {
      title: lang.reviewFormTitle,
      description: lang.reviewFormDescription,
      element: (
        <ClientFormsCreateStep3
          schema={jsonSchema}
          onChangeConfirmCreation={setConfirmCreation}
          confirmCreation={confirmCreation}
          displayError={isError}
        />
      ),
      nextStepDisabled: validateStep3(),
    },
  ];

  return (
    <>
      <WizardModal
        steps={steps}
        onClose={goBackToFormsList}
        onSubmit={handleFormSubmit}
        lastStepLocale={lang.saveAndActivate}
        showSidebar
      />
    </>
  );
};
