import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Request extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-request', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 12 12"
      >
        <path d="M6.5,0.29 L10.696,2.711 C11.0053932,2.88962826 11.196,3.21974349 11.196,3.577 L11.196,8.423 C11.196,8.78025651 11.0053932,9.11037174 10.696,9.289 L6.5,11.71 C6.19059892,11.8886328 5.80940108,11.8886328 5.5,11.71 L1.304,9.29 C0.994606783,9.11137174 0.804,8.78125651 0.804,8.424 L0.804,3.577 C0.804,3.21974349 0.994606783,2.88962826 1.304,2.711 L5.5,0.29 C5.80940108,0.111367205 6.19059892,0.111367205 6.5,0.29 Z M6.003,7.736 C5.85285395,7.73259133 5.70677512,7.78496593 5.593,7.883 C5.464,8.002 5.4,8.152 5.4,8.332 C5.39699689,8.48351791 5.45251152,8.63036306 5.555,8.742 C5.64607542,8.84358175 5.76903266,8.90925519 5.90186655,8.92949131 L6.003,8.936 C6.15281228,8.93824058 6.29677267,8.88309158 6.406,8.782 C6.53284721,8.66721855 6.60363002,8.50303163 6.6,8.332 C6.60389979,8.18271062 6.54811413,8.0380278 6.445,7.93 C6.33452932,7.80189518 6.17207203,7.73059039 6.003,7.736 Z M6.5,3 L5.5,3 L5.5,4.936 L5.697,7.056 L6.303,7.056 L6.5,4.936 L6.5,3 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Request);
