/**
 * @mixin BackboneEx.Mixin.View.Datepicker
 */
import { trackEvent } from 'common/analytics';

App.Views.Forms.Calendar.SpaAvailability = BackboneEx.View.Dialog.ValidatingForm.extend({
  events: {
    'click .button-cancel': 'close',
    'change input[name=spa-availability-closed]': 'toggleInventoryInput',
  },
  options: {
    title: Wahanda.lang.calendar.spaDays.spaAvailabilityForm.title,
    width: 460,
    dateFrom: null,
    dateTo: null,
  },

  fieldPrefix: /^spa\-availability\-/,
  templateId: 'spa-days-availability-form-template',
  elementToFocus: '#spa-availability-inventoryAvailable',

  onDialogOpen: function () {
    BackboneEx.View.Dialog.ValidatingForm.prototype.onDialogOpen.call(this);
    $.dateFieldAlign.connect({
      first: this.$('#spa-availability-dateFrom').blur().venueDatepicker(),
      later: this.$('#spa-availability-dateTo').venueDatepicker(),
    });
  },

  postInitialize: function () {
    trackEvent('calendar', 'view', 'spa-availability-modal');

    this.model = new App.Models.SpaAvailabilitySaver();

    if (this._isDatesPreset()) {
      this.weekdaysView = new App.Views.Partials.WeekdayCheckboxes({
        el: this.$('.weekdays'),
        lastCanBeDeselectedText:
          Wahanda.lang.calendar.spaDays.spaAvailabilityForm.canNotDeselectLastDay,
      });
    }
  },

  initForm: function () {
    this.setDefaultValues();
    if (this._isDatesPreset()) {
      this.weekdaysView.render();
      this.$('.b-preset-dates').hide();
    } else {
      this.renderDayRanges();
      this.$('.b-manual-dates').hide();
    }
    if (this.options.inventoryAvailable) {
      this.$('#spa-availability-inventoryAvailable').val(this.options.inventoryAvailable);
    }
  },

  renderDayRanges: function () {
    this.$('.v-dates').text(
      Wahanda.lang.date.months[this.options.date.getMonth()] +
        ' ' +
        Wahanda.Util.combineRanges(this.options.days).join(', '),
    );
  },

  setDefaultValues: function () {
    if (this.options.dateFrom) {
      this.$('#spa-availability-dateFrom').val(
        Wahanda.Date.formatToDefaultDate(this.options.dateFrom),
      );
    }
    if (this.options.dateTo) {
      this.$('#spa-availability-dateTo').val(Wahanda.Date.formatToDefaultDate(this.options.dateTo));
    }
  },

  toggleInventoryInput: function () {
    var isAvailable = this.$('#spa-availability-closed-no').is(':checked');
    this.$('#spa-availability-inventoryAvailable').toggleFormElements(isAvailable);
  },

  setValidation: function () {
    this._setDefaultValidation();
  },

  getModelValues: function () {
    var formValues = BackboneEx.View.Dialog.ValidatingForm.prototype.getModelValues.call(this);
    var values = {
      inventoryAvailable: formValues.inventoryAvailable || null,
      closed: formValues.closed === 'true',
    };

    if (this._isDatesPreset()) {
      values.dateRange = {
        dateFrom: Wahanda.Date.toApiString(
          this.$('#spa-availability-dateFrom').datepicker('getDate'),
        ),
        dateTo: Wahanda.Date.toApiString(this.$('#spa-availability-dateTo').datepicker('getDate')),
        weekdays: this.weekdaysView.getValues(),
      };
    } else {
      values.dates = Wahanda.Date.getMonthDateApiStrings(this.options.date, this.options.days);
    }
    return values;
  },

  _afterSaveCallback: function () {
    BackboneEx.View.Dialog.ValidatingForm.prototype._afterSaveCallback.call(this);

    var dates = this.model.get('dates') || this.model.get('dateRange');
    App.trigger(Wahanda.Event.CALENDAR_AVAILABILITY_SAVED, {
      forDates: dates,
      type: 'spa-day',
      source: 'availability',
    });
  },

  _isDatesPreset: function () {
    return this.options.dateFrom && null == this.options.days;
  },
});

BackboneEx.Mixin.extendView(
  App.Views.Forms.Calendar.SpaAvailability,
  BackboneEx.Mixin.View.Datepicker,
);
