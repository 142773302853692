import { connect } from 'react-redux';
import { statusCheckRequest } from './actions';
import { InstallStatus as Component } from './InstallStatus';

const mapStateToProps = ({ marketing }) => {
  return {
    status: marketing.fbeIntegration.installStatus.status,
  };
};

const mapDispatchToProps = {
  statusCheckRequest,
};

export const InstallStatus = connect(mapStateToProps, mapDispatchToProps)(Component);
