import React from 'react';
import Wahanda from 'common/wahanda';

export default class Loading extends React.Component<{}, {}> {
  render() {
    return (
      <div className="small-loader b-loader">
        <span className="icon" />
        <span className="text">{Wahanda.lang.shared.loading}</span>
      </div>
    );
  }
}
