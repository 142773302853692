import { connect } from 'react-redux';
import { BankPayment as BankPaymentComponent } from './BankPayment';

const mapStateToProps = ({
  bankDetails: { bankName, bankCode, bankAccountName, bankAccountNumber },
}) => ({
  bankName,
  bankCode,
  bankAccountName,
  bankAccountNumber,
});

const connector = connect(mapStateToProps);

export const BankPayment = connector(BankPaymentComponent);
