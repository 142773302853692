import React from 'react';
import classnames from 'classnames/bind';
import Wahanda from 'common/wahanda';
import { CampaignCardStatus } from './CampaignCardStatus';
import { STATUS } from '../../CampaignList';
import style from './CampaignCard.scss';

const lang = Wahanda.lang.marketing.campaignSelection;
const styleBoundClassnames = classnames.bind(style);
interface ICampaignCardProps extends React.HTMLAttributes<Element> {
  isVisible: boolean;
  name: string;
  description: string;
  id: string;
  status: any;
  onCampaignSelect: (...args: any[]) => any;
}
class CampaignCard extends React.Component<ICampaignCardProps, {}> {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (this.isEnabled()) {
      this.props.onCampaignSelect(this.props.id);
    }
  }

  getLabelText(status) {
    switch (status) {
      case STATUS.ACTIVE:
        return lang.available;
      case STATUS.COMING_SOON:
        return lang.comingSoon;
      default:
        return '';
    }
  }

  isEnabled = () => this.props.status === STATUS.ACTIVE;

  isDisabled = () => [STATUS.COMING_SOON].includes(this.props.status);

  isExpired = () => [STATUS.EXPIRED].includes(this.props.status);

  render() {
    const { name, description, status, isVisible } = this.props;
    const label = this.getLabelText(status);
    const classes = {
      container: true,
      CardDisabled: this.isDisabled(),
      christmas: false,
    };
    if (!isVisible || this.isExpired()) {
      return null;
    }
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div className={styleBoundClassnames(classes)} onClick={this.onClick}>
        <h2 className={style.header}>{name}</h2>
        <div className={style.desc}>{description}</div>
        <CampaignCardStatus isDisabled={this.isDisabled()} text={label} />
      </div>
    );
  }
}

export default CampaignCard;
