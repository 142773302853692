import React from 'react';
import classnames from 'classnames';
import { Spinner } from 'components/common/Spinner';
import { Chevron, Close, Search } from 'components/common/Icon';
import { InputContext } from '../InputContext';
import { InputSizeType } from '../utils/types';
import styleLegacy from './InputDecorators.scss';
import styleNewLook from './InputDecorators.newLook.scss';

interface Props {
  children?: React.ReactNode;
  dataTest?: string | null;
  isClearable?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  isSelect?: boolean;
  onClick?: () => void;
  onClear?: () => void;
  onMouseDown?: () => void;
}

export const InputDecorators = ({
  children,
  dataTest = null,
  isClearable,
  isLoading,
  isSearchable,
  isSelect,
  onClick,
  onMouseDown,
  onClear,
}: Props) => {
  const {
    size = InputSizeType.DEFAULT,
    disabled,
    isFocused,
    fullBorder,
    newLook,
  } = React.useContext(InputContext);

  const style = newLook ? styleNewLook : styleLegacy;

  const containerClassName = classnames(style.container, {
    [style.focused]: isFocused,
    [style.fullBorder]: fullBorder,
  });
  const arrowClassName = classnames(style.arrow, style[size], {
    [style.marginLeft]: isClearable || isLoading,
  });
  const arrowSize = size === 'large' ? 'medium' : 'small';
  const searchIconSize = !newLook && (fullBorder || size === 'large') ? 'medium' : 'small';

  return (
    <div className={containerClassName} onClick={onClick} onMouseDown={onMouseDown}>
      {isSearchable && <Search className={style.searchIcon} size={searchIconSize} />}
      {!!children && <div className={style.children}>{children}</div>}
      {isLoading && (
        <div className={style.loadSpinner} data-test={`decorator-${dataTest}-spinner`}>
          <Spinner dark size={arrowSize} />
        </div>
      )}
      {isClearable && (
        <div
          className={style.clearable}
          onClick={disabled ? undefined : onClear}
          data-test={`decorator-${dataTest}-clear`}
        >
          <Close className={style.close} size="tiny" />
        </div>
      )}
      {isSelect && !disabled && <Chevron className={arrowClassName} size={arrowSize} />}
    </div>
  );
};
