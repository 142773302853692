import React from 'react';
import classnames from 'classnames';
import Dialog from 'components/common/react-dialog';
import Footer from 'components/common/dialog/footer';

interface ICalendarEventEditorDialogsBaseProps extends React.HTMLAttributes<Element> {
  buttons?: {};
  title?: any;
  intro?: any;
  onClose?: (...args: any[]) => any;
  withIcon?: boolean;
  classes?: {};
}

export default class CalendarEventEditorDialogsBase extends React.Component<
  ICalendarEventEditorDialogsBaseProps,
  {}
> {
  render() {
    return (
      <Dialog
        dataTest="calendar-editor-base-modal"
        width={445}
        hideTitlebar
        classes={this.props.classes}
        onClose={this.props.onClose}
      >
        <div className={classnames('title', { 'with-icon': this.props.withIcon })}>
          {this.props.title}
        </div>
        <div className="intro">{this.props.intro}</div>
        <Footer buttons={this.props.buttons} fullWidth />
      </Dialog>
    );
  }
}
