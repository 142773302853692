import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Heart extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-heart', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 24 24"
          >
            <path
              d="M16 3c3.238 0 6 2.589 6 7 0 4.419-6.793 11-10 11S2 14.419 2 10c0-4.411 2.762-7 6-7 1.703 0 2.707.451 4 1.617C13.293 3.451 14.297 3 16 3zM8 5c-2.162 0-4 1.723-4 5 0 3.3 5.884 9 8 9 2.116 0 8-5.7 8-9 0-3.277-1.838-5-4-5-1.334 0-1.964.378-3.293 1.707L12 7.414l-.707-.707C9.963 5.377 9.333 5 8 5z"
              transform="translate(-406 -722) translate(368 684) translate(32 32) translate(6 6)"
            />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Heart);
