import { combineReducers } from 'redux';
import emailCampaign from './EmailCampaign/reducers';
import clientApp from './ClientApp/reducers';
import partnerSite from './PartnerSite/reducer';
import { backToBusiness } from './EmailCampaign/BackToBusiness/reducer';
import { fbeIntegration } from './FacebookBusinessExtension/reducer';
import { automatedMessaging } from './AutomatedMessaging/reducer';

export default combineReducers({
  emailCampaign,
  clientApp,
  partnerSite,
  backToBusiness,
  fbeIntegration,
  automatedMessaging,
});
