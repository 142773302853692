import Wahanda from 'common/wahanda';
import appsFlyerKey from 'config/appsFlyerKeys';
import AppsFlyerBase from 'vendor/appsflyer-banner.min';

class AFBanner {
  constructor() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'Banner' does not exist on type 'AFBanner... Remove this comment to see the full error message
    this.Banner = new AppsFlyerBase();
  }

  init = (domId) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'Banner' does not exist on type 'AFBanner... Remove this comment to see the full error message
    this.Banner.init(domId, {
      show_only_mobile: true,
      onelink_id: appsFlyerKey.onelink_id,
      media_source: appsFlyerKey.media_source,
      title: Wahanda.lang.mobileBanner.title,
      subtitle: Wahanda.lang.mobileBanner.subtitle,
      call_to_action: Wahanda.lang.mobileBanner.action,
      app_icon: '/assets/app-banner/tw-logo.svg',
    });
  };
}

export default AFBanner;
