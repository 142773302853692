import React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'components/common/Tooltip';
import { Avatar } from 'components/common/Avatar';
import { Close } from 'components/common/Icon';

import { ServiceEmployee } from '../../store/types';
import { EMPLOYEES_LIMIT } from '../../utils/constants';

import style from './EmployeeAvatars.scss';

export interface EmployeeAvatarsProps {
  employees: number[];
  employeesCollection: ServiceEmployee[];
  onChange: (bool: boolean, id: number) => void;
}

export const EmployeeAvatars = ({
  employees,
  employeesCollection,
  onChange,
}: EmployeeAvatarsProps) => {
  const hasOnlyOneEmployee = employeesCollection.length === 1;
  const maxEmployeesCollection = employees.length
    ? employeesCollection
        .filter((employee) => employees.includes(employee.id))
        .slice(0, EMPLOYEES_LIMIT)
        .reverse()
    : [];
  const employeeClass = classnames(style.employee, {
    [style.showHover]: !hasOnlyOneEmployee,
  });

  return (
    <>
      {maxEmployeesCollection.map((employee) => {
        const handleChange = () => {
          if (!hasOnlyOneEmployee) {
            onChange(false, employee.id);
          }
        };

        return (
          <div
            role="button"
            tabIndex={-1}
            key={employee.id}
            className={employeeClass}
            onClick={handleChange}
          >
            <Tooltip tooltip={employee.name} placement="top">
              <Avatar text={employee.name} src={employee.image?.uris['50x50']} />
              <Close className={style.closeIcon} size="tiny" />
            </Tooltip>
          </div>
        );
      })}
    </>
  );
};
