import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Sync extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-sync', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M19.4,9 C20.2751792,11.1273029 20.1921798,13.5284117 19.1721955,15.5902042 C18.1522112,17.6519968 16.2939798,19.174885 14.072,19.77 L13.999,19.79 L13.999,21.866 C13.999151,22.0506113 13.8975644,22.2202845 13.7347648,22.3073342 C13.5719652,22.3943838 13.3744456,22.384644 13.221,22.282 L8.623,19.217 C8.48392244,19.1242634 8.40038782,18.9681605 8.40038782,18.801 C8.40038782,18.6338395 8.48392244,18.4777366 8.623,18.385 L13.221,15.319 C13.3744456,15.216356 13.5719652,15.2066162 13.7347648,15.2936658 C13.8975644,15.3807155 13.999151,15.5503887 13.999,15.735 L13.999,17.702 C17.0833152,16.6127154 18.7268027,13.2536848 17.694,10.15 L19.4,9 Z M10.5,1.674 C10.5985958,1.674 10.6949683,1.70330138 10.777,1.758 L15.376,4.824 C15.5150776,4.91673659 15.5986122,5.07283947 15.5986122,5.24 C15.5986122,5.40716053 15.5150776,5.56326341 15.376,5.656 L10.777,8.722 C10.6235873,8.82408886 10.4264567,8.83351912 10.2639994,8.74654072 C10.1015422,8.65956232 10.0000952,8.49027586 10,8.306 L10,6.339 C6.91580566,7.42787576 5.27193631,10.7863329 6.304,13.89 L4.6,15.04 C3.71950105,12.9005652 3.80843201,10.4846922 4.84384472,8.4157842 C5.87925743,6.34687621 7.75972399,4.8276012 10,4.25 L10,2.174 C10,1.89785763 10.2238576,1.674 10.5,1.674 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Sync);
