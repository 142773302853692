import React, { Component } from 'react';
import SelectSupplierDialog from '../SelectSupplierDialog/container';
import NewOrderDialog from '../NewOrderDialog/container';
import OrderDetailsDialog from '../OrderDetailsDialog/container';

interface INewOrderProps extends React.HTMLAttributes<Element> {
  onClose: (...args: any[]) => any;
  newOrderId?: number;
  isSupplierSelected?: boolean;
  actions: {
    newProductOrderReset: (...args: any[]) => any;
  };
  newProductOrderReset?: any;
}

export default class NewOrder extends Component<INewOrderProps, {}> {
  static defaultProps = {
    newOrderId: null,
    isSupplierSelected: false,
  };

  onClose = () => {
    this.props.actions.newProductOrderReset();
    this.props.onClose();
  };

  renderNewOrderFlow = () => {
    if (!this.props.isSupplierSelected) {
      return <SelectSupplierDialog onClose={this.onClose} />;
    }
    // @ts-expect-error ts-migrate(2322) FIXME: Type '() => void' is not assignable to type 'never... Remove this comment to see the full error message
    return <NewOrderDialog onClose={this.onClose} />;
  };

  renderOrderDetaisDialog = () => (
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'never'... Remove this comment to see the full error message
    <OrderDetailsDialog orderId={this.props.newOrderId} onClose={this.onClose} />
  );

  render() {
    if (!this.props.newOrderId) {
      return this.renderNewOrderFlow();
    }
    return this.renderOrderDetaisDialog();
  }
}
