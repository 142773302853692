import React from 'react';
import Wahanda from 'common/wahanda';

import GooglePlay from './images/GooglePlay';
import AppStoreButton from './images/AppStore';
import style from './AppStore.scss';
import { ClientAppAnalytics as Analytics } from '../tracking';

const lang = Wahanda.lang.marketing.clientApp;

const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.wahanda.marketplace';
const APP_STORE_URL = 'https://itunes.apple.com/lt/app/treatwell-nails-hair-wax/id814443140?mt=8';

export const AppStore = () => {
  return (
    <div>
      <img className={style.banner} src={lang.appIllustration} alt={lang.heading} />
      <div className={style.appStoreButtons}>
        <a
          href={GOOGLE_PLAY_URL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={Analytics.trackGooglePlay}
        >
          <GooglePlay />
        </a>
        <a
          href={APP_STORE_URL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={Analytics.trackAppStore}
        >
          <AppStoreButton />
        </a>
      </div>
    </div>
  );
};
