import { action, payload } from 'ts-action';

interface Features {
  features: string[];
}

export const setFeatures = action('SET_FEATURES', payload<Features>());

export const requestFeatures = action('REQUEST_FEATURES', payload<{ venueId: number }>());

export const requestFeaturesSuccess = action('REQUEST_FEATURES_SUCCESS', payload<Features>());
