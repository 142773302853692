import { connect } from 'react-redux';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { TeamLayout as Container } from './';

const mapStateToProps = () => ({
  isRotaTabPermitted:
    App.isFeatureSupported('venue-employee-rota') && Wahanda.Permissions.useCalendar(),
  isTeamTabPermitted:
    Wahanda.Permissions.editUserPermissions() || Wahanda.Permissions.addTherapists(),
});

export const TeamLayout = connect(mapStateToProps)(Container);
