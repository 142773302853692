import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Scissors extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-scissors', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M11.8944272,13.4472136 L10.1055728,12.5527864 L15.1055728,2.5527864 L16.8944272,3.4472136 L11.8944272,13.4472136 Z M5,16 C3.34314575,16 2,14.6568542 2,13 C2,11.3431458 3.34314575,10 5,10 C6.65685425,10 8,11.3431458 8,13 C8,14.6568542 6.65685425,16 5,16 Z M5,14 C5.55228475,14 6,13.5522847 6,13 C6,12.4477153 5.55228475,12 5,12 C4.44771525,12 4,12.4477153 4,13 C4,13.5522847 4.44771525,14 5,14 Z M11,16 C12.6568542,16 14,17.3431458 14,19 C14,20.6568542 12.6568542,22 11,22 C9.34314575,22 8,20.6568542 8,19 C8,17.3431458 9.34314575,16 11,16 Z M11,18 C10.4477153,18 10,18.4477153 10,19 C10,19.5522847 10.4477153,20 11,20 C11.5522847,20 12,19.5522847 12,19 C12,18.4477153 11.5522847,18 11,18 Z M20.5527864,7.10557281 L21.4472136,8.89442719 L11.4472136,13.8944272 C11.3133515,13.9613582 11.0775273,14.0752044 10.7628082,14.2204594 C10.2402149,14.4616563 9.68560673,14.7027903 9.12139068,14.9284767 C8.65277009,15.1159249 8.19717495,15.2846639 7.76067221,15.4301648 C6.66698568,15.794727 5.74325698,16 5,16 L5,14 C5.47896524,14 6.2219032,13.8349027 7.12821668,13.5327982 C7.52504728,13.4005213 7.94445213,13.2451862 8.37860932,13.0715233 C8.90814327,12.8597097 9.43166013,12.6320937 9.92469182,12.4045406 C10.1702384,12.2912114 10.3620661,12.1994733 10.4863974,12.1384373 L20.5527864,7.10557281 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Scissors);
