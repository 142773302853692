import { ActionUnionTypes } from 'reduxStore/actionCreator';
import {
  BACK_TO_BUSINESS_INIT_STATUS_SUCCESS,
  BACK_TO_BUSINESS_INIT_STATUS_FAILURE,
} from 'reduxStore/actionsConst';

import * as backToBusinessActions from './actions';

export interface BackToBusinessState {
  currentReach?: number;
  emailReach?: number;
  smsReach?: number;
  potentialReach?: number;
  successfullyReached?: number;
  alreadySent?: boolean;
  isLoading: boolean;
}

export const initialState: BackToBusinessState = {
  isLoading: true,
};

export const backToBusiness = (
  state = initialState,
  action: ActionUnionTypes<typeof backToBusinessActions>,
): BackToBusinessState => {
  switch (action.type) {
    case BACK_TO_BUSINESS_INIT_STATUS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case BACK_TO_BUSINESS_INIT_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
