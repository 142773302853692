import React from 'react';
import { partial } from 'mori';
import Wahanda from 'common/wahanda';
import { trackEvent } from 'common/analytics';
import { ProgressBar } from 'components/common/ProgressBar';
import style from '../style.css';
import SubBlock, { ICON_EXCLAMATION, ICON_OK } from '../common/SubBlock';
import SubBlockText, { makeBold } from '../common/SubBlockText';
import maybeBuildButton from '../common/button-structure';

const LANG = Wahanda.lang.dashboard.trafficLights.bookings;
interface IBookingsProps extends React.HTMLAttributes<Element> {
  value?: number;
  threshold?: number;
}

export default class Bookings extends React.Component<IBookingsProps, {}> {
  render() {
    const { value, threshold } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const isValid = value >= threshold;
    const texts = isValid ? LANG.aboveThreshold : LANG.belowThreshold;
    const button = maybeBuildButton({
      title: Wahanda.lang.dashboard.trafficLights.shared.learnMore,
      link: texts.link,
      onClick: partial(trackEvent, 'dashboard', 'click', 'scoring-button'),
    });
    const goalText = Wahanda.Template.render(texts.goal, { threshold });
    return (
      <SubBlock title={texts.title} icon={isValid ? ICON_OK : ICON_EXCLAMATION}>
        <SubBlockText button={button}>
          {texts.intro}
          <br />
          {makeBold(`${Wahanda.lang.dashboard.trafficLights.shared.goal}:`)} {goalText}
        </SubBlockText>
        <div className={style.progressBar}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type 'number... Remove this comment to see the full error message */}
          <ProgressBar colour={isValid ? 'default' : 'danger'} value={value} label={value} />
        </div>
      </SubBlock>
    );
  }
}
