import React from 'react';
import Wahanda from 'common/wahanda';
import { Spinner } from 'components/common/Spinner';
import style from './InstallStatus.scss';
import { FBEInstallStatus } from './types';
import { NotInstalled } from './NotInstalled';
import { Installed } from './Installed';

const lang = Wahanda.lang.marketing.facebookBusinessExtension;

export interface Props {
  statusCheckRequest: () => void;
  status: FBEInstallStatus;
}

export const InstallStatus: React.FC<Props> = ({ status, statusCheckRequest }) => {
  React.useEffect(() => {
    statusCheckRequest();
  }, []);
  const renderByStatus = () => {
    switch (status) {
      case FBEInstallStatus.INSTALLED:
      case FBEInstallStatus.UNINSTALLING:
        return <Installed />;
      case FBEInstallStatus.NOT_INSTALLED:
      case FBEInstallStatus.INSTALLING:
        return <NotInstalled />;
      case FBEInstallStatus.PENDING:
      default:
        return (
          <div className={style.spinner}>
            <Spinner dark />
          </div>
        );
    }
  };
  return (
    <>
      <div className={style.installStatus}>{renderByStatus()}</div>
      <div className={style.description}>{lang.installStatusDescription}</div>
    </>
  );
};
