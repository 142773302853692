import React from 'react';
import Cookie from 'js-cookie';
import { UDVAppAdvocacyBanner as Analytics } from 'common/analytics';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';

import { VenueId, CustomerData } from './types';
import SuccessOverlay from './SuccessOverlay';
import style from './UDVAppAdvocacyBanner.scss';
import { Notice } from 'components/common/Notice';

const lang = Wahanda.lang.inlineClientEditing;
const CLEAR_SUCCESS_OVERLAY_TIMEOUT = 2000;

export const getCookieKey = (id: string) => `do-not-show-appvocacy-banner-${id}`;

export interface Props {
  venueId: string;
  customerId: string;
  appointmentDateTime: string;
  isShowingSuccessOverlay: boolean;
  shouldShowBanner: boolean;
  actions: {
    requestVenueDetailsAction: (data: VenueId) => void;
    requestInviteToDownloadAppBySMSAction: (data: CustomerData) => void;
    hideSuccessOverlayAction: () => void;
    clearUDVAppAdvocacyBannerAction: () => void;
  };
}

interface State {
  hasBeenTracked: boolean;
  textInvitationSent: boolean;
}

export class UDVAppAdvocacyBanner extends React.PureComponent<Props, State> {
  private timer: number;

  constructor(props: Props) {
    super(props);

    this.state = {
      hasBeenTracked: false,
      textInvitationSent: false,
    };
    this.timer = 0;
  }

  public componentDidMount() {
    this.props.actions.requestVenueDetailsAction({
      venueId: this.props.venueId,
    });
  }

  public componentDidUpdate() {
    if (this.props.shouldShowBanner) {
      this.trackBannerViewed();
    }
  }

  public componentWillUnmount() {
    this.props.actions.clearUDVAppAdvocacyBannerAction();
    clearTimeout(this.timer);
  }

  public render() {
    const { shouldShowBanner, isShowingSuccessOverlay } = this.props;
    if (!shouldShowBanner || this.state.textInvitationSent) {
      return null;
    }
    if (isShowingSuccessOverlay) {
      this.timer = window.setTimeout(this.closeSuccessOverlay, CLEAR_SUCCESS_OVERLAY_TIMEOUT);
    }
    const textInviteButton = (
      <Button
        colour="pos"
        label={lang.buttons.textInvite}
        onClick={this.onSendInvite}
        size="small"
        variant="secondary"
      />
    );
    return (
      <>
        <Notice
          dataTest="udv-app-advocacy-banner"
          className={style.notice}
          message={lang.messages.appAdvocacy}
          type="info"
        >
          {textInviteButton}
        </Notice>
        {isShowingSuccessOverlay && <SuccessOverlay onClose={this.closeSuccessOverlay} />}
      </>
    );
  }

  private trackBannerViewed = () => {
    if (!this.state.hasBeenTracked) {
      Analytics.trackUDVAppAdvocacyBannerView();
      this.setState({ hasBeenTracked: true });
    }
  };

  private onSendInvite = () => {
    const { appointmentDateTime, customerId } = this.props;
    const now = moment();
    const delta = moment(appointmentDateTime).diff(now, 'minutes');
    Analytics.trackInviteBySmsFromBannerClick(delta);
    this.props.actions.requestInviteToDownloadAppBySMSAction({
      id: customerId,
    });
  };

  private closeSuccessOverlay = () => {
    const { actions, customerId } = this.props;
    actions.hideSuccessOverlayAction();
    Cookie.set(getCookieKey(customerId), 'true', {
      expires: moment().add(7, 'days').toDate(),
    });
    this.setState({ textInvitationSent: true });
  };
}
