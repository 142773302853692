import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clientsImportSelectFile } from '../../actions';
import { FileUpload as Content } from './FileUpload';

const mapStateToProps = ({ clients }) => ({
  selectedFileName: clients.clientsImport.selectedFileName,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      clientsImportSelectFile,
    },
    dispatch,
  ),
});

export const FileUpload = connect(mapStateToProps, mapDispatchToProps)(Content);
