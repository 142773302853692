import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Mail extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-mail', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M2,5.17571608 L2,12.0046024 C2,12.0014661 5.99771818,11.999932 13.9931545,12 C13.9970066,12 13.9992884,10.1666667 14,6.5 L16,6.5 L16,11.9986199 C16,13.106439 15.1005931,14 13.9931545,14 L2.00684547,14 C0.900368558,14 0,13.1093607 0,12.0046024 L0,3.99539757 C0,2.89890976 0.9029758,2 2.00684547,2 L13.9931545,2 C15.4857438,2 16.0170836,3.46061462 14.8565084,4.40802288 L8.00495585,10.001127 L2,5.17571608 Z M3.72978,4 L7.99504415,7.4274444 L12.1936635,4 L3.72978,4 Z" />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-mail', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 24 24"
          >
            <path d="M4,7.32876823 L4,17.9931545 C4,17.9976039 9.33323208,17.9998857 19.9996962,18 C20.0014534,18 20.0015547,15 20,9 L22,9 L22,18.0046024 C22,19.0980876 21.1061087,20 20.0066023,20 L3.99339768,20 C2.88959423,20 2,19.0984804 2,17.9931545 L2,6.00684547 C2,4.90081279 2.88928839,4 3.99339768,4 L20.0066023,4 C21.4853862,4 22.0071044,5.44275107 20.9125089,6.41572485 L13.4162188,13.0790938 C12.6292218,13.7786467 11.3835314,13.7893582 10.5888748,13.0940337 L4,7.32876823 Z M3.99555339,6 C3.99749586,6.00001788 3.99859291,6.00014661 3.99921095,6.00090177 L3.99555339,6 Z M5.51859226,6 L11.905884,11.5888803 C11.9389682,11.6178289 12.0508252,11.6168671 12.0874911,11.5842752 L18.3698007,6 L5.51859226,6 Z" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Mail);
