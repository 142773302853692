import React, { Component } from 'react';
import classnames from 'classnames';
import echarts from 'echarts';

import style from './EChart.scss';

const ACTION = {
  LEGEND_SELECT_CHANGED: 'legendselectchanged',
};

interface TooltipFormatterParams {
  color: string;
  seriesName: string;
  value: string;
  name: string;
}

export interface EChartProps {
  className?: string;
  eChartOptions?: {
    tooltip?: {
      trigger?: string;
      formatter?: (params: TooltipFormatterParams[]) => string;
    };
  };
  actions?: {
    onLegendSelectChanged?: (val: any) => void;
  };
}

export class EChart extends Component<EChartProps, {}> {
  chart: any;

  echartsDiv: HTMLDivElement;

  static defaultProps = {
    className: null,
    eChartOptions: null,
    actions: null,
  };

  constructor(props) {
    super(props);
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLDivElem... Remove this comment to see the full error message
    this.echartsDiv = null;
    this.chart = null;
  }

  componentDidMount = () => {
    this.chart = echarts.init(this.echartsDiv);
    this.chart.setOption(this.props.eChartOptions);
    this.addActionListeners();
    window.addEventListener('resize', this.onWindowResize);
  };

  componentDidUpdate = () => {
    this.chart.setOption(this.props.eChartOptions);
    this.chart.resize();
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onWindowResize);
    echarts.dispose(this.echartsDiv);
  };

  onWindowResize = () => {
    this.chart.resize();
  };

  addActionListeners = () => {
    if (this.chart == null) {
      return;
    }
    const { actions } = this.props;
    if (actions == null) {
      return;
    }
    if (actions.onLegendSelectChanged) {
      this.chart.on(ACTION.LEGEND_SELECT_CHANGED, actions.onLegendSelectChanged);
    }
  };

  render = () => (
    <div
      className={classnames(this.props.className, style.chart)}
      ref={(div) => {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLDivElem... Remove this comment to see the full error message
        this.echartsDiv = div;
      }}
    />
  );
}
