import React, { PureComponent } from 'react';

export default class flagAT extends PureComponent<{}, {}> {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 640 480">
        <g fillRule="evenodd">
          <path fill="#fff" d="M640 480H0V0h640z" />
          <path fill="#df0000" d="M640 480H0V320h640zm0-319.9H0V.1h640z" />
        </g>
      </svg>
    );
  }
}
