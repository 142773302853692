import classnames from 'classnames';
import React from 'react';
import { useForm, FormProvider, SubmitHandler, UseFormMethods } from 'react-hook-form';
import style from './Form.scss';
import { FieldValues } from 'react-hook-form/dist/types/form';

interface Props<T extends FieldValues> {
  onSubmit: SubmitHandler<T>;
  children: (methods: UseFormMethods<T>) => React.ReactNode;
  inModal?: boolean;
}

export const Form = <T extends Record<string, any> = Record<string, any>>({
  children,
  onSubmit,
  inModal = false,
}: Props<T>) => {
  const methods = useForm<T>();
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        className={classnames({
          [style.modalForm]: inModal,
        })}
      >
        {children(methods)}
      </form>
    </FormProvider>
  );
};

Form.displayName = 'Form';
