import fetchCalendar from 'services/calendar';
import {
  STATUS_CREATED,
  STATUS_CONFIRMED,
  STATUS_NOSHOW,
  STATUS_CHECKED_OUT,
} from 'common/appointment';

const toObjectByDate = (data) => {
  const byDate = {};

  Object.entries<any[]>(data).forEach(([typeKey, items]) => {
    if (!items) {
      return;
    }
    items.forEach((item) => {
      const date = item.appointmentDate || item.itemDate;

      if (!byDate[date]) {
        byDate[date] = {};
      }
      if (!byDate[date][typeKey]) {
        byDate[date][typeKey] = [];
      }
      byDate[date][typeKey].push(item);
    });
  });

  return byDate;
};

const fetchForRange = async ({ dateFrom, dateTo, venueId, utmSource }) =>
  fetchCalendar({
    dateFrom,
    dateTo,
    venueId,
    utmSource,
    appointmentStatusCodes: [STATUS_CREATED, STATUS_CONFIRMED, STATUS_NOSHOW, STATUS_CHECKED_OUT],
    include: ['appointments', 'blocks'],
  }).then(toObjectByDate);

export default fetchForRange;
