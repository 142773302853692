import { getEventByName } from './TrackingEvents';
import { SegmentTrackingProcessor } from './TrackingProcessor';

export class TrackingContainer {
  processor;

  constructor(processor) {
    this.processor = new processor();
  }

  fireEvent(eventName, args) {
    const getEventData = getEventByName(eventName);

    if (!getEventData) {
      console.error(`The event '${eventName}' does not exist!`);
      return;
    }

    const preparedData = getEventData(...args);

    App.trigger(Wahanda.Event.TRACKING_CONTAINER_FIRE_EVENT, eventName, preparedData);

    this.processor.send(eventName, preparedData);
  }
}

export const trackingContainer = new TrackingContainer(SegmentTrackingProcessor);
