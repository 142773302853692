const matchByValue = (datum, value) => datum.toLowerCase().includes(value);

const matchInArray = (bacon, value) =>
  Array.isArray(bacon) && bacon.some((datum) => matchByValue(datum, value));

const reduceTreatments = (value, treatments, { name, aliases, ...treatment }) =>
  matchByValue(name, value) || matchInArray(aliases, value)
    ? treatments.concat({
        ...treatment,
        name,
        aliases,
      })
    : treatments;

/*
 *  With the magic of JavaScript:
 *
 *  n + true
 *    coerces to:
 *      n + 1
 *
 *  n + false
 *    coerces to:
 *      n + 0
 *
 *  Suggested by Balys!
 */
const numberOfTreatments = (treatments, value) =>
  treatments.reduce((n, { aliases }) => n + matchInArray(aliases, value), 0);

const generateTreatments = (treatments, value) =>
  treatments.reduce(
    (treatmentsList, treatment) => reduceTreatments(value, treatmentsList, treatment),
    [],
  );

/*
 *  Prepare the value once by converting to
 *  lower case and trimming white space
 */
export const generateTreatmentsFor = (treatments, value = '') =>
  generateTreatments(treatments, value.toLowerCase().trim());

/*
 *  Prepare the value once by converting to
 *  lower case and trimming white space
 */
export const numberOfTreatmentsFor = (treatments, value = '') =>
  numberOfTreatments(treatments, value.toLowerCase().trim());
