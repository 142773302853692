import React from 'react';
import Wahanda from 'common/wahanda';
import { BOOKING_ACTOR } from 'common/consts';
import style from './AppointmentInfoBoxes.scss';

const lang = Wahanda.lang.calendar.appointments.cancellation;
interface Props {
  bookingActor: BOOKING_ACTOR;
  isPrepaid: boolean;
}

export const AppointmentInfoBoxes: React.FunctionComponent<Props> = ({
  bookingActor,
  isPrepaid = false,
}) => {
  const Box = ({ label, value }) => (
    <div className={style.box}>
      <div className={style.label}>{label}</div>
      <div className={style.value}>{value}</div>
    </div>
  );
  const bookingSourceTextMap = new Map([
    [BOOKING_ACTOR.CUSTOMER, lang.bookingSource.marketplace],
    [BOOKING_ACTOR.WIDGET, lang.bookingSource.widget],
    [BOOKING_ACTOR.SUPPLIER, lang.bookingSource.direct],
  ]);
  const bookingSourceText = bookingSourceTextMap.get(bookingActor);
  const paymentTypeText = isPrepaid ? lang.paymentType.prepaid : lang.paymentType.payAtVenue;
  return (
    <div className={style.boxContainer}>
      <Box label={lang.bookingSource.label} value={bookingSourceText} />
      <Box label={lang.paymentType.label} value={paymentTypeText} />
    </div>
  );
};
