import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class CalendarBlock extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-calendar-block', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path
          d="M6,6 L4,6 L4,4 L1.99991026,4 L1.99999882,17.5286104 C1.99999922,17.6841092 1.99999961,17.8409485 2,17.9991283 L5.70919067,17.9999624 C5.80429813,17.9999749 5.90123457,17.9999874 6,18 L6,20 L1.99754465,20 C0.892924251,20 0,19.106246 0,17.9991283 L0,4.00087166 C0,2.8953067 0.891937332,2 1.99980749,2 L4,2 L4,0 L6,0 L6,6 Z M17,15 L17,17 L11,17 L11,15 L17,15 Z M16,2 C17.1045695,2 18,2.8954305 18,4 L18,8 L16,8 L16,2 Z M14,0 L14,6 L12,6 L12,4 L8,4 L8,2 L12,2 L12,0 L14,0 Z M10,9 L9.99967126,10.2549186 C11.1335669,9.46390447 12.5126081,9 14,9 C17.8659932,9 21,12.1340068 21,16 C21,19.8659932 17.8659932,23 14,23 C10.1340068,23 7,19.8659932 7,16 C7,14.04099 7.80473162,12.2699351 9.10165647,10.9993737 L8,11 L8,9 L10,9 Z M14,11 C11.2385763,11 9,13.2385763 9,16 C9,18.7614237 11.2385763,21 14,21 C16.7614237,21 19,18.7614237 19,16 C19,13.2385763 16.7614237,11 14,11 Z M17,15 L17,17 L11,17 L11,15 L17,15 Z M6,13 L6,15 L4,15 L4,13 L6,13 Z M6,9 L6,11 L4,11 L4,9 L6,9 Z"
          transform="translate(3 1)"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(CalendarBlock);
