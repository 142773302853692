/**
 * @param {Object} store - redux store
 * @param {Function} select - function for data selection from state
 * @param {Function} onChange - selected data change callback
 * @returns {*}
 */
export default function storeObserver(store, select, onChange) {
  let currentState;

  function handleChange() {
    const nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}
