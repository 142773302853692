import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { productToOrderSelectorFactory } from '../../../selectors';

import { productAddToOrder, productRemoveFromOrder, productOrderUpdate } from '../../../actions';

import Product from './index';

const mapStateToProps = () => {
  const getProductToOrderSelector = productToOrderSelectorFactory;
  return (state, props) => ({
    ...getProductToOrderSelector(state.productOrders, {
      productId: props.productId,
    }),
  });
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      productAddToOrder,
      productRemoveFromOrder,
      productOrderUpdate,
    },
    dispatch,
  ),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ quantity: null; inStockAmount: null; }' is... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(Product);
