import React from 'react';
import classnames from 'classnames';

import { initialsGenerator } from 'helpers/initialsGenerator';

import style from './Avatar.scss';

export interface AvatarProps {
  src?: string | null;
  text?: string;
  type?: 'small' | 'medium' | 'large';
}

export class Avatar extends React.PureComponent<AvatarProps> {
  public render() {
    const { text, type, src } = this.props;
    const initials = initialsGenerator(text || '');
    const image = <img className={style.image} src={src || ''} alt={initials} />;
    const className = classnames(style.container, style[type || 'small'], {
      [style.hasImage]: src,
    });

    return <div className={className}>{src ? image : initials}</div>;
  }
}
