require('./tab-base.js');
App.Views.Forms.MenuOffer2.DescriptionTab = App.Views.Forms.MenuOffer2.TabBase.extend(
  {
    tabName: Wahanda.lang.menu.offer.tabs.description.title,
    tabClasses: 'offer2--description',
    templateId: 'menu-offer2-description-template',
    events: {
      'click .js-remove-treatment': 'removeTreatment',
    },
    listeners: [],

    shouldRenderTreatmentTypeSelect: function () {
      return this.model.isLegacyPackage() || this.model.isSpaDay() || this.model.isSpaBreak();
    },

    render: function () {
      var renderTreatmentTypeSelect = this.shouldRenderTreatmentTypeSelect();

      var templateData = {
        isPackage: renderTreatmentTypeSelect,
      };

      var html = Wahanda.Template.renderTemplate(this.templateId, templateData);
      this.$el.empty().append(html);

      if (renderTreatmentTypeSelect) {
        this.renderTreatmentTypeSelect();
      }
      this.editor = new App.Views.Forms.MenuOffer2.RichEditor({
        el: this.$('.description'),
        editorName: Wahanda.lang.menu.offer.editors.description,
        title: Wahanda.lang.menu.offer.tabs.description.title,
        editorContent: this.model.get('description'),
        readOnly: this.model.isReadOnly(),
      });
      this.editor.render();

      this.setChangeListeners();
    },

    setChangeListeners: function () {
      const createListener = (selector, cb) => {
        return this.$(selector).find('textarea').ckeditor().editor.on('change', cb);
      };

      this.listeners.push(
        createListener('.description', () =>
          App.trigger(Wahanda.Event.TRACK_SERVICE_EDIT, 'description'),
        ),
      );
    },

    remove: function () {
      this.listeners.forEach(({ removeListener }) => removeListener());
    },

    getValues: function () {
      var values = {
        description: this.$('.description').find('textarea').val().trim(),
      };

      if (this.shouldRenderTreatmentTypeSelect()) {
        var selectedTreatments = [];
        $('.treatment-list .treatment').each(function () {
          var $treatment = $(this);
          selectedTreatments.push($treatment.data('id'));
        });
        values.treatmentIds = selectedTreatments;
      }

      return values;
    },
    renderTreatmentTypeSelect: function () {
      this.treatmentSelectView = new App.Views.Forms.MenuOffer2.Pricing.TreatmentsListSelect({
        el: this.$('.js-treatment-type-container'),
        treatments: this.options.treatments,
        model: this.model,
        selectText: Wahanda.lang.menu.offer.tabs.description.treatmentText,
        maxSelect: 10,
        mediator: this.options.mediator,
      });
      this.treatmentSelectView.render();
    },

    removeTreatment: function (evt) {
      var $treatment = $(evt.currentTarget).closest('.treatment');
      var treatmentId = $treatment.data('id');

      this.treatmentSelectView.removeTreatment(treatmentId);
      $treatment.remove();
    },
  },
  {
    optionsToInherit: ['treatments'],
  },
);
