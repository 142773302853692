/* global BackboneEx */
import { enableRecording } from 'common/analytics';

App.Views.Marketing = BackboneEx.View.Main.extend({
  initialize() {
    enableRecording();
  },
  render() {
    App.headerView.setModule('marketing');
    const node = this.$('#marketing-section').get(0);
    App.ES6.Initializers.Marketing().render(node, {
      venueId: App.config.get('venue').id,
      tab: (App.mainViewOptions || {}).initialTab,
    });
  },
});

App.Views.Marketing.Dynamic = {};
