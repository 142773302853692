/**
 * Base view class for (almost) all other views.
 */
BackboneEx.View.Base = Backbone.View.extend({
    setupElementFromTemplateHidden: true,

	renderAttribute: function(field, value, bHtml){
    	var $block = this.$('.b-' + field);
    	var $value = this.$('.v-' + field);
    	if (value && value != ""){
    		if (bHtml){
    			$value.html(value);
    		}
    		else {
    			$value.text(value);
    		}
    		$block.show();
    	}
    	else{
    		$value.text('');
    		$block.hide();
    	}
    },

	renderModelAttributes: function(bHtml) {
		var self = this;
		_.each(this.model.attributes, function(value, name) {
			self.renderAttribute(name, value, bHtml);
		});
	},

	setupElementFromTemplate: function() {
		var templateId = this._getTemplateId();
		if (templateId) {
			var html;
			// If getDialogTemplateVariables is a function, run the template through templating engine
			if (this.getDialogTemplateVariables) {
				html = Wahanda.Template.renderTemplate(templateId, this.getDialogTemplateVariables());
			} else {
				html = Wahanda.Template.get(templateId);
			}
			var $el = $(html);
            if (this.setupElementFromTemplateHidden) {
                $el.hide();
            }
			this.setElement($el);
		} else {
			var $el = this.$el.clone();
			// Change id that no duplicates exist in DOM
			$el.attr("id", _.uniqueId($el.attr("id")));
			this.setElement($el);
		}
	},

	_getTemplateId: function() {
		return this.templateId;
	}
});