import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ClientReports } from 'components/reports/ClientReports';
import storeBuilder from 'reduxStore/store';

export default function (params) {
  const store = storeBuilder();
  return {
    /**
     * Minimal `Backbone.trigger` implementation to check if the view is visible.
     *
     * @return {void}
     */
    trigger(command) {
      switch (command) {
        case 'visible':
          this.render();
          break;
        case 'hidden':
          this.destroy();
          break;
        default:
          break;
      }
    },
    destroy() {
      ReactDOM.unmountComponentAtNode(params.tabElement);
    },
    // Export the render fn too
    render() {
      ReactDOM.render(
        <Provider store={store}>
          <ClientReports />
        </Provider>,
        params.tabElement,
      );
    },
  };
}
