import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import UnderlinedInput from 'components/common/UnderlinedInput';
import { findEmployeeCategoryName } from 'components/menu/utils';
import style from '../style.scss';

const placeholderText = Wahanda.lang.menu.offer.sku.captionPlaceholder;
const notUniqueNameError = Wahanda.lang.menu.offer.errors.skuNameNotUnique;
interface ISkuNameProps extends React.HTMLAttributes<Element> {
  name?: string;
  inputName: string;
  useEmployeeCategoryPricing: boolean;
  employeeCategoriesCollection: any[];
  employeeCategoryId?: number;
  editable: boolean;
  onChange: (...args: any[]) => any;
  required: boolean;
}

export default class SkuName extends React.Component<ISkuNameProps, {}> {
  buildTextInput() {
    const { required } = this.props;
    const additionalProps = required
      ? {
          required,
          'data-rule-unique-group': 'offer-sku-name',
          'data-msg-unique-group': notUniqueNameError,
        }
      : null;
    return (
      <UnderlinedInput
        id={this.props.inputName}
        // @ts-expect-error ts-migrate(2769) FIXME: Property 'name' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
        name={this.props.inputName}
        value={this.props.name}
        onChange={this.props.onChange}
        placeholder={placeholderText}
        {...additionalProps}
      />
    );
  }

  render() {
    const {
      editable,
      name,
      useEmployeeCategoryPricing,
      employeeCategoriesCollection,
      employeeCategoryId,
    } = this.props;
    const useInput = editable && !useEmployeeCategoryPricing;
    const content = (() => {
      if (useEmployeeCategoryPricing) {
        return findEmployeeCategoryName(employeeCategoriesCollection, employeeCategoryId);
      }
      if (editable && useInput) {
        return this.buildTextInput();
      }
      return name;
    })();
    return (
      <div className={classnames(style.name, useInput ? null : style.nameInactive)}>{content}</div>
    );
  }
}
