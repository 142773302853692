import React from 'react';
import classnames from 'classnames';

export const ICON_EXCLAMATION = 'icon-exclamation';

export const ICON_OK = 'icon-ok';

interface ISubBlockProps extends React.HTMLAttributes<Element> {
  title?: string;
  icon?: any;
  lighterBorder?: boolean;
  name?: string;
}

export default class SubBlock extends React.Component<ISubBlockProps, {}> {
  render() {
    const classes = classnames('subblock', this.props.icon, this.props.name, {
      'lighter-border': this.props.lighterBorder,
    });
    return (
      <div className={classes}>
        <div className="title">{this.props.title}</div>
        {this.props.children}
      </div>
    );
  }
}
