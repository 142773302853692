import React from 'react';
import classnames from 'classnames';
import { Portal } from 'react-portal';
import Wahanda from 'common/wahanda';
import keycode from 'keycode';

import { useComponentDidMount, useOnClickOutside } from 'utilities/hooks';
import { Select } from 'components/common/Select';
import { OptionValue } from 'components/common/Select/utils/types';

import { InputContextProvider, InputBorder, InputText } from 'components/common/__BaseCommon';
import { DialogFooterButton } from 'components/common/DialogFooter';
import { Chevron, QuestionMark } from 'components/common/Icon';
import { Tooltip, TooltipPlacement } from 'components/common/Tooltip';

import { FINISHING_TIME_SLOTS, FINISHING_TIME_SLOTS_WITH_NULL } from 'common/constants/timeChoices';

import { getDropdownPosition, mapTimeSlots, TimeSlot } from './helpers';
import style from './DurationSelector.scss';

const LANG = Wahanda.lang.calendar.appointments.multi.labels;
const LANG_TOOLTIP = Wahanda.lang.menu.offer.sku.title;

export enum DurationTypes {
  DURATION = 'duration',
  APPLICATION = 'application',
  PROCESSING = 'processing',
  FINISHING = 'finishing',
}

export interface DurationSelectorProps {
  applicationTime: number;
  finishingTime?: number | null;
  processingTime: number;
  processingTimeDisabled?: boolean;
  canUserEditAppointment: boolean;
  onApplicationTimeChange: (param) => void;
  onProcessingTimeChange?: (param) => void;
  onFinishingTimeChange: (param) => void;
  isFinishingWithNull?: boolean;
  tracking?: {
    trackDurationInputClick?: () => void;
    trackDialogCloseClick?: () => void;
    trackSelectChange?: (property) => void;
  };
}

export const DurationSelector = ({
  applicationTime: aTime,
  finishingTime: fTime,
  processingTime: pTime,
  processingTimeDisabled,
  canUserEditAppointment,
  onApplicationTimeChange,
  onProcessingTimeChange,
  onFinishingTimeChange,
  isFinishingWithNull,
  tracking,
}: DurationSelectorProps) => {
  const componentDidMount = useComponentDidMount();
  const [applicationTime, setApplicationTime] = React.useState(aTime);
  const [finishingTime, setFinishingTime] = React.useState(fTime || null);
  const [processingTime, setProcessingTime] = React.useState(pTime);
  const [isVisible, setIsVisible] = React.useState(false);
  const [placement, setPlacement] = React.useState({
    top: 0,
    left: 0,
  });

  const input = React.useRef<HTMLDivElement>(null);
  const content = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setApplicationTime(aTime);
  }, [aTime]);

  React.useEffect(() => {
    document.addEventListener('keyup', handleKeyboardAction, true);

    return () => {
      document.removeEventListener('keyup', handleKeyboardAction);
    };
  }, []);

  const adjustPosition = () => {
    if (isVisible) {
      const position = getDropdownPosition(content, input);
      setPlacement({
        top: position.top,
        left: position.left,
      });
    }
  };

  const handleKeyboardAction = (event) => {
    const keyPressed = keycode(event);

    if (keyPressed === 'esc') {
      onClose(true);
    }
  };

  const renderDuration = () => {
    const finishingTimeLength = finishingTime || 0;
    const duration = applicationTime + processingTime + finishingTimeLength;

    return Wahanda.Time.toFormattedWithTemplate(duration, Wahanda.Time.getTimeDurationTemplate());
  };

  const renderApplicationTimeDropdown = () => {
    const options = { minMinutes: 5 };
    const durations = Wahanda.Time.getDurationValues(
      null,
      Wahanda.Time.getTimeDurationTemplate(),
      true,
      options,
    );
    const values = mapTimeSlots(durations);
    const selected = applicationTime;

    return renderSelect({
      dataTest: DurationTypes.APPLICATION,
      label: LANG.applicationTime,
      values,
      selected,
      tooltipContent: LANG_TOOLTIP.applicationTimeTooltip,
      onSelect: (val: number) => handleApplicationTimeChange(val),
    });
  };

  const renderProcessingTimeDropdown = () => {
    const PROCESSING_TIME_SLOTS = Wahanda.Time.getProcessingTimeSlotsArray();
    const values = mapTimeSlots(PROCESSING_TIME_SLOTS);
    const selected = processingTime;

    return renderSelect({
      dataTest: DurationTypes.PROCESSING,
      label: LANG.processingTime,
      values,
      selected,
      disabled: processingTimeDisabled,
      tooltipContent: LANG_TOOLTIP.processingTimeTooltip,
      onSelect: (val: number) => handleProcessingTimeChange(val),
    });
  };

  const renderFinishingTimeDropdown = () => {
    const FINISHING_TIME_SLOTS_WITH_ZERO = [0, ...FINISHING_TIME_SLOTS];
    const selected = finishingTime || FINISHING_TIME_SLOTS_WITH_ZERO[0];
    const extendedSlots = isFinishingWithNull
      ? FINISHING_TIME_SLOTS_WITH_NULL
      : FINISHING_TIME_SLOTS_WITH_ZERO;
    const values = mapTimeSlots(extendedSlots);

    return renderSelect({
      dataTest: DurationTypes.FINISHING,
      label: LANG.finishingTime,
      values,
      selected,
      tooltipContent: LANG_TOOLTIP.finishingTimeTooltip,
      onSelect: (val: number) => handleFinishingTimeChange(val),
    });
  };

  const renderSelect = ({
    dataTest,
    disabled = false,
    label,
    onSelect,
    selected,
    tooltipContent,
    values,
  }: {
    dataTest: string;
    disabled?: boolean;
    label: string;
    onSelect: (val: number) => void;
    selected: number;
    tooltipContent: React.ReactNode;
    values: TimeSlot[];
  }) => {
    const tooltip = (
      <Tooltip tooltip={tooltipContent} placement={TooltipPlacement.top}>
        <span className={style.questionIcon}>
          <QuestionMark size="small" />
        </span>
      </Tooltip>
    );

    const onSelectChange = (val: OptionValue | OptionValue[]) => {
      if (tracking?.trackSelectChange) {
        tracking.trackSelectChange(dataTest);
      }
      onSelect(val as number);
    };

    return (
      <div className={style.selector}>
        <div className={style.label}>
          {label}
          {tooltip}
        </div>
        <Select
          name={dataTest}
          disabled={disabled}
          options={values}
          dataTest={`duration-selector-${dataTest}-time`}
          selectedOption={selected}
          onSelect={onSelectChange}
        />
      </div>
    );
  };

  const handleApplicationTimeChange = (val: number) => {
    setApplicationTime(val);
    if (onApplicationTimeChange) {
      onApplicationTimeChange(val);
    }
  };

  const handleProcessingTimeChange = (val: number) => {
    setProcessingTime(val);
    if (onProcessingTimeChange) {
      onProcessingTimeChange(val);
    }
  };

  const handleFinishingTimeChange = (val: number) => {
    setFinishingTime(val);
    if (onFinishingTimeChange) {
      onFinishingTimeChange(val);
    }
  };

  const renderSelections = () => {
    const containerPosition = { top: placement.top, left: placement.left };
    const containerStyles = {
      ...containerPosition,
      height: 'auto',
    };

    const className = classnames(style.container, {
      [style.isHidden]: !isVisible,
    });

    const modal = (
      <Portal>
        <div ref={content} style={containerStyles} className={className}>
          <div className={style.wrapper}>
            {renderApplicationTimeDropdown()}
            {processingTime !== null && renderProcessingTimeDropdown()}
            {renderFinishingTimeDropdown()}
          </div>
          <DialogFooterButton
            dataTest="duration-selector-close-button"
            fullWidth
            onClick={onClose}
            size="medium"
            title={Wahanda.lang.shared.buttons.done}
          />
        </div>
      </Portal>
    );

    return modal;
  };

  const showDialog = () => {
    if (!canUserEditAppointment) {
      return;
    }

    if (tracking?.trackDurationInputClick) {
      tracking.trackDurationInputClick();
    }

    setIsVisible(!isVisible);
  };

  const onClose = (event?) => {
    setIsVisible(false);

    if (tracking?.trackDialogCloseClick && !!event) {
      tracking.trackDialogCloseClick();
    }
  };

  React.useLayoutEffect(() => {
    adjustPosition();
  }, [isVisible]);

  React.useLayoutEffect(() => {
    if (componentDidMount) {
      setIsVisible(true);
    }
  }, [applicationTime, finishingTime, processingTime]);

  useOnClickOutside(content, () => onClose(), isVisible);

  return (
    <>
      <InputContextProvider disabled={!canUserEditAppointment}>
        <InputBorder>
          <div
            ref={input}
            className={style.input}
            onClick={showDialog}
            data-test="duration-selector-duration-wrap"
          >
            <InputText
              dataTest="duration-selector-duration-time"
              readOnly
              name="duration"
              value={renderDuration()}
            />
            {canUserEditAppointment && <Chevron className={style.chevronIcon} size="small" />}
          </div>
        </InputBorder>
      </InputContextProvider>
      {canUserEditAppointment && renderSelections()}
    </>
  );
};
