import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OrderDetailsDialog from './index';
import {
  requestProductOrderAction,
  productOrderCanBeProcessed,
  requestCancelProductOrderAction,
} from '../actions';

const mapStateToProps = ({ productOrders }) => ({
  existingOrder: productOrders.existingOrder,
  productSupplier: productOrders.productSupplier,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      requestProductOrderAction,
      requestCancelProductOrderAction,
      productOrderCanBeProcessed,
    },
    dispatch,
  ),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ existingOrder: null; allowToProcess: boole... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsDialog);
