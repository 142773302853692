import React from 'react';
import classnames from 'classnames';
import Chevron from 'components/common/icons/Chevron';
import style from './style.scss';

interface Props {
  className?: string;
  disabled?: boolean;
}

class ReportLink extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { className, children, disabled } = this.props;
    const classes = classnames(style.link, className, {
      [style.disabled]: disabled,
      [style.enabled]: !disabled,
    });
    return (
      <div className={classes}>
        {children}
        <Chevron color={disabled ? style.disabledIconColor : style.iconColor} />
      </div>
    );
  }
}

export default ReportLink;
