import React from 'react';
import ReactDOM from 'react-dom';
import { get, toClj, count, reduce, partial } from 'mori';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { trackEvent } from 'common/analytics';
import Header from './Header';
import CriteriaList from './CriteriaList';
import AllDone from './AllDone';
import Loading from '../common/Loading';

const LANG = Wahanda.lang.dashboard.trafficLights.criteriaList;

function getMoreInfoTracker(actionName) {
  return partial(trackEvent, 'dashboard', 'click', 'listing-strength-info', actionName);
}

function getButtonClickTracker(actionName) {
  return partial(trackEvent, 'dashboard', 'click', 'listing-strength-action', actionName);
}

function composeCriteriaList(props) {
  const { thresholds, appointmentOrders, menuData, bankAccount } = props;
  if (!thresholds && !bankAccount) {
    return null;
  }
  const list = [
    {
      lang: LANG.bookingWidget,
      threshold: get(thresholds, 'minimumRollingWidgetOrderCount'),
      currentValue: get(appointmentOrders, 'rollingWidgetAppointmentCount'),
      checked:
        get(appointmentOrders, 'rollingWidgetAppointmentCount') >=
        get(thresholds, 'minimumRollingWidgetOrderCount'),
      onMoreInfoClick: getMoreInfoTracker('widget orders'),
      button: {
        link: Wahanda.Url.getFullUrl('settings', 'online-booking/online-booking-widget'),
        onClick: getButtonClickTracker('widget orders'),
        hasPermission: Wahanda.Permissions.accessSettings(),
      },
    },
    {
      lang: LANG.smartDiscounting,
      checked:
        get(menuData, 'offpeakDiscountedServicesCount') >=
        get(thresholds, 'minimumOffPeakDiscountedServices'),
      onMoreInfoClick: getMoreInfoTracker('offpeak discounting'),
      button: {
        link: Wahanda.Url.getFullUrl('menu', 'services'),
        onClick: getButtonClickTracker('offpeak discounting'),
        hasPermission: Wahanda.Permissions.viewMenu(),
      },
    },
  ];
  if (LANG.proPhotography.moreInfoLink) {
    const proButton = LANG.proPhotography.actionLink
      ? {
          link: LANG.proPhotography.actionLink,
          onClick: getButtonClickTracker('professional photography'),
          hasPermission: true,
        }
      : null;
    list.push({
      lang: LANG.proPhotography,
      checked: !App.config.get('venue').imageEditingEnabled,
      onMoreInfoClick: getMoreInfoTracker('professional photography'),
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '{ link: any... Remove this comment to see the full error message
      button: proButton,
    });
  }
  // @ts-expect-error ts-migrate(2345) FIXME: Property 'onMoreInfoClick' is missing in type '{ l... Remove this comment to see the full error message
  list.push({
    lang: LANG.bankAccountDetails,
    checked: bankAccount && get(bankAccount, 'bankCode') && get(bankAccount, 'accountNumber'),
    button: {
      link: Wahanda.Url.getFullUrl('settings', 'finance/bank-details'),
      onClick: getButtonClickTracker('bank account details'),
      hasPermission: Wahanda.Permissions.manageVenueDetails(),
    },
  });
  // Not finished go first
  list.sort((a, b) => {
    const aVal = a.checked ? 1 : 0;
    const bVal = b.checked ? 1 : 0;
    return aVal - bVal;
  });
  return toClj(list);
}

function calculateCriteriaPercentage(list) {
  const itemCount = count(list);
  const checkedCount = reduce((total, item) => total + (get(item, 'checked') ? 1 : 0), 0, list);
  return itemCount > 0 ? (checkedCount / itemCount) * 100 : 0;
}

function maybeSetMaxHeight() {
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  if (this.header && this.state.criteriaMaxHeight === null) {
    // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    this.setCriteriaMaxHeight();
  }
}

function getCriteriaMaxheight(rootRef, headerRef) {
  const root = rootRef.getBoundingClientRect();
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const header = ReactDOM.findDOMNode(headerRef).getBoundingClientRect(); //eslint-disable-line
  return root.bottom - header.bottom - 1;
}

interface IListingStrengthProps extends React.HTMLAttributes<Element> {
  thresholds?: {};
  appointmentOrders?: {};
  menuData?: {};
}
type ListingStrengthState = {
  criteriaMaxHeight?: number;
};
class ListingStrength extends React.Component<IListingStrengthProps, ListingStrengthState> {
  header: any;

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'root' has no initializer and is not defi... Remove this comment to see the full error message
  root: HTMLDivElement;

  // @ts-expect-error ts-migrate(2416) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
  state = {
    criteriaMaxHeight: null,
  };

  componentDidMount() {
    maybeSetMaxHeight.call(this);
    window.addEventListener('resize', this.checkCriteriaHeightOnResize);
  }

  componentDidUpdate = maybeSetMaxHeight;

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkCriteriaHeightOnResize);
  }

  setCriteriaMaxHeight() {
    const callback = () => {
      this.setState({
        criteriaMaxHeight: getCriteriaMaxheight(this.root, this.header),
      });
    };
    window.setTimeout(callback, 0);
  }

  // Handle media query changes on resize, update the component accordingly.
  checkCriteriaHeightOnResize = () => {
    if (!this.state.criteriaMaxHeight) {
      return;
    }
    const currentHeight = getCriteriaMaxheight(this.root, this.header);
    if (this.state.criteriaMaxHeight !== currentHeight) {
      this.setState({ criteriaMaxHeight: currentHeight });
    }
  };

  render() {
    const { criteriaMaxHeight } = this.state;
    const criteriaList = composeCriteriaList(this.props);
    const percentage = calculateCriteriaPercentage(criteriaList);
    const headerLang = LANG.header[percentage < 100 ? 'partial' : 'full'];
    const criteriaComponent = (() => {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      if (!criteriaList || !(criteriaMaxHeight > 0)) {
        return null;
      }
      if (percentage === 100) {
        return <AllDone />;
      }
      // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
      return <CriteriaList criteriaList={criteriaList} maxHeight={criteriaMaxHeight} />;
    })();
    return (
      <div
        className="container-block listing"
        ref={(root) => {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLDivElem... Remove this comment to see the full error message
          this.root = root;
        }}
      >
        {criteriaList ? null : <Loading />}
        {criteriaList ? (
          <Header
            percentage={percentage}
            lang={headerLang}
            ref={(header) => {
              this.header = header;
            }}
          />
        ) : null}
        {criteriaComponent}
        {criteriaList ? <div className="gradient" /> : null}
      </div>
    );
  }
}

export default ListingStrength;
