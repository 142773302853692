App.Views.Calendar.Dated.Tooltip = Backbone.View.extend({
  positionFix: {
    top: 0,
    left: 8,
  },

  initialize: function () {
    this.setElement($('#room-type-tooltip-template').html());
    this.$el.attr('id', this.cid);

    this.$arrow = this.$('.arrow');
  },

  /**
   * Renders the tooltip.
   *
   * @param App.Models.RoomAllocation|App.Models.SpaAllocation model
   */
  render: function (model) {
    // Date
    this.$('.wdt-days-date').text(model.getFormattedDate());

    if (model instanceof App.Models.RoomAllocation) {
      // Table data
      var data = model.getRoomTypesForTemplate(this.options.roomTypesCollection);
      this.$('tbody').html(
        Wahanda.Template.renderTemplate('room-type-tooltip-row', { list: data }),
      );
      this.$('table').show();
    } else {
      this.$('table').hide();
    }

    this.$('.b-disabled-offers').toggle(model.hasClosedOffers());
  },

  positionTo: function ($td) {
    if (!this.isAttached()) {
      this.attach();
    }

    // Moving the element out of view, but so it will be possible to calculate the width & height
    this.$el.css('top', -9999).show();

    var gridScroll = this.options.$container.scrollTop();
    var arrowTop = parseInt(this.$arrow.css('top', '').css('top'), 10);
    var eventPos = $td.position();
    var eventWidth = $td.outerWidth();
    var eventY = eventPos.top + gridScroll;
    var posX = eventWidth + this.positionFix.left + eventPos.left;
    var posY = eventY + this.positionFix.top;

    var width = this.$el.outerWidth();
    var height = this.$el.outerHeight();

    var cls = 'sal-event-info-right';
    if (posX + width >= this.options.$container.width()) {
      posX -= eventWidth + width + this.positionFix.left * 2;
      cls = 'sal-event-info-left';
    }
    if (posY < gridScroll) {
      // If event's top is higher than the viewport
      var diff = gridScroll - posY;
      diff = Math.min(diff, height);
      posY += diff;
      arrowTop = Math.max($td.outerHeight() + eventPos.top - 20, 5);
    } else if (posY + height >= this.options.$container.height() + gridScroll) {
      // If event is to the bottom of viewable area
      var originalY = posY;
      var diff = posY + height - (this.options.$container.height() + gridScroll);
      // diff      = Math.min(diff, this.$infoTable.height());
      posY -= diff;
      posY = Math.max(posY, gridScroll);
      arrowTop += originalY - posY;
    }

    this.$el
      .removeClass('sal-event-info-right sal-event-info-left')
      .addClass(cls)
      .css({ left: posX, top: posY });

    this.$arrow.css('top', arrowTop);
  },

  attach: function () {
    this.options.$container.append(this.$el);
  },

  isAttached: function () {
    return $('#' + this.cid).length === 1;
  },

  show: function () {
    this.$el.show();
  },

  hide: function () {
    this.$el.hide();
  },
});
