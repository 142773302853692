import { ACTIONS, trackEvent } from 'common/analytics';

const CATEGORY = 'receipt-copy-dialog';

export const ReceiptCopyDialogAnalytics = {
  trackDialogRendered() {
    trackEvent(CATEGORY, ACTIONS.VIEW, 'receipt-copy-reason-form');
  },
  trackSubmit() {
    trackEvent(CATEGORY, ACTIONS.SUBMIT, 'receipt-copy-reason-form');
  },
};
