/* eslint-disable func-names */
Wahanda.Dialogs = {
  /**
   * Show alert dialog with text.
   *
   * @param String title
   * @param Object options OPTIONAL Options which are passed to the Dialog instance
   *
   * @return App.Views.Dialog.Alert
   */
  HTMLAlert: function (title, options) {
    const view = new App.Views.Dialog.Alert(options || {});
    view.render(title);
    view.open();

    return view;
  },

  Alert: function (text, options) {
    text = Wahanda.Util.escapeHtml(text);
    text = Wahanda.Text.nl2br(text);

    return Wahanda.Dialogs.HTMLAlert(text, options);
  },

  Confirm: function (question, successCallback, cancelCallback) {
    if (confirm(question)) {
      successCallback();
    } else {
      cancelCallback && cancelCallback();
    }
  },

  getReactContainer: function (returnAsJquery) {
    let $dialogElement;
    if ($('.react-container').length === 0) {
      $dialogElement = $('<div class="react-container"></div>');
      $('body').append($dialogElement);
    } else {
      $dialogElement = $('.react-container');
    }
    return returnAsJquery ? $dialogElement : $dialogElement[0];
  },

  getReactStaticContainer: function (returnAsJquery) {
    let $dialogElement;
    if ($('.react-static-container').length === 0) {
      $dialogElement = $('<div class="react-static-container"></div>');
      $('body').append($dialogElement);
    } else {
      $dialogElement = $('.react-static-container');
    }
    return returnAsJquery ? $dialogElement : $dialogElement[0];
  },

  React: function (reactDialog, options) {
    let dialogOptions = { targetElement: Wahanda.Dialogs.getReactContainer() };

    if (options) {
      dialogOptions = _.extend(options, dialogOptions);
    }
    reactDialog(dialogOptions);
  },
};
