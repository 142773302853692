import React from 'react';

export function useComponentDidMount() {
  const [observer, setObserver] = React.useState(false);

  React.useEffect(() => {
    if (!observer) {
      setObserver(true);
    }
    return () => setObserver(false);
  }, []);

  return observer;
}
