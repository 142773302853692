(function () {
  var GapView = BackboneEx.View.Base.extend({
    attributes: {
      class: 'appointment--item--gap',
    },
    prevModel: null,
    nextModel: null,

    // TODO: on reschedule, re-calculate the gap

    initialize: function (options) {
      this.prevModel = options.prevModel;
      this.nextModel = options.nextModel;
    },

    render: function () {
      // Compute difference
      var diff =
        (this.nextModel.getStartDate().getTime() -
          this.prevModel.getEndDateWithAdditionalTime().getTime()) /
        1000 /
        60;
      // Render text
      this.$el.text(
        Wahanda.Template.render(
          Wahanda.lang.calendar.appointments.textTemplates.gap,
          Wahanda.Time.minutesToTimeHash(diff),
        ),
      );
    },
  });

  App.Views.Forms.Appointment2.TimeGap = GapView;
})();
