import React from 'react';
import { toClj, toJs, hashMap, get, count } from 'mori';
import keycode from 'keycode';
import classnames from 'classnames';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import Dialog from 'components/common/react-dialog';
import DialogFooter from 'components/common/dialog/footer';
import ClosingX from 'components/common/dialog/ClosingX';
import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import { CalendarAnalytics } from 'common/analytics';
import { CancellationContainer } from './Cancellation/container';
import UDV from './UDV';
import BlockTime from './BlockTime';
import { NoShowDialog } from './NoShow';

const store = storeBuilder();

function getInitialIsAppointmentTab(props) {
  const tab = (() => {
    if (props.editorData.tab) {
      return props.editorData.tab;
    }
    return 'appointment';
  })();
  return tab === 'appointment';
}

function bindKeyDownEvent(handler) {
  document.body.addEventListener('keyup', handler);
}

function removeKeyDownEvent(handler) {
  document.body.removeEventListener('keyup', handler);
}

function validSaveEnterTarget(node) {
  // Check only one dialog open
  if (document.getElementsByClassName('ui-dialog').length > 1) {
    return false;
  }
  if (node.type === 'textarea') {
    return false;
  }
  if (node.classList.contains('select-dropdown') && node.dataset.open === 'true') {
    return false;
  }
  if (node.classList.contains('ui-autocomplete-input')) {
    return false;
  }
  return true;
}

interface ICalendarEventEditorProps extends React.HTMLAttributes<Element> {
  editorData: {
    appointmentViewData?: {};
    blockViewData?: {};
    tab?: string;
  };
  buttonState?: {};
  onClose?: (...args: any[]) => any;
  blockViewData?: any;
  appointmentViewData?: any;
}
type CalendarEventEditorState = {
  isAppointment?: boolean;
  save?: any;
  buttonState?: any;
  checkout?: any;
  dialogTop?: any;
  confirmDeletionDialogData?: any;
  confirmNoShowDialogData?: any;
  ignoreKeyboardEvents?: any;
};

// eslint-disable-next-line import/no-default-export
export default class CalendarEventEditor extends React.Component<
  ICalendarEventEditorProps,
  CalendarEventEditorState
> {
  block: any;

  dialog: any;

  udv: any;

  static defaultProps = {
    buttonState: null,
    onClose: () => {},
  };

  state = {
    dialogTop: null,
    isAppointment: getInitialIsAppointmentTab(this.props),
  };

  componentDidMount() {
    // This works around the "too fast" keyboard binding, which is triggered before the
    // Header dropdown's "Enter" keyboard action (which opened this UDV) is finished.
    window.setTimeout(() => bindKeyDownEvent(this.handleGlobalKeyDown), 0);
    App.trigger(
      Wahanda.Event.CALENDAR_EDIT_FORM_OPENED,
      this.state.isAppointment ? 'appointment' : 'block',
    );
  }

  componentWillUnmount() {
    removeKeyDownEvent(this.handleGlobalKeyDown);
  }

  onChangeType(type) {
    App.trigger(Wahanda.Event.CALENDAR_EDIT_FORM_TAB_SWITCH, type);
    this.setState({
      isAppointment: type === 'appointment',
    });
    const tooltips = document.getElementsByClassName('qtip');
    while (tooltips.length > 0) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      tooltips[0].parentNode.removeChild(tooltips[0]);
    }
  }

  /*
   *  TODO:
   *
   *  It's not clear whether the function can return outside of the
   *  the "if ... else" block, and in that case, what it's return
   *  value should be
   */
  // eslint-disable-next-line consistent-return
  onSave = () => {
    if (this.isNewEvent()) {
      if (this.state.isAppointment) {
        CalendarAnalytics.trackAppointmentCreateSubmitClick();
      } else {
        CalendarAnalytics.trackBlockCreateSubmitClick({
          property: this.eventAvailabilityRuleTypeCode(),
        });
      }
    }
    if (this.state.isAppointment) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'buttonState' does not exist on type '{ d... Remove this comment to see the full error message
      if (this.state.buttonState.checkout) {
        return this.udv?.checkout();
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'buttonState' does not exist on type '{ d... Remove this comment to see the full error message
      if (this.state.buttonState.save) {
        return this.udv?.save();
      }
    } else {
      return this.block?.save();
    }
  };

  onCheckout = () => {
    this.udv.checkout();
  };

  onDelete = () => {
    this.block.del();
  };

  onClose = () => {
    if (this.isNewEvent()) {
      if (this.state.isAppointment) {
        CalendarAnalytics.trackAppointmentCreateCancelClick();
      } else {
        CalendarAnalytics.trackBlockCreateCancelClick({
          property: this.eventAvailabilityRuleTypeCode(),
        });
      }
    }
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.onClose();
  };

  onHide = () => {
    this.dialog.hide();
  };

  onShow = () => {
    this.dialog.show();
  };

  eventAvailabilityRuleTypeCode = () => {
    const type = $('#timeblock-rpt').val();
    const dateFrom = $('#timeblock-pdatefrom').val();
    const dateTo = $('#timeblock-pdateto').val();
    const isSingleDay = type === 'P' && dateFrom === dateTo;
    return isSingleDay ? 'D' : type;
  };

  isNewEvent = () => {
    return this.props.editorData.appointmentViewData && this.props.editorData.blockViewData;
  };

  setDialogRef = (dialog) => {
    this.dialog = dialog;
  };

  updateButtons = (buttonState) => {
    this.setState({
      buttonState,
    });
  };

  heightChange = () => {
    // TODO looks like a dead code, but this callback is expected in child components and in some backbone code.
    // Leaving noop for now since refactoring may introduce breaking changes in essential functionality.
  };

  topChange = (dialogTop) => {
    this.setState({
      dialogTop,
    });
  };

  showConfirmDeletionDialog = ({
    bookingActor,
    isPrepaid,
    isWithinCancellationPeriod,
    cancellationAllowed,
    onReschedule,
    action,
    onDoCancellation,
    onClose,
    reschedulingAllowed,
    paymentProtected,
    id,
    granularity,
    isFirstTimeCustomer,
    orderId,
    isRecurring,
  }) => {
    CalendarAnalytics.trackCancelAppointmentClicked();
    this.setState({
      confirmDeletionDialogData: hashMap(
        'bookingActor',
        bookingActor,
        'isPrepaid',
        isPrepaid,
        'isWithinCancellationPeriod',
        isWithinCancellationPeriod,
        'cancellationAllowed',
        cancellationAllowed,
        'reschedulingAllowed',
        reschedulingAllowed,
        'onReschedule',
        onReschedule,
        'action',
        action,
        'onDoCancellation',
        onDoCancellation,
        'onClose',
        onClose,
        'paymentProtected',
        paymentProtected,
        'id',
        id,
        'granularity',
        granularity,
        'isFirstTimeCustomer',
        isFirstTimeCustomer,
        'orderId',
        orderId,
        'isRecurring',
        isRecurring,
      ),
    });
  };

  showConfirmNoShowDialog = ({
    bookingActor,
    isPrepaid,
    isWithinCancellationPeriod,
    cancellationAllowed,
    onDoNoShow,
    onClose,
    reschedulingAllowed,
    type,
    isFirstTimeCustomer,
    isWalkin,
    paymentProtected,
    granularity,
    id,
  }) => {
    this.setState({
      confirmNoShowDialogData: hashMap(
        'type',
        type,
        'bookingActor',
        bookingActor,
        'isPrepaid',
        isPrepaid,
        'isWithinCancellationPeriod',
        isWithinCancellationPeriod,
        'cancellationAllowed',
        cancellationAllowed,
        'reschedulingAllowed',
        reschedulingAllowed,
        'onDoNoShow',
        onDoNoShow,
        'onClose',
        onClose,
        'isFirstTimeCustomer',
        isFirstTimeCustomer,
        'isWalkin',
        isWalkin,
        'paymentProtected',
        paymentProtected,
        'granularity',
        granularity,
        'id',
        id,
      ),
    });
  };

  handleGlobalKeyDown = (event) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ignoreKeyboardEvents' does not exist on ... Remove this comment to see the full error message
    if (event.defaultPrevented || this.state.ignoreKeyboardEvents) {
      return;
    }
    if (keycode(event) === 'enter' && validSaveEnterTarget(event.target)) {
      this.onSave();
    }
  };

  toggleDialogClosing = (dropdownState) => {
    const ignoreKeyboardEvents = !!dropdownState.isOpen;
    // Prevent closing the dialog while a dropdown is open
    this.toggleIgnoreKeyboardEvents(ignoreKeyboardEvents);
  };

  toggleIgnoreKeyboardEvents = (ignoreKeyboardEvents) => {
    this.setState({ ignoreKeyboardEvents });
    this.dialog.ignoreKeyboardEvents(ignoreKeyboardEvents);
  };

  notifyPositionChange = () => {
    if (this.udv) {
      this.udv.onPositionChange();
    }
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'confirmDeletionDialogData' does not exis... Remove this comment to see the full error message
    const { isAppointment, confirmDeletionDialogData, confirmNoShowDialogData } = this.state;
    const ulClassName = `tab-list variant-${isAppointment ? 'a' : 'b'}`;
    const refreshFn = () => {
      if (this.dialog) {
        this.dialog.updateHeight();
      }
    };
    const buttons = (() => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'buttonState' does not exist on type '{ d... Remove this comment to see the full error message
      const buttonData = this.state.buttonState;
      const buttonsList = [];
      if (!buttonData) {
        return null;
      }
      if (buttonData.checkout) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        buttonsList.push({
          classes: buttonData.checkout.classes,
          onClick: this.onCheckout,
          title: buttonData.checkout.title,
        });
      } else if (!this.state.isAppointment && buttonData.deleteButton) {
        buttonsList.push(
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
          {
            classes: buttonData.save.classes,
            onClick: this.onSave,
            title: buttonData.save.title,
            id: 'block-save-btn',
          },
          {
            classes: buttonData.deleteButton.classes,
            id: 'block-del-btn',
            onClick: this.onDelete,
            title: buttonData.deleteButton.title,
          },
        );
      } else if (buttonData.save) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        buttonsList.push({
          classes: buttonData.save.classes,
          onClick: this.onSave,
          title: buttonData.save.title,
        });
      } else if (buttonData.loading) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        buttonsList.push({
          classes: buttonData.loading.classes,
        });
      }
      if (this.state.isAppointment && buttonData.addNext) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        buttonsList.push(buttonData.addNext);
      }
      if (this.state.isAppointment && buttonData.repeat) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        buttonsList.push(buttonData.repeat);
      }
      if (this.state.isAppointment && buttonData.rebook) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        buttonsList.push(buttonData.rebook);
      }
      return buttonsList.length > 0 ? toClj(buttonsList) : null;
    })();
    const apptForm = (() => {
      if (this.props.editorData.appointmentViewData) {
        return (
          <div hidden={!isAppointment}>
            <UDV
              ref={(udv) => {
                this.udv = udv;
              }}
              // @ts-expect-error ts-migrate(2769) FIXME: Property 'data' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
              data={this.props.editorData.appointmentViewData}
              onRender={refreshFn}
              close={this.props.onClose}
              hide={this.onHide}
              show={this.onShow}
              updateButtons={this.updateButtons}
              topChange={this.topChange}
              heightChange={this.heightChange}
              toggleDialogClosing={this.toggleDialogClosing}
              showConfirmDeletion={this.showConfirmDeletionDialog}
              showConfirmNoShow={this.showConfirmNoShowDialog}
              forceSave={this.onSave}
              toggleIgnoreKeyboardEvents={this.toggleIgnoreKeyboardEvents}
            />
          </div>
        );
      }
      return null;
    })();
    const blockTime = (() => {
      if (this.props.editorData.blockViewData) {
        return (
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'boolean | u... Remove this comment to see the full error message
          <div hidden={isAppointment && apptForm}>
            <BlockTime
              ref={(block) => {
                this.block = block;
              }}
              // @ts-expect-error ts-migrate(2769) FIXME: Property 'data' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
              data={this.props.editorData.blockViewData}
              onRender={refreshFn}
              close={this.props.onClose}
              hide={this.onHide}
              show={this.onShow}
              updateButtons={this.updateButtons}
              topChange={this.topChange}
              heightChange={this.heightChange}
              toggleDialogClosing={this.toggleDialogClosing}
            />
          </div>
        );
      }
      return null;
    })();
    const tabBtns = (() => {
      if (blockTime && apptForm) {
        const list = (
          <ul className={ulClassName} key="list">
            <li
              className={classnames({ active: isAppointment })}
              onClick={() => this.onChangeType('appointment')}
            >
              {Wahanda.lang.calendar.tabs.appointment}
            </li>
            <li
              className={classnames({ active: !isAppointment })}
              onClick={() => this.onChangeType('block')}
            >
              {Wahanda.lang.calendar.tabs.block}
            </li>
          </ul>
        );
        const dragHandle = <div className="dialog-draggable under-tab-handle" key="drag-handle" />;
        return [dragHandle, list];
      }
      return null;
    })();
    const top = this.state.dialogTop;
    const blockTitle = blockTime ? Wahanda.lang.calendar.blockTime.title : null;
    const closeWarningText =
      Wahanda.lang.calendar.appointments.changesCloseWarnings[
        isAppointment ? 'appointment' : 'block'
      ];
    const confirmDeletionDialogComponent = () => {
      const onClose = () => this.setState({ confirmDeletionDialogData: null });
      const getValue = (name) => get(confirmDeletionDialogData, name);
      const removeDialogAndExecuteCallback = (paramName) => () => {
        const callback = getValue(paramName);
        onClose();
        this.props.onClose?.();
        if (typeof callback === 'function') {
          callback();
        }
      };
      return (
        <Provider store={store}>
          <CancellationContainer
            {...toJs(confirmDeletionDialogData)}
            onReschedule={removeDialogAndExecuteCallback('onReschedule')}
            onClose={removeDialogAndExecuteCallback('onClose')}
            doClose={onClose}
          />
        </Provider>
      );
    };
    const confirmNoShowDialogComponent = () => {
      const onClose = () => this.setState({ confirmNoShowDialogData: null });
      const getValue = (name) => get(confirmNoShowDialogData, name);
      const removeDialogAndExecuteCallback = (paramName) => () => {
        const callback = getValue(paramName);
        onClose();
        if (typeof callback === 'function') {
          callback();
        }
      };

      return (
        <Provider store={store}>
          <NoShowDialog
            {...toJs(confirmNoShowDialogData)}
            onClose={removeDialogAndExecuteCallback('onClose')}
            doClose={onClose}
          />
        </Provider>
      );
    };
    const footer = count(buttons) > 0 ? <DialogFooter buttons={buttons} fullWidth /> : null;
    return (
      <div>
        <Dialog
          dataTest="calendar-editor-modal"
          title="Title"
          onClose={this.onClose}
          classes={{ 'calendar-item-editor': true, top }}
          hideTitlebar
          ref={this.setDialogRef}
          width={600}
          keepTopPositionWhenResizing
          textWhenClosingChangesForm={closeWarningText}
          onPositionChange={this.notifyPositionChange}
          initialPosition="top"
        >
          <ClosingX onClick={() => this.dialog.close()} />
          <div
            className={classnames({
              'calendar-edit-title dialog-draggable': true,
              hidden: blockTime && apptForm,
            })}
            data-hj-suppress
          >
            {blockTitle}
          </div>

          {tabBtns}

          <div className="">
            {apptForm}
            {blockTime}
          </div>
          {footer}
        </Dialog>
        {confirmDeletionDialogData && confirmDeletionDialogComponent()}
        {confirmNoShowDialogData && confirmNoShowDialogComponent()}
      </div>
    );
  }
}
