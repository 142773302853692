import { connect } from 'react-redux';
import { Feature as Component } from './Feature';
import { isFeatureEnabled } from '../store/selectors';
import { TrunkFeatureId } from '../TrunkFeatures';

const mapStateToProps = (state, props: { featureId: TrunkFeatureId }) => ({
  isEnabled: isFeatureEnabled(state, props.featureId),
});

export const Feature = connect(mapStateToProps)(Component);
