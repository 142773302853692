/**
 * After-save question qTip for Dialog2 dialogs.
 * 
 * @param {Object} options
 * > htmlContent         HTML content
 * > saveAction          (optional) Function to execute when the user wants to save anyway
 * > cancelAction        (optional) Function to call when the user cancels
 * > primaryButtonText   (optional) Name of the save button
 * > secondaryButtonText (optional) Name of cancel button
 *
 * @returns jQuery The tooltip.
 */
$.fn.dialog2SaveQuestionTip = function(options) {
	_.defaults(options, {
		primaryButtonEnabled: true,
		primaryButtonText   : Wahanda.lang.shared.saveQuestionTip.buttons.save,
		secondaryButtonText : Wahanda.lang.shared.saveQuestionTip.buttons.cancel
	});
	var $html = $(
		Wahanda.Template.renderTemplate('dialog2-save-question-tip-template', options)
	);
	
	var tooltip = this.qtip({
		content: $html,
		style: {
			classes: 'dialog-tooltip'
		},
		position: options.position || {
			my: 'bottom right',
			at: 'top center'
		},
		show: {
			event: false,
			ready: true
		},
		hide: {
			event: false,
			fixed: true
		}
	});

	$html.find('.dialog2-tooltip--button.primary').on('click', getCallback('saveAction')).end()
		.find('.dialog2-tooltip--button.secondary').on('click', getCallback('cancelAction'));

	function getCallback(actionName) {
		return function() {
			tooltip.qtip('destroy');
			if (_.isFunction(options[actionName])) {
				options[actionName]();
			}
		};
	}
	
	return tooltip;
};
