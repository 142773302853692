import { connect } from 'react-redux';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { isFeatureEnabled } from 'common/trunk-features/store/selectors';

import { Marketing as Component, TABS } from './index';
import { CLIENT_APP_ENABLED_CHANNEL_FEATURE, COVID_CONSENT_FORMS } from './chnlFeatures';
import { isWebviewSelector } from 'reduxStore/common/appSettings/selectors';

const resolveDefaultTab = ({ tab }, isEmailCampaignsAccessible) => {
  // The tab parameter here comes from the backbone router,
  // e.g if user comes through direct link to the specific tab
  if (tab) return tab;
  return isEmailCampaignsAccessible ? TABS.CAMPAIGNS : TABS.AUTOMATED_MESSAGING;
};

const mapStateToProps = (state, ownProps) => {
  const isEmailCampaignsAccessible = Wahanda.Permissions.anyEmailCampaignEnabled();
  const isClientFormsAccessible =
    isFeatureEnabled(state, 'CAR-2635-client-forms-page') &&
    App.isFeatureSupported(COVID_CONSENT_FORMS);
  const isClientAppEnabled =
    App.isFeatureSupported(CLIENT_APP_ENABLED_CHANNEL_FEATURE) && App.isVenueListedOnMarketplace();
  const isPartnerSiteAccessible = Wahanda.Permissions.canAccessPartnerSites();

  const defaultTab = resolveDefaultTab(ownProps, isEmailCampaignsAccessible);

  return {
    defaultTab,
    hideSidebar: isWebviewSelector(state),
    isClientAppEnabled,
    isClientFormsAccessible,
    isEmailCampaignsAccessible,
    isPartnerSiteAccessible,
  };
};

export const Marketing = connect(mapStateToProps)(Component);
