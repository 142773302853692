/* global BackboneEx */
/* global _ */

import customerDuplicateCheck from 'utilities/customer-duplicate-check';
import { isValidNumber, formatInternationalFromInput } from 'common/phonenumber';
import { trackEvent } from 'common/analytics';

(function () {
  App.Views.Forms.Customer = BackboneEx.View.Dialog.ValidatingForm.extend({
    events: {
      'click .button-cancel': 'cancel',
      'click .a-show-year': 'showYearInput',
      'change #consumer-birthMonth': 'onBirthMonthChange',
      // those events handles the phone number field status: required or not.
      // @See onFieldFilledIn
      'change input, select, textarea': 'onFieldFilledIn',
    },

    mediaQueryOptions: {
      desktop: {
        width: 460,
      },
      wide: {
        width: 972,
      },
    },

    options: {
      title: Wahanda.lang.consumer.editForm.title,
    },

    templateId: 'consumer-edit-form-template',
    // eslint-disable-next-line no-useless-escape
    fieldPrefix: /^consumer\-/,

    postInitialize: function postInitialize() {
      trackEvent('client', 'view', 'customer-edit-form');

      this.setupBirthMonthSelect();
      if (this.isMultiLocale()) {
        this.$('#multilocale').wShow();
        this.setupLocaleSelect();
      }
      if (this.model.id) {
        this.refreshModelAndForm().done(() => this.bindToModelChange());
      } else {
        this.bindToModelChange();
      }

      // Todo: deprecated
      if (App.config.canShowPrepayRequirementFeatures()) {
        this.$('#prepaymentRequiredRow').wShow();
      }

      // filling in properties from parent
      this.setWalkInCallback = this.options.setWalkInCallback || function () {};
      this.allowWalkInCreation = this.options.allowWalkInCreation || false;
    },

    bindToModelChange() {
      if (this.options.onCustomerModelChange) {
        this.listenTo(this.model, 'change', (model) => {
          this.options.onCustomerModelChange(model);
        });
      }
    },

    isMultiLocale: function isMultiLocale() {
      const channelSupportedLocales = App.config.getChannelSupportedLocales();

      if (!channelSupportedLocales) {
        return false;
      }

      const numberOfSupportedLocales = Object.keys(channelSupportedLocales).length;

      return numberOfSupportedLocales > 1;
    },

    setupBirthMonthSelect: function setupBirthMonthSelect() {
      const birthMonths = {
        '': Wahanda.lang.consumer.editForm.labels.month,
      };
      _.each(Wahanda.lang.date.months, function (name, id) {
        birthMonths[id + 1] = name;
      });

      this.$('#consumer-birthMonth').setSelectOptions(birthMonths);
    },
    setupLocaleSelect: function setupLocaleSelect() {
      let supportedLanguages = {
        '': Wahanda.lang.consumer.editForm.labels.lang,
      };
      supportedLanguages[''] = '';

      _.each(App.config.getChannelSupportedLocales(), function (localeCode, name) {
        const localeName = Wahanda.lang.language[localeCode]
          ? Wahanda.lang.language[localeCode]
          : name;
        supportedLanguages[localeCode] = localeName;
      });

      // TEMP fix for DEV-49913 - need to map nl_NL to just nl
      const localeCode = this.model.get('locale');
      const localeName = Wahanda.lang.language[localeCode];

      function checkKey(code) {
        return _.findKey(supportedLanguages, function (value, key) {
          return key === code;
        });
      }

      if (localeCode && !checkKey(localeCode)) {
        supportedLanguages = _.omit(_.invert(supportedLanguages), localeName);
        supportedLanguages[localeName] = localeCode;
        supportedLanguages = _.invert(supportedLanguages);
      }

      this.$('#consumer-locale').setSelectOptions(supportedLanguages);
    },

    setValidation: function setValidation() {
      const self = this;
      const validations = Wahanda.Validate.getValidations('defaults', {
        submitHandler: function () {
          if (self.model.id > 0 || self.isOnlyNameFilledIn(self.getModelValues())) {
            _.debounce(function () {
              self.save();
            }, 200)();
          } else {
            self.duplicateCheck();
          }
        },
      });

      this.$('form').validate(validations);
    },

    initForm: function initForm() {
      this.fillFormFromModel();

      if (this.model.id) {
        this.toggleYearField();
      }

      const { elementToFocus } = this.options;

      if (elementToFocus) {
        if (elementToFocus === 'email') {
          this.elementToFocus = '#consumer-emailAddress';
        } else if (elementToFocus === 'phone') {
          this.elementToFocus = '#consumer-phone';
        }
      }

      this.setPhoneNumberAndEmailAsRequired(true);
    },

    duplicateCheck: function duplicateCheck() {
      this.disableDialog();

      const promise = customerDuplicateCheck(
        {
          name: this.$('#consumer-name').val(),
          phone: this.$('#consumer-phone').val().replace(/\s/g, ''),
          emailAddress: this.$('#consumer-emailAddress').val(),
        },
        this.$('button.save-action'),
      );

      promise.then(
        (data) => {
          if (data.createNew) {
            // No dupe found - continue saving.
            this.enableDialog();
            this.save();
          } else if (data.customerData) {
            // A duplicate was found and the user chose one to use instead.
            this.model.set(data.customerData);
            // Trigger the "saved" event for associated views to update their data
            this.model.trigger('saved');

            this.options.onChoice(this.model);
            this.resetCloseChangesCheck();

            this.close();
          } else {
            // The save action was cancelled.
            this.enableDialog();
          }
        },
        (err) => {
          // Probably a fetch error occured
          this.enableDialog();
          console.error(err);
        },
      );
    },

    /**
     * @returns {Object} A map { {String} fieldId: {Boolean} isRequired }
     */
    getIsPhoneAndEmailRequired: function getIsPhoneAndEmailRequired(data) {
      const { emailAddress, phone } = data;
      const respond = (emailRequired, phoneRequired) => ({
        'consumer-emailAddress': emailRequired,
        'consumer-phone': phoneRequired,
      });

      const existingCustomer = this.model.id > 0;
      const bothFieldsEmpty = !emailAddress && !phone;
      if (this.allowWalkInCreation && !existingCustomer && this.isOnlyNameFilledIn(data)) {
        // Walkin allowed and this is a new customer.
        // Allow no contact details to be entered.
        return respond(false, false);
      }
      // Existing customer, or walkins not allowed. Or we have something in form.
      // We require at least one field. Or both if none is entered
      return respond(bothFieldsEmpty || !!emailAddress, bothFieldsEmpty || !!phone);
    },

    /**
     * @returns true if name is the only filled in field in the form
     */
    isOnlyNameFilledIn: function isOnlyNameFilledIn(data) {
      const isEmpty = function (value) {
        return value === '' || value == null;
      };
      // those are the fields that I always expect to be filled in
      const isIgnoredField = function (name) {
        return ['active', 'name', 'sendMassEmails', 'prepaymentRequired'].includes(name);
      };
      // true if the user filled in only the Name field
      return _.all(data, (value, name) => isEmpty(value) || isIgnoredField(name));
    },

    /**
     * @param {bool} If the value checks should be performed on the `model`, or on DOM form data.
     */
    setPhoneNumberAndEmailAsRequired: function setPhoneNumberAndEmailAsRequired(checkInitialModel) {
      let values;
      if (checkInitialModel) {
        values = this.model.toJSON();
      } else {
        values = this.getModelValues();
      }

      const fieldStatus = this.getIsPhoneAndEmailRequired(values);
      Object.entries(fieldStatus).forEach(([inputId, isRequired]) => {
        this.$(`label[for='${inputId}']`)
          .toggleClass('optional', !isRequired)
          .toggleClass('required', isRequired);
        const $input = this.$(`#${inputId}`).toggleClass('required', isRequired);
        if (!isRequired) {
          $input.removeClass('error');
        }
      });
    },

    toggleYearField: function toggleYearField() {
      if (this.model.get('birthYear')) {
        this.showYearInput();
      }
    },

    getModelValues: function getModelValues() {
      const values = BackboneEx.View.Dialog.ValidatingForm.prototype.getModelValues.call(this);

      if (isValidNumber(values.phone, App.config.get('venue').countryCode)) {
        values.phone = formatInternationalFromInput(
          values.phone,
          App.config.get('venue').countryCode,
        );
      }

      values.birthYear = Wahanda.Text.str2int(values.birthYear);
      values.birthMonth = Wahanda.Text.str2int(values.birthMonth);
      values.birthDay = Wahanda.Text.str2int(values.birthDay);
      values.sendMassEmails = typeof values.sendMassEmails !== 'undefined';
      values.prepaymentRequired = typeof values.prepaymentRequired !== 'undefined';
      values.notes = values.notes === '' ? null : values.notes;

      // Trim whitespace and other symbols
      _.each(['name', 'email'], function (item) {
        values[item] = $.trim(values[item]);
      });

      if (!values.gender) {
        values.gender = null;
      }

      return values;
    },

    _afterSaveCallback: function _afterSaveCallback(options) {
      // eslint-disable-next-line no-underscore-dangle
      BackboneEx.View.Dialog.ValidatingForm.prototype._afterSaveCallback.call(this);
      App.trigger(Wahanda.Event.CUSTOMER_SAVED, this.model, options);
    },

    // Events

    showYearInput: function showYearInput() {
      const $link = this.$('.a-show-year');
      const $block = this.$('.b-birthYear');

      $link.wHide();
      $block.wShow();
    },

    onBirthMonthChange: function onBirthMonthChange() {
      this.$('#consumer-birthDay').valid();
    },

    // This event handler takes care of the status of the phone number:
    // required or optional.
    // The phone is optional as far as only the name is filled in,
    // for a new customer (always required for editing existing customers or
    // when the name is not the only field filled in)
    onFieldFilledIn: function onFieldFilledIn() {
      this.setPhoneNumberAndEmailAsRequired(false);
    },

    save: function save() {
      const modelValues = this.getModelValues();
      if (this.allowWalkInCreation && this.isOnlyNameFilledIn(modelValues)) {
        // walkin saving: no new customer is created
        this.setWalkInCallback(modelValues.name);
        BackboneEx.View.Dialog.ValidatingForm.prototype.resetCloseChangesCheck.call(this);
        this.close();
      } else {
        // executes the default save function
        BackboneEx.View.Dialog.ValidatingForm.prototype.save.call(this);
      }
    },

    disableDialog: function disableDialog() {
      this.disableForm();
      this.$el.loadmask();
    },

    enableDialog: function enableDialog() {
      this.enableForm();
      this.$el.unloadmask();
    },
  });

  BackboneEx.Mixin.View.asMediaQueryAwareDialogView(App.Views.Forms.Customer.prototype);

  /**
   * @param int|App.Models.Customer idOrModel
   * @param BackboneEx.View.Dialog parentDialog (optional) object referencing parent dialog - used to close it after saving data
   */
  App.Views.Forms.Customer.createInstance = function (idOrModel, parentDialog) {
    let model;
    if (typeof idOrModel === 'object') {
      model = idOrModel;
    } else {
      model = new App.Models.Customer({
        id: idOrModel,
      });
    }

    return new App.Views.Forms.Customer({
      model: model,
      parentDialog: parentDialog,
    });
  };
})();
