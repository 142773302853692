/**
 * Settings.
 */
App.Views.Settings.VenueResources = App.Views.Form.extend({
  isVisible: false,

  initialize: function () {
    this.on('visible', this.onVisible, this).on('hidden', this.onHidden, this);

    this.listenTo(this.model, 'fetched', this.renderIfVisible);
    this.listenTo(App, Wahanda.Event.APP_LOAD_STARTED, this.onConfigLoadStarted);

    this.injectReactView();
  },

  onVisible: function () {
    this.isVisible = true;
    this.injectReactView();
  },

  onHidden: function () {
    this.isVisible = false;
  },

  injectReactView: function () {
    App.ES6.Initializers.Resources.inject(this.el, App.getVenueId());
  },

  renderIfVisible: function () {
    if (this.isVisible) {
      this.injectReactView();
    }
  },

  onConfigLoadStarted: function () {
    if (this.isVisible) {
      App.ES6.Initializers.Resources.venueChangeInitiated(this.el);
    }
  },
});
