import { trackEvent, ACTIONS } from 'common/analytics';

import { DurationTypes } from 'components/shared/DurationSelector';

export class AddServicesModalAnalytics {
  public static CATEGORY = 'add-services-modal';

  public static trackAddServicesModalModalView() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.VIEW, 'show-modal');
  }

  public static trackAddServicesAddItemClick(value: number) {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.CLICK, 'service', 'add', value);
  }

  public static trackAddServicesCheckboxRemoveItemClick(value: number) {
    trackEvent(
      AddServicesModalAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'service',
      'checkbox-remove',
      value,
    );
  }

  public static trackAddServicesRemoveItemClick(value: number) {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.CLICK, 'service', 'remove', value);
  }

  public static trackSubmitClick({ property, value }: { property?: number; value: number }) {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.SUBMIT, 'services', property, value);
  }

  public static trackEmployeeEmptySubmit(value: number) {
    trackEvent(
      AddServicesModalAnalytics.CATEGORY,
      ACTIONS.SUBMIT,
      'services',
      'without-employees',
      value,
    );
  }

  public static trackCloseClick() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.CLICK, 'modal-close');
  }

  public static trackSearchUpdate(property: string) {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.UPDATE, 'modal-search', property);
  }

  // Duration selector
  public static trackDurationInputClick() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.CLICK, 'durations-dialog-show');
  }

  public static trackDialogCloseClick() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.CLICK, 'durations-dialog-hide');
  }

  public static trackDurationSelectChange(property: DurationTypes) {
    trackEvent(
      AddServicesModalAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'service-duration-change',
      property,
    );
  }

  public static trackServiceNameUpdate() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.UPDATE, 'service-name-change');
  }

  public static trackServiceSkuNameUpdate() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.UPDATE, 'service-sku-name-change');
  }

  public static trackPriceInputUpdate() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.UPDATE, 'service-price-change');
  }

  public static trackAddServicesSkuAddClick() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.CLICK, 'service-sku', 'add');
  }

  public static trackAddServicesSkuRemoveClick() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.CLICK, 'service-sku', 'remove');
  }

  public static trackAddServiceEmployeeAddView() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.VIEW, 'service-employee');
  }

  public static trackAddServiceEmployeeAddClick() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.CLICK, 'service-employee', 'add');
  }

  public static trackAddServiceEmployeeAddAllClick() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.CLICK, 'service-employee', 'add-all');
  }

  public static trackAddServiceEmployeeRemoveClick() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.CLICK, 'service-employee', 'remove');
  }

  public static trackAddServiceEmployeeRemoveAllClick() {
    trackEvent(AddServicesModalAnalytics.CATEGORY, ACTIONS.CLICK, 'service-employee', 'remove-all');
  }
}
