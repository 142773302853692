/* global BackboneEx */
import App from 'common/backbone-app';
import {
  allEmployeesOptionValue,
  allChairRenters,
  allFullTimeEmployees,
} from 'components/reports/newSalesReports/EmployeeFilter/constants';
import REPORT_TYPE from 'components/reports/newSalesReports/common/reportTypes';
import { TIME_PERIOD_FILTERS } from 'components/reports/ClientReports/timePeriodFilters';

// @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'BackboneEx'.
export const trackEvent = BackboneEx.Tool.Analytics.trackEvent;

// @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'BackboneEx'.
export const trackPageView = BackboneEx.Tool.Analytics.trackPageView;

// @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'BackboneEx'.
export const enableRecording = BackboneEx.Tool.Analytics.enableRecording;

// @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'BackboneEx'.
export const intercomInit = BackboneEx.Tool.Analytics.intercomInit;

// @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'BackboneEx'.
export const seedGenericContext = BackboneEx.Tool.Analytics.seedGenericContext;

export enum ACTIONS {
  VIEW = 'view', // View is sent for every page view or virtual page view and should be consistent across platforms, even if page / screen differs
  CLICK = 'click', // Interaction with a UI element, typically defined in label value
  SWIPE = 'swipe', // Swipe interaction of a UI element (primarily mobile)
  DRAG_AND_DROP = 'drag-and-drop', // Drag & drop of the object
  EDIT = 'edit', // Change UI element without saving the changes in the database
  UPDATE = 'update', // Update an entity in the database
  SUBMIT = 'submit', // Submit of the form (except when an entity is updated).
  CONTACT = 'contact', // Externally trigger contact via phone, email or other means of communication
  PRINT = 'print', // Send something to the printer for printing
  VALIDATION = 'validation', // System-incurred interaction triggered by a validation check
  ERROR = 'error', // System or backend error
}

export class SalesOverviewAnalytics {
  static PAGE_NAME = 'sales-overview-report';

  static trackPageView() {
    trackPageView(SalesOverviewAnalytics.PAGE_NAME);
  }

  static trackReportDateFilterChange(dateRangeType) {
    trackEvent(SalesOverviewAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'date-range', dateRangeType);
  }

  static trackCsvDownloadClick() {
    trackEvent(SalesOverviewAnalytics.PAGE_NAME, ACTIONS.CLICK, 'csv-download');
  }

  static trackChannelFilterChange(channel) {
    trackEvent(SalesOverviewAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'channel-filter', channel);
  }

  static trackEmployeeFilterChange(selectedId) {
    let filterType = '';
    switch (selectedId) {
      case allEmployeesOptionValue:
        filterType = 'all';
        break;
      case allChairRenters:
        filterType = 'self-employed';
        break;
      case allFullTimeEmployees:
        filterType = 'full-time';
        break;
      default:
        filterType = 'individual';
    }
    trackEvent(SalesOverviewAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'employee-filter', filterType);
  }

  static trackNavigateToServiceClick() {
    trackEvent(SalesOverviewAnalytics.PAGE_NAME, ACTIONS.CLICK, 'navigate-to-services');
  }

  static trackNavigateToProductsClick() {
    trackEvent(SalesOverviewAnalytics.PAGE_NAME, ACTIONS.CLICK, 'navigate-to-products');
  }
}

export class ProductSalesReportAnalytics {
  static PAGE_NAME = 'product-sales-reports';

  static trackPageView() {
    trackPageView(ProductSalesReportAnalytics.PAGE_NAME);
  }

  static trackReportDateFilterChange(dateRangeType) {
    trackEvent(ProductSalesReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'date-range', dateRangeType);
  }

  static trackEmployeeFilterChange(selectedId) {
    let filterType = '';
    switch (selectedId) {
      case allEmployeesOptionValue:
        filterType = 'all';
        break;
      case allChairRenters:
        filterType = 'self-employed';
        break;
      case allFullTimeEmployees:
        filterType = 'full-time';
        break;
      default:
        filterType = 'individual';
    }
    trackEvent(
      ProductSalesReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'employee-filter',
      filterType,
    );
  }

  static trackCsvDownloadClick() {
    trackEvent(ProductSalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'csv-download');
  }
}

export class ServiceSalesReportAnalytics {
  static PAGE_NAME = 'service-sales-reports';

  static trackPageView() {
    trackPageView(ServiceSalesReportAnalytics.PAGE_NAME);
  }

  static trackReportDateFilterChange(dateRangeType) {
    trackEvent(ServiceSalesReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'date-range', dateRangeType);
  }

  static trackCsvDownloadClick() {
    trackEvent(ServiceSalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'csv-download');
  }

  static trackChannelFilterChange(channel) {
    trackEvent(ServiceSalesReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'channel-filter', channel);
  }

  static trackEmployeeFilterChange(selectedId) {
    let filterType = '';
    switch (selectedId) {
      case allEmployeesOptionValue:
        filterType = 'all';
        break;
      case allChairRenters:
        filterType = 'self-employed';
        break;
      case allFullTimeEmployees:
        filterType = 'full-time';
        break;
      default:
        filterType = 'individual';
    }
    trackEvent(
      ServiceSalesReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'employee-filter',
      filterType,
    );
  }
}

export class EmployeeSalesReportAnalytics {
  static PAGE_NAME = 'employee-sales-reports';

  static trackPageView() {
    trackPageView(EmployeeSalesReportAnalytics.PAGE_NAME);
  }

  static trackReportDateFilterChange(dateRangeType) {
    trackEvent(EmployeeSalesReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'date-range', dateRangeType);
  }

  static trackEmployeeLinkClick() {
    trackEvent(EmployeeSalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'employee-link');
  }

  static trackCsvDownloadClick() {
    trackEvent(EmployeeSalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'csv-download');
  }
}

export class ChannelSalesReportAnalytics {
  static PAGE_NAME = 'channel-sales-reports';

  static trackPageView() {
    trackPageView(ChannelSalesReportAnalytics.PAGE_NAME);
  }

  static trackReportDateFilterChange(dateRangeType) {
    trackEvent(ChannelSalesReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'date-range', dateRangeType);
  }

  static trackCsvDownloadClick() {
    trackEvent(ChannelSalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'csv-download');
  }

  static trackLinkToServicesSalesClick() {
    trackEvent(ChannelSalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'navigate-to-services');
  }

  static trackLinkToProductsSalesClick() {
    trackEvent(ChannelSalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'navigate-to-products');
  }
}

export class DailySalesReportAnalytics {
  static PAGE_NAME = 'daily-sales-reports';

  static trackPageView() {
    trackPageView(DailySalesReportAnalytics.PAGE_NAME);
  }

  static trackReportDateFilterChange(dateRangeType) {
    trackEvent(DailySalesReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'date-range', dateRangeType);
  }

  static trackCsvDownloadClick() {
    trackEvent(DailySalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'csv-download');
  }

  static trackChannelFilterChange(channel) {
    trackEvent(DailySalesReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'channel-filter', channel);
  }

  static trackEmployeeFilterChange(selectedId) {
    let filterType = '';
    switch (selectedId) {
      case allEmployeesOptionValue:
        filterType = 'all';
        break;
      case allChairRenters:
        filterType = 'self-employed';
        break;
      case allFullTimeEmployees:
        filterType = 'full-time';
        break;
      default:
        filterType = 'individual';
    }
    trackEvent(DailySalesReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'employee-filter', filterType);
  }

  static trackServiceSalesLegendClick(toggled) {
    trackEvent(
      DailySalesReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'service-sales-legend',
      toggled ? 'checked' : 'unchecked',
    );
  }

  static trackProductSalesLegendClick(toggled) {
    trackEvent(
      DailySalesReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'product-sales-legend',
      toggled ? 'checked' : 'unchecked',
    );
  }

  static trackVoucherSalesLegendClick(toggled) {
    trackEvent(
      DailySalesReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'voucher-sales-legend',
      toggled ? 'checked' : 'unchecked',
    );
  }

  static trackDateLinkClick() {
    trackEvent(
      DailySalesReportAnalytics.PAGE_NAME,
      ACTIONS.CLICK,
      'navigate-to-sales-overview',
      'date-link',
    );
  }

  static trackLinkToServicesSalesClick() {
    trackEvent(ChannelSalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'navigate-to-services');
  }

  static trackLinkToProductsSalesClick() {
    trackEvent(ChannelSalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'navigate-to-products');
  }
}

export class MonthlySalesReportAnalytics {
  static PAGE_NAME = 'monthly-sales-reports';

  static trackPageView() {
    trackPageView(MonthlySalesReportAnalytics.PAGE_NAME);
  }

  static trackReportDateFilterChange(dateRangeType) {
    trackEvent(MonthlySalesReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'date-range', dateRangeType);
  }

  static trackCsvDownloadClick() {
    trackEvent(MonthlySalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'csv-download');
  }

  static trackChannelFilterChange(channel) {
    trackEvent(MonthlySalesReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'channel-filter', channel);
  }

  static trackEmployeeFilterChange(selectedId) {
    let filterType = '';
    switch (selectedId) {
      case allEmployeesOptionValue:
        filterType = 'all';
        break;
      case allChairRenters:
        filterType = 'self-employed';
        break;
      case allFullTimeEmployees:
        filterType = 'full-time';
        break;
      default:
        filterType = 'individual';
    }
    trackEvent(
      MonthlySalesReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'employee-filter',
      filterType,
    );
  }

  static trackServiceSalesLegendClick(toggled) {
    trackEvent(
      MonthlySalesReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'service-sales-legend',
      toggled ? 'checked' : 'unchecked',
    );
  }

  static trackProductSalesLegendClick(toggled) {
    trackEvent(
      MonthlySalesReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'product-sales-legend',
      toggled ? 'checked' : 'unchecked',
    );
  }

  static trackVoucherSalesLegendClick(toggled) {
    trackEvent(
      MonthlySalesReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'voucher-sales-legend',
      toggled ? 'checked' : 'unchecked',
    );
  }

  static trackDateLinkClick() {
    trackEvent(
      MonthlySalesReportAnalytics.PAGE_NAME,
      ACTIONS.CLICK,
      'navigate-to-sales-overview',
      'date-link',
    );
  }

  static trackLinkToServicesSalesClick() {
    trackEvent(MonthlySalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'navigate-to-services');
  }

  static trackLinkToProductsSalesClick() {
    trackEvent(MonthlySalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'navigate-to-products');
  }
}

export class VATSalesReportAnalytics {
  static PAGE_NAME = 'vat-sales-reports';

  static trackPageView() {
    trackPageView(VATSalesReportAnalytics.PAGE_NAME);
  }

  static trackReportDateFilterChange(dateRangeType) {
    trackEvent(VATSalesReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'date-range', dateRangeType);
  }

  static trackCsvDownloadClick() {
    trackEvent(VATSalesReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'csv-download');
  }

  static trackEmployeeFilterChange(selectedId) {
    const filterType = selectedId === allEmployeesOptionValue ? 'all' : 'individual';
    trackEvent(VATSalesReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'employee-filter', filterType);
  }
}

export class YearlySalesPeriodReportAnalytics {
  static PAGE_NAME = 'yearly-pos-reports';

  static trackPageView() {
    trackPageView(YearlySalesPeriodReportAnalytics.PAGE_NAME);
  }
}

export class MonthlySalesPeriodReportAnalytics {
  static PAGE_NAME = 'monthly-pos-reports';

  static trackPageView() {
    trackPageView(MonthlySalesPeriodReportAnalytics.PAGE_NAME);
  }
}

export class TransactionReportAnalytics {
  static PAGE_NAME = 'transactions';

  static trackPageView() {
    trackPageView(this.PAGE_NAME);
  }
}

export class BookingsListAnalytics {
  static PAGE_NAME = 'bookings';

  static trackPageView() {
    trackPageView(this.PAGE_NAME);
  }

  static trackOpenOrderDialog() {
    trackEvent(this.PAGE_NAME, ACTIONS.CLICK, 'open-order-dialog');
  }
}

export class SalesReportOldAnalytics {
  static PAGE_NAME = 'sales';

  static trackPageView() {
    trackPageView(this.PAGE_NAME);
  }
}

export class VenueStatsAnalytics {
  static PAGE_NAME = 'stats';

  static LABELS = {
    REPORT_LINK: 'report-link',
  };

  static trackPageView() {
    trackPageView(VenueStatsAnalytics.PAGE_NAME);
  }

  static trackClickLinkSalesThisMonth() {
    trackEvent(
      VenueStatsAnalytics.PAGE_NAME,
      ACTIONS.CLICK,
      VenueStatsAnalytics.LABELS.REPORT_LINK,
      'monthly',
    );
  }

  static trackClickLinkTopServices() {
    trackEvent(
      VenueStatsAnalytics.PAGE_NAME,
      ACTIONS.CLICK,
      VenueStatsAnalytics.LABELS.REPORT_LINK,
      'top-services',
    );
  }

  static trackClickLinkTopEmployees() {
    trackEvent(
      VenueStatsAnalytics.PAGE_NAME,
      ACTIONS.CLICK,
      VenueStatsAnalytics.LABELS.REPORT_LINK,
      'top-employees',
    );
  }

  static trackClickLinkSalesByChannel() {
    trackEvent(
      VenueStatsAnalytics.PAGE_NAME,
      ACTIONS.CLICK,
      VenueStatsAnalytics.LABELS.REPORT_LINK,
      'sales-by-channel',
    );
  }

  static trackClickLinkTotalSales() {
    trackEvent(
      VenueStatsAnalytics.PAGE_NAME,
      ACTIONS.CLICK,
      VenueStatsAnalytics.LABELS.REPORT_LINK,
      'total-sales',
    );
  }

  static trackClickLinkAcquiredClients() {
    trackEvent(
      VenueStatsAnalytics.PAGE_NAME,
      ACTIONS.CLICK,
      VenueStatsAnalytics.LABELS.REPORT_LINK,
      'acquired-clients',
    );
  }

  static trackClickLinkClientRetention() {
    trackEvent(
      VenueStatsAnalytics.PAGE_NAME,
      ACTIONS.CLICK,
      VenueStatsAnalytics.LABELS.REPORT_LINK,
      'retention',
    );
  }
}

export class SalesReportsAnalytics {
  static CATEGORY = 'new-sales-reports';

  static trackChangeReportDropdownClick() {
    trackEvent(SalesReportsAnalytics.CATEGORY, ACTIONS.CLICK, 'reports-dropdown');
  }

  static trackChangeReportDropdownSelected(reportType) {
    let report;
    switch (reportType) {
      case REPORT_TYPE.SALES_OVERVIEW:
        report = SalesOverviewAnalytics.PAGE_NAME;
        break;
      case REPORT_TYPE.PRODUCT_SALES:
        report = ProductSalesReportAnalytics.PAGE_NAME;
        break;
      case REPORT_TYPE.SERVICE_SALES:
        report = ServiceSalesReportAnalytics.PAGE_NAME;
        break;
      case REPORT_TYPE.EMPLOYEE_SALES:
        report = EmployeeSalesReportAnalytics.PAGE_NAME;
        break;
      case REPORT_TYPE.CHANNEL_SALES:
        report = ChannelSalesReportAnalytics.PAGE_NAME;
        break;
      case REPORT_TYPE.DAILY_SALES:
        report = DailySalesReportAnalytics.PAGE_NAME;
        break;
      case REPORT_TYPE.MONTHLY_SALES:
        report = MonthlySalesReportAnalytics.PAGE_NAME;
        break;
      case REPORT_TYPE.VAT:
        report = VATSalesReportAnalytics.PAGE_NAME;
        break;
      default:
        report = 'unknown';
        break;
    }
    trackEvent(SalesReportsAnalytics.CATEGORY, ACTIONS.UPDATE, 'reports-dropdown-select', report);
  }
}

export class CheckoutPaymentAnalytics {
  static CATEGORY = 'payment';

  static trackSalonVoucherTypeClick(voucherType) {
    let voucher;
    switch (voucherType) {
      case App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.SINGLE_VAT:
        voucher = '<sold after 2019-01-01>';
        break;
      case App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.MULTI_VAT:
      default:
        voucher = '<sold before 2019-01-01>';
    }
    trackEvent(CheckoutPaymentAnalytics.CATEGORY, ACTIONS.CLICK, 'add-voucher', voucher);
  }

  static trackThirdPartyVoucherClick() {
    trackEvent(CheckoutPaymentAnalytics.CATEGORY, ACTIONS.CLICK, 'add-3rd-party-voucher');
  }

  static trackOtherPaymentClick() {
    trackEvent(CheckoutPaymentAnalytics.CATEGORY, ACTIONS.CLICK, 'add-other-payment');
  }

  static trackCashPaymentAdd() {
    trackEvent(CheckoutPaymentAnalytics.CATEGORY, ACTIONS.EDIT, 'add', 'CASH');
  }

  static trackCardPaymentAdd() {
    trackEvent(CheckoutPaymentAnalytics.CATEGORY, ACTIONS.EDIT, 'add', 'CARD');
  }
}

export class CheckoutAnalytics {
  static CATEGORY = 'checkout';

  static trackEditVoucherClick() {
    trackEvent(CheckoutAnalytics.CATEGORY, ACTIONS.CLICK, 'edit-voucher');
  }

  static trackEditProductClick() {
    trackEvent(CheckoutAnalytics.CATEGORY, ACTIONS.CLICK, 'edit-product');
  }

  static trackEditServiceClick() {
    trackEvent(CheckoutAnalytics.CATEGORY, ACTIONS.CLICK, 'edit-service');
  }

  static trackEditPackageClick() {
    trackEvent(CheckoutAnalytics.CATEGORY, ACTIONS.CLICK, 'edit-package');
  }
}

export class CheckoutServiceAnalytics {
  static CATEGORY = 'service-line';

  static trackUpdateDiscount(discount) {
    trackEvent(CheckoutServiceAnalytics.CATEGORY, ACTIONS.UPDATE, 'discount', discount);
  }

  static trackClearDiscount() {
    trackEvent(CheckoutServiceAnalytics.CATEGORY, ACTIONS.UPDATE, 'clear-discount');
  }
}

export class CheckoutPackageAnalytics {
  static CATEGORY = 'package-line';

  static trackUpdateDiscount(discount) {
    trackEvent(CheckoutPackageAnalytics.CATEGORY, ACTIONS.UPDATE, 'discount', discount);
  }

  static trackClearDiscount() {
    trackEvent(CheckoutPackageAnalytics.CATEGORY, ACTIONS.UPDATE, 'clear-discount');
  }
}

export class CheckoutProductAnalytics {
  static CATEGORY = 'product-line';

  static trackSellingEmployeeDropdownClick() {
    trackEvent(CheckoutProductAnalytics.CATEGORY, ACTIONS.CLICK, 'sold-by');
  }

  static trackUpdateDiscount(discount) {
    trackEvent(CheckoutProductAnalytics.CATEGORY, ACTIONS.UPDATE, 'discount', discount);
  }

  static trackClearDiscount() {
    trackEvent(CheckoutProductAnalytics.CATEGORY, ACTIONS.UPDATE, 'clear-discount');
  }

  static trackSellingEmployeeUpdate() {
    trackEvent(CheckoutProductAnalytics.CATEGORY, ACTIONS.UPDATE, 'change-employee');
  }
}

export class CheckoutVoucherAnalytics {
  static CATEGORY = 'voucher-line';

  static trackSellingEmployeeDropdownClick() {
    trackEvent(CheckoutVoucherAnalytics.CATEGORY, ACTIONS.CLICK, 'sold-by');
  }

  static trackUpdateDiscount(discount) {
    trackEvent(CheckoutVoucherAnalytics.CATEGORY, ACTIONS.UPDATE, 'discount', discount);
  }

  static trackClearDiscount() {
    trackEvent(CheckoutVoucherAnalytics.CATEGORY, ACTIONS.UPDATE, 'clear-discount');
  }

  static trackSellingEmployeeUpdate() {
    trackEvent(CheckoutVoucherAnalytics.CATEGORY, ACTIONS.UPDATE, 'change-employee');
  }
}

export class CalendarAnalytics {
  static CATEGORY = 'calendar';

  static trackCancelAppointmentClicked() {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.CLICK, 'cancellation');
  }

  static trackPosLiteStandaloneProductCheckoutClick() {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.CLICK, 'pos-lite-checkout');
  }

  static trackPosLiteCheckoutDialogView() {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.VIEW, 'pos-lite-checkout-dialog');
  }

  static trackPosLiteCheckoutDialogSubmit() {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.SUBMIT, 'pos-lite-checkout-dialog');
  }

  static trackLookingForPOSClick() {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.CLICK, 'looking-for-pos');
  }

  static BLOCK_TYPES = {
    D: 'day',
    P: 'period',
    A: 'daily',
    W: 'weekly',
    B: 'bi-weekly',
  };

  static trackAppointmentCreateSubmitClick() {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.SUBMIT, 'appointment');
  }

  static trackAppointmentCreateCancelClick() {
    trackEvent(CalendarAnalytics.CATEGORY, ACTIONS.CLICK, 'appointment-close-button');
  }

  static trackBlockCreateSubmitClick({ property }) {
    trackEvent(
      CalendarAnalytics.CATEGORY,
      ACTIONS.SUBMIT,
      'block',
      CalendarAnalytics.BLOCK_TYPES[property],
    );
  }

  static trackBlockCreateCancelClick({ property }) {
    trackEvent(
      CalendarAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'block-close-button',
      CalendarAnalytics.BLOCK_TYPES[property],
    );
  }

  static trackClickHideNonWorking(isChecked) {
    trackEvent(
      CalendarAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'hide-non-working',
      isChecked ? 'checked' : 'unchecked',
    );
  }

  static trackUDVSelectChange(selectedValue, typedValue) {
    trackEvent('service-search', 'click', 'selected', {
      userSelected: selectedValue,
      userTyped: typedValue,
    });
  }

  static trackServiceShowMoreClick() {
    trackEvent('service-search', 'click', 'show-all-services', true);
  }
}

export class CancellationFlowAnalytics {
  static CATEGORY = 'cancellation-flow';

  static trackPaymentProtection({ preventPaymentProtection }) {
    trackEvent(
      CancellationFlowAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'payment-protection',
      preventPaymentProtection ? 'dont-charge-client' : 'charge-client',
    );
  }

  static trackPaymentProtectionSubmit({ preventPaymentProtection }) {
    trackEvent(
      CancellationFlowAnalytics.CATEGORY,
      ACTIONS.SUBMIT,
      'payment-protection',
      preventPaymentProtection ? 'dont-charge-client' : 'charge-client',
    );
  }

  static trackViewCancellationInitiationOnCancel() {
    trackEvent(
      CancellationFlowAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'cancellation-initiation',
      'on-cancel',
    );
  }

  static trackViewCancellationInitiationOnReject() {
    trackEvent(
      CancellationFlowAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'cancellation-initiation',
      'on-reject',
    );
  }

  static trackClickRescheduleButton() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.CLICK, 'reschedule-button');
  }

  static trackClickCancelButton() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.CLICK, 'cancellation-button');
  }

  static trackRescheduleConfirmed() {
    trackEvent(
      CancellationFlowAnalytics.CATEGORY,
      ACTIONS.DRAG_AND_DROP,
      'reschedule',
      'confirmed',
    );
  }

  static trackRescheduleCancelled() {
    trackEvent(
      CancellationFlowAnalytics.CATEGORY,
      ACTIONS.DRAG_AND_DROP,
      'reschedule',
      'cancelled',
    );
  }

  static trackCancellationReasonInitiation() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.VIEW, 'cancellation-reason');
  }

  static trackOrderCancellationInitiation() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.VIEW, 'cancel-appointment-or-order');
  }

  static trackCancelEntireOrder() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.CLICK, 'cancel-entire-order');
  }

  static trackCancelSingleAppointment() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.CLICK, 'cancel-single-appointment');
  }

  static trackVenueLedCancellation() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.CLICK, 'venue-led-cancellation');
  }

  static trackCustomerLedCancellation() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.CLICK, 'customer-led-cancellation');
  }

  static trackCoronaLedCancellation() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.CLICK, 'covid-19-led-cancellation');
  }

  static trackRefundInitiation() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.VIEW, 'refund-screen');
  }

  static trackRefundRequest() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.CLICK, 'request-refund');
  }

  static trackNoRefundRequested() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.CLICK, 'no-refund');
  }

  static trackCancellationSuccessful() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.VIEW, 'cancellation-confirmation');
  }

  static trackViewRequestCancellation() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.VIEW, 'request-cancellation');
  }

  static trackClickRequestCancellationForm() {
    trackEvent(CancellationFlowAnalytics.CATEGORY, ACTIONS.CLICK, 'request-cancellation-continue');
  }

  static trackClickCancelFutureAppointments(checked: boolean) {
    trackEvent(
      CancellationFlowAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'include-future-recurrences',
      checked ? 'yes' : 'no',
    );
  }

  static trackSubmitCancelFutureAppointments(checked: boolean) {
    trackEvent(
      CancellationFlowAnalytics.CATEGORY,
      ACTIONS.SUBMIT,
      'include-future-recurrences',
      checked ? 'yes' : 'no',
    );
  }
}

export class NoShowFlowAnalytics {
  static CATEGORY = 'no-show-flow';

  static trackNoShowOverPhone() {
    trackEvent(NoShowFlowAnalytics.CATEGORY, ACTIONS.VIEW, 'please-call');
  }

  static trackNoShowModalView() {
    trackEvent(NoShowFlowAnalytics.CATEGORY, ACTIONS.VIEW, 'no-show-reason');
  }

  static trackNoShowConfirmation() {
    trackEvent(NoShowFlowAnalytics.CATEGORY, ACTIONS.CLICK, 'no-show-confirmation');
  }

  static trackPaymentProtectionSubmit({ preventPaymentProtection }) {
    trackEvent(
      NoShowFlowAnalytics.CATEGORY,
      ACTIONS.SUBMIT,
      'payment-protection',
      preventPaymentProtection ? 'dont-charge-client' : 'charge-client',
    );
  }

  static trackPaymentProtection({ preventPaymentProtection }) {
    trackEvent(
      NoShowFlowAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'payment-protection',
      preventPaymentProtection ? 'dont-charge-client' : 'charge-client',
    );
  }
}

export class OnlineBookingWidgetAnalytics {
  static CATEGORY = 'booking-widget';

  static trackWidgetToggleEnabled() {
    trackEvent(OnlineBookingWidgetAnalytics.CATEGORY, ACTIONS.SUBMIT, 'save-booking-widget');
  }

  static trackWidgetPreview(section) {
    trackEvent(
      OnlineBookingWidgetAnalytics.CATEGORY,
      ACTIONS.CLICK,
      `preview${section ? `-${section}` : ''}`,
    );
  }

  static trackWidgetCopied(type) {
    trackEvent(OnlineBookingWidgetAnalytics.CATEGORY, ACTIONS.CLICK, `copied-${type}`);
  }

  static trackLinkClick(type) {
    trackEvent(OnlineBookingWidgetAnalytics.CATEGORY, ACTIONS.CLICK, `link-${type}`);
  }
}

export class AccountLanguageSelectAnalytics {
  static CATEGORY = 'change-language';

  static trackView() {
    trackEvent(AccountLanguageSelectAnalytics.CATEGORY, ACTIONS.VIEW, 'language-selection');
  }

  static trackApply(venueLocale, userLocale) {
    trackEvent(
      AccountLanguageSelectAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'apply',
      `venueLocale=${venueLocale}&userLocale=${userLocale}`,
    );
  }

  static trackClose() {
    trackEvent(AccountLanguageSelectAnalytics.CATEGORY, ACTIONS.CLICK, 'close');
  }
}

export class EmailCampaignAnalytics {
  static CATEGORY = 'crm-template';

  static trackPageView() {
    trackPageView(EmailCampaignAnalytics.CATEGORY);
  }
}

export class EmailCampaignSelectionAnalytics {
  static CATEGORY = 'crm-template';

  static PAGE_NAME = 'choose-campaign';

  static PROPERTY = 'marketing-tab';

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'PROPERTY' does not exist on type 'Functi... Remove this comment to see the full error message
  static trackPageView(property = this.constructor.PROPERTY) {
    trackEvent(
      EmailCampaignSelectionAnalytics.CATEGORY,
      ACTIONS.VIEW,
      EmailCampaignSelectionAnalytics.PAGE_NAME,
      property,
    );
  }

  static trackCampaignCardClick() {
    trackEvent(
      EmailCampaignSelectionAnalytics.CATEGORY,
      ACTIONS.CLICK,
      EmailCampaignSelectionAnalytics.PAGE_NAME,
      'next',
    );
  }

  static trackComingSoonCampaignClick(type) {
    trackEvent(
      EmailCampaignSelectionAnalytics.CATEGORY,
      ACTIONS.CLICK,
      EmailCampaignSelectionAnalytics.PAGE_NAME,
      type,
    );
  }
}

export class EmailCampaignPreviewAnalytics {
  static CATEGORY = 'crm-template';

  static PAGE_NAME = 'preview';

  static PROPERTY = 'marketing-tab';

  static trackError() {
    trackEvent(
      EmailCampaignPreviewAnalytics.CATEGORY,
      ACTIONS.ERROR,
      EmailCampaignPreviewAnalytics.PAGE_NAME,
      'send-email-error',
    );
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'PROPERTY' does not exist on type 'Functi... Remove this comment to see the full error message
  static trackPageView(property = this.constructor.PROPERTY) {
    trackEvent(
      EmailCampaignPreviewAnalytics.CATEGORY,
      ACTIONS.VIEW,
      EmailCampaignPreviewAnalytics.PAGE_NAME,
      property,
    );
  }

  static trackClientsLinkClick() {
    trackEvent(
      EmailCampaignPreviewAnalytics.CATEGORY,
      ACTIONS.CLICK,
      EmailCampaignPreviewAnalytics.PAGE_NAME,
      'client-list',
    );
  }

  static trackBackBtnClick() {
    trackEvent(
      EmailCampaignPreviewAnalytics.CATEGORY,
      ACTIONS.CLICK,
      EmailCampaignPreviewAnalytics.PAGE_NAME,
      'previous',
    );
  }

  static trackNextBtnClick() {
    trackEvent(
      EmailCampaignPreviewAnalytics.CATEGORY,
      ACTIONS.CLICK,
      EmailCampaignPreviewAnalytics.PAGE_NAME,
      'next',
    );
  }

  static trackOpenWidgetSettings() {
    trackEvent(
      EmailCampaignPreviewAnalytics.CATEGORY,
      ACTIONS.CLICK,
      EmailCampaignPreviewAnalytics.PAGE_NAME,
      'widget-settings',
    );
  }
}

export class EmailCampaignSummaryAnalytics {
  static CATEGORY = 'crm-template';

  static PAGE_NAME = 'set-audience';

  static PROPERTY = 'marketing-tab';

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'PROPERTY' does not exist on type 'Functi... Remove this comment to see the full error message
  static trackPageView(property = this.constructor.PROPERTY) {
    trackEvent(
      EmailCampaignSummaryAnalytics.CATEGORY,
      ACTIONS.VIEW,
      EmailCampaignSummaryAnalytics.PAGE_NAME,
      property,
    );
  }

  static trackBackBtnClick() {
    trackEvent(
      EmailCampaignSummaryAnalytics.CATEGORY,
      ACTIONS.CLICK,
      EmailCampaignSummaryAnalytics.PAGE_NAME,
      'previous',
    );
  }

  static trackNextBtnClick() {
    trackEvent(
      EmailCampaignSummaryAnalytics.CATEGORY,
      ACTIONS.CLICK,
      EmailCampaignSummaryAnalytics.PAGE_NAME,
      'send-email',
    );
  }
}

export class EmailCampaignSuccessAnalytics {
  static CATEGORY = 'crm-template';

  static PAGE_NAME = 'confirmation';

  static PROPERTY = 'marketing-tab';

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'PROPERTY' does not exist on type 'Functi... Remove this comment to see the full error message
  static trackPageView(property = this.constructor.PROPERTY) {
    trackEvent(
      EmailCampaignSuccessAnalytics.CATEGORY,
      ACTIONS.VIEW,
      EmailCampaignSuccessAnalytics.PAGE_NAME,
      property,
    );
  }

  static trackFeedbackBtnClick() {
    trackEvent(
      EmailCampaignSuccessAnalytics.CATEGORY,
      ACTIONS.CLICK,
      EmailCampaignSuccessAnalytics.PAGE_NAME,
      'send-feedback',
    );
  }
}

export class ProductSuppliersAnalytics {
  static CATEGORY = 'product-suppliers';

  static trackListView() {
    trackEvent(ProductSuppliersAnalytics.CATEGORY, ACTIONS.VIEW, 'suppliers-list');
  }

  static trackEmptyListView() {
    trackEvent(ProductSuppliersAnalytics.CATEGORY, ACTIONS.VIEW, 'empty-list');
  }

  static trackNewSupplierFromEmptyListClick() {
    trackEvent(ProductSuppliersAnalytics.CATEGORY, ACTIONS.CLICK, 'empty-list', 'new-supplier');
  }

  static trackNewSupplierFromSuppliersListClick() {
    trackEvent(ProductSuppliersAnalytics.CATEGORY, ACTIONS.CLICK, 'suppliers-list', 'new-supplier');
  }

  static trackSupplierDetailsView() {
    trackEvent(ProductSuppliersAnalytics.CATEGORY, ACTIONS.VIEW, 'supplier-details');
  }

  static trackSupplierDetailsClose() {
    trackEvent(ProductSuppliersAnalytics.CATEGORY, ACTIONS.CLICK, 'supplier-details', 'close');
  }

  static trackSupplierDetailsSaveClick() {
    trackEvent(ProductSuppliersAnalytics.CATEGORY, ACTIONS.CLICK, 'supplier-details', 'save');
  }

  static trackSupplierDetailsDeleteClick() {
    trackEvent(ProductSuppliersAnalytics.CATEGORY, ACTIONS.CLICK, 'supplier-details', 'delete');
  }

  static trackSupplierInfoTabClick() {
    trackEvent(
      ProductSuppliersAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'supplier-details',
      'supplier-info',
    );
  }

  static trackAssignedProductsTabClick() {
    trackEvent(
      ProductSuppliersAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'supplier-details',
      'assigned-products',
    );
  }

  static trackAssignedProductsEmptyListView() {
    trackEvent(ProductSuppliersAnalytics.CATEGORY, ACTIONS.VIEW, 'assigned-products-empty-list');
  }

  static trackAssignedProductsEmptyListButtonClick() {
    trackEvent(
      ProductSuppliersAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'assigned-products-empty-list',
      'go-to-products-page',
    );
  }
}

export class OrderProducts {
  static CATEGORY = 'orders';

  static trackEmptyListView() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.VIEW, 'empty-list');
  }

  static trackNewOrderClickViaEmptyList() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'empty-list', 'new-order');
  }

  static trackListView() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.VIEW, 'orders-list');
  }

  static trackNewOrderClickViaNotEmptyList() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'orders-list', 'new-order');
  }

  static trackListItemClick() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'orders-list', 'order-item');
  }

  static trackSuppliersView() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.VIEW, 'product-suppliers');
  }

  static trackSuppliersAutoSelectNoSuppliers() {
    trackEvent(
      OrderProducts.CATEGORY,
      ACTIONS.UPDATE,
      'product-suppliers',
      'auto-select-no-supplier',
    );
  }

  static trackSuppliersAutoSelect() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.UPDATE, 'product-suppliers', 'auto-select-supplier');
  }

  static trackSupplierClick() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'product-suppliers', 'supplier');
  }

  static trackSuppliersClose() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'product-suppliers', 'close');
  }

  static trackViewOrderProducts() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.VIEW, 'order-products');
  }

  static trackViewOrderProductsEmptyList() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.VIEW, 'order-products-empty-list');
  }

  static trackClickOrderProductsEmptyListButton() {
    trackEvent(
      OrderProducts.CATEGORY,
      ACTIONS.CLICK,
      'order-products-empty-list',
      'go-to-products-page',
    );
  }

  static trackClickCancelOrderProducts() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'order-products', 'cancel');
  }

  static trackClickPlaceOrder() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'order-products', 'place-order');
  }

  static trackClickCloseButton() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'order-products', 'close-button');
  }

  static trackViewOrderHasBeenPlaced() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.VIEW, 'order-details', 'ordering');
  }

  static trackViewOrderedItem() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.VIEW, 'order-details', 'ordered');
  }

  static trackViewCompletedOrder() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.VIEW, 'order-details', 'completed');
  }

  static trackClickDownloadPdf() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'order-details', 'download');
  }

  static trackClickCancelOrder() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'order-details', 'cancel-order');
  }

  static trackClickCloseDialog() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'order-details', 'close-button');
  }

  static trackClickProcessOrder() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'order-details', 'process-order');
  }

  static trackViewProcessOrderDialog() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.VIEW, 'order-processing');
  }

  static trackClickCancelProcessing() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'order-processing', 'cancel');
  }

  static trackClickCloseOrderProcessing() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'order-processing', 'close-button');
  }

  static trackClickCompleteOrder() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'order-processing', 'complete');
  }

  static trackClickProcessMinusButton() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'order-processing', 'minus-button');
  }

  static trackClickProcessPlusButton() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.CLICK, 'order-processing', 'plus-button');
  }

  static trackUpdateProcessOrdersAmount() {
    trackEvent(OrderProducts.CATEGORY, ACTIONS.UPDATE, 'order-processing', 'amount');
  }
}

export class IntegrationSetupAnalytics {
  static CATEGORY = 'online-booking-integrations';

  static trackIntegrationSetupView() {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.VIEW, 'setup-initiation');
  }

  static trackExternalSystemDropdownClick() {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.CLICK, 'integration-dropdown');
  }

  static trackExternalSystemDropdownSelect(key) {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.UPDATE, 'integration-dropdown', key);
  }

  static trackTestConnectionClick() {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.CLICK, 'test-connection-button');
  }

  static trackConnectionErrorView() {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.VIEW, 'test-connection-error');
  }

  static trackConnectionSuccessView() {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.VIEW, 'test-connection-success');
  }

  static trackEmployeeMappingView() {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.VIEW, 'employee-mapping');
  }

  static trackEmployeeMappingDropdownClick() {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.CLICK, 'employee-dropdown');
  }

  static trackEmployeeMappingDropdownSelect(employeeId) {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.UPDATE, 'employee-dropdown', employeeId);
  }

  static trackFinishSetupClick() {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.CLICK, 'confirm-button');
  }

  static trackLinkedCalendarView() {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.VIEW, 'linked-calendar');
  }

  static trackDeleteIntegrationClick() {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.CLICK, 'delete-integration');
  }

  static trackCancelSetupClick() {
    trackEvent(IntegrationSetupAnalytics.CATEGORY, ACTIONS.CLICK, 'cancel-setup');
  }
}

export class ClientRetentionReportAnalytics {
  static PAGE_NAME = 'client-retention-report';

  static trackPageView() {
    trackPageView(ClientRetentionReportAnalytics.PAGE_NAME);
  }

  static trackChannelFilterChange(channel) {
    trackEvent(ClientRetentionReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'channel-filter', channel);
  }

  static trackDateFilterChange(dateRangeType) {
    trackEvent(
      ClientRetentionReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'date-range',
      dateRangeType,
    );
  }

  static trackEmployeeFilterChange(selectedId) {
    const filterType = selectedId === allEmployeesOptionValue ? 'all' : 'individual';
    trackEvent(
      ClientRetentionReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'employee-filter',
      filterType,
    );
  }

  static trackAcquiredClientsLegendClick(toggled) {
    trackEvent(
      ClientRetentionReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'acquired-clients-legend',
      toggled ? 'checked' : 'unchecked',
    );
  }

  static trackClientRetentionLegendClick(toggled) {
    trackEvent(
      ClientRetentionReportAnalytics.PAGE_NAME,
      ACTIONS.UPDATE,
      'client-retention-legend',
      toggled ? 'checked' : 'unchecked',
    );
  }

  static trackClientRetentionAvarageInfoLinkClick() {
    trackEvent(ClientRetentionReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'info-box', 'average');
  }

  static trackClientRetentionHowWorksClick() {
    trackEvent(ClientRetentionReportAnalytics.PAGE_NAME, ACTIONS.CLICK, 'info-box', 'how-works');
  }

  static trackDatePeriodFilterChange(selectedPeriod) {
    let period;
    switch (selectedPeriod) {
      case TIME_PERIOD_FILTERS.ALL_TIME.value:
        period = 'all-time';
        break;
      case TIME_PERIOD_FILTERS.LAST_YEAR.value:
        period = 'last-year';
        break;
      case TIME_PERIOD_FILTERS.THIS_YEAR.value:
        period = 'this-year';
        break;
      case TIME_PERIOD_FILTERS.LAST_TWELVE_MONTHS.value:
        period = 'last-twelve-months';
        break;
      default:
        period = 'unknown';
    }
    trackEvent(ClientRetentionReportAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'date-picker', period);
  }
}

export class ImpressumAnalytics {
  static CATEGORY = 'impressum';

  static trackNonEmptyClick() {
    trackEvent(ImpressumAnalytics.CATEGORY, ACTIONS.CLICK, 'save');
  }
}

export class ClientAcquisitionAnalytics {
  static PAGE_NAME = 'client-acquisition-report';

  static trackPageView() {
    trackPageView(ClientAcquisitionAnalytics.PAGE_NAME);
  }

  static trackEmployeeFilterChange(selectedId) {
    const filterType = selectedId === allEmployeesOptionValue ? 'all' : 'individual';
    trackEvent(ClientAcquisitionAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'employee-filter', filterType);
  }

  static trackDatePeriodFilterChange(selectedPeriod) {
    let period;
    switch (selectedPeriod) {
      case TIME_PERIOD_FILTERS.ALL_TIME.value:
        period = 'all-time';
        break;
      case TIME_PERIOD_FILTERS.LAST_YEAR.value:
        period = 'last-year';
        break;
      case TIME_PERIOD_FILTERS.THIS_YEAR.value:
        period = 'this-year';
        break;
      case TIME_PERIOD_FILTERS.LAST_TWELVE_MONTHS.value:
        period = 'last-twelve-months';
        break;
      default:
        period = 'unknown';
    }
    trackEvent(ClientAcquisitionAnalytics.PAGE_NAME, ACTIONS.UPDATE, 'date-picker', period);
  }
}

export class SideBarAnalytics {
  static CATEGORY = 'side-bar';

  static trackReferSalonClick() {
    trackEvent(SideBarAnalytics.CATEGORY, ACTIONS.CLICK, 'refer-salon');
  }

  static trackHelpCenterClick() {
    trackEvent(SideBarAnalytics.CATEGORY, ACTIONS.CLICK, 'help-center');
  }

  static trackGettingStartedGuideClick() {
    trackEvent(SideBarAnalytics.CATEGORY, ACTIONS.CLICK, 'getting-started-guide');
  }

  static trackWhatIsNewClick({ property }) {
    trackEvent(SideBarAnalytics.CATEGORY, ACTIONS.CLICK, 'what-is-new', property);
  }

  static trackGetHelpClick() {
    trackEvent(SideBarAnalytics.CATEGORY, ACTIONS.CLICK, 'get-help');
  }
}

export class PromotionalOffersCampaignInitiation {
  static CATEGORY = 'discount-campaign-init';

  static trackSmartDiscountsInitFromList() {
    trackEvent(
      PromotionalOffersCampaignInitiation.CATEGORY,
      ACTIONS.CLICK,
      'smart-discounts',
      'from-list',
    );
  }

  static trackDiscountEmailDialogShown() {
    trackEvent(
      PromotionalOffersCampaignInitiation.CATEGORY,
      ACTIONS.CLICK,
      'smart-discounts',
      'discount-email-dialog-shown',
    );
  }

  static trackSmartDiscountsInitFromSave() {
    trackEvent(
      PromotionalOffersCampaignInitiation.CATEGORY,
      ACTIONS.CLICK,
      'smart-discounts',
      'from-save',
    );
  }

  static trackDoNotShow() {
    trackEvent(
      PromotionalOffersCampaignInitiation.CATEGORY,
      ACTIONS.CLICK,
      'smart-discounts',
      'do-not-show',
    );
  }

  static trackServicePromo() {
    trackEvent(
      PromotionalOffersCampaignInitiation.CATEGORY,
      ACTIONS.CLICK,
      'service-on-sale',
      'from-services-footer',
    );
  }
}

export class PartnerSiteSettings {
  static CATEGORY = 'partner-site-settings';

  static trackMarketingLinkClicked() {
    trackEvent(PartnerSiteSettings.CATEGORY, ACTIONS.CLICK, 'redirect-to-marketing');
  }
}

export class UDVAppAdvocacyBanner {
  static CATEGORY = 'app-advocacy-banner';

  static trackUDVAppAdvocacyBannerView() {
    trackEvent(UDVAppAdvocacyBanner.CATEGORY, ACTIONS.VIEW, 'banner');
  }

  static trackInviteBySmsFromBannerClick(delta) {
    trackEvent(UDVAppAdvocacyBanner.CATEGORY, ACTIONS.CLICK, 'invite-by-sms', 'from-banner', delta);
  }
}

export class VenueImagesAnalytics {
  static CATEGORY = 'venue-images';

  static trackReorder(id: number) {
    trackEvent(VenueImagesAnalytics.CATEGORY, ACTIONS.CLICK, 'reorder', id);
  }

  static trackDelete(id: number) {
    trackEvent(VenueImagesAnalytics.CATEGORY, ACTIONS.CLICK, 'delete', id);
  }

  static trackMultiUpload() {
    trackEvent(VenueImagesAnalytics.CATEGORY, ACTIONS.CLICK, 'multi-upload');
  }

  static trackError(error: string, property?: string) {
    trackEvent(VenueImagesAnalytics.CATEGORY, ACTIONS.ERROR, error, property);
  }
}
