import {
  OFFER_DIALOG_OPEN,
  OFFER_DIALOG_PACKAGE_PRICING_SKUS_CHANGE,
  OFFER_DIALOG_CLOSE,
  OFFER_DIALOG_CHANGE_PRICING_TYPE,
  OFFER_DIALOG_TOGGLE_SKU,
  OFFER_DIALOG_UPDATE_FULL_PRICE_AMOUNT,
  OFFER_DIALOG_PACKAGE_SERVICES_CHANGE,
  OFFER_DIALOG_PACKAGE_MASTER_SERVICE_CHANGE,
  OFFER_DIALOG_PRICING_AND_EMPLOYEES_READY,
  OFFER_DIALOG_CHANGE_USE_EMPLOYEE_CATEGORIES,
  OFFER_DIALOG_CHANGE_EMPLOYEE,
  OFFER_DIALOG_SELECT_ALL_EMPLOYEES,
  OFFER_DIALOG_DESELECT_ALL_EMPLOYEES,
  OFFER_DIALOG_OFFER_NAME_CHANGE,
  OFFER_DIALOG_CHANGE_ALLOW_PROCESSING,
  OFFER_DIALOG_SET_PROCESSING_TIME,
  TRACK_SERVICE_EDIT,
  OFFER_DIALOG_SAVED,
  RESET_SERVICE_EDIT_TRACKING,
} from 'reduxStore/actionsConst';

export const offerDialogChangePricingTypeAction = (isPackagePricingCustom) => ({
  type: OFFER_DIALOG_CHANGE_PRICING_TYPE,
  isPackagePricingCustom,
});

export const offerDialogOpenAction = (data) => ({
  type: OFFER_DIALOG_OPEN,
  data,
});

export const offerDialogPackageSkusChangeAction = (offerDialogSkus) => ({
  type: OFFER_DIALOG_PACKAGE_PRICING_SKUS_CHANGE,
  offerDialogSkus,
});

export const offerDalogCloseAction = () => ({ type: OFFER_DIALOG_CLOSE });

export const offerDialogToggleSkuAction = ({ indexArray, visible }) => ({
  type: OFFER_DIALOG_TOGGLE_SKU,
  indexArray,
  visible,
});

export const offerDialogUpdateFullPriceAmountAction = ({ index, fullPriceAmount }) => ({
  type: OFFER_DIALOG_UPDATE_FULL_PRICE_AMOUNT,
  index,
  fullPriceAmount,
});

export const offerDialogPackageServicesChange = ({
  offerDialogSubServices,
  offerDialogInlineServices,
  offerDialogSkus,
}) => ({
  type: OFFER_DIALOG_PACKAGE_SERVICES_CHANGE,
  offerDialogSubServices,
  offerDialogInlineServices,
  offerDialogSkus,
});

export const offerDialogPackageServicesMasterChange = ({ offerId, tempId }) => ({
  type: OFFER_DIALOG_PACKAGE_MASTER_SERVICE_CHANGE,
  offerId,
  tempId,
});

export const offerDialogPricingAndEmployeesReady = (data) => ({
  type: OFFER_DIALOG_PRICING_AND_EMPLOYEES_READY,
  data,
});

export const offerDialogChangeUseEmployeeCategoriesAction = (use) => ({
  type: OFFER_DIALOG_CHANGE_USE_EMPLOYEE_CATEGORIES,
  use,
});

export const offerDialogChangeEmployeeAction = (employeeId, shouldInclude) => ({
  type: OFFER_DIALOG_CHANGE_EMPLOYEE,
  employeeId,
  shouldInclude,
});

export const offerDialogSelectAllEmployeesAction = () => ({
  type: OFFER_DIALOG_SELECT_ALL_EMPLOYEES,
});

export const offerDialogDeselectAllEmployeesAction = () => ({
  type: OFFER_DIALOG_DESELECT_ALL_EMPLOYEES,
});

export const offerDialogAllowProcessingChangeAction = (allow) => ({
  type: OFFER_DIALOG_CHANGE_ALLOW_PROCESSING,
  allow,
});

export const offerDialogSetProcessingTime = (time) => ({
  type: OFFER_DIALOG_SET_PROCESSING_TIME,
  payload: time,
});

export const offerDialogNameChangeAction = (name) => ({
  type: OFFER_DIALOG_OFFER_NAME_CHANGE,
  name,
});

export const resetServiceEditTrackingAction = () => ({
  type: RESET_SERVICE_EDIT_TRACKING,
});

export const trackServiceEditAction = (label) => ({
  type: TRACK_SERVICE_EDIT,
  label,
});

export const offerDialogSaved = (payload) => ({
  type: OFFER_DIALOG_SAVED,
  payload,
});
