export enum ExportType {
  NF525 = 'NF525',
  GDPDU = 'GDPDU',
  DSFINVK = 'DSFINVK',
  TRANSACTIONS = 'TRANSACTIONS',
}

export enum ExportStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface TransactionExports {
  id: number;
  filename: string;
  type: ExportType;
  status: ExportStatus;
  lastModificationDate: string;
}
