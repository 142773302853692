import React from 'react';
import Wahanda from 'common/wahanda';
import classnames from 'classnames';
import { DailySalesReportAnalytics } from 'common/analytics';
import ServiceLink from '../ServiceSalesReport/link';
import ProductLink from '../ProductSalesReport/link';
import SalesReportLink from '../SalesReport/link';
import tableStyle from '../common/reportTable.scss';

interface IReportTableProps extends React.HTMLAttributes<Element> {
  selectedEmployeeId: number;
  selectedChannel: string;
  reportData: any;
  total?: any;
  map?: any;
  rows?: any;
}

export default class ReportTable extends React.Component<IReportTableProps, {}> {
  getTableHeader = () => (
    <thead>
      <tr>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={tableStyle.stackedHeaderDoubleHeight} rowSpan="2">
          {Wahanda.lang.reports.sales.dailySalesReport.headings.date}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.dailySalesReport.headings.services}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.dailySalesReport.headings.products}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.dailySalesReport.headings.vouchers}
        </th>
        <th
          className={classnames(tableStyle.centerHeader, tableStyle.stackedHeaderDoubleHeight)}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
          rowSpan="2"
        >
          {Wahanda.lang.reports.sales.dailySalesReport.headings.totalSalesIncludingVat}
        </th>
      </tr>
      <tr>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.dailySalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.dailySalesReport.headings.salesIncludingVat}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.dailySalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.dailySalesReport.headings.salesIncludingVat}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.dailySalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.dailySalesReport.headings.salesIncludingVat}
        </th>
      </tr>
    </thead>
  );

  getTableFooter = (total) => (
    <tfoot data-testid="daily-sales-totals">
      <tr>
        <td>{Wahanda.lang.reports.sales.employeeSalesReport.headings.grandTotal}</td>
        <td data-testid="daily-sales-services-qty" className={tableStyle.numeric}>
          {total.numberOfServices}
        </td>
        <td data-testid="daily-sales-services-gross" className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.serviceGrossTotal)}
        </td>
        <td data-testid="daily-sales-products-qty" className={tableStyle.numeric}>
          {total.numberOfProducts}
        </td>
        <td data-testid="daily-sales-products-gross" className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.productGrossTotal)}
        </td>
        <td data-testid="daily-sales-vouchers-qty" className={tableStyle.numeric}>
          {total.numberOfVouchers}
        </td>
        <td data-testid="daily-sales-vouchers-gross" className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.voucherGrossTotal)}
        </td>
        <td data-testid="daily-sales-grand-total" className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.grossTotal)}
        </td>
      </tr>
    </tfoot>
  );

  render() {
    const { selectedChannel, selectedEmployeeId } = this.props;
    return (
      <table data-testid="daily-sales-data" className={tableStyle.reportTable}>
        {this.getTableHeader()}
        <tbody>
          {this.props.reportData.rows.map((row) => (
            <tr data-testid="daily-sales-row" key={row.date.formatDateDefault()}>
              <td data-testid="daily-sales-date">
                <SalesReportLink
                  // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
                  className={tableStyle.link}
                  fromDate={row.date}
                  toDate={row.date}
                  employeeFilter={selectedEmployeeId}
                  selectedChannel={selectedChannel}
                  onClick={DailySalesReportAnalytics.trackDateLinkClick}
                >
                  {row.date.formatDateDefault()}
                </SalesReportLink>
              </td>
              <td data-testid="daily-sales-services-qty" className={tableStyle.numeric}>
                <ServiceLink
                  // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
                  className={tableStyle.link}
                  fromDate={row.date}
                  toDate={row.date}
                  selectedEmployeeId={selectedEmployeeId}
                  selectedChannel={selectedChannel}
                  onClick={DailySalesReportAnalytics.trackLinkToServicesSalesClick}
                >
                  {row.numberOfServices}
                </ServiceLink>
              </td>
              <td data-testid="daily-sales-services-gross" className={tableStyle.numeric}>
                <ServiceLink
                  // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
                  className={tableStyle.link}
                  fromDate={row.date}
                  toDate={row.date}
                  selectedEmployeeId={selectedEmployeeId}
                  selectedChannel={selectedChannel}
                  onClick={DailySalesReportAnalytics.trackLinkToServicesSalesClick}
                >
                  {Wahanda.Currency.formatFinancial(row.serviceGrossTotal)}
                </ServiceLink>
              </td>
              <td data-testid="daily-sales-products-qty" className={tableStyle.numeric}>
                <ProductLink
                  // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
                  className={tableStyle.link}
                  fromDate={row.date}
                  toDate={row.date}
                  employeeFilter={selectedEmployeeId}
                  onClick={DailySalesReportAnalytics.trackLinkToProductsSalesClick}
                >
                  {row.numberOfProducts}
                </ProductLink>
              </td>
              <td data-testid="daily-sales-products-gross" className={tableStyle.numeric}>
                <ProductLink
                  // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
                  className={tableStyle.link}
                  fromDate={row.date}
                  toDate={row.date}
                  employeeFilter={selectedEmployeeId}
                  onClick={DailySalesReportAnalytics.trackLinkToProductsSalesClick}
                >
                  {Wahanda.Currency.formatFinancial(row.productGrossTotal)}
                </ProductLink>
              </td>
              <td data-testid="daily-sales-vouchers-qty" className={tableStyle.numeric}>
                {row.numberOfVouchers}
              </td>
              <td data-testid="daily-sales-vouchers-gross" className={tableStyle.numeric}>
                {Wahanda.Currency.formatFinancial(row.voucherGrossTotal)}
              </td>
              <td data-testid="daily-sales-grand-total" className={tableStyle.grandTotal}>
                {Wahanda.Currency.formatFinancial(row.grossTotal)}
              </td>
            </tr>
          ))}
        </tbody>
        {this.getTableFooter(this.props.reportData.total)}
      </table>
    );
  }
}
