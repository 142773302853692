import React from 'react';
import classnames from 'classnames';
import uuidv4 from 'uuid';
import _ from 'common/underscore';
import Wahanda from 'common/wahanda';
import Dialog from 'components/common/dialog/FullHeightDialog';

import { AddCustom } from './AddCustom';
import { AllTreatmentTypes } from './AllTreatmentTypes';
import { TreatmentTypes } from './TreatmentTypes';
import { SelectServiceDialogAnalytics } from './tracking';

import style from './SelectServiceDialog.scss';

const {
  placeholder: PLACEHOLDER,
  noResultsFor: NO_RESULTS_FOR,
  alternativeAddCustomLabel: ALTERNATIVE_ADD_CUSTOM_LABEL,
  addCustomLabel: ADD_CUSTOM_LABEL,
  suggestAlternative: SUGGEST_ALTERNATIVE,
  title: TITLE,
  buttons: { addCustom: ADD_CUSTOM_BUTTON_TEXT },
} = Wahanda.lang.menu.serviceTemplateSelector;

const reduceMenuItems = (array, { menuItems }) => array.concat(menuItems);
const transformTreatmentType = ({ skus, ...treatmentType }) => ({
  ...treatmentType,
  skus: skus.map((sku) => ({
    ...sku,
    tempId: uuidv4.v4(),
    visible: true,
  })),
});
const mapSelectedTreatmentTypeId = (treatmentTypes, selectedTreatmentTypeId) =>
  treatmentTypes.map(({ treatmentGroupId, ...treatment }) => ({
    ...treatment,
    treatmentGroupId,
    selected: treatmentGroupId === selectedTreatmentTypeId,
  }));
const hasMatches = (numberOfTreatments, numberOfTreatmentTypes) =>
  !!(numberOfTreatments || numberOfTreatmentTypes);
const hasOnlyNameMatches = (numberOfTreatments, numberOfTreatmentTypes) =>
  !!(!numberOfTreatments && numberOfTreatmentTypes);
const hasOnlyAliasMatches = (numberOfTreatments, numberOfTreatmentTypes) =>
  !!(numberOfTreatments && !numberOfTreatmentTypes);

interface Props extends React.HTMLAttributes<Element> {
  treatmentTypes: any[];
  onClose: (...args: any[]) => any;
  onOpenCustomServiceDialog: (...args: any[]) => any;
  onOpenServiceDialog: (...args: any[]) => any;
  actions: {
    revertSelectServiceDialogAction: (...args: any[]) => any;
    changeSelectServiceValueAction: (...args: any[]) => any;
    revertSelectServiceValueAction: (...args: any[]) => any;
    changeSelectedTreatmentTypeIdAction: (...args: any[]) => any;
    revertSelectedTreatmentTypeIdAction: (...args: any[]) => any;
  };
  selectedTreatmentTypeId: number;
  selectServiceValue: string;
  treatmentsList: any[];
  treatmentTypesList: any[];
  numberOfTreatments?: number;
  numberOfTreatmentTypes?: number;
}

interface State {
  input?: any;
  isLoading?: boolean;
  hasChanges?: boolean;
}

export class SelectServiceDialog extends React.PureComponent<Props, State> {
  private input: any;

  public static defaultProps = {
    numberOfTreatments: 0,
    numberOfTreatmentTypes: 0,
  };

  public state = {
    hasChanges: false,
    isLoading: false,
    input: '',
  };

  public componentDidMount() {
    this.input.focus();
    this.updateInput();
  }

  public componentWillUnmount() {
    (this.onChangeDebounced as any).cancel();
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.selectServiceValue !== this.props.selectServiceValue) {
      this.updateInput();
    }
  }

  private updateInput = () => {
    const { selectServiceValue } = this.props;
    this.setState({
      input: selectServiceValue,
      isLoading: false,
    });
  };

  public executeSearch = () => {
    /* empty */
  };

  private onClose = () => {
    const { onClose } = this.props;
    this.revertSelectServiceDialog();
    onClose();
  };

  private onChange = ({ target: { value: selectServiceValue } }) => {
    if (_.isEmpty(selectServiceValue.trim())) {
      (this.onChangeDebounced as any).cancel();
    }
    this.setState({
      input: selectServiceValue,
      isLoading: true,
    });
    this.onChangeDebounced(selectServiceValue);
  };

  private onChangeDebounced = _.debounce((selectServiceValue) => {
    SelectServiceDialogAnalytics.trackServiceSearchUpdate(selectServiceValue);
    const {
      actions: { changeSelectServiceValueAction },
    } = this.props;
    changeSelectServiceValueAction(selectServiceValue);
    this.setState({ hasChanges: true });
  }, 500);

  private onTreatmentTypeClick = (selectedTreatmentTypeId) => {
    const { selectedTreatmentTypeId: previousTreatmentTypeId } = this.props;
    if (selectedTreatmentTypeId === previousTreatmentTypeId) {
      const {
        actions: { revertSelectedTreatmentTypeIdAction },
      } = this.props;
      revertSelectedTreatmentTypeIdAction();
    } else {
      const {
        actions: { changeSelectedTreatmentTypeIdAction },
      } = this.props;
      SelectServiceDialogAnalytics.trackExpandTemplateCategoryClicked();
      changeSelectedTreatmentTypeIdAction(selectedTreatmentTypeId);
    }
    this.setState({ hasChanges: true });
  };

  private onTreatmentClick = (selectedUUID) => {
    SelectServiceDialogAnalytics.trackTreatmentClicked(selectedUUID);
    const { treatmentTypes, onOpenServiceDialog, onClose } = this.props;
    const find = ({ uuid }) => uuid === selectedUUID; // eslint-disable-line no-shadow
    const treatment = transformTreatmentType(treatmentTypes.reduce(reduceMenuItems, []).find(find));
    onOpenServiceDialog(treatment);
    this.revertSelectServiceDialog();
    onClose();
  };

  private onAddCustomButtonClick = () => {
    SelectServiceDialogAnalytics.trackAddCustomTreatmentClicked();
    const { onOpenCustomServiceDialog, onClose } = this.props;
    onOpenCustomServiceDialog();
    this.revertSelectServiceDialog();
    onClose();
  };

  private onClearClick = () => {
    const {
      actions: { revertSelectServiceValueAction },
    } = this.props;
    revertSelectServiceValueAction();
    this.input.focus();
    this.setState({ hasChanges: true });
  };

  public getSelectorValueState(
    now,
    was,
    treatmentGroupId,
    numberOfTreatments,
    numberOfTreatmentTypes,
  ) {
    // eslint-disable-line class-methods-use-this
    const matches = hasMatches(numberOfTreatments, numberOfTreatmentTypes);
    const onlyAliasMatches = hasOnlyAliasMatches(numberOfTreatments, numberOfTreatmentTypes);
    return {
      ...(now ? { now } : {}),
      ...(was ? { was } : {}),
      ...(treatmentGroupId ? { treatmentGroupId } : {}),
      matches,
      ...(onlyAliasMatches ? { onlyAliasMatches } : {}),
    };
  }

  public getSelectorValueClearState() {
    const { selectServiceValue, selectedTreatmentTypeId } = this.props;
    const matches = this.hasMatches();
    const onlyAliasMatches = this.hasOnlyAliasMatches();
    return {
      ...(selectServiceValue ? { was: selectServiceValue } : {}),
      ...(selectedTreatmentTypeId ? { treatmentGroupId: selectedTreatmentTypeId } : {}),
      matches,
      ...(onlyAliasMatches ? { onlyAliasMatches } : {}),
    };
  }

  public getTreatmentTypeIdState(now, was, value) {
    const matches = this.hasMatches();
    const onlyAliasMatches = this.hasOnlyAliasMatches();
    return {
      ...(now ? { now } : {}),
      ...(was ? { was } : {}),
      ...(value ? { value } : {}),
      matches,
      ...(onlyAliasMatches ? { onlyAliasMatches } : {}),
    };
  }

  public getShutState() {
    const { selectServiceValue, selectedTreatmentTypeId } = this.props;
    const matches = this.hasMatches();
    const onlyAliasMatches = this.hasOnlyAliasMatches();
    return {
      ...(selectServiceValue ? { value: selectServiceValue } : {}),
      ...(selectedTreatmentTypeId ? { treatmentGroupId: selectedTreatmentTypeId } : {}),
      matches,
      ...(onlyAliasMatches ? { onlyAliasMatches } : {}),
    };
  }

  public getTreatmentState(treatmentId) {
    const { selectServiceValue, selectedTreatmentTypeId } = this.props;
    const matches = this.hasMatches();
    const onlyAliasMatches = this.hasOnlyAliasMatches();
    return {
      ...(selectServiceValue ? { value: selectServiceValue } : {}),
      ...(selectedTreatmentTypeId ? { treatmentGroupId: selectedTreatmentTypeId } : {}),
      treatmentId,
      matches,
      ...(onlyAliasMatches ? { onlyAliasMatches } : {}),
    };
  }

  public getCustomTreatmentState() {
    const { selectServiceValue, selectedTreatmentTypeId } = this.props;
    const matches = this.hasMatches();
    const onlyAliasMatches = this.hasOnlyAliasMatches();
    return {
      ...(selectServiceValue ? { value: selectServiceValue } : {}),
      ...(selectedTreatmentTypeId ? { treatmentGroupId: selectedTreatmentTypeId } : {}),
      matches,
      ...(onlyAliasMatches ? { onlyAliasMatches } : {}),
    };
  }

  private hasSelectServiceValue() {
    return !!this.props.selectServiceValue.trim().length;
  }

  private hasTreatmentTypesList() {
    return !!this.props.treatmentTypesList.length;
  }

  private hasSelectedTreatmentTypeId() {
    return !!this.props.selectedTreatmentTypeId;
  }

  public hasChanges() {
    return !!this.state.hasChanges;
  }

  private hasMatches() {
    const { numberOfTreatments, numberOfTreatmentTypes } = this.props;
    return hasMatches(numberOfTreatments, numberOfTreatmentTypes);
  }

  public hasOnlyNameMatches() {
    const { numberOfTreatments, numberOfTreatmentTypes } = this.props;
    return hasOnlyNameMatches(numberOfTreatments, numberOfTreatmentTypes);
  }

  private hasOnlyAliasMatches() {
    const { numberOfTreatments, numberOfTreatmentTypes } = this.props;
    return hasOnlyAliasMatches(numberOfTreatments, numberOfTreatmentTypes);
  }

  private revertSelectServiceDialog() {
    const {
      actions: { revertSelectServiceDialogAction },
    } = this.props;
    revertSelectServiceDialogAction();
  }

  private refInput = (input) => {
    this.input = input;
  };

  private renderAddCustomService() {
    return (
      <AddCustom
        classes={style.addCustomService}
        labelText={ADD_CUSTOM_LABEL}
        buttonText={ADD_CUSTOM_BUTTON_TEXT}
        onButtonClick={this.onAddCustomButtonClick}
      />
    );
  }

  private renderAlternativeAddCustomService() {
    return (
      <AddCustom
        classes={style.alternativeAddCustomService}
        labelText={ALTERNATIVE_ADD_CUSTOM_LABEL}
        buttonText={ADD_CUSTOM_BUTTON_TEXT}
        onButtonClick={this.onAddCustomButtonClick}
      />
    );
  }

  private renderNoResults() {
    const { selectServiceValue } = this.props;
    return (
      <div className={style.noResults}>
        <span>{NO_RESULTS_FOR}</span>
        &nbsp;
        <strong>{selectServiceValue.trim()}...</strong>
      </div>
    );
  }

  private renderSuggestAlternative() {
    // eslint-disable-line
    return <div className={style.suggestAlternative}>{SUGGEST_ALTERNATIVE}</div>;
  }

  private renderLayout() {
    if (this.hasSelectServiceValue()) {
      /*
       *  hasSelectServiceValue - true
       */
      if (this.hasTreatmentTypesList()) {
        /*
         *  hasSelectServiceValue - true
         *  hasTreatmentTypesList - true
         */
        const { treatmentTypesList, selectServiceValue } = this.props;
        const value = selectServiceValue.trim().toLowerCase();
        return (
          <div
            className={style.allTreatmentTypesList}
            style={Wahanda.Device.isIOS() ? { height: 1143 } : {}}
          >
            <AllTreatmentTypes
              list={treatmentTypesList}
              onTreatmentClick={this.onTreatmentClick}
              value={value}
            />
            {this.renderAddCustomService()}
          </div>
        );
      }
      /*
       *  hasSelectServiceValue - true
       *  hasTreatmentTypesList - false
       */
      if (this.hasSelectedTreatmentTypeId()) {
        /*
         *  hasSelectServiceValue - true
         *  hasTreatmentTypesList - false
         *  hasSelectedTreatmentTypeId - true
         */
        const { treatmentTypes, selectedTreatmentTypeId } = this.props;
        return (
          <div className={classnames(style.treatmentTypesList, style.treatmentTypesNotFound)}>
            {this.renderNoResults()}
            {this.renderSuggestAlternative()}
            <TreatmentTypes
              list={mapSelectedTreatmentTypeId(treatmentTypes, selectedTreatmentTypeId)}
              onTreatmentTypeClick={this.onTreatmentTypeClick}
              onTreatmentClick={this.onTreatmentClick}
              addCustomLabelText={ADD_CUSTOM_LABEL}
              addCustomButtonText={ADD_CUSTOM_BUTTON_TEXT}
              onAddCustomButtonClick={this.onAddCustomButtonClick}
            />
            {this.renderAlternativeAddCustomService()}
          </div>
        );
      }
      /*
       *  hasSelectServiceValue - true
       *  hasTreatmentTypesList - false
       *  hasSelectedTreatmentTypeId - false
       */
      const { treatmentTypes } = this.props;
      return (
        <div className={classnames(style.treatmentTypesList, style.treatmentTypesNotFound)}>
          {this.renderNoResults()}
          {this.renderSuggestAlternative()}
          <TreatmentTypes
            list={treatmentTypes} // props
            onTreatmentTypeClick={this.onTreatmentTypeClick}
          />
          {this.renderAlternativeAddCustomService()}
        </div>
      );
    }
    /*
     *  hasSelectServiceValue - false
     */
    if (this.hasTreatmentTypesList()) {
      /*
       *  hasSelectServiceValue - false
       *  hasTreatmentTypesList - true
       */
      if (this.hasSelectedTreatmentTypeId()) {
        /*
         *  hasSelectServiceValue - false
         *  hasTreatmentTypesList - true
         *  hasSelectedTreatmentTypeId - true
         */
        const { treatmentTypesList, selectedTreatmentTypeId } = this.props;
        return (
          <div
            className={style.treatmentTypesList}
            style={Wahanda.Device.isIOS() ? { height: 1143 } : {}}
          >
            <TreatmentTypes
              list={mapSelectedTreatmentTypeId(treatmentTypesList, selectedTreatmentTypeId)}
              onTreatmentTypeClick={this.onTreatmentTypeClick}
              onTreatmentClick={this.onTreatmentClick}
              addCustomLabelText={ADD_CUSTOM_LABEL}
              addCustomButtonText={ADD_CUSTOM_BUTTON_TEXT}
              onAddCustomButtonClick={this.onAddCustomButtonClick}
            />
          </div>
        );
      }
      /*
       *  hasSelectServiceValue - false
       *  hasTreatmentTypesList - false
       *  hasSelectedTreatmentTypeId - false
       */
      const { treatmentTypes } = this.props;
      return (
        <div
          className={style.treatmentTypesList}
          style={Wahanda.Device.isIOS() ? { height: 1143 } : {}}
        >
          <TreatmentTypes list={treatmentTypes} onTreatmentTypeClick={this.onTreatmentTypeClick} />
        </div>
      );
    }
    /*
     *  hasSelectServiceValue - false
     *  hasSelectedTreatmentTypeId - false
     */
    const { treatmentTypes } = this.props;
    return (
      <div
        className={style.treatmentTypesList}
        style={Wahanda.Device.isIOS() ? { height: 1143 } : {}}
      >
        <TreatmentTypes list={treatmentTypes} onTreatmentTypeClick={this.onTreatmentTypeClick} />
      </div>
    );
  }

  private renderClear() {
    if (this.hasSelectServiceValue()) {
      return (
        // eslint-disable-next-line
        <div className={style.clear} onClick={this.onClearClick}>
          &#65279;
        </div>
      );
    }
    return null;
  }

  private renderLoading() {
    return <div className={style.spinner} />;
  }

  public render() {
    const { input, isLoading } = this.state;
    return (
      <Dialog
        title={TITLE}
        width={482}
        minHeight={282}
        // @ts-expect-error ts-migrate(2769) FIXME: Property 'height' does not exist on type 'Intrinsi... Remove this comment to see the full error message
        height={Wahanda.Device.isIOS() ? 1143 : 0}
        initialPosition="top"
        keepTopPositionWhenResizing
        warnWhenClosingChangedForms={false}
        onClose={this.onClose}
        classes={{ [style.dialog]: true, selectService: true }}
      >
        <div className={style.searchBox} style={Wahanda.Device.isIOS() ? { height: 120 } : {}}>
          <input
            name="search"
            placeholder={PLACEHOLDER}
            onChange={this.onChange}
            value={input}
            ref={this.refInput}
            autoComplete="off"
          />
          {isLoading ? this.renderLoading() : this.renderClear()}
        </div>

        <div className={style.scrollBox}>{this.renderLayout()}</div>
      </Dialog>
    );
  }
}
