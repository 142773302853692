import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { ResourceCheckWarning } from './ResourceCheckWarning';

export const ResourceCheckWarningInitializer = ({ node, props }): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(node),
  render: () => {
    ReactDOM.render(<ResourceCheckWarning {...props} />, node);
  },
});
