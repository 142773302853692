import { trackEvent, trackPageView, ACTIONS } from 'common/analytics';

export class LoginAnalytics {
  public static CATEGORY = 'login';

  public static LINK = 'link';

  public static trackPageView() {
    trackPageView(LoginAnalytics.CATEGORY);
  }

  public static trackLoginClick() {
    trackEvent(LoginAnalytics.CATEGORY, ACTIONS.CLICK, 'login');
  }

  public static trackLoginSubmit() {
    trackEvent(LoginAnalytics.CATEGORY, ACTIONS.SUBMIT);
  }

  public static trackSignupClick() {
    trackEvent(LoginAnalytics.LINK, ACTIONS.CLICK, 'sign-up');
  }

  public static trackForgotClick() {
    trackEvent(LoginAnalytics.LINK, ACTIONS.CLICK, 'forgot-password');
  }

  public static trackAboutClick() {
    trackEvent(LoginAnalytics.LINK, ACTIONS.CLICK, 'about');
  }

  public static trackContactsClick() {
    trackEvent(LoginAnalytics.LINK, ACTIONS.CLICK, 'contacts');
  }

  public static trackTermsClick() {
    trackEvent(LoginAnalytics.LINK, ACTIONS.CLICK, 'open-terms');
  }

  public static trackTermsView() {
    trackEvent('terms-of-service', ACTIONS.VIEW);
  }

  public static trackInvalidFields() {
    trackEvent(LoginAnalytics.CATEGORY, ACTIONS.VALIDATION, 'fields-blank');
  }

  public static trackErrorNoVenues() {
    trackEvent(LoginAnalytics.CATEGORY, ACTIONS.ERROR, 'no-vanues');
  }

  public static trackErrorPassword() {
    trackEvent(LoginAnalytics.CATEGORY, ACTIONS.ERROR, 'password-incorrect');
  }
}
