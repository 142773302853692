import React from 'react';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import range from 'lodash/range';
import App from 'common/backbone-app';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';

import {
  TimeSlotsObject,
  RotaWorkingHoursObject,
  VenueBusinessHoursObject,
  WeekDays,
  DateRangeObject,
  WorkingHoursObject,
} from '../utils/types';

import { DAYS_PER_WEEK } from './constants';

export const venueDayTimeSlots = (payload: {
  workingHours: WorkingHoursObject[];
  date: string;
}): TimeSlotsObject[] => {
  const { date, workingHours } = payload;
  if (!workingHours.length) {
    return [];
  }
  const dayWorkingHours = find(workingHours, { date }) as WorkingHoursObject;

  return dayWorkingHours && dayWorkingHours.timeSlots.length
    ? dayWorkingHours.timeSlots
    : [{ timeFrom: '', timeTo: '' }];
};

export const venueStandardTimeSlots = (payload: {
  businessHours: VenueBusinessHoursObject[];
  workingHours: WorkingHoursObject;
  date: string;
}): TimeSlotsObject[] => {
  const { businessHours, date, workingHours } = payload;
  const { timeSlots } = workingHours;
  const businessHoursTimeSlot = businessDayTimeSlots({
    businessHours,
    date,
  })[0];

  const venueConfig = App.config.get('venue');
  const standardVenueConfigOpeningTime = moment(`${date} ${venueConfig.dayStartsAt}`)
    .add(1, 'h')
    .format('HH:mm');
  const standardVenueConfigClosingTime = moment(`${date} ${venueConfig.dayEndsAt}`)
    .subtract(1, 'h')
    .format('HH:mm');

  // Fallback to either business hours or to standard config hours
  const timeFrom = businessHoursTimeSlot.timeFrom || standardVenueConfigOpeningTime;
  const timeTo = businessHoursTimeSlot.timeTo || standardVenueConfigClosingTime;

  return timeSlots.length ? timeSlots : [{ timeFrom, timeTo }];
};

export const businessDayTimeSlots = (payload: {
  businessHours: VenueBusinessHoursObject[];
  date?: string;
  day?: WeekDays;
}): TimeSlotsObject[] => {
  const { day, date, businessHours } = payload;
  if ((businessHours && !businessHours.length) || (!date && !day)) {
    return [];
  }
  const dayOfWeek = day || moment(date).locale('en').formatWeekday();
  const businessDay = find(businessHours, {
    dayOfWeek: dayOfWeek.toUpperCase(),
  });

  const timeFrom = (businessDay && businessDay.openingTime) || '';
  const timeTo = (businessDay && businessDay.closingTime) || '';

  return [{ timeFrom, timeTo }];
};

export const dateRange = (payload: { dateFrom: string; dateTo: string }): string[] => {
  const { dateFrom, dateTo } = payload;
  if (dateFrom === dateTo) {
    return [dateFrom];
  }
  const startOfRange = moment(dateFrom);
  const endOfRange = moment(dateTo);
  const isBefore = startOfRange.isBefore(endOfRange);
  const days: string[] = [];
  const day = isBefore ? startOfRange : endOfRange;
  const end = isBefore ? endOfRange : startOfRange;

  while (day <= end) {
    days.push(day.formatApiDateString());
    day.add(1, 'day');
  }

  return days;
};

export const workingHoursRange = (timeSlots: TimeSlotsObject[]): JSX.Element[] | null => {
  if (!timeSlots.length) {
    return null;
  }
  return timeSlots.map(({ timeFrom, timeTo }, index) => {
    if (!timeFrom || !timeTo) {
      return <span key={`timeSlot-${index}`}>{Wahanda.lang.settings.shifts.nonWorking}</span>;
    }
    return (
      <span key={`timeSlot-${index}`}>
        {timeFrom} - {timeTo}
      </span>
    );
  });
};

export const getVenueDayWorkingHours = (
  date: string,
  workingHours: WorkingHoursObject[],
): WorkingHoursObject => find(workingHours, { date });

export const limitedHoursRange = (timeSlots: TimeSlotsObject[]) => {
  const startTime = moment.duration(timeSlots[0].timeFrom).asMinutes();
  const endTime = moment.duration(timeSlots[0].timeTo).asMinutes();
  return { startTime, endTime };
};

const getHoursPerDay = (workingHours: RotaWorkingHoursObject): number => {
  const { timeSlots, isClosed } = workingHours;
  if (!timeSlots.length) {
    return 0;
  }
  const count = timeSlots
    .map(({ timeTo, timeFrom }) => {
      const date = moment().formatApiDateString();
      const closes = moment(`${date} ${timeTo}`);
      const opens = moment(`${date} ${timeFrom}`);
      return timeTo && !isClosed ? closes.diff(opens, 'hours', true) : 0;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return count;
};

export const countHoursPerWeek = (workingHours: RotaWorkingHoursObject[]): string => {
  const hoursPerWeek = workingHours
    .map((workingDay) => getHoursPerDay(workingDay))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return hoursPerWeek.toFixed(1);
};

export const mapWeekdays = (): WeekDays[] => {
  const begin = moment().locale('en').startOf('isoWeek');
  const result: WeekDays[] = [];

  for (let i = 0; i < DAYS_PER_WEEK; i += Wahanda.Date.venueFirstDayOfWeek()) {
    const val = begin.formatWeekday().toUpperCase() as WeekDays;
    result.push(val);
    begin.add(1, 'd');
  }

  return result;
};

export const isBetweenVisibleRange = (payload: {
  datePickerRange: DateRangeObject;
  startDate: moment.Moment;
  endDate: moment.Moment;
}) => {
  const { datePickerRange, startDate, endDate } = payload;

  return (
    !!datePickerRange &&
    (startDate.isBetween(datePickerRange.dateFrom, datePickerRange.dateTo, undefined, '[)') ||
      endDate.isBetween(datePickerRange.dateFrom, datePickerRange.dateTo, undefined, '(]'))
  );
};

export const getWorkingHoursWithRange = (payload: {
  datePickerRange: DateRangeObject;
  workingHours: WorkingHoursObject[];
  // Prop needed to reassure UX that when week and month
  // view are changing that the grid would be displayed correctly
  rangeLimit?: number;
}): WorkingHoursObject[] => {
  const { datePickerRange, rangeLimit, workingHours } = payload;
  const startIndex = findIndex(workingHours, ['date', datePickerRange.dateFrom]);
  const endIndex = findIndex(workingHours, ['date', datePickerRange.dateTo]);
  const slicedWorkingHours =
    startIndex > -1 ? workingHours.slice(startIndex, endIndex + 1) : workingHours;

  return rangeLimit && slicedWorkingHours.length > rangeLimit
    ? slicedWorkingHours.slice(0, rangeLimit)
    : slicedWorkingHours;
};

export const getWeekdaysMapped = (payload: { start: moment.Moment; end: moment.Moment }) => {
  const { start, end } = payload;
  const diff = end.diff(start, 'd');
  const begin: moment.Moment = start;
  const dates: WeekDays[] = [];

  range(diff).forEach(() => {
    const val = begin.locale('en').formatWeekday().toUpperCase() as WeekDays;
    dates.push(val);
    begin.add(1, 'd');
  });
  return dates;
};
