/**
 * View mixin for calendar type changing.
 *
 * The view should have these attributes:
 * - string calendarViewType - The type for this view, e.g. "spa-day"
 *
 * If the view has an "onCalendarTypeChange" method, it will be called when the event happens.
 */
BackboneEx.Mixin.View.CalendarTypeChange = {
	isActiveView: false,

	setupTypeChangeListening: function() {
		var self = this;
		App.on('calendar:type-change', function(type) {
			if (_.isArray(self.calendarViewType)) {
				self.isActiveView = (-1 !== _.indexOf(self.calendarViewType, type));
			} else {
				self.isActiveView = (self.calendarViewType === type);
			}
			self.onCalendarTypeChange && self.onCalendarTypeChange();
		});
	},

	isActive: function() {
		return this.isActiveView === true;
	},

	// Returns a function which executes the passed in function only if this view is active.
	ifActive: function(func) {
		var self = this;
		return function() {
			if (self.isActive()) {
				func.apply(func, arguments);
			}
		};
	},

};
