import React from 'react';
import classnames from 'classnames';
import { Checkbox } from 'components/common/Checkbox';
import PriceInput from 'components/common/forms/price-input';
import getName from './utils';
import style from './style.scss';

type PricingRowProps = {
  subSkus: {
    discountPriceAmount?: number;
    fullPriceAmount?: number;
    id?: number;
    name?: string;
    offerId?: number;
  }[];
  isPackagePricingCustom: boolean;
  fullPriceAmount?: number;
  calculatedPriceAmount: number;
  visible: boolean;
  actions: {
    offerDialogToggleSkuAction: (...args: any[]) => any;
    offerDialogUpdateFullPriceAmountAction: (...args: any[]) => any;
  };
  index: number;
  offerDialogSubServices: any[];
  subSkuIdsIgnoredForName: any[];
  readonlyLight: boolean;
};
const PricingRow: React.SFC<PricingRowProps> = ({
  isPackagePricingCustom,
  subSkus,
  subSkuIdsIgnoredForName,
  fullPriceAmount,
  calculatedPriceAmount,
  visible,
  actions,
  index,
  offerDialogSubServices,
  readonlyLight,
}) => {
  const { offerDialogToggleSkuAction, offerDialogUpdateFullPriceAmountAction } = actions;
  // @ts-expect-error ts-migrate(2345) FIXME: Type 'any' is not assignable to type 'never'.
  const name = getName(subSkus, subSkuIdsIgnoredForName, offerDialogSubServices);
  const price = isPackagePricingCustom ? fullPriceAmount : calculatedPriceAmount;
  // if price is 0 we have to make it a string else the PriceInput component will not display it.
  const value = price !== 0 ? price : price.toString();
  const inputDisabled = !isPackagePricingCustom || readonlyLight;
  const priceInputId = `package-price-${index}${isPackagePricingCustom}`;
  const input = visible ? (
    <div className={classnames(style.price, !inputDisabled ? style.editable : style.disabled)}>
      <PriceInput
        controlledComponent
        id={priceInputId}
        name={priceInputId}
        required
        disabled={inputDisabled}
        value={value}
        onChange={(newPrice) => {
          offerDialogUpdateFullPriceAmountAction({
            index,
            fullPriceAmount: newPrice,
          });
        }}
      />
    </div>
  ) : null;
  return (
    <div className={style.container}>
      <Checkbox
        name={priceInputId}
        checked={visible}
        onChange={() => {
          offerDialogToggleSkuAction({
            indexArray: [index],
            visible: !visible,
          });
        }}
      />
      <div className={style.name}>{name}</div>
      {input}
    </div>
  );
};

export default PricingRow;
