/* global Backbone _ */
App.Views.Calendar.CalendarTypeSelect = Backbone.View.extend({
  spaBreaksDisabled: false,

  initialize: function () {
    this.$el.wMenu({
      currentElementRenderer: function (node) {
        var handler = this.current.find('.handler').clone();
        this.current.html(node.html()).append(handler);
      },
    });
  },

  render: function () {
    this.prepareCalendarList().done(
      function () {
        if (this.visible()) {
          this.renderList();
        } else {
          this.$el.hide();
          this.afterRender();
        }
      }.bind(this),
    );
  },

  visible: function () {
    return !App.isApp(window.location, navigator.userAgent) && App.config.canSellDated();
  },

  /**
   * Prepares the calendar list for rendering.
   *
   * @returns {$.Deferred} The promise to fetch the data.
   */
  prepareCalendarList: function () {
    this.allowedCalendars = [];
    var drd = $.Deferred();

    if (Wahanda.Permissions.useCalendar()) {
      this.allowedCalendars.push('appointment');
    }

    drd.resolve();

    return drd.promise();
  },

  renderList: function () {
    var LANG = Wahanda.lang.calendar.typeChoice;

    this.$('.filter-ddown')
      .empty()
      .append(
        _.map(this.allowedCalendars, function (calendarType) {
          switch (calendarType) {
            case 'spa-day':
              return $('<li data-type="spa-day"></li>').text(LANG.spaDay);

            case 'spa-break':
              return $('<li data-type="spa-break"></li>').text(LANG.spaBreak);

            case 'appointment':
            default:
              return $('<li data-type="appointment"></li>').text(LANG.appointmentDynamic);
          }
        }),
      );

    if (this.allowedCalendars.length > 1) {
      this.setCurrent();
      this.$el.show();
    } else {
      this.$el.hide();
    }

    this.afterRender();
  },

  removeSpaBreak: function () {
    this.$('li[data-type="spa-break"]').remove();
  },

  disableSpaBreak: function () {
    this.spaBreaksDisabled = true;
    this.removeSpaBreak();
  },

  /**
   * Sets the current element's text.
   *
   * @param {jQuery} $selected OPTIONAL The element to select
   * @returns {void}
   */
  setCurrent: function ($selected) {
    this.$el.wMenu('select', $selected || this.$('li.on'));
  },

  setCurrentByType: function (type) {
    var $selected = this.$('li[data-type=' + type + ']');
    if ($selected.length > 0) {
      this.setCurrent($selected);
    }
  },

  afterRender: function () {
    App.trigger(Wahanda.Event.CALENDAR_ALLOWED_TYPES, this.allowedCalendars);
    this.trigger('rendered');
  },
});
