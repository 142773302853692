import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { hasMultipleEmployeeCategories } from 'components/menu/selectors';
import { offerDialogChangeUseEmployeeCategoriesAction } from 'components/menu/actions';

import { setUseProcessingTime } from './actions';
import { isProcessingTimeChecked } from './selectors';

import TimeAndPricing from './TimeAndPricing';

const mapStateToProps = ({ menu: { menuOffer } }) => {
  return {
    employeeCategoryPricingChangeable: menuOffer.offerDialogEmployeeCategoryPricingChangeable,
    useEmployeeCategoryPricing: menuOffer.offerDialogUseEmployeeCategoryPricing,
    employeeCategoryPricingDisabled: menuOffer.offerDialogReadonly,
    hasEmployeeCategoryPricing: hasMultipleEmployeeCategories(
      menuOffer.offerDialogEmployees,
      menuOffer.allEmployees,
    ),
    canChangeProcessingTime: menuOffer.offerDialogAllowProcessing,
    isProcessingTimeChecked: isProcessingTimeChecked(menuOffer),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        setUseProcessingTime,
        offerDialogChangeUseEmployeeCategoriesAction,
      },
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeAndPricing);
