import React from 'react';
import { get } from 'mori';
import Wahanda from 'common/wahanda';
import { appState } from 'state';
import { TransactionDialog } from 'components/reports/transactions/common/transaction-dialog/container';
import changeDate from 'src/actions/pos/change-date';
import { DialogConfirmation } from 'components/common/dialog/DialogConfirmation';
import { DialogTypes } from './types';

export interface Props {
  dialog: DialogTypes | null;
  onClose: () => void;
}

export const RootTransactionDialog = ({ dialog, onClose }: Props) => {
  switch (dialog) {
    case DialogTypes.WITHDRAWAL: {
      const langBase = Wahanda.lang.reports.transactions.transaction;
      const title = langBase.titles.withdrawal;
      const buttonText = langBase.buttons.withdrawal;
      return (
        <TransactionDialog
          title={title}
          onClose={onClose}
          actionButtonText={buttonText}
          negativeValue
        />
      );
    }
    case DialogTypes.DEPOSIT: {
      const langBase = Wahanda.lang.reports.transactions.transaction;
      const title = langBase.titles.deposit;
      const buttonText = langBase.buttons.deposit;
      return <TransactionDialog title={title} onClose={onClose} actionButtonText={buttonText} />;
    }
    case DialogTypes.DAY_CLOSED_NOTIFICATION: {
      const langBase = Wahanda.lang.posRestrictions.prevDayOpenNoCloseDay;
      const title = langBase.title;
      const buttonText = langBase.buttonText;
      const text = langBase.text;
      const onClickFn = () => {
        // TODO: move this to redux store
        const openDay = get(appState.deref(), ['pos-status', 'openDay']);
        changeDate(openDay);
        onClose();
      };
      return (
        <DialogConfirmation
          dataTest="transaction-day-closed-confirmation-modal"
          title={title}
          text={text}
          buttonTitle={buttonText}
          onButtonClick={onClickFn}
          onClose={onClose}
        />
      );
    }
    default:
      return null;
  }
};
