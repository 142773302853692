App.Models.Image = {
  getUrl: function (imageId, options = {}) {
    var width = options.width || 99;
    var height = options.height || 66;

    return App.Api.wsUrl(
      '/media/venue/' +
        App.getVenueId() +
        '/gallery/image/' +
        imageId +
        '?width=' +
        width +
        '&height=' +
        height,
    );
  },
};
