import {
  GET_PRODUCT_ORDERS_REQUEST,
  GET_PRODUCT_ORDERS_RECEIVE,
  GET_PRODUCT_ORDERS_FAILURE,
  PRODUCT_ORDERS_RESET,
  CREATE_PRODUCT_ORDER_REQUEST,
  CREATE_PRODUCT_ORDER_SUCCESS,
  CREATE_PRODUCT_ORDER_FAILURE,
  COMPLETE_PRODUCT_ORDER_REQUEST,
  COMPLETE_PRODUCT_ORDER_SUCCESS,
  COMPLETE_PRODUCT_ORDER_FAILURE,
  PRODUCT_ORDER_REQUEST,
  PRODUCT_ORDER_RECEIVE,
  PRODUCT_ORDER_FAILURE,
  PRODUCT_ORDER_RESET,
  CANCEL_PRODUCT_ORDER_REQUEST,
  CANCEL_PRODUCT_ORDER_SUCCESS,
  CANCEL_PRODUCT_ORDER_FAILURE,
  UPDATE_EXISTING_ORDER,
  PRODUCT_ORDER_SELECT_SUPPLIER,
  PRODUCT_ADD_TO_ORDER,
  PRODUCT_REMOVE_FROM_ORDER,
  PRODUCT_ORDER_UPDATE,
  NEW_PRODUCT_ORDER_RESET,
  PRODUCT_ORDER_READY_TO_BE_PLACED,
  PRODUCT_ORDER_CAN_BE_PROCESSED,
} from 'reduxStore/actionsConst';

import {
  calculateTotalQuantityOrderedPerOrder,
  calculateProductOrderItemsTotalQuantity,
  pushCompletedOrdersToTheBottom,
  byDate,
} from './reducerHelpers';

const initialState = {
  orders: [],
  newOrder: [],
  newOrderTotal: 0,
  isSupplierSelected: false,
  productSupplier: null,
  newOrderId: null,
  existingOrder: null as any,
  placeOrderHasBeenClicked: false,
  processingOrder: false,
  loadingOrdersList: false,
  loadingOrderComplete: false,
};

export default function productOrders(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_ORDERS_REQUEST:
      return {
        ...state,
        loadingOrdersList: true,
      };

    case GET_PRODUCT_ORDERS_RECEIVE:
      return {
        ...state,
        orders: action.data
          .map(calculateTotalQuantityOrderedPerOrder)
          .sort(byDate)
          .sort(pushCompletedOrdersToTheBottom),
        loadingOrdersList: false,
      };

    case GET_PRODUCT_ORDERS_FAILURE:
      return {
        ...state,
        loadingOrdersList: false,
      };

    case PRODUCT_ORDERS_RESET:
      return {
        ...state,
        orders: [],
        loadingOrdersList: false,
      };

    case CREATE_PRODUCT_ORDER_REQUEST:
      return {
        ...state,
      };

    case CREATE_PRODUCT_ORDER_SUCCESS:
      return {
        ...state,
        newOrderId: action.data,
      };

    case CREATE_PRODUCT_ORDER_FAILURE:
      return {
        ...state,
      };

    case COMPLETE_PRODUCT_ORDER_REQUEST:
      return {
        ...state,
        loadingOrderComplete: true,
      };

    case COMPLETE_PRODUCT_ORDER_SUCCESS:
      return {
        ...state,
        loadingOrderComplete: false,
      };

    case COMPLETE_PRODUCT_ORDER_FAILURE:
      return {
        ...state,
        loadingOrderComplete: false,
      };

    case PRODUCT_ORDER_REQUEST:
      return {
        ...state,
      };

    case PRODUCT_ORDER_RECEIVE:
      return {
        ...state,
        existingOrder: {
          ...action.data,
          totalQuantityOrdered: action.data.productOrderItems.reduce(
            calculateProductOrderItemsTotalQuantity,
            0,
          ),
        },
        productSupplier: action.data.productSupplier,
      };

    case PRODUCT_ORDER_RESET:
      return {
        ...state,
        existingOrder: null,
        processingOrder: false,
        isSupplierSelected: false,
        productSupplier: null,
      };

    case PRODUCT_ORDER_FAILURE:
      return {
        ...state,
        loadingOrder: false,
      };

    case CANCEL_PRODUCT_ORDER_REQUEST:
      return {
        ...state,
      };

    case CANCEL_PRODUCT_ORDER_SUCCESS:
      return {
        ...state,
      };

    case CANCEL_PRODUCT_ORDER_FAILURE:
      return {
        ...state,
      };

    case UPDATE_EXISTING_ORDER:
      return {
        ...state,
        existingOrder: {
          ...state.existingOrder,
          productOrderItems: state.existingOrder.productOrderItems.map((item) => {
            if (item.id === action.item.id) {
              return action.item;
            }
            return item;
          }),
          totalQuantityOrdered: state.existingOrder.productOrderItems.reduce(
            (total, productOrderItem) => {
              if (productOrderItem.id === action.item.id) {
                return total + action.item.quantity;
              }
              return total + productOrderItem.quantity;
            },
            0,
          ),
        },
      };

    case PRODUCT_ORDER_SELECT_SUPPLIER:
      return {
        ...state,
        isSupplierSelected: true,
        productSupplier: action.supplier ? { ...action.supplier } : null,
      };

    case PRODUCT_ADD_TO_ORDER:
      return {
        ...state,
        newOrder: [...state.newOrder, action.item],
        newOrderTotal: state.newOrderTotal + action.item.quantity,
      };

    case PRODUCT_REMOVE_FROM_ORDER:
      return {
        ...state,
        newOrder: state.newOrder.filter(
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'venueProductId' does not exist on type '... Remove this comment to see the full error message
          (item) => action.item.venueProductId !== item.venueProductId,
        ),
        newOrderTotal:
          state.newOrderTotal -
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          state.newOrder.find((item) => item.venueProductId === action.item.venueProductId)
            .quantity,
      };

    case PRODUCT_ORDER_UPDATE:
      return {
        ...state,
        newOrder: state.newOrder.map((item) => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'venueProductId' does not exist on type '... Remove this comment to see the full error message
          if (item.venueProductId === action.item.venueProductId) {
            return action.item;
          }
          return item;
        }),
        newOrderTotal: state.newOrder
          .map((item) => {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'venueProductId' does not exist on type '... Remove this comment to see the full error message
            if (item.venueProductId === action.item.venueProductId) {
              return action.item;
            }
            return item;
          })
          .reduce((total, productOrderItem) => total + productOrderItem.quantity, 0),
      };

    case NEW_PRODUCT_ORDER_RESET:
      return {
        ...state,
        newOrder: [],
        newOrderTotal: 0,
        newOrderId: null,
        placeOrderHasBeenClicked: false,
        isSupplierSelected: false,
        productSupplier: null,
      };

    case PRODUCT_ORDER_READY_TO_BE_PLACED:
      return {
        ...state,
        placeOrderHasBeenClicked: true,
      };

    case PRODUCT_ORDER_CAN_BE_PROCESSED:
      return {
        ...state,
        processingOrder: true,
      };

    default:
      return state;
  }
}
