import React from 'react';
import {
  InputContextProvider,
  InputBorder,
  InputSizeType,
  InputLabel,
  InputError,
} from 'components/common/__BaseCommon';

interface Props {
  children: React.ReactNode;
  dataTest?: string;
  // InputContextProvider
  disabled?: boolean;
  size?: InputSizeType;
  isFocused?: boolean;
  fullBorder?: boolean;
  // InputLabel
  label?: string;
  required?: boolean;
  // InputBorder
  onKeyDown?: (event) => void;
  readOnly?: boolean;
  // InputError
  hasError?: boolean;
  errorMessage?: string;
  newLook?: boolean;
  isSearchable?: boolean;
}

export const InputBase: React.FC<Props> = ({
  children,
  dataTest,
  disabled,
  fullBorder = false,
  size = InputSizeType.DEFAULT,
  onKeyDown,
  isFocused = false,
  readOnly = false,
  hasError,
  errorMessage,
  label,
  required,
  newLook = false,
  isSearchable = false,
}) => {
  return (
    <InputContextProvider
      size={size}
      disabled={disabled}
      isFocused={isFocused}
      fullBorder={fullBorder}
      readOnly={readOnly}
      newLook={newLook}
      isSearchable={isSearchable}
    >
      <div>
        {label && <InputLabel label={label} required={required} />}
        <InputBorder hasError={hasError} onKeyDown={onKeyDown}>
          {children}
        </InputBorder>
        {hasError && errorMessage && <InputError dataTest={dataTest} message={errorMessage} />}
      </div>
    </InputContextProvider>
  );
};
