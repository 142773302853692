import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import Wahanda from 'common/wahanda';
import { MenuLayout } from 'components/menu/index';

// This is our main store. It is reused for the same venue, until a venue change happens.
// const store = storeBuilder();
export function render(node, config, options) {
  ReactDOM.render(
    <Provider store={storeBuilder()}>
      <MenuLayout
        venueId={config.venue.id}
        currentTab={options.tab}
        page={options.page}
        lang={Wahanda.lang.menu}
      />
    </Provider>,
    node,
  );
}

export function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}
