(function() {
    var defaultButtons = ['save', 'close'];
    BackboneEx.View.Dialog2.prototype.getRenderedFooter = function renderFooter() {
        var self = this;
        // Define the order of elements which will be rendered. It is as follows:
        // First, render the left buttons
        // Lastly, render the right buttons but in reverse order.
        var leftButtons = [];
        var rightButtons = [];
        _.each(this.buttons, function(button, name) {
            if (button === false || button === null || typeof button === 'undefined') {
                // If a button is set to false or null or undefined, don't render anything
                return;
            }

            var data = getButtonData(self, name, _.isObject(button) ? button : {});
            var html = data.html ? data.html : getButtonHtml(data);
            if (data.isHidden) {
                return;
            }
            if (data.direction === 'left') {
                leftButtons.push(html);
            } else {
                rightButtons.push(html);
            }
        });

        var $footer = $('<div>', { 'class': 'dialog2--footer clearfix' });
        $footer.append( leftButtons.join('') );
        // Reversing as it's float: right and the order is inverted
        $footer.append( rightButtons.reverse().join('') );

        if (this.footerText) {
            var footerHtml = Wahanda.Template.renderTemplate('dialog2-footer-text-template', this.footerText);
            $footer.append(footerHtml);
        }
        return $footer;
    };

    function getButtonData(context, name, definition) {
        if (-1 !== _.indexOf(defaultButtons, name)) {
            return getDefaultButtonData(context, name, definition);
        } else if (name === 'menu') {
            return getMenuButtonData(context, definition);
        }
        var data = {};
        data.title     = getTitle(context, definition.title);
        data.isHidden  = getIsHidden(context, definition.isHidden);
        data.direction = (definition.onRight ? 'right' : 'left');
        data.classes = 'dialog2--button dialog2--button-' + data.direction + (definition.classes && ' ' + definition.classes || '');
        data.actions = definition.actions || null;
        return data;
    }

    function getDefaultButtonData(context, name, definition) {
        if (name === 'save') {
            return {
                title    : getTitle(context, definition.title, Wahanda.lang.shared.save),
                direction: 'right',
                classes  : 'dialog2--button dialog2--button-right dialog2--button-green js-save'
            };
        } else if (name === 'close') {
            return {
                title    : getTitle(context, definition.title, Wahanda.lang.shared.close),
                direction: 'left',
                classes  : 'dialog2--button dialog2--button-left js-close'
            };
        }
    }

    function getMenuButtonData(context, definition) {
        if (typeof definition === 'function') {
            var definition = definition(context);
        }
        var actions = [];
        var self = context;
        _.each(definition.items, function(item) {

            if (typeof item.label === 'function') {
                item.label = item.label(context);
            }
            if (typeof item.classes === 'function') {
                item.classes = item.classes(context);
            }
            if (typeof item.dataAttr === 'function') {
                item.dataAttr = item.dataAttr(context);
            }

            var classes = (item.classes || '');
            actions.push({
                classes: classes,
                label  : item.label,
                dataAttr: item.dataAttr,
            });
        });

        if (!showSubMenu(actions)) {
            return false;
        }


        return {
            html: Wahanda.Template.renderTemplate('dialog2-footer-button-menu-template', {
                actions: actions
            })
        };
    }

    function showSubMenu(menuActions) {
        return _.any(menuActions, function (action) {
            return action.label;
        });
    }

    function getButtonHtml(data) {
        return Wahanda.Template.renderTemplate('dialog2-footer-button-template', data);
    }

    function getTitle(context, title, fallback) {
        if (typeof title === 'function') {
            title = title.call(context);
        }
        return title || fallback;
    }

    function getIsHidden(context, isHidden) {
        if (typeof isHidden === 'function') {
            isHidden = isHidden.call(context);
        }
        return isHidden;
    }
})();
