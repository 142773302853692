import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import { Currency } from 'components/common/DataFormat';
import tableStyle from '../common/reportTable.scss';

interface IReportTableProps extends React.HTMLAttributes<Element> {
  rows: {
    type?: string;
    rows?: {
      service?: string;
      numberIncludingCancelled?: number;
      grossTotalIncludingCancelled?: number;
      cancelledNumber?: number;
      cancelledGrossTotal?: number;
      number?: number;
      vat?: number;
      grossTotal?: number;
      netTotal?: number;
    }[];
    numberIncludingCancelled?: number;
    grossTotalIncludingCancelled?: number;
    cancelledNumber?: number;
    cancelledGrossTotal?: number;
    number?: number;
    netTotal?: number;
    vat?: number;
    grossTotal?: number;
  }[];
  total: {
    numberIncludingCancelled?: number;
    grossTotalIncludingCancelled?: number;
    cancelledNumber?: number;
    cancelledGrossTotal?: number;
    number?: number;
    netTotal?: number;
    vat?: number;
    grossTotal?: number;
  };
  map?: any;
}

export default class ReportTable extends React.Component<IReportTableProps, {}> {
  getTableHeader = () => (
    <thead>
      <tr>
        <th className={tableStyle.wideHeader}>&nbsp;</th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.serviceSalesReport.headings.salesIncludingCancelled}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.serviceSalesReport.headings.salesCancelled}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.serviceSalesReport.headings.salesTotal}
        </th>
      </tr>

      <tr>
        <th>&nbsp;</th>
        <th className={classnames(tableStyle.numeric, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.serviceSalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.serviceSalesReport.headings.amountIncludingVat}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.serviceSalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.serviceSalesReport.headings.amountIncludingVat}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.serviceSalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.serviceSalesReport.headings.amountIncludingVat}
        </th>
      </tr>
    </thead>
  );

  getTableFooter = () => {
    const total = this.props.total;
    return (
      <tfoot>
        <tr>
          <td>{Wahanda.lang.reports.sales.serviceSalesReport.headings.grandTotal}</td>
          <td className={tableStyle.numeric}>{total.numberIncludingCancelled}</td>
          <td className={tableStyle.numeric}>
            <Currency amount={total.grossTotalIncludingCancelled} />
          </td>
          <td className={tableStyle.numeric}>{total.cancelledNumber}</td>
          <td className={tableStyle.numeric}>
            <Currency amount={total.cancelledGrossTotal} alwaysNegative />
          </td>
          <td className={tableStyle.numeric}>{total.number}</td>
          <td className={tableStyle.numeric}>
            <Currency amount={total.grossTotal} />
          </td>
        </tr>
      </tfoot>
    );
  };

  getRowsForServiceType = (serviceType) =>
    [
      <tr
        key={serviceType.type}
        className={tableStyle.rowHeading}
        data-test="service-sale-category"
      >
        <td>{serviceType.type}</td>
        <td className={tableStyle.numeric}>{serviceType.numberIncludingCancelled}</td>
        <td className={tableStyle.numeric}>
          <Currency amount={serviceType.grossTotalIncludingCancelled} />
        </td>
        <td className={tableStyle.numeric}>{serviceType.cancelledNumber}</td>
        <td className={tableStyle.numeric}>
          <Currency amount={serviceType.cancelledGrossTotal} alwaysNegative />
        </td>
        <td className={tableStyle.numeric}>{serviceType.number}</td>
        <td className={tableStyle.grandTotal}>
          <Currency amount={serviceType.grossTotal} />
        </td>
      </tr>,
    ].concat(
      serviceType.rows.map((row) => (
        <tr key={row.service}>
          <td className={tableStyle.rowSubHeading}>{row.service}</td>
          <td className={tableStyle.numeric}>{row.numberIncludingCancelled}</td>
          <td className={tableStyle.numeric}>
            <Currency amount={row.grossTotalIncludingCancelled} />
          </td>
          <td className={tableStyle.numeric}>{row.cancelledNumber}</td>
          <td className={tableStyle.numeric}>
            <Currency amount={row.cancelledGrossTotal} alwaysNegative />
          </td>
          <td className={tableStyle.numeric}>{row.number}</td>
          <td className={tableStyle.grandTotal}>
            <Currency amount={row.grossTotal} />
          </td>
        </tr>
      )),
    );

  getTableBody = () =>
    this.props.rows.map((serviceType) => this.getRowsForServiceType(serviceType));

  render() {
    return (
      <table className={classnames(tableStyle.reportTable, tableStyle.dynamic)}>
        {this.getTableHeader()}
        <tbody>{this.getTableBody()}</tbody>
        {this.getTableFooter()}
      </table>
    );
  }
}
