/* eslint indent:0 complexity:0 */

import { curry } from 'mori';

import moment from './moment';

const RFC3339_RE = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(Z|[0-9+:]{4,6})$/;
const DATE_RE = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

export function isRFC3339(datestamp) {
  return typeof datestamp === 'string' && datestamp.match(RFC3339_RE) !== null;
}

export function isDate(maybeDate) {
  return typeof maybeDate === 'string' && maybeDate.match(DATE_RE) !== null;
}

export function isJsDate(maybeDate) {
  return typeof maybeDate === 'object' && maybeDate instanceof Date;
}

export function toMoment(x, maybeFormat) {
  if (moment.isMoment && moment.isMoment(x)) {
    return x;
  }
  if (typeof maybeFormat === 'string' && typeof x === 'string') {
    return moment(x, maybeFormat);
  }
  if (isRFC3339(x)) {
    return moment(x, 'YYYY-MM-DDTHH:mm:ssZ');
  }
  if (isDate(x)) {
    return moment(x, 'YYYY-MM-DD');
  }
  if (isJsDate(x)) {
    return moment(x);
  }
  return null;
}

export function toJsDate(maybeDate) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const maybeMomentDate = toMoment(maybeDate);

  if (maybeMomentDate) {
    return maybeMomentDate.toDate();
  }
  return null;
}

export function format(date, toFormat = 'YYYY-MM-DDTHH:mm:ssZ') {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const momentDate = toMoment(date);

  if (momentDate === null) {
    return null;
  }
  return momentDate.format(toFormat);
}

export function formatForUserLocale(maybeDate, type) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const date = toMoment(maybeDate);

  if (moment.isMoment(date)) {
    switch (type) {
      case 'date':
        return date.formatDateDefault();
      case 'long-date':
        return date.formatLongLocaleDate();
      case 'time':
        return date.formatTimeDefault();
      case 'datetime':
        return date.formatFullDateTime();
      default:
        return maybeDate;
    }
  } else {
    return maybeDate;
  }
}

export const toDateString = curry(format, 'YYYY-MM-DD');

export const toTimeString = curry(format, 'HH:mm');

export const toRFC3339String = curry(format, 'YYYY-MM-DDTHH:mm:ssZ');

export function now() {
  return moment().format();
}

export function getStartOf(maybeDate, period) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const date = toMoment(maybeDate);

  switch (date && period) {
    case 'year':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.startOf('year'));
    case 'month':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.startOf('month'));
    case 'quarter':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.startOf('quarter'));
    case 'week':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.startOf('week'));
    case 'isoWeek':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.startOf('isoWeek'));
    case 'day':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.startOf('day'));
    case 'hour':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.startOf('hour'));
    case 'minute':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.startOf('minute'));
    case 'second':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.startOf('second'));
    default:
      return maybeDate;
  }
}

export function getEndOf(maybeDate, period) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const date = toMoment(maybeDate);

  switch (date && period) {
    case 'year':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.endOf('year'));
    case 'month':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.endOf('month'));
    case 'quarter':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.endOf('quarter'));
    case 'week':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.endOf('week'));
    case 'isoWeek':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.endOf('isoWeek'));
    case 'day':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.endOf('day'));
    case 'hour':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.endOf('hour'));
    case 'minute':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.endOf('minute'));
    case 'second':
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return format(date.endOf('second'));
    default:
      return maybeDate;
  }
}

export function getRange(pFrom, pTo) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const from = moment(toMoment(pFrom));
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const to = toMoment(pTo);

  if (from.isAfter(to, 'day')) {
    return [];
  }

  const list = [];
  do {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    list.push(toDateString(from));
    from.add(1, 'd');
  } while (from.isSameOrBefore(to, 'day'));

  return list;
}
