/* global BackboneEx */
/* eslint func-names: 0 */
import _ from 'common/underscore';

/**
 * Supplier model.
 */
(function () {
  const PAY_ON_REDEMPTION = 'POR';
  const PAY_ON_ORDER = 'POO';

  App.Models.Supplier = BackboneEx.Model.Silent.extend(
    {
      PAYMENT_TERM_ON_REDEMPTION: PAY_ON_REDEMPTION,
      PAYMENT_TERM_ON_ORDER: PAY_ON_ORDER,

      defaults: {
        address: '',
      },

      initialize: function () {},

      url: function () {
        return App.Api.wsUrl(`/supplier/${this.id}.json`);
      },

      /**
       * Returns contact
       *
       * @return Object or undefined
       */
      getContact: function () {
        return _.first(this.get('contacts') || []);
      },

      isPaidOnOrder: function () {
        // Payment terms are deprecated - everyone is on payment on redemption. Remove completely in DEV-25754.
        return false;
      },
    },
    {
      PAYMENT_TERM_ON_REDEMPTION: PAY_ON_REDEMPTION,
      PAYMENT_TERM_ON_ORDER: PAY_ON_ORDER,
    },
  );
})();
