import React, { PureComponent } from 'react';
import { Notice } from 'components/common/Notice';
import style from './style.scss';

const lang = Wahanda.lang.settings.venue.integrations.externalSystemDescription;
interface IExternalSystemDescriptionProps extends React.HTMLAttributes<Element> {
  name: string;
  noConnection: boolean;
}

export default class ExternalSystemDescription extends PureComponent<
  IExternalSystemDescriptionProps,
  {}
> {
  render() {
    const { name, noConnection } = this.props;
    return (
      <div className={style.externalSystemDescription}>
        <h1 className={style.header}>
          {Wahanda.Template.render(lang.header, { externalSystemName: name })}
        </h1>
        <p className={style.description} dangerouslySetInnerHTML={{ __html: lang.description }} />
        {noConnection && <Notice type="info">{lang.noConnection}</Notice>}
      </div>
    );
  }
}
