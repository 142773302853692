import { connect } from 'react-redux';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { bindActionCreators } from 'redux';
import { CalendarAnalytics } from 'common/analytics';

import * as actions from '../store/actions';
import { getShiftEmployee, getEmployeeDayWorkingHours } from '../store/selectors';
import { getVenueDayWorkingHours } from '../utils/helpers';
import { rotaPermissions } from '../utils/permissions';

import { ShiftVenueModal as Modal } from './ShiftVenueModal';

const mapStateToProps = ({ shifts }, { employeeId, data: { date }, trackingCategory = '' }) => {
  const employee = employeeId && getShiftEmployee(shifts, { employeeId });
  const employeeWorkingHours =
    employeeId && getEmployeeDayWorkingHours(shifts, { date, employeeId });
  const venueWorkingHours = getVenueDayWorkingHours(date, shifts.venue.workingHours);

  return {
    ...rotaPermissions({
      employeeId,
      employeeWorkingHours,
      venueWorkingHours,
    }),
    venue: shifts.venue,
    employee,
    employeeWorkingHours,
    venueName: App.config.venueName(),
    venueWorkingHours,
    isBusinessHoursLoaded: shifts.isBusinessHoursLoaded,
    isSingleVenueEmployee: shifts.employees.employeesWorkingHours.length === 1,
    isShownOnCalendar: trackingCategory === CalendarAnalytics.CATEGORY,
    isRotaEnabled:
      App.isFeatureSupported('venue-employee-rota') && Wahanda.Permissions.useCalendar(),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ShiftVenueModal = connect(mapStateToProps, mapDispatchToProps)(Modal);
