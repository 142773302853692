import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { getEmailTemplateReceive, getEmailTemplateFailure } from './actions';

export function* getEmailTemplateRequested() {
  try {
    const template = yield call(api.get, apiUrl('EMAIL_CAMPAIGN_PREVIEW'));
    yield put(getEmailTemplateReceive({ template }));
  } catch (err) {
    yield put(getEmailTemplateFailure(err));
  }
}

export function* getSmartDiscountsTemplateRequested(params) {
  try {
    const template = yield call(
      api.get,
      apiUrl('EMAIL_CAMPAIGN_SMART_DISCOUNT_PREVIEW', {
        discountRuleId: params.discountRuleId,
      }),
    );
    yield put(getEmailTemplateReceive({ template }));
  } catch (err) {
    yield put(getEmailTemplateFailure(err));
  }
}

export function* getServiceOnSaleTemplateRequested(params) {
  try {
    const template = yield call(
      api.get,
      apiUrl('EMAIL_CAMPAIGN_SERVICE_ON_SALE_PREVIEW', {
        treatmentGroupId: params.treatmentGroupId,
      }),
    );
    yield put(getEmailTemplateReceive({ template }));
  } catch (err) {
    yield put(getEmailTemplateFailure(err));
  }
}

export function* getAppForYourClientsTemplateRequested() {
  try {
    const template = yield call(api.get, apiUrl('EMAIL_CAMPAIGN_APP_FOR_YOUR_CLIENTS_PREVIEW'));
    yield put(getEmailTemplateReceive({ template }));
  } catch (err) {
    yield put(getEmailTemplateFailure(err));
  }
}
