import { useQuery } from 'react-query';
import { UDVCovidFormLinkResponse } from './types';
import apiUrl from 'common/api-url';
import * as api from 'src/redux/apiService';

const cacheKey = (...params: string[]) =>
  `CovidFormLink${params.reduce((total, item) => `${total}#${item}`, '')}`;

interface Params {
  isVisible: boolean;
  appointmentDate: string;
  customerId?: string;
}

export const useAppointmentCovidForm = ({ isVisible, appointmentDate, customerId }: Params) => {
  return useQuery<UDVCovidFormLinkResponse>(
    cacheKey(customerId ?? '', appointmentDate),
    () =>
      api.get<UDVCovidFormLinkResponse>(
        apiUrl('CONSULTATION_FORMS_FORM_REFERENCE', {
          templateCode: 'COVID_19',
          date: appointmentDate,
          venueCustomerId: customerId,
        }),
      ),
    {
      // absence of venue customer id means that appointment creation dialog has been opened,
      // so there is no need to fetch for form yet
      enabled: isVisible && !!customerId,
    },
  );
};
