import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { LayoutHeader, LayoutHeaderProps } from 'components/common/Layout';

interface Params extends LayoutHeaderProps {
  node: HTMLElement;
}

export const LayoutHeaderInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(params.node),
  render: () => {
    ReactDOM.render(
      <LayoutHeader header={params.header} subheader={params.subheader} actions={params.actions} />,
      params.node,
    );
  },
});
