import React from 'react';
import classnames from 'classnames';
import { Minus, Tick } from 'components/common/Icon';
import style from './Checkbox.scss';

export interface CheckboxProps {
  checked?: boolean;
  children?: React.ReactNode;
  className?: string;
  dataTest?: string;
  disabled?: boolean;
  name: string;
  label?: React.ReactNode;
  labelClassName?: string;
  onChange?: (checked?: boolean, event?: React.ChangeEvent<HTMLInputElement>) => void;
  semiChecked?: boolean;
  value?: string | number;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const Checkbox = (props: CheckboxProps) => {
  const {
    name,
    value,
    label,
    className,
    labelClassName,
    semiChecked,
    disabled,
    children,
    dataTest,
    inputRef,
  } = props;
  const [checked, setChecked] = React.useState(props.checked || false);
  const Icon = semiChecked ? Minus : Tick;
  React.useEffect(() => {
    setChecked(!!props.checked);
  }, [props.checked]);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked: nextChecked } = event?.currentTarget || {};
    if (typeof nextChecked === 'boolean') {
      setChecked(nextChecked);
      if (props.onChange) {
        props.onChange(nextChecked, event);
      }
    }
  };
  const checkboxClassNames = classnames(style.container, className);
  const labelClassNames = classnames(style.label, labelClassName);
  const tickClassName = classnames({
    [style.tick]: !checked && !semiChecked,
    [style.tickChecked]: checked && !semiChecked,
    [style.tickSemiChecked]: semiChecked,
    [style.tickDisabled]: disabled,
  });
  return (
    <label className={checkboxClassNames} htmlFor={name} data-test={dataTest}>
      <input
        className={style.hiddenInput}
        type="checkbox"
        name={name}
        id={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        ref={inputRef}
      />
      <div className={tickClassName}>
        <Icon className={style.icon} size="small" />
      </div>
      {(label || children) && (
        <div className={labelClassNames}>
          {label && <span>{label}</span>}
          {children}
        </div>
      )}
    </label>
  );
};

Checkbox.displayName = 'Checkbox';
