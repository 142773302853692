import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'common/moment';
import App from 'common/backbone-app';
import { requestMonthlySalesReportDataAction } from '../actions';
import { MonthlySalesReport } from './index';

const mapStateToProps = (state) => {
  const props = {
    isPosFull: App.config.isPosFull(),
    displayState: state.salesReport.monthlySales.displayState,
  };

  if (state.salesReport.monthlySales.reportData) {
    // Deep clone the report data
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'reportData' does not exist on type '{ is... Remove this comment to see the full error message
    props.reportData = JSON.parse(JSON.stringify(state.salesReport.monthlySales.reportData));
    // Convert date fields to moments
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'reportData' does not exist on type '{ is... Remove this comment to see the full error message
    props.reportData.rows = props.reportData.rows.map((row) => ({
      ...row,
      month: moment(row.month),
    }));
  }
  return props;
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ requestMonthlySalesReportDataAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export { PRESET_RANGES } from './index';

export default connector(MonthlySalesReport);
