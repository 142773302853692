import { call, put, take } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import LocalStorage from 'common/local-storage';

import { GET_DISCOUNT_RULE_RECEIVE } from 'reduxStore/actionsConst';

import {
  requestDiscountRuleAction,
  receiveDiscountRuleAction,
  requestDiscountRuleFailureAction,
  receiveVenueDetailsAction,
  requestVenueDetailsFailureAction,
  updateDiscountRuleSuccessAction,
  updateDiscountRuleFailureAction,
  createDiscountRuleSuccessAction,
  createDiscountRuleFailureAction,
  removeDiscountRuleSuccessAction,
  removeDiscountRuleFailureAction,
} from './actions';

import { displayEmailCampaignDialog } from './EmailCampaignDialog/actions';

// @ts-expect-error ts-migrate(2691) FIXME: An import path cannot end with a '.tsx' extension.... Remove this comment to see the full error message
import { LS_KEY as DISABLE_EMAIL_CAMPAIGN_LS_KEY } from './EmailCampaignDialog/index.tsx';

export function* getDiscountRuleDataRequested({ venueId }) {
  try {
    const data = yield call(api.get, `/api/venue/${venueId}/discount-rules.json`);

    yield put(receiveDiscountRuleAction(data));
  } catch (err) {
    yield put(requestDiscountRuleFailureAction(err));
  }
}

export function* getVenueDetailsDataRequested({ venueId }) {
  try {
    const data = yield call(api.get, `/api/venue/${venueId}.json?include=opening-hours`);

    yield put(receiveVenueDetailsAction(data));
  } catch (err) {
    yield put(requestVenueDetailsFailureAction(err));
  }
}

export function* putDiscountRuleDataRequested({ venueId, discountId, payload }) {
  try {
    const data = yield call(
      api.put,
      `/api/venue/${venueId}/discount-rule/${discountId}.json`,
      payload,
    );

    yield put(updateDiscountRuleSuccessAction(data, { venueId, discountId, payload }));

    yield put(requestDiscountRuleAction(venueId));

    if (!LocalStorage.getForVenue(DISABLE_EMAIL_CAMPAIGN_LS_KEY)) {
      // Wait for discount rules to be received
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      yield take(receiveDiscountRuleAction().type);

      yield put(displayEmailCampaignDialog(discountId));
    }
  } catch (ex) {
    yield put(updateDiscountRuleFailureAction(ex, { venueId, discountId, payload }));
  }
}

export function* addDiscountRuleDataRequested({ venueId, payload }) {
  try {
    const data = yield call(api.post, `/api/venue/${venueId}/discount-rule.json`, payload);

    yield put(createDiscountRuleSuccessAction(data, { venueId, payload }));

    yield put(requestDiscountRuleAction(venueId));

    if (!LocalStorage.getForVenue(DISABLE_EMAIL_CAMPAIGN_LS_KEY)) {
      // Wait for discount rules to be received
      yield take(GET_DISCOUNT_RULE_RECEIVE);

      yield put(displayEmailCampaignDialog(data.id));
    }
  } catch (ex) {
    yield put(createDiscountRuleFailureAction(ex, { venueId, payload }));
  }
}

export function* removeDiscountRuleDataRequested({ venueId, discountId }) {
  try {
    const data = yield call(api.del, `/api/venue/${venueId}/discount-rule/${discountId}.json`);

    yield put(removeDiscountRuleSuccessAction(data, { venueId }));

    yield put(requestDiscountRuleAction(venueId));
  } catch (ex) {
    yield put(removeDiscountRuleFailureAction(ex, { venueId }));
  }
}
