import React from 'react';
import Dialog from 'components/common/react-dialog';
import { DialogFooter } from 'components/common/DialogFooter';
import Wahanda from 'common/wahanda';

type AlertDialogProps = {
  dataTest?: string;
  text: string;
  onClose: (...args: any[]) => any;
  title: string;
};
const AlertDialog: React.SFC<AlertDialogProps> = (props) => {
  const { dataTest, onClose, title, text } = props;
  const buttons = {
    onClick: onClose,
    title: Wahanda.lang.shared.ok,
    type: 'alert',
  };
  const content = text.includes('</') ? (
    /* eslint-disable-next-line react/no-danger */
    <div dangerouslySetInnerHTML={{ __html: text }} />
  ) : (
    text
  );
  return (
    <Dialog
      dataTest={dataTest}
      classes={{ 'with-text-red-exclamation-icon': true }}
      onClose={onClose}
      hideTitlebar
    >
      <div className="text">
        <h2>{title}</h2>
        {content}
      </div>
      {/* @ts-expect-error ts-migrate(2740) FIXME: Type '{ onClick: (...args: any[]) => any; title: a... Remove this comment to see the full error message */}
      <DialogFooter buttons={buttons} fullWidth />
    </Dialog>
  );
};

export default AlertDialog;
