(function($) {
	/**
	 * Selects the nearest time of the given SELECT element.
	 * 
	 * @param String|Int time In format HH:MM, or minutes from midnight.
	 */
	$.fn.selectClosestTime = function(time) {
		var $options = this.find('option');
		var selected = null;
		time         = isNaN(time) ? ttm(time) : time;
		
		$options.each(function(opt, index) {
			var val             = $(this).val();
			var currentDuration = ttm(val);
			if (currentDuration >= time) {
				selected = val;
				return false;
			}
		});
		
		if (null === selected) {
			var $last = $options.last();
			if (ttm($last.val()) < time) {
				selected = $last.val();
			}
		}
		
		this.val(selected);
		return this;
	};
	
	function ttm(time) {
		return Wahanda.Time.timeToMinutes(time);
	}
}(jQuery));
