import React from 'react';
import ReactDOM from 'react-dom';
import ProductDialog from 'components/menu/ProductsTab/ProductDialog';

function onClose(container) {
  ReactDOM.unmountComponentAtNode(container);
}

export default function (options: any = {}) {
  const container = Wahanda.Dialogs.getReactContainer();
  const close = (refetch) => {
    onClose(container);
    if (options.onClose) {
      options.onClose(refetch);
    }
  };
  const dialog = (
    <ProductDialog
      onClose={close}
      editedProduct={options.editProduct}
      vatRates={options.vatRates}
    />
  );
  ReactDOM.render(dialog, container);
}
