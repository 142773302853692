$(function($) {
	/**
	 * Aligns two time select, so that the first would have less time than the next one.
	 * 
	 * This plugin must be run on the changed time select.
	 * The other one must be a sibling (reside in the same parent).
	 * 
	 * @param Object options Prameters for the plugin. Possible values are
	 * 	> lesserSelectName - Name for the select element, which's value should be less
	 */
	$.fn.alignTimeSelects = function(options) {
		var opts         = $.extend({}, $.alignTimeSelects.defaults, options || {});
		var $select      = this;
		var $otherSelect = $select.siblings('select').eq(0);
		var shouldBeLess = $select.attr('name').indexOf(opts.lesserSelectName) !== -1;
		// 24 hours
		var max 		 = Wahanda.Time.timeToMinutes($select.find('option:last').attr('value'));
		var step		 = 60;
		var change       = null;
		
		var time  = Wahanda.Time.timeToMinutes($select.val());
		var other = Wahanda.Time.timeToMinutes($otherSelect.val());
		
		if (shouldBeLess && time >= other) {
			change = time + step;
			if (change > max) {
				change = max;
			}
		} else if (!shouldBeLess && time <= other) {
			change = time - step;
			if (change < 0) {
				change = 0;
			}
		}
		
		if (null !== change) {
			$otherSelect.val(
				Wahanda.Time.toFormatted(change, "H:i")
			)
			// Trigger the change event
			.change();
		}
	};
	
	$.alignTimeSelects = {
		defaults: {
			lesserSelectName: 'from'
		}
	};
}(jQuery));
