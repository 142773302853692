App.Collections.RoomTypes = Backbone.Collection.extend({
  model: App.Models.RoomType,
  /** true, false, null */
  active: true,

  url: function () {
    var base = '/venue/' + App.config.get('venue').id + '/room-types.json?';

    if (typeof this.active === 'boolean') {
      base += 'active=' + (this.active ? 'true' : 'false');
    }

    return App.Api.wsUrl(base);
  },

  /**
   * Returns array of models for this collection to use.
   *
   * @param Object data
   * @return Array
   */
  parse: function (data) {
    return data.roomTypes;
  },

  comparator: function (a, b) {
    var diff = a.get('maxOccupancy') - b.get('maxOccupancy');
    if (0 === diff) {
      diff = a.get('minOccupancy') - b.get('minOccupancy');
    }
    if (0 === diff) {
      diff = String(a.get('name')).localeCompare(String(b.get('name')));
    }
    return diff;
  },

  toSelectOptions: function (config) {
    config || (config = {});
    var options = [];

    if (this.length > 1) {
      options.push({
        value: 'all',
        title: Wahanda.lang.calendar.spaBreaks.allResources,
        selected: !config.selected,
      });
    }

    this.each(function (roomType) {
      options.push({
        value: roomType.id,
        title: roomType.get('name'),
        selected: config.selected && config.selected === roomType.id,
      });
    });

    return options;
  },
});
