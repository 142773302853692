import React from 'react';
import classnames from 'classnames';
import { colors } from 'style/exports/colors';

import style from './AnimatedTick.scss';

export interface AnimatedTickProps {
  className?: string;
  size?: number;
  colour?: string;
}

export const AnimatedTick = ({
  className,
  size = 24,
  colour = colors.colorGreen,
}: AnimatedTickProps) => {
  return (
    <svg
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
      width={size}
      height={size}
    >
      <circle
        className={classnames(style.path, style.circle)}
        stroke={colour}
        cx="65.1"
        cy="65.1"
        r="62.1"
      />
      <polyline
        stroke={colour}
        className={classnames(style.path, style.check)}
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>
  );
};
