import { createAction } from 'reduxStore/actionCreator';

import {
  FBE_UNINSTALL_REQUEST,
  FBE_UNINSTALL_SUCCESS,
  FBE_UNINSTALL_FAILURE,
} from 'reduxStore/actionsConst';

export const uninstallFBERequest = () => createAction(FBE_UNINSTALL_REQUEST);

export type UninstallFBERequestAction = ReturnType<typeof uninstallFBERequest>;

export const uninstallFBESuccess = () => createAction(FBE_UNINSTALL_SUCCESS);

export type UninstallFBESuccessAction = ReturnType<typeof uninstallFBESuccess>;

export const uninstallFBEFailure = () => createAction(FBE_UNINSTALL_FAILURE);

export type UninstallFBEFailureAction = ReturnType<typeof uninstallFBEFailure>;
