import React, { Children, useState } from 'react';
import style from './ClientFormsOnboardingModal.scss';
import { Modal, ModalContainer } from 'components/common/Modal';
import { Button, ButtonSpacer } from 'components/common/Button';
import Wahanda from 'common/wahanda';

const sharedLang = Wahanda.lang.shared;
const lang = Wahanda.lang.marketing.clientForms.onboardingModal;

export const OnboardingModal = ({
  children,
  onClose,
  dataTestId,
}: {
  dataTestId?: string;
  children: React.ReactNode[];
  onClose: () => void;
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const goNext = () => {
    if (currentStep < children.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goPrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const currentChild = Children.toArray(children)[currentStep];
  const isLastStep = currentStep === children.length - 1;

  return (
    <Modal onClose={onClose} dataTest={dataTestId}>
      <ModalContainer onClose={onClose} className={style.onboardingModalContainer}>
        {currentChild}
        {currentStep === 0 && (
          <article className={style.onboardingNextButtonWrapper}>
            <Button
              dataTest={`${dataTestId}-explore-now-button`}
              label={lang.exploreNowButton}
              size="large"
              fullWidth
              onClick={goNext}
            />
          </article>
        )}
        {currentStep > 0 && (
          <article className={style.onboardingButtonsWrapper}>
            <Button
              dataTest={`${dataTestId}-back-button`}
              label={sharedLang.navigation.back}
              size="large"
              variant="hollow"
              onClick={goPrevious}
            />
            <ButtonSpacer />
            <Button
              dataTest={`${dataTestId}-next-button`}
              label={isLastStep ? lang.startNowButton : sharedLang.navigation.next}
              size="large"
              onClick={isLastStep ? onClose : goNext}
            />
          </article>
        )}
      </ModalContainer>
    </Modal>
  );
};
