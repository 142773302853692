import Wahanda from 'common/wahanda';

export interface TimeSlot {
  name: string;
  value: number | string;
}

export function mapTimeSlots(slots: any[]): TimeSlot[] {
  const formatName = (time) => {
    if (time === null) {
      return Wahanda.lang.menu.offer.pricing.noTimeSelected;
    }

    return time.title || `${time} ${Wahanda.lang.datetime.duration.abbr.minutes}`;
  };

  const values = slots.map((time) => ({
    name: formatName(time),
    value: (time && time.minutes) || time,
  }));
  return values;
}

export function getDropdownPosition(node, parent) {
  const rect = node.current ? node.current.getBoundingClientRect() : {};
  const parentRect = parent.current ? parent.current.getBoundingClientRect() : {};
  const winHeight = window.innerHeight;
  const height = rect.height;
  const left = parentRect.left - Math.abs(parentRect.width - rect.width) / 2;

  const top = (() => {
    const topAlignedToParent = parentRect.top + 30;

    if (topAlignedToParent < 0) {
      return 10;
    }
    if (topAlignedToParent + height > winHeight) {
      return parentRect.top - height / 2;
    }
    return topAlignedToParent;
  })();

  return {
    left,
    top,
  };
}
