import Wahanda from 'common/wahanda';
import { SHIFTS_TAB, TEAM_MEMBER_TAB } from './constants';

export const getRedirectUrl = (
  isRotaTabPermitted: boolean,
  isTeamTabPermitted: boolean,
  activePage?: string,
): { currentTab?: string; redirectUrl?: Location } => {
  // If user doesn't have permissions to see either of the pages
  // redirect to calendar
  if (!isRotaTabPermitted && !isTeamTabPermitted) {
    return {
      redirectUrl: Wahanda.Url.getFullUrl('calendar'),
    };
  }

  // If user doesn't have permission to see Rota
  // change current tab to team
  if (!isRotaTabPermitted && activePage === SHIFTS_TAB) {
    return {
      currentTab: TEAM_MEMBER_TAB,
    };
  }

  // If user doesn't have permission to see Team
  // change current tab to rota
  if (!isTeamTabPermitted && activePage === TEAM_MEMBER_TAB) {
    return {
      currentTab: SHIFTS_TAB,
    };
  }

  return {
    redirectUrl: undefined,
    currentTab: undefined,
  };
};
