import React from 'react';
import Wahanda from 'common/wahanda';
import { LayoutHeader } from 'components/common/Layout';
import { Box } from 'components/common/Box';
import { InstallStatus } from '../InstallStatus';
import { BookingChannels, BookingChannelsWithWarnings } from './BookingChannels';

const lang = Wahanda.lang.marketing.facebookBusinessExtension;

export interface Props {
  hasWarnings: boolean;
}

export const Integration: React.FC<Props> = ({ hasWarnings }) => {
  return (
    <Box>
      <div>
        <LayoutHeader header={lang.headerOne} subheader={lang.description} />
        <InstallStatus />
      </div>
      <div>
        <LayoutHeader header={lang.headerTwo} />
        {hasWarnings ? <BookingChannelsWithWarnings /> : <BookingChannels />}
      </div>
    </Box>
  );
};
