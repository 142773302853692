import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { Checkbox } from 'components/common/Checkbox';
import Chevron from 'components/common/icons/Chevron';
import style from './style.scss';
// @ts-expect-error ts-migrate(2430) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
interface IProductListItemProps extends React.HTMLAttributes<Element> {
  id: number;
  name: string;
  newSupplier: {
    id?: number;
    name: string;
  };
  productSupplier?: {
    id: number;
    name: string;
  };
  selected?: boolean;
  actions: {
    selectProductAction: (...args: any[]) => any;
  };
  selectProductAction?: any;
}

export default class ProductListItem extends Component<IProductListItemProps, {}> {
  static defaultProps = {
    productSupplier: null,
    selected: false,
  };

  onChange = (isChecked) => {
    this.props.actions.selectProductAction({
      id: this.props.id,
      selected: isChecked,
    });
  };

  renderAssignationBadges = () => {
    const { newSupplier, productSupplier, selected } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (productSupplier === null || productSupplier.id === newSupplier.id) {
      return null;
    }
    return (
      <div className={style.assignationsBadges}>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        <div>{productSupplier.name}</div>
        {selected && (
          <Fragment>
            <Chevron color={style.chevronColor} />
            <div>{newSupplier.name}</div>
          </Fragment>
        )}
      </div>
    );
  };

  render() {
    const { id, name, selected } = this.props;
    return (
      <li className={classnames(style.listItem, { selected })}>
        <div className={style.checkboxContainer}>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'string'. */}
          <Checkbox name={id} label={name} checked={selected} onChange={this.onChange} value={id} />
        </div>
        {this.renderAssignationBadges()}
      </li>
    );
  }
}
