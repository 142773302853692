import React, { useContext } from 'react';
import classnames from 'classnames';

import { InputContext } from '../InputContext';
import style from './InputError.scss';

interface Props {
  dataTest?: string;
  message: string;
}

export const InputError = ({ dataTest, message }: Props) => {
  const { fullBorder } = useContext(InputContext);
  const classes = classnames(style.container, {
    [style.fullBorder]: fullBorder,
  });

  return (
    <div
      className={classes}
      data-test={`${dataTest}-error`}
      dangerouslySetInnerHTML={{ __html: message }}
    />
  );
};
