import React from 'react';
import classname from 'classnames';
import DialogContentBox from 'components/common/dialog/DialogContentBox';
import {
  Table,
  TableColumnHeader,
  TableRow,
  TableBody,
  TableHeader,
  TableData,
} from 'components/common/Table';
import Wahanda from 'common/wahanda';
import style from './style.scss';

const LANG = Wahanda.lang.menu.products.orders.orderDetailsDialog.productsOrdered;
interface IProductsOrderedProps extends React.HTMLAttributes<Element> {
  products: {
    id: number;
    name: string;
    quantity: number;
  }[];
}
class ProductsOrdered extends React.Component<IProductsOrderedProps, {}> {
  renderRows = () => {
    const products = this.props.products;
    return products.map((product) => (
      <TableRow key={product.id}>
        <TableData>{product.name}</TableData>
        <TableData className={style.right}>{product.quantity}</TableData>
      </TableRow>
    ));
  };

  render() {
    return (
      <DialogContentBox>
        <Table>
          <TableHeader>
            <TableRow>
              <TableColumnHeader className={style.noWrap}>
                {LANG.tableHeader.product}
              </TableColumnHeader>
              <TableColumnHeader className={classname(style.right, style.noWrap)}>
                {LANG.tableHeader.quantity}
              </TableColumnHeader>
            </TableRow>
          </TableHeader>
          <TableBody>{this.renderRows()}</TableBody>
        </Table>
      </DialogContentBox>
    );
  }
}

export default ProductsOrdered;
