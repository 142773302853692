import { trackEvent, ACTIONS } from 'common/analytics';
import { TYPES } from './services';

export enum OfferTypeValue {
  IN_GROUP = 1,
  TOP_BUTTON = 0,
}

export class OfferTypeChoiceDialogAnalytics {
  public static CATEGORY = 'services';

  public static trackOfferChoiceModalClick() {
    trackEvent(OfferTypeChoiceDialogAnalytics.CATEGORY, ACTIONS.CLICK, 'open-services-dialog');
  }

  public static trackOfferChoiceModalPerGroupClick() {
    trackEvent(
      OfferTypeChoiceDialogAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'open-services-dialog',
      'in group',
    );
  }

  public static trackOfferChoiceModalView() {
    trackEvent(OfferTypeChoiceDialogAnalytics.CATEGORY, ACTIONS.VIEW, 'services-dialog');
  }

  public static trackOfferTypeChoiceClick(payload: { property: TYPES; value?: OfferTypeValue }) {
    trackEvent(
      OfferTypeChoiceDialogAnalytics.CATEGORY,
      ACTIONS.CLICK,
      'service-dialog',
      payload.property,
      payload.value,
    );
  }
}
