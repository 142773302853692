import {
  WIDGET_TOGGLE_ENABLED_REQUEST,
  WIDGET_TOGGLE_ENABLED_SUCCESS,
  WIDGET_TOGGLE_ENABLED_FAILURE,
  WIDGET_GENERATE_CODE_REQUEST,
  WIDGET_GENERATE_CODE_SUCCESS,
  WIDGET_GENERATE_CODE_FAILURE,
  WIDGET_CLEAN_STORE,
  WIDGET_CLEAR_ERROR,
  WIDGET_SELECT_LANGUAGE,
} from 'reduxStore/actionsConst';

export const widgetToggleEnabled = ({ venueId, enabled }) => ({
  type: WIDGET_TOGGLE_ENABLED_REQUEST,
  venueId,
  enabled,
});

export const widgetToggleEnabledSuccess = ({ params, data }) => ({
  type: WIDGET_TOGGLE_ENABLED_SUCCESS,
  params,
  data,
});

export const widgetToggleEnabledFailure = ({ params, error }) => ({
  type: WIDGET_TOGGLE_ENABLED_FAILURE,
  params,
  error,
});

export const widgetGenerateCode = ({ locale }) => ({
  type: WIDGET_GENERATE_CODE_REQUEST,
  locale,
});

export const widgetGenerateCodeSuccess = ({ params, data }) => ({
  type: WIDGET_GENERATE_CODE_SUCCESS,
  data,
  params,
});

export const widgetGenerateCodeFailure = ({ params, error }) => ({
  type: WIDGET_GENERATE_CODE_FAILURE,
  error,
  params,
});

export const widgetCleanStore = () => ({
  type: WIDGET_CLEAN_STORE,
});

export const widgetClearError = () => ({
  type: WIDGET_CLEAR_ERROR,
});

export const widgetSelectLanguage = ({ lang }) => ({
  type: WIDGET_SELECT_LANGUAGE,
  lang,
});
