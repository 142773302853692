import {
  GET_SUPPLIER_DETAILS_REQUEST,
  GET_SUPPLIER_DETAILS_RECEIVE,
  GET_SUPPLIER_DETAILS_FAILURE,
} from 'reduxStore/actionsConst';

const defaultState = { isVatPayer: false };

export default function ReportContainerReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_SUPPLIER_DETAILS_RECEIVE:
      return {
        ...state,
        isVatPayer: action.data.vatNumber !== null,
      };
    case GET_SUPPLIER_DETAILS_REQUEST:
      return { ...state };
    case GET_SUPPLIER_DETAILS_FAILURE:
      return { ...state, errorMsg: action.ex };
    default:
      return state;
  }
}
