/* global BackboneEx */
import { enableRecording } from 'common/analytics';

App.Views.Menu = BackboneEx.View.Main.extend({
  tabsInitialized: false,

  initialize: function initialize() {
    enableRecording();
    this.lastVenueId = App.getVenueId();
  },

  showLoader: function showLoader() {
    this.lastShownLoader = this.$('.data-content:visible').loadmask();
  },

  hideLoader: function hideLoader() {
    return this.lastShownLoader && this.lastShownLoader.unloadmask();
  },

  getSelectedTab: function getSelectedTab() {
    const index = this.$el.tabs('option', 'selected');
    // Return the string #hash
    return this.$('.ui-tabs-nav').find('a').eq(index);
  },

  selectTab: function selectTab(hash) {
    const tabIndex = this.$('.ui-tabs-nav')
      .children()
      .index(this.$(`${hash}-tab`));
    this.$el.tabs('option', 'selected', tabIndex);
  },

  render: function render() {
    const page = window.location.href.includes('/menu') ? 'menu' : 'products';
    App.headerView.setModule(page);

    const menuNode = document.getElementById('menu-holder');

    if (App.getVenueId() !== this.lastVenueId) {
      // Destroy the previous state, as we have a new venue
      App.ES6.Initializers.Menu.destroy(menuNode);
      this.lastVenueId = App.getVenueId();
    }

    App.ES6.Initializers.Menu.render(menuNode, App.config.toJSON(), {
      tab: (App.mainViewOptions || {}).initialTab,
      page,
    });
    this.$('#nav2 .title:visible').center({ horizontal: false });
  },
});
