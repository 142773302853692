import React from 'react';
import Wahanda from 'common/wahanda';

const LANG = Wahanda.lang.dashboard.trafficLights.criteriaList.allDone;

export default class AllDone extends React.Component<{}, {}> {
  render() {
    return (
      <div className="all-done">
        <span className="all-done--icon" />
        <div className="all-done--title">{LANG.title}</div>
        <div className="all-done--note">{LANG.note}</div>
      </div>
    );
  }
}
