;(function($) {
	"use strict";
	/**
	 * A "notice" tooltip.
	 * 
	 * @param string text Text to show
	 * @param Object qtipOptions OPTIONAL
	 * @param int hideAfter OPTIONAL, default 500ms
	 * 
	 * @uses qtip2
	 * Copyright Wahanda.com
	 */
	$.fn.noticeTip = function(text, qtipOptions, hideAfter) {
		var opts = $.extend(
			{},
			$.fn.noticeTip.defaults,
			qtipOptions || {},
			{ content: { text: text } }
		);
		
		if (hideAfter > 0) {
			opts.hide.inactive = hideAfter;
		}
		
		this.each(function() {
			$(this).qtip(opts);
		});
	};
	
	// Global default configuration
	$.fn.noticeTip.defaults = {
		position: {
			my: "bottom center",
			at: "top center",
			adjust: {
				y: -2
			}
		},
		content: {
			text: "text"
		},
		show: {ready: true, event: false},
		hide: {
			event: false,
			inactive: 1500,
			effect: function(offset) {
				$(this).fadeOut(500);
			}
		},
		// This hides the qTip onClick
		events: {
			render: function(event, api) {
				api.elements.tooltip.click(api.hide);
			}
		},
		style: {
			tip: {
				width: 12,
				height: 7
			},
			classes: 'ui-qtip-notice'
		}
	};
}(jQuery));
