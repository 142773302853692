/* global BackboneEx */

(() => {
  const dialog2close = BackboneEx.View.Dialog2.prototype.close;

  App.Views.Dialog.ClientDeletionConfirm = BackboneEx.View.Dialog2.extend({
    dialogTitle: Wahanda.lang.consumer.dialog.questions.deletionTitle,
    templateId: 'client-deletion-confirm-template',
    options: {
      width: 350,
      dialogClass: 'client-confirm-dialog',
      dataTest: 'client-deletion-confirm-modal',
    },
    buttons: {
      delete: {
        title: Wahanda.lang.shared.delete,
        onRight: true,
        classes: 'js-delete dialog2--button-red',
      },
    },
    events: {
      'click .js-delete': 'deleteConfirm',
      'click .js-close': 'close',
    },

    render() {
      if (!this.rendered) {
        BackboneEx.View.Dialog2.prototype.render.call(this);
      }
    },

    deleteConfirm() {
      const self = this;
      this.model.setArchived();

      this.model.save().done(function () {
        App.trigger(Wahanda.Event.CUSTOMER_ARCHIVED, self.model);
        self.close(true);
      });
    },

    close(parentClose) {
      // By default, don't close the parent dialog
      dialog2close.call(this, typeof parentClose === 'boolean' ? parentClose : false);
    },
  });
})();
