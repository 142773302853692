import { connect } from 'react-redux';
import { BackToBusinessInit as Component } from './BackToBusinessInit';
import { backToBusinessInitStatusRequest } from '../actions';
import { BackToBusinessState } from '../reducer';

const mapStateToProps = ({ marketing }) => {
  const backToBusiness: BackToBusinessState = marketing?.backToBusiness;
  return {
    isLoading: backToBusiness.isLoading,
    alreadySent: backToBusiness.alreadySent,
  };
};
const mapDispatchToProps = {
  backToBusinessInitStatusRequest,
};

export const BackToBusinessInit = connect(mapStateToProps, mapDispatchToProps)(Component);
