import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ServicesTab as Component } from './ServicesTab';
import { selectPromotion } from '../../marketing/EmailCampaign/CampaignSelection/actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      selectPromotion,
    },
    dispatch,
  ),
});

export const ServicesTab = connect(null, mapDispatchToProps)(Component);
