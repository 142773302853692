import React, { useState } from 'react';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import moment from 'common/moment';
import { DatePickerWrapper, Props as DatePickerWrapperProps } from '../DatePickerWrapper';
import { Button } from 'components/common/Button';
import { Chevron } from 'components/common/Icon';

import style from '../DatePickerWrapper/DatePickerWrapper.scss';

export interface Props {
  date: string;
  id: string;
  focused?: boolean;
  blockFutureDays?: boolean;
  isDayBlocked?: (date: moment.Moment) => boolean;
  onDateChange: (date?: string) => void;
  onFocusChange?: SingleDatePickerShape['onFocusChange'];
  navigation?: {
    onNextRangeSelect: () => void;
    onPrevRangeSelect: () => void;
  };
}

export const SingleDayPicker = (props: Props) => {
  const [isFocused, changeFocus] = useState<boolean | null>(false);

  const isDayBlocked = (date: moment.Moment) => {
    if (props.isDayBlocked) {
      return props.isDayBlocked(date);
    }
    return false;
  };

  const onDateChange = (date: moment.Moment | null) => {
    if (date && isDayBlocked(date)) {
      return;
    }
    props.onDateChange(date?.formatApiDateString());
  };

  const onFocusChange = (args: { focused: boolean | null }) => {
    changeFocus(args.focused);
    props.onFocusChange?.(args);
  };

  const getNavigationProps = (): DatePickerWrapperProps['navigation'] => {
    if (!props.navigation) {
      return undefined;
    }
    return {
      nextRange: {
        onSelect: props.navigation.onNextRangeSelect,
        disabled: isDayBlocked(moment(props.date).add(1, 'd')),
      },
      prevRange: {
        onSelect: props.navigation.onPrevRangeSelect,
        disabled: isDayBlocked(moment(props.date).subtract(1, 'd')),
      },
    };
  };
  const isOutsideRange = () => {
    return false;
  };

  return (
    <DatePickerWrapper
      navigation={getNavigationProps()}
      render={() => (
        <SingleDatePicker
          onDateChange={onDateChange}
          date={moment(props.date)}
          id={props.id}
          onFocusChange={onFocusChange}
          focused={props.focused || isFocused}
          hideKeyboardShortcutsPanel
          numberOfMonths={1}
          navNext={<Button icon={<Chevron />} colour="plain" variant="hollow" />}
          navPrev={
            <Button icon={<Chevron className={style.prev} />} colour="plain" variant="hollow" />
          }
          isDayBlocked={isDayBlocked}
          isOutsideRange={isOutsideRange}
          verticalSpacing={0}
          daySize={40}
        />
      )}
    />
  );
};
