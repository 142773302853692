/* global BackboneEx */
/* eslint func-names:0 */
import { post } from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { trackEvent } from 'common/analytics';
import _ from 'common/underscore';
import { POS_OPERATIONS } from 'common/constants/pos-operations';
import Wahanda from 'common/wahanda';
import BigNumber from 'bignumber.js';
/**
 *	POSDialog extends the POSDialogBase to include Payments.
 */

if (!App.Views.POS) {
  App.Views.POS = {};
}

App.Views.Forms.POSDialog = App.Views.Forms.POS.POSDialogBase.extend({
  events: {
    'click .js-pos-payment--cash': 'showCashPaymentForm',
    'click .js-pos-payment--card': 'showCardPaymentForm',
    'click .js-pos-payment--voucher': 'showVoucherForm',
  },

  baseView: App.Views.Forms.POS.POSDialogBase,

  templateId: 'pos-checkout-template',

  options: {
    width: 750,
    dialogClass: 'dialog2--scrollable pos-checkout-dialog scrollbar-see-through-fix',
    dataTest: 'checkout-modal',
  },

  dialogTitle: Wahanda.lang.calendar.appointments.pos.title,

  buttons: {
    complete: {
      title: Wahanda.lang.calendar.appointments.pos.buttonCheckoutFinalise,
      classes:
        'dialog2--button-blue dialog2--button-disabled pos-checkout--button-complete js-complete-checkout',
      onRight: true,
    },
  },
  checkoutComplete: false,

  destruct: function () {
    // Call `remove` on all views to stop listening for events and do other cleanup
    const self = this;
    App.Views.Forms.POS.POSDialogBase.prototype.destruct.call(this);

    if (!this.checkoutComplete) {
      logCanceledCheckout();
    }

    async function logCanceledCheckout() {
      post(apiUrl('POS_OPERATION_ABORTED'), {
        operationType: POS_OPERATIONS.CHECKOUT,
        operationId: self.operationId,
      });
    }
  },

  initialize: function (...args) {
    const self = this;
    this.baseView.prototype.initialize.apply(this, args);

    this.options = _.extend({}, this.defaultOptions, this.options || {});

    // Pick up the parent events so we don't have to do our own close, etc
    this.events = _.extend({}, this.baseView.prototype.events, this.events || {});

    this.payments = this.checkoutCalc.getPayments();
    this.tips = this.checkoutCalc.getTips();

    this.enableCompletePaymentButton = false;

    this.payments.on(
      'add remove reset',
      function onUpdatePayments() {
        this.updatePayments();
        this.updateTotals();
        this.showAddPaymentButtons();
        this.refreshPosition();
      },
      this,
    );

    this.payments.on(
      'remove',
      function onRemovePayments() {
        this.updatePayments();
      },
      this,
    );

    this.products.on(
      'add change remove',
      function onUpdateProducts() {
        this.updateTotals();
        this.updatePayments();
        this.showAddPaymentButtons();
        this.refreshPosition();
      },
      this,
    );

    if (Wahanda.Features.isEnabled('pos-de-tss')) {
      trackPOSCheckoutStarted();
    }

    async function trackPOSCheckoutStarted() {
      post(apiUrl('POS_OPERATION'), {
        operationType: POS_OPERATIONS.CHECKOUT,
      }).then((response) => {
        self.operationId = response.operationId;
      });
    }
  },

  resetTips: function () {
    const self = this;
    this.payments.each((payment) => {
      const paymentTip = payment.get('tip');
      const paymentCost = payment.get('costAmount');
      if (paymentTip) {
        payment.set('amount', paymentCost + paymentTip.get('amount'), {
          silent: true,
        });
      }
      payment.unset('tip', { silent: true });
    });
    self.tips.reset();
  },

  onWindowResize: function () {
    this.setHeight();
    this.setMaxScrollHeight();
    this.refreshPosition();
  },

  render: function (...args) {
    this.baseView.prototype.render.apply(this, args);
    this.loadmask();

    this.updateTotals();
    this.showAddPaymentButtons();
    const self = this;

    this.setMaxScrollHeight();
    this.refreshPosition();

    this.employees = Wahanda.Cache.allEmployees();
    this.employees.done((collection) => {
      self.employees = collection;
      self.renderEmployeeSelection();
      self.unloadmask();
    });
    trackEvent('checkout', 'view', 'checkout-dialog');
  },

  getPaymentTitle: function () {
    return { title: Wahanda.lang.calendar.appointments.pos.paymentsTitle };
  },

  getEmployeeId: function () {
    if (this.$('.pos-employee-selection').find('select').length > 0) {
      return this.$('.pos-employee-selection').find('select').selectmenu('value');
    }
    return App.config.get('account').employeeId;
  },

  renderEmployeeSelection: function () {
    const employeeList = [];

    const currentEmployeeId = App.config.get('account').employeeId;
    this.employees.each((employee) => {
      employeeList.push({
        id: employee.id,
        name: employee.get('name'),
        selected: employee.id === currentEmployeeId,
      });
    });
    if (employeeList.length === 0) {
      return;
    }

    const html = Wahanda.Template.renderTemplate('pos-employee-selection', {
      employees: employeeList,
    });

    this.$('.pos-employee-selection').html(html);
    const $employeeWrapper = this.$('.pos-employee-selection').find('.wrapper');
    this.$('.pos-employee-selection')
      .find('select')
      .selectmenu({
        appendTo: $employeeWrapper,
        width: $employeeWrapper.width() || 205,
      });
  },

  renderBalances: function () {
    const balances = {
      'pos-balance-due': this.$('.js-balance-due'),
      'pos-balance-total-balance-due': this.$('.js-subtotal-due'),
      'pos-balance-total-paid': this.$('.js-total-paid'),
      'pos-balance-total-change': this.$('.js-total-change'),
    };

    const self = this;
    _.each(balances, ($el, templateId) => {
      const view = self.createBalanceView($el, templateId);
      view.render();
    });
  },

  getCollections: function () {
    return [this.products, this.payments, this.tips];
  },

  updateTotals: function () {
    this.showAddPaymentButtons();
    this.renderBalances();
  },

  updatePayments: function () {
    this.removePaymentForm();
    this.totalCost = this.checkoutCalc.getTotalCost();
    this.paid = 0;
    this.resetTips();
    const self = this;
    // The order of payment methods to take the money from are: voucher, card, cash.
    const paymentTypeOrderPrecedence = [
      App.Models.POS.PaymentDiscriminators.VOUCHER,
      App.Models.POS.PaymentDiscriminators.CARD,
      App.Models.POS.PaymentDiscriminators.CASH,
    ];

    _.each(paymentTypeOrderPrecedence, (paymentType) => {
      _.each(self.payments.getPaymentsByType(paymentType), (payment) => {
        self.updatePayment(payment);
      });
    });
  },

  updatePayment: function (payment) {
    const cardPayment = payment.get('paymentType') === App.Models.POS.PaymentDiscriminators.CARD;
    const voucherPayment =
      payment.get('paymentType') === App.Models.POS.PaymentDiscriminators.VOUCHER;
    const paymentAmount = payment.get('amount');
    let costAmount;
    if (paymentAmount <= this.totalCost - this.paid) {
      costAmount = paymentAmount;
    } else if (this.totalCost - this.paid < 0) {
      costAmount = 0;
    } else {
      costAmount = new BigNumber(this.totalCost).minus(this.paid).toNumber();
    }
    payment.set('costAmount', costAmount);

    this.paid += paymentAmount;
    if ((cardPayment || voucherPayment) && this.paid > this.totalCost) {
      const tipAmount = new BigNumber(paymentAmount).minus(costAmount).toNumber();
      payment.set('amount', paymentAmount);
      const tip = new App.Models.POS.Tip({
        amount: tipAmount,
        cardType: payment.get('cardType'),
      });
      payment.set('tip', tip);
      this.saveTip(tip);
    }
  },

  hideAddPaymentButtons: function () {
    this.$el.find('.pos-payment-container .pos-payment').hide();
  },

  removePaymentForm: function () {
    this.$el.find('.pos-payment--edit').parent().remove();
    this.showAddPaymentButtons();
  },

  showAddPaymentButtons: function () {
    if (this.$el.find('.pos-payment--edit').length) {
      // there is a payment edit form open
      return;
    }

    let isCashCheckout = false;

    this.payments.each(function (payment) {
      switch (payment.get('paymentType')) {
        case App.Models.POS.PaymentDiscriminators.CARD:
          break;
        case App.Models.POS.PaymentDiscriminators.CASH:
          isCashCheckout = true;
          break;
        default:
      }
    });

    const outstanding = this.checkoutCalc.getOutstanding();

    const showButtons = outstanding > 0;

    const containsSelectedAppointments = _.any(this.appointments, (appointment) => {
      return appointment.selected;
    });

    const containsProducts = this.products.length > 0;

    this.enableCompletePaymentButton =
      outstanding <= 0 && (containsSelectedAppointments || containsProducts);

    this.$el.find('.js-pos-payment--cash').toggle(!isCashCheckout && showButtons);
    this.$el.find('.js-pos-payment--card').toggle(showButtons);
    this.$el.find('.js-pos-payment--voucher').toggle(showButtons);

    this.$('.js-complete-checkout').toggleClass(
      'dialog2--button-disabled',
      !this.enableCompletePaymentButton,
    );
    this.$('.js-complete-checkout').prop('disabled', !this.enableCompletePaymentButton);
  },

  showPaymentForm: function (ModelClass, FormClass) {
    const model = new ModelClass();
    const form = new FormClass({ model: model, controller: this }).render();
    this.$el.find('.pos-payment-container').prepend(form.$el);
    this.hideAddPaymentButtons();
    form.$el.find('input[type=text]').select();
  },

  showCashPaymentForm: function () {
    this.showPaymentForm(App.Models.POS.CashPayment, App.Views.POS.CashPaymentForm);
  },

  showCardPaymentForm: function () {
    this.showPaymentForm(App.Models.POS.CardPayment, App.Views.POS.CardPaymentForm);
  },

  showVoucherForm: function () {
    this.showPaymentForm(App.Models.POS.VoucherPayment, App.Views.POS.VoucherPaymentForm);
  },

  saveTip: function (tip) {
    this.tips.add(tip);
  },

  removeTip: function (tip) {
    this.tips.remove(tip);
  },

  savePayment: function (payment) {
    const existingCashPayment = this.payments.where({
      paymentType: App.Models.POS.PaymentDiscriminators.CASH,
    });
    const cashPayment = payment.get('paymentType') === App.Models.POS.PaymentDiscriminators.CASH;

    if (cashPayment && existingCashPayment.length > 0) {
      const newCashAmount = existingCashPayment[0].get('amount') + payment.get('amount');
      payment.set('amount', newCashAmount);
      this.payments.remove(existingCashPayment[0]);
    }
    this.payments.add(payment);
    this.renderPaymentView(payment);

    // Trigger change event so that the Dialog Confirm Changed Close handler would kick in
    this.$el.trigger('change');
  },

  removePayment: function (payment) {
    this.payments.remove(payment);
  },

  removePayments: function () {
    this.payments.reset();
    this.$('.js-added-payments').empty();
    this.togglePaymentTitle();
  },

  async doCompleteCheckout() {
    if (this.enableCompletePaymentButton) {
      this.enableCompletePaymentButton = false;
      this.loadmask();

      try {
        await this.saveCustomerIfNeeded();
      } catch (err) {
        this.onSavingFailed(!err || !err.isValidationError);
        return;
      }

      this.checkoutPOS(this.getCheckoutModel(), {
        success: ({ receiptPdfUri, tipReceiptPdfUri, receiptCopyUri }) =>
          this.doSuccess({
            receiptPdfUri,
            receiptCopyUri,
            tipReceiptPdfUri,
          }),
        error: () => this.onSavingFailed(),
      });
    }
  },

  doSuccess: function ({ receiptPdfUri, receiptCopyUri, tipReceiptPdfUri }) {
    this.checkoutComplete = new App.Views.Forms.POSTransactionComplete({
      collection: this.collection,
      controller: this,
      receiptUri: receiptPdfUri,
      receiptButtonLabel: Wahanda.lang.calendar.appointments.pos.completeSaleReceipt,
      receiptCopyUri,
      receiptCopyButtonLabel: Wahanda.lang.pos.receiptCopyDialog.cta,
      secondReceiptUri: tipReceiptPdfUri,
      secondReceiptButtonLabel: Wahanda.lang.calendar.appointments.pos.completeCashReceipt,
    });
    this.checkoutComplete.render();
    this.checkoutComplete.open();
    this.resetCloseChangesCheck();
    this.checkoutComplete = true;
    this.close();
    App.trigger(Wahanda.Event.PRODUCTS_LIST_CHANGED);
    trackEvent('checkout', 'submit', 'checkout-form');
  },
});

BackboneEx.Mixin.View.asMediaQueryAwareDialogView(App.Views.Forms.POSDialog.prototype);
