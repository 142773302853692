App.Views.Calendar.MonthPicker = Backbone.View.extend({
  events: {
    monthcalendarselect: 'onMonthSelect',
  },

  // Ignore date change. Used when broadcasting own events.
  ignoreDateChange: false,

  initialize: function () {
    var self = this;
    this.$el.monthCalendar({
      year: this.options.date.getFullYear(),
      month: this.options.date.getMonth(),
    });

    App.on('calendar:date-change', function (date) {
      if (!self.ignoreDateChange) {
        self.onOuterDateChange(date);
      }
    });
  },

  render: function () {
    // TODO: update month and year ?
  },

  // Events

  onMonthSelect: function (event, year, month) {
    this.ignoreDateChange = true;
    App.trigger('calendar:date-change', new Date(year, month, 1));
    this.ignoreDateChange = false;
  },

  onOuterDateChange: function (date) {
    this.$el.monthCalendar('option', {
      year: date.getFullYear(),
      month: date.getMonth(),
    });
  },
});
