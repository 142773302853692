import { formatSkus } from 'utilities/menu/sku-helpers';
import { isPackageSkus2d } from 'components/menu/OfferTreatmentPricingAndEmployees/utils';
import { trackEvent } from 'common/analytics';

(function () {
  const NEW_SERVICE_MIN_DURATION = 5;
  const NEW_SERVICE_DEFAULT_DURATION = 60;

  const Base = BackboneEx.View.Base;
  var PackageServices = Base.extend({
    events: {
      'click .js-add-existing-services': 'addExistingServices',
      'click .js-add-new-service': 'addNewService',
      'click .js-remove-services': 'removeServices',
      'click .js-set-master': 'setMaster',
      'keyup input': 'inputKeyup',
      'change select': 'selectChange',
    },

    templateId: 'offer2-package-services-template',
    views: null,
    menu: null,
    services: null,
    skus: null,
    externalOfferChangesShown: false,

    initialize: function (options) {
      Base.prototype.initialize.call(this, options);
      this.tempId = 1;
      this.menu = options.menu;
    },

    addExistingServices: function () {
      this.openServicesSelectionDialog();
    },

    addNewService: function () {
      const existingServices = this.services || this.model.get('subServices');
      const newService = {
        tempId: this.tempId,
        duration: NEW_SERVICE_DEFAULT_DURATION,
      };
      existingServices.push(newService);
      this.tempId++;
      this.model.set('subServices', existingServices);
      this.options.mediator.trigger(this.options.mediator.PACKAGE_SERVICES_CHANGED);
      trackEvent('services', 'click', 'add-service-from-package');
      this.render();
    },

    removeServices: function (event) {
      const self = this;
      const $currentService = $(event.currentTarget).closest('.js-offer-2-package-selected');
      const removedServiceId = $currentService.data('serviceId');
      this.views = this.views.filter((view) => {
        if (view.$el.is($currentService)) {
          view.remove();
          return false;
        }
        return true;
      });
      $currentService.remove();
      if (!removedServiceId) {
        // Remove *new* service
        const tempId = $currentService.data('tempId');
        this.services = _.reject(this.services, (service) => service.tempId === tempId);
        this.model.set('subServices', this.services);
        this.options.mediator.trigger(this.options.mediator.PACKAGE_SERVICES_CHANGED);
        this.render();

        return;
      }

      /*
       * An issue still exists when editing a saved package
       * skus don't get refreshed.
       * */
      if (self.model.get('stateData')) {
        const { skusByOffer, subSkus, offers } = self.model.get('stateData');
        const filteredSubSkus = subSkus.filter((subSku) => subSku.offerId !== removedServiceId);

        delete skusByOffer[removedServiceId];
        delete offers[removedServiceId];
        const stateData = { skusByOffer, subSkus: filteredSubSkus, offers };

        self.model.set('stateData', stateData);
        this.skus = formatSkus(stateData);
      }

      this.services = _.filter(this.services, (service) => service.serviceId != removedServiceId);

      function getSkuSignature(sku) {
        return _.map(sku.subSkus, (subSku) => subSku.id).join(',');
      }

      // Remove the skus of the removed service.
      const newSkus = [];
      const dupes = {};
      _.each(this.skus, (sku) => {
        sku.subSkus = _.filter(sku.subSkus, (subSku) => subSku.offerId !== removedServiceId);
        // Skip duplicate entries.
        // Dupes are created when Open Selection offers are removed from a Package.
        const sign = getSkuSignature(sku);
        if (!dupes[sign]) {
          newSkus.push(sku);
          dupes[sign] = true;
        }
      });
      this.skus = newSkus;

      this.model.set('subServices', this.services);
      this.model.set('skus', this.skus);

      if (this.masterServiceId === removedServiceId) {
        this.masterServiceId = null;
      }
      this.render();

      this.options.mediator.trigger(this.options.mediator.PACKAGE_SERVICES_CHANGED);
    },

    openServicesSelectionDialog: function () {
      const view = new App.Views.Forms.MenuOffer2.Pricing.PackageServices.SelectServices({
        menu: this.menu,
        model: this.model,
        mediator: this.options.mediator,
        employeeCategories: this.options.employeeCategories,
      });
      view.render();
      view.open();
    },

    inputKeyup: function (event) {
      const $input = $(event.currentTarget);
      const service = this.getService($input);
      const key = $input.attr('name').split('[')[0];
      if (key) {
        service[key] = $input.val();
        this.model.set('subServices', this.services);

        this.options.mediator.trigger(this.options.mediator.PACKAGE_SERVICES_CHANGED);
      }
    },

    selectChange: function (event) {
      const $input = $(event.currentTarget);
      const service = this.getService($input);
      const key = $input.attr('name').split('[')[0];
      service[key] = $input.val();

      this.options.mediator.trigger(this.options.mediator.PACKAGE_SERVICES_CHANGED);
    },

    getService: function ($input) {
      const tempId = $input.parents('.row').data('temp-id');
      const service = _.find(this.services, (service) => service.tempId === tempId);

      return service;
    },

    setMaster: function (event) {
      const $currentService = $(event.currentTarget).closest('.row');
      const currentServiceId = $currentService.data('serviceId');
      let tempId;
      if (!currentServiceId) {
        tempId = $currentService.data('tempId');
      }
      _.each(this.services, (service) => {
        if (service.serviceId === currentServiceId) {
          service.master = true;
        } else if (service.tempId === tempId) {
          service.master = true;
        } else {
          service.master = false;
        }
      });

      this.masterServiceId = currentServiceId || tempId;

      this.render();

      this.options.mediator.trigger(this.options.mediator.PACKAGE_MASTER_SERVICE_CHANGED, {
        offerId: currentServiceId,
        tempId,
      });
    },

    getDurationOptionHtml: function (duration) {
      const format = Wahanda.Time.getTimeDurationTemplate();
      const selected = duration ? parseInt(duration, 10) : 0;
      return Wahanda.Time.getDurationValues(selected, format, true, {
        minMinutes: NEW_SERVICE_MIN_DURATION,
      });
    },

    isMasterServiceSet: function () {
      return _.findWhere(this.services, { master: true });
    },

    render: function () {
      if (this.model.id && !this.menu) {
        // Menu isn't loaded yet. When it is, the services will be re-rendered.
        return;
      }
      this.$el.parents('form').validate();

      const self = this;
      this.skus = this.model.get('skus');
      this.services = this.getServicesFromModel();

      const parentEl = this.$el.closest('.offer2').get(0);

      if (!this.isMasterServiceSet()) {
        // Set first service as master.
        this.masterServiceId =
          this.services &&
          this.services[0] &&
          (this.services[0].serviceId || this.services[0].tempId);
      }

      this.views = _.map(
        this.services,
        function (service) {
          if (service.tempId) {
            service.durationList = self.getDurationOptionHtml(service.duration);
          }
          service.skus = self.getSkus(service.serviceId);
          service.master = this.masterServiceId
            ? this.masterServiceId === (service.serviceId || service.tempId)
            : service.master;
          const view = new PackageServices.Item({
            model: service,
            parentEl: parentEl,
            employeeCategories: this.options.employeeCategories,
          });
          view.render();
          return view;
        },
        this,
      );

      this.model.set('subServices', this.services);

      this.$el.html(
        Wahanda.Template.renderTemplate(this.templateId, {
          hideList: !this.services || this.services.length === 0,
        }),
      );
      this.$('.js-service-list').append(_.pluck(this.views, '$el'));
      this.$('.js-service-list').sortable({
        handle: '.js-drag',
      });

      this.$('.js-select-wrap-target').selectWrap();

      this.$('.js-treatment-type-container').each(function () {
        self.renderTreatmentTypeSelector($(this));
      });

      this.checkChangedServices();
    },

    hasNewServices: function () {
      return _.any(this.services, (service) => service.tempId);
    },

    renderTreatmentTypeSelector: function ($selecterContainer) {
      // render treatment selector
      const service = this.getService($selecterContainer);

      const treatmentType = service.primaryTreatmentId;
      const view = new App.Views.Forms.MenuOffer2.Pricing.TreatmentTypeSelect({
        el: $selecterContainer,
        treatments: this.options.treatments,
        model: this.model,
        suffix: `[${service.tempId}]`,
        mediator: this.options.mediator,
      });
      view.render();
      $selecterContainer.find('select').val(treatmentType);
      $selecterContainer.find('select').chosen();
      view.setChosenSize();
    },

    getServicesFromModel: function () {
      let existingServices = this.model.get('subServices');
      if (
        existingServices &&
        existingServices[0] &&
        typeof existingServices[0].multiSkuSelection === 'undefined'
      ) {
        // The services aren't prefilled with multiSkuSelection data needed for rendering.
        // Fill it in.
        _.each(
          existingServices,
          function (service) {
            const offer = this.menu.get('offersCollection').get(service.serviceId);
            if (offer) {
              existingServices.multiSkuSelection = offer.get('multiSkuSelection');
            }
          },
          this,
        );
      }

      const newServiceList = [];
      _.each(this.services, (service) => {
        if (service.tempId) {
          newServiceList.push(service);
        }
        _.each(existingServices, (existingService) => {
          if (service.serviceId === existingService.serviceId) {
            newServiceList.push(service);
            existingService.existing = true;
          }
        });
      });

      existingServices = _.reject(existingServices, (service) => service.existing);

      return _.union(newServiceList, existingServices);
    },

    getSkus: function (serviceId) {
      const skus = {};
      _.each(this.skus, (sku) => {
        _.each(sku.subSkus, (subSku) => {
          if (subSku.offerId === serviceId) {
            // Make the skus unique in the list
            skus[subSku.id] = subSku;
          }
        });
      });

      return App.Models.MenuOffer.sortSkuList(_.values(skus), true);
    },

    getValues: function () {
      const self = this;
      const hiddenServices = this.model.get('hiddenServices');
      const newSubSkus = [];
      _.each(hiddenServices, (service) => {
        newSubSkus.push(service.skus[0]);
      });
      let skus = this.model.get('skus');

      if (skus.length > 0) {
        const existingSubSkus = [];
        _.each(skus, (sku) => {
          sku.subSkus = sku.subSkus.concat(newSubSkus);
        });
      } else {
        skus = [{ subSkus: newSubSkus }];
      }

      this.model.set('skus', skus);

      const sorting = {};
      let sortIndex = 0;
      this.$('.js-offer2--package-service').each(function () {
        const $service = $(this);
        sorting[$service.data('serviceId')] = ++sortIndex;
      });
      // Sort the services as in DOM
      if (this.model.get('subServices')) {
        this.model.get('subServices').sort((a, b) => sorting[a.serviceId] - sorting[b.serviceId]);
      }
      // No need to return anything - the model has already correct values on it.
      return {};
    },

    applyServiceChanges: function () {
      this.model.updatePackageWithServiceChanges(this.menu.get('offersCollection'));

      /*
       * we filter out skus that have subskus with not maching employeeCategoryIds
       * then we check if that sku list is 2dimensional pricing. If it is we will use the filtered
       * sku list combination.
       *
       * this happens because 2d packages don't support skus with not maching employeeCategoryIds, and the
       * validation logic is in select-services on save method.
       * */

      const skusWithRepeatingEmployeeCategoryId = this.model
        .get('skus')
        .filter((sku) =>
          sku.subSkus.every(
            (subSku) => subSku.employeeCategoryId === sku.subSkus[0].employeeCategoryId,
          ),
        );

      if (isPackageSkus2d(skusWithRepeatingEmployeeCategoryId)) {
        this.model.set('skus', skusWithRepeatingEmployeeCategoryId);
      }

      // Request the refresh of the whole view
      this.options.mediator.trigger(this.options.mediator.PACKAGE_SERVICES_CHANGE_ACCEPTED);
    },

    checkChangedServices: function () {
      if (!this.externalOfferChangesShown && this.model.hasChangedSubservices()) {
        App.Views.Dialog.MenuPackageIncludedOfferChangeNotification.open({
          packages: this.model.get('changedUnderlineServices'),
          onApply: this.applyServiceChanges.bind(this),
        });
        this.externalOfferChangesShown = true;
      }
    },
  });

  App.Views.Forms.MenuOffer2.Pricing.PackageServices = PackageServices;
})();
