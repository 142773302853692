/**
 * Reference data model.
 */
App.Models.Reference = Backbone.Model.extend({
  defaults: {
    // Venue id
    venueId: null,
    // Data type list
    types: [],
    // Additional GET parameters
    params: {},
  },

  initialize: function () {},

  urlRoot: function () {
    var base = '/venue/{{venueId}}/reference-data.json?type={{types}}{{&params}}';
    var urlParams = {
      venueId: this.get('venueId'),
      types: this.get('types').join(','),
      params: null,
    };
    // Additional URL param parsing
    var params = this.get('params');
    if (
      -1 !== _.indexOf(this.get('types'), 'location-state') &&
      !params['location-state.country-code']
    ) {
      // If states are required - add US as the country.
      params['location-state.country-code'] = 'US';
    }

    if (!_.isEmpty(params)) {
      // generates &key1=val1&key2=val2...
      urlParams.params = _.reduce(
        params,
        function (url, val, key) {
          return url + '&' + key + '=' + encodeURIComponent(val);
        },
        '',
      );
    }
    // Rendering the final URL
    var wsName = Wahanda.Template.render(base, urlParams);
    return App.Api.wsUrl(wsName);
  },

  /**
   * Returns duration in minutes, by scale.
   *
   * @param string id The scale ID, for example 15M
   * @return int
   */
  getDurationByScale: function (id) {
    var types = this.get('appointmentSlotType') || [];
    for (var i = 0, len = types.length; i < len; i++) {
      if (types[i].id === id) {
        return types[i].duration;
      }
    }
    return 1;
  },

  getRoomClassByCode: function (code) {
    return _.find(this.get('roomClass'), function (roomClass) {
      return code === roomClass.id;
    });
  },
});
