(function($) {
	var disabledClass           = 'disabled';
	var temporaryClass          = 'temporary-disabled';
	var disabledParentClass     = 'disabled-parent';
	var parentOfDisabledElement = '.form-element-wrapper';
	/**
	 * Form element disabling Wahanda style.
	 *  
 	 * @param boolean shouldDisable Should elements be disabled or enabled?
	 */
	$.fn.toggleForm = function(shouldDisable) {
		if (shouldDisable) {
			var elements = findFormElements(this);
			disableElements(elements);
		} else {
			var elements = this.find('.' + temporaryClass);
			reenableElements(elements);
		}
		
		return this;
	};
	
	function findFormElements(form) {
		return form.find(':input, button, .custom-form-element');
	};
	function disableElements(elements) {
		// Filter only those which are now not disabled now.
		var toDisable = elements.not("." + disabledClass).not('[disabled]');
		toDisable.disableFormElements().addClass(temporaryClass);
		
		toDisable.each(function() {
			var formItem   = $(this);
			var parentNode = findParentWrapper(formItem);
			if (parentNode.length) {
				parentNode.addClass(disabledParentClass);
			}
		});
	}
	function reenableElements(elements) {
		elements.enableFormElements();
		
		elements.each(function() {
			var formItem   = $(this);
			var parentNode = findParentWrapper(formItem);
			if (parentNode.length) {
				parentNode.removeClass(disabledParentClass);
			}
		});
	}
	function findParentWrapper(formElement) {
		return formElement.closest(parentOfDisabledElement);
	}
	
	$.toggleForm = {
		findFormElements  : findFormElements,
		disableElements   : disableElements,
		reenableElements  : reenableElements,
		findParentWrapper : findParentWrapper
	};
	
	/**
	 * Form elements disabling.
	 * 
	 * Works on selected elements.
	 */
	$.fn.disableFormElements = function() {
		return this
			.addClass(disabledClass)
			.removeClass(temporaryClass)
			.attr('disabled', 'disabled')
			.prop('disabled', true)
			.trigger('chosen:updated');
	};
	
	/**
	 * Enables disabled form elements.
	 */
	$.fn.enableFormElements = function() {
		return this
			.removeClass(disabledClass + ' ' + temporaryClass)
			.removeAttr('disabled').prop('disabled', false)
			.trigger('chosen:updated');
	};
	
	/**
	 * Toggles (disables or enables) form elements.
     * @param boolean enable Enable orr disable (false) the elements?
     * 
     * @return jQuery
	 */
	$.fn.toggleFormElements = function(enable) {
		return this[enable ? 'enableFormElements' : 'disableFormElements']();
	};
}(jQuery));
