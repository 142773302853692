import React from 'react';
import { PaymentOptions, Payments } from './PaymentOptions';
import { StripeCheckout } from './StripeCheckout';
import { BankPayment } from './BankPayment';

enum PaymentViews {
  PaymentOptions = 'PaymentOptions',
  CardPayment = 'CardPayment',
  BankPayment = 'BankPayment',
}
interface Props {
  idealSupported: boolean;
  doIdealPaymentRequest: (props: { paymentAmount: number }) => void;
  idealPaymentInProgress: boolean;
  onClose: () => void;
  reFetchRemittances: () => void;
  closingBalance: number;
  paymentReference: string;
}
interface State {
  activeView: PaymentViews;
}

export class PaymentContainer extends React.PureComponent<Props, State> {
  public state = {
    activeView: PaymentViews.PaymentOptions,
  };

  public paymentSelected = (paymentOption: Payments) => {
    if (paymentOption === Payments.ideal) {
      this.props.doIdealPaymentRequest({
        paymentAmount: this.props.closingBalance,
      });
      return;
    }
    const views = {
      [Payments.bank]: PaymentViews.BankPayment,
      [Payments.stripe]: PaymentViews.CardPayment,
    };
    this.setState({ activeView: views[paymentOption] });
  };

  public showBankPaymentOptions = () => {
    this.paymentSelected(Payments.bank);
  };

  public render() {
    const {
      onClose,
      closingBalance,
      reFetchRemittances,
      paymentReference,
      idealPaymentInProgress,
      idealSupported,
    } = this.props;
    const { activeView } = this.state;
    switch (activeView) {
      case PaymentViews.PaymentOptions:
        return (
          <PaymentOptions
            idealSupported={idealSupported}
            paymentInProgress={idealPaymentInProgress}
            onClose={onClose}
            onPaymentSelect={this.paymentSelected}
          />
        );
      case PaymentViews.CardPayment:
        return (
          <StripeCheckout
            linkClickHandler={this.showBankPaymentOptions}
            reFetchRemittances={reFetchRemittances}
            paymentAmount={closingBalance}
            onClose={onClose}
          />
        );
      case PaymentViews.BankPayment:
        return (
          <BankPayment
            paymentReference={paymentReference}
            closingBalance={closingBalance}
            onClose={onClose}
          />
        );
    }
  }
}
