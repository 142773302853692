(function () {
  /**
   * Wahanda Media Query detection and event triggering.
   *
   * It's main concept is based on
   *   http://davidwalsh.name/device-state-detection-css-media-queries-javascript
   */
  var MQ = {
    $div: null,
    _state: 1,
    states: {
      // Default state
      1: 'desktop',
      // Ipad-Landscape mode
      2: 'wide',
    },

    initialize: function () {
      if (MQ.$div) {
        // Already initialized.
        return;
      }

      MQ.$div = $('<div id="wahanda-media-query-tester"></div>').appendTo('body');
      $(window).on('resize', MQ._checkState);

      MQ._checkState();
    },

    /**
     * Return the name of current MQ in use.
     *
     * @return String
     */
    getState: function () {
      return MQ.states[MQ._state];
    },

    _checkState: function () {
      var zIndex = parseInt(MQ.$div.css('z-index'), 10);
      if (!zIndex) {
        // Skip invalid values
        return;
      }
      if (MQ._state !== zIndex && MQ.states[zIndex]) {
        MQ._state = zIndex;
        MQ._notifyStateChange();
      }
    },

    _notifyStateChange: function () {
      App.trigger(Wahanda.Event.MEDIA_QUERY_CHANGE, MQ.getState());
    },
  };

  Wahanda.MediaQuery = MQ;

  $(window).ready(MQ.initialize);
})();
