import { trackEvent } from 'common/analytics';

App.Views.Forms.Calendar.RoomDayDetails = BackboneEx.View.Dialog.ValidatingForm.DatedDay.extend({
  events: {
    'click .button-cancel': 'cancel',
    'change input[name=dated-day-closed]': 'toggleSingleInventoryInput',
    'focus #dated-day-inventoryAvailable': 'setDayOpen',
    'click .a-close-out': 'closeOutDay',
    'click .tab-history-body tr': 'openBookingDialog',
  },
  options: {
    title: Wahanda.lang.calendar.spaDays.spaDayDetailsForm.title,
    dialogClass: 'dated-day-form',
    width: 800,
    dataTest: 'room-day-details-modal',
  },

  closeOutInProgress: false,
  saveQuestionTitle: Wahanda.lang.calendar.spaBreaks.form.saveQuestion.title,
  saveQuestionText: Wahanda.lang.calendar.spaBreaks.form.saveQuestion.text,
  serviceType: 'O',

  postInitialize: function () {
    trackEvent('calandar', 'view', 'room-details-modal');

    this.model = new App.Models.RoomAllocation({
      date: this.options.date,
    });
    this.model.dateFrom = this.model.dateTo = this.options.date;

    if (!this._isSingleMode()) {
      this.toggledList = new App.Views.Partials.ToggleableList();
    }
  },

  offerEditingDisabled: function () {
    return this._isSingleMode() && this.collection.length > 1;
  },

  focusFormElement: function () {
    if (this._isSingleMode()) {
      var roomType = this.model.getRoomType(this.options.roomTypeId);
      if (!roomType.closed || !this.model.isSet()) {
        this.$('#dated-day-inventoryAvailable').focus();
      }
    } else {
      this.$('li.on:first').find('.room-price input').focus();
    }
  },

  fillFormFromModel: function () {
    if (this._isSingleMode()) {
      this.model.fillMissingValues(this.collection);
      this._fillSingleMode(this.model.getRoomType(this.options.roomTypeId));
    } else {
      this._fillMultipleMode();
    }
  },

  _fillSingleMode: function (roomType) {
    var inventory = '';
    var room;
    if (roomType.closed && this.model.isSet()) {
      this.$('#dated-day-closed-yes').prop('checked', true);
      this.toggleSingleInventoryInput();
    } else {
      if (this.model.isSet()) {
        inventory = roomType.inventoryAvailable || 0;
      }
      this.$('#dated-day-closed-no').prop('checked', true);
      this.$('#dated-day-inventoryAvailable').val(inventory);
    }
    this.$('.b-single').wShow();

    room = this.collection.get(roomType.roomTypeId);
    if (room) {
      this.renderAttribute('single-room', room.get('name'));
    }
  },

  _fillMultipleMode: function (roomTypes) {
    var model = this.model;
    var isSet = this.model.isSet();
    var list = [];
    this.collection.each(function (roomType) {
      var instance = model.getRoomType(roomType.id) || {
        closed: true,
        inventoryAvailable: '',
      };
      list.push({
        id: roomType.id,
        roomName: roomType.get('name'),
        open: !isSet || !instance.closed,
        inventoryAvailable: instance.inventoryAvailable,
      });
    });

    this.toggledList.setElement(this.$('.b-room-types'));
    this.toggledList.render('dated-day-details-form-template-room', {
      list: list,
    });

    this.$('.b-single').wHide();
    this.$('.b-room-types').wShow();
  },

  _isSingleMode: function () {
    return this.options.roomTypeId > 0;
  },

  getModelValues: function () {
    var formVals = BackboneEx.View.Dialog.ValidatingForm.prototype.getModelValues.call(this);
    var values = {
      dates: [this.options.date],
      roomTypes: [],
      closedOffers: [],
    };

    if (this._isSingleMode()) {
      // Single filtered room type
      var isClosed = this.closeOutInProgress || formVals.closed === 'true';
      values.roomTypes.push({
        roomTypeId: this.options.roomTypeId,
        inventoryAvailable: isClosed ? null : parseInt(formVals.inventoryAvailable, 10),
        closed: isClosed,
      });
    } else {
      var self = this;
      this.collection.each(function (roomType) {
        var id = roomType.id;
        var isClosed = self.closeOutInProgress || !self.$('#dated-day-open-' + id).prop('checked');
        var data = {
          roomTypeId: id,
          closed: isClosed,
          inventoryAvailable: isClosed
            ? null
            : parseInt(self.$('#dated-day-inventoryAvailable-' + id).val(), 10),
        };
        values.roomTypes.push(data);
      });
    }

    values.closedOffers = this.getClosedOffers();

    return values;
  },

  closeOutDay: function () {
    if (this.$('form:first').valid()) {
      this.closeOutInProgress = true;
      this.save();
      this.closeOutInProgress = false;
    }
  },
});
