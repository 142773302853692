import { checkDimensions, resizeAndCompressImage } from 'common/image-resize';
import {
  VenuePhotoCroppingUpdateRequestAction,
  VenuePhotosUploadRequestAction,
  venuePhotoCroppingUpdateFailure,
  venuePhotoCroppingUpdateSuccess,
  venuePhotoUploadFailure,
  venuePhotoUploadRequest,
  venuePhotoUploadSuccess,
} from 'components/settings/VenueDetails/actions';
import { all, call, put } from 'redux-saga/effects';
import { IMAGE_UPLOAD_MAX_DIMENSIONS } from 'common/consts';

type PhotoRequest = {
  venueId: Pick<VenuePhotosUploadRequestAction['payload'], 'venueId'>['venueId'];
  cropping?: Pick<VenuePhotoCroppingUpdateRequestAction['payload'], 'cropping'>['cropping'];
  localId?: Pick<VenuePhotoCroppingUpdateRequestAction['payload'], 'localId'>['localId'];
  file?: Pick<VenuePhotosUploadRequestAction['payload'], 'files'>['files'][number];
  sourceImageId?: Pick<
    VenuePhotoCroppingUpdateRequestAction['payload'],
    'sourceImageId'
  >['sourceImageId'];
};

const uploadPhoto = async function ({ venueId, cropping, file, sourceImageId }: PhotoRequest) {
  const url = App.Api.wsUrl(`/venue/${venueId}/image.json`);

  const formData = new FormData();
  if (file) {
    formData.append('file', file);
    formData.append('filename', file.name);
  }
  if (sourceImageId) {
    formData.append('sourceImageId', sourceImageId.toString());
  }
  if (cropping) {
    const { x1, y1, x2, y2 } = cropping;
    const croppingWithoutDecimals = {
      x1: x1.toFixed(0),
      y1: y1.toFixed(0),
      x2: x2.toFixed(0),
      y2: y2.toFixed(0),
    };
    // old API expects these as separate fields
    Object.entries(croppingWithoutDecimals).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // new API expects these as a single JSON string but it's still not implemented
    formData.append('cropping', JSON.stringify(croppingWithoutDecimals));
  }

  const response = await fetch(url, { method: 'POST', body: formData });

  if (!response.ok) {
    throw new Error('Failed to upload photo');
  }

  return response.json();
};

export function* uploadVenuePhotoRequested({
  venueId,
  file,
  localId = window.crypto.randomUUID(),
}: PhotoRequest) {
  const name = file!.name;

  let fileToUpload = file!;

  const { minWidth, minHeight, maxWidth, maxHeight } = IMAGE_UPLOAD_MAX_DIMENSIONS;

  const isBigEnough = yield call(checkDimensions, { file: fileToUpload, minWidth, minHeight });
  if (!isBigEnough) {
    return yield put(
      venuePhotoUploadFailure({
        photo: { localId, name, src: getSrc(fileToUpload) },
        error: Wahanda.lang.shared.errors.fileUpload.dimensionsTooSmall,
      }),
    );
  }

  const isSmallEnough = yield call(checkDimensions, { file: fileToUpload, maxWidth, maxHeight });
  if (!isSmallEnough) {
    fileToUpload = yield call(resizeAndCompressImage, { file: fileToUpload, maxWidth, maxHeight });
  }

  try {
    const photo = yield call(uploadPhoto, { venueId, file: fileToUpload });

    yield put(venuePhotoUploadSuccess({ photo: { ...photo, localId } }));
  } catch (err) {
    yield put(
      venuePhotoUploadFailure({
        photo: { localId, name, src: getSrc(fileToUpload) },
        error: `${name} ${Wahanda.lang.shared.errors.fileUpload.unexpected}`,
      }),
    );
  }
}

export function* uploadVenuePhotosRequested({
  payload: { venueId, files },
}: VenuePhotosUploadRequestAction) {
  try {
    const photos = files.map((file) => ({ file, localId: window.crypto.randomUUID() }));

    // We want to give the user immediate feedback about the photos being uploaded
    yield all(
      photos.map(({ file, localId }) => {
        const photo = { localId, name: file.name, src: getSrc(file), visible: true };
        return put(venuePhotoUploadRequest({ photo }));
      }),
    );

    // We need to upload the photos sequentially due to API limitations
    for (const { file, localId } of photos) {
      yield call(uploadVenuePhotoRequested, { venueId, file, localId });
    }
  } catch (err) {
    console.error(err);
  }
}

const getSrc = (file: File) => window.URL.createObjectURL(file);

export function* updateVenuePhotoCroppingRequested({
  payload: { localId, venueId, sourceImageId, cropping },
}: VenuePhotoCroppingUpdateRequestAction) {
  try {
    const photoResponse = yield call(uploadPhoto, { venueId, sourceImageId, cropping });

    yield put(venuePhotoCroppingUpdateSuccess({ photo: { ...photoResponse, localId } }));
  } catch (err) {
    const error = Wahanda.lang.shared.errors.fileUpload.unexpected;
    Wahanda.ToastNotifications.sendMessage(error, { variant: 'danger' });
    yield put(venuePhotoCroppingUpdateFailure({ photo: { localId }, error }));
  }
}
