import React from 'react';
import { TableRow, TableData } from 'components/common/Table';
import InStockIndicator from '../../InStockIndicator';
import AmountSetter from '../AmountSetter/container';
import style from './style.scss';

interface IProductRowProps extends React.HTMLAttributes<Element> {
  name: string;
  elementId: number;
  onChange: (...args: any[]) => any;
  amount?: number;
  inStockAmount?: number;
  useInStockIndicator?: boolean;
  isActive?: boolean;
}
class ProductRow extends React.Component<IProductRowProps, {}> {
  static defaultProps = {
    amount: null,
    inStockAmount: null,
    useInStockIndicator: false,
    isActive: false,
  };

  getStyle = () => (this.props.isActive ? style.active : null);

  renderInStockIndicator = () => (
    <TableData className={style.centered}>
      <InStockIndicator amount={this.props.inStockAmount} />
    </TableData>
  );

  render() {
    const { name, elementId, amount, onChange, useInStockIndicator } = this.props;
    return (
      <TableRow className={this.getStyle()}>
        <TableData className={style.centeredVertically}>{name}</TableData>
        {useInStockIndicator && this.renderInStockIndicator()}
        <TableData className={style.right}>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Property 'elementId' does not exist on type 'Intri... Remove this comment to see the full error message */}
          <AmountSetter elementId={elementId} amount={amount || null} callback={onChange} />
        </TableData>
      </TableRow>
    );
  }
}

export default ProductRow;
