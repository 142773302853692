import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { offerDialogSetProcessingTime, trackServiceEditAction } from 'components/menu/actions';
import SkuList from '.';

const mapStateToProps = ({ menu: { menuOffer } }) => ({
  processingTimeMins: menuOffer.offerDialogProcessingTimeMins || null,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      offerDialogSetProcessingTime,
      trackServiceEditAction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(SkuList);
