/* global Wahanda App */
Wahanda.canShowCustomerContactsForItem = function canShowCustomerContactsForItem(
  startDate,
  isConfirmed,
  optionalConfig,
  isFirstTimeCustomer = false,
) {
  if (!Wahanda.Permissions.viewClientContactDetails()) {
    return false;
  }
  const config = optionalConfig || App.config.getAppointmentCustomerContactsConfig();

  let moment;
  let now;
  let allowedFrom;

  switch (config.showWhen) {
    case 'AFTER_CONFIRMATION':
      return isConfirmed;
    case 'AFTER_START_TIME':
      moment = App.ES6.moment;
      now = moment.venueTimeZoneDate();
      // `minutes` might be negative, so time will be subtracted.
      allowedFrom = moment(startDate).add(config.minutes, 'minutes');

      // Always disclose for repeat customers
      if (!isFirstTimeCustomer) {
        return true;
      }
      return now.isAfter(allowedFrom);
    case 'ALWAYS':
    default:
      return true;
  }
};
