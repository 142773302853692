import React from 'react';
import classnames from 'classnames';
import { InputContext } from '../InputContext';
import styleNewLook from './InputLabel.newLook.scss';
import styleLegacy from './InputLabel.scss';

interface Props {
  label: string;
  required?: boolean;
}

export const InputLabel = ({ label, required, ...otherProps }: Props) => {
  const { fullBorder, newLook } = React.useContext(InputContext);
  const style = newLook ? styleNewLook : styleLegacy;

  const classes = classnames(style.container, {
    [style.fullBorder]: fullBorder,
  });

  const renderInput = () => (
    <label {...otherProps} className={classes}>
      {label}
      {required && <span className={style.required}>*</span>}
    </label>
  );

  if (newLook) {
    return <div className={style.root}>{renderInput()}</div>;
  }

  return renderInput();
};
