import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useImmer } from 'use-immer';
import classnames from 'classnames';
import style from './ModalBody.scss';

interface ModalBodyProps {
  children: React.ReactNode;
  noPadding?: boolean;
}

export const ModalBody = ({ children, noPadding = false }: ModalBodyProps) => {
  const [showShadow, setShowShadow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerRect, setContainerRect] = useImmer({
    top: 0,
    bottom: 0,
    height: 0,
  });
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const [scrollbarRect, setScrollbarRect] = useImmer({
    top: 0,
    bottom: 0,
    height: 0,
  });
  const contentHasScrollbars = containerRect.height < scrollbarRect.height;

  const spacingClass = classnames(style.spacing, {
    [style.noPadding]: noPadding,
    [style.hasScroll]: contentHasScrollbars,
  });

  const set = useCallback(() => {
    const parseNumber = (number) => {
      return number ? parseInt(number.toFixed(2), 10) : 0;
    };

    setScrollbarRect((draft) => {
      const rect = scrollbarRef?.current?.getBoundingClientRect();
      draft.top = parseNumber(rect?.top);
      draft.bottom = parseNumber(rect?.bottom);
      draft.height = parseNumber(rect?.height);
    });

    setContainerRect((draft) => {
      const rect = containerRef?.current?.getBoundingClientRect();
      draft.top = parseNumber(rect?.top);
      draft.bottom = parseNumber(rect?.bottom);
      draft.height = parseNumber(rect?.height);
    });
  }, [setContainerRect, setScrollbarRect]);

  useEffect(() => {
    set();
    window.addEventListener('resize', set);
    document.addEventListener('scroll', set, true);
    return () => {
      window.removeEventListener('resize', set);
      document.removeEventListener('scroll', set, true);
    };
  }, [set]);

  useEffect(() => {
    setShowShadow(contentHasScrollbars);
  }, [containerRect, scrollbarRect]);

  return (
    <div className={style.shadowWrapper} ref={containerRef}>
      <div className={style.container}>
        <div ref={scrollbarRef}>
          <div className={spacingClass}>{children}</div>
        </div>
      </div>
      {showShadow && (
        <>
          <div className={style.shadowTop} />
          <div className={style.shadowBottom} />
        </>
      )}
    </div>
  );
};
