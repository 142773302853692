(function () {
  function getLocaleFromPath() {
    var path = window.location.pathname;
    var matcher = /^\/([a-z]{2}(?:_[A-Z]{2})?)\//;
    var match = path.match(matcher);

    return match ? match[1] : null;
  }

  function maybeGetSearch(module) {
    var maybeGetExists = /\?/.test(module);
    var search = window.location.search.substring(1);

    return search ? (maybeGetExists ? '&' : '?') + search : '';
  }

  Wahanda.Url = {
    /**
     * Returns URL with venueId in it.
     * This function keeps all GET parameters which are set on the current request.
     *
     * @param String module
     * @param String path
     */
    getFullUrl: function (module, path) {
      var locale = getLocaleFromPath();
      var search = maybeGetSearch(module);

      var url =
        '/' +
        (locale ? locale + '/' : '') +
        (module ? module : '') +
        search +
        '#venue/' +
        App.config.get('venue').id;
      if (path) {
        url += path.startsWith('/') ? path : '/' + path;
      }
      return url;
    },

    /**
     * Parses the search parameter of the URI.
     * Taken from https://github.com/medialize/URI.js/
     *
     * @param String string
     */
    parseSearch: (function () {
      var decodeQuery = function (string) {
        return decodeURIComponent((string + '').replace(/\+/g, '%20'));
      };
      return function (string) {
        if (!string) {
          return {};
        }

        // throw out the funky business - "?"[name"="value"&"]+
        string = string.replace(/&+/g, '&').replace(/^\?*&*|&+$/g, '');

        if (!string) {
          return {};
        }

        var items = {};
        var splits = string.split('&');
        var length = splits.length;
        var v, name, value;

        for (var i = 0; i < length; i++) {
          v = splits[i].split('=');
          name = decodeQuery(v.shift());
          // no "=" is null according to http://dvcs.w3.org/hg/url/raw-file/tip/Overview.html#collect-url-parameters
          value = v.length ? decodeQuery(v.join('=')) : null;

          if (items[name]) {
            if (typeof items[name] === 'string') {
              items[name] = [items[name]];
            }

            items[name].push(value);
          } else {
            items[name] = value;
          }
        }

        return items;
      };
    })(),
  };
})();
