import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { IdentityVerificationModal } from 'components/calendar/IdentityVerificationModal/index';

interface Params {
  isKycNewSupplier: boolean;
  node: HTMLElement;
}

export const IdentityVerificationModalInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(params.node),
  render: () => {
    ReactDOM.render(
      <IdentityVerificationModal isKycNewSupplier={params.isKycNewSupplier} />,
      params.node,
    );
  },
});
