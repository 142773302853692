import React from 'react';
import { Button, ButtonSpacer } from 'components/common/Button';
import { Modal, ModalBody, ModalContainer, ModalHeader } from 'components/common/Modal';
import { useFormTemplatesEnableActions } from '../FormTemplates/useFormTemplatesEnableActions';
import style from './ClientFormsCreate.scss';
import Wahanda from 'common/wahanda';

const lang = Wahanda.lang.marketing.clientForms;

export const ClientFormsDisableConfirmationModal = ({ onExit, onContinue, formId }) => {
  const { disableSettingMutation, isLoading } = useFormTemplatesEnableActions({
    templateId: formId,
  });

  const handleDisableAndEdit = async () => {
    await disableSettingMutation();
    onContinue();
  };

  return (
    <Modal>
      <ModalContainer onClose={onExit}>
        <ModalHeader title={lang.disableConfirmationModalTitle} onClose={onExit} />
        <ModalBody>
          <p className={style.confirmationModal}>{lang.disableConfirmationModalDescription}</p>
          <Button
            disabled={isLoading}
            label={lang.disableConfirmationModalConfirm}
            fullWidth
            size="large"
            onClick={handleDisableAndEdit}
          />
          <ButtonSpacer />
          <Button
            disabled={isLoading}
            label={Wahanda.lang.shared.cancel}
            colour="plain"
            variant="secondary"
            size="large"
            fullWidth
            onClick={onExit}
          />
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
};
