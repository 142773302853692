import { createSelector } from 'reselect';
import findIndex from 'lodash/findIndex';

const getSelectedGroupList = ({ selectedGroups }) => selectedGroups;
const getSelectedGroupId = (_, props) => props.menuGroupId;
const getSelectedGroupServiceId = (_, props) => props.serviceId;

const getGroupList = ({ groupTypesList }) => groupTypesList;
const getGroupId = (_, { menuGroupId }) => menuGroupId;
const getGroupServiceId = (_, __, { serviceId }) => serviceId;

export const getEmployeesCollectionIds = ({ employeesCollection }) =>
  employeesCollection.map((employee) => employee.id);

/*
 * Selected group selectors
 */
export const getSelectedGroupIndex = createSelector(
  [getSelectedGroupList, getSelectedGroupId],
  (groups, id) => findIndex(groups, ['menuGroupId', id]),
);

export const getSelectedGroupService = createSelector(
  [getSelectedGroupList, getSelectedGroupIndex, getSelectedGroupServiceId],
  (groups, index, id) => groups[index].services.find((service) => service.serviceId === id),
);

export const getSelectedServiceIndex = createSelector(
  [getSelectedGroupList, getSelectedGroupIndex, getSelectedGroupServiceId],
  (groups, index, id) => findIndex(groups[index].services, ['serviceId', id]),
);

export const getSelectedIndexes = createSelector(
  [getSelectedGroupList, getSelectedGroupIndex, getSelectedGroupServiceId],
  (groups, index, id) => {
    return {
      selectedGroupIndex: index,
      selectedServiceIndex: findIndex(groups[index].services, ['serviceId', id]),
    };
  },
);

/*
 * Group selectors (loaded from API)
 */
const getGroup = createSelector([getGroupList, getGroupId], (groupList, id) =>
  groupList.find((group) => group.menuGroupId === id),
);

export const getGroupIndex = createSelector([getGroupList, getGroupId], (groupList, id) =>
  findIndex(groupList, ['menuGroupId', id]),
);

export const getGroupName = createSelector([getGroup, getGroupId], ({ name }) => name);

const getGroupServices = createSelector([getGroup, getGroupId], ({ services }) => services);

export const getGroupService = createSelector(
  [getGroupServices, getGroupServiceId],
  (services, serviceId) => services.find((service) => service.serviceId === serviceId),
);

export const getGroupServiceEmployees = createSelector(
  [getGroupServices, getGroupServiceId],
  (services, serviceId) => services.find((service) => service.serviceId === serviceId),
);

export const getGroupServiceIndex = createSelector(
  [getGroupServices, getGroupServiceId],
  (services, serviceId) => findIndex(services, ['serviceId', serviceId]),
);

export const hasFreeSku = createSelector([getSelectedGroupList], (selectedGroups) =>
  selectedGroups.some(({ services }) =>
    services.some(
      ({ skus, isPatchTestService }) =>
        !isPatchTestService && skus.some((sku) => !sku.fullPriceAmount),
    ),
  ),
);
