/* global jQuery */
/* eslint: no-trailing-spaces:0 camelcase:0 */

/**
 * Sets the size of chosen dropdown, etc. Must be run on the Chosen element.
 *
 * @param {object} data { width, maxDropdownHeight }
 *
 * @returns {void}
 */
jQuery.fn.setChosenSize = function (data) {
  var $cResults = this.find('.chosen-results');

  $cResults.css('max-height', data.maxDropdownHeight);

  // Set the correct widths of the input and it's container
  // This works around the "smart" layouting of the plugin.
  if (data.width) {
    var chosenInstance = this.find('.chosen-select').data('chosen');
    var $chosenDiv = this.find('.chosen-container').css('width', '');

    chosenInstance.f_width = data.width;
    $chosenDiv.width(data.width);
    this.find('.chosen-drop').width(data.width);
    this.find('.chosen-search').find('input').css('width', '');
  }
};
