import React from 'react';
import ReactDOM from 'react-dom';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import POSPreviousDayNotClosedModal from 'components/calendar/SidebarActions/POSPreviousDayNotClosedModal';

const container = Wahanda.Dialogs.getReactContainer();
const destroy = () => ReactDOM.unmountComponentAtNode(container);

function posCheckoutCheck(status, afterDayClosedFn) {
  const posStatus = new App.Models.PosStatus(status);
  if (posStatus.isTodayClosed()) {
    const langBase = Wahanda.lang.posRestrictions.dayClosedNoCheckout;
    App.ES6.Initializers.ConfirmationDialog({
      title: langBase.title,
      text: langBase.text,
    }).render();
    return false;
  }
  if (posStatus.isPreviousDayOpen()) {
    ReactDOM.render(
      <POSPreviousDayNotClosedModal onClose={destroy} afterDayClosedFn={afterDayClosedFn} />,
      container,
    );
    return false;
  }
  return true;
}

export default posCheckoutCheck;
