import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Wahanda from 'common/wahanda';
import { Initializer } from 'common/types/initializers';
import { UnsupportedBrowser } from './index';

const container = Wahanda.Dialogs.getReactStaticContainer();

export const UnsupportedBrowserInitializer = (): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(container),
  render: () => {
    ReactDOM.render(<UnsupportedBrowser />, container);
  },
});
