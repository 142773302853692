import { call, put, select } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import {
  setSupplierDataAction,
  requestProductSuppliersAction,
  receiveProductSuppliersAction,
  receiveProductSupplierAction,
  receiveSupplierSubmitSuccessAction,
  submitProductsAssignationAction,
  receiveProductsAssignationSuccessAction,
  requestProductSuppliersFailureAction,
  receiveSupplierDeleteSuccessAction,
} from './actions';
import getSupplier from './selectors';

function toEntity(response) {
  return {
    id: response.id,
    name: response.name,
    contactPersonName: response.contactPersonName,
    phone: response.phoneNumber,
    email: response.email,
    selectedProductsIds: response.venueProductIds ? response.venueProductIds : [],
  };
}

function apiGetSuppliersList(url) {
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  return api.get(url).then((data) => data.productSuppliers.map(toEntity));
}

function apiGetSupplier(url) {
  return api.get(url).then(toEntity);
}

export function* getProductSuppliersRequested() {
  try {
    const data = yield call(apiGetSuppliersList, apiUrl('PRODUCT_SUPPLIERS'));

    yield put(receiveProductSuppliersAction(data));
  } catch (err) {
    yield put(requestProductSuppliersFailureAction(err));
  }
}

export function* getProductSupplierRequested(action) {
  try {
    const data = yield call(apiGetSupplier, `${apiUrl('PRODUCT_SUPPLIER')}/${action.id}`);

    yield put(receiveProductSupplierAction(data));
  } catch (err) {
    yield put(requestProductSuppliersFailureAction(err));
  }
}

export function* getProductSupplierSubmitted() {
  try {
    const supplier = yield select(getSupplier);
    const isNew = supplier.id == null;
    const payload = {
      name: supplier.name,
      contactPersonName: supplier.contactPersonName,
      phoneNumber: supplier.phone,
      email: supplier.email,
    };

    const response = yield call(
      isNew ? api.post : api.put,
      isNew ? apiUrl('PRODUCT_SUPPLIER') : `${apiUrl('PRODUCT_SUPPLIER')}/${supplier.id}`,
      payload,
    );

    yield put(receiveSupplierSubmitSuccessAction());
    if (isNew) {
      yield put(setSupplierDataAction({ id: response.id }));
    }
    yield put(submitProductsAssignationAction());
  } catch (err) {
    yield put(requestProductSuppliersFailureAction(err));
  }
}

export function* getProductsAssignationSubmitted() {
  try {
    const supplier = yield select(getSupplier);
    const { id, selectedProductsIds } = supplier;

    yield call(api.post, apiUrl('PRODUCT_SUPPLIER_ASSIGN_PRODUCTS', { id }), {
      venueProductIds: selectedProductsIds,
    });

    yield put(receiveProductsAssignationSuccessAction());
    yield put(requestProductSuppliersAction());
  } catch (err) {
    yield put(requestProductSuppliersFailureAction(err));
  }
}

export function* getProductSupplierDeleted(action) {
  try {
    yield call(api.del, `${apiUrl('PRODUCT_SUPPLIER')}/${action.id}`);

    yield put(receiveSupplierDeleteSuccessAction());
    yield put(requestProductSuppliersAction());
  } catch (err) {
    yield put(requestProductSuppliersFailureAction(err));
  }
}
