import React from 'react';
import { Checkbox } from 'components/common/Checkbox';
import { findEmployeeCategoryName } from 'components/menu/utils';
import Wahanda from 'common/wahanda';

import { OfferEmployee, OfferEmployeeCategory } from '../types';
import { getEmployeeState, TYPE_NO_EMPLOYEE_CATEGORY, TYPE_LAST_OF_CATEGORY } from './utils';

import Item from './Item';
import style from './style.scss';

const LANG = Wahanda.lang.menu.offer.pricing.employees;

function getDisabledTooltipString(type, categoryName) {
  switch (type) {
    case TYPE_LAST_OF_CATEGORY:
      return Wahanda.Template.render(LANG.tooltipLastOfCategory, {
        category: categoryName,
      });
    case TYPE_NO_EMPLOYEE_CATEGORY:
      return LANG.tooltipNoCategory;
    default:
      return null;
  }
}

interface Props extends React.HTMLAttributes<Element> {
  actions: {
    offerDialogChangeEmployeeAction: (...args: any[]) => any;
    offerDialogSelectAllEmployeesAction: (...args: any[]) => any;
    offerDialogDeselectAllEmployeesAction: (...args: any[]) => any;
  };
  allEmployees: OfferEmployee[];
  employeeCategoriesCollection: OfferEmployeeCategory[];
  offerEmployees?: OfferEmployee[];
  initialOfferEmployees?: OfferEmployee[];
  disabled: boolean;
  useEmployeeCategoryPricing: boolean;
  employeeCategoryPricingChangeable: boolean;
  escapeWithSkuPricing?: boolean;
  length?: any;
  offerDialogChangeEmployeeAction?: any;
}

export default class OfferEmployees extends React.Component<Props, {}> {
  private getItems() {
    const {
      allEmployees,
      employeeCategoriesCollection,
      offerEmployees,
      initialOfferEmployees,
      useEmployeeCategoryPricing,
      employeeCategoryPricingChangeable,
      disabled,
    } = this.props;
    return allEmployees.map((employee) => {
      const employeeCategoryName = employee.employeeCategoryId
        ? findEmployeeCategoryName(employeeCategoriesCollection, employee.employeeCategoryId)
        : null;
      const employeeState = getEmployeeState(employee, {
        offerEmployees,
        initialOfferEmployees,
        useEmployeeCategoryPricing,
        employeeCategoryPricingChangeable,
      });
      const checked = employeeState.isChecked;
      const employeeDisabled = employeeState.disabled;
      const onClick =
        !employeeDisabled && !disabled ? () => this.handleEmployeeClick(employee, !checked) : null;
      const disabledTooltipMessage = employeeDisabled
        ? getDisabledTooltipString(
            employeeState.type,
            employee.employeeCategoryId
              ? findEmployeeCategoryName(employeeCategoriesCollection, employee.employeeCategoryId)
              : null,
          )
        : null;
      return (
        <Item
          key={employee.id}
          employee={employee}
          employeeCategoryName={employeeCategoryName}
          checked={checked}
          disabled={employeeDisabled}
          disabledTooltip={disabledTooltipMessage}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '((...args: ... Remove this comment to see the full error message
          onClick={onClick}
        />
      );
    });
  }

  public getValues() {
    const { offerEmployees } = this.props;

    return {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      employees: offerEmployees.map((employee) => ({ employeeId: employee.id })),
    };
  }

  private handleEmployeeClick = (employee, toCheck) => {
    this.props.actions.offerDialogChangeEmployeeAction(employee.id, toCheck);
  };

  private areAllEmployeesSelected = () =>
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    this.props.offerEmployees.length === this.props.allEmployees.length;

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  private areNoneEmployeesSelected = () => this.props.offerEmployees.length === 0;

  private areSomeEmployeesSelected = () =>
    !this.areAllEmployeesSelected() && !this.areNoneEmployeesSelected();

  private selectAllEmployeesHandler = () => {
    const {
      offerDialogSelectAllEmployeesAction,
      offerDialogDeselectAllEmployeesAction,
    } = this.props.actions;

    if (this.areAllEmployeesSelected()) {
      offerDialogDeselectAllEmployeesAction();
      return;
    }
    if (this.areNoneEmployeesSelected() || this.areSomeEmployeesSelected()) {
      offerDialogSelectAllEmployeesAction();
    }
  };

  private getSelectAllController = () =>
    this.props.allEmployees.length > 1 ? (
      <div className={style.selectAll}>
        <Checkbox
          name="select-all-employees"
          label={LANG.selectAll}
          checked={this.areAllEmployeesSelected()}
          onChange={this.selectAllEmployeesHandler}
          disabled={this.props.disabled}
          semiChecked={this.areSomeEmployeesSelected()}
        />
      </div>
    ) : null;

  public render() {
    const { escapeWithSkuPricing } = this.props;

    if (escapeWithSkuPricing) {
      return null;
    }

    return (
      <div className={style.container}>
        <div className={style.header}>{LANG.title}</div>
        {this.getSelectAllController()}
        <div className={style.items}>{this.getItems()}</div>
      </div>
    );
  }
}
