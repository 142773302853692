import React, { Component } from 'react';
import ReactDialog from 'components/common/react-dialog';
import Wahanda from 'common/wahanda';
import DetailsRow from '../Row';
import Reply from './Reply';
import ReviewDetails from '../Details';
import * as analytics from './analytics';
import style from './style.css';

const LANG = Wahanda.lang.dashboard.reviews;
interface IDialogProps extends React.HTMLAttributes<Element> {
  isMasquerading: boolean;
  reviewData: {
    treatmentRatings: {
      rating: number;
      treatmentName: string;
    }[];
    venueRatings: {
      venueReviewRatingType: string;
      rating: number;
    }[];
  };
  onClose: (...args: any[]) => any;
  saveReply: (...args: any[]) => any;
  reviewResponseSaving?: boolean;
  venueId: number;
}
class Dialog extends Component<IDialogProps, {}> {
  componentDidMount() {
    analytics.trackReviewViewed();
  }

  render() {
    const {
      reviewData,
      onClose,
      saveReply,
      reviewResponseSaving,
      venueId,
      isMasquerading,
    } = this.props;
    const canReply = !!(
      reviewData &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'response' does not exist on type '{ trea... Remove this comment to see the full error message
      !reviewData.response &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'content' does not exist on type '{ treat... Remove this comment to see the full error message
      reviewData.content &&
      !isMasquerading
    );
    let dialogRef;
    const resizeDialog = () => {
      dialogRef.updateHeight();
    };
    const hasTreatmentRatings = reviewData.treatmentRatings.length > 0;
    const hasVenueRatings = reviewData.venueRatings.length > 0;
    const reviewDetails =
      hasTreatmentRatings || hasVenueRatings ? (
        <ReviewDetails
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'reviewer' does not exist on type '{ trea... Remove this comment to see the full error message
          venueCustomerId={reviewData.reviewer.venueCustomerId}
          treatmentRatings={reviewData.treatmentRatings}
          venueRatings={reviewData.venueRatings}
        />
      ) : null;
    const dialogWidth = hasTreatmentRatings || hasVenueRatings ? 806 : 448;
    return (
      <ReactDialog
        dataTest="review-modal"
        width={dialogWidth}
        title={LANG.dialogTitle}
        classes={style.dialog}
        onClose={onClose}
        warnWhenClosingChangedForms={canReply}
        ref={(ref) => {
          dialogRef = ref;
        }}
        keepTopPositionWhenResizing
        restrictHeight
      >
        <div className={style.reviewPanel}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: Type '{ onResize: () => void; treatmentRatings: { ... Remove this comment to see the full error message */}
          <DetailsRow {...reviewData} onResize={resizeDialog} />
          {canReply ? (
            <Reply
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{ treatmentR... Remove this comment to see the full error message
              reviewId={reviewData.id}
              venueId={venueId}
              saveReply={saveReply}
              onHeightChange={resizeDialog}
              reviewResponseSaving={reviewResponseSaving}
            />
          ) : null}
        </div>
        {reviewDetails}
      </ReactDialog>
    );
  }
}

export default Dialog;
