import React from 'react';
import Wahanda from 'common/wahanda';
import ItemRowView from './item-row';

function getRow(amount, title, key, additionalClasses) {
  return (
    <ItemRowView title={title} amount={amount} key={key} additionalClass={additionalClasses} />
  );
}

/*
 *  TODO
 *
 *  It's this pattern again
 */
function getOptionalRow(amount, title, key, additionalClasses) {
  if (!amount) {
    return;
  }
  // eslint-disable-next-line consistent-return
  return getRow(amount, title, key, additionalClasses);
}

const { refundsLabel } = Wahanda.lang.reports.transactions;
type ItemGroupRowProps = {
  title?: string;
  totalAmount?: number;
  refundAmount?: number;
  additionalClasses?: string;
};
const ItemGroupRow: React.SFC<ItemGroupRowProps> = ({
  title,
  totalAmount,
  refundAmount,
  additionalClasses,
}) => (
  <div className="item-group">
    {[
      getRow(totalAmount, title, title, additionalClasses),
      getOptionalRow(refundAmount, refundsLabel, `refund${title}`, 'negative sub-item'),
    ]}
  </div>
);

export default ItemGroupRow;
