import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class CalendarAdd extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-calendar-add', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M21,16 L21,19 L24,19 L24,21 L21,21 L21,24 L19,24 L19,21 L16,21 L16,19 L19,19 L19,16 L21,16 Z M9,7 L7,7 L7,5 L4.99991026,5 L4.99999882,18.5286104 C4.99999922,18.6841092 4.99999961,18.8409485 5,18.9991283 L13.612949,18.9999779 C13.7405482,18.9999852 13.8695652,18.9999926 14,19 L14,21 L4.99754465,21 C3.89292425,21 3,20.106246 3,18.9991283 L3,5.00087166 C3,3.8953067 3.89193733,3 4.99980749,3 L7,3 L7,1 L9,1 L9,7 Z M13,15 L13,17 L11,17 L11,15 L13,15 Z M9,15 L9,17 L7,17 L7,15 L9,15 Z M19,3 C20.1045695,3 21,3.8954305 21,5 L21,14 L19,14 L19,3 Z M9,11 L9,13 L7,13 L7,11 L9,11 Z M13,11 L13,13 L11,13 L11,11 L13,11 Z M17,11 L17,13 L15,13 L15,11 L17,11 Z M17,1 L17,7 L15,7 L15,5 L11,5 L11,3 L15,3 L15,1 L17,1 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(CalendarAdd);
