/* global Backbone _ */

(function () {
  const FLAG_TERMS = 4;

  const ALL_FLAGS = FLAG_TERMS;

  App.Views.OnboardingWizzard = Backbone.View.extend({
    MODE_FULL: 'full',
    MODE_FETCH: 'fetch',

    events: {
      'click #wizzard-next': 'gotoNextStep',
    },
    /**
     * Current step number.
     */
    currentFlag: null,
    /**
     * Current check state.
     * It is a binary ORed integer of FLAG_* items.
     */
    checkState: 0,
    /**
     * Which flag`s dialog must be shown to the user.
     */
    stateByFlag: [],

    mode: null,

    /**
     * Are there any screens to be shown?
     *
     * this state should be checked only after this.checkState === ALL_FLAGS.
     */
    anythingToShow: false,

    _running: false,

    /**
     * Sets the mode wizard runs in:
     * - MODE_FETCH - ONLY fetch models.
     * - MODE_FULL - fetches the models with full wizard checks
     */
    setMode: function (mode) {
      this.mode = mode;
    },

    start: function () {
      this.checkTermsAgreed();
    },

    checkTermsAgreed: function () {
      const result =
        App.config.get('venue').supplierId > 0 &&
        App.config.get('venue').termsConditionsAgreed === false &&
        App.isAdministrator();
      // Conditions must be not agreed to show the dialog
      this.setStateFlag(FLAG_TERMS, result);
    },

    setStateFlag: function (checkFlag, checkResult, optional) {
      /* eslint-disable-next-line no-bitwise  */
      this.checkState |= checkFlag;
      this.anythingToShow = this.anythingToShow || (checkResult && !optional);

      this.stateByFlag.push({
        flag: checkFlag,
        result: checkResult,
      });

      /* eslint-disable-next-line no-underscore-dangle */
      if (this._running) {
        // Further checks must not run the wizard from the start.
        return;
      }
      if (this.checkState === ALL_FLAGS) {
        /* eslint-disable-next-line no-underscore-dangle */
        this._running = true;
        if (this.mode === this.MODE_FULL && this.anythingToShow) {
          this.stateByFlag = _.sortBy(this.stateByFlag, function (o) {
            return o.flag;
          });
          this.render();
        } else {
          // Nothing to show. Hide the overlay, let use the site.
          this.cleanup();
        }
      }
    },

    // ---- Rendering

    render: function () {
      App.trigger(Wahanda.Event.ONB_WIZARD_SHOW);
      this.$el.show();
      this.showOverlay();
      this.gotoNextStep();
    },

    gotoNextStep: function () {
      const nextStep = this.getNextStepMethod();

      if (nextStep) {
        this[nextStep]();
      }
    },

    getNextStepMethod: function () {
      let flag = null;

      /* eslint-disable-next-line */
      for (const i in this.stateByFlag) {
        if (this.currentFlag < this.stateByFlag[i].flag && this.stateByFlag[i].result) {
          flag = this.stateByFlag[i].flag;
          this.currentFlag = this.stateByFlag[i].flag;
          break;
        }
      }

      switch (flag) {
        case FLAG_TERMS:
          return 'renderTerms';

        default:
          return null;
      }
    },

    showOverlay: function () {
      this.$el.addClass('welcome-wizzard-overlay');

      const self = this;
      const $win = $(window);
      const resizeFunction = function () {
        self.$el.css({
          width: $win.width(),
          height: $win.height(),
        });
      };
      $win.on('resize.wizzard', resizeFunction);

      resizeFunction();
      $('body').append(this.$el);
    },

    // ---- Step renderers
    renderTerms: function () {
      const self = this;

      const terms = new App.Views.Forms.TermsAndConditions({
        modal: false,
        dialogClass: 'dialog-new-style',
        closeOnEscape: false,
        isFromWizard: true,
        saveOverride: function () {
          if (terms.$el.find('#i-agree').is(':checked')) {
            terms.disableForm();
            const $button = terms.$('.wizzard-next').action('doing');
            terms.agreeToTerms(
              function () {
                App.trigger(Wahanda.Event.ONB_WIZARD_TOS_ACCEPTED);
                terms.close();
                self.cleanup();
              },
              function () {
                terms.enableForm();
                $button.action().errorTip(Wahanda.lang.shared.savingFailed);
              },
            );
          }
        },
      });
      terms.render();
      terms.$el.addClass(terms.wizzardClass);

      terms.open();
    },

    /**
     * Cleanup actions for the dialog.
     */
    cleanup: function () {
      $(window).off('.wizzard');
      this.$el.hide();

      // Wizard's state
      this.currentFlag = null;
      this.checkState = 0;
      this.stateByFlag = [];
      this.anythingToShow = false;

      App.isLoaded = true;
      App.trigger(Wahanda.Event.ONB_WIZARD_DONE);
    },

    finishWizard: function () {
      window.location.reload();
    },
  });
})();
