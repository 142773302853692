import React, { Component } from 'react';
import Wahanda from 'common/wahanda';
import { CameraAdd } from 'components/common/Icon';
import Close from 'components/common/icons/Close';
import style from './style.scss';

const IMAGE_SIZE = 80;
interface IImageUploadButtonProps extends React.HTMLAttributes<Element> {
  imageUris?: {};
  onAddClick?: (...args: any[]) => any;
  onRemoveClick?: (...args: any[]) => any;
}

export default class ImageUploadButton extends Component<IImageUploadButtonProps, {}> {
  static defaultProps = {
    imageUris: null,
    onAddClick: () => {},
    onRemoveClick: () => {},
  };

  render() {
    const { imageUris, onAddClick, onRemoveClick } = this.props;
    const imageUri = imageUris
      ? Wahanda.ScreenDensity.getImageFromStructure(imageUris, IMAGE_SIZE, IMAGE_SIZE)
      : null;
    return (
      <div className={style.container}>
        <div onClick={onAddClick}>
          {imageUri ? (
            <img className={style.photo} src={imageUri} alt="" />
          ) : (
            <div className={style.circle}>
              <CameraAdd className={style.addPhotoIcon} />
            </div>
          )}
        </div>
        {imageUri && (
          <div className={style.remove} onClick={onRemoveClick}>
            <Close className={style.closeIcon} />
          </div>
        )}
      </div>
    );
  }
}
