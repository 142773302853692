import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSmartDiscounts, getIsLoaded } from 'components/marketing/EmailCampaign/selectors';
import { getDiscountsRulesRequest, selectDiscount } from '../../actions';
import SmartDiscountsSelect from './index';

const mapStateToProps = (state) => ({
  smartDiscounts: getSmartDiscounts(state),
  isLoaded: getIsLoaded(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getDiscountsRulesRequest,
      selectDiscount,
    },
    dispatch,
  ),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ smartDiscounts: null; }' is not assignable... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(SmartDiscountsSelect);
