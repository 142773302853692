import React from 'react';
import Wahanda from 'common/wahanda';
import { StateCard } from 'components/common/StateCard';
import { ServiceList } from 'components/common/IconPicture';
import style from './EmptyState.scss';

export const EmptyState = () => {
  return (
    <StateCard
      dataTest="add-services-modal-empty-state"
      image={<ServiceList />}
      heading={Wahanda.lang.menu.multipleServicesImport.emptyState.title}
      message={Wahanda.lang.menu.multipleServicesImport.emptyState.message}
    />
  );
};

export const NoResults = ({ searchedValue }: { searchedValue: string }) => {
  return (
    <div className={style.noResults}>
      {Wahanda.lang.menu.serviceTemplateSelector.noResultsFor}
      {': '}
      <strong>{searchedValue}</strong>
    </div>
  );
};
