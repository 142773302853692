import React, { useEffect, useState, useRef } from 'react';
import { fetchRemoteModule } from './fetchModule';
import styles from './Reconnect.module.css';

import {
  SharedProps,
  ValidComponent,
  InjectType,
} from '../../../../reconnect/types/moduleFederation';
import { track } from 'src/tracking/trackingService';

const remoteUrl = `/reconnect/remoteEntry.js`;

export const useRemoteModule = <T,>(scope: string, module: string) => {
  const [loadedModule, setModule] = useState<T | null>(null);

  useEffect(() => {
    fetchRemoteModule({
      remoteUrl,
      scope,
      module,
    })
      .then((fetchedModule) => {
        setModule(fetchedModule);
      })
      .catch(() => {});
  }, [module, scope]);

  return loadedModule;
};

const getLegacyConfig = (): Config => {
  const venue = App.config.get('venue');

  return {
    countryCode: venue.countryCode,
    venueId: venue.id,
    supplierId: App.getSupplierId(),
    isVatFeatureEnabled: App.config.isVatPayerFeatureEnabled(),
    isIdentityVerificationNeeded: App.isIdentityVerificationNeeded(),
    isBankAccountSyncNeeded: App.isBankAccountSyncNeeded(),
    isPointOfSaleEnabled: venue.pointOfSaleEnabled,
    locale: App.getClientLocale() || 'en',
    currency: Wahanda.Currency,
    numberFormat: App.config.getNumberFormat(),
  };
};

export type InitialiseI18n = (language: string) => Promise<unknown>;

const getConnectTrackingService = (): TrackingService => ({
  trackEvent: ({ name, payload }) => track(name, payload),
});

export const Reconnect = <C extends ValidComponent>({
  name,
  props,
}: {
  name: C;
  props?: SharedProps[C];
}): JSX.Element => {
  const remoteModule = useRemoteModule<{
    inject: InjectType;
    initialiseI18n: InitialiseI18n;
  }>('reconnect', './remoteEntry');
  const config = getLegacyConfig();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!remoteModule || !ref.current) {
      return;
    }
    config.trackingService = props?.trackingService ?? getConnectTrackingService();
    const unmount = remoteModule.inject<C>({ node: ref.current, name, config, props });
    return () => unmount();
  }, [remoteModule, name, props, config]);
  return <div ref={ref} className={styles.reconnect} />;
};
