import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class CheckFilled extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-check-filled', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 Z M15.793,8.293 L10.5,13.586 L8.207,11.293 L6.793,12.707 L10.5,16.414 L17.207,9.707 L15.793,8.293 L15.793,8.293 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(CheckFilled);
