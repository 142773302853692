import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import { UDVAppAdvocacyBanner as Component, getCookieKey } from './UDVAppAdvocacyBanner';
import { BOOKING_ACTOR } from 'common/consts';

const mapStateToProps = (state) => {
  const customerId = state.calendar.inlineClientForm.id;
  const doNotShowCookieExists = !!Cookie.get(getCookieKey(customerId));

  const shouldShowBanner =
    !doNotShowCookieExists &&
    !state.calendar.UDVAppAdvocacyBanner.isLoading &&
    state.calendar.inlineClientForm.phone &&
    App.isFeatureSupported('invite-to-dl-tw-app') &&
    state.calendar.inlineClientForm.sendMassEmails &&
    state.calendar.UDVAppAdvocacyBanner.venueDetails.isConsentedForAppAdvocacy &&
    [BOOKING_ACTOR.SUPPLIER, BOOKING_ACTOR.WIDGET].includes(
      state.calendar.UDVAppAdvocacyBanner.bookingActor,
    );

  return {
    isShowingSuccessOverlay: state.calendar.UDVAppAdvocacyBanner.isShowingSuccessOverlay,
    venueId: App.getVenueId(),
    appointmentDateTime: state.calendar.UDVAppAdvocacyBanner.appointmentDateTime,
    shouldShowBanner,
    customerId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const UDVAppAdvocacyBanner = connect(mapStateToProps, mapDispatchToProps)(Component);
