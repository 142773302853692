(function($) {
	/**
	 * Toggling of table rows by class name. In reality it hides the clicked row and shows others.
	 * 
	 * The events are delegated, so it is dynamic content friendly.
	 * Plugin must be run on a table.
	 * 
     * @param Object opts Options:
     *   > listenOn (string) - jQuery selector of TRs classname to listen for clicks on. Defaults to "head".
     *   > toggleBy (string) - classname by which to toggle. Defaults to "toggle".
     *   > canToggle (function) - OPTIONAL Function to check if the rows can be toggled. By default all rows are
     * 					toggled. Is passed three parameters: clicked row, whole table and plugin options.
	 */
	$.fn.tableToggler = function(opts) {
		var table   = this;
		var options = $.extend({}, $.fn.tableToggler.defaults, opts);
		
		table.on('click', 'tr.' + options.listenOn, function() {
			var clickedRow = $(this);
			if (!$.isFunction(opts.canToggle) || opts.canToggle(clickedRow, table, options)) {
				// Closing possibly open rows, opening headers
				table.find('tr.' + options.listenOn).removeClass('hidden');
				table.find('tr.' + options.toggleBy).addClass('hidden');
				
				var toToggle = clickedRow.nextUntil('tr.' + options.listenOn, 'tr');
				clickedRow.addClass('hidden');
				toToggle.removeClass('hidden');
			}
		});
	};
	
	$.fn.tableToggler.defaults = {
		listenOn: "head",
		toggleBy: "toggle",
		canToggle: function(tr, table, options) { return true; }
	};
}(jQuery));
