import React from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import Dialog from 'components/common/react-dialog';
import Loader from 'components/common/Loader';
import { Radio } from 'components/common/Radio';
import { DialogFooter, DialogFooterButtonProps } from 'components/common/DialogFooter';
import {
  DateRangeObject,
  InvalidateEmployeesSchedulePayload,
  ScheduleChangeOptionType,
} from '../utils/types';
import { ShiftsAnalytics } from '../tracking';
import style from './ShiftScheduleChangeModal.scss';

interface Props {
  employeeId: number;
  scheduleId: number;
  invalidFrom: string;
  onClose: (param) => void;
  onNewScheduleTrigger: () => void;
  trackingCategory?: string | null;
  // redux
  actions: {
    invalidateEmployeesSchedule: (payload: InvalidateEmployeesSchedulePayload) => void;
  };
  datePickerRange: DateRangeObject;
  isSavingShift: boolean;
}

export const ShiftScheduleChangeModal = ({
  actions,
  datePickerRange,
  employeeId,
  invalidFrom,
  isSavingShift,
  onClose,
  onNewScheduleTrigger,
  scheduleId,
  trackingCategory,
}: Props) => {
  const [radioValue, setRadioValue] = React.useState(ScheduleChangeOptionType.RESET);
  const observer = React.useRef(false);
  React.useEffect(() => {
    if (observer.current) {
      onClose(false);
    } else {
      ShiftsAnalytics.trackShiftScheduleChangeModalView({
        category: trackingCategory,
      });
      observer.current = true;
    }
  }, [isSavingShift]);

  function handleClose(event) {
    if (event) {
      ShiftsAnalytics.trackShiftScheduleChangeModalClose({
        category: trackingCategory,
      });
    }
    onClose(event);
  }

  function handleSubmit() {
    if (radioValue === ScheduleChangeOptionType.RESET) {
      actions.invalidateEmployeesSchedule({
        employeeId,
        scheduleId,
        invalidFrom,
        dateTo: datePickerRange.dateTo,
      });
    }
    if (radioValue === ScheduleChangeOptionType.NEW) {
      onNewScheduleTrigger();
    }
    ShiftsAnalytics.trackShiftScheduleChangeModalSubmit({
      category: trackingCategory,
      property: radioValue,
    });
  }

  const buttons: DialogFooterButtonProps[] = [
    {
      dataTest: 'schedule-change-footer-button-close',
      title: Wahanda.lang.shared.buttons.close,
      type: 'secondary',
      onClick: handleClose,
    },
    {
      dataTest: 'schedule-change-footer-button-confirm',
      title: Wahanda.lang.shared.buttons.confirm,
      onClick: handleSubmit,
    },
  ];
  const optionsList = [
    {
      label: Wahanda.lang.settings.shifts.modalEditSchedule.switchToSalon,
      value: ScheduleChangeOptionType.RESET,
    },
    {
      label: Wahanda.lang.settings.shifts.modalEditSchedule.createNew,
      value: ScheduleChangeOptionType.NEW,
    },
  ];
  const dateFrom = moment(invalidFrom).formatLongDate();
  return (
    <Dialog
      dataTest="shift-schedule-change-modal"
      width={400}
      title={Wahanda.lang.settings.shifts.modalEmployeeActions.changeSchedule}
      onClose={handleClose}
      noContentPadding
    >
      <div className={style.container}>
        <div className={style.date}>
          {Wahanda.lang.settings.shifts.modalEditSchedule.starting} {dateFrom}:
        </div>
        {optionsList.map((opt) => (
          <div className={style.radio} key={opt.value}>
            <Radio
              dataTest={`shift-schedule-modal-radio-${opt.value}`}
              checked={radioValue === opt.value}
              key={opt.value}
              label={opt.label}
              name="schedule-change"
              onChange={() => setRadioValue(opt.value)}
              value={opt.value}
            />
          </div>
        ))}
      </div>
      {isSavingShift && <Loader positionAbsolute />}
      <DialogFooter buttons={buttons} />
    </Dialog>
  );
};
