import App from 'common/backbone-app';

export function getSalesForceScriptConfig() {
  if (App.isProd) {
    return {
      bootstrapJsSrc:
        'https://treatwell2021.my.site.com/ESWTWConnectChatWeb1712056261029/assets/js/bootstrap.min.js',
      scrt2URL: 'https://treatwell2021.my.salesforce-scrt.com',
      init: {
        orgId: '00D4L000000iBHI',
        eswConfigDevName: 'TW_Connect_Chat_Web',
        siteUrl: 'https://treatwell2021.my.site.com/ESWTWConnectChatWeb1712056261029',
      },
    };
  }

  // development
  return {
    bootstrapJsSrc:
      'https://treatwell2021--partial.sandbox.my.site.com/ESWTWConnectChatWeb1711465679787/assets/js/bootstrap.min.js',
    scrt2URL: 'https://treatwell2021--partial.sandbox.my.salesforce-scrt.com',
    init: {
      orgId: '00DUE000000MNvd',
      eswConfigDevName: 'TW_Connect_Chat_Web',
      siteUrl:
        'https://treatwell2021--partial.sandbox.my.site.com/ESWTWConnectChatWeb1711465679787',
    },
  };
}
