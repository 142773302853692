import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';
import { allEmployeesOptionValue } from '../EmployeeFilter';
import { CHANNEL } from '../BookingChannelFilter';
import ROUTES from '../SalesReportContainer/reportRoutes';

interface ISalesReportLinkProps extends React.HTMLAttributes<Element> {
  onClick: (...args: any[]) => any;
  fromDate: any;
  toDate: any;
  employeeFilter?: number;
  selectedChannel?: string;
  match: {
    params: {
      venueId: string;
    };
  };
  className?: string;
  params?: any;
}
class SalesReportLink extends React.Component<ISalesReportLinkProps, {}> {
  static defaultProps = {
    employeeFilter: allEmployeesOptionValue,
    selectedChannel: CHANNEL.ALL,
    className: null,
  };

  onClick = () => {
    this.props.onClick(
      this.props.fromDate,
      this.props.toDate,
      this.props.selectedChannel,
      this.props.employeeFilter,
    );
  };

  get toLink() {
    const { venueId } = this.props.match.params;
    const { fromDate, toDate, selectedChannel, employeeFilter } = this.props;
    const toPath = pathToRegexp.compile(ROUTES.SALES_REPORT);
    return toPath({
      venueId,
      fromDate: fromDate.formatApiDateString(),
      toDate: toDate.formatApiDateString(),
      employeeFilter:
        employeeFilter != null && employeeFilter == null ? allEmployeesOptionValue : employeeFilter,
      selectedChannel,
    });
  }

  render() {
    return (
      <Link to={this.toLink} className={this.props.className} onClick={this.onClick}>
        {this.props.children}
      </Link>
    );
  }
}

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ employeeFilter: number; selectedChannel: s... Remove this comment to see the full error message
export default withRouter(SalesReportLink);
