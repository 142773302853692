/* global BackboneEx */
import { enableRecording } from 'common/analytics';

App.Views.Store = BackboneEx.View.Main.extend({
  initialize: function initialize() {
    enableRecording();
  },

  render: function render() {
    App.headerView.setModule('store');
  },
});
