import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions';
import AssignedProductsTab from './index';

const mapStateToProps = ({ productSuppliers: { supplier } }, props) => ({
  selected: supplier.selectedProductsIds.indexOf(props.id) !== -1,
  newSupplier: supplier,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ productSupplier: null; selected: boolean; ... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(AssignedProductsTab);
