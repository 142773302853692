import React from 'react';
import ReactDOM from 'react-dom';
import Wahanda from 'common/wahanda';
import { Initializer } from 'common/types/initializers';
import { Props, ImageEditDialog } from './';

const container = Wahanda.Dialogs.getReactContainer();

const mount = (component: JSX.Element) => {
  ReactDOM.render(component, container);
};

const unmount = () => {
  ReactDOM.unmountComponentAtNode(container);
};

export const ImageEditDialogInitializer = (props: Props): Initializer => {
  const onClose = () => {
    unmount();
    props.onClose && props.onClose();
  };

  return {
    render: () => mount(<ImageEditDialog {...props} onClose={onClose} />),
    destroy: unmount,
  };
};
