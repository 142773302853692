import React from 'react';

interface Props {
  className?: string;
}

class Rocket extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-rocket', this.props.className].join(' ')}
        viewBox="0 0 60 92"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFB500"
            d="M35.026 33.539c.586.83.552 1.931.557 2.95.004.708.082 1.506.631 1.954.65.53 1.69.268 2.25-.357s.745-1.496.843-2.33c.262-2.226.002-4.634-1.358-6.416-.798-1.047-1.924-1.807-3.123-2.348-2.584-1.166-10.228-2.662-12.206 4.12-1.45 4.974 10.04-.925 12.406 2.427"
            fillRule="nonzero"
          />
          <path
            fill="#FF5C39"
            d="M42.294 28.136c.259 8.356-4.776 17.803-4.776 26.163 0 3.902 3.539 16.879 5.771 17.597.307.099 2.244-6.237 2.38-7.817.277-3.248.757-6.464 1.72-9.577 1.412-4.562 1.97-10.122 2.251-14.887.345-5.84-.215-9.993-1.845-15.926-1.012-3.687-2.704-7.077-4.852-10.24C40.57 9.956 36.5 4.138 32.95 1.85c-1.58-1.02-6.417.742-6.417.742 8.373.804 15.218 8.016 15.761 25.545"
            fillRule="nonzero"
          />
          <path
            stroke="#001E62"
            d="M3.98 32.019v1.264m0 3.431v1.265m2.98-2.98H5.696m-3.432 0H1m34.653 48.98v1.264m0 3.432v1.264m2.98-2.98h-1.264m-3.431 0h-1.265M22.26 37.632a8.869 8.869 0 116.941 5.94"
            strokeWidth="1.417"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            stroke="#001E62"
            d="M10.851 22.733c.93-1.075 4.049-2.919 4.049-2.919 4.968-2.586 10.87-1.216 16.304-.058 5.433 1.157 11.582 2.533 16.007-.981.17-.135.521-.287.455-1.14-.03-.399-.155-.572-.433-.815-.464-.409-1.21-.384-1.59.107a1.406 1.406 0 00-.127 1.538c.105.188.238.394.403.622.418.575.718 1.24.984 1.95.301.803.558 1.662.892 2.507 3.233 8.17 2.56 21.982-4.766 49.178"
            strokeWidth="1.417"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            stroke="#001E62"
            d="M20.585 81.972c-9.733-33.41-11.324-49.379-7.743-58.428C20.287 4.722 27.269 1 30.319 1m13.756 14.237C38.101 3.57 32.854 1 30.32 1m7.198 77.648c1.365.1 2.656.242 3.846.434 5.454.882 11.494 1.692 15.032 1.812 0 0 .698-.08 1.18-.08.48 0 .863-.545.84-1.194-.353-10.786-5.47-19.34-11.73-19.34"
            strokeWidth="1.417"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            stroke="#001E62"
            d="M14.212 60.28c-6.261 0-11.378 8.554-11.732 19.34-.021.65.36 1.195.841 1.195 0 0 20.695-3.157 34.198-2.167"
            strokeWidth="1.417"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            stroke="#001E62"
            d="M37.939 79.531c0 .467-.287.848-.637.848H23.336c-.351 0-.637-.381-.637-.848"
            strokeWidth="1.23"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            stroke="#001E62"
            d="M27.153 84.911v5.626"
            strokeWidth="1.417"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  }
}

export const icon = Rocket;
