import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import moment from 'common/moment';
import {
  GET_APPOINTMENT_NOTIFICATIONS_REQUEST,
  GET_WEBSOCKETS_MESSAGE_RECEIVED,
} from 'reduxStore/actionsConst';
import {
  receiveAppointmentNotificationsAction,
  requestAppointmentNotificationsFailureAction,
} from './actions';

function get(url) {
  const isToday = (datetime) => moment(datetime).startOf('day').isSame(moment().startOf('day'));

  return api.get(url).then((data: any) =>
    data.appointmentEventDatas.map((item) => {
      const appointmentDatetime = item.appointmentLocalDateTime;
      const appointmentDatetimeOld = item.originalAppointmentLocalDateTime;
      const appointmentVisibleOnCalendar =
        item.currentAppointmentStatus !== 'CANCELLED' &&
        item.currentAppointmentStatus !== 'REJECTED';

      return {
        id: item.appointmentEventId,
        notificationType: item.appointmentEventType,
        notificationDatetime: item.occurredAt,
        appointmentId: item.appointmentId,
        appointmentDatetime,
        appointmentDatetimeOld,
        isAppointmentDatetimeToday: isToday(appointmentDatetime),
        isAppointmentDatetimeOldToday: isToday(appointmentDatetimeOld),
        appointmentVisibleOnCalendar,
        employeeFullName: item.employeeName,
        clientFullName: item.recipientName,
        additionalFields: item.additionalFields,
      };
    }),
  );
}

export default function* getAppointmentNotificationsRequested(action) {
  const isDirectRequested = action.type === GET_APPOINTMENT_NOTIFICATIONS_REQUEST;
  const isWebSocketsTriggered =
    action.type === GET_WEBSOCKETS_MESSAGE_RECEIVED &&
    action.data.type === 'appointment-activity-event';

  if (isDirectRequested || isWebSocketsTriggered) {
    try {
      const data = yield call(get, apiUrl('APPOINTMENT_EVENTS', { limit: 100 }));

      yield put(receiveAppointmentNotificationsAction(data));
    } catch (err) {
      yield put(requestAppointmentNotificationsFailureAction(err));
    }
  }
}
