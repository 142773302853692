import * as Sentry from '@sentry/browser';
import { httpPostJson } from '@treatwell/ui';
import { SalesForceTokens } from '@treatwell/ui/dist/types/src/hooks/useSalesforceBot/types';

export const getTokens = async () => {
  const path = Wahanda.Features.isEnabled('CD-1586-salesforce-bot-getting-tokens-with-venue-id')
    ? App.Api.wsUrl(`/venue/${App.getVenueId()}/salesforce/chat-token`)
    : App.Api.wsUrl('/salesforce/chat-token');

  try {
    return httpPostJson<SalesForceTokens | null>({ path });
  } catch (error) {
    Sentry?.captureException(error);
    return null;
  }
};
