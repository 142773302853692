import React from 'react';
import PromotionTypeSelect from './PromotionTypeSelect';
import SmartDiscountSelect from './SmartDiscountSelect/container';
import ServiceOnSaleSelect from './ServiceOnSaleSelect/container';
import {
  SMART_DISCOUNTS_PROMO_OFFER,
  SERVICE_ON_SALE_PROMO_OFFER,
} from './PromotionTypeSelect/promoTypes';

interface IPromotionalOffersProps extends React.HTMLAttributes<Element> {
  actions: {
    resetCampaignSelectionFlow: (...args: any[]) => any;
  };
  campaignSelection: {
    flowInProgress?: boolean;
    currentStep?: number;
    promotionalOffers?: {
      selectedPromotion?: string;
    };
  };
  selectedPromotion?: any;
  promotionalOffers?: any;
  resetCampaignSelectionFlow?: any;
}

export default class PromotionalOffers extends React.Component<IPromotionalOffersProps, {}> {
  abortFlow = () => {
    this.props.actions.resetCampaignSelectionFlow();
  };

  renderSecondStep = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    switch (this.props.campaignSelection.promotionalOffers.selectedPromotion) {
      case SMART_DISCOUNTS_PROMO_OFFER:
        // @ts-expect-error ts-migrate(2322) FIXME: Type '() => void' is not assignable to type 'never... Remove this comment to see the full error message
        return <SmartDiscountSelect onClose={this.abortFlow} />;
      case SERVICE_ON_SALE_PROMO_OFFER:
        // @ts-expect-error ts-migrate(2322) FIXME: Type '() => void' is not assignable to type 'never... Remove this comment to see the full error message
        return <ServiceOnSaleSelect onClose={this.abortFlow} />;
      default:
        return null;
    }
  };

  render() {
    const { campaignSelection } = this.props;
    switch (campaignSelection.currentStep) {
      case 1:
        return <PromotionTypeSelect onClose={this.abortFlow} />;
      case 2:
        return this.renderSecondStep();
      default:
        return null;
    }
  }
}
