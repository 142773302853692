import React from 'react';
import {
  InputBase,
  InputSizeType,
  InputText,
  InputTextValue,
} from 'components/common/__BaseCommon';

import { useComponentDidMount, useDebounce } from 'utilities/hooks';

export interface InputProps {
  autoComplete?: string;
  autoFocus?: boolean;
  dataTest?: string;
  disabled?: boolean;
  errorMessage?: string;
  fullBorder?: boolean;
  hasDebounce?: boolean;
  hasError?: boolean;
  label?: string;
  min?: number | string;
  maxLength?: number;
  max?: number | string;
  name: string;
  onChange?: (value: InputTextValue) => void;
  onBlur?: (value: InputTextValue) => void;
  onFocus?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  size?: InputSizeType;
  type?: string;
  value?: InputTextValue;
  newLook?: boolean;
}

export const Input = ({
  autoFocus = false,
  autoComplete,
  name,
  min,
  max,
  maxLength,
  disabled,
  dataTest,
  size = InputSizeType.DEFAULT,
  value,
  hasDebounce,
  hasError,
  errorMessage,
  fullBorder,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  label,
  readOnly = false,
  required,
  type = 'text',
  newLook = false,
}: InputProps) => {
  const componentDidMount = useComponentDidMount();
  const [inputValue, setInputValue] = React.useState(value as InputTextValue);
  const [isFocused, setFocus] = React.useState(autoFocus);
  let modelValue = useDebounce(inputValue, 300);
  if (!hasDebounce) {
    modelValue = inputValue;
  }

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
    setFocus(true);
  };
  const handleBlur = () => {
    if (onBlur) {
      onBlur(modelValue);
    }
    setFocus(false);
  };
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  React.useEffect(() => {
    if (componentDidMount && onChange) {
      onChange(modelValue);
    }
  }, [modelValue]);

  return (
    <InputBase
      size={size}
      dataTest={dataTest}
      disabled={disabled}
      label={label}
      readOnly={readOnly}
      hasError={hasError}
      fullBorder={fullBorder}
      required={required}
      errorMessage={errorMessage}
      isFocused={isFocused}
      newLook={newLook}
    >
      <InputText
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        dataTest={dataTest}
        name={name}
        min={min}
        max={max}
        maxLength={maxLength}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        readOnly={disabled || readOnly}
        value={inputValue || ''}
        type={type}
      />
    </InputBase>
  );
};

Input.displayName = 'Input';
