import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import { getWithPromise } from 'common/xhr';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { putCustomerInfoErrorAction } from 'reduxStore/common/customers/actions';

import { InlineClient } from './container';
import {
  clientSaved,
  clientSetWalkin,
  initialiseInlineClient,
  clientChosen,
  clearInlineClient,
  setClientProperties,
} from './store/actions';

const store = storeBuilder();

function showClientDetails(mediator) {
  return (id) => {
    const view = App.Views.Dialog.Consumer.createInstance(id, mediator);
    view.render();
    view.open();
  };
}

function customerUpdatedInBackbone(model) {
  store.dispatch(clientSaved(model.toJSON()));
}

function customerSaveError(xhr) {
  const error = get(xhr, 'responseJson.errors[0].name');
  if (error === 'invalid-venue-customer-email') {
    store.dispatch(putCustomerInfoErrorAction(error));
  }
}

function walkinClientRequested(mediator, anonymousNote) {
  store.dispatch(clientSetWalkin(anonymousNote));
  mediator.trigger(mediator.CUSTOMER_SET_WALKIN, { anonymousNote });
}

function autocompleteClientChosen(mediator, data) {
  store.dispatch(clientChosen(data));
  mediator.trigger(mediator.CUSTOMER_FOUND, new App.Models.Customer(data));
}

let inlineClientCreationRef = null;
let inlineClientReusedPropsRef = null;

function prepareProps({
  customerModel,
  mediator,
  appointmentCollection,
  toggleIgnoreKeyboardEvents,
  hasEditableAppointments,
  renderCallback,
}) {
  const isRepeat = appointmentCollection.determineIfRepeat();
  const viewContacts = Wahanda.Permissions.viewClientContactDetails();
  const bookingActor = appointmentCollection.determineOriginalSource();
  const appointmentDate = appointmentCollection.at(0).get('appointmentDate');
  const appointmentTime = appointmentCollection.at(0).get('startTime');
  const appointmentDateTime = `${appointmentDate} ${appointmentTime}`;
  let customerData = customerModel.toJSON();
  if (customerData.walkIn) {
    // If the customer is a walk-in, we use only the name.
    // Other attributes would confuse the backend when saving a new
    // customer (e.g. active: false).
    customerData = {
      id: customerData.id,
      anonymousNote: customerData.anonymousNote,
      walkIn: customerData.walkIn,
    };
  }
  return {
    appointmentDateTime,
    customerData,
    recipientName: appointmentCollection.getFirstRecipientName(),
    mediator,
    isRepeat,
    canEditClient:
      viewContacts &&
      Wahanda.Permissions.editClientContactDetails() &&
      (!customerModel.isArchived() || customerData.walkIn),
    canViewContactDetails: viewContacts,
    canRemoveClient:
      (customerData.walkIn || !appointmentCollection.customerId) &&
      hasEditableAppointments &&
      Wahanda.Permissions.editOwnCalendar(),
    toggleIgnoreKeyboardEvents,
    renderCallback,
    bookingActor,
  };
}

let lastProps;

function renderUsingExistingProps(node) {
  ReactDOM.render(
    <Provider store={store}>
      <InlineClient
        {...lastProps}
        toggleIgnoreKeyboardEvents={() => {}}
        ref={(ref) => {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Component<any, any, any>' is not assignable ... Remove this comment to see the full error message
          inlineClientReusedPropsRef = ref;
        }}
      />
    </Provider>,
    node,
  );
}

function destroyRenderedWithExistingProps(node) {
  ReactDOM.unmountComponentAtNode(node);
  inlineClientReusedPropsRef = null;
}

function render(data, node) {
  const props = prepareProps(data);
  App.on(Wahanda.Event.CUSTOMER_SAVED, customerUpdatedInBackbone);
  App.on(Wahanda.Event.APPOINTMENT_FORM_ERRORS, customerSaveError);

  const {
    appointmentDateTime,
    mediator,
    isRepeat,
    canRemoveClient,
    customerData,
    canViewContactDetails,
    canEditClient,
    recipientName,
    toggleIgnoreKeyboardEvents,
    renderCallback,
    bookingActor,
  } = props;
  store.dispatch(
    initialiseInlineClient({
      ...customerData,
      appointmentDateTime,
      isRepeat,
      canRemoveClient,
      recipientName,
      canViewContactDetails,
      canEditClient,
      bookingActor,
    }),
  );
  lastProps = {
    actions: {
      clientSelected: (clientData) => autocompleteClientChosen(mediator, clientData),
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      resetClient: () => walkinClientRequested(mediator),
      setClientProperties: (clientProps) => {
        store.dispatch(setClientProperties(clientProps));
        // We also need to inform the Backbone views of the changes
        mediator.trigger(mediator.CUSTOMER_SET_WALKIN, {
          anonymousNote: clientProps.name,
        });
      },
    },
    dataSource: ({ query, response }) => {
      getWithPromise(
        App.Api.wsVenueUrl(`/search.json?include=consumers&q=${encodeURIComponent(query)}`),
      )
        .done((apiData) => response(apiData && apiData.consumers ? apiData.consumers : []))
        .fail(() => response([]));
    },
    showClientInfo: showClientDetails(props.mediator),
  };
  ReactDOM.render(
    <Provider store={store}>
      <InlineClient
        {...lastProps}
        ref={(ref) => {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Component<any, any, any>' is not assignable ... Remove this comment to see the full error message
          inlineClientCreationRef = ref;
        }}
        toggleIgnoreKeyboardEvents={toggleIgnoreKeyboardEvents}
        renderCallback={renderCallback}
      />
    </Provider>,
    node,
  );
}

function submit() {
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  inlineClientCreationRef.getWrappedInstance().submit();
}

function submitWithExistingProps() {
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  inlineClientReusedPropsRef.getWrappedInstance().submit();
}

function isValid() {
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  return inlineClientCreationRef.getWrappedInstance().isValid();
}

function destroy(target) {
  App.off(Wahanda.Event.CUSTOMER_SAVED, customerUpdatedInBackbone);
  App.off(Wahanda.Event.APPOINTMENT_FORM_ERRORS, customerSaveError);
  ReactDOM.unmountComponentAtNode(target);
  store.dispatch(clearInlineClient());
  inlineClientCreationRef = null;
}

function getValues() {
  return store.getState().calendar.inlineClientForm;
}

export default {
  render,
  destroy,
  isValid,
  submit,
  submitWithExistingProps,
  getValues,
  renderUsingExistingProps,
  destroyRenderedWithExistingProps,
};
