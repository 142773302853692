import React from 'react';
import Wahanda from 'common/wahanda';
import Dialog from 'components/common/react-dialog';
import { ProgressBar } from 'components/common/ProgressBar';
import { ClientsAnalytics } from '../../tracking';

import style from './ClientsImportInProgress.scss';

const lang = Wahanda.lang.clientsImport.addMultipleClients;

export interface Props {
  onClose: () => void;
  value: number;
  actions: {
    clientsImportReset: () => void;
  };
}

export class ClientsImportInProgress extends React.PureComponent<Props> {
  public static defaultProps = {
    value: 0,
  };

  public render() {
    const { value } = this.props;

    return (
      <Dialog
        dataTest="clients-import-progress-modal"
        title={lang.progressHeader}
        width={400}
        onClose={this.onClose}
        restrictHeight
        noContentPadding
      >
        <div className={style.wrapper}>
          <div className={style.title}>{lang.uploading}</div>
          <ProgressBar value={value} label={`${value!}%`} />
        </div>
      </Dialog>
    );
  }

  private onClose = () => {
    ClientsAnalytics.trackClientsImportCloseClick();
    this.props.onClose();
    this.props.actions.clientsImportReset();
  };
}
