/*
 *  This file contains functions which read and compare event mouse positions.
 *  Which this we check if the mouse has moved or not - so that we know if we
 *  need to react to mouse events  or maybe it's just a after-effect of another
 *  DOM mutation, not involvind the mouse itself.
 */
export function readMousePosition(event) {
  return {
    x: event.pageX,
    y: event.pageY,
  };
}

export function isEqualMousePosition(currentPosition, previousPosition) {
  return currentPosition.x === previousPosition.x && currentPosition.y === previousPosition.y;
}
