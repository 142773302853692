const calculateMaxOffPeak = (offpeakDiscounts) => {
  let maxOffPeakDiscount = 0;

  if (offpeakDiscounts) {
    const { days } = offpeakDiscounts;

    if (days) {
      const keys = Reflect.ownKeys(days);

      if (keys.length) {
        const maxPercentages = keys.map((key) => {
          const day = Reflect.get(days, key);
          const percentages = Object.values(day);

          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
          return Math.max(...percentages);
        });

        maxOffPeakDiscount = Math.max(...maxPercentages);
      }
    }
  }
  return maxOffPeakDiscount;
};

const isActive = (discountRule) =>
  discountRule.active &&
  (discountRule.lastMinuteDiscount.active || discountRule.offpeakDiscounts.active);

const activeAndContainsPurchasableMenuItems = (discountRule) =>
  isActive(discountRule) &&
  discountRule.assignedMenuItems.some((menuItem) => menuItem.purchasable && menuItem.active);

const activeAndPurchasable = (menuItem) => menuItem.purchasable && menuItem.active;

const toEntity = (discountRule) => ({
  id: discountRule.id,
  active: discountRule.active,
  name: discountRule.name,
  assignedMenuItems: discountRule.assignedMenuItems.filter(activeAndPurchasable),
  lastMinuteDiscount: discountRule.lastMinuteDiscount,
  offpeakDiscounts: {
    ...discountRule.offpeakDiscounts,
    max: calculateMaxOffPeak(discountRule.offpeakDiscounts),
  },
});

export default (discountRules) =>
  discountRules.filter(activeAndContainsPurchasableMenuItems).map(toEntity);
