import { trackEvent, ACTIONS } from 'common/analytics';

BackboneEx.View.Dialog2 = (function() {
    var Base = BackboneEx.View.Base;
    var DialogCollection = BackboneEx.View.DialogCollection;

    return Base.extend({
        /**
         * List of buttons to render.
         *
         * Default is to render the "cancel" button.
         *
         * Format:
         * buttons: {
         *   // Default buttons
         *   close: true,
         *   save: true,
         *   // Default menu behind '...' button
         *   menu: {
         *     items: [
         *       {
         *         label: 'Item name',
         *         classes: 'js-other-action'
         *       },
         *       // ...
         *     ]
         *   }
         *   // Custom buttons
         *   someAction: {
         *     title: 'Action',
         *     classes: 'js-do-action',
         *     icon: 'tick2',
         *     onRight: true
         *   }
         * }
         */
        buttons: null,

        /**
         * Title for the dialog. If null, none is rendered.
         *
         * @type string or function
         */
        dialogTitle: null,

        className: 'dialog2--content',

        /**
         * $.ui.dialog default options.
         */
        defaultOptions: {
            modal       : true,
            position    : (Wahanda.Util.isTouchDevice() ? 'top' : 'center'),
            width       : 600,
            height      : 'auto',
            resizable   : false,
            draggable   : true,
            autoOpen    : false,
            // If set then parent is closed when this window is closed (e.g. saving appointment closes appointment view form)
            parentDialog: null,
            dataTest: "",
        },

        rendered: false,
        removeDialogOnCloseEvent: true,
        $dialogContent: null,
        showHeader: true,

        initialize: function(options) {
            this.options = _.extend({}, options, this.defaultOptions, this.options);

            this.options.dialogClass = 'dialog2' + (this.options.dialogClass ? ' ' + this.options.dialogClass : '');

            if (this.buttons && this.buttons.menu) {
                this.bindMenuButtonEvents();
            }
            if (Modernizr.touch) {
                this.setupTouchEvents();
            }
            trackEvent('design-system', ACTIONS.VIEW, 'draggable-dialog', this.options.dataTest);
        },

        bindMenuButtonEvents: function() {
            if (!this.events) {
                this.events = {};
            }
            if (!this.events['click .dialog2--button--menu']) {
                this.events['click .dialog2--button--menu'] = 'toggleMenu';
                // Delegate events is used to rebind the event listeners onto dynamically added menu buttons
                this.delegateEvents();
            }
        },

        render: function() {
            // Make this div a dialog
            this.setupDialog();
            this.setupElementFromTemplate();
            this.rendered = true;
        },

        setupDialog: function() {
            var self = this;
            this.$el.dialog(this.options);
            // Change the $el to dialog main element. This is required for being able to delegate to events from buttons.
            if (!this.$el.hasClass('ui-dialog')) {
                this.$dialogContent = this.$el;
                this.setElement( this.$el.parent() );
            }

            this.renderHeader();
            this.renderFooter();

            this.bindOpenEvent();
            this.bindCloseEvent();
            this.setHeight();
            this.bindCloseChangesCheck();

            // Common actions
            // Run after dialog is visible
            if (true === this.dialogCommand('isOpen')) {
                this.$dialogContent.trigger('dialogopen');
            }

            this.$dialogContent.one( "dialogdragstart", function() {
                trackEvent('design-system', ACTIONS.CLICK, 'draggable-dialog', self.options.dataTest);
            });
        },

        /**
         * This method fixes issues on some Android Touch devices when the Header "X" isn't clickable
         * but dragging starts.
         */
        setupTouchEvents: function() {
            var newEvents = {};

            _.each(this.events, function(selector, method) {
                if ('close' === method && /^click/i.test(selector)) {
                    selector = selector.replace(/^click/i, 'touchstart');
                    newEvents[selector] = method;
                }
            });

            _.extend(this.events, newEvents);
        },

        renderHeader: function() {
            var $titlebar = this.$('.ui-dialog-titlebar');

            if (this.showHeader) {
                html = Wahanda.Template.renderTemplate('dialog2-header-template', {
                    title: _.result(this, 'dialogTitle')
                });
                $titlebar.empty().append(html);
            } else {
                $titlebar.remove();
            }
        },

        renderFooter: function() {
            var $footer   = this.getRenderedFooter();
            var $existing = this.$('.dialog2--footer');
            if ($existing.length > 0) {
                $existing.remove();
            }
            // Add the footer only if it contains something.
            var renderFooter = !$footer.is(':empty');
            if (renderFooter) {
                this.$('.dialog2--content').after($footer);
            }
            this.$el.toggleClass('no-footer', !renderFooter);
        },

        setHeight: function() {
            this.$el.css('max-height', $(window).height() * 0.99);
        },

        setMaxScrollHeight: function (pixelsToSubtract) {
            var headerAndFooterHeight = this.$('.ui-dialog-titlebar').height() +
              this.$('.dialog2--footer').height();

            if (!pixelsToSubtract && 0 !== pixelsToSubtract) {
                pixelsToSubtract = 70;
            }

            this.$('.content-scroll').css(
              'max-height',
              $(window).height() - headerAndFooterHeight - pixelsToSubtract
            );
        },

        /**
         * Set the dialog title.
         *
         * @param String title will be treated as HTML, take care escaping
         */
        setTitle: function(title) {
            this.$('.dialog2--title').html(title);
        },

        isSafariOrIpad: function() {
            var ua = navigator.userAgent.toLowerCase();

            if (/(safari|ipad)/i.test(ua)) {
              if (ua.indexOf('chrome') > -1) {
                return false
              } else {
                return true;
              }
            }
            return false;
        },

        safariMagic: function() {
            /**
             * This is a hack method for safari
             * introduces in Version 11.0.2 (12604.4.7.1.4).
             * We have to add a style, that moves the modal
             * after render or else the modal will not show.
             */
            this.$el.css('transform','rotateX(0)');
        },

        bindOpenEvent: function() {
            this.onDialogEvent('open', function() {
                if (this.isSafariOrIpad()) {
                    this.safariMagic();
                };

                if (this.options.modal) {
                    DialogCollection.addToOpenModalDialogsStack(this);
                    $(".ui-widget-overlay").last().show();
                }
                this.setupResizeHandler();
                this.bindOverlayClose();
            });
        },

        bindCloseEvent: function() {
            this.onDialogEvent('close', function() {
                $(window).off('resize.' + this.cid);

                if (!this.removeDialogOnCloseEvent) {
                    return;
                }
                this._destroyDialog();
            });
        },

        resetCloseChangesCheck: function () {
            this.dialogChanged = false;
        },

        queueCloseChangesCheckWarning: function () {
            this.dialogChanged = true;
        },

        bindCloseChangesCheck: function () {
            this.dialogChanged = false;
            this.$el.on("change", function () {
                this.dialogChanged = true;
            }.bind(this));

            this.onDialogEvent("beforeclose", function () {
                if (this.dialogChanged) {
                	// Remove any previous instances
                    $('.dialog-changes-container').remove();
                    var tmpDiv = $("<div class='dialog-changes-container'></div>");
                    $('body').append(tmpDiv);

                    function removeNode () {
                        App.ES6.Initializers.DialogCloseWithChanges({ node: tmpDiv[0] }).destroy();
                        tmpDiv.remove();
                    }

                    App.ES6.Initializers.DialogCloseWithChanges({
                        node: tmpDiv[0],
                        data: {
                            onClose: removeNode,
                            onConfirm: function () {
                                removeNode();
                                this.resetCloseChangesCheck();
                                this.close();
                            }.bind(this)
                        }
                    }).render();

                    return false;
                }
            }.bind(this));
        },

        _destroyDialog: function() {
            if (this.destruct) {
                this.destruct();
            }
            this.$dialogContent.remove();
            this.remove();
        },

        setupResizeHandler: function() {
            $(window).on(
                'resize.' + this.cid,
                _.debounce(
                    _.bind(this.onWindowResize, this),
                    300
                )
            );
        },

        onWindowResize: function() {
            this.setHeight();
        },

        bindOverlayClose: function () {
            var $overlay = $(".ui-widget-overlay").last();

            // No need to separately unbind after closed, this is handled by jQuery
            $overlay.on(
                "click." + this.cid,
                this.close.bind(this, false)
            );
        },

        /**
         * Open the dialog.
         */
        open: function() {
            if (!this.rendered) {
                this.render();
            }
            this.dialogCommand('open');
        },

        /**
         * Close the dialog.
         *
         * @param boolean closeParent OPTIONAL Should the parent dialog be closed too? Defaults to TRUE.
         */
        close: function(closeParent) {
            this.dialogCommand('close');
            closeParent = (closeParent == null ? true : closeParent);

            if (closeParent && this.options.parentDialog){
                this.options.parentDialog.close();
            }

            if (_.isFunction(this._afterClose)) {
                this._afterClose();
            }
        },

        /**
         * Show the dialog.
         */
        show: function() {
            this.$el.show();
            $('.ui-widget-overlay').show();
        },

        /**
         * Hide the dialog.
         */
        hide: function() {
            this.$el.hide();
            $('.ui-widget-overlay').hide();

            if (_.isFunction(this._afterHide)) {
                this._afterHide();
            }
        },

        onDialogEvent: function(event, callback) {
            this.$dialogContent.on('dialog' + event, _.bind(callback, this));
        },

        /**
         * Forwards the given arguments to $.dialogContent.dialog() function
         */
        dialogCommand: function() {
            return this.$dialogContent.dialog.apply(this.$dialogContent, arguments);
        },

        getDialogRenderedTemplate: function () {
            return Wahanda.Template.get( this._getTemplateId() );
        },

        setupElementFromTemplate: function () {
            this.$dialogContent.html(
                this.getDialogRenderedTemplate()
            );
        },

        loadmask: function() {
            this.$dialogContent.loadmask();
        },

        unloadmask: function() {
            this.$dialogContent.unloadmask();
        },

        /**
         * Reposition the dialog. This is called mostly when inner content changes.
         */
        refreshPosition: function() {
            this.dialogCommand('option', 'position', this.options.position);
        },

        toggleMenu: function(event) {
            if (event.isDefaultPrevented()) {
                return;
            }
            var ANIM_DURATION = 500;
            var $menu  = this.$('.dialog2--button--menu');
            var toOpen = !$menu.hasClass('open');
            if (toOpen) {
                $menu.removeClass('closed');
                _.defer(function() {
                    $menu.addClass('open');
                });

                this.$el.one('click.dialog2menu', function() {
                    $menu.removeClass('open');
                    _.delay(function() {
                        $menu.addClass('closed');
                    }, ANIM_DURATION);
                });
            } else {
                if (!$(event.target).is('.dialog2--button--menu-list--item')) {
                    // No need to close as we desire the full animation.
                    return;
                }
                event.preventDefault();
                this.$el.off('.dialog2menu');
                // Hide the menu quickly, with no animations.
                $menu.addClass('closed').removeClass('open');
            }
        }
    });
})();
