Wahanda.ToastNotifications = {
  sendMessage: (message, options) => {
    new ToastNotification(message, options);
  },
};

/**
 * variant: 'success' | 'danger'
 */
class ToastNotification {
  constructor(message, { variant = 'success', timeout = 3000 } = {}) {
    this.message = message;
    this.variant = variant;
    this.notificationsListElement = document.getElementById('toast-notifications-list');
    this.removeAfterMs = timeout;
    this.animationDelayMs = 600;

    this.init();
  }

  init() {
    this.appendTemplateToDOM();
    this.addVariant();
    this.addIcon();
    this.addEvents();
    this.startAnimationIn();
    this.startRemoveTimer();
  }

  appendTemplateToDOM() {
    const node = document.createElement('div');
    node.innerHTML = this.renderTemplate();
    this.element = this.notificationsListElement.appendChild(node);
  }

  renderTemplate() {
    return Wahanda.Template.renderTemplate('toast-notification-template', {
      message: this.message,
    });
  }

  addClass(className) {
    const toastElement = this.element.querySelector('.ToastNotification');
    toastElement.classList.add(className);
  }

  addVariant() {
    this.addClass(`ToastNotification--${this.variant}`);
  }

  addIcon() {
    const iconElement = this.element.querySelector('.ToastNotification-Icon');
    iconElement.innerHTML = this.getIcon();
  }

  getIcon() {
    return {
      success: `
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.0007 11.5579L13.5293 4.0293L14.4721 4.97211L6.0007 13.4435L1.5293 8.97211L2.47211 8.0293L6.0007 11.5579Z" fill="#11C6CF"/>
        </svg>
      `,
      danger: `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" data-locator-experimental-features="true">
          <circle cx="8" cy="8" r="7" fill="none" stroke="#e04006" stroke-width="2"/>
          <path fill="none" stroke="#e04006" stroke-width="2" d="M8 4v6m0 1v2"/>
        </svg>
      `,
    }[this.variant];
  }

  addEvents() {
    this.element
      .querySelector('.ToastNotification-Close')
      .addEventListener('click', () => this.unmount());
  }

  startRemoveTimer() {
    this.removeTimer = setTimeout(() => this.unmount(), this.removeAfterMs);
  }

  startAnimationIn() {
    // Transition requires first class to be applied and then the second one
    setTimeout(() => {
      this.addClass('ToastNotification_in');
    }, 0);
  }

  startAnimationOut() {
    this.addClass('ToastNotification_out');
  }

  unmount() {
    clearTimeout(this.removeTimer);
    this.startAnimationOut();

    setTimeout(() => {
      this.element.parentNode.removeChild(this.element);
    }, this.animationDelayMs);
  }
}
