import React from 'react';
import Employees from './Employees/container';
import { Pricing } from './Pricing/container';

interface IOfferTreatmentPricingAndEmployeesProps extends React.HTMLAttributes<Element> {
  onPossiblePricingTypeChange: (...args: any[]) => any;
}

export class OfferTreatmentPricingAndEmployees extends React.Component<
  IOfferTreatmentPricingAndEmployeesProps,
  {}
> {
  getValues() {
    return Object.assign(
      {},
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      this.employeesRef.getWrappedInstance().getValues(),
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      this.pricingRef.getWrappedInstance().getValues(),
    );
  }

  employeesRef = null;

  pricingRef = null;

  render() {
    return (
      <div>
        <Employees
          ref={(ref) => {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Component<Pick<IOfferEmployeesProps, "style"... Remove this comment to see the full error message
            this.employeesRef = ref;
          }}
        />
        <Pricing
          // @ts-expect-error ts-migrate(2322) FIXME: Property 'onPossiblePricingTypeChange' does not ex... Remove this comment to see the full error message
          onPossiblePricingTypeChange={this.props.onPossiblePricingTypeChange}
          ref={(ref) => {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Component<Pick<unknown, never> | (Pick<Pick<... Remove this comment to see the full error message
            this.pricingRef = ref;
          }}
        />
      </div>
    );
  }
}
