require('./dialog-collection.js');
require('./dialog2.js');

import { trackEvent, ACTIONS } from 'common/analytics';

;(function() {
    var DialogCollection = BackboneEx.View.DialogCollection;
    /**
     * jQuery UI dialog wrapper View.
     */
    BackboneEx.View.Dialog = BackboneEx.View.Base.extend({
        /**
         * Events to delegate on the dialog's body object.
         */
        events: {},

        /**
         * Options for this dialog.
         */
        defaultOptions: {
            title    : "",
            buttons  : [],
            modal    : true,
            position : (Wahanda.Util.isTouchDevice() ? 'top' : 'center'),
            width    : 300,
            maxWidth: null,
            height   : "auto",
            resizable: false,
            draggable: true,
            autoOpen : false,
            parentDialog: null, // if set then parent is closed when this window is closed (e.g. saving appointment closes appointment view form)
            // Function to prepare content. Is called in render mode.
            onRender: null,
            dataTest: "",
        },

        removeDialogOnCloseEvent: true,
        confirmClosingIfChangesExist: true,

        initialize: function() {
            this.options = _.extend({}, this.defaultOptions, this.options || {});
            if (this.options.maxWidth){
                this.options.width = this.adjustDialogWidth(this.options.maxWidth);
            }
            this.setupElementFromTemplate();
            this.postInitialize();

		    trackEvent('design-system', ACTIONS.VIEW, 'draggable-dialog', this.options.dataTest);
        },

        /**
         * Adjusts dialog's width for multiple screen sizes
         */
        adjustDialogWidth: function(maxWidth) {
            var windowWidth = Wahanda.Util.isTouchDevice() ? window.screen.width : window.innerWidth;
            var smallWindowWidth = 800;
            return windowWidth < smallWindowWidth ? '90%' : maxWidth;
        },

        /**
         * Any actions needed to run after initialization.
         */
        postInitialize: function() {},

        /**
         * Renders the dialog.
         */
        render: function() {
            var self = this;
            $('body').append(this.$el);
            // Set the jQueryu UI dialog instance
            this.$el.dialog(this.options);

            this.bindCloseEvent();
            this.setupDialog.apply(this, arguments);

            // Common actions
            // Run after dialog is visible
            if (true === this.$el.dialog('isOpen')) {
                this.onDialogOpen();
            } else {

                this.$el.one('dialogopen', function() {
                    self.onDialogOpen();
                });
            }

            this.$el.one('dialogdragstart', function() {
                trackEvent('design-system', ACTIONS.CLICK, 'draggable-dialog', self.options.dataTest);
            });
        },

        onDialogOpen: function() {
            var self = this;

            window.setTimeout(function() {
                self.$('.vertically-centered').filter(':visible').center({horizontal: false});

                // Ensure that a dialog never extends past the bottom of the window.
                // It would be nicer to use Dialog's maxHeight property, but it is not honoured
                // when the height property is 'auto'.
                self.$el.css('max-height', $(window).height() + 'px');
            }, 50);

            this.bindOverlayClose();

            if (this.confirmClosingIfChangesExist) {
                this.bindCloseChangesCheck();
            }
        },

        bindOverlayClose: function () {
            var $overlay = $(".ui-widget-overlay").last();

            // No need to separately unbind after closed, this is handled by jQuery
            $overlay.on(
              "click." + this.cid,
              this.close.bind(this, false)
            );
        },

        onDialogEvent: function (event, callback) {
            this.$el.on(
              'dialog' + event,
              callback.bind(this)
            );
        },

        bindCloseChangesCheck: BackboneEx.View.Dialog2.prototype.bindCloseChangesCheck,
        resetCloseChangesCheck: BackboneEx.View.Dialog2.prototype.resetCloseChangesCheck,
        queueCloseChangesCheckWarning: BackboneEx.View.Dialog2.prototype.queueCloseChangesCheckWarning,

        /**
         * Sets up the dialog. Any magic you want on this.$el.
         */
        setupDialog: function() {},

        /**
         * When the dialog is closed, destroy it.
         */
        bindCloseEvent: function() {
            this.$el.on('dialogclose', function () {
                if (_.isFunction(this._afterClose)) {
                    this._afterClose();
                }
                if (this.removeDialogOnCloseEvent) {
                    this._destroyDialog();
                }
            }.bind(this));
        },

        _destroyDialog: function() {
            this.destruct();
            this.trigger('destruct');
            this.$el.remove();
        },

        /**
         * Show the dialog.
         */
        open: function() {
            if (this.options.modal) {
                DialogCollection.addToOpenModalDialogsStack(this);
            }
            this.$el.dialog('open');
        },

        /**
         * Hide the dialog.
         *
         * @param boolean closeParent OPTIONAL Should the parent dialog be closed too? Defaults to TRUE.
         */
        close: function(closeParent) {
            this.$el.dialog('close');
            closeParent = (closeParent == null ? true : closeParent);

            if (closeParent && this.options.parentDialog){
                this.options.parentDialog.close();
            }
        },

        /**
         * Destructor for dialogs.
         *
         * Here you should handle all un-common logic, like destroying jQuery plugin data.
         */
        destruct: function() {},

        /**
         * Disabled any forms on this dialog.
         */
        disableForm: function() {
            this.$el.toggleForm(true);
            if (this.customDisableForm) {
                this.customDisableForm();
            }
        },

        /**
         * Enables previously disabled forms.
         */
        enableForm: function() {
            this.$el.toggleForm(false);
            if (this.customEnableForm) {
                this.customEnableForm();
            }
        },

        /**
         * Set form values from the given hash.
         *
         * @param Object hash
         */
        setFormValues: function(hash) {
            var self = this;
            _.each(hash, function(value, name) {
                var element = self.getFormElement(name);
                if (element.length) {
                    element.is('textarea') ? element.text(value) : element.val(value);
                }
            });
        },

        /**
         * Returns form element with the given name.
         *
         * @param String name
         * @return jQuery
         */
        getFormElement: function(name) {
            return this.$('input, select, textarea').filter('[name="' + name + '"]');
        },

        loadmask: function() {
            this.$('.dialog-content').loadmask();
        },

        unloadmask: function() {
            this.$('.dialog-content').unloadmask();
        },

        setDialogOptions: function(key, value) {
            if (arguments.length === 2) {
                this.$el.dialog('option', key, value);
            } else {
                this.$el.dialog('option', key);
            }
        }
    });

}());
