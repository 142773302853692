interface ActionCreators {
  [actionCreator: string]: (...args: any[]) => any;
}

export type ActionUnionTypes<A extends ActionCreators> = ReturnType<A[keyof A]>;

export interface Action<T extends string> {
  type: T;
}

export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}

export function createAction<T extends string>(type: T): Action<T>;

export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>;

export function createAction<T extends string, P extends object>(type: T, payload?: P) {
  return payload === null ? { type } : { type, payload: { ...(payload as object) } }; // this is fixed in typescript 3.2 https://stackoverflow.com/a/51193091/3410616
}
