import { createAction } from 'reduxStore/actionCreator';

import {
  FBE_BUSINESS_CONFIG_REQUEST,
  FBE_BUSINESS_CONFIG_SUCCESS,
  FBE_BUSINESS_CONFIG_FAILURE,
  FBE_INTEGRATION_COMPLETE,
  FBE_INSTALLATION_SUCCESS,
  FBE_REINTEGRATION_COMPLETE,
} from 'reduxStore/actionsConst';

export interface UpdateFBECallToActionsPayload {
  accessToken: string;
}

export interface FBEBusinessConfigPayload {
  businessConfig: {
    business: {
      name: string;
    };
    ig_cta: {
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
    };
    messenger_menu: {
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
    };
    page_cta: {
      below_button_text: string;
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
    };
    page_post: {
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
      subtitle: string;
      title: string;
    };
  };
}

export const businessConfigRequest = () => createAction(FBE_BUSINESS_CONFIG_REQUEST);

export type BusinessConfigRequestAction = ReturnType<typeof businessConfigRequest>;

export const businessConfigSuccess = (payload: FBEBusinessConfigPayload) =>
  createAction(FBE_BUSINESS_CONFIG_SUCCESS, payload);

export type BusinessConfigSuccessAction = ReturnType<typeof businessConfigSuccess>;

export const businessConfigFailure = () => createAction(FBE_BUSINESS_CONFIG_FAILURE);

export type BusinessConfigFailureAction = ReturnType<typeof businessConfigFailure>;

export const completeFBEIntegration = () => createAction(FBE_INTEGRATION_COMPLETE);

export type CompleteFBEIntegrationAction = ReturnType<typeof completeFBEIntegration>;

export const installFBESuccess = () => createAction(FBE_INSTALLATION_SUCCESS);

export type InstallFBESuccessAction = ReturnType<typeof installFBESuccess>;

export const completeFBEReintegration = () => createAction(FBE_REINTEGRATION_COMPLETE);

export type CompleteFBEReintegrationAction = ReturnType<typeof completeFBEReintegration>;
