import React from 'react';
import AutogrowTextarea from 'react-textarea-autosize';
import style from './style.scss';

const TEXTAREA_COLS = 32;
const TEXTAREA_MAXLENGTH = 160;
interface IReceiptTextareaProps extends React.HTMLAttributes<Element> {
  value?: string;
  onChange: (...args: any[]) => any;
  label: string;
  autoFocus?: boolean;
  placeholder?: string;
}
type ReceiptTextareaState = {
  value?: any;
  length?: any;
};

export default class ReceiptTextarea extends React.Component<
  IReceiptTextareaProps,
  ReceiptTextareaState
> {
  static defaultProps = {
    value: null,
    autoFocus: false,
    placeholder: Wahanda.lang.settings.venue.pos.labels.receiptTextPlaceholder,
  };

  state = { value: this.props.value != null ? this.props.value : '' };

  onChange = (event) => {
    const value = event.target.value;
    this.setState({ value });
    this.props.onChange(value);
  };

  render() {
    const textLength = this.state.value ? this.state.value.length : 0;
    return (
      <div className={style.receiptTextarea}>
        <label>{this.props.label}</label>
        <div className={style.textareaContainer}>
          <AutogrowTextarea
            className={style.textarea}
            value={this.state.value}
            autoFocus={this.props.autoFocus}
            placeholder={this.props.placeholder}
            cols={TEXTAREA_COLS}
            maxLength={TEXTAREA_MAXLENGTH}
            onChange={this.onChange}
          />
          <div className={style.textLengthCounter}>
            {textLength}/{TEXTAREA_MAXLENGTH}
          </div>
        </div>
      </div>
    );
  }
}
