import App from 'common/backbone-app';
import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { submitWebToCaseForm } from 'common/salesforce';
import {
  covidUnlimitOperationSuccess,
  covidUnlimitOperationFailure,
  venueClosingSuccess,
  venueClosingFailure,
  VenueClosingRequestParams,
} from './actions';

export function* covidUnlimitOperationRequested() {
  try {
    yield call(api.post, apiUrl('COVID_UNLIMIT_OPERATION'));
    yield put(covidUnlimitOperationSuccess());
  } catch (err) {
    yield put(covidUnlimitOperationFailure());
  }
}

export function* venueClosingRequested({ payload }: { payload: VenueClosingRequestParams }) {
  try {
    yield call(submitWebToCaseForm, getWebToCasePayload(payload));
    yield put(venueClosingSuccess());
  } catch (err) {
    yield put(venueClosingFailure());
  }
}

function getWebToCasePayload({ employeeName, emailAddress }: VenueClosingRequestParams) {
  const data = {
    name: `${employeeName}`,
    email: `${App.isProd ? '' : 'TEST-REQUEST-'}${emailAddress}`,
    subject: 'Connect Churn Request',
    recordType: '01220000000QxAf',
  };

  return data;
}
