/* eslint-disable no-underscore-dangle */
import React from 'react';
import Wahanda from 'common/wahanda';
import { OnlineBookingWidgetAnalytics as Analytics } from 'common/analytics';
import Alert from 'components/common/dialog/Alert';
import style from './Widget.scss';
import { IntroSection, WidgetSection } from '../Section';

const lang = Wahanda.lang.settings.onlineBooking;
interface IWidgetSettingsProps extends React.HTMLAttributes<Element> {
  venueId: number;
  widgetEnabled?: boolean;
  allowToDisableWidget: boolean;
  defaultLanguage: string;
  availableLanguages: {
    title?: string;
    value: string;
  }[];
  actions: {
    widgetToggleEnabled: (...args: any[]) => any;
    widgetGenerateCode: (...args: any[]) => any;
    widgetSelectLanguage: (...args: any[]) => any;
    widgetClearError: (...args: any[]) => any;
    widgetCleanStore: (...args: any[]) => any;
  };
  widgetSettings: {
    enabled?: boolean;
    currentLanguage?: string;
    fetching?: boolean;
    widgetCode?: string;
    embeddedWidgetCode?: string;
    widgetLink?: string;
    saving?: boolean;
    error?: boolean;
  };
  error?: any;
  currentLanguage?: any;
  widgetGenerateCode?: any;
  widgetSelectLanguage?: any;
  widgetClearError?: any;
  widgetLink?: any;
  widgetToggleEnabled?: any;
  widgetCleanStore?: any;
}
type WidgetSettingsState = {
  copied?: boolean;
};
class WidgetSettings extends React.Component<IWidgetSettingsProps, WidgetSettingsState> {
  containerRef: any;

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'Timeout'.
  copiedTimeout: Timeout;

  onLearnMoreClick: any;

  onTutorialClick: any;

  static defaultProps = {
    widgetEnabled: undefined,
    widgetSettings: {},
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.onPreviewClick = this.onPreviewClick.bind(this);
    this.onDismissError = this.onDismissError.bind(this);
    this.getWidgetCode = this.getWidgetCode.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onWidgetEnabledToggle = this.onWidgetEnabledToggle.bind(this);
    this.onLanguageSelect = this.onLanguageSelect.bind(this);
    this.onTutorialClick = this.onLinkClick.bind(this, 'tutorial');
    this.onLearnMoreClick = this.onLinkClick.bind(this, 'learn-more');
  }

  // @ts-expect-error ts-migrate(2416) FIXME: Type 'string' is not assignable to type 'boolean |... Remove this comment to see the full error message
  state = {
    copied: '',
  };

  componentDidMount() {
    if (this.isWidgetEnabled() && !this.props.widgetSettings.widgetCode) {
      this.getWidgetCode();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.venueId !== nextProps.venueId) {
      this.props.actions.widgetCleanStore();
    }
    if (!this.isWidgetEnabled() && this.isWidgetEnabled(nextProps)) {
      this.getWidgetCode();
    }
  }

  componentWillUnmount() {
    this.props.actions.widgetCleanStore();
  }

  onWidgetEnabledToggle() {
    Analytics.trackWidgetToggleEnabled();
    this.props.actions.widgetToggleEnabled({
      venueId: this.props.venueId,
      enabled: !this.isWidgetEnabled(),
    });
  }

  onPreviewClick(section) {
    Analytics.trackWidgetPreview(section);
    // @ts-expect-error ts-migrate(2551) FIXME: Property 'wahanda' does not exist on type 'Window ... Remove this comment to see the full error message
    if (window.wahanda && section) {
      // @ts-expect-error ts-migrate(2551) FIXME: Property 'wahanda' does not exist on type 'Window ... Remove this comment to see the full error message
      window.wahanda.openOnlineBookingWidget(this.props.widgetSettings.widgetLink);
    }
  }

  onLinkClick(type, section) {
    if (section) {
      Analytics.trackLinkClick(`${section}-${type}`);
    } else {
      Analytics.trackLinkClick(type);
    }
  }

  onDismissError() {
    this.props.actions.widgetClearError();
  }

  onCopy(copied) {
    Analytics.trackWidgetCopied(copied);
    this.setState({ copied });
    clearInterval(this.copiedTimeout);
    this.copiedTimeout = setTimeout(() => {
      this.setState({ copied: false });
    }, 4000);
  }

  onLanguageSelect(selected) {
    this.props.actions.widgetSelectLanguage({ lang: selected });
  }

  getWidgetCode() {
    this.props.actions.widgetGenerateCode({
      locale: this.getCurrentLanguage(),
    });
  }

  getCurrentLanguage() {
    if (this.props.widgetSettings.currentLanguage) {
      return this.props.widgetSettings.currentLanguage;
    }
    return this.props.defaultLanguage;
  }

  isWidgetEnabled(props = this.props) {
    if (props.widgetSettings.enabled !== undefined) {
      return props.widgetSettings.enabled;
    }
    return props.widgetEnabled;
  }

  render() {
    const widgetEnabled = this.isWidgetEnabled();
    const SECTIONS = {
      button: 'widgetCode',
      embed: 'embeddedWidgetCode',
      link: 'widgetLink',
    };
    return (
      <main className={style.container} ref={this.containerRef}>
        {this.props.widgetSettings.error && (
          <Alert
            dataTest="widget-alert-modal"
            title=""
            text={Wahanda.lang.shared.savingFailed}
            onClose={this.onDismissError}
          />
        )}
        <IntroSection
          widgetEnabled={widgetEnabled}
          lang={lang}
          allowToDisableWidget={this.props.allowToDisableWidget}
          widgetSettings={this.props.widgetSettings}
          onWidgetEnabledToggle={this.onWidgetEnabledToggle}
          onLearnMoreClick={this.onLearnMoreClick}
          onPreviewClick={this.onPreviewClick}
        />
        {widgetEnabled &&
          Object.keys(SECTIONS).map((section) => (
            <WidgetSection
              key={section}
              section={section}
              code={this.props.widgetSettings[SECTIONS[section]]}
              canCopy={!this.props.widgetSettings.fetching}
              isCopied={this.state.copied === section}
              currentLanguage={this.getCurrentLanguage()}
              availableLanguages={this.props.availableLanguages}
              onCopy={() => this.onCopy(section)}
              onPreviewClick={this.onPreviewClick}
              onTutorialClick={this.onTutorialClick}
              onLanguageSelect={this.onLanguageSelect}
            />
          ))}
      </main>
    );
  }
}

export default WidgetSettings;
