import { connect } from 'react-redux';
import App from 'common/backbone-app';
import VenueInfoForPrint from './index';

const mapStateToProps = () => ({
  name: App.config.get('venue').name,
  fullName: App.config.get('venue').fullName,
  address: App.config.get('venue').addressLines,
  postCode: App.config.get('venue').postalRef,
  country: App.referenceData
    .get('country')
    .find((country) => country.id === App.config.get('venue').countryCode).title,
});

const connector = connect(mapStateToProps);

export default connector(VenueInfoForPrint);
