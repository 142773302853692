import React from 'react';
import Wahanda from 'common/wahanda';

export const UpgradeWithPromoGraphic = () => (
  <div className="upgrade-overlay visible">
    <div className="content">
      <div className="upgrade-image" />
      <div className="upgrade-copy">
        <span className="title-top">{Wahanda.lang.reports.transactions.upgrade.titleTop}</span>
        <span className="title">
          <span className="title-product">
            {Wahanda.lang.reports.transactions.upgrade.titleProduct}
          </span>
          <span className="title-pricing-level">
            {Wahanda.lang.reports.transactions.upgrade.titlePricingLevel}
          </span>
        </span>
        <span className="body">{Wahanda.lang.reports.transactions.upgrade.body}</span>
        <span
          className="phone-pre"
          dangerouslySetInnerHTML={{
            __html: Wahanda.lang.upgrade.phonePre,
          }}
        />
      </div>
    </div>
  </div>
);
