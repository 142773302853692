// General purpose DOM checking functions

export function isInput(element) {
  return element.tagName === 'INPUT';
}

export function isSelect(element) {
  return element.tagName === 'SELECT';
}

export function isTextarea(element) {
  return element.tagName === 'TEXTAREA';
}

export function isFormInput(element) {
  return isInput(element) || isSelect(element) || isTextarea(element);
}

export function isChildOf(element, parent) {
  return parent.contains(element);
}

export function isParentOf(element, possibleChild) {
  let parent = element;

  while (parent && parent !== possibleChild.parentNode) {
    parent = parent.parentNode;
  }

  return !!parent;
}

// Usag is e.g. `is(element).childOf(otherElement)`
export function is(element) {
  return {
    childOf(parent) {
      return isChildOf(element, parent);
    },

    parentOf(child) {
      return isParentOf(element, child);
    },
  };
}

export function hasMounted(containerEl) {
  return typeof containerEl.getElementsByTagName('div')[0] !== 'undefined';
}
