import React from 'react';
import classnames from 'classnames';
import style from './ModalFooter.scss';

interface ModalFooterProps {
  alignEnd?: boolean;
  children: React.ReactNode;
}

export const ModalFooter = ({ alignEnd = true, children }: ModalFooterProps) => {
  const containerClass = classnames(style.container, {
    [style.alignEnd]: React.Children.count(children) === 1 && alignEnd,
  });
  return <div className={containerClass}>{children}</div>;
};
