import React from 'react';
import classnames from 'classnames';
import { Button } from 'components/common/Button';
import { Plus } from 'components/common/Icon';

import style from './AddCustom.scss';

interface Props {
  labelText: string;
  buttonText: string;
  onButtonClick: (...args: any[]) => any;
  classes?: string;
}

export const AddCustom = ({ labelText, buttonText, classes = '', onButtonClick }: Props) => (
  <div className={classnames(style.container, classes)}>
    <div className={style.label}>{labelText}</div>
    <Button
      label={buttonText}
      onClick={onButtonClick}
      colour="plain"
      size="small"
      icon={<Plus size="small" />}
    />
  </div>
);
