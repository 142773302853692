import React from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';

import { DatePicker } from 'components/common/DatePicker';

import { ShiftsAnalytics } from '../tracking';
import { DAYS_PER_WEEK } from '../utils/constants';

import { ShiftNavigationButtons } from '../ShiftNavigationButtons';

import style from './ShiftsDatePicker.scss';

const LANG = Wahanda.lang.settings.shifts;

interface Props {
  goToWeek: (params) => void;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
}

export class ShiftsDatePicker extends React.Component<Props> {
  private increaseWeek = (date: moment.Moment) => {
    return moment(date).add(DAYS_PER_WEEK, 'days');
  };

  private decreaseWeek = (date: moment.Moment) => {
    return moment(date).subtract(DAYS_PER_WEEK, 'days');
  };

  private goToPrevWeek = () => {
    const { dateFrom, dateTo } = this.props;
    this.props.goToWeek({
      dateFrom: this.decreaseWeek(dateFrom),
      dateTo: this.decreaseWeek(dateTo),
    });
    ShiftsAnalytics.trackDatePickerPrevClick();
  };

  private goToNextWeek = () => {
    const { dateFrom, dateTo } = this.props;
    this.props.goToWeek({
      dateFrom: this.increaseWeek(dateFrom),
      dateTo: this.increaseWeek(dateTo),
    });
    ShiftsAnalytics.trackDatePickerNextClick();
  };

  private goToCurrentWeek = () => {
    this.props.goToWeek({
      dateFrom: moment().startOf('isoWeek'),
      dateTo: moment().endOf('isoWeek'),
    });
    ShiftsAnalytics.trackDatePickerCurrentClick();
  };

  private onDatePickerChange = (dateFrom, dateTo) => {
    if (this.props.dateFrom !== dateFrom) {
      this.props.goToWeek({ dateFrom, dateTo });
      ShiftsAnalytics.trackDatePickerChangeClick();
    }
  };

  private startDateOffset = (day) => {
    return day.startOf('isoWeek');
  };

  private endDateOffset = (day) => {
    return day.endOf('isoWeek');
  };

  public render() {
    const showGoToThis =
      this.props.dateFrom.formatApiDateString() !==
      moment().startOf('isoWeek').formatApiDateString();

    return (
      <div className={style.pickerActions}>
        <div className={style.datePicker}>
          <DatePicker
            onChange={this.onDatePickerChange}
            uniqueId="shifts"
            initialStartDate={this.props.dateFrom}
            initialEndDate={this.props.dateTo}
            startDateOffset={this.startDateOffset}
            endDateOffset={this.endDateOffset}
            readOnly
          />
        </div>
        <ShiftNavigationButtons
          showGoToThis={showGoToThis}
          buttonText={LANG.goToThisWeek}
          onGoToNext={this.goToNextWeek}
          onGoToPrev={this.goToPrevWeek}
          onGoToThis={this.goToCurrentWeek}
        />
      </div>
    );
  }
}
