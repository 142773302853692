import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { OfferPricing as Component } from './index';
import * as actions from '../../actions';

const mapStateToProps = ({ menu: { menuOffer } }) =>
  Object.assign(
    {},
    {
      cleanupTime: menuOffer.offerDialogCleanupTime,
      processingTimeMins: menuOffer.offerDialogProcessingTimeMins,
      useDefaultValues: menuOffer.offerDialogUseDefaultPricingValues,
      allowProcessing: menuOffer.offerDialogAllowProcessing,
      skus: menuOffer.offerDialogSkus,
      offerName: menuOffer.offerDialogOfferName,
      readonly: menuOffer.offerDialogReadonly,
      readonlyLight: menuOffer.offerDialogReadonlyLight,
      employeeCategoriesCollection: menuOffer.employeeCategoriesCollection,
      selectedEmployees: menuOffer.allEmployees.filter((employee) =>
        menuOffer.offerDialogEmployees.includes(employee.id),
      ),
      useEmployeeCategoryPricing: menuOffer.offerDialogUseEmployeeCategoryPricing,
      enclosedByPackage: menuOffer.offerDialogEnclosedByPackage,
      escapeWithSkuPricing: menuOffer.offerDialogEscapeWithSkuPricing,
      useProcessingTime: menuOffer.offerDialogUseProcessingTime,
    },
  );

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const Pricing = connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(Component as any);
