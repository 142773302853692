import React from 'react';
import classnames from 'classnames';
import Rating from 'react-components/Rating';
import style from './style.scss';

type ReviewDetailsRowProps = {
  classes?: string;
  name: string;
  rating: number;
};
const ReviewDetailsRow: React.SFC<ReviewDetailsRowProps> = (props) => {
  const { classes, name, rating } = props;
  return (
    <div className={classnames(style.component, classes)}>
      <p>{name}</p>
      <Rating rating={rating} size="small" />
    </div>
  );
};

export default ReviewDetailsRow;
