import React from 'react';
import { Button } from 'components/common/Button';
import { Copy, Tick } from 'components/common/Icon';
import style from './CopyButton.scss';

interface ICopyButtonProps extends React.HTMLAttributes<Element> {
  copyText: string;
  copiedText: string;
  copy?: string;
  isCopied: boolean;
  onClick?: (...args: any[]) => any;
  disabled?: boolean;
}

export class CopyButton extends React.Component<ICopyButtonProps, {}> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'copyNode' has no initializer and is not ... Remove this comment to see the full error message
  private copyNode: HTMLTextAreaElement | null;

  static defaultProps = {
    onClick: null,
    copy: '',
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (!this.props.copy) {
      return;
    }
    this.copyNode?.select();
    document.execCommand('copy');
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  }

  render() {
    const { isCopied, copyText, copiedText, copy, disabled } = this.props;
    return (
      <div>
        <Button
          disabled={disabled}
          onClick={this.onClick}
          label={isCopied ? copiedText : copyText}
          icon={isCopied ? <Tick /> : <Copy size="small" />}
        />
        <textarea
          className={style.copyNode}
          ref={(node) => {
            this.copyNode = node;
          }}
          value={copy}
          readOnly
        />
      </div>
    );
  }
}
