/* global BackboneEx */
(() => {
  const lang = Wahanda.lang.menu.enclosedService;
  const Dialog = BackboneEx.View.Dialog2.extend(
    {
      dialogTitle: lang.title,
      buttons: {
        ok: {
          title: lang.closeText,
          direction: 'right',
          classes: 'dialog2--button dialog2--button-right dialog2--button-green js-close',
        },
      },
      options: {
        width: 480,
        dialogClass: 'dialog2--list',
        dataTest: 'menu-enclosed-offer-change-notification-modal',
      },
      events: {
        'click .js-close': 'close',
      },
      lang,

      render() {
        this.setupDialog();
        this.rendered = true;

        this.$dialogContent.html(
          Wahanda.Template.render(this.getTemplateString(), {
            packages: this.options.packageList,
          }),
        );
      },

      getTemplateString() {
        return (
          `<div>${this.lang.topLine}</div>` +
          `<ul class="simple-list">` +
          `{{#packages}}<li>{{name}}</li>{{/packages}}` +
          `</ul>` +
          `<div>${this.lang.bottomLine}</div>`
        );
      },
    },
    {
      open(packageList) {
        const dialog = new Dialog({
          packageList,
        });

        dialog.open();
      },
    },
  );

  App.Views.Dialog.MenuEnclosedOfferChangeNotification = Dialog;
})();
