import { delay } from 'redux-saga';

import { call, put, all } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import {
  FBEIntegrationAssetsPayload,
  integrationAssetsSuccess,
  integrationAssetsFailure,
} from '../actions';
import { fbeIntegrationAssetsRequested } from '../sagas';
import {
  businessConfigSuccess,
  businessConfigFailure,
  FBEBusinessConfigPayload,
  installFBESuccess,
} from './actions';

import { FacebookBusinessExtensionAnalytics as Analytics } from './tracking';

export const FBE_POST_INTEGRATION_DELAY = 4000;

export function* businessConfigFBERequested() {
  try {
    const response: FBEBusinessConfigPayload = yield call(api.get, apiUrl('FBE_BUSINESS_CONFIG'));
    yield put(businessConfigSuccess(response));
  } catch (responseError) {
    Analytics.trackFBEFailed();
    yield put(businessConfigFailure());
  }
}

export function* integrationFBECompleted() {
  Analytics.trackFBESuccess();
  yield all([call(delay, FBE_POST_INTEGRATION_DELAY), call(fbeIntegrationAssetsRequested)]);
  yield put(installFBESuccess());
}

export function* reintegrationFBECompleted() {
  try {
    const response: FBEIntegrationAssetsPayload = yield call(
      api.get,
      apiUrl('FBE_INTEGRATION_ASSETS'),
    );
    if (response.instagramBusinessId) {
      Analytics.trackFBEReintegrationSuccess();
    } else {
      Analytics.trackFBEReintegrationFailure();
    }
    yield put(integrationAssetsSuccess(response));
    yield put(installFBESuccess());
  } catch (responseError) {
    Analytics.trackFBEReintegrationFailure();
    integrationAssetsFailure();
  }
}
