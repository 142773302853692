/* global BackboneEx */
/* global google */
/* global _ */
/* eslint func-names:0 */
import {
  STATUS_BAD_DEBT,
  STATUS_BAD_DEBT_PENDING,
  STATUS_LIABILITY,
  STATUS_UNRECEIVED,
} from 'common/remittance';
import { trackEvent } from 'common/analytics';
import URI from 'urijs';

/**
 * Supplier payments view.
 */
App.Views.Settings.SupplierPayments = BackboneEx.View.Base.extend({
  events: {
    'click .link-download': 'pdfDownload',
  },

  initialize: function () {
    const self = this;

    this.remittancesCollection = new App.Collections.Remittances();
    this.financeStats = new App.Models.FinanceStats();

    const models = [this.financeStats, this.remittancesCollection];
    this.fetchAndRender = function (quietFetch) {
      self.$el.loadmask();
      if (App.config.get('venue').supplierId > 0) {
        BackboneEx.Tool.MultiModelFetch.run(
          models,
          function () {
            self.removeAttention(quietFetch);
            self.render();
          },
          _.once(function (model, jqXHR) {
            let errorData = {};
            try {
              errorData = JSON.parse(jqXHR.responseText);
            } catch (e) {
              console.warn(e);
            }
            self.setErrorView(errorData);
          }),
        );
      } else {
        self.remittancesCollection.reset();
        self.removeAttention(quietFetch);
        self.render();
      }
    };
    self.on('visible', this.fetchAndRender);

    App.on(Wahanda.Event.APP_VENUE_CHANGED, this.fetchAndRender);

    $(window).on('resize', $.proxy(this.toggleTableTick, this));
  },

  pdfDownload: function () {
    trackEvent('finance-remittance-list', 'open', 'pdf');
  },

  tooltipHandler: function () {
    const self = this;
    this.$('.status-wrapper').on('hover', function () {
      const toolTipPosition = $(this).position();
      const tablePosition = $('.content-table-body table').offset().top - self.initialTablePosition;
      const topPosition =
        toolTipPosition.top - $('.hint-tooltip-note').height() / 2 - tablePosition;
      $('.hint-tooltip-wrapper').css('top', topPosition);
      if ($(this).hasClass('paid')) {
        $('.hint-tooltip-wrapper').css(
          'left',
          toolTipPosition.left - $('.hint-tooltip-wrapper.paid').width() - 9,
        );
        $('.hint-tooltip-wrapper.paid').show();
      } else if ($(this).hasClass('payment-due')) {
        $('.hint-tooltip-wrapper').css(
          'left',
          toolTipPosition.left - $('.hint-tooltip-wrapper.payment-due').width() - 9,
        );
        $('.hint-tooltip-wrapper.payment-due').show();
      } else if ($(this).hasClass('issued')) {
        $('.hint-tooltip-wrapper').css(
          'left',
          toolTipPosition.left - $('.hint-tooltip-wrapper.issued').width() - 9,
        );
        $('.hint-tooltip-wrapper.issued').show();
      } else if ($(this).hasClass('issued-for-payment')) {
        $('.hint-tooltip-wrapper').css(
          'left',
          toolTipPosition.left - $('.hint-tooltip-wrapper.issued-for-payment').width() - 9,
        );
        $('.hint-tooltip-wrapper.issued-for-payment').show();
      } else if ($(this).hasClass('received')) {
        $('.hint-tooltip-wrapper').css(
          'left',
          toolTipPosition.left - $('.hint-tooltip-wrapper.received').width() - 9,
        );

        $('.hint-tooltip-wrapper').find('.payment-details').wToggle($(this).data('payment-date'));
        if ($(this).data('payment-date')) {
          $('.hint-tooltip-wrapper').find('.payment-date').html($(this).data('payment-date'));
          if ($(this).data('payer-name')) {
            $('.hint-tooltip-wrapper').find('.payer-name').html($(this).data('payer-name'));
            $('.hint-tooltip-wrapper').find('.payer-email').html($(this).data('payer-email'));
            $('.hint-tooltip-wrapper').find('.payer-email-row').show();
          } else {
            $('.hint-tooltip-wrapper').find('.payer-name').html($(this).data('payer-email'));
            $('.hint-tooltip-wrapper').find('.payer-email-row').hide();
          }
        }

        $('.hint-tooltip-wrapper.received').show();
      } else if ($(this).hasClass('unpaid')) {
        $('.hint-tooltip-wrapper').css(
          'left',
          toolTipPosition.left - $('.hint-tooltip-wrapper.unpaid').width() - 9,
        );
        $('.hint-tooltip-wrapper.unpaid').show();
      }
    });
    this.$('.status-wrapper').on('mouseleave', function () {
      $('.hint-tooltip-wrapper').hide();
    });
  },

  removeAttention(quietFetch) {
    const attentionContainer = this.$('.unpaid-balance-note')[0];

    if (attentionContainer) {
      if (quietFetch) {
        $(attentionContainer).find('.dialog-root').css('visibility', 'visible');
        $(attentionContainer).css({
          visibility: 'hidden',
          height: 0,
        });
        App.trigger('checkout:payment-success');
        return;
      }
      App.ES6.Initializers.Notice({ node: attentionContainer }).destroy();
    }
  },

  /**
   * Renders the whole settings view.
   */
  render: function () {
    const self = this;

    if (this.remittancesCollection.length === 0) {
      this.setEmptyView(true);
      this.$('.payment-summary').wHide();
      this.$el.unloadmask();
      return;
    }

    this.$('.payment-summary').wShow();

    const totalPayment = this.$('.payment-summary .first');
    const visualisation = this.$('.payment-summary .second');
    const paymentsChart = visualisation.find('.chart');
    const months = self.financeStats.get('months');

    paymentsChart.empty();

    if (this.financeStats.get('totalEarnings') > 0) {
      totalPayment
        .find('.amount')
        .html(Wahanda.Currency.format(this.financeStats.get('totalEarnings')));
      totalPayment.wShow();
      this.$('.content-table-wrapper').removeClass('no-summary');
      this.$('.payment-summary').removeClass('no-summary');

      if (months && months.length > 0) {
        this.$('.separator').wShow();

        // If visualization api is already loaded, callback is immediately called.
        google.load('visualization', '1', {
          packages: ['corechart'],
          callback: renderChart,
        });
      }
    } else {
      totalPayment.wHide();
      this.$('.separator').wHide();
      visualisation.wHide();
      this.$('.content-table-wrapper').addClass('no-summary');
      this.$('.payment-summary').addClass('no-summary');
    }

    // Currently disabled as the UI presentation can confuse the supplier.
    // this.renderNextPaymentDate();
    this.renderTable();
    this.renderPaymentNote();

    this.$el.unloadmask();

    this.initialTablePosition = $('.content-table-body table').offset().top;

    function renderChart() {
      const currencySymbol = App.config.get('currency').symbol;
      const chartData = new google.visualization.DataTable();
      const currencyFormatter = new google.visualization.NumberFormat({
        fractionDigits: 2,
        prefix: currencySymbol,
      });

      chartData.addColumn({
        type: 'string',
        label: paymentsChart.data('x-axis-label'),
      });
      chartData.addColumn({
        type: 'number',
        label: paymentsChart.data('y-axis-label'),
      });

      chartData.addRows(
        _.map(months, function (month) {
          return [
            Wahanda.Date.formatDate('M yy', Wahanda.Date.parse(`${month.month}T00:00:00Z`)),
            month.earnings,
          ];
        }),
      );

      currencyFormatter.format(chartData, 1);

      visualisation.wShow();

      new google.visualization.LineChart(paymentsChart[0]).draw(chartData, {
        width: visualisation.width(),
        height: visualisation.height(),
        legend: { position: 'none' },
        pointSize: 4,
        hAxis: {
          showTextEvery: 2,
        },
        vAxis: {
          format: `${currencySymbol}#,###`,
          minValue: 0,
        },
      });
    }
  },

  renderNextPaymentDate: function () {
    const nextPayment = this.$('.payment-summary .second');
    const dayDiff = this.remittancesCollection.getNextPayDate();
    let nextPaymentText;
    let longPendingText = false;
    if (dayDiff === 0) {
      nextPaymentText = Wahanda.lang.date.today;
    } else if (dayDiff === 1) {
      nextPaymentText = Wahanda.lang.date.tomorrow;
    } else if (dayDiff > 1) {
      longPendingText = true;
      nextPaymentText = Wahanda.lang.datetime.distance_in_words.x_days.other;
      nextPaymentText = nextPaymentText.replace('{{count}}', dayDiff);
    }

    this.$('.separator').wToggle(nextPaymentText && this.financeStats.get('totalEarnings') > 0);

    const nextPaymenthtml = Wahanda.Template.renderTemplate('next-payment', {
      nextPaymentText: nextPaymentText,
      longPendingText: longPendingText,
    });

    nextPayment.html(nextPaymenthtml);
    if (nextPaymentText) {
      nextPayment.wShow();
    }
  },

  isDirectDebitEnabled: function () {
    const hasDirectDebitEnabled = this.model.get('hasDirectDebitEnabled') || false;

    return hasDirectDebitEnabled;
  },

  renderPaymentNote: function renderPaymentNote() {
    if (this.isDirectDebitEnabled()) {
      return;
    }

    const lastRemittance = this.remittancesCollection.models.find(
      (remittance) => remittance.isPast() && remittance.get('closingBalance'),
    );

    if (!lastRemittance) {
      return;
    }

    function getClosingBalance() {
      const badRemittanceStatuses = [
        STATUS_BAD_DEBT,
        STATUS_BAD_DEBT_PENDING,
        STATUS_LIABILITY,
        STATUS_UNRECEIVED,
      ];
      if (
        lastRemittance.get('closingBalance') > 0 ||
        (lastRemittance.get('closingBalance') < 0 &&
          badRemittanceStatuses.indexOf(lastRemittance.get('statusCode')) === -1)
      ) {
        return 0;
      }
      return lastRemittance.get('closingBalance') * -1;
    }

    const closingBalance = getClosingBalance();
    const supplierId = App.getSupplierId();
    const paymentReference = lastRemittance.get('reference');
    const reFetchRemittances = this.fetchAndRender;
    const paymentDueDays = App.config.get('paymentPeriodDays');
    // Native new URL not supported for IE11
    const windowUrl = new URI(window.location.href);
    const payNow = windowUrl.query(true).payNow;

    if (payNow) {
      if (windowUrl.query(true).source === 'email') {
        // Track that the paynow was opened from email.
        trackEvent('finance-payments', 'view', 'payment-form-from-email');
      }

      windowUrl.removeSearch('payNow');
      windowUrl.removeSearch('source');

      if (window.history.pushState) {
        window.history.pushState({}, document.title, windowUrl.toString());
      }
    }

    this.invalidCategoriesWarningInstance = App.ES6.Initializers.PaymentMessage.render(
      {
        closingBalance,
        supplierId,
        paymentReference,
        reFetchRemittances,
        paymentDueDays,
        payNow,
      },
      this.$('.unpaid-balance-note')[0],
    );
  },

  renderTable: function () {
    let tablehtml;
    const remittances = [];

    this.remittancesCollection.each(function (remittance) {
      if (!remittance.isPast()) {
        // Do not show remittances that have not ended yet
        return;
      }

      remittances.push({
        reference: remittance.get('reference'),
        pending: remittance.isPending(),
        date: remittance.getFormattedDates(),
        amount: Wahanda.Currency.format(remittance.get('totalAmount') * -1),
        closingBalance:
          remittance.get('closingBalance') !== null
            ? Wahanda.Currency.format(remittance.get('closingBalance') * -1)
            : null,
        items: remittance.get('itemCount'),
        status: remittance.getStatus(),
        pdfUri: remittance.getPdf(),
        csvZipUri: remittance.getCsvZip(),
        xlsUri: remittance.getXls(),
        paymentDetails: remittance.getPaymentDetails(),
      });
    });

    if (remittances.length > 0) {
      tablehtml = Wahanda.Template.renderTemplate('remittance-item', {
        remittances: remittances,
      });

      this.$('.content-table-body table').html(tablehtml);
      this.$('.content-note').wHide();
      this.$('.payment-summary').wShow();
      this.$('.content-table-wrapper').wShow();

      this.$('.content-table-body').shadows().find('table').html(tablehtml);
      this.tooltipHandler();
      this.toggleTableTick();
      this.setEmptyView(false);
    } else {
      this.setEmptyView(true);
    }
  },

  setEmptyView: function (isEmpty, errorTextType) {
    const errorClass = `${errorTextType || 'default'}-text`;
    this.$('.content-table-wrapper, .payment-summary').wToggle(!isEmpty);
    this.$('.content-note').wToggle(isEmpty);

    if (isEmpty) {
      this.$('.content-note').find('.second-txt').wHide().filter(`.${errorClass}`).wShow();
    } else {
      this.$('.content-note').wHide();
    }
  },

  setErrorView: function (errorData) {
    let errorType = '';

    if (
      errorData &&
      errorData.errors &&
      errorData.errors[0] &&
      errorData.errors[0].name === 'supplier-permission-denied'
    ) {
      errorType = 'no-supplier-error';
    }

    this.setEmptyView(true, errorType);
  },

  toggleTableTick: function () {
    this.$('.content-table-wrapper').tableAlign();
  },
});
