import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Finance extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-finance', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M15,7.002 L9.413,7 L4,12.414 L4,13 L2,13 L2,7.003 C2,5.899 2.89,5 3.993,5 L17.003,5 L15,5 L15,7.002 Z M21,9.002 L21,15.998 C21.0010618,16.5282489 20.7913022,17.0371729 20.416922,17.4126785 C20.0425418,17.7881841 19.5342497,17.9994703 19.004,18 L9,18 L9,16 L13.586,16 L18.586,11 L21,11 L21,9.001 L21,9.002 Z M19,13.414 L16.414,16 L19.001,16 L19.001,13.414 L19,13.414 Z M6.585,7 L3.998,7 L4,9.587 L6.585,7 Z M20.585,4 L18,4 L18,2 L24,2 L24,8 L22,8 L22,5.414 L18.414,9 L17,7.586 L20.586,4 L20.585,4 Z M11.5,13 C12,13 12.5,12.399 12.5,11.5 C12.5,10.601 12,10 11.5,10 C11,10 10.5,10.601 10.5,11.5 C10.5,12.399 11,13 11.5,13 Z M11.5,15 C9.756,15 8.5,13.372 8.5,11.5 C8.5,9.628 9.756,8 11.5,8 C13.244,8 14.5,9.628 14.5,11.5 C14.5,13.372 13.244,15 11.5,15 Z M2,18.586 L5.586,15 L7,16.414 L3.414,20 L6,20 L6,22 L0,22 L0,16 L2,16 L2,18.586 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Finance);
