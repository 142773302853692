import React from 'react';
import { History } from 'history';
import * as pathToRegexp from 'path-to-regexp';
import { ClientAcquisitionAnalytics } from 'common/analytics';
import { allEmployeesOptionValue } from 'components/reports/newSalesReports/EmployeeFilter';
import { PrintButton } from 'components/shared/PrintButton';
import TimeStamp from 'components/reports/newSalesReports/common/TimeStamp';
import Wahanda from 'common/wahanda';
import _ from 'common/underscore';
import moment from 'common/moment';
import Loader from 'components/common/Loader';
import { CLIENT_ROUTES } from 'components/reports/newSalesReports/SalesReportContainer/reportRoutes';
import Filters, { PossibleFilters } from 'components/reports/newSalesReports/common/Filters';
import { GoTo, Direction } from 'components/common/GoTo';
import { Notice } from 'components/common/Notice';
import BigSelectDropdown from 'components/reports/BigSelectDropdown';

import DISPLAY from 'components/reports/newSalesReports/displayStateEnum';
import VenueInfoForPrint from 'components/reports/POSReports/VenueInfoForPrint/container';
import ClientAcquisitionTable from './ClientAcquisitionTable';
import ClientAcquisitionGraph from './ClientAcquisitionGraph';
import { TIME_PERIOD_FILTERS, CLIENT_REPORT_DROPDOWN_VALUES } from '../timePeriodFilters';
import style from './style.scss';

interface RequestData {
  fromDate?: string;
  toDate?: string;
  selectedEmployeeId?: number;
}

interface MonthReportData {
  month: string;
  directFirstTimeClients: number;
  widgetFirstTimeClients: number;
  treatwellFirstTimeClients: number;
  totalFirstTimeClients: number;
}

interface ReportData {
  months: MonthReportData[];
}

interface Props {
  displayState: symbol;
  reportData?: ReportData;
  history: History;
  match: {
    params: {
      venueId: number;
      timePeriod?: string;
      selectedEmployeeId?: string;
    };
  };
  actions: {
    requestAcquisitionReportDataAction: (requestData: RequestData) => void;
  };
}

export class ClientAcquisitionReport extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onReportDropdownChange = this.onReportDropdownChange.bind(this);
    this.requestData = this.requestData.bind(this);
    this.getQueryParams = this.getQueryParams.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  public componentDidMount() {
    ClientAcquisitionAnalytics.trackPageView();

    this.requestData();
  }

  public componentDidUpdate(prevProps: Props) {
    const { params } = this.props.match;

    if (!_.isEqual(params, prevProps.match.params)) {
      this.requestData();
    }
  }

  public render() {
    const dropDownProps = {
      data: [
        CLIENT_REPORT_DROPDOWN_VALUES.CLIENT_ACQUISITION,
        CLIENT_REPORT_DROPDOWN_VALUES.CLIENT_RETENTION,
      ],
      selected: CLIENT_REPORT_DROPDOWN_VALUES.CLIENT_ACQUISITION.value,
      onSelect: this.onReportDropdownChange,
    };
    const filterProps = {
      onEmployeeChange: ClientAcquisitionAnalytics.trackEmployeeFilterChange,
      onFilterChange: this.onFilterChange,
      onPeriodChange: ClientAcquisitionAnalytics.trackDatePeriodFilterChange,
      employee: {
        selectedEmployeeId: this.getQueryParams().selectedEmployeeId,
      },
      period: {
        selected: this.getQueryParams().timePeriod,
        data: [
          {
            ...TIME_PERIOD_FILTERS.LAST_TWELVE_MONTHS,
          },
          {
            ...TIME_PERIOD_FILTERS.LAST_YEAR,
          },
          {
            ...TIME_PERIOD_FILTERS.THIS_YEAR,
          },
          {
            ...TIME_PERIOD_FILTERS.ALL_TIME,
          },
        ],
      },
    };

    return (
      <div className={style.report}>
        <VenueInfoForPrint />
        <GoTo className={style.backLink} href="/" direction={Direction.backward} dataTest="goto">
          {Wahanda.lang.reports.tabs.reports}
        </GoTo>
        <div className={style.actions}>
          <BigSelectDropdown {...dropDownProps} dataTest="client-report-switch" />
          <PrintButton />
        </div>
        <div className={style.filters}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: Type '{ selected: number; data: ({ value: number; ... Remove this comment to see the full error message */}
          <Filters {...filterProps} />
          <TimeStamp date={moment()} />
        </div>
        <Notice type="info">{Wahanda.lang.reports.clientReports.dataExclamation}</Notice>
        {this.renderContent()}
      </div>
    );
  }

  private renderContent() {
    const { reportData, displayState } = this.props;
    if (displayState === DISPLAY.WAITING) {
      return <Loader />;
    }

    if (reportData) {
      return (
        <div className={style.reportBody} data-test="report-body">
          <ClientAcquisitionGraph months={reportData.months} />
          <ClientAcquisitionTable months={reportData.months} />
        </div>
      );
    }
    return null;
  }

  private getQueryParams() {
    const defaults = _.defaults(this.props.match.params, {
      selectedEmployeeId: allEmployeesOptionValue,
      timePeriod: TIME_PERIOD_FILTERS.LAST_TWELVE_MONTHS.value,
    });
    return {
      venueId: defaults.venueId,
      timePeriod: Number(defaults.timePeriod),
      selectedEmployeeId: Number(defaults.selectedEmployeeId),
    };
  }

  private getTimePeriodValues(timePeriod: number) {
    switch (timePeriod) {
      case TIME_PERIOD_FILTERS.LAST_TWELVE_MONTHS.value:
        return TIME_PERIOD_FILTERS.LAST_TWELVE_MONTHS.range;
      case TIME_PERIOD_FILTERS.ALL_TIME.value:
        return TIME_PERIOD_FILTERS.ALL_TIME.range;
      case TIME_PERIOD_FILTERS.LAST_YEAR.value:
        return TIME_PERIOD_FILTERS.LAST_YEAR.range;
      case TIME_PERIOD_FILTERS.THIS_YEAR.value:
      default:
        return TIME_PERIOD_FILTERS.THIS_YEAR.range;
    }
  }

  private requestData() {
    const { requestAcquisitionReportDataAction } = this.props.actions;
    const { selectedEmployeeId, timePeriod } = this.getQueryParams();
    requestAcquisitionReportDataAction({
      selectedEmployeeId:
        selectedEmployeeId === allEmployeesOptionValue ? undefined : selectedEmployeeId,
      fromDate: this.getTimePeriodValues(timePeriod).fromDate,
      toDate: this.getTimePeriodValues(timePeriod).toDate,
    });
  }

  private onReportDropdownChange(selected: number) {
    const { push } = this.props.history;
    const { venueId } = this.props.match.params;
    switch (selected) {
      case 1:
        push(
          pathToRegexp.compile(CLIENT_ROUTES.ACQUISITION_REPORT)({
            venueId,
            ...CLIENT_REPORT_DROPDOWN_VALUES.CLIENT_ACQUISITION.defaultFilters,
          }),
        );
        break;
      case 2:
        push(
          pathToRegexp.compile(CLIENT_ROUTES.RETENTION_REPORT)({
            venueId,
            ...CLIENT_REPORT_DROPDOWN_VALUES.CLIENT_RETENTION.defaultFilters,
          }),
        );
        break;
      default:
        break;
    }
  }

  private onFilterChange(filters: PossibleFilters) {
    const { push } = this.props.history;
    const { timePeriod, selectedEmployeeId } = filters;
    const toPath = pathToRegexp.compile(CLIENT_ROUTES.ACQUISITION_REPORT);
    const path = toPath(_.defaults({ timePeriod, selectedEmployeeId }, this.getQueryParams()));
    push(path);
  }
}
