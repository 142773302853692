/* eslint react/prefer-stateless-function: 0 */
/* eslint arrow-body-style: 0 */
import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import {
  Table,
  TableColumnHeader,
  TableRow,
  TableBody,
  TableHeader,
} from 'components/common/Table';
import { TransactionsReportTracking } from 'components/reports/transactions/tracking';
import style from './style.scss';
import Transaction from './Transaction';
import { TRANSACTION_TYPE, TYPE } from './Transaction/types';

const lang = {
  headings: Wahanda.lang.reports.transactions.table.headings,
  payments: Wahanda.lang.reports.transactions.table.payments,
  badges: Wahanda.lang.reports.transactions.table.badges,
};
interface ITransactionReportProps extends React.HTMLAttributes<Element> {
  transactions?: {
    payments?: {
      type: string;
      amount: number;
    }[];
    lineItems?: {}[];
    time: string;
    transactionId?: string;
    referenceTransactionId?: string;
    amount: number;
    description?: string;
    notes?: string;
    created: string;
  }[];
  openDialog: (...args: any[]) => any;
  advancedView?: boolean;
}
class TransactionReport extends PureComponent<ITransactionReportProps, {}> {
  static defaultProps = {
    transactions: [],
    advancedView: false,
  };

  componentDidMount = () => {
    TransactionsReportTracking.trackTableView(this.props.advancedView);
  };

  isWithdrawal = (venueTransactionType) => {
    return (
      [
        TRANSACTION_TYPE.CARD_TIP_WITHDRAWAL,
        TRANSACTION_TYPE.VOUCHER_TIP_WITHDRAWAL,
        TRANSACTION_TYPE.WITHDRAWAL,
        TRANSACTION_TYPE.CASH_REGISTER_CORRECTION_WITHDRAWAL,
      ].indexOf(venueTransactionType) !== -1
    );
  };

  isDeposit = (venueTransactionType) => {
    return (
      [
        TRANSACTION_TYPE.DEPOSIT,
        TRANSACTION_TYPE.CASH_REGISTER_CORRECTION_DEPOSIT,
        TRANSACTION_TYPE.REFUNDED_CARD_TIP_DEPOSIT,
        TRANSACTION_TYPE.REFUNDED_VOUCHER_TIP_DEPOSIT,
      ].indexOf(venueTransactionType) !== -1
    );
  };

  isSale = (transaction) => {
    if (transaction.type === TYPE.CHECKOUT) {
      return true;
    }
    return false;
  };

  isRefund = (transaction) => {
    if (transaction.type === TYPE.CANCELLATION) {
      return true;
    }
    return false;
  };

  isWithdrawalTransaction = (transaction) => {
    const { venueTransactionType } = transaction;
    if (
      this.isWithdrawal(venueTransactionType) &&
      venueTransactionType !== TRANSACTION_TYPE.CASH_REGISTER_CORRECTION_WITHDRAWAL
    ) {
      return true;
    }
    return false;
  };

  isCorrection = (transaction) => {
    const { venueTransactionType } = transaction;
    if (
      this.isWithdrawal(venueTransactionType) &&
      venueTransactionType === TRANSACTION_TYPE.CASH_REGISTER_CORRECTION_WITHDRAWAL
    ) {
      return true;
    }
    return false;
  };

  renderSale = (transaction) => {
    const props = {
      advancedView: this.props.advancedView,
      onClick: this.props.openDialog,
      isSale: true,
      isWalkIn: !transaction.customerName,
      customerName: transaction.customerName || Wahanda.lang.shared.walkin,
      time: transaction.time,
      transactionId: transaction.transactionId,
      amount: transaction.amount,
      payments: transaction.payments,
      type: transaction.type,
      venueTransactionType: transaction.venueTransactionType,
      totalTaxAmount: transaction.totalTaxAmount,
      lineItems: transaction.lineItems,
      checkoutId: transaction.checkoutId,
      cancellationId: transaction.cancellationId,
      referenceTransactionId: transaction.referenceTransactionId,
    };
    return <Transaction {...props} key={transaction.transactionId} />;
  };

  renderRefund = (transaction) => {
    const props = {
      showRefundNote: true,
      isRefund: true,
      advancedView: this.props.advancedView,
      onClick: this.props.openDialog,
      isWalkIn: !transaction.customerName,
      customerName: transaction.customerName || Wahanda.lang.shared.walkin,
      time: transaction.time,
      transactionId: transaction.transactionId,
      amount: transaction.amount,
      payments: transaction.payments,
      type: transaction.type,
      venueTransactionType: transaction.venueTransactionType,
      totalTaxAmount: transaction.totalTaxAmount,
      lineItems: transaction.lineItems,
      refundNote: transaction.notes,
      referenceTransactionId: transaction.referenceTransactionId,
      checkoutId: transaction.checkoutId,
      cancellationId: transaction.cancellationId,
    };
    return <Transaction {...props} key={transaction.cancellationId} />;
  };

  renderWithdrawal = (transaction) => {
    const props = {
      advancedView: this.props.advancedView,
      isWithdrawal: true,
      time: transaction.time,
      transactionId: transaction.transactionId,
      amount: transaction.amount,
      type: transaction.type,
      venueTransactionType: transaction.venueTransactionType,
      totalTaxAmount: transaction.totalTaxAmount,
      lineItems: transaction.lineItems,
      description: transaction.description,
      referenceTransactionId: transaction.referenceTransactionId,
      checkoutId: transaction.checkoutId,
      cancellationId: transaction.cancellationId,
    };
    return <Transaction {...props} key={transaction.venueTransactionId} />;
  };

  renderDeposit = (transaction) => {
    const props = {
      isDeposit: true,
      advancedView: this.props.advancedView,
      time: transaction.time,
      transactionId: transaction.transactionId,
      amount: transaction.amount,
      type: transaction.type,
      venueTransactionType: transaction.venueTransactionType,
      totalTaxAmount: transaction.totalTaxAmount,
      lineItems: transaction.lineItems,
      description: transaction.description,
      referenceTransactionId: transaction.referenceTransactionId,
      checkoutId: transaction.checkoutId,
      cancellationId: transaction.cancellationId,
    };
    return <Transaction {...props} key={transaction.venueTransactionId} />;
  };

  renderCorrection = (transaction) => {
    const props = {
      advancedView: this.props.advancedView,
      isWithdrawal: true,
      time: transaction.time,
      transactionId: transaction.transactionId,
      amount: transaction.amount,
      type: transaction.type,
      venueTransactionType: transaction.venueTransactionType,
      totalTaxAmount: transaction.totalTaxAmount,
      lineItems: transaction.lineItems,
      description: transaction.description,
      checkoutId: transaction.checkoutId,
      cancellationId: transaction.cancellationId,
    };
    return <Transaction {...props} key={transaction.venueTransactionId} />;
  };

  renderRow = (transaction) => {
    if (this.isSale(transaction)) {
      return this.renderSale(transaction);
    }
    if (this.isRefund(transaction)) {
      return this.renderRefund(transaction);
    }
    if (this.isWithdrawalTransaction(transaction)) {
      return this.renderWithdrawal(transaction);
    }
    if (this.isDeposit(transaction.venueTransactionType)) {
      return this.renderDeposit(transaction);
    }
    if (this.isCorrection(transaction)) {
      return this.renderCorrection(transaction);
    }
    return null;
  };

  render() {
    const { transactions, advancedView } = this.props;
    return (
      <Table className={advancedView ? style.expandedView : null}>
        <TableHeader>
          <TableRow>
            <TableColumnHeader rowSpan="2" className={classnames(style.timeHeader, style.noWrap)}>
              <div>{lang.headings.time}</div>
              <div className={style.transactionId}>{lang.headings.transactionHash}</div>
            </TableColumnHeader>
            <TableColumnHeader
              rowSpan="2"
              className={classnames(style.transactionHeader, style.noWrap, style.hideMobile)}
            >
              {lang.headings.transactionHash}
            </TableColumnHeader>
            <TableColumnHeader rowSpan="2" className={classnames(style.typeHeader)}>
              {lang.headings.transactionType}
            </TableColumnHeader>
            <TableColumnHeader rowSpan="2" className={style.clientHeader}>
              {lang.headings.client}
            </TableColumnHeader>
            <TableColumnHeader
              className={classnames(style.totalSumHeader, style.alignRight)}
              rowSpan="2"
            >
              {lang.headings.totalSum}
            </TableColumnHeader>
            {advancedView && (
              <Fragment>
                <TableColumnHeader
                  className={classnames(style.notesHeader, style.dashedLeft)}
                  rowSpan="2"
                >
                  {lang.headings.positionNotes}
                </TableColumnHeader>
                <TableColumnHeader
                  rowSpan="2"
                  className={classnames(style.individSumHeader, style.minWidth, style.alignRight)}
                >
                  {lang.headings.individualSum}
                </TableColumnHeader>
                <TableColumnHeader
                  rowSpan="2"
                  className={classnames(
                    style.vatPercentageHeader,
                    style.minWidth,
                    style.alignRight,
                  )}
                >
                  {lang.headings.vatPercentage}
                </TableColumnHeader>
                <TableColumnHeader
                  rowSpan="2"
                  className={classnames(style.vatIndvHeader, style.minWidth, style.alignRight)}
                >
                  {lang.headings.vatIndividual}
                </TableColumnHeader>
              </Fragment>
            )}
            <TableColumnHeader
              className={classnames(
                style.paymentHeader,
                style.dashedLeft,
                style.centered,
                style.payment,
                style.noWrap,
              )}
              colSpan="2"
            >
              {lang.headings.payment}
            </TableColumnHeader>
          </TableRow>
          <TableRow className={style.dashedTop}>
            <TableColumnHeader
              className={classnames(
                style.dashedLeft,
                style.paymentInfo,
                style.alignLeft,
                style.noWrap,
              )}
            >
              {lang.headings.type}
            </TableColumnHeader>
            <TableColumnHeader
              className={classnames(style.paymentInfo, style.alignRight, style.noWrap)}
            >
              {lang.headings.amount}
            </TableColumnHeader>
          </TableRow>
        </TableHeader>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        <TableBody>{transactions.map(this.renderRow)}</TableBody>
      </Table>
    );
  }
}

export default TransactionReport;
