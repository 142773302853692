import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../store/actions';
import { resetCustomerAction } from 'reduxStore/common/customers/actions';

import { InlineClientCreation as Container } from './InlineClientCreation';

const mapStateToProps = ({ calendar: { inlineClientForm }, customers: { serverError } }) => ({
  name: inlineClientForm.name,
  phone: inlineClientForm.phone,
  emailAddress: inlineClientForm.emailAddress,
  serverError,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    ...bindActionCreators({ ...actions, resetCustomerAction }, dispatch),
    ...ownProps.actions,
  },
});

export const InlineClientCreation = connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(Container);
