import React from 'react';
import Wahanda from 'common/wahanda';
import { Facebook, Instagram, Messenger } from 'components/common/Icon';
import style from './BookingChannels.scss';

const lang = Wahanda.lang.marketing.facebookBusinessExtension.channels;
const channels = [
  {
    name: lang.facebook,
    description: lang.facebookDescription,
    icon: <Facebook className={style.fbBlue} size="large" />,
  },
  {
    name: lang.instagram,
    description: lang.instagramDescription,
    icon: <Instagram size="large" />,
  },
  {
    name: lang.messenger,
    description: lang.messengerDescription,
    icon: <Messenger size="large" />,
  },
];

export const BookingChannels: React.FC = () => {
  return (
    <>
      {channels.map((channel) => (
        <div key={channel.name} className={style.wrapper}>
          <div className={style.icon}>{channel.icon}</div>
          <div className={style.textBox}>
            <div className={style.name}>{channel.name}</div>
            <div className={style.description}>{channel.description}</div>
          </div>
        </div>
      ))}
    </>
  );
};
