import React from 'react';

interface Props {
  uuid: string;
  name: string;
  onClick: (...args: any[]) => any;
}

export const TreatmentName = ({ onClick, uuid, name }: Props) => {
  const handleClick = () => onClick(uuid);
  return <li onClick={handleClick}>{name}</li>;
};
