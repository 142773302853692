/**
 * Tool for fetching a model with possible lightweight logins.
 * The retuned function must be passed as the "error" parameter to the `fetch` method.
 * 
 * @param Object options Possible values: (everything optional)
 * > function onSuccess Function to call when the login succeeds and model is fetched
 * > function onError   Function to call when everything fails
 * > boolean customErrorOnUnknownCode OPTIONAL Should the `onError` callback be called even if an unknown 
 * 			 (e.g. not 403) error code is returned?
 * > Object lightweightOptions Options for the App.loginLightweight
 * > Array otherStatuses Other 4xx statuses to try login with lightweight
 */
BackboneEx.Tool.ModelLightweightFetch = function(options) {
	options = (options || {});
	return function(model, jqXHR) {
		var correctStatus = (jqXHR.status == 403);
		if (!correctStatus && _.isArray(options.otherStatuses)) {
			correctStatus = (-1 !== _.indexOf(options.otherStatuses, jqXHR.status));
		}
		if (correctStatus) {
			if (App.hasLightweightAuthKey()) {
				App.loginLightweight(
					_.extend(
						{},
						options.lightweightOptions || {},
						{
							success: function() {
								model.fetch()
									.done(options.onSuccess)
									.fail(options.onError);
							},
							error: options.onError
						}
					)
				);
			} else {
				options.onError();
			}
		} else {
			// Any other errors are handled as always
			if (options.customErrorOnUnknownCode) {
				options.onError();
			} else {
				Wahanda.Ajax.showError(jqXHR);
			}
		}
	};
};
