import React from 'react';
import ItemRowView from '../summary-view/item-row';
import ItemRowGroupView from '../summary-view/item-group-row';

const lang = Wahanda.lang.reports.transactions.daySummary.cashSummary;

// TODO: refactor to make it functional
function getRows(totalAmount, refundAmount, title, key, additionalClasses) {
  return (
    <ItemRowGroupView
      title={title}
      totalAmount={totalAmount}
      refundAmount={refundAmount}
      key={key}
      // @ts-expect-error ts-migrate(2322) FIXME: Property 'additionalClass' does not exist on type ... Remove this comment to see the full error message
      additionalClass={additionalClasses}
    />
  );
}

function getRow(amount, title, key, additionalClasses) {
  return (
    <div className="item-group">
      <ItemRowView title={title} amount={amount} key={key} additionalClass={additionalClasses} />
    </div>
  );
}

/*
 *  TODO
 *
 *  Simply return undefined ..?
 *
 *  What's the expected/valid value of amount?
 */
function getOptionalRow(amount, title, key, additionalClasses) {
  if (amount) {
    return getRow(amount, title, key, additionalClasses);
  }
  return undefined;
}

type CashRegisterProps = {
  cashSummary: {
    startOfDay?: number;
    cashSales?: number;
    cashRefunds?: number;
    deposits?: number;
    withdrawals?: number;
    drawerCorrection?: number;
    total?: number;
  };
};

export const CashRegister: React.SFC<CashRegisterProps> = (props) => {
  const emptyState = {
    startOfDay: false,
    cashSales: 0,
    cashRefunds: 0,
    deposits: 0,
    withdrawals: 0,
    drawerCorrection: 0,
    total: 0,
  };
  const cashSummary = props.cashSummary || emptyState;
  return (
    <div className="cash-summary">
      <div className="item important">{lang.heading}</div>
      <div className="item-container">
        {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3. */}
        {getRow(cashSummary.startOfDay, lang.startOfDay, 'startOfDay')}
        {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 5 arguments, but got 4. */}
        {getRows(cashSummary.cashSales, cashSummary.cashRefunds, lang.cashSales, 'cashSales')}
        {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3. */}
        {getRow(cashSummary.deposits, lang.deposits, 'deposits')}
        {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3. */}
        {getRow(cashSummary.withdrawals, lang.withdrawals, 'withdrawals')}
        {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3. */}
        {getOptionalRow(cashSummary.drawerCorrection, lang.drawerCorrection, 'drawerCorrection')}
        {getRow(cashSummary.total, lang.total, 'total', 'total')}
      </div>
    </div>
  );
};
