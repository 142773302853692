import React from 'react';
import { get, getIn } from 'mori';
import { sprintf } from 'sprintf-js';
import { appState } from 'state';
import { withCursor } from 'atom/cursors';
import Wahanda from 'common/wahanda';
import { toDateString, now } from 'common/datetime';
import moment from 'common/moment';
import { STATUS_CREATED, STATUS_CONFIRMED } from 'common/appointment';
import {
  close as closeDayCall,
  fetchOnce as fetchDailyReportOnce,
  clearFetchCache as clearPOSDailyReportFetchCache,
} from 'src/services/pos-daily-report';
import { fetch as fetchPosStatus } from 'src/services/pos-status';
import fetchCalendar from 'src/services/calendar';
import BaseDialog from 'src/components/common/dialog';
import { Loader } from 'components/common/Loader';
import { Button } from 'components/common/Button';
import { Receipt } from 'components/common/Icon';
import CloseForm from './CloseForm';
import style from './style.scss';

const renderLoading = () => (
  <div className={style.loader}>
    <Loader />
  </div>
);
const getCashSummaryTotal = function (state, date) {
  const dailyReport = getIn(state, ['pos', 'daily-report', date]);
  if (!dailyReport) {
    return null;
  }
  const totalCash = get(dailyReport, 'wasNeverOpened')
    ? get(dailyReport, 'previousClosingCashAmount')
    : getIn(dailyReport, ['cashSummary', 'total']);
  return totalCash || 0;
};
interface IDialogContentProps extends React.HTMLAttributes<Element> {
  callback?: (...args: any[]) => any;
  registerCloseFn?: (...args: any[]) => any;
  date: string;
}
type DialogContentState = {
  expectedAmount?: any;
  notDoneAppointments?: number;
  saveError?: boolean;
  saving?: boolean;
  dayClosed?: boolean;
  hasDrawerCorrection?: any;
  correctionReceiptId?: any;
};
class DialogContent extends React.Component<IDialogContentProps, DialogContentState> {
  form: any;

  // @ts-expect-error ts-migrate(2416) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
  state = {
    expectedAmount: getCashSummaryTotal(appState.deref(), this.props.date),
    saving: false,
    notDoneAppointments: null,
  };

  UNSAFE_componentWillMount() {
    const { date, registerCloseFn } = this.props;
    const calendarOptions = {
      dateFrom: date,
      dateTo: date,
      appointmentStatusCodes: [STATUS_CREATED, STATUS_CONFIRMED],
      utmSource: 'day-close-dialog',
    };
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    registerCloseFn(() => ({
      ...this.getCallbackParams(),
      dialogClosed: true,
    }));
    fetchDailyReportOnce(date);
    // Check if date is todays date before fetching info about
    // "notdone" appts
    if (moment(date).isSame(moment(), 'day')) {
      fetchCalendar(calendarOptions).done((objects) => {
        const appts = objects.appointments;
        this.setState({
          notDoneAppointments: appts.length,
        });
      });
    } else {
      this.setState({
        notDoneAppointments: 0,
      });
    }
    appState.addWatch(
      'POSCloseDialog',
      withCursor(
        (oldState, newState) => {
          this.setState({
            expectedAmount: getCashSummaryTotal(newState, date),
          });
        },
        ['pos', 'daily-report', date],
      ),
    );
  }

  componentWillUnmount() {
    appState.removeWatch('POSCloseDialog');
  }

  onCloseFail = () => {
    this.setState({
      saveError: true,
      saving: false,
    });
  };

  onClosed = (hasDrawerCorrection, correctionReceiptId) => {
    fetchPosStatus();
    clearPOSDailyReportFetchCache(now());
    this.setState({
      saving: false,
      dayClosed: true,
      hasDrawerCorrection,
      correctionReceiptId,
    });
  };

  getCallbackParams = () => ({
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dayClosed' does not exist on type '{ exp... Remove this comment to see the full error message
    dayClosed: this.state.dayClosed,
    date: toDateString(this.props.date),
  });

  disableValidationSetupWhenMounted = true;

  closeDay = ({ hasDrawerCorrection }) => {
    if (this.state.saving) {
      return;
    }
    const promise = closeDayCall(this.props.date, this.form.getAmount());
    this.setState({
      saveError: false,
      saving: true,
    });
    promise
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      .done((res) => this.onClosed(hasDrawerCorrection, res.correctionReceiptId))
      .fail(this.onCloseFail);
  };

  renderCloseForm() {
    const state = this.state;
    return (
      <CloseForm
        ref={(form) => {
          this.form = form;
        }}
        saving={state.saving}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
        notDoneAppointmentCount={state.notDoneAppointments}
        persistToBackend={this.closeDay}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'saveError' does not exist on type '{ exp... Remove this comment to see the full error message
        saveError={this.state.saveError}
        expectedAmount={this.state.expectedAmount}
      />
    );
  }

  renderDayCloseSuccess() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasDrawerCorrection' does not exist on t... Remove this comment to see the full error message
    const { hasDrawerCorrection } = this.state;
    const dialogLang = Wahanda.lang.reports.transactions.closeDay.dialog;
    const printReport = () => {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.callback({
        ...this.getCallbackParams(),
        showReport: true,
      });
    };
    const printCorrectionReceipt = () => {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.callback({
        ...this.getCallbackParams(),
        showCorrectionReceipt: true,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'correctionReceiptId' does not exist on t... Remove this comment to see the full error message
        correctionReceiptId: this.state.correctionReceiptId,
      });
    };
    const sharedButtonProps = {
      size: 'large',
      colour: 'pos',
      fullWidth: true,
      icon: <Receipt />,
    };
    return (
      <div className={style.closedDayInfoContainer}>
        <h2>{dialogLang.dayClosed}</h2>
        {/* @ts-expect-error ts-migrate(2769) FIXME: Type 'string' is not assignable to type '"pos" | "... Remove this comment to see the full error message */}
        <Button
          {...sharedButtonProps}
          label={dialogLang.openSettlementReportButton}
          onClick={printReport}
        />
        {hasDrawerCorrection && (
          <div className={style.settlementReportButtonLast}>
            {/* @ts-expect-error ts-migrate(2769) FIXME: Type 'string' is not assignable to type '"pos" | "... Remove this comment to see the full error message */}
            <Button
              {...sharedButtonProps}
              label={dialogLang.openDrawerCorrectionReceiptButton}
              onClick={printCorrectionReceipt}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dayClosed' does not exist on type '{ exp... Remove this comment to see the full error message
    if (this.state.dayClosed) {
      return this.renderDayCloseSuccess();
    }
    if (this.state.notDoneAppointments === null || this.state.expectedAmount === null) {
      return renderLoading();
    }
    return this.renderCloseForm();
  }
}

export default function open(closingDate, callback = null) {
  const title = (() => {
    if (closingDate) {
      return sprintf(
        '%s - %s',
        Wahanda.lang.reports.transactions.closeDay.dialog.title,
        moment(closingDate).formatDateDefault(),
      );
    }
    return Wahanda.lang.reports.transactions.closeDay.dialog.title;
  })();
  const dialog = new BaseDialog({
    dialogTitle: title,
    dialogClass: 'transactions-dialog',
    width: 460,
    close: callback,
    dataTest: 'close-day-modal',
  });
  dialog.openWithContent(
    <DialogContent
      // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type '((...args: ... Remove this comment to see the full error message
      callback={callback}
      date={closingDate}
      registerCloseFn={dialog.registerCloseParamsCallback}
    />,
  );
}
