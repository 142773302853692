export const assetConfig = {
  sms: {
    ukie: 'https://cdn1.treatwell.net/images/view/v2.i2799156.w1036.h754.xF72FBB08.png',
    fr: 'https://cdn1.treatwell.net/images/view/v2.i2799154.w1036.h754.xBEE0BD93.png',
    bnl: 'https://cdn1.treatwell.net/images/view/v2.i2799153.w1036.h754.xAF82745D.png',
    es: 'https://cdn1.treatwell.net/images/view/v2.i2799157.w1036.h754.xAC089DB4.png',
    dach: 'https://cdn1.treatwell.net/images/view/v2.i2799158.w1036.h754.x1FAC3CF0.png',
    lt: 'https://cdn1.treatwell.net/images/view/v2.i2799159.w1036.h754.xC623FD66.png',
    it: 'https://cdn1.treatwell.net/images/view/v2.i2799160.w1036.h754.x89524110.png',
  },
  pushNotification: {
    ukie: 'https://cdn1.treatwell.net/images/view/v2.i2804303.w1036.h754.xB90936EF.png',
    fr: 'https://cdn1.treatwell.net/images/view/v2.i2804304.w1036.h754.x3B3FD031.png',
    bnl: 'https://cdn1.treatwell.net/images/view/v2.i2804296.w1036.h754.x1941F091.png',
    es: 'https://cdn1.treatwell.net/images/view/v2.i2804308.w1036.h754.x776A0340.png',
    dach: 'https://cdn1.treatwell.net/images/view/v2.i2804300.w1036.h754.x00A65947.png',
    lt: 'https://cdn1.treatwell.net/images/view/v2.i2804305.w1036.h754.xCBEEDA42.png',
    it: 'https://cdn1.treatwell.net/images/view/v2.i2804310.w1036.h754.x8CC5125B.png',
  },
};
