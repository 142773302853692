import React, { Component } from 'react';
import Wahanda from 'common/wahanda';
import { PERMISSIONS } from 'common/constants/userPermissions';
import ReactDialog from 'components/common/react-dialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import { Checkbox } from 'components/common/Checkbox';
import style from './style.scss';

const lang = Wahanda.lang.settings.employees.employee.permissions;
const DIALOG_WIDTH = 600;
interface IEmployeeCustomPermissionsDialogProps extends React.HTMLAttributes<Element> {
  permissions?: string[];
  pointOfSalePermissionsVisible?: boolean;
  onClose: (...args: any[]) => any;
  onSave: (...args: any[]) => any;
}
type EmployeeCustomPermissionsDialogState = {
  permissions?: any[];
  indexOf?: (searchElement: any, fromIndex?: number) => number;
};

export default class EmployeeCustomPermissionsDialog extends Component<
  IEmployeeCustomPermissionsDialogProps,
  EmployeeCustomPermissionsDialogState
> {
  static defaultProps = {
    permissions: [],
    pointOfSalePermissionsVisible: false,
  };

  state = {
    // @ts-expect-error ts-migrate(2569) FIXME: Type 'string[] | undefined' is not an array type o... Remove this comment to see the full error message
    permissions: [...this.props.permissions],
  };

  onSave = () => {
    this.props.onSave(this.state.permissions);
  };

  onChange = (checked, event) => {
    let permissions = this.state.permissions;
    if (checked) {
      permissions.push(event.target.value);
    } else {
      permissions = permissions.filter((p) => p !== event.target.value);
    }
    this.setState({ permissions });
  };

  isChecked = (p) => this.state.permissions.indexOf(p) !== -1;

  renderPermission = (value, label, notChangeablePermission = false) => (
    <div className={style.checkboxContainer}>
      <Checkbox
        name={value}
        checked={this.isChecked(value) || notChangeablePermission}
        value={value}
        label={label}
        onChange={this.onChange}
        disabled={notChangeablePermission}
      />
    </div>
  );

  render() {
    const { pointOfSalePermissionsVisible, onClose } = this.props;
    return (
      <ReactDialog
        dataTest="employee-permissions-modal"
        title={lang.title}
        onClose={onClose}
        width={DIALOG_WIDTH}
        noContentPadding
        noContentTopBorders
      >
        <div className={style.dialogContent}>
          <div className={style.column}>
            <div className={style.group}>
              <h3 className={style.groupTitle}>{lang.general.title}</h3>
              {this.renderPermission(PERMISSIONS.viewBookings, lang.general.viewBookings)}
              {this.renderPermission(PERMISSIONS.manageVenueDetails, lang.general.manageVenue)}
              {this.renderPermission(PERMISSIONS.viewFinanceData, lang.general.viewFinance)}
              {this.renderPermission(PERMISSIONS.viewReportsSales, lang.general.viewReportsSales)}
              {this.renderPermission(PERMISSIONS.cancelTransaction, lang.general.cancelTransaction)}
              {this.renderPermission(
                PERMISSIONS.canEditServicePrice,
                lang.general.canEditServicePrice,
              )}
              {pointOfSalePermissionsVisible &&
                this.renderPermission(PERMISSIONS.managePos, lang.general.managePos)}
            </div>

            <div className={style.group}>
              <h3 className={style.groupTitle}>{lang.menu.title}</h3>
              {this.renderPermission(PERMISSIONS.viewMenu, lang.menu.viewMenu)}
              {this.renderPermission(PERMISSIONS.editMenu, lang.menu.editMenu)}
            </div>

            <div className={style.group}>
              <h3 className={style.groupTitle}>{lang.clients.clientsAndMarketing}</h3>
              {this.renderPermission(PERMISSIONS.viewClientList, lang.clients.viewClientList)}
              {this.renderPermission(
                PERMISSIONS.viewClientContactDetails,
                lang.clients.viewEditClient,
              )}
              {this.renderPermission(
                PERMISSIONS.accessMarketingTools,
                lang.clients.accessMarketingTools,
              )}
            </div>
          </div>

          <div className={style.column}>
            <div className={style.group}>
              <h3 className={style.groupTitle}>{lang.calendar.title}</h3>
              {this.renderPermission(0, lang.calendar.viewOwn, true)}
              {this.renderPermission(PERMISSIONS.editOwnCalendar, lang.calendar.editOwn)}
              {this.renderPermission(PERMISSIONS.viewAnyCalendar, lang.calendar.viewOthers)}
              {this.renderPermission(PERMISSIONS.editAnyCalendar, lang.calendar.addEditOthers)}
              {this.renderPermission(
                PERMISSIONS.deleteAppointments,
                lang.general.canDeleteAppointments,
              )}
            </div>

            <div className={style.group}>
              <h3 className={style.groupTitle}>{lang.staff.title}</h3>
              {this.renderPermission(PERMISSIONS.addTherapists, lang.staff.add)}
              {this.renderPermission(PERMISSIONS.editUserPermissions, lang.staff.changePermissions)}
            </div>
          </div>
        </div>
        <DialogFooter>
          <DialogFooterButton
            title={Wahanda.lang.shared.buttons.cancel}
            type="secondary"
            onClick={onClose}
          />
          <DialogFooterButton title={Wahanda.lang.shared.buttons.save} onClick={this.onSave} />
        </DialogFooter>
      </ReactDialog>
    );
  }
}
