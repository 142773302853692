(function () {
  App.Views.LoginRequest = BackboneEx.View.Dialog.LoginRequest.extend({
    options: {
      width: 540,
      title: Wahanda.lang.shared.loginRequest.title,
      titleText: Wahanda.lang.shared.loginRequest.texts.title,
      bodyText: Wahanda.lang.shared.loginRequest.texts.text,
      loginButtonText: Wahanda.lang.shared.loginRequest.buttons.login,
      dialogId: 'lightweight-dialog',
      dialogClass: 'login-request-dialog',
    },
  });

  App.Views.LoginRequest.show = function () {
    var view = new App.Views.LoginRequest();
    view.render();
    view.open();
  };
})();
