import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Login extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-login', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M12.0046024,0 C13.1010902,0 14,0.9029758 14,2.00684547 L14,13.9931545 C14,15.0996314 13.1093607,16 12.0046024,16 L3.99539757,16 C2.89890976,16 2,15.0970242 2,13.9931545 L2,11 L4.00103953,11 C4.00033294,12.7218161 3.9986972,14 3.99539757,14 C3.99539757,14 12,13.9998968 12,13.9931545 C12,13.9931545 11.9944951,2 12.0046024,2 L7.86271123,2.00095635 C5.90915612,2.00184346 4,2.00355273 4,2.00684547 L4.00096563,4.77845022 C4.00098519,4.85141611 4.00100453,4.92528881 4.0010236,5 L2,5 L2,2.00684547 C2,0.900368558 2.89063925,0 3.99539757,0 L12.0046024,0 Z M6.70710678,3.29289322 L11.4142136,8 L6.70710678,12.7071068 L5.29289322,11.2928932 L7.58578644,9 L2,9 L2,7 L7.58578644,7 L5.29289322,4.70710678 L6.70710678,3.29289322 Z" />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-login', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M12.0046024,0 C13.1010902,0 14,0.9029758 14,2.00684547 L14,13.9931545 C14,15.0996314 13.1093607,16 12.0046024,16 L3.99539757,16 C2.89890976,16 2,15.0970242 2,13.9931545 L2,11 L4.00103953,11 C4.00033294,12.7218161 3.9986972,14 3.99539757,14 C3.99539757,14 12,13.9998968 12,13.9931545 C12,13.9931545 11.9944951,2 12.0046024,2 L7.86271123,2.00095635 C5.90915612,2.00184346 4,2.00355273 4,2.00684547 L4.00096563,4.77845022 C4.00098519,4.85141611 4.00100453,4.92528881 4.0010236,5 L2,5 L2,2.00684547 C2,0.900368558 2.89063925,0 3.99539757,0 L12.0046024,0 Z M6.70710678,3.29289322 L11.4142136,8 L6.70710678,12.7071068 L5.29289322,11.2928932 L7.58578644,9 L2,9 L2,7 L7.58578644,7 L5.29289322,4.70710678 L6.70710678,3.29289322 Z" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Login);
