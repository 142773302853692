import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'components/common/Modal';
import { Button } from 'components/common/Button';
import style from './IdentityVerificationModal.scss';

interface Props {
  isKycNewSupplier: boolean;
}

export const IdentityVerificationModal = ({ isKycNewSupplier }: Props) => {
  const [isOpen, setOpen] = useState(true);

  if (!isOpen) {
    return <></>;
  }

  const onClose = () => setOpen(false);

  const openIdentityVerification = () => window.open(App.getIdentityVerificationUrl(), '_blank');

  const onLearnMore = () => window.open(App.getIdentityVerificationLearnMoreLink(), '_blank');

  const message = isKycNewSupplier
    ? Wahanda.lang.calendar.identityVerification.modal.newSupplierBody
    : Wahanda.lang.calendar.identityVerification.modal.body;

  return (
    <Modal
      dataTest="identity-verification-modal"
      title={Wahanda.lang.calendar.identityVerification.modal.headline}
      type="medium"
      onClose={onClose}
    >
      <ModalBody>
        <div
          className={style.identityVerificationModalBody}
          dangerouslySetInnerHTML={{ __html: message }}
        ></div>
      </ModalBody>
      <ModalFooter>
        <Button
          label={Wahanda.lang.calendar.identityVerification.modal.learnMore}
          variant="secondary"
          colour="plain"
          onClick={onLearnMore}
        />
        <div className={style.buttonWrapper}>
          <Button
            dataTest="identity-verification-modal-submit"
            label={Wahanda.lang.calendar.identityVerification.modal.submit}
            onClick={openIdentityVerification}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};
