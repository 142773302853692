import { connect } from 'react-redux';

import { menuOfferCheckedTransformer } from 'common/transformers/DiscountMenu/';

import DiscountRuleDialog from './index';

const mapStateToProps = ({ discountMenuItems: { menuItemOffers } }) => ({
  menuItemOfferIds: menuOfferCheckedTransformer(menuItemOffers),
});

export default connect(mapStateToProps)(DiscountRuleDialog);
