import { call, put, select } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { getEmailTemplate } from 'components/marketing/EmailCampaign/selectors';
import omit from 'lodash/omit';

import {
  getEmailCampaignRecipientCountSuccess,
  getEmailCampaignRecipientCountFailure,
  sendEmailCampaignSuccess,
  sendEmailCampaignFailure,
} from './actions';

export function* getEmailCampaignRecipientCount() {
  try {
    const data = yield call(api.get, apiUrl('CUSTOMER_MASS_EMAIL'));

    yield put(getEmailCampaignRecipientCountSuccess(data.count));
  } catch (error) {
    yield put(getEmailCampaignRecipientCountFailure(error));
  }
}

export function* getSmartDiscountsRecipientCountRequested(params) {
  const { selectedDiscountRuleId } = params;
  try {
    const data = yield call(
      api.get,
      apiUrl('EMAIL_CAMPAIGN_SMART_DISCOUNT_RECIPIENT_COUNT', {
        selectedDiscountRuleId,
      }),
    );
    yield put(getEmailCampaignRecipientCountSuccess(data));
  } catch (error) {
    yield put(getEmailCampaignRecipientCountFailure(error));
  }
}

export function* getServiceOnSaleRecipientCountRequested(params) {
  const { treatmentGroupId } = params;
  try {
    const data = yield call(
      api.get,
      apiUrl('EMAIL_CAMPAIGN_SERVICE_ON_SALE_RECIPIENT_COUNT', {
        treatmentGroupId,
      }),
    );
    yield put(getEmailCampaignRecipientCountSuccess(data));
  } catch (error) {
    yield put(getEmailCampaignRecipientCountFailure(error));
  }
}

export function* getAppForYourClientsRecipientCountRequested() {
  try {
    const data = yield call(api.get, apiUrl('EMAIL_CAMPAIGN_APP_FOR_YOUR_CLIENTS_RECIPIENT_COUNT'));
    yield put(getEmailCampaignRecipientCountSuccess(data));
  } catch (error) {
    yield put(getEmailCampaignRecipientCountFailure(error));
  }
}

export function* sendEmailCampaign() {
  try {
    const template = yield select(getEmailTemplate);

    // Note:
    // Arguments subject, title, message, signature, for this api are not
    // wrapped in "CampaignEmailData" as it is in other campaigns
    yield call(api.post, apiUrl('EMAIL_CAMPAIGN_SEND'), omit(template.modifiedTemplate, 'html'));

    yield put(sendEmailCampaignSuccess());
  } catch (error) {
    yield put(sendEmailCampaignFailure(error));
  }
}

export function* sendSmartDiscountsCampaignRequested(params) {
  try {
    const template = yield select(getEmailTemplate);
    const { message, signature, subject, title } = template.modifiedTemplate;

    const content = {
      campaignEmailData: { message, signature, subject, title },
      discountRuleId: params.discountRuleId,
    };

    yield call(api.post, apiUrl('SMART_DISCOUNTS_CAMPAIGN_SEND'), content);

    yield put(sendEmailCampaignSuccess());
  } catch (error) {
    yield put(sendEmailCampaignFailure(error));
  }
}

export function* sendServiceOnSaleCampaignRequested(params) {
  try {
    const template = yield select(getEmailTemplate);
    const { message, signature, subject, title } = template.modifiedTemplate;

    const content = {
      campaignEmailData: { message, signature, subject, title },
      treatmentGroupId: params.treatmentGroupId,
    };
    yield call(api.post, apiUrl('SERVICE_ON_SALE_CAMPAIGN_SEND'), content);

    yield put(sendEmailCampaignSuccess());
  } catch (error) {
    yield put(sendEmailCampaignFailure(error));
  }
}

export function* sendAppForYourClientsCampaignRequested() {
  try {
    const template = yield select(getEmailTemplate);
    const { message, signature, subject, title } = template.modifiedTemplate;
    const content = {
      campaignEmailData: { message, signature, subject, title },
    };
    yield call(api.post, apiUrl('APP_FOR_YOUR_CLIENTS_CAMPAIGN_SEND'), content);

    yield put(sendEmailCampaignSuccess());
  } catch (error) {
    yield put(sendEmailCampaignFailure(error));
  }
}

export function* sendBackToBusinessCampaignRequested() {
  try {
    const template = yield select(getEmailTemplate);
    const { message, signature, subject, title } = template.modifiedTemplate;
    const content = {
      campaignEmailData: { message, signature, subject, title },
    };
    yield call(api.post, apiUrl('BACK_TO_BUSINESS_CAMPAIGN_SEND'), content);

    yield put(sendEmailCampaignSuccess());
  } catch (error) {
    yield put(sendEmailCampaignFailure(error));
  }
}
