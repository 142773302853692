import {
  VENUE_DETAILS_REQUEST,
  VENUE_DETAILS_RECEIVE,
  VENUE_DETAILS_FAILURE,
  INVITE_TO_DOWNLOAD_APP_BY_SMS_REQUEST,
  INVITE_TO_DOWNLOAD_APP_BY_SMS_SUCCESS,
  INVITE_TO_DOWNLOAD_APP_BY_SMS_FAILURE,
  APP_ADVOCACY_BANNER_HIDE_SUCCESS_OVERLAY,
  CLEAR_APP_ADVOCACY_BANNER,
} from 'reduxStore/actionsConst';

import { VenueSettings, VenueId, UDVAppAdvocacyBannerError, CustomerData } from './types';

export const requestVenueDetailsAction = (data: VenueId) => ({
  type: VENUE_DETAILS_REQUEST,
  data,
});

export const receiveVenueDetailsAction = (data: VenueSettings) => ({
  type: VENUE_DETAILS_RECEIVE,
  data,
});

export const requestVenueDetailsFailureAction = (err: UDVAppAdvocacyBannerError) => ({
  type: VENUE_DETAILS_FAILURE,
  err,
});

export const requestInviteToDownloadAppBySMSAction = (data: CustomerData) => ({
  type: INVITE_TO_DOWNLOAD_APP_BY_SMS_REQUEST,
  data,
});

export const inviteToDownloadAppBySMSSuccessAction = () => ({
  type: INVITE_TO_DOWNLOAD_APP_BY_SMS_SUCCESS,
});

export const inviteToDownloadAppBySMSFailureAction = (err: UDVAppAdvocacyBannerError) => ({
  type: INVITE_TO_DOWNLOAD_APP_BY_SMS_FAILURE,
  err,
});

export const hideSuccessOverlayAction = () => ({
  type: APP_ADVOCACY_BANNER_HIDE_SUCCESS_OVERLAY,
});

export const clearUDVAppAdvocacyBannerAction = () => ({
  type: CLEAR_APP_ADVOCACY_BANNER,
});
