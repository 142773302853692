import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { clientsFileUploadRequest, clientsImportReset } from '../actions';
import { ClientsImportInit as Content } from './ClientsImportInit';

const mapStateToProps = ({ clients }) => ({
  isFileUploading: clients.clientsImport.isFileUploading,
  selectedFile: {
    file: clients.clientsImport.selectedFile,
    fileName: clients.clientsImport.selectedFileName,
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ clientsFileUploadRequest, clientsImportReset }, dispatch),
});

export const ClientsImportInit = connect(mapStateToProps, mapDispatchToProps)(Content);
