(function () {
  App.Models.StatsInteractions = BackboneEx.Model.Base.extend({
    url: function () {
      var url = '/venue/' + App.getVenueId() + '/stats/interactions.json';
      if (this.get('date')) {
        url += '?date=' + Wahanda.Date.toApiString(this.get('date'));
      }
      return App.Api.wsUrl(url);
    },

    /**
     * Returns list one type interactions, sorted by date.
     *
     * Also fills in any missing dates with values.
     *
     * @param String type
     * @param Date   dateFrom
     */
    getInteractionsByType: function (type, dateFrom) {
      var list = [];

      _.each(this.get('interactions'), function (data) {
        list.push({
          date: data.date,
          value: data[type],
        });
      });

      var now = new Date();
      var cYear = now.getFullYear();
      var cMonth = now.getMonth();

      var fYear = dateFrom.getFullYear();
      var fMonth = dateFrom.getMonth();

      var dateStr;
      // Fill in missing date values
      while (fYear < cYear || (fYear === cYear && fMonth <= cMonth)) {
        var dateStr = fYear + '-' + Wahanda.Number.to2digit(fMonth + 1) + '-01';
        if (
          null ==
          _.find(list, function (item) {
            return item.date === dateStr;
          })
        ) {
          list.push({
            date: dateStr,
            value: 0,
          });
        }
        fMonth++;
        if (fMonth == 12) {
          fMonth = 0;
          fYear++;
        }
      }

      list.sort(function (a, b) {
        if (a.date == b.date) {
          return 0;
        }
        return b.date < a.date ? 1 : -1;
      });

      return list;
    },
  });
})();
