// The initializer, connecting React with Backbone
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'common/menu-store';
import {
  offerDialogPricingAndEmployeesReady,
  offerDalogCloseAction,
  offerDialogNameChangeAction,
  offerDialogAllowProcessingChangeAction,
  trackServiceEditAction,
} from 'components/menu/actions';
import { ServiceEditLabel } from 'components/menu/ServicesTab/tracking';
import { OfferTreatmentPricingAndEmployees as EmployeesAndPricing } from './index';
import { getPricingType } from './Pricing/pricing-type';

let employeesAndPricingRef: EmployeesAndPricing | null = null;

export function render(target, props, { onPossiblePricingTypeChange }) {
  store.dispatch(
    offerDialogPricingAndEmployeesReady(
      Object.assign({ newSkuPricingType: getPricingType() }, props),
    ),
  );
  ReactDOM.render(
    <Provider store={store}>
      <EmployeesAndPricing
        onPossiblePricingTypeChange={onPossiblePricingTypeChange}
        ref={(ref) => {
          employeesAndPricingRef = ref;
        }}
      />
    </Provider>,
    target,
  );
}

export function getValues() {
  return employeesAndPricingRef?.getValues();
}

export function remove(target) {
  employeesAndPricingRef = null;
  ReactDOM.unmountComponentAtNode(target);
  store.dispatch(offerDalogCloseAction());
}

export function updateAllowProcessing(allow) {
  store.dispatch(offerDialogAllowProcessingChangeAction(allow));
}

export function updateOfferName(name) {
  store.dispatch(offerDialogNameChangeAction(name));
  store.dispatch(trackServiceEditAction(ServiceEditLabel.ServiceName));
}
