import React from 'react';
import { vector, hashMap } from 'mori';
import Dialog from 'components/common/react-dialog';
import Footer from 'components/common/dialog/footer';

// @ts-expect-error ts-migrate(2430) FIXME: Type '{ title?: string | undefined; text?: string ... Remove this comment to see the full error message
interface IDirectDebitBankDetailsChangeConfirmationProps extends React.HTMLAttributes<Element> {
  lang: {
    title?: string;
    text?: string;
    buttons?: {
      confirm?: string;
      cancel?: string;
    };
  };
  onConfirm?: (...args: any[]) => any;
  onClose?: (...args: any[]) => any;
}

export default class DirectDebitBankDetailsChangeConfirmation extends React.Component<
  IDirectDebitBankDetailsChangeConfirmationProps,
  {}
> {
  render() {
    const { buttons, title, text } = this.props.lang;
    const { onClose, onConfirm } = this.props;
    const buttonList = vector(
      hashMap(
        'onClick',
        () => {
          onConfirm!();
          onClose!();
        },
        'title',
        buttons?.confirm,
        'classes',
        { 'dialog2--button-right dialog2--button-destructive': true },
      ),
      hashMap('onClick', onClose, 'title', buttons?.cancel, 'classes', {
        'dialog2--button-left dialog2--button-grey': true,
      }),
    );
    return (
      <Dialog
        dataTest="direct-debit-bank-details-change-confirmation-modal"
        warnWhenClosingChangedForms={false}
        classes={{ 'with-text-red-exclamation-icon': true }}
        hideTitlebar
        onClose={onClose}
      >
        <div className="text">
          <h2>{title}</h2>
          {text}
        </div>
        <Footer buttons={buttonList} fullWidth />
      </Dialog>
    );
  }
}
