const ROLES = {
  ADMINISTRATOR: 'A',
  USER: 'U',
  RECEPTIONIST: 'R',
  THERAPIST: 'T',
  TAX_AUDITOR: 'X',
  BASIC: 'B',
};

export default Object.freeze(ROLES);
