import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions';
import { MenuGroup as Component } from './MenuGroup';

const mapDispatchToProps = (dispatch) =>
  Object.assign(
    {},
    {
      actions: bindActionCreators(actions, dispatch),
    },
  );

export const MenuGroup = connect(null, mapDispatchToProps)(Component);
