import React, { PureComponent } from 'react';
import { Badge } from 'components/common/Badge';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';

interface INotificationTimeBadgeProps extends React.HTMLAttributes<Element> {
  datetime: string;
  withTodayLabel?: boolean;
}
class NotificationTimeBadge extends PureComponent<INotificationTimeBadgeProps, {}> {
  static defaultProps = {
    withTodayLabel: false,
  };

  render() {
    const { datetime, withTodayLabel } = this.props;
    const todayLabel = Wahanda.lang.date.today;
    const timeLabel = moment(datetime).format('LT');
    const text = withTodayLabel ? `${todayLabel}, ${timeLabel}` : timeLabel;
    return <Badge type="success" text={text} />;
  }
}

export default NotificationTimeBadge;
