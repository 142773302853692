import React from 'react';
import TooltipTrigger from 'react-popper-tooltip';
import classnames from 'classnames';

import style from './Tooltip.scss';

export enum TooltipPlacement {
  auto = 'auto',
  top = 'top',
  topStart = 'top-start',
  topEnd = 'top-end',
  right = 'right',
  rightStart = 'right-start',
  rightEnd = 'right-end',
  bottom = 'bottom',
  bottomStart = 'bottom-start',
  bottomEnd = 'bottom-end',
  left = 'left',
  leftStart = 'left-start',
  leftEnd = 'left-end',
}

interface Props {
  className?: string;
  dataTest?: string;
  children: React.ReactNode;
  tooltip: React.ReactNode | string;
  hideArrow?: boolean;
  tooltipShown?: boolean;
  placement?: TooltipPlacement | string;
}

export const Tooltip = ({
  className,
  dataTest,
  children,
  tooltip,
  tooltipShown,
  hideArrow,
  ...props
}: Props) => {
  const { placement: placementType = TooltipPlacement.right } = props;

  return (
    <TooltipTrigger
      {...props}
      placement={TooltipPlacement[placementType]}
      tooltipShown={tooltipShown}
      tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
        <div
          data-test={dataTest}
          {...getTooltipProps({
            ref: tooltipRef,
            className: style.container,
            'data-placement': placement,
          })}
        >
          {!hideArrow && (
            <div
              {...getArrowProps({
                ref: arrowRef,
                className: style.arrow,
                'data-placement': placement,
              })}
            />
          )}
          {tooltip}
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <span
          {...getTriggerProps({
            ref: triggerRef,
            className: classnames(style.child, className),
          })}
        >
          {children}
        </span>
      )}
    </TooltipTrigger>
  );
};

Tooltip.displayName = 'Tooltip';
