import React from 'react';
import { CHANNEL_CODES } from 'config/domains-locales';
import { assetConfig } from './config';
import style from './style.scss';

interface Props {
  locale?: string;
  contentChannel: string;
}

export const PushNotificationPreview: React.FC<Props> = ({ locale, contentChannel }) => {
  const getAsset = () => {
    if (!locale) {
      return getAssetByContentChannel();
    }
    return getAssetByLocale();
  };
  const getAssetByLocale = () => {
    switch (locale) {
      case 'de':
      case 'de_AT':
      case 'de_CH':
        return assetConfig.pushNotification.dach;
      case 'nl_BE':
      case 'nl':
        return assetConfig.pushNotification.bnl;
      case 'es':
        return assetConfig.pushNotification.es;
      case 'fr':
        return assetConfig.pushNotification.fr;
      case 'it':
        return assetConfig.pushNotification.it;
      case 'lt':
        return assetConfig.pushNotification.lt;
      default:
        return assetConfig.pushNotification.ukie;
    }
  };
  const getAssetByContentChannel = () => {
    switch (contentChannel) {
      case CHANNEL_CODES.DE:
      case CHANNEL_CODES.AT:
      case CHANNEL_CODES.CH:
        return assetConfig.pushNotification.dach;
      case CHANNEL_CODES.BE:
      case CHANNEL_CODES.NL:
        return assetConfig.pushNotification.bnl;
      case CHANNEL_CODES.ES:
        return assetConfig.pushNotification.es;
      case CHANNEL_CODES.FR:
        return assetConfig.pushNotification.fr;
      case CHANNEL_CODES.IT:
        return assetConfig.pushNotification.it;
      case CHANNEL_CODES.LT:
        return assetConfig.pushNotification.lt;
      default:
        return assetConfig.pushNotification.ukie;
    }
  };
  return <img className={style.preview} src={getAsset()} />;
};
