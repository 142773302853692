App.Collections.Products = BackboneEx.Collection.Base.extend({
  model: App.Models.Products,
  url: function () {
    var base;
    base = '/products.json';
    return App.Api.wsVenueUrl(base);
  },

  parse: function (data) {
    return data;
  },

  getProductArray: function () {
    return this.toJSON();
  },
});
