BackboneEx.View.Menu.PricingListBase = Backbone.View.extend({
	getDaysHtml: function() {
		var days = [];
		var dayNo;
		for (var i = 0; i < 7; i++) {
			// Calculating for sunday to be last
			dayNo = (i + 1) % 7;
			days.push({
				day    : Wahanda.lang.date.weekdaysMini[dayNo],
				on     : (-1 !== _.indexOf(this.applicableDays, Wahanda.Date.dayNumberToStringMap[dayNo])),
				dayName: Wahanda.lang.date.weekdays[dayNo]
			});
		}
		
		return Wahanda.Template.renderTemplate('menu-offer-dated-pricing-weekdays', { list: days });
	},
	
	getAmountText: function(amount) {
		return Wahanda.Currency.format(amount || this.amount);
	},
	
	getDiscountText: function(amount, rrpAmount) {
		if ((this.rrpAmount || rrpAmount)> 0) {
			return Wahanda.lang.menu.offer.pricing.texts.discount.replace(
				'{{discount}}',
				Wahanda.Currency.getDiscount(amount || this.amount, rrpAmount || this.rrpAmount)
			);
		}
		return '';
	},
	
	setValues: function(values) {
		_.extend(this, values);
		this.render();
	},
	
	/**
	 * @return int
	 */
	getFirstWeekDayNumber: function() {
		var dayNo = Wahanda.Date.dayStringToNumberMap[this.applicableDays[0]];
		return dayNo === 0 ? 7 : dayNo;
	}
	
});
