// TODO you are not checking that add and edit are successful before update the
//   appState!!!
import { toClj, conj, curry, reduce, assoc, get, hashMap, vector, toJs } from 'mori';
import { mapv } from 'common-js';
import sprintf from 'sprintf';

import { GET, PUT, POST, DELETE } from 'common/superagent';
import { startWaiting, endWaitHashMap } from 'common/waiting';
import Wahanda from 'common/wahanda';

export const RESOURCE_FETCH_WAIT = 'resources-fetch';

export const RESOURCE_UPDATE_WAIT = 'resource-update';

export const RESOURCE_ADD_WAIT = 'resource-add';

export const RESOURCE_DELETE_WAIT = 'resource-delete';

export const FETCH_MENU_WAIT = 'menu-fetch';

export function fetchResources(stateAtom) {
  startWaiting(RESOURCE_FETCH_WAIT, stateAtom);
  GET(sprintf('/api/venue/%s/resources', get(stateAtom.deref(), 'venueId')), (response) => {
    stateAtom.swap(
      curry(conj, hashMap('resources', toClj(response.body)), endWaitHashMap(RESOURCE_FETCH_WAIT)),
    );
  });
}

export function updateResource(stateAtom, resourceId, updatedResource) {
  startWaiting(RESOURCE_UPDATE_WAIT, stateAtom);
  PUT(
    sprintf('/api/venue/%s/resource/%s', get(stateAtom.deref(), 'venueId'), resourceId),
    toJs(updatedResource),
    () => {
      const updatedResources = mapv(
        (r) => (get(r, 'id') === resourceId ? updatedResource : r),
        get(stateAtom.deref(), 'resources'),
      );

      stateAtom.swap(
        curry(conj, hashMap('resources', updatedResources), endWaitHashMap(RESOURCE_UPDATE_WAIT)),
      );
    },
  );
}

export function addResource(stateAtom, newResource) {
  startWaiting(RESOURCE_ADD_WAIT, stateAtom);
  POST(
    sprintf('/api/venue/%s/resource/', get(stateAtom.deref(), 'venueId')),
    toJs(newResource),
    (response) => {
      const resource = assoc(newResource, 'id', response.body.id);

      const updatedResources = conj(get(stateAtom.deref(), 'resources'), resource);

      stateAtom.swap(
        curry(conj, hashMap('resources', updatedResources), endWaitHashMap(RESOURCE_UPDATE_WAIT)),
      );
    },
  );
}

export function deleteResource(stateAtom, resourceId) {
  startWaiting(RESOURCE_DELETE_WAIT, stateAtom);
  DELETE(
    sprintf('/api/venue/%s/resource/%s', get(stateAtom.deref(), 'venueId'), resourceId),
    {},
    () => {
      const updatedResources = reduce(
        (acc, r) => (get(r, 'id') !== resourceId ? conj(acc, r) : acc),
        vector(),
        get(stateAtom.deref(), 'resources'),
      );

      stateAtom.swap(
        curry(conj, hashMap('resources', updatedResources), endWaitHashMap(RESOURCE_DELETE_WAIT)),
      );
    },
  );
}

export function fetchMenu(stateAtom) {
  startWaiting(FETCH_MENU_WAIT, stateAtom);
  Wahanda.Cache.menu().done((menu) => {
    // cleaning the services from not needed data
    const services = mapv(
      (s) =>
        hashMap(
          'id',
          get(s, 'id'),
          'name',
          get(s, 'name'),
          'groupId',
          get(s, 'groupId'),
          'menuItemTypeCode',
          get(s, 'menuItemTypeCode'),
        ),
      toClj(menu.get('offersCollection').toJSON()),
    );
    const groups = toClj(menu.get('groupsCollection').toJSON());

    stateAtom.swap(
      curry(
        conj,
        hashMap('services', services, 'menuGroups', groups),
        endWaitHashMap(FETCH_MENU_WAIT),
      ),
    );
  });
}
