import Cookie from 'js-cookie';
import App from 'common/backbone-app';
import { trunkFeatures } from './trunk-features';

const FEATURES_COOKIE = 'connect-trunk-features';

type Feature = typeof trunkFeatures[number];

export type TrunkFeatureId = Feature['id'];

export function getLocallyEnabledFeatures(): string[] {
  return calculateEnabledFeatures({
    fromConfig: trunkFeatures,
    fromCookie: featuresFromCookie(),
  });
}

export function calculateEnabledFeatures({ fromConfig, fromCookie }): string[] {
  const fromMergedConfig = mergeConfig(fromConfig, fromCookie)
    .filter(({ checked }) => checked)
    .map(({ id }) => id);

  return fromMergedConfig;
}

function canPersistCookie() {
  return !App.isProd;
}

function mergeConfig(defaults: Feature[], fromCookie): Feature[] {
  return defaults.map((toggle) => {
    const cookieToggle = fromCookie.find(({ id }) => id === toggle.id);
    const checked = cookieToggle ? cookieToggle.checked : toggle.checked;

    return {
      ...toggle,
      checked,
    };
  }) as Feature[];
}

function featuresFromCookie(): Feature[] {
  if (!canPersistCookie()) {
    return [];
  }

  const value = Cookie.get(FEATURES_COOKIE);
  return value ? JSON.parse(value) : [];
}

export function updateCookie(features: string[]) {
  const cookieFeatures = trunkFeatures.map((trunkFeature: Feature) => {
    return { ...trunkFeature, checked: features.includes(trunkFeature.id) };
  });

  const persistedCookie = canPersistCookie() ? cookieFeatures : [];

  Cookie.withConverter({
    // @ts-ignore to enable string return type, trunk-features library should be able to deserialize it
    // currently it is not able to do that
    write(val) {
      return val;
    },
  }).set(FEATURES_COOKIE, persistedCookie);
}
