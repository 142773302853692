import { createSelector } from 'reselect';
import { TrunkFeatureId } from '../TrunkFeatures';

export const getTrunkFeatures = (state) => state.trunkFeatures;

export const isFeatureEnabled = createSelector(
  getTrunkFeatures,
  (_, featureId: TrunkFeatureId) => featureId,
  ({ features }, featureId) => features.includes(featureId),
);
