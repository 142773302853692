/* eslint arrow-body-style: 0 */
import React from 'react';
import Formsy from 'formsy-react';
import { get, getIn, toJs } from 'mori';
import classnames from 'classnames';
import keycode from 'keycode';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import extendMixin from 'common/extend-mixin';
import ReactDialog from 'src/components/common/react-dialog';
import FormValidationMixin from 'src/components/mixins/form-validation';
import DialogErrorList from 'src/components/common/dialog/error-list';
import { createRefund } from 'src/services/pos-refund';
import { Loader } from 'components/common/Loader';
import UnderlinedInput from 'components/common/UnderlinedInput/withFormsy';
import { isNotEmpty } from 'common/validators';
import { POS_OPERATIONS } from 'common/constants/pos-operations';
import Pricing from '../common/transaction-summary/pricing';
import constants from '../constants';
import style from './IssueRefundDialog.scss';

interface IIssueRefundDialogProps extends React.HTMLAttributes<Element> {
  operationId?: {
    id: string;
  };
  onClose: (...args: any[]) => any;
  transactionData: {};
  currentDrawerTotal: number;
  actions: {
    requestPosStatusAction: (...args: any[]) => any;
    resetStateAction: (...args: any[]) => any;
    startPOSOperationRequest: (...args: any[]) => any;
  };
  resetStateAction?: any;
  requestPosStatusAction?: any;
}
type IssueRefundDialogState = {
  transactionData?: any;
  formValid?: boolean;
  saving?: boolean;
  refundNote?: any;
  find?: any;
  payments?: any;
};
class IssueRefundDialog extends React.Component<IIssueRefundDialogProps, IssueRefundDialogState> {
  defaultSaveFailHandler: any;

  dialog: any;

  formRef: any;

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'getErrorList' has no initializer and is ... Remove this comment to see the full error message
  getErrorList: any[];

  notes: any;

  static defaultProps = {
    operationId: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      transactionData: toJs(props.transactionData),
    };
    extendMixin(this, FormValidationMixin);
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.startPOSOperationRequest({
      operation: POS_OPERATIONS.REFUND,
    });
    if (this.notes) {
      this.notes.focus();
    }
    if (this.hasCashPayment()) {
      this.props.actions.requestPosStatusAction();
    }
  }

  componentWillUnmount() {
    if (this.hasCashPayment()) {
      this.props.actions.resetStateAction();
    }
  }

  onValid = () => {
    this.setState({
      formValid: true,
    });
  };

  onInvalid = () => {
    this.setState({
      formValid: false,
    });
  };

  onKeyUp = (event) => {
    if (keycode('enter') === event.keyCode) {
      this.formRef.submit();
    }
  };

  onFormSubmit = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (this.isSubmitDisabled()) {
      return;
    }
    if (!this.state.formValid) {
      return;
    }
    const note = this.state.refundNote;
    const promise = createRefund({
      checkoutId: getIn(this.props.transactionData, ['checkoutInformation', 'checkoutId']),
      paymentsToRefund: get(this.props.transactionData, 'payments'),
      notes: note,
      operationId: this.props.operationId,
    });
    promise
      .done((res) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'receiptId' does not exist on type '{ rec... Remove this comment to see the full error message
        const { receiptId, tipReceiptId, receiptCopyUri } = res;
        this.props.onClose({ receiptId, tipReceiptId, receiptCopyUri });
      })
      .fail(this.defaultSaveFailHandler(Wahanda.lang.reports.transactions.issueRefund.errors));
    this.setState({
      saving: true,
    });
  };

  // TODO: Need refactor: use React component for currency formatting
  getCurrentDrawerTotalFormatted = () => {
    return Wahanda.Currency.format(this.props.currentDrawerTotal, {
      forceDecimalPoint: true,
    });
  };

  getCashPayment = () => {
    return this.state.transactionData.payments.find(
      (payment) => payment.type === constants.PAYMENT_TYPE_CASH,
    );
  };

  getErrorListForView = () => {
    // @ts-expect-error ts-migrate(2349) FIXME: Type 'any[]' has no call signatures.
    const errorList = this.getErrorList() || [];
    if (this.isNotEnoughCashInDrawerForRefund()) {
      const errorTranslation =
        Wahanda.lang.reports.transactions.issueRefund.errors.notEnoughCashInDrawer;
      const text = Wahanda.Template.render(errorTranslation, {
        cashInDrawer: this.getCurrentDrawerTotalFormatted(),
      });
      errorList.push({ text });
    }
    return errorList;
  };

  submitForm = () => {
    this.formRef.submit();
  };

  isSubmitDisabled = () => {
    return this.state.saving || this.isNotEnoughCashInDrawerForRefund();
  };

  isNotEnoughCashInDrawerForRefund = () => {
    return (
      this.hasCashPayment() && this.getCashPayment().costAmount > this.props.currentDrawerTotal
    );
  };

  hasCashPayment = () => {
    return !!this.getCashPayment();
  };

  isLoading = () => this.hasCashPayment() && this.props.currentDrawerTotal == null;

  renderErrors = () => {
    const errorList = this.getErrorListForView();
    return errorList.length ? <DialogErrorList errors={errorList} /> : null;
  };

  renderText = () => {
    const lang = Wahanda.lang.reports.transactions.issueRefund;
    if (!App.config.get('venue').pointOfSaleEnabled) {
      return lang.textPosLite;
    }
    const ci = get(this.props.transactionData, 'checkoutInformation');
    return (
      <React.Fragment>
        {lang.textBeforeTransactionNo}
        <span className="non-split strong"> # {get(ci, 'transactionId')}</span>
        {lang.textAfterTransactionNo}
      </React.Fragment>
    );
  };

  render() {
    const lang = Wahanda.lang.reports.transactions.issueRefund;
    const state = this.state;
    const buttonClasses = classnames('dialog2--button dialog2--button-right dialog2--button-red', {
      'dialog2--button-disabled': this.isSubmitDisabled(),
    });
    return (
      <ReactDialog
        title={lang.title}
        classes={{ 'transactions-dialog issue-refund': true }}
        onClose={this.props.onClose}
        width={400}
        dataTest="transaction-refund-dialog"
        ref={(dialog) => {
          this.dialog = dialog;
        }}
      >
        {this.isLoading() ? (
          <div className="transactions-dialog--loading">
            <Loader />
          </div>
        ) : (
          <Formsy
            onValidSubmit={this.onFormSubmit}
            onKeyUp={this.onKeyUp}
            onValid={this.onValid}
            onInvalid={this.onInvalid}
            ref={(form) => {
              this.formRef = form;
            }}
          >
            {this.renderErrors()}
            <div className="text-row">{this.renderText()}</div>
            <Pricing
              isCancellation={false}
              data={this.props.transactionData}
              lang={Wahanda.lang.reports.transactions.checkoutSummary}
            />
            <div className={style.note}>
              <UnderlinedInput
                label={lang.note}
                name="refundNote"
                value={this.state.refundNote}
                onChange={(e) => this.setState({ refundNote: e.target.value })}
                disabled={state.saving}
                required
                validations={{
                  [isNotEmpty.KEY]: isNotEmpty.VALIDATOR,
                }}
                validationErrors={{
                  [isNotEmpty.KEY]: lang.errors.noteIsRequired,
                  isDefaultRequiredValue: lang.errors.noteIsRequired,
                }}
              />
            </div>
            <div className={classnames(style.borderTop, 'dialog2--big-buttons no-side-margins')}>
              <div
                className={buttonClasses}
                onClick={this.submitForm}
                data-test="refund-submit-button"
              >
                {state.saving ? Wahanda.lang.shared.saving : lang.buttonText}
              </div>
            </div>
          </Formsy>
        )}
      </ReactDialog>
    );
  }
}

export default IssueRefundDialog;
