import React from 'react';
import { SelfSetupTask } from 'components/shared/OnboardingWidget/models/SelfSetupTask';
import { ProgressBar } from 'components/common/ProgressBar';

export const Progress = ({ tasks }: { tasks: SelfSetupTask[] }) => {
  if (!tasks.length) {
    return null;
  }

  const percentage = Math.round(
    (tasks.filter((task) => task.isCompleted).length / tasks.length) * 100,
  );

  return (
    <ProgressBar value={percentage} label={`${percentage}%`} size="small" colour="secondary" />
  );
};
