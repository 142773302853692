import React from 'react';
import classnames from 'classnames';

import style from './style.scss';

interface Props {
  className?: string;
}

export class TableFooter extends React.PureComponent<Props> {
  public render() {
    const { children, className, ...rest } = this.props;
    return (
      <tfoot className={classnames(className, style.footer)} {...rest}>
        {children}
      </tfoot>
    );
  }
}
