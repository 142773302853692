import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../actions';
import MenuItem from './index';

const mapDispatchToProps = (dispatch) =>
  Object.assign(
    {},
    {
      actions: bindActionCreators(actions, dispatch),
    },
  );

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ discountId: null; discountName: null; disc... Remove this comment to see the full error message
export default connect(null, mapDispatchToProps)(MenuItem);
