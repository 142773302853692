;(function() {
	BackboneEx.View.Dialog.LoginRequest = BackboneEx.View.Dialog.extend({
		events: {
			"click button.button-primary": "redirectLogin"
		},

		options: {
			title: "",
			closeOnEscape: false,

			titleText: "",
			bodyText: "",
			loginButtonText: "",
			dialogId: "",
			dataTest: 'login-request-modal',
		},
		templateId: "login-request-dialog",

		removeHashFromReturnUrl: false,

		getDialogTemplateVariables: function() {
			return {
				"dialogId"   : this.options.dialogId,
				"title"      : this.options.titleText,
				"text"       : this.options.bodyText,
				"loginButton": this.options.loginButtonText
			};
		},

		redirectLogin: function() {
			var location = document.URL;

			if (this.removeHashFromReturnUrl) {
				var hashPos  = location.indexOf("#");
				if (hashPos > -1) {
					location = location.substring(0, hashPos);
				}
			}

			window.location = App.loginRedirectUrl(location);
		}
	});
}());