import React from 'react';

interface Props {
  className?: string;
}

class ComputerBookNow extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-computer-book-now', this.props.className].join(' ')}
        viewBox="0 0 96 48"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#5CCED3"
            d="M79.335,5.95 L79.335,42.57 L38.265,42.57 C38.265,42.57 70.544,32.247 72.831,1.11 L74.546,1.11 C77.1987982,1.13686932 79.335,3.29506569 79.335,5.948"
            fillRule="nonzero"
          />
          <path
            stroke="#232D56"
            d="M16.986,36.798 L16.92,15.051 L16.92,5.829 C16.92,3.244 19.017,1.135 21.59,1.135 L41.48,1.135 M83.122,42.571 L12.633,42.571"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            stroke="#232D56"
            d="M42.118,1.106 L70.853,1.159 L75.165,1.159 C77.19,1.159 78.835,2.812 78.835,4.849 L78.835,38.977 C78.8323442,39.939737 79.2122448,40.8640974 79.8911273,41.5467306 C80.5700098,42.2293638 81.492263,42.6143515 82.455,42.617 L93.056,42.617 C94.247,42.617 94.842,44.079 94.008,44.917 L93.748,45.18 C92.77,46.162 91.436,46.7380033 90.055,46.7380033 L5.343,46.7380033 C3.95193407,46.7395805 2.61957971,46.1774879 1.65,45.18 L1.388,44.918 C0.556,44.055 1.151,42.618 2.342,42.618 L13.347,42.618"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            fill="#FFB500"
            d="M67.866,25.753 C67.866,26.36 67.368,26.857 66.76,26.857 L28.995,26.857 C28.3854078,26.8559036 27.8911997,26.3625892 27.889,25.753 L27.889,16.252 C27.889,15.644 28.387,15.147 28.995,15.147 L66.76,15.147 C67.368,15.147 67.866,15.644 67.866,16.252 L67.866,25.752 L67.866,25.753 Z"
            fillRule="nonzero"
          />
          <path
            stroke="#232D56"
            d="M67.866,25.753 C67.866,26.36 67.368,26.857 66.76,26.857 L28.995,26.857 C28.3854078,26.8559036 27.8911997,26.3625892 27.889,25.753 L27.889,16.252 C27.889,15.644 28.387,15.147 28.995,15.147 L66.76,15.147 C67.368,15.147 67.866,15.644 67.866,16.252 L67.866,25.752 L67.866,25.753 Z"
          />
          <g
            fill="#232D56"
            transform="translate(30 15)"
            fillRule="nonzero"
            fontFamily="SourceSansPro-Bold, Source Sans Pro"
            fontSize="8"
            fontWeight="bold"
          >
            <text>
              <tspan x=".358" y="8.735">
                Book n
              </tspan>
            </text>
            <text>
              <tspan x="24.638" y="8.735">
                o
              </tspan>
            </text>
            <text>
              <tspan x="28.959" y="8.735">
                w
              </tspan>
            </text>
          </g>
          <g transform="translate(23 25)">
            <path
              fill="#FFF"
              d="M9.307,1.759 L7.627,8.514 C7.507,9.01 6.816,9.089 6.577,8.634 L5.747,6.969 C5.57,6.633 5.115,6.553 4.858,6.831 L2.583,9.108 C2.37011839,9.32645293 2.07802502,9.44965787 1.773,9.44965787 C1.46797498,9.44965787 1.17588161,9.32645293 0.963,9.108 C0.744315047,8.89442663 0.621020176,8.60167416 0.621020176,8.296 C0.621020176,7.99032584 0.744315047,7.69757337 0.963,7.484 L3.216,5.226 C3.34572493,5.10158555 3.40661169,4.9217171 3.37913086,4.74408738 C3.35165002,4.56645766 3.23925633,4.41339717 3.078,4.334 L1.456,3.521 C1.24690699,3.41403278 1.12598889,3.18868537 1.15246257,2.95531654 C1.17893625,2.7219477 1.34725956,2.52941223 1.575,2.472 L8.318,0.788 C8.912,0.608 9.445,1.144 9.308,1.758"
              fillRule="nonzero"
            />
            <path
              stroke="#232D56"
              d="M9.307,1.759 L7.627,8.514 C7.507,9.01 6.816,9.089 6.577,8.634 L5.747,6.969 C5.57,6.633 5.115,6.553 4.858,6.831 L2.583,9.108 C2.37011839,9.32645293 2.07802502,9.44965787 1.773,9.44965787 C1.46797498,9.44965787 1.17588161,9.32645293 0.963,9.108 C0.744315047,8.89442663 0.621020176,8.60167416 0.621020176,8.296 C0.621020176,7.99032584 0.744315047,7.69757337 0.963,7.484 L3.216,5.226 C3.34572493,5.10158555 3.40661169,4.9217171 3.37913086,4.74408738 C3.35165002,4.56645766 3.23925633,4.41339717 3.078,4.334 L1.456,3.521 C1.24690699,3.41403278 1.12598889,3.18868537 1.15246257,2.95531654 C1.17893625,2.7219477 1.34725956,2.52941223 1.575,2.472 L8.318,0.788 C8.912,0.608 9.445,1.144 9.308,1.758 L9.307,1.759 Z"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export const icon = ComputerBookNow;
