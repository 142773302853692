/* global jQuery */
/* eslint react/no-find-dom-node: 0 */
import React from 'react';
import { findDOMNode } from 'react-dom';
import classnames from 'classnames';
import UnderlinedInput from 'components/common/UnderlinedInput/withFormsy';
import styles from './UserAutocomplete.scss';
import renderMenu from './dropdown';

interface Props {
  name: string;
  dataSource: any;
  classes?: string;
  spinnerClasses?: string;
  placeholder?: string;
  wrapperClasses?: string;
  value?: string;
  onChange: (...args: any) => void;
  onClose?: () => void;
  onOpen?: () => void;
  onSelect: (...args: any) => void;
  required?: boolean;
  validations?: any;
  validationErrors?: any;
  disableAutoComplete?: boolean;
  dropdownWidth?: number;
  dataTest?: string;
  hasError?: boolean;
}

export class UserAutocomplete extends React.Component<Props> {
  public static defaultProps = {
    value: '',
    classes: null,
    placeholder: null,
    spinnerClasses: null,
    wrapperClasses: null,
    onOpen: null,
    onClose: null,
    required: false,
    validations: null,
    validationErrors: null,
    disableAutoComplete: false,
    dropdownWidth: null,
    hasError: false,
  };

  public state = {
    searching: false,
  };

  public componentDidMount() {
    // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type 'string'.
    const inpotDOM = jQuery(findDOMNode(this)).find('input');
    const { dropdownWidth } = this.props;
    inpotDOM.userAutocomplete({
      // We wrap the dataSource function to be able to toggle the `searching` state param.
      source: ({ query, response }) => {
        this.setState({ searching: true });
        this.props.dataSource({
          query,
          response: (data) => {
            this.setState({ searching: false });
            response(data);
          },
        });
      },
      select: this.onSelect,
      renderMenu,
      resizeMenu: function resizeMenu() {
        const $ul = this.menu.element;
        const $input = this.element;
        $ul.scrollTop(0);
        // Force the dropdown to be the same width as the input
        $ul.outerWidth(dropdownWidth != null ? dropdownWidth : $input.outerWidth());

        $ul.height('');
        const listTop = $input.offset().top;
        const winHeight = window.innerHeight || $(window).height();
        let maxHeight = winHeight - listTop - 60;

        if (!isNaN(this.options.maxHeight)) {
          maxHeight = Math.min(maxHeight, this.options.maxHeight);
        }

        if ($ul.height() > maxHeight) {
          $ul.height(maxHeight);
        }
      },
      // The dropdown goes a bit on the bottom of the input
      position: {
        offset: '0 -2',
      },
      open: this.onDropdownOpen,
      close: this.onDropdownClose,
    });

    // inpotDOM.attr('autocomplete', 'off');
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { disableAutoComplete } = nextProps;
    const autocomplete = disableAutoComplete ? 'disable' : 'enable';

    // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type 'string'.
    jQuery(findDOMNode(this)).find('input').autocomplete(autocomplete);
  }

  public componentWillUnmount() {
    try {
      // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type 'string'.
      jQuery(findDOMNode(this)).find('input').autocomplete('destroy');
    } catch (e) {
      // noop
    }
  }

  public onSelect = (evt, data) => {
    this.onDropdownClose();
    this.props.onSelect(data.item);
  };

  public onDropdownOpen = () => {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  public onDropdownClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  public render() {
    const {
      name,
      wrapperClasses,
      classes: propClasses,
      placeholder,
      spinnerClasses,
      value,
      onChange,
      required,
      validationErrors,
      validations,
      dataTest,
      hasError,
    } = this.props;
    const { searching } = this.state;
    const wrapperClassName = classnames(styles.wrapper, wrapperClasses);
    const inputClasses = classnames(propClasses, styles.input, searching ? styles.searching : null);

    let spinner = null;
    if (searching) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
      spinner = <div className={classnames(styles.spinner, spinnerClasses)} />;
    }
    return (
      <div className={wrapperClassName}>
        <UnderlinedInput
          dataTest={dataTest}
          autoComplete="off"
          value={value}
          name={name}
          inputClassName={inputClasses}
          placeholder={placeholder}
          onChange={onChange}
          validations={validations}
          validationErrors={validationErrors}
          required={required}
          hasError={hasError}
          type="search"
        />
        {spinner}
      </div>
    );
  }
}
