import React, { Fragment, useEffect } from 'react';
import styles from './OnboardingWidget.scss';
import { useChecklist } from 'components/shared/OnboardingWidget/hooks/useChecklist';
import { SelfSetupTask } from 'components/shared/OnboardingWidget/models/SelfSetupTask';
import { getTrackingByEventName } from 'src/tracking-container/TrackingEvents';
import { ChecklistCompleted } from './partials/ChecklistCompleted';
import { GetOnline } from './partials/GetOnline';
import { Progress } from './partials/Progress';
import { Task } from './partials/Task';

const trackEvent = getTrackingByEventName('self_onboarding_viewed');

export const OnboardingWidget = () => {
  const { data, isLoading, isError } = useChecklist();
  const { indexTaskOpen, toggleTaskOpen } = useOpenTask({ tasks: data?.tasks });

  useEffect(() => {
    if (data?.tasks) {
      trackEvent();
    }
  }, [data?.tasks]);

  if (isLoading || isError || !data) {
    return null;
  }

  const { tasks, tasksCompleted, checklistCompleted } = data;

  if (checklistCompleted) {
    return <ChecklistCompleted tasks={tasks.length} />;
  }

  if (!tasks.length) {
    return null;
  }

  const widgetTitle = Wahanda.Template.render(Wahanda.lang.onboardingWidget.title, {
    steps: tasks.length,
  });

  return (
    <section className={styles.container}>
      <h6 className={styles.title}>{widgetTitle}</h6>
      <Progress tasks={tasks} />
      {tasks.map((task, index) => (
        <Fragment key={task.title}>
          <Task
            index={index + 1}
            open={index === indexTaskOpen}
            onToggle={() => toggleTaskOpen(index)}
            {...task}
          />
          {index < tasks.length - 1 && <div className={styles.divider} />}
        </Fragment>
      ))}
      {tasksCompleted && (
        <>
          <div className={styles.divider} />
          <GetOnline />
        </>
      )}
    </section>
  );
};

const useOpenTask = ({ tasks }: { tasks?: SelfSetupTask[] }) => {
  const [indexTaskOpen, setIndexTaskOpen] = React.useState<number | null>(null);

  useEffect(() => {
    if (tasks) {
      const nextTaskToComplete = tasks.findIndex((task) => !task.isCompleted);
      setIndexTaskOpen(nextTaskToComplete);
    }
  }, [tasks]);

  const toggleTaskOpen = (index: number) =>
    setIndexTaskOpen(indexTaskOpen === index ? null : index);

  return { indexTaskOpen, toggleTaskOpen };
};
