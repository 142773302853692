import moment from 'common/moment';
import { allEmployeesOptionValue } from '../EmployeeFilter/index';
import { CHANNEL } from '../BookingChannelFilter';

export function serialiseFilters(filters) {
  const result = [filters.fromDate.format('YYYY-MM-DD'), filters.toDate.format('YYYY-MM-DD')];
  if (filters.selectedEmployeeId) {
    result.push(filters.selectedEmployeeId.toString());
  }
  if (filters.selectedChannel) {
    result.push(filters.selectedChannel);
  }
  return result;
}

export function deserialiseFilters(serialisedFilters, includeChannel, includeEmployee) {
  let size = 2;

  const defaultRes = {
    fromDate: moment().startOf('month'),
    toDate: moment().startOf('day'),
  };

  if (serialisedFilters && serialisedFilters.length >= 2) {
    defaultRes.fromDate = moment(serialisedFilters[0]);
    defaultRes.toDate = moment(serialisedFilters[1]);
  }

  if (includeEmployee) {
    size += 1;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedEmployeeId' does not exist on ty... Remove this comment to see the full error message
    defaultRes.selectedEmployeeId = allEmployeesOptionValue;
  }
  if (includeChannel) {
    size += 1;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedChannel' does not exist on type ... Remove this comment to see the full error message
    defaultRes.selectedChannel = CHANNEL.ALL;
  }
  if (!serialisedFilters || serialisedFilters.length !== size) {
    return defaultRes;
  }

  const result = {
    fromDate: moment(serialisedFilters[0]),
    toDate: moment(serialisedFilters[1]),
  };
  let index = 2;
  if (includeEmployee) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedEmployeeId' does not exist on ty... Remove this comment to see the full error message
    result.selectedEmployeeId = parseInt(serialisedFilters[index], 10);
    index += 1;
  }

  if (includeChannel) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedChannel' does not exist on type ... Remove this comment to see the full error message
    result.selectedChannel = serialisedFilters[index];
  }

  return result;
}
