import {
  WIDGET_TOGGLE_ENABLED_REQUEST,
  WIDGET_TOGGLE_ENABLED_SUCCESS,
  WIDGET_TOGGLE_ENABLED_FAILURE,
  WIDGET_GENERATE_CODE_REQUEST,
  WIDGET_GENERATE_CODE_SUCCESS,
  WIDGET_GENERATE_CODE_FAILURE,
  WIDGET_SELECT_LANGUAGE,
  WIDGET_CLEAN_STORE,
  WIDGET_CLEAR_ERROR,
} from 'reduxStore/actionsConst';

const widgetSettings = (state: any = {}, action) => {
  switch (action.type) {
    case WIDGET_TOGGLE_ENABLED_REQUEST:
      return {
        ...state,
        saving: true,
      };
    case WIDGET_TOGGLE_ENABLED_SUCCESS:
      return {
        saving: false,
        enabled: action.params.enabled,
      };
    case WIDGET_TOGGLE_ENABLED_FAILURE:
      return {
        ...state,
        saving: false,
        error: true,
      };
    case WIDGET_GENERATE_CODE_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case WIDGET_GENERATE_CODE_SUCCESS:
      return {
        ...state,
        ...action.data,
        fetching: false,
      };
    case WIDGET_GENERATE_CODE_FAILURE:
      return {
        ...state,
        fetching: false,
      };
    case WIDGET_SELECT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.lang,
      };
    case WIDGET_CLEAN_STORE: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { enabled, ...rest } = state;
      return rest;
    }
    case WIDGET_CLEAR_ERROR:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
};

export const getWidgetSettings = (state) => state.widgetSettings || {};

export default widgetSettings;
