import React, { memo, useState, useRef } from 'react';
import classnames from 'classnames';
import { useOnClickOutside } from 'utilities/hooks';
import { Chevron } from 'components/common/Icon';
import { Avatar } from 'components/common/Avatar';
import { Employee } from '../utils/types';
import style from './ShiftEmployeeSelector.scss';

interface Props {
  onClick: (id: number) => void;
  employeeId: number;
  // redux
  employee: Employee;
  employees: Employee[];
}

export const ShiftEmployeeSelector = memo(({ employee, employees, employeeId, onClick }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showEmployeeSelect, setShowEmployeeSelect] = useState(false);
  const isSingleEmployee = employees.length === 1;
  const selectList = employees
    .filter((e) => e.employeeId !== employeeId)
    .map((emp) => {
      const handleEmployeeChange = () => {
        onClick(emp.employeeId);
        setShowEmployeeSelect(!showEmployeeSelect);
      };
      return (
        <div className={style.employee} key={emp.employeeId} onClick={handleEmployeeChange}>
          <Avatar text={emp.employeeName} src={emp.employeeImage} type="medium" />
          <div className={style.employeeName}>{emp.employeeName}</div>
        </div>
      );
    });
  const handleEmployeeSelectShow = () => {
    if (isSingleEmployee) {
      return;
    }
    setShowEmployeeSelect(!showEmployeeSelect);
  };
  const selectListClass = classnames(style.selectList, {
    [style.listVisible]: showEmployeeSelect,
  });
  const containerClass = classnames(style.container, {
    [style.isSingleEmployee]: isSingleEmployee,
  });

  useOnClickOutside(containerRef, handleEmployeeSelectShow, showEmployeeSelect);

  return (
    <div className={containerClass} ref={containerRef}>
      <div className={style.employee} onClick={handleEmployeeSelectShow}>
        <Avatar text={employee.employeeName} src={employee.employeeImage} type="medium" />
        <div className={style.employeeName}>{employee.employeeName}</div>
        {!isSingleEmployee && <Chevron className={style.arrow} />}
      </div>
      {!isSingleEmployee && (
        <div className={style.selectListWrapper}>
          <div className={selectListClass}>{selectList}</div>
        </div>
      )}
    </div>
  );
});
