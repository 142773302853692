// Taken from react router v6
export enum NavigationType {
  Pop = 'POP',
  Push = 'PUSH',
  Replace = 'REPLACE',
}

export type UpdateLocationParams = {
  pathname: string;
  navigationType: NavigationType;
};

export const updateLocation = (data: UpdateLocationParams) => {
  if (data.navigationType === NavigationType.Push) {
    window.history.pushState(null, '', data.pathname);
  } else if (data.navigationType === NavigationType.Replace) {
    window.history.replaceState(null, '', data.pathname);
  }
};

// Returns the base path of the store, including the language code if present
export const getStoreBasePath = (): string | null => {
  const path = window.location.pathname;
  const match = path.match(/^((?:\/[a-zA-Z]{2,3}(?:[_-][a-zA-Z]{2})?)?\/store)/g);
  if (match) {
    return match[0];
  }
  return null;
};

export const isOnStore = (): boolean => getStoreBasePath() !== null;
