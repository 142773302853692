import React from 'react';

interface Props {
  size?: number;
  className?: string;
}

export const PlusIcon = ({ size = 12, ...props }: Props) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 12 12" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.666.658v4.654l4.669.013-.004 1.333-4.665-.014v4.65H5.333V6.642L.665 6.628l.003-1.333 4.665.013V.658h1.333z"
      clipRule="evenodd"
    ></path>
  </svg>
);
