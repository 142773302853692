/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';

import Wahanda from 'common/wahanda';
import { useImmer } from 'use-immer';
import { Input } from 'components/common/Input';

import { Analytics } from '../tracking';

import style from './AdvancedReminderSettings.scss';
import { ReminderSettings } from 'components/marketing/AutomatedMessaging/types';
import { AdvancedSettingsProps } from 'components/common/SettingBox';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/common/Modal';
import { Button } from 'components/common/Button';
import {
  PHONE_NUMBER_LENGTH,
  getPhoneNumberValidationError,
} from 'components/marketing/AutomatedMessaging/AdvancedReminderSettings/validatePhoneNumber';
import { Switch } from 'components/common/Switch';

const lang = Wahanda.lang.marketing.automatedMessaging.inviteToRebook.advanced;

const SMS_SENDER_PLACEHOLDER = 'Treatwell';

const SMS_SENDER_LABEL = Wahanda.Template.render(lang.phoneLabelDescription, {
  min: PHONE_NUMBER_LENGTH.MIN,
  max: PHONE_NUMBER_LENGTH.MAX,
});

export interface Props extends AdvancedSettingsProps {
  sendEmail: boolean;
  sendSms: boolean;
  emailHours: number;
  smsHours: number;
  smsFrom: string;
  showSmsSenderName: boolean;
  actions: {
    setReminderSettingsRequest: (payload: ReminderSettings) => void;
  };
}

export const AdvancedReminderSettings = ({
  sendEmail,
  emailHours,
  sendSms,
  smsHours,
  smsFrom,
  showSmsSenderName,
  onClose,
  actions: { setReminderSettingsRequest },
}: Props) => {
  React.useEffect(() => {
    Analytics.trackAdvancedRemindersView();
  }, []);

  const [isFromNumberValid, setFromNumberValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [reminderSettings, updateReminderSettings] = useImmer<ReminderSettings>({
    sendEmailReminders: sendEmail,
    emailReminderHours: emailHours,
    sendSmsReminders: sendSms,
    smsReminderHours: smsHours,
    smsFromNumber: smsFrom,
  });

  const validateSmsFromNumber = (smsFromNumber: string) => {
    // if we don't show the sms sender name, we don't need to validate it
    if (!showSmsSenderName) return setFromNumberValid(true);

    const validationError = getPhoneNumberValidationError(smsFromNumber);

    setFromNumberValid(!validationError);
    setErrorMessage(validationError || '');
  };

  const updateSmsFromNumber = (smsFromNumber: string) => {
    validateSmsFromNumber(smsFromNumber);
    updateReminderSettings((draft) => {
      draft.smsFromNumber = smsFromNumber;
    });
  };

  React.useEffect(() => {
    if (reminderSettings.sendSmsReminders) {
      validateSmsFromNumber(smsFrom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminderSettings.sendSmsReminders]);

  const updateSendEmailReminders = (sendEmailReminders = false) => {
    updateReminderSettings((draft) => {
      draft.sendEmailReminders = sendEmailReminders;
    });
  };

  const updateEmailReminderHours = (emailReminderHours: number) => {
    updateReminderSettings((draft) => {
      draft.emailReminderHours = emailReminderHours;
    });
  };

  const updateSendSmsReminders = (sendSmsReminders = false) => {
    updateReminderSettings((draft) => {
      draft.sendSmsReminders = sendSmsReminders;
    });
  };

  const updateSmsReminderHours = (smsReminderHours: number) => {
    updateReminderSettings((draft) => {
      draft.smsReminderHours = smsReminderHours;
    });
  };

  const handleClose = () => {
    Analytics.trackAdvancedRemindersAction('close');
    onClose();
  };

  const dialogContent = (
    <div className={style.container}>
      <div className={style.formSection}>
        <Switch
          className={style.switchWrapper}
          labelClassName={style.switchLabel}
          checked={reminderSettings.sendEmailReminders}
          onChange={(checked) => {
            updateSendEmailReminders(checked);
          }}
          label={lang.email}
        />
        {reminderSettings.sendEmailReminders && (
          <div className={style.inputWrapper}>
            <Input
              name="emailRemindersHours"
              label={lang.timeLabel}
              value={reminderSettings.emailReminderHours}
              onChange={(value) => updateEmailReminderHours(parseInt(value as string, 10))}
              type="number"
              disabled={!reminderSettings.sendEmailReminders}
              fullBorder
            />
          </div>
        )}
      </div>
      <div className={style.formSection}>
        <Switch
          className={style.switchWrapper}
          labelClassName={style.switchLabel}
          checked={reminderSettings.sendSmsReminders}
          onChange={(checked) => updateSendSmsReminders(checked)}
          label={lang.sms}
        />

        {reminderSettings.sendSmsReminders && (
          <>
            <div className={style.inputWrapper}>
              <Input
                label={lang.timeLabel}
                onChange={(value) => updateSmsReminderHours(parseInt(value as string, 10))}
                name="smsHours"
                type="number"
                value={reminderSettings.smsReminderHours}
                disabled={!reminderSettings.sendSmsReminders}
                fullBorder
              />
            </div>
            {showSmsSenderName && (
              <div className={style.formSection}>
                <div className={style.formTitle}>{lang.phoneLabel}</div>
                <div className={style.inputWrapper}>
                  <Input
                    onChange={(value) => updateSmsFromNumber(String(value))}
                    name="phoneNumber"
                    value={reminderSettings.smsFromNumber}
                    disabled={!reminderSettings.sendSmsReminders}
                    placeholder={SMS_SENDER_PLACEHOLDER}
                    hasError={!isFromNumberValid}
                    errorMessage={errorMessage}
                    label={SMS_SENDER_LABEL}
                    fullBorder
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );

  const shouldAllowToSubmit = () => {
    const {
      sendEmailReminders,
      emailReminderHours,
      sendSmsReminders,
      smsReminderHours,
    } = reminderSettings;

    if (sendEmailReminders && sendSmsReminders) {
      return emailReminderHours && smsReminderHours && isFromNumberValid;
    }

    if (sendEmailReminders) {
      return Boolean(emailReminderHours);
    }

    if (sendSmsReminders) {
      return Boolean(smsReminderHours) && isFromNumberValid;
    }

    // should allow to clear settings
    return true;
  };

  const handleSubmit = () => {
    Analytics.trackAdvancedRemindersAction('save');
    setReminderSettingsRequest(reminderSettings);
    onClose();
  };

  return (
    <ModalContainer onClose={handleClose}>
      <ModalHeader title={lang.title} onClose={handleClose} />
      <ModalBody>{dialogContent}</ModalBody>
      <ModalFooter>
        <Button
          dataTest="advanced_reminder-settings-button-close"
          label={Wahanda.lang.shared.buttons.close}
          colour="plain"
          variant="secondary"
          onClick={handleClose}
        />
        <Button
          dataTest="advanced_reminder-settings-save"
          label={Wahanda.lang.shared.buttons.save}
          variant="primary"
          disabled={!shouldAllowToSubmit()}
          onClick={handleSubmit}
        />
      </ModalFooter>
    </ModalContainer>
  );
};
