import React from 'react';
import { Select } from 'components/common/Select';
import Wahanda from 'common/wahanda';
import { allEmployeesOptionValue, allChairRenters, allFullTimeEmployees } from './constants';

export { allEmployeesOptionValue, allChairRenters, allFullTimeEmployees };

interface IEmployeeFilterProps extends React.HTMLAttributes<Element> {
  selected?: number;
  onChange?: (...args: any[]) => any;
  loadVenueEmployeeDetails: (...args: any[]) => any;
  categoryFilterEnabled?: boolean;
  employeeDetails: {
    id?: number;
    name?: string;
  }[];
  sort?: any;
  map?: any;
}

export class EmployeeFilter extends React.Component<IEmployeeFilterProps, {}> {
  static defaultProps = {
    categoryFilterEnabled: false,
    selected: allEmployeesOptionValue,
    onChange: () => {},
  };

  componentDidMount = () => {
    this.props.loadVenueEmployeeDetails();
  };

  getEmployeesByCategory = () => {
    const categoryGroupId = 1;
    const employeeGroupId = 2;
    return [
      {
        grouping: true,
        name: Wahanda.lang.reports.sales.filters.employee.categories,
        value: categoryGroupId,
      },
      {
        name: Wahanda.lang.reports.sales.filters.employee.allTeamMembers,
        value: allEmployeesOptionValue,
        groupingValue: categoryGroupId,
      },
      {
        name: Wahanda.lang.reports.sales.filters.employee.fullTime,
        value: allFullTimeEmployees,
        groupingValue: categoryGroupId,
      },
      {
        name: Wahanda.lang.reports.sales.filters.employee.contractor,
        value: allChairRenters,
        groupingValue: categoryGroupId,
      },
      {
        grouping: true,
        name: Wahanda.lang.reports.sales.filters.employee.teamMembers,
        value: employeeGroupId,
      },
      ...this.getEmployeeList().map((employee) => ({
        ...employee,
        groupingValue: employeeGroupId,
      })),
    ];
  };

  getEmployeeOptions = () => {
    if (this.props.categoryFilterEnabled) {
      return this.getEmployeesByCategory();
    }
    return [
      {
        name: Wahanda.lang.reports.sales.filters.employee.allEmployees,
        value: allEmployeesOptionValue,
      },
      ...this.getEmployeeList(),
    ];
  };

  getEmployeeList = () => {
    return (
      this.props.employeeDetails
        .map((employee) => ({
          name: employee.name,
          value: employee.id,
        }))
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        .sort((l, r) => l.name.localeCompare(r.name))
    );
  };

  employeeSelected = (selected) => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.onChange(selected);
  };

  render() {
    return (
      <Select
        dataTest="employee-filter"
        onSelect={this.employeeSelected}
        selectedOption={this.props.selected}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
        options={this.getEmployeeOptions()}
      />
    );
  }
}
