import React from 'react';
import { toClj } from 'mori';
import uuid from 'uuid';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import { Tooltip } from 'components/common/Tooltip';
import PriceInput from 'components/common/forms/price-input';
import Dropdown from 'components/common/SelectDropdown';
import formatPrice from 'common/formatters';
import { Button } from 'components/common/Button';
import { Close } from 'components/common/Icon';
import {
  FINISHING_TIME_SLOTS_WITH_NULL,
  FINISHING_TIME_DEFAULT,
} from 'common/constants/timeChoices';
import { ServiceEditLabel } from 'components/menu/ServicesTab/tracking';
import SkuName from './SkuName';
import style from '../style.scss';

const DEFAULT_DURATION = 60;

export const DELETE_BUTTON_STATE = {
  DISABLED: 'DISABLED',
  HIDDEN: 'HIDDEN',
  DEFAULT: 'DEFAULT',
};

const LANG = Wahanda.lang.menu.offer.sku;
// @ts-expect-error ts-migrate(2430) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
interface IPricingRowProps extends React.HTMLAttributes<Element> {
  id?: number;
  name?: string;
  fullPriceAmount?: number;
  discountPriceAmount?: number;
  duration?: number;
  processingTime?: number;
  finishingTimeMins?: number;
  useProcessingTime: boolean;
  onProcessingTimeSelected: (...args: any[]) => any;
  employeeCategoryId?: number;
  visible: boolean;
  employeeCategoriesCollection: any[];
  useEmployeeCategoryPricing: boolean;
  nameEditable: boolean;
  onDelete: (...args: any[]) => any;
  readonly: boolean;
  readonlyLight: boolean;
  onNameFieldChange?: (...args: any[]) => any;
  nameFieldRequired: boolean;
  deleteButton?: any;
  escapeWithSkuPricing?: boolean;
  onSkuPriceChange?: any;
  onSkuDiscountPriceChange?: any;
  onSkuDurationChange?: any;
}
type PricingRowState = {
  unq?: string;
  focused?: {};
  data?: {
    id: any;
    name: any;
    fullPriceAmount: any;
    discountPriceAmount: any;
    finishingTimeMins: any;
    duration: any;
    employeeCategoryId: any;
    visible: any;
  };
  duration?: any;
  finishingTimeMins?: any;
  name?: any;
};

export class PricingRow extends React.Component<IPricingRowProps, PricingRowState> {
  static defaultProps = {
    deleteButton: DELETE_BUTTON_STATE.DEFAULT,
    finishingTimeMins: FINISHING_TIME_DEFAULT,
  };

  constructor(props) {
    super(props);
    this.state = {
      unq: `-${uuid.v4()}`,
      focused: {},
      data: {
        id: this.props.id,
        name: this.props.name,
        fullPriceAmount: this.props.fullPriceAmount,
        discountPriceAmount: this.props.discountPriceAmount,
        finishingTimeMins: props.useProcessingTime ? props.finishingTimeMins : null,
        duration: this.props.duration,
        employeeCategoryId: this.props.employeeCategoryId,
        visible: this.props.visible,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { useProcessingTime } = this.props;
    // if processing time became disabled
    // then null out the finishing time
    if (prevProps.useProcessingTime && !useProcessingTime) {
      this.removeFinishingTime();
    }
    // If processing time becomes enabled - set finishing time to a default value
    if (!prevProps.useProcessingTime && useProcessingTime) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
      this.setValue('finishingTimeMins', FINISHING_TIME_DEFAULT);
    }
  }

  removeFinishingTime = () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Type '{ finishingTimeMins: null; }' is missing the... Remove this comment to see the full error message
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        finishingTimeMins: null,
      },
    }));
  };

  // Setters for value changes
  setName = (evt) => {
    this.setValue('name', evt.target.value, this.props.onNameFieldChange);
  };

  getTextDuration() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'duration' does not exist on type '{ id: ... Remove this comment to see the full error message
    const { duration } = this.state.data;
    return Wahanda.Time.toFormattedWithTemplate(duration, Wahanda.Time.getTimeDurationTemplate());
  }

  setValue(name, value, callback) {
    this.setState(
      // @ts-expect-error ts-migrate(2345) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
      (prevState) => ({
        data: {
          ...prevState.data,
          [name]: value,
        },
      }),
      () => {
        if (callback) {
          callback();
        }
      },
    );
  }

  get deleteButton() {
    const { onDelete, deleteButton } = this.props;
    if (deleteButton === DELETE_BUTTON_STATE.HIDDEN) {
      return null;
    }
    const disabled = deleteButton === DELETE_BUTTON_STATE.DISABLED;
    const deleteSkuButton = (
      <div className={style.delete}>
        <Button
          disabled={disabled}
          onClick={onDelete}
          icon={<Close size="tiny" />}
          size="small"
          colour="plain"
        />
      </div>
    );
    if (deleteButton === DELETE_BUTTON_STATE.DISABLED) {
      return (
        <Tooltip tooltip={LANG.canNotEdit} placement="top">
          {deleteSkuButton}
        </Tooltip>
      );
    }
    return deleteSkuButton;
  }

  getValues() {
    const { nameEditable, escapeWithSkuPricing } = this.props;
    return Object.assign({ durationTypeCode: 'MI' }, this.state.data, {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      name: nameEditable ? this.state.data.name : null,
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      duration: !escapeWithSkuPricing ? this.state.data.duration : null,
    });
  }

  /*
   *  Unique names are for jQuery validation to work fine
   */
  buildName(originalName) {
    return `${originalName}${this.state.unq}`;
  }

  updateFocus(propName, isFocused) {
    this.setState((prevState) => ({
      focused: {
        ...prevState.focused,
        [propName]: isFocused,
      },
    }));
  }

  buildPriceInputRow(
    key,
    editable,
    { required = false, moreThanName = undefined, defaultValue = undefined } = {},
  ) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const value = this.state.data[key];
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const isFocused = this.state.focused[key];
    const className = classnames(
      style.price,
      editable && style.editable,
      isFocused && style.focused,
    );
    const moreThan = moreThanName ? `#${this.buildName(moreThanName)}` : undefined;
    const onChange = (val) => {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
      this.setValue(key, val);
      if (key === 'fullPriceAmount') {
        this.props.onSkuPriceChange();
      }
      if (key === 'discountPriceAmount') {
        this.props.onSkuDiscountPriceChange();
      }
    };
    return (
      <div className={className}>
        {editable ? (
          <PriceInput
            value={value}
            id={this.buildName(key)}
            name={this.buildName(key)}
            onChange={onChange}
            onFocus={() => this.updateFocus(key, true)}
            onBlur={() => this.updateFocus(key, false)}
            fieldcontain
            required={required}
            defaultValue={defaultValue}
            data-rule-more-than={moreThan}
          />
        ) : (
          formatPrice(value)
        )}
      </div>
    );
  }

  buildFinishingTimeDropdown() {
    const values = this.mapTimeSlots(FINISHING_TIME_SLOTS_WITH_NULL);
    const onSelect = (val) => {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
      this.setValue('finishingTimeMins', val);
      this.props.onSkuDurationChange(ServiceEditLabel.FinishingTime);
    };
    return (
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      <Dropdown data={values} selected={this.state.data.finishingTimeMins} onSelect={onSelect} />
    );
  }

  buildProcessingTimeDropdown() {
    const { processingTime, onProcessingTimeSelected } = this.props;
    const PROCESSING_TIME_SLOTS = Wahanda.Time.getProcessingTimeSlotsArray();
    const values = this.mapTimeSlots(PROCESSING_TIME_SLOTS);
    return (
      <Dropdown
        data={values}
        selected={processingTime}
        onSelect={onProcessingTimeSelected}
        typeahead={false}
      />
    );
  }

  mapTimeSlots = (slots) =>
    slots.map((time) => {
      return {
        name:
          time === null
            ? Wahanda.lang.menu.offer.pricing.noTimeSelected
            : `${time} ${Wahanda.lang.datetime.duration.abbr.minutes}`,
        value: time,
      };
    });

  buildDurationDropdown() {
    const values = Wahanda.Time.getDurationValues(
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      this.state.data.duration,
      Wahanda.Time.getTimeDurationTemplate(),
      true,
      {
        minMinutes: 5,
        valueAsMinutes: true,
      },
    );
    const onSelect = (val) => {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
      this.setValue('duration', val);
      this.props.onSkuDurationChange(ServiceEditLabel.SkuDuration);
    };
    return (
      <Dropdown
        data={toClj(values)}
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        selected={this.state.data.duration || DEFAULT_DURATION}
        onSelect={onSelect}
        typeahead={false}
      />
    );
  }

  buildNameRow(editable) {
    const {
      useEmployeeCategoryPricing,
      employeeCategoriesCollection,
      employeeCategoryId,
      nameFieldRequired,
    } = this.props;
    return (
      <SkuName
        editable={editable}
        inputName={this.buildName('name')}
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        name={editable ? this.state.data.name : this.props.name}
        useEmployeeCategoryPricing={useEmployeeCategoryPricing}
        employeeCategoriesCollection={employeeCategoriesCollection}
        employeeCategoryId={employeeCategoryId}
        onChange={this.setName}
        required={nameFieldRequired}
      />
    );
  }

  buildDurationField(editable) {
    return (
      <div className={style.duration}>
        {editable ? this.buildDurationDropdown() : this.getTextDuration()}
      </div>
    );
  }

  buildFinishingTimeField() {
    return <div className={style.duration}>{this.buildFinishingTimeDropdown()}</div>;
  }

  buildProcessingTimeField() {
    return <div className={style.duration}>{this.buildProcessingTimeDropdown()}</div>;
  }

  buildTimeFields = () => {
    const { escapeWithSkuPricing, useProcessingTime, readonlyLight } = this.props;
    const editable = !readonlyLight;
    const durationField = !escapeWithSkuPricing && this.buildDurationField(editable);
    const processingTimeField = useProcessingTime && this.buildProcessingTimeField();
    const finishingTimeField = useProcessingTime && this.buildFinishingTimeField();
    return (
      <div
        className={classnames(style.timeWrapper, {
          [style.withExtraField]: finishingTimeField,
        })}
      >
        {durationField}
        {processingTimeField}
        {finishingTimeField}
      </div>
    );
  };

  render() {
    const { readonly, readonlyLight, nameEditable } = this.props;
    const editable = !readonlyLight;
    return (
      <div className={classnames(style.row, { [style.inactive]: !editable })}>
        {this.buildNameRow(!readonly && nameEditable)}
        {this.buildTimeFields()}
        {this.buildPriceInputRow('fullPriceAmount', editable, {
          required: true,
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null | un... Remove this comment to see the full error message
          moreThanName: 'discountPriceAmount',
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null | un... Remove this comment to see the full error message
          defaultValue: 0,
        })}
        {this.buildPriceInputRow('discountPriceAmount', editable)}
        {this.deleteButton}
      </div>
    );
  }
}
