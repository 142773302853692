import React from 'react';
import style from './style.scss';

export interface Props {
  name: string;
  fullName: string;
  country: string;
  postCode?: string;
  address?: string;
}

class VenueInfoForPrint extends React.PureComponent<Props> {
  public render() {
    const { name, fullName, country, postCode } = this.props;
    return (
      <div className={style.venueInfoForPrint}>
        <strong>{name}</strong>
        <p>{fullName}</p>
        {this.formatAddress()}
        <p>{postCode}</p>
        <p>{country}</p>
      </div>
    );
  }

  private formatAddress() {
    const { address } = this.props;
    if (!address) {
      return null;
    }

    return address.split('\n').map((addressLine) => <p key={addressLine}>{addressLine}</p>);
  }
}

export default VenueInfoForPrint;
