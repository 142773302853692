import React from 'react';
import { Time, TIME_TYPES } from 'components/common/DataFormat';
import DialogContentBox from 'components/common/dialog/DialogContentBox';
import { Notice } from 'components/common/Notice';
import { Button } from 'components/common/Button';
import Download from 'components/common/icons/Download';
import Wahanda from 'common/wahanda';
import VerticalStack from './VerticalStack';
import ProductOrderStatusBadge from '../../ProductOrderStatusBadge';
import style from './style.scss';

const LANG = Wahanda.lang.menu.products.orders.orderDetailsDialog.orderDetails;
type OrderDetailsProps = {
  orderDate: string;
  status: string;
  shouldMessageBeShown: boolean;
  onDownload: (...args: any[]) => any;
};

export const OrderDetails: React.SFC<OrderDetailsProps> = (props) => {
  const { orderDate, status, shouldMessageBeShown, onDownload } = props;
  return (
    <DialogContentBox>
      <div className={style.orderDetails}>
        <div className={style.info}>
          <VerticalStack>
            <p className={style.label}>{LANG.orderDate}</p>
            <p className={style.value}>
              <Time type={TIME_TYPES.DATE_TIME} time={orderDate} />
            </p>
          </VerticalStack>
          <VerticalStack>
            <p className={style.label}>{LANG.status}</p>
            <ProductOrderStatusBadge status={status} />
          </VerticalStack>
        </div>
        <div className={style.downloadPdf}>
          <Button
            label={LANG.downloadPdfButton}
            onClick={onDownload}
            variant="hollow"
            colour="help"
            icon={<Download />}
          />
        </div>
      </div>
      {shouldMessageBeShown && <Notice type="success" message={LANG.messageText} />}
    </DialogContentBox>
  );
};
