/* eslint-disable func-names */
import { trackEvent } from 'common/analytics';
import ROLES from 'common/constants/userRoles';

App.Views.Settings.Dynamic.EmployeeEmployees = App.Views.Form.extend({
  events: {
    'click .add-employee': 'openAddEmployeeForm',
    'click .employee': 'openEditEmployeeForm',
    'mouseenter .info-msg': 'showInfo',
    'mouseleave .info-msg': 'hideInfo',
    'select.menu .employee-filter': 'onFilterChange',
  },

  onlyActiveEmployees: true,

  initialize: function () {
    this.collection = new App.Collections.Employees();
    this.collection.include = ['employee-offers'];

    this.menu = new App.Models.Menu({
      serviceTypes: ['T', 'P', 'Y'],
    });

    this.setupSortable();

    // Bind to all events which need the list refreshed
    [
      Wahanda.Event.EMPLOYEE_ADDED,
      Wahanda.Event.EMPLOYEE_EDITED,
      Wahanda.Event.EMPLOYEE_IMAGE_UPDATED,
      Wahanda.Event.EMPLOYEE_ARCHIVED,
      Wahanda.Event.EMPLOYEE_UNARCHIVED,
    ].forEach((event) => this.listenTo(App, event, this.render));
  },

  setupSortable: function () {
    this.$('.data-content').sortable({
      items: '.employee',
      handle: '.b-handle',
      update: $.proxy(this.onSortUpdate, this),
    });
  },

  canShowArchived: function () {
    return App.isMasquerading() || App.config.getAccountEmployeeRoleCode() === ROLES.ADMINISTRATOR;
  },

  render: function () {
    this.$el.removeClass('hidden');

    const self = this;
    let loaded = 0;
    // How many models should be fetched?
    const toLoad = 3;
    const sync = function () {
      loaded += 1;
      if (toLoad === loaded) {
        self.renderFilter();
        self.renderList();
      }
    };

    this.$el.loadmask();
    // For Masquerading or Owner users -- show archived employees too.
    this.collection.onlyActive = this.canShowArchived() ? null : true;
    this.collection.fetch({ success: sync });
    this.options.categoriesCollection.fetch({ success: sync });
    Wahanda.Cache.menuFiltered({
      includedServiceTypes: this.menu.get('serviceTypes'),
    }).done((cachedMenu) => {
      this.menu.copyFrom(cachedMenu);
      sync();
    });
    this.$('.form-actions').wToggle(Wahanda.Permissions.addTherapists());
  },

  onFilterChange(evt, node) {
    trackEvent('team', 'click', 'active-filter');
    const newFilterVal = $(node).data('value') === 'active';

    if (this.onlyActiveEmployees !== newFilterVal) {
      this.onlyActiveEmployees = newFilterVal;
      this.renderList();
      this.$('.data-content').scrollTop(0);
    }
  },

  renderFilter() {
    const html = this.canShowArchived()
      ? Wahanda.Template.renderTemplate('employee-active-filter')
      : '';
    this.$('.employee-filter').wToggle(!!html).html(html);

    this.$el.toggleClass('with-filter', !!html);

    if (html) {
      this.$('#employee-act-filter').wMenu({
        currentElementRenderer: function (node) {
          const arrow = this.current.find('.handler').clone();
          this.current.html(node.html()).prepend(arrow);
        },
      });
    }

    this.onlyActiveEmployees = true;
  },

  renderList: function () {
    const self = this;
    const $list = this.$('.data-content').empty();
    const cats = this.options.categoriesCollection;
    const isEmpty = this.collection.length === 0;
    if (isEmpty) {
      this.$('.employee-filter, .data-actions').hide();
      this.$('.none-employees').show();
      this.$el.addClass('empty');
    } else {
      this.$('.employee-filter, .data-actions').show();
      this.$('.none-employees').hide();
      this.$el.removeClass('empty');
      const list = [];
      this.collection.each(function (employee) {
        if (self.onlyActiveEmployees && !employee.isActive()) {
          return;
        }

        let categoryName = '&nbsp;';
        const isActive = employee.isActive();
        const takesAppointments = employee.get('takesAppointments') && isActive;
        if (takesAppointments) {
          const categoryId = employee.get('employeeCategoryId');
          if (categoryId) {
            const category = cats.get(categoryId);
            if (category) {
              categoryName = category.get('name');
            }
          }
        }

        const employeeRoleCode = (employee.get('role') || '').toLowerCase();
        const roleName = Wahanda.lang.settings.employees.employees.roles[employeeRoleCode] || '';
        const statuses = [];
        if (takesAppointments && !employee.hasServices()) {
          statuses.push({
            type: 'critical',
            title: Wahanda.lang.settings.employees.employees.statuses.noServicesAssigned,
          });
        }
        if (!isActive) {
          statuses.push({
            type: 'critical',
            title: Wahanda.lang.settings.employees.employees.statuses.archived,
          });
        }

        const image = employee.get('image');
        const imageId = image ? image.id : null;
        const imageSrc = image
          ? Wahanda.ScreenDensity.getImageFromStructure(image.uris, 85, 85)
          : null;

        list.push({
          id: employee.id,
          name: employee.get('name'),
          category: categoryName,
          // eslint-disable-next-line camelcase
          image_src: imageSrc,
          imageId: imageId,
          statuses: statuses,
          takesAppts: takesAppointments,
          canLogin: employee.get('canLogin'),
          roleName: roleName,
        });
      });

      const filter = this.canShowArchived();

      $list.html(
        Wahanda.Template.renderTemplate('employee-employees-list-items', {
          list,
          filter,
        }),
      );
    }

    this.$el.unloadmask();
  },

  showInfo: function (event) {
    const $target = $(event.currentTarget);
    const $ancestor = this.$('.data-content');
    // Title: Note, No show note
    const text = $target.data('tooltip');

    const offset = $target.offset();
    const parentOffset = $ancestor.offset();

    const $info = $(
      Wahanda.Template.renderTemplate('employee-infotip-template', {
        tooltipDirection: 'right',
        note: text,
      }),
    );
    $ancestor.append($info);

    const containerScroll = $ancestor.scrollTop();
    // Tooltip position regarding to the scrollable container
    const y =
      offset.top -
      parentOffset.top +
      containerScroll -
      $info.height() / 2 +
      $target.outerHeight() / 2;

    const $arrow = $info.find('.arrow');
    $info.css({
      left: offset.left - parentOffset.left + $target.outerWidth() + $arrow.outerWidth(),
      top: y,
    });
    // Arrow tip positioning
    $arrow.css({
      top: offset.top - parentOffset.top + containerScroll - y,
    });
  },

  hideInfo: function () {
    this.$('.b-tooltip').remove();
  },

  openAddEmployeeForm: function () {
    trackEvent('team', 'click', 'add-team-member');
    App.ES6.Initializers.EmployeeDialog.render();
  },

  openEditEmployeeForm: function (event) {
    const employeeId = $(event.currentTarget).data('id');
    const employee = this.collection.get(employeeId);
    employee.include = ['employee-offers'];
    // this._openEmployeeForm(employee);
    App.ES6.Initializers.EmployeeDialog.render(employeeId);
  },

  _openEmployeeForm: function (model) {
    trackEvent('team-member', 'view');
    const view = new App.Views.Forms.Employee({
      model: model,
      categoriesCollection: this.options.categoriesCollection,
      menu: this.menu,
    });
    view.render();
    view.open();
  },

  onSortUpdate: function () {
    const order = this.getSortOrder();
    App.Models.Employee.setSorting(order);
  },

  getSortOrder: function () {
    const list = [];
    this.$('.data-content')
      .find('.employee')
      .map(function (pos, item) {
        list.push(parseInt(item.getAttribute('data-id'), 10));
        return null;
      });
    return list;
  },
});
