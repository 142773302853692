import { call, put, select } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { isFeatureEnabled } from 'common/trunk-features/store/selectors';
import {
  startPOSOperationRequest,
  startPOSOperationRequestSuccess,
  startPOSOperationRequestFailure,
  abortPOSOperationRequestStart,
} from './actions';
import { POSOperationResponse } from './types';

export function* startPOSOperation(action: ReturnType<typeof startPOSOperationRequest>) {
  try {
    const enabledFeature = yield select(isFeatureEnabled, 'pos-de-tss');
    if (!enabledFeature) {
      return;
    }
    const result: POSOperationResponse = yield call(api.post, apiUrl('POS_OPERATION'), {
      operationType: action.payload.operation,
    });
    yield put(startPOSOperationRequestSuccess(result));
  } catch (e) {
    yield put(startPOSOperationRequestFailure());
  }
}

export function* abortPOSOperation(action: ReturnType<typeof abortPOSOperationRequestStart>) {
  try {
    const enabledFeature = yield select(isFeatureEnabled, 'pos-de-tss');
    if (!enabledFeature) {
      return;
    }
    yield call(api.post, apiUrl('POS_OPERATION_ABORTED'), {
      operationId: action.payload.operationId,
      operationType: action.payload.operation,
    });
  } catch {
    // we don't care if the operation fails or succeed.
    // redux store resets before the operation happens
    // and if the operation fails we just proceed with no warning
    // as the backend will handle the rest.
  }
}
