import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import { Tooltip } from 'components/common/Tooltip';
import {
  PricingRow,
  DELETE_BUTTON_STATE,
} from 'components/menu/OfferTreatmentPricingAndEmployees/Pricing/PricingRow';
import {
  getSkuName,
  getPricingType,
} from 'components/menu/OfferTreatmentPricingAndEmployees/utils';
import {
  PRICING_TYPE_BY_EMPLOYEE_CAT,
  PRICING_TYPE_SIMPLE,
  PRICING_TYPE_2D,
} from 'components/menu/OfferTreatmentPricingAndEmployees/constants';
import { ServiceEditLabel } from 'components/menu/ServicesTab/tracking';
import style from './style.scss';

const LANG = Wahanda.lang.menu.offer.sku;

export { DELETE_BUTTON_STATE } from 'components/menu/OfferTreatmentPricingAndEmployees/Pricing/PricingRow';

const tooltipize = (children, text) => {
  return (
    <Tooltip className={style.tooltip} key={text} tooltip={text} placement="top">
      {children}
    </Tooltip>
  );
};
interface ISkuListProps extends React.HTMLAttributes<Element> {
  skus: any[];
  readonlyLight: boolean;
  readonly: boolean;
  selectedEmployees: {
    id: number;
    name: string;
    employeeCategoryId?: number;
  }[];
  employeeCategoriesCollection: any[];
  offerName: string;
  useProcessingTime: boolean;
  processingTimeMins?: number;
  onDelete: (...args: any[]) => any;
  nameEditable: boolean;
  doNotShowPricingLevelHeader?: boolean;
  className?: string;
  deleteButton?: string;
  escapeWithSkuPricing?: boolean;
  actions?: {
    offerDialogSetProcessingTime: (...args: any[]) => any;
    trackServiceEditAction?: any;
  };
  some?: any;
  trackServiceEditAction?: any;
  offerDialogSetProcessingTime?: any;
}

export default class SkuList extends React.Component<ISkuListProps, {}> {
  forceUpdate: any;

  onNameFieldChange = () => {
    /*
     * This is called on each sku name field input, to force a re-render of parent component
     * so that we could check if all name inputs are required or not.
     * */
    this.forceUpdate();
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    this.props.actions.trackServiceEditAction(ServiceEditLabel.SkuName);
  };

  getValues() {
    const skus = this.pricingRowRefs.map((data) => {
      // @ts-expect-error ts-migrate(2700) FIXME: Rest types may only be created from object types.
      const { ref, ...rest } = data;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getValues' does not exist on type 'never... Remove this comment to see the full error message
      return Object.assign({}, ref.getValues(), rest);
    });
    return skus;
  }

  getTimeFieldHeaders() {
    const { escapeWithSkuPricing, useProcessingTime } = this.props;
    const headLang = LANG.title;
    if (escapeWithSkuPricing) {
      return [];
    }
    const fields = [];
    // Duration / Application time field
    const durationTitle = useProcessingTime ? headLang.applicationTime : headLang.duration;
    const maybeTooltipize = (c) =>
      useProcessingTime ? tooltipize(c, headLang.applicationTimeTooltip) : c;
    const durationField = (
      <div key={durationTitle} className={style.duration}>
        {durationTitle}
      </div>
    );
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    fields.push(maybeTooltipize(durationField));
    // Processing time field
    if (useProcessingTime) {
      fields.push(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
        tooltipize(
          <div className={style.duration}>{headLang.processingTime}</div>,
          headLang.processingTimeTooltip,
        ),
      );
      fields.push(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
        tooltipize(
          <div className={style.duration}>{headLang.finishingTime}</div>,
          headLang.finishingTimeTooltip,
        ),
      );
    }
    return fields;
  }

  onSkuPriceChange = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    this.props.actions.trackServiceEditAction(ServiceEditLabel.SkuPrice);
  };

  onSkuDiscountPriceChange = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    this.props.actions.trackServiceEditAction(ServiceEditLabel.SkuDiscountPrice);
  };

  onSkuDurationChange = (label) => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    this.props.actions.trackServiceEditAction(label);
  };

  onProcessingTimeSelected = (...args) => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    this.props.actions.offerDialogSetProcessingTime(...args);
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    this.props.actions.trackServiceEditAction(ServiceEditLabel.ProcessingTime);
  };

  pricingRowRefs = [];

  render() {
    const {
      onDelete,
      offerName,
      skus,
      employeeCategoriesCollection,
      useProcessingTime,
      processingTimeMins,
      selectedEmployees,
      readonlyLight,
      readonly,
      nameEditable,
      doNotShowPricingLevelHeader,
      deleteButton,
    } = this.props;
    const headLang = LANG.title;
    const showDeleteButton = deleteButton !== DELETE_BUTTON_STATE.HIDDEN;
    const pricingType = getPricingType(skus);
    const useEmployeeCategoryPricing = [PRICING_TYPE_BY_EMPLOYEE_CAT, PRICING_TYPE_2D].includes(
      // @ts-expect-error ts-migrate(2345) FIXME: Type 'null' is not assignable to type 'string'.
      pricingType,
    );
    const isSimplePricing = pricingType === PRICING_TYPE_SIMPLE;
    const doNotDisplaySkuName = skus.length === 1 || doNotShowPricingLevelHeader;
    /*
     * If all name fields are empty then they are not required, if at least one name
     * field is not empty all of them are required. This calculation needs to happen before
     * this.pricingRowRefs = [], as we need to calculate this,
     * before re-rendering.
     * */
    const nameFieldRequired =
      this.pricingRowRefs.length === 0
        ? this.props.skus.some((sku) => !!(isSimplePricing ? offerName : getSkuName(sku)))
        : this.getValues().some((sku) => !!sku.name);
    this.pricingRowRefs = [];
    return (
      <div className={this.props.className}>
        <div className={style.headerRow}>
          <div className={style.name}>{doNotDisplaySkuName ? null : headLang.skuName}</div>
          <div
            className={classnames(style.timeWrapper, {
              [style.withExtraField]: useProcessingTime,
            })}
          >
            {this.getTimeFieldHeaders()}
          </div>
          <div className={style.price}>{headLang.price}</div>
          <div className={style.price}>{headLang.salePrice}</div>
          {showDeleteButton ? <span className={style.delete} /> : null}
        </div>
        {skus.map((sku) => {
          /*
           *  We need to rerender the pricing row component completely, when we change the
           *  pricing type
           *
           *  This is because when swiching pricing types we sometimes need to show the
           *  offerName, sometimes leave an empty input. Changing the key allows us to
           *  completely rerender the component
           */
          const key = (sku.id || sku.tempId) + pricingType;
          return (
            <PricingRow
              id={sku.id}
              fullPriceAmount={sku.fullPriceAmount}
              discountPriceAmount={sku.discountPriceAmount}
              duration={sku.duration}
              finishingTimeMins={sku.finishingTimeMins}
              processingTime={processingTimeMins}
              useProcessingTime={useProcessingTime}
              onProcessingTimeSelected={this.onProcessingTimeSelected}
              employeeCategoryId={sku.employeeCategoryId}
              name={isSimplePricing ? offerName : getSkuName(sku)}
              nameFieldRequired={nameFieldRequired}
              visible={sku.visible}
              useEmployeeCategoryPricing={useEmployeeCategoryPricing}
              employeeCategoriesCollection={employeeCategoriesCollection}
              // @ts-expect-error ts-migrate(2322) FIXME: Property 'selectedEmployees' does not exist on typ... Remove this comment to see the full error message
              selectedEmployees={selectedEmployees}
              onDelete={() => onDelete(sku)}
              readonlyLight={readonlyLight}
              readonly={readonly}
              ref={(ref) => {
                if (ref) {
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                  this.pricingRowRefs.push({
                    ref,
                    tempId: sku.tempId,
                    groupingId: sku.groupingId,
                  });
                }
              }}
              key={key}
              nameEditable={nameEditable}
              onNameFieldChange={this.onNameFieldChange}
              escapeWithSkuPricing={this.props.escapeWithSkuPricing}
              deleteButton={deleteButton}
              onSkuPriceChange={this.onSkuPriceChange}
              onSkuDiscountPriceChange={this.onSkuDiscountPriceChange}
              onSkuDurationChange={this.onSkuDurationChange}
            />
          );
        })}
      </div>
    );
  }
}
