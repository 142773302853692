export const resizeAndCompressImage = async ({
  file,
  maxWidth,
  maxHeight,
  quality = 0.7,
}: {
  file: File;
  maxWidth: number;
  maxHeight: number;
  quality?: number;
}): Promise<File> => {
  const { image, width, height } = await createImage(file);

  const { width: newWidth, height: newHeight } = calculateResize({
    maxWidth,
    maxHeight,
    width,
    height,
  });

  const resizedBlob = await resizeImage({
    image,
    type: file.type,
    width: newWidth,
    height: newHeight,
    quality,
  });

  return new File([resizedBlob], file.name, { type: file.type, lastModified: Date.now() });
};

const resizeImage = async ({
  image,
  type,
  width,
  height,
  quality,
}: {
  image: HTMLImageElement;
  type: string;
  width: number;
  height: number;
  quality: number;
}): Promise<Blob> =>
  new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return reject('Could not get 2d context');
    }
    ctx.drawImage(image, 0, 0, width, height);
    canvas.toBlob(
      (blob) => (!blob ? reject('Could not create blob') : resolve(blob)),
      type,
      quality,
    );
  });

export const calculateResize = ({
  maxWidth,
  maxHeight,
  width,
  height,
}: {
  maxWidth: number;
  maxHeight: number;
  width: number;
  height: number;
}): { width: number; height: number } => {
  if (width > height) {
    if (width > maxWidth) {
      return {
        width: maxWidth,
        height: (height * maxWidth) / width,
      };
    }
  } else if (height > maxHeight) {
    return {
      width: (width * maxHeight) / height,
      height: maxHeight,
    };
  }

  return { width, height };
};

export const checkDimensions = async ({
  file,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
}: {
  file: File;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
}): Promise<boolean> => {
  const { width, height } = await createImage(file);

  const isValid =
    (minWidth === undefined || width >= minWidth) &&
    (maxWidth === undefined || width <= maxWidth) &&
    (minHeight === undefined || height >= minHeight) &&
    (maxHeight === undefined || height <= maxHeight);

  return isValid;
};

export const createImage = async (
  file: File,
): Promise<{ image: HTMLImageElement; width: number; height: number }> =>
  new Promise((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      const { width, height } = image;
      resolve({ image, width, height });
    };
  });
