import React, { Component } from 'react';
import { trackEvent } from 'common/analytics';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';

import Image from './Image';
import Details from './Details';
import ActionButton from './ActionButton';
import style from './style.scss';

const analytics = {
  evoucher: {
    cat: 'stats',
    act: 'click',
    lab: 'redeem-evoucher',
  },
  marketplace: {
    cat: 'stats',
    act: 'click',
    lab: 'view-on-marketplace',
  },
  widget: {
    cat: 'stats',
    act: 'click',
    lab: 'view-booking-widget',
  },
};
interface IVenueInfoProps extends React.HTMLAttributes<Element> {
  rating: number;
  openMarketplace: (...args: any[]) => any;
  openWidget: (...args: any[]) => any;
  redeemEvoucherCallback: (...args: any[]) => any;
  name: string;
  redirectToImageEditing?: (...args: any[]) => any;
  lang: any;
  imageUrl?: string;
  isPending: boolean;
  imageEditingEnabled: boolean;
  canAccessSettings?: boolean;
}
class VenueInfo extends Component<IVenueInfoProps, {}> {
  static defaultProps = {
    openMarketplace: null,
    openWidget: null,
    redeemEvoucherCallback: null,
  };

  componentDidMount() {
    NavigationAnalytics.trackPageEventView(L2Page.STATS);
  }

  redeemEvoucherCallback = () => {
    const { cat, act, lab } = analytics.evoucher;
    trackEvent(cat, act, lab);
    const { redeemEvoucherCallback } = this.props;
    redeemEvoucherCallback();
  };

  openMarketplace = () => {
    const { cat, act, lab } = analytics.marketplace;
    trackEvent(cat, act, lab);
    const { openMarketplace } = this.props;
    openMarketplace();
  };

  openWidget = () => {
    const { cat, act, lab } = analytics.widget;
    trackEvent(cat, act, lab);
    const { openWidget } = this.props;
    openWidget();
  };

  render() {
    const {
      rating,
      name,
      redeemEvoucherCallback,
      openWidget,
      openMarketplace,
      imageEditingEnabled,
      redirectToImageEditing,
      canAccessSettings,
      lang,
      imageUrl,
      isPending,
    } = this.props;
    const noImageText = canAccessSettings && imageEditingEnabled ? lang.noImage.text : null;
    return (
      <div className={style.venueInfo}>
        <Image
          imageUrl={imageUrl}
          noImageText={noImageText}
          redirectToImageEditing={redirectToImageEditing}
        />
        <Details
          rating={rating}
          title={name}
          isPending={isPending}
          pendingText={lang.pendingText}
        />
        {Boolean(redeemEvoucherCallback) && (
          <ActionButton
            text={lang.actions.redeemEvoucher}
            classes={style.evoucherIcon}
            onClick={this.redeemEvoucherCallback}
          />
        )}
        {Boolean(openMarketplace) && (
          <ActionButton
            text={lang.actions.viewMarketplace}
            classes={style.marketplaceIcon}
            onClick={this.openMarketplace}
          />
        )}
        {Boolean(openWidget) && (
          <ActionButton
            text={lang.actions.viewWidget}
            classes={style.widgetIcon}
            onClick={this.openWidget}
          />
        )}
      </div>
    );
  }
}

export default VenueInfo;
