export interface SelectedFile {
  file: string | ArrayBuffer | null;
  fileName: string | null;
}

export enum ClientsImportStage {
  INIT = 'INIT',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  CAMPAIGN_PROMPT = 'CAMPAIGN_PROMPT',
}

export interface ClientsFileUploadResponse {
  id: string;
}

export interface Message {
  message: string;
  severity: 'INFO' | 'WARNING';
}

export enum FileImportStatus {
  FINISHED_WITH_WARNINGS = 'FINISHED_WITH_WARNINGS',
  FINISHED_SUCCESS = 'FINISHED_SUCCESS',
  UNFINISHED_BECAUSE_OF_ERRORS = 'UNFINISHED_BECAUSE_OF_ERRORS',
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface ProgressResponse {
  percentage: number;
  messages: Message[];
  importCount: number;
  status: FileImportStatus;
}
