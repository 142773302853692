import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class CalendarUpdate extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-calendar-update', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path
          d="M7 1L7 7 5 7 5 5 3 5 3 19 11 19 11 20.999 2.995 21C1.893 21 1 20.107 1 19.005L1 4.995C1 3.893 1.893 3 2.995 3L5 3 5 1 7 1zM17 3L17.005 3C18.107 3 19 3.893 19 4.995L19 8 16.999 8 16.999 6 17 6 17 3zM15 7L13 7 13 5 9 5 9 3 13 3 13 1 15 1 15 7zM18 10.5C18 10.362 18.112 10.25 18.25 10.25 18.304 10.25 18.357 10.268 18.4 10.3L21.733 12.8C21.843 12.883 21.866 13.04 21.783 13.15 21.769 13.169 21.753 13.186 21.733 13.2L18.4 15.7C18.29 15.783 18.133 15.76 18.05 15.65 18.018 15.607 18 15.554 18 15.5L18 14C16.402 14 15.096 15.249 15.005 16.824L15 17 13 17C13 14.239 15.239 12 18 12L18 10.5zM17.984 22.5C17.984 22.638 17.872 22.75 17.734 22.75 17.68 22.75 17.627 22.732 17.584 22.7L14.251 20.2C14.141 20.117 14.118 19.96 14.201 19.85 14.215 19.831 14.231 19.814 14.251 19.8L17.584 17.3C17.694 17.217 17.851 17.24 17.934 17.35 17.966 17.393 17.984 17.446 17.984 17.5L17.984 19C19.582 19 20.888 17.751 20.979 16.176L20.984 16 22.984 16C22.984 18.761 20.745 21 17.984 21L17.984 22.5z"
          fillRule="nonzero"
        />
        <path
          d="M5,9 L7,9 L7,11 L5,11 L5,9 Z M9,9 L11,9 L11,11 L9,11 L9,9 Z M9,13 L11,13 L11,15 L9,15 L9,13 Z M13,9 L15,9 L15,11 L13,11 L13,9 Z M5,13 L7,13 L7,15 L5,15 L5,13 Z"
          fillRule="nonzero"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(CalendarUpdate);
