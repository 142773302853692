import React from 'react';

import { CancellationFlowAnalytics } from 'common/analytics';
import { Button } from 'components/common/Button';
import Dialog from 'components/common/react-dialog';

import style from './CancellationReason.scss';

interface Props {
  lang: any;
  onVenueCancelled: () => void;
  onCustomerCancelled: () => void;
  onCovidCancelled: () => void;
  onClose: () => void;
}

export class CancellationReason extends React.Component<Props> {
  public componentDidMount() {
    CancellationFlowAnalytics.trackCancellationReasonInitiation();
  }

  private onCovidCancelled = () => {
    CancellationFlowAnalytics.trackCoronaLedCancellation();
    this.props.onCovidCancelled();
  };

  private onVenueCancelled = () => {
    CancellationFlowAnalytics.trackVenueLedCancellation();
    this.props.onVenueCancelled();
  };

  private onCustomerCancelled = () => {
    CancellationFlowAnalytics.trackCustomerLedCancellation();
    this.props.onCustomerCancelled();
  };

  public render() {
    const { lang, onClose } = this.props;

    return (
      <Dialog
        dataTest="cancellation-reason-modal"
        width={350}
        title={lang.title}
        classes={{ 'dialog2--button-choices appointment-deletion-proxy': true }}
        onClose={onClose}
      >
        <div className={style.header}>{lang.heading}</div>
        <Button
          label={lang.buttons.covid19Fault}
          onClick={this.onCovidCancelled}
          variant="secondary"
          fullWidth
          size="large"
          dataTest="cancellation-reason-covid"
        />
        <div className={style.button}>
          <Button
            label={lang.buttons.venueFault}
            onClick={this.onVenueCancelled}
            variant="secondary"
            fullWidth
            size="large"
            dataTest="cancellation-reason-salon"
          />
        </div>
        <div className={style.button}>
          <Button
            label={lang.buttons.customerFault}
            onClick={this.onCustomerCancelled}
            variant="secondary"
            fullWidth
            size="large"
            dataTest="cancellation-reason-client"
          />
        </div>
      </Dialog>
    );
  }
}
