import React from 'react';
import ProductRow from '../../../ProductRow';

interface IProductProps extends React.HTMLAttributes<Element> {
  productId: number;
  quantity?: number;
  name: string;
  inStockAmount?: number;
  actions: {
    productAddToOrder?: (...args: any[]) => any;
    productRemoveFromOrder?: (...args: any[]) => any;
    productOrderUpdate?: (...args: any[]) => any;
  };
  productOrderUpdate?: any;
  productAddToOrder?: any;
  productRemoveFromOrder?: any;
}

export default class Product extends React.Component<IProductProps, {}> {
  static defaultProps = {
    quantity: null,
    inStockAmount: null,
  };

  onChange = (newQuantityToOrder) => {
    const order = {
      venueProductId: this.props.productId,
      quantity: newQuantityToOrder,
    };
    if (newQuantityToOrder <= 0) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.actions.productRemoveFromOrder(order);
    } else if (!this.props.quantity) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.actions.productAddToOrder(order);
    } else {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.actions.productOrderUpdate(order);
    }
  };

  render() {
    const { name, productId, quantity, inStockAmount } = this.props;
    return (
      <ProductRow
        name={name}
        elementId={productId}
        amount={quantity}
        inStockAmount={inStockAmount}
        onChange={this.onChange}
        isActive={!!quantity}
        useInStockIndicator
      />
    );
  }
}
