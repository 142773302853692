import { action, payload } from 'ts-action';

import {
  Employee,
  RequestEmployeesWorkingHoursPayload,
  RequestEmployeesMonthWorkingHoursPayload,
  EmployeeWorkingHours,
  GetEmployeesSchedulePayload,
  Schedules,
  SetEmployeesShiftPayload,
  SetEmployeeOpenDayPayload,
  SetEmployeeTimeOffPayload,
  SetEmployeeSchedulePayload,
  SetVenueWorkingHoursPayload,
  RequestEmployeesDayPayload,
  InvalidateEmployeesSchedulePayload,
  VenueBusinessHoursObject,
  DateRangeObject,
  WorkingHoursObject,
} from '../utils/types';

/*
 * Reset shift modal used for legacyEvenMiddleware in Calendar
 */
export const resetShiftModal = action('[Rota] Reset Shift');

export const resetEmployeesSchedule = action('[Rota] Reset Employee Schedule');

/*
 * Requesting venue business hours
 */

export const getVenueBusinessHours = action('[Rota] Request Venue business hours');

export const getVenueBusinessHoursSuccess = action(
  '[Rota] Request Venue business hours Success',
  payload<{
    businessHours: VenueBusinessHoursObject[];
  }>(),
);

export const getVenueBusinessHoursFailure = action('[Rota] Request Venue business hours Failure');

/*
 * Requesting venue working hours
 */
export const getVenueWorkingHours = action(
  '[Rota] Request Venue working hours',
  payload<DateRangeObject>(),
);

export const getVenueWorkingHoursSuccess = action(
  '[Rota] Request Venue working hours Success',
  payload<{ workingHours: WorkingHoursObject[] }>(),
);

export const getVenueWorkingHoursFailure = action('[Rota] Request Venue working hours Failure');

/*
 * Setting custom venue shift
 */
export const setVenueWorkingHours = action(
  '[Rota] Set Venue business hours',
  payload<SetVenueWorkingHoursPayload>(),
);

export const setVenueWorkingHoursSuccess = action(
  '[Rota] Set Venue business hours Success',
  payload<WorkingHoursObject>(),
);

export const setVenueWorkingHoursFailure = action('[Rota] Set Venue working hours Failure');

/*
 * Requesting employee working hours
 */
export const getEmployeesWorkingHours = action(
  '[Rota] Request Employee working hours',
  payload<RequestEmployeesWorkingHoursPayload>(),
);

export const getEmployeesWorkingHoursSuccess = action(
  '[Rota] Request Employee working hours Success',
  payload<{
    datePickerRange: DateRangeObject;
    employeesWorkingHours: Employee[];
  }>(),
);

export const getEmployeesWorkingHoursFailure = action(
  '[Rota] Request Employee working hours Failure',
);

/*
 * Requesting employee month working hours
 */
export const getEmployeeMonthWorkingHours = action(
  '[Rota] Request Employee month working hours',
  payload<RequestEmployeesMonthWorkingHoursPayload>(),
);

export const getEmployeeMonthWorkingHoursSuccess = action(
  '[Rota] Request Employee month working hours Success',
  payload<{
    datePickerRange: DateRangeObject;
    employeeId: number;
    employeeWorkingHours: WorkingHoursObject[];
    workingHours: WorkingHoursObject[];
  }>(),
);

export const getEmployeeMonthWorkingHoursFailure = action(
  '[Rota] Request Employee month working hours Failure',
);

/*
 * Requesting employees day working hours
 */
export const getEmployeesDay = action(
  '[Rota] Request Employee day',
  payload<RequestEmployeesDayPayload>(),
);

export const getEmployeesDaySuccess = action(
  '[Rota] Request Employee day Success',
  payload<{
    date: string;
    employeesWorkingHours: Employee[];
  }>(),
);

export const getEmployeesDayFailure = action('[Rota] Request days Failure');

/*
 * Setting custom employee shift
 */
export const setEmployeesShift = action(
  '[Rota] Set Employee shift',
  payload<SetEmployeesShiftPayload>(),
);

export const setEmployeesShiftSuccess = action(
  '[Rota] Set Employee shift Success',
  payload<{
    date: string;
    employeeId: number;
    workingHours: WorkingHoursObject;
  }>(),
);

export const setEmployeesShiftFailure = action('[Rota] Set Employee shift Failure');

/*
 * Setting employee time off
 */
export const setEmployeesTimeOff = action(
  '[Rota] Set Employee time off',
  payload<SetEmployeeTimeOffPayload>(),
);

export const setEmployeesTimeOffSuccess = action(
  '[Rota] Set Employee time off Success',
  payload<EmployeeWorkingHours>(),
);

export const setEmployeesTimeOffFailure = action('[Rota] Set Employee time off Failure');

/*
 * Open up employee's day
 */
export const setEmployeesOpenDay = action(
  '[Rota] Set Employee open day',
  payload<SetEmployeeOpenDayPayload>(),
);

export const setEmployeesOpenDaySuccess = action(
  '[Rota] Set Employee open day Success',
  payload<EmployeeWorkingHours>(),
);

export const setEmployeesOpenDayFailure = action('[Rota] Set Employee open day Failure');

/*
 * Getting custom employee schedules
 */
export const getEmployeesSchedule = action(
  '[Rota] Get Employee schedule',
  payload<GetEmployeesSchedulePayload>(),
);

export const getEmployeesScheduleSuccess = action(
  '[Rota] Get Employee schedule Success',
  payload<Schedules>(),
);

export const getEmployeesScheduleFailure = action('[Rota] Get Employee schedule Failure');

/*
 * Setting custom employee schedules
 */
export const setEmployeesSchedule = action(
  '[Rota] Set Employee schedule',
  payload<SetEmployeeSchedulePayload>(),
);

export const setEmployeesScheduleSuccess = action(
  '[Rota] Set Employee schedule Success',
  payload<EmployeeWorkingHours>(),
);

export const setEmployeesScheduleFailure = action('[Rota] Set Employee schedule Failure');

/*
 * Invalidating custom employee schedules
 */
export const invalidateEmployeesSchedule = action(
  '[Rota] Invalidate Employee schedule',
  payload<InvalidateEmployeesSchedulePayload>(),
);

export const invalidateEmployeesScheduleSuccess = action(
  '[Rota] Invalidate Employee schedule Success',
  payload<EmployeeWorkingHours>(),
);

export const invalidateEmployeesScheduleFailure = action(
  '[Rota] Invalidate Employee schedule Failure',
);
