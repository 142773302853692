import React from 'react';

interface Props {
  className?: string;
}

class ServiceList extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-service-list', this.props.className].join(' ')}
        viewBox="0 0 82 90"
      >
        <g fill="none" transform="translate(1 1)" fillRule="evenodd">
          <g transform="translate(8)">
            <path
              fill="#F9D5C5"
              d="M39.794,49.039 C34.198,52.872 27.104,53.748 20.308,54.112 C15.668,54.362 21.368,60.897 16.942,59.491 C15.443,59.015 12.07,61.737 6.821,67.657 C6.821,71.858 3.919,72 8.164,72 L64.064,72 C66.633,72 71.2,69.06 71.2,66.516 L71.2,4.71 C71.2,4.114 71.236,3.54 71.078,3 C64.926,7.893 59.936,14.072 56.391,21.053 C51.416,30.853 48.904,42.797 39.794,49.039 Z"
              fillRule="nonzero"
            />
            <path
              stroke="#001E62"
              d="M0,64 L0,8 C0,3.582 3.582,0 8,0 L64,0 C68.418,0 72,3.582 72,8 L72,64 C72,68.418 68.418,72 64,72 L0,72"
              strokeWidth="2"
            />
            <path
              stroke="#001E62"
              d="M21.943,24 L13.057,24 C11.922,24 11,23.08 11,21.942 L11,13.057 C11,11.92 11.922,11 13.057,11 L21.943,11 C23.079,11 24,11.92 24,13.057 L24,21.942 C24,23.08 23.08,24 21.943,24 Z M21.943,43.094 L13.057,43.094 C11.922,43.094 11,42.174 11,41.036 L11,32.151 C11,31.015 11.922,30.094 13.057,30.094 L21.943,30.094 C23.079,30.094 24,31.014 24,32.151 L24,41.036 C24,42.173 23.08,43.094 21.943,43.094 Z"
              strokeWidth="2"
              strokeLinecap="square"
            />
            <path
              fill="#001E62"
              d="M30,14 L60,14 L60,16 L30,16 L30,14 Z M30,33 L60,33 L60,35 L30,35 L30,33 Z M30,19 L54,19 L54,21 L30,21 L30,19 Z M30,38 L54,38 L54,40 L30,40 L30,38 Z M30,52 L60,52 L60,54 L30,54 L30,52 Z M30,57 L54,57 L54,59 L30,59 L30,57 Z"
              fillRule="nonzero"
            />
            <path
              stroke="#001E62"
              d="M27.487,7.906 L18.567,18.456 C18.214,18.874 17.589,18.93 17.167,18.581 L14.9,16.706"
              strokeWidth="2"
            />
            <path
              stroke="#001E62"
              d="M21.943,62.094 L13.057,62.094 C11.922,62.094 11,61.174 11,60.036 L11,51.151 C11,50.015 11.922,49.094 13.057,49.094 L21.943,49.094 C23.079,49.094 24,50.014 24,51.151 L24,60.036 C24,61.173 23.08,62.094 21.943,62.094 Z"
              strokeWidth="2"
              strokeLinecap="square"
            />
          </g>
          <g transform="translate(0 22)">
            <path
              fill="#FFB500"
              d="M28.4424405,9.70455082 C36.7084405,1.43955082 49.3744405,0.702550824 56.7324405,8.05955082 C64.0884405,15.4165508 63.3524405,28.0815508 55.0864405,36.3485508 C47.5014405,43.9325508 36.3434405,45.3065508 28.8504405,39.7775508 C28.4884405,39.5095508 27.9804405,39.5295508 27.6604405,39.8465508 L16.0884405,51.4165508 C14.1664405,53.3395508 12.8144405,55.6865508 12.2744405,58.3505508 C12.0724405,59.3525508 11.5374405,60.3005508 10.6704405,61.0195508 C8.92544051,62.4655508 6.33344051,62.4215508 4.63244051,60.9225508 C2.59544051,59.1265508 2.52244051,56.0155508 4.41244051,54.1245508 C5.12044051,53.4165508 5.99944051,52.9845508 6.91644051,52.8265508 C9.43444051,52.3965508 11.5954405,50.9755508 13.4004405,49.1695508 L25.1864405,37.3845508 C25.4894405,37.0795508 25.5164405,36.5995508 25.2534405,36.2595508 C19.4484405,28.7695508 20.7494405,17.3975508 28.4414405,9.70455082 L28.4424405,9.70455082 Z M53.8584405,10.9195508 C48.0404405,5.10155082 37.8764405,5.83155082 31.1564405,12.5495508 C24.4374405,19.2695508 23.7074405,29.4335508 29.5264405,35.2525508 C35.3444405,41.0715508 45.5094405,40.3405508 52.2274405,33.6215508 C58.9474405,26.9035508 59.6774405,16.7385508 53.8584405,10.9195508 Z"
              transform="rotate(6 32.425 32.533)"
              fillRule="nonzero"
            />
            <path
              stroke="#001E62"
              d="M32.0941248,13.5701397 C38.8141248,6.85213975 48.9781248,6.12213975 54.7961248,11.9401397 C60.6151248,17.7591397 59.8851248,27.9241397 53.1651248,34.6421397 C46.4471248,41.3611397 36.2821248,42.0921397 30.4641248,36.2721397 C24.6451248,30.4541397 25.3751248,20.2911397 32.0941248,13.5711397 L32.0941248,13.5701397 Z"
              transform="rotate(6 42.63 24.107)"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
            <path
              fill="#FFF"
              d="M55.9130479,7.07472591 C48.1790479,2.34572591 37.5740479,3.20472591 30.1310479,10.6747259 C23.3720479,17.4547259 22.1070479,26.7857259 25.3110479,34.2787259 C22.9950479,26.8647259 24.5760479,18.5467259 31.0240479,12.0767259 C38.1880479,4.88972591 48.0950479,3.33072591 55.9140479,7.07372591 L55.9130479,7.07472591 Z"
              transform="rotate(6 39.773 19.213)"
              fillRule="nonzero"
            />
            <path
              stroke="#001E62"
              d="M25.2544639,36.2596045 C25.5174639,36.5996045 25.4904639,37.0796045 25.1874639,37.3846045 L13.4014639,49.1696045 C11.5964639,50.9756045 9.43546392,52.3966045 6.91746392,52.8266045 C6.00046392,52.9846045 5.12146392,53.4166045 4.41346392,54.1246045 C2.52346392,56.0146045 2.59646392,59.1266045 4.63346392,60.9226045 C6.33346392,62.4216045 8.92646392,62.4656045 10.6714639,61.0196045 C11.5384639,60.2996045 12.0734639,59.3526045 12.2754639,58.3496045 C12.8154639,55.6866045 14.1674639,53.3396045 16.0894639,51.4176045 L27.6544639,39.8526045 L27.6604639,39.8466045 C27.9804639,39.5296045 28.4884639,39.5096045 28.8504639,39.7766045 C36.3434639,45.3066045 47.5004639,43.9326045 55.0864639,36.3486045 C63.3524639,28.0816045 64.0884639,15.4166045 56.7324639,8.05860446 C49.3744639,0.702604458 36.7094639,1.43860446 28.4424639,9.70460446 C20.7504639,17.3976045 19.4494639,28.7706045 25.2544639,36.2596045 Z"
              transform="rotate(6 32.425 32.533)"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export const icon = ServiceList;
