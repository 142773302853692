import { ORDER_STATUS_ORDERED } from './constants';

export const calculateTotalQuantityOrderedPerOrder = (order) => ({
  ...order,
  totalQuantityOrdered: order.productOrderItems.reduce(
    (total, productOrderItem) => total + productOrderItem.quantity,
    0,
  ),
});

export const calculateProductOrderItemsTotalQuantity = (total, productOrderItem) =>
  total + productOrderItem.quantity;

// @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
export const byDate = (a, b) => new Date(b.created) - new Date(a.created);

export const pushCompletedOrdersToTheBottom = (a, b) => {
  if (a.status === b.status) {
    return 0;
  }
  if (a.status === ORDER_STATUS_ORDERED) {
    return -1;
  }
  return 1;
};
