import React from 'react';

interface Props {
  className?: string;
}

class SmartDiscount extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-smart-discount', this.props.className].join(' ')}
        viewBox="0 0 100 100"
      >
        <g fill="none" fillRule="evenodd">
          <line
            x1="52.206"
            x2="51.178"
            y1="69.259"
            y2="66.9"
            stroke="#001E62"
            strokeWidth="1.705"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <line x1="86.087" x2="92.067" y1="79.282" y2="67.448" stroke="#001E62" strokeWidth="2" />
          <path
            fill="#FFB500"
            d="M35.383,73.01 C18.206,69.38 5.951,53.675 6.638,36.2 C7.46,15.272 25.509,-0.236 45.864,1.346 C45.364,1.266 44.865,1.188 44.359,1.128 C22.64,-1.455 2.969,14.317 1.009,36.172 C-0.792,56.268 13.555,74.638 33.557,77.829 C51.968,80.767 69.18,70.404 75.787,54.136 C75.858,53.962 75.61,53.846 75.525,54.014 C67.9795074,68.6938549 51.5210754,76.4827033 35.385,73.01"
            fillRule="nonzero"
          />
          <path
            stroke="#001E62"
            d="M78.801,36.037 C76.815,16.36 60.201,1 40,1 C18.46,1 1,18.46 1,40 C1,61.54 18.46,79 40,79 C58.793,79 74.481,65.708 78.176,48.012 C79.298,45.029 79.506,43.174 78.801,42.446 C77.743,41.353 75.381,42.039 75.381,43.519 C75.381,44.465 77.761,48.233 82.523,54.824 C82.9363346,55.3964036 83.538311,55.8046853 84.223,55.977 L91.713,57.863 C92.6374672,58.095245 93.3965523,58.7529949 93.758,59.635 L102.182,80.204 C102.487105,80.9487588 102.480165,81.7848776 102.16274,82.5244694 C101.845316,83.2640613 101.24403,83.8450933 100.494,84.137 L88.264,88.894 C86.7421636,89.4854766 85.0267425,88.7513119 84.404,87.242 L75.964,66.784 C75.597374,65.8965804 75.6776713,64.8872873 76.18,64.069 L79.292,59.003"
            strokeWidth="2"
          />
          <path
            stroke="#001E62"
            d="M71.445,43.52 L68.881,43.215 M65.226,59.003 L63.158,57.463 M9.88,36.134 L7.315,35.834 M35.923,69.037 L35.617,71.59 M43.292,7.584 L42.986,10.137 M21.618,63.336 L20.071,65.396 M12.142,51.404 L9.774,52.429 M15.543,21.91 L13.47,20.377 M27.723,12.326 L26.706,9.963 M52.203,69.21 L51.186,66.847"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            fill="#00B0B9"
            d="M48.47,35.955 L79.603,22.51 C76.559035,15.4903911 71.7150037,9.3993029 65.561,4.853 L39.747,39.589"
            fillRule="nonzero"
          />
          <path
            stroke="#001E62"
            d="M48.47,35.955 L79.603,22.51 C76.559035,15.4903911 71.7150037,9.3993029 65.561,4.853 L39.747,39.589"
            strokeWidth="2"
            strokeLinecap="square"
          />
          <path
            fill="#00B0B9"
            d="M44.371,40.139 C44.067,42.683 41.748,44.499 39.194,44.195 C37.9697918,44.0519539 36.8526965,43.4280165 36.0888828,42.4606817 C35.3250691,41.493347 34.9772239,40.2620048 35.122,39.038 C35.427,36.495 37.745,34.678 40.299,34.983 C41.5231253,35.1258001 42.6402189,35.7495279 43.4040551,36.7167036 C44.1678913,37.6838793 44.5157653,38.9151056 44.371,40.139"
            fillRule="nonzero"
          />
          <path
            stroke="#001E62"
            d="M44.371,40.139 C44.067,42.683 41.748,44.499 39.194,44.195 C37.9697918,44.0519539 36.8526965,43.4280165 36.0888828,42.4606817 C35.3250691,41.493347 34.9772239,40.2620048 35.122,39.038 C35.427,36.495 37.745,34.678 40.299,34.983 C41.5231253,35.1258001 42.6402189,35.7495279 43.4040551,36.7167036 C44.1678913,37.6838793 44.5157653,38.9151056 44.371,40.139 L44.371,40.139 Z"
            strokeWidth="2"
          />
          <path
            fill="#00B0B9"
            stroke="#001E62"
            d="M86.603,69.084 C86.7266295,69.6717715 86.6114157,70.2845802 86.2827603,70.7873179 C85.9541049,71.2900557 85.4389955,71.64144 84.851,71.764 C84.0605499,71.9319148 83.2405624,71.6653744 82.6999188,71.0647819 C82.1592752,70.4641895 81.9801122,69.6207892 82.2299188,68.8522819 C82.4797253,68.0837746 83.1205499,67.5069148 83.911,67.339 C85.1358609,67.0795639 86.3397592,67.8599511 86.603,69.084 M95.583,76.99 C95.7069457,77.5780412 95.5919022,78.191234 95.2632386,78.6943595 C94.934575,79.1974849 94.4192856,79.5492195 93.831,79.672 C92.6062631,79.9319832 91.4022756,79.151248 91.14,77.927 C91.0160738,77.3390811 91.1311447,76.7260134 91.4598266,76.2230476 C91.7885084,75.7200818 92.3037923,75.3685483 92.892,75.246 C94.1161792,74.9878088 95.3187309,75.7679028 95.582,76.991"
            fillRule="nonzero"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  }
}

export const icon = SmartDiscount;
