import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { PhotoThumbnailList } from 'components/common/PhotoThumbnailList/PhotoThumbnailList';
import storeBuilder from 'reduxStore/store';
import { Provider, connect } from 'react-redux';
import { PhotoWithStatus, PhotosReducer } from 'components/settings/VenueDetails/reducer';
import { bindActionCreators } from 'redux';
import {
  VenuePhotoCroppingUpdateRequestAction,
  VenuePhotoRemoveRequestAction,
  VenuePhotosOrderRequestAction,
  VenuePhotosUploadRequestAction,
  venuePhotoCroppingUpdateRequest,
  venuePhotoRemoveRequest,
  venuePhotosOrderRequest,
  venuePhotosUploadRequest,
} from 'components/settings/VenueDetails/actions';
import { useImageEditDialog } from '../dialog/ImageEditDialog/useImageEditDialog';

const store = storeBuilder();

interface Params {
  node: HTMLElement;
}

const mapStateToProps = ({ venueDetails: { photos } }) => ({ photos });

const mapDispatchToProps = (dispatch) => ({
  removePhoto: bindActionCreators(venuePhotoRemoveRequest, dispatch),
  orderPhotos: bindActionCreators(venuePhotosOrderRequest, dispatch),
  uploadPhotos: bindActionCreators(venuePhotosUploadRequest, dispatch),
  updatePhotoCropping: bindActionCreators(venuePhotoCroppingUpdateRequest, dispatch),
});

export const PhotoThumbnailListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ({
    photos,
    removePhoto,
    orderPhotos,
    uploadPhotos,
    updatePhotoCropping,
  }: {
    photos: PhotosReducer['photos'];
    removePhoto: (params: VenuePhotoRemoveRequestAction['payload']) => void;
    orderPhotos: (params: VenuePhotosOrderRequestAction['payload']) => void;
    uploadPhotos: (params: VenuePhotosUploadRequestAction['payload']) => void;
    updatePhotoCropping: (params: VenuePhotoCroppingUpdateRequestAction['payload']) => void;
  }) => {
    const getPhotoByLocalId = (localId: string) => photos.find((p) => p.localId === localId);

    const onFilesSelected = (fileList: FileList) => {
      const venueId = App.config.get('venue').id;
      const files = Array.from(fileList);
      uploadPhotos({ venueId, files });
    };

    const openImageEditDialog = useImageEditDialog();

    const openImageEdit = ({ localId }) => {
      const photo = getPhotoByLocalId(localId);
      if (!photo || !photo.cropping || !photo.originalUri) {
        return;
      }
      const venueId = App.config.get('venue').id;
      openImageEditDialog({
        src: photo.originalUri,
        cropping: {
          x1: photo.cropping.x1,
          y1: photo.cropping.y1,
          x2: photo.cropping.x2,
          y2: photo.cropping.y2,
        },
        onSave: ({ x1, y1, x2, y2 }) =>
          updatePhotoCropping({
            venueId,
            localId,
            sourceImageId: photo.id!,
            cropping: { x1, y1, x2, y2 },
          }),
        onRemove: () => removePhoto({ localId: photo.localId }),
        onClose: () => {},
      });
    };

    const thumbnailListPhotos = useMemo(
      () =>
        photos.map((photo) => {
          const { localId, isLoading, error } = photo;
          return {
            id: localId,
            src: getThumbnailSrc(photo),
            alt: getThumbanilName(photo),
            isLoading: Boolean(isLoading),
            isEditable: Boolean(photo.cropping && photo.originalUri),
            error,
          };
        }),
      [photos],
    );

    return (
      <PhotoThumbnailList
        isEditable={Boolean(Wahanda.Permissions.canEditImages())}
        photos={thumbnailListPhotos}
        onRemove={({ id }) => removePhoto({ localId: id })}
        onEdit={({ id }) => openImageEdit({ localId: id })}
        onOrderChange={(photos) =>
          orderPhotos({ photos: photos.map(({ id }) => ({ localId: id })) })
        }
        onFilesSelected={onFilesSelected}
      />
    );
  },
);

export const PhotoThumbnailListInitializer = ({ node }: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(node),
  render: () => {
    ReactDOM.render(
      <Provider store={store}>
        <PhotoThumbnailListContainer />
      </Provider>,
      node,
    );
  },
});

const getThumbnailSrc = ({ src, uris, originalUri }: PhotoWithStatus) =>
  uris?.['99x66'] ||
  uris?.['120x80'] ||
  uris?.['156x104'] ||
  uris?.['198x132'] ||
  uris?.['240x160'] ||
  uris?.['312x208'] ||
  src ||
  originalUri ||
  '';

const getThumbanilName = ({ name, id, localId }: PhotoWithStatus) =>
  name || (id || localId)?.toString() || 'photo';
