import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Pos extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-pos', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M3.99339768,18 C4.00073842,18 4,18.0007502 4,18.0093689 L4,19.9906311 C4,19.9966341 3.99923177,19.999093 3.99811074,20 L20.0066023,20 C19.9992616,20 20,19.9992498 20,19.9906311 L20,18.0093689 C20,18.0033659 20.0007682,18.000907 20.0018893,18 L3.99339768,18 Z M3.99339768,20 L3.99811074,20 C3.99680054,20.00106 3.99500845,20 3.99339768,20 Z M2,18.0093689 C2,16.9032007 2.88902499,16 3.99339768,16 L20.0066023,16 C21.1127193,16 22,16.8962667 22,18.0093689 L22,19.9906311 C22,21.0967993 21.110975,22 20.0066023,22 L3.99339768,22 C2.88728071,22 2,21.1037333 2,19.9906311 L2,18.0093689 Z M5.13278222,16 L18.8672178,16 L18.1323509,10.1210649 C18.1265583,10.0747242 18.042104,10 17.9931545,10 L6.00684547,10 C5.95428046,10 5.87392701,10.0708416 5.86764911,10.1210649 L5.13278222,16 Z M11,1.38196601 L11,6 L9,6 L9,4.61803399 L8,5.11803399 L7,4.61803399 L7,7.99996601 L13.0000246,8.0007289 L13.0000246,8.0007289 L13,6.0093689 C13,4.89270473 13.8979628,4 15.0093689,4 L16.9906311,4 C18.1072953,4 19,4.89796285 19,6.0093689 L19,7.9906311 C19,8.07945666 18.9943182,8.16686512 18.983299,8.25251989 C19.5845478,8.57445426 20.0296398,9.17486035 20.1169066,9.8729954 L21.1327822,18 L2.86721778,18 L3.88309336,9.8729954 C3.97003688,9.17744724 4.40702003,8.58263162 4.99985369,8.25956493 L5,1.38196601 L8,2.88196601 L11,1.38196601 Z M11,12 L7,12 L7,14 L11,14 L11,12 Z M16.9906311,5.99999997 L15.0093689,5.99999997 C14.9999116,5.99999997 15,5.99991209 15,6.0093689 L15,7.9906311 C15,8.00008843 14.9999121,7.99999997 15.0093689,7.99999997 L16.9906311,7.99999997 C17.0000884,7.99999997 17,8.00008791 17,7.9906311 L17,6.0093689 C17,5.99991157 17.0000879,5.99999997 16.9906311,5.99999997 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Pos);
