import {
  GET_PARTNER_SITE_SETTINGS_RECEIVE,
  GET_PARTNER_SITE_SETTINGS_FAILURE,
  SELECT_PARTNER_SITE_THEME,
  TOGGLE_PARTNER_SITE_ENABLED,
  CHANGE_PARTNER_SITE_SUBDOMAIN,
  CHANGE_PARTNER_SITE_CUSTOM_DOMAIN,
  CHANGE_PARTNER_SITE_FACEBOOK_HANDLE,
  CHANGE_PARTNER_SITE_INSTAGRAM_HANDLE,
  CHANGE_PARTNER_SITE_TWITTER_HANDLE,
  CHANGE_PARTNER_SITE_IMPRESSUM_TEXT,
  SUBMIT_PARTNER_SITE_SETTINGS_REQUEST,
  SUBMIT_PARTNER_SITE_SETTINGS_SUCCESS,
  SUBMIT_PARTNER_SITE_SETTINGS_FAILURE,
  VALIDATE_PARTNER_SITE_SUBDOMAIN_REQUEST,
  VALIDATE_PARTNER_SITE_SUBDOMAIN_SUCCESS,
  VALIDATE_PARTNER_SITE_SUBDOMAIN_FAILURE,
  VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_REQUEST,
  VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_SUCCESS,
  VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_FAILURE,
  SET_SUBDOMAIN_VALIDATION_STATUS_TO_EMPTY,
  SET_CUSTOM_DOMAIN_VALIDATION_STATUS_TO_EMPTY,
  RESET_PARTNER_SITE_SETTINGS,
} from 'reduxStore/actionsConst';

import { Theme } from './themes';

/* Subdomain & Domain Validation statuses */

export enum ValidationStatus {
  INVALID = 'INVALID',
  UNAVAILABLE = 'UNAVAILABLE',
  OK = 'OK',
  EMPTY = 'EMPTY',
}

/* Redux state interfaces */
export interface PartnerSiteState {
  settings: PartnerSiteSettings;
  impressum: PartnerSiteImpressum;
  isLoading: boolean;
  isSubmitting: boolean;
  subdomainValidationStatus: PartnerSiteValidationStatus;
  customDomainValidationStatus: PartnerSiteValidationStatus;
  error?: PartnerSiteSettingsError;
}

/* Action payloads interfaces */
export interface PartnerSiteSettings {
  micrositeEnabled?: boolean;
  micrositeCustomDomain?: string;
  micrositeProEnabled?: boolean;
  micrositeSubdomain?: string;
  partnerSitesDomain?: string;
  facebookHandle?: string;
  twitterHandle?: string;
  instagramHandle?: string;
  theme?: Theme;
}

export interface PartnerSiteImpressum {
  text: string;
}

export interface PartnerSiteSettingsAndImpressum {
  settings: PartnerSiteSettings;
  impressum: PartnerSiteImpressum;
}

export interface PartnerSiteSettingsError {
  errorMessage: string;
}

export interface PartnerSiteTheme {
  theme: Theme;
}

export interface PartnerSiteSubdomain {
  subdomain: string;
}

export interface PartnerSiteCustomDomain {
  customDomain: string;
}

export interface FacebookHandle {
  facebookHandle: string;
}

export interface InstagramHandle {
  instagramHandle: string;
}

export interface TwitterHandle {
  twitterHandle: string;
}

export interface PartnerSiteValidationStatus {
  status: ValidationStatus;
}

/* Action interfaces */
export interface GetPartnerSiteSettingsReceiveAction {
  type: typeof GET_PARTNER_SITE_SETTINGS_RECEIVE;
  payload: PartnerSiteSettingsAndImpressum;
}

export interface GetPartnerSiteSettingsFailureAction {
  type: typeof GET_PARTNER_SITE_SETTINGS_FAILURE;
  payload: PartnerSiteSettingsError;
}

export interface ToggleEnablePartnerSiteAction {
  type: typeof TOGGLE_PARTNER_SITE_ENABLED;
}

export interface SelectPartnerSiteThemeAction {
  type: typeof SELECT_PARTNER_SITE_THEME;
  payload: PartnerSiteTheme;
}

export interface ChangePartnerSiteSubdomainAction {
  type: typeof CHANGE_PARTNER_SITE_SUBDOMAIN;
  payload: PartnerSiteSubdomain;
}

export interface ChangePartnerSiteCustomDomainAction {
  type: typeof CHANGE_PARTNER_SITE_CUSTOM_DOMAIN;
  payload: PartnerSiteCustomDomain;
}

export interface ChangePartnerSiteFacebookHandleAction {
  type: typeof CHANGE_PARTNER_SITE_FACEBOOK_HANDLE;
  payload: FacebookHandle;
}

export interface ChangePartnerSiteInstagramHandleAction {
  type: typeof CHANGE_PARTNER_SITE_INSTAGRAM_HANDLE;
  payload: InstagramHandle;
}

export interface ChangePartnerSiteTwitterHandleAction {
  type: typeof CHANGE_PARTNER_SITE_TWITTER_HANDLE;
  payload: TwitterHandle;
}

export interface ChangePartnerSiteImpressumTextAction {
  type: typeof CHANGE_PARTNER_SITE_IMPRESSUM_TEXT;
  payload: string;
}

export interface SubmitPartnerSiteSettingsRequestAction {
  type: typeof SUBMIT_PARTNER_SITE_SETTINGS_REQUEST;
}

export interface SubmitPartnerSiteSettingsSuccessAction {
  type: typeof SUBMIT_PARTNER_SITE_SETTINGS_SUCCESS;
}

export interface SubmitPartnerSiteSettingsFailureAction {
  type: typeof SUBMIT_PARTNER_SITE_SETTINGS_FAILURE;
  payload: PartnerSiteSettingsError;
}

export interface ValidatePartnerSiteSubdomainRequestAction {
  type: typeof VALIDATE_PARTNER_SITE_SUBDOMAIN_REQUEST;
}

export interface ValidatePartnerSiteSubdomainSuccessAction {
  type: typeof VALIDATE_PARTNER_SITE_SUBDOMAIN_SUCCESS;
  payload: PartnerSiteValidationStatus;
}

export interface ValidatePartnerSiteSubdomainFailureAction {
  type: typeof VALIDATE_PARTNER_SITE_SUBDOMAIN_FAILURE;
  payload: PartnerSiteSettingsError;
}

export interface ValidatePartnerSiteCustomDomainRequestAction {
  type: typeof VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_REQUEST;
}

export interface ValidatePartnerSiteCustomDomainSuccessAction {
  type: typeof VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_SUCCESS;
  payload: PartnerSiteValidationStatus;
}

export interface ValidatePartnerSiteCustomDomainFailureAction {
  type: typeof VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_FAILURE;
  payload: PartnerSiteSettingsError;
}

export interface SetSubdomainValidationStatusToEmptyAction {
  type: typeof SET_SUBDOMAIN_VALIDATION_STATUS_TO_EMPTY;
}

export interface SetCustomDomainValidationStatusToEmptyAction {
  type: typeof SET_CUSTOM_DOMAIN_VALIDATION_STATUS_TO_EMPTY;
}

export interface ResetPartnerSiteSettingsAction {
  type: typeof RESET_PARTNER_SITE_SETTINGS;
}

export type PartnerSiteActionTypes =
  | GetPartnerSiteSettingsReceiveAction
  | GetPartnerSiteSettingsFailureAction
  | ToggleEnablePartnerSiteAction
  | SelectPartnerSiteThemeAction
  | ChangePartnerSiteSubdomainAction
  | ChangePartnerSiteCustomDomainAction
  | ChangePartnerSiteFacebookHandleAction
  | ChangePartnerSiteInstagramHandleAction
  | ChangePartnerSiteTwitterHandleAction
  | ChangePartnerSiteImpressumTextAction
  | SubmitPartnerSiteSettingsRequestAction
  | SubmitPartnerSiteSettingsSuccessAction
  | SubmitPartnerSiteSettingsFailureAction
  | ValidatePartnerSiteSubdomainRequestAction
  | ValidatePartnerSiteSubdomainSuccessAction
  | ValidatePartnerSiteSubdomainFailureAction
  | ValidatePartnerSiteCustomDomainRequestAction
  | ValidatePartnerSiteCustomDomainSuccessAction
  | ValidatePartnerSiteCustomDomainFailureAction
  | SetSubdomainValidationStatusToEmptyAction
  | SetCustomDomainValidationStatusToEmptyAction
  | ResetPartnerSiteSettingsAction;
