import { xhr } from 'common/xhr';

/**
 * Venue menu.
 */
App.Models.Menu = BackboneEx.Model.Silent.extend(
  {
    defaults: {
      filter: null,
      include: 'skus',
      serviceTypes: null,

      groupsCollection: null,
      offersCollection: null,
    },

    initialize: function () {
      this.set({
        groupsCollection: new App.Collections.MenuGroups(),
        offersCollection: new App.Collections.MenuOffers(),
      });
    },

    // Override to return correct representation: the one the API gives
    toJSON: function () {
      return {
        groups: this.get('groupsCollection').toJSON(),
        offers: this.get('offersCollection').toJSON(),
      };
    },

    url: function () {
      var base =
        '/menu.json?{{#filter}}filter={{filter}}{{/filter}}{{#include}}&include={{include}}{{/include}}{{#serviceTypes}}&service-types={{serviceTypes}}{{/serviceTypes}}';
      var sTypes = this.get('serviceTypes');
      return App.Api.wsVenueUrl(
        Wahanda.Template.render(base, {
          venueId: this.get('venueId'),
          filter: this.get('filter'),
          include: this.get('include'),
          serviceTypes: _.isArray(sTypes) ? sTypes.join(',') : sTypes,
        }),
      );
    },

    parse: function (data) {
      var venueId = this.get('venueId');
      _.each(data.offers, function (o) {
        if (o.menuItemTypeCode != 'T' && typeof o.components === 'undefined') {
          o.components = [];
        }
        o.venueId = venueId;
      });

      // If a group has empty name, set it to a default one
      var tmpGroup = _.find(data.groups, function (g) {
        return g.name === '';
      });
      if (tmpGroup) {
        tmpGroup.name = Wahanda.lang.menu.menuGroupUnassigned;
      }

      return {
        groupsCollection: this.get('groupsCollection').reset(data.groups),
        offersCollection: this.get('offersCollection').reset(data.offers),
      };
    },

    copyFrom: function (otherMenu) {
      this.set(this.parse(otherMenu.toJSON()));
    },

    reorderGroups: function (orderedList, successCallback) {
      var url = App.Api.wsUrl('/venue/' + App.config.get('venue').id + '/menu-groups/reorder.json');

      xhr.doJQueryAjax({
        url,
        type: 'POST',
        data: JSON.stringify({
          displayOrder: orderedList,
        }),
        contentType: 'application/json',
        success: successCallback,
      });
    },

    toSelectOptions: function (config) {
      // config.includeOffers - true if offers has to be rendered. true - by default.

      if (config == null) {
        config = {};
      }

      if (config.includeOffers == null) {
        config.includeOffers = true;
      }
      // Legacy mode returns sku:$id and offer:$id as value instead of just $id
      if (config.legacy == null) {
        config.legacy = true;
      }

      // Optgroup  - group
      // 1st level - service
      // 2nd level - SKUs (if > 1)
      var html = '';

      if (config.empty) {
        html += '<option value="">' + Wahanda.lang.shared.selectInitialOption + '</option>';
      }

      var offersCollection = this.get('offersCollection');
      var offerMatcher = null;
      if (!config.withArchived) {
        offerMatcher = function (offer) {
          return !!offer.get('visible');
        };
      }
      this.get('groupsCollection').each(function (group) {
        var offers = offersCollection.getByGroupId(group.id, offerMatcher);
        if ((config.skipDated || config.skipPackages) && offers.length > 0) {
          offers = _.filter(offers, function (offer) {
            var valid = true;
            if (config.skipDated) {
              valid = !offer.isEscapeWithRangePricing();
            }
            if (valid && config.skipPackages) {
              valid = !offer.isServicePackage();
            }
            return valid;
          });
        }
        if (offers.length === 0) {
          return;
        }

        var htmlOffers = '';
        if (config.includeOffers) {
          _.each(offers, function (offer) {
            htmlOffers += offer.toSelectOptions(config);
          });
        }

        html += group.toSelectOptions(htmlOffers);
      });

      return html;
    },

    toSimpleList: function (config) {
      if (config == null) {
        config = {};
      }

      var items = [];
      var alwaysIncluded = config.alwaysInclude;

      if (config.empty) {
        items.push({
          name: config.initialOptionName || Wahanda.lang.shared.selectInitialOption,
          value: null,
        });
      }

      var offersCollection = this.get('offersCollection');
      var offerMatcher = null;
      if (!config.withArchived) {
        offerMatcher = function (offer) {
          return offer.id === alwaysIncluded || !!offer.get('visible');
        };
      }
      this.get('groupsCollection').each(function (group) {
        var offers = offersCollection.getByGroupId(group.id, offerMatcher);
        if ((config.skipDated || config.skipPackages) && offers.length > 0) {
          offers = _.filter(offers, function (offer) {
            var valid = true;

            if (offer.id === alwaysIncluded) {
              return true;
            }

            if (config.skipDated) {
              valid = !offer.isEscapeWithRangePricing();
            }
            if (valid && config.skipPackages) {
              valid = !offer.isServicePackage();
            }
            return valid;
          });
        }
        if (offers.length === 0) {
          return;
        }

        items.push({
          name: group.get('name'),
          value: group.id,
          grouping: true,
        });
        items = items.concat(
          _.map(offers, function (offer) {
            return {
              name: offer.getDisplayName(),
              value: offer.id,
              groupingValue: group.id,
            };
          }),
        );
      });

      return items;
    },

    offersToGroups: function () {
      var offerGroups = [];
      var offers = this.get('offersCollection');
      var groups = this.get('groupsCollection');
      for (var i = 0; i < groups.length; i++) {
        var model = groups.at(i);
        var groupId = model.get('id');
        var groupOfferCollection = offers.filter(function (o) {
          return o.get('groupId') === groupId;
        });
        offerGroups.push({
          group: model,
          offers: groupOfferCollection,
        });
      }
      return offerGroups;
    },

    /**
     * Has the collection at least one spa day?
     *
     * @param Object opts Options. Currently available:
     * > onlyDated - filter only dated spa days
     * @return boolean
     */
    hasSpaDays: function (opts) {
      opts = opts || {};
      var dated = opts.onlyDated ? true : null;
      return (
        null !=
        this.get('offersCollection').find(function (offer) {
          return offer.isSpaDay() && (dated ? offer.isFulfilledAs('dated') : true);
        })
      );
    },

    hasSpaBreaks: function () {
      return (
        null !=
        this.get('offersCollection').find(function (offer) {
          return offer.isSpaBreak();
        })
      );
    },

    getDatedWithNoPricing: function () {
      return this.get('offersCollection').filter(function (offer) {
        return offer.isFulfilledAs('dated') && !offer.hasDatedPricing();
      });
    },

    hasNonDatedServices: function () {
      return this.get('offersCollection').any(function (offer) {
        return !offer.isEscapeWithRangePricing();
      });
    },

    hasOffersWithPricing: function () {
      return this.get('offersCollection').any(function (offer) {
        return offer.hasPricesSet();
      });
    },

    getTreatmentIds: function (serviceId) {
      var service = this.get('offersCollection').find(function (offer) {
        return offer.id === serviceId;
      });
      return service.get('treatmentIds');
    },
  },
  {
    /**
     * Create a new instance of self and set the data from `data`.
     *
     * @param {Object} data { groups, offers }
     * @returns {App.Models.Menu} self
     */
    of: function (data) {
      const model = new App.Models.Menu();
      model.set(model.parse(data));

      return model;
    },
  },
);
