import React from 'react';

interface Props {
  className?: string;
}

class EnvelopeSmartphone extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-envelope-smartphone', this.props.className].join(' ')}
        viewBox="0 0 64 45"
      >
        <g fill="none" transform="translate(1 1)" fillRule="evenodd">
          <path
            fill="#00B0B9"
            d="M4.184 39.738c2.473-3.59 9.737-5.061 13.943-4.913 9.226.326 16.33 5.331 21.912-5.785 1.02-2.032 1.48-3.653 1.934-5.097.3-.956.493 3.18.578 12.407l9.493.65-1.556 5.875c-32.154.52-48.128.467-47.922-.16.113-.342.217-.678.393-.994.466-.836.922-1.544 1.225-1.983z"
          />
          <path
            stroke="#001E62"
            d="M52 36v3a4 4 0 01-4 4H4a4 4 0 01-4-4V11a4 4 0 014-4h39"
            strokeWidth="2"
          />
          <path
            stroke="#001E62"
            d="M52 37h-7a3 3 0 01-3-3V3a3 3 0 013-3h14a3 3 0 013 3v31a3 3 0 01-3 3h-1.878"
            strokeWidth="2"
            strokeLinecap="square"
          />
          <path
            stroke="#001E62"
            d="M5.103 11.678l3.18 2.437L26 27.695l15.708-12.09"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <rect width="6" height="2" x="49" y="3" fill="#001E62" rx="1" />
          <circle cx="52" cy="31" r="2" stroke="#001E62" strokeWidth="2" />
        </g>
      </svg>
    );
  }
}

export const icon = EnvelopeSmartphone;
