import React from 'react';
import { ClientFormsCreateStep2 } from './ClientFormsCreateStep2';
import { useClientFormsMutations } from './useClientFormsMutations';
import { WizardModal } from 'components/common/WizardModal';
import Wahanda from 'common/wahanda';
import { FORM_TEMPLATE_STATUSES, FormTemplate } from '../FormTemplates/types';
import { ClientFormsDisableConfirmationModal } from './ClientFormsDisableConfirmationModal';

const lang = Wahanda.lang.marketing.clientForms;

type ClientFormsUpdateProps = {
  onClose: <T>(updatedForm?: T) => void;
  formTemplate: FormTemplate;
};

export const ClientFormsUpdate = ({ onClose, formTemplate }: ClientFormsUpdateProps) => {
  const [wizardOpen, setWizardOpen] = React.useState(
    formTemplate.status === FORM_TEMPLATE_STATUSES.DISABLED,
  );
  const {
    selectedServices,
    handleChangeSelectedServices,
    handleFormSubmit,
    goBackToFormsList,
    isError,
    formTemplateDetailsLoading,
  } = useClientFormsMutations({
    onCloseCallback: onClose,
    formId: formTemplate.id,
  });

  const validateStep2 = () => !selectedServices.length;

  const steps = [
    {
      element: (
        <ClientFormsCreateStep2
          selectedServices={selectedServices}
          onChangeSelectedServices={handleChangeSelectedServices}
          displayError={isError}
          displayStepNumber={false}
          formTemplateDetailsLoading={formTemplateDetailsLoading}
          sortServicesBySelected
        />
      ),
      nextStepDisabled: validateStep2(),
    },
  ];

  return (
    <>
      {wizardOpen ? (
        <WizardModal
          steps={steps}
          onClose={goBackToFormsList}
          onSubmit={handleFormSubmit}
          lastStepLocale={lang.saveAndActivate}
        />
      ) : (
        <ClientFormsDisableConfirmationModal
          formId={formTemplate.id}
          onExit={onClose}
          onContinue={() => setWizardOpen(true)}
        />
      )}
    </>
  );
};
