App.Collections.RoomAllocations = Backbone.Collection.extend({
  model: App.Models.RoomAllocation,

  // URL parameters
  dateFrom: null,
  dateTo: null,
  roomTypeId: null,
  // Array || String
  include: null,

  // App.Collections.RoomTypes
  roomTypesCollection: null,

  setRoomTypes: function (collection) {
    this.roomTypesCollection = collection;
  },

  url: function () {
    var base =
      '/venue/' +
      App.config.get('venue').id +
      '/room-allocations.json?' +
      'date-from=' +
      this._toApiDate(this.dateFrom) +
      '&date-to=' +
      this._toApiDate(this.dateTo);
    if (null != this.roomTypeId) {
      base += '&room-type-id=' + this.roomTypeId;
    }
    if (null != this.include) {
      base += '&include=' + String(this.include);
    }
    return App.Api.wsUrl(base);
  },

  _toApiDate: function (date) {
    if (!date) {
      return '';
    }
    return typeof date === 'string' ? date : Wahanda.Date.toApiString(date);
  },

  parse: function (data) {
    return data.allocations;
  },

  getSoldOut: function () {
    return this.filter(function (model) {
      return _.any(model.get('roomTypes'), function (room) {
        return room.inventoryAvailable === 0;
      });
    });
  },

  /**
   * Returns RoomAllocation model by date.
   *
   * @param String dateString
   * @return App.Models.RoomAllocation
   */
  getByDate: function (dateString) {
    var model = this.get(dateString);
    if (!model) {
      model = new App.Models.RoomAllocation({
        date: dateString,
      });
      model.notSet = true;
      this.add(model);
    } else if (!model.get('roomTypes') || model.get('roomTypes').length === 0) {
      model.notSet = true;
    }
    if (!model._valuesPrefilled) {
      model._valuesPrefilled = true;
      model.fillMissingValues(this.roomTypesCollection);
    }
    return model;
  },
});
