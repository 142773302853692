import React, { Component } from 'react';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { trackEvent } from 'common/analytics';
import ImageUploadButton from './ImageUploadButton';
// @ts-expect-error ts-migrate(2430) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
interface IEmployeeProfilePhotoProps extends React.HTMLAttributes<Element> {
  id?: number;
  imageUris?: {};
  actions: {
    setEmployeeDataAction: (...args: any[]) => any;
  };
  setEmployeeDataAction?: any;
}

export default class EmployeeProfilePhoto extends Component<IEmployeeProfilePhotoProps, {}> {
  static defaultProps = {
    id: null,
    imageUris: null,
  };

  componentDidMount() {
    App.on(Wahanda.Event.EMPLOYEE_IMAGE_UPDATED, this.onPhotoUploaded);
  }

  componentWillUnmount() {
    App.off(Wahanda.Event.EMPLOYEE_IMAGE_UPDATED, this.onPhotoUploaded);
  }

  onPhotoUploaded = ({ image }) => {
    this.props.actions.setEmployeeDataAction({ imageId: image.id, image });
  };

  onAddClick = () => {
    const { id } = this.props;
    const view = new App.Views.Forms.Employee.EditImage({
      model: new App.Models.Employee({ id }),
    });
    view.render();
    view.open();
    trackEvent('team-member', 'click', 'image');
  };

  onRemoveClick = () => {
    trackEvent('team-member', 'click', 'remove-image');
    this.props.actions.setEmployeeDataAction({ imageId: null, image: null });
  };

  render() {
    const { imageUris } = this.props;
    return (
      <ImageUploadButton
        imageUris={imageUris}
        onAddClick={this.onAddClick}
        onRemoveClick={this.onRemoveClick}
      />
    );
  }
}
