import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../store/actions';

import { ShiftScheduleChangeModal as Modal } from './ShiftScheduleChangeModal';

const mapStateToProps = ({ shifts }) => ({
  datePickerRange: shifts.datePickerRange,
  isSavingShift: shifts.isSavingShift,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ShiftScheduleChangeModal = connect(mapStateToProps, mapDispatchToProps)(Modal);
