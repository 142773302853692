import React, { useState } from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import { useImmer } from 'use-immer';

import { Notice } from 'components/common/Notice';
import { Select } from 'components/common/Select';
import { Button } from 'components/common/Button';
import { Repeated } from 'components/common/Icon';

import {
  DEFAULT_RECURRENCE_COUNT,
  DEFAULT_RECURRENCE_INTERVAL,
  COUNTS,
  INTERVALS,
} from './utils/constants';
import { AppointmentRepeatBase, AppointmentRepeatModel } from './utils/types';
import { AppointmentRepeatAnalytics } from './utils/tracking';

import style from './AppointmentRepeatRow.scss';

export interface AppointmentRepeatRowProps {
  showSelectors?: boolean;
  selectedDate: string;
  onComponentLoad: (model: AppointmentRepeatModel) => void;
  onRepeatModelChange: (model: AppointmentRepeatModel) => void;
  onRemoveClick: () => void;
}

export const AppointmentRepeatRow = ({
  showSelectors = true,
  selectedDate,
  onComponentLoad,
  onRepeatModelChange,
  onRemoveClick,
}: AppointmentRepeatRowProps) => {
  const baseRepeat = AppointmentRepeatBase.WEEKLY;
  const [currentDate, setCurrenDate] = useState(selectedDate);
  const [selectValues, setSelectValues] = useImmer({
    count: DEFAULT_RECURRENCE_COUNT,
    interval: DEFAULT_RECURRENCE_INTERVAL,
  });
  const untilDate = moment(currentDate)
    .add(selectValues.interval * (selectValues.count - 1), 'w')
    .formatLongDateWithDay();
  const hasRepeat = selectValues.interval > 0;

  const noticeMessage = Wahanda.Template.render(
    Wahanda.lang.calendar.appointments.multi.labels.repeatsUntil,
    {
      date: untilDate,
    },
  );

  const handleIntervalChange = (val) => {
    setSelectValues((draft) => {
      draft.interval = val;
    });
    AppointmentRepeatAnalytics.trackIntervalChange(baseRepeat, val);
  };

  const handleCountChange = (val) => {
    setSelectValues((draft) => {
      draft.count = val;
    });
    AppointmentRepeatAnalytics.trackCountChange(val);
  };

  const handleRemoveClick = () => {
    onRemoveClick();
    AppointmentRepeatAnalytics.trackRemoveClick();
  };

  const repeatModel = {
    recurrence: {
      base: baseRepeat,
      properties: {
        count: selectValues.count,
        interval: selectValues.interval,
      },
    },
  };

  React.useLayoutEffect(() => {
    onComponentLoad(repeatModel);
    AppointmentRepeatAnalytics.trackComponentLoad();
  }, []);

  React.useLayoutEffect(() => {
    setCurrenDate(selectedDate);
  }, [selectedDate]);

  React.useLayoutEffect(() => {
    onRepeatModelChange(repeatModel);
  }, [selectValues]);

  return (
    <div className={style.container}>
      {showSelectors && (
        <div className={style.selectWrapper}>
          <div className={style.select}>
            <Select
              name="repeat-selector-interval"
              label={Wahanda.lang.calendar.appointments.multi.labels.repeatEvery}
              options={INTERVALS}
              dataTest="repeat-selector-interval"
              selectedOption={selectValues.interval}
              onSelect={handleIntervalChange}
            />
          </div>
          {hasRepeat && (
            <div className={style.select}>
              <Select
                name="repeat-selector-count"
                label={Wahanda.lang.calendar.appointments.multi.labels.repeatEnds}
                options={COUNTS}
                dataTest="repeat-selector-count"
                selectedOption={selectValues.count}
                onSelect={handleCountChange}
              />
            </div>
          )}
        </div>
      )}
      {hasRepeat && <Notice type="info" message={noticeMessage} icon={<Repeated />} />}
      <div className={style.actions}>
        <Button
          dataTest="repeat-remove-button"
          variant="hollow"
          colour="help"
          label={Wahanda.lang.calendar.appointments.multi.buttons.removeRepeat}
          onClick={handleRemoveClick}
        />
      </div>
    </div>
  );
};
