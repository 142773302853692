import React from 'react';

interface Props {
  onSuccess: (result: File) => void;
  accept: string;
  render: (props: { onUpload: () => void }) => any;
}

export class UploadButton extends React.PureComponent<Props> {
  private inputRef = React.createRef<HTMLInputElement>();

  private onUpload = () => {
    this.inputRef.current!.click();
  };

  private onSuccess = (event: any) => {
    const { onSuccess } = this.props;
    const file = event.target.files[0];
    onSuccess(file);
  };

  public render() {
    const { accept } = this.props;
    return (
      <div>
        <input
          style={{ display: 'none' }}
          ref={this.inputRef}
          onChange={this.onSuccess}
          type="file"
          accept={accept}
        />
        {this.props.render({
          onUpload: this.onUpload,
        })}
      </div>
    );
  }
}
