Wahanda.Geocoder = {
	ERROR_NOT_FOUND  : 1,
	ERROR_APPROXIMATE: 2,
	ERROR_NO_ADDRESS : 3,
	
    coder: null,
    
    /** @return google.maps.Geocoder */
    getCoder: function() {
        if (!this.coder) {
            this.coder = new google.maps.Geocoder();
        }
        return this.coder;
    },
    
    /**
     * Queries the Google geocoder and fetches results.
     * 
	 * @param Object options
	 *  > String address: the address to geocode
	 *  > function success: Successful geocode callback
	 *  > function error: gets called, when geocode does not return anything good. It is still passed the results
	 * 		array, and gets one of ERROR_* constants as the second parameter.
     */
    getResult: function(options) {
        if (!options.address || options.address.length < 5) {
        	options.error && options.error(null, Wahanda.Geocoder.ERROR_NO_ADDRESS);
            return;
        }
        
        this.getCoder().geocode(
            { 'address': options.address },
            function(results, status) {
                if (status === "OK") {
                    if (results && results[0] && results[0].geometry.location_type !== "APPROXIMATE") {
                        options.success(results);
                    } else {
                    	options.error && options.error(results, Wahanda.Geocoder.ERROR_APPROXIMATE);
                    }
                } else {
                	options.error && options.error(results, Wahanda.Geocoder.ERROR_NOT_FOUND);
                }
            }
        );
    }
};