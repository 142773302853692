import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class FacebookLite extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-facebook-lite', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 2c-4.42 0-8 3.58-8 8a8 8 0 006 7.748V14.5H8v-2h2v-2a3 3 0 012.824-2.995L13 7.5h2v2h-2a1 1 0 00-.993.883L12 10.5v2h3v2h-3V20a7.998 7.998 0 007.996-7.75L20 12c0-4.42-3.58-8-8-8z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(FacebookLite);
