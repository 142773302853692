import React from 'react';
import { concat, get, hash, hashMap, map, vector, toJs } from 'mori';
import SummaryRowsView from './row';
// @ts-expect-error ts-migrate(2430) FIXME: Type '{}' is not assignable to type 'string'.
interface IPricingProps extends React.HTMLAttributes<Element> {
  isCancellation: boolean;
  data: {};
  lang: {};
}

export default class Pricing extends React.Component<IPricingProps, {}> {
  getByType(isCancellation, ifCheckout, ifCancellation) {
    return isCancellation ? ifCancellation : ifCheckout;
  }

  render() {
    const { isCancellation, data, lang } = this.props;
    const getPaymentType = (paymentMethod) => {
      const t = get(paymentMethod, this.getByType(isCancellation, 'type', 'paymentType'));
      // Different types: one is for checkout, another - for refund...
      // for this use case, we simplify that to VOUCHER,
      // but we should refactor this...
      return t === 'VCHR' ? 'VOUCHER' : t;
    };
    const totals = get(
      data,
      this.getByType(isCancellation, 'checkoutTotals', 'cancellationTotals'),
    );
    const total = Math.abs(
      get(totals, this.getByType(isCancellation, 'totalAmount', 'overallAmount')),
    );
    const paymentMethods = map((paymentMethod) => {
      const paymentType = getPaymentType(paymentMethod);
      const voucherIssuer = get(paymentMethod, 'voucherIssuer', '');
      const vouncherText = () => {
        if (!voucherIssuer) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'paymentVoucher' does not exist on type '... Remove this comment to see the full error message
          return lang.paymentVoucher;
        }
        const voucherCode = get(paymentMethod, 'voucherCode');
        if (!voucherCode) {
          return voucherIssuer;
        }
        return `${voucherIssuer} (${get(paymentMethod, 'voucherCode')})`;
      };
      const names = hashMap(
        'CASH',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'paymentCash' does not exist on type '{}'... Remove this comment to see the full error message
        lang.paymentCash,
        'change',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'paymentChange' does not exist on type '{... Remove this comment to see the full error message
        lang.paymentChange,
        'CARD',
        get(paymentMethod, 'cardType'),
        'VOUCHER',
        vouncherText(),
        'tip',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'tip' does not exist on type '{}'.
        lang.tip,
        'TTPY',
        'Tap To Pay',
      );
      const toRow = (_isCancellation, _paymentType, totalPrice) => (
        <SummaryRowsView
          name={get(names, _paymentType)}
          hideZero
          totalPrice={totalPrice}
          negative={_isCancellation}
          key={`row-${_paymentType}-${hash(paymentMethod)}`}
        />
      );
      const row = toRow(isCancellation, paymentType, get(paymentMethod, 'amount'));
      if (paymentType === 'CASH' && get(totals, 'totalChangeAmount') > 0) {
        const rows = vector(row, toRow(isCancellation, 'change', get(totals, 'totalChangeAmount')));
        return rows;
      }
      return row;
    }, get(data, this.getByType(isCancellation, 'payments', 'refunds')));
    const totalRows = (() => {
      const totalRow = (
        <SummaryRowsView
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'totalPrice' does not exist on type '{}'.
          name={lang.totalPrice}
          totalPrice={total}
          additionalClass="total"
          negative={isCancellation}
          key="total"
        />
      );
      if (
        get(totals, this.getByType(isCancellation, 'totalAmountPrePaid', 'prepaidRefundsAmount'))
      ) {
        const amount = Math.abs(
          get(totals, this.getByType(isCancellation, 'totalAmountPrePaid', 'prepaidRefundsAmount')),
        );
        return vector(
          totalRow,
          <SummaryRowsView
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'prepaid' does not exist on type '{}'.
            name={lang.prepaid}
            totalPrice={amount}
            negative={isCancellation}
            key="total-prepay"
          />,
        );
      }
      return vector(totalRow);
    })();
    const cardTip = (() => {
      if (get(totals, 'totalTipAmount')) {
        return vector(
          <SummaryRowsView
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'tip' does not exist on type '{}'.
            name={lang.tip}
            totalPrice={get(totals, 'totalTipAmount')}
            hideZero
            negative={isCancellation}
            key="total-tip-amount"
          />,
        );
      }
      return null;
    })();
    return (
      <div className="transaction-dialog-pricing-section">
        {toJs(concat(totalRows, paymentMethods, cardTip))}
      </div>
    );
  }
}
