import React from 'react';
import Wahanda from 'common/wahanda';
import { LogoTreatwell } from 'components/common/IconPicture';
import { TermsOfServiceModal } from '../TermsOfServiceModal';
import { LoginAnalytics } from '../tracking';
import style from './LoginFooter.scss';

export const LoginFooter: React.FC = () => {
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const handleAboutClick = () => {
    LoginAnalytics.trackAboutClick();
  };
  const handleContactsClick = () => {
    LoginAnalytics.trackContactsClick();
  };
  const handleTermsClick = () => {
    LoginAnalytics.trackTermsClick();
    setShowTermsModal(true);
  };
  const handleClose = () => {
    setShowTermsModal(false);
  };
  return (
    <div className={style.container}>
      <LogoTreatwell className={style.logo} />
      <div className={style.footerLinks}>
        <a
          className={style.footerLink}
          href={Wahanda.lang.login.links.aboutUs}
          onClick={handleAboutClick}
        >
          {Wahanda.lang.footer.about}
        </a>
        <a
          className={style.footerLink}
          href={Wahanda.lang.login.links.contact}
          onClick={handleContactsClick}
        >
          {Wahanda.lang.footer.contacts}
        </a>
        <a className={style.footerLink} onClick={handleTermsClick}>
          {Wahanda.lang.footer.terms}
        </a>
        <span>{Wahanda.lang.login.copyright}</span>
      </div>
      {showTermsModal && <TermsOfServiceModal onClose={handleClose} />}
    </div>
  );
};
