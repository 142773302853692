import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import App from 'common/backbone-app';
import REPORT_TYPE from './reportTypes';

function getHashStart() {
  return `${App.config.currentVenueHash()}/salesReports`;
}

function persistStateToUrl(report, reportParams) {
  const paramsString = reportParams && reportParams.length > 0 ? `/${reportParams.join('/')}` : '';
  const hash = `${getHashStart()}/${report}${paramsString}`;
  App.mainRouter.navigate(hash);
}

function getStateFromUrl() {
  const defaultState = { report: REPORT_TYPE.SALES_OVERVIEW };
  const urlHash = window.location.hash;
  const hashStart = getHashStart();
  if (!urlHash.startsWith(hashStart)) {
    return defaultState;
  }
  const queryParamsString = urlHash.substring(hashStart.length);
  const queryParams = queryParamsString.split('/').filter((p) => p);
  if (queryParams.length === 0) {
    return defaultState;
  }
  return {
    report: queryParams[0],
    params: queryParams.slice(1),
  };
}

// reportDetailsUrlConnect is similar to React-Redux's connect method. It allows a component to take
// values from the url hash and to save changed values back to the URL.
// This component takes care of writing to and from the URL and exposes the URL as a report value,
// and an array of report parameters.
//
// mapUrlToProps(report, reportParams)
// report is a string, reportParams is an array of strings
//
// mapUpdateUrlToProps(save)
// save is a function that takes the report as a string and the reportParams as an array of strings
const reportDetailsUrlConnect = (mapUrlToProps, mapUpdateUrlToProps) => {
  function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
  }

  const enhance = (WrappedComponent) => {
    class ReportDetailsUrlConnect extends React.Component {
      constructor(props) {
        super(props);
        this.state = getStateFromUrl();
      }

      componentDidMount() {
        // We have to use 'hashchange' rather than 'popstate' as IE (not Edge) is bugged and doesn't
        // trigger the popstate event when the URL hash changes, and Microsoft are not going to fix
        // it. This means that we wouldn't notice when the report dropdown changed in IE.
        //
        // https://wahanda.atlassian.net/browse/SOF-118
        window.addEventListener('hashchange', this.onUrlChange);
      }

      componentWillUnmount() {
        window.removeEventListener('hashchange', this.onUrlChange);
      }

      onUrlChange = () => {
        this.setState(getStateFromUrl());
      };

      updateUrl = (report, reportParams) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'report' does not exist on type 'Readonly... Remove this comment to see the full error message
        persistStateToUrl(report || this.state.report, reportParams);
      };

      render() {
        const urlProps = mapUrlToProps
          ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'report' does not exist on type 'Readonly... Remove this comment to see the full error message
            mapUrlToProps(this.state.report, this.state.params, this.props)
          : {};
        const saveProps = mapUpdateUrlToProps
          ? mapUpdateUrlToProps(this.updateUrl, this.props)
          : {};
        return <WrappedComponent {...this.props} {...urlProps} {...saveProps} />;
      }
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'displayName' does not exist on type 'typ... Remove this comment to see the full error message
    ReportDetailsUrlConnect.displayName = `ReportDetailsUrlConnect(${getDisplayName(
      WrappedComponent,
    )})`;
    hoistNonReactStatic(ReportDetailsUrlConnect, WrappedComponent);
    return ReportDetailsUrlConnect;
  };
  return enhance;
};

export default reportDetailsUrlConnect;
