import React from 'react';
import { Badge } from 'components/common/Badge';

import style from './CampaignCardStatus.scss';

interface Props {
  text: string;
  isDisabled: boolean;
}

export const CampaignCardStatus = ({ isDisabled, text }: Props) => {
  const badgeType = isDisabled ? 'muted' : 'success';

  return (
    <div className={style.label}>
      <Badge text={text} type={badgeType} isLight={isDisabled} />
    </div>
  );
};

CampaignCardStatus.displayName = 'CampaignCardStatus';
