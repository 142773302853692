import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Facebook extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-facebook', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M14.215 5.906c-2.237 0-3.715 1.328-3.776 3.736l-.002.155V12H7.899v2.89h2.54v6.989C5.657 21.129 2 16.99 2 12 2 6.477 6.477 2 12 2s10 4.477 10 10c0 4.926-3.561 9.02-8.25 9.847l-.188.032V14.89h2.33L16.337 12h-2.773v-1.876c0-.79.387-1.562 1.63-1.562h1.26v-2.46l-.457-.067a14.443 14.443 0 00-1.781-.129z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Facebook);
