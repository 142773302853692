import { connect } from 'react-redux';
import {
  automatedMessagingSettingsRequest,
  automatedMessagingReset,
  setAutomatedMessagingSettingRequest,
} from 'components/marketing/AutomatedMessaging/actions';
import { Appvocacy as Component } from './Appvocacy';
import { SETTING } from 'components/marketing/AutomatedMessaging/types';

const mapStateToProps = ({ marketing: { automatedMessaging } }) => ({
  isSettingEnabled: automatedMessaging[SETTING.CONSENT_FOR_APPVOCACY],
  isSettingLoading:
    automatedMessaging.isLoading || automatedMessaging.loaders[SETTING.CONSENT_FOR_APPVOCACY],
});

const mapDispatchToProps = {
  setAutomatedMessagingSettingRequest,
  automatedMessagingSettingsRequest,
  automatedMessagingReset,
};

export const Appvocacy = connect(mapStateToProps, mapDispatchToProps)(Component);
