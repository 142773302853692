/* global BackboneEx */
/* global _ */
/* eslint no-underscore-dangle: 0 */
import APPOINTMENT_CHANNEL_CODE from 'common/constants/appointmentChannelCodes';

App.Views.Calendar.AppointmentTooltip = BackboneEx.View.CalendarTooltip.extend({
  template: 'calendar-appointment-tooltip',

  confirmedClass: 'sal-event-info-ok',
  unconfirmedClass: 'sal-event-info-web',
  checkedOutClass: 'sal-event-info-checkedout',
  noShowClass: 'sal-event-info-noshow',

  renderData: function renderData(calEvent, appointment) {
    const customer = appointment.getCustomer();
    const recipientDiffers =
      customer.get('recipientName') && customer.get('name') !== customer.get('recipientName');
    if (recipientDiffers) {
      this.$('.consumer').show();
    } else {
      this.$('.consumer').hide();
    }

    // Event type
    this.$('.type')
      .text(this._getTypeText(appointment.get('appointmentStatusCode')))
      .append(this._getTypeInfoText(appointment.get('appointmentStatusCode')));

    const isPrepaymentRequired = appointment.get('consumerPrepaymentRequired');
    let prepayIcon = '';
    if (isPrepaymentRequired) {
      prepayIcon = `<span class="prepay-icon" />`;
    }
    this.$('.client').text(appointment.getConsumerName()).prepend(prepayIcon);

    this.$('.consumer-name').text(customer.get('name'));
    this.$('.phone').text(
      appointment.canShowCustomerContacts() ? appointment.get('consumerPhone') || '' : '',
    );
    this.$('.date').text(this._getDate(appointment));
    this.$('.duration').html(this._getDuration(appointment));
    this.$('.service-title').text(this._addDotsIfTooLong(appointment.get('offerName')));

    this._renderCustomerStatus(appointment);

    const skuList = Wahanda.Template.renderTemplate(
      'appointment-sku-list-template',
      _.filter(appointment.get('skus'), (sku) => !sku.nameInherited),
    );
    this.$('.sku-titles').html(skuList);

    const notes = appointment.getAnyNotes();
    if (notes) {
      this.$('.notes-row').show().find('.notes').text(this._addDotsIfTooLong(notes));
    } else {
      this.$('.notes-row').hide();
    }

    this.$('.paid-by-customer-row').toggleClass('hidden', !calEvent.is_deposit_paid);
    if (calEvent.packageName) {
      this.$('.b-package-name').wShow();
      this.$('.v-package-name').text(calEvent.packageName);
    } else {
      this.$('.b-package-name').wHide();
    }

    if (calEvent.isRepeated) {
      this.$('.appointment-tooltip--recurring').wShow();
    } else {
      this.$('.appointment-tooltip--recurring').wHide();
    }

    this._renderAppointmentClasses(appointment);
    this._renderAmount(calEvent);
    this._renderSource(appointment);
    this._renderBooked(appointment);
    this._renderTaxField(appointment);

    this.$el.on('mouseleave', () => {
      this.options.onMouseLeave();
    });
  },

  _renderAppointmentClasses: function _renderAppointmentClasses(appointment) {
    this.$el.removeClass(
      `${this.confirmedClass} ${this.unconfirmedClass} ${this.checkedOutClass} ${this.noShowClass}`,
    );
    switch (appointment.get('appointmentStatusCode')) {
      case 'CN':
        this.$el.addClass(this.confirmedClass);
        break;
      case 'CP':
        this.$el.addClass(this.checkedOutClass);
        break;
      case 'NS':
        this.$el.addClass(this.noShowClass);
        break;
      default:
        this.$el.addClass(this.unconfirmedClass);
    }
  },

  _renderCustomerStatus: function _renderCustomerStatus(appointment) {
    const acquisitionLang = Wahanda.lang.calendar.appointments.multi.acquisition;
    const $customerStatusDOM = this.$('.js-client-status');

    if (appointment.get('walkIn')) {
      $customerStatusDOM.text(''); // reset the text to empty string
      return;
    }

    const firstTimeCustomer = appointment.get('firstTimeCustomer');
    if (typeof firstTimeCustomer === 'boolean') {
      // exclude null, undefined and falsy
      const repeatStatusText = firstTimeCustomer
        ? acquisitionLang.firstTime
        : acquisitionLang.repeating;
      this.$('.js-client-status').text(repeatStatusText);
    }
  },

  _renderSource: function _renderSource(apptModel) {
    const sourceRow = this.$('.source-row');
    const bookingActor = apptModel.get('bookingActor');
    const channelCode = apptModel.get('channelCode');

    let sourceImage;
    let sourceText;

    switch (bookingActor) {
      case 'SUPPLIER':
        sourceText = App.Models.Appointment.getDeviceText(apptModel);
        break;

      case 'SUPPLIERS_CUSTOMER':
        sourceText = Wahanda.lang.consumer.dialog.clientSource.widgetBooking;
        break;

      case 'CUSTOMER':
      default:
        if (channelCode && channelCode.startsWith(APPOINTMENT_CHANNEL_CODE.BOOKED_BY_GOOGLE)) {
          sourceImage = 'treatwell-badge-google';
        } else {
          sourceImage = 'treatwell-badge';
        }
        sourceText = '';
    }

    let icon = '';
    if (sourceImage) {
      icon = `<span class="icon ${sourceImage}"></span>`;
    }

    sourceRow.find('.v-text').html(icon + sourceText);
  },

  _renderTaxField: function _renderTaxField(appointment) {
    const feeRowDOM = this.$('.fee-row');
    const treatwellFeePercentage = appointment.get('treatwellFeePercentage');

    if (!Wahanda.Permissions.viewFinanceData() || treatwellFeePercentage == null) {
      feeRowDOM.wHide();
      return;
    }

    feeRowDOM.wShow();
    feeRowDOM.find('.v-text').text(`${treatwellFeePercentage}%`);
  },

  _renderBooked: function _renderBooked(apptModel) {
    const bookedRow = this.$('.booked-row');
    const bookedText = App.Models.Appointment.getBookingActorData(apptModel).text;
    const bookedTitle = Wahanda.lang.calendar.appointments.tooltip.labels.booked;

    bookedRow.find('.v-title').text(bookedTitle);
    bookedRow.find('.v-text').text(bookedText);
  },

  _renderAmount: function _renderAmount(appt) {
    const $block = this.$('.payment-row');

    if (appt.amount <= 0) {
      $block.hide();
      return;
    }

    let classes = '';
    const lang = Wahanda.lang.calendar.appointments.tooltip;
    let title = lang.labels.payment;
    let text;

    if (appt.checkedout || appt.paymentProtectionApplied) {
      title = lang.labels.grandTotal;
      text = '{{amount}}';
    } else if (appt.prepaid) {
      // Payment: Prepaid by customer £25 (Design incorrectly does not show the amount)
      text = lang.texts.prepaid;
      classes = 'prepaid';
    } else {
      // use appt.no_deposit_warning
      text = lang.texts.unpaid;
      classes = appt.no_deposit_warning ? 'unpaid' : '';
    }

    if (text == null) {
      $block.hide();
    } else {
      $block.find('.v-title').text(title);
      $block.find('.v-text').text(
        Wahanda.Template.render(text, {
          amount: Wahanda.Currency.getFormattedOrEmpty(appt.amount),
        }),
      );
      $block.attr('class', `payment-row ${classes}`);
      $block.show();
    }
  },

  _getTypeText: function _getTypeText(type) {
    const lang = Wahanda.lang.calendar.blockTime.types;
    switch (type) {
      case 'CN':
        return lang.appointmentConfirmed;
      case 'CP':
        return lang.appointmentCheckedOut;
      case 'NS':
        return lang.appointmentNoShow;
      default:
        return lang.appointmentUnconfirmed;
    }
  },

  _getTypeInfoText: function _getTypeInfoText(type) {
    const lang = Wahanda.lang.calendar.blockTime.types;
    switch (type) {
      case 'NS':
        return `<div class="type-info">${lang.appointmentNoShowInfo}</div>`;
      default:
        return null;
    }
  },

  _getDate: function _getDate(appointment) {
    const start = appointment.getStartDate();
    const end = appointment.getEndDateWithAdditionalTime();

    const startInfo = Wahanda.Date.formatToDefaultFullDate(start);
    const endInfo = Wahanda.Date.formatToDefaultFullDate(end);

    return `${startInfo.date}, ${startInfo.time} - ${endInfo.time}`;
  },

  _getDuration: function _getDuration(appointment) {
    const durationText = Wahanda.Time.getDurationText(appointment);
    const text = Wahanda.Template.render(
      Wahanda.lang.calendar.appointments.textTemplates.durationText,
      durationText,
    );

    return text;
  },

  _addDotsIfTooLong: function _addDotsIfTooLong(text) {
    if (text == null) {
      return '';
    }

    if (text.length <= 55) {
      return text;
    }

    return `${text.substring(0, 55)}...`;
  },
});
