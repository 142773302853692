import React from 'react';
import App from 'common/backbone-app';
import TabBase from './TabBase';

export default class BlockTime extends React.Component<{}, {}> {
  block: any;

  save() {
    this.block.save();
  }

  del() {
    this.block.del();
  }

  render() {
    return (
      <TabBase
        backboneClass={App.Views.Forms.BlockTime}
        ref={(block) => {
          this.block = block;
        }}
        classes="block-time"
        {...this.props}
      />
    );
  }
}
