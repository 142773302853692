import App from 'common/backbone-app';
import {
  PROMOTIONAL_OFFERS_CHANNEL_FEATURE,
  CLIENT_APP_CAMPAIGN_CHANNEL_FEATURE,
  EMAIL_CAMPAIGN_CHANNEL_FEATURE,
} from '../../../chnlFeatures';

import {
  PROMO_OFFERS_CAMPAIGN_ID,
  APP_FOR_YOUR_CLIENTS_CAMPAIGN_ID,
  GENERIC_MARKETING_CAMPAIGN_ID,
} from '../../CampaignList';

export const isCampaignEnabled = (campaignId) => {
  switch (campaignId) {
    case GENERIC_MARKETING_CAMPAIGN_ID: {
      return App.isFeatureSupported(EMAIL_CAMPAIGN_CHANNEL_FEATURE);
    }
    case PROMO_OFFERS_CAMPAIGN_ID:
      return App.isFeatureSupported(PROMOTIONAL_OFFERS_CHANNEL_FEATURE);
    case APP_FOR_YOUR_CLIENTS_CAMPAIGN_ID:
      return (
        App.isFeatureSupported(CLIENT_APP_CAMPAIGN_CHANNEL_FEATURE) &&
        App.isVenueListedOnMarketplace()
      );
    default:
      return true;
  }
};
