import { getLocallyEnabledFeatures } from 'common/trunk-features';

let enabledFeatures = getLocallyEnabledFeatures();

Wahanda.Features = {
  setEnabledFeatures: (features) => {
    enabledFeatures = features;
  },
  isEnabled: (feature) => {
    return enabledFeatures.includes(feature);
  },
};
