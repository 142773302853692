import { assoc, curry, toClj } from 'mori';
import { appState } from 'state';

export const KEY = 'menu-group-discount-editor';

// This sets the appState to the required to render data, and ReactGlobal will render it.
export default function show({
  group,
  openingHours,
  appointmentMorningEndTime,
  appointmentAfternoonEndTime,
  leadTimeMinutes,
}) {
  appState.swap(
    curry(assoc, KEY, {
      group: toClj(group.toJSON()),
      openingHours: toClj(openingHours),
      appointmentMorningEndTime,
      appointmentAfternoonEndTime,
      leadTimeMinutes,
    }),
  );
}
