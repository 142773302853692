App.Views.Forms.MenuOffer2.Pricing.TreatmentTypeSelect = Backbone.View.extend({
  events: {
    'change .js-primaryTreatmentId': 'onSelect',
    'click .active-result': 'onClick',
  },

  required: true,

  render: function () {
    this.$el
      .empty()
      .append(
        Wahanda.Template.renderTemplate('treatment-type-select-template', this.getTemplateVars()),
      );

    var $target = this.$('.chosen-select').chosen({
      // Do not search in the Group names
      group_search: false,
    });
    // Set Chosen placeholder
    if ($target.data('chosen')) {
      // Do this only if chosen has initialized - e.g. the browser is supported.
      this.$('.chosen-search')
        .find('input')
        .attr('placeholder', Wahanda.lang.menu.placeholders.lookupServiceType);

      $target.on('chosen:showing_dropdown.tt-select', this.setChosenSize.bind(this));
      // Set the Width
      var $chosenDiv = this.$('.chosen-container');
      $chosenDiv.css('width', 'auto');
    } else {
      $target.parent().addClass('no-chosen-support');
    }

    var primaryTreatmentId = this.model.get('primaryTreatmentId');
    if (primaryTreatmentId > 0) {
      var $chosen = this.$('.js-primaryTreatmentId')
        .val(primaryTreatmentId)
        .prop('disabled', false);
      var $parent = $chosen.parent().qtip('destroy');

      if (!App.isMasquerading()) {
        $chosen.disableFormElements();

        $parent.qtip({
          content: Wahanda.Util.getEditingLockedHtml(),
          position: {
            my: 'top center',
            at: 'bottom left',
            adjust: {
              // According to the design, we should not render the tooltip cetnered.
              // It's approx this much to the right, from the left input's corner.
              x: 150,
            },
          },
        });
      }
      $chosen.trigger('chosen:updated');
    }
  },

  getPreselectedDetails: function () {
    var self = this;
    _.each(this.options.preSelected, function (treatmentId) {
      self.$('.js-primaryTreatmentId').val(treatmentId);
      self
        .$('.js-primaryTreatmentId option[value="' + treatmentId + '"]')
        .prop('selected', true)
        .change();
    });
  },

  getTemplateVars: function () {
    var tplTypes = [];
    var types = this.options.treatments.get('treatmentTypesCollection');
    var treatments = this.options.treatments.get('treatmentsCollection');
    treatments.setCompareMethod('name').sort();
    var treatmentId = this.model.get('primaryTreatmentId');

    types.each(function (tType) {
      var type = {
        typeName: tType.get('name'),
        typeId: tType.id,
        treatments: [],
      };

      var list = treatments.getByTypeId(tType.id);
      var treatment;
      for (var i = 0, len = list.length; i < len; i++) {
        treatment = list[i];
        type.treatments.push({
          id: treatment.id,
          name: treatment.get('name'),
          on: treatment.id === treatmentId,
          aliases: treatment.get('aliases') ? treatment.get('aliases').join('|') : null,
        });
      }

      tplTypes.push(type);
    });

    return {
      types: tplTypes,
      name: this.model.get('name'),
      reference: this.model.get('supplierReference'),
      hidden: !this.model.get('id'),
      prefix: this.options.prefix,
      suffix: this.options.suffix,
      required: this.required,
      disabled: this.model.isReadOnly(),
    };
  },

  setChosenSize: function () {
    var parentRect = this.$el.closest('.offer2--tab-container').get(0).getBoundingClientRect();
    var ownRect = this.$('.chosen-results').get(0).getBoundingClientRect();

    this.$('.chosen-select')
      .parent()
      .setChosenSize({
        maxDropdownHeight: parentRect.bottom - ownRect.top - 10,
      });
  },

  /**
   * Scrolls chosen select to the passed-in group title, if exists.
   */
  scrollChosenToGroup: function () {
    if (!this.model.id) {
      var list = this.$('.chosen-results');
      var theGroup = null;
      var title = this.options.groupName;
      list.find('.group-result').each(function () {
        var $group = $(this);
        if ($group.text() === title) {
          theGroup = $group;
          return false;
        }
      });

      if (theGroup) {
        var top = theGroup.position().top;
        list.scrollTop(list.scrollTop() + top);
      }
    }
  },

  getValues: function () {
    var select = this.$('select[name=primaryTreatmentId]');
    return {
      groupId: parseInt(select.find('option:checked').parent().data('id'), 10),
      treatmentId: parseInt(select.val(), 10),
      treatmentName: select.find(':selected').text(),
    };
  },

  /**
   * If anything is selected in the select, show name input box.
   *
   * @param Event evt
   */
  onSelect: function (evt) {
    this.$('.chosen-container-active').removeClass('chosen-container-active');
    // Remove any validation errors on the fly
    this.$('select').valid();
  },

  onClick: function (evt) {
    if (this.options.selectText) {
      this.$('.chosen-container .chosen-single span').html(this.options.selectText);
    }
  },
});
