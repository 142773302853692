import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Download extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-download', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <polygon
          points="12 2.6 16.707 7.307 15.293 8.721 13 6.428 13 16.6 11 16.6 11 6.428 8.707 8.721 7.293 7.307"
          transform="matrix(1 0 0 -1 0 19.2)"
          fillRule="nonzero"
        />
        <path d="M4,19 L20,19 L20,16 L22,16 L22,21 L2,21 L2,16 L4,16 L4,19 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Download);
