/**
 * Menu group dialog form view.
 */
App.Views.Forms.MenuGroup = BackboneEx.View.Dialog.ValidatingForm.extend({
  events: {
    'click .button-cancel': 'close',
    'click .delete-action': 'del',
  },
  options: {
    width: 925,
    addToBeginning: false,
    dialogClass: 'no-title',
  },

  initForm: function () {
    if (this.model && this.model.id != null) {
      this.$('input[name=name]').val(this.model.get('name'));
      this.$el.find('.dialog-actions').css('border-top', 'none');
    } else {
      this.$('.button-secondary').hide();
      this.$el.find('.dialog-actions').css('border-top', 'none');
    }

    if (!App.isFeatureSupported('yield-discount')) {
      this.$el.addClass('no-discount');
    }
  },
  getModelValues: function () {
    return BackboneEx.View.Dialog.ValidatingForm.prototype.getModelValues.call(this);
  },
  setValidation: function () {
    var self = this;
    this.$('form').validate(
      Wahanda.Validate.getValidations('MenuGroup', {
        submitHandler: function () {
          self.save();
        },
      }),
    );
  },

  getConstraintErrors: function (data) {
    var errors = {};

    _.each(data.errors, function (error) {
      if ('menu-group-name-in-use' === error.name) {
        errors['name'] = Wahanda.lang.menu.group.errors.duplicateName;
      }
    });

    return errors;
  },

  onDialogOpen: function () {
    BackboneEx.View.Dialog.ValidatingForm.prototype.onDialogOpen.call(this);

    this.$el.css('min-height', '0');

    if (
      (this.model && this.model.id == null) ||
      !this.model.checkForAppointments(this.options.offers)
    ) {
      this.$el.parents('.ui-dialog').width('700');
    }
  },

  checkVenueOpen: function (day) {
    var match = false;
    var venueOpenDays = this.options.venue.get('openingHours');

    _.each(venueOpenDays, function (openDay) {
      if (openDay.dayOfWeek == day) {
        if (openDay.openingTime != '') {
          match = true;
        }
      }
    });
    return match;
  },

  deleteErrorText: function (xhr) {
    if (Wahanda.Util.isUserErrorCode(xhr.status)) {
      var data = JSON.parse(xhr.responseText);
      if (data && data.errors && data.errors[0] && data.errors[0].name === 'menu-group-not-empty') {
        return Wahanda.lang.menu.group.cantDeleteNotEmptyTooltip;
      }
    } else if (xhr.status === 404) {
      return Wahanda.lang.menu.group.cantDeleteNotExists;
    }
  },

  getErrorTooltipBody: function () {
    return Wahanda.lang.menu.group.cantDeleteNotEmptyTooltip;
  },

  addToCollection: function (model) {
    if (this.options.addToBeginning) {
      this.collection.unshift(model);
    } else {
      this.collection.add(model);
    }
  },

  _afterSaveCallback: function (data) {
    BackboneEx.View.Dialog.ValidatingForm.prototype._afterSaveCallback.apply(this, arguments);
    if (data.isNew) {
      App.trigger(Wahanda.Event.MENU_GROUP_CREATED, {
        groupId: this.model.id,
      });
    }
  },

  _afterDeleteCallback: function () {
    BackboneEx.View.Dialog.ValidatingForm.prototype._afterDeleteCallback.apply(this, arguments);
    App.trigger(Wahanda.Event.MENU_GROUP_DELETED, {
      groupId: this.model.id,
    });
  },
});
