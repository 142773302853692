/* global BackboneEx */
/* eslint func-names: 0 */

import inlineClient from 'components/calendar/InlineClient/initializer';
import apiUrl from 'common/api-url';
import BigNumber from 'bignumber.js';
import _ from 'common/underscore';
import { xhr } from 'common/xhr';
import PaymentType from 'components/reports/transactions/constants';
/**
 * POS checkout dialog base
 *
 * The base dialog includes Products and Prepayments.
 * The POS Lite and POS dialogs both extend this with different functionality &
 * templates.
 */
(function () {
  if (!App.Views.Forms.POS) {
    App.Views.Forms.POS = {};
  }
  const BaseView = BackboneEx.View.Dialog2;
  App.Views.Forms.POS.POSDialogBase = BaseView.extend({
    events: {
      'click .js-close': 'close',
      'click .js-complete-checkout': 'doCompleteCheckout',
    },

    templateId: '',

    dialogTitle: '',

    mediaQueryOptions: {
      desktop: {
        width: 750,
      },
      wide: {
        width: 750,
      },
    },

    setMaxScrollHeight() {
      this.$('.content-scroll').css('max-height', '100%');
    },

    initialize(...args) {
      BaseView.prototype.initialize.apply(this, args);
      const self = this;
      this.checkoutCalc = new App.Views.POS.POSCheckoutPriceCalculator({
        controller: this,
      });
      this.products = this.checkoutCalc.getProducts();
      Wahanda.Cache.productList().done((collection) => {
        self.existingProducts = collection;
      });
      this.venueCustomerId = this.options.venueCustomerId ? this.options.venueCustomerId : null;
      this.collection = this.collection ? this.collection : {};
      const models = _.filter(
        this.collection.models,
        (model) => !(model.isCancelled() || model.isCheckedOut() || model.isNoShow()),
      );

      this.appointments = [];
      _.each(models, (model, index) => {
        let services;
        if (typeof model.getLinkedPackageGroup !== 'undefined' && model.getLinkedPackageGroup()) {
          services = new App.Models.POS.PackageServices({
            collection: model.getLinkedPackageGroup(),
          });
        } else {
          services = new App.Models.POS.Services({
            model,
          });
        }
        const normalised = services.normalise(index);
        if (normalised.appointmentGroupId) {
          const existingPackage = _.findWhere(self.appointments, {
            appointmentGroupId: normalised.appointmentGroupId,
          });
          if (!existingPackage) {
            self.appointments.push(normalised);
          }
        } else {
          self.appointments.push(normalised);
        }
        self.venueCustomerId = model.get('venueCustomerId');
      });
    },

    applyFocusStyles($el, forceFlag) {
      const $focusedEl = $el.parents('.txt-input');
      if (forceFlag === undefined) {
        if ($el.is(':focus')) {
          $focusedEl.addClass('pos-focused');
        } else {
          $focusedEl.removeClass('pos-focused');
        }
      } else {
        $focusedEl.toggleClass('pos-focused', forceFlag);
      }
    },

    getRedeemedVoucherLineItems() {
      if (this.payments == null) {
        return [];
      }

      return this.payments
        .filter((payment) => payment.get('paymentType') === PaymentType.PAYMENT_TYPE_VOUCHER)
        .filter(
          (payment) =>
            payment.get('vatVoucherType') ===
            App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.SINGLE_VAT,
        )
        .map((payment) => ({
          amount: payment.get('amount'),
          voucherCode: payment.get('voucherCode'),
          voucherIssuer: payment.get('voucherIssuer'),
        }));
    },

    getVoucherLineItems() {
      return this.products
        .where({ type: App.Models.POS.ProductDescriminators.VOUCHER })
        .map((voucher) => ({
          description: voucher.get('description'),
          employeeId: voucher.get('employeeId'),
          quantity: voucher.get('quantity'),
          unitPrice: voucher.getUnitPriceDiscounted(),
          discount: voucher.getDiscount(),
        }));
    },

    getProductLineItems() {
      // First add the "physical products" then the vouchers
      const productLineItems = [];
      this.products.each((product) => {
        if (product.get('type') === App.Models.POS.ProductDescriminators.PRODUCT) {
          productLineItems.push({
            description: product.get('description'),
            employeeId: product.get('employeeId'),
            quantity: product.get('quantity'),
            unitPrice: product.getUnitPriceDiscounted(),
            venueProductId: product.get('id'),
            discount: product.getDiscount(),
          });
        }
      });
      return productLineItems;
    },

    getServiceLineItems() {
      const lineItems = [];
      const self = this;
      _.each(this.appointments, (appointment) => {
        if (appointment.selected) {
          lineItems.push({
            name: appointment.title,
            amount: getAmount(),
            discount: getDiscount(),
            originalAmount: appointment.originalAmount || 0,
            prepaid: !appointment.unpaid ? appointment.originalAmount || 0 : 0,
            serviceLineItemConstituents: self.getServiceLineItemConstituents(appointment),
          });
        }

        function getAmount() {
          return App.Views.POS.POSCheckoutPriceCalculator.prototype.calculatePriceAfterDiscount({
            price: appointment.amount,
            discountPercentage: appointment.discountPercentage,
          });
        }

        function getDiscount() {
          if (!appointment.discountPercentage) {
            return undefined;
          }
          return {
            amount: new BigNumber(appointment.amount).minus(getAmount()).toFixed(2),
            percentage: appointment.discountPercentage,
          };
        }
      });

      return lineItems;
    },

    getServiceLineItemConstituents(appointment) {
      const serviceLineConstituents = [];
      _.each(appointment.skus, (sku) => {
        const serviceLineConstituent = {
          serviceSkuId: sku.skuId,
          employeeName: appointment.employeeName || sku.employeeName,
          appointmentDate: appointment.appointmentDate,
          appointmentId: appointment.appointmentId || sku.appointmentId,
          name: sku.name || sku.skuName || appointment.offerName,
        };
        serviceLineConstituents.push(serviceLineConstituent);
      });
      return serviceLineConstituents;
    },

    disablePOSSubmit() {
      this.$('.js-complete-checkout').addClass('dialog2--button-disabled').prop('disabled', true);
    },

    enablePOSSubmit() {
      if (this.enableCompletePaymentButton) {
        this.$('.js-complete-checkout')
          .removeClass('dialog2--button-disabled')
          .prop('disabled', false);
      }
    },

    isCustomerRendered() {
      return this.collection.length > 0 && this.collection.canShowCustomerContacts();
    },

    renderCustomer() {
      if (!this.isCustomerRendered()) {
        return;
      }
      this.$el.closest('.ui-dialog').addClass('with-client');
      // "Existing props" means rendering using everything which was passed
      // from Appointment2 (our parent form in this case).
      inlineClient.renderUsingExistingProps(this.$('.js-client').get(0));
    },

    render(...args) {
      BaseView.prototype.render.apply(this, args);
      this.$dialogContent.empty().prepend(Wahanda.Template.renderTemplate(this.templateId, {}));
      this.renderCustomer();
      this.renderSummary('services', 'pos-checkout-services', {
        editPricePermitted: Wahanda.Permissions.editPOSServicePrice(),
      });
      this.renderServicesView();
      this.renderSummary('products', 'pos-product-summary');
      this.renderAddProductViews();
      this.renderSummary('payments', 'pos-summary-payments', this.getPaymentTitle());
      this.renderPrepayments();

      this.renderBalances();
      this.setMaxScrollHeight();
    },

    renderSummary(summaryType, templateId, additionalTemplateData) {
      const $container = this.$(`.js-summary-${summaryType}`);
      const summaryView = new App.Views.POS.ViewSummary({
        el: $container,
        model: this.appointments,
        controller: this,
        templateId,
        additionalTemplateData,
      });
      summaryView.render();
    },

    renderServicesView() {
      const self = this;
      $('.js-service-row').each(function () {
        const id = $(this).data('id');
        const model = _.find(self.appointments, (appointment) => id === appointment.id);
        const view = new App.Views.POS.Services({
          controller: self,
          model,
          el: $(this),
        });
        view.render();
      });
    },

    renderAddProductViews() {
      this.productView = new App.Views.POS.AddProduct({
        controller: this,
      });
      this.$('.pos-checkout-section--item--products').append(this.productView.render().$el);
    },

    renderPrepayments() {
      // Remove any existing prepayments...
      this.$('.js-prepayment').remove();
      if (this.checkoutCalc.getPrepaidTotal() > 0) {
        const payment = new App.Models.POS.PaymentLineItem({
          amount: this.checkoutCalc.getPrepaidTotal(),
          paymentType: 'prepaid',
        });
        this.renderPaymentView(payment);
      } else {
        this.togglePaymentTitle();
      }
    },

    renderPaymentView(payment) {
      const view = new App.Views.POS.ViewPayment({
        model: payment,
        controller: this,
      });
      this.$('.js-added-payments').append(view.render().$el);
      this.togglePaymentTitle();
    },

    togglePaymentTitle() {
      const showTitle =
        (this.payments && this.payments.length > 0) || this.checkoutCalc.getPrepaidTotal() > 0;
      this.$('.js-payment-title').toggle(showTitle);
    },

    createBalanceView($el, templateId) {
      const collections = this.getCollections();

      const view = new App.Views.POS.RunningBalance({
        el: $el,
        controller: this,
        collections,
        templateId,
      });
      return view;
    },

    saveItem(item) {
      if (!this.products.contains(item)) {
        const view = new App.Views.POS.ViewItem({
          model: item,
          controller: this,
        });
        let $itemContainer;
        if (item.get('type') === App.Models.POS.ProductDescriminators.VOUCHER) {
          $itemContainer = this.$('.js-added-vouchers');
        } else {
          $itemContainer = this.$('.js-added-products');
        }
        this.products.add(item);
        $itemContainer.append(view.render().$el);
      } else {
        this.removePayments();
      }
      this.trigger('product-form:reset');
    },

    /**
     * Persists all information that was captured on the POS form.
     *
     * @param Object checkoutModel representation of the checkout.
     * @param Object options additional options, used to supply optional callbacks [done / fail]
     */
    checkoutPOS(checkoutModel, options) {
      const url = apiUrl('CHECKOUT');
      const id = this.id;
      const self = this;

      xhr
        .doJQueryAjax({
          url,
          type: 'post',
          data: JSON.stringify(checkoutModel),
          contentType: 'application/json',
        })
        .done((data, status, response) => {
          const checkoutInfo = JSON.parse(response.responseText);
          const receiptPdfUri = apiUrl('POS_TRANSACTION_RECEIPT', {
            receiptId: checkoutInfo.receiptUri,
          });
          const tipReceiptPdfUri = checkoutInfo.tipWithdrawal
            ? apiUrl('POS_TRANSACTION_RECEIPT', {
                receiptId: checkoutInfo.tipWithdrawal.receiptUri,
              })
            : null;

          // Postpone the Checkout success notification as we first want to handle success
          // callbacks in the Checkout form, only then externally.
          window.setTimeout(() => {
            const checkedoutAppointments = checkoutModel.checkout.serviceLineItems
              .reduce(
                (accumulator, lineItem) => [
                  ...accumulator,
                  ...lineItem.serviceLineItemConstituents,
                ],
                [],
              )
              .map((serviceLineItem) => serviceLineItem.appointmentId);

            if (self.options.mediator) {
              self.options.mediator.trigger(self.options.mediator.APPOINTMENT_CHECKED_OUT, {
                id,
                checkedoutAppointmentIds: checkedoutAppointments,
              });
            }

            App.trigger(Wahanda.Event.APPOINTMENT_CHECKED_OUT, {
              id,
              checkedoutAppointmentIds: checkedoutAppointments,
            });
          }, 0);

          options.success({
            receiptPdfUri,
            receiptCopyUri: checkoutInfo.receiptCopyUri,
            tipReceiptPdfUri,
          });
        })
        // Optional callbacks
        .fail(options.error);
    },
    getCheckoutModel() {
      // NB: checkout.employeeId is misleading, we should be supplying
      // the employee that performed the checkout
      // and not the one on the appointment. The service rectifies this,
      // due to constraints on the model we have
      // to supply something. We need to sort the validation out so that we can let the service work this out.
      return {
        checkout: {
          employeeId: this.getEmployeeId(),
          productLineItems: this.getProductLineItems(),
          voucherLineItems: this.getVoucherLineItems(),
          redeemedVoucherLineItems: this.getRedeemedVoucherLineItems(),
          discountLineItems: this.discounts ? this.discounts.toJSON() : [],
          payments: this.payments ? this.payments.getServicePayments() : [],
          serviceLineItems: this.getServiceLineItems(),
          venueCustomerId: this.venueCustomerId,
          operationId: this.operationId,
        },
      };
    },

    doError() {
      this.checkoutError = new App.Views.Forms.CheckoutError({});
      this.checkoutError.render();
      this.checkoutError.open();
    },

    onSavingFailed(showError = true) {
      this.enableCompletePaymentButton = true;
      if (showError) {
        this.doError();
      }
      this.unloadmask();
    },

    /**
     * Save the Inline Client, with the Appointments associated with it.
     * @returns {Promise<boolean>} If the save wasn't cancelled.
     * @throws If saving or fetching failed
     */
    async saveCustomerIfNeeded() {
      if (!this.isCustomerRendered()) {
        return true;
      }

      // We need to submit to trigger some validation functions
      inlineClient.submitWithExistingProps();

      const checkoutButton = this.$('.js-complete-checkout').get(0);
      const { cancelled } = await this.options.saveAppointments(checkoutButton);

      if (cancelled) {
        return false;
      }

      // We also need to update the venueCustomerId here.
      // Going extra lengths to fetch the just-saved appointment as our backend does not
      // support returning response body with PUTs.
      const first = this.collection.getFirstAppointmentByBookingActorImportance();
      await first.fetchWithPromise();

      this.venueCustomerId = first.get('venueCustomerId');

      return true;
    },

    destruct() {
      inlineClient.destroyRenderedWithExistingProps(this.$('.js-client').get(0));
    },
  });
})();
