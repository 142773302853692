import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { CheckoutButton, CheckoutButtonProps } from './';

interface Params extends CheckoutButtonProps {
  node: HTMLElement;
}

export const CheckoutButtonInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(params.node),
  render: () => {
    ReactDOM.render(
      <CheckoutButton onCheckoutButtonClick={params.onCheckoutButtonClick} />,
      params.node,
    );
  },
});
