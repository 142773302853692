import React from 'react';

interface Props {
  className?: string;
}

class Warning extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-warning', this.props.className].join(' ')}
        viewBox="0 0 48 48"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#001E62"
            d="M38.6 4.95A23.896 23.896 0 0 0 24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24c0-2.323-.33-4.604-.974-6.79l-1.919.564A22.01 22.01 0 0 1 46 24c0 12.15-9.85 22-22 22S2 36.15 2 24 11.85 2 24 2c4.909 0 9.57 1.611 13.382 4.537L38.6 4.95z"
            fillRule="nonzero"
          />
          <path
            fill="#FF808B"
            stroke="#001E62"
            d="M24 33a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0-24a3 3 0 0 1 3 3v14a3 3 0 0 1-6 0V12a3 3 0 0 1 3-3z"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  }
}

export const icon = Warning;
