import { connect } from 'react-redux';
import { Integration as Component } from './Integration';

const mapStateToProps = (state) => {
  const { integrationAssets } = state.marketing.fbeIntegration.installStatus;

  let hasWarnings = false;
  if (integrationAssets && !integrationAssets.instagramBusinessId) {
    hasWarnings = true;
  }

  return {
    hasWarnings,
  };
};

export const Integration = connect(mapStateToProps)(Component);
