import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isVenuePending, isVenueTemporarilyDisabled } from 'common/extranet-helpers';

import * as actions from './actions';
import { Dashboard as Component } from './index';

const mapDispatchToProps = (dispatch) =>
  Object.assign(
    {},
    {
      actions: bindActionCreators(actions, dispatch),
    },
  );

const mapStateToProps = ({ dashboard }) => {
  const goalsAllowed =
    dashboard.marketplaceSupported &&
    !isVenuePending(dashboard) &&
    !isVenueTemporarilyDisabled(dashboard);

  const venueImage = dashboard.logoImage || dashboard.firstVenueImage;

  const isPending = isVenuePending(dashboard) || isVenueTemporarilyDisabled(dashboard);

  return Object.assign(
    {},
    {
      isMasquerading: dashboard.isMasquerading,
      name: dashboard.name,
      venueId: dashboard.venueId,
      supplierId: dashboard.supplierId,
      venue: dashboard.venue,
      venueStatistics: dashboard.venueStatistics,
      goalsAllowed,
      venueImage,
      rating: dashboard.rating,
      bookingWidgetSupported: dashboard.bookingWidgetSupported,
      marketplaceLink: dashboard.marketplaceLink,
      widgetLink: dashboard.widgetLink,
      canRedeemEvouchers: dashboard.canRedeemEvouchers,
      canAccessSettings: dashboard.canAccessSettings,
      canViewFinanceData: dashboard.canViewFinanceData,
      isPending,
      venueDetails: dashboard.venueDetails,
      supplierDetails: dashboard.supplierDetails,
      imageEditingEnabled: dashboard.imageEditingEnabled,
      customerReviews: dashboard.customerReviews,
      reviewResponseSaving: dashboard.reviewResponseSaving,
      reviewResponseErrors: dashboard.reviewResponseErrors,
      isFetchingCustomerReviews: dashboard.isFetchingCustomerReviews,
    },
  );
};

export const Dashboard = connect(mapStateToProps, mapDispatchToProps)(Component);
