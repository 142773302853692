import { Initializer } from 'common/types/initializers';
import { unmountComponentAtNode, render } from 'react-dom';
import React from 'react';
import { UDVCovidFormLink } from './';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';

const store = storeBuilder();

export const UDVCovidFormLinkInitializer = (
  customerId: string,
  appointmentDate: string,
  node: Element,
): Initializer => {
  return {
    destroy: () => unmountComponentAtNode(node),
    render: () =>
      render(
        <Provider store={store}>
          <UDVCovidFormLink customerId={customerId} appointmentDate={appointmentDate} />
        </Provider>,
        node,
      ),
  };
};
