(function () {
  var DENSITY = getDeviceDensity();

  /**
   * Screen density related functions.
   */
  Wahanda.ScreenDensity = {
    /**
     * Get the screen density
     *
     * @returns Int
     */
    get: function () {
      return DENSITY;
    },

    /**
     * Return correct dimensions for any device pixel ratio device.
     *
     * @param Int width
     * @param Int height
     * @returns {width: number, height: number}
     */
    convertDimensions: function (width, height) {
      return {
        width: width * DENSITY,
        height: height * DENSITY,
      };
    },

    getImageFromStructure: function (images, width, height) {
      var dimensions = Wahanda.ScreenDensity.convertDimensions(width, height);

      return images[dimensions.width + 'x' + dimensions.height];
    },
  };

  function getDeviceDensity() {
    return Math.min(Math.ceil(window.devicePixelRatio || 1), 2);
  }
})();
