import React from 'react';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import { ReviewsAnalytics } from '../tracking';
import style from './ReviewEmptyState.scss';

const lang = Wahanda.lang.dashboard.reviews.noReviews;

export interface Props {
  shouldRenderActions: boolean;
}

export const ReviewEmptyState: React.SFC<Props> = ({ shouldRenderActions }) => {
  const onButtonClick = () => {
    ReviewsAnalytics.trackEmptyStateRequestForReviewClicked();
    window.open(lang.helpLink, lang.button);
  };
  return (
    <div className={style.reviewEmptyState}>
      <div className={style.stars} />
      <div className={style.header}>{lang.header}</div>
      {shouldRenderActions && (
        <>
          <div className={style.body}>{lang.body}</div>
          <Button
            variant="secondary"
            onClick={onButtonClick}
            label={lang.button}
            dataTest="get-more-reviews-button"
          />
        </>
      )}
    </div>
  );
};
