import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'reduxStore/common/employees/actions';
import PublicProfileTab from './index';

const mapStateToProps = ({
  employees: {
    employee: { jobTitle, notes },
  },
}) => ({
  jobTitle,
  notes,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicProfileTab);
