import React from 'react';
import { Steps } from './steps';
import { OnboardingModal } from './OnboardingModal';

export const ClientFormsOnboardingModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <OnboardingModal dataTestId="client-forms-onboarding-modal" onClose={onClose}>
      {Steps}
    </OnboardingModal>
  );
};
