import React, { Component, Fragment } from 'react';
import ReactDialog from 'components/common/react-dialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import Wahanda from 'common/wahanda';
import { trackEvent } from 'common/analytics';
import UnderlinedInput from 'components/common/UnderlinedInput/withFormsy';
import DialogContentBox from 'components/common/dialog/DialogContentBox';
import { Notice } from 'components/common/Notice';
import { Button } from 'components/common/Button';
import { webcalUrl } from 'common/validators';
import Formsy from 'formsy-react';
import { DialogConfirmation } from 'components/common/dialog/DialogConfirmation';
import employeeExternalCalendarSyncErrors from './validators';
import style from './style.scss';

const lang = Wahanda.lang.settings.employees.employee.linkCalendar;
interface IExternalCalendarTabProps extends React.HTMLAttributes<Element> {
  externalCalendarUri?: string;
  linkedExternalSalonName?: string;
  serverErrorName?: string;
  loading?: boolean;
  calLinked?: boolean;
  actions: {
    setEmployeeDataAction: (...args: any[]) => any;
    linkExternalCalendarAction: (...args: any[]) => any;
    unlinkExternalCalendarAction: (...args: any[]) => any;
  };
  unlinkExternalCalendarAction?: any;
  linkExternalCalendarAction?: any;
  setEmployeeDataAction?: any;
}
type ExternalCalendarTabState = {
  isFormValid?: boolean;
  unlinkConfirmationDialogVisible?: boolean;
  linkSuccessDialogVisible?: boolean;
};

export default class ExternalCalendarTab extends Component<
  IExternalCalendarTabProps,
  ExternalCalendarTabState
> {
  static defaultProps = {
    externalCalendarUri: '',
    linkedExternalSalonName: null,
    serverErrorName: null,
    loading: false,
    calLinked: false,
  };

  state = {
    isFormValid: false,
    unlinkConfirmationDialogVisible: false,
    linkSuccessDialogVisible: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.calLinked && this.props.calLinked) {
      this.openLinkSuccessDialog();
    }
  }

  onChange = (event) => {
    this.props.actions.setEmployeeDataAction({
      [event.target.name]: event.target.value,
    });
  };

  onValid = () => {
    if (!this.state.isFormValid) {
      this.setState({
        isFormValid: true,
      });
    }
  };

  onInvalid = () => {
    if (this.state.isFormValid) {
      this.setState({
        isFormValid: false,
      });
    }
  };

  onSubmit = () => {
    const { calLinked } = this.props;
    if (calLinked) {
      this.setState({ unlinkConfirmationDialogVisible: true });
    } else {
      trackEvent('team-member-external-calendar', 'submit', 'link-calendar');
      this.props.actions.linkExternalCalendarAction();
    }
  };

  onUnlinkConfirmDialogClose = () => {
    this.setState({ unlinkConfirmationDialogVisible: false });
  };

  unlinkExternalCalendar = () => {
    this.props.actions.unlinkExternalCalendarAction();
    this.setState({ unlinkConfirmationDialogVisible: false });
  };

  openLinkSuccessDialog = () => {
    this.setState({ linkSuccessDialogVisible: true });
  };

  closeLinkSuccessDialog = () => {
    this.setState({ linkSuccessDialogVisible: false });
  };

  renderUnlinkConfirmDialog = () => {
    const unlinkLang = Wahanda.lang.settings.employees.employee.unlinkCalendar;
    return (
      <DialogConfirmation
        dataTest="external-calendar-unlink-confirmation-modal"
        title={unlinkLang.title}
        text={unlinkLang.text}
        buttonTitle={unlinkLang.buttons.unlink}
        buttonColor="red"
        onButtonClick={this.unlinkExternalCalendar}
        onClose={this.onUnlinkConfirmDialogClose}
      />
    );
  };

  renderLinkSuccessDialog = () => (
    <ReactDialog
      dataTest="external-calendar-link-success-modal"
      title={lang.title}
      onClose={this.closeLinkSuccessDialog}
      noContentPadding
    >
      <div className={style.linkSuccessDialogContent}>
        <p className={style.header} dangerouslySetInnerHTML={{ __html: lang.success.title }} />
        <p className={style.text}>{lang.success.text}</p>
      </div>
      <DialogFooter>
        <DialogFooterButton
          title={Wahanda.lang.shared.buttons.cancel}
          type="secondary"
          onClick={this.closeLinkSuccessDialog}
        />
        <DialogFooterButton
          title={Wahanda.lang.shared.buttons.done}
          onClick={this.closeLinkSuccessDialog}
        />
      </DialogFooter>
    </ReactDialog>
  );

  renderExternalSystemIntegrationNotice = () => {
    const { linkedExternalSalonName } = this.props;
    return (
      <div className={style.attentionContainer}>
        <Notice
          message={Wahanda.Template.render(lang.intro.externalSystemIntegrationAttention, {
            externalSystemName: linkedExternalSalonName,
          })}
        />
      </div>
    );
  };

  renderLinkInputRow = () => {
    const { externalCalendarUri, serverErrorName, loading, calLinked } = this.props;
    const buttonText = calLinked
      ? Wahanda.lang.settings.employees.employee.unlinkCalendar.buttons.unlink
      : Wahanda.lang.settings.employees.employee.linkCalendar.buttons.link;
    const validations = {
      [webcalUrl.KEY]: webcalUrl.VALIDATOR,
    };
    if (!loading && serverErrorName && employeeExternalCalendarSyncErrors[serverErrorName]) {
      validations[serverErrorName] = employeeExternalCalendarSyncErrors[serverErrorName];
    }
    const buttonDisabled =
      !calLinked &&
      (loading ||
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        externalCalendarUri.length === 0 ||
        webcalUrl.VALIDATOR(null, externalCalendarUri) !== true);
    return (
      <Fragment>
        <div className={style.inputGroupRow}>
          <div className={style.inputGroupRowItem}>
            <UnderlinedInput
              label={lang.labels.calendarAddress}
              name="externalCalendarUri"
              value={externalCalendarUri}
              placeholder={lang.labels.paste}
              onChange={this.onChange}
              validations={validations}
              validationErrors={{
                isDefaultRequiredValue: Wahanda.lang.validate.defaults.required,
              }}
              autoFocus
              disabled={calLinked}
            />
          </div>
          <div className={style.inputGroupRowItem}>
            <Button
              label={buttonText}
              variant="primary"
              colour="plain"
              onClick={this.onSubmit}
              disabled={buttonDisabled}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    const { linkedExternalSalonName, calLinked } = this.props;
    const linked = linkedExternalSalonName || calLinked;
    return (
      <Formsy onValid={this.onValid} onInvalid={this.onInvalid}>
        <DialogContentBox separated>
          <h3 className={style.header}>{lang.intro.title}</h3>
          <p className={style.text}>{lang.intro.info}</p>
          <p className={style.text}>
            <span className={style.note}>{lang.intro.note}</span> {lang.intro.noteText}
          </p>
          <div className={linked ? style.linked : style.notLinked}>
            {linkedExternalSalonName != null
              ? this.renderExternalSystemIntegrationNotice()
              : this.renderLinkInputRow()}
          </div>
        </DialogContentBox>
        {this.state.unlinkConfirmationDialogVisible && this.renderUnlinkConfirmDialog()}
        {this.state.linkSuccessDialogVisible && this.renderLinkSuccessDialog()}
      </Formsy>
    );
  }
}
