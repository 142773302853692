import { call, put, select } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import {
  requestProductOrdersAction,
  receiveProductOrdersAction,
  requestProductOrdersFailureAction,
  receiveProductOrderAction,
  requestProductOrderFailureAction,
  successCreateOrderAction,
  requestCreateOrderFailureAction,
  successCompleteOrderAction,
  requestCompleteOrderFailureAction,
  successCancelProductOrderAction,
  requestCancelProductOrderFailureAction,
} from './actions';
import { getSupplier } from './selectors';

export function* getProductOrdersRequested() {
  try {
    const response = yield call(api.get, apiUrl('GET_PRODUCT_ORDERS'));
    yield put(receiveProductOrdersAction(response.productOrders));
  } catch (err) {
    yield put(requestProductOrdersFailureAction(err));
  }
}

export function* productOrderRequested(data) {
  try {
    const params = { productOrderId: data.productOrderId };
    const response = yield call(api.get, apiUrl('PRODUCT_ORDER', params));
    yield put(receiveProductOrderAction(response.productOrder));
  } catch (ex) {
    yield put(requestProductOrderFailureAction(ex));
  }
}

export function* createNewOrderRequested(params) {
  try {
    const supplier = yield select(getSupplier);

    const payload = {
      productOrderItems: params.data,
      productSupplierId: supplier ? supplier.id : null,
    };
    const response = yield call(api.post, apiUrl('CREATE_PRODUCT_ORDER'), payload);

    yield put(successCreateOrderAction(response.id));
    // Update productOrders list...
    yield put(requestProductOrdersAction());
  } catch (ex) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    yield put(requestCreateOrderFailureAction(ex));
  }
}

export function* completeOrderRequested(params) {
  try {
    const payload = { productOrderItems: params.data.productOrderItems };
    yield call(
      api.post,
      apiUrl('COMPLETE_PRODUCT_ORDER', {
        productOrderId: params.data.productOrderId,
      }),
      payload,
    );
    yield put(successCompleteOrderAction());
    // Update productOrders list...
    yield put(requestProductOrdersAction());
  } catch (ex) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    yield put(requestCompleteOrderFailureAction(ex));
  }
}

export function* cancelOrderRequested(data) {
  try {
    const params = { productOrderId: data.productOrderId };
    yield call(api.post, apiUrl('CANCEL_PRODUCT_ORDER', params));
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    yield put(successCancelProductOrderAction(data.productOrderId));
    // Update productOrders list...
    yield put(requestProductOrdersAction());
  } catch (ex) {
    yield put(requestCancelProductOrderFailureAction(ex));
  }
}
