import React from 'react';
import classnames from 'classnames';
import style from './style.scss';

function getBgImageStyle(imageUrl) {
  return imageUrl ? { backgroundImage: `url(${imageUrl})` } : null;
}

function getContentForNoImage(text) {
  return <div className={style.noImageText}>{text}</div>;
}

type VenueInfoImageProps = {
  imageUrl?: string;
  noImageText?: string;
  redirectToImageEditing?: (...args: any[]) => any;
};
const VenueInfoImage: React.SFC<VenueInfoImageProps> = (props) => {
  const { imageUrl, noImageText, redirectToImageEditing } = props;
  const hasImage = !!imageUrl;
  const classes = classnames(style.image, {
    [style.noImage]: !hasImage && noImageText,
    [style.noImageNoPermission]: !hasImage && !noImageText,
  });
  const inlineStyles = getBgImageStyle(imageUrl);
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'CSSProperti... Remove this comment to see the full error message
    <div className={classes} onClick={redirectToImageEditing} style={inlineStyles}>
      {!hasImage && noImageText && getContentForNoImage(noImageText)}
    </div>
  );
};

export default VenueInfoImage;
