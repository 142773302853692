const PATHS = {
  /* Email Campaign paths */
  CAMPAIGN_SELECTION: '/email-campaign',

  CAMPAIGN_PREVIEW_DEFAULT: '/email-campaign/:templateId',
  CAMPAIGN_PREVIEW_SMART_DISCOUNTS:
    '/email-campaign/:templateId(smart-discounts)/:selectedDiscountRuleId(\\d+)',
  CAMPAIGN_PREVIEW_SERVICE_ON_SALE:
    '/email-campaign/:templateId(service-on-sale)/:selectedTreatmentGroupId(\\d+)',

  CAMPAIGN_SUMMARY_DEFAULT: '/email-campaign/:templateId/summary',
  CAMPAIGN_SUMMARY_SMART_DISCOUNTS:
    '/email-campaign/:templateId(smart-discounts)/summary/:selectedDiscountRuleId(\\d+)',
  CAMPAIGN_SUMMARY_SERVICE_ON_SALE:
    '/email-campaign/:templateId(service-on-sale)/summary/:selectedTreatmentGroupId(\\d+)',

  CAMPAIGN_SUCCESS: '/email-campaign/:templateId/success',

  /* Client App paths */
  CLIENT_APP_LANDING: '/app',

  /* Partner Site paths */
  PARTNER_SITE_SETTINGS: '/partner-site',

  /* FBE paths */
  FBE: '/facebook',

  /* Automated messaging */
  AUTOMATED_MESSAGING: '/automated-messaging',

  /* Automated messaging */
  CLIENT_FORMS: '/client-forms',

  CLIENT_FORMS_CREATE: '/client-forms/create',
};

export default PATHS;
