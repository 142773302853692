import { evaluate } from 'mathjs/lib/esm/number';

export class CurrencyFormatter {
  private thousandsSeparator: string;

  private decimalPoint: string;

  constructor(thousandsSeparator: string, decimalPoint: string) {
    this.thousandsSeparator = thousandsSeparator;
    this.decimalPoint = decimalPoint;
  }

  // Taken from Wahanda.Number. Could be refactored
  public formatNumber(
    value: number,
    options: {
      forceDecimalPoint?: boolean;
      decimalPoint?: string;
      noRounding?: boolean;
      asNumber?: boolean;
      thousandsSeparator?: string;
    } = {},
  ) {
    let decPoint;
    let num: any = value;

    if (options.asNumber || !options.decimalPoint) {
      // We need to return a Number. Can't have non-dot dec. point here.
      decPoint = '.';
    } else {
      decPoint = options.decimalPoint;
    }

    if (options.noRounding !== true) {
      num = Math.round(num * 100) / 100;
    }
    if (options.forceDecimalPoint) {
      num = String((parseFloat(num) || 0.0).toFixed(2));
    } else {
      num = String((parseFloat(num) || 0) + 0);
    }
    if (decPoint !== '.') {
      num = num.split('.', 2).join(decPoint);
    }

    if (!options.asNumber && options.thousandsSeparator) {
      const parts = num.split(decPoint, 2);
      let first = parts[0];

      const rxp = /(-?[0-9]+)([0-9]{3})/;
      while (rxp.test(first)) {
        first = first.replace(rxp, `$1${options.thousandsSeparator}$2`);
      }
      num = first + (parts[1] != null ? decPoint + parts[1] : '');
    }

    if (options.asNumber) {
      num = parseFloat(num);
    }

    return num;
  }

  // Taken from Wahanda.Number and renamed from formatPOSInputintoFloat.
  public parse(value: string, converted?: boolean) {
    let num = value;
    if (!num) {
      return '';
    }

    if (num === '0') {
      return '0';
    }

    let isInvalid = false;
    const regexThousands = new RegExp(
      `^([1-9](?:[0-9]{1,2})?(\\${this.thousandsSeparator}[0-9]{3})*)(\\${this.decimalPoint}[0-9]{2})?$`,
    );
    const regex = new RegExp(`^[0-9]+(\\${this.decimalPoint}[0-9]{1,2})?$`);
    if (String(num).includes(this.thousandsSeparator)) {
      if (!regexThousands.test(num)) {
        isInvalid = true;
      }
    } else if (!regex.test(num)) {
      isInvalid = true;
    }

    if (isInvalid) {
      if (converted) {
        return false;
      }
      // Replace decimal place and try to validate that.
      let alternativeDecimal;
      if (this.decimalPoint === '.') {
        alternativeDecimal = ',';
      } else {
        alternativeDecimal = '.';
      }

      const updatedNum = String(num).replace(alternativeDecimal, this.decimalPoint);
      return this.parse(updatedNum, true);
    }

    num = String(num).split(this.thousandsSeparator).join('');
    const decimalSplit = String(num).split(this.decimalPoint);
    num = decimalSplit.join('.');
    if (
      isNaN(num as any) ||
      decimalSplit.length > 2 ||
      (decimalSplit[1] && decimalSplit[1].length !== 2)
    ) {
      return num;
    }
    num = String((parseFloat(num) || 0.0).toFixed(2));
    return parseFloat(num);
  }

  // Taken from Wahanda.Number. Should be the only instance eventually
  public formatExpression(expression) {
    const matches = expression.match(/([^-+*/]*)/g);

    if (!matches) {
      return this.parse(expression);
    }

    const expressionWithoutTrailingSymbol = expression.replace(/[-+*/\s]+$/, '');

    return matches
      .map((x) => x.trim())
      .filter(Boolean)
      .map((match) => ({
        match,
        replacement: this.parse(match),
      }))
      .reduce((acc, { match, replacement }) => {
        return acc !== false && replacement ? acc.replace(match, replacement) : false;
      }, expressionWithoutTrailingSymbol);
  }

  public parseExpression(expression: string) {
    try {
      const evaluated = evaluate(this.formatExpression(expression));
      return this.floatToDecimal(evaluated);
    } catch (e) {
      return NaN;
    }
  }

  public floatToDecimal(x) {
    const inner = Math.round(Number(`${x}e2`));
    return Number(`${inner}e-2`);
  }
}
