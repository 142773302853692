/* global Backbone */

App.Routers.Marketing = Backbone.Router.extend({
  routes: {
    'venue/:venueId/:tab': 'default',
    'venue/:venueId/:tab/*params': 'default',
  },

  default: function (venueId, tab) {
    App.mainViewOptions = {
      initialTab: tab,
    };
    App.initVenue(venueId);
  },
});
