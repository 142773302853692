import React, { Component } from 'react';
import classnames from 'classnames';
import style from './style.scss';
import { TYPE_NEW, TYPE_RESCHEDULED, TYPE_CANCELLED } from '../constants';

interface IAppointmentNotificationIconProps extends React.HTMLAttributes<Element> {
  type: any;
}

export default class AppointmentNotificationIcon extends Component<
  IAppointmentNotificationIconProps,
  {}
> {
  render() {
    const { type } = this.props;
    const iconTypeClassMap = new Map([
      [TYPE_NEW, style.new],
      [TYPE_RESCHEDULED, style.rescheduled],
      [TYPE_CANCELLED, style.cancelled],
    ]);
    const iconTypeClass = iconTypeClassMap.get(type);
    return <div className={classnames(style.icon, iconTypeClass)} />;
  }
}
