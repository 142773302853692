import {
  GET_PARTNER_SITE_SETTINGS_REQUEST,
  GET_PARTNER_SITE_SETTINGS_RECEIVE,
  GET_PARTNER_SITE_SETTINGS_FAILURE,
  SELECT_PARTNER_SITE_THEME,
  TOGGLE_PARTNER_SITE_ENABLED,
  CHANGE_PARTNER_SITE_SUBDOMAIN,
  CHANGE_PARTNER_SITE_CUSTOM_DOMAIN,
  CHANGE_PARTNER_SITE_FACEBOOK_HANDLE,
  CHANGE_PARTNER_SITE_INSTAGRAM_HANDLE,
  CHANGE_PARTNER_SITE_TWITTER_HANDLE,
  CHANGE_PARTNER_SITE_IMPRESSUM_TEXT,
  SUBMIT_PARTNER_SITE_SETTINGS_REQUEST,
  SUBMIT_PARTNER_SITE_SETTINGS_SUCCESS,
  SUBMIT_PARTNER_SITE_SETTINGS_FAILURE,
  VALIDATE_PARTNER_SITE_SUBDOMAIN_REQUEST,
  VALIDATE_PARTNER_SITE_SUBDOMAIN_SUCCESS,
  VALIDATE_PARTNER_SITE_SUBDOMAIN_FAILURE,
  VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_REQUEST,
  VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_SUCCESS,
  VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_FAILURE,
  SET_SUBDOMAIN_VALIDATION_STATUS_TO_EMPTY,
  SET_CUSTOM_DOMAIN_VALIDATION_STATUS_TO_EMPTY,
  RESET_PARTNER_SITE_SETTINGS,
} from 'reduxStore/actionsConst';

import {
  PartnerSiteSettingsAndImpressum,
  PartnerSiteSettingsError,
  PartnerSiteTheme,
  PartnerSiteSubdomain,
  PartnerSiteCustomDomain,
  FacebookHandle,
  InstagramHandle,
  TwitterHandle,
  PartnerSiteValidationStatus,
} from './types';

export function getPartnerSiteSettingsRequest() {
  return {
    type: GET_PARTNER_SITE_SETTINGS_REQUEST,
  };
}

export function getPartnerSiteSettingsReceive(
  settingsAndImpressum: PartnerSiteSettingsAndImpressum,
) {
  return {
    type: GET_PARTNER_SITE_SETTINGS_RECEIVE,
    payload: settingsAndImpressum,
  };
}

export function getPartnerSiteSettingsFailure(err: PartnerSiteSettingsError) {
  return {
    type: GET_PARTNER_SITE_SETTINGS_FAILURE,
    payload: err,
  };
}

export function togglePartnerSiteEnabled() {
  return {
    type: TOGGLE_PARTNER_SITE_ENABLED,
  };
}

export function selectPartnerSiteTheme(theme: PartnerSiteTheme) {
  return {
    type: SELECT_PARTNER_SITE_THEME,
    payload: theme,
  };
}

export function changePartnerSiteSubdomain(subdomain: PartnerSiteSubdomain) {
  return {
    type: CHANGE_PARTNER_SITE_SUBDOMAIN,
    payload: subdomain,
  };
}

export function changePartnerSiteCustomDomain(customDomain: PartnerSiteCustomDomain) {
  return {
    type: CHANGE_PARTNER_SITE_CUSTOM_DOMAIN,
    payload: customDomain,
  };
}

export function changeFacebookHandle(facebookHandle: FacebookHandle) {
  return {
    type: CHANGE_PARTNER_SITE_FACEBOOK_HANDLE,
    payload: facebookHandle,
  };
}

export function changeInstagramHandle(instagramHandle: InstagramHandle) {
  return {
    type: CHANGE_PARTNER_SITE_INSTAGRAM_HANDLE,
    payload: instagramHandle,
  };
}

export function changeTwitterHandle(twitterHandle: TwitterHandle) {
  return {
    type: CHANGE_PARTNER_SITE_TWITTER_HANDLE,
    payload: twitterHandle,
  };
}

export function changeImpressumText(impressumText: string) {
  return {
    type: CHANGE_PARTNER_SITE_IMPRESSUM_TEXT,
    payload: impressumText,
  };
}

export function submitPartnerSiteSettingsRequest() {
  return {
    type: SUBMIT_PARTNER_SITE_SETTINGS_REQUEST,
  };
}

export function submitPartnerSiteSettingsSuccess() {
  return {
    type: SUBMIT_PARTNER_SITE_SETTINGS_SUCCESS,
  };
}

export function submitPartnerSiteSettingsFailure(err: PartnerSiteSettingsError) {
  return {
    type: SUBMIT_PARTNER_SITE_SETTINGS_FAILURE,
    payload: err,
  };
}

export function validatePartnerSiteSubdomainRequest() {
  return {
    type: VALIDATE_PARTNER_SITE_SUBDOMAIN_REQUEST,
  };
}

export function validatePartnerSiteSubdomainSuccess(status: PartnerSiteValidationStatus) {
  return {
    type: VALIDATE_PARTNER_SITE_SUBDOMAIN_SUCCESS,
    payload: status,
  };
}

export function validatePartnerSiteSubdomainFailure(err: PartnerSiteSettingsError) {
  return {
    type: VALIDATE_PARTNER_SITE_SUBDOMAIN_FAILURE,
    payload: err,
  };
}

export function validatePartnerSiteCustomDomainRequest() {
  return {
    type: VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_REQUEST,
  };
}

export function validatePartnerSiteCustomDomainSuccess(status: PartnerSiteValidationStatus) {
  return {
    type: VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_SUCCESS,
    payload: status,
  };
}

export function validatePartnerSiteCustomDomainFailure(err: PartnerSiteSettingsError) {
  return {
    type: VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_FAILURE,
    payload: err,
  };
}

export function setSubdomainValidationStatusToEmpty() {
  return {
    type: SET_SUBDOMAIN_VALIDATION_STATUS_TO_EMPTY,
  };
}

export function setCustomDomainValidationStatusToEmpty() {
  return {
    type: SET_CUSTOM_DOMAIN_VALIDATION_STATUS_TO_EMPTY,
  };
}

export function resetPartnerSiteSettings() {
  return {
    type: RESET_PARTNER_SITE_SETTINGS,
  };
}
