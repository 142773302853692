import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import ProductSupplierInformationTab from './index';

const mapStateToProps = ({
  productSuppliers: {
    supplier: { name, contactPersonName, phone, email },
  },
}) => ({
  name,
  contactPersonName,
  phone,
  email,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSupplierInformationTab);
