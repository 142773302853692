import { trackEvent, ACTIONS } from 'common/analytics';

export enum ServiceDurationLabel {
  Duration = 'duration',
  Application = 'duration-application',
  Finishing = 'duration-finishing',
  Processing = 'duration-processing',
}

interface ServiceDuration {
  label: ServiceDurationLabel;
  property: string;
  value: number;
}

export class AppointmentAnalytics {
  public static CATEGORY = 'appointment';

  public static trackAppointmentServiceEdit({ property, value }) {
    trackEvent(AppointmentAnalytics.CATEGORY, ACTIONS.EDIT, 'service', property, value);
  }

  public static trackAppointmentDateEdit({ property, value }) {
    trackEvent(AppointmentAnalytics.CATEGORY, ACTIONS.EDIT, 'date', property, value);
  }

  public static trackAppointmentStartTimeEdit({ property, value }) {
    trackEvent(AppointmentAnalytics.CATEGORY, ACTIONS.EDIT, 'start_time', property, value);
  }

  public static trackAppointmentDurationEdit({
    label = ServiceDurationLabel.Duration,
    property,
    value,
  }: ServiceDuration) {
    trackEvent(AppointmentAnalytics.CATEGORY, ACTIONS.EDIT, label, property, value);
  }

  public static trackAppointmentEmployeeEdit({ property, value }) {
    trackEvent(AppointmentAnalytics.CATEGORY, ACTIONS.EDIT, 'employee', property, value);
  }

  public static trackAppointmentCommissionsLinkClick() {
    trackEvent(AppointmentAnalytics.CATEGORY, ACTIONS.CLICK, 'commissions-link');
  }
}
