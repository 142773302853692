import { createAction } from 'reduxStore/actionCreator';
import {
  CORONA_REPORT_REQUEST,
  CORONA_REPORT_SUCCESS,
  CORONA_REPORT_FAILURE,
  CORONA_REPORT_RESET,
} from 'reduxStore/actionsConst';

export const downloadCoronaReportRequestAction = (payload: { from: string; to: string }) =>
  createAction(CORONA_REPORT_REQUEST, payload);

export const downloadCoronaReportRequestSuccessAction = () => createAction(CORONA_REPORT_SUCCESS);

export const downloadCoronaReportRequestFailureAction = () => createAction(CORONA_REPORT_FAILURE);

export const resetCoronaReportState = () => createAction(CORONA_REPORT_RESET);
