import { Checkbox } from 'components/common/Checkbox';
import { Expandable } from 'components/common/Expandable';
import React from 'react';
import { useGetGroupedServices } from 'src/hooks/useGetGroupedServices';
import styles from './ClientFormsServicePicker.scss';
import Loader from 'components/common/Loader';
import { Badge } from 'components/common/Badge';
import { SearchInput } from 'components/common/SearchInput';
import Wahanda from 'common/wahanda';

const lang = Wahanda.lang.marketing.clientForms;

type ClientFormsServicePickerProps = {
  selectedServices: number[];
  setSelectedServices: (serviceIds: number[]) => void;
  formTemplateDetailsLoading?: boolean;
  sortServicesBySelected?: boolean;
};

export const ClientFormsServicePicker = ({
  selectedServices,
  setSelectedServices,
  formTemplateDetailsLoading,
  sortServicesBySelected = false,
}: ClientFormsServicePickerProps) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const { groupedServices, isLoading } = useGetGroupedServices({
    searchQuery,
    selectedServices,
    sortServicesBySelected,
  });

  const handleSelectAllChange = (
    checked?: boolean,
    event?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const affectedServices: number[] = groupedServices[
      Number(event?.currentTarget.name)
    ].services.map((service) => service.id);

    setSelectedServices(
      checked
        ? [...selectedServices, ...affectedServices]
        : selectedServices.filter((service) => !affectedServices.includes(service)),
    );
  };

  const handleSelectServiceChange = (checked, event) => {
    setSelectedServices(
      checked
        ? [...selectedServices, Number(event.currentTarget.name)]
        : selectedServices.filter((service) => service !== Number(event.currentTarget.name)),
    );
  };

  if (isLoading || formTemplateDetailsLoading) return <Loader />;

  return (
    <div className={styles.wrapper}>
      <SearchInput
        name="client-form-service-picker-search"
        onChange={setSearchQuery}
        fullBorder
        isClearable
        isSearchable={true}
        placeholder={lang.servicesPickerPlaceholder}
        newLook
      />

      {groupedServices.map((group, i) => {
        if (group.services.length === 0) return null;

        const checkedServices = group.services.filter((service) =>
          selectedServices.includes(service.id),
        );
        const isChecked = checkedServices.length === group.services.length;
        const isSemiChecked = !isChecked && checkedServices.length > 0;

        return (
          <div key={`${group.id}-${searchQuery}`}>
            <Expandable
              defaultExpanded={searchQuery.length > 2}
              title={
                <div className={styles.expandableTitle}>
                  {group.name}
                  {checkedServices.length > 0 && (
                    <Badge
                      className={styles.badge}
                      text={checkedServices.length}
                      type="success"
                      isLight
                    />
                  )}
                </div>
              }
              newLook
              hasCheckbox
              checkboxDataTest={`service-group-${group.id}-checkbox`}
              checkboxName={String(i)}
              checkboxOnChange={handleSelectAllChange}
              checkboxIsChecked={isChecked}
              checkboxIsSemiChecked={isSemiChecked}
            >
              <ul className={styles.nestedList}>
                {group.services.map((service) => {
                  return (
                    <li key={service.id} className={styles.nestedListItem}>
                      <Checkbox
                        dataTest={`service-${service.id}-checkbox`}
                        label={<span className={styles.serviceName}>{service.name}</span>}
                        name={String(service.id)}
                        checked={selectedServices.includes(service.id)}
                        onChange={handleSelectServiceChange}
                      />
                    </li>
                  );
                })}
              </ul>
            </Expandable>
          </div>
        );
      })}
    </div>
  );
};
