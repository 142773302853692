import React from 'react';
import Wahanda from 'common/wahanda';
import Rating from 'react-components/Rating';
import StarGradient from 'react-components/Rating/StarGradient';
import { Notice } from 'components/common/Notice';
import { Info } from 'components/common/Icon';
import { ReviewsAnalytics } from '../tracking';
import { SIZE } from '../constants';
import { NumberUtils } from 'utilities/number';
import style from './style.scss';

const lang = Wahanda.lang.dashboard.reviews;
interface IHeaderProps extends React.HTMLAttributes<Element> {
  weightedRating: number;
  reviewCount: number;
  customerReviews: {
    page?: {
      total?: number;
    };
  };
}

export class Header extends React.PureComponent<IHeaderProps, {}> {
  handleInfoClick = () => {
    ReviewsAnalytics.trackInfoIconClicked();
  };

  render() {
    const { weightedRating, reviewCount, customerReviews } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const total = customerReviews.page.total;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const displayWarning = total < SIZE && total !== reviewCount;
    return (
      <div className={style.header}>
        <div className={style.container}>
          <div className={style.starsAndRatingContainer}>
            <div className={style.rating}>
              {NumberUtils.formatToSingleDecimalAndRoundUpByHalf(weightedRating)}
            </div>
            <div className={style.ratingContainer}>
              <div className={style.modifiedStars}>
                <Rating rating={weightedRating} size="big" />
                <StarGradient />
              </div>
              <div>
                <span className={style.text}>
                  {Wahanda.Template.render(lang.header, { reviewCount })}
                </span>
              </div>
            </div>
          </div>
          <a
            href={lang.moreReviewsInfoLink}
            onClick={this.handleInfoClick}
            data-test="reviews-info-icon"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Info className={style.infoIcon} />
          </a>
        </div>
        {displayWarning && <Notice message={lang.attentionMessage} />}
      </div>
    );
  }
}

export default Header;
