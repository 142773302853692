import { trackEvent } from 'common/analytics';
/* eslint-disable func-names */
/* global Backbone WorkingHoursCache */
App.Views.Calendar.DatePicker = Backbone.View.extend({
  lastVenueId: null,

  initialize: function () {
    const self = this;

    // Event bindings to the calendar view
    this.options.calendarView
      .on('change:date', function (date) {
        self.setDate(date);
      })
      .on('change:type', function () {
        self.refresh();
      });
  },

  render: function () {
    const self = this;

    this.$el.datepicker('destroy').datepicker({
      defaultDate: this.options.calendarView.currentOptions.date,
      firstDay:
        Wahanda.Date.dayStringToNumberMap[App.config.get('jqueryDateFormat').firstDayOfWeek],
      dateFormat: Wahanda.Date.apiDateFormat,

      onSelect: function (stringDate, inst) {
        const type = self.options.calendarView.currentOptions.type;
        trackEvent('navigation', 'click', `minicalendar-${type}`);
        self.onDateSelected(inst);
        self.fetchOpeningTimes(inst.selectedYear, inst.selectedMonth);
      },
      beforeShowDay: function (date) {
        return self.getDayOptions(date);
      },
      afterShow: function () {
        App.trigger(Wahanda.Event.CALENDAR_DATEPICKER_RENDERED);
      },
      afterUpdate: function () {
        App.trigger(Wahanda.Event.CALENDAR_DATEPICKER_RENDERED);
      },
      onChangeMonthYear: function (year, month) {
        self.fetchOpeningTimes(year, month - 1);
      },
      showOtherMonths: true,
      selectOtherMonths: true,
    });

    this.fetchOpeningTimes(null, null, true);
  },

  /**
   * Fetches new opening times, and refreshes the calendar.
   *
   * Year and month can be passed in. Month must be in JS format (e.g. starting from 0).
   */
  fetchOpeningTimes: function (year, month, force) {
    let shownDate;
    const self = this;
    if (year > 0 && month >= 0) {
      shownDate = new Date(year, month, 1);
    } else {
      shownDate = this.options.calendarView.currentOptions.date;
    }
    const datePrefix = `${shownDate.getFullYear()}-${Wahanda.Date._makeDoubleDigit(
      shownDate.getMonth() + 1,
    )}-`;

    const currentVenueId = App.getVenueId();
    if (
      !this.openingTimesModel ||
      this.lastVenueId !== currentVenueId ||
      `${datePrefix}01` !== this.openingTimesModel.get('dateFrom') ||
      force
    ) {
      WorkingHoursCache.get(
        `${datePrefix}01`,
        datePrefix + Wahanda.Date.getDaysInMonth(shownDate),
      ).done(function (newOpeningTimes) {
        self.openingTimesModel = newOpeningTimes;
        self.refresh();
      });
      this.lastVenueId = currentVenueId;
    }
  },

  /**
   * Redraws the datepicker.
   */
  refresh: function () {
    this.$el.datepicker('refresh');
  },

  onDateSelected: function (inst) {
    const date = new Date(inst.currentYear, inst.selectedMonth, inst.selectedDay);
    this.options.calendarView.trigger('change:date', date);
  },

  setDate: function (date) {
    this.$el.datepicker('setDate', date);
    // TODO: check if has date for the visible calendar
  },

  getDayOptions: function (date) {
    let classes = '';

    if (this.options.calendarView.currentOptions.type === 'day') {
      // No additional classes. DP selection is active.
    } else {
      const range = this.options.calendarView.getVisibleDateRange();
      if (Wahanda.Date.isDateBetween(date, range.from, range.to)) {
        classes += ' ui-datepicker-calendar-visible-week';
      }
    }

    const time = this.openingTimesModel && this.openingTimesModel.getTimesByDate(date);
    if (time) {
      if (time.timeSlots.length === 0) {
        classes += ' non-working-hours';
      } else if (time.type === 'CUSTOM') {
        classes += ' custom-hours';
      }
    }

    return [true, classes];
  },
});
