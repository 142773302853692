export const get = () =>
  new Promise((resolve, reject) => {
    Wahanda.Cache.menu()
      .done((result) => resolve(result.toJSON()))
      .fail((jq, err) => reject(err));
  });

export const getFiltered = (filterType) =>
  new Promise((resolve, reject) => {
    Wahanda.Cache.menuFiltered(filterType)
      .done((result) => resolve(result.toJSON()))
      .fail((jq, err) => reject(err));
  });
