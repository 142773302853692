import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../store/actions';
import { ShiftEmployeeMonth as Container } from './ShiftEmployeeMonth';

const mapStateToProps = ({ shifts }) => ({
  employees: shifts.employees.employeesWorkingHours,
  venue: shifts.venue,
  isLoading: shifts.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ShiftEmployeeMonth = connect(mapStateToProps, mapDispatchToProps)(Container);
