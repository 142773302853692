import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import { Percentage } from 'components/common/DataFormat';
import {
  Table,
  TableColumnHeader,
  TableRow,
  TableBody,
  TableHeader,
  TableData,
} from 'components/common/Table';

import style from './style.scss';

interface MonthlyClientRetention {
  month: string;
  firstTimeClients: number;
  repeatClients: number;
  totalClients: number;
  walkInClients: number;
  retention: number;
}

export interface Props {
  months: MonthlyClientRetention[];
  firstTimeClients: number;
  repeatClients: number;
  walkInClients: number;
}

const LANG = Wahanda.lang.reports.clientReports.clientRetention;

export default class ClientRettentionTable extends React.Component<Props> {
  public render() {
    const { months } = this.props;
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableColumnHeader className={classnames(style.monthHeader, style.borderRight)}>
              {LANG.headers.month}
            </TableColumnHeader>
            <TableColumnHeader
              className={classnames(style.textAlignRight, style.clientsHeader, style.borderRight)}
            >
              {LANG.headers.totalClients}
            </TableColumnHeader>
            <TableColumnHeader className={classnames(style.textAlignRight, style.retentionHeader)}>
              {LANG.headers.clientRetention}
            </TableColumnHeader>
          </TableRow>
        </TableHeader>
        <TableBody>
          {months
            .sort((m1, m2) => moment(m2.month).diff(moment(m1.month), 'day'))
            .map((month) => (
              <TableRow key={month.month}>
                <TableData className={classnames(style.date, style.borderRight)}>
                  {moment(month.month).format(LANG.headers.dateFormat)}
                </TableData>
                <TableData
                  className={classnames(
                    style.totalClients,
                    style.textAlignRight,
                    style.borderRight,
                  )}
                >
                  {month.totalClients}
                </TableData>
                <TableData
                  className={classnames(style.retention, style.bold, style.textAlignRight)}
                >
                  <Percentage value={month.retention} rounded />
                </TableData>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  }
}
