import { call, put } from 'redux-saga/effects';
import apiUrl from 'common/api-url';
import * as api from 'reduxStore/apiService';
import {
  receiveBankDetailsAction,
  requestLastPaymentStatusReceived,
  RequestLastPaymentStatusAction,
  RequestBankDetailsAction,
} from './actions';
import { LastPaymentResponse, CompanyResponse } from './types';

export function* getBankDetailsRequested(action: RequestBankDetailsAction) {
  const data: CompanyResponse = yield call(
    api.get,
    `/api/supplier/${action.payload.supplierId}/company.json`,
  );

  yield put(receiveBankDetailsAction(data));
}

export function* getLastPaymentStatus(action: RequestLastPaymentStatusAction) {
  const data: LastPaymentResponse = yield call(
    api.get,
    apiUrl('PREVIOUS_PAYMENT', { supplierId: action.payload.supplierId }),
  );
  yield put(requestLastPaymentStatusReceived(data));
}
