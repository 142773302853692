/**
 * Remittance model.
 */
(function () {
  App.Models.Remittance = BackboneEx.Model.Silent.extend({
    url: function () {
      return App.Api.wsUrl(`/supplier/${App.config.get('venue').supplierId}/remittances.json`);
    },

    isPending: function () {
      // Remittance is pending if the status code is issued or issued unreceived
      if (this.get('statusCode') === 'IS' || this.get('statusCode') === 'IP') {
        return true;
      }
      return false;
    },

    getFormattedDates: function () {
      const periodStartDate = Wahanda.Date.createDate(this.get('periodStartDate'));
      const periodEndDate = Wahanda.Date.createDate(this.get('periodEndDate'));
      return `${Wahanda.Date.formatToDefaultDate(
        periodStartDate,
      )} - ${Wahanda.Date.formatToDefaultDate(periodEndDate)}`;
    },

    getPdf: function () {
      if (this.get('pdfDownloadUri')) {
        return App.Api.wsUrl(this.get('pdfDownloadUri'));
      }
      return false;
    },

    getCsvZip: function () {
      if (this.get('csvZipDownloadUri')) {
        return App.Api.wsUrl(this.get('csvZipDownloadUri'));
      }
      return false;
    },

    getXls: function () {
      if (this.get('xlsDownloadUri')) {
        return App.Api.wsUrl(this.get('xlsDownloadUri'));
      }
      return false;
    },

    getPaymentDetails: function () {
      // Assumption atm is that we only allow 1 payment?
      const payment = this.get('payments')[0];

      if (!payment) {
        return null;
      }

      const paymentDate = Wahanda.Date.formatDateTime(
        App.config.get('jqueryDateFormat').defaultDate,
        App.config.get('jqueryDateFormat').defaultTime,
        Wahanda.Date.createDate(payment.created),
      );

      return {
        paymentDate: `${paymentDate.date}, ${paymentDate.time}`,
        payerName: payment.payerName,
        payerEmail: payment.payerEmail,
      };
    },

    getStatus: function () {
      let statusObj = {};

      switch (this.get('statusCode')) {
        case 'PD':
          statusObj = { paid: true };
          break;

        case 'CR':
          statusObj = { unpaid: true };
          break;

        case 'LB':
          statusObj = { liability: true };
          break;

        case 'BD':
          statusObj = { badDebt: true };
          break;

        case 'IS':
          statusObj = { issued: true };
          break;

        case 'IP':
          statusObj = { issuedUnpaid: true };
          break;

        case 'RC':
          statusObj = { received: true };
          break;
      }
      return statusObj;
    },

    isPast: function () {
      const date = Wahanda.Date.createDate(this.get('periodEndDate'));
      return Wahanda.Date.compareRangeToNow(null, date).isPast;
    },
  });
})();
