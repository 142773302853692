import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import { DialogCloseWithChanges } from 'components/common/dialog/DialogCloseWithChanges';
import style from './style.scss';

const lang = Wahanda.lang.menu.discounts.assignedTreatments.emptyState;
interface IEmptyStateProps extends React.HTMLAttributes<Element> {
  hasChanges: (...args: any[]) => any;
}
type EmptyStateState = {
  renderCancel?: boolean;
};
class EmptyState extends React.Component<IEmptyStateProps, EmptyStateState> {
  state = {
    renderCancel: false,
  };

  redirectToMenu() {
    // eslint-disable-line class-methods-use-this
    // This is nasty, horrible, bad code.
    // TODO: When the react sidebar is introduced, remove this.
    // Story number : DEV-52894
    window.location.assign(Wahanda.Url.getFullUrl('menu', 'services'));
    window.location.reload();
  }

  renderCancel() {
    // eslint-disable-line class-methods-use-this
    return (
      <DialogCloseWithChanges
        onConfirm={this.redirectToMenu}
        onClose={() => {
          this.setState({
            renderCancel: false,
          });
        }}
      />
    );
  }

  render() {
    const { renderCancel } = this.state;
    return (
      <div className={classnames('emptyTreatmentsList', style.emptyTreatmentsList)}>
        <h1>{lang.title}</h1>
        <p>{lang.description}</p>
        <Button
          onClick={() => {
            const { hasChanges } = this.props;
            if (hasChanges()) {
              this.setState({
                renderCancel: true,
              });
            } else {
              this.redirectToMenu();
            }
          }}
          label={lang.link}
        />
        {renderCancel ? this.renderCancel() : null}
      </div>
    );
  }
}

export default EmptyState;
