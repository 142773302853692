import { connect } from 'react-redux';
import { FBEInstallStatus } from '../types';
import { uninstallFBERequest } from './actions';
import { Installed as Component } from './Installed';

const mapStateToProps = ({
  marketing: {
    fbeIntegration: {
      installStatus: { status },
    },
  },
}) => {
  return {
    isLoading: status === FBEInstallStatus.UNINSTALLING,
  };
};

const mapDispatchToProps = {
  uninstallFBERequest,
};

export const Installed = connect(mapStateToProps, mapDispatchToProps)(Component);
