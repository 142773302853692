import React from 'react';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';
import 'react-dates/initialize';

import { DatePicker, DatePickerProps } from '../';

import { PRESET_DATE, PresetRanges } from '../presetDateRangePropTypes';

interface Props extends Omit<DatePickerProps, 'presetRanges'> {
  presetRanges: PresetRanges | null;
}

export const DEFAULT_DATE_PICKER_PRESETS = {
  thisMonth: {
    type: PRESET_DATE.THIS_MONTH,
    buttonText: Wahanda.lang.reports.sales.filters.dateRange.thisMonth,
    getDates: () => ({
      startDate: moment().startOf('month'),
      endDate: moment(),
    }),
  },
  lastMonth: {
    type: PRESET_DATE.LAST_MONTH,
    buttonText: Wahanda.lang.reports.sales.filters.dateRange.lastMonth,
    getDates: () => ({
      startDate: moment().startOf('month').subtract(1, 'months'),
      endDate: moment().startOf('month').subtract(1, 'days'),
    }),
  },
  thisWeek: {
    type: PRESET_DATE.THIS_WEEK,
    buttonText: Wahanda.lang.reports.sales.filters.dateRange.thisWeek,
    getDates: () => ({
      startDate: moment().startOf('week'),
      endDate: moment().endOf('week'),
    }),
  },
  lastWeek: {
    type: PRESET_DATE.LAST_WEEK,
    buttonText: Wahanda.lang.reports.sales.filters.dateRange.lastWeek,
    getDates: () => ({
      startDate: moment().startOf('week').subtract(1, 'weeks'),
      endDate: moment().startOf('week').subtract(1, 'days'),
    }),
  },
  thisYear: {
    type: PRESET_DATE.THIS_YEAR,
    buttonText: Wahanda.lang.reports.sales.filters.dateRange.thisYear,
    getDates: () => ({
      startDate: moment().startOf('year'),
      endDate: moment(),
    }),
  },
  last30Days: {
    type: PRESET_DATE.LAST_30_DAYS,
    buttonText: Wahanda.lang.reports.sales.filters.dateRange.lastThirtyDays,
    getDates: () => ({
      startDate: moment().subtract(30, 'days'),
      endDate: moment(),
    }),
  },
};

export const DatePickerWithPresets = ({
  presetRanges = DEFAULT_DATE_PICKER_PRESETS,
  ...otherProps
}: Props) => <DatePicker {...otherProps} presetRanges={presetRanges} />;
