import { createAction } from 'reduxStore/actionCreator';
import {
  DAILY_REPORT_REQUEST,
  DAILY_REPORT_SUCCESS,
  DAILY_REPORT_FAILURE,
} from 'reduxStore/actionsConst';
import { DailyReport } from './types';

export const dailyReportRequest = (date: string) => createAction(DAILY_REPORT_REQUEST, { date });

export const dailyReportSuccess = (dailyReport: DailyReport) =>
  createAction(DAILY_REPORT_SUCCESS, { dailyReport });

export const dailyReportFailure = () => createAction(DAILY_REPORT_FAILURE);
