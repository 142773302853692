import React from 'react';
import { InputError } from 'components/common/__BaseCommon';
import { StripeElement, Stripe } from '../types';
import style from './CardInput.scss';

interface Props {
  label?: string;
  stripe: Stripe;
  onReady?: (stripeElement: StripeElement) => void;
}

interface State {
  validationError?: string;
}

export default class CardInput extends React.PureComponent<Props, State> {
  private cardInput: React.RefObject<HTMLDivElement>;

  private element?: StripeElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      validationError: undefined,
    };
    this.cardInput = React.createRef<HTMLDivElement>();
  }

  public componentDidMount() {
    const { stripe, onReady } = this.props;
    this.element = stripe
      .elements({
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css?family=Roboto+Mono|Source+Sans+Pro:400,400i,600,700&amp;subset=latin-ext',
          },
        ],
      })
      .create('card', {
        hidePostalCode: true,
        hideIcon: true,
        style: {
          base: {
            color: style.fontColor,
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: style.fontSize,
            lineHeight: '25px',
            '::placeholder': {
              color: style.placeholderFontColor,
            },
          },
        },
      });

    this.element.mount(this.cardInput.current!);
    this.element.addEventListener('change', (event) => {
      const error = event.error ? event.error.message : undefined;

      this.setState({ validationError: error }, () => {
        /*
         ** this is a hack to force components/common/react-modal to re-render
         ** as it does not catch events that happen on iframe
         */
        window.dispatchEvent(new Event('resize'));
      });
    });
    if (onReady) {
      onReady(this.element);
    }
  }

  public componentWillUnmount() {
    if (!this.element) {
      return;
    }
    this.element.destroy();
  }

  public render() {
    const { label } = this.props;
    const { validationError } = this.state;
    return (
      <label className={style.container}>
        {label && <div className={style.label}> {label} </div>}
        <div className={style.input} ref={this.cardInput} />
        {validationError && (
          <div className={style.validationMessage}>
            <InputError message={validationError} />
          </div>
        )}
      </label>
    );
  }
}
