import 'react-dates/initialize';
import React from 'react';
import { Chevron } from 'components/common/Icon';
import { Button, ButtonProps } from 'components/common/Button';
import classnames from 'classnames';
import style from './DatePickerWrapper.scss';

interface Range {
  onSelect: () => void;
  disabled?: boolean;
}

export interface Props {
  render: () => {};
  navigation?: {
    nextRange: Range;
    prevRange: Range;
  };
}

export const DatePickerWrapper = (props: Props) => {
  const { render, navigation } = props;
  const navigationProps: ButtonProps = {
    colour: 'plain',
    variant: 'hollow',
    size: 'small',
  };
  const renderNavigationPreviousTimePeriod = () => {
    if (!navigation) {
      return null;
    }
    return (
      <Button
        {...navigationProps}
        onClick={navigation.prevRange.onSelect}
        disabled={navigation.prevRange.disabled}
        icon={<Chevron size="medium" className={style.prev} />}
      />
    );
  };
  const renderNavigationNextTimePeriod = () => {
    if (!navigation) {
      return null;
    }
    return (
      <Button
        {...navigationProps}
        disabled={navigation.nextRange.disabled}
        onClick={navigation.nextRange.onSelect}
        icon={<Chevron size="medium" />}
      />
    );
  };
  const className = classnames(style.picker, {
    [style.withNavigation]: navigation,
  });

  return (
    <div className={style.datePicker}>
      {renderNavigationPreviousTimePeriod()}
      <div className={className}>{render()}</div>
      {renderNavigationNextTimePeriod()}
    </div>
  );
};
