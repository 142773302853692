import React, { PureComponent } from 'react';

export default class DiscountTag extends PureComponent<{}, {}> {
  render() {
    return (
      <svg width="104" height="104">
        <g fill="none" fillRule="evenodd">
          <path
            fill="#00B0B9"
            stroke="#001E62"
            strokeWidth="2"
            d="M42.643 37.515c.88 0 1.744.258 2.491.758a4.488 4.488 0 0 1 1.228 6.22 4.461 4.461 0 0 1-2.859 1.903 4.469 4.469 0 0 1-3.362-.675 4.459 4.459 0 0 1-1.901-2.857 4.458 4.458 0 0 1 .675-3.364 4.48 4.48 0 0 1 3.728-1.985zm3.622 24.395v.001a4.468 4.468 0 0 1 1.903 2.857 4.487 4.487 0 0 1-6.897 4.59 4.45 4.45 0 0 1-1.9-2.855 4.453 4.453 0 0 1 .674-3.365 4.48 4.48 0 0 1 3.729-1.985c.88 0 1.744.258 2.49.757z"
          />
          <path
            fill="#001E62"
            d="M67.063 15.464a9.07 9.07 0 0 1 1.638 1.178 30.79 30.79 0 0 1 1.437-1.392 11.169 11.169 0 0 0-1.606-1.238c-.502.466-.99.951-1.469 1.452"
          />
          <path
            fill="#D6CF8D"
            d="M33.3 56.102c-.804-8.005-.543-16.029.788-23.85l-16.652 26.2L10.4 70.144c-.097.16-.097.49-.001.993a8.934 8.934 0 0 0-.156 4.686 8.936 8.936 0 0 0 4.026 5.59l18.015 11.14c3.809 2.353 8.7 1.51 11.537-1.779-5.483-10.382-8.92-21.47-10.295-32.646l-.226-2.026z"
          />
          <path
            stroke="#001E62"
            strokeWidth="2"
            d="M16.924 58.025l23.368-37.61a10 10 0 0 1 6.717-4.562l13.776-2.488a10 10 0 0 1 11.309 6.817l4.304 13.565a10 10 0 0 1-1.026 8.283L45.56 90.252c-2.905 4.697-9.067 6.151-13.765 3.247l-.027-.017-18-11.208a10 10 0 0 1-3.285-13.64l.538-.894"
          />
          <path
            stroke="#001E62"
            strokeWidth="2"
            d="M77.861 56.5c2.862-5.877 5.109-10.283 6.74-13.217 2.446-4.402 6.002-5.253 8.775-3.85 2.773 1.401 3.374 5.082 1.188 7.25-2.186 2.167-8.112 1.135-7.238-7.458.875-8.593 4.212-11.363 5.052-14.286.998-3.471.144-8.934-4.436-12.128-4.58-3.194-12.325-6.647-23.289 6.808l-1.383-.67a3 3 0 0 0-3.83 1.074l-1.292 2.004a3 3 0 0 0 .893 4.145l1.865 1.205a3 3 0 0 0 4.133-.868l1.351-2.05a3 3 0 0 0-.682-4.034l-1.055-.806M31.857 57.712l23.731-7.665"
          />
        </g>
      </svg>
    );
  }
}
