import React, { PureComponent } from 'react';
import style from './style.scss';

interface INotificationListHeaderProps extends React.HTMLAttributes<Element> {
  text: string;
}

export default class NotificationListHeader extends PureComponent<
  INotificationListHeaderProps,
  {}
> {
  render() {
    const { text } = this.props;
    return <div className={style.title}>{text}</div>;
  }
}
