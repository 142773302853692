import React, { Component } from 'react';
import moment from 'common/moment';
import classnames from 'classnames';
import { trackEvent } from 'common/analytics';
import { PaymentProtectionBadge } from 'components/shared/PaymentProtectiomBadge';
import style from './style.scss';
// @ts-expect-error ts-migrate(2430) FIXME: Type 'Element' is not assignable to type 'string'.
interface INotificationListItemProps extends React.HTMLAttributes<Element> {
  title: JSX.Element | string;
  icon: JSX.Element;
  detailsFirstRow?: JSX.Element | string;
  detailsSecondRow?: JSX.Element | string;
  datetime: string;
  lastSeenDatetime?: string;
  onClick?: (...args: any[]) => any;
  additionalFields?: { [_: string]: any };
}

export class NotificationListItem extends Component<INotificationListItemProps, {}> {
  static defaultProps = {
    detailsFirstRow: null,
    detailsSecondRow: null,
    lastSeenDatetime: null,
    onClick: () => {},
  };

  onListItemClick = () => {
    trackEvent('notification-list', 'click', 'notification-list-item');
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.onClick();
  };

  getDateForDisplay = () => {
    const { datetime } = this.props;
    return moment(datetime).calendar(null, {
      sameDay: () => `[${moment(datetime).fromNow(true)}]`,
      nextWeek: 'MMMM D',
      lastWeek: 'MMMM D',
      sameElse: 'MMMM D',
    });
  };

  render() {
    const {
      title,
      icon,
      detailsFirstRow,
      detailsSecondRow,
      datetime,
      lastSeenDatetime,
      additionalFields,
    } = this.props;
    const unseen = lastSeenDatetime ? moment(lastSeenDatetime).isBefore(datetime) : true;
    const classNames = classnames(style.item, { [style.unseen]: unseen });
    return (
      <div className={classNames} onClick={this.onListItemClick}>
        <div className={style.left}>{icon}</div>
        <div className={style.content}>
          <div className={style.title}>{title}</div>
          <div>{detailsFirstRow}</div>
          {additionalFields?.paymentProtectionApplied && (
            <div className={style.badge}>
              <PaymentProtectionBadge />
            </div>
          )}
          <div>{detailsSecondRow}</div>
        </div>
        <div className={style.right}>{this.getDateForDisplay()}</div>
      </div>
    );
  }
}

export default NotificationListItem;
