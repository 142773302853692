import { useQuery } from 'react-query';
import apiUrl, { RouteName } from 'common/api-url';
import * as api from 'reduxStore/apiService';

interface PaymentProtectionResponse {
  canPreventForCancellation: boolean;
  canPreventForNoShow: boolean;
}

export function usePaymentProtectionQuery(
  granularity: 'appointment' | 'appointment-group',
  id: number,
  enabled: boolean,
  flow: 'no-show' | 'cancellation',
) {
  const { isLoading, data } = useQuery<PaymentProtectionResponse>(
    ['appointment-payment-protection', flow, granularity, id],
    () => {
      const apiName = {
        appointment: 'APPOINTMENT_PAYMENT_PROTECTION',
        'appointment-group': 'APPOINTMENT_GROUP_PAYMENT_PROTECTION',
      };

      return api
        .get<PaymentProtectionResponse>(apiUrl(apiName[granularity] as RouteName, { id }), {
          skipErrorHandling: true,
        })
        .catch(() => {
          // Instead of blocking user in case of an error, allow to proceed without selecting whether to prevent payment protection
          return {
            canPreventForCancellation: false,
            canPreventForNoShow: false,
          };
        });
    },
    {
      enabled,
    },
  );

  return { isLoading, response: data };
}
