BackboneEx.View.DialogCollection = (function() {
    // The logic here handles closing of dialogs.
    // Main task - to close the most recently open dialog when ESC is pressed
    // Extra important - not to prevent closing of the requried dialog

    var openDialogsList = [];
    var KEY_ESCAPE = $.ui.keyCode.ESCAPE;

    function addToOpenModalDialogsStack(dialog) {
        openDialogsList.push({
            type: "backbone",
            dialog: dialog
        });

        dialog.$el.on('dialogbeforeclose', checkCorrectDialogClosed)
                  .on('dialogclose', updateReactState);

        updateReactState();
    }

    function pop () {
        var value = openDialogsList.pop();

        updateReactState();

        return value;
    }

    function getLast () {
        return openDialogsList[openDialogsList.length - 1];
    }

    function addReactDialog (uuid, closeFn) {
        const find = ({ type, key }) => (type === 'react' && key === uuid);
        if (openDialogsList.some(find) === false) {
            openDialogsList.push({
                type: 'react',
                key: uuid,
                closeFn
            });
        }
        updateReactState();
    }

    function removeReactDialog (uuid) {
        const find = ({ type, key }) => (type === 'react' && key === uuid);
        if (openDialogsList.some(find)) {
            openDialogsList.splice(
                openDialogsList.findIndex(find),
                1
            );
        }
        updateReactState();
    }

    function getDomEvent (event) {
        if (!event.originalEvent) {
            return event;
        }
        return getDomEvent(event.originalEvent);
    }

    function checkCorrectDialogClosed(event) {
        if (event.keyCode != KEY_ESCAPE) {
            // Checks work only for ESCAPE key triggered closings
            removeDialogFromStack(this);
            return true;
        }

        // Mark the event as handled. This is relevant to correctly handle closing of React dialogs.
        getDomEvent(event).dialogCollectionHandled = true;

        var last = pop();
        if (!last || (last.type === "backbone" && last.dialog.$el[0] === this)) {
            // No dialogs in stack, or the current dialog is the last one opened.
            removeDialogFromStack(this);
            return true;
        }

        // The currently closed dialog isn't the last one opened. Close the last one.
        // Using setTimeout() to avoid recursion.
        if (last.type === "backbone") {
            window.setTimeout(function() { last.close(false); }, 0);
        } else if (last.type === "react") {
            window.setTimeout(function() { last.closeFn(); }, 0);
        }


        // This prevents closing the current dialog
        return false;
    };

    function removeDialogFromStack (toRemove) {
        openDialogsList = _.filter(openDialogsList, function(item) {
            return item.type !== "backbone" || item.dialog.$el[0] !== toRemove;
        });
        updateReactState();
    }

    function updateReactState () {
        var backboneOverlays = _.where(openDialogsList, { type: "backbone" });

        App.ES6.Initializers.State.change({
            "backbone-dialog-overlay-count": backboneOverlays.length,
            "react-dialog-overlay-count": openDialogsList.length - backboneOverlays.length
        });
    }

    function isLastOpenReactDialog (dialogKey) {
        var last = getLast();

        if (!last) {
            return true;
        }

        return last.type === "react" && last.key === dialogKey;
    }

    function closeLastReactDialog () {
        var last = getLast();

        if (!last || last.type !== "react") {
            return;
        }

        last.closeFn();
    }

    return {
        addToOpenModalDialogsStack: addToOpenModalDialogsStack,
        isLastOpenReactDialog: isLastOpenReactDialog,
        addReactDialog: addReactDialog,
        removeReactDialog: removeReactDialog,
        pop: pop,
        closeLastReactDialog: closeLastReactDialog
    };
})();
