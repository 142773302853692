import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Verification extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-verification', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M12.062 1L3.016 5v6c0 5.55 3.86 10.74 9.046 12 5.187-1.26 9.047-6.45 9.047-12V5l-9.047-4zm7.036 10c0 4.52-2.995 8.69-7.036 9.93-4.04-1.24-7.036-5.41-7.036-9.93V6.3l7.036-3.11 7.036 3.11V11zm-11.65.59L6.031 13l4.02 4 8.042-8-1.417-1.42-6.624 6.59-2.604-2.58z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Verification);
