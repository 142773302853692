import Wahanda from 'common/wahanda';

export const ReceiptCopyDialogFields = ({ errors }) => {
  const reasonPattern = /[^\s]{3,}/;
  const reasonValidationRules = {
    required: Wahanda.lang.pos.receiptCopyDialog.validation.reasonRequired,
    pattern: {
      value: reasonPattern,
      message: Wahanda.lang.pos.receiptCopyDialog.validation.reasonRequired,
    },
  };

  return {
    reason: {
      name: 'reason',
      type: 'text',
      dataTest: 'receipt-copy-reason',
      label: Wahanda.lang.pos.receiptCopyDialog.reasonLabel,
      rules: reasonValidationRules,
      hasError: Boolean(errors.reason),
      errorMessage: errors.reason?.message,
      fullBorder: true,
      autoFocus: true,
      required: true,
    },
  };
};
