import React from 'react';
import classNames from 'classnames';
import style from './Radio.scss';

export interface RadioProps {
  dataTest?: string;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  label: string | React.ReactNode;
  name: string;
  onChange?: (event: any) => void;
  value: string | number;
}

export const Radio = ({
  dataTest = '',
  name,
  label,
  value,
  checked = false,
  onChange,
  className = '',
  disabled = false,
}: RadioProps) => {
  const htmlFor = `${name}-${value}`;
  return (
    <>
      <input
        id={htmlFor}
        className={style.hiddenInput}
        type="radio"
        name={name}
        checked={checked}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
      <label
        className={classNames(style.radio, style[className], {
          [style.disabled]: disabled,
          [style.checked]: checked,
        })}
        data-test={dataTest}
        htmlFor={htmlFor}
      >
        <div className={style.circle} />
        {label && <span className={style.label}>{label}</span>}
      </label>
    </>
  );
};
