import { xhr } from 'common/xhr';

App.Collections.Customers = BackboneEx.Collection.UrlFilter.extend({
  model: App.Models.Customer,

  // Last fetch result's URL suffix
  searchSuffixToContinue: null,

  urlTemplate: '/venue/{{venueId}}/customers.json',
  filters: App.Models.Customer.getUrlFilters(),
  filterMap: App.Models.Customer.getUrlFilterMap(),

  /**
   * Fetches model's data and adds the results to the collection, not removing any old data
   *
   * @return jqXHR
   */
  search: function (options) {
    var self = this;
    return xhr
      .doJQueryAjax({
        dataType: 'json',
        url: options.url || this.url(),
      })
      .success(onFetched)
      .error(options.error);

    function onFetched(data) {
      var items = self.parse(data);
      var lastLenth = self.length;
      var newItems = [];
      self.add(items);

      self.searchSuffixToContinue = data.nextPage;

      if (options.success) {
        for (var i = lastLenth, len = self.length; i < len; i++) {
          newItems.push(self.at(i));
        }
        options.success(newItems, {
          hasMoreResults: null != data.nextPage,
          totalCustomers: data.totalCustomers,
          filterResultCount:
            data.filterResultCount != null ? data.filterResultCount.toString() : null,
        });
      }
    }
  },

  /**
   * Returns next search results page.
   *
   * @param Object options Accepts the same parameters as `search`
   * @return jqXHR
   */
  getSearchNextPage: function (options) {
    var url = Wahanda.Template.render(this.urlTemplate, {
      venueId: App.getVenueId(),
    });
    url += '?' + this.searchSuffixToContinue;
    options.url = App.Api.wsUrl(url);

    return this.search(options);
  },

  /**
   * Returns array of models for this collection to use.
   *
   * @param Object data
   * @return Array
   */
  parse: function (data) {
    return data.customers;
  },
});
