import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import { Initializer } from 'common/types/initializers';
import { TeamLayout } from './container';

const store = storeBuilder();
interface Params {
  venueId: number;
  node: HTMLElement;
  tab: string;
}

export const TeamLayoutInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(params.node),
  render: () => {
    ReactDOM.render(
      <Provider store={store}>
        <TeamLayout venueId={params.venueId} currentTab={params.tab} />
      </Provider>,
      params.node,
    );
  },
});
