/* global Wahanda */

import { initialsGenerator } from 'helpers/initialsGenerator';

(() => {
  const InitialsGenerator = {
    generateHTML: function generateHTML(name, opts) {
      const initials = initialsGenerator(name);
      const classes = `initials-bubble${opts && opts.small ? ' small' : ''}`;

      return Wahanda.Template.render('<div class="{{classes}}">{{initials}}</div>', {
        initials,
        classes,
      });
    },
  };

  Wahanda.InitialsGenerator = InitialsGenerator;
})();
