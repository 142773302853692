import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { dailyReportRequest, dailyReportSuccess, dailyReportFailure } from './actions';

export function* getDailyReport(action: ReturnType<typeof dailyReportRequest>) {
  try {
    const posDailyReport = yield call(
      api.get,
      apiUrl('POS_DAILY_REPORT', { date: action.payload.date }),
    );

    yield put(dailyReportSuccess(posDailyReport));
  } catch {
    yield put(dailyReportFailure());
  }
}
