import { Checkbox as CK } from 'components/common/Checkbox';
import { CurrencyInput as FCI } from 'components/common/CurrencyInput';
import { Input as FI } from 'components/common/Input';
import { Select as FS } from 'components/common/Select';
import { Textarea as FTA } from 'components/common/Textarea';
import { withFormContext } from './withFormContext';

export * from './Form';

export const CurrencyInput = withFormContext(FCI);

export const Checkbox = withFormContext(CK);

export const Input = withFormContext(FI);

export const Select = withFormContext(FS);

export const Textarea = withFormContext(FTA);
