import React from 'react';
import Wahanda from 'common/wahanda';
import Dialog from 'components/common/react-dialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import { Expandable } from 'components/common/Expandable';
import { StatusMessage } from './StatusMessage';

import { ImportStatus } from '../reducer';
import { FileImportStatus } from '../types';
import { ClientImportAnalytics } from '../tracking';

import style from './ClientsImportFinish.scss';

const lang = Wahanda.lang.clientsImport.addMultipleClients.finish;

export interface Props {
  importStatus: ImportStatus;
  canAccessAppCampaign: boolean;
  onClose: () => void;
  refreshClientsList: () => void;
  actions: {
    clientsImportReset: () => void;
    clientsImportToCampaignPrompt: () => void;
  };
}

export class ClientsImportFinish extends React.PureComponent<Props> {
  public render() {
    return (
      <Dialog
        dataTest="clients-import-finish-modal"
        title={this.getDialogTitle()}
        width={500}
        onClose={this.onClose}
        restrictHeight
        noContentPadding
      >
        <div className={style.container}>
          <StatusMessage />
          {this.renderMessages()}
        </div>
        {this.renderFooter()}
      </Dialog>
    );
  }

  private renderFooter = () => {
    const { canAccessAppCampaign } = this.props;
    return (
      <DialogFooter>
        <DialogFooterButton
          type="secondary"
          title={Wahanda.lang.shared.close}
          onClick={this.onClose}
        />
        {canAccessAppCampaign && (
          <DialogFooterButton
            dataTest="continue-to-campaign-prompt"
            title={lang.success.continue}
            onClick={this.onContinue}
          />
        )}
      </DialogFooter>
    );
  };

  private renderMessage = ({ message }) => (
    <div className={style.warningMessage} key={message}>
      {message}
    </div>
  );

  private renderMessages = () => {
    const { messages } = this.props.importStatus;

    if (!messages || !messages.length) {
      return null;
    }

    return (
      <div className={style.expandable}>
        <Expandable
          maxHeight={200}
          title={
            <div data-test="warnings-title">
              {Wahanda.Template.render(lang.warnings, {
                count: messages.length,
              })}
            </div>
          }
        >
          <div data-test="warnings-children">{messages.map(this.renderMessage)}</div>
        </Expandable>
      </div>
    );
  };

  private getDialogTitle = (): string | undefined => {
    const { status } = this.props.importStatus;

    switch (status) {
      case FileImportStatus.FINISHED_SUCCESS:
      case FileImportStatus.FINISHED_WITH_WARNINGS:
        return lang.clientsAdded;
      default:
    }
  };

  private onClose = () => {
    this.props.actions.clientsImportReset();
    this.props.refreshClientsList();
    this.props.onClose();
  };

  private onContinue = () => {
    ClientImportAnalytics.trackContinueToCampaignPromptClicked();
    this.props.actions.clientsImportToCampaignPrompt();
  };
}
