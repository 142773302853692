import React from 'react';
import Wahanda from 'common/wahanda';
import { MonthlySalesReportAnalytics } from 'common/analytics';
import { LineChart } from 'components/common/LineChart';
import style from './style.scss';

const SERVICE_NAMES = {
  seriesService: Wahanda.lang.reports.sales.dailySalesReport.charts.legend.serviceSales,
  seriesProduct: Wahanda.lang.reports.sales.dailySalesReport.charts.legend.productSales,
  seriesVoucher: Wahanda.lang.reports.sales.dailySalesReport.charts.legend.voucherSales,
};
interface IMonthlySalesReportGraphProps extends React.HTMLAttributes<Element> {
  data: {
    month?: any;
    serviceGrossTotal?: number;
    productGrossTotal?: number;
    voucherGrossTotal?: number;
  }[];
}

export default class MonthlySalesReportGraph extends React.Component<
  IMonthlySalesReportGraphProps,
  {}
> {
  getChartProps = () => {
    const serviceSalesData = [];
    const productSalesData = [];
    const voucherSalesData = [];
    const months = [];
    const monthFormat = Wahanda.lang.reports.sales.monthlySalesReport.charts.dateFormat;
    for (let i = 0; i < this.props.data.length; i += 1) {
      const row = this.props.data[i];
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      months.push(row.month.format(monthFormat));
      // @ts-expect-error ts-migrate(2345) FIXME: Type 'undefined' is not assignable to type 'never'... Remove this comment to see the full error message
      serviceSalesData.push(row.serviceGrossTotal);
      // @ts-expect-error ts-migrate(2345) FIXME: Type 'undefined' is not assignable to type 'never'... Remove this comment to see the full error message
      productSalesData.push(row.productGrossTotal);
      // @ts-expect-error ts-migrate(2345) FIXME: Type 'undefined' is not assignable to type 'never'... Remove this comment to see the full error message
      voucherSalesData.push(row.voucherGrossTotal);
    }
    const { seriesService, seriesProduct, seriesVoucher } = SERVICE_NAMES;
    const tooltipTextBySeriesName = {};
    tooltipTextBySeriesName[seriesService] =
      Wahanda.lang.reports.sales.monthlySalesReport.charts.tooltip.serviceSales;
    tooltipTextBySeriesName[seriesProduct] =
      Wahanda.lang.reports.sales.monthlySalesReport.charts.tooltip.productSales;
    tooltipTextBySeriesName[seriesVoucher] =
      Wahanda.lang.reports.sales.monthlySalesReport.charts.tooltip.voucherSales;
    return {
      xAxisData: months,
      tooltipTotalString: Wahanda.lang.reports.sales.monthlySalesReport.charts.tooltip.total,
      tooltipTextBySeriesName,
      yAxisFormatter: (value) => Wahanda.Currency.formatFinancial(value),
      series: [
        {
          name: seriesVoucher,
          data: voucherSalesData,
          areaColor: '#a3df8b',
          color: '#65c93e',
        },
        {
          name: seriesProduct,
          data: productSalesData,
          areaColor: '#ff9d88',
          color: '#ff5c39',
        },
        {
          name: seriesService,
          data: serviceSalesData,
          areaColor: '#66d0d5',
          color: '#00b0b9',
        },
      ],
      actions: {
        onLegendSelectChanged: this.trackLegendToggle,
      },
    };
  };

  trackLegendToggle = (params) => {
    const { name, selected } = params;
    const toggled = selected[name];
    switch (name) {
      case SERVICE_NAMES.seriesService:
        MonthlySalesReportAnalytics.trackServiceSalesLegendClick(toggled);
        break;
      case SERVICE_NAMES.seriesProduct:
        MonthlySalesReportAnalytics.trackProductSalesLegendClick(toggled);
        break;
      case SERVICE_NAMES.seriesVoucher:
        MonthlySalesReportAnalytics.trackVoucherSalesLegendClick(toggled);
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div className={style.graphContainer}>
        <div className={style.graph}>
          <LineChart {...this.getChartProps()} />
        </div>
      </div>
    );
  }
}
