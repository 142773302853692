/* eslint-disable func-names */
import { xhr } from 'common/xhr';
import isFunction from 'lodash/isFunction';
import apiUrl from 'common/api-url';

(function () {
  const APPOINTMENT_STATUS_CHECKEDOUT = 'CP';
  const APPOINTMENT_STATUS_CREATED = 'CR';
  const APPOINTMENT_STATUS_CANCELLED = 'CC';
  const APPOINTMENT_STATUS_NOSHOW = 'NS';

  const BaseCollection = BackboneEx.Collection.Base;
  const AppointmentGroup = BaseCollection.extend(
    {
      model: App.Models.Appointment,
      data: null,

      initialize(models, options) {
        this.data = options || {};
        if (this.data.id) {
          this.id = this.data.id;
        }
      },

      /**
       * A very simplistic "set" implementation.
       *
       * Note that it differs A LOT from the Backbone.Model's one.
       *
       * @param values
       */
      set(values) {
        _.extend(this.data, values);
        return this;
      },

      url() {
        const id = this.id;
        const url = id ? apiUrl('APPOINTMENT_GROUP', { id }) : apiUrl('APPOINTMENTS');

        return url;
      },

      comparator(a, b) {
        return a.getStartDate().getTime() - b.getStartDate().getTime();
      },

      parse(data) {
        this.data = _.omit(data, 'appointments');
        this.id = this.data.id;

        return data.appointments;
      },

      add(...args) {
        BaseCollection.prototype.add.apply(this, args);

        const self = this;
        this.each(function (appt) {
          appt.linkWithGroup(self);
        });

        return this;
      },

      isPackageType() {
        return this.data.type === AppointmentGroup.TYPE_PACKAGE;
      },

      /**
       * Add IDs to appointment models after save
       */
      setAppointmentIdsAfterSave(appts) {
        const findId = (forAppointment) => {
          const apptData = forAppointment.toJSON();
          const match = appts.find(
            (data) =>
              apptData.appointmentDate === data.appointmentDate &&
              apptData.startTime === data.startTime &&
              apptData.offerId === data.offerId,
          );

          return (match && match.id) || null;
        };

        this.each((appt) => {
          const id = findId(appt);
          if (id) {
            appt.set('id', id);
          }
        });
      },

      correctDates(correctedDate) {
        this.each((appt) => {
          appt.set('appointmentDate', correctedDate);
        });
      },

      correctValues(correctValues) {
        this.each((appt) => {
          const correctAppt = correctValues.find((cAppt) => cAppt.id === appt.id);
          appt.set(correctAppt);
        });
      },

      /**
       * A very simplistic "save" implementation.
       *
       * @return Promise
       */
      save() {
        let data;
        const sharedApptProps = {
          appointments: this.getAppointmentForSave(),
          notes: this.data.notes,
          recurrence: this.data.recurrence,
          optionId: this.data.optionId,
        };

        if (!this.id) {
          data = {
            appointmentGroups: [
              {
                ...sharedApptProps,
                serviceId: this.data.offerId,
              },
            ],
            venueCustomerId: this.getAppointmentForSave()[0].venueCustomerId,
          };
        } else {
          data = {
            ...sharedApptProps,
            notifyConsumer: this.data.notifyConsumer,
            offerId: this.data.offerId,
          };
        }

        return xhr
          .doJQueryAjax({
            url: this.url(),
            type: this.id ? 'put' : 'post',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(data),
          })
          .done((saveData) => {
            const isNew = !this.id;
            if (isNew && saveData && saveData.id > 0) {
              this.data.id = saveData.id;
              this.id = saveData.id;
              this.setAppointmentIdsAfterSave(saveData.appointments);
            }
            const savedData = this.getAppointmentForSave();
            App.trigger(Wahanda.Event.APPOINTMENT_GROUP_SAVED, this, {
              isNew,
              apptsSaved: savedData,
            });
            this.trigger('saved');
          });
      },

      getAppointmentForSave() {
        return this.map((appt) => {
          return {
            // eslint-disable-next-line no-underscore-dangle
            ...appt._getSaveData(),
            serviceId: appt.get('offerId'),
          };
        });
      },

      getStatusText() {
        // eslint-disable-next-line no-underscore-dangle
        return App.Models.Appointment.getStatusText(this._getStatusCode());
      },

      getStatusClassName() {
        // eslint-disable-next-line no-underscore-dangle
        return App.Models.Appointment.getStatusClass(this._getStatusCode());
      },

      _getStatusCode() {
        if (this.isPackageType()) {
          return this.data.appointmentStatusCode;
        }
        // Works only for unconfirmed Multi-Services
        return this.at(0).get('appointmentStatusCode');
      },

      // @Deprecated
      getStatusClass() {
        return this.getStatusClassName();
      },

      getName() {
        if (this.isPackageType()) {
          return this.data.name;
        }
        let name;
        const appointment = this.at(0);
        const packageGroup = appointment.getLinkedPackageGroup();
        if (packageGroup) {
          name = packageGroup.getName();
        } else {
          name = appointment.getOfferName();
        }
        return Wahanda.Template.render(
          Wahanda.lang.calendar.appointments.multiServices.textTemplates.name,
          {
            name,
            count: this.getItemCount() - 1,
          },
        );
      },

      /**
       * Return item count for a multi-service group.
       *
       * An item is considered a PackageAppt Group or a Stand-Alone Appointment.
       *
       * @returns Number
       */
      getItemCount() {
        const groups = {};
        // This MultiSku group does not count!
        groups[this.id] = true;

        return this.reduce(function (memo, appt) {
          const inPackage = appt.get('appointmentGroupIds').length > 1;
          let returnedMemo = memo;
          if (!inPackage) {
            return returnedMemo + 1;
          }
          appt.eachGroup(function (groupId) {
            if (!groups[groupId]) {
              groups[groupId] = true;
              returnedMemo += 1;
            }
          });

          return returnedMemo;
        }, 0);
      },

      getOfferName() {
        return this.getName();
      },

      getDateCreated() {
        return Wahanda.Date.parse(this.data.created);
      },

      getStartDate() {
        return Wahanda.Date.createDate(this.data.appointmentDate, this.data.startTime);
      },

      getAppointmentDate() {
        return this.data.appointmentDate;
      },

      /**
       * Get the group duration in minutes.
       *
       * @param {bool} withAdditionalTime (optional) Should additional time be included? E.g. cleanup or processing.
       * @returns Integer
       */
      getDuration(withAdditionalTime) {
        let duration = 0;
        this.each(function (appt) {
          duration += appt.getDuration(withAdditionalTime);
        });
        return duration;
      },

      getRescheduleDuration() {
        let duration = 0;
        this.each(function (appt, index, list) {
          if (index === list.length - 1) {
            duration += appt.getDuration();
            duration += appt.getCleanupTimeLength();
          } else {
            duration += appt.getDuration(true);
          }
        });
        return duration;
      },

      /**
       * Get the formatted price.
       */
      getPriceText() {
        return Wahanda.Currency.getFormatted(this.data.price.amount);
      },

      isUnpaid() {
        // Proxy to the first Appointment
        return this.at(0).isUnpaid();
      },

      isCheckedOut() {
        return APPOINTMENT_STATUS_CHECKEDOUT === this.data.appointmentStatusCode;
      },

      hasCancellationPeriodPassed() {
        return this.at(0).hasCancellationPeriodPassed();
      },

      getCheckOut() {
        const totalCheckout = {
          totalAmount: 0,
          additionalAmount: 0,
          servicePrice: 0,
        };

        this.models.forEach(function (appointment) {
          const appointmentCheckout = appointment.get('checkout');
          totalCheckout.additionalAmount += appointmentCheckout.additionalAmount;
          totalCheckout.servicePrice += appointmentCheckout.serviceAmount;
          totalCheckout.totalAmount +=
            appointmentCheckout.additionalAmount + appointmentCheckout.serviceAmount;
        });
        totalCheckout.totalAmount = Wahanda.Currency.getFormatted(totalCheckout.totalAmount);
        totalCheckout.additionalAmount = Wahanda.Currency.getFormatted(
          totalCheckout.additionalAmount,
        );
        totalCheckout.servicePrice = Wahanda.Currency.getFormatted(totalCheckout.servicePrice);
        return totalCheckout;
      },

      isNoShow() {
        return APPOINTMENT_STATUS_NOSHOW === this.data.appointmentStatusCode;
      },

      isCancelled() {
        return APPOINTMENT_STATUS_CANCELLED === this.data.appointmentStatusCode;
      },

      isUnconfirmed() {
        if (this.isPackageType()) {
          return APPOINTMENT_STATUS_CREATED === this.data.appointmentStatusCode;
        }
        // For MULTI-SERVICE, a Group is unconfirmed when all of the appointments are unconfirmed.
        return this.at(0).isUnconfirmed();
      },

      isWidgetBooking() {
        return this.data.bookingActor === 'SUPPLIERS_CUSTOMER';
      },

      isWahandaBooking() {
        return this.data.bookingActor === 'CUSTOMER';
      },

      isCustomerEmailSet() {
        return this.data.customer && !!this.data.customer.emailAddress;
      },

      isConfirmationTriggered() {
        return this.data.groupConfirmationTriggered === true;
      },

      isValidForEmailNotifications() {
        return this.isCustomerEmailSet() && App.config.get('venue').consumerEmailNotifications;
      },

      isWalkIn() {
        return this.data.appointments[0].walkIn;
      },

      isPayAtVenue() {
        return !!this.data.payAtVenue;
      },

      isPrepaid() {
        return (this.isWahandaBooking() || this.isWidgetBooking()) && !this.isPayAtVenue();
      },

      getNotes() {
        return this.data.notes;
      },

      hasNotes() {
        return !!this.data.notes;
      },

      getTotalPrice() {
        return this.data.price?.amount;
      },

      getCustomerName() {
        if (this.data.customer) {
          return this.data.customer.name;
        }
        return '';
      },

      /**
       * Is this appointment in the past?
       *
       * @return boolean
       */
      isInThePast() {
        return this.getStartDate().getTime() < Wahanda.Date.createVenueDate().getTime();
      },

      createNew() {
        const clone = new this.constructor(
          _.map(this.models, function (appt) {
            const cloneAppt = appt.clone();
            return cloneAppt.createNew();
          }),
        );
        clone.id = null;
        clone.data = this.data;
        return clone;
      },

      canUpdateAppointmentGroup() {
        if (this.isCancelled()) {
          return false;
        }

        if (Wahanda.Permissions.editAnyCalendar()) {
          return true;
        }
        const currentEmployeeId = App.config.getAccountEmployeeId();
        const canEditOwnCalendar = Wahanda.Permissions.editOwnCalendar();
        const allAppointmentsHaveCurrentEmployeeAssigned = this.data.employeesAssignedToGroupAppointments.every(
          (employeeId) => employeeId === currentEmployeeId,
        );

        return canEditOwnCalendar && allAppointmentsHaveCurrentEmployeeAssigned;
      },

      getActionsValidity() {
        const canAccept = this.isUnconfirmed() && !this.isInThePast();
        const editable = this.canUpdateAppointmentGroup();
        // This returns true if and only if all of the appointments have TRUE set for an action's validity
        const getAllAppointmentActionValidity = _.bind(function (actionName) {
          return this.all(function (appt) {
            return appt.getActionsValidity()[actionName];
          });
        }, this);
        // This returns true if any of the appointments have TRUE set for an action's validity
        const getAnyAppointmentActionValidity = _.bind(function (actionName) {
          return this.any(function (appt) {
            return appt.getActionsValidity()[actionName];
          });
        }, this);
        const canNoShow =
          this.isInThePast() && editable && getAnyAppointmentActionValidity('canSetNoShow');
        const canShowDelete =
          this.id > 0 && editable && getAllAppointmentActionValidity('canShowDelete');
        const canDelete = this.id > 0 && editable && getAllAppointmentActionValidity('canDelete');
        const canShowReschedule = getAllAppointmentActionValidity('canShowReschedule') && editable;
        const posEnabled = App.config.get('venue').pointOfSaleEnabled;

        return {
          canAccept,
          canShowReject: canAccept,
          canReject: getAllAppointmentActionValidity('rejectPartOfMultiServiceOrder'),
          canPOSCheckout: posEnabled && this.canCheckout(),
          canBasicCheckout: !posEnabled && this.canCheckout(),
          canSetNoShow: canNoShow,
          canShowDelete,
          canDelete,
          canShowReschedule,
          canRebook: this.id > 0,
        };
      },

      canBeRescheduled() {
        return this.all(function (appt) {
          return appt.canBeRescheduled();
        });
      },

      canCheckout() {
        return !this.isUnconfirmed() && this.isInThePast() && !this.isCheckedOut();
      },

      getOrderSourceData() {
        const appt1 = this.at(0);
        if (appt1) {
          return appt1.getBookingActorData();
        }
        return null;
      },

      hasAppointmentsBelongingToMultiServices() {
        return this.any(function (appointment) {
          return appointment.get('appointmentGroupIds').length > 1;
        });
      },

      persistRebookingData() {
        // TODO: persist booking data
      },

      getStartTime() {
        return this.data.startTime;
      },

      getLast() {
        return this.at(this.length - 1);
      },

      getEndTime(withAdditionalTime) {
        return this.getLast().getEndTime(withAdditionalTime);
      },

      // Group actions

      /**
       * Confirm the Appointment Group
       *
       * @returns Promise
       */
      confirm() {
        const self = this;
        const isGroupConfirmation = this.getItemCount() > 1;
        const url = App.Api.wsVenueUrl(`/appointment-group/${this.id}/confirm.json`);
        if (isGroupConfirmation) {
          this.data.groupConfirmationTriggered = true;
        }
        return xhr
          .doJQueryAjax({
            url,
            type: 'post',
            dataType: 'json',
            contentType: 'application/json',
          })
          .done(function () {
            App.trigger(Wahanda.Event.APPOINTMENT_GROUP_CONFIRMED, self);
            self.trigger('confirmed');
          })
          .fail(function () {
            App.trigger(Wahanda.Event.APPOINTMENT_FORM_ERRORS);
            App.trigger(Wahanda.Event.APPOINTMENT_GROUP_CONFIRM_ERROR);
          });
      },

      /**
       * Reject the Appointment Group
       *
       * @param Object data { notes: String, notifyConsumer: Boolean }
       *
       * @returns Promise
       */
      reject(params) {
        const url = apiUrl('CANCEL_APPOINTMENT_GROUP', { id: this.id });
        const self = this;
        return xhr.doJQueryAjax({
          url,
          data: JSON.stringify(params.data),
          success() {
            App.trigger(Wahanda.Event.APPOINTMENT_GROUP_REJECTED, self);
            self.trigger('rejected');
          },
          dataType: 'json',
          type: 'POST',
          contentType: 'application/json',
        });
      },

      /**
       * Set this appointment group as no show.
       * @param Object options
       * > data
       * > success
       * > error
       * @return Promise
       */
      setNoShow(options) {
        const self = this;
        const oldSuccess = options.success;
        const extendedOptions = {
          ...options,
          success() {
            self.trigger('no-show');
            App.trigger(Wahanda.Event.APPOINTMENT_GROUP_SET_NOSHOW, self);

            self.persistRebookingData();

            if (isFunction(oldSuccess)) {
              oldSuccess.call(this);
            }
          },
        };

        return xhr.doJQueryAjax({
          url: apiUrl('NO_SHOW_APPOINTMENT_GROUP', { id: this.id }),
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            notifyConsumer: true,
            preventPaymentProtection: options.preventPaymentProtection,
          }),
          success: extendedOptions.success,
          error: extendedOptions.error,
        });
      },

      /**
       * Set this appointment group as deleted.
       *
       * @return Promise
       */
      destroy(options) {
        const self = this;
        return xhr.doJQueryAjax({
          url: apiUrl('CANCEL_APPOINTMENT_GROUP', { id: this.id }),
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(options.data),
          success() {
            self.trigger('deleted');
            App.trigger(Wahanda.Event.APPOINTMENT_GROUP_CANCELLED, self);
          },
        });
      },

      /**
       * Get sku id list for the Package Offer's choice.
       *
       * @return Array
       */
      getSkuIdList() {
        return this.map(function (appt) {
          return appt.get('skus')[0].skuId;
        });
      },

      formatAsModel(offersCollection) {
        const offer = offersCollection.get(this.data.offerId);
        let sku = offer.getPackageSku(this.getSkuIdList());

        if (!sku) {
          // The SKU does not exist any more in the Package.
          // Add an alternate SKU which will be shown in the SKU dropdown.
          sku = {
            id: null,
            name: this.map(function (appt) {
              return $.trim(`${appt.get('offerName')} ${appt.get('skuName')}`);
            }).join(', '),
          };
        }

        return this.formatAsModelWithData(offer.id, sku);
      },

      formatAsModelWithData(offerId, packageSku) {
        const firstAppt = this.at(0);
        return new App.Models.Appointment(
          _.extend(
            {},
            firstAppt.attributes,
            {
              offerId,
              skus: [
                {
                  skuId: packageSku.id,
                  skuName: packageSku.name,
                },
              ],
            },
            this.data.price,
          ),
        );
      },

      getIdHash() {
        return `ag:${this.id}`;
      },

      hasArchivedOffer(offersCollection) {
        const offer = offersCollection.get(this.data.offerId);

        return !offer || offer.isArchived();
      },
    },
    {
      TYPE_PACKAGE: 'PACKAGE',
    },
  );

  App.Collections.AppointmentGroup = AppointmentGroup;
})();
