import React, { useEffect, useRef, useState } from 'react';
import style from './FlipTabletBanner.scss';
import { FlipTabletIcon } from 'components/common/RichTextEditor/FlipTabletBanner/FlipTabletIcon';
import { useIsTablet } from 'utilities/hooks/useIsTablet';

export const FlipTabletBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const fullViewportHeight = useRef(0);
  const isTablet = useIsTablet();
  const setFullHeight = () => {
    const landscape = window.matchMedia('(orientation: landscape)').matches;
    if (landscape) {
      fullViewportHeight.current = window.innerHeight;
    }
  };

  const viewportHandler = (event) => {
    const viewport = event?.target;
    if (fullViewportHeight.current === 0) {
      setFullHeight();
    }
    const isKeyboardOpen = viewport.height < fullViewportHeight.current;
    if (isKeyboardOpen) {
      setIsVisible(true);
    } else if (viewport.height === fullViewportHeight.current) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    setFullHeight();
    window.visualViewport?.addEventListener('resize', viewportHandler);
    return () => {
      window.visualViewport?.removeEventListener('resize', viewportHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!(isTablet && isVisible)) {
    return null;
  }

  return (
    <div className={style.banner}>
      <FlipTabletIcon />
      <span>Flip for a better experience</span>
    </div>
  );
};
