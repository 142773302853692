import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { menuOfferTransformer } from 'common/transformers/DiscountMenu/';

import * as actions from './actions';
import { TreatmentList as Component } from './TreatmentList';

const mapDispatchToProps = (dispatch) =>
  Object.assign(
    {},
    {
      actions: bindActionCreators(actions, dispatch),
    },
  );

const mapStateToProps = ({ discountMenuItems: { menuItemGroups, menuItemOffers } }) => ({
  menuItemGroups: menuOfferTransformer(menuItemGroups, menuItemOffers),
  offersExist: menuItemOffers.length > 0,
});

export const TreatmentList = connect(mapStateToProps, mapDispatchToProps)(Component);
