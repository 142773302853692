(function () {
  App.Views.Forms.MenuOffer2.Pricing.Dated.Edit.Simple = BackboneEx.View.DynamicForm.extend({
    fieldPrefix: 'offer-pricing-',

    initialize: function () {
      var currency = App.config.get('currency').symbol;
      var html = Wahanda.Template.renderTemplate('menu-offer-pricing-edit-simple', {
        priceTitle: Wahanda.lang.menu.offer.pricing.price.replace('{{currency}}', currency),
        rrpTitle: Wahanda.lang.menu.offer.pricing.rrp.replace('{{currency}}', currency),
      });
      this.setElement(html);
      this.initMakeIdsUnique();

      // More-than validator
      this.$$('fullPriceAmount').attr(
        'more-than',
        '#offer-pricing-discountPriceAmount' + this._getUniqueSuffix(),
      );
    },

    render: function () {
      this.$$('fullPriceAmount').val(
        Wahanda.Number.formatFloatForInput(this.options.fullPriceAmount),
      );
      this.$$('discountPriceAmount').val(
        Wahanda.Number.formatFloatForInput(this.options.discountPriceAmount),
      );
    },

    getValues: function () {
      var price = this.$$('fullPriceAmount').val();
      var discounts = this.$$('discountPriceAmount').val();
      return {
        fullPriceAmount: parseFloat(price),
        discountPriceAmount: discounts >= 0 ? parseFloat(discounts) : null,
      };
    },
  });
})();
