import React from 'react';

import { VenueShiftsObject, WorkingHoursObject } from '../utils/types';
import { SCHEDULE_ROW_TYPE } from '../utils/constants';

import { ShiftRowCell } from '../ShiftRowCell';

interface Props {
  canEditEmployeeHours?: boolean;
  type: SCHEDULE_ROW_TYPE;
  gridClassName: string;
  workingHours: WorkingHoursObject[];
  onClick?: (param) => void;
  // redux
  canEditVenueHours: boolean;
  venue: VenueShiftsObject;
  isLoading: boolean;
}

export class ShiftRow extends React.PureComponent<Props> {
  private onClick = (workingHours: WorkingHoursObject, ele: any) => {
    const element = ele?.current;

    if (!(element instanceof Element)) {
      return;
    }

    if (this.props.onClick) {
      this.props.onClick({
        date: workingHours.date,
        popoverOffset: element?.getBoundingClientRect(),
        timeSlots: workingHours.timeSlots,
        type: workingHours.type,
      });
    }
  };

  public render() {
    const { workingHours } = this.props;
    return workingHours.map((workingDay, index) => (
      <ShiftRowCell
        gridClassName={this.props.gridClassName}
        key={index}
        index={index}
        workingHours={workingDay}
        onClick={this.onClick}
        type={this.props.type}
        venue={this.props.venue}
        isLoading={this.props.isLoading}
        canEditEmployeeHours={this.props.canEditEmployeeHours}
        canEditVenueHours={this.props.canEditVenueHours}
      />
    ));
  }
}
