import React from 'react';
import moment from 'common/moment';
import { MonthPicker, MonthPickerProps } from 'components/common/MonthPicker';
import SelectFilter from '../SelectFilter';
import style from './style.scss';

interface State {
  isOpen: boolean;
}

export interface Props {
  date: string;
  locale?: string;
  onMonthSelected: MonthPickerProps['onMonthSelected'];
}

export default class MonthPickerFilter extends React.PureComponent<Props, State> {
  private get locale() {
    const { locale } = this.props;
    if (!locale) {
      return 'en';
    }
    return locale;
  }

  private ref: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.ref = React.createRef();

    this.showMonthPicker = this.showMonthPicker.bind(this);
    this.hideMonthPicker = this.hideMonthPicker.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onDatePicked = this.onDatePicked.bind(this);
    this.renderMonthPicker = this.renderMonthPicker.bind(this);
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const { date } = this.props;
    const { isOpen } = this.state;
    return (
      <div ref={this.ref} className={style.monthFilter}>
        <SelectFilter
          isOpen={isOpen}
          text={moment(date).locale(this.locale).format('MMMM YYYY')}
          onClick={this.showMonthPicker}
        />
        {isOpen && this.renderMonthPicker()}
      </div>
    );
  }

  private renderMonthPicker() {
    const { date, locale } = this.props;
    return (
      <div className={style.monthPicker}>
        <MonthPicker date={date} onMonthSelected={this.onDatePicked} locale={locale} />
      </div>
    );
  }

  private handleClickOutside(event: MouseEvent) {
    if (this.ref.current && !this.ref.current.contains(event.target as Node)) {
      this.hideMonthPicker();
    }
  }

  private onDatePicked(date: string) {
    this.hideMonthPicker();
    this.props.onMonthSelected(date);
  }

  private hideMonthPicker() {
    this.setState({ isOpen: false });
  }

  private showMonthPicker() {
    this.setState({ isOpen: true });
  }
}
