import React from 'react';
import { map, toJs } from 'mori';
import ReactDialog from 'components/common/react-dialog';
import Wahanda from 'common/wahanda';
import Error from '../errors/error';

export const Types = {
  COLUMN: 'COLUMN',
  ROW: 'ROW',
};

interface IErrorDialogProps extends React.HTMLAttributes<Element> {
  dataTest?: string;
  errors?: any[];
  intro?: string;
  onClose?: (...args: any[]) => any;
  type?: any;
}

export class ErrorDialog extends React.Component<IErrorDialogProps, {}> {
  getErrors() {
    return toJs(map((error) => <Error {...error} key={error.errorName} />, this.props.errors));
  }

  render() {
    const { dataTest, type, onClose, intro } = this.props;
    const classes = {
      'confirmation-dialog': true,
      'errors-column': type === Types.COLUMN,
    };
    return (
      <ReactDialog
        dataTest={dataTest || 'error-modal'}
        title={Wahanda.lang.shared.error}
        width={480}
        classes={classes}
        onClose={onClose}
      >
        <div className="confirmation-dialog--text">
          <span className="intro error">{intro}</span>
          {this.getErrors()}
        </div>
      </ReactDialog>
    );
  }
}
