import React from 'react';
import Wahanda from 'common/wahanda';
import DiscountListItem from './DiscountListItem';
import style from './style.scss';

const lang = Wahanda.lang.menu.discounts.discountList;
type DiscountListProps = {
  venueId?: string;
  actions?: {
    updateDiscountRuleAction: (...args: any[]) => any;
    revertDiscountRuleAction: (...args: any[]) => any;
  };
  hasSaved: boolean;
  hasError: boolean;
  hasEditPermission: boolean;
  hasDeleteConfirmDialog: boolean;
  discountList: {
    id: number;
    name: string;
    active: boolean;
    lastMinuteDiscount?: {
      active: boolean;
    };
    offpeakDiscounts?: {
      active: boolean;
    };
    assignedMenuItems?: {
      id: number;
    }[];
  }[];
};
const DiscountList: React.SFC<DiscountListProps> = ({
  discountList,
  venueId,
  actions,
  hasSaved,
  hasError,
  hasEditPermission,
  hasDeleteConfirmDialog,
}) => (
  <div className={style.discountList}>
    <div className={style.discountListHead}>
      <h2>{lang.title}</h2>
      <span className={style.status}>{lang.status}</span>
    </div>
    <ul className={style.discountListBody}>
      {discountList.map(
        ({
          id,
          name,
          lastMinuteDiscount,
          offpeakDiscounts,
          active,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'appliedTreatmentCount' does not exist on... Remove this comment to see the full error message
          appliedTreatmentCount,
          assignedMenuItems,
        }) => (
          <DiscountListItem
            key={`discount-list-item-${id}`}
            id={id}
            name={name}
            lastMinuteDiscount={lastMinuteDiscount}
            offpeakDiscounts={offpeakDiscounts}
            assignedMenuItems={assignedMenuItems}
            number={appliedTreatmentCount}
            active={active}
            venueId={venueId}
            actions={actions}
            // @ts-expect-error ts-migrate(2769) FIXME: Property 'hasSaved' does not exist on type 'Intrin... Remove this comment to see the full error message
            hasSaved={hasSaved}
            hasError={hasError}
            hasEditPermission={hasEditPermission}
            hasDeleteConfirmDialog={hasDeleteConfirmDialog}
          />
        ),
      )}
    </ul>
  </div>
);

export default DiscountList;
