import React, { Component } from 'react';
import DialogContentBox from 'components/common/dialog/DialogContentBox';
import EmployeeCategories from './EmployeeCategories/container';
import Services from './Services/container';

interface IPricingAndServicesTabProps extends React.HTMLAttributes<Element> {
  categoriesVisible?: boolean;
  actions: {
    requestCategoriesAction: (...args: any[]) => any;
  };
  requestCategoriesAction?: any;
}

export default class PricingAndServicesTab extends Component<IPricingAndServicesTabProps, {}> {
  static defaultProps = {
    categoriesVisible: false,
  };

  componentDidMount() {
    this.props.actions.requestCategoriesAction();
  }

  render() {
    const { categoriesVisible } = this.props;
    return (
      <div>
        {categoriesVisible && (
          <DialogContentBox separated>
            <EmployeeCategories />
          </DialogContentBox>
        )}
        <DialogContentBox separated>
          <Services />
        </DialogContentBox>
      </div>
    );
  }
}
