import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'reduxStore/common/employees/actions';
import ExternalCalendarTab from './index';

const mapStateToProps = ({
  employees: {
    employee: { externalCalendarUri, linkedExternalSalonName, calLinked },
    loading,
    serverErrorName,
  },
}) => ({
  externalCalendarUri,
  linkedExternalSalonName,
  serverErrorName,
  loading,
  calLinked,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ externalCalendarUri: string; linkedExterna... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(ExternalCalendarTab);
