/* eslint-disable func-names */
(function () {
  /**
   * Wahanda's on-boarding wizzard.
   */
  Wahanda.Wizzard = {
    wizard: null,
    /**
     * Initializes the Wizzard. If a Wizzard window is required to be shown - it will pop up automatically.
     */
    initialize: function () {
      // eslint-disable-next-line no-underscore-dangle
      App.on('app:loaded', Wahanda.Wizzard._runWizard);
    },

    /**
     * @access static
     */
    _runWizard: function () {
      if (
        App.isRestrictedMode() ||
        (App.config.get('venue').listedOnMarketplace &&
          App.config.get('venue').termsConditionsAgreed)
      ) {
        App.isLoaded = true;
        App.trigger(Wahanda.Event.ONB_WIZARD_DONE);
        return;
      }
      if (!Wahanda.Wizzard.wizard) {
        Wahanda.Wizzard.wizard = new App.Views.OnboardingWizzard();
      }
      const wizard = Wahanda.Wizzard.wizard;

      if (
        (App.isMasquerading() && window.location.search.indexOf('show-onboarding=true') === -1) ||
        App.isTaxAuditor()
      ) {
        wizard.setMode(wizard.MODE_FETCH);
      } else {
        wizard.setMode(wizard.MODE_FULL);
      }

      wizard.start();
    },
  };
})();
