import { call, put } from 'redux-saga/effects';
import * as cache from 'reduxStore/cacheService';
import { receiveMenuItemsAction, setCheckedDataAction } from './actions';

// eslint-disable-next-line import/prefer-default-export
export function* getMenuDataRequested(action) {
  const { assignedTreatments, filterType, includedServiceTypes } = action;
  const data = yield call(cache.getFiltered, {
    filterType: filterType || undefined,
    includedServiceTypes: includedServiceTypes || undefined,
  });

  yield put(receiveMenuItemsAction(data));

  yield put(setCheckedDataAction({ assignedTreatments }));
}
