export const menuOfferTransformer = (groups, offers) =>
  groups.map((group) => ({
    ...group,
    offers: offers.filter((offer) => offer.groupId === group.id),
  }));

export const menuOfferCheckedTransformer = (offers) =>
  offers.filter((offer) => offer.checked).map((offer) => offer.id);

export const menuServiceTransformer = (groups, offers) =>
  groups.map((group) => ({
    ...group,
    services: offers.filter((offer) => offer.groupId === group.id),
  }));
