import React from 'react';
import { Discounts } from 'components/common/Icon';
import { Checkbox } from 'components/common/Checkbox';
import { Badge } from 'components/common/Badge';
import style from './style.scss';

interface IMenuItemProps extends React.HTMLAttributes<Element> {
  actions: {
    setCheckedMenuItemAction: (...args: any[]) => any;
  };
  offer: {
    id: number;
    checked?: boolean;
    name?: string;
    featured: boolean;
    discountRuleName?: string;
    discountRuleId?: number;
    distributionChannels?: any[];
    visibleFrom?: string;
    visibleTo?: string;
  };
  discountId?: number;
  discountName?: string;
  discountBadgeVisible?: boolean;
}

export default class MenuItem extends React.PureComponent<IMenuItemProps, {}> {
  static defaultProps = {
    discountId: null,
    discountName: null,
    discountBadgeVisible: false,
  };

  handleChange = () => {
    const {
      actions: { setCheckedMenuItemAction },
      offer: { id, checked },
    } = this.props;
    setCheckedMenuItemAction(id, !checked);
  };

  renderDistributionChannelBadges() {
    const { offer } = this.props;
    return (
      <React.Fragment key={offer.id}>
        {!offer.distributionChannels?.find((channel) => channel === 'CUSTOMER') && (
          <Badge
            className={style.badge}
            type="muted"
            isLight
            text={Wahanda.lang.menu.offer.missingChannel.CUSTOMER}
          />
        )}
        {!offer.distributionChannels?.find((channel) => channel === 'SUPPLIERS_CUSTOMER') && (
          <Badge
            className={style.badge}
            type="muted"
            isLight
            text={Wahanda.lang.menu.offer.missingChannel.SUPPLIERS_CUSTOMER}
          />
        )}
      </React.Fragment>
    );
  }

  renderFeatured() {
    const { offer } = this.props;
    if (!offer.featured) {
      return null;
    }
    return (
      <Badge
        key={`${offer.id}-featured`}
        className={style.badge}
        type="success"
        text={Wahanda.lang.menu.info.featured}
      />
    );
  }

  renderRange() {
    const {
      offer,
      offer: { visibleFrom: strFrom, visibleTo: strTo },
    } = this.props;
    if (!strFrom && !strTo) {
      return null;
    }
    const from = strFrom ? Wahanda.Date.createDate(strFrom) : null;
    const to = strTo ? Wahanda.Date.createDate(strTo) : null;
    const dateInfo = Wahanda.Date.compareRangeToNow(from, to);
    let dateString = null;
    let dateClass = null;
    if (dateInfo.isPast) {
      dateString = Wahanda.lang.menu.offer.listing.ended;
      // @ts-expect-error ts-migrate(2322) FIXME: Type '"critical"' is not assignable to type 'null'... Remove this comment to see the full error message
      dateClass = 'critical';
    } else if (dateInfo.isFuture) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
      dateString = `${Wahanda.lang.shared.from} ${Wahanda.Date.formatToDefaultDate(from)}`;
      // @ts-expect-error ts-migrate(2322) FIXME: Type '"future"' is not assignable to type 'null'.
      dateClass = 'future';
    } else if (dateInfo.isCurrent && to != null) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
      dateString = `${Wahanda.lang.shared.until} ${Wahanda.Date.formatToDefaultDate(to)}`;
      // @ts-expect-error ts-migrate(2322) FIXME: Type '"current"' is not assignable to type 'null'.
      dateClass = 'current';
    }
    if (dateString != null) {
      return (
        <span key={`${offer.id}-range`} className={`label label-${dateClass}`}>
          {dateString}
        </span>
      );
    }
    return null;
  }

  renderServiceBadges() {
    return [this.renderFeatured(), this.renderDistributionChannelBadges(), this.renderRange()];
  }

  renderDiscountBadge() {
    const {
      offer: { discountRuleName, discountRuleId, checked },
      discountId,
      discountName,
    } = this.props;
    if (discountRuleId && discountRuleId !== discountId) {
      if (checked) {
        return (
          <span className={style.pullRight}>
            <Badge
              className={style.badge}
              type="muted"
              isLight
              icon={<Discounts size="small" />}
              text={discountRuleName}
            />
            <span className={style.arrow} />
            <Badge
              className={style.badge}
              type="info"
              isLight
              icon={<Discounts size="small" />}
              text={discountName}
            />
          </span>
        );
      }
      return (
        <span className={style.pullRight}>
          <Badge
            className={style.badge}
            type="info"
            isLight
            icon={<Discounts size="small" />}
            text={discountRuleName}
          />
        </span>
      );
    }
    return null;
  }

  render() {
    const { offer, discountBadgeVisible } = this.props;
    return (
      <li className={style.item}>
        <Checkbox
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'string'.
          name={offer.id}
          label={offer.name}
          className={style.label}
          onChange={this.handleChange}
          checked={!!offer.checked}
        >
          {this.renderServiceBadges()}
        </Checkbox>
        {discountBadgeVisible && this.renderDiscountBadge()}
      </li>
    );
  }
}
