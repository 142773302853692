import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import Wahanda from 'common/wahanda';
import classnames from 'classnames';
import { Redirect, generatePath } from 'react-router-dom';

import { Notice } from 'components/common/Notice';
import { Button } from 'components/common/Button';
import Loader from 'components/common/Loader';

import { VENUE_OPENING_CAMPAIGN } from 'components/marketing/EmailCampaign/CampaignList';
import EmailTemplate, {
  EmailTemplateContent,
} from 'components/marketing/EmailCampaign/CampaignPreview/EmailTemplate';
import { fieldConfig } from 'components/marketing/EmailCampaign/CampaignPreview/config';
import PATHS from 'components/marketing/Router/paths';

import { Analytics } from '../tracking';
import { SmsPreview, PushNotificationPreview } from './Channels';

import style from './BackToBusiness.scss';

export interface Props {
  currentReach?: number;
  emailReach?: number;
  smsReach?: number;
  emailTemplate?: EmailTemplateContent;
  alreadySent?: boolean;
  successfullySent: boolean;
  isSending: boolean;
  backToBusinessInitStatusRequest: () => void;
  getBackToBusinessEmailTemplateRequest: () => void;
  sendBackToBusinessCampaignRequest: () => void;
  emailTemplateReset: () => void;
}

const lang = Wahanda.lang.marketing.backToBusiness.preview;

export const BackToBusiness: React.FC<Props> = ({
  currentReach,
  emailReach,
  smsReach,
  emailTemplate,
  alreadySent,
  successfullySent,
  isSending,
  backToBusinessInitStatusRequest,
  getBackToBusinessEmailTemplateRequest,
  sendBackToBusinessCampaignRequest,
  emailTemplateReset,
}) => {
  React.useEffect(() => {
    if (isUndefined(currentReach)) {
      backToBusinessInitStatusRequest();
    }
    emailTemplateReset();
    getBackToBusinessEmailTemplateRequest();
  }, []);

  if (isUndefined(currentReach) || isEmpty(emailTemplate) || isSending) {
    return (
      <div className={style.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  if (alreadySent) {
    return <Redirect to={{ pathname: PATHS.CAMPAIGN_SELECTION }} />;
  }

  if (successfullySent) {
    return (
      <Redirect
        to={{
          pathname: generatePath(PATHS.CAMPAIGN_SUCCESS, {
            templateId: VENUE_OPENING_CAMPAIGN,
          }),
        }}
      />
    );
  }

  const handleSendCampaignButton = () => {
    Analytics.trackCampaignSendButtonClicked();
    sendBackToBusinessCampaignRequest();
  };

  return (
    <div className={style.wrapper}>
      <div className={style.wrapperInner}>
        <div className={style.section}>
          <h1 className={style.heading}>{lang.title}</h1>
          <p className={style.smallParagraph}>{lang.description}</p>
          <div className={style.attentionWrapper}>
            <div className={style.attention}>
              <Notice message={lang.attentionMenu} />
            </div>
            <div className={style.attention}>
              <Notice message={lang.attentionWorkingHours} />
            </div>
          </div>
        </div>
        <div className={style.section}>
          <div className={classnames(style.sectionChannel)}>
            <div className={style.sectionInfo}>
              <h2 className={style.heading}>{lang.emailSection.title}</h2>
              <p
                className={style.largeParagraph}
                data-test="email-client-reach-description"
                dangerouslySetInnerHTML={{
                  __html: Wahanda.Template.render(lang.emailSection.description, {
                    currentReach: emailReach,
                  }),
                }}
              />
            </div>
            <div className={style.channelPreview}>
              <EmailTemplate isEditable={false} content={emailTemplate!} config={fieldConfig} />
            </div>
          </div>
        </div>
        <div className={style.section}>
          <div className={classnames(style.sectionChannel)}>
            <div className={style.sectionInfo}>
              <h2 className={style.heading}>{lang.smsSection.title}</h2>
              <p
                className={style.largeParagraph}
                data-test="sms-client-reach-description"
                dangerouslySetInnerHTML={{
                  __html: Wahanda.Template.render(lang.smsSection.description, {
                    currentReach: smsReach,
                  }),
                }}
              />
            </div>
            <div className={style.channelPreview}>
              <SmsPreview />
            </div>
          </div>
        </div>
        <div className={style.section}>
          <div className={classnames(style.sectionChannel)}>
            <div className={style.sectionInfo}>
              <h2 className={style.heading}>{lang.pushNotificationSection.title}</h2>
              <p className={style.largeParagraph}>{lang.pushNotificationSection.description}</p>
            </div>
            <div className={style.channelPreview}>
              <PushNotificationPreview />
            </div>
          </div>
        </div>
        <div className={style.sectionFooter}>
          <div className={classnames(style.sectionFooterInner)}>
            <div>
              <div className={style.heading}>{lang.footerSection.title}</div>
              <p
                className={style.largeParagraph}
                data-test="footer-description"
                dangerouslySetInnerHTML={{
                  __html: Wahanda.Template.render(lang.footerSection.description, {
                    currentReach,
                  }),
                }}
              />
            </div>
            <div className={style.footerButtonWrapper}>
              <Button
                dataTest="send-campaign-button"
                label={lang.footerSection.button}
                onClick={handleSendCampaignButton}
                disabled={currentReach === 0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
