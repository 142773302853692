;(function() {
	/**
	 * Helper jQuery plugin for aligning two tables: one header one (on top of the second one)
	 * and the content one (below the header one).
	 * 
	 * The plugin has to be run on a parent element, e.g. a DIV. It takes the first found table
	 * as the header table, second one - as the content table. If the TICK needs to be shown,
	 * the has-tick is added to the headers table. If not, the class is removed.
	 */
	$.fn.tableAlign = function() {
		var $tables = this.find('table');
		var $headersTable = $tables.eq(1);
		var $contentTable = $tables.eq(2);
		
		$headersTable.toggleClass('has-tick', $headersTable.width() > $contentTable.width());
		
		return this;
	};
}());
