import React, { Component } from 'react';
import Wahanda from 'common/wahanda';
import {
  Table,
  TableColumnHeader,
  TableRow,
  TableBody,
  TableHeader,
  TableData,
} from 'components/common/Table';
import ProductSupplierDialog from '../ProductSupplierDialog/container';

const lang = Wahanda.lang.menu.productSupplier;
interface IProductSuppliersListProps extends React.HTMLAttributes<Element> {
  suppliers?: {
    id: number;
    name: string;
    contactPersonName?: string;
    phone?: string;
    email?: string;
    selectedProductsIds: number[];
  }[];
}
type ProductSuppliersListState = {
  supplierDialogVisible?: boolean;
  clickedSupplierId?: any;
};
class ProductSuppliersList extends Component<
  IProductSuppliersListProps,
  ProductSuppliersListState
> {
  static defaultProps = {
    suppliers: [],
  };

  state = {
    supplierDialogVisible: false,
    clickedSupplierId: null,
  };

  onTableDataRowClick = (id) => {
    this.setState({
      supplierDialogVisible: true,
      clickedSupplierId: id,
    });
  };

  onSupplierDialogClose = () => {
    this.setState({
      supplierDialogVisible: false,
      clickedSupplierId: null,
    });
  };

  renderSupplierDialog = (id) => (
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
    <ProductSupplierDialog id={id} onClose={this.onSupplierDialogClose} />
  );

  renderTableHeader = () => (
    <TableHeader>
      <TableRow>
        <TableColumnHeader>{lang.supplierName}</TableColumnHeader>
        <TableColumnHeader>{lang.contactPerson}</TableColumnHeader>
        <TableColumnHeader>{lang.phone}</TableColumnHeader>
        <TableColumnHeader>{lang.email}</TableColumnHeader>
        <TableColumnHeader>{lang.assignedProducts}</TableColumnHeader>
      </TableRow>
    </TableHeader>
  );

  renderTableBody = () => {
    const { suppliers } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return <TableBody>{suppliers.map(this.renderTableDataRow)}</TableBody>;
  };

  renderTableDataRow = ({ id, name, contactPersonName, phone, email, selectedProductsIds }) => {
    const selectedProductsCount = selectedProductsIds.length;
    return (
      <TableRow key={id} onClick={() => this.onTableDataRowClick(id)}>
        <TableData>{name}</TableData>
        <TableData>{contactPersonName}</TableData>
        <TableData>{phone}</TableData>
        <TableData>{email}</TableData>
        <TableData>{selectedProductsCount > 0 && selectedProductsCount}</TableData>
      </TableRow>
    );
  };

  render() {
    const { supplierDialogVisible, clickedSupplierId } = this.state;
    return (
      <div>
        <Table>
          {this.renderTableHeader()}
          {this.renderTableBody()}
        </Table>
        {supplierDialogVisible && this.renderSupplierDialog(clickedSupplierId)}
      </div>
    );
  }
}

export default ProductSuppliersList;
