import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import { Plus } from 'components/common/Icon';

import { ShiftWorkingHourType, VenueShiftsObject, WorkingHoursObject } from '../utils/types';
import { SCHEDULE_ROW_TYPE } from '../utils/constants';
import { getVenueDayWorkingHours, workingHoursRange } from '../utils/helpers';
import style from './ShiftRowCell.scss';

interface Props {
  canEditEmployeeHours?: boolean;
  canEditVenueHours: boolean;
  gridClassName: string;
  index: number;
  isClear?: boolean;
  isLoading: boolean;
  onClick?: (param, index) => void;
  type: SCHEDULE_ROW_TYPE;
  showMonthDate?: boolean;
  venue: VenueShiftsObject;
  workingHours: WorkingHoursObject;
}
const LANG = Wahanda.lang.settings.shifts;

export const ShiftRowCell = React.memo(
  ({
    canEditEmployeeHours,
    canEditVenueHours,
    gridClassName,
    index,
    isClear,
    isLoading,
    onClick,
    type,
    showMonthDate,
    venue,
    workingHours,
  }: Props) => {
    const renderEmptyCell = () => {
      return <div className={gridClassName} key={index} />;
    };
    const cell = React.useRef<HTMLDivElement>(null);
    if (isLoading) {
      return renderEmptyCell();
    }
    const { timeSlots, date, schedule } = workingHours;
    let gridClassNames = '';
    let emptyContent = '';
    const boundActRunClick = () => onClick && onClick(workingHours, cell);
    const isSalonOpen = !!getVenueDayWorkingHours(date, venue.workingHours).timeSlots.length;
    const timeRange = workingHoursRange(timeSlots);
    let cellContent: JSX.Element | JSX.Element[] | null;
    cellContent = timeRange;
    const isCustomDay = workingHours.type === ShiftWorkingHourType.CUSTOM;
    const isScheduleDay = workingHours.type === ShiftWorkingHourType.SCHEDULE;
    const isCustomDayOff = (isCustomDay || isScheduleDay) && !timeRange;
    const CellLink = ({ text }) => (
      <a
        className={style.link}
        role="link"
        tabIndex={0}
        onClick={boundActRunClick}
        data-test={`shift-cell-${index}`}
      >
        {text}
      </a>
    );
    switch (type) {
      case SCHEDULE_ROW_TYPE.VENUE:
        emptyContent = LANG.closed;
        gridClassNames = gridClassName;
        gridClassNames = classnames(gridClassName, {
          [style.isCustomDay]: isCustomDay,
        });
        if (canEditVenueHours) {
          cellContent = <CellLink text={timeRange || emptyContent} />;
        }
        break;
      case SCHEDULE_ROW_TYPE.STYLIST:
        gridClassNames = classnames(gridClassName, {
          [style.isClosed]: !isSalonOpen,
          [style.isCustomDay]: isCustomDay && !isCustomDayOff,
          [style.isCustomDayOff]: isCustomDayOff && isSalonOpen,
          [style.isScheduledDay]: isScheduleDay && isSalonOpen,
          [style.isScheduleEnd]: schedule?.validTo === workingHours.date,
          [style.isScheduleStart]: schedule?.validFrom === workingHours.date,
        });
        if (canEditEmployeeHours) {
          cellContent = (
            <CellLink
              text={isCustomDayOff && isSalonOpen ? <Plus className={style.iconPlus} /> : timeRange}
            />
          );
        }
        break;
      default:
        emptyContent = '';
        break;
    }
    const monthDate =
      workingHours.date === moment(workingHours.date).startOf('month').formatApiDateString()
        ? moment(workingHours.date).formatDayMonth()
        : moment(workingHours.date).format('DD');
    const isToday = moment(workingHours.date).isSame(new Date(), 'day');
    const monthDateClassName = classnames(style.monthDate, {
      [style.isClear]: isClear,
    });
    return (
      <div data-hj-suppress className={gridClassNames} key={index} ref={cell}>
        {showMonthDate && (
          <span className={monthDateClassName}>
            {isToday ? (
              <div className={style.todayBadge}>{Wahanda.lang.date.today}</div>
            ) : (
              monthDate
            )}
          </span>
        )}
        {cellContent || emptyContent}
      </div>
    );
  },
);
