(function () {
  App.Views.Dialog.Booking.Appointment = BackboneEx.View.Base.extend({
    events: {
      'click .a-confirm-appointment': 'confirm',
      'click .a-reject-appointment': 'reject',
    },

    initialize: function () {},

    render: function () {
      var appointment = this.model.get('appointment');
      var date = Wahanda.Date.createDate(appointment.appointmentDate, appointment.startTime);
      var fullDate = Wahanda.Date.formatToDefaultFullDate(date);
      var apptModel = new App.Models.Appointment(appointment);

      this.$('.weekday').text(Wahanda.lang.date.weekdays[date.getDay()]);
      this.$('.day').text(date.getDate());
      this.$('.time').text(fullDate.time);
      this.$('.month').text(Wahanda.lang.date.months[date.getMonth()]);
      this.$('.year').text(date.getFullYear());
      this.$('.duration').text(
        Wahanda.Time.getDifference(appointment.startTime, appointment.endTime),
      );
      this.$('.status').text(this.getStatusText()).addClass(this.getStatusClass());
      this.$('.consumer-name').text(apptModel.getConsumerName());

      this.$el.toggleClass(
        'has-actions',
        this.model.isAppointmentUnconfirmed() && !this.model.isAppointmentInThePast(),
      );

      if (this.model.isCancelled()) {
        this.renderAttribute('cancellation-reason', this.model.getCancellationReason());
      }
    },

    /**
     * Return text representation of models confirmation state.
     *
     * @return String
     */
    getStatusText: function () {
      return App.Models.Appointment.getStatusText(
        this.model.get('appointment').appointmentStatusCode,
      );
    },

    /**
     * Return CSS class representing state.
     *
     * @return String
     */
    getStatusClass: function () {
      return App.Models.Appointment.getStatusClass(
        this.model.get('appointment').appointmentStatusCode,
      );
    },

    getAppointmentModel: function () {
      return new App.Models.Appointment(
        _.extend({ venueId: App.getVenueId() }, this.model.get('appointment')),
      );
    },

    // Events

    confirm: function () {
      var self = this;
      var appt = this.getAppointmentModel();
      var bookingView = this.options.bookingView;

      bookingView.disableForm();

      appt.confirm(
        function () {
          bookingView.close();
        },
        function () {
          self.$('.a-confirm-appointment').errorTip(Wahanda.lang.shared.savingFailed);
          bookingView.enableForm();
        },
      );
    },

    reject: function () {
      var appt = this.getAppointmentModel();
      var bookingView = this.options.bookingView;

      var rejectView = new App.Views.Calendar.AppointmentReject({
        model: appt,
      });
      rejectView.render();
      rejectView.open();

      appt.on('rejected', function () {
        bookingView.close();
      });
    },
  });
})();
