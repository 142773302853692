Wahanda.Analytics = {
  /**
   * Starts the analytics tracking.
   *
   * This is the place to put analytics tracing code for events, happening in the app.
   */
  start: function () {
    const trackEvent = BackboneEx.Tool.Analytics.trackEvent;
    const trackPageView = BackboneEx.Tool.Analytics.trackPageView;
    const e = Wahanda.Event;
    App.on(e.CALENDAR_VIEW, () => {
      trackPageView('calendar');
    })
      .on(e.CALENDAR_SWIPE, () => {
        trackEvent('calendar', 'swipe');
      })
      .on(e.CALENDAR_NEW_APPOINTMENT, () => {
        trackEvent('calendar', 'click', 'slot');
        // trackEvent('calendar-action-sheet', 'click', 'new-appointment');
      })
      .on(e.APPOINTMENT_DRAG_STARTED, () => {
        trackEvent('calendar', 'drag-and-drop', 'appointment');
      })
      .on(e.TIME_BLOCK_DRAG_STARTED, () => {
        trackEvent('calendar', 'drag-and-drop', 'block');
      })
      .on(e.HOME_TABLE_FILTERED, (data) => {
        const { entityTypes, dateFrom, dateTo } = data;
        trackEvent('bookings', 'edit', 'filter', {
          type: entityTypes,
          dateFrom,
          dateTo,
        });
      })
      .on(e.APPOINTMENT_DRAG_AND_DROP_SUBMITED, () => {
        trackEvent('appointment', 'update', 'drag-and-drop');
      })
      .on(e.CHECK_OUT_PRODUCT_ADDED, () => {
        trackEvent('product-line', 'update', 'add');
      })
      .on(e.PRODUCT_REMOVED, () => {
        trackEvent('product-line', 'update', 'remove');
      })
      .on(e.CHECK_OUT_VOUCHER_ADDED, () => {
        trackEvent('voucher-line', 'update', 'add');
      })
      .on(e.CHECK_OUT_VOUCHER_REMOVED, () => {
        trackEvent('voucher-line', 'update', 'remove');
      })
      .on(e.LIVECHAT_TOGGLE, (attrs) => {
        trackEvent('connect', 'contact', 'life-chat');
      })
      .on(e.CALENDAR_EDIT_FORM_TAB_SWITCH, (tab) => {
        trackEvent('calendar', 'click', 'appointment-tab-swich');
      })
      .on(e.CALENDAR_EDIT_FORM_OPENED, (tab) => {
        if (tab === 'block') {
          trackEvent('calendar', 'view', 'block');
        }

        if (tab === 'appointment') {
          trackEvent('calendar', 'view', 'appointment');
        }
      })
      .on(e.CALENDAR_RESCHEDULE_MODE_START, () => {
        trackEvent('calendar', 'click', 'reschedule-started');
      })
      .on(e.CALENDAR_RESCHEDULE_MODE_CANCEL, () => {
        trackEvent('calendar', 'click', 'reschedule-cancelled');
      })
      .on(e.CALENDAR_RESCHEDULE_MODE_FINISH, (data) => {
        trackEvent('calendar', 'submit', 'reschedule');
      })
      .on(e.APPOINTMENT_DRAG_REVERTED, () => {
        trackEvent('appointment', 'validation', 'drag-and-drop-cancelled');
      })
      .on(e.MENU_OFFER_SAVED, (offer, data) => {
        const type = data.customStatus;

        switch (type) {
          case 'archive':
            trackEvent('services', 'update', 'menu-offer', 'archive');
            break;
          case 'active':
            trackEvent('services', 'update', 'menu-offer', 'active');
            break;
          default:
            trackEvent('services', 'update', 'menu-offer', 'update');
            break;
        }
      })
      .on(e.MENU_FILTER_CHANGE, ({ filter }) => {
        const id = filter.split('/').reverse()[0];
        trackEvent('services', 'edit', 'status-filter', id);
      })
      .on(e.MENU_SUB_FILTER_CHANGE, (data) => {
        trackEvent('services', 'edit', 'event-type-filter');
      })
      .on(e.EMPLOYEE_ADDED, (data = {}) => {
        trackEvent('connect-employee', 'submit', 'employee-added', data);
      })
      .on(e.EMPLOYEE_EDITED, (data) => {
        trackEvent('connect-employee', 'update', 'employee-edited', data);
      })
      .on(e.EMPLOYEE_ARCHIVED, (data) => {
        trackEvent('connect-employee', 'update', 'employee-archived', data);
      })
      .on(e.EMPLOYEE_UNARCHIVED, (data) => {
        trackEvent('connect-employee', 'update', 'employee-unarchived', data);
      })
      .on(e.EMPLOYEE_CATEGORY_CREATED, (data) => {
        trackEvent('connect-employee', 'update', 'employee-category-created');
      })
      .on(e.EMPLOYEE_CATEGORY_DELETED, (data) => {
        trackEvent('connect-employee', 'update', 'employee-category-deleted');
      })
      .on(e.UDV_LEARN_MORE_LINK_CLICKED, () => {
        trackEvent(
          'connect-appointment',
          'click',
          'flamingo-learn-more-clicked-repeat-appointment',
        );
      })
      .on(e.UDV_NEW_LEARN_MORE_LINK_CLICKED, () => {
        trackEvent('connect-appointment', 'click', 'flamingo-learn-more-clicked-new-appointment');
      })
      .on(e.EVOUCHER_REDEMPTION_OPEN, () => {
        trackEvent('calendar', 'view', 'evoucher-redemption-dialog');
      })
      .on(e.HEADER_SEARCH_RESULTS_OPENED, () => {
        trackEvent('connect', 'view', 'header-search-results-shown');
      });
  },
};
