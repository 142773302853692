import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class History extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-history', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M13,2 C18.5228475,2 23,6.4771525 23,12 C23,17.5228475 18.5228475,22 13,22 C10.3472898,22 7.93582476,20.9671069 6.14584577,19.2815615 L7.27461008,17.5874833 C8.72740705,19.0759109 10.7557872,20 13,20 C17.418278,20 21,16.418278 21,12 C21,7.581722 17.418278,4 13,4 C8.581722,4 5,7.581722 5,12 L7.06574145,12 C7.34188383,12 7.56574145,12.2238576 7.56574145,12.5 C7.56574145,12.5987125 7.53652244,12.6952163 7.4817666,12.7773501 L5.74641674,15.37877 C5.74657198,15.3791027 5.74672725,15.3794354 5.74688254,15.3797681 L4.49331429,17.2596482 C4.49310834,17.2593159 4.49290241,17.2589835 4.4926965,17.258651 L4.41602515,17.3759623 C4.26284892,17.6057266 3.95241425,17.6678136 3.7226499,17.5146373 C3.66772376,17.4780199 3.62059228,17.4308884 3.58397485,17.3759623 L0.518233399,12.7773501 C0.365057169,12.5475858 0.427144103,12.2371511 0.656908448,12.0839749 C0.739042206,12.029219 0.835546053,12 0.934258546,12 L3,12 L3,12 C3,6.4771525 7.4771525,2 13,2 Z M14,6 L14,11.5857864 L16.7071068,14.2928932 L15.2928932,15.7071068 L12,12.4142136 L12,6 L14,6 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(History);
