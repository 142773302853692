import React from 'react';
import { ChannelSalesReportAnalytics } from 'common/analytics';
import reportDetailsUrlConnect from '../common/reportDetailsUrlConnect';
import { deserialiseFilters } from '../common/urlSelectors';
import { BaseCsvDownloadLink } from '../common/BaseCsvDownloadLink';

type CsvDownloadLinkProps = {
  fromDate: any;
  toDate: any;
};
const CsvDownloadLink: React.SFC<CsvDownloadLinkProps> = (props) => {
  const url = `reports/sales/channels/csv/?from=${props.fromDate.format(
    'YYYY-MM-DD',
  )}&to=${props.toDate.format('YYYY-MM-DD')}`;
  return (
    <BaseCsvDownloadLink
      relativeReportUrl={url}
      onClick={ChannelSalesReportAnalytics.trackCsvDownloadClick}
    />
  );
};

export { CsvDownloadLink };

const mapUrlToProps = (report, reportParams) => deserialiseFilters(reportParams, false, false);

// @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
export default reportDetailsUrlConnect(mapUrlToProps)(CsvDownloadLink);
