(function($) {
    /**
     * jQuery plugin for showing arrows when swiping.
     *
     * @param Object|String options Hash of options. Possbie values:
     * > onSwipe - is called when a swipe is detected. Passed parameters is the direction, 'right' or 'left'.
     * > canSwipeToDirection - if set, it should return if swiping left or right is available.
     *
     *   If `options` is a string, it's interpreted as a command to an already initialized swipeArrows instance.
     *
     * @return SwipeArrows instance (prevents jQuery chaining!)
     */
    $.fn.swipeArrows = function(options) {
        var inst = this.data('swipeArrows');
        if (!inst) {
            inst = new SwipeArrows(this, options || {});
        }

        if (typeof options === "string") {
            inst[options]();
        }

        return inst;
    };

    var MIN_SCROLL = 5;

    function canScrollToDirection (element, direction) {
        var scrollLeft = element.scrollLeft;
        var check;

        if (direction === 'left') {
            check = scrollLeft;
        } else {
            check = element.scrollWidth - scrollLeft - element.clientWidth;
        }

        return check > MIN_SCROLL;
    }

    function hasHorizontalScrolling (element) {
        return element.scrollWidth - element.clientWidth > MIN_SCROLL;
    }

    /**
     *
     * @param {jQuery} $el
     * @param {Object} options hash of
     *  {String} scrollElement The element scrolling will happen on.
     * @constructor
     */
    var SwipeArrows = function($el, options) {
        this.$element = $el;
        this.scrollElement = options.scrollElement;
        this.options = options;
        this.touchStartPoint = null;
        // How much of the shown arrow is visible?
        this.arrowShownPercentage = 0;
        this.arrowPercentageToAcceptAsSwipe = 80;
        this.moveMultiplicator = 0.7;
        // TODO: think of calculating this automagically.
        this.arrowWidth = 38;
        this.minimumScroll = 30;
        // Cached arrows
        this.$aLeft  = null;
        this.$aRight = null;

        this.lastScrollDirection = null;
        // Bind for event listening
        this.bindForTouch();
        this.appendArrowsHtml();

        this.paused = false;
        this.enabled = false;
        this.moveStarted = false;
        this.hasHorizontalScrolling = false;

        this.directionChecks = {};

        this.$element.data('swipeArrows', this);
    };

    SwipeArrows.prototype = {
        bindForTouch: function() {
            var self = this;

            this.$element.on('touchstart.swipeArrows', function(event) {
                if (self.paused) {
                    return;
                }

                self.touchStartPoint = getEventPoint(event);
                self.enabled = true;
                self.lastScrollDirection = null;
                self.hasHorizontalScrolling = hasHorizontalScrolling(self.$element.find(self.scrollElement)[0]);
            });
            this.$element.on('touchmove.swipeArrows', function(event) {
                if (!self.enabled || self.paused) {
                    return;
                }

                var point = getEventPoint(event);
                var xAxisDiff = self.getAxisDiff(point, 'x');
                var direction = (xAxisDiff < 0 ? 'left' : 'right');

                if (!self.moveStarted) {
                    if (canScrollToDirection(self.$element.find(self.scrollElement)[0], direction)) {
                        // There is DOM scrolling in the calendar.
                        // Don't start the swipe logic until the next move is started as the UX might
                        // be impaired otherwise.
                        self.enabled = false;
                        return;
                    }

                    var yDistance = self.getPointDistanceOnAxis(point, 'y');
                    if (yDistance >= self.minimumScroll) {
                        // It seems the user wants to scroll the content up or down.
                        self.enabled = false;
                        return;
                    }
                    var xDistance = self.getPointDistanceOnAxis(point, 'x');
                    if (xDistance < self.minimumScroll) {
                        // Not enough scrolled. Don't start showing the arrows yet.
                        return;
                    }
                    self.moveStarted = true;
                }

                // Prevent default so that DOM is not frozen and the animation can happen.
                event.preventDefault();

                // Check is the action can be started
                if (self.options.canSwipeToDirection) {
                    if (typeof self.directionChecks[direction] === 'undefined') {
                        self.directionChecks[direction] = !!self.options.canSwipeToDirection(direction);
                    }
                    if (self.directionChecks[direction] === false) {
                        self.hideArrows();
                        self.touchStartPoint = point;
                        return;
                    }
                }

                // TODO: compensate for minimumScroll. Should not jump right to the pos.
                var absDiff = Math.abs(xAxisDiff) * self.moveMultiplicator;
                var rightX  = leftX = '';
                var diff    = Math.min(
                    absDiff - self.minimumScroll - self.arrowWidth,
                    0
                );

                ('right' === direction) ? (rightX = diff) : (leftX = diff);

                self.$aLeft.css('left', leftX);
                self.$aRight.css('right', rightX);

                if (
                  self.hasHorizontalScrolling &&
                  (self.lastScrollDirection && self.lastScrollDirection !== direction)
                ) {
                    // Prevent arrows logic so that the user can go back within the same gesture
                    self.enabled = false;
                }

                if (diff === 0) {
                    // This will help the user "hide" the arrow without going all the way back to touchStart point
                    var sign = xAxisDiff < 0 ? -1 : 1;
                    self.touchStartPoint.x = point.x + xAxisDiff * self.moveMultiplicator + sign * self.minimumScroll;
                }

                self.lastScrollDirection = direction;
                self.arrowShownPercentage = (self.arrowWidth + diff) / self.arrowWidth * 100;
            });
            this.$element.on('touchend.swipeArrows', function(event) {
                // if the scroll is long enough, trigger the appropriate callback
                if (self.enabled && self.arrowShownPercentage >= self.arrowPercentageToAcceptAsSwipe && self.options.onSwipe) {
                    self.options.onSwipe(self.lastScrollDirection);
                }

                self.touchStartPoint = null;
                self.arrowShownPercentage = 0;
                self.directionChecks = {};

                // TODO: animate, fast
                if (self.enabled) {
                    if (self.lastScrollDirection === "right") {
                        self.$aRight.css('right', -self.arrowWidth);
                    } else {
                        self.$aLeft.css('left', -self.arrowWidth);
                    }
                }
                self.enabled = self.moveStarted = false;
            });
        },

        pause: function () {
            this.paused = true;
            this.enabled = false;
            this.hideArrows();
        },

        resume: function () {
            this.paused = false;
        },

        hideArrows: function() {
            this.$aRight.css('right', -this.arrowWidth);
            this.$aLeft.css('left', -this.arrowWidth);
        },

        appendArrowsHtml: function() {
            var $html = $(
                '<div class="swipe-back b-swipeArrows">&nbsp;</div>'
                + '<div class="swipe-fwd b-swipeArrows">&nbsp;</div>'
            );

            this.$aLeft  = $html.filter('.swipe-back');
            this.$aRight = $html.filter('.swipe-fwd');

            this.$element.append($html);
        },

        destroy: function() {
            // remove events and arrows from DOM
            this.$element.off('.swipeArrows');
            this.$element.find('.b-swipeArrows').remove();
            // remove data
            this.$element.removeData('swipeArrows');
        },

        /**
         * Returns the absolute distance in pixels between the points on an axis.
         *
         * @param Object point
         * @param String axis x or y
         * @return int
         */
        getPointDistanceOnAxis: function (point, axis) {
            return Math.abs( this.getAxisDiff(point, axis) );
        },

        getAxisDiff: function(point, axis) {
            return this.touchStartPoint[axis] - point[axis];
        }
    };

    function getEventXPos(event) {
        var pos = 0;
        try {
            return event.originalEvent.touches[0].pageX;
        } catch (e) {
        }
        return pos;
    };

    function getEventPoint(event) {
        try {
            var touch = event.originalEvent.touches[0];
            return {
                x: touch.pageX,
                y: touch.pageY
            };
        } catch (e) {
        }

        return { x: 0, y: 0 };
    };
}(jQuery));
