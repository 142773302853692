import React from 'react';
import classnames from 'classnames';

import legacyStyle from './Button.scss';
import newStyle from './Button.newLook.scss';

export interface ButtonProps {
  colour?: 'default' | 'pos' | 'alert' | 'plain' | 'help';
  dataTest?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  icon?: React.ReactNode;
  justify?: boolean;
  label?: string;
  onClick?: () => void;
  opensInNewWindow?: boolean;
  size?: 'small' | 'medium' | 'large';
  suffixIcon?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'hollow';
  newLook?: boolean;
}

export const ButtonSpacer = ({ size = 'medium' }: { size?: 'small' | 'medium' }) => {
  return <div className={legacyStyle[`${size}ButtonSpacer`]} />;
};

export const Button = ({
  colour = 'default',
  dataTest,
  disabled,
  fullWidth,
  href,
  icon,
  justify,
  label,
  onClick,
  opensInNewWindow,
  size = 'medium',
  suffixIcon,
  type = 'button',
  variant = 'primary',
  newLook = false,
}: ButtonProps) => {
  const style = newLook ? newStyle : legacyStyle;
  const className = classnames(
    style[variant],
    style[colour],
    style[size],
    fullWidth && style.fullWidth,
    {
      [style.noPadding]: icon && !label,
      [style.hasIcon]: icon,
      [style.justify]: (icon && suffixIcon && fullWidth) || justify,
    },
  );
  const hasIconLeft = icon && label && style.marginLeft;
  const hasIconRight = suffixIcon && label && style.marginRight;
  const labelClassName = label && (hasIconLeft || hasIconRight);

  const content = (
    <React.Fragment>
      {icon}
      {label && <span className={labelClassName}>{label}</span>}
      {suffixIcon && suffixIcon}
    </React.Fragment>
  );

  const sharedProps = {
    'data-test': dataTest,
    className,
    onClick,
    disabled,
  };

  const linkProps = opensInNewWindow
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};

  return href ? (
    <a {...sharedProps} href={href} {...linkProps}>
      {content}
    </a>
  ) : (
    /* eslint-disable react/button-has-type */
    <button {...sharedProps} type={type}>
      {content}
    </button>
    /* eslint-enable react/button-has-type */
  );
};

Button.displayName = 'Button';
