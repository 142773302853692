import React from 'react';
import ReactDOM from 'react-dom';
import Impressum from 'components/marketing/PartnerSite/Impressum/container';
import storeBuilder from 'reduxStore/store';
import { Provider } from 'react-redux';

const store = storeBuilder();

function render(node) {
  ReactDOM.render(
    <Provider store={store}>
      <Impressum />
    </Provider>,
    node,
  );
}

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

export default {
  render,
  destroy,
};
