import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions';
import ProductSuppliersTab from './index';

const mapStateToProps = ({ productSuppliers: { items, loading } }) => ({
  suppliers: items,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSuppliersTab);
