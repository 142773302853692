import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class PersonDetails extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-person-details', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path
          d="M23,19 L21,19 C21,17.5705052 20.3982381,16.2401676 19.360375,15.2975259 L20.7050408,13.8170281 C22.1560028,15.134868 23,17.000724 23,19 Z M16,12 L16,14 C13.2385763,14 11,16.2385763 11,19 L9,19 C9,15.1340068 12.1340068,12 16,12 Z M7,15 L7,17 L1,17 L1,15 L7,15 Z M16,5 C18.4852814,5 20.5,7.01471863 20.5,9.5 C20.5,11.9852814 18.4852814,14 16,14 C13.5147186,14 11.5,11.9852814 11.5,9.5 C11.5,7.01471863 13.5147186,5 16,5 Z M8,11 L8,13 L1,13 L1,11 L8,11 Z M16,7 C14.6192881,7 13.5,8.11928813 13.5,9.5 C13.5,10.8807119 14.6192881,12 16,12 C17.3807119,12 18.5,10.8807119 18.5,9.5 C18.5,8.11928813 17.3807119,7 16,7 Z M9,7 L9,9 L1,9 L1,7 L9,7 Z"
          transform="matrix(-1 0 0 1 24 0)"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(PersonDetails);
