import { ActionUnionTypes } from 'reduxStore/actionCreator';
import {
  COVID_UNLIMIT_OPERATION_REQUEST,
  COVID_UNLIMIT_OPERATION_SUCCESS,
  SHOW_VENUE_CLOSING_DIALOG,
  SHOW_VENUE_OPEN_CONFIRMATION_DIALOG,
  COVID_BANNER_HIDE_ALL_DIALOGS,
  VENUE_CLOSING_REQUEST,
  VENUE_CLOSING_SUCCESS,
  VENUE_CLOSING_FAILURE,
  RESET_COVID_BANNER_STATE,
} from 'reduxStore/actionsConst';

import * as actions from './actions';

export interface CovidBannerState {
  justSuccessfullyReopened: boolean;
  unlimitLoading: boolean;
  isVenueClosingDialogShown: boolean;
  isVenueOpenConfirmationDialogShown: boolean;
  submittingCloseRequest: boolean;
}

export const initialState: CovidBannerState = {
  justSuccessfullyReopened: false,
  unlimitLoading: false,
  isVenueClosingDialogShown: false,
  isVenueOpenConfirmationDialogShown: false,
  submittingCloseRequest: false,
};

export const covidBanner = (
  state = initialState,
  action: ActionUnionTypes<typeof actions>,
): CovidBannerState => {
  switch (action.type) {
    case COVID_UNLIMIT_OPERATION_REQUEST:
      return {
        ...state,
        unlimitLoading: true,
      };
    case COVID_UNLIMIT_OPERATION_SUCCESS:
      return {
        ...state,
        unlimitLoading: false,
        justSuccessfullyReopened: true,
      };
    case SHOW_VENUE_OPEN_CONFIRMATION_DIALOG:
      return {
        ...state,
        isVenueOpenConfirmationDialogShown: true,
      };
    case SHOW_VENUE_CLOSING_DIALOG:
      return {
        ...state,
        isVenueClosingDialogShown: true,
      };
    case COVID_BANNER_HIDE_ALL_DIALOGS:
      return {
        ...state,
        isVenueClosingDialogShown: false,
        isVenueOpenConfirmationDialogShown: false,
      };
    case VENUE_CLOSING_REQUEST:
      return {
        ...state,
        submittingCloseRequest: true,
      };
    case VENUE_CLOSING_SUCCESS:
      return {
        ...state,
        submittingCloseRequest: false,
        isVenueClosingDialogShown: false,
      };
    case VENUE_CLOSING_FAILURE:
      return {
        ...state,
        submittingCloseRequest: false,
        isVenueClosingDialogShown: true,
      };
    case RESET_COVID_BANNER_STATE:
      return initialState;
    default:
      return state;
  }
};
