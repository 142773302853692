/**
 * Base for all Models.
 *
 * It saves only the defined attributes, not the whole structure.
 */

const xhrToPromise = jqXhr => new Promise((resolve, reject) => {
  jqXhr.done(resolve).fail(reject);
});

BackboneEx.Model.Base = Backbone.Model.extend({
	/**
	 * Which attributes must be saved to the server.
	 * @var Array|Function
	 */
	attributesToSave: [],

	/**
	 * The structure of this model.
	 * It should be as a hash of name: type.
	 */
	structure: null,

	filters: null,

	/**
	 * Sets the attributes to be saved.
	 *
	 * @param Array attrs
	 */
	setAttributesToSave: function(attrs) {
		this.attributesToSave = attrs;
	},

	/**
	 * Converts attributes to JSON format and sets correct headers.
	 * After a successful save, executed the _afterSave callback.
	 *
	 * @see Backbone.Model.prototype.save
	 */
	save: function() {
		var self      = this;
		// Handle both `("key", value)` and `({key: value})` -style calls.
		var params    = Array.prototype.slice.call(arguments);
		var optionsId = 2;
		if (_.isObject(params[0]) || params[0] == null) {
			optionsId = 1;
		}
		params[optionsId]             = params[optionsId] ? _.clone(params[optionsId]) : {};
		params[optionsId].data        = JSON.stringify(this._getSaveData());
		params[optionsId].contentType = 'application/json';

		var xhr = Backbone.Model.prototype.save.apply(this, params);
		if (_.isFunction(self._afterSave)) {
			xhr.done(function() {
				self._afterSave();
			});
		}
		return xhr;
	},

  /**
	 * Issues a save, returning a Native Promise. To be used with async calls.
	 *
   * @param ...args The arguments which will be proxyed to Backbone.save().
   * @returns {Promise<any>}
   */
	async saveWithPromise(...args) {
		return xhrToPromise(
			this.save(...args)
		);
	},

	_getSaveData: function() {
		// Save only those attributes that are defined in attributesToSave
		var data  = {};
		var attrs = _.isFunction(this.attributesToSave) ? this.attributesToSave() : this.attributesToSave;
		for (var i = 0, len = attrs.length; i < len; i++) {
			var attr = attrs[i];
			var key = null;
			var type = null;
			if (typeof attr == 'string'){
				key = attr;
			}
			else if (typeof attr == 'object'){
				key = attr.name;
				type = attr.type;
			}
			if (null === type && this.structure && typeof this.structure[key] === "string") {
				type = this.structure[key];
			}

			if (key){
				var value = this.get(key);
				if (value != null){
					if (type == 'integer'){
						if (value === ''){
							value = null;
						}
						if (typeof value == 'string'){
							value = parseInt(value, 10);
						}
					}
					if (type == 'float'){
						if (value == ''){
							value = null
						}
						if (typeof value == 'string'){
							value = parseFloat(value);
						}
					}
					if (type == 'boolean') {
						value = !!value;
					}
				}
				data[key] = value;
			}
		}
		return data;
	},

	/**
	 * Is overriden so that after fetching the data the model`s state is set to up-to-date.
	 * It fixes inconsistencies with the overriden `set` method.
	 *
	 * @see Backbone.Model.prototype.fetch
	 */
	fetch: function(opts) {
		var xhr = Backbone.Model.prototype.fetch.apply(this, arguments);
		var options = opts || {};
		// .fetch() returns jqXHR with promise interface, so we can bind to the success event
		if (!options.silent) {
			var self = this;
			xhr.success(function() {
				self.change();
			})
		}
		return xhr;
	},

	async fetchWithPromise(...args) {
		return xhrToPromise(
			this.fetch(...args)
		);
	},

	/**
	 * Sets the "wait" flag so that only on successful response from server the model is removed from any collections
	 * which does contain it.
	 */
	destroy: function(opts) {
		opts = opts || {};
		if (typeof opts.wait != "boolean") {
			opts.wait = true;
		}
		return Backbone.Model.prototype.destroy.call(this, opts);
	},

	/**
	 * Reverts this model to the last good state (to the last "change" event).
	 */
	revert: function() {
		this.set(this.previousAttributes());
	},

	/**
	 * Returns a copy of the requested attribute.
	 *
	 * It should be a Object (Array, Object).
	 *
	 * @param string name
	 * @return Object
	 */
	getCopy: function(name) {
		return _.extend(true, {}, this.get(name));
	},

	/**
	 * Set the filters for URI of this model.
	 *
	 * @param Object filters
	 * @param Object options (optional) Options:
	 * > replace : Should this call replace all filters, or merge with existing ones? Defaults to true.
	 */
	setUrlFilters: function(filters, options) {
		var replace = options ? options.replace : true;

		if (replace) {
			this.filters = filters;
		} else {
			this.filters = _.extend(
				this.filters || {},
				filters
			);
		}
	},

	/**
	 * Convert the set filters to string.
	 *
	 * @return String
	 */
	getUrlFilterString: function() {
		var filter = '';
		_.each(this.filters, function(value, name) {
			if (value == null) {
				// Skip null-like values
				return;
			}
			if (filter) {
				filter += '&';
			}
			filter += encodeURIComponent(name) + '=';

			if (_.isArray(value)) {
				value = value.join(',');
			}
			filter += encodeURIComponent(value);
		});
		return filter;
	}
}, {
    /**
     * `getAttributesToSave` implementation returning all attributes currently set on the model.
     *
     * @returns Array
     */
    getAllAttributesToSave: function() {
        return _.keys(this.attributes);
    }
});
