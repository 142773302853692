import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  offerDialogChangeEmployeeAction,
  offerDialogSelectAllEmployeesAction,
  offerDialogDeselectAllEmployeesAction,
} from 'components/menu/actions';
import { hasMultipleEmployeeCategories } from 'components/menu/selectors';
import OfferEmployees from './index';

const mapStateToProps = ({ menu: { menuOffer } }) =>
  Object.assign(
    {},
    {
      allEmployees: menuOffer.allEmployees,
      offerEmployees: menuOffer.allEmployees.filter((employee) =>
        menuOffer.offerDialogEmployees.includes(employee.id),
      ),
      initialOfferEmployees: menuOffer.allEmployees.filter((employee) =>
        menuOffer.offerDialogInitialEmployees.includes(employee.id),
      ),
      employeeCategoriesCollection: menuOffer.employeeCategoriesCollection,
      disabled: menuOffer.offerDialogReadonly,
      useEmployeeCategoryPricing: menuOffer.offerDialogUseEmployeeCategoryPricing,
      escapeWithSkuPricing: menuOffer.offerDialogEscapeWithSkuPricing,
      employeeCategoryPricingChangeable: menuOffer.offerDialogEmployeeCategoryPricingChangeable,
      hasEmployeeCategoryPricing: hasMultipleEmployeeCategories(
        menuOffer.offerDialogEmployees,
        menuOffer.allEmployees,
      ),
    },
  );

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      offerDialogSelectAllEmployeesAction,
      offerDialogDeselectAllEmployeesAction,
      offerDialogChangeEmployeeAction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(OfferEmployees);
