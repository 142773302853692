/**
 * Collection for App.Models.MenuGroup.
 */
App.Collections.TreatmentTypes = BackboneEx.Collection.Base.extend({
  model: App.Models.MenuGroup,
  urlTemplate: null,

  comparator: function (a, b) {
    return a.get('name').localeCompare(b.get('name'));
  },
});
