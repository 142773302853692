import { UDVCovidFormLink as Component } from './UDVCovidFormLink';
import { connect } from 'react-redux';
import { UDVCovidFormLinkState } from './store/reducer';

const mapStateToProps = (state) => {
  const udvCovidFormLink: UDVCovidFormLinkState = state.calendar.udvCovidFormLink;

  return {
    isVisible: udvCovidFormLink.covidConsentFormsEnabled,
  };
};

export const UDVCovidFormLink = connect(mapStateToProps)(Component);
