/**
 * Settings.
 */
App.Views.Settings.Integrations = App.Views.Form.extend({
  isVisible: false,

  initialize: function () {
    this.on('visible', this.onVisible, this).on('hidden', this.onHidden, this);

    this.listenTo(this.model, 'fetched', this.renderIfVisible);
    App.on(Wahanda.Event.APP_VENUE_CHANGED, this.onVenueChanged.bind(this));
  },

  onVisible: function () {
    this.isVisible = true;
    this.injectReactView();
  },

  canNavigateAway: function () {
    return (
      App.ES6.Initializers.ExternalSalonIntegration.canNavigateAway() ||
      confirm(Wahanda.lang.settings.switchTabQuestion)
    );
  },

  onHidden: function () {
    this.destroyReactView();
    this.isVisible = false;
  },

  injectReactView: function () {
    App.ES6.Initializers.ExternalSalonIntegration.render(this.el);
  },

  destroyReactView: function () {
    App.ES6.Initializers.ExternalSalonIntegration.destroy(this.el);
  },

  renderIfVisible: function () {
    if (this.isVisible) {
      this.injectReactView();
    }
  },

  onVenueChanged: function () {
    if (this.isVisible) {
      this.destroyReactView();
      this.injectReactView();
    }
  },
});
