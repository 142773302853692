import React from 'react';
import { TreatmentNameWithValue, hasValue } from './TreatmentNameWithValue';
import { TreatmentName } from './TreatmentName';
import style from '../SelectServiceDialog.scss';

const hasValueInName = (value, name) => hasValue(value) && name.toLowerCase().includes(value);

interface Props {
  list: {
    uuid: string;
    name: string;
  }[];
  onTreatmentClick: (...args: any[]) => any;
  value?: string;
}

export const Treatments = ({ list, onTreatmentClick, value = '' }: Props) => (
  <ul className={style.treatments}>
    {list.map(({ uuid, name }) =>
      hasValueInName(value, name) ? (
        <TreatmentNameWithValue
          key={uuid}
          onClick={onTreatmentClick}
          uuid={uuid}
          name={name}
          value={value}
        />
      ) : (
        <TreatmentName key={uuid} onClick={onTreatmentClick} uuid={uuid} name={name} />
      ),
    )}
  </ul>
);
