/**
 * Sortable view.
 * 
 * It utilises the jQueryUI.sortable interface.
 * 
 * When rendering/initializing, call
 *    this.setupSotrable(container for sorting)
 * options must be defined in
 *    sortableOptions
 * parameter. To handle update, receive and remove events, override
 *    onSortUpdate, onSortReceive, onSortRemove
 * methods respectively.
 * 
 * On cross-list moving, the "update" event fires two times: first, in the removed list, second - in the added.
 * To check for this, use isEventFromCurrentList(ui, node) method.
 */
BackboneEx.View.Sortable = Backbone.View.extend({
	sortableOptions: {},
	
	/**
	 * Sets up sorting.
	 */
	setupSortable: function(target) {
		var self = this;
		// Events are execured in this order: remove, receive, update
		var opts = _.extend({}, this.sortableOptions || {}, {
			update: function(event, ui) {
				self.onSortUpdate(event, ui, this);
			},
			receive: function(event, ui) {
				self.onSortReceive(event, ui, this);
			},
			remove: function(event, ui) {
				self.onSortRemove(event, ui, this);
			}
		});
		this.destroySortable(target);
		target.sortable(opts);
	},

	destroySortable: function($target) {
		try {
			$target.sortable('destroy');
		} catch (e) {
			// Sortable isn't yet initialized
		}
	},
	
	/**
	 * Handles element sorting.
	 * 
	 * @param Event event
	 * @param Object ui
	 * @param DOMElement node Element on which this event is triggered
	 */
	onSortUpdate: function(event, ui, node) {
	},
	
	/**
	 * Is called when a offer is dropped from another group.
	 */
	onSortReceive: function(event, ui, node) {
	},
	
	/**
	 * Is called when the offer is removed from this group.
	 */
	onSortRemove: function(event, ui, node) {
	},
	
	isEventFromCurrentList: function (ui, node) {
		return !!ui.item.closest('.ui-sortable').is(node);
	}
	
});
