import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import Wahanda from 'common/wahanda';
import { Initializer } from 'common/types/initializers';
import { PopoverPlacement, PopoverAnchorOffset } from 'components/common/Popover';
import { CalendarAnalytics } from 'common/analytics';
import { RotaModalType } from '../utils/types';
import { ShiftVenueModal } from './container';

const store = storeBuilder();
const container = Wahanda.Dialogs.getReactContainer();
interface Params {
  employeeId: number;
  date: string;
  modalType: RotaModalType;
  popoverOffset: PopoverAnchorOffset;
  popoverPlacement: PopoverPlacement;
}

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

const onClose = () => {
  destroy(container);
};

export const ShiftVenueModalInitializer = (param: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(container),
  render: () => {
    ReactDOM.render(
      <Provider store={store}>
        <ShiftVenueModal
          onClose={onClose}
          employeeId={get(param, 'employeeId')}
          modalType={get(param, 'modalType')}
          popoverOffset={get(param, 'popoverOffset')}
          popoverPlacement={get(param, 'popoverPlacement')}
          data={{
            date: get(param, 'date'),
            showImage: false,
            title: Wahanda.lang.settings.shifts.modalVenueActions.title,
          }}
          trackingCategory={CalendarAnalytics.CATEGORY}
        />
      </Provider>,
      container,
    );
  },
});
