/* eslint-disable func-names */
/* global BackboneEx */
import React from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'components/common/Button';
import { trackEvent } from 'common/analytics';

import { OfferTypeChoiceDialogAnalytics } from 'components/menu/OfferTypeChoiceDialog/tracking';

function openServiceEditForm(options) {
  const view = new App.Views.Forms.MenuOffer2(options);
  view.render();
  view.open();
}

/**
 * Menu offer groups.
 */
App.Views.Menu.OfferGroup = BackboneEx.View.Sortable.extend({
  events: {
    'click .js-add-offer': 'showOfferTypeChoice',
  },
  sortableOptions: {
    axis: 'y',
    items: '> .offer',
    handle: '.icon',
    distance: 3,
    tolerance: 'pointer',
    forcePlaceholderSize: true,
    helper(event, $offer) {
      const $helper = $offer.clone();
      $helper.find('ul').remove();
      $helper.height($offer.find('.main').outerHeight());
      return $helper;
    },
  },
  offerViews: [],
  /**
   * @var App.Models.MenuGroup
   */
  model: null,

  initialize() {
    const self = this;

    this.model.on('change:id', (model) => {
      self.onGroupIdChange(model.previous('id'), model.get('id'));
    });

    this.sortableOptions.start = function () {
      self.options.parentView.$el.addClass('drag-in-progress');
      self.$('.offers').sortable('refreshPositions');
    };
    this.sortableOptions.stop = function () {
      self.options.parentView.$el.removeClass('drag-in-progress');
    };
  },

  render() {
    this.renderSelf();

    this.renderAddOfferButton();

    this.offerViews = [];
    if (this.options.enableSorting) {
      this.setupSortable(this.$('.offers'));
    }

    for (let i = 0, len = this.collection.length; i < len; i += 1) {
      this.createOfferView(this.collection.at(i));
    }
  },

  renderAddOfferButton() {
    if (!Wahanda.Permissions.editMenu()) {
      return;
    }

    const node = this.$('.js-add-single-offer')[0];
    const singleGroup = {
      menuGroupId: this.model.get('id'),
      name: this.model.get('name'),
    };

    ReactDOM.render(
      <Button
        dataTest="menu-add-to-group-button"
        label={Wahanda.lang.menu.buttons.addToGroup}
        size="small"
        variant="secondary"
        onClick={() => this.showOfferTypeDialog({ singleGroup })}
      />,
      node,
    );
  },

  onOfferTypeChoose() {
    App.ES6.Initializers.OfferTypeChoiceDialog().destroy();
  },

  showOfferTypeDialog(options) {
    const self = this;
    OfferTypeChoiceDialogAnalytics.trackOfferChoiceModalPerGroupClick();
    App.ES6.Initializers.OfferTypeChoiceDialog().render({
      onSelect: () => self.onOfferTypeChoose,
      singleGroup: options.singleGroup,
    });
  },

  createOfferView(model) {
    const view = new App.Views.Menu.Offer({
      model,
      treatments: this.options.treatments,
      employeeCategories: this.options.employeeCategories,
      venue: this.options.venue,
      featuredItemsCollection: this.options.featuredItemsCollection,
      employees: this.options.employees,
      fullMenu: this.options.fullMenu,
      roomTypes: this.options.roomTypes,
    });
    this.offerViews.push(view);
    view.render();

    this.$('.offers').append(view.$el);
  },

  /**
   * Renders the OfferGroup`s container element with title and some attributes
   */
  renderSelf() {
    const html = Wahanda.Template.renderTemplate('offer-group', {
      id: this.model.get('id'),
      name: this.model.get('name'),
      isMasquerading: App.isMasquerading(),
      editable: Wahanda.Permissions.editMenu(),
    });
    const el = $(html);

    this.$el.append(el);
    this.setElement(el);
  },

  /**
   * Handles element sorting.
   *
   * @param Event event
   * @param Object ui
   * @param DOMElement node Element on which this event is triggered
   */
  onSortUpdate(event, ui, node) {
    if (!this.isEventFromCurrentList(ui, node)) {
      // Event node differs from parent node. This means that this offer was dragged into another group.
      // Skip the update, another event will come.
      return;
    }
    trackEvent('services', 'update', 'menu-offer-position-reordered');

    const offerId = ui.item.data('offerId');
    const orderedList = [];
    this.$('.offer').each(function () {
      orderedList.push($(this).data('offerId'));
    });
    this.model.reorder(orderedList, () => {
      App.trigger(Wahanda.Event.MENU_OFFER_CHANGED_ORDER, { offerId });
    });
  },

  /**
   * Is called when a offer is dropped from another group.
   */
  onSortReceive(event, ui) {
    trackEvent('services', 'update', 'menu-offer-group-changed');

    const offerId = ui.item.data('offerId');
    const model = this.options.allOffers.get(offerId);
    model.set('groupId', this.model.id);
    this.collection.add(model);

    App.trigger(Wahanda.Event.MENU_OFFER_CHANGED_GROUP, { offerId });
  },

  /**
   * Is called when the offer is removed from this group.
   */
  onSortRemove(event, ui) {
    const offerId = ui.item.data('offerId');
    this.collection.remove(offerId);
    if (this.model.id < 0 && this.collection.length === 0) {
      // Redraw the view if this virtual group doesn't have any offers inside
      App.initVenue();
    }
  },

  /**
   * Received a new virtual group id from the server. Change it in the DOM and on the model.
   */
  onGroupIdChange(oldGroupId, newGroupId) {
    this.$el.data('offerGroupId', newGroupId).attr('data-offer-group-id', newGroupId);
  },

  showOfferTypeChoice(event) {
    event.stopPropagation();
    // This is tracked only for masqueraded users
    trackEvent('services', 'click', 'open-services-dialog', 'while-masquerading');

    this.openSelectServiceDialog();
  },

  isMenuTemplatesEnabled() {
    return App.config.isMenuServiceTemplatesEnabled() && this.options.menuTemplate.length > 0;
  },

  openSelectServiceDialog() {
    const treatmentOptions = this.getCreationOptions('treatment');
    const target = $('#select-service')[0];

    App.ES6.Initializers.SelectServiceDialog({
      treatmentTypes: this.options.menuTemplate,
      treatments: this.options.treatments.get('treatmentsCollection'),
      onOpenCustomServiceDialog: openServiceEditForm.bind(null, treatmentOptions),
      onOpenServiceDialog(templateOffer) {
        treatmentOptions.model.setFromTemplate(
          templateOffer, // .toJSON(),
          treatmentOptions.employeeCategories,
        );
        treatmentOptions.model.set('isCreatingService', true);
        openServiceEditForm(treatmentOptions);
      },
      node: target,
    }).render();
  },

  getCreationOptions(type) {
    return {
      treatments: this.options.treatments,
      employeeCategories: this.options.employeeCategories,
      model: App.Models.MenuOffer.typeOf(type, this.model.id, this.options.employees),
      venue: this.options.venue,
      featuredItemsCollection: this.options.featuredItemsCollection,
      employees: this.options.employees,
      groupName: type === 'treatment' ? this.options.groupName : null,
      roomTypes: this.options.roomTypes,
    };
  },
});
