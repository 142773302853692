import React, { PureComponent } from 'react';

interface ISyncProps extends React.HTMLAttributes<Element> {
  size?: number;
}

export default class Sync extends PureComponent<ISyncProps, {}> {
  static defaultProps = {
    size: 24,
  };

  render() {
    const { size } = this.props;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
        <defs>
          <path
            id="a"
            d="M6.304 13.89L4.6 15.04A7.999 7.999 0 0 1 10 4.25V2.174a.5.5 0 0 1 .777-.416l4.599 3.066a.5.5 0 0 1 0 .832l-4.599 3.066A.5.5 0 0 1 10 8.306V6.339a6 6 0 0 0-3.696 7.551zm11.39-3.74L19.4 9a7.999 7.999 0 0 1-5.401 10.79v2.076a.5.5 0 0 1-.778.416l-4.598-3.065a.5.5 0 0 1 0-.832l4.598-3.066a.5.5 0 0 1 .778.416v1.967a6 6 0 0 0 3.695-7.552z"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <g fill="#fff" mask="url(#b)">
            <path d="M-38-38H63V63H-38z" />
          </g>
        </g>
      </svg>
    );
  }
}
