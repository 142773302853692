import React from 'react';
import { Chevron } from 'components/common/Icon';
import { Direction } from './types';
import style from './style.scss';

interface Props {
  children: string;
  direction?: Direction;
  dataTest?: string;
}

export const GoToBase = (props: Props) => {
  const { children, dataTest, direction = Direction.forward } = props;
  return (
    <div data-test={dataTest} className={style.goToBaseWrapper}>
      {direction === Direction.backward && <Chevron className={style.rotated} />}

      <div>{children}</div>

      {direction === Direction.forward && <Chevron />}
    </div>
  );
};
