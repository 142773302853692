import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import { Initializer } from 'common/types/initializers';
import { RootTransactionDialog } from './index';

const store = storeBuilder();
interface Params {
  node: HTMLElement;
}

export const RootTransactionDialogInitializer = ({ node }: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(node),
  render: () => {
    ReactDOM.render(
      <Provider store={store}>
        <RootTransactionDialog />
      </Provider>,
      node,
    );
  },
});
