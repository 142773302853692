/* global Backbone */

/**
 * POS checkout currency input view
 * Sets up the the input field with the currency symbol correctly, either before or after depending on the app config.
 */
(function () {
  if (!App.Views.POS) {
    App.Views.POS = {};
  }
  const BaseView = Backbone.View;
  App.Views.POS.CurrencyInput = BaseView.extend({
    templateId: '',
    currency: {},

    initialize: function () {
      this.currency = App.config.get('currency');

      if (this.currency.displayInFront) {
        this.templateId = 'pos-currency-input-before';
      } else {
        this.templateId = 'pos-currency-input-after';
      }
    },

    render: function () {
      const html = Wahanda.Template.renderTemplate(this.templateId, this.getDialogTemplateVars());

      this.$el.html(html);
    },

    getDialogTemplateVars: function () {
      return {
        currencySymbol: Wahanda.Currency.getCurrencySymbolOrCode(),
        name: this.options.name,
        cssClass: this.options.cssClass,
        cssContainerClass: this.options.cssContainerClass,
        noFocus: this.options.noFocus,
        placeholder: this.options.placeholder,
        value: this.options.value,
        disabled: this.options.disabled,
        currencyCodeClass: Wahanda.Currency.isCurrencyCode(),
        dataTest: this.options.dataTest,
      };
    },
  });
})();
