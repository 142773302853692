import { call, put } from 'redux-saga/effects';
import apiUrl from 'common/api-url';
import * as api from 'reduxStore/apiService';
import { receiveTreatmentsAction, requestTreatmentsFailureAction } from './actions';

export function* getTreatmentsDataRequested() {
  try {
    const data = yield call(api.get, apiUrl('TREATMENTS', { include: 'treatment-aliases' }));

    yield put(receiveTreatmentsAction(data));
  } catch (err) {
    yield put(requestTreatmentsFailureAction(err));
  }
}
