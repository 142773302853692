import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Impressum from './index';
import { changeImpressumText } from '../actions';

const mapStateToProps = (state) => ({
  impressumText: state.marketing.partnerSite.impressum.text,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      changeImpressumText,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Impressum);
