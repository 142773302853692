import {
  calculateSkuCalculatedPriceAmountSelector,
  calculateInlineServicePriceSelector,
  isSkuCombinationNotSupportedSelector,
} from 'components/menu/selectors';
import {
  render as renderMenuPackagePricing,
  destroy as destroyMenuPackagePricing,
  actions,
} from 'src/initializers/PackagePricing';
import { uniq } from 'underscore';

(function () {
  const Base = BackboneEx.View.Base;

  /**
   * Actions:
   * - Offers changed [ subServices, skus ]
   * - Master service changed [ offerId || tempId ]
   * - Inline service added [ tempId ]
   * - Inline service removed [ tempId ]
   * - Inline price changed [ tempId, price ]
   */

  App.Views.Forms.MenuOffer2.Pricing.PackagePricing = Base.extend({
    reactInstance: null,
    rendered: false,
    initialize: function () {
      const mediator = this.options.mediator;

      this.listenTo(mediator, mediator.PACKAGE_SERVICES_CHANGED, this.onServicesChanged);
      this.listenTo(mediator, mediator.PACKAGE_SERVICES_CHANGE_ACCEPTED, this.onServicesChanged);
      this.listenTo(mediator, mediator.PACKAGE_MASTER_SERVICE_CHANGED, this.onMasterServiceChanged);
    },

    getServicesAndSkus: function () {
      const offerDialogSubServices = [];
      const offerDialogInlineServices = [];

      this.model.get('subServices').forEach((service) => {
        const targetArray = service.tempId ? offerDialogInlineServices : offerDialogSubServices;

        targetArray.push(Object.assign({}, service));
      });

      const inlineServicesPrice = calculateInlineServicePriceSelector(offerDialogInlineServices);

      const areAllSubSkuNamesIdentical = (subSkus) => {
        if (!subSkus) {
          return false;
        }

        const subSkusWithNames = subSkus.filter((subSku) => subSku.name);

        return subSkusWithNames.length < 2
          ? false
          : uniq(subSkusWithNames.map((subSku) => subSku.name.toUpperCase())).length === 1;
      };

      const skuWithNameMatchExist = this.model.get('skus').some((sku) => {
        return areAllSubSkuNamesIdentical(sku.subSkus);
      });

      const skus = this.model.get('skus').map((sku) => {
        const visible =
          sku.visible !== null && sku.visible !== undefined
            ? sku.visible
            : skuWithNameMatchExist
            ? areAllSubSkuNamesIdentical(sku.subSkus)
            : true;

        return Object.assign({}, sku, {
          visible,
          calculatedPriceAmount: calculateSkuCalculatedPriceAmountSelector(
            sku,
            inlineServicesPrice,
          ),
        });
      });

      return { offerDialogSubServices, offerDialogInlineServices, skus };
    },

    render: function () {
      if (this.rendered) {
        return;
      }
      this.rendered = true;

      const { skus, offerDialogInlineServices, offerDialogSubServices } = this.getServicesAndSkus();

      const props = {
        isPackagePricingCustom: this.model.isPackagePricingCustom(),
        readonly: this.model.isReadOnly(),
        readonlyLight: this.model.isReadOnlyLight(),
        employeeCategoriesCollection: this.options.employeeCategories.toJSON(),
        skus,
        offerDialogSubServices,
        offerDialogInlineServices,
      };

      this.reactInstance = renderMenuPackagePricing(this.el, props);
    },

    onServicesChanged: function () {
      const { skus, offerDialogInlineServices, offerDialogSubServices } = this.getServicesAndSkus();

      /*
       * TODO remove jsquery validation from this view to global view.
       * */

      if (
        isSkuCombinationNotSupportedSelector({
          offerDialogSkus: skus,
          offerDialogSubServices,
        })
      ) {
        $('.js-save').addClass('dialog2--button-disabled');
        $('.js-save').removeClass('js-save');
      } else {
        $('.dialog2--button-disabled').addClass('js-save');
        $('.dialog2--button-disabled').removeClass('dialog2--button-disabled');
      }

      actions.offerDialogPackageServicesChangeAction({
        offerDialogSkus: skus,
        offerDialogInlineServices,
        offerDialogSubServices,
      });
    },

    onMasterServiceChanged: function (data) {
      actions.masterServiceChangeAction(data);
    },

    getValues: function () {
      const values = this.reactInstance.getValues();

      // Add the savedServices' SKUs into each PackageSku
      const newServices = this.model.get('hiddenServices');

      // If our hiddenService have IDs, means we're in the saving phase and we should add the
      // additional subSkus data.
      if (newServices && newServices.length > 0 && newServices[0].serviceId > 0) {
        const additionalSubSkus = newServices.map((newService) => newService.skus[0]);

        values.skus = values.skus.map((sku) => {
          return Object.assign({}, sku, {
            subSkus: [...sku.subSkus, ...additionalSubSkus],
          });
        });
      }

      return values;
    },

    remove: function () {
      destroyMenuPackagePricing(this.el);
      Base.prototype.remove.call(this);
    },
  });
})();
