import React, { PureComponent } from 'react';

export default class flagCH extends PureComponent<{}, {}> {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 640 480">
        <g fillRule="evenodd" strokeWidth="1pt">
          <path fill="#d52b1e" d="M0 0h640v480H0z" />
          <g fill="#fff">
            <path d="M170 195h300v90H170z" />
            <path d="M275 90h90v300h-90z" />
          </g>
        </g>
      </svg>
    );
  }
}
