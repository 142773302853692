import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { FBEInstallStatus } from './types';
import { businessConfigRequest } from './NotInstalled/actions';
import {
  statusCheckSuccess,
  statusCheckFailure,
  FBEStatusCheckPayload,
  integrationAssetsRequest,
  integrationAssetsSuccess,
  integrationAssetsFailure,
  FBEIntegrationAssetsPayload,
} from './actions';

interface FBEStatusCheckResponse {
  installed: boolean;
}

export function* fbeIntegrationAssetsRequested() {
  try {
    const response: FBEIntegrationAssetsPayload = yield call(
      api.get,
      apiUrl('FBE_INTEGRATION_ASSETS'),
    );

    yield put(integrationAssetsSuccess(response));
  } catch (responseError) {
    yield put(integrationAssetsFailure());
  }
}

export function* checkFBEStatusRequested() {
  try {
    const response: FBEStatusCheckResponse = yield call(api.get, apiUrl('FBE_CHECK_STATUS'));
    const payload: FBEStatusCheckPayload = {
      status: FBEInstallStatus.NOT_INSTALLED,
    };

    if (response.installed) {
      payload.status = FBEInstallStatus.INSTALLED;
      yield put(integrationAssetsRequest());
    }

    yield put(businessConfigRequest());

    yield put(statusCheckSuccess(payload));
  } catch (responseError) {
    yield put(statusCheckFailure());
  }
}
