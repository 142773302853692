import React from 'react';
import { get, getIn, partial, toJs } from 'mori';
import Wahanda from 'common/wahanda';
import TransactionSummaryDialog from 'src/components/reports/transactions/common/transaction-summary';
import LinkedTransaction from 'src/components/reports/transactions/common/transaction-summary/linked-transaction';
import apiUrl from 'common/api-url';
import { DialogFooter } from 'components/common/DialogFooter';
import { Button } from 'components/common/Button';
import { Notice } from 'components/common/Notice';
import style from './checkout-summary.scss';

interface ICheckoutSummaryDialogProps extends React.HTMLAttributes<Element> {
  data?: {};
  onClose?: (...args: any[]) => any;
  openRefundDialog?: (...args: any[]) => any;
  openReceiptCopyDialog?: (...args: any[]) => any;
  openRefundSummary?: (...args: any[]) => any;
  cancellationId?: number;
}

export class CheckoutSummaryDialog extends React.Component<ICheckoutSummaryDialogProps, {}> {
  renderRefundWarning() {
    if (!this.isRefundDisabled()) {
      return null;
    }
    const isPosEnabled = App.config.get('venue').pointOfSaleEnabled;
    const hasReceiptId = Boolean(getIn(this.props.data, ['checkoutInformation', 'receiptId']));
    const attentionMessage =
      isPosEnabled && !hasReceiptId
        ? Wahanda.lang.posRestrictions.refundNonPosTransaction
        : Wahanda.lang.posRestrictions.refundPosTransaction;
    return (
      <div className={style.attentionWrapper}>
        <Notice message={attentionMessage} />
      </div>
    );
  }

  isRefundDisabled() {
    const isPosEnabled = App.config.get('venue').pointOfSaleEnabled;
    const hasReceiptId = Boolean(getIn(this.props.data, ['checkoutInformation', 'receiptId']));
    return isPosEnabled !== hasReceiptId;
  }

  render() {
    // Get the Checkout Summary only if the POS Status is loaded.
    const {
      data,
      openRefundSummary,
      openRefundDialog,
      openReceiptCopyDialog,
      cancellationId,
    } = this.props;
    const lang = Wahanda.lang.reports.transactions.checkoutSummary;
    const maybeCancellationId = getIn(
      data,
      ['checkoutInformation', 'cancellationId'],
      cancellationId,
    );
    const referenceTransactionId = getIn(data, ['checkoutInformation', 'referenceTransactionId']);
    const linkedTransaction = (() => {
      if (maybeCancellationId) {
        const onClickFn = partial(openRefundSummary, maybeCancellationId);
        return (
          <LinkedTransaction
            linkText={lang.linkedRefund}
            transactionId={referenceTransactionId}
            onLinkClick={onClickFn}
          />
        );
      }
      return null;
    })();
    const footerContent = (() => {
      const renderNewTabLink = ({
        text,
        href,
        dataTest,
      }: {
        text: string;
        href: string;
        dataTest?: string;
      }) => {
        if (!App.config.get('venue').pointOfSaleEnabled) {
          return null;
        }
        return (
          <div className="button-wrapper">
            <Button
              dataTest={dataTest}
              variant="hollow"
              colour="help"
              href={href}
              label={text}
              opensInNewWindow
            />
          </div>
        );
      };
      const renderRefundLink = () => {
        if (
          maybeCancellationId ||
          !Wahanda.Permissions.canCancelTransactions() ||
          this.isRefundDisabled()
        ) {
          return null;
        }
        return (
          <div className="button-wrapper">
            <Button
              dataTest="checkout-summary-issue-refund"
              variant="hollow"
              colour="help"
              // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              onClick={() => openRefundDialog(data)}
              label={lang.issueRefund}
            />
          </div>
        );
      };

      const renderReceiptCopyLink = () => {
        const hasReceiptUri = Boolean(data && toJs(get(data, 'checkoutInformation')).receiptUri);

        if (!hasReceiptUri) {
          return null;
        }

        return (
          <div className="button-wrapper">
            <Button
              dataTest="checkout-summary-reprint-receipt"
              variant="hollow"
              onClick={openReceiptCopyDialog}
              colour="help"
              label={Wahanda.lang.pos.receiptCopyDialog.cta}
            />
          </div>
        );
      };
      const renderTipWithdrawalLink = () => {
        const tipWithdrawal = toJs(get(data, 'tipWithdrawal'));
        if (tipWithdrawal) {
          const receiptUrl = apiUrl('POS_TRANSACTION_RECEIPT', {
            receiptId: tipWithdrawal.receiptUri,
          });
          return renderNewTabLink({
            text: lang.reprintTipReceipt,
            href: receiptUrl,
          });
        }
        return null;
      };
      return (
        <>
          {this.renderRefundWarning()}
          <DialogFooter fullWidth>
            {renderRefundLink()}
            {renderReceiptCopyLink()}
            {renderTipWithdrawalLink()}
          </DialogFooter>
        </>
      );
    })();
    return (
      <TransactionSummaryDialog
        data={data}
        dialogTitle={lang.dialogTitle}
        onClose={this.props.onClose}
        // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type '{} | undefi... Remove this comment to see the full error message
        linkedTransaction={linkedTransaction}
        footerContent={footerContent}
      />
    );
  }
}

export default CheckoutSummaryDialog;
