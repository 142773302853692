import React from 'react';
import classnames from 'classnames';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import TabBase from './TabBase';

export default class UDV extends React.Component<{}, {}> {
  udv: any;

  onPositionChange() {
    this.udv.onPositionChange();
  }

  save() {
    return (
      this.udv
        .save()
        // Catch rejected saves.
        .catch(() => {})
    );
  }

  checkout() {
    this.udv.checkout();
  }

  render() {
    const classes = classnames('udv', {
      'with-styled-inputs': Wahanda.Device.isMacOs(),
    });
    return (
      <TabBase
        ref={(udv) => {
          this.udv = udv;
        }}
        backboneClass={App.Views.Forms.Appointment2}
        classes={classes}
        {...this.props}
      />
    );
  }
}
