(function() {
	/**
	 * View for module main views.
	 */
	BackboneEx.View.Main = Backbone.View.extend({
		lightweightDialogOpen: false,

		openLightweightModeDialog: function() {
			var self = this;

			if (this.lightweightDialogOpen) {
				return;
			}

			var dialog = new App.Views.LightweightMode();
			dialog.render();
			dialog.open();
			this.lightweightDialogOpen = true;

			dialog.$el.one('dialogclose', function() {
				self.lightweightDialogOpen = false;
			});
		},

		getCurrentStateHash: function() {
			return '#';
		}

	});
}());
