import Loader from 'components/common/Loader';
import Wahanda from 'common/wahanda';
import pathToRegexp from 'path-to-regexp';
import { Notice } from 'components/common/Notice';
import { MonthlySalesReportAnalytics } from 'common/analytics';
import moment from 'common/moment';
import _ from 'common/underscore';
import React from 'react';
import BigNumber from 'bignumber.js';
import { CHANNEL } from '../BookingChannelFilter';
import { allEmployeesOptionValue } from '../EmployeeFilter';
import DISPLAY from '../displayStateEnum';
import Filters from '../common/Filters';
import { ReportTable } from './reportTable';
import MonthlySalesReportGraph from './MonthlySalesReportGraph';
import ReportLayout from '../common/ReportLayout';
import ROUTES from '../SalesReportContainer/reportRoutes';

export const PRESET_RANGES = {
  lastTwelveMonths: {
    buttonText: Wahanda.lang.reports.sales.filters.dateRange.lastTwelveMonths,
    getDates: () => ({
      startDate: moment().startOf('month').subtract(11, 'months'),
      endDate: moment().endOf('month'),
    }),
  },
  thisYear: {
    buttonText: Wahanda.lang.reports.sales.filters.dateRange.thisYear,
    getDates: () => ({
      startDate: moment().startOf('year'),
      endDate: moment(),
    }),
  },
  lastYear: {
    buttonText: Wahanda.lang.reports.sales.filters.dateRange.lastYear,
    getDates: () => ({
      startDate: moment().subtract(1, 'years').startOf('year'),
      endDate: moment().subtract(1, 'years').endOf('year'),
    }),
  },
};

interface IMonthlySalesReportProps extends React.HTMLAttributes<Element> {
  displayState: any;
  isPosFull: boolean;
  history: {
    push: (...args: any[]) => any;
  };
  match: {
    params: {
      venueId: string;
      fromDate?: string;
      toDate?: string;
      selectedChannel?: string;
      employeeFilter?: string;
    };
  };
  requestMonthlySalesReportDataAction: (...args: any[]) => any;
  reportData?: any;
  map?: any;
  rows?: any;
  params?: any;
  push?: any;
}

export class MonthlySalesReport extends React.Component<IMonthlySalesReportProps, {}> {
  static defaultProps = {
    reportData: null,
  };

  componentDidMount = () => {
    MonthlySalesReportAnalytics.trackPageView();
    this.requestDataFromApi();
  };

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(this.props.match.params, prevProps.match.params)) {
      this.requestDataFromApi();
    }
  };

  onFilterChange = (filters) => {
    const { venueId } = this.getQueryParams();
    const { fromDate, toDate, selectedChannel, selectedEmployeeId } = filters;
    const toPath = pathToRegexp.compile(ROUTES.MONTHLY_SALES_REPORTS);
    const newPath = toPath({
      venueId,
      fromDate: fromDate.formatApiDateString(),
      toDate: toDate.formatApiDateString(),
      employeeFilter:
        selectedChannel != null && selectedEmployeeId == null
          ? allEmployeesOptionValue
          : selectedEmployeeId,
      selectedChannel,
    });
    this.props.history.push(newPath);
  };

  getLoadingSpinner = () => <Loader />;

  getErrorMessage = () => <div>{Wahanda.lang.reports.sales.error}</div>;

  getQueryParams = () => {
    const { venueId, fromDate, toDate, employeeFilter, selectedChannel } = _.defaults(
      this.props.match.params,
      {
        fromDate: moment().startOf('month').subtract(11, 'months').formatApiDateString(),
        toDate: moment().endOf('month').formatApiDateString(),
        employeeFilter: allEmployeesOptionValue,
        selectedChannel: CHANNEL.ALL,
      },
    );
    return {
      venueId,
      selectedChannel,
      fromDate: moment(fromDate),
      toDate: moment(toDate),
      employeeFilter: new BigNumber(employeeFilter).toNumber(),
    };
  };

  requestDataFromApi = () => {
    const { venueId, fromDate, toDate, selectedChannel, employeeFilter } = this.getQueryParams();
    this.props.requestMonthlySalesReportDataAction(
      venueId,
      fromDate,
      toDate,
      selectedChannel === CHANNEL.ALL ? null : selectedChannel,
      employeeFilter === allEmployeesOptionValue ? null : employeeFilter,
    );
  };

  render() {
    let body;
    switch (this.props.displayState) {
      case DISPLAY.WAITING:
        body = this.getLoadingSpinner();
        break;
      case DISPLAY.REPORT:
        body = (
          <ReportTable
            venueId={this.getQueryParams().venueId}
            reportData={this.props.reportData}
            employeeFilter={this.getQueryParams().employeeFilter}
            selectedChannel={this.getQueryParams().selectedChannel}
          />
        );
        break;
      case DISPLAY.ERROR:
        body = this.getErrorMessage();
        break;
      default:
        throw new Error(`Unrecognised display state: ${this.props.displayState}`);
    }
    const dateFilterProps = {
      uniqueId: 'monthlySalesReportDateFilter',
      fromDate: this.getQueryParams().fromDate,
      toDate: this.getQueryParams().toDate,
      monthlyGranularity: true,
      presetRanges: PRESET_RANGES,
    };
    const employeeFilterProps = this.props.isPosFull
      ? {
          selectedEmployeeId: this.getQueryParams().employeeFilter,
          categoryFilterEnabled: true,
        }
      : null;
    const filters = (
      <Filters
        date={dateFilterProps}
        channel={{ selectedChannel: this.getQueryParams().selectedChannel }}
        // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type '{ selectedE... Remove this comment to see the full error message
        employee={employeeFilterProps}
        onFilterChange={this.onFilterChange}
        onChannelChange={MonthlySalesReportAnalytics.trackChannelFilterChange}
        onEmployeeChange={MonthlySalesReportAnalytics.trackEmployeeFilterChange}
      />
    );
    const graph =
      this.props.displayState === DISPLAY.REPORT ? (
        <MonthlySalesReportGraph
          data={this.props.reportData.rows.map((row) => ({
            month: row.month,
            serviceGrossTotal: row.serviceGrossTotal,
            productGrossTotal: row.productGrossTotal,
            voucherGrossTotal: row.voucherGrossTotal,
          }))}
        />
      ) : null;
    const messages = (
      <Notice type="success">
        <p
          dangerouslySetInnerHTML={{
            __html: Wahanda.lang.reports.newReportsMessage,
          }}
        />
      </Notice>
    );
    return (
      <ReportLayout
        messages={messages}
        filter={filters}
        showTimeStamp={this.props.displayState === DISPLAY.REPORT}
        body={body}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Element | u... Remove this comment to see the full error message
        graph={graph}
      />
    );
  }
}
