export enum ExportType {
  NF525 = 'NF525',
  GDPDU = 'GDPDU',
  DSFINVK = 'DSFINVK',
  TRANSACTIONS = 'TRANSACTIONS',
  DATEV_SKR03 = 'DATEV_SKR03',
  DATEV_SKR04 = 'DATEV_SKR04',
}

export enum ExportStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface TransactionExports {
  id: number;
  filename: string;
  type: ExportType;
  status: ExportStatus;
  lastModificationDate: string;
}

export const DATEV_EXPORT_FEATURE_FLAG = 'CD-1127-DATEV-export';
