App.Views.LightweightMode = BackboneEx.View.Dialog.LoginRequest.extend({
  options: {
    width: 540,
    title: Wahanda.lang.shared.lightweightDialog.title,
    titleText: Wahanda.lang.shared.lightweightDialog.body.title,
    bodyText: Wahanda.lang.shared.lightweightDialog.body.text,
    loginButtonText: Wahanda.lang.shared.lightweightDialog.buttons.login,
    dialogId: 'lightweight-dialog',
    dialogClass: 'lightweight-mode-dialog',
  },

  removeHashFromReturnUrl: true,
});
