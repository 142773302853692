import { get, intoArray, assoc, vector, count } from 'mori';
import Q from 'q';

import moment from 'common/moment';
import apiUrl from 'common/api-url';
import { xhr } from 'common/xhr';
import { toDateString, toTimeString } from 'common/datetime';

function blocksMergeable(a, b) {
  return (
    get(a, 'rid') === get(b, 'rid') &&
    moment(get(a, 'start')).isSame(get(b, 'start'), 'day') &&
    // The blocks are sorted by start time, so only one check is enough.
    moment(get(b, 'start')).isBetween(
      get(a, 'start'),
      get(a, 'end'),
      null as any,
      '[]', // marks both ends as inclusive
    )
  );
}

function getBlockKeyToMergeWith(otherBlocks, block) {
  const len = otherBlocks.length;
  let i;

  for (i = len - 1; i >= 0; i -= 1) {
    if (blocksMergeable(otherBlocks[i], block)) {
      return i;
    }
  }

  return null;
}

// Combine multiple blocks ending one after each other into one
function mergeBlocks(blocks) {
  const mutableBlocks = intoArray(blocks);
  const newBlocks: any[] = [];

  mutableBlocks.sort((a, b) => get(a, 'start').getTime() - get(b, 'start').getTime());

  mutableBlocks.forEach((block) => {
    const key = getBlockKeyToMergeWith(newBlocks, block);

    if (key === null) {
      newBlocks.push(block);
    } else if (moment(get(block, 'end')).isAfter(get(newBlocks[key], 'end'))) {
      // New block end time is later than the merged one's
      newBlocks[key] = assoc(newBlocks[key], 'end', get(block, 'end'));
    }
  });

  return vector(...newBlocks);
}

export function save(block, description) {
  const startMoment = moment(get(block, 'start'));
  const endMoment = moment(get(block, 'end'));

  return xhr.postWithPromise(apiUrl('TIME_BLOCK_CREATE'), {
    dateFrom: toDateString(startMoment),
    timeFrom: toTimeString(startMoment),
    dateTo: toDateString(endMoment),
    timeTo: toTimeString(endMoment),
    name: description || null,
    availabilityRuleTypeCode: 'D',
    weekDays: null,
    employeeId: get(block, 'rid'),
  });
}

export function saveMultiple(blocks, description) {
  const length = count(blocks);
  const deferred = Q.defer();
  const savedBlockIds: number[] = [];

  function fail() {
    deferred.reject();
  }

  function saveNext(currentIndex) {
    if (currentIndex === length) {
      deferred.resolve({ ids: savedBlockIds });
    } else {
      const block = get(blocks, currentIndex);

      save(block, description)
        .done(({ id }) => {
          savedBlockIds.push(id);
          saveNext(currentIndex + 1);
        })
        .fail(fail);
    }
  }

  saveNext(0);

  return deferred.promise;
}

export function mergeAndSaveMultiple(blockCalEvents, description) {
  const mergedBlocks = mergeBlocks(blockCalEvents);

  return saveMultiple(mergedBlocks, description);
}
