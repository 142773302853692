import React from 'react';
import { AccountLanguage } from './AccountLanguage';
import { DomainLanguage } from './DomainLanguage';
import style from './LoginLanguageSelect.scss';

export const LoginLanguageSelect: React.FC = () => {
  return (
    <div className={style.container}>
      <DomainLanguage />
      <AccountLanguage />
    </div>
  );
};
