import React from 'react';
import classnames from 'classnames';
import style from './Box.scss';

interface Props {
  children: React.ReactNode;
  dataTest?: string | null;
  className?: string | null;
}

export const Box: React.FC<Props> = ({ children, dataTest = null, className }: Props) => {
  const count = React.Children.count(children);
  return (
    <div data-test={dataTest} className={style.container}>
      {React.Children.map(children, (child, index) => (
        <div
          className={classnames(
            style.section,
            count === index + 1 ? null : style.separator,
            className,
          )}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
