import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { PatchTestRequired as Component } from './';

interface Params {
  node: HTMLElement;
  requiresPatchTest: boolean;
  togglePatchTestRequired: () => void;
}

export const PatchTestRequired = (params: Params): Initializer => {
  return {
    destroy: () => ReactDOM.unmountComponentAtNode(params.node),
    render: () => {
      ReactDOM.render(
        <Component
          requiresPatchTest={params.requiresPatchTest}
          togglePatchTestRequired={params.togglePatchTestRequired}
        />,
        params.node,
      );
    },
  };
};
