import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Filter extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-filter', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M8.90845525,7.48631424 L12.9608987,3 L3.03910175,3 L7.08190141,7.47563795 L7.39355859,12.6077729 L8.56165369,11.8534471 L8.90845525,7.48631424 Z M10.5751996,12.2528852 C10.538508,12.7149276 10.2861286,13.1343904 9.89148502,13.3892411 L7.77811996,14.753998 C7.07267344,15.2095572 6.12427427,15.0179466 5.65981112,14.3260237 C5.51242588,14.10646 5.42645867,13.852761 5.41052311,13.5903464 L5.08832018,8.28455177 L1.51285967,4.32628817 C0.766054451,3.49952699 0.843971055,2.2355052 1.68689128,1.50301647 C2.05983199,1.17893495 2.5408455,1 3.03910175,1 L12.9608987,1 C14.087057,1 14.9999887,1.8954305 14.9999887,3 C14.9999887,3.48870453 14.8175565,3.96049685 14.4871408,4.32628817 L10.8882741,8.31046391 L10.5751996,12.2528852 Z" />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-filter', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M8.90845525,7.48631424 L12.9608987,3 L3.03910175,3 L7.08190141,7.47563795 L7.39355859,12.6077729 L8.56165369,11.8534471 L8.90845525,7.48631424 Z M10.5751996,12.2528852 C10.538508,12.7149276 10.2861286,13.1343904 9.89148502,13.3892411 L7.77811996,14.753998 C7.07267344,15.2095572 6.12427427,15.0179466 5.65981112,14.3260237 C5.51242588,14.10646 5.42645867,13.852761 5.41052311,13.5903464 L5.08832018,8.28455177 L1.51285967,4.32628817 C0.766054451,3.49952699 0.843971055,2.2355052 1.68689128,1.50301647 C2.05983199,1.17893495 2.5408455,1 3.03910175,1 L12.9608987,1 C14.087057,1 14.9999887,1.8954305 14.9999887,3 C14.9999887,3.48870453 14.8175565,3.96049685 14.4871408,4.32628817 L10.8882741,8.31046391 L10.5751996,12.2528852 Z" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Filter);
