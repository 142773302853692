import { xhr } from 'common/xhr';

App.Models.Profile = BackboneEx.Model.Silent.extend({
  defaults: {},

  initialize: function () {},

  url: function () {
    return App.Api.wsUrl('/profile/' + this.id + '.json');
  },

  attributesToSave: function () {
    var attrs = ['emailAddress', 'professionalNewsletterSubscription'];
    if (this.get('password')) {
      attrs.push('password');
    }
    return attrs;
  },
});
