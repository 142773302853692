import React from 'react';
import Dialog from 'components/common/react-dialog';
import Wahanda from 'common/wahanda';
import { Radio } from 'components/common/Radio';
import { CancellationFlowAnalytics } from 'common/analytics';
import { DialogFooterButtonProps, DialogFooter } from 'components/common/DialogFooter';
import { CancellationBody } from './CancellationBody';
import { AppointmentInfoBoxes } from '../AppointmentInfoBoxes';
import { BOOKING_ACTOR } from '../../../../common/consts';

interface Props {
  language: any;
  isPrepaid: boolean;
  bookingActor: BOOKING_ACTOR;
  onCancel: (params: { requestRefund: boolean }) => void;
  onClose: () => void;
}

export const ConfirmCancellationWithRefundOptions: React.FunctionComponent<Props> = ({
  language,
  isPrepaid = false,
  bookingActor,
  onCancel,
  onClose,
}) => {
  const [state, setState] = React.useState({ selectedRefundOption: '' });
  React.useEffect(() => {
    CancellationFlowAnalytics.trackRefundInitiation();
  }, []);
  const trackEvents = () => {
    if (state.selectedRefundOption === 'yes') {
      CancellationFlowAnalytics.trackRefundRequest();
    } else {
      CancellationFlowAnalytics.trackNoRefundRequested();
    }
  };
  const onCancelMethod = () => {
    trackEvents();
    onCancel({ requestRefund: state.selectedRefundOption === 'yes' });
  };
  const buttons: DialogFooterButtonProps[] = [
    {
      title: Wahanda.lang.shared.close,
      type: 'secondary',
      onClick: onClose,
    },
    {
      title: language.buttons.cancel,
      type: 'alert',
      onClick: onCancelMethod,
      disabled: state.selectedRefundOption === '',
    },
  ];
  const handleOptionSelection = (event) => {
    setState({ selectedRefundOption: event.target.value });
  };
  return (
    <Dialog
      dataTest="confirm-cancellation-with-refund-modal"
      classes={{ 'appointment-deletion-proxy': true }}
      onClose={onClose}
      title={language.title}
      width={420}
    >
      <AppointmentInfoBoxes isPrepaid={isPrepaid} bookingActor={bookingActor} />
      <div className="title">{language.heading}</div>
      <div className="intro">
        <CancellationBody language={language} />
      </div>
      <div className="refund-options">
        <div className="refund-options__option">
          <Radio
            name="refund"
            value="yes"
            label={language.options.refund.heading}
            onChange={handleOptionSelection}
            checked={state.selectedRefundOption === 'yes'}
          />
          <p className="refund-options__option__body">{language.options.refund.body}</p>
        </div>
        <div className="refund-options__option">
          <Radio
            name="refund"
            value="no"
            label={language.options.nonRefund.heading}
            onChange={handleOptionSelection}
            checked={state.selectedRefundOption === 'no'}
          />
          <p className="refund-options__option__body">{language.options.nonRefund.body}</p>
        </div>
      </div>
      <DialogFooter fullWidth buttons={buttons} />
    </Dialog>
  );
};
