import React from 'react';
import Wahanda from 'common/wahanda';
import Dialog from 'components/common/react-dialog';
import { trackEvent, ACTIONS as TRACKING_ACTION } from 'common/analytics';
import { Button } from 'components/common/Button';

import style from './PrepayRequireDialog.scss';

const LANG = Wahanda.lang.customer.prepayRequireDialog;
const TRACKING_CATEGORY = 'calendar';
const TRACKING_LABEL = 'require-customer-prepayment';

interface Props {
  actions: {
    resetCustomerAction: () => void;
    requestCustomerAction: (id) => void;
    submitCustomerAction: () => void;
    setCustomerAction: (params) => void;
  };
  customerId?: string;
  isLoaded: boolean;
  onClose: () => void;
  onSubmit: () => void;
  prepaymentRequired: boolean;
}

export class PrepayRequireDialog extends React.Component<Props> {
  public componentDidMount() {
    const { customerId } = this.props;

    this.props.actions.resetCustomerAction();
    if (customerId) {
      this.props.actions.requestCustomerAction(customerId);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const { prepaymentRequired, isLoaded } = this.props;
    if (!prepaymentRequired && isLoaded && !prevProps.isLoaded) {
      trackEvent(TRACKING_CATEGORY, TRACKING_ACTION.VIEW, TRACKING_LABEL);
    }
  }

  private onSubmit = () => {
    trackEvent(TRACKING_CATEGORY, TRACKING_ACTION.CLICK, TRACKING_LABEL, 'yes');
    this.props.actions.setCustomerAction({ prepaymentRequired: true });
    this.props.actions.submitCustomerAction();
    this.props.onSubmit();
  };

  private onClose = () => {
    trackEvent(TRACKING_CATEGORY, TRACKING_ACTION.CLICK, TRACKING_LABEL, 'no');
    this.props.onClose();
  };

  public render() {
    const { prepaymentRequired, isLoaded } = this.props;

    if (!prepaymentRequired && isLoaded) {
      return (
        <Dialog
          dataTest="prepay-required-modal"
          width={380}
          title={LANG.title}
          onClose={this.onClose}
        >
          <div className={style.wrapper}>
            <div className={style.content}>{LANG.description}</div>
            <div className={style.buttonSpacing}>
              <Button fullWidth label={LANG.actionSubmit} onClick={this.onSubmit} />
            </div>
            <Button
              fullWidth
              variant="secondary"
              label={LANG.actionCancel}
              onClick={this.onClose}
            />
          </div>
        </Dialog>
      );
    }
    return null;
  }
}
