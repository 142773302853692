import React from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import { useImmer } from 'use-immer';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { Checkbox } from 'components/common/Checkbox';
import Dialog from 'components/common/react-dialog';
import Loader from 'components/common/Loader';
import { DialogFooter, DialogFooterButtonProps } from 'components/common/DialogFooter';
import {
  GetEmployeesSchedulePayload,
  DateRangeObject,
  VenueBusinessHoursObject,
  TimeSlotsObject,
  ScheduleType,
  Schedules,
  SetEmployeeSchedulePayload,
  WeekDays,
  WeekNumber,
} from '../utils/types';
import {
  businessDayTimeSlots,
  countHoursPerWeek,
  mapWeekdays,
  limitedHoursRange,
} from '../utils/helpers';
import { ShiftsAnalytics } from '../tracking';
import { ShiftTimeSlotRange } from '../ShiftTimeSlotRange';
import style from './ShiftScheduleModal.scss';

interface Props {
  date: string;
  employeeId: number;
  scheduleId?: number;
  onClose: () => void;
  trackingCategory?: string | null;
  // redux
  actions: {
    getEmployeesSchedule: (payload: GetEmployeesSchedulePayload) => void;
    setEmployeesSchedule: (param: SetEmployeeSchedulePayload) => void;
    resetEmployeesSchedule: () => void;
  };
  businessHours: VenueBusinessHoursObject[];
  datePickerRange: DateRangeObject;
  isBusinessHoursLoaded: boolean;
  isSavingShift: boolean;
  loadedSchedule: Schedules;
}
interface SchedulesState {
  dayOfWeek: WeekDays;
  isClosed: boolean;
  timeSlots: TimeSlotsObject[];
}

export const ShiftScheduleModal = ({
  actions,
  businessHours,
  date,
  datePickerRange,
  employeeId,
  onClose,
  isBusinessHoursLoaded,
  isSavingShift,
  loadedSchedule,
  scheduleId,
  trackingCategory,
}: Props) => {
  const dateFrom = moment(date).formatLongDate();
  const weekDays = mapWeekdays();
  const initialState = populateTimeSlots();
  const [schedules, setSchedules] = useImmer(initialState);
  const observer = React.useRef(false);
  const hoursPerWeek = countHoursPerWeek(schedules);
  const buttons: DialogFooterButtonProps[] = [
    {
      dataTest: 'schedule-modal-footer-button-cancel',
      title: Wahanda.lang.shared.buttons.close,
      type: 'secondary',
      onClick: handleClose,
    },
    {
      dataTest: 'schedule-modal-footer-button-save',
      title: Wahanda.lang.shared.buttons.save,
      onClick: handleSubmit,
    },
  ];
  React.useEffect(() => {
    ShiftsAnalytics.trackShiftScheduleModalView({
      category: trackingCategory,
    });
    if (scheduleId) {
      actions.getEmployeesSchedule({ employeeId, scheduleId });
    }
    return () => actions.resetEmployeesSchedule();
  }, []);
  React.useEffect(() => {
    if (loadedSchedule.dailySchedules.length) {
      const loadedDailySchedules = populateTimeSlots(loadedSchedule?.dailySchedules);
      weekDays.forEach((day) => {
        const index = findIndex(schedules, { dayOfWeek: day });
        const loadedIndex = findIndex(loadedDailySchedules, { dayOfWeek: day });
        setSchedules((draft) => {
          draft[index] = loadedDailySchedules[loadedIndex];
        });
      });
    }
  }, [loadedSchedule.dailySchedules]);
  React.useEffect(() => {
    if (observer.current && !isSavingShift) {
      handleClose(false);
    } else {
      observer.current = true;
    }
  }, [isSavingShift]);
  if (
    (scheduleId && !loadedSchedule.dailySchedules.length) ||
    !isBusinessHoursLoaded ||
    !schedules.length
  ) {
    return <Loader positionAbsolute />;
  }

  function populateTimeSlots(passedSchedule?): SchedulesState[] {
    return weekDays.map((day) => {
      const passedTimeSlots =
        passedSchedule &&
        (find(passedSchedule, { dayOfWeek: day }).timeSlots[0] || {
          timeFrom: '',
        });
      const timeSlots = passedSchedule
        ? passedTimeSlots
        : businessDayTimeSlots({
            businessHours,
            day,
          })[0];
      const isClosed = timeSlots.timeFrom === '';
      return {
        dayOfWeek: day,
        isClosed,
        timeSlots: isClosed ? [] : [timeSlots],
      };
    });
  }

  function onTimeSlotChange(timeSlots: TimeSlotsObject, day: string) {
    const index = findIndex(schedules, { dayOfWeek: day });
    setSchedules((draft) => {
      draft[index].timeSlots = [timeSlots];
    });
  }

  function onDayOffChange(day: WeekDays) {
    const index = findIndex(schedules, { dayOfWeek: day });
    setSchedules((draft) => {
      if (!draft[index].timeSlots.length && draft[index].isClosed) {
        draft[index].timeSlots = businessDayTimeSlots({
          businessHours,
          day,
        });
      }
      draft[index].isClosed = !draft[index].isClosed;
    });
  }

  function handleClose(event) {
    if (event) {
      ShiftsAnalytics.trackShiftScheduleModalClose({
        category: trackingCategory,
      });
    }
    onClose();
  }

  function handleSubmit() {
    const dailySchedules = schedules.map((item) => ({
      dayOfWeek: item.dayOfWeek,
      timeSlots: item.isClosed ? [] : item.timeSlots,
      weekNumber: WeekNumber.WEEK_ONE,
    }));
    const data = {
      employeeId,
      dailySchedules,
      type: ScheduleType.WEEKLY,
      validFrom: date,
      dateTo: datePickerRange.dateTo,
    };
    actions.setEmployeesSchedule(data);
    ShiftsAnalytics.trackShiftScheduleModalSubmit({
      category: trackingCategory,
    });
  }

  function renderDay(dayOfWeek: WeekDays) {
    const defaultTimeSlots = businessDayTimeSlots({
      businessHours,
      day: dayOfWeek,
    });
    const scheduleDay = find(schedules, { dayOfWeek });
    const { timeSlots, isClosed } = scheduleDay;
    const isSalonClosed = defaultTimeSlots[0].timeFrom === '';
    const isChecked = !isSalonClosed && !isClosed;
    const closedCopy = isSalonClosed
      ? Wahanda.lang.settings.shifts.salonClosed
      : Wahanda.lang.settings.shifts.offText;
    const label = Wahanda.lang.date.weekdaysByName[dayOfWeek];
    const handleCheckboxChange = () => onDayOffChange(dayOfWeek);
    return (
      <div className={style.weekDayRow} key={dayOfWeek}>
        <div className={style.weekDay}>
          <Checkbox
            name={dayOfWeek}
            label={label}
            dataTest={`schedules-checkbox-${dayOfWeek}`}
            disabled={isSalonClosed}
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </div>
        <div className={style.weekDaySlot}>
          {!isChecked ? (
            <div className={style.weekDayClosed}>{closedCopy}</div>
          ) : (
            <ShiftTimeSlotRange
              limitedHoursRange={limitedHoursRange(defaultTimeSlots)}
              defaultTimeSlots={defaultTimeSlots}
              timeSlots={timeSlots}
              onTimeSlotChange={(times) => onTimeSlotChange(times, dayOfWeek)}
              isLabelVisible={false}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <Dialog
      dataTest="shift-schedule-modal"
      width={520}
      title={Wahanda.lang.settings.shifts.modalEditSchedule.titleNew}
      onClose={handleClose}
      noContentPadding
      noContentTopBorders
    >
      <div className={style.container}>
        <div className={style.header}>
          <div className={style.dateTitle}>
            {Wahanda.lang.settings.shifts.modalEditSchedule.dateStart}
          </div>
          {dateFrom}
        </div>
        {weekDays.map((day) => renderDay(day))}

        <div className={style.totalHours}>
          <div className={style.weekDay}>
            {Wahanda.lang.settings.shifts.modalEditSchedule.total}
          </div>
          <div>
            <span data-test="schedule-total-hours">{hoursPerWeek}</span>{' '}
            {Wahanda.lang.settings.shifts.perWeek}
          </div>
        </div>

        {isSavingShift && <Loader positionAbsolute />}
      </div>
      <DialogFooter buttons={buttons} />
    </Dialog>
  );
};
