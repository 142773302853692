/* global App BackboneEx _ dataLayer */
/* eslint no-global-assign:0 */

import Wahanda from 'common/wahanda';
import { ACCOUNT_LOCALE_LOCAL_STORAGE_KEY } from 'components/AccountLanguageSelect/constants';

/**
 * Wahanda Analytics tracking.
 */
(function analytics() {
  const stringify = value => {
    if (value == null) {
      return null;
    }

    if (typeof value === 'object') {
      return !$.isEmptyObject(value) ? JSON.stringify(value) : null;
    }

    return `${value}`;
  };

  const Analytics = {
    /**
     * Get generic tracking context for any event.
     *
     * @returns {Object} the context
     */
    getGenericContext() {
      // In some pages (e.g. the Login page) we might not have `App.config`
      // thus we create a dummy Config and try to get NULLs from it.
      const appConfig = App.config || new App.Models.Config();

      return {
        environment: App.env,
        platformCode: 'DESKTP',
        viewName: App.getCurrentModule(),
        venueId: App.getVenueId(),
        loggedInAccountId: appConfig.getAccountId(),
        employeeId: appConfig.getAccountEmployeeId(),
        isMasquerading: App.isMasquerading(),
        deviceUuid: null,
        isApp: App.isApp(window.location, navigator.userAgent),
        version: App.Api.version,
        // From old tracking
        channel: App.domainChannelCode(),
        countryCode:
          appConfig.get('venue') && appConfig.get('venue').countryCode
      };
    },

    enableRecording() {
      if (App.isApp(window.location, navigator.userAgent)) {
        return;
      }
      Analytics.pushTrackingObject({
        event: 'enableHotJar',
        connectGeneric: {
          countryCode: Analytics.getGenericContext().countryCode
        }
      });

      const selfOnboardingEnabled = App.config.get('venue').selfOnboardingEnabled;
      const isPending = App.config.isVenuePending();
      const recordingTags = ['self-onboarding', isPending ? 'status-pending' : 'status-live'];

      if (selfOnboardingEnabled && isPending && App.isProd) {
        window.hj = window.hj || function () { (hj.q = hj.q || []).push(arguments) };
        window.hj('trigger', 'self-onboarding');
        window.hj('tagRecording', recordingTags);
      }
    },

    intercomInit() {
      const venueLocale = App.config.get('venue').locale
        ? App.config.get('venue').locale
        : App.domainLocale;
      const accountLocale = Wahanda.LocalStorage.get(
        ACCOUNT_LOCALE_LOCAL_STORAGE_KEY
      );
      const languageOverride = accountLocale || venueLocale;

      Analytics.pushTrackingObject({
        event: 'intercomInit',
        connectGeneric: {
          ...Analytics.getGenericContext(),
          venueId: App.getVenueId(),
        },
        intercomData: {
          accountEmailAddress: App.config.get('account').emailAddress,
          employeeName: App.config.get('account').employeeName,
          employeeRoleCode: App.config.get('account').employeeRoleCode,
          venueLocale,
          languageOverride,
          pricingPlanType: App.config.get('pricingPlanType'),
          supplierId: App.config.get('venue').supplierId,
          venueName: App.config.get('venue').name,
          venuePointOfSaleEnabled: App.config.get('venue').pointOfSaleEnabled,
          venueWeightedRating: App.config.get('venue').weightedRating,
          venueStatusType: App.config.get('venue').venueStatusType,
          selfOnboardingEnabled: App.config.get('venue').selfOnboardingEnabled,
          crmEnabled: Wahanda.Permissions.canAccessMarketingTools(),
          clientListEnabled: Wahanda.Permissions.viewClientList(),
          termsConditionsAgreed: !!App.config.get('venue').termsConditionsAgreed
        }
      });
    },

    /**
     * Track a page view
     */
    trackPageView(view) {
      Analytics.pushTrackingObject({
        event: 'virtualPageView',
        connectGeneric: {
          ...Analytics.getGenericContext(),
          viewName: view
        }
      });
    },

    /**
     * Push tracking object to the dataLayer
     */
    pushTrackingObject(object) {
      dataLayer = window.dataLayer || [];
      dataLayer.push(object);
    },

    /**
     * We push the initial app data to GTM
     * Should happen after extranet-settings are retrieved
     */
    seedGenericContext() {
      Analytics.pushTrackingObject({
        event: 'appLoaded',
        connectGeneric: {
          ...Analytics.getGenericContext()
        }
      })
    },

    /**
     * category, action and data are required (though data can be null)
     *
     * the remaining arguments are optional
     *
     */
    trackEvent(category, action, data, property = null, value = null) {
      const trackingObject = {
        event: 'trackEvent',
        eventData: {
          action,
          category,
          label: stringify(data),
          value,
          property: stringify(property),
          trigger: undefined
        },
        connectGeneric: Analytics.getGenericContext()
      };

      dataLayer = window.dataLayer || [];
      dataLayer.push(trackingObject);
    },

    addExternalSourceOptions(opts) {
      const isExternal = App.isFromExternalSourceOnce();
      let result = opts;
      if (isExternal) {
        result = _.extend(
          {
            lightweightAuthUsed: App.isRestrictedMode(),
            fromExternalSource: true
          },
          opts
        );
      }
      return result;
    }
  };

  BackboneEx.Tool.Analytics = Analytics;
})();
