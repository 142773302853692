import { onStateChange, listen } from 'common/websockets';

const EVENTS = ['availability-event', 'appointment-event'];

export default function setupWebSockets(callback) {
  let notifyStateChange;
  let notifyInvalidateDates;

  const setOnStateChangeHandler = (cb) => {
    notifyStateChange = cb;
  };
  const setOnInvalidateDatesHandler = (cb) => {
    notifyInvalidateDates = cb;
  };
  callback(setOnStateChangeHandler, setOnInvalidateDatesHandler);

  const eventHandler = (event) => {
    const { date, dateFrom, availabilityRuleType } = event;

    const param: any = {};

    if (date || (dateFrom && availabilityRuleType === 'SPECIFIC_DATE')) {
      param.date = date || dateFrom;
    } else if (dateFrom) {
      // If it's a non-single-date update, we need to invalidate all caches for
      // the date upwards.
      param.dateFrom = dateFrom;
    }

    if (param.date || param.dateFrom) {
      notifyInvalidateDates(param);
    }
  };

  const onConnected = () => {
    notifyStateChange(true);
    // We do not sent the 'listen-calendar' request because we expect
    // it to be sent by `src/websockets/calendar/listener`.
    EVENTS.forEach((eventName) => listen(eventName, eventHandler));
  };
  const onDisconnected = () => {
    notifyStateChange(false);
  };
  onStateChange(onConnected, onDisconnected);
}
