import React from 'react';
import Wahanda from 'common/wahanda';
import { Checkbox } from 'components/common/Checkbox';
import { Tooltip, TooltipPlacement } from 'components/common/Tooltip';
import { QuestionMark } from 'components/common/Icon';

import style from './TimeAndPricing.scss';

interface Props {
  hasEmployeeCategoryPricing: boolean;
  useEmployeeCategoryPricing: boolean;
  employeeCategoryPricingChangeable: boolean;
  employeeCategoryPricingDisabled: boolean;
  canChangeProcessingTime: boolean;
  isProcessingTimeChecked: boolean;
  actions: any;
}

const lang = Wahanda.lang.menu.offer.pricing;

export default class TimeAndPricing extends React.PureComponent<Props> {
  public render() {
    const staffPricingCheckbox = this.staffPricingCheckbox();
    const processingTimeCheckbox = this.processingTimeCheckbox();

    if (!staffPricingCheckbox && !processingTimeCheckbox) {
      return null;
    }

    return (
      <div className={style.container}>
        <h3 className={style.header}>{lang.timeAndPricing}</h3>
        {processingTimeCheckbox}
        {staffPricingCheckbox}
      </div>
    );
  }

  private handleSelectProcessingTime = (): void => {
    const { isProcessingTimeChecked } = this.props;
    this.props.actions.setUseProcessingTime(!isProcessingTimeChecked);
  };

  private staffPricingCheckbox() {
    const {
      hasEmployeeCategoryPricing,
      employeeCategoryPricingDisabled,
      employeeCategoryPricingChangeable,
      useEmployeeCategoryPricing,
      actions,
    } = this.props;

    if (!hasEmployeeCategoryPricing) {
      return null;
    }

    const isDisabled = !employeeCategoryPricingChangeable || employeeCategoryPricingDisabled;
    const changeHandler = isDisabled
      ? undefined
      : () => actions.offerDialogChangeUseEmployeeCategoriesAction(!useEmployeeCategoryPricing);

    return (
      <Checkbox
        name="use-staff-pricing"
        onChange={changeHandler}
        checked={useEmployeeCategoryPricing}
        disabled={isDisabled}
        label={lang.employees.typeCheckbox}
        className={style.checkbox}
      />
    );
  }

  private processingTimeCheckbox() {
    const { canChangeProcessingTime, isProcessingTimeChecked } = this.props;

    if (!canChangeProcessingTime) {
      return null;
    }

    const tooltip = (
      <Tooltip tooltip={lang.timing.processingTimeTooltip} placement={TooltipPlacement.top}>
        <span className={style.question}>
          <QuestionMark size="small" className={style.questionIcon} />
        </span>
      </Tooltip>
    );

    return (
      <Checkbox
        name="use-processing-time"
        onChange={this.handleSelectProcessingTime}
        checked={isProcessingTimeChecked}
        label={lang.timing.processingTimeCheckbox}
        className={style.checkbox}
      >
        {tooltip}
      </Checkbox>
    );
  }
}
