export const FORM_TEMPLATE_STATUSES = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  CREATED: 'CREATED',
} as const;

export type FormTemplate = {
  id: string;
  title: string;
  description: string;
  venueId: string;
  status: typeof FORM_TEMPLATE_STATUSES[keyof typeof FORM_TEMPLATE_STATUSES];
  created: string;
  updated: string;
  numberOfServices: number;
};

export type FormTemplatesResponse = FormTemplate[];

export const SHOW_CONSULTATION_FORMS_LIST_FEATURE_FLAG = 'CD-661-show-consultation-forms-list';
