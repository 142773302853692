import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class OnlineBooking extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-online-booking', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path
          d="M9,5 L7,5 L7,7 L9,7 L9,5 Z M11,5 L11,7 L19,7 L19,9 L3,9 L3,20 L21,20 L21,5 L11,5 Z M5,5 L3,5 L3,7 L5,7 L5,5 Z M21.007,3 C22.11,3 22.9999875,3.895 22.9999875,5 L22.9999875,20 C22.9999875,21.102 22.11,22 21.007,22 L2.993,22 C2.46337231,21.9994707 1.95569128,21.7883633 1.58184486,21.4132038 C1.20799844,21.0380444 0.998672347,20.5296263 0.999987505,20 L0.999987505,5 C0.999987505,3.898 1.89,3 2.993,3 L21.007,3 Z M9,11 L15,15 L13.0829875,15.959 L14.285,18 L11.964,18 L11.2899875,16.855 L9,18 L9,11 Z"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(OnlineBooking);
