import React from 'react';
import { vector } from 'mori';
import Wahanda from 'common/wahanda';
import { simpleButton, successButton } from 'components/common/dialog/buttons';
import Base from './Base';

const lang = Wahanda.lang.calendar.rebooking.confirmDialog;
interface IRebookChangesConfirmProps extends React.HTMLAttributes<Element> {
  onRebook?: (...args: any[]) => any;
  onClose?: (...args: any[]) => any;
}

export default class RebookChangesConfirm extends React.Component<IRebookChangesConfirmProps, {}> {
  getButtons() {
    const { onRebook, onClose } = this.props;
    return vector(
      simpleButton(Wahanda.lang.shared.cancel, onClose),
      successButton(lang.continue, () => {
        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onRebook();
        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onClose();
      }),
    );
  }

  render() {
    return (
      <Base
        title={lang.title}
        intro={lang.intro}
        classes={{ 'appointment-rebook-confirm': true }}
        buttons={this.getButtons()}
      />
    );
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
RebookChangesConfirm.defaultProps = {
  onRebook: () => {},
  onClose: () => {},
};
