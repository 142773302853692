import { xhr } from 'common/xhr';

/**
 * Menu group.
 */
(function () {
  var MenuGroup = BackboneEx.Model.Silent.extend({
    defaults: {
      venueId: null,
    },
    attributesToSave: function () {
      var fields = ['name', 'jitPricingDiscount', 'offpeakPricingDiscount'];
      if (this.get('id')) {
        fields.push('id');
      }
      return fields;
    },

    initialize: function () {},

    url: function () {
      var id = this.get('id');
      var base;
      if (id != null) {
        base = '/venue/{{venueId}}/menu-group/{{id}}.json';
      } else {
        base = '/venue/{{venueId}}/menu-group.json';
      }
      return App.Api.wsUrl(
        Wahanda.Template.render(base, {
          id: id,
          venueId: this.get('venueId') || App.getVenueId(),
        }),
      );
    },

    checkForAppointments: function (offers) {
      var match = false;
      var currentGroupid = this.id;
      _.each(offers.models, function (offer) {
        if (offer.get('groupId') == currentGroupid && offer.get('fulfillmentTypes').appointment) {
          match = true;
        }
      });
      return match;
    },

    /**
     * Persists reordering of group items to the server.
     *
     * @param Array groupOffersOrderList Ordered list of orders in the group
     * @param Function doneFn
     */
    reorder: function (groupOffersOrderList, doneFn) {
      var url = App.Api.wsUrl(
        Wahanda.Template.render('/venue/{{venueId}}/menu-group/{{groupId}}/reorder.json', {
          venueId: App.config.get('venue').id,
          groupId: this.get('id'),
        }),
      );
      var data = JSON.stringify({ displayOrder: groupOffersOrderList });
      var self = this;

      xhr.doJQueryAjax({
        url,
        type: 'POST',
        data: data,
        contentType: 'application/json',
        success: function (response) {
          self.set('id', response.groupId, { silent: false });
          doneFn && doneFn();
        },
      });
    },

    toSelectOptions: function (htmlOffers) {
      htmlOffers = htmlOffers || '';

      return (
        Wahanda.Template.render('<optgroup label="{{name}}" data-group-id="{{id}}">', {
          id: this.id,
          name: this.get('name'),
        }) +
        htmlOffers +
        '</optgroup>'
      );
    },
  });
  App.Models.MenuGroup = MenuGroup;
})();
