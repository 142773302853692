import React from 'react';
import { useQuery, useQueryCache, useMutation } from 'react-query';

import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import { L2Page } from 'common/tracking/navigation';
import apiUrl from 'common/api-url';
import { Verification } from 'components/common/Icon';
import { get, post } from 'src/redux/apiService';
import { colors } from 'style/exports/colors';

import { ConsultationFormsVenueSettingsResponse } from 'components/calendar/UDVCovidFormLink/types';
import { SettingBox, Props as SettingProps, Analytics } from 'components/common/SettingBox';

const lang = Wahanda.lang.marketing.clientForms.covid19;

const cacheKey = () => `CovidConsentForms${App.getVenueId()}`;

export const CovidConsent = () => {
  const queryCache = useQueryCache();

  const [enableSettingMutation, { isLoading: isEnabling }] = useMutation(
    () =>
      post(
        apiUrl('CONSULTATION_FORMS_ENABLE_COVID_CONSENT_FORMS', { venueId: App.getVenueId() }),
        {},
      ),
    {
      onSuccess: () => {
        queryCache.setQueryData<ConsultationFormsVenueSettingsResponse>(cacheKey(), {
          covidConsentFormsEnabled: true,
        });
      },
    },
  );

  const [disableSettingMutation, { isLoading: isDisabling }] = useMutation(
    () =>
      post(
        apiUrl('CONSULTATION_FORMS_DISABLE_COVID_CONSENT_FORMS', { venueId: App.getVenueId() }),
        {},
      ),
    {
      onSuccess: () => {
        queryCache.setQueryData<ConsultationFormsVenueSettingsResponse>(cacheKey(), {
          covidConsentFormsEnabled: false,
        });
      },
    },
  );

  const { isLoading: isFetching, isError, data } = useQuery(
    cacheKey(),
    () =>
      get<ConsultationFormsVenueSettingsResponse>(
        apiUrl('CONSULTATION_FORMS_VENUE_SETTINGS', {
          venueId: App.getVenueId(),
        }),
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  if (isError) return null;

  const isSettingLoading = isFetching || isDisabling || isEnabling;

  const setting: SettingProps = {
    Analytics: Analytics(L2Page.CLIENT_FORMS),
    text: {
      title: lang.title,
      description: lang.description,
      areYouSureTitle: lang.areYouSureTitle,
      areYouSureDescription: lang.areYouSureDescription,
    },
    isSettingLoading,
    isSettingEnabled: data?.covidConsentFormsEnabled,
    shouldAskBeforeDisabling: false,
    icon: Verification,
    iconBackground: colors.colorCoral,
    trackingId: 'setting-covid-consent-forms',
    link: lang.link,
    dataTest: 'covid-consent-forms-setting-box',
    callbacks: {
      enableSetting: enableSettingMutation,
      disableSetting: disableSettingMutation,
    },
  };

  return <SettingBox {...setting} />;
};
