/* eslint-disable func-names */
/* global BackboneEx */
import { xhr } from 'common/xhr';
import { OnboardingWizardTracking } from './tracking';

App.Views.Forms.TermsAndConditions = BackboneEx.View.Dialog.extend({
  events: {
    'click .button-cancel': 'close',
    'click button[type=submit]': function () {
      OnboardingWizardTracking.trackTermsAgreeClick();
      if (this.options.saveOverride !== null) {
        this.options.saveOverride();
      } else {
        this.save();
      }
    },
    'click input[type=checkbox]': 'toggleAgreeButton',
  },

  templateId: 'terms-of-service-template',
  wizzardClass: 'on-board-wizard-step2',

  options: {
    width: 800,
    title: Wahanda.lang.tosDialog.title,
    dataTest: 'wizard-terms-and-conditions-modal',
  },

  postInitialize() {
    const self = this;
    xhr.doJQueryAjax({
      url: App.Api.wsUrl('/terms-of-service.json'),
      data: {
        channelCode: App.domainChannelCode(),
        locale: Wahanda.lang.locale,
      },
      success(data) {
        self.setTermsHtml(data);
      },
    });
  },

  setupDialog() {
    const hasSupplier = App.config.get('venue').supplierId > 0;

    if (hasSupplier) {
      const $label = this.$('label[for=i-agree]');
      $label.html(String($label.html()).replace('{{venueName}}', App.config.get('venue').name));

      if (!this.options.isFromWizard) {
        this.$('#i-agree').attr('checked', 'checked').disableFormElements();
      }
    } else {
      this.$('.terms-agreement').addClass('hidden');
    }

    if (this.options.isFromWizard && !Wahanda.Permissions.manageVenueDetails()) {
      this.$el.addClass('no-edit-permissions');
    }
  },

  onDialogOpen() {
    BackboneEx.View.Dialog.prototype.onDialogOpen.call(this);
    this.$('.terms-txt').shadows();
  },

  /**
   * NOTE: this function is overridden from onboarding wizard.
   */
  save() {
    /*
		if (this.$('#i-agree').is(':checked')) {
			var self = this;
			this.disableForm();
			this.agreeToTerms(
				function() {
					self.close();
				},
				function() {
					self.enableForm();
				}
			);
		}
		*/
  },

  agreeToTerms(success, error) {
    const url = App.Api.wsUrl(`/supplier/${App.config.get('venue').supplierId}/accept-terms.json`);
    xhr.doJQueryAjax({
      url,
      type: 'post',
      success: function () {
        this.resetCloseChangesCheck();
        if (success) {
          success();
        }
      }.bind(this),
      error,
    });
  },

  toggleAgreeButton() {
    const isChecked = this.$('#i-agree').is(':checked');
    this.$('button.button-primary')[isChecked ? 'enableFormElements' : 'disableFormElements']();
  },

  setTermsHtml(data) {
    const $container = this.$('.terms-txt');
    $container.html(data.terms);
    // Open external links in a new tab
    const $links = $container.find('a[href^="http"]');
    $links.attr('target', '_blank');
  },
});
