import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'reduxStore/common/products/actions';
import AssignedProductsTab from './index';

const mapStateToProps = ({ products }) => ({
  products: products.items,
  loading: products.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignedProductsTab);
