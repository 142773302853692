/**
 * Get multiple lines for a key from a Wahanda.lang object structure
 *
 * For this to return multiple rows, the naing should be ${key}1, ${key}2, ${key}3 etc.
 *
 * @param {Object} langContainer The Wahanda.lang part of container
 * @param {String} key The key to get data for
 *
 * @returns {Array} The array of strings
 */

export default function getLinesFromLang(langContainer, key) {
  const lines = [];
  let i;
  let line;

  // eslint-disable-next-line no-cond-assign
  for (i = 1; typeof (line = langContainer[`${key}${i}`]) === 'string'; i += 1) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    lines.push(line);
  }

  if (lines.length === 0) {
    // No numbered lines found. Use the non-numbered type.
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    lines.push(langContainer[key]);
  }

  return lines;
}
