/* eslint-disable func-names */
import { xhr } from 'common/xhr';
import ROLES from 'common/constants/userRoles';

/**
 * Employee
 */
App.Models.Employee = BackboneEx.Model.Silent.extend(
  {
    defaults: {
      venueId: null,
      active: true,
      canLogin: true,
      jobTitle: '',
    },

    /**
     * Which model data params to include.
     */
    initialize: function () {
      this.include = [];
      this.nextSaveInclude = null;
    },

    attributesToSave: [
      'id',
      'name',
      'jobTitle',
      'active',
      'phone',
      'emailAddress',
      'gender',
      'imageId',
      'employeeCategoryId',
      'notes',
      'takesAppointments',
      'employeeOffers',
      'permissions',
      'role',
      'canLogin',
      'externalCalendarUri',
    ],

    url: function () {
      let base = `/venue/${App.config.get('venue').id}/employee`;
      if (this.id) {
        base += `/${this.id}`;
      }
      base += '.json';

      const toInclude = this.nextSaveInclude || this.include;
      if (toInclude.length > 0) {
        base += `?include=${toInclude.join(',')}`;
      }
      this.nextSaveInclude = null;

      return App.Api.wsUrl(base);
    },

    hasServices: function () {
      const list = this.get('employeeOffers');
      return list && list.length > 0;
    },

    isActive() {
      return this.get('active');
    },

    canBeBooked: function () {
      return this.get('takesAppointments') && this.hasServices();
    },

    hasBasicAccess() {
      return this.get('role') === ROLES.BASIC;
    },

    hasExternalCalendar: function () {
      return !!this.get('externalCalendarUri');
    },
    cropImage: function (x1, y1, x2, y2, successCallback, self) {
      xhr.doJQueryAjax({
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          x1: x1,
          y1: y1,
          x2: x2,
          y2: y2,
        }),
        url: App.Api.wsUrl(
          `/venue/${App.config.get('venue').id}/employee/${this.id}/image/${this.get('imageId')}`,
        ),
        success: function (data, status, XHR) {
          successCallback(data, XHR, self);
        },
      });
    },
  },
  {
    /**
     * Persist the order of employees for this venue.
     *
     * @param Array orderedListOfIds
     * @param Object callbacks (optional) "error" and "success" callbacks.
     */
    setSorting: function (orderedListOfIds, callbacksVal) {
      const url = App.Api.wsUrl(`/venue/${App.getVenueId()}/employees/reorder.json`);
      const callbacks = callbacksVal || {};
      xhr.doJQueryAjax({
        type: 'post',
        url: url,
        data: JSON.stringify({ displayOrder: orderedListOfIds }),
        contentType: 'application/json',
        error: callbacks.error,
        success: function (...args) {
          App.trigger(Wahanda.Event.EMPLOYEE_LIST_SORTED, {
            sortedList: orderedListOfIds,
          });
          if (callbacks.success) {
            callbacks.success.apply(this, args);
          }
        },
      });
    },
  },
);
