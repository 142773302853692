import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestSalesReportDataAction } from '../actions';
import { SalesReport } from './index';

const mapStateToProps = (state) => ({
  reportData: state.salesReport.sales.reportData,
  displayState: state.salesReport.sales.displayState,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ requestSalesReportDataAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ reportData: null; }' is not assignable to ... Remove this comment to see the full error message
export default connector(SalesReport);
