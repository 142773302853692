BackboneEx.Model.Silent.Allocation = BackboneEx.Model.Silent.extend({
	idAttribute: "date",
	lowInventoryCount: 2,
	// This Boolean flag indicates that this model is not fetched and not saved yet, and is probably
	// prefilled with some dummy data.
	notSet: false,

	isSet: function() {
		return !this.notSet;
	},

	getInventoryText: function() {
		throw new Error('Not implemented!');
	},

	getStateClass: function() {
		throw new Error('Not implemented!');
	},

	getDate: function() {
		return Wahanda.Date.createDate(this.get('date'));
	},

	getDateInLocalFormat: function() {
		var date = this.getDate();
		return Wahanda.Date.formatToDefaultDate(date);
	},

	getFormattedDate: function(format) {
		format || (format = App.config.get('jqueryDateFormat').longDate);
		return Wahanda.Date.formatDate(format, this.getDate());
	},

	hasClosedOffers: function() {
		return this.get('closedOffersCount') > 0;
	},

	isClosed: function() {
		return this.get('closed');
	},

	// Fetching / saving functions

	fetch: function(options) {
		// Performs a call to spa-allocations collection address, parsing only the first item
		options     = options ? _.clone(options) : {};
		var model   = this;
		var success = options.success;
		options.success = function(resp, status, xhr) {
			var data = {};
			if (resp.allocations.length > 0) {
				data = resp.allocations[0];
				model.notSet = false;
			} else {
				model.notSet = true;
			}
			if (!model.set(data, options)) return false;
			if (success) success(model, resp);
		};
		options.error = Backbone.wrapError(options.error, model, options);
		return (this.sync || Backbone.sync).call(this, 'read', this, options);
	},

	sync: function(type, model, options) {
		var forSave  = ("create" === type || "update" === type);
		options.url  = this.url(forSave);
		options.type = (forSave ? 'POST' : options.type);
		return Backbone.sync.call(model, type, model, options);
	}
});
