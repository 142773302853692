import React, { Component, Fragment } from 'react';
import Wahanda from 'common/wahanda';
import { ProductSuppliersAnalytics } from 'common/analytics';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';
import { Supplier } from 'components/common/IconPicture';
import { TabContent } from '../../TabContent';
import ProductSuppliersList from '../ProductSuppliersList/container';
import ProductSupplierDialog from '../ProductSupplierDialog/container';

const lang = Wahanda.lang.menu.productSupplier;
interface IProductSuppliersTabProps extends React.HTMLAttributes<Element> {
  suppliers?: {}[];
  loading?: boolean;
  actions: {
    requestProductSuppliersAction: (...args: any[]) => any;
    resetProductSuppliersAction: (...args: any[]) => any;
  };
  resetProductSuppliersAction?: any;
  length?: any;
  requestProductSuppliersAction?: any;
}
type ProductSuppliersTabState = {
  supplierDialogVisible?: boolean;
};

export default class ProductSuppliersTab extends Component<
  IProductSuppliersTabProps,
  ProductSuppliersTabState
> {
  static defaultProps = {
    suppliers: [],
    loading: false,
  };

  state = {
    supplierDialogVisible: false,
  };

  componentDidMount() {
    NavigationAnalytics.trackPageEventView(L2Page.PRODUCT_SUPPLIERS);
    this.props.actions.requestProductSuppliersAction();
  }

  componentDidUpdate(prevProps) {
    if (this.props.loading) {
      return;
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (prevProps.suppliers.length === 0 && this.props.suppliers.length > 0) {
      ProductSuppliersAnalytics.trackListView();
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (this.props.suppliers.length === 0) {
      ProductSuppliersAnalytics.trackEmptyListView();
    }
  }

  componentWillUnmount() {
    this.props.actions.resetProductSuppliersAction();
  }

  onSupplierDialogClose = () => {
    this.setState({
      supplierDialogVisible: false,
    });
  };

  onAddSupplierClick = () => {
    this.setState({
      supplierDialogVisible: true,
    });
  };

  onAddSupplierFromEmptyListClick = () => {
    ProductSuppliersAnalytics.trackNewSupplierFromEmptyListClick();
    this.onAddSupplierClick();
  };

  onAddSupplierFromSuppliersListClick = () => {
    ProductSuppliersAnalytics.trackNewSupplierFromSuppliersListClick();
    this.onAddSupplierClick();
  };

  // @ts-expect-error ts-migrate(2322) FIXME: Type '() => void' is not assignable to type 'never... Remove this comment to see the full error message
  renderSupplierDialog = () => <ProductSupplierDialog onClose={this.onSupplierDialogClose} />;

  render() {
    const { suppliers, loading } = this.props;
    const { supplierDialogVisible } = this.state;
    return (
      <Fragment>
        <TabContent
          header={lang.pageTitle}
          addNewButtonText={lang.addButtonText}
          onAddNewButtonClick={this.onAddSupplierFromSuppliersListClick}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{}' is not assignable to type 'never'.
          listElement={<ProductSuppliersList suppliers={suppliers} />}
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          itemsCount={suppliers.length}
          loading={loading}
          emptyStateHeading={lang.emptyList.heading}
          emptyStateMessage={lang.emptyList.message}
          emptyStateImage={<Supplier />}
          emptyStateButtonText={lang.emptyList.button}
          emptyStateButtonOnClick={this.onAddSupplierFromEmptyListClick}
        />
        {supplierDialogVisible && this.renderSupplierDialog()}
      </Fragment>
    );
  }
}
