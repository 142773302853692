import { addWatcher } from 'common/websockets/state';

const RECONNECT_TIMEOUT_QUICK = 30 * 1000;

/* We calculate a long reconnect time in rage from 1 minute to 5, to avoid all web sockets
 ** reconnecting at the same time;
 */
const reconectTimeoutSlow = () => (Math.floor(Math.random() * 4 * 60) + 60) * 1000;

const MAX_QUICK_RETRIES = 5;
let currentRetry = 0;
let isDisconnected = false;
let hadBeenConnected = false;

function getReconnectTime(retry) {
  if (hadBeenConnected) {
    return reconectTimeoutSlow();
  }
  return retry <= MAX_QUICK_RETRIES ? RECONNECT_TIMEOUT_QUICK : reconectTimeoutSlow();
}
/**
 * This extension reconnects the WS server if it was connected, and connection was lost.
 *
 * @param {Function} connectFn Function which connects to the WS server
 */

export default function setupReconnect(connectFn) {
  const onConnect = () => {
    currentRetry = 0;
    isDisconnected = false;
    hadBeenConnected = true;
  };
  const onDisconnect = () => {
    currentRetry += 1;
    isDisconnected = true;

    const retryIn = getReconnectTime(currentRetry);

    window.setTimeout(connectFn, retryIn);
  };

  addWatcher(onConnect, onDisconnect);
  // Try reconnecting when the `online` event is sent
  window.addEventListener('online', () => {
    if (isDisconnected) {
      connectFn();
    }
  });
}
