import { xhr } from 'common/xhr';

/**
 * Collection for App.Models.EmployeeCategory.
 */
App.Collections.EmployeeCategories = BackboneEx.Collection.Base.extend({
  model: App.Models.EmployeeCategory,
  urlTemplate: '/venue/{{venueId}}/employee-categories.json',
  /**
   * Returns array of models for this collection to use.
   *
   * @param Object data
   * @return Array
   */
  parse: function (data) {
    var venueId = this.venueId;
    _.each(data.employeeCategories, function (model) {
      model.venueId = venueId;
    });
    return data.employeeCategories;
  },

  /**
   * Enables comparator by name.
   *
   * @param boolean enabled Should the comparator be enabled or disabled?
   */
  nameComparatorEnabled: function (enabled) {
    if (enabled) {
      this.comparator = function (a, b) {
        return a.get('name').localeCompare(b.get('name'));
      };
    } else {
      delete this.comparator;
    }
  },

  updateOrder: function (employeeCategoryIds) {
    var data = JSON.stringify({
      employeeCategoryIds: employeeCategoryIds,
    });
    var url = App.Api.wsVenueUrl('/employee-categories/reorder');

    return xhr.doJQueryAjax({
      url: url,
      type: 'POST',
      data: data,
      contentType: 'application/json',
    });
  },
});
