import React, { Component } from 'react';
import Wahanda from 'common/wahanda';
import { trackEvent } from 'common/analytics';
import { Currency, Percentage } from 'components/common/DataFormat';
import {
  Table,
  TableColumnHeader,
  TableRow,
  TableBody,
  TableHeader,
  TableData,
} from 'components/common/Table';
import { fetchProduct as fetchVenueProduct } from 'src/services/ProductsService';
import App from 'common/backbone-app';
import InStockIndicator from '../InStockIndicator';

const lang = Wahanda.lang.menu.products;
interface IProductListProps extends React.HTMLAttributes<Element> {
  products?: {
    id: number;
    name: string;
    productSupplier?: {
      name?: string;
    };
    amount?: number;
    taxRate?: number;
    vatCategory?: string;
    inventory?: number;
  }[];
  map?: any;
}
class ProductList extends Component<IProductListProps, {}> {
  static defaultProps = {
    products: [],
  };

  onTableDataRowClick = (id) => {
    trackEvent('products', 'click', 'product');
    fetchVenueProduct(id);
  };

  renderTableHeader = () => (
    <TableHeader>
      <TableRow>
        <TableColumnHeader>{lang.columnName}</TableColumnHeader>
        <TableColumnHeader>{lang.columnSupplier}</TableColumnHeader>
        <TableColumnHeader>{lang.columnAmount}</TableColumnHeader>
        {App.config.get('venue').pointOfSaleEnabled && (
          <TableColumnHeader>{lang.columnTax}</TableColumnHeader>
        )}
        <TableColumnHeader>{lang.columnInventory}</TableColumnHeader>
      </TableRow>
    </TableHeader>
  );

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  renderTableBody = () => <TableBody>{this.props.products.map(this.renderTableDataRow)}</TableBody>;

  renderTableDataRow = ({ id, name, amount, inventory, productSupplier, vatCategory }) => (
    <TableRow key={id} onClick={() => this.onTableDataRowClick(id)}>
      <TableData>{name}</TableData>
      <TableData>{productSupplier && productSupplier.name}</TableData>
      <TableData>
        <Currency amount={amount} />
      </TableData>
      {App.config.get('venue').pointOfSaleEnabled && (
        <TableData>
          <Percentage
            value={
              vatCategory ? App.getVATRateForCategory(vatCategory) : App.getVenueStandartVat().rate
            }
          />
        </TableData>
      )}
      <TableData>
        <InStockIndicator amount={inventory} />
      </TableData>
    </TableRow>
  );

  render() {
    return (
      <div data-hj-suppress>
        <Table>
          {this.renderTableHeader()}
          {this.renderTableBody()}
        </Table>
      </div>
    );
  }
}

export default ProductList;
