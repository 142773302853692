import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { AppointmentRepeatRow, AppointmentRepeatRowProps } from './';

interface Props extends AppointmentRepeatRowProps {
  node: HTMLElement;
}

export const AppointmentRepeatRowInitializer = (props: Props): Initializer => {
  const onClose = () => ReactDOM.unmountComponentAtNode(props.node);
  const handleRemoveClick = () => {
    onClose();
    props.onRemoveClick();
  };
  return {
    destroy: onClose,
    render: () => {
      ReactDOM.render(
        <AppointmentRepeatRow
          selectedDate={props.selectedDate}
          onComponentLoad={props.onComponentLoad}
          onRepeatModelChange={props.onRepeatModelChange}
          onRemoveClick={handleRemoveClick}
        />,
        props.node,
      );
    },
  };
};
