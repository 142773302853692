import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from 'common/backbone-app';
import * as actions from '../store/actions';
import { ServiceRowSku as Component } from './ServiceRowSku';

const mapStateToProps = () => ({
  currencyContext: {
    ...App.config.get('currency'),
    ...App.config.getNumberFormat(),
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ServiceRowSku = connect(mapStateToProps, mapDispatchToProps)(Component);
