import React from 'react';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';
import Dialog from 'components/common/react-dialog';
import SelectDropdown from 'components/common/SelectDropdown';
import { Button } from 'components/common/Button';
import UnderlinedInput from 'components/common/UnderlinedInput';
import Loader from 'components/common/Loader';
import { SignatureSearchTracking } from './tracking';
import { SignaturesStatus } from './reducer';
import { Signature, SignatureSource } from './models';
import style from './SignatureSearchModel.scss';

export interface Props {
  status: SignaturesStatus;
  onClose: () => void;
  actions: {
    clearSignaturesAction: () => void;
    getSignaturesRequestAction: (payload: { signature: string; source: SignatureSource }) => void;
  };
  signatures?: Signature[];
}

export const SignatureSearchModal = (props: Props) => {
  const { onClose, signatures, actions, status } = props;
  const { clearSignaturesAction } = actions;
  const [signatureQuery, setSignature] = React.useState('');
  const [signatureSource, setSource] = React.useState(SignatureSource.ARCHIVE);
  React.useEffect(() => SignatureSearchTracking.trackOpen(), []);
  const renderBody = () => {
    if (status === SignaturesStatus.FETCHING) {
      return <Loader />;
    }
    if (signatures == null) {
      return (
        <div className={style.subText}>
          {Wahanda.lang.reports.transactions.signature.emptyState}
        </div>
      );
    }
    if (!signatures.length) {
      return (
        <React.Fragment>
          <div className={style.header}>
            {Wahanda.lang.reports.transactions.signature.noResults}
          </div>
          <div className={style.subText}>
            {Wahanda.lang.reports.transactions.signature.noResultsSuggestion}
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className={style.signatureList}>
          <div className={style.signatureHeader}>
            {Wahanda.lang.reports.transactions.signature.results}
          </div>
          {signatures.map(({ signature, transactionDateTime }) => (
            <div key={signature} className={style.signatureInfo}>
              <div>{signature}</div>
              <div className={style.signatureDate}>
                {moment(transactionDateTime).formatDateDefault()}
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };
  const onClick = () => {
    SignatureSearchTracking.trackSubmitClick(signatureSource);
    actions.getSignaturesRequestAction({
      signature: signatureQuery,
      source: signatureSource,
    });
  };
  const onSourceSelect = (newSource: SignatureSource) => {
    SignatureSearchTracking.trackSourceSelect(newSource);
    setSource(newSource);
  };
  const onDialogClose = () => {
    clearSignaturesAction();
    onClose();
  };
  return (
    <Dialog
      dataTest="report-transaction-signature-search-modal"
      warnWhenClosingChangedForms={false}
      noContentPadding
      title={Wahanda.lang.reports.transactions.signature.title}
      onClose={onDialogClose}
    >
      <div className={style.container}>
        <div className={style.form}>
          <UnderlinedInput
            label={Wahanda.lang.reports.transactions.signature.fields.signature}
            // @ts-expect-error ts-migrate(2769) FIXME: Property 'value' does not exist on type 'Intrinsic... Remove this comment to see the full error message
            value={signatureQuery}
            name="signature"
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
            onChange={(event) => setSignature(event.target.value)}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={style.dropdown}>
            <div className={style.dropdownLabel}>
              {Wahanda.lang.reports.transactions.signature.fields.source}
            </div>
            <SelectDropdown
              extraClassName={style.dropdownStyle}
              onSelect={onSourceSelect}
              data={[
                {
                  value: SignatureSource.ARCHIVE,
                  name: Wahanda.lang.reports.transactions.signature.sources.archive,
                },
                {
                  value: SignatureSource.GRAND_TOTAL,
                  name: Wahanda.lang.reports.transactions.signature.sources.grandTotal,
                },
                {
                  value: SignatureSource.RECEIPT,
                  name: Wahanda.lang.reports.transactions.signature.sources.receipt,
                },
                {
                  value: SignatureSource.RECEIPT_COPY,
                  name: Wahanda.lang.reports.transactions.signature.sources.receiptCopy,
                },
                {
                  value: SignatureSource.TECHNICAL_EVENT_ACTION_LOG,
                  name: Wahanda.lang.reports.transactions.signature.sources.actionLog,
                },
              ]}
              selected={signatureSource}
            />
          </label>
          <Button
            disabled={!signatureQuery}
            onClick={onClick}
            label={Wahanda.lang.reports.transactions.signature.searchButton}
          />
        </div>

        <div className={style.body}>{renderBody()}</div>
      </div>
    </Dialog>
  );
};
