import { Initializer } from 'common/types/initializers';
import { unmountComponentAtNode, render } from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import { CustomerFormLinks } from './CustomerFormLinks';

const store = storeBuilder();

export const CustomerFormLinksInitializer = (
  customerId: string,
  appointmentDate: string,
  node: Element,
): Initializer => {
  return {
    destroy: () => unmountComponentAtNode(node),
    render: () =>
      render(
        <Provider store={store}>
          <CustomerFormLinks customerId={customerId} appointmentDate={appointmentDate} />
        </Provider>,
        node,
      ),
  };
};
