/**
 * Usage:
 *
 * Add <InputContext> at the top of your react tree and pass in required props.
 * Require the context you want to consume and use the Consumer component, for e.g.
 *
 * const { size, disabled } = React.useContext(InputContext);
 *
 * OR
 *
 * <InputContext.Consumer>
 *     {({ size, disabled }) => (<>{size} {disabled}</>)}
 * </InputContext.Consumer>
 *
 */
import React from 'react';
import { InputSizeType } from '../utils/types';

interface Props {
  children?: React.ReactNode;
  disabled?: boolean;
  fullBorder?: boolean;
  readOnly?: boolean;
  isFocused?: boolean;
  size?: InputSizeType;
  newLook?: boolean;
  isSearchable?: boolean;
}

export const InputContext = React.createContext<Props>({
  disabled: false,
  fullBorder: false,
  readOnly: false,
  isFocused: false,
  size: InputSizeType.DEFAULT,
  newLook: false,
  isSearchable: false,
});

export const InputContextProvider = ({ children, ...values }: Props) => (
  <InputContext.Provider value={values}>{children}</InputContext.Provider>
);
