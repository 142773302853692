import React from 'react';
import { get, each } from 'mori';
import Wahanda from 'common/wahanda';

interface IRowProps extends React.HTMLAttributes<Element> {
  name?: string;
  totalPrice?: number;
  unitPrice?: number;
  constituents?: {};
  quantity?: number;
  additionalClass?: string;
  negative?: boolean;
  hideZero?: boolean;
}

export default class Row extends React.Component<IRowProps, {}> {
  getQuantity() {
    if (this.props.quantity) {
      return (
        <span className="quantity">
          {`${this.props.quantity} x ${Wahanda.Currency.format(this.props.unitPrice)}`}
        </span>
      );
    }
    return null;
  }

  getConstituents() {
    const constituentsList = [];
    each(this.props.constituents, (constituent) => {
      constituentsList.push(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
        <span className="constituent" key={get(constituent, 'id')}>
          {get(constituent, 'name')}
        </span>,
      );
    });
    return constituentsList;
  }

  render() {
    const quantity = this.getQuantity();
    const classNames = `row ${this.props.additionalClass || ''}`;
    const constituents = this.getConstituents();
    const sign = this.props.negative ? <span className="negative-sign">-</span> : null;
    if (this.props.totalPrice === 0 && this.props.hideZero) {
      return false;
    }
    return (
      <div className={classNames}>
        <span className="name">{this.props.name}</span>
        <span className="total-price">
          {sign}
          {Wahanda.Currency.format(this.props.totalPrice)}
        </span>
        {quantity}
        {constituents}
      </div>
    );
  }
}
