/* global jQuery */
import ReactDOM from 'react-dom';
import { filter, toJs } from 'mori';
import keycode from 'keycode';

import Wahanda from 'common/wahanda';

function getSaveError(jqXhr, errorListTexts) {
  if (jqXhr.responseJson) {
    const firstErrorName = jqXhr.responseJson.errors
      ? jqXhr.responseJson.errors[0].name
      : 'default';
    const errorCamelCase = Wahanda.Text.toCamelCase(firstErrorName);

    return errorListTexts[errorCamelCase] || errorListTexts.default;
  }
  return errorListTexts.default;
}

function setupValidation(component) {
  // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type 'string'.
  const $node = jQuery(ReactDOM.findDOMNode(component)); //eslint-disable-line
  // Find the form
  const $form = $node.is('form') ? $node : $node.find('form').first();

  if (!$form.is('form')) {
    throw new Error('No form found to setup the validation mixin');
  }

  $form.validate({
    /*
     *  This forces the plugin to NOT to attach any jQuery event handlers
     */
    onsubmit: false,

    /*
     *  We will handle focusing ourselves.
     *  focusInvalid: false,
     */
    showErrors: (errorMap, errorList) => {
      // eslint-disable-next-line no-param-reassign
      component.formValidationErrors = errorList.length > 0 ? errorList : null;
    },

    /*
     *  This doesn't seem to be referenced anywhere
     */
    invalidHandler() {},
  });

  return $form;
}

/**
 *  Form validation mixin.
 *
 *  This mixin (a) automatically binds to the first form of the Component and
 *  (b) provides the same validation as jQuery Validate plugin does.
 *
 *  How to use it:
 *    <Component><form onSubmit={this.onSubmit}> ... </Component>
 *
 *  Then, in the Component, use onFormValid and onFormInvalid handlers.
 */
const FormValidationMixin = {
  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'disableValidationSetupWhenMounted' does ... Remove this comment to see the full error message
    if (this.disableValidationSetupWhenMounted) {
      return;
    }
    this.setupValidation();
  },

  setupValidation() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property '$form' does not exist on type '{ compone... Remove this comment to see the full error message
    this.$form = setupValidation(this);

    ['defaultSaveFailHandler', 'getErrorList', 'onFormSubmit'].forEach((key) => {
      if (!this[key]) {
        this[key] = FormValidationMixin[key];
      }
    });
  },

  isValid() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property '$form' does not exist on type '{ compone... Remove this comment to see the full error message
    return this.$form.valid();
  },

  revalidate(form) {
    jQuery(form).valid();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'formValidationErrors' does not exist on ... Remove this comment to see the full error message
    return this.formValidationErrors;
  },

  onFormSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    if (this.isValid()) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFormValid' does not exist on type '{ c... Remove this comment to see the full error message
      this.onFormValid();
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFormInvalid' does not exist on type '{... Remove this comment to see the full error message
      this.onFormInvalid(this.formValidationErrors);
    }
  },

  onFormMaybeSubmitKeyup(event) {
    if (keycode('enter') === event.keyCode) {
      this.onFormSubmit(event);
    }
  },

  defaultSaveFailHandler(errorListTexts) {
    return (jqXhr) => {
      const saveError = getSaveError(jqXhr, errorListTexts);

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'setState' does not exist on type '{ comp... Remove this comment to see the full error message
      this.setState({
        saving: false,
        saveError,
      });
    };
  },

  // Generic error list return
  getErrorList() {
    let list = [];

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'state' does not exist on type '{ compone... Remove this comment to see the full error message
    if (this.state.saveError) {
      const errorText =
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'state' does not exist on type '{ compone... Remove this comment to see the full error message
        typeof this.state.saveError === 'string'
          ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'state' does not exist on type '{ compone... Remove this comment to see the full error message
            this.state.saveError
          : Wahanda.lang.reports.transactions.closeDay.dialog.errors.generalSave;

      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
      list.push({ text: errorText });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'state' does not exist on type '{ compone... Remove this comment to see the full error message
    } else if (this.state.validationErrors) {
      list = list.concat(
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'state' does not exist on type '{ compone... Remove this comment to see the full error message
        this.state.validationErrors.map((error) => ({
          text: error.message,
        })),
      );
    }

    return list;
  },

  getErrorTooltipMessage(inputName) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'state' does not exist on type '{ compone... Remove this comment to see the full error message
    if (this.state.validationErrors) {
      const errorMessage = toJs(
        filter((error) => {
          // eslint-disable-line consistent-return
          if (inputName === error.element.name) {
            return error.message;
          }
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'state' does not exist on type '{ compone... Remove this comment to see the full error message
        }, this.state.validationErrors),
      );

      return errorMessage;
    }
    return null;
  },

  componentWillUnmount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property '$form' does not exist on type '{ compone... Remove this comment to see the full error message
    this.$form = null;
  },
};

export default FormValidationMixin;
