import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { getSmartDiscountsFail, getSmartDiscountsReceive } from '../../actions';

export default function* getSmartDiscountsRequested() {
  try {
    const data = yield call(api.get, apiUrl('DISCOUNT_RULES'));
    yield put(getSmartDiscountsReceive(data.discountRules));
  } catch (err) {
    yield put(getSmartDiscountsFail(err));
  }
}
