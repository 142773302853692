import React from 'react';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import ReviewDetailsRow from './Row';
import VenueRating from './VenueRating';
import style from './style.scss';
import * as analytics from './analytics';

const LANG = Wahanda.lang.dashboard.reviews.details;
const openCustomerDialog = (id) => {
  analytics.trackClickOpenClientHistory();
  const view = App.Views.Dialog.Consumer.createInstance(id);
  view.render();
  view.open();
};
interface IReviewDetailsProps extends React.HTMLAttributes<Element> {
  venueCustomerId?: number;
  treatmentRatings?: any[];
  venueRatings?: any[];
}

export default class ReviewDetails extends React.Component<IReviewDetailsProps, {}> {
  get clientHistoryButton() {
    if (!this.props.venueCustomerId) {
      return null;
    }
    return (
      <div className={style.buttonContainer}>
        <Button
          variant="secondary"
          label={LANG.clientHistoryButton}
          onClick={() => openCustomerDialog(this.props.venueCustomerId)}
        />
      </div>
    );
  }

  get treatmentRatings() {
    const { treatmentRatings } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (treatmentRatings.length === 0) {
      return null;
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const ratings = treatmentRatings.map((indTreatmentRating, index) => (
      <ReviewDetailsRow
        key={index} // eslint-disable-line react/no-array-index-key
        name={indTreatmentRating.treatmentName}
        rating={indTreatmentRating.rating}
      />
    ));
    return (
      <div className={style.treatmentRating}>
        <h1>{LANG.treatmentRating}</h1>
        {ratings}
      </div>
    );
  }

  get venueRatings() {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return this.props.venueRatings.length > 0 ? (
      <VenueRating ratings={this.props.venueRatings} />
    ) : null;
  }

  render() {
    return (
      <div className={style.reviewDetails}>
        {this.treatmentRatings}
        {this.venueRatings}
        {this.clientHistoryButton}
      </div>
    );
  }
}
