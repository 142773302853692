import React, { Component } from 'react';
import classnames from 'classnames';
import NotificationListItem from 'components/notifications/NotificationListItem';
import { NotificationDatetimeFormatted } from 'components/notifications/NotificationDatetimeFormatted';
import NotificationTimeBadge from 'components/notifications/NotificationTimeBadge';
import AppointmentNotificationIcon from '../AppointmentNotificationIcon';
import AppointmentNotificationTitle from '../AppointmentNotificationTitle';
import style from './style.scss';

interface IAppointmentNotificationProps extends React.HTMLAttributes<Element> {
  type: any;
  employeeFullName?: string;
  clientFullName?: string;
  notificationDatetime: string;
  appointmentDatetime: string;
  isAppointmentDatetimeToday?: boolean;
  lastSeenDatetime?: string;
  onClick: (...args: any[]) => any;
  additionalFields?: { [_: string]: any };
}

export class AppointmentNotification extends Component<IAppointmentNotificationProps, {}> {
  static defaultProps = {
    employeeFullName: null,
    clientFullName: null,
    lastSeenDatetime: null,
    isAppointmentDatetimeToday: false,
  };

  renderDetails = () => {
    const { appointmentDatetime, isAppointmentDatetimeToday } = this.props;
    const classes = classnames(style.details, {
      [style.withTodayBadge]: isAppointmentDatetimeToday,
    });
    const details = isAppointmentDatetimeToday ? (
      <NotificationTimeBadge datetime={appointmentDatetime} withTodayLabel />
    ) : (
      <NotificationDatetimeFormatted datetime={appointmentDatetime} />
    );
    return <div className={classes}>{details}</div>;
  };

  render() {
    const {
      type,
      notificationDatetime,
      lastSeenDatetime,
      employeeFullName,
      clientFullName,
      additionalFields,
      onClick,
    } = this.props;
    return (
      <NotificationListItem
        // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
        title={clientFullName}
        icon={<AppointmentNotificationIcon type={type} />}
        detailsFirstRow={
          <AppointmentNotificationTitle
            notificationType={type}
            employeeFullName={employeeFullName}
          />
        }
        detailsSecondRow={this.renderDetails()}
        datetime={notificationDatetime}
        lastSeenDatetime={lastSeenDatetime}
        onClick={onClick}
        additionalFields={additionalFields}
      />
    );
  }
}

export default AppointmentNotification;
