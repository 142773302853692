import React, { Component } from 'react';
import moment from 'common/moment';
import classnames from 'classnames';
import NotificationListItem from 'components/notifications/NotificationListItem';
import { NotificationDatetimeFormatted } from 'components/notifications/NotificationDatetimeFormatted';
import NotificationTimeBadge from 'components/notifications/NotificationTimeBadge';
import AppointmentNotificationIcon from '../AppointmentNotificationIcon';
import AppointmentNotificationTitle from '../AppointmentNotificationTitle';
import { TYPE_RESCHEDULED } from '../constants';
import style from './style.scss';

interface IAppointmentNotificationRescheduledProps extends React.HTMLAttributes<Element> {
  employeeFullName?: string;
  clientFullName?: string;
  notificationDatetime: string;
  appointmentDatetime: string;
  appointmentDatetimeOld: string;
  isAppointmentDatetimeToday?: boolean;
  isAppointmentDatetimeOldToday?: boolean;
  lastSeenDatetime?: string;
  onClick: (...args: any[]) => any;
}

export default class AppointmentNotificationRescheduled extends Component<
  IAppointmentNotificationRescheduledProps,
  {}
> {
  static defaultProps = {
    employeeFullName: null,
    clientFullName: null,
    lastSeenDatetime: null,
    isAppointmentDatetimeToday: false,
    isAppointmentDatetimeOldToday: false,
  };

  renderDetails = () => {
    const {
      appointmentDatetime,
      appointmentDatetimeOld,
      isAppointmentDatetimeToday,
      isAppointmentDatetimeOldToday,
    } = this.props;
    const withTodayBadge = isAppointmentDatetimeOldToday || isAppointmentDatetimeToday;
    const classes = classnames(style.rescheduledDetails, {
      [style.withTodayBadge]: withTodayBadge,
    });
    const oldDatetimeFormatted = isAppointmentDatetimeOldToday ? (
      <NotificationTimeBadge datetime={appointmentDatetimeOld} withTodayLabel />
    ) : (
      <NotificationDatetimeFormatted datetime={appointmentDatetimeOld} />
    );
    let newDatetimeFormatted;
    const isRescheduledInSameDay = () =>
      moment(appointmentDatetime)
        .startOf('day')
        .isSame(moment(appointmentDatetimeOld).startOf('day'));
    if (isAppointmentDatetimeToday) {
      newDatetimeFormatted = (
        <NotificationTimeBadge
          datetime={appointmentDatetime}
          withTodayLabel={!isRescheduledInSameDay()}
        />
      );
    } else if (isRescheduledInSameDay()) {
      newDatetimeFormatted = moment(appointmentDatetime).format('LT');
    } else {
      newDatetimeFormatted = <NotificationDatetimeFormatted datetime={appointmentDatetime} />;
    }
    return (
      <div className={classes}>
        <div className={style.rescheduledOldDatetime}>{oldDatetimeFormatted}</div>
        <div className={style.chevron} />
        <div className={style.rescheduledNewDatetime}>{newDatetimeFormatted}</div>
      </div>
    );
  };

  render() {
    const {
      notificationDatetime,
      lastSeenDatetime,
      employeeFullName,
      clientFullName,
      onClick,
    } = this.props;
    return (
      <NotificationListItem
        // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
        title={clientFullName}
        icon={<AppointmentNotificationIcon type={TYPE_RESCHEDULED} />}
        detailsFirstRow={
          <AppointmentNotificationTitle
            notificationType={TYPE_RESCHEDULED}
            employeeFullName={employeeFullName}
          />
        }
        detailsSecondRow={this.renderDetails()}
        datetime={notificationDatetime}
        lastSeenDatetime={lastSeenDatetime}
        onClick={onClick}
      />
    );
  }
}
