import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { KycBanner } from 'components/KycBanner/index';

interface Params {
  kycVerificationNeeded: boolean;
  bankAccountSyncNeeded: boolean;
  isKycNewSupplier: boolean;
  node: HTMLElement;
}

export const KycBannerInitializer = (params: Params): Initializer => ({
  destroy: () => {
    document.getElementsByTagName('body')[0].classList.remove('kyc');

    ReactDOM.unmountComponentAtNode(params.node);
  },
  render: () => {
    document.getElementsByTagName('body')[0].classList.add('kyc');
    ReactDOM.render(
      <KycBanner
        kycVerificationNeeded={params.kycVerificationNeeded}
        bankAccountSyncNeeded={params.bankAccountSyncNeeded}
        isKycNewSupplier={params.isKycNewSupplier}
      />,
      params.node,
    );
  },
});
