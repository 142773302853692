import React from 'react';
import { partial } from 'mori';
import Wahanda from 'common/wahanda';
import { trackEvent } from 'common/analytics';
import SubBlock, { ICON_OK, ICON_EXCLAMATION } from '../../common/SubBlock';
import SubBlockText, { makeBold } from '../../common/SubBlockText';
import StarRating from '../../common/StarRating';
import maybeBuildButton from '../../common/button-structure';
import maybeGetLinesFromLang from '../../common/lang-lines';

const LANG = Wahanda.lang.dashboard.trafficLights.rating;

function roundRating(rating) {
  return Number(Number(rating).toFixed(1));
}

interface IActualRatingProps extends React.HTMLAttributes<Element> {
  rating?: number;
  threshold?: number;
}

export default class ActualRating extends React.Component<IActualRatingProps, {}> {
  render() {
    const { threshold } = this.props;
    const rating = roundRating(this.props.rating);
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const isValid = rating >= threshold;
    const texts = isValid ? LANG.aboveThreshold : LANG.belowThreshold;
    const textLines = maybeGetLinesFromLang(texts, 'intro');
    const button = maybeBuildButton({
      title: Wahanda.lang.dashboard.trafficLights.shared.learnMore,
      link: texts.link,
      onClick: partial(
        trackEvent,
        'connect-dashboard',
        'scoring-button-clicked',
        `from ${isValid ? '' : 'in'}valid rating`,
      ),
    });
    return (
      <SubBlock
        title={texts.title}
        name="subblock-stars"
        icon={isValid ? ICON_OK : ICON_EXCLAMATION}
        lighterBorder
      >
        <SubBlockText button={button}>
          {textLines.map((line) => (
            <div key={line}>{line}</div>
          ))}
          {makeBold(`${Wahanda.lang.dashboard.trafficLights.shared.goal}: `)}
          {Wahanda.Template.render(texts.goal, { threshold })}
        </SubBlockText>
        <StarRating value={rating} isValid={isValid} />
      </SubBlock>
    );
  }
}
