import React from 'react';
import { useMediaQuery } from 'utilities/hooks';

const defaultLargeScreenColumns = [7, 5];
const defaultSmallScreenColumns = [1];
const breakpointMediaQuery = '(max-width: 1025px)';
const ratioToColumns = (ratio: number[] = defaultLargeScreenColumns) =>
  ratio.map((r) => `${r}fr`).join(' ');
interface Props {
  columns?: number[];
  children: React.ReactNode;
  smColumns?: number[];
  gap?: number;
}

export const LayoutGrid = (props: Props) => {
  const isTablet = useMediaQuery(breakpointMediaQuery);
  let columns = props.columns;
  if (isTablet) {
    columns = props.smColumns ? props.smColumns : defaultSmallScreenColumns;
  }
  return (
    <div
      style={{
        display: 'grid',
        gridGap: props.gap ? `${props.gap}px` : '32px',
        gridTemplateColumns: ratioToColumns(columns),
      }}
    >
      {props.children}
    </div>
  );
};
