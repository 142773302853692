/**
 * Toggleable list view.
 * 
 * The template must be similar to the following skeleton:
 * 
	<li class="{{#open}}on{{/open}}{{^open}}off{{/open}}">
		<div class="item-wrapper clearfix">
			<div class="room-title">
				<input type="checkbox" class="checker a-toggle" {{#open}}checked="checked"{{/open}} />
			</div>
			<div class="room-price when-on">
				{{name}}
			</div>
			<div class="room-price when-off">
				<%= t "calendar.spa-breaks.form.labels.closed"%>
			</div>
		</div>
	</li>
 */
App.Views.Partials.ToggleableList = Backbone.View.extend({
  events: {
    'change .a-toggle': 'toggleRow',
  },

  options: {
    templateId: '',
    data: null,
  },

  render: function (templateId, data) {
    var html = Wahanda.Template.renderTemplate(templateId, data);
    this.$el.html(html);
  },

  // Events

  toggleRow: function (event) {
    var $checkbox = $(event.currentTarget);
    var $li = $checkbox.closest('li');
    var isOn = $checkbox.prop('checked');

    $li.toggleClass('on', isOn).toggleClass('off', !isOn);
    if (isOn) {
      $li.find('input[type=text]:visible:first').focus();
    }
  },
});
