import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { PhotoUpload } from 'components/common/PhotoUpload/PhotoUpload';
import AddImageIcon from '../icons/AddImage';
import storeBuilder from 'reduxStore/store';
import { Provider, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  VenuePhotosUploadRequestAction,
  venuePhotosUploadRequest,
} from 'components/settings/VenueDetails/actions';

const store = storeBuilder();

interface Params {
  node: HTMLElement;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  uploadPhotos: bindActionCreators(venuePhotosUploadRequest, dispatch),
});

interface PhotoUploadContainerProps {
  uploadPhotos: (params: VenuePhotosUploadRequestAction['payload']) => void;
}

const PhotoUploadContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ uploadPhotos }: PhotoUploadContainerProps) => {
  const onFilesSelected = (fileList: FileList) => {
    const venueId = App.config.get('venue').id;
    const files = Array.from(fileList);
    uploadPhotos({ venueId, files });
  };

  return (
    <PhotoUpload
      id="photo_upload"
      icon={<AddImageIcon />}
      label={Wahanda.lang.settings.venue.photoUpload.label}
      buttonLabel={Wahanda.lang.settings.venue.photoUpload.button_label}
      onFilesSelected={onFilesSelected}
    />
  );
});

export const PhotoUploadInitializer = ({ node }: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(node),
  render: () => {
    ReactDOM.render(
      <Provider store={store}>
        <PhotoUploadContainer />
      </Provider>,
      node,
    );
  },
});
