const isSubSkuMaster = (subSku, offerDialogSubServices) =>
  offerDialogSubServices
    .filter((service) => service.master)
    .find((service) => service.serviceId === subSku.offerId);

const getName = (subSkus, subSkuIdsIgnoredForName, offerDialogSubServices = []) => {
  const validSkus = subSkus.filter((sku) => !subSkuIdsIgnoredForName.includes(sku.id));
  const filteredSkus = validSkus.every((skus) => skus.nameInherited || !skus.employeeCategoryId)
    ? validSkus.filter((sku) => !!sku.name)
    : validSkus.filter((sku) => !!sku.name && (!sku.nameInherited || !sku.employeeCategoryId));

  return filteredSkus
    .sort((skuA, skuB) => {
      if (isSubSkuMaster(skuA, offerDialogSubServices)) {
        return -1;
      }
      if (isSubSkuMaster(skuB, offerDialogSubServices)) {
        return 1;
      }
      return 0;
    })
    .map((sku) => sku.name)
    .join(', ');
};

export default getName;
