import React, { Component } from 'react';
import Wahanda from 'common/wahanda';
import classnames from 'classnames';
import { Radio } from 'components/common/Radio';
import { Button } from 'components/common/Button';
import Close from 'components/common/icons/Close';
import Tick from 'components/common/icons/Tick';
import { GREEN } from 'components/common/icons/Tick/types';
import EmployeeCustomPermissionsDialog from './EmployeeCustomPermissionsDialog';
import style from './style.scss';

const lang = Wahanda.lang.settings.employees.employee;
interface IEmployeeRolesListItemProps extends React.HTMLAttributes<Element> {
  role: any;
  selected?: boolean;
  permissions?: string[];
  label: string;
  descriptions: {
    hideIcon?: boolean;
    disabled?: boolean;
    label?: string;
  }[];
  disabled?: boolean;
  pointOfSalePermissionsVisible?: boolean;
  autoOpenCustomPermissionsDialog?: boolean;
  onRoleSelect?: (...args: any[]) => any;
  onPermissionsSelect?: (...args: any[]) => any;
}
type EmployeeRolesListItemState = {
  customPermissionsDialogVisible?: boolean;
};
class EmployeeRolesListItem extends Component<
  IEmployeeRolesListItemProps,
  EmployeeRolesListItemState
> {
  static defaultProps = {
    permissions: [],
    selected: false,
    disabled: false,
    pointOfSalePermissionsVisible: false,
    autoOpenCustomPermissionsDialog: false,
    onRoleSelect: () => {},
    onPermissionsSelect: () => {},
  };

  state = {
    customPermissionsDialogVisible: false,
  };

  onRoleChange = () => {
    const { disabled, role, autoOpenCustomPermissionsDialog } = this.props;
    if (disabled) {
      return;
    }
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.onRoleSelect(role);
    if (autoOpenCustomPermissionsDialog) {
      this.setState({ customPermissionsDialogVisible: true });
    }
  };

  onCustomiseButtonClick = () => {
    this.setState({ customPermissionsDialogVisible: true });
  };

  onCustomPermissionsDialogClose = () => {
    this.setState({ customPermissionsDialogVisible: false });
  };

  onCustomPermissionsDialogSave = (permissions) => {
    this.setState({ customPermissionsDialogVisible: false });
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.onPermissionsSelect(permissions);
  };

  renderDescriptions = () => {
    const { descriptions } = this.props;
    return (
      <div>
        {descriptions.map((description) => (
          <span key={description.label} className={style.description}>
            {!description.hideIcon && (
              <span className={style.iconContainer}>
                {description.disabled ? (
                  <Close className={style.closeIcon} />
                ) : (
                  <Tick colour={GREEN} small />
                )}
              </span>
            )}
            {description.label}
          </span>
        ))}
      </div>
    );
  };

  render() {
    const {
      role,
      selected,
      label,
      permissions,
      disabled,
      pointOfSalePermissionsVisible,
    } = this.props;
    const classes = classnames({
      [style.listItem]: true,
      [style.active]: selected,
      [style.disabled]: disabled,
    });
    return (
      <div className={classes}>
        <div className={style.role} onClick={this.onRoleChange}>
          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'label' is missing in type '{ value: any;... Remove this comment to see the full error message */}
          <Radio
            value={role}
            name="employee-role"
            onChange={this.onRoleChange}
            checked={selected}
            disabled={disabled}
          />
          <div className={style.labelContainer}>
            {label}
            {this.renderDescriptions()}
          </div>
        </div>
        {!disabled && (
          <div className={style.customiseButtonContainer}>
            <Button
              label={lang.buttons.viewAndCustomise}
              onClick={this.onCustomiseButtonClick}
              variant="secondary"
              size="small"
            />
          </div>
        )}
        {this.state.customPermissionsDialogVisible && (
          <EmployeeCustomPermissionsDialog
            onClose={this.onCustomPermissionsDialogClose}
            onSave={this.onCustomPermissionsDialogSave}
            permissions={permissions}
            pointOfSalePermissionsVisible={pointOfSalePermissionsVisible}
          />
        )}
      </div>
    );
  }
}

export default EmployeeRolesListItem;
