import React from 'react';
import ReactDOM from 'react-dom/server';
import BigNumber from 'bignumber.js';
import { ClientRetentionReportAnalytics } from 'common/analytics';
import { EChart } from 'components/common/LineChart';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import RettentionTooltip from './RettentionTooltip';
import style from './style.scss';

interface TooltipParams {
  name: string;
  color: string;
  seriesName: string;
  value: string;
}

interface MonthlyClientRetention {
  month: string;
  firstTimeClients: number;
  repeatClients: number;
  totalClients: number;
  walkInClients: number;
  retention: number;
}

export interface Props {
  months: MonthlyClientRetention[];
  firstTimeClients: number;
  repeatClients: number;
  totalClients: number;
  walkInClients: number;
  retention: number;
}

const LANG = Wahanda.lang.reports.clientReports.clientRetention.legend;

export default class ClientRetentionGraph extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.tooltipFormatter = this.tooltipFormatter.bind(this);
    this.xAxisFormatter = this.xAxisFormatter.bind(this);
    this.legendFormatter = this.legendFormatter.bind(this);
    this.trackLegendToggle = this.trackLegendToggle.bind(this);
  }

  public render() {
    const options = {
      legend: {
        symbolKeepAspect: false,
        formatter: this.legendFormatter,
        show: true,
        bottom: 20,
        data: [
          {
            name: LANG.clientRetention,
          },
          {
            name: LANG.totalClients,
            icon: 'image:///assets/active_dot_4x.png',
          },
        ],
      },
      grid: {
        left: 70,
        right: 70,
        bottom: 100,
      },
      tooltip: {
        trigger: 'axis',
        formatter: this.tooltipFormatter,
        padding: 0,
      },
      yAxis: [
        {
          type: 'value',
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            formatter: '{value} %',
            color: style.fontColor,
            fontFamily: '"Source Sans Pro"',
            fontSize: '14',
          },
        },
        {
          type: 'value',
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: '',
          },
        },
      ],
      xAxis: [
        {
          type: 'category',
          data: this.getXAxis(),
          axisTick: { show: false },
          axisLabel: {
            formatter: this.xAxisFormatter,
            color: style.fontColor,
            fontFamily: '"Source Sans Pro"',
            fontSize: '14',
          },
          axisLine: {
            lineStyle: {
              color: style.fontColor,
            },
          },
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: 'rgba(0,0,0,0.13)',
              width: 2,
            },
          },
        },
      ],
      series: [
        {
          name: LANG.clientRetention,
          type: 'line',
          symbolSize: 7,
          lineStyle: {
            width: 3,
            color: style.lineColor,
          },
          itemStyle: {
            color: style.lineColor,
            borderWidth: 3,
            opacity: 1,
          },
          data: this.getRettention(),
        },
        {
          name: LANG.totalClients,
          type: 'bar',
          data: this.getTotalClients(),
          yAxisIndex: 1,
          itemStyle: {
            barBorderRadius: [3, 3, 0, 0],
            color: style.graphColor,
          },
          emphasis: {
            itemStyle: {
              color: '#D6E2E3',
            },
          },
        },
      ],
    };
    const actions = {
      onLegendSelectChanged: this.trackLegendToggle,
    };
    return (
      <div className={style.graphContainer}>
        <EChart className={style.graph} eChartOptions={options} actions={actions} />
      </div>
    );
  }

  public getXAxis(): string[] {
    return this.props.months.map((month) => month.month);
  }

  public getTotalClients(): number[] {
    return this.props.months.map((row) => row.totalClients);
  }

  public getRettention(): string[] {
    const { months } = this.props;
    return months.map((month) => new BigNumber(month.retention).toFixed(0));
  }

  public legendFormatter(name: string) {
    switch (name) {
      case LANG.clientRetention:
        return `${name} (%)`;
      case LANG.totalClients:
      default:
        return name;
    }
  }

  private trackLegendToggle = (params) => {
    const { name, selected } = params;
    const toggled = selected[name];
    switch (name) {
      case LANG.totalClients:
        ClientRetentionReportAnalytics.trackAcquiredClientsLegendClick(toggled);
        break;
      case LANG.clientRetention:
        ClientRetentionReportAnalytics.trackClientRetentionLegendClick(toggled);
        break;
      default:
    }
  };

  private xAxisFormatter(param: string) {
    return moment(param).format(Wahanda.lang.reports.sales.monthlySalesReport.charts.dateFormat);
  }

  private tooltipFormatter(params: TooltipParams[]) {
    // self note create cashing using underscore isEqual
    return ReactDOM.renderToStaticMarkup(<RettentionTooltip data={params} />);
  }
}
