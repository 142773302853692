/* eslint-disable func-names */
/* global BackboneEx _ */

import {
  setup as setupUnconfirmedAppts,
  getCollection as getUnconfirmedApptsCollection,
} from 'services/unconfirmed-appointments';

App.Views.Calendar.AppointmentAlerts = BackboneEx.View.Calendar.Alerts.extend({
  events: {
    'click .a-show-appointment': 'onAppointmentClicked',
  },

  initialize: function () {
    BackboneEx.View.Calendar.Alerts.prototype.initialize.call(this);

    this.appointments = getUnconfirmedApptsCollection();
    if (!this.appointments) {
      setupUnconfirmedAppts();
      this.appointments = getUnconfirmedApptsCollection();
    }
    this.listenTo(this.appointments, 'updated', this.render);
    this.$el.hide().removeClass('hidden');
  },

  _render: function () {
    this.$('.list').empty();
    let count = 0;
    // calendar-alerts-item-appointment
    if (this.appointments.get('appointments').length > 0) {
      count += this.renderAppointments();
    }

    if (count > 0) {
      this.$el.closest('.section-aside').toggleClass('has-alert-block', count > 0);
      this.$('.alert-count').text(count);
      this.show();
    } else {
      this.hide();
    }
  },

  /**
   * Renders unconfirmed future appointments.
   *
   * @return int Count of rendered items
   */
  renderAppointments: function () {
    let count = 0;
    const maxName = 30;
    let html = '';
    const appointments = this.getSortedAppointments();

    _.each(appointments, function (appointmentValue) {
      let appointment = appointmentValue;
      let appointmentGroup;
      if (appointment instanceof App.Collections.AppointmentGroup) {
        appointmentGroup = appointment;
        appointment = appointmentGroup.at(0);
      }
      const date = appointment.getStartDate();
      const name =
        (appointmentGroup ? appointmentGroup.getOfferName() : appointment.getOfferName()) || '';
      const clientName = appointment.getConsumerName();

      let className = 'future-appointment';
      let noEmployee = false;
      if (appointment.get('employeeId') == null) {
        className = 'no-employee-assigned';
        noEmployee = true;
      }

      const isCheckedout = appointment.isCheckedOut();
      html += Wahanda.Template.renderTemplate('calendar-alerts-item-appointment', {
        id: appointment.id,
        day: date.getDate(),
        month: Wahanda.lang.date.monthsShort[date.getMonth()],
        serviceName: name.length > maxName ? `${name.substring(0, maxName - 3)}...` : name,
        consumerName: clientName,
        hasNotes: !!appointment.get('notes'),
        depositPaid: appointment.isPaidByClient(),
        unpaidWarning: !isCheckedout && appointment.isUnpaidAndSoon(),
        checkedout: isCheckedout,
        className: className,
        noEmployee: noEmployee,
      });
      count += 1;
    });

    this.$('.list').append(html);

    return count;
  },

  onAppointmentClicked: function (event) {
    const id = parseInt($(event.currentTarget).data('id'), 10);

    const appointment = this.appointments.getAppointment(id);
    const date = appointment.getStartDate();

    this.options.calendarView.trigger('change:date', date);
    // We're creating a new Appointment instance so that the Appointment would be refetched and
    // all related Appointment Groups would be fetched too.
    this.options.calendarView.showAppointmentInfo(new App.Models.Appointment({ id }), false);
    this.options.calendarView.setAppointmentToScrollTo(id);
  },

  getSortedAppointments: function () {
    const appts = [].concat(this.appointments.getAppointments().models);
    const addedGroups = {};

    const list = [];
    _.each(appts, function (appt) {
      if (!appt.belongsToGroup()) {
        list.push(appt);
        return;
      }

      const group = appt.getLinkedPackageGroup();
      if (addedGroups[group.id]) {
        return;
      }
      addedGroups[group.id] = true;
      list.push(group);
    });

    return _.sortBy(list, function (appointment) {
      return `${appointment.get(
        'appointmentDate',
      )} ${appointment.get('startTime')} ${appointment.get('offerName')}`;
    });
  },
});
