import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import { Currency } from 'components/common/DataFormat';
import tableStyle from '../common/reportTable.scss';

interface IReportTableProps extends React.HTMLAttributes<Element> {
  total: {
    numberIncludingCancelled: number;
    grossTotalIncludingCancelled: number;
    cancelledNumber: number;
    cancelledGrossTotal: number;
    number: number;
    grossTotal: number;
  };
  rows: {
    product: string;
    numberIncludingCancelled: number;
    grossTotalIncludingCancelled: number;
    cancelledNumber: number;
    cancelledGrossTotal: number;
    number: number;
    vat: number;
    netTotal: number;
    grossTotal: number;
  }[];
}

export default class ReportTable extends React.Component<IReportTableProps, {}> {
  getTableHeader = () => (
    <thead>
      <tr>
        <th className={tableStyle.wideHeader}>&nbsp;</th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.productSalesReport.headings.salesIncludingCancelled}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.productSalesReport.headings.salesCancelled}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.productSalesReport.headings.salesTotal}
        </th>
      </tr>

      <tr>
        <th className={classnames(tableStyle.centerHeader, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.productSalesReport.headings.product}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.productSalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.productSalesReport.headings.amountIncludingVat}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.productSalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.productSalesReport.headings.amountIncludingVat}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.productSalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.numeric, tableStyle.smallHeader)}>
          {Wahanda.lang.reports.sales.productSalesReport.headings.amountIncludingVat}
        </th>
      </tr>
    </thead>
  );

  render() {
    const { rows, total } = this.props;
    return (
      <table className={classnames(tableStyle.reportTable, tableStyle.dynamic)}>
        {this.getTableHeader()}
        <tbody>
          {rows.map((row: any) => (
            <tr key={row.product} data-testid="product-sale-row">
              <td data-testid="product-name">{row.product}</td>
              <td data-testid="product-sale-quantity" className={tableStyle.numeric}>
                {row.numberIncludingCancelled}
              </td>
              <td data-testid="product-sale-amount" className={tableStyle.numeric}>
                <Currency amount={row.grossTotalIncludingCancelled} />
              </td>
              <td data-testid="product-refunds" className={tableStyle.numeric}>
                {row.cancelledNumber}
              </td>
              <td data-testid="product-refunds-amount" className={tableStyle.numeric}>
                <Currency amount={row.cancelledGrossTotal} alwaysNegative />
              </td>
              <td data-testid="product-total-sale-quantity" className={tableStyle.numeric}>
                {row.number}
              </td>
              <td data-testid="product-total-sale-amount" className={tableStyle.grandTotal}>
                <Currency amount={row.grossTotal} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot data-testid="product-sale-total">
          <tr>
            <td>{Wahanda.lang.reports.sales.productSalesReport.headings.grandTotal}</td>
            <td data-testid="product-sale-quantity" className={tableStyle.numeric}>
              {total.numberIncludingCancelled}
            </td>
            <td data-testid="product-sale-amount" className={tableStyle.numeric}>
              <Currency amount={total.grossTotalIncludingCancelled} />
            </td>
            <td data-testid="product-refunds" className={tableStyle.numeric}>
              {total.cancelledNumber}
            </td>
            <td data-testid="product-refunds-amount" className={tableStyle.numeric}>
              <Currency amount={total.cancelledGrossTotal} alwaysNegative />
            </td>
            <td data-testid="product-total-sale-quantity" className={tableStyle.numeric}>
              {total.number}
            </td>
            <td data-testid="product-total-sale-amount" className={tableStyle.numeric}>
              <Currency amount={total.grossTotal} />
            </td>
          </tr>
        </tfoot>
      </table>
    );
  }
}
