import React from 'react';
import Wahanda from 'common/wahanda';
import { Tooltip } from 'components/common/Tooltip';
import style from './WidgetPreview.scss';

const lang = Wahanda.lang.settings.onlineBooking;
const embedCode = (html, el) => {
  if (!el || html === null) return;
  const range = document.createRange();
  range.selectNodeContents(el);
  range.deleteContents();
  el.appendChild(range.createContextualFragment(html));
};
const getTooltipContent = () => (
  <>
    <strong>{lang.labels.preview}</strong> - {lang.tooltips.preview}
  </>
);
type WidgetPreviewProps = {
  code: string;
  onPreviewClick: (...args: any[]) => any;
};
const WidgetPreview: React.SFC<WidgetPreviewProps> = ({ code, onPreviewClick }) =>
  // @ts-expect-error ts-migrate(2322) FIXME: Type '""' is not assignable to type 'ReactElement<... Remove this comment to see the full error message
  code && (
    <div>
      <div className={style.text}>
        <p>
          <strong>{lang.labels.preview}&nbsp;</strong>
          {lang.button.preview}
        </p>
      </div>
      <div className={style.tooltipWrapper}>
        <Tooltip tooltip={getTooltipContent()} placement="right">
          <div
            ref={embedCode.bind(null, code)}
            onClick={onPreviewClick}
            className={style.preview}
          />
        </Tooltip>
      </div>
    </div>
  );

export default WidgetPreview;
