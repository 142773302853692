import { getPreferredLanguage, getVenueLanguage } from 'src/tracking/utils/context';
import { SALESFORCE_LOCALES } from './constants';
import { LANGUAGE_LOCALES } from 'config/domains-locales';

const PREFERRED_LOCALE_TO_SALESFORCE_LOCALE = {
  [LANGUAGE_LOCALES.EN]: SALESFORCE_LOCALES.en,
  [LANGUAGE_LOCALES.EN_BE]: SALESFORCE_LOCALES.en_BE,
  [LANGUAGE_LOCALES.EN_NL]: SALESFORCE_LOCALES.en_NL,
  [LANGUAGE_LOCALES.IE]: SALESFORCE_LOCALES.en_IE,
  [LANGUAGE_LOCALES.DE]: SALESFORCE_LOCALES.de,
  [LANGUAGE_LOCALES.AT]: SALESFORCE_LOCALES.de_AT,
  [LANGUAGE_LOCALES.CH]: SALESFORCE_LOCALES.de_CH,
  [LANGUAGE_LOCALES.ES]: SALESFORCE_LOCALES.es,
  [LANGUAGE_LOCALES.EL]: SALESFORCE_LOCALES.el,
  [LANGUAGE_LOCALES.FR]: SALESFORCE_LOCALES.fr,
  [LANGUAGE_LOCALES.FR_BE]: SALESFORCE_LOCALES.fr_BE,
  [LANGUAGE_LOCALES.IT]: SALESFORCE_LOCALES.it,
  [LANGUAGE_LOCALES.NL]: SALESFORCE_LOCALES.nl_NL,
  [LANGUAGE_LOCALES.NL_BE]: SALESFORCE_LOCALES.nl_BE,
  [LANGUAGE_LOCALES.LT]: SALESFORCE_LOCALES.lt,
  [LANGUAGE_LOCALES.DA]: SALESFORCE_LOCALES.da,
  [LANGUAGE_LOCALES.LV]: SALESFORCE_LOCALES.lv,
  [LANGUAGE_LOCALES.RU]: SALESFORCE_LOCALES.ru,
  [LANGUAGE_LOCALES.PT]: SALESFORCE_LOCALES.pt_PT,
  [LANGUAGE_LOCALES.VI]: SALESFORCE_LOCALES.vi,
};

export const getSalesForceLocale = () => {
  const preferredLocale = PREFERRED_LOCALE_TO_SALESFORCE_LOCALE[getPreferredLanguage()];
  const salesforceVenueLocale = SALESFORCE_LOCALES[getVenueLanguage()];
  const salesforceVenueLocaleWithoutRegion =
    SALESFORCE_LOCALES[getVenueLanguage()?.substring(0, 2)];

  return (
    preferredLocale ||
    salesforceVenueLocale ||
    salesforceVenueLocaleWithoutRegion ||
    SALESFORCE_LOCALES.en
  );
};
