import React from 'react';

type DialogRowProps = {
  title?: string;
  isLast?: boolean;
  customClasses?: string;
};
const DialogRow: React.SFC<DialogRowProps> = ({ title, children, isLast, customClasses }) => {
  const classes = `row clearfix${isLast ? ' last' : ''}${customClasses ? ` ${customClasses}` : ''}`;
  return (
    <div className={classes}>
      <div className="right">{children}</div>
      <div className="left">{title}</div>
    </div>
  );
};
DialogRow.defaultProps = {
  customClasses: '',
};

export default DialogRow;
