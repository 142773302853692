import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class LogOut extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-log-out', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33325 2.66683C3.15644 2.66683 2.98687 2.73707 2.86185 2.86209C2.73682 2.98712 2.66659 3.15668 2.66659 3.3335V12.6668C2.66659 12.8436 2.73682 13.0132 2.86185 13.1382C2.98687 13.2633 3.15644 13.3335 3.33325 13.3335H5.99992C6.36811 13.3335 6.66659 13.632 6.66659 14.0002C6.66659 14.3684 6.36811 14.6668 5.99992 14.6668H3.33325C2.80282 14.6668 2.29411 14.4561 1.91904 14.081C1.54397 13.706 1.33325 13.1973 1.33325 12.6668V3.3335C1.33325 2.80306 1.54397 2.29436 1.91904 1.91928C2.29411 1.54421 2.80282 1.3335 3.33325 1.3335H5.99992C6.36811 1.3335 6.66659 1.63197 6.66659 2.00016C6.66659 2.36835 6.36811 2.66683 5.99992 2.66683H3.33325ZM10.1952 4.19543C10.4555 3.93508 10.8776 3.93508 11.138 4.19543L14.4713 7.52876C14.7317 7.78911 14.7317 8.21122 14.4713 8.47157L11.138 11.8049C10.8776 12.0653 10.4555 12.0653 10.1952 11.8049C9.93483 11.5446 9.93483 11.1224 10.1952 10.8621L12.3904 8.66683H5.99992C5.63173 8.66683 5.33325 8.36835 5.33325 8.00016C5.33325 7.63197 5.63173 7.3335 5.99992 7.3335H12.3904L10.1952 5.13823C9.93483 4.87788 9.93483 4.45577 10.1952 4.19543Z"
          fill="#1A1A1A"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(LogOut);
