import React from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import classnames from 'classnames';
import { MonthlySalesReportAnalytics } from 'common/analytics';
import ServiceLink from '../ServiceSalesReport/link';
import ProductLink from '../ProductSalesReport/link';
import SalesReportLink from '../SalesReport/link';
import tableStyle from '../common/reportTable.scss';

interface IReportTableProps extends React.HTMLAttributes<Element> {
  reportData: {
    total: {
      numberOfServices: number;
      serviceGrossTotal: number;
      numberOfProducts: number;
      productGrossTotal: number;
      numberOfVouchers: number;
      voucherGrossTotal: number;
      grossTotal: number;
    };
    rows: {
      month: moment.Moment;
      numberOfServices: number;
      serviceGrossTotal: number;
      numberOfProducts: number;
      productGrossTotal: number;
      numberOfVouchers: number;
      voucherGrossTotal: number;
      grossTotal: number;
    }[];
  };
  employeeFilter: number;
  selectedChannel: string;
  venueId: string;
  map?: any;
}

export class ReportTable extends React.Component<IReportTableProps, {}> {
  getTableHeader = () => (
    <thead>
      <tr>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={tableStyle.stackedHeaderDoubleHeight} rowSpan="2">
          {Wahanda.lang.reports.sales.monthlySalesReport.headings.month}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.monthlySalesReport.headings.services}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.monthlySalesReport.headings.products}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.monthlySalesReport.headings.vouchers}
        </th>
        <th
          className={classnames(tableStyle.centerHeader, tableStyle.stackedHeaderDoubleHeight)}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
          rowSpan="2"
        >
          {Wahanda.lang.reports.sales.monthlySalesReport.headings.totalSalesIncludingVat}
        </th>
      </tr>
      <tr>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.monthlySalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.monthlySalesReport.headings.salesIncludingVat}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.monthlySalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.monthlySalesReport.headings.salesIncludingVat}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.monthlySalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.monthlySalesReport.headings.salesIncludingVat}
        </th>
      </tr>
    </thead>
  );

  getTableFooter = (total) => (
    <tfoot>
      <tr>
        <td>{Wahanda.lang.reports.sales.monthlySalesReport.headings.total}</td>
        <td className={tableStyle.numeric}>{total.numberOfServices}</td>
        <td className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.serviceGrossTotal)}
        </td>
        <td className={tableStyle.numeric}>{total.numberOfProducts}</td>
        <td className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.productGrossTotal)}
        </td>
        <td className={tableStyle.numeric}>{total.numberOfVouchers}</td>
        <td className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.voucherGrossTotal)}
        </td>
        <td className={tableStyle.numeric}>{Wahanda.Currency.formatFinancial(total.grossTotal)}</td>
      </tr>
    </tfoot>
  );

  getTableRow = (row, rowHeading) => {
    const { employeeFilter, selectedChannel, venueId } = this.props;
    const startOfMonth = row.month.clone().startOf('month');
    const endOfMonth = row.month.clone().endOf('month');
    return (
      <tr key={rowHeading}>
        <td>
          <SalesReportLink
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={tableStyle.link}
            fromDate={startOfMonth}
            toDate={endOfMonth}
            employeeFilter={employeeFilter}
            selectedChannel={selectedChannel}
            onClick={MonthlySalesReportAnalytics.trackDateLinkClick}
            venueId={venueId}
          >
            {rowHeading}
          </SalesReportLink>
        </td>
        <td className={tableStyle.numeric}>
          <ServiceLink
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={tableStyle.link}
            fromDate={startOfMonth}
            toDate={endOfMonth}
            employeeFilter={employeeFilter}
            selectedChannel={selectedChannel}
            onClick={MonthlySalesReportAnalytics.trackLinkToServicesSalesClick}
          >
            {row.numberOfServices}
          </ServiceLink>
        </td>
        <td className={tableStyle.numeric}>
          <ServiceLink
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={tableStyle.link}
            fromDate={startOfMonth}
            toDate={endOfMonth}
            employeeFilter={employeeFilter}
            selectedChannel={selectedChannel}
            onClick={MonthlySalesReportAnalytics.trackLinkToServicesSalesClick}
          >
            {Wahanda.Currency.formatFinancial(row.serviceGrossTotal)}
          </ServiceLink>
        </td>
        <td className={tableStyle.numeric}>
          <ProductLink
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={tableStyle.link}
            fromDate={startOfMonth}
            toDate={endOfMonth}
            employeeFilter={employeeFilter}
            onClick={MonthlySalesReportAnalytics.trackLinkToProductsSalesClick}
          >
            {row.numberOfProducts}
          </ProductLink>
        </td>
        <td className={tableStyle.numeric}>
          <ProductLink
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
            className={tableStyle.link}
            fromDate={startOfMonth}
            toDate={endOfMonth}
            employeeFilter={employeeFilter}
            onClick={MonthlySalesReportAnalytics.trackLinkToProductsSalesClick}
          >
            {Wahanda.Currency.formatFinancial(row.productGrossTotal)}
          </ProductLink>
        </td>
        <td className={tableStyle.numeric}>{row.numberOfVouchers}</td>
        <td className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(row.voucherGrossTotal)}
        </td>
        <td className={tableStyle.grandTotal}>
          {Wahanda.Currency.formatFinancial(row.grossTotal)}
        </td>
      </tr>
    );
  };

  render() {
    const tableRows = this.props.reportData.rows.map((row) =>
      this.getTableRow(
        row,
        row.month.format(Wahanda.lang.reports.sales.monthlySalesReport.headings.dateFormat),
      ),
    );
    return (
      <div>
        <table className={tableStyle.reportTable}>
          {this.getTableHeader()}
          <tbody>{tableRows}</tbody>
          {this.getTableFooter(this.props.reportData.total)}
        </table>
      </div>
    );
  }
}
