import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Eye extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-eye', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M3.3905488,12.1815275 L3.42989119,12.2374732 C3.51493302,12.3676483 3.70063557,12.6201471 3.98266009,12.9538959 C4.46099768,13.5199622 5.02780839,14.0876777 5.67765192,14.6166573 C7.51409744,16.1115437 9.62762738,17 12,17 C14.3726679,17 16.4843306,16.1137134 18.3176227,14.622589 C18.9662602,14.095014 19.5317984,13.5288214 20.0088803,12.9642905 C20.2901273,12.6314909 20.4752222,12.3797544 20.6106236,12.179303 C20.6690163,12.1049129 20.6692479,11.897608 20.5701088,11.7625268 C20.485067,11.6323517 20.2993644,11.3798529 20.0173399,11.0461041 C19.5390023,10.4800378 18.9721916,9.91232227 18.3223481,9.38334266 C16.4859026,7.88845628 14.3723726,7 12,7 C9.62733213,7 7.51566941,7.88628657 5.68237728,9.37741097 C5.03373981,9.90498601 4.46820157,10.4711786 3.99111972,11.0357095 C3.70987275,11.3685091 3.52477781,11.6202456 3.38937637,11.820697 C3.33098373,11.8950871 3.33075214,12.102392 3.3905488,12.1815275 Z M1.76543697,10.6565483 C2.24226848,9.92622696 3.13105177,8.87452964 4.42038951,7.82583536 C6.58536814,6.0649308 9.12266787,5 12,5 C14.8776274,5 17.4168321,6.06739551 19.5849443,7.83226212 C20.8759633,8.88316545 21.7664809,9.93700699 22.2051335,10.6127371 C22.8083042,11.4109791 22.8069531,12.6203847 22.234563,13.3434517 C21.7577315,14.073773 20.8689482,15.1254704 19.5796105,16.1741646 C17.4146319,17.9350692 14.8773321,19 12,19 C9.12237262,19 6.58316792,17.9326045 4.41505571,16.1677379 C3.1386698,15.128746 2.25375777,14.0868822 1.7719366,13.3563205 C1.19182463,12.5580508 1.2003947,11.3703333 1.76543697,10.6565483 Z M12,16 C9.790861,16 8,14.209139 8,12 C8,9.790861 9.790861,8 12,8 C14.209139,8 16,9.790861 16,12 C16,14.209139 14.209139,16 12,16 Z M12,14 C13.1045695,14 14,13.1045695 14,12 C14,10.8954305 13.1045695,10 12,10 C10.8954305,10 10,10.8954305 10,12 C10,13.1045695 10.8954305,14 12,14 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Eye);
