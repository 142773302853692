import React, { useEffect, useState } from 'react';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import { useMutation } from 'react-query';
import channelCodes from 'config/channelCodes';
import apiUrl from 'common/api-url';
import { openFileWithPost } from 'services/openFileWithPost';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/common/Modal';
import { Form, Textarea } from 'components/common/Form';
import { ReceiptCopyDialogFields } from './fields';
import { ReceiptCopyDialogAnalytics } from './analytics';
import { Notice } from 'components/common/Notice';
import style from './ReceiptCopyDialog.scss';

interface FormValues {
  reason: string;
}

interface RequestPayload {
  reason: string | null;
}

interface ReceiptCopyDialogProps {
  onClose: () => void;
  receiptCopyUri: string;
}

export const ReceiptCopyDialog = (props: ReceiptCopyDialogProps) => {
  const { onClose, receiptCopyUri } = props;
  // This dialog is called from multiple places and encapsulates logic that should not belong here
  // Keeping this logic here to have less logic scattered around multiple places
  const isReasonRequired = App.domainChannelCode() === channelCodes.FR;
  const [error, setError] = useState<any>();

  const getErrorMessage = () => {
    const expectedError = error?.errors?.map(({ message }) => message).join('. ');
    return expectedError || JSON.stringify(error);
  };

  const [downloadCopy, { isLoading }] = useMutation(
    (formData: RequestPayload) =>
      openFileWithPost(
        apiUrl('POS_TRANSACTION_RECEIPT', { receiptId: receiptCopyUri }),
        receiptCopyUri,
        formData,
      ),
    {
      onSuccess: onClose,
      onError: setError,
    },
  );

  const handleSubmit = (formData: FormValues) => {
    ReceiptCopyDialogAnalytics.trackSubmit();
    setError(null);
    downloadCopy(formData);
  };

  useEffect(() => {
    if (!isReasonRequired) {
      downloadCopy({ reason: null });
      return;
    }

    ReceiptCopyDialogAnalytics.trackDialogRendered();
  }, []);

  if (!isReasonRequired) {
    return null;
  }

  return (
    <ModalContainer onClose={onClose} zIndex={1010}>
      <Form<FormValues> onSubmit={handleSubmit} inModal>
        {({ errors }) => {
          const fields = ReceiptCopyDialogFields({ errors });

          return (
            <>
              <ModalHeader title={Wahanda.lang.pos.receiptCopyDialog.header} onClose={onClose} />
              <ModalBody>
                {error && (
                  <div className={style.noticeWrapper}>
                    <Notice type="alert" message={getErrorMessage()} />
                  </div>
                )}
                <Textarea {...fields.reason} />
              </ModalBody>
              <ModalFooter alignEnd={false}>
                <Button label="Close" colour="plain" variant="secondary" onClick={onClose} />
                <Button
                  type="submit"
                  disabled={isLoading}
                  label={Wahanda.lang.pos.receiptCopyDialog.submitLabel}
                  size="medium"
                  dataTest="receipt-copy-reason-submit"
                />
              </ModalFooter>
            </>
          );
        }}
      </Form>
    </ModalContainer>
  );
};
