export const sanitizeValues = (
  fields: Record<string, string>,
): { valids: Record<string, string>; invalids: Record<string, string> } => {
  const valids: Record<string, string> = {};
  const invalids: Record<string, string> = {};

  Object.entries(fields).forEach(([key, value]) => {
    if (isValidSalesforceValue(value)) {
      valids[key] = String(value);
    } else {
      invalids[key] = value;
    }
  });

  return { valids, invalids };
};

const isValidSalesforceValue = (value: unknown) => value !== undefined;
