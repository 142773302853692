import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Overnight extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-overnight', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M7.00200475,9 C7.89290961,9 8.3390764,10.0771419 7.70911154,10.7071068 L5.41621832,13 L8.00200475,13 L8.00200475,15 L3.00200475,15 C2.1110999,15 1.66493311,13.9228581 2.29489797,13.2928932 L4.58779119,11 L2.00200475,11 L2.00200475,9 L7.00200475,9 Z M14.0020048,6 C14.8929096,6 15.3390764,7.07714192 14.7091115,7.70710678 L12.4162183,10 L15.0020048,10 L15.0020048,12 L10.0020048,12 C9.1110999,12 8.66493311,10.9228581 9.29489797,10.2928932 L11.5877912,8 L9.00200475,8 L9.00200475,6 L14.0020048,6 Z M7.00200475,1 C7.89290961,1 8.3390764,2.07714192 7.70911154,2.70710678 L5.41621832,5 L8.00200475,5 L8.00200475,7 L3.00200475,7 C2.1110999,7 1.66493311,5.92285808 2.29489797,5.29289322 L4.58779119,3 L2.00200475,3 L2.00200475,1 L7.00200475,1 Z" />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-overnight', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M7.00200475,9 C7.89290961,9 8.3390764,10.0771419 7.70911154,10.7071068 L5.41621832,13 L8.00200475,13 L8.00200475,15 L3.00200475,15 C2.1110999,15 1.66493311,13.9228581 2.29489797,13.2928932 L4.58779119,11 L2.00200475,11 L2.00200475,9 L7.00200475,9 Z M14.0020048,6 C14.8929096,6 15.3390764,7.07714192 14.7091115,7.70710678 L12.4162183,10 L15.0020048,10 L15.0020048,12 L10.0020048,12 C9.1110999,12 8.66493311,10.9228581 9.29489797,10.2928932 L11.5877912,8 L9.00200475,8 L9.00200475,6 L14.0020048,6 Z M7.00200475,1 C7.89290961,1 8.3390764,2.07714192 7.70911154,2.70710678 L5.41621832,5 L8.00200475,5 L8.00200475,7 L3.00200475,7 C2.1110999,7 1.66493311,5.92285808 2.29489797,5.29289322 L4.58779119,3 L2.00200475,3 L2.00200475,1 L7.00200475,1 Z" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Overnight);
