import { ActionUnionTypes } from 'reduxStore/actionCreator';
import {
  INIT_STRIPE_REQUEST_SUCCESS,
  STRIPE_PAYMENT_FAILURE,
  CLEAR_STRIPE,
  CONFIRM_STRIPE_PAYMENT_REQUEST_SUCCESS,
  MAKE_CARD_PAYMENT,
  MAKE_IDEAL_PAYMENT,
  MAKE_IDEAL_PAYMENT_FAILURE,
} from 'reduxStore/actionsConst';
import * as stripeActions from './actions';

export interface StripeReducer {
  idealPaymentInProgress: boolean;
  paymentInProgress: boolean;
  error?: boolean;
  success?: boolean;
  stripePublicApiKey?: string;
  paymentIntentClientSecret?: string;
  supplierPaymentId?: string;
}

export const initialState: StripeReducer = {
  paymentInProgress: false,
  idealPaymentInProgress: false,
};

export const stripeReducer = (
  state = initialState,
  action: ActionUnionTypes<typeof stripeActions>,
): StripeReducer => {
  switch (action.type) {
    case CLEAR_STRIPE:
      return initialState;
    case INIT_STRIPE_REQUEST_SUCCESS:
      return {
        ...initialState,
        ...action.payload,
      };
    case CONFIRM_STRIPE_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        error: undefined,
        success: true,
      };
    case STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        paymentInProgress: false,
        error: true,
      };
    case MAKE_IDEAL_PAYMENT:
      return {
        ...state,
        idealPaymentInProgress: true,
      };
    case MAKE_CARD_PAYMENT:
      return {
        ...state,
        error: undefined,
        paymentInProgress: true,
      };
    case MAKE_IDEAL_PAYMENT_FAILURE:
      return {
        ...state,
        idealPaymentInProgress: false,
      };
    default:
      return state;
  }
};
