import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ExternalSalonIntegration from './index';
import * as actions from './actions';
import externalSalonSystemSelector from './selectors';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = ({ externalSalonIntegration: state }) => ({
  externalSystems: state.externalSalonSystems,
  externalSalonSystem: externalSalonSystemSelector(state),
  employeeLinks: state.employeeLinks,
  testConnectionFail: state.testConnectionFail,
  step: state.step,
  loading: state.loading,
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ step: null; externalSalonSystem: {}; emplo... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(ExternalSalonIntegration);
