import { send as sendWSCommand, onStateChange, listen, stopListening } from 'common/websockets';
import { LISTEN_CALENDAR } from 'common/websockets/commands';
import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import _ from 'common/underscore';
import DEBOUNCE_TIME from './config';

export default function setup(type, onChange, onWebSocketsConnected) {
  let connected = false;

  const onChangeDebounced = _.debounce(onChange, DEBOUNCE_TIME);

  function removeListeners() {
    stopListening(type, onChangeDebounced);
  }

  function setupLiseners() {
    sendWSCommand(LISTEN_CALENDAR, { venueId: App.getVenueId() });

    listen(type, onChangeDebounced);
  }

  function onConnected() {
    connected = true;
    setupLiseners();
    if (onWebSocketsConnected) {
      onWebSocketsConnected();
    }
  }

  function onDisconnected() {
    removeListeners();
    connected = false;
  }

  onStateChange(onConnected, onDisconnected);

  App.on(Wahanda.Event.APP_VENUE_CHANGED, () => {
    removeListeners();

    if (connected) {
      setupLiseners();
    }
  });
}
