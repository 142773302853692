import React from 'react';
import Wahanda from 'common/wahanda';
import ReviewDetailsRow from '../Row';
import style from './VenueRating.scss';

const LANG = Wahanda.lang.dashboard.reviews.details;
const REVIEW_TYPE = {
  AMBIANCE: 'AMBIENCE',
  CLEANLINESS: 'CLEANLINESS',
  SPA: 'SPA_FACILITIES',
  STAFF: 'STAFF',
};
type VenueRatingProps = {
  ratings?: any[];
};
const VenueRating: React.SFC<VenueRatingProps> = (props) => {
  const { ratings } = props;
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const ambiance = ratings.find((rating) => rating.venueReviewRatingType === REVIEW_TYPE.AMBIANCE);
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const cleanliness = ratings.find(
    (rating) => rating.venueReviewRatingType === REVIEW_TYPE.CLEANLINESS,
  );
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const staff = ratings.find((rating) => rating.venueReviewRatingType === REVIEW_TYPE.STAFF);
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const spaFacilities = ratings.find((rating) => rating.venueReviewRatingType === REVIEW_TYPE.SPA);
  const { rating: ambianceRating = null } = ambiance || {};
  const { rating: cleanlinessRating = null } = cleanliness || {};
  const { rating: spaFacilitiesRating = null } = spaFacilities || {};
  const { rating: staffRating = null } = staff || {};
  return (
    <div className={style.venueRating}>
      <h2>{LANG.venueRating}</h2>
      {ambianceRating && (
        <ReviewDetailsRow classes="ambiance" name={LANG.ambiance} rating={ambianceRating} />
      )}
      {cleanlinessRating && (
        <ReviewDetailsRow
          classes="cleanliness"
          name={LANG.cleanliness}
          rating={cleanlinessRating}
        />
      )}
      {spaFacilitiesRating && (
        <ReviewDetailsRow name={LANG.spaFacilities} rating={spaFacilitiesRating} />
      )}
      {staffRating && <ReviewDetailsRow classes="staff" name={LANG.staff} rating={staffRating} />}
    </div>
  );
};

export default VenueRating;
