import React from 'react';

let KEYS = 0;
// eslint-disable-next-line no-return-assign
const createErrorListItemKey = (key) =>
  `error_${key
    .toString()
    .substr(0, 20)
    .replace(/[^a-zA-Z0-9]+/, '_')}_${(KEYS += 1)}`.toLowerCase();

const mapErrorList = ({ for: id, text }) => {
  const key = createErrorListItemKey(id || text);
  return id ? (
    <label key={key} htmlFor={id}>
      {text}
    </label>
  ) : (
    <span key={key}>{text}</span>
  );
};
type ErrorListProps = {
  errors?: {
    for?: string;
    text: string;
  }[];
  className?: string;
};
const ErrorList: React.SFC<ErrorListProps> = ({ errors, className }) => (
  <div className="dialog2--messages">
    <div className={`dialog2--message ${className}`}>
      {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
      <div className="form-errors">{errors.map(mapErrorList)}</div>
    </div>
  </div>
);
ErrorList.defaultProps = {
  className: 'error',
};

export default ErrorList;
