import { trackEvent } from 'common/analytics';

export class AvailabilityAnalytics {
  public static CATEGORY = 'appointment-unavailable';

  public static trackWarning(appointmentId, closedSlot) {
    if (!closedSlot.employeeAvailable) {
      trackEvent(
        AvailabilityAnalytics.CATEGORY,
        'warning',
        AvailabilityAnalytics.employeeStatus(closedSlot),
        appointmentId,
      );
    }

    if (closedSlot.unavailableResources.length > 0) {
      trackEvent(
        AvailabilityAnalytics.CATEGORY,
        'warning',
        AvailabilityAnalytics.resourceStatus(closedSlot),
        appointmentId,
      );
    }
  }

  public static trackDateChange(appointmentId) {
    trackEvent(AvailabilityAnalytics.CATEGORY, 'changed', 'date', appointmentId);
  }

  public static trackStartTimeChange(appointmentId) {
    trackEvent(AvailabilityAnalytics.CATEGORY, 'changed', 'start_time', appointmentId);
  }

  public static trackDurationChange(appointmentId) {
    trackEvent(AvailabilityAnalytics.CATEGORY, 'changed', 'duration', appointmentId);
  }

  public static trackEmployeeChange(appointmentId) {
    trackEvent(AvailabilityAnalytics.CATEGORY, 'changed', 'employee', appointmentId);
  }

  public static trackSave(appointmentId, closedSlot) {
    trackEvent(
      AvailabilityAnalytics.CATEGORY,
      'saved',
      AvailabilityAnalytics.employeeStatus(closedSlot),
      appointmentId,
    );

    trackEvent(
      AvailabilityAnalytics.CATEGORY,
      'saved',
      AvailabilityAnalytics.resourceStatus(closedSlot),
      appointmentId,
    );
  }

  public static trackDismiss(appointmentId, closedSlot) {
    trackEvent(
      AvailabilityAnalytics.CATEGORY,
      'dismissed',
      AvailabilityAnalytics.employeeStatus(closedSlot),
      appointmentId,
    );

    trackEvent(
      AvailabilityAnalytics.CATEGORY,
      'dismissed',
      AvailabilityAnalytics.resourceStatus(closedSlot),
      appointmentId,
    );
  }

  public static employeeStatus(closedSlot) {
    const isAvailable = !closedSlot || closedSlot.employeeAvailable;
    return isAvailable ? 'employee_available' : 'employee_unavailable';
  }

  public static resourceStatus(closedSlot) {
    const isAvailable = !closedSlot || closedSlot.unavailableResources.length === 0;
    return isAvailable ? 'resources_available' : 'resources_unavailable';
  }
}
