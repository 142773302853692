import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../store/actions';
import { ServiceRowEmployees as Component } from './ServiceRowEmployees';

const mapStateToProps = ({ menu: { menuTemplates } }) => ({
  employeesCollection: menuTemplates.employeesCollection,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ServiceRowEmployees = connect(mapStateToProps, mapDispatchToProps)(Component);
