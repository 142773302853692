import { TRACK_SERVICE_EDIT, RESET_SERVICE_EDIT_TRACKING } from 'reduxStore/actionsConst';

export const initialState = {
  editActions: [],
};

export function servicesTabReducer(state = initialState, action) {
  switch (action.type) {
    case TRACK_SERVICE_EDIT:
      return { ...state, editActions: [...state.editActions, action.label] };
    case RESET_SERVICE_EDIT_TRACKING:
      return { ...initialState, editActions: [] };
    default:
      return state;
  }
}
