import Wahanda from 'common/wahanda';
import {
  GET_EXTERNAL_SALON_SYSTEM_SELECT,
  GET_EXTERNAL_SALON_INTEGRATION_REQUEST,
  GET_EXTERNAL_SALON_INTEGRATION_RECEIVE,
  GET_EXTERNAL_SALON_INTEGRATION_CREATE_REQUEST,
  GET_EXTERNAL_SALON_INTEGRATION_CREATE_RECEIVE,
  GET_EXTERNAL_SALON_EMPLOYEE_LINK_ADD,
  GET_EXTERNAL_SALON_EMPLOYEE_LINK_REMOVE,
  GET_EXTERNAL_SALON_INTEGRATION_DELETE_REQUEST,
  GET_EXTERNAL_SALON_INTEGRATION_DELETE_RECEIVE,
  GET_EXTERNAL_SALON_EMPLOYEES_REQUEST,
  GET_EXTERNAL_SALON_EMPLOYEES_RECEIVE,
  GET_EXTERNAL_SALON_SYSTEMS_REQUEST,
  GET_EXTERNAL_SALON_SYSTEMS_RECEIVE,
  GET_EXTERNAL_SALON_INTEGRATION_FAILURE,
  GET_EXTERNAL_SALON_INTEGRATION_RESET,
} from 'reduxStore/actionsConst';

export const SYSTEM_SELECTION_STEP = 0;

export const TEST_CONNECTION_STEP = 1;

export const EMPLOYEES_MAPPING_STEP = 2;

export const FINISH_STEP = 3;

export const OTHER_SYSTEM_ID = -1;

export const initialState = {
  step: null,
  externalSalonSystemId: null,
  externalSalonSystems: [],
  externalEmployees: [],
  employeeLinks: [],
  testConnectionFail: false,
  serverError: false,
  loading: false,
};

function getStep(state, data) {
  if (data.externalSalonSystemId == null) {
    return state.step === TEST_CONNECTION_STEP ? TEST_CONNECTION_STEP : SYSTEM_SELECTION_STEP;
  }

  return data.employeeLinks.length > 0 ? FINISH_STEP : EMPLOYEES_MAPPING_STEP;
}

export default function externalSalonIntegration(state = initialState, action) {
  switch (action.type) {
    case GET_EXTERNAL_SALON_SYSTEM_SELECT:
      return {
        ...state,
        step:
          action.id != null && action.id !== OTHER_SYSTEM_ID
            ? TEST_CONNECTION_STEP
            : SYSTEM_SELECTION_STEP,
        externalSalonSystemId: action.id,
        testConnectionFail: false,
      };

    case GET_EXTERNAL_SALON_INTEGRATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_EXTERNAL_SALON_INTEGRATION_RECEIVE: {
      const step = getStep(state, action.data);
      return {
        ...state,
        step,
        testConnectionFail: step === TEST_CONNECTION_STEP && state.step === step,
        externalSalonSystemId:
          step === TEST_CONNECTION_STEP
            ? state.externalSalonSystemId
            : action.data.externalSalonSystemId,
        employeeLinks: action.data.employeeLinks,
        loading: false,
      };
    }

    case GET_EXTERNAL_SALON_INTEGRATION_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_EXTERNAL_SALON_INTEGRATION_CREATE_RECEIVE:
      return {
        ...state,
        step: FINISH_STEP,
        loading: false,
      };

    case GET_EXTERNAL_SALON_EMPLOYEE_LINK_ADD:
      return {
        ...state,
        employeeLinks: [
          ...state.employeeLinks.filter(
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'externalEmployeeId' does not exist on ty... Remove this comment to see the full error message
            (l) => l.externalEmployeeId !== action.data.externalEmployeeId,
          ),
          action.data,
        ],
      };

    case GET_EXTERNAL_SALON_EMPLOYEE_LINK_REMOVE:
      return {
        ...state,
        employeeLinks: state.employeeLinks.filter(
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'externalEmployeeId' does not exist on ty... Remove this comment to see the full error message
          (l) => l.externalEmployeeId !== action.externalEmployeeId,
        ),
      };

    case GET_EXTERNAL_SALON_INTEGRATION_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_EXTERNAL_SALON_INTEGRATION_DELETE_RECEIVE:
      return {
        ...state,
        step: SYSTEM_SELECTION_STEP,
        externalSalonSystemId: null,
        externalEmployees: [],
        employeeLinks: [],
        loading: false,
      };

    case GET_EXTERNAL_SALON_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_EXTERNAL_SALON_EMPLOYEES_RECEIVE:
      return {
        ...state,
        externalEmployees: action.data,
        loading: false,
      };

    case GET_EXTERNAL_SALON_SYSTEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_EXTERNAL_SALON_SYSTEMS_RECEIVE:
      return {
        ...state,
        externalSalonSystems: [
          ...action.data,
          {
            id: OTHER_SYSTEM_ID,
            name: Wahanda.lang.settings.venue.integrations.selectExternalSystem.other,
            key: 'OTHER',
          },
        ],
        loading: false,
      };

    case GET_EXTERNAL_SALON_INTEGRATION_FAILURE:
      return {
        ...state,
        serverError: true,
        loading: false,
      };

    case GET_EXTERNAL_SALON_INTEGRATION_RESET:
      return initialState;

    default:
      return state;
  }
}
