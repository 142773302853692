/* global Wahanda App */
(function () {
  function redirectWithParams(urlKey, dayCollection, itemList) {
    var firstItem = dayCollection.at(0);
    var rescheduleDate = Wahanda.Date.toApiString(firstItem.getStartDate());
    var customerId = (function () {
      var appt =
        firstItem instanceof App.Collections.AppointmentGroup ? firstItem.at(0) : firstItem;

      return appt.getCustomer().id;
    })();

    var idList;
    if (itemList) {
      idList = itemList
        .map(function (item) {
          return item.getIdHash();
        })
        .join(';');
    } else {
      idList = '-1';
    }

    window.location =
      '/calendar#venue/' +
      App.getVenueId() +
      '/' +
      urlKey +
      '/' +
      idList +
      '/' +
      customerId +
      '/' +
      rescheduleDate;
  }

  Wahanda.Appointment = {
    /**
     * Enter rescheduling mode for the given list of appointments/appt groups.
     *
     * If in Calendar module, this will enter rescheduling mode.
     * If in any other module, it will redirect to Calendar module and then start the rescueduling mode.
     *
     * @param {Array} list of objects to reschedule: Appointment and/or AppointmentGroup
     * @param {App.Models.CustomerAppointments} dayCollection The collection of appointments/appt groups for an
     *   customer on a day.
     * @param {Object} maybePos (optional) Maybe a position of the shadow to show. Should be based on the initiating
     *   (e.g. click on button) event, clientX and clientY.
     * @returns {void}
     */
    enterReschedulingMode: function (list, dayCollection, maybePos, isWithinCancellation) {
      if (App.mainView instanceof App.Views.Calendar) {
        App.mainView.startReschedulingMode(list, {
          initialPosition: maybePos,
          dayCollection: dayCollection,
          isWithinCancellation: isWithinCancellation,
        });
      } else {
        // Redirect to Calendar module and open up the rescheduling mode
        redirectWithParams('appointment-reschedule', dayCollection, list);
      }
    },

    enterRebookingMode: function (dayCollection, maybePos) {
      if (App.mainView instanceof App.Views.Calendar) {
        App.mainView.startRebookingMode(dayCollection, {
          initialPosition: maybePos,
        });
      } else {
        // Redirect to Calendar module and open up the rescheduling mode
        redirectWithParams('appointment-rebooking', dayCollection);
      }
    },

    openUDV: function (appointmentData, blockData, maybeTab) {
      App.ES6.Initializers.State.change({
        'calendar-event-editor': {
          appointmentViewData: appointmentData,
          blockViewData: blockData,
          tab: maybeTab || 'appointment',
        },
      });
    },
  };
})();
