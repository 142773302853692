import { createAction } from 'reduxStore/actionCreator';
import {
  GET_APPOINTMENT_AVAILABILITY_REQUEST,
  GET_APPOINTMENT_AVAILABILITY_RECEIVE,
  GET_APPOINTMENT_AVAILABILITY_FAILURE,
} from 'reduxStore/actionsConst';
import { AvailabilityParams, AvailabilityResponse } from './types';

export const getAppointmentAvailabilityRequestAction = (payload: AvailabilityParams) =>
  createAction(GET_APPOINTMENT_AVAILABILITY_REQUEST, payload);

export const getAppointmentAvailabilitySuccessAction = (payload: AvailabilityResponse) =>
  createAction(GET_APPOINTMENT_AVAILABILITY_RECEIVE, payload);

export const getAppointmentAvailabilityFailureAction = () =>
  createAction(GET_APPOINTMENT_AVAILABILITY_FAILURE);
