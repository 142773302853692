import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import * as actions from './store/actions';
import { ShiftsContainer as Container } from './ShiftsContainer';

const mapStateToProps = ({ shifts }) => ({
  canManageVenueDetails: Wahanda.Permissions.manageVenueDetails(),
  datePickerRange: shifts.datePickerRange,
  employeeId: App.getEmployeeId(),
  employees: shifts.employees,
  isEmployeesLoaded: shifts.isEmployeesLoaded,
  isLoading: shifts.isLoading,
  venue: shifts.venue,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const ShiftsContainer = connect(mapStateToProps, mapDispatchToProps)(Container);
