import React from 'react';
import styles from './../OnboardingWidget.scss';
import { Badge } from 'components/common/Badge';
import { Tick } from 'components/common/Icon';

export const ChecklistCompleted = ({ tasks }: { tasks: number }) => {
  const stepsCompletedText = Wahanda.Template.render(
    Wahanda.lang.onboardingWidget.completed.steps,
    {
      current: tasks,
      total: tasks,
    },
  );

  return (
    <section className={styles.container}>
      <h6 className={styles.title}>{Wahanda.lang.onboardingWidget.completed.title}</h6>
      <p className={styles.description}>{Wahanda.lang.onboardingWidget.completed.description}</p>
      <p className={styles.checked}>
        <Badge type="success" isLight icon={<Tick size="tiny" />} />
        {stepsCompletedText}
      </p>
    </section>
  );
};
