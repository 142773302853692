import React from 'react';
import classnames from 'classnames';
import { useDebounce } from 'utilities/hooks';
import { InputBase, InputSizeType, InputText } from 'components/common/__BaseCommon';
import { CurrencyFormatter, useCurrency } from 'components/common/DataFormat';
import styles from './CurrencyInput.scss';

interface Props {
  autoFocus?: boolean;
  name: string;
  disabled?: boolean;
  size?: InputSizeType;
  dataTest?: string;
  value?: number;
  fullBorder?: boolean;
  forceDecimalPoint?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  label?: string;
  required?: boolean;
  onChange?: (value: number) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

export const CurrencyInput = ({
  autoFocus = false,
  name,
  disabled,
  dataTest,
  size = InputSizeType.DEFAULT,
  value,
  hasError,
  errorMessage,
  fullBorder,
  onChange,
  onBlur,
  onFocus,
  label,
  required,
}: Props) => {
  const { displayInFront, symbol, currencyCode, thousandsSeparator, decimalPoint } = useCurrency();
  const formatter = new CurrencyFormatter(thousandsSeparator, decimalPoint);
  const [viewValue, setViewValue] = React.useState(value ? formatter.formatNumber(value) : '0');
  const [modelValue, setModelValue] = React.useState(value);
  const debouncedModelValue = useDebounce(modelValue, 400);
  const [isFocused, setFocus] = React.useState(autoFocus);

  React.useEffect(() => {
    if (onChange) {
      onChange(debouncedModelValue);
    }
  }, [debouncedModelValue]);

  function handleOnFocus(event) {
    setFocus(true);
    if (onFocus) {
      onFocus();
    }
    if (event.target.value === '0') {
      setViewValue('');
      return;
    }
    try {
      const val = formatter.parseExpression(event.target.value);
      if (!isNaN(val)) {
        setViewValue(val);
      }
    } catch (e) {
      // Do nothing
    }
  }

  function handleOnBlur(event) {
    const parsedValue = formatter.parseExpression(event.target.value);
    setFocus(false);
    if (onBlur) {
      onBlur();
    }
    if (!event.target.value || parsedValue === 0) {
      setViewValue('0');
      setModelValue(0);
      return;
    }
    if (isNaN(parsedValue)) {
      return;
    }
    setModelValue(parsedValue);
    setViewValue(
      formatter.formatNumber(parsedValue, {
        decimalPoint,
        thousandsSeparator,
        forceDecimalPoint: true,
      }),
    );
  }

  function handleOnChange(event) {
    setViewValue(event.target.value);
    setModelValue(formatter.parseExpression(event.target.value));
  }

  const currencyClass = classnames({
    [styles.fullBorder]: fullBorder,
    [styles.prefix]: displayInFront,
    [styles.suffix]: !displayInFront,
  });
  const currency = <span className={currencyClass}>{symbol || currencyCode}</span>;
  const Prefix = () => (displayInFront && currency) || null;
  const Suffix = () => (!displayInFront && currency) || null;
  return (
    <InputBase
      size={size}
      disabled={disabled}
      label={label}
      hasError={hasError}
      required={required}
      errorMessage={errorMessage}
      fullBorder={fullBorder}
      isFocused={isFocused}
    >
      <Prefix />
      <InputText
        autoFocus={autoFocus}
        readOnly={disabled}
        dataTest={dataTest}
        alignRight={!displayInFront}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        name={name}
        value={viewValue}
      />
      <Suffix />
    </InputBase>
  );
};

CurrencyInput.displayName = 'CurrencyInput';
