import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'reduxStore/common/employees/actions';
import EmployeeProfilePhoto from './index';

const mapStateToProps = ({
  employees: {
    employee: { id, image },
  },
}) => ({
  id,
  imageUris: image ? image.uris : null,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ id: null; imageUris: null; }' is not assig... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProfilePhoto);
