const REPORT_TYPE = Object.freeze({
  SALES_OVERVIEW: 'salesOverview',
  PRODUCT_SALES: 'productSales',
  SERVICE_SALES: 'serviceSales',
  EMPLOYEE_SALES: 'employeeSales',
  CHANNEL_SALES: 'channelSales',
  DAILY_SALES: 'dailySales',
  MONTHLY_SALES: 'monthlySales',
  VAT: 'vat',
  CLIENT_ACQUISITION: 'clientAcquisition',
});

export default REPORT_TYPE;
