export const lastPaymentSelector = (state) => {
  const { lastPayment } = state.bankDetails;
  if (!lastPayment) {
    return undefined;
  }
  return {
    status: lastPayment.status,
    date: lastPayment.created,
    amount: lastPayment.amount,
    id: lastPayment.supplierPaymentId.id,
  };
};
