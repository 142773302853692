import React from 'react';
import classnames from 'classnames';
import { Chevron } from 'components/common/Icon';
import style from './FingerpostAction.scss';

export interface Props {
  onClick?: () => void;
  href?: string;
  dataTest?: string;
  icon?: React.ReactNode | null;
  label: string;
  subLabel?: string;
  badge?: JSX.Element;
}

export class FingerpostAction extends React.PureComponent<Props> {
  public render() {
    const { onClick, href, icon, label, subLabel, badge, dataTest } = this.props;
    const textClass = classnames(style.text, {
      [style.hasIcon]: !!icon,
    });

    return (
      <a className={style.actionLink} onClick={onClick} href={href} data-test={dataTest}>
        {icon}
        <div className={textClass}>
          <div className={style.label}>
            {label}
            {!!badge && <div className={style.badge}>{badge}</div>}
          </div>
          {!!subLabel && <div className={style.subLabel}>{subLabel}</div>}
        </div>
        <Chevron className={style.icon} />
      </a>
    );
  }
}
