import React from 'react';
import Wahanda from 'common/wahanda';
import { Analytics } from '../tracking';
import style from '../CovidBanner.scss';

const LANG = Wahanda.lang.shared.covidBanner;
interface Props {
  hasFullPermissions: boolean;
  showVenueClosingDialog: () => void;
}

export const Closed: React.SFC<Props> = ({ hasFullPermissions, showVenueClosingDialog }) => {
  const currentlyClosedTextNode = React.useRef<HTMLSpanElement>(null);
  const handleHelpCenterClick = () => {
    Analytics.trackHowToReopenClicked();
  };
  const handleNotPlanningToReopenClick = () => {
    showVenueClosingDialog();
  };
  React.useLayoutEffect(() => {
    const link:
      | HTMLAnchorElement
      | undefined = currentlyClosedTextNode?.current?.getElementsByTagName('a')[0];
    if (link) {
      link.setAttribute('href', LANG.howToReopenHelpCenterLink);
      link.onclick = handleHelpCenterClick;
      link.classList.add(style.link);
      link.setAttribute('data-test', 'reopen-help-center-link');
    }
  }, []);
  return (
    <>
      <span
        ref={currentlyClosedTextNode}
        dangerouslySetInnerHTML={{ __html: LANG.currentlyClosed }}
      />{' '}
      {hasFullPermissions && (
        <>
          <span>{LANG.notPlanning} </span>
          <a
            onClick={handleNotPlanningToReopenClick}
            data-test="not-planning-to-reopen-link"
            className={style.link}
          >
            {LANG.clickHere}
          </a>
        </>
      )}
    </>
  );
};
