import React from 'react';
import { CircleRating } from 'components/dashboard/CircleRating';

const CIRCLE_RADIUS = 40;
// @ts-expect-error ts-migrate(2430) FIXME: Type '{}' is not assignable to type 'string'.
interface IHeaderProps extends React.HTMLAttributes<Element> {
  percentage: number;
  lang: {};
}

export default class Header extends React.Component<IHeaderProps, {}> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'root' has no initializer and is not defi... Remove this comment to see the full error message
  root: HTMLDivElement;

  render() {
    const { percentage, lang } = this.props;
    return (
      <div
        className="block-heading"
        ref={(root) => {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLDivElem... Remove this comment to see the full error message
          this.root = root;
        }}
      >
        <CircleRating percentage={percentage} radius={CIRCLE_RADIUS} />
        <div className="side">
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{}'. */}
          <h3>{lang.title}</h3>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'body' does not exist on type '{}'. */}
          <div className="sub-content">{lang.body}</div>
        </div>
        <div className="full-break" />
      </div>
    );
  }
}
