App.Views.Forms.MenuOffer2.TabBase = BackboneEx.View.Base.extend({
  initialize: function () {
    this.formFieldPrefix = 'offer2-' + this.cid + '-';
  },

  getTabId: function () {
    return 'offer2-tab-' + this.cid;
  },

  render: function () {
    this.$el.html('This tab is for ' + this.tabName);
  },
});

BackboneEx.Mixin.View.Form.mixin(App.Views.Forms.MenuOffer2.TabBase);
