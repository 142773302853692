import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class External extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-external', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M5 3v2H3v8h8v-2h2v2a2 2 0 01-2 2H3a2 2 0 01-2-2V5a2 2 0 012-2h2zm10 6h-2V4.414L7.707 9.707 6.293 8.293 11.584 3H7V1h8v8z" />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-external', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M5 3v2H3v8h8v-2h2v2a2 2 0 01-2 2H3a2 2 0 01-2-2V5a2 2 0 012-2h2zm10 6h-2V4.414L7.707 9.707 6.293 8.293 11.584 3H7V1h8v8z" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(External);
