import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { downloadCoronaReportRequestAction, resetCoronaReportState } from './actions';
import { CoronaReportModal as Component } from './CoronaReportModal';

const mapStateToProps = (state) => ({
  status: state.coronaReport.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { downloadCoronaReportRequestAction, resetCoronaReportState },
    dispatch,
  ),
});

export const CoronaReportModal = connect(mapStateToProps, mapDispatchToProps)(Component);
