import Wahanda from 'common/wahanda';

App.Models.Checkout = BackboneEx.Model.Silent.extend({
  defaults: {},

  initialize: function initialize() {},

  getDateTime: function getDateTime() {
    const date = Wahanda.Date.parse(this.get('checkoutInformation').created);
    const dateTime = Wahanda.Date.formatToApiFullDate(date);
    return dateTime;
  },

  isCancelled: function isCancelled() {
    return this.get('checkoutInformation').cancellationId > 0;
  },

  getCheckoutId: function getCheckoutId() {
    return this.get('checkoutInformation').checkoutId;
  },

  markCanceled: function markCanceled(cancellationId) {
    this.get('checkoutInformation').cancellationId = cancellationId;
  },
});
