import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Hotel extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-hotel', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M7,13.5 C8.66,13.5 10,12.16 10,10.5 C10,8.84 8.66,7.5 7,7.5 C5.34,7.5 4,8.84 4,10.5 C4,12.16 5.34,13.5 7,13.5 Z M7,9.5 C7.55,9.5 8,9.95 8,10.5 C8,11.05 7.55,11.5 7,11.5 C6.45,11.5 6,11.05 6,10.5 C6,9.95 6.45,9.5 7,9.5 Z M19,7.5 L11,7.5 L11,14.5 L3,14.5 L3,4.5 L1,4.5 L1,19.5 L3,19.5 L3,16.5 L21,16.5 L21,19.5 L23,19.5 L23,11.5 C23,9.29 21.21,7.5 19,7.5 Z M21,14.5 L13,14.5 L13,9.5 L19,9.5 C20.1,9.5 21,10.4 21,11.5 L21,14.5 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Hotel);
