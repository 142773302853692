import { useMediaQuery } from 'utilities/hooks';

enum MediaQuery {
  mobile = '(max-width: 568px)',
  tablet = '(max-width: 1024px)',
  desktop = '(min-width: 1025px)',
}

export const useBreakpoint = (breakpoint: 'mobile' | 'tablet' | 'desktop') => {
  const isBreakpoint = useMediaQuery(MediaQuery[breakpoint]);

  return isBreakpoint;
};
