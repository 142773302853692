import React from 'react';
import Wahanda from 'common/wahanda';
import Dialog from 'components/common/react-dialog';
import { DialogFooter } from 'components/common/DialogFooter';
import style from './ClientsImportInit.scss';
import { DownloadTemplate } from './DownloadTemplate/container';
import { FillInDetails } from './FillInDetails';
import { FileUpload } from './FileUpload';
import { Step } from './Step';
import { ClientsAnalytics } from '../../tracking';
import { SelectedFile } from '../types';

const lang = Wahanda.lang.clientsImport;

export interface Props {
  onClose: () => void;
  isFileUploading: boolean;
  selectedFile: SelectedFile;
  actions: {
    clientsFileUploadRequest: (payload: SelectedFile) => void;
    clientsImportReset: () => void;
  };
}

export class ClientsImportInit extends React.PureComponent<Props> {
  public render() {
    return (
      <Dialog
        dataTest="clients-import-modal"
        title={lang.addMultipleClients.header}
        width={600}
        onClose={this.onClose}
        restrictHeight
        noContentPadding
      >
        {this.renderContent()}
        {this.renderFooter()}
      </Dialog>
    );
  }

  private renderContent = () => (
    <div className={style.container}>
      <Step count={1}>
        <DownloadTemplate />
      </Step>
      <Step count={2}>
        <FillInDetails />
      </Step>
      <Step count={3} withLine={false}>
        <FileUpload />
      </Step>
    </div>
  );

  private renderFooter = () => (
    <DialogFooter
      buttons={[
        {
          type: 'secondary',
          title: Wahanda.lang.shared.close,
          onClick: this.onClose,
        },
        {
          title: lang.addMultipleClients.addClients,
          onClick: this.onAddClientsClick,
          isLoading: this.props.isFileUploading,
          disabled: this.props.isFileUploading || !this.isFileSelected(),
        },
      ]}
    />
  );

  private onClose = () => {
    ClientsAnalytics.trackClientsImportCloseClick();
    this.props.onClose();
    this.props.actions.clientsImportReset();
  };

  private isFileSelected = () =>
    this.props.selectedFile.file !== null && this.props.selectedFile.fileName !== null;

  private onAddClientsClick = () => {
    ClientsAnalytics.trackClientsImportAddClientsClick();
    this.props.actions.clientsFileUploadRequest(this.props.selectedFile);
  };
}
