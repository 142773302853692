import { ACTIONS, trackEvent, trackPageView } from 'common/analytics';
import { snake } from 'case';

export enum L1Page {
  CALENDAR = 'calendar',
  DASHBOARD = 'dashboard',
  MENU = 'menu',
  TEAM = 'team',
  PRODUCTS = 'products',
  CLIENTS_LIST = 'clients-list',
  MARKETING = 'marketing',
  REPORTS_DASHBOARD = 'reports-dashboard',
  SETTINGS = 'settings',
}

export enum L2Page {
  STATS = 'stats',
  GOALS = 'goals',
  CUSTOMER_REVIEWS = 'customer-reviews',
  SERVICES = 'services',
  PRICING_LEVELS = 'pricing-levels',
  DISCOUNTS = 'discounts',
  ROTA = 'rota',
  TEAM = 'team',
  PRODUCTS = 'products',
  PRODUCT_SUPPLIERS = 'product-suppliers',
  PRODUCT_ORDERS = 'product-orders',
  EMAIL_CAMPAIGN = 'email-campaign',
  AUTOMATED_MESSAGING = 'automated-messaging',
  APP = 'tw-client-app',
  PARTNER_SITE = 'partner-site',
  FACEBOOK = 'facebook-widget',
  VENUE_DETAILS = 'venue-details',
  FINANCE = 'finance',
  NOTIFICATIONS = 'notifications',
  ONLINE_BOOKING = 'online-booking',
  CLIENT_FORMS = 'client-forms',
}

export enum L3Page {
  VENUE_DETAILS = 'venue-details',
  OPENING_HOURS = 'venue-opening-hours',
  RESOURCES = 'venue-resources',
  PAYMENTS = 'finance-remittance-list',
  BILLING_DETAILS = 'finance-billing-info',
  BANK_DETAILS = 'finance-bank-details',
  POS = 'finance-point-of-sale',
  SETTINGS = 'finance-settings',
  CLIENT_NOTIFICATIONS = 'client-notifications',
  FULFILLMENT = 'venue-notifications',
  ONLINE_BOOKING_WIDGET = 'booking-widget',
  ONLINE_BOOKING_SETTINGS = 'online-booking-settings',
  ONLINE_BOOKING_INTEGRATIONS = 'online-booking-integrations',
  CLIENT_FORMS_CREATE = 'client-forms-create',
}

export function mapPageToL1Enum(page: string): L1Page {
  return L1Page[snake(page).toUpperCase()];
}

export function mapPageToL2Enum(page: string): L2Page {
  return L2Page[snake(page).toUpperCase()];
}

export function mapPageToL3Enum(page: string): L3Page {
  return L3Page[snake(page).toUpperCase()];
}

export class NavigationAnalytics {
  public static CATEGORY = 'navigation';

  public static trackPageView(category: L1Page | L2Page | L3Page) {
    if (!category) {
      console.warn(`PageView ${category} not listed for tracking`);
      return;
    }
    trackPageView(category);
  }

  public static trackPageEventView(category: L2Page | L3Page) {
    if (!category) {
      console.warn(`Page view event ${category} not listed for tracking`);
      return;
    }
    trackEvent(category, ACTIONS.VIEW);
  }

  public static trackMainMenuClick(property: L1Page) {
    if (!property) {
      console.warn(`Main menu ${property} not listed for tracking`);
      return;
    }
    trackEvent(NavigationAnalytics.CATEGORY, ACTIONS.CLICK, 'L1', property);
  }

  public static trackSidebarClick(property: L2Page) {
    if (!property) {
      console.warn(`Sidebar ${property} not listed for tracking`);
      return;
    }
    trackEvent(NavigationAnalytics.CATEGORY, ACTIONS.CLICK, 'L2', property);
  }

  public static trackPageTabClick(property: L3Page) {
    if (!property) {
      console.warn(`Page tab ${property} not listed for tracking`);
      return;
    }
    trackEvent(NavigationAnalytics.CATEGORY, ACTIONS.CLICK, 'L3', property);
  }
}
