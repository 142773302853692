import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Chat extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
        return (
          <svg
            className={['icon-svg', 'svg-chat', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 12 12"
          >
            <path d="M8.9914698,0 C10.6532696,0 12,1.33864172 12,2.9996047 L12,6.76510118 C12,8.42368903 10.663639,9.76470588 9.00189865,9.76470588 L5.99703014,9.76470588 C5.68029369,9.76470588 5.11028713,9.96933505 4.86813056,10.1687581 L4.13570725,10.7719302 L2.86429275,9.22806976 L3.59671605,8.62489763 C4.19684402,8.1306746 5.21627139,7.76470588 5.99703014,7.76470588 L9.00189865,7.76470588 C9.55750936,7.76470588 10,7.32067363 10,6.76510118 L10,2.9996047 C10,2.44591733 9.55138823,2 8.9914698,2 L3.0085302,2 C2.45125696,2 2,2.44713164 2,2.99201702 L2,12 L0,12 L0,2.99201702 C0,1.33842963 1.35077822,0 3.0085302,0 L8.9914698,0 Z M9,4 L9,6 L3,6 L3,4 L9,4 Z" />
          </svg>
        );
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-chat', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path d="M11.9914698,2 C13.6532696,2 15,3.33864172 15,4.9996047 L15,8.76510118 C15,10.423689 13.663639,11.7647059 12.0018986,11.7647059 L6.99703014,11.7647059 C6.68029369,11.7647059 6.11028713,11.969335 5.86813056,12.1687581 L5.13570725,12.7719302 L3.86429275,11.2280698 L4.59671605,10.6248976 C5.19684402,10.1306746 6.21627139,9.76470588 6.99703014,9.76470588 L12.0018986,9.76470588 C12.5575094,9.76470588 13,9.32067363 13,8.76510118 L13,4.9996047 C13,4.44591733 12.5513882,4 11.9914698,4 L4.0085302,4 C3.45125696,4 3,4.44713164 3,4.99201702 L3,14 L1,14 L1,4.99201702 C1,3.33842963 2.35077822,2 4.0085302,2 L11.9914698,2 Z M11,6 L11,8 L5,8 L5,6 L11,6 Z" />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-chat', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 24 24"
          >
            <path d="M19.0049107,3 C20.6598453,3 22,4.35043647 22,6.0085302 L22,13.9914698 C22,15.6523112 20.6510594,17 18.9914698,17 L10.0024733,17 C9.71579295,17 9.19976167,17.2144519 8.99614866,17.4180649 L6.70710678,19.7071068 L5.29289322,18.2928932 L7.5819351,16.0038513 C8.16002984,15.4257566 9.18451939,15 10.0024733,15 L18.9914698,15 C19.5469054,15 20,14.5473258 20,13.9914698 L20,6.0085302 C20,5.45159872 19.5518396,5 19.0049107,5 L4.99508929,5 C4.44572825,5 4,5.44694334 4,6.00010618 L4,21 L2,21 L2,6.00010618 C2,4.34359085 3.33993808,3 4.99508929,3 L19.0049107,3 Z M14,11 L14,13 L6,13 L6,11 L14,11 Z M18,7 L18,9 L6,9 L6,7 L18,7 Z" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Chat);
