require('./menu-enclosed-offer-change-notification.js');
(function () {
  var lang = Wahanda.lang.menu.packageOfferChange;
  var Dialog = App.Views.Dialog.MenuEnclosedOfferChangeNotification.extend(
    {
      dialogTitle: lang.title,
      buttons: {
        apply: {
          title: lang.applyText,
          direction: 'right',
          classes: 'dialog2--button dialog2--button-right dialog2--button-green js-apply',
        },
      },
      events: {
        'click .js-close': 'close',
        'click .js-apply': 'onApplyChanges',
      },
      lang: lang,

      onApplyChanges: function () {
        this.options.onApply();
        this.close();
      },
    },
    {
      open: function (options) {
        var dialog = new Dialog({
          packageList: _.map(options.packages, function (val, key) {
            return {
              id: key,
              name: val,
            };
          }),
          onApply: options.onApply,
        });

        dialog.open();
      },
    },
  );

  App.Views.Dialog.MenuPackageIncludedOfferChangeNotification = Dialog;
})();
