import { trackEvent } from 'common/analytics';
;(function($) {
	/**
	 * Wahanda month calendar.
	 * 
	 * Events (parameters):
	 * - navigate (current year)
	 * - select (year, month)
	 */
	$.widget("wahanda.monthCalendar", {
		// These options will be used as defaults
		options: {
			// Language constants
			monthNames: Wahanda.lang.date.monthsShort,
			prevText: "Prev",
			nextText: "Next",
			year: null,
			month: null
		},
		
		_create: function() {
			var now            = this._getNow();
			this.renderedYear  = parseInt(this.options.year || now.year, 10);
			this.selectedMonth = parseInt(this.options.month) || null;
			this.selectedYear  = this.renderedYear;
			
			this.element.addClass('month-calendar');
			this._bindEvents();
			this.refresh();
		},
		
		_bindEvents: function() {
			var self = this;
			this.element
			.on('click.monthCalendar', '.month-calendar-prev', function() {
				trackEvent('navigation', 'click', 'prev-year')
				self.renderedYear--;
				self.refresh();
				self._trigger('navigate', null, [self.renderedYear]);
			})
			.on('click.monthCalendar', '.month-calendar-next', function() {
				trackEvent('navigation', 'click', 'next-year');
				self.renderedYear++;
				self.refresh();
				self._trigger('navigate', null, [self.renderedYear]);
			})
			.on('click.monthCalendar', 'td', function(event) {
				trackEvent('navigation', 'click', 'month');
				event.preventDefault();
				self.select($(event.currentTarget).data('month'), this.renderedYear);
				self._trigger('select', null, [self.renderedYear, self.selectedMonth]);
			});
		},
		
		select: function(month, year) {
			year || (year = this.renderedYear);
			
			this.selectedYear = this.renderedYear = year;
			this.selectedMonth = month;
			this.refresh();
		},
		
		getYear: function() {
			return this.renderedYear;
		},
		
		getMonth: function() {
			return this.selectedMonth;
		},
		
		_setOption: function(option, value) {
			if ('year' === option) {
				this.renderedYear = this.selectedYear = value;
				this.refresh();
			}
			if ('month' === option) {
				this.selectedMonth = value;
				this.refresh();
			}
			$.Widget.prototype._setOption.apply( this, arguments );
		},
		
		refresh: function() {
			var html = 
			'<div class="month-calendar-header">'
				+ '<a class="month-calendar-prev" title="' + this.options.prevText + '"><span class="arrow-left">' + this.options.prevText + '</span></a>'
				+ '<a class="month-calendar-next" title="' + this.options.nextText + '"><span class="arrow-right">' + this.options.nextText + '</span></a>'
				+ '<span class="month-calendar-year">' + this.renderedYear + '</span>'
			+ '</div>'
			+ '<table class="month-calendar-body">'
				+ this._getMonthsHtml()
			+ '</table>';
			
			this.element.html(html);
		},
		
		_getMonthsHtml: function() {
			var className, monthNumber;
			var html = '';
			for (var row = 0; row < 4; row++) {
				html += '<tr>';
				for (var cell = 0; cell < 3; cell++) {
					monthNumber = 3 * row + cell;
					className = '';
					if (this._isCurrentMonth(monthNumber)) {
						className += 'month-calendar-this-month ';
					}
					if (this._isSelected(monthNumber)) {
						className += 'month-calendar-selected ';
					}
					html += '<td data-month="' + monthNumber + '"><a href="#" class="' + className + '">' + this.options.monthNames[monthNumber] + '</a></td>';
				}
				html += '</tr>';
			}
			return html;
		},
		
		_isCurrentMonth: function(monthNumber) {
			var now = this._getNow();
			return (this.renderedYear === now.year) && (monthNumber === now.month);
		},
		
		_isSelected: function(monthNumber) {
			return (this.renderedYear === this.selectedYear) && (monthNumber === this.selectedMonth);
		},
		
		_getNow: function() {
			var date = new Date();
			return {
				year: date.getFullYear(),
				month: date.getMonth()
			};
		},
		
		destroy: function() {
			this.element.off('.monthcalendar');
			$.Widget.prototype.destroy.call( this );
		}
	});
}(jQuery));
