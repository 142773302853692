import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from 'components/common/Button';

import styles from './PhotoUpload.scss';

export interface Props {
  id: string;
  icon?: JSX.Element;
  label: string;
  buttonLabel: string;
  onFilesSelected: (files: FileList) => void;
}

export const PhotoUpload = ({ id, icon, label, buttonLabel, onFilesSelected }: Props) => {
  const [isDragging, setIsDragging] = useState(false);

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    onFilesSelected(event.dataTransfer.files);
  };
  const onDragOver = (event) => {
    event.preventDefault();
  };
  const onDragEnter = () => setIsDragging(true);
  const onDragLeave = () => setIsDragging(false);
  const onChangeFileInput = (event) => {
    if (event.target.files) {
      onFilesSelected(event.target.files);
    }
  };

  return (
    <label
      htmlFor={id}
      className={classNames(styles.wrapper, {
        [styles.isDragging]: isDragging,
      })}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
    >
      <div className={styles.label}>
        {icon}
        <span>{label}</span>
      </div>
      <Button label={buttonLabel} variant="secondary" />
      <input
        id={id}
        type="file"
        onChange={onChangeFileInput}
        multiple
        style={{ display: 'none' }}
      />
    </label>
  );
};
