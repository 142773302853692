App.Models.SpaAllocation = BackboneEx.Model.Silent.Allocation.extend({
  structure: {
    date: 'date',
    inventoryAvailable: 'integer',
    inventoryReserved: 'integer',
    inventoryBooked: 'integer',
    closed: 'boolean',
    closedOffers: 'array',
  },

  _afterSave: function () {
    App.trigger('spa-allocation:saved', this.get('date'));
    this.notSet = false;
  },

  _getSaveData: function () {
    // This model needs another type of saving
    var data = {
      dates: [this.get('date')],
      closed: !!this.get('closed'),
      closedOffers: this.get('closedOffers'),
    };
    if (!data.closed) {
      data.inventoryAvailable = parseInt(this.get('inventoryAvailable'), 10);
    }
    return data;
  },

  getInventoryText: function () {
    var inv = Wahanda.lang.calendar.spaDays.inventory;
    if (this.get('closed')) {
      return inv.closed;
    }
    if (this.get('inventoryAvailable') > 0) {
      return inv.count.replace('{{count}}', this.get('inventoryAvailable'));
    }
    if (this.get('inventoryAvailable') === 0 && this.get('inventoryBooked') === 0) {
      return inv.noinventory;
    }
    return inv.nothing;
  },

  getStateClass: function () {
    if (this.get('closed')) {
      return 'state-closed';
    }
    if (this.get('inventoryAvailable') === 0 && this.get('inventoryBooked') === 0) {
      return 'state-noinventory';
    }
    if (this.get('inventoryAvailable') === 0) {
      return 'state-out';
    }
    if (this.get('inventoryAvailable') <= this.lowInventoryCount) {
      return 'state-low';
    }
    return '';
  },

  getBookedInventoryCount: function () {
    return this.get('inventoryBooked');
  },

  // Fetching / saving functions
  url: function (forSave) {
    var base = '/venue/' + App.config.get('venue').id + '/spa-allocations.json';
    if (!forSave) {
      base +=
        '?' + 'date-from=' + this.get('date') + '&date-to=' + this.get('date') + '&include=offers';
    } else {
      base += '?include=closed-offers';
    }
    return App.Api.wsUrl(base);
  },
});
