App.Routers.Settings = Backbone.Router.extend({
  routes: {
    '_/*iglu': 'rootIgluReference',
    'venue/:id/:module/:tab/*iglu': 'openVenue',
    'venue/:id/:module/*iglu': 'openVenue',
    ':module/:tab/*iglu': 'defaultVenue',
    ':module/*iglu': 'defaultVenue',
  },
  openVenue: function (id, module, tab, iglu) {
    App.setVenue(id);
    App.mainViewOptions = {
      initialModule: module,
      initialTab: tab,
      initialIglu: App.Views.Dialog.IgluModal.findIgluUri(iglu, null),
    };
  },

  defaultVenue: function (module, tab, iglu) {
    this.openVenue(null, module, tab, iglu);
  },

  rootIgluReference: function (iglu) {
    this.defaultVenue(null, null, '/_/' + iglu);
  },
});
