import { trackEvent, ACTIONS } from 'common/analytics';

export class CustomerDuplicateCheckAnalytics {
  public static CATEGORY = 'client';

  public static useExistingClient() {
    trackEvent(CustomerDuplicateCheckAnalytics.CATEGORY, ACTIONS.SUBMIT, 'existing');
  }

  public static createNewClient() {
    trackEvent(CustomerDuplicateCheckAnalytics.CATEGORY, ACTIONS.CLICK, 'new-customer');
  }

  public static duplicatePopUpOpen() {
    trackEvent(CustomerDuplicateCheckAnalytics.CATEGORY, ACTIONS.VALIDATION, 'duplicate');
  }
}
