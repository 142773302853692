import { useEffect, useState } from 'react';
import {
  ClientFormsCreatePayloadValues,
  ClientFormsDetailsResponse,
  ClientFormsUpdatePayloadValues,
} from './types';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { get, post, put } from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { getClientFormCreateRequestPayload } from './util';
import App from 'common/backbone-app';
import { InputTextValue } from 'components/common/__BaseCommon';
import { formTemplatesListCacheKey } from '../FormTemplates/consts';
import { FormTemplate } from '../FormTemplates/types';

const cacheKey = ({ formId }) => `FormTemplatesDetails_${App.getVenueId()}_${formId}`;

export const useClientFormsMutations = ({
  formId,
  onCloseCallback,
}: {
  formId?: FormTemplate['id'];
  onCloseCallback: <T>(createdFormTitle?: T) => void;
}) => {
  const isUpdate = Boolean(formId);
  const [formTitle, setFormTitle] = useState<InputTextValue>('');
  const [htmlText, setHtmlText] = useState('');
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const queryCache = useQueryCache();
  const [confirmCreation, setConfirmCreation] = useState<boolean>(false);

  const goBackToFormsList = () => {
    onCloseCallback();
  };

  const handleSuccess = (data: ClientFormsCreatePayloadValues) => {
    const queryCacheKey = formTemplatesListCacheKey();
    queryCache.invalidateQueries(queryCacheKey);
    isUpdate ? onCloseCallback<boolean>(true) : onCloseCallback(data.title);
  };

  const { data: formTemplateDetails, isLoading: formTemplateDetailsLoading } = useQuery(
    cacheKey({ formId }),
    () => {
      return get<ClientFormsDetailsResponse>(
        apiUrl('CONSULTATION_FORMS_TEMPLATES_BY_ID', {
          venueId: App.getVenueId(),
          formId: formId,
        }),
      );
    },
    {
      enabled: isUpdate,
      refetchOnWindowFocus: false,
    },
  );

  const [mutate, { isLoading, isError }] = useMutation(
    (
      formData: ClientFormsCreatePayloadValues | ClientFormsUpdatePayloadValues,
    ): Promise<ClientFormsCreatePayloadValues> => {
      const httpRequest = isUpdate ? put : post;
      return httpRequest(
        apiUrl(isUpdate ? 'CONSULTATION_FORMS_TEMPLATES_BY_ID' : 'CONSULTATION_FORMS_TEMPLATES', {
          venueId: App.getVenueId(),
          formId: formId,
        }),
        formData,
      ) as Promise<ClientFormsCreatePayloadValues>;
    },
    {
      onSuccess: handleSuccess,
    },
  );

  const handleFormSubmit = async () => {
    const payloadData = isUpdate
      ? {
          serviceIds: selectedServices,
        }
      : getClientFormCreateRequestPayload({
          title: formTitle as string,
          htmlText,
          serviceIds: selectedServices,
          description: '',
        });
    await mutate(payloadData);
  };

  const handleChangeHtmlText = (text: string) => {
    setHtmlText(text);
  };

  const handleChangeSelectedServices = (serviceIds: number[]) => {
    setSelectedServices(serviceIds);
  };

  useEffect(() => {
    if (!formTemplateDetails?.services) return;
    setSelectedServices(
      formTemplateDetails.services.map((service) => Number(service.id.replace('srvc_', ''))),
    );
  }, [formTemplateDetails?.services]);

  return {
    formTitle,
    setFormTitle,
    htmlText,
    selectedServices,
    handleFormSubmit,
    handleChangeHtmlText,
    handleChangeSelectedServices,
    isLoading,
    isError,
    goBackToFormsList,
    formTemplateDetailsLoading,
    confirmCreation,
    setConfirmCreation,
  };
};
