import { conj, assocIn, toClj, updateIn, dissoc } from 'mori';
import { appState } from 'state';

import apiUrl from 'common/api-url';
import { xhr } from 'common/xhr';

// Fetch the POS Checkout Summary and save it to LIST_KEY appState key
export function fetchCheckoutSummary(checkoutId) {
  return xhr.getWithPromise(apiUrl('POS_CHECKOUT_SUMMARY', { checkoutId }));
}

// TODO: move this to a separate customer service,
// as it's not directly related to POS Checkout
export function fetchCustomer(venueCustomerId) {
  return xhr.getWithPromise(apiUrl('POS_CHECKOUT_CUSTOMER', { venueCustomerId }));
}

function saveToState(checkoutId, checkoutData, customerData) {
  const data = conj(toClj(checkoutData), toClj(customerData));

  appState.swap((state) => assocIn(state, ['checkout-summary', checkoutId], data));
}

export function fetch(checkoutId) {
  fetchCheckoutSummary(checkoutId).done((data) => {
    const checkoutData = data;

    if (!data.checkoutInformation.venueCustomerId) {
      saveToState(checkoutId, checkoutData, {});
    } else {
      fetchCustomer(data.checkoutInformation.venueCustomerId).done((customerData) => {
        saveToState(checkoutId, checkoutData, customerData);
      });
    }
  });
}

export function remove(checkoutId) {
  appState.swap((oldState) => updateIn(oldState, ['checkout-summary'], dissoc, checkoutId));
}
