import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class ClientApp extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-client-app', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M7.5,2 C6.3954305,2 5.5,2.8954305 5.5,4 L5.5,20 C5.5,21.1045695 6.3954305,22 7.5,22 L16.5,22 C17.6045695,22 18.5,21.1045695 18.5,20 L18.5,4 C18.5,2.8954305 17.6045695,2 16.5,2 L7.5,2 Z M7.5,0 L16.5,0 C18.709139,0 20.5,1.790861 20.5,4 L20.5,20 C20.5,22.209139 18.709139,24 16.5,24 L7.5,24 C5.290861,24 3.5,22.209139 3.5,20 L3.5,4 C3.5,1.790861 5.290861,0 7.5,0 Z M7.5,18 C6.94771525,18 6.5,18.4477153 6.5,19 L6.5,20 C6.5,20.5522847 6.94771525,21 7.5,21 L8.5,21 C9.05228475,21 9.5,20.5522847 9.5,20 L9.5,19 C9.5,18.4477153 9.05228475,18 8.5,18 L7.5,18 Z M7.5,14 C6.94771525,14 6.5,14.4477153 6.5,15 L6.5,16 C6.5,16.5522847 6.94771525,17 7.5,17 L8.5,17 C9.05228475,17 9.5,16.5522847 9.5,16 L9.5,15 C9.5,14.4477153 9.05228475,14 8.5,14 L7.5,14 Z M11.5,18 C10.9477153,18 10.5,18.4477153 10.5,19 L10.5,20 C10.5,20.5522847 10.9477153,21 11.5,21 L12.5,21 C13.0522847,21 13.5,20.5522847 13.5,20 L13.5,19 C13.5,18.4477153 13.0522847,18 12.5,18 L11.5,18 Z M11.5,14 C10.9477153,14 10.5,14.4477153 10.5,15 L10.5,16 C10.5,16.5522847 10.9477153,17 11.5,17 L12.5,17 C13.0522847,17 13.5,16.5522847 13.5,16 L13.5,15 C13.5,14.4477153 13.0522847,14 12.5,14 L11.5,14 Z M15.5,14 C14.9477153,14 14.5,14.4477153 14.5,15 L14.5,16 C14.5,16.5522847 14.9477153,17 15.5,17 L16.5,17 C17.0522847,17 17.5,16.5522847 17.5,16 L17.5,15 C17.5,14.4477153 17.0522847,14 16.5,14 L15.5,14 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(ClientApp);
