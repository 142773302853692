import React from 'react';
import { toClj, isEmpty, count } from 'mori';
import Wahanda from 'common/wahanda';
import { mergeAndSaveMultiple as saveBlocks } from 'services/time-block';
import { DialogConfirmation } from 'components/common/dialog/DialogConfirmation';
import SidebarActionableMode from './common/SidebarActionMode';

const lang = Wahanda.lang.calendar.multiBlockout;

function getConfirmationDialog(onCloseFn) {
  return (
    <DialogConfirmation
      dataTest="multi-block-out-confirmation-modal"
      onClose={onCloseFn}
      title={lang.error.title}
      text={lang.error.text}
      buttonTitle={lang.error.buttonText}
    />
  );
}

interface IMultiBlockoutModeProps extends React.HTMLAttributes<Element> {
  close?: (...args: any[]) => any;
  getBlockedValues?: (...args: any[]) => any;
  toggleSpinner?: (...args: any[]) => any;
}
type MultiBlockoutModeState = {
  error?: boolean;
};

export default class MultiBlockoutMode extends React.Component<
  IMultiBlockoutModeProps,
  MultiBlockoutModeState
> {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'description' has no initializer and is n... Remove this comment to see the full error message
  description: HTMLInputElement;

  sidebar: any;

  state = {};

  componentDidMount() {
    this.description.focus();
  }

  save = () => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    const blocks = toClj(this.props.getBlockedValues());
    if (isEmpty(blocks)) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.close();
    } else {
      this.sidebar.markSaving();
      saveBlocks(blocks, this.description.value)
        // @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type '{ ids: a... Remove this comment to see the full error message
        .then(({ ids }) => this.props.close({ saved: true, count: count(blocks), savedIds: ids }))
        .fail(() => {
          this.setState({ error: true });
          this.sidebar.unmarkSaving();
        });
    }
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
    const { error } = this.state;
    const errorConfirmation = error
      ? getConfirmationDialog(() => this.setState({ error: false }))
      : null;
    return (
      <SidebarActionableMode
        ref={(sidebar) => {
          this.sidebar = sidebar;
        }}
        close={this.props.close}
        onSave={this.save}
        toggleSpinner={this.props.toggleSpinner}
        title={lang.title}
        // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type '{} | undefi... Remove this comment to see the full error message
        beforeSidebarComponent={errorConfirmation}
      >
        <div className="intro">{lang.intro}</div>
        <input
          type="text"
          ref={(description) => {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'HTMLInputEl... Remove this comment to see the full error message
            this.description = description;
          }}
          placeholder={lang.description}
        />
      </SidebarActionableMode>
    );
  }
}
