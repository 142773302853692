import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import store from 'common/menu-store';
import {
  offerDialogOpenAction,
  offerDalogCloseAction,
  offerDialogPackageServicesChange,
  offerDialogPackageServicesMasterChange,
} from 'components/menu/actions';
import PackagePricing from 'components/menu/PackagePricing/container';

export const actions = {
  offerDialogPackageServicesChangeAction: bindActionCreators(
    offerDialogPackageServicesChange,
    store.dispatch,
  ),
  masterServiceChangeAction: bindActionCreators(
    offerDialogPackageServicesMasterChange,
    store.dispatch,
  ),
};

export function render(target, state) {
  const offerDialogPackageState = {
    offerDialogSkus: state.skus,
    offerDialogReadonly: state.readonly,
    offerDialogReadonlyLight: state.readonlyLight,
    isPackagePricingCustom: state.isPackagePricingCustom,
    employeeCategoriesCollection: state.employeeCategoriesCollection,
    offerDialogSubServices: state.offerDialogSubServices,
    offerDialogInlineServices: state.offerDialogInlineServices,
  };
  store.dispatch(offerDialogOpenAction(offerDialogPackageState));
  ReactDOM.render(
    <Provider store={store}>
      <PackagePricing />
    </Provider>,
    target,
  );
  return {
    getValues: () => {
      const {
        menu: { menuOffer },
      } = store.getState();
      const { offerDialogSkus } = menuOffer;
      return {
        skus: offerDialogSkus,
      };
    },
  };
}

/**
 *  Unmount the React Component and destroy it's related store data
 *
 *  @param {DOMNode} target The node Pricing was rendered into
 *  @returns {void}
 */
export function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
  store.dispatch(offerDalogCloseAction());
}
