import { assoc, assocIn, get, hashMap, map, conj, toClj, updateIn, dissoc } from 'mori';
import { appState } from 'state';

import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import apiUrl from 'common/api-url';
import { xhr } from 'common/xhr';
import { fetchCustomer } from 'src/services/pos-checkout-summary';

function saveToState(cancellationId, checkoutData, customerData) {
  const data = conj(toClj(checkoutData), toClj(customerData));

  appState.swap((state) => assocIn(state, ['cancellation-summary', cancellationId], data));
}

export function fetchCancellationSummary(cancellationId) {
  xhr.getWithPromise(apiUrl('POS_CANCELLATION_SUMMARY', { cancellationId })).done((data) => {
    const checkoutData = data;

    if (!data.cancellationInformation.venueCustomerId) {
      saveToState(cancellationId, checkoutData, {});
    } else {
      fetchCustomer(data.cancellationInformation.venueCustomerId).done((customerData) => {
        saveToState(cancellationId, checkoutData, customerData);
      });
    }
  });
}

export function removeCancellationSummary(cancellationId) {
  appState.swap((oldState) => updateIn(oldState, ['cancellation-summary'], dissoc, cancellationId));
}

function convertPaymentsToRefunds(payments) {
  return map((item) => {
    const type = get(item, 'type');
    const refund = hashMap(
      // Cash  and card payments are refunded partially (e.g. only the covering sum is refunded).
      // Voucher payments are refunded fully.
      'amount',
      get(item, type === 'VCHR' ? 'amount' : 'costAmount'),
      'paymentType',
      type,
    );

    return get(item, 'cardType') ? assoc(refund, 'cardType', get(item, 'cardType')) : refund;
  }, payments);
}

export function createRefund({ checkoutId, paymentsToRefund, notes, operationId }) {
  const employeeId = App.config.get('account').employeeId;
  const toEntity = (data) => ({
    receiptId: data.receiptUri,
    receiptCopyUri: data.receiptCopyUri,
    cancellationId: data.cancellationId,
    tipReceiptId: data.tipRefund ? data.tipRefund.receiptUri : null,
  });

  return xhr
    .postWithPromise(
      apiUrl('POS_REFUND'),
      hashMap(
        'employeeId',
        employeeId,
        'checkoutId',
        checkoutId,
        'refunds',
        convertPaymentsToRefunds(paymentsToRefund),
        'notes',
        notes,
        'operationId',
        operationId,
      ),
    )
    .then(toEntity)
    .done((entity) => {
      // Inform the Backbone world too
      App.trigger(Wahanda.Event.TRANSACTION_CANCELLED, {
        checkoutId,
        cancellationId: entity?.cancellationId,
      });
    });
}
