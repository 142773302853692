import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Receipt extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-receipt', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M5,0 L5,24 C3.8932319,24 3,23.1029738 3,21.9950534 L3,21.9950534 L3,2.00494659 C3,0.897645164 3.8926228,1.60982339e-15 4.99508929,1.60982339e-15 L4.99508929,1.60982339e-15 L5,0 Z M19.0049107,-1.66533454e-16 C20.1073772,-1.66533454e-16 21,0.897645164 21,2.00494659 L21,21.9950534 C21,23.1029738 20.1067681,24 19.0049107,24 L19,24 L19,-1.77635684e-15 L19.0049107,-1.66533454e-16 Z M9,22 L9,24 L7,24 L7,22 L9,22 Z M13,22 L13,24 L11,24 L11,22 L13,22 Z M17,22 L17,24 L15,24 L15,22 L17,22 Z M17,16 L17,18 L13,18 L13,16 L17,16 Z M11,16 L11,18 L7,18 L7,16 L11,16 Z M13,10 L13,12 L7,12 L7,10 L13,10 Z M17,6 L17,8 L7,8 L7,6 L17,6 Z M9,0 L9,2 L7,2 L7,0 L9,0 Z M13,0 L13,2 L11,2 L11,0 L13,0 Z M17,0 L17,2 L15,2 L15,0 L17,0 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Receipt);
