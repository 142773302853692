/* eslint-disable object-shorthand */
/* global BackboneEx, _ */
(function () {
  const BaseView = BackboneEx.View.Base;
  /**
   * The single Appointment view.
   *
   * Note on rendering: the views are rerendered after the model is changed (or reverted)
   * so please take extra care that you reset all state when render is called.
   */
  const MultiServicesItem = BaseView.extend({
    events: {
      'click .js-reject-package': 'onReject',
      'click .js-noshow-package': 'onNoShow',
      'click .js-add-package-note': 'onAddNote',
      'click .js-order-ref': 'onOrderView',
      'click .js-group-reschedule': 'onRescheduleClick',
    },

    templateId: 'appointment2-form-package-item-template',
    actionsTemplateId: 'appointment2-form-package-actions-template',
    notesTemplateId: 'appointment2-form-package-editable-notes-template',
    priceTemplateId: 'appointment2-form-price-template',

    apptViews: null,
    allFormState: null,
    rendered: false,
    isSubView: false,

    initialize: function (options) {
      BaseView.prototype.initialize.call(this, options);
      this.apptViews = [];
      this.allFormState = {};
      const self = this;

      // Proxy local collection events to mediator
      const eventMap = {
        rejected: this.options.mediator.APPOINTMENT_GROUP_REJECTED,
        'no-show': this.options.mediator.APPOINTMENT_GROUP_SET_NOSHOW,
        deleted: this.options.mediator.APPOINTMENT_GROUP_CANCELLED,
        'checked-out': this.options.mediator.APPOINTMENT_CHECKED_OUT,
      };
      _.each(eventMap, function (mediatorEvent, collectionEvent) {
        self.listenTo(self.collection, collectionEvent, function () {
          self.options.mediator.trigger(mediatorEvent, self.collection);
        });
      });
    },

    /**
     * Mark this view as a SubView of another MultiServicesItem view instance.
     */
    setSubView: function () {
      this.isSubView = true;
    },

    render: function () {
      this.renderMainContainer();
    },

    renderMainContainer: function () {
      const el = Wahanda.Template.renderTemplate(
        this.templateId,
        this.getMainContainerTemplateValues(),
        {
          actionsTemplate: Wahanda.Template.get(this.actionsTemplateId),
          notesTemplate: Wahanda.Template.get(this.notesTemplateId),
        },
      );

      if (this.rendered) {
        // Re-rendering means replacing the appointment div with a new one.
        this.undelegateEvents();
        this.$el.replaceWith(el);
        this.delegateEvents();
      } else {
        this.setElement(el);
        this.rendered = true;
      }
    },

    getMainContainerTemplateValues: function () {
      let price;
      if (!this.isSubView && !this.collection.isCheckedOut()) {
        price = this.collection.getPriceText();
      }
      const isFree = price <= 0;
      return {
        status: {
          name: this.isSubView ? null : this.collection.getStatusText(),
          className: this.isSubView ? null : this.collection.getStatusClassName(),
        },
        showPaymentStatus:
          !this.isSubView && this.collection.id > 0 && !this.collection.isCheckedOut(),
        free: isFree,
        pricePrepaid: this.collection.isPrepaid() && !isFree,
        unpaid: this.collection.isUnpaid() && !isFree,
        checkout: this.collection.isCheckedOut() ? this.collection.getCheckOut() : null,
        groupName: this.collection.isPackageType() ? this.collection.getName() : null,
        groupActions: this.isSubView ? {} : this.collection.getActionsValidity(),
        showAddNotes: this.isSubView ? false : !this.collection.hasNotes(),
        notes: this.collection.getNotes(),
        bookingActor: this.isSubView ? null : this.collection.getOrderSourceData(),
        isPackage: this.collection.isPackageType(),
        price: price,
      };
    },

    addAppointment: function (apptView) {
      this.apptViews.push(apptView);
      apptView.render();
      this.$('> .js-appt-list').append(apptView.$el);

      this.listenTo(
        apptView,
        'auto-rescheduled',
        function () {
          this.$el.colorNotifyChange();
        }.bind(this),
      );
    },

    isValid: function () {
      return _.all(this.apptViews, function (view) {
        return view.isValid();
      });
    },

    isValidReturnOnly: function () {
      return _.all(this.apptViews, function (view) {
        return view.isValidReturnOnly();
      });
    },

    showError: BackboneEx.Mixin.View.Form.implementation.showError,

    renderNotes: function () {
      if (this.collection.hasNotes()) {
        this.$('textarea').val(this.collection.getNotes());
      } else {
        this.$('.js-package-notes').empty();
      }
    },

    /**
     * @return { startTime, endTime, duration }
     */
    getAppointmentTimes: function (withCleanupTime) {
      let first;
      let last;
      let duration = 0;
      _.each(this.apptViews, function (view) {
        const times = view.getAppointmentTimes(withCleanupTime);
        if (!first) {
          first = times;
        }
        last = times;
        duration += times.duration;
      });

      return {
        startTime: first.startTime,
        endTime: last.endTime,
        duration: duration,
      };
    },

    getLastModel: function () {
      return this.apptViews[this.apptViews.length - 1].model;
    },

    getEmployeeId: function () {
      return this.apptViews[this.apptViews.length - 1].getEmployeeId();
    },

    /**
     * Start the Confirm action on the Group.
     *
     * @returns Promise
     */
    confirm: function () {
      return this.collection.confirm();
    },

    getLastAppointmentView: function () {
      let lastView = this.apptViews[this.apptViews.length - 1];
      if (lastView.getLastAppointmentView) {
        // `lastView` is a Package Group inside this Multi-Order view
        lastView = lastView.getLastAppointmentView();
      }
      return lastView;
    },

    remove: function () {
      _.invoke(this.apptViews, 'remove');
      BaseView.prototype.remove.call(this);
    },

    // UI actions

    save: function () {
      let toSave = 1;

      const triggerConfirmedEvent = function () {
        this.options.mediator.trigger(
          this.options.mediator.APPOINTMENT_GROUP_CONFIRMED,
          this.collection,
        );
        this.trigger('saved');
      }.bind(this);

      function standaloneApptSaveCallback() {
        toSave -= 1;
        if (toSave === 0) {
          triggerConfirmedEvent();
        }
      }

      function onDone() {
        this.apptViews.forEach(function (view) {
          if (view.model.hasChanged()) {
            // Manually set the status code to CONFIRMED
            view.model.set('appointmentStatusCode', 'CN');
            view.save(standaloneApptSaveCallback);
            toSave += 1;
          }
        });

        standaloneApptSaveCallback();
      }

      function onFail() {
        this.showError(Wahanda.lang.calendar.appointments.errors.couldNotConfirm);
      }

      // Fire the confirm action
      if (!this.collection.isConfirmationTriggered()) {
        this.confirm()
          .done(onDone.bind(this))
          // .done() - this will be handled by listen
          .fail(onFail.bind(this));
      }
    },

    getCancellationPayload: function (data = {}) {
      const {
        isVenueCancellation,
        isCovidCancellation,
        requestRefund,
        preventPaymentProtection,
        includeFutureRecurrences = null,
      } = data;

      let cancellationReason;
      if (isCovidCancellation) {
        cancellationReason = 'CV'; // Coronavirus precaution
      } else if (typeof isVenueCancellation === 'boolean') {
        cancellationReason = isVenueCancellation
          ? 'UN' // Venue can not do the Appointment
          : 'CC'; // Customer Cancellation
      }

      return {
        notifyConsumer: this.collection.isValidForEmailNotifications(),
        cancellationReason: cancellationReason,
        requestRefund: requestRefund,
        preventPaymentProtection,
        platform: 'DESKTOP',
        includeFutureRecurrences,
      };
    },

    onReject: function () {
      const doReject = function (data) {
        this.collection
          .reject({
            data: this.getCancellationPayload(data),
          })
          .fail(
            function () {
              this.$('.js-reject-package').errorTip(
                Wahanda.lang.calendar.appointments.errors.couldNotDelete,
              );
            }.bind(this),
          );
      }.bind(this);

      // Success will be handled by event on `collection`.
      this.options.showConfirmDeletion({
        bookingActor: this.collection.data.bookingActor,
        isPrepaid: this.collection.isPrepaid(),
        isWithinCancellationPeriod: !this.collection.hasCancellationPeriodPassed(),
        cancellationAllowed: this.collection.getActionsValidity().canReject,
        reschedulingAllowed: this.collection.canBeRescheduled(),
        onReschedule: function () {
          this.onRescheduleClick();
        }.bind(this),
        onDoCancellation: doReject,
        onClose: null,
      });
    },

    onNoShow: function () {
      const dialog = new App.Views.Calendar.AppointmentGroupNoShow({
        collection: this.collection,
      });
      dialog.render();
      dialog.open();
      // Success will be handled by event on `collection`.
    },

    onAddNote: function () {
      this.$('.js-add-package-note').remove();
      this.$('.js-package-notes').html(Wahanda.Template.renderTemplate(this.notesTemplateId));
      this.$('.js-package-notes').find('textarea').focus();
    },

    onOrderView: function () {
      const orderId = this.collection.data.orderId;
      const view = new App.Views.Dialog.Order2({
        model: new App.Models.Order({
          id: orderId,
        }),
        updateUrl: false,
      });
      view.render();
      view.open();
    },

    onRescheduleClick: function (event) {
      this.options.mediator.trigger(
        this.options.mediator.START_RESCHEDULE,
        [this.collection],
        event ? { x: event.clientX, y: event.clientY } : null,
      );
    },
  });

  BackboneEx.Mixin.View.Loadmask.mixin(MultiServicesItem);

  App.Views.Forms.Appointment2.MultiServicesItem = MultiServicesItem;
})();
