import React from 'react';
import result from 'lodash/result';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import style from './sales-summary.scss';
import ItemRow from '../summary-view/item-row';
import ItemRowGroupView from '../summary-view/item-group-row';

function getRow(amount, title, key, additionalClasses) {
  return <ItemRow title={title} amount={amount} key={key} additionalClass={additionalClasses} />;
}

function getRows(totalAmount, refundAmount, title, key, additionalClasses) {
  return (
    <ItemRowGroupView
      title={title}
      totalAmount={totalAmount}
      refundAmount={refundAmount}
      key={key}
      // @ts-expect-error ts-migrate(2322) FIXME: Property 'additionalClass' does not exist on type ... Remove this comment to see the full error message
      additionalClass={additionalClasses}
    />
  );
}

function getOptionalRow(amount, title, key, additionalClasses) {
  if (!amount) {
    return null;
  }
  return getRow(amount, title, key, additionalClasses);
}

const LANG = Wahanda.lang.reports.transactions.daySummary.salesSummary;
interface ISalesSummaryProps extends React.HTMLAttributes<Element> {
  salesSummary: {};
}

/*
 ** this component has to be a react component.
 ** because data is being loaded using lazy loading
 */
export class SalesSummary extends React.Component<ISalesSummaryProps, {}> {
  state = {
    expandServices: false,
    expandServicesRefunds: false,
    expandProducts: false,
    expandProductsRefunds: false,
    expandVouchers: false,
    expandVouchersRefunds: false,
  };

  renderVatList = (stateKey, vatList) => {
    const vats = [];
    if (this.state[stateKey] && vatList) {
      vatList.forEach((v) => {
        const vatRate = result(v, 'vatRate');
        const title = Wahanda.Template.render(LANG.vatRate, { vatRate });
        vats.push(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
          <ItemRow
            key={title}
            title={title}
            amount={result(v, 'totalAmount')}
            additionalClass="vat-item"
          />,
        );
      });
    }
    return vats;
  };

  renderSummary = (label, amount, vat, stateKey, additionalClass) => {
    const expandable = Array.isArray(vat) && vat.length > 0;
    const onClick = expandable ? () => this.setState({ [stateKey]: !this.state[stateKey] }) : null;
    return (
      <div className={classnames('item-group', additionalClass)}>
        <ItemRow
          title={label}
          amount={amount}
          expandable={expandable}
          // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type '((...args: ... Remove this comment to see the full error message
          onClick={onClick}
          additionalClass={classnames('expand', { open: this.state[stateKey] })}
        />
        {this.renderVatList(stateKey, vat)}
      </div>
    );
  };

  renderOptionalSummary = (label, amount, vat, stateKey, additionalClass) => {
    if (!amount) {
      return null;
    }
    return this.renderSummary(label, amount, vat, stateKey, additionalClass);
  };

  renderNoVATVoucherStats = (totalAmount, refundAmount, title, key) => {
    if (!(totalAmount || refundAmount)) {
      return null;
    }
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 5 arguments, but got 4.
    return getRows(totalAmount, refundAmount, title, key);
  };

  render() {
    const salesSummary = this.props.salesSummary;
    const vatRateGroupTotals = result(salesSummary, 'vatRateGroupsTotals');
    return (
      <div className="sales-summary">
        <div className="item important header">{LANG.heading}</div>
        <div className="item-container">
          {this.renderSummary(
            LANG.services,
            result(salesSummary, 'servicesTotalSalesAmount'),
            result(vatRateGroupTotals, 'services', 0),
            'expandServices',
            'servicesTotal',
          )}
          {this.renderOptionalSummary(
            LANG.serviceRefunds,
            result(salesSummary, 'servicesTotalRefundsAmount'),
            result(vatRateGroupTotals, 'serviceRefunds', 0),
            'expandServicesRefunds',
            'servicesTotalRefunds',
          )}
          {this.renderSummary(
            LANG.products,
            result(salesSummary, 'productsTotalSalesAmount'),
            result(vatRateGroupTotals, 'products', 0),
            'expandProducts',
            'productsTotal',
          )}
          {this.renderOptionalSummary(
            LANG.productRefunds,
            result(salesSummary, 'productsTotalRefundsAmount'),
            result(vatRateGroupTotals, 'productRefunds', 0),
            'expandProductsRefunds',
            'productsTotalRefunds',
          )}
          {Wahanda.Permissions.hasSingleVATVoucherSupport() && (
            <React.Fragment>
              {this.renderSummary(
                LANG.vouchers,
                result(salesSummary, 'vouchersWithVatAppliedTotalSalesAmount'),
                result(vatRateGroupTotals, 'vouchers', 0),
                'expandVouchers',
                'productsTotal',
              )}
              {this.renderOptionalSummary(
                LANG.refundedVouchers,
                result(salesSummary, 'vouchersWithVatAppliedTotalRefundsAmount', 0),
                result(vatRateGroupTotals, 'voucherRefunds', 0),
                'expandVouchersRefunds',
                'productsTotal',
              )}
            </React.Fragment>
          )}
          {getRow(result(salesSummary, 'totalTransactionsAmount', 0), LANG.total, 'total', 'total')}
          {getOptionalRow(
            result(salesSummary, 'redeemedVouchersAmount', 0),
            LANG.redeemedVouchers,
            'salon-vouchers-redeemed',
            style.redeemedVouchers,
          )}
          {getOptionalRow(
            result(salesSummary, 'redeemedVoucherRefundsAmount', 0),
            LANG.refundedRedeemedVouchers,
            'salon-vouchers-redeemed-refunded',
            style.refundedRedeemedVouchers,
          )}
          {this.renderNoVATVoucherStats(
            result(salesSummary, 'vouchersWithoutVatAppliedTotalSalesAmount', 0),
            result(salesSummary, 'vouchersWithoutVatAppliedTotalRefundsAmount', 0),
            LANG.voucherSold,
            'vouchersTotal',
          )}
        </div>
      </div>
    );
  }
}
