import React from 'react';
import FlagBE from 'components/common/Flag/flags/belgium';
import FlagDE from 'components/common/Flag/flags/germany';
import FlagES from 'components/common/Flag/flags/spain';
import FlagFR from 'components/common/Flag/flags/france';
import FlagIE from 'components/common/Flag/flags/ireland';
import FlagIT from 'components/common/Flag/flags/italy';
import FlagLT from 'components/common/Flag/flags/lithuania';
import FlagNL from 'components/common/Flag/flags/netherlands';
import FlagAT from 'components/common/Flag/flags/austria';
import FlagCH from 'components/common/Flag/flags/switzerland';
import FlagUK from 'components/common/Flag/flags/uk';
import FlagGR from 'components/common/Flag/flags/greece';
import FlagPT from 'components/common/Flag/flags/portugal';
import FlagDK from 'components/common/Flag/flags/denmark';
import FlagLV from 'components/common/Flag/flags/latvia';
import LANGUAGE_LOCALES from 'config/languageLocales';

export interface FlagProps {
  locale: string;
}

export const LocaleToFlagMap = {
  [LANGUAGE_LOCALES.EN]: <FlagUK />,
  [LANGUAGE_LOCALES.IE]: <FlagIE />,
  [LANGUAGE_LOCALES.ES]: <FlagES />,
  [LANGUAGE_LOCALES.DE]: <FlagDE />,
  [LANGUAGE_LOCALES.AT]: <FlagAT />,
  [LANGUAGE_LOCALES.CH]: <FlagCH />,
  [LANGUAGE_LOCALES.FR]: <FlagFR />,
  [LANGUAGE_LOCALES.EN_BE]: <FlagBE />,
  [LANGUAGE_LOCALES.NL_BE]: <FlagBE />,
  [LANGUAGE_LOCALES.FR_BE]: <FlagBE />,
  [LANGUAGE_LOCALES.IT]: <FlagIT />,
  [LANGUAGE_LOCALES.LT]: <FlagLT />,
  [LANGUAGE_LOCALES.NL]: <FlagNL />,
  [LANGUAGE_LOCALES.EN_NL]: <FlagNL />,
  [LANGUAGE_LOCALES.EL]: <FlagGR />,
  [LANGUAGE_LOCALES.PT]: <FlagPT />,
  [LANGUAGE_LOCALES.DA]: <FlagDK />,
  [LANGUAGE_LOCALES.LV]: <FlagLV />,
};

export const Flag = ({ locale }: FlagProps) => {
  return LocaleToFlagMap[locale] || null;
};

Flag.displayName = 'Flag';
