import React from 'react';

interface Props {
  className?: string;
}

class DiscountTag extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-discount-tag', this.props.className].join(' ')}
        viewBox="0 0 89 88"
      >
        <g fill="none" transform="translate(1 1)" fillRule="evenodd">
          <path
            fill="#00B0B9"
            stroke="#001E62"
            d="M33.643,28.5149944 C34.523,28.5149944 35.387,28.773 36.134,29.273 C38.1882753,30.6531225 38.7376366,33.435718 37.362,35.493 C36.7011543,36.4834614 35.6717752,37.168634 34.503,37.396 C33.3371613,37.6270034 32.1273264,37.3841008 31.141,36.721 C30.1515741,36.0603989 29.4671611,35.0317993 29.24,33.864 C29.0071982,32.6975198 29.2502189,31.4863766 29.915,30.5 C30.7482435,29.2573319 32.1468338,28.5126426 33.643,28.5149944 L33.643,28.5149944 Z M37.265,52.91 L37.265,52.911 C38.2544603,53.5719392 38.9394073,54.6002595 39.168,55.768 C39.5150097,57.5463559 38.7583926,59.3589001 37.2499973,60.3627473 C35.741602,61.3665945 33.7774783,61.3647218 32.271,60.358 C31.2816692,59.6983966 30.5974651,58.6702898 30.371,57.503 C30.1370609,56.3363332 30.3797743,55.1245666 31.045,54.138 C31.8784404,52.895037 33.2774798,52.1503083 34.774,52.1529927 C35.654,52.1529927 36.518,52.411 37.264,52.91 L37.265,52.91 Z"
            fillRule="nonzero"
            strokeWidth="2"
          />
          <path
            fill="#001E62"
            d="M58.063,6.464 C58.6511108,6.79452737 59.200509,7.18963798 59.701,7.642 C60.1647948,7.16255628 60.6440477,6.69831127 61.138,6.25 C60.6413122,5.7894905 60.1037641,5.37511651 59.532,5.012 C59.03,5.478 58.542,5.963 58.063,6.464"
            fillRule="nonzero"
          />
          <path
            fill="#ffb500"
            d="M24.3,47.102 C23.496,39.097 23.757,31.073 25.088,23.252 L8.436,49.452 L1.4,61.144 C1.303,61.304 1.303,61.634 1.399,62.137 C0.930746375,63.6572565 0.876891733,65.2749671 1.243,66.823 C1.7839644,69.149519 3.23383416,71.1626268 5.269,72.413 L23.284,83.553 C27.093,85.906 31.984,85.063 34.821,81.774 C29.338,71.392 25.901,60.304 24.526,49.128 L24.3,47.102 L24.3,47.102 Z"
            fillRule="nonzero"
          />
          <path
            stroke="#001E62"
            d="M7.924,49.025 L31.292,11.415 C32.7850992,9.01285378 35.2256004,7.35533308 38.009,6.853 L51.785,4.365 C56.7323125,3.47157566 61.5738225,6.39000997 63.094,11.182 L67.398,24.747 C68.2796296,27.525569 67.9049347,30.5505187 66.372,33.03 L36.56,81.252 C33.655,85.949 27.493,87.403 22.795,84.499 L22.768,84.482 L4.768,73.274 C0.132127104,70.3871283 -1.33028387,64.3148952 1.483,59.634 L2.021,58.74"
            strokeWidth="2"
          />
          <path
            stroke="#001E62"
            d="M68.861,47.5 C71.723,41.623 73.97,37.217 75.601,34.283 C78.047,29.881 81.603,29.03 84.376,30.433 C87.149,31.834 87.75,35.515 85.564,37.683 C83.378,39.85 77.452,38.818 78.326,30.225 C79.201,21.632 82.538,18.862 83.378,15.939 C84.376,12.468 83.522,7.005 78.942,3.811 C74.362,0.617 66.617,-2.836 55.653,10.619 L54.27,9.949 C52.9053819,9.28751707 51.2617147,9.74843054 50.44,11.023 L49.148,13.027 C48.2510391,14.4183975 48.6506762,16.2733758 50.041,17.172 L51.906,18.377 C53.2884863,19.2705787 55.1327947,18.8832427 56.039,17.509 L57.39,15.459 C58.257016,14.1438219 57.9591692,12.3820715 56.708,11.425 L55.653,10.619 M22.857,48.712 L46.588,41.047"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  }
}

export const icon = DiscountTag;
