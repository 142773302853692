import React from 'react';
import Wahanda from 'common/wahanda';
import { generatePath } from 'react-router-dom';
import { Spinner } from 'components/common/Spinner';
import { Button } from 'components/common/Button';
import { Success } from 'components/common/IconPicture';
import PATHS from 'components/marketing/Router/paths';
import { VENUE_OPENING_CAMPAIGN } from 'components/marketing/EmailCampaign/CampaignList';
import { LayoutHeader } from 'components/common/Layout';

import { ClientReach } from './ClientReach';
import { Analytics } from '../tracking';
import style from './BackToBusinessInit.scss';

const lang = Wahanda.lang.marketing.campaignSelection.campaigns.backToBusiness.initiation;
interface Props {
  dataTest?: string;
  alreadySent?: boolean;
  isLoading: boolean;
  backToBusinessInitStatusRequest: () => void;
}

export const BackToBusinessInit: React.FC<Props> = ({
  isLoading,
  alreadySent,
  backToBusinessInitStatusRequest,
}) => {
  React.useEffect(() => {
    backToBusinessInitStatusRequest();
  }, []);
  const handleViewCampaignButton = () => {
    Analytics.trackCampaignPreviewButtonClicked();
    window.location.assign(
      Wahanda.Url.getFullUrl(
        'marketing',
        generatePath(PATHS.CAMPAIGN_PREVIEW_DEFAULT, {
          templateId: VENUE_OPENING_CAMPAIGN,
        }),
      ),
    );
  };
  return isLoading ? (
    <div className={style.loaderWrapper}>
      <Spinner dark />
    </div>
  ) : (
    <div className={style.container}>
      <div className={style.info}>
        <LayoutHeader header={lang.title} subheader={lang.description} />
        <ClientReach />
      </div>
      <div className={style.action}>
        {alreadySent ? (
          <Success className={style.successPicture} />
        ) : (
          <Button
            dataTest="preview-venue-opening-campaign"
            onClick={handleViewCampaignButton}
            label={lang.viewCampaignButton}
            size="large"
          />
        )}
      </div>
    </div>
  );
};
