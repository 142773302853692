/* global BackboneEx */
App.Views.Dialog.Calendar.CloseOut = BackboneEx.View.Dialog.extend({
  events: {
    'click .button-cancel': 'close',
    'click .a-close-out': 'closeOut',
    // Spa-break checkboxes
    'change .a-toggle-all': 'toggleAllCheckboxes',
    'change .a-toggle': 'toggleCheckbox',
  },

  options: {
    title: Wahanda.lang.calendar.spaDays.spaDayCloseOut.title,
    width: 460,
    dataTest: 'close-out-modal',
  },

  templateId: 'dated-closeout-template',

  setupDialog() {
    this.$('.v-dates').text(
      `${Wahanda.lang.date.months[this.options.date.getMonth()]} ${Wahanda.Util.combineRanges(
        this.options.days,
      ).join(', ')}`,
    );

    if (this.options.isSpaBreak) {
      this.renderSpaBreak();
    }
  },

  renderSpaBreak() {
    if (this.isSingleRoomMode()) {
      const model = this.getRenderedRoomType();
      if (model) {
        this.renderAttribute('single-room', model.get('name'));
      }
    } else {
      this.renderRoomList();
    }
  },

  getRenderedRoomType() {
    let model = this.options.roomTypes.get(this.options.singleRoomTypeId);
    if (!model) {
      model = this.options.roomTypes.at(0);
    }
    return model;
  },

  isSingleRoomMode() {
    return this.options.singleRoomTypeId || this.options.roomTypes.length === 1;
  },

  renderRoomList() {
    const list = [];
    this.options.roomTypes.each((model) => {
      list.push({
        id: model.id,
        roomName: model.get('name'),
      });
    });
    this.$('.on-off-list').html(
      Wahanda.Template.renderTemplate('dated-closeout-template-room', {
        list,
      }),
    );
    this.$('.b-room-types').wShow();
  },

  disableSubmitIfnothingChecked() {
    const checkedCount = this.$('.on-off-list').find('li').find(':checkbox:checked').length;
    this.$('.a-close-out').toggleFormElements(checkedCount > 0);
  },

  // Events

  closeOut() {
    const self = this;
    let model;
    if (this.options.isSpaBreak) {
      model = new App.Models.RoomAvailabilitySaver();
      const roomTypes = [];
      if (this.isSingleRoomMode()) {
        const singleRoom = this.getRenderedRoomType();
        roomTypes.push({
          roomTypeId: (singleRoom || {}).id,
          inventoryAvailable: null,
          closed: true,
        });
      } else {
        this.options.roomTypes.each((type) => {
          const isClosed = self.$(`#dated-day-open-${type.id}`).prop('checked');
          if (isClosed) {
            roomTypes.push({
              roomTypeId: type.id,
              inventoryAvailable: null,
              closed: true,
            });
          }
        });
      }

      model.set({
        roomTypes,
        dates: Wahanda.Date.getMonthDateApiStrings(this.options.date, this.options.days),
      });
    } else {
      model = new App.Models.SpaAvailabilitySaver();
      model.set({
        inventoryAvailable: null,
        closed: true,
        dates: Wahanda.Date.getMonthDateApiStrings(this.options.date, this.options.days),
      });
    }

    this.disableForm();

    model.save(null, {
      success() {
        self.close();

        App.trigger(Wahanda.Event.CALENDAR_DAYS_CLOSED_OUT, {
          calendarType: self.options.isSpaBreak ? 'spa-break' : 'spa-day',
          dates: model.get('dates'),
        });
      },
      error() {
        self.enableForm();
        self.$('.a-close-out').errorTip(Wahanda.lang.shared.savingFailed);
      },
    });
  },

  toggleAllCheckboxes(event) {
    const $allCheckbox = $(event.currentTarget);
    const allChecked = $allCheckbox.prop('checked');
    const $allItems = this.$('.on-off-list').find('li').slice(1);

    $allCheckbox.closest('li').toggleClass('on', allChecked).toggleClass('off', !allChecked);

    if (allChecked) {
      $allItems.addClass('on').removeClass('off');
      $allItems.find('input[type=checkbox]').prop('checked', true).disableFormElements();
    } else {
      $allItems.addClass('off').removeClass('on');
      $allItems.find('input[type=checkbox]').prop('checked', false).enableFormElements();
    }

    this.disableSubmitIfnothingChecked();
  },

  toggleCheckbox(event) {
    const $cBox = $(event.currentTarget);
    const $li = $cBox.closest('li');
    const checked = $cBox.prop('checked');

    $li.toggleClass('on', checked).toggleClass('off', !checked);

    this.disableSubmitIfnothingChecked();
  },
});
