import React from 'react';
import ReactDOM from 'react-dom';
import ChangeConfirmation from 'components/settings/finance/DirectDebitBankDetailsChangeConfirmation';

export default function (targetNode, { lang, onConfirm }) {
  const removeFromDOM = () => {
    ReactDOM.unmountComponentAtNode(targetNode);
  };
  const dialog = <ChangeConfirmation lang={lang} onConfirm={onConfirm} onClose={removeFromDOM} />;
  ReactDOM.render(dialog, targetNode);
}
