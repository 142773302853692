import { connect } from 'react-redux';
import App from 'common/backbone-app';
import { SidebarInfo as Component } from './SidebarInfo';
import { shouldShowSupportPhoneNumberSelector } from 'utilities/shouldShowSupportPhoneNumber';

const mapStateToProps = (state) => {
  return {
    venueId: App.getVenueId(),
    employeeName: App.config.accountEmployeeName() || App.config.accountFullName(),
    emailAddress: App.config.get('account').emailAddress,
    accountId: App.config.getAccountId(),
    isSelfOnboarding: App.config.isVenuePending() && App.config.get('venue').selfOnboardingEnabled,
    phoneNumber: App.config.get('channel').supplierSupportPhoneNumber,
    shouldShowSupportPhoneNumber: shouldShowSupportPhoneNumberSelector(state),
  };
};

export const SidebarInfo = connect(mapStateToProps)(Component);
