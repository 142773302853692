import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import { connect } from 'react-redux';
import { CovidBanner as Component } from './CovidBanner';

const mapStateToProps = ({ covidBanner }) => {
  const isShutdown = App.isFeatureSupported('market-shutdown');
  const isSalonLimited = App.config.get('venue').limitedOperation;
  const justSuccessfullyReopened = covidBanner.justSuccessfullyReopened;
  return {
    hasFullPermissions: Wahanda.Permissions.manageVenueDetails(),
    canAccessMarketingTools: Wahanda.Permissions.canAccessMarketingTools(),
    isClosed: isShutdown,
    isReopenPending: !isShutdown && isSalonLimited && !justSuccessfullyReopened,
    isReopened: (!isShutdown && !isSalonLimited) || justSuccessfullyReopened,
    isVenueClosingDialogShown: covidBanner.isVenueClosingDialogShown || false,
    isVenueOpenConfirmationDialogShown: covidBanner.isVenueOpenConfirmationDialogShown || false,
  };
};

export const CovidBanner = connect(mapStateToProps)(Component);
