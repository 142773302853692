import { getWithPromise, putWithPromise, postWithPromise, deleteWithPromise } from 'common/xhr';

export const get = <T>(url, params = {}) =>
  new Promise<T>((resolve, reject) => {
    getWithPromise(url, params)
      .done((result) => resolve(result))
      .fail((jq, err) => reject(err));
  });

export const put = (uri: string, data?, returnXHRObjectOnFail?) =>
  new Promise((resolve, reject) => {
    putWithPromise(uri, data)
      .done((result) => resolve(result))
      .fail((jq, err) => {
        if (returnXHRObjectOnFail) {
          reject(jq);
        } else {
          reject(err);
        }
      });
  });

export const post = (uri: string, data?, returnXHRObjectOnFail?, options = {}) =>
  new Promise((resolve, reject) => {
    postWithPromise(uri, data, options)
      .done((result) => resolve(result))
      .fail((jq, err) => {
        if (returnXHRObjectOnFail) {
          reject(jq);
        } else {
          reject(err);
        }
      });
  });

export const del = (uri: string, returnXHRObjectOnFail?) =>
  new Promise((resolve, reject) => {
    // 'delete' is reserved
    deleteWithPromise(uri)
      .done((result) => resolve(result))
      .fail((jq, err) => {
        if (returnXHRObjectOnFail) {
          reject(jq);
        } else {
          reject(err);
        }
      });
  });
