import React, { PureComponent, Fragment } from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import classnames from 'classnames';
import { TableRow, TableData } from 'components/common/Table';
import { Badge } from 'components/common/Badge';
import { Currency, Time } from 'components/common/DataFormat';
import { TooltipOverflow } from 'components/common/TooltipOverflow';
import style from './style.scss';
import { TRANSACTION_TYPE, PAYMENT_TYPE } from './types';

const lang = {
  headings: Wahanda.lang.reports.transactions.table.headings,
  payments: Wahanda.lang.reports.transactions.table.payments,
  badges: Wahanda.lang.reports.transactions.table.badges,
};
const uniqueKey = (props) =>
  Object.values(props).reduce((accumulator, currentValue) => `${accumulator}-${currentValue}`, '');
const MAX_VOUCHER_LENGHT = 45;
interface ITransactionProps extends React.HTMLAttributes<Element> {
  lineItems?: {
    name?: string;
    amount: number;
    taxRate: number;
    taxAmount: number;
  }[];
  description?: string;
  amount: number;
  advancedView?: boolean;
  customerName?: string;
  referenceTransactionId?: string;
  transactionId?: string;
  time: string;
  refundNote?: string;
  onClick?: (...args: any[]) => any;
  venueTransactionType?: string;
  isWalkIn?: boolean;
  isWithdrawal?: boolean;
  isDeposit?: boolean;
  isSale?: boolean;
  isRefund?: boolean;
  payments?: {
    type: string;
    amount: number;
    description?: string;
    notes?: string;
  }[];
}
class Transaction extends PureComponent<ITransactionProps, {}> {
  static defaultProps = {
    isWalkIn: false,
    isSale: false,
    venueTransactionType: null,
    customerName: null,
    isRefund: false,
    isDeposit: false,
    isWithdrawal: false,
    description: null,
    lineItems: [],
    advancedView: false,
    refundNote: null,
    referenceTransactionId: null,
    transactionId: null,
    onClick: null,
    payments: [],
  };

  onClick = () => {
    if (!this.props.onClick) {
      return;
    }
    this.props.onClick(this.props);
  };

  getPaymentTypeText = (payment) => {
    switch (payment.type) {
      case PAYMENT_TYPE.CASH:
        return lang.payments.cash;
      case PAYMENT_TYPE.PREPAID:
        return lang.payments.prepaid;
      case PAYMENT_TYPE.TAP_TO_PAY:
        return 'Tap To Pay';
      case PAYMENT_TYPE.CARD:
        return `${lang.payments.card} (${payment.description})`;
      case PAYMENT_TYPE.VOUCHER:
        if (!payment.notes) {
          return payment.description;
        }
        return (
          <TooltipOverflow
            placement="top"
            text={`${payment.description} (${payment.notes})`}
            maxLength={MAX_VOUCHER_LENGHT}
          />
        );
      default:
        return null;
    }
  };

  renderRefundBadge = (text) => <div className={style.refunded}>{text}</div>;

  renderBadge = () => {
    const { isRefund, isDeposit, isSale, isWithdrawal, venueTransactionType } = this.props;
    if (isRefund) {
      return <Badge type="alert" text={lang.badges.refund} />;
    }
    if (isDeposit) {
      return <Badge type="success" text={lang.badges.deposit} />;
    }
    if (isSale) {
      return <Badge type="success" text={lang.badges.sale} />;
    }
    if (isWithdrawal) {
      if (venueTransactionType === TRANSACTION_TYPE.CASH_REGISTER_CORRECTION_WITHDRAWAL) {
        return <Badge type="alert" text={lang.badges.drawerCorrection} />;
      }
      return <Badge type="alert" text={lang.badges.withdrawal} />;
    }
    return null;
  };

  render() {
    const {
      time,
      transactionId,
      referenceTransactionId,
      customerName,
      description,
      refundNote,
      advancedView,
      amount,
      lineItems,
      isWithdrawal,
      payments,
      onClick,
      isWalkIn,
    } = this.props;
    return (
      <TableRow
        // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type '(() => void... Remove this comment to see the full error message
        onClick={onClick ? this.onClick : null}
        hoverable
        dataTest={`transaction-${transactionId}`}
      >
        <TableData>
          <p className={style.time}>
            <Time time={moment(time, 'HH:mm:ss').format()} />
          </p>
          <div className={style.transactionIdSmall}>{transactionId}</div>
        </TableData>
        <TableData className={style.hideMobile}>{transactionId}</TableData>
        <TableData className={style.transactionType}>
          {this.renderBadge()}
          {referenceTransactionId &&
            this.renderRefundBadge(`${lang.headings.refunds} ${referenceTransactionId}`)}
        </TableData>
        <TableData
          data-hj-suppress
          className={classnames(style.customerName, isWalkIn ? style.noWrap : null)}
        >
          {customerName}
        </TableData>
        <TableData className={style.totalSum}>
          <Currency amount={amount} />
        </TableData>
        {advancedView && (
          <Fragment>
            <TableData className={style.notes}>
              {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
              {lineItems.map((item, index) => (
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'number'.
                <div key={uniqueKey({ ...item, index })}>{item.name}</div>
              ))}
              {refundNote && this.renderRefundBadge(refundNote)}
              {description && <div className={style.description}>{description}</div>}
            </TableData>
            <TableData className={style.amount}>
              {isWithdrawal ? (
                <Currency amount={amount} />
              ) : (
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                lineItems.map((item, index) => (
                  // @ts-expect-error ts-migrate(2769) FIXME: Type 'unknown' is not assignable to type 'number'.
                  <Currency key={uniqueKey({ ...item, index })} amount={item.amount} />
                ))
              )}
            </TableData>
            <TableData className={style.taxRate}>
              {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
              {lineItems.map((item, index) => (
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'number'.
                <div key={uniqueKey({ ...item, index })}>{`${item.taxRate}%`}</div>
              ))}
            </TableData>
            <TableData className={style.taxAmount}>
              {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
              {lineItems.map((item, index) => (
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'number'.
                <div key={uniqueKey({ ...item, index })}>{item.taxAmount}</div>
              ))}
            </TableData>
          </Fragment>
        )}
        <TableData className={style.paymentType}>
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          {payments.map((payment, index) => (
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'number'.
            <div className={style.payment} key={uniqueKey({ ...payment, index })}>
              {this.getPaymentTypeText(payment)}
            </div>
          ))}
        </TableData>
        <TableData className={style.paymentAmount}>
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          {payments.map((payment, index) => (
            // @ts-expect-error ts-migrate(2769) FIXME: Type 'unknown' is not assignable to type 'number'.
            <Currency amount={payment.amount} key={uniqueKey({ ...payment, index })} />
          ))}
        </TableData>
      </TableRow>
    );
  }
}

export default Transaction;
