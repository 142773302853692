App.Collections.CustomerSearch = BackboneEx.Collection.UrlFilter.extend({
  model: App.Models.CustomerSearch,

  urlTemplate: '/venue/{{venueId}}/existing-customer-search.json',

  filters: {
    name: null,
    phone: null,
    email: null,
    include: null,
  },

  filterMap: {
    name: 'name',
    phone: 'phone',
    email: 'email',
    include: 'include',
  },

  parse: function (data) {
    return data;
  },
});
