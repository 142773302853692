/* global BackboneEx */
import { trackEvent } from 'common/analytics';

App.Views.Forms.Employee.LinkExternalCalendar = BackboneEx.View.Dialog.ValidatingForm.extend({
  events: {
    'click .button-cancel': 'close',
  },
  options: {
    width: 600,
    title: Wahanda.lang.settings.employees.employee.linkCalendar.title,
    dialogClass: 'cal-link-dialog',
  },
  templateId: 'employee-external-cal-link-dialog',
  confirmClosingIfChangesExist: false,

  setValidation() {
    this._setDefaultValidation();
  },

  save() {
    trackEvent('team-member-external-calendar', 'submit', 'link-calendar');
    const self = this;
    const originalCalendarUri = this.model.get('externalCalendarUri');

    let calUri = $.trim(this.$('#employee-externalCalendarUri').val());
    // Fix for DEV-4942: convert webcal to http
    calUri = calUri.replace(/^webcal:/, 'http:');
    this.model.set('externalCalendarUri', calUri);

    this.disableForm();
    const $button = this.$('.button-primary').action('doing');

    const checkExternalCalendar = new App.Models.ExmployeeExternalCalendar({
      id: this.model.get('id'),
      venueId: App.config.get('venue').id,
    });

    // Save the Employee ext. calendar URI to the employee.
    this.model.save(null, {
      success() {
        checkCalendarUri();
      },
    });

    function checkCalendarUri() {
      checkExternalCalendar.fetch({
        success() {
          App.trigger(Wahanda.Event.EMPLOYEE_EXT_CALENDAR_SUBSCRIBED, self.model);
          self.showSuccessDialog();
          self.close();
        },
        error(model, xhr) {
          revertExternalCalendarUriChange(xhr);
        },
      });
    }

    function revertExternalCalendarUriChange(xhr) {
      self.model.set('externalCalendarUri', originalCalendarUri);
      self.model.save(null, {
        success() {
          $button.action();
          self.enableForm();

          if (xhr.status === 422) {
            self.showCalendarUriError(xhr);
          } else {
            $button.errorTip(Wahanda.lang.shared.savingFailed);
          }
        },
      });
    }
  },

  showCalendarUriError(xhr) {
    const errors = Wahanda.Util.parseErrors(xhr);

    if (errors.length) {
      const lang = Wahanda.lang.settings.employees.employee.linkCalendar.uriError;
      const error = errors[0];
      const text = `${lang.intro}<br />${App.Models.ExmployeeExternalCalendar.getFailureTextByName(
        error.name,
      )}`;

      this.$('.button-primary').noticeTip(text, null, 5000);
    }
  },

  showSuccessDialog() {
    // TODO: after linking re-render the Appointment's Link part
    const Dialog = BackboneEx.View.Dialog.extend({
      events: {
        'click .button-cancel': 'close',
      },
      options: {
        width: 600,
        title: Wahanda.lang.settings.employees.employee.linkCalendar.title,
        dialogClass: 'cal-link-dialog',
        dataTest: 'link-external-calendar-modal',
      },

      templateId: 'employee-external-cal-link-success-dialog',
    });

    const view = new Dialog();
    view.render();
    view.open();
  },
});
