import React from 'react';
import Wahanda from 'common/wahanda';
import { GroupPayload, SingleGroup } from '../store/types';
import { ServiceRow } from '../ServiceRow';
import style from './ServiceGroups.scss';

interface Props {
  singleGroup?: SingleGroup;
  selectedGroups: GroupPayload[];
}

const GroupHeader = ({ name }: { name: string }) => {
  return (
    <div className={style.groupName}>
      {name}
      <div className={style.inputLegends}>
        <div className={style.duration}>
          {Wahanda.lang.menu.multipleServicesImport.serviceRow.duration}
        </div>
        <div className={style.price}>
          {Wahanda.lang.menu.multipleServicesImport.serviceRow.price}
        </div>
      </div>
    </div>
  );
};

const Services = ({ item }) =>
  item.services.map((service) => (
    <ServiceRow
      key={service.serviceId}
      serviceId={service.serviceId}
      menuGroupId={item.menuGroupId}
      name={service.name}
      processingTimeMins={service.processingTimeMins}
      skus={service.skus}
    />
  ));

export const ServiceGroups = ({ singleGroup, selectedGroups }: Props) => {
  const singleMenuGroupContent = !!singleGroup && (
    <div className={style.container}>
      <GroupHeader name={singleGroup.name} />
      {selectedGroups.map((item) => {
        if (!item.services.length) {
          return null;
        }
        return <Services item={item} key={item.menuGroupId} />;
      })}
    </div>
  );

  const multiMenuGroupsContent = selectedGroups.map((item) => {
    if (!item.services.length) {
      return null;
    }

    return (
      <div key={item.menuGroupId} className={style.container}>
        <GroupHeader name={item.name} />
        <Services item={item} />
      </div>
    );
  });

  return <div className={style.content}>{singleMenuGroupContent || multiMenuGroupsContent}</div>;
};
