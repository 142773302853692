/* global _ */
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import series from 'async/series';
import parallel from 'async/parallel';

const MINUTES_IN_DAY = 24 * 60 - 1;

/**
 *  Move an appointment or its appointment groups. Confirms if that is needed first
 *
 *  This could be a reschedule or rebook as the actions leading up to saving are
 *  the same except the absence of ids in the rebook process
 *
 *  @param {Appointment} appointment The appointment to reschedule
 *  @param {Object} changes List of changes
 *  @param {boolean} moveAllAssociatedAppts (optional) Should Package appointments
 *  be rescheduled one after another, not just the one acted on. Defaults to false
 *  @param {boolean} isRebook Flag to indicate whether we want to perform a rebook
 *
 *  @return {$.Promise} The promise to reschedule
 */
function reschedule(appointment, changes, moveAllAssociatedAppts, isRebook) {
  const deferred = $.Deferred();

  function updateAppointmentValues(appt, customChanges?) {
    const ownChanges = customChanges || changes;

    if (ownChanges.employeeId) {
      appt.set('employeeId', ownChanges.employeeId);
    }
    if (ownChanges.date && ownChanges.timeDiff !== null) {
      appt.set('notifyConsumer', appt.isValidForEmailNotifications());

      if (ownChanges.targetDate) {
        appt.moveTo(ownChanges.targetDate);
      } else {
        appt.move(ownChanges.timeDiff, ownChanges.date);
      }
    }
    if (isRebook) {
      /*
       *  Don't copy notes for rebooked appointments
       */
      appt.set('notes', null);
    }
  }

  function updateAppointment(appt, customChanges?, customDeferred?) {
    updateAppointmentValues(appt, customChanges);

    const ownDeferred = customDeferred || deferred;

    function save() {
      return appt.save().done(ownDeferred.resolve).fail(ownDeferred.reject);
    }

    if (!isRebook && appt.isUnconfirmed()) {
      appt.confirm().done(save).fail(ownDeferred.reject);
    } else {
      save();
    }
  }

  function updateAllAppointments(pkgGroup, customChanges) {
    let prevAppt;

    pkgGroup.each((appt, apptIndex) => {
      if (apptIndex === 0) {
        updateAppointmentValues(appt, customChanges);
      } else {
        const nextChanges = _.extend({}, changes, {
          targetDate: prevAppt.getEndDateWithAdditionalTime(),
        });

        updateAppointmentValues(appt, nextChanges);
      }
      prevAppt = appt;
    });
  }

  function movePackageAppts(pkgGroup, customChanges?, customDeferred?) {
    if (moveAllAssociatedAppts) {
      updateAllAppointments(pkgGroup, customChanges);
    } else {
      /*
       *  Update the dragged appointment with all changes - keeping others as is
       */
      updateAppointmentValues(appointment);
      /*
       *  If the date is changed, reschedule all Package appointments to the new date
       *  but don't apply other (employee, time, etc) changes
       */
      if (changes.date && changes.date !== pkgGroup.data.appointmentDate) {
        pkgGroup.each((appt) => {
          appt.set('appointmentDate', changes.date);
        });
      }
    }

    const ownDeferred = customDeferred || deferred;

    if (isRebook) {
      /*
       *  Don't copy notes for rebooked groups
       */
      pkgGroup.data.notes = null; // eslint-disable-line no-param-reassign
    }

    // eslint-disable-next-line no-param-reassign
    pkgGroup.data.notifyConsumer = appointment.get('notifyConsumer');
    pkgGroup.save().done(ownDeferred.resolve).fail(ownDeferred.reject);
  }

  function maybeConfirmPackageGroup(maybePackageGroup, maybeRebook) {
    if (maybePackageGroup) {
      if (maybePackageGroup.isUnconfirmed() && !maybeRebook) {
        maybePackageGroup
          .confirm()
          .done(_.partial(movePackageAppts, maybePackageGroup))
          .fail(deferred.reject);
      } else {
        movePackageAppts(maybePackageGroup);
      }
    } else {
      /*
       *  No package group. Reschedule the Appointment on it's own
       */
      updateAppointment(appointment);
    }
  }

  if (appointment.belongsToGroup()) {
    let packageGroup = appointment.getLinkedPackageGroup();

    if (isRebook) {
      /*
       *  Remove appt ids if this is a rebook action
       */
      if (packageGroup) {
        packageGroup = packageGroup.createNew();
      }
    }

    maybeConfirmPackageGroup(packageGroup, isRebook);
  } else {
    updateAppointment(isRebook ? appointment.createNew() : appointment);
  }

  return deferred.promise();
}

function findFirstAppointmentStart(collection, targetAppointment, changes) {
  let found = false;
  const targetDate = changes.date || targetAppointment.get('appointmentDate');
  const timeDiff = changes.timeDiff || 0;

  /*
   *  find difference between first and target appointments
   */
  const distance = collection.reduce((distanceInMinutes, appt) => {
    if (appt === targetAppointment) {
      found = true;
    }
    if (found) {
      return distanceInMinutes;
    }
    return distanceInMinutes + appt.getDuration(true);
  }, 0);

  const newStartMinutes = targetAppointment.getStartTimeMinutes() + timeDiff - distance;

  if (newStartMinutes < 0 || newStartMinutes > MINUTES_IN_DAY) {
    return null;
  }

  return Wahanda.Date.createDate(targetDate, Wahanda.Time.toApiString(newStartMinutes));
}

function fitsToSingleDay(targetDate, collection, firstStartDate) {
  if (firstStartDate < 0) {
    return false;
  }

  const workTime = App.Models.WorkingHours.getVisibleCalendarTimeRangeForDate(targetDate);
  const totalDuration = collection.reduce((memo, appt) => memo + appt.getDuration(true), 0);
  const startMinutes = Wahanda.Time.getDateMinutes(firstStartDate);
  const endMinutes = startMinutes + totalDuration;

  return workTime.opens <= startMinutes && workTime.closes >= endMinutes;
}

function wrapPromiseAsSeriesTask(promiseFn) {
  return (seriesCallback) => {
    promiseFn().then(
      () => seriesCallback(null),
      (err) => seriesCallback(err),
    );
  };
}

/**
 *  Entry point for appointment reschedule/rebooking.
 *
 *  @param {Appointment} appointment The target appointment
 *  @param {Object} changes { date, employeeId, timeDiff }
 *  @param {Collection} dayCollection (optional) List of appointments for the same
 *  customer on the same date, to be rescheduled one after another
 *  @param {boolean} moveAllAssociatedAppts (optional) Should all appointments be
 *  moved, not just the target one
 *  @param {boolean} isRebook (optional) Is this a rebooking action? If true,
 *  appointments will be cloned instead of moved
 *
 *  @returns {$.Promise} The promise to finish what was taken on.
 */
function proxyToReschedule(appointment, changes, dayCollection, moveAllAssociatedAppts, isRebook) {
  if (dayCollection) {
    /*
     *  Reschedule/rebook all appointments in the collection
     */
    const firstAppointmentStartDate = findFirstAppointmentStart(
      dayCollection,
      appointment,
      changes,
    );
    const canFitToSingleDay = fitsToSingleDay(
      changes.date,
      dayCollection,
      firstAppointmentStartDate,
    );

    if (!firstAppointmentStartDate || !canFitToSingleDay) {
      const error = new Error('Reschedule does not fit into a single day');

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type 'Error'.
      error.type = 'overlap-into-next-day';

      /*
       *  Throw the error (which stops the execution)
       *
       *  The error will be caught in the proxy function and won't be raised into the
       *  outer scope
       */
      throw error;
    }

    let prevDurations = 0;
    const appointmentsToSave = dayCollection.getSeparateAppointments();
    const tasks = appointmentsToSave.map((apptItem) => {
      const newStartDate = Wahanda.Date.addMinutesToDate(firstAppointmentStartDate, prevDurations);
      const localChanges = _.extend({}, changes, { targetDate: newStartDate });
      const durationItem = apptItem.getLinkedPackageGroup() || apptItem;

      prevDurations += durationItem.getDuration(true);

      return wrapPromiseAsSeriesTask(() => reschedule(apptItem, localChanges, true, isRebook));
    });

    const returnDeferred = $.Deferred();

    /*
     *  Run callbacks one after another, waiting for previous to finish before starting
     *  the next.
     *
     *  Parallel save fails if we're rebooking a larger amount of appointments (e.g. 5)
     *
     *  We need to save one after each other, waiting for the previous save to complete
     *  before starting the next one
     *
     *  BUT! for rescheduling, when no appointments are created, we can run the saves in
     *  parallel
     */
    const asyncFn = isRebook ? series : parallel;

    asyncFn(tasks, (maybeErr) => {
      if (!maybeErr) {
        returnDeferred.resolve();
      } else {
        returnDeferred.reject(maybeErr);
      }
    });

    return returnDeferred.promise();
  }
  return reschedule(appointment, changes, moveAllAssociatedAppts, isRebook);
}

export default function entryPoint(...args) {
  try {
    // @ts-expect-error ts-migrate(2556) FIXME: Expected 5 arguments, but got 0 or more.
    return proxyToReschedule(...args);
  } catch (e) {
    const deferred = $.Deferred();

    deferred.reject(e);

    if (window.console) {
      console.error(e);
    }

    return deferred.promise();
  }
}
