/* global BackboneEx */
App.Views.Dialog.ImageUpload = BackboneEx.View.Dialog.extend({
  events: {
    'click .button-cancel': 'close',
  },

  options: {
    dataTest: 'image-upload-modal',
  },

  ui: {
    progress: null,
    button: null,
  },

  setupDialog() {
    this.ui.progress = this.$('.progress');
    this.ui.button = this.$('.choose-file');
  },

  setProgress(percent) {
    if (this.ui.progress.is(':hidden')) {
      this.switchUIElems(this.ui.progress, this.ui.button);
      this.ui.button.qtip('hide');
    }

    const $bar = this.ui.progress.find('.bar');
    $bar.css('width', `${percent}%`);
  },

  hideProgress() {
    this.switchUIElems(this.ui.button, this.ui.progress);
  },

  switchUIElems($toShow, $toHide) {
    $toShow.hide().wShow().fadeIn();
    $toHide.fadeOut(() => {
      $toHide.wHide().css('display', '');
    });
  },

  showUploadError(errors) {
    const error = errors[0];
    const button = this.$('.submit-image');
    let errorText = null;

    switch (error.name) {
      case 'image-type-unknown':
        errorText = Wahanda.lang.shared.errors.fileUpload.notImage;
        break;

      case 'image-file-too-big':
        errorText = Wahanda.lang.shared.errors.fileUpload.tooBig;
        break;

      case 'image-dimensions-too-big':
        errorText = Wahanda.lang.shared.errors.fileUpload.dimensionsTooBig;
        break;

      case 'image-dimensions-too-small':
        errorText = Wahanda.lang.shared.errors.fileUpload.dimensionsTooSmall;
        break;

      default:
        break;
    }
    if (errorText) {
      button.noticeTip(errorText, null, 7500);
    }
  },

  handleFail(data) {
    try {
      let response;
      if (data.jqXHR.status === 413) {
        response = {
          errors: [
            {
              name: 'image-file-too-big',
            },
          ],
        };
      } else {
        response = JSON.parse(data.jqXHR.responseText);
      }
      this.showUploadError(response.errors);
    } catch (e) {
      console.log(e);
    }
  },
});
