import React from 'react';
import { Button } from 'components/common/Button';
import style from './StateCard.scss';

interface Props {
  dataTest?: string;
  heading: string;
  message: React.ReactNode;
  button?: {
    text: string;
    onClick: () => void;
  };
  secondaryButton?: {
    text: string;
    onClick: () => void;
  };
  image?: React.ReactNode;
}

export const StateCard = (props: Props) => (
  <div className={style.container} data-test={props.dataTest}>
    {props.image && <div className={style.image}>{props.image}</div>}
    <h1 className={style.heading}>{props.heading}</h1>
    {React.isValidElement(props.message) ? (
      <div className={style.text}>{props.message}</div>
    ) : (
      <div className={style.text} dangerouslySetInnerHTML={{ __html: props.message as string }} />
    )}

    {props.button && <Button label={props.button.text} onClick={props.button.onClick} />}
    {props.secondaryButton && (
      <div className={style.secondaryButton}>
        <Button
          variant="secondary"
          label={props.secondaryButton.text}
          onClick={props.secondaryButton.onClick}
        />
      </div>
    )}
  </div>
);

StateCard.displayName = 'StateCard';
