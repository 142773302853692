import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestChannelSalesReportDataAction } from '../actions';
import ChannelSalesReport from './index';

const mapStateToProps = (state) => ({
  reportData: state.salesReport.channelSales.reportData,
  displayState: state.salesReport.channelSales.displayState,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ requestChannelSalesReportDataAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ChannelSalesReport);
