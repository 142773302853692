import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';

const LANG = Wahanda.lang.menu.offer.packages.addServices.employeeCategoryMismatch;
const AlertDialog = App.Views.Dialog.Alert;

export default AlertDialog.extend({
  events: {
    'click .js-save': 'close',
  },

  buttons: {
    save: {
      title: LANG.button,
    },
  },

  options: {
    width: 350,
    dialogClass: 'dialog2--white dialog2--category-mismatch',
  },

  render: function () {
    const content = Wahanda.Template.render('<div class="title">{{title}}</div>{{body}}', LANG);

    AlertDialog.prototype.render.call(this, content);
  },
});
