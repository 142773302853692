import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Comment extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-comment', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M14.5857864,16 L18.0092545,16 C19.6620736,16 21,14.6598576 21,13.0041738 L21,8.99582624 C21,7.3461052 19.6544993,6 18.00077,6 L5.99922997,6 C4.34381257,6 3,7.34221647 3,8.99582624 L3,13.0041738 C3,14.6543694 4.34217277,16 5.99074554,16 L9.41421356,16 L12,18.5857864 L14.5857864,16 Z M18.00077,4 C20.75927,4 23,6.24173676 23,8.99582624 L23,13.0041738 C23,15.7636868 20.7673847,18 18.0092545,18 L15.4142136,18 L13.063027,20.3511866 C12.4760049,20.9382087 11.522201,20.9364145 10.936973,20.3511866 L8.58578644,18 L5.99074554,18 C3.23644968,18 1,15.7577886 1,13.0041738 L1,8.99582624 C1,6.23711454 3.23977481,4 5.99922997,4 L18.00077,4 Z M12,9.5 C11.1715729,9.5 10.5,10.1715729 10.5,11 C10.5,11.8284271 11.1715729,12.5 12,12.5 C12.8284271,12.5 13.5,11.8284271 13.5,11 C13.5,10.1715729 12.8284271,9.5 12,9.5 Z M17,9.5 C16.1715729,9.5 15.5,10.1715729 15.5,11 C15.5,11.8284271 16.1715729,12.5 17,12.5 C17.8284271,12.5 18.5,11.8284271 18.5,11 C18.5,10.1715729 17.8284271,9.5 17,9.5 Z M7,9.5 C6.17157288,9.5 5.5,10.1715729 5.5,11 C5.5,11.8284271 6.17157288,12.5 7,12.5 C7.82842712,12.5 8.5,11.8284271 8.5,11 C8.5,10.1715729 7.82842712,9.5 7,9.5 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Comment);
