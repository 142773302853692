(function ($) {
  var loadmaskDataKey = 'loadmask';
  var loadmaskClassName = 'loading-content';
  /**
   * "Loading" indicator mask
   *
   * Possible options:
   * > {String} position (optional) "bottom-right" for bottom right positioning
   *
   * (c) Treatwell
   */
  $.fn.loadmask = function (options) {
    if (this.data(loadmaskDataKey)) {
      this.unloadmask();
    }

    addMask(this, $.extend({}, $.loadmask.defaults, options || {}));

    return this;
  };

  $.fn.unloadmask = function () {
    var $mask = this.data(loadmaskDataKey);
    if ($mask) {
      $mask.remove();
      this.removeData(loadmaskDataKey);
    }

    return this;
  };

  function addMask($element, options) {
    var className = loadmaskClassName + (options.position === "bottom-right" ? " loadmask--bottom-right" : "");
    var $mask = $('<div>', { "class": className }).html(
      Wahanda.Template.renderTemplate("tw-loading")
    );

    $element.data(loadmaskDataKey, $mask);

    $mask.appendTo($element);
  }

  $.loadmask = {
    defaults: {
      text: Wahanda.lang.shared.loading
    }
  };
}(jQuery));
