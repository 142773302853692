import Wahanda from 'common/wahanda';
import { colors } from 'style/exports/colors';

import { Chat, Gift, PatchTest, Reload, Star, Team } from 'components/common/Icon';

import { AutomatedMessagingCategory, AutomatedMessagingSetting, SETTING } from './types';

const lang = Wahanda.lang.marketing.automatedMessaging;

const settingText = {
  [SETTING.GENERAL]: {
    title: lang.general.title,
    description: lang.general.description,
    areYouSureTitle: lang.general.areYouSureTitle,
    areYouSureDescription: lang.general.areYouSureDescription,
  },
  [SETTING.SEND_PATCH_TEST_INVITES]: {
    title: lang.sendPatchTestInvites.title,
    description: lang.sendPatchTestInvites.description,
    areYouSureTitle: lang.sendPatchTestInvites.areYouSureTitle,
    areYouSureDescription: lang.sendPatchTestInvites.areYouSureDescription,
  },
  [SETTING.SEND_HELPFUL_REMINDERS]: {
    title: lang.sendHelpfulReminders.title,
    description: lang.sendHelpfulReminders.description,
    areYouSureTitle: lang.sendHelpfulReminders.areYouSureTitle,
    areYouSureDescription: lang.sendHelpfulReminders.areYouSureDescription,
  },
  [SETTING.ASK_FOR_REVIEWS]: {
    title: lang.askForReview.title,
    description: lang.askForReview.description,
    areYouSureTitle: lang.askForReview.areYouSureTitle,
    areYouSureDescription: lang.askForReview.areYouSureDescription,
  },
  [SETTING.INVITES_TO_REBOOK]: {
    title: lang.inviteToRebook.title,
    description: lang.inviteToRebook.description,
    areYouSureTitle: lang.inviteToRebook.areYouSureTitle,
    areYouSureDescription: lang.inviteToRebook.areYouSureDescription,
  },
  [SETTING.SEND_BIRTHDAY_CONGRATULATIONS]: {
    title: lang.birthdayCongratulations.title,
    description: lang.birthdayCongratulations.description,
    areYouSureTitle: lang.birthdayCongratulations.areYouSureTitle,
    areYouSureDescription: lang.birthdayCongratulations.areYouSureDescription,
  },
};

export const settings: Array<AutomatedMessagingSetting> = [
  {
    name: SETTING.GENERAL,
    text: settingText[SETTING.GENERAL],
    shouldAskBeforeDisabling: true,
    link: lang.general.link,
    icon: Team,
    iconBackground: colors.colorNavy,
    trackingId: 'general',
    category: AutomatedMessagingCategory.TRANSACTIONAL,
  },
  {
    name: SETTING.SEND_PATCH_TEST_INVITES,
    text: settingText[SETTING.SEND_PATCH_TEST_INVITES],
    shouldAskBeforeDisabling: true,
    link: lang.sendPatchTestInvites.link,
    icon: PatchTest,
    iconBackground: colors.colorPink,
    trackingId: 'patch-test-invites',
    category: AutomatedMessagingCategory.TRANSACTIONAL,
    channelFeature: 'patchtest-invite-sms',
  },
  {
    name: SETTING.SEND_HELPFUL_REMINDERS,
    text: settingText[SETTING.SEND_HELPFUL_REMINDERS],
    shouldAskBeforeDisabling: true,
    link: lang.sendHelpfulReminders.link,
    icon: Chat,
    iconBackground: colors.colorGreen,
    trackingId: 'send-helpful-reminders',
    category: AutomatedMessagingCategory.TRANSACTIONAL,
  },
  {
    name: SETTING.ASK_FOR_REVIEWS,
    text: settingText[SETTING.ASK_FOR_REVIEWS],
    shouldAskBeforeDisabling: true,
    link: lang.askForReview.link,
    icon: Star,
    iconBackground: colors.colorYellow,
    trackingId: 'ask-for-review',
    category: AutomatedMessagingCategory.TRANSACTIONAL,
  },
  {
    name: SETTING.INVITES_TO_REBOOK,
    text: settingText[SETTING.INVITES_TO_REBOOK],
    shouldAskBeforeDisabling: true,
    link: lang.inviteToRebook.link,
    icon: Reload,
    iconBackground: colors.colorTeal,
    trackingId: 'invites-to-rebook',
    category: AutomatedMessagingCategory.MARKETING,
  },
  {
    name: SETTING.SEND_BIRTHDAY_CONGRATULATIONS,
    text: settingText[SETTING.SEND_BIRTHDAY_CONGRATULATIONS],
    shouldAskBeforeDisabling: true,
    link: lang.birthdayCongratulations.link,
    icon: Gift,
    iconBackground: colors.colorGold,
    trackingId: 'birthday-congratulations',
    category: AutomatedMessagingCategory.MARKETING,
    trunkFeatureName: 'automated-messaging-birthday-campaign',
  },
];
