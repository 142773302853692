import React from 'react';
import classnames from 'classnames';
import App from 'common/backbone-app';
import PriceInput from 'components/common/forms/price-input';
import style from './UnderlinedPriceInput.scss';

interface Props {
  controlledComponent?: boolean;
  name?: string;
  id?: string;
  disabled?: boolean;
  hasError?: boolean;
  prefixCurrency?: boolean;
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (event) => void;
}

export const UnderlinedPriceInput = ({ hasError, ...props }: Props) => {
  const classes = classnames(style.price, props.disabled ? style.disabled : style.editable, {
    [style.error]: hasError,
  });
  if (!App.isProd) {
    console.warn('UnderlinedPriceInput component is deprecated. Use CurrencyInput component');
  }
  return (
    <div className={classes}>
      <PriceInput {...props} />
    </div>
  );
};
