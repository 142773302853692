import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { newProductOrderReset } from '../actions';
import NewOrder from './index';

const mapStateToProps = ({ productOrders }) => ({
  newOrderId: productOrders.newOrderId,
  isSupplierSelected: productOrders.isSupplierSelected,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      newProductOrderReset,
    },
    dispatch,
  ),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ newOrderId: null; isSupplierSelected: bool... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(NewOrder);
