import React, { useEffect, useState } from 'react';
import Wahanda from 'common/wahanda';
import classnames from 'classnames';
import { useImmer } from 'use-immer';
import uuid from 'uuid';
import uniq from 'lodash/uniq';

import { useComponentDidMount } from 'utilities/hooks';
import { FINISHING_TIME_DEFAULT } from 'common/constants/timeChoices';
import { Notice } from 'components/common/Notice';
import { DurationTypes } from 'components/shared/DurationSelector';
import { Scissors } from 'components/common/Icon';
import { Input } from 'components/common/Input';
import { Button } from 'components/common/Button';

import { AddServicesModalAnalytics } from '../tracking';
import {
  ErrorType,
  ErrorPayload,
  Service,
  ServiceSku,
  ServicePayload,
  ServiceEditPayload,
} from '../store/types';
import { hasDuplicateSkuName, priceValidator } from '../utils/helpers';
import { errorMessages } from '../utils/errorMessages';

import { ServiceRowEmployees } from '../ServiceRowEmployees';
import { ServiceRowSku } from '../ServiceRowSku';
import { ServiceRowRemove } from '../ServiceRowRemove';
import style from './ServiceRow.scss';

interface Props extends Service {
  onClick?: () => void;
  name: string;
  menuGroupId: number;
  serviceId: number;
  processingTimeMins?: number;
  skus: ServiceSku[];
  // Redux
  actions: {
    setErrorType: (params: ErrorPayload) => void;
    removeService: (params: ServiceEditPayload) => void;
    updateSelectedService: (params: ServicePayload) => void;
  };
  employees: number[];
  hasActiveEmployees: boolean;
}

export const ServiceRow = ({
  actions,
  employees,
  hasActiveEmployees,
  name,
  menuGroupId,
  serviceId,
  processingTimeMins,
  skus,
}: Props) => {
  const componentDidMount = useComponentDidMount();
  const [serviceName, setServiceName] = useState(name);
  const [serviceEmployees, setServiceEmployees] = useState(employees);
  const [serviceSku, setServiceSku] = useImmer(
    skus.map((sku) => ({
      ...sku,
      finishingTimeMins: processingTimeMins && FINISHING_TIME_DEFAULT,
    })),
  );
  const [serviceNameError, setServiceNameError] = useState(false);
  const initialErrorStackArray = skus.map((_, index) => ({ index, types: [] })) as {
    index: number;
    types: ErrorType[];
  }[];
  const [errorStack, updateErrorStack] = useImmer(initialErrorStackArray);

  const isMultiSku = skus.length > 1;
  const showSkuAdd = serviceSku.length <= 50;
  const showStackErrors = !!errorStack.reduce((acc, curr) => acc + curr.types.length, 0);
  const showError = serviceNameError || showStackErrors;
  const containerClass = classnames(style.container, {
    [style.hasError]: showError,
  });
  const serviceClass = classnames(style.service, {
    [style.multiSku]: isMultiSku,
  });

  /*
   * Handle service remove and sku add/remove
   */

  const handleRemoveClick = () => {
    const data = { menuGroupId, serviceId };
    actions.removeService(data);
    AddServicesModalAnalytics.trackAddServicesRemoveItemClick(serviceId);
    actions.setErrorType({
      serviceId,
      errorType: ErrorType.NONE,
    });
  };

  const handleServiceSkuAdd = () => {
    const index = skus.length - 1;
    const lastSku = serviceSku[index];
    setServiceSku((draft) => {
      draft.push({
        ...lastSku,
        name: '',
        skuId: uuid.v4(),
        isTempId: true,
      });
    });
    updateErrorStack((draft) => {
      draft.push({ index: index + 1, types: [] });
    });
    AddServicesModalAnalytics.trackAddServicesSkuAddClick();
  };

  const handleServiceSkuRemove = (index) => {
    if (skus.length === 2 && !skus[0].name.length) {
      setServiceSku((draft) => {
        draft[0].name = serviceName;
      });
    }
    setServiceSku((draft) => {
      draft.splice(index, 1);
    });
    updateErrorStack((draft) => {
      draft.splice(index, 1);
    });

    if (serviceSku.length === 2) {
      updateErrorStack(() => initialErrorStackArray);
    }

    AddServicesModalAnalytics.trackAddServicesSkuRemoveClick();
  };

  /*
   * Handle field changes
   */

  const handleServiceNameChange = (value) => {
    if (!componentDidMount) {
      return;
    }
    const shouldShowError = !value.length;
    setServiceName(value);
    setServiceNameError(shouldShowError);
    if (componentDidMount) {
      AddServicesModalAnalytics.trackServiceNameUpdate();
    }
  };

  const handleApplicationTimeChange = (value, index) => {
    setServiceSku((draft) => {
      draft[index].durationTimeMins = value;
    });
  };

  const handleDurationTimeChange = (value, index) => {
    AddServicesModalAnalytics.trackDurationSelectChange(DurationTypes.DURATION);
    handleApplicationTimeChange(value, index);
  };

  const handleFinishingTimeChange = (value, index) => {
    setServiceSku((draft) => {
      draft[index].finishingTimeMins = value;
    });
  };

  const handlePriceChange = (value, index) => {
    const shouldShowError = !priceValidator(value);
    handleErrorStack(shouldShowError, ErrorType.CURRENCY, index);

    setServiceSku((draft) => {
      draft[index].fullPriceAmount = value;
    });
    if (componentDidMount) {
      AddServicesModalAnalytics.trackPriceInputUpdate();
    }
  };

  const handleSkuNameChange = (value, index, triggerTrack) => {
    const skuValue = value.trim('');
    const hasDuplicate = hasDuplicateSkuName(skus, index, value);
    handleErrorStack(!skuValue.length, ErrorType.SERVICE_SKU_NAME, index);
    handleErrorStack(hasDuplicate, ErrorType.SERVICE_SKU_NAME_DUPLICATE, index);

    setServiceSku((draft) => {
      draft[index].name = skuValue;
    });
    if (componentDidMount && triggerTrack) {
      AddServicesModalAnalytics.trackServiceSkuNameUpdate();
    }
  };

  const handleServiceEmployeeChange = (checked, employeeId) => {
    if (checked) {
      setServiceEmployees((emps) => [...emps, employeeId]);
      AddServicesModalAnalytics.trackAddServiceEmployeeAddClick();
    } else {
      setServiceEmployees(serviceEmployees.filter((item) => item !== employeeId));
      AddServicesModalAnalytics.trackAddServiceEmployeeRemoveClick();
    }
  };

  /*
   * Handle errors
   */

  const handleErrorStack = (show: boolean, type: ErrorType, index: number) => {
    if (show) {
      updateErrorStack((draft) => {
        const hasType = draft[index].types.includes(type);
        if (!hasType) {
          draft[index].types.push(type);
        }
      });
    } else {
      const theIndexOfTyped = errorStack[index].types.indexOf(type);
      if (theIndexOfTyped > -1) {
        updateErrorStack((draft) => {
          draft[index].types.splice(theIndexOfTyped, 1);
        });
      }
    }
  };

  useEffect(() => {
    if (!componentDidMount) {
      return;
    }
    const errorStackTypes = errorStack
      .reduce((acc, curr: any) => acc.concat(curr.types), [])
      .filter((item) => item !== ErrorType.EMPLOYEES);
    const shouldSetError = !!errorStackTypes.length || serviceNameError;
    const hasOnlyEmployeesError =
      !!errorStackTypes.length &&
      errorStackTypes.filter((type) => type === ErrorType.EMPLOYEES).length ===
        errorStackTypes.length;

    actions.setErrorType({
      serviceId,
      errorType: shouldSetError ? ErrorType.MINOR : ErrorType.NONE,
    });

    if (hasOnlyEmployeesError) {
      actions.setErrorType({ serviceId, errorType: ErrorType.EMPLOYEES });
    }
  }, [errorStack, serviceNameError]);

  useEffect(() => {
    if (!hasActiveEmployees) {
      actions.setErrorType({ serviceId, errorType: ErrorType.EMPLOYEES });
    }
  }, []);

  /*
   * Update reducer
   */
  useEffect(() => {
    const service = {
      name: serviceName,
      employees: serviceEmployees,
      serviceId,
      processingTimeMins,
      skus: serviceSku,
    };

    actions.updateSelectedService({
      menuGroupId,
      serviceId,
      service,
    });
  }, [serviceSku, serviceName, serviceEmployees]);

  useEffect(() => {
    setServiceEmployees(employees);

    if (componentDidMount) {
      handleErrorStack(!employees.length, ErrorType.EMPLOYEES, 0);
    }
  }, [employees]);

  /*
   * Checking if Attention component should be shown
   */
  const uniqErrorStack = uniq(
    errorStack
      .reduce((acc, curr: any) => acc.concat(errorMessages[curr.types]), [])
      .filter(Boolean),
  );
  const showErrorAttention =
    showStackErrors &&
    (uniqErrorStack.length > 1 || !!(uniqErrorStack.length === 1 && uniqErrorStack[0]?.length));
  const attentionMessage = <>{uniqErrorStack.map((msg) => <div key={msg}>{msg}</div>) || ''}</>;

  const showSkuRemove = serviceSku.length > 1;

  const RemoveIcon = () => (
    <ServiceRowRemove
      className={style.removeIcon}
      dataTest={`add-services-remove-${serviceId}`}
      onClick={handleRemoveClick}
    />
  );

  return (
    <div id={`${serviceId}`} className={containerClass}>
      {showErrorAttention && (
        <div className={style.errorsNotice}>
          <Notice type="alert" message={attentionMessage} />
        </div>
      )}
      <div className={style.wrapper}>
        <Scissors className={style.icon} />
        <div className={serviceClass}>
          <div className={style.name}>
            <div className={style.nameInput}>
              <Input
                dataTest="add-services-name-input"
                name={`service-name-${serviceId}`}
                value={serviceName}
                onChange={handleServiceNameChange}
                placeholder={Wahanda.lang.menu.offer.sku.captionPlaceholder}
                hasDebounce
                hasError={serviceNameError}
                errorMessage={Wahanda.lang.validate.defaults.required}
              />
            </div>
            {isMultiSku && <RemoveIcon />}
          </div>
          <div className={style.applications}>
            {serviceSku.map((sku, index) => {
              const showSkuNameError = !!errorStack[index].types.filter(
                (val) => val === ErrorType.SERVICE_SKU_NAME_DUPLICATE,
              ).length;
              const shouldAutoFocusName = !sku.name.length;

              return (
                <div key={`${serviceId}-${sku.skuId}`} className={style.row}>
                  <ServiceRowSku
                    index={index}
                    onSkuNameChange={(event, triggerTrack) =>
                      handleSkuNameChange(event, index, triggerTrack)
                    }
                    onApplicationTimeChange={(event) => handleApplicationTimeChange(event, index)}
                    onDurationTimeChange={(event) => handleDurationTimeChange(event, index)}
                    onFinishingTimeChange={(event) => handleFinishingTimeChange(event, index)}
                    onPriceChange={(event) => handlePriceChange(event, index)}
                    onSkuRemove={() => handleServiceSkuRemove(index)}
                    processingTime={processingTimeMins}
                    showName={isMultiSku}
                    showSkuRemove={showSkuRemove}
                    showSkuNameError={showSkuNameError}
                    shouldAutoFocusName={shouldAutoFocusName}
                    sku={sku}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {!isMultiSku && <RemoveIcon />}
      </div>
      <div className={style.actions}>
        <ServiceRowEmployees
          employees={serviceEmployees}
          onChange={handleServiceEmployeeChange}
          menuGroupId={menuGroupId}
          serviceId={serviceId}
        />
        {showSkuAdd && (
          <div className={style.cta}>
            <Button
              dataTest="add-services-add-sku-button"
              label={Wahanda.lang.menu.offer.sku.addPricingOptions}
              onClick={handleServiceSkuAdd}
              variant="secondary"
              size="small"
            />
          </div>
        )}
      </div>
    </div>
  );
};
