import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AutomatedMessaging as Component } from './AutomatedMessaging';
import {
  setAutomatedMessagingSettingRequest,
  automatedMessagingSettingsRequest,
  automatedMessagingReset,
} from './actions';

const mapStateToProps = ({ marketing: { automatedMessaging } }) => ({
  automatedMessagingState: automatedMessaging,
  isLoading: automatedMessaging.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setAutomatedMessagingSettingRequest,
      automatedMessagingSettingsRequest,
      automatedMessagingReset,
    },
    dispatch,
  ),
});

export const AutomatedMessaging = connect(mapStateToProps, mapDispatchToProps)(Component);
