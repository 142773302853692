import _ from 'common/underscore';
import uuid from 'uuid';

import {
  PRICING_TYPE_SIMPLE,
  PRICING_TYPE_BY_EMPLOYEE_CAT,
  PRICING_TYPE_CUSTOM,
  PRICING_TYPE_2D,
  DEFAULT_DURATION,
} from './constants';

// General note due to DEV-53123:
// When adding or removing SKUs, we need to make sure we move from or to single-SKU, we must
// remove the skuId of original SKUs, so that they are saved as new ones.

export function findEmployeeCategoryName(categories, id) {
  const category = (categories || []).find((cat) => cat.id === id);

  return category ? category.name : null;
}

export function getSkuName(sku) {
  return sku.nameInherited ? '' : sku.name;
}

function removeSkuId(sku) {
  return Object.assign({}, sku, {
    id: null,
    tempId: uuid.v4(),
  });
}

export function getPricingType(skus) {
  if (!Array.isArray(skus)) {
    return null;
  }
  if (skus.length === 0) {
    // The default.
    return PRICING_TYPE_SIMPLE;
  }

  const nameCheck = (sku) => !sku.nameInherited && typeof sku.name === 'string';
  const allSkusWithName = skus.every(nameCheck);
  const someSkusWithNames = skus.some(nameCheck);
  const allSkusWithEmployeeCategory = skus.every((sku) => sku.employeeCategoryId > 0);

  if (someSkusWithNames && allSkusWithEmployeeCategory) {
    return PRICING_TYPE_2D;
  }
  if (allSkusWithEmployeeCategory && !someSkusWithNames) {
    return PRICING_TYPE_BY_EMPLOYEE_CAT;
  }

  if (skus.length === 1 && !allSkusWithName) {
    return PRICING_TYPE_SIMPLE;
  }
  return PRICING_TYPE_CUSTOM;
}

export function getEmptySku() {
  return {
    id: null,
    name: '',
    employeeCategoryId: null,
    duration: DEFAULT_DURATION,
    finishingTimeMins: null,
    nameInherited: false,
    fullPriceAmount: 0,
    discountPriceAmount: null,
    visible: true,
  };
}

export function getEmployeeCategoriesFromEmployees(employees, employeeCatsToOrderBy) {
  if (!employees || employees.length === 0) {
    return [];
  }

  return employeeCatsToOrderBy
    .filter((cat) => employees.find((emp) => emp.employeeCategoryId === cat.id))
    .map((cat) => cat.id);
}

export function getEmptySkusForCategories(categoryIds) {
  const baseSku = getEmptySku();

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
  baseSku.name = null;

  return (categoryIds || []).map((employeeCategoryId) =>
    Object.assign({}, baseSku, { employeeCategoryId }),
  );
}

function get2dSkuData(skus) {
  const uniqueNames = [];
  const list = [];

  skus.forEach((sku) => {
    const key = sku.groupingId || sku.name;

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    if (typeof key === 'string' && !uniqueNames.includes(key)) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
      list.push({
        name: sku.name,
        groupingId: sku.groupingId,
      });
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      uniqueNames.push(key);
    }
  });

  return list.length > 0 ? list : null;
}

export function updateSkusForCategories(oldSkus, categoryIds, newSkuCallback) {
  let newSkus = [];
  const skuData2d = get2dSkuData(oldSkus);

  categoryIds.forEach((catId) => {
    /*
     * Add existing categories
     */
    const existing = oldSkus.filter((sku) => sku.employeeCategoryId === catId);

    if (existing.length === 0) {
      const newSku = getEmptySku();

      newSku.employeeCategoryId = catId;
      newSkuCallback(newSku);

      if (skuData2d) {
        skuData2d.forEach((named2dData) => {
          newSkus.push(Object.assign({}, newSku, named2dData));
        });
      } else {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ id: null; name: string; employ... Remove this comment to see the full error message
        newSkus.push(newSku);
      }
    } else {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      newSkus = newSkus.concat(existing.filter((exSku) => newSkus.indexOf(exSku) === -1));
    }
  });

  return newSkus.length === 1 || oldSkus.length === 1 ? newSkus.map(removeSkuId) : newSkus;
}

export function isSkuCombinationSupported(skus) {
  let nameSkus = false;
  let employeeCatSkus = false;
  let twoDimensionSkus = false;

  (skus || []).forEach((sku) => {
    const hasName = nameSkus || (sku.name && !sku.nameInherited);
    const hasEmployeeCat = employeeCatSkus || sku.employeeCategoryId > 0;

    nameSkus = nameSkus || (hasName && !hasEmployeeCat);
    employeeCatSkus = employeeCatSkus || (hasEmployeeCat && !hasName);
    twoDimensionSkus = twoDimensionSkus || (hasName && hasEmployeeCat);
  });

  return (
    ((nameSkus && 1) || 0) + ((employeeCatSkus && 1) || 0) + ((twoDimensionSkus && 1) || 0) < 2
  );
}

export function doesUseExistingCategory(skus, employees, employeeCatsToOrderBy) {
  const categories = getEmployeeCategoriesFromEmployees(employees, employeeCatsToOrderBy);

  return !skus.some((sku) => {
    const { employeeCategoryId } = sku;

    return employeeCategoryId && !categories.find((cat) => cat === employeeCategoryId);
  });
}

export function reduceSkusToCustomType(skus) {
  return skus.map((sku) =>
    Object.assign({}, sku, {
      name: sku.name || '',
      employeeCategoryId: null,
      id: null,
      tempId: uuid.v4(),
    }),
  );
}

export function splitTwoDimensionalSkus(skus) {
  const groupedSkus = _.groupBy(skus, (sku) => sku.groupingId || sku.name);

  return Object.values(groupedSkus);
}

function removeNameAndIdsFromSkus(skus) {
  return skus.map((sku) => Object.assign({}, sku, { name: null, id: null, tempId: uuid.v4() }));
}

export function remove2dSkusFromList(skuList, skusToRemove) {
  const cleanedUpSkus = skuList.filter(
    (sku) =>
      !skusToRemove.some(
        (skuToRemove) =>
          (sku.id && sku.id === skuToRemove.id) ||
          (sku.groupingId && sku.groupingId === skuToRemove.groupingId),
      ),
  );
  const newSkus = cleanedUpSkus.length === 1 ? cleanedUpSkus.map(removeSkuId) : cleanedUpSkus;

  return splitTwoDimensionalSkus(newSkus).length === 1
    ? removeNameAndIdsFromSkus(newSkus)
    : newSkus;
}

export function removeSkuFromList(skus, skuToRemove) {
  const leftSkus = _.without(skus, skuToRemove);

  return leftSkus.length === 1 ? removeNameAndIdsFromSkus(leftSkus) : leftSkus;
}

// Create CUSTOM or EMPLOYEE_CAT pricing skus
export function createSkusOfType(
  useEmployeeCategoryPricing,
  { selectedEmployees, employeeCategoriesCollection },
) {
  let skus;

  const groupingId = uuid.v4();

  if (useEmployeeCategoryPricing) {
    skus = getEmptySkusForCategories(
      getEmployeeCategoriesFromEmployees(selectedEmployees, employeeCategoriesCollection),
    ).map((sku) => {
      sku.tempId = uuid.v4(); // eslint-disable-line no-param-reassign
      sku.groupingId = groupingId; // eslint-disable-line no-param-reassign
      return sku;
    });
  }

  if (!skus || skus.length === 0) {
    const sku = getEmptySku();

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'tempId' does not exist on type '{ id: nu... Remove this comment to see the full error message
    sku.tempId = uuid.v4();
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
    sku.name = useEmployeeCategoryPricing ? sku.name : null;
    skus = [sku];
  }
  return skus;
}

function setEmptyNamesIfEmployeeCatPricing(skus) {
  if (getPricingType(skus) === PRICING_TYPE_BY_EMPLOYEE_CAT) {
    return skus.map((sku) => Object.assign({}, sku, { name: '', nameInherited: false }));
  }
  return skus;
}

export function add2dSkus(skus, { selectedEmployees, employeeCategoriesCollection }) {
  const prevSkus = skus.length === 1 ? skus.map(removeSkuId) : skus;
  const { groupingId: prevGroupingId } = prevSkus.slice(-1).pop();
  const prevSkusGroup = prevSkus.filter(({ groupingId }) => groupingId === prevGroupingId);

  const singleUuid = uuid.v4();
  const newSkus = createSkusOfType(true, {
    selectedEmployees,
    employeeCategoriesCollection,
  }).map((sku, i) => {
    return {
      ...sku,
      ...getReusableSkuValues(prevSkusGroup[i]),
      name: '',
      groupingId: singleUuid,
    };
  });

  return [].concat(setEmptyNamesIfEmployeeCatPricing(prevSkus), newSkus);
}

export function usesEmployeeCategories(skus) {
  return !skus.some((sku) => !sku.employeeCategoryId);
}

function getNewSku() {
  const newSku = getEmptySku();

  // Add a unique id so that React can use that for the `key` prop.
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tempId' does not exist on type '{ id: nu... Remove this comment to see the full error message
  newSku.tempId = uuid.v4();

  return newSku;
}

export function addCustomSku(skuList) {
  const targetSkuList = (skuList.length === 1 ? skuList.map(removeSkuId) : skuList).map((sku) =>
    sku.name === null ? { ...sku, name: '' } : sku,
  );
  const prevSku = targetSkuList.slice(-1).pop();
  const newEmptySku = getNewSku();
  const newSku = {
    ...newEmptySku,
    ...getReusableSkuValues(prevSku),
    name: '',
  };
  return [...targetSkuList, newSku];
}

export function getReusableSkuValues(sku) {
  const { name, duration, finishingTimeMins, fullPriceAmount, discountPriceAmount } = sku;

  return {
    name,
    duration,
    finishingTimeMins,
    fullPriceAmount,
    discountPriceAmount,
  };
}

export function add2dGrouping(skuList) {
  const nameGroupMap = {};

  return skuList.map((sku) => {
    const groupingId = nameGroupMap[sku.name] || uuid.v4();

    nameGroupMap[sku.name] = groupingId;

    return Object.assign({}, sku, { groupingId });
  });
}

export function isPackageSkus2d(skuList) {
  if (!skuList || skuList.length === 0) {
    return false;
  }

  return _.all(skuList, (sku) => {
    let catId = null;
    const isSubSkus2d =
      getPricingType(sku.subSkus.filter((subSku) => subSku.employeeCategoryId > 0)) ===
      PRICING_TYPE_2D;

    if (!isSubSkus2d) {
      return false;
    }

    const subSkuCheckPassed = _.all(sku.subSkus, (subSku) => {
      const currentCatId = subSku.employeeCategoryId;

      if (currentCatId > 0) {
        if (!catId) {
          catId = currentCatId;
        }
        return catId === currentCatId;
      }
      return true;
    });

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    return catId > 0 && subSkuCheckPassed;
  });
}

/**
 * Add missing employee category SKUs into the SKU list, if there are any, based on the
 * employee's categories.
 *
 * *Note*: this method must be run only on SKUs which have staff pricing on them.
 *
 * @param {Array} oldSkus Skus to have the categories added
 * @param {Array} employees Employees assigned to the offer
 * @param {Array} employeeCats All employee categories
 * @returns {Array} The maybe updated SKUs
 */
export function addMissingCategories(oldSkus, employees, employeeCats) {
  const categories = getEmployeeCategoriesFromEmployees(employees, employeeCats);

  const missingCats = categories.filter(
    (catId) => !oldSkus.some(({ employeeCategoryId }) => catId === employeeCategoryId),
  );

  if (!missingCats.length) {
    return oldSkus;
  }

  const pricingType = getPricingType(oldSkus);
  const data2d = pricingType === PRICING_TYPE_2D ? get2dSkuData(oldSkus) : null;

  const updatedSkus = [...oldSkus];
  const categorySkus = getEmptySkusForCategories(missingCats);

  const appendCategorySkus = (skuOverrides, addTempId = false) => {
    const initObject = {};
    if (addTempId === true) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'tempId' does not exist on type '{}'.
      initObject.tempId = uuid.v4();
    }

    categorySkus.forEach((catSku) =>
      updatedSkus.push(Object.assign(initObject, catSku, skuOverrides || {})),
    );
  };

  if (data2d) {
    // @ts-expect-error ts-migrate(2345) FIXME: Type 'number' is not assignable to type 'boolean |... Remove this comment to see the full error message
    data2d.forEach(appendCategorySkus);
  } else {
    const overrides = {};
    if (updatedSkus[0] && updatedSkus[0].groupingId) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'groupingId' does not exist on type '{}'.
      overrides.groupingId = updatedSkus[0].groupingId;
    }

    appendCategorySkus(overrides, true);
  }

  if (oldSkus.length === 1) {
    return updatedSkus.map(removeSkuId);
  }
  return updatedSkus;
}

export function getEmployees(activeEmployees, allEmployees) {
  const employeeIds = activeEmployees.map((employee) => employee.employeeId);

  return allEmployees.toJSON().filter((employee) => employeeIds.includes(employee.id));
}

export function getInvalidCategories(skus, employees, employeeCategories) {
  const invalidSkus = skus.filter(
    (sku) => !doesUseExistingCategory([sku], employees, employeeCategories),
  );
  const invalidEmployeeCategoryIds = invalidSkus.map((sku) => sku.employeeCategoryId);
  const invalidCategories = employeeCategories.filter((category) =>
    invalidEmployeeCategoryIds.includes(category.id),
  );

  if (invalidCategories.length === 0) {
    return null;
  }

  return invalidCategories;
}
