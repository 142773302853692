import React from 'react';
import Wahanda from 'common/wahanda';
import Button from 'components/common/SimpleButton';
import keycode from 'keycode';
import classnames from 'classnames';

const lang = Wahanda.lang.calendar.multiBlockout;
interface ISidebarActionModeProps extends React.HTMLAttributes<Element> {
  close?: (...args: any[]) => any;
  onSave?: (...args: any[]) => any;
  toggleSpinner?: (...args: any[]) => any;
  beforeSidebarComponent?: {};
  title?: string;
  classes?: any;
  actionButtonText?: string;
}
type SidebarActionModeState = {
  saving?: any;
};

export default class SidebarActionMode extends React.Component<
  ISidebarActionModeProps,
  SidebarActionModeState
> {
  onSave: any;

  state = {};

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyUp = (event) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'saving' does not exist on type '{}'.
    if (this.state.saving) {
      return;
    }
    const key = keycode(event);
    if (key === 'enter') {
      this.onSave();
    } else if (key === 'esc') {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.close();
    }
  };

  markSaving = () => {
    this.setState({
      saving: true,
    });
    if (this.props.toggleSpinner) {
      this.props.toggleSpinner(true);
    }
  };

  unmarkSaving = () => {
    this.setState({
      saving: false,
    });
    if (this.props.toggleSpinner) {
      this.props.toggleSpinner(false);
    }
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'saving' does not exist on type '{}'.
    const { saving } = this.state;
    const classes = classnames('sidebar-action-mode', this.props.classes);
    return (
      <div className={classes}>
        {this.props.beforeSidebarComponent}
        <div className="sidebar-overlay">
          <div className="title">{this.props.title}</div>
          {this.props.children}
          <div className="buttons">
            <Button
              title={this.props.actionButtonText || lang.buttons.save}
              // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type '((...args: ... Remove this comment to see the full error message
              onClick={saving ? null : this.props.onSave}
              classes={{ 'button-primary js-save': true, disabled: saving }}
            />
            <Button
              title={Wahanda.lang.shared.cancel}
              // @ts-expect-error ts-migrate(2769) FIXME: Type 'null' is not assignable to type '((...args: ... Remove this comment to see the full error message
              onClick={saving ? null : this.props.close}
              classes={{ 'button-basic js-cancel': true, disabled: saving }}
            />
          </div>
        </div>
        <div className="top-overlay" />
      </div>
    );
  }
}
