import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'reduxStore/common/employees/actions';
import { EmployeeInformationTab as Component } from './index';

const mapStateToProps = ({
  employees: {
    employee: { id, name, takesAppointments, phone, emailAddress, role, image, employmentStatus },
    submitting,
    serverErrorName,
    serverErrorData,
    serverErrorAction,
  },
}) => ({
  employmentStatus,
  id,
  name,
  takesAppointments,
  phone,
  emailAddress,
  emailDisabled: App.config.getAccountEmployeeId() === id,
  role,
  imageUris: image ? image.uris : null,
  submitting,
  serverErrorName,
  serverErrorData,
  serverErrorAction,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ employmentStatus: undefined; id: null; nam... Remove this comment to see the full error message
export const EmployeeInformationTab = connect(mapStateToProps, mapDispatchToProps)(Component);
