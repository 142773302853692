/* global _ */

/**
 * Wahanda permission checking library.
 *
 * It is implemented with buginess logic methods, e.g. editOwnCalendar(), viewMenu() instead of checking for specific
 * strings. This allows extending the checks in the future.
 */

import channelCodes from 'config/channelCodes';

(function iffy() {
  const Perm = {
    /**
     * List of permissions, plotted into an Object from the array, for easyer access.
     */
    list: {},

    permissionCount: 0,

    /* eslint-disable  no-plusplus,no-param-reassign */
    /**
     * Set the permission array for the current account.
     *
     * @param Array list
     */
    setPermissionList(list) {
      if (!_.isArray(list)) {
        list = [];
      }

      Perm.list = {};
      for (let i = 0; i < list.length; i++) {
        Perm.list[list[i]] = true;
      }
      Perm.permissionCount = list.length;
    },
    /* eslint-enable no-plusplus,no-param-reassign */

    hasAnyPermission() {
      return Perm.permissionCount > 0;
    },

    /**
     * Can the current user view bookings?
     * Affects bookings and leads APIs.
     */
    viewBookings() {
      return 'view-bookings' in Perm.list;
    },

    viewReports() {
      return 'view-reports-sales' in Perm.list;
    },

    viewReportsSales() {
      return Perm.viewReports();
    },

    manageVenueDetails() {
      return 'manage-venue-details' in Perm.list;
    },

    addTherapists() {
      return 'add-therapists' in Perm.list;
    },

    viewFinanceData() {
      return 'view-finance-data' in Perm.list;
    },

    viewPosReports() {
      return (
        App.config.get('venue').pointOfSaleEnabled && (Perm.viewReports() || App.isTaxAuditor())
      );
    },

    accessSettings() {
      return (
        !App.isTaxAuditor() &&
        (Perm.manageVenueDetails() || Perm.addTherapists() || Perm.viewFinanceData())
      );
    },

    accessTeam() {
      return (
        !App.isTaxAuditor() &&
        // disabling a false positive below
        // eslint-disable-next-line react-hooks/rules-of-hooks
        (Perm.useCalendar() || Perm.addTherapists() || Perm.editUserPermissions())
      );
    },

    viewMenu() {
      return 'view-menu' in Perm.list;
    },

    lockedDownEditMenu() {
      return 'edit-menu' in Perm.list;
    },

    editMenu() {
      return 'edit-menu' in Perm.list && !App.config.isLockdownAndNotMasquerading();
    },

    canEditImages() {
      if (Wahanda.Features.isEnabled('CD-998-allow-edit-venue-details-and-photos')) {
        return App.isAdministrator() || App.isMasquerading();
      }

      return App.config.get('venue').imageEditingEnabled || App.isMasquerading();
    },

    canDownloadAllImages() {
      return App.isAdministrator() || App.isMasquerading();
    },

    canEditVenueDetails() {
      if (Wahanda.Features.isEnabled('CD-998-allow-edit-venue-details-and-photos')) {
        return App.isAdministrator() || App.isMasquerading();
      }

      return App.config.get('venue').descriptionEditingEnabled || App.isMasquerading();
    },

    viewClientList() {
      return 'view-client-list' in Perm.list;
    },

    managePos() {
      return 'manage-pos' in Perm.list;
    },

    viewClientContactDetails() {
      return 'view-client-contact-details' in Perm.list;
    },

    canExportClientList() {
      return 'export-client-list' in Perm.list;
    },

    editClientContactDetails() {
      return Perm.viewClientContactDetails();
    },

    editOwnCalendar() {
      return 'edit-own-calendar' in Perm.list;
    },

    viewAnyCalendar() {
      return 'view-any-calendar' in Perm.list;
    },

    editRotaCalendar(employeeId) {
      return (
        Perm.editAnyCalendar() ||
        (Perm.editOwnCalendar() && App.config.getAccountEmployeeId() === employeeId)
      );
    },

    editAnyCalendar() {
      return 'edit-any-calendar' in Perm.list;
    },

    useCalendar() {
      return Perm.editAnyCalendar() || Perm.viewAnyCalendar() || Perm.editOwnCalendar();
    },

    hasSingleVATVoucherSupport() {
      return App.config.get('venue').contentChannelCode !== channelCodes.IE;
    },

    editUserPermissions() {
      return 'edit-user-permissions' in Perm.list;
    },

    editPOSServicePrice() {
      return 'can-edit-service-price' in Perm.list;
    },

    canDeleteAppointments() {
      return 'delete-appointments' in Perm.list;
    },

    canCancelTransactions() {
      return 'cancel-transaction' in Perm.list;
    },

    canAccessMarketingTools() {
      return 'access-marketing-tools' in Perm.list;
    },

    canAccessPartnerSites() {
      return Perm.manageVenueDetails() && App.config.get('venue').permitMicrosites;
    },

    anyEmailCampaignEnabled() {
      return (
        App.isFeatureSupported('marketing-campaign') ||
        App.isFeatureSupported('promo-offers-cmpgn') ||
        App.isFeatureSupported('app-for-clnts-cmpgn')
      );
    },

    canEditReceiptFreeTextTop() {
      return App.config.get('venue').countryCode !== 'IT';
    },

    canViewClientReports() {
      return 'view-client-list' in Perm.list || App.isMasquerading();
    },

    canExportTransactionList() {
      return (
        App.isTaxAuditor() ||
        Wahanda.Permissions.viewFinanceData() ||
        Wahanda.Permissions.managePos()
      );
    },

    canUseIdealPayment() {
      return App.config.get('venue').countryCode === 'NL';
    },

    canSearchESignature() {
      return App.config.get('venue').countryCode === 'FR';
    },

    canExportNF525() {
      return App.config.get('venue').countryCode === 'FR';
    },

    canExportDSFINVK() {
      return App.config.get('venue').countryCode === 'DE';
    },

    canExportGDPDU() {
      return App.config.get('venue').countryCode === 'DE';
    },

    canViewTransactionReport() {
      if (App.isMasquerading()) {
        return true;
      }

      return (
        (Perm.managePos() || Perm.viewFinanceData()) && App.config.get('venue').pointOfSaleEnabled
      ); // not sure if pos permission is required for transaction showing.
    },
  };
  Wahanda.Permissions = Perm;
})();
