import { ACTIONS, trackEvent } from 'common/analytics';

const CATEGORY = 'transactions';

export const TransactionsReportTracking = {
  openWithdrawalDialog: () => trackEvent(CATEGORY, ACTIONS.CLICK, 'open-withdrawal'),
  openDepositDialog: () => trackEvent(CATEGORY, ACTIONS.CLICK, 'open-deposit'),
  openCloseDayDialog: () => trackEvent(CATEGORY, ACTIONS.CLICK, 'open-close-day'),
  trackTableView: (isAdvanced) => {
    trackEvent(CATEGORY, ACTIONS.VIEW, 'report-table', isAdvanced ? 'advanced-view' : 'basic-view');
  },
  trackBasicViewClick: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'report-table', 'basic-view');
  },
  trackAdvancedViewClick: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'report-table', 'advanced-view');
  },
  trackTransactionDetailsClick: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'transaction-details');
  },
  trackExportDataClick: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'export-data');
  },
  trackPreviousDayClick: () => trackEvent(CATEGORY, ACTIONS.CLICK, 'previous-day'),
  trackNextDayClick: () => trackEvent(CATEGORY, ACTIONS.CLICK, 'next-day'),
  trackDateClick: () => trackEvent(CATEGORY, ACTIONS.CLICK, 'date'),
};
