import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Copy extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-copy', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M16,1 L4,1 C2.895,1 2,1.895 2,3 L2,17 L4,17 L4,3 L16,3 L16,1 L16,1 Z M19,5 L8,5 C6.895,5 6,5.896 6,7 L6,21 C6,22.104 6.895,23 8,23 L19,23 C20.104,23 21,22.104 21,21 L21,7 C21,5.896 20.104,5 19,5 L19,5 Z M8,7 L19,7 L19,21 L8,21 L8,7 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Copy);
