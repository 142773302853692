import React, { KeyboardEvent } from 'react';
import classnames from 'classnames';
import keycode from 'keycode';
import style from './Switch.scss';

const SPACE = keycode('space');

export interface SwitchProps {
  className?: string;
  dataTest?: string;
  labelClassName?: string;
  rhs?: boolean;
  onChange?: (param: boolean) => void;
  tabIndex?: number;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  controlled?: boolean;
}

export const Switch = ({
  className,
  dataTest,
  labelClassName,
  rhs,
  onChange,
  tabIndex = 0,
  checked = false,
  disabled = false,
  label,
  controlled = false,
}: SwitchProps) => {
  const [switchChecked, setSwitchChecked] = React.useState(checked);
  const observer = React.useRef(false);
  const labelElement = label && (
    <span data-test="switch-text" className={classnames(style.text, labelClassName)}>
      {label}
    </span>
  );
  const classNames = classnames(style.label, className, {
    [style.rhs]: rhs,
    [style.checked]: switchChecked,
    [style.disabled]: disabled,
  });

  const handleClick = () => {
    setSwitchChecked(!switchChecked);
    if (controlled) onChange?.(!switchChecked);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLLabelElement>) => {
    if (event.charCode === SPACE) {
      event.preventDefault();
      setSwitchChecked(!switchChecked);
      if (controlled) onChange?.(!switchChecked);
    }
  };

  React.useEffect(() => {
    if (controlled) return;
    if (observer.current) {
      if (onChange) {
        onChange(switchChecked);
      }
    } else {
      observer.current = true;
    }
  }, [switchChecked, onChange, controlled]);

  React.useEffect(() => {
    if (controlled) {
      setSwitchChecked(checked);
    }
  }, [controlled, checked]);

  return (
    <label
      data-test={dataTest}
      tabIndex={tabIndex}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      className={classNames}
      aria-checked={switchChecked}
    >
      {!rhs && labelElement}
      <div className={style.toggle} />
      {rhs && labelElement}
    </label>
  );
};

Switch.displayName = 'Switch';
