import React from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';

import { PopoverAnchorOffset, PopoverPlacement } from 'components/common/Popover';

import { actionGoToRota } from '../utils/actionItems';
import { editVenueShiftOptionsList } from '../utils/constants';
import { businessDayTimeSlots, venueStandardTimeSlots } from '../utils/helpers';
import {
  Employee,
  SetVenueWorkingHoursPayload,
  ShiftWorkingHourType,
  RequestEmployeesDayPayload,
  VenueShiftsObject,
  WorkingHoursObject,
  ShiftModalArgs,
  RotaModalType,
} from '../utils/types';
import { ShiftsAnalytics } from '../tracking';

import { ShiftActionsModal } from '../ShiftActionsModal';
import { ShiftDayModal } from '../ShiftDayModal';
import { ShiftTimeOffModal } from '../ShiftTimeOffModal';
import { ShiftRemoveCustomModal } from '../ShiftRemoveCustomModal';
import { ShiftEmployeeModal } from '../ShiftEmployeeModal';

import style from './ShiftVenueModal.scss';

const LANG = Wahanda.lang.settings.shifts;

interface Props {
  employeeId?: number | null;
  data: ShiftModalArgs;
  onClose: () => void;
  modalType: RotaModalType;
  popoverOffset?: PopoverAnchorOffset;
  popoverPlacement?: PopoverPlacement;
  trackingCategory?: string;
  // redux
  actions: {
    setVenueWorkingHours: (param: SetVenueWorkingHoursPayload) => void;
    resetShiftModal: () => void;
    getVenueBusinessHours: () => void;
    getEmployeesDay: (param: RequestEmployeesDayPayload) => void;
  };
  canAddTimeOff: boolean;
  canRemoveDayOff: boolean;
  canEditEmployeeHours: boolean;
  canEditVenueHours: boolean;
  venue: VenueShiftsObject;
  employee: Employee;
  employeeWorkingHours: WorkingHoursObject;
  venueName: string;
  venueWorkingHours: WorkingHoursObject;
  isBusinessHoursLoaded: boolean;
  isSingleVenueEmployee: boolean;
  isShownOnCalendar: boolean;
  isRotaEnabled: boolean;
}

interface State {
  modalType: RotaModalType;
}

export class ShiftVenueModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      modalType: this.props.isRotaEnabled ? this.props.modalType : RotaModalType.VENUE_DAY_EDIT,
    };
  }

  public static defaultProps = {
    employeeId: null,
    modalType: RotaModalType.VENUE_MODAL_ACTIONS,
  };

  public componentDidMount() {
    if (!this.props.isBusinessHoursLoaded) {
      this.props.actions.getVenueBusinessHours();
    }

    if (this.props.modalType === RotaModalType.VENUE_MODAL_ACTIONS) {
      ShiftsAnalytics.trackVenueShiftActionsModalView();
    }
  }

  public componentDidUpdate(prevProps) {
    const { date } = this.props.data;

    if (prevProps.data.date !== date) {
      this.props.actions.getVenueBusinessHours();
    }
  }

  private onClose = () => {
    this.setState({ modalType: RotaModalType.HIDE });
    this.props.onClose();
    if (this.props.isShownOnCalendar) {
      this.props.actions.resetShiftModal();
    }
  };

  private renderModal = () => {
    const { modalType } = this.state;

    switch (modalType) {
      case RotaModalType.HIDE:
        return null;
      case RotaModalType.VENUE_DAY_EDIT:
        return this.renderVenueEditModal();
      case RotaModalType.EMPLOYEE_DAY_EDIT:
        return this.renderEmployeeModal();
      case RotaModalType.EMPLOYEE_TIME_OFF_EDIT:
        return this.renderTimeOffOrRemoveModal();
      case RotaModalType.EMPLOYEE_REMOVE_CUSTOM_EDIT:
        return this.renderTimeOffOrRemoveModal(true);
      case RotaModalType.VENUE_MODAL_ACTIONS:
      default:
        return this.renderActionModal();
    }
  };

  private setModalType = (modalType: RotaModalType) => {
    this.setState({ modalType });
  };

  private renderActionModal = () => {
    const {
      canAddTimeOff,
      canRemoveDayOff,
      canEditEmployeeHours,
      canEditVenueHours,
      employee,
      employeeId,
      isBusinessHoursLoaded,
      isSingleVenueEmployee,
      venueWorkingHours,
      data,
    } = this.props;
    const { employeeName } = employee;

    if (!venueWorkingHours || !isBusinessHoursLoaded) {
      return null;
    }

    const isSalonClosed = venueWorkingHours.timeSlots.length === 0;

    const actionList = [
      {
        isHidden: !canEditVenueHours,
        label: this.props.venueName,
        onClick: () => this.setModalType(RotaModalType.VENUE_DAY_EDIT),
      },
      {
        isHidden: isSalonClosed || !canEditEmployeeHours || isSingleVenueEmployee,
        label: employeeName,
        onClick: () => this.setModalType(RotaModalType.EMPLOYEE_DAY_EDIT),
      },
      {
        isHidden: isSingleVenueEmployee || !canAddTimeOff,
        label: LANG.modalEmployeeActions.addTimeOff,
        onClick: () => this.setModalType(RotaModalType.EMPLOYEE_TIME_OFF_EDIT),
      },
      {
        isHidden: isSingleVenueEmployee || !canRemoveDayOff,
        label: LANG.modalEmployeeActions.removeDayOff,
        onClick: () => {
          if (employeeId) {
            this.setModalType(RotaModalType.EMPLOYEE_REMOVE_CUSTOM_EDIT);
          }
        },
      },
      actionGoToRota(false, true),
    ];

    if (this.props.popoverOffset) {
      return (
        <ShiftActionsModal
          actionList={actionList}
          data={data}
          onClose={this.onClose}
          offset={this.props.popoverOffset}
          placement={this.props.popoverPlacement}
        />
      );
    }
    return null;
  };

  private onVenueShiftEditSubmit = ({ type, timeSlots }) => {
    const { date } = this.props.data;
    const data = {
      date,
      type,
      timeSlots,
    };
    this.props.actions.setVenueWorkingHours(data);
    if (!this.props.isShownOnCalendar) {
      this.props.actions.getEmployeesDay({ date });
    }
    ShiftsAnalytics.trackVenueShiftDayModalSubmit({
      category: this.props.trackingCategory,
      property: type,
    });
  };

  private onVenueShiftEditClose = (event) => {
    if (event) {
      ShiftsAnalytics.trackVenueShiftDayModalClose({
        category: this.props.trackingCategory,
      });
    }
    this.onClose();
  };

  private renderVenueEditModal = () => {
    const { isBusinessHoursLoaded, venue, venueWorkingHours } = this.props;
    const { date } = this.props.data;
    if (!venueWorkingHours || !isBusinessHoursLoaded) {
      return null;
    }

    const { type, timeSlots } = venueWorkingHours;
    const timeSlotsArray = venueStandardTimeSlots({
      businessHours: venue.businessHours,
      workingHours: venueWorkingHours,
      date,
    });
    const businessHoursTimeSlots = businessDayTimeSlots({
      businessHours: venue.businessHours,
      date,
    });

    const header = (
      <div
        className={style.header}
        dangerouslySetInnerHTML={{
          __html: Wahanda.Template.render(LANG.modalEditVenueShift.header, {
            url: Wahanda.Url.getFullUrl('settings', 'venue-settings/opening-hours'),
          }),
        }}
      />
    );
    const isCustomDayOff = !timeSlots.length && type === ShiftWorkingHourType.CUSTOM;
    const workingHoursType = isCustomDayOff ? ShiftWorkingHourType.DAY_OFF : type;

    const onComponentMount = () =>
      ShiftsAnalytics.trackVenueShiftDayModalView({
        category: this.props.trackingCategory,
      });

    return (
      <ShiftDayModal
        date={date}
        header={header}
        onComponentMount={onComponentMount}
        onClose={this.onVenueShiftEditClose}
        onSubmit={this.onVenueShiftEditSubmit}
        optionsList={editVenueShiftOptionsList}
        title={LANG.modalEditVenueShift.title}
        timeSlots={timeSlotsArray}
        workingHoursType={workingHoursType}
        defaultTimeSlots={businessHoursTimeSlots}
      />
    );
  };

  private onEmployeeEditClose = (event) => {
    if (event) {
      ShiftsAnalytics.trackVenueShiftDayModalClose({
        category: this.props.trackingCategory,
      });
    }
    this.onClose();
  };

  private renderEmployeeModal = () => {
    const {
      data: { date },
      employeeId,
    } = this.props;

    return (
      <ShiftEmployeeModal
        employeeId={employeeId}
        date={date}
        modalType={RotaModalType.EMPLOYEE_DAY_EDIT}
        onClose={this.onEmployeeEditClose}
        trackingCategory={this.props.trackingCategory}
      />
    );
  };

  private renderTimeOffOrRemoveModal = (renderRemove = false) => {
    const Modal = renderRemove ? ShiftRemoveCustomModal : ShiftTimeOffModal;
    return (
      <Modal
        employeeId={this.props.employeeId}
        dateFrom={moment(this.props.data.date)}
        onClose={this.onClose}
        trackingCategory={this.props.trackingCategory}
      />
    );
  };

  public render() {
    return this.renderModal();
  }
}
