import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import PATHS from 'components/marketing/Router/paths';
import { Expandable } from 'components/common/Expandable';
import Select from 'components/common/forms/select';
import { List } from 'components/common/List';
import { ComputerBookNow, ComputerWidget, ComputerMedia } from 'components/common/IconPicture';
import { CopyButton } from './CopyButton';
import BaseSection from './';
import WidgetPreview from './WidgetPreview';
import style from './BaseSection.scss';

const SECTION = { BUTTON: 'button', LINK: 'link', EMBED: 'embed' };
interface IWidgetSectionProps extends React.HTMLAttributes<Element> {
  section: any;
  code?: string;
  canCopy: boolean;
  isCopied: boolean;
  onCopy: (...args: any[]) => any;
  currentLanguage: string;
  availableLanguages: {
    title?: string;
    value: string;
  }[];
  onLanguageSelect: (...args: any[]) => any;
  onPreviewClick: (...args: any[]) => any;
  onTutorialClick: (...args: any[]) => any;
  length?: any;
}
class WidgetSection extends React.PureComponent<IWidgetSectionProps, {}> {
  static defaultProps = {
    code: '',
  };

  constructor(props) {
    super(props);
    this.onLanguageSelect = this.onLanguageSelect.bind(this);
    this.onTutorialClick = this.onTutorialClick.bind(this);
    this.onPreviewClick = this.onPreviewClick.bind(this);
    this.onWidgetButtonClick = this.onWidgetButtonClick.bind(this);
  }

  onWidgetButtonClick() {
    this.props.onPreviewClick();
  }

  onPreviewClick(e) {
    e.preventDefault();
    this.props.onPreviewClick(this.props.section);
  }

  onTutorialClick(event) {
    if (event.target && event.target.nodeName === 'A') {
      this.props.onTutorialClick(this.props.section);
    }
  }

  onLanguageSelect(lang) {
    this.props.onLanguageSelect({ lang });
  }

  getSectionIcon(section) {
    return {
      button: <ComputerBookNow />,
      embed: <ComputerWidget />,
      link: <ComputerMedia />,
    }[section];
  }

  prepareSteps(steps) {
    const values = Object.values(steps);
    if (this.props.availableLanguages.length === 1) {
      values.shift();
    }
    return values;
  }

  render() {
    const {
      section,
      code,
      isCopied,
      onCopy,
      canCopy,
      currentLanguage,
      availableLanguages,
      onLanguageSelect,
    } = this.props;
    const lang = Wahanda.lang.settings.onlineBooking;
    const text = Wahanda.lang.settings.onlineBooking[section] || {};
    if (!text || !code) {
      return null;
    }
    const Icon = this.getSectionIcon(section);
    const steps = this.prepareSteps(text.steps);
    return (
      <BaseSection icon={Icon}>
        <h2>{text.title}</h2>
        <p>{text.intro}</p>
        <Expandable title={<h3>{text.setup || lang.labels.setup}</h3>}>
          {steps && steps.length && <List type="numbers">{steps}</List>}
          {section === SECTION.BUTTON && code && (
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'lang' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
            <WidgetPreview lang={lang} code={code} onPreviewClick={this.onWidgetButtonClick} />
          )}
          {section === SECTION.LINK && (
            <div className={style.preview}>
              <a href={code} onClick={this.onPreviewClick}>
                {code}
              </a>
            </div>
          )}
          <div className={classnames(style.select, style.actions)}>
            {this.props.availableLanguages.length > 1 && (
              <div className={style.left}>
                <label htmlFor={`lang-${section}`}>{lang.labels.language}</label>
                <Select
                  id={`lang-${section}`}
                  name={`lang-${section}`}
                  value={currentLanguage}
                  options={availableLanguages}
                  onChange={onLanguageSelect}
                />
              </div>
            )}
            <CopyButton
              className={style.right}
              copyText={text.copy || lang.labels.copy}
              copiedText={text.copied || lang.labels.copied}
              copy={code}
              disabled={!canCopy}
              isCopied={isCopied}
              onClick={onCopy}
            />
          </div>

          <p
            onClick={this.onTutorialClick}
            dangerouslySetInnerHTML={{
              __html: Wahanda.Template.render(lang.labels.tutorial, {
                socialIntegrationHref: Wahanda.Url.getFullUrl('marketing', PATHS.FBE),
              }),
            }}
          />
        </Expandable>
      </BaseSection>
    );
  }
}

export default WidgetSection;
