import { select } from 'redux-saga/effects';
import { getEditActions } from './selectors';
import { ServicesAnalytics, ServiceEditLabel } from '../tracking';

export function* offerDialogSaved({ payload: { serviceId } }) {
  const actions = yield select(getEditActions);

  const distinctActions: ServiceEditLabel[] = Array.from(new Set(actions));

  if (!serviceId) {
    return;
  }

  const track = ServicesAnalytics.trackServiceEdit.bind(null, serviceId);

  distinctActions.forEach(track);
}
