App.Routers.Profile = Backbone.Router.extend({
  routes: {
    'venue/:venueId/setup-profile/:profileId/auth/:authKey': 'profileSetup',
  },

  profileSetup: function (venueId, profileId, authKey) {
    App.mainViewOptions = {
      initialDialog: {
        type: 'profile-setup',
        profileId: profileId,
      },
    };

    App.setLightweightAuthKey(authKey);
    App.allowLightweightAuthentication = true;

    App.initVenue(venueId);
  },
});
