/**
 * Plain-text (textarea) text editor.
 * $el of this view should be an container having a textarea.
 * 
 * Possible View's child nodes:
 *   .show-editor - Clicking on it will hide it + show the textarea
 */
BackboneEx.View.Editor.PlainText = Backbone.View.extend({
	events: {
		"click .show-editor": "showEditor"
	},
	
	options: {
		editorName: "",
		editorContent: "",
		height: 70,
		title: "",
		tabindex: null
	},
	
	render: function() {
		if (!this.$editor.attr('id')) {
			this.$editor.attr('id', this.generateId());
		}
		this.setEditor();
	},
	
	setEditor: function() {
		this.$editor  = this.$('textarea');
	},
	
	generateId: function() {
		return _.uniqueId('editor_');
	},
	
	showEditor: function() {
		this.$('.show-editor').addClass('hidden disabled');
		this.$editor.removeClass('hidden');
	},
	
	destruct: function() {
	},
	
	getValue: function() {
		return this.$editor.val();
	},
	
	disable: function() {
		this.$editor.disableFormElements();
	},
	
	enable: function() {
		this.$editor.enableFormElements();
	},
	
	update: function(html) {
		this.$editor.text(html != null ? html : "");
	}
});
