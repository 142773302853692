import React from 'react';
import classnames from 'classnames';

import { InputContext, InputSizeType, SelectOptionType } from 'components/common/__BaseCommon';
import { SecondaryType, SelectItem } from '../utils/types';
import style from './SelectOption.scss';

interface Props {
  isActive?: boolean;
  isIntended?: boolean;
  isSelectable?: boolean;
  newLook?: boolean;
  item: SelectItem;
  onMouseMove?: (payload, event) => void;
  onClick?: (payload) => void;
}

export const SecondaryOptionText = (payload: {
  secondaryText: SecondaryType['secondaryText'];
  secondaryType?: SecondaryType['secondaryType'];
}) => {
  const { secondaryText, secondaryType = SelectOptionType.DEFAULT } = payload;
  const secondaryClassNames = classnames(style.secondary, {
    [style[secondaryType]]: secondaryType,
  });

  return (
    <>
      <span className={secondaryClassNames}>{`(${secondaryText})`}</span>
    </>
  );
};

export const SelectOption = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      item,
      onMouseMove,
      onClick,
      isActive = false,
      isSelectable = true,
      newLook = false,
      isIntended,
      ...otherProps
    },
    ref,
  ) => {
    const { size = InputSizeType.DEFAULT } = React.useContext(InputContext);
    const { name, value, secondary, type = SelectOptionType.DEFAULT } = item;
    const hidden = item.hide;

    const classNames = classnames(style.container, {
      [style.isActive]: isActive,
      [style.isIntended]: isIntended,
      [style.notSelectable]: !isSelectable,
      [style.newLook]: newLook,
      [style[size]]: size,
      [style[type]]: type,
      hidden,
    });

    const handleClick = () => (isSelectable && onClick ? onClick(item) : null);
    const handleMouseMove = (event) =>
      isSelectable && onMouseMove ? onMouseMove(item, event) : null;
    const dataTest = `select-option-${value}${type && `-${type}`}`;

    return (
      <div
        ref={ref}
        {...otherProps}
        className={classNames}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        data-test={dataTest}
      >
        {name}
        {!!secondary && (
          <SecondaryOptionText
            secondaryText={secondary.secondaryText}
            secondaryType={secondary.secondaryType}
          />
        )}
      </div>
    );
  },
);
