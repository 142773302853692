import React from 'react';
import classnames from 'classnames';
import Rating from 'react-components/Rating';
import StarGradient from 'react-components/Rating/StarGradient';
import style from './style.scss';

interface IStarRatingProps extends React.HTMLAttributes<Element> {
  isValid?: boolean;
  value?: number;
}

export default class StarRating extends React.Component<IStarRatingProps, {}> {
  render() {
    const { value, isValid } = this.props;
    const textValue = Number(value).toFixed(1);
    return (
      <div className={classnames('tl-stars-rating', { valid: isValid })}>
        <div className={classnames(style.rating, isValid ? style.valid : style.warning)}>
          <Rating rating={value} size="big" />
          <StarGradient />
        </div>
        <span className="tl-stars-rating--value">{textValue}</span>
      </div>
    );
  }
}
