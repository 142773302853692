;(function() {
	/**
	 * Reset the jQuery validator - AND remove any qtips.
	 */
	$.fn.resetValidator = function() {
		if (this.is('form')) {
			// Remove qTip errors
			this.find('.error').each(function() {
				$(this).qtip('destroy');
			});
			// Reset the validator
			this.validate().resetForm();
		}
		return this;
	};
}());
