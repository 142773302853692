import React from 'react';
import { CHANNEL_CODES, LANGUAGE_LOCALES } from 'config/domains-locales';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import { ClientsAnalytics } from '../../../tracking';

import style from './DownloadTemplate.scss';

const lang = Wahanda.lang.clientsImport.addMultipleClients.downloadTemplate;

export interface Props {
  contentChannel: string;
  locale?: string;
}

export const DownloadTemplate = ({ contentChannel, locale }: Props) => {
  const onDownloadClick = () => ClientsAnalytics.trackClientsImportDownloadTemplateClick();

  const fileName = locale ? getFileNameByLocale(locale) : getFileNameByChannel(contentChannel);

  return (
    <div className={style.container}>
      <span className={style.header}>{lang.header}</span>
      <p className={style.description}>{lang.description}</p>
      <div className={style.buttonWrapper}>
        <Button
          label={lang.downloadButton}
          variant="secondary"
          onClick={onDownloadClick}
          href={`/assets/${fileName}`}
        />
      </div>
    </div>
  );
};

const getFileNameByLocale = (locale: string) => {
  switch (locale) {
    case LANGUAGE_LOCALES.DE:
      return 'DE_template.xlsx';
    case LANGUAGE_LOCALES.ES:
      return 'ES_template.xlsx';
    case LANGUAGE_LOCALES.FR:
      return 'FR_template.xlsx';
    case LANGUAGE_LOCALES.FR_BE:
      return 'BEFR_template.xlsx';
    case LANGUAGE_LOCALES.NL_BE:
      return 'BENL_template.xlsx';
    case LANGUAGE_LOCALES.IE:
      return 'IE_template.xlsx';
    case LANGUAGE_LOCALES.IT:
      return 'IT_template.xlsx';
    case LANGUAGE_LOCALES.AT:
      return 'AT_template.xlsx';
    case LANGUAGE_LOCALES.CH:
      return 'CH_template.xlsx';
    case LANGUAGE_LOCALES.NL:
      return 'NL_template.xlsx';
    case LANGUAGE_LOCALES.LT:
      return 'LT_template.xlsx';
    case LANGUAGE_LOCALES.LV:
      return 'LV_template.xlsx';
    case LANGUAGE_LOCALES.ET_EE:
      return 'ETEE_template.xlsx';
    case LANGUAGE_LOCALES.EN_NL:
    case LANGUAGE_LOCALES.EN_BE:
    case LANGUAGE_LOCALES.EN:
    default:
      return 'EN_template.xlsx';
  }
};

const getFileNameByChannel = (contentChannel: string) => {
  switch (contentChannel) {
    case CHANNEL_CODES.DE:
      return 'DE_template.xlsx';
    case CHANNEL_CODES.ES:
      return 'ES_template.xlsx';
    case CHANNEL_CODES.FR:
      return 'FR_template.xlsx';
    case CHANNEL_CODES.BE:
      return 'BENL_template.xlsx';
    case CHANNEL_CODES.IE:
      return 'IE_template.xlsx';
    case CHANNEL_CODES.IT:
      return 'IT_template.xlsx';
    case CHANNEL_CODES.AT:
      return 'AT_template.xlsx';
    case CHANNEL_CODES.CH:
      return 'CH_template.xlsx';
    case CHANNEL_CODES.NL:
      return 'NL_template.xlsx';
    case CHANNEL_CODES.LT:
      return 'LT_template.xlsx';
    case CHANNEL_CODES.LV:
      return 'LV_template.xlsx';
    case CHANNEL_CODES.EE:
      return 'ETEE_template.xlsx';
    case CHANNEL_CODES.GB:
    default:
      return 'EN_template.xlsx';
  }
};
