import React, { Component } from 'react';
import Wahanda from 'common/wahanda';
import UnderlinedInput from 'components/common/UnderlinedInput/withFormsy';
import DialogContentBox from 'components/common/dialog/DialogContentBox';
import style from './style.scss';

const lang = Wahanda.lang.menu.productSupplier;
interface IProductSupplierInformationTabProps extends React.HTMLAttributes<Element> {
  name?: string;
  contactPersonName?: string;
  phone?: string;
  email?: string;
  actions: {
    setSupplierDataAction: (...args: any[]) => any;
  };
  setSupplierDataAction?: any;
}

export default class ProductSupplierInformationTab extends Component<
  IProductSupplierInformationTabProps,
  {}
> {
  static defaultProps = {
    name: '',
    contactPersonName: '',
    phone: '',
    email: '',
  };

  onChange = (event) => {
    this.props.actions.setSupplierDataAction({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { name, contactPersonName, phone, email } = this.props;
    return (
      <div>
        <DialogContentBox separated>
          <UnderlinedInput
            label={lang.supplierName}
            name="name"
            value={name}
            onChange={this.onChange}
            validationErrors={{
              isDefaultRequiredValue: Wahanda.lang.validate.defaults.required,
            }}
            maxLength={100}
            autoFocus
            required
          />
        </DialogContentBox>
        <DialogContentBox separated>
          <UnderlinedInput
            label={lang.contactPerson}
            name="contactPersonName"
            value={contactPersonName}
            onChange={this.onChange}
            maxLength={255}
          />
          <div className={style.inputGroupRow}>
            <div className={style.inputGroupRowItem}>
              <UnderlinedInput
                label={lang.phone}
                name="phone"
                value={phone}
                onChange={this.onChange}
                maxLength={20}
              />
            </div>
            <div className={style.inputGroupRowItem}>
              <UnderlinedInput
                label={lang.email}
                name="email"
                value={email}
                onChange={this.onChange}
                validations={{
                  isEmail: true,
                }}
                validationErrors={{
                  isEmail: Wahanda.lang.validate.defaults.email,
                }}
                maxLength={100}
              />
            </div>
          </div>
        </DialogContentBox>
      </div>
    );
  }
}
