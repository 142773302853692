import {
  INITIAL_STATE_DASHBOARD,
  GET_VENUE_REQUEST,
  GET_VENUE_RECEIVE,
  GET_VENUE_FAILURE,
  GET_VENUE_STATISTICS_REQUEST,
  GET_VENUE_STATISTICS_RECEIVE,
  GET_VENUE_STATISTICS_FAILURE,
  GET_SUPPLIER_DETAILS_REQUEST,
  GET_SUPPLIER_DETAILS_RECEIVE,
  GET_SUPPLIER_DETAILS_FAILURE,
  TRACK_ACTION_ANALYTICS,
  VENUE_STATE_RESET,
  GET_CUSTOMER_REVIEWS_FAILURE,
  GET_CUSTOMER_REVIEWS_RECEIVE,
  GET_CUSTOMER_REVIEWS_REQUEST,
  PUT_REVIEW_RESPONSE,
  PUT_REVIEW_RESPONSE_SUCCESS,
  PUT_REVIEW_RESPONSE_FAILURE,
  CLEAR_PUT_REVIEW_RESPONSE_FAILURE,
  CLEAR_CUSTOMER_REVIEWS,
} from 'reduxStore/actionsConst';

export const requestVenueDetailsAction = (venueId) => ({
  type: GET_VENUE_REQUEST,
  venueId,
});

export const receiveVenueDetailsAction = (data) => ({
  type: GET_VENUE_RECEIVE,
  data,
});

export const requestVenueDetailsFailureAction = (ex) => ({
  type: GET_VENUE_FAILURE,
  ex,
});

export const trackAnalytics = (cat, act, lab) => ({
  type: TRACK_ACTION_ANALYTICS,
  meta: {
    ga: { cat, act, lab },
  },
});

export const initialStatePopulation = (data) => ({
  type: INITIAL_STATE_DASHBOARD,
  data,
});

export const resetStateToDefault = () => ({
  type: VENUE_STATE_RESET,
});

export const requestVenueStatisticsAction = (venueId) => ({
  type: GET_VENUE_STATISTICS_REQUEST,
  venueId,
});

export const receiveVenueStatisticsAction = (data) => ({
  type: GET_VENUE_STATISTICS_RECEIVE,
  data,
});

export const requestVenueStatisticsFailureAction = (ex) => ({
  type: GET_VENUE_STATISTICS_FAILURE,
  ex,
});

export const requestSupplierDetailsAction = (supplierId) => ({
  type: GET_SUPPLIER_DETAILS_REQUEST,
  supplierId,
});

export const receiveSupplierDetailsAction = (data) => ({
  type: GET_SUPPLIER_DETAILS_RECEIVE,
  data,
});

export const requestSupplierDetailsFailureAction = (ex) => ({
  type: GET_SUPPLIER_DETAILS_FAILURE,
  ex,
});

export const requestCustomerReviewsAction = ({ venueId, page, size }) => ({
  type: GET_CUSTOMER_REVIEWS_REQUEST,
  venueId,
  page,
  size,
});

export const requestCustomerReviewsFailureAction = (ex) => ({
  type: GET_CUSTOMER_REVIEWS_FAILURE,
  ex,
});

export const receiveCustomerReviewsAction = (data) => ({
  type: GET_CUSTOMER_REVIEWS_RECEIVE,
  data,
});

export const clearCustomerReviewsAction = () => ({
  type: CLEAR_CUSTOMER_REVIEWS,
});

export const clearResponseToCustomerReviewError = () => ({
  type: CLEAR_PUT_REVIEW_RESPONSE_FAILURE,
});

export const putResponseToCustomerReviewFailureAction = (data) => ({
  type: PUT_REVIEW_RESPONSE_FAILURE,
  data,
});

export const putResponseToCustomerReviewSuccessAction = (reviewId, data) => ({
  type: PUT_REVIEW_RESPONSE_SUCCESS,
  reviewId,
  data,
});

export const submitResponseToCustomerReview = ({ content, venueId, reviewId }) => ({
  type: PUT_REVIEW_RESPONSE,
  content,
  venueId,
  reviewId,
});
