import { createAction } from 'reduxStore/actionCreator';
import {
  INIT_STRIPE_REQUEST,
  INIT_STRIPE_REQUEST_SUCCESS,
  CONFIRM_STRIPE_PAYMENT_REQUEST_SUCCESS,
  STRIPE_PAYMENT_FAILURE,
  CLEAR_STRIPE,
  MAKE_CARD_PAYMENT,
  MAKE_IDEAL_PAYMENT,
  MAKE_IDEAL_PAYMENT_FAILURE,
} from 'reduxStore/actionsConst';
import { Stripe, StripeElement } from './types';

export const requestInitStripe = (payload: { supplierId: number; closingBalance: number }) =>
  createAction(INIT_STRIPE_REQUEST, payload);

export type RequestInitStripeAction = ReturnType<typeof requestInitStripe>;

export const resetStripe = () => createAction(CLEAR_STRIPE);

export type ResetStripeAction = ReturnType<typeof resetStripe>;

export const requestInitStripeSuccess = (payload: { stripePublicApiKey: string }) =>
  createAction(INIT_STRIPE_REQUEST_SUCCESS, payload);

export type RequestInitStripeSuccessAction = ReturnType<typeof requestInitStripeSuccess>;

export const requestConfirmStripePaymentSuccess = () =>
  createAction(CONFIRM_STRIPE_PAYMENT_REQUEST_SUCCESS);

export type RequestConfirmStripePaymentSuccessAction = ReturnType<
  typeof requestConfirmStripePaymentSuccess
>;

export const stripePaymentFailed = () => createAction(STRIPE_PAYMENT_FAILURE);

export type StripePaymentFailedAction = ReturnType<typeof stripePaymentFailed>;

export const doStripePaymentRequest = (payload: {
  paymentAmount: number;
  cardholderName: string;
  supplierId: string;
  stripe: Stripe;
  saveCardInfo: boolean;
  cardElement: StripeElement;
}) => createAction(MAKE_CARD_PAYMENT, payload);

export type DoStripePaymentRequestAction = ReturnType<typeof doStripePaymentRequest>;

export const doIdealPaymentRequest = (payload: { supplierId: string; paymentAmount: number }) =>
  createAction(MAKE_IDEAL_PAYMENT, payload);

export type DoIdealPaymentRequestAction = ReturnType<typeof doIdealPaymentRequest>;

export const doIdealPaymentFailure = () => createAction(MAKE_IDEAL_PAYMENT_FAILURE);

export type DoIdealPaymentFailureAction = ReturnType<typeof doIdealPaymentFailure>;
