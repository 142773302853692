import React from 'react';
import App from 'common/backbone-app';
import classnames from 'classnames';
import SelectDropdown from 'components/common/SelectDropdown';
import style from './style.scss';

export interface Data<T> {
  name: string;
  value?: T;
}

export interface Props<T> {
  data: Data<T>[];
  extraClassName?: string;
  onSelect?: (selected: T) => void;
  onOpen?: () => void;
  dataTest?: string;
  selected?: string | number;
}

class BigSelectDropdown<T extends string | number> extends React.PureComponent<Props<T>> {
  static defaultProps = {
    extraClassName: '',
  };

  render() {
    const props = {
      ...this.props,
      extraClassName: classnames(this.props.extraClassName, style.bigSelectDropdown),
      optionExtraClassName: style.bigOptions,
    };
    if (!App.isProd) {
      console.warn('BigSelectDropdown component is deprecated. Use Select component');
    }
    return <SelectDropdown {...props} />;
  }
}

export default BigSelectDropdown;
