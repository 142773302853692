import React from 'react';
import Wahanda from 'common/wahanda';
import { RouteComponentProps, generatePath } from 'react-router-dom';
import BigSelectDropdown from 'components/reports/BigSelectDropdown';

interface UrlProps {
  venueId: string;
}

export const ReportSelectionDropdown = ({ history, match }: RouteComponentProps<UrlProps>) => {
  const dropdownOptions = [
    {
      name: Wahanda.lang.reports.tabs.transactions,
      value: '/venue/:venueId/transactions',
    },
    {
      name: Wahanda.lang.reports.tabs.bookingList,
      value: '/venue/:venueId/bookings',
    },
  ];
  const onDropdownSelect = (selection: string) => {
    const { push } = history;
    const { venueId } = match.params;
    push(generatePath(selection, { venueId }));
  };
  return (
    <BigSelectDropdown data={dropdownOptions} selected={match?.path} onSelect={onDropdownSelect} />
  );
};
