import React from 'react';
import ReactDOM from 'react-dom';
import pathToRegexp from 'path-to-regexp';
import { Provider } from 'react-redux';
import SalesReportContainer from 'components/reports/newSalesReports/SalesReportContainer/container';
import ROUTES from 'components/reports/newSalesReports/SalesReportContainer/reportRoutes';
import storeBuilder from 'reduxStore/store';
import App from 'common/backbone-app';
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

export default function (params) {
  const store = storeBuilder();
  return {
    /**
     * Minimal `Backbone.trigger` implementation to check if the view is visible.
     *
     * @return {void}
     */
    trigger(command) {
      switch (command) {
        case 'visible':
          this.render();
          break;
        case 'hidden':
          this.destroy();
          break;
        default:
          break;
      }
    },
    destroy() {
      ReactDOM.unmountComponentAtNode(params.tabElement);
    },
    render() {
      const { NEW_SALES_REPORTS } = ROUTES;
      ReactDOM.render(
        <Router hashType="noslash">
          <Provider store={store}>
            <Switch>
              <Route path={NEW_SALES_REPORTS} component={SalesReportContainer} />
              <Route
                render={() => (
                  <Redirect
                    to={pathToRegexp.compile(NEW_SALES_REPORTS)({
                      venueId: App.getVenueId(),
                    })}
                  />
                )}
              />
            </Switch>
          </Provider>
        </Router>,
        params.tabElement,
      );
    },
  };
}
