import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import ROLES from 'common/constants/userRoles';

import {
  getConfigurablePermissionsByRoles,
  getConfigurableEmployeePermissions,
} from 'reduxStore/common/employees/selectors';
import * as actions from 'reduxStore/common/employees/actions';
import EmployeePermissions from './index';

const mapStateToProps = (state) => {
  const {
    employees: {
      employee: { id, canLogin, role, supplierBound },
      isLoaded,
    },
  } = state;

  const pointOfSalePermissionsVisible = App.config.get('venue').pointOfSaleEnabled;
  const showTaxAuditorRole =
    App.config.get('venue').pointOfSaleEnabled || role === ROLES.TAX_AUDITOR;
  const selectedPermissions = getConfigurableEmployeePermissions(state, {
    pointOfSalePermissionsVisible,
  });
  const permissionsByRoles = getConfigurablePermissionsByRoles(state, {
    pointOfSalePermissionsVisible,
  });
  const showBasicRole = permissionsByRoles.find((i) => i.role === ROLES.BASIC) != null;
  // Show access attention message if employee doesn't have login or it's a new employee
  const showAccessAttention = (!canLogin || id == null) && isLoaded;

  return {
    id,
    role,
    selectedPermissions,
    permissionsByRoles,
    showAccessAttention,
    showTaxAuditorRole,
    showBasicRole,
    pointOfSalePermissionsVisible,
    disabled:
      supplierBound ||
      !Wahanda.Permissions.editUserPermissions() ||
      App.config.getAccountEmployeeId() === id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ id: null; role: null; selectedPermissions:... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(EmployeePermissions);
