export enum SignatureSource {
  RECEIPT = 'RECEIPT',
  RECEIPT_COPY = 'RECEIPT_COPY',
  GRAND_TOTAL = 'GRAND_TOTAL',
  TECHNICAL_EVENT_ACTION_LOG = 'TECHNICAL_EVENT_ACTION_LOG',
  ARCHIVE = 'ARCHIVE',
}

export interface Signature {
  signature: string;
  transactionDateTime: string;
}

export interface SignatureResponse {
  signatures: Signature[];
}
