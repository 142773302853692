require('../pricing/treatment-type-select.js');
(function () {
  var BaseView = App.Views.Forms.MenuOffer2.Pricing.TreatmentTypeSelect;
  var TreatmentsListSelect = BaseView.extend({
    events: {
      'change .js-primaryTreatmentId': 'onSelect',
      'click .active-result': 'onClick',
    },

    required: false,

    render: function () {
      this.selectedTreatments = [];

      BaseView.prototype.render.call(this);

      this.setModelTreatments();
      this.renderTreatments();
    },

    setModelTreatments: function () {
      var self = this;
      this.selectedTreatments = [];

      _.each(
        this.model.get('treatmentIds'),
        function (treatmentId) {
          var treatment = self.options.treatments.get('treatmentsCollection').get(treatmentId);

          if (!treatment) {
            return;
          }

          this.selectedTreatments.push({
            treatmentId: treatmentId,
            treatmentName: treatment.get('name'),
          });
        }.bind(this),
      );
    },

    renderTreatments: function () {
      var html = Wahanda.Template.renderTemplate(
        'menu-offer2-description-treatment-list-template',
        {
          treatments: this.selectedTreatments,
          readOnly: this.model.isReadOnly(),
        },
      );
      this.$el.closest('.treatment-select').find('.treatment-list').html(html);
    },

    /**
     * If anything is selected in the select, show name input box.
     *
     * @param Event evt
     */
    onSelect: function (evt) {
      var currentSelection = this.getValues();
      this.$('.chosen-container .chosen-single span').html(this.options.selectText);
      if (
        this.selectedTreatments.length === this.options.maxSelect ||
        this.isDuplicateTreatment(currentSelection.treatmentId)
      ) {
        return;
      }
      this.selectedTreatments.push(currentSelection);
      //Add current selection to list
      this.renderTreatments();

      BaseView.prototype.onSelect.call(this, evt);
    },

    removeTreatment: function (treatmentId) {
      this.selectedTreatments = _.reject(this.selectedTreatments, function (treatment) {
        return treatmentId === treatment.treatmentId;
      });
      if (this.selectedTreatments.length === 0) {
        this.$('.js-primaryTreatmentId').val('');
      }
    },

    isDuplicateTreatment: function (treatmentId) {
      return _.any(this.selectedTreatments, function (treatment) {
        return treatment.treatmentId === treatmentId;
      });
    },

    update: function () {
      BaseView.prototype.update.call(this);

      this.setModelTreatments();
      // Set the field's value to something non-empty to keep the validation happy
      this.$('.js-primaryTreatmentId').val((this.model.get('treatmentIds') || [])[0]);
    },
  });

  App.Views.Forms.MenuOffer2.Pricing.TreatmentsListSelect = TreatmentsListSelect;
})();
