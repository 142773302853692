import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class CautionFilled extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-caution-filled', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 24 24"
          >
            <path d="M14.46 3.353l8.08 14.294C23.898 20.05 22.762 22 19.997 22h-16c-2.76 0-3.9-1.941-2.537-4.353L9.54 3.353c1.358-2.404 3.557-2.412 4.92 0zM12 16.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM13 7h-2v7h2V7z" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(CautionFilled);
