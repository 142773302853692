require('../mixin.js');
BackboneEx.Mixin.Common.UrlFilter = {
    /**
     * Url template in MustacheJS format, which utilizes the {{venueId}} param.
     */
    // urlTemplate: '',
    /**
     * Possible URL filters, in JS camelCase.
     */
    // filters: {},
    /**
     * Filter map in form:
     *   "camelCase": "url-param-name"
     */
    // filterMap: {},

    initialize: function() {
        if (this.filters === null) {
            this.filters = {};
        }
        if (this.filterMap === null) {
            this.filterMap = {};
        }
    },

    url: function() {
        var base = Wahanda.Template.render(this.urlTemplate, {venueId: App.config.get('venue').id});
        var value;
        var params = [];

        for (var key in this.filterMap) {
            if (value = this.getFilterValue(key)) {
                params.push(this.filterMap[key] + "=" + encodeURIComponent(value));
            }
        }

        if (params.length > 0) {
            base += '?' + params.join('&');
        }

        return App.Api.wsUrl(base);
    },

    /**
     * Gets one filter value.
     *
     * @param string key
     * @return string or null, if value isn't valid
     */
    getFilterValue: function(key) {
        var data = this.filters[key];
        if (_.isArray(data)) {
            if (data.length > 0) {
                return data.join(',');
            }
        } else if (data) {
            return data;
        }
        return null;
    },

    /**
     * Sets the filter's values for URL. When fetching next time, only those passed-in last will be used.
     *
     * @param Object filters Params should be strings or arrays, which will be concatenated into a string
     */
    setFilters: function(filters) {
        for (var key in this.filters) {
            if (filters[key] != null) {
                this.filters[key] = filters[key];
            } else {
                this.filters[key] = null;
            }
        }
    }
};
