;(function() {
	/**
	 * jQuery plugin for changing text in Wahandish buttons generated with Rails code.
	 *
	 * @param String|Object text If the button is action button, provide { default: "", doing: "" }
	 * @param Boolean isHtml (optional) Should HTML be allowed?
	 */
	$.fn.changeButtonText = function(text, isHtml) {
		return this.each(function() {
			var $inner = $(this).find('.button-inner');
			if (typeof text == 'string') {
				updateSimpleText.call($inner, text, isHtml);
			} else {
				updateActionTexts.call($inner, text, isHtml);
			}
		});
	};

	function updateSimpleText(text, isHtml) {
		var $children = this.children();
		// Append the children and the text. Any old text nodes should be "gone".
		this.empty().append($children).append(
			isHtml ? text : document.createTextNode(text)
		);
	};

	function updateActionTexts(text, isHtml) {
		this.find('.msg-action-default').text(text['default']);
		this.find('.msg-action-doing').text(text['doing']);
	};
}());
