import {
  GET_CLIENT_APP_SHARE_LINK_REQUEST,
  GET_CLIENT_APP_SHARE_LINK_SUCCESS,
  GET_CLIENT_APP_SHARE_LINK_FAILURE,
} from 'reduxStore/actionsConst';

export const getClientAppShareLinkRequest = () => ({
  type: GET_CLIENT_APP_SHARE_LINK_REQUEST,
});

export const getClientAppShareLinkSuccess = (uris) => ({
  type: GET_CLIENT_APP_SHARE_LINK_SUCCESS,
  uris,
});

export const getClientAppShareLinkFailure = (error) => ({
  type: GET_CLIENT_APP_SHARE_LINK_FAILURE,
  error,
});
