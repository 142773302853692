import { connect } from 'react-redux';
import { getEmailTemplate, getCampaignSummary } from 'components/marketing/EmailCampaign/selectors';
import { sendBackToBusinessCampaignRequest } from 'components/marketing/EmailCampaign/CampaignSummary/actions';
import { emailTemplateReset } from 'components/marketing/EmailCampaign/CampaignPreview/actions';
import { BackToBusiness as Component } from './BackToBusiness';
import { BackToBusinessState } from '../reducer';
import { backToBusinessInitStatusRequest, getBackToBusinessEmailTemplateRequest } from '../actions';

const mapStateToProps = (state) => {
  const backToBusiness: BackToBusinessState = state.marketing?.backToBusiness;
  const { currentReach, emailReach, smsReach, alreadySent } = backToBusiness;

  const isSending = getCampaignSummary(state).isSending || false;
  const successfullySent = getCampaignSummary(state).isSent || false;

  return {
    alreadySent,
    currentReach,
    emailReach,
    smsReach,
    isSending,
    successfullySent,
    emailTemplate: getEmailTemplate(state),
  };
};

const mapDispatchToProps = {
  backToBusinessInitStatusRequest,
  getBackToBusinessEmailTemplateRequest,
  sendBackToBusinessCampaignRequest,
  emailTemplateReset,
};

export const BackToBusiness = connect(mapStateToProps, mapDispatchToProps)(Component);
