import {
  OFFER_DIALOG_OPEN,
  OFFER_DIALOG_PACKAGE_PRICING_SKUS_CHANGE,
  OFFER_DIALOG_CLOSE,
  OFFER_DIALOG_CHANGE_PRICING_TYPE,
  OFFER_DIALOG_TOGGLE_SKU,
  OFFER_DIALOG_UPDATE_FULL_PRICE_AMOUNT,
  OFFER_DIALOG_PACKAGE_SERVICES_CHANGE,
  OFFER_DIALOG_PACKAGE_MASTER_SERVICE_CHANGE,
  OFFER_DIALOG_PRICING_AND_EMPLOYEES_READY,
  OFFER_DIALOG_CHANGE_USE_EMPLOYEE_CATEGORIES,
  OFFER_DIALOG_CHANGE_EMPLOYEE,
  OFFER_DIALOG_SELECT_ALL_EMPLOYEES,
  OFFER_DIALOG_DESELECT_ALL_EMPLOYEES,
  OFFER_DIALOG_OFFER_NAME_CHANGE,
  OFFER_DIALOG_CHANGE_ALLOW_PROCESSING,
  OFFER_DIALOG_USE_PROCESSING_TIME,
  OFFER_DIALOG_SET_PROCESSING_TIME,
} from 'reduxStore/actionsConst';
import { PROCESSING_TIME_DEFAULT } from 'common/constants/timeChoices';
import {
  changeVisibleStateOfSku,
  updateFullPriceAmount,
  updateOfferDialogSkusPrices,
  updateOfferDialogSkusPricingType,
  calculateOfferDialogSkuPrices,
  updateMasterService,
  updateOfferDialogEmployeeAndStaffPricing,
  selectAllEmployees,
  deselectAllEmployees,
  filterAllStaffPricedEmployeesIfNoneSelected,
  skipOfferDialogPrefix,
} from './selectors';
import { initialiseSkus } from './OfferTreatmentPricingAndEmployees/Pricing/sku-conversion';

export const initialState = {};

export function menu(state = initialState, action) {
  switch (action.type) {
    case OFFER_DIALOG_OPEN:
      return Object.assign({}, state, {
        offerDialogSkus: action.data.offerDialogSkus,
        offerDialogSkusPrices: action.data.offerDialogSkus.map(
          calculateOfferDialogSkuPrices(action.data.isPackagePricingCustom),
        ),
        offerDialogInlineServices: action.data.offerDialogInlineServices,
        isPackagePricingCustom: action.data.isPackagePricingCustom,
        employeeCategoriesCollection: action.data.employeeCategoriesCollection,
        offerDialogReadonly: action.data.offerDialogReadonly,
        offerDialogReadonlyLight: action.data.offerDialogReadonlyLight,
        offerDialogSubServices: action.data.offerDialogSubServices,
      });

    case OFFER_DIALOG_PACKAGE_PRICING_SKUS_CHANGE:
      return Object.assign({}, state, {
        offerDialogSkus: action.offerDialogSkus,
      });

    case OFFER_DIALOG_CHANGE_PRICING_TYPE:
      return Object.assign({}, state, {
        isPackagePricingCustom: action.isPackagePricingCustom,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogSkus' does not exist on type ... Remove this comment to see the full error message
        offerDialogSkus: state.offerDialogSkus.map(
          updateOfferDialogSkusPricingType(
            action.isPackagePricingCustom,
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogSkusPrices' does not exist on... Remove this comment to see the full error message
            state.offerDialogSkusPrices,
          ),
        ),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogSkusPrices' does not exist on... Remove this comment to see the full error message
        offerDialogSkusPrices: state.offerDialogSkusPrices.map(
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogSkus' does not exist on type ... Remove this comment to see the full error message
          updateOfferDialogSkusPrices(action.isPackagePricingCustom, state.offerDialogSkus),
        ),
      });

    case OFFER_DIALOG_TOGGLE_SKU:
      return Object.assign({}, state, {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogSkus' does not exist on type ... Remove this comment to see the full error message
        offerDialogSkus: state.offerDialogSkus.map(
          changeVisibleStateOfSku(action.indexArray, action.visible),
        ),
      });

    case OFFER_DIALOG_UPDATE_FULL_PRICE_AMOUNT:
      return Object.assign({}, state, {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogSkus' does not exist on type ... Remove this comment to see the full error message
        offerDialogSkus: state.offerDialogSkus.map(
          updateFullPriceAmount(action.index, action.fullPriceAmount),
        ),
      });

    case OFFER_DIALOG_PACKAGE_SERVICES_CHANGE:
      return Object.assign({}, state, {
        offerDialogSkus: action.offerDialogSkus,
        offerDialogSkusPrices: action.offerDialogSkus.map(
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'isPackagePricingCustom' does not exist o... Remove this comment to see the full error message
          calculateOfferDialogSkuPrices(state.isPackagePricingCustom),
        ),
        offerDialogInlineServices: action.offerDialogInlineServices,
        offerDialogSubServices: action.offerDialogSubServices,
      });

    case OFFER_DIALOG_PACKAGE_MASTER_SERVICE_CHANGE:
      return Object.assign({}, state, {
        offerDialogSubServices: updateMasterService(
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogSubServices' does not exist o... Remove this comment to see the full error message
          state.offerDialogSubServices,
          action.offerId,
          action.tempId,
        ),
        offerDialogInlineServices: updateMasterService(
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogInlineServices' does not exis... Remove this comment to see the full error message
          state.offerDialogInlineServices,
          action.offerId,
          action.tempId,
        ),
      });

    case OFFER_DIALOG_PRICING_AND_EMPLOYEES_READY:
      return Object.assign(
        {},
        state,
        {
          employeeCategoriesCollection: action.data.employeeCategoriesCollection,
          allEmployees: action.data.allEmployees,
          offerDialogReadonly: action.data.offerDialogReadonly,
          offerDialogReadonlyLight: action.data.offerDialogReadonlyLight,
          offerDialogEmployees: action.data.offerDialogEmployees,
          offerDialogInitialEmployees: action.data.offerDialogEmployees,
          offerDialogCleanupTime: action.data.offerDialogCleanupTime,
          offerDialogProcessingTimeMins: action.data.offerDialogProcessingTimeMins,
          offerDialogUseDefaultPricingValues: action.data.offerDialogUseDefaultPricingValues,
          offerDialogAllowProcessing: action.data.offerDialogAllowProcessing,
          offerDialogUseProcessingTime: action.data.offerDialogProcessingTimeMins > 0,
          offerDialogOfferName: action.data.offerDialogOfferName,
          offerDialogUseEmployeeCategoryPricing: action.data.offerDialogUseEmployeeCategoryPricing,
          offerDialogEmployeeCategoryPricingChangeable:
            action.data.offerDialogEmployeeCategoryPricingChangeable,
          offerDialogEnclosedByPackage: action.data.offerDialogEnclosedByPackage,
          offerDialogEscapeWithSkuPricing: action.data.offerDialogEscapeWithSkuPricing,
          offerId: action.data.offerId,
        },
        // This exposes `offerDialogSkus` and `offerDialogIsUnsupportedSkuCombination`
        initialiseSkus({
          skus: action.data.offerDialogSkus,
          employeeCategoriesCollection: action.data.employeeCategoriesCollection,
          selectedEmployees: action.data.allEmployees.filter((employee) =>
            action.data.offerDialogEmployees.includes(employee.id),
          ),
          newSkuPricingType: action.data.newSkuPricingType,
        }),
      );

    case OFFER_DIALOG_CHANGE_USE_EMPLOYEE_CATEGORIES:
      return Object.assign({}, state, {
        offerDialogUseEmployeeCategoryPricing: action.use,
        offerDialogEmployees: action.use
          ? filterAllStaffPricedEmployeesIfNoneSelected(
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogEmployees' does not exist on ... Remove this comment to see the full error message
              state.offerDialogEmployees,
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'allEmployees' does not exist on type '{}... Remove this comment to see the full error message
              state.allEmployees,
            )
          : // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogEmployees' does not exist on ... Remove this comment to see the full error message
            state.offerDialogEmployees,
      });

    case OFFER_DIALOG_CHANGE_EMPLOYEE:
      return Object.assign(
        {},
        state,
        updateOfferDialogEmployeeAndStaffPricing(action.employeeId, action.shouldInclude, {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogEmployees' does not exist on ... Remove this comment to see the full error message
          offerDialogEmployees: state.offerDialogEmployees,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogUseEmployeeCategoryPricing' d... Remove this comment to see the full error message
          offerDialogUseEmployeeCategoryPricing: state.offerDialogUseEmployeeCategoryPricing,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'allEmployees' does not exist on type '{}... Remove this comment to see the full error message
          allEmployees: state.allEmployees,
        }),
      );

    case OFFER_DIALOG_SELECT_ALL_EMPLOYEES:
      return Object.assign(
        {},
        state,
        selectAllEmployees({
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'allEmployees' does not exist on type '{}... Remove this comment to see the full error message
          allEmployees: state.allEmployees,
        }),
      );

    case OFFER_DIALOG_DESELECT_ALL_EMPLOYEES:
      return Object.assign({}, state, deselectAllEmployees());

    case OFFER_DIALOG_CHANGE_ALLOW_PROCESSING:
      return Object.assign({}, state, {
        offerDialogAllowProcessing: action.allow,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogProcessingTimeMins' does not ... Remove this comment to see the full error message
        offerDialogProcessingTimeMins: action.allow ? state.offerDialogProcessingTimeMins : null,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'offerDialogUseProcessingTime' does not e... Remove this comment to see the full error message
        offerDialogUseProcessingTime: action.allow ? state.offerDialogUseProcessingTime : false,
      });

    case OFFER_DIALOG_SET_PROCESSING_TIME:
      return {
        ...state,
        offerDialogProcessingTimeMins: action.payload,
      };

    case OFFER_DIALOG_USE_PROCESSING_TIME:
      return {
        ...state,
        offerDialogUseProcessingTime: action.payload,
        offerDialogProcessingTimeMins: action.payload ? PROCESSING_TIME_DEFAULT : null,
      };

    case OFFER_DIALOG_OFFER_NAME_CHANGE:
      return Object.assign({}, state, {
        offerDialogOfferName: action.name,
      });

    case OFFER_DIALOG_CLOSE:
      return Object.assign({}, skipOfferDialogPrefix(state), {
        isPackagePricingCustom: null,
        employeeCategoriesCollection: null,
      });

    default:
      return state;
  }
}

export default menu;
