/* global _ */
/* global Backbone */
/* eslint func-names: 0 */
/* eslint no-restricted-globals: 0 */
import BigNumber from 'bignumber.js';

(function () {
  App.Models.POS = {};
  App.Collections.POS = {};
  App.Models.POS.Services = App.Models.Appointment.extend({
    normalise: function (index) {
      const appointmentData = this.get('model');
      const normalisedObject = {
        id: index,
        appointmentId: appointmentData.get('id'),
        title: appointmentData.get('offerName'),
        offerName: appointmentData.get('offerName'),
        appointmentPrice: Wahanda.Currency.getFormatted(appointmentData.get('amount')),
        appointmentDate: appointmentData.get('appointmentDate'),
        amount: appointmentData.get('amount'),
        originalAmount: appointmentData.get('amount'),
        skus: appointmentData.get('skus'),
        employeeId: appointmentData.get('employeeId'),
        employeeName: appointmentData.get('employeeName'),
        unpaid: appointmentData.isUnpaid(),
        isMarketplaceBooking: appointmentData.isWahandaBooking(),
        selected: true,
        taxRate: appointmentData.get('taxRate'),
      };
      return normalisedObject;
    },
  });

  App.Models.POS.PackageServices = App.Models.Appointment.extend({
    normalise: function (index) {
      const packageColllection = this.get('collection');
      const packageData = packageColllection.data;
      const normalisedObject = {
        id: index,
        appointmentGroupId: packageData.id,
        isPackage: true,
        title: packageData.name,
        appointmentDate: packageData.appointmentDate,
        amount: packageData.price.amount,
        originalAmount: packageData.price.amount,
        appointmentPrice: Wahanda.Currency.getFormatted(packageData.price.amount),
        skus: this.getAppointmentSkus(),
        unpaid: packageColllection.isUnpaid(),
        isMarketplaceBooking: packageColllection.isWahandaBooking(),
        selected: true,
      };
      return normalisedObject;
    },

    getAppointmentSkus: function () {
      const appointments = this.get('collection').models;
      const self = this;
      let appointmentSkus = [];
      _.each(appointments, (appointment) => {
        const skuLineData = self.getSkuLineData(appointment);
        appointmentSkus = _.union(appointmentSkus, skuLineData);
      });

      return appointmentSkus;
    },

    getSkuLineData: function (appointment) {
      const formattedSkus = [];
      const skus = appointment.get('skus');

      _.each(skus, (sku) => {
        const skuNames = _.pluck(skus, 'skuName');
        const skuLineItem = {
          skuId: sku.skuId,
          name: appointment.get('offerName') + (sku.skuName ? `- ${sku.skuName}` : ''),
          variants: skuNames.join(', '),
          appointmentDate: appointment.get('appointmentDate'),
          appointmentId: appointment.get('id'),
          employeeId: appointment.get('employeeId'),
          employeeName: appointment.get('employeeName'),
          skuName: appointment.get('offerName'),
        };
        formattedSkus.push(skuLineItem);
      });
      return formattedSkus;
    },
  });

  App.Models.POS.Discount = Backbone.Model.extend({
    defaults: {
      description: '',
    },
    validate: function (attributes) {
      const errors = {};
      if (isNaN(attributes.amount)) {
        errors.amount = 'Price must be a number';
      } else if (attributes.amount === 0) {
        errors.amount = 'Price must not be 0';
      }

      if (!_.isEmpty(errors)) {
        return errors;
      }
      return undefined;
    },
  });
  /**
   * Lists the product discriminators
   *
   * @type {{PRODUCT: string, VOUCHER: string}}
   */

  App.Models.POS.ProductDescriminators = {
    PRODUCT: 'PRODUCT',
    VOUCHER: 'VOUCHER',
  };

  App.Models.POS.Product = Backbone.Model.extend({
    defaults: {
      id: null,
      description: '',
      quantity: 1,
      unitPrice: '',
      type: '',
      inventory: null,
      employeeId: null,
      discountPercentage: null,
    },
    validate: function (attributes) {
      const errors = {};
      const unitPrice = attributes.unitPrice;
      if (isNaN(unitPrice) || unitPrice === false) {
        errors.unitPrice = 'Price must be a number';
      } else if (unitPrice === '' || unitPrice < 0) {
        errors.unitPrice = 'Price must be 0 or greater';
      }

      if (attributes.description === '') {
        errors.description = 'Name cannot be empty';
      } else if (attributes.description && attributes.description.length > 255) {
        errors.description = 'Name cannot be over 255 characters';
      }

      if (!_.isEmpty(errors)) {
        return errors;
      }
      return undefined;
    },

    applyDiscount: function applyDiscount(price) {
      const discountPercentage = this.get('discountPercentage');
      if (price == null || discountPercentage == null) {
        return price;
      }
      const BN = BigNumber.clone({ ROUNDING_MODE: BigNumber.ROUND_HALF_UP });
      const discount = new BN(discountPercentage).dividedBy(100).times(price).toFixed(2);
      const discountedPrice = new BN(price).minus(discount).toNumber();

      return discountedPrice;
    },

    getDiscount: function () {
      const discountPercentage = this.get('discountPercentage');
      if (!discountPercentage) {
        return undefined;
      }
      return {
        amount: new BigNumber(this.get('unitPrice'))
          .minus(this.getUnitPriceDiscounted())
          .toFixed(2),
        percentage: discountPercentage,
      };
    },

    getUnitPriceDiscounted: function () {
      return this.applyDiscount(this.get('unitPrice'));
    },

    getTotalDiscounted: function () {
      return new BigNumber(this.getUnitPriceDiscounted())
        .multipliedBy(this.get('quantity'))
        .toFixed(2);
    },

    getTotal: function () {
      return new BigNumber(this.get('unitPrice')).multipliedBy(this.get('quantity')).toNumber();
    },
  });

  /**
   * Lists all the payment discriminators that are available.
   * These should map to those on the server side.
   * @type {{CASH: string, CARD: string, VOUCHER: string}}
   */
  App.Models.POS.PaymentDiscriminators = {
    CASH: 'CASH',
    CARD: 'CARD',
    VOUCHER: 'VCHR',
  };

  /**
   * Basic definition of a payment.
   * This is a base type so that we have a model to supply to the Payments collection.
   *
   * @type {*|void}
   */
  App.Models.POS.Payment = Backbone.Model.extend({
    defaults: {
      amount: null,
      costAmount: null,
      paymentType: null,
      cashInDrawer: null,
      tips: null,
    },
  });

  App.Models.POS.PaymentLineItem = Backbone.Model.extend({
    defaults: {
      amount: null,
      paymentType: null,
    },
  });

  App.Models.POS.CardPayment = App.Models.POS.Payment.extend({
    defaults: {
      paymentType: App.Models.POS.PaymentDiscriminators.CARD,
    },
    validate: function (attributes) {
      const errors = {};
      if (!attributes.cardType) {
        errors.cardType = 'You must select a card type';
      }
      if (isNaN(attributes.amount)) {
        errors.amount = 'Price must be a number';
      } else if (attributes.amount <= 0) {
        errors.amount = 'Price must be greater than 0';
      } else if (attributes.tips && attributes.tips > attributes.cashInDrawer) {
        errors.amount = 'NOT_ENOUGH_CASH_IN_DRAWER_FOR_TIPS';
      }
      if (!_.isEmpty(errors)) {
        return errors;
      }
      return undefined;
    },
  });

  App.Models.POS.CashPayment = App.Models.POS.Payment.extend({
    defaults: {
      paymentType: App.Models.POS.PaymentDiscriminators.CASH,
    },
    validate: function (attributes) {
      const errors = {};

      if (isNaN(attributes.amount)) {
        errors.amount = 'Price must be a number';
      } else if (attributes.amount <= 0) {
        errors.amount = 'Price must be greater than 0';
      }
      if (!_.isEmpty(errors)) {
        return errors;
      }
      return undefined;
    },
  });

  App.Models.POS.VoucherPayment = App.Models.POS.Payment.extend({
    defaults: {
      paymentType: App.Models.POS.PaymentDiscriminators.VOUCHER,
    },
    validate: function (attributes) {
      const errors = {};
      if (!attributes.voucherIssuer) {
        errors.voucherIssuer = 'You must select a voucher issuer';
      }
      if (isNaN(attributes.amount)) {
        errors.amount = 'Price must be a number';
      } else if (attributes.amount <= 0) {
        errors.amount = 'Price must be greater than 0';
      } else if (
        attributes.vatVoucherType === App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.SINGLE_VAT &&
        attributes.amount > attributes.outstandingSumToPay
      ) {
        errors.amount = 'CANT_LEAVE_TIP_WITH_VOUCHER';
      } else if (attributes.tips && attributes.tips > attributes.cashInDrawer) {
        errors.amount = 'NOT_ENOUGH_CASH_IN_DRAWER_FOR_TIPS';
      }
      if (attributes.vatVoucherType === App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.SINGLE_VAT) {
        const productsTaxRate = attributes.products.map((product) => product.get('taxRate'));
        const appointmentsTaxRate = attributes.appointments.map(
          (appointment) => appointment.taxRate,
        );
        const containsNonDefaultTaxRate = [...productsTaxRate, ...appointmentsTaxRate]
          .filter((taxRate) => taxRate != null)
          .some((taxRate) => taxRate !== App.getVenueStandartVat().rate);
        if (containsNonDefaultTaxRate) {
          errors.multiVatVoucherNotSupported = true;
        }
      }
      if (!_.isEmpty(errors)) {
        return errors;
      }
      return undefined;
    },
  });

  App.Models.POS.Tip = App.Models.POS.Payment.extend({
    validate: function (attributes) {
      const errors = {};

      if (isNaN(attributes.amount)) {
        errors.amount = 'Amount must be a number';
      } else if (attributes.amount <= 0) {
        errors.amount = 'Amount must be greater than 0';
      }
      if (!_.isEmpty(errors)) {
        return errors;
      }
      return undefined;
    },
  });

  App.Collections.POS.Discounts = Backbone.Collection.extend({
    model: App.Models.POS.Discount,
    getTotal: function () {
      return this.reduce(
        (total, discount) => total.plus(discount.get('amount')),
        new BigNumber(0),
      ).toNumber();
    },
  });

  App.Collections.POS.Products = Backbone.Collection.extend({
    model: App.Models.POS.Product,
    filterByType: function (type) {
      return this.filter((product) => product.get('type') === type);
    },

    getTotalDiscounted: function (type) {
      let collection = this;
      if (type) {
        collection = this.filterByType(type);
      }

      return collection
        .reduce((total, product) => total.plus(product.getTotalDiscounted()), new BigNumber(0))
        .toNumber();
    },
    getTotal: function (type) {
      let collection = this;
      if (type) {
        collection = this.filterByType(type);
      }

      return collection
        .reduce((total, product) => total.plus(product.getTotal()), new BigNumber(0))
        .toNumber();
    },

    /**
     * Get the previous total of all products
     */

    getPreviousTotal: function () {
      return this.reduce((total, product) => {
        if (product.hasChanged()) {
          const productPrice = new BigNumber(product.previous('quantity')).multipliedBy(
            product.previous('unitPrice'),
          );
          return total.plus(productPrice);
        }
        return total.plus(product.getTotal());
      }, new BigNumber(0)).toNumber();
    },

    /**
     * Get the total number of all products
     * (not just quantity of one type of product)
     * @return Number quantity
     */
    getTotalQuantity: function (type) {
      let collection = this;
      if (type) {
        collection = this.filter((product) => product.get('type') === type);
      }
      return collection.reduce((quantity, product) => quantity + product.get('quantity'), 0);
    },
  });

  App.Collections.POS.Payments = Backbone.Collection.extend({
    model: App.Models.POS.Payment,
    getTotal: function () {
      return this.reduce((total, payment) => {
        let amount;
        if (
          payment.get('paymentType') === App.Models.POS.PaymentDiscriminators.CARD ||
          payment.get('paymentType') === App.Models.POS.PaymentDiscriminators.VOUCHER
        ) {
          amount = payment.get('costAmount');
        } else {
          amount = payment.get('amount');
        }

        // amount could be undefined;
        return total.plus(amount || 0);
      }, new BigNumber(0)).toNumber();
    },

    getTotalCash: function () {
      return this.reduce((total, payment) => {
        if (payment.get('paymentType') === App.Models.POS.PaymentDiscriminators.CASH) {
          return total.plus(payment.get('amount'));
        }
        return total;
      }, new BigNumber(0)).toNumber();
    },

    getTotalVouchersWithVAT: function () {
      return this.filter(
        (payment) => payment.get('paymentType') === App.Models.POS.PaymentDiscriminators.VOUCHER,
      )
        .filter(
          (payment) =>
            payment.get('vatVoucherType') ===
            App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.SINGLE_VAT,
        )
        .reduce((total, payment) => total.plus(payment.get('costAmount')), new BigNumber(0))
        .toNumber();
    },

    getTotalVouchers: function () {
      return this.filter(
        (payment) => payment.get('paymentType') === App.Models.POS.PaymentDiscriminators.VOUCHER,
      )
        .filter(
          (payment) =>
            payment.get('vatVoucherType') !==
            App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.SINGLE_VAT,
        )
        .reduce((total, payment) => total.plus(payment.get('costAmount')), new BigNumber(0))
        .toNumber();
    },

    getTotalCard: function () {
      return this.reduce((total, payment) => {
        if (payment.get('paymentType') === App.Models.POS.PaymentDiscriminators.CARD) {
          return total.plus(payment.get('costAmount'));
        }
        return total;
      }, new BigNumber(0)).toNumber();
    },

    getPaymentsByType: function (type) {
      return this.filter((payment) => payment.get('paymentType') === type);
    },

    getTotalPaidSum: function () {
      return new BigNumber(this.getTotalCash())
        .plus(this.getTotalCard())
        .plus(this.getTotalVouchers())
        .toNumber();
    },

    /**
     * At the moment the API does not know anything about tips.
     * So we need to remove it from the model that is
     * being posted back. We do however, wish to capture the total value paid,
     * so add tip ontop of the final amount.
     * This should be superseded very soon and record the actual tip value.
     *
     * @returns {*}
     */

    getServicePayments: function () {
      const paymentsWithoutSingleVATVouchers = this.filter(
        (payment) =>
          payment.get('vatVoucherType') !==
          App.Views.POS.VoucherPaymentForm.VOUCHER_TYPES.SINGLE_VAT,
      );

      return paymentsWithoutSingleVATVouchers.map((payment) => {
        const clone = payment.clone();

        if (clone instanceof App.Models.POS.CardPayment && clone.has('tip')) {
          const amount = new BigNumber(clone.get('costAmount'))
            .plus(clone.get('tip').get('amount'))
            .toNumber();

          clone.set('amount', amount);
          clone.unset('tip');
        }

        return clone;
      });
    },
  });

  App.Collections.POS.Tips = Backbone.Collection.extend({
    model: App.Models.POS.Tip,
    getTotal: function () {
      return this.reduce(
        (total, tip) => total.plus(tip.get('amount')),
        new BigNumber(0),
      ).toNumber();
    },
  });
})();
