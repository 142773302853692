import { all, fork, takeLatest, takeEvery } from 'redux-saga/effects';

import {
  GET_VENUE_REQUEST,
  GET_VENUE_STATISTICS_REQUEST,
  GET_CUSTOMER_REVIEWS_REQUEST,
  CUSTOMER_INFO_REQUEST,
  CUSTOMER_INFO_SUBMIT,
  PUT_REVIEW_RESPONSE,
  GET_SUPPLIER_DETAILS_REQUEST,
  GET_DISCOUNT_RULE_REQUEST,
  PUT_DISCOUNT_RULE_REQUEST,
  ADD_DISCOUNT_RULE_REQUEST,
  REMOVE_DISCOUNT_RULE_REQUEST,
  GET_VENUE_DETAILS_REQUEST,
  GET_DISCOUNT_RULES_REQUEST,
  GET_TREATMENTS_REQUEST,
  GET_SALES_REPORT_REQUEST,
  GET_PRODUCT_SALES_REPORT_REQUEST,
  GET_EMPLOYEE_SALES_REPORT_REQUEST,
  GET_SERVICE_SALES_REPORT_REQUEST,
  GET_CHANNEL_SALES_REPORT_REQUEST,
  GET_MONTHLY_SALES_REPORT_REQUEST,
  GET_VAT_REPORT_REQUEST,
  GET_DAILY_SALES_REPORT_REQUEST,
  GET_VENUE_EMPLOYEES_REQUEST,
  GET_MENU_ITEMS_REQUEST,
  GET_BANK_DETAILS_REQUEST,
  GET_POS_STATUS_REQUEST,
  GET_SALES_SUMMARY_REPORT_REQUEST,
  GET_VENUE_MONTHLY_SALES_REPORT_REQUEST,
  GET_APPOINTMENT_NOTIFICATIONS_REQUEST,
  GET_WEBSOCKETS_MESSAGE_RECEIVED,
  GET_EMAIL_TEMPLATE_REQUEST,
  GET_SMART_DISCOUNTS_TEMPLATE_REQUEST,
  GET_SERVICE_ON_SALE_TEMPLATE_REQUEST,
  GET_APP_FOR_YOUR_CLIENTS_TEMPLATE_REQUEST,
  GET_BACK_TO_BUSINESS_TEMPLATE_REQUEST,
  GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_REQUEST,
  WIDGET_SELECT_LANGUAGE,
  WIDGET_GENERATE_CODE_REQUEST,
  WIDGET_TOGGLE_ENABLED_REQUEST,
  SEND_EMAIL_CAMPAIGN_REQUEST,
  GET_RETTENTION_REPORT_REQUEST,
  GET_PRODUCTS_LIST_REQUEST,
  GET_PRODUCT_ORDERS_REQUEST,
  CREATE_PRODUCT_ORDER_REQUEST,
  PRODUCT_ORDER_REQUEST,
  COMPLETE_PRODUCT_ORDER_REQUEST,
  CANCEL_PRODUCT_ORDER_REQUEST,
  EMPLOYEES_REQUEST,
  EMPLOYEES_EMPLOYEE_REQUEST,
  EMPLOYEES_SUBMIT_EMPLOYEE,
  EMPLOYEES_DELETE_EMPLOYEE,
  EMPLOYEES_EXTERNAL_CALENDAR_LINK,
  EMPLOYEES_EXTERNAL_CALENDAR_UNLINK,
  EMPLOYEES_PERMISSIONS_BY_ROLES_REQUEST,
  EMPLOYEES_CATEGORIES_REQUEST,
  GET_EXTERNAL_SALON_SYSTEMS_REQUEST,
  GET_EXTERNAL_SALON_INTEGRATION_REQUEST,
  GET_EXTERNAL_SALON_INTEGRATION_CREATE_REQUEST,
  GET_EXTERNAL_SALON_INTEGRATION_DELETE_REQUEST,
  GET_EXTERNAL_SALON_EMPLOYEES_REQUEST,
  GET_MASS_EMAIL_COUNT_REQUEST,
  GET_CLIENT_APP_SHARE_LINK_REQUEST,
  PRODUCT_SUPPLIERS_REQUEST,
  PRODUCT_SUPPLIERS_SUPPLIER_REQUEST,
  PRODUCT_SUPPLIERS_SUBMIT_SUPPLIER,
  PRODUCT_SUPPLIERS_SUBMIT_PRODUCTS_ASSIGNATION,
  PRODUCT_SUPPLIERS_DELETE_SUPPLIER,
  CLIENTS_BULK_DELETE_REQUEST,
  GET_CLIENT_ACQUISITION_REQUEST,
  GET_SMART_DISCOUNTS_REQUEST,
  GET_TREATMENT_TYPES_REQUEST,
  GET_SMART_DISCOUNTS_RECIPIENT_COUNT_REQUEST,
  GET_SERVICE_ON_SALE_RECIPIENT_COUNT_REQUEST,
  GET_APP_FOR_YOUR_CLIENTS_RECIPIENT_COUNT_REQUEST,
  SEND_SMART_DISCOUNTS_CAMPAIGN_REQUEST,
  SEND_SERVICE_ON_SALE_CAMPAIGN_REQUEST,
  GET_POS_REPORT_DATA_REQUEST,
  SEND_APP_FOR_YOUR_CLIENTS_CAMPAIGN_REQUEST,
  SEND_BACK_TO_BUSINESS_CAMPAIGN_REQUEST,
  GET_PARTNER_SITE_SETTINGS_REQUEST,
  SUBMIT_PARTNER_SITE_SETTINGS_REQUEST,
  VALIDATE_PARTNER_SITE_SUBDOMAIN_REQUEST,
  VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_REQUEST,
  FBE_UNINSTALL_REQUEST,
  FBE_STATUS_CHECK_REQUEST,
  FBE_BUSINESS_CONFIG_REQUEST,
  FBE_INTEGRATION_COMPLETE,
  FBE_INTEGRATION_ASSETS_REQUEST,
  FBE_REINTEGRATION_COMPLETE,
  INIT_STRIPE_REQUEST,
  MAKE_CARD_PAYMENT,
  VENUE_DETAILS_REQUEST,
  INVITE_TO_DOWNLOAD_APP_BY_SMS_REQUEST,
  CLIENTS_FILE_UPLOAD_REQUEST,
  CLIENTS_FILE_UPLOAD_SUCCESS,
  MAKE_IDEAL_PAYMENT,
  GET_LAST_PAYMENT_STATUS_REQUEST,
  GET_RANKING_BOOST_REQUEST,
  OPEN_WITHDRAWAL_DIALOG,
  OPEN_DEPOSIT_DIALOG,
  OPEN_DAY_CLOSED_NOTIFICATION_DIALOG,
  GET_SIGNATURE_REQUEST,
  DAILY_REPORT_REQUEST,
  GET_APPOINTMENT_AVAILABILITY_REQUEST,
  OFFER_DIALOG_SAVED,
  COVID_UNLIMIT_OPERATION_REQUEST,
  CORONA_REPORT_REQUEST,
  BACK_TO_BUSINESS_INIT_STATUS_REQUEST,
  VENUE_CLOSING_REQUEST,
  UPLOAD_VENUE_PHOTOS_REQUEST,
  VENUE_PHOTO_CROPPING_UPDATE_REQUEST,
} from 'reduxStore/actionsConst';

import {
  getVenueDetailsRequested,
  getVenueStatisticsRequested,
  getCustomerReviewsRequested,
  putResponseToCustomerReview,
  getSupplierDetailsRequested,
} from 'components/dashboard/sagas';

import {
  getSalesSummaryReportRequested,
  getMonthlySalesReport,
} from 'components/dashboard/VenueStats/sagas';

import { getRankingBoostRequested } from 'components/dashboard/Reviews/sagas';

import {
  getDiscountRuleDataRequested,
  putDiscountRuleDataRequested,
  addDiscountRuleDataRequested,
  removeDiscountRuleDataRequested,
  getVenueDetailsDataRequested,
} from 'components/menu/DiscountsTab/sagas';

import { offerDialogSaved } from 'components/menu/ServicesTab/store/sagas';

import { getDiscountRulesDataRequested } from 'components/menu/OfferPricing/DiscountRuleRow/sagas';

import { getTreatmentsDataRequested } from 'components/menu/SelectServiceDialog/store/sagas';

import {
  getSalesReportData,
  getProductSalesReportData,
  getEmployeeSalesReportData,
  getServiceSalesReportData,
  getChannelSalesReportData,
  getMonthlySalesReportData,
  getVatReportData,
  getDailySalesReportData,
  getVenueEmployeeData,
  getRetentionReportData,
  getAcquisitionReportData,
  getPosReportData,
} from 'components/reports/newSalesReports/sagas';

import { getMenuDataRequested } from 'components/menu/DiscountRuleDialog/TreatmentList/sagas';

import {
  getMenuGroup,
  setMenuGroup,
  deleteMenuGroup,
} from 'components/menu/MenuGroupModal/store/actions';
import {
  getMenuGroupRequested,
  setMenuGroupRequested,
  deleteMenuGroupRequested,
} from 'components/menu/MenuGroupModal/store/sagas';

import {
  requestMenuGroupTemplateRequested,
  setMenuServicesRequested,
} from 'components/menu/AddServicesModal/store/sagas';

import {
  getMenuGroupTemplate,
  setMenuGroupServices,
} from 'components/menu/AddServicesModal/store/actions';

import {
  getBankDetailsRequested,
  getLastPaymentStatus,
} from 'components/settings/PaymentMessage/sagas';

import {
  getEmailTemplateRequested,
  getSmartDiscountsTemplateRequested,
  getServiceOnSaleTemplateRequested,
  getAppForYourClientsTemplateRequested,
} from 'components/marketing/EmailCampaign/CampaignPreview/sagas';
import {
  getBackToBusinessTemplateRequested,
  getBackInBusinessInitStatusRequested,
} from 'components/marketing/EmailCampaign/BackToBusiness/sagas';

import { getPosStatusRequested } from 'reduxStore/common/posStatus/sagas';
import { getDailyReport } from 'reduxStore/common/dailyReport/sagas';

import getAppointmentNotificationsRequested from 'reduxStore/common/appointmentNotifications/sagas';

import {
  getEmailCampaignRecipientCount,
  getSmartDiscountsRecipientCountRequested,
  getServiceOnSaleRecipientCountRequested,
  getAppForYourClientsRecipientCountRequested,
  sendEmailCampaign,
  sendSmartDiscountsCampaignRequested,
  sendServiceOnSaleCampaignRequested,
  sendAppForYourClientsCampaignRequested,
  sendBackToBusinessCampaignRequested,
} from 'components/marketing/EmailCampaign/CampaignSummary/sagas';

import getMassEmailCountRequested from 'components/marketing/EmailCampaign/sagas';
import getClientAppShareLinkRequested from 'components/marketing/ClientApp/sagas';

import {
  changeWidgetLanguage,
  getWidgetEmbedCode,
  toggleWidgetEnabled,
} from 'components/settings/online/Widget/redux/sagas';

import getProductsListRequested from 'reduxStore/common/products/sagas';

import {
  getProductOrdersRequested,
  productOrderRequested,
  createNewOrderRequested,
  completeOrderRequested,
  cancelOrderRequested,
} from 'components/menu/ProductOrdersTab/sagas';

import {
  getEmployeesRequested,
  getEmployeeRequested,
  getEmployeeSubmitted,
  getEmployeeDeleted,
  getEmployeeExternalCalendarLinkRequested,
  getEmployeeExternalCalendarUnlinkRequested,
  getPermissionsByRolesRequested,
  getCategoriesRequested,
} from 'reduxStore/common/employees/sagas';

import {
  getExternalSalonSystemsRequested,
  getExternalSalonIntegrationRequested,
  getExternalSalonIntegrationCreateRequested,
  getExternalSalonIntegrationDeleteRequested,
  getExternalSalonEmployeesRequested,
} from 'components/settings/externalSalonIntegration/sagas';

import {
  getProductSuppliersRequested,
  getProductSupplierRequested,
  getProductSupplierSubmitted,
  getProductSupplierDeleted,
  getProductsAssignationSubmitted,
} from 'components/menu/ProductSuppliers/sagas';

import getClientsBulkDeleted from 'components/clients/ClientsBulkDelete/sagas';

import getSmartDiscountsRequested from 'components/marketing/EmailCampaign/CampaignSelection/PromotionalOffers/SmartDiscountSelect/sagas';

import getTreatmentTypesRequested from 'components/marketing/EmailCampaign/CampaignSelection/PromotionalOffers/ServiceOnSaleSelect/sagas';

import { getCustomerRequested, getCustomerSubmitted } from 'reduxStore/common/customers/sagas';

import {
  getPartnerSiteSettingsRequested,
  submitPartnerSiteSettingsRequested,
  validatePartnerSiteSubdomainRequested,
  validatePartnerSiteCustomDomainRequested,
} from 'components/marketing/PartnerSite/sagas';

import {
  businessConfigFBERequested,
  integrationFBECompleted,
  reintegrationFBECompleted,
} from 'components/marketing/FacebookBusinessExtension/InstallStatus/NotInstalled/sagas';
import { uninstallFBERequested } from 'components/marketing/FacebookBusinessExtension/InstallStatus/Installed/sagas';
import {
  checkFBEStatusRequested,
  fbeIntegrationAssetsRequested,
} from 'components/marketing/FacebookBusinessExtension/InstallStatus/sagas';

import {
  automatedMessagingSettingsRequested,
  setAutomatedMessagingSettingRequested,
  setReminderSettingsRequested,
} from 'components/marketing/AutomatedMessaging/sagas';

import {
  getVenueBusinessHoursRequested,
  getVenueWorkingHoursRequested,
  getEmployeesWorkingHoursRequested,
  getEmployeeMonthWorkingHoursRequested,
  getEmployeesScheduleRequested,
  getEmployeesDayHoursRequested,
  setEmployeesShiftRequested,
  setEmployeesOpenDayRequested,
  setEmployeesTimeOffRequested,
  invalidateEmployeesScheduleRequested,
  setEmployeesScheduleRequested,
  setVenueWorkingHoursRequested,
} from 'components/shifts/store/sagas';
import * as ShiftActions from 'components/shifts/store/actions';

import {
  postSetupStripe,
  postDoStripePayment,
  postDoIdealPayment,
} from 'components/settings/PaymentMessage/PaymentContainer/Stripe/sagas';
import {
  venueDetailsRequested,
  inviteToDownloadAppBySMSRequested,
} from 'components/calendar/UDVAppAdvocacyBanner/sagas';

import {
  clientsFileUploadRequested,
  processClientsFileImport,
} from 'components/clients/ClientsImport/sagas';

import {
  openWithdrawalDialog,
  openDepositDialog,
  openDayClosedNotificationDialog,
} from 'components/reports/transactions/RootTransactionDialog/sagas';
import { getSignatures } from 'components/reports/transactions/SignatureSearchModal/sagas';

import { getPosExports, initPosExport } from 'components/reports/ExportDialog/sagas';

import * as ExportActions from 'components/reports/ExportDialog/actions';

import { getAppointmentAvailabilityRequested } from 'components/calendar/CalendarEventEditor/store/sagas';
import { downloadCoronaReport } from 'components/reports/Reports/CoronaReportModal/sagas';

import {
  covidUnlimitOperationRequested,
  venueClosingRequested,
} from 'components/CovidBanner/sagas';

import { requestEnabledFeatures, featuresResolved } from 'common/trunk-features/store/sagas';
import { requestFeatures, requestFeaturesSuccess } from 'common/trunk-features/store/actions';
import {
  startPOSOperationRequest,
  abortPOSOperationRequestStart,
} from 'reduxStore/common/posOperation/actions';
import { startPOSOperation, abortPOSOperation } from 'reduxStore/common/posOperation/sagas';

import {
  automatedMessagingSettingsRequest,
  setAutomatedMessagingSettingRequest,
  setReminderSettingsRequest,
} from 'components/marketing/AutomatedMessaging/actions';
import {
  uploadVenuePhotosRequested,
  updateVenuePhotoCroppingRequested,
} from 'components/settings/VenueDetails/sagas';

export function* rootSaga() {
  yield all([
    // @ts-expect-error there's a misleading typing issue with forking.
    // used 'as any' in other places so that it would be possible to detect different errors
    fork(takeLatest, GET_VENUE_REQUEST, getVenueDetailsRequested),
    fork(takeLatest as any, GET_VENUE_STATISTICS_REQUEST, getVenueStatisticsRequested),
    fork(takeLatest as any, GET_SUPPLIER_DETAILS_REQUEST, getSupplierDetailsRequested),
    fork(takeLatest as any, GET_CUSTOMER_REVIEWS_REQUEST, getCustomerReviewsRequested),
    fork(takeLatest as any, CUSTOMER_INFO_REQUEST, getCustomerRequested),
    fork(takeLatest as any, CUSTOMER_INFO_SUBMIT, getCustomerSubmitted),
    fork(takeLatest as any, PUT_REVIEW_RESPONSE, putResponseToCustomerReview),
    fork(takeLatest as any, GET_DISCOUNT_RULE_REQUEST, getDiscountRuleDataRequested),
    fork(takeLatest as any, PUT_DISCOUNT_RULE_REQUEST, putDiscountRuleDataRequested),
    fork(takeLatest as any, ADD_DISCOUNT_RULE_REQUEST, addDiscountRuleDataRequested),
    fork(takeLatest as any, REMOVE_DISCOUNT_RULE_REQUEST, removeDiscountRuleDataRequested),
    fork(takeLatest as any, GET_VENUE_DETAILS_REQUEST, getVenueDetailsDataRequested),
    fork(takeLatest as any, GET_DISCOUNT_RULES_REQUEST, getDiscountRulesDataRequested),
    fork(takeEvery as any, OFFER_DIALOG_SAVED, offerDialogSaved),
    fork(takeLatest as any, GET_RETTENTION_REPORT_REQUEST, getRetentionReportData),
    fork(takeLatest as any, GET_TREATMENTS_REQUEST, getTreatmentsDataRequested),
    fork(takeLatest as any, GET_SALES_REPORT_REQUEST, getSalesReportData),
    fork(takeLatest as any, GET_PRODUCT_SALES_REPORT_REQUEST, getProductSalesReportData),
    fork(takeLatest as any, GET_EMPLOYEE_SALES_REPORT_REQUEST, getEmployeeSalesReportData),
    fork(takeLatest as any, GET_SERVICE_SALES_REPORT_REQUEST, getServiceSalesReportData),
    fork(takeLatest as any, GET_CHANNEL_SALES_REPORT_REQUEST, getChannelSalesReportData),
    fork(takeLatest as any, GET_MONTHLY_SALES_REPORT_REQUEST, getMonthlySalesReportData),
    fork(takeLatest as any, GET_RANKING_BOOST_REQUEST, getRankingBoostRequested),
    fork(takeLatest as any, GET_VAT_REPORT_REQUEST, getVatReportData),
    fork(takeLatest as any, GET_DAILY_SALES_REPORT_REQUEST, getDailySalesReportData),
    fork(takeLatest as any, GET_VENUE_EMPLOYEES_REQUEST, getVenueEmployeeData),
    fork(takeLatest as any, GET_MENU_ITEMS_REQUEST, getMenuDataRequested),
    fork(takeLatest as any, getMenuGroup.type, getMenuGroupRequested),
    fork(takeLatest as any, getMenuGroupTemplate.type, requestMenuGroupTemplateRequested),
    fork(takeLatest as any, setMenuGroupServices.type, setMenuServicesRequested),
    fork(takeLatest as any, setMenuGroup.type, setMenuGroupRequested),
    fork(takeLatest as any, deleteMenuGroup.type, deleteMenuGroupRequested),
    fork(takeLatest as any, GET_BANK_DETAILS_REQUEST, getBankDetailsRequested),
    fork(takeLatest as any, GET_POS_STATUS_REQUEST, getPosStatusRequested),
    fork(takeLatest as any, GET_SALES_SUMMARY_REPORT_REQUEST, getSalesSummaryReportRequested),
    fork(takeLatest as any, GET_VENUE_MONTHLY_SALES_REPORT_REQUEST, getMonthlySalesReport),
    fork(
      takeLatest as any,
      GET_APPOINTMENT_NOTIFICATIONS_REQUEST,
      getAppointmentNotificationsRequested,
    ),
    fork(takeLatest as any, GET_WEBSOCKETS_MESSAGE_RECEIVED, getAppointmentNotificationsRequested),
    fork(takeLatest as any, GET_EMAIL_TEMPLATE_REQUEST, getEmailTemplateRequested),
    fork(
      takeLatest as any,
      GET_SMART_DISCOUNTS_TEMPLATE_REQUEST,
      getSmartDiscountsTemplateRequested,
    ),
    fork(
      takeLatest as any,
      GET_SERVICE_ON_SALE_TEMPLATE_REQUEST,
      getServiceOnSaleTemplateRequested,
    ),
    fork(
      takeLatest as any,
      GET_APP_FOR_YOUR_CLIENTS_TEMPLATE_REQUEST,
      getAppForYourClientsTemplateRequested,
    ),
    fork(
      takeLatest as any,
      GET_BACK_TO_BUSINESS_TEMPLATE_REQUEST,
      getBackToBusinessTemplateRequested,
    ),
    fork(
      takeLatest as any,
      GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_REQUEST,
      getEmailCampaignRecipientCount,
    ),
    fork(takeLatest as any, SEND_EMAIL_CAMPAIGN_REQUEST, sendEmailCampaign),
    fork(takeLatest as any, GET_MASS_EMAIL_COUNT_REQUEST, getMassEmailCountRequested),
    fork(takeLatest as any, GET_CLIENT_APP_SHARE_LINK_REQUEST, getClientAppShareLinkRequested),
    fork(takeLatest as any, WIDGET_GENERATE_CODE_REQUEST, getWidgetEmbedCode),
    fork(takeLatest as any, WIDGET_TOGGLE_ENABLED_REQUEST, toggleWidgetEnabled),
    fork(takeLatest as any, WIDGET_SELECT_LANGUAGE, changeWidgetLanguage),
    fork(takeLatest as any, GET_PRODUCTS_LIST_REQUEST, getProductsListRequested),
    fork(takeLatest as any, GET_PRODUCT_ORDERS_REQUEST, getProductOrdersRequested),
    fork(takeLatest as any, PRODUCT_ORDER_REQUEST, productOrderRequested),
    fork(takeLatest as any, CREATE_PRODUCT_ORDER_REQUEST, createNewOrderRequested),
    fork(takeLatest as any, COMPLETE_PRODUCT_ORDER_REQUEST, completeOrderRequested),
    fork(takeLatest as any, CANCEL_PRODUCT_ORDER_REQUEST, cancelOrderRequested),
    fork(takeLatest as any, EMPLOYEES_REQUEST, getEmployeesRequested),
    fork(takeLatest as any, EMPLOYEES_EMPLOYEE_REQUEST, getEmployeeRequested),
    fork(takeLatest as any, EMPLOYEES_SUBMIT_EMPLOYEE, getEmployeeSubmitted),
    fork(takeLatest as any, EMPLOYEES_DELETE_EMPLOYEE, getEmployeeDeleted),
    fork(
      takeLatest as any,
      EMPLOYEES_EXTERNAL_CALENDAR_LINK,
      getEmployeeExternalCalendarLinkRequested,
    ),
    fork(
      takeLatest as any,
      EMPLOYEES_EXTERNAL_CALENDAR_UNLINK,
      getEmployeeExternalCalendarUnlinkRequested,
    ),
    fork(takeLatest as any, EMPLOYEES_PERMISSIONS_BY_ROLES_REQUEST, getPermissionsByRolesRequested),
    fork(takeLatest as any, EMPLOYEES_CATEGORIES_REQUEST, getCategoriesRequested),
    fork(takeLatest as any, GET_EXTERNAL_SALON_SYSTEMS_REQUEST, getExternalSalonSystemsRequested),
    fork(
      takeLatest as any,
      GET_EXTERNAL_SALON_INTEGRATION_REQUEST,
      getExternalSalonIntegrationRequested,
    ),
    fork(
      takeLatest as any,
      GET_EXTERNAL_SALON_INTEGRATION_DELETE_REQUEST,
      getExternalSalonIntegrationDeleteRequested,
    ),
    fork(
      takeLatest as any,
      GET_EXTERNAL_SALON_EMPLOYEES_REQUEST,
      getExternalSalonEmployeesRequested,
    ),
    fork(
      takeLatest as any,
      GET_EXTERNAL_SALON_INTEGRATION_CREATE_REQUEST,
      getExternalSalonIntegrationCreateRequested,
    ),
    fork(takeLatest as any, PRODUCT_SUPPLIERS_REQUEST, getProductSuppliersRequested),
    fork(takeLatest as any, PRODUCT_SUPPLIERS_SUPPLIER_REQUEST, getProductSupplierRequested),
    fork(takeLatest as any, PRODUCT_SUPPLIERS_SUBMIT_SUPPLIER, getProductSupplierSubmitted),
    fork(
      takeLatest as any,
      PRODUCT_SUPPLIERS_SUBMIT_PRODUCTS_ASSIGNATION,
      getProductsAssignationSubmitted,
    ),
    fork(takeLatest as any, PRODUCT_SUPPLIERS_DELETE_SUPPLIER, getProductSupplierDeleted),
    fork(takeLatest as any, GET_CLIENT_ACQUISITION_REQUEST, getAcquisitionReportData),
    fork(takeLatest as any, PRODUCT_SUPPLIERS_DELETE_SUPPLIER, getProductSupplierDeleted),
    fork(takeLatest as any, CLIENTS_BULK_DELETE_REQUEST, getClientsBulkDeleted),
    fork(takeLatest as any, GET_SMART_DISCOUNTS_REQUEST, getSmartDiscountsRequested),
    fork(takeLatest as any, GET_TREATMENT_TYPES_REQUEST, getTreatmentTypesRequested),
    fork(
      takeLatest as any,
      GET_SMART_DISCOUNTS_RECIPIENT_COUNT_REQUEST,
      getSmartDiscountsRecipientCountRequested,
    ),
    fork(
      takeLatest as any,
      GET_SERVICE_ON_SALE_RECIPIENT_COUNT_REQUEST,
      getServiceOnSaleRecipientCountRequested,
    ),
    fork(
      takeLatest as any,
      GET_APP_FOR_YOUR_CLIENTS_RECIPIENT_COUNT_REQUEST,
      getAppForYourClientsRecipientCountRequested,
    ),
    fork(
      takeLatest as any,
      SEND_SMART_DISCOUNTS_CAMPAIGN_REQUEST,
      sendSmartDiscountsCampaignRequested,
    ),
    fork(
      takeLatest as any,
      SEND_SERVICE_ON_SALE_CAMPAIGN_REQUEST,
      sendServiceOnSaleCampaignRequested,
    ),
    fork(takeLatest as any, GET_POS_REPORT_DATA_REQUEST, getPosReportData),
    fork(
      takeLatest as any,
      SEND_APP_FOR_YOUR_CLIENTS_CAMPAIGN_REQUEST,
      sendAppForYourClientsCampaignRequested,
    ),
    fork(
      takeLatest as any,
      SEND_BACK_TO_BUSINESS_CAMPAIGN_REQUEST,
      sendBackToBusinessCampaignRequested,
    ),
    fork(takeLatest as any, GET_PARTNER_SITE_SETTINGS_REQUEST, getPartnerSiteSettingsRequested),
    fork(
      takeLatest as any,
      SUBMIT_PARTNER_SITE_SETTINGS_REQUEST,
      submitPartnerSiteSettingsRequested,
    ),
    fork(
      takeLatest as any,
      VALIDATE_PARTNER_SITE_SUBDOMAIN_REQUEST,
      validatePartnerSiteSubdomainRequested,
    ),
    fork(
      takeLatest as any,
      VALIDATE_PARTNER_SITE_CUSTOM_DOMAIN_REQUEST,
      validatePartnerSiteCustomDomainRequested,
    ),
    fork(takeLatest as any, FBE_INTEGRATION_COMPLETE, integrationFBECompleted),
    fork(takeLatest as any, FBE_REINTEGRATION_COMPLETE, reintegrationFBECompleted),
    fork(takeLatest as any, FBE_UNINSTALL_REQUEST, uninstallFBERequested),
    fork(takeLatest as any, FBE_STATUS_CHECK_REQUEST, checkFBEStatusRequested),
    fork(takeLatest as any, FBE_BUSINESS_CONFIG_REQUEST, businessConfigFBERequested),
    fork(takeLatest as any, FBE_INTEGRATION_ASSETS_REQUEST, fbeIntegrationAssetsRequested),
    fork(
      takeEvery as any,
      setAutomatedMessagingSettingRequest.type,
      setAutomatedMessagingSettingRequested,
    ),
    fork(takeEvery as any, setReminderSettingsRequest.type, setReminderSettingsRequested),
    fork(
      takeLatest as any,
      automatedMessagingSettingsRequest.type,
      automatedMessagingSettingsRequested,
    ),
    fork(takeLatest as any, INIT_STRIPE_REQUEST, postSetupStripe),
    fork(takeLatest as any, MAKE_CARD_PAYMENT, postDoStripePayment),
    fork(takeLatest as any, MAKE_IDEAL_PAYMENT, postDoIdealPayment),
    fork(takeLatest as any, VENUE_DETAILS_REQUEST, venueDetailsRequested),
    fork(
      takeLatest as any,
      INVITE_TO_DOWNLOAD_APP_BY_SMS_REQUEST,
      inviteToDownloadAppBySMSRequested,
    ),
    // Shifts
    fork(
      takeLatest as any,
      ShiftActions.getVenueBusinessHours.type,
      getVenueBusinessHoursRequested,
    ),
    fork(takeLatest as any, ShiftActions.getVenueWorkingHours.type, getVenueWorkingHoursRequested),
    fork(
      takeLatest as any,
      ShiftActions.getEmployeesWorkingHours.type,
      getEmployeesWorkingHoursRequested,
    ),
    fork(
      takeLatest as any,
      ShiftActions.getEmployeeMonthWorkingHours.type,
      getEmployeeMonthWorkingHoursRequested,
    ),
    fork(takeLatest as any, ShiftActions.getEmployeesDay.type, getEmployeesDayHoursRequested),
    fork(takeLatest as any, ShiftActions.setEmployeesShift.type, setEmployeesShiftRequested),
    fork(takeLatest as any, ShiftActions.setEmployeesOpenDay.type, setEmployeesOpenDayRequested),
    fork(takeLatest as any, ShiftActions.setEmployeesTimeOff.type, setEmployeesTimeOffRequested),
    fork(takeLatest as any, ShiftActions.getEmployeesSchedule.type, getEmployeesScheduleRequested),
    fork(takeLatest as any, ShiftActions.setEmployeesSchedule.type, setEmployeesScheduleRequested),
    fork(
      takeLatest as any,
      ShiftActions.invalidateEmployeesSchedule.type,
      invalidateEmployeesScheduleRequested,
    ),
    fork(takeLatest as any, ShiftActions.setVenueWorkingHours.type, setVenueWorkingHoursRequested),
    fork(takeLatest as any, CLIENTS_FILE_UPLOAD_REQUEST, clientsFileUploadRequested),
    fork(takeLatest as any, CLIENTS_FILE_UPLOAD_SUCCESS, processClientsFileImport),
    fork(takeLatest as any, GET_LAST_PAYMENT_STATUS_REQUEST, getLastPaymentStatus),
    fork(takeLatest as any, GET_SIGNATURE_REQUEST, getSignatures),
    fork(takeLatest as any, ExportActions.initPosExport.type, initPosExport),
    fork(takeLatest as any, ExportActions.getPosExports.type, getPosExports),
    // Modals
    fork(takeLatest as any, OPEN_WITHDRAWAL_DIALOG, openWithdrawalDialog),
    fork(takeLatest as any, OPEN_DEPOSIT_DIALOG, openDepositDialog),
    fork(takeLatest as any, OPEN_DAY_CLOSED_NOTIFICATION_DIALOG, openDayClosedNotificationDialog),
    fork(takeLatest as any, DAILY_REPORT_REQUEST, getDailyReport),
    fork(
      takeLatest as any,
      BACK_TO_BUSINESS_INIT_STATUS_REQUEST,
      getBackInBusinessInitStatusRequested,
    ),
    fork(
      takeEvery as any,
      GET_APPOINTMENT_AVAILABILITY_REQUEST,
      getAppointmentAvailabilityRequested,
    ),
    fork(takeLatest as any, COVID_UNLIMIT_OPERATION_REQUEST, covidUnlimitOperationRequested),
    fork(takeEvery as any, CORONA_REPORT_REQUEST, downloadCoronaReport),
    fork(takeLatest as any, VENUE_CLOSING_REQUEST, venueClosingRequested),
    fork(takeLatest as any, requestFeatures.type, requestEnabledFeatures),
    fork(takeLatest as any, requestFeaturesSuccess.type, featuresResolved),
    fork(takeLatest as any, startPOSOperationRequest.type, startPOSOperation),
    fork(takeLatest as any, abortPOSOperationRequestStart.type, abortPOSOperation),
    fork(takeLatest as any, UPLOAD_VENUE_PHOTOS_REQUEST, uploadVenuePhotosRequested),
    fork(takeLatest as any, VENUE_PHOTO_CROPPING_UPDATE_REQUEST, updateVenuePhotoCroppingRequested),
  ]);
}
