import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';

import { CLIENT_APP_CAMPAIGN_CHANNEL_FEATURE } from 'components/marketing/chnlFeatures';
import { ClientsImportFinish as Component } from './ClientsImportFinish';
import { clientsImportReset, clientsImportToCampaignPrompt } from '../actions';

const mapStateToProps = ({ clients }) => ({
  importStatus: clients.clientsImport.importStatus,
  canAccessAppCampaign:
    App.isVenueListedOnMarketplace() &&
    Wahanda.Permissions.canAccessMarketingTools() &&
    App.isFeatureSupported(CLIENT_APP_CAMPAIGN_CHANNEL_FEATURE),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ clientsImportReset, clientsImportToCampaignPrompt }, dispatch),
});

export const ClientsImportFinish = connect(mapStateToProps, mapDispatchToProps)(Component);
