import React from 'react';
import { toClj } from 'mori';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';

import TrafficLights from './TrafficLights';

interface Props extends React.HTMLAttributes<Element> {
  appointmentOrders?: {};
  reviews?: {};
  thresholds?: {};
  menuData?: {};
  bankAccount?: {};
  requestVenueStatisticsAction: (...args: any[]) => any;
  requestSupplierDetailsAction: (...args: any[]) => any;
  venueId: number;
  supplierId: number;
}

export class Goals extends React.Component<Props> {
  public componentDidMount() {
    const { appointmentOrders, bankAccount } = this.props;
    /*
     *  If we don't have the data in the store yet, fetch it
     */
    if (!appointmentOrders) {
      const { requestVenueStatisticsAction, venueId } = this.props;
      requestVenueStatisticsAction(venueId);
    }
    if (!bankAccount) {
      const { requestSupplierDetailsAction, supplierId } = this.props;
      requestSupplierDetailsAction(supplierId);
    }

    NavigationAnalytics.trackPageEventView(L2Page.GOALS);
  }

  public render() {
    const { bankAccount, appointmentOrders, reviews, thresholds, menuData } = this.props;
    return (
      <TrafficLights
        appointmentOrders={toClj(appointmentOrders)}
        reviews={toClj(reviews)}
        menuData={toClj(menuData)}
        thresholds={toClj(thresholds)}
        bankAccount={toClj(bankAccount)}
      />
    );
  }
}
