import { xhr } from 'common/xhr';

/* global App Wahanda BackboneEx */
App.Views.Dashboard = BackboneEx.View.Main.extend({
  lastVenueId: null,

  initialize: function () {
    this.lastVenueId = App.getVenueId();
  },

  render: function () {
    App.headerView.setModule('dashboard');
    var dashboardNode = document.getElementById('home-holder');

    if (App.getVenueId() !== this.lastVenueId) {
      // Destroy the previous state, as we have a new venue
      App.ES6.Initializers.Dashboard.destroy(dashboardNode);
      this.lastVenueId = App.getVenueId();
    }

    App.ES6.Initializers.Dashboard.render(dashboardNode, App.config.toJSON(), {
      tab: (App.mainViewOptions || {}).tab,
    });

    this.openDialogsFromUrl();
  },

  openDialogsFromUrl: function () {
    if (App.mainViewOptions) {
      if (App.mainViewOptions.initVoucherReference) {
        this.showVoucherForm(App.mainViewOptions.initVoucherReference);
        delete App.mainViewOptions.initVoucherReference;
        window.location.hash = '';
      } else if (App.mainViewOptions.initialDialog) {
        switch (App.mainViewOptions.initialDialog.type) {
          case 'booking':
            this.openBookingDialog(App.mainViewOptions.initialDialog.id);
            break;
        }
        delete App.mainViewOptions.initialDialog;
        window.location.hash = '';
      }
    }
  },

  openBookingDialog: function (booking) {
    App.Views.Dialog.Booking.show(booking, {
      updateUrl: false,
    });
  },

  showVoucherForm: function (reference) {
    xhr
      .doJQueryAjax({
        url: App.Api.wsUrl(
          '/supplier/' + App.config.get('venue').supplierId + '/evoucher/find.json',
        ),
        data: { reference: reference },
        dataType: 'json',
        skipErrorHandling: true,
      })
      .success(function (data) {
        data.referenceCode = reference;
        var form = new App.Views.Calendar.VoucherRedemption({
          initialVoucher: data,
        });
        form.render();
        form.open();
      })
      .error(function (jqXHR) {
        switch (jqXHR.status) {
          case 403:
            Wahanda.Dialogs.Confirm(Wahanda.lang.home.voucherRelogin.question, function () {
              window.location = App.loginRedirectUrl();
            });
            break;
          case 404:
            Wahanda.Dialogs.Alert(Wahanda.lang.home.voucherRelogin.errors.voucherNotFound);
            break;
        }
      });
  },
});
