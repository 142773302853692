import React, { Fragment } from 'react';
import { generatePath } from 'react-router-dom';
import CampaignError from 'components/marketing/EmailCampaign/CampaignError';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import { List } from 'components/common/List';
import Loader from 'components/common/Loader';
import { LayoutContainer, LayoutHeader } from 'components/common/Layout';

import { EmailCampaignSummaryAnalytics as Analytics } from 'common/analytics';
import style from './CampaignSummary.scss';
import PATHS from '../../Router/paths';
import { APP_FOR_YOUR_CLIENTS_CAMPAIGN_ID, GENERIC_MARKETING_CAMPAIGN_ID } from '../CampaignList';
import {
  SMART_DISCOUNTS_PROMO_OFFER,
  SERVICE_ON_SALE_PROMO_OFFER,
} from '../CampaignSelection/PromotionalOffers/PromotionTypeSelect/promoTypes';

const lang = Wahanda.lang.marketing.audienceSummary;
interface ICampaignSummaryProps extends React.HTMLAttributes<Element> {
  selectedDiscountRuleId?: number;
  referrer?: string;
  selectedTreatmentGroupId?: number;
  minInterval: number;
  actions: {
    clearEmailCampaignError: (...args: any[]) => any;
    getEmailCampaignRecipientCountRequest: (...args: any[]) => any;
    getServiceOnSaleRecipientCountRequest: (...args: any[]) => any;
    getAppForYourClientsRecipientCountRequest: (...args: any[]) => any;
    sendEmailCampaignRequest: (...args: any[]) => any;
    getMassEmailCountRequest: (...args: any[]) => any;
    getSmartDiscountsRecipientCountRequest: (...args: any[]) => any;
    recipientCountReset: (...args: any[]) => any;
    sendSmartDiscountsCampaignRequest: (...args: any[]) => any;
    sendServiceOnSaleCampaignRequest: (...args: any[]) => any;
    sendAppForYourClientsCampaignRequest: (...args: any[]) => any;
  };
  history: {
    push: (...args: any[]) => any;
  };
  match: {
    params: {
      templateId: string;
    };
  };
  campaignSummary: {
    recipientCount?: number;
    error?: {
      type?: any;
    };
    isSent?: boolean;
    isSending?: boolean;
  };
  recipientCount?: any;
  params?: any;
  getServiceOnSaleRecipientCountRequest?: any;
  push?: any;
  getSmartDiscountsRecipientCountRequest?: any;
  clearEmailCampaignError?: any;
  recipientCountReset?: any;
  error?: any;
}
class CampaignSummary extends React.Component<ICampaignSummaryProps, {}> {
  constructor(props) {
    super(props);
    this.onErrorClose = this.onErrorClose.bind(this);
    this.onErrorRetry = this.onErrorRetry.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
  }

  public static defaultProps = {
    selectedDiscountRuleId: null,
    selectedTreatmentGroupId: null,
    referrer: undefined,
  };

  public componentDidMount() {
    Analytics.trackPageView(this.props.referrer);
    this.load();
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.campaignSummary.isSent) {
      this.onSuccess();
    } else if (this.props.campaignSummary.error !== nextProps.campaignSummary.error) {
      this.load();
    }
  }

  public componentWillUnmount() {
    this.props.actions.recipientCountReset();
  }

  public onErrorClose() {
    this.props.actions.clearEmailCampaignError();
  }

  public onErrorRetry() {
    this.onErrorClose();
    this.onNextClick();
  }

  public onBackClick() {
    Analytics.trackBackBtnClick();
    this.goToPreviewPage();
  }

  public onNextClick() {
    Analytics.trackNextBtnClick();
    const { templateId } = this.props.match.params;
    switch (templateId) {
      case SMART_DISCOUNTS_PROMO_OFFER:
        this.props.actions.sendSmartDiscountsCampaignRequest(this.props.selectedDiscountRuleId);
        break;
      case SERVICE_ON_SALE_PROMO_OFFER:
        this.props.actions.sendServiceOnSaleCampaignRequest(this.props.selectedTreatmentGroupId);
        break;
      case GENERIC_MARKETING_CAMPAIGN_ID:
        this.props.actions.sendEmailCampaignRequest();
        break;
      case APP_FOR_YOUR_CLIENTS_CAMPAIGN_ID:
        this.props.actions.sendAppForYourClientsCampaignRequest();
        break;
      default:
        break;
    }
  }

  public onSuccess() {
    const {
      match: {
        params: { templateId },
      },
    } = this.props;
    this.props.history.push(generatePath(PATHS.CAMPAIGN_SUCCESS, { templateId }));
  }

  public getSmartDiscountRecipientCount = () => {
    const { selectedDiscountRuleId } = this.props;
    if (!selectedDiscountRuleId) {
      this.props.history.push(generatePath(PATHS.CAMPAIGN_SELECTION));
      return;
    }
    this.props.actions.getSmartDiscountsRecipientCountRequest(selectedDiscountRuleId);
  };

  public getServiceOnSaleRecipientCount = () => {
    if (!this.props.selectedTreatmentGroupId) {
      this.props.history.push(generatePath(PATHS.CAMPAIGN_SELECTION));
      return;
    }
    this.props.actions.getServiceOnSaleRecipientCountRequest(this.props.selectedTreatmentGroupId);
  };

  public goToPreviewPage() {
    Analytics.trackNextBtnClick();
    const { templateId } = this.props.match.params;
    switch (templateId) {
      case SMART_DISCOUNTS_PROMO_OFFER:
        this.props.history.push(
          generatePath(PATHS.CAMPAIGN_PREVIEW_SMART_DISCOUNTS, {
            templateId,
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
            selectedDiscountRuleId: this.props.selectedDiscountRuleId,
          }),
        );
        break;
      case SERVICE_ON_SALE_PROMO_OFFER:
        this.props.history.push(
          generatePath(PATHS.CAMPAIGN_PREVIEW_SERVICE_ON_SALE, {
            templateId,
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
            selectedTreatmentGroupId: this.props.selectedTreatmentGroupId,
          }),
        );
        break;
      default:
        this.props.history.push(generatePath(PATHS.CAMPAIGN_PREVIEW_DEFAULT, { templateId }));
        break;
    }
  }

  public load() {
    const { templateId } = this.props.match.params;
    switch (templateId) {
      case SMART_DISCOUNTS_PROMO_OFFER:
        this.getSmartDiscountRecipientCount();
        break;
      case SERVICE_ON_SALE_PROMO_OFFER:
        this.getServiceOnSaleRecipientCount();
        break;
      case GENERIC_MARKETING_CAMPAIGN_ID:
        this.props.actions.getEmailCampaignRecipientCountRequest();
        break;
      case APP_FOR_YOUR_CLIENTS_CAMPAIGN_ID:
        this.props.actions.getAppForYourClientsRecipientCountRequest();
        break;
      default:
        break;
    }
  }

  public renderNextButton = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (this.props.campaignSummary.recipientCount > 0) {
      return <Button label={lang.buttons.forwardTitle} onClick={this.onNextClick} />;
    }
    return <Button label={lang.buttons.forwardTitle} disabled />;
  };

  public render() {
    const { error, recipientCount, isSending } = this.props.campaignSummary;
    const { minInterval } = this.props;
    const isLoading = (isSending || recipientCount === undefined) && !error;
    return (
      <Fragment>
        {isLoading && <Loader positionAbsolute />}

        {error && <CampaignError onRetry={this.onErrorRetry} onClose={this.onErrorClose} />}

        {!isLoading && (
          <LayoutContainer
            footer={
              <div className={style.buttons}>
                <Button
                  label={lang.buttons.backwardTitle}
                  variant="secondary"
                  colour="plain"
                  onClick={this.onBackClick}
                />
                {this.renderNextButton()}
              </div>
            }
          >
            <LayoutHeader header={lang.title} subheader={lang.introduction} />

            <div className={style.criteria}>
              <p>{lang.criteriaIntroduction}</p>
              <List type="ticksTeal" extraSpacing>
                {[
                  lang.criteria.emailAddressPresent,
                  lang.criteria.optedInToMarketing,
                  Wahanda.Template.render(lang.criteria.haveNotBookedRecently, { minInterval }),
                ]}
              </List>
            </div>

            <p>{lang.recipientCount}</p>
            <p className={style.recipientCount}>{recipientCount}</p>
          </LayoutContainer>
        )}
      </Fragment>
    );
  }
}

export { CampaignSummary };
