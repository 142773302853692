/* global Backbone BackboneEx */
BackboneEx.View.CalendarTooltip = Backbone.View.extend({
  template: null,
  minTypeElHeight: 19,
  positionFix: {
    left: 7,
    top: -30
  },

  initialize: function initialize() {
    const el = $($(`#${this.template}`).html());
    el.attr('id', this.cid);
    this.setElement(el);
    // Caching jQuery objects
    this.$arrow = this.$('.arrow');
    this.$infoTable = this.$('table.sal-hint-info');
  },

  render: function render() {},

  // eslint-disable-next-line no-unused-vars
  renderData: function renderData(calEvent, block) {},

  /**
   * Position this view according to the event element.
   *
   * @param jQuery $event
   */
  position: function position($event) {
    // Moving the element out of view, but so it will be possible to calculate the width & height
    this.$el.css('top', -9999).show();

    const tdPosition = $event.closest('td').position();
    const elemPosition = $event.position();

    let arrowTop = parseInt(this.$arrow.css('top', '').css('top'), 10);
    const eventPos = $event.position();
    const eventWidth = $event.outerWidth();
    let posX =
      tdPosition.left + eventWidth + this.positionFix.left + eventPos.left;
    let posY = elemPosition.top + this.positionFix.top;

    const width = this.$el.outerWidth();
    const height = this.$el.outerHeight();
    const typeElHeight = this.$el.find('.js-appt-type').outerHeight();

    let cls = 'sal-event-info-right';
    const $grid = $event.closest('.wc-scrollable-grid');
    const gridScrollTop = $grid.scrollTop();
    const gridScrollLeft = $grid.scrollLeft();
    let diff;

    if (posX + width >= $grid.width() + gridScrollLeft) {
      posX -=
        eventWidth +
        width +
        this.positionFix.left +
        $event.find('.wc-status').width();
      cls = 'sal-event-info-left';
    }

    if (posY < gridScrollTop) {
      // If event's top is higher than the viewport
      diff = gridScrollTop - posY;
      diff = Math.min(diff, $event.height());
      posY += diff;
    } else if (posY + height >= $grid.height() + gridScrollTop) {
      // If event is to the bottom of viewable area
      const originalY = posY;
      diff = posY + height - ($grid.height() + gridScrollTop);
      diff = Math.min(diff, this.$infoTable.height());
      posY -= diff;
      posY = Math.max(posY, gridScrollTop);
      arrowTop += originalY - posY;
    } else if (typeElHeight > 0 && typeElHeight > this.minTypeElHeight) {
      const typeDiff = typeElHeight - this.minTypeElHeight;
      posY -= typeDiff;
      arrowTop += typeDiff;
    }

    this.$el
      .removeClass('sal-event-info-right sal-event-info-left')
      .addClass(cls)
      .css({ left: posX, top: posY });

    this.$arrow.css('top', arrowTop);

    this.$el.addClass(cls);
  },

  hide: function hide() {
    this.$el.hide();
  },

  /**
   * Is this element attached to DOM?
   *
   * @return boolean
   */
  isAttached: function isAttached() {
    return $(`#${this.cid}`).length > 0;
  }
});
