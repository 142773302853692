import { xhr } from 'common/xhr';

/**
 * Booking model.
 */
App.Models.Booking = BackboneEx.Model.Silent.extend({
  defaults: {
    venueId: null,
  },

  STATUS_UNCONFIRMED: 'UC',
  STATUS_CONFIRMED: 'CN',
  STATUS_CANCELLED: 'CC',

  initialize() {},

  url() {
    const venueId = this.get('venueId') || App.getVenueId();
    return App.Api.wsUrl(`/venue/${venueId}/booking/${this.id}.json`);
  },

  getFullDeliveryAddress() {
    let address;
    const delivery = this.get('delivery');

    if (delivery) {
      address = delivery.address || '';
      if (delivery.addressPostalRef) {
        // eslint-disable-next-line eqeqeq
        if (address != '') {
          address += '\n';
        }
        address += delivery.addressPostalRef;
      }
      if (delivery.addressCountry) {
        // eslint-disable-next-line eqeqeq
        if (address != '') {
          address += '\n';
        }
        address += delivery.addressCountry;
      }
      return address;
    }
    return '';
  },

  /**
   * Confirms the booking.
   *
   * @param Object options OPTIONAL Object with possible params:
   * > success - success callback
   * > error - error callback
   */
  confirm(options) {
    this.ajaxStatusCall(
      App.Api.wsUrl(`/venue/${App.getVenueId()}/booking/${this.id}/confirm.json`),
      {
        options,
        event: 'booking:confirmed',
      },
    );
  },

  /**
   * Rejects the booking.
   *
   * @param Object options OPTIONAL Object with possible params:
   * > success - success callback
   * > error - error callback
   * > reason - the reason for this rejection
   */
  reject(options) {
    this.ajaxStatusCall(
      App.Api.wsUrl(`/venue/${App.getVenueId()}/booking/${this.id}/reject.json`),
      {
        options,
        data: JSON.stringify({ reason: options.reason }),
        event: 'booking:rejected',
      },
    );
  },

  ajaxStatusCall(url, params) {
    const options = params.options || {};
    const self = this;

    xhr.doJQueryAjax({
      url,
      success() {
        // TODO: refactor to pass parameters in one object
        App.trigger(params.event, self.id, {
          type: self.get('serviceTypeCode'),
        });
        if (_.isFunction(options.success)) {
          options.success();
        }
      },
      error: options.error,
      type: 'POST',
      contentType: 'application/json',
      data: params.data,
    });
  },

  isUnconfirmed() {
    return this.get('bookingStatus') === this.STATUS_UNCONFIRMED;
  },

  isConfirmed() {
    return this.get('bookingStatus') === this.STATUS_CONFIRMED;
  },

  isAppointmentUnconfirmed() {
    const appt = this.get('appointment') || {};
    return appt.appointmentStatusCode === 'CR';
  },

  isCancelled() {
    return this.get('bookingStatus') === this.STATUS_CANCELLED;
  },

  isAppointmentInThePast() {
    const appt = this.get('appointment');
    if (!appt) {
      return false;
    }
    try {
      const date = Wahanda.Date.createDate(appt.appointmentDate, appt.endTime);
      return date.getTime() < new Date().getTime();
    } catch (e) {
      return false;
    }
  },

  isDated() {
    return this.get('bookingType') === 'DT';
  },

  isSpaBreak() {
    return this.get('serviceTypeCode') === 'O';
  },

  isSpaDay() {
    return this.get('serviceTypeCode') === 'Y';
  },

  isAppointment() {
    return !!this.get('appointment');
  },

  isPaidByClient() {
    return this.isPrepaid();
  },

  isPayAtVenue() {
    // eslint-disable-next-line yoda, eqeqeq
    return true == this.get('payAtVenue');
  },

  isEvoucher() {
    return this.get('serviceTypeCode') === 'SV';
  },

  isPaymentProtectionApplied() {
    return this.isAppointment() && this.getAppointmentModel().isPaymentProtectionApplied();
  },

  /**
   * Check if this booking is currently unpaid.
   *
   * If is appointment, delegates to it's checks.
   *
   * @param Object options
   *   > onlyPayAtVenue (boolean, optional) Should only payAtVenue appointments be marked as unpaid? Currently relevant only for appointments.
   *
   * @return boolean
   */
  isUnpaid(options) {
    if (this.isAppointment()) {
      return this.getAppointmentModel().isUnpaid(options);
    }
    // Unpaid === (date >= today && payAtVenue)
    if (!this.isPayAtVenue()) {
      return false;
    }
    const date = this.getCheckinDate();
    const today = Wahanda.Date.createVenueDate();
    const result = Wahanda.Date.compare(date, today);
    // Check in date should be today or later.
    return result <= 0;
  },

  isPrepaid() {
    return !this.isUnpaid();
  },

  getAppointmentModel() {
    if (!this.isAppointment()) {
      throw new Error('Tried getting Appointment model of a non-appointment booking');
    }
    return new App.Models.Appointment(this.get('appointment'));
  },

  getAppointmentBelongsToGroup() {
    if (!this.isAppointment()) {
      return false;
    }
    return this.getAppointmentModel().belongsToGroup();
  },

  getStatusText() {
    const text = Wahanda.lang.bookingDialog.dated.statuses[this.get('bookingStatus')];
    return text || '';
  },

  getTypeString() {
    if (this.get('dated')) {
      return 'dated';
    }
    if (this.get('evouchers')) {
      return 'evoucher';
    }
    return 'appointment';
  },

  getStatusClass() {
    switch (this.get('bookingStatus')) {
      case 'UC':
        return 'status-not-confirmed';
      case 'CN':
        return 'status-confirmed';
      case 'CC':
        return 'status-cancelled';
      case 'RJ':
        return 'status-rejected';
      default:
        return '';
    }
  },

  getDatedDate() {
    const dated = this.get('dated');
    if (!dated) {
      return null;
    }
    return Wahanda.Date.createDate(dated.itemDate);
  },

  getAmountText() {
    const amount = this.get('itemOriginalAmount');
    if (amount) {
      return Wahanda.Currency.format(amount);
    }
    return '';
  },

  /**
   * Returns the checkin and (if available) checkout dates.
   *
   * @return { checkin, checkout }
   */
  getVisitDateRange() {
    return {
      checkin: this.getCheckinDate(),
      checkout: this.getCheckoutDate(),
    };
  },

  getCheckinDate() {
    const dated = this.get('dated');
    if (dated) {
      return Wahanda.Date.createDate(dated.itemDate, dated.checkinTime);
    }
    return null;
  },

  getCheckoutDate() {
    const dated = this.get('dated');
    if (dated && dated.numberOfNights > 0) {
      const checkin = this.getCheckinDate();
      let checkout = Wahanda.Date.addDaysToDate(checkin, dated.numberOfNights);
      checkout.setHours(0);
      checkout.setMinutes(0);
      checkout = Wahanda.Date.addMinutesToDate(
        checkout,
        Wahanda.Time.timeToMinutes(dated.checkoutTime || '00:00'),
      );
      return checkout;
    }
    return null;
  },

  getCancellationReason() {
    return this.get('cancellationReason');
  },

  /**
   * Return lead guest name for a dated booking.
   *
   * @return String or null
   */
  getLeadGuestName() {
    const dated = this.get('dated');
    if (!dated) {
      return null;
    }
    return (
      _.find(dated.guests, function (itm) {
        return itm.leadGuest;
      }) || {}
    ).guestName;
  },

  getDateCreated() {
    return Wahanda.Date.parse(this.get('created'));
  },

  getOfferName() {
    return this.get('itemName');
  },
});
