App.Views.Partials.FormCheckboxList = Backbone.View.extend({
  events: {
    'change input': 'toggleRow',
  },

  /**
   * Data must be in this format:
   *
   * {
   *   elementName - name of the input element
   *   list: [
   *     {
   * 	     id   - id of the item
   *       name - item name to display
   *       on   - (optional) Should the item be marked as active
   *     }
   *   ]
   * }
   * @param {Object} data
   */
  render: function (data) {
    this.$el.html(Wahanda.Template.renderTemplate('form-checkbox-list', data));
  },

  // UI event handling

  toggleRow: function (event) {
    var $input = $(event.currentTarget);
    var checked = $input.prop('checked');
    $input.closest('li').toggleClass('on', checked).toggleClass('off', !checked);
  },
});
