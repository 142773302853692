import App from 'common/backbone-app';
import { setFeatures } from 'common/trunk-features/store/actions';
import { setMenuGroupServicesSuccess } from 'components/menu/AddServicesModal/store/actions';
import {
  archiveMenuGroup,
  deleteMenuGroupSuccess,
  setMenuGroupSuccess,
} from 'components/menu/MenuGroupModal/store/actions';
import {
  invalidateEmployeesScheduleSuccess,
  setVenueWorkingHoursSuccess,
  setEmployeesScheduleSuccess,
  setEmployeesTimeOffSuccess,
  setEmployeesOpenDaySuccess,
  setEmployeesShiftSuccess,
  resetShiftModal,
} from 'components/shifts/store/actions';

import {
  CUSTOMER_DUPLICATE_SET_DATA,
  CUSTOMER_INFO_SUBMIT_SUCCESS,
  OFFER_DIALOG_USE_PROCESSING_TIME,
  OFFER_DIALOG_CHANGE_USE_EMPLOYEE_CATEGORIES,
  EMPLOYEE_WORKING_HOURS_TOGGLE,
  GET_APPOINTMENT_AVAILABILITY_RECEIVE,
  VENUE_CLOSING_SUCCESS,
} from '../../actionsConst';
import { getIsNewCustomer } from '../../common/customers/selectors';

export function legacyEventMiddleware(store) {
  return (next) => (action) => {
    const groupId = action.payload && action.payload.id;

    switch (action.type) {
      case CUSTOMER_INFO_SUBMIT_SUCCESS:
      case CUSTOMER_DUPLICATE_SET_DATA:
        if (action.data) {
          const isNew = getIsNewCustomer(store.getState());
          const model = new App.Models.Customer(action.data);
          App.trigger(Wahanda.Event.CUSTOMER_SAVED, model, {
            isNew,
          });
        }
        break;
      case OFFER_DIALOG_USE_PROCESSING_TIME:
        App.trigger(Wahanda.Event.CHANGE_USE_PROCESSING_TIME, {
          isEnabled: action.payload,
        });
        break;
      case OFFER_DIALOG_CHANGE_USE_EMPLOYEE_CATEGORIES:
        App.trigger(Wahanda.Event.PRICING_TYPE_CHANGE, {
          isEnabled: action.use,
        });
        break;
      case setEmployeesShiftSuccess.type:
      case setEmployeesOpenDaySuccess.type:
      case setEmployeesTimeOffSuccess.type:
      case setEmployeesScheduleSuccess.type:
      case setVenueWorkingHoursSuccess.type:
      case invalidateEmployeesScheduleSuccess.type:
        if (action.payload) {
          App.trigger(Wahanda.Event.CALENDAR_WORK_TIME_SAVED, {
            date: action.payload.date,
          });
        }
        App.trigger(Wahanda.Event.CALENDAR_REQUEST_REFRESH);
        break;
      case resetShiftModal.type:
        App.trigger(Wahanda.Event.SHIFT_MODAL_CLOSE);
        break;
      case EMPLOYEE_WORKING_HOURS_TOGGLE:
        App.trigger(Wahanda.Event.EMPLOYEE_WORKING_HOURS_TOGGLE, action.payload);
        break;
      case GET_APPOINTMENT_AVAILABILITY_RECEIVE:
        App.trigger(Wahanda.Event.GET_APPOINTMENT_AVAILABILITY_RECEIVE, action.payload);
        break;
      case VENUE_CLOSING_SUCCESS:
        App.trigger(Wahanda.Event.COVID_VENUE_CLOSED);
        break;
      case setMenuGroupServicesSuccess.type:
      case setMenuGroupSuccess.type:
        App.trigger(Wahanda.Event[groupId ? 'MENU_GROUP_CREATED' : 'MENU_GROUP_UPDATED'], {
          groupId,
        });
        break;
      case deleteMenuGroupSuccess.type:
        App.trigger(Wahanda.Event.MENU_GROUP_DELETED);
        break;
      case archiveMenuGroup.type:
        App.trigger(Wahanda.Event.MENU_GROUP_ARCHIVED);
        break;
      case setFeatures.type:
        App.trigger(Wahanda.Event.APP_FEATURES_LOADED, action.payload);
        break;
      default:
        break;
    }
    return next(action);
  };
}
