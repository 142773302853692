import React from 'react';
import style from './UDVCovidFormLink.scss';
import { Direction, GoTo } from 'components/common/GoTo';
import { UDVCovidFormLinkAnalytics } from './tracking';
import { useAppointmentCovidForm } from './hooks';
import { Tooltip } from 'components/common/Tooltip';
import { Badge } from 'components/common/Badge';
import { CovidFormLinkStatus } from './types';
import Wahanda from 'common/wahanda';

const lang = Wahanda.lang.UDVCovidFormLink;

interface Props {
  isVisible: boolean;
  customerId?: string;
  appointmentDate: string;
}

export const UDVCovidFormLink = ({ isVisible, customerId, appointmentDate }: Props) => {
  const { isLoading, isError, data } = useAppointmentCovidForm({
    isVisible,
    customerId,
    appointmentDate,
  });

  if (isLoading || isError || !isVisible || !data) {
    return null;
  }

  const { uri, status } = data;

  const badgeByStatus = () => {
    let tooltipText: string, badge: JSX.Element;
    switch (status) {
      case CovidFormLinkStatus.SENT:
        tooltipText = lang.sent.help;
        badge = <Badge text={lang.sent.badge} type="muted" />;
        break;
      case CovidFormLinkStatus.CANT_BE_SENT:
        tooltipText = lang.cantBeSent.help;
        badge = <Badge text={lang.cantBeSent.badge} type="alert" />;
        break;
      case CovidFormLinkStatus.COMPLETED:
        tooltipText = lang.completed.help;
        badge = <Badge text={lang.completed.badge} type="success" />;
        break;
      case CovidFormLinkStatus.SCHEDULED:
      default:
        tooltipText = lang.scheduled.help;
        badge = <Badge text={lang.scheduled.badge} type="muted" />;
        break;
    }

    return (
      <Tooltip placement="top" tooltip={tooltipText}>
        {badge}
      </Tooltip>
    );
  };

  return (
    <div className={style.container}>
      <div className={style.inner}>
        <div className={style.title}>
          <div className={style.text}>{lang.title}</div>
          {badgeByStatus()}
        </div>

        <GoTo
          className={style.arrow}
          onClick={() => UDVCovidFormLinkAnalytics.trackUDVCovidFormLinkClick()}
          dataTest={'link-to-form'}
          href={uri}
          direction={Direction.forward}
          noRouter
          newTab
        >
          {status === CovidFormLinkStatus.COMPLETED ? lang.viewForm : lang.completeForm}
        </GoTo>
      </div>
    </div>
  );
};
