import React from 'react';
import _ from 'common/underscore';
import App from 'common/backbone-app';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';
import 'react-dates/initialize';
import {
  DateRangePicker,
  DayPickerRangeControllerShape,
  FocusedInputShape,
  DateRangePickerShape,
  SingleDatePickerShape,
} from 'react-dates';

import { Button } from 'components/common/Button';
import { Chevron } from 'components/common/Icon';
import { PRESET_DATE, PresetRanges } from './presetDateRangePropTypes';
import styleLegacy from './DatePickerWrapper/DatePickerWrapper.scss';
import styleNewLook from './DatePickerWrapper/DatePickerWrapper.newLook.scss';
import classnames from 'classnames';

type DefaultInnerDatePickerProps = Partial<DateRangePickerShape> & Partial<SingleDatePickerShape>;

const dateRangePickerLang = Wahanda?.lang?.shared?.dateRangePicker;

const defaultInnerDateRangePickerProps: DefaultInnerDatePickerProps = {
  // input related props
  startDatePlaceholderText: dateRangePickerLang?.startDate || 'Start date',
  endDatePlaceholderText: dateRangePickerLang?.endDate || 'End date',
  disabled: false,
  required: false,
  readOnly: false,
  screenReaderInputMessage: '',
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: undefined,
  customArrowIcon: <span className={styleLegacy.ArrowIcon}>&ndash;</span>,
  customCloseIcon: undefined,
  block: false,
  small: false,
  regular: false,

  // calendar presentation and interaction related props
  renderMonthText: undefined,
  orientation: 'horizontal',
  anchorDirection: 'left',
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: undefined,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,
  verticalSpacing: 0,

  // navigation related props
  navPrev: (
    <Button icon={<Chevron className={styleLegacy.prev} />} colour="plain" variant="hollow" />
  ),
  navNext: <Button icon={<Chevron />} colour="plain" variant="hollow" />,
  onPrevMonthClick: () => undefined,
  onNextMonthClick: () => undefined,
  onClose: () => undefined,

  // day presentation and interaction related props
  weekDayFormat: 'ddd',
  renderCalendarDay: undefined,
  daySize: 40,
  minimumNights: 0,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isDayHighlighted: () => false,

  // internationalization
  displayFormat: () =>
    App.config.momentDateFormat?.defaultDate || App.config.get('momentDateFormat').defaultDate,
  monthFormat: 'MMMM YYYY',
};

const defaultInnerNewDateRangePickerProps: DefaultInnerDatePickerProps = {
  customArrowIcon: <span className={styleNewLook.ArrowIcon}></span>,
  numberOfMonths: 1,
  verticalSpacing: 20,
};

export interface DatePickerProps {
  uniqueId: string;
  initialStartDate: moment.Moment | null;
  initialEndDate: moment.Moment | null;
  monthlyGranularity?: boolean;
  labelStartDate?: string;
  labelEndDate?: string;
  presetRanges?: PresetRanges | null;
  readOnly?: boolean;
  focusedInput?: FocusedInputShape;
  onChange: (start: moment.Moment, end: moment.Moment, type?: PRESET_DATE) => void;
  startDateOffset?: (day: any) => any;
  endDateOffset?: (day: any) => any;
  maxRangeDays?: number;
  numberOfMonths?: number;
  isDayBlocked?: (day: moment.Moment) => boolean;
  newLook?: boolean;
}

interface State {
  endDate: DatePickerProps['initialEndDate'];
  startDate: DatePickerProps['initialStartDate'];
  focusedInput: FocusedInputShape;
}

// @types/react-dates looks out of date
type DatePickerType = React.ClassicComponentClass<
  DateRangePickerShape & DayPickerRangeControllerShape
>;

export class DatePicker extends React.Component<DatePickerProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: props.focusedInput || (null as any),
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
    };
  }

  public static defaultProps = {
    onChange: () => undefined,
    monthlyGranularity: false,
    readOnly: false,
    labelStartDate: null,
    labelEndDate: null,
    presetRanges: null,
    startDateOffset: undefined,
    endDateOffset: undefined,
    newLook: false,
  };

  public componentDidUpdate = (prevProps: DatePickerProps) => {
    if (
      [
        prevProps.initialStartDate,
        prevProps.initialEndDate,
        this.props.initialEndDate,
        this.props.initialStartDate,
      ].every((date) => date == null)
    ) {
      return;
    }

    if (
      !moment(prevProps.initialStartDate ? prevProps.initialStartDate : undefined).isSame(
        this.props.initialStartDate ? this.props.initialStartDate : undefined,
      ) ||
      !moment(prevProps.initialEndDate ? prevProps.initialEndDate : undefined).isSame(
        this.props.initialEndDate ? this.props.initialEndDate : undefined,
      )
    ) {
      this.onChange({
        startDate: this.props.initialStartDate,
        endDate: this.props.initialEndDate,
      });
    }
  };

  private onChange = ({ startDate, endDate }) => {
    const granularStartDate = this.props.monthlyGranularity
      ? startDate.clone().startOf('month')
      : startDate;
    const nonNullEndDate = endDate || startDate;
    const granularEndDate = this.props.monthlyGranularity
      ? nonNullEndDate.clone().endOf('month')
      : nonNullEndDate;

    this.setState({ startDate: granularStartDate, endDate: granularEndDate });
  };

  private onClose = ({ startDate, endDate, type = null }) => {
    const granularEndDate = this.props.monthlyGranularity
      ? endDate.clone().endOf('month')
      : endDate;

    let dateType;
    if (type == null) {
      dateType = PRESET_DATE.CUSTOM;
    } else {
      dateType = type;
    }

    this.props.onChange(startDate, granularEndDate, dateType);
  };

  private onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  };

  private selectDateRange = (dateRange) => {
    if (!this.props.presetRanges) {
      return;
    }

    const { startDate, endDate } = this.props.presetRanges[dateRange].getDates();
    const { type } = this.props.presetRanges[dateRange];

    this.onChange({ startDate, endDate });
    this.onClose({ startDate, endDate, type });
    // Setting the focused component to null closes the dropdown
    this.onFocusChange(null);
  };

  private renderDayContents = (date) => <div>{date.format('D')}</div>;

  private renderPresetOptions = (style) => {
    const buttons = _.map(this.props.presetRanges, (value, key) => {
      const onClick = () => this.selectDateRange(key);

      return (
        <button type="button" key={key} onClick={onClick}>
          {value.buttonText}
        </button>
      );
    });

    if (buttons.length === 0) {
      return <></>;
    }

    return <div className={style.Presets}>{buttons}</div>;
  };

  private isOutsideRange = (day: moment.Moment): boolean => {
    const { focusedInput, startDate } = this.state;
    const { maxRangeDays } = this.props;

    if (!startDate) {
      return false;
    }

    if (!maxRangeDays || focusedInput === 'startDate') {
      return false;
    }

    const rangeLimitDay = startDate.clone().add(maxRangeDays, 'days').formatApiDateString();
    // When endDate is selected, disable dates before and after allowed range
    return !day.isBetween(startDate, rangeLimitDay, 'day', '[)');
  };

  public render() {
    const { labelStartDate, labelEndDate } = this.props;
    const style = this.props.newLook ? styleNewLook : styleLegacy;

    const Picker = DateRangePicker as DatePickerType;
    const defaultProps = {
      ...defaultInnerDateRangePickerProps,
      ...(this.props.newLook ? defaultInnerNewDateRangePickerProps : {}),
    };
    return (
      <div className={style.datePicker}>
        <div>
          {(labelStartDate || labelEndDate) && (
            <div className={style.labels}>
              <div className={style.label}>{labelStartDate}</div>
              <div className={classnames(style.label, style.labelRight)}>{labelEndDate}</div>
            </div>
          )}
          <Picker
            {...defaultProps}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onDatesChange={this.onChange}
            onClose={this.onClose}
            focusedInput={this.state.focusedInput}
            onFocusChange={this.onFocusChange}
            startDateId={`${this.props.uniqueId}_startDate`}
            endDateId={`${this.props.uniqueId}_endDate`}
            firstDayOfWeek={App.config.getFirstDayOfWeek()}
            renderDayContents={this.renderDayContents}
            renderCalendarInfo={() => this.renderPresetOptions(style)}
            hideKeyboardShortcutsPanel
            readOnly={this.props.readOnly}
            startDateOffset={this.props.startDateOffset}
            endDateOffset={this.props.endDateOffset}
            isOutsideRange={this.isOutsideRange}
            numberOfMonths={this.props.numberOfMonths}
            isDayBlocked={this.props.isDayBlocked}
          />
        </div>
      </div>
    );
  }
}
