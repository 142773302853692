import { call, put } from 'redux-saga/effects';
import apiUrl from 'common/api-url';
import * as api from 'reduxStore/apiService';
import {
  requestSalesSummaryReportReceiveAction,
  requestMonthlySalesReportReceiveAction,
} from './actions';

export function* getSalesSummaryReportRequested(params) {
  const { venueId, from, to } = params;
  const data = yield call(api.get, apiUrl('DASHBOARD_REPORTS', { venueId, from, to }));
  yield put(requestSalesSummaryReportReceiveAction(data));
}

export function* getMonthlySalesReport(params) {
  const { from, to, venueId } = params;
  const data = yield call(api.get, apiUrl('MONTHLY_SALES_REPORT', { venueId, from, to }));
  yield put(requestMonthlySalesReportReceiveAction(data));
}
