/**
 * Collection for App.Models.MenuOffer.
 */
App.Collections.MenuOffers = BackboneEx.Collection.Base.extend({
  model: App.Models.MenuOffer,
  urlTemplate: null,
  serviceColours: {},

  /**
   * Filters out offers by group id, and an additional matcher function, if passed.
   *
   * @param int groupId
   * @param function additionalMatcher OPTIONAL
   * @return Array
   */
  getByGroupId: function (groupId, additionalMatcher) {
    var runMatcher = _.isFunction(additionalMatcher);
    return this.filter(function (offer) {
      return groupId === offer.get('groupId') && (runMatcher ? additionalMatcher(offer) : true);
    });
  },

  add: function (offers) {
    this.serviceColours = _.reduce(
      offers,
      function (accumulator, offer) {
        return _.extend(accumulator, _.object([offer.id], [offer.connectDisplayColour]));
      },
      this.serviceColours || {},
    );

    return BackboneEx.Collection.Base.prototype.add.call(this, offers);
  },

  getColourFor: function (offerId) {
    return this.serviceColours[offerId];
  },
});
