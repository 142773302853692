;(function($) {
	/**
	 * Menu.
	 * 
	 * The HTML is as follows:
	 * 
	 * <div class="">
	 *   <div class="filter-current">...</div> <!-- the header -->
	 *   <ul class="filter-ddown"> <!-- List of dropdown items -->
	 *     <li>Element 1</li>
	 *     <li class="active">Element 2</li>
	 *     ...
	 *   </div>
	 * </div>
	 * 
	 * When a <li> node is clicked
	 * 
	 * The menu triggers these events:
	 *   select.menu When a menu item is selected
	 *   open.menu   When the menu is opened
	 *   hide.menu   When the menu is hidden
	 * 
	 * Copyright Wahanda.com
	 */
	$.fn.wMenu = function(optsOrCmd, cmdOpts) {
		var menu = this.data('menu');
		if (!menu || !menu[optsOrCmd]) {
			// No menu instance defined. Create new one.
			this.data(
				'menu',
				new Menu(this, $.extend({}, $.fn.wMenu.defaults, optsOrCmd || {}))
			);
		} else {
			// Menu instance defined, so run a command against it.
			menu[optsOrCmd](cmdOpts);
		}
		return this;
	};
	
	/**
	 * The menu object constructor.
	 * 
	 * @param jQuery node The menu container
	 * @param Object options
	 * > (string or jQuery node) selected OPTIONAL The initially selected value. Can be a jQuery selector, or a jQuery
	 *		object.
	 */
	function Menu(node, options) {
		this.menu    = node;
		this.options = options;
		
		this.current = node.find('.filter-current');
		this.list    = node.find('.filter-ddown').hide();
		
		this.bind();
		
		if (options.selected) {
			this.select(options.selected.jquery ? options.selected : this.menu.find(options.selected));
			delete options.selected;
		}
	};
	
	Menu.prototype.bind = function() {
		var self = this;
		this.current.on('click.menu', function() {
			if (self.isOpen()) {
				self.hide();
			} else {
				self.open();
			}
		});
		this.list.on('click.menu', 'li', function() {
			self.select($(this), true);
		});
		$('body').on('click.menu keydown.menu', function(event) {
			if (event.type === "click") {
				// Ignore click on the "current menu item" element
				if (!self.current.is(event.target) && self.current.has(event.target).length === 0) {
					self.hide();
				}
			} else if (event.type === "keydown" && event.keyCode === 27) {
				self.hide();
			}
		});
	};
	
	Menu.prototype.isOpen = function() {
		return this.list.is(':visible');
	};
	
	Menu.prototype.open = function() {
		this.list.show();
		this.menu.trigger('open.menu');
	};
	
	Menu.prototype.hide = function() {
		this.list.hide();
		this.menu.trigger('hide.menu');
	};
	
	/**
	 * Destroys all menu functionality on the DOM.
	 */
	Menu.prototype.destroy = function() {
		this.current.off('.menu');
		this.list.off('.menu');
		$('body').off('.menu');
		
		this.menu.removeData('menu');
		
		this.menu = this.current = this.list = null;
	};
	
	/**
	 * @param jQuery node The selected node
	 * @param boolean trigger OPTIONAL Trigger events
	 */
	Menu.prototype.select = function(node, trigger) {
		if (!node) {
			return;
		}
		if (!node.hasClass(this.options.activeClass)) {
			this.list.find('li.' + this.options.activeClass).removeClass(this.options.activeClass);
			node.addClass(this.options.activeClass);
			if (trigger) {
				this.menu.trigger('select.menu', node);
			}
			// Pass rendering of the title to a overridale function
			this.options.currentElementRenderer.call(this, node);
		}
	};
	
	/**
	 * Menu defaults.
	 */
	$.fn.wMenu.defaults = {
		/**
		 * Renders the "currently selected" element of the menu. Can be overriden.
		 * 
		 * @param jQuery node The newely selected node
		 * @scope `this` points to the menu object
		 */
		currentElementRenderer: function(node) {
			this.current.html(node.html());
		},
		/**
		 * Class name for the active LI in menu list.
		 */
		activeClass: "on"
	};
}(jQuery));
