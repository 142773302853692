import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class MoneyCoins extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-money-coins', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M4,21 C1.790861,21 0,19.209139 0,17 C0,14.790861 1.790861,13 4,13 C6.209139,13 8,14.790861 8,17 C8,19.209139 6.209139,21 4,21 Z M4,19 C5.1045695,19 6,18.1045695 6,17 C6,15.8954305 5.1045695,15 4,15 C2.8954305,15 2,15.8954305 2,17 C2,18.1045695 2.8954305,19 4,19 Z M5,11 L3,11 L3,5.00292933 C3,3.89890472 3.89047747,3 4.99339768,3 L21.0066023,3 C22.1082892,3 23,3.90415942 23,5.00247329 L23,14.9975267 C23,16.102097 22.1088293,17 21.0044225,17 L15,17 L15,15 L21.0008149,15 C21.0004078,14.999853 21.0001362,11.6673441 21,5.00247329 C21,5.00253767 15.6661472,5.00171324 4.99844173,5 L5,11 Z M13,14 C11.2374077,14 10,12.1438884 10,10 C10,7.85611157 11.2374077,6 13,6 C14.7625923,6 16,7.85611157 16,10 C16,12.1438884 14.7625923,14 13,14 Z M13,12 C13.4465467,12 14,11.1698201 14,10 C14,8.83017993 13.4465467,8 13,8 C12.5534533,8 12,8.83017993 12,10 C12,11.1698201 12.5534533,12 13,12 Z M9.29289322,3.29289322 L10.7071068,4.70710678 L4.70710678,10.7071068 L3.29289322,9.29289322 L9.29289322,3.29289322 Z M21.2928932,9.29289322 L22.7071068,10.7071068 L16.7071068,16.7071068 L15.2928932,15.2928932 L21.2928932,9.29289322 Z M10,23 C7.790861,23 6,21.209139 6,19 C6,16.790861 7.790861,15 10,15 C12.209139,15 14,16.790861 14,19 C14,21.209139 12.209139,23 10,23 Z M10,21 C11.1045695,21 12,20.1045695 12,19 C12,17.8954305 11.1045695,17 10,17 C8.8954305,17 8,17.8954305 8,19 C8,20.1045695 8.8954305,21 10,21 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(MoneyCoins);
