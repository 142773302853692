import { connect } from 'react-redux';
import { ReopenPending as ReopenPendingComponent } from './ReopenPending';
import { Closed as ClosedComponent } from './Closed';
import { showVenueClosingDialog, showVenueOpenConfirmationDialog } from '../actions';

const mapStateToProps = ({ covidBanner }) => {
  return {
    unlimitLoading: covidBanner.unlimitLoading,
  };
};

const mapDispatchToProps = {
  showVenueClosingDialog,
  showVenueOpenConfirmationDialog,
};

export const ReopenPending = connect(mapStateToProps, mapDispatchToProps)(ReopenPendingComponent);

export const Closed = connect(null, { showVenueClosingDialog })(ClosedComponent);
