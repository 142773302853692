/* eslint-disable */
/*
 * All domain related functionality goes here.
 */
(function () {
  /**
   * Return the current content channel the user is being served.
   *
   * @return {String} The Content channel.
   */
  App.domainChannelCode = function getContentChannel() {
    return App.LOCALE_TO_CHANNEL_CODE_MAP[App.domainLocale] || 'WHN_GB';
  };

  App.venueChannelCode = function getVenueContentChannelCode() {
    return App.config.get('venue').contentChannelCode || 'WHN_GB';
  };

  App.isSalonmeister = function isSalonmeister() {
    var salonmeisterChannelArray = ['WHN_DE', 'WHN_AT', 'WHN_CH'];

    if ($.inArray(App.domainChannelCode(), salonmeisterChannelArray) != -1) {
      return true;
    } else {
      return false;
    }
  };

  App.isZensoon = function isZensoon() {
    return App.domainChannelCode() === 'WHN_FR';
  };

  function verifyMobileDomain(domain) {
    if (/\.net$/.test(domain)) {
      var currentHost = location.hostname;
      // Allow using the Mobile Domain only if we are currently on it.
      return /connect\.treatwell\.net/.test(currentHost);
    }
    return true;
  }

  App.getDomainFromLocale = function getDomainFromLocale(locale) {
    var domain;

    function isTWDomain(domainToTest) {
      return /treatwell/i.test(domainToTest);
    }

    // Search for the domain name, prioritising the Treatwell domains
    _.any(App.DOMAIN_TO_LOCALE_MAP, function (dLocale, dDomain) {
      if (dLocale === locale) {
        domain = dDomain;
      }
      // If it's a TW domain, don't search for others
      // Uness it's a mobile .net domain.
      if (isTWDomain(domain) && verifyMobileDomain(domain)) {
        // Don't search for others
        return true;
      }
    });

    return domain;
  };

  App.getDomainForChannel = function getDomainForChannel(channelCode) {
    var locale = App.getLocaleForChannelCode(channelCode);
    return App.getDomainFromLocale(locale);
  };

  App.getLocaleForChannelCode = function getLocaleForChannelCode(channelCode) {
    var locale;
    _.any(App.CHANNEL_CODE_TO_LOCALE_MAP, function (channelLocale, localeChannelCode) {
      if (channelCode === localeChannelCode) {
        locale = channelLocale;
        return true;
      }
    });

    return locale;
  };

  App.isValidContentChannel = function isValidContentChannel(channelCode) {
    return !!App.getLocaleForChannelCode(channelCode);
  };

  function getInfix(domain) {
    var infix;

    _.any(App.DOMAIN_TO_LOCALE_MAP, function (locale, domainTemplate) {
      var regexp = '^c(?:onnect)?\\.' + domainTemplate.replace('.', '\\.') + '(.*)$';
      var r = new RegExp(regexp, 'i');
      var match = r.exec(domain);

      if (match) {
        infix = match[1];
        return true;
      }
    });
    return infix;
  }

  function formatContentChannelWithPrefix(channelCode, prefix) {
    var cCode =
      !channelCode || !App.isValidContentChannel(channelCode)
        ? App.domainChannelCode()
        : channelCode;

    var infix = getInfix(document.location.hostname);
    var domainTemplate = App.getDomainForChannel(cCode);

    return 'https://' + prefix + '.' + domainTemplate + (infix ? infix : '');
  }

  App.getContentChannelUrl = function getContentChannelUrl(channelCode) {
    return formatContentChannelWithPrefix(channelCode, 'connect');
  };

  App.getPortalRootUrl = function getPortalRootUrl(channelCode) {
    return formatContentChannelWithPrefix(channelCode, 'www');
  };

  App.getFullConnectRootUrl = function getFullConnectRootUrl(channelCode) {
    return App.getContentChannelUrl(channelCode);
  };

  App.getMaiaRootUrl = function (channelCode) {
    var infix = getInfix(document.location.hostname) || '';

    return 'https://maia.treatwell.net' + infix;
  };

  App.getWebSocketsUrl = () => {
    const infix = getInfix(document.location.hostname) || '';

    return `wss://connect.treatwell.net${infix}:3050`;
  };
})();
