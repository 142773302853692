import { trackEvent, ACTIONS } from 'common/analytics';

export enum ServiceEditLabel {
  AddPricingOption = 'add-pricing-option',
  RemovePricingOption = 'remove-pricing-option',
  AddDiscount = 'add-discount',
  RemoveDiscount = 'remove-discount',
  ServiceName = 'service-name',
  SkuName = 'sku-name',
  SkuPrice = 'sku-price',
  SkuDiscountPrice = 'sku-sale-price',
  SkuDuration = 'sku-duration',
  FinishingTime = 'sku-finishing-time',
  GoodToKnow = 'good-to-know',
  Description = 'description',
  Restrictions = 'restrictions',
  CleanUpTime = 'cleanup-time',
  ProcessingTime = 'processing-time',
}

export class ServicesAnalytics {
  public static CATEGORY = 'services';

  public static trackServiceEdit(serviceId: number, label: ServiceEditLabel) {
    trackEvent(ServicesAnalytics.CATEGORY, ACTIONS.UPDATE, label, serviceId);
  }
}
