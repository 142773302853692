import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class ColumnExpand extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-column-expand', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 16 16"
      >
        <g fillRule="nonzero">
          <path d="M6.41 11.59L2.83 8l3.58-3.59L5 3 0 8l5 5zM9.59 11.59L13.17 8 9.59 4.41 11 3l5 5-5 5z" />
        </g>
      </svg>
    );
  }
}

export const icon = iconWrapper(ColumnExpand);
