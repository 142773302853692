export function formatFuse(results) {
  return results.map(({ item, matches }) => {
    const formattedMatches = matches.reduce((acc, curr) => {
      if (curr.key.includes('.')) {
        return acc;
      }

      acc[curr.key] = indicesToMatches(curr);
      return acc;
    }, {});

    return {
      ...item,
      ...formattedMatches,
    };
  });
}

export function indicesToMatches({ indices, value }) {
  if (indices.length === 0) {
    return value;
  }

  return value
    .split('')
    .reduce((acc, curr, i) => {
      return [...acc, { text: curr, matches: isInRanges(indices, i) }];
    }, [])
    .reduce((acc, curr, i) => {
      if (i === 0) {
        return [curr];
      }

      if (curr.matches !== acc[acc.length - 1].matches) {
        return [...acc, curr];
      }

      const lastItem = acc.pop();
      lastItem.text += curr.text;
      return [...acc, lastItem];
    }, []);
}

function isInRanges(ranges, index) {
  return ranges.some(([start, end]) => index >= start && index <= end);
}
