import React, { PureComponent } from 'react';
import { assocIn, hashMap, get, curry } from 'mori';
import { appState } from 'state';
import Wahanda from 'common/wahanda';
import {
  openSettlementReportPdf,
  openDrawerCorrectionPdf,
} from 'components/reports/transactions/day-status/settlement-report';
import { DialogConfirmation } from 'components/common/dialog/DialogConfirmation';

interface Props extends React.HTMLAttributes<Element> {
  text?: string;
  afterDayClosedFn?: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
}

export default class POSPreviousDayNotClosedModal extends PureComponent<Props> {
  static defaultProps = {
    afterDayClosedFn: null,
    text: null,
  };

  onDayClose = ({
    dialogClosed,
    dayClosed,
    date,
    showReport,
    showCorrectionReceipt,
    correctionReceiptId,
  }) => {
    const { afterDayClosedFn } = this.props;
    if (dialogClosed && dayClosed && afterDayClosedFn != null) {
      afterDayClosedFn();
    }
    if (showReport) {
      openSettlementReportPdf(date);
    }
    if (showCorrectionReceipt) {
      openDrawerCorrectionPdf(correctionReceiptId);
    }
  };

  onClick = (event, closeConfirmDialog) => {
    if (!Wahanda.Permissions.managePos()) {
      closeConfirmDialog();
      return;
    }
    const posStatus = get(appState.deref(), 'pos-status');
    const date = get(posStatus, 'openDay');
    appState.swap(
      curry(assocIn, ['pos', 'close-day'], hashMap('date', date, 'callback', this.onDayClose)),
    );
    closeConfirmDialog();
  };

  getText = () => {
    if (!Wahanda.Permissions.managePos()) {
      return Wahanda.lang.posRestrictions.noPermissions.text;
    }
    const { text } = this.props;
    if (text) {
      return text;
    }
    return Wahanda.lang.posRestrictions.prevDayOpenNoCheckout.text;
  };

  getButtonText = () => {
    if (!Wahanda.Permissions.managePos()) {
      return Wahanda.lang.posRestrictions.noPermissions.action;
    }
    return Wahanda.lang.posRestrictions.prevDayOpenDropdown.button;
  };

  render() {
    const { onClose } = this.props;
    return (
      <DialogConfirmation
        dataTest="previous-day-not-closed-confirmation-modal"
        title={Wahanda.lang.posRestrictions.prevDayOpenNoCloseDay.title}
        text={this.getText()}
        buttonTitle={this.getButtonText()}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(event: any, closeConfirmDialog: any) => voi... Remove this comment to see the full error message
        onButtonClick={this.onClick}
        onClose={onClose}
      />
    );
  }
}
