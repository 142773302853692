import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Wahanda from 'common/wahanda';
import * as actions from './actions';
import { ExportDialogRedesigned as Component } from './ExportDialogRedesigned';
import { isFeatureEnabled } from 'common/trunk-features/store/selectors';

const mapStateToProps = (state) => {
  return {
    canExportNF525: Wahanda.Permissions.canExportNF525(),
    canExportDSFINVK:
      Wahanda.Permissions.canExportDSFINVK() && isFeatureEnabled(state, 'pos-de-tss'),
    canExportGDPDU: Wahanda.Permissions.canExportGDPDU(),
    status: state.transactionsExport.status,
    exports: state.transactionsExport.posExports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ExportDialogRedesigned = connector(Component);
