import React, { useEffect, useRef } from 'react';
import { Notice } from 'components/common/Notice';
import { Button } from 'components/common/Button';
import App from 'common/backbone-app';

export interface BannerProps {
  message: string;
  buttonLabel: string;
  actionButtonUrl: string;
  openInNewTab: boolean;
}

export const KycBanner: React.FunctionComponent<BannerProps> = (props) => {
  const { message, buttonLabel, actionButtonUrl, openInNewTab } = props;
  const kycBannerRef = useRef<HTMLDivElement>(null);

  const setContentHolderStyles = () => {
    const contentHolder = document.querySelector<HTMLDivElement>('.content-holder');
    const header = document.querySelector<HTMLDivElement>('#header');
    const covidBanner = document.querySelector<HTMLDivElement>('#covid-banner-wrapper');
    const headerHeight = header?.offsetHeight || 0;
    const bannerHeight = kycBannerRef.current?.offsetHeight || 0;
    const covidBannerHeight = covidBanner?.offsetHeight || 0;
    const isApp = App.isApp(window.location, navigator.userAgent);

    if (contentHolder && !isApp) {
      contentHolder.style.transform = `translateY(${kycBannerRef.current?.offsetHeight || 0}px)`;
      contentHolder.style.height = `calc(100% - ${
        headerHeight + bannerHeight + covidBannerHeight
      }px)`;
    }
  };

  const removeContentHolderStyles = () => {
    const contentHolder = document.querySelector<HTMLDivElement>('.content-holder');

    if (contentHolder) {
      contentHolder.style.transform = '';
      contentHolder.style.height = '';
    }
  };

  useEffect(() => {
    setContentHolderStyles();
    window.addEventListener('resize', setContentHolderStyles);

    return () => {
      window.removeEventListener('resize', setContentHolderStyles);
      removeContentHolderStyles();
    };
  }, []);

  return (
    <div ref={kycBannerRef}>
      <Notice type={'expose'} message={message}>
        <div style={{ margin: '0 1rem' }}>
          <Button
            label={buttonLabel}
            size={'small'}
            onClick={() => window.open(actionButtonUrl, openInNewTab ? '_blank' : '_self')}
          />
        </div>
      </Notice>
    </div>
  );
};
