import {
  GET_APPOINTMENT_NOTIFICATIONS_REQUEST,
  GET_APPOINTMENT_NOTIFICATIONS_RECEIVE,
  GET_APPOINTMENT_NOTIFICATIONS_FAILURE,
  SET_APPOINTMENT_NOTIFICATIONS_VISIBLE,
  GET_APPOINTMENT_NOTIFICATIONS_RESET,
} from 'reduxStore/actionsConst';

export const initialState = {
  serverError: false,
  loading: false,
  visible: false,
};

export default function appointmentNotifications(state = initialState, action) {
  switch (action.type) {
    case GET_APPOINTMENT_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_APPOINTMENT_NOTIFICATIONS_RECEIVE:
      return {
        ...state,
        items: action.data,
        loading: false,
      };

    case GET_APPOINTMENT_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        serverError: true,
        loading: false,
      };

    case SET_APPOINTMENT_NOTIFICATIONS_VISIBLE:
      return {
        ...state,
        visible: action.visible,
      };

    case GET_APPOINTMENT_NOTIFICATIONS_RESET:
      return initialState;

    default:
      return state;
  }
}
