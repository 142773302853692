import {
  CUSTOMER_INFO_REQUEST,
  CUSTOMER_INFO_REQUEST_SUCCESS,
  CUSTOMER_INFO_REQUEST_FAILURE,
  CUSTOMER_INFO_SET_DATA,
  CUSTOMER_INFO_SUBMIT,
  CUSTOMER_INFO_SUBMIT_SUCCESS,
  CUSTOMER_INFO_SUBMIT_FAILURE,
  CUSTOMER_INFO_RESET,
} from 'reduxStore/actionsConst';

export const initialState = {
  customer: {
    active: true,
    birthDay: null,
    birthMonth: null,
    birthYear: null,
    emailAddress: null,
    gender: null,
    history: null,
    id: null,
    isWithinVenueRetentionPeriod: null,
    lastAppointmentCommissionType: null,
    locale: null,
    name: null,
    notes: '',
    phone: '',
    prepaymentRequired: false,
    sendMassEmails: false,
  },
  isLoaded: false,
  loading: false,
  submitting: false,
};

export default function customer(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        customer: {
          ...state.customer,
          id: action.id,
        },
      };

    case CUSTOMER_INFO_SET_DATA:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.data,
        },
      };

    case CUSTOMER_INFO_SUBMIT:
      return {
        ...state,
        loading: true,
        submitting: true,
      };

    case CUSTOMER_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        customer: action.data,
        isLoaded: true,
        loading: false,
      };

    case CUSTOMER_INFO_SUBMIT_SUCCESS:
      return {
        ...state,
        customer: action.data,
        isLoaded: true,
        loading: false,
        serverError: null,
        submitting: false,
      };

    case CUSTOMER_INFO_SUBMIT_FAILURE:
      return {
        ...state,
        serverError: action.data || null,
        loading: false,
        submitting: false,
      };

    case CUSTOMER_INFO_REQUEST_FAILURE:
      return {
        ...state,
        serverError: action.data || null,
        loading: false,
      };

    case CUSTOMER_INFO_RESET:
      return initialState;

    default:
      return state;
  }
}
