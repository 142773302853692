/**
 * Collection for venue images.
 */
App.Collections.BookingsEnquiries = BackboneEx.Collection.UrlFilter.extend({
  model: App.Models.Booking,
  urlTemplate: '/venue/{{venueId}}/bookings-enquiries.json',

  filters: {
    arrivalDateFrom: null,
    arrivalDateTo: null,
    dateFrom: null,
    dateTo: null,
    entity: null,
    type: null,
  },
  filterMap: {
    arrivalDateFrom: 'arrival-date-from',
    arrivalDateTo: 'arrival-date-to',
    dateFrom: 'date-from',
    dateTo: 'date-to',
    entity: 'entity',
    type: 'type',
  },

  parse: function (data) {
    // TODO not sure this is the way!
    //   Concerns:
    //   * why the backend cannot return the json as the frontend needs it?
    return data.map(function (item) {
      return item.booking;
    });
  },
});
