import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { KycBanner } from 'components/KycBanner/oldBanner';

interface Params {
  node: HTMLElement;
}

export const KycOldBannerInitializer = (params: Params): Initializer => ({
  destroy: () => {
    document.getElementsByTagName('body')[0].classList.remove('kyc');

    ReactDOM.unmountComponentAtNode(params.node);
  },
  render: () => {
    document.getElementsByTagName('body')[0].classList.add('kyc');
    ReactDOM.render(<KycBanner />, params.node);
  },
});
