/**
 * Menu upgrade dialog view.
 */
App.Views.Forms.MenuUpgrade = BackboneEx.View.Dialog.ValidatingForm.extend({
  events: {
    'click .button-cancel': 'cancel',
    'click button[type=submit]': 'save',
    'click .continue': 'showForm',
  },

  options: {
    width: 600,
  },

  postInitialize: function () {
    this.openingHoursView = new App.Views.Partials.OpeningHours({
      model: this.model,
    });
    this.weekdayCheckboxesView = new App.Views.Partials.WeekdayCheckboxes({
      selectedDays: this.model.get('applicableDays'),
    });
  },

  initForm: function () {
    this.openingHoursView.setElement(this.$('.week'));
    this.openingHoursView.render();

    this.$('select[name=voucherValidityMonths]').html(this.getValidityMonthsHtml());
  },

  getValidityMonthsHtml: function () {
    var months = [1, 2, 3, 6, 12];
    var options = [];
    var selected = this.model.get('voucherValidityMonths') || 3;
    for (var i in months) {
      var name =
        months[i] === 1
          ? Wahanda.lang.datetime.distance_in_words.x_months.one
          : Wahanda.lang.datetime.distance_in_words.x_months.other.replace('{{count}}', months[i]);
      options.push({
        value: months[i],
        title: name,
        selected: selected == months[i],
      });
    }
    return Wahanda.Template.renderTemplate('form-select-items', {
      options: options,
    });
  },

  save: function () {
    this.openingHoursView.ifValid(
      function () {
        var formData = Wahanda.Form.serialize(this.$el.find(':input'));
        this.disableForm();
        var saveButton = this.$('.save-action');
        saveButton.action('doing');

        this.model.set({
          voucherValidityMonths: formData.voucherValidityMonths,
          openingHours: this.openingHoursView.getValues(),
        });

        var self = this;
        this.model.enableSellingAllOffers(
          function onSuccess() {
            self.close();
            self.options.onUpgradeSuccess();

            App.trigger(Wahanda.Event.MENU_UPGRADED_TO_SELLING_ALL_OFFERS);
          },
          function onError() {
            self.enableForm();
            saveButton.action();
          },
        );
      }.bind(this),
    );
  },

  showForm: function () {
    this.$('.upgrade-venue-dialog-warning').addClass('hidden');
    this.$('.upgrade-form').removeClass('hidden');
  },
});
