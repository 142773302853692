import React from 'react';
import { connect } from 'react-redux';
import urlTemplate from 'url-template';
import moment from 'common/moment';
import _ from 'common/underscore';
import { ProductSalesReportAnalytics } from 'common/analytics';
import { BaseCsvDownloadLink } from '../common/BaseCsvDownloadLink';
import { getEmployeeIdListForReports } from '../selectors';

type CsvDownloadLinkProps = {
  fromDate: string;
  toDate: string;
  employees?: number[];
};
const CsvDownloadLink: React.SFC<CsvDownloadLinkProps> = ({ fromDate, toDate, employees }) => {
  const getQueryParams = () => {
    const params = _.defaults(
      { fromDate, toDate, employees },
      {
        fromDate: moment().startOf('month').formatApiDateString(),
        toDate: moment().endOf('month').formatApiDateString(),
      },
    );
    return params;
  };
  const url = urlTemplate.parse('reports/sales/products/csv/{?employees,from,to}').expand({
    from: getQueryParams().fromDate,
    to: getQueryParams().toDate,
    employees: getQueryParams().employees,
  });
  return (
    <BaseCsvDownloadLink
      onClick={ProductSalesReportAnalytics.trackCsvDownloadClick}
      relativeReportUrl={url}
    />
  );
};
CsvDownloadLink.defaultProps = {
  employees: undefined,
};

export { CsvDownloadLink };

const connector = connect((state, props) => {
  return {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'match' does not exist on type '{}'.
    fromDate: props.match.params.fromDate,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'match' does not exist on type '{}'.
    toDate: props.match.params.toDate,
    employees: getEmployeeIdListForReports(state, {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'match' does not exist on type '{}'.
      employmentStatusOrId: Number(props.match.params.employeeFilter),
    }),
  };
})(CsvDownloadLink);

export default connector;
