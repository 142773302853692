(function () {
  App.Views.Forms.MenuOffer2.Pricing.PackageServices.SelectServices.Message = Backbone.View.extend({
    initialize: function () {
      this.setElement('<span class="offer2--footer--message">');
      this.$el.append('<span class="icon">').append('<span class="js-text">');
    },

    render: function () {
      this.$('.js-text').text(this.options.message);
      return this;
    },
  });
})();
