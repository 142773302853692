import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import { PaymentMessage } from './index';
import { requestBankDetailsAction, requestLastPaymentStatus } from './actions';

const store = storeBuilder();

function render(
  { closingBalance, supplierId, reFetchRemittances, paymentDueDays, payNow, paymentReference },
  node,
) {
  store.dispatch(requestBankDetailsAction(supplierId));
  store.dispatch(requestLastPaymentStatus(supplierId));
  ReactDOM.render(
    <Provider store={store}>
      <PaymentMessage
        closingBalance={closingBalance}
        supplierId={supplierId}
        reFetchRemittances={reFetchRemittances}
        paymentDueDays={paymentDueDays}
        payNow={payNow}
        paymentReference={paymentReference}
      />
    </Provider>,
    node,
  );
}

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

export const paymentsInitializer = {
  render,
  destroy,
};
