import React from 'react';
import style from './LayoutHeader.scss';

export interface LayoutHeaderProps {
  header: React.ReactNode;
  subheader?: React.ReactNode;
  actions?: JSX.Element[];
}

export const LayoutHeader = ({ actions, header, subheader }: LayoutHeaderProps) => {
  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <div className={style.title}>{header}</div>
        {subheader && <div className={style.subtitle}>{subheader}</div>}
      </div>
      {actions && (
        <div className={style.actions}>
          {actions.map((item, i) => (
            <div key={i} className={style.actionItem}>
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

LayoutHeader.displayName = 'LayoutHeader';
