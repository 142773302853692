import { action, payload } from 'ts-action';
import { AutomatedMessagingSettings, SendHelpfulReminderSetting, ReminderSettings } from './types';

export const automatedMessagingSettingsRequest = action(
  '[AutomatedMessaging] Get Settings Request',
);

export const automatedMessagingSettingsSuccess = action(
  '[AutomatedMessaging] Get Settings Request Success',
  payload<AutomatedMessagingSettings | ReminderSettings>(),
);

export const setAutomatedMessagingSettingRequest = action(
  '[AutomatedMessaging] Set Request',
  payload<Partial<AutomatedMessagingSettings>>(),
);

export const setAutomatedMessagingSettingSuccess = action(
  '[AutomatedMessaging] Set Request Success',
  payload<Partial<AutomatedMessagingSettings>>(),
);

export const setReminderSettingsRequest = action(
  '[AutomatedMessaging] Set Reminder Settings Request',
  payload<ReminderSettings>(),
);

export const setReminderSettingsSuccess = action(
  '[AutomatedMessaging] Set Reminder Settings Request Success',
  payload<SendHelpfulReminderSetting | ReminderSettings>(),
);

export const automatedMessagingSettingsFailure = action('[AutomatedMessaging] Request Failure');

export const automatedMessagingReset = action('[AutomatedMessaging] Reset');
