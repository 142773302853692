import React from 'react';
import { vector, hashMap } from 'mori';
import classNames from 'classnames';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import ReactDialog from 'components/common/react-dialog';
import ErrorList from 'components/common/dialog/error-list';
import DialogFooter from 'components/common/dialog/footer';
import { Switch } from 'components/common/Switch';
import Tab from 'components/common/dialog/TabSet/Tab';
import TabSet from 'components/common/dialog/TabSet';
import TabBody from 'components/common/dialog/TabSet/TabBody';
import TabHead from 'components/common/dialog/TabSet/TabHead';
import { Notice } from 'components/common/Notice';
import LastMinuteDiscounts from './LastMinute';
import OffPeakDiscounts from './OffPeak';
import { TreatmentList } from './TreatmentList';
import Confirm from './Dialog/Confirm';
import style from './style.scss';
import { DiscountRuleDialogAnalytics } from './tracking';

const DISCOUNT_SETTINGS_TAB = 'DISCOUNT_SETTINGS_TAB';
const ASSIGNED_TREATMENTS_TAB = 'ASSIGNED_TREATMENTS_TAB';
const {
  title: TITLE,
  discountName: DISCOUNT_NAME,
  discountNamePlaceholder: DISCOUNT_NAME_PLACEHOLDER,
  discountSettings,
  activateRule: ACTIVATE_RULE,
  assignedTreatments,
  intro: { text: INTRO_TEXT, link: INTRO_LINK, actionText: ACTION_TEXT },
  defaultDiscountName: DEFAULT_DISCOUNT_NAME,
  errors: {
    hasNoPermissionToEdit: HAS_NO_PERMISSION,
    discountNameIsNotUnique: NAME_NOT_UNIQUE,
    discountNameRequired: NAME_REQUIRED,
  },
} = Wahanda.lang.menu.smartDiscounting;
const { cancel: CANCEL, delete: DELETE, save: SAVE } = Wahanda.lang.shared;
const DIALOG_DISABLED_CLASSES = 'dialog2--button-disabled';
const DIALOG_CANCEL_CLASSES = 'dialog2--button-left';
const DIALOG_MENU_CLASSES = 'dialog2--button-left dialog2--button-menu';
const DIALOG_SAVE_CLASSES = 'dialog2--button-right dialog2--button-green';
const DIALOG_SAVE_DISABLED_CLASSES = `${DIALOG_SAVE_CLASSES} ${DIALOG_DISABLED_CLASSES}`;
const DISCOUNT_NAME_LENGTH_MIN = 0;
const DISCOUNT_NAME_LENGTH_MAX = 100;
const DISCOUNT_NAME_PERMISSION_ERROR = {
  text: HAS_NO_PERMISSION,
};
/*
const DISCOUNT_NAME_LENGTH_ERROR = {
  text: NAME_NOT_LENGTH,
  for: 'discountName'
};
*/
const DISCOUNT_NAME_UNIQUE_ERROR = {
  text: NAME_NOT_UNIQUE,
  for: 'discountName',
};
const DISCOUNT_NO_NAME_ERROR = {
  text: NAME_REQUIRED,
  for: 'discountName',
};
const NO_OP = () => {};
const hasIntroLink = () => INTRO_LINK && INTRO_LINK !== 'NULL';
const hasValidDiscountNameLength = (discountName) => {
  const n = discountName.length;
  return (
    n > DISCOUNT_NAME_LENGTH_MIN && n < DISCOUNT_NAME_LENGTH_MAX + 1 // n < 101 (max is 100)
  );
};

function renderCancelButton(onClose) {
  return hashMap('title', CANCEL, 'onClick', onClose, 'classes', DIALOG_CANCEL_CLASSES);
}

function renderMenuButton(onClick) {
  return hashMap(
    'actions',
    [
      {
        label: DELETE,
        onClick,
      },
    ],
    'classes',
    DIALOG_MENU_CLASSES,
  );
}

function renderSaveButton(isDisabled, onSave) {
  const onClick = isDisabled ? NO_OP : onSave;
  const classes = isDisabled ? DIALOG_SAVE_DISABLED_CLASSES : DIALOG_SAVE_CLASSES;
  return hashMap('title', SAVE, 'onClick', onClick, 'classes', classes);
}

function renderPermissionError() {
  /*
   *  If the user does not have permission to edit the discounts it
   *  is presented as an error
   */
  const errors = [DISCOUNT_NAME_PERMISSION_ERROR];
  return <ErrorList errors={errors} className="locked" />;
}

function renderUniqueNameError() {
  const errors = [DISCOUNT_NAME_UNIQUE_ERROR];
  return <ErrorList errors={errors} className="error" />;
}

function renderNoNameError() {
  const errors = [DISCOUNT_NO_NAME_ERROR];
  return <ErrorList errors={errors} className="error" />;
}

// @ts-expect-error ts-migrate(2430) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
interface IDiscountRuleDialogProps extends React.HTMLAttributes<Element> {
  id?: number;
  name?: string;
  lastMinuteDiscount?: {
    active: boolean;
  };
  offpeakDiscounts?: {
    active: boolean;
  };
  assignedMenuItems?: {
    id: number;
    name?: string;
  }[];
  number?: number;
  active?: boolean;
  onClose: (...args: any[]) => any;
  venueId?: string;
  actions?: {
    presentDiscountRuleDeleteConfirmAction: (...args: any[]) => any;
    discardDiscountRuleDeleteConfirmAction: (...args: any[]) => any;
    createDiscountRuleAction: (...args: any[]) => any;
    removeDiscountRuleAction: (...args: any[]) => any;
    updateDiscountRuleAction: (...args: any[]) => any;
    revertDiscountRuleAction: (...args: any[]) => any;
  };
  hasEditPermission?: boolean;
  hasSaved?: boolean;
  hasError?: boolean;
  hasDeleteConfirmDialog?: boolean;
  isAssignedTreatmentAvailable?: boolean;
  menuItemOfferIds: number[];
}
type DiscountRuleDialogState = {
  isSaving?: boolean;
  hasChanged?: boolean;
  selectedTab?: any;
  name?: any;
  hasDiscountNameLengthError?: boolean;
  active?: boolean;
  contentMaxHeight?: any;
};

export default class DiscountRuleDialog extends React.Component<
  IDiscountRuleDialogProps,
  DiscountRuleDialogState
> {
  private discountName: any;

  private lastMinute: any;

  private offpeak: any;

  public toggle: any;

  public state = {
    name: this.props.name,
    active: this.props.active,
    selectedTab: DISCOUNT_SETTINGS_TAB,
    hasChanged: false,
    initialActivityStatus: this.props.active,
    initialAssignedMenuItemIds: this.props.assignedMenuItems
      ? this.props.assignedMenuItems.map(({ id }) => id)
      : [],
  };

  public componentDidMount() {
    DiscountRuleDialogAnalytics.trackTabView('discount-dialog');
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dialogRoot' does not exist on type '{}'.
    const { dialogRoot } = this.dialogRef;
    if (dialogRoot) dialogRoot.addEventListener('change', this.handleChange);
  }

  public UNSAFE_componentWillReceiveProps(props) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isSaving' does not exist on type '{ name... Remove this comment to see the full error message
    const { isSaving } = this.state;
    if (isSaving) {
      const { hasSaved, hasError } = props;
      this.setState({
        isSaving: !(hasSaved || hasError),
        hasChanged: false,
      });
    }
  }

  public componentDidUpdate(props) {
    const { hasError } = this.props;
    if (hasError) {
      this.discountName.focus();
    } else {
      const { hasSaved: wasHasSaved } = props;
      const { hasSaved: nowHasSaved } = this.props;
      if (wasHasSaved !== nowHasSaved) {
        const { onClose } = this.props;
        onClose();
      }
    }
  }

  public componentWillUnmount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dialogRoot' does not exist on type '{}'.
    const { dialogRoot } = this.dialogRef;
    if (dialogRoot) dialogRoot.removeEventListener('change', this.handleChange);
  }

  private dialogRef = {};

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasChanges' does not exist on type '{}'.
  private hasChanges = () => this.dialogRef.hasChanges();

  public hasAssignedTreatments() {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return !!this.props.assignedMenuItems.length;
  }

  private isAssignedTreatmentAvailable() {
    return this.props.isAssignedTreatmentAvailable;
  }

  private handleClose = () => {
    const { onClose } = this.props;
    this.setState({ isSaving: false }, () => {
      onClose();
    });
  };

  private handleTabClick = (selectedTab) => {
    if (selectedTab === ASSIGNED_TREATMENTS_TAB) {
      DiscountRuleDialogAnalytics.trackTabView('assigned-treatments-list');
    } else {
      DiscountRuleDialogAnalytics.trackTabView('discount-dialog');
    }
    this.setState({
      selectedTab,
    });
  };

  private trackServiceAssignment() {
    const initial = this.state.initialAssignedMenuItemIds;
    const current = this.props.menuItemOfferIds;

    initial.forEach((id) => {
      if (!current.includes(id)) {
        DiscountRuleDialogAnalytics.trackServiceUnassigned(id);
      }
    });

    current.forEach((id) => {
      if (!initial.includes(id)) {
        DiscountRuleDialogAnalytics.trackServiceAssigned(id);
      }
    });

    if (this.state.initialActivityStatus !== this.state.active) {
      DiscountRuleDialogAnalytics.trackActivityStatusChange(Boolean(this.state.active));
    }
  }

  private handleSave = () => {
    const { id } = this.props;

    if (id) {
      this.updateDiscount();
    } else {
      this.createDiscount();
    }

    App.trigger(Wahanda.Event.MENU_DISCOUNT_SAVED);
  };

  private handleMenu = () => {
    this.presentDiscountRuleDeleteConfirmAction();
  };

  private handleDiscountNameChange = () => {
    /*
     *  Soft validation: modify the error state boolean but don't grab focus
     */
    const { value } = this.discountName;
    const name = value.trim();
    this.setState({
      name,
      hasDiscountNameLengthError: !hasValidDiscountNameLength(name),
      hasChanged: true,
    });
  };

  private handleDialogAccept = () => {
    const { onClose } = this.props;
    this.discardDiscountRuleDeleteConfirmAction();
    this.removeDiscount();
    onClose();
  };

  private handleDialogReject = () => {
    this.discardDiscountRuleDeleteConfirmAction();
  };

  private handleToggleChange = () => {
    const { active } = this.state;
    const { hasEditPermission } = this.props;
    if (hasEditPermission) {
      this.setState({ active: !active });
    }
  };

  private handleChange = (event = {}) => {
    // THIS IS A REAL DOM EVENT NOT A FAKE REACT EVENT
    //
    // The discounts dialog can be opened from within another dialog
    // so we don't want to propagate change events (which are raised
    // by form elements) because both dialogs will respond to them
    //
    // We could do this with Redux (http://git.treatwell.net/dev/connect-desktop/merge_requests/341#note_31712)
    // but the parent dialog in this case is Backbone not React!
    if ('stopPropagation' in event) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'stopPropagation' does not exist on type ... Remove this comment to see the full error message
      event.stopPropagation();
    } else if ('cancelBubble' in event) {
      event.cancelBubble = true; // eslint-disable-line no-param-reassign
    }
  };

  private presentDiscountRuleDeleteConfirmAction() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'presentDiscountRuleDeleteConfirmAction' ... Remove this comment to see the full error message
      actions: { presentDiscountRuleDeleteConfirmAction },
    } = this.props;
    presentDiscountRuleDeleteConfirmAction();
  }

  private discardDiscountRuleDeleteConfirmAction() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'discardDiscountRuleDeleteConfirmAction' ... Remove this comment to see the full error message
      actions: { discardDiscountRuleDeleteConfirmAction },
    } = this.props;
    discardDiscountRuleDeleteConfirmAction();
  }

  public updateDiscount() {
    /*
     *  Soft validation: cannot save if the discount name is in error (although the
     *  buttons should be disabled so they're impossible to click, anyway)
     */
    const { hasEditPermission, hasError } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasDiscountNameLengthError' does not exi... Remove this comment to see the full error message
    const { hasChanged, hasDiscountNameLengthError } = this.state;
    if (hasEditPermission && (!hasError || hasChanged) && !hasDiscountNameLengthError) {
      /*
       *  Hard validation: put the focus back on the discountName field if it fails
       *  validation at the server (with the component notified by props, after dispatch)
       */
      const {
        venueId,
        id,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateDiscountRuleAction' does not exist... Remove this comment to see the full error message
        actions: { updateDiscountRuleAction },
        menuItemOfferIds,
      } = this.props;
      const { name, active } = this.state;
      const lastMinuteDiscount = this.lastMinute.getValues();
      const offpeakDiscounts = this.offpeak.getValues();
      const values = {
        name,
        active,
        lastMinuteDiscount,
        offpeakDiscounts,
        assignedMenuItemIds: menuItemOfferIds,
      };
      this.trackServiceAssignment();
      DiscountRuleDialogAnalytics.trackUpdate(id as number);
      this.setState({ isSaving: true });
      App.trigger(Wahanda.Event.MENU_OFFER_UPDATED_DISCOUNT);
      updateDiscountRuleAction(venueId, id, values);
    }
  }

  public createDiscount() {
    /*
     *  Soft validation: PART 1
     */
    const { hasEditPermission, hasError } = this.props;
    const { hasChanged } = this.state;
    if (hasEditPermission && (!hasError || hasChanged)) {
      /*
       *  Soft validation: PART 2
       */
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasDiscountNameLengthError' does not exi... Remove this comment to see the full error message
      let { hasDiscountNameLengthError } = this.state;
      hasDiscountNameLengthError = hasChanged ? hasDiscountNameLengthError : true;
      if (hasDiscountNameLengthError) {
        this.setState({
          hasDiscountNameLengthError,
        });
      } else {
        /*
         *  Hard validation: put the focus back on the discountName field if it fails
         *  validation at the server (with the component notified by props, after dispatch)
         */
        const {
          venueId,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'createDiscountRuleAction' does not exist... Remove this comment to see the full error message
          actions: { createDiscountRuleAction },
          menuItemOfferIds,
        } = this.props;
        const { name, active } = this.state;
        const lastMinuteDiscount = this.lastMinute.getValues();
        const offpeakDiscounts = this.offpeak.getValues();
        const values = {
          name,
          active,
          lastMinuteDiscount,
          offpeakDiscounts,
          assignedMenuItemIds: menuItemOfferIds,
        };
        this.trackServiceAssignment();
        DiscountRuleDialogAnalytics.trackSubmit();
        this.setState({ isSaving: true });
        App.trigger(Wahanda.Event.MENU_OFFER_CREATED_DISCOUNT);
        createDiscountRuleAction(venueId, values);
      }
    }
  }

  private removeDiscount() {
    const {
      venueId,
      id,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'removeDiscountRuleAction' does not exist... Remove this comment to see the full error message
      actions: { removeDiscountRuleAction },
    } = this.props;
    App.trigger(Wahanda.Event.MENU_OFFER_REMOVED_DISCOUNT);
    removeDiscountRuleAction(venueId, id);
  }

  private refDiscountName = (discountName) => {
    this.discountName = discountName;
  };

  private refLastMinute = (lastMinute) => {
    this.lastMinute = lastMinute;
  };

  private refOffpeak = (offpeak) => {
    this.offpeak = offpeak;
  };

  public refToggle = (toggle) => {
    this.toggle = toggle;
  };

  private renderDialogDiscountRuleDelete() {
    return <Confirm onAccept={this.handleDialogAccept} onReject={this.handleDialogReject} />;
  }

  private renderDialogFooter() {
    // disabled while hasError or isSaving or hasDiscountNameLengthError
    const { hasEditPermission, hasError, id } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasDiscountNameLengthError' does not exi... Remove this comment to see the full error message
    const { hasChanged, hasDiscountNameLengthError, isSaving } = this.state;
    const isDisabled =
      !hasEditPermission || (hasError && !hasChanged) || hasDiscountNameLengthError || isSaving;
    let buttons;
    if (!!id && hasEditPermission) {
      buttons = vector(
        renderCancelButton(this.handleClose),
        renderMenuButton(this.handleMenu),
        renderSaveButton(isDisabled, this.handleSave),
      );
    } else {
      buttons = vector(
        renderCancelButton(this.handleClose),
        renderSaveButton(isDisabled, this.handleSave),
      );
    }
    return <DialogFooter buttons={buttons} />;
  }

  private renderIntroLink() {
    // eslint-disable-line class-methods-use-this
    if (hasIntroLink()) {
      return (
        <a target="_blank" href={INTRO_LINK} rel="noopener noreferrer">
          {ACTION_TEXT} &raquo;
        </a>
      );
    }
    return null;
  }

  public renderHeaderText() {
    return (
      <div className="heading">
        {INTRO_TEXT} {this.renderIntroLink()}
      </div>
    );
  }

  private renderLastMinuteDiscounts() {
    const { venueId, lastMinuteDiscount, hasEditPermission } = this.props;
    return (
      <LastMinuteDiscounts
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
        venueId={venueId}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ active: boolean; }' is missing the followi... Remove this comment to see the full error message
        data={lastMinuteDiscount}
        ref={this.refLastMinute}
        disabled={!hasEditPermission}
      />
    );
  }

  private renderOffpeakDiscounts() {
    const { offpeakDiscounts, hasEditPermission } = this.props;
    return (
      <OffPeakDiscounts
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ active: boolean; }' is missing the followi... Remove this comment to see the full error message
        data={offpeakDiscounts}
        ref={this.refOffpeak}
        disabled={!hasEditPermission}
      />
    );
  }

  private renderDiscountAction() {
    const { name, hasEditPermission } = this.props;
    const { active } = this.state;
    return (
      <div className={style.discountAction}>
        <div className={style.input}>
          <label htmlFor="discountName">{DISCOUNT_NAME}</label>
          <input
            type="text"
            id="discountName"
            defaultValue={name}
            ref={this.refDiscountName}
            onChange={this.handleDiscountNameChange}
            disabled={!hasEditPermission}
            placeholder={DISCOUNT_NAME_PLACEHOLDER}
          />
        </div>
        <Switch
          dataTest="discount-switch"
          onChange={this.handleToggleChange}
          label={ACTIVATE_RULE}
          checked={active}
          disabled={!hasEditPermission}
        />
      </div>
    );
  }

  private renderDiscountNotice() {
    // eslint-disable-line class-methods-use-this
    const isWidgetEnabled = App.config.get('venue').treatwellBrandedWidgetEnabled;
    const isPartner = App.config.get('venue').countryCode === 'GB';
    const isSmartDiscountsEnabledOnWidget = App.config.get('venue').smartDiscountsEnabledOnWidget;
    let discountNote = '';
    if (isWidgetEnabled && isSmartDiscountsEnabledOnWidget) {
      if (isPartner) {
        discountNote = Wahanda.lang.menu.services.discountNote.partner.widgetEnabled;
      } else {
        discountNote = Wahanda.lang.menu.services.discountNote.nonpartner.widgetEnabled;
      }
    } else if (isPartner) {
      discountNote = Wahanda.lang.menu.services.discountNote.partner.widgetDisabled;
    } else {
      discountNote = Wahanda.lang.menu.services.discountNote.nonpartner.widgetDisabled;
    }
    return <Notice dataTest="discount-notice" message={discountNote} />;
  }

  private renderDiscountName() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasDiscountNameLengthError' does not exi... Remove this comment to see the full error message
    const { hasDiscountNameLengthError } = this.state;
    const discountAction = this.renderDiscountAction();
    const discountNotice = this.renderDiscountNotice();
    const className = classNames(style.discountName, {
      [style.error]: hasDiscountNameLengthError,
    });
    return (
      <div className={className}>
        {discountAction}
        {discountNotice}
      </div>
    );
  }

  private renderDiscountSettings = () => {
    const discountName = this.renderDiscountName();
    const lastMinuteDiscounts = this.renderLastMinuteDiscounts();
    const offpeakDiscounts = this.renderOffpeakDiscounts();
    return (
      <div className="discount-settings">
        {discountName}
        {lastMinuteDiscounts}
        {offpeakDiscounts}
      </div>
    );
  };

  private renderAssignedTreatments = () => {
    const { assignedMenuItems, id } = this.props;
    const currentDiscountName = this.state.name || DEFAULT_DISCOUNT_NAME;
    return (
      <TreatmentList
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type '(Infer... Remove this comment to see the full error message
        assignedTreatments={assignedMenuItems}
        hasChanges={this.hasChanges}
        discountId={id}
        discountName={currentDiscountName}
        filterType="ACT"
        discountBadgeVisible
      />
    );
  };

  private renderErrors() {
    const { hasEditPermission } = this.props;
    let errorList;
    if (!hasEditPermission) {
      errorList = renderPermissionError();
    } else {
      const { hasError } = this.props;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasDiscountNameLengthError' does not exi... Remove this comment to see the full error message
      const { hasDiscountNameLengthError } = this.state;
      if (hasError) {
        errorList = renderUniqueNameError();
      }
      if (hasDiscountNameLengthError) {
        errorList = renderNoNameError();
      }
    }
    return errorList;
  }

  private renderDialog() {
    const { hasDeleteConfirmDialog } = this.props;
    if (hasDeleteConfirmDialog) {
      return this.renderDialogDiscountRuleDelete();
    }
    return null;
  }

  private renderTabSet() {
    const { selectedTab } = this.state;
    const { hasError, hasEditPermission } = this.props;
    const hasErrorHeader = !hasEditPermission || hasError;
    let assignedTreatmentsTab;
    let assignedTreatmentsTabBody;
    if (this.isAssignedTreatmentAvailable()) {
      assignedTreatmentsTab = (
        <Tab
          tab={ASSIGNED_TREATMENTS_TAB}
          label={assignedTreatments}
          onClick={this.handleTabClick}
          selectedTab={selectedTab}
        />
      );
      assignedTreatmentsTabBody = (
        <TabBody
          tab={ASSIGNED_TREATMENTS_TAB}
          selectedTab={selectedTab}
          className={hasErrorHeader ? 'error' : ''}
        >
          {this.renderAssignedTreatments()}
        </TabBody>
      );
    } else {
      assignedTreatmentsTab = (
        <Tab
          tab={ASSIGNED_TREATMENTS_TAB}
          label={assignedTreatments}
          selectedTab={selectedTab}
          disabled
        />
      );
    }
    return (
      <TabSet>
        <TabHead>
          <Tab
            tab={DISCOUNT_SETTINGS_TAB}
            label={discountSettings}
            onClick={this.handleTabClick}
            selectedTab={selectedTab}
          />
          {assignedTreatmentsTab}
        </TabHead>
        {this.renderErrors()}
        <TabBody
          tab={DISCOUNT_SETTINGS_TAB}
          selectedTab={selectedTab}
          className={hasErrorHeader ? 'error' : ''}
        >
          {this.renderDiscountSettings()}
        </TabBody>
        {assignedTreatmentsTabBody}
      </TabSet>
    );
  }

  public render() {
    const { onClose } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'contentMaxHeight' does not exist on type... Remove this comment to see the full error message
    const { contentMaxHeight } = this.state;
    const footer = this.renderDialogFooter();
    return (
      <ReactDialog
        dataTest="discount-rule-modal"
        width={874}
        title={TITLE}
        onClose={onClose}
        classes={{ discountRuleEditing: true }}
        keepTopPositionWhenResizing
        dialogContentMaxHeight={contentMaxHeight}
        footer={footer}
        initialPosition="top"
        ref={(ref) => {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '{}'.
          this.dialogRef = ref;
        }}
        onConfirm={() => {
          this.handleSave();
        }}
      >
        {this.renderTabSet()}
        {this.renderDialog()}
      </ReactDialog>
    );
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
DiscountRuleDialog.defaultProps = {
  id: 0,
  name: '',
  hasEditPermission: false,
  hasSaved: false,
  hasError: false,
  hasDeleteConfirmDialog: false,
  isAssignedTreatmentAvailable: true,
};
