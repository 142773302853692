import { fetch as fetchDailyReport, addTransaction } from 'src/services/pos-daily-report';
import { fetch as fetchPosStatus } from 'src/services/pos-status';

import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { POSOperationId } from 'reduxStore/common/posOperation/types';

export function refreshAfterTransaction() {
  // if successful, request state refresh
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  fetchDailyReport();
  // And refresh the fetched pos-status data
  fetchPosStatus();
}

export function createCashTransaction(amount: number, notes: string, operationId: POSOperationId) {
  const promise = addTransaction(amount, notes, operationId);

  promise.done(() => {
    refreshAfterTransaction();
    App.trigger(Wahanda.Event.TRANSACTION_CREATED);
  });

  // return the promise for other UI related tasks
  return promise;
}
