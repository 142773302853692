import React from 'react';
import get from 'lodash/get';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { Select } from 'components/common/Select';
import { TimeSlotsObject } from '../utils/types';
import style from './ShiftTimeSlotRange.scss';

const LANG = Wahanda.lang.settings.shifts;
interface Props {
  // Used to set limited startTime, endTime for custom working hours selects
  limitedHoursRange?: {
    startTime: number;
    endTime: number;
  };
  defaultTimeSlots: TimeSlotsObject[];
  isLabelVisible?: boolean;
  onTimeSlotChange: (timeSlots: TimeSlotsObject) => void;
  timeSlots: TimeSlotsObject[];
}
const TIME_SLOT = {
  timeFrom: 'timeFrom',
  timeTo: 'timeTo',
};

export const ShiftTimeSlotRange = ({
  limitedHoursRange,
  defaultTimeSlots,
  isLabelVisible = true,
  onTimeSlotChange,
  timeSlots,
}: Props) => {
  const venueTimeSlots = defaultTimeSlots[0];
  const observer = React.useRef(false);
  const [slotChangedName, setSlotChangedName] = React.useState('');
  const [slots, setSlots] = React.useState([
    {
      timeFrom: get(timeSlots, '[0].timeFrom') || venueTimeSlots.timeFrom,
      timeTo: get(timeSlots, '[0].timeTo') || venueTimeSlots.timeTo,
    },
  ]);
  const step = App.config.get('venue').appointmentSlotDuration;
  const startTime = limitedHoursRange?.startTime || 0;
  const endTime = limitedHoursRange?.endTime || 1440;
  const timesFromData = Wahanda.Time.getTimeFieldValues(startTime, endTime - step, step);
  const timesToData = Wahanda.Time.getTimeFieldValues(startTime + step, endTime, step);
  const { timeFrom, timeTo } = slots[0];

  function handleTimeSlotChange(formFieldName: string) {
    return (event) => {
      const handledSlots = {
        ...slots[0],
        [formFieldName]: event,
      };
      onTimeSlotChange(handledSlots);
      setSlots([handledSlots]);
      setSlotChangedName(formFieldName);
    };
  }

  const alignTimeSelects = (formFieldName: string) => {
    if (formFieldName === '') {
      return;
    }
    const selectValue = slots[0][formFieldName];
    const shouldBeLess = formFieldName === TIME_SLOT.timeFrom;
    const otherSelect = shouldBeLess ? TIME_SLOT.timeTo : TIME_SLOT.timeFrom;
    // 24 hours
    const max = 1440;
    const alignStep = 60;
    let change = -1;
    const time = Wahanda.Time.timeToMinutes(selectValue);
    const other = Wahanda.Time.timeToMinutes(slots[0][otherSelect]);
    if (shouldBeLess && time >= other) {
      change = time + alignStep;
      if (change > max) {
        change = max;
      }
    } else if (!shouldBeLess && time <= other) {
      change = time - alignStep;
      if (change < 0) {
        change = 0;
      }
    }
    if (change !== -1) {
      const alignedSlots = {
        ...slots[0],
        [otherSelect]: Wahanda.Time.toFormatted(change, 'H:i'),
      };
      setSlots([alignedSlots]);
    }
  };
  React.useEffect(() => {
    if (observer.current) {
      setSlots(timeSlots);
      setSlotChangedName('');
    } else {
      observer.current = true;
    }
  }, [timeSlots]);
  React.useEffect(() => {
    if (slotChangedName) {
      alignTimeSelects(slotChangedName);
    }
  }, [slotChangedName]);
  return (
    <div className={style.timeSlots}>
      <div className={style.timeSlot}>
        {isLabelVisible && <div className={style.selectLabel}>{LANG.timeSlotFrom}</div>}
        <Select
          name="shift-time-slot-from"
          options={timesFromData}
          dataTest="shift-time-slot-from"
          onSelect={handleTimeSlotChange(TIME_SLOT.timeFrom)}
          selectedOption={timeFrom}
        />
      </div>
      {!isLabelVisible && <div className={style.separatorDash}>-</div>}
      <div className={style.timeSlot}>
        {isLabelVisible && <div className={style.selectLabel}>{LANG.timeSlotTo}</div>}
        <Select
          name="shift-time-slot-to"
          options={timesToData}
          dataTest="shift-time-slot-to"
          onSelect={handleTimeSlotChange(TIME_SLOT.timeTo)}
          selectedOption={timeTo}
        />
      </div>
    </div>
  );
};
