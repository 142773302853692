/**
 * Wahanda Mobile page (Backbone.View object) registry.
 */
Wahanda.Page = {
  registry: {},
  pages: {},

  get: function (page) {
    if (!Wahanda.Page.pages[page] && Wahanda.Page.registry[page]) {
      Wahanda.Page.pages[page] = new Wahanda.Page.registry[page]({
        el: $('#' + page),
      });
    }
    return Wahanda.Page.pages[page];
  },

  register: function (pageId, classObj) {
    Wahanda.Page.registry[pageId] = classObj;
  },
};
