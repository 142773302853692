/**
 *
 */
BackboneEx.Mixin.View.asMediaQueryAwareDialogView = function(target) {
    var originalInit = target.initialize;
    target.initialize = function() {
        copyOptions(this);
        originalInit.apply(this, arguments);
        listenForMediaQueryChange(this);
    };

    /**
     * Returns options which should be used for the currently efective media query state.
     */
    target.getCurrentMediaQueryOptions = function getCurrentMediaQueryOptions() {
        var activeState = Wahanda.MediaQuery.getState();
        if (this.mediaQueryOptions && this.mediaQueryOptions[activeState]) {
            return this.mediaQueryOptions[activeState];
        }
        return null;
    };

    /**
     * Copy options from the currently used Media Query to the `options` object
     */
    function copyOptions(view) {
        var newOptions = view.getCurrentMediaQueryOptions();
        if (!newOptions) {
            return;
        }
        view.options = _.extend(
            view.options || {},
            newOptions
        );
    };

    function listenForMediaQueryChange(view) {
        App.on(Wahanda.Event.MEDIA_QUERY_CHANGE, listener);

        function listener(state) {
            var options = view.getCurrentMediaQueryOptions();
            if (options) {
                view.setDialogOptions(options);
                // Reposition the view if needed
                window.setTimeout(
                    function() {
                        reposition(view, options);
                    },
                    0
                );
            }
        };

        view.on('destruct', function() {
            App.off(null, listener);
        });
    };

    // Common fix: if options provide new width, but no position - set original position to recenter the view
    // Can't add this to options, as resizing and repositioning in the same call does not work.
    function reposition(view, options) {
        if (!options.width) {
            return;
        }
        var position = options.position || view.$el.dialog('option', 'position');

        view.$el.dialog('option', 'position', position);
    };
};
