/* global BackboneEx */

export function isLastOpenDialog(key) {
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'BackboneEx'.
  return BackboneEx.View.DialogCollection.isLastOpenReactDialog(key);
}

export function addToStack(key, close) {
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'BackboneEx'.
  BackboneEx.View.DialogCollection.addReactDialog(key, close);
}

export function removeFromStack(key) {
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'BackboneEx'.
  BackboneEx.View.DialogCollection.removeReactDialog(key);
}

export function closeLastReactDialog() {
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'BackboneEx'.
  BackboneEx.View.DialogCollection.closeLastReactDialog();
}
