import React, { AnchorHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { GoToBase } from './GoToBase';
import { Direction } from './types';
import style from './style.scss';

interface Props {
  children: string;
  onClick?: () => void;
  href: string;
  direction?: Direction;
  className?: string;
  dataTest?: string;
  noRouter?: boolean;
  newTab?: boolean;
}

export const GoTo = (props: Props) => {
  const {
    href,
    onClick,
    children,
    direction,
    className,
    dataTest,
    noRouter = false,
    newTab = false,
  } = props;
  const sharedProps = {
    className: classnames(style.goTo, className),
    onClick,
  };
  const BaseComponent = (
    <GoToBase direction={direction} dataTest={dataTest}>
      {children}
    </GoToBase>
  );
  if (noRouter) {
    const additionalAttributes: Partial<AnchorHTMLAttributes<HTMLAnchorElement>> = {};
    if (newTab) {
      additionalAttributes['target'] = '_blank';
      additionalAttributes['rel'] = 'noreferrer noopener';
    }
    return (
      <a href={href} {...sharedProps} {...additionalAttributes}>
        {BaseComponent}
      </a>
    );
  }
  return (
    <Link to={href} {...sharedProps}>
      {BaseComponent}
    </Link>
  );
};

GoTo.displayName = 'GoTo';
