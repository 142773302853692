;(function($) {
	/**
	 * Wahanda's hide and show functions.
	 * 
	 * They work the same as jQuery`s native .show(), .hide() and .toggle()
	 */
	$.fn.wShow = function() {
		return this.removeClass('hidden');
	};
	
	$.fn.wHide = function() {
		return this.addClass('hidden');
	};
	
	$.fn.wToggle = function(show) {
		if (arguments.length === 0) {
			return this.toggleClass('hidden');
		} else {
			return this.toggleClass('hidden', !show);
		}
	};
}(jQuery));
