import React from 'react';
import { map, toJs, hash, get } from 'mori';
import classnames from 'classnames';
import assign from 'object-assign';
import ReactDialog from 'src/components/common/react-dialog';

function getButtons(list, closeDialogFn) {
  return map((buttonDesc) => {
    const clickHandler = () => {
      const passedInHandler = get(buttonDesc, 'onClick');
      try {
        passedInHandler();
      } catch (e) {
        // An error in the handler.
        closeDialogFn({ afterButtonAction: true });
        throw e;
      }
      closeDialogFn({ afterButtonAction: true });
    };
    const classes = classnames('button-choices--button', {
      'is-primary': get(buttonDesc, 'primary'),
    });
    return (
      <div key={hash(buttonDesc)} className={classes} onClick={clickHandler}>
        {get(buttonDesc, 'title')}
      </div>
    );
  }, list);
}

interface IButtonChoicesDialogProps extends React.HTMLAttributes<Element> {
  title?: string;
  buttons?: {};
  onClose?: (...args: any[]) => any;
  classes?: {};
}

export default class ButtonChoicesDialog extends React.Component<IButtonChoicesDialogProps, {}> {
  dialog: any;

  render() {
    const closeDialogFn = (params) => {
      this.dialog.close(params);
    };
    return (
      <ReactDialog
        dataTest="button-choices-modal"
        hideTitlebar
        width={350}
        classes={assign({ 'dialog2--button-choices': true }, this.props.classes)}
        onClose={this.props.onClose}
        ref={(dialog) => {
          this.dialog = dialog;
        }}
        warnWhenClosingChangedForms={false}
      >
        <div className="dialog2--button-choices--title">{this.props.title}</div>
        {this.props.children}
        {toJs(getButtons(this.props.buttons, closeDialogFn))}
      </ReactDialog>
    );
  }
}
