import React from 'react';
import { Avatar } from 'components/common/Avatar';

import { ServiceEmployee } from '../../store/types';

import style from './EmployeeCheckboxLabel.scss';

interface Props {
  employee: ServiceEmployee;
}

export const EmployeeCheckboxLabel = ({ employee }: Props) => {
  return (
    <div className={style.label}>
      <div className={style.avatar}>
        <Avatar text={employee.name} src={employee.image?.uris['50x50']} />
      </div>
      {employee.name}
    </div>
  );
};
