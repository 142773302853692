import { BOOKING_ACTOR } from 'common/consts';

export enum ExplanationBulletPoint {
  WILL_CHARGE_COMMISSION = 'WILL_CHARGE_COMMISSION',
  WILL_NOT_CHARGE_COMMISSION = 'WILL_NOT_CHARGE_COMMISSION',
  WILL_REFUND_CUSTOMER = 'WILL_REFUND_CUSTOMER',
  CANCELLATION_EMAIL = 'CANCELLATION_EMAIL',
  WILL_RECEIVE_PAID_AMOUNT = 'WILL_RECEIVE_PAID_AMOUNT',
  NO_SHOW_EMAIL = 'NO_SHOW_EMAIL',
  NO_SHOW_EMAIL_PAYMENT_PROTECTED = 'NO_SHOW_EMAIL_PAYMENT_PROTECTED',
  CANCELLATION_EMAIL_PAYMENT_PROTECTED = 'CANCELLATION_EMAIL_PAYMENT_PROTECTED',
}

export interface ExplanationBulletPointsContext {
  flow: string;
  bookingActor: BOOKING_ACTOR;
  isPrepaid: boolean;
  isFirstTimeCustomer?: boolean;
  isVenueLed?: boolean;
  isReasonCovid?: boolean;
  canPreventPaymentProtection?: boolean;
}

export const buildExplanationBullets = ({
  flow,
  bookingActor,
  isPrepaid,
  isFirstTimeCustomer,
  isVenueLed,
  isReasonCovid,
  canPreventPaymentProtection,
}: ExplanationBulletPointsContext) => {
  const B = ExplanationBulletPoint;

  const NO_SHOW = flow === 'no-show';
  const CANCELLATION = flow === 'cancellation';
  const PREPAID = Boolean(isPrepaid);
  const BOOKED_BY_CUSTOMER = bookingActor === BOOKING_ACTOR.CUSTOMER;
  const FIRST_TIME = Boolean(isFirstTimeCustomer);
  const NOT_FIRST_TIME = !FIRST_TIME;
  const VENUE_LED = Boolean(isVenueLed);
  const COVID_LED = Boolean(isReasonCovid);
  const CAN_PREVENT_PAYMENT_PROTECTION = Boolean(canPreventPaymentProtection);
  const CANNOT_PREVENT_PAYMENT_PROTECTION = !CAN_PREVENT_PAYMENT_PROTECTION;

  const bulletPoints: ExplanationBulletPoint[] = [];

  if (flow === 'no-show') {
    const when = whenFn.bind(null, bulletPoints, NO_SHOW);

    when(PREPAID).push(B.WILL_RECEIVE_PAID_AMOUNT);
    when(PREPAID, BOOKED_BY_CUSTOMER, FIRST_TIME).push(B.WILL_CHARGE_COMMISSION);
    when(BOOKED_BY_CUSTOMER, NOT_FIRST_TIME).push(B.WILL_NOT_CHARGE_COMMISSION);
    when(CANNOT_PREVENT_PAYMENT_PROTECTION).push(B.NO_SHOW_EMAIL);
    when(CAN_PREVENT_PAYMENT_PROTECTION).push(B.NO_SHOW_EMAIL_PAYMENT_PROTECTED);
  }

  if (flow === 'cancellation') {
    const when = whenFn.bind(null, bulletPoints, CANCELLATION);

    when(BOOKED_BY_CUSTOMER, NOT_FIRST_TIME).push(B.WILL_NOT_CHARGE_COMMISSION);
    when(PREPAID, VENUE_LED || COVID_LED).push(B.WILL_REFUND_CUSTOMER);
    when(CAN_PREVENT_PAYMENT_PROTECTION).push(B.CANCELLATION_EMAIL_PAYMENT_PROTECTED);
    when(CANNOT_PREVENT_PAYMENT_PROTECTION).push(B.CANCELLATION_EMAIL);
  }

  return bulletPoints;
};

function whenFn(arr, ...args: boolean[]) {
  let shouldPushToArray;

  if (args.some((x) => !x)) {
    shouldPushToArray = false;
  } else {
    shouldPushToArray = true;
  }

  return {
    push(bulletPoint: ExplanationBulletPoint) {
      if (shouldPushToArray) {
        arr.push(bulletPoint);
      }
    },
  };
}
