(function () {
  var STATUS_CONFIRMED = 'CN';

  App.Views.Dialog.Booking.Evoucher = Backbone.View.extend({
    events: {
      'click .a-open-evoucher': 'onOpenEvoucher',
    },

    initialize: function () {},

    render: function () {
      var vouchers = this.model.get('evouchers');
      var html = '';
      var self = this;
      _.each(vouchers, function (voucher) {
        var noVenues = null == voucher.applicableVenues || voucher.applicableVenues.length === 0;
        var params = {
          recipientName: voucher.recipientName,
          noVenues: noVenues,
          venues: voucher.applicableVenues,
          date: Wahanda.Date.formatToDefaultFullDate(Wahanda.Date.createDate(voucher.expiryDate))
            .date,
          statusText: self.getStatusText(voucher),
          reference: voucher.reference,
          refOpensDialog: !App.isRestrictedMode() && voucher.evoucherStatusCode === 'A',
        };
        html += Wahanda.Template.renderTemplate('booking-evouchers-table-item', params);
      });

      var $table = this.$('table');
      $table.find('tbody').html(html);

      var $availableForVenues = $table.find('.available-for-venues');

      if (App.isContextFromExternalSoruce() || App.config.get('venue').partOfChain) {
        $availableForVenues.removeClass('hidden');
      } else {
        $availableForVenues.addClass('hidden');
      }
    },

    /**
     * Return text representation of models confirmation state.
     *
     * @return String
     */
    getStatusText: function (voucher) {
      var type = null;
      switch (voucher.evoucherStatusCode) {
        case 'R':
          var dateTime = {};
          if (voucher.redemptionDate) {
            dateTime = Wahanda.Date.formatToDefaultFullDate(
              Wahanda.Date.parse(voucher.redemptionDate),
            );
          }
          return Wahanda.Template.render(Wahanda.lang.bookingDialog.evoucher.labels.redeemed, {
            venue: voucher.redemptionVenueName,
            clientName: voucher.redeemedByName,
            date: dateTime.date,
            time: dateTime.time,
          });

        case 'A':
          type = 'active';
          break;

        case 'V':
          type = 'voided';
          break;

        case 'X':
          type = 'expired';
          break;

        case 'S':
          type = 'reserved';
          break;
      }

      return (
        '<span class="status-' +
        type +
        '"><span class="status-txt">' +
        Wahanda.lang.calendar.voucherRedemption.types[type] +
        '</span></span>'
      );
    },

    // Events

    onOpenEvoucher: function (event) {
      var $target = $(event.currentTarget);
      var reference = $target.data('reference');
      // Show only the first, unmasked part
      reference = reference.split('-')[0];

      var dialog = new App.Views.Calendar.VoucherRedemption({
        maskedReference: reference,
      });
      dialog.render();
      dialog.open();
    },
  });
})();
