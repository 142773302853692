import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';

import {
  widgetGenerateCode,
  widgetGenerateCodeSuccess,
  widgetGenerateCodeFailure,
  widgetToggleEnabledSuccess,
  widgetToggleEnabledFailure,
} from './actions';

export function* changeWidgetLanguage(params) {
  const locale = params.lang;
  if (locale) {
    yield put(widgetGenerateCode({ locale }));
  }
}

export function* getWidgetEmbedCode(params) {
  try {
    const data = yield call(api.get, apiUrl('WIDGET_EMBED_CODE', params));

    yield put(widgetGenerateCodeSuccess({ data, params }));
  } catch (error) {
    yield put(widgetGenerateCodeFailure({ error, params }));
  }
}

export function* toggleWidgetEnabled(params) {
  try {
    const data = yield call(api.put, apiUrl('WIDGET_TOGGLE_ENABLE', params), {
      useOnlineBookingWidget: params.enabled,
    });
    yield put(widgetToggleEnabledSuccess({ data, params }));
  } catch (error) {
    yield put(widgetToggleEnabledFailure({ error, params }));
  }
}
