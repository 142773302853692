import moment from 'common/moment';

export interface DateDurationArgs {
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
}

export interface ShiftHeaderArgs {
  date: string;
  image?: string | null;
  title?: string;
  showImage?: boolean;
}

export interface ShiftModalArgs extends ShiftHeaderArgs {
  timeSlots?: TimeSlotsObject[];
  type?: ShiftWorkingHourType;
}

export enum RotaModalType {
  HIDE = 'HIDE',
  EMPLOYEE_MODAL_ACTIONS = 'EMPLOYEE_MODAL_ACTIONS',
  EMPLOYEE_DAY_EDIT = 'EMPLOYEE_DAY_EDIT',
  EMPLOYEE_SCHEDULE_CHANGE = 'EMPLOYEE_SCHEDULE_CHANGE',
  EMPLOYEE_SCHEDULE_NEW = 'EMPLOYEE_SCHEDULE_NEW',
  EMPLOYEE_SCHEDULE_EDIT = 'EMPLOYEE_SCHEDULE_EDIT',
  EMPLOYEE_TIME_OFF_EDIT = 'EMPLOYEE_TIME_OFF_EDIT',
  EMPLOYEE_REMOVE_CUSTOM_EDIT = 'EMPLOYEE_REMOVE_CUSTOM_EDIT',
  VENUE_DAY_EDIT = 'VENUE_DAY_EDIT',
  VENUE_MODAL_ACTIONS = 'VENUE_MODAL_ACTIONS',
}

export enum ScheduleChangeOptionType {
  RESET = 'RESET',
  NEW = 'NEW',
}

export enum WeekDays {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum WeekNumber {
  WEEK_ONE = 'WEEK_ONE',
  WEEK_TWO = 'WEEK_TWO',
}

export enum ShiftWorkingHourType {
  DAY_OFF = 'DAY_OFF',
  TIME_OFF = 'TIME_OFF',
  CUSTOM = 'CUSTOM',
  SCHEDULE = 'SCHEDULE',
  STANDARD = 'STANDARD',
  VENUE = 'VENUE',
}

export enum ShiftCustomTypes {
  CUSTOM_DAY_OFF = 'CUSTOM_DAY_OFF',
  CUSTOM_WORKING_DAY = 'CUSTOM_WORKING_DAY',
}

export enum ScheduleType {
  WEEKLY = 'WEEKLY',
}

export enum LoadingStatus {
  FETCHING = 'FETCHING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIAL = 'INITIAL',
}

export interface DateRangeObject {
  dateFrom: string;
  dateTo: string;
}

export interface TimeSlotsObject {
  timeFrom: string;
  timeTo: string;
}

export interface ScheduleObject {
  scheduleId: number;
  validFrom: string;
  validTo?: string;
}

export interface WorkingHoursObject {
  date: string;
  timeSlots: TimeSlotsObject[];
  type: ShiftWorkingHourType;
  schedule?: ScheduleObject;
}

export interface RotaWorkingHoursObject {
  timeSlots: TimeSlotsObject[];
  isClosed?: boolean;
}

export interface VenueShiftsObject {
  businessHours: VenueBusinessHoursObject[];
  workingHours: WorkingHoursObject[];
}

export interface Employee {
  employeeId: number;
  employeeImage?: string;
  employeeName: string;
  workingHours: WorkingHoursObject[];
}

export interface EmployeeWorkingHours {
  employeeId: number;
  employeeWorkingHours: WorkingHoursObject[];
}

export interface SetShiftsObject {
  employeeId: number;
  date: string;
  workingHours: WorkingHoursObject;
}

export interface EmployeeShiftsArray {
  employeesWorkingHours: Employee[];
}

export interface VenueBusinessHoursObject {
  closingTime: string;
  dayOfWeek: string;
  openingTime: string;
}

export interface DailySchedulesObject {
  dayOfWeek: WeekDays;
  timeSlots: TimeSlotsObject[];
  weekNumber: WeekNumber;
}

export interface Schedules {
  dailySchedules: DailySchedulesObject[];
  type: ScheduleType;
  validFrom: string;
  validTo?: string | undefined;
}

export interface GetEmployeesSchedulePayload {
  employeeId: number;
  scheduleId: number;
}

export interface InvalidateEmployeesSchedulePayload {
  employeeId: number;
  scheduleId: number;
  invalidFrom: string;
  dateTo: string;
}

/*
 * Action Payloads
 */

export interface RequestEmployeesWorkingHoursPayload extends DateRangeObject {
  employeeId?: number | null;
}

export interface RequestEmployeesMonthWorkingHoursPayload extends DateRangeObject {
  employeeId: number;
}

export interface RequestEmployeesDayPayload {
  date: string;
}

export interface SetEmployeesShiftPayload {
  employeeId: number;
  date: string;
  type: ShiftWorkingHourType;
  timeSlots: TimeSlotsObject;
  venueTimeSlots: TimeSlotsObject;
  fetchShiftData?: boolean;
}

export interface SetEmployeeOpenDayPayload {
  employeeId: number;
  dates: string[];
  dateFrom?: string;
  dateTo?: string;
  types?: ShiftCustomTypes[];
}

export interface SetEmployeeTimeOffPayload {
  employeeId: number;
  dates: string[];
  dateFrom?: string;
  dateTo?: string;
}

export interface SetEmployeeSchedulePayload {
  employeeId: number;
  dailySchedules: DailySchedulesObject[];
  dateTo: string;
  validFrom: string;
  type: ScheduleType;
}

export interface SetVenueWorkingHoursPayload {
  date: string;
  type: ShiftWorkingHourType;
  timeSlots: TimeSlotsObject;
}
