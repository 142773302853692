import React from 'react';
import Wahanda from 'common/wahanda';
import { toClj } from 'mori';
import Formsy from 'formsy-react';
import App from 'common/backbone-app';
import _ from 'common/underscore';

import customerDuplicateCheck from 'utilities/customer-duplicate-check';
import UnderlinedInput from 'components/common/UnderlinedInput/withFormsy';
import SelectDropdown from 'components/common/SelectDropdown/withFormsy';
import { InputError } from 'components/common/__BaseCommon';
import { Checkbox } from 'components/common/Checkbox';
import Loader from 'components/common/Loader';
import { Textarea } from 'components/common/Textarea';
import { Modal, ModalBody, ModalFooter } from 'components/common/Modal';
import { Button } from 'components/common/Button';

import {
  birthDayValidator,
  birthYearValidator,
  emailValidator,
  phoneValidator,
} from 'common/validators';
import { ClientAnalytics } from '../tracking';

import style from './CustomerFormDialog.scss';

interface EditFormObject {
  birthDay?: number | null;
  birthMonth?: number | null;
  birthYear?: number | null;
  emailAddress: string | null;
  gender?: string | null;
  locale?: string | null;
  name: string | null;
  notes?: string;
  phone: string;
  prepaymentRequired: boolean;
  sendMassEmails: boolean;
}

interface Props extends EditFormObject {
  supportsMultiplePaymentOptions: boolean;
  active: boolean;
  actions: {
    resetCustomerAction: () => void;
    requestCustomerAction: (id) => void;
    submitCustomerAction: () => void;
    setCustomerAction: (params) => void;
    setDuplicateCustomerDataAction: (params) => void;
  };
  fieldToFocus?: string;
  customerId?: string;
  customerData?: EditFormObject;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  submitting: boolean;
  serverError?: string;
}

interface State {
  isFormValid: boolean;
  isSubmitting: boolean;
  showServerError: boolean;
  editForm: EditFormObject;
}

const LANG = Wahanda.lang;
const GDPR_LANG = LANG.inlineClientEditing.messages;
const CONSUMER_FIELDS_LANG = LANG.consumer.editForm.labels;

const genderSelectData = [
  {
    name: CONSUMER_FIELDS_LANG.genderNotSpecified,
  },
  {
    name: CONSUMER_FIELDS_LANG.female,
    value: 'F',
  },
  {
    name: CONSUMER_FIELDS_LANG.male,
    value: 'M',
  },
];

const initialFormState = {
  birthDay: null,
  birthMonth: null,
  birthYear: null,
  emailAddress: null,
  gender: null,
  locale: null,
  name: null,
  notes: '',
  phone: '',
  prepaymentRequired: false,
  sendMassEmails: false,
};

export class CustomerFormDialog extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isFormValid: false,
      isSubmitting: props.submitting,
      showServerError: false,
      editForm: { ...initialFormState },
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.setupLocaleSelect = this.setupLocaleSelect.bind(this);
    this.getServerErrorMessage = this.getServerErrorMessage.bind(this);

    this.submitButton = React.createRef<HTMLDivElement>();
    this.formRef = React.createRef<HTMLFormElement>();
  }

  public static defaultProps = {
    loading: false,
    submitting: false,
  };

  public componentDidMount() {
    const { customerData, customerId } = this.props;

    // reset customer state
    this.props.actions.resetCustomerAction();

    if (customerId) {
      this.props.actions.setCustomerAction({ id: customerId });
      this.props.actions.requestCustomerAction(customerId);
    } else if (customerData) {
      this.props.actions.setCustomerAction(customerData);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.name !== this.props.name) {
      const editForm = { ...this.getEditFormValues(this.props) };
      this.setState({ editForm });
    }

    if (prevProps.submitting && !this.props.submitting) {
      if (this.props.serverError) {
        this.onInvalid();
        this.setState({ showServerError: true });
      }

      if (this.props.onSubmit && !this.props.serverError) {
        this.props.onSubmit();
      }
    }
  }

  public componentWillUnmount() {
    this.props.actions.resetCustomerAction();
  }

  private submitButton: React.RefObject<HTMLDivElement>;

  private formRef: any;

  private getEditFormValues(props) {
    return {
      birthDay: props.birthDay,
      birthMonth: props.birthMonth,
      birthYear: props.birthYear,
      emailAddress: props.emailAddress,
      gender: props.gender,
      notes: props.notes,
      name: props.name || props.customerData?.name,
      locale: props.locale,
      phone: props.phone,
      sendMassEmails: props.sendMassEmails,
      prepaymentRequired: props.prepaymentRequired,
    };
  }

  private onValid = () => {
    if (!this.state.isFormValid) {
      this.setState({
        isFormValid: true,
      });
    }
  };

  private onInvalid = () => {
    if (this.state.isFormValid) {
      this.setState({
        isFormValid: false,
      });
    }
  };

  private hasMultiLocale() {
    const supportedLocales = App.config.getChannelSupportedLocales();
    return supportedLocales ? Object.keys(supportedLocales).length > 1 : false;
  }

  private setupLocaleSelect() {
    const supportedLocales = App.config.getChannelSupportedLocales();
    const options = [] as any[];

    if (!supportedLocales) {
      return null;
    }

    _.each(App.config.getChannelSupportedLocales(), (value, name) => {
      options.push({
        value,
        name: LANG.language[value] || name,
      });
    });
    options.unshift({ value: null, name: LANG.consumer.editForm.labels.lang });

    return options;
  }

  private setupBirthMonthSelect() {
    const birthMonths = LANG.date.months.map((name, i) => ({
      name,
      value: i + 1,
    }));
    birthMonths.unshift({
      name: LANG.consumer.editForm.labels.month,
      value: null,
    });

    return birthMonths;
  }

  private onFieldChange(formFieldName: string) {
    return (e) => {
      let updatedValue;

      switch (formFieldName) {
        case 'sendMassEmails':
        case 'prepaymentRequired':
          updatedValue = !this.state.editForm[formFieldName];
          break;
        case 'birthDay':
        case 'birthYear':
          updatedValue = e.target.value ? e.target.value : null;
          break;
        case 'birthMonth':
        case 'gender':
        case 'locale':
          updatedValue = e;
          break;
        case 'notes':
          updatedValue = e;
          break;
        default:
          updatedValue = e.target.value;
          break;
      }

      const editForm = {
        ...this.state.editForm,
        [formFieldName]: updatedValue,
      };
      this.setState({ editForm, showServerError: false });
    };
  }

  private getChangedCustomerData() {
    const { name, phone, emailAddress } = this.state.editForm;
    const customerData = {
      name: this.props.name === name ? undefined : name,
      phone: this.props.phone === phone ? undefined : phone,
      emailAddress: this.props.emailAddress === emailAddress ? undefined : emailAddress,
    };

    if (this.shouldCheckForDuplicate()) {
      return customerData;
    }
    return { name, phone, emailAddress };
  }

  private async checkDuplicate() {
    const customerData: any = this.getChangedCustomerData();
    const el = this.submitButton.current;

    try {
      const data = await customerDuplicateCheck(customerData, el);
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      if (data.createNew) {
        // No dupe found - continue saving.
        this.saveForm(this.state.editForm);
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      } else if (data.customerData) {
        // A duplicate was found and the user chose one to use instead.
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        this.props.actions.setDuplicateCustomerDataAction(data.customerData);
        if (this.props.onClose) {
          this.props.onClose();
        }
      }
      this.setState({ isSubmitting: false });
    } catch (err) {
      this.setState({ isSubmitting: false });
      console.error(err);
    }
  }

  private saveForm(data) {
    this.props.actions.setCustomerAction(data);
    this.props.actions.submitCustomerAction();
  }

  private validateForm = () => {
    this.formRef.submit();
    return this.state.isFormValid;
  };

  private shouldCheckForDuplicate = () => {
    const { emailAddress, phone } = this.props;
    const { emailAddress: newEmailAddress, phone: newPhone } = this.state.editForm;

    if (newEmailAddress && emailAddress !== newEmailAddress) {
      return true;
    }

    if (newPhone && phone !== newPhone) {
      return true;
    }

    return false;
  };

  private onSubmit() {
    const { customerId, active } = this.props;
    const data = { ...this.state.editForm, active, id: customerId };

    if (!this.validateForm()) {
      return;
    }

    const { prepaymentRequired } = this.state.editForm;
    const isPrepayChanged = prepaymentRequired !== this.props.prepaymentRequired;

    if (isPrepayChanged) {
      ClientAnalytics.trackPrepayRequirementChanged(prepaymentRequired);
    }

    this.setState({
      isSubmitting: true,
    });

    if (!customerId || this.shouldCheckForDuplicate()) {
      this.checkDuplicate();
    } else {
      this.saveForm(data);
    }
  }

  private getServerErrorMessage() {
    const { serverError } = this.props;
    switch (serverError) {
      case 'invalid-venue-customer-email':
        return Wahanda.lang.validate.defaults.email;
      default:
        return Wahanda.lang.shared.errors.labels.savingFailed;
    }
  }

  public render() {
    const { onClose, supportsMultiplePaymentOptions } = this.props;
    const {
      editForm: { name },
      showServerError,
    } = this.state;

    const isDefaultRequiredValue = Wahanda.lang.validate.defaults.required;
    const isBirthMonthRequired = !!this.state.editForm.birthDay && !this.state.editForm.birthMonth;
    const isBirthDayRequired = !!this.state.editForm.birthMonth && !this.state.editForm.birthDay;

    return (
      <Modal
        dataTest="customer-form-modal"
        onClose={onClose}
        type="small"
        title={LANG.consumer.editForm.title}
      >
        <ModalBody noPadding>
          <div data-hj-suppress>
            {this.props.loading || (this.state.isSubmitting && <Loader positionAbsolute />)}
            <Formsy
              className={style.wrapper}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              ref={(form) => {
                this.formRef = form;
              }}
            >
              <div className={style.separator}>
                <UnderlinedInput
                  autoFocus={this.props.customerId == null || this.props.fieldToFocus === 'name'}
                  formsyClass={style.field}
                  maxLength={100}
                  name="name"
                  onChange={this.onFieldChange('name')}
                  value={name}
                  label={CONSUMER_FIELDS_LANG.name}
                  validationErrors={{ isDefaultRequiredValue }}
                  required
                />
                <div className={style.row}>
                  <UnderlinedInput
                    autoFocus={this.props.fieldToFocus === 'phone'}
                    formsyClass={`${style.field} ${style.col6}`}
                    maxLength={100}
                    name="phone"
                    type="phone"
                    onChange={this.onFieldChange('phone')}
                    value={this.state.editForm.phone}
                    label={CONSUMER_FIELDS_LANG.phone}
                    validations={{
                      [phoneValidator.KEY]: phoneValidator.VALIDATOR,
                    }}
                    validationErrors={{ isDefaultRequiredValue }}
                    required={!this.state.editForm.emailAddress}
                  />
                  <div className={style.col6}>
                    <UnderlinedInput
                      autoFocus={this.props.fieldToFocus === 'email'}
                      formsyClass={style.field}
                      maxLength={100}
                      name="emailAddress"
                      type="email"
                      onChange={this.onFieldChange('emailAddress')}
                      value={this.state.editForm.emailAddress}
                      label={CONSUMER_FIELDS_LANG.email}
                      validations={{
                        [emailValidator.KEY]: emailValidator.VALIDATOR,
                      }}
                      validationErrors={{ isDefaultRequiredValue }}
                      hasError={showServerError}
                      required={!this.state.editForm.phone}
                    />
                    {showServerError && <InputError message={this.getServerErrorMessage()} />}
                  </div>
                </div>
              </div>
              <div className={style.gdprMessage}>
                <div className={style.field}>
                  <div
                    className={style.checkboxHeader}
                    dangerouslySetInnerHTML={{ __html: GDPR_LANG.gdprHeader }}
                  />
                  <div className={style.checkboxWrapper}>
                    <Checkbox
                      name="sendMassEmails"
                      checked={this.state.editForm.sendMassEmails}
                      onChange={this.onFieldChange('sendMassEmails') as any}
                      label={<span dangerouslySetInnerHTML={{ __html: GDPR_LANG.gdprMessage }} />}
                    />
                  </div>
                </div>
                {supportsMultiplePaymentOptions && (
                  <div className={style.field}>
                    <div className={style.checkboxHeader}>
                      {CONSUMER_FIELDS_LANG.prepaymentRequiredHeader}
                    </div>
                    <div className={style.checkboxWrapper}>
                      <Checkbox
                        name="prepaymentRequired"
                        dataTest="customer-form-dialog-prepay-checkbox"
                        checked={this.state.editForm.prepaymentRequired}
                        onChange={this.onFieldChange('prepaymentRequired') as any}
                        label={CONSUMER_FIELDS_LANG.prepaymentRequired}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={style.separator}>
                <div className={style.field}>
                  <div className={style.label}>{CONSUMER_FIELDS_LANG.gender}</div>
                  <SelectDropdown
                    name="gender"
                    selected={this.state.editForm.gender || ''}
                    data={toClj(genderSelectData)}
                    onSelect={this.onFieldChange('gender')}
                  />
                </div>
                {this.hasMultiLocale() && (
                  <div className={style.field}>
                    <div className={style.label}>{CONSUMER_FIELDS_LANG.lang}</div>
                    <SelectDropdown
                      name="locale"
                      selected={this.state.editForm.locale || ''}
                      data={toClj(this.setupLocaleSelect())}
                      onSelect={this.onFieldChange('locale')}
                    />
                  </div>
                )}
                <div className={style.field}>
                  <div className={style.row}>
                    <div className={`${style.field} ${style.col4}`}>
                      <div className={style.label}>{CONSUMER_FIELDS_LANG.birth.month}</div>
                      <SelectDropdown
                        name="birthMonth"
                        selected={this.state.editForm.birthMonth as number}
                        data={toClj(this.setupBirthMonthSelect())}
                        onSelect={this.onFieldChange('birthMonth')}
                        validationErrors={{ isDefaultRequiredValue }}
                        required={isBirthMonthRequired}
                      />
                    </div>
                    <UnderlinedInput
                      formsyClass={`${style.field} ${style.col4}`}
                      name="birthDay"
                      min={birthDayValidator.MIN}
                      max={birthDayValidator.MAX}
                      onChange={this.onFieldChange('birthDay')}
                      value={this.state.editForm.birthDay}
                      validationErrors={{ isDefaultRequiredValue }}
                      validations={{
                        [birthDayValidator.KEY]: birthDayValidator.VALIDATOR,
                      }}
                      label={CONSUMER_FIELDS_LANG.birth.day}
                      required={isBirthDayRequired}
                    />
                    <UnderlinedInput
                      formsyClass={`${style.field} ${style.col4}`}
                      name="birthYear"
                      min={birthYearValidator.MIN}
                      onChange={this.onFieldChange('birthYear')}
                      value={this.state.editForm.birthYear}
                      validations={{
                        [birthYearValidator.KEY]: birthYearValidator.VALIDATOR,
                      }}
                      label={CONSUMER_FIELDS_LANG.birth.year}
                    />
                  </div>
                </div>
                <Textarea
                  label={CONSUMER_FIELDS_LANG.notes}
                  maxRows={10}
                  onChange={this.onFieldChange('notes')}
                  value={this.state.editForm.notes}
                  hasDebounce
                />
              </div>
            </Formsy>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            label={Wahanda.lang.shared.buttons.cancel}
            variant="secondary"
            colour="plain"
            onClick={onClose}
          />
          <div className={style.buttonWrapper} ref={this.submitButton}>
            <Button
              dataTest="customer-form-dialog-submit"
              label={Wahanda.lang.shared.buttons.save}
              onClick={this.onSubmit}
            />
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
