import React from 'react';
import ReactDOM from 'react-dom';
import Wahanda from 'common/wahanda';
import RebookingChangesConfirm from 'components/calendar/CalendarEventEditor/dialogs/RebookingChangesConfirm';

function onClose(container) {
  ReactDOM.unmountComponentAtNode(container);
}

export default function (options) {
  const container = Wahanda.Dialogs.getReactContainer();
  const close = () => {
    onClose(container);
  };
  const dialog = <RebookingChangesConfirm {...options} onClose={close} />;
  ReactDOM.render(dialog, container);
}
