import { connect } from 'react-redux';
import App from 'common/backbone-app';
import { EmployeeFilter as Component } from './index';
import { requestVenueEmployeeDataAction } from '../actions';

const mapStateToProps = (state) =>
  Object.assign(
    {},
    {
      employeeDetails: state.salesReport.filterData.employees,
    },
  );

const mapDispatchToProps = (dispatch) => ({
  loadVenueEmployeeDetails: () => {
    const venueId = App.getVenueId();
    dispatch(requestVenueEmployeeDataAction(venueId));
  },
});

export const EmployeeFilter = connect(mapStateToProps, mapDispatchToProps)(Component);
