import React from 'react';
import Wahanda from 'common/wahanda';

/*
 *  types are defined in common/CircleRating
 */
function getCircle({ radius, strokeWidth, ...moreProps }: any) {
  const circleRadius = radius - strokeWidth / 2;
  const { style, className, transform } = moreProps;
  return (
    <circle
      cx={radius}
      cy={radius}
      r={circleRadius}
      style={style}
      className={className}
      transform={transform}
    />
  );
}

function getInnerCircle(props) {
  const middle = props.radius;
  const radius = props.radius - props.strokeWidth / 2;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - dashArray * (props.percentage / 100);
  // Workaround for iOS (e.g. iPad on iOS 10.1.1), which ignored rotation by multiples of 90.
  const circleRotationDeg = Wahanda.Device.isIOS() ? 89.9 : 90;
  return getCircle({
    // This part fills the circle only partially, starting from right
    style: {
      strokeDasharray: dashArray,
      strokeDashoffset: dashOffset,
    },
    // Transform so that the progress starts at the bottom
    transform: `rotate(${circleRotationDeg} ${middle} ${middle})`,
    ...props,
  });
}

interface ICircleRatingProps extends React.HTMLAttributes<Element> {
  radius?: number;
  className?: string;
  percentage: number;
  strokeWidth?: number;
}

export const CircleRating = ({
  radius = 50,
  className = 'circle-rating',
  strokeWidth = 8,
  ...props
}: ICircleRatingProps) => {
  const wh = radius * 2;
  const percentage = Math.round(props.percentage);
  return (
    <svg width={wh} height={wh} className={className}>
      {getCircle({ radius, strokeWidth, className: 'outer' })}
      {getInnerCircle({
        radius,
        strokeWidth,
        percentage,
        className: 'inner',
      })}
      <text className="percentage" x={radius} y={radius} dy=".4em">
        {`${percentage}%`}
      </text>
    </svg>
  );
};
