import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Clock extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
        return (
          <svg
            className={['icon-svg', 'svg-clock', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 12 12"
          >
            <path d="M6,10 C8.209139,10 10,8.209139 10,6 C10,3.790861 8.209139,2 6,2 C3.790861,2 2,3.790861 2,6 C2,8.209139 3.790861,10 6,10 Z M6,0 C9.3137085,0 12,2.6862915 12,6 C12,9.3137085 9.3137085,12 6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 Z M7,3 L5,3 L5,6.41421356 L7.29289322,8.70710678 L8.70710678,7.29289322 L7,5.58578644 L7,3 Z" />
          </svg>
        );
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-clock', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2zm1 2v3.586l2.207 2.207-1.414 1.414L7 8.414V4h2z"
              fillRule="nonzero"
            />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-clock', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2zm1 2v3.586l2.207 2.207-1.414 1.414L7 8.414V4h2z"
              fillRule="nonzero"
            />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Clock);
