App.Views.Partials.ConsumerBlock = BackboneEx.View.Base.extend({
  events: {
    'autocompleteselect #consumer-block-search': 'onConsumerSelected',
    'keyup #consumer-block-search': 'onConsumerSearchChanged',
    'click .a-change-consumer': 'onResetConsumer',
    'click .a-walk-in': 'onWalkInClick',
    'click .a-show-customer': 'showConsumer',
  },
  initialize: function () {
    this.renderMarkup();

    this.isAnonymous = false;
    this.selectedConsumer = null;
  },

  renderMarkup: function () {
    this.$el.empty();

    this.$el.append($('#consumer-block-template').html());

    this.$('#consumer-block-search').userAutocomplete({
      source: App.Api.wsUrl(
        '/venue/' + App.config.get('venue').id + '/search.json?include=consumers',
      ),
    });

    if (!Wahanda.Permissions.editOwnCalendar()) {
      this.$('.a-new-consumer').remove();
    }
  },

  render: function () {},

  /**
   * Toggle search vs. walk-in comment inputs.
   */
  _toggleInputs: function () {
    this.$('#consumer-block-anonymous-comment').wToggle(this.isAnonymous);
    this.$('#consumer-block-search').wToggle(!this.isAnonymous);
  },

  /**
   * Returns if the block is valid and whole form can be saved.
   */
  isValid: function () {
    return this.isAnonymous || this.selectedConsumer != null;
  },

  /**
   * Returns the current state's values.
   *
   * @return Array
   */
  getValues: function () {
    var values = {
      anonymous: this.isAnonymous,
      consumer: this.selectedConsumer,
      note: null,
      search: this.$('#consumer-block-search').val(),
    };

    if (this.isAnonymous) {
      values.note = this.$('#consumer-block-anonymous-comment').val();
    }

    return values;
  },

  renderConsumer: function (consumer, notChangeable, notEditable) {
    // Re-render the markup because there could be some irreversible changes done to it.
    this.renderMarkup();

    this.selectedConsumer = consumer;
    this.isAnonymous = false;

    // do not allow to change consumer if appointmet is saved
    if (this.options.isLocked) {
      notChangeable = true;
    }

    this.$('.b-consumer-autocomplete').wHide();
    this.$('.b-consumer-buttons').wHide();
    this.$('.b-person-info').find('.a-change-consumer').toggle(!notChangeable);

    this.$('#consumer-block-search').val('');

    this.$('.f-consumer-name').text(consumer.name);

    var phone, email;
    if (Wahanda.Permissions.viewClientContactDetails()) {
      phone = consumer.phone;
      email = consumer.emailAddress;
    }

    this.renderAttribute('consumer-phone', phone);
    this.renderAttribute('consumer-email', email);

    // Disable editing the client
    if (notEditable) {
      // Making text non-clickable
      var $consumerName = $('.b-consumer-name');
      $consumerName.text($consumerName.text());
      // Removing edit button
      this.$('.a-consumer-edit').remove();
    } else if (!Wahanda.Permissions.editClientContactDetails()) {
      this.$('.a-consumer-edit').remove();
    }

    this.$('.b-person-info').wShow();

    // Notes
    this.renderAttribute('customer-note', consumer.notes);
    if (consumer.notes != null && consumer.notes != '') {
      var $noteSpan = this.$('.v-customer-note');
      // If the note is fully visible, remove the link to open the Customer dialog.
      if ($noteSpan.height() == $noteSpan.prop('scrollHeight')) {
        $noteSpan.unwrap();
      }
    }
  },

  renderWalkIn: function (comment) {
    this.selectedConsumer = null;
    this.isAnonymous = true;

    this.$('.b-consumer-buttons').wHide();
    this.$('.b-walkin').wShow();

    // Copy search field data into the walk-in field
    this.$('#consumer-block-anonymous-comment').val(comment);

    this._toggleInputs();
  },

  // Events

  onConsumerSelected: function (event, ui) {
    this.renderConsumer(ui.item);
  },

  onConsumerSearchChanged: function (event) {
    // Commented because those buttons must be shown all the time for UX purpose.
    //		var $input = $(event.target);
    //		this.$('.b-consumer-buttons').wToggle($input.val().length > 0);
  },

  onResetConsumer: function (event) {
    this.selectedConsumer = null;
    var $searchField = this.$('#consumer-block-search');
    if (this.isAnonymous) {
      $searchField.val('');
      this.isAnonymous = false;
    } else {
      $searchField.val('');
    }

    this.$('.b-person-info').wHide();
    this.$('.b-walkin').wHide();

    this.$('.b-consumer-autocomplete').wShow();
    this.$('.b-consumer-buttons').wShow();

    this._toggleInputs();
    $searchField.focus();
  },

  onWalkInClick: function () {
    this.renderWalkIn(this.$('#consumer-block-search').val());
    this.$('#consumer-block-anonymous-comment').focus();
  },

  showConsumer: function (event) {
    var view = App.Views.Dialog.Consumer.createInstance(this.selectedConsumer.id);
    view.render();
    view.open();
  },
});
