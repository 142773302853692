import React from 'react';
import { partial } from 'mori';
import Wahanda from 'common/wahanda';
import { trackEvent } from 'common/analytics';
import { ProgressBar } from 'components/common/ProgressBar';
import style from '../../style.css';
import SubBlock, { ICON_EXCLAMATION } from '../../common/SubBlock';
import SubBlockText, { makeBold } from '../../common/SubBlockText';
import maybeGetLinesFromLang from '../../common/lang-lines';
import maybeBuildButton from '../../common/button-structure';

const LANG = Wahanda.lang.dashboard.trafficLights.rating.notEnoughVotes;
interface ILowVotesProps extends React.HTMLAttributes<Element> {
  ratingCount?: number;
  threshold?: number;
}

export default class LowVotes extends React.Component<ILowVotesProps, {}> {
  render() {
    const { ratingCount, threshold } = this.props;
    const textLines = maybeGetLinesFromLang(LANG, 'intro');
    const button = maybeBuildButton({
      title: Wahanda.lang.dashboard.trafficLights.shared.learnMore,
      link: LANG.link,
      onClick: partial(
        trackEvent,
        'connect-dashboard',
        'scoring-button-clicked',
        'from not enough rates',
      ),
    });
    return (
      <SubBlock title={LANG.title} name="subblock-bar" icon={ICON_EXCLAMATION} lighterBorder>
        <SubBlockText button={button}>
          {textLines.map((line) => (
            <div key={line}>{line}</div>
          ))}
          {makeBold(`${Wahanda.lang.dashboard.trafficLights.shared.goal}: `)}
          {Wahanda.Template.render(LANG.goal, { threshold })}
        </SubBlockText>
        <div className={style.progressBar}>
          <ProgressBar
            colour="danger"
            // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type 'number... Remove this comment to see the full error message
            value={ratingCount}
            maxValue={threshold}
            label={ratingCount}
          />
        </div>
      </SubBlock>
    );
  }
}
