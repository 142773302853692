/* global Backbone */

import { fetchProduct as fetchVenueProduct } from 'src/services/ProductsService';
import { appState } from 'state';
import { get, vector } from 'mori';
import { cursorValueHasChanged, withAnyCursor } from 'atom/cursors';
import {
  trackEvent,
  CheckoutProductAnalytics,
  CheckoutVoucherAnalytics,
  CheckoutAnalytics,
} from 'common/analytics';
import App from 'common/backbone-app';
import _ from 'common/underscore';

if (!App.Views.POS) {
  App.Views.POS = {};
}

App.Views.POS.AddProduct = Backbone.View.extend({
  events: {
    'click .js-add-product-row': 'showProductForm',
    'click .js-add-voucher-row': 'showVoucherForm',
  },

  initialize: function initialize() {
    const self = this;
    this.loadProductList();

    appState.addWatch(
      'posCheckoutProduct',
      withAnyCursor(
        (oldState, newState) => {
          const editProduct = get(newState, 'edit-product');
          if (editProduct) {
            App.ES6.Initializers.ProductDialog({
              editProduct,
              vatRates: App.getVenueChannelVATRates(),
              onClose: (refetch) => {
                if (refetch === true) {
                  App.trigger(Wahanda.Event.PRODUCTS_LIST_CHANGED);
                  self.loadProductList(true);
                }
              },
            });
          }
        },
        vector(['edit-product']),
        cursorValueHasChanged,
      ),
    );

    this.controller = this.options.controller;
    this.controller.on('product-form:reset', this.show, this);
    this.controller.on('product-form:editing', this.hideAddProduct, this);
    this.resetModel();
  },

  loadProductList: function loadProductList(autoshow) {
    this.products = Wahanda.Cache.productList();
    const self = this;
    $.when(this.products).done((collection) => {
      self.productList = self.processProducts(collection);
      if (autoshow) {
        self.itemForm.updateProductList(self.productList);
        self.itemForm.onQuantityChange();
      }
    });
  },

  render: function render() {
    this.setElement($(Wahanda.Template.renderTemplate('pos-add-item')));
    return this;
  },

  processProducts: function processProducts(collection) {
    for (let i = 0; i < collection.length; i += 1) {
      collection.at(i).processProduct();
    }
    return collection.getProductArray();
  },

  showProductForm: function showProductForm(productList) {
    // trigger event to close others
    this.controller.trigger('product-form:reset');
    // add form
    if (!this.productList) {
      this.productList = this.processProducts(productList);
    }
    this.model.set('productList', this.productList);
    this.showItemForm({
      placeholder: Wahanda.lang.calendar.appointments.pos.productSearchPlaceholder,
      productList: this.productList,
      type: App.Models.POS.ProductDescriminators.PRODUCT,
    });
  },

  showVoucherForm: function showVoucherForm() {
    this.showItemForm({
      placeholder: Wahanda.lang.calendar.appointments.pos.addVoucherPlaceholder,
      type: App.Models.POS.ProductDescriminators.VOUCHER,
    });
  },

  showItemForm: function showItemForm(options) {
    const defaultItemFormOptions = {
      model: this.model,
      controller: this.controller,
    };
    this.itemForm = new App.Views.POS.ItemForm(_.extend(defaultItemFormOptions, options));
    this.$el.after(this.itemForm.$el).hide();
    this.itemForm.render();
    this.itemForm.$el.find('input.js-line-item-name')[0].focus();
  },

  resetModel: function resetModel() {
    this.model = new App.Models.POS.Product();
  },

  show: function show() {
    this.resetModel();
    this.$el.show();
  },

  hideAddProduct: function hideAddProduct() {
    this.$el.hide();
  },
});

App.Views.POS.ItemForm = App.Views.POS.LineItemForm.extend({
  className: 'product-form update pos-item-add--container',

  events: {
    'click .js-cancel-add-product': 'close',
    'click .js-edit-product': 'showEditProductDialog',
  },

  baseView: App.Views.POS.LineItemForm,
  addLineItemButtonClass: '.js-add-line-item',
  templateId: 'pos-item-form',

  initialize: function initialize() {
    this.baseView.prototype.initialize.apply(this, arguments);
    // Pick up the parent events so we don't have to do our own close, etc
    this.events = _.extend({}, this.baseView.prototype.events, this.events || {});
    this.controller.on('product-form:reset', this.destroy, this);
  },

  getSelectedEmployee: function getSelectedEmployee() {
    if (this.model.get('employeeId')) {
      return this.model.get('employeeId');
    }

    const appointments = this.controller.appointments;

    if (appointments == null || !appointments.length) {
      return parseInt(this.controller.getEmployeeId(), 10);
    }

    const firstAppointment = appointments[0];
    if (firstAppointment.isPackage) {
      return firstAppointment.skus[0].employeeId;
    }

    return firstAppointment.employeeId;
  },

  setupEmployeeDropdown: function setupEmployeeDropdown(employees) {
    const self = this;
    const selectedEmployee = self.getSelectedEmployee();
    const data = {
      employees: employees
        .map((employee) => ({
          id: employee.get('id'),
          name: employee.get('name'),
          selected: employee.get('id') === selectedEmployee,
        }))
        .sort((employee1, employee2) => employee1.name.localeCompare(employee2.name)),
    };
    self
      .$('.js-pos-item-select--item--employee')
      .html(Wahanda.Template.renderTemplate('pos-employee-sold-product-dropdown', data));

    self.$('.js-employee-select').selectmenu({
      appendTo: $('.js-outer-content'),
      width: 'auto',
      create: () => {
        // setting the initial value
        const newSelectedEmployee = data.employees.find((employee) => employee.selected);
        self.updateFormData('employeeId', selectedEmployee);
        self.updateFormData('employeeName', newSelectedEmployee.name);
      },
      change: (event, ui) => {
        if (self.options.type === App.Models.POS.ProductDescriminators.VOUCHER) {
          CheckoutVoucherAnalytics.trackSellingEmployeeUpdate();
        }
        if (self.options.type === App.Models.POS.ProductDescriminators.PRODUCT) {
          CheckoutProductAnalytics.trackSellingEmployeeUpdate();
        }
        const newSelectedEmployee = data.employees.find(
          (employee) => employee.id === parseInt(ui.value, 10),
        );
        self.updateFormData('employeeId', ui.value);
        self.updateFormData('employeeName', newSelectedEmployee.name);
      },
      open: () => {
        if (self.options.type === App.Models.POS.ProductDescriminators.VOUCHER) {
          CheckoutVoucherAnalytics.trackSellingEmployeeDropdownClick();
        }
        if (self.options.type === App.Models.POS.ProductDescriminators.PRODUCT) {
          CheckoutProductAnalytics.trackSellingEmployeeDropdownClick();
        }
      },
    });
  },

  updateAmount: function updateAmount(value) {
    this.updateFormData('unitPrice', Wahanda.Number.formatPOSInputintoFloat(value));
    this.updateFormData('formattedUnitPrice', Wahanda.Number.formatPOSInputintoFloat(value));
  },

  updateName: function updateName(value) {
    this.updateFormData('description', value);
  },

  setViewState: function setViewState() {
    this.baseView.prototype.setAddLineItemButtonState.apply(this, arguments);
  },

  setButtonText: function setButtonText() {
    const $labels = this.$el.find('.pos-checkout--button-labels');
    const labelType = this.isNew() ? 'add' : 'update';
    const label = $labels.data(`button-label-${labelType}`);
    this.$el.find('.js-add-line-item .js-add-product-title').text(label);
  },

  showEditProductDialog: function showEditProductDialog($event) {
    const productId = $($event.target).closest('[data-product-id]').attr('data-product-id');
    fetchVenueProduct(productId);
    trackEvent('product-line', 'click', 'stock-update');
  },

  render: function render() {
    this.baseView.prototype.render.apply(this, arguments);

    this.updateFormData('type', this.options.type);
    this.setButtonText();
    this.setViewState();
    this.setupQuantitySelector();
    this.setupAutocomplete();

    const self = this;
    Wahanda.Cache.allEmployees().done((employees) => {
      self.setupEmployeeDropdown(employees);
    });
    return this;
  },

  renderCurrencyInput: function renderCurrencyInput() {
    const view = new App.Views.POS.CurrencyInput({
      el: this.$('.js-currency-input'),
      name: 'product-price',
      cssClass: 'js-line-item-amount',
      cssContainerClass: 'currency-input--small',
      placeholder: Wahanda.Number.formatFloatForPOSInput(0),
      value: Wahanda.Number.formatFloatForPOSInput(this.model.get('formattedUnitPrice')),
    });
    view.render();
  },

  setupQuantitySelector: function setupQuantitySelector() {
    const self = this;

    this.quantitySelector = new App.Views.POS.QuantitySelector({
      el: this.$('.js-quantity-selector'),
      model: this.formData,
      onQuantityChange: self.onQuantityChange.bind(self),
    });
    this.quantitySelector.render();
  },

  onQuantityChange: function onQuantityChange() {
    const stock = this.formData.get('inventory');
    const quantity = this.quantitySelector.formData.get('quantity');

    if (stock != null && quantity > stock) {
      this.$el.find('.pos-item-select--item--stock-warning .stock').html(stock);
      this.$el.find('.pos-item-select--item--stock-warning').wShow();
      this.$el.find('.pos-item-select--item').addClass('with-errors');
    } else {
      this.$el.find('.pos-item-select--item--stock-warning').wHide();
      this.$el.find('.pos-item-select--item').removeClass('with-errors');
    }
  },

  updateProductList: function updateProductList(productList) {
    const self = this;
    this.options.productList = productList;
    this.setupAutocomplete();
    const item = self.options.productList.find((i) => i.id === self.formData.get('id'));
    self.$el
      .find('.js-line-item-name')
      .search('search', item.description)
      .search('select', item)
      .focus()
      .blur();
  },

  setupAutocomplete: function setupAutocomplete() {
    const self = this;
    const selectItem = (item) => {
      self.$el
        .find('.js-line-item-amount')
        .attr('value', Wahanda.Number.formatFloatForPOSInput(item.unitPrice) || 0);
      self.updateFormData('taxRate', item.taxRate);
      self.updateFormData('description', item.description);
      self.updateFormData('unitPrice', item.unitPrice);
      self.updateFormData(
        'formattedUnitPrice',
        Wahanda.Number.formatFloatForPOSInput(item.unitPrice),
      );
      self.updateFormData('id', item.id);
      self.updateFormData('inventory', item.inventory);
      self.onQuantityChange();
    };

    const source = () => {
      const filtered = _.filter(self.options.productList, (product) => {
        const match = self.controller.products.where({
          description: product.name,
        });
        return match.length === 0;
      });

      return {
        products: filtered,
      };
    };

    self.$el.find('.js-line-item-name').search({
      appendTo: self.$el,
      $loader: self.$el.find('.search-loader'),
      source: source,
      localSource: 'products',
      select: selectItem,
    });
  },

  isNew: function isNew() {
    return !this.controller.products.contains(this.model);
  },

  close: function close() {
    if (this.options.type === App.Models.POS.ProductDescriminators.VOUCHER) {
      App.trigger(Wahanda.Event.CHECK_OUT_VOUCHER_REMOVED);
    }
    if (this.options.type === App.Models.POS.ProductDescriminators.PRODUCT) {
      App.trigger(Wahanda.Event.PRODUCT_REMOVED);
    }
    this.controller.trigger('product-form:reset');
  },

  save: function save() {
    $('.js-line-item-name').blur();

    if (
      this.formData.attributes.discountPercentage != null &&
      this.model.get('discountPercentage') !== this.formData.attributes.discountPercentage
    ) {
      switch (this.formData.attributes.type) {
        case App.Models.POS.ProductDescriminators.VOUCHER:
          CheckoutVoucherAnalytics.trackUpdateDiscount(this.formData.attributes.discountPercentage);
          break;
        case App.Models.POS.ProductDescriminators.PRODUCT:
          CheckoutProductAnalytics.trackUpdateDiscount(this.formData.attributes.discountPercentage);
          break;
        default:
      }
    } else if (
      this.formData.attributes.discountPercentage == null &&
      this.model.get('discountPercentage') != null
    ) {
      switch (this.model.get('type')) {
        case App.Models.POS.ProductDescriminators.VOUCHER:
          CheckoutVoucherAnalytics.trackClearDiscount(this.formData.attributes.discountPercentage);
          break;
        case App.Models.POS.ProductDescriminators.PRODUCT:
          CheckoutProductAnalytics.trackClearDiscount(this.formData.attributes.discountPercentage);
          break;
        default:
      }
    }

    this.quantitySelector.save();
    const item = this.model.set(this.formData.attributes);
    if (this.model.isValid()) {
      if (item.get('type') === App.Models.POS.ProductDescriminators.VOUCHER) {
        App.trigger(Wahanda.Event.CHECK_OUT_VOUCHER_ADDED);
      }
      if (item.get('type') === App.Models.POS.ProductDescriminators.PRODUCT) {
        App.trigger(Wahanda.Event.CHECK_OUT_PRODUCT_ADDED);
      }
      this.controller.saveItem(item);
      this.controller.enablePOSSubmit();
    }
  },
});

App.Views.POS.ViewItem = App.Views.POS.LineItemView.extend({
  baseView: App.Views.POS.LineItemView,
  events: {
    'click .remove-product': 'removeProductRow',
    'click .pos-item-select--container ': 'showProductForm',
  },

  initialize: function initialize() {
    this.baseView.prototype.initialize.apply(this, arguments);
    this.controller.on('product-form:reset', this.show, this);
  },

  render: function render() {
    const self = this;
    const attrs = this.model.attributes;
    const totalProducts = Wahanda.Currency.getFormatted(self.model.getTotal());

    const discountedPrice =
      attrs.discountPercentage != null ? self.model.getTotalDiscounted() : null;

    const templateModel = _.extend(
      {
        total: totalProducts,
        priceWithQuantity: attrs.quantity <= 1 ? '' : `x ${attrs.quantity}`,
        isVoucher: attrs.type === App.Models.POS.ProductDescriminators.VOUCHER,
        discountedPrice: discountedPrice ? Wahanda.Currency.getFormatted(discountedPrice) : null,
      },
      attrs,
    );

    // Showing an item;
    this.$el.html(Wahanda.Template.renderTemplate('pos-view-product', templateModel));
    return this;
  },

  removeProductRow: function removeProductRow() {
    this.controller.products.remove(this.model);
    this.controller.removePayments();
    const containsSelectedAppointments = _.any(this.controller.appointments, (app) => app.selected);
    const containsProducts = this.controller.products.length > 0;
    if (!containsSelectedAppointments && !containsProducts) {
      this.controller.disablePOSSubmit();
    }
  },

  /**
   * Click handler for popup menu list items
   * Performs the action from the list item, in this case edit or delete
   * the form.
   * @param  {Object}
   */
  listItemAction: function listItemAction(data) {
    if (data.item.hasClass('edit')) {
      this.showProductForm();
    } else if (data.item.hasClass('delete')) {
      this.controller.products.remove(this.model);
    }
  },

  // TODO: same as for AddProduct
  showProductForm: function showProductForm(evt) {
    if ($(evt.target).hasClass('remove-product')) {
      return;
    }

    switch (this.model.get('type')) {
      case App.Models.POS.ProductDescriminators.VOUCHER:
        CheckoutAnalytics.trackEditVoucherClick();
        break;
      case App.Models.POS.ProductDescriminators.PRODUCT:
        CheckoutAnalytics.trackEditProductClick();
        break;
      default:
        break;
    }

    // trigger event to close others
    this.controller.trigger('product-form:reset');
    this.controller.trigger('product-form:editing');
    // add form
    const form = new App.Views.POS.ItemForm({
      model: this.model,
      controller: this.controller,
      type: this.model.get('type'),
    });
    this.$el.after(form.$el).hide();
    form.render();
    form.onQuantityChange();
  },

  // TODO: same as for AddProduct
  show: function show() {
    this.$el.show();
  },
});
