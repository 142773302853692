import { appState } from 'state';

import { xhr } from 'common/xhr';
import apiUrl from 'common/api-url';

export const POS_SETTINGS_KEY = 'pos-settings';

export function fetch() {
  xhr.get(appState, POS_SETTINGS_KEY, apiUrl('POS_SETTINGS'));
}
