import React from 'react';
import ReactDOM from 'react-dom';
import { assoc, curry } from 'mori';
import { appState } from 'state';
import Wahanda from 'common/wahanda';
import PosSettings from '../components/settings/pos';

let posSettingsChanged = false;

function onChange({ hasChanges }) {
  posSettingsChanged = hasChanges;
}

export function initPOSSettings(targetEl, venueId) {
  appState.swap(curry(assoc, 'venueId', venueId));
  ReactDOM.render(
    <PosSettings
      key={venueId}
      onChange={onChange}
      canEditReceiptFreeTextTop={Wahanda.Permissions.canEditReceiptFreeTextTop()}
    />,
    targetEl,
  );
}

export function unmountPOSSettings(targetEl) {
  ReactDOM.unmountComponentAtNode(targetEl);
}

export function canNavigateAway() {
  return !posSettingsChanged;
}
