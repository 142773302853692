import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Gift extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-gift', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M11,6 L13,6 L13,22 L11,22 L11,6 Z M4,8 L4,12 L20,12 L20,8 L4,8 Z M6,14 L6,20 L18,20 L18,16 L20,16 L20,20.0017887 C20,21.1053703 19.1054862,22 18.0059397,22 L5.99406028,22 C4.8927712,22 4,21.1125667 4,20.000385 L4,14 L2,14 L2,7.99810135 C2,6.89458045 2.89821238,6 3.99079514,6 L20.0092049,6 C21.1086907,6 22,6.88670635 22,7.99810135 L22,14 L6,14 Z M8,3 C7.31606658,3 7,3.26338882 7,4 C7,4.64995102 8.81823134,5.65265922 10.9215895,5.92796066 C10.6208195,3.9979049 9.4830338,3 8,3 Z M12.9392314,5.94505825 C13.6374247,5.86491818 14.3082247,5.70293162 14.8964909,5.49794831 C16.1295576,5.06828213 17,4.44970329 17,4 C17,3.26338882 16.6839334,3 16,3 C15.3919936,3 14.8420162,3.16772715 14.3839548,3.49865045 C14.1332371,2.84850661 13.8111534,2.25650297 13.4273108,1.73098223 C14.1674135,1.25465796 15.0417486,1 16,1 C17.7160666,1 19,2.06994452 19,4 C19,5.57957934 17.2514512,6.91991607 15,7.57334754 C14.3640962,7.75790386 13.6880742,7.88766346 13,7.95253989 L13,8 L12,8 C8.51857603,8 5,6.24071198 5,4 C5,2.06994452 6.28393342,1 8,1 C10.5943268,1 12.5735662,2.86658478 12.9392314,5.94505825 L12.9392314,5.94505825 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Gift);
