import React from 'react';
import classnames from 'classnames';
import style from './style.scss';

type ButtonProps = {
  className?: string;
  onClick?: (...args: any[]) => any;
};
const Button: React.SFC<ButtonProps> = ({ children, className, onClick }) => (
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
  <button className={classnames(style.button, style[className])} onClick={onClick}>
    {children}
  </button>
);
Button.defaultProps = {
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
  className: null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '((...args: ... Remove this comment to see the full error message
  onClick: null,
};

export default Button;
