import { trackEvent, ACTIONS } from 'common/analytics';

const CATEGORY = 'covid19-shutdown-exit';

export const Analytics = {
  trackHowToReopenClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'reopen-help-center');
  },
  trackReopenClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'reopen-button');
  },
  trackViewCampaignClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'view-campaign-button');
  },
  trackViewCampaignFromDialogClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'view-campaign-from-dialog');
  },
  trackVenueClosingRequestClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'venue-closing-click');
  },
};
