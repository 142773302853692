/**
 * Collection for Menu Template items.
 */
App.Collections.MenuTemplate = BackboneEx.Collection.Base.extend({
  model: App.Models.MenuTemplateItem,
  urlTemplate: '/venue/{{venueId}}/menu-template.json',

  parse: function (data) {
    return data.venueMenuTemplateGroups;
  },
});
