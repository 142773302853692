import { useQuery } from 'react-query';
import { FormTemplatesResponse } from './types';
import apiUrl from 'common/api-url';
import { get } from 'reduxStore/apiService';
import App from 'common/backbone-app';

const cacheKey = () => `FormTemplatesList_${App.getVenueId()}`;

export const useFormTemplates = () => {
  const { isLoading, isError, data: formTemplates, refetch } = useQuery(
    cacheKey(),
    () =>
      get<FormTemplatesResponse>(
        apiUrl('CONSULTATION_FORMS_TEMPLATES', {
          venueId: App.getVenueId(),
        }),
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    isError,
    formTemplates,
    refetch,
  };
};
