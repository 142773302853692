/**
 * POS checkout running cost view
 *
 * It listens to the passed in collection and when items are added or
 * removed it recalculates the balance due field.
 *
 */
(function () {
  if (!App.Views.POS) {
    App.Views.POS = {};
  }
  var BaseView = Backbone.View;
  App.Views.POS.RunningBalance = BaseView.extend({
    templateId: '',
    totalChange: 0,
    balanceDue: 0,

    initialize: function () {
      this.templateId = this.options.templateId;
      this.controller = this.options.controller;
      this.checkoutCalc = this.controller.checkoutCalc;
      this.collections = this.options.collections;

      _.each(
        this.collections,
        function (collection) {
          collection.on('add remove', this.render, this);
        },
        this,
      );
    },

    render: function () {
      var html = Wahanda.Template.renderTemplate(
        this.templateId,
        this.getDialogTemplateVariables(),
      );

      this.$el.html(html);
      this.hideOrShowBalance();
    },

    getDialogTemplateVariables: function () {
      var subTotal = Wahanda.Currency.getFormatted(Math.abs(this.checkoutCalc.getSubTotal()));

      var totalCost = Wahanda.Currency.getFormatted(Math.abs(this.checkoutCalc.getTotalCost()));

      this.balance = this.checkoutCalc.getOutstanding();
      if (this.balance < 0) {
        this.balance = 0;
      }
      this.balanceDue = Wahanda.Currency.getFormatted(this.balance);

      var totalPaid = this.checkoutCalc.getTotalPaid();
      var totalTips = this.getTips();
      var totalPrePaid = this.checkoutCalc.getPrepaidTotal();

      var overallTotalPaid = Wahanda.Currency.getFormatted(totalPaid + totalTips + totalPrePaid);

      this.change = this.checkoutCalc.getChange();
      this.totalChange = Wahanda.Currency.getFormatted(this.change);

      return {
        totalCost: totalCost,
        subtotal: subTotal,
        balanceDue: this.balanceDue,
        totalPaid: overallTotalPaid,
        totalChange: this.totalChange,
      };
    },

    hideOrShowBalance: function () {
      if (this.$el.hasClass('js-total-change')) {
        this.$el.toggle(this.change > 0);
      }

      if (this.$el.hasClass('js-total-paid')) {
        this.$el.find('.incl-tip').toggle(this.controller.tips.length > 0);
      }
      if (this.$el.hasClass('js-balance-due')) {
        this.$el.toggle(this.change <= 0);
      }
    },

    getTips: function () {
      if (this.controller.tips) {
        return this.controller.tips.getTotal();
      } else {
        return 0;
      }
    },
  });
})();
