import ReactDOM from 'react-dom';
import { Props, ImageEditDialog } from './';
import React from 'react';

const container = Wahanda.Dialogs.getReactContainer();

const mount = (component: JSX.Element) => {
  ReactDOM.render(component, container);
};

const unmount = () => {
  ReactDOM.unmountComponentAtNode(container);
};

export const useImageEditDialog = () => (props: Props) => {
  const onClose = () => {
    unmount();
    props.onClose && props.onClose();
  };
  return mount(<ImageEditDialog {...props} onClose={onClose} />);
};
