/* global jQuery */
import React from 'react';
import { get, toJs, map, filter } from 'mori';
import { withAnyCursor, cursorValueHasChanged } from 'atom/cursors';
import { appState } from 'state';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import { fetchConfig, configFetched } from 'src/actions/pos/fetchConfig';
import SelectElement from 'src/components/common/forms/select';
import { POS_SETTINGS_KEY } from 'src/services/posSettings';
import { VENUE_POS_SETTINGS_KEY, save as saveSettings } from 'src/services/venuePosSettings';
import { trackEvent } from 'common/analytics';
import ReceiptTextarea from './ReceiptTextarea';
import style from './style.scss';

const TEXT_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

function getFreeText(position) {
  const type = position === TEXT_POSITION.TOP ? 'Top' : 'Bottom';
  const venueSettings = get(appState.deref(), VENUE_POS_SETTINGS_KEY);
  return get(venueSettings, `receipt${type}CustomText`);
}

function getVenueReceiptSize() {
  const venueSettings = get(appState.deref(), VENUE_POS_SETTINGS_KEY);
  const widths = get(venueSettings, 'receiptPdfWidth');
  return get(widths, 'size');
}

function getChosenWidthOption(select) {
  const chosenValue = select.state.value;
  const existingWidths = get(get(appState.deref(), POS_SETTINGS_KEY), 'receiptPdfWidths');
  const chosenWidthObject = filter((width) => get(width, 'size') === chosenValue, existingWidths);
  return toJs(chosenWidthObject)[0];
}

function getSelectOptions(settings) {
  const toCamelCase = Wahanda.Text.toCamelCase;
  const widthSettings = map(
    (setting) => ({
      text: Wahanda.lang.settings.venue.pos.codes.width[toCamelCase(get(setting, 'code'))],
      value: get(setting, 'size'),
    }),
    get(settings, 'receiptPdfWidths'),
  );
  return widthSettings;
}

function setupTooltips() {
  /*
   *  TODO:
   *
   *  There's no need for this method to use DOM methods
   *  and then wrap the returned element with jQuery --
   *  it may as well use jQuery immediately!
   */
  const posContainer = document.getElementById('pos');
  const formContent = document.getElementById('pos-form-content');
  const opts = {
    position: {
      container: jQuery(formContent as any),
      viewport: true,
    },
  };
  jQuery(posContainer as any).tooltipize(opts);
}

interface IundefinedProps extends React.HTMLAttributes<Element> {
  onChange: (...args: any[]) => any;
  canEditReceiptFreeTextTop: boolean;
}
type undefinedState = {
  topText?: any;
  bottomText?: any;
  saving?: boolean;
};

export default class extends React.Component<IundefinedProps, undefinedState> {
  forceUpdate: any;

  posReceiptWidth: any;

  state = {
    topText: getFreeText(TEXT_POSITION.TOP),
    bottomText: getFreeText(TEXT_POSITION.BOTTOM),
    saving: false,
  };

  UNSAFE_componentWillMount() {
    fetchConfig();
    appState.addWatch(
      'posSettings',
      withAnyCursor(
        () => {
          // Render when one of important keys is changed
          this.setState({
            topText: getFreeText(TEXT_POSITION.TOP),
            bottomText: getFreeText(TEXT_POSITION.BOTTOM),
          });
          this.forceUpdate();
        },
        [[POS_SETTINGS_KEY], [VENUE_POS_SETTINGS_KEY]],
        cursorValueHasChanged,
      ),
    );
    this.props.onChange({ hasChanges: false });
  }

  componentDidUpdate() {
    setupTooltips();
  }

  onSubmit = (event) => {
    this.setState({ saving: true });
    if (event) {
      event.preventDefault();
    }
    const saveData = {
      receiptPdfWidth: toJs(getChosenWidthOption(this.posReceiptWidth)),
      receiptTopCustomText: this.state.topText,
      receiptBottomCustomText: this.state.bottomText,
    };
    saveSettings(saveData, this.saveComplete);
    this.props.onChange({ hasChanges: false });
    trackEvent('finance-point-of-sale', 'submit', 'save-point-of-sale-settings');
  };

  onPosReceiptWidthChange = (newValue) => {
    const oldValue = getVenueReceiptSize();
    const hasChanges = oldValue !== newValue;
    this.props.onChange({ hasChanges });
  };

  onTextareaChange = (position, value) => {
    const type = position === TEXT_POSITION.TOP ? 'topText' : 'bottomText';
    this.setState(
      {
        [type]: value,
      },
      () => {
        const oldTopText = getFreeText(TEXT_POSITION.TOP) || '';
        const oldBottomText = getFreeText(TEXT_POSITION.BOTTOM) || '';
        const newTopText = this.state.topText || '';
        const newBottomText = this.state.bottomText || '';
        const hasTopTextChanges = oldTopText !== newTopText;
        const hasBottomTextChanges = oldBottomText !== newBottomText;
        const hasChanges = hasTopTextChanges || hasBottomTextChanges;
        this.props.onChange({ hasChanges });
      },
    );
  };

  getReceiptText() {
    if (!this.props.canEditReceiptFreeTextTop) {
      return Wahanda.lang.settings.venue.pos.labels.receiptTextInfoJustBot;
    }
    return Wahanda.lang.settings.venue.pos.labels.receiptTextInfo;
  }

  saveComplete = () => {
    this.setState({ saving: false });
  };

  render() {
    const content = (() => {
      if (!configFetched()) {
        return <div />;
      }
      const settings = get(appState.deref(), POS_SETTINGS_KEY);
      const selectOptions = getSelectOptions(settings);
      const value = getVenueReceiptSize();
      const save = (() => {
        if (this.state.saving) {
          return {
            disabled: true,
            text: Wahanda.lang.shared.saving,
          };
        }
        return {
          disabled: false,
          text: Wahanda.lang.shared.save,
        };
      })();
      const buttonClasses = classnames('button action action-default button-primary save-action', {
        'action-doing': this.state.saving,
      });
      return (
        <div>
          <form action="">
            <div id="pos-form-content" className="form-content">
              <table cellPadding="0" cellSpacing="0" className="default-form">
                <tbody>
                  <tr className="form-header-row">
                    <td colSpan={2}>{Wahanda.lang.settings.venue.pos.labels.receiptPrinting}</td>
                  </tr>
                  <tr
                    className="form-row"
                    data-tooltip={Wahanda.lang.settings.venue.pos.tooltips.width}
                  >
                    <td className="label-part">
                      <label htmlFor="pos-receipt-width">
                        {Wahanda.lang.settings.venue.pos.labels.receiptWidth}
                      </label>
                    </td>
                    <td className="input-part">
                      <div className="">
                        <SelectElement
                          name="pos-receipt-width"
                          ref={(posReceiptWidth) => {
                            this.posReceiptWidth = posReceiptWidth;
                          }}
                          options={toJs(selectOptions)}
                          value={value}
                          onChange={this.onPosReceiptWidthChange}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="form-header-row">
                    <td colSpan={2}>{Wahanda.lang.settings.venue.pos.labels.receiptText}</td>
                  </tr>
                  <tr className="form-row">
                    <td colSpan={2} className={style.freeTextDescription}>
                      <label>{this.getReceiptText()}</label>
                    </td>
                  </tr>
                  {this.props.canEditReceiptFreeTextTop && (
                    <tr className="form-row">
                      <td colSpan={2} className="input-part">
                        <ReceiptTextarea
                          autoFocus
                          label={Wahanda.lang.settings.venue.pos.labels.receiptTextTop}
                          value={this.state.topText}
                          onChange={(text) => this.onTextareaChange(TEXT_POSITION.TOP, text)}
                        />
                      </td>
                    </tr>
                  )}

                  <tr className="form-row">
                    <td colSpan={2} className="input-part">
                      <ReceiptTextarea
                        autoFocus={!this.props.canEditReceiptFreeTextTop}
                        label={Wahanda.lang.settings.venue.pos.labels.receiptTextBottom}
                        value={this.state.bottomText}
                        onChange={(text) => this.onTextareaChange(TEXT_POSITION.BOTTOM, text)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="form-actions">
              <button
                type="submit"
                disabled={save.disabled}
                className={buttonClasses}
                onClick={this.onSubmit}
              >
                <div className="button-inner">
                  <div className="button-icon icons-tick" />
                  <span className="msg msg-action-default">{save.text}</span>
                </div>
              </button>
            </div>
          </form>
        </div>
      );
    })();
    return <div>{content}</div>;
  }
}
