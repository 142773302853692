import { connect } from 'react-redux';
import { rotaPermissions } from '../utils/permissions';
import { ShiftRow as Component } from './ShiftRow';

const mapStateToProps = ({ shifts }) => ({
  venue: shifts.venue,
  isLoading: shifts.isLoading,
  canEditVenueHours: rotaPermissions({}).canEditVenueHours,
});

export const ShiftRow = connect(mapStateToProps)(Component);
