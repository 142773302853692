import React from 'react';
import SelectDropdown from 'components/common/SelectDropdown';
import Wahanda from 'common/wahanda';
import { list, hashMap } from 'mori';

// Note that the string values here match the enum
// com.treatwell.connect.reporting.common.dataLayer.models.BookingChannel in the reporting API
// codebase.
/**
 * @deprecated use BookingChannel enum
 */
export const CHANNEL = Object.freeze({
  ALL: 'ALL',
  CONNECT: 'DIRECT',
  WIDGET: 'WIDGET',
  TREATWELL: 'TREATWELL',
});

export enum BookingChannel {
  ALL = 'ALL',
  CONNECT = 'DIRECT',
  WIDGET = 'WIDGET',
  TREATWELL = 'TREATWELL',
}

interface Props {
  selected?: string;
  onChange?: (...args: any[]) => void;
}

export default class BookingChannelFilter extends React.Component<Props> {
  private bookingChannelOptions;

  public constructor(props) {
    super(props);
    this.bookingChannelOptions = this.getBookingChannelOptions();
  }

  public static defaultProps = {
    selected: CHANNEL.ALL,
  };

  private getBookingChannelOptions = () =>
    list(
      hashMap('name', Wahanda.lang.reports.sales.filters.bookingChannel.all, 'value', CHANNEL.ALL),
      hashMap(
        'name',
        Wahanda.lang.reports.sales.filters.bookingChannel.connect,
        'value',
        CHANNEL.CONNECT,
      ),
      hashMap(
        'name',
        Wahanda.lang.reports.sales.filters.bookingChannel.widget,
        'value',
        CHANNEL.WIDGET,
      ),
      hashMap(
        'name',
        Wahanda.lang.reports.sales.filters.bookingChannel.treatwell,
        'value',
        CHANNEL.TREATWELL,
      ),
    );

  private channelSelected = (selected) => {
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  };

  public render() {
    return (
      <SelectDropdown
        dataTest="channel-filter"
        onSelect={this.channelSelected}
        data={this.bookingChannelOptions}
        selected={this.props.selected}
      />
    );
  }
}
