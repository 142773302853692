import React, { PureComponent } from 'react';

interface IMinusProps extends React.HTMLAttributes<Element> {
  size?: number;
}

export default class Minus extends PureComponent<IMinusProps, {}> {
  static defaultProps = {
    size: 10,
  };

  render() {
    const { size } = this.props;
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width={size}
        height={size}
      >
        <line strokeWidth="22" x1="0" y1="50" x2="100" y2="50" />
      </svg>
    );
  }
}
