import {
  GET_TREATMENTS_REQUEST,
  GET_TREATMENTS_RECEIVE,
  GET_TREATMENTS_FAILURE,
  SELECT_SERVICE_DIALOG_CHANGE_SELECTED_TREATMENT_TYPE_ID,
  SELECT_SERVICE_DIALOG_REVERT_SELECTED_TREATMENT_TYPE_ID,
  INITIAL_STATE_SELECT_SERVICE_DIALOG,
  SELECT_SERVICE_DIALOG_CHANGE_VALUE,
  SELECT_SERVICE_DIALOG_REVERT_VALUE,
  SELECT_SERVICE_DIALOG_REVERT,
} from 'reduxStore/actionsConst';

export const requestTreatmentsAction = () => ({
  type: GET_TREATMENTS_REQUEST,
});

export const receiveTreatmentsAction = (data) => ({
  type: GET_TREATMENTS_RECEIVE,
  data,
  hasError: false,
});

export const requestTreatmentsFailureAction = (ex) => ({
  type: GET_TREATMENTS_FAILURE,
  ex,
  hasError: true,
});

export const changeSelectedTreatmentTypeIdAction = (selectedTreatmentTypeId) => ({
  type: SELECT_SERVICE_DIALOG_CHANGE_SELECTED_TREATMENT_TYPE_ID,
  selectedTreatmentTypeId,
});

export const revertSelectedTreatmentTypeIdAction = () => ({
  type: SELECT_SERVICE_DIALOG_REVERT_SELECTED_TREATMENT_TYPE_ID,
  selectedTreatmentTypeId: 0,
});

export const initialStateAction = (data) => ({
  type: INITIAL_STATE_SELECT_SERVICE_DIALOG,
  data,
});

export const changeSelectServiceValueAction = (selectServiceValue) => ({
  type: SELECT_SERVICE_DIALOG_CHANGE_VALUE,
  selectServiceValue,
  selectedTreatmentTypeId: 0,
});

export const revertSelectServiceValueAction = () => ({
  type: SELECT_SERVICE_DIALOG_REVERT_VALUE,
  selectServiceValue: '',
  selectedTreatmentTypeId: 0,
});

export const revertSelectServiceDialogAction = () => ({
  type: SELECT_SERVICE_DIALOG_REVERT,
});
