/* global BackboneEx */
import Cookie from 'js-cookie';

App.Views.MobileAppBanner = BackboneEx.View.Base.extend({
  events: {
    'click .download-app': 'downloadApp',
    'click .exit-link': 'closeOverlay',
    'click .close-banner': 'closeBanner',
  },

  downloadApp: function downloadApp() {
    window.location.href = 'http://m.onelink.me/23d5aeb3';
  },

  closeOverlay: function closeOverlay() {
    this.setCookie('mobile-overlay', 7);
    this.$el.find('.banner-overlay').hide();
    this.$el.find('.app-smart-overlay').hide();
  },

  closeBanner: function closeBanner() {
    this.setCookie('mobile-banner', 1);
    this.$el.find('.app-smart-banner').hide();
    $('body').removeClass('app-banner');
  },

  setCookie: function setCookie(cookieName, dayExpiry) {
    Cookie.set(cookieName, 'hidden', { expires: dayExpiry, path: '/' });
  },

  render: function render() {
    // Check that the user has not just been directed from the app,
    // and show neither the overlay nor the banner
    if (App.isFromApp(window.location, navigator.userAgent)) {
      this.setCookie('mobile-overlay', 1);
      this.setCookie('banner-overlay', 1);
    }

    const showOverlay =
      Wahanda.Device.mobile &&
      !Cookie.get('mobile-overlay') &&
      !App.isApp(window.location, navigator.userAgent);
    const showBanner =
      !showOverlay &&
      Wahanda.Device.mobile &&
      !Cookie.get('mobile-banner') &&
      !App.isApp(window.location, navigator.userAgent);
    if (showOverlay) {
      this.$el.find('.banner-overlay').show();
      this.$el.find('.app-smart-overlay').show();
    } else if (showBanner) {
      this.$el.find('.app-smart-banner').show();
      $('body').addClass('app-banner');
    }
  },
});
