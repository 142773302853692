App.Views.Settings.OnlineBookingWidget = App.Views.Form.extend({
  isVisible: false,

  initialize() {
    this.on('visible', this.onVisible, this).on('hidden', this.onHidden, this);

    this.model.on('fetched', () => {
      this.render();
    });

    this.listenTo(this.model, 'fetched', this.renderIfVisible);
  },

  onVisible() {
    this.isVisible = true;
    $('#online-booking').addClass('slim-view');
    this.injectReactView();
  },

  onHidden() {
    App.ES6.Initializers.SettingsOnlineWidget.destroy(this.el);
    $('#online-booking').removeClass('slim-view');
    this.isVisible = false;
  },

  getAllowToDisableWidget() {
    return (
      !(App.isFeatureSupported('i2r-email') && this.model.get('sendInvitesToRebook')) &&
      !this.options.micrositesModel.get('micrositeEnabled')
    );
  },

  getCurrentLanguage() {
    return App.config.get('venue').locale || App.domainLocale;
  },

  getLangOptions() {
    const supportedLocales = App.config.getChannelSupportedLocales();
    return Object.keys(supportedLocales).map((localeName) => {
      const localeCode = supportedLocales[localeName];

      return {
        text: Wahanda.lang.language[localeCode] || localeName,
        value: localeCode,
      };
    });
  },

  injectReactView() {
    App.ES6.Initializers.SettingsOnlineWidget.render(this.el, {
      venueId: App.config.get('venue').id,
      widgetEnabled: this.model.get('useOnlineBookingWidget'),
      allowToDisableWidget: this.getAllowToDisableWidget(),
      defaultLanguage: this.getCurrentLanguage(),
      availableLanguages: this.getLangOptions(),
    });
  },

  renderIfVisible() {
    if (this.isVisible) {
      this.injectReactView();
    }
  },
});
