import React, { useCallback } from 'react';
import Wahanda from 'common/wahanda';
import { TogglableSetting } from './types';
import { SettingsTrackers } from './tracking';
import style from './Setting.scss';
import { AreYouSureDialog } from './AreYouSureDialog';
import { Box } from 'components/common/Box';
import { Switch } from '../Switch';
import { Chevron } from 'components/common/Icon';
import classNames from 'classnames';
export interface AdvancedSettingsProps {
  onClose: () => void;
}

export interface Props extends TogglableSetting {
  defaultIsAdvancedSettingsVisible?: boolean;
  AdvancedSettings?: React.ComponentType<AdvancedSettingsProps>;
  Analytics: SettingsTrackers;
  isSettingLoading: boolean;
  dataTest?: string;
  isEnableable?: boolean;
  advancedSettingsLabel?: string;
  callbacks: {
    enableSetting: () => void;
    disableSetting: () => void;
  };
  // note: by default the advanced settings are shown when the setting is enabled and the AdvancedSettings component is provided, this can be overriden by setting this to true
  allowSettingButtonWhenDisabled?: boolean;
}

export const SettingBox = ({
  defaultIsAdvancedSettingsVisible = false,
  Analytics,
  AdvancedSettings,
  text: { title, description, areYouSureTitle, areYouSureDescription },
  shouldAskBeforeDisabling,
  isSettingEnabled,
  trackingId,
  link,
  dataTest,
  callbacks: { enableSetting, disableSetting },
  isEnableable = true,
  advancedSettingsLabel = Wahanda.lang.marketing.automatedMessaging.changeSettings,
  allowSettingButtonWhenDisabled = false,
}: Props) => {
  const [isAreYouSureDialogVisible, setAreYouSureDialog] = React.useState(false);
  const [isAdvancedSettingsVisible, setIsAdvancedSettingsVisible] = React.useState(
    defaultIsAdvancedSettingsVisible,
  );

  const handleHowItWorksClick = () => {
    Analytics.trackHowItWorksClick(trackingId);
  };

  const setSetting = () => {
    Analytics.trackSettingChange(trackingId, !isSettingEnabled);
    isSettingEnabled ? disableSetting() : enableSetting();
  };

  const handleSettingChange = useCallback(() => {
    if (isSettingEnabled && shouldAskBeforeDisabling) {
      setAreYouSureDialog(true);
      return;
    }
    setSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSettingEnabled, shouldAskBeforeDisabling]);

  const handleAreYouSureClose = () => {
    setAreYouSureDialog(false);
  };

  const handleAreYouSureSubmit = () => {
    setSetting();
    setAreYouSureDialog(false);
  };

  const renderAdvancedSettingsButton = () => {
    return (
      <div
        className={style.advancedSettingsButton}
        onClick={() => setIsAdvancedSettingsVisible(true)}
        data-test={`${trackingId}-advanced-settings-button`}
      >
        <span>{advancedSettingsLabel}</span>
        <Chevron size="small" />
      </div>
    );
  };

  const renderActionButton = () => {
    if (!isEnableable) return;

    const buttonDataTest = `${trackingId}-setting-button`;

    return (
      <Switch
        dataTest={buttonDataTest}
        checked={isSettingEnabled}
        onChange={handleSettingChange}
        controlled
      />
    );
  };

  const renderAreYouSureDialog = () => (
    <AreYouSureDialog
      trackingId={trackingId}
      areYouSureDescription={areYouSureDescription}
      areYouSureTitle={areYouSureTitle}
      onAreYouSureClose={handleAreYouSureClose}
      onAreYouSureSubmit={handleAreYouSureSubmit}
    />
  );

  const renderAdvancedSettings = () => {
    if (!AdvancedSettings) {
      return null;
    }

    return <AdvancedSettings onClose={() => setIsAdvancedSettingsVisible(false)} />;
  };

  return (
    <Box
      dataTest={dataTest}
      className={classNames({
        [style.wrapperContainer]: true,
        [style.disabled]: !allowSettingButtonWhenDisabled && !isSettingEnabled,
        [style.withAdvancedSettings]: AdvancedSettings && isSettingEnabled,
      })}
    >
      <>
        {isAreYouSureDialogVisible && renderAreYouSureDialog()}
        {isAdvancedSettingsVisible && renderAdvancedSettings()}
        <div
          className={classNames({
            [style.container]: true,
            [style.containerWithAdvancedSettings]:
              allowSettingButtonWhenDisabled || (AdvancedSettings && isSettingEnabled),
          })}
        >
          <div className={style.textSectionContainer}>
            <div className={style.textContainer}>
              <div className={style.title}>{title}</div>
              {description && (
                <div className={style.description}>
                  <p>
                    {description}{' '}
                    {link && (
                      <a
                        data-test={`how-it-works-${trackingId}`}
                        onClick={handleHowItWorksClick}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={link}
                      >
                        {Wahanda.lang.shared.howItWorks}
                      </a>
                    )}
                  </p>
                </div>
              )}
            </div>
            <div className={style.buttonWrapper}>{renderActionButton()}</div>
          </div>
          <div
            className={classNames({
              [style.advancedSettingsWrapper]: true,
              [style.hidden]:
                !allowSettingButtonWhenDisabled && (!AdvancedSettings || !isSettingEnabled),
            })}
          >
            {renderAdvancedSettingsButton()}
          </div>
        </div>
      </>
    </Box>
  );
};
