import React from 'react';

interface Props {
  language: {
    body: any;
    bookingTermsLinkUrl: string;
    bookingTermsLinkText: string;
  };
}

export const CancellationBody: React.FunctionComponent<Props> = ({ language }) => {
  const bodyParts = language.body.split('{{bookingTermsLink}}');
  if (bodyParts.length !== 2) {
    return language.body;
  }
  return (
    <>
      {bodyParts[0]}
      <a href={language.bookingTermsLinkUrl} target="_blank" rel="noopener noreferrer">
        {language.bookingTermsLinkText}
      </a>
      {bodyParts[1]}
    </>
  );
};
