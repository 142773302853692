import React from 'react';
import Wahanda from 'common/wahanda';
import Dialog from 'components/common/react-dialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import { Analytics } from '../../tracking';
import style from './VenueClosingDialog.scss';

const LANG = Wahanda.lang.shared.covidBanner;

export interface Props {
  hideCovidBannerDialogs: () => void;
  venueClosingRequest: (payload: {
    venueId: number;
    employeeName: string;
    emailAddress: string;
  }) => void;
  venueId: number;
  employeeName: string;
  emailAddress: string;
  submittingCloseRequest: boolean;
}

export const VenueClosingDialog: React.SFC<Props> = ({
  hideCovidBannerDialogs,
  venueClosingRequest,
  venueId,
  employeeName,
  emailAddress,
  submittingCloseRequest,
}) => {
  const handleClose = () => {
    hideCovidBannerDialogs();
  };
  const handleSubmit = () => {
    Analytics.trackVenueClosingRequestClicked();
    venueClosingRequest({
      venueId,
      employeeName,
      emailAddress,
    });
  };
  const dialogContent = () => (
    <div className={style.dialogContent}>
      <div className={style.header}>{LANG.notPlanning}</div>
      <div className={style.description}>{LANG.notReopeningDescription}</div>
      <div
        className={style.description}
        dangerouslySetInnerHTML={{
          __html: LANG.notReadyToReopen,
        }}
      />
    </div>
  );
  const dialogFooter = () => (
    <DialogFooter>
      <DialogFooterButton
        dataTest="cancel-venue-close-dialog"
        type="secondary"
        title={Wahanda.lang.shared.close}
        onClick={handleClose}
      />
      <DialogFooterButton
        dataTest="submit-venue-close-request"
        title={LANG.wontReopen}
        onClick={handleSubmit}
        type="alert"
        isLoading={submittingCloseRequest}
        disabled={submittingCloseRequest}
      />
    </DialogFooter>
  );
  return (
    <div className={style.dialogWrapper}>
      <Dialog
        dataTest="venue-closing-modal"
        title={LANG.confirmDialogTitle1}
        width={520}
        onClose={handleClose}
        restrictHeight
        noContentPadding
      >
        {dialogContent()}
        {dialogFooter()}
      </Dialog>
    </div>
  );
};
