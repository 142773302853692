import React, { useEffect } from 'react';
import Wahanda from 'common/wahanda';

import { Analytics } from './tracking';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/common/Modal';
import { Button } from 'components/common/Button';

export interface Props {
  trackingId: string;
  areYouSureTitle?: string;
  areYouSureDescription?: string;
  onAreYouSureClose: () => void;
  onAreYouSureSubmit: () => void;
}

export const AreYouSureDialog = ({
  trackingId,
  areYouSureTitle = Wahanda.lang.shared.disableFeatureConfirm,
  areYouSureDescription,
  onAreYouSureClose,
  onAreYouSureSubmit,
}: Props) => {
  useEffect(() => {
    Analytics.trackAreYouSureView(trackingId);
  }, []);

  const handleAreYouSureClose = () => {
    Analytics.trackAreYouSureClose(trackingId);
    onAreYouSureClose();
  };

  return (
    <ModalContainer dataTest="are-you-sure-setting-modal" onClose={handleAreYouSureClose}>
      <ModalHeader title={areYouSureTitle} onClose={handleAreYouSureClose} />
      <ModalBody>{areYouSureDescription}</ModalBody>
      <ModalFooter>
        <Button
          dataTest="are-you-sure-footer-button-close"
          label={Wahanda.lang.shared.buttons.cancel}
          colour="plain"
          variant="secondary"
          onClick={handleAreYouSureClose}
        />
        <Button
          dataTest="are-you-sure-footer-button-confirm"
          colour="alert"
          label={Wahanda.lang.shared.buttons.disable}
          onClick={onAreYouSureSubmit}
        />
      </ModalFooter>
    </ModalContainer>
  );
};
