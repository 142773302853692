import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'reduxStore/common/employees/actions';
import { menuOfferCheckedTransformer } from 'common/transformers/DiscountMenu';
import { EmployeeDialog as Component } from './index';

const mapStateToProps = ({
  employees: {
    employee: { takesAppointments, active, canLogin, emailAddress, previousEmailAddress },
    loading,
    isLoaded,
    submitting,
    serverError,
  },
  discountMenuItems: { menuItemOffers },
}) => ({
  emailAddress,
  previousEmailAddress,
  loading,
  isLoaded,
  submitting,
  serverError,
  takesAppointments,
  active,
  canLogin,
  selectedMenuItemIds: menuOfferCheckedTransformer(menuItemOffers),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const EmployeeDialog = connect(mapStateToProps, mapDispatchToProps)(Component);
