import React from 'react';
import Wahanda from 'common/wahanda';
import { bindKeyEvent, unbindKeyEvent } from 'common/events';

import { ModalContainer, ModalHeader, ModalBody } from 'components/common/Modal';
import { Button, ButtonSpacer } from 'components/common/Button';

import style from './DialogCloseWithChanges.scss';

const defaultLang = Wahanda.lang.shared.dialogCloseChanges;

export interface Props {
  onClose?: () => void;
  onConfirm?: () => void;
  text?: string;
}

export class DialogCloseWithChanges extends React.Component<Props> {
  public static defaultProps = {
    text: defaultLang.text,
  };

  public componentDidMount() {
    bindKeyEvent('enter', this.onKeyConfirm);
  }

  public componentWillUnmount() {
    unbindKeyEvent('enter', this.onKeyConfirm);
  }

  private onKeyConfirm = () => {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  };

  public render() {
    const { onClose, onConfirm, text } = this.props;

    return (
      // This modal may be on a stack of other modals with variable z-index. Ridiculously large override is needed.
      <ModalContainer onClose={onClose} type="tiny" zIndex={10000}>
        <ModalHeader title={defaultLang.title} onClose={onClose} />
        <ModalBody>
          <div className={style.content}>{text}</div>

          <Button
            label={defaultLang.accept}
            colour="default"
            fullWidth
            size="large"
            onClick={onConfirm}
          />
          <ButtonSpacer />
          <Button
            label={defaultLang.cancel}
            colour="default"
            variant="secondary"
            size="large"
            fullWidth
            onClick={onClose}
          />
        </ModalBody>
      </ModalContainer>
    );
  }
}
