import { assoc, toClj } from 'mori';
import { appState } from 'state';

import App from 'common/backbone-app';
import apiUrl from 'common/api-url';
import { xhr } from 'common/xhr';

export const VENUE_POS_SETTINGS_KEY = 'venue-pos-settings';

export function fetch() {
  xhr.get(appState, VENUE_POS_SETTINGS_KEY, apiUrl('VENUE_POS_SETTINGS'));
}

export function save(data, saveComplete) {
  const { receiptPdfWidth, receiptTopCustomText, receiptBottomCustomText } = data;
  const venueId = App.getVenueId();

  return xhr
    .putWithPromise(apiUrl('VENUE_POS_SETTINGS_UPDATE'), {
      receiptPdfWidth,
      venueId,
      receiptTopCustomText,
      receiptBottomCustomText,
    })
    .done((savedData) => {
      appState.swap((state) => assoc(state, VENUE_POS_SETTINGS_KEY, toClj(savedData)));
      // Set saving set to false and clean up
      saveComplete();
    });
}
