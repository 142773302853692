import React from 'react';
import Wahanda from 'common/wahanda';
import { Button } from 'components/common/Button';
import { FinancePaymentsTracking } from '../../../tracking';
import style from './style.scss';

interface Props {
  onClose: () => void;
  reFetchRemittances: (force: boolean) => void;
}
const lang = Wahanda.lang.settings.supplier.payments.payNowDialog;

export class StripePaymentSucceeded extends React.PureComponent<Props> {
  private async fetchRemittances() {
    this.props.reFetchRemittances(true);
  }

  public componentDidMount() {
    FinancePaymentsTracking.trackSuccessView();
    this.fetchRemittances();
  }

  public render() {
    const { onClose } = this.props;
    const successLang = lang.success;
    const sharedLang = Wahanda.lang.shared;

    return (
      <div data-test="card-payment-success" className={style.container}>
        <div className={style.successMessage}>
          <div className={style.successIcon} />
          <div className={style.successTextBlock}>
            <div className={style.successHeader}>{successLang.header}</div>
            <div className={style.successBody}>{successLang.body}</div>
          </div>
        </div>
        <div className={style.actions}>
          <Button onClick={onClose} label={sharedLang.ok} fullWidth size="large" />
        </div>
      </div>
    );
  }
}
