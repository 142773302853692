import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Triangle extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-triangle', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <polygon points="10 17 15 12 10 7" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Triangle);
