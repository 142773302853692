import React from 'react';
import { get } from 'mori';
import classnames from 'classnames';

interface ISelectDropdownItemProps extends React.HTMLAttributes<Element> {
  item?: {};
  onSelect?: (...args: any[]) => any;
  onMouseMove?: (...args: any[]) => any;
  selectable?: boolean;
  active?: boolean;
  indented?: boolean;
}

export default class SelectDropdownItem extends React.PureComponent<ISelectDropdownItemProps, {}> {
  render() {
    const { selectable, item, onSelect, onMouseMove, active, indented } = this.props;
    const hidden = get(item, 'hide');
    const classNames = classnames(
      'select-dropdown-item',
      get(item, 'primaryClass'),
      {
        'dropdown-item-warning': get(item, 'hasWarning'),
      },
      {
        'not-selectable': !selectable,
        active,
        hidden,
        indented,
      },
    );
    const handleClick = selectable && onSelect ? () => onSelect(item) : null;
    const handleMouseMove = selectable && onMouseMove ? (event) => onMouseMove(item, event) : null;
    const primaryText = get(item, 'name');
    const primaryValue = get(item, 'value');
    const secondaryText = get(item, 'secondaryText');
    const hasSecondaryText = !!secondaryText;
    const secondaryClassNames = classnames('secondaryText', get(item, 'secondaryClass'));
    return (
      <div
        className={classNames}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '((event: Mo... Remove this comment to see the full error message
        onClick={handleClick}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '((event: Mo... Remove this comment to see the full error message
        onMouseMove={handleMouseMove}
        data-value={primaryValue}
        data-test={`select-option-${primaryValue}`}
      >
        {primaryText}
        {hasSecondaryText && <span className={secondaryClassNames}>{`(${secondaryText})`}</span>}
      </div>
    );
  }
}
