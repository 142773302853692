import React, { Component } from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import { NavigationAnalytics, L2Page, mapPageToL2Enum } from 'common/tracking/navigation';
import { Sidebar, SidebarLink } from 'components/common/Sidebar';
import Error404 from 'components/error/404';
import {
  ClientApp,
  Campaigns,
  Website,
  FacebookLite,
  Chat,
  CalendarEntry,
} from 'components/common/Icon';

import { MarketingRouter } from './Router/container';
import style from './style.scss';

export const TABS = {
  CAMPAIGNS: L2Page.EMAIL_CAMPAIGN,
  CLIENT_APP: 'app',
  PARTNER_SITE: L2Page.PARTNER_SITE,
  FBE: 'facebook',
  AUTOMATED_MESSAGING: L2Page.AUTOMATED_MESSAGING,
  CLIENT_FORMS: L2Page.CLIENT_FORMS,
};

const LIVE_CHAT_SOURCE = 'marketing sidebar';
const lang = Wahanda.lang.marketing;
interface IMarketingProps extends React.HTMLAttributes<Element> {
  venueId: number | string;
  defaultTab: string;
  isClientAppEnabled: boolean;
  isPartnerSiteAccessible: boolean;
  isEmailCampaignsAccessible: boolean;
  isClientFormsAccessible: boolean;
  hideSidebar: boolean;
}
type MarketingState = {
  tab?: any;
};

export class Marketing extends Component<IMarketingProps, MarketingState> {
  public state = {
    tab: this.props.defaultTab,
  };

  constructor(props: IMarketingProps) {
    super(props);
    this.handleHashChanged = this.handleHashChanged.bind(this);
  }

  public handleHashChanged() {
    const newTab = location.hash.split('/').pop();
    if (!newTab) {
      return;
    }

    if (newTab === this.state.tab) {
      return;
    }

    if (!Object.values(TABS).includes(newTab)) {
      return;
    }

    this.setState({ tab: newTab });
  }

  public componentDidMount() {
    window.addEventListener('hashchange', this.handleHashChanged);
    NavigationAnalytics.trackPageView(mapPageToL2Enum(this.props.defaultTab));
  }

  public componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleHashChanged);
  }

  public handleNavigationClick = (path) => () => {
    this.setState({ tab: path });
    NavigationAnalytics.trackSidebarClick(mapPageToL2Enum(path));
    return this.navigateTo(path);
  };

  public navigateTo = (path) => {
    const { venueId } = this.props;
    window.location.hash = `venue/${venueId}/${path}`;
  };

  public render() {
    const {
      venueId,
      isClientAppEnabled,
      isEmailCampaignsAccessible,
      isPartnerSiteAccessible,
      isClientFormsAccessible,
      hideSidebar,
    } = this.props;
    const { tab } = this.state;
    if (!tab) {
      return null;
    }
    if (!Wahanda.Permissions.canAccessMarketingTools()) {
      return <Error404 />;
    }

    return (
      <div className={classnames(style.wrapper)}>
        <Sidebar liveChatSource={LIVE_CHAT_SOURCE} hidden={hideSidebar}>
          <div>
            {isEmailCampaignsAccessible && (
              <SidebarLink
                dataTest="email-campaign-link"
                icon={<Campaigns />}
                isSelected={tab === TABS.CAMPAIGNS}
                onClick={this.handleNavigationClick(TABS.CAMPAIGNS)}
                text={lang.tabs.campaigns}
              />
            )}
            <SidebarLink
              dataTest="automated-messaging"
              icon={<Chat />}
              isSelected={tab === TABS.AUTOMATED_MESSAGING}
              onClick={this.handleNavigationClick(TABS.AUTOMATED_MESSAGING)}
              text={lang.tabs.automatedMessaging}
            />
            {isClientFormsAccessible && (
              <SidebarLink
                dataTest="client-forms"
                icon={<CalendarEntry />}
                onClick={this.handleNavigationClick(TABS.CLIENT_FORMS)}
                isSelected={tab === TABS.CLIENT_FORMS}
                text={lang.tabs.clientForms}
              />
            )}
            {isClientAppEnabled && (
              <SidebarLink
                dataTest="client-app-link"
                icon={<ClientApp />}
                isSelected={tab === TABS.CLIENT_APP}
                onClick={this.handleNavigationClick(TABS.CLIENT_APP)}
                text={lang.tabs.clientApp}
              />
            )}
            {isPartnerSiteAccessible && (
              <SidebarLink
                dataTest="partner-site-link"
                icon={<Website />}
                isSelected={tab === TABS.PARTNER_SITE}
                onClick={this.handleNavigationClick(TABS.PARTNER_SITE)}
                text={lang.tabs.partnerSite}
              />
            )}
            <SidebarLink
              dataTest="fbe-link"
              icon={<FacebookLite />}
              isSelected={tab === TABS.FBE}
              onClick={this.handleNavigationClick(TABS.FBE)}
              text={lang.tabs.fbe}
            />
          </div>
        </Sidebar>
        <MarketingRouter venueId={venueId} tab={tab} />
      </div>
    );
  }
}
