import React from 'react';
import classnames from 'classnames';
import { InputContext } from '../InputContext';
import styleLegacy from './InputBorder.scss';
import styleNewLook from './InputBorder.newLook.scss';

interface Props {
  children: React.ReactNode;
  hasError?: boolean;
  onKeyDown?: (event) => void;
  tabIndex?: number;
}

export const InputBorder = ({ children, hasError, onKeyDown, tabIndex }: Props) => {
  const { disabled, isFocused, readOnly, fullBorder, newLook } = React.useContext(InputContext);
  const style = newLook ? styleNewLook : styleLegacy;
  const classes = classnames(style.container, {
    [style.disabled]: disabled || readOnly,
    [style.readOnly]: readOnly,
    [style.hasError]: hasError,
    [style.focused]: isFocused,
    [style.fullBorder]: fullBorder,
  });
  return (
    <div className={classes} onKeyDown={onKeyDown} tabIndex={tabIndex}>
      {children}
    </div>
  );
};
