export const openFileWithPost = (url, filename, body) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onloadstart = function () {
      xhr.responseType = 'blob';
    };

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        if (!(window.navigator as any).msSaveOrOpenBlob) {
          const href = (window.URL || window.webkitURL).createObjectURL(xhr.response);
          window.open(href, '_blank');
        } else {
          const fileData = [xhr.response];
          const blobObject = new Blob(fileData);
          (window.navigator as any).msSaveOrOpenBlob(blobObject, filename);
        }
        resolve({});
      }

      if (xhr.readyState === 4 && xhr.status !== 200) {
        const reader = new FileReader();
        reader.onload = () => {
          reject(JSON.parse(reader.result as string));
        };
        reader.readAsText(xhr.response);
      }
    };

    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    xhr.send(JSON.stringify(body));
  });
};
