import React from 'react';
import Wahanda from 'common/wahanda';
import style from './style.scss';

const LANG = Wahanda.lang.menu.products.orders;
interface ITotalQuantityLabelProps extends React.HTMLAttributes<Element> {
  quantity: number;
}

export default class TotalQuantityLabel extends React.Component<ITotalQuantityLabelProps, {}> {
  render() {
    return (
      <div className={style.contentWrapper}>
        <div className={style.label}>{LANG.totalQuantityLabel + this.props.quantity}</div>
      </div>
    );
  }
}
