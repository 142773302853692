import React from 'react';
import curry from 'lodash/curry';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import App from 'common/backbone-app';
import { Switch } from 'components/common/Switch';
import { MoneyDeposit, MoneyWithdraw, LogOut, Share, Search } from 'components/common/Icon';
import { TransactionsReportTracking } from 'components/reports/transactions/tracking';
import { Button } from 'components/common/Button';
import { refreshAfterTransaction } from 'src/actions/pos/create-transaction';
import { ACTION, canDelegateToApp, delegateToApp } from 'common/app-delegation';
import { isPreviousDayOpen, closedOrOpenedDayInFuture } from 'src/services/pos-status';
import {
  openSettlementReportPdf,
  openDrawerCorrectionPdf,
} from 'components/reports/transactions/day-status/settlement-report';
import { DayPicker } from './DayPicker';
import openCloseDialog from '../day-status/close-dialog';
import { ReportSelectionDropdown } from '../ReportSelectionDropdown';
import style from './ActionsAndFiltersBar.scss';

function onCloseDialogCallback(date, params) {
  if (params == null) {
    return;
  }
  if (params.showReport) {
    openSettlementReportPdf(date);
  }
  if (params.showCorrectionReceipt) {
    openDrawerCorrectionPdf(params.correctionReceiptId);
  }
}

type ActionsAndFiltersBarProps = {
  date: string;
  dailyReport: {
    isClosed?: boolean;
  };
  posStatus?: {
    openDay?: string;
  };
  lang: {
    posRestrictions: {
      prevDayOpenNoCloseDay: string;
    };
    shared: {
      loading: string;
    };
    reports: {
      transactions: {
        transaction?: {
          titles?: {
            withdrawal: string;
          };
          buttons?: {
            withdrawal: string;
          };
        };
        closeDay: {
          shared: {
            loading: string;
          };
          withdrawalButton: string;
          depositButton: string;
          closeDayButton: string;
          viewSettlementReportButton?: string;
          dayStatus?: {
            title: string;
            closed: string;
          };
        };
        basicView: string;
        advancedView: string;
      };
    };
  };
  advancedView: boolean;
  actions: {
    showAdvancedView?: (...args: any[]) => any;
    showSimpleView?: (...args: any[]) => any;
    toggleExportDialogFn?: (...args: any[]) => any;
    openWithdrawalDialog?: (...args: any[]) => any;
    openDepositDialog?: (...args: any[]) => any;
    openDayCloseNotificationDialog?: (...args: any[]) => any;
    onSignatureSearchClick?: (...args: any[]) => any;
  };
};

export const ActionsAndFiltersBar: React.SFC<ActionsAndFiltersBarProps> = ({
  date,
  dailyReport,
  posStatus,
  lang,
  actions,
  advancedView,
}) => {
  const langBase = lang.reports.transactions.closeDay;
  const isDayClosed = dailyReport.isClosed;
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const openDay = posStatus.openDay;
  const canEdit = !App.isTaxAuditor();
  const isLoading = !(!!posStatus || !!dailyReport);
  const isDateToday = moment(date).isSame(moment(), 'day');
  const hasDayOpenInThePast = isPreviousDayOpen(date);
  const buttonClickAction = () => {
    if (isDayClosed) {
      openSettlementReportPdf(date);
    } else if (hasDayOpenInThePast) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      actions.openDayCloseNotificationDialog();
    } else {
      const actionDate = openDay || date;
      if (canDelegateToApp(ACTION.CLOSE_DAY)) {
        delegateToApp(ACTION.CLOSE_DAY, {
          day: actionDate,
          onDone: refreshAfterTransaction,
        });
      } else {
        TransactionsReportTracking.openCloseDayDialog();
        openCloseDialog(actionDate, curry(onCloseDialogCallback)(date));
      }
    }
  };
  const onWithdrawalDialogClick = () => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    actions.openWithdrawalDialog();
    TransactionsReportTracking.openWithdrawalDialog();
  };
  const onDepositDialogClick = () => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    actions.openDepositDialog();
    TransactionsReportTracking.openDepositDialog();
  };
  const TransactionButtons = () => {
    // Transactions are allowed only for today
    if (
      canEdit &&
      !isLoading &&
      !isDayClosed &&
      isDateToday &&
      !hasDayOpenInThePast &&
      Wahanda.Permissions.managePos()
    ) {
      return [
        <Button
          onClick={onWithdrawalDialogClick}
          key="withdraw-btn"
          dataTest="cash-withdrawal-button"
          label={langBase.withdrawalButton}
          icon={<MoneyWithdraw />}
          variant="secondary"
          colour="help"
          size="large"
          newLook
        />,
        <Button
          onClick={onDepositDialogClick}
          key="deposit-btn"
          dataTest="cash-deposit-button"
          label={langBase.depositButton}
          icon={<MoneyDeposit />}
          variant="secondary"
          colour="help"
          size="large"
          newLook
        />,
      ];
    }
    return null;
  };
  const SearchBySignatureButton = () => {
    return Wahanda.Permissions.canSearchESignature() ? (
      <Button
        onClick={actions.onSignatureSearchClick}
        label={Wahanda.lang.reports.transactions.signature.searchBySignature}
        icon={<Search />}
        variant="secondary"
        colour="help"
        size="large"
        newLook
      />
    ) : null;
  };
  const MainActionButton = () => {
    if (!Wahanda.Permissions.managePos()) {
      return null;
    }
    const buttonText = isDayClosed ? langBase.viewSettlementReportButton : langBase.closeDayButton;
    if (
      (canEdit &&
        closedOrOpenedDayInFuture(date, posStatus) === false &&
        isPreviousDayOpen(date) === false) ||
      isDayClosed
    ) {
      return (
        <Button
          onClick={buttonClickAction}
          label={buttonText}
          icon={<LogOut />}
          variant="secondary"
          colour="help"
          size="large"
          newLook
        />
      );
    }
    return null;
  };
  const DayLabel = () => {
    return isDayClosed ? (
      <span
        className={style.dayCloseLabel}
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      >{`${langBase.dayStatus.title} ${langBase.dayStatus.closed}`}</span>
    ) : null;
  };
  const handleViewToggle = () => {
    if (advancedView) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      actions.showSimpleView();
    } else {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      actions.showAdvancedView();
    }
  };
  const AdvancedViewToggle = () => {
    return isLoading ? null : (
      <Switch
        onChange={handleViewToggle}
        label={Wahanda.lang.reports.transactions.advancedView}
        checked={advancedView}
        rhs
      />
    );
  };
  const ExportDialogButton = () => {
    return Wahanda.Permissions.canExportTransactionList() ? (
      <Button
        onClick={actions.toggleExportDialogFn}
        label={Wahanda.lang.reports.transactions.exports.exportLink}
        icon={<Share />}
        variant="secondary"
        colour="help"
        size="large"
        dataTest="pos-exports-link"
        newLook
      />
    ) : null;
  };
  const SynchDATEVButton = () => {
    // TODO update handler upon connecting DATEV
    // return Wahanda.Features.isEnabled(DATEV_EXPORT_FEATURE_FLAG) ? (
    //   <Button
    //     onClick={() => {}}
    //     label={Wahanda.lang.reports.transactions.synchDatev}
    //     icon={<Sync />}
    //     variant="secondary"
    //     colour="help"
    //     size="large"
    //     newLook
    //   />
    // ) : null;
    return null;
  };

  if (isLoading) {
    return <div className="loading">{lang.shared.loading}</div>;
  }
  return (
    <div className={style.actionsAndFiltersBar}>
      <div className={style.dropdownAndButtons}>
        <div className={style.dropdown}>
          <ReportSelectionDropdown />
        </div>
        <div className={style.buttons}>
          <DayLabel />
          {/* @ts-expect-error ts-migrate(2786) FIXME: Type 'Element[]' is missing the following properti... Remove this comment to see the full error message */}
          <TransactionButtons />
          <MainActionButton />
          <div className={style.actions}>
            <SearchBySignatureButton />
            <ExportDialogButton />
            <SynchDATEVButton />
          </div>
        </div>
      </div>

      <div className={style.dayPickerAndToggle}>
        <div className={style.dayPicker}>
          <DayPicker date={date} />
        </div>
        <AdvancedViewToggle />
      </div>
    </div>
  );
};
