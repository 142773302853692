/* global App Wahanda jcrop_api */
import { trackEvent } from 'common/analytics';

App.Views.Forms.Employee.EditImage = App.Views.Dialog.ImageCrop.extend({
  events: {
    'click .button-cancel': 'close',
    'click .submit-image': 'submitImage',
  },
  options: {
    width: 800,
    title: Wahanda.lang.settings.employees.employee.image.title,
    dialogClass: 'edit-image-dialog',
    MIN_WIDTH: 25,
    MIN_HEIGHT: 25,
    aspectRatio: 1,
  },
  templateId: 'edit-image-dialog',

  submitImage: function () {
    trackEvent('team-member-image', 'submit', 'update-image');
    if (typeof jcrop_api === 'undefined') {
      this.resetCloseChangesCheck();
      this.close();
      return;
    }

    var devicePixelRatio = window.deviceRixelRatio || 1;
    var coordinates = jcrop_api.tellSelect();
    if (coordinates.h != 0 || coordinates.w != 0) {
      //crop
      this.model.cropImage(
        Math.floor(coordinates.x) * devicePixelRatio,
        Math.floor(coordinates.y) * devicePixelRatio,
        Math.floor(coordinates.x2) * devicePixelRatio,
        Math.floor(coordinates.y2) * devicePixelRatio,
        this.cropSuccessCallback,
        this,
      );
    } else {
      this.resetCloseChangesCheck();
      this.close();
    }
  },

  // This is called on a successful crop POST request, and eventually closes the dialog after updating the imageID
  cropSuccessCallback: function (data, XHR, self) {
    self.setNewImage(data);
    self.resetCloseChangesCheck();
    self.close();
  },

  setNewImage: function (image) {
    this.model.set({
      imageId: image.id,
      image: image,
    });
    App.trigger(Wahanda.Event.EMPLOYEE_IMAGE_UPDATED, {
      id: this.model.id,
      image: image,
    });
  },

  setupDialog: function () {
    App.Views.Dialog.ImageUpload.prototype.setupDialog.call(this);

    var self = this;
    this.$('input[type=file]')
      .fileupload()
      .fileupload('option', {
        dataType: 'json',
        url: App.Api.wsVenueUrl('/employee/' + this.model.get('id') + '/image.json'),
        multipart: true,
        paramName: 'file',
        imageOrientation: true,
        autoUpload: true,
        submit: function (e, data) {
          data.formData = {
            filename: data.files[0].name,
          };
          self.setProgress(0);
          return true;
        },
        done: function (event, data) {
          if (data.result && _.isArray(data.result.errors)) {
            self.showUploadError(data.result.errors);
          } else if (data.result.id) {
            if ($('.uploaded-image').attr('src') != '') {
              self.resetImage();
            }

            self.renderImage(data.result.uploadedUri);
            self.setNewImage(data.result);
            if (data.files && data.files[0]) {
              self.onFilesLoad(data.files);
            }
          }
        },
        fail: function (event, data) {
          self.handleFail(data);
          self.hideProgress();
        },
        progress: function (event, data) {
          if (1 == data.loaded && 1 == data.total) {
            // This is a dummy progress event from non-XHR upload.
            // Ignore it.
            return;
          }
          var percent = parseInt((data.loaded / data.total) * 100, 10);
          self.setProgress(percent);
        },
      });

    this.$el.bind('dialogopen', function () {
      $(this).find('.image-list').shadows();
    });

    this.$el.unloadmask();
  },

  renderImage: function (location) {
    this.$('.dialog-content img').attr('src', location);
  },
});
