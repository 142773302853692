import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class AddMultiple extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-add-multiple', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M19.3333333,2 C20.8060927,2 22,3.19390733 22,4.66666667 L22,15.3333333 C22,16.8060927 20.8060927,18 19.3333333,18 L8.66666667,18 C7.19390733,18 6,16.8060927 6,15.3333333 L6,4.66666667 C6,3.19390733 7.19390733,2 8.66666667,2 L19.3333333,2 Z M19.1428571,4 L8.85714286,4 C8.38375593,4 8,4.38375593 8,4.85714286 L8,15.1428571 C8,15.6162441 8.38375593,16 8.85714286,16 L19.1428571,16 C19.6162441,16 20,15.6162441 20,15.1428571 L20,4.85714286 C20,4.38375593 19.6162441,4 19.1428571,4 Z M10,11 L10,9 L18,9 L18,11 L10,11 Z M13,6 L15,6 L15,14 L13,14 L13,6 Z M16,22 L5,22 C3.34314575,22 2,20.6568542 2,19 L2,9 C2,7.69374794 2.8348501,6.58248558 4.00008893,6.17067428 L4,19 C4,19.5522847 4.44771525,20 5,20 L18.8289758,20.0009007 C18.4168852,21.1656226 17.3058822,22 16,22 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(AddMultiple);
