import React from 'react';
import classnames from 'classnames';

import style from './PopoverBody.scss';

interface Props {
  children: React.ReactNode;
  shadow?: 'large' | null;
  noPadding?: boolean;
  maxHeight?: number;
}

export class PopoverBody extends React.PureComponent<Props> {
  public render() {
    const { children, shadow, noPadding, maxHeight } = this.props;
    const className = classnames(style.tooltip, {
      [style.shadowLarge]: shadow === 'large',
      [style.noPadding]: noPadding,
      [style.hasMaxHeight]: maxHeight,
    });

    return (
      <div className={className} style={maxHeight ? { maxHeight } : undefined}>
        {children}
      </div>
    );
  }
}
