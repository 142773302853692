import React, { Component } from 'react';
import Formsy from 'formsy-react';
import Wahanda from 'common/wahanda';
import ReactDialog from 'components/common/react-dialog';
import {
  DialogFooter,
  DialogFooterButton,
  DialogFooterGroup,
  DialogFooterGroupItem,
} from 'components/common/DialogFooter';
import Tab from 'components/common/dialog/TabSet/Tab';
import TabSet from 'components/common/dialog/TabSet';
import TabBody from 'components/common/dialog/TabSet/TabBody';
import TabHead from 'components/common/dialog/TabSet/TabHead';
import Loader from 'components/common/Loader';
import { DialogConfirmation } from 'components/common/dialog/DialogConfirmation';
import { ProductSuppliersAnalytics } from 'common/analytics';
import ProductSupplierInformationTab from './ProductSupplierInformationTab/container';
import AssignedProductsTab from './AssignedProductsTab/container';
import style from './style.scss';

const DIALOG_WIDTH = 540;
const lang = Wahanda.lang.menu.productSupplier.dialog;
const INFORMATION_TAB = 'INFORMATION_TAB';
const ASSIGNED_PRODUCTS_TAB = 'ASSIGNED_PRODUCTS_TAB';
// @ts-expect-error ts-migrate(2430) FIXME: Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
interface IProductSupplierDialogProps extends React.HTMLAttributes<Element> {
  id?: number;
  loading?: boolean;
  products?: any[];
  onClose: (...args: any[]) => any;
  actions: {
    submitSupplierAction: (...args: any[]) => any;
    requestProductSupplierAction: (...args: any[]) => any;
    resetSupplierDataAction: (...args: any[]) => any;
    deleteSupplierAction: (...args: any[]) => any;
  };
  length?: any;
  deleteSupplierAction?: any;
  submitSupplierAction?: any;
  requestProductSupplierAction?: any;
  resetSupplierDataAction?: any;
}
type ProductSupplierDialogState = {
  isFormValid?: boolean;
  deleteConfirmationDialogVisible?: boolean;
  selectedTab?: any;
};

export default class ProductSupplierDialog extends Component<
  IProductSupplierDialogProps,
  ProductSupplierDialogState
> {
  static defaultProps = {
    id: null,
    loading: false,
    products: [],
  };

  state = {
    selectedTab: INFORMATION_TAB,
    isFormValid: false,
    deleteConfirmationDialogVisible: false,
  };

  componentDidMount() {
    ProductSuppliersAnalytics.trackSupplierDetailsView();
    this.props.actions.resetSupplierDataAction();
    if (!this.isNew()) {
      this.props.actions.requestProductSupplierAction(this.props.id);
    }
  }

  onValid = () => {
    if (!this.state.isFormValid) {
      this.setState({
        isFormValid: true,
      });
    }
  };

  onInvalid = () => {
    if (this.state.isFormValid) {
      this.setState({
        isFormValid: false,
      });
    }
  };

  onDeleteButtonClick = () => {
    this.setState({ deleteConfirmationDialogVisible: true });
  };

  onDeleteConfirmDialogClose = () => {
    this.setState({ deleteConfirmationDialogVisible: false });
  };

  onSubmit = () => {
    ProductSuppliersAnalytics.trackSupplierDetailsSaveClick();
    this.props.actions.submitSupplierAction();
    this.props.onClose();
  };

  onTabClick = (selectedTab) => {
    switch (selectedTab) {
      case INFORMATION_TAB:
        ProductSuppliersAnalytics.trackSupplierInfoTabClick();
        break;
      case ASSIGNED_PRODUCTS_TAB:
        ProductSuppliersAnalytics.trackAssignedProductsTabClick();
        break;
      default:
    }
    this.setState({
      selectedTab,
    });
  };

  deleteSupplier = () => {
    ProductSuppliersAnalytics.trackSupplierDetailsDeleteClick();
    this.props.actions.deleteSupplierAction(this.props.id);
    this.props.onClose();
  };

  closeDialog = () => {
    ProductSuppliersAnalytics.trackSupplierDetailsClose();
    this.props.onClose();
  };

  isNew = () => this.props.id == null;

  renderDeleteConfirmDialog = () => (
    <DialogConfirmation
      dataTest="product-supplier-delete-confirmation-modal"
      title={lang.deleteConfirmationDialogTitle}
      text={lang.deleteConfirmationDialogText}
      buttonTitle={Wahanda.lang.shared.buttons.delete}
      buttonColor="red"
      onButtonClick={this.deleteSupplier}
      onClose={this.onDeleteConfirmDialogClose}
    />
  );

  renderContent = () => {
    const { selectedTab } = this.state;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const tabContentHeight = this.props.products.length * 45 + 32;
    return (
      <div data-hj-suppress className={style.dialogContent}>
        <TabSet>
          <TabHead>
            <Tab
              tab={INFORMATION_TAB}
              label={lang.tabInformationLabel}
              onClick={this.onTabClick}
              selectedTab={selectedTab}
            />
            <Tab
              tab={ASSIGNED_PRODUCTS_TAB}
              label={lang.tabAssignedProductsLabel}
              onClick={this.onTabClick}
              selectedTab={selectedTab}
              disabled={!this.state.isFormValid}
            />
          </TabHead>
          <TabBody tab={INFORMATION_TAB} selectedTab={selectedTab}>
            <div className={style.tabContent} style={{ height: tabContentHeight }}>
              <ProductSupplierInformationTab />
            </div>
          </TabBody>
          <TabBody tab={ASSIGNED_PRODUCTS_TAB} selectedTab={selectedTab}>
            <div className={style.tabContent} style={{ height: tabContentHeight }}>
              <AssignedProductsTab selected={selectedTab === ASSIGNED_PRODUCTS_TAB} />
            </div>
          </TabBody>
        </TabSet>
        {this.props.loading && <Loader positionAbsolute />}
      </div>
    );
  };

  renderFooter = () => {
    const cancelButton = (
      <DialogFooterButton
        title={Wahanda.lang.shared.buttons.cancel}
        type="secondary"
        onClick={this.closeDialog}
      />
    );
    const deleteButton = (
      <DialogFooterGroup>
        <DialogFooterGroupItem
          title={Wahanda.lang.shared.buttons.delete}
          type="critical"
          onClick={this.onDeleteButtonClick}
        />
      </DialogFooterGroup>
    );
    const submitButton = (
      <DialogFooterButton
        title={Wahanda.lang.shared.buttons.save}
        disabled={!this.state.isFormValid}
        onClick={this.onSubmit}
      />
    );
    return (
      <DialogFooter>
        {cancelButton}
        {!this.isNew() && deleteButton}
        {submitButton}
      </DialogFooter>
    );
  };

  render() {
    return (
      <ReactDialog
        dataTest="product-supplier-modal"
        title={lang.title}
        width={DIALOG_WIDTH}
        onClose={this.closeDialog}
        noContentPadding
        noContentTopBorders
      >
        <div className={style.container}>
          <Formsy onValid={this.onValid} onInvalid={this.onInvalid} onSubmit={this.onSubmit}>
            {this.renderContent()}
          </Formsy>
        </div>
        {this.state.deleteConfirmationDialogVisible && this.renderDeleteConfirmDialog()}
        {this.renderFooter()}
      </ReactDialog>
    );
  }
}
