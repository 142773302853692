import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Repeated extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-repeated', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 12 12"
      >
        <path d="M10 6c0 2.206-1.794 4-4 4-1.06 0-2.079-.433-2.824-1.175L2 10V6.667h3.333L4.126 7.874c.499.5 1.157.793 1.874.793C7.47 8.667 8.667 7.47 8.667 6H10zm0-4v3.333H6.667l1.208-1.208C7.377 3.627 6.719 3.333 6 3.333 4.53 3.333 3.333 4.53 3.333 6H2c0-2.206 1.794-4 4-4 1.08 0 2.08.424 2.827 1.174L10 2z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Repeated);
