import React from 'react';
import ReactDOM from 'react-dom';
import DiscountsTab from 'components/menu/DiscountsTab/container';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
// This is our main store. It is reused for the same venue, until a venue change happens.
const store = storeBuilder();

export function render(target, state) {
  const {
    venue: { id: venueId },
  } = state;
  // Render the component
  ReactDOM.render(
    <Provider store={store}>
      {/* @ts-expect-error */}
      <DiscountsTab venueId={venueId} />
    </Provider>,
    target,
  );
}

export function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}
