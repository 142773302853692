import { assocIn, curry, get, getIn, hashMap, conj, some } from 'mori';
import { appState } from 'state';

function key(item) {
  return ['waiting-for', item];
}

/*
 *  Functions to read, set and remove waiting state
 *
 *  Useful when issuing an XHR request and showing a spinner while wating for the request
 *  to complete
 */
export function isWaiting(itemKey, stateAtom = appState) {
  return getIn(stateAtom.deref(), key(itemKey)) === true;
}

export function isAnyWaiting(itemKeys, stateAtom = appState) {
  const waitingMap = get(stateAtom.deref(), 'waiting-for');

  return some((itemKey) => get(waitingMap, itemKey) === true, itemKeys);
}

export function startWaiting(itemKey, stateAtom = appState) {
  return stateAtom.swap(curry(assocIn, key(itemKey), true));
}

export function endWaitHashMap(itemKey) {
  return assocIn(hashMap(), key(itemKey), false);
}

export function endWait(itemKey, stateAtom = appState) {
  return stateAtom.swap(curry(conj, endWaitHashMap(itemKey)));
}
