import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
// For how long should an ID (appt or time block) be active?
import ID_CACHE_TIMEOUT from './config';

let appointmentChanges;
let timeBlockChanges;

function addChange(list, id) {
  // Expire old added caches
  const newList = list.filter(({ id: itemId }) => itemId !== id);
  // And add the new one
  newList.push({
    id,
    added: Date.now(),
  });

  return newList;
}

function parseBlockId(stringId) {
  if (!isNaN(stringId)) {
    return stringId;
  }
  const match = String(stringId).match(/(\d+)$/);

  if (match && !isNaN(+match[1])) {
    return parseInt(match[1], 10);
  }
  return null;
}

export function setup() {
  appointmentChanges = [];
  timeBlockChanges = [];

  const event = Wahanda.Event;

  const appointmentEvents = [
    event.APPOINTMENT_CONFIRMED,
    event.APPOINTMENT_REJECTED,
    event.APPOINTMENT_CANCELLED,
    event.APPOINTMENT_SAVED,
    event.APPOINTMENT_RESCHEDULED_BLOCK_CREATED,
    event.APPOINTMENT_CHECKED_OUT,
    event.APPOINTMENT_SET_NOSHOW,
  ];
  App.on(appointmentEvents.join(' '), (data) => {
    if (!isNaN(data)) {
      // If the event is passing in an number
      appointmentChanges = addChange(appointmentChanges, data);
    } else if (data && data.id > 0) {
      appointmentChanges = addChange(appointmentChanges, data.id);
    }
  });

  const apptGroupEvents = [
    event.APPOINTMENT_GROUP_SAVED,
    event.APPOINTMENT_GROUP_CONFIRMED,
    event.APPOINTMENT_GROUP_REJECTED,
    event.APPOINTMENT_GROUP_SET_NOSHOW,
    event.APPOINTMENT_GROUP_CANCELLED,
  ];
  App.on(apptGroupEvents.join(' '), (apptGroupCollection) => {
    apptGroupCollection.each((appt) => {
      appointmentChanges = addChange(appointmentChanges, appt.id);
    });
  });

  const timeBlockEvents = [event.CALENDAR_BLOCK_TIME_SAVED, event.CALENDAR_BLOCK_TIME_DELETED];
  App.on(timeBlockEvents.join(' '), ({ id: timeBlockId }) => {
    timeBlockChanges = addChange(timeBlockChanges, parseBlockId(timeBlockId));
  });

  App.on(Wahanda.Event.CALENDAR_MULTIPLE_BLOCKS_SAVED, ({ savedIds }) =>
    savedIds.forEach((timeBlockId) => {
      timeBlockChanges = addChange(timeBlockChanges, parseBlockId(timeBlockId));
    }),
  );
}

function different(list, idsToMatch) {
  let matched = 0;

  idsToMatch.forEach((id) => {
    const match = list.some((item) => id === item.id);
    matched += match ? 1 : 0;
  });

  return idsToMatch.length > matched;
}

export function hasChanges(apptIds, timeBlockIds) {
  const now = Date.now();
  const notExpiredFilter = (item) => now - item.added <= ID_CACHE_TIMEOUT;

  appointmentChanges = appointmentChanges.filter(notExpiredFilter);
  timeBlockChanges = timeBlockChanges.filter(notExpiredFilter);

  const hasChanged =
    different(appointmentChanges, apptIds) || different(timeBlockChanges, timeBlockIds);

  return hasChanged;
}
