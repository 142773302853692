import React from 'react';
import { Notice } from 'components/common/Notice';
import { Button } from 'components/common/Button';

export const KycBanner: React.FunctionComponent = () => (
  <div>
    <Notice type={'expose'} message={Wahanda.lang.shared.identityVerification.banner.body}>
      <div style={{ margin: '0 1rem' }}>
        <Button
          label={Wahanda.lang.shared.identityVerification.banner.button}
          size={'small'}
          onClick={() => window.open(App.getIdentityVerificationUrl(), '_blank')}
        />
      </div>
    </Notice>
  </div>
);
