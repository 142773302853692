App.Models.RoomType = BackboneEx.Model.Silent.extend({
  structure: {
    name: 'string',
    active: 'boolean',
    roomClassCode: 'string',
    minOccupancy: 'integer',
    maxOccupancy: 'integer',
    description: 'string',
  },

  attributesToSave: [
    'name',
    'active',
    'roomClassCode',
    'minOccupancy',
    'maxOccupancy',
    'description',
  ],

  url: function () {
    var base = '/venue/' + App.config.get('venue').id + '/room-type';
    if (this.id) {
      base += '/' + this.id;
    }
    base += '.json';
    return App.Api.wsUrl(base);
  },

  getGuestRange: function () {
    var result = '';
    if (this.get('minOccupancy')) {
      result = String(this.get('minOccupancy'));
      if (this.get('maxOccupancy') && this.get('minOccupancy') < this.get('maxOccupancy')) {
        return Wahanda.lang.settings.venue.roomTypes.guestNumber.plural.replace(
          '{{count}}',
          result + ' - ' + String(this.get('maxOccupancy')),
        );
      } else {
        if ('1' === result) {
          return Wahanda.lang.settings.venue.roomTypes.guestNumber.singular.replace(
            '{{count}}',
            result,
          );
        } else {
          return Wahanda.lang.settings.venue.roomTypes.guestNumber.plural.replace(
            '{{count}}',
            result,
          );
        }
      }
    }
    return result;
  },

  getClassTitle: function () {
    var code = this.get('roomClassCode');
    var match = App.referenceData.getRoomClassByCode(code);
    return match ? match.title : '';
  },
});
