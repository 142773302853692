import { trackEvent, ACTIONS } from 'common/analytics';

export class InlineClientAnalytics {
  public static CATEGORY = 'client';

  public static userClickedEditClient() {
    trackEvent(InlineClientAnalytics.CATEGORY, ACTIONS.CLICK, 'edit-client-information');
  }

  public static userClickedClientInfo(property: string) {
    trackEvent(InlineClientAnalytics.CATEGORY, ACTIONS.CLICK, 'see-client-information', property);
  }

  public static userClickedRemoveClient() {
    trackEvent(InlineClientAnalytics.CATEGORY, ACTIONS.CLICK, 'remove-client');
  }

  public static userClickedAddPhone() {
    trackEvent(InlineClientAnalytics.CATEGORY, ACTIONS.CLICK, 'update-phone-number');
  }

  public static userClickedAddEmail() {
    trackEvent(InlineClientAnalytics.CATEGORY, ACTIONS.CLICK, 'update-email-number');
  }
}
