import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'reduxStore/common/employees/actions';
import Services from './index';

const mapStateToProps = ({
  employees: {
    employee: { employeeOffers },
  },
}) => ({
  employeeOffers,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);
