import App from 'common/backbone-app';
import * as Sentry from '@sentry/browser';
import { useSalesforceBot } from '@treatwell/ui';
import { getSalesForceLocale } from 'common/salesforce-bot/getSalesForceLocale';
import { getSalesForceScriptConfig } from 'common/salesforce-bot/getSalesForceScriptConfig';
import { useEffect, useMemo } from 'react';
import { getTrackingByEventName } from 'src/tracking-container/TrackingEvents';
import { ChatSourceType } from 'src/tracking-container/events/chat';
import { sanitizeValues } from 'common/salesforce-bot/sanitizeValues';
import { getTokens } from 'common/salesforce-bot/getTokens';
import { getExternalUserFullName } from 'common/salesforce-bot/getExternalUserFullName';
import { FAKE_MASQUERADING_EMAIL } from 'common/salesforce-bot/constants';
import { withSentryScope } from 'helpers/withSentryScope';

const trackChatOpened = getTrackingByEventName('chat_opened');

let isLoadingSalesForceBot = false;

const SalesforceBot = () => {
  const {
    bootstrapJsSrc,
    init: { orgId, eswConfigDevName, siteUrl },
    scrt2URL,
  } = useMemo(() => getSalesForceScriptConfig(), []);

  const locale = useMemo(() => getSalesForceLocale(), []);

  const initialValues = sanitizeValues({
    externalUserFullName: getExternalUserFullName(),
    externalUserId: App.config.getAccountId(),
    externalVenueId: App.config.get('venue').id,
    externalLanguage: getSalesForceLocale(),
    externalSource: 'Web', // Web, iOS, Android
    externalUserEmail: App.isMasquerading()
      ? FAKE_MASQUERADING_EMAIL
      : App.config.get('account').emailAddress,
    externalCountry: App.config.get('venue').countryCode,
  });

  const { loadBot } = useSalesforceBot({
    init: { orgId, eswConfigDevName, siteUrl, scrt2URL },
    bootstrapJsSrc,
    initialPrechat: { hidden: initialValues.valids },
    locale,
    getTokens,
  });

  const openChat = withSentryScope(({ source }: { source: ChatSourceType }) => {
    trackChatOpened({ service: 'Salesforce', source });

    Sentry.addBreadcrumb({
      category: 'Salesforce chat',
      message: 'Chat initiated',
      data: { source, initialValues },
    });
    console.log('Sentry.addBreadcrumb', {
      category: 'Salesforce chat',
      message: 'Chat initiated',
      data: { source, initialValues },
    });

    if (window?.embeddedservice_bootstrap?.utilAPI?.launchChat) {
      Sentry.captureMessage('SalesForce chat READY and LAUNCHING NOW');
      console.log('Sentry.captureMessage', 'SalesForce chat READY and LAUNCHING NOW');
      window.embeddedservice_bootstrap.utilAPI.launchChat();
      return;
    }

    if (isLoadingSalesForceBot) {
      Sentry.captureMessage('SalesForce chat ALREADY LOADING');
      console.log('Sentry.captureMessage', 'SalesForce chat ALREADY LOADING');
      Wahanda.ToastNotifications.sendMessage('The chat is loading, please wait!', {
        variant: 'danger',
        timeout: 2500,
      });
      return;
    }

    isLoadingSalesForceBot = true;
    Sentry.addBreadcrumb({
      category: 'SalesForce chat',
      message: 'SalesForce chat NOT READY: ATTEMPTING TO INITIALISE',
      data: { initialValues },
    });
    console.log('Sentry.addBreadcrumb', {
      category: 'SalesForce chat',
      message: 'SalesForce chat NOT READY: ATTEMPTING TO INITIALISE',
      data: { initialValues },
    });

    loadBot()
      .then(() => {
        Sentry.captureMessage('SalesForce chat INITIALISED: SUCCESS');
        console.log('Sentry.captureMessage', 'SalesForce chat INITIALISED: SUCCESS');
      })
      .catch((error) => {
        Sentry.captureException({
          message: 'SalesForce chat INITIALISED: ERROR',
          data: { error, initialValues },
        });
        console.log('Sentry.captureException', {
          message: 'SalesForce chat INITIALISED: ERROR',
          data: { error, initialValues },
        });
        Wahanda.ToastNotifications.sendMessage('Failed to open chat, please try again later.', {
          variant: 'danger',
          timeout: 2500,
        });
      })
      .finally(() => {
        isLoadingSalesForceBot = false;
      });
  });

  useEffect(() => {
    App.on(Wahanda.Event.SALESFORCE_BOT_OPEN, openChat);
    () => {
      App.off(Wahanda.Event.SALESFORCE_BOT_OPEN);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default SalesforceBot;
