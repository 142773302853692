/* global _ */
import { DEFAULT_OPENING_HOURS } from 'common/constants/defaultOpeningHours';
import moment from 'common/moment';
import { trackEvent } from 'common/analytics';

const CHECKBOX_SELECTOR = '.js-day-checkbox';

/**
 * Opening hours partial.
 */
App.Views.Partials.OpeningHours = App.Views.Form.extend({
  events: {
    "change input[type='checkbox']": 'onChangeDayAvailability',
    'change select': 'onChangeWorkTime',
    'click .js-apply-to-all': 'onApplyToAll',
  },

  render: function () {
    const firstDay = App.config.get('jqueryDateFormat').firstDayOfWeek;
    const firstDayInt = Wahanda.Date.dayStringToNumberMap[firstDay];
    const timeFormat = App.config.get('jqueryDateFormat').defaultTime;
    const dayParams = [];
    let days = this.model.get('openingHours');
    let duration = App.referenceData.getDurationByScale(this.model.get('appointmentSlotType'));

    if (duration === 1) {
      // Workaround for wizard when settings are incomplete
      duration = 15; // Because channel defaults are usually 15 or 30
    }

    // Sort the days by firstDayOfWeek
    days = _.sortBy(days, function (day) {
      const intDay = Wahanda.Date.dayStringToNumberMap[day.dayOfWeek];
      if (intDay < firstDayInt) {
        return intDay + 7;
      }
      return intDay;
    });

    for (let i = 0, len = days.length; i < len; i++) {
      const day = days[i];

      const from = moment
        .duration(day.openingTime || this.getDefaultOpeningTime(day.dayOfWeek))
        .asMinutes();

      const to = moment
        .duration(day.closingTime || this.getDefaultClosingTime(day.dayOfWeek))
        .asMinutes();

      const fromOpts = Wahanda.Time.getTimeFieldValues(
        0,
        1440,
        duration,
        timeFormat,
        Wahanda.Time.getNearestSelectFunc(from, duration, 0),
      );
      const toOpts = Wahanda.Time.getTimeFieldValues(
        0,
        1440,
        duration,
        timeFormat,
        Wahanda.Time.getNearestSelectFunc(to, duration, 0),
      );
      dayParams.push({
        on: !!(day.openingTime && day.closingTime),
        id: day.dayOfWeek,
        title: Wahanda.lang.date.weekdaysByName[day.dayOfWeek],
        'times-from': Wahanda.Template.renderTemplate('form-select-items', {
          options: fromOpts,
        }),
        'times-to': Wahanda.Template.renderTemplate('form-select-items', {
          options: toOpts,
        }),
        isFirstDay: this.isFirstDay(day.dayOfWeek),
      });
    }

    this.$el.empty().append(
      Wahanda.Template.renderTemplate('opening-hours-days-items', {
        days: dayParams,
      }),
    );
  },

  getDefaultOpeningTime: function (day) {
    return DEFAULT_OPENING_HOURS.find(({ dayOfWeek }) => dayOfWeek === day).openingTime;
  },

  getDefaultClosingTime: function (day) {
    return DEFAULT_OPENING_HOURS.find(({ dayOfWeek }) => dayOfWeek === day).closingTime;
  },

  /**
   * Handler clicks on the enabled/disabled day's checkboxes
   *
   * @param Event event
   */
  onChangeDayAvailability: function (event) {
    const checkbox = event.target;
    const elem = $(checkbox).closest('li');
    elem[checkbox.checked ? 'addClass' : 'removeClass']('on');
    elem[checkbox.checked ? 'removeClass' : 'addClass']('off');
    // Disabling the selects
    const selects = elem.find('select');
    selects.attr('disabled', !checkbox.checked);

    this.updateOpeningHours(elem);
  },

  onChangeWorkTime: function (event) {
    const $select = $(event.target);
    const $li = $select.closest('li');
    const dayId = $li.find(CHECKBOX_SELECTOR).attr('id');
    $select.alignTimeSelects();

    this.updateOpeningHours($li);

    this.showApplyToAll(dayId);
  },

  /**
   * Updates opening hours based on the DOM state.
   *
   * @param jQuery $row The changed row, containing checkbox and two selects
   */
  updateOpeningHours: function ($row) {
    const $chbox = $row.find(CHECKBOX_SELECTOR);
    const sels = $row.find('select');
    const $from = sels.eq(0);
    const $to = sels.eq(1);

    const dayId = $chbox.attr('id');
    let from = null;
    let to = null;

    if ($chbox.prop('checked')) {
      from = $from.val();
      to = $to.val();
    }
    this.model.setOpeningHours(dayId, from, to);
  },

  isFirstDay: function (day) {
    return day === App.config.get('jqueryDateFormat').firstDayOfWeek;
  },

  getValues: function () {
    const list = [];
    this.$el.find('li').each(function () {
      const $li = $(this);
      const isEnabled = $li.find(CHECKBOX_SELECTOR).is(':checked');
      const day = $li.find(CHECKBOX_SELECTOR).attr('id');
      list.push({
        dayOfWeek: day,
        openingTime: isEnabled ? $li.find('select[name^=time-from]').val() : null,
        closingTime: isEnabled ? $li.find('select[name^=time-to]').val() : null,
      });
    });
    return list;
  },

  showValidationDialog: function () {
    function makeLink(target, isPhone) {
      const tpl = '<a href="{{schema}}{{target}}">{{target}}</a>';

      return Wahanda.Template.render(tpl, {
        schema: isPhone ? 'tel:' : 'mailto:',
        target: target,
      });
    }

    const textContent = Wahanda.lang.openingTimes.text
      .replace('{email}', makeLink(App.config.get('channel').supplierSupportEmail))
      .replace('{phone}', makeLink(App.config.get('channel').supplierSupportPhoneNumber, true));

    App.Views.Dialog.ValidationError.open(Wahanda.lang.openingTimes.heading, textContent);
  },

  ifValid: function (validCb) {
    // For Opening Times to be valid, there needs to be at least one day open.
    const valid = _.any(this.getValues(), function (value) {
      return !!value.openingTime && !!value.closingTime;
    });

    if (valid) {
      validCb();
    } else {
      this.showValidationDialog();
    }
  },

  hideApplyToAll: function () {
    const $dayWithApplyAll = this.$el.find('[data-weekday]:not(.hidden)');
    $dayWithApplyAll.toggleClass('hidden', true);
  },

  showApplyToAll: function (dayId) {
    this.hideApplyToAll();
    this.$el.find(`[data-weekday='${dayId}']`).toggleClass('hidden', false);
  },

  onApplyToAll: function (event) {
    const that = this;

    this.hideApplyToAll();

    const $timeFrom = (parent) => parent.find('select[name^=time-from]');
    const $timeTo = (parent) => parent.find('select[name^=time-to]');

    const $select = $(event.target);
    const $firstLi = $select.closest('li');
    const timeFrom = $timeFrom($firstLi).val();
    const timeTo = $timeTo($firstLi).val();
    const $openDays = this.$el.find('li:not(.off)');

    trackEvent('venue-opening-hours', 'click', 'apply-to-all', $openDays.length);

    $openDays.each(function () {
      const $li = $(this);

      if ($li.hasClass('off')) {
        return;
      }

      $timeFrom($li).val(timeFrom);
      $timeTo($li).val(timeTo);

      that.updateOpeningHours($li);
    });
  },
});
