import React from 'react';
import classnames from 'classnames';
import style from './style.scss';

interface Props {
  className?: string;
}

class Card extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { children, className } = this.props;
    return <div className={classnames(style.card, className)}>{children}</div>;
  }
}

export default Card;
