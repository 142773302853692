import { Message, Transporter, TransporterDelegate } from './transporter';

export class WebTransporter extends Transporter {
  private iframe: HTMLIFrameElement;

  constructor(delegate: TransporterDelegate, iframe: HTMLIFrameElement) {
    super(delegate);
    this.iframe = iframe;
  }

  _send(data: Message) {
    this.iframe.contentWindow?.postMessage({ type: 'WebTWConnect', data }, '*');
  }

  _listen() {
    window.addEventListener('message', ({ data }) => {
      if (data.type === 'WebTWConnect') {
        this._onMessage(data.data);
      }
    });
  }
}
