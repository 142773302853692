import React from 'react';
import ReactDOM from 'react-dom';
import assign from 'object-assign';
import Wahanda from 'common/wahanda';

const DEFAULT_OPTIONS = {
  modal: true,
  position: Wahanda.Util.isTouchDevice() ? 'top' : 'center',
  width: 600,
  height: 'auto',
  resizable: false,
  draggable: true,
  autoOpen: true,
};

export default class Dialog {
  dialog: any;

  options: boolean;

  closeParamsCallback = null;

  constructor(dialogOptions) {
    // @ts-expect-error ts-migrate(2551) FIXME: Property '$dialog' does not exist on type 'Dialog'... Remove this comment to see the full error message
    this.$dialog = null;
    // Save the passed in close obj
    if (dialogOptions && dialogOptions.close) {
      // eslint-disable-next-line no-param-reassign
      dialogOptions.passedInClose = dialogOptions.close;
    }
    this.options = assign({}, DEFAULT_OPTIONS, dialogOptions, {
      close: this.close,
    });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dialogClass' does not exist on type 'boo... Remove this comment to see the full error message
    this.options.dialogClass = `dialog2${
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'dialogClass' does not exist on type 'boo... Remove this comment to see the full error message
      this.options.dialogClass ? ` ${this.options.dialogClass}` : ''
    }`;
  }

  renderHeader() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dialogTitle' does not exist on type 'boo... Remove this comment to see the full error message
    const template = `<div class="dialog2--title">${this.options.dialogTitle}</div>
      <span class="dialog2--close js-close"></span>`;
    // @ts-expect-error ts-migrate(2551) FIXME: Property '$dialog' does not exist on type 'Dialog'... Remove this comment to see the full error message
    this.$dialog
      .parents('.ui-dialog')
      .find('.ui-dialog-titlebar')
      .empty()
      .append(template)
      .find('.js-close')
      // @ts-expect-error ts-migrate(2551) FIXME: Property '$dialog' does not exist on type 'Dialog'... Remove this comment to see the full error message
      .click(() => this.$dialog.dialog('close'));
  }

  open(DialogContent) {
    this.openWithContent(<DialogContent closeDialog={this.close} />);
  }

  openWithContent(content) {
    // @ts-expect-error ts-migrate(2551) FIXME: Property '$dialog' does not exist on type 'Dialog'... Remove this comment to see the full error message
    this.$dialog = jQuery('<div>').dialog(this.options);
    this.renderHeader();
    ReactDOM.render(
      <div className="dialog2react">{content}</div>,
      // @ts-expect-error ts-migrate(2551) FIXME: Property '$dialog' does not exist on type 'Dialog'... Remove this comment to see the full error message
      this.$dialog[0],
    );
  }

  registerCloseParamsCallback = (callback) => {
    this.closeParamsCallback = callback;
  };

  close = (p1, p2) => {
    // @ts-expect-error ts-migrate(2551) FIXME: Property '$dialog' does not exist on type 'Dialog'... Remove this comment to see the full error message
    ReactDOM.unmountComponentAtNode(this.$dialog[0]);
    // @ts-expect-error ts-migrate(2551) FIXME: Property '$dialog' does not exist on type 'Dialog'... Remove this comment to see the full error message
    this.$dialog.remove();
    const params = (() => {
      const param = p1 instanceof jQuery.Event ? p2 : p1;
      // @ts-expect-error ts-migrate(2721) FIXME: Cannot invoke an object which is possibly 'null'.
      return this.closeParamsCallback ? assign(param, this.closeParamsCallback()) : param;
    })();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'passedInClose' does not exist on type 'b... Remove this comment to see the full error message
    if (this.options.passedInClose && typeof this.options.passedInClose === 'function') {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'passedInClose' does not exist on type 'b... Remove this comment to see the full error message
      this.options.passedInClose(params);
    }
  };
}
