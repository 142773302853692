import {
  GET_SALES_REPORT_REQUEST,
  GET_SALES_REPORT_RECEIVE,
  GET_SALES_REPORT_FAILURE,
  GET_PRODUCT_SALES_REPORT_REQUEST,
  GET_PRODUCT_SALES_REPORT_RECEIVE,
  GET_PRODUCT_SALES_REPORT_FAILURE,
  GET_EMPLOYEE_SALES_REPORT_REQUEST,
  GET_EMPLOYEE_SALES_REPORT_RECEIVE,
  GET_EMPLOYEE_SALES_REPORT_FAILURE,
  GET_SERVICE_SALES_REPORT_REQUEST,
  GET_SERVICE_SALES_REPORT_RECEIVE,
  GET_SERVICE_SALES_REPORT_FAILURE,
  GET_CHANNEL_SALES_REPORT_REQUEST,
  GET_CHANNEL_SALES_REPORT_RECEIVE,
  GET_CHANNEL_SALES_REPORT_FAILURE,
  GET_DAILY_SALES_REPORT_REQUEST,
  GET_DAILY_SALES_REPORT_RECEIVE,
  GET_DAILY_SALES_REPORT_FAILURE,
  GET_MONTHLY_SALES_REPORT_RECEIVE,
  GET_MONTHLY_SALES_REPORT_REQUEST,
  GET_MONTHLY_SALES_REPORT_FAILURE,
  GET_VAT_REPORT_REQUEST,
  GET_VAT_REPORT_RECEIVE,
  GET_VAT_REPORT_FAILURE,
  GET_VENUE_EMPLOYEES_REQUEST,
  GET_VENUE_EMPLOYEES_RECEIVE,
  GET_VENUE_EMPLOYEES_FAILURE,
  GET_RETTENTION_REPORT_REQUEST,
  GET_RETTENTION_REPORT_RECEIVE,
  GET_CLIENT_ACQUISITION_REQUEST,
  GET_CLIENT_ACQUISITION_RECEIVE,
  GET_POS_REPORT_DATA_REQUEST,
  GET_POS_REPORT_DATA_RECEIVE,
  GET_POS_REPORT_DATA_FAILURE,
} from 'reduxStore/actionsConst';

export const requestSalesReportDataAction = (
  venueId,
  from,
  to,
  bookingChannel,
  employeeFilter,
) => ({
  type: GET_SALES_REPORT_REQUEST,
  venueId,
  from,
  to,
  bookingChannel,
  employeeFilter,
});

export const receiveSalesReportDataAction = (data) => ({
  type: GET_SALES_REPORT_RECEIVE,
  data,
});

export const requestSalesReportDataFailureAction = (ex) => ({
  type: GET_SALES_REPORT_FAILURE,
  ex,
});

export const requestProductSalesReportDataAction = (venueId, from, to, employeeFilter) => ({
  type: GET_PRODUCT_SALES_REPORT_REQUEST,
  venueId,
  from,
  to,
  employeeFilter,
});

export const receiveProductSalesReportDataAction = (data) => ({
  type: GET_PRODUCT_SALES_REPORT_RECEIVE,
  data,
});

export const requestProductSalesReportDataFailureAction = (ex) => ({
  type: GET_PRODUCT_SALES_REPORT_FAILURE,
  ex,
});

export const requestEmployeeSalesReportDataAction = (venueId, from, to) => ({
  type: GET_EMPLOYEE_SALES_REPORT_REQUEST,
  venueId,
  from,
  to,
});

export const receiveEmployeeSalesReportDataAction = (data) => ({
  type: GET_EMPLOYEE_SALES_REPORT_RECEIVE,
  data,
});

export const requestEmployeeSalesReportDataFailureAction = (ex) => ({
  type: GET_EMPLOYEE_SALES_REPORT_FAILURE,
  ex,
});

export const requestServiceSalesReportDataAction = (
  venueId,
  from,
  to,
  channel,
  employeeFilter,
) => ({
  type: GET_SERVICE_SALES_REPORT_REQUEST,
  venueId,
  from,
  to,
  channel,
  employeeFilter,
});

export const receiveServiceSalesReportDataAction = (data) => ({
  type: GET_SERVICE_SALES_REPORT_RECEIVE,
  data,
});

export const requestServiceSalesReportDataFailureAction = (ex) => ({
  type: GET_SERVICE_SALES_REPORT_FAILURE,
  ex,
});

export const requestChannelSalesReportDataAction = (venueId, from, to) => ({
  type: GET_CHANNEL_SALES_REPORT_REQUEST,
  venueId,
  from,
  to,
});

export const receiveChannelSalesReportDataAction = (data) => ({
  type: GET_CHANNEL_SALES_REPORT_RECEIVE,
  data,
});

export const requestChannelSalesReportDataFailureAction = (ex) => ({
  type: GET_CHANNEL_SALES_REPORT_FAILURE,
  ex,
});

export const requestVatReportDataAction = (venueId, from, to, employeeId) => ({
  type: GET_VAT_REPORT_REQUEST,
  venueId,
  from,
  to,
  employeeId,
});

export const receiveVatReportDataAction = (data) => ({
  type: GET_VAT_REPORT_RECEIVE,
  data,
});

export const requestVatReportDataFailureAction = (ex) => ({
  type: GET_VAT_REPORT_FAILURE,
  ex,
});

export const requestMonthlySalesReportDataAction = (
  venueId,
  from,
  to,
  channel,
  employeeFilter,
) => ({
  type: GET_MONTHLY_SALES_REPORT_REQUEST,
  venueId,
  from,
  to,
  channel,
  employeeFilter,
});

export const receiveMonthlySalesReportDataAction = (data) => ({
  type: GET_MONTHLY_SALES_REPORT_RECEIVE,
  data,
});

export const requestMonthlySalesReportDataFailureAction = (ex) => ({
  type: GET_MONTHLY_SALES_REPORT_FAILURE,
  ex,
});

export const requestDailySalesReportDataAction = (
  venueId,
  from,
  to,
  bookingChannel,
  employeeFilter,
) => ({
  type: GET_DAILY_SALES_REPORT_REQUEST,
  venueId,
  from,
  to,
  bookingChannel,
  employeeFilter,
});

export const receiveDailySalesReportDataAction = (data) => ({
  type: GET_DAILY_SALES_REPORT_RECEIVE,
  data,
});

export const requestDailySalesReportDataFailureAction = (ex) => ({
  type: GET_DAILY_SALES_REPORT_FAILURE,
  ex,
});

export const requestVenueEmployeeDataAction = (venueId) => ({
  type: GET_VENUE_EMPLOYEES_REQUEST,
  venueId,
});

export const receiveVenueEmployeeDataAction = (data) => ({
  type: GET_VENUE_EMPLOYEES_RECEIVE,
  data,
});

export const requestVenueEmployeeDataFailureAction = (ex) => ({
  type: GET_VENUE_EMPLOYEES_FAILURE,
  ex,
});

export const requestRettentionReportDataAction = (props) => ({
  type: GET_RETTENTION_REPORT_REQUEST,
  fromDate: props.fromDate,
  toDate: props.toDate,
  selectedChannel: props.selectedChannel,
  selectedEmployeeId: props.selectedEmployeeId,
});

export const receiveRettentionReportDataAction = (data) => ({
  type: GET_RETTENTION_REPORT_RECEIVE,
  data,
});

export const requestAcquisitionReportDataAction = (params) => ({
  type: GET_CLIENT_ACQUISITION_REQUEST,
  fromDate: params.fromDate,
  toDate: params.toDate,
  selectedEmployeeId: params.selectedEmployeeId,
});

export const receiveAcquisitionReportDataAction = (data) => ({
  type: GET_CLIENT_ACQUISITION_RECEIVE,
  data,
});

export const getPOSReportDataRequestAction = (params) => ({
  type: GET_POS_REPORT_DATA_REQUEST,
  from: params.from,
  to: params.to,
  venueId: params.venueId,
});

export const getPOSReportDataReceiveAction = (data) => ({
  type: GET_POS_REPORT_DATA_RECEIVE,
  data,
});

export const getPOSReportDataFailureAction = (ex) => ({
  type: GET_POS_REPORT_DATA_FAILURE,
  ex,
});
