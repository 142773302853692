import { enableRecording } from 'common/analytics';

App.Views.Team = BackboneEx.View.Main.extend({
  initialize: function () {
    this.lastVenueId = App.getVenueId();
    enableRecording();
  },

  showLoader: function showLoader() {
    this.lastShownLoader = this.$('.data-content:visible').loadmask();
  },

  hideLoader: function hideLoader() {
    return this.lastShownLoader && this.lastShownLoader.unloadmask();
  },

  render: function () {
    App.headerView.setModule('team');

    const node = document.getElementById('home-holder');

    if (App.getVenueId() !== this.lastVenueId) {
      // Destroy the previous state, as we have a new venue
      App.ES6.Initializers.Team({ node }).destroy();
      this.lastVenueId = App.getVenueId();
    }

    App.ES6.Initializers.Team({
      node,
      venueId: this.lastVenueId,
      tab: (App.mainViewOptions || {}).initialTab,
    }).render();

    this.$('#nav2 .title:visible').center({ horizontal: false });
  },
});
