import React from 'react';

interface Props {
  className?: string;
}

class TeamSetup extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-team-setup', this.props.className].join(' ')}
        viewBox="0 0 95 85"
      >
        <g fill="none" transform="translate(1 1)" fillRule="evenodd">
          <rect
            width="18"
            height="18"
            x="75"
            fill="#FFB500"
            stroke="#001E62"
            rx="2"
            fillRule="nonzero"
            strokeWidth="2"
          />
          <polygon
            fill="#001E62"
            points="85 4 85 8 89 8 89 10 85 10 85 14 83 14 83 10 79 10 79 8 83 8 83 4"
            fillRule="nonzero"
          />
          <g transform="translate(0 13)">
            <path
              fill="#00B0B9"
              d="M40.76154 44.42301C40.76154 44.42301 47.16684 62.25471 65.96514 55.27611 62.15544 56.12571 58.01094 55.35891 54.75654 53.20431 51.50214 51.04791 49.18014 47.53161 48.47544 43.69221L43.06104 43.69221C41.32314 43.69221 39.79584 44.58861 38.91294 45.94401L40.76154 44.42301zM12.21813 50.0076C12.73203 50.0076 13.14513 49.5837 13.13613 49.0698 13.04883 44.5392 14.68863 42.1308 17.71173 38.3508 20.85453 34.4205 25.48413 31.5036 27.26433 26.7966 29.65293 20.4768 26.07813 13.0266 28.60803 6.7617 29.74383 3.9501 32.12793 1.692 34.95573.6174 35.27253.4968 35.18793.0288 34.84953.0288L32.43123.0288C21.26763.0288 12.21813 9.0783 12.21813 20.2419L12.21813 50.0076z"
              fillRule="nonzero"
            />
            <path
              stroke="#001E62"
              d="M12 49.0698L12 20.2131C12 9.0495 20.8364 0 32 0L40.02543 0C47.87073.4185 54.10323 7.1766 54.10323 15.1263L54.10323 16 44.27163 16C41.92083 16.0198 40.02543 18.3573 40.02543 20.7072L40.02543 26.3727C40.02543 41.7312 32.80023 54.1809 17.44263 54.1809 9.45898438 54.1809-2.4407143e-12 57.9345306-2.4407143e-12 70M48.58029 21.7332C47.45079 22.5297 45.94149 22.5486 44.79399 21.7908"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
            <path
              stroke="#001E62"
              d="M49.5558 12.70242C50.8365 14.01462 50.7303 16.08462 50.9274 17.90712 51.2667 21.03552 52.7796 24.02622 55.1196 26.13582 55.5741 26.54622 55.8783 27.11052 55.9008 27.72162 55.9674 29.60172 53.5923 29.53692 52.8327 30.85092 52.3161 31.74282 52.4295 32.96772 52.9371 33.86412 53.5887 35.01432 52.3944 35.90982 51.3648 35.93052 51.6132 36.27342 52.1028 36.63162 52.506 37.00242 52.7715 37.24632 52.7688 37.66032 52.5114 37.91412 52.0173 38.40102 51.804 39.54312 51.7329 40.22442 51.5142 42.32502 49.0905 43.43562 46.9863 43.60302 44.8812 43.76952 42.6321 43.44282 40.7619 44.42292 38.9061 45.39582 37.8333 47.62062 38.1141 49.69602 38.3967 51.77142 39.9528 53.59212 41.9238 54.30402M47.78577 34.1451C48.24657 34.8471 49.27617 35.8803 51.36507 35.9298"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
            <path
              stroke="#001E62"
              d="M48.47571,43.69176 C49.17951,47.53206 51.50151,51.04836 54.75681,53.20386 C58.01121,55.35936 62.15481,56.12526 65.96541,55.27566 C64.46511,60.16806 59.28741,63.65106 54.19161,63.19746"
              strokeWidth="2"
            />
            <path
              stroke="#001E62"
              d="M14.21235,59.4198 C17.04825,65.4012 23.14215,69.5367 30.20175,69.5367 C37.21455,69.5367 43.27335,65.4552 46.13355,59.5386"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </g>
          <path
            fill="#001E62"
            d="M85,23 L85,32 L58,32 L58,30 L83,30 L83,23 L85,23 Z M70,8 L70,10 L48,10 L48,8 L70,8 Z M65,23 L65,25 L58,25 L58,23 L65,23 Z M73,19 L73,21 L58,21 L58,19 L73,19 Z M70,15 L70,17 L58,17 L58,15 L70,15 Z M49,8 L49,16 L47,16 L47,8 L49,8 Z"
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  }
}

export const icon = TeamSetup;
