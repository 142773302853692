import { on, reducer } from 'ts-action';
import { consultationFormsVenueSettingsReceived } from './actions';

export interface UDVCovidFormLinkState {
  covidConsentFormsEnabled: boolean;
}

export const initialState: UDVCovidFormLinkState = {
  covidConsentFormsEnabled: false,
};

export const udvCovidFormLink = reducer(
  initialState,
  on(consultationFormsVenueSettingsReceived, (state, action) => ({
    ...state,
    ...action.payload,
  })),
);
