import React from 'react';
import Wahanda from 'common/wahanda';
import { Currency } from 'components/common/DataFormat';
import {
  Table,
  TableColumnHeader,
  Align,
  TableRow,
  TableBody,
  TableHeader,
  TableData,
  Level,
  TableLayout,
} from 'components/common/Table';
import SubHeader from '../SubHeader';
import style from './style.scss';

interface StatsSummaryHeaders {
  mainHeader: string;
  salesHeader: string;
  refundsHeader: string;
}

interface VoucherStats {
  quantity: number;
  grossAmount: number;
}

interface SummaryStats {
  sales: VoucherStats;
  refunds: VoucherStats;
}

export interface Props {
  singlePurposeVouchers?: SummaryStats;
  multiPurposeVouchers?: SummaryStats;
}

const LANG = Wahanda.lang.reports.posReports.salesPeriod.notRevenueRelevant;

class NotRevenueRelevant extends React.PureComponent<Props> {
  public render() {
    const { singlePurposeVouchers, multiPurposeVouchers } = this.props;

    if (!(singlePurposeVouchers || multiPurposeVouchers)) {
      return null;
    }

    return (
      <div>
        <SubHeader text={LANG.header} />
        <Table border layout={TableLayout.FIXED}>
          <TableHeader>{this.renderHeader()}</TableHeader>
          <TableBody>
            {this.renderSinglePurposeStats()}
            {this.renderMultiPurposeStats()}
          </TableBody>
        </Table>
      </div>
    );
  }

  private renderHeader() {
    return (
      <TableRow>
        <TableColumnHeader>{LANG.type}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.quantity}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.gross}</TableColumnHeader>
      </TableRow>
    );
  }

  private renderStatsSummary(headers: StatsSummaryHeaders, data: SummaryStats) {
    return (
      <React.Fragment>
        <TableRow className={style.subHeader}>
          <TableColumnHeader>{headers.mainHeader}</TableColumnHeader>
          <TableColumnHeader align={Align.RIGHT} />
          <TableColumnHeader align={Align.RIGHT} />
        </TableRow>
        {this.renderVoucherSummary(headers.salesHeader, data.sales)}
        {this.renderVoucherRefundSummary(headers.refundsHeader, data.refunds)}
      </React.Fragment>
    );
  }

  private renderMultiPurposeStats() {
    const { multiPurposeVouchers } = this.props;
    if (multiPurposeVouchers == null) {
      return null;
    }

    const headers = {
      mainHeader: LANG.noVatVouchers,
      salesHeader: LANG.vouchersSold,
      refundsHeader: LANG.vouchersRefunded,
    };
    return this.renderStatsSummary(headers, multiPurposeVouchers);
  }

  private renderSinglePurposeStats() {
    const { singlePurposeVouchers } = this.props;
    if (singlePurposeVouchers == null) {
      return null;
    }

    const headers = {
      mainHeader: LANG.vatVouchers,
      salesHeader: LANG.vouchersRedeemed,
      refundsHeader: LANG.redeemedVouchersRefunded,
    };
    return this.renderStatsSummary(headers, singlePurposeVouchers);
  }

  private renderVoucherSummary(type: string, stats: VoucherStats) {
    return (
      <TableRow level={Level.ONE}>
        <TableData align={Align.LEFT}>{type}</TableData>
        <TableData align={Align.RIGHT}>{stats.quantity}</TableData>
        <TableData align={Align.RIGHT}>
          <Currency amount={stats.grossAmount} />
        </TableData>
      </TableRow>
    );
  }

  private renderVoucherRefundSummary(type: string, stats: VoucherStats) {
    return (
      <TableRow level={Level.ONE}>
        <TableData align={Align.LEFT}>{type}</TableData>
        <TableData align={Align.RIGHT}>{stats.quantity}</TableData>
        <TableData align={Align.RIGHT}>
          <Currency alwaysNegative amount={stats.grossAmount} />
        </TableData>
      </TableRow>
    );
  }
}

export default NotRevenueRelevant;
