import React from 'react';
import pathToRegexp from 'path-to-regexp';
import BigNumber from 'bignumber.js';
import { Notice } from 'components/common/Notice';
import Loader from 'components/common/Loader';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';
import _ from 'common/underscore';
import { ServiceSalesReportAnalytics } from 'common/analytics';
import { CHANNEL } from '../BookingChannelFilter';
import { allEmployeesOptionValue } from '../EmployeeFilter';
import DISPLAY from '../displayStateEnum';
import Filters from '../common/Filters';
import ReportTable from './reportTable';
import ReportLayout from '../common/ReportLayout';
import ROUTES from '../SalesReportContainer/reportRoutes';

interface IServiceSalesReportProps extends React.HTMLAttributes<Element> {
  history: {
    push: (...args: any[]) => any;
  };
  match: {
    params: {
      venueId: string;
      fromDate?: string;
      toDate?: string;
      selectedChannel?: string;
      employeeFilter?: string;
    };
  };
  requestServiceSalesReportDataAction: (...args: any[]) => any;
  reportData?: {
    rows: {
      type?: string;
      rows?: {
        service?: string;
        numberIncludingCancelled?: number;
        grossTotalIncludingCancelled?: number;
        cancelledNumber?: number;
        cancelledGrossTotal?: number;
        number?: number;
        netTotal?: number;
        vat?: number;
        grossTotal?: number;
      }[];
      numberIncludingCancelled?: number;
      grossTotalIncludingCancelled?: number;
      cancelledNumber?: number;
      cancelledGrossTotal?: number;
      number?: number;
      netTotal?: number;
      vat?: number;
      grossTotal?: number;
    }[];
    total: {
      numberIncludingCancelled?: number;
      grossTotalIncludingCancelled?: number;
      cancelledNumber?: number;
      cancelledGrossTotal?: number;
      number?: number;
      netTotal?: number;
      vat?: number;
      grossTotal?: number;
    };
  };
  displayState: any;
  params?: any;
  push?: any;
}

export class ServiceSalesReport extends React.Component<IServiceSalesReportProps, {}> {
  static defaultProps = {
    reportData: null,
  };

  componentDidMount = () => {
    ServiceSalesReportAnalytics.trackPageView();
    this.requestDataFromApi();
  };

  componentDidUpdate = (prevProps) => {
    const { params } = this.props.match;
    if (!_.isEqual(params, prevProps.match.params)) {
      this.requestDataFromApi();
    }
  };

  onFilterChange = (filters) => {
    const { venueId } = this.getQueryParams();
    const { fromDate, toDate, selectedChannel, selectedEmployeeId } = filters;
    const toPath = pathToRegexp.compile(ROUTES.SERVICE_SALES_REPORT);
    const newPath = toPath({
      venueId,
      fromDate: fromDate.formatApiDateString(),
      toDate: toDate.formatApiDateString(),
      employeeFilter:
        selectedChannel != null && selectedEmployeeId == null
          ? allEmployeesOptionValue
          : selectedEmployeeId,
      selectedChannel,
    });
    this.props.history.push(newPath);
  };

  getLoadingSpinner = () => <Loader />;

  getErrorMessage = () => <div>{Wahanda.lang.reports.sales.error}</div>;

  getQueryParams = () => {
    const { venueId, fromDate, toDate, employeeFilter, selectedChannel } = _.defaults(
      this.props.match.params,
      {
        fromDate: moment().startOf('month').formatApiDateString(),
        toDate: moment().endOf('month').formatApiDateString(),
        employeeFilter: allEmployeesOptionValue,
        selectedChannel: CHANNEL.ALL,
      },
    );
    return {
      venueId,
      selectedChannel,
      fromDate: moment(fromDate),
      toDate: moment(toDate),
      employeeFilter: new BigNumber(employeeFilter).toNumber(),
    };
  };

  trackDateFilterChange = (data) => {
    ServiceSalesReportAnalytics.trackReportDateFilterChange(data.type);
  };

  requestDataFromApi = () => {
    const { fromDate, toDate, selectedChannel, employeeFilter, venueId } = this.getQueryParams();
    this.props.requestServiceSalesReportDataAction(
      venueId,
      moment(fromDate),
      moment(toDate),
      selectedChannel === CHANNEL.ALL ? null : selectedChannel,
      employeeFilter === allEmployeesOptionValue ? null : employeeFilter,
    );
  };

  render() {
    let body;
    switch (this.props.displayState) {
      case DISPLAY.WAITING:
        body = this.getLoadingSpinner();
        break;
      case DISPLAY.REPORT:
        body = (
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          <ReportTable rows={this.props.reportData.rows} total={this.props.reportData.total} />
        );
        break;
      case DISPLAY.ERROR:
        body = this.getErrorMessage();
        break;
      default:
        throw new Error(`Unrecognised display state: ${this.props.displayState}`);
    }
    const dateFilterProps = {
      uniqueId: 'serviceSalesReportDateFilter',
      fromDate: this.getQueryParams().fromDate,
      toDate: this.getQueryParams().toDate,
    };
    const filters = (
      <Filters
        date={dateFilterProps}
        channel={{ selectedChannel: this.getQueryParams().selectedChannel }}
        employee={{
          categoryFilterEnabled: true,
          selectedEmployeeId: this.getQueryParams().employeeFilter,
        }}
        onFilterChange={this.onFilterChange}
        onDatePickerChange={this.trackDateFilterChange}
        onEmployeeChange={ServiceSalesReportAnalytics.trackEmployeeFilterChange}
        onChannelChange={ServiceSalesReportAnalytics.trackChannelFilterChange}
      />
    );
    const messages = (
      <Notice type="success">
        <p
          dangerouslySetInnerHTML={{
            __html: Wahanda.lang.reports.newReportsMessage,
          }}
        />
      </Notice>
    );
    return (
      <ReportLayout
        messages={messages}
        filter={filters}
        showTimeStamp={this.props.displayState === DISPLAY.REPORT}
        body={body}
      />
    );
  }
}
