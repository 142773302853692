import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CLIENT_ROUTES } from 'components/reports/newSalesReports/SalesReportContainer/reportRoutes';
import ClientRettentionReport from './ClientRetentionReport/container';
import { ClientAcquisitionReport } from './ClientAcquisitionReport/container';

export class ClientReports extends React.PureComponent {
  public render() {
    return (
      <Switch>
        <Route path={CLIENT_ROUTES.RETENTION_REPORT} component={ClientRettentionReport} />
        <Route path={CLIENT_ROUTES.ACQUISITION_REPORT} component={ClientAcquisitionReport} />
      </Switch>
    );
  }
}
