import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import YearMonthPosReport from './index';
import { getPOSReportDataRequestAction } from '../../../newSalesReports/actions';

const mapStateToProps = (state) => ({
  displayState: state.salesReport.posReport.displayState,
  reportData: state.salesReport.posReport.reportData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getPOSReportDataRequestAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(YearMonthPosReport);
