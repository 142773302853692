import {
  GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_SUCCESS,
  GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_FAILURE,
  CLEAR_EMAIL_CAMPAIGN_ERROR,
  SEND_EMAIL_CAMPAIGN_REQUEST,
  SEND_EMAIL_CAMPAIGN_SUCCESS,
  SEND_EMAIL_CAMPAIGN_FAILURE,
  SEND_SMART_DISCOUNTS_CAMPAIGN_REQUEST,
  SEND_SERVICE_ON_SALE_CAMPAIGN_REQUEST,
  SEND_APP_FOR_YOUR_CLIENTS_CAMPAIGN_REQUEST,
  RECIPIENT_COUNT_RESET,
  SEND_BACK_TO_BUSINESS_CAMPAIGN_REQUEST,
} from 'reduxStore/actionsConst';

export const ERROR_SUMMARY = 'ERROR_SUMMARY';

export const ERROR_SEND = 'ERROR_SEND';

const campaignSummary = (state = {}, action) => {
  switch (action.type) {
    case GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_SUCCESS:
      return {
        ...state,
        recipientCount: action.count,
      };

    case GET_EMAIL_CAMPAIGN_RECIPIENT_COUNT_FAILURE:
      return {
        ...state,
        error: {
          type: ERROR_SUMMARY,
        },
      };

    case SEND_SMART_DISCOUNTS_CAMPAIGN_REQUEST:
      return {
        ...state,
        isSending: true,
      };

    case SEND_SERVICE_ON_SALE_CAMPAIGN_REQUEST:
      return {
        ...state,
        isSending: true,
      };

    case SEND_APP_FOR_YOUR_CLIENTS_CAMPAIGN_REQUEST:
      return {
        ...state,
        isSending: true,
      };

    case RECIPIENT_COUNT_RESET:
      return {};

    case SEND_EMAIL_CAMPAIGN_REQUEST:
      return {
        ...state,
        isSending: true,
      };

    case SEND_EMAIL_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isSent: true,
        isSending: false,
      };

    case SEND_EMAIL_CAMPAIGN_FAILURE:
      return {
        ...state,
        isSending: false,
        isSent: false,
        error: {
          type: ERROR_SEND,
        },
      };

    case SEND_BACK_TO_BUSINESS_CAMPAIGN_REQUEST:
      return {
        ...state,
        isSending: true,
      };

    case CLEAR_EMAIL_CAMPAIGN_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default campaignSummary;
