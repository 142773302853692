import {
  DISCARD_DISCOUNT_EMAIL_CAMPAIGN_DIALOG,
  PRESENT_DISCOUNT_EMAIL_CAMPAIGN_DIALOG,
} from 'reduxStore/actionsConst';

import { DialogState, DialogTypes } from './types';

const initialState: DialogState = {
  discountId: null,
  isVisible: false,
};

export default function discountEmailCampaignDialog(
  state = initialState,
  action: DialogTypes,
): DialogState {
  switch (action.type) {
    case PRESENT_DISCOUNT_EMAIL_CAMPAIGN_DIALOG:
      return {
        discountId: action.discountId,
        isVisible: true,
      };
    case DISCARD_DISCOUNT_EMAIL_CAMPAIGN_DIALOG:
      return {
        discountId: null,
        isVisible: false,
      };
    default:
      return state;
  }
}
