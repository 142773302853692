import React from 'react';
import classnames from 'classnames';
import Rating from 'react-components/Rating';
import StarGradient from 'react-components/Rating/StarGradient';
import style from './style.scss';

type VenueInfoDetailsProps = {
  rating: number;
  title: string;
  isPending: boolean;
  pendingText: string;
};
const VenueInfoDetails: React.SFC<VenueInfoDetailsProps> = ({
  rating,
  title,
  isPending,
  pendingText,
}) => {
  const ratingStars = !isPending ? (
    <div className={style.rating}>
      <span className={classnames({ [style.noRating]: rating === 0 || !rating })}>
        {rating ? Number(rating).toFixed(1) : 0}
      </span>
      <Rating rating={rating} size="small" />
      <StarGradient />
    </div>
  ) : null;
  const pending = isPending ? <div className={style.pending}>{pendingText}</div> : null;
  return (
    <div className={style.details}>
      <div
        className={classnames([
          style.title,
          {
            [style.pendingTitle]: isPending,
          },
        ])}
      >
        {title}
      </div>
      {ratingStars}
      {pending}
    </div>
  );
};

export default VenueInfoDetails;
