import React from 'react';
import moment from 'common/moment';
import Wahanda from 'common/wahanda';
import { Notice } from 'components/common/Notice';
import Dialog from 'components/common/react-dialog';
import { Loader } from 'components/common/Loader';
import { DatePicker } from 'components/common/DatePicker';
import { DialogFooterButtonProps, DialogFooter } from 'components/common/DialogFooter';
import * as Tracking from './tracking';
import { ReportStatus } from './types';
import style from './CoronaReportModal.scss';

interface Props {
  status: ReportStatus;
  onClose: () => void;
  actions: {
    resetCoronaReportState: () => void;
    downloadCoronaReportRequestAction: (props: { from: string; to: string }) => void;
  };
}

export const CoronaReportModal = (props: Props) => {
  const [startDate, setStartDate] = React.useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = React.useState<moment.Moment | null>(null);
  React.useEffect(() => {
    Tracking.trackOpen();
  }, []);
  const onChangeHandler = (newStartDate: moment.Moment, newEndDate: moment.Moment) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };
  const onClose = () => {
    props.actions.resetCoronaReportState();
    props.onClose();
  };
  const canSubmit = () => {
    if (props.status === ReportStatus.LOADING) {
      return false;
    }
    if (!(startDate && endDate)) {
      return false;
    }
    return true;
  };
  const buttons: DialogFooterButtonProps[] = [
    {
      title: Wahanda.lang.reports.sales.coronaReportModal.close,
      type: 'secondary',
      onClick: onClose,
    },
    {
      title: Wahanda.lang.reports.sales.coronaReportModal.downloadButton,
      type: 'default',
      dataTest: 'get-corona-report-submit',
      disabled: !canSubmit(),
      onClick: () => {
        if (!startDate || !endDate) {
          return;
        }
        Tracking.trackDownloadClick();
        props.actions.downloadCoronaReportRequestAction({
          from: startDate.formatApiDateString(),
          to: endDate.formatApiDateString(),
        });
      },
    },
  ];
  const renderContent = () => {
    if (props.status === ReportStatus.LOADING) {
      return (
        <div className={style.loader}>
          <Loader />
        </div>
      );
    }
    return (
      <>
        {props.status === ReportStatus.ERROR && (
          <div className={style.error}>
            <Notice
              type="alert"
              message={Wahanda.lang.reports.sales.coronaReportModal.errorMessage}
            />
          </div>
        )}
        <p>{Wahanda.lang.reports.sales.coronaReportModal.body}</p>
        <strong className={style.label}>
          {Wahanda.lang.reports.sales.coronaReportModal.label}
        </strong>
        <div className={style.datePicker}>
          <DatePicker
            uniqueId="CoronaReportModalDatePicker"
            labelStartDate={Wahanda.lang.reports.sales.coronaReportModal.from}
            labelEndDate={Wahanda.lang.reports.sales.coronaReportModal.to}
            onChange={onChangeHandler}
            initialStartDate={startDate}
            initialEndDate={endDate}
          />
        </div>
      </>
    );
  };
  return (
    <Dialog
      dataTest="corona-report-modal"
      keepTopPositionWhenResizing
      initialPosition="top"
      width="500"
      onClose={onClose}
      title={Wahanda.lang.reports.sales.coronaReportModal.title}
      noOverflow
    >
      <div className={style.container}>
        {renderContent()}
        <DialogFooter fullWidth buttons={buttons} />
      </div>
    </Dialog>
  );
};
