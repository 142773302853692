import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Chevron extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-chevron', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <polygon points="14.586 12 8.293 5.707 9.707 4.293 17.414 12 9.707 19.707 8.293 18.293" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Chevron);
