import { createAction } from 'reduxStore/actionCreator';

import {
  FBE_STATUS_CHECK_REQUEST,
  FBE_STATUS_CHECK_SUCCESS,
  FBE_STATUS_CHECK_FAILURE,
  FBE_INTEGRATION_ASSETS_REQUEST,
  FBE_INTEGRATION_ASSETS_SUCCESS,
  FBE_INTEGRATION_ASSETS_FAILURE,
} from 'reduxStore/actionsConst';
import { FBEInstallStatus } from './types';

export interface FBEStatusCheckPayload {
  status: FBEInstallStatus;
}

export interface FBEIntegrationAssetsPayload {
  instagramBusinessId?: string;
  pageId?: string;
}

export const statusCheckRequest = () => createAction(FBE_STATUS_CHECK_REQUEST);

export type StatusCheckRequestAction = ReturnType<typeof statusCheckRequest>;

export const statusCheckSuccess = (payload: FBEStatusCheckPayload) =>
  createAction(FBE_STATUS_CHECK_SUCCESS, payload);

export type StatusCheckSuccessAction = ReturnType<typeof statusCheckSuccess>;

export const statusCheckFailure = () => createAction(FBE_STATUS_CHECK_FAILURE);

export type StatusCheckFailureAction = ReturnType<typeof statusCheckFailure>;

export const integrationAssetsRequest = () => createAction(FBE_INTEGRATION_ASSETS_REQUEST);

export type IntegrationAssetsRequestAction = ReturnType<typeof integrationAssetsRequest>;

export const integrationAssetsSuccess = (payload: FBEIntegrationAssetsPayload) =>
  createAction(FBE_INTEGRATION_ASSETS_SUCCESS, payload);

export type IntegrationAssetsSuccessAction = ReturnType<typeof integrationAssetsSuccess>;

export const integrationAssetsFailure = () => createAction(FBE_INTEGRATION_ASSETS_FAILURE);

export type IntegrationAssetsFailureAction = ReturnType<typeof integrationAssetsFailure>;
