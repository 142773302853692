import React, { Component, Fragment } from 'react';
import { trackEvent } from 'common/analytics';
import moment from 'common/moment';
import LocalStorage from 'common/local-storage';
import Wahanda from 'common/wahanda';
import NotificationListHeader from '../NotificationListHeader';
import AppointmentNotification from '../AppointmentNotifications/AppointmentNotification';
import AppointmentNotificationRescheduled from '../AppointmentNotifications/AppointmentNotificationRescheduled';
import { TYPE_RESCHEDULED } from '../AppointmentNotifications/constants';
import style from './style.scss';

interface INotificationListProps extends React.HTMLAttributes<Element> {
  appointmentNotifications?: {
    id: number;
    employeeFullName?: string;
    notificationType: string;
    appointmentId: number;
    notificationDatetime: string;
    appointmentDatetime: string;
    appointmentDatetimeOld?: string;
    clientFullName?: string;
  }[];
  actions: {
    requestAppointmentNotificationsAction: (...args: any[]) => any;
    resetStateAction: (...args: any[]) => any;
    requestWebSocketsConnectAction: (...args: any[]) => any;
  };
  loading: boolean;
  onAppointmentClick: (...args: any[]) => any;
  onChange?: (...args: any[]) => any;
  visible?: boolean;
  resetStateAction?: any;
  requestAppointmentNotificationsAction?: any;
  requestWebSocketsConnectAction?: any;
}
type NotificationListState = {
  lastSeenDatetime?: any;
};

export class NotificationList extends Component<INotificationListProps, NotificationListState> {
  static defaultProps = {
    appointmentNotifications: [],
    onChange: () => {},
    visible: false,
  };

  state = {
    lastSeenDatetime: LocalStorage.getForVenue('notification-list-seen-datetime'),
  };

  componentDidMount() {
    this.props.actions.requestWebSocketsConnectAction({
      type: 'appointment-activity-event',
    });
    this.props.actions.requestAppointmentNotificationsAction();
  }

  componentDidUpdate(prevProps) {
    const { visible, onChange } = this.props;
    if (prevProps.visible !== visible) {
      if (visible) {
        trackEvent('notification-list', 'view');
      } else {
        this.setLastSeenDatetimeFromLacalStorage();
      }
    }
    if (visible) {
      const lastSeenDatetime = moment().toISOString();
      LocalStorage.setForVenue('notification-list-seen-datetime', lastSeenDatetime);
    }
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onChange(this.getUnseenNotificationsCount());
  }

  componentWillUnmount() {
    this.props.actions.resetStateAction();
  }

  onListItemClick = ({
    appointmentId,
    appointmentDatetime,
    appointmentVisibleOnCalendar,
  }) => () => {
    this.props.onAppointmentClick({
      appointmentId,
      appointmentDatetime,
      appointmentVisibleOnCalendar,
    });
  };

  setLastSeenDatetimeFromLacalStorage = () => {
    this.setState({
      lastSeenDatetime: LocalStorage.getForVenue('notification-list-seen-datetime'),
    });
  };

  getUnseenNotificationsCount = () => {
    const { lastSeenDatetime } = this.state;
    const { appointmentNotifications } = this.props;
    const isUnseen = (notification) =>
      lastSeenDatetime
        ? moment(lastSeenDatetime).isBefore(notification.notificationDatetime)
        : true;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return appointmentNotifications.filter(isUnseen).length;
  };

  renderList = () => {
    const { appointmentNotifications } = this.props;
    const getListItemProps = (notification) => {
      const {
        id: key,
        notificationType,
        employeeFullName,
        notificationDatetime,
        appointmentId,
        appointmentDatetime,
        appointmentDatetimeOld,
        isAppointmentDatetimeToday,
        isAppointmentDatetimeOldToday,
        clientFullName,
        appointmentVisibleOnCalendar,
        additionalFields = {},
      } = notification;
      return {
        key,
        type: notificationType,
        employeeFullName,
        notificationDatetime,
        appointmentId,
        appointmentDatetime,
        appointmentDatetimeOld,
        isAppointmentDatetimeToday,
        isAppointmentDatetimeOldToday,
        clientFullName,
        lastSeenDatetime: this.state.lastSeenDatetime,
        appointmentVisibleOnCalendar,
        additionalFields,
        onClick: this.onListItemClick(notification),
      };
    };
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return appointmentNotifications.map((notification) => {
      switch (notification.notificationType) {
        case TYPE_RESCHEDULED:
          return <AppointmentNotificationRescheduled {...getListItemProps(notification)} />;
        default:
          return <AppointmentNotification {...getListItemProps(notification)} />;
      }
    });
  };

  render() {
    const lang = Wahanda.lang.header.notifications.appointments;
    const { loading, visible } = this.props;
    const list = this.renderList();
    return (
      visible && (
        <Fragment>
          <NotificationListHeader text={lang.title} />
          <Fragment>
            {list.length > 0 && <div className={style.list}>{list}</div>}
            {!loading && list.length === 0 && (
              <div className={style.emptyListTitle}>{lang.noEvents}</div>
            )}
          </Fragment>
        </Fragment>
      )
    );
  }
}

export default NotificationList;
