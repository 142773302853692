import React from 'react';

import style from './style.scss';

interface Props {
  title: string;
  description?: string;
  children: React.ReactNode | React.ReactNodeArray;
}

class SettingsSection extends React.PureComponent<Props, {}> {
  public render() {
    const { title, description, children } = this.props;
    return (
      <div className={style.settingsSection}>
        <h1 className={style.header}>{title}</h1>
        {description && <p className={style.description}>{description}</p>}
        {children}
      </div>
    );
  }
}

export default SettingsSection;
