/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint func-names: 0 */
/* global BackboneEx */

import {
  addMissingCategories,
  getEmployees,
  getInvalidCategories,
} from 'components/menu/OfferTreatmentPricingAndEmployees/utils';
import { findEmployeeCategoryName } from 'components/menu/utils';
import {
  PRICING_TYPE_BY_EMPLOYEE_CAT,
  PRICING_TYPE_2D,
} from 'components/menu/OfferTreatmentPricingAndEmployees/constants';

import { store as discountRuleMenuRowStore } from 'src/initializers/DiscountRuleMenuRow';

(function () {
  const BACKGROUND_TO_PREVIEW_COLOURS_MAPPING = {
    ffffff: '#ffffff',
    ffe5e4: '#ffcbc9',
    ffecd1: '#ffd9a2',
    fff6cc: '#ffec98',
    e6f8e1: '#cdf0c2',
    def8f1: '#bdf1e2',
    e1f1ff: '#c2e3ff',
    e9eaff: '#d2d4ff',
    fae6fa: '#f5cdf5',
  };

  function toDropdownColours(colourMapping) {
    const keys = _.keys(colourMapping);
    const values = _.values(colourMapping);
    const lastIndex = values.length;

    return _.map(_.zip(keys, values, _.range(lastIndex)), (mapping) => ({
      calendarColour: mapping[0],
      dropdownColour: mapping[1],
      first: mapping[2] === 0,
      last: mapping[2] === lastIndex - 1,
    }));
  }

  const Base = BackboneEx.View.Base;

  function getSelectedDiscountRuleId() {
    const state = discountRuleMenuRowStore?.getState();
    const discountRule = state?.discountRule;
    return discountRule?.selectedDiscountRuleId;
  }

  App.Views.Forms.MenuOffer2.Pricing.ServiceDetails = Base.extend({
    templateId: 'menu-offer2-services-template',
    contentRendered: false,

    events: {
      'change .js-primaryTreatmentId': 'onGroupChange',
      'keyup .js-offer-name': 'onNameChange',
      'click #colour-dropdown-expanded .colour-preview': 'onColourPreviewExpandedClick',
      'click #colour-dropdown-expanded': 'onExpandedDropdownClick',
      'click #colour-dropdown-collapsed': 'onColourPreviewClick',
      'mouseenter #colour-dropdown-expanded': 'onExpandedDropdownMouseEnter',
      'mouseleave #colour-dropdown-expanded': 'onExpandedDropdownMouseLeave',
    },

    initialize: function () {
      this.formFieldPrefix = `offer2-${this.cid}-`;
      // eslint-disable-next-line prefer-rest-params
      this.constructor.__super__.initialize.apply(this, arguments);

      this.on('render', this.afterRender);
    },

    render: function () {
      if (!this.contentRendered) {
        this.$el.html(Wahanda.Template.renderTemplate(this.templateId, this.getTemplateData()));

        if (this.model.isTreatment()) {
          this.renderTreatmentTypeSelect();
          this.offerName = this.model.get('name');
          if (!this.model.isCustom()) {
            this.primaryTreatmentId = this.model.get('primaryTreatmentId');
          }
        }

        if (!this.model.isEscape()) {
          this.renderDiscountRuleSelect();
        }

        this.renderPatchTestRequired();

        const skus = this.model.get('skus') || [];
        if (
          [PRICING_TYPE_BY_EMPLOYEE_CAT, PRICING_TYPE_2D].includes(
            App.Models.MenuOffer.getPricingType(skus),
          )
        ) {
          const employees = getEmployees(this.model.get('employees'), this.options.employees);
          const employeeCategories = this.options.employeeCategories.toJSON();
          const invalidCategories = getInvalidCategories(skus, employees, employeeCategories);

          if (invalidCategories) {
            const invalidCategoryNames = invalidCategories
              .map((category) => category.name)
              .join(', ');
            this.invalidCategoriesWarningInstance = App.ES6.Initializers.Notice({
              node: this.$('#attention-missing-categories')[0],
              boldMessage: Wahanda.lang.menu.offer.sku.errors.skuCategoryMissingBold,
              message: Wahanda.Template.render(
                Wahanda.lang.menu.offer.sku.errors.skuCategoryMissing,
                { invalidCategoryNames },
              ),
            }).render();
          }

          const skusWithMissingCategories = addMissingCategories(
            skus,
            employees,
            employeeCategories,
          );
          if (skusWithMissingCategories.length !== skus.length) {
            const addedCategories = skusWithMissingCategories
              .filter((sku) => !sku.id)
              .map((sku) => findEmployeeCategoryName(employeeCategories, sku.employeeCategoryId))
              .join(', ');

            this.additionalSkusWarningInstance = App.ES6.Initializers.Notice({
              node: this.$('#attention-added-categories')[0],
              boldMessage: Wahanda.lang.menu.offer.sku.errors.skuCategoryAddedBold,
              message: Wahanda.Template.render(
                Wahanda.lang.menu.offer.sku.errors.skuCategoryAdded,
                { addedCategories },
              ),
            }).render();
          }
        }

        this.fillSelects();

        this.$field('stayNights').add(this.$field('occupancyGuests')).selectWrap();

        if (
          this.model.isLegacyPackage() ||
          this.model.isTreatment() ||
          this.model.isServicePackage()
        ) {
          this.setActiveServiceColour(this.model.get('connectDisplayColour'));
        } else {
          this.$('.colour-dropdown').css({ display: 'none' });
          this.$('.js-package-title-group').addClass('remove-padding-right');
          this.$('.with-colour-selector').removeClass('with-colour-selector');
        }

        this.trigger('render');
        this.contentRendered = true;
      }

      if (this.model.id) {
        this.fillFormFromModel();
        this.$field('stayNights').add(this.$field('occupancyGuests')).selectWrap('updateText');
      }
    },

    getTemplateData: function () {
      return {
        name: this.model.get('name'),
        prefix: this.formFieldPrefix,
        treatmentType: this.model.isTreatment(),
        discountRule: !this.model.isEscape(),
        supplierReference: this.model.isEscape(),
        numberOfGuests: this.model.isEscape(),
        numberOfNights: this.model.isSpaBreak(),
        colours: toDropdownColours(BACKGROUND_TO_PREVIEW_COLOURS_MAPPING),
        disabled: this.model.isReadOnly(),
      };
    },

    renderTreatmentTypeSelect: function () {
      const view = new App.Views.Forms.MenuOffer2.Pricing.TreatmentTypeSelect({
        el: this.$('.js-treatment-type-container'),
        treatments: this.options.treatments,
        model: this.model,
        prefix: this.formFieldPrefix,
        mediator: this.options.mediator,
      });
      view.render();
    },

    renderPatchTestRequired: function () {
      const isCreatingService = this.model.get('isCreatingService');
      let requiresPatchTest = this.model.get('requiresPatchTest');
      if (!this.model.isTreatment()) {
        return;
      }
      const patchTestExists = this.patchTestExists();

      if (!App.isFeatureSupported('patch-tests') || !patchTestExists) {
        return;
      }

      this.model.setPatchTestExists(patchTestExists);

      const togglePatchTestRequired = () => {
        this.model.togglePatchTestRequired();
      };

      if (isCreatingService) {
        togglePatchTestRequired();
        requiresPatchTest = true;
      }

      App.ES6.Initializers.PatchTestRequired({
        node: $('#patch-test-required')[0],
        requiresPatchTest,
        togglePatchTestRequired,
      }).render();
    },

    patchTestExists: function () {
      const selectedTreatment = this.options.treatments
        .get('treatmentsCollection')
        .find((treatment) => treatment.get('id') === parseInt(this.primaryTreatmentId, 10));
      return selectedTreatment ? selectedTreatment.get('requiresPatchTest') : false;
    },

    renderDiscountRuleSelect: function () {
      const initialDiscountRule = [
        {
          id: this.model.get('discountRuleId'),
          name: this.model.get('discountRuleName'),
          active: this.model.get('discountRuleActive'),
        },
      ];

      this.reactInstance = App.ES6.Initializers.renderDiscountRuleMenuRow(
        $('#discount-rule-row')[0],
        App.getVenueId(),
        this.model.get('discountRuleId'),
        initialDiscountRule,
        this.options.hasDialogChanged,
      );
    },

    fillSelects: function () {
      this.$field('stayNights')
        .append(generateOptions(14))
        .val(this.model.get('stayNights') || 1);

      this.$field('occupancyGuests')
        .append(generateOptions(30))
        .val(this.model.get('occupancyGuests') || 1);

      function generateOptions(max) {
        let options = '';
        for (let i = 1; i <= max; i++) {
          options += `<option>${i}</option>`;
        }
        return options;
      }
    },

    getValues: function () {
      const backboneValues = BackboneEx.Mixin.View.Form.implementation.getValues.call(
        this,
        /* onlyWithPrefix = */ true,
      );
      const primaryTreatmentIdChanged =
        this.model.get('primaryTreatmentId') !== parseInt(backboneValues.primaryTreatmentId, 10);
      const treatmentIds = this.model.get('treatmentIds') || [
        parseInt(backboneValues.primaryTreatmentId, 10),
      ];

      if (primaryTreatmentIdChanged && treatmentIds) {
        const treatmentIndex = treatmentIds.indexOf(this.model.get('primaryTreatmentId'));
        treatmentIds[treatmentIndex] = parseInt(backboneValues.primaryTreatmentId, 10);
      }

      const discountRuleId = getSelectedDiscountRuleId();
      return _.extend(backboneValues, {
        discountRuleId,
        treatmentIds,
      });
    },

    setActiveServiceColour: function (colour) {
      if (typeof colour === 'string' && colour.length === 6) {
        const previewColour = BACKGROUND_TO_PREVIEW_COLOURS_MAPPING[colour];

        if (previewColour) {
          this.$('#colour-dropdown-collapsed .colour-preview').css({
            'background-color': previewColour,
          });

          const expandedPreviews = this.$('#colour-dropdown-expanded .colour-preview');

          // remove existing selected attribute
          expandedPreviews.removeClass('selected-colour');

          // set new selected attribute
          this.$(expandedPreviews).filter(`[data-colour='${colour}']`).addClass('selected-colour');

          this.$field('connectDisplayColour').val(colour);
        }
      }
    },

    onGroupChange: function () {
      const was = this.primaryTreatmentId;
      const now = this.$('.js-primaryTreatmentId').val();

      const { treatments, mediator } = this.options;

      const treatment = treatments.getTreatment(now);

      mediator.trigger(mediator.MAIN_TREATMENT_CHANGED, treatment);

      const state = { now, was };

      App.trigger(Wahanda.Event.MENU_OFFER_TREATMENT_CHANGED, this, {
        state,
      });

      this.primaryTreatmentId = now;

      const patchTestExists = this.patchTestExists();

      // Check if patch test checkbox is still needed
      if (!patchTestExists) {
        this.model.setPatchTestExists(patchTestExists);
        App.ES6.Initializers.PatchTestRequired({
          node: $('#patch-test-required')[0],
        }).destroy();
        return;
      }
      this.renderPatchTestRequired();
    },

    onNameChange: function () {
      const was = this.offerName;
      const now = this.$('.js-offer-name').val();

      const state = {
        ...(was ? { was } : {}),
        ...(now ? { now } : {}),
      };

      App.trigger(Wahanda.Event.MENU_OFFER_NAME_CHANGED, this, {
        state,
      });

      this.offerName = now;
    },

    onExpandedDropdownClick: function () {
      this.disableBodyListener();
      this.$('#colour-dropdown-collapsed').removeClass('hidden');
      this.$('#colour-dropdown-expanded').addClass('hidden');
    },

    onColourPreviewExpandedClick: function (evt) {
      if ($(evt.target).hasClass('arrow')) {
        return;
      }
      const colour = this.$(evt.target).attr('data-colour');
      this.setActiveServiceColour(colour);
      this.onExpandedDropdownClick();
    },

    onColourPreviewClick: function () {
      this.enableBodyListener();
      this.$('#colour-dropdown-expanded').removeClass('hidden');
      this.$('#colour-dropdown-collapsed').addClass('hidden');
    },

    onExpandedDropdownMouseEnter: function () {
      this.$('#colour-dropdown-expanded').addClass('hover');
    },

    onExpandedDropdownMouseLeave: function () {
      this.$('#colour-dropdown-expanded').removeClass('hover');
    },

    enableBodyListener: function () {
      $('body').on('click', this.closeDropdown.bind(this));
    },

    disableBodyListener: function () {
      $('body').off('click', this.closeDropdown.bind(this));
    },

    closeDropdown: function (evt) {
      if (!$(evt.target).hasClass('colour-dropdown')) {
        this.onExpandedDropdownClick();
      }
    },
  });

  BackboneEx.Mixin.View.Form.mixin(App.Views.Forms.MenuOffer2.Pricing.ServiceDetails);
})();
