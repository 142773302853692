/* global jQuery */
import Wahanda from 'common/wahanda';
import styles from '../UserAutocomplete.scss';

const itemTemplate = `
<li class="${styles.item}">
  <a class="${styles.link}">
    <div class="${styles.name}">
      {{#prepaymentRequired}}<span class="${styles.prepayIcon}"></span>{{/prepaymentRequired}}
      {{name}}
    </div>
    {{#phone}}<div class="${styles.phone}">{{phone}}</div>{{/phone}}
    {{#emailAddress}}<div class="${styles.email}">{{emailAddress}}</div>{{/emailAddress}}
  </a>
</li>
`;

export default function renderMenu(ul, items) {
  ul.addClass(styles.menu);
  ul.attr('data-test', 'udv-client-autocomplete');
  const lines = items.map((item) => {
    const elem = jQuery(Wahanda.Template.render(itemTemplate, item));
    elem.data('ui-autocomplete-item', item);
    return elem;
  });

  ul.append(lines);
}
