import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class MoneyWithdraw extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-money-withdraw', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66667 8.66667C3.82998 8.66667 4.81931 9.41157 5.1837 10.4504C5.60764 10.1658 6.11779 10 6.66667 10C8.13943 10 9.33333 11.1939 9.33333 12.6667C9.33333 14.1394 8.13943 15.3333 6.66667 15.3333C5.50335 15.3333 4.51402 14.5884 4.14963 13.5496C3.72569 13.8342 3.21555 14 2.66667 14C1.19391 14 0 12.8061 0 11.3333C0 9.86057 1.19391 8.66667 2.66667 8.66667ZM6.66667 11.3333C5.93029 11.3333 5.33333 11.9303 5.33333 12.6667C5.33333 13.403 5.93029 14 6.66667 14C7.40305 14 8 13.403 8 12.6667C8 11.9303 7.40305 11.3333 6.66667 11.3333ZM2.66667 10C1.93029 10 1.33333 10.597 1.33333 11.3333C1.33333 12.0697 1.93029 12.6667 2.66667 12.6667C3.40305 12.6667 4 12.0697 4 11.3333C4 10.597 3.40305 10 2.66667 10ZM14.0044 2C14.7389 2 15.3333 2.60277 15.3333 3.33498V9.99835C15.3333 10.7347 14.7392 11.3333 14.0029 11.3333H10V10H10.3893L14 6.38933L13.9999 3.33418L6.94267 3.33333L3.33333 6.94267V7.33333H2V3.33529C2 2.59927 2.59365 2 3.32893 2H14.0044ZM14 8.276L12.276 10H14.0005L14 8.276ZM8.66667 4C9.84173 4 10.6667 5.23741 10.6667 6.66667C10.6667 8.09593 9.84173 9.33333 8.66667 9.33333C7.4916 9.33333 6.66667 8.09593 6.66667 6.66667C6.66667 5.23741 7.4916 4 8.66667 4ZM8.66667 5.33333C8.36897 5.33333 8 5.88679 8 6.66667C8 7.44655 8.36897 8 8.66667 8C8.96436 8 9.33333 7.44655 9.33333 6.66667C9.33333 5.88679 8.96436 5.33333 8.66667 5.33333ZM5.056 3.33333H3.33229L3.33267 5.05667L5.056 3.33333Z"
          fill="#1A1A1A"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(MoneyWithdraw);
