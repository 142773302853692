import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { requestPosStatusAction, resetStateAction } from 'reduxStore/common/posStatus/actions';
import {
  startPOSOperationRequest,
  abortPOSOperationRequestStart,
} from 'reduxStore/common/posOperation/actions';
import { dailyReportRequest } from 'reduxStore/common/dailyReport/actions';
import { TransactionDialog as Component } from './index';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      dailyReportRequest,
      requestPosStatusAction,
      resetStateAction,
      startPOSOperationRequest,
      abortPOSOperationRequestStart,
    },
    dispatch,
  ),
});

const mapStateToProps = ({ posStatus, posOperation }) => ({
  currentDrawerTotal: posStatus.cashBalance,
  operationId: posOperation.operationId,
});

export const TransactionDialog = connect(mapStateToProps, mapDispatchToProps)(Component);
