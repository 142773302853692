import React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import App from 'common/backbone-app';
import { stripeInstanceSelector } from '../selectors';
import { stripePaymentFailed, doStripePaymentRequest, doIdealPaymentRequest } from '../actions';
import { withStripe as withStripeHoC, InjectedProps } from './withStripe';

const mapStateToProps = (state) => {
  return {
    stripe: stripeInstanceSelector(state, {
      locale: App.getClientLocale(),
    }),
    stripePublicApiKey: state.stripe.stripePublicApiKey,
    error: state.stripe.error,
    success: state.stripe.success,
    supplierId: App.config.get('venue').supplierId,
    paymentInProgress: state.stripe.paymentInProgress,
    idealPaymentInProgress: state.stripe.idealPaymentInProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { doIdealPaymentRequest, doStripePaymentRequest, stripePaymentFailed },
    dispatch,
  ),
});

type hocType = <T>(
  c: React.ComponentType<T>,
) => React.ComponentType<Pick<T, Exclude<keyof T, keyof InjectedProps>>>;

export const withStripe = (compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStripeHoC,
) as unknown) as hocType;
