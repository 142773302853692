/* global BackboneEx _ */
App.Views.Forms.MenuOffer2.Pricing.Dated.Edit.Rooms = BackboneEx.View.DynamicForm.extend({
  events: {
    'change .a-toggle': 'toggleCheckbox',
  },

  fieldPrefix: 'offer-pricing-',

  initialize: function () {
    this.roomsToRender = this.options.rooms;
  },

  getTemplateVars: function () {
    var currency = App.config.get('currency').symbol;
    var vars = {
      rooms: [],
      suffix: this._getUniqueSuffix(),
      hasRooms: this.options.roomTypes.length > 0,
      priceTitle: Wahanda.lang.menu.offer.pricing.price.replace('{{currency}}', currency),
      rrpTitle: Wahanda.lang.menu.offer.pricing.rrp.replace('{{currency}}', currency),
    };
    this.options.roomTypes.each(function (rType) {
      vars.rooms.push({
        id: rType.id,
        roomName: rType.get('name'),
      });
    });
    return vars;
  },

  $$: function (fieldName, roomTypeId) {
    var id = '#' + this.fieldPrefix + fieldName + '-' + roomTypeId + this._getUniqueSuffix();
    return this.$(id);
  },

  render: function () {
    // Setting HTML structure
    var $html = $(
      Wahanda.Template.renderTemplate('menu-offer-pricing-edit-rooms', this.getTemplateVars()),
    );
    this.$el.replaceWith($html);
    this.setElement($html);
    // Setting content
    this.fillValues(this.roomsToRender);
    this.disableSubmitIfnothingChecked();
  },

  fillValues: function (rooms) {
    var self = this;
    if (rooms) {
      // Some rooms are passed in
      _.each(rooms || [], function (room) {
        var roomId = room.roomTypeId;
        try {
          self
            .$$('enabled', roomId)
            .prop('checked', true)
            // Parent list element
            .closest('li')
            .removeClass('off')
            .addClass('on')
            .data('pricingId', room.pricingRuleId);
          self
            .$$('fullPriceAmount', roomId)
            .val(Wahanda.Number.formatFloatForInput(room.fullPriceAmount));
          self
            .$$('discountPriceAmount', roomId)
            .val(Wahanda.Number.formatFloatForInput(room.discountPriceAmount));
        } catch (e) {
          console.log(e);
        }
      });
    } else {
      // No rooms are passed in. Check all as enabled.
      this.$('li').removeClass('off').addClass('on');
      this.$('.a-toggle').prop('checked', true);
    }
  },

  getValues: function () {
    var self = this;
    var ret = {
      rooms: [],
    };

    var $enabledItems = this.$('li.on');
    $enabledItems.each(function () {
      var $li = $(this);
      var roomTypeId = $li.data('id');
      ret.rooms.push({
        pricingRuleId: $li.data('pricingId') || null,
        roomTypeId: roomTypeId,
        fullPriceAmount: parseFloat(self.$$('fullPriceAmount', roomTypeId).val()),
        discountPriceAmount: parseFloat(self.$$('discountPriceAmount', roomTypeId).val()) || null,
      });
    });

    return ret;
  },

  disableSubmitIfnothingChecked: function () {
    if (this.options.canSaveToggleCallback) {
      var someChecked = this.$('.a-toggle:checked').length > 0;
      this.options.canSaveToggleCallback(someChecked);
    }
  },

  toggleCheckbox: function (event) {
    var $cBox = $(event.currentTarget);
    var $li = $cBox.closest('li');
    var checked = $cBox.prop('checked');

    $li.toggleClass('on', checked).toggleClass('off', !checked);
    if (checked) {
      $li.find('input[type=text]:first').focus();
    }

    this.disableSubmitIfnothingChecked();
  },

  isValid: function () {
    return this.$('li.on').length > 0;
  },
});
