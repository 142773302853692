import { createSelector } from 'reselect';
import ROLES from 'common/constants/userRoles';
import { PERMISSIONS, POINT_OF_SALE_PERMISSIONS } from 'common/constants/userPermissions';

const filterConfigurablePermissions = ({ permissions, pointOfSalePermissionsVisible }) => {
  const allConfigurablePermissions = Object.values(PERMISSIONS);
  const pointOfSaleConfigurablePermissions = Object.values(POINT_OF_SALE_PERMISSIONS);

  return permissions.filter(
    (p) =>
      allConfigurablePermissions.includes(p) &&
      (pointOfSalePermissionsVisible || !pointOfSaleConfigurablePermissions.includes(p)),
  );
};

export const getEmployee = (state) => state.employees.employee;

export const getConfigurablePermissionsByRoles = createSelector(
  (state: any) => state.employees.permissionsByRoles,
  (_, { pointOfSalePermissionsVisible }) => ({
    pointOfSalePermissionsVisible,
  }),
  (permissionsByRoles, { pointOfSalePermissionsVisible }) =>
    permissionsByRoles
      .filter((i) => Object.values(ROLES).includes(i.role))
      .map((rolePermissions) => {
        const configurableRolePermissions = filterConfigurablePermissions({
          permissions: rolePermissions.permissions,
          pointOfSalePermissionsVisible,
        });

        return {
          role: rolePermissions.role,
          permissions: configurableRolePermissions,
        };
      }),
);

export const getConfigurableEmployeePermissions = createSelector(
  (state: any) => state.employees.employee.permissions,
  (_, { pointOfSalePermissionsVisible }) => ({
    pointOfSalePermissionsVisible,
  }),
  (permissions, { pointOfSalePermissionsVisible }) =>
    filterConfigurablePermissions({
      permissions,
      pointOfSalePermissionsVisible,
    }),
);
