/**
 * Model that can set values silently.
 */
BackboneEx.Model.Silent = BackboneEx.Model.Base.extend({
	/**
	 * Set model`s value.
	 * If options.silent is not a boolean, it will be set to true.
	 * 
	 * @see Backbone.Model.prototype.set
	 */
	set: function() {
		var params = Array.prototype.slice.call(arguments);
		// Handle both `("key", value)` and `({key: value})` -style calls.
		var optionsId = 2;
		if (_.isObject(params[0]) || params[0] == null) {
			optionsId = 1;
		} 
		params[optionsId] = params[optionsId] ? _.clone(params[optionsId]) : {};
		
		if (typeof params[optionsId].silent != "boolean") {
			params[optionsId].silent = true;
		}
		return BackboneEx.Model.Base.prototype.set.apply(this, params);
	},
	
	/**
	 * Triggers change() after save, so no pending attributes are left.
	 * 
	 * @see Backbone.Model.prototype.save
	 */
	save: function() {
		var self = this;
		var xhr  = BackboneEx.Model.Base.prototype.save.apply(this, arguments);
		xhr.done(function() {
			self.change();
		});
		return xhr;
	}
});
