import React from 'react';
import classnames from 'classnames';

import style from './LayoutContainer.scss';

interface Props {
  children: React.ReactNode;
  fullWidth?: boolean;
  footer?: React.ReactNode;
  scrollable?: boolean;
}

export const LayoutContainer = ({ fullWidth, footer, scrollable = true, children }: Props) => {
  const classes = classnames(style.wrapper, {
    [style.fullWidth]: fullWidth,
    [style.scrollable]: scrollable,
  });
  return (
    <div className={style.container}>
      <div className={classes}>
        <div className={style.inner}>{children}</div>
      </div>
      {footer && <div className={style.footer}>{footer}</div>}
    </div>
  );
};

LayoutContainer.displayName = 'LayoutContainer';
