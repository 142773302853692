import { trackEvent, ACTIONS } from 'common/analytics';

const CATEGORY = 'review';

export const ReviewsAnalytics = {
  trackReviewClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'details');
  },
  trackZeroToFiveRequestForReviewClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'request-for-review', 'from-zero-to-five');
  },
  trackEmptyStateRequestForReviewClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'request-for-review', 'from-empty-state');
  },
  trackZeroToFiveClientsImportClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'clients-import', 'from-zero-to-five');
  },

  trackZeroToFiveFbeClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'facebook-widget', 'from-zero-to-five');
  },

  trackRankingBoostHelpClick: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'ranking-boost-learn-more');
  },

  trackInfoIconClicked: () => {
    trackEvent(CATEGORY, ACTIONS.CLICK, 'simple-ways-to-get-reviews');
  },
};
