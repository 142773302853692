Wahanda.Comparators = {
  /**
   * Custructs a alphanumeric comparator.
   *
   * @param String attributeToCompare The attributeto compare models with
   * @return function
   */
  alphanumeric: function (attributeToCompare) {
    return function (a, b) {
      var aAttr = a.get(attributeToCompare);
      var bAttr = b.get(attributeToCompare);
      return String(aAttr).localeCompare(String(bAttr));
    };
  },

  /**
   * Custructs a numeric comparator.
   *
   * @param String attributeToCompare The attributeto compare models with
   * @return function
   */
  numeric: function (attributeToCompare) {
    return function (a, b) {
      var aAttr = a.get(attributeToCompare) || 0;
      var bAttr = b.get(attributeToCompare) || 0;
      return bAttr - aAttr;
    };
  },
};
