import { on, reducer } from 'ts-action';
import { setAppSettings } from 'reduxStore/common/appSettings/actions';
import { AppSettings } from 'reduxStore/common/appSettings/types';

const initialState: AppSettings = {
  isWebview: false,
};

export const appSettingsReducer = reducer(
  initialState,
  on(setAppSettings, (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  }),
);
