import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { InlineClient as Container } from './';
import { suggestionMessageSelector, showGDPRMessageSelector } from './store/selectors';
import * as actions from './store/actions';

const mapStateToProps = (state, ownProps) => ({
  isRepeat: state.calendar.inlineClientForm.isRepeat,
  isReadOnly: state.calendar.inlineClientForm.isReadOnlyMode,
  dataSource: ownProps.dataSource,
  id: state.calendar.inlineClientForm.id,
  showClientInfo: ownProps.showClientInfo,
  toggleIgnoreKeyboardEvents: ownProps.toggleIgnoreKeyboardEvents,
  messageForUser: suggestionMessageSelector(state.calendar.inlineClientForm),
  renderCallback: ownProps.renderCallback,
  showGDPR: showGDPRMessageSelector(state.calendar.inlineClientForm),
  sendMassEmails: state.calendar.inlineClientForm.sendMassEmails,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    ...bindActionCreators(actions, dispatch),
    ...ownProps.actions,
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true });

export const InlineClient = connector(Container);
