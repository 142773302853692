export default function updateReviewResponse(reviews, id, response = null) {
  return reviews.map((review) => {
    if (review.id !== id) {
      return review;
    }

    return Object.assign({}, review, {
      response,
    });
  });
}
