import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';
import BigSelectDropdown from 'components/reports/BigSelectDropdown';
import { GoTo, Direction } from 'components/common/GoTo';
import VenueInfoForPrint from 'components/reports/POSReports/VenueInfoForPrint/container';
import Wahanda from 'common/wahanda';
import { SalesReportsAnalytics, enableRecording } from 'common/analytics';
import { PrintButton } from 'components/shared/PrintButton';

import style from './style.scss';
import SalesReport from '../SalesReport/container';
import ProductSalesReport from '../ProductSalesReport/container';
import EmployeeSalesReport from '../EmployeeSalesReport/container';
import ChannelSalesReport from '../ChannelSalesReport/container';
import MonthlySalesReport, {
  PRESET_RANGES as MONTHLY_SALES_PRESET_RANGES,
} from '../MonthlySalesReport/container';
import ServiceSalesReport from '../ServiceSalesReport/container';
import DailySalesReport from '../DailySalesReport/container';
import REPORT_TYPE from '../common/reportTypes';
import EmployeeSalesReportCsvDownloadLink from '../EmployeeSalesReport/csvDownloadLink';
import ProductSalesReportCsvDownloadLink from '../ProductSalesReport/csvDownloadLink';
import SalesOverviewReportCsvDownloadLink from '../SalesReport/csvDownloadLink';
import ServiceSalesReportCsvDownloadLink from '../ServiceSalesReport/csvDownloadLink';
import ChannelSalesReportCsvDownloadLink from '../ChannelSalesReport/csvDownloadLink';
import DailySalesReportCsvDownloadLink from '../DailySalesReport/csvDownloadLink';
import MonthlySalesReportCsvDownloadLink from '../MonthlySalesReport/csvDownloadLink';
import ROUTES from './reportRoutes';
import { DEFAULT_DATE_PICKER_PRESETS } from 'components/common/DatePicker/DatePickerWithPresets';

interface ISalesReportContainerProps extends React.HTMLAttributes<Element> {
  match: {
    params?: {
      venueId: string;
      fromDate?: string;
      toDate?: string;
      report: string;
    };
  };
  history: {
    push: (...args: any[]) => any;
  };
  report?: any;
  params?: any;
}
class SalesReportContainer extends React.Component<ISalesReportContainerProps, {}> {
  constructor(props) {
    super(props);
    this.displayReport = this.displayReport.bind(this);
    this.getCsvDownloadLink = this.getCsvDownloadLink.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  componentDidMount() {
    enableRecording();
  }

  onSelectChange(selected) {
    SalesReportsAnalytics.trackChangeReportDropdownSelected(selected);
    const {
      history,
      match: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'venueId' does not exist on type '{ venue... Remove this comment to see the full error message
        params: { venueId, fromDate, toDate, report },
      },
    } = this.props;
    const toPath = pathToRegexp.compile('/venue/:venueId/salesReports/:report/:fromDate?/:toDate?');
    if (selected === REPORT_TYPE.MONTHLY_SALES) {
      const thisYearPreset = MONTHLY_SALES_PRESET_RANGES.lastTwelveMonths.getDates();
      history.push(
        toPath({
          venueId,
          report: selected,
          fromDate: thisYearPreset.startDate.formatApiDateString(),
          toDate: thisYearPreset.endDate.formatApiDateString(),
        }),
      );
      return;
    }
    if (report === REPORT_TYPE.MONTHLY_SALES && selected !== REPORT_TYPE.MONTHLY_SALES) {
      const thisMonthPreset = DEFAULT_DATE_PICKER_PRESETS.thisMonth.getDates();
      history.push(
        toPath({
          venueId,
          report: selected,
          fromDate: thisMonthPreset.startDate.formatApiDateString(),
          toDate: thisMonthPreset.endDate.formatApiDateString(),
        }),
      );
      return;
    }
    history.push(
      toPath({
        venueId,
        fromDate,
        toDate,
        report: selected,
      }),
    );
  }

  get selectedReport() {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return this.props.match.params.report;
  }

  getCsvDownloadLink() {
    return (
      <Switch>
        <Route exact path={ROUTES.SALES_REPORT} component={SalesOverviewReportCsvDownloadLink} />
        <Route
          exact
          path={ROUTES.SERVICE_SALES_REPORT}
          component={ServiceSalesReportCsvDownloadLink}
        />
        <Route
          exact
          path={ROUTES.PRODUCT_SALES_REPORT}
          component={ProductSalesReportCsvDownloadLink}
        />
        <Route exact path={ROUTES.EMPLOYEE_SALES} component={EmployeeSalesReportCsvDownloadLink} />
        <Route exact path={ROUTES.CHANNEL_SALES} component={ChannelSalesReportCsvDownloadLink} />
        <Route exact path={ROUTES.DAILY_SALES} component={DailySalesReportCsvDownloadLink} />
        <Route
          exact
          path={ROUTES.MONTHLY_SALES_REPORTS}
          component={MonthlySalesReportCsvDownloadLink}
        />
      </Switch>
    );
  }

  displayReport() {
    return (
      <Switch>
        <Route exact path={ROUTES.SALES_REPORT} component={SalesReport} />
        <Route exact path={ROUTES.SERVICE_SALES_REPORT} component={ServiceSalesReport} />
        <Route exact path={ROUTES.PRODUCT_SALES_REPORT} component={ProductSalesReport} />
        <Route exact path={ROUTES.EMPLOYEE_SALES} component={EmployeeSalesReport} />
        <Route exact path={ROUTES.CHANNEL_SALES} component={ChannelSalesReport} />
        <Route exact path={ROUTES.DAILY_SALES} component={DailySalesReport} />
        <Route exact path={ROUTES.MONTHLY_SALES_REPORTS} component={MonthlySalesReport} />
        <Redirect to="/" />
      </Switch>
    );
  }

  render() {
    const options = [
      {
        name: Wahanda.lang.reports.sales.reportType.salesReport,
        value: REPORT_TYPE.SALES_OVERVIEW,
      },
      {
        name: Wahanda.lang.reports.sales.reportType.serviceSalesReport,
        value: REPORT_TYPE.SERVICE_SALES,
      },
      {
        name: Wahanda.lang.reports.sales.reportType.productSalesReport,
        value: REPORT_TYPE.PRODUCT_SALES,
      },
      {
        name: Wahanda.lang.reports.sales.reportType.employeeSalesReport,
        value: REPORT_TYPE.EMPLOYEE_SALES,
      },
      {
        name: Wahanda.lang.reports.sales.reportType.channelSalesReport,
        value: REPORT_TYPE.CHANNEL_SALES,
      },
      {
        name: Wahanda.lang.reports.sales.reportType.dailySalesReport,
        value: REPORT_TYPE.DAILY_SALES,
      },
      {
        name: Wahanda.lang.reports.sales.reportType.monthlySalesReport,
        value: REPORT_TYPE.MONTHLY_SALES,
      },
    ];
    return (
      <div className={style.reportContainer}>
        <GoTo className={style.backLink} href="/" direction={Direction.backward}>
          {Wahanda.lang.reports.tabs.reports}
        </GoTo>
        <VenueInfoForPrint />
        <div className={style.selectorRow}>
          <div className={style.reportSelector}>
            <BigSelectDropdown
              data={options}
              selected={this.selectedReport}
              onSelect={this.onSelectChange}
              onOpen={SalesReportsAnalytics.trackChangeReportDropdownClick}
            />
          </div>
          <div className={style.userActionButtons}>
            <PrintButton />
            {this.getCsvDownloadLink()}
          </div>
        </div>

        <div className={style.reportBody}>{this.displayReport()}</div>
      </div>
    );
  }
}

export default SalesReportContainer;
