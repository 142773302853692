import { ActionUnionTypes } from 'reduxStore/actionCreator';
import {
  FBE_STATUS_CHECK_REQUEST,
  FBE_STATUS_CHECK_SUCCESS,
  FBE_UNINSTALL_REQUEST,
  FBE_UNINSTALL_SUCCESS,
  FBE_BUSINESS_CONFIG_SUCCESS,
  FBE_INTEGRATION_COMPLETE,
  FBE_INSTALLATION_SUCCESS,
  FBE_INTEGRATION_ASSETS_SUCCESS,
  FBE_INTEGRATION_ASSETS_REQUEST,
  FBE_REINTEGRATION_COMPLETE,
} from 'reduxStore/actionsConst';
import { FBEInstallStatus } from './types';

import * as fbeStatusCheckActions from './actions';
import * as fbeInstalledActions from './Installed/actions';
import * as fbeNotInstalledActions from './NotInstalled/actions';

export interface FBEState {
  status: FBEInstallStatus;
  integrationAssets?: fbeStatusCheckActions.FBEIntegrationAssetsPayload;
  integrationAssetsLoading: boolean;
  businessConfig?: {
    business: {
      name: string;
    };
    ig_cta: {
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
    };
    messenger_menu: {
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
    };
    page_cta: {
      below_button_text: string;
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
    };
    page_post: {
      cta_button_text: string;
      cta_button_url: string;
      enabled: boolean;
      subtitle: string;
      title: string;
    };
  };
}

export const initialState: FBEState = {
  status: FBEInstallStatus.PENDING,
  integrationAssetsLoading: false,
};

export const installStatus = (
  state = initialState,
  action:
    | ActionUnionTypes<typeof fbeInstalledActions>
    | ActionUnionTypes<typeof fbeNotInstalledActions>
    | ActionUnionTypes<typeof fbeStatusCheckActions>,
): FBEState => {
  switch (action.type) {
    case FBE_INTEGRATION_COMPLETE:
      return {
        ...state,
        status: FBEInstallStatus.INSTALLING,
      };
    case FBE_REINTEGRATION_COMPLETE:
      return {
        ...state,
        status: FBEInstallStatus.INSTALLING,
      };
    case FBE_STATUS_CHECK_REQUEST:
      return {
        ...state,
        status: FBEInstallStatus.PENDING,
      };
    case FBE_UNINSTALL_REQUEST:
      return {
        ...state,
        status: FBEInstallStatus.UNINSTALLING,
      };
    case FBE_INSTALLATION_SUCCESS:
      return {
        ...state,
        status: FBEInstallStatus.INSTALLED,
      };
    case FBE_UNINSTALL_SUCCESS:
      return {
        ...state,
        status: FBEInstallStatus.NOT_INSTALLED,
        integrationAssets: undefined,
      };
    case FBE_STATUS_CHECK_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
      };
    case FBE_BUSINESS_CONFIG_SUCCESS:
      return {
        ...state,
        businessConfig: action.payload.businessConfig,
      };
    case FBE_INTEGRATION_ASSETS_REQUEST:
      return {
        ...state,
        integrationAssetsLoading: true,
      };
    case FBE_INTEGRATION_ASSETS_SUCCESS:
      return {
        ...state,
        integrationAssetsLoading: false,
        integrationAssets: action.payload,
      };
    default:
      return state;
  }
};
