import React, { PureComponent } from 'react';
import { StateCard } from 'components/common/StateCard';
import Loader from 'components/common/Loader';
import { Button } from 'components/common/Button';
import { LayoutHeader, LayoutContainer } from 'components/common/Layout';

interface TabContentProps {
  header: string;
  addNewButtonText: string;
  onAddNewButtonClick: (...args: any[]) => any;
  listElement: JSX.Element;
  loading?: boolean;
  itemsCount?: number;
  emptyStateHeading: string;
  emptyStateMessage: string;
  emptyStateButtonText: string;
  emptyStateButtonOnClick: (...args: any[]) => any;
  emptyStateImage: JSX.Element;
}

export class TabContent extends PureComponent<TabContentProps, {}> {
  static defaultProps = {
    itemsCount: 0,
    loading: false,
  };

  renderEmptyList = () => {
    const {
      emptyStateHeading,
      emptyStateMessage,
      emptyStateButtonText,
      emptyStateButtonOnClick,
      emptyStateImage,
    } = this.props;
    return (
      <StateCard
        image={emptyStateImage}
        heading={emptyStateHeading}
        message={emptyStateMessage}
        button={{
          text: emptyStateButtonText,
          onClick: emptyStateButtonOnClick,
        }}
      />
    );
  };

  renderContent = () => {
    const { header, addNewButtonText, onAddNewButtonClick, listElement } = this.props;
    return (
      <LayoutContainer>
        <LayoutHeader
          header={header}
          actions={[
            <Button key="add-new" onClick={onAddNewButtonClick} label={addNewButtonText} />,
          ]}
        />
        {listElement}
      </LayoutContainer>
    );
  };

  render() {
    const { itemsCount, loading } = this.props;
    return (
      <>
        {itemsCount === 0 && !loading && this.renderEmptyList()}
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {itemsCount > 0 && this.renderContent()}
        {loading && <Loader positionAbsolute />}
      </>
    );
  }
}
