import React from 'react';
import { Checkbox } from 'components/common/Checkbox';
import style from './RecurrenceCancellation.scss';
import Wahanda from 'common/wahanda';

interface Props {
  onChange: (boolean) => void;
}

export const RecurrenceCancellation = (props: Props) => {
  const onChange = (checked?: boolean) => {
    props.onChange(Boolean(checked));
  };

  return (
    <div className={style.checkboxContainer}>
      <Checkbox
        label={Wahanda.lang.recurrence.cancellation.checkboxLabel}
        name="recurrence-cancellation-option"
        dataTest="recurrence-cancellation-option"
        onChange={onChange}
      />
    </div>
  );
};
