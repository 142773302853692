import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class PersonCircle extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-person-circle', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path
          d="M19,20 L17,20 C17,18.5705052 16.3982381,17.2401676 15.360375,16.2975259 L16.7050408,14.8170281 C18.1560028,16.134868 19,18.000724 19,20 Z M12,13 L12,15 C9.23857625,15 7,17.2385763 7,20 L5,20 C5,16.1340068 8.13400675,13 12,13 Z M12,15 C9.51471863,15 7.5,12.9852814 7.5,10.5 C7.5,8.01471863 9.51471863,6 12,6 C14.4852814,6 16.5,8.01471863 16.5,10.5 C16.5,12.9852814 14.4852814,15 12,15 Z M12,13 C13.3807119,13 14.5,11.8807119 14.5,10.5 C14.5,9.11928813 13.3807119,8 12,8 C10.6192881,8 9.5,9.11928813 9.5,10.5 C9.5,11.8807119 10.6192881,13 12,13 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z"
          transform="matrix(-1 0 0 1 24 0)"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(PersonCircle);
