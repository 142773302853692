import React from 'react';
import classnames from 'classnames';
import { EmployeeSalesReportAnalytics } from 'common/analytics';
import Wahanda from 'common/wahanda';
import Link from '../SalesReport/link';
import tableStyle from '../common/reportTable.scss';

interface IReportTableProps extends React.HTMLAttributes<Element> {
  fromDate: any;
  toDate: any;
  rows: {
    employee?: string;
    numberOfServices?: number;
    serviceGrossTotal?: number;
    numberOfProducts?: number;
    productGrossTotal?: number;
    numberOfVouchers?: number;
    voucherGrossTotal?: number;
    grossTotal?: number;
  }[];
  total: {
    numberOfServices?: number;
    serviceGrossTotal?: number;
    numberOfProducts?: number;
    productGrossTotal?: number;
    numberOfVouchers?: number;
    voucherGrossTotal?: number;
    grossTotal?: number;
  };
}

export default class ReportTable extends React.Component<IReportTableProps, {}> {
  getTableHeader = () => (
    <thead>
      <tr>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={tableStyle.stackedHeaderDoubleHeight} rowSpan="2">
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.employee}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.services}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.products}
        </th>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)} colSpan="2">
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.vouchers}
        </th>
        <th
          className={classnames(tableStyle.centerHeader, tableStyle.stackedHeaderDoubleHeight)}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
          rowSpan="2"
        >
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.totalSales} <br />
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.includingVat}
        </th>
      </tr>
      <tr>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.sales} <br />
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.includingVat}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.sales} <br />
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.includingVat}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.quantity}
        </th>
        <th className={classnames(tableStyle.centerHeader, tableStyle.stackedHeader)}>
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.sales} <br />
          {Wahanda.lang.reports.sales.employeeSalesReport.headings.includingVat}
        </th>
      </tr>
    </thead>
  );

  getTableFooter = (total) => (
    <tfoot>
      <tr>
        <td>{Wahanda.lang.reports.sales.employeeSalesReport.headings.grandTotal}</td>
        <td className={tableStyle.numeric}>{total.numberOfServices}</td>
        <td className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.serviceGrossTotal)}
        </td>
        <td className={tableStyle.numeric}>{total.numberOfProducts}</td>
        <td className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.productGrossTotal)}
        </td>
        <td className={tableStyle.numeric}>{total.numberOfVouchers}</td>
        <td className={tableStyle.numeric}>
          {Wahanda.Currency.formatFinancial(total.voucherGrossTotal)}
        </td>
        <td className={tableStyle.numeric}>{Wahanda.Currency.formatFinancial(total.grossTotal)}</td>
      </tr>
    </tfoot>
  );

  render() {
    const { rows, total } = this.props;
    const { fromDate, toDate } = this.props;
    return (
      <div>
        <table className={tableStyle.reportTable}>
          {this.getTableHeader()}
          <tbody>
            {rows.map((row) => (
              <tr data-testid="employee-sales-row" key={row.employee}>
                <td data-testid="employee-sales-name">
                  <Link
                    // @ts-expect-error ts-migrate(2322) FIXME: Property 'className' does not exist on type 'Intri... Remove this comment to see the full error message
                    className={tableStyle.link}
                    fromDate={fromDate}
                    toDate={toDate}
                    // @ts-expect-error ts-migrate(2551) FIXME: Property 'employeeId' does not exist on type '{ em... Remove this comment to see the full error message
                    employeeFilter={row.employeeId}
                    onClick={EmployeeSalesReportAnalytics.trackEmployeeLinkClick}
                  >
                    {row.employee}
                  </Link>
                </td>
                <td data-testid="employee-sales-services-quantity" className={tableStyle.numeric}>
                  {row.numberOfServices}
                </td>
                <td data-testid="employee-sales-services-gross" className={tableStyle.numeric}>
                  {Wahanda.Currency.formatFinancial(row.serviceGrossTotal)}
                </td>
                <td data-testid="employee-sales-products-quantity" className={tableStyle.numeric}>
                  {row.numberOfProducts}
                </td>
                <td data-testid="employee-sales-products-gross" className={tableStyle.numeric}>
                  {Wahanda.Currency.formatFinancial(row.productGrossTotal)}
                </td>
                <td data-testid="employee-sales-vouchers-quantity" className={tableStyle.numeric}>
                  {row.numberOfVouchers}
                </td>
                <td data-testid="employee-sales-vouchers-gross" className={tableStyle.numeric}>
                  {Wahanda.Currency.formatFinancial(row.voucherGrossTotal)}
                </td>
                <td data-testid="employee-sales-gross-total" className={tableStyle.grandTotal}>
                  {Wahanda.Currency.formatFinancial(row.grossTotal)}
                </td>
              </tr>
            ))}
          </tbody>
          {this.getTableFooter(total)}
        </table>
      </div>
    );
  }
}
