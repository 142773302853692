export const useOpenFileSelect = ({
  onFilesSelected,
  multiple = true,
}: {
  onFilesSelected: (files: FileList) => void;
  multiple?: boolean;
}) => {
  return () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = multiple;
    input.addEventListener('change', () => {
      if (input.files) {
        onFilesSelected(input.files);
      }
    });
    input.click();
  };
};
