import React from 'react';

interface Props {
  className?: string;
}

class Supplier extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-supplier', this.props.className].join(' ')}
        viewBox="0 0 97 60"
      >
        <g fill="none" transform="translate(0 1)" fillRule="evenodd">
          <g transform="translate(4)">
            <path
              fill="#00B0B9"
              d="M48.623,45 C34.208,42.333 27,35 27,23 C27,11 19.986,4.059 5.957,2.177 L3.771,1.79 L3.771,40 C3.771,41.3260824 4.2977842,42.597852 5.23546609,43.5355339 C6.17314799,44.4732158 7.44491755,45 8.771,45 L12.832,45 L15.309,39 L20.859,37 L26.809,39 L29.218,45 L48.623,45 Z"
              fillRule="nonzero"
            />
            <path
              fill="#FFB500"
              d="M58.947,10 C63.372,10 67.227,11.667 70.514,15 C73.799,18.333 77.965,19.345 83.01,18.036 L85.203,20.652 L84.138,23 L58.948,23 L58.948,10 L58.947,10 Z"
              fillRule="nonzero"
            />
            <path
              stroke="#001E62"
              d="M59,2 L69.164,2 C72.8484569,1.99964709 76.3288478,3.69191343 78.604,6.59 L90.293,20.482 C91.3990283,21.891112 92,23.6306611 92,25.422 L90.367,41.407 C90.1582253,43.4481911 88.4388401,45.0000001 86.387,45.0000001 L8,45.0000001 C5.790861,45.0000001 4,43.209139 4,41 L4,23 L3.9909995,4 C3.9909995,2.93878756 4.4116625,1.92085803 5.16186553,1.17027989 C5.91206857,0.41970176 6.92978743,-0.002 7.9909995,-0.002 L51,0 C53.209139,2.22044605e-16 55,1.790861 55,4 L55,37"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              stroke="#001E62"
              d="M32.242,41 L57,41 C58.1045695,41 59,40.1045695 59,39 L59,8 C59,6.8954305 59.8954305,6 61,6 L69.316,6 C71.6539843,6 73.8750179,7.02253575 75.395,8.799 L84.722,19.699 C85.2301051,20.2922497 85.3463456,21.1270154 85.0196467,21.8365109 C84.6929478,22.5460064 83.9830991,23.0003857 83.202,23 L9,23"
              strokeWidth="2"
            />
            <path
              fill="#FFF"
              d="M65,44 L81,44 L81,46 L65,46 L65,44 Z M13,44 L29,44 L29,46 L13,46 L13,44 Z"
              fillRule="nonzero"
            />
            <path
              stroke="#001E62"
              d="M81.485,36.515 C76.799,31.828 69.201,31.828 64.515,36.515 M29.485,36.515 C24.799,31.828 17.201,31.828 12.515,36.515"
              strokeWidth="2"
            />
            <path
              fill="#FFB500"
              d="M18,42.578 C21.018,44.454 22.527,46.594 22.527,49 C23.419,47.667 24.309,45.78 25.198,43.337 L22.445,41 L18,42.578 Z"
              fillRule="nonzero"
            />
            <path
              fill="#FFB500"
              d="M70,42.578 C73.018,44.454 74.527,46.594 74.527,49 C75.419,47.667 76.309,45.78 77.198,43.337 L74.445,41 L70,42.578 Z"
              fillRule="nonzero"
            />
            <path
              stroke="#001E62"
              d="M9.282,45 L8.12,45 C7.37691638,45 6.6374749,44.8962031 5.923,44.692 L2.9,43.83 C1.18291364,43.3388653 -0.000546071341,41.7689447 -1.88896776e-07,39.983 L-1.88896776e-07,5.017 C-1.88896776e-07,3.23114637 1.1838737,1.66166329 2.901,1.171 L5.923,0.308 C6.6374749,0.103796913 7.37691638,0 8.12,0 L10.9999998,0"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="21" cy="45" r="8" stroke="#001E62" strokeWidth="2" />
            <circle cx="73" cy="45" r="8" stroke="#001E62" strokeWidth="2" />
            <circle cx="21" cy="45" r="4" stroke="#001E62" strokeWidth="2" />
            <circle cx="73" cy="45" r="4" stroke="#001E62" strokeWidth="2" />
            <polygon
              fill="#D8D8D8"
              stroke="#001E62"
              points="32.5 16.5 49.5 16.5 49.5 17.5 32.5 17.5"
              fillRule="nonzero"
            />
          </g>
          <path
            stroke="#001E62"
            d="M85.743,53 C83.733,56.116 77.976,57.314 73.547,56.116 C69.119,54.918 64.401,52.777 60.003,54.022 C55.305,55.352 52.111,60.24 47.487,58.801 C43.884,57.68 44.632,53.677 41.174,52.375 C35.735,50.326 31.945,57.309 25.763,57.994 C19.733,58.663 15.539,53.01 9.437,53.282"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            stroke="#001E62"
            d="M67,50.754 C64.012,49.749 60.765,49.497 57.26,50 M14.113,50 C9.58125037,49.1119508 4.92324077,49.0807459 0.38,49.908"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  }
}

export const icon = Supplier;
