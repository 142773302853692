import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Expand extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-expand', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M5,13 L5,17.584 L9.29289322,13.2928932 L10.7071068,14.7071068 L6.414,19 L11,19 L11,21 L3,21 L3,13 L5,13 Z M21,3 L21,11 L19,11 L19,6.414 L14.7071068,10.7071068 L13.2928932,9.29289322 L17.584,5 L13,5 L13,3 L21,3 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Expand);
