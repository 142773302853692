import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Award extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-award', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M7,23.2807764 L7,16 L9,16 L9,20.7192236 L12,19.9692236 L15,20.7192236 L15,19 L17,19 L17,23.2807764 L12,22.0307764 L7,23.2807764 Z M12,0 C16.9705627,0 21,4.02943725 21,9 C21,13.9705627 16.9705627,18 12,18 C7.02943725,18 3,13.9705627 3,9 C3,4.02943725 7.02943725,0 12,0 Z M12,2 C8.13400675,2 5,5.13400675 5,9 C5,12.8659932 8.13400675,16 12,16 C15.8659932,16 19,12.8659932 19,9 C19,5.13400675 15.8659932,2 12,2 Z M12,4 L13.1755705,7.38196599 L16.7552826,7.45491503 L13.9021131,9.618034 L14.9389263,13.045085 L12,11 L9.06107374,13.045085 L10.0978869,9.618034 L7.24471742,7.45491503 L10.8244295,7.38196599 L12,4 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Award);
