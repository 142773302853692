import moment from 'common/moment';
import Wahanda from 'common/wahanda';

export const TIME_PERIOD_FILTERS = {
  LAST_TWELVE_MONTHS: {
    value: 4,
    name: Wahanda.lang.reports.sales.filters.dateRange.lastTwelveMonths,
    range: {
      fromDate: moment().subtract(11, 'month').formatApiDateString(),
      toDate: moment().formatApiDateString(),
    },
  },
  ALL_TIME: {
    value: 0,
    name: Wahanda.lang.reports.sales.filters.dateRange.allTime,
    range: {
      fromDate: undefined,
      toDate: undefined,
    },
  },
  THIS_YEAR: {
    value: 1,
    name: Wahanda.lang.reports.sales.filters.dateRange.thisYear,
    range: {
      fromDate: moment().startOf('year').formatApiDateString(),
      toDate: moment().formatApiDateString(),
    },
  },
  LAST_YEAR: {
    value: 2,
    name: Wahanda.lang.reports.sales.filters.dateRange.lastYear,
    range: {
      fromDate: moment().startOf('year').subtract(1, 'year').formatApiDateString(),
      toDate: moment().endOf('year').subtract(1, 'year').formatApiDateString(),
    },
  },
};

export const CLIENT_REPORT_DROPDOWN_VALUES = {
  CLIENT_ACQUISITION: {
    name: Wahanda.lang.reports.tabs.clientAcquisition,
    value: 1,
    defaultFilters: {},
  },
  CLIENT_RETENTION: {
    name: Wahanda.lang.reports.clientReports.clientRetention.reportName,
    value: 2,
    defaultFilters: {},
  },
};
