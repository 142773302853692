import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import Wahanda from 'common/wahanda';
import { Initializer } from 'common/types/initializers';
import { PrepayRequireDialog } from './index';

const store = storeBuilder();
const container = Wahanda.Dialogs.getReactContainer();
interface Params {
  customerId?: number;
}

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

const onClose = () => {
  destroy(container);
};
const onSubmit = () => {
  onClose();
};

export const PrepayRequireDialogInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(container),
  render: () => {
    ReactDOM.render(
      <Provider store={store}>
        <PrepayRequireDialog
          customerId={get(params, 'customerId')}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </Provider>,
      container,
    );
  },
});
