import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Arrow extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-arrow', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 12 12"
      >
        <path
          d="M6 0l4.707 4.707-1.414 1.414L7 3.828V11H5V3.828L2.707 6.121 1.293 4.707 6 0z"
          fillRule="nonzero"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(Arrow);
