import { xhr } from 'common/xhr';

/**
 * Wahanda namespace.
 */
var Wahanda = window.Wahanda;

// Is set to true, when the client navigates away from the page.
// Does not work on all browsers.
Wahanda.pageUnloading = false;

(function () {
  var connectionErrorDialogShown = false;
  var iosDialogShowTimeout = null;
  /**
   * Handles the given error. This is the default handler.
   *
   * @param jqXHR
   */
  var defaultErrorHandler = function (jqXHR, ajaxSettings, thrownError, event) {
    if (jqXHR.status in { 401: 1, 403: 1 } || jqXHR.statusText === 'abort') {
      // These errors are handled outside this scope. Or the request is aborted.
      return;
    }

    if (jqXHR.readyState === 0) {
      if (!Wahanda.pageUnloading && !connectionErrorDialogShown) {
        Wahanda.Ajax.showConnectionError();
      }
    } else {
      Wahanda.Ajax.showError(jqXHR);
    }
  };

  /**
   * Wahanda ajax handler.
   */
  Wahanda.Ajax = {
    /**
     * The error handler.
     */
    errorHandler: defaultErrorHandler,
    /**
     * Important statuses: these probably must be handled by Wahanda.Ajax
     */
    importantStatuses: [401, 403],

    errorDialogClass: null,
    errorDialog: null,

    initErrorHandler: function () {
      $(document)
        .unbind('ajaxError.wahanda')
        .bind(
          'ajaxError.wahanda',
          /**
           * Sets a handler for ajax errors.
           *
           * @param Event evt
           * @param jqXHR jqXHR
           * @param Object ajaxSettings
           * @param Error thrownError
           */
          function (evt, jqXHR, ajaxSettings, thrownError) {
            if (jqXHR.readyState === 0 || Wahanda.Ajax._canHandleError(ajaxSettings, jqXHR)) {
              Wahanda.Ajax.errorHandler(jqXHR, ajaxSettings, thrownError, evt);
            }
          },
        );

      Wahanda.Ajax.Retry.initialize();
    },

    /**
     * Checks if current error should be handled.
     *
     * This is coputed based on the `skipErrorHandling` parameter of jQuery ajax settings.
     * It can be a boolean, or a function returning boolean values. If it will evaluate to TRUE, no
     * default error handling will be performed.
     *
     * @param Object settings jQuery errored ajax request settings
     * @param jqXHR jqXHR
     * @return boolean
     */
    _canHandleError: function (settings, jqXHR) {
      switch (typeof settings.skipErrorHandling) {
        case 'boolean':
          return !settings.skipErrorHandling;
        case 'function':
          return settings.skipErrorHandling(jqXHR, settings);
      }
      return true;
    },

    /**
     * Sets the global ajax error handler.
     *
     * @param function handler
     */
    setErrorHandler: function (handler) {
      this.errorHandler = handler;
    },

    /**
     * Sets the global ajax error handler, passing it through `safeErrorHandler` method.
     */
    setSafeErrorHandler: function (handler) {
      this.errorHandler = this.safeErrorHandler(handler);
    },

    /**
     * Sets the default error handler.
     */
    resetErrorHandler: function () {
      Wahanda.Ajax.setErrorHandler(defaultErrorHandler);
    },

    /**
     * Shows an error. All information is derived from jqXHR.
     *
     * @param jqXHR jqXHR
     */
    showError: function (jqXHR) {
      if (/application\/json/i.test(jqXHR.getResponseHeader('content-type'))) {
        this._showErrorJson(jqXHR);
      } else {
        this._showErrorText(jqXHR);
      }
    },

    _showErrorJson: function (jqXHR) {
      var errors = $.parseJSON(jqXHR.responseText) || {};
      errors.RUID = jqXHR.getResponseHeader('ruid');
      errors.server = jqXHR.getResponseHeader('X-WHN-Origin');
      var texts = Wahanda.lang.error.ajax.json;
      var msg =
        texts.title +
        '<br><br>' +
        texts.body +
        '<br><br>' +
        Wahanda.Template.renderTemplate('error-ajax-json', errors);

      App.lastError = msg;
      if (!App.isLoaded) {
        Wahanda.Dialogs.HTMLAlert(msg, { width: 500 });
      }
    },

    _showErrorText: function (jqXHR) {
      var type = parseInt(jqXHR.status / 100, 10);
      // Find best match for error template
      var template =
        // code-500 responses
        Wahanda.lang.error.ajax['code-' + jqXHR.status] ||
        // type-4 (4xx) erro responses
        Wahanda.lang.error.ajax['type-' + type] ||
        // General, unspecific errors
        Wahanda.lang.error.ajax.general;
      var params = {
        status: jqXHR.status,
        statusText: jqXHR.statusText,
        body: null,
        RUID: jqXHR.getResponseHeader('ruid'),
        server: jqXHR.getResponseHeader('X-WHN-Origin'),
      };
      if (jqXHR.responseText) {
        // If response body exists, get it as text
        var match = jqXHR.responseText.match(/<body[^>]*>([\s\S]*)<\/body>/i);
        params.body = match ? $(match[1]).text() : jqXHR.responseText;
      }

      var text =
        Wahanda.Template.render(template.title, params) +
        '\n\n' +
        Wahanda.Template.render(template.body, params);
      App.lastError = text;
      if (!App.isLoaded) {
        Wahanda.Dialogs.HTMLAlert(text, { width: 500 });
      }
    },

    showConnectionError: function () {
      if (/i(Phone|Pad)/i.test(window.navigator.userAgent)) {
        // The iDevices do seem not to support the beforeunload event.
        // We need to hack around this.
        if (iosDialogShowTimeout) {
          return;
        }
        iosDialogShowTimeout = window.setTimeout(function dialogShowFn() {
          iosDialogShowTimeout = null;
          if (!Wahanda.pageUnloading) {
            showConnErrorDialog();
          }
        }, 5000);
      } else {
        showConnErrorDialog();
      }

      function showConnErrorDialog() {
        connectionErrorDialogShown = true;
        var constructor = Wahanda.Ajax._getErrorDialogClass();
        // Show the retry dialog
        Wahanda.Ajax.errorDialog = new constructor({
          onClose: function () {
            connectionErrorDialogShown = false;
            Wahanda.Ajax.errorDialog = null;
          },
        });
        Wahanda.Ajax.errorDialog.render();
        Wahanda.Ajax.errorDialog.open();
        // Trigger global event
        App.trigger(Wahanda.Event.APP_CONNECTION_ERROR);
      }
    },

    /**
     * Returns the error dialog class to display to the user.
     * It knows about the context of mobile connect or full connect.
     */
    _getErrorDialogClass: function () {
      return App.Views.Dialog.Alert.AjaxRetry;
    },

    /**
     * Wraps the given function in another one in a "safe" way: it is allowed to handle all errors except
     * 401 and 403. Those two statuses cause a "Login redirect" dialog to appear.
     *
     * @param function whenSafe
     * @return function
     */
    safeErrorHandler: function (whenSafe) {
      return function (xhr) {
        if (-1 === _.indexOf(Wahanda.Ajax.importantStatuses, xhr.status)) {
          whenSafe(xhr);
        } else {
          App.Views.LoginRequest.show();
        }
      };
    },
  };

  /**
   * The ajax retry handler.
   */
  Wahanda.Ajax.Retry = {
    timerName: 'ajax-retry',
    // Ajax requests to retry
    toRetry: [],
    // ms to retry the requests
    retryInterval: 15000,
    // Is the retry running? Can run only one at a time.
    _running: false,

    initialize: function () {
      // Setup ajax prefilter for slipping-in our own Deferred object.
      // http://www.bennadel.com/blog/2132-Using-ajaxPrefilter-To-Configure-AJAX-Requests-In-jQuery-1-5.htm
      $.ajaxPrefilter(function (options, _, jqXHR) {
        var defer = $.Deferred();
        // TODO: possibility to skip error handling? Can be passed in options, if needed.
        jqXHR.then(
          function () {
            Wahanda.Ajax.errorDialog && Wahanda.Ajax.errorDialog.close();
            defer.resolve.apply(this, arguments);
          },
          function () {
            var isAborted = jqXHR.statusText === 'abort';
            var isConnectionError = jqXHR.readyState === 0;
            var isGet = options.type === 'GET';

            if (isAborted || !isConnectionError || !isGet) {
              // Reject the request normally - it's a valid error
              if (!Wahanda.pageUnloading) {
                defer.rejectWith(this, arguments);
              }
            } else {
              // Do not execute any fail callbacks.
              // Add this request to the queue for retrying.
              Wahanda.Ajax.Retry.saveRequest(options);
            }
          },
        );

        jqXHR = defer.promise(jqXHR);
        jqXHR.success = jqXHR.done;
        jqXHR.error = jqXHR.fail;
      });

      // Bind to timer
      App.Timer.on(Wahanda.Ajax.Retry.timerName, Wahanda.Ajax.Retry.retry);
    },

    /**
     * Save the request for retrying in a few seconds.
     *
     * @param Object ajaxSettings The ajaxSettings array
     */
    saveRequest: function (ajaxSettings) {
      var self = Wahanda.Ajax.Retry;
      self.toRetry.push({
        time: new Date().getTime(),
        ajaxSettings: ajaxSettings,
      });
      self.startTimer();
    },

    startTimer: function () {
      if (!App.Timer.started(Wahanda.Ajax.Retry.timerName)) {
        App.Timer.start(Wahanda.Ajax.Retry.timerName, 2000);
      }
    },

    stopTimer: function () {
      App.Timer.cancel(Wahanda.Ajax.Retry.timerName);
    },

    /**
     * Retry all saved requests.
     *
     * @param boolean force OPTIONAL Force the requests to retry, ignoring that they are still "fresh".
     *                      Defaults to false.
     */
    retry: function (force) {
      var self = Wahanda.Ajax.Retry;
      if (self._running) {
        return;
      }
      self._running = true;

      var toRun = self.toRetry;
      self.toRetry = [];

      var now = new Date().getTime();
      var item;

      for (var i = 0, len = toRun.length; i < len; i++) {
        item = toRun[i];
        if (!safeToRetry(item.ajaxSettings)) {
          notifyBadRetry(item.ajaxSettings);
          continue;
        }
        if (force || now - item.time > self.retryInterval) {
          // Add ajax counter
          item.ajaxSettings.headers = addCounterHeader(item.ajaxSettings.headers);
          xhr.doJQueryAjax(item.ajaxSettings);
        } else {
          self.toRetry.push(item);
        }
      }

      if (self.toRetry.length === 0) {
        self.stopTimer();
      }

      self._running = false;
    },
  };

  /**
   * Is the request safe to retry? A second check.
   * @param item
   */
  function safeToRetry(item) {
    if (typeof item.type === 'undefined' || !item.type || /get/i.test(item.type)) {
      return true;
    }
    return false;
  }

  function notifyBadRetry(item) {
    xhr.postWithPromise(App.Api.wsUrl('/client-error'), {
      message: 'Was about to retry a Ajax request with type `' + item.type + '` (only GET allowed)',
      level: 'ERROR',
    });
  }

  // Add retry number header so that we're able to track if the request originated from this retry mechanism
  function addCounterHeader(headers) {
    headers = headers || {};
    headers['X-Retry'] = (headers['X-Retry'] || 0) + 1;
    return headers;
  }
})();

if (typeof $ !== 'undefined') {
  $(window).on('beforeunload unload', function () {
    Wahanda.pageUnloading = true;
  });
}
