import { useState } from 'react';
import { WizardModalProps } from 'components/common/WizardModal/WizardModal.types';

type WizardModalHook = {
  currentStep: number;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  handleClose: () => void;
  showConfirmationModal: () => void;
  hideConfirmationModal: () => void;
  isOpen: boolean;
  isSubmitting: boolean;
  goToStep: (step: number) => void;
};
export const useWizardModal = ({
  steps,
  onSubmit,
  onClose,
}: Omit<WizardModalProps, 'lastStepLocale'>): WizardModalHook => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNextStep = async () => {
    if (currentStep < steps.length - 1) setCurrentStep((prev) => prev + 1);
    else {
      setIsSubmitting(true);
      await onSubmit();
      setIsSubmitting(false);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep >= 1) setCurrentStep((prev) => prev - 1);
  };

  const goToStep = (step: number) => {
    setCurrentStep(step);
  };

  const showConfirmationModal = () => {
    setIsOpen(true);
  };

  const hideConfirmationModal = () => {
    setIsOpen(false);
  };

  const handleClose = () => {
    hideConfirmationModal();
    onClose();
  };

  return {
    currentStep,
    handleNextStep,
    handlePreviousStep,
    isOpen,
    isSubmitting,
    showConfirmationModal,
    hideConfirmationModal,
    handleClose,
    goToStep,
  };
};
