import React from 'react';
import Dialog from 'components/common/react-dialog';
import { Button } from 'components/common/Button';
import Wahanda from 'common/wahanda';
import style from './style.scss';

const lang = Wahanda.lang.settings.employees.employee.noServicesAssignedConfirm;
interface IRescheduleOrCancelChoiceProps extends React.HTMLAttributes<Element> {
  onSaveClick: (...args: any[]) => any;
  onAssignServicesClick: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
}
class RescheduleOrCancelChoice extends React.Component<IRescheduleOrCancelChoiceProps, {}> {
  onAssignServicesClick = () => {
    this.props.onAssignServicesClick();
  };

  onSaveClick = () => {
    this.props.onSaveClick();
  };

  render() {
    return (
      <Dialog
        dataTest="no-services-assigned-confirm-modal"
        width={380}
        title={lang.title}
        onClose={this.props.onClose}
      >
        <div className={style.dialog}>
          <div>{lang.text}</div>
          <div className={style.primaryButton}>
            <Button
              variant="primary"
              label={lang.buttons.assignServices}
              onClick={this.onAssignServicesClick}
              fullWidth
            />
          </div>
          <Button
            variant="secondary"
            label={lang.buttons.save}
            onClick={this.onSaveClick}
            fullWidth
          />
        </div>
      </Dialog>
    );
  }
}

export default RescheduleOrCancelChoice;
