import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class ClockUnavailable extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-clock-unavailable', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 16 16"
      >
        <path
          d="M16 8a8 8 0 0 1-11.124 7.367l1.57-1.57a6 6 0 0 0 7.35-7.35l1.571-1.571C15.775 5.836 16 6.892 16 8zM8 0c1.848 0 3.55.627 4.905 1.68L14.293.292l1.414 1.414-14 14-1.414-1.414 1.386-1.388A8 8 0 0 1 8 0zm0 2a6 6 0 0 0-4.891 9.476L7 7.584V4h2v1.584l2.476-2.475A5.973 5.973 0 0 0 8 2zm2.829 7.414l.378.379-1.414 1.414-.379-.379 1.415-1.414z"
          fillRule="nonzero"
        />
      </svg>
    );
  }
}

export const icon = iconWrapper(ClockUnavailable);
