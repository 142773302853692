import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import { EmailCampaignSuccessAnalytics as Analytics } from 'common/analytics';
import { StateCard } from 'components/common/StateCard';
import { Analytics as BackToBusinessAnalytics } from 'components/marketing/EmailCampaign/BackToBusiness/tracking';
import style from './CampaignSuccess.scss';
import SuccessImage from './SuccessImage';

const langDefault = Wahanda.lang.marketing.newCampaign.successAndSentDefault;
const langAppForYourClients = Wahanda.lang.marketing.newCampaign.successAndSentAppForYourClients;
const langVenueOpening = Wahanda.lang.marketing.newCampaign.successAndSentVenueOpeningCampaign;
interface ICampaignSuccessProps extends React.HTMLAttributes<Element> {
  referrer?: string;
  match: {
    params: {
      templateId: string;
    };
  };
  params?: any;
}

export class CampaignSuccess extends React.Component<ICampaignSuccessProps, {}> {
  static defaultProps = {
    referrer: undefined,
  };

  componentDidMount() {
    Analytics.trackPageView(this.props.referrer);
  }

  getLang = () => {
    const { templateId } = this.props.match.params;
    switch (templateId) {
      case 'app-for-your-clients':
        return langAppForYourClients;
      case 'venue-opening-campaign':
        return langVenueOpening;
      default:
        return langDefault;
    }
  };

  giveFeedback() {
    Analytics.trackFeedbackBtnClick();
    window.open(langDefault.surveyLinkUrl, '_blank');
  }

  onViewReportClicked = () => {
    BackToBusinessAnalytics.trackViewReportFromSuccessClicked();
    window.location.assign(Wahanda.Url.getFullUrl('reports', 'clientReports/retention'));
  };

  getSuccessCard = () => {
    const { templateId } = this.props.match.params;
    const lang = this.getLang();
    const canViewClientReports = Wahanda.Permissions.canViewClientReports();
    switch (templateId) {
      case 'venue-opening-campaign':
        return (
          <StateCard
            heading={lang.header}
            message={canViewClientReports ? lang.text : ''}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '{ text: str... Remove this comment to see the full error message
            button={
              canViewClientReports
                ? {
                    text: lang.button,
                    onClick: this.onViewReportClicked,
                  }
                : null
            }
            image={<SuccessImage className="icon-svg" />}
          />
        );
      default:
        return (
          <StateCard
            heading={lang.header}
            message={lang.text}
            button={{
              text: lang.surveyLink,
              onClick: this.giveFeedback,
            }}
            image={<SuccessImage className="icon-svg" />}
          />
        );
    }
  };

  render() {
    return <div className={classnames(style.wrapper)}>{this.getSuccessCard()}</div>;
  }
}
