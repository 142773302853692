/* eslint no-console:0 */
import App from 'common/backbone-app';
import getAuthData from './auth';
import * as state from './state';
import setupPing from './ext/ping';
import setupReconnect from './ext/reconnect';
import getEndpoint from './endpoint';

let wsClient;
let messageQueue: ReturnType<typeof formatWSMessage>[] = [];
let authenticated = false;
let listeners = {};

// WS constants
const WS_CONNECTING = 0; // The connection is not yet open.
const WS_OPEN = 1; // The connection is open and ready to communicate.
// WS_CLOSING = 2; // The connection is in the process of closing.
// WS_CLOSED = 3; // The connection is closed or couldn't be opened.

function formatWSMessage(command, data) {
  return JSON.stringify({ command, data });
}

function createMessageCallbackHandler(next) {
  return ({ data }) => {
    try {
      const jsData = JSON.parse(data);
      next(jsData);
    } catch (e) {
      console.error(`WS: error parsing server message '${data}'`);
    }
  };
}

const notifyListeners = createMessageCallbackHandler((msg) => {
  if (!msg.type || !listeners[msg.type]) {
    return;
  }

  listeners[msg.type].forEach((callback) => {
    try {
      callback(msg.data, msg.type);
    } catch (e) {
      console.error(
        "WS: error in callback %o handling '%s' callback: %s",
        callback,
        JSON.stringify(msg),
        e,
      );
    }
  });
});

function connect() {
  // If we have an open connection, don't connect twice.
  if (wsClient && [WS_CONNECTING, WS_OPEN].includes(wsClient.readyState)) {
    return;
  }

  wsClient = new WebSocket(`${getEndpoint()}/?ruid=${App.ruid}`);

  wsClient.addEventListener('open', () => {
    wsClient?.send(formatWSMessage('authenticate', getAuthData()));
  });

  const handleAuthMessage = createMessageCallbackHandler((msg) => {
    if (msg.type !== 'authenticate') {
      // Not auth yet. Don't handle.
      return;
    }
    if (msg.data.success) {
      authenticated = true;
      wsClient.addEventListener('message', notifyListeners);
      wsClient.removeEventListener('message', handleAuthMessage);

      // Send queues messages
      if (messageQueue.length > 0) {
        messageQueue.forEach((queuedMsg) => wsClient.send(queuedMsg));
        messageQueue = [];
      }

      // Notify the App about connection.
      state.notifyConnected();
    } else {
      // Authentication failure. We won't retry. Close the connection.
      wsClient.close();
    }
  });

  wsClient.addEventListener('message', handleAuthMessage);

  wsClient.addEventListener('close', () => {
    wsClient = null;
    authenticated = false;
    // Reset the message listeners
    listeners = {};
    state.notifyDisconnected();
  });
}

function send(command, data?: { [_: string]: any }) {
  const strData = formatWSMessage(command, data || null);

  if (wsClient && wsClient.readyState === WS_OPEN && authenticated) {
    wsClient.send(strData);
  } else {
    // Try connecting, if needed.
    connect();
    messageQueue.push(strData);
  }
}

/**
 * Listen for {type} messages.
 *
 * @param {String} type Message type, e.g. 'ping'
 * @param {Function} callback The function to call once a message is received.
 */
function listen(type, callback) {
  if (!listeners[type]) {
    listeners[type] = [];
  }
  listeners[type].push(callback);
}

/**
 * Stop listening for {type} messages.
 *
 * @param {String} type Message type
 * @param {Function} callback The *same function reference* which was assigned with `listen`.
 */
function stopListening(type, callback) {
  if (listeners[type]) {
    listeners[type] = listeners[type].filter((cb) => cb !== callback);
  }
}

/**
 * Be notified about WebSocket connection changes.
 *
 * @param {Function} onConnect
 * @param {Function} onDisconnect
 */
function onStateChange(onConnect, onDisconnect) {
  state.addWatcher(onConnect, onDisconnect);
  connect();
}

// Setup extensions
setupPing(send);
setupReconnect(connect);

export { send, listen, stopListening, onStateChange };
