import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Minus extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-minus', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <rect width="10" height="2" x="3" y="7" />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-minus', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 24 24"
          >
            <polygon points="5 11 5 13 19 13 19 11" />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Minus);
