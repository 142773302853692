import { connect } from 'react-redux';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { isFeatureEnabled } from 'common/trunk-features/store/selectors';

import { getClientAppShareLinkRequest } from './actions';
import { ClientApp as Component } from './ClientApp';
import { CLIENT_APP_CAMPAIGN_CHANNEL_FEATURE } from '../chnlFeatures';

const mapStateToProps = (state) => ({
  shareableLinks: state.marketing.clientApp.uris,
  isEmailCampaignsAccessible:
    Wahanda.Permissions.canAccessMarketingTools() &&
    App.isFeatureSupported(CLIENT_APP_CAMPAIGN_CHANNEL_FEATURE),
  channelCode: App.domainChannelCode(),
  hasAppvocacySettings: isFeatureEnabled(state, 'CAR-1985-client-app-appvocacy-setting'),
});

const mapDispatchToProps = {
  getClientAppShareLinkRequest,
};

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ shareableLinks: null; }' is not assignable... Remove this comment to see the full error message
export const ClientApp = connect(mapStateToProps, mapDispatchToProps)(Component);
