import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { ReceiptCopyDialog } from './ReceiptCopyDialog';

interface Params {
  node: HTMLElement;
}

export const ReceiptCopyDialogInitializer = (params: Params): Initializer => {
  const onClose = () => ReactDOM.unmountComponentAtNode(params.node);

  return {
    destroy: onClose,
    render: (options) => {
      ReactDOM.render(
        <ReceiptCopyDialog onClose={onClose} receiptCopyUri={options.receiptCopyUri} />,
        params.node,
      );
    },
  };
};
