import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'reduxStore/common/customers/actions';
import { PrepayRequireDialog as Component } from './PrepayRequireDialog';

const mapStateToProps = ({ customers: { customer, isLoaded } }) => ({
  isLoaded,
  prepaymentRequired: customer.prepaymentRequired,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export const PrepayRequireDialog = connect(mapStateToProps, mapDispatchToProps)(Component);
