/**
 * Persistent storage.
 *
 * Use `set(key, val)` to store values.
 * Use `get(key)` to retrieve the values. `undefined` means that no such key was previously set.
 * Use `remove(key)` to remove an existing value.
 */
Wahanda.LocalStorage = (function () {
  var Store = {};

  if ('localStorage' in window) {
    Store.set = function (key, value) {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (e) {
        debug('Error storing value: ' + e);
      }
    };

    Store.get = function (key) {
      var result;
      try {
        var json = localStorage.getItem(key);
        if (json) {
          result = JSON.parse(json);
        }
      } catch (e) {
        debug('Error getting value: ' + e);
      }
      return result;
    };

    Store.remove = function (key) {
      try {
        localStorage.removeItem(key);
      } catch (e) {
        debug('Error removing key: ' + e);
      }
    };

    // Per-venue storage
    Store.setForVenue = function (key, value) {
      key = makeVenueKey(key);
      Store.set(key, value);
    };

    Store.getForVenue = function (key) {
      return Store.get(makeVenueKey(key));
    };

    Store.removeForVenue = function (key, value) {
      return Store.remove(makeVenueKey(key));
    };
  } else {
    // No localStorage support
    Store.set = Store.remove = Store.get = Store.setForVenue = Store.getForVenue = Store.removeForVenue = function () {};
  }

  function debug(error) {
    window.console && console.log && console.log(error);
  }

  function makeVenueKey(key) {
    return key + ';' + App.getVenueId();
  }

  return Store;
})();
