import { produce, Draft } from 'immer';
import Latinize from 'latinize';
import { reducer, on } from 'ts-action';
import * as actions from './actions';

import {
  ErrorType,
  ErrorStack,
  ErrorPayload,
  GroupPayload,
  ServiceEmployee,
  ServicePayload,
  ServiceEditPayload,
} from './types';
import {
  getGroupIndex,
  getGroupName,
  getGroupService,
  getGroupServiceIndex,
  getEmployeesCollectionIds,
  getSelectedGroupIndex,
  getSelectedIndexes,
} from './selectors';

interface State {
  selectedGroups: GroupPayload[];
  groupTypesList: GroupPayload[];
  isSubmitting: boolean;
  serverError: boolean;
  employeesCollection: ServiceEmployee[];
  errorType: ErrorType;
  errorStacks: ErrorStack[];
}

export const initialState: State = {
  selectedGroups: [],
  groupTypesList: [],
  isSubmitting: false,
  serverError: false,
  employeesCollection: [],
  errorType: ErrorType.NONE,
  errorStacks: [],
};

function addMultipleServiceItem(state: State, payload: ServiceEditPayload) {
  const { menuGroupId, serviceId } = payload;

  const groupName = getGroupName(state, { menuGroupId });
  const groupIndex = getGroupIndex(state, { menuGroupId });
  const service = getGroupService(state, { menuGroupId }, { serviceId });
  const serviceIndex = getGroupServiceIndex(state, { menuGroupId }, { serviceId });
  const selectedGroupIndex = getSelectedGroupIndex(state, {
    menuGroupId,
  });

  // Add selectedGroups object
  const groupsState = produce(state, (draftState) => {
    if (selectedGroupIndex < 0) {
      draftState.selectedGroups.push({
        name: groupName,
        menuGroupId,
        services: [],
      });
    }
  });

  const groups = produce(groupsState, (draftState) => {
    draftState.selectedGroups[
      getSelectedGroupIndex(groupsState, {
        menuGroupId,
      })
    ].services.push(service);
    draftState.groupTypesList[groupIndex].services[serviceIndex].checked = true;
  });

  return groups;
}

function removeMultipleServiceItem(state: State, payload: ServiceEditPayload) {
  const { menuGroupId, serviceId } = payload;
  const groupIndex = getGroupIndex(state, { menuGroupId });
  const serviceIndex = getGroupServiceIndex(state, { menuGroupId }, { serviceId });
  const { selectedGroupIndex, selectedServiceIndex } = getSelectedIndexes(state, payload);

  const groupsState = produce(state, (draftState) => {
    draftState.selectedGroups[selectedGroupIndex].services.splice(selectedServiceIndex, 1);
    draftState.groupTypesList[groupIndex].services[serviceIndex].checked = false;
  });

  // Remove selectedGroups object
  const groups = produce(groupsState, (draftState) => {
    if (!draftState.selectedGroups[selectedGroupIndex].services.length) {
      draftState.selectedGroups.splice(selectedGroupIndex, 1);
    }
  });

  return groups;
}

function populateMultipleServices(state: State, payload: GroupPayload[]) {
  const groupTypesList = Object.values(payload).map(({ services, ...group }) => {
    return {
      ...group,
      services: services.map((service) => ({
        ...service,
        checked: false,
        latinName: Latinize(service.name),
      })),
    };
  });

  return {
    ...state,
    groupTypesList,
  };
}

function updateServiceSku(state: State, payload: ServicePayload) {
  const { menuGroupId, serviceId, service } = payload;
  const { selectedGroupIndex, selectedServiceIndex } = getSelectedIndexes(state, {
    menuGroupId,
    serviceId,
  });

  return produce(state, (draftState) => {
    draftState.selectedGroups[selectedGroupIndex].services[selectedServiceIndex] = {
      ...draftState.selectedGroups[selectedGroupIndex].services[selectedServiceIndex],
      ...service,
    };
  });
}

function selectAllEmployees(state: State, payload: ServiceEditPayload) {
  const { selectedGroupIndex, selectedServiceIndex } = getSelectedIndexes(state, payload);
  const ids = getEmployeesCollectionIds({ employeesCollection: state.employeesCollection });

  return produce(state, (draftState: Draft<State>) => {
    draftState.selectedGroups[selectedGroupIndex].services[selectedServiceIndex].employees = ids;
  });
}

function deselectAllEmployees(state: State, payload: ServiceEditPayload) {
  const { selectedGroupIndex, selectedServiceIndex } = getSelectedIndexes(state, payload);

  return produce(state, (draftState: Draft<State>) => {
    draftState.selectedGroups[selectedGroupIndex].services[selectedServiceIndex].employees = [];
  });
}

function setError(state: State, payload: ErrorPayload) {
  const { errorType, serviceId } = payload;
  let errorStacksState = state;
  if (serviceId) {
    errorStacksState = produce(state, (draft: Draft<State>) => {
      if (errorType === ErrorType.NONE) {
        draft.errorStacks = draft.errorStacks.filter((item) => item.serviceId !== serviceId);
      } else {
        draft.errorStacks.push({
          serviceId,
          errorType,
        });
      }
    });
  }

  const producedState = produce(errorStacksState, (draft: Draft<State>) => {
    let passedErrorType: ErrorType = ErrorType.NONE;
    const hasMinorErrors = !!draft.errorStacks.filter(
      (item) => ![ErrorType.NONE, ErrorType.EMPLOYEES].includes(item.errorType),
    ).length;

    if (errorType === ErrorType.SERVER) {
      passedErrorType = ErrorType.SERVER;
    } else if (errorType === ErrorType.EMPLOYEES && !hasMinorErrors) {
      passedErrorType = ErrorType.EMPLOYEES;
    } else if (hasMinorErrors) {
      passedErrorType = ErrorType.MINOR;
    }

    draft.errorType = passedErrorType;
  });

  return producedState;
}

export const multipleServicesSelectReducer = reducer(
  initialState,
  on(actions.getMenuGroupTemplateSuccess, (state, { payload }) =>
    populateMultipleServices(state, payload),
  ),

  on(actions.addService, (state, { payload }) => addMultipleServiceItem(state, payload)),
  on(actions.removeService, (state, { payload }) => removeMultipleServiceItem(state, payload)),
  on(actions.updateSelectedService, (state, { payload }) => updateServiceSku(state, payload)),
  on(actions.setErrorType, (state, { payload }) => setError(state, payload)),

  on(actions.setMenuGroupServices, (state) => ({
    ...state,
    isSubmitting: true,
  })),
  on(actions.setMenuGroupServicesSuccess, (state) => ({
    ...state,
    isSubmitting: false,
    serverError: false,
  })),
  on(actions.setMenuGroupServicesFailure, (state) => ({
    ...state,
    isSubmitting: false,
    serverError: true,
    errorType: ErrorType.SERVER,
  })),

  on(actions.setEmployeesCollection, (state, payload) =>
    produce(state, (draft: Draft<State>) => {
      draft.employeesCollection = payload.payload;
    }),
  ),
  on(actions.selectAllEmployees, (state, { payload }) => selectAllEmployees(state, payload)),
  on(actions.deselectAllEmployees, (state, { payload }) => deselectAllEmployees(state, payload)),

  on(actions.resetSelectedService, () => initialState),
);
