;(function($) {
	/**
	 * Changes the action for this `.form-action .action` element.
	 * 
	 * @param string action
	 * 
	 * Copyright Wahanda.com
	 */
	$.fn.action = function(action) {
		removeActions(this);
		
		action = action || "default";
		this.addClass("action-" + action);
		return this;
	};
	
	/**
	 * Removes all action-* classNames from the given elements.
	 * 
	 * @param jQuery $list elements
	 */
	function removeActions($list) {
		$list.each(function() {
			var $node = $(this);
			var classes = $node.attr("class") || "";
			classes = $.trim(classes.replace(/action\-[^\s]+\s*/g, ''));
			$node.attr("class", classes);
		});
	}
}(jQuery));
