const DashboardReports = Backbone.Model.extend({
  initialize: function initialize({
    treatwellChannelSales,
    directChannelSales,
    widgetChannelSales,
    topServices,
    topEmployees,
    salesByMonth,
  }) {
    this.set({
      treatwellChannelSales,
      directChannelSales,
      widgetChannelSales,
      topServices,
      topEmployees,
      salesByMonth,
    });
  },
});

App.Models.DashboardReports = DashboardReports;
