import React from 'react';

import { L2Page } from 'common/tracking/navigation';
import { Verification } from 'components/common/Icon';
import { colors } from 'style/exports/colors';
import { SettingBox, Props as SettingProps, Analytics } from 'components/common/SettingBox';
import { FORM_TEMPLATE_STATUSES, FormTemplate } from './types';
import { slugify } from 'utilities/string';
import { useFormTemplatesEnableActions } from './useFormTemplatesEnableActions';
import Wahanda from 'common/wahanda';
import { ClientFormsUpdate } from '../ClientFormsCreate/ClientFormsUpdate';

const lang = Wahanda.lang.marketing.clientForms;

type FormTemplateBoxProps = {
  formTemplate: FormTemplate;
  onActionPerformed: () => void;
};

const getTrackingId = (formName: FormTemplate['title']) =>
  `setting-${slugify(formName)}-consent-forms`;

const getTestId = (formName: FormTemplate['title']) =>
  `${slugify(formName)}-consent-forms-setting-box`;

const getClientFormsUpdateSuccessMessage = (formTitle: string) =>
  Wahanda.Template.render(lang.updateSuccessMessage, { formTitle });

export const FormTemplateBox = ({ formTemplate }: FormTemplateBoxProps): JSX.Element => {
  const {
    enableSettingMutation,
    disableSettingMutation,
    isLoading,
  } = useFormTemplatesEnableActions({
    templateId: formTemplate.id,
  });

  const enableSetting = async () => {
    await enableSettingMutation();
  };

  const disableSetting = async () => {
    await disableSettingMutation();
  };

  const handleCreateFormClose = (onClose: () => void) => {
    function closeForm<T>(updatedForm?: T) {
      if (updatedForm === true)
        Wahanda.ToastNotifications.sendMessage(
          getClientFormsUpdateSuccessMessage(formTemplate.title),
        );
      onClose();
    }

    return closeForm;
  };

  const setting: SettingProps = {
    Analytics: Analytics(L2Page.CLIENT_FORMS),
    text: {
      title: formTemplate.title,
      description:
        formTemplate.numberOfServices > 1
          ? Wahanda.Template.render(lang.formLinkedToMultipleTreatments, {
              numberOfServices: formTemplate.numberOfServices,
            })
          : lang.formLinkedToSingleTreatment,
    },
    isSettingLoading: isLoading,
    isSettingEnabled: formTemplate.status === FORM_TEMPLATE_STATUSES.ENABLED,
    isEnableable: true,
    shouldAskBeforeDisabling: false,
    icon: Verification,
    iconBackground: colors.colorGrey50,
    trackingId: getTrackingId(formTemplate.title),
    dataTest: getTestId(formTemplate.title),
    advancedSettingsLabel: lang.editTreatments,
    AdvancedSettings: ({ onClose }) => (
      <ClientFormsUpdate onClose={handleCreateFormClose(onClose)} formTemplate={formTemplate} />
    ),
    allowSettingButtonWhenDisabled: true,
    callbacks: {
      enableSetting,
      disableSetting,
    },
  };

  return <SettingBox {...setting} />;
};
