import React from 'react';
import Wahanda from 'common/wahanda';
import { Ratings, FacebookLite, PersonAdd } from 'components/common/Icon';
import PATHS from 'components/marketing/Router/paths';
import { ActionBox } from './ActionBox';
import { ReviewsAnalytics } from '../tracking';
import style from './ZeroToFive.scss';

const LANG = Wahanda.lang.dashboard.reviews.zeroToFive;

export class ZeroToFive extends React.PureComponent {
  private renderActions = () => {
    return (
      <div className={style.actions}>
        <ActionBox
          dataTest="request-for-review-action"
          label={LANG.actions.reviews.label}
          sublabel={LANG.actions.reviews.sublabel}
          Icon={Ratings}
          onClick={this.handleRequestForReviewClick}
        />
        <ActionBox
          dataTest="fbe-integration-action"
          label={LANG.actions.fbe.label}
          sublabel={LANG.actions.fbe.sublabel}
          Icon={FacebookLite}
          onClick={this.handleFbeClick}
        />
        <ActionBox
          dataTest="clients-import-action"
          label={LANG.actions.clientsImport.label}
          sublabel={LANG.actions.clientsImport.sublabel}
          Icon={PersonAdd}
          onClick={this.handleClientsImportClick}
        />
      </div>
    );
  };

  private handleClientsImportClick = () => {
    ReviewsAnalytics.trackZeroToFiveClientsImportClicked();
    window.location.assign(Wahanda.Url.getFullUrl('clients?startImport=true'));
  };

  private handleFbeClick = () => {
    ReviewsAnalytics.trackZeroToFiveFbeClicked();
    window.location.assign(Wahanda.Url.getFullUrl('marketing', PATHS.FBE));
  };

  private handleRequestForReviewClick = () => {
    window.open(LANG.helpLink, '_blank');
    ReviewsAnalytics.trackZeroToFiveRequestForReviewClicked();
  };

  public render() {
    return (
      <div className={style.container}>
        <h1 className={style.title} data-test="title">
          {LANG.title}
        </h1>
        <p className={style.description} data-test="description">
          {LANG.description}
        </p>
        {this.renderActions()}
      </div>
    );
  }
}
