import { reducer, on } from 'ts-action';
import { setFeatures } from './actions';

interface State {
  features: string[];
}

const initialState: State = {
  features: [],
};

export const trunkFeaturesReducer = reducer(
  initialState,
  on(setFeatures, (_, { payload }) => {
    return {
      features: payload.features,
    };
  }),
);
