export const CLEANUP_TIME_SLOTS = [null, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

export const ZERO_TIME_SLOT = 0;

export const PROCESSING_TYPE = 'processing';

export const CLEANUP_TYPE = 'cleanup';

export const PROCESSING_TIME_DEFAULT = 30;

export const PROCESSING_TIME_SLOTS = [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 70, 80, 90];

export const FINISHING_TIME_DEFAULT = 30;

export const FINISHING_TIME_SLOTS = [
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
  105,
  120,
  150,
];

export const FINISHING_TIME_SLOTS_WITH_NULL = [null, ...FINISHING_TIME_SLOTS];
