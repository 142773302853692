import { send as sendWSCommand, onStateChange, listen, stopListening } from 'common/websockets';
import { LISTEN_CALENDAR } from 'common/websockets/commands';
import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import _ from 'common/underscore';
import { hasChanges } from '../refresh-handler';
import DEBOUNCE_TIME from './config';

const EVENTS = ['appointment-event', 'availability-event'];

export default function setup(calendarView) {
  let connected = false;
  // The queue of Appointments and Blocks to notify the Calendar about.
  // We queue the data to not trigger refreshes too often, as we receive multiple events
  // for Appointment Groups, etc.
  const queue = {
    venueId: null,
    changes: {},
    length: 0,
  };

  function pushToQueue(type, data) {
    const currentVenueId = App.getVenueId();

    if (data.venueId !== currentVenueId) {
      return;
    }
    if (queue.venueId !== currentVenueId) {
      queue.venueId = currentVenueId;
      queue.changes = {};
      queue.length = 0;
    }
    if (!queue.changes[type]) {
      queue.changes[type] = [];
    }
    queue.changes[type].push(data);
    queue.length += 1;
  }

  function checkNotAllEventsOurs() {
    const apptChanges = queue.changes['appointment-event'] || [];
    const blockChanges = queue.changes['availability-event'] || [];

    const ownAccountId = App.config.getAccountId();
    const otherAccountFilter = (item) => item.accountId !== ownAccountId;

    // First do a simple check - if there are any other accounts than own
    // who's actions have lead to the WebSocket messages being received.
    if (apptChanges.some(otherAccountFilter) || blockChanges.some(otherAccountFilter)) {
      return true;
    }

    const apptIds = apptChanges.map((data) => data.appointmentId);
    const availabilityIds = blockChanges.map((data) => data.availabilityRuleId);

    // As a second check, check if all notifications aren't due to own actions.
    return hasChanges(apptIds, availabilityIds);
  }

  // Notify the Calendar about changes DEBOUNCE_TIME seconds after the events finish flowing in.
  const notifyAboutChangesDebounced = _.debounce(() => {
    if (queue.venueId === App.getVenueId() && queue.length > 0 && checkNotAllEventsOurs()) {
      calendarView.onCalendarChangeNotification(queue.changes);
    }
    queue.changes = {};
    queue.length = 0;
  }, DEBOUNCE_TIME);

  function handleCalendarMessage(data, type) {
    pushToQueue(type, data);
    notifyAboutChangesDebounced();
  }

  function removeCalendarListeners() {
    EVENTS.forEach((event) => stopListening(event, handleCalendarMessage));
  }

  function setupCalendarLiseners() {
    sendWSCommand(LISTEN_CALENDAR, { venueId: App.getVenueId() });

    EVENTS.forEach((event) => listen(event, handleCalendarMessage));
  }

  function onConnected() {
    connected = true;
    setupCalendarLiseners();
    // We also re-fetch the whole calendar data list, as we currently don't have a way
    // of checking if any updates have come in during the [initialLoad...WebSocketsConnected]
    // period.
    calendarView.onWebSocketsConnected();
  }

  function onDisconnected() {
    removeCalendarListeners();
    connected = false;
  }

  onStateChange(onConnected, onDisconnected);

  App.on(Wahanda.Event.APP_VENUE_CHANGED, () => {
    removeCalendarListeners();

    if (connected) {
      setupCalendarLiseners();
    }
  });
}
