/**
 * Base for pricing and pricing dates editing.
 */
BackboneEx.View.Dialog.ValidatingForm.PricingBase = BackboneEx.View.Dialog.ValidatingForm.extend({
	
	setValidation: function() {
		this._setDefaultValidation();
	},
	
	save: function() {
		var result   = this.options.saveCallback(this.getChildViewValues());
		if (result == null || result.valid === true) {
			this.resetCloseChangesCheck();
			this.close();
		} else if (result.fixableIntersection) {
			// Show question tooltip and forward action to the one after button is clicked
			// result.saveCallback
			this._intersectionActionTooltip(result.saveCallback);
		} else {
			// Error is final.
			this.$('.a-save').noticeTip(
				result.errorText
			);
		}
	},
	
	getChildViewValues: function() {
		var dataView = (this.datesView || this.editView);
		return dataView.getValues();
	},
	
	_intersectionActionTooltip: function(saveCallback) {
		var self = this;
		var values = this.getChildViewValues();
		var $tooltipButton = this.$('.a-save');
		var $tipContent = Wahanda.Template.renderTemplate(
			'menu-offer-pricing-intersection-question',
			{
				startDate : Wahanda.Date.formatToDefaultDate(values.dateFrom),
				finishDate: Wahanda.Date.formatToDefaultDate(values.dateTo),
			}
		);
		$tipContent = jQuery($tipContent);
		
		this.disableForm();
		
		$tooltipButton.action('doing');
		var tooltip = $tooltipButton.qtip({
			content: $tipContent,
			style: {
				classes: "dialog-tooltip clarification-dialog"
			},
			position: {
				my: "bottom center",
				at: "top center"
			},
			show: {
				event: false,
				ready: true
			},
			hide: {
				event: false,
				fixed: true
			}
		});
		
		$tipContent
			.find('.a-stop').on('click', function() {
				tooltip.qtip('destroy');
				saveCallback(true);
				self.close();
			}).end()
			.find('.a-split').on('click', function() {
				tooltip.qtip('destroy');
				saveCallback(false);
				self.close();
			}).end()
			.find('.button-cancel').on('click', function() {
				tooltip.qtip('destroy');
				self.enableForm();
				$tooltipButton.action();
			});
	}
	
});
