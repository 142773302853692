/* global _ */
/* eslint-disable no-continue */
import { trackEvent } from 'common/analytics';

(function iffe() {
  // eslint-disable-next-line no-multi-assign
  const Form = (App.Views.Settings.OnlineBookingSettings = App.Views.Form.extend({
    events: {
      'change #booking-settings-onlineBookingInterval': 'onIntervalChange',
      'change #booking-settings-appointmentMorningEndTime': 'onAppointmentSplitTimeChange',
      'change #booking-settings-appointmentAfternoonEndTime': 'onAppointmentSplitTimeChange',
    },
    // eslint-disable-next-line no-useless-escape
    fieldPrefix: /^booking\-settings\-/,

    initialize: function initialize() {
      const self = this;
      this.populateFields();
      this.setValidation();

      this.$morningEnd = this.$('#booking-settings-appointmentMorningEndTime');
      this.$afternoonEnd = this.$('#booking-settings-appointmentAfternoonEndTime');
      this.$('.b-for-yield-discount').wToggle(App.isFeatureSupported('yield-discount'));

      this.model.on('fetched', () => {
        self.render();
      });
    },

    render: function render() {
      this.renderAppointmentMorningEndTimeOptions();
      this.renderAppointmentAfternoonEndTimeOptions();
      this.appointmentSplitsAvoidOverlap(this.$morningEnd);
      this.populateCancellationHours();

      this.renderScales();
      this.renderBookingMinHours();
      this.populateFromModel();

      this.$el.tooltipize({
        position: {
          container: this.$el.find('.data-content'),
          viewport: true,
        },
      });
    },

    populateCancellationHours: function (modelKey) {
      var $select = this.$('#appointment-cancellationPolicy');
      var cancellationHours = this.model.getCancellationHours(modelKey);
      var cancellationHoursObject = [];
      var currentCancellation = this.model.get(modelKey);
      var duration = Wahanda.lang.datetime.duration;
      _.each(cancellationHours, function (hour) {
        var title;
        if (hour === -1) {
          title = Wahanda.lang.settings.venue.policies.noCancellations;
        } else {
          hour == 1 ? (title = hour + ' ' + duration.hour) : (title = hour + ' ' + duration.hours);
        }

        cancellationHoursObject.push({
          title: title,
          value: String(hour),
          selected: hour === currentCancellation,
        });
      });

      $select.html(
        Wahanda.Template.renderTemplate('form-select-items', {
          options: cancellationHoursObject,
        }),
      );
    },

    populateFromModel: function populateFromModel() {
      this.fillFormFromModel(
        {
          onlineBookingMaxDays: 30,
          leadTimeMinutes: 48,
          allowMultiEmployeeFulfillment: !this.model.get('blockMultiEmployeeFulfillment'),
        },
        ['appointmentMorningEndTime', 'appointmentAfternoonEndTime'],
      );
      this.$('#booking-settings-leadTimeMinutes').selectNearestDuration(
        this.model.get('leadTimeMinutes'),
      );
      this.$('#policies-onlineBookingMaxDays').attr(
        'max',
        App.config.get('venue').appointmentMaxAvailabilityPeriodDaysLimit,
      );
    },

    renderAppointmentMorningEndTimeOptions: function renderAppointmentMorningEndTimeOptions() {
      let defaultTime = this.model.get('appointmentMorningEndTime');
      const openingHoursRange = this.model.getOpeningHoursRange();
      let defaultMinutes = Wahanda.Time.timeToMinutes(defaultTime);

      if (!openingHoursRange.containsMinutes(defaultMinutes)) {
        defaultMinutes = openingHoursRange.openingMinutes + 60;
      }
      defaultMinutes = Math.min(defaultMinutes, openingHoursRange.closingMinutes);
      defaultTime = Wahanda.Time.toApiString(defaultMinutes);

      this.renderSplitTimeOptions(this.$morningEnd, defaultTime);
    },

    renderAppointmentAfternoonEndTimeOptions: function renderAppointmentAfternoonEndTimeOptions() {
      let defaultTime = this.model.get('appointmentAfternoonEndTime');
      const openingHoursRange = this.model.getOpeningHoursRange();
      let defaultMinutes = Wahanda.Time.timeToMinutes(defaultTime);

      if (!openingHoursRange.containsMinutes(defaultMinutes)) {
        defaultMinutes = openingHoursRange.closingMinutes - 60;
      }
      defaultMinutes = Math.max(defaultMinutes, openingHoursRange.openingMinutes);
      defaultTime = Wahanda.Time.toApiString(defaultMinutes);

      this.renderSplitTimeOptions(this.$afternoonEnd, defaultTime);
    },

    renderSplitTimeOptions: function renderSplitTimeOptions($split, defaultTime) {
      const openingHoursRange = this.model.getOpeningHoursRange();
      const step = App.config.get('venue').appointmentSlotDuration;
      const options = Wahanda.Time.getTimeFieldValues(
        openingHoursRange.openingMinutes,
        openingHoursRange.closingMinutes,
        step,
        App.config.get('jqueryDateFormat').defaultTime,
        Wahanda.Time.getNearestSelectFunc(defaultTime, step, openingHoursRange.openingMinutes),
      );

      $split.html(
        Wahanda.Template.renderTemplate('form-select-items', {
          options: options,
        }),
      );
    },

    populateFields: function populateFields() {
      this.renderScales();
      this.renderBookingTimeInterval();
    },

    save: function save() {
      trackEvent('online-booking-settings', 'submit', 'save-online-booking');
      this.model.setNextSaveRelations(['onlineBookingInterval']);
      this.model.setAttributesToSave([
        'appointmentSlotType',
        'onlineBookingInterval',
        'onlineBookingMaxDays',
        'leadTimeMinutes',
        'spaDayLeadTimeMinutes',
        'overnightStayLeadTimeMinutes',
        'appointmentMorningEndTime',
        'appointmentAfternoonEndTime',
        'blockMultiEmployeeFulfillment',
        'appointmentsCancellationPolicyHours',
      ]);
      this.model.set(this.getFormValues());

      if (
        this.model.get('appointmentMorningEndTime') ===
        this.model.get('appointmentAfternoonEndTime')
      ) {
        const menuGroupModel = new App.Models.MenuGroup();
        Wahanda.Cache.menu().done((menu) => {
          // TODO: We need to reenable this for the new discounting rules
          menuGroupModel.set('groupsCollection', menu.get('groupsCollection'));

          // menuGroupModel.resetOffPeakDiscount();
        });
      }
      // eslint-disable-next-line no-underscore-dangle
      this._defaultSaveAction();
    },

    getFormValues: function getFormValues() {
      // eslint-disable-next-line no-underscore-dangle
      const values = Form.__super__.getFormValues.call(this);

      if (values.onlineBookingInterval === '0') {
        values.onlineBookingInterval = null;
      }
      // Reverse the value for better UX
      values.blockMultiEmployeeFulfillment = !values.allowMultiEmployeeFulfillment;
      delete values.allowMultiEmployeeFulfillment;

      return values;
    },

    _modelValuesHaventChanged: function _modelValuesHaventChanged() {
      return true;
    },

    _afterSaveCallback: function _afterSaveCallback() {
      this.renderBookingMinHours();
      this.populateFromModel();
    },

    renderScales: function renderScales() {
      const times = App.referenceData.get('appointmentSlotType');
      const currentScale = this.model.get('appointmentSlotType');

      const scales = times.map((time) => ({
        title: time.title,
        value: time.id,
        selected: currentScale === time.id,
      }));

      this.$('#booking-settings-appointmentSlotType')
        .empty()
        .append(
          Wahanda.Template.renderTemplate('form-select-items', {
            options: scales,
          }),
        );
    },

    renderBookingTimeInterval: function renderBookingTimeInterval() {
      const times = App.referenceData.get('onlineBookingInterval');
      const lang = Wahanda.lang.settings.onlineBooking;

      const data = [
        {
          title: lang.values.bookingTimeDefault,
          value: '0',
        },
      ];

      _.each(times, (time) => {
        data.push({
          title: lang.times[`time${time.id}`] || Wahanda.Time.getDurationFull(time.id),
          value: time.id,
        });
      });

      this.$('#booking-settings-onlineBookingInterval')
        .empty()
        .append(
          Wahanda.Template.renderTemplate('form-select-items', {
            options: data,
          }),
        );
    },

    renderBookingMinHours: function renderBookingMinHours() {
      const min = App.referenceData.get('appointment-min-availability-period') || 1;
      const hoursInDay = 24;
      const hoursInWeek = hoursInDay * 7;

      // Max selectable elements
      const maxDays = 5;
      const maxDaysHoursFragment = 2;
      const maxWeeks = 4;
      const minutes = [30];
      const hours = [1, 2, 4, 8];
      const duration = Wahanda.lang.datetime.duration;
      const $fragmentWithHours = $(document.createDocumentFragment());
      const $fragmentMinimumDays = $(document.createDocumentFragment());
      let i;
      let title;
      const currentModelValue = this.model.get('leadTimeMinutes');
      const currentModelSpaDayValue = this.model.get('spaDayLeadTimeMinutes');
      const currentModelOvernightValue = this.model.get('overnightStayLeadTimeMinutes');
      const daysAndWeekList = new Map([[0, Wahanda.lang.settings.venue.policies.noCloseOut]]);

      // No close out
      const $noCloseOutOption = $('<option value="0">').text(
        Wahanda.lang.settings.venue.policies.noCloseOut,
      );
      $fragmentWithHours.append($noCloseOutOption.clone());
      $fragmentMinimumDays.append($noCloseOutOption);

      function appendToBookingMinHours(hour) {
        $fragmentWithHours.append(
          $('<option>', { value: hour * 60 }).text(
            `${hour} ${(hour === 1 ? duration.hour : duration.hours).toLowerCase()}`,
          ),
        );
      }

      function appendToBookingMinMinutes(mins) {
        $fragmentWithHours.append(
          $('<option>', { value: minutes }).text(
            `${mins} ${(mins === 1 ? duration.minute : duration.minutes).toLowerCase()}`,
          ),
        );
      }

      // Minutes
      for (i = 0; i < minutes.length; i += 1) {
        const previousMinutes = i > 0 ? minutes[i - 1] : 0;
        const minute = minutes[i];
        if (minute < min) {
          continue;
        }

        // Check for value out of the currently supported range.
        // If we have a custom value set, allow it, render it. But only if it's set.
        // After it will be changed to something else, it will be removed.
        if (currentModelValue > previousMinutes && currentModelValue < minute) {
          appendToBookingMinMinutes(currentModelValue);
        }
        appendToBookingMinMinutes(minute);
      }

      // Hours
      for (i = 0; i < hours.length; i += 1) {
        const previousHour = i > 0 ? hours[i - 1] : 1;
        const hour = hours[i];
        if (hour < min) {
          continue;
        }

        const currentModelValueHours = minutes / 60;

        // Check for value out of the currently supported range.
        // If we have a custom value set, allow it, render it. But only if it's set.
        // After it will be changed to something else, it will be removed.
        if (currentModelValueHours > previousHour && currentModelValueHours < hour) {
          appendToBookingMinHours(currentModelValueHours);
        }
        appendToBookingMinHours(hour);
      }

      // Days
      for (i = Math.max(min, hoursInDay); i <= maxDays * hoursInDay; i += hoursInDay) {
        const day = parseInt(i / hoursInDay, 10);
        if (day === 4) {
          // we're intentionally leaving "4 days" out as it's an unlikely value, according to Darius
          continue;
        }
        title = `${day} ${(parseInt(day, 10) === 1 ? duration.day : duration.days).toLowerCase()}`;
        if (day <= maxDaysHoursFragment) {
          $fragmentWithHours.append($('<option>', { value: i * 60 }).text(title));
          daysAndWeekList.set(i, title);
        }
        $fragmentMinimumDays.append($('<option>', { value: i * 60 }).text(title));
        daysAndWeekList.set(i, title);
      }

      // Weeks
      for (i = Math.max(min, hoursInWeek); i <= maxWeeks * hoursInWeek; i += hoursInWeek) {
        const week = parseInt(i / hoursInWeek, 10);
        title = `${week} ${(parseInt(week, 10) === 1
          ? duration.week
          : duration.weeks
        ).toLowerCase()}`;
        $fragmentMinimumDays.append($('<option>', { value: i * 60 }).text(title));
        daysAndWeekList.set(i, title);
      }

      function hasOptionWithValue($list) {
        return _.any(
          $list.children(),
          (option) => parseInt(option.value, 10) === parseInt(currentModelValue, 10),
        );
      }

      // If we can't find our custom hour set (data preservation of previous custom values),
      // add it at the end of the list, as Hours.
      if (currentModelValue && !hasOptionWithValue($fragmentWithHours)) {
        appendToBookingMinHours(currentModelValue);
      }

      if (App.isMasquerading()) {
        this.$('#booking-settings-leadTimeMinutes').empty().append($fragmentWithHours);
        this.$('#booking-settings-spaDayLeadTimeMinutes')
          .empty()
          .append($fragmentMinimumDays.clone());
        this.$('#booking-settings-overnightStayLeadTimeMinutes')
          .empty()
          .append($fragmentMinimumDays);

        this.$('#booking-settings-leadTimeMinutes-tr-unchangeable').hide();
      } else {
        const bookingLeadTimeMinutes = currentModelValue / 60;
        // eslint-disable-next-line no-nested-ternary
        const bookingLeadTime =
          bookingLeadTimeMinutes >= 1
            ? currentModelValue / 60 === 0
              ? Wahanda.lang.settings.venue.policies.noCloseOut
              : `${currentModelValue / 60} ${(currentModelValue / 60 === 1
                  ? duration.hour
                  : duration.hours
                ).toLowerCase()}`
            : currentModelValue === 0
            ? Wahanda.lang.settings.venue.policies.noCloseOut
            : `${currentModelValue} ${(currentModelValue === 1
                ? duration.minute
                : duration.minutes
              ).toLowerCase()}`;

        this.$('#booking-settings-leadTimeMinutesUnchangeable').replaceWith(
          `<span id="booking-settings-leadTimeMinutesUnchangeable">${bookingLeadTime}</span>`,
        );

        const spaDayHours = daysAndWeekList.get(currentModelSpaDayValue / 60);
        this.$('#booking-settings-spaDayLeadTimeMinutesUnchangeable').replaceWith(
          `<span id="booking-settings-spaDayLeadTimeMinutesUnchangeable">${spaDayHours}</span>`,
        );

        const overnightHours = daysAndWeekList.get(currentModelOvernightValue / 60);
        this.$('#booking-settings-overnightStayLeadTimeMinutesUnchangeable').replaceWith(
          `<span id="booking-settings-overnightStayLeadTimeMinutesUnchangeable">${overnightHours}</span>`,
        );

        this.$('#booking-settings-leadTimeMinutes-tr').hide();
      }
    },

    /** Events */

    onIntervalChange: function onIntervalChange(event) {
      const value = parseInt($(event.currentTarget).val(), 10);
      let slotType;
      if (!value) {
        return;
      }

      switch (true) {
        // Skip 1 hour item. Others must hatch.
        case value !== 60 && value % 60 === 0:
          slotType = '1HR';
          break;

        // 1 hour inslusive.
        case value % 30 === 0:
          slotType = '30M';
          break;

        // 40 minutes -> 20 minutes
        case value === 40:
          slotType = '20M';
          break;

        default:
          slotType = '15M';
      }

      this.$('#booking-settings-appointmentSlotType').val(slotType);
    },

    onAppointmentSplitTimeChange: function onAppointmentSplitTimeChange(event) {
      this.appointmentSplitsAvoidOverlap($(event.currentTarget));
    },

    /**
     * Ensure morningEnd is not later than afternoonEnd, or v.v.
     * If necessary, adjust the select to not overlap the just-changed select.
     */
    appointmentSplitsAvoidOverlap: function appointmentSplitsAvoidOverlap($split) {
      const isMorningEndChanged = $split.is(this.$morningEnd);
      const $otherSplit = isMorningEndChanged ? this.$afternoonEnd : this.$morningEnd;

      const value = $split.val();
      const otherValue = $otherSplit.val();

      const minutes = Wahanda.Time.timeToMinutes(value);
      const otherMinutes = Wahanda.Time.timeToMinutes(otherValue);
      const adjustOther = isMorningEndChanged ? otherMinutes < minutes : otherMinutes > minutes;

      if (adjustOther) {
        $otherSplit.val(value);
      }
    },
  }));
})();
