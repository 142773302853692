import React from 'react';
import classnames from 'classnames';

import style from './ChangeYearButton.scss';

export interface Props {
  className?: string;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
}

export class ChangeYearButton extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  public render() {
    const { className, children, disabled, selected } = this.props;
    const styles = classnames(className, style.button, {
      [style.active]: selected,
    });
    return (
      <button className={classnames(styles)} onClick={this.onClick} disabled={disabled}>
        {children}
      </button>
    );
  }

  private onClick() {
    const { disabled, onClick } = this.props;
    if (disabled || onClick == null) {
      return;
    }

    onClick();
  }
}
