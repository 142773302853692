import React, { useState } from 'react';

import { TabsProps } from './types';
import { TabItem } from './TabItem';
import style from './Tabs.scss';
import { Tooltip, TooltipPlacement } from 'components/common/Tooltip';

export const Tabs = ({ tabs, defaultTabIndex = 0, onChange = () => {} }: TabsProps) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(defaultTabIndex);

  const handleTabClick = (index) => {
    setCurrentTabIndex(index);
    onChange(index);
  };

  return (
    <div>
      <div className={style.tabs}>
        {tabs.map((tab, index) => {
          const isSelected = index === currentTabIndex;

          const tabItem = (
            <TabItem
              key={index}
              isSelected={isSelected}
              tab={tab}
              onClick={() => handleTabClick(index)}
            />
          );

          return tab.tooltip ? (
            <Tooltip key={index} tooltip={tab.tooltip} placement={TooltipPlacement.top}>
              {tabItem}
            </Tooltip>
          ) : (
            tabItem
          );
        })}
      </div>
      <div>{tabs[currentTabIndex].content}</div>
    </div>
  );
};
