import React from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';

import { Mail, Facebook, Twitter } from 'components/common/Icon';
import InfoIcon from 'components/common/icons/Info';
import { LayoutHeader } from 'components/common/Layout';
import { List } from 'components/common/List';

import { ClientAppAnalytics as Analytics } from '../tracking';
import ShareButton from '../Button';
import { CopyToClipboard } from '../CopyToClipboard';

import style from './Description.scss';

const lang = Wahanda.lang.marketing.clientApp;

interface Props {
  twitterUri: string;
  facebookUri: string;
  clipboardUri: string;
  isEmailCampaignsAccessible: boolean;
  isInfoBlockVisible: boolean;
  onLaunchCampaignClick: () => void;
}

export const Description = ({
  twitterUri,
  facebookUri,
  clipboardUri,
  isEmailCampaignsAccessible,
  isInfoBlockVisible,
  onLaunchCampaignClick,
}: Props) => {
  return (
    <div>
      <LayoutHeader header={lang.heading} subheader={lang.subheading} />
      <div className={classnames(style.spacedBlock)}>
        <List type="ticksGreen" extraSpacing>
          {[lang.actionPoint1, lang.actionPoint2, lang.actionPoint3]}
        </List>
      </div>
      <p className={classnames(style.subheading, style.spacedBlock, style.withSeparator)}>
        {lang.repeatCustomers}{' '}
        <a
          href={lang.helpCenterUrl}
          onClick={Analytics.trackHelpCenter}
          target="_blank"
          rel="noopener noreferrer"
        >
          {lang.helpCenter}
        </a>
      </p>

      <h3 className={classnames(style.spacedBlock, style.smallHeading)}>{lang.soundGood}</h3>
      <p className={style.spacedBlock}>{lang.shareNow}</p>
      {isInfoBlockVisible && (
        <div className={classnames(style.info, style.spacedBlock)}>
          <div className={style.infoIcon}>
            <InfoIcon />
          </div>
          <p dangerouslySetInnerHTML={{ __html: lang.info }} />
        </div>
      )}
      <div className={classnames(style.buttonWrapper, style.spacedBlock)}>
        <FacebookShareButton
          beforeOnClick={Analytics.trackFacebook}
          url={facebookUri}
          quote={Wahanda.Template.render(lang.sharedText, {
            link: facebookUri,
          })}
        >
          <ShareButton className="facebook">
            <Facebook className={style.whiteFill} />
            <span>Facebook</span>
          </ShareButton>
        </FacebookShareButton>
        <TwitterShareButton
          beforeOnClick={Analytics.trackTwitter}
          url={twitterUri}
          title={Wahanda.Template.render(lang.sharedText, { link: '' })}
        >
          <ShareButton className="twitter">
            <Twitter className={style.whiteFill} />
            <span>Twitter</span>
          </ShareButton>
        </TwitterShareButton>
        {isEmailCampaignsAccessible && (
          <ShareButton className="launchCampaign" onClick={onLaunchCampaignClick}>
            <Mail className={style.whiteFill} />
            <span>{lang.buttons.launchCampaign}</span>
          </ShareButton>
        )}
      </div>
      <div className={style.copyLink}>
        <span className={style.copyLinkLabel}>{lang.copyLinkLabel}</span>
      </div>
      <CopyToClipboard stringToCopy={clipboardUri} onAfterCopy={Analytics.trackClipboard} />
    </div>
  );
};
