import React from 'react';
import style from './BaseSection.scss';

type BaseSectionProps = {
  icon: React.ReactNode;
};

const BaseSection: React.SFC<BaseSectionProps> = ({ children, icon }) => (
  <section className={style.section}>
    <div className={style.icon}>{icon}</div>
    <div className={style.content}>{children}</div>
  </section>
);

export default BaseSection;
