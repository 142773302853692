/* eslint-disable func-names */
import { xhr } from 'common/xhr';
import * as appointmentStatus from 'common/appointment';

App.Collections.CustomerAppointments = BackboneEx.Collection.Base.extend(
  {
    // The filter parameters
    date: null,
    customerId: null,
    customAppointmentStatuses: null,

    model: App.Models.Appointment,
    // Used for sorting
    employeeCollection: null,
    appointmentGroups: null,
    lastSyncHadChanges: false,

    initialize(...args) {
      BackboneEx.Collection.Base.prototype.initialize.apply(this, args);
      this.customAppointmentStatuses = [];
      this.appointmentGroups = [];
      // Set the employee collection so that we can sort correctly.
      this.fetchEmployees();
    },

    url() {
      let statuses = ['CN', 'CP', 'NS', 'CR'];

      if (this.customAppointmentStatuses) {
        statuses = statuses.concat(this.customAppointmentStatuses);
      }
      const base =
        `${
          '/calendar.json?' +
          'include=appointments,appointment-evouchers,checkouts' +
          '&appointment-status-codes='
        }${statuses.join(',')}&date-from={{date}}` +
        `&date-to={{date}}` +
        `&venue-customer-id={{customerId}}` +
        `{{#utmSource}}&utm_source={{utmSource}}{{/utmSource}}`;
      const urlParams = {
        date: this.date,
        customerId: this.customerId,
        utmSource: this.utmSource,
      };
      // Rendering the final URL
      return App.Api.wsVenueUrl(Wahanda.Template.render(base, urlParams));
    },

    checkAppointmentGroupCorrectDates(appointmentGroup) {
      if (!this.AppointmentGroupState) {
        this.appointmentGroupState = {};
      }
      if (!this.appointmentGroupState[appointmentGroup.id]) {
        this.appointmentGroupState[appointmentGroup.id] = 0;
      }
      // Fetch package with id...
      const self = this;
      if (this.appointmentGroupState[appointmentGroup.id] > 5) {
        return;
      }

      const correctDate = appointmentGroup.appointmentDate;
      const appointmentGroupSetUpCorrectly = appointmentGroup.appointments.every((appointment) => {
        return appointment.appointmentDate === correctDate;
      });

      if (!appointmentGroupSetUpCorrectly) {
        console.log(
          `Please refresh and check times and assigned employees of "${appointmentGroup.name}" appointment group, id:"${appointmentGroup.id}" are correct, as all appontments moved in group to ${correctDate}`,
        );

        // Save and check again
        const appointmentGroupCollection = new App.Collections.AppointmentGroup();
        appointmentGroupCollection.id = appointmentGroup.id;
        appointmentGroupCollection.fetch().done(function (data) {
          appointmentGroupCollection.correctDates(correctDate);
          appointmentGroupCollection.save().done(() => {
            window.setTimeout(() => self.checkAppointmentGroupCorrectDates(data), 4000);
          });
          self.appointmentGroupState[appointmentGroup.id] += 1;
        });
      }
    },

    /**
     * Override the default `reset` function to split off appointments from groups.
     *
     * @param data
     */
    reset(resetData) {
      const self = this;
      const data = resetData || {};
      // Setup the appointments as this Collection's models
      BackboneEx.Collection.Base.prototype.reset.call(this, data.appointments);

      this.stopListening(null, 'reset');

      // Setup any groups
      this.appointmentGroups = [];
      _.each(data.appointmentGroups, function (group) {
        // Check group dates are all on the same day...(for masq'd users only)
        // TODO: fix package correction (see DEV-58815)
        // if (App.isMasquerading()) {
        //   self.checkAppointmentGroupCorrectDates(group);
        // }

        self.appointmentGroups.push(
          new App.Collections.AppointmentGroup(
            _.map(group.appointmentIds, function (id) {
              return self.get(id);
            }),
            group,
          ),
        );
      });

      // When the ApptGroup collection is fetched, take appointments from it and
      // renew this collection's models.
      _.each(
        this.appointmentGroups,
        function (apptGroup) {
          this.listenTo(apptGroup, 'reset', this.onApptGroupReset);
        },
        this,
      );

      return this;
    },

    // Overriding the `sync` method to not trigger `reset()` on fetches which don't change
    // what the collection has inside already.
    sync(method, self, options) {
      if (method !== 'read') {
        return Backbone.sync.call(this, method, self, options);
      }

      // It's a read. We must check if the fetched results differ to call a reset() on the collection.
      const url = this.url();

      function onDone(data) {
        // options.success
        const isChanged = _.any(
          data.appointments,
          function (apptData) {
            const oldAppt = this.get(apptData.id);

            return !oldAppt || !oldAppt.isEqualTo(apptData);
          }.bind(this),
        );

        if (isChanged) {
          // eslint-disable-next-line prefer-rest-params
          options.success.apply(null, arguments);
        }
        this.lastSyncHadChanges = isChanged;
      }

      return xhr.getWithPromise(url).done(onDone.bind(this)).fail(options.error);
    },

    cloneWithStatuses(acceptedStatuses) {
      const appts = this.filter(function (appt) {
        return appt.id > 0 && _.indexOf(acceptedStatuses, appt.get('appointmentStatusCode') > -1);
      });

      const clone = new App.Collections.CustomerAppointments();
      clone.date = this.date;
      clone.customerId = this.customerId;
      clone.utmSource = this.utmSource;

      const addedGroupIds = [];

      _.each(appts, function (appt) {
        if (appt.belongsToGroup()) {
          const pkg = appt.getLinkedPackageGroup();

          [pkg].forEach(function (group) {
            if (group && _.indexOf(addedGroupIds, group.id) === -1) {
              clone.addGroupCollection(group.createNew());
              addedGroupIds.push(group.id);
            }
          });
        } else {
          clone.add(appt.createNew());
        }
      });

      return clone;
    },

    onApptGroupReset(apptGroup) {
      apptGroup.each(function (appt) {
        this.remove(appt.id, { silent: true });
        this.add(appt, { silent: true });
      }, this);
    },

    loadFromOrder(order) {
      BackboneEx.Collection.Base.prototype.reset.call(this, order.getAppointments());

      this.appointmentGroups = order.getPackageGroup();
      this.customerId = order.getCustomerId();
      this.date = this.at(0).get('appointmentDate');
      this.isWalkIn = this.at(0).get('isWalkIn');
    },

    /**
     * Add all models from and the AppointmentGroup itself to this collection.
     *
     * @param apptGroup
     *
     * @returns self
     */
    addGroupCollection(apptGroup) {
      if (_.indexOf(this.appointmentGroups, apptGroup) === -1) {
        this.add(apptGroup.models);
        this.appointmentGroups.push(apptGroup);
      }
      return this;
    },

    fetchEmployees() {
      const self = this;
      Wahanda.Cache.employees().done(function (employees) {
        self.employeeCollection = employees;
      });
    },

    /**
     * Compare two models by date.
     *
     * @param App.Models.Appointment a
     * @param App.Models.Appointment b
     * @returns {number}
     */
    comparator(a, b) {
      let valA = toTime(a);
      let valB = toTime(b);

      if (valA === valB && this.employeeCollection) {
        // Start time is the same. Sort by employee order.
        valA = this.employeeCollection.getIndex(a.get('employeeId'));
        valB = this.employeeCollection.getIndex(b.get('employeeId'));
      }

      return valA - valB;

      function toTime(model) {
        return parseInt(model.get('startTime').replace(':', ''), 10);
      }
    },

    isSortOrderChanged() {
      const self = this;
      let prevModel = null;
      // eslint-disable-next-line consistent-return
      return this.any(function (model) {
        if (prevModel) {
          return self.comparator(prevModel, model) >= 0;
        }
        prevModel = model;
      });
    },

    getUnsavedAppointmentCount() {
      return this.reduce(function (sum, model) {
        return sum + (model.id ? 0 : 1);
      }, 0);
    },

    getAppointmentGroup(groupId) {
      return _.find(this.appointmentGroups, function (g) {
        return g.id === groupId;
      });
    },

    getCheckoutIds() {
      const checkedOut = _.filter(this.models, function (appt) {
        return appt.isCheckedOut();
      });

      return checkedOut.map(function (appt) {
        return appt.get('checkoutId');
      });
    },

    missingTransactions(checkoutModel) {
      const expectedCheckouts = this.getCheckoutIds();
      if (expectedCheckouts[0] === null) {
        return 0;
      }
      let returnedCheckouts = checkoutModel || [];
      returnedCheckouts = _.map(returnedCheckouts, function (checkout) {
        return checkout.get('checkoutInformation').checkoutId;
      });
      return _.difference(expectedCheckouts, returnedCheckouts);
    },

    getAppointmentPackageGroup(appointment) {
      return this.findAppointmentGroupType(appointment);
    },

    findAppointmentGroupType(appointment) {
      if (!appointment.belongsToGroup()) {
        return false;
      }
      const method = 'isPackageType';

      return _.find(this.appointmentGroups, function (group) {
        return appointment.belongsToGroup(group.id) && group[method]();
      });
    },

    hasUnconfirmed() {
      return this.any(function (appt) {
        return appt.isUnconfirmed();
      });
    },

    hasConfirmed() {
      return this.any(function (appt) {
        return appt.isConfirmed();
      });
    },

    hasUnsaved() {
      return this.any(function (appt) {
        return !appt.id;
      });
    },

    hasCheckedOut() {
      return this.any(function (appt) {
        return appt.isCheckedOut();
      });
    },

    allCheckedOut() {
      return this.all(function (appt) {
        return appt.isCheckedOut();
      });
    },

    isWalkin() {
      return this.any(function (appt) {
        return appt.isWalkin();
      });
    },

    canBeCheckedOut() {
      // Check if collection date is tomorrow, today or in the past.
      if (this.date == null) {
        return false;
      }

      const collectionDate = Wahanda.Date.createDate(this.date);
      const now = Wahanda.Date.createVenueDate();
      const dateDifference = Wahanda.Date.getDayDifference(now, collectionDate);

      return dateDifference > -2 && this.hasConfirmed();
    },

    canShowCustomerContacts() {
      const config = App.config.getAppointmentCustomerContactsConfig();

      return this.all(function (appt) {
        return appt.canShowCustomerContacts(config);
      });
    },

    /**
     * Check that all AppointmentGroup's Appointments are present.
     *
     * @returns {Bool} If all is good.
     */
    checkIntegrity() {
      // Check if all appointments are present.
      return _.all(
        this.appointmentGroups,
        function (group) {
          return _.all(
            group.data.appointmentIds,
            function (apptId) {
              return !!this.get(apptId);
            }.bind(this),
          );
        }.bind(this),
      );
    },

    /**
     * Returns a list of Appointments which don't belong to any groups or are the first appointment in them.
     *
     * @returns {Array} The appointments
     */
    getSeparateAppointments() {
      const list = [];
      const seenGroups = {};

      this.each(function (appt) {
        let ignore = false;

        _.each(appt.get('appointmentGroupIds'), function (agId) {
          if (!seenGroups[agId]) {
            seenGroups[agId] = true;
          } else {
            ignore = true;
          }
        });

        if (!ignore) {
          list.push(appt);
        }
      });

      return list;
    },

    refund() {
      this.forEach((appointment) => {
        const shouldKeepStatus = appointment.isNoShow() || appointment.isCancelled();

        if (!shouldKeepStatus) {
          appointment.set('appointmentStatusCode', appointmentStatus.STATUS_CONFIRMED);
        }
      });
    },

    getSeparateItemCount() {
      const groups = {};

      return this.reduce(function (memo, appt) {
        let repeats = false;

        _.each(appt.get('appointmentGroupIds'), (agId) => {
          //  Appointment groups are not counted as repeating.
          // They will be removed once confirmed.
          if (groups[agId]) {
            repeats = true;
          } else {
            groups[agId] = true;
          }
        });

        return memo + (repeats ? 0 : 1);
      }, 0);
    },

    getRescheduleDuration() {
      const length = this.length;

      return this.reduce(function (memo, item, index) {
        if (index === length - 1) {
          if (item instanceof App.Collections.AppointmentGroup) {
            return memo + item.getRescheduleDuration();
          }
          return memo + item.getDuration() + item.getCleanupTimeLength();
        }
        return memo + item.getDuration(true);
      }, 0);
    },

    /**
     * Get the first recipient name.
     *
     * Recipient name - name of the recipient which does not match the name of the person who booked.
     *
     * @returns {String|null} The name of the recipient, if any.
     */
    getFirstRecipientName() {
      const apptWithRecipient = this.find(function (appt) {
        return !appt.isConnectBooking() && appt.get('name') !== appt.get('recipientName');
      });

      if (apptWithRecipient) {
        return this.canShowCustomerContacts()
          ? apptWithRecipient.get('recipientName')
          : apptWithRecipient.get('recipientFirstName');
      }

      return null;
    },

    getFirstAppointmentByBookingActorImportance() {
      const sortedList = this.sortBy((appt) => {
        switch (appt.get('bookingActor')) {
          case 'CUSTOMER':
            return 0;
          case 'SUPPLIERS_CUSTOMER':
            return 1;
          default:
            return 2;
        }
      });

      return sortedList[0];
    },

    determineOriginalSource() {
      const firstAppt = this.getFirstAppointmentByBookingActorImportance();
      return firstAppt && firstAppt.get('bookingActor');
    },

    determineIfRepeat: function determineIfRepeat() {
      const firstAppt = this.getFirstAppointmentByBookingActorImportance();
      if (firstAppt == null) {
        return null;
      }
      const fistTimeCustomer = firstAppt.get('firstTimeCustomer');

      if (fistTimeCustomer == null) {
        return null;
      }

      return !fistTimeCustomer;
    },
  },
  {
    /**
     * Start rebooking mode of this collection.
     *
     * @param {self} collection The CustomerAppointments (self) collection to start rebooking
     * @param {MenuOffersCollection} offers List of all offers
     * @param {Object} maybeMousePosition { x, y }
     *
     * @returns {bool} if the rebooking was started
     */
    enterRebooking(collection, offers, maybeMousePosition) {
      const Appt = App.Models.Appointment.prototype;
      const collectionToRebook = collection.cloneWithStatuses([
        Appt.STATUS_CONFIRMED,
        Appt.STATUS_CHECKED_OUT,
        Appt.STATUS_CREATED,
        Appt.STATUS_PENDING_RESCHEDULE,
        Appt.STATUS_NO_SHOW,
      ]);

      const hasArchivedOffer = collectionToRebook.any(function (item) {
        return item.hasArchivedOffer(offers);
      });

      if (hasArchivedOffer) {
        Wahanda.Dialogs.HTMLAlert(
          Wahanda.Template.render(
            '<strong>{{heading}}</strong><br />{{text}}',
            Wahanda.lang.calendar.rebooking.serviceArchived,
          ),
        );
        return false;
      }

      Wahanda.Appointment.enterRebookingMode(collectionToRebook, maybeMousePosition);

      return true;
    },
  },
);
