import React from 'react';
import { ServiceListItem } from '../services';
import style from './ListItem.scss';

interface Props {
  service: ServiceListItem;
  onSelect: () => void;
}

export const ListItem = ({ service, onSelect }: Props) => {
  const { icon, title, type, description } = service;
  return (
    <li>
      <a
        data-test={`menu-offer-choice-${type}`}
        role="link"
        className={style.listItem}
        onClick={onSelect}
      >
        <div className={style.icon}>{icon}</div>
        <div>
          <div className={style.title}>{title}</div>
          <div className={style.description}>{description}</div>
        </div>
      </a>
    </li>
  );
};
