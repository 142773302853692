import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import { InputError } from 'components/common/__BaseCommon';
import SelectDropdown from './index';

interface IUnderlinedInputWithFormsyProps extends React.HTMLAttributes<Element> {
  isFormSubmitted: (...args: any[]) => any;
  getErrorMessage: (...args: any[]) => any;
  formsyClass?: string;
  required?: boolean;
  value?: string | number;
  setValue?: any;
  onSelect?: any;
  selected?: any;
}
class UnderlinedInputWithFormsy extends Component<
  IUnderlinedInputWithFormsyProps,
  { isInitialValueSet: boolean }
> {
  static defaultProps = {
    value: '',
    formsyClass: '',
    required: false,
  };

  constructor(props) {
    super(props);
    this.state = { isInitialValueSet: false };
  }

  UNSAFE_componentWillReceiveProps() {
    if (!this.state.isInitialValueSet && this.props.selected) {
      this.setState({ isInitialValueSet: true }, () => {
        this.props.setValue(this.props.selected);
      });
    }
  }

  onSelect = (value) => {
    this.props.setValue(value);
    this.props.onSelect(value);
  };

  getProps = () => {
    const propsToExclude = [
      'getValue',
      'hasValue',
      'isFormDisabled',
      'isValid',
      'isPristine',
      'isFormSubmitted',
      'isRequired',
      'isValidValue',
      'resetValue',
      'setValidations',
      'getErrorMessage',
      'getErrorMessages',
      'setValue',
      'showRequired',
      'showError',
      'innerRef',
      'validationError',
      'validationErrors',
      'formsyClass',
      'validations',
    ];
    return Object.keys(this.props)
      .filter((propKey) => propsToExclude.indexOf(propKey) === -1)
      .reduce((accumulator, key) => Object.assign(accumulator, { [key]: this.props[key] }), {});
  };

  showError() {
    return (
      this.props.isFormSubmitted() && (this.props.getErrorMessage() != null || this.props.required)
    );
  }

  render() {
    const { formsyClass } = this.props;
    return (
      <div className={formsyClass}>
        <SelectDropdown {...this.getProps()} onSelect={this.onSelect} />
        {this.showError() && <InputError message={this.props.getErrorMessage()} />}
      </div>
    );
  }
}

export default withFormsy(UnderlinedInputWithFormsy);
