import React from 'react';

import Latinize from 'latinize';
import Fuse from 'fuse.js';
import { formatFuse } from './formatFuse';
import { ElementRect, Option, OptionGroup, OptionValue } from './types';

export const getFirstActive = (data) => {
  return data.some((item) => {
    if (!item.hide && !item.grouping) {
      return item;
    }
  });
};

export function filterValidSearchValues(data) {
  return data.filter((item) => {
    const value = item.value;

    if (item.grouping) {
      return false;
    }

    return value !== '' && value !== null && value !== false;
  });
}

export interface DropdownStyle {
  width: number;
  maxHeight: number | string;
  height: number | string;
  top: number;
  left: number;
}

export const getDropdownPositionAndHeight = (payload: {
  elementRect: ElementRect;
  parentRect: ElementRect;
  isSearchable?: boolean;
  newLook?: boolean;
}): DropdownStyle => {
  const { elementRect, parentRect, isSearchable } = payload;
  const winHeight: number = window.innerHeight;
  const height: any = (() => {
    if (isSearchable) {
      return 'auto';
    }
    const setHeight = Math.floor(elementRect.height);

    if (setHeight > 0) {
      return setHeight;
    }
    return Math.min(Math.floor(winHeight * 0.95), elementRect.height);
  })();

  let top = (() => {
    if (isSearchable) {
      return parentRect.bottom + 1;
    }
    const topAlignedToParent = parentRect.top - height / 2;

    if (topAlignedToParent < 0) {
      return 10;
    }
    if (topAlignedToParent + height > winHeight) {
      return (winHeight - height) / 2;
    }
    return topAlignedToParent;
  })();

  const maxHeight = (() => {
    if (isSearchable) {
      return Math.max(
        0,
        // For searchable, we want 50% of the screen max.
        // But do not exceed the bottom bounds!
        Math.min(winHeight * 0.5, winHeight - parentRect.bottom - 15),
      );
    }
    return '95%';
  })();

  const { width, left } = parentRect;

  if (payload.newLook) {
    top = top + 90;
  }
  return {
    width,
    maxHeight,
    height,
    top,
    left,
  };
};

export const wrapName = (payload: { name: []; highlightClass: string }) => {
  const { name, highlightClass } = payload;
  if (Array.isArray(name)) {
    return name.map(({ matches, text }, i) => {
      const key = `match-${i}`;
      return matches ? (
        <span className={highlightClass} key={key}>
          {text}
        </span>
      ) : (
        text
      );
    });
  }

  return name;
};

export const formatResultData = (results, highlightClass) =>
  results.map((item) => ({
    ...item,
    name: wrapName({ name: item.name, highlightClass }),
  }));

export const formatResultDataToString = (result) =>
  result
    ? typeof result === 'string'
      ? result
      : result.reduce((acc, curr) => {
          return `${acc}${typeof curr === 'string' ? curr : curr.props.children}`;
        }, '')
    : undefined;

export const getSuggestions = <T extends OptionValue>(payload: {
  highlightClass?: string;
  items: Option<T>[] | OptionGroup<T>[];
  inputValue?: string;
  isSearchable: boolean;
}): Option<T>[] | OptionGroup<T>[] => {
  const { highlightClass, items, inputValue, isSearchable } = payload;

  if (inputValue && isSearchable) {
    const dataToSearch = filterValidSearchValues(items);
    const fuse = new Fuse(dataToSearch, {
      keys: ['latinName', 'name'],
      shouldSort: true,
      includeMatches: true,
    });
    const results = fuse.search(Latinize(inputValue));
    const formattedResults = formatResultData(formatFuse(results), highlightClass);

    return formattedResults;
  }
  return items;
};
