/* global Backbone */

App.Routers.Reports = Backbone.Router.extend({
  routes: {
    'venue/:venueid/bookings/:filterType/:filterDate': 'bookings',
    'venue/:venueid/bookings/:filterType/:filterDate/:dialogType/:dialogTypeId': 'bookings',
    'venue/:venueid/bookings': 'bookings',
    'venue/:venueid/sales/:periodGranularity/:startDate': 'sales',
    'venue/:venueid/sales': 'sales',
    'venue/:venueid/transactions': 'transactions',
    'venue/:venueid/transactions/:date': 'transactions',
    'venue/:venueid/salesReports': 'salesReports',
    'venue/:venueid/salesReports/*reportParams': 'salesReports',
    'venue/:venueid/clientReports': 'clientReports',
    'venue/:venueid/clientReports/*reportParams': 'clientReports',
    'venue/:venueid/posReports/': 'posReports',
    'venue/:venueid/posReports/*reportParams': 'posReports',
  },

  bookings: function bookings(venueId, filterType, filterDate, dialogType, dialogTypeId) {
    App.mainViewOptions = {
      reportType: 'bookings',
    };

    if (filterType != null) {
      App.mainViewOptions.filters = {
        type: filterType,
        date: filterDate,
      };
    }
    if (dialogType && dialogTypeId) {
      App.mainViewOptions.initialDialog = {
        type: dialogType,
        id: dialogTypeId,
        // Used for venueId check and redirect back from login to correct URL
        venueId: venueId,
        dialogUrl: document.URL,
      };
    }

    App.initVenue(venueId);
  },

  sales: function sales(venueId, periodGranularity, startDate) {
    App.mainViewOptions = {
      reportType: 'sales',
    };

    if (periodGranularity && startDate) {
      App.mainViewOptions.filters = {
        periodGranularity: periodGranularity,
        startDate: startDate,
      };
    }

    App.initVenue(venueId);
  },

  transactions: function transactions(venueId, date) {
    App.mainViewOptions = {
      reportType: 'transactions',
      date: date,
    };

    App.initVenue(venueId);
  },

  salesReports: function salesReports(venueId) {
    App.initVenue(venueId);
  },

  clientReports: function clientReports(venueId) {
    App.initVenue(venueId);
  },

  posReports: function posReports(venueId) {
    App.initVenue(venueId);
  },
});
