App.Views.Forms.MenuOffer2.Pricing.Dated.Edit = Backbone.View.extend({
  initialize: function () {
    var html = $('#menu-offer-pricing-edit').html();
    this.$el.append(html);

    if (this.options.renderDates && !this.options.forceDateToFuture) {
      this.datesView = new App.Views.Forms.MenuOffer2.Pricing.Dated.Dates({
        el: this.$('.b-dates-container'),
        dateFrom: Wahanda.Date.createDateIfString(this.options.dateFrom),
        dateTo: this.options.dateTo ? Wahanda.Date.createDateIfString(this.options.dateTo) : null,
        pricingType: this.options.pricingType,
      });
    }
    this.weekdayView = new App.Views.Partials.WeekdayCheckboxes({
      el: this.$('.weekdays'),
      selectedDays: this.options.applicableDays,
      disabledDays: this.options.usedWeekdays,
      lastCanBeDeselectedText: Wahanda.lang.menu.offer.pricing.errors.selectDay,
    });

    if (this.options.hasRoomTypes) {
      this.editView = new App.Views.Forms.MenuOffer2.Pricing.Dated.Edit.Rooms({
        rooms: this.options.rooms,
        roomTypes: this.options.roomTypes,
        canSaveToggleCallback: this.options.canSaveToggleCallback,
      });
    } else {
      this.editView = new App.Views.Forms.MenuOffer2.Pricing.Dated.Edit.Simple({
        discountPriceAmount: this.options.discountPriceAmount,
        fullPriceAmount: this.options.fullPriceAmount,
      });
    }
  },

  render: function () {
    this.renderDates();
    this.weekdayView.render();
    this.renderForm();
  },

  renderDates: function () {
    if (this.options.renderDates && !this.options.forceDateToFuture) {
      this.datesView.render();
    } else {
      this.$('.b-dates').remove();
    }
  },

  renderForm: function () {
    this.editView.render();
    this.$('table').append(this.editView.$el);
  },

  getValues: function () {
    var values = {
      applicableDays: this.weekdayView.getValues(),
    };
    if (!this.options.hasRoomTypes) {
      values.pricingRuleId = this.options.pricingRuleId ? this.options.pricingRuleId : null;
    }
    _.extend(values, this.editView.getValues());
    if (this.datesView) {
      _.extend(values, this.datesView.getValues());
    } else if (this.options.forceDateToFuture) {
      _.extend(values, {
        pricingType: 'F',
        dateFrom: Wahanda.Date.createVenueDate(),
        dateTo: null,
      });
    }
    return values;
  },

  isValid: function () {
    var valid = this.$('input:visible').valid();
    if (valid && _.isFunction(this.editView.isValid)) {
      valid = this.editView.isValid();
    }
    return !!valid;
  },
});
