import React from 'react';
import OrderDetailsDialog from '../OrderDetailsDialog/container';
import ProcessOrderDialog from '../ProcessOrderDialog/container';

type ProcessOrderProps = {
  onClose: (...args: any[]) => any;
  orderId: number;
  existingOrder?: {
    created?: string;
    id?: number;
    status?: string;
    totalQuantityOrdered?: number;
    productOrderItems?: {
      quantity?: number;
      id?: number;
      name?: string;
      venueProductId?: number;
    }[];
  };
  processingOrder: boolean;
  actions: {
    productOrderReset: (...args: any[]) => any;
    requestProductOrdersAction: (...args: any[]) => any;
  };
};
const ProcessOrder: React.SFC<ProcessOrderProps> = (props) => {
  const onClose = () => {
    props.actions.productOrderReset();
    props.onClose();
  };
  if (!props.processingOrder) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'never'.
    return <OrderDetailsDialog orderId={props.orderId} onClose={onClose} allowToProcess />;
  }
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type '{ crea... Remove this comment to see the full error message
  return <ProcessOrderDialog productOrder={props.existingOrder} onClose={onClose} />;
};
ProcessOrder.defaultProps = {
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '{ created?:... Remove this comment to see the full error message
  existingOrder: null,
};

export default ProcessOrder;
