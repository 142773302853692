import React from 'react';
import Wahanda from 'common/wahanda';
import { Currency } from 'components/common/DataFormat';
import {
  Table,
  TableColumnHeader,
  Align,
  TableRow,
  TableBody,
  TableFooter,
  TableHeader,
  TableData,
  TableLayout,
} from 'components/common/Table';
import SubHeader from '../SubHeader';
import style from './style.scss';

interface Sales {
  grossSales: number;
  grossRefunds: number;
  total: number;
}

export interface Props {
  services: Sales;
  products: Sales;
  vouchers: Sales;
  grandTotal: Sales;
}

const LANG = Wahanda.lang.reports.posReports.salesPeriod.salesByCategory;

export default class SalesByCategory extends React.PureComponent<Props> {
  public render() {
    return (
      <div>
        <SubHeader text={LANG.header} />
        <Table border layout={TableLayout.FIXED}>
          <TableHeader>{this.renderHeader()}</TableHeader>
          <TableBody>{this.renderBody()}</TableBody>
          <TableFooter>{this.renderFooter()}</TableFooter>
        </Table>
      </div>
    );
  }

  private renderHeader() {
    return (
      <TableRow>
        <TableColumnHeader align={Align.LEFT}>{LANG.category}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.grossSales}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.grossRefunds}</TableColumnHeader>
        <TableColumnHeader align={Align.RIGHT}>{LANG.grossTotal}</TableColumnHeader>
      </TableRow>
    );
  }

  private renderSales(category: string, sales: Sales) {
    const { grossSales, total, grossRefunds } = sales;
    return (
      <TableRow>
        <TableData align={Align.LEFT}>{category}</TableData>
        <TableData align={Align.RIGHT}>
          <Currency amount={grossSales} />
        </TableData>
        <TableData align={Align.RIGHT}>
          <Currency alwaysNegative amount={grossRefunds} />
        </TableData>
        <TableData align={Align.RIGHT}>
          <Currency amount={total} />
        </TableData>
      </TableRow>
    );
  }

  private renderBody() {
    return (
      <React.Fragment>
        {this.renderSales(LANG.services, this.props.services)}
        {this.renderSales(LANG.products, this.props.products)}
        {this.renderSales(LANG.vouchers, this.props.vouchers)}
      </React.Fragment>
    );
  }

  private renderFooter() {
    const { grossRefunds, grossSales, total } = this.props.grandTotal;
    return (
      <TableRow>
        <TableData align={Align.LEFT}>
          <div className={style.bolded}>{LANG.grandTotal}</div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={grossSales} />
          </div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency alwaysNegative amount={grossRefunds} />
          </div>
        </TableData>
        <TableData align={Align.RIGHT}>
          <div className={style.bolded}>
            <Currency amount={total} />
          </div>
        </TableData>
      </TableRow>
    );
  }
}
