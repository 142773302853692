import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from 'common/backbone-app';
import { requestInitStripe, resetStripe } from '../actions';
import StripeProvider from './StripeProvider';

const mapStateToProps = () => {
  return {
    supplierId: App.getSupplierId(),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setupStripe: bindActionCreators(requestInitStripe, dispatch),
    resetStripe: bindActionCreators(resetStripe, dispatch),
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(StripeProvider);
