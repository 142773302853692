import React from 'react';

interface Props {
  className?: string;
}

export class TableBody extends React.PureComponent<Props> {
  public render() {
    const { children, className, ...rest } = this.props;
    return (
      <tbody className={className} {...rest}>
        {children}
      </tbody>
    );
  }
}
