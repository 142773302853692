const STOCK_WARNING_SOFT_LIMIT = 5;
const STOCK_WARNING_HARD_LIMIT = 0;
const STOCK_WARNING_SOFT_NAME = 'soft';
const STOCK_WARNING_HARD_NAME = 'hard';

/**
 * Product list.
 */
App.Models.Products = BackboneEx.Model.Silent.extend({
  initialize: function () {},

  url: function () {
    var base;
    base = '/products.json';
    return App.Api.wsVenueUrl(base);
  },

  processProduct: function () {
    const price = Wahanda.Currency.getFormatted(this.get('amount'));
    const inventory = this.get('inventory');
    const hasStockWarningSoft =
      inventory > STOCK_WARNING_HARD_LIMIT && inventory <= STOCK_WARNING_SOFT_LIMIT;
    const hasStockWarningHard = inventory <= STOCK_WARNING_HARD_LIMIT;
    const hasStockWarning = hasStockWarningSoft || hasStockWarningHard;
    const stockWarningString = hasStockWarningSoft
      ? STOCK_WARNING_SOFT_NAME
      : hasStockWarningHard
      ? STOCK_WARNING_HARD_NAME
      : null;

    this.set('price', price);
    this.set('value', this.get('name'));
    this.set('unitPrice', this.get('amount'));
    this.set('inventoryManaged', inventory != null);
    this.set('hasStockWarning', hasStockWarning);
    this.set('hasStockWarningSoft', hasStockWarningSoft);
    this.set('hasStockWarningHard', hasStockWarningHard);
    this.set('stockWarningString', stockWarningString);
  },
});
