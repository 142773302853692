import { createAction } from 'reduxStore/actionCreator';
import {
  GET_RANKING_BOOST_REQUEST,
  GET_RANKING_BOOST_SUCCESS,
  GET_RANKING_BOOST_FAILURE,
} from 'reduxStore/actionsConst';

export interface RankingBoostResponse {
  active: boolean;
  endDate: string;
}

export const getRankingBoostRequestAction = () => createAction(GET_RANKING_BOOST_REQUEST);

export type GetRankingBoostRequestAction = ReturnType<typeof getRankingBoostRequestAction>;

export const getRankingBoostSuccessAction = (data: RankingBoostResponse) =>
  createAction(GET_RANKING_BOOST_SUCCESS, data);

export type GetRankingBoostSuccessAction = ReturnType<typeof getRankingBoostSuccessAction>;

export const getRankingBoostFailureAction = () => createAction(GET_RANKING_BOOST_FAILURE);

export type GetRankingBoostFailureAction = ReturnType<typeof getRankingBoostFailureAction>;
