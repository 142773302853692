import { combineReducers } from 'redux';

import { menu as menuOffer } from './reducer';
import { menuGroup } from './MenuGroupModal/store/reducer';
import { multipleServicesSelectReducer as menuTemplates } from './AddServicesModal/store/reducer';

export const menu = combineReducers({
  menuGroup,
  menuOffer,
  menuTemplates,
});
