import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Treatwell extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-treatwell', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M4.99263161,5.16000366 L4.99263161,14.2019503 C4.99263161,15.6819732 5.80356747,16.5739908 7.14162345,16.5739908 C7.56734706,16.5739908 7.99312973,16.4929091 8.45942375,16.3306865 L8.45942375,16.3306865 L8.45942375,16.4725943 C7.68899925,17.6687424 6.59424765,18.3377704 5.21562126,18.3377704 C3.41127126,18.3377704 2,17.1619016 2,15.0129098 L2,15.0129098 L2,9.37690556 L0.350006104,9.37690556 L0.350006104,9.15385686 L4.74932723,5.16000366 L4.99263161,5.16000366 Z M17.1260261,8.01855847 L20.1873503,15.0129098 L18.7073274,18.276968 L18.5046525,18.276968 L13.9633645,8.01855847 L17.1260261,8.01855847 Z M11.449422,8.01854666 L14.5107462,15.012898 L13.0307823,18.2769561 L12.8280484,18.2769561 L8.87470653,9.37685832 L6,9.37685832 L6,8.01855847 L11.449422,8.01854666 Z M23.3500061,8.01855847 L21.3631778,11.9313299 L21.2212699,11.9313299 C21.2212699,10.9582305 20.7752907,9.01197261 20.2684262,8.01855847 L20.2684262,8.01855847 L23.3500061,8.01855847 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Treatwell);
