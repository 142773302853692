export const updateFullPriceAmount = (modifyIndex, fullPriceAmount) => (sku, index) =>
  index !== modifyIndex ? sku : Object.assign({}, sku, { fullPriceAmount });

export const changeVisibleStateOfSku = (modifyIndexArray, visible) => (sku, index) =>
  !modifyIndexArray.includes(index) ? sku : Object.assign({}, sku, { visible });

export const calculatePrice = (subSkus) =>
  subSkus.reduce(
    (sum, { discountPriceAmount, fullPriceAmount }) =>
      sum + (discountPriceAmount || fullPriceAmount || 0),
    0,
  );

export const calculateOfferDialogSkuPrices = (isPackagePricingCustom) => (sku) => {
  const fullPriceAmount = isPackagePricingCustom ? sku.fullPriceAmount : sku.calculatedPriceAmount;

  return {
    fullPriceAmount,
  };
};

export const updateOfferDialogSkusPrices = (isPackagePricingCustom, offerDialogSkus) => (
  sku,
  index,
) => {
  const fullPriceAmount = isPackagePricingCustom
    ? sku.fullPriceAmount
    : offerDialogSkus[index].fullPriceAmount;

  return Object.assign({}, sku, {
    fullPriceAmount,
  });
};

export const updateOfferDialogSkusPricingType = (isPackagePricingCustom, offerDialogSkusPrices) => (
  sku,
  index,
) => {
  const fullPriceAmount = isPackagePricingCustom
    ? offerDialogSkusPrices[index].fullPriceAmount
    : null;

  return Object.assign({}, sku, {
    fullPriceAmount,
  });
};

export const mapEmployeeCategoryNamesWithSkusSelector = ({
  offerDialogSkus,
  employeeCategoriesCollection,
}) => {
  const employeeCategoriesIds = employeeCategoriesCollection.map((o) => o.id);
  return offerDialogSkus
    .map((sku) => {
      const { subSkus } = sku;

      if (!(subSkus && subSkus.length)) {
        return sku;
      }

      if (!subSkus.some((subSku) => subSku.employeeCategoryId)) {
        return sku;
      }

      const { employeeCategoryId } = subSkus.find((subSku) => subSku.employeeCategoryId);
      const category = employeeCategoriesCollection.find(({ id }) => id === employeeCategoryId);

      if (!employeeCategoriesIds.includes(category && category.id)) {
        return undefined;
      }

      if (!category) {
        return sku;
      }

      return Object.assign({}, sku, {
        employeeCategoryId: category.id,
        employeeCategoryName: category.name,
      });
    })
    .filter(Boolean);
};

export const isSkuCombinationNotSupportedSelector = ({ offerDialogSkus, offerDialogSubServices }) =>
  offerDialogSubServices.length > 0 && offerDialogSkus.length === 0;

export const updateMasterService = (offers, serviceId, tempId) =>
  offers.map((offer) => {
    const master = !!(
      (serviceId && offer.serviceId === serviceId) ||
      (tempId && offer.tempId === tempId)
    );

    return Object.assign({}, offer, { master });
  });

export const calculateInlineServicePriceSelector = (offerDialogInlineServices) =>
  offerDialogInlineServices.reduce((sum, { price }) => {
    const value = price ? Number(price) : 0;

    return sum + value;
  }, 0);

export const calculateSkuCalculatedPriceAmountSelector = (sku, additionalPricePrefix = 0) =>
  calculatePrice(sku.subSkus) + additionalPricePrefix;

export const hasMultipleEmployeeCategories = (employeeIds, allEmployees) => {
  const uniqueCats = {};

  employeeIds.forEach((id) => {
    const employee = allEmployees.find((e) => e.id === id);
    if (employee && employee.employeeCategoryId > 0) {
      uniqueCats[employee.employeeCategoryId] = true;
    }
  });

  return Object.keys(uniqueCats).length > 1;
};

// Include or remove the given employeeId from offerDialogList.
// Also, if useEmployeeCategories is checked, uncheck it if we have less than two
// distinct employee categories.
export const updateOfferDialogEmployeeAndStaffPricing = (
  employeeId,
  shouldInclude,
  { offerDialogEmployees, offerDialogUseEmployeeCategoryPricing, allEmployees },
) => {
  const filteredEmployees = offerDialogEmployees.filter((id) => id !== employeeId);
  const employees = shouldInclude ? [...filteredEmployees, employeeId] : filteredEmployees;

  const canUseEmployeeCategories = offerDialogUseEmployeeCategoryPricing
    ? hasMultipleEmployeeCategories(employees, allEmployees)
    : false;

  return {
    offerDialogEmployees: employees,
    offerDialogUseEmployeeCategoryPricing: canUseEmployeeCategories,
  };
};

export const selectAllEmployees = ({ allEmployees }) => ({
  offerDialogEmployees: allEmployees.map((employee) => employee.id),
  offerDialogUseEmployeeCategoryPricing: false,
});

export const deselectAllEmployees = () => {
  return {
    offerDialogEmployees: [],
    offerDialogUseEmployeeCategoryPricing: false,
  };
};

const getStaffPricedOfferEmployeesCount = (selectedEmployeeIds, allEmployees) =>
  allEmployees.reduce((sum, employee) => {
    const checked = selectedEmployeeIds.includes(employee.id);

    return sum + (checked && employee.employeeCategoryId > 0 ? 1 : 0);
  }, 0);

export const filterAllStaffPricedEmployeesIfNoneSelected = (offerDialogEmployees, allEmployees) => {
  const staffPricedEmployees = offerDialogEmployees.filter((id) => {
    const employee = allEmployees.find((e) => e.id === id);
    return employee && employee.employeeCategoryId > 0;
  });
  const currentStaffPricedCount = getStaffPricedOfferEmployeesCount(
    staffPricedEmployees,
    allEmployees,
  );

  if (currentStaffPricedCount > 0) {
    return staffPricedEmployees;
  }
  return allEmployees
    .filter((emp) => emp.employeeCategoryId > 0)
    .map((emp) => emp.id)
    .concat(staffPricedEmployees);
};

export const skipOfferDialogPrefix = (state) => {
  // Filter out any offerDialog related keys
  const filteredState = {};
  Object.keys(state).forEach((key) => {
    if (!/^offerDialog/.test(key)) {
      filteredState[key] = state[key];
    }
  });
  return filteredState;
};
