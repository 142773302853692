import App from 'common/backbone-app';
import { connect } from 'react-redux';
import { completeFBEReintegration } from '../../InstallStatus/NotInstalled/actions';
import { BookingChannelsWithWarnings as Component } from './BookingChannelsWithWarnings';

const mapStateToProps = ({
  marketing: {
    fbeIntegration: { installStatus },
  },
}) => {
  return {
    venueId: App.config.get('venue').id,
    timezone: App.getTimezone(),
    currency: App.config.get('currency').currencyCode,
    businessConfig: installStatus.businessConfig,
    facebookPageId: installStatus.integrationAssets?.pageId,
  };
};

const mapDispatchToProps = {
  completeFBEReintegration,
};

export const BookingChannelsWithWarnings = connect(mapStateToProps, mapDispatchToProps)(Component);
