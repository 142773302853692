import {
  GET_EMAIL_TEMPLATE_REQUEST,
  GET_SMART_DISCOUNTS_TEMPLATE_REQUEST,
  GET_SERVICE_ON_SALE_TEMPLATE_REQUEST,
  GET_APP_FOR_YOUR_CLIENTS_TEMPLATE_REQUEST,
  GET_EMAIL_TEMPLATE_RECEIVE,
  GET_EMAIL_TEMPLATE_FAILURE,
  EMAIL_TEMPLATE_RESET,
  EDIT_MARKETING_EMAIL_CONTENT,
  STORE_MARKETING_EMAIL_CONTENT,
} from 'reduxStore/actionsConst';

import { parse, validate } from './EmailTemplateParser';

export const getEmailTemplateRequest = () => ({
  type: GET_EMAIL_TEMPLATE_REQUEST,
});

export const getSmartDiscountsTemplateRequest = ({ discountRuleId }) => ({
  type: GET_SMART_DISCOUNTS_TEMPLATE_REQUEST,
  discountRuleId,
});

export const getServiceOnSaleTemplateRequest = ({ treatmentGroupId }) => ({
  type: GET_SERVICE_ON_SALE_TEMPLATE_REQUEST,
  treatmentGroupId,
});

export const getAppForYourClientTemplateRequest = () => ({
  type: GET_APP_FOR_YOUR_CLIENTS_TEMPLATE_REQUEST,
});

export const getEmailTemplateReceive = ({ template }) => {
  const validationResult = validate(template.html);
  if (!validationResult.isValid) {
    return {
      type: GET_EMAIL_TEMPLATE_FAILURE,
      error: validationResult.error,
    };
  }

  return {
    type: GET_EMAIL_TEMPLATE_RECEIVE,
    template: {
      ...template,
      html: parse(template.html),
    },
  };
};

export const getEmailTemplateFailure = (error) => ({
  type: GET_EMAIL_TEMPLATE_FAILURE,
  error,
});

export const emailTemplateReset = () => ({
  type: EMAIL_TEMPLATE_RESET,
});

export const storeMarketingEmailContent = (content) => ({
  type: STORE_MARKETING_EMAIL_CONTENT,
  content,
});

export const editMarketingEmailContent = (field) => ({
  type: EDIT_MARKETING_EMAIL_CONTENT,
  field,
});
