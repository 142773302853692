/* eslint-disable func-names */
/**
 * Collection for menu offer types (treatments).
 */
App.Collections.Treatments = BackboneEx.Collection.Base.extend({
  model: BackboneEx.Model.Silent,
  urlTemplate: null,
  /**
   * name or popularity
   */
  compareBy: 'popularity',

  comparator: function (a, b) {
    if (this.compareBy === 'name') {
      return a.get('name').localeCompare(b.get('name'));
    }
    return b.get('popularity') - a.get('popularity');
  },

  /**
   * @see this.compareBy
   * @return self
   */
  setCompareMethod: function (method) {
    this.compareBy = method;
    return this;
  },

  getCompareMethod: function () {
    return this.compareBy;
  },

  getByTypeId: function (typeId) {
    return this.filter(function (t) {
      return t.get('treatmentGroupId') === typeId;
    });
  },

  hasTreatmentsForTypeId: function (typeId) {
    return this.any(function (t) {
      return t.get('treatmentGroupId') === typeId;
    });
  },
});
