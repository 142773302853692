import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as productsActions from 'reduxStore/common/products/actions';

import { newOrderProductsSelector } from '../../selectors';

import ProductsTable from './index';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(productsActions, dispatch),
});

const mapStateToProps = (state) => ({
  products: newOrderProductsSelector(state),
  loading: state.products.loading,
  newOrderTotal: state.productOrders.newOrderTotal,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTable);
