import React from 'react';
import { get, toClj, toJs, isEmpty, map, hashMap, merge } from 'mori';
import Wahanda from 'common/wahanda';
import ReactDialog from 'src/components/common/react-dialog';
import OverviewView from './transaction-summary/overview';
import SummaryRowsView from './transaction-summary/row';
import Pricing from './transaction-summary/pricing';

const DIALOG_WIDTH = 470;

function getFooter(footerContent) {
  if (footerContent) {
    return <div className="footer">{footerContent}</div>;
  }
  return null;
}

function getByType(isCancellation, ifCheckout, ifCancellation) {
  return isCancellation ? ifCancellation : ifCheckout;
}

function getFirst(hMap, try1, try2) {
  const first = get(hMap, try1);
  return first || get(hMap, try2);
}

function getServices(isCancellation, data) {
  const key = getByType(isCancellation, 'serviceLineItems', 'cancelledServiceLineItems');
  const services = map(
    (service) => (
      <SummaryRowsView
        key={get(service, 'id')}
        name={getFirst(service, 'name', 'description')}
        totalPrice={get(service, 'amount')}
        constituents={get(service, 'serviceLineItemConstituents')}
        negative={isCancellation}
      />
    ),
    get(data, key),
  );
  if (!isEmpty(services)) {
    return <div className="service-section">{toJs(services)}</div>;
  }
  return null;
}

function getProducts(isCancellation, data) {
  const key = getByType(isCancellation, 'productLineItems', 'cancelledProductLineItems');
  const products = map(
    (product) => (
      <SummaryRowsView
        key={get(product, 'id')}
        name={getFirst(product, 'name', 'description')}
        totalPrice={get(product, 'amount')}
        quantity={get(product, 'quantity')}
        unitPrice={get(product, 'unitPrice')}
        negative={isCancellation}
      />
    ),
    get(data, key),
  );
  if (!isEmpty(products)) {
    return <div className="product-section">{toJs(products)}</div>;
  }
  return null;
}

interface ITransactionSummaryDialogProps extends React.HTMLAttributes<Element> {
  data?: {};
  onClose?: (...args: any[]) => any;
  type?: string;
  dialogTitle?: string | {};
  footerContent?: {};
  linkedTransaction?: {};
}
type TransactionSummaryDialogState = {
  isCancellation?: boolean;
};

export default class TransactionSummaryDialog extends React.Component<
  ITransactionSummaryDialogProps,
  TransactionSummaryDialogState
> {
  dialog: any;

  state = {
    isCancellation: this.props.type === 'cancellation',
  };

  render() {
    const { data, linkedTransaction, footerContent, dialogTitle, onClose } = this.props;
    const { isCancellation } = this.state;
    const content = (() => {
      const lang = Wahanda.lang.reports.transactions.checkoutSummary;
      if (!data) {
        return <div className="loading">{lang.loading}</div>;
      }
      const serviceList = getServices(isCancellation, data);
      const productList = getProducts(isCancellation, data);
      const maybeSeperator = (() => {
        if (productList && serviceList) {
          return <span className="seperator" />;
        }
        return null;
      })();
      // For Refunds, change some strings
      const langPart = Wahanda.lang.reports.transactions.refundSummary;
      const overviewLang = merge(
        toClj(lang),
        getByType(
          isCancellation,
          null,
          hashMap(
            'transactionLabel',
            langPart.refundIdLabel,
            'checkoutOutLabel',
            langPart.refundedByLabel,
          ),
        ),
      );
      return (
        <div>
          {linkedTransaction}
          <OverviewView
            data={data}
            lang={toJs(overviewLang)}
            isCancellation={this.state.isCancellation}
          />
          {serviceList}
          {maybeSeperator}
          {productList}
          <Pricing isCancellation={isCancellation} data={data} lang={lang} />
          {getFooter(footerContent)}
        </div>
      );
    })();
    return (
      <ReactDialog
        dataTest="report-transaction-summary-modal"
        title={dialogTitle}
        classes={{ 'checkout-summary': true }}
        width={DIALOG_WIDTH}
        onClose={onClose}
        ref={(dialog) => {
          this.dialog = dialog;
        }}
      >
        {content}
      </ReactDialog>
    );
  }
}
