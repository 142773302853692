(function () {
  var BookingsEnquiries = BackboneEx.Model.Silent.extend(
    {
      appointmentGroupsById: null,

      url: function () {
        var base =
          '/venue/' + App.getVenueId() + '/bookings-enquiries.json?' + this.getUrlFilterString();
        return App.Api.wsUrl(base);
      },

      parse: function (list) {
        var apptGroupAppointments = {};
        var apptGroups = {};

        function addApptToGroup(appt) {
          appt.eachGroup(function (groupId) {
            if (apptGroups[groupId]) {
              apptGroups[groupId].add(appt);
            } else {
              if (!apptGroupAppointments[groupId]) {
                apptGroupAppointments[groupId] = [];
              }
              apptGroupAppointments[groupId].push(appt);
            }
          });
        }

        function addApptGroup(groupData) {
          var groupId = groupData.id;
          var group = new App.Collections.AppointmentGroup(
            apptGroupAppointments[groupId] || [],
            groupData,
          );
          apptGroups[groupId] = group;

          return group;
        }

        var parsedList = [];

        // Convert each item to it's Backbone.Model instance.
        _.each(list, function (item) {
          if (item.booking) {
            item.booking = new App.Models.Booking(item.booking);
            if (item.booking.isAppointment()) {
              // Check if booking appointments belong to a group
              var appt = item.booking.getAppointmentModel();
              if (appt.belongsToGroup()) {
                addApptToGroup(appt);
              }
            }
          } else if (item.appointment) {
            item.appointment = new App.Models.Appointment(item.appointment);
            if (item.appointment.belongsToGroup()) {
              addApptToGroup(item.appointment);
            }
          } else if (item.appointmentGroup) {
            item.appointmentGroup = addApptGroup(item.appointmentGroup);
          } else {
            // An unknown type. Skip.
            return;
          }

          parsedList.push(item);
        });

        this.appointmentGroupsById = apptGroups;

        return {
          data: parsedList,
        };
      },

      /**
       * Works like _.each on the list of models.
       *
       * @param Function callback
       */
      each: function (callback) {
        _.each(this.get('data'), callback);
      },

      getItemAppointmentGroups: function (item) {
        var appointment = BookingsEnquiries.getAppointmentFromItem(item);
        if (!appointment) {
          return null;
        }

        var self = this;
        return _.map(appointment.get('appointmentGroupIds'), function (groupId) {
          return self.appointmentGroupsById[groupId];
        });
      },
    },
    {
      getAppointmentFromItem: function (item) {
        return (
          item.appointment ||
          (item.booking && item.booking.isAppointment() && item.booking.getAppointmentModel())
        );
      },

      itemBelongsToGroup: function (item) {
        var appt = BookingsEnquiries.getAppointmentFromItem(item);
        if (appt) {
          return appt.belongsToGroup();
        }

        var group = item.appointmentGroup;
        if (!group) {
          return false;
        }

        return group.isPackageType() && group.hasAppointmentsBelongingToMultiServices();
      },
    },
  );

  App.Models.BookingsEnquiries = BookingsEnquiries;
})();
