(function () {
  var BaseDialog = BackboneEx.View.Dialog2;

  // The string status is correct. CP stands for Unconfirmed. And yes, CP in appointments is Checked-Out.
  var DATED_STATUS_UNCONFIRMED = 'CP';

  var OrderView = BaseDialog.extend(
    {
      events: {
        'click .js-close': 'close',
        'click .js-accept': 'onAccept',
        'click .js-reject': 'onReject',
        'click .js-open-maia-order': 'openOrderInMaiaWindow',
      },

      templateId: 'order2-dialog-template',

      dialogTitle: Wahanda.lang.orderDialog.title,

      initialize: function () {
        this.buttons = {};

        if (App.isMasquerading()) {
          this.buttons.close = {
            title: Wahanda.lang.shared.close,
          };
          this.buttons.menu = {
            items: [
              {
                label: 'View Order in Maia',
                classes: 'js-open-maia-order iglu-menu-item-background',
              },
            ],
          };
        }

        BaseDialog.prototype.initialize.call(this);
      },

      renderContainerOnly: function () {
        if (!this.rendered) {
          BaseDialog.prototype.render.call(this);
        }
      },

      render: function () {
        if (!this.rendered) {
          BaseDialog.prototype.render.call(this);
        }
        var self = this;
        this.model.fetch({
          success: function (data) {
            self.model = data;
            self.renderTemplate();
          },
          skipErrorHandling: true,
        });
      },

      renderTemplate: function () {
        var canShowCustomerContacts = this.model.canShowCustomerContacts();
        this.customerDetails = {
          name: canShowCustomerContacts
            ? this.model.get('customerName')
            : this.model.getFirstName(),
          phoneNumber: canShowCustomerContacts && this.model.get('customerPhoneNumber'),
          emailAddress: canShowCustomerContacts && this.model.get('customerEmailAddress'),
        };
        if (this.model.get('appointments')) {
          this.order = this.getAppointmentDetails();
        } else {
          this.order = this.getOffersDetails();
        }

        this.$('.dialog2--content').html(
          Wahanda.Template.renderTemplate(this.templateId, {
            customer: this.customerDetails,
            orderReference: this.model.get('orderReference'),
            date: Wahanda.Date.formatToDefaultFullDate(
              Wahanda.Date.parse(this.model.get('createdDate') || this.model.get('created')),
            ).date,
            order: this.order,
            footer: this.getFooterTemplateData(),
            partnerName: this.model.getBookingActorName(),
          }),
        );

        this.$('.full-loading').remove();
        this.$('.order2-dialog').show();
      },

      getAppointmentDetails: function () {
        var self = this;
        var order;
        var items = [];

        _.each(this.model.get('appointments'), function (appointment) {
          if (appointment.appointmentGroupIds.length === 0) {
            items.push(self.getAppointmentTemplateData(appointment));
          }
        });
        _.each(this.model.get('appointmentGroups'), function (appointmentGroup) {
          if (!appointmentGroup.name) {
            return;
          }
          items.push({
            title: appointmentGroup.name,
            skus: self.getAppointmentGroupSkus(appointmentGroup.appointmentIds),
            cost: Wahanda.Currency.format(appointmentGroup.price.amount),
            discount: self.getDiscount(appointmentGroup.discount),
          });
        });

        order = {
          cost: Wahanda.Currency.format(this.model.get('totalAmount')),
          items: items,
        };
        return order;
      },

      getDiscount: function (discountCode) {
        if (!discountCode || !discountCode.type) {
          return;
        }
        var discount;
        if (discountCode.type === 'JIT') {
          discount = Wahanda.lang.orderDialog.jit;
        } else if (discountCode.type === 'OFFPEAK') {
          discount = Wahanda.lang.orderDialog.offpeak;
        }

        return discount;
      },

      getAppointmentGroupSkus: function (appointmentIds) {
        var appointments = this.model.get('appointments');
        var skus = [];
        _.each(appointmentIds, function (appointmentId) {
          var currentAppointment = _.findWhere(appointments, {
            id: appointmentId,
          });
          skus.push({ skuName: currentAppointment.offerName });
        });
        return skus;
      },

      getOffersDetails: function () {
        var fulfillment = {
          isEvoucher: this.model.isEvoucher(),
          isDated: this.model.isDated(),
          isAppointment: this.model.isAppointment(),
        };
        var item = {};
        var actionButtons = false;

        if (fulfillment.isAppointment) {
          item = this.model.get('appointment');
          _.extend(item, this.getAppointmentTemplateData());
        } else if (fulfillment.isDated) {
          item = this.getDatedTemplateData();
          actionButtons = item.isUnconfirmed;
        } else {
          item = this.model.get('evouchers')[0];
          item = this.getEvoucherTemplateData(item);
        }

        _.extend(item, fulfillment);

        return {
          items: [item],
          cost: item.cost,
          showConfirmations: actionButtons,
        };
      },

      getDatedTemplateData: function () {
        var datedItem = this.model.get('datedBookings')[0];

        // Dated item attribute generation
        var arrivalText = Wahanda.Template.render(Wahanda.lang.orderDialog.dated.checkinDate, {
          date: Wahanda.Date.formatToDefaultDate(Wahanda.Date.createVenueDate(datedItem.itemDate)),
          nights: datedItem.numberOfNights > 0 ? datedItem.numberOfNights : null,
        });
        var skus = [{ skuName: arrivalText }];
        if (datedItem.roomTypeName) {
          skus.push({ skuName: datedItem.roomTypeName });
        }
        skus.push({
          skuName: getGuests(),
        });

        return {
          title: datedItem.offerName,
          cost: Wahanda.Currency.format(this.model.get('totalAmount')),
          skus: skus,
          isSpaBreak: datedItem.datedServiceType === 'FX',
          isUnconfirmed: datedItem.bookingStatus === DATED_STATUS_UNCONFIRMED,
        };

        function getGuests() {
          return _.pluck(datedItem.guests, 'guestName').join(', ');
        }
      },

      getAppointmentTemplateData: function (appointment) {
        if (!appointment) {
          appointment = this.model.get('appointment');
        }
        return {
          title: appointment.offerName,
          skus: appointment.skus,
          discount: this.getDiscount(appointment.discount),
          cost: Wahanda.Currency.format(appointment.amount),
        };
      },

      getEvoucherTemplateData: function (offer) {
        var newObject = {
          cost: Wahanda.Currency.format(offer.price.amount),
        };

        return _.extend(offer, newObject);
      },

      getFooterTemplateData: function () {
        var zeroAmount = Wahanda.Currency.format(0);
        var total = this.order.cost;
        var prePaid = this.model.get('payAtVenue') ? zeroAmount : total;
        var toPay = this.model.get('payAtVenue') ? total : zeroAmount;
        return {
          total: total,
          prePaid: prePaid,
          toPay: toPay,
        };
      },

      disableForm: function () {
        this.$('.footer-button').addClass('disabled');
        this.loadmask();
      },

      enableForm: function () {
        this.$('.footer-button').removeClass('disabled');
        this.unloadmask();
      },

      // UI events

      onAccept: function () {
        if (this.confirming) {
          return;
        }

        this.confirming = true;
        var self = this;

        this.disableForm();

        this.model
          .confirm()
          .done(function () {
            self.close();
          })
          .fail(function () {
            self.$('.js-accept').errorTip(Wahanda.lang.shared.savingFailed);
            self.confirming = false;
            self.enableForm();
          });
      },

      onReject: function () {
        var View = BaseDialog.extend({
          events: {
            'click .js-close': 'close',
          },
          options: {
            width: 460,
          },
          templateId: 'order-reject-message-template',
        });
        var dialog = new View();
        dialog.render();
        dialog.open();
      },

      openOrderInMaiaWindow: function () {
        var orderId = this.model.id;
        var link = '/operations/orders/order.php?&id=' + orderId;
        var url = App.getMaiaRootUrl() + link;

        window.open(url, 'Maia Order#' + orderId);
      },
    },
    {
      openById: function (orderId) {
        var dialog = new OrderView({
          model: new App.Models.Order({ id: orderId }),
        });
        dialog.render();
        dialog.open();
      },

      openByOrderItemId: function (orderItemId) {
        var booking = new App.Models.Booking({ id: orderItemId });
        var orderModel = new App.Models.Order();
        var dialog = new OrderView({
          model: orderModel,
        });

        dialog.renderContainerOnly();
        dialog.open();

        booking.fetch().done(function () {
          orderModel.id = booking.get('orderId');
          dialog.render();
        });
      },

      openByBookingId: function (bookingId) {
        OrderView.openByOrderItemId(bookingId);
      },
    },
  );

  BackboneEx.Mixin.View.Loadmask.mixin(OrderView);

  App.Views.Dialog.Order2 = OrderView;
})();
