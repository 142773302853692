import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import {
  receiveVenueDetailsAction,
  requestVenueDetailsFailureAction,
  receiveVenueStatisticsAction,
  requestVenueStatisticsFailureAction,
  receiveSupplierDetailsAction,
  requestSupplierDetailsFailureAction,
  requestCustomerReviewsFailureAction,
  receiveCustomerReviewsAction,
  putResponseToCustomerReviewFailureAction,
  putResponseToCustomerReviewSuccessAction,
} from './actions';

export function* getVenueDetailsRequested(params) {
  try {
    const data = yield call(
      api.get,
      `/api/venue/${params.venueId}.json?include=opening-hours,fulfillment-communication,images`,
    );

    yield put(receiveVenueDetailsAction(data));
  } catch (err) {
    yield put(requestVenueDetailsFailureAction(err));
  }
}

export function* getVenueStatisticsRequested(params) {
  try {
    const data = yield call(api.get, `/api/venue/${params.venueId}/statistics.json`);

    yield put(receiveVenueStatisticsAction(data));
  } catch (err) {
    yield put(requestVenueStatisticsFailureAction(err));
  }
}

export function* getSupplierDetailsRequested(params) {
  try {
    const data = yield call(api.get, `/api/supplier/${params.supplierId}.json`);

    yield put(receiveSupplierDetailsAction(data));
  } catch (err) {
    yield put(requestSupplierDetailsFailureAction(err));
  }
}

export function* getCustomerReviewsRequested(params) {
  const { venueId, page, size } = params;

  try {
    const data = yield call(api.get, `/api/venue/${venueId}/reviews?page=${page}&size=${size}`);

    yield put(receiveCustomerReviewsAction(data));
  } catch (err) {
    yield put(requestCustomerReviewsFailureAction(err));
  }
}

export function* putResponseToCustomerReview(params) {
  const { venueId, reviewId, content } = params;

  try {
    const data = yield call(api.put, `/api/venue/${venueId}/review/${reviewId}/venue-response`, {
      content,
    });

    yield put(putResponseToCustomerReviewSuccessAction(reviewId, data));
  } catch (err) {
    yield put(putResponseToCustomerReviewFailureAction(err));
  }
}
