import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { getMassEmailReceive, getMassEmailFailure } from './actions';

export default function* getMassEmailCountRequested() {
  try {
    const count = yield call(api.get, apiUrl('EMAIL_CAMPAIGN_COUNT'));
    yield put(getMassEmailReceive({ count }));
  } catch (err) {
    yield put(getMassEmailFailure(err));
  }
}
