import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class MoneyFlows extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-money-flows', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M21,11 L21,15.9975267 C21,17.102097 20.1088293,18 19.0044225,18 L9,18 L9,16 L13.584,16 L18.584,11 L21,11 Z M19,13.414 L16.414,16 L19.0008149,16 L19,13.414 Z M15,5 L15.1287203,7.00188331 L9.44780054,7.00092957 L9.44780054,7.00092957 L4,12.414 L4,13 L2,13 L2,7.00292933 C2,5.89890472 2.89047747,5 3.99339768,5 L15,5 Z M3.99844173,7 L3.999,9.585 L6.75314386,7.000471 L3.99844173,7 L3.99844173,7 Z M24,2 L24,8 L22,8 L22,5.414 L18.4142136,9 L17,7.58578644 L20.584,4 L18,4 L18,2 L24,2 Z M11.5,13 C12.0009098,13 12.5,12.3986364 12.5,11.5 C12.5,10.6013636 12.0009098,10 11.5,10 C10.9990902,10 10.5,10.6013636 10.5,11.5 C10.5,12.3986364 10.9990902,13 11.5,13 Z M5.58578644,15 L7,16.4142136 L3.41421356,20 L6,20 L6,22 L0,22 L0,16 L2,16 L2,18.5857864 L5.58578644,15 Z M11.5,8 C13.2441125,8 14.5,9.62800225 14.5,11.5 C14.5,13.3719977 13.2441125,15 11.5,15 C9.75588745,15 8.5,13.3719977 8.5,11.5 C8.5,9.62800225 9.75588745,8 11.5,8 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(MoneyFlows);
