import apiUrl from 'common/api-url';
import { useMutation, useQueryCache } from 'react-query';
import { post } from 'reduxStore/apiService';
import { FORM_TEMPLATE_STATUSES, FormTemplate } from './types';
import { formTemplatesListCacheKey } from './consts';

type UseFormTemplatesEnableActionsProps = {
  templateId: FormTemplate['id'];
};

export const useFormTemplatesEnableActions = ({
  templateId,
}: UseFormTemplatesEnableActionsProps) => {
  const queryCache = useQueryCache();

  const onMutateHandler = ({ status }: { status: keyof typeof FORM_TEMPLATE_STATUSES }) => {
    return () => {
      const queryCacheKey = formTemplatesListCacheKey();
      queryCache.cancelQueries(queryCacheKey);

      const previousFormTemplates = queryCache.getQueryData(queryCacheKey);

      queryCache.setQueryData(queryCacheKey, (oldTemplates: FormTemplate[] = []) =>
        oldTemplates.map((template) => ({
          ...template,
          status: template.id === templateId ? status : template.status,
        })),
      );

      return () => queryCache.setQueryData(queryCacheKey, previousFormTemplates);
    };
  };

  const onErrorHandler = (err, newTodo, rollback) => rollback();

  const [enableSettingMutation, { isLoading: isLoadingEnableMutation }] = useMutation(
    () =>
      post(
        apiUrl('CONSULTATION_FORMS_TEMPLATES_ENABLE', {
          venueId: App.getVenueId(),
          templateId,
        }),
      ),
    {
      onMutate: onMutateHandler({ status: FORM_TEMPLATE_STATUSES.ENABLED }),
      onError: onErrorHandler,
    },
  );

  const [disableSettingMutation, { isLoading: isLoadingDisableMutation }] = useMutation(
    () =>
      post(
        apiUrl('CONSULTATION_FORMS_TEMPLATES_DISABLE', {
          venueId: App.getVenueId(),
          templateId,
        }),
      ),
    {
      onMutate: onMutateHandler({ status: FORM_TEMPLATE_STATUSES.DISABLED }),
      onError: onErrorHandler,
    },
  );

  return {
    enableSettingMutation,
    disableSettingMutation,
    isLoading: isLoadingEnableMutation || isLoadingDisableMutation,
  };
};
