import React from 'react';
import Scoring from './Scoring';
import ListingStrength from './ListingStrength';

interface ITrafficLightsProps extends React.HTMLAttributes<Element> {
  appointmentOrders?: {};
  reviews?: {};
  thresholds?: {};
  menuData?: {};
  bankAccount?: {};
}

export default class TrafficLights extends React.Component<ITrafficLightsProps, {}> {
  render() {
    const { appointmentOrders, reviews, thresholds } = this.props;
    return (
      <div className="traffic-lights">
        <Scoring appointmentOrders={appointmentOrders} reviews={reviews} thresholds={thresholds} />
        <ListingStrength {...this.props} />
      </div>
    );
  }
}
