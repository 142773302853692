import { trackEvent, ACTIONS } from 'common/analytics';

export class ClientImportAnalytics {
  public static CATEGORY = 'clients-import-flow';

  public static trackFailureHelpCenterClicked() {
    trackEvent(ClientImportAnalytics.CATEGORY, ACTIONS.CLICK, 'help-center-link');
  }

  public static trackFailureLiveChatClicked() {
    trackEvent(ClientImportAnalytics.CATEGORY, ACTIONS.CLICK, 'live-chat');
  }

  public static trackContinueToCampaignPromptClicked() {
    trackEvent(ClientImportAnalytics.CATEGORY, ACTIONS.CLICK, 'continue-to-campaign-prompt');
  }

  public static trackLaunchCampaignClicked() {
    trackEvent(ClientImportAnalytics.CATEGORY, ACTIONS.CLICK, 'launch-campaign');
  }

  public static trackCloseCampaignPromptClicked() {
    trackEvent(ClientImportAnalytics.CATEGORY, ACTIONS.CLICK, 'close-campaign-prompt');
  }
}
