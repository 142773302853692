import React from 'react';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import LocalStorage from 'common/local-storage';
import { Checkbox } from 'components/common/Checkbox';
import { PromotionalOffersCampaignInitiation as Analytics } from 'common/analytics';

import ReactDialog from 'components/common/react-dialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import style from './style.scss';

export interface Props {
  isVisible: boolean;
  discountId: number | null;
  onClose: () => void;
}

export interface State {
  isChecked: boolean;
}

interface Lang {
  [key: string]: string;
}

const lang: Lang = Wahanda.lang.menu.discounts.discountList.emailCampaign;

export const LS_KEY = 'DiscountsTab:showDiscountEmailCampaignDialog';

export default class EmailCampaignDialog extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isChecked: false,
    };

    this.handleChecked = this.handleChecked.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleDoNotShow = this.handleDoNotShow.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.isVisible && this.props.isVisible) {
      Analytics.trackDiscountEmailDialogShown();
    }
  }

  public render() {
    const { isVisible } = this.props;

    if (!isVisible) {
      return null;
    }

    const { isChecked } = this.state;

    return (
      <ReactDialog
        dataTest="discount-email-campaign-modal"
        // @ts-expect-error ts-migrate(2769) FIXME: Property 'autoHeight' does not exist on type 'Intr... Remove this comment to see the full error message
        autoHeight
        title={lang.windowTitle}
        width={520}
        warnWhenClosingChangedForms={false}
        onClose={this.handleCloseClick}
      >
        <div className={classnames(style.wrapper)}>
          <h1 className={classnames(style.title)}>{lang.title}</h1>
          <p>{lang.description}</p>
          <p>
            <Checkbox
              checked={isChecked}
              onChange={this.handleChecked}
              name="do-not-show-campaign"
              label={lang.doNotShow}
            />
          </p>
        </div>
        <DialogFooter fullWidth>
          <DialogFooterButton
            dataTest="campaign-dialog-close-button"
            title={Wahanda.lang.shared.close}
            type="secondary"
            onClick={this.handleCloseClick}
          />
          <DialogFooterButton title={lang.callToAction} onClick={this.handleActionClick} />
        </DialogFooter>
      </ReactDialog>
    );
  }

  private handleChecked(): void {
    const { isChecked } = this.state;

    this.setState({
      isChecked: !isChecked,
    });
  }

  private handleDoNotShow(): void {
    const { isChecked } = this.state;
    if (isChecked) {
      Analytics.trackDoNotShow();
      LocalStorage.setForVenue(LS_KEY, true);
    }
  }

  private handleCloseClick(): void {
    const { onClose } = this.props;

    this.handleDoNotShow();
    return onClose();
  }

  private handleActionClick(): void {
    const { discountId } = this.props;

    this.handleDoNotShow();

    Analytics.trackSmartDiscountsInitFromSave();
    window.location = Wahanda.Url.getFullUrl(
      'marketing?referrer=discounts-link',
      `email-campaign/smart-discounts/${discountId}`,
    );
  }
}
