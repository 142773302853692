import React from 'react';
import moment from 'common/moment';
import { PopoverBody } from 'components/common/Popover';
import { Chevron } from 'components/common/Icon';
import { ChangeYearButton } from './ChangeYearButton';

import style from './MonthPicker.scss';

interface State {
  date: string;
}

export interface MonthPickerProps {
  isFutureLimited?: boolean;
  locale?: string;
  date: string;
  onMonthSelected: (selectedMonthDate: string) => void;
}

export class MonthPicker extends React.PureComponent<MonthPickerProps, State> {
  private fullDateFormat = 'YYYY-MM-DD';

  private yearFormat = 'YYYY';

  private monthFormat = 'MMMM';

  constructor(props: MonthPickerProps) {
    super(props);

    this.state = {
      date: props.date,
    };

    this.reduceYear = this.reduceYear.bind(this);
    this.increaseYear = this.increaseYear.bind(this);
    this.renderMonth = this.renderMonth.bind(this);
  }

  public static defaultProps = {
    isFutureLimited: true,
  };

  public render() {
    const { date } = this.state;
    const isYearInTheFuture = this.props.isFutureLimited
      ? moment(date).startOf('year').add(1, 'year').isAfter(Date.now())
      : false;
    return (
      <div className={style.monthPicker}>
        <PopoverBody>
          <div className={style.header}>
            <ChangeYearButton className={style.backButton} onClick={this.reduceYear}>
              <Chevron className={style.icon} />
            </ChangeYearButton>
            <strong className={style.currentMonth}>
              {moment(date).locale(this.locale).format(this.yearFormat)}
            </strong>
            <ChangeYearButton
              className={style.forwardButton}
              onClick={this.increaseYear}
              disabled={isYearInTheFuture}
            >
              <Chevron className={style.icon} />
            </ChangeYearButton>
          </div>
          {this.renderMonths()}
        </PopoverBody>
      </div>
    );
  }

  private get locale(): string {
    const { locale } = this.props;
    if (locale == null) {
      return 'en';
    }
    return locale;
  }

  private renderMonths() {
    const result: JSX.Element[] = [];

    for (let i = 0; i < 12; i += 1) {
      const month = moment(this.state.date).locale(this.locale).month(i);
      const isMonthInTheFuture = this.props.isFutureLimited
        ? moment(month).isAfter(moment(Date.now()))
        : false;
      const isCurrentlySelected = moment(month).isSame(moment(this.props.date), 'month');
      result.push(
        this.renderMonth(month.format(this.monthFormat), isCurrentlySelected, isMonthInTheFuture),
      );
    }

    return <div className={style.months}>{result}</div>;
  }

  private onClick = (month: string) => {
    const { onMonthSelected } = this.props;

    return () =>
      onMonthSelected(moment(this.state.date).locale(this.locale).month(month).format('YYYY-MM'));
  };

  private renderMonth(month: string, isSelected: boolean, isDisabled: boolean) {
    return (
      <ChangeYearButton
        onClick={this.onClick(month)}
        className={style.monthButton}
        key={month}
        disabled={isDisabled}
        selected={isSelected}
      >
        <p className={style.capitalize}>{month}</p>
      </ChangeYearButton>
    );
  }

  private reduceYear() {
    this.setState({
      date: moment(this.state.date).subtract(1, 'year').format(this.fullDateFormat),
    });
  }

  private increaseYear() {
    this.setState({
      date: moment(this.state.date).add(1, 'year').format(this.fullDateFormat),
    });
  }
}
