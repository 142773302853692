import App from 'common/backbone-app';
import apiUrl from 'common/api-url';
import * as api from 'reduxStore/apiService';
import { queryCache, useMutation } from 'react-query';
import { submitWebToCaseForm } from 'common/salesforce';
import { VenueStatusType } from 'components/shared/OnboardingWidget/OnboardingWidget.types';
import {
  ChecklistCacheType,
  checklistKey,
} from 'components/shared/OnboardingWidget/hooks/useChecklist';

const goLive = () => {
  const venueId = App.getVenueId();
  return api.post(apiUrl('VENUE_GO_LIVE', { venueId }));
};

const goLiveSalesforce = () => {
  const countryCode = App.config.get('venue').countryCode;
  return submitWebToCaseForm({
    recordType: '01220000000Qw9p',
    subject: 'Self set up: request go live',
    Country__c: countryCode,
  });
};

const updateVenueStatus = (venueStatusType: VenueStatusType) =>
  App.config.set('venue', {
    ...App.config.get('venue'),
    venueStatusType,
  });

const optimisticUpdate = () => {
  const queryCacheKey = checklistKey();
  queryCache.cancelQueries(queryCacheKey);
  const old = queryCache.getQueryData(queryCacheKey);
  queryCache.setQueryData<ChecklistCacheType>(queryCacheKey, (old = { tasks: [] }) => ({
    ...old,
    checklistCompleted: true,
  }));
  return () => queryCache.setQueryData(queryCacheKey, old);
};

export const useGoLive = () =>
  useMutation(goLive, {
    onMutate: optimisticUpdate,
    onError: (error, variables, rollback) => rollback(),
    onSuccess: () => {
      goLiveSalesforce();
      updateVenueStatus(VenueStatusType.ACTIVE);
    },
  });
