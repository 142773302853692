import React from 'react';
import { Button } from 'components/common/Button';
import { Plus } from 'components/common/Icon';

interface IEmptyListProps extends React.HTMLAttributes<Element> {
  icon?: string;
  text?: string;
  addButtonText?: string;
  addAction?: (...args: any[]) => any;
}

export class EmptyList extends React.PureComponent<IEmptyListProps, {}> {
  static defaultProps = {
    icon: undefined,
    text: undefined,
    addButtonText: undefined,
    addAction: undefined,
  };

  render() {
    const { icon, text, addButtonText, addAction } = this.props;
    const iconClassNames = `icon ${icon}`;
    return (
      <div className="empty-list-container">
        <div className={iconClassNames} />
        <div className="add-new-wrapper">
          <div>
            {text}
            <div>
              <Button
                icon={<Plus />}
                label={addButtonText}
                onClick={addAction}
                dataTest="empty-list-button"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
