import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import Wahanda from 'common/wahanda';
import { Initializer } from 'common/types/initializers';
import { SingleGroup } from 'components/menu/AddServicesModal/store/types';
import { OfferTypeChoiceDialog } from './OfferTypeChoiceDialog';

const store = storeBuilder();
const container = Wahanda.Dialogs.getReactContainer();
interface Params {
  onSelect: () => void;
  singleGroup?: SingleGroup;
}

export const OfferTypeChoiceDialogInitializer = (): Initializer => {
  const onClose = () => ReactDOM.unmountComponentAtNode(container);
  return {
    render: (params: Params) => {
      ReactDOM.render(
        <Provider store={store}>
          <OfferTypeChoiceDialog
            onClose={onClose}
            onSelect={params.onSelect}
            singleGroup={params.singleGroup}
          />
        </Provider>,
        container,
      );
    },
    destroy: onClose,
  };
};
