import React from 'react';
import Wahanda from 'common/wahanda';
import ReactDialog from 'components/common/react-dialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import { StateCard } from 'components/common/StateCard';
import DiscountTag from 'components/common/icons/DiscountTag';
import Loader from 'components/common/Loader';
import style from './style.scss';
import LinkToPreview from '../LinkToPreview';
import LinkContent from '../LinkToPreview/linkContent';

const lang = Wahanda.lang.marketing.campaignSelection.promotionalOffers.serviceOnSale;
const PREVIEW_URL = 'email-campaign/service-on-sale';
interface IServiceOnSaleSelectProps extends React.HTMLAttributes<Element> {
  onClose: (...args: any[]) => any;
  isLoaded: boolean;
  actions: {
    getTreatmentGroupsRequest: (...args: any[]) => any;
    selectTreatmentGroup: (...args: any[]) => any;
  };
  serviceOnSaleTreatmentGroups: {
    id: number;
    name: string;
  }[];
  map?: any;
  length?: any;
  getTreatmentGroupsRequest?: any;
}

export default class ServiceOnSaleSelect extends React.Component<IServiceOnSaleSelectProps, {}> {
  static defaultProps = {
    serviceOnSaleTreatmentGroups: null,
  };

  componentDidMount() {
    this.props.actions.getTreatmentGroupsRequest();
  }

  redirectToMenuPage = () => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Location'... Remove this comment to see the full error message
    window.location = `menu#venue/${App.getVenueId()}`;
  };

  noValidTreatmentGroupsToShow = () => this.props.serviceOnSaleTreatmentGroups.length === 0;

  renderEmptyState = () => (
    <React.Fragment>
      <div className={style.emptyState}>
        <StateCard
          heading={lang.emptyState.title}
          message={lang.emptyState.description}
          image={<DiscountTag />}
        />
      </div>
      <DialogFooter>
        <DialogFooterButton title={lang.emptyState.button} onClick={this.redirectToMenuPage} />
      </DialogFooter>
    </React.Fragment>
  );

  renderTreatmentGroupsList = () => (
    <div className={style.dialogContent}>
      <div className={style.textContainer}>
        <span className={style.header}>{lang.header}</span>
        <span className={style.description}>{lang.description}</span>
      </div>
      <div className={style.box}>
        {this.props.serviceOnSaleTreatmentGroups.map((group) => (
          <LinkToPreview key={group.id} url={`${PREVIEW_URL}/${group.id}`}>
            <LinkContent>
              <div className={style.treatmentGroup}>
                <span className={style.name}>{group.name}</span>
              </div>
            </LinkContent>
          </LinkToPreview>
        ))}
      </div>
    </div>
  );

  renderDialogContent = () =>
    this.noValidTreatmentGroupsToShow()
      ? this.renderEmptyState()
      : this.renderTreatmentGroupsList();

  render() {
    const { onClose, isLoaded } = this.props;
    return (
      <ReactDialog
        dataTest="email-campaign-service-on-sale-modal"
        title={lang.title}
        onClose={onClose}
        noContentPadding
      >
        {isLoaded ? (
          this.renderDialogContent()
        ) : (
          <div className={style.loader}>
            <Loader />
          </div>
        )}
      </ReactDialog>
    );
  }
}
