import Wahanda from 'common/wahanda';
import { ShiftWorkingHourType } from '../utils/types';

const LANG = Wahanda.lang.settings.shifts;

export const DAYS_PER_WEEK = 7;

export const MAX_DAYS_RANGE = 90;

export enum SCHEDULE_ROW_TYPE {
  VENUE = 'VENUE',
  STYLIST = 'STYLIST',
}

const sharedListOptions = [
  {
    label: LANG.modalEditShift.actionDayOff,
    value: ShiftWorkingHourType.DAY_OFF,
  },
  {
    label: LANG.modalEditShift.actionCustom,
    value: ShiftWorkingHourType.CUSTOM,
  },
];

export const editShiftOptionsList = [
  {
    label: LANG.modalEditShift.actionDefault,
    value: ShiftWorkingHourType.VENUE,
  },
  ...sharedListOptions,
];

export const editScheduleOptionsList = [
  {
    label: LANG.modalEditShift.actionDefault,
    value: ShiftWorkingHourType.SCHEDULE,
  },
  ...sharedListOptions,
];

export const editVenueShiftOptionsList = [
  {
    label: LANG.modalEditVenueShift.actionDefault,
    value: ShiftWorkingHourType.STANDARD,
  },
  {
    label: LANG.modalEditVenueShift.actionDayOff,
    value: ShiftWorkingHourType.DAY_OFF,
  },
  {
    label: LANG.modalEditVenueShift.actionCustom,
    value: ShiftWorkingHourType.CUSTOM,
  },
];
