(function () {
  /**
   * Date functions.
   */
  Wahanda.Date = {
    // MONDAY -> 1
    dayStringToNumberMap: {
      SUNDAY: 0,
      MONDAY: 1,
      TUESDAY: 2,
      WEDNESDAY: 3,
      THURSDAY: 4,
      FRIDAY: 5,
      SATURDAY: 6,
    },
    // 1 -> MONDAY. Sunday is 0.
    dayNumberToStringMap: [
      'SUNDAY',
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
    ],
    apiDateFormat: 'yy-mm-dd',
    SECONDS_IN_DAY: 86400,
    /**
     * Parses the given date to a Date object.
     *
     * If format is {date}T{time}Z, returned date is converted from UTC to venue's local time
     * If format is {date}T{time}L, the time is in local client's browser time
     * If format is {date}T{time}, returned date is in current venue's local time
     *
     * @param string str
     * @param boolean local If date is not UTC,
     * @return Date
     * @throws Error if string is invalid
     */
    parse: function (str) {
      var pattern = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})([Z]?)/;
      var parts = str.match(pattern);

      if (!parts) {
        throw new Error('"' + str + '" does not look like a valid date');
      }

      if (parts[7] === 'Z') {
        // UTC time converted to Venue's date
        return this.createVenueDate(
          Date.UTC(
            parseInt(parts[1], 10),
            parseInt(parts[2], 10) - 1,
            parseInt(parts[3], 10),
            parseInt(parts[4], 10),
            parseInt(parts[5], 10),
            parseInt(parts[6], 10),
            0,
          ),
        );
      } else {
        // Venue local time
        return new Date(
          parseInt(parts[1], 10),
          parseInt(parts[2], 10) - 1,
          parseInt(parts[3], 10),
          parseInt(parts[4], 10),
          parseInt(parts[5], 10),
          parseInt(parts[6], 10),
          0,
        );
      }
    },

    /**
     * Converts the given Date object to string accepted by the API.
     *
     * @throws Error if the passed in date isn't correct.
     *
     * @param Date date
     * @return string
     */
    toApiString: function (date) {
      if (!this.isValidDate(date)) {
        const typeError = new TypeError(
          `Incorrect date of type '${typeof date}' [${String(date)}] passed in to toApiString`,
        );

        if (window.onerror) {
          try {
            window.onerror(`${typeError.message}\n${typeError.stack}`);
          } catch (e) {
            // Ignore if onerror isn't defined.
          }
        }

        throw typeError;
      }

      return (
        date.getFullYear() +
        '-' +
        this._makeDoubleDigit(date.getMonth() + 1) +
        '-' +
        this._makeDoubleDigit(date.getDate())
      );
    },

    /**
     * Creates a date in venue time zone.
     *
     * @param $_ Accepts all parameters, that the Date object accepts
     * @return Date
     */
    createVenueDate: function () {
      var date;

      switch (arguments.length) {
        case 1:
          date = new Date(arguments[0]);
          break;

        case 3:
          date = new Date(arguments[0], arguments[1], arguments[2]);
          break;

        case 4:
          date = new Date(arguments[0], arguments[1], arguments[2], arguments[3]);
          break;

        case 5:
          date = new Date(arguments[0], arguments[1], arguments[2], arguments[3], arguments[4]);
          break;

        case 6:
          date = new Date(
            arguments[0],
            arguments[1],
            arguments[2],
            arguments[3],
            arguments[4],
            arguments[5],
          );
          break;

        case 7:
          date = new Date(
            arguments[0],
            arguments[1],
            arguments[2],
            arguments[3],
            arguments[4],
            arguments[5],
            arguments[6],
          );
          break;

        default:
          date = new Date();
      }
      return this._adjustDate(date, true);
    },

    /**
     * Adjusts the given date to the venue's time zone.
     *
     * @param Date date
     * @param boolean bOnClient
     * @return Date
     */
    _adjustDate: function (date, bOnClient) {
      var d = new Date();
      var clientOffset = date.getTimezoneOffset();
      var adjust = App.config.get('venue').timeOffset + clientOffset;
      if (!bOnClient) {
        adjust = -adjust;
      }
      return new Date(date.getTime() + adjust * 60000);
    },

    /**
     * Adds (or subtracts, if negative) given minutes from the given date.
     * @param Date date
     * @param int minutes
     *
     * @return Date The new date object
     */
    addMinutesToDate: function (date, minutes) {
      var dt = new Date(date.getTime() + minutes * 60000);
      return new Date(dt.getTime() + (dt.getTimezoneOffset() - date.getTimezoneOffset()) * 60000);
    },

    addDaysToDate: function (date, days) {
      // 1440 = minutes in day
      return this.addMinutesToDate(date, days * 1440);
    },

    addYearsToDate: function (date, years) {
      var dt = new Date(date.getTime());
      return new Date(dt.setFullYear(dt.getUTCFullYear() + years));
    },

    /**
     * Formats the date to format in jQuery Datepicker format.
     * @see http://docs.jquery.com/UI/Datepicker/formatDate
     *
     * @param String format
     * @param Date date
     * @return String
     */
    formatDate: function (format, date) {
      return $.datepicker.formatDate(format, date);
    },

    /**
     * Formats the date to format in jQuery Datepicker format.
     * @see self::formatDate and Wahanda.Time.toFormatted
     *
     * @param String dateFormat
     * @param string timeFormat
     * @param Date date
     * @return {date, time} - formatted object
     */
    formatDateTime: function (dateFormat, timeFormat, date) {
      var minutes = Wahanda.Time.getDateMinutes(date);
      return {
        date: this.formatDate(dateFormat, date),
        time: Wahanda.Time.toFormatted(minutes, timeFormat),
      };
    },

    /**
     * Formats to venue default time and date strings.
     *
     * @param string|Date date If string, must be in `Wahanda.Date.parse` acceptable format
     * @see formatDateTime
     */
    formatToDefaultFullDate: function (date) {
      return this.formatDateTime(
        App.config.get('jqueryDateFormat').defaultDate,
        App.config.get('jqueryDateFormat').defaultTime,
        typeof date === 'string' ? this.parse(date) : date,
      );
    },

    /**
     * Formats to venue default date string.
     *
     * @param string|Date date If string, must be in `Wahanda.Date.parse` acceptable format
     * @see formatDate
     */
    formatToDefaultDate: function (date) {
      return this.formatDate(
        App.config.get('jqueryDateFormat').defaultDate,
        typeof date === 'string' ? this.parse(date) : date,
      );
    },

    /**
     * Formats Date object to API date and time format.
     *
     * @see formatToDefaultFullDate
     */
    formatToApiFullDate: function (date) {
      return this.formatDateTime(
        this.apiDateFormat,
        Wahanda.Time.apiTimeFormat,
        typeof date === 'string' ? this.parse(date) : date,
      );
    },

    /**
     * Is the given date valid?
     *
     * @param Date date
     * @return boolean
     */
    isValidDate: function (date) {
      return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.valueOf());
    },

    /**
     * @param String dateString
     * @return boolean
     */
    isValidApiDateString: function (dateString) {
      var pattern = /^(\d{4})-(\d{2})-(\d{2})$/;
      return pattern.test(dateString);
    },

    /**
     * Is the given date today?
     *
     * @param Date date
     * @return boolean
     */
    isToday: function (date) {
      var today = this.createVenueDate();
      return this.isEqualDates(date, today);
    },

    isEqualDates: function (date1, date2) {
      return (
        date1.getYear() == date2.getYear() &&
        date1.getMonth() == date2.getMonth() &&
        date1.getDate() == date2.getDate()
      );
    },

    /**
     * Is the given date inside of other two?
     *
     * @param Date date
     * @param Date from
     * @param Date to
     * @return boolean
     */
    isDateBetween: function (date, from, to) {
      var time = this.getMidnight(date).getTime();
      return time >= this.getMidnight(from).getTime() && time <= this.getMidnight(to).getTime();
    },

    /**
     * Returns the given date with time set to 00:00:00.
     * If no date is passed, current date is returned.
     *
     * @param Date date
     * @return Date
     */
    getMidnight: function (date) {
      if (!date) {
        date = new Date();
      }
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    },

    /**
     * Gets days in month of the given Date object.
     *
     * @param Date date
     * @return int
     */
    getDaysInMonth: function (date) {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    },

    _makeDoubleDigit: function (value) {
      return value < 10 ? '0' + value : value;
    },

    /**
     * Do given date ranges overlap?
     *
     * @param Array range1 [from, to]
     * @param Array range2 [from, to]
     * @return boolean
     */
    doOverlap: function (range1, range2) {
      // (StartA <= EndB) and (EndA >= StartB)
      return (
        range1[0].getTime() <= range2[1].getTime() && range1[1].getTime() >= range2[0].getTime()
      );
    },

    /**
     * Creates date from string.
     *
     * @param String date yyyy-mm-dd
     * @param String time OPTIONAL HH:mm (default 00:00)
     * @param String fromUTC OPTIONAL Convert the time from UTC to Local venue time (default off)
     * @return Date
     */
    createDate: function (date, time, fromUTC) {
      time = time || '00:00';
      return this.parse(date + 'T' + time + ':00' + (fromUTC ? 'Z' : ''));
    },

    /**
     * Creates date from string, if the given param is string. Otherwise return what is given.
     *
     * @param String | Date date
     * @return Date
     */
    createDateIfString: function (date) {
      if (typeof date === 'string') {
        date = this.createDate(date);
      }
      return date;
    },

    /**
     * Returns array of dates, having the same month as "monthDate", but days from "days" array.
     * @param Date monthDate
     * @param Array days Array of integers
     */
    getMonthDates: function (monthDate, days) {
      var year = monthDate.getFullYear();
      var month = monthDate.getMonth();
      var dates = [];
      _.each(days, function (day) {
        dates.push(new Date(year, month, day));
      });
      return dates;
    },

    getMonthDateApiStrings: function (monthDate, days) {
      var dates = this.getMonthDates(monthDate, days);
      var strings = [];
      for (var i = 0, len = dates.length; i < len; i++) {
        strings.push(this.toApiString(dates[i]));
      }
      return strings;
    },

    /**
     * Returns date (ignoring the time) range information in contrast to currentDate.
     *
     * E.g. is the dated in the past, or currentDate in the middle, or everything will be in the future.
     *
     * @param Date from OPTIONAL
     * @param Date to OPTIONAL
     * @param Date currentDate OPTIONAL Defaults to current venue date
     *
     * @return Object {
     * 	  isFuture: boolean,
     *    isCurrent: boolean,
     *    isPast: boolean
     * }
     */
    compareRangeToNow: function (from, to, currentDate) {
      currentDate || (currentDate = this._adjustDate(this.createVenueDate()));

      var now = this.getMidnight(currentDate).getTime();
      from = from ? this.getMidnight(from).getTime() : null;
      to = to ? this.getMidnight(to).getTime() : null;

      var isFuture = false;
      var isCurrent = false;
      var isPast = false;

      if (from && from > now) {
        isFuture = true;
      } else if (to && now > to) {
        isPast = true;
      } else {
        isCurrent = true;
      }

      return {
        isFuture: isFuture,
        isCurrent: isCurrent,
        isPast: isPast,
      };
    },

    /**
     * Converts day enumerations (MONDAY, ...) array to corresponding integer (weekday number) array.
     *
     * @param Array list
     * @return Array
     */
    dayEnumsToNumbers: function (list) {
      var ret = [];
      for (var i = 0, len = list.length; i < len; i++) {
        ret.push(this.dayStringToNumberMap[list[i]]);
      }
      return ret;
    },

    isPast: function (date, checkOnlyDates) {
      date = Wahanda.Date.createDateIfString(date);
      if (checkOnlyDates) {
        return Wahanda.Date.compareRangeToNow(null, date).isPast;
      } else {
        var now = Wahanda.Date.createVenueDate();
        return now.getTime() > date.getTime();
      }
    },

    isFuture: function (date, checkOnlyDates) {
      if (checkOnlyDates) {
        return Wahanda.Date.compareRangeToNow(null, date).isFuture;
      } else {
        var now = Wahanda.Date.createVenueDate();
        return now.getTime() < date.getTime();
      }
    },

    /**
     * Calculates date difference in days between two given dates.
     *
     * @param Date date1
     * @param Date date2
     *
     * @return int Can be negative, if date2 ir greater
     */
    getDayDifference: function (date1, date2) {
      var time1 = date1.getTime();
      var time2 = date2.getTime();
      var diff = (time1 - time2) / 1000;

      return Math.floor(diff / Wahanda.Date.SECONDS_IN_DAY);
    },

    /**
     * Return the first day of week for this venue, 0 being sunday.
     *
     * @return int
     */
    venueFirstDayOfWeek: function () {
      return this.dayStringToNumberMap[App.config.get('jqueryDateFormat').firstDayOfWeek];
    },

    /**
     * Get a date range which spans over one full week.
     *
     * This means that if today is not the start of the week, the range will be from today till next week`s last
     * day.
     *
     * @param Date startFrom
     * @return Object { from: Date, to: Date }
     */
    getFullWeekRange: function (startFrom) {
      var firstDay = this.venueFirstDayOfWeek();
      var currentDay = startFrom.getDay();
      var addDays = 7;

      if (currentDay === firstDay) {
        addDays = 6;
      } else if (currentDay > firstDay) {
        addDays += firstDay + 6 - currentDay;
      } else {
        addDays += firstDay - currentDay - 1;
      }

      return {
        from: this.getMidnight(startFrom),
        to: new Date(
          startFrom.getFullYear(),
          startFrom.getMonth(),
          startFrom.getDate() + addDays,
          23,
          59,
          59,
        ),
      };
    },

    /**
     * Get date at the venue's firstDayOfWeek for a date.
     *
     * @param Date date to find week start for
     * @return date
     */
    getWeekStartDate: function (date) {
      var firstDay = this.venueFirstDayOfWeek();
      var currentDay = date.getDay();
      var subtractDays = currentDay - firstDay;
      var weekStartDate = new Date(date);

      weekStartDate.setDate(weekStartDate.getDate() - subtractDays);

      return weekStartDate;
    },

    /**
     * Returns array of each day in the given date range.
     *
     * @param Date from
     * @param Date to
     * @return Array
     */
    getRangeDays: function (from, to) {
      var days = [];

      from = this.getMidnight(this.createDateIfString(from));
      to = this.getMidnight(this.createDateIfString(to));

      if (from.getTime() > to.getTime()) {
        return days;
      }

      while (!this.isEqualDates(from, to)) {
        days.push(from);
        from = new Date(from.getFullYear(), from.getMonth(), from.getDate() + 1, 0, 0, 0);
      }
      days.push(to);

      return days;
    },

    getUTCDate: function (date) {
      if (!date) {
        var date = new Date();
      }
      var UTCDate = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
      );

      return UTCDate;
    },

    getUTCMidnight: function (date) {
      if (!date) {
        var date = new Date();
      }
      var UTCDate = new Date(
        Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
      );

      return UTCDate;
    },

    /**
     * Returns "today", "tommorow" or a string formatted date.
     *
     * @param Object options
     * > Date   date       The date to calculate distance to
     * > String dateFormat (optional) Format of date to use if the date is not today nor tomorrow
     * > Date   now        (optional) The date to use as the current date (starting point)
     *
     * @return String
     */
    getDistanceInWordsOrDate: function (options) {
      options = options || {};
      var now = this.getMidnight(options.now || this.createVenueDate());
      var date = this.getMidnight(options.date);
      var format = options.dateFormat || App.config.get('jqueryDateFormat').defaultDate;

      if (this.isEqualDates(date, now)) {
        return Wahanda.lang.date.today;
      } else if (1 === this.getDayDifference(date, now)) {
        return Wahanda.lang.date.tomorrow;
      }
      return this.formatDate(format, date);
    },

    /**
     * Returns the closest weekday in the future.
     *
     * @param Date date
     * @param Int weekDay 0 .. 6
     *
     * @return Date or null, if the passed in parameters are incorrect
     */
    getNextWeekDay: function (date, weekDay) {
      // eslint-disable-next-line
      if (!date instanceof Date || isNaN(weekDay)) {
        return null;
      }

      var cDay = date.getDay();
      var diff = (weekDay - cDay) % 7;
      if (diff <= 0) {
        diff += 7;
      }

      return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff, 0, 0, 0);
    },

    previousWeekdayNumber: function (day) {
      if (day === 0) {
        day = 7;
      }
      return day - 1;
    },

    /**
     * Returns a new Date object with the time set.
     *
     * This function supports two formats:
     *   > setTime(date, 'HH:MM');
     *   > setTime(date, HH, MM);
     *
     * @return Date
     */
    setTime: function (date, hours, minutes) {
      if (arguments.length === 2) {
        var time = Wahanda.Time.splitHours(hours);
        hours = time.hours;
        minutes = time.minutes;
      }
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
    },

    /**
     * Compare two dates.
     *
     * @param Date date1
     * @param Date date2
     * @param Object options (optional) Possible options:
     * > onlyDates Compare only dates. Time part is ignored.
     *
     * @return int Less than 0 if date1 is greater, > 0 - is date 2 is later, and 0 if dates are equal.
     */
    compare: function (date1, date2, options) {
      options = options || {};

      if (options.onlyDates) {
        date1 = Wahanda.Date.getMidnight(date1);
        date2 = Wahanda.Date.getMidnight(date2);
      }

      return date2.getTime() - date1.getTime();
    },

    /**
     * Get the latest date.
     *
     * @param boolean onlyDates (optional) Compare only dates, w/o times? Defaults to FALSE. Can be omitted.
     * @param Date ?any Any count of dates.
     * @return Date or null if nothing passed
     */
    max: function () {
      return minOrMax('max', arguments);
    },

    /**
     * Get the earliest date.
     *
     * @param boolean onlyDates (optional) Compare only dates, w/o times? Defaults to FALSE. Can be omitted.
     * @param Date ?any Any count of dates.
     * @return Date or null if nothing passed
     */
    min: function () {
      return minOrMax('min', arguments);
    },

    isSameMonth: function (date1, date2) {
      date1 = Wahanda.Date.createDateIfString(date1);
      date2 = Wahanda.Date.createDateIfString(date2);

      return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
    },

    isCurrentMonth: function (date) {
      return Wahanda.Date.isSameMonth(date, Wahanda.Date.createVenueDate());
    },
  };

  function minOrMax(type, args) {
    var onlyDates = false;
    if (args && typeof args[0] === 'boolean') {
      onlyDates = args[0];
      args = Array.prototype.slice.call(args, 1);
    }
    return _[type](args, function (date) {
      return (onlyDates ? Wahanda.Date.getMidnight(date) : date).getTime();
    });
  }
})();
