import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Website extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-website', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M10.0000125,5 L8.0000125,5 L8.0000125,7 L10.0000125,7 L10.0000125,5 Z M12.0000125,5 L12.0000125,7 L18.0000125,7 L18.0000125,9 L4.0000125,9 L4.0000125,19 L20.0000125,19 L20.0000125,5 L12.0000125,5 Z M6.0000125,5 L4.0000125,5 L4.0000125,7 L6.0000125,7 L6.0000125,5 Z M2.00000622,5 C2.00000622,3.898 2.8900125,3 3.9930125,3 L20.0070125,3 C21.1100125,3 22.0000062,3.895 22.0000062,5 L22.0000062,19 C22.0000062,20.102 21.1100125,21 20.0070125,21 L3.9930125,21 C3.4633848,20.9994707 2.95570377,20.7883633 2.58185736,20.4132038 C2.20801094,20.0380444 1.99868484,19.5296263 2.00000622,19 L2.00000622,5 Z M9.6210125,15.793 L8.2070125,17.207 L5.0000125,14 L8.2070125,10.793 L9.6210125,12.207 L7.8280125,14 L9.6210125,15.793 Z M16.1930125,14 L14.4000125,12.207 L15.8140125,10.793 L19.0210125,14 L15.8140125,17.207 L14.4000125,15.793 L16.1930125,14 Z M12.0510125,10.684 L13.9490125,11.316 L11.9490125,17.316 L10.0510125,16.684 L12.0510125,10.684 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Website);
