import React from 'react';
import { HashRouter as Router, Switch, Route, RouteComponentProps } from 'react-router-dom';
import App from 'common/backbone-app';
import { TransactionsTab } from 'components/reports/transactions-tab';
import ROUTES from 'components/reports/newSalesReports/SalesReportContainer/reportRoutes';
import BookingsReport from './Bookings';
import Sales from './Sales';
import { ReportsDashboard } from './ReportsDashboard';
import { ClientReports } from '../ClientReports';
import NewSalesReports from '../newSalesReports/SalesReportContainer/container';
import POSReports from '../POSReports';

interface Props {
  actions: {
    requestSupplierDetailsAction: (supplierId: number) => void;
  };
  canViewVatSaleReport: boolean;
  canViewPosReports: boolean;
  canViewClientReports: boolean;
  canViewSaleReports: boolean;
  canViewOtherReports: boolean;
  canViewBookingsReport: boolean;
  canViewTransactionsReport: boolean;
  venueId: number;
}

export class Reports extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.renderReportsDashboard = this.renderReportsDashboard.bind(this);
    this.renderTransactionsReport = this.renderTransactionsReport.bind(this);
    this.renderPosReports = this.renderPosReports.bind(this);
  }

  public componentDidMount() {
    this.props.actions.requestSupplierDetailsAction(App.getSupplierId());
  }

  public render() {
    const {
      canViewClientReports,
      canViewBookingsReport,
      canViewTransactionsReport,
      canViewSaleReports,
      canViewPosReports,
      canViewVatSaleReport,
    } = this.props;
    return (
      <Router hashType="noslash">
        <Switch>
          {canViewSaleReports && <Route path="/venue/:venueId/sales" component={Sales} />}
          {canViewSaleReports && (
            <Route path={ROUTES.NEW_SALES_REPORTS} component={NewSalesReports} />
          )}
          {canViewClientReports && (
            <Route path="/venue/:venueId/clientReports" component={ClientReports} />
          )}
          {canViewBookingsReport && (
            <Route path="/venue/:venueId/bookings" component={BookingsReport} />
          )}
          {canViewTransactionsReport && (
            <Route
              path="/venue/:venueId/transactions/:date?"
              render={this.renderTransactionsReport}
            />
          )}
          {(canViewPosReports || canViewVatSaleReport) && (
            <Route path="/venue/:venueId/posReports" render={this.renderPosReports} />
          )}
          ;
          <Route path="/venue/:venueId/reportsDashboard" render={this.renderReportsDashboard} />
          <Route render={this.renderReportsDashboard} />
        </Switch>
      </Router>
    );
  }

  private renderPosReports() {
    const { canViewPosReports, canViewVatSaleReport } = this.props;
    return (
      <POSReports
        canViewVatSaleReport={canViewVatSaleReport}
        canViewPosReports={canViewPosReports}
      />
    );
  }

  private renderTransactionsReport(routerProps: RouteComponentProps) {
    return <TransactionsTab {...routerProps} />;
  }

  private renderReportsDashboard() {
    const {
      canViewClientReports,
      canViewOtherReports,
      canViewSaleReports,
      canViewPosReports,
      canViewBookingsReport,
      canViewTransactionsReport,
      canViewVatSaleReport,
      venueId,
    } = this.props;
    return (
      <ReportsDashboard
        canViewVatSaleReport={canViewVatSaleReport}
        canViewPosReports={canViewPosReports}
        canViewClientReports={canViewClientReports}
        canViewOtherReports={canViewOtherReports}
        canViewSaleReports={canViewSaleReports}
        canViewBookingsReport={canViewBookingsReport}
        canViewTransactionsReport={canViewTransactionsReport}
        venueId={venueId}
      />
    );
  }
}
