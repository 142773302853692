;(function($) {
	"use strict";
	/**
	 * A "error" tooltip, shown in forms.
	 * 
	 * @param string text Text to show
	 * @param Object qtipOptions OPTIONAL
	 * 
	 * @uses qtip2
	 * Copyright Wahanda.com
	 */
	$.fn.formErrorTip = function(text, qtipOptions) {
		var options = $.extend(
			{
				show: {
					event: "focus"
				},
				hide: {
					event: "blur"
				},
				position: {
					my: 'center left',
					at: 'center right',
					adjust: {
						x: 10
					}
				},
				style: {
					classes: 'ui-tooltip-form-error'
				}
			},
			qtipOptions,
			{ content: text }
		);
		this.qtip(options);
		
		return this;
	};

	$.fn.formErrorTip.hideWithTimeout = {
		event: false,
		inactive: 3000,
		effect: function() {
			$(this).fadeOut(500);
		}
	};
	
	// Derived helpers

	// Error tip on custom (non-form) elements
	$.fn.formCustomElementErrorTip = function(text, qtipOptions) {
		var options = _.extend(
			{
				show: { ready: true, event: false },
				hide: { event: "unfocus" }
			},
			qtipOptions
		);

		return this.formErrorTip(text, options);
	};
}(jQuery));
