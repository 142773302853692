import { SERVICE_PACKAGE, PACKAGE } from './constants';

/*
 *  Where objects have been destructured and tested for explicitly
 *  it's because that attribute can be null, so:
 *
 *    { offpeakDiscounts: {} }
 *
 *  and
 *
 *    { offpeakDiscounts: null }
 *
 *  are both valid. Consequently we can't use a default:
 *
 *    { offpeakDiscounts: {} = {} }
 *
 *  and so the less pretty "destructure then test for falsiness" has been used
 *
 */
export const discountRuleOffPeakTransformer = (discountRule) => {
  const { offpeakDiscounts } = discountRule;
  let maxOffPeakDiscount = 0;

  if (offpeakDiscounts) {
    const { days } = offpeakDiscounts;

    if (days) {
      const keys = Reflect.ownKeys(days);

      if (keys.length) {
        const maxPercentages = keys.map((key) => {
          const day = Reflect.get(days, key);
          const percentages = Object.values(day);

          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
          return Math.max(...percentages);
        });

        maxOffPeakDiscount = Math.max(...maxPercentages);
      }
    }
  }

  return {
    ...discountRule,
    offpeakDiscounts: {
      ...(offpeakDiscounts || {}),
      maxOffPeakDiscount,
    },
  };
};

/*
 *  See the comment preceding "discountRuleOffPeakTransformer"
 */
export const discountRuleLastMinuteTransformer = (discountRule) => {
  const { lastMinuteDiscount } = discountRule;
  let maxLastMinuteDiscount = 0;

  if (lastMinuteDiscount) {
    const { percentage } = lastMinuteDiscount;

    maxLastMinuteDiscount = percentage;
  }

  return {
    ...discountRule,
    lastMinuteDiscount: {
      ...(lastMinuteDiscount || {}),
      maxLastMinuteDiscount,
    },
  };
};

export const discountRulesOffPeakTransformer = (discountRules) =>
  discountRules.map(discountRuleOffPeakTransformer);

export const discountRulesLastMinuteTransformer = (discountRules) =>
  discountRules.map(discountRuleLastMinuteTransformer);

export const discountRulesAppliedTreatmentCountTransformer = (discountRules) =>
  discountRules.map((discountRule) => {
    const { assignedMenuItems } = discountRule;
    let numberOfTreatments = 0;

    if (Array.isArray(assignedMenuItems)) {
      const assignedActiveMenuItems = assignedMenuItems.filter((item) => item.active);
      numberOfTreatments = assignedActiveMenuItems.length;
    }

    return {
      ...discountRule,
      appliedTreatmentCount: numberOfTreatments,
    };
  });

export const discountRulesBookingHoursTransformer = (discountRules, venueDetails) => {
  /*
   *  Default values should come from somehwere ..?
   */
  const { leadTimeMinutes } = venueDetails;

  return discountRules.map((discountRule) => {
    const { lastMinuteDiscount } = discountRule;

    return {
      ...discountRule,
      lastMinuteDiscount: {
        ...(lastMinuteDiscount || {}),
        ...(!isNaN(leadTimeMinutes) ? { leadTimeMinutes } : { leadTimeMinutes: 0 }),
      },
    };
  });
};

export const discountRulesAfternoonEndTimeTransformer = (discountRules, venueDetails) => {
  /*
   *  Default value?
   */
  const { appointmentAfternoonEndTime } = venueDetails;

  return discountRules.map((discountRule) => {
    const { offpeakDiscounts } = discountRule;

    return {
      ...discountRule,
      offpeakDiscounts: {
        ...(offpeakDiscounts || {}),
        appointmentAfternoonEndTime,
      },
    };
  });
};

export const discountRulesMorningEndTimeTransformer = (discountRules, venueDetails) => {
  /*
   *  Default value?
   */
  const { appointmentMorningEndTime } = venueDetails;

  return discountRules.map((discountRule) => {
    const { offpeakDiscounts } = discountRule;

    return {
      ...discountRule,
      offpeakDiscounts: {
        ...(offpeakDiscounts || {}),
        appointmentMorningEndTime,
      },
    };
  });
};

export const discountRulesTreatmentPackageFlagTransformer = (discountRules) =>
  discountRules.map((discountRule) => {
    const { assignedMenuItems } = discountRule;

    return {
      ...discountRule,
      assignedMenuItems: assignedMenuItems.map((menuItem) => {
        const { menuItemTypeCode } = menuItem;
        const servicePackage = menuItemTypeCode === SERVICE_PACKAGE || menuItemTypeCode === PACKAGE;

        return {
          ...menuItem,
          servicePackage,
        };
      }),
    };
  });
