import React from 'react';
import Wahanda from 'common/wahanda';
import moment from 'common/moment';
import { PopoverBody } from 'components/common/Popover';
import Dot from 'components/common/icons/Dot';
import style from './style.scss';

interface Data {
  color: string;
  seriesName: string;
  value: string;
  name: string;
}

interface Props {
  data: Data[];
}

const DATE_FORMAT = Wahanda.lang.reports.clientReports.clientRetention.headers.dateFormat;
const LANG = Wahanda.lang.reports.clientReports.clientRetention.legend;

export default class RettentionTooltip extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.renderText = this.renderText.bind(this);
  }

  public renderText(data: Data): string {
    let text = `${data.seriesName}: ${data.value}`;
    if (data.seriesName === LANG.clientRetention) {
      text = `${text} %`;
    }
    return text;
  }

  public render() {
    const { data } = this.props;
    const header = data[0].name;
    return (
      <PopoverBody>
        <h1 className={style.header}>{moment(header).format(DATE_FORMAT)}</h1>
        <ul>
          {data.map((param) => (
            <li className={style.tooltipList} key={param.seriesName}>
              <Dot fill={param.color} />
              <span className={style.tooltipText}>{this.renderText(param)}</span>
            </li>
          ))}
        </ul>
      </PopoverBody>
    );
  }
}
