import React, { Component, Fragment } from 'react';
import { toJs, vector, get, isEmpty, toClj } from 'mori';
import { withAnyCursor, cursorValueHasChanged } from 'atom/cursors';
import { appState } from 'state';
import Wahanda from 'common/wahanda';
import App from 'common/backbone-app';
import { trackEvent } from 'common/analytics';
import { NavigationAnalytics, L2Page } from 'common/tracking/navigation';

import { fetch as fetchVenueProducts } from 'src/services/ProductsService';
import { Products } from 'components/common/IconPicture';
import { TabContent } from '../TabContent';
import ProductList from './ProductList';
import ProductDialog from './ProductDialog';

const lang = Wahanda.lang.menu.products;
type ProductsTabState = {
  products?: any;
  editProduct?: any;
  productsRefetch?: any;
  addProductDialogShown?: boolean;
};

export class ProductsTab extends Component<{}, ProductsTabState> {
  state = {
    products: null,
    addProductDialogShown: false,
  };

  public componentDidMount() {
    appState.addWatch(
      'venueProductsTab',
      withAnyCursor(
        (oldState, newState) => {
          this.setState({
            products: get(newState, 'venue-products'),
            editProduct: get(newState, 'edit-product'),
            productsRefetch: get(newState, 'venue-products-refetch'),
          });
        },
        vector(['venue-products'], ['edit-product'], ['venue-products-refetch']),
        cursorValueHasChanged,
      ),
    );

    fetchVenueProducts();
    NavigationAnalytics.trackPageEventView(L2Page.PRODUCTS);
  }

  onProductDialogClose = (refetch) => {
    this.setState({
      addProductDialogShown: false,
      editProduct: toClj({}),
    });
    if (refetch === true) {
      this.refetchProducts();
    }
  };

  addProduct = () => {
    trackEvent('products', 'click', 'add-new-product');
    this.setState({
      addProductDialogShown: true,
    });
  };

  refetchProducts() {
    this.setState({
      productsRefetch: true,
    });
    fetchVenueProducts();
  }

  public render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'editProduct' does not exist on type '{ p... Remove this comment to see the full error message
    const { addProductDialogShown, editProduct } = this.state;
    const products = this.state.products ? toJs(this.state.products) : [];
    const productDialog = (() => {
      if (addProductDialogShown || !isEmpty(editProduct)) {
        return (
          <ProductDialog
            onClose={this.onProductDialogClose}
            editedProduct={editProduct}
            vatRates={App.getVenueChannelVATRates()}
          />
        );
      }
      return null;
    })();
    return (
      <Fragment>
        <TabContent
          header={lang.pageHeader}
          addNewButtonText={lang.addButtonText}
          onAddNewButtonClick={this.addProduct}
          listElement={<ProductList products={products} />}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'productsRefetch' does not exist on type ... Remove this comment to see the full error message
          loading={this.state.productsRefetch}
          itemsCount={products.length}
          emptyStateHeading={lang.emptyState.heading}
          emptyStateMessage={lang.emptyState.message}
          emptyStateImage={<Products />}
          emptyStateButtonText={lang.addButtonText}
          emptyStateButtonOnClick={this.addProduct}
        />
        {productDialog}
      </Fragment>
    );
  }
}
