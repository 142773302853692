// Get the image density.
// We do not support more than 2x currently.
const getDensity = () => Math.min(Math.ceil(window.devicePixelRatio || 1), 2);

/**
 * Pick a URI from the list based on dimensions.
 *
 * @param {Object} uris Hash of dimensions, the key being ${w}x${h}
 * @param {Number} width Requested width
 * @param {Number} height Requested height
 *
 * @returns {String} URI to the image
 */
export default function getUriByDimension(uris, width, height) {
  const density = getDensity();
  const requiredW = width * density;
  const requiredH = height * density;

  return uris[`${requiredW}x${requiredH}`];
}
