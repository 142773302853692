import React from 'react';
import { ClientsImportInit } from './ClientsImportInit';
import { ClientsImportInProgress } from './ClientsImportInProgress';
import { ClientsImportFinish } from './ClientsImportFinish';
import { ClientsImportCampaignPrompt } from './ClientsImportCampaignPrompt';
import { ClientsImportStage } from './types';
import { useQuery } from 'react-query';
import apiUrl from 'common/api-url';
import { get } from 'reduxStore/apiService';
import { ClientsImportCollision } from './ClientsImportCollision/ClientsImportCollision';

export interface Props {
  onClose: () => void;
  refreshClientsList: () => void;
  stage: ClientsImportStage;
  actions: {
    clientsFileUploadSuccess: (params: { id: string }) => void;
  };
}

export const ClientsImport = (props: Props) => {
  const { isLoading, data } = useQuery<{ id?: string }>('client-imports', () =>
    get<{ id: string }>(apiUrl('ACTIVE_CUSTOMERS_IMPORT')).catch(() => ({})),
  );
  const {
    stage,
    onClose,
    refreshClientsList,
    actions: { clientsFileUploadSuccess },
  } = props;

  const activeImportId = data?.id;

  const renderStep = () => {
    switch (stage) {
      case ClientsImportStage.IN_PROGRESS:
        return <ClientsImportInProgress onClose={onClose} />;
      case ClientsImportStage.FINISHED:
        return <ClientsImportFinish onClose={onClose} refreshClientsList={refreshClientsList} />;
      case ClientsImportStage.CAMPAIGN_PROMPT:
        return (
          <ClientsImportCampaignPrompt onClose={onClose} refreshClientsList={refreshClientsList} />
        );
      case ClientsImportStage.INIT:
      default:
        return <ClientsImportInit onClose={onClose} />;
    }
  };

  const switchToProgress = () => {
    clientsFileUploadSuccess({ id: activeImportId as string });
  };

  if (isLoading) {
    return null;
  }

  if (activeImportId && stage === ClientsImportStage.INIT) {
    return <ClientsImportCollision onClose={onClose} onSubmit={switchToProgress} />;
  }

  return renderStep();
};
