import React from 'react';
// import Facebook from 'components/common/icons/Facebook';
// import Instagram from 'components/common/icons/Instagram';
import { Instagram, Facebook, Twitter } from 'components/common/Icon';
// import Twitter from 'components/common/icons/Twitter';
import style from './style.scss';

export const FACEBOOK_SOCIAL_LINK = 'facebook-social-link';

export const INSTAGRAM_SOCIAL_LINK = 'instagram-social-link';

export const TWITTER_SOCIAL_LINK = 'twitter-social-link';

interface Props {
  type: typeof FACEBOOK_SOCIAL_LINK | typeof INSTAGRAM_SOCIAL_LINK | typeof TWITTER_SOCIAL_LINK;
  children?: React.ReactNode;
}

class SocialLink extends React.PureComponent<Props, {}> {
  public render() {
    return (
      <div className={style.socialLink}>
        {this.getLogo()}
        {this.getLabel()}
        {this.props.children}
      </div>
    );
  }

  private getLogo = () => {
    switch (this.props.type) {
      case FACEBOOK_SOCIAL_LINK:
        return <Facebook className={style.facebookIcon} />;
      case INSTAGRAM_SOCIAL_LINK:
        return <Instagram />;
      case TWITTER_SOCIAL_LINK:
        return <Twitter className={style.twitterIcon} />;
      default:
        return null;
    }
  };

  private getLabel = () => {
    switch (this.props.type) {
      case FACEBOOK_SOCIAL_LINK:
        return <span className={style.socialDomain}>Facebook.com/</span>;
      case INSTAGRAM_SOCIAL_LINK:
        return <span className={style.socialDomain}>Instagram.com/</span>;
      case TWITTER_SOCIAL_LINK:
        return <span className={style.socialDomain}>Twitter.com/</span>;
      default:
        return null;
    }
  };
}

export default SocialLink;
