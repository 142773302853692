import { connect } from 'react-redux';
import App from 'common/backbone-app';
import YearlySalesPeriodReport from './index';

const mapStateToProps = () => ({
  venueCreated: new Date(App.config.get('venue').created),
});
const connector = connect(mapStateToProps);

export default connector(YearlySalesPeriodReport);
