import App from 'common/backbone-app';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getCampaignSelection } from 'components/marketing/EmailCampaign/selectors';
import { CampaignSelection } from './';
import { VENUE_OPENING_CAMPAIGN } from '../../chnlFeatures';
import { resetCampaignSelectionFlow, startCampaignSelection } from './actions';
import { emailTemplateReset } from '../CampaignPreview/actions';

const mapStateToProps = (state) => ({
  campaignSelection: getCampaignSelection(state),
  isOpeningCampaignEnabled: App.isFeatureSupported(VENUE_OPENING_CAMPAIGN),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      startCampaignSelection,
      resetCampaignSelectionFlow,
      emailTemplateReset,
    },
    dispatch,
  ),
});

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ push: (...args: any[]) => any; }' is missi... Remove this comment to see the full error messaged

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignSelection));
