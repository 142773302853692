/* global jQuery */
import React from 'react';
import {
  get,
  assoc,
  reduce,
  conj,
  mapIndexed,
  vector,
  hashMap,
  toJs,
  partial,
  curry,
  each,
  filter,
  isEmpty,
} from 'mori';
import classnames from 'classnames';
import { mapv } from 'common-js';
import { appState } from 'state';
import Wahanda from 'common/wahanda';
import { TYPE_TREATMENT } from 'common/appointment';
import { triggerEvent } from 'common/events';
import ReactDialog from 'src/components/common/react-dialog';
import { updateResource, addResource, deleteResource } from 'services/ResourcesService';
import { trackEvent } from 'common/analytics';

const getCheckedServiceIds = (services) =>
  reduce(
    (acc, s) => {
      if (get(s, 'checked')) {
        return conj(acc, get(s, 'id'));
      }
      return acc;
    },
    vector(),
    services,
  );

function valid(validations, field) {
  return validations
    ? get(validations, field) === true
    : // If the validations aren't yet initialized, return all fields as valid
      true;
}

function allValid(validations) {
  // All valid only if all hashMap values are strictly equal to `true`
  return reduce(
    (isValid, val) => isValid && get(val, 1) === true,
    true, // initial acc value
    validations,
  );
}

type FooterProps = {
  resourceId?: string | number;
  onDeleteButtonClick: (...args: any[]) => any;
  onSaveButtonClick: (...args: any[]) => any;
  isSaveButtonEnabled?: boolean;
};
const Footer: React.SFC<FooterProps> = ({
  resourceId,
  onDeleteButtonClick,
  onSaveButtonClick,
  isSaveButtonEnabled,
}) => {
  const deleteButton = ((id) =>
    id ? (
      <div
        className="dialog2--button dialog2--button-left dialog2--button-red"
        onClick={onDeleteButtonClick}
      >
        <span className="title">{Wahanda.lang.settings.venue.resources.delete}</span>
      </div>
    ) : null)(resourceId);
  return (
    <div className="dialog2--footer">
      {deleteButton}
      <div
        className="dialog2--button dialog2--button-right dialog2--button-green"
        onClick={curry(onSaveButtonClick, isSaveButtonEnabled)}
      >
        <span className="title">{Wahanda.lang.settings.venue.resources.save}</span>
      </div>
    </div>
  );
};
interface IServicesListItemProps extends React.HTMLAttributes<Element> {
  onClick: (...args: any[]) => any;
  index: number;
  input: {
    id: number;
    name: string;
    checked: boolean;
  };
}
class ServicesListItem extends React.Component<IServicesListItemProps, {}> {
  input: any;

  getInput() {
    return this.input;
  }

  refInput = (input) => {
    this.input = input;
  };

  render() {
    const {
      onClick,
      index,
      input: { id, name, checked },
    } = this.props;
    return (
      <li
        key={index}
        className={classnames('data-list-row', { checked })}
        onClick={() => {
          onClick(id);
          /*
           * This is neither a label nor an input so will not raise a change event
           * So, raise a change event on behalf of the input
           */
          triggerEvent(this.getInput(), 'change');
        }}
      >
        <input
          ref={this.refInput}
          type="checkbox"
          checked={checked}
          onChange={() => {
            /*
             * This will raise a change event
             */
            onClick(id);
          }}
        />
        <span className="data-list-item">{name}</span>
      </li>
    );
  }
}
type ServicesListProps = {
  group: {};
  services: {};
  onServiceClick: (...args: any[]) => any;
};
const ServicesList: React.SFC<ServicesListProps> = ({ group, services, onServiceClick }) => {
  const groupId = get(group, 'id');
  const relevantServices = filter(
    (service) =>
      get(service, 'groupId') === groupId && get(service, 'menuItemTypeCode') === TYPE_TREATMENT,
    services,
  );
  if (isEmpty(relevantServices)) {
    return null;
  }
  return (
    <div key={`group-${groupId}`}>
      <div className="group-name">{get(group, 'name')}</div>
      <ul className="data-list">
        {toJs(
          mapIndexed(
            partial(
              (onClick, index, { checked, ...input }) => (
                <ServicesListItem
                  key={index}
                  onClick={onClick}
                  index={index}
                  // @ts-expect-error ts-migrate(2769) FIXME: Type '{ checked: boolean; }' is missing the follow... Remove this comment to see the full error message
                  input={{ ...input, checked: !!checked }}
                />
              ),
              onServiceClick,
            ),
            toJs(relevantServices),
          ),
        )}
      </ul>
    </div>
  );
};
// is not editing (so, is it adding)?
const renderResourceDescription = (resourceId) =>
  resourceId ? null : (
    <span className="add-resource-description">
      {Wahanda.lang.settings.venue.resources.addResourceDescription}
    </span>
  );
const renderServicesList = (groups, services, onServiceClick) =>
  mapv(
    (group) => (
      <ServicesList
        key={`group-${get(group, 'id')}`}
        services={services}
        onServiceClick={onServiceClick}
        group={group}
      />
    ),
    groups,
  );

// @ts-expect-error ts-migrate(2430) FIXME: Type '{}' is not assignable to type 'string'.
interface IResourceDetailsProps extends React.HTMLAttributes<Element> {
  services?: {};
  groups?: {};
  resource?: {};
  onCloseFn: (...args: any[]) => any;
  isResourceNameUniqueFn: (...args: any[]) => any;
}
type ResourceDetailsState = {
  services?: any;
  focusInvalid?: any;
  resource?: any;
  saveButtonClicked?: boolean;
  validations?: any;
  title?: any;
};

// eslint-disable-next-line react/no-multi-comp
export default class ResourceDetails extends React.Component<
  IResourceDetailsProps,
  ResourceDetailsState
> {
  dialog: any;

  name: any;

  quantity: any;

  state = {
    focusInvalid: false,
    validations: null,
    services: this.props.services,
    resource: this.props.resource,
    saveButtonClicked: false,
    title:
      Wahanda.lang.settings.venue.resources.dialog.titles[this.props.resource ? 'edit' : 'add'],
  };

  // @ts-expect-error ts-migrate(2416) FIXME: Property 'services' is optional in type 'Readonly<... Remove this comment to see the full error message
  UNSAFE_componentWillReceiveProps({ services }) {
    this.setState({ services });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.focusInvalid === true && nextState.focusInvalid === false) {
      // Only stop rendering if the single change is to stop focusing invalid fields
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    this.updateValidationTooltips();
    if (this.state.focusInvalid) {
      this.focusFirstInvalid();
    }
  }

  // TODO make of the following two, one function only!
  onNameInputChange = ({ target: { value } }) => {
    const { resource } = this.state;
    this.setState({
      resource: assoc(resource, 'name', value),
    });
  };

  onQuantityInputChange = ({ target: { value } }) => {
    const { resource } = this.state;
    this.setState({
      resource: assoc(resource, 'quantity', value),
    });
  };

  // end TODO
  onDeleteButtonClick = () => {
    const resourceId = get(this.state.resource, 'id');
    deleteResource(appState, resourceId);
    this.props.onCloseFn();
  };

  onSaveButtonClick = () => {
    trackEvent('venue-resources', 'submit', 'save-resource');
    const validate = (resource, isResourceNameUnique) => {
      const name = get(resource, 'name', '');
      return hashMap(
        'name',
        name.trim() === '' ? false : name.length > 0 && isResourceNameUnique(),
        'quantity',
        get(resource, 'quantity', 1) > 0,
      );
    };
    const resourceName = get(this.state.resource, 'name');
    const resourceId = get(this.state.resource, 'id');
    const validations = validate(
      this.state.resource,
      partial(this.props.isResourceNameUniqueFn, resourceId, resourceName),
    );
    /*
     * Validation happens only when the save button is clicked (according
     * to the design)
     */
    this.setState({
      saveButtonClicked: true,
      focusInvalid: true,
      validations,
    });
    if (!allValid(validations)) {
      return;
    }
    const quantity = get(this.state.resource, 'quantity', 1);
    const serviceIds = getCheckedServiceIds(this.state.services);
    // editing
    if (resourceId) {
      updateResource(
        appState,
        resourceId,
        assoc(
          this.state.resource,
          'name',
          resourceName,
          'quantity',
          quantity,
          'serviceIds',
          serviceIds,
        ),
      );
    } else {
      // adding
      addResource(
        appState,
        hashMap('name', resourceName, 'quantity', quantity, 'serviceIds', serviceIds),
      );
    }
    this.props.onCloseFn();
  };

  focusFirstInvalid() {
    const validations = this.state.validations;
    const invalidField = (() => {
      if (!valid(validations, 'name')) {
        return this.name;
      }
      if (!valid(validations, 'quantity')) {
        return this.quantity;
      }
      return null;
    })();
    if (invalidField) {
      invalidField.focus();
    }
    this.setState({
      focusInvalid: false,
    });
  }

  updateValidationTooltips() {
    const validations = this.state.validations;
    each(validations, (item) => {
      const id = get(item, 0);
      const input = this[id];
      if (valid(validations, id)) {
        jQuery(input).qtip('destroy');
      } else {
        jQuery(input).formErrorTip(
          // Can't use dataset as we need to support IE10.
          input.getAttribute('data-error'),
        );
      }
    });
  }

  refName = (name) => {
    this.name = name;
  };

  refQuantity = (quantity) => {
    this.quantity = quantity;
  };

  refDialog = (dialog) => {
    this.dialog = dialog;
  };

  render() {
    const { groups } = this.props;
    const { resource, services, validations } = this.state;
    const resourceId = get(resource, 'id');
    const resourceName = get(resource, 'name', '');
    const quantity = get(resource, 'quantity', 1);
    const onServiceClick = (id) => {
      this.setState({
        services: mapv((service) => {
          if (get(service, 'id') === id) {
            return assoc(service, 'checked', !get(service, 'checked'));
          }
          return service;
        }, services),
      });
    };
    const resourceDescription = renderResourceDescription(resourceId);
    const servicesList = renderServicesList(groups, services, onServiceClick);
    // TODO split this htm in more components: you have two rows there are very
    //   similar, maybe your need to generate them by repeating them as component
    return (
      <ReactDialog
        dataTest="resource-details-modal"
        title={this.state.title}
        classes={{ 'resource-details-dialog': true }}
        onClose={this.props.onCloseFn}
        width={535}
        ref={this.refDialog}
      >
        {resourceDescription}
        <table cellPadding="0" cellSpacing="0" className="default-form">
          <tbody>
            <tr className="form-row">
              <td className="label-part">
                <label htmlFor="resource-name">
                  {Wahanda.lang.settings.venue.resources.resourceName}
                </label>
              </td>
              <td className="input-part">
                <div
                  className={`txt-input ${
                    this.state.saveButtonClicked && !valid(validations, 'name') ? 'novalid' : ''
                  }`}
                >
                  <input
                    type="text"
                    name="resource-name"
                    id="resource-name"
                    value={resourceName}
                    ref={this.refName}
                    onChange={this.onNameInputChange}
                    data-error={
                      !this.props.isResourceNameUniqueFn(resourceId, resourceName)
                        ? Wahanda.lang.settings.venue.resources.dialog.errors.nameInUse
                        : Wahanda.lang.settings.venue.resources.dialog.errors.name
                    }
                  />
                </div>
              </td>
            </tr>
            <tr className="form-row">
              <td className="label-part">
                <label htmlFor="resource-quantity">
                  {Wahanda.lang.settings.venue.resources.quantity}
                </label>
              </td>
              <td className="input-part">
                <div
                  className={`txt-input quantity ${
                    this.state.saveButtonClicked && !valid(validations, 'quantity') ? 'novalid' : ''
                  }`}
                >
                  <input
                    type="text"
                    name="resource-quantity"
                    id="resource-quantity"
                    value={quantity}
                    ref={this.refQuantity}
                    onChange={this.onQuantityInputChange}
                    data-error={Wahanda.lang.settings.venue.resources.dialog.errors.quantity}
                  />
                </div>
              </td>
            </tr>
            <tr className="form-row">
              <td className="label-part">
                <label className="optional">
                  {Wahanda.lang.settings.venue.resources.assignedServices}
                </label>
              </td>
              <td className="input-part">
                <div className="data-list-wrapper">{toJs(servicesList)}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <Footer
          resourceId={resourceId}
          onDeleteButtonClick={this.onDeleteButtonClick}
          onSaveButtonClick={this.onSaveButtonClick}
        />
      </ReactDialog>
    );
  }
}
