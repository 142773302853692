import React from 'react';
import ReactDOM from 'react-dom';
import { EmployeeDialog } from 'components/employees/EmployeeDialog/container';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import storeObserver from 'reduxStore/store/storeObserver';
import { trackEvent } from 'common/analytics';

const store = storeBuilder();
let destroyStoreObserver = () => null;

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

function render(id) {
  const container = Wahanda.Dialogs.getReactContainer();
  const onClose = () => {
    destroy(container);
  };
  const onSubmit = ({ archived, unarchived, added, emailChanged }) => {
    let event;
    if (archived) {
      event = Wahanda.Event.EMPLOYEE_ARCHIVED;
    } else if (added) {
      event = Wahanda.Event.EMPLOYEE_ADDED;
    } else if (unarchived) {
      event = Wahanda.Event.EMPLOYEE_UNARCHIVED;
    } else {
      event = Wahanda.Event.EMPLOYEE_EDITED;
    }
    destroyStoreObserver();
    const selectFromState = (state) =>
      !state.employees.submitting &&
      !state.employees.serverError &&
      state.employees.employee.id != null;
    const onStateChange = (requested) => {
      if (requested) {
        const {
          employees: {
            employee: { id: employeeId, emailAddress, image },
          },
        } = store.getState();
        destroyStoreObserver();
        if (!!emailAddress && (added || emailChanged)) {
          Wahanda.Dialogs.Alert(Wahanda.lang.settings.employees.employee.newAccount, {
            dialogClass: 'alert-dialog success-msg',
          });
        }
        if (added && image == null) {
          const view = new App.Views.Dialog.NewEmployeeImage({
            model: new App.Models.Employee({ id: employeeId }),
          });
          view.render();
          view.open();
        }
        App.trigger(event, {
          by: App.isMasquerading() ? 'treatwell' : 'owner',
        });
      }
    };
    destroyStoreObserver = storeObserver(store, selectFromState, onStateChange);
  };
  const onError = () => {
    destroyStoreObserver();
  };
  const onPasswordReset = () => {
    trackEvent('team-member', 'click', 'send-password-reset-instruction');
    const passReset = new App.Models.EmployeePasswordReset();
    passReset.save(
      {
        id,
        venueId: App.config.get('venue').id,
      },
      {
        error: () => {
          Wahanda.Dialogs.Alert(
            Wahanda.lang.settings.employees.employee.errors.resetPasswordEmail,
            { dialogClass: 'alert-dialog' },
          );
        },
        success: () => {
          Wahanda.Dialogs.Alert(Wahanda.lang.settings.employees.employee.resetSuccess, {
            dialogClass: 'alert-dialog success-msg',
          });
        },
      },
    );
  };
  const dialog = (
    <Provider store={store}>
      <EmployeeDialog
        id={id}
        onClose={onClose}
        onSubmit={onSubmit}
        onError={onError}
        onPasswordReset={onPasswordReset}
      />
    </Provider>
  );
  ReactDOM.render(dialog, container);
  return container;
}

export const EmployeeDialogInitializer = {
  render,
  destroy,
};
