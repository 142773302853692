import React from 'react';
import classname from 'classnames';
import Loader from 'components/common/Loader';
import {
  Table,
  TableColumnHeader,
  TableRow,
  TableBody,
  TableHeader,
} from 'components/common/Table';
import Wahanda from 'common/wahanda';
import NoProductsState from 'components/menu/NoProductsState/container';
import { OrderProducts } from 'common/analytics';
import Product from './Product/container';
import style from './style.scss';
import TotalQuantityLabel from '../../TotalQuantityLabel';

const LANG = Wahanda.lang.menu.products.orders.newOrderDialog;
interface IProductsTableProps extends React.HTMLAttributes<Element> {
  products?: {
    id: number;
    name: string;
    inventory?: number;
  }[];
  actions: {
    requestProductsListAction: (...args: any[]) => any;
    resetProductsListAction: (...args: any[]) => any;
  };
  newOrderTotal: number;
  loading: boolean;
  map?: any;
  resetProductsListAction?: any;
  requestProductsListAction?: any;
}
class ProductsTable extends React.Component<IProductsTableProps, {}> {
  static defaultProps = {
    products: [],
  };

  componentDidMount() {
    this.props.actions.requestProductsListAction();
  }

  componentDidUpdate() {
    const { loading, products } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (!loading && products.length === 0) {
      OrderProducts.trackViewOrderProductsEmptyList();
    }
  }

  componentWillUnmount() {
    this.props.actions.resetProductsListAction();
  }

  onGoToProductsPageButtonClick = () => {
    OrderProducts.trackClickOrderProductsEmptyListButton();
  };

  renderProducts() {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return this.props.products.map((product) => (
      <Product
        key={product.id}
        productId={product.id}
        name={product.name}
        inStockAmount={product.inventory}
      />
    ));
  }

  renderProductsTable = () => (
    <React.Fragment>
      <Table>
        <TableHeader>
          <TableRow>
            <TableColumnHeader className={style.noWrap}>
              {LANG.tableHeader.product}
            </TableColumnHeader>
            <TableColumnHeader className={classname(style.centered, style.noWrap)}>
              {LANG.tableHeader.inStock}
            </TableColumnHeader>
            <TableColumnHeader className={classname(style.right, style.noWrap)}>
              {LANG.tableHeader.quantityToOrder}
            </TableColumnHeader>
          </TableRow>
        </TableHeader>
        <TableBody>{this.renderProducts()}</TableBody>
      </Table>
      <TotalQuantityLabel quantity={this.props.newOrderTotal} />
    </React.Fragment>
  );

  renderNoProductsMessage = () => (
    <div className={style.noProductsMessageContainer}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '() => void' is not assignable to type 'never... Remove this comment to see the full error message */}
      <NoProductsState onClick={this.onGoToProductsPageButtonClick} />
    </div>
  );

  renderContent = () =>
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    this.props.products.length > 0 ? this.renderProductsTable() : this.renderNoProductsMessage();

  render() {
    return this.props.loading ? <Loader positionAbsolute /> : this.renderContent();
  }
}

export default ProductsTable;
