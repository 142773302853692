/* eslint-disable func-names */
/* global Backbone _ */
/**
 * Menu select list view.
 */
App.Views.Menu.MenuFilters = Backbone.View.extend({
  events: {
    'select.menu': 'onFilterChange',
    'click .menu-sub-filter .sub-filter a': 'onSubFilterClicked',
  },

  firstRender: true,
  defaultFilter: 'ACT',
  defaultSubFilter: -1,
  ignoredFilters: ['ALL', 'UNL'],
  curX: null,
  curDown: null,
  curScrollLeft: null,
  isScrolling: false,

  initialize: function () {
    this.lastSelectedFilter = null;
  },

  render: function () {
    this.treatments = this.options.treatments;

    if (!this.firstRender) {
      this.destroyMenu();
    } else {
      this.firstRender = false;
    }

    const html = Wahanda.Template.renderTemplate('menu-dropdown', this.getTemplateVars());

    this.$el.html(html);

    const scrollLeftTo = this.$('.menu-sub-filter .ui-tabs-active:first');

    // Reset the nav to the scroll position the user last had it in, it almost
    // looks like we never even recreated the DOM
    this.$('.menu-sub-filter').scrollLeft(scrollLeftTo[0] ? scrollLeftTo[0].offsetLeft : 0);

    this.showScrollArrows();

    $(window).resize(() => {
      this.showScrollArrows();
    });

    this.$('.menu-sub-filter').scroll(() => {
      this.showScrollArrows();
    });

    this.attachScrollHandler();

    this.attachScrollButtonsHandler();

    // Attach the menu plugin
    this.attachMenu();
  },

  showScrollArrows: function () {
    if (this.$('.menu-sub-filter').width() === this.$('.menu-sub-filter ul').width()) {
      this.$('.menu-scrollRight').hide();
      this.$('.menu-scrollLeft').hide();
    } else {
      this.$('.menu-scrollRight').show();
      this.$('.menu-scrollLeft').show();

      if (
        this.$('.menu-sub-filter').scrollLeft() ===
        this.$('.menu-sub-filter ul').width() - this.$('.menu-sub-filter').width()
      ) {
        this.$('.menu-scrollRight').hide();
      }

      if (this.$('.menu-sub-filter').scrollLeft() === 0) {
        this.$('.menu-scrollLeft').hide();
      }
    }
  },

  hideScrollArrows: function () {
    this.$('.menu-scrollRight').hide();
    this.$('.menu-scrollLeft').hide();
  },

  attachScrollButtonsHandler: function () {
    const that = this;

    this.$('.menu-scrollRight').click(() => {
      if (!that.isScrolling) {
        that.isScrolling = true;
        that.$('.menu-sub-filter').animate(
          {
            scrollLeft: that.$('.menu-sub-filter').scrollLeft() + 100,
            complete: () => {
              that.isScrolling = false;
            },
          },
          {
            duration: 500,
            complete: () => {
              that.showScrollArrows();
              that.isScrolling = false;
            },
          },
        );
      }
    });

    that.$('.menu-scrollLeft').click(() => {
      if (!that.isScrolling) {
        that.isScrolling = true;
        that.$('.menu-sub-filter').animate(
          {
            scrollLeft: that.$('.menu-sub-filter').scrollLeft() - 100,
          },
          {
            duration: 500,
            complete: () => {
              that.showScrollArrows();
              that.isScrolling = false;
            },
          },
        );
      }
    });
  },

  attachScrollHandler: function () {
    const menuSubFilter = this.$('.menu-sub-filter')[0];

    menuSubFilter.addEventListener('mousemove', (e) => {
      if (this.curDown) {
        e.preventDefault();
        menuSubFilter.scrollLeft = this.curScrollLeft + (this.curX - e.pageX);
      }
    });

    menuSubFilter.addEventListener('mousedown', (e) => {
      this.curX = e.pageX;
      this.curDown = true;
      this.curScrollLeft = menuSubFilter.scrollLeft;
    });

    menuSubFilter.addEventListener('mouseup', () => {
      // Turn back on hovers
      this.curDown = false;
      this.showScrollArrows();
    });
  },

  attachMenu: function () {
    this.$el.wMenu({
      // Custom element renderer
      currentElementRenderer: function (node) {
        const handler = this.current.find('.handler').clone();
        this.current.html(node.html()).append(handler);
      },
    });
  },

  /**
   * Generates the menu from the collection.
   *
   * @return Object
   */
  getTemplateVars: function () {
    const activeCode = this.getFilterValue();
    const toKeep = ['ACT', activeCode];

    const current = this.collection.find(function (m) {
      return m.get('code') === activeCode;
    });
    const list = [];
    const templateList = [];

    const defaultFilterUrl = Wahanda.Template.render(
      'venue/{{venueId}}/services/{{defaultFilter}}/{{defaultSubFilter}}',
      {
        venueId: App.getVenueId(),
        defaultFilter: this.defaultFilter,
        defaultSubFilter: this.defaultSubFilter,
      },
    );
    /* eslint-disable */
    for (var i = 0, len = this.collection.length; i < len; i++) {
      var item = this.collection.at(i);
      if (
        (_.indexOf(this.ignoredFilters, item.get('code')) === -1 && item.get('itemCount') > 0) ||
        _.indexOf(toKeep, item.get('code')) !== -1
      ) {
        list.push({
          title: Wahanda.lang.menu.filters[item.get('code')],
          count: item.get('itemCount'),
          hash: `venue/${App.getVenueId()}/services/${item.get(
            'code',
          )}/${this.getSubFilterValue()}`,
          classes: `${item.get('code').toLowerCase()} ${
            item.get('code') === activeCode ? 'on' : ''
          }`,
        });
      }
    }
    if (App.config.isMenuServiceTemplatesEnabled()) {
      templateList.push({
        title: Wahanda.lang.menu.group.allServicesItem,
        hash: `venue/${App.getVenueId()}/services/${this.getFilterValue()}/-1`,
        classes: this.getSubFilterValue() === -1 ? 'ui-tabs-active' : '',
      });
    }

    let totalOfferCount = 0;
    const fullMenu = this.options.fullMenu;

    for (var i = 0, len = this.options.menuTemplate.length; i < len; i++) {
      var item = this.options.menuTemplate.at(i);
      var treatmentGroupId = item.get('treatmentGroupId');
      const menuOffers = this.options.menuOffers;
      // Need to check if there are any services with this treatmentGroupId before
      // rendering the item.
      if (!this.treatments) {
        return;
      }
      var treatments = this.treatments.get('treatmentsCollection').toArray();
      var validServices;
      if (treatmentGroupId > 0 && App.config.isMenuServiceTemplatesEnabled()) {
        treatments = this.treatments.get('treatmentsCollection').filter(function (treatment) {
          return treatment.get('treatmentGroupId') === treatmentGroupId;
        });
        validServices = menuOffers.filter(function (offer) {
          return _.some(treatments, function (treatment) {
            const offerTreatments = offer.getTreatmentIds(fullMenu);
            return offerTreatments.indexOf(treatment.id) !== -1;
          });
        });
      } else {
        validServices = menuOffers;
      }

      if (validServices.length > 0) {
        templateList.push({
          title: item.get('treatmentTypeName'),
          hash: `venue/${App.getVenueId()}/services/${this.getFilterValue()}/${item.get(
            'treatmentGroupId',
          )}`,
          classes:
            item.get('treatmentGroupId') === this.getSubFilterValue() ? 'ui-tabs-active' : '',
          hidden: !App.config.isMenuServiceTemplatesEnabled(),
        });
      }
      if (validServices.length === 0 && item.get('treatmentGroupId') === this.getSubFilterValue()) {
        // There are no selected services to display here, so resetting back to 'All services' filter.

        window.location.hash = defaultFilterUrl;
      }
      totalOfferCount += validServices.length;
    }

    if (totalOfferCount === 0 && App.config.isMenuServiceTemplatesEnabled()) {
      // There are no selected services to display here, so resetting back to 'All services' filter.
      window.location.hash = defaultFilterUrl;
    }

    return {
      current: {
        title: Wahanda.lang.menu.filters[current.get('code')],
        count: current.get('itemCount'),
        classes: current.get('code').toLowerCase(),
        code: current.get('code'),
      },
      list: list,
      templateList: templateList,
      resetable: activeCode !== this.defaultFilter,
      defaultFilter: defaultFilterUrl,
      hidden: list.length < 2,
    };
    /* eslint-enable */
  },

  /**
   * Returns the filter value.
   *
   * @return string
   */
  getFilterValue: function () {
    return (App.mainViewOptions && App.mainViewOptions.initialFilter) || this.defaultFilter;
  },

  getSubFilterValue: function () {
    return (App.mainViewOptions && App.mainViewOptions.initialSubFilter) || this.defaultSubFilter;
  },

  /**
   * Destroys the earlyer created menu instance.
   */
  destroyMenu: function () {
    const menu = this.$el.data('menu');
    if (menu) {
      menu.destroy();
    }
  },

  onFilterChange: function (event, item) {
    let filter = $(item).find('a').attr('href');
    if (filter) {
      // Loose the #
      filter = filter.substring(1);
      App.trigger(Wahanda.Event.MENU_FILTER_CHANGE, { filter: filter });
    }
  },

  onSubFilterClicked: function (event) {
    const target = this.$(event.target);
    App.trigger(Wahanda.Event.MENU_SUB_FILTER_CHANGE, {
      name: target.text().trim(),
    });
  },
});
