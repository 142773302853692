import { trackEvent } from 'common/analytics';

App.Views.Forms.Calendar.SpaDayDetails = BackboneEx.View.Dialog.ValidatingForm.DatedDay.extend({
  events: {
    'click .button-cancel': 'cancel',
    'change input[name=dated-day-closed]': 'toggleSingleInventoryInput',
    'focus #dated-day-inventoryAvailable': 'setDayOpen',
    'click .tab-history-body tr': 'openBookingDialog',
  },
  options: {
    title: Wahanda.lang.calendar.spaDays.spaDayDetailsForm.title,
    dialogClass: 'dated-day-form',
    width: 800,
    dataTest: 'spa-days-details-modal',
  },

  saveQuestionTitle: Wahanda.lang.calendar.spaDays.form.saveQuestion.title,
  saveQuestionText: Wahanda.lang.calendar.spaDays.form.saveQuestion.text,
  serviceType: 'Y',

  postInitialize: function () {
    trackEvent('calendar', 'view', 'spa-day-details-modal');

    this.model = new App.Models.SpaAllocation({
      date: this.options.date,
      closed: false,
      inventoryAvailable: '',
    });

    this.$('.b-availability-title, .a-close-out').hide();
  },

  renderForm: function () {
    this.constructor.__super__.renderForm.call(this);
  },

  fillFormFromModel: function () {
    this.constructor.__super__.fillFormFromModel.call(this);

    var $available = this.$('#dated-day-inventoryAvailable');
    if (this.model.get('closed')) {
      // If the day is marked as closed, do not put "0" into the field - leave it empty
      $available.val('');
    }
  },

  focusFormElement: function () {
    var isDayClosed = this.$('#dated-day-closed-yes').prop('checked');
    var $input = this.$('#dated-day-inventoryAvailable');
    if (isDayClosed) {
      $input.blur();
    } else {
      $input.focus();
    }
  },

  getModelValues: function () {
    var values = BackboneEx.View.Dialog.ValidatingForm.prototype.getModelValues.call(this);
    values.closed = 'true' === values.closed;
    values.closedOffers = this.getClosedOffers();

    return values;
  },
});
