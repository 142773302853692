import React from 'react';
import Wahanda from 'common/wahanda';
import tableStyle from 'components/reports/newSalesReports/common/reportTable.scss';

interface IReportTableProps extends React.HTMLAttributes<Element> {
  serviceRows: {
    vatRate?: number;
    netTotal?: number;
    vat?: number;
  }[];
  productRows: {
    vatRate?: number;
    netTotal?: number;
    vat?: number;
  }[];
  voucherRows: {
    vatRate?: number;
    netTotal?: number;
    vat?: number;
  }[];
  total: {
    netTotal?: number;
    vat?: number;
  };
}

export default class ReportTable extends React.Component<IReportTableProps, {}> {
  getTableHeader = () => (
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th className={tableStyle.numeric}>
          {Wahanda.lang.reports.sales.vatReport.headings.netTotal}
        </th>
        <th className={tableStyle.numeric}>{Wahanda.lang.reports.sales.vatReport.headings.vat}</th>
      </tr>
    </thead>
  );

  getTableFooter = (total) => (
    <tfoot>
      <tr>
        <td>{Wahanda.lang.reports.sales.vatReport.headings.grandTotal}</td>
        <td className={tableStyle.numeric}>{Wahanda.Currency.formatFinancial(total.netTotal)}</td>
        <td className={tableStyle.numeric}>{Wahanda.Currency.formatFinancial(total.vat)}</td>
      </tr>
    </tfoot>
  );

  getAllRows = (serviceData, productData, voucherData) => {
    const serviceHeader = (
      <tr key="serviceHeader" className={tableStyle.rowHeading}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <td colSpan="3"> {Wahanda.lang.reports.sales.vatReport.headings.services} </td>
      </tr>
    );
    const productsHeader = (
      <tr key="productHeader" className={tableStyle.rowHeading}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <td colSpan="3"> {Wahanda.lang.reports.sales.vatReport.headings.products} </td>
      </tr>
    );
    const voucherHeader = (
      <tr key="voucherHeader" className={tableStyle.rowHeading}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message */}
        <td colSpan="3"> {Wahanda.lang.reports.sales.vatReport.headings.vouchers} </td>
      </tr>
    );
    const serviceRows = [serviceHeader].concat(this.getEachTypesRows(serviceData, 'service'));
    const productRows = [productsHeader].concat(this.getEachTypesRows(productData, 'product'));
    const voucherRows = [voucherHeader].concat(this.getEachTypesRows(voucherData, 'voucher'));
    return serviceRows.concat(productRows).concat(voucherRows);
  };

  getEachTypesRows = (data, salesType) =>
    data.map((row) => (
      <tr key={salesType.concat(row.vatRate)}>
        <td className={tableStyle.rowSubHeading}>
          {row.vatRate}% {Wahanda.lang.reports.sales.vatReport.headings.vat}
        </td>
        <td className={tableStyle.numeric}>{Wahanda.Currency.formatFinancial(row.netTotal)}</td>
        <td className={tableStyle.numeric}>{Wahanda.Currency.formatFinancial(row.vat)}</td>
      </tr>
    ));

  render() {
    const { serviceRows, productRows, voucherRows, total } = this.props;
    return (
      <div>
        <table className={tableStyle.reportTable}>
          {this.getTableHeader()}
          <tbody>{this.getAllRows(serviceRows, productRows, voucherRows)}</tbody>
          {this.getTableFooter(total)}
        </table>
      </div>
    );
  }
}
