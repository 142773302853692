import {
  EMPLOYEES_REQUEST,
  EMPLOYEES_RECEIVE,
  EMPLOYEES_FAILURE,
  EMPLOYEES_RESET,
  EMPLOYEES_SET_EMPLOYEE_DATA,
  EMPLOYEES_SUBMIT_EMPLOYEE,
  EMPLOYEES_EMPLOYEE_SUBMIT_SUCCESS,
  EMPLOYEES_EMPLOYEE_REQUEST,
  EMPLOYEES_EMPLOYEE_RECEIVE,
  EMPLOYEES_EMPLOYEE_DATA_RESET,
  EMPLOYEES_CREATE_EMPLOYEE,
  EMPLOYEES_DELETE_EMPLOYEE,
  EMPLOYEES_DELETE_EMPLOYEE_SUCCESS,
  EMPLOYEES_EXTERNAL_CALENDAR_LINK,
  EMPLOYEES_EXTERNAL_CALENDAR_LINK_SUCCESS,
  EMPLOYEES_EXTERNAL_CALENDAR_UNLINK,
  EMPLOYEES_EXTERNAL_CALENDAR_UNLINK_SUCCESS,
  EMPLOYEES_PERMISSIONS_BY_ROLES_REQUEST,
  EMPLOYEES_PERMISSIONS_BY_ROLES_RECEIVE,
  EMPLOYEES_CATEGORIES_REQUEST,
  EMPLOYEES_CATEGORIES_RECEIVE,
} from 'reduxStore/actionsConst';

export const initialState = {
  employee: {
    active: true,
    canLogin: false,
    emailAddress: null,
    previousEmailAddress: '',
    employeeCategoryId: null,
    employeeOffers: [],
    externalCalendarUri: '',
    calLinked: false,
    gender: null,
    id: null,
    image: null,
    imageId: null,
    jobTitle: '',
    linkedExternalSalonName: null,
    name: '',
    notes: '',
    permissions: [],
    phone: '',
    role: null,
    supplierBound: false,
    takesAppointments: false,
  },
  items: [],
  isLoaded: false,
  permissionsByRoles: [],
  categories: [],
  serverError: false,
  serverErrorName: null,
  serverErrorData: null,
  serverErrorAction: null,
  loading: false,
  submitting: false,
};

export default function employees(state = initialState, action) {
  // Employee only gets connect access when emailAddress is entered
  const canLogin = state.employee && !!state.employee.emailAddress;
  const emailAddress = canLogin ? state.employee.emailAddress : null;

  switch (action.type) {
    case EMPLOYEES_REQUEST:
    case EMPLOYEES_PERMISSIONS_BY_ROLES_REQUEST:
    case EMPLOYEES_CATEGORIES_REQUEST:
    case EMPLOYEES_EXTERNAL_CALENDAR_LINK:
    case EMPLOYEES_EXTERNAL_CALENDAR_UNLINK:
      return {
        ...state,
        loading: true,
      };

    case EMPLOYEES_DELETE_EMPLOYEE:
      return {
        ...state,
        loading: true,
        submitting: true,
      };

    case EMPLOYEES_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        employee: {
          ...state.employee,
          id: action.id,
        },
      };

    case EMPLOYEES_SUBMIT_EMPLOYEE:
      return {
        ...state,
        loading: true,
        submitting: true,
        employee: {
          ...state.employee,
          active: true,
          emailAddress,
          canLogin,
        },
      };

    case EMPLOYEES_EMPLOYEE_SUBMIT_SUCCESS:
    case EMPLOYEES_DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        submitting: false,
      };

    case EMPLOYEES_RECEIVE:
      return {
        ...state,
        items: action.data,
        loading: false,
      };

    case EMPLOYEES_FAILURE:
      return {
        ...state,
        serverError: true,
        serverErrorName: action.serverErrorName,
        serverErrorData: action.serverErrorData,
        serverErrorAction: action.serverErrorAction,
        loading: false,
        submitting: false,
      };

    case EMPLOYEES_RESET:
      return initialState;

    case EMPLOYEES_SET_EMPLOYEE_DATA:
      return {
        ...state,
        employee: {
          ...state.employee,
          ...action.data,
        },
        serverError: initialState.serverError,
        serverErrorName: initialState.serverErrorName,
        serverErrorData: initialState.serverErrorData,
        serverErrorAction: initialState.serverErrorAction,
      };

    case EMPLOYEES_EMPLOYEE_RECEIVE:
      return {
        ...state,
        employee: {
          ...state.employee,
          ...action.data,
          previousEmailAddress: action.data.emailAddress,
        },
        loading: false,
        isLoaded: true,
      };

    case EMPLOYEES_EMPLOYEE_DATA_RESET:
      return {
        ...state,
        employee: {
          ...initialState.employee,
        },
        isLoaded: false,
        serverError: initialState.serverError,
        serverErrorName: initialState.serverErrorName,
        serverErrorData: initialState.serverErrorData,
        serverErrorAction: initialState.serverErrorAction,
      };
    case EMPLOYEES_EXTERNAL_CALENDAR_LINK_SUCCESS:
      return {
        ...state,
        employee: {
          ...state.employee,
          calLinked: true,
        },
        loading: false,
        serverError: false,
        serverErrorName: null,
        serverErrorData: null,
        serverErrorAction: null,
      };
    case EMPLOYEES_EXTERNAL_CALENDAR_UNLINK_SUCCESS:
      return {
        ...state,
        employee: {
          ...state.employee,
          externalCalendarUri: '',
          calLinked: false,
        },
        loading: false,
        serverError: false,
        serverErrorName: null,
        serverErrorData: null,
        serverErrorAction: null,
      };
    case EMPLOYEES_PERMISSIONS_BY_ROLES_RECEIVE:
      return {
        ...state,
        permissionsByRoles: action.data,
        loading: false,
      };
    case EMPLOYEES_CATEGORIES_RECEIVE:
      return {
        ...state,
        categories: action.data,
        loading: false,
      };
    case EMPLOYEES_CREATE_EMPLOYEE:
      return {
        ...state,
        isLoaded: true,
      };
    default:
      return state;
  }
}
