import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class BellOff extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-bell-off', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M17.4286459,16.5183029 C17.1992691,15.8085103 16.9704207,15.0116029 16.757006,14.1442931 C16.4361497,12.8403444 16.1787097,11.4919352 16.0077221,10.1240347 C15.395305,5.22469783 14.7217212,4 12,4 C9.2782788,4 8.60469499,5.22469783 7.99227788,10.1240347 C7.82179158,11.4879251 7.566178,12.8300371 7.24806215,14.1261594 C7.03526169,14.9931876 6.80705954,15.7890224 6.57831834,16.497229 C6.51566925,16.6911971 6.45899509,16.8604271 6.4108838,17 L17.5889126,17 C17.5415601,16.8622194 17.487758,16.7012216 17.4286459,16.5183029 Z M14,20 C14,21.1045695 13.1045695,22 12,22 C10.8954305,22 10,21.1045695 10,20 L10,20 Z M12,2 C16.1282788,2 17.2664597,4.06941981 17.9922779,9.87596527 C18.1530675,11.1622819 18.3960204,12.4348112 18.6990757,13.6664175 C18.9006671,14.4856776 19.116468,15.2371504 19.3317406,15.9032983 C19.4594674,16.2985413 19.5572953,16.5741647 19.6210885,16.7419948 C20.0285649,17.9130933 19.2299233,19 17.9931545,19 L6.00684547,19 C4.76389734,19 3.98663489,17.8973292 4.39752699,16.6889003 C4.45040237,16.5510558 4.54785562,16.276569 4.67512694,15.8825238 C4.88967883,15.2182488 5.10476975,14.4681382 5.3057095,13.649435 C5.60614233,12.4253599 5.84737015,11.1587811 6.00772212,9.87596527 C6.7335403,4.06941981 7.8717212,2 12,2 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(BellOff);
