import React, { Children, cloneElement, MouseEvent } from 'react';

import { Portal } from 'react-portal';
import classnames from 'classnames';

import style from './ModalContainer.scss';

interface ModalContainerProps {
  // children: React.ReactNode;
  className?: string;
  children: any;
  dataTest?: string;
  hasSplit?: boolean;
  hasLightSplit?: boolean;
  initialPosition?: 'center' | 'top';
  onClose?: () => void;
  onChange?: () => void;
  type?: 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
  zIndex?: number;
}

export const ModalContainer = ({
  children,
  className,
  dataTest,
  hasSplit,
  hasLightSplit,
  initialPosition = 'center',
  onClose,
  onChange,
  type = 'small',
  zIndex,
}: ModalContainerProps) => {
  const containerClass = classnames(
    style.container,
    style[type],
    {
      [style.dark]: hasSplit,
      [style.positionTop]: initialPosition === 'top',
    },
    className ? className : '',
  );

  const handleInsideClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  const zIndexOverride = zIndex ? { zIndex } : {};

  const content = (
    <div className={style.overlay} onClick={onClose} onChange={onChange} style={zIndexOverride}>
      <div
        className={containerClass}
        data-test={dataTest}
        onClick={handleInsideClick}
        style={zIndexOverride}
      >
        {hasSplit || hasLightSplit ? (
          <>
            {Children.map(children, (element, index) => {
              return cloneElement(element, { className: style[`child${index}`] });
            })}
          </>
        ) : (
          <div className={style.wrapper}>{children}</div>
        )}
      </div>
    </div>
  );

  return <Portal>{content}</Portal>;
};
