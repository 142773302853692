import React from 'react';
import { OrderProducts } from 'common/analytics';
import SetButton from './setButton';
import actionTypes from './actionTypes';
import style from './style.scss';

interface IAmountSetterProps extends React.HTMLAttributes<Element> {
  amount?: number;
  callback: (...args: any[]) => any;
  processingOrder?: boolean;
}

export default class AmountSetter extends React.Component<IAmountSetterProps, {}> {
  static defaultProps = {
    amount: null,
    processingOrder: false,
  };

  onIncrement = () => {
    const { amount, callback, processingOrder } = this.props;
    if (processingOrder) {
      OrderProducts.trackClickProcessPlusButton();
    }
    if (!amount) {
      callback(1);
      return;
    }
    callback(amount + 1);
  };

  onDecrement = () => {
    const { amount, callback, processingOrder } = this.props;
    if (processingOrder) {
      OrderProducts.trackClickProcessMinusButton();
    }
    if (amount) {
      if (amount === 1) {
        callback(0);
        return;
      }
      callback(amount - 1);
    }
  };

  handleChange = (event) => {
    const { callback, processingOrder } = this.props;
    if (processingOrder) {
      OrderProducts.trackUpdateProcessOrdersAmount();
    }
    callback(Number(event.target.value));
  };

  /*
      Validator for the input field is needed because
      type=number is not supported in some browsers.
    */
  validate = (event) => {
    const theEvent = event || window.event;
    let key = '';
    // Handle paste
    if (theEvent.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      // Handle key press
      const keyPressed = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(keyPressed);
    }
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  render() {
    const { amount } = this.props;
    return (
      <div className={style.amountSetter}>
        <SetButton
          type={actionTypes.DECREASE}
          onAmountChanged={this.onDecrement}
          isActive={!!amount}
        />

        <input
          className={style.amount}
          value={amount || ''}
          name="amount"
          onKeyPress={this.validate}
          onChange={this.handleChange}
        />

        <SetButton
          type={actionTypes.INCREASE}
          onAmountChanged={this.onIncrement}
          isActive={!!amount}
        />
      </div>
    );
  }
}
