import { connect } from 'react-redux';
import App from 'common/backbone-app';
import { DownloadTemplate as Content } from './DownloadTemplate';

const mapStateToProps = () => ({
  contentChannel: App.domainChannelCode(),
  locale: App.getClientLocale(),
});

export const DownloadTemplate = connect(mapStateToProps)(Content);
