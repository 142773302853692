import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class HairRemoval extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-hair-removal', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 15 15"
          >
            <path
              d="M11.743 7.3l2.082 2.915c.839 1.174.706 2.782-.315 3.803-.976.976-2.559.976-3.535 0-1.02-1.02-1.153-2.629-.315-3.803L11.743 7.3zM13 1c1.105 1.105 1.105 2.895 0 4l-8 8c-1.105 1.105-2.895 1.105-4 0s-1.105-2.895 0-4l8-8c1.105-1.105 2.895-1.105 4 0zm-1.258 9.74l-.454.638c-.27.378-.228.897.101 1.225.195.196.512.196.707 0 .3-.299.362-.754.168-1.119l-.066-.106-.456-.638zM7 5.828l-4.586 4.586c-.323.324-.323.848 0 1.172.297.296.762.321 1.087.074l.085-.074L7 8.172V5.828zm3.499-3.488l-.085.074L9 3.828v2.344l2.586-2.586c.323-.324.323-.848 0-1.172-.297-.296-.762-.321-1.087-.074z"
              fillRule="evenodd"
            />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-hair-removal', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 24 24"
          >
            <path
              d="M21.5 2.5c1.38 1.38 1.38 3.62 0 5l-14 14c-1.38 1.38-3.62 1.38-5 0s-1.38-3.62 0-5l14-14c1.38-1.38 3.62-1.38 5 0zM19 11.343l2.749 4.14c1.027 1.548.82 3.604-.492 4.917-1.247 1.246-3.267 1.246-4.514 0-1.313-1.313-1.519-3.37-.492-4.916L19 11.344zm-6-2.516l-9.086 9.087c-.6.6-.6 1.572 0 2.172.567.566 1.465.598 2.069.094l.103-.094 6.913-6.915V8.827zm6 6.132l-1.082 1.63c-.5.755-.4 1.757.24 2.397.465.465 1.22.465 1.685 0 .602-.603.726-1.526.321-2.26l-.082-.136L19 14.96zm-.983-11.14l-.103.095L15 6.827v4.344l5.087-5.085c.6-.6.6-1.572 0-2.172-.567-.566-1.465-.598-2.069-.094z"
              fillRule="evenodd"
            />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(HairRemoval);
