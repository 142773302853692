import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Body extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-body', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path
              d="M11.402.568l1.7 3.55c.051.107.096.218.132.331.377 1.157-.071 2.385-1.02 3.047l.003.014c.092.625.282 1.23.563 1.794l.147.278c.363.644.542 1.51.57 2.611l.003.307v1h-1.586c-.576 0-1.131.206-1.566.577l-.14.13-.559.558c-.47.47-1.109.735-1.774.735-.623 0-1.219-.245-1.661-.677l-.128-.135-.464-.53c-.325-.371-.78-.602-1.267-.65l-.184-.008H2.5v-1c0-1.146.148-2.053.478-2.737l.095-.18c.31-.55.531-1.145.656-1.762l.054-.31.009-.064c-.91-.678-1.333-1.885-.963-3.024l.056-.154.064-.15L4.593.578l1.814.842-.569 1.224 1.56 1.142c.225.164.44.341.642.53.2-.189.414-.366.638-.53l1.517-1.11-.597-1.245 1.804-.864zM8.038 7.347l-.018.016c-.637.556-1.473.83-2.313.765l.055-.325c-.144.97-.465 1.907-.947 2.762-.107.19-.19.469-.245.836l-.016.118.067.007c.89.102 1.722.507 2.353 1.15l.154.165.463.53c.072.082.175.129.284.129.108 0 .212-.034.299-.097l.06-.052.559-.558c.662-.662 1.513-1.092 2.427-1.238l.226-.031-.016-.123c-.045-.306-.11-.551-.193-.734l-.052-.102c-.422-.748-.72-1.558-.884-2.4l-.006-.032c-.823.045-1.636-.236-2.257-.786zM4.979 4.495l-.216.466c-.012.026-.023.053-.032.08-.112.347.052.717.372.872l.09.036.407.132c.398.13.83.03 1.13-.247-.161-.158-.332-.3-.512-.433L4.98 4.495zm6.095.017l-1.215.89c-.18.131-.351.274-.511.429.268.251.641.357 1.002.283l.127-.033.406-.132c.368-.12.569-.514.45-.882l-.035-.086-.224-.469z"
              fillRule="evenodd"
            />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-body', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 24 24"
          >
            <path
              d="M16.936.649l2.084 5.556c.027.072.052.145.074.22.528 1.758-.42 3.61-2.127 4.224l-.18.059-.13.039c-1.615.484-3.351.143-4.657-.884-.92.724-2.054 1.107-3.21 1.1l-.302 1.208c-.263 1.054-.739 2.042-1.395 2.905l-.226.283c-.417.5-.696 1.305-.81 2.421l-.02.223.127.004c1.425.066 2.782.628 3.836 1.589l.207.197.914.914c.157.156.36.256.576.284l.131.009h.344c.22 0 .434-.073.608-.206l.099-.087.914-.914c1.08-1.08 2.523-1.715 4.043-1.786l.126-.004-.02-.223c-.103-1.023-.347-1.785-.708-2.29l-.101-.131c-.602-.72-1.07-1.54-1.387-2.419.4-.03.798-.096 1.192-.196l.295-.082.129-.039.214-.068.145-.053c.194.477.45.926.76 1.338l.187.237c.86 1.028 1.284 2.552 1.328 4.563L20 19v1h-1.879c-1.015 0-1.991.374-2.744 1.047l-.17.16-.914.914c-.516.516-1.2.825-1.923.872l-.198.007h-.344c-.729 0-1.43-.266-1.976-.743l-.145-.136-.914-.914c-.718-.718-1.673-1.143-2.68-1.2L5.878 20H4v-1c0-2.068.377-3.65 1.185-4.737l.147-.186c.516-.618.902-1.333 1.135-2.101l.08-.29.28-1.12C5.25 9.883 4.4 8.112 4.907 6.425l.034-.11.04-.109L7.063.649l1.872.702-.944 2.518 1.128.846c1.002.752 1.964 1.555 2.881 2.407.739-.688 1.507-1.343 2.302-1.965l.728-.555.976-.733-.943-2.518L16.936.65zM7.26 5.819l-.407 1.088c-.012.03-.022.061-.031.092-.215.717.155 1.47.834 1.747l.131.046.13.039c.886.266 1.833.125 2.594-.361-.827-.762-1.692-1.48-2.59-2.155L7.26 5.82zm9.479 0l-.66.496c-.898.674-1.763 1.393-2.59 2.153.705.452 1.57.606 2.398.415l.196-.052.13-.039c.761-.228 1.194-1.031.965-1.793l-.03-.092-.409-1.087z"
              fillRule="evenodd"
            />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Body);
