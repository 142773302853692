/**
 * Employee categories.
 */
App.Views.Settings.EmployeeEmployeesMaster = App.Views.Form.extend({
  initialize: function () {
    this.model.on('fetched', this.render.bind(this));
  },

  // Turn off navigation checking on this view.
  canNavigateAway: null,

  /**
   * Returns the View that should be rendered.
   */
  getViewClass: function () {
    if (typeof this.subView === 'undefined') {
      this.subView = new App.Views.Settings.Dynamic.EmployeeEmployees({
        el: this.$('.dynamic-allocation'),
        model: this.model,
        categoriesCollection: this.options.categoriesCollection,
      });
    }

    return this.subView;
  },

  render: function () {
    this.$('.dynamic-allocation').addClass('hidden');
    // Rendering the correct subview
    var subView = this.getViewClass();
    subView.render();
  },
});
