import {
  GET_DISCOUNT_RULE_RECEIVE,
  GET_DISCOUNT_RULE_FAILURE,
  PUT_DISCOUNT_RULE_RECEIVE,
  PUT_DISCOUNT_RULE_FAILURE,
  ADD_DISCOUNT_RULE_RECEIVE,
  ADD_DISCOUNT_RULE_FAILURE,
  REVERT_DISCOUNT_RULE,
  GET_VENUE_DETAILS_REQUEST,
  GET_VENUE_DETAILS_RECEIVE,
  GET_VENUE_DETAILS_FAILURE,
  PRESENT_DISCOUNT_RULE_DELETE_CONFIRM,
  DISCARD_DISCOUNT_RULE_DELETE_CONFIRM,
  INITIAL_STATE_MENU_DISCOUNT,
} from 'reduxStore/actionsConst';

export const initialState = {
  hasMenuDiscount: false,
  hasVenueDetails: false,
  hasSaved: false,
  hasError: false,
  hasDeleteConfirmDialog: false,
  discountRules: [],
  venueDetails: {},
};

export default function menuDiscount(state = initialState, action) {
  switch (action.type) {
    case GET_DISCOUNT_RULE_RECEIVE:
      return {
        ...state,
        ...action.data,
        hasMenuDiscount: true,
      };

    case GET_DISCOUNT_RULE_FAILURE:
      return {
        ...state,
        hasMenuDiscount: false,
      };

    case PUT_DISCOUNT_RULE_RECEIVE:
      return {
        ...state,
        hasSaved: true,
        hasError: false,
      };

    case PUT_DISCOUNT_RULE_FAILURE:
      return {
        ...state,
        hasSaved: false,
        hasError: true,
      };

    case ADD_DISCOUNT_RULE_RECEIVE:
      return {
        ...state,
        hasSaved: true,
        hasError: false,
      };

    case ADD_DISCOUNT_RULE_FAILURE:
      return {
        ...state,
        hasSaved: false,
        hasError: true,
      };

    case REVERT_DISCOUNT_RULE:
      return {
        ...state,
        hasSaved: false,
        hasError: false,
      };

    case PRESENT_DISCOUNT_RULE_DELETE_CONFIRM:
      return {
        ...state,
        hasDeleteConfirmDialog: true,
      };

    case DISCARD_DISCOUNT_RULE_DELETE_CONFIRM:
      return {
        ...state,
        hasDeleteConfirmDialog: false,
      };

    case INITIAL_STATE_MENU_DISCOUNT:
      return {
        ...state,
        ...action.data,
      };

    case GET_VENUE_DETAILS_REQUEST:
      return {
        ...state,
        hasVenueDetails: false,
      };

    case GET_VENUE_DETAILS_RECEIVE:
      return {
        ...state,
        hasVenueDetails: true,
        venueDetails: {
          ...action.data,
        },
      };

    case GET_VENUE_DETAILS_FAILURE:
      return {
        ...state,
        hasVenueDetails: false,
      };

    default:
      return state;
  }
}
