/* global Backbone */
/* global BackboneEx */
/* eslint func-names: 0 */
/* eslint no-underscore-dangle: 0 */
import moment from 'common/moment';
import Wahanda from 'common/wahanda';
import _ from 'common/underscore';
import { trackEvent } from 'common/analytics';
import {
  setup as setupUnconfirmedAppts,
  getCollection as getUnconfirmedApptsCollection,
} from 'services/unconfirmed-appointments';

App.Views.Header.Notifications = Backbone.View.extend({
  /** @var int Millis after which to reload all notifications */
  reloadTimeout: 10 * 60 * 1000,
  /** @var int Timeout timer name */
  timerName: 'notifications',
  soundTimerTime: 600000, // 10 minutes
  soundNotificationTimer: 'sound-notifications',
  venueModel: null,

  events: {
    'click .track-unpaid-remittance-click': 'onClickUnpaidRemittance',
    'click .track-unconfirmed-online-appointments': 'onClickUnconfirmedAppointment',
    'click .track-fulfillment-address-click': 'onClickEmailMissing',
    'click .track-dated-link-click': 'onClickDated',
  },

  initialize: function () {
    const self = this;

    if (!App.isRestrictedMode()) {
      setupUnconfirmedAppts();
    }

    App.on(Wahanda.Event.ONB_WIZARD_DONE, () => {
      self.prefetchModels();
    });

    App.on('checkout:payment-success', () => {
      self.prefetchModels();
    });

    this.unconfirmedOnlineAppointments = getUnconfirmedApptsCollection();
    this.unconfirmedDated = new App.Collections.Bookings();
    this.unpaidRemittance = new App.Models.UnpaidRemittance();
    this.totalCount = 0;
    this.unseenAppointmentNotificationsCount = 0;
    this.analyticsData = {
      unpaidRemittance: false,
      unconfirmedAppointment: false,
      primaryNotificationEmailMissing: false,
      dated: false,
      noNotification: true,
    };
    // Binding to save events. Rerender when they happen.
    const rerenderCallback = this.renderWithVenue.bind(this);
    const wEvent = Wahanda.Event;
    App.on(wEvent.SETTINGS_FULFILLMENT_COMMUNICATION_SAVED, () => {
      // The timeout is for making sure the Cache clears itself before we ask for data.
      setTimeout(rerenderCallback, 0);
    });

    let firstLoad = true;
    App.on(wEvent.APP_LOADED, () => {
      if (firstLoad) {
        firstLoad = false;
        return;
      }
      App.Timer.reset(self.timerName);
      self.prefetchModels();
    });

    // Timer.
    this.startNotificationTimer();

    const headerDropdownOnChange = (data) => {
      if (data.shown) {
        trackEvent('notifications', 'clicked', null, self.analyticsData);
        trackEvent('notifications', 'view', null, self.analyticsData);
        App.ES6.Initializers.NotificationsList.setListVisible(true);
      } else {
        App.ES6.Initializers.NotificationsList.setListVisible(false);
      }
    };

    const headerDropdownOnChangeDebounced = _.debounce(headerDropdownOnChange);

    this.$el.headerDropdown({
      onChange: headerDropdownOnChangeDebounced,
    });
  },

  startNotificationTimer: function () {
    const self = this;
    App.Timer.start(self.timerName, self.reloadTimeout);
    App.Timer.on(self.timerName, () => {
      self.prefetchModels();
    });
  },

  // Model data fetching
  prefetchModels: function () {
    const self = this;
    // Models to fetch
    // We always want to check for remittanceUnpaid
    const models = [];

    if (App.config.canSellDated()) {
      if (Wahanda.Permissions.viewBookings()) {
        this.unconfirmedDated.setFilters({
          arrivalDateFrom: Wahanda.Date.toApiString(Wahanda.Date.createVenueDate()),
          bookingType: 'DT',
          bookingStatus: 'UC',
        });
        models.push(this.unconfirmedDated);
      } else {
        this.unconfirmedDated.reset();
      }
    }

    if (Wahanda.Permissions.viewFinanceData()) {
      models.push(this.unpaidRemittance);
    }

    self.renderWithVenue();

    if (models.length > 0) {
      BackboneEx.Tool.MultiModelFetch.run(
        models,
        () => {
          // Reset the timer, just in case it will run again very fast.
          if (App.Timer.started(self.timerName)) {
            App.Timer.reset(self.timerName);
          } else {
            self.startNotificationTimer();
          }
          self.listenTo(self.unconfirmedOnlineAppointments, 'updated', () => {
            self.renderWithVenue();
          });
        },
        () => {
          App.Timer.cancel(self.timerName);
        },
      );
    } else {
      this.hideCountBadge();
    }
  },

  checkSoundNotifications: function () {
    if (!App.config.get('venue').allowSoundNotifications || !Wahanda.Permissions.useCalendar()) {
      App.Timer.cancel(this.soundNotificationTimer);
      return;
    }
    const appts = this.unconfirmedOnlineAppointments.get('appointments');
    const hasAppts = appts && appts.length > 0;

    if (hasAppts) {
      App.Timer.start(this.soundNotificationTimer, this.soundTimerTime);
    } else {
      App.Timer.cancel(this.soundNotificationTimer);
    }
  },

  renderWithVenue: function () {
    const self = this;
    Wahanda.Cache.venue().done((venue) => {
      if (self.isAppointmentNotificationsPermitted()) {
        if (self.venueModel == null) {
          self.renderAppointmentNotifications();
        } else if (venue.id !== self.venueModel.id) {
          App.ES6.Initializers.NotificationsList.fetch();
        }
      }

      self.venueModel = venue;
      self.renderLinks();
      self.checkSoundNotifications();
    });
  },

  // Rendering

  render: function () {
    /* Empty body */
  },
  /**
   * Reset the count
   */
  reset: function () {
    this.$('.notifications-link-list-container').empty();
    App.ES6.Initializers.NotificationsListHeader.destroy(
      this.$('.notification-list-title-container').get(0),
    );
    this.totalCount = this.unseenAppointmentNotificationsCount;
  },

  renderLinks: function () {
    this.reset();

    this.renderFulfillmentAddress();
    this.renderUnconfirmedOnlineAppointments();
    if (Wahanda.Permissions.viewFinanceData()) {
      this.renderUnpaidRemittance();
    }

    if (App.config.canSellDated()) {
      this.renderDated();
    }

    this.setTotalCount();
  },

  onAppointmentNotificationClick: function ({
    appointmentId,
    appointmentDatetime,
    appointmentVisibleOnCalendar,
  }) {
    const appointmentDate = moment(appointmentDatetime).toDate();
    const mainView = App.mainView;
    const isCalendarPage = () => mainView instanceof App.Views.Calendar;
    const needHighlightAppointmentOnCalendar = isCalendarPage() && appointmentVisibleOnCalendar;

    const getOnCalendarRenderPromise = () =>
      new Promise((resolve) => {
        App.once('calendar:objects-rendered', resolve);
      });

    const highlightAppointmentOnCalendar = () => {
      const calendar = mainView.appointmentCalendar;

      if (calendar.isAppointmentInCalendar(appointmentId)) {
        calendar.setAppointmentToScrollTo(appointmentId);
        calendar.scrollToAppointment(() => calendar.indicateAppointment(appointmentId));
      } else {
        trackEvent('notifications', 'click', 'appointment-notification-not-exists-in-calendar');
        App.Views.Forms.Appointment2.openById(appointmentId);
      }
    };

    const onDayViewChange = () => {
      const calendar = mainView.appointmentCalendar;
      const calendarDatetime = calendar.getDate();
      const sameDay = moment(appointmentDate)
        .startOf('day')
        .isSame(moment(calendarDatetime).startOf('day'));
      let dateChanged = Promise.resolve();

      if (!sameDay) {
        dateChanged = getOnCalendarRenderPromise();
        calendar.trigger('change:date', appointmentDate);
      }

      dateChanged.then(highlightAppointmentOnCalendar);
    };

    const onCalendarChange = () => {
      const calendar = mainView.appointmentCalendar;
      const calendarType = calendar.getCalendarType();
      const needChangeToDayView = calendarType !== 'day';
      let dayViewChanged = Promise.resolve();

      if (needChangeToDayView) {
        dayViewChanged = getOnCalendarRenderPromise();
        calendar.setCalendarType('day');
      }

      dayViewChanged.then(onDayViewChange);
    };

    if (needHighlightAppointmentOnCalendar) {
      let calendarChanged = Promise.resolve();
      const mainCalendarType = mainView.getCalendarType();

      if (mainCalendarType !== 'appointment') {
        calendarChanged = getOnCalendarRenderPromise();
        mainView.changeType('appointment');
      }

      calendarChanged.then(onCalendarChange);
    } else {
      App.Views.Forms.Appointment2.openById(appointmentId);
    }
  },

  onAppointmentNotificationsListLoad: function (unseenCount) {
    this.totalCount -= this.unseenAppointmentNotificationsCount;
    this.unseenAppointmentNotificationsCount = unseenCount;
    this.totalCount += unseenCount;
    this.setTotalCount();
  },

  isAppointmentNotificationsPermitted: function () {
    const venueActiveOrUseWidget =
      (App.config.isVenueActive() && App.isFeatureSupported('marketplace')) ||
      App.config.get('venue').useOnlineBookingWidget;
    return Wahanda.Permissions.viewAnyCalendar() && venueActiveOrUseWidget;
  },

  renderAppointmentNotifications: function () {
    const self = this;
    App.ES6.Initializers.NotificationsList.render(self.$('.activity-log-container').get(0), {
      onAppointmentClick: self.onAppointmentNotificationClick.bind(self),
      onChange: self.onAppointmentNotificationsListLoad.bind(self),
    });
  },

  showCountBadge: function () {
    this.$el.removeClass('no-badge').css('width', '');
  },

  hideCountBadge: function () {
    const isShown = !this.$el.hasClass('no-badge');
    if (isShown) {
      // Save the current width
      this.$el.css('width', this.$el.width());
    }
    this.$el.addClass('no-badge');
  },

  renderFulfillmentAddress: function () {
    const venue = this._getVenueModel();
    if (venue.getFulfillmentAddress() == null) {
      this.analyticsData.primaryNotificationEmailMissing = true;
      this.analyticsData.noNotification = false;
      // Passing the count is as null as we do not need to show the count
      this.addLink(
        Wahanda.Url.getFullUrl(
          'settings?prevalidate=fulfillment',
          'notifications-settings/fulfillment',
        ),
        Wahanda.lang.header.notifications.links.noFulfillmentAddress,
        null,
        'track-fulfillment-address-click',
      );
      this.totalCount += 1;
    }
  },

  renderUnpaidRemittance: function () {
    if (this.unpaidRemittance.get('total') > 0) {
      this.analyticsData.unpaidRemittance = true;
      this.analyticsData.noNotification = false;
      this.addLink(
        Wahanda.Url.getFullUrl('settings?notification=true', 'finance/payments'),
        Wahanda.lang.header.notifications.links.unpaidRemittance,
        this.unpaidRemittance.get('total'),
        'track-unpaid-remittance-click',
      );
      this.totalCount += 1;
    }
  },

  onClickUnpaidRemittance: function () {
    trackEvent('unpaid-remittance', 'clicked');
  },

  onClickUnconfirmedAppointment: function () {
    trackEvent('unconfirmed-appointments', 'clicked');
  },

  onClickEmailMissing: function () {
    trackEvent('primary-notiifcation-email-missing', 'clicked');
  },

  onClickDated: function () {
    trackEvent('dated', 'clicked');
  },

  renderUnconfirmedOnlineAppointments: function () {
    const count = this.unconfirmedOnlineAppointments.countUniqueViewItems();
    if (count > 0) {
      this.analyticsData.unconfirmedAppointment = true;
      this.analyticsData.noNotification = false;
    }
    this._addLinkIfCountNotZero(
      count,
      Wahanda.Url.getFullUrl('calendar', 'appointment'),
      Wahanda.lang.header.notifications.links.onlineUnconfirmedAppointments,
      'track-unconfirmed-online-appointments',
    );
  },

  _addLinkIfCountNotZero: function (count, link, title) {
    if (count > 0) {
      this.addLink(link, title, count);
      this.totalCount += count;
    }
  },

  _getVenueModel: function () {
    return this.venueModel;
  },

  _getUnpaidRemittanceModel: function () {
    return this.unpaidRemittance;
  },

  setTotalCount: function () {
    if (this.totalCount > 0) {
      this.showCountBadge();
    } else {
      this.hideCountBadge();
    }
  },

  // Actions
  /** @return jQuery */
  getList: function () {
    return this.$('.notification-list');
  },

  /**
   * @param String url
   * @param String name
   * @param int count
   */
  addLink: function (url, title, count, classes) {
    const notificationTitleAdded = this.$('.notification-list-title-container:empty').length === 0;

    if (!notificationTitleAdded) {
      App.ES6.Initializers.NotificationsListHeader.render(
        this.$('.notification-list-title-container').get(0),
        { text: Wahanda.lang.header.notifications.title },
      );
    }

    this.$('.notifications-link-list-container').append(
      Wahanda.Template.renderTemplate('notifications-list-item', {
        link: url,
        title: title,
        count: count,
        class: classes,
      }),
    );
  },
});
