import React from 'react';
import Wahanda from 'common/wahanda';
import SelectDropdown from 'components/common/SelectDropdown';
import { CLEANUP_TIME_SLOTS } from 'common/constants/timeChoices';

import style from './CleanupTime.scss';

const lang = Wahanda.lang.menu.offer;

interface Props {
  cleanupTime: number;
  onDurationChange: (cleanupTime: any) => void;
  escapeWithSkuPricing: boolean;
}

export class CleanUpTime extends React.PureComponent<Props> {
  public render() {
    if (this.props.escapeWithSkuPricing) {
      return null;
    }
    const choices = this.dropdownChoices();
    return (
      <div className={style.container}>
        <h3 className={style.header}>{lang.cleanupTime.label}</h3>
        <div className={style.dropdown}>
          <SelectDropdown
            data={choices}
            onSelect={this.props.onDurationChange}
            selected={this.props.cleanupTime}
          />
        </div>
      </div>
    );
  }

  private dropdownChoices = () => {
    return CLEANUP_TIME_SLOTS.map((minutes) => {
      const timeOption = Wahanda.lang.datetime.distance_in_words.x_minutes.other.replace(
        '{{count}}',
        minutes,
      );

      const name = minutes === null ? Wahanda.lang.menu.offer.pricing.noTimeSelected : timeOption;
      const value = minutes;

      return { value, name };
    });
  };
}
