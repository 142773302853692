import React from 'react';
import { Checkbox } from 'components/common/Checkbox';

import { MenuGroup } from './MenuGroup';
import EmptyState from './EmptyState';

import style from './TreatmentList.scss';

interface Props {
  actions: {
    requestMenuItemsAction: (...args: any[]) => void;
    setCheckedMenuItemsAction: (...args: any[]) => void;
  };
  menuItemGroups: any[];
  offersExist: boolean;
  assignedTreatments: any[];
  discountId?: number;
  discountName?: string;
  discountBadgeVisible?: boolean;
  filterType?: string;
  includedServiceTypes?: string[];
  selectAllCheckboxVisible?: boolean;
  hasChanges?: () => void;
}

export class TreatmentList extends React.Component<Props> {
  public static defaultProps = {
    discountBadgeVisible: false,
    selectAllCheckboxVisible: false,
    hasChanges: () => false,
  };

  public componentDidMount() {
    const {
      actions: { requestMenuItemsAction },
      assignedTreatments,
      filterType,
      includedServiceTypes,
    } = this.props;

    requestMenuItemsAction({
      assignedTreatments,
      filterType,
      includedServiceTypes,
    });
  }

  private handleSelectAllChange = (checked) => {
    const {
      actions: { setCheckedMenuItemsAction },
      menuItemGroups,
    } = this.props;
    const allMenuItemsIds = [];

    menuItemGroups.forEach((group) =>
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      group.offers.forEach((offer) => allMenuItemsIds.push(offer.id)),
    );

    setCheckedMenuItemsAction(allMenuItemsIds, checked);
  };

  private areAllMenuItemsChecked = () => {
    const { menuItemGroups } = this.props;
    return menuItemGroups
      .map((group) => group.offers.every((offer) => offer.checked))
      .every((checked) => checked);
  };

  private renderEmptyState() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasChanges' does not exist on type 'Read... Remove this comment to see the full error message
    // eslint-disable-line class-methods-use-this
    return <EmptyState hasChanges={this.props.hasChanges} />;
  }

  private renderGroups() {
    const {
      menuItemGroups,
      discountId,
      discountName,
      discountBadgeVisible,
      selectAllCheckboxVisible,
    } = this.props;

    return (
      <div className={style.list}>
        {selectAllCheckboxVisible && (
          <Checkbox
            name="selectAll"
            label={Wahanda.lang.menu.offer.allServices}
            checked={this.areAllMenuItemsChecked()}
            onChange={this.handleSelectAllChange}
            className={style.selectAllCheckbox}
          />
        )}
        {menuItemGroups.map((group) => (
          <MenuGroup
            group={group}
            key={group.id}
            discountId={discountId}
            discountName={discountName}
            discountBadgeVisible={discountBadgeVisible}
          />
        ))}
      </div>
    );
  }

  public render() {
    const { offersExist } = this.props;

    return offersExist ? this.renderGroups() : this.renderEmptyState();
  }
}
