import {
  GET_MENU_ITEMS_REQUEST,
  GET_MENU_ITEMS_RECEIVE,
  SET_ALL_MENU_ITEMS_CHECKED,
  SET_MENU_ITEM_CHECKED,
  SET_MENU_ITEMS_CHECKED,
} from 'reduxStore/actionsConst';

export const requestMenuItemsAction = ({
  assignedTreatments,
  filterType,
  includedServiceTypes,
}) => ({
  type: GET_MENU_ITEMS_REQUEST,
  assignedTreatments,
  filterType,
  includedServiceTypes,
});

export const receiveMenuItemsAction = (data) => ({
  type: GET_MENU_ITEMS_RECEIVE,
  data,
});

export const setCheckedDataAction = ({ assignedTreatments }) => ({
  type: SET_ALL_MENU_ITEMS_CHECKED,
  assignedTreatments,
});

export const setCheckedMenuItemAction = (offerId, checked) => ({
  type: SET_MENU_ITEM_CHECKED,
  offerId,
  checked,
});

export const setCheckedMenuItemsAction = (offerIds, checked) => ({
  type: SET_MENU_ITEMS_CHECKED,
  offerIds,
  checked,
});
