import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeBuilder from 'reduxStore/store';
import { Marketing } from 'components/marketing/container';
import { HashRouter } from 'react-router-dom';
// This is our main store. It is reused for the same venue, until a venue change happens.
const store = storeBuilder();

export const MarketingInitializer = () => {
  return {
    destroy: (target) => ReactDOM.unmountComponentAtNode(target),
    render: (target, props) => {
      ReactDOM.render(
        <Provider store={store}>
          <HashRouter hashType="noslash" basename={`venue/${App.getVenueId()}`}>
            <Marketing {...props} />
          </HashRouter>
        </Provider>,
        target,
      );
    },
  };
};
