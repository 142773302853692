import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import App from 'common/backbone-app';

import Wahanda from 'common/wahanda';

import EmailCampaignDialog from 'components/menu/DiscountsTab/EmailCampaignDialog/';
import * as actions from './actions';

import {
  getDiscountId,
  isCurrentDiscountActive,
  isDiscountOffpeakActive,
  isLastMinuteActive,
  getAssignedServices,
} from './selectors';

const mapStateToProps = (state) => {
  const isVisible: boolean =
    isCurrentDiscountActive(state) &&
    (isDiscountOffpeakActive(state) || isLastMinuteActive(state)) &&
    getAssignedServices(state).length > 0 &&
    Wahanda.Permissions.canAccessMarketingTools() &&
    App.isFeatureSupported('promo-offers-cmpgn');

  return {
    isVisible,
    discountId: getDiscountId(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: bindActionCreators(actions.hideEmailCampaignDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailCampaignDialog);
