import React from 'react';
import classname from 'classnames';
import ReactDialog from 'components/common/react-dialog';
import { DialogFooter, DialogFooterButton } from 'components/common/DialogFooter';
import Loader from 'components/common/Loader';
import { Notice } from 'components/common/Notice';
import DialogContentBox from 'components/common/dialog/DialogContentBox';
import {
  Table,
  TableColumnHeader,
  TableRow,
  TableBody,
  TableHeader,
} from 'components/common/Table';
import Wahanda from 'common/wahanda';
import { OrderProducts } from 'common/analytics';
import TotalQuantityLabel from '../TotalQuantityLabel';
import ProcessOrderRow from './ProcessOrderRow/container';
import style from './style.scss';

const LANG = Wahanda.lang.menu.products.orders.orderProcessDialog;
interface IProcessOrderDialogProps extends React.HTMLAttributes<Element> {
  productOrder: {
    created?: string;
    id?: number;
    status?: string;
    totalQuantityOrdered?: number;
    productOrderItems?: {
      quantity?: number;
      id?: number;
      name?: string;
      venueProductId?: number;
    }[];
  };
  actions: {
    requestCompleteOrderAction?: (...args: any[]) => any;
  };
  onClose: (...args: any[]) => any;
  loadingOrderComplete: boolean;
  totalQuantityOrdered?: any;
  map?: any;
  productOrderItems?: any;
  requestCompleteOrderAction?: any;
}
class ProcessOrderDialog extends React.Component<IProcessOrderDialogProps, {}> {
  componentDidMount() {
    OrderProducts.trackViewProcessOrderDialog();
  }

  handleCompleteOrder = () => {
    OrderProducts.trackClickCompleteOrder();
    const data = {
      productOrderId: this.props.productOrder.id,
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      productOrderItems: this.props.productOrder.productOrderItems.map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
    };
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.actions.requestCompleteOrderAction(data);
    this.props.onClose();
  };

  trackAndClose = () => {
    OrderProducts.trackClickCloseOrderProcessing();
    this.props.onClose();
  };

  trackAndCancel = () => {
    OrderProducts.trackClickCancelProcessing();
    this.props.onClose();
  };

  renderCompleteOrderButton = () => (
    <DialogFooterButton title={LANG.completeOrderButton} onClick={this.handleCompleteOrder} />
  );

  renderCancelButton = () => (
    <DialogFooterButton title={LANG.closeButton} type="secondary" onClick={this.trackAndCancel} />
  );

  renderProductOrders() {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return this.props.productOrder.productOrderItems.map((item) => (
      <ProcessOrderRow key={item.id} productInOrderId={item.id} />
    ));
  }

  renderDialogContent = () => (
    <div className={style.dialogContent}>
      <DialogContentBox>
        <Notice className={style.messageMargin} type="success" message={LANG.message} />
        <Table>
          <TableHeader>
            <TableRow>
              <TableColumnHeader className={style.noWrap}>
                {LANG.tableHeader.product}
              </TableColumnHeader>
              <TableColumnHeader className={classname(style.right, style.noWrap)}>
                {LANG.tableHeader.quantityReceived}
              </TableColumnHeader>
            </TableRow>
          </TableHeader>
          <TableBody>{this.renderProductOrders()}</TableBody>
        </Table>
        {/* @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type 'number... Remove this comment to see the full error message */}
        <TotalQuantityLabel quantity={this.props.productOrder.totalQuantityOrdered} />
      </DialogContentBox>
    </div>
  );

  renderDialogFooter = () => (
    <DialogFooter>
      {this.renderCancelButton()}
      {this.renderCompleteOrderButton()}
    </DialogFooter>
  );

  render() {
    return (
      <ReactDialog
        dataTest="product-process-order-modal"
        title={LANG.title}
        onClose={this.trackAndClose}
        width={700}
        warnWhenClosingChangedForms={false}
        noContentPadding
      >
        {this.props.loadingOrderComplete ? <Loader /> : this.renderDialogContent()}
        {this.renderDialogFooter()}
      </ReactDialog>
    );
  }
}

export default ProcessOrderDialog;
