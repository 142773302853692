import { put, call } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { requestFeatures, setFeatures, requestFeaturesSuccess } from './actions';
import { updateCookie, getLocallyEnabledFeatures } from '../TrunkFeatures';

export function* requestEnabledFeatures(action: ReturnType<typeof requestFeatures>) {
  try {
    const { enabledFeatures } = yield call(
      api.get,
      apiUrl('VENUE_ENABLED_FEATURES', action.payload),
      {
        skipErrorHandling: true,
      },
    );
    yield put(requestFeaturesSuccess({ features: enabledFeatures }));
  } catch (e) {
    yield put(requestFeaturesSuccess({ features: [] }));
  }
}

export function* featuresResolved(action: ReturnType<typeof requestFeaturesSuccess>) {
  const features = yield call(getLocallyEnabledFeatures);
  updateCookie(features);
  yield put(setFeatures({ features: [...action.payload.features, ...features] }));
}
