/* eslint arrow-body-style: 0 */
import React from 'react';
import { get, getIn, partial, toJs } from 'mori';
import Wahanda from 'common/wahanda';
import TransactionSummaryDialog from 'src/components/reports/transactions/common/transaction-summary';
import LinkedTransaction from 'src/components/reports/transactions/common/transaction-summary/linked-transaction';
import apiUrl from 'common/api-url';
import { Button } from 'components/common/Button';
import { DialogFooter } from 'components/common/DialogFooter';

interface ICancellationSummaryDialogProps extends React.HTMLAttributes<Element> {
  data?: {};
  onClose?: (...args: any[]) => any;
  openCheckoutSummary?: (...args: any[]) => any;
  openReceiptCopyDialog?: (...args: any[]) => any;
}

export default class CancellationSummaryDialog extends React.PureComponent<
  ICancellationSummaryDialogProps,
  {}
> {
  renderFooter = () => {
    const renderNewTabLink = ({ text, href, dataTest }) => {
      if (!App.config.get('venue').pointOfSaleEnabled) {
        return null;
      }
      return (
        <div className="button-wrapper">
          <Button
            dataTest={dataTest}
            variant="hollow"
            colour="help"
            href={href}
            label={text}
            opensInNewWindow
          />
        </div>
      );
    };
    const renderReceiptCopyLink = () => {
      const cancellationInformation = toJs(get(this.props.data, 'cancellationInformation'));
      const receiptUri = cancellationInformation?.receiptUri;

      if (!receiptUri) {
        return null;
      }

      return (
        <div className="button-wrapper">
          <Button
            dataTest="checkout-summary-reprint-receipt"
            variant="hollow"
            onClick={this.props.openReceiptCopyDialog}
            colour="help"
            label={Wahanda.lang.pos.receiptCopyDialog.cta}
          />
        </div>
      );
    };

    const renderTipWithdrawalLink = () => {
      const tipRefund = toJs(get(this.props.data, 'tipRefund'));
      if (tipRefund) {
        const receiptUrl = apiUrl('POS_TRANSACTION_RECEIPT', {
          receiptId: tipRefund.receiptUri,
        });
        // @ts-expect-error ts-migrate(2345) FIXME: Property 'dataTest' is missing in type '{ text: an... Remove this comment to see the full error message
        return renderNewTabLink({
          text: Wahanda.lang.reports.transactions.refundSummary.printDepositReceiptButton,
          href: receiptUrl,
        });
      }
      return null;
    };
    return (
      <DialogFooter fullWidth>
        {renderReceiptCopyLink()}
        {renderTipWithdrawalLink()}
      </DialogFooter>
    );
  };

  render() {
    // Get the Checkout Summary only if the POS Status is loaded.
    const { data, onClose, openCheckoutSummary } = this.props;
    const lang = Wahanda.lang.reports.transactions.refundSummary;
    const checkoutId = getIn(data, ['cancellationInformation', 'checkoutId']);
    const referenceTransactionId = getIn(data, [
      'cancellationInformation',
      'referenceTransactionId',
    ]);
    return (
      <TransactionSummaryDialog
        type="cancellation"
        data={data}
        dialogTitle={lang.dialogTitle}
        onClose={onClose}
        linkedTransaction={(() => (
          <LinkedTransaction
            linkText={lang.linkedRefund}
            transactionId={referenceTransactionId}
            onLinkClick={partial(openCheckoutSummary, checkoutId)}
          />
        ))()}
        footerContent={this.renderFooter()}
      />
    );
  }
}
