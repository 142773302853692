import {
  START_CAMPAIGN_SELECTION_FLOW,
  RESET_CAMPAIGN_SELECTION_FLOW,
  SELECT_PROMOTION,
  GET_SMART_DISCOUNTS_RECEIVE,
  GET_TREATMENT_TYPES_RECEIVE,
  SELECT_DISCOUNT,
  SELECT_TREATMENT_GROUP,
} from 'reduxStore/actionsConst';

import processDiscountRules from './reducerHelpers';

const defaultState = {
  flowInProgress: false,
  currentStep: null,
  promotionalOffers: {
    selectedPromotion: null,
    smartDiscounts: [],
    isDataLoaded: false,
    serviceOnSaleTreatmentGroups: [],
  },
};

const campaignSelection = (state = defaultState, action) => {
  switch (action.type) {
    case START_CAMPAIGN_SELECTION_FLOW:
      return {
        ...state,
        flowInProgress: true,
        currentStep: 1,
      };

    case RESET_CAMPAIGN_SELECTION_FLOW:
      return {
        ...defaultState,
      };

    case SELECT_PROMOTION:
      return {
        ...state,
        currentStep: 2,
        promotionalOffers: {
          ...state.promotionalOffers,
          selectedPromotion: action.data,
        },
      };

    case GET_SMART_DISCOUNTS_RECEIVE:
      return {
        ...state,
        promotionalOffers: {
          ...state.promotionalOffers,
          isDataLoaded: true,
          smartDiscounts: processDiscountRules(action.data),
        },
      };

    case GET_TREATMENT_TYPES_RECEIVE:
      return {
        ...state,
        promotionalOffers: {
          ...state.promotionalOffers,
          isDataLoaded: true,
          serviceOnSaleTreatmentGroups: action.data.serviceOnSaleTreatmentGroups,
        },
      };

    case SELECT_DISCOUNT:
      return {
        ...defaultState,
        promotionalOffers: {
          ...defaultState.promotionalOffers,
          selectedDiscountRuleId: action.data.selectedDiscountRuleId,
        },
      };

    case SELECT_TREATMENT_GROUP:
      return {
        ...defaultState,
        promotionalOffers: {
          ...defaultState.promotionalOffers,
          selectedTreatmentGroupId: action.data.selectedTreatmentGroupId,
        },
      };

    default:
      return state;
  }
};

export default campaignSelection;
