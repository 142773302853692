import { trackEvent, ACTIONS } from 'common/analytics';

export class ClientAnalytics {
  public static CATEGORY = 'client';

  public static trackPrepayRequirementChanged(isPrepaymentRequired) {
    trackEvent(
      ClientAnalytics.CATEGORY,
      ACTIONS.SUBMIT,
      'require-customer-prepayment',
      isPrepaymentRequired ? 'yes' : 'no',
    );
  }
}
