import React from 'react';
import ReactDOM from 'react-dom';
import Wahanda from 'common/wahanda';
import { Initializer } from 'common/types/initializers';
import { DialogConfirmation } from './';

const container = Wahanda.Dialogs.getReactContainer();
interface Params {
  title: string;
  text: string | object;
}

function destroy(target) {
  ReactDOM.unmountComponentAtNode(target);
}

const onClose = () => {
  destroy(container);
};

export const DialogConfirmationInitializer = (params: Params): Initializer => ({
  destroy: () => ReactDOM.unmountComponentAtNode(container),
  render: () => {
    ReactDOM.render(<DialogConfirmation {...params} onClose={onClose} />, container);
  },
});
