/**
 * Weekday checkboxes partial.
 *
 * Possible options:
 * > Array selectedDays  - list of on rendering selected days
 * > int   tabindexStart - from which INT to start the tabindex on each day
 */
App.Views.Partials.WeekdayCheckboxes = App.Views.Form.extend({
  events: {
    "change input[type='checkbox']": 'onChangeDayAvailability',
    'change select': 'onChangeWorkTime',
  },

  options: {
    tabindexStart: 120,
    lastCanBeDeselectedText: Wahanda.lang.menu.offer.fulfillment.canNotDeselectLast,
    icon: 'tick2',
    activeClass: 'on',
  },

  render: function () {
    var firstDayInt =
      Wahanda.Date.dayStringToNumberMap[App.config.get('jqueryDateFormat').firstDayOfWeek];
    var days = _.clone(Wahanda.lang.date.weekdaysMini);
    var weekdays = [];
    var self = this;
    // Default - all are active
    var active =
      this.options.selectedDays && this.options.selectedDays.length > 0
        ? this.options.selectedDays
        : Wahanda.Date.dayNumberToStringMap;
    var disabled =
      this.options.disabledDays && this.options.disabledDays.length > 0
        ? this.options.disabledDays
        : [];
    for (var i = 0; i < 7; i++) {
      var dayString = Wahanda.Date.dayNumberToStringMap[i];
      weekdays.push({
        on: -1 !== _.indexOf(active, dayString),
        value: Wahanda.Date.dayNumberToStringMap[i],
        day: Wahanda.lang.date.weekdaysMini[i],
        tabindex: this.tabindexStart + i,
        disabled: -1 !== _.indexOf(disabled, dayString),
      });
    }
    // Sort the days by firstDayOfWeek
    weekdays = _.sortBy(weekdays, function (day, dayOfWeek) {
      if (dayOfWeek < firstDayInt) {
        return dayOfWeek + 7;
      }
      return dayOfWeek;
    });

    this.$el
      .html(
        Wahanda.Template.renderTemplate('weekday-checkboxes', {
          weekdays: weekdays,
          icon: this.options.icon,
          activeClass: this.options.activeClass,
        }),
      )
      .buttonList({
        multiple: true,
        allowEvent: function () {
          var result = $.fn.buttonList.lastNotUncheckable.apply(this, arguments);

          if (!result) {
            Wahanda.Dialogs.Alert(self.options.lastCanBeDeselectedText);
          }

          return result;
        },
        activeClass: this.options.activeClass,
      });
  },

  /**
   * Handler clicks on the enabled/disabled day's checkboxes
   *
   * @param Event event
   */
  onChangeDayAvailability: function (event) {
    var checkbox = event.target;
    var elem = $(checkbox).closest('li');
    elem[checkbox.checked ? 'addClass' : 'removeClass']('on');
    elem[checkbox.checked ? 'removeClass' : 'addClass']('off');
    // Disabling the selects
    var selects = elem.find('select');
    selects.attr('disabled', !checkbox.checked);

    this.updateOpeningHours(elem);
  },

  onChangeWorkTime: function (event) {
    var $select = $(event.target);
    this.alignTimeSelects($select);

    this.updateOpeningHours($select.closest('li'));
  },

  /**
   * Aligns two time selects so that the first is always earlyer than the later.
   *
   * @param jQuery $select
   */
  alignTimeSelects: function ($select) {
    var $otherSelect = $select.siblings('select').eq(0);
    var shouldBeLess = /from/.test($select.attr('name'));
    // 24 hours
    var max = 1440;
    var step = 60;
    var change = null;

    var time = Wahanda.Time.timeToMinutes($select.val());
    var other = Wahanda.Time.timeToMinutes($otherSelect.val());

    if (shouldBeLess && time >= other) {
      change = time + step;
      if (change > max) {
        change = max;
      }
    } else if (!shouldBeLess && time <= other) {
      change = time - step;
      if (change < 0) {
        change = 0;
      }
    }

    if (null !== change) {
      $otherSelect
        .val(Wahanda.Time.toFormatted(change, 'H:i'))
        // Trigger the change event
        .change();
    }
  },

  /**
   * Updates opening hours based on the DOM state.
   *
   * @param jQuery $row The changed row, containing checkbox and two selects
   */
  updateOpeningHours: function ($row) {
    var $chbox = $row.find("input[type='checkbox']");
    var sels = $row.find('select');
    var $from = sels.eq(0);
    var $to = sels.eq(1);

    var dayId = $chbox.attr('id');
    var from = '';
    var to = '';

    if ($chbox.prop('checked')) {
      from = $from.val();
      to = $to.val();
    }
    this.model.setOpeningHours(dayId, from, to);
  },

  getValues: function () {
    var list = [];
    this.$('li.' + this.options.activeClass).each(function () {
      list.push($(this).data('value'));
    });
    return _.difference(list, this.options.disabledDays || []);
  },
});
