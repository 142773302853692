import React, { Component } from 'react';
import { toClj } from 'mori';
import Wahanda from 'common/wahanda';
import SelectDropdown from 'components/common/SelectDropdown';
import {
  Table,
  TableColumnHeader,
  TableRow,
  TableBody,
  TableHeader,
  TableData,
} from 'components/common/Table';
import { IntegrationSetupAnalytics } from 'common/analytics';
import style from './style.scss';

const lang = Wahanda.lang.settings.venue.integrations.employeesMapping;
interface IEmployeesMappingProps extends React.HTMLAttributes<Element> {
  externalSystemName?: string;
  externalSystemEmployees: {
    id?: string | number;
    name: string;
  }[];
  treatwellEmployees: {
    id: number;
    name: string;
  }[];
  employeeLinks: {
    externalEmployeeId?: string | number;
    treatwellEmployeeId: number;
  }[];
  readOnly?: boolean;
  hideHeader?: boolean;
  hideDescription?: boolean;
  treatwellEmployeesActions: {
    requestEmployeesAction: (...args: any[]) => any;
    resetStateAction: (...args: any[]) => any;
  };
  externalSalonIntegrationActions: {
    requestExternalSalonEmployeesAction: (...args: any[]) => any;
    addEmployeeLinkAction: (...args: any[]) => any;
    removeEmployeeLinkAction: (...args: any[]) => any;
  };
  find?: any;
  map?: any;
  removeEmployeeLinkAction?: any;
  addEmployeeLinkAction?: any;
  resetStateAction?: any;
  requestExternalSalonEmployeesAction?: any;
  requestEmployeesAction?: any;
}
class EmployeesMapping extends Component<IEmployeesMappingProps, {}> {
  static defaultProps = {
    externalSystemName: null,
    readOnly: false,
    hideHeader: false,
    hideDescription: false,
  } as any;

  componentDidMount() {
    this.props.treatwellEmployeesActions.requestEmployeesAction();
    this.props.externalSalonIntegrationActions.requestExternalSalonEmployeesAction();
  }

  componentWillUnmount() {
    this.props.treatwellEmployeesActions.resetStateAction();
  }

  onTreatwellEmployeeSelect = (externalEmployeeId, treatwellEmployeeId) => {
    IntegrationSetupAnalytics.trackEmployeeMappingDropdownSelect(treatwellEmployeeId);
    if (treatwellEmployeeId != null) {
      this.props.externalSalonIntegrationActions.addEmployeeLinkAction({
        externalEmployeeId,
        treatwellEmployeeId,
      });
    } else {
      this.props.externalSalonIntegrationActions.removeEmployeeLinkAction(externalEmployeeId);
    }
  };

  getTreatwellEmployeesOptions = (selected) => {
    const { treatwellEmployees } = this.props;
    const selectedTreatwellEmployeeIds = this.props.employeeLinks.map(
      (link) => link.treatwellEmployeeId,
    );
    const filter = (employee) =>
      selectedTreatwellEmployeeIds.indexOf(employee.id) === -1 || employee.id === selected;
    const options = treatwellEmployees.filter(filter).map((employee) => ({
      value: employee.id,
      name: `${employee.name}`,
      secondaryText: `ID: ${employee.id}`,
    }));
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
    options.unshift({ value: null, name: lang.notLinked });
    return options;
  };

  getLinkedTreatwellEmployeeId = (externalEmployeeId) => {
    const link = this.props.employeeLinks.find((l) => l.externalEmployeeId === externalEmployeeId);
    return link ? link.treatwellEmployeeId : null;
  };

  render() {
    const {
      externalSystemEmployees,
      externalSystemName,
      readOnly,
      hideHeader,
      hideDescription,
    } = this.props;
    return (
      <div className={style.employeesMapping}>
        {!hideHeader && <h1 className={style.header}>{lang.header}</h1>}
        {!hideDescription && (
          <p className={style.description}>
            {Wahanda.Template.render(lang.description, { externalSystemName })}
          </p>
        )}
        <Table className={style.table}>
          <TableHeader>
            <TableRow>
              <TableColumnHeader>{externalSystemName}</TableColumnHeader>
              <TableColumnHeader>{Wahanda.lang.shared.appName}</TableColumnHeader>
            </TableRow>
          </TableHeader>
          <TableBody>
            {externalSystemEmployees.map((employee) => {
              const selectedTreatwellEmployeeId = this.getLinkedTreatwellEmployeeId(employee.id);
              return (
                <TableRow key={employee.id}>
                  <TableData>
                    <div>{employee.name}</div>
                    <div className={style.employeeId}>{`ID: ${employee.id}`}</div>
                  </TableData>
                  <TableData className={style.rightColumn}>
                    <SelectDropdown
                      data={toClj(this.getTreatwellEmployeesOptions(selectedTreatwellEmployeeId))}
                      selected={selectedTreatwellEmployeeId}
                      onSelect={(id) => this.onTreatwellEmployeeSelect(employee.id, id)}
                      onOpen={IntegrationSetupAnalytics.trackEmployeeMappingDropdownClick}
                      extraClassName={style.employeeSelect}
                      disabled={readOnly}
                    />
                  </TableData>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default EmployeesMapping;
