/**
 * Popup menu view
 *
 * The template must be similar to the following skeleton:
 <span class="popup-menu--button"></span>
  <ul class="hidden popup-menu--list">
      {{#items}}
          <li class='{{classes}} popup-menu--item'>{{name}}</li>
      {{/items}}
  </ul>
 *
 * On initialize you might provide the "parentEl" property to bind for off-clicks to close the menu.
 */
(function () {
  var DEFAULT_TEMPLATE = 'popup-menu-template';
  var BaseView = Backbone.View;
  App.Views.Partials.PopupMenu = BaseView.extend({
    events: {
      'click .popup-menu--button': 'toggleMenu',
      'click .popup-menu--item': 'onMenuItemClick',
    },

    $list: null,
    open: false,

    initialize: function (options) {
      if (options && options.parentEl) {
        this.setupCloseListeners($(options.parentEl));
      }
    },

    /**
     * If parentEl is provided, setup onClick listener on it to close the menu.
     *
     * @param jQuery $el
     */
    setupCloseListeners: function ($el) {
      var self = this;
      $el.on('click.' + this.cid, function (event) {
        if (!self.open || $.contains(self.$el.get(0), event.target)) {
          return;
        }
        self.toggleMenu();
      });
    },

    render: function (templateId, data) {
      var html = Wahanda.Template.renderTemplate(templateId || DEFAULT_TEMPLATE, data);
      this.$el.html(html);

      this.$list = this.$('.popup-menu--list');
    },

    remove: function () {
      BaseView.prototype.remove.call(this);
      if (this.options && this.options.parentEl) {
        this.options.parentEl.off('.' + this.cid);
      }
    },

    toggleMenu: function () {
      this.open = !this.open;
      this.$list.toggle(this.open);
    },

    onMenuItemClick: function (e) {
      this.toggleMenu();
      this.trigger('item-clicked', {
        item: $(e.currentTarget),
      });
    },
  });
})();
