import Wahanda from 'common/wahanda';

export const DEFAULT_RECURRENCE_COUNT = 5;

export const DEFAULT_RECURRENCE_INTERVAL = 2;

export const COUNTS = Object.keys(Array(49).fill('')).map((item) => ({
  value: Number(item) + 2,
  name: Wahanda.Template.render(Wahanda.lang.calendar.appointments.multi.labels.repeatTimes, {
    count: Number(item) + 2,
  }),
}));

const weekTitle = (week) => {
  return `${week} ${(parseInt(week, 10) === 1
    ? Wahanda.lang.datetime.duration.week
    : Wahanda.lang.datetime.duration.weeks
  ).toLowerCase()}`;
};

const INTERVALS_START_RANGE = Object.keys(Array(11).fill('')).map((item) => ({
  value: Number(item) + 1,
  name: weekTitle(Number(item) + 1),
}));

export const INTERVALS = [
  ...INTERVALS_START_RANGE,
  { value: 16, name: weekTitle(16) },
  { value: 20, name: weekTitle(20) },
];
