import React from 'react';
import classnames from 'classnames';

import { SidebarInfo } from '../SidebarInfo';
import { SidebarLink, SidebarLinkProps } from '../SidebarLink';

import style from './Sidebar.scss';
import { OnboardingWidget } from 'components/shared/OnboardingWidget/OnboardingWidget';
import App from 'common/backbone-app';
import { ChatSourceType } from 'src/tracking-container/events/chat';

interface Props {
  links?: SidebarLinkProps[];
  children?: React.ReactNode;
  classes?: string;
  liveChatSource: ChatSourceType;
  hidden?: boolean;
}

export class Sidebar extends React.PureComponent<Props> {
  public render() {
    const { children, classes, links = [], liveChatSource, hidden } = this.props;
    const renderLink = () => {
      return links.map((link) => <SidebarLink {...link} key={link.dataTest} />);
    };

    if (hidden) {
      return null;
    }

    return (
      <div className={classnames(style.sidebar, [classes])} data-test="sidebar">
        {App.isOnboardingWidgetEnabled() ? (
          <OnboardingWidget />
        ) : (
          <>
            <div className={style.links}>{links.length > 0 ? renderLink() : children}</div>
            <SidebarInfo liveChatSource={liveChatSource} />
          </>
        )}
      </div>
    );
  }
}
