import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestEmployeeSalesReportDataAction } from '../actions';
import EmployeeSalesReport from './index';

const mapStateToProps = (state) => ({
  reportData: state.salesReport.employeeSales.reportData,
  displayState: state.salesReport.employeeSales.displayState,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ requestEmployeeSalesReportDataAction }, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

// @ts-expect-error ts-migrate(2345) FIXME: Type '{ reportData: null; }' is not assignable to ... Remove this comment to see the full error message
export default connector(EmployeeSalesReport);
