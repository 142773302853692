import React, { Component } from 'react';
import Wahanda from 'common/wahanda';
import _ from 'common/underscore';
import { Notice } from 'components/common/Notice';
import { UnderlinedPriceInput } from 'components/common/UnderlinedPriceInput';
import { isPackageSkus2d } from 'components/menu/OfferTreatmentPricingAndEmployees/utils';
import ServicePrices from './ServicePrices';
import PricingRow from './PricingRow';
import PricingRowTwoDimensional from './PricingRowTwoDimensional';
import { generateKey, findRepeatingSubSkuIdsInAllSkus } from './utils';
import getName from './PricingRow/utils';
import style from './style.scss';

const LANG = Wahanda.lang.menu.package;
interface IPackagePricingProps extends React.HTMLAttributes<Element> {
  isPackagePricingCustom: boolean;
  actions: {
    offerDialogChangePricingTypeAction: (...args: any[]) => any;
    offerDialogToggleSkuAction: (...args: any[]) => any;
    offerDialogUpdateFullPriceAmountAction: (...args: any[]) => any;
  };
  offerDialogSkus: any[];
  isSkuCombinationNotSupported: boolean;
  offerDialogSubServices: any[];
  readonlyLight?: any;
}

export default class PackagePricing extends Component<IPackagePricingProps, {}> {
  showPricingRows = (offerDialogSkus) => !(offerDialogSkus.length < 2);

  areAllSkusSingleSelection = (offerDialogSkus) => offerDialogSkus.length === 1;

  split2dPackages = (skus, subSkuIdsIgnoredForName, offerDialogSubServices) => {
    const groupedSkus = _.groupBy(
      skus.map((sku, index) => Object.assign({}, sku, { index })),
      (sku) => getName(sku.subSkus, subSkuIdsIgnoredForName, offerDialogSubServices),
    );
    return Object.keys(groupedSkus).map((key) => ({
      nameReadOnly: key,
      skus: groupedSkus[key],
      visible: groupedSkus[key].every((sku) => sku.visible),
      indexArray: groupedSkus[key].map((sku) => sku.index),
    }));
  };

  renderPricingRows = ({
    offerDialogSkus,
    isPackagePricingCustom,
    actions,
    offerDialogSubServices,
    readonlyLight,
  }) => {
    const subSkuIdsIgnoredForName = findRepeatingSubSkuIdsInAllSkus(offerDialogSkus);
    if (!isPackageSkus2d(offerDialogSkus)) {
      return (
        <div className={style.pricingRows}>
          {offerDialogSkus.map((sku, index) => (
            <PricingRow
              {...sku}
              offerDialogSubServices={offerDialogSubServices}
              index={index}
              isPackagePricingCustom={isPackagePricingCustom}
              actions={actions}
              key={generateKey(sku, isPackagePricingCustom)}
              subSkuIdsIgnoredForName={subSkuIdsIgnoredForName}
              readonlyLight={readonlyLight}
            />
          ))}
        </div>
      );
    }
    return (
      <ul>
        {this.split2dPackages(offerDialogSkus, subSkuIdsIgnoredForName, offerDialogSubServices).map(
          (sku2D) => (
            <div
              className={style.pricingRows}
              key={generateKey(sku2D.skus[0], isPackagePricingCustom)}
            >
              <PricingRowTwoDimensional
                {...sku2D}
                actions={actions}
                isPackagePricingCustom={isPackagePricingCustom}
              />
            </div>
          ),
        )}
      </ul>
    );
  };

  render() {
    const {
      isPackagePricingCustom,
      offerDialogSkus,
      actions,
      isSkuCombinationNotSupported,
      readonlyLight,
    } = this.props;
    const { offerDialogUpdateFullPriceAmountAction } = actions;
    const show = this.showPricingRows(offerDialogSkus);
    const showSingleSelectionPrice = this.areAllSkusSingleSelection(offerDialogSkus);
    return (
      <div className={style.container}>
        {isSkuCombinationNotSupported && (
          <Notice className={style.attention} message={LANG.unsupportedCombination} />
        )}
        <div className={style.servicePrices}>
          <ServicePrices
            isPackagePricingCustom={isPackagePricingCustom}
            actions={actions}
            readonlyLight={readonlyLight}
          />
          {showSingleSelectionPrice && (
            <UnderlinedPriceInput
              value={
                isPackagePricingCustom
                  ? offerDialogSkus[0].fullPriceAmount
                  : offerDialogSkus[0].calculatedPriceAmount
              }
              name={generateKey(offerDialogSkus[0], isPackagePricingCustom)}
              id={generateKey(offerDialogSkus[0], isPackagePricingCustom)}
              // @ts-expect-error ts-migrate(2322) FIXME: Property 'required' does not exist on type 'Intrin... Remove this comment to see the full error message
              required
              disabled={!isPackagePricingCustom || readonlyLight}
              controlledComponent
              onChange={(fullPriceAmount) =>
                offerDialogUpdateFullPriceAmountAction({
                  index: 0,
                  fullPriceAmount,
                })
              }
            />
          )}
        </div>
        {/* @ts-expect-error ts-migrate(2345) FIXME: Property 'readonlyLight' is optional in type 'Read... Remove this comment to see the full error message */}
        {show && this.renderPricingRows(this.props)}
      </div>
    );
  }
}
