import {
  GET_APPOINTMENT_NOTIFICATIONS_REQUEST,
  GET_APPOINTMENT_NOTIFICATIONS_RECEIVE,
  GET_APPOINTMENT_NOTIFICATIONS_FAILURE,
  SET_APPOINTMENT_NOTIFICATIONS_VISIBLE,
  GET_APPOINTMENT_NOTIFICATIONS_RESET,
} from 'reduxStore/actionsConst';

export const requestAppointmentNotificationsAction = () => ({
  type: GET_APPOINTMENT_NOTIFICATIONS_REQUEST,
});

export const receiveAppointmentNotificationsAction = (data) => ({
  type: GET_APPOINTMENT_NOTIFICATIONS_RECEIVE,
  data,
});

export const requestAppointmentNotificationsFailureAction = (ex) => ({
  type: GET_APPOINTMENT_NOTIFICATIONS_FAILURE,
  ex,
});

export const setAppointmentNotificationsVisibleAction = (visible) => ({
  type: SET_APPOINTMENT_NOTIFICATIONS_VISIBLE,
  visible,
});

export const resetStateAction = () => ({
  type: GET_APPOINTMENT_NOTIFICATIONS_RESET,
});
