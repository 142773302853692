import * as Sentry from '@sentry/browser';

/**
 * Wraps a function with a Sentry scope that includes the account_id and venue_id tags.
 */
export function withSentryScope<TArgs extends unknown[], TResult>(
  fn: (...args: TArgs) => Promise<TResult> | TResult | void,
): (...args: TArgs) => Promise<TResult | undefined> {
  return async (...args: TArgs) => {
    return new Promise<TResult | undefined>((resolve, reject) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          account_id: App.config.getAccountId(),
          venue_id: App.config.get('venue').id,
        });

        try {
          const result = fn(...args);

          if (result instanceof Promise) {
            result.then(resolve).catch(reject);
          } else {
            resolve(result as TResult | undefined);
          }
        } catch (error) {
          reject(error);
        }
      });
    });
  };
}
