import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestAcquisitionReportDataAction } from '../../newSalesReports/actions';
import { ClientAcquisitionReport } from './index';

const mapSateToProps = (state) => ({
  displayState: state.salesReport.acquisitionReport.displayState,
  reportData: state.salesReport.acquisitionReport.reportData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ requestAcquisitionReportDataAction }, dispatch),
});

const connector = connect(mapSateToProps, mapDispatchToProps);
const ClientAcquisitionReportContainer = connector(ClientAcquisitionReport);

export { ClientAcquisitionReportContainer as ClientAcquisitionReport };
