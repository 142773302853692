import apiUrl from 'common/api-url';

export function openSettlementReportPdf(date) {
  const settlementReportUri = apiUrl('POS_SETTLEMENT_REPORT', { date });

  window.open(settlementReportUri);
}

export function openDrawerCorrectionPdf(receiptId) {
  const receiptUrl = apiUrl('POS_TRANSACTION_RECEIPT', { receiptId });

  window.open(receiptUrl);
}
