import React from 'react';
import ReactDOM from 'react-dom';
import { ProductsTab } from 'components/menu/ProductsTab';

export function render(targetElement) {
  ReactDOM.render(<ProductsTab />, targetElement);
}

export function destroy(targetElement) {
  ReactDOM.unmountComponentAtNode(targetElement);
}
