/* eslint-disable padding-line-between-statements */
export { icon as AddMultiple } from './dist/AddMultiple';
export { icon as AddSingle } from './dist/AddSingle';
export { icon as Alert } from './dist/Alert';
export { icon as AllServices } from './dist/AllServices';
export { icon as Arrow } from './dist/Arrow';
export { icon as Award } from './dist/Award';
export { icon as Barcode } from './dist/Barcode';
export { icon as BellOff } from './dist/BellOff';
export { icon as BellOn } from './dist/BellOn';
export { icon as Birthday } from './dist/Birthday';
export { icon as Body } from './dist/Body';
export { icon as CalendarAdd } from './dist/CalendarAdd';
export { icon as CalendarBlock } from './dist/CalendarBlock';
export { icon as CalendarConfirm } from './dist/CalendarConfirm';
export { icon as CalendarEntry } from './dist/CalendarEntry';
export { icon as CalendarUpdate } from './dist/CalendarUpdate';
export { icon as Calendar } from './dist/Calendar';
export { icon as CameraAdd } from './dist/CameraAdd';
export { icon as Camera } from './dist/Camera';
export { icon as Campaigns } from './dist/Campaigns';
export { icon as CardAlert } from './dist/CardAlert';
export { icon as CautionFilled } from './dist/CautionFilled';
export { icon as Caution } from './dist/Caution';
export { icon as Chart } from './dist/Chart';
export { icon as Chat } from './dist/Chat';
export { icon as CheckFilled } from './dist/CheckFilled';
export { icon as Chevron } from './dist/Chevron';
export { icon as ClientApp } from './dist/ClientApp';
export { icon as Clock } from './dist/Clock';
export { icon as ClockUnavailable } from './dist/ClockUnavailable';
export { icon as Close } from './dist/Close';
export { icon as ColumnCollapse } from './dist/ColumnCollapse';
export { icon as ColumnExpand } from './dist/ColumnExpand';
export { icon as Comment } from './dist/Comment';
export { icon as Cookies } from './dist/Cookies';
export { icon as Copy } from './dist/Copy';
export { icon as Delete } from './dist/Delete';
export { icon as Desktop } from './dist/Desktop';
export { icon as Discounts } from './dist/Discounts';
export { icon as DocumentFeedback } from './dist/DocumentFeedback';
export { icon as Document } from './dist/Document';
export { icon as Dots } from './dist/Dots';
export { icon as Download } from './dist/Download';
export { icon as Edit } from './dist/Edit';
export { icon as Exclamation } from './dist/Exclamation';
export { icon as Expand } from './dist/Expand';
export { icon as External } from './dist/External';
export { icon as Eye } from './dist/Eye';
export { icon as Face } from './dist/Face';
export { icon as FacebookLite } from './dist/FacebookLite';
export { icon as Facebook } from './dist/Facebook';
export { icon as Filter } from './dist/Filter';
export { icon as Finance } from './dist/Finance';
export { icon as Gear } from './dist/Gear';
export { icon as Gift } from './dist/Gift';
export { icon as Goals } from './dist/Goals';
export { icon as HairRemoval } from './dist/HairRemoval';
export { icon as Hamburger } from './dist/Hamburger';
export { icon as Hand } from './dist/Hand';
export { icon as Heart } from './dist/Heart';
export { icon as HelpWheel } from './dist/HelpWheel';
export { icon as History } from './dist/History';
export { icon as Hotel } from './dist/Hotel';
export { icon as Hyperlink } from './dist/Hyperlink';
export { icon as Info } from './dist/Info';
export { icon as Instagram } from './dist/Instagram';
export { icon as Link } from './dist/Link';
export { icon as LogOut } from './dist/LogOut';
export { icon as Login } from './dist/Login';
export { icon as Luggage } from './dist/Luggage';
export { icon as Mail } from './dist/Mail';
export { icon as Medical } from './dist/Medical';
export { icon as Messenger } from './dist/Messenger';
export { icon as Minus } from './dist/Minus';
export { icon as MoneyCoins } from './dist/MoneyCoins';
export { icon as MoneyDeposit } from './dist/MoneyDeposit';
export { icon as MoneyFlows } from './dist/MoneyFlows';
export { icon as MoneyPerson } from './dist/MoneyPerson';
export { icon as MoneyWithdraw } from './dist/MoneyWithdraw';
export { icon as Money } from './dist/Money';
export { icon as Nails } from './dist/Nails';
export { icon as Note } from './dist/Note';
export { icon as Notebook } from './dist/Notebook';
export { icon as NotificationsOn } from './dist/NotificationsOn';
export { icon as Notifications } from './dist/Notifications';
export { icon as OnlineBooking } from './dist/OnlineBooking';
export { icon as Overnight } from './dist/Overnight';
export { icon as Package } from './dist/Package';
export { icon as PatchTest } from './dist/PatchTest';
export { icon as PersonAdd } from './dist/PersonAdd';
export { icon as PersonCircle } from './dist/PersonCircle';
export { icon as PersonDetails } from './dist/PersonDetails';
export { icon as Person } from './dist/Person';
export { icon as Phone } from './dist/Phone';
export { icon as Planet } from './dist/Planet';
export { icon as Play } from './dist/Play';
export { icon as Plus } from './dist/Plus';
export { icon as Pos } from './dist/Pos';
export { icon as Print } from './dist/Print';
export { icon as Product } from './dist/Product';
export { icon as Products } from './dist/Products';
export { icon as QuestionMark } from './dist/QuestionMark';
export { icon as Question } from './dist/Question';
export { icon as Ratings } from './dist/Ratings';
export { icon as Receipt } from './dist/Receipt';
export { icon as Reload } from './dist/Reload';
export { icon as Repeat } from './dist/Repeat';
export { icon as Repeated } from './dist/Repeated';
export { icon as Request } from './dist/Request';
export { icon as Schedules } from './dist/Schedules';
export { icon as Scissors } from './dist/Scissors';
export { icon as Search } from './dist/Search';
export { icon as Share } from './dist/Share';
export { icon as Shipment } from './dist/Shipment';
export { icon as Shrink } from './dist/Shrink';
export { icon as Sliders } from './dist/Sliders';
export { icon as Slippers } from './dist/Slippers';
export { icon as Sort } from './dist/Sort';
export { icon as Spa } from './dist/Spa';
export { icon as Star } from './dist/Star';
export { icon as Suppliers } from './dist/Suppliers';
export { icon as Sync } from './dist/Sync';
export { icon as Team } from './dist/Team';
export { icon as Tick } from './dist/Tick';
export { icon as TopRated } from './dist/TopRated';
export { icon as Treatwell } from './dist/Treatwell';
export { icon as Triangle } from './dist/Triangle';
export { icon as Twitter } from './dist/Twitter';
export { icon as VenueSearch } from './dist/VenueSearch';
export { icon as VenueSettings } from './dist/VenueSettings';
export { icon as Venue } from './dist/Venue';
export { icon as Verification } from './dist/Verification';
export { icon as Verified } from './dist/Verified';
export { icon as Voucher } from './dist/Voucher';
export { icon as WalkIn } from './dist/WalkIn';
export { icon as WebsiteSettings } from './dist/WebsiteSettings';
export { icon as Website } from './dist/Website';
