import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Hyperlink extends React.PureComponent<Props> {
  public render() {
    return (
      <svg
        className={['icon-svg', 'svg-hyperlink', this.props.className].join(' ')}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
      >
        <path d="M13.7071068,8.29289322 C14.6653761,9.2511625 15,10.2550343 15,11 L15,12 L13,12 L13,11 C13,10.7449657 12.8346239,10.2488375 12.2928932,9.70710678 C11.4854041,8.89961769 9.63777139,8.77644218 8.70710678,9.70710678 L4.70710678,13.7071068 C3.59763107,14.8165825 3.59763107,16.1834175 4.70710678,17.2928932 C5.81658249,18.4023689 7.18341751,18.4023689 8.29289322,17.2928932 L9,16.5857864 L10.4142136,18 L9.70710678,18.7071068 C7.81658249,20.5976311 5.18341751,20.5976311 3.29289322,18.7071068 C1.40236893,16.8165825 1.40236893,14.1834175 3.29289322,12.2928932 L7.29289322,8.29289322 C9.04972861,6.53605782 12.157453,6.74323945 13.7071068,8.29289322 Z M20.7071068,5.29289322 C22.5976311,7.18341751 22.5976311,9.81658249 20.7071068,11.7071068 L16.7071068,15.7071068 C14.9502714,17.4639422 11.842547,17.2567606 10.2928932,15.7071068 C9.33462393,14.7488375 9,13.7449657 9,13 L9,12 L11,12 L11,13 C11,13.2550343 11.1653761,13.7511625 11.7071068,14.2928932 C12.5145959,15.1003823 14.3622286,15.2235578 15.2928932,14.2928932 L19.2928932,10.2928932 C20.4023689,9.18341751 20.4023689,7.81658249 19.2928932,6.70710678 C18.1834175,5.59763107 16.8165825,5.59763107 15.7071068,6.70710678 L15,7.41421356 L13.5857864,6 L14.2928932,5.29289322 C16.1834175,3.40236893 18.8165825,3.40236893 20.7071068,5.29289322 Z" />
      </svg>
    );
  }
}

export const icon = iconWrapper(Hyperlink);
