;(function($) {
	/**
	 * Fills the given SELECT object with passed-in options.
	 * 
	 * @param Object options Hash or array with options. Key - value, Valu - option title.
	 */
	$.fn.setSelectOptions = function(options) {
		var list = document.createDocumentFragment();
		$.each(options, function(key, value) {
			var $opt = $('<option>', { value: key }).text(value);
			list.appendChild($opt.get(0));
		});
		
		this.empty().append(list);
		
		return this;
	};
}(jQuery));
