(function () {
  /**
   * Wahanda time profiler.
   * It prints the time between start() and stop() calls to the console.
   */
  var TP = (Wahanda.TimeProfiler = {
    _profiles: {},

    start: function (name, childName) {
      if (childName) {
        if (TP._profiles[name]) {
          TP._profiles[name].children[childName] = {
            start: time(),
            end: null,
          };
        } else {
          TP._err('Can not add child "' + childName + '" to non-existing profile "' + name + '"');
        }
      } else {
        TP._profiles[name] = {
          start: time(),
          children: {},
        };
      }
    },

    end: function (name, childName) {
      var profile = TP._profiles[name];
      if (TP._profiles[name]) {
        if (childName) {
          if (profile.children[childName]) {
            profile.children[childName].end = time();
          } else {
            TP._err(
              'Time child profile "' +
                childName +
                '" of parent "' +
                name +
                '" not stopped because it was not started',
            );
          }
        } else {
          TP._output('"' + name + '" completed in ' + (time() - profile.start) + 'ms');

          if (profile.children) {
            for (var child in profile.children) {
              TP._output(
                '... "' +
                  child +
                  '" took ' +
                  (profile.children[child].end - profile.children[child].start) +
                  'ms',
              );
            }
          }

          delete TP._profiles[name];
        }
      } else {
        TP._err('Time profile "' + name + '" not stopped because it was not started');
      }
    },

    isStarted: function (name) {
      return !!TP._profiles[name];
    },

    _output: function (text) {
      window.console && console.info && console.info('Profile: ' + text);
    },

    _err: function (error) {
      window.console && console.error && console.error(error);
    },
  });

  function time() {
    return new Date().getTime();
  }
})();
