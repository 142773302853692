import React from 'react';
import { iconWrapper, InjectedProps } from '../iconWrapper';

interface Props extends InjectedProps {
  className?: string;
}

class Hand extends React.PureComponent<Props> {
  public render() {
    const size = this.props.size;
    switch (size) {
      case 'large':
      case 'tiny':
      case 'small':
        return (
          <svg
            className={['icon-svg', 'svg-hand', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0a1 1 0 0 1 .993.883L9 1v5h1V2a1 1 0 0 1 .883-.993L11 1a1 1 0 0 1 .993.883L12 2v4c.35 0 .687.06 1 .17V4a1 1 0 0 1 2 0v9a3 3 0 0 1-2.824 2.995L12 16h-1v-2h1a1 1 0 0 0 .993-.883L13 13V9a1 1 0 0 0-.883-.993L12 8H7a1 1 0 0 0-.993.883L6 9v3.22l-2.958-.592L7.414 16H4.585L.461 11.875a1.498 1.498 0 0 1-.41-1.355 1.498 1.498 0 0 1 1.621-1.198l.143.021L4 9.78V2a1 1 0 0 1 2 0v4.17a3.03 3.03 0 0 1 .824-.165L7 6V1a1 1 0 0 1 1-1z"
              fillRule="evenodd"
            />
          </svg>
        );
      case 'medium':
      default:
        return (
          <svg
            className={['icon-svg', 'svg-hand', this.props.className].join(' ')}
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 24 24"
          >
            <path
              d="M12.5 0a2.5 2.5 0 0 1 2.461 2.06 2.5 2.5 0 0 1 3 2 2.501 2.501 0 0 1 3.034 2.276L21 6.5V20a4 4 0 0 1-3.8 3.995L17 24v-2a2.001 2.001 0 0 0 1.995-1.85L19 20V6.5a.5.5 0 0 0-.992-.09L18 6.5V12h-2V4.5a.5.5 0 0 0-.992-.09L15 4.5V12h-2V2.5a.5.5 0 0 0-.992-.09L12 2.5V12h-2V4.5a.5.5 0 0 0-.992-.09L9 4.5V17H7.213L4.17 15.098a.776.776 0 0 0-.962.11.54.54 0 0 0-.084.655l.053.075L9.966 24H7.35l-5.705-6.774a2.54 2.54 0 0 1 .147-3.433 2.778 2.778 0 0 1 3.27-.488l.166.097L7 14.508V4.5a2.501 2.501 0 0 1 2.336-2.495L9.5 2c.186 0 .367.02.54.059A2.5 2.5 0 0 1 12.5 0z"
              fillRule="evenodd"
            />
          </svg>
        );
    }
  }
}

export const icon = iconWrapper(Hand);
