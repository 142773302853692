import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { onStateChange } from 'common/websockets';
import uuid from 'uuid';

import setupRefresh from './refresh';
import setupEvents from './events';
import setupWebSockets from './websockets';

let unconfirmedAppts;

export function getCollection() {
  return unconfirmedAppts;
}

export function setup() {
  // The responsibility of this code is keeping the Unconfirmed Appointment list up to date.
  // This is does via WebSocket notifications, or if not connected - polling on regular intervals.
  const { STATUS_CODE_BOOKED } = App.Models.CalendarObjects.prototype;
  unconfirmedAppts = new App.Models.CalendarObjects({
    dateFrom: Wahanda.Date.toApiString(Wahanda.Date.createVenueDate()),
    include: ['appointments'],
    appointmentStatusCodes: [STATUS_CODE_BOOKED],
    utmSource: 'unconfirmed-notifications',
  });

  let lastFetch = null;
  let wsConnected = false;

  const update = async () => {
    if (!Wahanda.Permissions.useCalendar()) {
      return;
    }

    const currentFetch = uuid.v4();
    lastFetch = currentFetch;

    unconfirmedAppts.trigger('update-started');

    unconfirmedAppts.set('venueId', App.getVenueId());
    await unconfirmedAppts.fetchWithPromise();

    if (currentFetch === lastFetch) {
      // We should handle race conditions - taking only the last fetch's value.
      unconfirmedAppts.trigger('updated');
    }
  };
  const updateIfNoWebSocketsConnection = () => {
    if (!wsConnected) {
      update();
    }
  };

  setupRefresh(unconfirmedAppts, updateIfNoWebSocketsConnection);
  setupEvents(update, updateIfNoWebSocketsConnection);
  if (!App.isRestrictedMode()) {
    setupWebSockets(update);
  }

  // WebSockets connection state listening
  onStateChange(
    () => {
      wsConnected = true;
    },
    () => {
      wsConnected = false;
    },
  );
}
