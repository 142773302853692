import React from 'react';
import { toJs, map, get, filter, assoc, some, curry, hashMap, toClj } from 'mori';
import Fuse from 'fuse.js';
import Latinize from 'latinize';
import { formatFuse } from './formatFuse';

const getValue = curry(get, 'value');

function wrapName({ name }) {
  if (Array.isArray(name)) {
    return name.map(({ matches, text }, i) => {
      const key = `match-${i}`;
      return matches ? (
        <span className="typeahead-match" key={key}>
          {text}
        </span>
      ) : (
        text
      );
    });
  }

  return name;
}

function formatResultData(results) {
  // Need to format fuzzy results
  return map(
    (item) =>
      hashMap(
        'name',
        wrapName(item),
        'value',
        item.value,
        'groupingValue',
        item.groupingValue,
        'grouping',
        item.grouping,
      ),
    results,
  );
}

function getFirstActive(data) {
  // eslint-disable-next-line consistent-return
  return some((item) => {
    if (!get(item, 'hide') && !get(item, 'grouping')) {
      return item;
    }
  }, data);
}

function filterValidSearchValues(data) {
  return filter((item) => {
    const value = getValue(item);

    if (get(item, 'grouping')) {
      return false;
    }

    return value !== '' && value !== null && value !== false;
  }, data);
}

function latinizeData(data) {
  return map((item) => assoc(item, 'latinName', Latinize(get(item, 'name'))), data);
}

interface Props {
  data: any;
  originalSelected: any;
  onResults: (data: any, originalSelected: any) => void;
  defaultValue: string;
}

export class FuzzySearchInput extends React.Component<Props> {
  private input;

  public static defaultProps = {
    defaultValue: '',
  };

  public state = {
    data: latinizeData(this.props.data),
  };

  public componentDidMount() {
    // It's too dangerous to disable this right now as
    // I haven't been able to look at exactly how it works
    // TODO: Come back and work out why we're setting state inside of didMount

    if (this.input.value) {
      this.handleChange();
    }
  }

  public focus() {
    const value = this.input.value;
    this.input.focus();
    this.input.value = '';
    this.input.value = value;
    this.handleChange();
  }

  private handleChange = () => {
    const value = this.input.value;

    if (this.props.data) {
      // If `value` is empty, return the full list w/o filtering out the searchable values
      if (!value) {
        this.props.onResults(toClj(this.props.data), this.props.originalSelected);
        return;
      }

      const dataToSearch = filterValidSearchValues(this.state.data);

      const fuse = new Fuse(toJs(dataToSearch), {
        keys: ['latinName', 'name'],
        shouldSort: true,
        includeMatches: true,
      });
      const results = fuse.search(Latinize(value));
      const formatedResults = formatResultData(formatFuse(results));
      const newSelected = getFirstActive(formatedResults);

      // Notify of the change
      this.props.onResults(formatedResults, newSelected);
    } else {
      console.log('oops, no data supplied');
    }
  };

  public render() {
    const { defaultValue } = this.props;
    return (
      <input
        type="text"
        ref={(input) => {
          this.input = input;
        }}
        className="typeahead"
        onChange={this.handleChange}
        defaultValue={defaultValue}
      />
    );
  }
}
