/**
 * Tool for running an action after all models finished loading.
 * 
 * @param Array models Array of Models (or Collections) which need to be fetched
 * @param Function onAllFetched The function which will be called when all models are fetched
 * @param Function onError OPTIONAL Function to be called when an error occurs while fetching a model
 */
BackboneEx.Tool.MultiModelFetch = {
	run: function(models, onAllFetched, onError) {
		var loaded = 0;
		// How many models should be fetched?
		var toLoad = models.length;
		var sync = function() {
			loaded++;
			if (toLoad === loaded) {
				onAllFetched();
			}
		};
		
		if (_.isFunction(onError)) {
			// Run the error function only once
			onError = _.once(onError);
		}
		for (var i = 0; i < toLoad; i++) {
			models[i].fetch({ success: sync, error: onError });
		}
	}
};
