import { call, put } from 'redux-saga/effects';
import * as api from 'reduxStore/apiService';
import apiUrl from 'common/api-url';
import { businessConfigRequest } from '../NotInstalled/actions';
import { uninstallFBESuccess, uninstallFBEFailure } from './actions';

export function* uninstallFBERequested() {
  try {
    yield call(api.post, apiUrl('FBE_UNINSTALL'));
    yield put(businessConfigRequest());
    yield put(uninstallFBESuccess());
  } catch (responseError) {
    yield put(uninstallFBEFailure());
  }
}
