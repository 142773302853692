// this method cleans up the received HTML content and
// extracts the root <table> from the DOM string
export const parse = (template = '') => {
  const parser = new DOMParser();

  return [template]
    .map((v) => v.replace(/\r?\n|\r/g, '')) // strip \r\n type of newlines
    .map((v) => parser.parseFromString(v, 'text/html')) // parse HTML into DOM
    .map((v) => {
      // select the first <table> element
      const tableNodes = v.querySelectorAll('body > table');
      return tableNodes[0];
    })
    .map((v) => v.outerHTML) // encode to string again
    .map((v) => v.trim().replace(/>\s+</g, '><')) // trim whitespaces between tags
    .pop(); // unwrap the collection
};

export const validate = (template = '') => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(template, 'text/html');
  const tableNodes = dom.querySelectorAll('body > table');

  if (tableNodes.length !== 1) {
    const error =
      tableNodes.length === 0
        ? 'Provided HTML string does not provide a <table>'
        : 'Provided HTML contains more than a single <table>';

    return {
      isValid: false,
      error,
    };
  }

  return {
    isValid: true,
  };
};
